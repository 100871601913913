import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useAppTranslation } from "@hooks";
import { MutationOptions } from "./types";
import { fetchSectionQueryKey } from "./useFetchSectionQuery";
import { searchSectionsQueryKey } from "./useSearchSectionsQuery";
import { SectionRequestDto, updateSection } from "@client";

export function useUpdateSectionMutation(options?: MutationOptions) {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();

  return useMutation(
    (data: { id: number; input: SectionRequestDto }) =>
      updateSection(data.id, data.input),
    {
      ...options,
      onSuccess: (_, { id }) => {
        toast.success(t("sections.success_save"));
        queryClient.invalidateQueries([fetchSectionQueryKey]);
        queryClient.invalidateQueries([searchSectionsQueryKey]);
        options?.onSuccess && options.onSuccess(_, { id });
      },
    },
  );
}
