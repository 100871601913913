import { useCategoriesNutrientsFetchQuery } from "@hooks/queries/dictionaries";
import { useMemo } from "react";
import { NutrientCategoryConst } from "@consts/NutrientCategoryConst";

const useNutrientsCategories = () => {
  const { data: dataNutrientsCategories } = useCategoriesNutrientsFetchQuery();

  return useMemo(() => {
    if (!dataNutrientsCategories) {
      return [];
    }

    return dataNutrientsCategories.filter(
      c =>
        ![
          NutrientCategoryConst.ENERGY,
          NutrientCategoryConst.MACRO_ELEMENTS,
        ].includes(c.id),
    );
  }, [dataNutrientsCategories]);
};

export default useNutrientsCategories;
