import { styled, Tab } from "@mui/material";

export const StyledTab = styled(Tab)`
  padding: 0.75rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.tablet}px) {
    padding-left: 0;
  }
`;

export const TabsContainer = styled("div")`
  background-color: ${({ theme }) => theme.colors.lightPurple};
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.tablet}px) {
    border-right: solid 1px ${({ theme }) => theme.palette.divider};
    padding-left: 1.75rem;
  }
`;
