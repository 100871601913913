import { IconButton, styled } from "@mui/material";

interface IconButtonStyledProps {
  compact?: boolean;
}
export const IconButtonStyled = styled(IconButton, {
  shouldForwardProp: prop => prop !== "compact",
})<IconButtonStyledProps>`
  &.MuiButtonBase-root {
    background: ${({ theme }) => theme.colors.neutral.light[100]};
    border: 1px solid ${({ theme }) => theme.palette.primary.medium};
    height: ${({ compact }) => (compact ? "40px" : "46px")};
    min-width: 40px;
    width: fit-content;
    gap: 4px;
  }
`;

export const ButtonText = styled("span")`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary.main};
`;
