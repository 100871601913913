import { useContext, useEffect, useState } from "react";
import { FieldValues, useFormContext, useWatch } from "react-hook-form";
import { BlurContext } from "./BlurContext";

interface AutosaveOnBlurWatchProps<T> {
  onSubmit: (data: T) => void;
  isLoading: boolean;
}

export function AutosaveOnBlurWatch<T extends FieldValues>({
  onSubmit,
  isLoading,
}: AutosaveOnBlurWatchProps<T>) {
  const { isBlurred } = useContext(BlurContext);
  const values = useWatch<T>();
  const {
    handleSubmit,
    formState: { isDirty },
  } = useFormContext<T>();

  const [queued, setQueued] = useState(false);

  const update = () => {
    setQueued(false);
    handleSubmit(
      data => onSubmit(data),
      e => console.log(e),
    )();
  };

  useEffect(() => {
    if (!isBlurred) return;

    if (isLoading) setQueued(true);

    if (isDirty && values && !isLoading) update();
  }, [isBlurred]);

  useEffect(() => {
    if (queued && !isLoading) {
      update();
    }
  }, [queued, isLoading]);

  return <></>;
}
