import { slice } from "lodash";

export const mapElementsCountLabel = (
  optionsAmount: number,
  page: number,
  perPage: number,
) => {
  const start = Math.min(optionsAmount, 1 + page * perPage);
  const end = Math.min(optionsAmount, (page + 1) * perPage);

  return `${start}-${end}`;
};

export function getPaginatedSlice<T>(
  array: T[],
  page: number,
  perPage: number,
): T[] {
  const startIndex = page * perPage;
  const endIndex = page * perPage + perPage;

  return slice(array, startIndex, endIndex);
}
