import React from "react";
import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const CheckRound = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 58 58">
    <circle
      id="Ellipse_124"
      data-name="Ellipse 124"
      cx="29"
      cy="29"
      r="29"
      fill="#e4f8e9"
    />
    <g
      id="_20-icon-confirm"
      data-name="20-icon-confirm"
      transform="translate(20 23)"
    >
      <path
        id="check_icon"
        data-name="check icon"
        d="M0,6l6,6L18,0"
        fill="none"
        stroke="#40c862"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
    </g>
  </SvgIcon>
);
