import React, { Dispatch } from "react";
import { useAppTranslation } from "@hooks";
import Dialog from "@components/Alloweat/Dialog/Dialog";
import DialogTitle from "@components/Alloweat/Dialog/DialogTitle";
import { Button, DialogContent, Stack } from "@mui/material";
import DialogActions from "@components/Alloweat/Dialog/DialogActions";
import MainProductField from "@views/dietician/ClientHealth/ProfileTab/IrrigationSection/Dialog/MainProductField";
import GoalField from "@views/dietician/ClientHealth/ProfileTab/IrrigationSection/Dialog/GoalField";
import { IconButton } from "@components/Alloweat/IconButton";
import { CloseIcon } from "@icons/index";
import { FormCancelButton, FormSubmitButton } from "@components/FormButtons";
import IrrigationFormDetails from "@views/dietician/ClientHealth/ProfileTab/IrrigationSection/Dialog/IrrigationForm";

type IrrigationDialogProps = {
  open: boolean;
  setOpen: Dispatch<boolean>;
};

const IrrigationDialog = ({ open, setOpen }: IrrigationDialogProps) => {
  const { t } = useAppTranslation();
  const title = t(
    "patient.health_and_goal.dietary_profile_tab.section_hydration_modal.title",
  ).toLowerCase();
  return (
    <Dialog open={open} fullWidth PaperProps={{ sx: { maxWidth: "510px" } }}>
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {title.charAt(0).toUpperCase() + title.slice(1)}
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon color="727272" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <IrrigationFormDetails onSuccess={() => setOpen(false)}>
        <DialogContent>
          <Stack direction="column" gap="16px">
            <GoalField />
            <MainProductField />
          </Stack>
        </DialogContent>
        <DialogActions>
          <FormCancelButton onClick={() => setOpen(false)} />
          <FormSubmitButton />
        </DialogActions>
      </IrrigationFormDetails>
    </Dialog>
  );
};

export default IrrigationDialog;
