import { Chip, Stack } from "@mui/material";
import {
  ItemTitle,
  ItemTitleValue,
  TagsLabel,
} from "@views/dietician/PatientMonitoring2/components/MealsBox/MealBoxByDays.styled";
import {
  DoctorMonitoring,
  LikeFilledMonitoring,
  MealsMonitoring,
} from "@assets/icons/Monitoring";
import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import { useFetchMonitoringMealsStats } from "@hooks/queries/client/monitoring";
import { mapSelectedDaysToRequestQueryParams } from "@views/dietician/PatientMonitoring2/PatientMonitoringUtils";
import MealsBoxByDaysItem from "@views/dietician/PatientMonitoring2/components/MealsBox/MealsBoxByDaysItem";
import { useTagsUtils } from "@hooks/useTagsUtils";
import { useMemo } from "react";
import MonitoringDivider from "@views/dietician/PatientMonitoring2/components/MonitoringDivider";
import { FetchTagCategoriesTag } from "@client";
import { useAppTranslation } from "@hooks";
import { Trans } from "react-i18next";
import { uniq } from "lodash";
import MealsBox from "@views/dietician/PatientMonitoring2/components/MealsBox/MealsBox";
import MealsBoxShowMore from "@views/dietician/PatientMonitoring2/components/MealsBox/MealsBoxShowMore";

const MealsBoxByDays = () => {
  const { type, patientId, selectedDays } = useMonitoring();
  const { t, isPolishChosen } = useAppTranslation();
  const { getTagById } = useTagsUtils();

  const { data } = useFetchMonitoringMealsStats(
    {
      patientId,
      ...mapSelectedDaysToRequestQueryParams(selectedDays.selectDays),
    },
    { enabled: type.type !== "day" },
  );

  const { all, notTasty, tasty, eaten, items } = useMemo(() => {
    if (!data) {
      return {
        all: 0,
        tasty: 0,
        notTasty: 0,
        eaten: 0,
        items: 0,
      };
    }

    return data.data.meals;
  }, [data]);

  const tags: FetchTagCategoriesTag[] = useMemo(() => {
    if (data === undefined) {
      return [];
    }

    const tagsId = uniq(data.data.tags.map(tag => tag.id));

    const tags = tagsId
      .map(id => getTagById(id))
      .filter(
        (tag: FetchTagCategoriesTag | null): tag is FetchTagCategoriesTag =>
          tag !== null,
      );

    return tags.sort((t1, t2) => {
      if (isPolishChosen) {
        return (t1.namePl ?? "").localeCompare(t2.namePl ?? "");
      }
      return (t1.nameEn ?? "").localeCompare(t2.nameEn ?? "");
    });
  }, [data, getTagById, isPolishChosen]);

  return (
    <MealsBox showMore={<MealsBoxShowMore />}>
      <Stack direction="column" spacing="24px">
        <MealsBoxByDaysItem
          icon={
            <MealsMonitoring
              fill="#7448D0"
              width="24"
              height="24"
              viewBox="-2 -2 18 20"
              size=""
            />
          }
          text={
            <ItemTitle>
              <Trans
                i18nKey={"monitoring2.meals_box.multiple_days.title_1"}
                components={{
                  Highlight: <ItemTitleValue />,
                }}
                values={{
                  variable:
                    Math.round((eaten / Math.max(all, 1)) * 100).toString() +
                    "%",
                }}
              />
            </ItemTitle>
          }
          percent={Math.round((eaten / Math.max(all, 1)) * 100)}
          items={[
            {
              value: eaten,
              label: t("monitoring2.meals_box.multiple_days.eaten", {
                count: eaten,
              }),
            },
            {
              value: all - eaten,
              label: t("monitoring2.meals_box.multiple_days.not_eaten", {
                count: all - eaten,
              }),
            },
          ]}
        />

        <MonitoringDivider />

        <MealsBoxByDaysItem
          icon={
            <LikeFilledMonitoring
              fill="#7448D0"
              width="24"
              height="24"
              viewBox="-2 -4 18 20"
              size=""
            />
          }
          text={
            <ItemTitle>
              <Trans
                i18nKey={"monitoring2.meals_box.multiple_days.title_2"}
                components={{
                  Highlight: <ItemTitleValue />,
                }}
                values={{
                  variable:
                    Math.round((tasty / Math.max(items, 1)) * 100).toString() +
                    "%",
                }}
              />
            </ItemTitle>
          }
          percent={Math.round((tasty / Math.max(items, 1)) * 100)}
          items={[
            {
              value: tasty,
              label: t("monitoring2.meals_box.multiple_days.enjoyed", {
                count: tasty,
              }),
            },
            {
              value: notTasty,
              label: t("monitoring2.meals_box.multiple_days.not_enjoyed", {
                count: tasty,
              }),
            },
          ]}
        />
        {tags.length > 0 && (
          <>
            <MonitoringDivider />
            <Stack direction="column" spacing="16px">
              <TagsLabel>
                <DoctorMonitoring
                  fill="#7448D0"
                  style={{ marginRight: "8px" }}
                />
                {t("monitoring2.meals_box.multiple_days.symptoms")}
              </TagsLabel>
              <Stack direction="row" gap="8px" flexWrap="wrap">
                {tags.map(item => (
                  <Chip
                    key={item.id}
                    color="primary"
                    label={isPolishChosen ? item.namePl : item.nameEn}
                  />
                ))}
              </Stack>
            </Stack>
          </>
        )}
      </Stack>
    </MealsBox>
  );
};

export default MealsBoxByDays;
