import { ProductReplacementDto } from "@client/diets";
import { Nutrient } from "@typeDefinitions";
import { Dispatch, SetStateAction, createContext, useContext } from "react";

export interface SelectedContextIf {
  setSelectedCurrent: Dispatch<SetStateAction<ProductReplacementDto | null>>;
  setSelectedReplacement: Dispatch<
    SetStateAction<ProductReplacementDto | null>
  >;
  nutrientsDifference: Nutrient[];
  calculateDifferece: (replacementNutrients: Nutrient[]) => void;
  selectedDietProducts: number[];
  targetNutrients: Nutrient[] | undefined;
}

export const SelectedContext = createContext<SelectedContextIf | undefined>(
  undefined,
);

export const useSelectedContext = () => {
  const context = useContext(SelectedContext);

  if (!context) {
    throw new Error("useSelectedContext must be used within a SelectedContext");
  }

  return context;
};
