import { ReactNode } from "react";
import {
  CardContentWrapper,
  CardTitleStyled,
  DragHandleWrapper,
  QuestionnaireCardStyled,
  SpacingDiv,
} from "./QuestionnaireCardWrapper.styled";
import { DragHandle } from "@assets/icons/DesignSystem";

interface QuestionnaireCardWrapperProps {
  title: ReactNode;
  actions: ReactNode;
  children: ReactNode;
  dragHandle?: boolean;
}

export const QuestionnaireCardWrapper = ({
  title,
  actions,
  children,
  dragHandle,
}: QuestionnaireCardWrapperProps) => {
  return (
    <QuestionnaireCardStyled>
      <SpacingDiv>
        {dragHandle && (
          <DragHandleWrapper className="dragHandle">
            <DragHandle />
          </DragHandleWrapper>
        )}
      </SpacingDiv>
      <CardContentWrapper>
        <div className="flex justify-between">
          <CardTitleStyled>{title}</CardTitleStyled>
          {actions}
        </div>

        {children}
      </CardContentWrapper>
    </QuestionnaireCardStyled>
  );
};
