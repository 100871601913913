import { Control, Controller, Path } from "react-hook-form";

import { Datepicker, DatepickerProps } from "@components/Datepicker";
import { FieldValues } from "react-hook-form/dist/types";

/**
 * datepicker component with React Hook Form Controller
 */
export const FormDatepicker = <T extends FieldValues>(
  props: FormDatepickerProps<T>,
) => {
  const { name, control, error, ...rest } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onBlur, onChange, value, ref } }) => {
        return (
          <Datepicker
            ref={ref}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            selected={value}
            {...rest}
          />
        );
      }}
    />
  );
};

interface FormDatepickerProps<T extends FieldValues> extends DatepickerProps {
  /** name attribute */
  name: Path<T>;
  /** react hook form control */
  control: Control<T>;
  /** error message */
  error?: string;
}
