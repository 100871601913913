import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const CupMonitoring = (props: SvgIconProps) => (
  <SvgIcon width="14" height="17" viewBox="0 0 14 17" {...props}>
    <path
      d="M1.5926 0.00164413H12.4074C12.6005 0.00164413 12.8258 0.0982049 12.9546 0.25914C13.1155 0.420074 13.1799 0.645383 13.1477 0.870691L11.989 14.3892C11.8924 15.5801 10.8946 16.4813 9.7037 16.4813H4.2963C3.0732 16.4813 2.0754 15.5801 1.97884 14.3892L0.82011 0.870691C0.787923 0.645383 0.852297 0.420074 1.01323 0.25914C1.14198 0.0982049 1.36729 0.00164413 1.5926 0.00164413ZM2.65477 4.12157H11.313L11.5384 1.54662H2.42946L2.65477 4.12157ZM2.78351 5.66654L3.52381 14.2605C3.556 14.6467 3.87787 14.9364 4.2963 14.9364H9.7037C10.0899 14.9364 10.4118 14.6467 10.444 14.2605L11.1843 5.66654H2.78351Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
