import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, number, array, mixed, InferType, date } from "yup";
import { MEETING_TYPE, MEETING_MODE } from "@utils/scheduleEvent";
import { useDeepCompareEffect } from "react-use";
import { CalendarTimeUnit } from "@views/dietician/Home/components/Calendar/MiniCalendar/_components/CalendarTabs";
import { startOfToday } from "date-fns";
import { useCalendarFilters } from "@views/dietician/Home/components/Calendar/_hooks/CalendarFilters.context";
import { useEffect } from "react";

const resolver = object({
  dietitians: array().of(string()).default([]),
  date: date().default(startOfToday()),
  time_unit: string().default(""),
  search: string().nullable().default(null),
  patients: array().of(number()).default([]),
  all_day: number().nullable().default(null),
  meeting_types: array().of(mixed<MEETING_TYPE>()).default([]),
  meeting_modes: array().of(mixed<MEETING_MODE>()).default([]),
  facilities: array().of(number()).default([]),
  product: array().of(string()),
});

export type CalendarFiltersSchema = InferType<typeof resolver>;

export const calendarFiltersDefaultValues: CalendarFiltersSchema = {
  dietitians: [],
  facilities: [],
  meeting_types: [],
  date: startOfToday(),
  time_unit: CalendarTimeUnit.WEEK,
  search: null,
  patients: [],
  all_day: null,
  meeting_modes: [],
  product: [],
};

export const useCalendarFiltersForm = () => {
  const [filters, setFilters] = useCalendarFilters();

  const form = useForm<CalendarFiltersSchema>({
    mode: "onChange",
    resolver: yupResolver(resolver),
    defaultValues: filters.filters,
  });

  const formData = useWatch(form);

  useDeepCompareEffect(() => {
    setFilters(prevFilters => ({
      accountId: prevFilters.accountId,
      filters: formData as CalendarFiltersSchema,
    }));
  }, [formData]);

  useEffect(() => {
    form.reset({ ...formData, dietitians: filters.filters.dietitians ?? [] });
  }, [filters.filters.dietitians.length]);

  return form;
};
