import { string, lazy, object } from "yup";

export const mapObjectDynamicFieldsRules = <T>(
  map: Record<string, unknown>,
  rule: T,
): Record<string, T> => {
  return Object.keys(map).reduce(
    (newMap, key) => ({ ...newMap, [key]: rule }),
    {},
  );
};
