import { useMutation, useQueryClient } from "@tanstack/react-query";
import { patientKeys } from "@hooks/queries/client/patientKeys";
import {
  UpdatePatientHydrationProductParams,
  UpdatePatientHydrationProductPayload,
  updatePatientHydrationProducts,
} from "@client/patient/hydrationProduct/updatePatientHydrationProduct";
import { FetchPatientHydrationProductResponse } from "@client/patient/hydrationProduct/fetchPatientHydrationProduct";

const useUpdatePatientHydrationProductMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      params,
      payload,
    }: {
      params: UpdatePatientHydrationProductParams;
      payload: UpdatePatientHydrationProductPayload;
    }) => {
      return updatePatientHydrationProducts(params, payload);
    },
    onSuccess: async (_, variables) => {
      queryClient.setQueryData(
        patientKeys.hydrationProduct(variables.params.patientId),
        (oldData: FetchPatientHydrationProductResponse | undefined) => {
          if (oldData === undefined) {
            return undefined;
          }

          return {
            ...oldData,
            data: {
              hydrationProduct: variables.payload.hydration_product,
              goal: variables.payload.goal,
            },
          };
        },
      );
    },
  });
};

export default useUpdatePatientHydrationProductMutation;
