import { IdDto } from "@client/common";
import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const postCreatorDietMealsItems = async (
  dietId: string,
  payload: CreatorDietMealsItemsRequest,
  version: string,
) => {
  return await fetchData(
    `/dietitian/creator-2/diets/${dietId}/meals-items`,
    APIMethods.POST,
    payload,
    undefined,
    { "Alloweat-Creator-Version": version },
  );
};

export interface ItemDto {
  type: "recipe" | "product";
  id: number;
}

export interface RecipeItemDto extends ItemDto {
  type: "recipe";
  servings: number;
}

export interface ProductItemDto extends ItemDto {
  type: "product";
  grams: number;
  measure: IdDto;
}

export interface CreatorDietMealDto {
  meal: IdDto;
  item: RecipeItemDto | ProductItemDto;
}
export interface CreatorDietMealsItemsRequest {
  data: CreatorDietMealDto[];
}
