import { Typography } from "@mui/material";

import { useAppTranslation } from "@hooks";

import { LabelWrapper, StyledSlider } from "../CaloricNeedsForm.styled";
import { isArray } from "lodash";

interface LabeledSliderProps {
  value: number;
  onChange: (data: number) => void;
  step: number;
  disabled?: boolean;
}
export const LabeledSlider = ({
  value,
  onChange,
  step,
  disabled,
}: LabeledSliderProps) => {
  const { t } = useAppTranslation();
  const valueCount = value !== null ? value - 1 : null;

  return (
    <div className="flex flex-col flex-1">
      <StyledSlider
        disabled={disabled}
        step={step}
        value={valueCount ? valueCount * step : 0}
        onChange={(_, value) =>
          !isArray(value) ? onChange(Math.floor(value / step) + 1) : null
        }
        valueLabelDisplay={disabled ? "off" : "on"}
        valueLabelFormat={_ => {
          switch (valueCount) {
            case 0:
              return t("balance.very_low");
            case 1:
              return t("balance.low");
            case 2:
              return t("balance.moderate");
            case 3:
              return t("balance.high");
            case 4:
              return t("balance.very_high");
            default:
              return "";
          }
        }}
      />
      <LabelWrapper>
        <Typography variant="caption">{t("balance.very_low")}</Typography>
        <Typography variant="caption">
          {step > 25 ? t("balance.high") : t("balance.very_high")}
        </Typography>
      </LabelWrapper>
    </div>
  );
};
