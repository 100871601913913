import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import { PhoneDto, phoneSchema } from "./common";

export const fetchClients = async (
  params?: URLSearchParams,
): Promise<ApiResponse<ClientDto[]>> => {
  const response = await fetchData(
    "/dietitian/clinic/patients/search",
    APIMethods.GET,
    undefined,
    params,
  );
  return await fetchClientsResponseSchema.validate(response);
};

const clientSchema = yup.object().shape({
  id: yup.number().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().nullable().default(null),
  phone: phoneSchema.required(),
  avatar: yup.string().nullable().default(null),
});

const fetchClientsResponseSchema = yup.object().shape({
  data: yup.array().of(clientSchema).required(),
});

export interface ClientDto {
  id: number;
  firstName: string;
  lastName: string;
  email: string | null;
  phone: PhoneDto;
  avatar: string | null;
}
