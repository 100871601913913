import { IconButton, styled, ToggleButton } from "@mui/material";

export const SimulationSectionWrapper = styled("div")`
  display: grid;
  gap: 1.25rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.laptop}px) {
    display: flex;
    gap: 2rem;
  }
`;

export const TopSectionWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const SimulationBottomSectionWrapper = styled("div")`
  display: grid;
  gap: 1.75rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.laptop}px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0.25rem;
`;

export const StyledToggleButton = styled(ToggleButton)`
  padding: 0.75rem;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: 1rem;
`;
