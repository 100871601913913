import dayjs from "dayjs";

import { useAppParams, useProgramLocalStorage } from "@hooks";
import {
  ProgramScheduleNavigationContext,
  useProgramScheduleNavigationContext,
} from "@views/dietician/ProgramSchedule/context";
import { FetchProgramResponse } from "@client/program";
import {
  useFetchDietitianProgramScheduleQuery,
  useInitDietitianProgramScheduleMutation,
} from "@hooks/queries/schedule";
import { useProgram } from "@hooks/resources";

import { ProgramScheduleTour } from "components/Tours/ProgramScheduleTour";
import { ProgramScheduleView } from "./ProgramScheduleView";
import { useEffect, useState } from "react";

export const ProgramSchedule = () => {
  const { patientId, programId } = useAppParams();
  const parsedClientId = parseInt(patientId);
  const programIdParsed = parseInt(programId);

  const { mutate: initSchedule, isLoading: isGenerating } =
    useInitDietitianProgramScheduleMutation(programIdParsed);

  const {
    program,
    isLoading: isProgramLoading,
    isFetching: isProgramFetching,
  } = useProgram();

  const programCast = program as FetchProgramResponse | undefined;

  const {
    context,
    queryData: { view, date },
  } = useProgramScheduleNavigationContext({
    start: programCast && dayjs(programCast.startDate),
    end:
      programCast && programCast.durationDays
        ? dayjs(programCast.startDate).add(programCast.durationDays - 1, "days")
        : dayjs(programCast?.startDate).add(90, "days"),
    showDates: false,
    focusOnToday: false,
  });

  const [enableSchedule, setEnableSchedule] = useState(false);
  const { markInitialized } = useProgramLocalStorage();

  useEffect(() => {
    initSchedule(undefined, {
      onSuccess: () => {
        setEnableSchedule(true);
        markInitialized();
      },
    });
  }, []);

  const { scheduleData, isLoading: isScheduleLoading } =
    useFetchDietitianProgramScheduleQuery(programIdParsed, view, date, {
      enabled: enableSchedule,
    });

  return (
    <>
      <ProgramScheduleTour />
      <ProgramScheduleNavigationContext.Provider value={context}>
        <ProgramScheduleView
          scheduleData={scheduleData}
          programId={programIdParsed}
          clientId={parsedClientId}
          isLoading={isScheduleLoading}
          program={program}
          isProgramLoading={isProgramLoading || isProgramFetching}
          isGenerating={isGenerating}
        />
      </ProgramScheduleNavigationContext.Provider>
    </>
  );
};
