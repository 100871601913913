import { LanguagesSlugs } from "@typeDefinitions";
import * as yup from "yup";
import { translationLang } from "@utils/yup_schema";

export interface PatientProgramSearchNewResource {
  id: number;
  name: string | null;
  translations: {
    lang: LanguagesSlugs;
    name: string | null;
  }[];
  language: LanguagesSlugs;
  startDate: string;
  endDate: string | null;
  programDaysCount: number;
  shared: boolean;
  isActive: boolean;
}

export const patientProgramsSearchSchema = yup.array().of(
  yup.object({
    id: yup.number().required(),
    name: yup.string().nullable().defined(),
    translations: yup
      .array()
      .of(
        yup.object({
          lang: translationLang,
          name: yup.string().nullable().defined(),
        }),
      )
      .required(),
    language: translationLang,
    startDate: yup.string().required(),
    endDate: yup.string().nullable().defined(),
    programDaysCount: yup.number().required(),
    shared: yup.boolean().required(),
    isActive: yup.boolean().required(),
  }),
);
