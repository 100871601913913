import * as yup from "yup";

export interface PatientProductExchangerResource {
  tag: {
    id: number;
  };
}

export const patientProductExchangerSchema = yup.object({
  tag: yup
    .object({
      id: yup.number().required(),
    })
    .required(),
});
