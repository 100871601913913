import { BoxTitleContainer } from "@views/dietician/PatientMonitoring2/components/BoxWrapper";
import { FC, ReactNode } from "react";
import { Stack } from "@mui/material";
import { useAppTranslation } from "@hooks";
import { MealsMonitoring } from "@assets/icons/Monitoring";

type MealBoxType = {
  showMore?: ReactNode;
  children: ReactNode;
};
const MealsBox: FC<MealBoxType> = ({ children, showMore }) => {
  const { t } = useAppTranslation();

  return (
    <Stack direction="column" spacing="24px" flex="1">
      <Stack direction="row" justifyContent="space-between">
        <BoxTitleContainer
          icon={<MealsMonitoring fill="#727272" />}
          title={t("monitoring2.meals_box.title")}
        />
        {showMore}
      </Stack>
      {children}
    </Stack>
  );
};

export default MealsBox;
