import { useAppParams } from "@hooks";
import { usePostCreatorDietMealMutation } from "@hooks/queries/diets/creator";
import { usePostProgramDietMealMutation } from "@hooks/queries/program/dietCreator/usePostProgramDietMealMutation";

export const usePostDietMealMutation = () => {
  const { programId } = useAppParams();

  return programId
    ? usePostProgramDietMealMutation(programId)
    : usePostCreatorDietMealMutation();
};
