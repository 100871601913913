import { useQuery } from "@tanstack/react-query";
import {
  fetchMonitoringCalendarDays,
  FetchMonitoringCalendarDaysParams,
  FetchMonitoringCalendarDaysResponse,
} from "client/patient/monitoring";
import { QueryOptionsTyped } from "@hooks/queries";
import { patientMonitoringKeys } from "@hooks/queries/client/monitoring/patientMonitoringKey";
import {
  fetchMonitoringCalendarDaysByMonths,
  FetchMonitoringCalendarDaysByMonthsParams,
  FetchMonitoringCalendarDaysByMonthsResponse,
} from "@client/patient/monitoring/fetchMonitoringCalendarDaysByMonths";

export const useFetchMonitoringCalendarDaysByMonths = (
  params: FetchMonitoringCalendarDaysByMonthsParams,
  options?: QueryOptionsTyped<FetchMonitoringCalendarDaysByMonthsResponse>,
) => {
  return useQuery({
    queryKey: patientMonitoringKeys.calendarDaysByMonths(params),
    queryFn: () => fetchMonitoringCalendarDaysByMonths(params),
    ...options,
  });
};
