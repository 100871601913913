import { Box, styled } from "@mui/material";

export const Layout = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 4px;
` as typeof Box;

export const Label = styled("span")`
  font: var(--font-figtree-regular);
  font-size: 12px;
  line-height: 20px;
  color: #4d516e;
`;

export const Value = styled("span")`
  font: var(--font-figtree-medium);
  font-size: 14px;
  line-height: 24px;
  color: #333333;
`;
