import { Dispatch, SetStateAction, useMemo } from "react";

import { InputAdornment, Slider, TextField } from "@mui/material";
import { isArray } from "lodash";

import { decimalFourDigitsInput } from "@utils/inputs";

import { DashStyled, Label, InputsWrapper } from "./SliderFilter.styled";
import { MediumThumbComponent } from "@components/SliderThumbs";
import { useNutrients } from "@hooks";

export interface SliderFilterProps {
  nutrientId: string;
  label?: string;
  value?: [number, number];
  setValue: Dispatch<SetStateAction<[number, number]>>;
  maxValue: number;
  step: number;
  decimalInput?: any;
}

export const SliderFilter = ({
  label: externalLabel,
  value = [0, 0],
  setValue,
  maxValue,
  step,
  decimalInput = decimalFourDigitsInput,
  nutrientId,
}: SliderFilterProps) => {
  const { nutrientDict } = useNutrients();
  const nutrient = useMemo(
    () => nutrientDict.get(Number(nutrientId)),
    [nutrientId, nutrientDict],
  );
  const label = externalLabel || nutrient?.name;
  const unit = nutrient?.unit;

  const min = value?.[0];
  const max = value?.[1];

  const handleChange = (value: string, input: SliderValue) => {
    if (input === SliderValue.MIN) setValue([Number(value), max]);
    if (input === SliderValue.MAX) setValue([min, Number(value)]);
  };

  const onSliderChange = (_: Event, value: number | number[]) => {
    if (isArray(value)) setValue(value as [number, number]);
  };

  return (
    <div className="grid gap-1">
      <Label>{label}</Label>

      <div className="grid">
        <InputsWrapper>
          <TextField
            value={String(min)}
            onChange={e => handleChange(e.target.value, SliderValue.MIN)}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{unit}</InputAdornment>
              ),
              inputComponent: decimalInput,
            }}
          />
          <DashStyled>-</DashStyled>
          <TextField
            value={String(max)}
            onChange={e => handleChange(e.target.value, SliderValue.MAX)}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{unit}</InputAdornment>
              ),
              inputComponent: decimalInput,
            }}
          />
        </InputsWrapper>
        <Slider
          slots={{ thumb: MediumThumbComponent }}
          value={value}
          max={maxValue}
          onChange={onSliderChange}
          step={step}
        />
      </div>
    </div>
  );
};

enum SliderValue {
  MIN = "min",
  MAX = "max",
}
