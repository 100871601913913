import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "../types";
import { fetchPatientVisitsQueryKey } from "./useFetchPatientVisitsQuery";
import { addFoodToVisit } from "@client/visits";

interface FoodPayload {
  foodId: number;
  isFavourite: boolean;
}

export const useAddVisitFoodMutation = (
  patientId: number,
  scheduleId: number,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ foodId, isFavourite }: FoodPayload) =>
      addFoodToVisit(scheduleId, foodId, { isFavourite }),
    {
      ...options,
      onSuccess() {
        queryClient.invalidateQueries([fetchPatientVisitsQueryKey, patientId]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};
