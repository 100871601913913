import { Chip, Typography } from "@mui/material";

import { useAppTranslation } from "@hooks";
import { MuiWhiteTooltip } from "@components/MuiButtonWithTooltip";

import {
  ExpandableChipsStyled,
  TooltipWrapper,
} from "./ExpandableChips.styled";

interface ExpandableChipsProps {
  labels: string[];
  variant?: "newFilled" | "filled" | "outlined";
  showCount?: number;
}
export const ExpandableChips = ({
  labels,
  variant,
  showCount = 4,
}: ExpandableChipsProps) => {
  const { t } = useAppTranslation();

  return (
    <ExpandableChipsStyled>
      {labels.slice(0, showCount).map((label, i) => (
        <Chip key={i} label={label} variant={variant} color="primary" />
      ))}
      {labels.length > showCount && (
        <MuiWhiteTooltip
          placement="bottom"
          title={
            <TooltipWrapper>
              {labels.slice(showCount, labels.length).map((label, i) => (
                <Chip key={i} label={label} variant={variant} color="primary" />
              ))}
            </TooltipWrapper>
          }
        >
          <Typography variant="body2" color="text.secondary">
            {t("common.more").toLowerCase()}
          </Typography>
        </MuiWhiteTooltip>
      )}
    </ExpandableChipsStyled>
  );
};
