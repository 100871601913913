import { useParams } from "react-router-dom";

type ProgramParams = {
  programId: string;
};

export function useProgramId() {
  const { programId } = useParams<ProgramParams>();
  return programId ? parseInt(programId) : undefined;
}
