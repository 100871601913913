import { useMutation, useQueryClient } from "@tanstack/react-query";

import { updateProgramSection, UpdateProgramSectionPayload } from "@client";

import { MutationOptions } from "./types";
import { useFetchProgramSectionsQueryKey } from "./useFetchProgramSectionsQuery";
import { fetchProgramSectionQueryKey } from "./useFetchProgramSectionQuery";

interface UpdateProgramSectionProps {
  programId: number;
  sectionId: number;
  payload: UpdateProgramSectionPayload;
}

export const useUpdateProgramSectionMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ programId, sectionId, payload }: UpdateProgramSectionProps) =>
      updateProgramSection(programId, sectionId, payload),
    {
      ...options,
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries([
          useFetchProgramSectionsQueryKey,
          variables?.programId,
        ]);
        queryClient.invalidateQueries([
          fetchProgramSectionQueryKey,
          variables?.programId.toString(),
          variables?.sectionId.toString(),
        ]);
        options?.onSuccess && options.onSuccess(_, variables);
      },
    },
  );
};
