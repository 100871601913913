import { APIMethods, ApiResponse } from "@typeDefinitions";
import * as yup from "yup";
import { idSchema } from "@client";
import { fetchData } from "@utils/api";

export const postCreatorMealProduct = async (
  mealId: string,
  payload: PostCreatorMealProductRequest,
  version: number,
): Promise<ApiResponse<PostCreatorMealProductResponse>> => {
  const response = await fetchData(
    `/dietitian/creator/meals/${mealId}/product-add`,
    APIMethods.POST,
    payload,
    undefined,
    { "Alloweat-Creator-Version": `${version}` },
  );

  return apiResponseSchema.validate(response);
};

export interface PostCreatorMealProductRequest {
  id: number;
}

interface PostCreatorMealProductResponse {
  id: number;
}

const apiResponseSchema = yup
  .object()
  .shape({
    data: idSchema.required(),
  })
  .required();
