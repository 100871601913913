import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import * as yup from "yup";

export async function fetchVisitNote(
  patientId: number,
): Promise<ApiResponse<NoteObject>> {
  const data = await fetchData(
    `/dietitian/patients/${patientId}/note`,
    APIMethods.GET,
  );
  await patientNoteSchema.validate(data);
  return data;
}

const patientNoteSchema = yup.object().shape({
  data: yup
    .object()
    .shape({
      note: yup.string(),
    })
    .required(),
});

interface NoteObject {
  note: string;
}
