import { useEffect, useMemo } from "react";
import { FormProvider, UseFormReturn, useController } from "react-hook-form";

import { IconButton, InputAdornment } from "@mui/material";

import { Close, Loupe, Plus } from "@assets/icons";
import {
  DEFAULT_MACROS_RANGE,
  RecipesFiltersFormInput,
  RecipesFiltersNew,
} from "@components/RecipesFiltersNew";
import { useSearchRecipesInfiniteQuery } from "@hooks/queries";

import { AutosaveWatchComponent } from "@components/AutosaveWatchComponent";
import { defaultFilters as recipeResetFilters } from "@components/RecipesFiltersNew/recipesFiltersUtils";
import { useAppTranslation } from "@hooks";
import { isNumber } from "lodash";
import { RecipeAccordion, RecipeAccordionProps } from "../ElementsAccordions";
import {
  ButtonStyled,
  FormTextFieldStyled,
} from "./AddElementSidePanel.styled";
import { FiltersAlert } from "./FiltersAlert";
import { InfiniteList } from "./InfiniteList";
import { TabWrapper } from "./common.styled";

interface RecipesTabProps {
  form: UseFormReturn<RecipesFiltersFormInput, any>;
  setTotal: (total: number | undefined) => void;
  defaultFilters?: RecipesFiltersFormInput;
  onAddOwnRecipe: () => void;
  mealId: number;
}

export const RecipesTab = ({
  form,
  setTotal,
  defaultFilters,
  onAddOwnRecipe,
  mealId,
}: RecipesTabProps) => {
  const { t } = useAppTranslation();
  const resetValues = useMemo(
    () => ({
      ...recipeResetFilters,
      patient: defaultFilters?.patient,
    }),
    [],
  );
  const { recipes, submit, isFetching, meta, ...rest } =
    useSearchRecipesInfiniteQuery(defaultFilters, { refetchOnMount: false });

  const {
    field: { value, onChange },
  } = useController({ control: form.control, name: "query" });
  const handleClearQuery = () => onChange("");

  useEffect(() => {
    if (isNumber(meta?.total)) setTotal(meta?.total);
  }, [meta?.total]);

  const mappedRecipes = useMemo<RecipeAccordionProps[]>(
    () =>
      recipes.map(({ tags, media, ...rest }) => ({
        ...rest,
        imgUrl: media.url ?? undefined,
        tags: tags.map(t => t.id),
        clientContext: "clientContext" in rest ? rest.clientContext : undefined,
        mealId,
      })) ?? [],
    [recipes, mealId],
  );

  const handleReset = () => {
    form.reset(resetValues);
    submit(resetValues);
  };

  const clearDefaultFilters = () => {
    const newFilters = {
      ...defaultFilters,
      nutrients: DEFAULT_MACROS_RANGE,
      tags: [],
    };

    form.reset(newFilters);
    submit(newFilters);
  };

  return (
    <TabWrapper>
      <div className="flex justify-between">
        <div className="flex gap-[4px]">
          <FormProvider {...form}>
            <AutosaveWatchComponent isLoading={isFetching} onSubmit={submit} />
            <FormTextFieldStyled
              control={form.control}
              name="query"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Loupe />
                  </InputAdornment>
                ),
                endAdornment: value && (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={handleClearQuery}>
                      <Close size="w-[10px] h-[10px]" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder={t("common.search")}
            />
            <RecipesFiltersNew
              unshiftTabs
              total={meta?.total}
              resetValues={resetValues}
            />
          </FormProvider>
        </div>

        <ButtonStyled onClick={onAddOwnRecipe}>
          <Plus />
          {t("diet.add_own_recipe")}
        </ButtonStyled>
      </div>

      <FiltersAlert
        defaultFilters={defaultFilters}
        onClear={clearDefaultFilters}
      />
      <InfiniteList
        list={mappedRecipes}
        Element={RecipeAccordion}
        clearFilters={handleReset}
        {...rest}
      />
    </TabWrapper>
  );
};
