import { useMemo } from "react";

import { Visits, VisitTagGroup } from "@typeDefinitions";
import { SortableList } from "@components/SortableList";

import { usePatientVisitDetails } from "./usePatientVisitDetails";
import { VisitDetailSegmentBuilder } from "../VisitDetialSegmentBuilder/VisitDetailSegmentBuilder";
import { PastNotesCard } from "../VisitDetailSegment/components/PastNotesCard/PastNotesCard";
import { ColumnWrapper } from "./components/ColumnWrapper.styled";

export const DRAG_HANDLE = "dragHandle";

interface PatientVisitDetailsProps {
  patientId: number;
  visit: Visits;
  visitOrder: number;
  tags?: VisitTagGroup[];
  patientVisits: Visits[];
}

export const PatientVisitDetails = (props: PatientVisitDetailsProps) => {
  const { patientId, visit, visitOrder, tags, patientVisits } = props;

  const { segmentsLeft, segmentsRight } = usePatientVisitDetails(
    visit,
    patientId,
    visitOrder,
    patientVisits,
  );

  const commonProps = useMemo(
    () => ({
      patientVisits,
      tags,
      patientId,
      visitId: visit.id,
      isFirst: !visitOrder,
    }),
    [tags],
  );

  return (
    <div className="flex">
      <ColumnWrapper className="mr-2">
        <SortableList
          defaultItemList={segmentsLeft}
          handle={"." + DRAG_HANDLE}
          cols={1}
          flexBox
        >
          {segmentsLeft.map(segment =>
            segment.type === "pastNotes" ? (
              <PastNotesCard key={segment.name} segment={segment} />
            ) : (
              <VisitDetailSegmentBuilder
                key={segment.name}
                isLeft
                segment={segment}
                {...commonProps}
              />
            ),
          )}
        </SortableList>
      </ColumnWrapper>
      <ColumnWrapper className="ml-2">
        <SortableList
          defaultItemList={segmentsRight}
          handle={"." + DRAG_HANDLE}
          cols={1}
          flexBox
        >
          {segmentsRight.map(segment =>
            segment.type === "pastNotes" ? (
              <PastNotesCard key={segment.name} segment={segment} />
            ) : (
              <VisitDetailSegmentBuilder
                key={segment.name}
                segment={segment}
                {...commonProps}
              />
            ),
          )}
        </SortableList>
      </ColumnWrapper>
    </div>
  );
};
