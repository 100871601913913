import { styled } from "@mui/material";

export const ImagePlaceholerStyled = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.primary.light2};
  position: relative;
`;

export const PacmanWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 16px 0;
  position: absolute;
`;
