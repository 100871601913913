import { useMemo } from "react";
import { useController } from "react-hook-form";

import { isNumber, round } from "lodash";

import { OptionIf } from "@components/Filters";
import { useMeasures } from "@hooks";

import { MeasureExtended } from "../../ProductsContext";

interface UseMeasureInputProps {
  measures?: MeasureExtended[];
  formIdx: number;
}

interface MeasureOption extends OptionIf {
  grams: number;
}
export const useMeasureInput = ({
  measures,
  formIdx,
}: UseMeasureInputProps) => {
  const { measureOptions } = useMeasures();

  const {
    field: { value: measureId, onChange: setMeasureId },
  } = useController({
    name: `products.${formIdx}.measureId`,
  });

  const {
    field: { value: grams, onChange: setGrams },
  } = useController({
    name: `products.${formIdx}.grams`,
  });

  const filteredMeasures = useMemo(
    () =>
      measures
        ?.map(m => {
          const option = measureOptions?.find(o => o.id === m.id.toString());
          return option
            ? {
                ...option,
                grams: m.grams,
              }
            : undefined;
        })
        .filter((o): o is MeasureOption => !!o) ?? [],
    [measures, measureOptions],
  );

  const selectedMeasure = useMemo(
    () => filteredMeasures.find(({ id }) => id === measureId?.toString()),
    [measureId, filteredMeasures],
  );

  const measureGrams = useMemo(
    () => measures?.find(m => m.id === measureId)?.grams,
    [measures, measureId],
  );

  const amount = useMemo(
    () => round(grams / (measureGrams || 1), 1),
    [grams, measureGrams],
  );

  const handleAmountChange = (value?: number | null) => {
    if (isNumber(value)) {
      const roundedGrams = round(value * (measureGrams || 0));
      setGrams(roundedGrams);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setGrams(0);
    }
  };

  return {
    measureId,
    setMeasureId,
    grams,
    amount,
    filteredMeasures,
    selectedMeasure,
    handleAmountChange,
    handleBlur,
  };
};
