import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Chat = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="14" height="15" viewBox="0 0 14 15" fill="none">
      <path
        d="M6.76258 14.2667C6.60964 14.2662 6.46299 14.2056 6.35424 14.0981L4.42925 12.1667H1.98333C1.45732 12.1667 0.95285 11.9577 0.580903 11.5858C0.208956 11.2138 0 10.7093 0 10.1833V2.48334C0 1.95733 0.208956 1.45286 0.580903 1.08091C0.95285 0.708965 1.45732 0.5 1.98333 0.5H11.55C12.076 0.5 12.5805 0.708965 12.9524 1.08091C13.3244 1.45286 13.5333 1.95733 13.5333 2.48334V10.1833C13.5333 10.7093 13.3244 11.2138 12.9524 11.5858C12.5805 11.9577 12.076 12.1667 11.55 12.1667H9.4325L7.14641 14.1273C7.04066 14.2174 6.90621 14.2668 6.76725 14.2667H6.76258ZM4.67074 11C4.82369 11.0005 4.97034 11.061 5.07908 11.1686L6.79291 12.89L8.83458 11.14C8.94024 11.0496 9.07471 11 9.21375 11H11.5471C11.7637 11 11.9714 10.914 12.1245 10.7608C12.2777 10.6076 12.3637 10.3999 12.3637 10.1833V2.48334C12.3637 2.37609 12.3426 2.2699 12.3016 2.17082C12.2605 2.07173 12.2004 1.98171 12.1245 1.90587C12.0487 1.83004 11.9587 1.76988 11.8596 1.72884C11.7605 1.6878 11.6543 1.66667 11.5471 1.66667H1.98041C1.87316 1.66667 1.76697 1.6878 1.66789 1.72884C1.56881 1.76988 1.47878 1.83004 1.40294 1.90587C1.32711 1.98171 1.26695 2.07173 1.22591 2.17082C1.18487 2.2699 1.16375 2.37609 1.16375 2.48334V10.1833C1.16375 10.3999 1.24979 10.6076 1.40294 10.7608C1.5561 10.914 1.76382 11 1.98041 11H4.67074Z"
        fill="black"
        fillOpacity="0.87"
      />
      <path
        d="M4.60332 8.07531C4.53418 8.07764 4.46528 8.06603 4.40072 8.04118C4.33616 8.01632 4.27726 7.97873 4.22753 7.93065C4.1778 7.88256 4.13825 7.82496 4.11124 7.76127C4.08423 7.69759 4.07031 7.62911 4.07031 7.55994C4.07031 7.49076 4.08423 7.42229 4.11124 7.3586C4.13825 7.29491 4.1778 7.23731 4.22753 7.18923C4.27726 7.14114 4.33616 7.10355 4.40072 7.0787C4.46528 7.05384 4.53418 7.04224 4.60332 7.04456H7.90907C8.04276 7.04906 8.16946 7.10533 8.26244 7.20149C8.35542 7.29765 8.4074 7.42618 8.4074 7.55994C8.4074 7.6937 8.35542 7.82222 8.26244 7.91838C8.16946 8.01454 8.04276 8.07081 7.90907 8.07531H4.60332ZM4.60332 5.74198C4.53418 5.74431 4.46528 5.7327 4.40072 5.70784C4.33616 5.68299 4.27726 5.6454 4.22753 5.59732C4.1778 5.54923 4.13825 5.49163 4.11124 5.42794C4.08423 5.36425 4.07031 5.29578 4.07031 5.2266C4.07031 5.15743 4.08423 5.08896 4.11124 5.02527C4.13825 4.96158 4.1778 4.90398 4.22753 4.85589C4.27726 4.80781 4.33616 4.77022 4.40072 4.74536C4.46528 4.72051 4.53418 4.7089 4.60332 4.71123H9.46424C9.53338 4.7089 9.60228 4.72051 9.66683 4.74536C9.73139 4.77022 9.79029 4.80781 9.84003 4.85589C9.88976 4.90398 9.92931 4.96158 9.95632 5.02527C9.98333 5.08896 9.99724 5.15743 9.99724 5.2266C9.99724 5.29578 9.98333 5.36425 9.95632 5.42794C9.92931 5.49163 9.88976 5.54923 9.84003 5.59732C9.79029 5.6454 9.73139 5.68299 9.66683 5.70784C9.60228 5.7327 9.53338 5.74431 9.46424 5.74198H4.60332Z"
        fill="black"
        fillOpacity="0.87"
      />
    </SvgIcon>
  );
};
