import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export async function removeFoodGroupFromVisit(
  scheduleId: number,
  foodGroupId: number,
): Promise<ApiResponse<void>> {
  return fetchData(
    `/dietitian/schedules/${scheduleId}/visit/food-group/${foodGroupId}`,
    APIMethods.DELETE,
  );
}
