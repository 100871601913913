import { NutrientsList } from "@components/Nutrients2/NutrientsList";
import NutrientBox from "@components/Nutrients2/NutrientBox";
import { TabPanel } from "@components/Alloweat/Tabs/TabPanel";
import { useCategoriesNutrientsFetchQuery } from "@hooks/queries/dictionaries";
import { useVisibleNutrients } from "@context/VisibleNutrientsContext";
import { TabPanelProps } from "@mui/lab/TabPanel/TabPanel";
import { DietitianNutrientTabValue } from "@components/Nutrients2/PatientNutrientsTabs";
import { NutrientValueType } from "@components/Nutrients2/Types";

type Props = {
  values: NutrientValueType[];
} & Omit<TabPanelProps, "value">;

const DietitianNutrientsTabPanel = ({ values, ...props }: Props) => {
  const { nutrients: visibleNutrients } = useVisibleNutrients();
  const { data: dataNutrientsCategories } = useCategoriesNutrientsFetchQuery();

  if (
    !visibleNutrients ||
    visibleNutrients.length === 0 ||
    !dataNutrientsCategories
  ) {
    return null;
  }

  return (
    <TabPanel {...props} value={DietitianNutrientTabValue}>
      <NutrientsList>
        {visibleNutrients.map(visibleNutrient => {
          const value = values.find(
            value => value.id === parseInt(visibleNutrient),
          );
          const nutrient = dataNutrientsCategories
            .map(c => c.nutrients)
            .flat()
            .find(n => n.id === parseInt(visibleNutrient));
          if (!nutrient) {
            return null;
          }
          return (
            <NutrientBox key={nutrient.id} nutrient={nutrient} value={value} />
          );
        })}
      </NutrientsList>
    </TabPanel>
  );
};

export default DietitianNutrientsTabPanel;
