import { ListTabs } from "@hooks";
import { Macros, MicrosFilters } from "@utils/macros";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

export const useProgramsFiltersForm = () => {
  const form = useForm<ProgramsFiltersFormInput>();
  const [init, setInit] = useState(true);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (init) {
      form.reset({
        tabs: ListTabs.ALL,
        page: 1,
        nutrients: DEFAULT_PROGRAMS_MACROS_RANGE,
        ...convertSearchParamsToObj(searchParams),
      });
      setInit(false);
    }
  }, [init]);

  return form;
};

export interface ProgramsFiltersFormInput {
  query?: string;
  tags?: string[];
  nutrients?: { [id: string]: [number, number] };
  tabs?: ListTabs;
  page?: number;
  perPage?: number;
  days?: string;
  typeDays?: string[];
  authorId?: string;
  numberOfDietMeals?: string[];
}

export const convertSearchParamsToObj = (
  searchParams: URLSearchParams,
): ProgramsFiltersFormInput => {
  const result: ProgramsFiltersFormInput = { nutrients: {} };

  result.tags = searchParams.get("tags")?.split(",") ?? [];
  result.authorId = searchParams.get("authorId") ?? undefined;
  result.days = searchParams.get("days") ?? undefined;
  result.typeDays = searchParams.get("typeDays")?.split(",") ?? [];
  result.numberOfDietMeals =
    searchParams.get("numberOfDietMeals")?.split(",") ?? [];
  result.query = searchParams.get("query") ?? "";
  result.page = searchParams.get("page")
    ? Number(searchParams.get("page"))
    : undefined;
  result.perPage = searchParams.get("perPage")
    ? Number(searchParams.get("perPage"))
    : undefined;

  searchParams.forEach((value, key) => {
    if (key.startsWith("nutrients")) {
      const nutrients = value.split(",");

      result.nutrients = { ...DEFAULT_PROGRAMS_MACROS_RANGE };

      nutrients.forEach(nutrientString => {
        const [nutrientId, from, to] = nutrientString.split("|");

        if (nutrientId) {
          if (!result.nutrients) result.nutrients = {};

          if (!result.nutrients[nutrientId])
            result.nutrients[nutrientId] = [0, 0];

          const array: [number, number] = [Number(from || 0), Number(to || 0)];
          result.nutrients[nutrientId] = array;
        }
      });
    }

    if (key.startsWith("owner")) result.tabs = value as ListTabs;
    if (key.startsWith(ListTabs.LATEST))
      result.tabs = ListTabs.LATEST as ListTabs;
    if (key.startsWith(ListTabs.FAVORITE))
      result.tabs = ListTabs.FAVORITE as ListTabs;
    if (key.startsWith(ListTabs.UNVERIFIED))
      result.tabs = ListTabs.UNVERIFIED as ListTabs;
  });

  if (Object.keys(result.nutrients!).length === 0) delete result.nutrients;

  return result;
};

export const DEFAULT_PROGRAMS_MACROS_RANGE: { [id: string]: [number, number] } =
  {
    [Macros.ENERGY]: [0, 4500],
    [Macros.PROTEIN]: [0, 200],
    [Macros.FATS]: [0, 200],
    [Macros.CARBS]: [0, 800],
    [MicrosFilters.SATURATED_FAT]: [0, 30],
    [MicrosFilters.CHOLESTEROL]: [0, 500],
    [MicrosFilters.DIETARY_FIBER]: [0, 50],
    [MicrosFilters.SODIUM]: [0, 2500],
    [MicrosFilters.POTASSIUM]: [0, 4000],
    [MicrosFilters.CALCIUM]: [0, 1300],
    [MicrosFilters.PHOSPHORUS]: [0, 1500],
    [MicrosFilters.MAGNESIUM]: [0, 500],
    [MicrosFilters.IRON]: [0, 30],
    [MicrosFilters.TOTAL_FOLATE]: [0, 600],
    [MicrosFilters.KOBALAMINA]: [0, 3],
    [MicrosFilters.GLYCEMIC_LOAD]: [0, 200],
    [MicrosFilters.CARBOHYDRATE_EXCHANGERS]: [0, 25],
  };
