import { Typography, styled } from "@mui/material";
import { CLIENT_PROFILE_EVENT_STATUS } from "@utils";

interface EventTypeWrapperWrapperProps {
  status?: CLIENT_PROFILE_EVENT_STATUS;
}

export const EventTypeWrapper = styled("div")<EventTypeWrapperWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  ${({ status, theme }) => {
    switch (status) {
      case CLIENT_PROFILE_EVENT_STATUS.CREATED:
        return `
          background: ${theme.colors.survey.yellow.light}; 
          color: ${theme.colors.survey.yellow.dark}; 
        `;
      case CLIENT_PROFILE_EVENT_STATUS.ACTIVATED:
        return `
            background: ${theme.palette.warning.light}; 
            color: ${theme.palette.warning.main}; 
          `;
      case CLIENT_PROFILE_EVENT_STATUS.DEACTIVATED:
        return `
          background: ${theme.colors.neutral.light[200]}; 
          color: ${theme.colors.neutral.dark[700]}; 
      `;
      case CLIENT_PROFILE_EVENT_STATUS.SURVEY_SENT:
        return `
          background: ${theme.palette.info.light}; 
          color: ${theme.palette.info.main}; 
      `;
      case CLIENT_PROFILE_EVENT_STATUS.SURVEY_RESPONDED:
        return `
          background: ${theme.palette.info.light}; 
          color: ${theme.palette.info.main}; 
      `;
      case CLIENT_PROFILE_EVENT_STATUS.VISIT_CREATED:
        return `
          background: ${theme.palette.success.light}; 
          color: ${theme.palette.success.main}; 
      `;
      case CLIENT_PROFILE_EVENT_STATUS.SUBSCRIPTION_ACTIVATED:
        return `
          background: ${theme.colors.survey.green.light}; 
          color: ${theme.colors.survey.green.dark}; 
      `;
      case CLIENT_PROFILE_EVENT_STATUS.PROGRAM_CREATED:
        return `
          background: ${theme.palette.info.light};  
          color: ${theme.palette.primary.dark}; 
      `;
      case CLIENT_PROFILE_EVENT_STATUS.MOBILE_APP_ACTIVATED:
        return `
          background: ${theme.colors.survey.pink.light};  
          color: ${theme.colors.survey.pink.dark}; 
      `;
      case CLIENT_PROFILE_EVENT_STATUS.MOBILE_APP_DEACTIVATED:
        return `
          background: ${theme.colors.neutral.light[200]}; 
          color: ${theme.colors.neutral.dark[700]};  
      `;
      default:
        return `
        width: 2.5rem; 
        height: 2.5rem;
      `;
    }
  }};
`;

export const EventName = styled("p")`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
  font-weight: 600;
  line-height: 1.25rem;
`;

export const EventTime = styled(Typography)`
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;
