import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";
import { Currencies } from "@utils/scheduleEvent";

import { LangDto } from "../common";

export const createClinicProduct = async (
  payload: CreateClinicProductRequest,
) => {
  return await fetchData(
    "/dietitian/clinic/products",
    APIMethods.POST,
    payload,
  );
};

export interface CreateClinicProductRequest {
  isActive: boolean;
  prices: PriceDto[];
  translations: TranslationDto[];
}

interface PriceDto {
  amount: number;
  currency: Currencies;
}

interface TranslationDto {
  lang: LangDto;
  name: string;
}
