import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Notebook = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      style={{ fill: "none" }}
      width="14"
      height="15"
      viewBox="0 0 14 15"
    >
      <path
        d="M13.125 10.375C13.0885 10.9766 12.7969 11.3867 12.25 11.6055V13.4375H12.4688C12.8698 13.474 13.0885 13.6927 13.125 14.0938C13.0885 14.4948 12.8698 14.7135 12.4688 14.75H3.0625C2.44271 14.7318 1.92318 14.5221 1.50391 14.1211C1.10286 13.7018 0.893229 13.1823 0.875 12.5625V2.9375C0.893229 2.31771 1.10286 1.79818 1.50391 1.37891C1.92318 0.977865 2.44271 0.768229 3.0625 0.75H11.8125C12.1771 0.768229 12.487 0.895833 12.7422 1.13281C12.9792 1.38802 13.1068 1.69792 13.125 2.0625V10.375ZM10.9375 13.4375V11.6875H3.0625C2.80729 11.6875 2.59766 11.7695 2.43359 11.9336C2.26953 12.0977 2.1875 12.3073 2.1875 12.5625C2.1875 12.8177 2.26953 13.0273 2.43359 13.1914C2.59766 13.3555 2.80729 13.4375 3.0625 13.4375H10.9375ZM11.8125 10.375V2.0625H3.0625C2.80729 2.0625 2.59766 2.14453 2.43359 2.30859C2.26953 2.47266 2.1875 2.68229 2.1875 2.9375V10.5664C2.46094 10.4388 2.7526 10.375 3.0625 10.375H11.8125ZM5.03125 5.125C4.63021 5.08854 4.41146 4.86979 4.375 4.46875C4.41146 4.06771 4.63021 3.84896 5.03125 3.8125H9.84375C10.2448 3.84896 10.4635 4.06771 10.5 4.46875C10.4635 4.86979 10.2448 5.08854 9.84375 5.125H5.03125ZM5.03125 7.3125C4.63021 7.27604 4.41146 7.05729 4.375 6.65625C4.41146 6.25521 4.63021 6.03646 5.03125 6H9.84375C10.2448 6.03646 10.4635 6.25521 10.5 6.65625C10.4635 7.05729 10.2448 7.27604 9.84375 7.3125H5.03125Z"
        fill="#727272"
      />
    </SvgIcon>
  );
};
