import { useQuery } from "@tanstack/react-query";

import { NutrientDto } from "@client";
import { fetchCreatorDietNutrients } from "@client/diets/creator";
import { QueryOptionsTyped } from "@hooks/queries/types";
import { ApiResponse } from "@typeDefinitions";

export const fetchCreatorDietNutrientsQueryKey =
  "fetchCreatorDietNutrientsQueryKey";

export const useFetchCreatorDietNutrientsQuery = (
  dietId: string,
  options?: QueryOptionsTyped<ApiResponse<NutrientDto[]>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchCreatorDietNutrientsQueryKey, dietId],
    () => fetchCreatorDietNutrients(dietId),
    options,
  );

  return {
    nutrients: data?.data,
    ...rest,
  };
};
