import { Typography, useTheme } from "@mui/material";
import { Subtitle } from "@views/dietician/ClientProfile/ClientProfile.styled";
import { ReactNode } from "react";

interface ClientProfileCardItemProps {
  title?: string;
  subtitle?: ReactNode;
  extraEl?: ReactNode;
}

export const ClientProfileCardItem = ({
  title,
  subtitle,
  extraEl,
}: ClientProfileCardItemProps) => {
  const { colors } = useTheme();
  return (
    <div className="grid gap-1">
      <Typography variant="body2" color={colors.neutral.medium[800]}>
        {title}
      </Typography>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {extraEl && <>{extraEl}</>}
    </div>
  );
};
