import { useEffect, useState } from "react";
import { QuestionFormInputs } from "../Question/useQuestionForm";
import { useForm } from "react-hook-form";
import { QuestionTextAreaDto, QuestionTextDto } from "@client/surveys";

export const useTextConfigForm = (values?: TextConfigFormInputs) => {
  const [init, setInit] = useState(true);
  const form = useForm<TextConfigFormInputs>({
    defaultValues: {
      title: "",
      description: null,
      required: false,
      placeholder: null,
    },
  });

  useEffect(() => {
    if (init && values) {
      form.reset(values);
      setInit(false);
    }
  }, [values]);

  return form;
};

export interface TextConfigFormInputs extends QuestionFormInputs {
  placeholder: string | null;
}

export const mapTextConfigForm = ({
  title,
  description,
  config,
}: QuestionTextDto | QuestionTextAreaDto): TextConfigFormInputs => {
  return {
    title,
    description,
    placeholder: config.placeholder,
    required: config.required,
  };
};

export const mapTextConfigRequest = (
  { description, placeholder, required, title }: TextConfigFormInputs,
  { id, type }: QuestionTextDto | QuestionTextAreaDto,
): QuestionTextDto | QuestionTextAreaDto => {
  return {
    id,
    title,
    description,
    config: { placeholder, required },
    type,
  };
};
