import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export async function swapProgramDays(
  programId: number,
  payload: ProgramDaySwapPayload,
): Promise<void> {
  await fetchData(
    `/dietitian/programs/${programId}/schedule/meals/replace`,
    APIMethods.PUT,
    payload,
  );
}

export interface ProgramDaySwapPayload {
  mealsId: number[];
}
