import { useAppParams } from "@hooks";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { UpdateCreatorMealTagsRequest } from "@client/meals";
import { useDebounce } from "react-use";
import { useUpdateCreatorMealTagsMutation } from "@hooks/queries/meals/useUpdateCreatorMealTagsMutation";
import { MealTagsInputs } from "@views/dietician/MealEdit/forms";

export const MealTagsFormWatch = () => {
  const { mealId } = useAppParams();
  const values = useWatch<MealTagsInputs>();
  const {
    handleSubmit,
    formState: { isDirty },
  } = useFormContext<MealTagsInputs>();

  const { mutate, isLoading } = useUpdateCreatorMealTagsMutation(mealId);
  const [queued, setQueued] = useState(false);

  const createRequest = (
    data: MealTagsInputs,
  ): UpdateCreatorMealTagsRequest => {
    return {
      tags: data.tags.map(t => Number(t)),
    };
  };

  const [isDirtyLatch, setIsDirtyLatch] = useState(isDirty);

  const update = () => {
    setQueued(false);
    handleSubmit(
      data => mutate(createRequest(data)),
      err => console.log(err),
    )();
  };

  useDebounce(
    () => {
      if (isLoading) {
        setQueued(true);
      }

      if ((isDirty || isDirtyLatch) && values && !isLoading) {
        setIsDirtyLatch(true);
        update();
      }
    },
    1000,
    [JSON.stringify(values)],
  );

  useEffect(() => {
    if (queued && !isLoading) {
      update();
    }
  }, [queued, isLoading]);

  return <></>;
};
