import { Box, styled } from "@mui/material";
import MacroCircularProgress, {
  MacroCircularProgressProps,
} from "@components/MacroCircularProgress/MacroCircularProgress";
import { ExclamationMarkInCircleIcon } from "@icons/index";
import { useAppTranslation } from "@hooks";
import { useMemo } from "react";

type Props = MacroCircularProgressProps;

const MacroCircularProgressChart = (props: Props) => {
  const { t } = useAppTranslation();

  const show = showExclamationMark(props.type, props.percent);

  const label = useMemo(() => {
    switch (props.type) {
      case "kcal":
        return t("common.calories");
      case "protein":
        return t("common.proteins");
      case "fat":
        return t("common.fats");
      case "carb":
        return t("common.carbs");
    }
  }, [t, props.type]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap="12px">
      <MacroCircularProgress {...props} />
      <Box display="flex" flexDirection="row" gap="2px" alignItems="center">
        {show && (
          <ExclamationMarkInCircleIcon
            color="#D44450"
            fontSize="18px"
            style={{ margin: "auto" }}
          />
        )}
        <Label>{label}</Label>
      </Box>
    </Box>
  );
};

const showExclamationMark = (
  type: Props["type"],
  percent: Props["percent"],
) => {
  if (type === "kcal") {
    return percent >= 110 || percent <= 90;
  }

  return percent >= 105 || percent <= 95;
};

const Label = styled("span")`
  font: var(--font-figtree-medium);
  font-size: 12px;
  line-height: 18px;
  color: #2f303a;
`;

export default MacroCircularProgressChart;
