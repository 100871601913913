import { useMemo } from "react";

import { Typography } from "@mui/material";
import sanitizeHtml from "sanitize-html";

import { useAppTranslation } from "@hooks";

interface RecipeNotesProps {
  comment: string | null;
  commentEn: string | null;
}

export const RecipeNotes = ({ comment, commentEn }: RecipeNotesProps) => {
  const { isPolishChosen, t } = useAppTranslation();

  const sanitized = useMemo(() => {
    if (isPolishChosen) return sanitizeHtml(comment ?? "");
    else return sanitizeHtml(commentEn ?? "");
  }, [isPolishChosen, commentEn, comment]);

  if (!sanitized) return null;

  return (
    <>
      <Typography variant="h6">{t("recipes.notes")}</Typography>
      <Typography
        className="px-4"
        variant="body1"
        dangerouslySetInnerHTML={{
          __html: sanitized.replaceAll("\n", "<br />"),
        }}
      />
    </>
  );
};
