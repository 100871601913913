import { useParams } from "react-router-dom";
import { useMemo } from "react";

export type ProgramParams = {
  programId: string;
};

export const useProgramSummaryParams = () => {
  const { programId } = useParams<ProgramParams>();
  const programIdParsed = useMemo(() => parseInt(programId ?? ""), [programId]);
  return { programId: programIdParsed };
};
