import { Box, Stack } from "@mui/material";
import { ButtonOwnProps } from "@mui/material/Button/Button";
import dayjs from "dayjs";
import {
  BoxWrapper,
  TodayButton,
  Title,
  CustomButton,
  ButtonList,
} from "@views/dietician/PatientMonitoring2/components/CalendarType/CalendarType.styled";
import {
  Type,
  useMonitoring,
} from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import { useAppTranslation } from "@hooks";
import { CalendarMonitoring } from "@assets/icons/Monitoring";

const CalendarType = () => {
  const { type, selectedDays } = useMonitoring();
  const { t } = useAppTranslation();

  const getVariantButton = (buttonType: Type): ButtonOwnProps["variant"] =>
    buttonType === type.type ? "contained" : undefined;

  const handleOnClick = (variant: "day" | "week" | "month") => {
    type.setType(variant);
    selectedDays.setSelectDays({
      from: dayjs().startOf(variant),
      to: dayjs().endOf(variant),
    });
  };

  return (
    <BoxWrapper>
      <Stack direction="column">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignContent="center"
        >
          <Title>{t("monitoring2.calendar.title")}</Title>
          <Box display="flex" alignItems="center">
            <TodayButton
              onClick={() => handleOnClick("day")}
              variant="outlined"
              color="primary"
              size="small"
            >
              {t("monitoring2.calendar.today_button")}
            </TodayButton>
          </Box>
        </Stack>
        <ButtonList direction="row" spacing="4px">
          <CustomButton
            variant={getVariantButton("day")}
            onClick={() => handleOnClick("day")}
          >
            D
          </CustomButton>
          <CustomButton
            variant={getVariantButton("week")}
            onClick={() => handleOnClick("week")}
          >
            T
          </CustomButton>
          <CustomButton
            variant={getVariantButton("month")}
            onClick={() => handleOnClick("month")}
          >
            M
          </CustomButton>
          <CustomButton
            variant={getVariantButton("custom")}
            onClick={() => undefined}
          >
            <CalendarMonitoring />
          </CustomButton>
        </ButtonList>
      </Stack>
    </BoxWrapper>
  );
};

export default CalendarType;
