import { Card, DialogActions, styled } from "@mui/material";

export const TooltipWrapper = styled(Card)`
  min-width: 32rem;
  width: 100%;

  & > h2 {
    text-align: center;
  }
`;

export const StyledDialogActions = styled(DialogActions)`
  justify-content: space-between;
  padding: 0 1.5rem 1.5rem;
`;

export const StyledDialogContentText = styled("div")`
  margin: 0;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-weight: 400;
  line-height: 1.5;
  font-size: 1rem;
  white-space: pre-line;
  color: ${({ theme }) => theme.palette.text.primary};
`;
