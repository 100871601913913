import { useCallback, useMemo } from "react";

import { useAppTranslation } from "@hooks";
import { useFetchTagCategoriesQuery } from "./queries";
import { FetchTagCategoriesCategory, FetchTagCategoriesTag } from "@client";

export function useTags(): UseTagsReturn {
  const { tagCategories: data } = useFetchTagCategoriesQuery();
  const { isPolishChosen } = useAppTranslation();

  const tags: TagsDict = useMemo(
    () =>
      Object.fromEntries(
        data?.flatMap(c => categoryToTagsDictEntries(c, isPolishChosen)) ?? [],
      ),
    [data, isPolishChosen],
  );

  const tagCategories: TagCategoriesDict = useMemo(
    () =>
      Object.fromEntries(
        data?.map(c => [
          c.id,
          {
            id: c.id,
            name: (isPolishChosen ? c.name : c.nameEn) ?? c.id.toString(),
            tags: c.tags.map(t => dtoToTag(t, isPolishChosen)),
          },
        ]) ?? [],
      ),
    [data, isPolishChosen],
  );

  const getTagCategory = useCallback(
    (id: number): TagCategory | undefined => {
      if (tagCategories && tagCategories[id]) return tagCategories[id];
      return;
    },
    [tagCategories],
  );

  const getTag = useCallback(
    (id: number): Tag | undefined => {
      if (tags && tags[id]) return tags[id];
      return;
    },
    [tags],
  );

  return { tags, tagCategories, getTagCategory, getTag };
}

interface Tag {
  id: number;
  name: string;
}

interface TagsDict {
  [id: number]: Tag;
}

export interface TagCategoriesDict {
  [id: number]: TagCategory;
}

export interface TagCategory {
  id: number;
  name: string;
  tags: Tag[];
}

interface UseTagsReturn {
  tags: TagsDict;
  tagCategories: TagCategoriesDict;
  getTagCategory: (id: number) => TagCategory | undefined;
  getTag: (id: number) => Tag | undefined;
}

const dtoToTag = (t: FetchTagCategoriesTag, polish: boolean) => ({
  id: t.id,
  name:
    (polish ? t.namePl ?? t.nameEn : t.nameEn ?? t.namePl) ?? t.id.toString(),
});

const categoryToTagsDictEntries = (
  c: FetchTagCategoriesCategory,
  polish: boolean,
) => c.tags.map(t => [t.id, dtoToTag(t, polish)]);
