import {
  ActionsDto,
  actionsSchema,
  LangDto,
  NutrientDto,
  nutrientSchema,
  ProgramDayNutrientDto,
  TagDto,
} from "@client";
import * as yup from "yup";

const mealSchema = yup.object().shape({
  id: yup.string().required(),
  title: yup.string().ensure(),
  titleEn: yup.string().ensure(),
  nutrients: yup.array().of(nutrientSchema).default([]),
});

const dietSchema = yup.object().shape({
  id: yup.number().required(),
  title: yup.string().ensure(),
  titleEn: yup.string().ensure(),
  nutrients: yup.array().of(nutrientSchema).required(),
  mealsCount: yup.number().required(),
  meals: yup.array().of(mealSchema).default([]),
});

const daySchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  nameEn: yup.string().ensure(),
  frequency: yup.array().of(yup.number().required()).required(),
  diet: dietSchema.nullable().default(null),
  targetNutrients: yup
    .object()
    .shape({
      norm: yup
        .object()
        .shape({
          id: yup.number().required(),
        })
        .nullable()
        .default(null),
      nutrients: yup
        .array()
        .of(
          yup.object().shape({
            id: yup.number().required(),
            value: yup.number().required(),
            visible: yup.boolean().required(),
          }),
        )
        .required(),
    })
    .required(),
});

export const fetchProgramShape = {
  id: yup.number().required(),
  name: yup.string().ensure(),
  nameEn: yup.string().ensure(),
  showNutrients: yup.array().of(yup.number().required()).required(),
  showCals: yup.boolean().required(),
  showMacros: yup.boolean().required(),
  showMicros: yup.boolean().required(),
  showMeasurements: yup.boolean().required(),
  hasDiets: yup.boolean().required(),
  hasTasks: yup.boolean().required(),
  hasDiary: yup.boolean().required(),
  hasChat: yup.boolean().required(),
  hasEducation: yup.boolean().required(),
  hasMacros: yup.boolean().required(),
  hasMonitoring: yup.boolean().required(),
  hasNewsfeed: yup.boolean().required(),
  hasSubstitutes: yup.boolean().required(),
  hasShoppingList: yup.boolean().required(),
  sections: yup.array().of(yup.number().required()).required(),
  langId: yup.string().oneOf(["pl", "en"]).required(),
  days: yup.array().of(daySchema).required(),
  reviewed: yup.boolean().required(),
  actions: actionsSchema.required(),
  startDate: yup.string().required(),
};

export interface GenericFetchProgramResponse {
  id: number;
  name: string;
  nameEn: string;
  showNutrients: number[];
  showCals: boolean;
  showMacros: boolean;
  showMicros: boolean;
  showMeasurements: boolean;
  hasDiets: boolean;
  hasTasks: boolean;
  hasDiary: boolean;
  hasChat: boolean;
  hasEducation: boolean;
  hasMacros: boolean;
  hasMonitoring: boolean;
  hasNewsfeed: boolean;
  hasSubstitutes: boolean;
  hasShoppingList: boolean;
  sections: number[];
  langId: "pl" | "en";
  days: DayDto[];
  reviewed: boolean;
  actions: ActionsDto;
  startDate: string;
}

export interface DayDto {
  id: number;
  name: string;
  nameEn: string;
  frequency: number[];
  diet: DayDietDto | null;
  targetNutrients: TargetNutrients;
}

export interface TargetNutrients {
  nutrients: ProgramDayNutrientDto[];
  norm: { id: number } | null;
}

interface DayDietDto {
  id: number;
  title: string;
  titleEn: string;
  meals: ProgramMealDto[];
  mealsCount: number;
  nutrients: NutrientDto[];
}

export interface ProgramMealDto {
  id: string;
  title: string;
  titleEn: string;
  nutrients: NutrientDto[];
}

export interface AuthorDto {
  firstName: string;
  lastName: string;
  id: number;
}

interface TranslationDto {
  lang: LangDto;
  name: string;
}

interface ProgramDayDietDto {
  id: number;
  mealsCount: number;
  nutrients: NutrientDto[];
  tags: TagExtendedDto[];
  translations: TranslationDto[];
}

export interface ProgramDayDto {
  diet: ProgramDayDietDto | null;
  frequency: number[];
  id: number;
  translations: TranslationDto[];
}

export interface TagExtendedDto extends TagDto {
  clinic: ClinicDto;
  name: string;
  nameEn: string;
  tagCategory: TagCategoryDto;
  translations: TagsTranslationDto[];
}

export interface TagsTranslationDto {
  langId: "pl" | "en";
  name: string | null;
}

interface TagCategoryDto {
  id: number | null;
}

interface ClinicDto {
  id: number | null;
}

export const authorSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  id: yup.number().required(),
});

export const tagCategorySchema = yup.object().shape({
  id: yup.number().nullable().default(null),
});

export const tagsTranslationSchema = yup.object().shape({
  langId: yup.mixed<LangDto>().oneOf(Object.values(LangDto)).required(),
  name: yup.string().ensure(),
});

export const clinicIdSchema = yup
  .object()
  .shape({
    id: yup.number().nullable().default(null),
  })
  .required();

export const tagExtendedSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  nameEn: yup.string().ensure(),
  tagCategory: tagCategorySchema.required(),
  clinic: clinicIdSchema,
  translations: yup.array().of(tagsTranslationSchema).required().min(1),
});

export const translationSchema = yup.object().shape({
  lang: yup.mixed<LangDto>().oneOf(Object.values(LangDto)).required(),
  name: yup.string().ensure(),
});

const tagSchemaDto = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  nameEn: yup.string().required(),
  tagCategory: tagCategorySchema.required(),
  clinic: clinicIdSchema,
  translations: yup.array().of(tagsTranslationSchema).required().min(1),
});

const programDayDietSchema = yup.object().shape({
  id: yup.number().required(),
  mealsCount: yup.number().required(),
  nutrients: yup.array().of(nutrientSchema).required(),
  tags: yup.array().of(tagSchemaDto).required(),
  translations: yup.array().of(translationSchema).required().min(1),
});

export const programDaySchema = yup.object().shape({
  diet: programDayDietSchema.nullable().default(null),
  frequency: yup.array().of(yup.number().required()).required(),
  id: yup.number().required(),
  translations: yup.array().of(translationSchema).required().min(1),
});
