import { useMutation } from "@tanstack/react-query";

import { IdDto } from "@client";
import { ApiResponse } from "@typeDefinitions";
import { postCreatorMealClone } from "@client/meals";

import { MutationOptions } from "../types";

export const usePostCreatorMealCloneMutation = (options?: MutationOptions) => {
  const mutation = useMutation<ApiResponse<IdDto>, unknown, string>(
    mealId => postCreatorMealClone(mealId),
    options,
  );

  return mutation;
};
