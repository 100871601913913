import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { deleteNote } from "@client/notes/deleteNote";
import { ApiEndpoints } from "@typeDefinitions";
import { useAppTranslation } from "@hooks/useAppTranslation";

export const useDeleteNoteMutation = () => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation<
    AxiosResponse,
    AxiosError,
    { id: string }
  >(data => deleteNote(data.id), {
    onSuccess: () => {
      queryClient.invalidateQueries([ApiEndpoints.AccountNotes]);

      toast.success(t("notes.deleteSuccess"));
    },
  });

  const handleDeleteNote = async (id: string) => {
    try {
      await mutateAsync({ id });
    } catch {
      toast.error(t("notes.deleteFailure"));
    }
  };

  return { handleDeleteNote };
};
