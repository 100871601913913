import { styled } from "@mui/material";

export const PatientProgramDayGrid = styled("div")`
  display: grid;
  gap: 2rem;
  padding: 0.5rem 1rem 2.5rem 1rem;
  width: 100%;
`;

interface WrapperSectionProps {
  isDesk?: boolean;
}

export const WrapperSection = styled("div")<WrapperSectionProps>`
  display: ${({ isDesk }) => (isDesk ? "grid" : "flex")};
  gap: 2rem;
`;
