import { Autocomplete, Popper, styled, TextField } from "@mui/material";

export const AutocompleteStyled = styled(Autocomplete)`
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallMax}px) {
    width: 270px;
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.medium}px) {
    width: 300px;
  }

  & .MuiInputBase-input {
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.colors.neutral.dark[800]};
  }

  input::placeholder {
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.neutral.dark[700]};
    line-height: 20px;
  }
` as typeof Autocomplete;

export const TagLabel = styled("span")`
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;

export const AutocompleteWrapper = styled("div")`
  display: flex;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallMax}px) {
    gap: 4px;
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.medium}px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const StyledPopper = styled(Popper)`
  & .MuiAutocomplete-groupUl {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  & .MuiAutocomplete-listbox > * {
    @media screen and (max-width: ${({ theme }) =>
        theme.breakpoints.values.smallMax}px) {
      width: 262px;
    }
    @media screen and (min-width: ${({ theme }) =>
        theme.breakpoints.values.medium}px) {
      width: 292px;
    }
  }
`;

export const TextFieldStyled = styled(TextField)`
  & .MuiFormHelperText-root {
    margin: unset;
  }
`;
