import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { Roles } from "@utils/settings";
import {
  DietitianFacilityDto,
  dietitianFacilitySchema,
  PhoneDto,
  phoneSchema,
} from "../common";
import * as yup from "yup";

export const fetchClinicDietitian = async (
  id: number,
): Promise<ApiResponse<ClinicDietitianResponse>> => {
  const response = await fetchData(
    `/dietitian/clinic/dietitians/${id}`,
    APIMethods.GET,
  );
  await fetchClinicDietitianSchema.validate(response);

  return response;
};

export interface ClinicDietitianResponse {
  id: number;
  active: boolean;
  firstName: string;
  lastName: string;
  email: string;
  roles: Roles[];
  avatar?: string;
  facility?: DietitianFacilityDto;
  clientsCount: number;
  phone: PhoneDto;
}

const clinicDietitianSchema = yup.object().shape({
  id: yup.number().required(),
  active: yup.boolean().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required(),
  roles: yup
    .array()
    .of(yup.mixed().oneOf(Object.values(Roles)))
    .required(),
  avatar: yup.string().nullable(),
  facility: dietitianFacilitySchema.nullable(),
  clientsCount: yup.number().required(),
  phone: phoneSchema.required(),
});

const fetchClinicDietitianSchema = yup.object().shape({
  data: clinicDietitianSchema.required(),
});
