import * as yup from "yup";

import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";

import { DictionaryNameDto, dictionaryNameSchema } from "./common";

export const fetchClientSources = async (): Promise<
  ApiResponse<SourceDto[]>
> => {
  const response = await fetchData(
    `/dietitian/patient/profile/dictionaries/sources`,
    APIMethods.GET,
  );
  await fetchClientSourcesSchema.validate(response);

  return response;
};

const sourceSchema = yup.object().shape({
  id: yup.number().required(),
  names: yup.array().of(dictionaryNameSchema).required(),
});

const fetchClientSourcesSchema = yup.object().shape({
  data: yup.array().of(sourceSchema).required(),
});

interface SourceDto {
  id: number;
  names: DictionaryNameDto[];
}
