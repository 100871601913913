import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";
import { Currencies, MEETING_MODE, MEETING_TYPE } from "@utils/scheduleEvent";

export const updateScheduleEvent = async (
  id: number,
  payload: UpdateScheduleEventRequest,
) => {
  await fetchData(`/dietitian/schedules/${id}`, APIMethods.PUT, payload);
};

export interface UpdateScheduleEventRequest {
  meetingTypeId: MEETING_TYPE;
  dietitianId: number;
  patientId: number;
  title: string;
  dateStart: string;
  dateEnd: string;
  isAllDay: number;
  additionalInfo: string | null;
  meetingModeId: MEETING_MODE | null;
  clinicFacilityId: number | null;
  clinicProductId: number | null;
  price: {
    value: number;
    currency: Currencies;
  } | null;
  notification: boolean | null;
}
