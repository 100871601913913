import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const fetchAccountUnreadNotifications = async (): Promise<
  ApiResponse<UnreadNotificationsDto>
> => {
  const response = await fetchData(
    "/dietitian/account/notifications/number-of-unread",
    APIMethods.GET,
  );
  return await fetchAccountUnreadNotificationsSchema.validate(response);
};

const unreadNotificationSchema = yup.object().shape({
  notifications: yup.number().required(),
});

const fetchAccountUnreadNotificationsSchema = yup.object().shape({
  data: unreadNotificationSchema.required(),
});

export interface UnreadNotificationsDto {
  notifications: number;
}
