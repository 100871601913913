import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";

export async function updateProgramDayVariant(
  programId: number,
  dayId: number,
  payload: ProgramDayVariantPayload,
): Promise<ApiResponse<any>> {
  return await fetchData(
    `/dietitian/programs/${programId}/schedule/days/${dayId}/meals/replace`,
    APIMethods.PUT,
    payload,
  );
}

export interface ProgramDayVariantPayload {
  id: number | null;
  program: { id: number };
  programDay: { id: number };
  date: string;
  meals: {
    dietMeal: {
      title: string;
      hour: string;
      description: string;
    };
    recipe: {
      id: number;
      title: string;
      foodRecipe?: {
        // if type = food
        food: {
          id: number;
        };
        grams: number;
        foodMeasure: {
          id: number;
        };
      }[];
    };
    servings: number;
  }[];
}
