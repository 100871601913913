import { IconButton, useMediaQuery, useTheme } from "@mui/material";

import { DuplicateFiles, Pen, TrashNew } from "@assets/icons";
import { DragHandle } from "@assets/icons/DesignSystem";
import cupcake from "@assets/img/productTile/cupcake.png";
import {
  ExpandableTags,
  mapUserChips,
  UserChips,
  UserTagType,
} from "@components/ExpandableChips";
import { MacrosText } from "@components/MacrosText";
import { SpecialTagIf, SpecialTags } from "@components/TagWithIcon";
import { ClientContextIf, Nutrient } from "@typeDefinitions";

import {
  DraggableButton,
  DraggableItemStyled,
  HandleWrapper,
  ImagePlaceholder,
  ImageWrapper,
  ImgBackground,
  ImgStyled,
  Name,
} from "./DraggableItem.styled";
import { MouseEventHandler, useMemo } from "react";
import { useDietUsedRecipes } from "@hooks/useDietUsedRecipes";

interface DraggableItemProps {
  name: string;
  nutrients: Nutrient[];
  tags: number[];
  tagsWithIcons: SpecialTagIf[];
  onDelete: () => void;
  onEdit?: () => void;
  imgUrl?: string;
  onClick?: () => void;
  onCopy?: () => void;
  clientContext?: ClientContextIf;
}
export const DraggableItem = ({
  name,
  nutrients,
  tags,
  tagsWithIcons,
  onDelete,
  onEdit,
  imgUrl,
  onClick,
  onCopy,
  clientContext,
}: DraggableItemProps) => {
  const {
    colors: { neutral },
  } = useTheme();
  const { breakpoints } = useTheme();
  const upMedium = useMediaQuery(`${breakpoints.up("medium")}`);
  const { isDoubledRecipe } = useDietUsedRecipes();

  const userTags = useMemo(
    () =>
      mapUserChips({
        already_used: isDoubledRecipe(name),
        disliked: clientContext?.hasUnliked,
        allergen: clientContext?.hasAllergens,
        liked: clientContext?.hasLiked,
      }),
    [isDoubledRecipe, clientContext],
  );

  const handleClick =
    (fn?: () => void): MouseEventHandler<HTMLButtonElement> =>
    e => {
      e.stopPropagation();
      fn?.();
    };

  if (upMedium)
    return (
      <DraggableItemStyled onClick={onClick}>
        <ImageWrapper>
          {imgUrl && <ImgStyled src={imgUrl} />}

          {!imgUrl && (
            <ImgBackground>
              <ImagePlaceholder src={cupcake} />
            </ImgBackground>
          )}

          <DraggableButton className="dragHandle">
            <DragHandle />
          </DraggableButton>
        </ImageWrapper>

        <div className="grid gap-[4px] flex-1">
          <Name>{name}</Name>

          <div className="flex gap-[8px]">
            <MacrosText nutrients={nutrients} />
            <SpecialTags tags={tagsWithIcons} />
          </div>
        </div>

        <div className="flex-1 flex gap-[8px]">
          <UserChips tags={userTags} />
          <ExpandableTags tags={tags} showCount={3} />
        </div>

        <div className="flex">
          {onCopy && (
            <IconButton size="small" onClick={handleClick(onCopy)}>
              <DuplicateFiles size="w-[16px] h-[16px]" />
            </IconButton>
          )}
          <IconButton size="small" onClick={handleClick(onEdit)}>
            <Pen size="w-[16px] h-[16px]" />
          </IconButton>
          <IconButton size="small" onClick={handleClick(onDelete)}>
            <TrashNew size="w-[16px] h-[16px]" />
          </IconButton>
        </div>
      </DraggableItemStyled>
    );

  return (
    <DraggableItemStyled onClick={onClick}>
      <HandleWrapper className="dragHandle">
        <DragHandle fill={neutral.dark[700]} />
      </HandleWrapper>

      <div className="grid gap-[4px] flex-1">
        <div className="grid gap-[4px] flex-1">
          <Name>{name}</Name>

          <div className="flex gap-[8px]">
            <MacrosText nutrients={nutrients} />
            <SpecialTags tags={tagsWithIcons} />
          </div>
        </div>

        <div className="flex-1 flex gap-[8px]">
          <UserChips tags={userTags} />
          <ExpandableTags tags={tags} showCount={3} />
        </div>
      </div>

      <div className="flex">
        {onCopy && (
          <IconButton size="small" onClick={handleClick(onCopy)}>
            <DuplicateFiles size="w-[16px] h-[16px]" />
          </IconButton>
        )}
        <IconButton size="small" onClick={handleClick(onEdit)}>
          <Pen size="w-[16px] h-[16px]" />
        </IconButton>
        <IconButton size="small" onClick={handleClick(onDelete)}>
          <TrashNew size="w-[16px] h-[16px]" />
        </IconButton>
      </div>
    </DraggableItemStyled>
  );
};
