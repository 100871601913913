import { useCallback } from "react";

import { useAppTranslation } from "@hooks";
import { useProductForm } from "./useProductsForm";
import { useFetchClinicProducts } from "@hooks/queries";
import {
  useCreateClinicProductMutation,
  useFetchClinicCurrencies,
  useUpdateClinicProductMutation,
} from "@hooks/queries/settings";
import { Currencies } from "@utils/scheduleEvent";
import { LangDto } from "@client";

import { InputAdornment, useTheme } from "@mui/material";
import { FormTextField } from "@components/FormTextField";
import { FormAutocomplete } from "@components/FormAutocomplete";
import { FormOutlinedInput } from "@components/FormOutlinedInput";
import { ModalWrapper } from "@components/ModalWrapper";

import { Divider, HeaderStyled } from "../AddOfficeModal/AddOfficeModal.styled";
import { CheckedIcon } from "@assets/icons";

interface ProductsModalProps {
  open: boolean;
  onClose: () => void;
  edit?: boolean;
  id?: number;
}

export const ProductsModal = ({
  open,
  onClose,
  edit = false,
  id,
}: ProductsModalProps) => {
  const { t } = useAppTranslation();
  const { palette } = useTheme();
  const { clinicProducts } = useFetchClinicProducts({ enabled: !!id && edit });
  const { currencies } = useFetchClinicCurrencies();

  const isPln = !!currencies?.find(c => c.currency === "PLN");
  const isEur = !!currencies?.find(c => c.currency === "EUR");
  const isUsd = !!currencies?.find(c => c.currency === "USD");
  const isGbp = !!currencies?.find(c => c.currency === "GBP");

  const { reset, control, handleSubmit } = useProductForm(
    clinicProducts?.find(product => product.id === id),
    isPln,
    isEur,
    isUsd,
    isGbp,
  );

  const closeWithReset = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  const { mutate: create, isLoading: isCreateLoading } =
    useCreateClinicProductMutation({ onSuccess: closeWithReset });
  const { mutate: update, isLoading: isUpdateLoading } =
    useUpdateClinicProductMutation(id ?? 0, { onSuccess: closeWithReset });

  const onSubmit = handleSubmit(
    d => {
      const prices = [];

      if (isPln)
        prices.push({ currency: Currencies.PLN, amount: parseFloat(d.pln) });

      if (isEur)
        prices.push({ currency: Currencies.EUR, amount: parseFloat(d.eur) });

      if (isGbp)
        prices.push({ currency: Currencies.GBP, amount: parseFloat(d.gbp) });

      if (isUsd)
        prices.push({ currency: Currencies.USD, amount: parseFloat(d.usd) });

      if (edit) {
        update({
          isActive: d.status === "active",
          prices,
          translations: [
            { lang: LangDto.PL, name: d.name },
            { lang: LangDto.EN, name: d.nameEn },
          ],
        });
      } else {
        create({
          isActive: d.status === "active",
          prices,
          translations: [
            { lang: LangDto.PL, name: d.name },
            { lang: LangDto.EN, name: d.nameEn },
          ],
        });
      }
    },
    e => console.log(e),
  );

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      reset={reset}
      isLoading={isCreateLoading || isUpdateLoading}
    >
      <HeaderStyled>{t("products_settings.add_product")}</HeaderStyled>
      <FormTextField
        control={control}
        name="name"
        size="small"
        label={t("products_settings.product_name")}
        variant="outlined"
      />
      <FormTextField
        control={control}
        name="nameEn"
        size="small"
        label={t("products_settings.product_name_en")}
        variant="outlined"
      />
      <FormAutocomplete
        control={control}
        name="status"
        id="status"
        size="small"
        popupIcon={<CheckedIcon fill={palette.primary.main} />}
        className="w-full"
        options={[
          { id: "active", label: t("products_settings.active") },
          { id: "inactive", label: t("products_settings.inactive") },
        ]}
        label={t("products_settings.status")}
      />
      <Divider />
      {isPln && (
        <FormOutlinedInput
          control={control}
          name="pln"
          size="small"
          label={t("products_settings.price")}
          className="max-w-1/2"
          endAdornment={<InputAdornment position="end">PLN</InputAdornment>}
        />
      )}
      {isEur && (
        <FormOutlinedInput
          control={control}
          name="eur"
          size="small"
          label={t("products_settings.price")}
          className="max-w-1/2"
          endAdornment={<InputAdornment position="end">EUR</InputAdornment>}
        />
      )}
      {isUsd && (
        <FormOutlinedInput
          control={control}
          name="usd"
          size="small"
          label={t("products_settings.price")}
          className="max-w-1/2"
          endAdornment={<InputAdornment position="end">USD</InputAdornment>}
        />
      )}
      {isGbp && (
        <FormOutlinedInput
          control={control}
          name="gbp"
          size="small"
          label={t("products_settings.price")}
          className="max-w-1/2"
          endAdornment={<InputAdornment position="end">GBP</InputAdornment>}
        />
      )}
    </ModalWrapper>
  );
};
