import { styled } from "@mui/material";

export const Text = styled("span")`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
`;

export const Bold = styled(Text)`
  font-weight: 600;
`;
