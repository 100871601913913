import { useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { useAppParams, useAppTranslation } from "@hooks";
import { ApiResponse } from "@typeDefinitions";
import { DayTabs } from "@components/DayTabs";
import { OptimizationDto } from "@client/schedule";
import { useProgram } from "@hooks/resources";
import {
  useFetchOptimizerSettingsQuery,
  useOptimizedProgramScheduleMutation,
  useSaveOptimizerSettingsMutation,
} from "@hooks/queries/schedule";

import { programToDayTabs } from "./utils";
import {
  optimizerFormToSettingsRequest,
  useOptimizerForm,
} from "./useOptimizerForm";
import { OptimizerDayTable } from "./OptimizerDayTable";
import {
  DayTabsWrapper,
  FormSliderStyled,
} from "./OptimizerSettingsModal.styled";
import { usePatientImportantNutrients } from "./usePatientImportantNutrients";

export interface OptimizerSettingsModalProps {
  open: boolean;
  onClose: (data?: ApiResponse<OptimizationDto[]>) => void;
}

export const OptimizerSettingsModal = ({
  open,
  onClose = () => null,
}: OptimizerSettingsModalProps) => {
  const { t } = useAppTranslation();
  const { programId } = useAppParams();
  const { program, isLoading } = useProgram();

  const [selectedDay, setSelectedDay] = useState(0);
  const selectedDayId = program?.days[selectedDay]?.id;

  const { settings } = useFetchOptimizerSettingsQuery(programId);
  const { importantNutrients } = usePatientImportantNutrients();
  const form = useOptimizerForm(program, settings, importantNutrients);

  const { mutateAsync, isLoading: isMutating } =
    useOptimizedProgramScheduleMutation(programId);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { mutateAsync: settingMutate } =
    useSaveOptimizerSettingsMutation(programId);

  const mappedDays = useMemo(
    () => (program ? programToDayTabs(program) : []),
    [],
  );
  const handleSubmit = () => {
    setIsSubmitting(true);
    form.handleSubmit(
      async d => {
        try {
          await settingMutate(optimizerFormToSettingsRequest(d));
          await mutateAsync(undefined, {
            onSuccess: onClose,
            onSettled: () => setIsSubmitting(false),
          });
        } catch (e) {
          console.log(e);
        }
      },
      e => {
        console.log(e);
        setIsSubmitting(false);
        setSelectedDay(mappedDays.findIndex(day => e.days?.[day.id]));
      },
    )();
  };

  if (isLoading) return <CircularProgress />;

  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth maxWidth="small">
      <DialogTitle>{t("patient.schedule.optimization.title")}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          {t("patient.schedule.optimization.modal_text")}
        </DialogContentText>

        <FormProvider {...form}>
          <DayTabsWrapper>
            <DayTabs
              selected={selectedDay}
              onChange={setSelectedDay}
              orientation={"vertical"}
              days={mappedDays}
            />
            <div className="grid flex-1">
              <FormSliderStyled
                key={`${selectedDayId}.slider`}
                name={`days.${selectedDayId}.precision`}
                color="secondary"
                valueLabelDisplay="off"
                marks={[
                  {
                    value: 0,
                    label: t("patient.schedule.optimization.precision"),
                  },
                  {
                    value: 100,
                    label: t("patient.schedule.optimization.diversity"),
                  },
                ]}
              />

              {selectedDayId && program?.days[selectedDay] && (
                <OptimizerDayTable
                  key={`${selectedDayId}.table`}
                  day={program?.days[selectedDay]}
                  namePrefix={`days.${selectedDayId}`}
                />
              )}
            </div>
          </DayTabsWrapper>
        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => onClose()}
          disabled={isMutating}
          variant="contained"
          color="inherit"
        >
          {t("common.cancel")}
        </Button>
        <LoadingButton
          loading={isSubmitting || isMutating}
          onClick={handleSubmit}
          variant="contained"
        >
          {t("common.apply")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
