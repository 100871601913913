import { CircularProgress, styled } from "@mui/material";

export const InfiniteCircularProgressStyled = styled("div")`
  position: relative;
  width: 1.25rem;
  height: 1.25rem;
`;

export const CircularProgressBackgroundStyled = styled(CircularProgress)`
  color: ${({ theme }) => theme.colors.gray300};
  position: absolute;
  left: 0;
`;
export const CircularProgressStyled = styled(CircularProgress)`
  position: absolute;
  left: 0;
`;
