import { useMemo } from "react";

import { Nutrient } from "@typeDefinitions";
import { useNutrientDictionary } from "@hooks";
import { roundNutrientDigitsById } from "@utils";

import { MicrosTableCell, MicrosValue } from "./RecipeTable.styled";
import { mapVisibleNutrientsDetails } from "@context/VisibleNutrientsContext";

interface MicrosRowProps {
  nutrients: Nutrient[];
  visibleNutrients: number[];
}
export const MicrosRow = ({ nutrients, visibleNutrients }: MicrosRowProps) => {
  const { nutrientDictionary } = useNutrientDictionary();
  const getNutrient = (id: number) =>
    nutrientDictionary[id] ? nutrientDictionary[id] : undefined;

  const mappedMicros = useMemo(
    () => mapVisibleNutrientsDetails(visibleNutrients, nutrients, getNutrient),
    [nutrients, getNutrient, nutrientDictionary],
  );

  return (
    <>
      {mappedMicros.map(micro => (
        <MicrosTableCell align="center" key={micro.id}>
          <MicrosValue>{micro.value}</MicrosValue> {micro.unit}
        </MicrosTableCell>
      ))}
    </>
  );
};
