import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import * as yup from "yup";
import {
  ProgramOptimizationSettingsResponse,
  programOptimizationSettingsSchema,
} from "./common";
import { validateAndFixNutrients } from "../utils/validateCatching";

export async function fetchProgramOptimizationSettings(
  programId: number | string,
): Promise<ApiResponse<ProgramOptimizationSettingsResponse[]>> {
  const data = await fetchData(
    `/dietitian/programs/${programId}/schedule/settings`,
    APIMethods.GET,
  );
  return await validateAndFixNutrients(
    programOptimizationSettingsSchemaResponse,
    data,
  );
}

const programOptimizationSettingsSchemaResponse = yup.object().shape({
  data: yup.array().of(programOptimizationSettingsSchema),
});
