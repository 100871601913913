import { styled, Typography } from "@mui/material";

export const StyledTypography = styled(Typography)`
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MacrosListWrapper = styled("div")<MacroWrapperProps>`
  display: flex;
  gap: ${({ fixed }) => !fixed && "1.25rem"};
  align-items: center;
`;

interface MacroWrapperProps {
  compact?: boolean;
  fixed?: boolean;
}

export const MacroWrapper = styled("div")<MacroWrapperProps>`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  width: ${({ fixed }) => fixed && "4.125rem"};

  .nutrient-text {
    font-size: ${({ compact }) => compact && "0.75rem"};
    line-height: ${({ compact }) => compact && "1.25rem"};
  }
`;
