import { APIMethods, NoContentApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const storeReadMonitoringCalendarDay = async (
  patientId: number,
  dayId: number,
): Promise<NoContentApiResponse> => {
  return await fetchData(
    `/dietitian/patients/${patientId}/monitoring2/days/${dayId}/read`,
    APIMethods.POST,
  );
};
