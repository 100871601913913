import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import dayjs from "dayjs";
import { useEffect } from "react";
import {
  FetchClientLastMeasurementResponse,
  FetchClientResponse,
  UpdateClientMeasurementRequest,
} from "@client";
import { useAppTranslation } from "./useAppTranslation";

export const useUpdateClientMeasurementForm = (
  values?: UpdateClientMeasurementFormInput,
) => {
  const { t } = useAppTranslation();
  const resolver = yup.object().shape({
    date: yup.string().required(t("common.required_field")),
  });

  const form = useForm<UpdateClientMeasurementFormInput>({
    defaultValues: values,
    resolver: yupResolver(resolver),
    shouldFocusError: false,
  });

  useEffect(() => {
    if (values) form.reset(values);
  }, [values]);

  return form;
};

export const mapUpdateClientMeasurementRequest = (
  data: UpdateClientMeasurementFormInput,
): UpdateClientMeasurementRequest => {
  return {
    date: dayjs(data.date).format("YYYY-MM-DD"),
    growth: data.growth ?? null,
    weight: data.weight ?? null,
    bodyFatLevel: data.bodyFatLevel ?? null,
    arm: data.arm ?? null,
    tightBiceps: data.tightBiceps ?? null,
    waist: data.waist ?? null,
    hip: data.hip ?? null,
    thigh: data.thigh ?? null,
    calf: data.calf ?? null,
  };
};

export const mapUpdateClientMeasurementForm = (
  data: FetchClientResponse | undefined,
  lastMeasurement: FetchClientLastMeasurementResponse | undefined,
): UpdateClientMeasurementFormInput | undefined => {
  if (!data || !lastMeasurement) return;
  return {
    date: lastMeasurement.date ?? dayjs().toDate(),
    growth: lastMeasurement.growth ?? null,
    weight: lastMeasurement.weight ?? null,
    bodyFatLevel: lastMeasurement.bodyFatLevel ?? null,
    arm: lastMeasurement.arm ?? null,
    tightBiceps: lastMeasurement?.tightBiceps ?? null,
    waist: lastMeasurement.waist ?? null,
    hip: lastMeasurement.hip ?? null,
    thigh: lastMeasurement.thigh ?? null,
    calf: lastMeasurement.calf ?? null,
    targetWeight: data.profile.targetWeight?.toString() ?? null,
    goal: data.profile.targets[0]?.id ?? null,
  };
};

export interface UpdateClientMeasurementFormInput {
  date: Date;
  growth: number | null;
  weight: number | null;
  bodyFatLevel: number | null;
  arm: number | null;
  tightBiceps: number | null;
  waist: number | null;
  hip: number | null;
  thigh: number | null;
  calf: number | null;
  targetWeight: string | null;
  goal: number | null;
}
