import { Dispatch, SetStateAction, useMemo, useState } from "react";

import { Radio, RadioGroup, TextField, useTheme } from "@mui/material";
import { slice } from "lodash";

import { Loupe } from "@assets/icons";
import { Pagination } from "@components/PaginationNew";
import { useAppTranslation } from "@hooks";
import { useFetchSurveysAlloweatQuery } from "@hooks/queries/surveys";
import { SearchNoResults } from "@views/emptyStates/SearchNoResults";

import {
  FormControlLabelStyled,
  ModalLabel,
  PaginationTypography,
} from "./AddQuestionnaireModal.styled";
import { ChipsSelect } from "./ChipsSelect";
import { CustomRadioLabel } from "./CustomRadioLabel";
import { langDict } from "./useQuestionnaireModalForm";

interface TemplateQuestionnaireContentProps {
  selectedTemplate: number | null;
  setSelectedTemplate: Dispatch<SetStateAction<number | null>>;
}

interface SurveysOption {
  id: number;
  name: string;
}

const DEFAULT_PAGE = 0;

export const TemplateQuestionnaireContent = ({
  selectedTemplate,
  setSelectedTemplate,
}: TemplateQuestionnaireContentProps) => {
  const { t } = useAppTranslation();
  const { colors } = useTheme();
  const { surveys, isLoading } = useFetchSurveysAlloweatQuery();

  const [page, setPage] = useState(DEFAULT_PAGE);
  const DEFAULT_PER_PAGE = 10;

  const [langId, setLangId] = useState(0);
  const [query, setQuery] = useState("");
  const langOptions = useMemo(
    () => [
      {
        id: 0,
        label: t("questionnaires.all"),
      },
      {
        id: 1,
        label: "PL",
      },
      {
        id: 2,
        label: "EN",
      },
    ],
    [t],
  );

  const getSurveysSlice = (array: SurveysOption[]) => {
    const startIndex = 0 + page * DEFAULT_PER_PAGE;
    const endIndex = 10 + page * DEFAULT_PER_PAGE;

    return slice(array, startIndex, endIndex);
  };

  const filteredOptions = useMemo(
    () =>
      surveys
        ?.filter(s => (langId !== 0 ? s.langId === langDict[langId] : true))
        .filter(s =>
          query
            ? s.name.toLocaleLowerCase().includes(query.toLocaleLowerCase())
            : true,
        )
        .map(s => ({ id: s.id, name: s.name })) ?? [],
    [surveys, langId, query, page],
  );

  const paginatedList = getSurveysSlice(filteredOptions);
  const isOnePage = !!filteredOptions && filteredOptions.length <= 10;
  const pageCount = Math.ceil(filteredOptions.length / 10);

  const mapElementsCountLabel = () => {
    const start = Math.min(filteredOptions.length, 1 + page * 10);
    const end = Math.min(filteredOptions.length, (page + 1) * DEFAULT_PER_PAGE);

    return `${start}-${end}`;
  };

  return (
    <div className="grid gap-4">
      <ModalLabel>{t("questionnaires.select_template")}:</ModalLabel>
      <div className="grid gap-3">
        <TextField
          size="small"
          placeholder={t("common.search")}
          value={query}
          onChange={e => {
            setQuery(e.target.value);
            setPage(DEFAULT_PAGE);
          }}
          InputProps={{
            startAdornment: (
              <Loupe className="mr-2" fill={colors.neutral.dark[700]} />
            ),
          }}
          sx={{ width: "480px" }}
        />
        <ChipsSelect
          onChange={lang => {
            setLangId(lang);
            setPage(DEFAULT_PAGE);
          }}
          selectedId={langId}
          options={langOptions}
        />
      </div>
      <RadioGroup
        sx={{ gap: "0.5rem" }}
        value={selectedTemplate}
        onChange={(e, v) => setSelectedTemplate(Number(v))}
      >
        {paginatedList.map(({ id, name }) => (
          <FormControlLabelStyled
            key={id}
            value={id}
            control={<Radio />}
            label={<CustomRadioLabel label={name} />}
          />
        ))}
        {!isLoading && !paginatedList.length && <SearchNoResults />}
      </RadioGroup>
      <div className="flex justify-between">
        <PaginationTypography>
          {mapElementsCountLabel()} {t("common.of")} {filteredOptions.length}
        </PaginationTypography>
        <Pagination
          isOnePage={isOnePage}
          page={page}
          onPageChange={(_, newPage) => setPage(newPage)}
          definedPageCount={pageCount}
        />
      </div>
    </div>
  );
};
