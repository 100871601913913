import _ from "lodash";
import { FieldValues, useController, UseFormReturn } from "react-hook-form";
import { MultiSelectFilterDefinition } from "@typeDefinitions";
import { useAppTranslation } from "@hooks";

import { BaseFilterSelect } from "./BaseFilterSelect";
import { CheckboxStyled, CheckboxWrapper } from "./MultiSelectFilter.styled";

interface SingleFilterProps<T extends FieldValues> {
  form: UseFormReturn<T>;
  filter: MultiSelectFilterDefinition<T>;
}

export const MultiSelectFilter = <T extends FieldValues>({
  form: { control },
  filter: { name, label, options },
}: SingleFilterProps<T>) => {
  const { t } = useAppTranslation();
  const {
    field: { onChange, value },
  } = useController({ name, control });
  const values = (value as any[]) ?? [];

  return (
    <BaseFilterSelect name={label} selected={!!values.length}>
      <CheckboxWrapper>
        {options.map(option => (
          <CheckboxStyled
            fullWidth
            key={option.value}
            label={t(option.label)}
            value={values.includes(option.value)}
            onChange={e =>
              onChange(
                e.target.checked
                  ? _.concat(values, option.value)
                  : _.without(values, option.value),
              )
            }
          />
        ))}
      </CheckboxWrapper>
    </BaseFilterSelect>
  );
};
