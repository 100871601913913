import {
  AccordionDetails,
  AccordionSummary,
  Typography,
  styled,
} from "@mui/material";

export const RecipeName = styled(Typography)`
  font-size: 1.25rem;
`;

export const AccordionSummaryStyled = styled(AccordionSummary)`
  padding: unset;
  min-height: unset;

  & .MuiAccordionSummary-content {
    margin: unset;
    display: grid;
    gap: 0.25rem;
  }
`;

export const AccordionDetailsStyled = styled(AccordionDetails)`
  padding: unset;
  display: grid;
`;

export const MicronutrientsWrapper = styled("div")`
  display: flex;
  gap: 1rem;
  height: 2rem;
`;

export const RecipeNameWrapper = styled("div")`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`;

export const RecipeDetailsWrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-top: 0.5rem;
`;
