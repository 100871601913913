import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useAppTranslation } from "@hooks";
import { MutationOptions } from "../types";
import { fetchClinicDietitiansQueryKey } from "./useFetchClinicDietitiansQuery";
import { deleteClinicDietitian } from "@client/settings";
import { toast } from "react-toastify";

export const useDeleteClinicDietitianMutation = (options?: MutationOptions) => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, number>(
    id => deleteClinicDietitian(id),
    {
      ...options,
      onSuccess: () => {
        toast.success(t("dietitians_settings.dietitian_removed"));
        queryClient.invalidateQueries([fetchClinicDietitiansQueryKey]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};
