import { createContext, useContext } from "react";

export type ItemsListCallback = (items: (ProductItem | RecipeItem)[]) => void;
export interface ItemsContextIf {
  addItems: (items: (ProductItem | RecipeItem)[]) => void;
  deleteItems: (items: Item[]) => void;
  updateItem: (item: ProductItem | RecipeItem) => void;
  submit: (callback: ItemsListCallback) => void;
  isChecked: (item: Item) => boolean;
  addCollection: (itemId: number) => void;
  getCollectionStatus: (id: number) => {
    checked: boolean;
    indeterminate: boolean;
  };
  updateCollectionItems: (id: number, items: number[]) => void;
  toggleItems: (items: (ProductItem | RecipeItem)[]) => void;
  clearValues: () => void;
  itemsCount: number;
  getRecipeServings: (id: number) => number | null;
  singleSelect?: boolean;
}

export interface Item {
  type: "recipe" | "product";
  id: number;
  mealId: number;
}

export interface RecipeItem extends Item {
  type: "recipe";
  servings: number;
}

export interface ProductItem extends Item {
  type: "product";
  grams: number;
  measure: number;
}

export interface CollectionItem {
  id: number;
  items: number[];
}

export const ItemsContext = createContext<ItemsContextIf | undefined>(
  undefined,
);

export const useItemsContext = () => {
  const context = useContext(ItemsContext);

  if (!context) {
    throw new Error("useItemsContext must be used within a ItemsContext");
  }

  return context;
};
