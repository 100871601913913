import { Card, styled } from "@mui/material";

export const AlertCardStyled = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex: 1;
  padding: 1.5rem;
  border-radius: 1rem;
  max-width: 41rem;
  background: ${({ theme }) => theme.palette.primary.medium};
  box-shadow: ${({ theme }) => theme.boxShadows.card};
`;

export const TitleStyled = styled("span")`
  align-self: center;
  width: 100%;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const TextContent = styled("span")`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
  white-space: pre-line;
`;
