import { styled } from "@mui/material";

interface CustomHeaderWrapperProps {
  width: number;
}
export const CustomHeaderWrapper = styled("div")<CustomHeaderWrapperProps>`
  display: flex;
  justify-content: space-between;
  width: ${({ width }) => width}px;
`;
