import { useContext, useEffect, useState } from "react";
import Talk from "talkjs";
import { useNavigate, useParams } from "react-router-dom";
import { TextField } from "@mui/material";
import { useDebounce } from "react-use";
import { omit } from "lodash";

import { useFetchClientsForChatQuery } from "@hooks/queries/useFetchClientsForChatQuery";
import { Search } from "@assets/icons";

import { StyledInboxAutocomplete, StyledInboxGrid } from "./Inbox.styled";
import { useFetchClientQuery } from "@hooks/queries";
import { ChatboxContext } from "@context/ChatboxContext";
import { useInbox } from "@hooks/talkjs";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";

export const Inbox = () => {
  const { clientId } = useParams();
  const { account } = useFetchDietitianAccountQuery();
  const { client } = useFetchClientQuery(clientId ?? "", {
    enabled: !!clientId,
  });
  const navigate = useNavigate();
  const [query, setQuery] = useState("");

  const { clients, submit: submitClient } = useFetchClientsForChatQuery({
    keepPreviousData: true,
  });

  useDebounce(() => submitClient({ text: query }), 200, [query]);

  const { session } = useContext(ChatboxContext);
  const { inbox, inboxRef } = useInbox({ session, theme: "v2_desktop_chat" });

  useEffect(() => {
    if (inbox) {
      inbox.onConversationSelected(e => {
        if (e.conversation?.id) {
          const clientId = e.conversation.id.split("-")[0];
          navigate(`/chat/${clientId}`);
        }
      });

      inbox.onCustomConversationAction(
        "chatHeaderProfileOnClick",
        function (event) {
          if ("params" in event) {
            // TODO: Are we sure the user is exposed this way? not in event.conversation.participants ?
            const userEvent = event as any;
            navigate(`/patients/${userEvent.params.user.id}/info-and-services`);
          }
        },
      );
    }
  }, [inbox]);

  useEffect(() => {
    if (inbox && session && client && account) {
      const conversation = session.getOrCreateConversation(
        `${clientId}-${account.id}`,
      );

      conversation.setParticipant(session.me);
      conversation.setParticipant(
        new Talk.User({
          id: `${client.id}`,
          name: `${client.firstName + " " + client.lastName}`,
          email: `${client.email}`,
          photoUrl: null,
          role: "default",
        }),
      );

      inbox.select(conversation);
    }
  }, [inbox, session, client, account]);

  return (
    <StyledInboxGrid>
      <StyledInboxAutocomplete
        size="small"
        popupIcon={<Search />}
        options={
          clients?.map(client => ({
            id: client.id,
            label: client.firstName + " " + client.lastName,
          })) ?? []
        }
        onChange={(e, value) => {
          if (value) {
            setQuery("");
            navigate(`/chat/${(value as any).id}`);
          }
        }}
        renderInput={params => (
          <TextField
            {...omit(params, "InputLabelProps")}
            value={query}
            onChange={e => setQuery(e.target.value)}
          />
        )}
      />
      <div ref={inboxRef} className="h-screen-4/5 w-full" />
    </StyledInboxGrid>
  );
};
