import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const patchCreatorRecipeServings = async (
  mealId: string,
  recipeId: string,
  version: number,
  payload: PatchCreatorRecipeServingsRequest,
) => {
  await fetchData(
    `/dietitian/creator/meals/${mealId}/recipes/${recipeId}`,
    APIMethods.PATCH,
    payload,
    undefined,
    { "Alloweat-Creator-Version": `${version}` },
  );
};

export interface PatchCreatorRecipeServingsRequest {
  servings: number;
}
