import {
  QuestionTextAreaDto,
  TextAreaAnswerResourceDto,
} from "@client/surveys";
import { useAppTranslation } from "@hooks";

import { OuterLabel } from "@components/OuterLabel";
import { QuestionPreview } from "../../QuestionPreview/QuestionPreview";
import { TextStyled } from "../../QuestionPreview/QuestionPreview.styled";

interface AnswerTextAreaProps {
  question: QuestionTextAreaDto;
  answer?: TextAreaAnswerResourceDto;
  listIndex: number;
  totalQuestions: number;
}

export const AnswerTextArea = ({
  answer,
  question,
  listIndex,
  totalQuestions,
}: AnswerTextAreaProps) => {
  const { title, description } = question;
  const { t } = useAppTranslation();
  return (
    <QuestionPreview
      title={title}
      description={description}
      listIndex={listIndex}
      totalQuestions={totalQuestions}
    >
      <OuterLabel label={t("questionnaires.questions.answer")}>
        <TextStyled>
          {answer?.answer.value ?? t("questionnaires.questions.no_answer")}
        </TextStyled>
      </OuterLabel>
    </QuestionPreview>
  );
};
