import { useEffect } from "react";
import { useForm } from "react-hook-form";

import {
  TagResourceDto,
  UpdateDietCreatorTagsPayloadIf,
} from "@client/diets/creator";

export const useTagsForm = (defaultValues?: TagsInput) => {
  const form = useForm<TagsInput>({ defaultValues });

  useEffect(() => {
    if (defaultValues) form.reset(defaultValues, { keepDirtyValues: true });
  }, [defaultValues]);

  return form;
};

export interface TagsInput {
  tags: string[];
}

export const mapTagsForm = (
  data: TagResourceDto[] | undefined,
): TagsInput | undefined => {
  if (!data) return;

  return {
    tags: data.map(t => t.id.toString()),
  };
};

export const mapTagsRequest = ({
  tags,
}: TagsInput): UpdateDietCreatorTagsPayloadIf => {
  return { tags: tags.map(t => Number(t)) };
};
