import { fetchData } from "@utils/api";
import { APIMethods, NoContentApiResponse } from "@typeDefinitions";

export type UpdatePatientNormNutrientsParams = {
  patientId: number;
};

export type UpdatePatientNormNutrientsPayload = {
  norm: {
    id: number;
  };
  nutrients: {
    id: number;
    value: number;
  }[];
};

export type UpdatePatientNormNutrientsResponse = NoContentApiResponse;

export const updatePatientNormNutrients = async (
  params: UpdatePatientNormNutrientsParams,
  payload: UpdatePatientNormNutrientsPayload,
): Promise<UpdatePatientNormNutrientsResponse> => {
  return await fetchData(
    "/dietitian/patients/{patient_id}/norm-nutrients".replace(
      "{patient_id}",
      params.patientId.toString(),
    ),
    APIMethods.PUT,
    payload,
  );
};
