import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const updateClientChat = async (
  id: number,
  payload: UpdateClientChatRequest,
) => {
  await fetchData(
    `/dietitian/patients/${id}/application-modules/chat`,
    APIMethods.PUT,
    payload,
  );
};

export interface UpdateClientChatRequest {
  isActive: boolean;
}
