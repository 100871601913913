import { FormProvider } from "react-hook-form";

import { FormTextField } from "@components/FormTextField";
import { useUpdateSurveySubgroupQuestionMutation } from "@hooks/queries/surveys";
import { QuestionTableDto, QuestionType } from "@client/surveys";
import { OuterLabel } from "@components/OuterLabel";

import { Question } from "../Question/Question";
import { QuestionEditWatch } from "../QuestionEditWatch";
import {
  TableConfigFormInputs,
  mapTableConfigForm,
  mapTableConfigRequest,
  useTableConfigForm,
} from "../forms/useTableConfigForm";
import { ControlledTable } from "./ControlledTable";
import { useMemo } from "react";
import { useAppTranslation } from "@hooks";

interface QuestionTableProps {
  listIndex: number;
  totalQuestions: number;
  question: QuestionTableDto;
  onTypeChange: (currentType: QuestionType, newType: QuestionType) => void;
}

export const QuestionTable = ({
  listIndex,
  totalQuestions,
  question,
  onTypeChange,
}: QuestionTableProps) => {
  const { t } = useAppTranslation();
  const mappedForm = useMemo(() => mapTableConfigForm(question), [question]);
  const form = useTableConfigForm(mappedForm);
  const { mutate } = useUpdateSurveySubgroupQuestionMutation();

  const onSubmit = (data: TableConfigFormInputs) => {
    mutate({
      id: question.id.toString(),
      payload: mapTableConfigRequest(data, question),
    });
  };

  return (
    <FormProvider {...form}>
      <Question
        id={question.id}
        listIndex={listIndex}
        totalQuestions={totalQuestions}
        onTypeChange={onTypeChange}
        questionType={question.type}
      >
        <OuterLabel label={t("questionnaires.questions.table.header")}>
          <FormTextField size="small" name="heading" fullWidth />
        </OuterLabel>

        <ControlledTable />
      </Question>

      <QuestionEditWatch isLoading={false} onSubmit={onSubmit} />
    </FormProvider>
  );
};
