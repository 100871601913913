import { styled, TextField } from "@mui/material";
import { TreeView } from "@mui/lab";

export const TextFieldStyled = styled(TextField)`
  width: 100%;

  & .MuiOutlinedInput-root {
    padding-right: unset;
  }
`;

export const StyledTreeView = styled(TreeView)`
  flex-grow: 1;
  overflow-y: auto;

  .MuiTreeItem-content {
    padding: 0.25rem 0;
  }
  .MuiTreeItem-label {
    font-size: 0.875rem !important;
  }
`;
