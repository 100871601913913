import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getPdfCoverImagesAPI, uploadPdfMainImageAPI } from "@Pdf/api/pdfAPI";
import { MutationOptions } from "@hooks/queries";
import { pdfImagesQueryKey } from "@Pdf/hooks/query/usePdfImagesQuery";

const useAddPdfImageMutation = (options: MutationOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation(uploadPdfMainImageAPI, {
    ...options,
    onSuccess: async () => {
      const response = await getPdfCoverImagesAPI();

      queryClient.setQueryData([pdfImagesQueryKey], () => response);

      options.onSuccess?.();
    },
  });
};

export { useAddPdfImageMutation };
