import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const RadioDashed = (props: SvgIconProps) => (
  <SvgIcon width="25" height="24" viewBox="0 0 25 24" {...props}>
    <rect
      x="4.91504"
      y="4.5"
      width="15"
      height="15"
      rx="7.5"
      fill={props.fill ?? "currentColor"}
      stroke="#D0D0D0"
      strokeDasharray="2 2"
    />
  </SvgIcon>
);
