import { Accordion, AccordionSummary, styled } from "@mui/material";

export const SectionTitle = styled("p")`
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;

export const StyledAccordion = styled(Accordion)`
  box-shadow: unset;
  :before {
    display: none;
  }
`;

export const AccordionSummaryStyled = styled(AccordionSummary)`
  padding: 0;
  justify-content: unset;
  min-height: 1rem;
  color: ${({ theme }) => theme.palette.primary.main};
  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(180deg);
  }

  & .MuiAccordionSummary-content {
    margin: 0;
    flex-grow: unset;
    margin-right: 0.5rem;
  }

  & .MuiAccordionSummary-expandIconWrapper {
    color: inherit;
  }
`;

export const AccordionContent = styled("div")`
  display: grid;
  gap: 1.5rem;
  margin-top: 1rem;
`;
