import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import {
  useFetchNormNutrients,
  useFetchNutrientCategoriesQuery,
  useFetchProgramDayNutrientsQuery,
  useFetchProgramQuery,
} from "@hooks/queries";
import { NutrientsInput } from "@typeDefinitions/types";
import { FetchProgramDayNutrientsResponse } from "@client";
import { DEFAULT_NORM_ID } from "@utils/nutrients";
import { nutrientCatgoriesMapper } from "../microUtils";

interface MicrosFormInputs {
  nutrients: NutrientsInput[];
}

export const useMicrosForm = (
  normId: number,
  programId: number,
  dayId: number,
  defaultValues?: FetchProgramDayNutrientsResponse,
) => {
  const form = useForm<MicrosFormInputs>({
    defaultValues: {
      nutrients: [],
    },
  });

  const { nutrients, isLoading: isNutrientsNormLoading } =
    useFetchNormNutrients(normId ?? DEFAULT_NORM_ID, { enabled: !!normId });

  const { nutrientCategories, isLoading: isCategoriesLoading } =
    useFetchNutrientCategoriesQuery();

  const [idMap, setIdMap] = useState<Record<number, number>>({});

  const { program, isLoading: checkedNormsLoading } =
    useFetchProgramQuery(programId);

  const { nutrients: getProgramNutrients, isLoading: isNutrientLoading } =
    useFetchProgramDayNutrientsQuery(programId.toString(), dayId.toString());

  const programNutrients = getProgramNutrients?.data.nutrients;

  const checkedNutrients = program?.showNutrients;

  useEffect(() => {
    if (nutrients && nutrientCategories && defaultValues && checkedNutrients) {
      form.setValue(
        "nutrients",
        nutrientCatgoriesMapper(
          nutrientCategories,
          nutrients,
          programNutrients,
          checkedNutrients,
        ),
      );
      setIdMap(
        Object.fromEntries(
          nutrientCategories
            .flatMap(nutrient => nutrient.nutrients)
            .map((nutrient, index) => [nutrient.id, index]),
        ),
      );
    }
  }, [
    normId,
    nutrients,
    nutrientCategories,
    programId,
    dayId,
    checkedNutrients,
  ]);

  return {
    idMap,
    form,
    isLoading:
      isNutrientsNormLoading ||
      checkedNormsLoading ||
      isCategoriesLoading ||
      isNutrientLoading,
  };
};
