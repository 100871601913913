import { Chip, Typography } from "@mui/material";

import { SelectOption } from "@components/FormAutocomplete";

import { TagCategorySimplified } from "@hooks/useTagsNew";
import { DISABLED_SYSTEM_TAGS } from "@utils/tagsNew";
import { TagsWrapper } from "./EditProductDetails.styled";

interface TagCategoryProps {
  category: TagCategorySimplified;
  selectedTags: SelectOption[];
  toggleTag: (id: number) => void;
}

export const TagCategory = ({
  category,
  selectedTags,
  toggleTag,
}: TagCategoryProps) => {
  if (!category.tags.length) return <></>;

  return (
    <div key={category.id} className="grid gap-2">
      <Typography variant="body2">{category.name}</Typography>
      <TagsWrapper>
        {category.tags.map(tag => {
          const isSelected = selectedTags.some(
            selTag => selTag.id === tag.id.toString(),
          );
          const disabled =
            !!tag.systemId && DISABLED_SYSTEM_TAGS.includes(tag.systemId);

          if (isSelected) {
            return (
              <Chip
                key={tag.id}
                label={tag.name}
                variant="newFilled"
                color="primary"
                clickable
                disabled={disabled}
                onClick={() => toggleTag(tag.id)}
              />
            );
          } else
            return (
              <Chip
                key={tag.id}
                label={tag.name}
                variant="newOutlined"
                color="primary"
                clickable
                disabled={disabled}
                onClick={() => toggleTag(tag.id)}
              />
            );
        })}
      </TagsWrapper>
    </div>
  );
};
