import { Dialog, Box } from "@mui/material";
import { CheckedIcon, Dolar, Person, Location } from "@assets/icons";
import { FormProvider } from "react-hook-form";
import { useProductsAndCurrencies } from "./_hooks/useProductsAndCurrencies";
import { FormAutocomplete } from "@components/FormAutocomplete";
import { useCallback, useEffect, useState, type FC } from "react";
import { ScheduleModalActionButtons } from "./_components/SchduleModalActionButtons";
import {
  AddEventModalInput,
  useScheduleVisitForm,
} from "./_hooks/useScheduleVisitForm";
import { useAppTranslation } from "@hooks";
import {
  ScheduleVisitModalWrapper,
  ScheduleVisitModalLabel,
  ScheduleVisitModalFormWrapper,
  ScheduleVisitModalDivider,
} from "./ScheduleVisitModal.styled";
import { useScheduleModalSideEffects } from "./_hooks/useScheduleModalSideEffects";
import { useClients } from "./_hooks/useClients";
import { AddClientModal } from "@components/AddClientModalNew";
import { ApiError } from "@components/ApiError";
import { ScheduleModalClientSection } from "./_components/ScheduleModalClientSection";

import { FormTextField } from "@components/FormTextField";
import { useDietitiansAndFacilities } from "./_hooks/useDietitiansAndFacilities";

import { ScheduleTimeSection } from "./_components/ScheduleTimeSection";
import { ScheduleModalHeader } from "./_components/ScheduleModalHeader";
import { ScheduleModalNotifications } from "./_components/ScheduleModalNotifications";
import { ScheduleModalTabs } from "./_components/SchduleModalTabs";
import { ScheduleModalPriceSection } from "./_components/SchduleModalPriceSection";
import { useScheduleVisitModalContext } from "./ScheduleVisitModalContext";
import { MEETING_TYPE } from "@utils";
import { useWatch } from "react-hook-form";

interface ScheduleVisitModalProps {
  onlyDates?: boolean;
  navigateToVisit?: boolean;
}

export const ScheduleVisitModal: FC<ScheduleVisitModalProps> = ({
  onlyDates = false,
  navigateToVisit,
}) => {
  const { t } = useAppTranslation();

  const {
    isVisitModalOpen: onOpen,
    handleVisitModalClose: onClose,
    activeTab: defaultActiveTab,
    eventId,
  } = useScheduleVisitModalContext();

  const [activeTab, setActiveTab] = useState<MEETING_TYPE>(
    MEETING_TYPE.CONSULTATION,
  );

  const { form, onFormSubmit } = useScheduleVisitForm(navigateToVisit);

  const { control, handleSubmit, setValue } = form;
  const eventType = useWatch({ control, name: "eventType" });

  const { dietitiansMapped, facilitiesMapped } = useDietitiansAndFacilities();
  const { productsMapped, currenciesMapped, clinicProducts } =
    useProductsAndCurrencies();

  const handleSubmitForm = async (data: AddEventModalInput) => {
    await onFormSubmit(data);
    setActiveTab(MEETING_TYPE.CONSULTATION);
  };

  const {
    checkedClientEmail,
    addClientModal,
    clients,
    isError,
    isSuccess,
    openAddClientModal,
    closeAddClientModal,
    handleCreateClientSuccess,
  } = useClients(form.watch);

  const onCloseWithoutSubmit = useCallback(() => {
    setActiveTab(MEETING_TYPE.CONSULTATION);

    form.reset();
    onClose && onClose();
  }, [form, onClose]);

  const transitionOptions = { enter: 0, exit: 0 };

  useScheduleModalSideEffects(form, clinicProducts);

  useEffect(() => {
    if (defaultActiveTab) {
      setActiveTab(defaultActiveTab);
    }
  }, [defaultActiveTab]);

  useEffect(() => {
    if (eventType !== activeTab) {
      setValue("eventType", activeTab);
    }
  }, [activeTab, eventType]);

  if (isError || (isSuccess && !clients))
    return <ApiError message={t("patient.programs.api_error")} />;

  if (addClientModal)
    return (
      <AddClientModal
        onClose={closeAddClientModal}
        open={addClientModal}
        onSuccess={handleCreateClientSuccess}
        transitionDuration={transitionOptions}
      />
    );

  const isVisitTabActive = activeTab === MEETING_TYPE.CONSULTATION;
  return (
    <Dialog
      open={onOpen}
      className="max-w-2xl mx-auto"
      onClose={onCloseWithoutSubmit}
      fullWidth
      transitionDuration={transitionOptions}
    >
      <FormProvider {...form}>
        <ScheduleVisitModalWrapper className="custom-scrollbar">
          <ScheduleModalHeader onCloseWithoutSubmit={onCloseWithoutSubmit} />
          <ScheduleModalTabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            eventId={eventId}
          />
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <ScheduleVisitModalFormWrapper>
              <ScheduleModalClientSection
                onlyDates={onlyDates}
                openAddClientModal={openAddClientModal}
                isVisitTabActive={isVisitTabActive}
              />
              {isVisitTabActive && (
                <Box>
                  <ScheduleVisitModalLabel>
                    {t("calendar.product")}
                  </ScheduleVisitModalLabel>
                  <FormAutocomplete
                    control={control}
                    name="clinicProductId"
                    id="clinicProductId"
                    size="small"
                    popupIcon={<CheckedIcon className="text-gray-450" />}
                    className="w-full"
                    options={productsMapped ?? []}
                    disabled={onlyDates}
                    startIcon={<Dolar className="text-gray-450" />}
                  />
                </Box>
              )}

              <Box>
                <ScheduleVisitModalLabel>
                  {t("calendar.name")}
                </ScheduleVisitModalLabel>
                <FormTextField
                  control={control}
                  name="eventName"
                  size="small"
                  variant="outlined"
                  disabled={onlyDates}
                  fullWidth
                />
              </Box>
              <ScheduleTimeSection isVisitTabActive={isVisitTabActive} />
              {isVisitTabActive && (
                <ScheduleModalPriceSection
                  onlyDates={onlyDates}
                  currenciesMapped={currenciesMapped}
                />
              )}
              <ScheduleVisitModalDivider />
              <Box order={isVisitTabActive ? 0 : 3}>
                <ScheduleVisitModalLabel>
                  {t("calendar.dietitian")}
                </ScheduleVisitModalLabel>
                <FormAutocomplete
                  control={form.control}
                  name="dietitianId"
                  id="dietitianId"
                  size="small"
                  popupIcon={<CheckedIcon className="text-gray-450" />}
                  className="w-full"
                  options={dietitiansMapped ?? []}
                  disabled={onlyDates}
                  startIcon={<Person className="text-gray-450 text-2xl" />}
                />
              </Box>
              {isVisitTabActive && (
                <Box>
                  <ScheduleVisitModalLabel>
                    {t("calendar.location")}
                  </ScheduleVisitModalLabel>
                  <FormAutocomplete
                    control={form.control}
                    name="clinicFacilityId"
                    id="clinicFacilityId"
                    size="small"
                    popupIcon={<CheckedIcon className="text-gray-450" />}
                    className="w-full"
                    options={facilitiesMapped ?? []}
                    disabled={onlyDates}
                    startIcon={<Location className="text-gray-450 text-2xl" />}
                  />
                </Box>
              )}
              <Box order={isVisitTabActive ? 0 : 4}>
                <ScheduleVisitModalLabel>
                  {`${t("calendar.additionalInfo")} ${t(
                    "calendar.maxCharacters",
                  )}`}
                </ScheduleVisitModalLabel>
                <FormTextField
                  control={form.control}
                  name="additionalInfo"
                  multiline
                  rows={5}
                  size="small"
                  fullWidth
                  inputProps={{ maxLength: 250 }}
                  disabled={onlyDates}
                />
              </Box>
              {isVisitTabActive && (
                <>
                  <ScheduleVisitModalDivider />
                  <ScheduleModalNotifications
                    checkedClientEmail={!!checkedClientEmail}
                  />
                </>
              )}
            </ScheduleVisitModalFormWrapper>
            <ScheduleModalActionButtons
              onCloseWithoutSubmit={onCloseWithoutSubmit}
            />
          </form>
        </ScheduleVisitModalWrapper>
      </FormProvider>
    </Dialog>
  );
};
