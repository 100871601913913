import { useContext } from "react";

import { Typography } from "@mui/material";

import { useAppTranslation } from "@hooks";

import { BoldTypography, DayNoteStyled } from "./MonitoringCalendar.styled";
import { MonitoringContext } from "../context/MonitoringContext";

export const DayNote = () => {
  const { t } = useAppTranslation();
  const { selectedDayDetails } = useContext(MonitoringContext);

  return (
    <DayNoteStyled>
      <BoldTypography variant="subtitle2">
        {t("monitoring.note")}:
      </BoldTypography>
      <Typography variant="body2">
        {selectedDayDetails?.foodDiary?.note.patient ?? ""}
      </Typography>
    </DayNoteStyled>
  );
};
