import {
  ANSWER_VALUE,
  SystemQuestionDto,
  UserFatTissueAnswerResourceDto,
} from "@client/surveys";
import { OuterLabel } from "@components/OuterLabel";
import { useAppTranslation } from "@hooks";

import { QuestionPreview } from "../../QuestionPreview/QuestionPreview";
import { StyledAnswer } from "./SystemAnswerUserFatTissue.styled";

interface SystemAnswerUserFatTissueProps {
  question: SystemQuestionDto;
  answer?: UserFatTissueAnswerResourceDto;
  listIndex: number;
  totalQuestions: number;
}

export const SystemAnswerUserFatTissue = ({
  answer,
  question,
  listIndex,
  totalQuestions,
}: SystemAnswerUserFatTissueProps) => {
  const { title, description } = question;
  const { t } = useAppTranslation();
  const noAnswer = answer?.answer.value === null;
  const answerNo = answer?.answer.value === ANSWER_VALUE.No;
  const answerYes = answer?.answer.value === ANSWER_VALUE.Yes;

  return (
    <QuestionPreview
      title={title}
      description={description}
      listIndex={listIndex}
      totalQuestions={totalQuestions}
      systemQuestion
    >
      <OuterLabel label={t("questionnaires.questions.answer")}>
        <StyledAnswer>
          {noAnswer && t("questionnaires.questions.no_answer")}
          {answerNo && t("common.no")}
          {answerYes && `${t("common.yes")}, ${answer.answer.fat} %`}
        </StyledAnswer>
      </OuterLabel>
    </QuestionPreview>
  );
};
