import { useContext } from "react";

import { INTRODUCTION, TableOfContentsContext } from "./TableOfContentsContext";

export const useContentSelector = () => {
  const { selectedId, groupId, subgroupId, ...rest } = useContext(
    TableOfContentsContext,
  );

  const isIntroduction = selectedId === INTRODUCTION;
  const isGroup = !!groupId && selectedId.split("-").length === 1;
  const isSubgroup = !!subgroupId && selectedId.split("-").length === 2;

  return {
    isIntroduction,
    isGroup,
    isSubgroup,
    selectedId,
    groupId,
    subgroupId,
    ...rest,
  };
};
