import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const DoctorMonitoring = (props: SvgIconProps) => (
  <SvgIcon width="14" height="14" viewBox="0 0 14 14" {...props}>
    <path
      d="M2.88672 1.18359C3.03906 1.61523 2.78516 2.07227 2.37891 2.22461L1.71875 2.45312V5.5C1.71875 6.8457 2.81055 7.9375 4.15625 7.9375C5.50195 7.9375 6.59375 6.8457 6.59375 5.5V2.45312L5.9082 2.22461C5.50195 2.07227 5.27344 1.61523 5.40039 1.18359C5.55273 0.777344 6.00977 0.548828 6.44141 0.675781L7.10156 0.904297C7.76172 1.13281 8.21875 1.74219 8.21875 2.45312V5.5C8.21875 7.48047 6.82227 9.10547 4.96875 9.48633C5.14648 10.9082 6.33984 12 7.8125 12C9.36133 12 10.6562 10.7305 10.6562 9.15625V7.37891C9.91992 7.04883 9.4375 6.33789 9.4375 5.5C9.4375 4.38281 10.3262 3.46875 11.4688 3.46875C12.5859 3.46875 13.5 4.38281 13.5 5.5C13.5 6.33789 12.9922 7.04883 12.2812 7.37891V9.15625C12.2812 11.6445 10.2754 13.625 7.8125 13.625C5.45117 13.625 3.52148 11.8223 3.34375 9.48633C1.49023 9.13086 0.09375 7.48047 0.09375 5.5V2.45312C0.09375 1.74219 0.525391 1.13281 1.18555 0.904297L1.8457 0.675781C2.27734 0.523438 2.73438 0.777344 2.88672 1.18359ZM11.4688 6.3125C11.9004 6.3125 12.2812 5.95703 12.2812 5.5C12.2812 5.06836 11.9004 4.6875 11.4688 4.6875C11.0117 4.6875 10.6562 5.06836 10.6562 5.5C10.6562 5.95703 11.0117 6.3125 11.4688 6.3125Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
