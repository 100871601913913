import type {
  ActionsDto,
  IdDto,
  NutrientDto,
  TranslationLangDto,
} from "@client/common";
import {
  idSchema,
  nutrientSchema,
  translationLangSchema,
} from "@client/common";
import type {
  FoodWrapperDto,
  RecipeWrapperDto,
  TagResourceDto,
} from "@client/diets";
import {
  foodWrapperSchema,
  recipeWrapperSchema,
  tagResourceSchema,
} from "@client/diets";
import { ApiEndpoints, APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import { array, boolean, number, object, string } from "yup";

export const fetchCollectionSearchPreview = async (
  collectionId: string,
): Promise<ApiResponse<CollectionSearchPreviewDto>> => {
  const response = await fetchData(
    `${ApiEndpoints.CollectionsSearchPreview}/${collectionId}`,
    APIMethods.GET,
  );

  return reponseSchema.validate(response);
};

export interface CollectionSearchPreviewDto {
  id: number;
  clinic: IdDto;
  name: string;
  translations: TranslationLangDto[];
  nutrients: NutrientDto[];
  tags: TagResourceDto[];
  recipes: RecipeWrapperDto[];
  products: FoodWrapperDto[];
  favorite: number[];
  actions: Omit<ActionsDto, "canVisible">;
}

const actionsSchema = object({
  canEdit: boolean().required(),
  canDelete: boolean().required(),
});

const collectionSearchPreviewSchema = object({
  id: number().required(),
  clinic: idSchema.required(),
  name: string().required(),
  translations: array().of(translationLangSchema).required(),
  nutrients: array().of(nutrientSchema).required(),
  tags: array().of(tagResourceSchema).required(),
  recipes: array().of(recipeWrapperSchema).required(),
  products: array().of(foodWrapperSchema).required(),
  favorite: array().of(number().required()).required(),
  actions: actionsSchema.required(),
});

const reponseSchema = object({
  data: collectionSearchPreviewSchema.required(),
});
