import { Box, Typography } from "@mui/material";
import image from "@assets/img/tasksEmpty.png";
import { useAppTranslation } from "@hooks";

export const CalendarEmptyState = () => {
  const { t } = useAppTranslation();

  return (
    <Box
      display="flex"
      flexDirection="column"
      margin="10px auto"
      alignItems="center"
      gap="8px"
    >
      <img src={image} alt="brak wydarzeń" className="w-72 h-auto" />
      <Typography
        fontWeight={600}
        fontSize="16px"
        lineHeight="24px"
        align="center"
        color={({ colors }) => colors.neutral.dark[900]}
      >
        {t("calendar.empty_state_title")}
      </Typography>
      <Typography
        fontWeight={400}
        fontSize="14px"
        align="center"
        color={({ colors }) => colors.neutral.dark[800]}
      >
        {t("calendar.empty_state_content")}
      </Typography>
    </Box>
  );
};
