import { useMutation } from "@tanstack/react-query";
import { useAppTranslation } from "@hooks";
import { MutationOptions } from "../types";
import {
  PatchDietitianPasswordRequest,
  patchDietitianPassword,
} from "@client/settings";
import { toast } from "react-toastify";

export const usePatchDietitianPasswordMutation = (
  options?: MutationOptions,
) => {
  const { t } = useAppTranslation();
  return useMutation<unknown, unknown, PatchDietitianPasswordRequest>(
    payload => patchDietitianPassword(payload),
    {
      ...options,
      onSuccess: () => {
        toast.success(t("common.save_changes"));
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};
