import { ReactNode } from "react";
import { Button, Typography } from "@mui/material";
import { useAppTranslation } from "@hooks";

export interface CalendarButtonProps {
  id: number;
  onClick: () => void;
  label: ReactNode;
  selected: boolean;
}

interface CalendarCustomButtonsProps {
  buttons: CalendarButtonProps[];
}

export const CalendarCustomButtons = ({
  buttons,
}: CalendarCustomButtonsProps) => {
  const { t } = useAppTranslation();

  return (
    <>
      <Typography variant="subtitle1">{t("common.choose")}:</Typography>
      {buttons.map(({ id, label, onClick, selected }) => (
        <Button
          key={id}
          size="small"
          variant={selected ? "contained" : "text"}
          onClick={onClick}
        >
          {label}
        </Button>
      ))}
    </>
  );
};
