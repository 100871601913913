import { Drawer, Typography, Box, List } from "@mui/material";
import { useTasksContext } from "../Tasks.context";
import {
  EmptyCalendar,
  Person,
  ScaleMedium,
  TrashNew,
  Pencil,
  Close,
  LinkAnchor,
} from "@assets/icons";
import { useSingleTaskQuery } from "@hooks/queries/tasks/useSingleTaskQuery";
import dayjs from "dayjs";
import { useUpdateTaskStatus } from "@hooks/queries/tasks/useUpdateTaskStatus";
import { IconButton } from "@components/IconButton";
import { OutlineCircle, CompleteCircleOutlined } from "@assets/icons";
import { CustomTagChip } from "@components/CustomTagChip";
import { useAppTranslation } from "@hooks";
import { useCallback, useMemo } from "react";
import { useIsTaskEditable } from "@hooks/tasks/useIsTaskEditable";
import { getTaskPriorityIcon } from "@utils/tasks/getTaskPriorityIcon";

export const composeFullUrl = (relatedLink: string) => {
  if (relatedLink.startsWith("http://") || relatedLink.startsWith("https://")) {
    return relatedLink;
  }
  return `https://${relatedLink}`;
};

export const TasksPreviewPanel = () => {
  const { t } = useAppTranslation();

  const {
    isSidePanelOpen,
    handleCloseSidePanel,
    currentTaskId,
    handleOpenDeleteModal,
    handleOpenCreateModal,
  } = useTasksContext();

  const { data, isLoading } = useSingleTaskQuery(currentTaskId ?? "");
  const isTaskEditable = useIsTaskEditable(currentTaskId);

  const { handleUpdateTaskStatus } = useUpdateTaskStatus();

  const composedRelatedLink = useMemo(() => {
    return composeFullUrl(data?.relatedLink ?? "");
  }, [data?.relatedLink]);

  const toggleTaskStatus = useCallback(() => {
    if (!data || !currentTaskId) return;
    handleUpdateTaskStatus({
      isDone: !data.isDone,
      id: Number(currentTaskId),
    });
  }, [data, currentTaskId, handleUpdateTaskStatus]);

  if (isLoading) {
    return (
      <Drawer
        anchor="right"
        open={isSidePanelOpen}
        onClose={handleCloseSidePanel}
        slotProps={{
          backdrop: {
            invisible: true,
            transitionDuration: 0,
          },
        }}
        PaperProps={{
          sx: { width: 450, padding: 2, borderRadius: 0 },
        }}
      >
        <Typography variant="h6">{t("tasks.tasksTable.ariaLabel")}</Typography>
        <Typography variant="body1">{t("tasks.loading")}</Typography>
      </Drawer>
    );
  }

  if (!data) return null;

  return (
    <Drawer
      anchor="right"
      open={isSidePanelOpen}
      onClose={handleCloseSidePanel}
      slotProps={{
        backdrop: {
          invisible: true,
          transitionDuration: 0,
        },
      }}
      PaperProps={{
        sx: {
          width: 450,
          padding: 2,
          borderRadius: 0,
          gap: 3,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="start"
        gap={0.4}
      >
        <Typography variant="h6" id="task-preview-panel-title">
          {data.name}
        </Typography>
        <Box display="flex" gap={1}>
          <IconButton
            color="gray"
            disabled={!isTaskEditable}
            onClick={handleOpenCreateModal}
          >
            <Pencil />
          </IconButton>
          <IconButton color="gray" onClick={handleOpenDeleteModal}>
            <TrashNew />
          </IconButton>
          <IconButton color="gray" onClick={handleCloseSidePanel}>
            <Close />
          </IconButton>
        </Box>
      </Box>
      <Box>
        <Typography variant="subtitle2" color="text.secondary">
          {t("tasks.tasksPreviewPanel.description")}
        </Typography>
        <Typography variant="body1" color="text.primary">
          {data.description || t("tasks.noDescription")}
        </Typography>
      </Box>

      <Box>
        <Typography variant="subtitle2" color="text.secondary">
          {t("tasks.tasksPreviewPanel.tags")}
        </Typography>
        {data.customTags.length ? (
          <List className="flex flex-wrap gap-2">
            {data.customTags.map(({ name, color, id }) => (
              <CustomTagChip key={id} color={color} label={name} id={id} />
            ))}
          </List>
        ) : (
          <Typography variant="body1" color="text.primary">
            -
          </Typography>
        )}
      </Box>
      <Box>
        <Typography variant="subtitle2" color="text.secondary">
          {t("tasks.tasksPreviewPanel.link")}
        </Typography>
        {data.relatedLink ? (
          <Box display="flex" alignItems="center" gap={1}>
            <LinkAnchor className="text-gray-450 text-2xl" />
            <a
              href={composedRelatedLink}
              target="_blank"
              rel="noopener noreferrer"
              className="text-base text-purple-dark font-medium"
            >
              {composedRelatedLink}
            </a>
          </Box>
        ) : (
          <Typography variant="body1" color="text.primary">
            -
          </Typography>
        )}
      </Box>
      <Box>
        <Typography variant="subtitle2" color="text.secondary">
          {t("tasks.tasksPreviewPanel.assignedTo")}
        </Typography>
        {data.dietitian ? (
          <Box display="flex" alignItems="center" gap={1}>
            <Person className="text-2xl text-gray-450" />
            <Typography variant="body1" color="text.primary">
              {data.dietitian.name}
            </Typography>
          </Box>
        ) : (
          <Typography variant="body1" color="text.primary">
            {t("tasks.noAssignedDietitian")}
          </Typography>
        )}
      </Box>
      <Box>
        <Typography variant="subtitle2" color="text.secondary">
          {t("tasks.tasksPreviewPanel.author")}
        </Typography>
        {data.owner ? (
          <Box display="flex" alignItems="center" gap={1}>
            <Person className="text-2xl text-gray-450" />
            <Typography variant="body1" color="text.primary">
              {data.owner?.name ?? "-"}
            </Typography>
          </Box>
        ) : (
          <Typography variant="body1" color="text.primary">
            {t("tasks.noAuthor")}
          </Typography>
        )}
      </Box>
      <Box>
        <Typography variant="subtitle2" color="text.secondary">
          {t("tasks.tasksPreviewPanel.deadline")}
        </Typography>
        <Box display="flex" alignItems="center" gap={1.5}>
          <EmptyCalendar className="ml-1 text-lg" />
          <Typography variant="body1" color="text.primary">
            {data.dueDate
              ? dayjs(data.dueDate).format("DD.MM.YYYY")
              : t("tasks.noDeadline")}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography variant="subtitle2" color="text.secondary">
          {t(`tasks.tasksPreviewPanel.priority`)}
        </Typography>
        <Box display="flex" alignItems="center" gap={0.7}>
          {data.priority && getTaskPriorityIcon(data.priority)}
          <Typography variant="body1" color="text.primary">
            {data.priority ? t(`tasks.priority.${data.priority}`) : "-"}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography variant="subtitle2" color="text.secondary">
          {t("tasks.tasksPreviewPanel.client")}
        </Typography>
        {data.client ? (
          <Box display="flex" alignItems="center" gap={1}>
            <Person className="text-2xl text-gray-450" />
            <Typography variant="body1" color="text.primary">
              {data.client.name}
            </Typography>
          </Box>
        ) : (
          <Typography variant="body1" color="text.primary">
            -
          </Typography>
        )}
      </Box>
      <Box mt={2}>
        {!data?.isDone ? (
          <IconButton
            color="secondary-purple"
            className="!border-primary-opacity rounded-lg max-w-max font-semibold gap-x-1"
            onClick={toggleTaskStatus}
          >
            <OutlineCircle className="text-2xl" />
            <span> {t("tasks.tasksPreviewPanel.markAsUndone")}</span>
          </IconButton>
        ) : (
          <IconButton
            color="secondary-green"
            className="max-w-max rounded-lg"
            onClick={toggleTaskStatus}
          >
            <CompleteCircleOutlined className="text-2xl text-green-dark" />
            <span className="text-green-dark font-medium">
              {t("tasks.tasksPreviewPanel.markAsDone")}
            </span>
          </IconButton>
        )}
      </Box>
    </Drawer>
  );
};
