import { useParams } from "react-router-dom";
import { useMemo } from "react";

type ProgramParams = {
  programId: string;
};
export const useProgramParams = () => {
  const { programId } = useParams<ProgramParams>();
  return useMemo(
    () => ({
      programId: programId ? parseInt(programId) : NaN,
    }),
    [programId],
  );
};
