import { Nutrient } from "@typeDefinitions";
import _, { round } from "lodash";

export const ENERGY_ID = 208;
export const PROTEIN_ID = 203;
export const FATS_ID = 204;
export const CARBS_ID = 205;
export const ASSIMILABLE_CARBOHYDRATES_ID = 1110;
export const GLYCEMIC_LOAD_ID = 1111;
export const GLYCEMIC_INDEX_ID = 1109;
export const CARBOHYDRATE_EXCHANGERS_ID = 1112;
export const PROTEIN_AND_FAT_EXCHANGERS_ID = 1113;
export const DEFAULT_NORM_ID = 12;

export const MACROELEMENTS_CATEGORY_ID = 1;
export const ENERGY_CATEGORY_ID = 2;

export const roundNutrientDigitsById = (
  value: number | undefined,
  categoryId: number | undefined,
  nutrientId: number | undefined,
) =>
  value !== undefined &&
  value.toFixed(fractionNutrientDigitsById(categoryId, nutrientId));

export const fractionNutrientDigitsById = (
  categoryId: number | undefined,
  nutrientId: number | undefined,
) => {
  if (nutrientId && [208, 203, 204, 205, 1112, 1113].includes(nutrientId))
    return 1;
  if (categoryId && [3, 6].includes(categoryId)) return 1;
  if (categoryId && [4, 5].includes(categoryId)) return 2;
  return 0;
};

export const scaleNutrients = (
  nutrients: Nutrient[],
  numerator: number,
  denominator = 100,
): Nutrient[] => {
  return nutrients.map(nutrient => {
    if (nutrient.id !== GLYCEMIC_INDEX_ID) {
      return {
        ...nutrient,
        value: (nutrient.value * numerator) / denominator,
      };
    } else {
      return nutrient;
    }
  });
};

export const sumNutrient = (nutrients: Nutrient[][]): Nutrient[] => {
  const noIndex = _(nutrients)
    .flatten()
    .filter(n => n.id === GLYCEMIC_INDEX_ID)
    .isEmpty();

  const sum = _(nutrients)
    .flatten()
    .filter(n => n.id !== GLYCEMIC_INDEX_ID)
    .groupBy(n => n.id)
    .mapValues(nutrients =>
      nutrients.reduce((na, nb) => ({ id: na.id, value: na.value + nb.value })),
    )
    .values()
    .value();

  const glycaemicLoad = sum.find(n => n.id === GLYCEMIC_LOAD_ID)?.value;
  const carbo = sum.find(n => n.id === ASSIMILABLE_CARBOHYDRATES_ID)?.value;

  if (!noIndex && glycaemicLoad !== undefined && carbo) {
    return [
      ...sum,
      {
        id: GLYCEMIC_INDEX_ID,
        value: calcGlycaemicIndex(glycaemicLoad, carbo),
      },
    ];
  } else {
    return sum;
  }
};

export const meanNutrient = (nutrients: Nutrient[][]): Nutrient[] => {
  const filteredNutrients = nutrients.filter(nutrient => nutrient);

  const noIndex = _(filteredNutrients)
    .flatten()
    .filter(n => n.id === GLYCEMIC_INDEX_ID)
    .isEmpty();

  const mean = _(filteredNutrients)
    .flatten()
    .filter(n => n.id !== GLYCEMIC_INDEX_ID)
    .groupBy(n => n.id)
    .mapValues(nutrients =>
      nutrients.reduce((na, nb) => ({ id: na.id, value: na.value + nb.value })),
    )
    .values()
    .map(n => ({ id: n.id, value: n.value / nutrients.length }))
    .value();

  const glycaemicLoad = mean.find(n => n.id === GLYCEMIC_LOAD_ID)?.value;
  const carbo = mean.find(n => n.id === ASSIMILABLE_CARBOHYDRATES_ID)?.value;

  if (!noIndex && glycaemicLoad !== undefined && carbo) {
    return [
      ...mean,
      {
        id: GLYCEMIC_INDEX_ID,
        value: calcGlycaemicIndex(glycaemicLoad, carbo),
      },
    ];
  } else {
    return mean;
  }
};

const calcGlycaemicIndex = (load: number, carbo: number) =>
  (100 * load) / carbo;

export const buildNutrientsDict = (nutrients: Nutrient[]) => {
  const map = new Map<number, number>();
  nutrients.forEach(nutrient => {
    map.set(nutrient.id, nutrient.value);
  });
  return map;
};

export const getNutrientValue = (
  nutrients: Nutrient[] | undefined,
  id: number,
) => {
  if (!nutrients) return 0;

  return nutrients.find(n => n.id === id)?.value ?? 0;
};
