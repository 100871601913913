import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { ProgramRowType } from "@views/dietician/PatientProgramsNew/Grid/RowType";
import { useChangeSharedProgramMutation } from "@hooks/queries/client/program/useChangeSharedProgramMutation";
import { CustomMenuItem } from "@views/dietician/PatientProgramsNew/PatientProgramsGrid.styled";
import { DownloadPdf, Hide, Mobile } from "@assets/icons";
import { usePostClientActivateProgram } from "@hooks/queries";
import { useAppTranslation } from "@hooks";

type SharedActionType = {
  program: ProgramRowType["program"];
  onSuccess: () => void;
  patientId: number;
};

const SharedAction = ({ program, onSuccess, patientId }: SharedActionType) => {
  const { t } = useAppTranslation();
  const { mutate } = usePostClientActivateProgram(program.id, {
    onSuccess: () => {
      onSuccess();
    },
  });

  const handleOnClick = () => {
    mutate({
      patientId: patientId,
      activate: !program.isActive,
    });
  };

  return (
    <>
      {!program.isActive && (
        <CustomMenuItem onClick={handleOnClick}>
          <ListItemIcon>
            <Hide fill="#727272" style={{ width: "24px" }} />
          </ListItemIcon>
          <ListItemText>
            {t("patient.programs_grid.row.actions.activate")}
          </ListItemText>
        </CustomMenuItem>
      )}
      {program.isActive && (
        <CustomMenuItem onClick={handleOnClick}>
          <ListItemIcon>
            <Mobile size="" fill="#727272" style={{ width: "24px" }} />
          </ListItemIcon>
          <ListItemText>
            {t("patient.programs_grid.row.actions.deactivate")}
          </ListItemText>
        </CustomMenuItem>
      )}
    </>
  );
};

export default SharedAction;
