import * as yup from "yup";

import {
  IdDto,
  TranslationDto,
  idSchema,
  translationSchema,
} from "@client/common";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import { TagCategoryType } from "@utils/tagsNew";

export const fetchDietCreatorTags = async (
  dietId: string,
): Promise<ApiResponse<TagResourceDto[]>> => {
  const response = await fetchData(
    `/dietitian/creator-2/diets/${dietId}/tags`,
    APIMethods.GET,
  );

  return creatorDietTagsResponseSchema.validate(response);
};

interface TagCategoryDto {
  id: TagCategoryType;
}
export interface TagResourceDto {
  id: number;
  systemId: number | null;
  name: string;
  translations: TranslationDto[];
  clinicN: IdDto | null;
  tagCategory: TagCategoryDto;
}

const tagCategorySchema = yup.object().shape({
  id: yup.number<TagCategoryType>().required(),
});
const tagSchema = yup.object().shape({
  id: yup.number().required(),
  systemId: yup.number().nullable().default(null),
  name: yup.string().required(),
  translations: yup.array().of(translationSchema).required(),
  clinicN: idSchema.nullable().default(null),
  tagCategory: tagCategorySchema.required(),
});

export const creatorDietTagsResponseSchema = yup.object().shape({
  data: yup.array().of(tagSchema).required(),
});
