import { ReactNode } from "react";
import {
  PageLayoutWrapper,
  TitleStyled,
  TitleWrapper,
} from "./PageLayout.styled";
import { isString } from "lodash";
import { IconButton } from "@mui/material";
import { ArrowLeft } from "@assets/icons/Arrows";
import { useNavigate } from "react-router-dom";

interface PageLayoutProps {
  title: ReactNode | string;
  returnButton?: boolean | string;
  extras?: ReactNode;
  children: ReactNode;
}
export const PageLayout = ({
  title,
  returnButton,
  extras,
  children,
}: PageLayoutProps) => {
  const navigate = useNavigate();
  const handleReturn = () =>
    isString(returnButton) ? navigate(returnButton) : navigate(-1);
  return (
    <PageLayoutWrapper>
      <div className="grid gap-[16px]">
        <div className="flex justify-between">
          <TitleWrapper>
            {returnButton && (
              <IconButton color="primary" onClick={handleReturn}>
                <ArrowLeft size="w-[16px] h-[16px]" />
              </IconButton>
            )}
            {isString(title) ? <TitleStyled>{title}</TitleStyled> : title}
          </TitleWrapper>

          {extras}
        </div>

        {children}
      </div>
    </PageLayoutWrapper>
  );
};
