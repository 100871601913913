import { FormLabeledCheckboxMui } from "@components/FormCheckboxMui";
import { Tooltip, Box } from "@mui/material";
import { useAppTranslation } from "@hooks";
import type { FC } from "react";

interface ScheduleModalNotificationsProps {
  checkedClientEmail: boolean;
}

export const ScheduleModalNotifications: FC<
  ScheduleModalNotificationsProps
> = ({ checkedClientEmail }) => {
  const { t } = useAppTranslation();

  return (
    <Box display="flex" flexDirection="column" gap="16px" paddingLeft="10px">
      <Tooltip
        title={
          !checkedClientEmail ? (
            <div className="h-[15px]">
              {t("visits.add.disabled_notification_msg")}
            </div>
          ) : null
        }
        placement="top-start"
        arrow
      >
        <div>
          <FormLabeledCheckboxMui
            disabled={!checkedClientEmail}
            label={t("visits.add.notification")}
            name="notification"
          />
        </div>
      </Tooltip>
    </Box>
  );
};
