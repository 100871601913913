import { DietitianTag } from "@typeDefinitions";
import { useAppTranslation, useTags } from "@hooks";
import { memo, useMemo } from "react";
import { ExpandableRawTagsList } from "./ExpandableRawTagsList";

export const ExpandableTagsList = memo(
  ({ tags, maxCount = 4 }: ExpandableTagsListProps) => {
    const { t } = useAppTranslation();
    const { tags: tagsDetails } = useTags();

    const resolvedTags = useMemo(
      () => tags.map(t => tagsDetails[t.id]?.name ?? t.id.toString()),
      [tags, tagsDetails],
    );

    return <ExpandableRawTagsList maxCount={maxCount} tags={resolvedTags} />;
  },
);

interface ExpandableTagsListProps {
  tags: DietitianTag[];
  maxCount?: number;
}
