import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const DeleteIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="21" height="20" viewBox="0 0 21 20" {...props}>
      <rect
        x="0.5"
        width="20"
        height="20"
        rx="10"
        fill={props.fill ?? "currentColor"}
      />
      <path
        d="M14.4584 13.099C14.7014 13.3941 14.7014 13.6892 14.4584 13.9844C14.1632 14.2274 13.8681 14.2274 13.573 13.9844L10.5 10.8854L7.40108 13.9844C7.10594 14.2274 6.8108 14.2274 6.51567 13.9844C6.27261 13.6892 6.27261 13.3941 6.51567 13.099L9.61462 10L6.51567 6.87501C6.27261 6.57987 6.27261 6.28473 6.51567 5.98959C6.8108 5.74653 7.10594 5.74653 7.40108 5.98959L10.5 9.11459L13.599 6.01563C13.8941 5.77257 14.1893 5.77257 14.4844 6.01563C14.7275 6.31077 14.7275 6.60591 14.4844 6.90105L11.3855 10L14.4584 13.099Z"
        fill="#727272"
      />
    </SvgIcon>
  );
};
