import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { CreateTaskRequestPayload } from "@client/tasks/createTask";
import { createTask } from "@client/tasks/createTask";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { ApiEndpoints } from "@typeDefinitions";
import { useAppTranslation } from "@hooks/useAppTranslation";
import { useDeleteSuggestedTaskMutation } from "../suggestedTasks/useDeleteSuggestedTaskMutation";
import { useDeleteNoteMutation } from "../notes/useDeleteNoteMutation";

interface CreateTaskMutationPayload {
  noteId: number | null;
  suggestedTaskId: string | null;
  data: CreateTaskRequestPayload;
}

export const useCreateTaskMutation = () => {
  const queryClient = useQueryClient();
  const { t } = useAppTranslation();
  const { handleDeleteSuggestedTask } = useDeleteSuggestedTaskMutation();
  const { handleDeleteNote } = useDeleteNoteMutation();

  const { mutateAsync } = useMutation<
    AxiosResponse,
    AxiosError,
    CreateTaskMutationPayload
  >(({ suggestedTaskId: _, noteId: __, data }) => createTask(data), {
    onSuccess: (_, { suggestedTaskId, noteId }) => {
      queryClient.invalidateQueries([ApiEndpoints.Tasks]);
      queryClient.invalidateQueries([ApiEndpoints.TasksCount]);

      if (suggestedTaskId) {
        handleDeleteSuggestedTask(suggestedTaskId);
      }

      if (noteId) {
        handleDeleteNote(String(noteId));
      }

      toast.success(t("tasks.createSuccess"));
    },
  });

  const handleCreateTask = async (payload: CreateTaskMutationPayload) => {
    try {
      await mutateAsync(payload);
    } catch (error) {
      toast.error(t("tasks.createFailure"));
    }
  };

  return { handleCreateTask };
};
