import { useQuery } from "@tanstack/react-query";
import { ApiEndpoints } from "@typeDefinitions";
import { fetchSuggestedTasksCount } from "@client/suggestedTasks/fetchSuggestedTasksCount";
import { SuggestedTasksQueryParams } from "@client/suggestedTasks/fetchSuggestedTasksList";

export const useSuggestedTasksCountQuery = (
  params?: SuggestedTasksQueryParams,
) => {
  return useQuery({
    queryKey: [ApiEndpoints.SuggestedTasksCount, params],
    queryFn: async () => {
      const { data } = await fetchSuggestedTasksCount(params);

      return data;
    },
  });
};
