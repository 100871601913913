import { DragHandle } from "@assets/icons";
import { Button, styled } from "@mui/material";

export const StyledDragHandle = styled(DragHandle)`
  cursor: pointer;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const AddMeasureBtn = styled(Button)`
  width: max-content;
  padding: 0.5rem 1.25rem;
  margin-left: 1rem;
  border-radius: 6px;
`;

export const ListWrapper = styled("div")`
  display: grid;
  gap: 1.5rem;
  max-width: 40rem;
`;
