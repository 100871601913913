import { useAppTranslation } from "@hooks";
import { ThemeProviderWrapperNew } from "themeNew";
import {
  ContentWrapper,
  VisitsEmptyStateStyled,
} from "./VisitsEmptyState.styled";
import { Button } from "@mui/material";
import image from "@assets/img/visitsEmptyState.png";
import { PlusRounded } from "@assets/icons/PlusRounded";
import { EmptyStateCard, StyledSubTitle, StyledTitle } from "../common.styled";

interface VisitsEmptyStateProps {
  onClick: () => void;
}
export const VisitsEmptyState = ({ onClick }: VisitsEmptyStateProps) => {
  const { t } = useAppTranslation();

  return (
    <ThemeProviderWrapperNew>
      <EmptyStateCard>
        <VisitsEmptyStateStyled>
          <ContentWrapper>
            <img src={image} alt={t("empty_states.alt.no_visits")} />
            <StyledTitle>{t("empty_states.visits")}</StyledTitle>
            <StyledSubTitle maxWidth="40.375rem">
              {t("empty_states.visits_info")}
            </StyledSubTitle>
          </ContentWrapper>

          <Button
            startIcon={<PlusRounded size="w-3 h-3" />}
            variant="contained"
            onClick={onClick}
          >
            {t("empty_states.add_visit")}
          </Button>
        </VisitsEmptyStateStyled>
      </EmptyStateCard>
    </ThemeProviderWrapperNew>
  );
};
