import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from "@mui/material";

export const DialogStyled = styled(Dialog)`
  & .MuiDialog-container {
    height: unset;
  }
  & .MuiDialog-paper {
    margin: unset;
    width: 100vw;
    height: 100vh;
  }
`;

export const DialogContentStyled = styled(DialogContent)`
  background: ${({ theme }) => theme.palette.primary.light};
`;

export const DialogTitleStyled = styled(DialogTitle)`
  font-size: 1.25rem;
  box-shadow: ${({ theme }) => theme.boxShadows.tooltip};
`;
export const IconButtonStyled = styled(IconButton)`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
`;
export const ContentWrapper = styled("div")`
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  max-width: 62.375rem;
  margin: 2.5rem auto;
`;

export const SurveyContentWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex: 1;
`;

export const TableOfContentsWrapper = styled("div")`
  position: sticky;
  top: 2.5rem;
`;
