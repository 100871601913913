import React from "react";

import { useAppTranslation } from "@hooks";
import { useCategoriesNutrientsFetchQuery } from "@hooks/queries/dictionaries";
import { Spinner } from "@components/Spinner";

import { NutrientCategory } from "./NutrientCategory";
import { NutrientValueType } from "./Types";
import { useCalcNutrients } from "../../ProductForm";

export const ProductNutrients = ({
  isForm = false,
  values = [],
}: ProductNutrientsProps) => {
  const { t } = useAppTranslation();
  const { data: categoriesNutrients, isLoading } =
    useCategoriesNutrientsFetchQuery();

  if (isForm) {
    useCalcNutrients();
  }

  if (isLoading) {
    return <Spinner className="w-full h-56 bg-transparent" />;
  }

  return (
    <div className="w-full flex flex-col p-6 mb-3 rounded-md bg-gray-200 font-semibold">
      <p className="mb-8 text-lg">
        {t("product.nutrients.nutritional_values_per")}
      </p>
      <div className="w-full flex flex-col gap-4">
        {categoriesNutrients?.map(nutrientCategory => (
          <NutrientCategory
            key={nutrientCategory.id}
            nutrientCategory={nutrientCategory}
            isForm={isForm}
            values={values}
          />
        ))}
      </div>
    </div>
  );
};

interface ProductNutrientsProps extends NutrientsValues {
  isForm?: boolean;
}

export interface NutrientsValues {
  values?: NutrientValueType[];
}
