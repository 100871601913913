import { useTest503 } from "@hooks/queries/useTest503";
import { Button } from "@mui/material";
import { useState } from "react";

export const TestEmpty = () => {
  const [test503, setTest503] = useState(false);
  const [color, setColor] = useState("primary");
  const x = useTest503({ enabled: test503 }, true);

  const handle500 = () => {
    setColor("");
  };
  return (
    <div className="flex flex-col items-center gap-10">
      <Button size="large" onClick={() => setTest503(true)}>
        Sprawdz banner o aplikacji mobilnej 📲
      </Button>
      <Button onClick={handle500} color={color as "primary"}>
        Sprawdz 500 💀
      </Button>
      {/* <Button onClick={() => setTest503(true)}>Sprawdz 503 🔌</Button> */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Button onClick={() => methodDoesNotExist()}>
        Sprawdz ReferenceError 👻
      </Button>
    </div>
  );
};
