import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  PostDietMealsSaveToCollectionBaseRequest,
  postDietMealsSaveToCollectionBase,
} from "@client/diets";

import { MutationOptions } from "../types";
import { searchMealPatternsQueryNewKey } from "../useSearchMealPatternsQueryNew";

export const usePostDietMealsSaveToCollectionBaseMutation = (
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    UsePostDietMealsSaveToCollectionBaseMutationPayload
  >(
    ({ dietId, mealId, payload }) =>
      postDietMealsSaveToCollectionBase(dietId, mealId, payload),
    {
      ...options,
      onSuccess: (d, v) => {
        options?.onSuccess && options.onSuccess(d, v);
        queryClient.invalidateQueries([searchMealPatternsQueryNewKey]);
      },
    },
  );
};

interface UsePostDietMealsSaveToCollectionBaseMutationPayload {
  dietId: string;
  mealId: string;
  payload: PostDietMealsSaveToCollectionBaseRequest;
}
