import { Controller, ControllerProps } from "react-hook-form";

import { FormControl, FormHelperText, InputLabel } from "@mui/material";
import { OutlinedInputProps } from "@mui/material/OutlinedInput/OutlinedInput";
import { useAppTranslation } from "@hooks/useAppTranslation";
import { StyledOutlinedInput } from "./FormOutlinedInput.styled";

export type FormOutlinedInputProps = Omit<ControllerProps<any>, "render"> &
  Omit<OutlinedInputProps, "value" | "onChange" | "error" | "helperText"> & {
    centeredText?: boolean;
  };

export const FormOutlinedInput = (props: FormOutlinedInputProps) => {
  const { t } = useAppTranslation();

  return (
    <Controller
      {...props}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl>
          <InputLabel shrink error={!!error}>
            {props.label}
          </InputLabel>
          <StyledOutlinedInput
            {...props}
            value={value}
            onChange={onChange}
            error={!!error}
          />
          <FormHelperText error={!!error}>
            {error?.message ? t(error.message) : undefined}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};
