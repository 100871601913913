import { WEEKDAYS_VALUES } from "@utils/weekdays";
import { WeekdayButton } from "./WeekdayButton";

export type Weekday = typeof WEEKDAYS_VALUES[number];

interface WeekdaySelectorProps {
  active: Weekday[];
  onChange?: (active: Weekday[]) => void;
  size: "large" | "small";
  className?: string;
}

export const WeekdaySelector = ({
  active,
  onChange = () => null,
  size,
  className,
}: WeekdaySelectorProps) => {
  const handleDayClick = (day: Weekday, dayValue: boolean) => {
    if (dayValue) {
      onChange(active.filter(d => d !== day));
    } else {
      onChange([...active, day]);
    }
  };

  return (
    <div className={`flex gap-2 ${className}`}>
      {WEEKDAYS_VALUES.map(day => (
        <WeekdayButton
          key={day}
          size={size}
          active={active.includes(day)}
          onChange={dayValue => handleDayClick(day, dayValue)}
          weekday={day}
        />
      ))}
    </div>
  );
};
