import {
  DietMealListItemCreatorDto,
  fetchCreatorDietMeals,
} from "@client/diets/creator";
import { fetchCreatorDietMeal } from "@client/diets/creator/fetchCreatorDietMeal";
import { QueryOptionsTyped } from "@hooks/queries/types";
import { useQuery } from "@tanstack/react-query";
import { ApiResponse } from "@typeDefinitions";

export const fetchCreatorDietMealQueryKey = "fetchCreatorDietMealQuery";

export const useFetchCreatorDietMealQuery = (
  dietId: string,
  mealId: string,
  options?: QueryOptionsTyped<ApiResponse<DietMealListItemCreatorDto>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchCreatorDietMealQueryKey, dietId, mealId],
    () => fetchCreatorDietMeal(dietId, mealId),
    options,
  );

  return {
    data: data?.data,
    ...rest,
  };
};
