import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { CircularProgress } from "@mui/material";

import { ApiError } from "@components/ApiError";
import { useAppParams } from "@hooks";
import { usePostDietCloneMutation } from "@hooks/queries/diets";
import { DIETS } from "@routes";

export const DietClone = () => {
  const { dietId } = useAppParams();
  const { mutate, isError } = usePostDietCloneMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (dietId)
      mutate(dietId, {
        onSuccess: data => navigate(`${DIETS}/${data.data.id}/edit`),
      });
  }, [dietId]);

  if (isError) return <ApiError />;
  return (
    <div className="w-full grid place-items-center">
      <CircularProgress />
    </div>
  );
};
