import { Button, DialogActions, Popover, styled } from "@mui/material";

export const StyledPopover = styled(Popover)`
  z-index: 1500;
`;

export const ModalWrapper = styled("div")`
  display: grid;
  gap: 0.857rem;
  padding: 1.143rem 1.143rem 0.857rem;
  width: 22.857rem;
`;

export const TitleWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled("h3")`
  font-size: 0.857rem;
  line-height: 1.429rem;
`;

export const StyledDialogActions = styled(DialogActions)`
  padding: 0.857rem 1.143rem;
  background: ${({ theme }) => theme.palette.primary.light};
`;

export const CancelButton = styled(Button)`
  background: ${({ theme }) => theme.colors.neutral.light[100]};
  border: 1px solid ${({ theme }) => theme.palette.primary.main};

  &:hover {
    background: ${({ theme }) => theme.colors.neutral.light[100]};
  }
`;
