import * as yup from "yup";

import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";

import {
  ImageDto,
  imageSchema,
  MediaDto,
  mediaSchema,
  NutrientDto,
  nutrientSchema,
  TagDto,
  tagSchema,
} from "./common";

export async function fetchDiet(
  id: number,
): Promise<ApiResponse<FetchDietResponse>> {
  const rsp = await fetchData(`/dietitian/creator/diets/${id}`, APIMethods.GET);
  return await fetchDietResponseSchema.validate(rsp);
}

export async function fetchProgramDiet(
  programId: number,
  dayId: number,
): Promise<ApiResponse<FetchDietResponse>> {
  const rsp = await fetchData(
    `/dietitian/programs/${programId}/days/${dayId}/diet`,
    APIMethods.GET,
  );
  return await fetchDietResponseSchema.validate(rsp);
}

interface MeasureDto {
  id: number;
  description: string;
  descriptionEn: string;
  sortOrder: number;
  grams: number;
}

const measureSchema = yup.object().shape({
  id: yup.number().required(),
  description: yup.string().required(),
  descriptionEn: yup.string().required(),
  sortOrder: yup.number().required(),
  grams: yup.number().required(),
});

interface ExchangeDto {
  foodFrom: {
    id: number;
    description: string;
    descriptionEn?: string;
  };
  foodIdTo: number;
}

const exchangeSchema = yup.object().shape({
  foodFrom: yup.object().shape({
    id: yup.number().required(),
    description: yup.string().required(),
    descriptionEn: yup.string().ensure(),
  }),
  foodIdTo: yup.number().required(),
});

interface FoodDto {
  id: number;
  description?: string;
  descriptionPl?: string;
  nutrients: NutrientDto[];
  measures: MeasureDto[];
  exchange?: ExchangeDto | null;
}

const foodSchema = yup.object().shape({
  id: yup.number().required(),
  description: yup.string().ensure(),
  descriptionPl: yup.string().ensure(),
  nutrients: yup.array().of(nutrientSchema).required(),
  measures: yup.array().of(measureSchema).required(),
  exchange: exchangeSchema.nullable(),
});

interface FoodRecipeDto {
  id: number;
  grams: number;
  foodMeasureId: number;
  food: FoodDto;
}

const foodRecipeSchema = yup.object().shape({
  id: yup.number().required(),
  grams: yup.number().required(),
  foodMeasureId: yup.number().required(),
  food: foodSchema.required(),
});

export interface DietMealRecipeDto {
  servings: number;
  id: number;
  recipe: {
    id: number;
    name: string;
    nameEn?: string;
    description?: string;
    descriptionEn?: string;
    reviewed: boolean;
    isComplex: boolean;
    servings: number;
    tags: TagDto[];
    foodRecipe: FoodRecipeDto[];
    nutrients: NutrientDto[];
    media: MediaDto | null;
    comment: string | null;
    commentEn: string | null;
  };
}

const recipeSchema = yup.object().shape({
  servings: yup.number().required(),
  id: yup.number().required(),
  recipe: yup
    .object()
    .shape({
      id: yup.number().required(),
      name: yup.string().required(),
      nameEn: yup.string().ensure(),
      description: yup.string().ensure(),
      descriptionEn: yup.string().ensure(),
      reviewed: yup.boolean().required(),
      isComplex: yup.boolean().required(),
      servings: yup.number().required(),
      tags: yup.array().of(tagSchema).required(),
      foodRecipe: yup.array().of(foodRecipeSchema).required(),
      nutrients: yup.array().of(nutrientSchema).required(),
      media: mediaSchema.nullable().default(null),
      comment: yup.string().nullable().default(null),
      commentEn: yup.string().nullable().default(null),
    })
    .required(),
});

export interface ProductDto {
  id: number;
  description: string;
  descriptionEn?: string;
  nutrients: NutrientDto[];
  measures: MeasureDto[];
  config: {
    grams: number;
    foodMeasure: {
      id: number;
    };
  };
  exchange?: ExchangeDto | null;
}

const productConfigSchema = yup.object().shape({
  grams: yup.number().required(),
  foodMeasure: yup.object().shape({
    id: yup.number().required(),
  }),
});

const productSchema = yup.object().shape({
  id: yup.number().required(),
  description: yup.string().required(),
  descriptionEn: yup.string().ensure(),
  nutrients: yup.array().of(nutrientSchema).required(),
  measures: yup.array().of(measureSchema).required(),
  config: productConfigSchema.required(),
  exchange: exchangeSchema.nullable(),
});

export interface DietMealDto {
  id: number;
  name: string;
  nameEn?: string;
  kcal: number;
  hour: string | null;
  description?: string;
  descriptionEn?: string;
  food: ProductDto[];
  recipes: DietMealRecipeDto[];
  nutrients: NutrientDto[];
}

const dietMealSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  nameEn: yup.string().ensure(),
  kcal: yup.number().required(),
  hour: yup.string().ensure(),
  description: yup.string().ensure(),
  descriptionEn: yup.string().ensure(),
  food: yup.array().of(productSchema).required(),
  recipes: yup.array().of(recipeSchema).required(),
  nutrients: yup.array().of(nutrientSchema).required(),
});

export interface FetchDietResponse {
  id: number;
  version?: number | null;
  name: string;
  nameEn?: string;
  isReviewed: boolean;
  tags: TagDto[];
  targetNutrients: NutrientDto[];
  meals: DietMealDto[];
  image: ImageDto | null;
}

const fetchDietResponseSchema = yup.object().shape({
  data: yup
    .object()
    .shape({
      id: yup.number().required(),
      version: yup.number().nullable(),
      name: yup.string().required(),
      nameEn: yup.string().ensure(),
      isReviewed: yup.boolean().required(),
      tags: yup.array().of(tagSchema).required(),
      targetNutrients: yup.array(nutrientSchema).required(),
      meals: yup.array().of(dietMealSchema).required(),
      image: imageSchema.nullable().default(null),
    })
    .required(),
});
