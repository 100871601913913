import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const updateClientCustomTags = async ({
  clientId,
  tags,
}: UpdateClientCustomTagRequest) => {
  await fetchData(
    `/dietitian/patients/${clientId}/custom-tags/sync`,
    APIMethods.PUT,
    {
      tags,
    },
  );
};

export interface UpdateClientCustomTagRequest {
  clientId: number;
  tags: number[];
}
