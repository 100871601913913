import { QueryOptionsTyped } from "@hooks/queries";
import { useQuery } from "@tanstack/react-query";
import { patientKeys } from "@hooks/queries/client/patientKeys";
import {
  fetchPatientImportantNutrients,
  FetchPatientImportantNutrientsParams,
  FetchPatientImportantNutrientsResponse,
} from "@client/patient/nutrients/fetchPatientImportantNutrients";
import { patientNutrientKeys } from "@hooks/queries/client/nutrient/patientNutrientKeys";

const useFetchPatientImportantNutrients = (
  params: FetchPatientImportantNutrientsParams,
  options?: QueryOptionsTyped<FetchPatientImportantNutrientsResponse>,
) => {
  return useQuery({
    queryKey: patientNutrientKeys.important(params.patientId),
    queryFn: () => fetchPatientImportantNutrients(params),
    ...options,
  });
};

export default useFetchPatientImportantNutrients;
