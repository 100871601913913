import { useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";

import { Button, Drawer, InputAdornment } from "@mui/material";
import { omit } from "lodash";

import { Loupe, Plus } from "@assets/icons";
import { AutosaveWatchComponent } from "@components/AutosaveWatchComponent";
import { TabsChipsFiltersForm } from "@components/Filters/TabsChipsFilter/TabsChipsFilterForm";
import { ITEMS_ON_PAGE } from "@components/PaginationNew";
import { ProductPreview } from "@components/PreviewDrawer/Product";
import { ProductsDataGrid } from "@components/ProductsDataGrid";
import { mapProductRows } from "@components/ProductsDataGrid/productsDataGridUtils";
import { ProductsFiltersNew } from "@components/ProductsFiltersNew";
import { FormTextFieldClearableStyled } from "@components/ProductsFiltersNew/ProductsFilters.styled";
import {
  DEFAULT_MACROS_RANGE,
  ProductsFiltersFormInput,
  useProductsFiltersForm,
} from "@components/ProductsFiltersNew/useProductsFiltersForm";

import { ListTabs, useAppTranslation, useListTabs, useUserRoles } from "@hooks";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";
import { useSearchFoodQuery } from "@hooks/queries/useSearchFoodQuery";

import { ThemeProviderWrapperNew } from "themeNew";
import { SingleCardPageLayout } from "@components/PageLayout";
import { useNavigate } from "react-router-dom";
import { NEW_PRODUCT } from "@routes";
import { ProductsViewWrapper } from "./ProductsNew.styled";

export const ProductsNew = () => {
  const { t } = useAppTranslation();
  const form = useProductsFiltersForm();
  const { products, submit, isFetching, meta } = useSearchFoodQuery(undefined, {
    keepPreviousData: true,
  });
  const { account } = useFetchDietitianAccountQuery();
  const { isAdminClinicMember } = useUserRoles();
  const { adminTabs, regularTabs } = useListTabs();

  const navigate = useNavigate();
  const [selectedId, setSelectedId] = useState<number | null>(null);

  const tab = form.watch("tabs");

  const tabs: { label: string; id: ListTabs }[] = useMemo(
    () => (isAdminClinicMember ? adminTabs : regularTabs),
    [t, isAdminClinicMember],
  );

  const tabsWithCount = useMemo(() => {
    return tabs.map(option => ({
      ...option,
      label:
        option.id === tab && !!meta?.total
          ? `${option.label} (${meta?.total})`
          : option.label,
    }));
  }, [meta, t, tabs]);

  const mappedRows = useMemo(
    () => mapProductRows(products, account?.id ?? 0),
    [products, mapProductRows],
  );

  const handleChangePage = (id: number) => {
    submit(state => ({ ...state, page: id + 1 }));
  };
  const handleChangePerPage = (perPage: number) => {
    submit(state => ({ ...state, perPage }));
  };

  const handleChangeTab = (tab: string) => {
    submit(state => ({
      ...state,
      tabs: tab as ListTabs,
      page: undefined,
    }));
    form.setValue("page", undefined);
  };

  const handleSubmitForm = (data: ProductsFiltersFormInput) => {
    const nutrientsWithNonDefaultValues = Object.entries(
      data.nutrients || {},
    ).reduce((acc, [key, value]) => {
      const defaultValue = DEFAULT_MACROS_RANGE[key];
      if (
        !defaultValue ||
        defaultValue[0] !== value[0] ||
        defaultValue[1] !== value[1]
      ) {
        acc[key] = value;
      }
      return acc;
    }, {} as { [id: string]: [number, number] });

    submit({
      ...omit(data, ["nutrients"]),
      page: undefined,
      nutrients: nutrientsWithNonDefaultValues,
    });
  };

  return (
    <ThemeProviderWrapperNew>
      <ProductsViewWrapper>
        <SingleCardPageLayout
          rebranding
          title={t("common.products")}
          childrenClassName="flex flex-col gap-6"
          extra={
            <div className="flex gap-2">
              <Button
                variant="contained"
                onClick={() => navigate(NEW_PRODUCT)}
                startIcon={<Plus size="w-3 h-3" className="stroke-current" />}
              >
                {t("products.add_product")}
              </Button>
            </div>
          }
        >
          <FormProvider {...form}>
            <div className="grid gap-[16px]">
              <div className="flex gap-[4px]">
                <FormTextFieldClearableStyled
                  name="query"
                  size="small"
                  placeholder={t("filters.search_product_or_producer")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Loupe />
                      </InputAdornment>
                    ),
                  }}
                />
                <ProductsFiltersNew total={meta?.total} />
              </div>

              <TabsChipsFiltersForm
                name="tabs"
                options={tabsWithCount}
                onChangeExternal={handleChangeTab}
              />
              <AutosaveWatchComponent
                isLoading={isFetching}
                onSubmit={handleSubmitForm}
                isDirtyCondition={false}
              />

              <ProductsDataGrid
                rows={mappedRows}
                isOnePage={false}
                perPage={meta?.perPage ?? ITEMS_ON_PAGE.TWENTY_FIVE}
                onPageChange={handleChangePage}
                page={meta?.currentPage ? meta.currentPage - 1 : 0}
                setPerPage={handleChangePerPage}
                rowCount={meta?.total}
                loading={isFetching}
                onSelectRow={(id: number | null) => setSelectedId(id)}
              />

              <Drawer open={!!selectedId} variant="persistent" anchor="right">
                <ProductPreview
                  key={selectedId}
                  productId={selectedId}
                  onClose={() => setSelectedId(null)}
                />
              </Drawer>
            </div>
          </FormProvider>
        </SingleCardPageLayout>
      </ProductsViewWrapper>
    </ThemeProviderWrapperNew>
  );
};
