import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const MenuDivider = styled("hr")`
  border-color: #d1d1d1;
`;

export const TopMenuButton = styled(Button)`
  width: 3.25rem;
  height: 3.25rem;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  min-width: 0;

  & .MuiButton-endIcon {
    margin: 0;
  }
`;
