import IrrigationCircle from "@views/dietician/PatientMonitoring2/components/Irrigation/IrrigationCircle";
import { useAppTranslation } from "@hooks";
import { Trans } from "react-i18next";
import { Box } from "@mui/material";
import {
  SummaryText,
  SummaryTextHighlight,
} from "@views/dietician/PatientMonitoring2/components/Irrigation/Summary.styled";

type SummaryProps = {
  value: number;
};

const Summary = ({ value }: SummaryProps) => {
  const { t } = useAppTranslation();

  return (
    <Box display="flex" flexDirection="row" gap="16px" alignItems="center">
      <IrrigationCircle />
      <SummaryText>
        <Trans
          i18nKey={"monitoring2.irrigation_box.summary"}
          components={{
            Highlight: <SummaryTextHighlight />,
          }}
          values={{
            variable: value.toFixed(2),
          }}
        />
      </SummaryText>
    </Box>
  );
};

export default Summary;
