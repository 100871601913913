import { useModalState } from "@hooks";
import { createContext, useCallback, useContext, useState } from "react";
import type { FC, ReactNode } from "react";

type CurrentTaskId = string | null;

interface ModalContextType {
  isDeleteModalOpen: boolean;
  isCreateModalOpen: boolean;
  isSidePanelOpen: boolean;
  isSuggestedTasksPanelOpen: boolean;
  currentTaskId: CurrentTaskId;
  setCurrentTaskId: React.Dispatch<React.SetStateAction<CurrentTaskId>>;
  handleOpenDeleteModal: () => void;
  handleCloseDeleteModal: () => void;
  handleOpenCreateModal: () => void;
  handleCloseCreateModal: () => void;
  handleOpenSidePanel: () => void;
  handleCloseSidePanel: () => void;
  handleOpenSuggestedTasksPanel: () => void;
  handleCloseSuggestedTasksPanel: () => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const useTasksContext = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error(
      "useTasksContext must be used within a TasksContextProvider",
    );
  }

  return context;
};

export const TasksContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isDeleteModalOpen, handleOpenDeleteModal, handleCloseDeleteModal] =
    useModalState();
  const [isCreateModalOpen, handleOpenCreateModal, handleCloseCreateModalRaw] =
    useModalState();
  const [isSidePanelOpen, handleOpenSidePanel, handleCloseSidePanelRaw] =
    useModalState();

  const [
    isSuggestedTasksPanelOpen,
    handleOpenSuggestedTasksPanel,
    handleCloseSuggestedTasksPanelRaw,
  ] = useModalState();

  const [currentTaskId, setCurrentTaskId] = useState<string | null>(null);

  const handleCloseCreateModal = useCallback(() => {
    if (!isSidePanelOpen) {
      setCurrentTaskId(null);
    }
    handleCloseCreateModalRaw();
  }, [isSidePanelOpen, handleCloseCreateModalRaw]);

  const handleCloseSidePanel = useCallback(() => {
    setCurrentTaskId(null);
    handleCloseSidePanelRaw();
  }, [handleCloseSidePanelRaw]);

  const handleCloseSuggestedTasksPanel = useCallback(() => {
    setCurrentTaskId(null);
    handleCloseSuggestedTasksPanelRaw();
  }, [handleCloseSuggestedTasksPanelRaw]);

  return (
    <ModalContext.Provider
      value={{
        isDeleteModalOpen,
        isCreateModalOpen,
        currentTaskId,
        isSidePanelOpen,
        setCurrentTaskId,
        handleOpenDeleteModal,
        handleCloseDeleteModal,
        handleOpenCreateModal,
        handleCloseCreateModal,
        handleCloseSidePanel,
        handleOpenSidePanel,
        isSuggestedTasksPanelOpen,
        handleOpenSuggestedTasksPanel,
        handleCloseSuggestedTasksPanel,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
