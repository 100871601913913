import { ReactNode, useMemo } from "react";
import { LangContext } from "@views/dietician/MealEdit/context/LangContext";
import { LangDto } from "@client";

interface LangContextProviderProps {
  lang: LangDto;
  children: ReactNode;
}

export const LangContextProvider = ({
  lang,
  children,
}: LangContextProviderProps) => {
  const isPolish = lang === LangDto.PL;
  const value = useMemo(() => ({ lang, isPolish }), [lang]);

  return <LangContext.Provider value={value}>{children}</LangContext.Provider>;
};
