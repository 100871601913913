import { Ref } from "react";

import { Chip, Divider } from "@mui/material";

import { useAppTranslation, useModal } from "@hooks";
import { fetchClientQueryKey, usePostClientAvatar } from "@hooks/queries";

import { EmailContent } from "./InfoSection.styled";
import { Pencil } from "@assets/icons";
import { Avatar } from "@components/AvatarNew";
import dayjs from "dayjs";
import { mapAddressString } from "@utils";
import {
  ClientProfileCard,
  EditButton,
  SectionTitle,
  SectionWrapper,
  Subtitle,
  TitleSection,
} from "../../ClientProfile.styled";
import { ProfileSectionProps, noData } from "../../ClientProfile";
import { ExpandedTextArea } from "./components/ExpandedTextArea";
import { ClientProfileCardItem } from "../ClientProfileCardItem";
import { EditClientInfoModal } from "@components/EditClientInfoModal";
import { FetchClientResponse, parseFileToData } from "@client";
import { ClientCustomTags } from "components/ClientCustomTags";
import { CustomTag } from "@client/customTags/fetchCustomTags";
import { useQueryClient } from "@tanstack/react-query";
import { ApiResponse } from "@typeDefinitions";

interface InfoSectionProps extends ProfileSectionProps {
  id: number;
  infoSectionRef?: Ref<HTMLDivElement>;
}

export const InfoSection = ({
  id,
  account,
  infoSectionRef,
}: InfoSectionProps) => {
  const {
    firstName,
    lastName,
    email,
    emailVerified,
    avatarUrl,
    profile: { sex, birthDate, lang, note, address },
    phone: { number, prefix },
    tags,
  } = account;
  const { t, isPolishChosen } = useAppTranslation();
  const queryClient = useQueryClient();
  const age = dayjs(dayjs()).diff(birthDate, "years");
  const { modalOpened, onModalOpen, onModalClose } = useModal();

  const { mutate: uploadAvatar, isLoading } = usePostClientAvatar(
    id.toString(),
  );

  const gender = sex
    ? sex === "m"
      ? t("client_profile.info.man")
      : t("client_profile.info.woman")
    : noData;
  const selectedLanguage = lang
    ? lang === "pl"
      ? t("client_profile.info.language.pl")
      : t("client_profile.info.language.en")
    : noData;

  const handleTagsUpdated = (clientId: number, tags: CustomTag[]) => {
    queryClient.setQueryData<ApiResponse<FetchClientResponse>>(
      [fetchClientQueryKey, clientId],
      oldData => {
        if (!oldData) {
          return;
        }

        return {
          ...oldData,
          data: {
            ...oldData?.data,
            tags,
          },
        };
      },
    );
  };

  return (
    <>
      <ClientProfileCard className="qwe" ref={infoSectionRef}>
        <TitleSection>
          <p>{t("client_profile.info.client_information")}</p>
          <EditButton
            onClick={onModalOpen}
            startIcon={<Pencil size="w-4 h-4" className="stroke-current" />}
            disableRipple
          >
            {t("common.edit")}
          </EditButton>
        </TitleSection>
        <div className="flex justify-center">
          <Avatar
            isEditable
            size="huge"
            isUploading={isLoading}
            onFileUpload={e =>
              uploadAvatar(
                e.target.files?.item(0)
                  ? parseFileToData(e.target.files?.item(0))
                  : undefined,
              )
            }
            image={avatarUrl ?? undefined}
            fullName={`${firstName ?? ""} ${lastName ?? ""}`}
          />
        </div>
        <SectionWrapper>
          <SectionTitle>{t("client_profile.info.tags")}</SectionTitle>

          <ClientCustomTags
            clientId={id}
            tags={tags}
            onUpdated={handleTagsUpdated}
            editable
            maxRowsCount={3}
          />

          <SectionTitle>{t("client_profile.info.personal_info")}</SectionTitle>

          <ClientProfileCardItem
            title={t("client_profile.info.name_and_last_name")}
            subtitle={`${firstName} ${lastName}`}
          />
          <ClientProfileCardItem
            title={t("client_profile.info.gender")}
            subtitle={gender}
          />
          <ClientProfileCardItem
            title={t("client_profile.info.age")}
            subtitle={isNaN(age) ? noData : age}
          />
          <ClientProfileCardItem
            title={t("common.language")}
            subtitle={selectedLanguage}
          />
          <ClientProfileCardItem
            title={t("client_profile.info.notes_and_comments")}
            subtitle={<ExpandedTextArea note={note} />}
          />
        </SectionWrapper>

        <Divider />

        <SectionTitle>{t("client_profile.info.contact_info")}</SectionTitle>

        <ClientProfileCardItem
          title={t("common.phone_number")}
          subtitle={number ? `${prefix} ${number}` : noData}
        />
        <ClientProfileCardItem
          title={t("common.email_address")}
          subtitle={
            <EmailContent>
              <Subtitle>{email ?? noData}</Subtitle>
              {email && !emailVerified && (
                <Chip
                  color="warning"
                  size="small"
                  label={t("client_profile.info.unverified")}
                />
              )}
            </EmailContent>
          }
        />
        <ClientProfileCardItem
          title={t("client_profile.info.address")}
          subtitle={mapAddressString(address, isPolishChosen) || noData}
        />
      </ClientProfileCard>

      <EditClientInfoModal open={modalOpened} onClose={onModalClose} id={id} />
    </>
  );
};
