import { useQuery } from "@tanstack/react-query";

import { QueryOptions } from "./types";
import { buildDefaultQueryOptions } from "./common";
import { fetchClinicProducts } from "@client";

export const fetchClinicProductsQueryKey = "fetchClinicProductsQueryKey";

export const useFetchClinicProducts = (options?: QueryOptions) => {
  const { data, ...rest } = useQuery(
    [fetchClinicProductsQueryKey],
    fetchClinicProducts,
    buildDefaultQueryOptions(options),
  );

  return { clinicProducts: data?.data, ...rest };
};
