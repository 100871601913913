import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { programPreviewKeys } from "@hooks/queries/program/preview/programPreviewKeys";
import { patientProgramPreviewKeys } from "@hooks/queries/client/program/preview/patientProgramPreviewKeys";
import useProgramSearchPreviewRemoveQueries from "@components/ProgramSearchPreview/hooks/useProgramSearchPreviewRemoveQueries";

const usePatientProgramSearchPreviewRemoveQueries = ({
  programId,
  patientId,
}: {
  programId: number;
  patientId: number;
}) => {
  const query = useQueryClient();
  useProgramSearchPreviewRemoveQueries({ programId });

  useEffect(() => {
    return () => {
      query.removeQueries(
        patientProgramPreviewKeys.program({
          patientId: patientId,
          programId: programId,
        }),
      );
    };
  }, []);
};

export default usePatientProgramSearchPreviewRemoveQueries;
