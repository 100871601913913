import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "../types";
import { PostSurveySubgroupRequest, postSurveySubgroup } from "@client/surveys";
import { fetchSurveyFullQueryKey } from "./useFetchSurveyFullQuery";

export const usePostSurveySubgroupMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, PostSurveySubgroupMutationPayload>(
    ({ id, payload }) => postSurveySubgroup(id, payload),
    {
      ...options,
      onSuccess: (data, variables) => {
        options?.onSuccess && options.onSuccess(data, variables);

        queryClient.invalidateQueries([fetchSurveyFullQueryKey]);
      },
    },
  );
};

interface PostSurveySubgroupMutationPayload {
  id: string;
  payload: PostSurveySubgroupRequest;
}
