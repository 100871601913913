import { Nutrient } from "@typeDefinitions";
import { useAppTranslation, useNutrientDictionary } from "@hooks";
import { StyledSpan } from "./MacrosList.styled";
import { ENERGY_ID } from "@utils/nutrients";
import { round } from "lodash";

interface MacroEntryProps {
  compact: boolean;
  nutrient: Nutrient;
}

export const MacroEntry = ({ compact, nutrient }: MacroEntryProps) => {
  const { nutrientDictionary } = useNutrientDictionary();
  const { t } = useAppTranslation();

  return (
    <StyledSpan key={nutrient.id} compact={compact}>
      {nutrient.id === ENERGY_ID && (
        <span>
          <span className="font-semibold">
            {round(isNaN(nutrient.value) ? 0 : nutrient.value)}
          </span>
          {"\u00A0"}
          {t("common.kcal")}
        </span>
      )}

      {nutrient.id !== ENERGY_ID && (
        <span>
          {`${nutrientDictionary[nutrient.id]?.short}:`}
          {"\u00A0"}
          <span className="font-semibold">
            {round(isNaN(nutrient.value) ? 0 : nutrient.value)}
            {nutrientDictionary[nutrient.id]?.unit}
          </span>
        </span>
      )}
    </StyledSpan>
  );
};
