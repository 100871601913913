import { Tab, Tabs, styled } from "@mui/material";

export const TabsStyled = styled(Tabs)`
  &.MuiTabs-root {
    min-height: unset;
  }
  & .MuiTabs-flexContainer {
    gap: 24px;
  }
`;

export const TabStyled = styled(Tab)`
  &.MuiButtonBase-root {
    text-transform: unset;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    min-width: unset;
  }
  &.MuiTab-root {
    padding: 6px 0;
    min-height: unset;
  }
`;
