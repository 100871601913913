import { useQuery, useQueryClient } from "@tanstack/react-query";

import { ApiResponse } from "@typeDefinitions";

import { QueryOptionsTyped } from "./types";
import {
  EmptyEventDto,
  SurveyRespondedEventDto,
  SurveySentEventDto,
  fetchClientEvents,
} from "@client/fetchClientEvents";
import { fetchClientQueryKey } from "./useFetchClientQuery";

export const fetchClientEventsQueryKey = "fetchClientEventsQueryKey";

export const useFetchClientEventsQuery = (
  id: string,
  options?: QueryOptionsTyped<
    ApiResponse<
      (SurveySentEventDto | SurveyRespondedEventDto | EmptyEventDto)[]
    >
  >,
) => {
  const queryClient = useQueryClient();
  const { data, ...rest } = useQuery(
    [fetchClientEventsQueryKey, id],
    () => fetchClientEvents(id),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries([fetchClientQueryKey, id]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );

  return {
    events: data?.data,
    ...rest,
  };
};
