import { useRef } from "react";
import { Link } from "react-router-dom";
import { useAppTranslation } from "@hooks";
import { useWhatsNewTypeName } from "@components/WhatsNewList/hooks";
import { Trans } from "react-i18next";
import dayjs from "dayjs";
import {
  ChipSection,
  ContentWrapper,
  LearnMoreButton,
  SectionSubTitle,
  SectionTitle,
  StyledList,
  WhatsNewChip,
  WhatsNewItemWrapper,
} from "./WhatsNewItem.styled";

interface WhatNewsItemProps {
  id: string;
  date: string | null;
  opened: boolean;
  type: number;
  title: string;
  content: string;
  url: string | null;
}

export const WhatsNewItem = ({
  id,
  date,
  opened,
  type,
  title,
  content,
  url,
}: WhatNewsItemProps) => {
  const { t } = useAppTranslation();
  const { typeName } = useWhatsNewTypeName(type);
  const anchorEl = useRef<null | HTMLDivElement>(null);
  const whatsNewDate = dayjs(date);

  const transComponents = {
    paragraph: <SectionSubTitle />,
    bold: <strong />,
    element: <li className="list-item last:pb-0" />,
    list: <StyledList />,
  };

  return (
    <WhatsNewItemWrapper>
      <ChipSection>
        <WhatsNewChip size="small" type={type} label={typeName} />
        {whatsNewDate.format("DD.MM.YYYY")}
      </ChipSection>
      <ContentWrapper ref={anchorEl}>
        <SectionTitle>{title}</SectionTitle>
        <Trans i18nKey={content} components={transComponents} />
        {url && (
          <Link to={url} target="_blank" rel="noopener noreferrer">
            <LearnMoreButton>{t("whatsNew.learn_more")}</LearnMoreButton>
          </Link>
        )}
      </ContentWrapper>
    </WhatsNewItemWrapper>
  );
};
