import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";
import { BodyMeasurementConst } from "@consts/BodyMeasurementConst";

export const updateClientMeasurement = async (
  id: string,
  payload: UpdateClientMeasurementRequest,
) => {
  await fetchData(
    `/dietitian/patient-measurements/${id}`,
    APIMethods.PUT,
    payload,
  );
};

export interface UpdateClientMeasurementRequest {
  date: string | null;
  growth: number | null;
  weight: number | null;
  bodyFatLevel: number | null;
  arm: number | null;
  tightBiceps: number | null;
  waist: number | null;
  hip: number | null;
  thigh: number | null;
  calf: number | null;
  water?: number | null;
  bodyFatMass?: number | null;
  skeletalMuscleMass?: number | null;
  boneTissueMass?: number | null;
  chest?: number | null;
  abdominal?: number | null;
  bodyMeasurements?: { id: number; value: number | null }[];
}
