import { LoadingButton } from "@mui/lab";
import { Button, styled } from "@mui/material";
import { getHexColorOpacity } from "@utils";

export const GenerateButton = styled(LoadingButton)`
  display: flex;
  flex-direction: column;
  place-items: center;
  gap: 12px;
  width: 126px;
  min-width: 126px;
  height: 126px;
  border-radius: 16px;
  border: 1px dashed
    ${({ theme }) => getHexColorOpacity(theme.palette.primary.main, 30)};
  box-shadow: ${({ theme }) => theme.boxShadows.card};
  background: ${({ theme }) => theme.colors.neutral.light[100]};
  padding: 12px;
`;

export const ButtonStyled = styled(LoadingButton)`
  width: 132px;
  font-weight: 600;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.4px;
  text-align: center;
  padding: 0;
  gap: 4px;
`;

export const TextStyled = styled("span")`
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.4px;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral.medium[600]};
`;

export const ImageStyled = styled("img")`
  width: 126px;
  min-width: 126px;
  height: 126px;
  border-radius: 16px;
`;

export const ImageWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
