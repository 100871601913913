import { styled, Card, Button } from "@mui/material";

export const ClientProfileWrapper = styled("div")`
  display: grid;
  grid-template-columns: minmax(19.875rem, 28.75rem) minmax(18.563rem, 23.75rem);
  gap: 1rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.medium}px) {
    grid-template-columns: repeat(2, minmax(19.875rem, 28.75rem)) minmax(
        18.563rem,
        23.75rem
      );
  }
`;

interface IClientCardWrapperProps {
  isDisabled?: boolean;
}

export const ClientCardWrapper = styled("div")<IClientCardWrapperProps>`
  display: grid;
  gap: 1rem;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : "unset")};
`;

interface ClientProfileCardProps {
  maxHeight?: number;
}

export const ClientProfileCard = styled(Card, {
  shouldForwardProp: propName => propName !== "maxHeight",
})<ClientProfileCardProps>`
  display: grid;
  gap: 24px;
  width: 100%;
  margin: 0 auto;
  padding: 24px;
  box-shadow: unset;
  height: fit-content;
  max-height: ${({ maxHeight }) => `${maxHeight}px`};
  overflow-y: auto;
  border-radius: 16px;
`;

export const TitleSection = styled("div")`
  display: flex;
  gap: 8px;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
`;

export const EditButton = styled(Button)`
  height: unset;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  padding: 0;
  color: ${({ theme }) => theme.palette.primary.main};
  &:hover {
    background: unset;
  }
`;

export const SectionWrapper = styled("div")`
  display: grid;
  gap: 16px;
`;

export const SectionTitle = styled("p")`
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;

export const Subtitle = styled("span")`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const PaymentsOfficeWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
