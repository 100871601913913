import { useAppTranslation } from "@hooks";
import { ControllerProps, Controller } from "react-hook-form";
import { TextField, TextFieldProps } from "@mui/material";

export type FormColorPickerFieldProps = Omit<ControllerProps<any>, "render"> &
  Omit<TextFieldProps, "onChange" | "error" | "helperText">;

export const FormColorPickerField = (props: FormColorPickerFieldProps) => {
  const { t } = useAppTranslation();

  return (
    <Controller
      {...props}
      render={({
        field: { onChange, value, onBlur, ref },
        fieldState: { error },
      }) => (
        <TextField
          ref={ref}
          {...props}
          type={"color"}
          value={value}
          onChange={onChange}
          onBlur={e => {
            onBlur();
            props.onBlur && props.onBlur(e);
          }}
          error={!!error}
          helperText={error?.message ? t(error.message) : undefined}
        />
      )}
    />
  );
};
