import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";

import { fetchData } from "@utils/api";

export interface CategoriesSectionsResponse {
  id: number;
  description: string;
  descriptionEn: string;
  templates: SectionCategoriesDto[];
}

export const fetchProgramCategories = async (): Promise<
  ApiResponse<CategoriesSectionsResponse[]>
> => {
  const data = await fetchData(
    "/dietitian/programs/sections/categories",
    APIMethods.GET,
  );
  return await fetchProgramCategorySchema.validate(data);
};

const sectionProgramCategorySchema = yup.object().shape({
  id: yup.number().required(),
  title: yup.string().required(),
  titleEn: yup.string().nullable().default(null),
  isSystemic: yup.boolean().required(),
});

export const sectionCategoriesListSchema = yup
  .array()
  .of(sectionProgramCategorySchema);

export const categoriesSchema = yup.object({
  id: yup.number().required(),
  description: yup.string().required(),
  descriptionEn: yup.string().required(),
  templates: sectionCategoriesListSchema.required(),
});

export const fetchProgramCategorySchema = yup.object({
  data: yup.array().of(categoriesSchema).required(),
});

export interface SectionCategoriesDto {
  id: number;
  title: string;
  titleEn: string | null;
  isSystemic: boolean;
}
