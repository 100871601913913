import { GridStateColDef } from "@mui/x-data-grid";

import { useAppTranslation } from "@hooks";

import { CustomHeaderWrapper } from "./CustomHeader.styled";
import { ProgramRowIf } from "../ProgramsDataGrid";

export const CustomHeader = ({
  computedWidth,
}: GridStateColDef<ProgramRowIf, any, any>) => {
  const { t } = useAppTranslation();

  return (
    <CustomHeaderWrapper width={computedWidth}>
      <span>{t("programs.program_name")}</span>
      <span>{t("common.tags")}</span>
      <span>{t("programs.actions")}</span>
    </CustomHeaderWrapper>
  );
};
