import {
  createContext,
  useContext,
  useState,
  type Dispatch,
  type FC,
  type ReactNode,
  type SetStateAction,
} from "react";

type CommonType = string | null;

interface TasksSuggestedContextType {
  clientId: CommonType;
  tagId: number | null;
  suggestedTaskId: CommonType;
  dueDate: CommonType;
  setClientId: Dispatch<SetStateAction<CommonType>>;
  setTagId: Dispatch<SetStateAction<number | null>>;
  setDueDate: Dispatch<SetStateAction<CommonType>>;
  setSuggestedTaskId: Dispatch<SetStateAction<CommonType>>;
}

const TasksSuggestedContext = createContext<
  TasksSuggestedContextType | undefined
>(undefined);

export const useTasksSuggested = () => {
  const context = useContext(TasksSuggestedContext);

  if (!context) {
    throw new Error(
      "useTasksSuggested must be used within a TasksSuggestedContextProvider",
    );
  }

  return context;
};

export const TasksSuggestedContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [clientId, setClientId] = useState<CommonType>(null);
  const [tagId, setTagId] = useState<number | null>(null);
  const [dueDate, setDueDate] = useState<CommonType>(null);
  const [suggestedTaskId, setSuggestedTaskId] = useState<CommonType>(null);

  return (
    <TasksSuggestedContext.Provider
      value={{
        clientId,
        tagId,
        dueDate,
        suggestedTaskId,
        setClientId,
        setSuggestedTaskId,
        setTagId,
        setDueDate,
      }}
    >
      {children}
    </TasksSuggestedContext.Provider>
  );
};
