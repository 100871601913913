import { Control, Controller, Path } from "react-hook-form";
import { Input, InputProps } from "@components/Input";

import { FieldValues } from "react-hook-form/dist/types";

export interface FormInputProps<T extends FieldValues> extends InputProps {
  name: Path<T>;
  control: Control<T>;
  numeric?: boolean;
  padding?: number;
}

export const FormInput = <T extends FieldValues>(props: FormInputProps<T>) => {
  const {
    name,
    control,
    error,
    onChange,
    onFocus,
    onBlur: externalOnBlur,
    numeric = false,
    padding,
    ...rest
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange: formOnChange, onBlur, value, ref },
        fieldState: { error: errors },
      }) => {
        return (
          <Input
            padding={padding}
            ref={ref}
            name={name}
            value={(value as string | number | undefined) ?? ""}
            onFocus={onFocus}
            onBlur={e => {
              if (externalOnBlur) {
                externalOnBlur(e);
              }
              onBlur();
            }}
            onChange={e => {
              if (onChange) {
                onChange(e);
              }

              if (numeric) {
                if (isNaN(parseInt(e.target.value))) {
                  return;
                }
                formOnChange(parseInt(e.target.value));
              } else {
                formOnChange(e.target.value);
              }
            }}
            error={error ?? (errors?.message as string)}
            {...rest}
          />
        );
      }}
    />
  );
};
