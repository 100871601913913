import { useCallback, useMemo, useState } from "react";

import { GridRenderCellParams } from "@mui/x-data-grid";

import { Plus } from "@assets/icons";
import { ClinicDietitianDto } from "@client/settings";
import { useAppTranslation } from "@hooks";
import { useFetchClinicDietitiansQuery } from "@hooks/queries/settings";
import { Roles } from "@utils/settings";
import { DietitianModal } from "@components/DietitianModal";
import { DataGrid } from "@components/DataGrid";
import { PersonDetailsCell } from "../PersonDetailsCell";

import { DietitianActions } from "./DietitianActions";
import {
  AddDietitianButton,
  DietitiansTabStyled,
} from "./DietitiansTab.styled";

export const DietitiansTab = () => {
  const { t } = useAppTranslation();
  const { dietitians, isLoading } = useFetchClinicDietitiansQuery();

  const [openAddModal, setOpenAddModal] = useState(false);
  const handleCloseAddModal = useCallback(() => {
    setOpenAddModal(false);
    setDietitianId(undefined);
  }, []);
  const handleOpenAddModal = useCallback(() => setOpenAddModal(true), []);

  const [dietitianId, setDietitianId] = useState<number | undefined>(undefined);
  const handleSetDietitianId = useCallback(
    (id?: number) => setDietitianId(id),
    [],
  );

  const getUserRole = (roles: Roles[]) => {
    if (roles.includes(Roles.DIETITIAN_OWNER)) return t("roles.admin");
    else if (roles.includes(Roles.DIETITIAN_WORKER)) return t("roles.worker");
    else if (roles.includes(Roles.DIETITIAN_ASSISTANT))
      return t("roles.assistant");
    else if (roles.includes(Roles.PATIENT)) return t("roles.patient");
  };

  const mapDietitiansGrid = (data?: ClinicDietitianDto[]) => {
    if (!data) return [];
    return data.map(dietitian => ({
      id: dietitian.id,
      active: dietitian.active,
      name: dietitian.firstName + " " + dietitian.lastName,
      email: dietitian.email,
      phone:
        dietitian.phone.prefix && dietitian.phone.number
          ? `${dietitian.phone.prefix} ${dietitian.phone.number
              .match(/.{1,3}/g)
              ?.join(" ")}`
          : "",
      avatar: dietitian.avatar,
      location: dietitian.facility?.name ?? "-",
      clientsCount: dietitian.clientsCount ?? "-",
      role: getUserRole(dietitian.roles),
      roles: dietitian.roles,
    }));
  };

  const dietitiansMapped = useMemo(
    () => mapDietitiansGrid(dietitians),
    [dietitians],
  );

  const columns = useMemo(
    () => [
      {
        field: "name",
        headerName: t("dietitians_settings.full_name"),
        flex: 1,
        minWidth: 400,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => (
          <PersonDetailsCell
            id={params.row.id}
            name={params.row.name}
            email={params.row.email}
            phone={params.row.phone}
            avatarUrl={params.row.avatar}
          />
        ),
      },
      {
        field: "location",
        headerName: t("dietitians_settings.location"),
        flex: 0.5,
        minWidth: 150,
        disableColumnMenu: true,
      },
      {
        field: "clientsCount",
        headerName: t("dietitians_settings.clients_count"),
        flex: 0.3,
        minWidth: 150,
        disableColumnMenu: true,
      },
      {
        field: "role",
        headerName: t("dietitians_settings.role"),
        flex: 0.3,
        minWidth: 150,
        disableColumnMenu: true,
      },
      {
        field: "actions",
        headerName: t("dietitians_settings.options"),
        type: "actions",
        minWidth: 125,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => (
          <DietitianActions
            id={params.row.id}
            setDietitianId={handleSetDietitianId}
            openEditModal={handleOpenAddModal}
            isAdmin={params.row.roles.includes(Roles.DIETITIAN_OWNER)}
            isActive={params.row.active}
          />
        ),
      },
    ],
    [],
  );

  return (
    <>
      <DietitiansTabStyled>
        <AddDietitianButton
          variant="contained"
          onClick={handleOpenAddModal}
          startIcon={<Plus size="w-3 h-3" className="stroke-current" />}
        >
          {t("dietitians_settings.add_dietitian")}
        </AddDietitianButton>
        <DataGrid
          columns={columns}
          rows={dietitiansMapped}
          getRowSpacing={() => ({
            top: 8,
          })}
          getRowHeight={() => "auto"}
          pageSize={10}
          disableSelectionOnClick
          loading={isLoading}
          autoHeight
        />
      </DietitiansTabStyled>
      <DietitianModal
        open={openAddModal}
        onClose={handleCloseAddModal}
        id={dietitianId}
        edit={!!dietitianId}
      />
    </>
  );
};
