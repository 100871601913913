import { TagCategoriesDict } from "@hooks";
import { pick } from "lodash";

const PRODUCT_TAG_CATEGORIES = [13, 15, 22, 30];
const RECIPE_TAG_CATEGORIES = [1, 2, 7, 8, 10, 18, 19, 21, 24, 29, 30];
const MEAL_TAG_CATEGORIES = [1, 2, 7, 8, 10, 18, 19, 21, 24, 29, 30];
const DIET_TAG_CATEGORIES = [13, 23, 30];

export const SPECIFIC_TAG_CATEGORIES: Record<TagCategoryBase, number[]> = {
  product: PRODUCT_TAG_CATEGORIES,
  recipe: RECIPE_TAG_CATEGORIES,
  meal: MEAL_TAG_CATEGORIES,
  diet: DIET_TAG_CATEGORIES,
};

export type TagCategoryBase = "product" | "recipe" | "meal" | "diet";

export const filterTagsDictionary = (
  categories: TagCategoriesDict,
  base: TagCategoryBase,
): TagCategoriesDict => {
  return pick(categories, SPECIFIC_TAG_CATEGORIES[base]);
};

export const clearFormInputs = (
  reset: (values: Record<string, never>) => void,
  submit: (value: Record<string, never>) => void,
) => {
  reset({});
  submit({});
};
