import { Button, styled } from "@mui/material";

export const StyledImg = styled("img")`
  height: 8.5rem;
  width: 8.5rem;
  min-width: 8.5rem;
  border-radius: 0.25rem;
  z-index: 1;
`;

export const StyledImagePlaceholder = styled("div")`
  display: flex;
  min-height: 8.5rem;
  min-width: 8.5rem;
  border-radius: 0.25rem;
  background: ${({ theme }) => theme.palette.action.hover};
  align-items: center;
  justify-content: center;
`;

export const RecipeDetailsWrapper = styled("div")`
  display: grid;
  gap: 1rem;
  width: 100%;
  padding: 0.5rem;
`;

export const HeaderWrapper = styled("div")`
  display: flex;
  gap: 1rem;
  width: 100%;
`;

export const PreviewWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
`;

export const TitleWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: start;
`;

interface RecipeMealHeaderWrapperProps {
  justifyOff?: boolean;
}

export const RecipeMealHeaderWrapper = styled(
  "div",
)<RecipeMealHeaderWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyOff }) =>
    justifyOff ? "unset" : "space-between"};
  gap: ${({ justifyOff }) => (justifyOff ? "0.5rem" : "unset")};
`;

interface StyledButtonProps {
  isOpen: boolean;
}

export const StyledExpandButton = styled(Button, {
  shouldForwardProp: propName => propName !== "isOpen",
})<StyledButtonProps>`
  padding: 0;

  &:hover {
    background-color: unset;
  }

  & .MuiTypography-root {
    color: ${({ theme }) => theme.palette.text.primary};
  }

  & .MuiButton-endIcon {
    transition: all 0.2s;
    & .arrow-down {
      font-size: 1.375rem;
    }
    ${({ isOpen }) =>
      isOpen &&
      `
    transform:  rotate(180deg);
    `}
  }
`;

export const AddMealButton = styled(Button)`
  font-size: 1rem;
`;
