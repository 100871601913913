import { IdDto } from "@client/common";
import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const postProgramDietMealsItems = async (
  programId: string,
  dietId: string,
  payload: ProgramDietMealsItemsRequest,
  version: string,
) => {
  return await fetchData(
    `/dietitian/program/${programId}/diet-creator/${dietId}/meals-items`,
    APIMethods.POST,
    payload,
    undefined,
    { "Alloweat-Creator-Version": version },
  );
};

export interface ItemDto {
  type: "recipe" | "product";
  id: number;
}

export interface RecipeItemDto extends ItemDto {
  type: "recipe";
  servings: number;
}

export interface ProductItemDto extends ItemDto {
  type: "product";
  grams: number;
  measure: IdDto;
}

export interface ProgramDietMealDto {
  meal: IdDto;
  item: RecipeItemDto | ProductItemDto;
}
export interface ProgramDietMealsItemsRequest {
  data: ProgramDietMealDto[];
}
