import { useQuery } from "@tanstack/react-query";
import { QueryOptionsTyped } from "../types";
import {
  FetchSingedSurveysResponse,
  fetchSingedSurveys,
} from "@client/surveys";
import { ApiResponse } from "@typeDefinitions";

export const fetchSingedSurveysQueryKey = "fetchSingedSurveysQueryKey";

export const useFetchSingedSurveysQuery = (
  url: string,
  options?: QueryOptionsTyped<ApiResponse<FetchSingedSurveysResponse>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchSingedSurveysQueryKey, url],
    () => fetchSingedSurveys(url),
    options,
  );

  return {
    data: data?.data,
    ...rest,
  };
};
