import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import { validateAndFixNutrients } from "./utils/validateCatching";

export async function fetchNorms(
  patientId?: string,
): Promise<ApiResponse<NormLocationDto[]>> {
  const response = await fetchData(
    patientId
      ? `/dietitian/nutrients/norm/location/age?patient_id=${patientId}`
      : "/dietitian/nutrients/norm/location/age",
    APIMethods.GET,
  );
  return await validateAndFixNutrients(fetchNormsResponseSchema, response);
}

const normSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  nameEn: yup.string().required(),
});

const normsLocationSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  normAge: yup.array().of(normSchema),
});

const fetchNormsResponseSchema = yup.object().shape({
  data: yup.array().of(normsLocationSchema),
});

export interface NormLocationDto {
  id: number;
  name: string;
  normAge: NormDto[];
}
interface NormDto {
  id: number;
  name: string;
  nameEn: string;
}
