import { useEffect, useState } from "react";

export const useInfiniteProgress = (start: boolean, subtractValue: number) => {
  const [yValue, setYValue] = useState(0);
  let xValue = 0;
  let interval: ReturnType<typeof setInterval>;

  const homographicFn = (value: number) => {
    return (-100 * value) / (-value - subtractValue);
  };

  const countValue = () => {
    interval = setInterval(() => {
      setYValue(homographicFn((xValue += 0.1)));
    }, 100);
  };

  useEffect(() => {
    if (!start) {
      xValue = 0;
      setYValue(0);
    } else countValue();

    return () => clearInterval(interval);
  }, [start]);

  return [yValue];
};
