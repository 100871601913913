import { useAppParams } from "@hooks";
import { MutationOptions } from "@hooks/queries";
import { useUpdateCreatorDietMealMutation } from "@hooks/queries/diets/creator";
import { useUpdateProgramDietMealMutation } from "@hooks/queries/program/dietCreator";

export const useUpdateDietMealMutation = (options?: MutationOptions) => {
  const { programId } = useAppParams();

  return programId
    ? useUpdateProgramDietMealMutation(programId, options)
    : useUpdateCreatorDietMealMutation(options);
};
