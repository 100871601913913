import styled from "@emotion/styled";

export const DragHandle = styled.div`
  cursor: pointer;
  color: #b8b7b3;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  margin-left: -1rem;
`;
//cursor-pointer text-gray flex items-center text-2xl -ml-4
