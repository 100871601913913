import React from "react";
import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const BulletSuccess = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <g transform="translate(-1539 -1253)">
      <circle
        cx="9"
        cy="9"
        r="9"
        transform="translate(1539 1253)"
        fill="#46bb63"
      />
      <g transform="translate(1544 1259)">
        <path
          d="M0,2.666,2.667,5.333,8,0"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.5"
        />
      </g>
    </g>
  </SvgIcon>
);
