import { Typography, styled } from "@mui/material";

export const SearchNoResultsStyled = styled("div")`
  display: grid;
  place-items: center;
  gap: 1rem;
`;
export const ContentWrapper = styled("div")`
  display: grid;
  place-items: center;
  gap: 0.5rem;
`;

export const NoResultsTitle = styled(Typography)`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;

export const NoResultsInfo = styled(Typography)`
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
  max-width: 22.313rem;
  text-align: center;
`;
