import React from "react";
import { useAppTranslation } from "@hooks";
import { capitalize } from "lodash";

export const PCFValue = (props: PCFValueProps) => {
  const { name, value, noLongNames, unit = "g" } = props;

  const { t } = useAppTranslation();

  return (
    <span className="flex">
      {!noLongNames && (
        <span className="hidden lg:block">
          {capitalize(`${t(`common.${name}`)}: `)}
        </span>
      )}
      <span className={!noLongNames ? "lg:hidden" : ""}>
        {capitalize(
          `${t(name === "kcal" ? "common.kcal" : `common.${name}_short`)}: `,
        )}
      </span>
      <span className="ml-1 font-semibold">
        {` ${value?.toFixed(0) || 0}`}
        {name !== "kcal" && unit}
      </span>
    </span>
  );
};

interface PCFValueProps {
  name: "kcal" | "protein" | "carbs" | "fats";
  value?: number;
  className?: string;
  /** Prevents rendering long property name on large screens */
  noLongNames?: boolean;
  unit?: string;
}
