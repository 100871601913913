import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useQueryClient } from "@tanstack/react-query";
import { useMediaQuery, useTheme } from "@mui/material";

import { useAppTranslation } from "@hooks";
import { useProgram } from "@hooks/resources/useProgram";
import { ClientProgramMacrosSliders } from "@views/dietician/PatientProgramDays/components";
import {
  deleteProgramDayDietMutation,
  updateProgramDayDietMutation,
} from "@hooks/queries";
import { ApiError } from "@components/ApiError";

import { ProgramDayConfig } from "./components/ProgramDayConfig";
import { SelectDiet } from "./SelectDiet";
import {
  PatientProgramDayGrid,
  WrapperSection,
} from "./PatientProgramDay.styled";
import { DeskDivider } from "./PatientProgramDays.styled";
import { DayCardDietView } from "./components/DayCardDietView";
import { FullWidthSpinner } from "@components/FullWidthSpinner";

export const PatientProgramDay = () => {
  const { t } = useAppTranslation();
  const { dayId } = useParams();
  const { breakpoints } = useTheme();
  const isMatchDesc = useMediaQuery(`${breakpoints.up("desktop")}`);
  const { program, isLoading, isError } = useProgram();
  const queryClient = useQueryClient();

  const [loading, setLoading] = useState(false);

  const dietPresent = program?.days.find(
    ({ id }) => id.toString() === dayId,
  )?.diet;

  const isMutating =
    !!queryClient.isMutating({ mutationKey: [deleteProgramDayDietMutation] }) ||
    !!queryClient.isMutating({ mutationKey: [updateProgramDayDietMutation] });

  useEffect(() => {
    if (isMutating && dietPresent) setLoading(true);
  }, [isMutating]);

  useEffect(() => {
    if ((!isLoading && dietPresent) || isError) setLoading(false);
  }, [isLoading, dietPresent, isError]);

  return (
    <PatientProgramDayGrid>
      <ProgramDayConfig />
      <WrapperSection isDesk={!isMatchDesc}>
        <div className="flex-1">
          <h2 className="text-xl">{t("programs.target_values")}</h2>
          <ClientProgramMacrosSliders dietId={dietPresent?.id} />
        </div>
        {isMatchDesc && <DeskDivider />}
        <div className="grid flex-1">
          {loading && <FullWidthSpinner />}
          {dietPresent && !loading && <DayCardDietView />}
          {!dietPresent && !loading && <SelectDiet />}
          {isError && <ApiError />}
        </div>
      </WrapperSection>
    </PatientProgramDayGrid>
  );
};
