import {
  useAppParams,
  useAppTranslation,
  useUpdateClientMobileAppAccess,
} from "@hooks";
import { useFetchClientQuery } from "@hooks/queries";
import { MonitoringEmptyState } from "@views/emptyStates/MonitoringEmptyState";
import { Spinner } from "@components/Spinner";

import { MonitoringCalendar, MonitoringChartSection } from "./components";
import { MealsSection } from "./components/MealsSection";
import { MonitoringContextProvider } from "./context/MonitoringContextProvider";
import { NavigationSection } from "./components/NavigationSection";
import {
  PatientMonitoringWrapper,
  MonitoringSection,
} from "./PatientMonitoring.styled";
import { ThemeProviderWrapper } from "theme";

export const PatientMonitoring = () => {
  const { t } = useAppTranslation();
  const { patientId } = useAppParams();
  const { client, isLoading: isLoadingClient } = useFetchClientQuery(
    patientId,
    {
      enabled: !!patientId,
    },
  );
  const hasAccess = !!client?.active;
  const hasEmail = !!client?.email;
  const hasEverActivatedMobileApp =
    !!client?.mobileApplication?.firstActivatedAt;

  const { mutate, isLoading } = useUpdateClientMobileAppAccess();

  if (isLoadingClient) return <Spinner />;

  const tooltip = () => {
    if (!hasEmail && !hasAccess)
      return t("empty_states.monitoring_tooltip_both");
    if (!hasEmail) return t("empty_states.monitoring_tooltip_email");
    if (!hasAccess) return t("empty_states.monitoring_tooltip_activation");
    return "";
  };

  if (!hasEverActivatedMobileApp || !hasAccess || !hasEmail)
    return (
      <MonitoringEmptyState
        disabled={!hasAccess || !hasEmail}
        isLoading={isLoading}
        onClick={() => mutate({ id: patientId, payload: { hasAccess: true } })}
        tooltip={tooltip()}
      />
    );

  return (
    <MonitoringContextProvider>
      <ThemeProviderWrapper>
        <PatientMonitoringWrapper>
          <NavigationSection />

          <MonitoringSection>
            <MonitoringCalendar />
            <MealsSection />
          </MonitoringSection>

          <MonitoringChartSection />
        </PatientMonitoringWrapper>
      </ThemeProviderWrapper>
    </MonitoringContextProvider>
  );
};
