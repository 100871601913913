import { MouseEventHandler, useState } from "react";

import { IconButton, SxProps, Theme } from "@mui/material";

import { Star, StarFilled } from "@assets/icons/DesignSystem";

interface FavouriteToggleButtonProps {
  isFavorite: boolean;
  sx?: SxProps<Theme> | undefined;
  onChange: () => void;
  isLoading: boolean;
  className?: string | undefined;
}
export const FavoriteToggleButton = ({
  isFavorite,
  sx,
  onChange,
  isLoading,
  className,
}: FavouriteToggleButtonProps) => {
  const [isFavouriteOptimistic, setIsFavouriteOptimistic] =
    useState(isFavorite);

  const toggleFavourite: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();
    if (isLoading) return;
    setIsFavouriteOptimistic(!isFavouriteOptimistic);
    onChange();
  };

  return (
    <IconButton
      sx={sx}
      size="small"
      color={isFavouriteOptimistic ? "secondary" : undefined}
      onClick={toggleFavourite}
      className={className}
    >
      {isFavouriteOptimistic ? <StarFilled /> : <Star />}
    </IconButton>
  );
};
