import { TabPanel } from "@mui/lab";
import { Card, Divider, styled } from "@mui/material";

export const TabPanelStyled = styled(TabPanel)`
  min-height: max-content;
  &.MuiTabPanel-root {
    padding: 16px;
  }
`;

export const PanelWrapper = styled("div")`
  display: grid;
  gap: 24px;
`;

export const CardStyled = styled(Card)`
  display: grid;
  gap: 16px;
  padding: 16px;
  border-radius: 16px;
  box-shadow: ${({ theme }) => theme.boxShadows.card};
  background: radial-gradient(
    100% 1757.1% at 0% 0%,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border: 2px solid ${({ theme }) => theme.colors.neutral.light[100]};
`;

export const DividerStyled = styled(Divider)`
  border-color: ${({ theme }) => theme.colors.neutral.light[200]};
`;
