import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export async function updateProgramDayNutrients(
  programId: string,
  dayId: string,
  payload: UpdateProgramDayNutrientsRequest,
) {
  await fetchData(
    `/dietitian/programs/${programId}/days/${dayId}/target-nutrients`,
    APIMethods.PUT,
    payload,
  );
}

export interface UpdateProgramDayNutrientsRequest {
  nutrients: {
    id: number;
    value: number;
    visible: boolean;
  }[];
  normId: string;
}
