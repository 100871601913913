import _ from "lodash";

interface ElementChange {
  element: string;
  from: number;
  to: number;
}

// TODO remove when this is merged https://github.com/lodash/lodash/pull/5637
function move<T>(array: T[], oldIndex: number, newIndex: number): T[] {
  const length = array.length;

  if (
    length === 0 ||
    oldIndex === newIndex ||
    oldIndex < 0 ||
    oldIndex >= length
  ) {
    return array;
  }

  if (newIndex >= length) {
    newIndex = length - 1;
  } else if (newIndex < 0) {
    newIndex = 0;
  }

  array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);

  return array;
}

export function detectMovedElement(start: string[], moved: string[]) {
  if (_.isEqual(start, moved)) {
    return null;
  } else if (start.length !== moved.length) {
    throw "Arrays have different length";
  } else {
    for (let from = 0; from < start.length; from++) {
      const to = moved.indexOf(start[from]);
      if (to !== -1 && _.isEqual(move([...start], from, to), moved)) {
        return { from, to };
      }
    }

    throw "Cannot detect single move";
  }
}
