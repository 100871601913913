import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const PlayInCircle = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16" height="17" viewBox="0 0 16 17" {...props}>
      <path
        d="M14.5 8.4993C14.5 6.77539 13.8152 5.12209 12.5962 3.9031C11.3772 2.68412 9.72391 1.9993 8 1.9993C6.27609 1.9993 4.62279 2.68412 3.40381 3.9031C2.18482 5.12209 1.5 6.77539 1.5 8.4993C1.5 10.2232 2.18482 11.8765 3.40381 13.0955C4.62279 14.3145 6.27609 14.9993 8 14.9993C9.72391 14.9993 11.3772 14.3145 12.5962 13.0955C13.8152 11.8765 14.5 10.2232 14.5 8.4993ZM0 8.4993C0 6.37757 0.842855 4.34273 2.34315 2.84244C3.84344 1.34215 5.87827 0.499298 8 0.499298C10.1217 0.499298 12.1566 1.34215 13.6569 2.84244C15.1571 4.34273 16 6.37757 16 8.4993C16 10.621 15.1571 12.6559 13.6569 14.1562C12.1566 15.6564 10.1217 16.4993 8 16.4993C5.87827 16.4993 3.84344 15.6564 2.34315 14.1562C0.842855 12.6559 0 10.621 0 8.4993ZM5.88438 5.09617C6.12188 4.96492 6.40938 4.96805 6.64375 5.1118L11.1438 7.8618C11.3656 7.9993 11.5031 8.23992 11.5031 8.50242C11.5031 8.76492 11.3656 9.00555 11.1438 9.14305L6.64375 11.893C6.4125 12.0337 6.12188 12.0399 5.88438 11.9087C5.64687 11.7774 5.5 11.5274 5.5 11.2555V5.7493C5.5 5.47742 5.64687 5.22742 5.88438 5.09617Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
