import { ProductItemDto, fetchDietProductItems } from "@client/diets";
import { useQuery } from "@tanstack/react-query";
import { ApiResponse } from "@typeDefinitions";
import { QueryOptionsTyped } from "../types";

export const fetchDietProductItemsQueryKey = "fetchDietProductItemsQueryKey";

export const useFetchDietProductItemsQuery = (
  dietId: string,
  productId: string,
  options?: QueryOptionsTyped<ApiResponse<ProductItemDto[]>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchDietProductItemsQueryKey, dietId, productId],
    () => fetchDietProductItems(dietId, productId),
    options,
  );

  return {
    data: data?.data,
    ...rest,
  };
};
