import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  fetchCreatorMealProduct,
  FetchMealResponse,
  ProductDto,
} from "@client/meals";
import { QueryOptionsTyped, fetchCreatorMealQueryKey } from "@hooks/queries";
import { ApiResponse } from "@typeDefinitions";

export const fetchCreatorProductQueryKey = "fetchCreatorProductQueryKey";

export const useFetchCreatorProductQuery = (
  mealId: string,
  productId: string,
  options?: QueryOptionsTyped<ApiResponse<ProductDto>>,
) => {
  const queryClient = useQueryClient();

  const { data, ...rest } = useQuery(
    [fetchCreatorProductQueryKey, mealId, productId],
    () => fetchCreatorMealProduct(mealId, productId),
    {
      ...options,
      onSuccess: product => {
        options?.onSuccess && options.onSuccess(product);
        queryClient.setQueryData<ApiResponse<FetchMealResponse>>(
          [fetchCreatorMealQueryKey, mealId],
          old => {
            if (!old) return;
            return {
              ...old,
              data: {
                ...old.data,
                products: old?.data.products.map(p => {
                  if (!p.isPlaceholder) return p;
                  else return product.data;
                }),
              },
            };
          },
        );
      },
    },
  );

  return {
    product: data?.data,
    ...rest,
  };
};
