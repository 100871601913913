import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const LinkAnchor = (props: SvgIconProps) => {
  return (
    <SvgIcon width="25" height="26" viewBox="0 0 25 26" fill="none" {...props}>
      <path
        d="M19.7938 13.5074L16.2015 16.9765C14.39 18.6952 11.5467 18.6455 9.82747 16.8653C8.20093 15.1491 8.15355 12.4916 9.71565 10.7374L9.87515 10.5526C10.1618 10.2451 10.631 10.222 10.9386 10.5087C11.2461 10.7954 11.2692 11.2646 10.9825 11.5721L10.8548 11.7262C9.77176 12.8949 9.80316 14.677 10.9084 15.8215C12.0442 16.9977 13.9502 17.031 15.1577 15.8956L18.75 12.4266C19.9268 11.2594 19.9596 9.38471 18.8242 8.17721C17.7185 7.06399 15.9386 6.97042 14.7333 7.98078L14.5431 8.13374C14.2257 8.40949 13.7576 8.37007 13.4818 8.0527C13.2061 7.73534 13.2455 7.26721 13.5629 6.99146L13.7531 6.8385C15.5613 5.30733 18.2468 5.44797 19.9051 7.13342C21.6243 8.91369 21.5747 11.757 19.7938 13.5074ZM3.52762 12.5047L7.1512 9.03612C8.93148 7.31693 11.7748 7.36656 13.494 9.14683C15.1523 10.8323 15.1992 13.521 13.6058 15.2747L13.4151 15.4589C13.1596 15.767 12.6586 15.8208 12.3511 15.5341C12.0435 15.2474 12.0205 14.7782 12.3071 14.4707L12.4979 14.2865C13.5497 13.1172 13.5183 11.3351 12.4131 10.1906C11.2778 8.98313 9.3718 8.94986 8.195 10.117L4.57141 13.5855C3.39461 14.7527 3.36189 16.6274 4.49724 17.8349C5.60298 18.9481 7.38287 19.0417 8.58818 18.0313L8.77838 17.8784C9.09574 17.6026 9.56388 17.642 9.83963 17.9594C10.1154 18.2768 10.076 18.7449 9.7586 19.0206L9.5684 19.1736C7.76015 20.7048 5.0747 20.5641 3.41636 18.8787C1.69717 17.0984 1.74734 14.2238 3.52762 12.5047Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
