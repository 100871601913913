import { createContext } from "react";
import { FieldValues, UseFormHandleSubmit } from "react-hook-form";

export interface QuestionContextIf {
  handleSubmit: UseFormHandleSubmit<FieldValues> | undefined;
  groupId: number;
  subgroupId: number;
  questionId: number;
  isTouched: boolean;
}

export interface ValidationContextIf {
  answersList: QuestionContextIf[];
  handleSubmit: () => void;
  updateAnswerControl: (
    groupId: number,
    subgroupId: number,
    questionId: number,
    handleSubmit: UseFormHandleSubmit<FieldValues>,
  ) => void;
  handleTouched: (questionId: number, isTouched: boolean) => void;
  getIsTouched: (questionId: number) => boolean;
}

export const ValidationContext = createContext<ValidationContextIf>({
  answersList: [],
  handleSubmit: () => {
    null;
  },
  updateAnswerControl: () => {
    null;
  },
  handleTouched: () => {
    null;
  },
  getIsTouched: () => false,
});
