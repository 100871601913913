import { fetchProgramSection } from "@client";
import { useQuery } from "@tanstack/react-query";

export const fetchProgramSectionQueryKey = "fetchProgramSectionQueryKey";

export const useFetchProgramSectionQuery = (
  programId: string,
  sectionId: string,
) => {
  const { data, ...rest } = useQuery({
    queryKey: [fetchProgramSectionQueryKey, programId, sectionId],
    queryFn: () => fetchProgramSection(programId, sectionId),
  });

  return {
    data: data?.data,
    ...rest,
  };
};
