import { fetchOauthGoogleLink, OauthGoogleLinkDto } from "@client/oauth";
import { useMutation } from "@tanstack/react-query";
import { MutationOptions } from "../types";

export const useFetchOauthGoogleLinkMutation = (options?: MutationOptions) => {
  return useMutation<OauthGoogleLinkDto, unknown, void>(
    fetchOauthGoogleLink,
    options,
  );
};
