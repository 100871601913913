import { useQuery } from "@tanstack/react-query";

import { QueryOptions } from "./types";
import { fetchProgramDayNutrientsQueryKey } from "./useFetchProgramDayNutrientsQuery";
import { buildDefaultQueryOptions } from "./common";
import { fetchProgramDayNutrients } from "@client";

export const useFetchMultipleProgramDayNutrientsQuery = (
  programId: string,
  dayIds?: string[],
  options?: QueryOptions,
) => {
  if (!dayIds) return [];

  return dayIds.map(dayId => {
    const { data } = useQuery(
      [fetchProgramDayNutrientsQueryKey, programId, dayId],
      () => fetchProgramDayNutrients(programId, dayId),
      buildDefaultQueryOptions(options),
    );
    return {
      dayId,
      normId: data?.data?.norm?.id,
      nutrients: data?.data?.nutrients,
    };
  });
};
