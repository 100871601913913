import { ReactNode } from "react";

import {
  InputAdornment,
  Slider,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { isArray } from "lodash";

import { OuterLabel } from "@components/OuterLabel";
import { MediumThumbComponent } from "@components/SliderThumbs";
import { decimalFourDigitsInput } from "@utils/inputs";

import {
  ContentStyled,
  InputWithSliderStyled,
  SliderStyled,
} from "./MacroComponent.styled";
import { useAutosaveOnBlurContext } from "@components/AutosaveOnBlur";

interface InputWithSliderProps {
  label: string;
  unit: string;
  value: number;
  sliderValue: number;
  onInputChange: (newValue: string) => void;
  onSliderChange: (newValue: number) => void;
  valueLabelFormat: string;
  min: number;
  max: number;
  button?: ReactNode;
  step: number;
  color?: "error" | "primary" | "secondary" | "info" | "success" | "warning";
}
export const InputWithSlider = ({
  label,
  unit,
  value,
  sliderValue,
  onInputChange,
  onSliderChange,
  valueLabelFormat,
  min,
  max,
  button,
  step,
  color,
}: InputWithSliderProps) => {
  const { breakpoints } = useTheme();
  const upMedium = useMediaQuery(`${breakpoints.up("medium")}`);
  const { onBlur, onFocus } = useAutosaveOnBlurContext();

  const buttonFallback = <div className="min-w-[40px] w-[40px]" />;

  if (upMedium)
    return (
      <div>
        <OuterLabel label={label}>
          <ContentStyled>
            <InputWithSliderStyled>
              <TextField
                color={color}
                focused={color === "warning" ? true : undefined}
                value={value}
                onChange={event => onInputChange(event.target.value)}
                size="small"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{unit}</InputAdornment>
                  ),
                  inputComponent: decimalFourDigitsInput,
                }}
                onBlur={onBlur}
                onFocus={onFocus}
              />
              <SliderStyled
                slots={{ thumb: MediumThumbComponent }}
                valueLabelDisplay="on"
                value={sliderValue}
                onChange={(_, value) =>
                  !isArray(value) && onSliderChange(value)
                }
                valueLabelFormat={valueLabelFormat}
                min={min}
                max={max}
                step={step}
                onBlur={onBlur}
                onFocus={onFocus}
              />
            </InputWithSliderStyled>

            {button}
          </ContentStyled>
        </OuterLabel>
      </div>
    );

  return (
    <div>
      <OuterLabel label={label}>
        <ContentStyled>
          <TextField
            value={value}
            onChange={event => onInputChange(event.target.value)}
            size="small"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{unit}</InputAdornment>
              ),
            }}
            onBlur={onBlur}
            onFocus={onFocus}
          />
          <Slider
            slots={{ thumb: MediumThumbComponent }}
            valueLabelDisplay="on"
            value={sliderValue}
            onChange={(_, value) => !isArray(value) && onSliderChange(value)}
            valueLabelFormat={valueLabelFormat}
            min={min}
            max={max}
            step={step}
            onBlur={onBlur}
            onFocus={onFocus}
          />
          {button ?? buttonFallback}
        </ContentStyled>
      </OuterLabel>
    </div>
  );
};
