import { object, array } from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

import {
  GeneratedMealDto,
  generatedMealSchema,
} from "./postDietAutoGenerateMeal";

export const postDietAutoGenerateDiet = async (
  dietId: string,
  payload: PostDietAutoGenerateDietRequest,
): Promise<ApiResponse<GeneratedMealDto[]>> => {
  const response = await fetchData(
    `/dietitian/diets/${dietId}/auto-generate`,
    APIMethods.POST,
    payload,
  );

  return responseSchema.validate(response);
};

export interface PostDietAutoGenerateDietRequest {
  tagsId: number[];
}

const responseSchema = object({
  data: array().of(generatedMealSchema.required()).required(),
});
