import {
  PdfClinicType,
  PdfContentSectionType,
  PdfDaysScheduleType,
  PdfDietitianType,
  PdfFoodMenuType,
  PdfMealsSchemeType,
  PdfMealsSuggestedType,
  PdfDisplayType,
  PdfPageReferenceType,
  PdfPatientType,
  PdfResponseType,
  PdfSectionResponseType,
  PdfShippingListType,
} from "@Pdf/types/pdfTypes";
import { updateImages } from "./pdfImageUtils";
import {
  getDaysScheduleSectionContent,
  getMealsSchemeSectionContent,
  getMealsSuggestedSectionContent,
  getContentSectionContent,
  getShoppingListSectionContent,
  getFoodMenuSectionContent,
} from "@Pdf/utils/pdfSectionUtils";
import {
  getBackground,
  getCoverHandDrawnPage,
  getCoverPage,
  getCoverPrintPage,
  getFooter,
  getHeader,
  getTableOfContent,
} from "@Pdf/utils/pdfDecoratorsUtils";
import { Content, PageBreak, TDocumentDefinitions } from "pdfmake/interfaces";
import {
  getHorizontalDividerContent,
  getPageHeaderContent,
} from "./pdfContentComponentsUtils";
import { NutrientDetails } from "@hooks";
import { migrateNotSupportedHTMLContentStyle } from "@Pdf/utils/htmlToPdfUtils";
import { PDF_COLORS, PDF_DISPLAY_CODE } from "@Pdf/defaults/pdfDefaults";
import { rgb2hex } from "@Pdf/utils/stringUtils";
import { isArray } from "@Pdf/utils/guards";

// playground requires you to assign document definition to a variable called dd

export const updatePdfSectionClientSide = async (
  section: PdfSectionResponseType,
  htmlContentCache: Record<number, Content>,
  print: boolean,
) => {
  let imageRefs: Record<string, string> = {};

  switch (section.type) {
    case "content": {
      const tmp = section as PdfContentSectionType;

      const { images, content } = getContentSectionContent(tmp, print);

      htmlContentCache[section.id] = await migrateNotSupportedHTMLContentStyle(
        content,
        images,
      );

      imageRefs = { ...imageRefs, ...images };

      break;
    }
    case "days_schedule": {
      if (!print) {
        const tmp = section as PdfDaysScheduleType;

        for (let i = 0; i < tmp.data.length; i += 1) {
          const item = tmp.data[i];
          for (let k = 0; k < item.meals.length; k += 1) {
            const meal = item.meals[k];

            if (meal.mealDescription.image) {
              const imagePart = meal.mealDescription.image.split("/");
              const [ref] = imagePart[imagePart.length - 1].split(".");

              if (ref) {
                imageRefs[ref] = meal.mealDescription.image;
              }
            }
          }
        }
      }

      break;
    }
    case "meals_suggested": {
      if (!print) {
        const tmp = section as PdfMealsSuggestedType;

        for (let i = 0; i < tmp.data.length; i += 1) {
          const item = tmp.data[i];
          for (let j = 0; j < item.mealsType.length; j += 1) {
            const mealType = item.mealsType[j];
            for (let k = 0; k < mealType.meals.length; k += 1) {
              const meal = mealType.meals[k];

              if (meal.description?.image) {
                const imagePart = meal.description.image.split("/");
                const [ref] = imagePart[imagePart.length - 1].split(".");

                if (ref) {
                  imageRefs[ref] = meal.description.image;
                }
              }
            }
          }
        }
      }

      break;
    }
    default:
      break;
  }

  return imageRefs;
};

export const getPdfSectionClientSide = async (
  content: PdfSectionResponseType,
) => {
  const htmlContentCache: Record<number, Content> = {};
  const imageRefs = await updatePdfSectionClientSide(
    content,
    htmlContentCache,
    false,
  );

  await updateImages(imageRefs);

  return { htmlContentCache, imageRefs };
};

export const getPdfClientSide = async (
  content: PdfResponseType,
  coverPageType: string,
  coverPageId: string,
) => {
  let imageRefs: Record<string, string> = {
    coverPage: coverPageId,
  };
  const htmlContentCache: Record<number, Content> = {};

  if (content.clinic.logoUrl) {
    imageRefs.logoUrl = content.clinic.logoUrl;
  }

  for (const section of content.sections) {
    imageRefs = {
      ...imageRefs,
      ...(await updatePdfSectionClientSide(
        section,
        htmlContentCache,
        coverPageType === "print",
      )),
    };
  }

  await updateImages(imageRefs);

  return { htmlContentCache, imageRefs };
};

export const getPdfSectionDefinition = (
  section: PdfSectionResponseType,
  htmlContentCache: Record<number, Content>,
  imageRefs: Record<string, string>,
  nutrientDict: Record<number, NutrientDetails>,
  primaryColor: string,
  primaryColorMid: string,
  primaryColorExtraLight: string,
  coverPageType: string,
  translations: Record<string, string>,
  display: PdfDisplayType,
  pageBreak?: PageBreak,
) => {
  // Do not do anything once the section doesnt have any data
  if (isArray(section.data) && !section.data.length) return null;
  switch (section.type) {
    case "content": {
      const tmp = section as PdfContentSectionType;

      return {
        pageReference: {
          id: `${tmp.id}`,
          title: tmp.data.title,
        },
        content: [
          getPageHeaderContent(
            tmp.data.title,
            `${tmp.id}`,
            [0, 0, 0, 0],
            pageBreak,
          ),
          getHorizontalDividerContent(),
          htmlContentCache[tmp.id] ?? [],
        ],
      };
    }
    case "days_schedule": {
      const tmp = section as PdfDaysScheduleType;

      return {
        pageReference: {
          id: `${tmp.id}`,
          title: tmp.name,
        },
        content: [
          getPageHeaderContent(tmp.name, `${tmp.id}`, [0, 0, 0, 0], pageBreak),
          ...getDaysScheduleSectionContent(
            tmp,
            imageRefs,
            nutrientDict,
            primaryColor,
            coverPageType,
            translations,
            display,
          ),
        ],
      };
    }
    case "meals_scheme": {
      const tmp = section as PdfMealsSchemeType;

      return {
        pageReference: {
          id: `${tmp.id}`,
          title: tmp.name,
        },
        content: [
          getPageHeaderContent(tmp.name, `${tmp.id}`, [0, 0, 0, 0], pageBreak),
          getHorizontalDividerContent(),
          ...getMealsSchemeSectionContent(
            tmp,
            nutrientDict,
            primaryColor,
            coverPageType,
            translations,
            display,
          ),
        ],
      };
    }
    case "meals_suggested": {
      const tmp = section as PdfMealsSuggestedType;

      return {
        pageReference: {
          id: `${tmp.id}`,
          title: tmp.name,
        },
        content: [
          getPageHeaderContent(tmp.name, `${tmp.id}`, [0, 0, 0, 0], pageBreak),
          getHorizontalDividerContent(),
          ...getMealsSuggestedSectionContent(
            tmp,
            imageRefs,
            nutrientDict,
            primaryColor,
            coverPageType,
            translations,
            display,
          ),
        ],
      };
    }
    case "shipping_list": {
      const tmp = section as PdfShippingListType;

      return {
        pageReference: {
          id: `${tmp.id}`,
          title: tmp.name,
        },
        content: [
          getPageHeaderContent(tmp.name, `${tmp.id}`, [0, 0, 0, 0], pageBreak),
          getHorizontalDividerContent(),
          ...getShoppingListSectionContent(tmp, coverPageType, translations),
        ],
      };
    }
    case "food_menu": {
      const tmp = section as PdfFoodMenuType;

      return {
        pageReference: {
          id: `${tmp.id}`,
          title: tmp.name,
        },
        content: [
          {
            stack: getFoodMenuSectionContent(
              tmp,
              coverPageType,
              translations,
              nutrientDict,
              primaryColor,
              primaryColorMid,
              primaryColorExtraLight,
              display,
            ),
            pageOrientation: "landscape",
            pageBreak,
          },
        ],
      };
    }
    default:
      console.error("Not supported section", section);
      return null;
  }
};

export const getPdfDefinition = (
  content: PdfResponseType,
  htmlContentCache: Record<number, Content>,
  imageRefs: Record<string, string>,
  nutrientDict: Record<number, NutrientDetails>,
  primaryColor: string,
  primaryColorMid: string,
  primaryColorLight: string,
  primaryColorExtraLight: string,
  coverPageType: string,
  title: string,
  translations: Record<string, string>,
  display: PdfDisplayType,
): Content => {
  const pageReferences: Array<PdfPageReferenceType> = [];
  const contentItems: Array<any> = [];

  for (const section of content.sections) {
    const definition = getPdfSectionDefinition(
      section,
      htmlContentCache,
      imageRefs,
      nutrientDict,
      primaryColor,
      primaryColorMid,
      primaryColorExtraLight,
      coverPageType,
      translations,
      display,
      "before",
    );

    if (definition) {
      pageReferences.push(definition.pageReference);
      if (isArray(definition.content)) {
        contentItems.push(...definition.content);
      } else {
        contentItems.push(definition.content);
      }
    }
  }

  const stack: Array<Content> = [];

  if (coverPageType === "photo") {
    stack.push(
      getCoverPage(
        primaryColor,
        content.program.startDate,
        title,
        content.patient,
        content.clinic,
        content.dietitian,
        imageRefs,
        translations,
        display,
      ),
    );
  } else if (coverPageType === "hand-drawn") {
    stack.push(
      getCoverHandDrawnPage(
        primaryColor,
        primaryColorLight,
        content.program.startDate,
        title,
        content.patient,
        content.clinic,
        content.dietitian,
        imageRefs,
        translations,
        display,
      ),
    );
  } else {
    stack.push(
      getCoverPrintPage(
        primaryColor,
        content.program.startDate,
        title,
        content.patient,
        content.clinic,
        content.dietitian,
        imageRefs,
        translations,
        display,
      ),
    );
  }

  if (pageReferences.length) {
    stack.push(
      getTableOfContent(
        pageReferences,
        primaryColor,
        primaryColorLight,
        primaryColorExtraLight,
        translations,
      ),
    );
  }

  stack.push(...contentItems);

  return {
    stack,
  };
};

export const getSectionPdf = (
  section: PdfSectionResponseType,
  patient: PdfPatientType,
  clinic: PdfClinicType,
  dietitian: PdfDietitianType,
  htmlContentCache: Record<number, Content>,
  imageRefs: Record<string, string>,
  nutrientDict: Record<number, NutrientDetails>,
  primaryColor: string,
  translations: Record<string, string>,
  weekdays: Array<string>,
  options: Set<string>,
  clinicOptions: Set<string>,
  kcal: boolean,
  micros: Array<number>,
  macros: boolean,
): TDocumentDefinitions => {
  const primaryColorMid = rgb2hex(primaryColor, 0.3);
  const primaryColorLight = rgb2hex(primaryColor, 0.2);
  const primaryColorExtraLight = rgb2hex(primaryColor, 0.05);

  const display = {
    weekdays: options.has(PDF_DISPLAY_CODE.WEEKDAY) ? weekdays : [],
    logo: options.has(PDF_DISPLAY_CODE.LOGO),
    clinicName: clinicOptions.has(PDF_DISPLAY_CODE.CLINIC_NAME),
    clinicAddress: clinicOptions.has(PDF_DISPLAY_CODE.CLINIC_ADDRESS),
    clinicEmail: clinicOptions.has(PDF_DISPLAY_CODE.CLINIC_EMAIL),
    clinicLogo: clinicOptions.has(PDF_DISPLAY_CODE.CLINIC_LOGO),
    clinicPhone: clinicOptions.has(PDF_DISPLAY_CODE.CLINIC_PHONE),
    date: options.has(PDF_DISPLAY_CODE.DATE),
    hour: options.has(PDF_DISPLAY_CODE.MEAL_HOUR),
    dayType: options.has(PDF_DISPLAY_CODE.DAY_TYPE),
    kcal,
    micros,
    macros,
  };

  const definition = getPdfSectionDefinition(
    section,
    htmlContentCache,
    imageRefs,
    nutrientDict,
    primaryColor,
    primaryColorMid,
    primaryColorExtraLight,
    "photo",
    translations,
    display,
  );

  let content: Content = [];

  if (definition) {
    content = definition.content as Content;
  }

  return {
    content,
    pageOrientation: section.type === "food_menu" ? "landscape" : "portrait",
    header() {
      return getHeader(clinic, patient, primaryColor, display);
    },
    footer(currentPage: number, maxPage: number) {
      return getFooter(
        currentPage,
        maxPage,
        clinic,
        dietitian,
        primaryColor,
        false,
        translations,
        display,
      );
    },
    background() {
      return getBackground(primaryColorLight);
    },
    pageBreakBefore: function (currentNode) {
      return (
        (currentNode.style as Array<string>)?.indexOf("pdf-pagebreak-before") >
        -1
      );
    },
    pageMargins: [43, 71, 43, 43],
    defaultStyle: {
      font: "Figtree",
      color: "#333333",
      markerColor: "#333333",
    },
    styles: {
      cellStyle: {
        margin: [4, 4, 4, 4],
      },
      verticalSeparator: {
        color: "#F1F1F1",
        alignment: "center",
      },
      FONT_5_MEDIUM: {
        fontSize: 5,
        font: "MediumFigtree",
      },
      FONT_8_MEDIUM: {
        fontSize: 8,
        font: "MediumFigtree",
      },
      FONT_10_MEDIUM: {
        fontSize: 10,
        font: "MediumFigtree",
      },
      FONT_12_MEDIUM: {
        fontSize: 12,
        font: "MediumFigtree",
      },
      FONT_14_MEDIUM: {
        fontSize: 14,
        font: "MediumFigtree",
      },
      FONT_16_MEDIUM: {
        fontSize: 16,
        font: "MediumFigtree",
      },
      FONT_6_SEMI_BOLD: {
        fontSize: 6,
        font: "SemiFigtree",
      },
      FONT_8_SEMI_BOLD: {
        fontSize: 8,
        font: "SemiFigtree",
      },
      FONT_10_SEMI_BOLD: {
        fontSize: 10,
        font: "SemiFigtree",
      },
      FONT_12_SEMI_BOLD: {
        fontSize: 12,
        font: "SemiFigtree",
      },
      FONT_18_SEMI_BOLD: {
        fontSize: 18,
        font: "SemiFigtree",
      },
      FONT_46_SEMI_BOLD: {
        fontSize: 46,
        font: "SemiFigtree",
      },
      PRIMARY_COLOR: {
        color: primaryColor,
      },
      PRIMARY_FILL_COLOR: {
        fillColor: primaryColor,
      },
      NEUTRAL_DARK_200_COLOR: {
        color: PDF_COLORS.NEUTRAL_DARK_200_COLOR,
      },
      NEUTRAL_DARK_600_COLOR: {
        color: PDF_COLORS.NEUTRAL_DARK_600_COLOR,
      },
      NEUTRAL_DARK_700_COLOR: {
        color: PDF_COLORS.NEUTRAL_DARK_700_COLOR,
      },
      NEUTRAL_DARK_800_COLOR: {
        color: PDF_COLORS.NEUTRAL_DARK_800_COLOR,
      },
      NEUTRAL_DARK_900_COLOR: {
        color: PDF_COLORS.NEUTRAL_DARK_900_COLOR,
      },
      NEUTRAL_DARK_700_FILL_COLOR: {
        color: PDF_COLORS.NEUTRAL_DARK_700_COLOR,
      },
      NEUTRAL_DARK_800_FILL_COLOR: {
        color: PDF_COLORS.NEUTRAL_DARK_800_COLOR,
      },
      NEUTRAL_DARK_900_FILL_COLOR: {
        color: PDF_COLORS.NEUTRAL_DARK_900_COLOR,
      },
    },
    images: imageRefs,
  };
};

export const getPdf = (
  content: PdfResponseType,
  htmlContentCache: Record<number, Content>,
  imageRefs: Record<string, string>,
  nutrientDict: Record<number, NutrientDetails>,
  primaryColor: string,
  coverPageType: string,
  title: string,
  translations: Record<string, string>,
  weekdays: Array<string>,
  options: Set<string>,
  clinicOptions: Set<string>,
  kcal: boolean,
  micros: Array<number>,
  macros: boolean,
): TDocumentDefinitions => {
  const primaryColorMid = rgb2hex(primaryColor, 0.3);
  const primaryColorLight = rgb2hex(primaryColor, 0.2);
  const primaryColorExtraLight = rgb2hex(primaryColor, 0.05);
  const display = {
    weekdays: options.has(PDF_DISPLAY_CODE.WEEKDAY) ? weekdays : [],
    logo: options.has(PDF_DISPLAY_CODE.LOGO),
    clinicName: clinicOptions.has(PDF_DISPLAY_CODE.CLINIC_NAME),
    clinicAddress: clinicOptions.has(PDF_DISPLAY_CODE.CLINIC_ADDRESS),
    clinicEmail: clinicOptions.has(PDF_DISPLAY_CODE.CLINIC_EMAIL),
    clinicLogo: clinicOptions.has(PDF_DISPLAY_CODE.CLINIC_LOGO),
    clinicPhone: clinicOptions.has(PDF_DISPLAY_CODE.CLINIC_PHONE),
    date: options.has(PDF_DISPLAY_CODE.DATE),
    hour: options.has(PDF_DISPLAY_CODE.MEAL_HOUR),
    dayType: options.has(PDF_DISPLAY_CODE.DAY_TYPE),
    kcal,
    micros,
    macros,
  };

  return {
    content: getPdfDefinition(
      content,
      htmlContentCache,
      imageRefs,
      nutrientDict,
      primaryColor,
      primaryColorMid,
      primaryColorLight,
      primaryColorExtraLight,
      coverPageType,
      title,
      translations,
      display,
    ),
    header(currentPage: number) {
      // Do not add any header for cover page
      if (currentPage === 1) return;

      return getHeader(content.clinic, content.patient, primaryColor, display);
    },
    footer(currentPage: number, maxPage: number) {
      // Do not add any footer for cover page
      if (currentPage === 1) return;

      return getFooter(
        currentPage,
        maxPage,
        content.clinic,
        content.dietitian,
        primaryColor,
        coverPageType === "print",
        translations,
        display,
      );
    },
    background(currentPage) {
      // Do not add any background for cover page
      if (
        coverPageType === "print" ||
        (coverPageType === "photo" && currentPage === 1)
      )
        return undefined;

      return getBackground(primaryColorLight);
    },
    pageBreakBefore: function (currentNode) {
      return (
        (currentNode.style as Array<string>)?.indexOf("pdf-pagebreak-before") >
        -1
      );
    },
    pageMargins: [43, 71, 43, 43],
    defaultStyle: {
      font: "Figtree",
      color: "#333333",
      markerColor: "#333333",
    },
    styles: {
      cellStyle: {
        margin: [4, 4, 4, 4],
      },
      verticalSeparator: {
        color: "#F1F1F1",
        alignment: "center",
      },
      FONT_5_MEDIUM: {
        fontSize: 5,
        font: "MediumFigtree",
      },
      FONT_8_MEDIUM: {
        fontSize: 8,
        font: "MediumFigtree",
      },
      FONT_10_MEDIUM: {
        fontSize: 10,
        font: "MediumFigtree",
      },
      FONT_12_MEDIUM: {
        fontSize: 12,
        font: "MediumFigtree",
      },
      FONT_14_MEDIUM: {
        fontSize: 14,
        font: "MediumFigtree",
      },
      FONT_16_MEDIUM: {
        fontSize: 16,
        font: "MediumFigtree",
      },
      FONT_6_SEMI_BOLD: {
        fontSize: 6,
        font: "SemiFigtree",
      },
      FONT_8_SEMI_BOLD: {
        fontSize: 8,
        font: "SemiFigtree",
      },
      FONT_10_SEMI_BOLD: {
        fontSize: 10,
        font: "SemiFigtree",
      },
      FONT_12_SEMI_BOLD: {
        fontSize: 12,
        font: "SemiFigtree",
      },
      FONT_18_SEMI_BOLD: {
        fontSize: 18,
        font: "SemiFigtree",
      },
      FONT_46_SEMI_BOLD: {
        fontSize: 46,
        font: "SemiFigtree",
      },
      PRIMARY_COLOR: {
        color: primaryColor,
      },
      PRIMARY_FILL_COLOR: {
        fillColor: primaryColor,
      },
      NEUTRAL_DARK_200_COLOR: {
        color: PDF_COLORS.NEUTRAL_DARK_200_COLOR,
      },
      NEUTRAL_DARK_700_COLOR: {
        color: PDF_COLORS.NEUTRAL_DARK_700_COLOR,
      },
      NEUTRAL_DARK_800_COLOR: {
        color: PDF_COLORS.NEUTRAL_DARK_800_COLOR,
      },
      NEUTRAL_DARK_900_COLOR: {
        color: PDF_COLORS.NEUTRAL_DARK_900_COLOR,
      },
      NEUTRAL_DARK_700_FILL_COLOR: {
        color: PDF_COLORS.NEUTRAL_DARK_700_COLOR,
      },
      NEUTRAL_DARK_800_FILL_COLOR: {
        color: PDF_COLORS.NEUTRAL_DARK_800_COLOR,
      },
      NEUTRAL_DARK_900_FILL_COLOR: {
        color: PDF_COLORS.NEUTRAL_DARK_900_COLOR,
      },
    },
    images: imageRefs,
  };
};
