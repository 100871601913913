import { SvgIcon, SvgIconProps } from "@mui/material";

export const Carrot = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      d="M3.47356 18.5998C3.39271 18.7533 3.35511 18.9242 3.36547 19.0909C3.37583 19.2576 3.43367 19.4128 3.53176 19.5368C3.62986 19.6608 3.76382 19.7482 3.91678 19.788C4.06974 19.8277 4.23489 19.8181 4.39146 19.7603C7.45308 18.7711 10.4053 17.3454 13.1601 15.5255C13.725 15.1729 14.1647 14.6267 14.395 13.9914C14.6253 13.3561 14.6301 12.6763 14.4085 12.082L14.3897 12.0374C13.9423 11.0125 13.2523 10.14 12.3785 9.49467L12.3405 9.46727C11.8273 9.13253 11.1954 9.01948 10.5657 9.1498C9.93597 9.28012 9.35259 9.64465 8.92719 10.1736C6.75965 12.7359 4.92354 15.5728 3.47356 18.5998Z"
      stroke={props.fill ?? "currentColor"}
      strokeMiterlimit="10"
      fill="none"
    />
    <path
      d="M13.7951 14.8501L12.3362 13.0071"
      stroke={props.fill ?? "currentColor"}
      strokeMiterlimit="10"
      strokeLinecap="round"
      fill="none"
    />
    <path
      d="M6.4765 13.5381L7.93537 15.3811"
      stroke={props.fill ?? "currentColor"}
      strokeMiterlimit="10"
      strokeLinecap="round"
      fill="none"
    />
    <path
      d="M15.4013 3.91377L15.3957 3.8993C15.3846 3.87194 15.3739 3.84619 15.3625 3.82197C15.351 3.79776 15.3347 3.76122 15.3189 3.73156C15.3032 3.70189 15.2895 3.67598 15.2758 3.65378L15.2654 3.64066C15.2402 3.5986 15.212 3.55885 15.1809 3.52165C14.97 3.255 14.6701 3.08149 14.3318 3.03049C13.9935 2.97948 13.6376 3.0541 13.3242 3.2417C13.0107 3.4293 12.759 3.71839 12.6115 4.06017C12.4639 4.40195 12.4296 4.77549 12.5143 5.11766C12.2605 5.24635 12.0521 5.46361 11.9285 5.72834C11.8049 5.99308 11.7748 6.2869 11.8437 6.55427C11.9126 6.82164 12.0758 7.04399 12.3025 7.17925C12.5292 7.31452 12.8035 7.35332 13.0738 7.28831C13.2942 7.47728 13.4629 7.72307 13.5658 8.00512C13.6688 8.28716 13.703 8.5973 13.6655 8.90967C15.9394 6.20014 15.6752 4.61249 15.4013 3.91377Z"
      stroke={props.fill ?? "currentColor"}
      strokeMiterlimit="10"
      strokeLinecap="round"
      fill="none"
    />
    <path
      d="M20.101 9.85119L20.1134 9.85963C20.1369 9.8759 20.159 9.89159 20.1794 9.90775C20.1999 9.9239 20.231 9.94737 20.2558 9.96898C20.2806 9.99059 20.3023 10.0094 20.3205 10.0276L20.3309 10.0407C20.3657 10.0744 20.3979 10.111 20.4271 10.1502C20.638 10.4169 20.7469 10.7603 20.7355 11.1227C20.7241 11.485 20.5931 11.8439 20.3646 12.1389C20.136 12.4338 19.824 12.6466 19.4811 12.7415C19.1383 12.8363 18.7856 12.8073 18.4827 12.6593C18.3194 12.9029 18.0814 13.0837 17.8138 13.1673C17.5462 13.251 17.2677 13.2316 17.0311 13.1129C16.7945 12.9942 16.6163 12.7845 16.5303 12.5236C16.4443 12.2626 16.4566 11.9686 16.5647 11.6973C16.4275 11.4342 16.2287 11.2152 15.9848 11.0587C15.7409 10.9022 15.459 10.8126 15.1625 10.7974C18.0719 8.89141 19.5 9.44368 20.101 9.85119Z"
      stroke={props.fill ?? "currentColor"}
      strokeMiterlimit="10"
      strokeLinecap="round"
      fill="none"
    />
    <path
      d="M20.0116 8.87037C20.3665 8.55452 20.6087 8.12326 20.6917 7.6597C20.7747 7.19614 20.6925 6.73304 20.461 6.35956C20.6598 6.06913 20.7675 5.72733 20.7675 5.3867C20.7676 5.04608 20.6599 4.72546 20.4611 4.47412C20.2623 4.22277 19.9834 4.05462 19.6672 3.99549C19.351 3.93635 19.015 3.98951 18.7109 4.1468C18.4044 3.84011 17.9888 3.67386 17.5441 3.68006C17.0994 3.68627 16.657 3.86446 16.3022 4.18037"
      stroke={props.fill ?? "currentColor"}
      strokeMiterlimit="10"
      strokeLinecap="round"
      fill="none"
    />
  </SvgIcon>
);
