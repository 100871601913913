import { styled } from "@mui/material";
import { getHexColorOpacity } from "@utils";

export const Text = styled("span")`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const TextSemiBold = styled(Text)`
  font-weight: 500;
`;

export const TextBold = styled(TextSemiBold)`
  font-weight: 600;
`;

export const LetterCircle = styled("span")`
  display: grid;
  place-items: center;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.primary.light};
  background-color: ${({ theme }) =>
    getHexColorOpacity(theme.colors.neutral.dark[700], 10)};
  font-size: 8px;
  font-weight: 500;
`;

export const Wrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: 2px;
`;
