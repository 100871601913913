import { Box, IconButton, Menu, styled, Typography } from "@mui/material";
import { CounterBubble } from "../../MiniCalendar/MiniCalendar.styled";

export const CounterBubbleButton = styled(CounterBubble)`
  cursor: pointer;
`;

export const MenuWrapper = styled(Menu)`
  & .MuiList-root {
    width: 300px;
    padding: 12px;
  }
`;

export const MenuHeader = styled("header")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const MenuHeaderTitle = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
`;

export const MenuStyledIconButton = styled(IconButton)`
  font-size: 12px;
  padding: 0;
`;

export const MenuItemEvents = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const MenuItemEventContent = styled("p")`
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 2px;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  font-weight: 500;
  gap: 8px;
`;
