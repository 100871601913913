import { Dialog } from "@mui/material";

import { Close } from "@assets/icons/DesignSystem";
import {
  DialogTitleStyled,
  IconButtonStyled,
} from "@components/ModalWrapperNew/ModalWrapper.styled";
import { useAppTranslation } from "@hooks";
import { DialogContentStyled } from "./LearnMoreModal.styled";

interface LearnMoreModalProps {
  open: boolean;
  onClose: () => void;
}
export const LearnMoreModal = ({ open, onClose }: LearnMoreModalProps) => {
  const { t } = useAppTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionProps={{ unmountOnExit: true }}
    >
      <DialogTitleStyled>
        {t("integrations.google_calendar.title")}
      </DialogTitleStyled>

      <IconButtonStyled onClick={onClose} size="small">
        <Close size="w-[12px] h-[12px]" />
      </IconButtonStyled>

      <DialogContentStyled>
        <p>{t("integrations.google_calendar.desc_1")}</p>
        <p>
          {t("integrations.google_calendar.desc_2")}
          <ul className="ml-[4px]">
            <li>{t("integrations.google_calendar.desc_3")}</li>
            <li>{t("integrations.google_calendar.desc_4")}</li>
          </ul>
        </p>
      </DialogContentStyled>
    </Dialog>
  );
};
