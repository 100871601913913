import { ReactNode, useMemo, useState } from "react";

import dayjs from "dayjs";

import { useAppParams } from "@hooks";
import {
  useFetchMonitoringProgramDayCalendarQuery,
  useFetchMonitoringScheduleCalendarQuery,
} from "@hooks/queries";

import { MonitoringContext, ToggleButtonValue } from "./MonitoringContext";

interface MonitroingContextProviderProps {
  children: ReactNode;
}

export const MonitoringContextProvider = ({
  children,
}: MonitroingContextProviderProps) => {
  const { patientId } = useAppParams();
  const [toggleButton, setToggleButton] = useState<ToggleButtonValue>(
    ToggleButtonValue.PROGRAM,
  );
  const [selectedDayDate, setSelectedDayDate] = useState(
    dayjs().format("YYYY-MM-DD"),
  );
  const [month, setMonth] = useState(dayjs());

  const { programDayDays } = useFetchMonitoringProgramDayCalendarQuery(
    patientId,
    month.startOf("month").format("YYYY-MM-DD"),
    month.endOf("month").format("YYYY-MM-DD"),
    { enabled: toggleButton === ToggleButtonValue.PROGRAM },
  );
  const { scheduleDays } = useFetchMonitoringScheduleCalendarQuery(
    patientId,
    month.startOf("month").format("YYYY-MM-DD"),
    month.endOf("month").format("YYYY-MM-DD"),
    { enabled: toggleButton === ToggleButtonValue.SCHEDULE },
  );

  const selectedDayDetails = useMemo(
    () =>
      toggleButton === ToggleButtonValue.PROGRAM
        ? programDayDays?.find(({ date }) => date === selectedDayDate)
        : scheduleDays?.find(({ date }) => date === selectedDayDate),
    [programDayDays, scheduleDays, selectedDayDate, toggleButton],
  );

  const days = useMemo(
    () =>
      toggleButton === ToggleButtonValue.PROGRAM
        ? programDayDays
        : scheduleDays,
    [programDayDays, scheduleDays, toggleButton],
  );

  const value = useMemo(
    () => ({
      toggleButton,
      setToggleButton,
      month,
      setMonth,
      selectedDayDate,
      setSelectedDayDate,
      selectedDayDetails,
      days,
    }),
    [
      toggleButton,
      setToggleButton,
      selectedDayDate,
      setSelectedDayDate,
      month,
      setMonth,
      selectedDayDetails,
      days,
    ],
  );

  return (
    <MonitoringContext.Provider value={value}>
      {children}
    </MonitoringContext.Provider>
  );
};
