import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse, LanguagesSlugs } from "@typeDefinitions";
import * as yup from "yup";
import { logFetchValidateError } from "@utils/validate";

export type FetchTagsProgramPreviewParams = number;
export type FetchTagsProgramPreviewResponse = ApiResponse<
  TagProgramPreviewSchema[]
>;
export const fetchTagsProgramPreview = async (
  programId: FetchTagsProgramPreviewParams,
): Promise<FetchTagsProgramPreviewResponse> => {
  const response = await fetchData(
    `/dietitian/programs-search-preview/${programId}/tags`,
    APIMethods.GET,
  );

  return await fetchTagsProgramPreviewSchema
    .validate(response)
    .catch(logFetchValidateError("fetchTagsProgramPreview"));
};

export interface TagProgramPreviewSchema {
  id: number;
  translations: {
    lang: LanguagesSlugs;
    name: string;
  }[];
}

const fetchTagsProgramPreviewSchema = yup.object({
  data: yup
    .array()
    .of(
      yup.object({
        id: yup.number().required(),
        translations: yup
          .array()
          .of(
            yup.object({
              lang: yup
                .mixed<LanguagesSlugs>()
                .oneOf(Object.values(LanguagesSlugs))
                .required(),
              name: yup.string().required(),
            }),
          )
          .required(),
      }),
    )
    .defined(),
});
