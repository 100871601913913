import * as yup from "yup";

import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";

import { DictionaryNameDto, dictionaryNameSchema } from "./common";
import { logFetchValidateError } from "@utils/validate";

export const fetchClientTargets = async (): Promise<
  ApiResponse<TargetDto[]>
> => {
  const response = await fetchData(
    `/dietitian/patient/profile/dictionaries/targets`,
    APIMethods.GET,
  );

  return await fetchClientTargetsSchema
    .validate(response)
    .catch(logFetchValidateError("fetchClientTargets"));
};

const targetSchema = yup.object({
  id: yup.number().required(),
  names: yup
    .array()
    .of(
      yup.object({
        lang: yup.mixed<"pl" | "en">().oneOf(["pl", "en"]).required(),
        value: yup.string().required(),
      }),
    )
    .required(),
  sex: yup.mixed<"m" | "w">().oneOf(["m", "w"]).required(),
});

const fetchClientTargetsSchema = yup.object({
  data: yup.array().of(targetSchema).required(),
});

export interface TargetDto {
  id: number;
  names: DictionaryNameDto[];
  sex: "m" | "w";
}
