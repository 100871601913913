import { APIMethods, ApiResponse, LanguagesSlugs } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import * as yup from "yup";
import {
  DietSearchPreviewResourceDto,
  dietSearchPreviewResourceSchema,
} from "@client/diets";
import { NutrientDto, nutrientSchema } from "@client";
import { logFetchValidateError } from "@utils/validate";

export interface FetchDietMealProgramPreviewParams {
  programId: number;
  dietMealId: number;
}

export type FetchDietMealProgramPreviewResponse =
  ApiResponse<DietMealProgramPreview>;

export const fetchDietMealProgramPreview = async ({
  programId,
  dietMealId,
}: FetchDietMealProgramPreviewParams): Promise<FetchDietMealProgramPreviewResponse> => {
  const response = await fetchData(
    `/dietitian/programs-search-preview/${programId}/diet-meals/${dietMealId}`,
    APIMethods.GET,
  );

  return await responseSchema
    .validate(response)
    .catch(logFetchValidateError("fetchDietMealProgramPreview"));
};

type DietMealItemRecipe = {
  id: number;
  servings: number;
  nutrients: NutrientDto[];
  recipe: {
    id: number;
    servings: number;
    name: string;
    translations: {
      lang: LanguagesSlugs;
      name: string | null;
    }[];
    image: {
      url: string;
    } | null;
  };
};

type DietMealItemProduct = {
  id: number;
  grams: number;
  nutrients: NutrientDto[];
  product: {
    id: number;
    name: string;
    translations: {
      lang: LanguagesSlugs;
      name: string | null;
    }[];
    image: {
      url: string;
    } | null;
  };
};

export type DietMealProgramPreview = {
  id: number;
  name: string;
  translations: {
    lang: LanguagesSlugs;
    name: string | null;
  }[];
  nutrients: NutrientDto[];
  recipes: DietMealItemRecipe[];
  products: DietMealItemProduct[];
};

const recipeSchema = yup.object({
  id: yup.number().required(),
  name: yup.string().required(),
  translations: yup
    .array()
    .of(
      yup.object({
        lang: yup
          .mixed<LanguagesSlugs>()
          .oneOf(Object.values(LanguagesSlugs))
          .required(),
        name: yup.string().nullable().defined(),
      }),
    )
    .required(),
  servings: yup.number().required(),
  image: yup
    .object({
      url: yup.string().defined(),
    })
    .nullable()
    .defined(),
});

const recipeItemSchema = yup.object({
  id: yup.number().required(),
  servings: yup.number().required(),
  recipe: recipeSchema.required(),
  nutrients: yup.array().of(nutrientSchema.required()).required(),
});

const productSchema = yup.object({
  id: yup.number().required(),
  name: yup.string().required(),
  translations: yup
    .array()
    .of(
      yup.object({
        lang: yup
          .mixed<LanguagesSlugs>()
          .oneOf(Object.values(LanguagesSlugs))
          .required(),
        name: yup.string().nullable().defined(),
      }),
    )
    .required(),
  image: yup
    .object({
      url: yup.string().defined(),
    })
    .nullable()
    .defined(),
});

const productItemSchema = yup.object({
  id: yup.number().required(),
  grams: yup.number().required(),
  product: productSchema.required(),
  nutrients: yup.array().of(nutrientSchema.required()).required(),
});

const dietMealSchema = yup.object({
  id: yup.number().required(),
  name: yup.string().required(),
  translations: yup
    .array()
    .of(
      yup.object({
        lang: yup
          .mixed<LanguagesSlugs>()
          .oneOf(Object.values(LanguagesSlugs))
          .required(),
        name: yup.string().nullable().defined(),
      }),
    )
    .required(),
  nutrients: yup.array().of(nutrientSchema.required()).required(),
  recipes: yup.array().of(recipeItemSchema).defined(),
  products: yup.array().of(productItemSchema).defined(),
});

const responseSchema = yup.object({
  data: dietMealSchema.defined(),
});
