import dayjs from "dayjs";
import { getPolishWeekDay } from "@utils/dateUtils";
import { capitalizeFirstLetter } from "@utils/text";
import { AppTranslationProps } from "@hooks";

export const tooltipLabelFormatter = (
  t: AppTranslationProps["t"],
  date: string,
  dataLength?: number,
) => {
  const day = dayjs(date);
  const name = t("common.name_day_of_week." + getPolishWeekDay(day.day()));

  return `${capitalizeFirstLetter(name)} (${day.format("DD.MM")})`;
};

export const xAxisTickFormatter = (
  t: AppTranslationProps["t"],
  date: string,
  dataLength?: number,
) => {
  if (dataLength && dataLength > 7) {
    return dayjs(date).format("DD");
  }

  const day = dayjs(date);
  const name = t("common.min_name_day_of_week." + getPolishWeekDay(day.day()));

  return `${name}. (${day.format("DD.MM")})`;
};
