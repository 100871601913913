import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Poland = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 93 73">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        imageRendering="optimizeQuality"
        fillRule="evenodd"
        clipRule="evenodd"
        viewBox="0 0 512 512"
      >
        <g fillRule="nonzero">
          <path
            fill="#fff"
            d="M256 19.48c65.3 0 124.46 26.48 167.25 69.27l1.09 1.18c42.14 42.71 68.18 101.37 68.18 166.06 0 65.31-26.5 124.46-69.29 167.25l-1.18 1.09c-42.73 42.16-101.4 68.19-166.05 68.19-65.23 0-124.37-26.51-167.18-69.33-42.84-42.74-69.33-101.89-69.33-167.2 0-65.31 26.48-124.45 69.27-167.24C131.55 45.96 190.7 19.48 256 19.48z"
          />
          <path
            fill="#fff"
            d="M243.37 472.03h25.27c113.64-6.54 203.77-100.76 203.77-216.04 0-114.41-88.83-208.09-201.27-215.86h-30.28C128.43 47.9 39.6 141.58 39.6 255.99c0 115.28 90.13 209.5 203.77 216.04z"
          />
          <path
            fill="#dc143c"
            d="M243.37 472.03h25.27c113.61-6.54 203.72-100.72 203.77-215.94H39.6c.05 115.22 90.16 209.4 203.77 215.94z"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
