import {
  CircularProgress,
  Drawer,
  IconButton,
  Stack,
  Switch,
} from "@mui/material";
import { ReactNode, useCallback, useMemo, useRef, useState } from "react";
import { Close, Filters } from "@assets/icons";
import { CustomChip } from "@components/Filters/TabsChipsFilter/TabsChipsFilter.styled";
import MealsListFilterDrawer from "@views/dietician/PatientMonitoring2/components/MealsBox/MealsListFilterDrawer";
import { useFetchMonitoringMealsClientInfinite } from "@hooks/queries/client/monitoring";
import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import { mapSelectedDaysToRequestQueryParams } from "@views/dietician/PatientMonitoring2/PatientMonitoringUtils";
import { useAppTranslation } from "@hooks";
import RecipeMealItem from "@views/dietician/PatientMonitoring2/components/MealsBox/Drawer/RecipeMealItem";
import {
  Header,
  Label,
} from "@views/dietician/PatientMonitoring2/components/MealsBox/MealsListDrawer.styled";
import { PatientMealsMonitoringResource } from "@client/resources/PatientMealsMonitoringResource";

type MealsListDrawerProps = {
  onClose: () => void;
};
const MealsListDrawer = ({ onClose }: MealsListDrawerProps) => {
  const { t } = useAppTranslation();
  const [ratings, setRatings] = useState<number[]>([]);
  const [tagsId, setTagsId] = useState<number[]>([]);
  const [symptoms, setSymptoms] = useState<number>(0);

  const [openFiltersDrawer, setOpenFiltersDrawer] = useState(false);

  const { patientId, selectedDays } = useMonitoring();

  const params = useMemo(() => {
    return {
      ...mapSelectedDaysToRequestQueryParams(selectedDays.selectDays),
      ...(ratings.length > 0 && { rating: ratings }),
      ...(tagsId.length > 0 && { tags: tagsId }),
      ...(symptoms === 1 && { symptoms: 1 }),
    };
  }, [ratings, tagsId, symptoms, selectedDays.selectDays]);

  const { data, fetchNextPage, hasNextPage, isFetching, isLoading } =
    useFetchMonitoringMealsClientInfinite({ patientId, ...params });

  const handleObserver = useRef<IntersectionObserver>();
  const lastElement = useCallback(
    (element: HTMLLIElement) => {
      if (isLoading) return;
      if (handleObserver.current) handleObserver.current.disconnect();
      handleObserver.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasNextPage && !isFetching) {
          fetchNextPage();
        }
      });
      if (element) handleObserver.current.observe(element);
    },
    [isLoading, hasNextPage],
  );

  const recipes = useMemo(() => {
    const recipes: ReactNode[] = [];

    const mapDay = (day: PatientMealsMonitoringResource) => {
      day.meals.forEach(meal => {
        meal.items.forEach(item => {
          if (item.type !== "food_diary_meal_item_recipe") {
            return;
          }

          recipes.push(
            <li key={item.id} style={{ margin: "8px 0px" }} ref={lastElement}>
              <RecipeMealItem day={day} meal={meal} item={item} />
            </li>,
          );
        });
      });
    };

    (data?.pages ?? []).forEach(page => page.data.forEach(mapDay));

    return recipes;
  }, [data]);

  const handleChipOnClick = (rating: number) => {
    setRatings(prev => {
      if (prev.find(i => i === rating) != undefined) {
        return prev.filter(i => i !== rating);
      }

      return [...prev, rating];
    });
  };

  const isChecked = (rating: number) =>
    ratings.find(i => i === rating) != undefined;

  return (
    <>
      <Stack direction="column" spacing="16px">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Header>{t("monitoring2.meals_box.meals_list_drawer.header")}</Header>
          <IconButton onClick={onClose}>
            <Close fill="#727272" />
          </IconButton>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" spacing="8px">
            <Label>{t("monitoring2.meals_box.meals_list_drawer.label")}</Label>
            <Stack direction="row" spacing="16px" alignItems="center">
              <CustomChip
                variant={isChecked(1) ? "filled" : "outlined"}
                onClick={() => handleChipOnClick(1)}
                label={t("monitoring2.meals_box.meals_list_drawer.tasted")}
                color={isChecked(1) ? "primary" : "default"}
              />
              <CustomChip
                variant={isChecked(0) ? "filled" : "outlined"}
                onClick={() => handleChipOnClick(0)}
                label={t("monitoring2.meals_box.meals_list_drawer.not_tasted")}
                color={isChecked(0) ? "primary" : "default"}
              />
              <Stack
                direction="row"
                spacing="4px"
                alignItems="center"
                onClick={() => setSymptoms(prev => (prev === 1 ? 0 : 1))}
                sx={{}}
              >
                <Switch checked={symptoms === 1} />
                <span>Posiłki z objawami</span>
              </Stack>
            </Stack>
          </Stack>
          <IconButton
            color="primary"
            onClick={() => setOpenFiltersDrawer(true)}
          >
            <Filters fill="#7448D0" />
          </IconButton>
        </Stack>
        <Stack direction="column" flex={1}>
          <ul>
            {recipes}
            {(isLoading || isFetching) && (
              <li style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </li>
            )}
          </ul>
        </Stack>
      </Stack>
      <Drawer
        open={openFiltersDrawer}
        onClose={() => setOpenFiltersDrawer(false)}
        anchor="right"
        PaperProps={{
          sx: { width: "440px" },
        }}
        hideBackdrop={true}
      >
        <MealsListFilterDrawer
          onClose={() => setOpenFiltersDrawer(false)}
          tagsId={tagsId}
          setTagsId={setTagsId}
        />
      </Drawer>
    </>
  );
};

export default MealsListDrawer;
