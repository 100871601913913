import { useMutation, useQueryClient } from "@tanstack/react-query";

import { requestShowSection } from "@client";
import { MutationOptions, searchSectionsQueryKey } from "@hooks/queries";

export const useShowSectionMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation((id: number) => requestShowSection(id), {
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries([searchSectionsQueryKey]);
      options?.onSuccess && options.onSuccess();
    },
  });
};
