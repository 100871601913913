import { CheckInCircle, IInCricle } from "@assets/icons";
import { FormTextField } from "@components/FormTextField";
import { SyncTwoTone } from "@mui/icons-material";
import { Card, styled } from "@mui/material";

export const CardStyled = styled(Card)`
  display: grid;
  gap: 2.286rem;
  place-items: center;
  width: 36.429rem;
  border-radius: 0.857rem;
  box-shadow: 0px 0px 20px 0px #0000000d;
`;

export const MobileWrapper = styled("div")`
  display: grid;
  gap: 2.286rem;
  place-items: center;
  max-width: 36.429rem;
  width: 100%;
`;

export const MobileWrapperPaper = styled("div")`
  display: grid;
  gap: 2.286rem;
  place-items: center;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.neutral.light[100]};
`;

export const InfoWrapper = styled("div")`
  display: grid;
  place-items: center;
  gap: 0.857rem;
  padding: 1.714rem 1.714rem 0 1.714rem;
`;

export const Title = styled("span")`
  font-size: 1.714rem;
  line-height: 2.286rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;

export const Text = styled("span")`
  font-size: 1.143rem;
  line-height: 1.714rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const InputsWrapper = styled("div")`
  display: grid;
  gap: 1.143rem;
  width: 100%;
`;

export const ActionsWrapper = styled("div")`
  display: grid;
  gap: 1.714rem;
  width: 100%;
  padding: 0 1.714rem 1.714rem 1.714rem;
`;

export const FormTextFieldStyled = styled(FormTextField)`
  & .MuiInputBase-root {
    padding-right: 0.25rem;
  }
`;

export const InfoAlert = styled("span")`
  font-size: 0.857rem;
  line-height: 1.429rem;
  color: ${({ theme }) => theme.palette.info.main};
`;
export const IInCricleStyled = styled(IInCricle)`
  align-self: flex-start;
  margin: 0.25rem 0;
  color: ${({ theme }) => theme.palette.info.main};
`;

export const FixedButtonWrapper = styled("div")`
  position: fixed;
  box-shadow: ${({ theme }) => theme.boxShadows.mobileNavigation};
  background: ${({ theme }) => theme.colors.neutral.light[100]};
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.5rem;
`;

export const CtaText = styled("span")`
  font-family: ${({ theme }) => theme.fontFamily.rockSalt};
  font-size: 0.822rem;
  line-height: 0.822rem;
  color: ${({ theme }) => theme.palette.secondary.main};
  width: 50%;
`;

export const SuccessInfoWrapper = styled("div")`
  display: grid;
  gap: 0.857rem;
  place-items: center;
`;
export const CtaWrapper = styled("div")`
  display: grid;
  width: 100%;
  place-items: center;
  gap: 1rem;
`;

export const LinksWrapper = styled("div")`
  display: flex;
  gap: 0.446rem;
`;

export const SuccessViewWrapper = styled("div")`
  display: grid;
  gap: 3.429rem;
  padding: 2.286rem 1.714rem;
  width: 100%;
`;

export const CheckInCircleStyled = styled(CheckInCircle)`
  width: 1.714rem;
  height: 1.714rem;
  color: ${({ theme }) => theme.palette.success.main};
`;

export const ImagesWrapper = styled("div")`
  display: flex;
  align-items: start;
  gap: 1rem;
`;
export const TitleWrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
