import { fetchData } from "@utils/api";
import {
  APIMethods,
  ApiResponse,
  MetadataSchema,
  PaginationApiResponse,
} from "@typeDefinitions";
import * as yup from "yup";

export const fetchClientMeasurementList = async (
  id: string,
  page?: number,
): Promise<PaginationApiResponse<FetchClientMeasurementListResponse[]>> => {
  const response = await fetchData(
    `/dietitian/patients/${id}/list-measurements${page ? "?page=" + page : ""}`,
    APIMethods.GET,
  );
  return await responseSchema.validate(response);
};

const measurementSchema = yup.number().nullable().defined();

const patientMeasurementBody = yup.object({
  body: yup
    .object({
      id: yup.number().required(),
    })
    .required(),
  value: measurementSchema,
});

const patientMeasurementPhotoSchema = yup.object({
  id: yup.number().required(),
  url: yup.string().required(),
  type: yup
    .object({
      id: yup.number().required(),
    })
    .required(),
  name: yup.string().nullable().defined(),
  media: yup
    .object({
      originalName: yup.string().required(),
    })
    .required(),
});

const clientMeasurementListSchema = yup.object({
  id: yup.number().required(),
  source: yup
    .object({
      id: yup.number().required(),
    })
    .required(),
  date: yup.string().defined(),
  growth: measurementSchema,
  weight: measurementSchema,
  bodyFatLevel: measurementSchema,
  arm: measurementSchema,
  tightBiceps: measurementSchema,
  waist: measurementSchema,
  hip: measurementSchema,
  thigh: measurementSchema,
  calf: measurementSchema,
  bodyMeasurements: yup.array().of(patientMeasurementBody).defined(),
  photos: yup.array().of(patientMeasurementPhotoSchema).defined(),
});

const responseSchema = yup.object({
  data: yup.array().of(clientMeasurementListSchema).defined(),
  meta: MetadataSchema.defined(),
});

export interface FetchClientMeasurementListResponse {
  id: number;
  source: SourceDto;
  date: string;
  growth: number | null;
  weight: number | null;
  bodyFatLevel: number | null;
  arm: number | null;
  tightBiceps: number | null;
  waist: number | null;
  hip: number | null;
  thigh: number | null;
  calf: number | null;
  bodyMeasurements: {
    body: {
      id: number;
    };
    value: number | null;
  }[];
  photos: {
    id: number;
    url: string;
    type: {
      id: number;
    };
    name: string | null;
    media: {
      originalName: string;
    };
  }[];
}

interface SourceDto {
  id: number;
}
