import { MacrosList } from "@components/MacrosList";
import { MicronutrientPreview } from "@components/MicronutrientsModal";
import { Nutrient } from "@typeDefinitions";
import { ProductCellTitle, ProductCellWrapper } from "./ProductCell.styled";

interface ProductCellProps {
  name: string;
  nutrients: Nutrient[];
  productId: number;
}

export const ProductCell = ({ name, nutrients }: ProductCellProps) => {
  return (
    <>
      <ProductCellTitle noWrap variant="subtitle2">
        {name}
      </ProductCellTitle>
      <ProductCellWrapper>
        <div className="max-w-xs">
          <div className="flex">
            <MacrosList nutrients={nutrients} regularFont compact />
            <MicronutrientPreview noPadding compact means={nutrients} />
          </div>
        </div>
      </ProductCellWrapper>
    </>
  );
};
