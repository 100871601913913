import { Button, styled } from "@mui/material";

export const Label = styled("span")`
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral.medium[800]};
`;

interface ButtonStyledProps {
  selected?: boolean;
}

export const ButtonStyled = styled(Button, {
  shouldForwardProp: prop => prop !== "selected",
})<ButtonStyledProps>`
  &.MuiButton-outlined {
    border-radius: 8px;
    background: ${({ theme, selected }) =>
      selected
        ? theme.palette.primary.medium
        : theme.colors.neutral.light[100]};
    color: ${({ theme, selected }) =>
      selected ? theme.palette.primary.main : theme.colors.neutral.dark[700]};
    border-color: ${({ theme, selected }) =>
      selected ? theme.palette.primary.main : theme.colors.neutral.light[200]};
  }
`;

export const DashStyled = styled("span")`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.714rem;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const InputsWrapper = styled("div")`
  display: flex;
  gap: 8px;
  align-items: center;
`;
