import { useMemo } from "react";

import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { GroupIf, TableOfContents } from "@components/TableOfContents";
import { TableOfContentsContextProvider } from "@context/TableOfContentsContext";

import { useFetchSurveyFullQuery } from "@hooks/queries/surveys";
import {
  ContentSelectorWrapper,
  DialogStyled,
  IconButtonStyled,
  QuestionnaireAnswerStyled,
  TableOfContentsWrapper,
} from "./QuestionnaireAnswerPreviewModal.styled";

import { Close } from "@assets/icons/DesignSystem";
import { ApiError } from "@components/ApiError";
import { AlertCard } from "./AlertCard";
import { ContentSelector } from "./ContentSelector";
import { Navigation } from "./Navigation";

interface QuestionnaireAnswerPreviewModalProps {
  id: number;
  open: boolean;
  onClose: () => void;
}

export const QuestionnaireAnswerPreviewModal = ({
  id,
  open,
  onClose,
}: QuestionnaireAnswerPreviewModalProps) => {
  const { breakpoints } = useTheme();
  const downSmall = useMediaQuery(`${breakpoints.down("smallNew")}`);

  const { survey, isLoading, isError } = useFetchSurveyFullQuery(
    id.toString(),
    { enabled: open },
  );

  const groups: GroupIf[] = useMemo(
    () =>
      survey?.groups.map(g => ({
        id: g.id,
        name: g.title,
        subgroups: g.sections.map(({ id, title }) => ({ id, name: title })),
      })) ?? [],
    [survey?.groups],
  );

  const firstGroupId = groups?.[0]?.id;
  const firstSubgroupId = groups?.[0]?.subgroups[0]?.id;

  if (isLoading)
    return (
      <DialogStyled open={open} onClose={onClose}>
        <DialogContent>
          <div className="grid place-items-center">
            <CircularProgress />
          </div>
        </DialogContent>
      </DialogStyled>
    );

  if (isError)
    return (
      <DialogStyled open={open} onClose={onClose}>
        <DialogContent>
          <ApiError />
        </DialogContent>
      </DialogStyled>
    );

  return (
    <TableOfContentsContextProvider
      defaultGroupId={firstGroupId}
      defaultSubgroupId={firstSubgroupId}
      definedQuestionnaireId={id.toString()}
    >
      <DialogStyled open={open} onClose={onClose}>
        <DialogTitle>{survey?.name}</DialogTitle>

        <IconButtonStyled onClick={onClose} size="small">
          <Close size="w-3 h-3" />
        </IconButtonStyled>

        <DialogContent>
          <QuestionnaireAnswerStyled>
            {!downSmall && (
              <TableOfContentsWrapper>
                <TableOfContents
                  groups={groups}
                  hideIntroduction={!survey?.introduction.on}
                />
              </TableOfContentsWrapper>
            )}

            <ContentSelectorWrapper>
              <AlertCard survey={survey} />

              <ContentSelector survey={survey} />
            </ContentSelectorWrapper>
          </QuestionnaireAnswerStyled>
        </DialogContent>

        <DialogActions>
          <Navigation survey={survey} />
        </DialogActions>
      </DialogStyled>
    </TableOfContentsContextProvider>
  );
};
