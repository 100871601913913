import { IconButton, TextField, styled } from "@mui/material";

export const TableAnswerPreviewWrapper = styled("div")`
  display: grid;
  overflow-x: auto;
`;

export const RowHeaderStyled = styled("span")`
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1.5rem;
  min-height: 1.5rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.medium[800]};
  position: sticky;
  left: 0;
  z-index: 100;
  background-color: ${({ theme }) => theme.colors.neutral.light[100]};

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallMax}px) {
    width: 6.25rem;
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.medium}px) {
    width: 10rem;
  }
`;

export const ColumnHeaderStyled = styled("span")`
  font-size: 1rem;
  line-height: 1.5rem;
  min-height: 1.5rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.medium[800]};
  width: 14.375rem;
  padding-left: 0.75rem;
`;

export const StyledTextField = styled(TextField)`
  width: 14.375rem;
  margin: 0.25rem 0;
`;

export const HeaderStyled = styled("span")`
  font-size: 1rem;
  line-height: 1.5rem;
  min-height: 1.5rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.medium[800]};
`;

export const IconButtonStyled = styled(IconButton)`
  position: absolute;
  right: 1rem;
  top: 1rem;
`;
