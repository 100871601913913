import { Button, ButtonGroup } from "@mui/material";
import { Sexes, useAppTranslation } from "@hooks";
import { Control, Controller } from "react-hook-form";

interface SexSelectorProps {
  control: Control<any>;
  name: string;
}

export const SexSelector = ({ control, name }: SexSelectorProps) => {
  const { t } = useAppTranslation();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, onBlur } }) => (
        <ButtonGroup fullWidth variant="outlined">
          <Button
            variant={value === Sexes.m ? "outlined" : "contained"}
            onClick={value => {
              onChange(value);
              onBlur();
            }}
            value={Sexes.w}
          >
            {t("patients.add.personal_info.sex.w")}
          </Button>
          <Button
            variant={value === Sexes.m ? "contained" : "outlined"}
            onClick={value => {
              onChange(value);
              onBlur();
            }}
            value={Sexes.m}
          >
            {t("patients.add.personal_info.sex.m")}
          </Button>
        </ButtonGroup>
      )}
    />
  );
};
