import { useQuery } from "@tanstack/react-query";

import {
  fetchClientMeasurement,
  FetchClientMeasurementResponse,
} from "@client";
import { ApiResponse } from "@typeDefinitions";

import { QueryOptionsTyped } from "./types";

export const fetchClientMeasurementQueryKey = "fetchClientMeasurementQueryKey";

export const useFetchClientMeasurementQuery = (
  id: string,
  options?: QueryOptionsTyped<ApiResponse<FetchClientMeasurementResponse>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchClientMeasurementQueryKey, id],
    () => fetchClientMeasurement(id),
    options,
  );

  return {
    measurement: data?.data,
    ...rest,
  };
};
