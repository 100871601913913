import { ArrowDown } from "@assets/icons/Arrows";
import { Button, Collapse, styled } from "@mui/material";

export const CollapseStyled = styled(Collapse)`
  padding: 24px;
  margin: -24px;
`;

export const ButtonStyled = styled(Button)`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  align-self: flex-start;
`;

interface ArrowDownStyledProps {
  rotate: boolean;
}
export const ArrowDownStyled = styled(ArrowDown, {
  shouldForwardProp: prop => prop !== "rotate",
})<ArrowDownStyledProps>`
  width: 16px;
  height: 16px;
  ${({ rotate }) => rotate && "rotate: 180deg;"};
  transition: all 0.2s;
`;
