import { SelectOption } from "@components/FormAutocomplete";
import { OuterLabel } from "@components/OuterLabel";
import { TagCategorySimplified, useTagsNew } from "@hooks/useTagsNew";
import { SAFARI_TAB_INDEX } from "@utils";

import { TagsWrapper } from "./ExpandableTagAutocomplete.styled";
import { ChipStyled } from "./TagCategory.styled";
import { DISABLED_SYSTEM_TAGS } from "@utils/tagsNew";

interface TagCategoryProps {
  category: TagCategorySimplified;
  selectedTags: SelectOption[];
  toggleTag: (id: number) => void;
  readOnly?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
}

export const TagCategory = ({
  category,
  selectedTags,
  toggleTag,
  readOnly = false,
  onBlur,
  onFocus,
}: TagCategoryProps) => {
  const { isSystemTag } = useTagsNew();

  const handleToggle = (id: number) => {
    toggleTag(id);
  };

  if (!category.tags.length) return <></>;

  return (
    <OuterLabel label={category.name}>
      <TagsWrapper>
        {category.tags.map(tag => {
          const isSelected = selectedTags.some(
            selTag => selTag.id === tag.id.toString(),
          );
          const disabled =
            readOnly || DISABLED_SYSTEM_TAGS.includes(tag.systemId ?? 0);

          if (isSelected) {
            return (
              <ChipStyled
                key={tag.id}
                label={tag.name}
                variant="filled"
                color="primary"
                clickable
                disabled={disabled}
                onClick={() => handleToggle(tag.id)}
                onFocus={onFocus}
                onBlur={onBlur}
                tabIndex={SAFARI_TAB_INDEX}
              />
            );
          } else
            return (
              <ChipStyled
                key={tag.id}
                label={tag.name}
                variant="outlinedWhite"
                clickable
                disabled={disabled}
                onClick={() => handleToggle(tag.id)}
                onFocus={onFocus}
                onBlur={onBlur}
                tabIndex={SAFARI_TAB_INDEX}
              />
            );
        })}
      </TagsWrapper>
    </OuterLabel>
  );
};
