import { Loupe } from "@assets/icons";
import { useAppParams, useAppTranslation } from "@hooks";
import {
  Button,
  DialogActions,
  DialogTitle,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";
import {
  DescriptionStyled,
  DialogContentStyled,
  DialogStyled,
  IconButtonStyled,
} from "./AddSystemQuestionModal.styled";
import { QuestionAccordion } from "./QuestionAccordion";
import { Close } from "@assets/icons/DesignSystem";
import {
  FullSurveyDto,
  QuestionType,
  SystemQuestionType,
} from "@client/surveys";
import { useEffect, useMemo, useState } from "react";
import {
  fetchSurveyFullQueryKey,
  postSurveySubgroupQuestionMutationKey,
  usePostSurveySubgroupQuestionMutation,
} from "@hooks/queries/surveys";
import { useContentSelector } from "@context/TableOfContentsContext";
import { LangDto } from "@client";
import { useQueryClient } from "@tanstack/react-query";
import { ApiResponse } from "@typeDefinitions";

interface AddSystemQuestionModalProps {
  open: boolean;
  onClose: () => void;
  questionnaireLang?: LangDto;
}

export const AddSystemQuestionModal = ({
  open,
  onClose,
  questionnaireLang,
}: AddSystemQuestionModalProps) => {
  const { t } = useAppTranslation();
  const { questionnaireId } = useAppParams();
  const { colors } = useTheme();
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData<ApiResponse<FullSurveyDto>>([
    fetchSurveyFullQueryKey,
    questionnaireId,
  ]);
  const selectedQuestionTypes = useMemo(
    () =>
      data?.data.groups.flatMap(g =>
        g.sections.flatMap(s => s.questions.map(q => q.type)),
      ) ?? [],
    [data?.data],
  );
  const [selectedType, setSelectedType] = useState<SystemQuestionType[]>([]);
  const [query, setQuery] = useState("");

  const { mutate } = usePostSurveySubgroupQuestionMutation();
  const { subgroupId } = useContentSelector();

  const closeWithReset = () => {
    onClose();
    setSelectedType([]);
    setQuery("");
  };

  const systemQuestion: { type: SystemQuestionType; title: string }[] = [
    {
      type: QuestionType.SYSTEM_USER_WEIGHT,
      title: "questionnaires.questions.system_user_weight.question",
    },
    {
      type: QuestionType.SYSTEM_USER_HEIGHT,
      title: "questionnaires.questions.system_user_height.question",
    },
    {
      type: QuestionType.SYSTEM_USER_FAT_TISSUE,
      title: "questionnaires.questions.system_user_fat_tissue.question",
    },
    {
      type: QuestionType.SYSTEM_MEASUREMENTS,
      title: "questionnaires.questions.system_measurements.question",
    },
    {
      type: QuestionType.SYSTEM_USER_BIRTHDAY,
      title: "questionnaires.questions.system_user_birthday.question",
    },
    {
      type: QuestionType.SYSTEM_COOPERATION_GOALS,
      title: "questionnaires.questions.system_cooperation_goals.question",
    },
    {
      type: QuestionType.SYSTEM_USER_TARGET_BODY_WEIGHT,
      title: "questionnaires.questions.system_user_target_body_weight.question",
    },
    {
      type: QuestionType.SYSTEM_PREGNANT_WOMAN,
      title: "questionnaires.questions.system_pregnant_woman.question",
    },
    {
      type: QuestionType.SYSTEM_SEX,
      title: "questionnaires.questions.system_sex.question",
    },
    {
      type: QuestionType.SYSTEM_USER_PREFERENCES_FOOD_UNLIKED,
      title:
        "questionnaires.questions.system_user_preferences_food_unliked.question",
    },
    {
      type: QuestionType.SYSTEM_USER_PREFERENCES_FOOD_LIKED,
      title:
        "questionnaires.questions.system_user_preferences_food_liked.question",
    },
    {
      type: QuestionType.SYSTEM_TAG_ALLERGEN,
      title: "questionnaires.questions.system_tag_allergen.question",
    },
    {
      type: QuestionType.SYSTEM_TAG_DIET_TYPE,
      title: "questionnaires.questions.system_tag_diet_type.question",
    },
  ];

  const handleAddSystemQuestion = () => {
    if (!subgroupId || !selectedType.length) return;

    selectedType.forEach(async selected => {
      const titleTranslation =
        systemQuestion.find(q => q.type === selected)?.title ?? "";
      mutate(
        {
          id: subgroupId.toString(),
          payload: {
            title: t(titleTranslation, { lng: questionnaireLang }),
            type: selected,
            description: null,
            config: {
              required: true,
            },
          },
        },
        { onSuccess: closeWithReset },
      );
    });
  };

  const filteredSystemQuestion = useMemo(
    () =>
      systemQuestion
        .filter(q =>
          query
            ? t(q.title).toLocaleLowerCase().includes(query.toLocaleLowerCase())
            : true,
        )
        .sort((a, b) => {
          if (t(a.title) < t(b.title)) return -1;
          if (t(a.title) > t(b.title)) return 1;
          return 0;
        }),
    [systemQuestion, query, t],
  );

  return (
    <DialogStyled open={open} onClose={closeWithReset}>
      <DialogTitle>{t("questionnaires.questions.add")}</DialogTitle>

      <IconButtonStyled onClick={onClose} size="small">
        <Close size="w-3 h-3" />
      </IconButtonStyled>

      <DialogContentStyled>
        <DescriptionStyled>
          {t("questionnaires.questions.modal_desc")}
        </DescriptionStyled>

        <TextField
          size="small"
          placeholder={t("common.search")}
          InputProps={{
            startAdornment: (
              <Loupe className="mr-2" fill={colors.neutral.dark[700]} />
            ),
          }}
          fullWidth
          onChange={e => setQuery(e.target.value)}
        />
        {filteredSystemQuestion.map(q => {
          const alreadySelected = selectedQuestionTypes.includes(q.type);
          return (
            <Tooltip
              key={q.type}
              title={t("questionnaires.questions.already_added")}
              disableHoverListener={!alreadySelected}
              arrow
            >
              <div>
                <QuestionAccordion
                  title={t(q.title)}
                  type={q.type}
                  setSelectedType={setSelectedType}
                  selectedType={selectedType}
                  disabled={alreadySelected}
                />
              </div>
            </Tooltip>
          );
        })}
      </DialogContentStyled>

      <DialogActions>
        <Button variant="outlined" onClick={closeWithReset}>
          {t("common.cancel")}
        </Button>
        <Button
          disabled={!selectedType}
          onClick={handleAddSystemQuestion}
          variant="contained"
        >
          {t("questionnaires.questions.add")}
        </Button>
      </DialogActions>
    </DialogStyled>
  );
};
