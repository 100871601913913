import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  UpdateDietCreatorTagsPayloadIf,
  updateDietCreatorTags,
} from "@client/diets/creator";
import { MutationOptions } from "@hooks/queries/types";

import { useCreatorVersion } from "./useCreatorVersion";
import { fetchDietCreatorTagsQueryKey } from "./useFetchDietCreatorTagsQuery";
import { fetchCreatorDietQueryKey } from "./useFetchCreatorDietQuery";

export const useUpdateDietCreatorTagsMutation = (
  options: MutationOptions = {},
) => {
  const quertClient = useQueryClient();
  const { version, updateVersion } = useCreatorVersion();
  return useMutation<
    unknown,
    unknown,
    UseUpdateDietCreatorTagsMutationIf,
    unknown
  >(({ dietId, payload }) => updateDietCreatorTags(dietId, payload, version), {
    ...options,
    onSuccess: (d, v) => {
      options.onSuccess?.(d, v);
      quertClient.invalidateQueries([fetchDietCreatorTagsQueryKey, v.dietId]);
    },
    onMutate: updateVersion,
    onError: (e, v) => {
      options.onError?.(e);
      quertClient.invalidateQueries([fetchDietCreatorTagsQueryKey, v.dietId]);
      quertClient.invalidateQueries([fetchCreatorDietQueryKey, v.dietId]);
    },
  });
};

interface UseUpdateDietCreatorTagsMutationIf {
  dietId: string;
  payload: UpdateDietCreatorTagsPayloadIf;
}
