import { SurveyDto, fetchSurveys } from "@client/surveys/fetchSurveys";
import { useQuery } from "@tanstack/react-query";
import { QueryOptionsTyped } from "../types";
import { ApiResponse } from "@typeDefinitions";

export const fetchSurveysQueryKey = "fetchSurveysQueryKey";

export const useFetchSurveysQuery = (
  options?: QueryOptionsTyped<ApiResponse<SurveyDto[]>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchSurveysQueryKey],
    fetchSurveys,
    options,
  );

  return {
    surveys: data?.data,
    ...rest,
  };
};
