import { DollarInGreenRound, NoParkingGreySign } from "@assets/icons";
import { Typography, styled } from "@mui/material";

export const AvatarComponentWrapper = styled("div")`
  display: flex;
  gap: 0.75rem;
  width: 100%;
  border-radius: 0.25rem;
  padding: 0.285rem;

  &:hover {
    background: ${({ theme }) => theme.palette.primary.light};
  }
`;

export const StyledDollarIcon = styled(DollarInGreenRound)`
  position: absolute;
  top: -2px;
  right: -7px;
  width: 1.25rem;
  height: 1.25rem;
`;
export const StyledNoParkingGreySign = styled(NoParkingGreySign)`
  position: absolute;
  top: -2px;
  right: -7px;
  width: 1.25rem;
  height: 1.25rem;
`;

export const StyledFullName = styled("p")`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
  font-weight: 500;
  line-height: 150%;
`;

export const CooperationDetails = styled(Typography)`
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;
