import { Tab, Tabs, styled } from "@mui/material";

export const TabsStyled = styled(Tabs)`
  width: fit-content;
  border: 1px solid ${({ theme }) => theme.palette.primary.medium};
  background: ${({ theme }) => theme.colors.neutral.light[100]};
  gap: 4px;
  padding: 4px;
  border-radius: 8px;
  border: 1px 0px 0px 0px;

  &.MuiTabs-root {
    min-height: unset;
    max-width: 100%;
  }

  & .MuiTabs-indicator {
    display: none;
  }
  & .MuiButtonBase-root.MuiTabScrollButton-root {
    border-radius: 8px;
  }
  & .MuiTabs-flexContainer {
    gap: unset;
  }
`;

interface TabStyledProps {
  size?: "small";
}
export const TabStyled = styled(Tab, {
  shouldForwardProp: prop => prop !== "size",
})<TabStyledProps>`
  &.MuiButtonBase-root {
    text-transform: unset;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    min-width: 32px;
  }
  &.MuiTab-root {
    border-radius: 8px;
    padding: ${({ size }) => (size === "small" ? "2px 6px" : "6px")};
    min-height: unset;
    color: ${({ theme }) => theme.colors.neutral.dark[700]};
  }
  &.MuiTab-root.Mui-selected {
    color: ${({ theme }) => theme.palette.primary.main};
    background: ${({ theme }) => theme.palette.primary.medium};
  }
  &.MuiTab-root.Mui-disabled {
    opacity: 0.4;
  }
`;
