import { fetchDietRecipeUsed, UsedRecipeDto } from "@client/diets";
import { useQuery } from "@tanstack/react-query";
import { QueryOptionsTyped } from "../types";
import { ApiResponse } from "@typeDefinitions";

export const fetchDietRecipeUsedQueryKey = "fetchDietRecipeUsedQueryKey";

export const useFetchDietRecipeUsedQuery = (
  dietId: string,
  options?: QueryOptionsTyped<ApiResponse<UsedRecipeDto[]>>,
) => {
  const { data, ...rest } = useQuery({
    queryKey: [fetchDietRecipeUsedQueryKey, dietId],
    queryFn: () => fetchDietRecipeUsed(dietId),
    staleTime: FIVE_MINUTES,
    ...options,
  });

  return {
    data: data?.data,
    ...rest,
  };
};

const FIVE_MINUTES = 5 * 60 * 1000;
