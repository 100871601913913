import { useQuery } from "@tanstack/react-query";
import {
  fetchMonitoringCalendarDays,
  FetchMonitoringCalendarDaysParams,
  FetchMonitoringCalendarDaysResponse,
} from "client/patient/monitoring";
import { QueryOptionsTyped } from "@hooks/queries";
import { patientMonitoringKeys } from "@hooks/queries/client/monitoring/patientMonitoringKey";

export const useFetchMonitoringCalendarDays = (
  params: FetchMonitoringCalendarDaysParams,
  options?: QueryOptionsTyped<FetchMonitoringCalendarDaysResponse>,
) => {
  return useQuery({
    queryKey: patientMonitoringKeys.calendarDays(params),
    queryFn: () => fetchMonitoringCalendarDays(params),
    ...options,
  });
};
