import { fetchData } from "@utils/api";
import { SurveySubgroupQuestionRequest } from "./postSurveySubgroupQuestion";
import { APIMethods } from "@typeDefinitions";

export const updateSurveySubgroupQuestion = async (
  subgroupId: string,
  payload: SurveySubgroupQuestionRequest,
) => {
  await fetchData(
    `/dietitian/survey-questions/${subgroupId}`,
    APIMethods.PUT,
    payload,
  );
};
