import { useContext } from "react";

import { useTheme } from "@mui/material";
import { isArray } from "lodash";

import { PieChart } from "@assets/icons";
import { MediumThumbComponent } from "@components/SliderThumbs";
import { useAppTranslation } from "@hooks";

import { ServingContext } from "../ServingsContext";
import {
  Label,
  LabelWrapper,
  SliderStyled,
  Value,
  Wrapper,
} from "./ServingsSlider.styled";

interface ServingsSliderProps {
  hideSlider?: boolean;
  onSliderChange?: (value: number) => void;
}

export const ServingsSlider = ({
  hideSlider,
  onSliderChange,
}: ServingsSliderProps) => {
  const {
    colors: { neutral },
  } = useTheme();
  const { selectedServings, setSelectedServings, totalServings } =
    useContext(ServingContext);
  const { t } = useAppTranslation();

  const handleSliderChange = (value: number) => {
    setSelectedServings(value);
  };

  const handleCommitedChange = (value: number) => {
    onSliderChange && onSliderChange(value);
  };

  const SINGLE_SERVING = 1;
  const FIVE_SERVINGS = 5;

  return (
    <Wrapper>
      <LabelWrapper>
        <PieChart fill={neutral.dark[700]} size="w-[14px] h-[14px]" />
        <Label>{t("recipe.ingredients_table.servings")}:</Label>
      </LabelWrapper>

      <Value>{selectedServings}</Value>

      {!hideSlider && (
        <SliderStyled
          slots={{ thumb: MediumThumbComponent }}
          value={selectedServings}
          max={totalServings === SINGLE_SERVING ? FIVE_SERVINGS : totalServings}
          min={1}
          onChange={(e, v) => !isArray(v) && handleSliderChange(v)}
          onChangeCommitted={(e, v) => !isArray(v) && handleCommitedChange(v)}
        />
      )}
    </Wrapper>
  );
};
