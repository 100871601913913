import { useMemo } from "react";
import { pl, enUS } from "date-fns/locale";
import { Typography } from "@mui/material";
import type { FC } from "react";
import { useAppTranslation } from "@hooks";
import { Plus } from "@assets/icons";
import { Controller, FormProvider } from "react-hook-form";
import {
  CalendarWrapper,
  CalendarHeader,
  AddButtonWrapper,
  StyledCalendarHeaderBox,
} from "./MiniCalendar.styled";

import { MiniCalendarWeekView } from "./_components/MiniCalendarWeekView";
import { MiniCalendarDayView } from "./_components/MiniCalendarDayView";
import { MiniCalendarMonthView } from "./_components/MiniCalendarMonthView";
import { useCalendarFiltersForm } from "@components/CalendarFilters";
import { mapCalendarScheduleEvents } from "@utils";
import { useSearchScheduleEvents } from "@hooks/queries";
import { useWatch } from "react-hook-form";
import { CalendarTabs, CalendarTimeUnit } from "./_components/CalendarTabs";
import { useScheduleVisitModalContext } from "../../ScheduleVisit/ScheduleVisitModalContext";

interface CustomCalendarProps {
  enabled: boolean;
}

export const MiniCalendar: FC<CustomCalendarProps> = ({ enabled }) => {
  const { t, isPolishChosen } = useAppTranslation();
  const locale = isPolishChosen ? pl : enUS;

  const { scheduleEvents, isFetching } = useSearchScheduleEvents({ enabled });
  const { handleVisitModalOpen, handleSetStartDate } =
    useScheduleVisitModalContext();

  const form = useCalendarFiltersForm();

  const events = useMemo(
    () => mapCalendarScheduleEvents(scheduleEvents ?? []),
    [scheduleEvents],
  );

  const timeUnit = useWatch({ control: form.control, name: "time_unit" });
  const dietitians = useWatch({ control: form.control, name: "dietitians" });

  return (
    <FormProvider {...form}>
      <CalendarWrapper className="custom-scrollbar">
        <CalendarHeader>
          <Typography variant="h6">{t("sidemenu.agenda")}</Typography>
          <StyledCalendarHeaderBox>
            <CalendarTabs />
            <AddButtonWrapper onClick={handleVisitModalOpen}>
              <Plus width="14px" height="14px" />
            </AddButtonWrapper>
          </StyledCalendarHeaderBox>
        </CalendarHeader>
        <Controller
          name="date"
          control={form.control}
          render={({ field: { value, onChange } }) => (
            <>
              {timeUnit === CalendarTimeUnit.TODAY && (
                <MiniCalendarDayView
                  events={events}
                  currentDate={value}
                  setCurrentDate={onChange}
                  locale={locale}
                  isLoading={isFetching}
                  dietitians={dietitians?.length}
                  onAddEvent={handleVisitModalOpen}
                  handleSetStartDate={handleSetStartDate}
                />
              )}
              {timeUnit === CalendarTimeUnit.WEEK && (
                <MiniCalendarWeekView
                  events={events}
                  currentDate={value}
                  setCurrentDate={onChange}
                  locale={locale}
                  isLoading={isFetching}
                  dietitians={dietitians?.length}
                />
              )}
              {timeUnit === CalendarTimeUnit.MONTH && (
                <MiniCalendarMonthView
                  events={events}
                  currentDate={value}
                  setCurrentDate={onChange}
                  locale={locale}
                  isLoading={isFetching}
                  dietitians={dietitians?.length}
                />
              )}
            </>
          )}
        />
      </CalendarWrapper>
    </FormProvider>
  );
};
