import { styled } from "@mui/material";

export const TitleWrapper = styled("div")`
  display: flex;
  align-items: center;
  overflow: hidden;
`;
export const Title = styled("span")`
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TagIconWrapper = styled("div")`
  display: flex;
  gap: 6px;
  align-items: center;
`;

export const Label = styled("span")`
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
`;

export const KcalText = styled(Label)`
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;
