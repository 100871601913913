export interface SimulationChartMapperProps {
  week: number;
  balance: {
    weight: number;
  } | null;
  simulation: {
    weight: number;
  } | null;
}

export const simulationChartMapper = ({
  week,
  balance,
  simulation,
}: SimulationChartMapperProps) => ({
  name: week.toString(),
  kg: balance?.weight,
  kgSimulation: simulation?.weight,
});
