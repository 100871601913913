import { whatsNewListStub } from "@components/WhatsNewList";
import { NEWS_UPDATE_KEY } from "@layouts/Dashboard/components/DrawerMenu";
import { useState, useEffect } from "react";
import { useLocalStorage } from "react-use";

export const useWhatsNew = (refetch?: boolean) => {
  const initialNewsLength = whatsNewListStub.length;
  const [news, setNews] = useLocalStorage(NEWS_UPDATE_KEY, 0);
  const updatedNews = localStorage.getItem(NEWS_UPDATE_KEY);
  const unreadNews = initialNewsLength - Number(updatedNews);
  const [newDataLength, setNewDataLength] = useState(unreadNews);

  useEffect(() => {
    if (!updatedNews) setNews(0);
    if (updatedNews && Number(updatedNews) !== initialNewsLength) {
      setNewDataLength(unreadNews);
    }
  }, [updatedNews]);

  const handleButtonClick = () => {
    setNews(value => (value === 0 || value) && value + newDataLength);
  };

  return {
    unreadNews,
    handleButtonClick,
  };
};
