import { AccordionDetails, Typography } from "@mui/material";

import { ArrowRight } from "@assets/icons/Arrows";
import { Nutrients } from "@components/Nutrients";
import { Measure, Nutrient } from "@typeDefinitions";

import { useMeasures } from "@hooks";
import {
  AccordionStyled,
  AccordionSummaryStyled,
  MeasureText,
} from "./ProductAccordion.styled";
import { round } from "lodash";

interface ProductAccordionProps {
  nutrients: Nutrient[];
  name: string;
  measure?: Measure;
  grams: number;
}
export const ProductAccordion = ({
  nutrients,
  name,
  measure,
  grams,
}: ProductAccordionProps) => {
  const { getMeasure } = useMeasures();
  const measureDetails = measure && getMeasure(measure?.id);

  return (
    <AccordionStyled>
      <AccordionSummaryStyled expandIcon={<ArrowRight />}>
        {name}

        {measureDetails && (
          <MeasureText>
            {round(grams / (measure.grams || 1), 1)} {measureDetails.name}
          </MeasureText>
        )}
      </AccordionSummaryStyled>
      <AccordionDetails>
        <Nutrients values={nutrients} />
      </AccordionDetails>
    </AccordionStyled>
  );
};
