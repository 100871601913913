import { Stack, styled } from "@mui/material";

export const FooterWrapper = styled(Stack)`
  background: #fbfafc;
  padding: 16px;
` as typeof Stack;

export const HeadWrapper = styled(Stack)`
  background: #fbfafc;
  padding: 16px;
` as typeof Stack;

export const Header = styled("span")`
  font-family: Figtree, serif;
  font-weight: 600;
  color: #2f303a;
  line-height: 30px;
  font-size: 18px;
`;

export const Label = styled("span")`
  font-family: Figtree, serif;
  font-weight: 500;
  color: #4d516e;
  line-height: 20px;
  font-size: 12px;
`;
