import { useMemo } from "react";
import { useFetchCustomTagsQuery } from "./queries/customTags/useFetchCustomTagsQuery";

export const useAllTags = () => {
  const { tags } = useFetchCustomTagsQuery();
  const systemTags = useMemo(
    () => tags.filter(({ isSystem }) => isSystem),
    [tags],
  );

  const customTags = useMemo(
    () => tags.filter(({ isSystem }) => !isSystem),
    [tags],
  );

  return { systemTags, customTags, allTags: tags };
};
