import { TreeItem } from "@mui/lab";
import React from "react";

export const TreeNode = ({ options, onClick }: TreeNodeProps) => {
  return (
    <>
      {options.map(o => {
        if (o.children.length) {
          return (
            <TreeItem key={o.value} nodeId={o.value} label={o.title}>
              {o.children && (
                <TreeNode options={o.children} onClick={onClick} />
              )}
            </TreeItem>
          );
        } else {
          return (
            <TreeItem
              key={o.value}
              nodeId={o.value}
              label={o.title}
              onClick={() => onClick(o.value)}
            />
          );
        }
      })}
    </>
  );
};

export interface TreeSelectOption {
  value: string;
  title: string;
  children: TreeSelectOption[];
}

export interface TreeNodeProps {
  options: TreeSelectOption[];
  onClick: (id: string) => void;
}
