import { AccordionDetails } from "@mui/material";

import { ArrowRight } from "@assets/icons/Arrows";
import { Nutrients } from "@components/Nutrients";
import { Nutrient } from "@typeDefinitions";

import {
  AccordionStyled,
  AccordionSummaryStyled,
} from "./RecipeProductAccordion.styled";
import { LoadingNutrient } from "./LoadingNutrients";

interface RecipeProductAccordionProps {
  name: string;
  id: string;
  grams: number;
}
export const RecipeProductAccordion = ({
  name,
  id,
  grams,
}: RecipeProductAccordionProps) => {
  return (
    <AccordionStyled TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummaryStyled expandIcon={<ArrowRight />}>
        {name}
      </AccordionSummaryStyled>
      <AccordionDetails>
        <LoadingNutrient productId={id} grams={grams} />
      </AccordionDetails>
    </AccordionStyled>
  );
};
