import { ReactNode } from "react";

import { useTheme } from "@mui/material";

import { SvgIconProps } from "@components/SvgIcon";

import { Title } from "./SectionTitleWrapper.styled";
import { isString } from "lodash";

interface SectionTitleWrapperProps {
  Icon: (props: SvgIconProps) => JSX.Element;
  title: string | ReactNode;
  children: ReactNode;
}

export const SectionTitleWrapper = ({
  Icon,
  title,
  children,
}: SectionTitleWrapperProps) => {
  const {
    colors: { neutral },
  } = useTheme();
  return (
    <div className="grid gap-[16px]">
      <div className="flex gap-[8px]">
        <Icon fill={neutral.dark[700]} size="w-[14px] h-[14px]" />
        <Title>{title}</Title>
      </div>
      {children}
    </div>
  );
};
