import { Dispatch, SetStateAction, createContext } from "react";

import dayjs from "dayjs";
import { MonitoringDayDto } from "@client";

export enum ToggleButtonValue {
  PROGRAM = "program",
  SCHEDULE = "schedule",
}
interface MonitoringContextIf {
  toggleButton: ToggleButtonValue;
  setToggleButton: Dispatch<SetStateAction<ToggleButtonValue>>;
  month: dayjs.Dayjs;
  setMonth: Dispatch<SetStateAction<dayjs.Dayjs>>;
  selectedDayDate: string;
  setSelectedDayDate: Dispatch<SetStateAction<string>>;
  selectedDayDetails: MonitoringDayDto | undefined;
  days: MonitoringDayDto[] | undefined;
}

export const MonitoringContext = createContext<MonitoringContextIf>({
  toggleButton: ToggleButtonValue.PROGRAM,
  setToggleButton: () => null,
  month: dayjs(),
  setMonth: () => null,
  selectedDayDate: "",
  setSelectedDayDate: () => null,
  selectedDayDetails: undefined,
  days: [],
});
