import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const BreadSlice = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="12" viewBox="0 0 14 12">
      <path
        d="M7 0.3125C5.375 0.3125 0.5 1.125 0.5 4.375C0.5 5.27129 1.22871 6 2.125 6V10.4688C2.125 11.1416 2.6709 11.6875 3.34375 11.6875H10.6562C11.3291 11.6875 11.875 11.1416 11.875 10.4688V6C12.7713 6 13.5 5.27129 13.5 4.375C13.5 1.125 8.625 0.3125 7 0.3125Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
