import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const updateCreatorMealTags = async (
  mealId: string,
  payload: UpdateCreatorMealTagsRequest,
  version: number,
) => {
  return await fetchData(
    `/dietitian/creator/meals/${mealId}/tags`,
    APIMethods.PUT,
    payload,
    undefined,
    { "Alloweat-Creator-Version": `${version}` },
  );
};

export interface UpdateCreatorMealTagsRequest {
  tags: number[];
}
