import { IconButton, styled } from "@mui/material";

export const ClientTitleWrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0 0.5rem;
`;

export const TitleWrapper = styled("div")`
  display: grid;
  grid-template-columns: auto;
  max-width: 80%;
  line-height: normal;
`;

export const StyledClientTitle = styled("p")`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0;
`;
