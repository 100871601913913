import { CircularProgress, Dialog, styled } from "@mui/material";

export const StyledDialogModal = styled(Dialog)`
  & .MuiPaper-root {
    box-shadow: none;
    overflow-y: unset;
    background-color: transparent;
  }
`;

export const RunningCircularProgress = styled(CircularProgress)`
  &.MuiCircularProgress-root {
    position: absolute;
  }
`;

export const BackCircularProgress = styled(CircularProgress)`
  &.MuiCircularProgress-root {
    color: ${({ theme }) => theme.colors.whiteBackground};
  }
`;
