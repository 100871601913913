import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";
import { IdDto } from "@client";

export const updateCreatorMealRecipe = async (
  mealId: string,
  recipeId: string,
  payload: UpdateCreatorMealRecipeRequest,
  version: number,
) => {
  return await fetchData(
    `/dietitian/creator/meals/${mealId}/recipes/${recipeId}`,
    APIMethods.PUT,
    payload,
    undefined,
    { "Alloweat-Creator-Version": `${version}` },
  );
};

export interface UpdateCreatorMealRecipeRequest {
  servings: number;
  recipe: RecipeRequest;
}

interface RecipeRequest {
  id: number;
  name: string;
  nameEn: string;
  description: string;
  descriptionEn: string;
  isComplex: boolean;
  servings: number;
  reviewed: boolean | null;
  tags: number[];
  media: IdDto | null;
  foodRecipe: UpdateFoodRecipeDto[];
  version: number | null;
  comment: string | null;
  commentEn: string | null;
}

export interface UpdateFoodRecipeDto {
  id: number | null;
  foodId: number;
  grams: number;
  foodMeasureId: number;
}
