import { createContext, ReactNode, useContext, useState } from "react";
import _ from "lodash";

type Subscriber = () => void;
type UnsubscribeFunction = () => void;
type NotifyFunction = () => void;

interface TourTriggerContextIf {
  enabled: boolean;
  notify: NotifyFunction;
  register: (subscriber: Subscriber) => UnsubscribeFunction;
}

const TourTriggerContext = createContext<TourTriggerContextIf>({
  enabled: false,
  notify: () => null,
  register: () => () => null,
});

function useTourTriggerContextProvider() {
  const [subscribers, setSubscribers] = useState<Subscriber[]>([]);

  return {
    context: {
      enabled: !!subscribers.length,
      notify: () => subscribers.forEach(subscriber => subscriber()),
      register: (subscriber: Subscriber) => {
        setSubscribers(_.uniq([...subscribers, subscriber]));
        return () => setSubscribers(_.without(subscribers, subscriber));
      },
    },
  };
}

interface TourTriggerContextProviderProps {
  children: ReactNode;
}

export function TourTriggerContextProvider({
  children,
}: TourTriggerContextProviderProps) {
  const { context } = useTourTriggerContextProvider();

  return (
    <TourTriggerContext.Provider value={context}>
      {children}
    </TourTriggerContext.Provider>
  );
}

export function useTourTriggerContext() {
  return useContext(TourTriggerContext);
}
