import { memo, useEffect } from "react";
import { useFormContext } from "react-hook-form";

import dayjs from "dayjs";

import { useAppTranslation, useClientParams } from "@hooks";
import { useFetchClientQuery } from "@hooks/queries";
import { Labeled } from "@components/Labeled";
import { FormInput } from "@components/FormInput";

export const ProgramNameSelect = memo(() => {
  const { t } = useAppTranslation();
  const { control, watch, setValue } = useFormContext();
  const id = useClientParams();
  const { client } = useFetchClientQuery(id, { enabled: !!id });
  const namePl = watch("namePl");

  useEffect(() => {
    if (namePl === "" && client) {
      setValue(
        "namePl",
        client &&
          `${client.firstName} ${client.lastName} - ${dayjs().format(
            "DD.MM.YYYY",
          )}`,
      );
    }
  }, [client]);

  return (
    <div className="flex items-stretch gap-6">
      <Labeled
        className="w-full"
        label={t("addNutritionalProgram.step1.typeProgramName")}
      >
        <FormInput
          name="namePl"
          control={control}
          variant="outlined"
          placeholder={t("addNutritionalProgram.step1.programName")}
        />
      </Labeled>
      <Labeled
        className="w-full"
        label={t("addNutritionalProgram.step1.typeProgramNameEng")}
      >
        <FormInput
          name="nameEn"
          control={control}
          variant="outlined"
          placeholder={t("addNutritionalProgram.step1.programNameEng")}
        />
      </Labeled>
    </div>
  );
});
