import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ChangeEvent, useState } from "react";

import * as yup from "yup";
import { Accordion, FormControlLabel, Radio } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";

import { Chats } from "@assets/icons/Program";
import { Carrot } from "@assets/icons/Menu";
import { Input } from "@components/Input";
import { MultiLanguageInput } from "@components/MultiLanguageInput";
import { FormInput } from "@components/FormInput";
import { FormTextarea } from "@components/FormTextarea";
import { FormCheckbox } from "@components/FormCheckbox";
import { Checkbox } from "@components/Checkbox";
import { FormDatepicker } from "@components/FormDatepicker";
import { FormRadioGroup } from "@components/FormRadioGroup";
import { FormRadioTileGroup } from "@components/FormRadioTileGroup";
import { RadioTile } from "@components/RadioTile";
import { FormSelect } from "@components/FormSelect";
import { SelectOption } from "@components/SelectOption";
import { FormSwitch } from "@components/FormSwitch";
import { Button } from "@components/Button";
import { Button as MuiButton } from "@mui/material";
import { FormMultiselect } from "@components/FormMultiSelect";
import { AutosaveStatus } from "@components/AutosaveStatus";
import {
  Weekday,
  WeekdaySelector,
} from "@components/WeekdaySelector/WeekdaySelector";
import { usePostClientActivateProgram } from "@hooks/queries";
import { weekdays } from "@utils/weekdays";
import { parseValueToString } from "@utils/numbers";
import { ThemeProviderWrapperNew } from "themeNew";
// import { DevTool } from "@hookform/devtools";

/** data interface */
interface TestFormInput {
  name: string;
  namePl: string;
  nameEn: string;
  info?: string;
  email: string;
  bio: string;
  agreement: boolean;
  newsletter?: boolean;
  ecoProgram: boolean;
  date: Date | string;
  sex: string;
  language: string;
  dietType: string;
  days: number[];
  showElements?: boolean;
  article?: string;
  percent: number[];
  surname: string;
  kcal: number;
  search: string;
}

/** data schema validation */
const testFormSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  info: yup.string().nullable(),
  email: yup.string().required("Email is reqired").email("Bad email format"),
  bio: yup.string().required("Bio is required").min(40, "Min 40 chars"),
  agreement: yup.boolean().oneOf([true], "Agreement is required"),
  newsletter: yup.boolean(),
  ecoProgram: yup.boolean().oneOf([true], "Eco program is required"),
  date: yup.mixed<Date | string>().required("Date is required"),
  sex: yup.string().nullable(),
  language: yup.string().required("Lang is required"),
  dietType: yup.string().required("diet is required"),
  days: yup.array().of(yup.number()).required("Days are required"),
  showElements: yup.boolean(),
  article: yup.string().nullable(),
  percent: yup.array().of(yup.number()).nullable(),
  surname: yup.string().required("Surname is required"),
  kcal: yup.number(),
});

/** form for testing form components */
export const TestForm = () => {
  const { i18n } = useTranslation();
  const {
    watch,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<TestFormInput>({
    resolver: yupResolver(testFormSchema),
    defaultValues: {
      kcal: 0,
      search: "",
      namePl: "Polska nazwa",
      nameEn: "English name",
    },
  });

  const [selectedDays, setSelectedDays] = useState<Weekday[]>([]);
  const countTabs = [1, 2, 3, 4, 5, 6, 7];

  const watchValues = watch();

  const onSubmit = (data: TestFormInput): void => {
    console.log(data);
  };

  const handleCustomOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.name as keyof TestFormInput, e.target.value);
  };

  const handleKcalInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const valueAsNumber = parseInt(value);
    setValue(name as keyof TestFormInput, valueAsNumber);
  };

  const handleMultiLangChange = (lang: string, value?: string) => {
    if (lang === "pl") setValue("namePl", value ?? "");
    if (lang === "en") setValue("nameEn", value ?? "");
  };

  const multiLangValue = (lang: string) => {
    if (lang === "pl") return watch("namePl") ?? "";
    if (lang === "en") return watch("nameEn") ?? "";
    else return "";
  };
  const { mutate } = usePostClientActivateProgram(3376);

  return (
    <>
      {/* <FormSelect
        open={open}
        displayEmpty
        form={form}
        name="perPage"
        // onChange={(e) => handleChange(e as any)}
        onClose={handleClose}
        onOpen={handleOpen}
        value={age}
      >
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={25}>25</MenuItem>
        <MenuItem value={50}>50</MenuItem>
      </FormSelect> */}
      <MuiButton>mutate</MuiButton>
      <ThemeProviderWrapperNew>
        <MuiButton>mutate</MuiButton>
      </ThemeProviderWrapperNew>
      <Button onClick={() => mutate({ patientId: 827, activate: true })}>
        mutate
      </Button>
      <AutosaveStatus />
      {/*<DayCardDietView />*/}
      <div className="grid grid-cols-4 bg-white rounded p-7 mb-20">
        <div className="grid grid-cols-1 col-span-1">
          {/** required text */}
          <div className="col-span-1 mb-4">
            <WeekdaySelector
              active={selectedDays}
              onChange={active => setSelectedDays(active)}
              size="large"
            />
            <WeekdaySelector
              active={selectedDays}
              onChange={active => setSelectedDays(active)}
              size="small"
            />

            <label htmlFor="name">Name: </label>
            <Input
              variant="outlined"
              placeholder="Type name"
              error={errors?.name?.message}
              {...register("name")}
            />
          </div>

          {/** multi language input */}
          <MultiLanguageInput
            label="Nazwa"
            languages={["pl", "en"]}
            onChange={handleMultiLangChange}
            value={multiLangValue}
          />

          {/** input with suffix and icon button*/}
          {/*<InputSuffixIconButton*/}
          {/*  onIconClick={() => console.log("click")}*/}
          {/*  icon={<Edit size={"w-5 h-5"} />}*/}
          {/*  suffix={"kcal"}*/}
          {/*/>*/}

          {/** required text */}
          <div className="col-span-1 mb-4">
            <label htmlFor="surname">Surname: </label>
            <FormInput
              name="surname"
              control={control}
              variant="outlined"
              placeholder="Type surname"
              onChange={handleCustomOnChange}
              error={errors?.surname?.message}
            />
          </div>
          {/** value as number */}
          <div className="col-span-1 mb-4">
            <label htmlFor="surname">Calories: </label>
            <FormInput
              name="kcal"
              control={control}
              value={watchValues.kcal || ""}
              onChange={handleKcalInput}
              variant="outlined"
              placeholder="Type calories"
            />
          </div>

          {/** optional text */}
          <div className="col-span-1 mb-4">
            <label htmlFor="name">Info: </label>
            <Input
              variant="outlined"
              placeholder="Type info"
              {...register("info")}
            />
          </div>

          {/** email format */}
          <div className="col-span-1 mb-4">
            <label htmlFor="name">Email: </label>
            <Input
              variant="outlined"
              placeholder="Type email"
              error={errors?.email?.message}
              {...register("email")}
            />
          </div>

          {/** required textarea */}
          <div className="col-span-1 mb-4">
            <label htmlFor="name">Email: </label>
            <FormTextarea
              name="bio"
              control={control}
              placeholder="Type bio"
              error={errors?.bio?.message}
            />
          </div>

          {/** required checkbox */}
          <div className="col-span-1 mb-4">
            <label htmlFor="name">Agreement: </label>
            <FormCheckbox
              name="agreement"
              control={control}
              label="I agree to proceed my data"
              error={errors?.agreement?.message}
            />
          </div>

          {/** optional checkbox */}
          <div className="col-span-1 mb-4">
            <label htmlFor="name">Newsletter: </label>
            <Checkbox
              label="I agree to get marketing information"
              error={errors?.newsletter?.message}
              {...register("newsletter")}
            />
          </div>

          {/** rounded checkbox */}
          <div className="col-span-1 mb-4">
            <label htmlFor="day">Newsletter: </label>
            <Checkbox round label="Mon" name="day" />
          </div>

          {/** required checkbox with bg */}
          <div className="col-span-1 mb-4">
            <label htmlFor="name">Eco program: </label>
            <Checkbox
              withBackground
              iconEnd={<Chats size="w-12 h-12" />}
              label="I agree to get eco program info"
              error={errors?.ecoProgram?.message}
              {...register("ecoProgram")}
            />
          </div>

          {/** required checkbox with bg */}
          <div className="col-span-1 mb-4">
            <label htmlFor="name">Date: </label>
            <FormDatepicker
              name="date"
              control={control}
              error={errors?.date?.message}
            />
          </div>

          {/** radio group */}
          <div className="col-span-1 mb-4">
            <label htmlFor="name">Sex: </label>
            <FormRadioGroup name="sex" control={control}>
              <FormControlLabel
                label="Female"
                value="female"
                control={<Radio />}
              />

              <label htmlFor="name">Name: </label>
              <Input
                variant="outlined"
                placeholder="Type name"
                error={errors?.name?.message}
                {...register("name")}
              />
            </FormRadioGroup>
          </div>

          {/** required text */}
          <div className="col-span-1 mb-4">
            <label htmlFor="surname">Surname: </label>
            <FormInput
              name="surname"
              control={control}
              variant="outlined"
              placeholder="Type surname"
              onChange={handleCustomOnChange}
              error={errors?.surname?.message}
            />
          </div>
          {/** value as number */}
          <div className="col-span-1 mb-4">
            <label htmlFor="surname">Calories: </label>
            <FormInput
              name="kcal"
              control={control}
              value={watchValues.kcal || ""}
              onChange={handleKcalInput}
              variant="outlined"
              placeholder="Type calories"
            />
          </div>

          {/** optional text */}
          <div className="col-span-1 mb-4">
            <label htmlFor="name">Info: </label>
            <Input
              variant="outlined"
              placeholder="Type info"
              {...register("info")}
            />
          </div>

          {/** email format */}
          <div className="col-span-1 mb-4">
            <label htmlFor="name">Email: </label>
            <Input
              variant="outlined"
              placeholder="Type email"
              error={errors?.email?.message}
              {...register("email")}
            />
          </div>

          {/** required textarea */}
          <div className="col-span-1 mb-4">
            <label htmlFor="name">Email: </label>
            <FormTextarea
              name="bio"
              control={control}
              placeholder="Type bio"
              error={errors?.bio?.message}
            />
          </div>

          {/** required checkbox */}
          <div className="col-span-1 mb-4">
            <label htmlFor="name">Agreement: </label>
            <FormCheckbox
              name="agreement"
              control={control}
              label="I agree to proceed my data"
              error={errors?.agreement?.message}
            />
          </div>

          {/** optional checkbox */}
          <div className="col-span-1 mb-4">
            <label htmlFor="name">Newsletter: </label>
            <Checkbox
              label="I agree to get marketing information"
              error={errors?.newsletter?.message}
              {...register("newsletter")}
            />
          </div>

          {/** rounded checkbox */}
          <div className="col-span-1 mb-4">
            <label htmlFor="day">Newsletter: </label>
            <Checkbox round label="Mon" name="day" />
          </div>

          {/** required checkbox with bg */}
          <div className="col-span-1 mb-4">
            <label htmlFor="name">Eco program: </label>
            <Checkbox
              withBackground
              iconEnd={<Chats size="w-12 h-12" />}
              label="I agree to get eco program info"
              error={errors?.ecoProgram?.message}
              {...register("ecoProgram")}
            />
          </div>

          {/** required checkbox with bg */}
          <div className="col-span-1 mb-4">
            <label htmlFor="name">Date: </label>
            <FormDatepicker
              name="date"
              control={control}
              error={errors?.date?.message}
            />
          </div>

          {/** radio group */}
          <div className="col-span-1 mb-4">
            <label htmlFor="name">Sex: </label>
            <FormRadioGroup name="sex" control={control}>
              <FormControlLabel
                label="Female"
                value="female"
                control={<Radio />}
              />
              <FormControlLabel label="Men" value="men" control={<Radio />} />
            </FormRadioGroup>
          </div>

          {/** radio tile group */}
          <div className="col-span-1 mb-4">
            <label htmlFor="name">Language: </label>
            <FormRadioTileGroup
              name="language"
              control={control}
              variant="outlined"
              error={errors?.language?.message}
            >
              <RadioTile label="PL" value="PL" />
              <RadioTile label="Ang" value="ENG" />
            </FormRadioTileGroup>
          </div>

          {/** select */}
          <FormSelect
            name="dietType"
            control={control}
            menuStyle={{ width: 400 }}
            error={errors?.dietType?.message}
            filterOptions
            placeholder="Wybierz dietę"
          >
            <SelectOption value="SIMPLE">Prosta</SelectOption>
            <SelectOption value="COMPLEX">Złozona</SelectOption>
            <SelectOption value="MIXED">Mieszana</SelectOption>
            <SelectOption value="SIMPLE2">Prosta2</SelectOption>
            <SelectOption value="COMPLEX2">Złozona2</SelectOption>
            <SelectOption value="MIXED2">Mieszana2</SelectOption>
            <SelectOption value="SIMPLE3">Prosta3</SelectOption>
            <SelectOption value="COMPLEX3">Złozona3</SelectOption>
            <SelectOption value="MIXED3">Mieszana3</SelectOption>
            <SelectOption value="SIMPLE4">Prosta4</SelectOption>
            <SelectOption value="COMPLEX4">Złozona4</SelectOption>
            <SelectOption value="MIXED4">Mieszana4</SelectOption>
            <SelectOption value="SIMPLE5">Prosta5</SelectOption>
            <SelectOption value="COMPLEX5">Złozona5</SelectOption>
            <SelectOption value="MIXED5">Mieszana5</SelectOption>
          </FormSelect>

          {/** multiselect */}
          <div className="col-span-1 mb-2">
            <label htmlFor="days">Days:</label>
            <FormMultiselect
              name="days"
              control={control}
              options={weekdays}
              placeholder="Wybierz dni"
              labelKey={i18n.language === "pl" ? "labelPl" : "labelEn"}
              tagLabelKey={i18n.language === "pl" ? "shortPl" : "shortEn"}
            />
          </div>

          {/** switch */}
          <div className="col-span-1 mb-2">
            <label htmlFor="days">Show elements:</label>
            <FormSwitch name="showElements" control={control} />
          </div>

          <div className="col-span-1 mb-2">
            <FormInput
              name="kcal"
              control={control}
              variant="outlined"
              value={parseValueToString(watchValues.kcal)}
              onChange={handleKcalInput}
            />
          </div>

          {/** submit button */}
          <div className="col-span-1 mb-4">
            <Button size="large" onClick={handleSubmit(onSubmit)}>
              Submit
            </Button>
            <Button
              isLoading
              size="large"
              onClick={handleSubmit(onSubmit)}
              style={{ width: 150 }}
            >
              Submit
            </Button>
          </div>

          <div className="col-span-1">
            {/** accordtion */}
            <Accordion title="Examplary accordtion">
              <Button size="large">Hello world</Button>
            </Accordion>
          </div>
        </div>
        {/** Frequency picker */}
        {/* <div className="col-span-2 mb-4">
        <FrequencyPicker
          useIntervals
          name="intervals"
          availableWeekdays={weekdays.map(day => day.id)}
        />
      </div> */}

        {/*<DevTool control={control} />*/}
      </div>
    </>
  );
};
