import { QueryOptionsTyped } from "@hooks/queries";
import { useQuery } from "@tanstack/react-query";
import { FetchDietProgramPreviewParams } from "@client/program/preview/fetchDietProgramPreview";
import {
  fetchProgramPreview,
  FetchProgramPreviewParams,
  FetchProgramPreviewResponse,
} from "@client/program/preview/fetchProgramPreview";
import { programPreviewKeys } from "@hooks/queries/program/preview/programPreviewKeys";

export const getKey = (params: FetchProgramPreviewParams) =>
  programPreviewKeys.program(params.programId);

const useFetchProgramPreview = (
  params: FetchProgramPreviewParams,
  options?: QueryOptionsTyped<FetchProgramPreviewResponse>,
) => {
  return useQuery({
    queryKey: getKey(params),
    queryFn: () => fetchProgramPreview(params),
    ...options,
  });
};

export default useFetchProgramPreview;
