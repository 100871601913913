import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchDataRaw } from "@utils/api";

export async function confirmVisit(url: string): Promise<ApiResponse<void>> {
  const response = await fetchDataRaw(
    url,
    APIMethods.POST,
    undefined,
    undefined,
    undefined,
    undefined,
    "",
  );

  if (response.status === 250) {
    throw "Already confirmed";
  }

  return response.data;
}
