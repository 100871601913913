import { Divider } from "@mui/material";

import { Nutrient } from "@typeDefinitions";
import { useAppTranslation, useMeasures } from "@hooks";
import { MacrosListMui } from "@components/MacrosList";
import { MicronutrientPreview } from "@components/MicronutrientsModal";
import { LabeledDetail } from "@components/PreviewDetails";
import { scaleNutrients } from "@utils";

import { ProductTitle, RowNutrientsWrapper } from "../../Meal.styled";

export interface MealProduct {
  namePl: string;
  nameEn: string;
  nutrients: Nutrient[];
  grams: number;
  measureId: number;
  measureGrams: number;
  id: number;
}
export const ProductRow = ({
  namePl,
  nameEn,
  nutrients,
  grams,
  measureGrams,
  measureId,
}: MealProduct) => {
  const { isPolishChosen, t } = useAppTranslation();
  const { getMeasure } = useMeasures();

  const name = isPolishChosen ? namePl : nameEn;
  const measureAmount = grams / measureGrams;
  const measureLabel = getMeasure(measureId)?.name ?? "";

  const scaledNutrients = scaleNutrients(nutrients, grams);

  return (
    <>
      <div className="grid">
        <ProductTitle variant="h3">{name}</ProductTitle>
        <RowNutrientsWrapper>
          <MacrosListMui nutrients={scaledNutrients} />
          <MicronutrientPreview means={scaledNutrients} noPadding />
        </RowNutrientsWrapper>
        <div className="flex gap-16">
          <LabeledDetail label={t("meals.mass")} value={`${grams} g`} />
          <LabeledDetail
            label={t("meals.home_measure")}
            value={`${measureAmount} ${measureLabel}`}
          />
        </div>
      </div>
      <Divider />
    </>
  );
};
