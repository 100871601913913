import styled from "@emotion/styled";

export const WysiwygEditorWrapper = styled.div`
  display: flex;
  max-height: 420px;
  overflow: auto;
  margin: -8px;
  font-size: initial;
  // who write styles in this way???
  & > div {
    width: 100%;
    height: max-content;
    min-height: 138px;
    border: 1px solid transparent;
    border-radius: 5px;
    outline: none !important;
    padding: 8px;
  }
  & > div:focus {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
  ol,
  ul {
    list-style: revert;
    padding: revert;
    margin: revert;
  }
  .mce-content-body:not([dir="rtl"])[data-mce-placeholder]:not(.mce-visualblocks)::before {
    left: 8px;
  }
`;

export const WysiwygEditorWrapper2 = styled("div")`
  margin: -8px;
  max-height: 345px;

  & .ql-container {
    max-height: 300px;

    & .ql-editor {
      max-height: 300px;
    }
  }

  & .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border: 1px solid transparent;
    border-radius: 5px;

    &:focus-within {
      border: 1px solid ${({ theme }) => theme.palette.primary.main};
    }
  }
`;
