import { UseFormReturn } from "react-hook-form";
import { useMemo } from "react";

import { FilterDefinition, FilterType, Ownership } from "@typeDefinitions";
import { Plus } from "@assets/icons";
import { useAppTranslation } from "@hooks";
import { useFetchSectionCategoriesQuery } from "@hooks/queries/useFetchSectionCategoriesQuery";
import { SECTION_NEW } from "@routes";
import { SectionsFiltersInput } from "@typeDefinitions/types";
import { SearchFilters } from "@components/SearchFilters";
import { Link } from "react-router-dom";
import { ActionButton } from "@components/ListModules";

export const SectionFilters = ({ form, onSubmit }: SectionFiltersProps) => {
  const { t, isPolishChosen } = useAppTranslation();

  const { sectionCategories } = useFetchSectionCategoriesQuery();

  const filters = useMemo<FilterDefinition<SectionsFiltersInput>[]>(
    () => [
      {
        type: FilterType.SINGLE,
        name: "owner",
        label: `${t("sections.filters.sections")}`,
        options: [
          { label: t("sections.filters.all"), value: undefined },
          { label: t("sections.filters.own"), value: Ownership.OWN },
          { label: t("sections.filters.alloweat"), value: Ownership.ALLOWEAT },
        ],
      },
      {
        type: FilterType.SINGLE,
        name: "category",
        label: `${t("sections.filters.category")}`,
        options:
          sectionCategories?.map(category => ({
            label: isPolishChosen
              ? category.description
              : category.descriptionEn,
            value: category.id,
          })) ?? [],
      },
    ],
    [t, isPolishChosen, sectionCategories],
  );

  return (
    <div className="flex flex-col gap-3">
      <SearchFilters
        form={form}
        onSubmit={onSubmit}
        searchFieldName={"name"}
        searchFieldPlaceholderLabel={t("sections.search")}
        actionComponent={
          <Link to={SECTION_NEW}>
            <ActionButton
              variant="contained"
              size="large"
              startIcon={<Plus size="w-4 h-4" className="stroke-current" />}
            >
              {t("sections.createNew")}
            </ActionButton>
          </Link>
        }
        filters={filters}
      />
    </div>
  );
};

interface SectionFiltersProps {
  form: UseFormReturn<SectionsFiltersInput>;
  onSubmit?: () => any;
}
