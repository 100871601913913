import * as yup from "yup";
import { NutrientDto, nutrientSchema } from "@client";

export interface DietitianProgramScheduleRecipe {
  id: number | null;
  clinicId: number | null;
  title: string;
  titleEn: string | null;
  description: string | null;
  descriptionEn: string | null;
  reviewed: boolean | null;
  isComplex: boolean | null;
  servings: number | null;
  nutrients: NutrientDto[];
}

export interface DietitianProgramScheduleMeal {
  id: number | null;
  dietMeal: {
    title: string;
    hour: string | null; // hh:mm
    description: string | null;
    id: number | null;
  };
  recipe: DietitianProgramScheduleRecipe | null;
  servings: number | null;
}

export interface DietitianProgramScheduleDayVariant {
  id: number | null;
  date: string;
  program: { id: number };
  programDay: {
    id: number;
    diet: { id: number | null };
    name: string;
  };
  meals: DietitianProgramScheduleMeal[];
  nutrients: NutrientDto[];
  actions: { canCopy: boolean };
}

export interface DietitianProgramScheduleDay
  extends DietitianProgramScheduleDayVariant {
  id: number;
}

export interface ProgramOptimizationSettings {
  programDay: {
    id: number;
  };
  nutrient: {
    id: number;
  };
  value: number;
  distribution: number;
  tolerance: number;
}

export interface ProgramOptimizationSettingsResponse
  extends ProgramOptimizationSettings {
  program?: {
    id: number;
  };
}

export const mealDescriptionSchema = yup.object().shape({
  title: yup.string().required(),
  hour: yup.string().nullable().default(null),
  description: yup.string().nullable().default(null),
  id: yup.number().nullable().default(null),
});

export const mealRecipeSchema = yup.object().shape({
  id: yup.number().nullable().default(null),
  clinicId: yup.number().nullable().default(null),
  title: yup.string().required(),
  titleEn: yup.string().nullable().default(null),
  description: yup.string().nullable().default(null),
  descriptionEn: yup.string().nullable().default(null),

  reviewed: yup.boolean().nullable().default(null),
  isComplex: yup.boolean().nullable().default(null),
  servings: yup.number().nullable().default(null),
  nutrients: yup.array().of(nutrientSchema).required(),
});

export const dietitianDietDaySchema = yup.object().shape({
  id: yup.number().required(),
  date: yup.string().required(),
  program: yup
    .object()
    .shape({
      id: yup.number().required(),
    })
    .required(),
  programDay: yup
    .object()
    .shape({
      id: yup.number().required(),
      name: yup.string().required(),
      diet: yup
        .object()
        .shape({
          id: yup.number().nullable().default(null),
        })
        .required(),
    })
    .required(),
  nutrients: yup.array().of(nutrientSchema).required(),
  meals: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().nullable().default(null),
        dietMeal: mealDescriptionSchema.required(),
        recipe: mealRecipeSchema.nullable().default(null),
        servings: yup.number().nullable().default(null),
      }),
    )
    .required(),
  actions: yup.object().shape({ canCopy: yup.boolean().required() }).required(),
});

export const programOptimizationSettingsSchema = yup.object().shape({
  program: yup
    .object()
    .shape({
      id: yup.number().required(),
    })
    .required(),
  programDay: yup
    .object()
    .shape({
      id: yup.number().required(),
    })
    .required(),
  nutrient: yup
    .object()
    .shape({
      id: yup.number().required(),
    })
    .required(),
  value: yup.number().required(),
  distribution: yup.number().required(),
  tolerance: yup.number().required(),
});
