import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import * as yup from "yup";
import { validateAndFixNutrients } from "../utils/validateCatching";

export async function fetchNutrients(): Promise<
  ApiResponse<FetchNutrientsResponse[]>
> {
  const data = await fetchData("/nutrients", APIMethods.GET);
  return await validateAndFixNutrients(fetchNutrientsResponseSchema, data);
}

const fetchNutrientsResponseSchema = yup.object().shape({
  data: yup.array().of(
    yup.object().shape({
      units: yup.string().nullable(),
      id: yup.number().required(),
      nutrientCategoryId: yup.number().required(),
      descriptionEn: yup.string().required(),
      descriptionPl: yup.string().required(),
      nameEn: yup.string().required(),
      namePl: yup.string().required(),
      dietitianVisible: yup.boolean().required(),
      patientVisible: yup.boolean().required(),
      diaryVisible: yup.boolean().required(),
    }),
  ),
});

interface FetchNutrientsResponse {
  id: number;
  nutrientCategoryId: number;
  descriptionEn: string;
  descriptionPl: string;
  nameEn: string;
  namePl: string;
  dietitianVisible: boolean;
  patientVisible: boolean;
  units?: string;
}
