import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import { validateAndFixNutrients } from "./utils/validateCatching";

export async function fetchProgramDayNutrients(
  programId: string,
  dayId: string,
): Promise<ApiResponse<FetchProgramDayNutrientsResponse>> {
  const rsp = await fetchData(
    `/dietitian/programs/${programId}/days/${dayId}/target-nutrients`,
    APIMethods.GET,
  );
  return await validateAndFixNutrients(
    fetchDietDayNutrientsResponseSchema,
    rsp,
  );
}

const fetchDietDayNutrientsResponseSchema = yup.object().shape({
  data: yup
    .object()
    .shape({
      norm: yup
        .object()
        .shape({
          id: yup.string().required(),
        })
        .nullable(),
      nutrients: yup
        .array()
        .of(
          yup.object().shape({
            id: yup.number().required(),
            value: yup.number().required(),
            visible: yup.boolean().required(),
          }),
        )
        .required(),
    })
    .required(),
});

export interface ProgramDayNutrientDto {
  id: number;
  value: number;
  visible: boolean;
}

export interface FetchProgramDayNutrientsResponse {
  nutrients: ProgramDayNutrientDto[];
  norm?: { id: string };
}
