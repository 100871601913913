import { ReactElement } from "react";
import useFetchPatientHydrationProduct from "@hooks/queries/client/useFetchPatientHydrationProduct";
import { useClientParams } from "@hooks";
import useUpdatePatientHydrationProductMutation from "@hooks/queries/client/useUpdatePatientHydrationProductMutation";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import {
  GOAL_OTHER_VARIANT,
  GOALS_VARIANTS,
} from "@views/dietician/ClientHealth/ProfileTab/IrrigationSection/Dialog/GoalField";
import { PatientHydrationProductResource } from "@client/resources/PatientHydrationProductResource";

type IrrigationFormProps = {
  patientHydrationProduct: PatientHydrationProductResource;
  children: ReactElement | ReactElement[];
  onSuccess: () => void;
};

export type FormPops = {
  hydrationProductId: string;
  selectedGoal: string;
  goal: string;
};

const IrrigationFormDetails = ({
  children,
  onSuccess,
}: Pick<IrrigationFormProps, "onSuccess" | "children">) => {
  const id = useClientParams();
  const { data } = useFetchPatientHydrationProduct({ patientId: id });

  if (!data) {
    return null;
  }

  return (
    <IrrigationForm patientHydrationProduct={data.data} onSuccess={onSuccess}>
      {children}
    </IrrigationForm>
  );
};

const IrrigationForm = ({
  children,
  onSuccess,
  patientHydrationProduct,
}: IrrigationFormProps) => {
  const id = useClientParams();
  const mutation = useUpdatePatientHydrationProductMutation();

  const form = useForm<FormPops>({
    defaultValues: {
      hydrationProductId:
        patientHydrationProduct.hydrationProduct.id.toString(),
      goal: patientHydrationProduct.goal.toString(),
      selectedGoal: GOALS_VARIANTS.includes(
        patientHydrationProduct.goal.toString(),
      )
        ? patientHydrationProduct.goal.toString()
        : GOAL_OTHER_VARIANT,
    },
  });

  const onSubmit: SubmitHandler<FormPops> = async data => {
    await new Promise<void>(resolve => {
      mutation.mutate(
        {
          params: {
            patientId: id,
          },
          payload: {
            hydration_product: {
              id: parseInt(data.hydrationProductId),
            },
            goal: data.goal ? parseInt(data.goal) : 0,
          },
        },
        {
          onSuccess: () => {
            resolve();
            onSuccess();
          },
        },
      );
    });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>{children}</form>
    </FormProvider>
  );
};

export default IrrigationFormDetails;
