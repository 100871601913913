import { AccordionDetails, Box, CircularProgress } from "@mui/material";
import {
  AccordionStyled,
  AccordionSummaryStyled,
  AccordionHeader,
} from "./TasksAccordion.styled";
import { ArrowDown } from "@assets/icons";
import { TasksTable } from "../TasksTable/TasksTable";
import { cloneElement, useMemo, useState } from "react";
import type { FC, ReactElement } from "react";
import { useTasksQuery } from "@hooks/queries/tasks/useTasksQuery";
import { TaskType } from "./TasksAccordion";
import { useTaskViewSettingsContext } from "../TaskViewSettings.context";

interface TaskAccordionItemProps {
  title: string;
  isExpanded: boolean;
  isHidden: boolean;
  icon: ReactElement | null;
  type: TaskType;
  id?: string;
}

export const TaskAccordionItem: FC<TaskAccordionItemProps> = ({
  title,
  isExpanded,
  isHidden,
  icon,
  type,
  id,
}) => {
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const { hideFinished } = useTaskViewSettingsContext();

  const { data, isLoading } = useTasksQuery(page, perPage, type, id);

  const tasksToDisplay = useMemo(() => {
    if (!data) return [];

    const { tasks } = data;

    if (type === TaskType.Todo && id === "others") {
      return tasks.filter(
        ({ customTags, isDone }) =>
          (!customTags || customTags.length === 0) &&
          (hideFinished ? !isDone : true),
      );
    }

    if (type === TaskType.Tag) {
      return tasks.filter(({ customTags, isDone }) =>
        customTags?.some(
          ({ name }) => name === title && (hideFinished ? !isDone : true),
        ),
      );
    }

    return tasks;
  }, [data, type, hideFinished]);

  const iconWithClass = useMemo(() => {
    return icon ? cloneElement(icon, { className: "text-2xl" }) : null;
  }, [icon]);

  if (isLoading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding={2}
      >
        <CircularProgress />
      </Box>
    );

  if (isHidden || (!isLoading && tasksToDisplay.length === 0)) {
    return null;
  }

  if (!data) return null;

  const { meta } = data;

  const isTagsView = type === TaskType.Tag && hideFinished;

  const isTagsViewOthersSection = type === TaskType.Todo && id === "others";

  const totalCount =
    isTagsView || isTagsViewOthersSection ? tasksToDisplay.length : meta.total;

  return (
    <AccordionStyled
      defaultExpanded={isExpanded}
      className="last-of-type:border-none pb-1 border-b-2 border-dashed border-primary-opacity"
    >
      <AccordionSummaryStyled expandIcon={<ArrowDown />}>
        <AccordionHeader>
          {iconWithClass}
          <span> {`${title} (${totalCount})`}</span>
        </AccordionHeader>
      </AccordionSummaryStyled>
      <AccordionDetails>
        <TasksTable
          tasks={tasksToDisplay}
          page={page}
          taskType={type}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          isFetching={false}
          meta={meta}
          totalCount={totalCount}
        />
      </AccordionDetails>
    </AccordionStyled>
  );
};
