import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Smartphone = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="10" height="14" viewBox="0 0 10 14">
      <path
        d="M0.5 2.125C0.5 1.22871 1.23381 0.5 2.13636 0.5H7.86364C8.76619 0.5 9.5 1.22871 9.5 2.125V11.875C9.5 12.7713 8.76619 13.5 7.86364 13.5H2.13636C1.23381 13.5 0.5 12.7713 0.5 11.875V2.125ZM5.81818 11.875C5.81818 11.6595 5.73198 11.4528 5.57854 11.3005C5.4251 11.1481 5.217 11.0625 5 11.0625C4.783 11.0625 4.5749 11.1481 4.42146 11.3005C4.26802 11.4528 4.18182 11.6595 4.18182 11.875C4.18182 12.0905 4.26802 12.2972 4.42146 12.4495C4.5749 12.6019 4.783 12.6875 5 12.6875C5.217 12.6875 5.4251 12.6019 5.57854 12.4495C5.73198 12.2972 5.81818 12.0905 5.81818 11.875ZM7.86364 2.125H2.13636V10.25H7.86364V2.125Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
