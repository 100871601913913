import {
  ClientProfileCard,
  EditButton,
  SectionWrapper,
  TitleSection,
} from "../../ClientProfile.styled";
import { useAppTranslation, useClientSources, useModal } from "@hooks";
import { Pencil } from "@assets/icons";
import { ProfileSectionProps, noData } from "../../ClientProfile";
import { ClientProfileCardItem } from "../ClientProfileCardItem";
import { EditClientOfficeModal } from "@components/EditClientOfficeModal";

export const OfficeSection = ({ account }: ProfileSectionProps) => {
  const {
    dietitian,
    facility,
    profile: { sources },
  } = account;
  const { t } = useAppTranslation();
  const { getSourceName } = useClientSources();
  const { modalOpened, onModalOpen, onModalClose } = useModal();
  const sourceId = sources[0]?.id ?? 0;

  return (
    <>
      <ClientProfileCard>
        <TitleSection>
          <p>{t("client_profile.office.office")}</p>
          <EditButton
            onClick={onModalOpen}
            startIcon={<Pencil size="w-4 h-4" className="stroke-current" />}
            disableRipple
          >
            {t("common.edit")}
          </EditButton>
        </TitleSection>

        <SectionWrapper>
          <ClientProfileCardItem
            title={t("client_profile.office.dietitian")}
            subtitle={dietitian?.name ?? noData}
          />
          <ClientProfileCardItem
            title={t("common.localization")}
            subtitle={facility?.name ?? noData}
          />
          <ClientProfileCardItem
            title={t("client_profile.office.recommendation_source")}
            subtitle={getSourceName(sourceId) || noData}
          />
        </SectionWrapper>
      </ClientProfileCard>

      <EditClientOfficeModal open={modalOpened} onClose={onModalClose} />
    </>
  );
};
