import { useMemo } from "react";

import { TagCategoryType } from "@utils/tagsNew";

import {
  useFetchTagCategoriesQuery,
  useFetchTagCategoriesQueryKey,
} from "./queries";
import { useAppTranslation } from "./useAppTranslation";
import { useQueryClient } from "@tanstack/react-query";
import { ApiResponse } from "@typeDefinitions";
import { FetchTagCategoriesCategory, LangDto, TranslationDto } from "@client";
import { getTranslation } from "@utils/translations";

export interface TagSimplified {
  name: string;
  id: number;
  systemId: number | null;
  translations?: TranslationDto[];
}

export interface TagCategorySimplified {
  id: number;
  type: TagCategoryType;
  name: string;
  tags: TagSimplified[];
}

export interface OptionIf {
  id: string;
  label: string;
  translations: TranslationDto[];
}

interface UseTagsReturn {
  tagCategoryDictionary: Map<number, TagCategorySimplified>;
  tagNameDictionary: Map<number, string>;
  tagDictionary: Map<number, TagSimplified>;
  getTagCategoryName: (type: TagCategoryType) => string | undefined;
  getTagCategoryOptions: (type: TagCategoryType) => OptionIf[] | undefined;
  isSystemTag: (id: number) => boolean;
}

export const useTagsNew = (): UseTagsReturn => {
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData<
    ApiResponse<FetchTagCategoriesCategory[]>
  >([useFetchTagCategoriesQueryKey]);
  const { tagCategories } = useFetchTagCategoriesQuery({
    enabled: !data?.data,
  });
  const tagCategoriesCached = data?.data ?? tagCategories;
  const { isPolishChosen } = useAppTranslation();
  const tagCategoryDictionary = useMemo(
    () => createTypeMap(),
    [tagCategoriesCached, isPolishChosen],
  );
  const tagNameDictionary = useMemo(
    () => createTagNameMap(),
    [tagCategoryDictionary],
  );
  const tagDictionary = useMemo(() => createTagMap(), [tagCategoryDictionary]);

  function createTypeMap(): Map<number, TagCategorySimplified> {
    const typeMap = new Map<number, TagCategorySimplified>();

    tagCategoriesCached?.forEach(category => {
      const simplifiedTags: TagSimplified[] = category.tags.map(tag => ({
        id: tag.id,
        name: (isPolishChosen ? tag.namePl : tag.nameEn) ?? "",
        systemId: tag.systemId,
        translations: [
          { langId: LangDto.PL, name: tag.namePl ?? "" },
          { langId: LangDto.EN, name: tag.nameEn ?? "" },
        ],
      }));

      const simplifiedCategory: TagCategorySimplified = {
        id: category.id,
        type: category.type,
        name: (isPolishChosen ? category.name : category.nameEn) ?? "",
        tags: simplifiedTags,
      };

      typeMap.set(category.type, simplifiedCategory);
    });

    return typeMap;
  }

  function createTagNameMap(): Map<number, string> {
    const tagMap = new Map<number, string>();

    tagCategoryDictionary.forEach(category => {
      category.tags.forEach(tag => {
        const tagName = tag.name;
        tagMap.set(tag.id, tagName);
      });
    });

    return tagMap;
  }

  function createTagMap(): Map<number, TagSimplified> {
    const tagMap = new Map<number, TagSimplified>();

    tagCategoryDictionary.forEach(category => {
      category.tags.forEach(tag => {
        tagMap.set(tag.id, tag);
      });
    });

    return tagMap;
  }

  const getTagCategoryName = (type: TagCategoryType) =>
    tagCategoryDictionary.get(type)?.name;

  const getTagCategoryOptions = (
    type: TagCategoryType,
  ): OptionIf[] | undefined =>
    tagCategoryDictionary.get(type)?.tags.map(tag => ({
      id: tag.id.toString(),
      label: tag.name,
      translations: tag.translations ?? [],
    }));

  const isSystemTag = (id: number) => !!tagDictionary.get(id)?.systemId;

  return {
    tagCategoryDictionary,
    tagNameDictionary,
    tagDictionary,
    getTagCategoryName,
    getTagCategoryOptions,
    isSystemTag,
  };
};
