import { Control, Controller, FieldValues, Path } from "react-hook-form";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import { plPL, enUS } from "@mui/x-date-pickers/locales";

import { Clock, EmptyCalendar } from "@assets/icons";
import { useAppTranslation } from "@hooks/useAppTranslation";
import { MINUTES_VALIDATION } from "@views/dietician/Diet/components/AddVisitModal";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";

export interface IMuiTimeDatePickerProps<T extends FieldValues> {
  timeVersion?: boolean;
  dateVersion?: boolean;
  label?: string;
  disabled?: boolean;
  name: Path<T>;
  control: Control<T>;
  step?: number;
  variant?: string;
  minDate?: any;
  maxDate?: any;
  hideHelperText?: boolean;
  readOnly?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  fullWidth?: boolean;
  controlledError?: string;
  minTime?: Dayjs;
  onChangeCallback?: () => void;
}

export const MuiTimeDatePicker = <T extends FieldValues>({
  timeVersion,
  dateVersion,
  label,
  disabled,
  name,
  control,
  step,
  variant,
  minDate,
  maxDate,
  hideHelperText = false,
  readOnly,
  onBlur: onBlurExternal,
  onFocus,
  minTime,
  fullWidth,
  controlledError,
  onChangeCallback,
}: IMuiTimeDatePickerProps<T>) => {
  const { t, isPolishChosen, i18n } = useAppTranslation();
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={i18n.language}
      localeText={
        (isPolishChosen ? plPL : enUS).components.MuiLocalizationProvider
          .defaultProps.localeText
      }
    >
      <Controller
        name={name}
        control={control}
        render={({
          field: { value, onChange, ref, onBlur },
          fieldState: { error },
        }) => {
          const isMinutesError = error?.type === MINUTES_VALIDATION;
          const handleBlur = () => {
            onBlur();
            onBlurExternal && onBlurExternal();
          };
          const handleOnChange = (value: any) => {
            onChange(value);
            onChangeCallback?.();
          };

          return (
            <>
              {dateVersion && (
                <DatePicker
                  readOnly={readOnly}
                  InputAdornmentProps={{ position: "start" }}
                  minDate={minDate}
                  maxDate={maxDate}
                  disabled={disabled}
                  showDaysOutsideCurrentMonth
                  components={{
                    OpenPickerIcon: EmptyCalendar,
                  }}
                  label={label}
                  value={value}
                  onChange={handleOnChange}
                  inputFormat={isPolishChosen ? "DD.MM.YYYY" : "MM/DD/YYYY"}
                  PopperProps={{
                    onBlur: onBlurExternal,
                    onFocus: onFocus,
                    sx: theme => ({
                      ...theme.palette.primary,
                      "& .MuiPickersDay-today .MuiButtonBase-root .MuiPickersDay-root":
                        {
                          backgroundColor: theme.palette.primary.main,
                          color: theme.colors.whiteBackground,
                        },
                    }),
                  }}
                  InputProps={{
                    inputRef: ref,
                    sx:
                      variant === "standard"
                        ? { "& .MuiButtonBase-root": { padding: "1rem" } }
                        : undefined,
                  }}
                  renderInput={(params: any) => {
                    const hasMinutesError =
                      !isMinutesError &&
                      (!!params.error || !!error) &&
                      !readOnly;

                    const hasParamsError = hasMinutesError && params.error;
                    const hasMessageError = hasMinutesError && error?.message;

                    let errorMessage: string | undefined;

                    if (controlledError) errorMessage = controlledError;
                    else if (hasMessageError)
                      errorMessage = t(error.message ?? "");
                    else if (hasParamsError)
                      errorMessage = t(
                        "addNutritionalProgram.step1.invalid_date_format",
                      );

                    return (
                      <TextField
                        name={name}
                        focused={params.error || error}
                        variant={variant}
                        size="small"
                        fullWidth
                        {...params}
                        error={hasMinutesError || controlledError}
                        helperText={errorMessage}
                        ref={params.inputRef}
                        onBlur={handleBlur}
                        onFocus={onFocus}
                        FormHelperTextProps={{ hidden: hideHelperText }}
                      />
                    );
                  }}
                />
              )}

              {timeVersion && (
                <TimePicker
                  readOnly={readOnly}
                  minutesStep={step}
                  disabled={disabled}
                  ampm={!isPolishChosen}
                  minTime={minTime}
                  components={{
                    OpenPickerIcon: Clock,
                    ActionBar: undefined,
                  }}
                  label={label}
                  value={value}
                  onChange={handleOnChange}
                  InputProps={{ inputRef: ref }}
                  renderInput={(params: any) => (
                    <TextField
                      variant={variant}
                      size="small"
                      fullWidth={fullWidth}
                      {...params}
                      error={!!error && !readOnly}
                      helperText={error?.message ? t(error.message) : undefined}
                      ref={params.inputRef}
                      onBlur={handleBlur}
                      onFocus={onFocus}
                      FormHelperTextProps={{ hidden: hideHelperText }}
                    />
                  )}
                  PopperProps={{
                    onBlur: onBlurExternal,
                    onFocus: onFocus,
                  }}
                />
              )}
            </>
          );
        }}
      />
    </LocalizationProvider>
  );
};
