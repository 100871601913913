import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

import { QueryOptionsTyped } from "../types";

import { ApiResponse } from "@typeDefinitions";
import dayjs from "dayjs";
import {
  DietitianProgramScheduleDay,
  fetchDietitianProgramScheduleDays,
} from "@client/schedule";

export const fetchDietitianProgramScheduleKey =
  "requestDietitianProgramSchedule";

export const useFetchDietitianProgramScheduleQuery = (
  programId: number,
  periodDays: number,
  date: dayjs.Dayjs,
  options?: QueryOptionsTyped<ApiResponse<DietitianProgramScheduleDay[]>>,
) => {
  const params = useMemo(
    () =>
      new URLSearchParams({
        periodDays: periodDays.toString(),
        date: date.format("YYYY-MM-DD"),
      }),
    [periodDays, date],
  );

  const { data, ...rest } = useQuery(
    [fetchDietitianProgramScheduleKey, programId.toString(), params.toString()],
    () => fetchDietitianProgramScheduleDays(programId, params),
    options,
  );

  return {
    scheduleData: data?.data,
    pageInfo: data?.meta,
    totalPages: data?.meta?.lastPage || 1,
    ...rest,
  };
};
