import { Button, Chip, styled } from "@mui/material";

export const StyledChip = styled(Chip)`
  height: 28px;

  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 4px 0;

  &.MuiChip-colorError {
    background: ${({ theme }) => theme.palette.error.light};
    color: ${({ theme }) => theme.palette.error.dark};
  }
  &.MuiChip-colorWarning {
    background: ${({ theme }) => theme.colors.orange.light};
    color: ${({ theme }) => theme.colors.orange.dark};
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.mediumMax}px) {
    max-width: 265px;
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.laptop}px) {
    max-width: 412px;
  }
`;

export const ExpandedButton = styled(Button)`
  justify-content: unset;
  padding: 0;

  &:hover {
    background: unset;
  }
`;
