import { fetchData } from "@utils/api";
import { APIMethods, ApiEndpoints } from "@typeDefinitions";
import { TaskPriority } from "@views/dietician/Tasks/components/TaskPrioritySelect";

export const createTask = async (payload: CreateTaskRequestPayload) => {
  return await fetchData(ApiEndpoints.Tasks, APIMethods.POST, payload);
};

export interface CreateTaskRequestPayload {
  name: string;
  description?: string | null;
  relatedLink?: string | null;
  dueDate: string | null;
  priority: TaskPriority;
  dietitianId: number | null;
  clientId: number | null;
  customTagsIds: number[];
}
