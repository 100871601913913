import { Content } from "pdfmake/interfaces";
import {
  PDF_PAGE_HEIGHT_WITHOUT_MARGIN,
  PDF_PAGE_WIDTH_WITHOUT_MARGIN,
} from "@Pdf/defaults/pdfDefaults";
import { getImageDimension } from "@Pdf/utils/pdfImageUtils";
import { isArray } from "@Pdf/utils/guards";

function traverseAndTransform(node: any) {
  if (isArray(node)) {
    node.forEach(traverseAndTransform);
  } else if (node && typeof node === "object" && node.nodeName) {
    if (node.background) {
      node.fillColor = rgbToHex(node.background);
      delete node.background;
    }

    // Iterate over all properties of the node
    for (const key in node) {
      if (isArray(node[key])) {
        traverseAndTransform(node[key]);
      } else if (key === "table") {
        traverseAndTransform(node[key].body);
      }
    }
  } else if (node.background) {
    node.fillColor = rgbToHex(node.background);
    delete node.background;
  }
}

function rgbToHex(rgb: string) {
  const rgbValues = rgb.match(/\d+/g);
  if (!rgbValues || rgbValues.length !== 3) return rgb;

  return `#${(
    (1 << 24) +
    (parseInt(rgbValues[0]) << 16) +
    (parseInt(rgbValues[1]) << 8) +
    parseInt(rgbValues[2])
  )
    .toString(16)
    .slice(1)
    .toUpperCase()}`;
}
export const migrateNotSupportedHTMLContentStyle = async (
  content: Array<any>,
  imageRefs: Record<string, string>,
): Promise<Array<Content>> => {
  for (let i = 0; i < content.length; i += 1) {
    if (content[i].stack) {
      await migrateNotSupportedHTMLContentStyle(content[i].stack, imageRefs);
    } else if (
      content[i].tMarginLeft === "auto" &&
      content[i].tMarginRight === "auto"
    ) {
      if (content[i].nodeName === "TABLE") {
        traverseAndTransform(content[i]);

        const tmp = { ...content[i], width: "auto" };

        tmp.table.headerRows = 1;
        tmp.table.dontBreakRows = true;
        tmp.table.keepWithHeaderRows = 1;

        content[i] = {
          columns: [{ width: "*", text: "" }, tmp, { width: "*", text: "" }],
        };
      } else {
        content[i].alignment = "center";
      }
    }

    if (content[i].nodeName === "TABLE") {
      traverseAndTransform(content[i]);

      content[i].table.headerRows = 1;
      content[i].table.dontBreakRows = true;
      content[i].table.keepWithHeaderRows = 1;

      if (content[i].width === "100%" && !content[i].table.widths) {
        content[i].table.widths = content[i].table.body[0].map(
          (_: any, index: number) => (index === 0 ? "*" : "auto"),
        );
      }
    }

    if (content[i].width > PDF_PAGE_WIDTH_WITHOUT_MARGIN) {
      content[i].width = PDF_PAGE_WIDTH_WITHOUT_MARGIN;

      content[i].fit = [
        PDF_PAGE_WIDTH_WITHOUT_MARGIN,
        PDF_PAGE_HEIGHT_WITHOUT_MARGIN,
      ];
    } else if (content[i].height > PDF_PAGE_HEIGHT_WITHOUT_MARGIN) {
      content[i].height = PDF_PAGE_HEIGHT_WITHOUT_MARGIN;

      content[i].fit = [
        PDF_PAGE_WIDTH_WITHOUT_MARGIN,
        PDF_PAGE_HEIGHT_WITHOUT_MARGIN,
      ];
    }

    if (
      content[i].nodeName === "IMG" &&
      imageRefs[content[i].image] &&
      (!content[i].width || !content[i].height)
    ) {
      const dimension = await getImageDimension(imageRefs[content[i].image]);

      if (dimension) {
        if (
          !content[i].width &&
          dimension.width > PDF_PAGE_WIDTH_WITHOUT_MARGIN
        ) {
          content[i].width = PDF_PAGE_WIDTH_WITHOUT_MARGIN;

          content[i].fit = [
            PDF_PAGE_WIDTH_WITHOUT_MARGIN,
            PDF_PAGE_HEIGHT_WITHOUT_MARGIN,
          ];
        } else if (
          !content[i].height &&
          dimension.height > PDF_PAGE_HEIGHT_WITHOUT_MARGIN
        ) {
          content[i].height = PDF_PAGE_HEIGHT_WITHOUT_MARGIN;

          content[i].fit = [
            PDF_PAGE_WIDTH_WITHOUT_MARGIN,
            PDF_PAGE_HEIGHT_WITHOUT_MARGIN,
          ];
        }
      }
    }
  }

  return content;
};
