import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const deleteProgramDietMealItems = async (
  programId: string,
  dietId: string,
  mealId: string,
  version: string,
) => {
  return await fetchData(
    `/dietitian/program/${programId}/diet-creator/${dietId}/meals-items?mealId=${mealId}`,
    APIMethods.DELETE,
    undefined,
    undefined,
    { "Alloweat-Creator-Version": version },
  );
};
