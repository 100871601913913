import {
  ClientItem,
  ClientList,
  ClientListResponsive,
  ClientListWrapper,
  ClientListWrapperResponsive,
  StyledScales,
  StyledSubtitle,
  SubtitleDescr,
} from "./ClientProfileSection.styled";
import {
  DoubleArrows,
  HeartWithPlus,
  PercentageCircle,
  ProfilClientCalendar,
  ScalesWithArrow,
  ScalesWithPercent,
  VenusMars,
} from "@assets/icons";
import dayjs from "dayjs";
import { FetchClientResponse } from "@client";
import { useAppTranslation, useClientAdditionalFeatures } from "@hooks";
import { calculateLbm } from "@views/dietician/PatientEnergyBalance";
import { round } from "lodash";
import { useTheme } from "@mui/material";

interface ClientPropsListProps {
  client: FetchClientResponse;
  responsive: boolean;
}

export const ClientPropsList = ({
  client,
  responsive,
}: ClientPropsListProps) => {
  const { t } = useAppTranslation();
  const { getAdditionalFeatureName } = useClientAdditionalFeatures();
  const { palette } = useTheme();

  const { height, weight, targetWeight, bodyFat, sex } = client.profile;
  const age = dayjs(dayjs()).diff(client.profile.birthDate, "years");
  const clientLbm = round(calculateLbm(weight, bodyFat), 1);
  const additionalFeatureId = client.profile.additionalFeatures[0]?.id ?? 0;

  const ClientListComponent = responsive ? ClientListResponsive : ClientList;
  const ClientListWrapperComponent = responsive
    ? ClientListWrapperResponsive
    : ClientListWrapper;

  const gender =
    sex === "m" ? t("client_profile.man") : t("client_profile.woman");

  return (
    <ClientListWrapperComponent>
      <ClientListComponent>
        <ClientItem>
          <ProfilClientCalendar size="w-6 h-6" />
          <SubtitleDescr>
            <StyledSubtitle>{t("client_profile.age")}</StyledSubtitle>
            <p className="text-base">
              {age === 0 ? 0 : age || t("common.not_specified")}
            </p>
          </SubtitleDescr>
        </ClientItem>

        <ClientItem>
          <StyledScales size="w-5 h-5" fill={palette.primary.main} />
          <SubtitleDescr>
            <StyledSubtitle>{t("client_profile.body_weight")}</StyledSubtitle>
            <p className="text-base">
              {weight ? `${weight} kg` : t("common.not_specified")}
            </p>
          </SubtitleDescr>
        </ClientItem>

        <ClientItem>
          <ScalesWithPercent size="w-6 h-6" />
          <SubtitleDescr>
            <StyledSubtitle>
              {t("client_profile.lean_body_weight")}
            </StyledSubtitle>
            <p className="text-base">
              {clientLbm && clientLbm !== weight ? `${clientLbm} kg` : "-"}
            </p>
          </SubtitleDescr>
        </ClientItem>

        <ClientItem>
          <HeartWithPlus size="w-6 h-6" />
          <SubtitleDescr>
            <StyledSubtitle>
              {t("client_profile.additional_features")}
            </StyledSubtitle>
            <p className="text-base">
              {getAdditionalFeatureName(additionalFeatureId) ||
                t("common.not_specified")}
            </p>
          </SubtitleDescr>
        </ClientItem>
      </ClientListComponent>

      <ClientListComponent>
        <ClientItem>
          <DoubleArrows size="w-6 h-6" />
          <SubtitleDescr>
            <StyledSubtitle>{t("client_profile.growth")}</StyledSubtitle>
            <p className="text-base">
              {height ? `${height} cm` : t("common.not_specified")}
            </p>
          </SubtitleDescr>
        </ClientItem>

        <ClientItem>
          <ScalesWithArrow size="w-6 h-6" />
          <SubtitleDescr>
            <StyledSubtitle>
              {t("client_profile.target_body_weight")}
            </StyledSubtitle>
            <p className="text-base">
              {targetWeight ? `${targetWeight} kg` : t("common.not_specified")}
            </p>
          </SubtitleDescr>
        </ClientItem>

        <ClientItem>
          <PercentageCircle size="w-6 h-6" />
          <SubtitleDescr>
            <StyledSubtitle>{t("client_profile.fat_tissue")}</StyledSubtitle>
            <p className="text-base">
              {bodyFat ? `${bodyFat} %` : t("common.not_specified")}
            </p>
          </SubtitleDescr>
        </ClientItem>

        <ClientItem>
          <VenusMars size="w-6 h-6" />
          <SubtitleDescr>
            <StyledSubtitle>{t("client_profile.sex")}</StyledSubtitle>
            <p className="text-base">
              {sex === null ? t("common.not_specified") : gender}
            </p>
          </SubtitleDescr>
        </ClientItem>
      </ClientListComponent>
    </ClientListWrapperComponent>
  );
};
