import { random } from "lodash";
import { ReactNode } from "react";

export const MOCK_PATIENT_GOALS = [
  "Nabranie masy ciała",
  "Utrata masy ciała",
  "Zwiększenie tkanki tłuszczowej",
  "Zmniejszenie thanki tłuszczowej",
  "Poprawa kondycji",
];

export const PRELIMINARY_BODY_CONTENT_ANALYSIS_FIELDS: Array<MeasurementField> =
  [
    {
      key: "weight",
      label: "Masa ciała",
      labelEn: "Body mass",
      suffix: "kg",
      isTooltip: false,
      tooltipContent: null,
      tooltipContentEn: null,
    },
    {
      key: "bodyFatContent",
      label: "Zawartość tkanki tłuszczowej",
      labelEn: "Body fat content",
      suffix: "%",
      isTooltip: false,
      tooltipContent: null,
      tooltipContentEn: null,
    },
    {
      key: "fatContent",
      label: "Tkanka tłuszczowa",
      labelEn: "Fat content",
      suffix: "kg",
      isTooltip: false,
      tooltipContent: null,
      tooltipContentEn: null,
    },
    {
      key: "muscleMass",
      label: "Masa mięśniowa",
      labelEn: "Muscle mass",
      suffix: "kg",
      isTooltip: false,
      tooltipContent: null,
      tooltipContentEn: null,
    },
    {
      key: "fmmMass",
      label: "Masa bezłuszczowa FMM",
      labelEn: "FMM fat-freee mass",
      suffix: "kg",
      isTooltip: true,
      tooltipContent: (
        <p>
          FFM – beztłuszczowa masa ciała (fat-free mass) najważniejsza
          metabolicznie składowa organizmu, odpowiada głównie masie mięśniowej,
          narządom wewnętrznym i kośćcowi; powinniśmy szczególnie i nią dbać,
          ponieważ najłatwiej jest ją stracić (zarówno w chorobie, jak i podczas
          nierozważnego odchudzania), a najtrudniej odbudować
        </p>
      ),
      tooltipContentEn: (
        <p>
          FFM - fat-free mass, the most important metabolically component of the
          body, mainly corresponds to muscle mass, internal organs and skeleton;
          we should especially take care of it, because it is easiest to lose
          (both in illness and during reckless weight loss), and the most
          difficult to rebuild
        </p>
      ),
    },
    {
      key: "visceralFat",
      label: "Tłuszcz wisceralny",
      labelEn: "Visceral fat",
      suffix: null,
      isTooltip: false,
      tooltipContent: null,
      tooltipContentEn: null,
    },
    {
      key: "water",
      label: "Woda",
      labelEn: "Water",
      suffix: null,
      isTooltip: false,
      tooltipContent: null,
      tooltipContentEn: null,
    },
  ];

export const TARGET_BODY_CONTENT_ANALYSIS_FIELDS: Array<MeasurementField> = [
  {
    key: "properBodyWeight",
    label: "Należna masa ciała",
    labelEn: "Proper body weight",
    suffix: "kg",
    isTooltip: false,
    tooltipContent: null,
    tooltipContentEn: null,
  },
  {
    key: "idealBodyWeight",
    label: "Idealna masa ciała",
    labelEn: "Ideal body weight",
    suffix: "kg",
    isTooltip: false,
    tooltipContent: null,
    tooltipContentEn: null,
  },
  {
    key: "weightToReachGoal",
    label: "Do ospełnienia celu",
    labelEn: "To reach goal",
    suffix: "kg",
    isTooltip: false,
    tooltipContent: null,
    tooltipContentEn: null,
  },
  {
    key: "bodyFatContent2",
    label: "Zawartość tkanki tłuszczowej",
    labelEn: "Body fat content",
    suffix: "%",
    isTooltip: false,
    tooltipContent: null,
    tooltipContentEn: null,
  },
  {
    key: "targetFatContent",
    label: "Docelowa zawartość tkanki tł.",
    labelEn: "Target body fat content",
    suffix: "%",
    isTooltip: false,
    tooltipContent: null,
    tooltipContentEn: null,
  },
  {
    key: "fatContentExcess",
    label: "Nadmiar tkanki tłuszczowej",
    labelEn: "Fat content excess",
    suffix: null,
    isTooltip: false,
    tooltipContent: null,
    tooltipContentEn: null,
  },
  {
    key: "whr",
    label: "WHR",
    labelEn: "WHR",
    suffix: null,
    isTooltip: true,
    tooltipContent: (
      <p>
        Kalkulator WHR ( ang. Waist-Hip Ratio ) - Stosunek Talia-Biodra jest
        kalkulatorem pozwalającym na dokładne określenie typu sylwetki oraz ew.
        typu nadwagi (brzuszna bądź udowa). Dzięki niemu można dokładniej
        dopasować dietę do indywidualnych potrzeb.
      </p>
    ),
    tooltipContentEn: (
      <p>
        The WHR (Waist-Hip Ratio) calculator - the Waist-Hip Ratio ratio is a
        calculator that allows you to accurately determine the type of figure
        and possibly the type of overweight (abdominal or thigh). Thanks to it,
        you can more accurately adjust the diet to individual needs.
      </p>
    ),
  },
  {
    key: "bmi",
    label: "BMI",
    labelEn: "BMI",
    suffix: null,
    isTooltip: true,
    tooltipContent: (
      <p>
        Kalkulator BMI jest narzędziem, które mówi, czy ważymy odpowiednio do
        swojego wzrostu. Jest bardzo prosty w obsłudze, a normy pozwalają na
        jednoznaczną interpretację wyników. W czasach, gdy nadmierna troska o
        sylwetkę staje się problemem społecznym, kalkulator BMI jest wiarygodnym
        wyznacznikiem prawidłowej wagi.
      </p>
    ),
    tooltipContentEn: (
      <p>
        The BMI calculator is a tool that tells you if you weigh according to
        your height. It is very easy to use and the standards allow for an
        unambiguous interpretation of the results. At a time when excessive
        concern for the figure becomes a social problem, the BMI calculator is a
        reliable indicator of the correct weight.
      </p>
    ),
  },
];

export const TARGET_BODY_WEIGHT_MOCK_VALUES = ["Wzór", "Inny", "Inny 2"];

export const WAISTLINE_FIELDS: Array<MeasurementField> = [
  {
    key: "arm",
    label: "Ramię",
    labelEn: "Arm",
    suffix: "cm",
    isTooltip: false,
    tooltipContent: null,
    tooltipContentEn: null,
  },
  {
    key: "armWithTighBiceps",
    label: "Ramię (napięty biceps)",
    labelEn: "Arm (tight biceps)",
    suffix: "cm",
    isTooltip: false,
    tooltipContent: null,
    tooltipContentEn: null,
  },
  {
    key: "waistMin",
    label: "Talia (min)",
    labelEn: "Waist (min)",
    suffix: "cm",
    isTooltip: false,
    tooltipContent: null,
    tooltipContentEn: null,
  },
  {
    key: "hipsMax",
    label: "Biodra (max)",
    labelEn: "Hips (max)",
    suffix: "cm",
    isTooltip: false,
    tooltipContent: null,
    tooltipContentEn: null,
  },
  {
    key: "thighMiddle",
    label: "Udo (środek)",
    labelEn: "Thigh (middle)",
    suffix: "cm",
    isTooltip: false,
    tooltipContent: null,
    tooltipContentEn: null,
  },
  {
    key: "calfMax",
    label: "Łydka (max)",
    labelEn: "Calf (max)",
    suffix: "cm",
    isTooltip: false,
    tooltipContent: null,
    tooltipContentEn: null,
  },
];

export const BONES_FIELDS: Array<MeasurementField> = [
  {
    key: "humerus",
    label: "Kość ramienna",
    labelEn: "Humerus",
    suffix: "cm",
    isTooltip: false,
    tooltipContent: null,
    tooltipContentEn: null,
  },
  {
    key: "wrist",
    label: "Nadgarstek",
    labelEn: "Wrist",
    suffix: "cm",
    isTooltip: false,
    tooltipContent: null,
    tooltipContentEn: null,
  },
  {
    key: "femur",
    label: "Kość udowa",
    labelEn: "Femur",
    suffix: "cm",
    isTooltip: false,
    tooltipContent: null,
    tooltipContentEn: null,
  },
];

export const SOMATOTYPES_LIST = [
  {
    key: "ECTOMORPH",
    namePl: "Ektomorfik",
    nameEn: "Ectomorph",
  },
  {
    key: "MESOMORPH",
    namePl: "Mezomorfik",
    nameEn: "Mesomorph",
  },
  {
    key: "ENDOMORPH",
    namePl: "Endomorfik",
    nameEn: "Endomorph",
  },
];

export const CALIPER_FORMS_LIST = [
  {
    key: "FORMULA1",
    namePl: "Formuła 1",
    nameEn: "Formula 1",
  },
  {
    key: "FORMULA2",
    namePl: "Formuła 2",
    nameEn: "Formula 2",
  },
  {
    key: "FORMULA3",
    namePl: "Formuła 3",
    nameEn: "Formula 3",
  },
];

export const CALIPER_FIELDS: Array<MeasurementField> = [
  {
    key: "triceps",
    label: "Triceps",
    labelEn: "Triceps",
    suffix: "mm",
    isTooltip: false,
    tooltipContent: null,
    tooltipContentEn: null,
  },
  {
    key: "scapular",
    label: "Podłopłatkowy",
    labelEn: "Scapular",
    suffix: "mm",
    isTooltip: false,
    tooltipContent: null,
    tooltipContentEn: null,
  },
  {
    key: "biceps",
    label: "Biceps",
    labelEn: "Biceps",
    suffix: "mm",
    isTooltip: false,
    tooltipContent: null,
    tooltipContentEn: null,
  },
  {
    key: "hipCrest",
    label: "Grzebień biodrowy",
    labelEn: "Hip crest",
    suffix: "mm",
    isTooltip: false,
    tooltipContent: null,
    tooltipContentEn: null,
  },
  {
    key: "supraspinal",
    label: "Nadkolcowy",
    labelEn: "Supraspinal",
    suffix: "mm",
    isTooltip: false,
    tooltipContent: null,
    tooltipContentEn: null,
  },
  {
    key: "abdomen",
    label: "Brzuch",
    labelEn: "Abdomen",
    suffix: "mm",
    isTooltip: false,
    tooltipContent: null,
    tooltipContentEn: null,
  },
  {
    key: "thigh",
    label: "Udo",
    labelEn: "Thigh",
    suffix: "mm",
    isTooltip: false,
    tooltipContent: null,
    tooltipContentEn: null,
  },
  {
    key: "calf",
    label: "Łydka",
    labelEn: "Calf",
    suffix: "mm",
    isTooltip: false,
    tooltipContent: null,
    tooltipContentEn: null,
  },
];

export const CALIPER_SUMMARY_FIELDS: Array<MeasurementField> = [
  {
    key: "sixFoldsSum",
    label: "Suma sześciu fałdów",
    labelEn: "Six folds sum",
    suffix: "mm",
    isTooltip: false,
    tooltipContent: null,
    tooltipContentEn: null,
  },
  {
    key: "eightFoldsSum",
    label: "Suma ośmiu fałdów",
    labelEn: "Eight folds sum",
    suffix: "mm",
    isTooltip: false,
    tooltipContent: null,
    tooltipContentEn: null,
  },
  {
    key: "fatContent",
    label: "Zawartość tkanki tłuszczowej",
    labelEn: "Fat content",
    suffix: "%",
    isTooltip: false,
    tooltipContent: null,
    tooltipContentEn: null,
  },
  {
    key: "fatContentMass",
    label: "Masa tkanki tłuszczowej",
    labelEn: "Fat content mass",
    suffix: "kg",
    isTooltip: false,
    tooltipContent: null,
    tooltipContentEn: null,
  },
  {
    key: "muscleMassContent",
    label: "Zawartość masy mięśniowej",
    labelEn: "Muscle mass content",
    suffix: "%",
    isTooltip: false,
    tooltipContent: null,
    tooltipContentEn: null,
  },
  {
    key: "muscleMass",
    label: "Masa mięśniowa",
    labelEn: "Muscle mass",
    suffix: "kg",
    isTooltip: false,
    tooltipContent: null,
    tooltipContentEn: null,
  },
];

export const CHART_PARAMS = [
  "Masa ciała",
  "Zawartość tkanki tłuszczowej",
  "Masa mięśniowa",
];

export const VISITS_MOCK_HISTORY = [
  {
    name: "visits",
    value: random(40, 60),
    visit: {
      name: "Wizyta 1",
      date: "21.06.2022",
      isFirst: true,
    },
  },
  {
    name: "visits",
    value: random(40, 60),
    visit: {
      name: "Wizyta 2",
      date: "22.06.2022",
    },
  },
  {
    name: "visits",
    value: random(40, 60),
    visit: {
      name: "Wizyta 3",
      date: "23.06.2022",
    },
  },
  {
    name: "visits",
    value: random(40, 60),
    visit: {
      name: "Wizyta 4",
      date: "24.06.2022",
    },
  },
  {
    name: "visits",
    value: random(40, 60),
    visit: {
      name: "Wizyta 5",
      date: "25.06.2022",
    },
  },
  {
    name: "visits",
    value: random(40, 60),
    visit: {
      name: "Wizyta 6",
      date: "26.06.2022",
      isLast: true,
    },
  },
];

interface MeasurementField {
  key: string;
  label: string;
  labelEn: string;
  suffix: string | null;
  isTooltip: boolean;
  tooltipContent: string | ReactNode | null;
  tooltipContentEn: string | ReactNode | null;
}
