import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const ArrowPrev = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 8.942 15.828">
    <g
      id="previous_arrow_-_left"
      data-name="previous arrow - left"
      transform="translate(1 14.414) rotate(-90)"
    >
      <path
        id="Stroke_3"
        data-name="Stroke 3"
        d="M13,6.527,6.5,0,0,6.527"
        transform="translate(0 0)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </SvgIcon>
);
