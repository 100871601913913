import { PlusRounded } from "@assets/icons/PlusRounded";
import image from "@assets/img/clientsEmptyState.png";
import twistedArrow from "@assets/img/twistedArrowUpwards.png";
import { useAppTranslation } from "@hooks";
import { Button } from "@mui/material";
import { ThemeProviderWrapperNew } from "themeNew";
import {
  ClientsEmptyStateStyled,
  ContentWrapper,
  ButtonWithCtaWrapper,
  CtaStyled,
  CtaText,
} from "./ClientsEmptyState.styled";
import { EmptyStateCard, StyledSubTitle, StyledTitle } from "../common.styled";

interface ClientsEmptyStateProps {
  onClick: () => void;
}
export const ClientsEmptyState = ({ onClick }: ClientsEmptyStateProps) => {
  const { t } = useAppTranslation();

  return (
    <ThemeProviderWrapperNew>
      <EmptyStateCard>
        <ClientsEmptyStateStyled>
          <ContentWrapper>
            <img src={image} alt={t("empty_states.alt.no_clients")} />
            <StyledTitle>{t("empty_states.clients")}</StyledTitle>
            <StyledSubTitle maxWidth="40rem">
              {t("empty_states.clients_info")}
            </StyledSubTitle>
          </ContentWrapper>

          <ButtonWithCtaWrapper>
            <Button
              startIcon={<PlusRounded size="w-3 h-3" />}
              variant="contained"
              onClick={onClick}
            >
              {t("empty_states.add_client")}
            </Button>

            <CtaStyled>
              <img width="116px" src={twistedArrow} className="self-end" />

              <CtaText>{t("empty_states.first_client")}</CtaText>
            </CtaStyled>
          </ButtonWithCtaWrapper>
        </ClientsEmptyStateStyled>
      </EmptyStateCard>
    </ThemeProviderWrapperNew>
  );
};
