import { array, number, object, string } from "yup";

import type {
  IdDto,
  ImageDto,
  MeasureDto,
  NutrientDto,
  TranslationDto,
} from "@client/common";
import {
  idSchema,
  imageSchema,
  measureSchema,
  nutrientSchema,
  translationSchema,
} from "@client/common";
import type { TagResourceDto } from "@client/diets";
import { tagResourceSchema } from "@client/diets";
import { ApiEndpoints, APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const fetchCollectionSearchPreviewProduct = async (
  collectionId: string,
  itemId: string,
): Promise<ApiResponse<CollectionProductSearchPreviewDto>> => {
  const response = await fetchData(
    `${ApiEndpoints.CollectionsSearchPreview}/${collectionId}/product-items/${itemId}`,
    APIMethods.GET,
  );

  return reponseSchema.validate(response);
};

interface NestedProductSearchPreviewDto {
  id: number;
  name: string;
  producer: string | null;
  category: IdDto;
  translations: TranslationDto[];
  nutrients: NutrientDto[];
  tags: TagResourceDto[];
  measures: MeasureDto[];
  image: ImageDto;
}
export interface CollectionProductSearchPreviewDto {
  id: number;
  grams: number;
  measure: IdDto;
  product: NestedProductSearchPreviewDto;
}

const nestedProductSearchPreviewSchema = object({
  id: number().required(),
  name: string().required(),
  producer: string().nullable().default(null),
  category: idSchema.required(),
  translations: array().of(translationSchema).required(),
  nutrients: array().of(nutrientSchema).required(),
  tags: array().of(tagResourceSchema).required(),
  measures: array().of(measureSchema).required(),
  image: imageSchema.required(),
});

const collectionProductSearchPreviewSchema = object({
  id: number().required(),
  grams: number().required(),
  measure: idSchema.required(),
  product: nestedProductSearchPreviewSchema.required(),
});

const reponseSchema = object({
  data: collectionProductSearchPreviewSchema.required(),
});
