import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Handset = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      style={{ fill: "none" }}
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        d="M19.2237 16.0387C19.2237 16.3537 19.1537 16.6775 19.005 16.9925C18.8562 17.3075 18.6638 17.605 18.41 17.885C17.9813 18.3575 17.5087 18.6988 16.975 18.9175C16.45 19.1363 15.8813 19.25 15.2688 19.25C14.3763 19.25 13.4225 19.04 12.4162 18.6113C11.41 18.1825 10.4037 17.605 9.40625 16.8788C8.38959 16.1351 7.43023 15.3161 6.53625 14.4287C5.65142 13.538 4.83534 12.5815 4.095 11.5675C3.3775 10.57 2.8 9.5725 2.38 8.58375C1.96 7.58625 1.75 6.6325 1.75 5.7225C1.75 5.1275 1.855 4.55875 2.065 4.03375C2.275 3.5 2.6075 3.01 3.07125 2.5725C3.63125 2.02125 4.24375 1.75 4.89125 1.75C5.13625 1.75 5.38125 1.8025 5.6 1.9075C5.8275 2.0125 6.02875 2.17 6.18625 2.3975L8.21625 5.25875C8.37375 5.4775 8.4875 5.67875 8.56625 5.87125C8.645 6.055 8.68875 6.23875 8.68875 6.405C8.68875 6.615 8.6275 6.825 8.505 7.02625C8.39125 7.2275 8.225 7.4375 8.015 7.6475L7.35 8.33875C7.25375 8.435 7.21 8.54875 7.21 8.68875C7.21 8.75875 7.21875 8.82 7.23625 8.89C7.2625 8.96 7.28875 9.0125 7.30625 9.065C7.46375 9.35375 7.735 9.73 8.12 10.185C8.51375 10.64 8.93375 11.1038 9.38875 11.5675C9.86125 12.0313 10.3162 12.46 10.78 12.8537C11.235 13.2387 11.6112 13.5012 11.9087 13.6587C11.9525 13.6762 12.005 13.7025 12.0662 13.7287C12.1362 13.755 12.2062 13.7637 12.285 13.7637C12.4337 13.7637 12.5475 13.7113 12.6437 13.615L13.3088 12.9588C13.5275 12.74 13.7375 12.5737 13.9388 12.4688C14.14 12.3462 14.3412 12.285 14.56 12.285C14.7262 12.285 14.9013 12.32 15.0938 12.3988C15.2862 12.4775 15.4875 12.5913 15.7063 12.74L18.6025 14.7962C18.83 14.9537 18.9875 15.1375 19.0837 15.3562C19.1712 15.575 19.2237 15.7937 19.2237 16.0387V16.0387Z"
        stroke="#1E1E1E"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </SvgIcon>
  );
};
