import { Link } from "react-router-dom";

import { IconButton } from "@mui/material";

import {
  Clock,
  Cutlery,
  DuplicateFiles,
  Pen,
  PieChart,
  TrashNew,
} from "@assets/icons";
import { ConfirmationModal } from "@components/ConfirmationModal";
import { ExpandableTags } from "@components/ExpandableChips";
import { FavoriteToggleButton } from "@components/FavoriteToggleButton";
import { RecipeRowIf } from "@components/RecipesDataGrid/RecipesDataGrid";
import { TagWithIcon, useTagLabel } from "@components/TagWithIcon";
import { useAppTranslation, useModal } from "@hooks";
import {
  useDeleteRecipeMutation,
  usePostToggleFavoriteRecipeMutation,
} from "@hooks/queries";

import { ImagePlaceholder } from "./ImagePlaceholder";
import {
  ActionsWrapper,
  ButtonsWrapper,
  CardStyled,
  ContentWrapper,
  FavoriteToggleButtonStyled,
  ImgStyled,
  TitleStyled,
} from "./RecipeTile.styled";
import { MacrosText } from "@components/MacrosText";

interface RecipeTileProps extends RecipeRowIf {
  onSelect: () => void;
}

export const RecipeTile = ({
  namePl,
  actions,
  id,
  isFavorite,
  nameEn,
  nutrients,
  servings,
  tags,
  imgUrl,
  onSelect,
}: RecipeTileProps) => {
  const { t, isPolishChosen } = useAppTranslation();
  const { mutate, isLoading: isLoadingToggle } =
    usePostToggleFavoriteRecipeMutation();
  const { mutate: deleteRecipe, isLoading: isDeleting } =
    useDeleteRecipeMutation();
  const { modalOpened, onModalOpen, onModalClose } = useModal();
  const handleDelete = () => deleteRecipe(id);
  const { difficultyOfPreparationTagLabel, preparationTimeTagLabel } =
    useTagLabel(tags.map(t => Number(t)));

  const handleToggle = () => mutate(id?.toString() ?? "");

  return (
    <>
      <CardStyled onClick={onSelect}>
        <ButtonsWrapper className="buttonsWrapper">
          <FavoriteToggleButtonStyled
            onChange={handleToggle}
            isLoading={isLoadingToggle}
            isFavorite={isFavorite}
            favorite={isFavorite}
            className="favoriteButton"
          />

          <ActionsWrapper className="buttonsWrapper">
            {actions.canEdit && (
              <Link to={`/recipes/${id}/edit`}>
                <IconButton>
                  <Pen />
                </IconButton>
              </Link>
            )}
            <Link to={`/recipes/${id}/copy`}>
              <IconButton>
                <DuplicateFiles />
              </IconButton>
            </Link>
            {actions.canDelete && (
              <IconButton
                onClick={e => {
                  e.stopPropagation();
                  onModalOpen();
                }}
                disabled={isDeleting}
              >
                <TrashNew />
              </IconButton>
            )}
          </ActionsWrapper>
        </ButtonsWrapper>

        <div className="h-[260px] w-full">
          {imgUrl && (
            <ImgStyled
              loading="lazy"
              src={imgUrl}
              alt={isPolishChosen ? namePl : nameEn}
            />
          )}
          {!imgUrl && <ImagePlaceholder />}
        </div>

        <ContentWrapper>
          <ExpandableTags tags={tags.map(t => Number(t))} />

          <TitleStyled>{isPolishChosen ? namePl : nameEn}</TitleStyled>

          <div className="grid gap-[6px]">
            <MacrosText nutrients={nutrients} />

            <div className="flex gap-[16px]">
              {preparationTimeTagLabel && (
                <TagWithIcon Icon={Clock} label={preparationTimeTagLabel} />
              )}
              {difficultyOfPreparationTagLabel && (
                <TagWithIcon
                  Icon={Cutlery}
                  label={difficultyOfPreparationTagLabel}
                />
              )}
              {servings > 1 && (
                <TagWithIcon
                  Icon={PieChart}
                  label={t("recipe.recipe_complex", { count: servings })}
                />
              )}
            </div>
          </div>
        </ContentWrapper>
      </CardStyled>
      <ConfirmationModal
        confirmText={t("common.delete")}
        description={t("recipes.delete_modal.question", {
          variable: isPolishChosen ? namePl : nameEn,
        })}
        onCancel={onModalClose}
        open={modalOpened}
        onConfirm={handleDelete}
        title={t("recipes.delete_modal.delete")}
      />
    </>
  );
};
