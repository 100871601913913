import { createContext, useContext } from "react";

interface AutosaveOnBlurContextIf {
  onBlur: () => void;
  onFocus: () => void;
  isBlurred: boolean;
}

export const AutosaveOnBlurContext = createContext<
  AutosaveOnBlurContextIf | undefined
>(undefined);

export const useAutosaveOnBlurContext = () => {
  const context = useContext(AutosaveOnBlurContext);

  if (!context) {
    throw new Error(
      "useAutosaveOnBlurContext must be used within a AutosaveOnBlurContext",
    );
  }

  return context;
};
