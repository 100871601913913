import { useSearchParams } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { useWatch } from "react-hook-form";
import {
  ProgramFiltersFormKeys,
  ProgramFiltersFormArrayNumberKeys,
  ProgramFiltersFormNumberKeys,
  ProgramFiltersFormPops,
  ProgramsFiltersFormDefaultValues,
  ProgramFiltersFormStringKeys,
  ProgramFiltersFormArrayNutrientsKeys,
} from "@views/dietician/Programs/ProgramsFiltersFormType";
import isNumber from "lodash/isNumber";

const useSetProgramsFiltersSearchParams = () => {
  const values = useWatch<ProgramFiltersFormPops>();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // if (!values?.page || !values?.perPage) {
    //   return;
    // }
    setSearchParams(
      mapRequestParamsToProgramsSearchQueryParams(
        values as ProgramFiltersFormPops,
      ),
    );
  }, [values]);
};

const mapRequestParamsToProgramsSearchQueryParams = (
  values: ProgramFiltersFormPops,
) => {
  let search = {};

  function isEqualValues(key: keyof ProgramFiltersFormPops) {
    if (!ProgramsFiltersFormDefaultValues[key]) {
      return false;
    }

    if (!values[key]) {
      return false;
    }

    return ProgramsFiltersFormDefaultValues[key] === values[key];
  }

  for (const key of ProgramFiltersFormKeys) {
    const value = values[key];
    if (!value || isEqualValues(key)) {
      continue;
    }

    if (key === "page" && isNumber(value)) {
      search = Object.assign(search, { [key]: value + 1 });
      continue;
    }

    if (ProgramFiltersFormArrayNumberKeys.includes(key)) {
      if (Array.isArray(value) && value.length) {
        search = Object.assign(search, { [key]: value.join(",") });
      }
      continue;
    }

    // if (ProgramFiltersFormArrayNutrientsKeys.includes(key)) {
    //     if (Array.isArray(value) && value.length) {
    //         search = Object.assign(search, { [key]: value.join(",") });
    //     }
    //     continue;
    // }

    if (ProgramFiltersFormNumberKeys.includes(key)) {
      search = Object.assign(search, { [key]: value.toString() });
      continue;
    }

    if (ProgramFiltersFormStringKeys.includes(key)) {
      search = Object.assign(search, { [key]: value });
      continue;
    }
  }

  return search;
};

export default useSetProgramsFiltersSearchParams;
