import { CircularProgress } from "@mui/material";

import { ProductTabPanels } from "@components/PreviewDrawer/Product/ProductTabPanels";
import { TabSwitch } from "@components/TabSwitch";
import { useAppTranslation } from "@hooks";
import { useFetchCollectionSearchPreviewProductQuery } from "@hooks/queries/collections";
import { TabContext } from "@mui/lab";
import { scaleNutrients } from "@utils";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { MeasureSelect } from "./MeasureSelect";

interface ProductCollectionContentProps {
  id: number;
  grams: number;
  measure: string;
  setGrams: Dispatch<SetStateAction<number>>;
  setMeasure: Dispatch<SetStateAction<string>>;
  setIsTouched: Dispatch<SetStateAction<boolean>>;
  collectionId: number;
  itemId: number;
}
export const ProductCollectionContent = ({
  id,
  grams,
  measure,
  setGrams,
  setMeasure,
  setIsTouched,
  collectionId,
  itemId,
}: ProductCollectionContentProps) => {
  const { t } = useAppTranslation();
  const { data: product, isLoading } =
    useFetchCollectionSearchPreviewProductQuery(
      collectionId.toString(),
      itemId.toString(),
    );
  const [tab, setTab] = useState(Tabs.NUTRITIONAL_VALUES);

  const tabs = useMemo(
    () => [
      {
        label: t("common.nutrients_values"),
        id: Tabs.NUTRITIONAL_VALUES,
      },
      {
        label: t("common.tags"),
        id: Tabs.TAGS,
      },
    ],
    [t],
  );

  const handleGramsChange = (value: number) => {
    setGrams(value);
    setIsTouched(true);
  };

  const handleMeasureChange = (id: string) => {
    setMeasure(id);
    setIsTouched(true);
  };

  if (isLoading) return <CircularProgress />;
  if (!product) return null;

  const { nutrients, measures, tags } = product.product;
  return (
    <>
      <div className="grid gap-[12px]">
        <div className="pl-[8px]">
          <MeasureSelect
            id={id}
            grams={grams}
            onGramsChange={handleGramsChange}
            measure={measure}
            onMeasureChange={handleMeasureChange}
          />
        </div>
        <div className="pl-[8px]">
          <TabSwitch tabs={tabs} value={tab} onChange={setTab} />
        </div>
      </div>
      <TabContext value={tab}>
        <ProductTabPanels
          nutrients={scaleNutrients(nutrients, grams)}
          measures={measures}
          tags={tags.map(t => t.id)}
        />
      </TabContext>
    </>
  );
};

enum Tabs {
  NUTRITIONAL_VALUES = "0",
  TAGS = "1",
}
