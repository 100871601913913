import { ReactNode } from "react";

import { IconButton } from "@mui/material";

import { ArrowLeft } from "@assets/icons/Arrows";

import {
  PageNavigationHeaderWrapper,
  TitleStyled,
} from "./PageNavigationHeader.styled";

interface PageNavigationHeaderProps {
  onClick: () => void;
  title: string;
  children: ReactNode;
}
export const PageNavigationHeader = ({
  onClick,
  title,
  children,
}: PageNavigationHeaderProps) => {
  return (
    <PageNavigationHeaderWrapper>
      <div className="flex items-center">
        <IconButton onClick={onClick} color="primary">
          <ArrowLeft />
        </IconButton>
        <TitleStyled>{title}</TitleStyled>
      </div>

      {children}
    </PageNavigationHeaderWrapper>
  );
};
