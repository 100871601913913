import DialogTitle from "@components/Alloweat/Dialog/DialogTitle";
import { Button, DialogContent, Stack } from "@mui/material";
import { IconButton } from "@components/Alloweat/IconButton";
import { CloseIcon } from "@icons/index";
import DialogActions from "@components/Alloweat/Dialog/DialogActions";
import { LoadingButton } from "@mui/lab";
import Dialog from "@components/Alloweat/Dialog/Dialog";
import { useAppTranslation } from "@hooks";
import { ReactElement } from "react";

type DeleteModalProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  content: string | ReactElement | ReactElement[];
  isLoading: boolean;
  confirmOnClick: () => void;
};

const DeleteModal = ({
  open,
  onClose,
  title,
  content,
  isLoading,
  confirmOnClick,
}: DeleteModalProps) => {
  const { t } = useAppTranslation();

  return (
    <Dialog open={open} fullWidth PaperProps={{ sx: { maxWidth: "510px" } }}>
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <span>{title}</span>
          <IconButton disabled={isLoading} onClick={onClose}>
            <CloseIcon color="727272" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button disabled={isLoading} variant="outlined" onClick={onClose}>
          {t("common.cancel")}
        </Button>
        <LoadingButton
          color="error"
          onClick={confirmOnClick}
          loading={isLoading}
          variant="contained"
        >
          {t("common.remove")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
