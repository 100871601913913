import { useMemo, useState } from "react";

import { PieChart } from "@assets/icons";
import ArrowIcon from "@assets/icons_new/chevron.svg?react";
import { TagWithIcon } from "@components/TagWithIcon";
import {
  useAppTranslation,
  useBasicNutrientsGetter,
  useModalState,
} from "@hooks";
import { IconButton, styled } from "@mui/material";
import { Nutrient } from "@typeDefinitions";

import { ThemeProviderWrapperNew } from "themeNew";
import { PCFValue } from "../../../../../PCFValue";
import { SimplifiedRecipeOrProductData } from "../../RecipeChangeSidePanel";
import { NutrientWrapper } from "../NutrientWrapper.styled";
import { RecipeProductRowWrapper } from "../RecipeProductRowWrapper.styled";
import { RecipeUsesWrapper } from "../RecipeUsesWrapper.styled";
import { RecipeServingsEditor } from "./components";
import { RecipeCollapse } from "./RecipeCollapse";
import { useRecipeEncounter } from "./useRecipeEncounter";

export const RecipeModalEntry = ({
  onSelect,
  recipe,
  optionsInModal,
  uses,
  totalServings,
  usedServings,
  defaultSelectedServings,
}: RecipeModalEntryProps) => {
  const { t, isPolishChosen } = useAppTranslation();
  const [open, onOpen, onClose] = useModalState();
  const { id, name: namePl, nameEn, nutrients, isComplex } = recipe;
  const { encounters } = useRecipeEncounter(recipe.name);
  const [servings, setServings] = useState(
    defaultSelectedServings || singleServing,
  );

  const name = useMemo(
    () => (isPolishChosen ? namePl : nameEn ?? namePl),
    [isPolishChosen],
  );

  const modifier = useMemo(
    () => servings / (totalServings || singleServing),
    [servings, totalServings],
  );

  const handleSelect = () => {
    onSelect({ id, name, servings });
  };

  const { kcal, protein, fat, carbs } = useBasicNutrientsGetter(
    nutrients,
    modifier,
  );

  const handleToggleCollapse = open ? onClose : onOpen;

  return (
    <>
      <RecipeProductRowWrapper>
        <div className="w-full">
          <NameWrapper onClick={handleSelect}>
            <NameStyled>
              {name || t("patient.schedule.unnamed_meal")}
            </NameStyled>
            {!!uses && uses > 0 && (
              <RecipeUsesWrapper>
                {t("common.used")}&nbsp;{uses}
              </RecipeUsesWrapper>
            )}
          </NameWrapper>
          <div className="flex text-sm">
            <NutrientWrapper>
              <PCFValue value={kcal} name="kcal" />
            </NutrientWrapper>
            <NutrientWrapper>
              <PCFValue value={protein * modifier} name="protein" />
            </NutrientWrapper>
            <NutrientWrapper>
              <PCFValue value={fat * modifier} name="fats" />
            </NutrientWrapper>
            <NutrientWrapper>
              <PCFValue value={carbs * modifier} name="carbs" />
            </NutrientWrapper>
            <ThemeProviderWrapperNew>
              {totalServings > 1 && usedServings && (
                <TagWithIcon
                  label={`Wykorzystano porcje: ${usedServings}/${totalServings}`}
                  Icon={PieChart}
                />
              )}
            </ThemeProviderWrapperNew>
          </div>
        </div>
        {isComplex && !optionsInModal && (
          <RecipeServingsEditor
            servings={servings}
            totalServings={totalServings}
            setServings={setServings}
          />
        )}
        <IconButtonStyled onClick={handleToggleCollapse}>
          <ArrowIcon transform={open ? "rotate(90)" : "rotate(270)"} />
        </IconButtonStyled>
      </RecipeProductRowWrapper>
      <ThemeProviderWrapperNew>
        <RecipeCollapse
          open={open}
          totalServings={totalServings}
          meals={encounters}
        />
      </ThemeProviderWrapperNew>
    </>
  );
};

const singleServing = 1;

export interface RecipeModalEntryProps {
  onSelect: (recipe: SimplifiedRecipeOrProductData) => void;
  optionsInModal?: boolean;
  uses?: number;
  recipe: {
    id: number;
    name: string;
    nameEn?: string;
    isComplex?: boolean;
    servings?: number;
    nutrients: Nutrient[];
  };
  totalServings: number;
  usedServings?: number;
  defaultSelectedServings?: number;
}

const IconButtonStyled = styled(IconButton)`
  align-self: center;
  padding: unset;
`;

const NameStyled = styled("span")`
  font-size: 18px;
  font-weight: bold;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const NameWrapper = styled("div")`
  max-width: 510px;
  display: flex;
  height: 32px;
  margin-bottom: 8px;
  align-items: center;
  &:hover {
    color: #801fff;
  }
  cursor: pointer;
`;
