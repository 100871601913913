import { styled } from "@mui/material";

export const MonitoringEmptyStateStyled = styled("div")`
  display: grid;
  place-items: center;
  place-content: center;
  gap: 1.5rem;
  height: 100%;
`;

export const ContentWrapper = styled("div")`
  display: grid;
  place-items: center;
  gap: 1rem;
`;
