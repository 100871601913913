import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const ArrowMeasureMonitoring = (props: SvgIconProps) => (
  <SvgIcon width="12" height="14" viewBox="0 0 12 14" {...props}>
    <path
      d="M6.64714 0.476159C6.28921 0.113416 5.70793 0.113416 5.34999 0.476159L0.76845 5.11928C0.410517 5.48202 0.410517 6.07111 0.76845 6.43386C1.12638 6.7966 1.70767 6.7966 2.0656 6.43386L5.08369 3.3723L5.08369 12.2755C5.08369 12.7891 5.49317 13.2041 6 13.2041C6.50683 13.2041 6.91631 12.7891 6.91631 12.2755L6.91631 3.3723L9.9344 6.43096C10.2923 6.7937 10.8736 6.7937 11.2316 6.43096C11.5895 6.06821 11.5895 5.47912 11.2316 5.11637L6.65001 0.473257L6.64714 0.476159Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
