import { Checkbox, Typography } from "@mui/material";
import { uniq } from "lodash";

import { useAppTranslation } from "@hooks";
import { CategoryNutrientsResponse } from "@typeDefinitions/responses/dictionaries";
import { useVisibleNutrients } from "@context/VisibleNutrientsContext/VisibleNutrientsContext";

import {
  GroupWrapper,
  StyledDivider,
  StyledFormControlLabel,
} from "./NutrientsViewSettings.styled";

interface NutrientGroupProps {
  category: CategoryNutrientsResponse;
}

export const NutrientGroup = ({ category }: NutrientGroupProps) => {
  const { t, isPolishChosen } = useAppTranslation();
  const { nutrients, setNutrients } = useVisibleNutrients();
  const isAllNutrientsVisible = category.nutrients.every(nutrient =>
    nutrients.includes(nutrient.id.toString()),
  );
  const isSomeNutrientsVisible =
    !isAllNutrientsVisible &&
    category.nutrients.some(nutrient =>
      nutrients.includes(nutrient.id.toString()),
    );

  const handleToggleNutrient = (id: string) => {
    const isVisible = nutrients?.includes(id);

    if (isVisible) setNutrients(uniq(nutrients?.filter(n => n !== id)));
    else setNutrients(uniq([...nutrients, id]));
  };

  const handleToggleAll = () => {
    const categoryNutrients = category.nutrients.map(n => n.id.toString());
    if (isAllNutrientsVisible) {
      const nutrientsArr = uniq(
        nutrients?.filter(n => !categoryNutrients.includes(n)),
      );
      setNutrients(nutrientsArr);
    } else {
      const nutrientsArr = uniq([...nutrients, ...categoryNutrients]);
      setNutrients(nutrientsArr);
    }
  };

  return (
    <GroupWrapper>
      <Typography variant="body1" lineHeight="2.5rem" fontWeight={600}>
        {isPolishChosen ? category.namePl : category.nameEn}
      </Typography>
      <StyledFormControlLabel
        checked={isAllNutrientsVisible}
        control={
          <Checkbox
            onClick={handleToggleAll}
            indeterminate={isSomeNutrientsVisible}
          />
        }
        label={t("common.check_all")}
      />
      <StyledDivider />
      <div className="grid">
        {category.nutrients.map(nutrient => {
          return (
            <div className="flex" key={nutrient.id}>
              <StyledFormControlLabel
                checked={nutrients?.includes(nutrient.id.toString())}
                control={
                  <Checkbox
                    onClick={() => handleToggleNutrient(nutrient.id.toString())}
                  />
                }
                label={
                  isPolishChosen
                    ? nutrient.descriptionPl
                    : nutrient.descriptionEn
                }
              />
            </div>
          );
        })}
      </div>
    </GroupWrapper>
  );
};
