import { Button, styled } from "@mui/material";

export const RoundedButton = styled(Button)`
  padding: 0;
  width: 28px;
  height: 28px;
  min-width: 28px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.light[200]};
  border-radius: 100%;
  font-size: 12px;
`;

interface WrapperProps {
  gap: number;
  maxHeight: number;
}

export const Wrapper = styled("div")<WrapperProps>`
  display: flex;
  flex-wrap: wrap;
  gap: ${props => `${props.gap}px`};
  max-height: ${props => `${props.maxHeight}px`};
  overflow: hidden;
  align-self: center;
`;

export const CollapseWrapper = styled("div")`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px;
  max-width: 300px;
`;
