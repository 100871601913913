import {
  FetchSingedSurveysResponse,
  fetchPatientSurvey,
} from "@client/surveys";
import { useQuery } from "@tanstack/react-query";
import { QueryOptionsTyped } from "../types";
import { ApiResponse } from "@typeDefinitions";

export const fetchPatientSurveyQueryKey = "fetchPatientSurveyQueryKey";

export const useFetchPatientSurveyQuery = (
  patientId: string,
  surveyId: string,
  options?: QueryOptionsTyped<ApiResponse<FetchSingedSurveysResponse>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchPatientSurveyQueryKey, patientId, surveyId],
    () => fetchPatientSurvey(patientId, surveyId),
    options,
  );

  return {
    data: data?.data,
    ...rest,
  };
};
