import * as yup from "yup";

export interface PatientProfileShowNutrientResource {
  kcal: boolean;
  macro: boolean;
  micro: boolean;
  important: boolean;
  targetMeals: boolean;
}

export const patientShowNutrientsSchema = yup.object({
  kcal: yup.boolean().required(),
  macro: yup.boolean().required(),
  micro: yup.boolean().required(),
  important: yup.boolean().required(),
  targetMeals: yup.boolean().required(),
});
