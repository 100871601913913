import { useFormContext } from "react-hook-form";

import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";

import { useAppTranslation } from "@hooks";
import { useCloneRecipeToBaseMutation } from "@hooks/queries";
import { useCreatorVersion } from "@hooks/queries/diets/creator/useCreatorVersion";

import { useUpdateScheduleRecipeMutation } from "@hooks/queries/schedule";
import { toast } from "react-toastify";
import {
  RecipeEditInput,
  mapScheduleRecipeEditRequest,
} from "../useRecipeEditForm";
import { Footer } from "./DrawerFooter.styled";

interface ScheduleDrawerFooterProps {
  onClose: () => void;
  programId: string;
  mealId: string;
  dayId: string;
  recipeId: string | undefined;
}

export const ScheduleDrawerFooter = ({
  onClose,
  programId,
  mealId,
  dayId,
  recipeId,
}: ScheduleDrawerFooterProps) => {
  const { t } = useAppTranslation();
  const { handleSubmit } = useFormContext<RecipeEditInput>();
  const { version } = useCreatorVersion();
  const { mutate: updateRecipe, isLoading: isUpdating } =
    useUpdateScheduleRecipeMutation(programId);
  const { mutate: saveToBase, isLoading: isSavingToBase } =
    useCloneRecipeToBaseMutation({
      onSuccess: () => {
        toast.success(t("diet.recipe_sidepanel.add_to_base_toast"));
      },
    });

  const handleEdit = (onSuccess: () => void) =>
    handleSubmit(
      data =>
        recipeId &&
        updateRecipe(
          {
            dayId,
            mealId,
            recipe: mapScheduleRecipeEditRequest(
              data,
              Number(version),
              recipeId,
            ),
          },
          { onSuccess },
        ),
      err => console.log(err),
    )();

  const handleSaveToBase = () =>
    recipeId && handleEdit(() => saveToBase(recipeId));
  const onSubmit = () => handleEdit(onClose);

  return (
    <Footer>
      <Button variant="outlined" onClick={onClose}>
        {t("common.cancel")}
      </Button>
      {recipeId && (
        <LoadingButton
          variant="outlined"
          onClick={handleSaveToBase}
          loading={isSavingToBase}
        >
          {t("diet.recipe_sidepanel.add_to_base")}
        </LoadingButton>
      )}
      <LoadingButton
        variant="contained"
        onClick={onSubmit}
        loading={isUpdating}
      >
        {t("common.save")}
      </LoadingButton>
    </Footer>
  );
};
