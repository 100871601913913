import { Button, Typography, styled } from "@mui/material";

export const ItemsHeader = styled(Typography)`
  font-size: 1.25rem;
`;

export const AddRecipeButton = styled(Button)`
  display: flex;
  gap: 0.25rem;
  padding-left: 0.625rem;
`;
