import { DictionaryNameDto } from "@client";

interface DictionaryIf {
  id: number;
  names: DictionaryNameDto[];
}

export const pairOptions = (
  array: undefined | DictionaryIf[],
): Record<number, number | undefined> | undefined => {
  if (!array?.length) return;
  const mapped = Object.keys(array).map(id => {
    const obj = array[parseInt(id)];
    return [
      [obj.id],
      Object.values(array).find(
        element =>
          element.id !== obj.id &&
          element.names.filter(name => name.value === obj.names[0].value)
            .length,
      )?.id,
    ];
  });
  return Object.fromEntries(mapped);
};

export const getFromDict = (
  id: number | undefined,
  dict: Record<number, number | undefined> | undefined,
) => {
  if (!id || !dict) return;
  else return dict[id];
};
