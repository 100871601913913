import { Box, styled } from "@mui/material";
import { DataGrid as MuiDataGrid } from "@mui/x-data-grid";
import { ComponentProps, memo, ReactNode, useCallback } from "react";
import { GridPaginationWrapper } from "@components/PaginationNew";
import { EmptyStateCardForGrid } from "@views/emptyStates/common.styled";
import { FiltersNoResults } from "@views/emptyStates/FiltersNoResults";

const DataGrid = styled(MuiDataGrid)`
  border: none;
  .MuiDataGrid-columnHeaders {
    color: #727272;
    font: var(--font-body-text-12-figtree-medium);
    border: none;
    margin-bottom: 8px;
  }

  .MuiDataGrid-columnHeader {
    padding: 4px 8px;
    :focus {
      border: none;
      outline: none;
    }
    :focus-within {
      border: none;
      outline: none;
    }
  }

  .MuiDataGrid-row {
    background: #fff;
    border-radius: 8px;
    :hover {
      filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08));
    }
  }

  .MuiDataGrid-row {
    &.Mui-selected {
      filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08));
      .MuiDataGrid-cell {
        background: #fbfafc;
        border-color: #f5f1fe;
        :first-of-type {
          border-left: solid 3px #7448d0;
        }
      }
    }
    :hover {
      .MuiDataGrid-cell {
        background: #fbfafc;
        border-color: #f5f1fe;
      }
    }
  }

  .MuiDataGrid-columnSeparator {
    display: none;
  }

  .MuiDataGrid-cell {
    border-bottom: 1px solid #f1f1f1;
    border-top: 1px solid #f1f1f1;
    padding: 8px;
    :first-of-type {
      border-left: 1px solid #f1f1f1;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      padding-left: 12px;
    }
    :last-of-type {
      border-right: 1px solid #f1f1f1;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      padding-right: 12px;
    }
    :focus {
      border: none;
      outline: none;
    }
    :focus-within {
      border: none;
      outline: none;
    }
  }

  .MuiDataGrid-footerContainer {
    margin-top: 8px;
    border: unset;
    min-height: auto;

    & .MuiToolbar-root {
      min-height: auto;
      padding: 0;
      color: ${({ theme }) => theme.colors.neutral.dark[700]};
    }

    & .MuiTablePagination-root {
      width: 100%;
      overflow: unset;
    }

    & .MuiTablePagination-spacer {
      display: none;
    }

    & .MuiPagination-root.MuiPagination-text.MuiTablePagination-actions {
      margin-left: auto;
    }
  }
  .MuiDataGrid-selectedRowCount {
    display: none;
  }
  .MuiDataGrid-virtualScroller {
    scrollbar-gutter: stable;
    scrollbar-width: thin;
  }
  .MuiDataGrid-overlay {
    height: auto !important;
  }
  ,
  .MuiDataGrid-overlayWrapper {
    height: auto !important;
  }
  ,
  .MuiDataGrid-overlayWrapperInner {
    height: auto !important;
  }
` as typeof MuiDataGrid;

export const CustomDataGrid = memo(
  (props: ComponentProps<typeof MuiDataGrid>) => {
    const getRowSpacing = useCallback(() => ({ top: 8 }), []);

    return (
      <DataGrid
        autoHeight
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        paginationMode="server"
        pagination
        getRowSpacing={getRowSpacing}
        components={{
          Pagination: GridPaginationWrapper,
          NoRowsOverlay: () => (
            <Box height="auto">
              <EmptyStateCardForGrid className="emptyState">
                <FiltersNoResults />
              </EmptyStateCardForGrid>
            </Box>
          ),
        }}
        rowsPerPageOptions={[10, 25]}
        headerHeight={32}
        {...props}
        rowHeight={(props.rowCount ?? 0) === 0 ? 150 : props.rowHeight ?? 52}
      />
    );
  },
);

export const FlexDataGrid = ({ children }: { children: ReactNode }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ flexGrow: 1 }}>{children}</div>
      </div>
    </Box>
  );
};

export default DataGrid;
