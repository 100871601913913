import { KcalMonitoring } from "@assets/icons/Monitoring";
import {
  KcalValue,
  MacroWrapper,
} from "@views/dietician/PatientMonitoring2/components/MealsBox/Client/MealItem.styled";

type KcalMacroProps = {
  value: number;
};

const KcalMacro = ({ value }: KcalMacroProps) => {
  return (
    <MacroWrapper>
      <KcalMonitoring fill="#727272" />
      <KcalValue>{value} kcal</KcalValue>
    </MacroWrapper>
  );
};

export default KcalMacro;
