import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const updateSurveyGroupsSort = async (
  surveyId: string,
  payload: UpdateSurveyGroupsSortRequest,
) => {
  await fetchData(
    `/dietitian/surveys/${surveyId}/groups-sort`,
    APIMethods.PUT,
    payload,
  );
};

export interface UpdateSurveyGroupsSortRequest {
  ids: number[];
}
