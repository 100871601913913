import { CustomTag } from "@client/customTags/fetchCustomTags";
import { CustomTagChip, CustomTagProps } from "@components/CustomTagChip";
import { CustomTagsDropdown } from "@components/CustomTagsDropdown";
import { useSingleTaskQuery } from "@hooks/queries/tasks/useSingleTaskQuery";
import { useMemo, type FC } from "react";
import { useUpdateTaskMutation } from "@hooks/queries/tasks/useUpdateTaskMutation";
import {
  renderTagChips,
  mapTaskData,
  mapTagsToStringIds,
} from "./TaskTagsSelect.utils";
import { Tooltip } from "@mui/material";
import { Container, TooltipContainer } from "./TaskTagsSelect.styled";
import { RoundedButton } from "@components/OverflowingList/OverflowingList.styled";

interface TaskTagsSelectProps {
  tags: CustomTag[];
  taskId: number;
  editable?: boolean;
}

export const TaskTagsSelect: FC<TaskTagsSelectProps> = ({
  taskId,
  tags,
  editable = false,
}) => {
  const currentTaskId = String(taskId);

  const { data } = useSingleTaskQuery(currentTaskId);
  const { handleUpdateTask } = useUpdateTaskMutation();

  const updateTaskWithTags = (tagIds: string[]) => {
    if (data) {
      handleUpdateTask({
        id: currentTaskId,
        ...mapTaskData(data, tagIds),
      });
    }
  };

  const handleDelete = (tagId: number) => {
    const updatedTags = tags.filter(tag => tag.id !== tagId);
    const leftTagIds = mapTagsToStringIds(updatedTags);
    updateTaskWithTags(leftTagIds);
  };

  const onDropdownClosed = (selectedTags: CustomTag[]) => {
    const currentTagIds = mapTagsToStringIds(selectedTags);
    updateTaskWithTags(currentTagIds);
  };

  const tagsIds = useMemo(() => {
    return tags.map(({ id }) => id);
  }, [tags]);

  const visibleItems = tags.slice(0, 1);
  const overflowItems = tags.slice(1);
  const overflowCount = overflowItems.length;

  return (
    <Container onClick={e => e.stopPropagation()}>
      {renderTagChips(visibleItems, handleDelete)}
      {overflowCount > 0 && (
        <Tooltip
          title={
            <TooltipContainer>
              {renderTagChips(overflowItems, handleDelete)}
            </TooltipContainer>
          }
          arrow
          placement="top"
          slotProps={{
            tooltip: {
              sx: {
                "bgcolor": "white",
                "color": "text.primary",
                "& .MuiTooltip-arrow": {
                  color: "white",
                },
              },
            },
          }}
        >
          <RoundedButton>+{overflowCount}</RoundedButton>
        </Tooltip>
      )}
      {editable && (
        <CustomTagsDropdown
          defaultSelectedTagsIds={tagsIds}
          onClosed={onDropdownClosed}
          triggerClassName="invisible group-hover/tags:visible"
        />
      )}
    </Container>
  );
};
