import { ChangeEvent, useRef } from "react";

import { COLORS } from "@utils/constants";
import { Spinner } from "@components/Spinner";
import { MountainsWithSun } from "@assets/icons";
import { AvatarMui, StyledIconButton } from "./Avatar.styled";

export type AvatarSize = "extra-small" | "small" | "medium" | "large" | "huge";

export interface AvatarProps {
  fullName: string;
  image?: string | null;
  size?: AvatarSize;
  color?: string;
  isEditable?: boolean;
  isUploading?: boolean;
  onFileUpload?: (event: ChangeEvent<HTMLInputElement>) => Promise<void> | void;
  status?: boolean;
  className?: string;
}
export const Avatar = (props: AvatarProps) => {
  const {
    image,
    size = "medium",
    color = COLORS[0],
    fullName = "Anna Kowalska",
    isEditable = false,
    isUploading = false,
    onFileUpload,
    status,
    className,
  } = props;

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleUploadFileButtonClick = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  function stringAvatar(name: string) {
    return {
      children: `${name
        .split(" ")
        .filter(w => w)
        .map(w => w[0].toUpperCase())
        .slice(0, 2)
        .join("")}`,
    };
  }

  return (
    <div className="relative">
      <AvatarMui
        size={size}
        status={status}
        className={className}
        {...stringAvatar(fullName)}
        src={image ?? undefined}
      />
      {isEditable && (
        <StyledIconButton
          disabled={isUploading}
          onClick={handleUploadFileButtonClick}
        >
          {isUploading ? (
            <Spinner
              size="w-6 h-6"
              borderWidth={2}
              className="rounded-full bg-transparent"
            />
          ) : (
            <>
              <MountainsWithSun size="w-5 h-5" />
              <input
                type="file"
                accept="image/png,image/jpg,image/jpeg,image/webp"
                ref={fileInputRef}
                disabled={isUploading}
                onChange={onFileUpload}
                style={{ display: "none" }}
              />
            </>
          )}
        </StyledIconButton>
      )}
    </div>
  );
};
