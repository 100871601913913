import { RowType } from "@views/dietician/PatientProgramChoice/PatientProgramChoiceGrid";
import { useAppTranslation } from "@hooks";
import { styled } from "@mui/material";
import { useMemo } from "react";

type NameProps = {
  program: Pick<RowType, "translations">;
};
const Name = ({ program }: NameProps) => {
  const { currentLanguageDTO } = useAppTranslation();

  const name = useMemo(() => {
    return (
      program.translations.find(t => t.lang === currentLanguageDTO)?.name ?? ""
    );
  }, [program, currentLanguageDTO]);

  return <NameStyled>{name}</NameStyled>;
};

const NameStyled = styled("span")`
  color: #333333;
  font-family: Figtree, serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`;

export default Name;
