import { getMappedItemFromApiFieldName } from "@views/dietician/PatientMonitoring2/components/MeasurementsBox/MeasurementUtil";
import { BodyMeasurementMonitoringResource } from "@client/resources/BodyMeasurementMonitoringResource";
import { BodyMeasurementConst } from "@consts/BodyMeasurementConst";

export const getFirstMeasurement = (
  data: BodyMeasurementMonitoringResource[],
): BodyMeasurementMonitoringResource | null => {
  const measurements = data.filter(i => i.measurements);

  if (!measurements.length) {
    return null;
  }

  return measurements[0];
};

export const getLastMeasurement = (
  data: BodyMeasurementMonitoringResource[],
): BodyMeasurementMonitoringResource | null => {
  const measurements = data.filter(i => i.measurements).reverse();

  if (!measurements.length) {
    return null;
  }

  return measurements[0];
};

type Measurements = {
  first: Exclude<BodyMeasurementMonitoringResource["measurements"], null>;
  second: Exclude<BodyMeasurementMonitoringResource["measurements"], null>;
};

const getValues = (
  measurements: Measurements,
  equalFunction: (a: number, b: number) => boolean,
  icon: string,
) => {
  const { first, second } = measurements;

  return first.bodyMeasurements
    .filter(firstBodyMeasurement => {
      if (firstBodyMeasurement.body.id === BodyMeasurementConst.height) {
        return false;
      }

      const secondBodyMeasurement = second.bodyMeasurements.find(
        secondBodyMeasurement =>
          secondBodyMeasurement.body.id === firstBodyMeasurement.body.id,
      );

      return equalFunction(
        firstBodyMeasurement.value ?? 0,
        secondBodyMeasurement?.value ?? 0,
      );
    })
    .map(firstBodyMeasurement => {
      const secondBodyMeasurement = second.bodyMeasurements.find(
        secondBodyMeasurement =>
          secondBodyMeasurement.body.id === firstBodyMeasurement.body.id,
      );

      const value = Math.abs(
        (firstBodyMeasurement.value ?? 0) - (secondBodyMeasurement?.value ?? 0),
      );

      return {
        body: firstBodyMeasurement.body,
        value: Number.isInteger(value) ? value : Math.round(value * 100) / 100,
        icon: icon,
      };
    });
};
export const getDecreases = (measurements: Measurements) => {
  const func = (a: number, b: number) => a - b > 0;
  return getValues(measurements, func, "-");
};

export const getIncreases = (measurements: Measurements) => {
  const func = (a: number, b: number) => a - b < 0;
  return getValues(measurements, func, "+");
};

export const getEquals = (measurements: Measurements) => {
  const func = (a: number, b: number) => a - b === 0;
  return getValues(measurements, func, " ");
};
