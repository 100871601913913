import { fetchOauthGoogleHandleCallback } from "@client/oauth";
import { useQuery } from "@tanstack/react-query";
import { QueryOptions } from "../types";

export const fetchOauthGoogleHandleCallbackQueryKey =
  "fetchOauthGoogleHandleCallbackQueryKey";

export const useFetchOauthGoogleHandleCallbackQuery = (
  params: URLSearchParams,
  options?: QueryOptions,
) => {
  const { data, ...rest } = useQuery({
    queryFn: () => fetchOauthGoogleHandleCallback(params),
    queryKey: [fetchOauthGoogleHandleCallbackQueryKey, params.toString()],
    ...options,
  });

  return { data: data?.data, ...rest };
};
