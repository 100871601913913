import { useMutation, useQueryClient } from "@tanstack/react-query";

import { LOGIN } from "@routes";
import { MutationOptions } from "./types";
import { fetchDietitianAccountQueryKey } from "./settings";
import { logout } from "@client";

export const useLogoutMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation(logout, {
    ...options,
    onSuccess: () => {
      queryClient.resetQueries([fetchDietitianAccountQueryKey]);
      window.location.replace(LOGIN);
    },
  });

  return {
    logout: mutate,
    ...rest,
  };
};
