import React, { ReactElement, useMemo } from "react";
import { SubmitHandler, useFieldArray } from "react-hook-form";
import { FormTextField } from "@components/FormTextField";
import { FormColorPickerDropdown } from "@components/FormColorPicker";
import { ModalShell } from "@components/ModalShell";
import { useAppTranslation } from "@hooks";
import { Button, InputAdornment, Tooltip, useTheme } from "@mui/material";
import { Info, Plus } from "@assets/icons";
import { useSyncCustomTagsMutation } from "@hooks/queries/customTags/useSyncCustomTagsMutation";
import {
  CustomTagManageFormInput,
  mapCustomTagManageFormToRequest,
  mapTagsToCustomTagManageForm,
  useCustomTagsManageForm,
} from "@hooks/useCustomTagManageForm";
import { CustomTagChip } from "@components/CustomTagChip";
import {
  Description,
  FormTagsList,
  FormTagInputRow,
  SectionLabel,
  SystemTagsList,
} from "@components/CustomTagsManageModal/CustomTagsManageModal.styled";
import { useFetchCustomTagsQuery } from "@hooks/queries/customTags/useFetchCustomTagsQuery";
import { defaultColor } from "@components/ColorPicker";
import { CustomTagDeleteButton } from "@components/CustomTagsManageModal/CustomTagDeleteButton";

export interface CustomTagsManageModalProps {
  open: boolean;
  onClose: () => void;
}

export const CustomTagsManageModal = ({
  open,
  onClose,
}: CustomTagsManageModalProps): ReactElement => {
  const { t } = useAppTranslation();

  const { palette } = useTheme();

  const { tags } = useFetchCustomTagsQuery();

  const systemTags = useMemo(() => tags.filter(tag => tag.isSystem), [tags]);

  const customTags = useMemo(() => tags.filter(tag => !tag.isSystem), [tags]);

  const mappedForm = useMemo(
    () => mapTagsToCustomTagManageForm(customTags),
    [customTags],
  );

  const { mutate, isLoading } = useSyncCustomTagsMutation({
    onSuccess: () => {
      onClose();
      reset();
    },
  });

  const { control, reset, handleSubmit } = useCustomTagsManageForm(mappedForm);

  const {
    fields: formTags,
    append,
    remove,
  } = useFieldArray({
    name: "tags",
    control: control,
  });

  const onSubmit: SubmitHandler<CustomTagManageFormInput> = data => {
    mutate(mapCustomTagManageFormToRequest(data));
  };

  const handleAddTagClick = () => {
    append({ tagId: null, name: "", color: defaultColor });
  };

  const handleDeleteTagClick = (index: number) => {
    remove(index);
  };

  return (
    <ModalShell
      title={t("custom_tags.manage_modal.title")}
      open={open}
      onClose={onClose}
      isLoading={isLoading}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Description>{t("custom_tags.manage_modal.description")}</Description>
      <SectionLabel>
        {t("custom_tags.manage_modal.system_section_title")}
        <Tooltip
          title={
            <>
              <span>{t("custom_tags.manage_modal.info.text1")}</span>
              <ul className="list-disc pl-[16px]">
                <li>{t("custom_tags.manage_modal.info.text2")}</li>
                <li>{t("custom_tags.manage_modal.info.text3")}</li>
                <li>{t("custom_tags.manage_modal.info.text4")}</li>
                <li>{t("custom_tags.manage_modal.info.text5")}</li>
              </ul>
            </>
          }
          placement="top"
          arrow
        >
          <span>
            <Info className="w-[12px] h-[12px]" fill={palette.primary.main} />
          </span>
        </Tooltip>
      </SectionLabel>
      <SystemTagsList>
        {systemTags.map(tag => (
          <CustomTagChip
            key={`system-tag-${tag.id}`}
            id={tag.id}
            label={tag.name}
            color={tag.color}
          />
        ))}
      </SystemTagsList>
      <SectionLabel>
        {t("custom_tags.manage_modal.custom_section_title")}
      </SectionLabel>
      <FormTagsList>
        {formTags.map((field, index) => (
          <FormTagInputRow key={field.id}>
            <FormTextField
              control={control}
              name={`tags.${index}.name`}
              size="small"
              variant="outlined"
              fullWidth
              placeholder={t("custom_tags.manage_modal.tag_name_placeholder")}
              autoFocus
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FormColorPickerDropdown
                      control={control}
                      name={`tags.${index}.color`}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <CustomTagDeleteButton
              index={index}
              id={field.tagId}
              label={field.name}
              onDelete={handleDeleteTagClick}
            />
          </FormTagInputRow>
        ))}
        <Button
          onClick={handleAddTagClick}
          startIcon={<Plus size="w-3 h-3" />}
          color="primary"
        >
          {t("custom_tags.manage_modal.add_tag")}
        </Button>
      </FormTagsList>
    </ModalShell>
  );
};
