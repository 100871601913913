import { useAppTranslation, useClientParams } from "@hooks";
import {
  SectionHeadEditLayout,
  SectionHeadLayout,
  SectionItemLabel,
  SectionItemLayout,
  SectionLayout,
  SectionTitle,
  TabHeadActionEditLabel,
} from "@views/dietician/ClientHealth/ClientHealthNew.styled";
import { Box, Chip } from "@mui/material";
import useFetchPatientHydrationProduct from "@hooks/queries/client/useFetchPatientHydrationProduct";
import { useIrrigationProducts } from "@hooks/dictionary/useIrrigationProducts";
import { useMemo, useState } from "react";
import { Language } from "@typeDefinitions";
import IrrigationDialog from "@views/dietician/ClientHealth/ProfileTab/IrrigationSection/Dialog/IrrigationDialog";
import { EditIcon } from "@icons/index";

const IrrigationSection = () => {
  const { t, currentLanguage } = useAppTranslation();
  const id = useClientParams();
  const [open, setOpen] = useState(false);
  const { data } = useFetchPatientHydrationProduct({ patientId: id });
  const { getTranslationById } = useIrrigationProducts();

  const irrigationProductName = useMemo(() => {
    if (!data) {
      return "";
    }

    const lang: Language = currentLanguage as unknown as Language;
    return getTranslationById(data.data.hydrationProduct.id, lang)?.name ?? "";
  }, [data, getTranslationById, currentLanguage]);

  return (
    <SectionLayout>
      <SectionHeadLayout>
        <SectionTitle>
          {t(
            "patient.health_and_goal.dietary_profile_tab.section_hydration.section_title",
          )}
        </SectionTitle>
        <SectionHeadEditLayout onClick={() => setOpen(true)}>
          <EditIcon color="#7448D0" />
          <TabHeadActionEditLabel>{t("common.edit")}</TabHeadActionEditLabel>
        </SectionHeadEditLayout>
      </SectionHeadLayout>
      <SectionItemLayout>
        <SectionItemLabel>
          {t(
            "patient.health_and_goal.dietary_profile_tab.section_hydration.main_beverage",
          )}
        </SectionItemLabel>
        {irrigationProductName && (
          <Box>
            <Chip color="primary" label={irrigationProductName} />
          </Box>
        )}
      </SectionItemLayout>
      <SectionItemLayout>
        <SectionItemLabel>
          {t(
            "patient.health_and_goal.dietary_profile_tab.section_hydration.daily_goal",
          )}
        </SectionItemLabel>
        {data?.data.goal} ml
      </SectionItemLayout>

      <IrrigationDialog open={open} setOpen={setOpen} />
    </SectionLayout>
  );
};

export default IrrigationSection;
