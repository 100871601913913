import { useController, useWatch } from "react-hook-form";

import { DragHandle, TrashNew } from "@assets/icons";
import { MacrosText } from "@components/MacrosText";
import { OuterLabel } from "@components/OuterLabel";

import { useProductsContext } from "../ProductsContext";
import { RecipeEditInput } from "../useRecipeEditForm";
import {
  DraggableButton,
  GramsTextField,
  IconButtonStyled,
  MacrosWrapper,
  RowContainter,
} from "./EditProductRow.styled";
import { MeasureInput } from "./MeasureInput/MeasureInput";
import { ProductSearchAutocomplete } from "./ProductSearchAutocomplete";
import { useMemo } from "react";
import { scaleNutrients } from "@utils";
import { useAppTranslation } from "@hooks";
import { decimalFourDigitsInput } from "@utils/inputs";

interface EditProductRowProps {
  label?: boolean;
  formIdx: number;
  onDelete: (idx: number) => void;
}
export const EditProductRow = ({
  label = false,
  formIdx,
  onDelete,
}: EditProductRowProps) => {
  const { t } = useAppTranslation();
  const {
    field: { value: grams, onChange: setGrams },
  } = useController({ name: `products.${formIdx}.grams` });
  const productId = useWatch<RecipeEditInput, `products.${number}.foodId`>({
    name: `products.${formIdx}.foodId`,
  });
  const { products } = useProductsContext();
  const product = productId ? products.get(productId) : undefined;
  const scaledNutrients = useMemo(
    () => scaleNutrients(product?.nutrients ?? [], grams),
    [product, grams],
  );
  return (
    <RowContainter>
      <DraggableButton className="dragHandle" size="small">
        <DragHandle />
      </DraggableButton>
      <ProductSearchAutocomplete showLabel={label} formIdx={formIdx} />

      <OuterLabel label={label && t("diet.recipe_sidepanel.grams")}>
        <GramsTextField
          size="small"
          value={grams}
          onChange={e => setGrams(e.target.value)}
          InputProps={{
            inputComponent: decimalFourDigitsInput,
          }}
        />
      </OuterLabel>

      <MeasureInput
        label={label}
        measures={product?.measures}
        formIdx={formIdx}
      />

      <OuterLabel label={label && t("diet.recipe_sidepanel.nutrients")}>
        <MacrosWrapper>
          <MacrosText
            nutrients={scaledNutrients}
            config={{
              brackets: false,
              dashes: false,
              zeros: true,
              precision: 1,
            }}
          />
        </MacrosWrapper>
      </OuterLabel>

      <IconButtonStyled
        color="primary"
        onClick={() => onDelete(formIdx)}
        marginTop={label}
      >
        <TrashNew />
      </IconButtonStyled>
    </RowContainter>
  );
};
