import { fetchData } from "@utils/api";
import { APIMethods, ApiEndpoints } from "@typeDefinitions";

export const toggleSuggestedTasksVisibility = async ({
  id,
  hidden,
}: SuggestedTasksVisibilityRequestPayload) => {
  const response = await fetchData(
    `${ApiEndpoints.SuggestedTasks}/${id}/hidden`,
    APIMethods.POST,
    { hidden },
  );

  return response;
};

export interface SuggestedTasksVisibilityRequestPayload {
  id: number;
  hidden: number;
}
