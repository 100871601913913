import { useContext, useState } from "react";

import { Collapse } from "@mui/material";

import { TableOfContentsContext } from "@context/TableOfContentsContext";
import { SubgroupElementPopover } from "./SubgroupElementPopover";
import {
  ListElementStyled,
  StyledArrowDown,
  TitleStyled,
} from "./TableOfContentsPopover.styled";

interface SubgroupIf {
  id: number;
  name: string;
}

interface GroupElementPopoverProps {
  id: string;
  title: string;
  subgroups: SubgroupIf[];
}

export const GroupElementPopover = ({
  id,
  title,
  subgroups,
}: GroupElementPopoverProps) => {
  const { selectedId, setSelectedId, setGroupId, groupId } = useContext(
    TableOfContentsContext,
  );

  const selected = selectedId === id;
  const [expanded, setExpanded] = useState(groupId === Number(id));

  const handleToggleExpand = () => {
    setSelectedId(id);
    setExpanded(!expanded);
    setGroupId(Number(id));
  };

  return (
    <div className="grid">
      <ListElementStyled selected={selected} onClick={handleToggleExpand}>
        <StyledArrowDown expanded={expanded} />
        <TitleStyled>{title}</TitleStyled>
      </ListElementStyled>
      <Collapse in={expanded}>
        <div>
          {subgroups.map(({ id: subgroupId, name }) => (
            <SubgroupElementPopover
              key={subgroupId}
              id={`${subgroupId}`}
              groupId={id}
              title={name}
            />
          ))}
        </div>
      </Collapse>
    </div>
  );
};
