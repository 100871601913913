import { createContext, ReactNode } from "react";

export interface MealElementContextIf {
  selected: number[];
  onChanged: (id: number, selected: boolean) => Promise<void>;
}

const defaultValues: MealElementContextIf = {
  selected: [],
  onChanged: async () => {
    null;
  },
};

export const MealElementContext =
  createContext<MealElementContextIf>(defaultValues);

interface MealElementContextProviderProps {
  context: MealElementContextIf;
  children: ReactNode;
}

export const MealElementContextProvider = ({
  context,
  children,
}: MealElementContextProviderProps) => {
  return (
    <MealElementContext.Provider value={context}>
      {children}
    </MealElementContext.Provider>
  );
};
