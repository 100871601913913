import * as yup from "yup";

import {
  APIMethods,
  ApiResponse,
  MetadataSchema,
  PaginationApiResponse,
} from "@typeDefinitions";
import { fetchData } from "@utils/api";

import {
  ActionsDto,
  ClinicIdDto,
  LangDto,
  NutrientDto,
  TranslationDto,
  actionsSchema,
  clinicIdSchema,
  nutrientSchema,
  translationSchema,
} from "./common";

export async function searchProgramsNew(
  params?: URLSearchParams,
): Promise<ApiResponse<ProgramSearchResourceDto[]>> {
  const data = await fetchData(
    "/dietitian/programs/search-new",
    APIMethods.GET,
    undefined,
    params,
  );
  return await apiResponseSchema.validate(data);
}

export type SearchProgramsPaginationParams = {
  page: number;
  perPage: number;
  query?: string;
  typeDays?: number[];
  authorId?: number;
  days?: number;
  nutrients?: { id: number; from: number | null; to: number | null }[];
  tags?: number[];
  numberOfDietMeals?: number[];
  reviewed?: 0 | 1;
  favorite?: 1;
  latest?: 1;
  owner?: "own" | "alloweat";
};
export type SearchProgramsPaginationResponse = PaginationApiResponse<
  ProgramSearchResourceDto[]
>;

export async function searchProgramsNewDefault(
  params: SearchProgramsPaginationParams,
): Promise<SearchProgramsPaginationResponse> {
  const data = await fetchData(
    "/dietitian/programs/search-new",
    APIMethods.GET,
    undefined,
    mapParamsToURLSearchParams(params),
  );
  return await responseSchema.validate(data);
}

const mapParamsToURLSearchParams = (
  params: SearchProgramsPaginationParams,
): URLSearchParams => {
  const urlParams = new URLSearchParams();
  urlParams.set("page", params.page.toString());
  urlParams.set("perPage", params.perPage.toString());
  if (params.query) {
    urlParams.set("query", params.query);
  }
  if (params.typeDays && params.typeDays.length) {
    params.typeDays.forEach((id: number) =>
      urlParams.append("typeDays[]", id.toString()),
    );
  }
  if (params.authorId) {
    urlParams.set("authorId", params.authorId.toString());
  }
  if (params.days) {
    urlParams.set("days", params.days.toString());
  }
  if (params.nutrients && params.nutrients.length) {
    const nutrients = params.nutrients
      .filter(nutrient => !(nutrient.from === null && nutrient.to === null))
      .map(nutrient => `${nutrient.id}|${nutrient.from}|${nutrient.to}`);

    urlParams.set("nutrients", nutrients.join(","));
  }
  if (params.tags && params.tags.length) {
    urlParams.set("tags", params.tags.join(","));
  }
  if (params.numberOfDietMeals && params.numberOfDietMeals.length) {
    urlParams.set("numberOfDietMeals", params.numberOfDietMeals.join(","));
  }
  if (params.favorite) {
    urlParams.set("favorite", "1");
  }
  if (params.latest) {
    urlParams.set("latest", "1");
  }
  if (params.owner) {
    urlParams.set("owner", params.owner);
  }
  if (params.reviewed) {
    urlParams.set("reviewed", params.reviewed.toString());
  }

  return urlParams;
};

const tagCategorySchema = yup.object().shape({
  id: yup.number().nullable().default(null),
});
const tagResourceSchema = yup.object().shape({
  id: yup.number().required(),
  systemId: yup.number().nullable().default(null),
  name: yup.string().required(),
  translations: yup.array().of(translationSchema.required()).required(),
  clinicN: clinicIdSchema.nullable().default(null),
  tagCategoryId: tagCategorySchema.required(),
});

const programTranslationSchema = yup.object().shape({
  lang: yup.mixed<LangDto>().oneOf(Object.values(LangDto)).required(),
  name: yup.string().ensure(),
});

export const programSearchResourceSchema = yup.object().shape({
  id: yup.number().required(),
  clinic: clinicIdSchema.required(),
  translations: yup.array().of(programTranslationSchema.required()).required(),
  language: yup.mixed<LangDto>().oneOf(Object.values(LangDto)).required(),
  numberOfProgramDays: yup.number().required(),
  startDate: yup.string().required(),
  endDate: yup.string().nullable().default(null),
  tags: yup.array(tagResourceSchema.required()).required(),
  nutrients: yup.array().of(nutrientSchema).required(),
  actions: actionsSchema.required(),
  favorite: yup.array().of(yup.number().required()).required(),
});

const apiResponseSchema = yup.object().shape({
  data: yup.array().of(programSearchResourceSchema).required(),
});

const responseSchema = yup.object({
  data: yup.array().of(programSearchResourceSchema).required(),
  meta: MetadataSchema.defined(),
});

export interface ProgramSearchResourceDto {
  id: number;
  clinic: ClinicIdDto;
  translations: ProgramTranslationDto[];
  language: LangDto;
  numberOfProgramDays: number;
  startDate: string;
  endDate: string | null;
  tags: TagResourceDto[];
  nutrients: NutrientDto[];
  actions: ActionsDto;
  favorite: number[];
}

interface ProgramTranslationDto {
  lang: LangDto;
  name: string;
}

interface TagResourceDto {
  id: number;
  systemId: number | null;
  name: string;
  translations: TranslationDto[];
  clinicN: ClinicIdDto | null;
  tagCategoryId: TagCategoryDto;
}

interface TagCategoryDto {
  id: number | null;
}
