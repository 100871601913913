import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";

import { fetchData } from "@utils/api";
import {
  SectionCategoryDto,
  sectionSearchCategorySchema,
  actionsSchema,
  ActionsDto,
} from "./common";
import { validateAndFixNutrients } from "./utils/validateCatching";

export const searchSections = async (
  params?: URLSearchParams,
): Promise<ApiResponse<SearchSectionsResponse[]>> => {
  const data = await fetchData(
    "/dietitian/program-section-templates/search",
    APIMethods.GET,
    undefined,
    params,
  );
  return await validateAndFixNutrients(searchSectionsSchema, data);
};

const sectionSchema = yup.object().shape({
  id: yup.number().required(),
  clinicId: yup.number().nullable(),
  title: yup.string().required(),
  titleEn: yup.string().nullable(),
  hide: yup.boolean().required(),
  programSectionCategory: sectionSearchCategorySchema,
  actions: actionsSchema.required(),
});

export const searchSectionsSchema = yup.object().shape({
  data: yup.array().of(sectionSchema).required(),
});

export interface SearchSectionsResponse {
  id: number;
  clinicId: number;
  title: string;
  titleEn: string;
  hide: boolean;
  programSectionCategory: SectionCategoryDto;
  actions: ActionsDto;
}
