import { Autocomplete, styled } from "@mui/material";

export const StyledInboxAutocomplete = styled(Autocomplete)`
  & .MuiAutocomplete-popupIndicator {
    transform: none;
  }
`;

export const StyledInboxGrid = styled("div")`
  display: grid;
  gap: 1rem;
  margin: 1rem;
  width: 100%;
`;
