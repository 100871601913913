import { styled } from "@mui/material";

export const Footer = styled("div")`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 12px 16px;
  background: ${({ theme }) => theme.palette.primary.light};
  bottom: 0;
  right: 0;
  position: fixed;
  width: 740px;
  border-end-start-radius: 8px;
  border-end-end-radius: 8px;
`;

export const Title = styled("span")`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;
