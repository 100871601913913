import { Textarea, TextareaProps } from "@components/Textarea";
import { Control, Controller, Path } from "react-hook-form";

import { FieldValues } from "react-hook-form/dist/types";

interface FormTextareaProps<T extends FieldValues> extends TextareaProps {
  name: Path<T>;
  control: Control<T>;
  error?: string;
}

export const FormTextarea = <T extends FieldValues>(
  props: FormTextareaProps<T>,
) => {
  const { name, control, error: externalError, ...rest } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onBlur, onChange, value, ref },
        fieldState: { error },
      }) => (
        <Textarea
          ref={ref}
          name={name}
          value={(value as string | number | undefined) ?? ""}
          onBlur={onBlur}
          onChange={onChange}
          error={externalError ?? error?.message}
          {...rest}
        />
      )}
    />
  );
};
