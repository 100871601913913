import styled from "@emotion/styled";

export const ProgramDietDetailsWrapper = styled.div`
  display: flex;
  gap: 0.75rem;
  padding-top: 0.5rem;
`;

export const MicronutrientsSection = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  gap: 0.75rem;
`;
