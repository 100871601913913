import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import * as yup from "yup";

export type FetchUsedRecipesResponse = ApiResponse<UsedRecipeDto[]>;

export async function fetchUsedRecipes(
  programId: number,
  params: URLSearchParams,
): Promise<FetchUsedRecipesResponse> {
  const data = await fetchData(
    `/dietitian/programs/${programId}/schedule/recipes-used`,
    APIMethods.GET,
    undefined,
    params,
  );
  return await fetchUsedRecipesSchema.validate(data);
}

const fetchUsedRecipesSchema = yup.object().shape({
  data: yup
    .array()
    .of(
      yup.object().shape({
        title: yup.string().required(),
        quantity: yup.number().required(),
        servings: yup.number().required(),
      }),
    )
    .required(),
});

export interface UsedRecipeDto {
  title: string;
  quantity: number;
  servings: number;
}
