import { useState } from "react";

import { CircularProgress } from "@mui/material";

import { AutosaveStatus } from "@components/AutosaveStatus";
import { useFetchSingedSurveysQuery } from "@hooks/queries/surveys";
import { Error404 } from "@views/emptyStates/Error404";

import pacmanLeft from "@assets/img/pacmanBackgroundLeft.png";
import pacmanRight from "@assets/img/pacmanBackgroundRight.png";
import { useAppTranslation } from "@hooks";

import { useDynamicViewportMeta } from "@hooks";
import { ThemeProviderWrapperNew } from "themeNew";
import { IntroductionView } from "./IntroductionView";
import { OutroView } from "./OutroView";
import { QuestionnaireAnswer } from "./QuestionnaireAnswer";
import {
  ContentWrapper,
  Header,
  LayoutWrapper,
  PacmanBackground,
  ViewWrapper,
} from "./QuestionnaireLayout.styled";
import { useParseUrl } from "./useParseUrl";

export const QuestionnaireLayout = () => {
  useDynamicViewportMeta();
  const { url } = useParseUrl();
  const {
    t,
    i18n: { changeLanguage },
  } = useAppTranslation();
  const [introduction, setIntorduction] = useState(true);
  const [outro, setOutro] = useState(false);
  const { data, isLoading, isSuccess } = useFetchSingedSurveysQuery(
    url as string,
    {
      enabled: !!url,
      onSuccess: data =>
        data?.data.survey.langId && changeLanguage(data?.data.survey.langId),
    },
  );

  const showSurvey = !introduction && !outro && isSuccess;
  const showIntroduction = introduction && !outro && isSuccess;

  if (!url) return <Error404 />;

  return (
    <ThemeProviderWrapperNew>
      <LayoutWrapper>
        <Header>
          <img
            src="/static/Logo@2x.png"
            srcSet="/static/Logo@2x.png"
            className="h-8 w-40"
            alt={t("common.logo_alt")}
          />

          <AutosaveStatus />
        </Header>

        <PacmanBackground>
          <img src={pacmanLeft} />
          <img src={pacmanRight} />
        </PacmanBackground>

        <ContentWrapper>
          {isLoading && (
            <div className="grid place-items-center">
              <CircularProgress />
            </div>
          )}
          {showSurvey && (
            <QuestionnaireAnswer handleShowOutro={() => setOutro(true)} />
          )}
          {showIntroduction && (
            <ViewWrapper>
              <IntroductionView
                onClick={() => setIntorduction(false)}
                introductionTitle={data?.survey.introduction.title ?? ""}
                introductionText={data?.survey.introduction.description ?? ""}
                show={data?.survey.introduction.on}
              />
            </ViewWrapper>
          )}
          {outro && (
            <ViewWrapper>
              <OutroView />
            </ViewWrapper>
          )}
        </ContentWrapper>
      </LayoutWrapper>
    </ThemeProviderWrapperNew>
  );
};
