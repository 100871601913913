import { useNavigate } from "react-router-dom";
import { FormProvider } from "react-hook-form";
import { useCallback, useEffect, useMemo, useState } from "react";

import dayjs from "dayjs";

import {
  PatientProgramFormInput,
  useAppTranslation,
  usePatientProgramForm,
} from "@hooks";
import {
  useCreatePatientProgramMutation,
  useFetchPatientProgramQuery,
  useUpdatePatientProgramMutation,
} from "@hooks/queries";
import { FetchPatientProgramResponse } from "@client/program";
import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";
import { SingleCardPageLayout } from "@components/PageLayout";
import { ProgramInfoNavigation } from "@components/forms/ProgramInfoNavigation";
import { usePatientProgramSummaryParams } from "@views/dietician/PatientProgram";
import {
  LanguageSelect,
  PatientProgramDurationSelect,
  ProgramNameSelect,
  SectionsSelect,
} from "@views/dietician/ProgramForm/components";

import { PatientProgramEditWrapper } from "./PatientProgramEdit.styled";

interface ProgramEditProps {
  copy?: boolean;
}

export const PatientProgramEdit = ({ copy = false }: ProgramEditProps) => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();

  const { patientId, programId } = usePatientProgramSummaryParams();
  const { program, isLoading, isError, isSuccess } =
    useFetchPatientProgramQuery(patientId.toString(), programId.toString());

  const [programCopyId, setProgramCopyId] = useState(0);

  const { mutate: updatePatientProgram, isLoading: isUpdating } =
    useUpdatePatientProgramMutation(
      patientId,
      copy ? programCopyId : programId,
      {
        onSuccess: () =>
          navigate(
            `/patients/${patientId}/nutritional-programs/${
              copy ? programCopyId : programId
            }/days`,
          ),
      },
    );

  const { mutate: createPatientProgram } = useCreatePatientProgramMutation(
    programId,
    {
      onSuccess: data => data && setProgramCopyId(data?.id),
    },
  );

  const mapPatientProgramForm = (
    program?: FetchPatientProgramResponse,
  ): PatientProgramFormInput | undefined => {
    if (!program) return;
    return {
      namePl: program.name ? program.name + (copy ? " (Kopia)" : "") : "",
      nameEn: program.name
        ? (program.nameEn ?? program.name) + (copy ? " (Copy)" : "")
        : "",
      workModel: "diets",
      showCals: program.showCals,
      showMacros: program.showMacros,
      showMicros: program.showNutrients,
      showMeasurements: program.showMeasurements,
      hasDiets: program.hasDiets,
      hasTasks: program.hasTasks,
      hasDiary: program.hasDiary,
      hasChat: program.hasChat,
      hasEducation: program.hasEducation,
      hasMacros: program.hasMacros,
      hasMonitoring: program.hasMonitoring,
      hasNewsfeed: program.hasNewsfeed,
      hasSubstitutes: program.hasSubstitutes,
      hasShoppingList: program.hasShoppingList,
      sectionIds: program.sections,
      programLanguage: program.langId,
      days: program.days,
      startDate: program.startDate
        ? dayjs(program.startDate).toDate()
        : undefined,
      finishDate: program.finishDate
        ? dayjs(program.finishDate).toDate()
        : null,
      isIndefinite: program.finishDate === null,
    };
  };

  const mapPatientProgramRequest = (program: PatientProgramFormInput) => {
    return {
      name: program.namePl,
      nameEn: program.nameEn,
      showCals: program.showCals,
      showMacros: program.showMacros,
      showNutrients: program.showMicros,
      showMeasurements: program.showMeasurements,
      hasDiets: program.workModel === "diets",
      hasTasks: program.hasTasks,
      hasDiary: program.hasDiary,
      hasChat: program.hasChat,
      hasEducation: program.hasEducation,
      hasMacros: program.workModel === "macros",
      hasMonitoring: program.hasMonitoring,
      hasNewsfeed: program.hasNewsfeed,
      hasSubstitutes: program.hasSubstitutes,
      hasShoppingList: program.hasShoppingList,
      sections: program.sectionIds,
      langId: program.programLanguage,
      days: program.days,
      startDate: program.startDate
        ? dayjs(program.startDate).format("YYYY-MM-DD")
        : undefined,
      finishDate: program.finishDate
        ? dayjs(program.finishDate).format("YYYY-MM-DD")
        : null,
    };
  };

  const formMapped = useMemo(() => mapPatientProgramForm(program), [program]);
  const form = usePatientProgramForm(formMapped);

  const submit = useCallback(
    form.handleSubmit(program =>
      updatePatientProgram(mapPatientProgramRequest(program)),
    ),
    [updatePatientProgram],
  );

  useEffect(() => {
    if (copy) createPatientProgram();
  }, []);

  return (
    <>
      <SingleCardPageLayout title={t("programs.edit_program")}>
        {isLoading && <Spinner className="h-52" />}

        {isError && <ApiError />}

        {isSuccess && (
          <FormProvider {...form}>
            <PatientProgramEditWrapper>
              <ProgramNameSelect />
              <LanguageSelect name="programLanguage" />
              <PatientProgramDurationSelect edit />
              <SectionsSelect programId={programId} />
            </PatientProgramEditWrapper>
          </FormProvider>
        )}
      </SingleCardPageLayout>

      <ProgramInfoNavigation
        onBack={() => navigate(-1)}
        onSubmit={submit}
        isSubmitting={isUpdating}
      />
    </>
  );
};
