import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "../types";
import { deleteSurvey } from "@client/surveys/deleteSurvey";
import { fetchSurveysQueryKey } from "./useFetchSurveysQuery";

export const useDeleteSurveyMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, number>(
    id => deleteSurvey(id.toString()),
    {
      ...options,
      onSuccess: (data, variables) => {
        options?.onSuccess && options.onSuccess(data, variables);
        queryClient.invalidateQueries([fetchSurveysQueryKey]);
      },
    },
  );
};
