import { Avatar } from "@components/AvatarNew";
import {
  AvatarComponentWrapper,
  StyledFullName,
  CooperationDetails,
} from "./AvatarComponent.styled";
import { Link, To } from "react-router-dom";
import dayjs from "dayjs";
import { useAppEnvironment, useAppTranslation } from "@hooks";
import { Tooltip } from "@mui/material";

interface AvatarComponentProps {
  id: number;
  fullName: string;
  avatarUrl?: string;
  patient?: boolean;
  subscriptionsEnd?: string;
  isActiveClient: boolean;
}

export const AvatarComponent = ({
  id,
  fullName,
  avatarUrl,
  patient = false,
  subscriptionsEnd,
  isActiveClient,
}: AvatarComponentProps) => {
  const { isProd } = useAppEnvironment();
  const { t } = useAppTranslation();
  const endDate = dayjs(subscriptionsEnd);
  const currDate = dayjs();

  const hours = endDate.diff(currDate, "hours");

  const isSubscriptionActive = !(hours <= 0);

  const subscriptionTitle = subscriptionsEnd
    ? endDate.format("DD.MM.YYYY")
    : t("clients.table.indefinite");

  return (
    <Tooltip title={t("clients.table.show_details")} arrow placement="top">
      <Link
        className="w-full"
        to={
          ((patient && `/patients/${id}/info-and-services`) as To) ?? undefined
        }
      >
        <AvatarComponentWrapper>
          <div className="relative">
            <Avatar
              image={avatarUrl}
              fullName={fullName}
              status={isSubscriptionActive}
            />

            {/* 
                   // uncomment, when subscription will add
            {isSubscriptionActive ? (
              <StyledDollarIcon />
            ) : (
              <StyledNoParkingGreySign />
            )} */}
          </div>
          <div>
            <StyledFullName>{fullName}</StyledFullName>
            <CooperationDetails>
              {isActiveClient
                ? t("clients.table.cooperation_until")
                : `${t("clients.cooperation")}:`}{" "}
              {isActiveClient ? subscriptionTitle : t("clients.table.inactive")}
            </CooperationDetails>
          </div>
        </AvatarComponentWrapper>
      </Link>
    </Tooltip>
  );
};
