import styled from "@emotion/styled";
import { AccordionSummary, AccordionDetails } from "@mui/material";
import { ProgramSummaryTableTitle } from "./ProgramSummaryTableTitle";

export const StyledAccordionSummary = styled(AccordionSummary)`
  font-size: 1rem;
  padding: 0;

  & .MuiAccordionSummary-content {
    margin: 1.07rem 0;
  }
`;

export const ProgramSummaryTitle = styled(ProgramSummaryTableTitle)`
  background-color: ${({ theme }) => theme.colors.whiteBackground};
  font-size: 1rem;
  padding: 1rem 0;
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
  font-size: 1rem;
`;
