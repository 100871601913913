import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Download = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 20.115">
    <rect
      id="Rectangle_1008"
      data-name="Rectangle 1008"
      width="20"
      height="20"
      fill="#fff"
      opacity="0"
    />
    <g id="Group_4505" data-name="Group 4505" transform="translate(1.25 0.25)">
      <path
        id="Stroke_1"
        data-name="Stroke 1"
        d="M10.486,0H3.834A3.82,3.82,0,0,0,0,3.729V14.466a3.806,3.806,0,0,0,3.834,3.887h7.988a3.867,3.867,0,0,0,3.73-3.887V5.276Z"
        transform="translate(0.75 0.762)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Stroke_3"
        data-name="Stroke 3"
        d="M0,0V2.909A2.575,2.575,0,0,0,2.569,5.484c1.316,0,2.663,0,2.754,0"
        transform="translate(10.975 0.75)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Stroke_5"
        data-name="Stroke 5"
        d="M.5,6.573V0"
        transform="translate(7.641 7.441)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Stroke_7"
        data-name="Stroke 7"
        d="M0,0,2.838,2.851,5.677,0"
        transform="translate(5.303 11.163)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  </SvgIcon>
);
