import { Route, Routes, useMatch, useNavigate } from "react-router-dom";
import { useContext, useEffect, useMemo, useState } from "react";

import { Alert, Tabs, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { difference, omit } from "lodash";

import { useAppParams, useAppTranslation } from "@hooks";
import { CARBS_ID, ENERGY_ID, FATS_ID, PROTEIN_ID } from "@utils/macros";
import { PATIENT_PROGRAM_DAYS_ALL, PROGRAM_DAYS_ALL } from "@routes";
import { WEEKDAYS_VALUES } from "@utils/weekdays";
import { useProgram } from "@hooks/resources/useProgram";
import { ProgramEmptyDietContext } from "@context";
import {
  useFetchClientBalanceQuery,
  useUpdateProgramDays,
  useUpdateProgramDaysInitial,
} from "@hooks/queries";
import { Spinner } from "@components/Spinner";
import { ProgramDayTab } from "@components/WeekdaySelectorTab";

import { PatientProgramDaysNavigation } from "./components/PatientProgramDaysNavigation";
import { PatientProgramDay } from "./PatientProgramDay";
import {
  ButtonStyled,
  HeadSection,
  StyledCard,
  StyledHeadAddBtn,
  StyledTab,
  TabsContainer,
  ViewWrapper,
} from "./PatientProgramDays.styled";

export const PatientProgramDays = () => {
  const { t, isPolishChosen } = useAppTranslation();
  const { hideWarningBorder } = useContext(ProgramEmptyDietContext);
  const { breakpoints } = useTheme();
  const navigate = useNavigate();
  const isUnmatchSmartphone = useMediaQuery(`${breakpoints.down("small")}`);
  const isMatchTablet = useMediaQuery(`${breakpoints.up("tablet")}`);
  const isMatchSmartphone = useMediaQuery(
    `${breakpoints.between("small", "tablet")}`,
  );

  const { patientId, programId } = useAppParams();
  const { program, isLoading, isFetching } = useProgram();

  const { mutate: putDay, isLoading: putDayLoading } =
    useUpdateProgramDays(programId);
  const { mutate: initDay } = useUpdateProgramDaysInitial(programId);

  const selectedDays = useMemo(
    () => program?.days.flatMap(day => day.frequency) ?? [],
    [program],
  );

  const { balanceData } = useFetchClientBalanceQuery(patientId, {
    enabled: !!patientId,
  });

  const handleAddDay = () => {
    hideWarningBorder();
    if (patientId && balanceData?.days.length && !program?.days.length) {
      initDay({
        days: balanceData?.days.map((day, idx) => ({
          id: null,
          name: day.translations.find(t => t.locale === "pl")?.name ?? "",
          nameEn: day.translations.find(t => t.locale === "en")?.name ?? "",
          frequency: day.frequency,
          norm: null,
          targetNutrients: [
            {
              id: ENERGY_ID,
              value: balanceData?.days[idx].suggestedEnergy.kcal ?? 0,
              visible: true,
            },
            {
              id: PROTEIN_ID,
              value: balanceData?.days[idx].suggestedEnergy.protein ?? 0,
              visible: true,
            },
            {
              id: FATS_ID,
              value: balanceData?.days[idx].suggestedEnergy.fat ?? 0,
              visible: true,
            },
            {
              id: CARBS_ID,
              value: balanceData?.days[idx].suggestedEnergy.carb ?? 0,
              visible: true,
            },
          ],
        })),
      });
      setDaysCount(balanceData.days.length);
    } else {
      putDay({
        days: [
          ...(program?.days.map(day =>
            omit(day, ["diet", "targetNutrients"]),
          ) ?? []),
          {
            id: null,
            name: program?.days.length ? "Kolejny dzień" : "Codziennie",
            nameEn: program?.days.length ? "Another day" : "Every day",
            frequency: difference(WEEKDAYS_VALUES, selectedDays),
          },
        ],
      });
      setDaysCount(1);
    }
  };

  const match = useMatch(
    patientId ? PATIENT_PROGRAM_DAYS_ALL : PROGRAM_DAYS_ALL,
  );

  useEffect(() => {
    if (!match?.params["*"]?.length && program?.days?.[0]?.id) {
      navigate(`${program?.days?.[0]?.id}`, { replace: true });
    }
  }, [match?.params["*"], program?.days?.[0]?.id]);

  useEffect(() => {
    if (program?.days.length === 0) {
      handleAddDay();
    } else setDaysCount(program?.days.length);
  }, [program, balanceData]);

  useEffect(() => {
    if (
      program?.days.findIndex(
        day => day.id.toString() === match?.params["*"],
      ) === -1 &&
      program?.days?.[0]?.id
    ) {
      navigate(`${program?.days?.[0]?.id}`, { replace: true });
    }
  }, [program?.days, match?.params["*"]]);

  const [daysCount, setDaysCount] = useState<number | undefined>(undefined);

  useEffect(() => {
    const isNewDayCreated =
      program?.days.length &&
      program.days[program.days.length - 1] &&
      daysCount &&
      daysCount < program.days.length;

    if (isNewDayCreated) {
      const latestDayId = program.days[program.days.length - 1].id;

      setDaysCount(program.days.length);
      navigate(`${latestDayId}`, { replace: true });
    }
  }, [program?.days.length]);

  if (isLoading) return <Spinner />;

  return (
    <ViewWrapper>
      {/*<EmptyDietWarningDialog/>*/}
      <HeadSection>
        <Typography variant="h5">
          {t("addNutritionalProgram.step2.dietSettings")}
        </Typography>
        {isMatchSmartphone && (
          <StyledHeadAddBtn
            size="small"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAddDay}
          >
            {t("program.days.add")}
          </StyledHeadAddBtn>
        )}
      </HeadSection>

      {isUnmatchSmartphone ? (
        <Alert severity="error">{t("common.device_to_narrow")}</Alert>
      ) : (
        <StyledCard>
          <TabsContainer>
            <Tabs
              color="prime"
              variant="scrollable"
              scrollButtons="auto"
              value={program?.days.findIndex(
                day => day.id.toString() === match?.params["*"],
              )}
              onChange={(_, value) => navigate(`${program?.days?.[value]?.id}`)}
              orientation={isMatchSmartphone ? "horizontal" : "vertical"}
            >
              {program?.days.map(day => (
                <StyledTab
                  key={day.id}
                  label={
                    <ProgramDayTab
                      title={isPolishChosen ? day.name : day.nameEn ?? day.name}
                      energy={
                        day.diet?.nutrients?.find(n => n.id === ENERGY_ID)
                          ?.value
                      }
                      cpm={
                        day.targetNutrients.nutrients.find(
                          n => n.id === ENERGY_ID,
                        )?.value
                      }
                      weekdays={day.frequency}
                      withTarget
                    />
                  }
                />
              ))}
            </Tabs>
            {isMatchTablet && (
              <ButtonStyled
                loading={putDayLoading || isFetching}
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleAddDay}
              >
                {t("program.days.add")}
              </ButtonStyled>
            )}
          </TabsContainer>

          <div className="flex-grow flex">
            <Routes>
              <Route path=":dayId" element={<PatientProgramDay />} />
            </Routes>
          </div>
        </StyledCard>
      )}

      <PatientProgramDaysNavigation />
    </ViewWrapper>
  );
};
