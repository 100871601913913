import { useRef } from "react";
import { useAppTranslation } from "@hooks";
import {
  accountUnreadNotificationQueryKey,
  useInfinityNotificationService,
} from "../hooks";
import { NotificationEmptyState } from "../NotificationEmptyState";
import image from "@assets/img/notificationUnreadEmptyState.png";
import {
  NotificationCard,
  NotificationSectionWrapper,
} from "@components/NotificationMenu/NotificationMenu.styled";
import { DateDivider } from "../NotificationHistory/components/DateDivider";
import { HistoryItem } from "../NotificationHistory";
import dayjs from "dayjs";
import { useInfiniteScroll } from "@hooks/helpers/useInfiniteScroll";
import { NotificationType } from "@client";
import { Spinner } from "@components/Spinner";

export const UnreadNotifications = () => {
  const { t } = useAppTranslation();

  const notificationCardRef = useRef<HTMLDivElement | null>(null);

  const { data, fetchNextPage, hasNextPage, isFetching, isLoading } =
    useInfinityNotificationService(accountUnreadNotificationQueryKey);

  const observeElement = useInfiniteScroll(fetchNextPage);

  let currentDate = "";

  if (!data?.pages[0].data.length && !isLoading) {
    return (
      <NotificationEmptyState
        content={t("notifications.unread_empty_state_content")}
        img={image}
      />
    );
  }

  if (isLoading) return <Spinner />;

  return (
    <NotificationSectionWrapper>
      <NotificationCard ref={notificationCardRef}>
        <div>
          {data?.pages.map(notifications => {
            return notifications?.data?.map(
              (notification: NotificationType) => {
                const { createdAt } = notification;
                const eventDate = dayjs(createdAt);

                if (currentDate !== eventDate.format("DD.MM")) {
                  currentDate = eventDate.format("DD.MM");

                  return (
                    <div key={currentDate}>
                      <DateDivider>
                        {eventDate.format("DD.MM.YYYY")}
                      </DateDivider>
                      <HistoryItem notification={notification} />
                    </div>
                  );
                }
                return (
                  <HistoryItem
                    key={eventDate.toString()}
                    notification={notification}
                  />
                );
              },
            );
          })}

          {hasNextPage && !isFetching && !isLoading && (
            <div ref={e => observeElement(e)} />
          )}
        </div>
      </NotificationCard>
    </NotificationSectionWrapper>
  );
};
