import { useMemo } from "react";
import { FormProvider } from "react-hook-form";

import {
  PregnantWomanAnswerResourceDto,
  PregnantWomanValue,
  SystemQuestionDto,
} from "@client/surveys";
import { useUpdateSingedSurveyMutation } from "@hooks/queries/surveys";

import { useAppTranslation } from "@hooks";
import { useUpdateValidationContext } from "../../context/useUpdateValidationContext";
import { useParseUrl } from "../../useParseUrl";
import { AutosaveOnBlurWatch } from "../AutosaveOnBlurWatch";
import { BlurContextProvider, FormAutocompleteWrapper } from "../BlurContext";
import { Question } from "../Question/Question";
import {
  PregnantWomanAnswerFormInputs,
  mapPregnantWomanAnswerForm,
  mapPregnantWomanAnswerRequest,
  usePregnantWomanAnswerForm,
} from "../forms";
import { TrimesterRadio } from "./TrimesterRadio";

interface SystemQuestionPregnantWomanProps {
  question: SystemQuestionDto;
  answer?: PregnantWomanAnswerResourceDto;
  listIndex: number;
  totalQuestions: number;
  readOnly?: boolean;
}

export const SystemQuestionPregnantWoman = ({
  question: {
    title,
    config: { required },
    id,
  },
  answer,
  readOnly,
  ...rest
}: SystemQuestionPregnantWomanProps) => {
  const { t } = useAppTranslation();
  const { url } = useParseUrl();
  const mappedForm = useMemo(
    () => mapPregnantWomanAnswerForm(answer?.answer),
    [answer?.answer],
  );
  const { formState, handleSubmit, control, ...form } =
    usePregnantWomanAnswerForm(mappedForm, required);
  const { mutate, isLoading } = useUpdateSingedSurveyMutation();

  const onSubmit = (data: PregnantWomanAnswerFormInputs) => {
    if (url) mutate({ payload: mapPregnantWomanAnswerRequest(data, id), url });
  };

  const { errors, touchedFields } = formState;
  const hasError = !!Object.values(errors).length;

  useUpdateValidationContext(id, touchedFields, handleSubmit);

  const allOptions = useMemo(
    () => [
      {
        id: `${PregnantWomanValue.LACK}`,
        label: t("questionnaires.questions.system_pregnant_woman.none"),
      },
      {
        id: `${PregnantWomanValue.PREGNANT}`,
        label: t("questionnaires.questions.system_pregnant_woman.pregnant"),
      },
      {
        id: `${PregnantWomanValue.LACTATION}`,
        label: t("questionnaires.questions.system_pregnant_woman.lactating"),
      },
    ],
    [t],
  );

  return (
    <FormProvider
      formState={formState}
      handleSubmit={handleSubmit}
      control={control}
      {...form}
    >
      <BlurContextProvider>
        <Question
          {...rest}
          title={title}
          description={null}
          required={required}
          hasError={hasError}
          readOnly={readOnly}
        >
          <FormAutocompleteWrapper
            blurOnSelect
            control={control}
            name="value"
            id="value"
            options={allOptions}
            size="small"
            readOnly={readOnly}
            placeholder={t(
              "questionnaires.questions.system_pregnant_woman.placeholder",
            )}
            hideHelperText
            InputProps={{
              readOnly,
              error: !readOnly && hasError,
            }}
          />
          <TrimesterRadio />
        </Question>

        <AutosaveOnBlurWatch onSubmit={onSubmit} isLoading={isLoading} />
      </BlurContextProvider>
    </FormProvider>
  );
};
