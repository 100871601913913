import { useMemo } from "react";

import { ArrowRight } from "@assets/icons/Arrows";
import { mapUserChips, UserChips } from "@components/ExpandableChips";
import { useMealClientContext } from "@hooks";

import {
  AccordionDetailsStyled,
  AccordionStyled,
  AccordionSummaryStyled,
  Title,
} from "./RemovedProductAccordion.styled";
import { RemovedProductAccordionContent } from "./RemovedProductAccordionContent";

interface RemovedProductAccordionProps {
  from: number;
  previousName: string;
}
export const RemovedProductAccordion = ({
  from,
  previousName,
}: RemovedProductAccordionProps) => {
  const { productsClientContextMap } = useMealClientContext();
  const clientContext = useMemo(
    () => productsClientContextMap.get(from),
    [productsClientContextMap, from],
  );
  const userTags = useMemo(
    () =>
      mapUserChips({
        disliked: clientContext?.hasUnliked,
        liked: clientContext?.hasLiked,
        allergen: clientContext?.hasAllergens,
      }),
    [clientContext],
  );

  return (
    <AccordionStyled TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummaryStyled
        expandIcon={<ArrowRight size="w-[16px] h-[16px]" />}
      >
        <Title>{previousName}</Title>
        <UserChips tags={userTags} />
      </AccordionSummaryStyled>

      <AccordionDetailsStyled>
        <RemovedProductAccordionContent from={from} />
      </AccordionDetailsStyled>
    </AccordionStyled>
  );
};
