import { SectionWrapper, TopPart } from "./RecipeCompositionSection.styled";
import { useAppTranslation } from "@hooks";
import { Autocomplete, TextField, Typography, useTheme } from "@mui/material";
import { FieldValues } from "react-hook-form";
import { CheckedIcon } from "@assets/icons";
import { RecipeTable } from "./RecipeTable";
import { omit, range } from "lodash";
import { Nutrient } from "@typeDefinitions";
import { useMemo, useState } from "react";
import { SelectOption } from "@components/FormAutocomplete";
import { SearchNoResults } from "@views/emptyStates/SearchNoResults";

interface RecipeCompositionSectionProps {
  numOfPortions: number;
  nutrients: Nutrient[];
  rows: Row[];
}

export const RecipeCompositionSection = ({
  numOfPortions,
  rows,
  nutrients,
}: RecipeCompositionSectionProps) => {
  const { t } = useAppTranslation();
  const { palette } = useTheme();
  const defaultServings = {
    id: `${numOfPortions}`,
    label: `${numOfPortions}/${numOfPortions}`,
  };
  const [selectedServings, setSelectedServing] =
    useState<SelectOption>(defaultServings);

  const optionsArray: SelectOption[] = useMemo(
    () =>
      range(1, numOfPortions + 1).map(num => ({
        id: `${num}`,
        label: `${num}/${numOfPortions}`,
      })),
    [numOfPortions],
  );

  return (
    <SectionWrapper>
      <TopPart>
        <Typography variant="h6">
          {t("recipe.ingredients_table.composition")}
        </Typography>
        <Autocomplete
          noOptionsText={<SearchNoResults />}
          disabled={numOfPortions === 1}
          disableClearable
          size="small"
          disablePortal
          options={optionsArray}
          value={selectedServings}
          onChange={(_, value) => setSelectedServing(value ?? defaultServings)}
          sx={{ width: 300 }}
          popupIcon={<CheckedIcon fill={palette.primary.main} />}
          renderInput={params => (
            <TextField
              {...omit(params, "InputLabelProps")}
              label={t("recipe_new.number_of_portions")}
            />
          )}
        />
      </TopPart>
      {!!rows.length && (
        <RecipeTable
          rows={rows}
          portionRatio={Number(selectedServings.id) / numOfPortions}
          recipeNutrients={nutrients}
        />
      )}
      {!rows.length && (
        <Typography variant="body2" color="grey" className="text-center">
          {t("recipe_new.no_products")}
        </Typography>
      )}
    </SectionWrapper>
  );
};

export interface Row {
  id: number;
  grams: number;
  foodMeasureId: number;
  food: Product;
}

export interface Product {
  id: number;
  description: string | null;
  descriptionPl: string | null;
  nutrients: Nutrient[];
  measures: Measure[];
}

export interface Measure {
  id: number;
  grams: number;
}

export const visibleNutrients = [];
