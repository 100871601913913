import { useNavigate } from "react-router-dom";
import { Spinner } from "@components/Spinner";
import { useModal } from "@hooks";
import { useFetchClientsQuery } from "@hooks/queries";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";
import { DIETICIAN_SETTINGS } from "@routes";
import { DashboardEmptyState } from "@views/emptyStates/Dashboard";
import { AddClientModal } from "@components/AddClientModalNew";
import { ThemeProviderWrapperNew } from "themeNew";
import { HomeWrapper } from "./Home.styled";
import { useMediaQuery, useTheme } from "@mui/material";
import { LaunchBannerSmall } from "./components/LaunchBannerSmall";
import { getSubscriptionInfo } from "@utils/subscription";
import { MiniCalendar } from "./components/Calendar/MiniCalendar/MiniCalendar";
import { ScheduleVisitModal } from "./components/ScheduleVisit/ScheduleVisitModal";
import { ScheduleVisitModalProvider } from "./components/ScheduleVisit/ScheduleVisitModalContext";
import { ScheduleActionModal } from "./components/ScheduleVisit/_components/ScheduleActionModal";
import { CalendarFiltersProvider } from "./components/Calendar/_hooks/CalendarFilters.context";
import { CalendarColorsProvider } from "../Calendar";
import { LaunchBanner } from "./components/LaunchBanner";
import { CloseableBanner } from "./components/CloseableBanner/CloseableBanner";
import { LaunchModal } from "./components/LaunchModal";

export const Home = () => {
  const { account } = useFetchDietitianAccountQuery();
  const { breakpoints } = useTheme();
  const { hasSubscription, isTrial } = getSubscriptionInfo();
  const isUpMedium = useMediaQuery(`${breakpoints.up("medium")}`);
  const enabled = !!account?.clinic?.id && hasSubscription !== null;
  const navigate = useNavigate();

  const { isLoading } = useFetchClientsQuery(undefined, {
    enabled,
    keepPreviousData: true,
  });

  const { modalOpened, onModalClose, onModalOpen } = useModal();
  const {
    modalOpened: visitOpen,
    onModalClose: onClose,
    onModalOpen: onOpen,
  } = useModal();
  const ThreeDays = 3;

  const NotificationBannerOpened =
    ((hasSubscription && hasSubscription <= ThreeDays) ||
      hasSubscription === 0) &&
    isTrial;

  if (isLoading) return <Spinner />;

  return (
    <>
      <ThemeProviderWrapperNew>
        <CloseableBanner />
        <LaunchModal />
      </ThemeProviderWrapperNew>
      <HomeWrapper heightWithBanner={NotificationBannerOpened}>
        <DashboardEmptyState
          onAddClient={onModalOpen}
          onAddVisit={onOpen}
          onClickCompanyData={() => navigate(DIETICIAN_SETTINGS)}
        />
        <ScheduleVisitModalProvider>
          <ThemeProviderWrapperNew>
            <CalendarColorsProvider>
              <CalendarFiltersProvider>
                <MiniCalendar enabled={enabled} />
              </CalendarFiltersProvider>
              <AddClientModal open={modalOpened} onClose={onModalClose} />
              <ScheduleVisitModal />
              <ScheduleActionModal />
            </CalendarColorsProvider>
          </ThemeProviderWrapperNew>
        </ScheduleVisitModalProvider>
      </HomeWrapper>
    </>
  );
};
