import { CategoriesSectionsResponse } from "@client";

export const getDefaultProgramSections = (
  sections: Array<CategoriesSectionsResponse>,
) => {
  const sectionIds: Array<number> = [];

  for (const section of sections) {
    for (const template of section.templates) {
      if (template.isSystemic) {
        sectionIds.push(template.id);
      }
    }
  }

  // It should come from BE
  const order = [32, 7, 160, 8, 9];
  const orderMap = new Map();
  order.forEach((num, index) => orderMap.set(num, index));

  return sectionIds.sort((a, b) => {
    if (orderMap.has(a) && orderMap.has(b)) {
      return orderMap.get(a) - orderMap.get(b);
    }
    if (orderMap.has(a)) return -1;
    if (orderMap.has(b)) return 1;
    return a - b;
  });
};
