import { useAppTranslation, useSectionParams } from "@hooks";
import { SingleCardPageLayout } from "@components/PageLayout";

import { SectionDetails } from "./components";

export const Section = () => {
  const sectionId = useSectionParams();
  const { t } = useAppTranslation();

  return (
    <SingleCardPageLayout title={t("sections.title")}>
      <SectionDetails sectionId={sectionId} showHeader />
    </SingleCardPageLayout>
  );
};
