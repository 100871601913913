import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";
import { LangDto } from "@client";

export const updateDietitianAccount = (payload: UpdateDietitianAccount) => {
  return fetchData("/dietitian/account", APIMethods.PUT, payload);
};

export interface UpdateDietitianAccount {
  firstName: string;
  lastName: string;
  phone: PhoneDto;
  language: LangDto;
}

interface PhoneDto {
  prefix: string | null;
  number: string | null;
}
