import { Control, useController } from "react-hook-form";
import { ReactNode } from "react";

import { Checkbox, CheckboxProps, useTheme } from "@mui/material";

import { StyledFormControlLabel } from "./FormCheckboxMui.styled";
import { CheckboxChecked, CheckboxUnChecked } from "@assets/icons";

export interface FormCheckboxMuiProps
  extends Omit<CheckboxProps, "value" | "onChange"> {
  control?: Control<any>;
  name: string;
  label?: ReactNode;
  icon?: ReactNode;
  checkedIcon?: ReactNode;
  rebranding?: boolean;
  error?: boolean;
}

export const FormCheckboxMui = ({
  control,
  name,
  label,
  icon,
  checkedIcon,
  rebranding,
  error,
  ...rest
}: FormCheckboxMuiProps) => {
  const {
    field: { value, onChange },
  } = useController({ control, name });
  const { palette } = useTheme();

  return label ? (
    <StyledFormControlLabel
      control={
        <Checkbox
          key={value}
          checkedIcon={rebranding ? <CheckboxChecked /> : checkedIcon}
          icon={
            rebranding ? (
              <CheckboxUnChecked
                fill={error ? palette.error.main : undefined}
              />
            ) : (
              icon
            )
          }
          checked={value}
          onChange={onChange}
          {...rest}
        />
      }
      label={label}
      labelPlacement="end"
    />
  ) : (
    <Checkbox
      checkedIcon={rebranding ? <CheckboxChecked /> : checkedIcon}
      icon={rebranding ? <CheckboxUnChecked /> : icon}
      checked={value}
      onChange={onChange}
      {...rest}
    />
  );
};
