import { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { FieldValues, UseFormHandleSubmit } from "react-hook-form";

import _ from "lodash";

import { TableOfContentsContext } from "@context/TableOfContentsContext";
import { usePostSingedSurveyReportMutation } from "@hooks/queries/surveys";

import {
  QuestionContextIf,
  ValidationContext,
  ValidationContextIf,
} from "./ValidationContext";
import { useParseUrl } from "../useParseUrl";

interface ValidationContextProviderProps {
  children: ReactNode;
  questions: QuestionContextIf[];
  handleShowOutro: () => void;
}

export const ValidationContextProvider = ({
  children,
  questions,
  handleShowOutro,
}: ValidationContextProviderProps) => {
  const [answersList, setAnswersList] =
    useState<QuestionContextIf[]>(questions);
  const { setGroupId, setSubgroupId, setSelectedId } = useContext(
    TableOfContentsContext,
  );
  const [formsListValid, setFormsListValid] = useState<boolean[]>([]);
  const { reportUrl } = useParseUrl();
  const { mutate } = usePostSingedSurveyReportMutation();

  const handleSubmitForms = () => {
    _(answersList)
      .sortBy(["groupId", "subgroupId"])
      .reverse()
      .forEach(async ({ handleSubmit, groupId, subgroupId, questionId }) => {
        if (!handleSubmit) {
          handleTouched(questionId, true);
          setGroupId(groupId);
          setSubgroupId(subgroupId);
          setSelectedId(`${groupId}-${subgroupId}`);
          setFormsListValid(list => [...list, false]);

          return;
        }

        await handleSubmit(
          () => {
            setFormsListValid(list => [...list, true]);
          },
          () => {
            handleTouched(questionId, true);
            setGroupId(groupId);
            setSubgroupId(subgroupId);
            setSelectedId(`${groupId}-${subgroupId}`);
            setFormsListValid(list => [...list, false]);
          },
        )();
      });
  };

  useEffect(() => {
    if (!formsListValid.length || formsListValid.length !== answersList.length)
      return;
    if (formsListValid.every(value => value) && reportUrl)
      mutate({ url: reportUrl }, { onSuccess: handleShowOutro });

    setFormsListValid([]);
  }, [formsListValid]);

  const updateAnswerControl = (
    groupId: number,
    subgroupId: number,
    questionId: number,
    handleSubmit: UseFormHandleSubmit<FieldValues>,
  ) => {
    setAnswersList(state => {
      const isTouchedState = state.find(
        el => el.questionId === questionId,
      )?.isTouched;
      return [
        ...state.filter(el => el.questionId !== questionId),
        {
          groupId,
          subgroupId,
          questionId,
          handleSubmit,
          isTouched: !!isTouchedState,
        },
      ];
    });
  };

  const handleTouched = (questionId: number, isTouched: boolean) => {
    setAnswersList(state => {
      const editedQuestion = state.find(q => q.questionId === questionId);

      if (editedQuestion)
        return [
          ...state.filter(q => q.questionId !== questionId),
          { ...editedQuestion, isTouched },
        ];
      return state;
    });
  };

  const getIsTouched = (questionId: number) =>
    !!answersList.find(answer => answer.questionId === questionId)?.isTouched;

  const value: ValidationContextIf = useMemo(
    () => ({
      answersList: [],
      handleSubmit: handleSubmitForms,
      updateAnswerControl,
      handleTouched,
      getIsTouched,
    }),
    [answersList],
  );

  return (
    <ValidationContext.Provider value={value}>
      {children}
    </ValidationContext.Provider>
  );
};
