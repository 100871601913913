import { ChangeEvent, useMemo } from "react";

import { Checkbox, CircularProgress } from "@mui/material";

import { TranslationDto } from "@client";
import { AutosaveOnBlurProvider } from "@components/AutosaveOnBlur";
import { useAppParams, useAppTranslation } from "@hooks";
import { useFetchCreatorDietMealsQuery } from "@hooks/queries/diets/creator";
import { Nutrient } from "@typeDefinitions";
import { SAFARI_TAB_INDEX } from "@utils";

import { CardWrapper } from "../common";
import { AddRowButton } from "./AddRowButton";
import { CheckboxLabel } from "./MealScheduleCard.styled";
import { ScheduleRow } from "./ScheduleRow";
import { SummaryRow } from "./SummaryRow";
import { useShowMacros } from "./useShowMacros";

interface MealScheduleCardProps {
  targetNutrients: Nutrient[];
  showMacros: boolean;
}
export const MealScheduleCard = ({
  targetNutrients,
  showMacros: showMacrosApi,
}: MealScheduleCardProps) => {
  const { t } = useAppTranslation();
  const { dietId } = useAppParams();
  const { meals, isFetching } = useFetchCreatorDietMealsQuery(dietId);
  const { updateShowMacros, showMacros, isLoading } =
    useShowMacros(showMacrosApi);

  const summaryRow = useMemo(
    () => (
      <SummaryRow
        showMacros={Boolean(showMacros)}
        meals={meals}
        targetNutrients={targetNutrients}
      />
    ),
    [showMacros, meals, targetNutrients],
  );

  const isCalculating = isFetching && Boolean(showMacros);

  const handleCheckbox = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    updateShowMacros(checked);
  };

  return (
    <CardWrapper
      title={t("diet.meal_schedule")}
      extras={
        <div className="flex items-center">
          <Checkbox
            checked={Boolean(showMacros)}
            onChange={handleCheckbox}
            disabled={isLoading}
            tabIndex={SAFARI_TAB_INDEX}
          />
          <CheckboxLabel>{t("diet.show_macronurtients")}</CheckboxLabel>
        </div>
      }
    >
      {isCalculating && (
        <div className="grid place-items-center">
          <CircularProgress />
        </div>
      )}

      {!isCalculating &&
        meals?.map((m, idx) => (
          <AutosaveOnBlurProvider key={m.id}>
            <ScheduleRow
              showLabel={!idx}
              showMacros={Boolean(showMacros)}
              data={m}
            />
          </AutosaveOnBlurProvider>
        ))}

      {summaryRow}
      <AddRowButton />
    </CardWrapper>
  );
};
