import { forwardRef, SyntheticEvent, useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";

import { useAppTranslation } from "@hooks";
import { Input, InputProps } from "@components/Input";
import { InputEndIcon } from "@components/InputEndIcon";
import { Calendar, Chevron } from "@assets/icons";
import pl from "date-fns/locale/pl";

import { parseISO } from "date-fns";

registerLocale("pl", pl);

/**
 * universal datepicker component
 */
export const Datepicker = forwardRef<HTMLInputElement, DatepickerProps>(
  (props, ref) => {
    const {
      onChange,
      start,
      end,
      minDate,
      maxDate,
      variant = "standard",
      onFocus,
      onBlur,
      selected,
      noStartDate = false,
      disabled = false,
      ...inputProps
    } = props;

    const { i18n } = useAppTranslation();

    const [startDate, setStartDate] = useState<Date | null>(
      noStartDate ? null : start ? start : new Date(),
    );
    const [endDate, setEndDate] = useState<Date | null>(end || null);
    const [focused, setFocus] = useState(false);

    useEffect(() => {
      onChange && onChange(endDate ? [startDate, endDate] : startDate);
    }, [startDate, endDate]);

    const handleChange = (date: Date | null) => {
      if (date instanceof Date) setStartDate(date);
    };

    const handleFocus = (e: SyntheticEvent<HTMLInputElement>) => {
      setFocus(true);
      onFocus && onFocus(e);
    };

    const handleBlur = (e: SyntheticEvent<any>) => {
      setFocus(false);
      onBlur && onBlur(e);
    };

    return (
      <>
        <DatePicker
          open={focused}
          onClickOutside={handleBlur}
          dateFormat="dd.MM.yyyy"
          calendarStartDay={1}
          selected={
            selected
              ? typeof selected === "string"
                ? parseISO(selected)
                : selected
              : startDate
          }
          onChange={handleChange}
          // startDate={startDate}
          // endDate={endDate}
          minDate={minDate}
          maxDate={maxDate}
          nextMonthButtonLabel={<Chevron className="transform -rotate-90" />}
          previousMonthButtonLabel={<Chevron className="transform rotate-90" />}
          locale={i18n.language}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={disabled}
          customInput={
            <Input
              ref={ref}
              focused={focused}
              variant={variant}
              suffix={
                <InputEndIcon
                  focused={focused}
                  disabled={disabled}
                  variant={variant}
                  {...inputProps}
                  icon={<Calendar />}
                  onMouseUp={() => {
                    setFocus(!focused);
                  }}
                />
              }
              {...inputProps}
            />
          }
        />
      </>
    );
  },
);

export interface DatepickerProps extends InputProps {
  /** start date */
  start?: Date;
  /** end date */
  end?: Date;
  /** minimal date value */
  minDate?: Date;
  /** max date value */
  maxDate?: Date;
  /** date seleceted */
  selected?: any;
  /** change date handler */
  onChange?: (date: Date | [Date | null, Date | null] | null) => void;
  /** Determines whether datepicker should not automatically pick date on render */
  noStartDate?: boolean;
}
