import { MutationOptions } from "@hooks/queries";
import { NoContentApiResponse } from "@typeDefinitions";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { changeSharedProgram } from "@client/patient/programs/changeSharedProgram";
import { patientProgramsSearchKeys } from "@hooks/queries/client/program/useFetchClientPrograms";

export type DataType = {
  patientId: number;
  programId: number;
  payload: {
    shared: boolean;
  };
};
export const useChangeSharedProgramMutation = (
  options?: MutationOptions<NoContentApiResponse>,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ patientId, programId, payload }: DataType) =>
      changeSharedProgram({ patientId, programId, payload }),
    ...options,
    onSuccess: async (data, variables) => {
      options?.onSuccess && options.onSuccess(data, variables);
      await queryClient.invalidateQueries(
        patientProgramsSearchKeys.base(variables.patientId),
      );
    },
  });
};
