import { ClientProfileCardItem } from "@views/dietician/ClientProfile/components/ClientProfileCardItem";
import { SectionWrapper } from "../../EditClientMeasurementModal.styled";
import { FormTextField } from "@components/FormTextField";
import { Control } from "react-hook-form";
import {
  Sexes,
  useAppTranslation,
  useMapClientAutocompleteOptions,
} from "@hooks";
import { decimalInput1ThreeDigits } from "@utils/inputs";
import { useFetchClientTargetsQuery } from "@hooks/queries";
import { FormSelect } from "@components/FormSelectNew";
import { MenuItem } from "@mui/material";
import { Spinner } from "@components/Spinner";

interface GoalsSectionProps {
  control: Control<any>;
}

export const GoalsSection = ({ control }: GoalsSectionProps) => {
  const { t } = useAppTranslation();
  const { targets, isLoading, isSuccess } = useFetchClientTargetsQuery();
  const { mapOptions } = useMapClientAutocompleteOptions(Sexes.m);

  const goalList = mapOptions(targets);

  if (isLoading) return <Spinner />;

  return (
    <SectionWrapper>
      <ClientProfileCardItem
        title={t("client_profile.health.goals")}
        extraEl={
          <FormSelect control={control} name="goal" id="goal" size="small">
            {goalList?.map(param => (
              <MenuItem value={param.id} key={param.label}>
                {param.label}
              </MenuItem>
            ))}
          </FormSelect>
        }
      />
      <ClientProfileCardItem
        title={t("client_profile.health.target_body_weight")}
        extraEl={
          <FormTextField
            control={control}
            name="targetWeight"
            size="small"
            variant="outlined"
            InputProps={{
              inputComponent: decimalInput1ThreeDigits,
            }}
          />
        }
      />
    </SectionWrapper>
  );
};
