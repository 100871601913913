import {
  SystemQuestionDto,
  UserHeightAnswerResourceDto,
} from "@client/surveys";
import { OuterLabel } from "@components/OuterLabel";
import { useAppTranslation } from "@hooks";

import { QuestionPreview } from "../../QuestionPreview/QuestionPreview";
import { StyledAnswer } from "./SystemAnswerUserHeight.styled";

interface SystemAnswerUserHeightProps {
  question: SystemQuestionDto;
  answer?: UserHeightAnswerResourceDto;
  listIndex: number;
  totalQuestions: number;
}

export const SystemAnswerUserHeight = ({
  answer,
  question,
  listIndex,
  totalQuestions,
}: SystemAnswerUserHeightProps) => {
  const { title, description } = question;
  const { t } = useAppTranslation();

  return (
    <QuestionPreview
      title={title}
      description={description}
      listIndex={listIndex}
      totalQuestions={totalQuestions}
      systemQuestion
    >
      <OuterLabel label={t("questionnaires.questions.answer")}>
        <StyledAnswer>
          {answer?.answer.value
            ? `${answer.answer.value} cm`
            : t("questionnaires.questions.no_answer")}
        </StyledAnswer>
      </OuterLabel>
    </QuestionPreview>
  );
};
