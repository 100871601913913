import { Button, Card, Popper, styled } from "@mui/material";

export const CardStyled = styled(Card)`
  display: flex;
  flex-wrap: wrap;
  width: max-content;
  max-width: 380px;
  padding: 16px;
  gap: 8px;
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.boxShadows.card};
`;

export const TagsWrapper = styled("div")`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  gap: 8px;
`;

export const ExpandableMealTagsStyled = styled("div")`
  display: flex;
  gap: 8px;
  overflow: hidden;
`;

export const ButtonStyled = styled(Button)`
  font-size: 12px;
  line-height: 20px;
  height: 20px;
  width: unset;
  min-width: unset;
  padding: unset;
`;

export const PopperStyled = styled(Popper)`
  z-index: 1500;
`;
