import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import { TaskDeadlineDatePicker } from "../TaskDeadlineDatePicker";
import { LinkAnchor } from "@assets/icons";
import { Close } from "@assets/icons";
import { FormProvider } from "react-hook-form";
import { TaskTagsComboBox } from "../TaskTagsComboBox";
import { FormAutocomplete } from "@components/FormAutocomplete";
import { Person } from "@assets/icons";
import { TaskPrioritySelect } from "../TaskPrioritySelect";
import { useCreateTaskForm } from "@hooks/tasks/useCreateTaskForm";
import type { CreateTaskFormSchema } from "@hooks/tasks/useCreateTaskForm";
import { useTasksContext } from "../../Tasks.context";
import {
  TaskCreationStyledLabel,
  TaskCreationStyledBox,
  TaskCreationStyledTextField,
} from "./TasksCreateModal.styled";
import { useAppTranslation } from "@hooks";
import { useCallback, useMemo } from "react";
import { ExclamationMarkInCircle } from "@assets/icons";
import { useTaskFilterOptions } from "@hooks/tasks/useTaskFilterOptions";
import { useWatch } from "react-hook-form";
import debounce from "lodash/debounce";

export const TasksCreateModal = () => {
  const { onCreateTaskSubmit, form } = useCreateTaskForm();
  const { t } = useAppTranslation();

  const {
    dietitiansOptionsForAdmin,
    clientsOptions,
    handleSearchClients,
    handleClearSearchedClients,
  } = useTaskFilterOptions();

  const { handleCloseCreateModal, isCreateModalOpen, currentTaskId } =
    useTasksContext();

  const onCreateTaskSubmitWithClose = useCallback(
    async (data: CreateTaskFormSchema) => {
      await onCreateTaskSubmit(data);
      handleCloseCreateModal();
    },
    [onCreateTaskSubmit, handleCloseCreateModal],
  );

  const handleCloseModalAndResetForm = () => {
    form.reset();
    handleClearSearchedClients();
    handleCloseCreateModal();
  };

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid },
  } = form;

  const description = useWatch({
    control,
    name: "description",
  });

  const minRows = 4;
  const charPerRow = 64;

  const getDescriptionRows = (text: string) =>
    Math.max(minRows, Math.ceil(text.length / charPerRow));

  const debouncedGetDescriptionRows = useMemo(
    () => debounce(getDescriptionRows, 300),
    [],
  );

  const validation = currentTaskId ? isDirty && isValid : isValid;
  const isSubmitButtonDisabled = !validation || isSubmitting;

  return (
    <Dialog open={isCreateModalOpen} className="max-w-2xl mx-auto" fullWidth>
      <FormProvider {...form}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <DialogTitle id="task-create-dialog-title">
            {t("tasks.taskCreateModal.title")}
          </DialogTitle>
          <IconButton onClick={handleCloseModalAndResetForm}>
            <Close />
          </IconButton>
        </Box>
        <DialogContent sx={{ padding: 0 }}>
          <form onSubmit={handleSubmit(onCreateTaskSubmitWithClose)}>
            <Box display="flex" flexDirection="column" gap={2} paddingX={2}>
              <Box display="flex" flexDirection="column">
                <TaskCreationStyledLabel htmlFor="task-name">
                  {t("tasks.taskCreateModal.nameLabel")}
                </TaskCreationStyledLabel>
                <TaskCreationStyledTextField
                  id="task-name"
                  name="name"
                  control={control}
                  size="small"
                  placeholder={t("tasks.taskCreateModal.namePlaceholder")}
                  required
                  errorIcon={<ExclamationMarkInCircle />}
                  inputProps={{ maxLength: 55 }}
                />
              </Box>
              <div className="flex flex-col custom-scrollbar custom-scrollbar-gray">
                <TaskCreationStyledLabel htmlFor="task-description">
                  {t("tasks.taskCreateModal.descriptionLabel")}
                </TaskCreationStyledLabel>
                <TaskCreationStyledTextField
                  id="task-description"
                  name="description"
                  control={control}
                  size="small"
                  multiline
                  rows={debouncedGetDescriptionRows(description ?? "")}
                  placeholder={t(
                    "tasks.taskCreateModal.descriptionPlaceholder",
                  )}
                  inputProps={{ maxLength: 1000 }}
                />
              </div>
              <Box display="flex" flexDirection="column">
                <TaskCreationStyledLabel htmlFor="task-tags">
                  {t("tasks.taskCreateModal.tagLabel")}
                </TaskCreationStyledLabel>
                <TaskTagsComboBox />
              </Box>
              <Box display="flex" flexDirection="column">
                <TaskCreationStyledLabel htmlFor="task-related-link">
                  {t("tasks.taskCreateModal.linkLabel")}
                </TaskCreationStyledLabel>
                <TaskCreationStyledTextField
                  id="task-related-link"
                  name="relatedLink"
                  control={control}
                  placeholder={t("tasks.taskCreateModal.linkPlaceholder")}
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <TaskCreationStyledBox>
                        <LinkAnchor className="text-3xl text-gray-450" />
                        <Typography variant="body2" ml={1}>
                          https://
                        </Typography>
                      </TaskCreationStyledBox>
                    ),
                  }}
                />
              </Box>
              <Box display="flex" flexDirection="column">
                <TaskCreationStyledLabel htmlFor="task-dietitian">
                  {t("tasks.taskCreateModal.assignToLabel")}
                </TaskCreationStyledLabel>
                <FormAutocomplete
                  id="task-create-dietitian"
                  options={dietitiansOptionsForAdmin ?? []}
                  name="dietitianId"
                  control={control}
                  placeholder={t("tasks.taskCreateModal.assignToPlaceholder")}
                  size="small"
                  startIcon={<Person className="text-2xl text-gray-450" />}
                />
              </Box>
              <Box display="flex" gap={3}>
                <Box display="flex" flexDirection="column" flex={1}>
                  <TaskCreationStyledLabel htmlFor="task-deadline">
                    {t("tasks.taskCreateModal.deadlineLabel")}
                  </TaskCreationStyledLabel>
                  <TaskDeadlineDatePicker />
                </Box>
                <Box display="flex" flexDirection="column" flex={1}>
                  <TaskCreationStyledLabel htmlFor="task-priority">
                    {t("tasks.taskCreateModal.priorityLabel")}
                  </TaskCreationStyledLabel>
                  <TaskPrioritySelect />
                </Box>
              </Box>

              <Box display="flex" flexDirection="column">
                <TaskCreationStyledLabel htmlFor="task-client">
                  {t("tasks.taskCreateModal.clientLabel")}
                </TaskCreationStyledLabel>
                <FormAutocomplete
                  id="task-create-client"
                  options={clientsOptions ?? []}
                  name="clientId"
                  control={control}
                  onBlur={handleClearSearchedClients}
                  placeholder={t("tasks.taskCreateModal.clientPlaceholder")}
                  size="small"
                  startIcon={<Person className="text-2xl" />}
                  onSearch={handleSearchClients}
                />
              </Box>
            </Box>
            <DialogActions sx={{ marginTop: 4 }}>
              <Button
                onClick={handleCloseModalAndResetForm}
                color="primary"
                variant="outlined"
              >
                {t("tasks.taskCreateModal.cancelButton")}
              </Button>
              <Button
                disabled={isSubmitButtonDisabled}
                type="submit"
                color="primary"
                variant="contained"
                aria-hidden={false}
              >
                {t("tasks.taskCreateModal.saveButton")}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </FormProvider>
    </Dialog>
  );
};
