import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const RadioEmpty = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16">
    <rect
      x="0.5"
      y="0.5"
      width="15"
      height="15"
      rx="7.5"
      fill="none"
      stroke={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
