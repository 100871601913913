import { useEffect, useMemo } from "react";

import { CircularProgress } from "@mui/material";
import { isEqual } from "lodash";

import { useAppParams, useBalanceClientValues } from "@hooks";
import {
  FetchClientBalanceResponse,
  UpdateClientBalanceRequest,
} from "@client";
import {
  useFetchClientBalanceQuery,
  useUpdateClientBalanceMutation,
} from "@hooks/queries";
import { usePatientForBalance } from "@components/CaloricNeedsForm/components";
import { MacrosSliderMacros, MacrosSliders } from "@components/MacrosSliders";

import {
  DEFAULT_SLIDER_VALUES,
  mapBalanceWithMacrosSlider,
  mapMacrosForGoal,
} from "./balanceUtils";

function updateBalanceDataWithMacrosSlider(
  balanceData: FetchClientBalanceResponse,
  data: MacrosSliderMacros,
  dayId: string,
): UpdateClientBalanceRequest {
  return mapBalanceWithMacrosSlider(dayId, balanceData, data);
}

export const BalanceMacrosSliders = () => {
  const { patientId, dayId } = useAppParams();

  const { balanceData, isLoading } = useFetchClientBalanceQuery(patientId);
  const balanceDay = balanceData?.days.find(day => day.id.toString() === dayId);
  const { client, isLoading: isClientLoading } = usePatientForBalance();
  const { cpmAvg } = useBalanceClientValues();
  const weightLose =
    !!client?.profile.weight &&
    !!client?.profile.targetWeight &&
    client?.profile.weight > client?.profile.targetWeight;
  const { mutate: updateBalance } = useUpdateClientBalanceMutation(patientId);

  const handleChange = (data: MacrosSliderMacros) => {
    if (balanceData) {
      updateBalance(
        updateBalanceDataWithMacrosSlider(balanceData, data, dayId),
      );
    }
  };

  const values = useMemo(
    () => mapMacrosForGoal(balanceDay?.suggestedEnergy, cpmAvg, weightLose),
    [balanceDay],
  );

  useEffect(() => {
    const shouldSetDefaultValues =
      balanceDay &&
      balanceDay.suggestedEnergy.kcal === null &&
      !isEqual(values, DEFAULT_SLIDER_VALUES);

    if (shouldSetDefaultValues) {
      handleChange({
        energy: values.kcal,
        fat: values.fat,
        carbs: values.carbs,
        protein: values.protein,
      });
    }
  }, [values]);

  if (isLoading || isClientLoading) return <CircularProgress />;

  return (
    <MacrosSliders
      onChange={handleChange}
      defaultValues={values}
      showNorms={false}
      clientWeight={client?.profile.weight ?? null}
    />
  );
};
