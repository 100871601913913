import * as yup from "yup";

import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import {
  BalanceActivityDaily,
  BalanceActivityTraining,
  BalanceFormula,
  BalanceType,
  BalanceValueMethod,
  FrequencyDay,
} from "@utils/balance";
import { LangDto } from "./common";

export const fetchClientBalance = async (
  patientId: string,
): Promise<ApiResponse<FetchClientBalanceResponse>> => {
  const response = await fetchData(
    `/dietitian/patients/${patientId}/energy-balance`,
    APIMethods.GET,
  );
  return fetchClientBalanceResponseSchema.validate(response);
};

const valueMethodSchema = yup.object().shape({
  value: yup.number().nullable().default(null),
  method: yup.number().oneOf([1, 2]).nullable().default(null),
});

const translationSchema = yup.object().shape({
  locale: yup.mixed<LangDto>().oneOf(Object.values(LangDto)).required(),
  name: yup.string().nullable().default(null),
});

const suggestedEnergySchema = yup.object().shape({
  kcal: yup.number().nullable().default(null),
  protein: yup.number().nullable().default(null),
  carb: yup.number().nullable().default(null),
  fat: yup.number().nullable().default(null),
});

const activitySchema = yup.object().shape({
  daily: yup
    .mixed<BalanceActivityDaily>()
    .oneOf([1, 2, 3, 4])
    .nullable()
    .default(null),
  training: yup
    .mixed<BalanceActivityTraining>()
    .oneOf([1, 2, 3, 4, 5])
    .nullable()
    .default(null),
});

const energyDemandPalSchema = yup.object().shape({
  pattern: yup
    .mixed<BalanceFormula>()
    .oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
    .nullable()
    .default(null),
  pal: valueMethodSchema.required(),
  cpm: valueMethodSchema.required(),
  spm: valueMethodSchema.required(),
  activity: activitySchema.required(),
});

const energyDemandManualSchema = yup.object().shape({
  pattern: yup
    .mixed<BalanceFormula>()
    .oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
    .nullable()
    .default(null),
  spm: valueMethodSchema.required(),
  tef: valueMethodSchema.required(),
  neat: valueMethodSchema.required(),
  tea: valueMethodSchema.required(),
  cpm: valueMethodSchema.required(),
  steps: yup.number().nullable().default(null),
});

const balanceDaySchema = yup.object().shape({
  id: yup.number().required(),
  type: yup.mixed<BalanceType>().oneOf([1, 2]).required(),
  frequency: yup
    .array()
    .of(yup.mixed<FrequencyDay>().oneOf([1, 2, 3, 4, 5, 6, 7]).required())
    .required(),
  translations: yup.array().of(translationSchema).required(),
  suggestedEnergy: suggestedEnergySchema.required(),
  energyDemand: yup.lazy(value => {
    if (value.pal) return energyDemandPalSchema.required();
    else return energyDemandManualSchema.required();
  }),
});

const clientBalanceSchema = yup.object().shape({
  type: yup.mixed<BalanceType>().oneOf([1, 2]).required(),
  days: yup.array().of(balanceDaySchema).required(),
});

const fetchClientBalanceResponseSchema = yup.object().shape({
  data: clientBalanceSchema.required(),
});

export interface FetchClientBalanceResponse {
  type: BalanceType;
  days: BalanceDayDto[];
}

export interface BalanceDayDto {
  id: number;
  type: BalanceType;
  frequency: FrequencyDay[];
  translations: TranslationDto[];
  suggestedEnergy: SuggestedEnergyDto;
  energyDemand: EnergyDemandPalDto | EnergyDemandManualDto;
}

interface TranslationDto {
  locale: "pl" | "en";
  name: string | null;
}

export interface SuggestedEnergyDto {
  kcal: number | null;
  protein: number | null;
  carb: number | null;
  fat: number | null;
}

interface EnergyDemandBasic {
  pattern: BalanceFormula | null;
  spm: ValueMethodDto;
  cpm: ValueMethodDto;
}
export interface EnergyDemandPalDto extends EnergyDemandBasic {
  pal: ValueMethodDto;
  activity: ActivityDto;
}

interface ActivityDto {
  daily: BalanceActivityDaily | null;
  training: BalanceActivityTraining | null;
}

export interface EnergyDemandManualDto extends EnergyDemandBasic {
  tef: ValueMethodDto;
  neat: ValueMethodDto;
  tea: ValueMethodDto;
  steps: number | null;
}

interface ValueMethodDto {
  value: number | null;
  method: BalanceValueMethod | null;
}
