import * as yup from "yup";

import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";

export const postClientDislikedProductsCheck = async (
  patientId: number,
  payload: PostClientDislikedProductsCheckRequest,
): Promise<ApiResponse<CheckProductDto[]>> => {
  const response = await fetchData(
    `/dietitian/patients/${patientId}/food/dislike/check`,
    APIMethods.POST,
    payload,
  );
  return await postClientDislikedProductsCheckResponseSchema.validate(response);
};

interface PostClientDislikedProductsCheckRequest {
  foodId: number[];
}

export interface CheckProductDto {
  dislike: boolean;
  food: FoodDto;
}

interface FoodDto {
  id: number;
}

const foodDto = yup.object().shape({
  id: yup.number().required(),
});

const checkProductSchema = yup.object().shape({
  dislike: yup.boolean().required(),
  food: foodDto.required(),
});

const postClientDislikedProductsCheckResponseSchema = yup.object().shape({
  data: yup.array().of(checkProductSchema).required(),
});
