import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  DietMealItemCreatorResponseDto,
  UpdateProductsSortPayload,
  updateCreatorDietMealProductsSort,
} from "@client/diets/creator";
import { MutationOptions } from "@hooks/queries/types";

import { useCreatorVersion } from "./useCreatorVersion";
import { fetchCreatorDietMealsItemsQuery } from "./useFetchCreatorDietMealsItemsQuery";
import { fetchCreatorDietQueryKey } from "./useFetchCreatorDietQuery";
import { ApiResponse } from "@typeDefinitions";
import { updateSortOrder } from "./optimisticUpdateUtils";

export const useUpdateCreatorDietMealProductsSortMutation = (
  options?: MutationOptions,
) => {
  const { version, updateVersion } = useCreatorVersion();
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    UseUpdateCreatorDietMealProductsSortMutation,
    MutationContextIf
  >(
    ({ dietId, mealId, payload }) =>
      updateCreatorDietMealProductsSort(dietId, mealId, payload, version),
    {
      ...options,
      onMutate: async ({ dietId, mealId, payload }) => {
        await queryClient.cancelQueries([
          fetchCreatorDietMealsItemsQuery,
          dietId,
          mealId,
        ]);
        const previousData = queryClient.getQueryData<
          ApiResponse<DietMealItemCreatorResponseDto>
        >([fetchCreatorDietMealsItemsQuery, dietId, mealId]);

        if (!previousData?.data) return { previousData, invalidate: true };

        queryClient.setQueryData<ApiResponse<DietMealItemCreatorResponseDto>>(
          [fetchCreatorDietMealsItemsQuery, dietId, mealId],
          oldData => {
            if (!oldData) return previousData;
            const { products, ...rest } = oldData.data;
            return {
              data: {
                ...rest,
                products: updateSortOrder(products, payload.ids),
              },
            };
          },
        );
        updateVersion();
      },
      onSettled: (d, e, v, context) => {
        if (context?.invalidate) {
          queryClient.invalidateQueries([
            fetchCreatorDietMealsItemsQuery,
            v.dietId,
            v.mealId,
          ]);
          queryClient.invalidateQueries([fetchCreatorDietQueryKey, v.dietId]);
        }
      },
      onError: (_, { dietId, mealId }) => {
        queryClient.invalidateQueries([
          fetchCreatorDietMealsItemsQuery,
          dietId,
          mealId,
        ]);
        queryClient.invalidateQueries([fetchCreatorDietQueryKey, dietId]);
      },
    },
  );
};

interface UseUpdateCreatorDietMealProductsSortMutation {
  dietId: string;
  mealId: string;
  payload: UpdateProductsSortPayload;
}

interface MutationContextIf {
  previousData?: ApiResponse<DietMealItemCreatorResponseDto>;
  invalidate?: boolean;
}
