import { Link } from "react-router-dom";

import { IconButton } from "@mui/material";

import { DuplicateFiles, Pen, TrashNew } from "@assets/icons";
import { ConfirmationModal } from "@components/ConfirmationModal";
import { ExpandableTags } from "@components/ExpandableChips";
import { FavoriteToggleButton } from "@components/FavoriteToggleButton";
import { MacrosText } from "@components/MacrosText";
import { MealRowIf } from "@components/MealPatternsDataGrid/MealPatternsDataGrid";
import { TagWithIcon } from "@components/TagWithIcon";
import { useAppTranslation, useModal } from "@hooks";
import {
  useDeleteMealPatternMutation,
  usePostToggleFavoriteMealPatternMutation,
} from "@hooks/queries";
import { DocWithApple } from "@assets/icons/DesignSystem";
import { ExpandableMealTags } from "@components/MealPatternsDataGrid/NameColumnCell/ExpandableMealTags";
import { useTagsNew } from "@hooks/useTagsNew";
import { TagCategoryType } from "@utils/tagsNew";

import { ImagePlaceholder } from "./ImagePlaceholder";
import {
  ActionsWrapper,
  ButtonsWrapper,
  CardStyled,
  ContentWrapper,
  FavoriteToggleButtonStyled,
  ImgStyled,
  TitleStyled,
} from "./MealTile.styled";
import { useMemo } from "react";

interface MealTileProps extends MealRowIf {
  onSelect: () => void;
}

export const MealTile = ({
  namePl,
  actions,
  id,
  isFavorite,
  nameEn,
  nutrients,
  tags,
  imgUrl,
  onSelect,
  totalItems,
}: MealTileProps) => {
  const { t, isPolishChosen } = useAppTranslation();
  const { mutate, isLoading: isLoadingToggle } =
    usePostToggleFavoriteMealPatternMutation();
  const { mutate: deleteMeal, isLoading: isDeleting } =
    useDeleteMealPatternMutation();
  const { modalOpened, onModalOpen, onModalClose } = useModal();
  const handleDelete = () => deleteMeal(id);
  const { tagCategoryDictionary } = useTagsNew();

  const mealTagList =
    tagCategoryDictionary
      .get(TagCategoryType.MEAL)
      ?.tags.map(t => t.id.toString()) ?? [];

  const mealTags = useMemo(
    () => tags.filter(t => mealTagList.includes(t)),
    [tags, mealTagList],
  );

  const otherTags = useMemo(
    () => tags.filter(t => !mealTagList.includes(t)),
    [tags, mealTagList],
  );

  const tagsSorted = useMemo(
    () => [...mealTags, ...otherTags],
    [mealTags, otherTags],
  );

  const handleToggle = () => mutate(id?.toString() ?? "");

  return (
    <>
      <CardStyled onClick={onSelect}>
        <ButtonsWrapper>
          <FavoriteToggleButtonStyled
            onChange={handleToggle}
            isLoading={isLoadingToggle}
            isFavorite={isFavorite}
            favorite={isFavorite}
            className="favoriteButton"
          />

          <ActionsWrapper className="buttonsWrapper">
            {actions.canEdit && (
              <Link to={`/meals/${id}/edit`}>
                <IconButton>
                  <Pen />
                </IconButton>
              </Link>
            )}
            <Link to={`/meals/${id}/copy`}>
              <IconButton>
                <DuplicateFiles />
              </IconButton>
            </Link>
            {actions.canDelete && (
              <IconButton
                onClick={e => {
                  e.stopPropagation();
                  onModalOpen();
                }}
                disabled={!actions.canDelete || isDeleting}
              >
                <TrashNew />
              </IconButton>
            )}
          </ActionsWrapper>
        </ButtonsWrapper>

        <div className="h-[260px] w-full">
          {imgUrl && (
            <ImgStyled
              loading="lazy"
              src={imgUrl}
              alt={isPolishChosen ? namePl : nameEn}
            />
          )}
          {!imgUrl && <ImagePlaceholder />}
        </div>

        <ContentWrapper>
          <ExpandableTags tags={tagsSorted.map(t => Number(t))} />

          <TitleStyled>{isPolishChosen ? namePl : nameEn}</TitleStyled>

          <div className="grid gap-[6px]">
            <MacrosText nutrients={nutrients} />

            <div className="h-[20px]">
              <TagWithIcon
                Icon={DocWithApple}
                label={t("collections.propositions", { count: totalItems })}
              />
            </div>
          </div>
        </ContentWrapper>
      </CardStyled>
      <ConfirmationModal
        confirmText={t("common.delete")}
        description={t("meals.delete_modal.question", {
          variable: isPolishChosen ? namePl : nameEn,
        })}
        onCancel={onModalClose}
        open={modalOpened}
        onConfirm={handleDelete}
        title={t("meals.delete_modal.delete")}
      />
    </>
  );
};
