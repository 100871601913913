import { Alert, Button, styled } from "@mui/material";

export const BannersWrapper = styled("div")`
  display: grid;
  gap: 4px;
  margin-bottom: 18px;
`;

const StyledAlert = styled(Alert)`
  font-size: 12px;
  align-items: center;
  background: ${({ theme }) => theme.palette.info.light};
  color: ${({ theme }) => theme.palette.info.main};
  line-height: 20px;
  letter-spacing: 0.4px;

  & .MuiAlert-icon {
    margin-right: 8px;
  }

  & .MuiAlert-action {
    padding: 0;
  }

  & .MuiIconButton-root {
    padding: 4px;
  }
`;

export const SystemSentAlert = styled(StyledAlert)`
  background: ${({ theme }) => theme.palette.info.light};
  color: ${({ theme }) => theme.palette.info.main};
`;

export const TrialPeriodAlert = styled(StyledAlert)`
  background: ${({ theme }) => theme.palette.warning.light};
  color: ${({ theme }) => theme.palette.warning.dark};
  margin-bottom: 18px;
`;

export const StyledButton = styled(Button)`
  font-size: 14px;
  width: max-content;
`;
