import { Typography } from "@mui/material";

import { useNutrientDictionary } from "@hooks";

import {
  ChartLabel,
  ChartLabelBold,
  MicronutrientDetailsWrapper,
  MicronutrientValueWrapper,
  WarningDot,
} from "./MonitoringDayDetails.styled";
import { roundNutrientDigitsById } from "@utils";

interface MicronutrientDetailsProps {
  nutrientId: number;
  value: number | null;
  targetValue: number | null;
}

export const MicronutrientDetails = ({
  nutrientId,
  value,
  targetValue,
}: MicronutrientDetailsProps) => {
  const { getNutrient } = useNutrientDictionary();
  const nutrient = getNutrient(nutrientId);

  if (!nutrient) return <></>;

  const { short, categoryId, unit } = nutrient;

  return (
    <MicronutrientDetailsWrapper>
      <Typography variant="body2">{short}:</Typography>

      <MicronutrientValueWrapper>
        {!!targetValue && !!value && value < targetValue && <WarningDot />}
        <ChartLabelBold>
          {value !== null
            ? roundNutrientDigitsById(value, nutrientId, categoryId)
            : "-"}{" "}
          {unit}
        </ChartLabelBold>
        <ChartLabel>
          /{" "}
          {targetValue !== null
            ? roundNutrientDigitsById(
                targetValue,
                nutrientId,
                nutrient?.categoryId,
              )
            : "-"}{" "}
          {unit}
        </ChartLabel>
      </MicronutrientValueWrapper>
    </MicronutrientDetailsWrapper>
  );
};
