import { styled } from "@mui/material";

export const Image = styled("img")`
  width: 200px;
`;

export const Title = styled("span")`
  font-weight: 600;
  line-height: 20px;
  font-size: 16px;
  color: #141414;
`;

export const Text = styled("span")`
  font-weight: normal;
  line-height: 18px;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: #333333;
`;
