import { fetchCreatorMealQueryKey, MutationOptions } from "@hooks/queries";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  FetchMealResponse,
  postCreatorMealRecipe,
  PostCreatorMealRecipeRequest,
} from "@client/meals";
import { useFetchMealTagsQueryKey } from "@hooks/queries/meals/useFetchCreatorMealTags";
import { fetchCreatorMealNutrientsQueryKey } from "@hooks/queries/meals/useFetchCreatorMealNutrientsQuery";
import { ApiResponse } from "@typeDefinitions";
import { IdDto } from "@client";
import { useMealVersion } from "@views/dietician/MealEdit/hooks";

export const usePostCreatorMealRecipeMutation = (
  mealId: string,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();
  const { version, updateVersion } = useMealVersion();

  const mutation = useMutation<
    ApiResponse<IdDto>,
    unknown,
    PostCreatorMealRecipeRequest
  >(payload => postCreatorMealRecipe(mealId, payload, version), {
    ...options,
    onSuccess: async (data, variables) => {
      options?.onSuccess && options.onSuccess(data, variables);
      queryClient.invalidateQueries([useFetchMealTagsQueryKey, mealId]);
      queryClient.invalidateQueries([
        fetchCreatorMealNutrientsQueryKey,
        mealId,
      ]);
      await queryClient.cancelQueries([fetchCreatorMealQueryKey, mealId]);
      queryClient.setQueryData<ApiResponse<FetchMealResponse>>(
        [fetchCreatorMealQueryKey, mealId],
        old => {
          if (!old) return;
          return {
            ...old,
            data: {
              ...old.data,
              recipes: [
                ...old.data.recipes,
                {
                  id: data.data.id,
                  servings: 1,
                  recipe: {
                    id: data.data.id,
                    isPlaceholder: true,
                  },
                },
              ],
              numberOfItems: old.data.numberOfItems + 1,
            },
          };
        },
      );
    },
    onMutate: async () => {
      updateVersion();
    },
    onError: () => {
      queryClient.invalidateQueries([fetchCreatorMealQueryKey, mealId]);
    },
  });

  return mutation;
};
