import { ReactElement, useMemo } from "react";
import { SubmitHandler } from "react-hook-form";
import { FormTextField } from "@components/FormTextField";
import { FormColorPicker } from "@components/FormColorPicker";
import { OuterLabel } from "@components/OuterLabel";
import { ModalShell } from "@components/ModalShell";
import { CustomTagFormInput, useCustomTagForm } from "@hooks/useCustomTagForm";
import { useAppTranslation } from "@hooks";
import { useUpdateCustomTagMutation } from "@hooks/queries/customTags/useUpdateCustomTagMutation";
import { CustomTag } from "@client/customTags/fetchCustomTags";
import { InputAdornment } from "@mui/material";
import { Tag } from "@assets/icons";

export interface CustomTagEditModalProps {
  tag?: CustomTag | null;
  open: boolean;
  onClose: () => void;
}

export const CustomTagEditModal = ({
  tag,
  open,
  onClose,
}: CustomTagEditModalProps): ReactElement => {
  const { t } = useAppTranslation();

  const { mutate, isLoading } = useUpdateCustomTagMutation();

  const defaultValues = useMemo(
    () => ({
      name: tag?.name ?? "",
      color: tag?.color ?? "",
    }),
    [tag?.id],
  );

  const { control, handleSubmit } = useCustomTagForm(defaultValues);

  const onSubmit: SubmitHandler<CustomTagFormInput> = data => {
    if (!tag) {
      return;
    }

    mutate(
      { id: tag.id, payload: data },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  return (
    <ModalShell
      title={t("custom_tags.edit_modal.title")}
      open={open}
      onClose={onClose}
      isLoading={isLoading}
      onSubmit={handleSubmit(onSubmit)}
    >
      <OuterLabel label={t("custom_tags.fields.name")}>
        <FormTextField
          control={control}
          name="name"
          size="small"
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Tag />
              </InputAdornment>
            ),
          }}
        />
      </OuterLabel>
      <OuterLabel label={t("custom_tags.fields.color")}>
        <FormColorPicker control={control} name="color" outlined />
      </OuterLabel>
    </ModalShell>
  );
};
