import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const CheckRounded = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="16" height="15" viewBox="0 0 16 15" fill="none">
      <path
        d="M2 7L6 11L14 3"
        fill="none"
        stroke="#46BB63"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
