import { postNewSubscriptionPurchase } from "@client/clinic";
import { SubscriptionPurchaseRequest } from "@client/resources/SubscriptionPurchaseRequest";
import { fetchDietitianAccountQueryKey } from "@hooks/queries/settings";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ApiErrorIf } from "@typeDefinitions";
import { AxiosError } from "axios";
import { toast } from "react-toastify";

export const usePostNewSubscriptionPurchaseMutation = () => {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    AxiosError<ApiErrorIf>,
    SubscriptionPurchaseRequest
  >({
    mutationFn: (payload: SubscriptionPurchaseRequest) =>
      postNewSubscriptionPurchase(payload),
    onSuccess: () => {
      queryClient.invalidateQueries([fetchDietitianAccountQueryKey]);
    },
    onError: err => {
      if (err?.response?.data?.message === ALREADY_HAS_SUBSCRIPTION)
        toast.error(
          "Wystąpił błąd. Skontaktuj się z nami na czacie lub wyślij wiadomość na adres support@alloweat.com",
        );
    },
  });
};

const ALREADY_HAS_SUBSCRIPTION = "You have next subscription!";
