import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useAppTranslation } from "@hooks";
import { MutationOptions } from "../types";
import { fetchClinicBillingDataQueryKey } from "./useFetchClinicBillingDataQuery";
import {
  UpdateClinicBillingDataRequest,
  updateClinicBillingData,
} from "@client/settings";
import { toast } from "react-toastify";

export const useUpdateClinicBillingDataMutation = (
  options?: MutationOptions,
) => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, UpdateClinicBillingDataRequest>(
    payload => updateClinicBillingData(payload),
    {
      ...options,
      onSuccess: () => {
        toast.success(t("common.save_changes"));
        queryClient.invalidateQueries([fetchClinicBillingDataQueryKey]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};
