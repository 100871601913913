import { MediaDto } from "@client/common";
import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const updateProgramDietMealRecipe = async (
  programId: string,
  dietId: string,
  mealId: string,
  recipeId: string,
  payload: ProgramDietMealRecipeRequest,
  version: string,
) => {
  const response = await fetchData(
    `/dietitian/program/${programId}/diet-creator/${dietId}/meal-items-recipe/${recipeId}`,
    APIMethods.PUT,
    payload,
    undefined,
    { "Alloweat-Creator-Version": version },
  );

  return response;
};

export interface ProgramDietMealRecipeRequest {
  servings: number;
  recipe: ProgramRecipeRequest;
}

interface ProgramRecipeRequest {
  id: string;
  name: string;
  nameEn: string;
  description: string;
  descriptionEn: string;
  servings: number;
  reviewed: boolean | null;
  tags: number[];
  media: MediaDto | null;
  foodRecipe: FoodRecipeDto[];
  version: number | null;
}

export interface FoodRecipeDto {
  id: number | null;
  foodId: number;
  grams: number;
  foodMeasureId: number;
}
