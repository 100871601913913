import { useTaskViewSettingsContext } from "../TaskViewSettings.context";

import { OrangeCircle, CompleteCircle, Checklist } from "@assets/icons";
import { useFetchCustomTagsQuery } from "@hooks/queries/customTags/useFetchCustomTagsQuery";
import { TaskAccordionItem } from "./TaskAccordionItem";
import { GroupByEnum } from "@hooks/tasks/useTaskFilterOptions";
import { useAppTranslation } from "@hooks";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import { Button } from "@components/Button";
import image from "@assets/img/tasksEmpty.png";
import { useTasksContext } from "../../Tasks.context";
import { useTasksQuery } from "@hooks/queries/tasks/useTasksQuery";
import { useQueryParams } from "@hooks/queries/useQueryParams";

export enum TaskType {
  Completed = "completed",
  Todo = "todo",
  Tag = "tag",
  All = "all",
}

export const TasksAccordion = () => {
  const { t } = useAppTranslation();
  const { hideFinished, groupBy } = useTaskViewSettingsContext();
  const { tags } = useFetchCustomTagsQuery();
  const { handleOpenCreateModal } = useTasksContext();
  const { palette } = useTheme();

  const currentPage = 0;
  const perPageCount = 10;

  const { data, isLoading } = useTasksQuery(
    currentPage,
    perPageCount,
    TaskType.All,
  );

  const { get } = useQueryParams("customTagsIds");

  const { customTagsIds } = get<{
    customTagsIds: string[] | undefined;
  }>();

  const filterByTags =
    typeof customTagsIds === "string" ? [customTagsIds] : customTagsIds;

  const accordionsByTag = [
    ...tags.map(({ name, id }) => ({
      id: String(id),
      title: name,
      icon: null,
      isExpanded: true,
      isHidden: false,
      type: TaskType.Tag,
    })),
    {
      id: "others",
      title: t("tasks.others"),
      icon: null,
      isExpanded: true,
      isHidden: false,
      type: TaskType.Todo,
    },
  ];

  const accordionsByTagsFiltered = filterByTags?.length
    ? accordionsByTag.filter(({ id }) => filterByTags.includes(id))
    : accordionsByTag;

  const accordionsByStatus = [
    {
      title: t("tasks.toDo"),
      icon: <OrangeCircle fill={palette.warning.main} />,
      isExpanded: true,
      isHidden: false,
      type: TaskType.Todo,
    },
    {
      title: t("tasks.finished"),
      icon: <CompleteCircle fill={palette.success.main} />,
      isExpanded: false,
      isHidden: hideFinished,
      type: TaskType.Completed,
    },
  ];

  const accordions =
    groupBy === GroupByEnum.Status
      ? accordionsByStatus
      : accordionsByTagsFiltered;

  if (!isLoading && !data?.tasks.length) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        margin="10px auto"
        alignItems="center"
        gap={1}
      >
        <img src={image} alt={t("tasks.noTasks")} className="w-72 h-auto" />
        <Typography
          fontWeight={600}
          fontSize="16px"
          lineHeight="1.5rem"
          align="center"
          color={({ colors }) => colors.neutral.dark[900]}
        >
          {t("tasks.noTasks")}
        </Typography>
        <Typography
          fontWeight={400}
          fontSize="14px"
          align="center"
          color={({ colors }) => colors.neutral.dark[800]}
        >
          {t("tasks.noTasksAddedYet")}
        </Typography>
        <Button
          color="secondary-purple"
          size="large"
          className="border-none"
          onClick={handleOpenCreateModal}
        >
          {t("tasks.addTask")}
        </Button>
      </Box>
    );
  }

  return (
    <>
      {accordions.map(accordionProps => (
        <TaskAccordionItem key={accordionProps.title} {...accordionProps} />
      ))}
    </>
  );
};
