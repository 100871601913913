import { yupResolver } from "@hookform/resolvers/yup";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";
import { TaskPriority } from "@views/dietician/Tasks/components/TaskPrioritySelect";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useCreateTaskMutation } from "@hooks/queries/tasks/useCreateTaskMutation";
import { mapCreateTaskFormToPayload } from "@utils/tasks/mapCreateTaskFormToPayload";
import { useTasksContext } from "@views/dietician/Tasks/Tasks.context";
import { useSingleTaskQuery } from "@hooks/queries/tasks/useSingleTaskQuery";
import { Task } from "@client/tasks/fetchTasks";
import { useUpdateTaskMutation } from "@hooks/queries/tasks/useUpdateTaskMutation";
import { useTasksSuggested } from "@views/dietician/Tasks/components/TasksSuggested/TasksSuggestedPanel.context";
import { useAllTags } from "@hooks/useAllTags";
import { useNotesSidePanelContext } from "@components/NotesSidePanel/NotesSidePanel.context";

interface MapFromSuggestedTaskToFormParams {
  name: string;
  clientId: string | null;
  dietitianId: string | undefined;
  dueDate: string | null;
  urlWithoutProtocol: string;
  customTagsIds: string[];
}

const mapServerStateToForm = (data: Task | null | undefined) => {
  return {
    name: data?.name ?? "",
    description: data?.description ?? "",
    customTagsIds: data?.customTags?.map(tag => String(tag.id)) ?? [],
    relatedLink: data?.relatedLink ?? "",
    dietitianId: data?.dietitian?.id ? String(data.dietitian.id) : "",
    clientId: data?.client?.id ? String(data.client.id) : null,
    dueDate: data?.dueDate ?? null,
    priority: data?.priority ?? null,
  };
};

const mapFromSuggestedTaskToForm = ({
  name,
  clientId,
  dietitianId,
  dueDate,
  urlWithoutProtocol,
  customTagsIds,
}: MapFromSuggestedTaskToFormParams) => ({
  name,
  dietitianId,
  description: "",
  customTagsIds,
  relatedLink: urlWithoutProtocol ?? "",
  clientId: clientId ?? null,
  dueDate: dueDate ?? null,
  priority: null,
});

const getCleanUrl = () => {
  const url = new URL(window.location.href);
  return url.pathname.endsWith("/") ? "" : `${url.host}${url.pathname}`;
};

const resolver = yup.object({
  name: yup.string().required("tasks.taskCreateModal.nameValidation"),
  description: yup.string(),
  customTagsIds: yup.array().of(yup.string()).nullable(),
  relatedLink: yup.string(),
  dietitianId: yup.string().nullable(),
  clientId: yup.string().nullable(),
  dueDate: yup.string().nullable(),
  priority: yup
    .mixed<TaskPriority>()
    .oneOf(Object.values(TaskPriority))
    .nullable(),
});

export type CreateTaskFormSchema = yup.InferType<typeof resolver>;

interface GetFallbackFormStateParams {
  fallbackSuggestedTaskState: CreateTaskFormSchema;
  fallbackNoteState: CreateTaskFormSchema;
  fallbackDefaultState: CreateTaskFormSchema;
  isSuggestedTaskBeingAdded: boolean;
  isNoteBeingAdded: boolean;
  noteDescription: string;
}

const getFallbackFormState = ({
  isSuggestedTaskBeingAdded,
  fallbackSuggestedTaskState,
  isNoteBeingAdded,
  fallbackDefaultState,
  noteDescription,
}: GetFallbackFormStateParams) => {
  if (isSuggestedTaskBeingAdded) {
    return fallbackSuggestedTaskState;
  }

  if (isNoteBeingAdded) {
    return { ...fallbackDefaultState, description: noteDescription };
  }

  return fallbackDefaultState;
};

export const useCreateTaskForm = () => {
  const { account } = useFetchDietitianAccountQuery();
  const { allTags } = useAllTags();
  const { handleCreateTask } = useCreateTaskMutation();
  const { handleUpdateTask } = useUpdateTaskMutation();
  const { isNotesPanelOpen, noteDescription, noteId } =
    useNotesSidePanelContext();
  const { currentTaskId, isSuggestedTasksPanelOpen } = useTasksContext();
  const { data: serverState } = useSingleTaskQuery(currentTaskId ?? "");
  const suggestedTaskData = useTasksSuggested();

  const { suggestedTaskId, tagId, clientId, dueDate } = suggestedTaskData;
  const composedFormState = mapServerStateToForm(serverState);

  const currentAccountId = (account?.id ?? "").toString();
  const urlWithoutProtocol = getCleanUrl();

  const validTag = tagId ? allTags.find(({ id }) => id === tagId) : null;

  const composedFromSuggestedTask = mapFromSuggestedTaskToForm({
    clientId,
    dueDate,
    name: validTag?.name ?? "",
    dietitianId: currentAccountId,
    urlWithoutProtocol,
    customTagsIds: validTag?.id ? [String(validTag?.id)] : [],
  });

  const defaultValues = {
    name: "",
    description: "",
    customTagsIds: [],
    relatedLink: urlWithoutProtocol ?? "",
    dietitianId: currentAccountId,
    clientId: null,
    dueDate: null,
    priority: null,
  };

  const isSuggestedTaskBeingAdded = !currentTaskId && isSuggestedTasksPanelOpen;
  const isNoteBeingAdded = !currentTaskId && isNotesPanelOpen;

  const fallbackSuggestedTaskState = composedFromSuggestedTask;
  const fallbackNoteState = { ...defaultValues, description: noteDescription };
  const fallbackDefaultState = defaultValues;

  const fallbackFormState = getFallbackFormState({
    fallbackSuggestedTaskState,
    fallbackNoteState,
    fallbackDefaultState,
    isSuggestedTaskBeingAdded,
    isNoteBeingAdded,
    noteDescription,
  });

  const form = useForm<CreateTaskFormSchema>({
    defaultValues,
    resolver: yupResolver(resolver),
    values: currentTaskId ? composedFormState : fallbackFormState,
    resetOptions: {
      keepDirtyValues: false,
      keepErrors: false,
    },
    mode: "onChange",
  });

  const onCreateTaskSubmit = async (data: CreateTaskFormSchema) => {
    if (currentTaskId) {
      await handleUpdateTask({
        id: currentTaskId,
        ...mapCreateTaskFormToPayload(data),
      });
    } else {
      await handleCreateTask({
        noteId,
        suggestedTaskId,
        data: mapCreateTaskFormToPayload(data),
      });
    }

    form.reset();
  };

  return { onCreateTaskSubmit, form };
};
