import * as yup from "yup";

export interface CalendarMonthMonitoringResource {
  date: string;
  progress: number;
  notification: boolean;
}

export const calendarMonthMonitoringSchema = yup.object({
  date: yup.string().required(),
  progress: yup.number().required().integer().max(100).min(0),
  notification: yup.boolean().required(),
});
