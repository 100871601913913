import { useAppTranslation } from "@hooks";
import {
  GramsCell,
  Header,
  MeasureCell,
  NameCell,
  NutrientsHeader,
  TableHeadersStyled,
} from "./ProductRow.styled";

export const TableHeaders = () => {
  const { t } = useAppTranslation();

  return (
    <TableHeadersStyled>
      <NameCell>
        <Header>{t("common.product")}</Header>
      </NameCell>

      <GramsCell>
        <Header>{t("recipe.ingredients_table.grams")}</Header>
      </GramsCell>

      <MeasureCell>
        <Header>{t("recipe.ingredients_table.amount")}</Header>
      </MeasureCell>

      <NutrientsHeader>
        <Header>{t("recipe.ingredients_table.nutritional_values")}</Header>
      </NutrientsHeader>
    </TableHeadersStyled>
  );
};
