import {
  UpdateSurveySubgroupsSortRequest,
  updateSurveySubgroupsSort,
} from "@client/surveys";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "../types";
import { fetchSurveyFullQueryKey } from "./useFetchSurveyFullQuery";

export const useUpdateSurveySubgroupsSortMutation = (
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    UpdateSurveySubgroupsSortMutationPayload
  >(({ id, payload }) => updateSurveySubgroupsSort(id, payload), {
    ...options,
    onSuccess(data, variables) {
      options?.onSuccess && options.onSuccess(data, variables);

      queryClient.invalidateQueries([fetchSurveyFullQueryKey]);
    },
  });
};

interface UpdateSurveySubgroupsSortMutationPayload {
  id: string;
  payload: UpdateSurveySubgroupsSortRequest;
}
