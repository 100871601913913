import { styled } from "@mui/material";
import { DatePicker as MuiDataPicer } from "@mui/x-date-pickers/DatePicker";

const DataPicker = styled(MuiDataPicer)`
  .MuiInputBase-root {
    border: 1px solid #f5f1fe;
    border-radius: 8px;
    padding: 8px 12px;
    color: #333333;
    height: unset;

    &.Mui-disabled {
      border-color: #f1f1f1;
      opacity: 0.6;
    }

    .MuiInputBase-input {
      font-family: Figtree, serif;
      font-weight: 500;
      padding: 0;
      height: unset;
      font-size: 14px;
      line-height: 24px;
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
` as typeof MuiDataPicer;

export default DataPicker;
