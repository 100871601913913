import CopyIcon from "@icons/copy.svg?react";
import { IconButton } from "@mui/material";
import {
  GoogleMeetComponentWrapper,
  Title,
  UrlText,
} from "./GoogleMeetComponent.styled";

interface GoogleMeetComponentProps {
  url: string;
}
export const GoogleMeetComponent = ({ url }: GoogleMeetComponentProps) => {
  const handleCopy = () => {
    navigator.clipboard
      .writeText(url)
      .then()
      .catch(err => {
        console.error(err);
      });
  };
  return (
    <GoogleMeetComponentWrapper>
      <div className="w-[24px] h-[24px] grid place-items-center">
        <img
          width={20}
          height={20}
          src="/static/googleCalendar/googleMeet.svg"
        />
      </div>

      <div className="grid flex-1">
        <Title>Dołącz w Google Meet</Title>
        <UrlText>{url.replace("https://", "")}</UrlText>
      </div>

      <IconButton
        size="small"
        onClick={handleCopy}
        sx={{ marginRight: "-8px" }}
      >
        <CopyIcon fontSize={16} />
      </IconButton>
    </GoogleMeetComponentWrapper>
  );
};
