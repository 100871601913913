import {
  memo,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";

import { Nutrient } from "@typeDefinitions";
import { MacrosList } from "@components/MacrosList";

import { ActionButtons } from "./ActionButtons";
import { ProgramContext } from "@context";
import { MACROS_ARRAY } from "@utils/macros";
import {
  BorderedAccordion,
  BorderedAccordionDetails,
  StyledAccordionSummary,
  StyledInfoSection,
  StyledWrapper,
} from "./ListElement.styled";

export interface ListElementProps {
  title: ReactNode;
  body?: ReactNode;
  animationIndex?: number;
  macros?: Nutrient[];
  micros?: ReactElement;
  prefix?: ReactNode;
  checkbox?: ReactNode;
  dragHandle?: ReactNode;
  selectInput?: ReactNode;
  suffix?: ReactNode;
  onClick?: () => void;
  onEdit?: null | string;
  onCopy?: string;
  onHide?: () => Promise<void>;
  onDelete?: () => Promise<void>;
  onShow?: () => Promise<void>;
  children?: ReactNode;
}

export const ListElement = memo(
  ({
    title,
    body,
    children,
    macros,
    micros,
    prefix,
    suffix,
    checkbox,
    dragHandle,
    selectInput,
    onClick,
    onEdit,
    onCopy,
    onHide,
    onDelete,
    onShow,
  }: ListElementProps) => {
    const [collapsed, setCollapsed] = useState(true);
    const toggleCollapsed = useCallback(
      () => setCollapsed(state => !state),
      [],
    );
    const { microsShownToDietitian } = useContext(ProgramContext);

    return (
      <BorderedAccordion
        expanded={!collapsed}
        onChange={toggleCollapsed}
        TransitionProps={{ unmountOnExit: true }}
      >
        <StyledAccordionSummary expandIcon={null}>
          <div className="w-full py-1 items-center flex">
            <div className="flex items-center">
              <div className="flex gap-2" onClick={e => e.stopPropagation()}>
                {dragHandle}
                {checkbox}
              </div>

              <StyledInfoSection>
                <StyledWrapper>
                  <div
                    className="hover:text-primary cursor-pointer"
                    onClick={onClick}
                  >
                    {title}
                  </div>
                  {suffix}
                </StyledWrapper>

                <div
                  className="flex flex-wrap text-sm items-center"
                  style={{ marginTop: "-0.5rem", marginBottom: "-0.5rem" }}
                >
                  {macros && (
                    <>
                      {prefix && <span className="mr-4 w-18">{prefix}</span>}
                      <div className={"w-72"}>
                        <MacrosList
                          nutrients={macros}
                          show={[...MACROS_ARRAY, ...microsShownToDietitian]}
                        />
                      </div>
                    </>
                  )}
                  <div onClick={e => e.stopPropagation()}>{micros}</div>
                </div>
              </StyledInfoSection>
            </div>

            {selectInput && (
              <div onClick={e => e.stopPropagation()}>{selectInput}</div>
            )}

            {body && (
              <div className="flex-grow" onClick={e => e.stopPropagation()}>
                {body}
              </div>
            )}

            {children && (
              <div style={{ width: 400 }}>
                <ActionButtons
                  collapsed={collapsed}
                  onEdit={onEdit}
                  onCopy={onCopy}
                  onHide={onHide}
                  onDelete={onDelete}
                  onShow={onShow}
                />
              </div>
            )}
          </div>
        </StyledAccordionSummary>
        <BorderedAccordionDetails>{children}</BorderedAccordionDetails>
      </BorderedAccordion>
    );
  },
);
