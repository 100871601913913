import { Box, styled } from "@mui/material";
import AlloweatDivider from "@components/Alloweat/Divider";

export const TabLayout = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  background: #fff;
  border-radius: 16px;
` as typeof Box;

export const TabHeadLayout = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
` as typeof Box;

export const TabEditLayout = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  cursor: pointer;
` as typeof Box;

export const TabTitle = styled("span")`
  font: var(--font-figtree-semi-bold);
  font-size: 16px;
  line-height: 20px;
  color: #141414;
`;

export const TabHeadActionEditLabel = styled("span")`
  font: var(--font-figtree-medium);
  font-size: 14px;
  line-height: 24px;
  color: #7448d0;
`;

export const TabSectionLayout = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
` as typeof Box;

export const SectionLayout = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
` as typeof Box;

export const SectionTitle = styled("span")`
  font: var(--font-figtree-semi-bold);
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: #727272;
  text-transform: uppercase;
` as typeof Box;

export const SectionHeadLayout = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
` as typeof Box;

export const SectionHeadEditLayout = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  cursor: pointer;
` as typeof Box;

export const SectionItemLayout = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 4px;
` as typeof Box;

export const SectionItemLabel = styled("span")`
  font: var(--font-figtree-regular);
  font-size: 12px;
  line-height: 20px;
  color: #4d516e;
`;

export const SectionItemTextValue = styled("span")`
  font: var(--font-figtree-medium);
  font-size: 14px;
  line-height: 24px;
  color: #333333;
`;

export const SectionItemChips = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
`;

export const NutrientNormLabel = styled("span")`
  font: var(--font-figtree-medium);
  font-size: 14px;
  line-height: 24px;
  color: #333333;
`;

export const ChipItemsWrapper = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
`;

export const ClientHealthWrapper = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: flex-start;
  //flex-wrap: wrap;
  > * {
    flex: 1;
    max-width: calc(100% / 3);
  }
`;

export const Divider = styled(AlloweatDivider)`
  border-color: #d0d0d0;
`;

export const DialogSectionLayout = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const DialogSectionItemLayout = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const DialogSectionItemLabel = styled("div")`
  font: var(--font-figtree-regular);
  font-size: 12px;
  line-height: 18px;
  color: #333333;
`;
