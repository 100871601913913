import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { LoadingButton } from "@mui/lab";
import {
  IconButton,
  InputAdornment,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { Eye } from "@assets/icons";
import image from "@assets/img/dashboardEmptyState.png";
import { OuterLabel } from "@components/OuterLabel";
import { useAppTranslation } from "@hooks";
import { useResetPasswordMutation } from "@hooks/queries";
import { LOGIN } from "@routes";

import {
  ActionsWrapper,
  CardStyled,
  FixedButtonWrapper,
  FormTextFieldStyled,
  IInCricleStyled,
  InfoAlert,
  InfoWrapper,
  InputsWrapper,
  MobileWrapper,
  Text,
  Title,
} from "./Reset.styled";
import { SuccessViewClient } from "./SuccessViewClient";
import { SuccessViewDietitian } from "./SuccessViewDietitian";
import {
  ResetPasswordInputs,
  useResetPasswordForm,
} from "./useResetPasswordForm";

export const ResetNew = () => {
  const { t } = useAppTranslation();
  const { search } = useLocation();
  const navigate = useNavigate();
  const { mutate, isLoading } = useResetPasswordMutation();
  const { breakpoints } = useTheme();
  const isDownSmall = useMediaQuery(`${breakpoints.down("smallNew")}`);

  const { control, handleSubmit } = useResetPasswordForm();

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const [showSuccessView, setShowSuccessView] = useState(false);

  const searchParams = new URLSearchParams(search);
  const url = useMemo(() => searchParams.get("url"), [searchParams]);
  const type = useMemo(() => searchParams.get("type"), [searchParams]);

  const handleResetPassword = async ({ password }: ResetPasswordInputs) => {
    if (!url) return;

    mutate(
      {
        url: atob(url),
        data: { password },
      },
      {
        onSuccess: () => setShowSuccessView(true),
      },
    );
  };

  const handleSubmitWrapper = () => {
    handleSubmit(
      data => handleResetPassword(data),
      err => console.log(err),
    )();
  };

  const toggleShowPassword = () => setShowPassword(!showPassword);

  const toggleShowPasswordConfirmation = () =>
    setShowPasswordConfirmation(!showPasswordConfirmation);

  useEffect(() => {
    if (!url || !type) navigate(LOGIN, { replace: true });
  }, [url, type]);

  if (showSuccessView && type === UserType.DIETITIAN)
    return <SuccessViewDietitian />;

  if (showSuccessView && type === UserType.PATIENT)
    return <SuccessViewClient />;

  if (isDownSmall)
    return (
      <>
        <MobileWrapper>
          <InfoWrapper>
            <img
              src={image}
              alt={t("empty_states.alt.no_monitoring")}
              width={180}
              height={180}
            />
            <Title>{t("auth.reset.welcome")}</Title>

            <Text>
              {type === UserType.DIETITIAN
                ? t("auth.reset.web_app_desc")
                : t("auth.reset.mobile_app_desc")}
            </Text>
          </InfoWrapper>

          <ActionsWrapper>
            <InputsWrapper>
              <OuterLabel label={t("auth.reset.password")}>
                <FormTextFieldStyled
                  control={control}
                  name="password"
                  type={showPassword ? "text" : "password"}
                  size="small"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={toggleShowPassword}
                          edge="end"
                          size="small"
                          color="primary"
                        >
                          <Eye size="w-5 h-5" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t("auth.reset.password_placeholder")}
                />
                <div className="flex gap-1">
                  <IInCricleStyled />
                  <InfoAlert>{t("auth.reset.password_info")}</InfoAlert>
                </div>
              </OuterLabel>
              <OuterLabel label={t("auth.reset.password_confirmation")}>
                <FormTextFieldStyled
                  control={control}
                  name="passwordConfirmation"
                  type={showPasswordConfirmation ? "text" : "password"}
                  size="small"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={toggleShowPasswordConfirmation}
                          edge="end"
                          size="small"
                          color="primary"
                        >
                          <Eye size="w-5 h-5" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t(
                    "auth.reset.password_confirmation_placeholder",
                  )}
                />
              </OuterLabel>
            </InputsWrapper>
          </ActionsWrapper>
        </MobileWrapper>
        <FixedButtonWrapper>
          <LoadingButton
            fullWidth
            onClick={handleSubmitWrapper}
            loading={isLoading}
            variant="contained"
          >
            {t("auth.reset.save_password")}
          </LoadingButton>
        </FixedButtonWrapper>
      </>
    );
  return (
    <CardStyled>
      <InfoWrapper>
        <img
          src={image}
          alt={t("empty_states.alt.no_monitoring")}
          width={180}
          height={180}
        />
        <Title>{t("auth.reset.welcome")}</Title>

        <Text>
          {type === "dietitian"
            ? t("auth.reset.web_app_desc")
            : t("auth.reset.mobile_app_desc")}
        </Text>
      </InfoWrapper>

      <ActionsWrapper>
        <InputsWrapper>
          <OuterLabel label={t("auth.reset.password")}>
            <FormTextFieldStyled
              control={control}
              name="password"
              type={showPassword ? "text" : "password"}
              size="small"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={toggleShowPassword}
                      edge="end"
                      size="small"
                      color="primary"
                    >
                      <Eye size="w-5 h-5" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder={t("auth.reset.password_placeholder")}
            />
            <div className="flex gap-1">
              <IInCricleStyled />
              <InfoAlert>{t("auth.reset.password_info")}</InfoAlert>
            </div>
          </OuterLabel>
          <OuterLabel label={t("auth.reset.password_confirmation")}>
            <FormTextFieldStyled
              control={control}
              name="passwordConfirmation"
              type={showPasswordConfirmation ? "text" : "password"}
              size="small"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={toggleShowPasswordConfirmation}
                      edge="end"
                      size="small"
                      color="primary"
                    >
                      <Eye size="w-5 h-5" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder={t("auth.reset.password_confirmation_placeholder")}
            />
          </OuterLabel>
        </InputsWrapper>

        <LoadingButton
          fullWidth
          onClick={handleSubmitWrapper}
          loading={isLoading}
          variant="contained"
        >
          {t("auth.reset.save_password")}
        </LoadingButton>
      </ActionsWrapper>
    </CardStyled>
  );
};

enum UserType {
  DIETITIAN = "dietitian",
  PATIENT = "patient",
}
