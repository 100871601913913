import React, { useMemo } from "react";

import { round } from "lodash";

import { Input } from "@components/Input";

import { NutrientValueType } from "./Types";

export const NutrientValue = ({ id, values }: NutrientValueProps) => {
  const value = useMemo(() => {
    const value = values.find(item => item.id === id)?.value;
    return value !== null && value !== undefined ? round(value, 3) : "-";
  }, [values, id]);

  return <Input textCenter readOnly value={value} className="w-16" />;
};

interface NutrientValueProps {
  id: number;
  values: NutrientValueType[];
}
