import styled from "@emotion/styled";

interface RecipeNameWrapperProps {
  hover?: boolean;
}

export const RecipeNameWrapper = styled.div<RecipeNameWrapperProps>`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  cursor: ${({ hover }) => hover && "pointer"};
  display: flex;
  height: 32px;
  align-items: center;
  ${({ hover }) => hover && "&:hover { color: rgb(128, 31, 255); }"}
`;
