import { ChangeEventHandler, useState } from "react";
import { Control, FieldValues, Path, useController } from "react-hook-form";

import { TextField, TextFieldProps } from "@mui/material";

import { useAppTranslation } from "@hooks";

import { CharCounter } from "./FormTextFieldCharCounter.styled";
import { isString } from "lodash";

interface FormTextFieldCharCounterProps<T extends FieldValues>
  extends Omit<TextFieldProps, "onChange" | "error" | "helperText"> {
  name: Path<T>;
  control?: Control<T>;
  maxLength: number;
  strict?: boolean;
}

export const FormTextFieldCharCounter = <T extends FieldValues>({
  name,
  control,
  maxLength,
  strict,
  ...props
}: FormTextFieldCharCounterProps<T>) => {
  const { t } = useAppTranslation();

  const {
    field: { onChange, value, onBlur, ref },
    fieldState: { error },
  } = useController({ name, control });

  const handleChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = e => {
    const inputValue = e.target.value;
    const isOverLimit = inputValue.length > maxLength;
    const isWriting = isString(value) ? inputValue.length > value.length : true;

    if (isOverLimit && isWriting && strict) return;

    onChange(inputValue);
  };

  const overLimit = isString(value) ? value.length > maxLength : false;

  return (
    <div className="grid w-full">
      <TextField
        ref={ref}
        {...props}
        value={value}
        onChange={handleChange}
        onBlur={e => {
          onBlur();
          props.onBlur && props.onBlur(e);
        }}
        error={!!error}
        helperText={error?.message ? t(error.message) : undefined}
      />
      <CharCounter error={overLimit}>
        {value?.length ?? 0} / {maxLength}
      </CharCounter>
    </div>
  );
};
