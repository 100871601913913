import { useQuery } from "@tanstack/react-query";

import { ApiResponse } from "@typeDefinitions";
import {
  DietRecipeSearchPreviewResourceDto,
  searchDietsPreviewRecipeItem,
} from "@client/diets/searchDietsPreviewRecipeItem";

import { QueryOptionsTyped } from "../types";

export const searchDietsPreviewRecipeItemQueryKey =
  "searchDietsPreviewRecipeItemQueryKey";

export const useSearchDietsPreviewRecipeItemQuery = (
  dietId: string,
  mealId: string,
  itemId: string,
  options?: QueryOptionsTyped<ApiResponse<DietRecipeSearchPreviewResourceDto>>,
) => {
  const { data, ...rest } = useQuery(
    [searchDietsPreviewRecipeItemQueryKey, dietId, mealId, itemId],
    () => searchDietsPreviewRecipeItem(dietId, mealId, itemId),
    options,
  );

  return {
    recipe: data?.data,
    ...rest,
  };
};
