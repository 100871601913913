import image from "@assets/img/monitoringEmptyState.png";
import { ThemeProviderWrapperNew } from "themeNew";
import { Phone } from "@assets/icons/Phone";
import {
  ContentWrapper,
  MonitoringEmptyStateStyled,
} from "./MonitoringEmptyState.styled";
import { useAppTranslation } from "@hooks";
import { LoadingButton } from "@mui/lab";
import { EmptyStateCard, StyledSubTitle, StyledTitle } from "../common.styled";
import { Tooltip } from "@mui/material";

interface MonitoringEmptyStateProps {
  onClick: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  tooltip?: string;
}
export const MonitoringEmptyState = ({
  onClick,
  disabled,
  isLoading,
  tooltip,
}: MonitoringEmptyStateProps) => {
  const { t } = useAppTranslation();

  return (
    <ThemeProviderWrapperNew>
      <EmptyStateCard>
        <MonitoringEmptyStateStyled>
          <ContentWrapper>
            <img src={image} alt={t("empty_states.alt.no_monitoring")} />

            <StyledTitle>{t("empty_states.monitoring")}</StyledTitle>

            <StyledSubTitle maxWidth="40rem">
              {t("empty_states.monitoring_info")}
            </StyledSubTitle>
          </ContentWrapper>

          <Tooltip title={tooltip} disableHoverListener={!disabled} arrow>
            <div>
              <LoadingButton
                startIcon={<Phone />}
                onClick={onClick}
                variant="contained"
                disabled={disabled}
                loading={isLoading}
              >
                {t("empty_states.monitoring_activate")}
              </LoadingButton>
            </div>
          </Tooltip>
        </MonitoringEmptyStateStyled>
      </EmptyStateCard>
    </ThemeProviderWrapperNew>
  );
};
