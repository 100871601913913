import { styled } from "@mui/material";
import { ViewType } from "../../Pricing";

interface PriceAnnuallyProps {
  value: number;
  grossPrice: boolean;
  view: ViewType;
}
export const PriceGross = ({ value, grossPrice, view }: PriceAnnuallyProps) => {
  const anually = view === ViewType.annually;

  return (
    <div className="flex items-end gap-[4px]">
      <Text>
        {value}zł {grossPrice ? "brutto " : "+VAT "}
        {anually ? "jednorazowo za rok" : " / miesiąc"}
      </Text>
    </div>
  );
};

const Text = styled("span")`
  font-family: Figtree;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;
