import { useAppTranslation } from "@hooks";
import { useFoodGroupTreeFetchQuery } from "@hooks/queries/dictionaries";
import { useFoodGroupTreeOptions } from "@components/FoodGroupSelect";
import { useTreeSelectTitle } from "@components/TreeSelect";
import { Spinner } from "@components/Spinner";
import { Input } from "@components/Input";

import { ProductTags, ProductTagsProps } from "../../ProductForm";
import { NutrientsValues, ProductNutrients } from "../ProductNutrientsList";
import {
  ProductMeasuresShow,
  ProductMeasuresShowProps,
} from "../ProductMeasures/ProductMeasuresShow";

export const ProductDetails = ({ product }: ProductDetailsProps) => {
  const { t } = useAppTranslation();
  const { data: foodGroupTree, isLoading } = useFoodGroupTreeFetchQuery();
  const options = useFoodGroupTreeOptions(foodGroupTree);
  const title = useTreeSelectTitle(options, product?.foodGroup?.id.toString());

  if (!product) return <Spinner className="w-full h-56 bg-transparent" />;

  return (
    <div className="flex flex-col gap-8 bg-white">
      <div className={"flex gap-8"}>
        <div className={"flex-1"}>
          <p className="mb-2 text-lg font-semibold">{t("product.name")}:</p>
          <Input
            fullWidth
            readOnly
            name="producer"
            value={product?.descriptionPl ?? "Brak"}
          />
        </div>
        <div className={"flex-1"}>
          <p className="mb-2 text-lg font-semibold">
            {t("product.english_name")}:
          </p>
          <Input
            fullWidth
            readOnly
            name="producer"
            value={product?.description ?? "Brak"}
          />
        </div>
      </div>
      {product?.tags && product.tags.length ? (
        <div className={"flex gap-8"}>
          <div className={"flex-1"}>
            <p className="mb-2 text-lg font-semibold">{t("products.tags")}:</p>
            <div className={"flex justify-start"}>
              <ProductTags tags={product.tags} className={"bg-white"} />
            </div>
          </div>
        </div>
      ) : null}
      <div className={"flex gap-8"}>
        <div className={"flex-1"}>
          <p className="mb-2 text-lg font-semibold">{t("product.group")}:</p>
          <Input
            fullWidth
            readOnly
            name="producer"
            value={isLoading ? "..." : title}
          />
        </div>
        <div className={"flex-1"}>
          <p className="mb-2 text-lg font-semibold">Producent:</p>
          <Input
            fullWidth
            readOnly
            name="producer"
            value={product?.producer ?? "Brak"}
          />
        </div>
      </div>
      <div className={"w-1/2"}>
        <ProductMeasuresShow measures={product.measures ?? []} />
      </div>
      <div>
        <ProductNutrients values={product.values} />
      </div>
    </div>
  );
};

interface ProductDetailsProps {
  product?: ProductDetails;
}

interface ProductDetails
  extends ProductMeasuresShowProps,
    NutrientsValues,
    ProductTagsProps {
  description?: string | null;
  descriptionPl?: string | null;
  producer?: string | null;
  foodGroup: FoodGroup;
}

interface FoodGroup {
  id: number;
}
