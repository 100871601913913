import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const DocWithApple = (props: SvgIconProps) => (
  <SvgIcon width="20" height="18" viewBox="0 0 20 18" {...props}>
    <path
      fill="none"
      d="M14.8419 9.18115C15.3203 8.98667 15.8367 8.90382 16.3519 8.93886C16.8338 8.94088 17.3077 9.06203 17.7314 9.29152C18.1551 9.52102 18.5155 9.85175 18.7805 10.2543C19.1775 10.9533 19.3677 11.7507 19.329 12.5536C19.2903 13.3566 19.0242 14.132 18.5619 14.7895C18.2345 15.3441 17.8393 15.8558 17.3853 16.3126C17.0958 16.6814 16.7264 16.9797 16.305 17.1849C15.77 17.3504 15.255 16.9592 14.7131 16.9592H14.6722C14.1338 16.9592 13.6176 17.3504 13.0805 17.1849C12.6592 16.9797 12.29 16.6815 12.0008 16.3126C11.5471 15.8556 11.1519 15.344 10.8243 14.7895C10.3623 14.1318 10.0964 13.3565 10.0577 12.5536C10.0189 11.7507 10.2089 10.9534 10.6055 10.2543C10.8706 9.8517 11.2313 9.52093 11.6551 9.29144C12.0789 9.06195 12.5528 8.94083 13.0347 8.93886"
      stroke={props.fill ?? "currentColor"}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill="none"
      d="M13.8887 7.38455C14.8458 5.32147 17.2356 6.19101 17.2356 6.19101C16.8912 6.73077 16.4903 7.23229 16.0396 7.68709C15.8091 7.86952 15.5415 7.9992 15.2555 8.06714C14.9695 8.13507 14.672 8.13959 14.3841 8.08032"
      stroke={props.fill ?? "currentColor"}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill="none"
      d="M14.8406 9.17995C14.604 8.24575 14.0813 7.40892 13.3457 6.78662"
      stroke={props.fill ?? "currentColor"}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill="none"
      d="M12.7477 3.50024V1.00012H1.85408C1.70716 1.01103 1.57013 1.08511 1.47289 1.20617C1.37565 1.32723 1.32609 1.48547 1.33501 1.64638V16.4196C1.32628 16.5804 1.37601 16.7384 1.47323 16.8593C1.57045 16.9802 1.70728 17.0541 1.85408 17.065H9.63528"
      stroke={props.fill ?? "currentColor"}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.48438 4.79413H9.67234"
      stroke={props.fill ?? "currentColor"}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.48438 7.37769H8.37535"
      stroke={props.fill ?? "currentColor"}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.48438 9.96094H7.07836"
      stroke={props.fill ?? "currentColor"}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.48438 12.5447H7.07836"
      stroke={props.fill ?? "currentColor"}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
