import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useAppTranslation } from "@hooks";
import { useEffect } from "react";

export const useCustomTagForm = (values?: CustomTagFormInput) => {
  const { t } = useAppTranslation();

  const formSchema = yup.object().shape({
    name: yup.string().required(t("common.required_field")),
    color: yup.string().required(t("common.required_field")),
  });

  const form = useForm<CustomTagFormInput>({
    defaultValues: values ?? {
      name: "",
      color: "",
    },
    resolver: yupResolver(formSchema),
    shouldFocusError: false,
  });

  useEffect(() => {
    if (values) form.reset(values);
  }, [values]);

  return form;
};

export interface CustomTagFormInput {
  name: string;
  color: string;
}
