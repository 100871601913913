import {
  DietMealListItemCreatorDto,
  SaveDietMealCreatorRequest,
  TranslationExtendedDto,
} from "@client/diets/creator";
import { getNutrientValue } from "@utils";
import { CARBS_ID, ENERGY_ID, FATS_ID, PROTEIN_ID } from "@utils/macros";
import { getTranslation } from "@utils/translations";
import { useForm } from "react-hook-form";

export const useCommentForm = (defaultValues?: CommentInputs) => {
  const form = useForm<CommentInputs>({ defaultValues });

  return form;
};

export type CommentInputs = Record<string, string | undefined>;

export const mapCommentForm = (
  data?: TranslationExtendedDto[],
): CommentInputs | undefined => {
  if (!data) return;

  return data.reduce((acc, { langId, description }) => {
    acc[langId] = description ?? undefined;
    return acc;
  }, {} as CommentInputs);
};

export const mapCommentRequest = (
  values: CommentInputs,
  data?: DietMealListItemCreatorDto,
): SaveDietMealCreatorRequest | undefined => {
  if (!data) return;

  const { hour, type, targets, translations } = data;

  const mappedTranslations = Object.entries(values).map(
    ([langId, description]) => ({
      description: description ?? null,
      langId,
      name: getTranslation(translations, langId),
    }),
  );

  return {
    hour: hour,
    mealTypeId: type?.id ?? null,
    targetKcal: getNutrientValue(targets, ENERGY_ID),
    targetProtein: getNutrientValue(targets, PROTEIN_ID),
    targetFat: getNutrientValue(targets, FATS_ID),
    targetCarb: getNutrientValue(targets, CARBS_ID),
    translations: mappedTranslations,
  };
};
