import { useContext } from "react";
import { useController, useFormContext } from "react-hook-form";

import { Checkbox, FormControlLabel, Radio } from "@mui/material";
import { isArray } from "lodash";

import { useAppTranslation } from "@hooks";
import { LangDto } from "@client";
import { DTagResourceDto } from "@client/surveys";
import { SAFARI_TAB_INDEX, getTranslatedName } from "@utils";

import { BlurContext } from "../BlurContext";
import { TagAnswerFormInputs } from "../forms";
import {
  OptionsWrapper,
  RadioGroupStyled,
} from "./SystemQuestionTagDietType.styled";

interface TagRadioGroupProps {
  readOnly?: boolean;
  options: DTagResourceDto[] | undefined;
}
export const TagRadioGroup = ({ readOnly, options }: TagRadioGroupProps) => {
  const { t, i18n } = useAppTranslation();
  const { handleBlur, handleFocus } = useContext(BlurContext);
  const { control } = useFormContext<TagAnswerFormInputs>();
  const {
    field: { value, onChange },
  } = useController<TagAnswerFormInputs>({ control, name: "value" });
  const {
    field: { value: tagsId, onChange: onChangeTagsId },
  } = useController<TagAnswerFormInputs>({ control, name: "tagsId" });

  const handleChange = (checked: boolean, id: string) => {
    if (!tagsId || !isArray(tagsId)) return;

    if (tagsId.includes(id) && !checked)
      onChangeTagsId(tagsId.filter(tag => tag !== id));
    if (!tagsId.includes(id) && checked) onChangeTagsId([...tagsId, id]);
  };

  if (value === undefined || !isArray(tagsId)) return <></>;

  return (
    <div className="grid gap-[24px]">
      <div className="grid gap-[16px]">
        <RadioGroupStyled
          tabIndex={SAFARI_TAB_INDEX}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={value}
          onChange={(_, v) => !readOnly && onChange(v === BooleanAnswer.YES)}
        >
          <FormControlLabel
            value={BooleanAnswer.NO}
            control={<Radio />}
            label={t("questionnaires.questions.system_tag_diet_type.no")}
          />
          <FormControlLabel
            value={BooleanAnswer.YES}
            control={<Radio />}
            label={t("questionnaires.questions.system_tag_diet_type.yes")}
          />
        </RadioGroupStyled>

        {value && (
          <div className="grid gap-[16px]">
            <OptionsWrapper>
              {options?.map(({ id, translations }) => (
                <FormControlLabel
                  tabIndex={SAFARI_TAB_INDEX}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  key={id}
                  control={
                    <Checkbox
                      checked={tagsId.includes(id)}
                      onChange={(_, checked) =>
                        !readOnly && handleChange(checked, id)
                      }
                    />
                  }
                  label={getTranslatedName(
                    translations,
                    i18n.language as LangDto,
                  )}
                />
              ))}
            </OptionsWrapper>
          </div>
        )}
      </div>
    </div>
  );
};

enum BooleanAnswer {
  YES = "true",
  NO = "false",
}
