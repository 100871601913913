import * as yup from "yup";

import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";

export const fetchClinicName = async (): Promise<
  ApiResponse<FetchClinicNameResponse>
> => {
  const response = await fetchData("/dietitian/clinic/name", APIMethods.GET);
  await fetchClinicNameSchema.validate(response);

  return response;
};

export interface FetchClinicNameResponse {
  name: string;
}

const clinicNameSchema = yup.object().shape({
  name: yup.string().required(),
});
const fetchClinicNameSchema = yup.object().shape({
  data: clinicNameSchema.required(),
});
