import { useState } from "react";
import { TabContext, TabList } from "@mui/lab";
import { ProgramSearchPreview } from "@client/program/preview/fetchProgramPreview";
import {
  CustomTab,
  CustomTabPanel,
} from "@components/ProgramSearchPreview/Components/ProgramComponentsTabs/ProgramComponentsTabs.styled";
import TypeDaysComponentProgram from "@components/ProgramSearchPreview/Components/ProgramComponentsTabs/TypeDaysComponentProgram";
import ProgramTags from "@components/ProgramSearchPreview/Components/ProgramTags";
import ProgramSystemSections from "@components/ProgramSearchPreview/Components/ProgramSystemSections";
import ProgramMaterials from "@components/ProgramSearchPreview/Components/ProgramMaterials/ProgramMaterials";
import { useAppTranslation } from "@hooks";

type ComponentsProgramPatientPreviewType = {
  program: ProgramSearchPreview;
  value: string;
};
const ProgramComponentsTabsPanels = ({
  program,
  value,
}: ComponentsProgramPatientPreviewType) => {
  return (
    <>
      <CustomTabPanel hidden={value !== "1"}>
        {value === "1" && <TypeDaysComponentProgram program={program} />}
      </CustomTabPanel>
      <CustomTabPanel hidden={value !== "2"}>
        {value === "2" && <ProgramMaterials programId={program.id} />}
      </CustomTabPanel>
      <CustomTabPanel hidden={value !== "3"}>
        {value === "3" && <ProgramSystemSections programId={program.id} />}
      </CustomTabPanel>
      <CustomTabPanel hidden={value !== "4"}>
        {value === "4" && <ProgramTags programId={program.id} />}
      </CustomTabPanel>
    </>
  );
};

export default ProgramComponentsTabsPanels;
