import { useFormContext } from "react-hook-form";

import { IconButton, useTheme } from "@mui/material";

import { useAppTranslation, useMeasures } from "@hooks";
import { decimalInput0 } from "@utils/inputs";
import { CheckedIcon, Trash } from "@assets/icons";
import { DietitianProductFormInput } from "@views/dietician/product-views/ProductForm";
import { FormTextField } from "@components/FormTextField";
import { FormAutocomplete } from "@components/FormAutocomplete";

import { ProductMeasureSchema } from "./ProductMeasureSchema";
import { StyledDragHandle } from "./ProductMeasureItem.styled";

interface ProductMeasureItemProps {
  index: number;
  remove: () => void;
  readOnly?: boolean;
  selectedMeasures: (string | null)[];
}

export const ProductMeasureItem = ({
  index,
  remove,
  readOnly = false,
  selectedMeasures,
}: ProductMeasureItemProps) => {
  const { t } = useAppTranslation();
  const { palette } = useTheme();
  const { measureOptions } = useMeasures();
  const { control } = useFormContext<DietitianProductFormInput>();

  return (
    <ProductMeasureSchema
      dragHandle={<StyledDragHandle className="dragHandleProduct" />}
      measure={
        <FormAutocomplete
          label={`${t("products.measure_value")} ${index + 1}`}
          control={control}
          name={`measures.${index}.remoteId`}
          id={`measures.${index}.remoteId`}
          size="small"
          popupIcon={<CheckedIcon fill={palette.primary.main} />}
          className="w-full"
          options={measureOptions ?? []}
          disabled={readOnly}
          renderOption={(props, option) => {
            return (
              <li
                {...props}
                aria-disabled={selectedMeasures.includes(option.id)}
              >
                {option.label}
              </li>
            );
          }}
        />
      }
      grams={
        <FormTextField
          control={control}
          name={`measures.${index}.value`}
          size="small"
          label={t("products.grams")}
          InputProps={{ inputComponent: decimalInput0 }}
          disabled={readOnly}
        />
      }
      remove={
        <IconButton color="primary" onClick={remove} disabled={readOnly}>
          <Trash size="w-5 h-5" />
        </IconButton>
      }
    />
  );
};
