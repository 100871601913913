import { useCallback, useMemo } from "react";

import {
  BillingDataFormInput,
  mapBillingDataForm,
  mapBillingDataRequest,
  useAppTranslation,
  useBillingDataForm,
} from "@hooks";
import {
  useFetchClinicBillingDataQuery,
  useUpdateClinicBillingDataMutation,
} from "@hooks/queries/settings";
import { ModalWrapper } from "@components/ModalWrapper";
import { FormTextField } from "@components/FormTextField";
import { FormCountryAutocomplete } from "@components/FormCountryAutocomplete";

interface BillingDataModalProps {
  open: boolean;
  onClose: () => void;
}

export const BillingDataModal = ({ onClose, open }: BillingDataModalProps) => {
  const { t } = useAppTranslation();
  const { billingData } = useFetchClinicBillingDataQuery();
  const mappedBillingData = useMemo(
    () => mapBillingDataForm(billingData),
    [billingData],
  );
  const { reset, handleSubmit, control } =
    useBillingDataForm(mappedBillingData);

  const { mutate, isLoading } = useUpdateClinicBillingDataMutation({
    onSuccess: onClose,
  });
  const handleUpdateBillingData = useCallback(
    (data: BillingDataFormInput) => mutate(mapBillingDataRequest(data)),
    [mappedBillingData, mutate],
  );

  return (
    <ModalWrapper
      title={t("billing_settings.title")}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit(handleUpdateBillingData)}
      reset={reset}
      isLoading={isLoading}
    >
      <FormTextField
        control={control}
        name="name"
        size="small"
        label={t("billing_settings.name")}
        variant="outlined"
      />
      <FormTextField
        control={control}
        name="vatId"
        size="small"
        label={t("billing_settings.vat_id")}
        variant="outlined"
      />
      <FormTextField
        control={control}
        name="email"
        size="small"
        label={t("billing_settings.email")}
        variant="outlined"
      />
      <div className="flex gap-4">
        <FormCountryAutocomplete
          control={control}
          name="prefix"
          id="prefix"
          size="small"
          label={t("billing_settings.prefix")}
          className="flex-1"
        />
        <FormTextField
          control={control}
          name="phone"
          size="small"
          label={t("billing_settings.phone")}
          variant="outlined"
          className="flex-3"
        />
      </div>
      <FormTextField
        control={control}
        name="street"
        size="small"
        label={t("billing_settings.street")}
        variant="outlined"
      />
      <div className="flex gap-4">
        <FormTextField
          control={control}
          name="streetNumber"
          size="small"
          label={t("billing_settings.street_number")}
          variant="outlined"
          className="flex-1"
        />
        <FormTextField
          control={control}
          name="houseNumber"
          size="small"
          label={t("billing_settings.house_number")}
          variant="outlined"
          className="flex-1"
        />
      </div>
      <div className="flex gap-4 pb-6">
        <FormTextField
          control={control}
          name="postCode"
          size="small"
          label={t("billing_settings.post_code")}
          variant="outlined"
          className="flex-1"
        />
        <FormTextField
          control={control}
          name="city"
          size="small"
          label={t("billing_settings.city")}
          variant="outlined"
          className="flex-1"
        />
      </div>
    </ModalWrapper>
  );
};
