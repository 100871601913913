import { styled } from "@mui/material";
import GraduationCapIcon from "@assets/icons_new/graduationCap.svg?react";

export const StudentInfo = () => {
  return (
    <Wrapper>
      <IconWrapper>
        <GraduationCapIcon />
      </IconWrapper>

      <TextWrapper>
        <Title>Jesteś studentem?</Title>

        <Text>
          Świetnie się składa! Kliknij w “kup teraz”, wybierz opcję “jestem
          studentem”, a wybrany pakiet dostaniesz z <Bold>50% rabatem.</Bold>
        </Text>
      </TextWrapper>
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  display: flex;
  gap: 24px;
  align-items: flex-start;
  padding: 24px;
  border-radius: 16px;
  background: ${({ theme }) => theme.palette.primary.light2};
`;

const IconWrapper = styled("div")`
  display: grid;
  place-items: center;
  height: 44px;
  width: 44px;
  border-radius: 8px;
  background: #fff;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const TextWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Title = styled("span")`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #2d1d51;
`;

const Text = styled("span")`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-align: left;
  color: #483a68;
`;

const Bold = styled(Text)`
  font-weight: 600;
`;
