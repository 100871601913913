import { Dispatch, SetStateAction, useEffect, useMemo } from "react";

import { InputBaseComponentProps } from "@mui/material";
import { omit } from "lodash";

import {
  InputComponentWrapper,
  InputStyled,
  StyledChip,
} from "./FoodAndAllergensInputComponent.styled";
import {
  TreeItemOption,
  buildDictionary,
  handleCheckboxChange,
} from "@components/TreeSelectSearch";

interface CustomInputComponentProps {
  props: InputBaseComponentProps;
  options: TreeItemOption[];
  simplifiedSelectedIds: string[];
  selected: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;
  onFocus?: () => void;
  onBlur?: () => void;
  color?: "error" | "warning" | "success" | "primary";
}

export const FoodAndAllergensInputComponent = (
  props: CustomInputComponentProps,
) => {
  const {
    props: inputProps,
    options,
    selected,
    setSelected,
    simplifiedSelectedIds,
    onFocus,
    onBlur,
    color = "primary",
  } = props;

  const dictionary = useMemo(() => buildDictionary(options), [options]);

  useEffect(() => {
    onBlur && onBlur();
  }, []);

  return (
    <div className="grid w-full">
      <InputComponentWrapper>
        {simplifiedSelectedIds.map((chip, index) => {
          const data = dictionary.get(chip);

          if (!data) return <></>;

          const { id, name } = data;

          return (
            <StyledChip
              onFocus={onFocus}
              onBlur={onBlur}
              key={index}
              label={name}
              color={color}
              onDelete={() =>
                handleCheckboxChange(id, selected, options, setSelected)
              }
            />
          );
        })}

        <InputStyled {...omit(inputProps, "className")} />
      </InputComponentWrapper>
    </div>
  );
};
