import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const updateClientMobileAppAccess = async (
  id: string,
  payload: UpdateClientMobileAppRequest,
) => {
  await fetchData(
    `/dietitian/patients/${id}/active-mobile-app`,
    APIMethods.PATCH,
    payload,
  );
};

export interface UpdateClientMobileAppRequest {
  hasAccess: boolean;
}
