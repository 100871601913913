import { LoadingButton } from "@mui/lab";
import { MenuItem, styled } from "@mui/material";

export const MenuItemStyled = styled(MenuItem)`
  width: 11.875rem;
  min-height: unset;
  height: 2.5rem;
  padding: 0.5rem 0.75rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const LoadingButtonStyled = styled(LoadingButton)`
  width: 16.25rem;
  align-self: center;
`;
