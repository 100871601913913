import { useAppTranslation } from "@hooks";
import { Chip } from "@mui/material";

interface AlreadyInDietChipProps {
  show: boolean;
}
export const AlreadyInDietChip = ({ show }: AlreadyInDietChipProps) => {
  const { t } = useAppTranslation();

  if (!show) return null;
  return <Chip color="info" label={t("diet.recipe_sidepanel.product_used")} />;
};
