import {
  CloseButton,
  DrawerHeader,
  KnowledgeButton,
  StyledDrawer,
  StyledList,
} from "./WhatsNewList.styled";
import { Close } from "@assets/icons";
import { useAppTranslation } from "@hooks";
import { WhatsNewItem } from "./components/WhatsNewItem";
import { Link } from "react-router-dom";
import { whatsNewListStub } from "@components/WhatsNewList";
import { Error404 } from "@views/emptyStates/Error404";

interface WhatsNewListProps {
  onClose?: () => void;
  opened: boolean;
}

export const WhatsNewList = ({ onClose, opened }: WhatsNewListProps) => {
  const { t } = useAppTranslation();
  const knowledgeLink =
    "https://www.youtube.com/playlist?list=PLLkOkRlsDcT5jbMV7XocVpy1mJZQuOy18";

  return (
    <StyledDrawer
      variant="persistent"
      anchor="right"
      open={opened}
      onClick={e => e.stopPropagation()}
    >
      <DrawerHeader>
        {t("whatsNew.title")}
        <div className="flex items-center">
          <Link to={knowledgeLink} target="_blank" rel="noopener noreferrer">
            <KnowledgeButton>{t("whatsNew.video_tutorial")}</KnowledgeButton>
          </Link>
          <CloseButton onClick={onClose} size="small">
            <Close size="w-[10px] h-[10px]" />
          </CloseButton>
        </div>
      </DrawerHeader>
      <StyledList onClick={e => e.stopPropagation()}>
        {whatsNewListStub?.length ? (
          whatsNewListStub?.map(({ id, date, type, title, content, url }) => (
            <WhatsNewItem
              key={id}
              id={id.toString()}
              date={date}
              opened={opened}
              type={type}
              title={title}
              content={content}
              url={url}
            />
          ))
        ) : (
          <Error404 />
        )}
      </StyledList>
    </StyledDrawer>
  );
};
