import { ReactNode } from "react";

import { Divider, useTheme } from "@mui/material";
import {
  CalendarWrapper,
  DividerWithMargin,
  WeeksButtonWrapper,
} from "./DateRangePicker.styled";
import {
  CalendarButtonProps,
  CalendarCustomButtons,
} from "./CalendarCustomButtons";

interface CalendarContainerProps {
  children: ReactNode;
  buttons: CalendarButtonProps[];
  bottomBorder?: boolean;
  isFooterDisplayed: boolean;
}

export const CalendarContainer = ({
  children,
  buttons,
  bottomBorder = false,
  isFooterDisplayed,
}: CalendarContainerProps) => {
  const { colors } = useTheme();

  return (
    <div className="px-4 py-2">
      <CalendarWrapper wideGap={bottomBorder}>{children}</CalendarWrapper>
      {isFooterDisplayed && (
        <>
          <Divider color={colors.borderGray} />
          <WeeksButtonWrapper>
            <CalendarCustomButtons buttons={buttons} />
          </WeeksButtonWrapper>
          {bottomBorder && <DividerWithMargin color={colors.borderGray} />}
        </>
      )}
    </div>
  );
};
