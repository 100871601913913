import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { logFetchValidateError } from "@utils/validate";
import * as yup from "yup";
import {
  PatientProfileShowNutrientResource,
  patientShowNutrientsSchema,
} from "@client/resources/PatientProfileShowNutrientResource";

export type FetchPatientShowNutrientsParams = {
  patientId: number;
};

export type FetchPatientShowNutrientsResponse =
  ApiResponse<PatientProfileShowNutrientResource>;

export const fetchPatientShowNutrients = async (
  params: FetchPatientShowNutrientsParams,
): Promise<FetchPatientShowNutrientsResponse> => {
  const response = await fetchData(
    "/dietitian/patients/{patient_id}/show-nutrients".replace(
      "{patient_id}",
      params.patientId.toString(),
    ),
    APIMethods.GET,
  );

  return await schema
    .validate(response)
    .catch(
      logFetchValidateError("/dietitian/patients/{patient_id}/show-nutrients"),
    );
};

const schema = yup.object({
  data: patientShowNutrientsSchema.defined(),
});
