import { styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

export interface StyledDataGrid {
  stickyFirstCell?: boolean;
  stickyLastCell?: boolean;
}

export const StyledDataGrid = styled(DataGrid, {
  shouldForwardProp: propName =>
    !["stickyFirstCell", "stickyLastCell"].includes(propName as string),
})<StyledDataGrid>`
  background-color: transparent;
  border: unset;

  & .MuiDataGrid-columnSeparator.MuiDataGrid-columnSeparator--sideRight {
    color: transparent;
  }

  & .MuiDataGrid-row {
    background-color: ${({ theme }) => theme.colors.neutral.light[100]};
    box-shadow: unset;
    border-radius: 0.5rem;

    &:hover {
      background: ${({ theme }) => theme.colors.neutral.light[100]};
    }
  }

  & .MuiDataGrid-columnHeaders {
    border: unset;
    color: ${({ theme }) => theme.colors.neutral.dark[700]};
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    background-color: transparent;
    box-shadow: unset;
    min-height: 2rem !important;
  }

  & .MuiDataGrid-columnHeader {
    padding: 0 0.5rem;
    height: 2rem !important;

    &:focus,
    &:focus-within {
      outline: none;
    }
  }

  & .MuiDataGrid-columnHeaderTitle {
    font-weight: inherit;
  }

  & .MuiDataGrid-footerContainer {
    margin-top: 0.5rem;
    min-height: 2.5rem;
    border: unset;

    & .MuiToolbar-root {
      min-height: 2.5rem;
      height: 2.5rem;
      color: ${({ theme }) => theme.colors.neutral.dark[700]};
      padding: 0;
    }

    & .MuiTablePagination-root {
      width: 100%;
      overflow: unset;
    }

    & .MuiTablePagination-spacer {
      display: none;
    }

    & .MuiPagination-root.MuiPagination-text.MuiTablePagination-actions {
      margin-left: auto;
    }
  }

  & .MuiDataGrid-cell {
    border-bottom: unset;
    padding: 0 0.25rem;
    border-radius: 0.5rem;

    &:focus,
    &:focus-within {
      outline: none;
    }

    ${({ stickyFirstCell, theme }) =>
      stickyFirstCell &&
      `
      &:first-of-type {
        position: sticky;
        z-index: 100;
        left: 0;
        background: ${theme.colors.neutral.light[100]};
      }
    `};

    ${({ stickyLastCell, theme }) =>
      stickyLastCell &&
      `
      &:last-of-type {
        position: sticky;
        z-index: 100;
        right: 0;
        background: ${theme.colors.neutral.light[100]};
      }
    `};
  }

  & .MuiDataGrid-cell--textLeft:first-of-type {
    font-weight: 600;
  }

  & .MuiDataGrid-virtualScroller {
    margin-top: 2rem !important;
  }
` as typeof DataGrid;

export const StyleHeaders = styled("div")`
  &.headerItem {
    display: flex;
    align-items: center;
    background: ${({ theme }) => theme.colors.neutral.light[100]};
    padding: 5px;
    position: absolute;
    z-index: 100;
    top: 0;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    height: 2rem;
    color: ${({ theme }) => theme.colors.neutral.dark[700]};
    background: ${({ theme }) => theme.palette.primary.light};

    &.stickyName {
      left: 0;
      flex: 1;
      min-width: 15rem;
    }

    &.stickyAction {
      right: 0;
    }
  }
`;
