import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const patchDietitianPassword = (
  payload: PatchDietitianPasswordRequest,
) => {
  return fetchData("/dietitian/account/password", APIMethods.PATCH, payload);
};

export interface PatchDietitianPasswordRequest {
  oldPassword: string;
  password: string;
  passwordConfirmation: string;
}
