import {
  ProductsReplacementRequest,
  postDietsProductsReplacement,
} from "@client/diets";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "../types";
import { fetchDietProductsListQueryKey } from "./useFetchDietProductsListQuery";

export const usePostDietsProductsReplacementMutation = (
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    UsePostDietsProductsReplacementMutationIf
  >(({ dietId, payload }) => postDietsProductsReplacement(dietId, payload), {
    ...options,
    onSuccess: (d, v) => {
      options?.onSuccess && options.onSuccess(d, v);
      queryClient.invalidateQueries([fetchDietProductsListQueryKey, v.dietId]);
    },
  });
};

interface UsePostDietsProductsReplacementMutationIf {
  dietId: string;
  payload: ProductsReplacementRequest;
}
