import { useAppParams } from "@hooks";
import {
  RecipeEditContent,
  ScheduleRecipeEditContent,
} from "../RecipeEditContent";
import { StyledDrawer } from "./RecipeEditDrawer.styled";
import { RecipeId } from "./RecipeEditDrawer";

interface ScheduleRecipeEditDrawerProps {
  open: boolean;
  onClose: () => void;
  mealId: string;
  dayId: string;
}

export const ScheduleRecipeEditDrawer = ({
  open,
  onClose,
  mealId,
  dayId,
}: ScheduleRecipeEditDrawerProps) => {
  const { dietId } = useAppParams();

  return (
    <StyledDrawer
      open={open}
      variant="persistent"
      anchor="right"
      onClose={onClose}
    >
      {open && (
        <ScheduleRecipeEditContent
          mealId={mealId}
          dayId={dayId}
          onClose={onClose}
        />
      )}
    </StyledDrawer>
  );
};
