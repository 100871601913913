import { Control, FieldValues, Path, useController } from "react-hook-form";
import { TreeItemOption, TreeSelectSearch } from "./TreeSelectSearch";
import { CustomInputComponentProps } from "./CutomInputComponent";

interface FormTreeSelectSearchProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  options: TreeItemOption[];
  onBlur?: () => void;
  onFocus?: () => void;
  placeholder?: string;
  color?: "error" | "warning" | "success" | "primary";
  startAdornment?: JSX.Element;
  InputComponent?: (props: CustomInputComponentProps) => JSX.Element;
}
export const FormTreeSelectSearch = <T extends FieldValues>({
  control,
  name,
  options,
  onFocus,
  onBlur: onBlurExternal,
  placeholder,
  color,
  startAdornment,
  InputComponent,
}: FormTreeSelectSearchProps<T>) => {
  const {
    field: { value, onBlur, onChange, ref },
  } = useController({ control, name });

  const onBlurWrapper = () => {
    onBlur(), onBlurExternal && onBlurExternal();
  };

  return (
    <TreeSelectSearch
      options={options}
      value={value}
      onChange={onChange}
      onBlur={onBlurWrapper}
      onFocus={onFocus}
      ref={ref}
      placeholder={placeholder}
      color={color}
      startAdornment={startAdornment}
      InputComponent={InputComponent}
    />
  );
};
