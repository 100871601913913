import {
  SaveDietMealCreatorRequest,
  postCreatorDietMeal,
} from "@client/diets/creator";
import { MutationOptions } from "@hooks/queries/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCreatorVersion } from "./useCreatorVersion";
import { fetchCreatorDietMealsQueryKey } from "./useFetchCreatorDietMealsQuery";
import { fetchCreatorDietQueryKey } from "./useFetchCreatorDietQuery";
import { searchDietsQueryKeyNew } from "@hooks/queries/useSearchDietsQueryNew";

export const usePostCreatorDietMealMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();
  const { version } = useCreatorVersion();

  return useMutation<unknown, unknown, UsePostCreatorDietMealMutationIf>(
    ({ dietId, payload }) => postCreatorDietMeal(dietId, payload, version),
    {
      ...options,
      onSuccess: (d, v) => {
        options?.onSuccess && options.onSuccess(d, v);
        queryClient.invalidateQueries([
          fetchCreatorDietMealsQueryKey,
          v.dietId,
        ]);
        queryClient.invalidateQueries([fetchCreatorDietQueryKey, v.dietId]);
        queryClient.invalidateQueries([searchDietsQueryKeyNew]);
      },
    },
  );
};

interface UsePostCreatorDietMealMutationIf {
  dietId: string;
  payload: SaveDietMealCreatorRequest;
}
