import image from "@assets/img/noResults.svg";
import { Button, Typography } from "@mui/material";
import { ThemeProviderWrapperNew } from "themeNew";
import {
  ContentWrapper,
  FiltersNoResultsStyled,
  NoResultsInfo,
  NoResultsText,
} from "./FiltersNoResults.styled";
import { useAppTranslation } from "@hooks";

interface FiltersNoResultsProps {
  onClick?: () => void;
}

export const FiltersNoResults = ({ onClick }: FiltersNoResultsProps) => {
  const { t } = useAppTranslation();

  return (
    <ThemeProviderWrapperNew>
      <FiltersNoResultsStyled>
        <ContentWrapper>
          <img src={image} alt={t("empty_states.alt.filters")} />

          <NoResultsText>{t("empty_states.no_results")}</NoResultsText>

          <NoResultsInfo>{t("empty_states.filters_info")}</NoResultsInfo>
        </ContentWrapper>

        {onClick && (
          <Button onClick={onClick}>{t("empty_states.clear_filers")}</Button>
        )}
      </FiltersNoResultsStyled>
    </ThemeProviderWrapperNew>
  );
};
