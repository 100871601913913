import * as yup from "yup";

export interface PatientMeasurementResource {
  id: number;
  source: {
    id: number;
  };
  date: string;
  photos: Image[];
  bodyMeasurements: BodyMeasurement[];
}

interface Image {
  id: number;
  url: string;
  type: {
    id: number;
  };
  name: string | null;
  media: {
    originalName: string;
  };
}

interface BodyMeasurement {
  body: {
    id: number;
  };
  value: number | null;
}

const bodyMeasurementPhotoSchema = yup.object({
  id: yup.number().required(),
  url: yup.string().required(),
  type: yup
    .object({
      id: yup.number().required(),
    })
    .required(),
  name: yup.string().nullable().defined(),
  media: yup
    .object({
      originalName: yup.string().required(),
    })
    .required(),
});

const bodyMeasurement = yup.object({
  body: yup
    .object({
      id: yup.number().required(),
    })
    .required(),
  value: yup.number().nullable().defined(),
});

export const patientMeasurementSchema = yup.object({
  id: yup.number().required(),
  source: yup
    .object({
      id: yup.number().required(),
    })
    .required(),
  date: yup.string().required(),
  photos: yup.array().of(bodyMeasurementPhotoSchema).defined(),
  bodyMeasurements: yup.array().of(bodyMeasurement).defined(),
});
