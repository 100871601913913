import { deleteFoodImage } from "@client/food";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "../types";
import { fetchProductQueryKey } from "./useFetchProductQuery";
import { useFetchFoodQueryKey } from "./useFetchFoodQuery";

export const useDeleteFoodImageMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, string>(id => deleteFoodImage(id), {
    ...options,
    onSuccess: (data, foodId) => {
      queryClient.invalidateQueries([fetchProductQueryKey, foodId]);
      queryClient.invalidateQueries([useFetchFoodQueryKey, Number(foodId)]);
    },
  });
};
