import { Button, styled } from "@mui/material";

export const ModalDeleteActions = styled("div")`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  gap: 8px;
  padding: 12px;
  justify-content: flex-end;
  background-color: ${({ theme }) => theme.colors.primaryLightNew};
`;

export const ModalDeleteButton = styled(Button)`
  font-size: 14px;
  padding: 0 12px;
  height: 40px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.error.main};
  color: white;
`;

export const ModalCancelButton = styled(Button)`
  font-size: 14px;
  padding: 0 12px;
  height: 40px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.main};
`;
