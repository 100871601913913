import { useState } from "react";

import { Checkbox } from "@mui/material";

import { Clock, Cutlery, PieChart } from "@assets/icons";
import { CreatorDietMealDto, RecipeItemDto } from "@client/diets/creator";
import { MacrosText } from "@components/MacrosText";
import { ModalWrapper } from "@components/ModalWrapperNew";
import { OuterLabel } from "@components/OuterLabel";
import { TagWithIcon } from "@components/TagWithIcon";
import { useAppParams, useAppTranslation } from "@hooks";
import {
  useFetchCreatorDietMealsQuery,
  usePostCreatorDietMealsItemsMutation,
} from "@hooks/queries/diets/creator";
import { useTagsNew } from "@hooks/useTagsNew";
import { Nutrient } from "@typeDefinitions";
import { TagCategoryType } from "@utils/tagsNew";
import { getTranslation } from "@utils/translations";

import {
  FormControlLabelStyled,
  RecordWrapper,
  Title,
} from "./CopyRecipeModal.styled";

interface RecipeIf {
  id: number;
  name: string;
  nutrients: Nutrient[];
  tags: number[];
  servings: number;
}

interface CopyRecipeModalProps {
  open: boolean;
  onClose: () => void;
  recipe: RecipeIf;
}

export const CopyRecipeModal = ({
  open,
  onClose,
  recipe,
}: CopyRecipeModalProps) => {
  const { t, currentLanguage } = useAppTranslation();
  const { dietId } = useAppParams();
  const { meals } = useFetchCreatorDietMealsQuery(dietId, { enabled: open });
  const { mutate, isLoading } = usePostCreatorDietMealsItemsMutation({
    onSuccess: () => handleClose(),
  });
  const { name, nutrients, tags, servings } = recipe;

  const [selectedMeals, setSelectedMeals] = useState<number[]>([]);

  const { tagCategoryDictionary, tagNameDictionary } = useTagsNew();
  const totalServings = servings;
  const hasMultipleServings = totalServings > 1;

  const difficultyOfPreparationTagList = tagCategoryDictionary.get(
    TagCategoryType.DIFFICULTY_OF_PREPARATION,
  )?.tags;
  const preparationTimeTagList = tagCategoryDictionary.get(
    TagCategoryType.PREPARATION_TIME,
  )?.tags;

  const difficultyOfPreparationTag = tags?.find(tag =>
    difficultyOfPreparationTagList?.some(t => t.id === tag),
  );
  const preparationTimeTag = tags?.find(tag =>
    preparationTimeTagList?.some(t => t.id === tag),
  );

  const handleToggleCheckbox = (mealId: number) => {
    selectedMeals.includes(mealId)
      ? setSelectedMeals(selectedMeals.filter(id => id !== mealId))
      : setSelectedMeals(state => [...state, mealId]);
  };

  const handleSubmit = () => {
    mutate({ dietId, payload: { data: mapRequest(recipe, selectedMeals) } });
  };

  const handleClose = () => {
    onClose();
    setSelectedMeals([]);
  };

  return (
    <ModalWrapper
      open={open}
      onClose={handleClose}
      title={t("diet.copy_recipe.title")}
      onSubmit={handleSubmit}
      loading={isLoading}
    >
      <div className="flex flex-col gap-[24px]">
        <OuterLabel label={t("diet.copy_recipe.selected_recipes")}>
          <RecordWrapper>
            <Title>{name}</Title>
            <div className="flex gap-[16px]">
              <MacrosText nutrients={nutrients} />
              {!!preparationTimeTag && (
                <TagWithIcon
                  Icon={Clock}
                  label={tagNameDictionary.get(Number(preparationTimeTag))}
                />
              )}
              {!!difficultyOfPreparationTag && (
                <TagWithIcon
                  Icon={Cutlery}
                  label={tagNameDictionary.get(
                    Number(difficultyOfPreparationTag),
                  )}
                />
              )}
              {hasMultipleServings && (
                <TagWithIcon
                  Icon={PieChart}
                  label={`${totalServings} ${t("recipe.portions")}`}
                />
              )}
            </div>
          </RecordWrapper>
        </OuterLabel>

        {meals && (
          <OuterLabel label={t("diet.copy_recipe.copy_to")}>
            <div className="flex flex-col gap-[8px] w-full">
              {meals.map(({ translations, id }) => (
                <FormControlLabelStyled
                  control={
                    <Checkbox
                      checked={selectedMeals.includes(id)}
                      onChange={() => handleToggleCheckbox(id)}
                    />
                  }
                  label={getTranslation(translations, currentLanguage)}
                />
              ))}
            </div>
          </OuterLabel>
        )}
      </div>
    </ModalWrapper>
  );
};

const mapRequest = (
  { id, servings }: RecipeIf,
  mealIds: number[],
): CreatorDietMealDto[] => {
  const recipeItem: RecipeItemDto = {
    id: id,
    type: "recipe",
    servings: servings,
  };

  return mealIds.map(mealId => ({
    meal: { id: mealId },
    item: recipeItem,
  }));
};
