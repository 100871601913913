import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const updateClientRecipeBase = async (
  id: number,
  payload: UpdateClientRecipeBaseRequest,
) => {
  await fetchData(
    `/dietitian/patients/${id}/application-modules/recipe-base`,
    APIMethods.PUT,
    payload,
  );
};

export interface UpdateClientRecipeBaseRequest {
  alloweat: boolean;
  clinic: boolean;
}
