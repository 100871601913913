import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const GreyGraph = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      style={{ fill: "none" }}
      width="14"
      height="15"
      viewBox="0 0 14 15"
    >
      <path
        d="M7.5 0.5C7.91667 0.520833 8.27083 0.666667 8.5625 0.9375C8.83333 1.22917 8.97917 1.58333 9 2V13C8.97917 13.4167 8.83333 13.7708 8.5625 14.0625C8.27083 14.3333 7.91667 14.4792 7.5 14.5H6.5C6.08333 14.4792 5.72917 14.3333 5.4375 14.0625C5.16667 13.7708 5.02083 13.4167 5 13V2C5.02083 1.58333 5.16667 1.22917 5.4375 0.9375C5.72917 0.666667 6.08333 0.520833 6.5 0.5H7.5ZM7.5 2H6.5V13H7.5V2ZM2.5 6.5C2.91667 6.52083 3.27083 6.66667 3.5625 6.9375C3.83333 7.22917 3.97917 7.58333 4 8V13C3.97917 13.4167 3.83333 13.7708 3.5625 14.0625C3.27083 14.3333 2.91667 14.4792 2.5 14.5H1.5C1.08333 14.4792 0.729167 14.3333 0.4375 14.0625C0.166667 13.7708 0.0208333 13.4167 0 13V8C0.0208333 7.58333 0.166667 7.22917 0.4375 6.9375C0.729167 6.66667 1.08333 6.52083 1.5 6.5H2.5ZM2.5 8H1.5V13H2.5V8ZM10 4C10.0208 3.58333 10.1667 3.22917 10.4375 2.9375C10.7292 2.66667 11.0833 2.52083 11.5 2.5H12.5C12.9167 2.52083 13.2708 2.66667 13.5625 2.9375C13.8333 3.22917 13.9792 3.58333 14 4V13C13.9792 13.4167 13.8333 13.7708 13.5625 14.0625C13.2708 14.3333 12.9167 14.4792 12.5 14.5H11.5C11.0833 14.4792 10.7292 14.3333 10.4375 14.0625C10.1667 13.7708 10.0208 13.4167 10 13V4ZM11.5 13H12.5V4H11.5V13Z"
        fill="#727272"
      />
    </SvgIcon>
  );
};
