import { compact, difference, round } from "lodash";

import {
  FetchClientBalanceResponse,
  SuggestedEnergyDto,
  UpdateClientBalanceRequest,
} from "@client";
import { FrequencyDay } from "@utils/balance";
import { CaloricNeedsFormInputs } from "@components/CaloricNeedsForm";
import { MacrosSliderMacros } from "@components/MacrosSliders";

import { NamesIf } from "./BalanceDay";

export const mapBalanceRequest = (
  dayId: string,
  balanceData: FetchClientBalanceResponse,
  names: NamesIf,
  frequency: FrequencyDay[],
  caloricNeeds: CaloricNeedsFormInputs,
): UpdateClientBalanceRequest => {
  return {
    type: balanceData.type,
    days: balanceData.days.map(day => {
      if (day.id.toString() === dayId) {
        return {
          ...day,
          frequency,
          translations: [
            { locale: "pl", name: names.pl },
            { locale: "en", name: names.en },
          ],
          energyDemand: {
            spm: {
              value: caloricNeeds.spm.value,
              method: caloricNeeds.spm.method,
            },
            pattern: caloricNeeds.spm.formula,
            pal: {
              value: caloricNeeds.pal.value,
              method: caloricNeeds.pal.method,
            },
            activity: {
              training: caloricNeeds.pal.training,
              daily: caloricNeeds.pal.daily,
            },
            cpm: {
              value: caloricNeeds.cpm.value,
              method: caloricNeeds.cpm.method,
            },
          },
        };
      } else
        return {
          ...day,
          frequency: difference(day.frequency, frequency),
        };
    }),
  };
};

export const mapBalanceWithMacrosSlider = (
  dayId: string,
  balanceData: FetchClientBalanceResponse,
  data: MacrosSliderMacros,
): UpdateClientBalanceRequest => {
  return {
    type: balanceData.type,
    days: balanceData.days.map(day =>
      day.id.toString() === dayId
        ? {
            ...day,
            suggestedEnergy: {
              kcal: round(data.energy),
              protein: round(data.protein),
              fat: round(data.fat),
              carb: round(data.carbs),
            },
          }
        : day,
    ),
  };
};

export const calculateDailyKcal = (cpm: number, weightLoss: boolean) => {
  if (cpm < 550 && weightLoss) return 500;
  else {
    if (weightLoss) return cpm - 550;
    else return cpm + 550;
  }
};

export const calculateMacro = (
  kcal: number,
  percent: number,
  macro: "p" | "f" | "c",
) => {
  if (macro === "f") return (kcal / 9 / 100) * percent;
  else return (kcal / 4 / 100) * percent;
};

export const mapMacrosForGoal = (
  suggested: SuggestedEnergyDto | undefined,
  cpm: number | null,
  weightLoss: boolean,
) => {
  if (!!suggested && compact(Object.values(suggested)).length === 4) {
    return {
      kcal: suggested.kcal as number,
      protein: suggested.protein as number,
      fat: suggested.fat as number,
      carbs: suggested.carb as number,
    };
  } else if (cpm) {
    const kcal = calculateDailyKcal(cpm, weightLoss);
    return {
      kcal,
      protein: calculateMacro(kcal, 25, "p"),
      fat: calculateMacro(kcal, 28, "f"),
      carbs: calculateMacro(kcal, 47, "c"),
    };
  } else {
    return DEFAULT_SLIDER_VALUES;
  }
};

export const DEFAULT_SLIDER_VALUES = {
  kcal: 500,
  protein: 0,
  fat: 0,
  carbs: 0,
};
