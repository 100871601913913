import { useQuery } from "@tanstack/react-query";
import { fetchDictionaryNutrients, Nutrients } from "@client/dictionaries";
import { LanguagesSlugs } from "@typeDefinitions";

export const fetchDictionaryNutrientsQueryKey =
  "fetchDictionaryNutrientsQueryKey";

export function useFetchDictionaryNutrients() {
  return useQuery(
    [fetchDictionaryNutrientsQueryKey],
    fetchDictionaryNutrients,
    {
      retryOnMount: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
    },
  );
}

export const getNutrientsById = (
  nutrients: Nutrients[],
  nutrientId: number,
) => {
  return nutrients.find(nutrient => nutrient.id === nutrientId);
};

export const getNameByLang = (nutrient: Nutrients, lang: LanguagesSlugs) => {
  if (lang === LanguagesSlugs.EN) {
    return nutrient.nameEn;
  }

  return nutrient.namePl;
};
