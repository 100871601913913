import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "react-use";

import _ from "lodash";
import { useMediaQuery, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { useAppParams, useAppTranslation } from "@hooks";
import {
  MultiLanguageInputStyled,
  ProgramDayConfigStyled,
  WeekdaySection,
} from "./ProgramDayConfig.styled";
import { useProgram } from "@hooks/resources/useProgram";
import { useUpdateProgramDays } from "@hooks/queries";
import { ProgramEmptyDietContext } from "@context";
import { WeekdaySelector } from "@components/WeekdaySelector";
import { ConfirmationWrapper } from "@components/ConfirmationWrapper";

export const ProgramDayConfig = () => {
  const { t } = useAppTranslation();
  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(`${breakpoints.up("desktop")}`);
  const { hideWarningBorder } = useContext(ProgramEmptyDietContext);

  const { programId, dayId } = useAppParams();
  const { program } = useProgram();

  const [isDeleting, setIsDeleting] = useState(false);
  const [names, setNames] = useState<Record<string, string> | null>(null);
  const [days, setDays] = useState<number[] | null>(null);

  const day = useMemo(
    () => program?.days.find(day => day.id.toString() === dayId),
    [program, dayId],
  );

  useEffect(() => {
    if (program && day) {
      setNames({ pl: day.name, en: day.nameEn ?? "" });
      setDays(day.frequency ?? []);
    }
  }, [day]);
  const { mutate } = useUpdateProgramDays(programId);

  useDebounce(
    () => {
      if (program && names !== null && days !== null) {
        if (
          day &&
          names.pl === day.name &&
          names.en === day.nameEn &&
          _.isEqual(day.frequency, days)
        )
          return;

        mutate({
          days: program.days.map(day => {
            if (day.id.toString() === dayId) {
              return {
                ..._.omit(day, ["diet", "targetNutrients"]),
                name: names.pl === "" ? day.name : names.pl,
                nameEn: names.en === "" ? null : names.en,
                frequency: days,
              };
            } else {
              return {
                ..._.omit(day, ["diet", "targetNutrients"]),
                frequency: _.difference(day.frequency, days),
              };
            }
          }),
        });
      }
    },
    250,
    [names, days, day],
  );

  const handleDays = (active: number[]) => {
    const currentDays = program?.days.flatMap(day => day.frequency) ?? [];
    const allDays = [...currentDays, ...active];

    if (allDays.length > 1) {
      setDays(active);
    }
  };

  const navigate = useNavigate();

  const removeDay = () => {
    if (program) {
      // markModified();
      setIsDeleting(true);
      hideWarningBorder();
      mutate(
        {
          days: program.days
            .filter(day => day.id.toString() !== dayId)
            .map(day => _.omit(day, ["diet", "targetNutrients"])),
        },
        {
          onSuccess: () => {
            navigate("../");
          },
          onSettled: () => {
            setIsDeleting(false);
          },
        },
      );
    }
  };

  if (names === null || days === null) return <div />;

  return (
    <>
      <ProgramDayConfigStyled>
        <MultiLanguageInputStyled
          languages={Object.keys(names)}
          onChange={(lang, value) =>
            setNames(state => ({ ...state, [lang]: value ?? "" }))
          }
          value={lang => names[lang]}
          label={t("program.days.name")}
          inputProps={{ maxLength: 200 }}
        />
        <WeekdaySection>
          {isDesktop && (
            <WeekdaySelector active={days} size="large" onChange={handleDays} />
          )}
          <ConfirmationWrapper
            onConfirm={removeDay}
            confirmationText={t("program.days.delete_confirmation")}
            submitText={t("common.yes")}
            disabled={program?.days.length === 1}
          >
            <LoadingButton
              variant="outlined"
              color="error"
              size="small"
              disabled={program?.days.length === 1}
              loading={isDeleting}
            >
              {t("program.days.delete")}
            </LoadingButton>
          </ConfirmationWrapper>
        </WeekdaySection>
      </ProgramDayConfigStyled>
      {!isDesktop && (
        <WeekdaySelector active={days} size="large" onChange={handleDays} />
      )}
    </>
  );
};
