import { useMemo } from "react";

import { Accordion, Divider, Typography } from "@mui/material";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";

import { MacrosListMui } from "@components/MacrosList";
import { MicronutrientPreview } from "@components/MicronutrientsModal";
import { Nutrient } from "@typeDefinitions";
import { LangDto, TranslationTitleDto } from "@client";
import { useAppTranslation } from "@hooks";
import { RecipeTable } from "@components/RecipeCompositionSection/RecipeTable";
import { Row } from "@components/RecipeCompositionSection";
import { Pacman } from "@assets/icons";
import { LabeledDetail } from "@components/PreviewDetails";

import {
  AccordionDetailsStyled,
  AccordionSummaryStyled,
  MicronutrientsWrapper,
  RecipeDetailsWrapper,
  RecipeName,
  RecipeNameWrapper,
} from "./RecipeItem.styled";

interface MealIf {
  translations: TranslationTitleDto[];
  hour: string | null;
}
interface RecipeIf {
  translations?: TranslationTitleDto[];
  servings: number;
}
interface MealWrapperIf {
  nutrients: Nutrient[];
  recipe: RecipeIf | null;
  meal: MealIf;
  servings: number;
  liked: boolean;
}

interface RecipeItemProps {
  ingredients?: Row[];
  expanded: boolean;
  onToggleExpand: () => void;
  isLiked: boolean;
  isLast: boolean;
  meal: MealWrapperIf;
}

export const RecipeItem = ({
  ingredients = [],
  expanded,
  onToggleExpand,
  isLiked,
  isLast,
  meal,
}: RecipeItemProps) => {
  const { isPolishChosen, t } = useAppTranslation();
  const {
    nutrients,
    servings,
    meal: { hour, translations: titles },
    recipe,
  } = meal;
  const recipeNames = recipe?.translations ?? [];
  const totalServings = recipe?.servings ?? 1;

  const getTranslatedTitle = (
    translations: TranslationTitleDto[],
    lang: LangDto,
  ) => translations.find(t => t.langId === lang)?.title;

  const title = useMemo(
    () =>
      isPolishChosen
        ? getTranslatedTitle(titles, LangDto.PL)
        : getTranslatedTitle(titles, LangDto.EN),
    [titles, isPolishChosen],
  );
  const recipeName = useMemo(
    () =>
      isPolishChosen
        ? getTranslatedTitle(recipeNames, LangDto.PL)
        : getTranslatedTitle(recipeNames, LangDto.EN),
    [recipeNames, isPolishChosen],
  );

  return (
    <div>
      <div className="grid p-2">
        <div className="flex">
          <Typography variant="h6">
            {title}, {!!hour && hour}
          </Typography>
        </div>

        <RecipeNameWrapper>
          {isLiked && <FavoriteRoundedIcon color="primary" />}
          <RecipeName variant="body1">{recipeName}</RecipeName>
        </RecipeNameWrapper>

        <Accordion expanded={expanded}>
          <AccordionSummaryStyled onClick={onToggleExpand}>
            <MicronutrientsWrapper>
              <MacrosListMui nutrients={nutrients} fixed />
              <MicronutrientPreview means={nutrients} noPadding />
            </MicronutrientsWrapper>
          </AccordionSummaryStyled>

          <AccordionDetailsStyled>
            <div className="w-full overflow-x-auto">
              <RecipeTable
                rows={ingredients}
                portionRatio={1}
                recipeNutrients={nutrients}
                hideMicros
                calculateNutrients={false}
                stickyHeader={false}
              />
            </div>

            <RecipeDetailsWrapper>
              {totalServings > 1 && <Pacman size="w-5 h-5" />}
              <LabeledDetail
                label={`${t("recipe.servings_count")}:`}
                value={`${servings} / ${totalServings} ${t("recipe.portions")}`}
              />
              <LabeledDetail
                label={`${t("recipe.products_count")}:`}
                value={ingredients.length.toString()}
              />
            </RecipeDetailsWrapper>
          </AccordionDetailsStyled>
        </Accordion>
      </div>
      {!isLast && <Divider />}
    </div>
  );
};
