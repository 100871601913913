import { useMemo } from "react";

import { CircularProgress } from "@mui/material";

import { DietProductExchangeItemDto } from "@client/diets";
import { useAppParams, useAppTranslation } from "@hooks";
import { useFetchDietsItemsProductsRemovedQuery } from "@hooks/queries/diets";
import { getTranslation } from "@utils/translations";

import { SmallText } from "../ProductAccordion.styled";
import { UsedInRow, UsedInRowProps } from "../UsedInRow";

interface RemovedProductAccordionContentProps {
  from: number;
}
export const RemovedProductAccordionContent = ({
  from,
}: RemovedProductAccordionContentProps) => {
  const { currentLanguage, t } = useAppTranslation();
  const { dietId } = useAppParams();
  const { data, isLoading } = useFetchDietsItemsProductsRemovedQuery(
    dietId,
    from.toString(),
  );

  const recipes = useMemo<UsedInRowProps[]>(
    () =>
      data
        ?.filter(
          (d): d is DietProductExchangeItemDto & { item: { type: "recipe" } } =>
            d.item.type === "recipe",
        )
        .map(({ item, meal }) => ({
          id: item.id,
          name: getTranslation(item.recipe.translations, currentLanguage),
          mealName: getTranslation(meal.translations, currentLanguage),
          exchange: false,
          mealId: meal.id.toString(),
          recipeIds: {
            dietRecipeId: item.id.toString(),
            recipeId: item.recipe.id.toString(),
          },
        })) ?? [],
    [data, currentLanguage],
  );

  const products = useMemo<UsedInRowProps[]>(
    () =>
      data
        ?.filter(
          (
            d,
          ): d is DietProductExchangeItemDto & { item: { type: "product" } } =>
            d.item.type === "product",
        )
        .map(({ item, meal }) => ({
          id: item.id,
          name: getTranslation(item.product.translations, currentLanguage),
          mealName: getTranslation(meal.translations, currentLanguage),
          exchange: false,
          mealId: meal.id.toString(),
          productIds: {
            dietProductId: item.id.toString(),
            productId: item.product.id.toString(),
          },
        })) ?? [],
    [data, currentLanguage],
  );

  if (isLoading)
    return (
      <div className="grid w-full items-center">
        <CircularProgress />
      </div>
    );

  return (
    <div className="grid gap-[8px]">
      {!!recipes?.length && (
        <div className="grid gap-[8px]">
          <SmallText>
            {t("diet.appears_in_recipes", { count: recipes.length })}:
          </SmallText>
          {recipes.map(props => (
            <UsedInRow key={props.id} {...props} />
          ))}
        </div>
      )}

      {!!products?.length && (
        <div className="grid gap-[8px]">
          <SmallText>{t("diet.appears_as_product")}:</SmallText>
          {products.map(props => (
            <UsedInRow key={props.id} {...props} />
          ))}
        </div>
      )}
    </div>
  );
};
