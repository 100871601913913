import { useUserRoles } from "@hooks";
import { useFetchSchedulesDictionariesQuery } from "@hooks/queries";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";

export const useCalendarFilterOptions = () => {
  const { account } = useFetchDietitianAccountQuery();
  const { isClinicAdmin, isWorker } = useUserRoles();

  const { schedulesDictionaries } = useFetchSchedulesDictionariesQuery();

  const dietitiansOptionsForAdmin = schedulesDictionaries?.dietitians.map(
    ({ id, firstName, lastName }) => ({
      id: id,
      label: `${firstName} ${lastName}`,
    }),
  );

  const dietitianOptionsForWorker = dietitiansOptionsForAdmin?.filter(
    ({ id }) => id === account?.id,
  );

  const dietitiansOptions = isClinicAdmin
    ? dietitiansOptionsForAdmin
    : dietitianOptionsForWorker;

  const allDietitiansIds = dietitiansOptions?.map(({ id }) => String(id));
  const currentDietitianId = [String(account?.id)];

  const defaultDietitianIdsForAdmin = isClinicAdmin
    ? allDietitiansIds
    : currentDietitianId;

  const defaultDietitianIds = isWorker ? defaultDietitianIdsForAdmin : [];

  return {
    defaultDietitianIds,
    currentDietitianId: String(account?.id),
    dietitiansOptionsForAdmin,
  };
};
