import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  ErrorData,
  postProgramFromDiet,
  PostProgramFromDietRequest,
  PostProgramFromDietResponse,
} from "@client/postProgramFromDiet";

import { MutationOptions } from "./types";
import {
  searchDietsInfiniteQueryKey,
  searchDietsQueryKeyNew,
} from "./useSearchDietsQueryNew";
import { ApiResponse } from "@typeDefinitions";
import { AxiosError } from "axios";

export const usePostProgramFromDietMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation<
    ApiResponse<PostProgramFromDietResponse>,
    AxiosError<ErrorData>,
    UsePostProgramFromDietMutationPayload
  >(({ clientId, payload }) => postProgramFromDiet(clientId, payload), {
    ...options,
    onSuccess: () => {
      const queryKeys = [searchDietsQueryKeyNew, searchDietsInfiniteQueryKey];
      queryKeys.forEach(key => queryClient.invalidateQueries([key]));
    },
  });
};

export interface UsePostProgramFromDietMutationPayload {
  clientId: string;
  payload: PostProgramFromDietRequest;
}
