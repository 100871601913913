import { useTagsNew } from "@hooks/useTagsNew";
import { TagCategoryType } from "@utils/tagsNew";

export const useTagLabel = (tags: number[]) => {
  const { tagCategoryDictionary, tagNameDictionary } = useTagsNew();

  const difficultyOfPreparationTagList = tagCategoryDictionary.get(
    TagCategoryType.DIFFICULTY_OF_PREPARATION,
  )?.tags;
  const preparationTimeTagList = tagCategoryDictionary.get(
    TagCategoryType.PREPARATION_TIME,
  )?.tags;

  const difficultyOfPreparationTag = tags.find(tag =>
    difficultyOfPreparationTagList?.some(t => t.id === tag),
  );
  const preparationTimeTag = tags.find(tag =>
    preparationTimeTagList?.some(t => t.id === tag),
  );

  const difficultyOfPreparationTagLabel =
    (difficultyOfPreparationTag &&
      tagNameDictionary.get(difficultyOfPreparationTag)) ||
    "";
  const preparationTimeTagLabel =
    (preparationTimeTag && tagNameDictionary.get(preparationTimeTag)) || "";
  return {
    difficultyOfPreparationTagLabel,
    preparationTimeTagLabel,
  };
};
