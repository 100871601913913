import { useQuery } from "@tanstack/react-query";

import {
  CreatorDietMealProductDto,
  fetchCreatorDietMealProduct,
} from "@client/diets/creator";
import { QueryOptionsTyped } from "@hooks/queries/types";
import { ApiResponse } from "@typeDefinitions";

import { useCreatorVersion } from "./useCreatorVersion";

export const fetchCreatorDietMealProductQueryKey =
  "fetchCreatorDietMealProductQueryKey";

export const useFetchCreatorDietMealProductQuery = (
  dietId: string,
  mealId: string,
  productId: string,
  options?: QueryOptionsTyped<ApiResponse<CreatorDietMealProductDto>>,
) => {
  const { version } = useCreatorVersion();

  const { data, ...rest } = useQuery(
    [fetchCreatorDietMealProductQueryKey, dietId, mealId, productId],
    () => fetchCreatorDietMealProduct(dietId, mealId, productId, version),
    options,
  );

  return {
    data: data?.data,
    ...rest,
  };
};
