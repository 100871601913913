import { styled, Typography } from "@mui/material";

interface StyledSpanProps {
  compact: boolean;
}

export const StyledSpan = styled("span", {
  shouldForwardProp: propName => propName !== "compact",
})<StyledSpanProps>`
  min-width: ${({ compact }) => (compact ? "65" : "70")}px;
`;

interface MacrosListRowProps {
  regularFont?: boolean;
}

export const MacrosListRow = styled("div", {
  shouldForwardProp: propName => propName !== "regularFont",
})<MacrosListRowProps>`
  display: flex;
  align-items: center;
  overflow-x: auto;
  min-height: 2rem;
  height: 100%;
  font-family: ${({ regularFont, theme }) =>
    regularFont ? theme.fontFamily.regular : "unset"};
`;

export const MacroDivider = styled("div")`
  height: 1rem;
  min-width: 1px;
  background-color: ${({ theme }) => theme.colors.tableBorder};
  margin: 0 1rem 0 1rem;
`;
