import { Box, CircularProgress } from "@mui/material";
import { ProgramLayout } from "@components/ProgramSearchPreview/Components/ProgramLayout.styled";
import ProgramHighlightedBox from "@components/ProgramSearchPreview/Components/ProgramHighlightedBox.styled";
import ProgramHeadLayout from "@components/ProgramSearchPreview/Components/Head/ProgramHeadLayout";
import ProgramComponentsTabs from "@components/ProgramSearchPreview/Components/ProgramComponentsTabs/ProgramComponentsTabs";
import useFetchPatientProgramSearchPreview from "@hooks/queries/client/program/preview/useFetchPatientProgramSearchPreview";
import usePatientProgramSearchPreviewRemoveQueries from "@components/PatientProgramSearchPreview/hooks/usePatientProgramSearchPreviewRemoveQueries";
import ProgramActionButtons from "@components/PatientProgramSearchPreview/Components/ActionButtons/ProgramActionButtons";
import ProgramPeriod from "@components/PatientProgramSearchPreview/Components/ProgramPeriod";
import { useState } from "react";
import ProgramComponentsTabsPanels from "@components/ProgramSearchPreview/Components/ProgramComponentsTabs/ProgramComponentsTabsPanels";

type ProgramPreviewProps = {
  programId: number;
  patientId: number;
};

const PatientProgramSearchPreview = ({
  programId,
  patientId,
}: ProgramPreviewProps) => {
  const [tabVisible, setTabVisible] = useState("1");

  usePatientProgramSearchPreviewRemoveQueries({ patientId, programId });
  const { data, isFetching } = useFetchPatientProgramSearchPreview(
    { programId: programId, patientId: patientId },
    { staleTime: Infinity },
  );
  const program = data?.data;

  if (program === undefined || isFetching) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ProgramLayout>
      <ProgramHighlightedBox>
        <ProgramHeadLayout
          program={program}
          actionButtons={
            <ProgramActionButtons program={program} patientId={patientId} />
          }
        />
        <ProgramPeriod {...program} />
        <ProgramComponentsTabs setValue={setTabVisible} value={tabVisible} />
      </ProgramHighlightedBox>
      <ProgramComponentsTabsPanels program={program} value={tabVisible} />
    </ProgramLayout>
  );
};

export default PatientProgramSearchPreview;
