import { GeometricFigures } from "@assets/icons";
import { SectionTitleWrapper } from "@components/PreviewDrawer/common/SectionTitleWrapper";
import { useAppTranslation } from "@hooks";

import { Header } from "./NutrientsByProduct.styled";
import { RecipeProductAccordion } from "../Accordions/RecipeProductAccordion/RecipeProductAccordion";

interface ProductIf {
  name: string;
  id: number;
  grams: number;
}
export interface NutrientsByProductProps {
  products: ProductIf[];
}

export const NutrientsByProduct = ({ products }: NutrientsByProductProps) => {
  const { t } = useAppTranslation();

  return (
    <SectionTitleWrapper
      Icon={GeometricFigures}
      title={t("common.micronutrients")}
    >
      <div className="grid gap-[8px]">
        <Header>{t("common.product")}</Header>

        {products.map((p, idx) => (
          <RecipeProductAccordion
            key={idx}
            name={p.name}
            id={p.id.toString()}
            grams={p.grams}
          />
        ))}
      </div>
    </SectionTitleWrapper>
  );
};
