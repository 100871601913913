import { useMemo } from "react";

import { GroupIf, TableOfContents } from "@components/TableOfContents";
import { TableOfContentsContextProvider } from "@context/TableOfContentsContext";
import { useAppParams } from "@hooks";
import { useFetchPatientSurveyQuery } from "@hooks/queries/surveys";

import { ThemeProviderWrapperNew } from "themeNew";
import {
  ContentStyled,
  ContentWrapper,
  TitleStyled,
} from "./PatientSurveyAnswer.styled";
import { TableOfContentsWrapper } from "./PatientSurveyModal.styled";
import { SubgroupContent } from "./SubgroupContent";

export const PatientSurveyAnswer = () => {
  const { patientId, questionnaireId } = useAppParams();
  const { data, isLoading, isSuccess } = useFetchPatientSurveyQuery(
    patientId,
    questionnaireId ?? "",
    { enabled: !!questionnaireId },
  );

  const groups: GroupIf[] = useMemo(
    () =>
      data?.survey.groups.map(g => ({
        id: g.id,
        name: g.title,
        subgroups: g.sections.map(s => ({ id: s.id, name: s.title })),
      })) ?? [],
    [data?.survey.groups],
  );

  const firstGroupId = groups?.[0]?.id;
  const firstSubgroupId = groups?.[0]?.subgroups[0]?.id;

  if (isSuccess)
    return (
      <ThemeProviderWrapperNew>
        <TitleStyled>{data?.survey.name}</TitleStyled>

        <ContentStyled>
          <TableOfContentsContextProvider
            defaultGroupId={firstGroupId}
            defaultSubgroupId={firstSubgroupId}
          >
            <ContentWrapper>
              <TableOfContentsWrapper>
                <TableOfContents groups={groups} />
              </TableOfContentsWrapper>

              <SubgroupContent surveyId={questionnaireId ?? ""} />
            </ContentWrapper>
          </TableOfContentsContextProvider>
        </ContentStyled>
      </ThemeProviderWrapperNew>
    );

  return <></>;
};
