import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  PostDietitianActivationDto,
  postDietitianActivation,
} from "@client/settings";
import { MutationOptions } from "../types";
import { fetchClinicDietitiansQueryKey } from "./useFetchClinicDietitiansQuery";

export const usePostDietitianActivation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, PostDietitianActivationProps>(
    ({ id, payload }) => postDietitianActivation(id, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([fetchClinicDietitiansQueryKey]);
        options?.onSuccess && options?.onSuccess();
      },
      ...options,
    },
  );
};

interface PostDietitianActivationProps {
  id: string;
  payload: PostDietitianActivationDto;
}
