import * as yup from "yup";

import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const createClientMeasurement = async (
  id: string,
  payload: CreateClientMeasurementRequest,
): Promise<CreateClientMeasurementResponse> => {
  const response = await fetchData(
    `/dietitian/patients/${id}/measurements`,
    APIMethods.POST,
    payload,
  );
  return await createClientResponseSchema.validate(response);
};

export interface CreateClientMeasurementRequest {
  date: string | null;
  growth: number | null;
  weight: number | null;
  bodyFatLevel: number | null;
  arm: number | null;
  tightBiceps: number | null;
  waist: number | null;
  hip: number | null;
  thigh: number | null;
  calf: number | null;

  water?: number | null;
  bodyFatMass?: number | null;
  skeletalMuscleMass?: number | null;
  boneTissueMass?: number | null;
  chest?: number | null;
  abdominal?: number | null;
  bodyMeasurements?: { id: number; value: number | null }[];
}

const createClientResponseSchema = yup.object().shape({
  data: yup
    .object()
    .shape({
      id: yup.number().required(),
    })
    .required(),
});

export interface CreateClientMeasurementResponse {
  data: {
    id: number;
  };
}
