import { FoodSearchResourceDto, searchFood } from "@client";
import {
  ProductsFiltersFormInput,
  convertSearchParamsToObj,
} from "@components/ProductsFiltersNew/useProductsFiltersForm";
import { usePagination } from "@hooks/usePagination";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { ApiResponse } from "@typeDefinitions";
import {
  parseBooleanString,
  parseCategory,
  parseNumber,
  parseNutrientsList,
  parseString,
  parseStringArrayNew,
  parseTagsList,
} from "@utils";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { QueryOptionsTyped } from "./types";
import { omitDefaultNutrients, parseTabName } from "./useSearchRecipesQueryNew";

export const searchFoodQueryKey = "searchFoodQueryKey";

interface CustomOptions
  extends QueryOptionsTyped<ApiResponse<FoodSearchResourceDto[]>> {
  disableParams?: boolean;
}

export const useSearchFoodQuery = (
  defaultInputs: ProductsFiltersFormInput | undefined = undefined,
  options?: CustomOptions,
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [committedInputs, setCommittedInputs] =
    useState<ProductsFiltersFormInput>(
      omitDefaultNutrients(
        defaultInputs ?? convertSearchParamsToObj(searchParams),
      ),
    );
  const params = useMemo(
    () => parseFoodFilters(committedInputs),
    [committedInputs],
  );

  useEffect(() => {
    if (!options?.disableParams) setSearchParams(params, { replace: true });
  }, [params, options?.disableParams]);

  const { handlePaginationClick, paginatedParams } = usePagination(params);
  const { data, ...rest } = useQuery(
    [searchFoodQueryKey, paginatedParams.toString()],
    () => searchFood(paginatedParams),
    options,
  );

  return {
    products: data?.data,
    links: data?.meta?.links,
    submit: setCommittedInputs,
    ...rest,
    handlePaginationClick,
    meta: data?.meta,
  };
};

export const parseFoodFilters = (filters?: ProductsFiltersFormInput) => {
  const output = new URLSearchParams();

  parseTagsList(filters?.tags, output);
  parseNutrientsList(filters?.nutrients, output);
  parseTabName(filters?.tabs, output);
  parseString(filters?.query || undefined, "query", output);
  parseNumber(filters?.page, "page", output);
  parseNumber(filters?.perPage, "perPage", output);
  parseBooleanString(filters?.onlyPl, "onlyPl", output);
  parseNumber(filters?.patient, "patient", output);
  parseString(filters?.barcode || undefined, "barcode", output);
  parseStringArrayNew(filters?.shops, "shops", output);

  parseCategory(filters?.foodGroup, "category", output);

  return output;
};

const searchFoodInfiniteQueryKey = "searchFoodInfiniteQueryKey";

export const useSearchFoodInfiniteQuery = (
  defaultInputs: ProductsFiltersFormInput | undefined = {},
  options?: QueryOptionsTyped<ApiResponse<FoodSearchResourceDto[]>>,
) => {
  const [committedInputs, setCommittedInputs] =
    useState<ProductsFiltersFormInput>(defaultInputs);
  const params = useMemo(
    () => parseFoodFilters(omitDefaultNutrients(committedInputs)),
    [committedInputs],
  );

  const fetchFood = ({ pageParam = 1 }) => {
    const paginatedParams = new URLSearchParams(params);
    paginatedParams.set("page", pageParam.toString());
    return searchFood(paginatedParams);
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    ...rest
  } = useInfiniteQuery(
    [searchFoodInfiniteQueryKey, params.toString()],
    fetchFood,
    {
      getNextPageParam: ({ meta }) => {
        if (!meta) return;
        const { currentPage, total, perPage } = meta;
        const nextPage = currentPage + 1;
        return nextPage <= Math.ceil(total / (perPage || 1))
          ? nextPage
          : undefined;
      },
      enabled: options?.enabled,
      keepPreviousData: options?.keepPreviousData,
      refetchOnMount: options?.refetchOnMount,
    },
  );

  const products = data?.pages.flatMap(page => page.data) || [];
  const lastMeta = data ? data.pages[data.pages.length - 1].meta : undefined;

  return {
    products,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    submit: setCommittedInputs,
    meta: lastMeta,
    ...rest,
  };
};
