import { FlameOutlined } from "@assets/icons";
import { NutrientDto } from "@client";
import { useNutrients } from "@hooks";
import { useTheme } from "@mui/material";
import { ENERGY_ID } from "@utils";
import { MACROS_ARRAY, macrosSortKey } from "@utils/macros";
import { NutrientLabel } from "./NutrientLabel";

interface NutrientsListProps {
  nutrients: NutrientDto[];
}
export const NutrientsList = ({ nutrients }: NutrientsListProps) => {
  const { nutrientDict } = useNutrients();
  const macros = nutrients
    .filter(n => MACROS_ARRAY.includes(n.id))
    .sort((a, b) => macrosSortKey(a.id, b.id));
  const {
    colors: { neutral },
  } = useTheme();
  return (
    <div className="flex gap-[16px]">
      {macros.map(m => {
        const macro = nutrientDict.get(m.id);

        if (m.id === ENERGY_ID)
          return (
            <NutrientLabel
              key={m.id}
              label={<FlameOutlined fill={neutral.dark[700]} />}
              unit={macro?.unit ?? ""}
              value={m.value}
            />
          );

        return (
          <NutrientLabel
            key={m.id}
            label={`${macro?.name ?? ""}:`}
            unit={macro?.unit ?? ""}
            value={m.value}
          />
        );
      })}
    </div>
  );
};
