import { useMemo, useState } from "react";
import { useDebounce } from "react-use";

import { useAppTranslation } from "@hooks";
import { useFoodGroupTreeFetchQuery } from "@hooks/queries/dictionaries";
import { useSearchFoodQuery } from "@hooks/queries/useSearchFoodQuery";
import {
  useAddVisitFoodGroupMutation,
  useAddVisitFoodMutation,
  useRemoveVisitFoodGroupMutation,
  useRemoveVisitFoodMutation,
} from "@hooks/queries/visits";
import { VisitSegmentListType } from "@typeDefinitions";
import { FoodGroupTreeResponse } from "@typeDefinitions/responses/dictionaries";

import {
  ListOption,
  TagSelectAutocomplete,
} from "../TagSelectAutocomplete/TagSelectAutocomplete";
import { SectionWrapper } from "./components/SectionWrapper.styled";

interface VisitProductContentProps {
  patientId: number;
  visitId: number;
  type: VisitSegmentListType;
  value: any;
  group?: any;
}

export const ProductContent = ({
  type,
  value,
  group = [],
  patientId,
  visitId,
}: VisitProductContentProps) => {
  const [searchPhrase, setFoodSearchPhrase] = useState("");
  const [searchInputValue, setSearchInputValue] = useState("");

  const { t, isPolishChosen } = useAppTranslation();

  const { products, isLoading, submit } = useSearchFoodQuery(
    { query: searchPhrase },
    { disableParams: true },
  );
  const { data, isLoading: groupsLoading } = useFoodGroupTreeFetchQuery();
  const { mutate: addFoodGroupToVisit } = useAddVisitFoodGroupMutation(
    patientId,
    visitId,
  );
  const { mutate: addFoodToVisit } = useAddVisitFoodMutation(
    patientId,
    visitId,
  );
  const { mutate: removeFoodFromVisit } = useRemoveVisitFoodMutation(
    patientId,
    visitId,
  );
  const { mutate: removeFoodGroupFromVisit } = useRemoveVisitFoodGroupMutation(
    patientId,
    visitId,
  );

  useDebounce(
    () => {
      setFoodSearchPhrase(searchInputValue);
      submit({ query: searchInputValue });
    },
    500,
    [searchInputValue],
  );

  const isFavourite = useMemo(() => type === "favoriteProducts", []);

  const options = useMemo(
    () =>
      (products || []).map(({ id, namePl, nameEn }) => ({
        id: id.toString(),
        label: isPolishChosen ? namePl : nameEn ?? namePl,
      })),
    [products, isPolishChosen],
  );

  const groupOptions = useMemo(
    () => flattenAndGroupOptions(t, data, isPolishChosen),
    [data],
  );

  const handleChange = (value: ListOption[]) => {
    if (value.length)
      addFoodToVisit({
        foodId: parseInt(value.slice(-1)[0].id),
        isFavourite,
      });
  };

  const handleGroupChange = (value: ListOption[]) => {
    if (value.length)
      addFoodGroupToVisit({
        foodGroupId: parseInt(value.slice(-1)[0].id),
        isFavourite,
      });
  };

  const handleRemove = (foodId: string) => {
    if (value) removeFoodFromVisit({ foodId: parseInt(foodId) });
  };

  const handleGroupRemove = (foodGroupId: string) => {
    if (value) removeFoodGroupFromVisit({ foodGroupId: parseInt(foodGroupId) });
  };

  return (
    <>
      <SectionWrapper>{t("common.products")}</SectionWrapper>
      <TagSelectAutocomplete
        value={value}
        options={options}
        onChange={handleChange}
        onRemove={handleRemove}
        onInputChange={setSearchInputValue}
        isLoading={isLoading}
        apiSearch
      />
      <SectionWrapper className="mt-5">{t("common.categories")}</SectionWrapper>
      <TagSelectAutocomplete
        value={group}
        options={groupOptions}
        groupBy={option => option.parent || "0"}
        onChange={handleGroupChange}
        onRemove={handleGroupRemove}
        isLoading={groupsLoading}
      />
    </>
  );
};

const flattenAndGroupOptions = (
  t: (name: string) => string,
  data?: FoodGroupTreeResponse[],
  isPolishChosen?: boolean,
): ListOption[] => {
  const getLabel = (o: FoodGroupTreeResponse) =>
    (isPolishChosen ? o.descriptionPl : o.description) ?? o.description ?? "";

  return (
    data?.reduce((options, o) => {
      const label = getLabel(o);
      if (o.children?.length) {
        return [
          ...options,
          {
            id: o.id.toString(),
            parent: label,
            label: `${t("common.all")}: ${
              (isPolishChosen ? o.descriptionPl : o.description) ??
              o.description
            }`,
          },
          ...o.children.map(c => ({
            id: c.id.toString(),
            parent: label,
            label: getLabel(c),
          })),
        ];
      }

      options.push({
        id: o.id.toString(),
        parent: label,
        label,
      });
      return options;
    }, [] as ListOption[]) ?? []
  );
};
