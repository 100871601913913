import { BigSlashSeparator } from "./BigSlashSeparator";
import {
  ContentSection,
  RedPoint,
  Wrapper,
} from "./MicronutrientsModal.styled";

export interface MicroItemProps {
  name: string;
  units: string;
  displayedValue?: string;
  value?: number;
  norm?: number;
}

export const MicroItem = ({
  name,
  units,
  value,
  norm,
  displayedValue,
}: MicroItemProps) => {
  return (
    <Wrapper>
      <span className="text-xs">{name}</span>
      <ContentSection>
        {!!value && norm !== undefined && value < norm && <RedPoint />}
        <span className="font-semibold">{`${displayedValue ?? "-"} ${
          units ?? ""
        }`}</span>
        {norm !== undefined && (
          <>
            <BigSlashSeparator />
            <span>{norm ? `${norm} ${units ?? ""}` : "-"}</span>
          </>
        )}
      </ContentSection>
    </Wrapper>
  );
};
