import { ReactNode, useState } from "react";
import { ServingContext } from "./ServingContext";

interface ServingContextProviderProps {
  children: ReactNode;
  totalServings: number;
  servings?: number;
}
export const ServingContextProvider = ({
  children,
  totalServings,
  servings,
}: ServingContextProviderProps) => {
  const [selectedServings, setSelectedServings] = useState(
    servings ?? totalServings,
  );

  return (
    <ServingContext.Provider
      value={{ selectedServings, setSelectedServings, totalServings }}
    >
      {children}
    </ServingContext.Provider>
  );
};
