import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createPdfColorAPI, getPdfColorsAPI } from "@Pdf/api/pdfAPI";
import { MutationOptions } from "@hooks/queries";
import { pdfColorsQueryKey } from "@Pdf/hooks/query/usePdfColorsQuery";

const useAddPdfColorMutation = (options: MutationOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation(createPdfColorAPI, {
    ...options,
    onSuccess: async () => {
      const response = await getPdfColorsAPI();

      queryClient.setQueryData([pdfColorsQueryKey], () => response);

      options.onSuccess?.();
    },
  });
};

export { useAddPdfColorMutation };
