import { useEffect } from "react";
import { useForm } from "react-hook-form";

import * as yup from "yup";

import { NutrientDto } from "@client";
import {
  DietNutrientsNormDto,
  DietNutrientsNormRequest,
} from "@client/diets/creator";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppParams, useAppTranslation, useNutrients } from "@hooks";
import { useFetchNormNutrients } from "@hooks/queries";
import { useUpdateDietNutrientsNormCombinedMutation } from "../../hooks";

export const useNutrientsNormForm = (
  defaultValues: NutrientNormInputs | undefined,
  autosave?: boolean,
) => {
  const { t } = useAppTranslation();
  const { dietId } = useAppParams();
  const { mutate } = useUpdateDietNutrientsNormCombinedMutation();

  const resolver = yup.object().shape({
    normId: yup.string().required(t("common.required_field")),
  });
  const { watch, reset, getValues, ...rest } = useForm<NutrientNormInputs>({
    defaultValues,
    resolver: yupResolver(resolver),
  });
  const { nutrientDict } = useNutrients();
  const normId = watch("normId");

  const { refetch } = useFetchNormNutrients(Number(normId), {
    enabled: false,
    onSuccess: data => {
      const mappedForm = mapSystemNormForm(
        normId,
        data?.data,
        Array.from(nutrientDict.keys()),
        getValues(),
      );

      if (autosave && mappedForm)
        return mutate({ dietId, payload: mapNutrientsNormRequest(mappedForm) });

      reset(mappedForm);
    },
  });

  useEffect(() => {
    if (normId && defaultValues?.normId !== normId) refetch();
  }, [normId]);

  useEffect(() => {
    if (defaultValues) reset(defaultValues);
  }, [defaultValues]);

  return { watch, reset, getValues, ...rest };
};

export interface NutrientNormInputs {
  nutrients: NutrientIf[];
  normId: string | null;
}
interface NutrientIf {
  id: number;
  value: string;
  show: boolean;
}

export const mapSystemNormForm = (
  normId: string | null,
  normNutrients: NutrientDto[] | undefined,
  nutrientsList: number[],
  previous?: NutrientNormInputs,
): NutrientNormInputs | undefined => {
  if (!normNutrients || !normId) return;

  const previousToShow =
    previous?.nutrients.filter(n => n.show).map(n => n.id) ?? [];
  return {
    normId: normId,
    nutrients: nutrientsList.map(id => {
      const nutrient = normNutrients.find(n => n.id === id);
      return {
        id: id,
        value: `${nutrient?.value || 0}`,
        show: previousToShow.includes(id),
      };
    }),
  };
};

export const mapNutrientsNormForm = (
  data: DietNutrientsNormDto | undefined,
  nutrientsIds: number[],
): NutrientNormInputs | undefined => {
  if (!data) return;

  const { norm, nutrients } = data;
  return {
    normId: norm?.id ? norm.id.toString() : null,
    nutrients: nutrientsIds.map(id => {
      const nutrient = nutrients.find(n => n.nutrient.id === id);

      return {
        id: id,
        value: `${nutrient?.value || 0}`,
        show: !!nutrient?.show,
      };
    }),
  };
};
export const mapNutrientsNormRequest = (
  values: NutrientNormInputs,
): DietNutrientsNormRequest => {
  return {
    normId: values.normId ? Number(values.normId) : 0,
    nutrients: values.nutrients.map(({ id, show, value }) => ({
      nutrientId: id,
      show,
      value: Number(value),
    })),
  };
};
