import React from "react";
import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Book = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 26 26">
    <g id="Group_4040" data-name="Group 4040" transform="translate(2 1)">
      <path
        id="Path_473"
        data-name="Path 473"
        d="M599.623,95.644H584.442a2.24,2.24,0,0,0-2.24,2.241v1.274h-.073a1.757,1.757,0,0,0-1.754,1.754v.03a1.757,1.757,0,0,0,1.754,1.754h.073v3.176h-.073a1.757,1.757,0,0,0-1.754,1.754v.031a1.757,1.757,0,0,0,1.754,1.754h.073v3.177h-.073a1.757,1.757,0,0,0-1.754,1.754v.031a1.756,1.756,0,0,0,1.754,1.754h.073V117.4a2.24,2.24,0,0,0,2.24,2.241h15.181a2.6,2.6,0,0,0,2.6-2.6V98.248A2.6,2.6,0,0,0,599.623,95.644Zm1.387,21.4a1.389,1.389,0,0,1-1.387,1.387H584.442a1.026,1.026,0,0,1-1.024-1.025v-1.275h.21a1.756,1.756,0,0,0,1.754-1.754v-.031a1.757,1.757,0,0,0-1.754-1.754h-.21v-3.177h.21a1.757,1.757,0,0,0,1.754-1.754v-.031a1.757,1.757,0,0,0-1.754-1.754h-.21V102.7h.21a1.757,1.757,0,0,0,1.754-1.754v-.03a1.757,1.757,0,0,0-1.754-1.754h-.21V97.885a1.026,1.026,0,0,1,1.024-1.025h15.181a1.39,1.39,0,0,1,1.387,1.388Zm-19.42-2.666v-.031a.541.541,0,0,1,.539-.538h1.5a.541.541,0,0,1,.539.538v.031a.542.542,0,0,1-.539.539h-1.5a.542.542,0,0,1-.539-.539Zm0-6.715v-.031a.542.542,0,0,1,.539-.539h1.5a.542.542,0,0,1,.539.539v.031a.542.542,0,0,1-.539.538h-1.5a.542.542,0,0,1-.539-.538Zm0-6.716v-.03a.542.542,0,0,1,.539-.539h1.5a.542.542,0,0,1,.539.539v.03a.542.542,0,0,1-.539.539h-1.5a.542.542,0,0,1-.539-.539Z"
        transform="translate(-580.375 -95.644)"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.3"
      />
      <path
        id="Stroke_5"
        data-name="Stroke 5"
        d="M6.113.5H0"
        transform="translate(9.887 12.962)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Stroke_7"
        data-name="Stroke 7"
        d="M5.129.5H0"
        transform="translate(9.886 9.01)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  </SvgIcon>
);
