import { FormControlLabel, FormGroup } from "@mui/material";
import { FormCheckboxMui, FormCheckboxMuiProps } from "./FormCheckboxMui";
import { ReactNode } from "react";

interface FormLabeledCheckboxMuiProps extends FormCheckboxMuiProps {
  label: ReactNode;
  groupClassName?: string;
}

export const FormLabeledCheckboxMui = ({
  label,
  groupClassName,
  ...rest
}: FormLabeledCheckboxMuiProps) => {
  return (
    <FormGroup className={groupClassName}>
      <FormControlLabel control={<FormCheckboxMui {...rest} />} label={label} />
    </FormGroup>
  );
};
