import { useAppTranslation } from "@hooks";
import {
  SectionHeadEditLayout,
  SectionHeadLayout,
  SectionLayout,
  SectionTitle,
  TabHeadActionEditLabel,
} from "@views/dietician/ClientHealth/ClientHealthNew.styled";
import NutritionalNormItemSection from "@views/dietician/ClientHealth/ProfileTab/ProfileSection/NutritionalNormItemSection";
import ProductExchangersItemSection from "@views/dietician/ClientHealth/ProfileTab/ProfileSection/ProductExchangersItemSection";
import AdditionalInformationItemSection from "@views/dietician/ClientHealth/ProfileTab/ProfileSection/AdditionalInformationItemSection";
import { useState } from "react";
import ProfileSectionDialog from "@views/dietician/ClientHealth/ProfileTab/ProfileSection/Dialog/ProfileSectionDialog";
import { EditIcon } from "@icons/index";

const ProfileSection = () => {
  const { t } = useAppTranslation();
  const [open, setOpen] = useState(false);

  return (
    <SectionLayout>
      <SectionHeadLayout>
        <SectionTitle>
          {t(
            "patient.health_and_goal.dietary_profile_tab.section_dietary_profile.section_title",
          )}
        </SectionTitle>
        <SectionHeadEditLayout onClick={() => setOpen(true)}>
          <EditIcon color="#7448D0" />
          <TabHeadActionEditLabel>{t("common.edit")}</TabHeadActionEditLabel>
        </SectionHeadEditLayout>
      </SectionHeadLayout>

      <AdditionalInformationItemSection />
      <NutritionalNormItemSection />
      <ProductExchangersItemSection />

      <ProfileSectionDialog open={open} setOpen={setOpen} />
    </SectionLayout>
  );
};

export default ProfileSection;
