import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { QuestionTableDto } from "@client/surveys";

import { QuestionFormInputs } from "../Question/useQuestionForm";

export const useTableConfigForm = (values?: TableConfigFormInputs) => {
  const [init, setInit] = useState(true);
  const form = useForm<TableConfigFormInputs>({
    defaultValues: {
      title: "",
      description: null,
      required: false,
      columns: [],
      rows: [],
      heading: "",
    },
  });

  useEffect(() => {
    if (init && values) {
      form.reset(values);
      setInit(false);
    }
  }, [values]);

  return form;
};

interface CellIf {
  name: string;
  uuid: string;
}
export interface TableConfigFormInputs extends QuestionFormInputs {
  heading: string | null;
  columns: CellIf[];
  rows: CellIf[];
}

export const mapTableConfigForm = ({
  title,
  description,
  config: { columns, required, rows, heading },
}: QuestionTableDto): TableConfigFormInputs => {
  return {
    title,
    description,
    required,
    columns,
    rows,
    heading,
  };
};

export const mapTableConfigRequest = (
  {
    description,
    required,
    title,
    columns,
    rows,
    heading,
  }: TableConfigFormInputs,
  { id, type }: QuestionTableDto,
): QuestionTableDto => {
  return {
    id,
    title,
    description,
    config: { required, columns, rows, heading },
    type,
  };
};
