import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import { FoodPayload } from "./common";

export async function addFoodToVisit(
  scheduleId: number,
  foodId: number,
  payload: FoodPayload,
): Promise<ApiResponse<void>> {
  return fetchData(
    `/dietitian/schedules/${scheduleId}/visit/food/${foodId}`,
    APIMethods.PUT,
    payload,
  );
}
