import { Typography, styled } from "@mui/material";

interface ListElementWrapperProps {
  disabled?: boolean;
}
export const ListElementWrapper = styled("div", {
  shouldForwardProp: propName => propName !== "disabled",
})<ListElementWrapperProps>`
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  background: ${({ disabled }) => (disabled ? "#FAF9F9" : "white")};
  border-top: 1px solid rgba(0, 0, 0, 0.15);
`;

export const NameWrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 1.25rem;
`;

export const HeaderWrapper = styled("div")`
  display: flex;
  margin-bottom: 1rem;
  justify-content: center;
`;

export const ResultsHeader = styled(Typography)`
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.6);
`;

export const ItemSelectWrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;
