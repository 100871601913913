import { useNutrientDictionary } from "@hooks";
import { Tooltip, Typography, useTheme } from "@mui/material";
import {
  ChartEmpty,
  ChartFull,
  ItemWithChartWrapper,
  NutrientItemValueWrapper,
} from "./NutrientItem.styled";
import { round } from "lodash";
import { CARBS_ID, ENERGY_ID, FATS_ID, PROTEIN_ID } from "@utils";
import { BoldTypography } from "./MonitoringCalendar.styled";

interface NutrientItemProps {
  value?: number;
  target?: number;
  id: number;
  chart?: boolean;
  difference?: boolean;
}
export const NutrientItem = ({
  value,
  target,
  id,
  chart,
  difference,
}: NutrientItemProps) => {
  const { getNutrient } = useNutrientDictionary();
  const nutrientDetails = getNutrient(id);
  const { colors, palette } = useTheme();

  const percentage =
    value && target && Math.min(round((value / target) * 100), 100);
  const macrosColors: Record<string, string> = {
    [ENERGY_ID]: palette.primary.main,
    [PROTEIN_ID]: colors.chartPurple,
    [FATS_ID]: colors.chartOrange,
    [CARBS_ID]: colors.chartGreen,
  };

  if (difference && chart) {
    const diffResult = value && target && value - target;

    return (
      <ItemWithChartWrapper>
        <Typography>{nutrientDetails?.name}</Typography>
        <div className="flex gap-1">
          <BoldTypography>
            {diffResult && diffResult > 0 && "+"}
            {diffResult ? round(diffResult) : "-"}{" "}
          </BoldTypography>
          <Typography>{nutrientDetails?.unit}</Typography>
        </div>
      </ItemWithChartWrapper>
    );
  }

  if (chart)
    return (
      <ItemWithChartWrapper>
        <Typography>{nutrientDetails?.name}</Typography>
        <div>
          <ChartEmpty>
            <ChartFull
              width={percentage ? `${Math.max(16, percentage)}%` : "0%"}
              background={macrosColors[id]}
            />
          </ChartEmpty>
        </div>
        <NutrientItemValueWrapper>
          <BoldTypography>{value ? round(value) : "-"}</BoldTypography>
          <Typography>/</Typography>
          <Typography>
            {target ? round(target) : "-"} {nutrientDetails?.unit}
          </Typography>
        </NutrientItemValueWrapper>
      </ItemWithChartWrapper>
    );

  if (difference) {
    const diffResult = value && target && value - target;

    return (
      <div className="flex gap-1">
        <Tooltip title={nutrientDetails?.name}>
          <Typography>{nutrientDetails?.short}:</Typography>
        </Tooltip>
        <NutrientItemValueWrapper>
          <BoldTypography>
            {diffResult && diffResult > 0 && "+"}
            {diffResult ? round(diffResult) : "-"}
          </BoldTypography>
          <Typography>{nutrientDetails?.unit}</Typography>
        </NutrientItemValueWrapper>
      </div>
    );
  }

  return (
    <div className="flex gap-1">
      <Tooltip title={nutrientDetails?.name}>
        <Typography>{nutrientDetails?.short}:</Typography>
      </Tooltip>
      <NutrientItemValueWrapper>
        <BoldTypography>{value ? round(value) : "-"}</BoldTypography>
        <Typography>/</Typography>
        <Typography>
          {target ? round(target) : "-"} {nutrientDetails?.unit}
        </Typography>
      </NutrientItemValueWrapper>
    </div>
  );
};
