import { styled } from "@mui/material";
import { useFetchBodyMeasurementsQuery } from "@hooks/queries/dictionaries";
import { useMemo } from "react";
import {
  getBodyMeasurementsById,
  getTranslation,
} from "@utils/dictionaries/bodyMeasurementsDictionaryUtils";
import { MeasurementCompareItemProps } from "@views/dietician/PatientMonitoring2/components/MeasurementsBox/Compare/MeasurementCompareItem";
import { useAppTranslation } from "@hooks";

type Props = {
  value: MeasurementCompareItemProps["values"][0];
};

const MeasurementCompareBodyItemValue = ({ value }: Props) => {
  const { currentLanguage } = useAppTranslation();
  const { data } = useFetchBodyMeasurementsQuery();

  const bodyMeasurement = useMemo(
    () => getBodyMeasurementsById(data?.data ?? [], value.body.id),
    [data, value],
  );

  if (!bodyMeasurement) {
    return null;
  }

  return (
    <ListItem>
      <ValueLabel>
        {getTranslation(bodyMeasurement, currentLanguage)?.name}:{" "}
        <Value>
          {value.icon} {Math.round(value.value * 10) / 10}{" "}
          {bodyMeasurement.unit}
        </Value>
      </ValueLabel>
    </ListItem>
  );
};

const ListItem = styled("li")`
  padding: 8px 0;
  border-bottom: 1px solid #f5f1fe;
  &:last-of-type {
    border-bottom: none;
  }
`;

const ValueLabel = styled("span")`
  color: #2f303a;
  font-size: 14px;
  line-height: 28px;
  font-family: Figtree, serif;
  font-weight: 500;
`;

const Value = styled("span")`
  font-weight: 600;
`;

export default MeasurementCompareBodyItemValue;
