import { ReactNode } from "react";

import { CLIENT_PROFILE_EVENT_STATUS } from "@utils";

import { EventName, EventTime, EventTypeWrapper } from "./EventItem.styled";

interface EventItemProps {
  status?: CLIENT_PROFILE_EVENT_STATUS;
  createdAt?: string;
  name?: string;
  icon?: ReactNode;
  children?: ReactNode;
}

export const EventItem = ({
  status,
  createdAt,
  name,
  icon,
  children,
}: EventItemProps) => {
  return (
    <>
      <EventTypeWrapper status={status}>{icon}</EventTypeWrapper>
      <div>
        <EventName>{name}</EventName>
        <EventTime>{createdAt}</EventTime>
        {children}
      </div>
    </>
  );
};
