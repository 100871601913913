import { useMemo } from "react";
import { FormProvider } from "react-hook-form";

import { Carrot } from "@assets/icons/Menu";
import { BreadcrumbsPath } from "@components/BreadcrumbsPath";
import { ApiError } from "@components/ApiError";
import { Spinner } from "@components/Spinner";
import { AutosaveStatus } from "@components/AutosaveStatus";
import { useAppParams, useAppTranslation } from "@hooks";
import { useFetchProductQuery } from "@hooks/queries/food";
import { useCategoriesNutrientsFetchQuery } from "@hooks/queries/dictionaries";

import { ProductEditWrapper } from "./ProductEdit.styled";
import { mapProductForm, useProductForm } from "../ProductForm";
import { ProductEditWatch } from "../ProductEdit/ProductEditWatch";
import { ProductEditFormBody } from "../ProductForm/ProductFormBody";

export const ProductEdit = () => {
  const { t, isPolishChosen } = useAppTranslation();
  const { productId } = useAppParams();
  const parsedProductId = productId ? parseInt(productId) : NaN;
  const { product, isLoading, isFetching, isError } = useFetchProductQuery(
    parsedProductId,
    { enabled: !!parsedProductId },
  );
  const { data: nutrientCategories } = useCategoriesNutrientsFetchQuery();
  const mappedFormValues = useMemo(
    () => mapProductForm(product, nutrientCategories),
    [product, nutrientCategories],
  );

  const form = useProductForm(mappedFormValues);

  const productName =
    (isPolishChosen
      ? product?.descriptionPl
      : product?.description ?? product?.descriptionPl) ?? "";

  const breadcrumbs = [
    {
      icon: <Carrot />,
      backTo: "/products",
      title: t("product.title"),
    },
    {
      backTo: `/products/${productId}`,
      title: productName,
    },
    {
      backTo: `/products/${productId}/edit`,
      title: t("product_edit.title"),
    },
  ];

  if (isLoading)
    return (
      <ProductEditWrapper>
        <Spinner />
      </ProductEditWrapper>
    );

  if (isError || !product)
    return (
      <ProductEditWrapper>
        <ApiError />
      </ProductEditWrapper>
    );

  return (
    <ProductEditWrapper>
      <div className="flex justify-between">
        <BreadcrumbsPath breadcrumbs={breadcrumbs} />
        <AutosaveStatus />
      </div>

      <FormProvider {...form}>
        <ProductEditWatch product={product} productLoading={isFetching} />
        <ProductEditFormBody product={product} />
      </FormProvider>
    </ProductEditWrapper>
  );
};
