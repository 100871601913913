import React from "react";
import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const CloseRound = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 58 58">
    <circle
      id="Ellipse_124"
      data-name="Ellipse 124"
      cx="29"
      cy="29"
      r="29"
      fill="#fdeff2"
    />
    <g
      id="_20-icon-confirm"
      data-name="20-icon-confirm"
      transform="translate(20.917 20.865)"
    >
      <path
        id="check_icon"
        data-name="check icon"
        d="M1.834,16.27,9.589,8.491,11.947,6.11,18,0"
        transform="translate(-1.834)"
        fill="none"
        stroke="#f25c5a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
      <path
        id="check_icon-2"
        data-name="check icon"
        d="M0,0,7.754,7.779l2.358,2.381,6.053,6.11"
        transform="translate(0)"
        fill="none"
        stroke="#f25c5a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
    </g>
  </SvgIcon>
);
