import Grid from "@mui/material/Unstable_Grid2";
import LeftSide from "@components/Client/PatientBodyMeasurementsGalleryModal/LeftSide";
import RightSide from "@components/Client/PatientBodyMeasurementsGalleryModal/RightSide";
import { useEffect, useMemo, useState } from "react";
import { FetchClientMeasurementListResponse } from "@client";
import { useFetchClientMeasurementListInfinite } from "@hooks/queries";

export type ImagePreview = {
  measurement: FetchClientMeasurementListResponse;
  photo: FetchClientMeasurementListResponse["photos"][0] | null;
} | null;

type MeasurementPhotosContentProps = {
  patientId: number;
};
const MeasurementPhotosContent = ({
  patientId,
}: MeasurementPhotosContentProps) => {
  const [imagePreview, setImagePreview] = useState<ImagePreview>(null);
  const { data, isLoading, isFetching, fetchNextPage, hasNextPage } =
    useFetchClientMeasurementListInfinite(patientId.toString());

  const measurements = useMemo(() => {
    return (data?.pages ?? [])
      .map(p => p.data)
      .flat()
      .filter(m => m.photos.length);
  }, [data]);

  useEffect(() => {
    if (imagePreview) {
      return;
    }

    if (!measurements.length) {
      return;
    }

    const measurement = measurements.find(m => m.photos.length);
    if (!measurement) {
      return;
    }

    setImagePreview({
      measurement: measurement,
      photo: measurement.photos[0],
    });
  }, [measurements]);

  return (
    <Grid container flex="1" spacing="24px" alignItems="stretch">
      <Grid width="420px" maxHeight="100%">
        <LeftSide
          imagePreview={imagePreview}
          setImagePreview={setImagePreview}
          measurements={measurements}
          request={{
            isLoading,
            isFetching,
            fetchNextPage,
            hasNextPage,
          }}
        />
      </Grid>
      <Grid smallNew maxHeight="100%">
        <RightSide
          imagePreview={imagePreview}
          setImagePreview={setImagePreview}
          measurements={measurements}
          request={{
            isLoading,
            isFetching,
            fetchNextPage,
            hasNextPage,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default MeasurementPhotosContent;
