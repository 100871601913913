import { Card, styled } from "@mui/material";

export const StyledCard = styled(Card)`
  display: grid;
  gap: 1rem;
  padding: 1.5rem;
`;

export const ProductEditWrapper = styled("div")`
  display: grid;
  gap: 0.75rem;
`;
