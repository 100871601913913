import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

import { SectionCategoryDto } from "./common";
import * as yup from "yup";
import { validateAndFixNutrients } from "./utils/validateCatching";

export async function fetchSectionCategories(): Promise<SectionCategoryDto[]> {
  const { data } = await fetchData(
    `/dietitian/program-section-categories`,
    APIMethods.GET,
  );
  return await validateAndFixNutrients(requestSectionCategoriesSchema, data);
}

const requestSectionCategoriesSchema = yup.array().of(
  yup.object().shape({
    id: yup.number().required(),
    description: yup.string().required(),
    descriptionEn: yup.string().required(),
  }),
);
