import { DividerStyled, NavigationHeader } from "./DrawerMenu.styled";
import { MenuItem } from "./MenuItem";
import { MenuGroupIf } from "./DrawerMenu";
import { useAppTranslation } from "@hooks";
import { Tooltip } from "@mui/material";

interface MenuGroupProps {
  isDashboard: boolean;
  open: boolean;
  group: MenuGroupIf;
}
export const MenuGroup = ({ isDashboard, open, group }: MenuGroupProps) => {
  const { t } = useAppTranslation();
  return (
    <div>
      {!isDashboard && open && (
        <NavigationHeader>{t(group.label)}</NavigationHeader>
      )}
      {!isDashboard && !open && <DividerStyled />}
      <div className="grid gap-2">
        {group.links.map((data, index) => (
          <Tooltip
            key={index}
            title={t(data.label)}
            disableHoverListener={open || !!data.wrapper}
            placement="right"
          >
            <div>
              <MenuItem data={data} open={open} />
            </div>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};
