import { Link, useMediaQuery, useTheme } from "@mui/material";

import { useAppTranslation } from "@hooks";
import { AlloweatLayout } from "@layouts/AlloweatLayout";

import {
  ButtonStyled,
  CardStyled,
  ContainerWithButton,
  MobileWrapper,
  PageWrapper,
  Text,
  TextContainer,
  Title,
} from "./NewMobileAppBanner.styled";

export const NewMobileAppBanner = () => {
  const { t } = useAppTranslation();
  const { breakpoints } = useTheme();
  const isDownMedium = useMediaQuery(`${breakpoints.down("medium")}`);
  const LINK = "https://alloweat.com/zrobilismy-to-alloweat-2-0-juz-jest";
  const IMG_LINK =
    "https://alloweat.s3.eu-central-1.amazonaws.com/public/web/maintenance_work.png";

  if (isDownMedium)
    return (
      <MobileWrapper>
        <img src={IMG_LINK} width={458} height={280} />

        <ContainerWithButton>
          <TextContainer>
            <Title>{t("empty_states.new_app.title")}</Title>
            <Text>{t("empty_states.new_app.description")}</Text>
          </TextContainer>

          <ButtonStyled LinkComponent={Link} href={LINK} variant="contained">
            {t("empty_states.new_app.whats_coming")}
          </ButtonStyled>
        </ContainerWithButton>
      </MobileWrapper>
    );

  return (
    <AlloweatLayout>
      <PageWrapper>
        <CardStyled>
          <img src={IMG_LINK} width={458} height={280} />

          <ContainerWithButton>
            <TextContainer>
              <Title>{t("empty_states.new_app.title")}</Title>
              <Text>{t("empty_states.new_app.description")}</Text>
            </TextContainer>

            <ButtonStyled LinkComponent={Link} href={LINK} variant="contained">
              {t("empty_states.new_app.whats_coming")}
            </ButtonStyled>
          </ContainerWithButton>
        </CardStyled>
      </PageWrapper>
    </AlloweatLayout>
  );
};
