import { styled } from "@mui/material";
import { Button } from "@components/Button";

export const PdfTemplatePickerItemStyled = styled("div")`
  position: relative;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.light["200"]};
  padding: 8px 12px;
`;

export const PdfTemplatePickerItemControlStyled = styled("div")`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

export const PdfTemplatePickerItemImageStyled = styled("img")`
  width: 100%;
  height: 200px;
  border-radius: 8px;
  object-fit: cover;
`;

export const PdfTemplatePickerItemDeleteButtonStyled = styled(Button)`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 32px;
  height: 32px;
  border-radius: 18px;
  background: #fbfafcb2;
  padding: 0;
  margin: 0;
`;
