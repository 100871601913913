import { useQuery } from "@tanstack/react-query";
import { fetchMonitoringMealsDietitian } from "client/patient/monitoring";
import { QueryOptionsTyped } from "@hooks/queries";
import {
  FetchMonitoringMealsDietitianParams,
  FetchMonitoringMealsDietitianResponse,
} from "@client/patient/monitoring/fetchMonitoringMealsDietitian";
import { patientMonitoringKeys } from "@hooks/queries/client/monitoring/patientMonitoringKey";

export const fetchMonitoringMealsDietitianQueryKey =
  "fetchMonitoringMealsDietitianQueryKey";

export const useFetchMonitoringMealsDietitian = (
  params: FetchMonitoringMealsDietitianParams,
  options?: QueryOptionsTyped<FetchMonitoringMealsDietitianResponse>,
) => {
  return useQuery({
    queryKey: patientMonitoringKeys.mealsDietitian(params),
    queryFn: () => fetchMonitoringMealsDietitian(params),
    ...options,
  });
};
