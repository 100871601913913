import { Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { ThemeProviderWrapperNew } from "../../../themeNew";
import CalendarDaysOfFactory from "@views/dietician/PatientMonitoring2/components/CalendarDays/CalendarDaysOfFactory";
import CalendarType from "@views/dietician/PatientMonitoring2/components/CalendarType/CalendarType";
import { MonitorContextProvider } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import NutrientValuesBox from "@views/dietician/PatientMonitoring2/components/NutrientValues/NutrientValuesBox";
import MealsBoxByType from "@views/dietician/PatientMonitoring2/components/MealsBox/MealsBoxByType";
import IrrigationBoxFactory from "@views/dietician/PatientMonitoring2/components/Irrigation/IrrigationBoxFactory";
import MeasurementsBox from "@views/dietician/PatientMonitoring2/components/MeasurementsBox/MeasurementsBox";
import SummaryCardsBox from "@views/dietician/PatientMonitoring2/components/SummaryCard/SummaryCardsBox";

const PatientMonitoringOn = () => {
  return (
    <MonitorContextProvider>
      <ThemeProviderWrapperNew>
        <Grid container spacing="16px" columns={12}>
          <Grid smallNew={12}>
            <Stack direction="row">
              <CalendarType />
              <CalendarDaysOfFactory />
            </Stack>
          </Grid>
          <Grid smallNew={12}>
            <SummaryCardsBox />
          </Grid>
          <Grid smallNew={12}>
            <Grid container spacing="16px" columns={12}>
              <Grid smallNew={12} medium={6}>
                <NutrientValuesBox />
              </Grid>
              <Grid smallNew={12} medium={6}>
                <Grid container spacing="16px" columns={12}>
                  <Grid smallNew={12}>
                    <MealsBoxByType />
                  </Grid>
                  <Grid smallNew={12}>
                    <MeasurementsBox />
                  </Grid>
                  <Grid smallNew={12}>
                    <IrrigationBoxFactory />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProviderWrapperNew>
    </MonitorContextProvider>
  );
};

export default PatientMonitoringOn;
