import { Box, Stack, styled } from "@mui/material";
import { IconButton } from "@components/Alloweat/IconButton";
import { ImagePreview } from "@components/Client/PatientBodyMeasurementsGalleryModal/MeasurementPhotosContent";
import { Dispatch, useMemo } from "react";
import { useAppTranslation } from "@hooks";
import dayjs from "dayjs";
import { FetchClientMeasurementListResponse } from "@client";
import { ClientSilhouettePhotoArray } from "@consts/ClientSilhouettePhotoConst";
import { ChevronIcon, GalleryIcon } from "@icons/index";

type RightSideProps = {
  imagePreview: ImagePreview;
  setImagePreview: Dispatch<ImagePreview>;
  measurements: FetchClientMeasurementListResponse[];
  request: {
    hasNextPage?: boolean;
    fetchNextPage: () => void;
    isFetching: boolean;
    isLoading: boolean;
  };
};

const RightSide = ({
  imagePreview,
  setImagePreview,
  measurements,
  request,
}: RightSideProps) => {
  const { t } = useAppTranslation();

  const parsedDate = useMemo(() => {
    if (!imagePreview) {
      return "";
    }
    return dayjs(imagePreview.measurement.date).format("DD.MM.YYYY");
  }, [imagePreview]);

  const allImages = useMemo(() => {
    return measurements
      .map(m =>
        m.photos.sort(
          (a, b) =>
            ClientSilhouettePhotoArray.indexOf(a.type.id) -
            ClientSilhouettePhotoArray.indexOf(b.type.id),
        ),
      )
      .flat();
  }, [measurements]);

  const prevOnClick = () => {
    if (!imagePreview || !imagePreview.photo) {
      return;
    }

    const index = allImages.findIndex(
      photo => photo.id === imagePreview.photo?.id,
    );

    if (index < 1) {
      return;
    }

    const image = allImages[index - 1];

    const measurement = measurements.find(m =>
      m.photos.find(p => p.id === image.id),
    );

    if (!measurement) {
      return;
    }

    setImagePreview({
      measurement: measurement,
      photo: image,
    });
  };

  const nextOnClick = () => {
    if (!imagePreview || !imagePreview.photo) {
      return;
    }

    const index = allImages.findIndex(
      photo => photo.id === imagePreview.photo?.id,
    );

    if (index === -1 || index + 1 >= allImages.length) {
      return;
    }

    const image = allImages[index + 1];

    const measurement = measurements.find(m =>
      m.photos.find(p => p.id === image.id),
    );

    if (!measurement) {
      return;
    }

    setImagePreview({
      measurement: measurement,
      photo: image,
    });
  };

  return (
    <Stack direction="column" gap="0px" height="100%">
      {imagePreview !== null && imagePreview.photo !== null ? (
        <NoImage>
          <Image src={imagePreview.photo.url} loading="lazy" />
        </NoImage>
      ) : (
        <NoImage>
          <Box
            width="150px"
            height="150px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="28px"
            bgcolor="#fff"
          >
            <GalleryIcon color="#7448D0" fontSize="64px" />
          </Box>
        </NoImage>
      )}
      <Box>
        <Stack
          direction="row"
          gap="16px"
          alignItems="center"
          justifyContent="center"
          padding="12px 16px"
          bgcolor="#F5F1FE"
          sx={{ borderRadius: "0px 0px 8px 8px" }}
        >
          <IconButton onClick={prevOnClick}>
            <ChevronIcon color="#7448D0" />
          </IconButton>
          {imagePreview === null && <span></span>}
          {imagePreview !== null && (
            <span>
              {imagePreview.photo
                ? t(
                    `monitoring2.measurements_box.photo_section.${imagePreview.photo.type.id}`,
                  )
                : ""}{" "}
              ({parsedDate})
            </span>
          )}
          <IconButton onClick={nextOnClick}>
            <ChevronIcon
              color="#7448D0"
              style={{ transform: "rotate(180deg)" }}
            />
          </IconButton>
        </Stack>
      </Box>
    </Stack>
  );
};

const Image = styled("img")`
  max-height: 100%;
`;

const NoImage = styled(Box)`
  background: radial-gradient(#fff 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  max-height: calc(100% - 48px);
` as typeof Box;

export default RightSide;
