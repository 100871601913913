import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const postProgramDietToBase = async (
  programId: string,
  programDayId: string,
  payload: PostProgramDietToBaseRequest,
) => {
  await fetchData(
    `/dietitian/programs/${programId}/days/${programDayId}/store-diet-in-base`,
    APIMethods.POST,
    payload,
  );
};

export interface PostProgramDietToBaseRequest {
  namePl: string;
  nameEn: string | null;
}
