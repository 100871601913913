import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { deleteSingleTask } from "@client/tasks/deleteSingleTask";
import { ApiEndpoints } from "@typeDefinitions";
import { useAppTranslation } from "@hooks/useAppTranslation";

export const useDeleteTaskMutation = () => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation<
    AxiosResponse,
    AxiosError,
    { id: string }
  >(data => deleteSingleTask(data.id), {
    onSuccess: () => {
      queryClient.invalidateQueries([ApiEndpoints.Tasks]);
      queryClient.invalidateQueries([ApiEndpoints.TasksCount]);

      toast.success(t("tasks.deleteSuccess"));
    },
  });

  const handleDeleteTask = async (id: string) => {
    try {
      await mutateAsync({ id });
    } catch {
      toast.error(t("tasks.deleteFailure"));
    }
  };

  return { handleDeleteTask };
};
