import { useQuery } from "@tanstack/react-query";

import {
  FetchClientFoodPreferencesResponse,
  fetchClientFoodPreferences,
} from "@client";
import { ApiResponse } from "@typeDefinitions";

import { QueryOptionsTyped } from "./types";
import { buildDefaultQueryOptions } from "./common";

export const fetchClientFoodPreferencesQueryKey =
  "fetchClientFoodPreferencesQueryKey";

export const useFetchClientFoodPreferences = (
  id: string,
  liked: number,
  options?: QueryOptionsTyped<
    ApiResponse<FetchClientFoodPreferencesResponse[]>
  >,
) => {
  const { data, ...rest } = useQuery(
    [fetchClientFoodPreferencesQueryKey, id, liked],
    () => {
      const paginated = new URLSearchParams();
      paginated.append("liked", liked.toString());
      return fetchClientFoodPreferences(id, paginated);
    },
    buildDefaultQueryOptions(options),
  );

  return {
    clientFoodPreferences: data?.data,
    ...rest,
  };
};
