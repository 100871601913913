import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const OrangeCircle = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        d="M12 19.875C7.83984 19.875 4.5 16.5352 4.5 12.375C4.5 8.24414 7.83984 4.875 12 4.875C16.1309 4.875 19.5 8.24414 19.5 12.375C19.5 16.5352 16.1309 19.875 12 19.875Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
