import {
  fetchProductShops,
  ProductShopDictionaryDto,
} from "@client/dictionaries";
import { useQuery } from "@tanstack/react-query";
import { QueryOptionsTyped } from "../types";
import { ApiResponse } from "@typeDefinitions";

export const fetchProductShopsQueryKey = "fetchProductShopsQueryKey";

export const useFetchProductShopsQuery = (
  options?: QueryOptionsTyped<ApiResponse<ProductShopDictionaryDto[]>>,
) => {
  const { data, ...rest } = useQuery({
    queryKey: [fetchProductShopsQueryKey],
    queryFn: fetchProductShops,
    ...options,
  });

  return {
    data: data?.data,
    ...rest,
  };
};
