import { memo, useContext, useState } from "react";

import { FormControlLabel, MenuItem, Radio, Tooltip } from "@mui/material";
import { format } from "date-fns";
import dayjs from "dayjs";

import { WEEKDAYS_MAP, WEEKDAYS_MAP_EN } from "@utils/constants";
import { useAppParams, useAppTranslation } from "@hooks";
import { useClearScheduleDayMutation } from "@hooks/queries/schedule";
import { useCloneScheduleDayMutation } from "@hooks/queries";
import { ScheduleClipboardContext } from "@views/dietician/ProgramSchedule";
import { DietitianProgramScheduleDay } from "@client/schedule";
import { Card } from "@components/Card";
import { DropMenuMui } from "@components/DropMenuMui";
import { BulletMenu, Reload } from "@assets/icons";
import { OptimizerSettingsBase, RecipeColor } from "@typeDefinitions";

import { ColoredCircle } from "../../../ColoredCircle";
import { DayVariantModal } from "../DayVariantModal";

import { ScheduleClearAlert } from "../ScheduleClearAlert/ScheduleClearAlert";
import {
  MenuButtonWrapper,
  StyledOrangeIconButton,
} from "./ScheduleGridDay.styled";
import { dayIndexFactory } from "./scheduleDayUtils";

interface ScheduleGridDayProps {
  programId: number;
  id: number;
  date: string;
  dayName: string;
  dayNameEn?: string;
  color: string;
  programDayId: number;
  scheduleDay: DietitianProgramScheduleDay;
  recipeColors: RecipeColor[];
  readOnly?: boolean;
  normId?: number;
  variantName?: string;
  selectedVariant?: string | number;
  optimizerSettings?: OptimizerSettingsBase[];
  startDate?: string;
  pastDate?: boolean;
  canCopy?: boolean;
}

export const ScheduleGridDay = memo((props: ScheduleGridDayProps) => {
  const {
    id,
    date,
    dayName,
    dayNameEn,
    color,
    readOnly,
    variantName,
    selectedVariant,
    programId,
    scheduleDay,
    startDate,
    pastDate,
    programDayId,
    canCopy,
    ...dayVariantModalProps
  } = props;

  const { t, isPolishChosen } = useAppTranslation();

  const { mutate: clearScheduleDay, isLoading } = useClearScheduleDayMutation(
    programId,
    id,
  );
  const { mutate: pasteScheduleDay } = useCloneScheduleDayMutation(programId);

  const [showVariantModal, setVariantModalState] = useState(false);
  const [clearAlertVisible, showClearAlert] = useState(false);

  const { day: copiedDay, copyDay } = useContext(ScheduleClipboardContext);
  const handleVariantModal = () => setVariantModalState(s => !s);
  const handleScheduleDayClear = () => {
    showClearAlert(false);
    clearScheduleDay();
  };

  const handleScheduleClearAlert = () => {
    if (isLoading) return;
    showClearAlert(!clearAlertVisible);
  };

  const handleCopyDay = () => {
    copyDay({
      id,
      date,
      mealsCount: scheduleDay.meals.length,
      programDayId,
    });
  };

  const handlePasteDay = () => {
    if (copiedDay) {
      pasteScheduleDay(
        {
          programScheduleDay: { id: copiedDay.id },
          daysToReplace: [{ id }],
        },
        { onSuccess: () => copyDay(undefined) },
      );
    }
  };
  const dateObj = new Date(date);

  const { patientId } = useAppParams();
  const programDayIdx = dayIndexFactory(date, startDate);

  return (
    <Card
      square
      noContainerPaddings
      className="min-w-[8rem] border-l-4 mr-1.5 -my-3 h-auto sticky left-0 z-10"
      style={{ borderColor: color }}
      containerClassName="items-center justify-center text-sm flex flex-col"
    >
      {variantName ? (
        <FormControlLabel
          label={variantName}
          labelPlacement="top"
          value={id}
          control={<Radio />}
        />
      ) : (
        <Tooltip
          placement="top"
          title={t("common.day_passed")}
          disableInteractive={!pastDate}
          disableHoverListener={!pastDate}
          disableFocusListener={!pastDate}
          disableTouchListener={!pastDate}
        >
          <div className="grid justify-items-center">
            <MenuButtonWrapper>
              <DropMenuMui
                icon={<BulletMenu className="step-five" />}
                size="small"
              >
                <MenuItem onClick={handleCopyDay} disabled={!canCopy}>
                  {t("patient.schedule.copy_day")}
                </MenuItem>
                {copiedDay && (
                  <MenuItem
                    onClick={handlePasteDay}
                    disabled={
                      dayjs().isAfter(date, "day") ||
                      copiedDay.programDayId !== programDayId
                    }
                  >
                    {t("patient.schedule.paste_day")}:&nbsp;
                    {dayjs(copiedDay.date).format("L")},&nbsp;
                    {copiedDay.mealsCount}&nbsp;
                    {t("patient.schedule.meals", {
                      count: copiedDay.mealsCount,
                    })}
                  </MenuItem>
                )}
                <MenuItem
                  onClick={handleScheduleClearAlert}
                  disabled={pastDate}
                >
                  {t("patient.schedule.clear.day")}
                </MenuItem>
              </DropMenuMui>
            </MenuButtonWrapper>
            {clearAlertVisible && (
              <ScheduleClearAlert
                variant="day"
                onSubmit={handleScheduleDayClear}
                onClose={handleScheduleClearAlert}
              />
            )}
            <span className="font-semibold mb-1">
              {
                ((isPolishChosen ? WEEKDAYS_MAP : WEEKDAYS_MAP_EN) as any)[
                  dateObj.getDay()
                ]
              }
            </span>
            <span className="mb-2">
              {patientId
                ? format(dateObj, "dd.MM.yyyy")
                : startDate && `${t("common.day")} ${programDayIdx}`}
            </span>
            <ColoredCircle color={color}>
              {(dayName ?? scheduleDay.programDay.name)
                ?.slice(0, 1)
                .toUpperCase()}
            </ColoredCircle>
            {!readOnly && (
              <StyledOrangeIconButton
                color="secondary"
                onClick={handleVariantModal}
                disabled={pastDate}
              >
                <Reload size="w-9 h-9" />
              </StyledOrangeIconButton>
            )}
            {!readOnly && showVariantModal && (
              <DayVariantModal
                {...dayVariantModalProps}
                programDayId={programDayId}
                scheduleDay={scheduleDay}
                programId={programId}
                dayName={dayName}
                color={color}
                date={date}
                onVariantModalClose={handleVariantModal}
              />
            )}
          </div>
        </Tooltip>
      )}
    </Card>
  );
});
