import { useEffect } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { LangDto } from "@client";
import { useAppTranslation } from "@hooks";
import {
  FetchDietitianAccountResponse,
  UpdateDietitianAccount,
} from "@client/settings";
import { POLISH_PHONE_PREFIX } from "@utils";

export const useDietitianAccountForm = (
  defaultValues?: DietitianAccountFormInput,
) => {
  const { t } = useAppTranslation();

  const dietitianAccountSchema = yup.object().shape({
    firstName: yup.string().trim().required(t("common.required_field")),
    lastName: yup.string().trim().required(t("common.required_field")),
    prefix: yup.string().nullable().default(null),
    phone: yup.string().nullable().default(null),
    email: yup.string().email(t("common.email_invalid")).required(),
    language: yup.mixed<LangDto>().oneOf(Object.values(LangDto)).required(),
  });
  const form = useForm<DietitianAccountFormInput>({
    resolver: yupResolver(dietitianAccountSchema),
  });

  useEffect(() => {
    if (defaultValues) form.reset(defaultValues);
  }, [defaultValues]);
  return form;
};

export interface DietitianAccountFormInput {
  firstName: string;
  lastName: string;
  prefix: string | null;
  phone: string | null;
  email: string;
  language: LangDto;
}

export const mapDietitianAccountForm = (
  data?: FetchDietitianAccountResponse,
): DietitianAccountFormInput => {
  return {
    firstName: data?.firstName ?? "",
    lastName: data?.lastName ?? "",
    prefix: data?.phone.prefix ?? POLISH_PHONE_PREFIX,
    phone: data?.phone.number ?? "",
    email: data?.email ?? "",
    language: data?.language ?? LangDto.PL,
  };
};

export const mapDietitianAccountRequest = (
  data: DietitianAccountFormInput,
): UpdateDietitianAccount => {
  return {
    firstName: data.firstName,
    lastName: data.lastName,
    phone:
      data.phone && data.prefix
        ? {
            prefix: data.prefix,
            number: data.phone,
          }
        : {
            prefix: null,
            number: null,
          },
    language: data.language,
  };
};
