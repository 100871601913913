import { Outlet } from "react-router-dom";

import { Header } from "./components/Header";
import { ReactNode } from "react";

/** public layout */
interface LandingProps {
  authPage?: boolean;
  children?: ReactNode;
}
export const Landing = ({ authPage, children }: LandingProps) => {
  return (
    <div className="min-h-screen w-full flex flex-col overflow-x-hidden">
      <Header authPage={authPage} />
      <div className="w-full min-h-screen h-max flex flex-wrap items-center justify-center sm:pt-16 lg:pt-20 pt-12 bg-gray-200">
        {children}
        <Outlet />
      </div>
    </div>
  );
};
