import { useAppTranslation, useModal } from "@hooks";
import { Chip, Divider, Tooltip } from "@mui/material";
import { Pencil } from "@assets/icons";
import {
  ClientProfileCard,
  EditButton,
  SectionTitle,
  SectionWrapper,
  Subtitle,
  TitleSection,
} from "../../ClientProfile.styled";
import { ProfileSectionProps, noData } from "../../ClientProfile";
import { ActiveServicesContent, CheckIcon } from "./PaymentsSection.styled";
import dayjs from "dayjs";
import { ClientProfileCardItem } from "../ClientProfileCardItem";
import { EditClientPaymentsModal } from "@components/EditClientPaymentsModal";

export const PaymentsSection = ({ account }: ProfileSectionProps) => {
  const {
    active,
    profile: {
      applicationModules: {
        chat,
        accessRecipeBase: { alloweat, clinic },
      },
    },
  } = account;
  const { t } = useAppTranslation();
  const { modalOpened, onModalOpen, onModalClose } = useModal();
  const activeUntil = dayjs(active?.until);
  const hasAppModule = chat || alloweat || clinic;

  return (
    <>
      <ClientProfileCard>
        <TitleSection>
          <p>{t("client_profile.payments.payments_and_services")}</p>
          <Tooltip
            title={t("client_profile.payments.edit_payments_tooltip")}
            disableHoverListener={!!active}
            arrow
            placement="top"
          >
            <div>
              <EditButton
                disabled={!active}
                onClick={onModalOpen}
                startIcon={<Pencil size="w-4 h-4" className="stroke-current" />}
                disableRipple
              >
                {t("common.edit")}
              </EditButton>
            </div>
          </Tooltip>
        </TitleSection>

        <SectionWrapper>
          <SectionTitle>
            {t("client_profile.payments.cooperation")}
          </SectionTitle>

          <ClientProfileCardItem
            title={t("client_profile.payments.cooperation")}
            subtitle={
              active ? (
                active?.until ? (
                  <Subtitle>
                    {`${t(
                      "client_profile.payments.valid_until",
                    )} ${activeUntil.format("DD.MM.YYYY")}`}
                  </Subtitle>
                ) : (
                  t("client_profile.edit.payments.indefinite")
                )
              ) : (
                noData
              )
            }
          />
        </SectionWrapper>

        <Divider />

        <SectionTitle>{t("client_profile.payments.mobile_app")}</SectionTitle>
        <ClientProfileCardItem
          title={t("client_profile.payments.mobile_app")}
          subtitle={
            active ? (
              <Chip
                className="w-fit"
                icon={
                  active.mobileApp ? (
                    <CheckIcon size="w-3.5 h-3.5" />
                  ) : undefined
                }
                color={active.mobileApp ? "success" : "warning"}
                size="small"
                label={
                  active.mobileApp
                    ? t("client_profile.payments.status_active")
                    : t("client_profile.payments.status_inactive")
                }
              />
            ) : (
              noData
            )
          }
        />
        <ClientProfileCardItem
          title={t("client_profile.payments.active_services")}
          subtitle={
            hasAppModule ? (
              <ActiveServicesContent>
                {chat && (
                  <Chip
                    className="w-fit"
                    color="primary"
                    size="small"
                    label={t("common.chat")}
                  />
                )}
                {alloweat && (
                  <Chip
                    className="w-fit"
                    color="primary"
                    size="small"
                    label={t("client_profile.payments.alloweat_recipes")}
                  />
                )}
                {clinic && (
                  <Chip
                    className="w-fit"
                    color="primary"
                    size="small"
                    label={t("client_profile.payments.dietitians_recipes")}
                  />
                )}
              </ActiveServicesContent>
            ) : (
              noData
            )
          }
        />
      </ClientProfileCard>

      <EditClientPaymentsModal open={modalOpened} onClose={onModalClose} />
    </>
  );
};
