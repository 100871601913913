import { Accordion, AccordionSummary, styled } from "@mui/material";

export const AccordionStyled = styled(Accordion)`
  &.MuiAccordion-root {
    box-shadow: none;

    &.Mui-expanded {
      margin: 0;
    }

    &:before {
      display: none;
    }
  }
`;

export const AccordionSummaryStyled = styled(AccordionSummary)`
  padding: 0.5rem;
  display: flex;
  gap: 0.5rem;
  flex-direction: row-reverse;

  &.MuiButtonBase-root {
    min-height: unset;
  }

  & .MuiAccordionSummary-content {
    margin: 0;
    min-height: unset;

    &.Mui-expanded {
      margin: 0;
    }
  }

  & .MuiAccordionSummary-expandIconWrapper {
    padding: 0;
    font-size: 1.2rem;
    transform: rotate(-90deg);

    &.Mui-expanded {
      transform: rotate(0);
    }
  }
`;

export const AccordionHeader = styled("header")`
  display: flex;
  gap: 0 0.175rem;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
`;
