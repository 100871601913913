import { useEffect, useState } from "react";
import { useDebounce } from "react-use";

import { isEqual } from "lodash";
import { Typography, useMediaQuery, useTheme } from "@mui/material";

import { useAppParams, useAppTranslation } from "@hooks";
import { FrequencyDay } from "@utils/balance";
import {
  useFetchClientBalanceQuery,
  useUpdateClientBalanceMutation,
} from "@hooks/queries";
import { usePatientForBalance } from "@components/CaloricNeedsForm/components";
import {
  CaloricNeedsForm,
  CaloricNeedsFormInputs,
  mapCaloricNeedsForm,
} from "@components/CaloricNeedsForm";
import { Spinner } from "@components/Spinner";
import { MuiInfoClickTooltip } from "@components/MuiInfoClickTooltip";

import {
  DeskDivider,
  PatientProgramDayGrid,
  WrapperSection,
} from "./PatientEnergyBalance.styled";
import { mapBalanceRequest } from "./balanceUtils";
import { BalanceMacrosSliders } from "./BalanceMacrosSliders";
import { BalanceProgramDayConfig } from "./BalanceProgramDayConfig";

export const BalanceDay = () => {
  const { t } = useAppTranslation();
  const { breakpoints } = useTheme();
  const isMatchDesktop = useMediaQuery(`${breakpoints.up("desktop")}`);

  const { patientId, dayId } = useAppParams();

  const { balanceData, isLoading } = useFetchClientBalanceQuery(patientId);

  const balanceDay = balanceData?.days.find(day => day.id.toString() === dayId);

  const [selectedFrequency, setSelectedFrequency] = useState<FrequencyDay[]>(
    balanceDay?.frequency ?? [],
  );

  const [names, setNames] = useState<NamesIf>({
    pl: balanceDay?.translations.find(t => t.locale === "pl")?.name ?? "",
    en: balanceDay?.translations.find(t => t.locale === "en")?.name ?? "",
  });

  const { client, patientDataFilled } = usePatientForBalance();

  const [caloricNeeds, setCaloricNeeds] = useState<
    CaloricNeedsFormInputs | undefined
  >(mapCaloricNeedsForm(balanceDay, client, patientDataFilled));

  const { mutate: updateBalance } = useUpdateClientBalanceMutation(patientId);

  const handleNameChange = (lang: string, value?: string) => {
    setNames(state => ({ ...state, [lang as "pl" | "en"]: value ?? null }));
  };

  const handleShowName = (lang: string) => names[lang as "pl" | "en"] ?? "";

  useDebounce(
    () => {
      if (
        !balanceData ||
        !caloricNeeds ||
        isEqual(
          mapBalanceRequest(
            dayId ?? "0",
            balanceData,
            names,
            selectedFrequency,
            caloricNeeds,
          ),
          balanceData,
        )
      )
        return;
      updateBalance(
        mapBalanceRequest(
          dayId ?? "0",
          balanceData,
          names,
          selectedFrequency,
          caloricNeeds,
        ),
      );
    },
    300,
    [names, selectedFrequency, caloricNeeds],
  );

  useEffect(() => {
    setSelectedFrequency(balanceDay?.frequency ?? []);
    setNames({
      pl: balanceDay?.translations.find(t => t.locale === "pl")?.name ?? "",
      en: balanceDay?.translations.find(t => t.locale === "en")?.name ?? "",
    });
    setCaloricNeeds(mapCaloricNeedsForm(balanceDay, client, patientDataFilled));
  }, [dayId]);

  if (isLoading) return <Spinner />;

  return (
    <PatientProgramDayGrid>
      <BalanceProgramDayConfig
        selectedDays={selectedFrequency}
        onChange={active => setSelectedFrequency(active)}
        handleMultiLangChange={handleNameChange}
        langValue={handleShowName}
      />
      <WrapperSection isDesk={!isMatchDesktop}>
        <div className="flex-1">
          <div className="flex items-center">
            <Typography variant="h6">
              {t("balance.energy_expenditure")}
            </Typography>
            <MuiInfoClickTooltip
              content={
                <p className="grid text-start">
                  {t("balance.tooltip_1")}
                  <br></br>
                  <br></br>
                  {t("balance.tooltip_2")}
                  <br></br>
                  <br></br>
                  {t("balance.tooltip_3")}
                </p>
              }
            />
          </div>
          <CaloricNeedsForm
            key={JSON.stringify(caloricNeeds)}
            onChange={values => setCaloricNeeds(values)}
            defaultValues={caloricNeeds}
          />
        </div>

        {isMatchDesktop && <DeskDivider />}
        <div className="flex-1">
          <h2 className="text-xl">{t("programs.target_values")}</h2>
          <BalanceMacrosSliders />
        </div>
      </WrapperSection>
    </PatientProgramDayGrid>
  );
};

export interface NamesIf {
  pl: string | null;
  en: string | null;
}
