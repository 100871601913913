import React from "react";

import classnames from "classnames";

import { Chevron } from "@assets/icons";

export const InputEndIcon = (props: InputEndIconProps) => {
  const { variant, focused, icon, onMouseUp, disabled } = props;

  return (
    <button
      className={classnames({
        "flex items-center justify-center px-1 w-10 text-primary": true,
        "border-l": variant === "outlined",
        "border-primary": !disabled && variant === "outlined" && focused,
        "border-gray-500 group-hover:border-secondary":
          !disabled && variant === "outlined" && !focused,
        "rounded-r-md": !disabled,
        "text-gray-700 cursor-default": disabled,
      })}
      type="button"
      onMouseUp={onMouseUp}
      disabled={disabled}
    >
      {icon || (
        <div
          className={classnames({
            "p-1 rounded-full flex items-center hover:bg-secondary-lighter":
              true,
          })}
        >
          <Chevron className={focused ? "transform rotate-180" : undefined} />
        </div>
      )}
    </button>
  );
};

interface InputEndIconProps {
  /** style variant; matches input cariants */
  variant?: "standard" | "outlined" | "clean";
  /** will be shown in focused state (works for "outlined" variant) */
  focused?: boolean;
  /** icon to show instead of Chevron */
  icon?: React.ReactNode;
  /** callback fired when clicking within container */
  onMouseUp?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
  /** is button disabled */
  disabled?: boolean;
}
