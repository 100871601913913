import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const PointerArrowLeft = (props: SvgIconProps) => {
  return (
    <SvgIcon width="12" height="11" viewBox="0 0 12 11" {...props}>
      <path
        d="M11.9121 5.125C11.8783 5.4974 11.6751 5.70052 11.3027 5.73438H2.69531L6.07227 8.95898C6.30924 9.22982 6.30924 9.51758 6.07227 9.82227C5.80143 10.0592 5.51367 10.0592 5.20898 9.82227L0.740234 5.55664C0.621745 5.43815 0.5625 5.29427 0.5625 5.125C0.5625 4.95573 0.621745 4.81185 0.740234 4.69336L5.20898 0.427734C5.51367 0.190755 5.80143 0.190755 6.07227 0.427734C6.30924 0.732422 6.30924 1.02018 6.07227 1.29102L2.69531 4.51562H11.3281C11.6836 4.54948 11.8783 4.7526 11.9121 5.125Z"
        fill={props?.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
