import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { TagExtendedNewDto } from "@client";

export const useMealTagsForm = (values: TagExtendedNewDto[] | undefined) => {
  const [init, setInit] = useState(true);
  const { reset, ...rest } = useForm<MealTagsInputs>({
    defaultValues: {
      tags: [],
    },
  });

  useEffect(() => {
    if (values && init) {
      reset({ tags: values.map(t => t.id) }, { keepDirtyValues: true });
      setInit(false);
    }
  }, [values]);

  return {
    reset,
    ...rest,
  };
};

export interface MealTagsInputs {
  tags: string[];
}
