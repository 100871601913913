import { QueryOptionsTyped } from "@hooks/queries";
import { useQuery } from "@tanstack/react-query";
import {
  FetchMonitoringMeasurementsParams,
  FetchMonitoringMeasurementsResponse,
  fetchMonitoringMeasurements,
} from "@client/patient/monitoring/fetchMonitoringMeasurements";
import { patientMonitoringKeys } from "@hooks/queries/client/monitoring/patientMonitoringKey";

export const useFetchMonitoringMeasurements = (
  params: FetchMonitoringMeasurementsParams,
  options?: QueryOptionsTyped<FetchMonitoringMeasurementsResponse>,
) => {
  return useQuery({
    queryKey: patientMonitoringKeys.measurements(params),
    queryFn: () => fetchMonitoringMeasurements(params),
    ...options,
  });
};
