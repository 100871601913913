import React, { FC, useState } from "react";
import { Menu, MenuItem, ListItemIcon } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { Pencil, TrashNew } from "@assets/icons";
import { useTasksContext } from "../Tasks.context";
import { IconButton } from "@components/IconButton";
import { useAppTranslation } from "@hooks";
import { useIsTaskEditable } from "@hooks/tasks/useIsTaskEditable";
interface TaskDropdownMenuProps {
  id: string;
}

export const TaskDropdownMenu: FC<TaskDropdownMenuProps> = ({ id }) => {
  const { t } = useAppTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { setCurrentTaskId, handleOpenDeleteModal, handleOpenCreateModal } =
    useTasksContext();

  const isTaskEditable = useIsTaskEditable(id);

  const open = Boolean(anchorEl);

  const handleOpenDropdown = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropDown = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        color="purple-light"
        className="hover:bg-gray-450"
        size="small"
        onClick={handleOpenDropdown}
      >
        <MoreVertIcon className="text-gray-450" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseDropDown}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleCloseDropDown}>
          <ListItemIcon>
            <IconButton
              color="primary"
              className="flex gap-x-2 hover:bg-transparent"
              disabled={!isTaskEditable}
              onClick={() => {
                setCurrentTaskId(id);
                handleOpenCreateModal();
              }}
            >
              <Pencil className="text-gray-450 text-lg" />
              <span className="text-black">
                {t("tasks.taskDropdownMenu.editTask")}
              </span>
            </IconButton>
          </ListItemIcon>
        </MenuItem>
        <MenuItem onClick={handleCloseDropDown}>
          <ListItemIcon>
            <IconButton
              color="primary"
              className="flex gap-x-2 hover:bg-transparent"
              onClick={() => {
                setCurrentTaskId(id);
                handleOpenDeleteModal();
              }}
            >
              <TrashNew className="text-gray-450 text-lg" />
              <span className="text-black">
                {t("tasks.taskDropdownMenu.deleteTask")}
              </span>
            </IconButton>
          </ListItemIcon>
        </MenuItem>
      </Menu>
    </div>
  );
};
