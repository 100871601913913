import { styled, Button, Accordion, AccordionSummary } from "@mui/material";

export const ClientHealthWrapper = styled("div")`
  display: grid;
  grid-template-columns: repeat(2, minmax(310px, 355px));
  gap: 16px;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.medium}px) {
    grid-template-columns: repeat(2, minmax(356px, 460px)) minmax(356px, 380px);
  }
`;

export const EditButton = styled(Button)`
  height: unset;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  padding: 0;
  color: ${({ theme }) => theme.palette.primary.main};
  &:hover {
    background: unset;
  }
`;

export const SectionWrapper = styled("div")`
  display: grid;
  gap: 14px;
`;

export const SectionTitle = styled("p")`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;

export const Subtitle = styled("p")`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const HealthSectionsWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledAccordion = styled(Accordion)`
  box-shadow: unset;
  :before {
    display: none;
  }
`;

export const AccordionSummaryStyled = styled(AccordionSummary)`
  padding: 0;
  justify-content: unset;
  min-height: 14px;
  color: ${({ theme }) => theme.palette.primary.main};
  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(180deg);
  }

  & .MuiAccordionSummary-content {
    margin: 0;
    flex-grow: unset;
    margin-right: 4px;
  }

  & .MuiAccordionSummary-expandIconWrapper {
    color: inherit;
  }
`;
