import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import { FileData, fileDataSchema } from "./common";
import * as yup from "yup";

export async function uploadVisitFile(
  patientId: number,
  payload: FormData,
): Promise<ApiResponse<FileData>> {
  const data = await fetchData(
    `/dietitian/patients/${patientId}/schedules/visits/files/upload`,
    APIMethods.POST,
    payload,
    undefined,
    { "Content-Type": "multipart/form-data" },
  );
  savedFileDataSchema.validate(data);
  return data;
}

const savedFileDataSchema = yup.object().shape({
  data: fileDataSchema,
});
