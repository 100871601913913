import { styled } from "@mui/material";

export const Circle = styled("div")(({ color }) => ({
  backgroundColor: color,
  width: "8px",
  height: "8px",
  borderRadius: "16px",
}));

export const TitleWrapper = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const TitleLabel = styled("span")`
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
  line-height: 24px;
`;

export const PercentLabel = styled("span")`
  font-family: Figtree, serif;
  font-weight: 500;
  color: #2f303a;
  line-height: 28px;
  font-size: 14px;
`;

export const LegendLabel = styled("span")`
  font-family: Figtree, serif;
  font-weight: 400;
  color: #2f303a;
  font-size: 12px;
  line-height: 20px;
`;

export const ValueLegendLabel = styled("span")`
  font-family: Figtree, serif;
  font-weight: 600;
  color: #2f303a;
  font-size: 12px;
  line-height: 20px;
`;

export const ItemTitle = styled("span")`
  font-family: Figtree, serif;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
  font-size: 14px;
  line-height: 24px;
`;

export const ItemTitleValue = styled("span")`
  font-family: Figtree, serif;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
  font-size: 18px;
  line-height: 24px;
`;

export const TagsLabel = styled("span")`
  font-family: Figtree, serif;
  font-weight: 500;
  line-height: 24px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;
