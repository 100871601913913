import { Card, DialogContent, styled } from "@mui/material";

interface QuestionCardProps {
  isError?: boolean;
}
export const QuestionCard = styled(Card, {
  shouldForwardProp: prop => prop !== "isError",
})<QuestionCardProps>`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  box-shadow: ${({ theme }) => theme.boxShadows.card};
  padding: 1.5rem;
  border-radius: 1rem;
  border: ${({ isError, theme }) =>
    isError && `1px solid ${theme.palette.error.main}`};
  box-sizing: border-box;
  max-width: 41rem;
  background: ${({ theme }) => theme.gradients.survey};
  backdrop-filter: blur(0.75rem);
  position: relative;
`;

export const DialogContentStyled = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  box-shadow: ${({ theme }) => theme.boxShadows.card};
  padding: 1.5rem;
  border-radius: 1rem;
  box-sizing: border-box;
  background: ${({ theme }) => theme.gradients.survey};
  backdrop-filter: blur(0.75rem);
`;

export const QuestionNumberText = styled("span")`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.medium[800]};
`;

export const RequiredInfo = styled("span")`
  font-size: 0.857rem;
  line-height: 1.429rem;
  color: ${({ theme }) => theme.colors.neutral.medium[600]};
`;

export const QuestionTitle = styled("span")`
  font-size: 1.5rem;
  line-height: 1.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const QuestionDescription = styled("span")`
  font-size: 0.857rem;
  line-height: 1.429rem;
  color: ${({ theme }) => theme.colors.neutral.medium[800]};
  white-space: pre-line;
`;

export const ErrorAlert = styled("span")`
  font-size: 0.857rem;
  line-height: 1.429rem;
  color: ${({ theme }) => theme.palette.error.main};
`;
