import { useQuery } from "@tanstack/react-query";

import { QueryOptionsTyped } from "./types";
import { ApiResponse } from "@typeDefinitions";
import { FetchDietResponse, fetchProgramDiet } from "@client";

export const fetchProgramDietQueryKey = "fetchProgramDietDetails";

export const useFetchProgramDietDetailsQuery = (
  programId: number,
  dayId: number,
  dietId: number,
  options?: QueryOptionsTyped<ApiResponse<FetchDietResponse>>,
) => {
  const { data: dietDetails, ...rest } = useQuery(
    [fetchProgramDietQueryKey, programId, dayId, dietId],
    () => fetchProgramDiet(programId, dayId),
    options,
  );
  return { dietDetails, ...rest };
};
