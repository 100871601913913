import { useCallback, useContext } from "react";
import { useParams } from "react-router-dom";

import {
  WeightGoal,
  calculateAverage,
  mapCpmValues,
  mapSuggestedValues,
} from "@utils/balance";
import { SimulationSlidersContext } from "@views/dietician/PatientEnergyBalance/context/SimulationSlidersContext";

import { useFetchClientBalanceQuery, useFetchClientQuery } from "./queries";

export const useBalanceClientValues = () => {
  const { patientId } = useParams();
  const { balanceData, ...rest } = useFetchClientBalanceQuery(patientId ?? "");
  const { client } = useFetchClientQuery(Number(patientId));
  const { dayKcalDiff } = useContext(SimulationSlidersContext);

  const mapGoal = useCallback(() => {
    if (!client || !client.profile.weight || !client.profile.targetWeight)
      return WeightGoal.MAINTAIN;

    const {
      profile: { weight, targetWeight },
    } = client;

    if (weight > targetWeight) return WeightGoal.LOSS;
    else if (weight < targetWeight) return WeightGoal.GAIN;
    else return WeightGoal.MAINTAIN;
  }, [client]);

  const weightGoal = mapGoal();
  const cpmValues = mapCpmValues(balanceData?.days ?? []);
  const suggestedValues = mapSuggestedValues(
    balanceData?.days ?? [],
    weightGoal,
    dayKcalDiff,
  );

  const cpmAvg = calculateAverage(cpmValues);
  const suggestedAvg = calculateAverage(suggestedValues);

  return {
    balanceData,
    cpmAvg,
    suggestedAvg,
    difference: suggestedAvg - cpmAvg,
    weightGoal,
    ...rest,
  };
};
