import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import * as yup from "yup";
import { ProductDto, productSchema } from "./";

export const fetchCreatorMealProduct = async (
  mealId: string,
  productId: string,
): Promise<ApiResponse<ProductDto>> => {
  const response = await fetchData(
    `/dietitian/creator/meals/${mealId}/products/${productId}`,
    APIMethods.GET,
  );

  return apiResponseSchema.validate(response);
};

const apiResponseSchema = yup.object().shape({
  data: productSchema.required(),
});
