import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MutationOptions } from "./types";
import { postClientAvatar, postClientSendEmailVerification } from "@client";
import { fetchClientQueryKey } from "./useFetchClientQuery";
import { fetchClientsQueryKey } from "./useFetchClientsQuery";
import { ApiErrorIf, ApiResponse } from "@typeDefinitions";
import { FileData } from "@client/visits";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { useAppTranslation } from "@hooks/useAppTranslation";

export const usePostClientAvatar = (id: string, options?: MutationOptions) => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();
  return useMutation<
    ApiResponse<FileData>,
    AxiosError<ApiErrorIf>,
    FormData | undefined
  >(request => postClientAvatar(id, request), {
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries([fetchClientQueryKey, Number(id)]);
      queryClient.invalidateQueries([fetchClientsQueryKey]);
      options?.onSuccess && options?.onSuccess();
    },
    onError: e => e && toast.error(t("client_profile.photo_max_size")),
  });
};
