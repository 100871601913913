import { styled } from "@mui/material";

export const ActionButtonsWrapper = styled("div")`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 16px;
  background-color: ${({ theme }) => theme.palette.primary.light};
  width: 699px;
  border-radius: 0 0 16px 16px;
`;

export const Text = styled("span")`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
`;

export const Bold = styled(Text)`
  font-weight: 600;
`;
