import { useNavigate } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";

import { useStoreFoodEditorMutation } from "@hooks/queries/food";
import { GRAMS_ID } from "@utils";
import { useCategoriesNutrientsFetchQuery } from "@hooks/queries/dictionaries";
import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";

import { ProductFormWrapper } from "./ProductNew.styled";
import {
  DietitianProductFormInput,
  mapNutrientCategoriesForm,
  mapProductRequest,
  useProductForm,
} from "../ProductForm";

export const ProductNew = () => {
  const [init, setInit] = useState(true);
  const { data } = useCategoriesNutrientsFetchQuery();
  const navigate = useNavigate();

  const newProduct = useMemo<DietitianProductFormInput | undefined>(() => {
    if (!data) return;

    return {
      namePl: PRODUCT_NAME_PLACEHOLDER,
      nameEn: "",
      producer: "",
      categoryId: PRODUCT_CATEGORY_PLACEHOLDER.toString(),
      tags: [],
      measures: [{ remoteId: GRAMS_ID.toString(), value: "1" }],
      categories: mapNutrientCategoriesForm(data, []),
      reviewed: false,
      plOnly: false,
      shops: [],
      barcode: null,
    };
  }, [data]);

  const form = useProductForm(newProduct);
  const {
    formState: { isValid },
    handleSubmit,
  } = form;

  const {
    mutate: createProduct,
    isLoading,
    isError,
  } = useStoreFoodEditorMutation({
    onSuccess: data => {
      setInit(false);
      navigate(`/products/${data?.id}/edit`, { replace: true });
    },
  });

  useEffect(() => {
    if (init && isValid)
      handleSubmit(
        data => createProduct(mapProductRequest(data, { version: 0 })),
        err => console.log(err),
      )();
  }, [init, isValid]);

  if (init || isLoading)
    return (
      <ProductFormWrapper>
        <Spinner />
      </ProductFormWrapper>
    );

  if (isError)
    return (
      <ProductFormWrapper>
        <ApiError />
      </ProductFormWrapper>
    );

  return <></>;
};

export const PRODUCT_NAME_PLACEHOLDER = "Nowy produkt";
export const PRODUCT_CATEGORY_PLACEHOLDER = 900;
