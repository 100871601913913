import { ChangeEventHandler, SyntheticEvent, useMemo, useState } from "react";
import { useController } from "react-hook-form";
import { useDebounce } from "react-use";

import { Alert } from "@mui/material";
import { isArray, uniq } from "lodash";

import { fetchFood } from "@client";
import { OptionIf } from "@components/Filters";
import { OuterLabel } from "@components/OuterLabel";
import {
  Lang,
  useAppParams,
  useAppTranslation,
  useMealClientContext,
} from "@hooks";
import { useFetchRecipeProductsSearchQuery } from "@hooks/queries";
import { getTranslation } from "@utils/translations";

import { ProductOptionIf, useProductsContext } from "../../ProductsContext";
import { RecipeEditInput } from "../../useRecipeEditForm";
import { CustomOption } from "./CustomOption";
import {
  ProductAutocomplete,
  ProductTextField,
} from "./ProductSearchAutocomplete.styled";
import { useAlreadyUsedProducts } from "./useAlreadyUsedProducts";

interface ProductSearchAutocompleteProps {
  showLabel?: boolean;
  formIdx: number;
}

export const ProductSearchAutocomplete = ({
  showLabel,
  formIdx,
}: ProductSearchAutocompleteProps) => {
  const { isPolishChosen, t, currentLanguage } = useAppTranslation();
  const { patientId } = useAppParams();
  const client = patientId ? Number(patientId) : undefined;

  const [query, setQuery] = useState<string | undefined>(undefined);
  const {
    field: { value, onChange },
  } = useController<RecipeEditInput, `products.${number}.foodId`>({
    name: `products.${formIdx}.foodId`,
  });

  const {
    field: { onChange: onChangeMeasureId },
  } = useController<RecipeEditInput, `products.${number}.measureId`>({
    name: `products.${formIdx}.measureId`,
  });
  const {
    field: { onChange: onChangeGrams },
  } = useController<RecipeEditInput, `products.${number}.grams`>({
    name: `products.${formIdx}.grams`,
  });
  const { productsOptions, addProduct } = useProductsContext();
  const { alreadyUsedProducts } = useAlreadyUsedProducts();
  const { data, isLoading, submit } = useFetchRecipeProductsSearchQuery({
    client,
  });

  const { productsClientContextMap, getSeverity, getProducts } =
    useMealClientContext();
  const clientContext = useMemo(
    () => (value ? productsClientContextMap.get(value) : undefined),
    [productsClientContextMap],
  );
  const { alert, color } = useMemo(
    () => getSeverity(clientContext ?? {}),
    [getSeverity, clientContext],
  );
  const showAlert = !!color && !!alert;

  const mappedProducts = useMemo(
    () =>
      data?.map(product => {
        const { id, nutrients, translations } = product;
        const clientContext =
          "clientContext" in product ? product.clientContext : undefined;

        return {
          id: id.toString(),
          label: getTranslation(translations, currentLanguage),
          nutrients,
          clientContext,
          alreadyInDiet: alreadyUsedProducts.has(id),
        };
      }),
    [data, isPolishChosen, patientId, alreadyUsedProducts],
  );

  const uniqProducts = useMemo<ProductOptionIf[]>(
    () =>
      uniq([
        ...(productsOptions.filter(p => p.id === value?.toString()) ?? []),
        ...(mappedProducts ?? []),
      ]),
    [mappedProducts, productsOptions],
  );

  const selectedProduct = useMemo(
    () => uniqProducts.find(p => value && p.id === value.toString()),
    [value],
  );

  const handleSearch: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = e => {
    setQuery(e.target.value);
  };

  const handleChange = (
    _: SyntheticEvent<Element, Event>,
    value: OptionIf | null,
  ) => {
    if (value === null) return;

    onChange(Number(value.id));
    handleSelect(value.id);
  };

  const handleSelect = async (id: string[] | string | null) => {
    if (isArray(id) || !id) return;
    const product = await fetchFood(id);
    getProducts([Number(id)]);

    if (!product) return;

    const { measures, nutrients, descriptionPl, description } = product.data;
    addProduct({
      foodId: Number(id),
      measures,
      nutrients,
      translations: [
        { lang: Lang.PL, name: descriptionPl ?? "" },
        { lang: Lang.EN, name: description ?? "" },
      ],
    });
    setQuery((isPolishChosen ? descriptionPl : description) ?? "");
    if (!measures[0]) return;

    onChangeMeasureId(measures[0].id);
    onChangeGrams(measures[0].grams);
  };

  const fetchDefaultQuery = () => {
    if (!query && selectedProduct) setQuery(selectedProduct.label);
  };

  useDebounce(
    () => {
      if (query !== undefined) submit({ client, query });
    },
    300,
    [query, client],
  );

  return (
    <OuterLabel label={showLabel && t("diet.recipe_sidepanel.products")}>
      <ProductAutocomplete
        disableClearable
        size="small"
        options={mappedProducts ?? []}
        value={selectedProduct}
        renderInput={props => (
          <ProductTextField
            {...props}
            onChange={handleSearch}
            helperText={showAlert && <Alert severity={color}>{alert}</Alert>}
          />
        )}
        loading={isLoading}
        onChange={handleChange}
        renderOption={CustomOption}
        onMouseOver={fetchDefaultQuery}
        filterOptions={o => o}
        placeholder={t("diet.recipe_sidepanel.search")}
        componentsProps={{
          paper: {
            sx: { width: "440px" },
          },
        }}
      />
    </OuterLabel>
  );
};
