import { SvgIcon, SvgIconProps } from "@mui/material";

export const QuestionMarkCircle = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M9.3811 9.29997C9.5927 8.69847 10.0103 8.19127 10.5601 7.86819C11.1098 7.54511 11.7561 7.42701 12.3846 7.53481C13.013 7.64261 13.583 7.96934 13.9937 8.45714C14.4043 8.94495 14.6291 9.56234 14.6281 10.2C14.6281 12 11.9281 12.9 11.9281 12.9"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M11.9999 16.5H12.0096"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
