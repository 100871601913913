import { fetchData } from "@utils/api";
import { APIMethods, NoContentApiResponse } from "@typeDefinitions";
import * as yup from "yup";

export type UpdatePatientShowNutrientsParams = {
  patientId: number;
};

export type UpdatePatientShowNutrientsPayload = {
  kcal: boolean;
  macro: boolean;
  micro: boolean;
  important: boolean;
  targetMeals: boolean;
};

export type UpdatePatientShowNutrientsResponse = NoContentApiResponse;

export const updatePatientShowNutrients = async (
  params: UpdatePatientShowNutrientsParams,
  payload: UpdatePatientShowNutrientsPayload,
): Promise<UpdatePatientShowNutrientsResponse> => {
  return await fetchData(
    "/dietitian/patients/{patient_id}/show-nutrients".replace(
      "{patient_id}",
      params.patientId.toString(),
    ),
    APIMethods.PUT,
    payload,
  );
};

export interface PatientShowNutrients {
  kcal: boolean;
  macro: boolean;
  micro: boolean;
  important: boolean;
  targetMeals: boolean;
}

const patientShowNutrientsSchema = yup.object({
  kcal: yup.boolean().required(),
  macro: yup.boolean().required(),
  micro: yup.boolean().required(),
  important: yup.boolean().required(),
  targetMeals: yup.boolean().required(),
});

const schema = yup.object({
  data: patientShowNutrientsSchema.defined(),
});
