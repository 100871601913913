import { ReactNode } from "react";

import styled from "@emotion/styled";
import { Card } from "@components/Card";

interface StyledCardProps {
  header: ReactNode;
  action: ReactNode;
}

export const StyledCard = styled(Card)<StyledCardProps>`
  display: grid;
  margin-bottom: 1.5rem;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`;
