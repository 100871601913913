import useFetchPatientImportantNutrients from "@hooks/queries/client/nutrient/useFetchPatientImportantNutrients";
import { useAppTranslation, useClientParams } from "@hooks";
import {
  ChipItemsWrapper,
  SectionItemChips,
  SectionItemLabel,
  SectionItemLayout,
  TabSectionLayout,
} from "@views/dietician/ClientHealth/ClientHealthNew.styled";
import { useMemo } from "react";
import {
  getNameByLang,
  getNutrientsById,
  useFetchDictionaryNutrients,
} from "@hooks/queries/dictionaries";
import { Nutrients } from "@client/dictionaries";
import { Chip } from "@mui/material";

const ImportantNutrientsItemSection = () => {
  const { t, currentLanguage } = useAppTranslation();
  const id = useClientParams();
  const { data } = useFetchPatientImportantNutrients(
    { patientId: id },
    { staleTime: Infinity },
  );
  const { data: nutrients } = useFetchDictionaryNutrients();

  const importantNutrientsName = useMemo(() => {
    if (!data || !nutrients) {
      return [];
    }

    const importantNutrients = data.data
      .map(({ id }) => getNutrientsById(nutrients, id))
      .filter(nutrient => nutrient !== undefined) as Nutrients[];

    return importantNutrients.map(nutrient =>
      getNameByLang(nutrient, currentLanguage),
    );
  }, [data, nutrients, currentLanguage]);

  return (
    <SectionItemLayout>
      <SectionItemLabel>
        {t(
          "patient.health_and_goal.dietary_profile_tab.nutritional_values_section.important",
        )}
      </SectionItemLabel>
      <SectionItemChips>
        {importantNutrientsName.map((name, index) => (
          <Chip key={index} color="primary" label={name} />
        ))}
      </SectionItemChips>
    </SectionItemLayout>
  );
};

export default ImportantNutrientsItemSection;
