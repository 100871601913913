import useFetchSectionsProgramPreview from "@hooks/queries/program/preview/useFetchSectionsProgramPreview";
import { Box, CircularProgress, Stack } from "@mui/material";
import {
  MaterialItem,
  MaterialItemTitle,
} from "@components/ProgramSearchPreview/Components/ProgramMaterials/ProgramMaterials.styled";
import { filterSystemProgramSections } from "@components/ProgramSearchPreview/utils/ProgramSectionsUtil";
import { useAppTranslation } from "@hooks";
import EmptyHere from "@components/ProgramSearchPreview/Components/EmptyHere/EmptyHere";

type Props = {
  programId: number;
};

const ProgramSystemSections = ({ programId }: Props) => {
  const { currentLanguage, t } = useAppTranslation();
  const { data } = useFetchSectionsProgramPreview(programId, {
    staleTime: Infinity,
  });

  if (data === undefined) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  const materials = filterSystemProgramSections(data.data, true);

  if (materials.length === 0) {
    return (
      <EmptyHere
        title={t("program_preview.system_sections.empty.title")}
        description={t("program_preview.system_sections.empty.description")}
      />
    );
  }

  return (
    <ul style={{ marginTop: "-8px" }}>
      {materials.map((material, index) => {
        const materialName =
          material.translations.find(t => t.lang === currentLanguage)?.name ??
          material.name;

        return (
          <MaterialItem key={index}>
            <MaterialItemTitle>{materialName}</MaterialItemTitle>
          </MaterialItem>
        );
      })}
    </ul>
  );
};

export default ProgramSystemSections;
