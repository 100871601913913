import { TabPanel } from "@mui/lab";
import { styled } from "@mui/material";

export const TabLabel = styled("span")`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
  text-transform: uppercase;
`;

export const TabPanelStyled = styled(TabPanel)`
  padding: 0;
`;
