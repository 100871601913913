import { useMemo, useState } from "react";

import { TabContext } from "@mui/lab";
import { CircularProgress, ClickAwayListener, useTheme } from "@mui/material";
import { round } from "lodash";

import { DocWithApple } from "@assets/icons/DesignSystem";
import { LangDto } from "@client";
import { ExpandableMealTags } from "@components/MealPatternsDataGrid/NameColumnCell/ExpandableMealTags";
import { useAppTranslation, useNutrients } from "@hooks";
import {
  useFetchCreatorMealQuery,
  usePostToggleFavoriteMealPatternMutation,
} from "@hooks/queries";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";
import { useTagsNew } from "@hooks/useTagsNew";
import { ENERGY_ID, buildNutrientsDict } from "@utils";
import { TagCategoryType } from "@utils/tagsNew";

import { DetailsSection } from "../common/DetailsSection";
import { PreviewActionButtons } from "../common/PreviewActionButtons";
import { TabsWrapper } from "../common/TabsWrapper";
import { TagsTab } from "../common/TagsTab";
import {
  DetailsWrapper,
  ImgStyled,
  LoadingWrapper,
  TabPanelStyled,
  TagIconWrapper,
  Wrapper,
} from "./CollectionPreview.styled";
import { NutrientsTab } from "./NutrientsTab/NutrientsTab";
import { RecipesAndProductsTab } from "./RecipesAndProductsTab";
import { getFirstImage } from "./collectionUtils";

interface CollectionPreviewProps {
  mealId: number | null;
  onClose: () => void;
}
export const CollectionPreview = ({
  mealId,
  onClose,
}: CollectionPreviewProps) => {
  const { t } = useAppTranslation();
  const {
    colors: { neutral },
  } = useTheme();
  const { nutrientDict } = useNutrients();
  const { tagCategoryDictionary } = useTagsNew();
  const { account } = useFetchDietitianAccountQuery();

  const [tab, setTab] = useState(Tabs.RECIPES_AND_PRODUCTS);

  const { mutate, isLoading: isLoadingToggle } =
    usePostToggleFavoriteMealPatternMutation();
  const { meal, isLoading } = useFetchCreatorMealQuery(`${mealId}`, {
    enabled: !!mealId,
  });

  const img = useMemo(() => getFirstImage(meal), [meal]);

  const mealNutrientsDict = useMemo(
    () => buildNutrientsDict(meal?.nutrients ?? []),
    [meal],
  );
  const roundedKcal = useMemo(
    () =>
      round(
        mealNutrientsDict.get(ENERGY_ID) ?? 0,
        nutrientDict.get(ENERGY_ID)?.roundPrecision,
      ),
    [mealNutrientsDict, nutrientDict],
  );

  const titleEn = useMemo(
    () => meal?.translations.find(t => t.langId === LangDto.EN)?.name,
    [meal],
  );

  const mealTagList = tagCategoryDictionary.get(TagCategoryType.MEAL)?.tags;

  const mealTags = mealTagList
    ?.map(t => t.id)
    .filter(tag => meal?.tags.map(t => t.id).includes(tag.toString()));

  const tabs = useMemo(
    () => [
      {
        label: t("common.nutrients_values"),
        value: Tabs.NUTRITIONAL_VALUES,
      },
      {
        label: `${t("collections.recipes_products")} (${meal?.numberOfItems})`,
        value: Tabs.RECIPES_AND_PRODUCTS,
      },
      {
        label: t("common.tags"),
        value: Tabs.TAGS,
      },
    ],
    [t, meal?.numberOfItems],
  );
  if (isLoading)
    return (
      <Wrapper>
        <LoadingWrapper>
          <CircularProgress />;
        </LoadingWrapper>
      </Wrapper>
    );

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Wrapper>
        <TabContext value={tab}>
          {img && (
            <PreviewActionButtons
              onToggle={() => mutate(mealId?.toString() ?? "")}
              isLoading={isLoadingToggle}
              isFavorite={!!meal?.favorite.includes(account?.id ?? 0)}
              actions={meal?.actions}
              editLink={`/meals/${mealId}/edit`}
              copyLink={`/meals/${mealId}/copy`}
              onClose={onClose}
              withImg
            />
          )}

          {img && <ImgStyled src={img} />}
          <DetailsWrapper>
            <DetailsSection
              titlePl={meal?.title ?? ""}
              titleEn={titleEn ?? ""}
              kcal={roundedKcal}
              actions={
                <>
                  {!img && (
                    <PreviewActionButtons
                      onToggle={() => mutate(mealId?.toString() ?? "")}
                      isLoading={isLoadingToggle}
                      isFavorite={!!meal?.favorite.includes(account?.id ?? 0)}
                      actions={meal?.actions}
                      editLink={`/meals/${mealId}/edit`}
                      copyLink={`/meals/${mealId}/copy`}
                      onClose={onClose}
                    />
                  )}
                </>
              }
              tags={
                <>
                  {!!mealTags?.length && (
                    <TagIconWrapper>
                      <DocWithApple fill={neutral.dark[700]} />
                      <ExpandableMealTags tags={mealTags} showCount={3} />
                    </TagIconWrapper>
                  )}
                </>
              }
            />

            <TabsWrapper onChange={v => setTab(v)} tabs={tabs} value={tab} />
          </DetailsWrapper>

          <TabPanelStyled value={Tabs.NUTRITIONAL_VALUES}>
            <NutrientsTab nutrients={meal?.nutrients ?? []} />
          </TabPanelStyled>

          <TabPanelStyled value={Tabs.RECIPES_AND_PRODUCTS}>
            <RecipesAndProductsTab
              recipes={meal?.recipes ?? []}
              products={meal?.products ?? []}
            />
          </TabPanelStyled>

          <TabPanelStyled value={Tabs.TAGS}>
            <TagsTab tags={meal?.tags.map(t => Number(t.id)) ?? []} />
          </TabPanelStyled>
        </TabContext>
      </Wrapper>
    </ClickAwayListener>
  );
};

enum Tabs {
  NUTRITIONAL_VALUES = "0",
  RECIPES_AND_PRODUCTS = "1",
  TAGS = "2",
}
