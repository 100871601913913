import { useAppTranslation } from "@hooks";

import { round } from "lodash";

import { scaleNutrients } from "@utils/nutrients";
import { NotFound } from "@views/error/NotFound";
import { useFetchDietitianDietDetails } from "@hooks/queries";
import { Tab, Tabs } from "@components/Tabs";
import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";
import { MacrosList } from "@components/MacrosList";
import { MicronutrientPreview } from "@components/MicronutrientsModal";
import { FoodListElement, RecipeListElement } from "@components/ListModules";

import {
  SubmenuWrapper,
  MeanSeaction,
  SetValueSection,
} from "./DietDetails.styled";

interface DietDetailsProps {
  dietId: number;
  padding?: boolean;
}

export const DietDetails = ({ dietId, padding }: DietDetailsProps) => {
  const { dietDetails, isLoading, isError } =
    useFetchDietitianDietDetails(dietId);
  const { t, isPolishChosen } = useAppTranslation();

  if (isNaN(dietId)) return <NotFound />;

  if (isLoading) return <Spinner />;

  if (isError || !dietDetails) return <ApiError />;

  return (
    <Tabs padding={padding}>
      {dietDetails?.data.meals.map((meal, id) => (
        <Tab
          key={meal.id}
          id={meal.id.toString()}
          title={isPolishChosen ? meal.name : meal.nameEn || meal.name}
        >
          <>
            <SubmenuWrapper>
              <MeanSeaction>
                <span className="font-semibold">
                  {t("meals.mean_values")}
                  {`: ${meal.name}`}
                </span>
                <div className={"flex"}>
                  <MacrosList nutrients={meal.nutrients} />
                  <MicronutrientPreview
                    means={dietDetails.data.meals[id].nutrients}
                  />
                </div>
              </MeanSeaction>
              <SetValueSection>
                <span className="font-semibold">
                  {t("diets.edit.set-values")}
                </span>
                <span className="mr-1 text-sm">
                  <span className="font-semibold">{meal.kcal}</span>&nbsp;
                  {t("common.kcal")}
                </span>
              </SetValueSection>
            </SubmenuWrapper>

            {meal.food.map(
              ({ description, nutrients, config: { grams }, id }) => (
                <FoodListElement
                  id={id}
                  description={description}
                  nutrients={scaleNutrients(nutrients, grams)}
                  grams={grams}
                />
              ),
            )}

            {meal.recipes.map(({ recipe, servings }) => (
              <RecipeListElement
                key={recipe.id}
                recipe={{
                  ...recipe,
                  namePl: recipe.name,
                  nameEn: recipe.nameEn ?? recipe.name,
                  macros: recipe.nutrients.map(n => ({
                    ...n,
                    value: round((n.value * servings) / recipe.servings),
                  })),
                }}
                selectedServings={servings}
                numOfServings={recipe.servings}
              />
            ))}
          </>
        </Tab>
      ))}
    </Tabs>
  );
};
