import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  DietMealItemCreatorResponseDto,
  DietMealListItemCreatorDto,
  deleteCreatorDietMealItems,
} from "@client/diets/creator";
import { MutationOptions } from "@hooks/queries/types";
import { searchDietsQueryKeyNew } from "@hooks/queries/useSearchDietsQueryNew";
import { ApiResponse } from "@typeDefinitions";
import { deleteProgramDietMealItems } from "@client/program/dietCreator";
import { useCreatorVersion } from "@hooks/queries/diets/creator/useCreatorVersion";
import {
  fetchCreatorDietMealsItemsQuery,
  fetchCreatorDietMealsQueryKey,
  fetchCreatorDietNutrientsQueryKey,
  fetchCreatorDietQueryKey,
} from "@hooks/queries/diets/creator";
import {
  clearItemsCount,
  deleteAllMealItems,
} from "@hooks/queries/diets/creator/optimisticUpdateUtils";

export const useDeleteProgramDietMealItemsMutation = (
  programId: string,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();
  const { version } = useCreatorVersion();

  return useMutation<
    unknown,
    unknown,
    UseDeleteProgramDietMealItemsMutationIf,
    MutationContextIf
  >(
    ({ dietId, mealId }) =>
      deleteProgramDietMealItems(programId, dietId, mealId, version),
    {
      ...options,
      onSuccess: (d, v) => {
        options?.onSuccess && options.onSuccess(d, v);
        queryClient.invalidateQueries([fetchCreatorDietQueryKey, v.dietId]);
        queryClient.invalidateQueries([searchDietsQueryKeyNew]);
        queryClient.invalidateQueries([
          fetchCreatorDietNutrientsQueryKey,
          v.dietId,
        ]);
      },
      onMutate: async ({ dietId, mealId }) => {
        await queryClient.cancelQueries([
          fetchCreatorDietMealsItemsQuery,
          dietId,
          mealId,
        ]);
        await queryClient.cancelQueries([
          fetchCreatorDietMealsQueryKey,
          dietId,
        ]);

        const previousItemsData = queryClient.getQueryData<
          ApiResponse<DietMealItemCreatorResponseDto>
        >([fetchCreatorDietMealsItemsQuery, dietId, mealId]);

        queryClient.setQueryData<ApiResponse<DietMealItemCreatorResponseDto>>(
          [fetchCreatorDietMealsItemsQuery, dietId, mealId],
          oldData => {
            if (!oldData) return previousItemsData;

            return deleteAllMealItems(oldData);
          },
        );

        const previousMealData = queryClient.getQueryData<
          ApiResponse<DietMealListItemCreatorDto[]>
        >([fetchCreatorDietMealsQueryKey, dietId]);

        const hasMeal = previousMealData?.data.some(
          meal => meal.id.toString() === mealId,
        );

        if (!hasMeal) return { previousMealData, invalidate: true };

        queryClient.setQueryData<ApiResponse<DietMealListItemCreatorDto[]>>(
          [fetchCreatorDietMealsQueryKey, dietId],
          oldData => {
            if (!oldData) return previousMealData;

            return clearItemsCount(oldData, mealId);
          },
        );
      },
      onSettled: (d, e, v, context) => {
        if (context?.invalidate) {
          queryClient.invalidateQueries([
            fetchCreatorDietMealsQueryKey,
            v.dietId,
          ]);
        }
      },
      onError: (_, { dietId, mealId }) => {
        queryClient.invalidateQueries([fetchCreatorDietMealsQueryKey, dietId]);
        queryClient.invalidateQueries([
          fetchCreatorDietMealsItemsQuery,
          dietId,
          mealId,
        ]);
      },
    },
  );
};

interface UseDeleteProgramDietMealItemsMutationIf {
  dietId: string;
  mealId: string;
}
interface MutationContextIf {
  previousData?: ApiResponse<DietMealListItemCreatorDto[]>;
  invalidate?: boolean;
}
