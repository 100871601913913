import { ProgramEmptyState } from "@views/emptyStates/ProgramEmptyState";
import AddPatientProgramDialog from "@views/dietician/PatientProgramsNew/AddPatientProgramDialog/AddPatientProgramDialog";
import { useState } from "react";

type PatientProgramEmptyStateProps = {
  patientId: number;
};
const PatientProgramsEmptyState = ({
  patientId,
}: PatientProgramEmptyStateProps) => {
  const [openAddPatientProgramDialog, setOpenAddPatientProgramDialog] =
    useState(false);
  return (
    <>
      <ProgramEmptyState
        onClick={() => setOpenAddPatientProgramDialog(true)}
        disabled={false}
      />
      <AddPatientProgramDialog
        patientId={patientId}
        setOpen={setOpenAddPatientProgramDialog}
        open={openAddPatientProgramDialog}
      />
    </>
  );
};

export default PatientProgramsEmptyState;
