import { useContext, useMemo, useState } from "react";

import { TabContext } from "@mui/lab";

import { TabSwitch } from "@components/TabSwitch/TabSwitch";
import { Nutrient } from "@typeDefinitions";
import { GLYCEMIC_INDEX_ID } from "@utils";

import { ServingContext } from "../ServingsContext";

import {
  NutrientsByProduct,
  NutrientsByProductProps,
} from "./NutrientsByProduct";

import { NutrientsSection } from "@components/PreviewDrawer/common";
import { MacrosChart } from "@components/PreviewDrawer/common/MacrosChart";
import { useAppTranslation } from "@hooks";
import { ServingsSlider } from "../ServingsSlider/ServingsSlider";
import {
  CardStyled,
  DividerStyled,
  TabPanelStyled,
} from "./NutrientsTab.styled";
import { ProductIf, ProductsTable } from "./ProductsTable";

export interface NutrientsTabProps {
  nutrients: Nutrient[];
  products: ProductIf[];
  hideSlider?: boolean;
  NutrientsByProductComponent?: ({
    products,
  }: NutrientsByProductProps) => JSX.Element;
}

export const NutrientsTab = ({
  nutrients,
  products,
  hideSlider,
  NutrientsByProductComponent,
}: NutrientsTabProps) => {
  const { t } = useAppTranslation();
  const [tab, setTab] = useState(Tabs.PRODUCTS);
  const { selectedServings, totalServings } = useContext(ServingContext);
  const servingRatio = selectedServings / totalServings;

  const proportionalNutrients = useMemo(
    () =>
      nutrients.map(n => {
        if (n.id === GLYCEMIC_INDEX_ID) return n;
        return {
          ...n,
          value: n.value * servingRatio,
        };
      }),
    [nutrients, servingRatio],
  );

  const proportionalProducts = useMemo(
    () =>
      products.map(p => ({
        ...p,
        grams: p.grams * servingRatio,
      })),
    [products, servingRatio],
  );

  return (
    <TabContext value={tab}>
      <div className="grid gap-[24px]">
        <div className="flex justify-between">
          <ServingsSlider hideSlider={hideSlider} />
          <TabSwitch
            value={tab}
            onChange={setTab}
            tabs={[
              { id: Tabs.PRODUCTS, label: t("common.products") },
              { id: Tabs.GENERAL, label: t("recipe.general") },
            ]}
          />
        </div>

        <TabPanelStyled value={Tabs.PRODUCTS}>
          <CardStyled>
            <ProductsTable
              nutrients={proportionalNutrients}
              products={proportionalProducts}
            />

            <DividerStyled />

            {NutrientsByProductComponent ? (
              <NutrientsByProductComponent products={proportionalProducts} />
            ) : (
              <NutrientsByProduct products={proportionalProducts} />
            )}
          </CardStyled>
        </TabPanelStyled>

        <TabPanelStyled value={Tabs.GENERAL}>
          <CardStyled>
            <MacrosChart nutrients={proportionalNutrients} />

            <DividerStyled />

            <NutrientsSection nutrients={proportionalNutrients} />
          </CardStyled>
        </TabPanelStyled>
      </div>
    </TabContext>
  );
};

enum Tabs {
  PRODUCTS = "0",
  GENERAL = "1",
}
