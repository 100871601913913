import { useMemo } from "react";

import { CircularProgress } from "@mui/material";

import { useAppParams } from "@hooks";
import { useFetchCreatorDietQuery } from "@hooks/queries/diets/creator";

import { MacrosCard } from "../MacrosCard";
import { MealScheduleCard } from "../MealScheduleCard";
import { NutrientsCard } from "../NutrientsCard";
import { NutrientsSidePanelContextProvider } from "../RecipesTab/NutrientsSidePanel";

export const SettingsTab = () => {
  const { dietId, patientId } = useAppParams();
  const { diet, isLoading } = useFetchCreatorDietQuery(dietId);

  const targetNutrients = useMemo(
    () => diet?.targetNutrients ?? [],
    [diet?.targetNutrients],
  );
  const showMacros = useMemo(() => !!diet?.showMacros, [!!diet?.showMacros]);

  if (isLoading) return <CircularProgress />;

  return (
    <div className="grid gap-[16px]">
      <NutrientsSidePanelContextProvider>
        <MacrosCard target={targetNutrients} />
      </NutrientsSidePanelContextProvider>

      <MealScheduleCard
        targetNutrients={targetNutrients}
        showMacros={showMacros}
      />

      {!patientId && <NutrientsCard targetMacros={targetNutrients} />}
    </div>
  );
};
