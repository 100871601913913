import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import { logFetchValidateError } from "@utils/validate";
import * as yup from "yup";
import {
  PatientMeasurementResource,
  patientMeasurementSchema,
} from "@client/resources/PatientMeasurementResource";

export type FetchPatientBodyMeasurementParams = {
  patientId: number;
  bodyMeasurementId: number;
};

export type FetchPatientBodyMeasurementResponse =
  ApiResponse<PatientMeasurementResource>;

export const fetchPatientBodyMeasurement = async (
  params: FetchPatientBodyMeasurementParams,
): Promise<FetchPatientBodyMeasurementResponse> => {
  const response = await fetchData(
    "/dietitian/patient-measurements/{measurement_id}".replace(
      "{measurement_id}",
      params.bodyMeasurementId.toString(),
    ),
    APIMethods.GET,
  );

  return await schema
    .validate(response)
    .catch(
      logFetchValidateError("/dietitian/patient-measurements/{measurement_id}"),
    );
};

const schema = yup.object({
  data: patientMeasurementSchema.defined(),
});
