import { Nutrients } from "@components/Nutrients";
import { SidePanelWrapper } from "@components/SidePanelWrapper";
import { Nutrient } from "@typeDefinitions";
import { CardStyled, NormText } from "./NutrientsSidePanel.styled";
import { useAppParams, useAppTranslation } from "@hooks";
import { OuterLabel } from "@components/OuterLabel";
import { useFetchNormsQuery } from "@hooks/queries";
import { useMemo } from "react";

interface NutrientsSidePanelProps {
  open: boolean;
  onClose: () => void;
  values: Nutrient[] | undefined;
  targets: Nutrient[] | undefined;
  importantNutrients: number[] | undefined;
  normId: number | undefined;
}

export const NutrientsSidePanel = ({
  open,
  onClose,
  values = [],
  targets = [],
  importantNutrients = [],
  normId,
}: NutrientsSidePanelProps) => {
  const { t } = useAppTranslation();
  const { norms } = useFetchNormsQuery();
  const { patientId } = useAppParams();
  const formattedNormString = useMemo(() => {
    if (!norms) return null;

    for (const location of norms) {
      const norm = location.normAge.find(norm => norm.id === normId);
      if (norm) return `${location.name}, ${norm.name}`;
    }
    return null;
  }, [norms, normId]);

  return (
    <SidePanelWrapper
      onClose={onClose}
      open={open}
      title={t("diet.other_nutrients")}
    >
      <div className="p-[16px]">
        <CardStyled>
          {formattedNormString && (
            <OuterLabel
              label={t("diet.dietary_standard")}
              tooltip={
                patientId
                  ? t("diet.dietary_standard_instruction_tooltip")
                  : undefined
              }
            >
              <NormText>{formattedNormString}</NormText>
            </OuterLabel>
          )}
          <Nutrients
            values={values}
            targets={targets}
            importantNutrients={importantNutrients}
          />
        </CardStyled>
      </div>
    </SidePanelWrapper>
  );
};
