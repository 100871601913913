import dayjs from "dayjs";

import { IdDto, LangDto, NutrientDto } from "@client";
import {
  DietMealItemCreatorResponseDto,
  DietMealListItemCreatorDto,
  SaveDietMealCreatorRequest,
} from "@client/diets/creator";
import { ApiResponse } from "@typeDefinitions";
import { CARBS_ID, ENERGY_ID, FATS_ID, PROTEIN_ID } from "@utils/macros";

export const updateDietMeal = (
  oldData: ApiResponse<DietMealListItemCreatorDto[]>,
  payload: SaveDietMealCreatorRequest,
  mealId: string,
): ApiResponse<DietMealListItemCreatorDto[]> => {
  const updateNutrients = (
    targetKcal: number,
    targetProtein: number,
    targetFat: number,
    targetCarb: number,
  ): NutrientDto[] => {
    return [
      { id: ENERGY_ID, value: targetKcal },
      { id: PROTEIN_ID, value: targetProtein },
      { id: FATS_ID, value: targetFat },
      { id: CARBS_ID, value: targetCarb },
    ];
  };

  const updateMealItem = (
    item: DietMealListItemCreatorDto,
  ): DietMealListItemCreatorDto => {
    const {
      translations,
      hour,
      targetKcal,
      targetProtein,
      targetFat,
      targetCarb,
      mealTypeId,
    } = payload;
    return {
      ...item,
      translations: translations.map(({ langId, name, description }) => ({
        langId: langId as LangDto,
        name: name ?? "",
        description: description ?? "",
      })),
      hour,
      targets: updateNutrients(
        targetKcal,
        targetProtein,
        targetFat,
        targetCarb,
      ),
      type: mealTypeId !== null ? { id: mealTypeId } : null,
      itemsCount: item.itemsCount,
    };
  };

  const newData = oldData.data
    .map(item => (item.id === parseInt(mealId) ? updateMealItem(item) : item))
    .sort((a, b) => {
      const timeA = dayjs(a.hour, "HH:mm");
      const timeB = dayjs(b.hour, "HH:mm");
      if (timeA.isBefore(timeB)) return -1;
      if (timeA.isAfter(timeB)) return 1;
      return 0;
    });

  return {
    ...oldData,
    data: newData,
  };
};
export const deleteDietMeal = (
  oldData: ApiResponse<DietMealListItemCreatorDto[]>,
  mealId: string,
): ApiResponse<DietMealListItemCreatorDto[]> => {
  const newData = oldData.data.filter(item => item.id !== parseInt(mealId));

  return {
    ...oldData,
    data: newData,
  };
};

export const updateSortOrder = <T extends IdDto>(
  items: T[],
  sort: number[],
): T[] => {
  if (items.length !== sort.length) {
    throw new Error(
      "The length of items array and sort array must be the same.",
    );
  }

  const itemMap = new Map<number, T>();
  items.forEach(item => {
    itemMap.set(item.id, item);
  });

  const sortedItems: T[] = sort.map(id => {
    const item = itemMap.get(id);
    if (!item) {
      throw new Error(`Item with id ${id} not found.`);
    }
    return item;
  });

  return sortedItems;
};
export const clearItemsCount = (
  oldData: ApiResponse<DietMealListItemCreatorDto[]>,
  mealId: string,
): ApiResponse<DietMealListItemCreatorDto[]> => {
  const newData = oldData.data.map(item => {
    if (item.id === parseInt(mealId)) {
      return {
        ...item,
        nutrients: [],
        itemsCount: 0,
      };
    }
    return item;
  });

  return {
    ...oldData,
    data: newData,
  };
};
export const deleteAllMealItems = (
  oldData: ApiResponse<DietMealItemCreatorResponseDto>,
): ApiResponse<DietMealItemCreatorResponseDto> => {
  return {
    ...oldData,
    data: {
      ...oldData.data,
      products: [],
      recipes: [],
    },
  };
};
