import { Nutrient } from "@typeDefinitions";
import { createContext, useContext } from "react";

interface NutrientsSidePanelContextIf {
  onOpen: (values: Nutrient[] | undefined) => void;
}
export const NutrientsSidePanelContext = createContext<
  NutrientsSidePanelContextIf | undefined
>(undefined);

export const useNutrientsSidePanelContext = () => {
  const context = useContext(NutrientsSidePanelContext);

  if (!context) {
    throw new Error(
      "useNutrientsSidePanelContext must be used within a NutrientsSidePanelContext",
    );
  }

  return context;
};
