import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const postOauthGoogleSetPushCalendar = async (id: string) => {
  await fetchData(
    `/dietitian/oauth/google/set-push-calendar`,
    APIMethods.POST,
    {
      id,
    },
  );
};
