import { useMutation } from "@tanstack/react-query";
import { MutationOptions } from "../types";
import { postDietClone } from "@client/diets";
import { ApiResponse } from "@typeDefinitions";
import { IdDto } from "@client";

export const usePostDietCloneMutation = (options?: MutationOptions) => {
  return useMutation<ApiResponse<IdDto>, unknown, string>(
    dietId => postDietClone(dietId),
    options,
  );
};
