import { EventWithType } from "@typeDefinitions";

export const useEventPosition = (size = 90, marginTop = 0) => {
  const calculateEventPosition = (event: EventWithType) => {
    const startDate = new Date(event.start);
    const endDate = new Date(event.end);

    const startHour = startDate.getHours();
    const startMinutes = startDate.getMinutes();
    const endHour = endDate.getHours();
    const endMinutes = endDate.getMinutes();

    const top = startHour * size + (startMinutes / 60) * size + marginTop;

    const durationInMinutes =
      (endHour - startHour) * 60 + (endMinutes - startMinutes);

    const height = (durationInMinutes / 60) * size;

    return { top, height };
  };

  return { calculateEventPosition };
};
