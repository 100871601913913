import { TextField, styled } from "@mui/material";

export const AmountTextField = styled(TextField)`
  width: 56px;
  & fieldset {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left-width: 0.5px;
  }

  &:hover + .arrows {
    opacity: 1;
  }
`;

export const MeasuresTextField = styled(TextField)`
  & fieldset {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right-width: 0.5px;
  }
`;

export const ArrowsWrapper = styled("div")`
  position: absolute;
  top: 6px;
  right: 2px;
  opacity: 0;
  &:hover {
    opacity: 1;
  }
`;

export const MesureGrams = styled("span")`
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
  font-size: 14px;
`;
