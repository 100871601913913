import { Typography, useTheme } from "@mui/material";

import { useAppTranslation } from "@hooks";

import {
  BarPercent,
  Circle,
  LegendWrapper,
  MacroLabel,
  TypographyStyled,
} from "./RecipeChart.styled";
import { ReactNode } from "react";

interface RecipeChartProps {
  protein: number;
  fat: number;
  carbs: number;
  title?: ReactNode;
}

export const MacrosBarChart = ({
  protein,
  fat,
  carbs,
  title,
}: RecipeChartProps) => {
  const { t } = useAppTranslation();
  const {
    colors: { chartPurple, chartOrange, chartGreen },
    palette: {
      action: { hover },
    },
  } = useTheme();

  const placeholderWidth = 100 - protein - fat - carbs;

  return (
    <>
      {title ? (
        title
      ) : (
        <Typography variant="h6">{t("recipe.energy_distribution")}</Typography>
      )}
      <div className="flex w-full">
        <BarPercent
          background={chartPurple}
          width={`${protein}%`}
          radius="0.75rem 0 0 0.75rem"
        >
          <TypographyStyled variant="subtitle2">{protein}%</TypographyStyled>
        </BarPercent>
        <BarPercent background={chartOrange} width={`${fat}%`}>
          <TypographyStyled variant="subtitle2">{fat}%</TypographyStyled>
        </BarPercent>
        <BarPercent
          background={chartGreen}
          width={`${carbs}%`}
          radius="0 0.75rem 0.75rem 0"
        >
          <TypographyStyled variant="subtitle2">{carbs}%</TypographyStyled>
        </BarPercent>
        <BarPercent
          background={hover}
          width={`${placeholderWidth}%`}
          radius={`${placeholderWidth === 100 ? 0.75 : 0}rem 0.75rem 0.75rem ${
            placeholderWidth === 100 ? 0.75 : 0
          }rem`}
        />
      </div>
      <LegendWrapper>
        <MacroLabel>
          <Circle color={chartPurple} />
          <Typography variant="body2">{t("recipe.protein")}</Typography>
        </MacroLabel>
        <MacroLabel>
          <Circle color={chartOrange} />
          <Typography variant="body2">{t("recipe.fat")}</Typography>
        </MacroLabel>
        <MacroLabel>
          <Circle color={chartGreen} />
          <Typography variant="body2">{t("recipe.carbs")}</Typography>
        </MacroLabel>
      </LegendWrapper>
    </>
  );
};
