import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { FormProvider } from "react-hook-form";
import { toast } from "react-toastify";

import { useSectionForm } from "@hooks";
import { useCreateSectionMutation } from "@hooks/queries";
import { SectionFormInput } from "@typeDefinitions/types";
import { SingleCardPageLayout } from "@components/PageLayout";
import { EditorHeaderReviewedToggle } from "@components/EditorHeaderReviewedToggle";
import { SectionForm } from "@components/SectionForm";
import { FormCommitFooter } from "@components/MealForm";

export const SectionNew = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const form = useSectionForm();
  const { mutate: createSectionMutation, isLoading } = useCreateSectionMutation(
    {
      onSuccess: () => toast.success(t("sections.success_save")),
    },
  );

  const submit = useCallback(
    (d: SectionFormInput) => {
      createSectionMutation(
        {
          title: d.namePl,
          titleEn: d.nameEn ?? "",
          content: d.contentPl ?? "",
          contentEn: d.contentEn ?? "",
          programSectionCategoryId: parseInt(d.sectionCategoryId),
          reviewed: !!d.reviewed,
        },
        { onSuccess: () => navigate(-1) },
      );
    },
    [createSectionMutation],
  );

  return (
    <FormProvider {...form}>
      <SingleCardPageLayout
        title={t("sections.new_section")}
        extra={<EditorHeaderReviewedToggle name={"reviewed"} />}
      >
        <form
          className="flex flex-col gap-7 p-7"
          onSubmit={form.handleSubmit(submit)}
        >
          <SectionForm withCategory />

          <FormCommitFooter
            actionText={t("common.save")}
            isLoading={isLoading}
          />
        </form>
      </SingleCardPageLayout>
    </FormProvider>
  );
};
