import { IconButton, Tooltip } from "@mui/material";
import {
  CheckedMonitoring,
  UncheckedMonitoring,
} from "@assets/icons/Monitoring";
import { useQueryClient } from "@tanstack/react-query";
import { getKey } from "@hooks/queries/client/program/preview/useFetchPatientProgramSearchPreview";
import { FetchPatientProgramPreviewSearchResponse } from "@client/patient/programs/fetchPatientProgramSearchPreview";
import { usePostClientActivateProgram } from "@hooks/queries";
import { useAppTranslation } from "@hooks";

type ActiveActionType = {
  program: {
    id: number;
    isActive: boolean;
  };
  patientId: number;
};

const ActiveAction = ({ program, patientId }: ActiveActionType) => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();
  const { mutate } = usePostClientActivateProgram(program.id, {
    onSuccess: (
      data,
      variables: {
        patientId: number;
        activate: boolean;
      },
    ) => {
      queryClient.setQueryData(
        getKey({ programId: program.id, patientId: patientId }),
        (oldData: FetchPatientProgramPreviewSearchResponse | undefined) => {
          if (!oldData) {
            return;
          }

          return {
            ...oldData,
            data: {
              ...oldData.data,
              isActive: variables.activate,
            },
          };
        },
      );
    },
  });

  const handleOnClick = () => {
    mutate({
      patientId: patientId,
      activate: !program.isActive,
    });
  };

  return (
    <>
      {program.isActive && (
        <Tooltip
          title={t("patient.program_preview.menu_actions.deactivate")}
          arrow
        >
          <IconButton onClick={handleOnClick}>
            <CheckedMonitoring
              fill="#3AB795"
              style={{ width: "32px", height: "32px" }}
            />
          </IconButton>
        </Tooltip>
      )}
      {!program.isActive && (
        <Tooltip
          title={t("patient.program_preview.menu_actions.activate")}
          arrow
        >
          <IconButton onClick={handleOnClick}>
            <UncheckedMonitoring
              fill="#FFFFFF"
              style={{ width: "32px", height: "32px" }}
            />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default ActiveAction;
