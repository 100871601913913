import { useMemo, ReactNode } from "react";
import _ from "lodash";

import { NormNutrientDto } from "@client";

import { NormsContext } from "./NormsContext";

interface INormsProps {
  children: ReactNode;
  norm?: NormNutrientDto[];
}

export const NormsProvider = ({ children, norm }: INormsProps) => {
  const providerValue = useMemo(() => {
    const dict = _(norm)
      .groupBy(n => n.id)
      .mapValues(n => n[0])
      .value();

    return {
      isEmpty: !norm?.length,
      getNorm: (id: number) => dict[id],
    };
  }, [norm]);

  return (
    <NormsContext.Provider value={providerValue}>
      {children}
    </NormsContext.Provider>
  );
};
