import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Carrot = (props: SvgIconProps) => (
  <SvgIcon {...props} width="26" height="26" viewBox="0 0 26 26">
    <g id="ic-menu-diet" transform="translate(13 14)">
      <g
        id="Group_8"
        data-name="Group 8"
        transform="translate(-8.087 -9.042)"
        fill="currentColor"
      >
        <g
          id="Group_4431"
          data-name="Group 4431"
          transform="translate(123.425 -1172.29) rotate(50)"
        >
          <path
            id="Path_874"
            data-name="Path 874"
            d="M839.734,903.3a1.155,1.155,0,0,0,2.131,0,46.651,46.651,0,0,0,3.848-12.585,3.622,3.622,0,0,0-2.513-4.125l-.066-.017a9.772,9.772,0,0,0-4.669,0l-.066.017a3.622,3.622,0,0,0-2.513,4.125A46.666,46.666,0,0,0,839.734,903.3Z"
            transform="translate(-11.111 -42.424)"
            fill="none"
            stroke="currentColor"
            strokeMiterlimit="10"
            strokeWidth="1.3"
          />
          <line
            id="Line_281"
            data-name="Line 281"
            x1="3.363"
            transform="translate(831.087 847.059)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1.3"
          />
          <line
            id="Line_282"
            data-name="Line 282"
            x1="1.795"
            transform="translate(832.769 848.567)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1.3"
          />
          <line
            id="Line_283"
            data-name="Line 283"
            x2="3.363"
            transform="translate(826.074 853.601)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1.3"
          />
          <line
            id="Line_284"
            data-name="Line 284"
            x2="1.795"
            transform="translate(826.459 855.11)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1.3"
          />
          <path
            id="Path_875"
            data-name="Path 875"
            d="M824.269,852.631l-.021-.006c-.04-.011-.078-.021-.115-.029s-.092-.021-.139-.029-.088-.015-.125-.019l-.024,0a1.434,1.434,0,0,0-.209-.01h0a2.123,2.123,0,0,0-.837,4.074,1.567,1.567,0,0,0,2.871,1.2,2.639,2.639,0,0,1,2.311.716C827.244,853.948,825.283,852.92,824.269,852.631Z"
            transform="translate(0 -16.248)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1.3"
          />
          <path
            id="Path_876"
            data-name="Path 876"
            d="M869.248,852.631l.021-.006c.04-.011.078-.021.115-.029s.092-.021.139-.029.088-.015.125-.019l.024,0a1.436,1.436,0,0,1,.21-.01h0a2.123,2.123,0,0,1,.837,4.074,1.567,1.567,0,0,1-2.872,1.2,2.639,2.639,0,0,0-2.311.716C866.272,853.948,868.234,852.92,869.248,852.631Z"
            transform="translate(-34.141 -16.248)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1.3"
          />
          <path
            id="Path_877"
            data-name="Path 877"
            d="M838.282,867.805a1.478,1.478,0,0,1,.563,1.162,1.6,1.6,0,0,1-.17.683"
            transform="translate(-13.005 -28.091)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1.3"
          />
          <path
            id="Path_878"
            data-name="Path 878"
            d="M847.4,835.638a2.535,2.535,0,0,0-2.267-2.521,2.1,2.1,0,0,0-4.05,0,2.535,2.535,0,0,0-2.267,2.521"
            transform="translate(-13.418 0)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1.3"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);
