import dayjs from "dayjs";
import { SubscriptionTypes } from "@utils/user";
import { SubscriptionDto } from "@client/settings";

export function getSubscription(subscription: SubscriptionDto | undefined) {
  const hasSubscription =
    subscription && subscription.finishedAt
      ? dayjs(subscription.finishedAt).endOf("day").isAfter(dayjs())
      : true;
  const trialDaysLeft =
    subscription && dayjs(subscription.finishedAt).isAfter(dayjs())
      ? dayjs(dayjs(subscription.finishedAt)).diff(dayjs(), "days")
      : 0;
  const isIndefinite = subscription && !subscription?.finishedAt;
  const isTrial = subscription?.type.id === SubscriptionTypes.TRIAL;
  const isUnlimitedGrowth =
    subscription?.type.id === SubscriptionTypes.UNLIMITED_GROWTH;
  const isGrowth = subscription?.type.id === SubscriptionTypes.GROWTH;
  const isStarter = subscription?.type.id === SubscriptionTypes.STARTER;
  const isGrowth10 = subscription?.type.id === SubscriptionTypes.GROWTH_10;
  const isGrowth30 = subscription?.type.id === SubscriptionTypes.GROWTH_30;
  const isGrowth60 = subscription?.type.id === SubscriptionTypes.GROWTH_60;
  const isEnterprise = subscription?.type.id === SubscriptionTypes.ENTERPRISE;
  const isStudent = subscription?.type.id === SubscriptionTypes.STUDENT;

  return {
    trialDaysLeft,
    hasSubscription,
    isIndefinite,
    isTrial,
    isUnlimitedGrowth,
    isGrowth,
    isStarter,
    isGrowth10,
    isGrowth30,
    isGrowth60,
    isEnterprise,
    isStudent,
  };
}
