import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const updateCreatorMealProductsSort = async (
  mealId: string,
  payload: UpdateCreatorMealProductsSortRequest,
  version: number,
) => {
  return await fetchData(
    `/dietitian/creator/meals/${mealId}/products-sort`,
    APIMethods.PUT,
    payload,
    undefined,
    { "Alloweat-Creator-Version": `${version}` },
  );
};

interface UpdateCreatorMealProductsSortRequest {
  products: number[];
}
