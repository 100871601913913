import { useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";

import { TabContext } from "@mui/lab";
import { useTheme } from "@mui/material";

import { Globe } from "@assets/icons/DesignSystem";
import { LangDto } from "@client";
import { TranslationExtendedDto } from "@client/diets/creator";
import { ModalWrapper } from "@components/ModalWrapperNew";
import { TabSwitch } from "@components/TabSwitch";
import { useAppParams, useAppTranslation } from "@hooks";

import { TabLabel, TabPanelStyled } from "./CommentModal.styled";
import {
  mapCommentForm,
  mapCommentRequest,
  useCommentForm,
} from "./useCommentForm";
import { useMealData } from "./useMealData";
import { useUpdateDietMealMutation } from "@views/dietician/DietCreator/hooks";
import { FormTextFieldCharCounter } from "@components/FormTextField";

interface CommentModalProps {
  open: boolean;
  onClose: () => void;
  mealId: number;
  translations: TranslationExtendedDto[];
}

export const CommentModal = ({
  open,
  onClose,
  translations,
  mealId,
}: CommentModalProps) => {
  const { dietId } = useAppParams();
  const { t } = useAppTranslation();
  const {
    colors: { neutral },
  } = useTheme();
  const { mutate, isLoading } = useUpdateDietMealMutation({
    onSuccess: onClose,
  });
  const { mealData, dietLang } = useMealData(mealId);
  const mappedForm = useMemo(
    () => mapCommentForm(translations),
    [translations],
  );
  const { handleSubmit, getValues, ...rest } = useCommentForm(mappedForm);

  const handleSave = () => {
    handleSubmit(
      d => {
        const payload = mapCommentRequest(d, mealData);
        if (payload) mutate({ dietId, mealId: mealId.toString(), payload });
      },
      e => console.log(e),
    )();
  };

  const tabs = useMemo(
    () =>
      Object.keys(getValues())
        .filter(key => key !== LangDto.PL && dietLang.includes(key))
        .map(k => ({ id: k, label: t(`common.${k}`) })),
    [getValues(), t],
  );
  const [tab, setTab] = useState<string>(LangDto.EN);

  const hasAnyComment = useMemo(
    () => translations.some(t => !!t.description),
    [translations],
  );

  return (
    <FormProvider handleSubmit={handleSubmit} getValues={getValues} {...rest}>
      <ModalWrapper
        open={open}
        onClose={onClose}
        onSubmit={handleSave}
        title={t(`diet.comment_modal.${hasAnyComment ? "edit" : "add"}`)}
        loading={isLoading}
      >
        <FormTextFieldCharCounter
          key="pl"
          name="pl"
          size="small"
          fullWidth
          multiline
          maxLength={160}
          minRows={2}
        />

        {!!tabs.length && (
          <div className="grid gap-[16px]">
            <div className="flex items-center gap-[8px]">
              <Globe size="w-[16px] h-[16px]" fill={neutral.dark[700]} />
              <TabLabel>{t("diet.comment_modal.other")}</TabLabel>
              <TabSwitch tabs={tabs} value={tab} onChange={setTab} />
            </div>
            <TabContext value={tab}>
              {tabs.map(({ id }) => (
                <TabPanelStyled key={id} value={id}>
                  <FormTextFieldCharCounter
                    key={id}
                    name={id}
                    size="small"
                    fullWidth
                    multiline
                    maxLength={160}
                    minRows={2}
                  />
                </TabPanelStyled>
              ))}
            </TabContext>
          </div>
        )}
      </ModalWrapper>
    </FormProvider>
  );
};
