import { Chip, styled } from "@mui/material";

interface CustomChipProps {
  borderRadius?: string;
}

export const CustomChip = styled(Chip)<CustomChipProps>`
  & .MuiChip-label.MuiChip-labelMedium {
    padding: 6px 8px;
  }

  &.MuiButtonBase-root.MuiChip-root.MuiChip-outlined.MuiChip-sizeMedium {
    border-radius: ${({ borderRadius }) => borderRadius ?? "16px"};
  }

  & .MuiChip-label.MuiChip-labelSmall {
    padding: 0 7px;
  }

  &.MuiChip-colorPrimary {
    background: ${({ theme }) => theme.palette.primary.medium};
    border-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
  }

  &.MuiChip-colorDefault {
    background: ${({ theme }) => theme.palette.background.default};
    border-color: ${({ theme }) => theme.palette.primary.medium};
    color: ${({ theme }) => theme.colors.neutral.dark[700]};
  }
`;

export const LabelWrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: 8px;
`;
