import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import * as yup from "yup";

export const createPatientProgram = async (
  id: number,
  section: CreatePatientProgramRequestDto,
): Promise<CreatePatientProgramResponseDto> => {
  const rsp = await fetchData(
    `/dietitian/patients/${id}/programs`,
    APIMethods.POST,
    section,
  );
  await createProgramResponseValidation.validate(rsp.data);
  return rsp.data;
};

export interface CreatePatientProgramRequestDto {
  name: "";
  hasDiary: true;
  showNutrients: [];
  sections: [];
  startDate: "";
  finishDate: null;
}

export interface CreatePatientProgramResponseDto {
  id: number;
}

const createProgramResponseValidation = yup
  .object()
  .shape({
    id: yup.number().required(),
  })
  .required();
