import { useEffect, useMemo } from "react";
import { FormProvider, UseFormReturn, useController } from "react-hook-form";

import { IconButton, InputAdornment } from "@mui/material";
import { isNumber } from "lodash";

import { Close, Loupe } from "@assets/icons";
import { AutosaveWatchComponent } from "@components/AutosaveWatchComponent";
import {
  DEFAULT_MACROS_RANGE,
  RecipesFiltersFormInput,
  RecipesFiltersNew,
} from "@components/RecipesFiltersNew";
import { useSearchMealPatternsInfiniteQuery } from "@hooks/queries";

import { defaultFilters as recipeResetFilters } from "@components/RecipesFiltersNew/recipesFiltersUtils";
import { useAppTranslation } from "@hooks";
import { CollectionAccordion } from "../ElementsAccordions/CollectionAccordion";
import { FormTextFieldStyled } from "./AddElementSidePanel.styled";
import { TabWrapper } from "./common.styled";
import { FiltersAlert } from "./FiltersAlert";
import { InfiniteList } from "./InfiniteList";

interface CollectionsTabProps {
  form: UseFormReturn<RecipesFiltersFormInput, any>;
  setTotal: (total: number | undefined) => void;
  defaultFilters?: RecipesFiltersFormInput;
  mealId: number;
}

export const CollectionsTab = ({
  form,
  setTotal,
  defaultFilters,
  mealId,
}: CollectionsTabProps) => {
  const { t } = useAppTranslation();
  const resetValues = useMemo(
    () => ({
      ...recipeResetFilters,
      patient: defaultFilters?.patient,
    }),
    [],
  );
  const { meals, meta, submit, isFetching, ...rest } =
    useSearchMealPatternsInfiniteQuery(defaultFilters, {
      refetchOnMount: false,
    });

  const {
    field: { value, onChange },
  } = useController({ control: form.control, name: "query" });
  const handleClearQuery = () => onChange("");

  const handleReset = () => {
    form.reset(resetValues);
    submit(resetValues);
  };

  const clearDefaultFilters = () => {
    const newFilters = {
      ...defaultFilters,
      nutrients: DEFAULT_MACROS_RANGE,
      tags: [],
    };

    form.reset(newFilters);
    submit(newFilters);
  };

  useEffect(() => {
    if (isNumber(meta?.total)) setTotal(meta?.total);
  }, [meta?.total]);

  return (
    <TabWrapper>
      <div className="flex justify-between">
        <div className="flex gap-[4px]">
          <FormProvider {...form}>
            <AutosaveWatchComponent isLoading={isFetching} onSubmit={submit} />
            <FormTextFieldStyled
              control={form.control}
              name="query"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Loupe />
                  </InputAdornment>
                ),
                endAdornment: value && (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={handleClearQuery}>
                      <Close size="w-[10px] h-[10px]" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder={t("common.search")}
            />
            <RecipesFiltersNew
              unshiftTabs
              total={meta?.total}
              resetValues={resetValues}
            />
          </FormProvider>
        </div>
      </div>

      <FiltersAlert
        defaultFilters={defaultFilters}
        onClear={clearDefaultFilters}
      />

      <InfiniteList
        list={meals.map(m => ({ mealId, ...m }))}
        Element={CollectionAccordion}
        clearFilters={handleReset}
        {...rest}
      />
    </TabWrapper>
  );
};
