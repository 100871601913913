export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const getProgressColor = (isToday: boolean, progress: number) => {
  if (isToday) {
    return "#7448D0";
  }

  if (progress < 30) {
    return "#D44450";
  }

  if (progress < 80) {
    return "#FAB569";
  }

  return "#3AB795";
};
