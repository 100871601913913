import * as yup from "yup";

import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";

import { DictionaryNameDto, dictionaryNameSchema } from "./common";

export const fetchClientAdditionalFeatures = async (): Promise<
  ApiResponse<AdditionalFeatureDto[]>
> => {
  const response = await fetchData(
    `/dietitian/patient/profile/dictionaries/additional-features`,
    APIMethods.GET,
  );
  await fetchClientAdditionalFeaturesSchema.validate(response);

  return response;
};

const additionalFeatureSchema = yup.object().shape({
  id: yup.number().required(),
  names: yup.array().of(dictionaryNameSchema).required(),
  sex: yup.mixed().oneOf(["m", "w"]).required(),
});

const fetchClientAdditionalFeaturesSchema = yup.object().shape({
  data: yup.array().of(additionalFeatureSchema).required(),
});

export interface AdditionalFeatureDto {
  id: number;
  names: DictionaryNameDto[];
  sex: "m" | "w";
}
