import { TabStyled, TabsStyled } from "./TabsWrapper.styled";

interface Tab<T extends string> {
  label: string;
  value: T;
}
interface TabsWrapperProps<T extends string> {
  value: T;
  onChange: (value: T) => void;
  tabs: Tab<T>[];
}
export const TabsWrapper = <T extends string>({
  value,
  onChange,
  tabs,
}: TabsWrapperProps<T>) => {
  return (
    <TabsStyled value={value} onChange={(_, v) => onChange(v)}>
      {tabs.map(({ label, value }, idx) => (
        <TabStyled label={label} value={value} key={idx} />
      ))}
    </TabsStyled>
  );
};
