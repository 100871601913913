import { styled, Typography } from "@mui/material";

export const ColumnsStyled = styled("div")`
  columns: 20rem auto;
  column-gap: 2rem;
  padding: 0 1rem;
`;

export const TypographyStyled = styled(Typography)`
  font-weight: 600;
`;

export const MeasureElement = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.border}`};
  padding: 0.5rem 0 0.3rem;
  break-inside: avoid;
`;
