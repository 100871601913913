import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import { useFetchMonitoringIrrigation } from "@hooks/queries/client/monitoring";
import IrrigationBox from "@views/dietician/PatientMonitoring2/components/Irrigation/IrrigationBox";
import sumBy from "lodash/sumBy";
import { useCallback, useMemo } from "react";
import { mapSelectedDaysToRequestQueryParams } from "@views/dietician/PatientMonitoring2/PatientMonitoringUtils";
import { PatientHydrationProductsMonitoringResource } from "@client/resources/PatientHydrationProductsMonitoringResource";
import {
  tooltipLabelFormatter,
  xAxisTickFormatter,
} from "@views/dietician/PatientMonitoring2/utils/ChartUtils";
import { useAppTranslation } from "@hooks";

const IrrigationBoxOfDays = () => {
  const { t } = useAppTranslation();
  const { patientId, selectedDays } = useMonitoring();
  const { data } = useFetchMonitoringIrrigation({
    patientId,
    ...mapSelectedDaysToRequestQueryParams(selectedDays.selectDays),
  });

  const value = useMemo(() => getValue(data?.data ?? []), [data]);
  const norm = useMemo(() => getNorm(data?.data ?? []), [data]);

  const getXAxisTickFormatter = useCallback(
    (date: string) => xAxisTickFormatter(t, date, data?.data.length ?? 0),
    [t, data],
  );

  const getTooltipLabelFormatter = useCallback(
    (date: string) => tooltipLabelFormatter(t, date, data?.data.length ?? 0),
    [t, data],
  );

  return (
    <IrrigationBox
      value={value}
      data={data?.data ?? []}
      norm={norm}
      xAxisTickFormatter={getXAxisTickFormatter}
      tooltipLabelFormatter={getTooltipLabelFormatter}
    />
  );
};

const getValue = (data: PatientHydrationProductsMonitoringResource[]) => {
  if (data.length === 0) {
    return 0;
  }

  return (
    sumBy(data, i => sumBy(i.products, o => o.milliliters)) /
    1000 /
    Math.max(data.filter(d => d.goal !== null).length, 1)
  );
};

const getNorm = (data: PatientHydrationProductsMonitoringResource[]) => {
  if (data.length === 0) {
    return 0;
  }

  return (
    sumBy(data, i => i.goal ?? 0) /
    1000 /
    Math.max(data.filter(d => d.goal !== null).length, 1)
  );
};

export default IrrigationBoxOfDays;
