import { ListTabs, useAppTranslation, useListTabs, useUserRoles } from "@hooks";
import { useMemo } from "react";
import { Stack } from "@mui/material";
import FilterTabChip from "@components/ProgramsFilters/Filters/Fields/FilterTabChip";
import AllFilterTabChip from "@components/ProgramsFilters/Filters/Fields/AllFilterTabChip";

type ProgramsTabsFiltersType = {
  total: number;
};
const ProgramsTabsFilters = ({ total }: ProgramsTabsFiltersType) => {
  const { t } = useAppTranslation();
  const { adminTabs, regularTabs } = useListTabs();
  const { isAdminClinicMember } = useUserRoles();
  const tabs: { label: string; id: ListTabs }[] = useMemo(
    () => (isAdminClinicMember ? adminTabs : regularTabs),
    [t, isAdminClinicMember],
  );
  const allTab = useMemo(() => tabs.find(t => t.id === ListTabs.ALL), [tabs]);
  const tabsWithoutAll = useMemo(
    () => tabs.filter(t => t.id !== ListTabs.ALL),
    [tabs],
  );

  return (
    <Stack direction="row" gap="8px">
      <AllFilterTabChip label={allTab?.label ?? ""} total={total} />
      {tabsWithoutAll.map(tab => {
        return (
          <FilterTabChip
            key={tab.id}
            label={tab.label}
            formField={tab.id}
            total={total}
          />
        );
      })}
    </Stack>
  );
};

export default ProgramsTabsFilters;
