import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const patchClinicProduct = async (id: number, isActive: boolean) => {
  return await fetchData(
    `/dietitian/clinic/products/${id}/active`,
    APIMethods.PATCH,
    { isActive },
  );
};
