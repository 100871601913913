import { styled } from "@mui/material";

interface BreakpointsIf {
  isMobile?: boolean;
  isTablet?: boolean;
}
export const MacrosWrapper = styled("div")<BreakpointsIf>`
  display: grid;
  grid-template-columns: ${({ isMobile }) =>
    isMobile ? "repeat(2, 1fr)" : "repeat(4, 1fr)"};
`;

export const MicrosWrapper = styled("div")`
  display: flex;
  flex-wrap: wrap;
`;

export const MealAverageNutrientsWrapper = styled("div")<BreakpointsIf>`
  display: flex;
  flex-direction: ${({ isTablet }) => (isTablet ? "column" : "row")};
  align-items: center;
`;

export const TitleWrapper = styled("div")`
  display: flex;
  align-items: center;
  align-self: start;
  white-space: nowrap;
  height: 2.75rem;
`;

("flex justify-between gap-14");

export const ToggleButtonsWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  gap: 3.5rem;
`;
