import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const SymptomsMonitoring = (props: SvgIconProps) => (
  <SvgIcon width="14" height="14" viewBox="0 0 14 14" {...props}>
    <path
      d="M2.84277 1.03125C2.96973 1.33594 2.79199 1.69141 2.4873 1.81836L1.39551 2.22461C1.31934 2.25 1.26855 2.32617 1.26855 2.42773V5.70312C1.26855 7.17578 2.43652 8.34375 3.90918 8.34375H4.1123H4.31543C5.7627 8.34375 6.95605 7.17578 6.95605 5.70312V2.42773C6.95605 2.32617 6.87988 2.25 6.80371 2.22461L5.71191 1.81836C5.40723 1.69141 5.22949 1.33594 5.35645 1.03125C5.4834 0.726562 5.83887 0.548828 6.14355 0.675781L7.23535 1.08203C7.79395 1.28516 8.1748 1.81836 8.1748 2.42773V5.70312C8.1748 7.70898 6.65137 9.35938 4.72168 9.5625C4.79785 11.1621 6.14355 12.4062 7.76855 12.4062C9.44434 12.4062 10.8154 11.0605 10.8154 9.35938V7.45508C9.97754 7.20117 9.39355 6.41406 9.39355 5.5C9.39355 4.38281 10.2822 3.46875 11.4248 3.46875C12.542 3.46875 13.4561 4.38281 13.4561 5.5C13.4561 6.41406 12.8467 7.20117 12.0342 7.45508V9.35938C12.0342 11.7207 10.1045 13.625 7.76855 13.625C5.45801 13.625 3.5791 11.8223 3.50293 9.5625C1.54785 9.35938 0.0498047 7.70898 0.0498047 5.70312V2.42773C0.0498047 1.81836 0.405273 1.28516 0.963867 1.08203L2.05566 0.675781C2.38574 0.548828 2.71582 0.726562 2.84277 1.03125ZM11.4248 4.6875C10.9678 4.6875 10.6123 5.06836 10.6123 5.5C10.6123 5.95703 10.9678 6.3125 11.4248 6.3125C11.8564 6.3125 12.2373 5.95703 12.2373 5.5C12.2373 5.06836 11.8564 4.6875 11.4248 4.6875Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
