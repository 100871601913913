import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import { CreateRecipeRequest } from "@client";

export async function updateScheduleRecipe(
  programId: number,
  dayId: number,
  mealId: number,
  recipe: CreateRecipeRequest,
): Promise<ApiResponse<void>> {
  return await fetchData(
    `/dietitian/programs/${programId}/schedule/days/${dayId}/meals/${mealId}/recipe/creator`,
    APIMethods.PUT,
    recipe,
  );
}
