import {
  BlankTablet,
  CakeWithCandles,
  Clock,
  EmptyBookOpened,
} from "@assets/icons";
import { useAppTranslation } from "@hooks";
import { NOTIFICATION_EVENT_STATUS } from "@utils";
import { useMemo } from "react";

export const useNotificationVariantData = (
  type?: NOTIFICATION_EVENT_STATUS,
  endsIn?: number,
) => {
  const { t } = useAppTranslation();
  const eventVariantsDict = useMemo(
    () => ({
      [NOTIFICATION_EVENT_STATUS.PROGRAM_ENDS]: {
        icon: <EmptyBookOpened size="w5 h-5" />,
        event: endsIn
          ? endsIn > 0 && endsIn !== 0
            ? t("notifications.end_of_nutrition_program_in", { count: endsIn })
            : t("notifications.nutrition_program_ended")
          : t("notifications.end_of_nutrition_program_today"),
      },
      [NOTIFICATION_EVENT_STATUS.PATIENT_COOPERATION_ENDS]: {
        icon: <Clock size="w5 h-5" />,
        event: endsIn
          ? endsIn > 0 && endsIn !== 0
            ? t("notifications.end_of_cooperation_in", { count: endsIn })
            : t("notifications.cooperation_ended")
          : t("notifications.end_of_cooperation_today"),
      },
      [NOTIFICATION_EVENT_STATUS.SURVEY_REPORT]: {
        icon: <BlankTablet size="w5 h-5" />,
        event: t("notifications.received"),
      },
      [NOTIFICATION_EVENT_STATUS.PATIENT_BIRTHDAY]: {
        icon: <CakeWithCandles size="w5 h-5" />,
        event: t("notifications.birthday"),
      },
    }),
    [t, type],
  );

  if (!type || !eventVariantsDict[type])
    return { icon: undefined, event: undefined };
  return eventVariantsDict[type];
};
