import { useCallback, useMemo } from "react";

import { useNutrients } from "@hooks";
import { DietNutrientsNormDto } from "@client/diets/creator";

import { SelectedNutrient } from "./SelectedNutrient";
import { NutrientNormInputs } from "./useNutrientsNormForm";
import { MACROS_ARRAY } from "@utils/macros";

interface SelectedNutrientsListProps {
  mappedNormForm: NutrientNormInputs | undefined;
  norm: DietNutrientsNormDto | undefined;
}

export const SelectedNutrientsList = ({
  mappedNormForm,
  norm,
}: SelectedNutrientsListProps) => {
  const { sortOrder } = useNutrients();

  const getNutrientIdx = useCallback(
    (nutrientId: number) =>
      mappedNormForm?.nutrients.findIndex(n => n.id === nutrientId) ?? 0,
    [mappedNormForm],
  );

  const nutrientsToShow = useMemo(
    () =>
      norm?.nutrients
        .filter(({ nutrient }) => !MACROS_ARRAY.includes(nutrient.id))
        .filter(({ show }) => show)
        .sort((a, b) => {
          return (
            (sortOrder.get(a.nutrient.id) ?? 0) -
            (sortOrder.get(b.nutrient.id) ?? 0)
          );
        }) ?? [],
    [norm?.nutrients, sortOrder],
  );

  return (
    <>
      {nutrientsToShow.map((n, idx) => (
        <SelectedNutrient
          key={n.nutrient.id}
          nutrientId={n.nutrient.id}
          formIdx={getNutrientIdx(n.nutrient.id)}
          showLabel={idx === 0}
        />
      ))}
    </>
  );
};
