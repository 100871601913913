import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const DoubleArrows = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      style={{ fill: "none" }}
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        d="M15.81 13.5013L10.4988 18.8125L5.1875 13.5013M10.4988 3.9375V18.6637"
        stroke="#801FFF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.19 6.62375L10.5012 1.3125L15.8125 6.62375M10.5012 16.1875L10.5012 1.46125"
        stroke="#801FFF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
