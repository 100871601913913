import * as yup from "yup";

import { ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import {
  FoodRecipeDto,
  MediaDto,
  NewRecipeDto,
  NutrientDto,
  recipeSchema,
  TagDto,
} from "@client";

export async function fetchScheduleRecipe(
  programId: number,
  dayId: number,
  mealId: number,
): Promise<ApiResponse<NewRecipeDto>> {
  const data = await fetchData(
    `/dietitian/programs/${programId}/schedule/days/${dayId}/meals/${mealId}/recipe/creator`,
  );
  return await fetchScheduleRecipeSchema.validate(data);
}

export interface RecipeDto {
  id: number;
  name: string;
  nameEn?: string | null;
  description?: string | null;
  descriptionEn?: string | null;
  reviewed: boolean;
  isComplex: boolean;
  servings: number;
  tags: TagDto[];
  foodRecipe: FoodRecipeDto[];
  nutrients: NutrientDto[];
  version?: number;
  media: MediaDto | null;
  comment: string | null;
  commentEn: string | null;
}

const fetchScheduleRecipeSchema = yup.object().shape({
  data: recipeSchema.required(),
});
