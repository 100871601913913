import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAppTranslation } from "@hooks";
import { MutationOptions } from "../types";
import { fetchClinicFacilitiesQueryKey } from "./useFetchClinicFacilitiesQuery";
import { deleteClinicFacility } from "@client/settings";
import { toast } from "react-toastify";

export const useDeleteClinicFacility = (options?: MutationOptions) => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, number | string>(
    id => deleteClinicFacility(id),
    {
      ...options,
      onSuccess: () => {
        toast.success(t("company_settings.office_added"));
        queryClient.invalidateQueries([fetchClinicFacilitiesQueryKey]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};
