import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const BittenCookie = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="14" viewBox="0 0 14 14">
      <path
        d="M7.04051 0.81472C7.01884 0.668483 6.90781 0.549327 6.76157 0.527662C6.16308 0.443712 5.55376 0.552035 5.01756 0.836385L3.01357 1.90608C2.48008 2.19043 2.04949 2.63727 1.7841 3.18159L0.790232 5.22891C0.524839 5.77323 0.43818 6.39068 0.543795 6.98646L0.936468 9.22876C1.04208 9.82725 1.33185 10.3743 1.76785 10.7967L3.40083 12.3783C3.83683 12.8007 4.39199 13.0715 4.99048 13.1555L7.2382 13.4723C7.83668 13.5563 8.44601 13.448 8.98221 13.1636L10.9862 12.0939C11.5197 11.8096 11.9503 11.3627 12.2157 10.8157L13.2095 8.77109C13.4749 8.22677 13.5616 7.60932 13.456 7.01354C13.4316 6.87001 13.3124 6.76169 13.1689 6.74002C11.7742 6.51796 10.6558 5.46451 10.339 4.10234C10.2902 3.89653 10.1223 3.73946 9.91379 3.70696C8.43517 3.47136 7.26798 2.29875 7.0378 0.81472H7.04051ZM5.7 3.96694C5.92983 3.96694 6.15025 4.05824 6.31277 4.22076C6.47529 4.38327 6.56659 4.60369 6.56659 4.83353C6.56659 5.06336 6.47529 5.28378 6.31277 5.4463C6.15025 5.60881 5.92983 5.70012 5.7 5.70012C5.47017 5.70012 5.24975 5.60881 5.08723 5.4463C4.92471 5.28378 4.83341 5.06336 4.83341 4.83353C4.83341 4.60369 4.92471 4.38327 5.08723 4.22076C5.24975 4.05824 5.47017 3.96694 5.7 3.96694ZM3.96682 9.16647C3.96682 8.93664 4.05812 8.71622 4.22064 8.5537C4.38316 8.39119 4.60358 8.29988 4.83341 8.29988C5.06325 8.29988 5.28367 8.39119 5.44618 8.5537C5.6087 8.71622 5.7 8.93664 5.7 9.16647C5.7 9.39631 5.6087 9.61673 5.44618 9.77924C5.28367 9.94176 5.06325 10.0331 4.83341 10.0331C4.60358 10.0331 4.38316 9.94176 4.22064 9.77924C4.05812 9.61673 3.96682 9.39631 3.96682 9.16647ZM10.0329 7.43329C10.2628 7.43329 10.4832 7.5246 10.6457 7.68711C10.8082 7.84963 10.8995 8.07005 10.8995 8.29988C10.8995 8.52972 10.8082 8.75014 10.6457 8.91265C10.4832 9.07517 10.2628 9.16647 10.0329 9.16647C9.80311 9.16647 9.58269 9.07517 9.42018 8.91265C9.25766 8.75014 9.16636 8.52972 9.16636 8.29988C9.16636 8.07005 9.25766 7.84963 9.42018 7.68711C9.58269 7.5246 9.80311 7.43329 10.0329 7.43329Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
