import { Button, styled } from "@mui/material";

export const ColorPickerDropdownButton = styled(Button)`
  width: 24px;
  height: 24px;
  min-width: 24px;
  padding: 0;
`;

export const ColorPickerDropdownButtonCircle = styled("div")`
  width: 13px;
  height: 13px;
  border-radius: 100%;
`;
