import { EVENT_STATUS } from "@utils/scheduleEvent";
import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const patchScheduleEvent = async (
  id: number,
  payload: PatchScheduleEventRequest,
) => fetchData(`/dietitian/schedules/${id}/status`, APIMethods.PATCH, payload);

interface PatchScheduleEventRequest {
  status: EVENT_STATUS;
  notification?: boolean;
}
