import { useQuery } from "@tanstack/react-query";
import { fetchFood } from "@client";

export const useFetchFoodQueryKey = "useFetchFoodQueryKey";

export const useFetchFoodQuery = (id: number, enabled: boolean) => {
  const { data, ...rest } = useQuery(
    [useFetchFoodQueryKey, id],
    () => fetchFood(id),
    {
      onError: err => {
        console.log(err);
      },
      enabled,
    },
  );

  return { data, ...rest };
};
