import { TextField, styled } from "@mui/material";

export const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    padding-right: 0;

    & .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled {
      -webkit-text-fill-color: unset;
      color: ${({ theme }) => theme.colors.neutral.dark[800]};
    }
  }
`;

export const StyledSubgroupField = styled("div")`
  width: 100%;
  height: 2.5rem;
  padding: 0.5rem 0 0.5rem 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.neutral.light[200]};
`;

export const SubgroupTitle = styled("span")`
  flex: 1;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
`;
