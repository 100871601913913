import { Stack } from "@mui/material";
import { ArrowMonitoring } from "@assets/icons/Monitoring";
import {
  MainAccordion,
  MainAccordionSummary,
  MealName,
  MealTime,
} from "@views/dietician/PatientMonitoring2/components/MealsBox/Client/MealItem.styled";
import MealItemRecipe from "@views/dietician/PatientMonitoring2/components/MealsBox/Dietitian/MealItemRecipe";
import MealItemListItem from "@views/dietician/PatientMonitoring2/components/MealsBox/MealItemListItem";
import { DietitianMealsMonitoringResource } from "@client/resources/DietitianMealsMonitoringResource";

type MealItemProps = {
  meal: DietitianMealsMonitoringResource["meals"][0];
  onClickRecipe: (id: number) => void;
  expanded: boolean;
  toggleExpanded: () => void;
};
const MealItem = ({
  meal,
  onClickRecipe,
  expanded,
  toggleExpanded,
}: MealItemProps) => {
  const handleChange = () => {
    toggleExpanded();
  };

  return (
    <>
      <MainAccordion
        disableGutters={true}
        expanded={expanded}
        onChange={handleChange}
        style={{ boxShadow: "none" }}
      >
        <MainAccordionSummary>
          <Stack direction="column" flex="1" spacing="8px">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" alignItems="center" spacing="6px">
                <MealName>{meal.name}</MealName>
                <MealTime>{meal.time}</MealTime>
              </Stack>
              <Stack direction="row" alignItems="center" spacing="6px">
                <ArrowMonitoring
                  style={{
                    color: "#727272",
                    transform: expanded ? "rotate(90deg)" : "",
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
        </MainAccordionSummary>
      </MainAccordion>
      <ul>
        {meal.items.map(item => (
          <MealItemListItem key={item.id}>
            <MealItemRecipe
              item={item}
              onClickRecipe={onClickRecipe}
              expanded={expanded}
              handleChangeExpanded={handleChange}
            />
          </MealItemListItem>
        ))}
      </ul>
    </>
  );
};

export default MealItem;
