import { styled, Typography } from "@mui/material";

export const TypographyStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 0.75rem;
  font-weight: 500;
`;

export const AutosaveStatusWrapper = styled("div")`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  width: 4.75rem;
`;
