import { Card, styled } from "@mui/material";

export const CardStyled = styled(Card)`
  border-radius: 1rem;
  padding: 1.5rem 1.5rem 2rem 1.5rem;
  box-shadow: ${({ theme }) => theme.boxShadows.card};

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.mediumMax}px) {
    width: 42.875rem;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.laptop}px) {
    width: 48.875rem;
  }
`;

export const SmallCard = styled(Card)`
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: ${({ theme }) => theme.boxShadows.card};
`;

export const TitleStyled = styled("span")`
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const TextStyled = styled("span")`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
  white-space: pre-line;
`;

export const FixedButtonWrapper = styled("div")`
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0.5rem 0.5rem 2.375rem 0.5rem;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral.light[100]};
  box-shadow: ${({ theme }) => theme.boxShadows.mobileNavigation};
`;

export const TextWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

export const LayoutWithButton = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`;
