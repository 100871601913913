import { forwardRef, ReactElement } from "react";
import { ColorsListWrapper } from "./ColorPicker.styled";
import { ColorPickerButton } from "@components/ColorPicker/ColorPickerButton";

export interface ColorPickerProps {
  value?: string;
  onChange: (value: string) => void;
  outlined?: boolean;
  className?: string;
  colors?: Array<string>;
}

export const defaultColor = "#71CF84";

const defaultColors: string[] = [
  "#71CF84",
  "#8AB4F3",
  "#5474BE",
  "#BA98F5",
  "#FE85C9",
  "#FFAE53",
  "#D9AB11",
  "#44CEBB",
  "#50BEE1",
];

export const ColorPicker = forwardRef<HTMLDivElement, ColorPickerProps>(
  (
    { value, onChange, outlined = false, className, colors = defaultColors },
    ref,
  ): ReactElement => {
    return (
      <ColorsListWrapper ref={ref} outlined={outlined} className={className}>
        {colors.map(color => {
          return (
            <ColorPickerButton
              key={color}
              color={color}
              onChange={onChange}
              outlined={outlined}
              selected={value === color}
            />
          );
        })}
      </ColorsListWrapper>
    );
  },
);
