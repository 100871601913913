import { MouseEventHandler } from "react";

import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

import { StyledArrowButton } from "./StepsArrowButtons.styled";

interface StepsArrowButtonsProps {
  clickTop: MouseEventHandler<HTMLButtonElement> | undefined;
  clickDown: MouseEventHandler<HTMLButtonElement> | undefined;
}

export const StepsArrowButtons = ({
  clickTop,
  clickDown,
}: StepsArrowButtonsProps) => {
  return (
    <div className="grid">
      <StyledArrowButton size="small" onClick={clickTop}>
        <KeyboardArrowUp />
      </StyledArrowButton>
      <StyledArrowButton size="small" onClick={clickDown}>
        <KeyboardArrowDown />
      </StyledArrowButton>
    </div>
  );
};
