import { Button, Dialog, Stack } from "@mui/material";
import { Dispatch, useState } from "react";
import { useNavigate } from "react-router-dom";
import CardOption from "@views/dietician/PatientProgramsNew/AddPatientProgramDialog/CardOption";
import FileIcon from "@icons/file.svg?react";
import PlusIcon from "@icons/plus.svg?react";
import CloseIcon from "@icons/close.svg?react";
import {
  CustomDialogActions,
  CustomDialogContent,
  CustomDialogTitle,
} from "@views/dietician/PatientProgramsNew/AddPatientProgramDialog/AddPatientProgramDialog.styled";
import { IconButton } from "@components/Alloweat/IconButton";
import { useAppTranslation } from "@hooks";

type AddPatientProgramDialogProps = {
  patientId: number;
  open: boolean;
  setOpen: Dispatch<boolean>;
};

const AddPatientProgramDialog = ({
  patientId,
  open,
  setOpen,
}: AddPatientProgramDialogProps) => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const [selected, setSelected] = useState<number | null>(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    if (selected === 2) {
      navigate(`/patients/${patientId}/programs/choice`);
    }

    if (selected === 1) {
      navigate(`/patients/${patientId}/nutritional-programs/new`);
    }
  };
  const isSelected = (value: number) => selected === value;

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          padding="16px"
        >
          <CustomDialogTitle>
            {t("patient.programs.create_modal.title")}
          </CustomDialogTitle>
          <IconButton onClick={handleClose}>
            <CloseIcon color="#727272" />
          </IconButton>
        </Stack>
        <CustomDialogContent>
          <Stack direction="row" gap="16px">
            <CardOption
              icon={<PlusIcon />}
              title={t("patient.programs.create_modal.new")}
              selected={isSelected(1)}
              onClick={() => setSelected(1)}
            />
            <CardOption
              icon={<FileIcon />}
              title={t("patient.programs.create_modal.choice")}
              selected={isSelected(2)}
              onClick={() => setSelected(2)}
            />
          </Stack>
        </CustomDialogContent>
        <CustomDialogActions>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            {t("patient.programs.create_modal.button_cancel")}
          </Button>
          <Button
            disabled={selected === null}
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            autoFocus
          >
            {t("patient.programs.create_modal.button_next")}
          </Button>
        </CustomDialogActions>
      </Dialog>
    </>
  );
};

export default AddPatientProgramDialog;
