import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { logFetchValidateError } from "@utils/validate";
import { object } from "yup";
import {
  PatientProgramPreviewSearchResource,
  patientProgramSearchPreviewSchema,
} from "@client/resources/PatientProgramPreviewSearchResource";

export type FetchPatientProgramPreviewSearchParams = {
  patientId: number;
  programId: number;
};

export type FetchPatientProgramPreviewSearchResponse =
  ApiResponse<PatientProgramPreviewSearchResource>;

export const fetchPatientProgramSearchPreview = async (
  params: FetchPatientProgramPreviewSearchParams,
): Promise<FetchPatientProgramPreviewSearchResponse> => {
  const response = await fetchData(
    `/dietitian/patients/{patient_id}/programs-search-preview/{program_id}`
      .replace("{patient_id}", params.patientId.toString())
      .replace("{program_id}", params.programId.toString()),
    APIMethods.GET,
  );

  return await schema
    .validate(response)
    .catch(
      logFetchValidateError(
        "/dietitian/patients/{patient_id}/programs-search-preview/{program_id}",
      ),
    );
};

const schema = object({
  data: patientProgramSearchPreviewSchema.defined(),
});
