import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MutationOptions } from "../types";
import { fetchClinicProductsQueryKey } from "../useFetchClinicProducts";
import {
  UpdateClinicProductRequest,
  updateClinicProduct,
} from "@client/settings";

export const useUpdateClinicProductMutation = (
  id: number,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, UpdateClinicProductRequest>(
    payload => updateClinicProduct(id, payload),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries([fetchClinicProductsQueryKey]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};
