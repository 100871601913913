import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { fetchDiet } from "@client";
import { useAppTranslation, useModalState } from "@hooks";
import { useUpdateProgramDietMutation } from "@hooks/queries/useCreateProgramDietMutation";

import { DietTemplates, NewDiet } from "@assets/icons/Program";
import { Modal } from "@components/Modal/Modal";
import { Spinner } from "@components/Spinner";
import { TileButton } from "@components/TileButton";
import { usePostProgramDayDietMutation } from "@hooks/queries";
import {
  DietNameInputs,
  DietNameModal,
  mapDietNameRequest,
} from "@views/dietician/DietCreator/components/DietNameModal";

import { ThemeProviderWrapperNew } from "themeNew";
import { DietSelect } from "./DietSelect";

export const AddDietModal = (props: AddDietModalProps) => {
  const { dietDayId, onClose, programId } = props;

  const [showSearchInput, setShowSearchInput] = useState(false);
  const [openCreateDiet, onOpenCreateDiet, onCloseCreateDiet] = useModalState();
  const { t } = useAppTranslation();
  const navigate = useNavigate();

  const handleAddDietFromTemplates = useCallback(
    () => setShowSearchInput(true),
    [],
  );

  const { mutateAsync: mutateDietAsync } = useUpdateProgramDietMutation();
  const { mutate: createProgramDiet, isLoading: isCreatingProgramDiet } =
    usePostProgramDayDietMutation();
  const [isInProgress, setIsInProgress] = useState(false);

  const handleCreateDiet = async (values: DietNameInputs) => {
    if (!programId || !dietDayId) return;

    createProgramDiet(
      {
        programId: programId.toString(),
        dayId: dietDayId.toString(),
        payload: mapDietNameRequest(values),
      },
      {
        onSuccess: async ({ data }) => {
          navigate(`days/${dietDayId}/diet/${data.id}/edit`);
        },
      },
    );
  };

  const handleCreateNewDietFromTemplate = useCallback(
    async (id: number) => {
      setIsInProgress(true);
      try {
        const { data } = await fetchDiet(id);
        await mutateDietAsync({
          programId,
          dayId: dietDayId,
          payload: {
            ...data,
            id: null,
            version: null,
            nameEn: data.nameEn ?? null,
            tags: data.tags.map(t => t.id),
            meals: data.meals.map(m => ({
              ...m,
              nameEn: m.nameEn ?? m.name,
              id: null,
              hour: m.hour ?? null,
              description: m.description ?? null,
              descriptionEn: m.descriptionEn ?? null,
              food: m.food.map(f => ({
                grams: f.config.grams,
                foodId: f.id,
                foodMeasureId: f.config.foodMeasure.id,
              })),
              recipes: m.recipes.map(r => ({
                servings: r.servings,
                id: null,
                recipe: {
                  ...r.recipe,
                  media: r.recipe.media ?? null,
                  id: null,
                  tags: r.recipe.tags.map(tag => tag.id),
                  nameEn: r.recipe.nameEn ?? "",
                  description: r.recipe.description ?? "",
                  foodRecipe: r.recipe.foodRecipe.map((foodRecipe, index) => ({
                    id: null,
                    foodId: foodRecipe.food.id,
                    foodMeasureId: foodRecipe.foodMeasureId,
                    grams: foodRecipe.grams,
                    sortOrder: index,
                  })),
                },
              })),
              mealPatterns: [],
            })),
          },
        });
        props.onClose();
      } finally {
        setIsInProgress(false);
      }
    },
    [mutateDietAsync, props.onClose],
  );

  return (
    <>
      <Modal
        onClose={onClose}
        closeButton={false}
        submitButton={false}
        style={{ width: "70vw" }}
        title={t("program.diets.diet_day_card.create_diet")}
      >
        {isInProgress && <Spinner className="h-64" />}

        {!isInProgress && (
          <div className="mb-4 p-6">
            {!showSearchInput && (
              <>
                <p className="w-full text-center text-2xl mb-6">
                  {t("common.add")}:
                </p>
                <div className="w-full flex justify-center">
                  <TileButton
                    icon={
                      <DietTemplates
                        size="w-16 h-16"
                        className="stroke-current"
                      />
                    }
                    text={t("addNutritionalProgram.addDietModal.dietTemp")}
                    onClick={handleAddDietFromTemplates}
                    className="mr-5"
                  />
                  <TileButton
                    icon={
                      <NewDiet size="w-16 h-16" className="stroke-current" />
                    }
                    text={t("program.diets.add_diet_modal.new_diet")}
                    className="ml-5"
                    onClick={onOpenCreateDiet}
                  />
                </div>
              </>
            )}
            {showSearchInput && (
              <div className="h-[90vh]">
                <DietSelect onDietSelect={handleCreateNewDietFromTemplate} />
              </div>
            )}
          </div>
        )}
      </Modal>

      <ThemeProviderWrapperNew>
        <DietNameModal
          open={openCreateDiet}
          onClose={onCloseCreateDiet}
          title={t("diets.add_modal.title")}
          confirmText={t("diets.add_modal.submit")}
          onSubmit={handleCreateDiet}
          loading={isCreatingProgramDiet}
        />
      </ThemeProviderWrapperNew>
    </>
  );
};

interface AddDietModalProps {
  programId: number;
  patientId?: number;
  dietDayId: number;
  onClose: () => void;
}
