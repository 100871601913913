import styled from "@emotion/styled";

interface TableContentWrapperProps {
  colSpan: number;
  border?: boolean;
  bold?: boolean;
  header?: boolean;
  justifyStart?: boolean;
  justifyEnd?: boolean;
  borderTop?: boolean;
}

export const TableContentWrapper = styled.div<TableContentWrapperProps>`
  display: flex;
  height: 3.5rem;
  justify-content: ${({ justifyStart, justifyEnd }) =>
    justifyStart ? "start" : justifyEnd ? "end" : "center"};
  padding-left: ${({ justifyStart }) => justifyStart && "1.5rem"};
  align-items: center;
  font-weight: ${({ bold, header }) => (header || bold) && "bold"};
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.tableBorder};
  border-right-width: ${({ border }) => border && "1px"};
  border-top-width: ${({ borderTop }) => borderTop && "1px"};
  background-color: ${({ theme, header }) =>
    header && theme.colors.tableBackground};
  grid-column: ${({ colSpan }) => `span ${colSpan} / span ${colSpan}`};
`;
