import { DialogContent, IconButton, Typography, styled } from "@mui/material";

export const ProgramEmptyStateWrapper = styled("div")`
  display: grid;
  place-items: center;
  place-content: center;
  gap: 1.5rem;
  height: 100%;
`;

export const ContentWrapper = styled("div")`
  display: grid;
  place-items: center;
  place-content: center;
  gap: 1rem;
`;

export const FeatureTileStyled = styled("div")`
  display: grid;
  place-items: center;
  gap: 0.5rem;
  padding: 1rem 1.5rem;
`;

export const DotWrapper = styled("div")`
  display: flex;
  justify-content: center;
  min-width: 1.375rem;
`;

export const DialogContentStyled = styled(DialogContent)`
  max-width: 31.875rem;
`;

export const IconButtonStyled = styled(IconButton)`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
`;

export const ModalTitleWrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: 0.375rem;
`;

export const StyledQuestion = styled(Typography)`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;

export const TilesListWrapper = styled("div")`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`;

export const AnswerStyled = styled(Typography)`
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

export const QuestionWrapper = styled("div")`
  display: flex;
  gap: 0.75rem;
  align-items: center;
`;
