import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export async function requestHideSection(id: number) {
  await fetchData(
    `/dietitian/program-section-templates/${id}/hide`,
    APIMethods.PUT,
  );
}

export async function requestShowSection(id: number) {
  await fetchData(
    `/dietitian/program-section-templates/${id}/show`,
    APIMethods.PUT,
  );
}

export const deleteSection = async (sectionId: number) => {
  return await fetchData(
    `/dietitian/program-section-templates/${sectionId}`,
    APIMethods.DELETE,
  );
};
