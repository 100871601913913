import { Nutrient } from "@typeDefinitions";
import {
  ProductCellWrapper,
  TotalTitle,
} from "@components/RecipeCompositionSection/ProductCell.styled";
import { useAppTranslation } from "@hooks";
import { Typography } from "@mui/material";
import { MacrosList } from "@components/MacrosList";
import { MicronutrientPreview } from "@components/MicronutrientsModal";

interface RecipeSumRowProps {
  nutrients: Nutrient[];
}
export const RecipeSumRow = ({ nutrients }: RecipeSumRowProps) => {
  const { t } = useAppTranslation();

  return (
    <ProductCellWrapper>
      <TotalTitle noWrap>{t("diets.sum")}:</TotalTitle>
      <Typography noWrap className="pt-1" variant="subtitle2" />
      <div className="flex">
        <MacrosList nutrients={nutrients} regularFont compact />
        <MicronutrientPreview noPadding compact means={nutrients} />
      </div>
    </ProductCellWrapper>
  );
};
