import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const fetchOauthGoogleHandleCallback = async (
  params?: URLSearchParams,
) => {
  const response = await fetchData(
    "/dietitian/oauth/google/handle-callback",
    APIMethods.GET,
    undefined,
    params,
  );

  return response;
};
