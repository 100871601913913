import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import dayjs from "dayjs";
import * as yup from "yup";

import { LangDto } from "@client";
import {
  DietMealListItemCreatorDto,
  SaveDietMealCreatorRequest,
  TranslationExtendedDto,
} from "@client/diets/creator";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppTranslation } from "@hooks";
import { CARBS_ID, ENERGY_ID, FATS_ID, PROTEIN_ID } from "@utils/macros";
import { getTranslation } from "@utils/translations";

import { findNutrient } from "../../common";

export const useScheduleRowForm = (defaultValues?: ScheduleRowInputs) => {
  const { t } = useAppTranslation();
  const [init, setInit] = useState(true);
  const resolver = yup.object().shape({
    mealTypeId: yup.string().required(t("common.required_field")),
    hour: yup
      .date()
      .required(t("common.required_field"))
      .typeError(t("common.required_field")),
  });

  const { watch, reset, setValue, ...rest } = useForm<ScheduleRowInputs>({
    defaultValues,
    resolver: yupResolver(resolver),
  });

  useEffect(() => {
    if (!defaultValues) return;

    if (init) {
      reset(defaultValues);
      setInit(false);
      return;
    }

    if (defaultValues.mealTypeId !== watch("mealTypeId")) {
      setValue("mealTypeId", defaultValues.mealTypeId, { shouldDirty: true });
    }

    if (
      defaultValues.namePl !== watch("namePl") ||
      defaultValues.nameEn !== watch("nameEn")
    ) {
      setValue("namePl", defaultValues.namePl, { shouldDirty: true });
      setValue("nameEn", defaultValues.nameEn, { shouldDirty: true });
      return;
    }
  }, [defaultValues]);
  return { watch, reset, setValue, ...rest };
};

export interface ScheduleRowInputs {
  mealTypeId: string | null;
  hour: Date | null;
  kcal: string | null;
  protein: string | null;
  fat: string | null;
  carb: string | null;
  namePl: string | null;
  nameEn: string | null;
}

export const mapScheduleRowForm = (
  data?: DietMealListItemCreatorDto,
): ScheduleRowInputs | undefined => {
  if (!data) return;
  const { type, hour, targets, translations } = data;
  const namePl = getTranslation(translations, LangDto.PL);
  const nameEn = getTranslation(translations, LangDto.EN);

  return {
    mealTypeId: type?.id ? String(type.id) : null,
    hour: dayjs(hour, "HH:mm").toDate(),
    kcal: findNutrient(ENERGY_ID, targets).toString(),
    protein: findNutrient(PROTEIN_ID, targets).toString(),
    fat: findNutrient(FATS_ID, targets).toString(),
    carb: findNutrient(CARBS_ID, targets).toString(),
    namePl,
    nameEn,
  };
};

export const mapScheduleRequest = (
  values: ScheduleRowInputs,
  translationsToKeep: TranslationExtendedDto[] | undefined,
): SaveDietMealCreatorRequest => {
  const { hour, mealTypeId, kcal, protein, fat, carb, namePl, nameEn } = values;

  return {
    hour: dayjs(hour).format("HH:mm"),
    mealTypeId: Number(mealTypeId),
    targetKcal: Number(kcal),
    targetProtein: Number(protein),
    targetFat: Number(fat),
    targetCarb: Number(carb),
    translations: [
      {
        langId: LangDto.PL,
        name: namePl,
        description: getTranslation(
          translationsToKeep,
          LangDto.PL,
          "description",
        ),
      },
      {
        langId: LangDto.EN,
        name: nameEn,
        description: getTranslation(
          translationsToKeep,
          LangDto.EN,
          "description",
        ),
      },
    ],
  };
};

export const NAME_PL_PLACEHOLDER = "Wybierz typ posiłku";
export const NAME_EN_PLACEHOLDER = "Select meal type";
