import { fetchData } from "@utils/api";
import {
  BirthdayAnswerQuestionDto,
  CheckboxAnswerQuestionDto,
  CooperationGoalsAnswerQuestionDto,
  DateAnswerQuestionDto,
  DateTimeAnswerQuestionDto,
  FatTissueAnswerQuestionDto,
  FoodAnswerQuestionDto,
  HeightAnswerQuestionDto,
  MeasurementAnswerQuestionDto,
  PregnantWomanAnswerQuestionDto,
  RadioAnswerQuestionDto,
  SelectAnswerQuestionDto,
  SexAnswerQuestionDto,
  SwitchAnswerQuestionDto,
  TableAnswerQuestionDto,
  TagsAnswerQuestionDto,
  TextAnswerQuestionDto,
  TimeAnswerQuestionDto,
  UserTargetBodyWeightAnswerQuestionDto,
  WeightAnswerQuestionDto,
} from "./fetchSingedSurveys";
import { APIMethods } from "@typeDefinitions";

export const updateSingedSurveys = async (
  url: string,
  data: UpdateSingedSurveyRequest,
) => {
  await fetchData(
    url,
    APIMethods.PUT,
    data,
    undefined,
    undefined,
    undefined,
    undefined,
    "",
  );
};

export interface UpdateSingedSurveyRequest {
  questionId: number;
  answer:
    | TextAnswerQuestionDto
    | CheckboxAnswerQuestionDto
    | RadioAnswerQuestionDto
    | SwitchAnswerQuestionDto
    | DateAnswerQuestionDto
    | TimeAnswerQuestionDto
    | DateTimeAnswerQuestionDto
    | SelectAnswerQuestionDto
    | TableAnswerQuestionDto
    | WeightAnswerQuestionDto
    | HeightAnswerQuestionDto
    | FatTissueAnswerQuestionDto
    | MeasurementAnswerQuestionDto
    | BirthdayAnswerQuestionDto
    | CooperationGoalsAnswerQuestionDto
    | UserTargetBodyWeightAnswerQuestionDto
    | PregnantWomanAnswerQuestionDto
    | SexAnswerQuestionDto
    | FoodAnswerQuestionDto
    | TagsAnswerQuestionDto;
}
