import * as yup from "yup";

import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import { validateAndFixNutrients } from "./utils/validateCatching";

export const fetchSchedulesDictionaries =
  async (): Promise<SchedulesDictionariesDto> => {
    const response = await fetchData(
      `/dietitian/schedules/search/dictionaries`,
      APIMethods.GET,
    );
    return await validateAndFixNutrients(
      fetchSchedulesDictionariesResponseSchema,
      response,
    );
  };

const dietitiansSchema = yup.object().shape({
  id: yup.number().required(),
  active: yup.boolean().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
});

const facilitiesSchema = yup.object().shape({
  id: yup.number().required(),
  city: yup.string().required(),
});

const meetingTypesSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.object().shape({
    en: yup.string().required(),
    pl: yup.string().required(),
  }),
});

const fetchSchedulesDictionariesResponseSchema = yup.object().shape({
  dietitians: yup.array().of(dietitiansSchema).required(),
  facilities: yup.array().of(facilitiesSchema).required(),
  meetingTypes: yup.array().of(meetingTypesSchema).required(),
});

export interface SchedulesDictionariesDto {
  dietitians: DietitiansDto[];
  facilities: FacilitiesDto[];
  meetingTypes: MeetingTypesDto[];
}

export interface DietitiansDto {
  id: number;
  active: boolean;
  firstName: string;
  lastName: string;
}

interface FacilitiesDto {
  id: number;
  city: string;
}

interface MeetingTypesDto {
  id: number;
  name: {
    en: string;
    pl: string;
  };
}
