import { IdDto } from "@client/common";
import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const updateCreatorDietMealProduct = async (
  dietId: string,
  mealId: string,
  productId: string,
  payload: CreatorDietMealProductRequest,
  version: string,
) => {
  const response = await fetchData(
    `/dietitian/creator-2/diets/${dietId}/meals/${mealId}/products/${productId}`,
    APIMethods.PUT,
    payload,
    undefined,
    { "Alloweat-Creator-Version": version },
  );

  return response;
};

export interface CreatorDietMealProductRequest {
  food: IdDto;
  measure: IdDto;
  grams: number;
}
