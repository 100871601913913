import { useState } from "react";
import { BillingPeriodToggle } from "./components/BillingPeriodToggle";
import { ThemeProviderWrapperNew } from "themeNew";
import { VatToggle } from "./components/VatToggle";
import { StarterCard } from "./components/StarterCard";
import { EnterpriseCard } from "./components/EnterpriseCard";
import { ScaleCard } from "./components/ScaleCard";
import { BuyPackageModal } from "./components";
import { useModalState } from "@hooks";
import { StudentInfo } from "./components/StudentInfo";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";
import dayjs from "dayjs";

export const Pricing = () => {
  const { account } = useFetchDietitianAccountQuery();
  const [view, setView] = useState<ViewType>(ViewType.annually);
  const [showVat, setShowVat] = useState<boolean>(false);
  const [open, onOpen, onClose] = useModalState();
  const [selectedPackage, setSelectedPackage] = useState<"starter" | "scale">(
    "starter",
  );

  const handleOpen = (value: "starter" | "scale") => () => {
    setSelectedPackage(value);
    onOpen();
  };

  const noSubscription = account?.clinic?.subscriptionNew === null;
  const subscriptionExpriesSoon =
    dayjs(account?.clinic?.subscriptionNew?.finishedAt).diff(
      undefined,
      "days",
    ) <= 3;
  const allowToBuy = noSubscription || subscriptionExpriesSoon;

  return (
    <ThemeProviderWrapperNew>
      <div className="flex flex-col gap-[16px]">
        <BillingPeriodToggle value={view} setValue={setView} />

        <div className="flex gap-[24px]">
          <StarterCard
            priceVariant={view}
            showVat={showVat}
            onClickBuy={handleOpen("starter")}
            disabled={!allowToBuy}
          />
          <ScaleCard
            priceVariant={view}
            showVat={showVat}
            onClickBuy={handleOpen("scale")}
            disabled={!allowToBuy}
          />
          <EnterpriseCard />
        </div>

        <VatToggle value={showVat} setValue={setShowVat} />

        <StudentInfo />
      </div>

      <BuyPackageModal
        open={open}
        onClose={onClose}
        selectedPackage={selectedPackage}
        view={view}
        showVat={showVat}
      />
    </ThemeProviderWrapperNew>
  );
};
export enum ViewType {
  monthly,
  annually,
}
