import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "../types";
import { fetchPatientVisitsQueryKey } from "./useFetchPatientVisitsQuery";
import { duplicatePreviousVisitData } from "@client/visits";

export const useDuplicatePreviousVisitDataMutation = (
  patientId: number,
  scheduleId: number,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation(() => duplicatePreviousVisitData(scheduleId), {
    ...options,
    onSuccess() {
      queryClient.invalidateQueries([fetchPatientVisitsQueryKey, patientId]);
      options?.onSuccess && options.onSuccess();
    },
  });
};
