import { ReactNode } from "react";
import { FeatureTileStyled } from "./ProgramEmptyState.styled";
import { Button } from "@mui/material";
import { useAppTranslation } from "@hooks";
import { StyledSubTitle } from "../common.styled";

interface FeatureTileProps {
  icon: ReactNode;
  title: string;
  onClick: () => void;
}
export const FeatureTile = ({ icon, onClick, title }: FeatureTileProps) => {
  const { t } = useAppTranslation();
  return (
    <FeatureTileStyled>
      {icon}
      <StyledSubTitle minWidth="15.75rem">{title}</StyledSubTitle>

      <Button onClick={onClick}>{t("empty_states.find_out_more")}</Button>
    </FeatureTileStyled>
  );
};
