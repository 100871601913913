import { useMemo } from "react";
import { FormProvider } from "react-hook-form";

import {
  CheckboxAnswerResourceDto,
  QuestionCheckboxDto,
} from "@client/surveys";
import { useUpdateSingedSurveyMutation } from "@hooks/queries/surveys";

import { useUpdateValidationContext } from "../../context/useUpdateValidationContext";
import { useParseUrl } from "../../useParseUrl";
import { AutosaveOnBlurWatch } from "../AutosaveOnBlurWatch";
import { BlurContextProvider } from "../BlurContext";
import { Question } from "../Question/Question";
import {
  CheckboxAnswerFormInputs,
  mapCheckboxAnswerForm,
  mapCheckboxAnswerRequest,
  useCheckboxAnswerForm,
} from "../forms";
import { ControlledCheckboxContent } from "./ControlledCheckboxContent";

interface QuestionCheckboxProps {
  question: QuestionCheckboxDto;
  answer?: CheckboxAnswerResourceDto;
  listIndex: number;
  totalQuestions: number;
  readOnly?: boolean;
}
export const QuestionCheckbox = ({
  question: {
    title,
    description,
    config: { required, options, other },
    id,
  },
  answer,
  readOnly,
  ...rest
}: QuestionCheckboxProps) => {
  const { url } = useParseUrl();
  const mappedForm = useMemo(
    () => mapCheckboxAnswerForm(answer?.answer),
    [answer?.answer],
  );

  const { handleSubmit, formState, control, ...form } = useCheckboxAnswerForm(
    mappedForm,
    required,
  );
  const { mutate, isLoading } = useUpdateSingedSurveyMutation();

  const onSubmit = (data: CheckboxAnswerFormInputs) => {
    if (url) mutate({ payload: mapCheckboxAnswerRequest(data, id), url });
  };

  const { errors, touchedFields } = formState;
  const hasError = !!Object.values(errors).length;

  useUpdateValidationContext(id, touchedFields, handleSubmit);

  return (
    <FormProvider
      handleSubmit={handleSubmit}
      formState={formState}
      control={control}
      {...form}
    >
      <BlurContextProvider>
        <Question
          {...rest}
          title={title}
          description={description}
          required={required}
          hasError={hasError}
          readOnly={readOnly}
        >
          <ControlledCheckboxContent
            options={options}
            other={other}
            readOnly={readOnly}
          />
        </Question>

        <AutosaveOnBlurWatch onSubmit={onSubmit} isLoading={isLoading} />
      </BlurContextProvider>
    </FormProvider>
  );
};
