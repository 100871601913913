import { styled } from "@mui/material";

interface BadgeIf {
  success: boolean;
}

export const StyledDiv = styled("div")<BadgeIf>`
  border-radius: 0.75rem;
  background-color: ${({ success }) => (success ? "#E8F8EC" : "#FDEFF2")};
  padding: 2px 10px;
`;

export const StyledSpan = styled("span")<BadgeIf>`
  color: ${({ theme, success }) =>
    success ? "#46BB63" : theme.colors.alertTomato};
  font-weight: 600;
`;
