import { memo, ReactNode, useState } from "react";

import { Collapse } from "@mui/material";
import classNames from "classnames";

import { ArrowDown, DragHandle as DragHandleIcon } from "@assets/icons";
import { IconButton } from "@components/IconButton";

import { DRAG_HANDLE } from "../../../PatientVisitDetails/PatientVisitDetails";
import { DragHandle } from "./components/DragHandle.styled";
import { HeaderWrapper } from "./components/HeaderWrapper.styled";
import { CardContainerWrapper } from "./components/CardContainerWrapper.styled";
import { TitleBarWrapper } from "./components/TitleBarWrapper.styled";

export interface VisitSegmentCardProps {
  children: ReactNode;
  title: string;
  action?: ReactNode;
  draggable?: boolean;
  collapsable?: boolean;
  defaultVisibility?: boolean;
}

export const VisitSegmentCard = memo(
  ({
    children,
    draggable,
    collapsable,
    title,
    action,
    defaultVisibility = false,
  }: VisitSegmentCardProps) => {
    const [contentVisible, showContent] = useState(
      !collapsable || defaultVisibility,
    );

    const switchVisibility = () => {
      showContent(!contentVisible);
    };

    return (
      <CardContainerWrapper draggable={draggable}>
        <TitleBarWrapper onClick={switchVisibility}>
          <HeaderWrapper>
            {draggable && (
              <DragHandle className={DRAG_HANDLE}>
                <DragHandleIcon />
              </DragHandle>
            )}
            {title}
          </HeaderWrapper>
          {action}
          {collapsable && (
            <IconButton size="large" style={{ fontSize: "22px" }}>
              <ArrowDown
                className={classNames(
                  "transform transition duration-100 ease-in ",
                  contentVisible && "rotate-180",
                )}
              />
            </IconButton>
          )}
        </TitleBarWrapper>
        <Collapse in={contentVisible} mountOnEnter unmountOnExit>
          <div className="px-7 py-5">{children}</div>
        </Collapse>
      </CardContainerWrapper>
    );
  },
);
