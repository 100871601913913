import { Stack } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useMemo, useState } from "react";
import CalendarDays from "@views/dietician/PatientMonitoring2/components/CalendarDays/CalendarDays";
import {
  CalendarDayTitle,
  CalendarDayWrapper,
} from "@views/dietician/PatientMonitoring2/components/CalendarDays/CalendarDays.styled";
import { CalendarDaysOfProps } from "@views/dietician/PatientMonitoring2/components/CalendarDays/CalendarDayOfType";
import CalendarDayItem from "@views/dietician/PatientMonitoring2/components/CalendarDays/CalendarDayItem";
import { useFetchMonitoringCalendarDays } from "@hooks/queries/client/monitoring";
import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import { CalendarDayMonitoringResource } from "@client/resources/CalendarDayMonitoringResource";

const CalendarDaysOfWeek = (props: CalendarDaysOfProps) => {
  const { patientId } = useMonitoring();

  const {
    type: { setValue: setType },
    selectedDays: { value: selectDays, setValue: setSelectDays },
  } = props;

  const [periodDays, setPeriodDays] = useState<{
    startDate: Dayjs;
    endDate: Dayjs;
  }>(() => initDays());

  const { data: calendarDaysData } = useFetchMonitoringCalendarDays({
    patientId,
    dateFrom: periodDays.startDate.format("YYYY-MM-DD"),
    dateTo: periodDays.endDate.format("YYYY-MM-DD"),
  });

  const handleOnClickWeek = (item: Dayjs) => {
    if (!(item.isSame(selectDays.from) && item.isSame(selectDays.to))) {
      setSelectDays({
        from: item.clone().startOf("week"),
        to: item.clone().endOf("week"),
      });
    }
  };

  const handleOnClickDay = (day: Dayjs) => {
    setType("day");
    setSelectDays({ from: day, to: day });
  };

  const addWeeks = (weeks: number) => {
    setPeriodDays(rest => ({
      ...rest,
      startDate: rest.startDate.clone().add(weeks, "week"),
      endDate: rest.endDate.clone().add(weeks, "week"),
    }));
  };

  const renderDaysOfWeek = (dayOfWeek: Dayjs, selected: boolean) => {
    const days = [];
    const endDayOfWeek = dayOfWeek.clone().add(1, "week");

    for (
      let day = dayjs(dayOfWeek);
      day.isBefore(endDayOfWeek);
      day = day.add(1, "day")
    ) {
      const apiDay: CalendarDayMonitoringResource | null =
        calendarDaysData?.data.find(d => d.date === day.format("YYYY-MM-DD")) ||
        null;

      days.push(
        <CalendarDayWrapper
          key={day.format("DD.MM.YYYY")}
          onClick={() => handleOnClickDay(day)}
          selected={selected}
        >
          <CalendarDayItem
            text={day.format("DD")}
            progress={apiDay?.progress ?? 0}
            selected={selected}
            notification={Boolean(apiDay?.notification)}
            today={day.isToday()}
          />
        </CalendarDayWrapper>,
      );
    }

    return days;
  };

  const renderWeeks = () => {
    const weeks = [];
    for (
      let firstDayOfWeek = dayjs(periodDays.startDate);
      firstDayOfWeek.isBefore(periodDays.endDate);
      firstDayOfWeek = firstDayOfWeek.add(1, "week")
    ) {
      const selected = selectDays.from.isSame(firstDayOfWeek);
      weeks.push(
        <Stack
          key={firstDayOfWeek.format("DD.MM.YYYY")}
          direction="column"
          flex={1}
        >
          <CalendarDayTitle
            style={{ background: selected ? "#F2F2FF" : "#FFF" }}
            onClick={() => handleOnClickWeek(firstDayOfWeek)}
          >
            {firstDayOfWeek.format("DD.MM")} -{" "}
            {firstDayOfWeek.clone().add(6, "days").format("DD.MM")}
          </CalendarDayTitle>
          <Stack direction="row">
            {renderDaysOfWeek(firstDayOfWeek, selected)}
          </Stack>
        </Stack>,
      );
    }
    return weeks;
  };

  const daysComponents = useMemo(
    () => renderWeeks(),
    [selectDays, calendarDaysData?.data, periodDays],
  );

  return (
    <CalendarDays
      addNextDays={() => addWeeks(1)}
      addPrevDays={() => addWeeks(-1)}
    >
      {daysComponents}
    </CalendarDays>
  );
};

const initDays = () => {
  const startOfCurrentWeek = dayjs().startOf("week");

  const startDate = startOfCurrentWeek.clone().add(-2, "week");
  const endDate = startOfCurrentWeek.clone().endOf("week").add(1, "week");

  return {
    startDate: startDate,
    endDate: endDate,
  };
};

export default CalendarDaysOfWeek;
