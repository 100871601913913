import { patientKeys } from "@hooks/queries/client/patientKeys";

export const patientBodyMeasurementKeys = {
  base: (patientId: number) => patientKeys.bodyMeasurement(patientId),
  settings: (id: number) => [
    ...patientBodyMeasurementKeys.base(id),
    "settings",
  ],
  list: (id: number) => [...patientBodyMeasurementKeys.base(id), "list"],
  id: (id: number, bodyMeasurementId: number) => [
    ...patientBodyMeasurementKeys.base(id),
    bodyMeasurementId,
  ],
  last: (id: number) => [...patientBodyMeasurementKeys.base(id), "last"],
  lastImages: (id: number) => [
    ...patientBodyMeasurementKeys.base(id),
    "last_images",
  ],
};
