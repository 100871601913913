import React from "react";
import { useTags } from "@hooks";
import { ListTag } from "@components/ListTag";
import { Tag } from "@components/Tag";

export const ProductTags = ({ tags, className }: ProductTagsProps) => {
  const { tags: tagsObj } = useTags();

  if (!tags) {
    return null;
  }

  return (
    <ListTag className={className}>
      {tags.map(tag => (
        <Tag key={tag.id} title={tagsObj[tag.id]?.name ?? tag.id.toString()} />
      ))}
    </ListTag>
  );
};

export interface ProductTagsProps {
  className?: string;
  tags: TagId[];
}

interface TagId {
  id: number;
}
