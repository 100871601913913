import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import MealsBoxByDay from "@views/dietician/PatientMonitoring2/components/MealsBox/MealsBoxByDay";
import MealsBoxByDays from "@views/dietician/PatientMonitoring2/components/MealsBox/MealsBoxByDays";
import { BoxWrapper } from "@views/dietician/PatientMonitoring2/components/BoxWrapper";

const MealsBoxByType = () => {
  const { type } = useMonitoring();

  return (
    <BoxWrapper id="monotoring_meals_box">
      {type.type === "day" ? <MealsBoxByDay /> : <MealsBoxByDays />}
    </BoxWrapper>
  );
};

export default MealsBoxByType;
