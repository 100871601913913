import { FullSurveyDto, TextAnswerResourceDto } from "@client/surveys";
import { useContentSelector } from "@context/TableOfContentsContext";

import { QuestionFactory } from "../QuestionnaireAnswer/Questions/QuestionFactory";

interface SubgroupContentProps {
  survey?: FullSurveyDto;
}

export const SubgroupContent = ({ survey }: SubgroupContentProps) => {
  const { groupId, subgroupId } = useContentSelector();

  const subgroupData = survey?.groups
    .find(g => g.id === groupId)
    ?.sections.find(s => s.id === subgroupId);
  const answers: TextAnswerResourceDto[] = [];

  const getAnswer = (id: number) => answers?.find(a => a.question.id === id);

  if (!subgroupData) return <></>;

  return (
    <>
      {subgroupData.questions.map((q, idx) => (
        <QuestionFactory
          key={q.id}
          listIndex={idx}
          totalQuestions={subgroupData.questions.length}
          question={q}
          answer={getAnswer(q.id)}
          readOnly
        />
      ))}
    </>
  );
};
