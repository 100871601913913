import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export interface UpdateProgramSectionPayload {
  title: string;
  titleEn: string;
  content: string;
  contentEn: string;
}

export const updateProgramSection = async (
  programId: number,
  sectionId: number,
  payload: UpdateProgramSectionPayload,
) =>
  await fetchData(
    `/dietitian/programs/${programId}/sections/${sectionId}`,
    APIMethods.PUT,
    payload,
  );
