import { Card } from "@mui/material";
import { ReactNode } from "react";
import { CardStyled, Title } from "./CardWrapper.styled";

interface CardWrapperProps {
  title: string;
  extras?: ReactNode;
  children: ReactNode;
}
export const CardWrapper = ({ title, extras, children }: CardWrapperProps) => {
  return (
    <CardStyled>
      <div className="flex justify-between">
        <Title>{title}</Title>

        {extras}
      </div>

      {children}
    </CardStyled>
  );
};
