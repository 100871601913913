import { FormProvider } from "react-hook-form";

import { TextField } from "@mui/material";

import { QuestionType, SystemQuestionDto } from "@client/surveys";
import { useUpdateSurveySubgroupQuestionMutation } from "@hooks/queries/surveys";

import { Question } from "../Question/Question";
import { QuestionEditWatch } from "../QuestionEditWatch";
import {
  SystemQuestionConfigFormInputs,
  mapSystemQuestionConfigForm,
  mapSystemQuestionConfigRequest,
  useSystemQuestionConfigForm,
} from "../forms/useSystemQuestionConfigForm";
import { useAppTranslation } from "@hooks";
import { OuterLabel } from "@components/OuterLabel";
import { LangDto } from "@client";

interface SystemQuestionMeasurementsProps {
  listIndex: number;
  totalQuestions: number;
  question: SystemQuestionDto;
  onTypeChange: (currentType: QuestionType, newType: QuestionType) => void;
  questionnaireLang?: LangDto;
}

export const SystemQuestionMeasurements = ({
  listIndex,
  totalQuestions,
  question,
  onTypeChange,
  questionnaireLang,
}: SystemQuestionMeasurementsProps) => {
  const { t } = useAppTranslation();
  const form = useSystemQuestionConfigForm(
    mapSystemQuestionConfigForm({
      ...question,
      title: t("questionnaires.questions.system_measurements.question", {
        lng: questionnaireLang,
      }),
    }),
  );

  const { mutate } = useUpdateSurveySubgroupQuestionMutation();

  const onSubmit = (data: SystemQuestionConfigFormInputs) => {
    mutate({
      id: question.id.toString(),
      payload: mapSystemQuestionConfigRequest(data, question),
    });
  };

  return (
    <FormProvider {...form}>
      <Question
        id={question.id}
        listIndex={listIndex}
        totalQuestions={totalQuestions}
        onTypeChange={onTypeChange}
        questionType={question.type}
        systemQuestion
        infoText={t("questionnaires.questions.system_measurements.info")}
      >
        {["arm", "biceps", "waist", "hips", "thigh", "calf"].map(measure => (
          <OuterLabel
            key={measure}
            label={t(`questionnaires.questions.system_measurements.${measure}`)}
          >
            <TextField
              size="small"
              disabled
              placeholder={t(
                "questionnaires.questions.system_measurements.placeholder",
              )}
              fullWidth
            />
          </OuterLabel>
        ))}
      </Question>

      <QuestionEditWatch isLoading={false} onSubmit={onSubmit} />
    </FormProvider>
  );
};
