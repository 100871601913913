import { Box, styled } from "@mui/material";

export const SummaryCardWrapper = styled(Box)`
  background: ${({ theme }) => theme.colors.neutral.light[100]};
  padding: 16px;
  border-radius: 16px;
` as typeof Box;

export const ActualValue = styled("span")`
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
  line-height: 20px;
  font-size: 20px;
  font-family: Figtree, serif;
  font-style: normal;
  font-weight: 600;
`;

export const TargetValue = styled("span")`
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
  line-height: 20px;
  font-size: 16px;
  font-family: Figtree, serif;
  font-style: normal;
  font-weight: 500;
`;

export const Title = styled("span")`
  color: #2f303a;
  line-height: 20px;
  font-size: 14px;
  font-family: Figtree, serif;
  font-style: normal;
  font-weight: 500;
  text-wrap: nowrap;
`;

export const Percent = styled("span")`
  color: #2f303a;
  line-height: 20px;
  font-size: 14px;
  font-family: Figtree, serif;
  font-style: normal;
  font-weight: 600;
`;

export const PercentInCircle = styled(Box)`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
` as typeof Box;
