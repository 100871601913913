import { useContentSelector } from "@context/TableOfContentsContext";

import { GroupCard, IntroductionCard } from "../components/Cards";
import { SubgroupCard } from "../components/Cards/SubgroupCard";

export const ContentSelector = () => {
  const {
    selectedId,
    groupId,
    subgroupId,
    isIntroduction,
    isGroup,
    isSubgroup,
  } = useContentSelector();

  if (isIntroduction) return <IntroductionCard />;

  if (isSubgroup && subgroupId)
    return (
      <div className="flex gap-4">
        <SubgroupCard key={selectedId} id={subgroupId} groupId={groupId} />
      </div>
    );

  if (isGroup && groupId) return <GroupCard key={selectedId} id={groupId} />;

  return <></>;
};
