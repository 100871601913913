import { fetchData } from "@utils/api";
import { SaveDietMealCreatorRequest } from "./updateCreatorDietMeal";
import { APIMethods } from "@typeDefinitions";

export const postCreatorDietMeal = async (
  dietId: string,
  payload: SaveDietMealCreatorRequest,
  version: string,
) => {
  return await fetchData(
    `/dietitian/creator-2/diets/${dietId}/meals`,
    APIMethods.POST,
    payload,
    undefined,
    { "Alloweat-Creator-Version": version },
  );
};
