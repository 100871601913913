import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export async function updateProgramDayDiet(
  programId: string,
  dayId: string,
  dietId: string,
): Promise<Response> {
  return await fetchData(
    `/dietitian/programs/${programId}/days/${dayId}/diet/${dietId}`,
    APIMethods.PUT,
  );
}
