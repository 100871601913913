import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const MediumThumb = (props: SvgIconProps) => {
  return (
    <SvgIcon width="21" height="20" viewBox="0 0 21 20" {...props}>
      <rect x="1" width="20" height="20" rx="10" fill="#7448D0" />
      <circle
        cx="10.564"
        cy="10"
        r="9"
        fill="white"
        stroke="#7448D0"
        strokeWidth="2"
      />
      <ellipse
        cx="10.7407"
        cy="9.99999"
        rx="5.45454"
        ry="5.45455"
        fill="#7448D0"
      />
    </SvgIcon>
  );
};
