import { Stack } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useFetchMonitoringCalendarDays } from "@hooks/queries/client/monitoring";
import { capitalizeFirstLetter } from "@views/dietician/PatientMonitoring2/components/CalendarDays/CalendarDaysUtils";
import {
  CalendarDayTitle,
  CalendarDayWrapper,
} from "@views/dietician/PatientMonitoring2/components/CalendarDays/CalendarDays.styled";
import CalendarDays from "@views/dietician/PatientMonitoring2/components/CalendarDays/CalendarDays";
import { CalendarDaysOfProps } from "@views/dietician/PatientMonitoring2/components/CalendarDays/CalendarDayOfType";
import CalendarDayItem from "@views/dietician/PatientMonitoring2/components/CalendarDays/CalendarDayItem";
import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import { useStoreReadMonitoringCalendarDayMutation } from "@hooks/queries/client/monitoring/useStoreReadMonitoringCalendarDayMutation";
import { CalendarDayMonitoringResource } from "@client/resources/CalendarDayMonitoringResource";

const CalendarDaysOfDay = (props: CalendarDaysOfProps) => {
  const {
    type: { setValue: setType },
    selectedDays: { value: selectDays, setValue: setSelectDays },
  } = props;

  const { patientId } = useMonitoring();

  const [periodDays, setPeriodDays] = useState<{
    startDate: Dayjs;
    endDate: Dayjs;
  }>(() => {
    return initDays();
  });

  const { data: calendarDaysData } = useFetchMonitoringCalendarDays({
    patientId,
    dateFrom: periodDays.startDate.format("YYYY-MM-DD"),
    dateTo: periodDays.endDate.format("YYYY-MM-DD"),
  });

  const storeReadMutate = useStoreReadMonitoringCalendarDayMutation({
    dateFrom: periodDays.startDate.format("YYYY-MM-DD"),
    dateTo: periodDays.endDate.format("YYYY-MM-DD"),
  });

  useEffect(() => {
    if (
      !selectDays.from
        .clone()
        .startOf("day")
        .isBetween(periodDays.startDate, periodDays.endDate, "day", "[]")
    ) {
      setPeriodDays(initDays);
    }
  }, [selectDays]);

  const handleOnClickMonth = (item: Dayjs) => {
    setType("month");
    setSelectDays({
      from: item.clone().startOf("month"),
      to: item.clone().endOf("month"),
    });
  };

  const handleOnClickDay = (
    day: Dayjs,
    apiDay: CalendarDayMonitoringResource | null,
  ) => {
    setSelectDays({ from: day, to: day });
    if (!apiDay || !apiDay.notification) {
      return;
    }
    storeReadMutate.mutate({ patientId: patientId, dayId: apiDay.id });
  };

  const addDays = (days: number) => {
    setPeriodDays(rest => ({
      ...rest,
      startDate: rest.startDate.clone().add(days, "day"),
      endDate: rest.endDate.clone().add(days, "day"),
    }));
  };

  const renderDaysOfMonth = (dayOfMonth: Dayjs) => {
    const days = [];
    const endDayOfMonth = dayOfMonth.clone().endOf("month");

    for (
      let day = dayjs(dayOfMonth);
      day.isBefore(endDayOfMonth);
      day = day.add(1, "day")
    ) {
      if (
        day.isBefore(periodDays.startDate) ||
        day.isAfter(periodDays.endDate)
      ) {
        continue;
      }
      const apiDay: CalendarDayMonitoringResource | null =
        calendarDaysData?.data.find(d => d.date === day.format("YYYY-MM-DD")) ||
        null;

      days.push(
        <CalendarDayWrapper
          key={day.format("DD.MM.YYYY")}
          onClick={() => handleOnClickDay(day.clone(), apiDay)}
          selected={day.isSame(selectDays.from)}
        >
          <CalendarDayItem
            text={day.format("DD")}
            progress={apiDay?.progress ?? 0}
            selected={selectDays.from.isSame(day)}
            notification={Boolean(apiDay?.notification)}
            today={day.isToday()}
          />
        </CalendarDayWrapper>,
      );
    }

    return days;
  };

  const renderMonths = () => {
    const months = [];
    for (
      let day = dayjs(periodDays.startDate).startOf("month");
      day.format("YYYY-MM") <= periodDays.endDate.format("YYYY-MM");
      day = day.add(1, "month")
    ) {
      let month = 0;
      for (
        let day2 = dayjs(day);
        day2.format("YYYY-MM-DD") <=
        dayjs(day).endOf("month").format("YYYY-MM-DD");
        day2 = day2.add(1, "day")
      ) {
        if (
          dateIsBetweenDates(day2, periodDays.startDate, periodDays.endDate)
        ) {
          month = month + 1;
        }
      }
      months.push(
        <Stack key={day.format("DD.MM.YYYY")} direction="column" flex={month}>
          <CalendarDayTitle
            onClick={() => {
              handleOnClickMonth(day.clone());
            }}
          >
            {capitalizeFirstLetter(day.format("MMMM"))}
          </CalendarDayTitle>
          <Stack direction="row">{renderDaysOfMonth(day)}</Stack>
        </Stack>,
      );
    }

    return months;
  };

  const daysComponents = useMemo(
    () => renderMonths(),
    [selectDays, calendarDaysData?.data, periodDays],
  );

  return (
    <CalendarDays
      addNextDays={() => addDays(7)}
      addPrevDays={() => addDays(-7)}
    >
      {daysComponents}
    </CalendarDays>
  );
};

const initDays = () => {
  const today = dayjs().startOf("day");
  const startDate = today.clone().add(-20, "days");
  const endDate = today.clone().add(7, "days");

  return {
    startDate: startDate,
    endDate: endDate,
  };
};

const dateIsBetweenDates = (day: Dayjs, from: Dayjs, to: Dayjs): boolean => {
  return (
    day.format("YYYY-MM-DD") >= from.format("YYYY-MM-DD") &&
    day.format("YYYY-MM-DD") <= to.format("YYYY-MM-DD")
  );
};

export default CalendarDaysOfDay;
