import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const ExclamationMark = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 26 26">
    <g
      id="Group_5203"
      data-name="Group 5203"
      transform="translate(-3861.064 -1103.877)"
      fill="currentColor"
    >
      <path
        id="Path_1685"
        data-name="Path 1685"
        d="M3865.473,1127.561a4.4,4.4,0,0,1-3.812-6.6l8.592-14.881a4.4,4.4,0,0,1,7.623,0l8.592,14.881a4.4,4.4,0,0,1-3.812,6.6Zm6.165-20.683-8.592,14.881a2.8,2.8,0,0,0,2.427,4.2h17.183a2.8,2.8,0,0,0,2.427-4.2l-8.591-14.881a2.8,2.8,0,0,0-4.854,0l-.423-.244Z"
        fill="currentColor"
      />
      <path
        id="Stroke_3"
        data-name="Stroke 3"
        d="M.5,0V4.92"
        transform="translate(3873.564 1112.824)"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <path
        id="Stroke_15"
        data-name="Stroke 15"
        d="M.5.5H.507"
        transform="translate(3873.564 1121.665)"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
    </g>
  </SvgIcon>
);
