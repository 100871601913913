import { fetchData } from "@utils/api";

import { APIMethods } from "@typeDefinitions";
import { SaveDietMealCreatorRequest } from "@client/diets/creator";

export const postProgramDietMeal = async (
  programId: string,
  dietId: string,
  payload: SaveDietMealCreatorRequest,
  version: string,
) => {
  return await fetchData(
    `/dietitian/program/${programId}/diet-creator/${dietId}/meals`,
    APIMethods.POST,
    payload,
    undefined,
    { "Alloweat-Creator-Version": version },
  );
};
