import { Box, styled } from "@mui/material";
import { CircleDownRegularIcon } from "@icons/index";
import { NutrientTargetValueType } from "@components/Nutrients2/Types";

type Props = {
  nutrient: NutrientTargetValueType;
};
const NutrientTargetValue = ({ nutrient }: Props) => {
  return (
    <BoxWrapper>
      {nutrient.value < nutrient.target && (
        <CircleDownRegularIcon fontSize="24px" color={"#BF8000"} />
      )}
      <Value>{nutrient.value.toFixed()}</Value>
      {!!nutrient.target && (
        <TargetValue>
          / {nutrient.target.toFixed()} {nutrient.units}
        </TargetValue>
      )}
      {!nutrient.target && <TargetValue>{nutrient.units}</TargetValue>}
    </BoxWrapper>
  );
};

const BoxWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  color: #4d516e;
  font-size: 12px;
  line-height: 20px;
` as typeof Box;

const Value = styled("span")`
  font: var(--font-figtree-semi-bold);
  color: #333333;
`;

const TargetValue = styled("span")`
  font: var(--font-figtree-regular);
`;

export default NutrientTargetValue;
