import { Dispatch, ReactNode, SetStateAction } from "react";
import {
  ModalWrapper,
  StyledDialogActions,
  StyledPopover,
  Title,
  TitleWrapper,
} from "./ConfirmPopper.styled";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAppTranslation } from "@hooks";

interface ConfirmationPopoverProps {
  onConfirm: () => void;
  anchor?: HTMLElement;
  setAnchor: Dispatch<SetStateAction<(EventTarget & HTMLElement) | undefined>>;
  confirmationText: string;
  submitText?: string;
  isLoading?: boolean;
  extraContent?: ReactNode;
}

export const ConfirmPopover = ({
  onConfirm,
  anchor,
  setAnchor,
  confirmationText,
  submitText,
  isLoading,
  extraContent,
}: ConfirmationPopoverProps) => {
  const { t } = useAppTranslation();

  const handleSubmit = () => {
    setAnchor(undefined);
    onConfirm();
  };
  return (
    <StyledPopover
      open={!!anchor}
      anchorEl={anchor}
      onClose={() => setAnchor(undefined)}
    >
      <ModalWrapper>
        <TitleWrapper>
          <Title>{confirmationText}</Title>
        </TitleWrapper>
        {extraContent}
      </ModalWrapper>
      <StyledDialogActions>
        <Button variant="outlined" onClick={() => setAnchor(undefined)}>
          {t("common.cancel")}
        </Button>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          onClick={handleSubmit}
        >
          {submitText ?? t("common.save")}
        </LoadingButton>
      </StyledDialogActions>
    </StyledPopover>
  );
};
