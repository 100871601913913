import { SvgIcon, SvgIconProps } from "@mui/material";

export const PenInSquare = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M11.0997 5.26077H5.15777C4.70751 5.26077 4.2757 5.43964 3.95732 5.75801C3.63895 6.07639 3.46008 6.5082 3.46008 6.95846V18.8423C3.46008 19.2925 3.63895 19.7243 3.95732 20.0427C4.2757 20.3611 4.70751 20.5399 5.15777 20.5399H17.0416C17.4918 20.5399 17.9236 20.3611 18.242 20.0427C18.5604 19.7243 18.7393 19.2925 18.7393 18.8423V12.9004"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M17.4661 3.98747C17.8038 3.64978 18.2618 3.46007 18.7393 3.46007C19.2169 3.46007 19.6749 3.64978 20.0126 3.98747C20.3503 4.32516 20.54 4.78317 20.54 5.26074C20.54 5.7383 20.3503 6.19631 20.0126 6.534L11.9486 14.598L8.55322 15.4469L9.40207 12.0515L17.4661 3.98747Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
