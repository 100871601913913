import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const updateSurveySubgroup = async (
  subgroupId: string,
  payload: UpdateSurveySubgroupRequest,
) => {
  await fetchData(
    `/dietitian/survey-sections/${subgroupId}`,
    APIMethods.PUT,
    payload,
  );
};

export interface UpdateSurveySubgroupRequest {
  title: string;
  description: string | null;
}
