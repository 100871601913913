import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import { SurveyDto, fetchSurveysSchema } from "./fetchSurveys";

export const fetchSurveysAlloweat = async (): Promise<
  ApiResponse<SurveyDto[]>
> => {
  const response = await fetchData(
    "/dietitian/surveys-alloweat",
    APIMethods.GET,
  );

  return await fetchSurveysSchema.validate(response);
};
