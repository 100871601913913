import { useQuery } from "@tanstack/react-query";

import { useAppTranslation } from "@hooks";
import { buildDefaultQueryOptions } from "../common";
import {
  DayVariantDrawRequestBody,
  fetchSampleScheduleDayVariants,
} from "@client/schedule";

export const fetchSampleScheduleDayVariantsKey = "requestScheduleDayVariants";

export const useFetchSampleScheduleDayVariantsQuery = (
  programId: number,
  dayId: number,
  settings: DayVariantDrawRequestBody,
) => {
  const { t } = useAppTranslation();
  const { data, ...rest } = useQuery(
    [fetchSampleScheduleDayVariantsKey, programId, dayId, settings],
    () => fetchSampleScheduleDayVariants(programId, dayId, settings),
    buildDefaultQueryOptions({
      refetchOnWindowFocus: false,
      errorToastMessage: t("patient.schedule.variants.error"),
    }),
  );

  return { sampleVariants: data?.data, ...rest };
};
