import { ReactNode } from "react";

import { Chip } from "@mui/material";

import { OuterLabel } from "@components/OuterLabel";

import {
  TextStyled,
  CardTitle,
  QuestionPreviewCard,
} from "./QuestionPreview.styled";
import { useAppTranslation } from "@hooks";

export interface QuestionPreviewProps {
  children: ReactNode;
  title: string;
  description: string | null;
  totalQuestions: number;
  listIndex: number;
  systemQuestion?: boolean;
  actionComponents?: ReactNode;
}

export const QuestionPreview = ({
  children,
  title,
  description,
  totalQuestions,
  listIndex,
  systemQuestion,
  actionComponents,
}: QuestionPreviewProps) => {
  const { t } = useAppTranslation();
  return (
    <QuestionPreviewCard>
      <div className="flex justify-between">
        <div className="flex gap-2 items-center">
          <CardTitle>
            {t("questionnaires.questions.question")} {listIndex + 1}/
            {totalQuestions}
          </CardTitle>

          <Chip
            color={systemQuestion ? "primary" : "default"}
            label={
              systemQuestion
                ? t("questionnaires.system")
                : t("questionnaires.own")
            }
          />
        </div>

        {actionComponents}
      </div>
      <OuterLabel label={t("questionnaires.questions.question")}>
        <TextStyled>{title}</TextStyled>
      </OuterLabel>

      {!systemQuestion && (
        <OuterLabel label={t("questionnaires.questions.descripiton")}>
          <TextStyled>
            {description ?? t("questionnaires.questions.no_answer")}
          </TextStyled>
        </OuterLabel>
      )}

      {children}
    </QuestionPreviewCard>
  );
};
