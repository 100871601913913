import { ModalWrapper } from "@components/ModalWrapperNew";
import { useAppTranslation } from "@hooks";
import { Alert } from "@mui/material";

interface ConfirmSendToClientModalProps {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
}
export const ConfirmSendToClientModal = ({
  open,
  onSubmit,
  onClose,
}: ConfirmSendToClientModalProps) => {
  const { t } = useAppTranslation();

  const handleSubmit = () => {
    onSubmit();
    onClose();
  };
  return (
    <ModalWrapper
      title=""
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      showCloseButton={false}
      confirmText={t("pdf.send_to_client")}
    >
      {t("pdf.send_to_client_confirmation")}
      <Alert severity="info">{t("pdf.send_to_client_info")}</Alert>
    </ModalWrapper>
  );
};
