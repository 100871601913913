import { useAppTranslation } from "@hooks";
import { useFetchClinicCurrencies } from "@hooks/queries/settings";
import { useCurrenciesForm } from "./useCurrenciesForm";
import { useUpdateClinicCurrenciesMutation } from "@hooks/queries/settings/useUpdateClinicCurrenciesMutation";

import { ModalWrapper } from "@components/ModalWrapper";
import { FormLabeledCheckboxMui } from "@components/FormCheckboxMui";
import { FormAutocomplete } from "@components/FormAutocomplete";

import { HeaderStyled } from "./CurrenciesModal.styled";
import { Currencies } from "@utils/scheduleEvent";
import { FormHelperText, FormLabel } from "@mui/material";
import { toast } from "react-toastify";

interface CurrenciesModalProps {
  open: boolean;
  onClose: () => void;
}

export const CurrenciesModal = ({ open, onClose }: CurrenciesModalProps) => {
  const { t } = useAppTranslation();
  const { currencies } = useFetchClinicCurrencies();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useCurrenciesForm(currencies);

  const { mutate, isLoading } = useUpdateClinicCurrenciesMutation({
    onSuccess: onClose,
    onError: e => {
      if (e.response.status === 422) {
        return toast.error(t("products_settings.unchecked_currency_err"));
      }
      console.log("error", e);
    },
  });

  const onSubmit = handleSubmit(
    d => {
      const currencies: Currencies[] = [];

      if (d.active.pln) currencies.push(Currencies.PLN);
      if (d.active.eur) currencies.push(Currencies.EUR);
      if (d.active.usd) currencies.push(Currencies.USD);
      if (d.active.gbp) currencies.push(Currencies.GBP);

      mutate({ currencies, default: d.default });
    },
    e => console.log(e),
  );

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      reset={reset}
      isLoading={isLoading}
    >
      <HeaderStyled>{t("products_settings.currencies_config")}</HeaderStyled>

      <div>
        <FormLabel>{t("products_settings.active_currencies")}</FormLabel>

        <FormLabeledCheckboxMui
          control={control}
          name="active.pln"
          label="PLN"
        />
        <FormLabeledCheckboxMui
          control={control}
          name="active.eur"
          label="EUR"
        />
        <FormLabeledCheckboxMui
          control={control}
          name="active.usd"
          label="USD"
        />
        <FormLabeledCheckboxMui
          control={control}
          name="active.gbp"
          label="GBP"
        />

        {errors.active && (
          <FormHelperText error>{errors.active.message}</FormHelperText>
        )}
      </div>

      <FormAutocomplete
        control={control}
        name="default"
        id="default"
        size="small"
        className="w-full"
        options={[
          { id: "PLN", label: "PLN" },
          { id: "EUR", label: "EUR" },
          { id: "USD", label: "USD" },
          { id: "GBP", label: "GBP" },
        ]}
        label={t("products_settings.default_currency")}
        disableClearable
      />
    </ModalWrapper>
  );
};
