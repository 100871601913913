import { Fab, IconButton, styled } from "@mui/material";

import { FormTextFieldWrapper } from "../BlurContext/";

export const HeaderStyled = styled("span")`
  font-size: 1rem;
  line-height: 1.5rem;
  min-height: 1.5rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.medium[800]};
`;

export const RowHeaderStyled = styled("span")`
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1.5rem;
  min-height: 1.5rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.medium[800]};
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.neutral.light[100]};

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallMax}px) {
    width: 6.25rem;
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.medium}px) {
    width: 10rem;
  }
`;
export const ColumnHeaderStyled = styled("span")`
  font-size: 1rem;
  line-height: 1.5rem;
  min-height: 1.5rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.medium[800]};
  width: 14.375rem;
  padding-left: 0.75rem;
`;

export const ContentWrapper = styled("div")`
  display: grid;
  gap: 1rem;
`;

export const TableAnswerInputsWrapper = styled("div")`
  display: grid;
  overflow-x: auto;
  gap: 0.5rem;
`;

export const StyledFormTextField = styled(FormTextFieldWrapper)`
  width: 14.375rem;

  & .MuiOutlinedInput-root {
    height: 100%;
  }
`;

export const IconButtonStyled = styled(IconButton)`
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

export const FabStyled = styled(Fab)`
  position: absolute;
  right: 0;
  top: 50%;
  width: 32px;
  height: 32px;
  min-height: unset;

  &.MuiButtonBase-root.MuiFab-root {
    background: ${({ theme }) => theme.palette.primary.light};
    box-shadow: ${({ theme }) => theme.boxShadows.tooltip};
  }
  & svg {
    color: ${({ theme }) => theme.colors.neutral.dark[700]};
    font-size: 16px;
  }
`;
