import { Box, styled } from "@mui/material";
import { ReactNode } from "react";

type CardOptionProps = {
  icon: ReactNode;
  title: string;
  onClick: () => void;
  selected: boolean;
};
export const CardOption = ({
  icon,
  title,
  onClick,
  selected,
}: CardOptionProps) => {
  const Component = selected ? CardSelectedBoxStyled : CardBoxStyled;
  return (
    <Component onClick={onClick}>
      {icon}
      <Title selected={selected}>{title}</Title>
    </Component>
  );
};

const CardBoxStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 12px;
  filter: drop-shadow(0 0 10px RGBA(0, 0, 0, 0.05));
  backdrop-filter: blur(24px);
  background: #ffffff;
  cursor: pointer;
  padding: 16px 24px;
  min-width: 230px;
  color: #333333;
  svg {
    font-size: 24px;
  }
` as typeof Box;

const CardSelectedBoxStyled = styled(CardBoxStyled)`
  border: 1px solid #7448d0;
  background: #f5f1fe;
  color: #7448d0;
` as typeof CardBoxStyled;

const TitleDefault = styled("span")`
  line-height: 24px;
  font-size: 12px;
  font-family: Figtree, serif;
  font-weight: normal;
`;

const Title = styled(TitleDefault)<{ selected: boolean }>(({ selected }) => ({
  color: selected ? "#7448D0" : "#333333",
  fontSize: selected ? "14px" : "12px",
}));

export default CardOption;
