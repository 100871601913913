import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import IrrigationSummaryCardOf from "@views/dietician/PatientMonitoring2/components/SummaryCard/Irrigation/IrrigationSummaryCardOf";
import sumBy from "lodash/sumBy";
import { Dayjs } from "dayjs";
import { useMemo } from "react";
import { useFetchMonitoringIrrigation } from "@hooks/queries/client/monitoring";
import { PatientHydrationProductsMonitoringResource } from "@client/resources/PatientHydrationProductsMonitoringResource";

const IrrigationSummaryCardOfDay = () => {
  const { patientId, selectedDays } = useMonitoring();
  const { data } = useFetchMonitoringIrrigation({
    patientId,
    dateFrom: selectedDays.selectDays.from
      .clone()
      .add(-3, "days")
      .format("YYYY-MM-DD"),
    dateTo: selectedDays.selectDays.from
      .clone()
      .add(3, "days")
      .format("YYYY-MM-DD"),
  });

  const value = useMemo(
    () => getValues(data?.data, selectedDays.selectDays.from),
    [data, selectedDays.selectDays.from],
  );

  return (
    <IrrigationSummaryCardOf
      actualValue={value.actual}
      targetValue={value.target}
    />
  );
};

const getValues = (
  data: PatientHydrationProductsMonitoringResource[] | undefined,
  day: Dayjs,
) => {
  const date = day.format("YYYY-MM-DD");
  const itemDate = (data ?? []).find(i => i.date === date);

  return {
    actual: sumBy(itemDate?.products ?? [], p => p.milliliters) / 1000,
    target: (itemDate?.goal ?? 0) / 1000,
  };
};

export default IrrigationSummaryCardOfDay;
