import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const ListMonitoring = (props: SvgIconProps) => (
  <SvgIcon width="15" height="13" viewBox="0 0 15 13" {...props}>
    <path
      d="M1 0.704102C1.53125 0.704102 2 1.17285 2 1.7041C2 2.2666 1.53125 2.7041 1 2.7041C0.4375 2.7041 0 2.2666 0 1.7041C0 1.17285 0.4375 0.704102 1 0.704102ZM4.75 0.954102H14.25C14.6562 0.954102 15 1.29785 15 1.7041C15 2.1416 14.6562 2.4541 14.25 2.4541H4.75C4.3125 2.4541 4 2.1416 4 1.7041C4 1.29785 4.3125 0.954102 4.75 0.954102ZM4.75 5.9541H14.25C14.6562 5.9541 15 6.29785 15 6.7041C15 7.1416 14.6562 7.4541 14.25 7.4541H4.75C4.3125 7.4541 4 7.1416 4 6.7041C4 6.29785 4.3125 5.9541 4.75 5.9541ZM4.75 10.9541H14.25C14.6562 10.9541 15 11.2979 15 11.7041C15 12.1416 14.6562 12.4541 14.25 12.4541H4.75C4.3125 12.4541 4 12.1416 4 11.7041C4 11.2979 4.3125 10.9541 4.75 10.9541ZM2 6.7041C2 7.2666 1.53125 7.7041 1 7.7041C0.4375 7.7041 0 7.2666 0 6.7041C0 6.17285 0.4375 5.7041 1 5.7041C1.53125 5.7041 2 6.17285 2 6.7041ZM1 10.7041C1.53125 10.7041 2 11.1729 2 11.7041C2 12.2666 1.53125 12.7041 1 12.7041C0.4375 12.7041 0 12.2666 0 11.7041C0 11.1729 0.4375 10.7041 1 10.7041Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
