import { CircularProgress } from "@mui/material";

import { ProductTabPanels } from "@components/PreviewDrawer/Product/ProductTabPanels";
import { useFetchProductQuery } from "@hooks/queries/food";
import { scaleNutrients } from "@utils";
import { MeasureSelect } from "./MeasureSelect";
import { TabSwitch } from "@components/TabSwitch";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { TabContext } from "@mui/lab";
import { useAppTranslation } from "@hooks";

interface ProductContentProps {
  id: number;
  grams: number;
  measure: string;
  setGrams: Dispatch<SetStateAction<number>>;
  setMeasure: Dispatch<SetStateAction<string>>;
  setIsTouched: Dispatch<SetStateAction<boolean>>;
}
export const ProductContent = ({
  id,
  grams,
  measure,
  setGrams,
  setMeasure,
  setIsTouched,
}: ProductContentProps) => {
  const { t } = useAppTranslation();
  const { product, isLoading } = useFetchProductQuery(id, {
    onSuccess: d => {
      const defaultMeasure = d?.data.measures[0];
      if (!defaultMeasure) return;
      setMeasure(defaultMeasure.id.toString());
      setGrams(defaultMeasure.grams);
    },
  });
  const [tab, setTab] = useState(Tabs.NUTRITIONAL_VALUES);

  const tabs = useMemo(
    () => [
      {
        label: t("common.nutrients_values"),
        id: Tabs.NUTRITIONAL_VALUES,
      },
      {
        label: t("common.tags"),
        id: Tabs.TAGS,
      },
    ],
    [t],
  );

  const handleGramsChange = (value: number) => {
    setGrams(value);
    setIsTouched(true);
  };

  const handleMeasureChange = (id: string) => {
    setMeasure(id);
    setIsTouched(true);
  };

  if (isLoading) return <CircularProgress />;
  if (!product) return <></>;

  return (
    <>
      <div className="grid gap-[12px]">
        <div className="pl-[8px]">
          <MeasureSelect
            id={id}
            grams={grams}
            onGramsChange={handleGramsChange}
            measure={measure}
            onMeasureChange={handleMeasureChange}
          />
        </div>
        <div className="pl-[8px]">
          <TabSwitch tabs={tabs} value={tab} onChange={setTab} />
        </div>
      </div>
      <TabContext value={tab}>
        <ProductTabPanels
          nutrients={scaleNutrients(product?.nutrients ?? [], grams)}
          measures={product?.measures ?? []}
          tags={product?.tags.map(t => t.id) ?? []}
        />
      </TabContext>
    </>
  );
};

enum Tabs {
  NUTRITIONAL_VALUES = "0",
  TAGS = "1",
}
