import { useMutation, useQueryClient } from "@tanstack/react-query";

import { CreateProductResponse, requestCreateFoodEditor } from "@client/food";
import { StoreFoodRequest } from "@typeDefinitions/requests";

import { MutationOptions } from "../types";

export function useStoreFoodEditorMutation(
  options?: MutationOptions<CreateProductResponse>,
) {
  const queryClient = useQueryClient();

  return useMutation(
    (data: StoreFoodRequest) => requestCreateFoodEditor(data),
    {
      ...options,
      onSuccess: (data, variables) => {
        options?.onSuccess && options.onSuccess(data, variables);
      },
    },
  );
}
