import { InputAdornment, TextField, styled } from "@mui/material";

export const TextFieldStyled = styled(TextField)`
  & .MuiOutlinedInput-root {
    height: 40px;
    & fieldset {
      border-radius: 0 8px 8px 0;
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    top: -6.5px;
  }
`;

export const PrefixStyled = styled("div")`
  display: grid;
  place-items: center;
  border: 1px solid ${({ theme }) => theme.palette.primary.medium};
  border-right: none;
  border-radius: 8px 0 0 8px;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
  text-transform: uppercase;
`;

export const TextFieldWrapper = styled("div")`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;
