import { useTheme } from "@mui/material";
import { Text, Wrapper } from "./BoxElement.styled";
import ArrowRightInCircleIcon from "@assets/icons_new/arrowRightInCircle.svg?react";

interface BoxElementProps {
  text: string;
}
export const BoxElement = ({ text }: BoxElementProps) => {
  const { palette } = useTheme();
  return (
    <Wrapper>
      <div className="grid place-items-center min-w-[32px]">
        <ArrowRightInCircleIcon color={palette.primary.main} fontSize={16} />
      </div>
      <Text>{text}</Text>
    </Wrapper>
  );
};
