import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import { PhoneDto, phoneSchema } from "./common";

export const fetchClientsForChat = async (
  params?: URLSearchParams,
): Promise<ApiResponse<ClientForChatDto[]>> => {
  const response = await fetchData(
    "/dietitian/chat/patients/search",
    APIMethods.GET,
    undefined,
    params,
  );
  return await fetchClientsResponseSchema.validate(response);
};

const clientSchema = yup.object().shape({
  id: yup.number().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required(),
  phone: phoneSchema.required(),
});

const fetchClientsResponseSchema = yup.object().shape({
  data: yup.array().of(clientSchema).required(),
});

export interface ClientForChatDto {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: PhoneDto;
}
