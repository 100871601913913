import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Phone = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 11 17" {...props}>
      <path
        d="M9 0.222778C9.5625 0.243612 10.0312 0.441528 10.4062 0.816528C10.7812 1.19153 10.9792 1.66028 11 2.22278V14.2228C10.9792 14.7853 10.7812 15.254 10.4062 15.629C10.0312 16.004 9.5625 16.2019 9 16.2228H2C1.4375 16.2019 0.96875 16.004 0.59375 15.629C0.21875 15.254 0.0208333 14.7853 0 14.2228V2.22278C0.0208333 1.66028 0.21875 1.19153 0.59375 0.816528C0.96875 0.441528 1.4375 0.243612 2 0.222778H9ZM9.5 14.2228V2.22278C9.47917 1.91028 9.3125 1.74361 9 1.72278H2C1.6875 1.74361 1.52083 1.91028 1.5 2.22278V14.2228C1.52083 14.5353 1.6875 14.7019 2 14.7228H9C9.3125 14.7019 9.47917 14.5353 9.5 14.2228ZM6.5 12.7228C6.8125 12.7436 6.97917 12.9103 7 13.2228C6.97917 13.5353 6.8125 13.7019 6.5 13.7228H4.5C4.1875 13.7019 4.02083 13.5353 4 13.2228C4.02083 12.9103 4.1875 12.7436 4.5 12.7228H6.5Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
