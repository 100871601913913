import { useMemo } from "react";
import { useAppParams, useAppTranslation } from "@hooks";
import { FullSpinner, MealWrapper, StyledCard } from "./Meal.styled";
import { BreadcrumbsPath } from "@components/BreadcrumbsPath";
import { Meals } from "@assets/icons/Menu";
import { useFetchCreatorMealQuery } from "@hooks/queries";
import { ViewError } from "@components/ViewError";
import { LangDto, TranslationDto } from "@client";
import { percentageFromNutrients } from "@components/MacrosSliders";
import { GroupedNutrients } from "@components/GroupedNutrients";

import { MealDetails } from "./components/MealDetails";
import { MealRecipe, MealRecipes } from "./components/MealRecipes";
import { MealProduct, ProductsCard } from "./components/MealProducts";
import { MacrosBarChart } from "@components/MacrosBarChart";
import { compact } from "lodash";

export const Meal = () => {
  const { mealId } = useAppParams();
  const { meal, isError, isLoading } = useFetchCreatorMealQuery(mealId);

  const { t, isPolishChosen } = useAppTranslation();

  const getNameByLang = (
    lang: LangDto,
    translations: TranslationDto[] | undefined,
  ): string => translations?.find(n => n.langId === lang)?.name ?? "";
  const mealNamePl = getNameByLang(LangDto.PL, meal?.translations);
  const mealNameEn = getNameByLang(LangDto.EN, meal?.translations);

  const mealName = isPolishChosen ? mealNamePl : mealNameEn || mealNamePl;

  const breadcrumbs = useMemo(
    () => [
      {
        icon: <Meals />,
        backTo: "/meals",
        title: t("meals.title"),
      },
      {
        backTo: `/meals/${meal?.id}`,
        title: mealName,
      },
    ],
    [meal, mealName],
  );

  const recipes = useMemo<(MealRecipe | undefined)[]>(
    () =>
      meal?.recipes.map(recipe => {
        if (!recipe.recipe.isPlaceholder) {
          const {
            id,
            servings: selectedServings,
            recipe: {
              nutrients,
              name,
              nameEn,
              servings,
              foodRecipe,
              media,
              description,
              descriptionEn,
              isComplex,
            },
          } = recipe;

          return {
            id,
            nutrients,
            servings,
            productsCount: foodRecipe.length ?? 0,
            namePl: name ?? "",
            nameEn: nameEn ?? "",
            mediaUrl: media?.url ?? "",
            rows: foodRecipe.map(food => ({ ...food, grams: food.grams ?? 0 })),
            descriptionPl: description ?? "",
            descriptionEn: descriptionEn ?? "",
            isComplex,
            selectedServings,
          };
        }
      }) ?? [],
    [meal],
  );

  const macrosPercentage = useMemo(
    () => percentageFromNutrients(meal?.nutrients),
    [percentageFromNutrients, meal?.nutrients],
  );

  const products = useMemo<(MealProduct | undefined)[]>(
    () =>
      meal?.products.map(p => {
        if (!p.isPlaceholder)
          return {
            id: p.id,
            nutrients: p.food.nutrients,
            grams: p.grams,
            measureId: p.measure.id,
            namePl: getNameByLang(LangDto.PL, p.food.translations),
            nameEn: getNameByLang(LangDto.EN, p.food.translations),
            measureGrams:
              p.food.measures.find(m => m.id === p.measure.id)?.grams ?? 1,
          };
      }) ?? [],
    [meal],
  );

  const tags = useMemo(
    () =>
      meal?.tags.map(
        t =>
          (isPolishChosen
            ? t.translations.find(lang => lang.langId === LangDto.PL)?.name
            : t.translations.find(lang => lang.langId === LangDto.EN)?.name) ??
          t.name,
      ) ?? [],
    [meal?.tags, isPolishChosen],
  );

  if (isLoading) return <FullSpinner />;

  if (isError || !meal) return <ViewError />;

  return (
    <MealWrapper>
      <BreadcrumbsPath breadcrumbs={breadcrumbs} />

      <StyledCard>
        <MealDetails
          name={mealName}
          nutrients={meal.nutrients}
          canEdit={meal.actions.canEdit}
          canDelete={meal.actions.canDelete}
          canToggleVisibility={meal.actions.canVisible}
          tags={tags}
        />
      </StyledCard>

      {!!products.length && (
        <StyledCard>
          <ProductsCard products={compact(products)} />
        </StyledCard>
      )}

      <StyledCard>
        <MealRecipes
          recipes={compact(recipes)}
          canEdit={meal.actions.canEdit}
        />
      </StyledCard>

      <StyledCard>
        <MacrosBarChart {...macrosPercentage} />
      </StyledCard>

      <StyledCard>
        <GroupedNutrients
          title={t("common.nutrients_values")}
          nutrients={meal.nutrients}
        />
      </StyledCard>
    </MealWrapper>
  );
};
