import { useMutation, useQueryClient } from "@tanstack/react-query";
import { storeReadMonitoringCalendarDay } from "client/patient/monitoring";
import { ApiResponse } from "@typeDefinitions";
import { CalendarDayMonitoringResource } from "@client/resources/CalendarDayMonitoringResource";
import { patientMonitoringKeys } from "@hooks/queries/client/monitoring/patientMonitoringKey";

type Params = {
  dateFrom: string;
  dateTo: string;
};

export const useStoreReadMonitoringCalendarDayMutation = (params: Params) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      patientId,
      dayId,
    }: {
      patientId: number;
      dayId: number;
    }) => {
      return storeReadMonitoringCalendarDay(patientId, dayId);
    },
    onSuccess: async (_, variables) => {
      queryClient.setQueryData(
        patientMonitoringKeys.calendarDays({
          patientId: variables.patientId,
          ...params,
        }),
        (oldData: ApiResponse<CalendarDayMonitoringResource[]> | undefined) => {
          if (oldData === undefined) {
            return undefined;
          }

          return {
            ...oldData,
            data: oldData.data.map(item => {
              if (item.id === variables.dayId) {
                return {
                  ...item,
                  notification: false,
                };
              }
              return item;
            }),
          };
        },
      );
    },
  });
};
