import { useForm } from "react-hook-form";

import {
  FetchClientFoodGroupPreferencesResponse,
  FetchClientFoodPreferencesResponse,
  FetchClientTagsResponse,
  UpdateClientFoodCategoriesPreferencesRequest,
  UpdateClientFoodPreferencesRequest,
  UpdateClientTagsRequest,
} from "@client";
import { useEffect } from "react";
import { compact } from "lodash";
import { TagCategoryType } from "@utils/tagsNew";
import { allergenCategoryId, dietTypeId } from "@utils";

export const useEditFoodAndAllergensForm = (
  values?: EditFoodAndAllergensFormInput,
) => {
  const form = useForm<EditFoodAndAllergensFormInput>({
    defaultValues: values,
  });

  useEffect(() => {
    if (values) form.reset(values);
  }, [values]);

  return form;
};

export const mapEditAllergensRequest = (
  data: EditFoodAndAllergensFormInput,
): UpdateClientTagsRequest => {
  return {
    categoryType: TagCategoryType.ALLERGENS,
    tagsId: data?.allergens?.map(el => Number(el)) ?? [],
  };
};

export const mapEditDietTypeRequest = (
  data: EditFoodAndAllergensFormInput,
): UpdateClientTagsRequest => {
  return {
    categoryType: TagCategoryType.DIET_TYPE,
    tagsId: data?.dietType?.map(el => Number(el)) ?? [],
  };
};
export const mapEditDislikedProductsRequest = (
  data: EditFoodAndAllergensFormInput,
): UpdateClientFoodPreferencesRequest => {
  return {
    isLiked: false,
    productsId: data?.dislikedProducts.map(el => Number(el)),
  };
};
export const mapEditLikedProductsRequest = (
  data: EditFoodAndAllergensFormInput,
): UpdateClientFoodPreferencesRequest => {
  return {
    isLiked: true,
    productsId: data?.likedProducts.map(el => Number(el)),
  };
};

export const mapEditDislikedCategoriesRequest = (
  data: EditFoodAndAllergensFormInput,
): UpdateClientFoodCategoriesPreferencesRequest => {
  return {
    isLiked: false,
    categoriesId: data?.dislikedCategories.map(el => Number(el)),
  };
};

export const mapEditLikeCategoriesRequest = (
  data: EditFoodAndAllergensFormInput,
): UpdateClientFoodCategoriesPreferencesRequest => {
  return {
    isLiked: true,
    categoriesId: data?.likedCategories.map(el => Number(el)),
  };
};

export const mapEditFoodAndAllergensForm = (
  data: FetchClientTagsResponse[] | undefined,
  dislikedProducts: FetchClientFoodPreferencesResponse[] | undefined,
  likedProducts: FetchClientFoodPreferencesResponse[] | undefined,
  preferences: FetchClientFoodGroupPreferencesResponse[] | undefined,
): EditFoodAndAllergensFormInput | undefined => {
  const dataFields = {
    allergens:
      data
        ?.filter(el => {
          if (el.category.id === allergenCategoryId) return el.id;
        })
        .map(e => e.id.toString()) ?? [],
    dietType:
      data
        ?.filter(el => {
          if (el.category.id === dietTypeId) return el.id;
        })
        .map(e => e.id.toString()) ?? [],
  };

  const productsFields = {
    dislikedProducts: dislikedProducts?.map(el => el.food.id.toString()) ?? [],
    likedProducts: likedProducts?.map(el => el.food.id.toString()) ?? [],
  };
  const categoriesFields = {
    dislikedCategories: compact(
      preferences?.map(el => {
        if (el.isLiked === false) return el.foodGroup.id.toString();
      }),
    ),
    likedCategories: compact(
      preferences?.map(el => {
        if (el.isLiked === true) return el.foodGroup.id.toString();
      }),
    ),
  };

  return { ...dataFields, ...productsFields, ...categoriesFields };
};

export interface EditFoodAndAllergensFormInput {
  allergens?: string[];
  dietType?: string[];
  dislikedProducts: string[];
  likedProducts: string[];
  dislikedCategories: string[];
  likedCategories: string[];
}
