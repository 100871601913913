import { Box, styled } from "@mui/material";

type Props = {
  value: number;
  assumption: number;
  type: "kcal" | "protein" | "fat" | "carb";
};

const MacroCircularLabel = ({ value, assumption, type }: Props) => {
  const unit = type === "kcal" ? "kcal" : "g";
  return (
    <Box display="flex" flexDirection="column" gap="0px" alignItems="center">
      <ValueLabel>{value}</ValueLabel>
      <AssumptionLabel>
        / {assumption} {unit}
      </AssumptionLabel>
    </Box>
  );
};

const ValueLabel = styled("span")`
  font: var(--font-figtree-semi-bold);
  font-size: 12px;
  line-height: 18px;
  color: #2f303a;
`;
const AssumptionLabel = styled("span")`
  font: var(--font-figtree-medium);
  font-size: 10px;
  line-height: 18px;
  color: #2f303a;
`;

export default MacroCircularLabel;
