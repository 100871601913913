import React from "react";
import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const BulletMenu = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <g id="bullet-menu" opacity="0.35">
      <rect
        id="icon_20x20_odstep_18px"
        data-name="icon 20x20 / odstep 18px"
        width="20"
        height="20"
        fill="#fff"
        opacity="0"
      />
      <g
        id="Group_4157"
        data-name="Group 4157"
        transform="translate(-245 -389)"
      >
        <g
          id="Component_111_43"
          data-name="Component 111 – 43"
          transform="translate(253 389)"
        >
          <circle
            id="Ellipse_46"
            data-name="Ellipse 46"
            cx="1.75"
            cy="1.75"
            r="1.75"
            fill="currentColor"
          />
        </g>
        <g
          id="Component_111_44"
          data-name="Component 111 – 44"
          transform="translate(253 396)"
        >
          <circle
            id="Ellipse_46-2"
            data-name="Ellipse 46"
            cx="1.75"
            cy="1.75"
            r="1.75"
            fill="currentColor"
          />
        </g>
        <g
          id="Component_111_45"
          data-name="Component 111 – 45"
          transform="translate(253 403)"
        >
          <circle
            id="Ellipse_46-3"
            data-name="Ellipse 46"
            cx="1.75"
            cy="1.75"
            r="1.75"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);
