import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import { dietitianDietDaySchema, DietitianProgramScheduleDay } from "./common";
import * as yup from "yup";
import { validateAndFixNutrients } from "../utils/validateCatching";

export async function fetchDietitianProgramScheduleDays(
  programId: number,
  params?: URLSearchParams,
): Promise<ApiResponse<DietitianProgramScheduleDay[]>> {
  const data = await fetchData(
    `/dietitian/programs/${programId}/schedule/days`,
    APIMethods.GET,
    undefined,
    params,
  );
  return await fetchDietDaysSchema.validate(data);
}

const fetchDietDaysSchema = yup.object().shape({
  data: yup.array().of(dietitianDietDaySchema).required(),
});
