import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteCreatorMealRecipe, FetchMealResponse } from "@client/meals";
import { fetchCreatorMealQueryKey, MutationOptions } from "@hooks/queries";
import { ApiResponse } from "@typeDefinitions";
import { useFetchMealTagsQueryKey } from "./useFetchCreatorMealTags";
import { fetchCreatorMealNutrientsQueryKey } from "./useFetchCreatorMealNutrientsQuery";
import { useMealVersion } from "@views/dietician/MealEdit/hooks";

export const useDeleteCreatorMealRecipeMutation = (
  mealId: string,
  recipeId: string,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();
  const { version, updateVersion } = useMealVersion();

  const mutation = useMutation<unknown, unknown>(
    () => deleteCreatorMealRecipe(mealId, recipeId, version),
    {
      ...options,
      onSuccess: (data, variables) => {
        options?.onSuccess && options.onSuccess(data, variables);
        queryClient.invalidateQueries([useFetchMealTagsQueryKey, mealId]);
        queryClient.invalidateQueries([
          fetchCreatorMealNutrientsQueryKey,
          mealId,
        ]);
      },
      onMutate: async () => {
        await queryClient.cancelQueries([fetchCreatorMealQueryKey, mealId]);
        queryClient.setQueryData<ApiResponse<FetchMealResponse>>(
          [fetchCreatorMealQueryKey, mealId],
          old => {
            if (!old) return;
            return {
              ...old,
              data: {
                ...old?.data,
                recipes: old?.data.recipes.filter(
                  r => r.id.toString() !== recipeId,
                ),
                numberOfItems: old.data.numberOfItems - 1,
              },
            };
          },
        );
        updateVersion();
      },
      onError: () => {
        queryClient.invalidateQueries([fetchCreatorMealQueryKey, mealId]);
      },
    },
  );

  return mutation;
};
