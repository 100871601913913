import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const updateClientFoodPreferences = async (
  patientId: string,
  payload: UpdateClientFoodPreferencesRequest,
) => {
  await fetchData(
    `/dietitian/patients/${patientId}/food-preferences`,
    APIMethods.PUT,
    payload,
  );
};

export interface UpdateClientFoodPreferencesRequest {
  isLiked: boolean;
  productsId: number[];
}
