import { useQuery } from "@tanstack/react-query";

import {
  FetchPatientProgramResponse,
  fetchPatientProgram,
} from "@client/program";
import { ApiResponse } from "@typeDefinitions";

import { QueryOptionsTyped } from "./types";

export const fetchPatientProgramQueryKey = "fetchPatientProgramQueryKey";

export const useFetchPatientProgramQuery = (
  patientId: string,
  programId: string | undefined,
  options?: QueryOptionsTyped<ApiResponse<FetchPatientProgramResponse>>,
) => {
  const programIdExist = !!programId && programId !== "0"; // This is duo PatientProgram = selectedProgramId = 0 -> it should be not defined!!
  const enabled =
    options?.enabled === undefined
      ? programIdExist
      : options?.enabled && programIdExist;

  const { data, ...rest } = useQuery(
    [fetchPatientProgramQueryKey, patientId, programId],
    () => fetchPatientProgram(patientId!, programId!),
    {
      ...options,
      enabled,
    },
  );
  return { program: data?.data, ...rest };
};
