import { ThemeProviderWrapperNew } from "themeNew";
import { ResetNew } from "./ResetNew";

export const ResetWrappedInTheme = () => {
  return (
    <ThemeProviderWrapperNew>
      <ResetNew />
    </ThemeProviderWrapperNew>
  );
};
