import { useAppParams } from "@hooks";
import { useUpdateCreatorDietShowMacrosMutation } from "@hooks/queries/diets/creator";
import { useUpdateProgramDietShowMacrosMutation } from "@hooks/queries/program/dietCreator";

export const useUpdateDietShowMacrosMutation = () => {
  const { programId } = useAppParams();

  return programId
    ? useUpdateProgramDietShowMacrosMutation(programId)
    : useUpdateCreatorDietShowMacrosMutation();
};
