import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const PaperPlane = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="17" height="17" viewBox="0 0 17 17">
      <path
        d="M15.6875 1.125C15.9375 1.3125 16.0312 1.5625 15.9688 1.875L13.9688 15.375C13.9271 15.6042 13.8021 15.7812 13.5938 15.9062C13.4688 15.9688 13.3438 16 13.2188 16C13.1146 16 13.0208 15.9792 12.9375 15.9375L9.84375 14.6562L6.375 16.875C6.25 16.9583 6.11458 17 5.96875 17C5.86458 17 5.76042 16.9688 5.65625 16.9062C5.38542 16.7604 5.25 16.5417 5.25 16.25V12.6875L0.46875 10.6875C0.177083 10.5625 0.0208333 10.3438 0 10.0312C0 9.73958 0.125 9.51042 0.375 9.34375L14.875 1.09375C15.1458 0.947917 15.4167 0.958333 15.6875 1.125ZM11.5312 4.71875L2.4375 9.90625L5.6875 11.25L11.5312 4.71875ZM6.71875 14.875L8.1875 13.9375L6.71875 13.3438V14.875ZM12.6562 14.1875L14.1562 4.0625L7.125 11.875L12.6562 14.1875Z"
        strokeWidth="0.01px"
        fill={props.fill ?? "currentColor"}
        transform="translate(0, -1)"
      />
    </SvgIcon>
  );
};
