import { useAppTranslation } from "@hooks";
import { useItemsContext } from "../RecipesTab/ItemsContext";
import { Bold, Text } from "./SelectedItemsCounter.styled";

export const SelectedItemsCounter = () => {
  const { t } = useAppTranslation();
  const { itemsCount } = useItemsContext();

  return (
    <div>
      <Bold>{t("common.selected")}&nbsp;</Bold>
      <Text>({t("diet.proposals", { count: itemsCount })})</Text>
    </div>
  );
};
