import {
  Button,
  styled,
  Accordion,
  AccordionDetails,
  Typography,
  IconButton,
  Autocomplete,
  Divider,
  AccordionSummary,
} from "@mui/material";
import { FormTextField } from "@components/FormTextField";

export const StyledImg = styled("img")`
  height: 8.5rem;
  width: 8.5rem;
  min-width: 8.5rem;
  border-radius: 0.25rem;
  z-index: 1;
`;

export const StyledImagePlaceholder = styled("div")`
  display: flex;
  min-height: 8.5rem;
  min-width: 8.5rem;
  border-radius: 0.25rem;
  background: ${({ theme }) => theme.palette.action.hover};
  align-items: center;
  justify-content: center;
`;

export const RecipeDetailsWrapper = styled("div")`
  display: grid;
  gap: 1rem;
  width: 100%;
`;

export const HeaderWrapper = styled("div")`
  display: flex;
  gap: 1rem;
  width: 100%;
`;

export const PreviewWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`;

export const TitleWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: start;
`;

interface StyledButtonProps {
  isOpen: boolean;
}

export const StyledButton = styled(Button, {
  shouldForwardProp: propName => propName !== "isOpen",
})<StyledButtonProps>`
  padding: 0;

  &:hover {
    background-color: unset;
  }

  & .MuiTypography-root {
    color: ${({ theme }) => theme.palette.text.primary};
  }

  & .MuiButton-endIcon {
    transition: all 0.2s;
    & .arrow-down {
      font-size: 1.375rem;
    }
    ${({ isOpen }) =>
      isOpen &&
      `
    transform:  rotate(180deg);
    `}
  }
`;

interface StyledAccordionProps {
  isMargin?: boolean;
}

export const StyledAccordion = styled(Accordion, {
  shouldForwardProp: propName => propName !== "isMargin",
})<StyledAccordionProps>`
  background: transparent;
  flex-direction: column-reverse;
  display: flex;

  & .MuiButtonBase-root.MuiAccordionSummary-root {
    width: 4rem;
    margin-left: auto;
  }
`;

export const StyledTypography = styled(Typography)`
  font-weight: 600;
  min-width: max-content;
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  &.MuiAccordionDetails-root {
    display: grid;
    gap: 1rem;
  }
`;

export const CountsWrapper = styled("div")`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: end;
`;

export const NameFormTextField = styled(FormTextField)`
  flex: 0.4;
`;

export const PortionsFormTextField = styled(FormTextField)`
  flex: 0.2;
`;

export const StyledServingsAutocomplete = styled(Autocomplete)`
  width: 7.5rem;
` as typeof Autocomplete;

export const StyledDivider = styled(Divider)`
  padding-top: 0.5rem;
`;
export const NameSectionWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  margin-top: 0.75rem;
`;

export const AccordionSummaryPlaceholder = styled(AccordionSummary)`
  display: none;
`;

export const StyledExpandButton = styled(Button, {
  shouldForwardProp: propName => propName !== "isOpen",
})<StyledButtonProps>`
  padding: 0 0.5rem 0 0;

  &:hover {
    background-color: unset;
  }

  & .MuiTypography-root {
    color: ${({ theme }) => theme.palette.text.primary};
  }

  & .MuiButton-endIcon {
    transition: all 0.2s;
    & .arrow-down {
      font-size: 1.375rem;
    }
    ${({ isOpen }) =>
      isOpen &&
      `
    transform:  rotate(180deg);
    `}
  }
`;

interface BreakpointsIf {
  isSmall?: boolean;
}
export const DescriptionChartWrapper = styled("div", {
  shouldForwardProp: propName => propName !== "isSmall",
})<BreakpointsIf>`
  display: flex;
  flex-direction: ${({ isSmall }) => (isSmall ? "column-reverse" : "row")};
`;

export const ChartWrapper = styled("div", {
  shouldForwardProp: propName => propName !== "isSmall",
})<BreakpointsIf>`
  display: flex;
  flex: 1;
  gap: 3rem;
  justify-content: ${({ isSmall }) => (isSmall ? "center" : "end")};
`;
