import { useQuery } from "@tanstack/react-query";

import {
  DietSearchPreviewResourceDto,
  searchDietsPreviewDiet,
} from "@client/diets";
import { ApiResponse } from "@typeDefinitions";

import { QueryOptionsTyped } from "../types";

export const searchDietsPreviewDietQueryKey = "searchDietsPreviewDietQueryKey";

export const useSearchDietsPreviewDietQuery = (
  dietId: string,
  options?: QueryOptionsTyped<ApiResponse<DietSearchPreviewResourceDto>>,
) => {
  const { data, ...rest } = useQuery(
    [searchDietsPreviewDietQueryKey, dietId],
    () => searchDietsPreviewDiet(dietId),
    options,
  );

  return {
    diet: data?.data,
    ...rest,
  };
};
