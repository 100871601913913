import { styled } from "@mui/material";

export const LiStyled = styled("li")`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;
