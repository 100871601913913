import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Send = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <g
      id="_20-icon-send"
      data-name="20-icon-send"
      transform="translate(-980 -153)"
    >
      <g id="Group_4279" data-name="Group 4279" transform="translate(977 151)">
        <rect
          id="Rectangle_1195"
          data-name="Rectangle 1195"
          width="20"
          height="20"
          transform="translate(3 2)"
          fill="#fff"
          opacity="0"
        />
        <path
          id="Path_760"
          data-name="Path 760"
          d="M10.854,15.264,9,17.455V21L19,8Z"
          fill="#fff"
        />
        <path
          id="Path_761"
          data-name="Path 761"
          d="M3,12l5.607,2.99h.006L17,8l-6.225,7.357L12,16.781,18,20,21,3Z"
          fill="#fff"
        />
      </g>
    </g>
  </SvgIcon>
);
