import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MutationOptions } from "./types";
import {
  UpdateClientFoodCategoriesPreferencesRequest,
  updateClientFoodCategoriesPreferences,
} from "@client";
import { fetchClientFoodGroupPreferencesQueryKey } from "./useFetchClientFoodGroupPreferences";
import { fetchClientFoodPreferencesQueryKey } from "./useFetchClientFoodPreferences";
import { AxiosError } from "axios";
import { ApiErrorIf } from "@typeDefinitions";

export const useUpdateClientFoodCategoriesPreferences = (
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    AxiosError<ApiErrorIf>,
    UpdateClientFoodCategoriesPreferencesMutationPayload
  >(({ id, payload }) => updateClientFoodCategoriesPreferences(id, payload), {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([fetchClientFoodPreferencesQueryKey]);
      queryClient.invalidateQueries([fetchClientFoodGroupPreferencesQueryKey]);
      options?.onSuccess && options.onSuccess(data, variables);
    },
    ...options,
  });
};

interface UpdateClientFoodCategoriesPreferencesMutationPayload {
  id: string;
  payload: UpdateClientFoodCategoriesPreferencesRequest;
}
