import { MouseEventHandler, useState } from "react";

import { Chip, Fade, Popper, useMediaQuery, useTheme } from "@mui/material";

import { useAppTranslation } from "@hooks";
import { useTagsNew } from "@hooks/useTagsNew";

import { CardStyled, ShowMoreChip, TagsWrapper } from "./TagsColumnCell.styled";

interface TagsColumnCellProps {
  tags: string[];
}

export const TagsColumnCell = ({ tags }: TagsColumnCellProps) => {
  const { t } = useAppTranslation();
  const { tagNameDictionary } = useTagsNew();
  const { breakpoints } = useTheme();
  const downMedium = useMediaQuery(`${breakpoints.down("medium")}`);
  const downLaptop = useMediaQuery(`${breakpoints.down("laptop")}`);
  const MOBILE_SLICE_COUNT = 7;
  const TABLET_SLICE_COUNT = 4;
  const LAPTOP_SLICE_COUNT = 5;

  const sliceCount = downMedium
    ? MOBILE_SLICE_COUNT
    : downLaptop
    ? TABLET_SLICE_COUNT
    : LAPTOP_SLICE_COUNT;

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const toggleAnchor: MouseEventHandler<HTMLDivElement> = e =>
    anchorEl ? setAnchorEl(null) : setAnchorEl(e.currentTarget);

  const handleMouseLeave = () => setAnchorEl(null);

  return (
    <TagsWrapper onMouseLeave={handleMouseLeave}>
      {tags.slice(0, sliceCount).map(t => (
        <Chip
          key={t}
          color="primary"
          label={tagNameDictionary.get(Number(t))}
        />
      ))}
      {tags.length > sliceCount && (
        <ShowMoreChip
          color="primary"
          variant="outlined"
          label={t("common.more").toLocaleLowerCase()}
          onClick={toggleAnchor}
        />
      )}
      {tags.length > sliceCount && (
        <Popper open={!!anchorEl} anchorEl={anchorEl} transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} in={!!anchorEl}>
              <CardStyled>
                {tags.slice(sliceCount).map(t => (
                  <Chip
                    key={t}
                    color="primary"
                    label={tagNameDictionary.get(Number(t))}
                  />
                ))}
              </CardStyled>
            </Fade>
          )}
        </Popper>
      )}
    </TagsWrapper>
  );
};
