import { useMutation } from "@tanstack/react-query";
import { useAppTranslation } from "@hooks";
import { MutationOptions } from "./types";
import { resendActivationLinkRequest } from "@client";
import { toast } from "react-toastify";

export function useResendActivationLinkRequestMutation(
  options?: MutationOptions,
) {
  const { t } = useAppTranslation();

  return useMutation(
    (data: { email: string }) => resendActivationLinkRequest(data),
    {
      ...options,
      onSuccess: () => {
        toast.success(t("auth.activation.resend.success"));
        options?.onSuccess && options.onSuccess();
      },
    },
  );
}
