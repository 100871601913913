import { Dialog } from "@mui/material";
import {
  CloseButton,
  ModalWrapper,
  StyledDialogActions,
  Title,
  TitleWrapper,
} from "@components/BuyPlanModal/BuyPlanModal.styled";
import { Close } from "@assets/icons";

import { FC, useState } from "react";
import { useAppTranslation } from "@hooks";
import { FormProvider } from "react-hook-form";
import usePdfColorPickerForm from "@Pdf/hooks/form/usePdfColorPickerForm";
import { FormColorPickerField } from "@components/FormColorPickerField";
import { StyledCancelBtn } from "@views/dietician/Diet/components/EditBrandNameModal";
import { useAddPdfColorMutation } from "@Pdf/hooks/query/useAddPdfColorQuery";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { FormTextField } from "@components/FormTextField";
import LabelField from "@Pdf/components/LabelField/LabelField";

type PropsType = {
  open: boolean;
  onSubmitted: (color: string) => void;
  onClose: () => void;
};

const ColorPickerModal: FC<PropsType> = ({ open, onClose, onSubmitted }) => {
  const { t } = useAppTranslation();
  const [submitting, setSubmitting] = useState(false);
  const form = usePdfColorPickerForm();
  const { mutate } = useAddPdfColorMutation({
    onSuccess: async () => {
      setSubmitting(false);
      toast.success(t("pdf.create_color_success"));
      onSubmitted(form.getValues("color"));
      onClose();
    },
    onError: () => {
      setSubmitting(false);
      toast.error(t("pdf.create_color_error"));
    },
  });

  const onSubmit = () => {
    form.handleSubmit(
      data => {
        mutate(data);
      },
      () => {
        setSubmitting(false);
      },
    )();
  };

  return (
    <Dialog open={open} id={"export-pdf-modal"}>
      <ModalWrapper style={{ width: "fit-content" }}>
        <TitleWrapper>
          <Title>{t("pdf.modalColorTitle")}</Title>
          <CloseButton size="small" onClick={onClose}>
            <Close size="w-3 h-3" />
          </CloseButton>
        </TitleWrapper>
        <FormProvider {...form}>
          <div
            className={"flex flex-col gap-[16px]"}
            style={{ width: "436px" }}
          >
            <LabelField label={t("pdf.chose_color")}>
              <FormColorPickerField
                control={form.control}
                name="color"
                size="small"
                variant="outlined"
              />
            </LabelField>
            <LabelField label={t("pdf.write_color")}>
              <FormTextField
                control={form.control}
                name="color"
                size="small"
                helperText={t("common.color_hint")}
                variant="outlined"
              />
            </LabelField>
          </div>
        </FormProvider>
      </ModalWrapper>
      <StyledDialogActions>
        <StyledCancelBtn variant="text" onClick={onClose}>
          {t("common.cancel")}
        </StyledCancelBtn>
        <LoadingButton
          variant="contained"
          loading={submitting}
          onClick={onSubmit}
        >
          {t("common.save")}
        </LoadingButton>
      </StyledDialogActions>
    </Dialog>
  );
};

export default ColorPickerModal;
