import {
  useState,
  useLayoutEffect,
  useEffect,
  RefObject,
  SetStateAction,
  Dispatch,
} from "react";
import { debounce } from "lodash";
import { OptionIf } from "@components/Filters/FiltersBuilder";

interface UseVisibleChipsProps {
  options: OptionIf[];
  containerRef: RefObject<HTMLDivElement>;
  expanded: boolean;
  maxLines?: number;
  debounceDelay?: number;
  setExpanded: Dispatch<SetStateAction<boolean>>;
}

interface UseVisibleChipsReturn {
  visibleCount: number;
  collapsedVisibleCount: number;
  handleExpand: () => void;
}

export const useVisibleChips = ({
  options,
  containerRef,
  expanded,
  setExpanded,
  maxLines = 2,
  debounceDelay = 200,
}: UseVisibleChipsProps): UseVisibleChipsReturn => {
  const [visibleCount, setVisibleCount] = useState(options.length);
  const [collapsedVisibleCount, setCollapsedVisibleCount] = useState(
    options.length,
  );

  const calculateVisibleChips = () => {
    if (containerRef.current && !expanded) {
      const container = containerRef.current;
      const chipElements = container.querySelectorAll('[data-chip="true"]');

      if (!chipElements.length) return;

      let lastOffsetTop = (chipElements[0] as HTMLElement).offsetTop;
      let visibleChips = 0;
      let lines = 1;

      const tolerance = 10; // Tolerance for line change

      for (let i = 0; i < chipElements.length; i++) {
        const chipElement = chipElements[i] as HTMLElement;

        if (Math.abs(chipElement.offsetTop - lastOffsetTop) > tolerance) {
          lines++;
          lastOffsetTop = chipElement.offsetTop;

          if (lines > maxLines) {
            break;
          }
        }
        visibleChips++;
      }

      // If total lines are within maxLines, show all chips
      if (lines <= maxLines) {
        setVisibleCount(options.length);
      } else {
        setVisibleCount(visibleChips);
      }
    } else if (expanded) {
      setVisibleCount(options.length);
    }
  };

  const debouncedCalculateVisibleChips = debounce(
    calculateVisibleChips,
    debounceDelay,
  );

  useLayoutEffect(() => {
    calculateVisibleChips();
    // Cleanup debounce on unmount
    return () => {
      debouncedCalculateVisibleChips.cancel();
    };
  }, [options, expanded]);

  useEffect(() => {
    const handleResize = () => {
      debouncedCalculateVisibleChips();
    };

    window.addEventListener("resize", handleResize);

    // Initial calculation
    calculateVisibleChips();

    return () => {
      window.removeEventListener("resize", handleResize);
      debouncedCalculateVisibleChips.cancel();
    };
  }, [options, expanded]);

  const handleExpand = () => {
    setCollapsedVisibleCount(options.length - visibleCount);
    setExpanded(!expanded);
  };

  return { visibleCount, collapsedVisibleCount, handleExpand };
};
