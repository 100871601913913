import React, { useCallback } from "react";

import { Control, Path } from "react-hook-form";

import { useAppTranslation } from "@hooks";
import { FieldValues } from "react-hook-form/dist/types";
import { FormInput } from "@components/FormInput";

import { Search } from "@assets/icons";
import { SearchButton } from "./SearchBar.styled";

export const SearchBar = <T extends FieldValues>(props: SearchBarProps<T>) => {
  const { t } = useAppTranslation();

  const keyDownCallback = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter" && props.onSearch) {
        props.onSearch();
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [props.onSearch],
  );

  return (
    <div className="w-1/2 flex flex-col items-center justify-center">
      <FormInput
        fullWidth
        name={props.name}
        control={props.control}
        placeholder={props.placeholder}
        variant="outlined"
        className="flex items-center"
        onKeyDown={keyDownCallback}
        suffix={
          <SearchButton
            size="small"
            onClick={props.onSearch}
            startIcon={<Search size={"w-4"} />}
          >
            {t("common.search")}
          </SearchButton>
        }
      />
    </div>
  );
};

interface SearchBarProps<T extends FieldValues> {
  name: Path<T>;
  control: Control<T>;
  onSearch?: () => any;
  placeholder: string;
}
