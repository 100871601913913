import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export async function populateDietitianProgramSchedule(
  programId: number,
): Promise<ApiResponse<void>> {
  return await fetchData(
    `/dietitian/programs/${programId}/schedule/update`,
    APIMethods.POST,
  );
}
