import { styled } from "@mui/material";
import MuiPagination from "@mui/material/Pagination";

interface MuiPaginationProps {
  isOnePage: boolean;
}

export const StyledMuiPagination = styled(MuiPagination, {
  shouldForwardProp: propName => propName !== "isOnePage",
})<MuiPaginationProps>`
  & .MuiPagination-root.MuiPagination-text.MuiTablePagination-actions {
    margin-left: auto;
  }

  & .MuiPaginationItem-root {
    color: ${({ theme }) => theme.colors.neutral.dark[700]};

    &.Mui-disabled {
      opacity: 1;
    }
  }

  & .MuiPagination-ul {
    & li {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 2.5rem;

      & .MuiButtonBase-root {
        border-radius: 0.5rem;
        &.Mui-selected {
          background: ${({ theme }) => theme.palette.primary.medium};
        }
      }
    }

    & li:not(:first-of-type):not(:last-child) {
      background: ${({ theme }) => theme.colors.neutral.light[100]};
      border-top: 1px solid ${({ theme }) => theme.palette.primary.medium};
      border-bottom: 1px solid ${({ theme }) => theme.palette.primary.medium};
    }

    & li:nth-of-type(2) {
      border-radius: 0.5rem 0 0 0.5rem;
      border-left: 1px solid ${({ theme }) => theme.palette.primary.medium};
    }

    & li:nth-last-of-type(2) {
      border-radius: ${({ isOnePage }) =>
        isOnePage ? "0.5rem" : "0 0.5rem 0.5rem 0"};
      border-right: 1px solid ${({ theme }) => theme.palette.primary.medium};
    }
  }
`;

export const PerPageWrapper = styled("div")`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-left: 14px;
`;

export const TextStyled = styled("span")`
  font-size: 12.25px;
  line-height: 17.5px;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;
