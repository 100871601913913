import {
  useCreateScheduleEventMutation,
  useUpdateScheduleEvent,
} from "@hooks/queries";
import { PATIENTS } from "@routes";
import { MEETING_TYPE } from "@utils";
import { useNavigate } from "react-router-dom";

export const useScheduleVisitMutations = (
  id: number,
  navigateToVisit?: boolean,
) => {
  const navigate = useNavigate();
  const { mutateAsync: createScheduleEvent } = useCreateScheduleEventMutation({
    onSuccess: (d, payload) => {
      if (
        navigateToVisit &&
        payload?.meetingTypeId === MEETING_TYPE.CONSULTATION
      )
        navigate(
          `${PATIENTS}/${d?.data.patient?.id}/appointments#${d?.data.id}`,
        );
    },
  });
  const { mutateAsync: updateScheduleEvent } = useUpdateScheduleEvent(id);

  return { createScheduleEvent, updateScheduleEvent };
};
