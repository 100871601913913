import { CustomRadioLabel } from "@components/CustomRadioLabel";
import { MuiTimeDatePicker } from "@components/MuiTimeDatePicker";
import { useAppTranslation } from "@hooks";
import { Radio, RadioGroup } from "@mui/material";
import dayjs from "dayjs";
import { useMemo } from "react";
import { Control, useController } from "react-hook-form";
import { FormControlLabelStyled } from "../../EditClientPaymentsModal.styled";

export enum paymentsOptions {
  INDEFINITE = "indefinite",
  UNTIL = "until",
}
interface ClientInfoViewProps {
  control: Control<any>;
}

export const ClientPaymentsView = ({ control }: ClientInfoViewProps) => {
  const { t } = useAppTranslation();
  const {
    field: { value, onChange },
  } = useController({ control, name: "indefinite" });

  const untilList = useMemo(
    () => [
      {
        id: paymentsOptions.INDEFINITE,
        name: t("client_profile.edit.payments.indefinite"),
      },
      {
        id: paymentsOptions.UNTIL,
        name: t("client_profile.edit.payments.active_until"),
      },
    ],
    [t],
  );

  return (
    <div className="grid gap-6">
      <RadioGroup
        className="gap-2"
        value={value}
        onChange={(e, v) => onChange(v as paymentsOptions)}
      >
        {untilList.map(({ id, name }) => (
          <FormControlLabelStyled
            key={id}
            value={id}
            control={<Radio />}
            label={<CustomRadioLabel label={name} />}
          />
        ))}
      </RadioGroup>
      {value === paymentsOptions.UNTIL && (
        <MuiTimeDatePicker
          dateVersion
          name="activeUntil"
          control={control}
          minDate={dayjs().toDate()}
        />
      )}
    </div>
  );
};
