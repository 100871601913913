import {
  Button,
  styled,
  IconButton,
  DialogActions,
  AccordionSummary,
  Accordion,
  Alert,
} from "@mui/material";
import { FormTextField } from "@components/FormTextField";
import { FormCountryAutocomplete } from "@components/FormCountryAutocomplete";

export const AddClientModalWrapper = styled("div")`
  display: grid;
  gap: 1rem;
  padding: 1rem;
  width: 32rem;
`;

export const TitleWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled("h3")`
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.25rem;
`;

export const CloseButton = styled(IconButton)`
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;

export const SectionTitle = styled("p")`
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;

export const StyledDialogActions = styled(DialogActions)`
  padding: 0.75rem 1rem;
  background: ${({ theme }) => theme.palette.primary.light};
`;

export const CancelButton = styled(Button)`
  background: ${({ theme }) => theme.colors.neutral.light[100]};
  border: 1px solid ${({ theme }) => theme.palette.primary.main};

  &:hover {
    background: ${({ theme }) => theme.colors.neutral.light[100]};
  }
`;

export const PhoneRowWrapper = styled("div")`
  display: grid;
  grid-template-columns: 6.25rem 1fr;
  align-items: start;
  gap: 1rem;
`;

export const StyledFormCountryAutocomplete = styled(FormCountryAutocomplete)`
  & .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
    padding-right: 54px;
  }
`;

export const StyledAccordion = styled(Accordion)`
  box-shadow: unset;
  :before {
    display: none;
  }
`;

export const AccordionSummaryStyled = styled(AccordionSummary)`
  padding: 0;
  flex-direction: row-reverse;
  min-height: 1rem;
  color: ${({ theme }) => theme.palette.primary.main};
  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(180deg);
  }

  & .MuiAccordionSummary-content {
    margin: 0;
    margin-left: 0.5rem;
  }

  & .MuiAccordionSummary-expandIconWrapper {
    color: inherit;
  }
`;

export const AccordionContent = styled("div")`
  display: grid;
  gap: 1.5rem;
  margin-top: 1rem;
`;

export const StyledTextArea = styled(FormTextField)`
  padding: unset;

  & legend {
    display: none;
  }
`;

export const SexButtonsWrapper = styled("div")`
  display: grid;
  justify-items: start;
`;

export const StyledAlert = styled(Alert)`
  font-size: 0.625rem;
  padding: 0.2rem;
  align-items: center;
  background: ${({ theme }) => theme.palette.info.light};
  color: ${({ theme }) => theme.palette.info.main};
  line-height: 1.125rem;

  &.MuiPaper-root {
    border-radius: 0.25rem;
  }

  & .MuiAlert-icon {
    padding: 0;
  }

  & .MuiAlert-message {
    padding: 0;
  }
`;
