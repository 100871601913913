import { useEffect } from "react";
import { useForm, UseFormReturn } from "react-hook-form";

import * as yup from "yup";
import _ from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";

import { ProgramDayNutrientDto } from "@client";
import { useAppTranslation } from "@hooks";
import { ENERGY_ID } from "@views/dietician/product-views/ProductForm";
import {
  FetchPatientProgramResponse,
  FetchProgramResponse,
} from "@client/program";
import {
  ImportanceType,
  OptimizedProgramScheduleRequestBody,
  OptimizerSettingsResponse,
  SaveOptimizerSettingsRequestBody,
} from "@client/schedule";
import { Nutrient } from "@typeDefinitions";

export function useOptimizerForm(
  program?: FetchProgramResponse | FetchPatientProgramResponse,
  settings?: OptimizerSettingsResponse[],
  importantNutrients?: Nutrient[],
): UseFormReturn<OptimiserFormInput> {
  const { t } = useAppTranslation();
  const resolver = yupResolver(
    yup.lazy((value: OptimiserFormInput) =>
      yup.object().shape({
        days: yup.object().shape(
          _.mapValues(value.days, ({ nutrients }) =>
            yup
              .object()
              .shape({
                precision: yup.number().required(),
                nutrients: yup.object().shape(
                  _.mapValues(nutrients, nutrient =>
                    yup.object().shape({
                      used: yup.boolean().required(),
                      value: yup
                        .number()
                        .typeError("common.wrong_value")
                        .when("used", {
                          is: true,
                          then: schema => schema.required(),
                        }),
                      tolerance: yup
                        .number()
                        .typeError("common.wrong_value")
                        .when("used", {
                          is: true,
                          then: schema => schema.required(),
                        }),
                      distribution: yup
                        .number()
                        .typeError("common.wrong_value")
                        .when("used", {
                          is: true,
                          then: schema =>
                            schema.min(1, "common.wrong_value").required(),
                          otherwise: schema => schema.notRequired(),
                        }),
                    }),
                  ),
                ),
              })
              .test({
                message: t(
                  "patient.schedule.optimization.distribution_error_sum",
                ),
                name: SUM_ERROR,
                test: ({ nutrients }) =>
                  _(Object.values(nutrients)).sumBy("distribution") === 100,
              }),
          ),
        ),
      }),
    ),
  );

  const form = useForm<OptimiserFormInput>({ defaultValues, resolver });

  useEffect(() => {
    if (settings && program) {
      form.reset(
        _.merge(
          apiToFormInput(program, importantNutrients),
          settingsToFormInput(settings),
        ),
      );
    }
  }, [settings, importantNutrients]);

  return form;
}

function programNutrientToOptimizerNutrient(
  nutrients: ProgramDayNutrientDto[],
): OptimiserNutrientFormInput {
  const result = nutrients
    .filter(nutrient => nutrient.visible)
    .map(({ id, value }) => [
      id,
      {
        used: id === ENERGY_ID,
        value: value,
        tolerance: defaultTolerance,
        distribution:
          id === ENERGY_ID ? defaultEnergyDistribution : defaultDistribution,
      },
    ]);

  return Object.fromEntries(result);
}

function apiToFormInput(
  program: FetchProgramResponse | FetchPatientProgramResponse,
  micronutrientList?: Nutrient[],
): OptimiserFormInput {
  const { days } = program;

  return {
    days: Object.fromEntries(
      days.map(({ id, targetNutrients }) => {
        const micronutrients = micronutrientList
          ? Object.fromEntries(
              micronutrientList.map(({ id, value }) => [
                id,
                {
                  used: false,
                  value: value ?? 0,
                  tolerance: defaultTolerance,
                  distribution: defaultDistribution,
                },
              ]),
            )
          : {};

        return [
          id,
          {
            precision: defaultPrecision,
            nutrients: {
              ...programNutrientToOptimizerNutrient(targetNutrients.nutrients),
              ...micronutrients,
            },
          },
        ];
      }),
    ),
  };
}

function settingsToFormInput(
  settings: OptimizerSettingsResponse[],
): OptimiserFormInput {
  return {
    days: Object.fromEntries(
      settings.map(({ programDay, nutrients, balances }) => {
        const mealBalance = balances.find(
          ({ type }) => type.id === ImportanceType.MEALS,
        );

        const nutrientsBalance = balances.find(
          ({ type }) => type.id === ImportanceType.NUTRIENTS,
        );

        return [
          programDay.id,
          {
            precision:
              mealBalance && nutrientsBalance
                ? mealBalance.value
                : defaultPrecision,
            nutrients: Object.fromEntries([
              [
                ENERGY_ID,
                {
                  used: false,
                  value: 0,
                  distribution: 0,
                  tolerance: 0,
                },
              ],
              ...nutrients.map(
                ({ nutrient, value, distribution, tolerance }) => {
                  return [
                    nutrient.id,
                    {
                      used: true,
                      value,
                      distribution,
                      tolerance,
                    },
                  ];
                },
              ),
            ]),
          },
        ];
      }),
    ),
  };
}

export function optimizerFormToRequest({
  days,
}: OptimiserFormInput): OptimizedProgramScheduleRequestBody {
  return {
    searchOptions: Object.entries(days).map(
      ([dayId, { nutrients, precision }]) => ({
        programDay: { id: parseInt(dayId) },
        nutrients: Object.entries(nutrients)
          .filter(([_, nutrient]) => nutrient.used)
          .map(([nutrientId, nutrient]) => ({
            id: nutrientId,
            value: nutrient.value,
            distribution: nutrient.distribution,
            tolerance: nutrient.tolerance,
          })),
        balances: [
          { type: ImportanceType.MEALS, value: precision },
          { type: ImportanceType.NUTRIENTS, value: 100 - precision },
        ],
      }),
    ),
  };
}

export function optimizerFormToSettingsRequest({
  days,
}: OptimiserFormInput): SaveOptimizerSettingsRequestBody {
  return {
    settings: Object.entries(days).map(([dayId, { nutrients, precision }]) => ({
      programDay: { id: parseInt(dayId) },
      nutrients: Object.entries(nutrients)
        .filter(([_, nutrient]) => nutrient.used)
        .map(([nutrientId, nutrient]) => ({
          nutrient: { id: nutrientId },
          value: nutrient.value,
          distribution: nutrient.distribution,
          tolerance: nutrient.tolerance,
        })),
      balances: [
        { type: { id: ImportanceType.MEALS }, value: precision },
        { type: { id: ImportanceType.NUTRIENTS }, value: 100 - precision },
      ],
    })),
  };
}

interface OptimiserNutrientFormInput {
  [nutrientId: string]: {
    used: boolean;
    value: number;
    tolerance: number;
    distribution: number;
  };
}

interface OptimiserFormInput {
  days: {
    [dayId: string]: {
      precision: number;
      nutrients: OptimiserNutrientFormInput;
    };
  };
}

const defaultValues: OptimiserFormInput = { days: {} };
const defaultPrecision = 50;
const defaultTolerance = 10;
const defaultEnergyDistribution = 100;
const defaultDistribution = 0;
export const SUM_ERROR = "sum_error";
