import { useMemo } from "react";

import {
  FoodGroupResourceDto,
  SystemQuestionDto,
  UserPreferencesFoodLikedAnswerResourceDto,
} from "@client/surveys";
import { OuterLabel } from "@components/OuterLabel";
import { useAppTranslation } from "@hooks";

import { QuestionPreview } from "../../QuestionPreview/QuestionPreview";
import { StyledAnswer } from "./SystemAnswerUserPreferencesFoodLiked.styled";

interface SystemAnswerUserPreferencesFoodLikedProps {
  question: SystemQuestionDto;
  answer?: UserPreferencesFoodLikedAnswerResourceDto;
  listIndex: number;
  totalQuestions: number;
  options?: FoodGroupResourceDto[];
}

export const SystemAnswerUserPreferencesFoodLiked = ({
  answer,
  question,
  listIndex,
  totalQuestions,
  options,
}: SystemAnswerUserPreferencesFoodLikedProps) => {
  const { title, description } = question;
  const { t, isPolishChosen } = useAppTranslation();

  function filterAndKeepHierarchy(
    data: FoodGroupResourceDto[],
    idsToKeep: number[],
  ): FoodGroupResourceDto[] {
    function filterRecursive(
      items: FoodGroupResourceDto[],
    ): FoodGroupResourceDto[] {
      return items.reduce<FoodGroupResourceDto[]>((acc, item) => {
        const filteredChildren = filterRecursive(item.children);
        if (idsToKeep.includes(item.id) || filteredChildren.length > 0) {
          acc.push({ ...item, children: filteredChildren });
        }
        return acc;
      }, []);
    }

    return filterRecursive(data);
  }

  const mappedOptions = useMemo(
    () =>
      filterAndKeepHierarchy(options ?? [], answer?.answer.foodGroupsId ?? []),
    [options, answer?.answer.foodGroupsId, filterAndKeepHierarchy],
  );

  const noAnswer = answer?.answer.value === undefined;

  const renderTree = (node: FoodGroupResourceDto) => {
    const { children, description, descriptionPl, id } = node;
    const selected = answer?.answer.foodGroupsId.includes(id);
    return (
      <div key={id} className="flex flex-col">
        <StyledAnswer irrelevant={!selected}>
          {isPolishChosen ? descriptionPl : description}
        </StyledAnswer>
        {!!children.length && (
          <div className="grid ml-4">
            {children.map(child => renderTree(child))}
          </div>
        )}
      </div>
    );
  };
  return (
    <QuestionPreview
      title={title}
      description={description}
      listIndex={listIndex}
      totalQuestions={totalQuestions}
      systemQuestion
    >
      <OuterLabel label={t("questionnaires.questions.answer")}>
        <StyledAnswer>
          {noAnswer && t("questionnaires.questions.no_answer")}
          {!noAnswer && answer.answer.value
            ? t(
                "questionnaires.questions.system_user_preferences_food_unliked.yes",
              )
            : t(
                "questionnaires.questions.system_user_preferences_food_unliked.no",
              )}
        </StyledAnswer>
        {mappedOptions.map(option => renderTree(option))}
      </OuterLabel>
      {answer?.answer.description && (
        <OuterLabel
          label={t(
            "questionnaires.questions.system_user_preferences_food_unliked.comment",
          )}
        >
          <StyledAnswer>{answer.answer.description}</StyledAnswer>
        </OuterLabel>
      )}
    </QuestionPreview>
  );
};
