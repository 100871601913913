import { Checkbox, FormControlLabel, styled } from "@mui/material";

export const FormControlLabelStyled = styled(FormControlLabel)`
  padding: 0.375rem 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.palette.primary.medium};
  background: ${({ theme }) => theme.colors.neutral.light[100]};
  margin: unset;
  gap: 0.5rem;
`;

export const CheckboxStyled = styled(Checkbox)`
  margin: 0.25rem;
`;

export const LabelWrapper = styled("div")`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const Label = styled("span")`
  font-size: 0.857rem;
  line-height: 1.25rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;
