import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Tag = (props: SvgIconProps) => (
  <SvgIcon width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
    <path
      d="M0.5 1.70449V5.46377C0.5 5.89125 0.668476 6.30112 0.970225 6.60287L5.39587 11.0285C6.02452 11.6572 7.04292 11.6572 7.67156 11.0285L11.0285 7.67157C11.6572 7.04292 11.6572 6.02452 11.0285 5.39588L6.60287 0.970234C6.30112 0.668485 5.89124 0.500009 5.46377 0.500009H1.707C1.04063 0.497494 0.5 1.03813 0.5 1.70449ZM3.31632 2.50915C3.52973 2.50915 3.7344 2.59393 3.8853 2.74483C4.03621 2.89574 4.12099 3.1004 4.12099 3.31381C4.12099 3.52722 4.03621 3.73189 3.8853 3.8828C3.7344 4.0337 3.52973 4.11848 3.31632 4.11848C3.10291 4.11848 2.89824 4.0337 2.74734 3.8828C2.59644 3.73189 2.51166 3.52722 2.51166 3.31381C2.51166 3.1004 2.59644 2.89574 2.74734 2.74483C2.89824 2.59393 3.10291 2.50915 3.31632 2.50915Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
