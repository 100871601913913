import { useQuery } from "@tanstack/react-query";

import { fetchCreatorMeal, FetchMealResponse } from "@client/meals";
import { QueryOptionsTyped } from "@hooks/queries/types";
import { ApiResponse } from "@typeDefinitions";

export const fetchCreatorMealQueryKey = "fetchCreatorMealQueryKey";

export const useFetchCreatorMealQuery = (
  mealId: string,
  options?: QueryOptionsTyped<ApiResponse<FetchMealResponse>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchCreatorMealQueryKey, mealId],
    () => fetchCreatorMeal(mealId),
    options,
  );

  return {
    meal: data?.data,
    ...rest,
  };
};
