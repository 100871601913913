import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ChatboxContainer } from "./Chatbox.styled";
import { ChatboxContext, UserChatIf } from "./ChatboxContext";
import Talk from "talkjs";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";

interface ChatboxProps {
  user?: UserChatIf;
  conversationId?: string;
}
export const Chatbox = ({ user, conversationId }: ChatboxProps) => {
  const navigate = useNavigate();
  const { account } = useFetchDietitianAccountQuery();
  const { closeChat, session } = useContext(ChatboxContext);
  const html = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!session) return;
    let conversation: Talk.ConversationBuilder | null = null;
    let userId: string | null = null;

    if (user) {
      const { id, name, email } = user;

      const me = session.me;
      const other = new Talk.User({
        id,
        name,
        email,
      });

      const conversationIdToUse = [other.id, me.id].join("-");
      conversation = session.getOrCreateConversation(conversationIdToUse);
      conversation.setParticipant(other);
      conversation.setParticipant(me);
      userId = id;
    }

    if (conversationId) {
      conversation = session.getOrCreateConversation(conversationId);
      userId = conversationId
        .split("-")
        .filter(id => id !== account?.id.toString())?.[0];
    }

    const newChatbox = session.createChatbox(conversation, {
      theme: "v2_desktop_chatbox",
    });
    newChatbox.mount(html.current);

    newChatbox.onCustomConversationAction(
      "chatboxProfileOnClick",
      function (event) {
        navigate(`/patients/${userId}/info-and-services`);
      },
    );
    newChatbox.onCustomConversationAction(
      "chatHeaderProfileOnClick",
      function (event) {
        navigate(`/patients/${userId}/info-and-services`);
      },
    );

    newChatbox.onCustomConversationAction(
      "chatboxCloseOnClick",
      function (event) {
        closeChat(event.conversation.id);
        newChatbox.destroy();
      },
    );

    return () => {
      newChatbox && newChatbox.destroy();
    };
  }, [session]);

  return <ChatboxContainer ref={html} />;
};
