import { useMemo } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useMediaQuery, useTheme } from "@mui/material";

import { GroupIf, TableOfContents } from "@components/TableOfContents";
import { TableOfContentsContextProvider } from "@context/TableOfContentsContext";
import { fetchSingedSurveysQueryKey } from "@hooks/queries/surveys";
import { FetchSingedSurveysResponse } from "@client/surveys";
import { ApiResponse } from "@typeDefinitions";

import {
  QuestionnaireAnswerStyled,
  TableOfContentsWrapper,
} from "./QuestionnaireAnswer.styled";
import { ContentSelector } from "./ContentSelector";
import { useParseUrl } from "./useParseUrl";
import { ContentSelectorWrapper } from "./ContentSelector.styled";
import { AlertCard } from "./AlertCard/AlertCard";
import { ValidationContextProvider } from "./context";
import { NavigationBar } from "./NavigationBar";

interface QuestionnaireAnswerProps {
  handleShowOutro: () => void;
}
export const QuestionnaireAnswer = ({
  handleShowOutro,
}: QuestionnaireAnswerProps) => {
  const { url } = useParseUrl();
  const { breakpoints } = useTheme();
  const downSmall = useMediaQuery(`${breakpoints.down("smallNew")}`);
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData<
    ApiResponse<FetchSingedSurveysResponse>
  >([fetchSingedSurveysQueryKey, url]);

  const groups: GroupIf[] = useMemo(
    () =>
      data?.data.survey.groups.map(g => ({
        id: g.id,
        name: g.title,
        subgroups: g.sections.map(({ id, title }) => ({ id, name: title })),
      })) ?? [],
    [data?.data.survey.groups],
  );

  const firstGroupId = groups?.[0]?.id;
  const firstSubgroupId = groups?.[0]?.subgroups[0]?.id;

  const questionsMapped = useMemo(
    () =>
      data?.data.survey.groups.flatMap(g =>
        g.sections.flatMap(s =>
          s.questions.map(q => ({
            groupId: g.id,
            subgroupId: s.id,
            questionId: q.id,
            isTouched: false,
            handleSubmit: undefined,
          })),
        ),
      ) ?? [],
    [data?.data.survey.groups],
  );

  return (
    <TableOfContentsContextProvider
      defaultGroupId={firstGroupId}
      defaultSubgroupId={firstSubgroupId}
    >
      <QuestionnaireAnswerStyled>
        <ValidationContextProvider
          questions={questionsMapped}
          handleShowOutro={handleShowOutro}
        >
          {!downSmall && (
            <TableOfContentsWrapper>
              <TableOfContents
                groups={groups}
                hideIntroduction={!data?.data.survey.introduction.on}
              />
            </TableOfContentsWrapper>
          )}

          <ContentSelectorWrapper>
            <AlertCard />

            <ContentSelector />
          </ContentSelectorWrapper>

          <NavigationBar groups={groups} />
        </ValidationContextProvider>
      </QuestionnaireAnswerStyled>
    </TableOfContentsContextProvider>
  );
};
