import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const WateringCan = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      style={{ fill: "none" }}
      width="16"
      height="14"
      viewBox="0 0 16 14"
    >
      <path
        d="M0 1.3125C0 0.9375 0.125 0.625 0.375 0.375C0.625 0.125 0.9375 0 1.3125 0H14.6875C15.0625 0 15.375 0.125 15.625 0.375C15.875 0.625 16 0.9375 16 1.3125C16 1.625 15.9062 1.89583 15.7188 2.125L10.5 8.53125V13C10.5 13.2708 10.4062 13.5104 10.2188 13.7188C10.0104 13.9062 9.77083 14 9.5 14C9.25 14 9.04167 13.9167 8.875 13.75L5.96875 11.5C5.65625 11.2292 5.5 10.8958 5.5 10.5V8.53125L0.28125 2.125C0.09375 1.89583 0 1.625 0 1.3125ZM1.71875 1.5L6.84375 7.78125C6.94792 7.92708 7 8.08333 7 8.25V10.375L9 11.9688V8.25C9 8.08333 9.05208 7.92708 9.15625 7.78125L14.2812 1.5H1.71875Z"
        fill="#7448D0"
      />
    </SvgIcon>
  );
};
