import CloseDrawerActionButton from "@components/ProgramSearchPreview/Components/ActionButton/CloseDrawerActionButton";

type ProgramActionButtonsProps = {
  programId: number;
};

const ProgramActionButtons = ({ programId }: ProgramActionButtonsProps) => {
  return (
    <>
      <CloseDrawerActionButton />
    </>
  );
};

export default ProgramActionButtons;
