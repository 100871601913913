import { FormTextField } from "@components/FormTextField";
import { TabPanel } from "@mui/lab";
import { Button, Drawer, styled } from "@mui/material";

export const StyledDrawer = styled(Drawer)`
  & .MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    margin-top: 56px;
    max-height: calc(100% - 56px);
    width: 700px;
  }
`;

export const Header = styled("div")`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.neutral.light[100]};
`;

export const Title = styled("span")`
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const Body = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0px 16px 24px 16px;
  overflow-y: auto;
`;

export const ButtonStyled = styled(Button)`
  display: flex;
  gap: 8px;
`;

export const FormTextFieldStyled = styled(FormTextField)`
  width: 320px;
`;

export const TabPanelStyled = styled(TabPanel)`
  padding: 0;
`;
