import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { Currencies } from "@utils/scheduleEvent";
import * as yup from "yup";
import { ClinicIdDto, clinicIdSchema } from "./common";
import {
  ClinicFacilityDto,
  ClinicProductDto,
  DietitianDto,
  MeetingModeDto,
  MeetingTypeDto,
  PriceDto,
  StatusDto,
  clinicFacilitySchema,
  clinicProductSchema,
  dietitianSchema,
  meetingModeSchema,
  meetingTypeSchema,
  priceSchema,
  statusSchema,
} from "./fetchScheduleEvent";

export const createScheduleEvent = async (
  payload: CreateScheduleEventRequest,
): Promise<ApiResponse<CreateScheduleEventResponse>> => {
  const response = await fetchData(
    `/dietitian/schedules`,
    APIMethods.POST,
    payload,
  );

  return apiResponse.validate(response);
};

export interface CreateScheduleEventRequest {
  meetingTypeId: number;
  meetingModeId: number | null;
  dietitianId: number;
  patientId: number;
  title: string;
  dateStart: string;
  dateEnd: string;
  isAllDay: number;
  additionalInfo: string | null;
  clinicFacilityId: number | null;
  clinicProductId: number | null;
  price: {
    value: number;
    currency: Currencies;
  } | null;
  notification: boolean | null;
}

export interface CreateScheduleEventResponse {
  id: number;
  dietitian: DietitianDto;
  patient: PatientDto | null;
  clinic: ClinicIdDto;
  status: StatusDto;
  title: string;
  dateStart: string;
  dateEnd: string;
  isAllDay: boolean;
  additionalInfo: string | null;
  meetingType: MeetingTypeDto | null;
  meetingMode: MeetingModeDto | null;
  clinicFacility: ClinicFacilityDto | null;
  clinicProduct: ClinicProductDto | null;
  price: PriceDto | null;
}

interface PatientDto {
  id: number;
  firstName: string;
  lastName: string;
  email: string | null;
  phone: string | null;
}

const patientSchema = yup.object().shape({
  id: yup.number().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().nullable().default(null),
  phone: yup.string().nullable().default(null),
});

const createScheduleEventSchema = yup.object().shape({
  id: yup.number().required(),
  dietitian: dietitianSchema.required(),
  patient: patientSchema.nullable().default(null),
  clinic: clinicIdSchema.required(),
  status: statusSchema.required(),
  title: yup.string().required(),
  dateStart: yup.string().required(),
  dateEnd: yup.string().required(),
  isAllDay: yup.boolean().required(),
  additionalInfo: yup.string().nullable().default(null),
  meetingType: meetingTypeSchema.nullable().default(null),
  meetingMode: meetingModeSchema.nullable().default(null),
  clinicFacility: clinicFacilitySchema.nullable().default(null),
  clinicProduct: clinicProductSchema.nullable().default(null),
  price: priceSchema.nullable().default(null),
});

const apiResponse = yup.object().shape({
  data: createScheduleEventSchema.required(),
});
