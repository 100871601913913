import { styled } from "@mui/material";

export const ContentWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5rem;
  position: sticky;
  top: 1rem;
`;
