import {
  MonitoringDayDto,
  fetchMonitoringProgramDayCalendar,
  fetchMonitoringScheduleCalendar,
} from "@client";
import { useQuery } from "@tanstack/react-query";
import { QueryOptionsTyped } from "./types";
import { ApiResponse } from "@typeDefinitions";

export const fetchMonitoringProgramDayCalendarQueryKey =
  "fetchMonitoringProgramDayCalendarQueryKey";

export const useFetchMonitoringProgramDayCalendarQuery = (
  patientId: string,
  from: string,
  to: string,
  options?: QueryOptionsTyped<ApiResponse<MonitoringDayDto[]>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchMonitoringProgramDayCalendarQueryKey, patientId, from, to],
    () => fetchMonitoringProgramDayCalendar(patientId, from, to),
    options,
  );

  return { programDayDays: data?.data, ...rest };
};

export const fetchMonitoringScheduleCalendarQueryKey =
  "fetchMonitoringScheduleCalendarQueryKey";

export const useFetchMonitoringScheduleCalendarQuery = (
  patientId: string,
  from: string,
  to: string,
  options?: QueryOptionsTyped<ApiResponse<MonitoringDayDto[]>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchMonitoringScheduleCalendarQueryKey, patientId, from, to],
    () => fetchMonitoringScheduleCalendar(patientId, from, to),
    options,
  );

  return { scheduleDays: data?.data, ...rest };
};
