import { Button, DialogActions } from "@mui/material";
import { useAppTranslation } from "@hooks";
import type { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useWatch } from "react-hook-form";

interface SchduleModalActionButtonsProps {
  onCloseWithoutSubmit: () => void;
}

export const ScheduleModalActionButtons: FC<SchduleModalActionButtonsProps> = ({
  onCloseWithoutSubmit,
}) => {
  const { t } = useAppTranslation();
  const {
    formState: { isDirty, isValid, isSubmitting },
  } = useFormContext();

  const isSubmitButtonDisabled = !(isDirty && isValid) || isSubmitting;

  return (
    <DialogActions className="mt-[24px]">
      <Button onClick={onCloseWithoutSubmit} color="primary" variant="outlined">
        {t("common.cancel")}
      </Button>
      <Button
        disabled={isSubmitButtonDisabled}
        type="submit"
        color="primary"
        variant="contained"
        aria-hidden={false}
      >
        {t("common.save")}
      </Button>
    </DialogActions>
  );
};
