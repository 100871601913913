import * as yup from "yup";

import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";

export const fetchClinicClientsStatistics = async (): Promise<
  ApiResponse<FetchClinicClientsStatisticsResponse>
> => {
  const response = await fetchData(
    "/dietitian/clinic/patients/statistics",
    APIMethods.GET,
  );
  await fetchClinicClientsStatisticsResponseSchema.resolve(response);

  return response;
};

export interface FetchClinicClientsStatisticsResponse {
  all: number;
  active: number;
}

const fetchClinicClientsStatisticsSchema = yup.object().shape({
  all: yup.number().required(),
  active: yup.number().required(),
});
const fetchClinicClientsStatisticsResponseSchema = yup.object().shape({
  data: fetchClinicClientsStatisticsSchema.required(),
});
