import {
  Autocomplete,
  Button,
  IconButton,
  TextField,
  styled,
} from "@mui/material";

export const AutocompleteStyled = styled(Autocomplete)`
  width: 376px;
` as typeof Autocomplete;

export const MeasuresTextField = styled(TextField)`
  & fieldset {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right-width: 0.5px;
  }
`;

export const AmountTextField = styled(TextField)`
  width: 120px;
  &
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall.MuiInputBase-adornedStart.MuiInputBase-adornedEnd {
    padding: 0 4px;
  }

  & fieldset {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left-width: 0.5px;
  }
`;

export const GramsTextField = styled(TextField)`
  width: 88px;
`;

export const SquareIconButton = styled(IconButton)`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.primary.medium};
`;
