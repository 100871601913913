import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "../types";
import { postPatientSendSurvey } from "@client/surveys";
import { fetchClientEventsQueryKey } from "../useFetchClientEventsQuery";

export const usePostPatientSendSurveyMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, PostPatientSendSurveyMutationPayload>(
    ({ patientId, surveyId }) => postPatientSendSurvey(patientId, surveyId),
    {
      ...options,
      onSuccess: (data, variables) => {
        options?.onSuccess && options.onSuccess(data, variables);
        queryClient.invalidateQueries([fetchClientEventsQueryKey]);
      },
    },
  );
};

interface PostPatientSendSurveyMutationPayload {
  patientId: string;
  surveyId: number;
}
