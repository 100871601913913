import { TranslationDto } from "@client";
import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const updateCreatorMeal = async (
  mealId: string,
  payload: UpdateCreatorMealRequest,
  version: number,
) => {
  return await fetchData(
    `/dietitian/creator/meals/${mealId}`,
    APIMethods.PUT,
    payload,
    undefined,
    { "Alloweat-Creator-Version": `${version}` },
  );
};

export interface UpdateCreatorMealRequest {
  translations: TranslationDto[];
  reviewed: boolean;
}
