import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { LOGIN } from "@routes";
import { CheckRound } from "@assets/icons";
import { Button } from "@components/Button";
import { useRegisterMutation } from "@hooks/queries";
import { RegisterForm } from "@components/forms/RegisterForm";

/**
 * View for registration
 * @route /auth/register
 */
export const Register = () => {
  const { t } = useTranslation();
  const { register, isLoading, isSuccess } = useRegisterMutation({
    onError: () => toast.error(t("auth.register.api_error")),
  });

  if (isSuccess)
    return (
      <div
        className="bg-white rounded-md shadow-paper p-7 flex flex-col justify-center items-center px-48 mt-10"
        style={{ minWidth: 900 }}
      >
        <CheckRound size="w-20 h-auto" className="mb-8" />
        <h3 className="text-xl">{t("auth.register.account_activated")}</h3>
        <Button fontLight className="mt-8 min-w-1/2" size="large" to={LOGIN}>
          {t("auth.register.back_to_login")}
        </Button>
      </div>
    );

  return (
    <div
      className="bg-white rounded-md shadow-paper p-7 flex flex-col justify-center items-center px-48 mt-10 mb-10"
      style={{ minWidth: 900 }}
    >
      <img
        src="/static/Logo@2x.png"
        srcSet="/static/Logo@2x.png"
        className="sm:h-10 h-6 mb-6"
        alt="Alloweat logo"
      />

      <h3 className="text-xl">{t("auth.register.title")}</h3>

      <RegisterForm onSubmit={register} isLoading={isLoading} />
    </div>
  );
};
