export const calculateForecast = (
  difference: number,
  daysCount: number,
  clientWeight: number,
) => {
  const differenceForDays = difference * daysCount;
  const differenceInKg = differenceForDays / ONE_KG_IN_KCAL;
  const differenceInPercentage = (differenceInKg / (clientWeight || 1)) * 100;

  return {
    differenceForDays,
    differenceInKg,
    differenceInPercentage,
  };
};

export const calculateMassDifference = (
  weight: undefined | null | number,
  targetWeight: undefined | null | number,
) => {
  if (targetWeight) {
    return targetWeight - (weight ?? 0);
  } else {
    return null;
  }
};

export const ONE_KG_IN_KCAL = 7700;
