import { useParams } from "react-router-dom";
import { useMemo } from "react";

export type PatientProgramSummaryParams = {
  patientId: string;
  programId: string;
};

export const usePatientProgramSummaryParams = () => {
  const { patientId, programId } = useParams<PatientProgramSummaryParams>();
  const programIdParsed = useMemo(() => parseInt(programId ?? ""), [programId]);
  const patientIdParsed = useMemo(() => parseInt(patientId ?? ""), [patientId]);

  return { patientId: patientIdParsed, programId: programIdParsed };
};
