import { Autocomplete } from "@mui/material";

import { OuterLabel } from "@components/OuterLabel";
import { Measure } from "@typeDefinitions";
import { decimalInput1ThreeDigits } from "@utils/inputs";
import { StepsArrowButtons } from "@components/StepsArrowButtons";

import {
  AmountTextField,
  ArrowsWrapper,
  MeasuresTextField,
  MesureGrams,
} from "./MeasureInput.styled";
import { useMeasureInput } from "./useMeasureInput";
import { useAppTranslation } from "@hooks";
import { MeasureExtended } from "../../ProductsContext";

interface MeasureInputProps {
  label?: boolean;
  measures?: MeasureExtended[];
  formIdx: number;
}

export const MeasureInput = ({
  label,
  measures,
  formIdx,
}: MeasureInputProps) => {
  const { t } = useAppTranslation();
  const {
    setMeasureId,
    amount,
    filteredMeasures,
    selectedMeasure,
    handleAmountChange,
    handleBlur,
  } = useMeasureInput({ measures, formIdx });

  return (
    <div className="w-[175px] flex">
      <OuterLabel
        label={label && t("diet.recipe_sidepanel.measure")}
        className="flex-1"
      >
        <Autocomplete
          key={selectedMeasure?.id}
          size="small"
          fullWidth
          options={filteredMeasures}
          disableClearable
          value={selectedMeasure}
          onChange={(e, v) => setMeasureId(Number(v.id))}
          renderInput={props => <MeasuresTextField {...props} />}
          renderOption={(props, option) => (
            <li {...props} key={option.id} className="grid p-[6px] gap-[4px]">
              <span className="flex-1">{option.label}</span>
              <MesureGrams>({option.grams} g)</MesureGrams>
            </li>
          )}
        />
      </OuterLabel>
      <OuterLabel label={label && t("diet.recipe_sidepanel.amount")}>
        <div className="relative">
          <AmountTextField
            size="small"
            value={amount}
            InputProps={{ inputComponent: decimalInput1ThreeDigits }}
            onChange={e => handleAmountChange(Number(e.target.value))}
            onBlur={handleBlur}
          />

          <ArrowsWrapper className="arrows">
            <StepsArrowButtons
              clickTop={() => handleAmountChange(Math.floor(amount + 1))}
              clickDown={() =>
                handleAmountChange(Math.max(0, Math.ceil(amount - 1)))
              }
            />
          </ArrowsWrapper>
        </div>
      </OuterLabel>
    </div>
  );
};
