import { fetchData } from "@utils/api";
import { ApiEndpoints, APIMethods } from "@typeDefinitions";
import { SuggestedTasksQueryParams } from "./fetchSuggestedTasksList";
import qs from "qs";

export const fetchSuggestedTasksCount = async (
  params?: SuggestedTasksQueryParams,
) => {
  const queryParams = qs.stringify(params);

  const response = await fetchData<SuggestedTasksCountResponse>(
    `${ApiEndpoints.SuggestedTasksCount}?${queryParams}`,
    APIMethods.GET,
  );

  return response;
};

interface SuggestedTasksData {
  count: number;
}

interface SuggestedTasksCountResponse {
  data: SuggestedTasksData;
}
