import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import * as yup from "yup";
import { validateAndFixNutrients } from "./utils/validateCatching";

export const fetchNormNutrients = async (
  id: number,
): Promise<ApiResponse<NormNutrientDto[]>> => {
  const response = await fetchData(
    `/dietitian/nutrients/norm/age/${id}/values`,
    APIMethods.GET,
  );
  return await validateAndFixNutrients(
    fetchNormNutrientsResponseSchema,
    response,
  );
};

export interface NormNutrientDto {
  id: number;
  value: number;
}

const normNutrientSchema = yup.object().shape({
  id: yup.number().required(),
  value: yup.number().required(),
});

const fetchNormNutrientsResponseSchema = yup.object().shape({
  data: yup.array().of(normNutrientSchema),
});
