import { useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { QueryOptionsTyped } from "../types";
import { buildDefaultQueryOptions } from "../common";
import { ApiResponse } from "@typeDefinitions";
import {
  ClinicClientsFiltersInput,
  ClinicClientsSortInput,
} from "@typeDefinitions/types";
import {
  handleCheckFilters,
  parseNumber,
  parseString,
  parseStringArray,
} from "@utils/filters";
import { useSearchParams } from "react-router-dom";
import { ClinicClientDtoNew, searchClientsNew } from "@client/settings";

export const searchClientsQueryKey = "searchClientsQueryKey";

export const useSearchClientsQuery = (
  page: number,
  perPage: number,
  options?: QueryOptionsTyped<ApiResponse<ClinicClientDtoNew[]>>,
) => {
  const [committedInputs, setCommittedInputs] =
    useState<ClinicClientsFiltersInput>();
  const [sorting, setSorting] = useState<ClinicClientsSortInput>();

  const params = useMemo(
    () => parseClinicClientsFilters(committedInputs, sorting),
    [committedInputs, sorting],
  );

  const [_, setQueryParams] = useSearchParams();

  useEffect(() => {
    setQueryParams(params);
  }, [params]);

  const queryKey = [searchClientsQueryKey, params.toString(), page, perPage];

  const { data, ...rest } = useQuery(
    queryKey,
    () => {
      const paginated = new URLSearchParams(params);
      paginated.append("page", (page + 1).toString());
      paginated.append("perPage", perPage.toString());
      return searchClientsNew(paginated);
    },
    buildDefaultQueryOptions(options),
  );

  return {
    clients: data?.data,
    meta: data?.meta,
    queryKey,
    submit: setCommittedInputs,
    submitSort: setSorting,
    hasSubmittedFilters: handleCheckFilters(committedInputs),
    ...rest,
  };
};

const parseClinicClientsFilters = (
  filters?: ClinicClientsFiltersInput,
  sort?: ClinicClientsSortInput,
) => {
  const search = new URLSearchParams();

  parseStringArray(filters?.dietitians, "dietitians", search);
  parseStringArray(filters?.programs, "programs", search);
  parseNumber(filters?.appointment, "appointment", search);
  parseNumber(filters?.activeMobileApp, "activeMobileApp", search);
  parseStringArray(filters?.customTags, "customTags", search);

  if (filters?.search?.trim()) {
    parseString(filters?.search, "search", search);
  }

  parseString(filters?.client, "client", search);
  parseString(sort?.orderDir, "orderDir", search);
  parseString(sort?.orderBy, "orderBy", search);

  return search;
};
