import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import { t } from "i18next";

import { useProgramForm } from "@hooks";
import {
  useCreateProgramMutation,
  useUpdateProgramMutation,
} from "@hooks/queries";
import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";
import { EditorHeaderReviewedToggle } from "@components/EditorHeaderReviewedToggle";
import { SingleCardPageLayout } from "@components/PageLayout";
import { ProgramInfoNavigation } from "@components/forms/ProgramInfoNavigation";
import {
  DurationSelect,
  LanguageSelect,
  ProgramNameSelect,
  SectionsSelect,
} from "@views/dietician/ProgramForm/components";

export const ProgramNew = () => {
  const methods = useProgramForm();
  const navigate = useNavigate();
  const [programId, setProgramId] = useState<number | undefined>();

  const { mutate, isLoading, isError } = useCreateProgramMutation({
    onSuccess: data => data && setProgramId(data?.id),
  });

  const { mutate: updateProgram, isLoading: isUpdateLoading } =
    useUpdateProgramMutation(programId ?? 0, {
      onSuccess: () => navigate(`/programs/${programId}/days`),
    });

  useEffect(() => mutate(), []);

  const submit = useCallback(
    methods.handleSubmit(d =>
      updateProgram({
        name: d.namePl,
        nameEn: d.nameEn,
        hasDiets: d.workModel === "diets",
        hasMacros: d.workModel === "macros",
        hasDiary: d.hasDiary,
        hasMonitoring: d.hasMonitoring,
        hasEducation: d.hasEducation,
        hasTasks: d.hasTasks,
        hasShoppingList: d.hasShoppingList,
        hasChat: d.hasChat,
        hasNewsfeed: d.hasNewsfeed,
        showCals: d.showCals,
        showMacros: d.showMacros,
        showNutrients: d.showMicros,
        sections: d.sectionIds,
        durationDays: d.duration,
        reviewed: !!d.reviewed,
        langId: d.programLanguage,
      }),
    ),
    [mutate],
  );

  if (isLoading) return <Spinner className="h-52" />;

  if (isError || !programId) return <ApiError />;

  return (
    <>
      <FormProvider {...methods}>
        <SingleCardPageLayout
          title={t("programs.create_new_program")}
          extra={<EditorHeaderReviewedToggle name={"reviewed"} />}
        >
          <div className="flex flex-col gap-y-7 p-7">
            <ProgramNameSelect />
            <LanguageSelect name="programLanguage" />
            <DurationSelect />
            <SectionsSelect programId={programId} />
          </div>
        </SingleCardPageLayout>
      </FormProvider>

      <ProgramInfoNavigation
        onBack={() => navigate(-1)}
        onSubmit={submit}
        isSubmitting={isUpdateLoading}
      />
    </>
  );
};
