import * as yup from "yup";

import {
  IdDto,
  idSchema,
  MeasureDto,
  measureSchema,
  NutrientDto,
  nutrientSchema,
  TranslationDto,
  translationSchema,
} from "@client/common";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const fetchCreatorDietMealProduct = async (
  dietId: string,
  mealId: string,
  productId: string,
  version: string,
): Promise<ApiResponse<CreatorDietMealProductDto>> => {
  const response = await fetchData(
    `/dietitian/creator-2/diets/${dietId}/meals/${mealId}/products/${productId}`,
    APIMethods.GET,
    undefined,
    undefined,
    { "Alloweat-Creator-Version": version },
  );

  return responseSchema.validate(response);
};

export interface CreatorDietMealProductDto {
  id: number;
  grams: number;
  measure: IdDto;
  food: FoodDetailsDto;
}

interface FoodDetailsDto {
  id: number;
  name: string;
  translations: TranslationDto[];
  measures: MeasureDto[];
  nutrients: NutrientDto[];
  foodGroup: IdDto;
  producer: string | null;
}

const foodDetailsSchema = yup.object({
  id: yup.number().required(),
  name: yup.string().required(),
  translations: yup.array().of(translationSchema).required(),
  measures: yup.array().of(measureSchema).required(),
  nutrients: yup.array().of(nutrientSchema).required(),
  foodGroup: idSchema.required(),
  producer: yup.string().nullable().default(null),
});

const creatorDietMealProductSchema = yup.object({
  id: yup.number().required(),
  grams: yup.number().required(),
  measure: idSchema.required(),
  food: foodDetailsSchema.required(),
});

const responseSchema = yup.object({
  data: creatorDietMealProductSchema.required(),
});
