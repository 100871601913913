import { useQuery } from "@tanstack/react-query";

import { QueryOptions } from "./types";
import { buildDefaultQueryOptions } from "./common";
import { fetchRecipe } from "@client";

export const fetchRecipeQueryKey = "fetchRecipeQuery";

export function useFetchRecipeQuery(id: number, options?: QueryOptions) {
  const { data: recipe, ...rest } = useQuery(
    [fetchRecipeQueryKey, id],
    () => fetchRecipe(id),
    buildDefaultQueryOptions(options),
  );

  return { recipe, ...rest };
}
