import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const IceCream = (props: SvgIconProps) => {
  return (
    <SvgIcon width="10" height="14" viewBox="0 0 10 14">
      <path
        d="M8.6334 4.5625C8.64863 4.42793 8.65625 4.29336 8.65625 4.15625C8.65625 2.1377 7.01855 0.5 5 0.5C2.98145 0.5 1.34375 2.1377 1.34375 4.15625C1.34375 4.29336 1.35137 4.42793 1.3666 4.5625H1.34375C0.670898 4.5625 0.125 5.1084 0.125 5.78125C0.125 6.4541 0.670898 7 1.34375 7H2.70215H7.29785H8.65625C9.3291 7 9.875 6.4541 9.875 5.78125C9.875 5.1084 9.3291 4.5625 8.65625 4.5625H8.6334ZM1.75 7.8125L4.41094 13.1369C4.52266 13.3604 4.74863 13.5 5 13.5C5.25137 13.5 5.47734 13.3604 5.58906 13.1369L8.25 7.8125H1.75Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
