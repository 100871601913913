import { useCallback, useMemo } from "react";
import { FormProvider, useController } from "react-hook-form";

import { LoadingButton } from "@mui/lab";
import { Button, ClickAwayListener, IconButton } from "@mui/material";

import { Close } from "@assets/icons/DesignSystem";
import { Nutrients } from "@components/Nutrients";
import { useAppParams, useAppTranslation, useNutrients } from "@hooks";

import { Label } from "../MealScheduleCard/ScheduleRow";
import { NormAutocomplete } from "./NormAutocomplete";
import { NormNutrientRow } from "./NormNutrientRow";
import { Body, Footer, Header, StyledDrawer, Title } from "./SidePanel.styled";
import {
  NutrientNormInputs,
  mapNutrientsNormForm,
  mapNutrientsNormRequest,
  useNutrientsNormForm,
} from "./useNutrientsNormForm";
import {
  useFetchDietNutrientsNormQuery,
  useUpdateDietNutrientsNormCombinedMutation,
} from "../../hooks";

interface SidePanelProps {
  open: boolean;
  onClose: () => void;
}

export const SidePanel = ({ open, onClose }: SidePanelProps) => {
  const { t } = useAppTranslation();
  const { dietId } = useAppParams();
  const { norm } = useFetchDietNutrientsNormQuery(dietId);
  const { mutate, isLoading } = useUpdateDietNutrientsNormCombinedMutation();
  const { nutrientDict } = useNutrients();

  const mappedNormForm = useMemo(
    () => mapNutrientsNormForm(norm, Array.from(nutrientDict.keys())),
    [norm, nutrientDict],
  );
  const getNutrientIdx = useCallback(
    (nutrientId: number) =>
      mappedNormForm?.nutrients.findIndex(n => n.id === nutrientId) ?? 0,
    [mappedNormForm],
  );

  const form = useNutrientsNormForm(mappedNormForm);

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController<NutrientNormInputs, "normId">({
    name: "normId",
    control: form.control,
  });

  const handleOnClose = () => {
    form.reset();
    onClose();
  };

  const handleClickAway = () => open && handleOnClose();

  const handleSubmit = () => {
    form.handleSubmit(
      v =>
        mutate(
          { dietId, payload: mapNutrientsNormRequest(v) },
          { onSuccess: onClose },
        ),
      e => console.log(e),
    )();
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <StyledDrawer
          open={open}
          variant="persistent"
          anchor="right"
          onClose={handleOnClose}
        >
          <Header>
            <Title>{t("diet.other_nutrients")}</Title>

            <IconButton size="small" onClick={handleOnClose}>
              <Close size="w-[12px] h-[12px]" />
            </IconButton>
          </Header>

          <FormProvider {...form}>
            <Body>
              <Label label={t("diet.select_norm")}>
                <NormAutocomplete
                  value={value}
                  onChange={onChange}
                  error={error}
                />
              </Label>

              <Nutrients
                values={[]}
                NutrientRowComponent={props => (
                  <NormNutrientRow {...props} idx={getNutrientIdx(props.id)} />
                )}
              />
            </Body>
          </FormProvider>

          <Footer>
            <Button
              variant="outlined"
              onClick={handleOnClose}
              disabled={isLoading}
            >
              {t("common.cancel")}
            </Button>
            <LoadingButton
              variant="contained"
              onClick={handleSubmit}
              loading={isLoading}
            >
              {t("common.save")}
            </LoadingButton>
          </Footer>
        </StyledDrawer>
      </div>
    </ClickAwayListener>
  );
};
