import { LoadingButton } from "@mui/lab";
import { styled, useTheme } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";

import CheckFilledIcon from "@assets/icons_new/checkFilled.svg?react";
import DashedCircleIcon from "@assets/icons_new/dashedCircle.svg?react";
import { useAppParams, useAppTranslation } from "@hooks";
import { fetchPatientProgramQueryKey } from "@hooks/queries";
import { useChangeSharedProgramMutation } from "@hooks/queries/client/program/useChangeSharedProgramMutation";

interface ShowInAppButtonProps {
  shared: boolean;
}
export const ShowInAppButton = ({ shared }: ShowInAppButtonProps) => {
  const queryClient = useQueryClient();
  const { t } = useAppTranslation();
  const { patientId, programId } = useAppParams();
  const { colors, palette } = useTheme();
  const { mutate, isLoading } = useChangeSharedProgramMutation({
    onSuccess: () => {
      queryClient.invalidateQueries([
        fetchPatientProgramQueryKey,
        patientId,
        programId,
      ]);
    },
  });

  const handleToggle = () => {
    mutate({
      patientId: Number(patientId),
      programId: Number(programId),
      payload: { shared: !shared },
    });
  };

  if (shared)
    return (
      <LoadingButtonStyled loading={isLoading} onClick={handleToggle}>
        <CheckFilledIcon color={palette.success.main} />
        {t("program_list.shown_in_app")}
      </LoadingButtonStyled>
    );

  return (
    <LoadingButtonStyled loading={isLoading} onClick={handleToggle}>
      <DashedCircleIcon color={colors.neutral.medium[400]} />
      {t("program_list.hidden_in_app")}
    </LoadingButtonStyled>
  );
};

const LoadingButtonStyled = styled(LoadingButton)`
  display: flex;
  gap: 4px;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;
