import { RadioGroup, styled } from "@mui/material";

export const OptionsWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding-left: 1rem;
`;

export const Label = styled("span")`
  font-weight: 600;
  font-size: 1.143rem;
  line-height: 1.429rem;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const RadioGroupStyled = styled(RadioGroup)`
  padding-left: 0.375rem;
`;
