import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const ScalesWithPercent = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      style={{ fill: "none" }}
      width="21"
      height="21"
      viewBox="0 0 21 21"
    >
      <path
        d="M8.75 19.25H12.25C16.625 19.25 18.375 17.5 18.375 13.125V7.875C18.375 3.5 16.625 1.75 12.25 1.75H8.75C4.375 1.75 2.625 3.5 2.625 7.875V13.125C2.625 17.5 4.375 19.25 8.75 19.25Z"
        stroke="#801FFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M15.0938 7.25355C13.8278 6.12896 12.1933 5.50781 10.5 5.50781C8.80668 5.50781 7.17219 6.12896 5.90625 7.25355L7.81375 10.3161C8.5532 9.65673 9.5093 9.29238 10.5 9.29238C11.4907 9.29238 12.4468 9.65673 13.1862 10.3161L15.0938 7.25355Z"
        stroke="#801FFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M8.47378 16.4988L12.2524 12.5012M8.71067 13.5037C8.89915 13.5037 9.07991 13.4245 9.21318 13.2835C9.34646 13.1425 9.42133 12.9512 9.42133 12.7518C9.42133 12.5524 9.34646 12.3612 9.21318 12.2202C9.07991 12.0792 8.89915 12 8.71067 12C8.52219 12 8.34143 12.0792 8.20815 12.2202C8.07487 12.3612 8 12.5524 8 12.7518C8 12.9512 8.07487 13.1425 8.20815 13.2835C8.34143 13.4245 8.52219 13.5037 8.71067 13.5037ZM12.4893 17C12.6778 17 12.8586 16.9208 12.9919 16.7798C13.1251 16.6388 13.2 16.4476 13.2 16.2482C13.2 16.0488 13.1251 15.8575 12.9919 15.7165C12.8586 15.5755 12.6778 15.4963 12.4893 15.4963C12.3009 15.4963 12.1201 15.5755 11.9868 15.7165C11.8535 15.8575 11.7787 16.0488 11.7787 16.2482C11.7787 16.4476 11.8535 16.6388 11.9868 16.7798C12.1201 16.9208 12.3009 17 12.4893 17Z"
        stroke="#801FFF"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
