import { useAppTranslation } from "@hooks";
import { useTheme } from "@mui/material";
import { purple } from "@mui/material/colors";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import { CustomTooltip } from "./CustomTooltip";
import {
  simulationChartMapper,
  SimulationChartMapperProps,
} from "./simulationChartMapper";

interface SimulationChartProps {
  data: SimulationChartMapperProps[];
  targetWeight: number | null;
}

export const SimulationChart = ({
  data,
  targetWeight,
}: SimulationChartProps) => {
  const { fontFamily, colors } = useTheme();
  const { t } = useAppTranslation();
  const chartData = data.map(simulationChartMapper);

  return (
    <ResponsiveContainer width={"99%"} height={260}>
      <LineChart
        data={chartData}
        margin={{
          top: 6,
          right: 3.5,
        }}
      >
        <CartesianGrid />
        <Legend />
        <XAxis
          dataKey="name"
          fontSize={12}
          fontFamily={fontFamily.regular}
          label={{
            value: t("balance.weeks"),
            position: "insideBottomRight",
            fontSize: "12px",
            fontFamily: fontFamily.regular,
            offset: -3.5,
          }}
        />
        <YAxis
          unit=" kg"
          fontSize={12}
          color="#fff"
          fontFamily={fontFamily.regular}
          label={{
            value: t("common.bodyWeight"),
            angle: -90,
            position: "left",
            fontSize: "12px",
            fontFamily: fontFamily.regular,
            offset: -5,
          }}
          domain={["auto", "auto"]}
        />
        <Tooltip
          content={<CustomTooltip />}
          wrapperStyle={{ outline: "none" }}
        />
        <Line
          dataKey="kgSimulation"
          name={t("balance.simulation")}
          stroke={colors.yellow}
          activeDot={{ r: 8 }}
          dot={{ r: 0 }}
        />
        <Line
          dataKey="kg"
          name={t("common.bodyWeight")}
          stroke={purple[500]}
          activeDot={{ r: 8 }}
          dot={{ r: 0 }}
        />
        {targetWeight && (
          <ReferenceLine y={targetWeight} stroke={colors.gray500} />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};
