import { useQuery } from "@tanstack/react-query";
import { fetchSingleTask } from "@client/tasks/fetchSingleTask";
import { ApiEndpoints } from "@typeDefinitions";
import { AxiosError } from "axios";
import { useAppTranslation } from "@hooks/useAppTranslation";

enum ResponseStatus {
  NotFound = 404,
}

export const useSingleTaskQuery = (id: string) => {
  const { t } = useAppTranslation();

  return useQuery({
    queryKey: [ApiEndpoints.Tasks, id],
    queryFn: async () => {
      const response = await fetchSingleTask(id);

      if (!response) return null;

      return response.data;
    },
    onError: error => {
      if (
        error instanceof AxiosError &&
        error.response?.status === ResponseStatus.NotFound
      ) {
        console.error(t("tasks.taskNotFound"));
      }
    },
    enabled: !!id,
  });
};
