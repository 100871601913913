import React from "react";

import { Button } from "@mui/material";

import { useAppTranslation } from "@hooks";
import { Download } from "@assets/icons";
import { useDuplicatePreviousVisitDataMutation } from "@hooks/queries/visits";

export interface VisitActionProps {
  patientId: number;
  visitId: number;
}

export const VisitAction = ({ patientId, visitId }: VisitActionProps) => {
  const { t } = useAppTranslation();
  const { mutate } = useDuplicatePreviousVisitDataMutation(patientId, visitId);

  const handleDataImport = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    mutate();
  };

  return (
    <Button
      startIcon={<Download />}
      variant="text"
      color="primary"
      size="small"
      onClick={handleDataImport}
      className="text-primary -my-0.5"
    >
      {t("visits.import_last")}
    </Button>
  );
};
