import { SectionProgramPreviewSchema } from "@client/program/preview/fetchSectionsProgramPreview";
import _ from "lodash";
import { LanguagesSlugs } from "@typeDefinitions";
import { ProgramSectionCategory } from "@client/dictionaries/fetchProgramSectionCategoriesDictionary";

export const filterSystemProgramSections = (
  sections: SectionProgramPreviewSchema[],
  isSystem: boolean,
) => sections.filter(section => section.section.isSystem === isSystem);

export const getUniqueSectionCategoriesId = (
  sections: SectionProgramPreviewSchema[],
) => _.uniq(sections.map(section => section.section.category.id));

export const getSectionsByCategoriesId = (
  sections: SectionProgramPreviewSchema[],
  categoryId: number,
) => sections.filter(section => section.section.category.id === categoryId);

export const getSectionCategoryName = (
  category: ProgramSectionCategory | null,
  lang: LanguagesSlugs,
) => {
  if (!category) {
    return "";
  }

  const translation = category.translations.find(t => t.lang === lang);
  return translation?.name ?? category.name;
};
