import { APIMethods, ApiResponse, NewVisit } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export async function removeTagFromVisit(
  scheduleId: number,
  tagId: number,
): Promise<ApiResponse<void>> {
  return fetchData(
    `/dietitian/schedules/${scheduleId}/visit/tags/${tagId}`,
    APIMethods.DELETE,
  );
}
