import { useInfiniteQuery, useQuery } from "@tanstack/react-query";

import {
  fetchClientMeasurementList,
  FetchClientMeasurementListResponse,
} from "@client";
import { ApiResponse } from "@typeDefinitions";

import { QueryOptionsTyped } from "./types";

export const fetchClientMeasurementListQueryKey =
  "fetchClientMeasurementListQueryKey";

export const useFetchClientMeasurementList = (
  id: string,
  options?: QueryOptionsTyped<
    ApiResponse<FetchClientMeasurementListResponse[]>
  >,
) => {
  const { data, ...rest } = useQuery(
    [fetchClientMeasurementListQueryKey, id],
    () => fetchClientMeasurementList(id),
    options,
  );

  return {
    measurements: data?.data,
    ...rest,
  };
};

export const fetchClientMeasurementListQueryInfiniteKey =
  "fetchClientMeasurementListQueryInfiniteKey";

export const useFetchClientMeasurementListInfinite = (id: string) => {
  return useInfiniteQuery({
    queryKey: [fetchClientMeasurementListQueryInfiniteKey, id],
    queryFn: ({ pageParam = 1 }) => {
      return fetchClientMeasurementList(id, pageParam);
    },
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.meta.lastPage <= lastPage.meta.currentPage) {
        return undefined;
      }
      return lastPage.meta.currentPage + 1;
    },
    refetchOnMount: false,
    cacheTime: 0,
  });
};
