import { useCallback, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import {
  useAppParams,
  useAppTranslation,
  useModal,
  useNutrientDictionary,
} from "@hooks";
import { useFetchSimpleProgramDietQuery } from "@hooks/queries/useFetchSimpleProgramDietQuery";
import { requestDietNutrients } from "@client";
import { DEFAULT_NORM_ID } from "@utils/nutrients";
import { Edit, Reload } from "@assets/icons";
import { ProgramEmptyDietContext } from "@context";
import {
  useDeleteProgramDayDietMutation,
  useFetchProgramDayNutrientsQuery,
} from "@hooks/queries";
import { DietBalanceChart } from "@components/DietBalanceChart";
import { MicronutrientPreview } from "@components/MicronutrientsModal";
import { DietToBaseDialog } from "@components/DietToBaseDialog";

import { MicroListDayView } from "./MicroListDayView";
import { ActionButtonsSection, StyledModal } from "./DayCardDietView.styled";
import { SelectDiet } from "../SelectDiet";
import {
  createChartMacros,
  createChartMicros,
} from "./utils/dietNutrientsMappers";
import { FullWidthSpinner } from "@components/FullWidthSpinner";
import { usePatientImportantNutrients } from "@views/dietician/ProgramSchedule/components/ProgramScheduleGrid/component/OptimizerSettingsModal/usePatientImportantNutrients";

export const DayCardDietView = () => {
  const { t } = useAppTranslation();
  const { hideWarningBorder } = useContext(ProgramEmptyDietContext);

  const { programId, dayId, patientId } = useAppParams();
  const { importantNutrients } = usePatientImportantNutrients();
  const { modalOpened, onModalOpen, onModalClose } = useModal();
  const {
    modalOpened: modalOpenedBase,
    onModalOpen: onModalOpenBase,
    onModalClose: onModalCloseBase,
  } = useModal();

  const handleOpenModal = () => {
    onModalOpen();
    hideWarningBorder();
  };

  const { simpleDiet, isLoading: isDietLoading } =
    useFetchSimpleProgramDietQuery(programId, dayId);

  const { nutrients, isLoading: isNutrientsLoading } =
    useFetchProgramDayNutrientsQuery(programId, dayId);
  const { mutateAsync, isLoading } = useDeleteProgramDayDietMutation();

  const { nutrientDictionary } = useNutrientDictionary();

  const query = useCallback(
    () => requestDietNutrients(parseInt(simpleDiet?.id ?? "0") ?? 0),
    [simpleDiet?.id],
  );

  const macros = useMemo(
    () =>
      simpleDiet && nutrients
        ? createChartMacros(
            nutrientDictionary,
            simpleDiet.nutrients,
            nutrients.data.nutrients,
          )
        : [],
    [nutrientDictionary, simpleDiet, nutrients],
  );

  const micros = useMemo(
    () =>
      simpleDiet && nutrients
        ? createChartMicros(
            nutrientDictionary,
            simpleDiet.nutrients,
            nutrients.data.nutrients,
          )
        : [],
    [nutrientDictionary, simpleDiet, nutrients],
  );

  const patientMicros = useMemo(
    () =>
      simpleDiet &&
      createChartMicros(
        nutrientDictionary,
        simpleDiet.nutrients,
        importantNutrients?.map(n => ({ ...n, visible: true })) ?? [],
      ),
    [nutrientDictionary, simpleDiet, importantNutrients],
  );

  const navigate = useNavigate();

  const onEditDiet = useCallback(() => {
    if (simpleDiet) {
      navigate(
        patientId
          ? `/patients/${patientId}/nutritional-programs/${programId}/days/${dayId}/diet/${simpleDiet.id}/edit`
          : `/programs/${programId}/days/${dayId}/diet/${simpleDiet.id}/edit`,
      );
    }
  }, [navigate, programId, dayId, simpleDiet?.id]);

  const onDeleteDiet = useCallback(async () => {
    if (simpleDiet && programId && dayId) {
      await mutateAsync({ programId, dayId });
    }
  }, [programId, dayId, simpleDiet?.id]);

  if (isDietLoading || isNutrientsLoading) return <FullWidthSpinner />;

  return (
    <div>
      <h2 className="text-xl">
        {t("common.diet")}: {simpleDiet?.title}
      </h2>
      <DietBalanceChart
        daysView
        macros={macros}
        micros={patientId ? patientMicros : micros}
        microPreview={
          <MicronutrientPreview
            autoMargin
            query={query}
            normId={DEFAULT_NORM_ID}
            programDay={
              !!programId && !!dayId
                ? { programId: parseInt(programId), dayId: parseInt(dayId) }
                : undefined
            }
          />
        }
      />
      <div>
        <MicroListDayView />
      </div>
      <ActionButtonsSection>
        <LoadingButton variant="outlined" onClick={onModalOpenBase}>
          {t("program.days.add_to_base")}
        </LoadingButton>
        <LoadingButton
          variant="outlined"
          onClick={handleOpenModal}
          loading={isLoading}
          className="flex gap-2"
        >
          {t("common.change")}
          <Reload size="w-5 h-5" />
        </LoadingButton>
        <Button variant="contained" onClick={onEditDiet} className="flex gap-2">
          {t("common.edit")}
          <Edit size="w-5 h-5" />
        </Button>
      </ActionButtonsSection>
      {modalOpened && (
        <StyledModal
          onClose={onModalClose}
          closeButton={false}
          submitButton={false}
          title={t("program.days.change_diet")}
        >
          <div className="pt-4 pb-4">
            <SelectDiet
              onSelect={async () => {
                onModalClose();
                await onDeleteDiet();
              }}
            />
          </div>
        </StyledModal>
      )}
      <DietToBaseDialog
        open={modalOpenedBase}
        onClose={onModalCloseBase}
        dietName={{
          pl: simpleDiet?.title ?? null,
          en: simpleDiet?.titleEn ?? null,
        }}
      />
    </div>
  );
};
