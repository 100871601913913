import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { RefCallBack } from "react-hook-form";
import { useDebounce } from "react-use";

import {
  ClickAwayListener,
  IconButton,
  InputAdornment,
  InputBaseComponentProps,
  TextField,
  useTheme,
} from "@mui/material";
import { isArray, isEqual } from "lodash";

import { Loupe } from "@assets/icons";
import { ArrowDown } from "@assets/icons/Arrows";
import { Close } from "@assets/icons/DesignSystem";
import { useAppTranslation } from "@hooks";

import {
  CustomInputComponent,
  CustomInputComponentProps,
} from "./CutomInputComponent";
import {
  CheckboxStyled,
  InputAdornmentStyled,
  PopperStyled,
  StyledCard,
  LabelWrapper,
  TreeItemStyled,
  TreeViewStyled,
} from "./TreeSelectSearch.styled";
import {
  filterData,
  handleCheckboxChange,
  mapAllSelectedIds,
  simplifySelectedIds,
} from "./treeSelectSearchUtils";

export interface TreeItemOption {
  id: string;
  name: string;
  children?: TreeItemOption[];
  parentId: string;
  allParents: string[];
}

interface TreeSelectSearchProps {
  options: TreeItemOption[];
  value: string[];
  onChange: Dispatch<SetStateAction<string[]>>;
  onBlur?: () => void;
  onFocus?: () => void;
  ref?: RefCallBack;
  placeholder?: string;
  singleSelect?: boolean;
  color?: "error" | "warning" | "success" | "primary";
  startAdornment?: JSX.Element;
  InputComponent?: (props: CustomInputComponentProps) => JSX.Element;
}

export const TreeSelectSearch = ({
  options,
  onChange,
  value,
  onBlur,
  onFocus,
  ref,
  placeholder,
  singleSelect = false,
  color,
  startAdornment,
  InputComponent: ExternalComponent,
}: TreeSelectSearchProps) => {
  const { t } = useAppTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [expanded, setExpanded] = useState<string[]>([]);
  const {
    colors: { neutral },
  } = useTheme();

  const [selected, setSelected] = useState<string[]>(
    mapAllSelectedIds(value, options),
  );
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredData, setFilteredData] = useState<TreeItemOption[]>([]);
  const [popoverWidth, setPopoverWidth] = useState<number | undefined>();
  const [popoverHeight, setPopoverHeight] = useState<number | undefined>();

  const handleToggle = (_: SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const renderTree = (node: TreeItemOption) => {
    return (
      <TreeItemStyled
        key={node.id}
        nodeId={node.id}
        label={
          <LabelWrapper>
            <CheckboxStyled
              tabIndex={0}
              onBlur={onBlur}
              onFocus={onFocus}
              checked={selected.includes(node.id)}
              onChange={() => {
                handleCheckboxChange(
                  node.id,
                  selected,
                  options,
                  setSelected,
                  singleSelect,
                );
                setSearchValue("");
              }}
              onClick={e => e.stopPropagation()}
            />
            {node.name}
          </LabelWrapper>
        }
      >
        {isArray(node.children)
          ? node.children.map(node => renderTree(node))
          : null}
      </TreeItemStyled>
    );
  };

  const simplifiedSelectedIds = useMemo(
    () => simplifySelectedIds(selected, options),
    [selected, options],
  );

  const clearAll = (e?: MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();
    setSelected([]);
    setExpanded([]);
  };

  useDebounce(
    () => {
      setExpanded([]);
      setFilteredData(filterData(options, searchValue, setExpanded));
    },
    500,
    [searchValue],
  );

  useEffect(() => {
    if (anchorEl?.clientWidth) setPopoverWidth(anchorEl.clientWidth);
    if (anchorEl?.getBoundingClientRect()) {
      const { top, height } = anchorEl.getBoundingClientRect();
      const marginOffset = 16;
      setPopoverHeight(window.innerHeight - top - height - marginOffset);
    }

    if (!anchorEl) setSearchValue("");
  }, [anchorEl]);

  useEffect(() => {
    onChange(simplifiedSelectedIds);
  }, [simplifiedSelectedIds]);

  useEffect(() => {
    if (value.length === 0 && !isEqual(selected, [])) setSelected([]);
  }, [value]);

  const InputComponent = useCallback(
    (props: InputBaseComponentProps) => (
      <CustomInputComponent
        props={props}
        options={options}
        selected={selected}
        setSelected={setSelected}
        simplifiedSelectedIds={simplifiedSelectedIds}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    ),
    [selected, setSelected, simplifiedSelectedIds],
  );

  const ExternalInputComponent =
    ExternalComponent &&
    useCallback(
      (props: InputBaseComponentProps) => (
        <ExternalComponent
          props={props}
          options={options}
          selected={selected}
          setSelected={setSelected}
          simplifiedSelectedIds={simplifiedSelectedIds}
          onBlur={onBlur}
          onFocus={onFocus}
          color={color}
        />
      ),
      [selected, setSelected, simplifiedSelectedIds],
    );

  return (
    <ClickAwayListener onClickAway={() => anchorEl && setAnchorEl(null)}>
      <div className="w-full">
        <TextField
          fullWidth
          onBlur={onBlur}
          onFocus={onFocus}
          focused={!!anchorEl}
          ref={ref}
          size="small"
          placeholder={placeholder}
          InputProps={{
            startAdornment: startAdornment ?? (
              <InputAdornment position="start">
                <Loupe />
              </InputAdornment>
            ),
            inputComponent: ExternalInputComponent ?? InputComponent,
            endAdornment: (
              <div className="flex">
                <InputAdornmentStyled
                  position="end"
                  hidden={!simplifiedSelectedIds.length}
                >
                  <IconButton
                    size="small"
                    onClick={clearAll}
                    onFocus={onFocus}
                    onBlur={onBlur}
                  >
                    <Close size="w-3 h-3" />
                  </IconButton>
                </InputAdornmentStyled>
                <InputAdornment position="end">
                  <ArrowDown />
                </InputAdornment>
              </div>
            ),
          }}
          onClick={e => {
            setAnchorEl(e.currentTarget);
          }}
          variant="outlined"
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
        />

        <PopperStyled
          anchorEl={anchorEl}
          open={!!anchorEl}
          width={popoverWidth}
          height={popoverHeight}
        >
          <StyledCard>
            <TreeViewStyled
              expanded={expanded}
              selected={selected}
              onNodeToggle={handleToggle}
              defaultExpandIcon={<ArrowDown fill={neutral.dark[700]} />}
              defaultCollapseIcon={
                <ArrowDown fill={neutral.dark[700]} className="rotate-180" />
              }
            >
              {filteredData.map(data => renderTree(data))}
            </TreeViewStyled>
          </StyledCard>
        </PopperStyled>
      </div>
    </ClickAwayListener>
  );
};
