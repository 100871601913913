import { IconButton, Menu, MenuItem, styled, Typography } from "@mui/material";

export const ScheduleVisitDropdownMenu = styled(Menu)`
  & .MuiList-padding {
    padding: 10px;
  }

  & .MuiList-root {
    width: 200px;
  }

  & .MuiMenuItem-gutters {
    padding: 0 8px;
  }
`;

export const ScheduleVisitMenuItem = styled(MenuItem)`
  display: flex;
  gap: 10px;
  border-radius: 8px;
`;

export const ScheduleVisitMenuItemIconButton = styled(IconButton)`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.neutral.dark["700"]};
  background-color: transparent !important;
  padding: 0;
`;

export const ScheduleVisitMenuItemText = styled(Typography)`
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  color: ${({ theme }) => theme.colors.neutral.dark["800"]};
`;

export const ScheduleVisitMenuDivider = styled("div")`
  width: 100%;
  height: 1px;
  margin: 10px 0;
  background-color: ${({ theme }) => theme.colors.neutral.light["200"]};
`;
