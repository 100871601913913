import { OutlinedInput, styled } from "@mui/material";

interface CenteredPlaceholder {
  centeredText?: boolean;
}

export const StyledOutlinedInput = styled(OutlinedInput)<CenteredPlaceholder>`
  & > input {
    text-align: ${({ centeredText }) => (centeredText ? "center" : "unset")};
  }
`;
