import { useMemo } from "react";

import { useTheme } from "@mui/material";
import { round } from "lodash";

import { FlameFilled } from "@assets/icons";
import { useNutrients } from "@hooks";
import { Nutrient } from "@typeDefinitions";
import { ENERGY_ID } from "@utils";
import { CARBS_ID, FATS_ID, PROTEIN_ID } from "@utils/macros";

import {
  KcalText,
  Label,
  MacrosStringWrapper,
  MacrosTextWrapper,
  Text,
} from "./MacrosText.styled";
import { UserTagType } from "@components/ExpandableChips";
import {
  CrossedCircle,
  ThumbsDown,
  ThumbsUp,
} from "@assets/icons/DesignSystem";

interface ConfigIf {
  brackets?: boolean;
  dashes?: boolean;
  zeros?: boolean;
  pluses?: boolean;
  precision?: number;
}
interface MacrosTextProps {
  nutrients: Nutrient[];
  targets?: Nutrient[];
  targetVariant?: Variant;
  config?: ConfigIf;
  userTags?: Partial<Record<UserTagType, boolean>>;
}

export const MacrosText = ({
  nutrients,
  targets,
  targetVariant = "difference",
  config = defaultConfig,
  userTags,
}: MacrosTextProps) => {
  const {
    colors: { neutral },
    palette,
  } = useTheme();
  const { nutrientDict } = useNutrients();

  const valuesDict = useMemo(() => {
    return new Map(nutrients.map(nutrient => [nutrient.id, nutrient.value]));
  }, [nutrients]);

  const targetsDict = useMemo(() => {
    return new Map(targets?.map(nutrient => [nutrient.id, nutrient.value]));
  }, [targets]);

  const formatValue = (value: number | undefined) => {
    if (value === undefined) {
      return config.zeros ? "0" : "";
    }

    return config.pluses && value > 0
      ? `+${round(value, config.precision)}`
      : `${round(value, config.precision)}`;
  };

  const getColorByValue = (
    value: number | undefined,
    target: number | undefined,
  ): "success" | "warning" | "error" | undefined => {
    if (value === undefined || target === undefined) return undefined;

    const targetNonZero = target || 1;

    const percentage =
      targetVariant === "difference"
        ? (Math.abs(value - target) / targetNonZero) * 100
        : Math.abs(value / targetNonZero) * 100;

    if (percentage < 5) return "success";
    if (percentage < 10) return "warning";

    return "error";
  };

  const macrosElements = [PROTEIN_ID, FATS_ID, CARBS_ID].flatMap(
    (id, index) => {
      const value = valuesDict.get(id);
      const target = targetsDict.get(id);
      const nutrient = nutrientDict.get(id);

      const element = (
        <Label color={getColorByValue(value, target)} key={`${id}-value`}>
          {nutrient?.short ?? ""}:&nbsp;{formatValue(value)}
          {nutrient?.unit ?? ""}
        </Label>
      );

      if (index < 2) {
        return [
          element,
          <span key={`${id}-separator`}> {config.dashes ? "-" : " "} </span>,
        ].filter(Boolean);
      }

      return [element];
    },
  );

  const energyValue = formatValue(valuesDict.get(ENERGY_ID));
  const energyColor = getColorByValue(
    valuesDict.get(ENERGY_ID),
    targetsDict.get(ENERGY_ID),
  );

  return (
    <MacrosTextWrapper>
      {!!userTags?.allergen && <CrossedCircle fill={palette.error.dark} />}
      {!!userTags?.disliked && <ThumbsDown fill={palette.warning.dark} />}
      {!!userTags?.liked && <ThumbsUp fill={palette.success.dark} />}

      <FlameFilled
        fill={energyColor ? palette[energyColor].dark : neutral.dark[700]}
      />
      <MacrosStringWrapper>
        <KcalText color={energyColor}>{energyValue}&nbsp;kcal</KcalText>
        <Text>
          {config.brackets ? "(" : ""}
          {macrosElements}
          {config.brackets ? ")" : ""}
        </Text>
      </MacrosStringWrapper>
    </MacrosTextWrapper>
  );
};

type Variant = "difference" | "percentage";

const defaultConfig: ConfigIf = {
  brackets: true,
  dashes: true,
  pluses: false,
  zeros: false,
  precision: 0,
};
