import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import { useFetchMonitoringIrrigation } from "@hooks/queries/client/monitoring";
import IrrigationBox from "@views/dietician/PatientMonitoring2/components/Irrigation/IrrigationBox";
import dayjs, { Dayjs } from "dayjs";
import sumBy from "lodash/sumBy";
import { useCallback, useMemo } from "react";
import { PatientHydrationProductsMonitoringResource } from "@client/resources/PatientHydrationProductsMonitoringResource";
import { useAppTranslation } from "@hooks";
import {
  tooltipLabelFormatter,
  xAxisTickFormatter,
} from "@views/dietician/PatientMonitoring2/utils/ChartUtils";

const IrrigationBoxOfDay = () => {
  const { t } = useAppTranslation();
  const { patientId, selectedDays } = useMonitoring();
  const { data } = useFetchMonitoringIrrigation({
    patientId,
    dateFrom: selectedDays.selectDays.from
      .clone()
      .add(-3, "days")
      .format("YYYY-MM-DD"),
    dateTo: selectedDays.selectDays.from
      .clone()
      .add(3, "days")
      .format("YYYY-MM-DD"),
  });

  const value = useMemo(
    () => getTodayValue(data?.data ?? [], selectedDays.selectDays.from),
    [data, selectedDays.selectDays.from],
  );
  const norm = useMemo(
    () => getNorm(data?.data ?? [], selectedDays.selectDays.from),
    [data, selectedDays.selectDays.from],
  );

  const getXAxisTickFormatter = useCallback(
    (date: string) => xAxisTickFormatter(t, date),
    [t],
  );

  const getTooltipLabelFormatter = useCallback(
    (date: string) => tooltipLabelFormatter(t, date),
    [t],
  );

  return (
    <IrrigationBox
      value={value}
      data={data?.data ?? []}
      norm={norm}
      xAxisTickFormatter={getXAxisTickFormatter}
      tooltipLabelFormatter={getTooltipLabelFormatter}
    />
  );
};

const getTodayValue = (
  data: PatientHydrationProductsMonitoringResource[],
  date: Dayjs,
) => {
  const itemDate = data.find(i => i.date === date.format("YYYY-MM-DD"));
  if (itemDate === undefined) {
    return 0;
  }

  return sumBy(itemDate.products, o => o.milliliters) / 1000;
};

const getNorm = (
  data: PatientHydrationProductsMonitoringResource[],
  date: Dayjs,
) => {
  const itemDate = data.find(i => i.date === date.format("YYYY-MM-DD"));
  if (itemDate === undefined) {
    return 0;
  }

  return (itemDate.goal ?? 0) / 1000;
};

export default IrrigationBoxOfDay;
