import { SaveDietMealCreatorRequest } from "@client/diets/creator";
import { postProgramDietMeal } from "@client/program/dietCreator";
import {
  fetchCreatorDietMealsQueryKey,
  fetchCreatorDietQueryKey,
} from "@hooks/queries/diets/creator";
import { useCreatorVersion } from "@hooks/queries/diets/creator/useCreatorVersion";
import { MutationOptions } from "@hooks/queries/types";
import { searchDietsQueryKeyNew } from "@hooks/queries/useSearchDietsQueryNew";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const usePostProgramDietMealMutation = (
  programId: string,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();
  const { version } = useCreatorVersion();

  return useMutation<unknown, unknown, UsePostProgramDietMealMutationIf>(
    ({ dietId, payload }) =>
      postProgramDietMeal(programId, dietId, payload, version),
    {
      ...options,
      onSuccess: (d, v) => {
        options?.onSuccess && options.onSuccess(d, v);
        queryClient.invalidateQueries([
          fetchCreatorDietMealsQueryKey,
          v.dietId,
        ]);
        queryClient.invalidateQueries([fetchCreatorDietQueryKey, v.dietId]);
        queryClient.invalidateQueries([searchDietsQueryKeyNew]);
      },
    },
  );
};

interface UsePostProgramDietMealMutationIf {
  dietId: string;
  payload: SaveDietMealCreatorRequest;
}
