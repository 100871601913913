import * as yup from "yup";

import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";

import { fetchProgramShape, GenericFetchProgramResponse } from "./common";

export const fetchProgram = async (
  programId: number,
): Promise<ApiResponse<FetchProgramResponse>> => {
  const data = await fetchData(
    `/dietitian/programs/${programId}`,
    APIMethods.GET,
  );
  return await fetchProgramSchema.validate(data);
};

const fetchProgramSchema = yup.object().shape({
  data: yup
    .object()
    .shape({
      ...fetchProgramShape,
      durationDays: yup.number().nullable().default(null),
    })
    .required(),
});

export interface FetchProgramResponse extends GenericFetchProgramResponse {
  durationDays: number | null;
}
