import { QueryOptionsTyped } from "@hooks/queries";
import { useQuery } from "@tanstack/react-query";
import {
  fetchSectionsProgramPreview,
  FetchSectionsProgramPreviewParams,
  FetchSectionsProgramPreviewResponse,
} from "@client/program/preview/fetchSectionsProgramPreview";
import { programPreviewKeys } from "@hooks/queries/program/preview/programPreviewKeys";

export const getKey = (programId: FetchSectionsProgramPreviewParams) =>
  programPreviewKeys.programSections(programId);

const useFetchSectionsProgramPreview = (
  programId: FetchSectionsProgramPreviewParams,
  options?: QueryOptionsTyped<FetchSectionsProgramPreviewResponse>,
) => {
  return useQuery({
    queryKey: getKey(programId),
    queryFn: () => fetchSectionsProgramPreview(programId),
    ...options,
  });
};

export default useFetchSectionsProgramPreview;
