import { useCallback, useMemo } from "react";
import MeasurementsBodySection from "@views/dietician/PatientMonitoring2/components/MeasurementsBox/MeasurementsBodySection";
import { FetchClientMeasurementListResponse } from "@client";
import { useFetchClientMeasurementsChart } from "@hooks/queries/useFetchClientMeasurementsChart";
import { useParams } from "react-router-dom";
import { useFetchClientQueryNew } from "@hooks/queries";
import { mapClientDataToGoals } from "@views/dietician/PatientMonitoring2/components/MeasurementsBox/MeasurementUtil";
import dayjs from "dayjs";
import { useAppTranslation } from "@hooks";
import { BodyMeasurementMonitoringResource } from "@client/resources/BodyMeasurementMonitoringResource";

type MeasurementChartProps = {
  measurement: FetchClientMeasurementListResponse | null;
};
const MeasurementChart = ({ measurement }: MeasurementChartProps) => {
  const { t } = useAppTranslation();
  const { patientId } = useParams();
  if (!patientId) {
    throw new Error("Undefined patient");
  }
  const { data } = useFetchClientMeasurementsChart(patientId);
  const { data: clientData } = useFetchClientQueryNew(patientId);

  const mappedMeasurement = useMemo(() => {
    if (!measurement) {
      return null;
    }

    return {
      date: measurement.date ?? "",
      measurements: {
        ...measurement,
        bodyMeasurements: measurement.bodyMeasurements,
      },
    };
  }, [measurement]);

  const mappedData: BodyMeasurementMonitoringResource[] = useMemo(() => {
    if (!data) {
      return [];
    }

    const tmp = data.data.map((d, index) => ({
      date: d.date,
      measurements: { id: index + 1, ...d },
    }));

    return [
      ...tmp,
      {
        date: dayjs().add(1, "month").format("YYYY-MM"),
        measurements: null,
      },
    ];
  }, [data]);

  const goals = useMemo(
    () => mapClientDataToGoals(clientData?.data),
    [clientData],
  );

  const getXAxisTickFormatter = useCallback(
    (date: string) => {
      return t("common.abbreviated_month_name." + (dayjs(date).month() + 1));
    },
    [t],
  );

  const getTooltipLabelFormatter = useCallback(
    (date: string) => {
      return t("common.full_month_name." + (dayjs(date).month() + 1));
    },
    [t],
  );

  return (
    <MeasurementsBodySection
      data={mappedData}
      latest={mappedMeasurement}
      goals={goals}
      xAxisTickFormatter={getXAxisTickFormatter}
      tooltipLabelFormatter={getTooltipLabelFormatter}
    />
  );
};

export default MeasurementChart;
