import { FormControlLabel, styled } from "@mui/material";

export const StyledFormControlLabel = styled(FormControlLabel)`
  gap: 0.5rem;

  & .MuiTypography-root {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: ${({ theme }) => theme.colors.neutral.dark[800]};
  }
`;
