import { ReactNode, useContext, useEffect, useMemo, useState } from "react";

import { CircularProgress } from "@mui/material";
import { format } from "date-fns";
import { isEqual } from "lodash";

import { Button } from "@components/Button";
import { RadioTile } from "@components/RadioTile";
import { RadioTileGroup } from "@components/RadioTileGroup";
import { SidePanelWrapper } from "@components/SidePanelWrapper";
import { Tab, Tabs } from "@components/Tabs";
import { useAppTranslation } from "@hooks";
import {
  useCreateProgramDayMealMutation,
  useFetchUsedRecipesQuery,
  useProgramDayMealFoodMutation,
  useProgramDayMealRecipeMutation,
} from "@hooks/queries/schedule";
import { useFetchProgramDietDetailsQuery } from "@hooks/queries/useFetchProgramDietDetails";
import { MealTypes } from "@typeDefinitions";
import { ProgramScheduleNavigationContext } from "@views/dietician/ProgramSchedule/context";

import { ThemeProviderWrapper } from "theme";
import { MealTab } from "./components/MealTab";
import { NoElementsWrapper } from "./components/NoElementsWrapper.styled";

interface RecipeChangeSidePanelProps extends RecipeChangeModalProps {
  onOpenReplaceItemSidePanel: () => void;
}

export const RecipeChangeSidePanel = (props: RecipeChangeSidePanelProps) => {
  const {
    programId,
    dayId,
    programDayId,
    mealId,
    dietMeal,
    dayDate,
    children,
    title,
    submitButtonDisabled,
    newMealCreation = false,
    onClose,
    onOpenReplaceItemSidePanel,
  } = props;

  const { t } = useAppTranslation();

  const { currentRangeStart, currentRangeEnd } = useContext(
    ProgramScheduleNavigationContext,
  );

  const [selectedRange, setSelectedRange] = useState({
    from: currentRangeStart.format("YYYY-MM-DD"),
    to: currentRangeEnd.format("YYYY-MM-DD"),
  });
  const [recipeModalVisible, openRecipeModal] = useState(false);
  const [selectedRecipeOrProduct, setSelectedRecipeOrProduct] = useState<{
    type: MealTypes;
    data: SimplifiedRecipeOrProductData;
  }>();
  const [category, setCategory] = useState<MealTypes>(MealTypes.RECIPE);

  const { mutate: createDayMeal } = useCreateProgramDayMealMutation(
    programId,
    dayId,
  );
  const { mutate: updateDayMealRecipe } = useProgramDayMealRecipeMutation(
    programId,
    dayId,
  );
  const { mutate: updateDayMealProduct } = useProgramDayMealFoodMutation(
    programId,
    dayId,
  );
  const { dietDetails, isLoading, isRefetching } =
    useFetchProgramDietDetailsQuery(programId, programDayId, 0);
  const { recipes: usedRecipes, submit } = useFetchUsedRecipesQuery(
    programId,
    selectedRange,
  );
  const meals = useMemo(() => dietDetails?.data.meals, [dietDetails]);

  const initialTabIndex = useMemo(() => {
    const index = dietDetails?.data.meals?.findIndex(
      meal => meal.name.toLowerCase() === dietMeal.title.toLowerCase(),
    );
    return !index || index < 0 ? 0 : index;
  }, [meals]);

  const handleRecipeOrProductSelect = (
    data: SimplifiedRecipeOrProductData,
    type?: MealTypes,
  ) => {
    openRecipeModal(false);
    if (newMealCreation) {
      setSelectedRecipeOrProduct({ type: type || category, data });
      return;
    }
    commitAndClose(type || category, data);
  };

  const handleCategoryChange = (val?: string | number) => {
    if (val) setCategory(val as MealTypes);
  };

  const commitAndClose = (
    type: MealTypes,
    recipeOrProductData: SimplifiedRecipeOrProductData,
  ) => {
    if (!mealId) {
      createDayMeal(dietMeal, {
        onSuccess: data => {
          if (data?.data?.id)
            updateRecipe(type, data?.data?.id, recipeOrProductData);
        },
      });
      return;
    }
    updateRecipe(type, mealId, recipeOrProductData);
  };

  const updateRecipe = (
    type: MealTypes,
    mealId: number,
    { name, ...data }: SimplifiedRecipeOrProductData,
  ) => {
    if (type === MealTypes.RECIPE) {
      updateDayMealRecipe({ mealId, ...data });
      onClose();
      return;
    }

    updateDayMealProduct({ mealId, ...data });
    onClose();
  };

  const handleOpenReplacementItemSidePanel = () => {
    onOpenReplaceItemSidePanel();
    onClose();
  };

  useEffect(() => {
    const newRange = {
      from: currentRangeStart.format("YYYY-MM-DD"),
      to: currentRangeEnd.format("YYYY-MM-DD"),
    };
    if (!isEqual(selectedRange, newRange)) {
      setSelectedRange(newRange);
      submit(newRange);
    }
  }, [currentRangeStart, currentRangeEnd]);

  return (
    <>
      <SidePanelWrapper
        open={!recipeModalVisible}
        onClose={onClose}
        title={
          <span className="flex items-center">
            {title || t("patient.schedule.select_meal")}
            {dietMeal.title.length > 0 && (
              <>
                <span className="mx-4 border-r border-gray-400 h-7" />
                <strong className="text-lg">{dietMeal.title}</strong>
              </>
            )}
            <span className="mx-4 border-r border-gray-400 h-7" />
            <strong className="text-lg text-gray-700">
              {format(new Date(dayDate), "dd.MM.yyyy")}
            </strong>
            {(isLoading || isRefetching) && (
              <CircularProgress size="1.75rem" className="mx-4" />
            )}
          </span>
        }
      >
        <ThemeProviderWrapper>
          <div className="p-6 flex flex-col">
            {children}
            {newMealCreation && (
              <>
                <div className="grid grid-cols-3">
                  <div className="h-10 flex items-center">
                    {`${t("patient.schedule.new_meal_create.recipe")}: `}
                  </div>
                  <div className="h-10 flex items-center col-span-2">
                    <strong>
                      {selectedRecipeOrProduct?.data.name || t("errors.no")}
                      {!!selectedRecipeOrProduct?.data.grams &&
                        ` - ${selectedRecipeOrProduct?.data.grams}g`}
                    </strong>
                  </div>
                </div>
                <div className="w-full border-b border-gray my-4" />
              </>
            )}
            <div className="flex justify-between mb-2">
              <RadioTileGroup
                name="mealCategory"
                variant="outlined"
                defaultValue={category}
                onChange={handleCategoryChange}
              >
                <RadioTile
                  label={t("common.recipe")}
                  value={MealTypes.RECIPE}
                  className="h-10"
                />
                <RadioTile
                  label={t("pdf.recipe.product")}
                  value={MealTypes.FOOD}
                  className="h-10"
                />
              </RadioTileGroup>

              <Button
                size="large"
                color="primary"
                className="mt-2"
                onClick={handleOpenReplacementItemSidePanel}
              >
                {t("patient.schedule.add_recipe_or_meal")}
              </Button>
            </div>
            {!meals?.length ? (
              <NoElementsWrapper>
                {t("patient.schedule.no_recipes")}
              </NoElementsWrapper>
            ) : (
              <Tabs showScroll={false} initialTab={initialTabIndex}>
                {meals
                  .sort((a, b) =>
                    !a.hour || !b.hour ? 1 : a.hour > b.hour ? 1 : -1,
                  )
                  .map(({ id, name, recipes, food }, i) => (
                    <Tab id={i.toString()} title={`${name}`}>
                      <MealTab
                        key={id}
                        category={category}
                        food={food}
                        recipes={recipes}
                        handleRecipeOrProductSelect={
                          handleRecipeOrProductSelect
                        }
                        usedRecipes={usedRecipes}
                      />
                    </Tab>
                  ))}
              </Tabs>
            )}
          </div>
        </ThemeProviderWrapper>
      </SidePanelWrapper>
    </>
  );
};

export interface RecipeChangeModalProps {
  children?: ReactNode;
  title?: string;
  programId: number;
  dayId: number;
  programDayId: number;
  mealId: number | null;
  dietMeal: {
    title: string;
    hour: string | null;
    description: string | null;
  };
  onClose: () => void;
  dayDate: string;
  newMealCreation?: boolean;
  submitButtonDisabled?: boolean;
}

export interface SimplifiedRecipeOrProductData {
  id: number;
  servings?: number;
  name?: string | null;
  grams?: number;
  foodMeasureId?: number;
}
