import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export async function requestHideProduct(id: number) {
  await fetchData(`/dietitian/food/${id}/hide`, APIMethods.PUT);
}

export async function requestDeleteProduct(id: number) {
  await fetchData(`/dietitian/food/${id}`, APIMethods.DELETE);
}

export async function requestShowProduct(id: number) {
  await fetchData(`/dietitian/food/${id}/show`, APIMethods.PUT);
}
