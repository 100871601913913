import { useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";

import { TabContext } from "@mui/lab";
import { CircularProgress } from "@mui/material";

import { useAppParams, useAppTranslation } from "@hooks";

import { useFetchScheduleRecipeQuery } from "@hooks/queries/schedule";
import { mapScheduleRecipeEditForm, useRecipeEditForm } from "..";
import { BasicInformation } from "../BasicInformation";
import { ProductsAndNutritionalValues } from "../ProductsAndNutritionalValues";
import {
  ProductsContextProvider,
  mapScheduleDefaultProductsContext,
} from "../ProductsContext";
import { TagsPanel } from "../TagsPanel";
import { DrawerHeader } from "./DrawerHeader";
import { Tab } from "./RecipeEditContent";
import { PanelWrapper, TabPanelStyled } from "./RecipeEditContent.styled";
import { ScheduleDrawerFooter } from "./ScheduleDrawerFooter";

interface ScheduleRecipeEditContentProps {
  mealId: string;
  onClose: () => void;
  dayId: string;
}

export const ScheduleRecipeEditContent = ({
  mealId,
  onClose,
  dayId,
}: ScheduleRecipeEditContentProps) => {
  const { isPolishChosen } = useAppTranslation();
  const { programId } = useAppParams();
  const [tab, setTab] = useState(Tab.INFO);

  const { recipe, isLoading } = useFetchScheduleRecipeQuery(
    programId,
    dayId,
    mealId,
  );

  const defaultValues = useMemo(
    () => mapScheduleRecipeEditForm(recipe),
    [recipe],
  );
  const defaultProducts = useMemo(
    () => mapScheduleDefaultProductsContext(recipe),
    [recipe],
  );
  const form = useRecipeEditForm(defaultValues);

  if (isLoading)
    return (
      <div className="grid h-full place-items-center">
        <CircularProgress />
      </div>
    );

  return (
    <FormProvider {...form}>
      <ProductsContextProvider defaultValues={defaultProducts}>
        <DrawerHeader
          onClose={onClose}
          tab={tab}
          setTab={setTab}
          title={(isPolishChosen ? recipe?.name : recipe?.nameEn) ?? undefined}
        />

        <TabContext value={tab}>
          <TabPanelStyled value={Tab.INFO}>
            <PanelWrapper>
              <BasicInformation />
            </PanelWrapper>
          </TabPanelStyled>
          <TabPanelStyled value={Tab.PRODUCTS}>
            <PanelWrapper>
              <ProductsAndNutritionalValues />
            </PanelWrapper>
          </TabPanelStyled>
          <TabPanelStyled value={Tab.TAGS}>
            <PanelWrapper>
              <TagsPanel />
            </PanelWrapper>
          </TabPanelStyled>
        </TabContext>

        <ScheduleDrawerFooter
          onClose={onClose}
          mealId={mealId}
          dayId={dayId}
          programId={programId}
          recipeId={recipe?.id.toString()}
        />
      </ProductsContextProvider>
    </FormProvider>
  );
};
