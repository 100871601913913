import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const StarFilled = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="14" viewBox="0 0 14 14" {...props}>
      <path
        d="M9.31787 4.43359L12.9741 4.99219C13.2957 5.04297 13.5158 5.2207 13.6343 5.52539C13.7189 5.84701 13.6512 6.1263 13.4312 6.36328L10.7905 8.95312L11.3999 12.6855C11.4507 13.0072 11.3407 13.2695 11.0698 13.4727C10.799 13.6589 10.5197 13.6758 10.2319 13.5234L6.95654 11.7969L3.70654 13.5234C3.40186 13.6758 3.1141 13.6589 2.84326 13.4727C2.58936 13.2695 2.47933 13.0072 2.51318 12.6855L3.14795 8.95312L0.507324 6.36328C0.270345 6.1263 0.202637 5.84701 0.304199 5.52539C0.405762 5.2207 0.61735 5.04297 0.938965 4.99219L4.59521 4.43359L6.22021 1.08203C6.38949 0.794271 6.63493 0.641927 6.95654 0.625C7.29508 0.641927 7.54053 0.794271 7.69287 1.08203L9.31787 4.43359Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
