import { Autocomplete, Button, styled, Typography } from "@mui/material";

export const StyledAutocomplete = styled(Autocomplete)`
  max-width: 30rem;
  flex: 1;
` as typeof Autocomplete;

export const TagsLabel = styled(Typography)`
  font-weight: 600;
  font-size: 0.75rem;
`;

export const SelectedTagsWrapper = styled("div")`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0 0.5rem 0.5rem 0;
`;

export const ShowTagsButton = styled(Button)`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  color: ${({ theme }) => theme.palette.text.primary};

  & > svg {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const CollapseWrapper = styled("div")`
  display: grid;
  gap: 0.75rem;
  padding-bottom: 0.75rem;
`;
