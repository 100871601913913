import { deleteSurveyGroup } from "@client/surveys";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "../types";
import { fetchSurveyFullQueryKey } from "./useFetchSurveyFullQuery";

export const useDeleteSurveyGroupMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, string>(id => deleteSurveyGroup(id), {
    ...options,
    onSuccess: (data, variables) => {
      options?.onSuccess && options.onSuccess(data, variables);

      queryClient.invalidateQueries([fetchSurveyFullQueryKey]);
    },
  });
};
