import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const ReceiptWithPercent = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      style={{ fill: "none" }}
      width="21"
      height="21"
      viewBox="0 0 21 21"
    >
      <path
        d="M19.25 5.25V7.3675C19.25 8.75 18.375 9.625 16.9925 9.625H14V3.50875C14 2.5375 14.7963 1.75 15.7675 1.75C16.7213 1.75875 17.5962 2.14375 18.2262 2.77375C18.8562 3.4125 19.25 4.2875 19.25 5.25Z"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.75 6.125V18.375C1.75 19.1012 2.5725 19.5125 3.15 19.075L4.64625 17.955C4.99625 17.6925 5.48625 17.7275 5.80125 18.0425L7.25375 19.5038C7.595 19.845 8.155 19.845 8.49625 19.5038L9.96625 18.0338C10.2725 17.7275 10.7625 17.6925 11.1037 17.955L12.6 19.075C13.1775 19.5037 14 19.0925 14 18.375V3.5C14 2.5375 14.7875 1.75 15.75 1.75H5.25C2.625 1.75 1.75 3.31625 1.75 5.25V6.125Z"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.48633 12.0138L10.2638 7.23633"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4337 11.8125H10.4415M5.41992 7.4375H5.4278"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
