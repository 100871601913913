import { Typography } from "@mui/material";
import {
  MacrosValue,
  StyledInfoAlert,
  StyledTypography,
} from "./ScheduleSummary.styled";
import { round } from "lodash";

interface ScheduleSummaryTileProps {
  name: string;
  value: number;
  target: number;
  unit: string;
}
export const ScheduleSummaryTile = ({
  name,
  value,
  target,
  unit,
}: ScheduleSummaryTileProps) => {
  return (
    <StyledInfoAlert icon={false}>
      <Typography>{name}</Typography>
      <StyledTypography>
        <MacrosValue>{round(value)}</MacrosValue> / {round(target)} {unit}
      </StyledTypography>
    </StyledInfoAlert>
  );
};
