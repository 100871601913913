import { useNavigate } from "react-router-dom";
import { FormProvider } from "react-hook-form";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  ProgramFormInput,
  useAppTranslation,
  useProgramForm,
  useProgramParams,
} from "@hooks";
import {
  useCloneProgramMutation,
  useFetchProgramQuery,
  useUpdateProgramMutation,
} from "@hooks/queries";
import { FetchProgramResponse } from "@client/program";
import { SingleCardPageLayout } from "@components/PageLayout";
import { EditorHeaderReviewedToggle } from "@components/EditorHeaderReviewedToggle";
import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";
import { ProgramInfoNavigation } from "@components/forms/ProgramInfoNavigation";
import {
  DurationSelect,
  LanguageSelect,
  ProgramNameSelect,
  SectionsSelect,
} from "@views/dietician/ProgramForm/components";

import { ProgramEditFormWrapper } from "./ProgramEdit.styled";

interface ProgramEditProps {
  copy?: boolean;
}

export const ProgramEdit = ({ copy = false }: ProgramEditProps) => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const { programId } = useProgramParams();
  const { program, isSuccess, isLoading, isError } =
    useFetchProgramQuery(programId);
  const [programCopyId, setProgramCopyId] = useState(0);

  const { mutate: updateProgram, isLoading: isUpdateLoading } =
    useUpdateProgramMutation(copy ? programCopyId : programId, {
      onSuccess: () =>
        navigate(`/programs/${copy ? programCopyId : programId}/days`),
    });

  const { mutate: cloneProgram, isLoading: isCloneProgramLoading } =
    useCloneProgramMutation({
      onSuccess: data => data && setProgramCopyId(data?.id),
    });

  const mapProgramForm = (
    program?: FetchProgramResponse,
  ): ProgramFormInput | undefined => {
    if (!program) return;
    return {
      namePl: program.name + (copy ? " (Kopia)" : ""),
      nameEn: (program.nameEn ?? program.name) + (copy ? " (Copy)" : ""),
      workModel: "diets",
      programLanguage: program.langId,
      hasDiary: program.hasDiary,
      hasMonitoring: program.hasMonitoring,
      hasEducation: program.hasEducation,
      hasTasks: program.hasTasks,
      hasShoppingList: program.hasShoppingList,
      hasNewsfeed: program.hasNewsfeed,
      hasChat: program.hasChat,
      showCals: program.showCals,
      showMacros: program.showMacros,
      duration: program.durationDays ?? null,
      showMicros: program.showNutrients,
      sectionIds: program.sections,
      reviewed: program.reviewed,
    };
  };

  const mapProgramRequest = (program: ProgramFormInput) => {
    return {
      name: program.namePl,
      nameEn: program.nameEn,
      hasDiets: program.workModel === "diets",
      hasMacros: program.workModel === "macros",
      hasDiary: program.hasDiary,
      hasMonitoring: program.hasMonitoring,
      hasEducation: program.hasEducation,
      hasTasks: program.hasTasks,
      hasShoppingList: program.hasShoppingList,
      hasChat: program.hasChat,
      hasNewsfeed: program.hasNewsfeed,
      showCals: program.showCals,
      showMacros: program.showMacros,
      showNutrients: program.showMicros,
      sections: program.sectionIds,
      durationDays: program.duration,
      reviewed: !!program.reviewed,
      langId: program.programLanguage,
    };
  };

  const form = useProgramForm(
    useMemo(() => mapProgramForm(program), [program]),
  );

  const submit = useCallback(
    form.handleSubmit(program => updateProgram(mapProgramRequest(program))),
    [updateProgram],
  );

  useEffect(() => {
    if (copy) cloneProgram(programId);
  }, []);

  return (
    <>
      <FormProvider {...form}>
        <SingleCardPageLayout
          title={t("programs.edit_program")}
          extra={<EditorHeaderReviewedToggle name={"reviewed"} />}
        >
          {(isLoading || isCloneProgramLoading) && <Spinner className="h-52" />}

          {isError && <ApiError />}

          {isSuccess && !isCloneProgramLoading && (
            <ProgramEditFormWrapper>
              <ProgramNameSelect />
              <LanguageSelect name="programLanguage" />
              <DurationSelect edit />
              <SectionsSelect programId={programId} />
            </ProgramEditFormWrapper>
          )}
        </SingleCardPageLayout>
      </FormProvider>

      <ProgramInfoNavigation
        onBack={() => navigate(-1)}
        onSubmit={submit}
        isSubmitting={isUpdateLoading}
      />
    </>
  );
};
