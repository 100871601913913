import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useAppTranslation } from "@hooks";
import { MutationOptions } from "../types";
import { fetchClinicDietitiansQueryKey } from "./useFetchClinicDietitiansQuery";
import {
  CreateClinicDietitianRequest,
  createClinicDietitian,
} from "@client/settings";
import { toast } from "react-toastify";

export const useCreateClinicDietitianMutation = (options?: MutationOptions) => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, CreateClinicDietitianRequest>(
    payload => createClinicDietitian(payload),
    {
      ...options,
      onSuccess: () => {
        toast.success(t("dietitians_settings.dietician_added"));
        queryClient.invalidateQueries([fetchClinicDietitiansQueryKey]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};
