import { IconButton, Tooltip } from "@mui/material";
import {
  DataType,
  useChangeSharedProgramMutation,
} from "@hooks/queries/client/program/useChangeSharedProgramMutation";
import {
  CheckedMonitoring,
  UncheckedMonitoring,
} from "@assets/icons/Monitoring";
import { useQueryClient } from "@tanstack/react-query";
import { getKey } from "@hooks/queries/client/program/preview/useFetchPatientProgramSearchPreview";
import { FetchPatientProgramPreviewSearchResponse } from "@client/patient/programs/fetchPatientProgramSearchPreview";
import { useAppTranslation } from "@hooks";

type SharedActionType = {
  program: {
    id: number;
    shared: boolean;
  };
  patientId: number;
};

const SharedAction = ({ program, patientId }: SharedActionType) => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();
  const { mutate } = useChangeSharedProgramMutation({
    onSuccess: (data, variables: DataType) => {
      queryClient.setQueryData(
        getKey({ programId: program.id, patientId: patientId }),
        (oldData: FetchPatientProgramPreviewSearchResponse | undefined) => {
          if (!oldData) {
            return;
          }

          return {
            ...oldData,
            data: {
              ...oldData.data,
              shared: variables.payload.shared,
            },
          };
        },
      );
    },
  });

  const handleOnClick = () => {
    mutate({
      programId: program.id,
      patientId: patientId,
      payload: {
        shared: !program.shared,
      },
    });
  };

  return (
    <>
      {program.shared && (
        <Tooltip
          title={t("patient.program_preview.menu_actions.hide_in_app")}
          arrow
        >
          <IconButton onClick={handleOnClick}>
            <CheckedMonitoring
              fill="#3AB795"
              style={{ width: "32px", height: "32px" }}
            />
          </IconButton>
        </Tooltip>
      )}
      {!program.shared && (
        <Tooltip
          title={t("patient.program_preview.menu_actions.show_in_app")}
          arrow
        >
          <IconButton onClick={handleOnClick}>
            <UncheckedMonitoring
              fill="#FFFFFF"
              style={{ width: "32px", height: "32px" }}
            />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default SharedAction;
