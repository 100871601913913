import { styled, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { TabPanel } from "@mui/lab";

export const CustomTabPanel = styled(TabPanel)`
  padding: 0px;
` as typeof TabPanel;

export const CustomToggleButtonGroup = styled(ToggleButtonGroup)`
  padding: 4px;
  border: solid 1px #f5f1fe;
  border-radius: 8px;
` as typeof ToggleButtonGroup;

export const CustomToggleButton = styled(ToggleButton)`
    background: ${({ theme }) => theme.colors.neutral.light[100]};
    font-family: Figtree, serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    border-radius: 8px !important;
    color: #727272;
    border: none;
    padding: 2px 6px;
    text-transform: none;

    &:hover {
        background-color:  ${({ theme }) => theme.colors.neutral.light[100]};
    }

    &.Mui-selected {
        color:   ${({ theme }) => theme.palette.primary.main};
        background: #F5F1FE;
` as typeof ToggleButton;
