import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { ReactElement } from "react";
import { useFetchClientQuery, useUpdateClientMutation } from "@hooks/queries";
import useFetchPatientNormNutrients from "@hooks/queries/client/nutrient/useFetchPatientNormNutrients";
import {
  mapUpdateClientForm,
  mapUpdateClientRequest,
  useClientParams,
} from "@hooks";
import useFetchPatientProductExchangers from "@hooks/queries/client/useFetchPatientProductExchangers";
import { Backdrop, CircularProgress } from "@mui/material";
import useUpdatePatientProductExchangersMutation from "@hooks/queries/client/useUpdatePatientProductExchangersMutation";
import useUpdatePatientNormNutrientsMutation from "@hooks/queries/client/nutrient/useUpdatePatientNormNutrientsMutation";
import { FetchClientResponse } from "@client";
import { FetchPatientProductExchangersResponse } from "@client/patient/fetchPatientProductExchangers";
import { FetchPatientNormNutrientsResponse } from "@client/patient/nutrients/fetchPatientNormNutrients";

type MainFormProps = {
  children: ReactElement | ReactElement[];
  onSuccess: () => void;
};

export type FormPops = {
  additionalFeatureId: string;
  nutrientsNormId: string | null;
  nutrientsNorm: {
    [key: string]: string;
  };
  productExchangersId: number[];
};

const ProfileSectionForm = ({ children, onSuccess }: MainFormProps) => {
  const id = useClientParams();
  const { client, isLoading } = useFetchClientQuery(id);
  const { data: productExchangersData, isLoading: isLoadingProducts } =
    useFetchPatientProductExchangers({ patientId: id });
  const { data: nutrientsNormData, isLoading: isLoadingNutrients } =
    useFetchPatientNormNutrients({ patientId: id });

  if (
    !client ||
    !productExchangersData ||
    !nutrientsNormData ||
    isLoading ||
    isLoadingProducts ||
    isLoadingNutrients
  ) {
    return (
      <Backdrop open={true} sx={{ color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <MainFormWrapper
      client={client}
      nutrientsNormData={nutrientsNormData}
      productExchangersData={productExchangersData}
      onSuccess={onSuccess}
    >
      {children}
    </MainFormWrapper>
  );
};

type MainFormWrapperProps = {
  client: FetchClientResponse;
  productExchangersData: FetchPatientProductExchangersResponse;
  nutrientsNormData: FetchPatientNormNutrientsResponse;
  children: ReactElement | ReactElement[];
  onSuccess: () => void;
};

const MainFormWrapper = ({
  client,
  productExchangersData,
  nutrientsNormData,
  children,
  onSuccess,
}: MainFormWrapperProps) => {
  const id = client.id;

  const updateClientMutate = useUpdateClientMutation(Number(id));
  const productExchangersMutation = useUpdatePatientProductExchangersMutation();
  const patientNormMutation = useUpdatePatientNormNutrientsMutation();

  const form = useForm<FormPops>({
    defaultValues: {
      additionalFeatureId:
        client.profile.additionalFeatures[0]?.id.toString() ?? "",
      productExchangersId: productExchangersData.data.map(
        productExchanger => productExchanger.tag.id,
      ),
      nutrientsNormId: nutrientsNormData.data.norm?.id.toString() ?? null,
      nutrientsNorm: Object.fromEntries(
        nutrientsNormData.data.nutrients.map(item => [
          item.id.toString(),
          item.value?.toString() ?? "",
        ]),
      ),
    },
  });

  const onSubmit: SubmitHandler<FormPops> = async data => {
    if (!client) {
      return;
    }

    if (
      patientNormMutation.isLoading ||
      productExchangersMutation.isLoading ||
      updateClientMutate.isLoading
    ) {
      return;
    }

    const promise1 = data.nutrientsNormId
      ? patientNormMutation.mutateAsync({
          params: {
            patientId: id,
          },
          payload: {
            norm: {
              id: parseInt(data.nutrientsNormId),
            },
            nutrients: Object.keys(data.nutrientsNorm).map(nutrientId => ({
              id: parseInt(nutrientId),
              value: data.nutrientsNorm[nutrientId]
                ? parseFloat(data.nutrientsNorm[nutrientId])
                : 0,
            })),
          },
        })
      : Promise.resolve();

    const promise2 = productExchangersMutation.mutateAsync({
      params: {
        patientId: id,
      },
      payload: {
        tags_id: data.productExchangersId,
      },
    });

    const clientForm = mapUpdateClientForm(client);
    const promise3 = clientForm
      ? updateClientMutate.mutateAsync(
          mapUpdateClientRequest({
            ...clientForm,
            additionalFeature: parseInt(data.additionalFeatureId),
          }),
        )
      : Promise.resolve();

    await Promise.all([promise1, promise2, promise3]).then(() => {
      onSuccess();
    });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>{children}</form>
    </FormProvider>
  );
};

export default ProfileSectionForm;
