import { useMemo } from "react";
import { useController } from "react-hook-form";

import { useAppTranslation } from "@hooks";

import {
  ModalLabel,
  StyledFormTextField,
} from "./AddQuestionnaireModal.styled";
import { ChipsSelect } from "@components/ChipsSelect";

import { QuestionnaireModalFormInputs } from "./useQuestionnaireModalForm";

export const NewQuestionnaireContent = () => {
  const { t } = useAppTranslation();
  const {
    field: { value: langId, onChange: setLangId },
  } = useController<QuestionnaireModalFormInputs>({ name: "langId" });

  const langOptions = useMemo(
    () => [
      {
        id: 1,
        label: "PL",
      },
      {
        id: 2,
        label: "EN",
      },
    ],
    [],
  );

  return (
    <div className="grid gap-4">
      <div className="grid gap-1">
        <ModalLabel>{t("questionnaires.name")}</ModalLabel>
        <StyledFormTextField
          name="name"
          size="small"
          placeholder={t("questionnaires.name")}
        />
      </div>

      <div className="grid gap-1">
        <ModalLabel>{t("questionnaires.language")}</ModalLabel>
        <ChipsSelect
          onChange={setLangId}
          selectedId={Number(langId)}
          options={langOptions}
        />
      </div>
    </div>
  );
};
