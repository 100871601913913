import { ReactNode } from "react";
import { useLocalStorage } from "react-use";
import {
  VisibleNutrientsContext,
  visibleNutrientsKey,
} from "@context/VisibleNutrientsContext/VisibleNutrientsContext";

interface VisibleNutrientsContextProviderProps {
  children: ReactNode;
}

export const VisibleNutrientsContextProvider = ({
  children,
}: VisibleNutrientsContextProviderProps) => {
  const [nutrients, setNutrients] = useLocalStorage<string[]>(
    visibleNutrientsKey,
    [],
  );

  return (
    <VisibleNutrientsContext.Provider
      value={{ nutrients: nutrients ?? [], setNutrients }}
    >
      {children}
    </VisibleNutrientsContext.Provider>
  );
};
