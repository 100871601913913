import htmlToPdfMake from "html-to-pdfmake";
import {
  PdfContentSectionType,
  PdfDaysScheduleType,
  PdfDisplayType,
  PdfFoodMenuType,
  PdfMealsSchemeType,
  PdfMealsSuggestedType,
  PdfNutrientsType,
  PdfShippingListType,
} from "@Pdf/types/pdfTypes";
import {
  Content,
  ContentColumns,
  ContentStack,
  ContentTable,
  Size,
} from "pdfmake/interfaces";
import {
  getDietInformationContent,
  getExtenderContent,
  getHorizontalDividerContent,
  getIconLabelContent,
  getMacrosContent,
  getMealTypeSectionContent,
  getMicrosContent,
  getNutrientsTableContent,
  getProductSectionContent,
} from "./pdfContentComponentsUtils";
import {
  SVG_CHECKBOX_ICON,
  SVG_FIRE_ICON,
  SVG_FORK_ICON,
} from "@Pdf/defaults/pdfSvgIconDefaults";
import { NutrientDetails } from "@hooks";
import format from "date-fns/format";
import { getSvgHorizontalSmallDivider } from "@Pdf/utils/pdfSvgComponentsUtils";
import {
  getNutrientDescription,
  getNutrientShortDescription,
  getNutrientValue,
  getVisibleMicros,
} from "@Pdf/utils/pdfNutrientUtils";
import {
  NUTRIENT_CARBS_ID,
  NUTRIENT_FAT_ID,
  NUTRIENT_KCAL_ID,
  NUTRIENT_PROTEIN_ID,
} from "@Pdf/defaults/pdfNutrientDefaults";
import { PDF_COLORS } from "@Pdf/defaults/pdfDefaults";

const cleanHTMLContent = (html: string): string => {
  // Create a temporary container to hold the HTML
  const container = document.createElement("div");
  container.innerHTML = html;

  // Get all <tr> and <td> elements
  const trsAndTds = Array.from(
    container.querySelectorAll("tr, td"),
  ) as Array<HTMLTableRowElement>;

  // Iterate over each <tr> and <td> element
  trsAndTds.forEach(element => {
    // Find all <p> tags that are direct children
    const pTags = Array.from(
      element.querySelectorAll(":scope > p"),
    ) as Array<HTMLParagraphElement>;

    pTags.forEach(pTag => {
      // Transfer styles from <p> to <td>
      Array.from(pTag.style).forEach((styleName: string) => {
        element.style[styleName as any] = pTag.style[styleName as any];
      });

      // Move the content of the <p> tag to the parent <tr> or <td>
      while (pTag.firstChild) {
        element.appendChild(pTag.firstChild);
      }
      // Remove the empty <p> tag
      pTag.remove();
    });
  });

  // Remove <a> tags without href and no content
  Array.from(container.querySelectorAll("a:not([href])")).forEach(aTag => {
    // If the <a> tag has no meaningful content, unwrap it
    if (!aTag.textContent || !aTag.textContent.trim()) {
      aTag.remove();
    } else {
      // Move its children to the parent and remove the <a> tag
      while (aTag.firstChild) {
        aTag.parentNode?.insertBefore(aTag.firstChild, aTag);
      }
      aTag.remove();
    }
  });

  // Additional cleanup tasks combined into one step
  return container.innerHTML
    .replace(
      /(\p{Emoji_Presentation}|\p{Extended_Pictographic})(\u200D(\p{Emoji_Presentation}|\p{Extended_Pictographic}))*|[\u{1F3FB}-\u{1F3FF}]/gu,
      "",
    ) // Clean emojis
    .replace(/<!-- pagebreak -->/g, "<pagebreakbefore></pagebreakbefore>") // Insert page breaks
    .replace(/(margin-left: auto|margin-right: auto)/g, (match: string) => {
      // Adjust auto margins
      if (match === "margin-left: auto")
        return "margin-left: 0; t-margin-left: auto";
      if (match === "margin-right: auto")
        return "margin-right: 0; t-margin-right: auto";
      return match;
    })
    .replace(
      /font-family:\s*(?!Figtree)[^;]+;|font-size:\s*(?:large|larger|medium|small|smaller|x-small|x-large|xx-small|xx-large|xxx-large);|line-height:\s*[^;]+;/g,
      "",
    ) // Clean unsupported attributes
    .replace(/(\d+(\.\d+)?)(pt)/g, (_, num: string) =>
      parseFloat(num).toString(),
    ); // Replace pt with js values
};

export const getContentSectionContent = (
  section: PdfContentSectionType,
  print: boolean,
): any =>
  htmlToPdfMake(cleanHTMLContent(section.data.content), {
    customTag: (node: any) => {
      // The id doesn't matter for PDF
      delete node.ret.id;
      // Default font is Figtree
      delete node.ret.font;

      if (node.ret.nodeName === "PAGEBREAKBEFORE") {
        node.ret.pageBreak = "before";
      }

      return node.ret;
    },
    removeExtraBlanks: true,
    imagesByReference: true,
    defaultStyles: {
      table: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        layout: print ? "listPrint" : "list",
        fontSize: 10,
      },
      h1: {
        fontSize: 24,
        bold: true,
        margin: [0, 12, 0, 12],
      },
      h2: {
        fontSize: 18,
        bold: true,
        margin: [0, 12, 0, 12],
      },
      h3: {
        fontSize: 14,
        bold: true,
        margin: [0, 12, 0, 12],
      },
      h4: {
        fontSize: 12,
        bold: true,
        margin: [0, 12, 0, 12],
      },
      h5: {
        fontSize: 10,
        bold: true,
        margin: [0, 12, 0, 12],
      },
      h6: {
        fontSize: 8,
        bold: true,
        margin: [0, 12, 0, 12],
      },
      ol: {
        marginLeft: 24,
      },
      ul: {
        marginLeft: 24,
      },
      th: {
        font: "MediumFigtree",
        color: "#727272",
        fillColor: "#ffffff",
      },
    },
  });

export const getMealsSuggestedSectionContent = (
  section: PdfMealsSuggestedType,
  imageRefs: Record<string, string>,
  nutrientDict: Record<number, NutrientDetails>,
  primaryColor: string,
  coverPageType: string,
  translations: Record<string, string>,
  display: PdfDisplayType,
): Array<Content> => {
  const items: Array<Content> = [];

  for (let i = 0; i < section.data.length; i += 1) {
    const item = section.data[i];
    const mealTypeContent: Array<Content> = [];

    for (let j = 0; j < item.mealsType.length; j += 1) {
      const mealType = item.mealsType[j];
      const mealsContent: Array<Content> = [];
      for (let k = 0; k < mealType.meals.length; k += 1) {
        const meal = mealType.meals[k];

        mealsContent.push(
          getProductSectionContent(
            meal.description,
            meal.products,
            meal.nutrientsForServings,
            meal.servings,
            imageRefs,
            nutrientDict,
            primaryColor,
            coverPageType,
            translations,
            display,
            k + 1,
          ),
          getHorizontalDividerContent([0, 12, 0, 12]),
        );
      }

      mealTypeContent.push(
        getMealTypeSectionContent(
          mealType.name,
          translations,
          display,
          mealType.hour,
          mealType.description,
        ),
        ...mealsContent,
      );
    }

    const stack: Array<Content> = [];

    const dietInformationContent = getDietInformationContent(
      item.programDay.name,
      item.programDay.frequency,
      item.programDay.targetNutrients,
      nutrientDict,
      primaryColor,
      display,
    );

    if (dietInformationContent.columns.length) {
      stack.push(dietInformationContent);
    }

    stack.push(
      {
        text: item.name,
        style: ["NEUTRAL_DARK_900_COLOR", "FONT_12_MEDIUM"],
      },
      getHorizontalDividerContent([0, 8, 0, 12]),
      ...mealTypeContent,
    );

    items.push({
      stack,
    });
  }

  return items;
};

export const getMealsSchemeSectionContent = (
  section: PdfMealsSchemeType,
  nutrientDict: Record<number, NutrientDetails>,
  primaryColor: string,
  coverPageType: string,
  translations: Record<string, string>,
  display: PdfDisplayType,
): Array<Content> => {
  const items: Array<Content> = [];

  for (let i = 0; i < section.data.length; i += 1) {
    const item = section.data[i];
    const mealContent: ContentStack = {
      stack: [],
    };

    for (let j = 0; j < item.diet.meals.length; j += 1) {
      const meal = item.diet.meals[j];

      const columns = [];

      if (display.hour) {
        columns.push({
          text: meal.hour,
          style: ["NEUTRAL_DARK_700_COLOR", "FONT_10_MEDIUM"],
          width: 32,
        });
      }

      columns.push({
        text: meal.name,
        style: ["PRIMARY_COLOR", "FONT_10_MEDIUM"],
        width: "*",
      });

      const nutrientsTableContent: ContentTable = getNutrientsTableContent(
        meal.nutrients,
        coverPageType,
      );
      const mealDetailsContent: ContentStack = {
        unbreakable: true,
        stack: [
          {
            columns,
            columnGap: 4,
          },
        ],
        margin: [0, 8, 0, 8],
      };

      if (nutrientsTableContent.table.body.length) {
        mealDetailsContent.stack.push(nutrientsTableContent);
      }

      mealContent.stack.push(mealDetailsContent);
    }

    const stack: Array<Content> = [];
    const dietInformationContent = getDietInformationContent(
      item.name,
      item.frequency,
      item.nutrients,
      nutrientDict,
      primaryColor,
      display,
    );

    if (dietInformationContent.columns.length) {
      stack.push(dietInformationContent);
    }

    stack.push(
      {
        text: item.diet.name,
        style: ["NEUTRAL_DARK_900_COLOR", "FONT_12_MEDIUM"],
      },
      getHorizontalDividerContent([0, 8, 0, 12]),
      {
        text: translations.macronutrientBreakdown,
        margin: [0, 0, 0, 4],
      },
      mealContent,
      getHorizontalDividerContent(),
    );

    items.push({
      pageBreak: i !== 0 ? "before" : undefined,
      stack,
    });
  }

  return items;
};

export const getDaysScheduleSectionContent = (
  section: PdfDaysScheduleType,
  imageRefs: Record<string, string>,
  nutrientDict: Record<number, NutrientDetails>,
  primaryColor: string,
  coverPageType: string,
  translations: Record<string, string>,
  display: PdfDisplayType,
): Array<Content> => {
  const items: Array<Content> = [];

  for (let i = 0; i < section.data.length; i += 1) {
    const item = section.data[i];
    const columns: Array<Content | (Content & { width?: Size })> = [
      {
        text: `${translations.day} ${i + 1}`,
        width: "auto",
        style: ["PRIMARY_COLOR", "FONT_12_MEDIUM"],
      },
    ];

    const text = [];

    if (display.date) {
      text.push(item.day.date);
    }

    if (display.weekdays.length) {
      text.push(item.day.name);
    }

    if (text.length) {
      columns.push({
        text: text.join(", "),
        style: ["NEUTRAL_DARK_900_COLOR", "FONT_12_MEDIUM"],
      });
    }

    const mealTypeContent: Array<Content> = [];

    const nutrients: Array<PdfNutrientsType> = [];
    const nutrientsCache: Map<number, number> = new Map();

    for (let j = 0; j < item.meals.length; j += 1) {
      const meal = item.meals[j];

      for (const nutrient of meal.nutrientsForServings) {
        if (nutrientsCache.has(nutrient.id)) {
          nutrients[nutrientsCache.get(nutrient.id)!].value += nutrient.value;
        } else {
          nutrientsCache.set(nutrient.id, nutrients.length);
          nutrients[nutrients.length] = nutrient;
        }
      }

      mealTypeContent.push({
        layout: "cardList",
        table: {
          headerRows: 1,
          keepWithHeaderRows: 1,
          widths: ["*"],
          body: [
            [
              getMealTypeSectionContent(
                meal.mealType.name,
                translations,
                display,
                meal.mealType.hour,
                meal.mealType.description,
              ),
            ],
            [
              getProductSectionContent(
                meal.mealDescription,
                meal.products.map(product => ({
                  name: product.name,
                  grams: product.weight.grams,
                  kcal: product.kcal,
                  measure: {
                    name: product.weight.measure.name,
                    value: product.weight.measure.value,
                  },
                })),
                meal.nutrientsForServings,
                meal.mealType.servings,
                imageRefs,
                nutrientDict,
                primaryColor,
                coverPageType,
                translations,
                display,
              ),
            ],
            [getHorizontalDividerContent([0, 12, 0, 12])],
          ],
        },
      });
    }

    const stack: Array<Content> = [
      {
        columns,
        columnGap: 4,
      },
      getHorizontalDividerContent(),
    ];

    const dietInformationContent = getDietInformationContent(
      item.programDay.name,
      item.frequency,
      item.nutrients,
      nutrientDict,
      primaryColor,
      display,
    );

    if (dietInformationContent.columns.length) {
      stack.push(dietInformationContent);
    }

    const visibleMicros = getVisibleMicros(display.micros, nutrients);

    if (visibleMicros.length) {
      dietInformationContent.margin = [0, 0, 0, 0];
      const microsContent = getMicrosContent(
        visibleMicros,
        nutrientDict,
        primaryColor,
      );
      microsContent.margin = [0, 4, 0, 4];
      stack.push(microsContent);
    }

    stack.push(
      {
        text: item.diet.name,
        style: ["NEUTRAL_DARK_900_COLOR", "FONT_12_MEDIUM"],
      },
      getHorizontalDividerContent([0, 8, 0, 12]),
    );

    stack.push(mealTypeContent);

    items.push({
      stack,
      margin: [0, 8, 0, 0],
    });
  }

  return items;
};

export const getShoppingListSectionContent = (
  section: PdfShippingListType,
  coverPageType: string,
  translations: Record<string, string>,
): Array<Content> => {
  const items: Array<Content> = [];

  for (let i = 0; i < section.data.length; i += 1) {
    const item = section.data[i];

    const leftListStack: Array<Content> = [];
    const rightListStack: Array<Content> = [];
    let leftListStackCount = 0;
    let rightListStackCount = 0;
    for (let j = 0; j < item.products.length; j += 1) {
      const { category, products } = item.products[j];

      const productsListStack: ContentStack = {
        margin: [0, 8, 0, 0],
        unbreakable: true,
        stack: [
          {
            text: category.name,
            style: ["PRIMARY_COLOR", "FONT_8_SEMI_BOLD"],
          },
          {
            layout: coverPageType === "print" ? "listPrint" : "list",
            table: {
              headerRows: 1,
              widths: ["*", "auto", "auto"],
              body: [
                [
                  {
                    text: translations.product,
                    style: ["NEUTRAL_DARK_700_COLOR"],
                  },
                  {
                    text: translations.mass,
                    style: ["NEUTRAL_DARK_700_COLOR"],
                  },
                  {
                    text: translations.measure,
                    style: ["NEUTRAL_DARK_700_COLOR"],
                  },
                ],
                ...products.map(product => [
                  {
                    width: "auto",
                    columns: [
                      {
                        svg: SVG_CHECKBOX_ICON,
                        width: 12,
                        height: 12,
                      },
                      {
                        text: product.name,
                        width: "*",
                        margin: [0, 1, 0, 0],
                      },
                    ],
                    columnGap: 4,
                  },
                  {
                    text: `${product.grams} g`,
                    noWrap: true,
                  },
                  {
                    width: "auto",
                    columns: [
                      {
                        width: 20,
                        text: +(
                          product.grams / product.productMeasure.grams
                        ).toFixed(2),
                      },
                      {
                        width: "*",
                        text: product.productMeasure.measure.name,
                        style: ["NEUTRAL_DARK_700_COLOR"],
                      },
                    ],
                  },
                ]),
              ],
            },
            fontSize: 8,
            margin: [0, 8, 0, 0],
          },
        ],
      };

      if (leftListStackCount < rightListStackCount) {
        leftListStack.push(productsListStack);

        leftListStackCount += products.length;
      } else {
        rightListStack.push(productsListStack);

        rightListStackCount += products.length;
      }
    }

    items.push({
      stack: [
        {
          columns: [
            {
              text: `${translations.shopping} ${i + 1}`,
              style: ["NEUTRAL_DARK_900_COLOR", "FONT_12_MEDIUM"],
              width: "auto",
            },
            {
              text: `${format(new Date(item.startAt), "dd.MM.yyyy")}-${format(
                new Date(item.finishAt),
                "dd.MM.yyyy",
              )}`,
              style: ["NEUTRAL_DARK_700_COLOR", "FONT_12_MEDIUM"],
              width: "auto",
            },
          ],
          margin: [0, 8, 0, 0],
          columnGap: 4,
        },
        {
          columns: [
            {
              stack: leftListStack,
            },
            {
              stack: rightListStack,
            },
          ],
          columnGap: 8,
        },
      ],
      pageBreak: i !== 0 ? "before" : undefined,
    });
  }

  return items;
};

const getMaxLevelForWeekday = (
  section: PdfFoodMenuType,
  weekdayNum: number,
  initialIndex: number,
): number => {
  let tmp = weekdayNum;
  let mealIndex = initialIndex;
  let maxLevel = 0;

  while (tmp < 7 && mealIndex < section.data.length) {
    maxLevel = Math.max(section.data[mealIndex].meals.length, maxLevel);

    tmp += 1;
    mealIndex += 1;
  }

  return maxLevel;
};

export const getFoodMenuSectionContent = (
  section: PdfFoodMenuType,
  coverPageType: string,
  translations: Record<string, string>,
  nutrientDict: Record<number, NutrientDetails>,
  primaryColor: string,
  primaryColorMid: string,
  primaryColorExtraLight: string,
  display: PdfDisplayType,
): Array<Content> => {
  const weekday = display.weekdays.length > 0;
  const print = coverPageType === "print";
  const items: Array<Content> = [];
  const tables: Array<Content> = [];

  const hideAllDetails = !display.kcal && !display.macros && !display.dayType;
  const hideHeaders = hideAllDetails && !display.date;
  let subtractFromHeader = 0;
  let subtractFromCard = 0;

  if (!display.kcal && !display.macros) {
    subtractFromHeader += 10;
    subtractFromCard += 10;
  }
  if (!display.dayType) subtractFromHeader += 8;
  if (hideAllDetails) {
    subtractFromHeader += 4;
    subtractFromCard += 6;
  }

  let headers: Array<Content> = [];
  let rows: Array<Array<Content>> = [];
  const startData =
    section.data.length > 0 ? new Date(section.data[0].day.date) : null;
  let weekdayNum = Math.max((startData ? startData.getDay() || 7 : 0) - 1, 0);

  let maxLevel = getMaxLevelForWeekday(section, weekdayNum, 0);

  // It's to tell how many placeholders we need to fill-in
  const initialWeekdayNum = weekdayNum;
  let addedPlaceholders = false;

  let allKcal = 0;
  const allMacros = {
    [NUTRIENT_PROTEIN_ID]: 0,
    [NUTRIENT_FAT_ID]: 0,
    [NUTRIENT_CARBS_ID]: 0,
  };

  let from: null | string = null;
  let to: null | string = null;
  let days = 0;

  for (let i = 0; i < section.data.length; i += 1) {
    days += 1;
    const item = section.data[i];

    if (!from) from = item.day.date;

    const text: Array<string> = [];

    if (display.date)
      text.push(`${format(new Date(item.day.date), "dd.MM.yyyy")}`);
    if (weekday) text.push(`${item.day.name}`);

    const headerStackContent: Content = [];

    if (text.length) {
      headerStackContent.push({
        text: text.join(", "),
        style: ["FONT_6_SEMI_BOLD"],
      });
    }

    if (!hideAllDetails && text.length) {
      headerStackContent.push({
        svg: getSvgHorizontalSmallDivider(
          print ? PDF_COLORS.NEUTRAL_DARK_900_COLOR : primaryColorMid,
        ),
        width: 96,
        height: 1,
        margin: [0, 2, 0, 2],
      });
    }

    if (display.dayType) {
      headerStackContent.push(
        getIconLabelContent(
          SVG_FORK_ICON(primaryColor),
          item.programDay.name,
          0,
          5,
          8,
          1,
        ),
      );
    }

    const columns: Array<Content> = [];

    if (display.kcal) {
      allKcal += getNutrientValue(item.nutrients, NUTRIENT_KCAL_ID);

      columns.push(
        getIconLabelContent(
          SVG_FIRE_ICON(primaryColor),
          getNutrientShortDescription(
            item.nutrients,
            nutrientDict,
            NUTRIENT_KCAL_ID,
          ),
          0,
          5,
          8,
          1,
          true,
        ),
      );
    }

    if (display.macros) {
      allMacros[NUTRIENT_PROTEIN_ID] += getNutrientValue(
        item.nutrients,
        NUTRIENT_PROTEIN_ID,
      );
      allMacros[NUTRIENT_FAT_ID] += getNutrientValue(
        item.nutrients,
        NUTRIENT_FAT_ID,
      );
      allMacros[NUTRIENT_CARBS_ID] += getNutrientValue(
        item.nutrients,
        NUTRIENT_CARBS_ID,
      );

      columns.push(
        getMacrosContent(
          item.nutrients,
          nutrientDict,
          true,
          [0, 1, 0, 0],
          5,
          "#727272",
        ),
      );
    }

    if (columns.length) {
      headerStackContent.push({
        columns,
        columnGap: 2,
        margin: [0, 2, 0, 0],
      });
    }

    headers.push({
      stack: [
        {
          canvas: [
            {
              type: "rect",
              x: 2,
              y: 2,
              w: 104,
              h: 38 - subtractFromHeader,
              color: primaryColorExtraLight,
              r: 4,
              lineWidth: 0.5,
              lineColor: print
                ? PDF_COLORS.NEUTRAL_DARK_900_COLOR
                : primaryColorMid,
            },
          ],
          relativePosition: { x: 0, y: 0 },
        },
        {
          stack: headerStackContent,
          margin: [6, 6, 6, 6],
        },
      ],
    });

    // TODO: item should not be a null - BE needs to handle it
    const meals = item.meals.filter(meal => meal.item !== null);

    for (let j = 0; j < meals.length; j += 1) {
      const meal = meals[j];

      const columns: Array<Content> = [];
      const mealHeaderColumnsContent: ContentColumns = {
        columns: [
          {
            text: meal.mealType.name,
            style: ["NEUTRAL_DARK_700_COLOR", "FONT_5_MEDIUM"],
            width: "auto",
            noWrap: true,
          },
        ],
      };

      if (display.hour) {
        mealHeaderColumnsContent.columns.push(getExtenderContent(), {
          text: meal.mealType.hour,
          style: ["NEUTRAL_DARK_700_COLOR", "FONT_5_MEDIUM"],
          alignment: "right",
          width: "auto",
          noWrap: true,
        });
      }

      let mealName = "-";
      if (meal.item) {
        mealName += `${meal.item.item.name}`;
        if (meal.item.servings > 1 || meal.item.item.servings > 1) {
          mealName += ` (${translations.serving}: ${meal.item.servings} / ${meal.item.item.servings})`;
        }
      }

      const mealStackContent: Array<Content> = [
        mealHeaderColumnsContent,
        {
          table: {
            heights: 20,
            widths: ["*"],
            body: [
              [
                {
                  text: mealName,
                  style: ["FONT_6_SEMI_BOLD"],
                  margin: [0, 2, 0, 2],
                },
              ],
            ],
          },
          layout: "cardList",
        },
      ];

      if (display.kcal) {
        columns.push(
          getIconLabelContent(
            SVG_FIRE_ICON(primaryColor),
            getNutrientShortDescription(
              meal.nutrients,
              nutrientDict,
              NUTRIENT_KCAL_ID,
            ),
            0,
            5,
            8,
            1,
          ),
        );
      }

      if (display.macros) {
        columns.push(
          getMacrosContent(
            meal.nutrients,
            nutrientDict,
            true,
            [0, 1, 0, 0],
            5,
            "#727272",
          ),
        );
      }

      if (columns.length) {
        mealStackContent.push(
          {
            svg: getSvgHorizontalSmallDivider(
              PDF_COLORS.NEUTRAL_DARK_200_COLOR,
            ),
            width: 96,
            height: 1,
            margin: [0, 0, 0, 2],
          },
          {
            columns,
            columnGap: 2,
            margin: [0, 2, 0, 0],
          },
        );
      }

      const mealContent: ContentStack = {
        stack: [
          {
            canvas: [
              {
                type: "rect",
                x: 2,
                y: 2,
                w: 104,
                h: 47 - subtractFromCard,
                color: !print && j % 2 === 0 ? "#FBFAFC" : "#FFFFFF",
                r: 4,
                lineColor: PDF_COLORS.NEUTRAL_DARK_200_COLOR,
                lineWidth: 0.5,
              },
            ],
            relativePosition: { x: 0, y: 0 },
          },
          {
            stack: mealStackContent,
            margin: [6, 6, 6, 6],
          },
        ],
        unbreakable: true,
      };

      if (!rows[j]) {
        rows[j] = [];
      }

      rows[j].push([mealContent]);
    }

    if (meals.length < maxLevel) {
      const diff = maxLevel - meals.length;
      const last = meals.length;

      for (let k = 0; k < diff; k += 1) {
        const index = last + k;
        if (!rows[index]) rows[index] = [];

        rows[index].push({
          canvas: [
            {
              type: "rect",
              x: 2,
              y: 2,
              w: 104,
              h: 47 - subtractFromCard,
              color: "#FFFFFF",
            },
          ],
        });
      }
    }

    weekdayNum += 1;

    if (weekdayNum === 7 || i === section.data.length - 1) {
      let headerRows = 0;
      const body = [];
      const heights = [];
      if (hideHeaders) {
        heights.push(...item.meals.map(() => 51 - subtractFromCard));
        body.push(...rows);
      } else {
        heights.push(
          40 - subtractFromHeader,
          ...item.meals.map(() => 51 - subtractFromCard),
        );

        if (!addedPlaceholders) {
          for (let i = 0; i < initialWeekdayNum; i += 1) {
            const text: Array<string> = [];

            if (display.date && startData)
              text.push(
                `${format(
                  new Date(startData).setDate(startData.getDate() - i - 1),
                  "dd.MM.yyyy",
                )}`,
              );
            if (weekday) text.push(translations[initialWeekdayNum - i]);

            const headerStackContent: Content = [];

            if (text.length) {
              headerStackContent.push({
                text: text.join(", "),
                style: ["FONT_6_SEMI_BOLD"],
              });
            }

            if (!hideAllDetails && text.length) {
              headerStackContent.push({
                svg: getSvgHorizontalSmallDivider(
                  print ? PDF_COLORS.NEUTRAL_DARK_900_COLOR : primaryColorMid,
                ),
                width: 96,
                height: 1,
                margin: [0, 2, 0, 2],
              });
            }

            if (display.dayType) {
              headerStackContent.push(
                getIconLabelContent(
                  SVG_FORK_ICON(primaryColor),
                  "-",
                  0,
                  5,
                  8,
                  1,
                ),
              );
            }

            const columns: Array<Content> = [];

            if (display.kcal) {
              columns.push(
                getIconLabelContent(
                  SVG_FIRE_ICON(primaryColor),
                  "-",
                  0,
                  5,
                  8,
                  1,
                  true,
                ),
              );
            }

            if (columns.length) {
              headerStackContent.push({
                columns,
                columnGap: 2,
                margin: [0, 2, 0, 0],
              });
            }

            headers.unshift({
              stack: [
                {
                  canvas: [
                    {
                      type: "rect",
                      x: 2,
                      y: 2,
                      w: 104,
                      h: 38 - subtractFromHeader,
                      color: primaryColorExtraLight,
                      r: 4,
                      lineWidth: 0.5,
                      lineColor: print
                        ? PDF_COLORS.NEUTRAL_DARK_900_COLOR
                        : primaryColorMid,
                    },
                  ],
                  relativePosition: { x: 0, y: 0 },
                },
                {
                  stack: headerStackContent,
                  margin: [6, 6, 6, 6],
                },
              ],
            });

            for (let j = 0; j < rows.length; j += 1) {
              const mealStackContent: Array<Content> = [
                {
                  text: translations.emptyProgram,
                  style: ["NEUTRAL_DARK_600_COLOR", "FONT_6_SEMI_BOLD"],
                  margin: [0, 0, 0, 19.5],
                },
              ];

              if (display.kcal) {
                mealStackContent.push(
                  {
                    svg: getSvgHorizontalSmallDivider(
                      PDF_COLORS.NEUTRAL_DARK_200_COLOR,
                    ),
                    width: 96,
                    height: 1,
                    margin: [0, 0, 0, 3],
                  },
                  getIconLabelContent(
                    SVG_FIRE_ICON(primaryColor),
                    "-",
                    0,
                    5,
                    8,
                    1,
                  ),
                );
              }
              rows[j].unshift({
                stack: [
                  {
                    canvas: [
                      {
                        type: "rect",
                        x: 2,
                        y: 2,
                        w: 104,
                        h: 47 - subtractFromCard,
                        color: !print && j % 2 === 0 ? "#FBFAFC" : "#FFFFFF",
                        r: 4,
                        lineColor: PDF_COLORS.NEUTRAL_DARK_200_COLOR,
                        lineWidth: 0.5,
                      },
                    ],
                    relativePosition: { x: 0, y: 0 },
                  },
                  {
                    stack: mealStackContent,
                    margin: [6, 6, 6, 6],
                  },
                ],
                unbreakable: true,
              });
            }
          }
        }

        body.push(headers, ...rows);
        headerRows = 1;
      }

      const textContent: any = {
        text: section.name,
        style: ["NEUTRAL_DARK_900_COLOR", "FONT_12_SEMI_BOLD"],
        width: "auto",
      };
      if (tables.length === 0) {
        textContent.id = `${section.id}`;
      }
      const headerColumnsContent: ContentColumns = {
        columns: [textContent],
        columnGap: 4,
        margin: [0, 0, 0, 8],
      };

      to = item.day.date;

      if (display.date) {
        if (from && to) {
          headerColumnsContent.columns.push({
            text: `${format(
              addedPlaceholders || initialWeekdayNum === 0
                ? new Date(from)
                : new Date(from).setDate(
                    new Date(from).getDate() - initialWeekdayNum,
                  ),
              "dd.MM.yyyy",
            )} - ${format(new Date(to), "dd.MM.yyyy")}`,
            style: ["NEUTRAL_DARK_700_COLOR", "FONT_12_MEDIUM"],
            width: "auto",
          });
        } else if (from) {
          headerColumnsContent.columns.push({
            text: `${format(
              addedPlaceholders || initialWeekdayNum === 0
                ? new Date(from)
                : new Date(from).setDate(
                    new Date(from).getDate() - initialWeekdayNum,
                  ),
              "dd.MM.yyyy",
            )}`,
            style: ["NEUTRAL_DARK_700_COLOR", "FONT_12_MEDIUM"],
            width: "auto",
          });
        }
      }

      const headerDetailsContent: ContentColumns & { width: Size } = {
        columns: [
          {
            text: `${translations.avgValues} (${days} ${translations.days})`,
            style: ["NEUTRAL_DARK_900_COLOR", "FONT_8_SEMI_BOLD"],
            width: "auto",
            noWrap: true,
            margin: [0, 3, 0, 0],
          },
        ],
        columnGap: 16,
        width: "auto",
        unbreakable: true,
      };

      if (display.kcal) {
        headerDetailsContent.columns.push(
          getIconLabelContent(
            SVG_FIRE_ICON(primaryColor),
            `${Math.floor(allKcal / days)} ${
              nutrientDict[NUTRIENT_KCAL_ID].name
            }`,
            2,
            8,
            12,
            4,
            true,
          ),
        );
      }

      if (display.macros) {
        headerDetailsContent.columns.push({
          text: `${getNutrientDescription(
            {
              id: NUTRIENT_PROTEIN_ID,
              value: Math.floor(allMacros[NUTRIENT_PROTEIN_ID] / days),
            },
            nutrientDict,
          )}  -  ${getNutrientDescription(
            {
              id: NUTRIENT_FAT_ID,
              value: Math.floor(allMacros[NUTRIENT_FAT_ID] / days),
            },
            nutrientDict,
          )}  -  ${getNutrientDescription(
            {
              id: NUTRIENT_CARBS_ID,
              value: Math.floor(allMacros[NUTRIENT_CARBS_ID] / days),
            },
            nutrientDict,
          )}`,
          margin: [0, 3, 0, 0],
          style: ["NEUTRAL_DARK_800_COLOR", "FONT_8_SEMI_BOLD"],
          width: "auto",
          noWrap: true,
        });
      }

      const columns = [headerColumnsContent];

      if (display.kcal || display.macros) columns.push(headerDetailsContent);

      tables.push({
        stack: [
          {
            columns,
            columnGap: 0,
          },
          {
            table: {
              heights,
              headerRows,
              widths: [108, 108, 108, 108, 108, 108, 108],
              body,
              dontBreakRows: true,
            },
            layout: "cardList",
          },
        ],
        pageBreak: tables.length !== 0 ? "before" : undefined,
      });

      weekdayNum = 0;
      headers = [];
      rows = [];
      allKcal = 0;
      allMacros[NUTRIENT_PROTEIN_ID] = 0;
      allMacros[NUTRIENT_FAT_ID] = 0;
      allMacros[NUTRIENT_CARBS_ID] = 0;
      from = null;
      days = 0;
      addedPlaceholders = true;

      maxLevel = getMaxLevelForWeekday(section, weekdayNum, i);
    }
  }

  items.push(tables);

  return items;
};
