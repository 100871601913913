import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const ArrowDown = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="8" viewBox="0 0 14 8" {...props}>
      <path
        d="M13.5312 1.53125L7.5625 7.25C7.35417 7.41667 7.16667 7.5 7 7.5C6.8125 7.5 6.63542 7.42708 6.46875 7.28125L0.46875 1.53125C0.322917 1.38542 0.25 1.20833 0.25 1C0.25 0.791667 0.322917 0.614583 0.46875 0.46875C0.802083 0.177083 1.15625 0.177083 1.53125 0.46875L7 5.71875L12.4688 0.46875C12.8438 0.177083 13.1979 0.1875 13.5312 0.5C13.6771 0.625 13.75 0.791667 13.75 1C13.75 1.20833 13.6771 1.38542 13.5312 1.53125Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
