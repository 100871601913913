import dayjs from "dayjs";

import { useAppParams } from "@hooks";
import {
  ProgramScheduleNavigationContext,
  useProgramScheduleNavigationContext,
} from "@views/dietician/ProgramSchedule/context";
import { ProgramScheduleTour } from "components/Tours/ProgramScheduleTour";
import { FetchPatientProgramResponse } from "@client/program";
import {
  useFetchDietitianClientProgramScheduleQuery,
  useInitDietitianProgramScheduleMutation,
} from "@hooks/queries/schedule";
import { useProgram } from "@hooks/resources";

import { ProgramScheduleView } from "./ProgramScheduleView";
import { useEffect, useState } from "react";

export const ClientProgramSchedule = () => {
  const { patientId, programId } = useAppParams();
  const parsedClientId = parseInt(patientId);
  const programIdParsed = parseInt(programId);

  const { mutate: initSchedule, isLoading: isGenerating } =
    useInitDietitianProgramScheduleMutation(parseInt(programId));

  const {
    program,
    isLoading: isProgramLoading,
    isFetching: isProgramFetching,
  } = useProgram();

  const programCast = program as FetchPatientProgramResponse | undefined;

  const {
    context,
    queryData: { view, date },
  } = useProgramScheduleNavigationContext({
    start: programCast && dayjs(programCast.startDate),
    end:
      programCast && programCast.finishDate
        ? dayjs(programCast.finishDate)
        : dayjs(programCast?.startDate).add(90, "days"),
    showDates: true,
    focusOnToday: true,
  });

  const [enableSchedule, setEnableSchedule] = useState(false);

  useEffect(() => {
    if (!enableSchedule && !isGenerating) {
      initSchedule(undefined, { onSuccess: () => setEnableSchedule(true) });
    }
  });

  const { scheduleData, isLoading: isScheduleLoading } =
    useFetchDietitianClientProgramScheduleQuery(
      patientId,
      programId,
      view,
      date,
      {
        enabled: enableSchedule,
      },
    );

  return (
    <>
      <ProgramScheduleTour />
      <ProgramScheduleNavigationContext.Provider value={context}>
        <ProgramScheduleView
          scheduleData={scheduleData}
          programId={programIdParsed}
          clientId={parsedClientId}
          isLoading={isScheduleLoading}
          program={program}
          isProgramLoading={isProgramLoading || isProgramFetching}
          isGenerating={isGenerating}
        />
      </ProgramScheduleNavigationContext.Provider>
    </>
  );
};
