import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ProductInDietDto } from "@client/diets";
import { useAppParams, useAppTranslation, useMealClientContext } from "@hooks";
import { useFetchDietProductsListQuery } from "@hooks/queries/diets";
import { getTranslation } from "@utils/translations";

import { CategoryTitle } from "./CategoryTitle";
import { NoProductsEmptyState } from "./EmptyState";
import { ProductAccordion } from "./ProductAccordion";
import { groupProductsByCategory } from "./productsListUtils";
import { CircularProgress } from "@mui/material";
import { getClientContextSummary } from "./productListsUtils";
import { ChipFilters } from "./ChipFilters";
import { usePostPatientProductsContextMutation } from "@hooks/queries";

interface ProductsListProps {
  group: boolean;
  query?: string;
  setCount: (count: number) => void;
}

export const ProductsList = ({ group, query, setCount }: ProductsListProps) => {
  const { dietId } = useAppParams();
  const { currentLanguage, isPolishChosen, t } = useAppTranslation();
  const navigate = useNavigate();
  const { data, isLoading } = useFetchDietProductsListQuery(dietId, {
    onSuccess: d => setCount(d?.data.length ?? 0),
    enabled: !!dietId,
  });
  const [filterList, setFilterList] = useState<number[]>([]);

  const filteredProducts = useMemo(
    () =>
      data
        ?.filter(({ product, mainProductCategory }) => {
          const queryLowerCase = query?.toLowerCase() ?? "";
          const queryInName = getTranslation(
            product.translations,
            currentLanguage,
          )
            .toLowerCase()
            .includes(queryLowerCase);
          const queryInCategory =
            group &&
            (isPolishChosen
              ? mainProductCategory.descriptionPl
              : mainProductCategory.description
            )
              .toLowerCase()
              .includes(queryLowerCase);

          return queryInName || queryInCategory;
        })
        .filter(({ product }) =>
          filterList.length ? filterList.includes(product.id) : true,
        ),
    [data, query, currentLanguage, group, filterList],
  );

  const foodIds = useMemo(
    () => data?.map(({ product }) => product.id) ?? [],
    [data],
  );
  const { mutate: fetchProducts } = usePostPatientProductsContextMutation();

  const { productsClientContextMap } = useMealClientContext();
  const clientContextSummary = getClientContextSummary(
    foodIds,
    productsClientContextMap,
  );

  const groupedProducts = useMemo(
    () => groupProductsByCategory(filteredProducts ?? []),
    [filteredProducts],
  );
  const productsSorted = useMemo(
    () =>
      filteredProducts?.sort((a, b) => {
        const aName = getTranslation(a.product.translations, currentLanguage);
        const bName = getTranslation(b.product.translations, currentLanguage);
        return aName.localeCompare(bName);
      }) ?? [],
    [filteredProducts, currentLanguage],
  );

  const sortProducts = (a: ProductInDietDto, b: ProductInDietDto) => {
    const aName = getTranslation(a.product.translations, currentLanguage);
    const bName = getTranslation(b.product.translations, currentLanguage);
    return aName.localeCompare(bName);
  };

  const navigateToRecipes = () => navigate(`/diets/${dietId}/edit/recipes`);

  useEffect(() => {
    if (foodIds.length) fetchProducts({ payload: foodIds });
  }, [foodIds.length]);

  if (isLoading)
    return (
      <div className="grid place-items-center">
        <CircularProgress />
      </div>
    );

  if (!data?.length)
    return (
      <NoProductsEmptyState
        infoText={t("diet.empty_state.products_info")}
        onClick={navigateToRecipes}
        buttonText={t("diet.empty_state.add_recipe")}
      />
    );

  if (group)
    return (
      <>
        <ChipFilters
          summary={clientContextSummary}
          setFilterList={setFilterList}
        />
        {Object.entries(groupedProducts).map(([categoryId, data]) => {
          const { categoryNameEn, categoryNamePl, products } = data;
          const mainCategoryId = data.products[0].mainProductCategory.id;

          return (
            <div key={categoryId} className="grid gap-[16px]">
              <CategoryTitle
                id={mainCategoryId}
                nameEn={categoryNameEn}
                namePl={categoryNamePl}
              />
              <ul className="grid gap-[8px]">
                {products
                  .sort(sortProducts)
                  .map(({ grams, product, wasExchange }) => (
                    <ProductAccordion
                      key={product.id}
                      grams={grams}
                      name={getTranslation(
                        product.translations,
                        currentLanguage,
                      )}
                      tags={product.tags.map(t => t.id)}
                      wasExchange={wasExchange}
                      id={product.id}
                    />
                  ))}
              </ul>
            </div>
          );
        })}
      </>
    );

  return (
    <>
      <ChipFilters
        summary={clientContextSummary}
        setFilterList={setFilterList}
      />
      {productsSorted?.map(({ grams, product, wasExchange }) => (
        <ProductAccordion
          key={product.id}
          grams={grams}
          name={getTranslation(product.translations, currentLanguage)}
          tags={product.tags.map(t => t.id)}
          wasExchange={wasExchange}
          id={product.id}
        />
      ))}
    </>
  );
};
