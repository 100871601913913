export const patientKeys = {
  base: (id: number) => ["patient", id],
  nutrient: (id: number) => [...patientKeys.base(id), "nutrient"],
  hydrationProduct: (id: number) => [
    ...patientKeys.base(id),
    "hydration_product",
  ],
  productExchangers: (id: number) => [
    ...patientKeys.base(id),
    "product_exchangers",
  ],
  bodyMeasurement: (id: number) => [
    ...patientKeys.base(id),
    "body_measurement",
  ],
  monitoring: (id: number) => [...patientKeys.base(id), "monitoring"],
};
