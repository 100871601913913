import { useMemo } from "react";
import {
  FieldValues,
  Path,
  useController,
  useFormContext,
} from "react-hook-form";

import { Autocomplete, TextField } from "@mui/material";

import { OptionIf } from "../FiltersBuilder";
import { Label, Wrapper } from "./AutocompleteFilter.styled";

export interface AutocompleteFilterProps<T extends FieldValues> {
  label: string;
  options: OptionIf[];
  name?: Path<T>;
  placeholder: string;
}

export const AutocompleteFilter = <T extends FieldValues>({
  label,
  options,
  name,
  placeholder,
}: AutocompleteFilterProps<T>) => {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ control, name: name ?? "tags" });
  const selected = value as string[] | undefined;

  const toggleSelect = (id: string) => {
    if (selected?.includes(id)) return onChange(selected.filter(s => s !== id));
    return onChange([...(selected ?? []), id]);
  };

  const selectedOptions = useMemo(
    () => options.filter(option => selected?.some(id => option.id === id)),
    [options, selected],
  );

  return (
    <Wrapper>
      <Label>{label}</Label>
      <Autocomplete
        size="small"
        options={options}
        value={selectedOptions}
        onChange={(e, value) => onChange(value.map(({ id }) => id))}
        multiple
        renderInput={params => (
          <TextField {...params} placeholder={placeholder} />
        )}
        ChipProps={{
          color: "primary",
        }}
      />
    </Wrapper>
  );
};
