import {
  OutlinedInput,
  Slider,
  styled,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

export const MacrosSlidersWrapper = styled("div")`
  display: grid;
  padding-top: 2.5rem;
  gap: 2rem;
`;

export const LabelStyled = styled(Typography)`
  min-width: 7.5rem;
  text-align: end;
`;

export const AlertWrapperStyled = styled("div")`
  display: flex;
  justify-content: flex-start;
  padding-left: 9rem;
`;

export const SliderStyled = styled(Slider)`
  width: 10rem;
  flex: 1;
`;

export const MacroOutlinedInputStyled = styled(OutlinedInput)`
  width: 7.5rem;
`;

export const ToggleButtonGroupStyled = styled(ToggleButtonGroup)`
  margin-left: 9rem;
`;

export const RowStyled = styled("div")`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

export const NutritionModalFlex = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  margin-top: 1.5rem;
`;
