import { MacrosChart } from "@components/PreviewDrawer/common/MacrosChart";
import { Stack } from "@mui/material";
import { Nutrient } from "@typeDefinitions";
import MicroNutrients from "@components/ProgramSearchPreview/Components/DietNutrients/MicroNutrients";

type DietNutrientsProps = {
  nutrients: Nutrient[];
};

const DietNutrients = ({ nutrients }: DietNutrientsProps) => {
  return (
    <Stack direction="column" gap="24px">
      <MacrosChart nutrients={nutrients} />
      <MicroNutrients nutrients={nutrients} />
    </Stack>
  );
};

export default DietNutrients;
