import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const PlusRounded = (props: SvgIconProps) => (
  <SvgIcon width="11" height="11" viewBox="0 0 11 11" {...props}>
    <path
      d="M10.7812 5.42859C10.7812 5.66557 10.7051 5.86023 10.5527 6.01257C10.4004 6.16492 10.2057 6.24109 9.96875 6.24109H6.3125V9.89734C6.3125 10.1343 6.23633 10.329 6.08398 10.4813C5.93164 10.6337 5.73698 10.7098 5.5 10.7098C5.26302 10.7098 5.06836 10.6337 4.91602 10.4813C4.76367 10.329 4.6875 10.1343 4.6875 9.89734V6.24109H1.03125C0.794271 6.24109 0.599609 6.16492 0.447266 6.01257C0.294922 5.86023 0.21875 5.66557 0.21875 5.42859C0.21875 5.19161 0.294922 4.99695 0.447266 4.8446C0.599609 4.69226 0.794271 4.61609 1.03125 4.61609H4.6875V0.959839C4.6875 0.72286 4.76367 0.528198 4.91602 0.375854C5.06836 0.223511 5.26302 0.147339 5.5 0.147339C5.73698 0.147339 5.93164 0.223511 6.08398 0.375854C6.23633 0.528198 6.3125 0.72286 6.3125 0.959839V4.61609H9.96875C10.2057 4.61609 10.4004 4.69226 10.5527 4.8446C10.7051 4.99695 10.7812 5.19161 10.7812 5.42859Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
