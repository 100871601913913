import { memo, ReactNode } from "react";
import { Path, UseFormReturn } from "react-hook-form";

import { useAppTranslation, useUserRoles } from "@hooks";
import { SearchBar } from "@components/SearchBar";
import { FilterDefinition, FilterType } from "@typeDefinitions";

import {
  MultiSelectFilter,
  RangeFilter,
  SearchFilterTags,
  SingleFilter,
} from "./components";
import { TreeSelectFilter } from "./components/TreeSelectFilter";

import {
  FilterTagsWrapper,
  SearchBarWrapper,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledTitle,
} from "./SearchFilters.styled";
import { FieldValues } from "react-hook-form/dist/types";

declare module "react" {
  function memo<A, B>(
    Component: (props: A) => B,
  ): (props: A) => ReactElement | null;
}

interface SearchFiltersProps<T extends FieldValues> {
  form: UseFormReturn<T>;
  searchFieldName: Path<T>;
  searchFieldPlaceholderLabel: string;
  actionComponent?: ReactNode;
  actionComponentStart?: ReactNode;
  filters: FilterDefinition<T>[];
  onSubmit?: () => any;
  flip?: boolean;
}

export const SearchFilters = memo(
  <T extends FieldValues>(props: SearchFiltersProps<T>) => {
    const { t } = useAppTranslation();
    const filtersInLine = 7;
    const { isAdminClinicMember } = useUserRoles();

    return (
      <div className="flex flex-col mb-4">
        <SearchBarWrapper order={props.flip ? 2 : 0}>
          {props.actionComponentStart}
          <SearchBar
            name={props.searchFieldName}
            control={props.form.control}
            placeholder={t(props.searchFieldPlaceholderLabel)}
            onSearch={props.onSubmit}
          />
          {props.actionComponent}
        </SearchBarWrapper>

        <StyledAccordion defaultExpanded order={props.flip ? 0 : 1}>
          <StyledAccordionSummary>
            <StyledTitle className="text-xl">
              {t("common.filters")}:
            </StyledTitle>
          </StyledAccordionSummary>

          <StyledAccordionDetails>
            <div
              className={`rounded-md grid mt-8 grid-cols-${filtersInLine} gap-7`}
            >
              {props.filters
                .filter(filter => {
                  if (
                    filter !== "spacer" &&
                    filter.type === FilterType.SINGLE
                  ) {
                    return isAdminClinicMember
                      ? !filter.clinicOnly
                      : !filter.adminOnly;
                  }
                  return true;
                })
                .map((filter, i) => {
                  if (filter === "spacer") {
                    return (
                      <div
                        key={i}
                        className={`col-span-${
                          filtersInLine - (i % filtersInLine)
                        }`}
                      />
                    );
                  }

                  if (filter.type === FilterType.SINGLE) {
                    return (
                      <SingleFilter
                        key={filter.name}
                        filter={filter}
                        form={props.form}
                      />
                    );
                  }

                  if (filter.type === FilterType.MULTI) {
                    return (
                      <MultiSelectFilter
                        key={filter.name}
                        filter={filter}
                        form={props.form}
                      />
                    );
                  }

                  if (filter.type === FilterType.RANGE) {
                    return (
                      <RangeFilter
                        key={filter.name}
                        filter={filter}
                        form={props.form}
                      />
                    );
                  }

                  if (filter.type === FilterType.TREE_SELECT) {
                    return (
                      <TreeSelectFilter
                        key={filter.name}
                        filter={filter}
                        form={props.form}
                      />
                    );
                  }
                })}
            </div>
          </StyledAccordionDetails>
        </StyledAccordion>

        <FilterTagsWrapper order={props.flip ? 1 : 2}>
          <SearchFilterTags
            form={props.form}
            filters={props.filters}
            onSearch={props.onSubmit ?? (() => null)}
          />
        </FilterTagsWrapper>
      </div>
    );
  },
);
