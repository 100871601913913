import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Clip = (props: SvgIconProps) => (
  <SvgIcon width="25" height="25" viewBox="0 0 25 25" {...props}>
    <path
      d="M7.94046 6.00712C7.94891 5.52321 8.34669 5.13908 8.8306 5.14752L15.8295 5.26969C16.3134 5.27814 16.6976 5.67592 16.6891 6.15983C16.6807 6.64374 16.2829 7.02787 15.799 7.01942L14.9925 7.00535L15.2334 11.0625C16.2273 11.6241 17.0042 12.5483 17.3617 13.6895L17.3876 13.772C17.4732 14.0415 17.4216 14.3332 17.2536 14.5573C17.0856 14.7813 16.818 14.9134 16.5364 14.9085L7.78775 14.7558C7.50616 14.7509 7.24603 14.6123 7.08327 14.3798C6.92051 14.1472 6.88187 13.8539 6.97672 13.5903L7.00549 13.5087C7.40258 12.3807 8.21132 11.4842 9.22418 10.9576L9.60658 6.91134L8.80006 6.89726C8.31615 6.88881 7.93202 6.49103 7.94046 6.00712ZM11.272 15.6917L13.0217 15.7223L12.9759 18.3469C12.9674 18.8308 12.5696 19.2149 12.0857 19.2065C11.6018 19.198 11.2177 18.8002 11.2261 18.3163L11.272 15.6917Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
