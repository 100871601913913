import { Tooltip, useTheme } from "@mui/material";
import { round } from "lodash";

import { useNutrientDictionary } from "@hooks";
import { CARBS_ID, ENERGY_ID, FATS_ID, PROTEIN_ID } from "@utils/macros";

import {
  LabelDot,
  LabelLongWrapper,
  LabelShortWrapper,
  NameTypographyStyled,
  StyledTypography,
} from "./NutrientLabel.styled";

interface NutrientLabelProps {
  id: number;
  shortName?: boolean;
  value: number;
  categoryId?: number;
}

export const NutrientLabel = ({
  id,
  shortName = false,
  value,
  categoryId = 0,
}: NutrientLabelProps) => {
  const { getNutrient } = useNutrientDictionary();
  const nutrient = getNutrient(id);
  const {
    colors: { chartPurple, chartOrange, chartGreen },
    palette: { primary },
  } = useTheme();
  const COLORS_BY_ID: Record<string, string> = {
    [ENERGY_ID]: primary.main,
    [PROTEIN_ID]: chartPurple,
    [FATS_ID]: chartOrange,
    [CARBS_ID]: chartGreen,
  };
  const COLORS_BY_CATEGORY: Record<string, string> = {
    "3": "#E2F3E6",
    "4": "#EDF2FE",
    "5": "#F5F1FE",
    "6": "#FEF5EB",
    "7": "#E2F3E6",
  };

  if (shortName)
    return (
      <Tooltip title={nutrient?.name}>
        <LabelShortWrapper>
          <LabelDot
            background={COLORS_BY_CATEGORY[categoryId] ?? primary.main}
          />
          <NameTypographyStyled variant="subtitle2">
            {nutrient?.short}:
          </NameTypographyStyled>
          <StyledTypography variant="body2">
            {round(value)} {nutrient?.unit}
          </StyledTypography>
        </LabelShortWrapper>
      </Tooltip>
    );

  return (
    <LabelLongWrapper>
      <LabelDot background={COLORS_BY_ID[id]} />
      <NameTypographyStyled variant="subtitle2">
        {nutrient?.name}:
      </NameTypographyStyled>
      <StyledTypography variant="body2">
        {round(value)} {nutrient?.unit}
      </StyledTypography>
    </LabelLongWrapper>
  );
};
