import {
  memo,
  Children,
  isValidElement,
  cloneElement,
  ReactElement,
} from "react";

/**
 * single tab for tabs
 */
export const Tab = memo((props: TabProps) => {
  const { id, title, children } = props;

  const childrenWithProps = Children.map(children, child => {
    if (isValidElement<TabProps>(child)) {
      const props: Partial<TabProps> = {
        title,
      };
      return cloneElement(child, props);
    }
    return child;
  });

  return (
    <div id={id} className="w-full h-full">
      {childrenWithProps}
    </div>
  );
});

export interface TabProps {
  /** unique id */
  id: string;
  /** title for headers */
  title: string;
  /** children */
  children: ReactElement | ReactElement[];
  error?: boolean;
  hasSubstitutes?: boolean;
}
