import { useEffect, useState } from "react";
import { useDebounce } from "react-use";
import { useFormContext, useWatch } from "react-hook-form";

import { QuestionFormInputs } from "./Question/useQuestionForm";

interface QuestionEditWatchProps<T extends QuestionFormInputs> {
  onSubmit: (data: T) => void;
  isLoading: boolean;
}

export function QuestionEditWatch<T extends QuestionFormInputs>({
  onSubmit,
  isLoading,
}: QuestionEditWatchProps<T>) {
  const values = useWatch<T>();
  const {
    handleSubmit,
    formState: { isDirty },
  } = useFormContext<T>();

  const [queued, setQueued] = useState(false);

  const update = () => {
    setQueued(false);
    handleSubmit(
      data => onSubmit(data),
      err => console.log(err),
    )();
  };

  const [isDirtyLatch, setIsDirtyLatch] = useState(isDirty);

  useDebounce(
    () => {
      if (isLoading) {
        setQueued(true);
      }

      if ((isDirty || isDirtyLatch) && values && !isLoading) {
        setIsDirtyLatch(true);
        update();
      }
    },
    1000,
    [JSON.stringify(values)],
  );

  useEffect(() => {
    if (queued && !isLoading) {
      update();
    }
  }, [queued, isLoading]);

  return <></>;
}
