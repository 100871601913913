import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import {
  FullSurveyDto,
  QuestionType,
  fullSurveySchema,
} from "./fetchSurveyFull";
import {
  IdDto,
  LangDto,
  SexDto,
  TranslationDto,
  idSchema,
  sexSchema,
  translationSchema,
} from "@client/common";
import * as yup from "yup";

export const fetchSingedSurveys = async (
  url: string,
): Promise<ApiResponse<FetchSingedSurveysResponse>> => {
  const response = await fetchData(
    url,
    APIMethods.GET,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    "",
  );

  return fetchSignedSurveysResponseSchema.validate(response);
};

export interface FetchSingedSurveysResponse {
  survey: FullSurveyDto;
  answers: (
    | TextAnswerResourceDto
    | TextAreaAnswerResourceDto
    | CheckboxAnswerResourceDto
    | RadioAnswerResourceDto
    | SwitchAnswerResourceDto
    | DateAnswerResourceDto
    | TimeAnswerResourceDto
    | DateTimeAnswerResourceDto
    | SelectAnswerResourceDto
    | TableAnswerResourceDto
    | UserFatTissueAnswerResourceDto
    | UserWeightAnswerResourceDto
    | UserHeightAnswerResourceDto
    | MeasurementAnswerResourceDto
    | BirthdayAnswerResourceDto
    | CooperationGoalsAnswerResourceDto
    | UserTargetBodyWeightAnswerResourceDto
    | PregnantWomanAnswerResourceDto
    | SexAnswerResourceDto
    | UserPreferencesFoodUnlikedAnswerResourceDto
    | UserPreferencesFoodLikedAnswerResourceDto
    | TagAllergenAnswerResourceDto
    | TagDietTypeAnswerResourceDto
  )[];
  dictionaries: DictionariesDto;
}

interface UuidDto {
  uuid: string;
}

interface SurveyAnswerResourceDto<T> {
  id: number;
  survey: IdDto;
  question: IdDto;
  patient: IdDto;
  type: QuestionType;
  answer: T;
}

export interface TextAnswerQuestionDto {
  value: string | null;
}

export interface TextAnswerResourceDto
  extends SurveyAnswerResourceDto<TextAnswerQuestionDto> {
  type: QuestionType.TEXT;
}

export interface TextAreaAnswerResourceDto
  extends SurveyAnswerResourceDto<TextAnswerQuestionDto> {
  type: QuestionType.TEXTAREA;
}

export interface CheckboxAnswerQuestionDto {
  uuids: string[];
  text: string | null;
}

export interface CheckboxAnswerResourceDto
  extends SurveyAnswerResourceDto<CheckboxAnswerQuestionDto> {
  type: QuestionType.CHECKBOX;
}

export interface RadioAnswerQuestionDto {
  uuid: string | null;
  text: string | null;
}

export interface RadioAnswerResourceDto
  extends SurveyAnswerResourceDto<RadioAnswerQuestionDto> {
  type: QuestionType.RADIO;
}

export interface SwitchAnswerQuestionDto {
  value: number;
  text: string | null;
}

export interface SwitchAnswerResourceDto
  extends SurveyAnswerResourceDto<SwitchAnswerQuestionDto> {
  type: QuestionType.SWITCH;
}

export interface DateAnswerQuestionDto {
  value: string | null;
}

export interface DateAnswerResourceDto
  extends SurveyAnswerResourceDto<DateAnswerQuestionDto> {
  type: QuestionType.DATE;
}

export interface TimeAnswerQuestionDto {
  value: string | null;
}

export interface TimeAnswerResourceDto
  extends SurveyAnswerResourceDto<TimeAnswerQuestionDto> {
  type: QuestionType.TIME;
}

export interface DateTimeAnswerQuestionDto {
  value: string | null;
}

export interface DateTimeAnswerResourceDto
  extends SurveyAnswerResourceDto<DateTimeAnswerQuestionDto> {
  type: QuestionType.DATE_TIME;
}

export interface SelectAnswerQuestionDto {
  uuid: string | null;
}

export interface SelectAnswerResourceDto
  extends SurveyAnswerResourceDto<SelectAnswerQuestionDto> {
  type: QuestionType.SELECT;
}

export interface TableAnswerQuestionDto {
  data: TableRowAswerQuestionDto[];
  comment: string | null;
}

export interface TableRowAswerQuestionDto {
  column: UuidDto;
  row: UuidDto;
  value: string | null;
}

export interface TableAnswerResourceDto
  extends SurveyAnswerResourceDto<TableAnswerQuestionDto> {
  type: QuestionType.TABLE;
}

export interface WeightAnswerQuestionDto {
  value: number | null;
}

export interface UserWeightAnswerResourceDto
  extends SurveyAnswerResourceDto<WeightAnswerQuestionDto> {
  type: QuestionType.SYSTEM_USER_WEIGHT;
}

export interface HeightAnswerQuestionDto {
  value: number | null;
}

export interface UserHeightAnswerResourceDto
  extends SurveyAnswerResourceDto<HeightAnswerQuestionDto> {
  type: QuestionType.SYSTEM_USER_HEIGHT;
}

export interface FatTissueAnswerQuestionDto {
  value: number | null;
  fat: number | null;
}

export interface UserFatTissueAnswerResourceDto
  extends SurveyAnswerResourceDto<FatTissueAnswerQuestionDto> {
  type: QuestionType.SYSTEM_USER_FAT_TISSUE;
}

export interface MeasurementAnswerQuestionDto {
  arm: number | null;
  tightBiceps: number | null;
  waist: number | null;
  hip: number | null;
  thigh: number | null;
  calf: number | null;
}

export interface MeasurementAnswerResourceDto
  extends SurveyAnswerResourceDto<MeasurementAnswerQuestionDto> {
  type: QuestionType.SYSTEM_MEASUREMENTS;
}

export interface BirthdayAnswerQuestionDto {
  value: string | null;
}

export interface BirthdayAnswerResourceDto
  extends SurveyAnswerResourceDto<BirthdayAnswerQuestionDto> {
  type: QuestionType.SYSTEM_USER_BIRTHDAY;
}

export interface CooperationGoalsAnswerQuestionDto {
  id: number | null;
  other: string | null;
}

export interface CooperationGoalsAnswerResourceDto
  extends SurveyAnswerResourceDto<CooperationGoalsAnswerQuestionDto> {
  type: QuestionType.SYSTEM_COOPERATION_GOALS;
}

export interface UserTargetBodyWeightAnswerQuestionDto {
  value: number | null;
}

export interface UserTargetBodyWeightAnswerResourceDto
  extends SurveyAnswerResourceDto<UserTargetBodyWeightAnswerQuestionDto> {
  type: QuestionType.SYSTEM_USER_TARGET_BODY_WEIGHT;
}

export enum PregnantWomanValue {
  LACK = 1,
  PREGNANT = 2,
  LACTATION = 3,
}

export enum Trimester {
  I = 1,
  II = 2,
  III = 3,
}

export interface PregnantWomanAnswerQuestionDto {
  value: PregnantWomanValue | null;
  trimester: Trimester | null;
  comment: string | null;
}

export interface PregnantWomanAnswerResourceDto
  extends SurveyAnswerResourceDto<PregnantWomanAnswerQuestionDto> {
  type: QuestionType.SYSTEM_PREGNANT_WOMAN;
}

export enum SurveySexDto {
  M = "m",
  W = "w",
  O = "o",
}
export interface SexAnswerQuestionDto {
  sex: SurveySexDto | null;
}

export interface SexAnswerResourceDto
  extends SurveyAnswerResourceDto<SexAnswerQuestionDto> {
  type: QuestionType.SYSTEM_SEX;
}

export interface FoodAnswerQuestionDto {
  value: boolean;
  foodId: number[];
  foodGroupsId: number[];
  description: string | null;
}

export interface UserPreferencesFoodUnlikedAnswerResourceDto
  extends SurveyAnswerResourceDto<FoodAnswerQuestionDto> {
  type: QuestionType.SYSTEM_USER_PREFERENCES_FOOD_UNLIKED;
}

export interface UserPreferencesFoodLikedAnswerResourceDto
  extends SurveyAnswerResourceDto<FoodAnswerQuestionDto> {
  type: QuestionType.SYSTEM_USER_PREFERENCES_FOOD_LIKED;
}

export interface TagsAnswerQuestionDto {
  value: boolean | null;
  tagsId: number[];
  description: string | null;
}

export interface TagAllergenAnswerResourceDto
  extends SurveyAnswerResourceDto<TagsAnswerQuestionDto> {
  type: QuestionType.SYSTEM_TAG_ALLERGEN;
}
export interface TagDietTypeAnswerResourceDto
  extends SurveyAnswerResourceDto<TagsAnswerQuestionDto> {
  type: QuestionType.SYSTEM_TAG_DIET_TYPE;
}

export interface TranslationLegacyDto {
  lang: LangDto;
  value: string;
}

interface NullableIdDto {
  id: number | null;
}

export interface DictionariesDto {
  systemSourceRecommendation: DictionaryResourceDto[];
  systemAdditionalFeature: PatientProfileAdditionalFeatureResourceDto[];
  systemCooperationGoals: PatientProfileTargetResourceDto[];
  systemTagElimination: DTagResourceDto[];
  systemTagAllergen: DTagResourceDto[];
  systemMeals: SystemMealsDto;
  systemTagKindOfCuisine: DTagResourceDto[];
  systemUserActivity: SystemUserActivityDto;
  foodGroups: FoodGroupResourceDto[];
  systemTagDietType: DTagResourceDto[];
}

export interface FoodGroupResourceDto {
  id: number;
  parentId: number;
  description: string;
  descriptionPl: string | null;
  children: FoodGroupResourceDto[];
}

interface DictionaryResourceDto {
  id: number;
  value: string;
  translations: TranslationLegacyDto[];
}

interface PatientProfileAdditionalFeatureResourceDto {
  id: number;
  value: string;
  translations: TranslationLegacyDto[];
  sex: SexDto;
}

export interface PatientProfileTargetResourceDto {
  id: number;
  value: string;
  translations: TranslationLegacyDto[];
  sex: SexDto;
}

export interface DTagResourceDto {
  id: string;
  name: string;
  translations: TranslationDto[];
  clinicN: IdDto | null;
  tagCategory: NullableIdDto;
}

interface SystemMealsDto {
  meals: DTagResourceDto[];
  typeOfRecipes: DTagResourceDto[];
}

interface SystemUserActivityDto {
  daily: DictionaryResourceDto[];
  training: DictionaryResourceDto[];
}

interface DictionaryResourceDto {
  id: number;
  value: string;
  translations: TranslationLegacyDto[];
}

const translationLegacySchema = yup.object().shape({
  lang: yup.mixed<LangDto>().oneOf(Object.values(LangDto)).required(),
  value: yup.string().required(),
});

const nullableIdSchema = yup.object().shape({
  id: yup.number().nullable().default(null),
});

const dictionaryResourceSchema = yup.object().shape({
  id: yup.number().required(),
  value: yup.string().required(),
  translations: yup.array().of(translationLegacySchema).required(),
});

const patientProfileAdditionalFeatureResourceSchema = yup.object().shape({
  id: yup.number().required(),
  value: yup.string().required(),
  translations: yup.array().of(translationLegacySchema.required()).required(),
  sex: sexSchema.required(),
});

const patientProfileTargetResourceSchema = yup.object().shape({
  id: yup.number().required(),
  value: yup.string().required(),
  translations: yup.array().of(translationLegacySchema.required()).required(),
  sex: sexSchema.required(),
});

const dTagResourceSchema = yup.object().shape({
  id: yup.string().required(),
  name: yup.string().required(),
  translations: yup.array().of(translationSchema.required()).required(),
  clinicN: idSchema.nullable().default(null),
  tagCategory: nullableIdSchema.required(),
});

const systemMealsSchema = yup.object().shape({
  meals: yup.array().of(dTagResourceSchema.required()).default([]),
  typeOfRecipes: yup.array().of(dTagResourceSchema.required()).default([]),
});

const systemUserActivitySchema = yup.object().shape({
  daily: yup.array().of(dictionaryResourceSchema.required()).default([]),
  training: yup.array().of(dictionaryResourceSchema.required()).default([]),
});

const foodGroupResourceSchema: yup.ObjectSchema<FoodGroupResourceDto> = yup
  .object()
  .shape({
    id: yup.number().required(),
    parentId: yup.number().required(),
    description: yup.string().required(),
    descriptionPl: yup.string().nullable().default(null),
    children: yup
      .array()
      .of(yup.lazy(() => foodGroupResourceSchema.required()))
      .required(),
  });

export const dictionariesSchema = yup.object().shape({
  systemSourceRecommendation: yup
    .array()
    .of(dictionaryResourceSchema.required())
    .default([]),
  systemAdditionalFeature: yup
    .array()
    .of(patientProfileAdditionalFeatureResourceSchema.required())
    .default([]),
  systemCooperationGoals: yup
    .array()
    .of(patientProfileTargetResourceSchema.required())
    .default([]),
  systemTagElimination: yup
    .array()
    .of(dTagResourceSchema.required())
    .default([]),
  systemTagAllergen: yup.array().of(dTagResourceSchema.required()).default([]),
  systemMeals: systemMealsSchema.required(),
  systemTagKindOfCuisine: yup
    .array()
    .of(dTagResourceSchema.required())
    .default([]),
  systemUserActivity: systemUserActivitySchema.required(),
  foodGroups: yup.array().of(foodGroupResourceSchema).required(),
  systemTagDietType: yup.array().of(dTagResourceSchema.required()).default([]),
});

const textAnswerQuestionSchema = yup.object().shape({
  value: yup.string().nullable().default(null),
});

const textAnswerResourceSchema = yup.object().shape({
  id: yup.number().required(),
  survey: idSchema.required(),
  question: idSchema.required(),
  patient: idSchema.required(),
  type: yup.string<QuestionType.TEXT>().required(),
  answer: textAnswerQuestionSchema.required(),
});

const textAreaAnswerResourceSchema = yup.object().shape({
  id: yup.number().required(),
  survey: idSchema.required(),
  question: idSchema.required(),
  patient: idSchema.required(),
  type: yup.string<QuestionType.TEXTAREA>().required(),
  answer: textAnswerQuestionSchema.required(),
});

const checkboxAnswerQuestionSchema = yup.object().shape({
  uuids: yup.array().of(yup.string().required()).required(),
  text: yup.string().nullable().default(null),
});

const checkboxAnswerResourceSchema = yup.object().shape({
  id: yup.number().required(),
  survey: idSchema.required(),
  question: idSchema.required(),
  patient: idSchema.required(),
  type: yup.string<QuestionType.CHECKBOX>().required(),
  answer: checkboxAnswerQuestionSchema.required(),
});

const radioAnswerQuestionSchema = yup.object().shape({
  uuid: yup.string().nullable().default(null),
  text: yup.string().nullable().default(null),
});

const radioAnswerResourceSchema = yup.object().shape({
  id: yup.number().required(),
  survey: idSchema.required(),
  question: idSchema.required(),
  patient: idSchema.required(),
  type: yup.string<QuestionType.RADIO>().required(),
  answer: radioAnswerQuestionSchema.required(),
});

const switchAnswerQuestionSchema = yup.object().shape({
  value: yup.number().min(0).max(1).required(),
  text: yup.string().nullable().default(null),
});

const switchAnswerResourceSchema = yup.object().shape({
  id: yup.number().required(),
  survey: idSchema.required(),
  question: idSchema.required(),
  patient: idSchema.required(),
  type: yup.string<QuestionType.SWITCH>().required(),
  answer: switchAnswerQuestionSchema.required(),
});

const dateAnswerQuestionSchema = yup.object().shape({
  value: yup.string().nullable().default(null),
});

const dateAnswerResourceSchema = yup.object().shape({
  id: yup.number().required(),
  survey: idSchema.required(),
  question: idSchema.required(),
  patient: idSchema.required(),
  type: yup.string<QuestionType.DATE>().required(),
  answer: dateAnswerQuestionSchema.required(),
});

const timeAnswerQuestionSchema = yup.object().shape({
  value: yup.string().nullable().default(null),
});

const timeAnswerResourceSchema = yup.object().shape({
  id: yup.number().required(),
  survey: idSchema.required(),
  question: idSchema.required(),
  patient: idSchema.required(),
  type: yup.string<QuestionType.TIME>().required(),
  answer: timeAnswerQuestionSchema.required(),
});

const dateTimeAnswerQuestionSchema = yup.object().shape({
  value: yup.string().nullable().default(null),
});

const dateTimeAnswerResourceSchema = yup.object().shape({
  id: yup.number().required(),
  survey: idSchema.required(),
  question: idSchema.required(),
  patient: idSchema.required(),
  type: yup.string<QuestionType.DATE_TIME>().required(),
  answer: dateTimeAnswerQuestionSchema.required(),
});

const selectAnswerQuestionSchema = yup.object().shape({
  uuid: yup.string().nullable().default(null),
});

const selectAnswerResourceSchema = yup.object().shape({
  id: yup.number().required(),
  survey: idSchema.required(),
  question: idSchema.required(),
  patient: idSchema.required(),
  type: yup.string<QuestionType.SELECT>().required(),
  answer: selectAnswerQuestionSchema.required(),
});

const uuidSchema = yup.object().shape({
  uuid: yup.string().required(),
});

const tableRowAswerQuestionSchema = yup.object().shape({
  column: uuidSchema.required(),
  row: uuidSchema.required(),
  value: yup.string().nullable().default(null),
});

const tableAnswerQuestionSchema = yup.object().shape({
  data: yup.array().of(tableRowAswerQuestionSchema).required(),
  comment: yup.string().nullable().default(null),
});

const tableAnswerResourceSchema = yup.object().shape({
  id: yup.number().required(),
  survey: idSchema.required(),
  question: idSchema.required(),
  patient: idSchema.required(),
  type: yup.string<QuestionType.TABLE>().required(),
  answer: tableAnswerQuestionSchema.required(),
});

const systemUserWeightAnswerQuestionSchema = yup.object().shape({
  value: yup.number().nullable().default(null),
});

const systemUserWeightAnswerResourceSchema = yup.object().shape({
  id: yup.number().required(),
  survey: idSchema.required(),
  question: idSchema.required(),
  patient: idSchema.required(),
  type: yup.string<QuestionType.SYSTEM_USER_WEIGHT>().required(),
  answer: systemUserWeightAnswerQuestionSchema.required(),
});

const systemUserHeightAnswerQuestionSchema = yup.object().shape({
  value: yup.number().nullable().default(null),
});

const systemUserHeightAnswerResourceSchema = yup.object().shape({
  id: yup.number().required(),
  survey: idSchema.required(),
  question: idSchema.required(),
  patient: idSchema.required(),
  type: yup.string<QuestionType.SYSTEM_USER_HEIGHT>().required(),
  answer: systemUserHeightAnswerQuestionSchema.required(),
});

const systemUserFatTissueAnswerQuestionSchema = yup.object().shape({
  value: yup.number().nullable().default(null),
  fat: yup.number().nullable().default(null),
});

const systemUserFatTissueAnswerResourceSchema = yup.object().shape({
  id: yup.number().required(),
  survey: idSchema.required(),
  question: idSchema.required(),
  patient: idSchema.required(),
  type: yup.string<QuestionType.SYSTEM_USER_FAT_TISSUE>().required(),
  answer: systemUserFatTissueAnswerQuestionSchema.required(),
});

const systemMeasurementAnswerQuestionSchema = yup.object().shape({
  arm: yup.number().nullable().default(null),
  tightBiceps: yup.number().nullable().default(null),
  waist: yup.number().nullable().default(null),
  hip: yup.number().nullable().default(null),
  thigh: yup.number().nullable().default(null),
  calf: yup.number().nullable().default(null),
});

const systemMeasurementAnswerResourceSchema = yup.object().shape({
  id: yup.number().required(),
  survey: idSchema.required(),
  question: idSchema.required(),
  patient: idSchema.required(),
  type: yup.string<QuestionType.SYSTEM_MEASUREMENTS>().required(),
  answer: systemMeasurementAnswerQuestionSchema.required(),
});

const systemUserBirthdayAnswerQuestionSchema = yup.object().shape({
  value: yup.string().nullable().default(null),
});

const systemUserBirthdayAnswerResourceSchema = yup.object().shape({
  id: yup.number().required(),
  survey: idSchema.required(),
  question: idSchema.required(),
  patient: idSchema.required(),
  type: yup.string<QuestionType.SYSTEM_USER_BIRTHDAY>().required(),
  answer: systemUserBirthdayAnswerQuestionSchema.required(),
});

const systemCooperationGoalsAnswerQuestionSchema = yup.object().shape({
  id: yup.number().nullable().default(null),
  other: yup.string().nullable().default(null),
});

const systemCooperationGoalsAnswerResourceSchema = yup.object().shape({
  id: yup.number().required(),
  survey: idSchema.required(),
  question: idSchema.required(),
  patient: idSchema.required(),
  type: yup.string<QuestionType.SYSTEM_COOPERATION_GOALS>().required(),
  answer: systemCooperationGoalsAnswerQuestionSchema.required(),
});

const systemUserTargetBodyWeightAnswerQuestionSchema = yup.object().shape({
  value: yup.number().nullable().default(null),
});

const systemUserTargetBodyWeightAnswerResourceSchema = yup.object().shape({
  id: yup.number().required(),
  survey: idSchema.required(),
  question: idSchema.required(),
  patient: idSchema.required(),
  type: yup.string<QuestionType.SYSTEM_USER_TARGET_BODY_WEIGHT>().required(),
  answer: systemUserTargetBodyWeightAnswerQuestionSchema.required(),
});

const systemPregnantWomanAnswerQuestionSchema = yup.object().shape({
  value: yup
    .mixed<PregnantWomanValue>()
    .oneOf([
      PregnantWomanValue.LACK,
      PregnantWomanValue.PREGNANT,
      PregnantWomanValue.LACTATION,
    ])
    .nullable()
    .default(null),
  trimester: yup
    .mixed<Trimester>()
    .oneOf([Trimester.I, Trimester.II, Trimester.III])
    .nullable()
    .default(null),
  comment: yup.string().nullable().default(null),
});

const systemPregnantWomanAnswerResourceSchema = yup.object().shape({
  id: yup.number().required(),
  survey: idSchema.required(),
  question: idSchema.required(),
  patient: idSchema.required(),
  type: yup.string<QuestionType.SYSTEM_PREGNANT_WOMAN>().required(),
  answer: systemPregnantWomanAnswerQuestionSchema.required(),
});

const systemSexAnswerQuestionSchema = yup.object().shape({
  sex: yup.string<SurveySexDto>().required(),
});

const systemSexAnswerResourceSchema = yup.object().shape({
  id: yup.number().required(),
  survey: idSchema.required(),
  question: idSchema.required(),
  patient: idSchema.required(),
  type: yup.string<QuestionType.SYSTEM_SEX>().required(),
  answer: systemSexAnswerQuestionSchema.required(),
});

const foodAnswerQuestionSchema = yup.object().shape({
  value: yup.boolean().required(),
  foodId: yup.array().of(yup.number().required()).required(),
  foodGroupsId: yup.array().of(yup.number().required()).required(),
  description: yup.string().nullable().default(null),
});

const systemUserPreferencesFoodUnlikedAnswerResourceSchema = yup
  .object()
  .shape({
    id: yup.number().required(),
    survey: idSchema.required(),
    question: idSchema.required(),
    patient: idSchema.required(),
    type: yup
      .string<QuestionType.SYSTEM_USER_PREFERENCES_FOOD_UNLIKED>()
      .required(),
    answer: foodAnswerQuestionSchema.required(),
  });

const systemUserPreferencesFoodLikedAnswerResourceSchema = yup.object().shape({
  id: yup.number().required(),
  survey: idSchema.required(),
  question: idSchema.required(),
  patient: idSchema.required(),
  type: yup
    .string<QuestionType.SYSTEM_USER_PREFERENCES_FOOD_LIKED>()
    .required(),
  answer: foodAnswerQuestionSchema.required(),
});

const tagAnswerQuestionSchema = yup.object().shape({
  value: yup.boolean().nullable().default(null),
  tagsId: yup.array().of(yup.number().required()).required(),
  description: yup.string().nullable().default(null),
});

const systemTagAllergenResourceSchema = yup.object().shape({
  id: yup.number().required(),
  survey: idSchema.required(),
  question: idSchema.required(),
  patient: idSchema.required(),
  type: yup.string<QuestionType.SYSTEM_TAG_ALLERGEN>().required(),
  answer: tagAnswerQuestionSchema.required(),
});

const systemTagDietTypeResourceSchema = yup.object().shape({
  id: yup.number().required(),
  survey: idSchema.required(),
  question: idSchema.required(),
  patient: idSchema.required(),
  type: yup.string<QuestionType.SYSTEM_TAG_DIET_TYPE>().required(),
  answer: tagAnswerQuestionSchema.required(),
});

const fetchSingedSurveysSchema = yup.object().shape({
  survey: fullSurveySchema.required(),
  answers: yup
    .array()
    .of(
      yup.lazy(value => {
        switch (value.type) {
          case QuestionType.TEXT:
            return textAnswerResourceSchema.required();
          case QuestionType.TEXTAREA:
            return textAreaAnswerResourceSchema.required();
          case QuestionType.CHECKBOX:
            return checkboxAnswerResourceSchema.required();
          case QuestionType.RADIO:
            return radioAnswerResourceSchema.required();
          case QuestionType.SWITCH:
            return switchAnswerResourceSchema.required();
          case QuestionType.DATE:
            return dateAnswerResourceSchema.required();
          case QuestionType.TIME:
            return timeAnswerResourceSchema.required();
          case QuestionType.DATE_TIME:
            return dateTimeAnswerResourceSchema.required();
          case QuestionType.SELECT:
            return selectAnswerResourceSchema.required();
          case QuestionType.TABLE:
            return tableAnswerResourceSchema.required();
          case QuestionType.SYSTEM_USER_WEIGHT:
            return systemUserWeightAnswerResourceSchema.required();
          case QuestionType.SYSTEM_USER_HEIGHT:
            return systemUserHeightAnswerResourceSchema.required();
          case QuestionType.SYSTEM_USER_FAT_TISSUE:
            return systemUserFatTissueAnswerResourceSchema.required();
          case QuestionType.SYSTEM_MEASUREMENTS:
            return systemMeasurementAnswerResourceSchema.required();
          case QuestionType.SYSTEM_USER_BIRTHDAY:
            return systemUserBirthdayAnswerResourceSchema.required();
          case QuestionType.SYSTEM_COOPERATION_GOALS:
            return systemCooperationGoalsAnswerResourceSchema.required();
          case QuestionType.SYSTEM_USER_TARGET_BODY_WEIGHT:
            return systemUserTargetBodyWeightAnswerResourceSchema.required();
          case QuestionType.SYSTEM_PREGNANT_WOMAN:
            return systemPregnantWomanAnswerResourceSchema.required();
          case QuestionType.SYSTEM_SEX:
            return systemSexAnswerResourceSchema.required();
          case QuestionType.SYSTEM_USER_PREFERENCES_FOOD_UNLIKED:
            return systemUserPreferencesFoodUnlikedAnswerResourceSchema.required();
          case QuestionType.SYSTEM_USER_PREFERENCES_FOOD_LIKED:
            return systemUserPreferencesFoodLikedAnswerResourceSchema.required();
          case QuestionType.SYSTEM_TAG_ALLERGEN:
            return systemTagAllergenResourceSchema.required();
          case QuestionType.SYSTEM_TAG_DIET_TYPE:
            return systemTagDietTypeResourceSchema.required();
          default:
            return textAnswerResourceSchema.required();
        }
      }),
    )
    .required(),
  dictionaries: dictionariesSchema.required(),
});

export const fetchSignedSurveysResponseSchema = yup.object().shape({
  data: fetchSingedSurveysSchema.required(),
});

export enum ANSWER_VALUE {
  No = 0,
  Yes = 1,
}
