import { FormCountryAutocomplete } from "@components/FormCountryAutocomplete";
import { FormTextField } from "@components/FormTextField";
import { styled } from "@mui/material";

interface Flex {
  flex: number;
}

export const StyledFormCountryAutocomplete = styled(
  FormCountryAutocomplete,
)<Flex>`
  flex: ${({ flex }) => flex};
`;

export const StyledPhoneTextField = styled(FormTextField)<Flex>`
  flex: ${({ flex }) => flex};
`;
