import React, {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  PropsWithChildren,
  FC,
  useEffect,
} from "react";
import { calendarFiltersDefaultValues } from "@components/CalendarFilters";
import type { CalendarFiltersSchema } from "@components/CalendarFilters/useCalendarFiltersForm";
import { useCalendarFilterOptions } from "./useCalendarFilterOptions";

enum LocalStorageKeys {
  ScheduleEvents = "schedule_events",
}

interface CalendarFiltersSchemaWithId {
  accountId: string | null;
  filters: CalendarFiltersSchema;
}

type CalendarFiltersContextType = [
  CalendarFiltersSchemaWithId,
  Dispatch<SetStateAction<CalendarFiltersSchemaWithId>>,
];

const defaultCalendarFiltersSchemaWithId: CalendarFiltersSchemaWithId = {
  accountId: null,
  filters: calendarFiltersDefaultValues,
};

const CalendarFiltersContext = createContext<CalendarFiltersContextType>([
  defaultCalendarFiltersSchemaWithId,
  () => ({}),
]);

export const useCalendarFilters = (): CalendarFiltersContextType => {
  const context = useContext(CalendarFiltersContext);
  if (!context) {
    throw new Error(
      "useCalendarFilters must be used within a CalendarFiltersProvider",
    );
  }
  return context;
};

export const CalendarFiltersProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { defaultDietitianIds, currentDietitianId } =
    useCalendarFilterOptions();

  const [filters, setFiltersState] = useState<CalendarFiltersSchemaWithId>(
    () => {
      const defaultFilters = {
        accountId: currentDietitianId,
        filters: {
          ...calendarFiltersDefaultValues,
          dietitians: defaultDietitianIds ?? [],
        },
      };
      try {
        const item = localStorage.getItem(LocalStorageKeys.ScheduleEvents);

        if (item) {
          const parsedItem: CalendarFiltersSchemaWithId = JSON.parse(item);

          if (parsedItem.accountId === currentDietitianId) {
            if (parsedItem.filters.date) {
              parsedItem.filters.date = new Date(parsedItem.filters.date);
            }
            return parsedItem;
          }

          return defaultFilters;
        }
        return defaultFilters;
      } catch (error) {
        console.error(error);
        return defaultFilters;
      }
    },
  );

  const setFilters: Dispatch<
    SetStateAction<CalendarFiltersSchemaWithId>
  > = value => {
    setFiltersState(prevFilters => {
      const newFilters =
        typeof value === "function" ? value(prevFilters) : value;
      try {
        const filtersToStore = {
          ...newFilters,
          filters: {
            ...newFilters.filters,
            date:
              newFilters.filters.date instanceof Date
                ? newFilters.filters.date.toISOString()
                : newFilters.filters.date,
          },
        };
        localStorage.setItem(
          LocalStorageKeys.ScheduleEvents,
          JSON.stringify(filtersToStore),
        );
      } catch (error) {
        console.error(error);
      }
      return newFilters;
    });
  };

  useEffect(() => {
    if (defaultDietitianIds?.length) {
      setFilters({
        ...filters,
        filters: { ...filters.filters, dietitians: defaultDietitianIds },
      });
    }
  }, [defaultDietitianIds?.length]);

  return (
    <CalendarFiltersContext.Provider value={[filters, setFilters]}>
      {children}
    </CalendarFiltersContext.Provider>
  );
};
