import {
  BoxTitleContainer,
  BoxWrapper,
} from "@views/dietician/PatientMonitoring2/components/BoxWrapper";
import { Chip, Stack } from "@mui/material";
import { useAppTranslation } from "@hooks";
import IrrigationChart from "@views/dietician/PatientMonitoring2/components/Irrigation/IrrigationChart";
import Summary from "@views/dietician/PatientMonitoring2/components/Irrigation/Summary";
import { IrrigationMonitoring } from "@assets/icons/Monitoring";
import { PatientHydrationProductsMonitoringResource } from "@client/resources/PatientHydrationProductsMonitoringResource";

type IrrigationBoxProps = {
  data: PatientHydrationProductsMonitoringResource[];
  value: number;
  norm: number;
  tooltipLabelFormatter: (date: string) => string;
  xAxisTickFormatter: (date: string) => string;
};

const IrrigationBox = ({
  data,
  value,
  norm,
  xAxisTickFormatter,
  tooltipLabelFormatter,
}: IrrigationBoxProps) => {
  const { t } = useAppTranslation();

  return (
    <BoxWrapper id="monotoring_irrigation_box">
      <Stack spacing="24px">
        <Stack
          display="flex"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <BoxTitleContainer
            icon={<IrrigationMonitoring fill="#727272" />}
            title={t("monitoring2.irrigation_box.title")}
          />
          {value < norm && (
            <Chip
              color="warning"
              label={t("monitoring2.irrigation_box.below_norm")}
            />
          )}
        </Stack>

        <Summary value={value} />
        <IrrigationChart
          data={data ?? []}
          xAxisTickFormatter={xAxisTickFormatter}
          tooltipLabelFormatter={tooltipLabelFormatter}
        />
      </Stack>
    </BoxWrapper>
  );
};

export default IrrigationBox;
