import {
  CheckboxAnswerResourceDto,
  DateAnswerResourceDto,
  DateTimeAnswerResourceDto,
  QuestionCheckboxDto,
  QuestionDateDto,
  QuestionEmptyDto,
  QuestionRadioDto,
  QuestionSelectDto,
  QuestionSliderDto,
  QuestionSwitchDto,
  QuestionTableDto,
  QuestionTextAreaDto,
  QuestionTextDto,
  QuestionType,
  RadioAnswerResourceDto,
  SelectAnswerResourceDto,
  SwitchAnswerResourceDto,
  SystemQuestionDto,
  TableAnswerResourceDto,
  TextAnswerResourceDto,
  TextAreaAnswerResourceDto,
  TimeAnswerResourceDto,
  UserWeightAnswerResourceDto,
  UserFatTissueAnswerResourceDto,
  UserHeightAnswerResourceDto,
  MeasurementAnswerResourceDto,
  BirthdayAnswerResourceDto,
  CooperationGoalsAnswerResourceDto,
  DictionariesDto,
  UserTargetBodyWeightAnswerResourceDto,
  PregnantWomanAnswerResourceDto,
  SexAnswerResourceDto,
  UserPreferencesFoodUnlikedAnswerResourceDto,
  UserPreferencesFoodLikedAnswerResourceDto,
  TagAllergenAnswerResourceDto,
  TagDietTypeAnswerResourceDto,
} from "@client/surveys";

import { QuestionCheckbox } from "./QuestionCheckbox/QuestionCheckbox";
import { QuestionDate } from "./QuestionDate/QuestionDate";
import { QuestionRadio } from "./QuestionRadio/QuestionRadio";
import { QuestionText } from "./QuestionText/QuestionText";
import { QuestionTextArea } from "./QuestionTextArea/QuestionTextArea";
import { QuestionTable } from "./QuestionTable/QuestionTable";
import { SystemQuestionUserWeight } from "./SystemQuestionUserWeight";
import { SystemQuestionUserFatTissue } from "./SystemQuestionUserFatTissue";
import { SystemQuestionUserHeight } from "./SystemQuestionUserHeight";
import { SystemQuestionMeasurements } from "./SystemQuestionMeasurements";
import { SystemQuestionUserBirthday } from "./SystemQuestionUserBirthday";
import { SystemQuestionCooperationGoals } from "./SystemQuestionCooperationGoals";
import { SystemQuestionUserTargetBodyWeight } from "./SystemQuestionUserTargetBodyWeight";
import { SystemQuestionPregnantWoman } from "./SystemQuestionPregnantWoman";
import { SystemQuestionSex } from "./SystemQuestionSex";
import { SystemQuestionUserPreferencesFoodUnliked } from "./SystemQuestionUserPreferencesFoodUnliked";
import { SystemQuestionUserPreferencesFoodLiked } from "./SystemQuestionUserPreferencesFoodLiked";
import { SystemQuestionTagAllergen } from "./SystemQuestionTagAllergen";
import { SystemQuestionTagDietType } from "./SystemQuestionTagDietType";

interface QuestionFactoryProps {
  question:
    | QuestionTextDto
    | QuestionTextAreaDto
    | QuestionCheckboxDto
    | QuestionRadioDto
    | QuestionDateDto
    | QuestionSwitchDto
    | QuestionSelectDto
    | QuestionSliderDto
    | QuestionTableDto
    | SystemQuestionDto
    | QuestionEmptyDto;
  answer?:
    | TextAnswerResourceDto
    | TextAreaAnswerResourceDto
    | CheckboxAnswerResourceDto
    | RadioAnswerResourceDto
    | SwitchAnswerResourceDto
    | DateAnswerResourceDto
    | TimeAnswerResourceDto
    | DateTimeAnswerResourceDto
    | SelectAnswerResourceDto
    | TableAnswerResourceDto
    | UserWeightAnswerResourceDto
    | UserHeightAnswerResourceDto
    | UserFatTissueAnswerResourceDto
    | MeasurementAnswerResourceDto
    | BirthdayAnswerResourceDto
    | CooperationGoalsAnswerResourceDto
    | UserTargetBodyWeightAnswerResourceDto
    | PregnantWomanAnswerResourceDto
    | SexAnswerResourceDto
    | UserPreferencesFoodUnlikedAnswerResourceDto
    | UserPreferencesFoodLikedAnswerResourceDto
    | TagAllergenAnswerResourceDto
    | TagDietTypeAnswerResourceDto;
  listIndex: number;
  totalQuestions: number;
  readOnly?: boolean;
  dictionaires?: DictionariesDto;
}
export const QuestionFactory = ({
  question,
  answer,
  dictionaires,
  ...rest
}: QuestionFactoryProps) => {
  const checkType = (type: QuestionType) =>
    question.type === type && (answer === undefined || answer.type === type);

  if (checkType(QuestionType.TEXT))
    return (
      <QuestionText
        question={question as QuestionTextDto}
        answer={answer as TextAnswerResourceDto}
        {...rest}
      />
    );
  if (checkType(QuestionType.TEXTAREA))
    return (
      <QuestionTextArea
        question={question as QuestionTextAreaDto}
        answer={answer as TextAreaAnswerResourceDto}
        {...rest}
      />
    );
  if (checkType(QuestionType.RADIO))
    return (
      <QuestionRadio
        question={question as QuestionRadioDto}
        answer={answer as RadioAnswerResourceDto}
        {...rest}
      />
    );

  if (checkType(QuestionType.CHECKBOX))
    return (
      <QuestionCheckbox
        question={question as QuestionCheckboxDto}
        answer={answer as CheckboxAnswerResourceDto}
        {...rest}
      />
    );

  if (checkType(QuestionType.DATE))
    return (
      <QuestionDate
        question={question as QuestionDateDto}
        answer={answer as DateAnswerResourceDto}
        {...rest}
      />
    );

  if (checkType(QuestionType.TABLE))
    return (
      <QuestionTable
        question={question as QuestionTableDto}
        answer={answer as TableAnswerResourceDto}
        {...rest}
      />
    );

  if (checkType(QuestionType.SYSTEM_USER_WEIGHT))
    return (
      <SystemQuestionUserWeight
        question={question as SystemQuestionDto}
        answer={answer as UserWeightAnswerResourceDto}
        {...rest}
      />
    );

  if (checkType(QuestionType.SYSTEM_USER_HEIGHT))
    return (
      <SystemQuestionUserHeight
        question={question as SystemQuestionDto}
        answer={answer as UserHeightAnswerResourceDto}
        {...rest}
      />
    );

  if (checkType(QuestionType.SYSTEM_USER_FAT_TISSUE))
    return (
      <SystemQuestionUserFatTissue
        question={question as SystemQuestionDto}
        answer={answer as UserFatTissueAnswerResourceDto}
        {...rest}
      />
    );

  if (checkType(QuestionType.SYSTEM_MEASUREMENTS))
    return (
      <SystemQuestionMeasurements
        question={question as SystemQuestionDto}
        answer={answer as MeasurementAnswerResourceDto}
        {...rest}
      />
    );

  if (checkType(QuestionType.SYSTEM_USER_BIRTHDAY))
    return (
      <SystemQuestionUserBirthday
        question={question as SystemQuestionDto}
        answer={answer as BirthdayAnswerResourceDto}
        {...rest}
      />
    );

  if (checkType(QuestionType.SYSTEM_COOPERATION_GOALS))
    return (
      <SystemQuestionCooperationGoals
        question={question as SystemQuestionDto}
        answer={answer as CooperationGoalsAnswerResourceDto}
        options={dictionaires?.systemCooperationGoals}
        {...rest}
      />
    );

  if (checkType(QuestionType.SYSTEM_USER_TARGET_BODY_WEIGHT))
    return (
      <SystemQuestionUserTargetBodyWeight
        question={question as SystemQuestionDto}
        answer={answer as UserTargetBodyWeightAnswerResourceDto}
        options={dictionaires?.systemCooperationGoals}
        {...rest}
      />
    );

  if (checkType(QuestionType.SYSTEM_PREGNANT_WOMAN))
    return (
      <SystemQuestionPregnantWoman
        question={question as SystemQuestionDto}
        answer={answer as PregnantWomanAnswerResourceDto}
        {...rest}
      />
    );

  if (checkType(QuestionType.SYSTEM_SEX))
    return (
      <SystemQuestionSex
        question={question as SystemQuestionDto}
        answer={answer as SexAnswerResourceDto}
        {...rest}
      />
    );

  if (checkType(QuestionType.SYSTEM_USER_PREFERENCES_FOOD_UNLIKED))
    return (
      <SystemQuestionUserPreferencesFoodUnliked
        question={question as SystemQuestionDto}
        answer={answer as UserPreferencesFoodUnlikedAnswerResourceDto}
        options={dictionaires?.foodGroups}
        {...rest}
      />
    );

  if (checkType(QuestionType.SYSTEM_USER_PREFERENCES_FOOD_LIKED))
    return (
      <SystemQuestionUserPreferencesFoodLiked
        question={question as SystemQuestionDto}
        answer={answer as UserPreferencesFoodLikedAnswerResourceDto}
        options={dictionaires?.foodGroups}
        {...rest}
      />
    );

  if (checkType(QuestionType.SYSTEM_TAG_ALLERGEN))
    return (
      <SystemQuestionTagAllergen
        question={question as SystemQuestionDto}
        answer={answer as TagAllergenAnswerResourceDto}
        options={dictionaires?.systemTagAllergen}
        {...rest}
      />
    );

  if (checkType(QuestionType.SYSTEM_TAG_DIET_TYPE))
    return (
      <SystemQuestionTagDietType
        question={question as SystemQuestionDto}
        answer={answer as TagDietTypeAnswerResourceDto}
        options={dictionaires?.systemTagDietType}
        {...rest}
      />
    );

  return <></>;
};
