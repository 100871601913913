import { TabList } from "@components/Alloweat/Tabs/TabList";
import { Tab } from "@mui/material";
import { TabPanel } from "@components/Alloweat/Tabs/TabPanel";
import { TabContext } from "@mui/lab";
import { useMemo, useState } from "react";
import { useAppTranslation } from "@hooks";
import { useFetchBodyMeasurementsQuery } from "@hooks/queries/dictionaries";
import { BodyMeasurementConst } from "@consts/BodyMeasurementConst";
import { FetchBodyMeasurementsDictionaryResponse } from "@client/dictionaries";
import { SectionLayout } from "@views/dietician/ClientHealth/ClientHealthNew.styled";
import { getBodyMeasurementsByIds } from "@utils/dictionaries/bodyMeasurementsDictionaryUtils";
import BodyMeasurementItem from "@components/Client/BodyMeasurementsTabs/BodyMeasurementItem";
import BMI from "@components/Client/BodyMeasurementsTabs/BMI";
import Age from "@components/Client/BodyMeasurementsTabs/Age";
import { PatientMeasurementResource } from "@client/resources/PatientMeasurementResource";

type BodyMeasurementsTabsProps = {
  patientBodyMeasurement: Omit<PatientMeasurementResource, "photos"> | null;
  client: {
    age?: number | null;
  };
  showAge?: boolean;
};
const BodyMeasurementsTabs = ({
  patientBodyMeasurement,
  client,
}: BodyMeasurementsTabsProps) => {
  const [type, setType] = useState<"basic" | "advance" | "circuits">("basic");
  const { t } = useAppTranslation();
  const { data: bodyMeasurements } = useFetchBodyMeasurementsQuery();

  const advanceMeasurements = useMemo(
    () => getMeasurementsByIds(bodyMeasurements, AdvanceMeasurementsTypes),
    [bodyMeasurements],
  );

  const circuitsMeasurements = useMemo(
    () => getMeasurementsByIds(bodyMeasurements, CircuitsMeasurementsTypes),
    [bodyMeasurements],
  );

  const height = useMemo(
    () => findMeasurementById(bodyMeasurements, BodyMeasurementConst.height),
    [bodyMeasurements],
  );

  const weight = useMemo(
    () => findMeasurementById(bodyMeasurements, BodyMeasurementConst.weight),
    [bodyMeasurements],
  );

  const bodyFatLevel = useMemo(
    () =>
      findMeasurementById(bodyMeasurements, BodyMeasurementConst.bodyFatLevel),
    [bodyMeasurements],
  );

  const getPatientMeasurementBodyValue = (id: number) => {
    if (!patientBodyMeasurement) {
      return null;
    }

    const bodyValue = patientBodyMeasurement?.bodyMeasurements.find(
      measurement => measurement.body.id === id,
    );

    return bodyValue?.value?.toString() ?? null;
  };

  return (
    <TabContext value={type}>
      <TabList
        onChange={(_, newValue) => setType(newValue)}
        scrollButtons={"auto"}
        variant="scrollable"
      >
        <Tab
          label={t(
            "patient.health_and_goal.body_measurement_tab.section_last_measurement.type_tabs.basic",
          )}
          value="basic"
        />
        <Tab
          label={t(
            "patient.health_and_goal.body_measurement_tab.section_last_measurement.type_tabs.advance",
          )}
          value="advance"
        />
        <Tab
          label={t(
            "patient.health_and_goal.body_measurement_tab.section_last_measurement.type_tabs.circuits",
          )}
          value="circuits"
        />
      </TabList>
      <TabPanel value="basic">
        <SectionLayout>
          {height && (
            <BodyMeasurementItem
              measurement={height}
              value={getPatientMeasurementBodyValue(
                BodyMeasurementConst.height,
              )}
            />
          )}
          {client.age !== undefined && <Age value={client.age} />}
          {weight && (
            <BodyMeasurementItem
              measurement={weight}
              value={getPatientMeasurementBodyValue(
                BodyMeasurementConst.weight,
              )}
            />
          )}
          <BMI patientBodyMeasurement={patientBodyMeasurement} />
          {bodyFatLevel && (
            <BodyMeasurementItem
              measurement={bodyFatLevel}
              value={getPatientMeasurementBodyValue(
                BodyMeasurementConst.bodyFatLevel,
              )}
            />
          )}
        </SectionLayout>
      </TabPanel>
      <TabPanel value="advance">
        <SectionLayout>
          {advanceMeasurements.map(measurement => (
            <BodyMeasurementItem
              key={measurement.id}
              measurement={measurement}
              value={getPatientMeasurementBodyValue(measurement.id)}
            />
          ))}
        </SectionLayout>
      </TabPanel>
      <TabPanel value="circuits">
        <SectionLayout>
          {circuitsMeasurements.map(measurement => (
            <BodyMeasurementItem
              key={measurement.id}
              measurement={measurement}
              value={getPatientMeasurementBodyValue(measurement.id)}
            />
          ))}
        </SectionLayout>
      </TabPanel>
    </TabContext>
  );
};

const AdvanceMeasurementsTypes = [
  BodyMeasurementConst.water,
  BodyMeasurementConst.bodyFatMass,
  BodyMeasurementConst.boneTissueMass,
  BodyMeasurementConst.skeletalMuscleMass,
];

const CircuitsMeasurementsTypes = [
  BodyMeasurementConst.arm,
  BodyMeasurementConst.tightBiceps,
  BodyMeasurementConst.waist,
  BodyMeasurementConst.hip,
  BodyMeasurementConst.thigh,
  BodyMeasurementConst.calf,
  BodyMeasurementConst.chest,
  BodyMeasurementConst.abdominal,
];

const findMeasurementById = (
  bodyMeasurements: FetchBodyMeasurementsDictionaryResponse | undefined,
  id: number,
) => {
  return bodyMeasurements?.data.find(measurement => measurement.id === id);
};

const getMeasurementsByIds = (
  bodyMeasurements: FetchBodyMeasurementsDictionaryResponse | undefined,
  measurements: number[],
) => {
  if (!bodyMeasurements) {
    return [];
  }

  return getBodyMeasurementsByIds(bodyMeasurements.data, measurements);
};

export default BodyMeasurementsTabs;
