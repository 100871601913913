import { MultiSelectFilter } from "@components/Filters/MultiSelectFilter";
import { useAppTranslation } from "@hooks";
import { useMemo } from "react";

const DietMealNumberField = () => {
  const { t } = useAppTranslation();
  const mappedOptions = useMemo(options, []);

  return (
    <MultiSelectFilter
      label={t("diets.meals_number")}
      options={mappedOptions}
      name="numberOfDietMeals"
    />
  );
};

const options = () =>
  [1, 2, 3, 4, 5, 6, 7, 8].map(day => ({
    id: day.toString(),
    label: day === 8 ? ">7" : day.toString(),
  }));

export default DietMealNumberField;
