import { SingleCardPageLayout } from "@components/PageLayout";
import { useAppTranslation } from "@hooks/useAppTranslation";

import { TasksAccordion } from "./TasksAccordion/TasksAccordion";
import { DietitianFilter } from "./TaskDietitianFilter/DietitianFilter";

import { FormProvider } from "react-hook-form";
import { TaskTags } from "./TaskTags";

import { useFilterTaskForm } from "@hooks/tasks/useFilterTaskForm";

import { TasksActions } from "./TasksActions/TasksActions";

export const TasksPanel = () => {
  const { t } = useAppTranslation();
  const form = useFilterTaskForm();

  return (
    <FormProvider {...form}>
      <SingleCardPageLayout
        rebranding
        title={t("tasks.title")}
        childrenClassName="flex flex-col gap-6 mt-6"
        extra={<TasksActions />}
      >
        <div className="flex gap-2 items-center">
          <DietitianFilter />
          <TaskTags />
        </div>
        <TasksAccordion />
      </SingleCardPageLayout>
    </FormProvider>
  );
};
