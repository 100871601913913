import { useAppTranslation, useProductsCategories } from "@hooks";
import { useTheme } from "@mui/material";
import { IconWrapper, Title } from "./CategoryTitle.styled";

interface CategoryTitleProps {
  id: number;
  namePl: string;
  nameEn: string;
}
export const CategoryTitle = ({ id, nameEn, namePl }: CategoryTitleProps) => {
  const { isPolishChosen } = useAppTranslation();
  const { mappedIcons } = useProductsCategories();
  const {
    colors: { neutral },
  } = useTheme();

  const Icon = mappedIcons.get(id);

  return (
    <div className="flex gap-[12px]">
      {Icon && (
        <IconWrapper>
          <Icon fill={neutral.dark[700]} />
        </IconWrapper>
      )}
      <Title>{isPolishChosen ? namePl : nameEn}</Title>
    </div>
  );
};
