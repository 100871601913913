import { ArrowDown } from "@assets/icons/Arrows";
import styled from "@emotion/styled";
import { IconButton, Popover, Typography } from "@mui/material";

interface ListElementStyledProps {
  selected?: boolean;
}

export const ListElementStyled = styled("li")<ListElementStyledProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0;
  border-radius: 0.5rem;
  cursor: pointer;

  ${({ selected, theme }) =>
    selected
      ? `
    background: ${theme.colors.neutral.light[100]};
    color: ${theme.palette.primary.main};
  `
      : `
    color: ${theme.colors.neutral.dark[800]};
  `}
`;

export const SubgroupStyled = styled("li")<ListElementStyledProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0 0.25rem 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;

  ${({ selected, theme }) =>
    selected
      ? `
    background: ${theme.colors.neutral.light[100]};
    color: ${theme.palette.primary.main};
  `
      : `
    color: ${theme.colors.neutral.dark[800]};
  `}
`;

interface StyledArrowDownProps {
  expanded?: boolean;
}

export const StyledArrowDown = styled(ArrowDown, {
  shouldForwardProp: prop => prop !== "expanded",
})<StyledArrowDownProps>`
  margin-left: 0.25rem;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  rotate: ${({ expanded }) => (expanded ? "360deg" : "270deg")};
`;

export const TitleStyled = styled(Typography)`
  font-weight: 600;
  line-height: 1.5rem;
  flex: 1;
`;

export const SubgroupName = styled("span")`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
`;
export const CloseIconButton = styled(IconButton)`
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;

  & svg {
    height: 0.75rem;
    width: 0.75rem;
  }
`;

export const HeaderText = styled("span")`
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const IntroductionTitle = styled(Typography)`
  font-weight: 600;
  line-height: 1.5rem;
  cursor: pointer;
`;

export const PopoverStyled = styled(Popover)`
  & .MuiPaper-root {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1.5rem;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    background: ${({ theme }) => theme.colors.survey.light};
  }
`;
