import { LoadingButton } from "@mui/lab";
import { Card, Typography, styled } from "@mui/material";

export const EmptyStateCard = styled(Card)`
  border-radius: 1rem;
  box-shadow: unset;
  height: 100%;
  padding: 1.286rem 0;
`;

export const MonitoringEmptyStateStyled = styled("div")`
  display: grid;
  place-items: center;
  place-content: center;
  gap: 2.286rem;
  height: 100%;
`;

export const ContentWrapper = styled("div")`
  display: grid;
  place-items: center;
  gap: 1rem;
`;

export const StyledTitle = styled(Typography)`
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;

export const StyledLoadingButton = styled(LoadingButton)`
  font-size: 1rem;
  padding: 0.525rem 1rem;
  border-radius: 0.571rem;

  & span {
    margin-right: 0.857rem;
  }
`;
