import { useQuery } from "@tanstack/react-query";
import { QueryOptions } from "./types";
import { fetchDietitianNutrientsCategories } from "@client";

const useFetchDietitianNutrientsCategoriesQueryKey =
  "fetchDietitianNutrientsCategories";
export const useFetchDietitianNutrientsCategoriesQuery = (
  options?: QueryOptions,
) => {
  const { data, ...rest } = useQuery(
    [useFetchDietitianNutrientsCategoriesQueryKey],
    fetchDietitianNutrientsCategories,
    options,
  );

  return { nutrientCategories: data?.data, ...rest };
};
