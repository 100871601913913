import { Chip, styled } from "@mui/material";

export const ProgramDayChip = styled(Chip)`
  background: transparent;
  border-color: #f5f1fe;
  .MuiChip-label {
    padding: 6px 8px;
  }
` as typeof Chip;

export const ProgramDayName = styled("span")`
  font-family: Figtree, serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #727272;
`;

export const NutrientBoxSubtitle = styled("span")`
  font: var(--font-figtree-semi-bold);
  font-size: 14px;
  line-height: 24px;
  color: #333333;
`;
