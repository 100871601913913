import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const MountainsWithSun = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="16" height="14" viewBox="0 0 16 14">
      <path
        d="M14 1.5C14.275 1.5 14.5 1.725 14.5 2V11.9937L14.3438 11.7906L10.0938 6.29063C9.95312 6.10625 9.73125 6 9.5 6C9.26875 6 9.05 6.10625 8.90625 6.29063L6.3125 9.64688L5.35938 8.3125C5.21875 8.11563 4.99375 8 4.75 8C4.50625 8 4.28125 8.11563 4.14062 8.31563L1.64062 11.8156L1.5 12.0094V12V2C1.5 1.725 1.725 1.5 2 1.5H14ZM2 0C0.896875 0 0 0.896875 0 2V12C0 13.1031 0.896875 14 2 14H14C15.1031 14 16 13.1031 16 12V2C16 0.896875 15.1031 0 14 0H2ZM4.5 6C4.69698 6 4.89204 5.9612 5.07403 5.88582C5.25601 5.81044 5.42137 5.69995 5.56066 5.56066C5.69995 5.42137 5.81044 5.25601 5.88582 5.07403C5.9612 4.89204 6 4.69698 6 4.5C6 4.30302 5.9612 4.10796 5.88582 3.92597C5.81044 3.74399 5.69995 3.57863 5.56066 3.43934C5.42137 3.30005 5.25601 3.18956 5.07403 3.11418C4.89204 3.0388 4.69698 3 4.5 3C4.30302 3 4.10796 3.0388 3.92597 3.11418C3.74399 3.18956 3.57863 3.30005 3.43934 3.43934C3.30005 3.57863 3.18956 3.74399 3.11418 3.92597C3.0388 4.10796 3 4.30302 3 4.5C3 4.69698 3.0388 4.89204 3.11418 5.07403C3.18956 5.25601 3.30005 5.42137 3.43934 5.56066C3.57863 5.69995 3.74399 5.81044 3.92597 5.88582C4.10796 5.9612 4.30302 6 4.5 6Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
