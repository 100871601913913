import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Bell = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="14" height="16" viewBox="0 0 14 16">
      <path
        d="M7.0003 0C6.46693 0 6.03602 0.446875 6.03602 1V1.6C3.83624 2.0625 2.17887 4.08125 2.17887 6.5V7.29375C2.17887 8.7125 1.7118 10.0906 0.859008 11.1969L0.410013 11.7812C0.235236 12.0063 0.202088 12.3156 0.322624 12.575C0.44316 12.8344 0.696285 13 0.973517 13H13.0271C13.3043 13 13.5574 12.8344 13.678 12.575C13.7985 12.3156 13.7654 12.0063 13.5906 11.7812L13.1416 11.2C12.2888 10.0906 11.8217 8.7125 11.8217 7.29375V6.5C11.8217 4.08125 10.1644 2.0625 7.96459 1.6V1C7.96459 0.446875 7.53367 0 7.0003 0ZM7.0003 3C8.86559 3 10.3753 4.56563 10.3753 6.5V7.29375C10.3753 8.79062 10.7942 10.25 11.5716 11.5H2.42899C3.20644 10.25 3.6253 8.79062 3.6253 7.29375V6.5C3.6253 4.56563 5.13501 3 7.0003 3ZM8.92887 14H7.0003H5.07173C5.07173 14.5312 5.27363 15.0406 5.63524 15.4156C5.99684 15.7906 6.48803 16 7.0003 16C7.51258 16 8.00376 15.7906 8.36537 15.4156C8.72698 15.0406 8.92887 14.5312 8.92887 14Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
