import { useMutation } from "@tanstack/react-query";

import {
  CreateMealRequest,
  CreateMealResponse,
  postCreateMeal,
} from "@client/meals";
import { ApiResponse } from "@typeDefinitions";

import { MutationOptions } from "../types";

export const usePostCreateMealMutation = (options?: MutationOptions) => {
  const mutation = useMutation<
    ApiResponse<CreateMealResponse>,
    unknown,
    CreateMealRequest
  >(payload => postCreateMeal(payload), options);

  return mutation;
};
