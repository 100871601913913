import { styled } from "@mui/material";

interface ErrorTextWrapperProps {
  hide?: boolean;
  largeText?: boolean;
}

export const ErrorTextWrapper = styled("div")<ErrorTextWrapperProps>`
  display: ${({ hide }) => (hide ? "none" : "grid")};
  grid-template-columns: ${({ largeText }) => (largeText ? 18 : 13)}px 1fr;
  gap: 5px;
  align-items: center;
`;

export const HelperText = styled("p")`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.error.main};
`;
