import { useClientParams } from "@hooks";
import useFetchPatientLastBodyMeasurement from "@hooks/queries/client/bodyMeasurement/useFetchPatientLastBodyMeasurement";
import BodyMeasurementsTabs from "@components/Client/BodyMeasurementsTabs/BodyMeasurementsTabs";
import { useFetchClientQueryNew } from "@hooks/queries";
import { useMemo } from "react";
import { getAge } from "@utils/client";

const LastMeasurementBodyTypeTabs = () => {
  const id = useClientParams();
  const { data: lastMeasurement } = useFetchPatientLastBodyMeasurement({
    patientId: id,
  });
  const { data: client } = useFetchClientQueryNew(id);

  const age = useMemo(() => {
    if (!client) {
      return null;
    }

    return getAge(client.data) ?? null;
  }, [client]);

  return (
    <BodyMeasurementsTabs
      client={{
        age: age,
      }}
      patientBodyMeasurement={lastMeasurement?.data ?? null}
    />
  );
};

export default LastMeasurementBodyTypeTabs;
