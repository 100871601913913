import { GroupDto } from "@client/surveys";
import { GroupIf, TableOfContents } from "@components/TableOfContents";

import { ContentWrapper } from "./SideMenuActions.styled";

interface SideMenuActionsProps {
  groups: GroupDto[];
}
export const SideMenuActions = ({ groups }: SideMenuActionsProps) => {
  return (
    <ContentWrapper>
      <TableOfContents edit groups={mapGroups(groups)} />
    </ContentWrapper>
  );
};

const mapGroups = (groups: GroupDto[]): GroupIf[] =>
  groups.map(({ id, title, sections }) => ({
    id: id,
    name: title,
    subgroups: sections.map(({ id, title }) => ({ id, name: title })),
  }));
