import { ChipsSelect } from "@components/ChipsSelect";
import { ContextSummary } from "./productListsUtils";
import { useState } from "react";
import { useAppTranslation } from "@hooks";

interface ChipFiltersProps {
  summary: ContextSummary;
  setFilterList: (ids: number[]) => void;
}
export const ChipFilters = ({ summary, setFilterList }: ChipFiltersProps) => {
  const { t } = useAppTranslation();
  const [selected, setSelected] = useState<number | null>(null);

  const translations: Record<keyof ContextSummary, string> = {
    hasLiked: t("user_tags_filters.liked"),
    hasUnliked: t("user_tags_filters.disliked"),
    hasAllergens: t("user_tags_filters.allergen"),
  };

  const options = Object.keys(summary).map(name => {
    const key = name as keyof ContextSummary;
    return {
      id: Object.keys(summary).indexOf(name) + 1,
      label: `${translations[key]} (${summary[key].length})`,
      items: summary[key],
    };
  });

  const onChange = (id: number) => {
    if (selected === id) {
      setSelected(null);
      setFilterList([]);
      return;
    }
    setSelected(id);
    setFilterList(options.find(o => o.id === id)?.items ?? []);
  };

  return (
    <ChipsSelect onChange={onChange} options={options} selectedId={selected} />
  );
};
