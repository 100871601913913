import { FC, useContext, useMemo } from "react";

import { useAppTranslation } from "@hooks";
import { Micronutrient, Nutrient } from "@typeDefinitions";

import { MicroItem } from "./MicroItem";
import { NormsContext } from "../NormsContext";
import { roundNutrientDigitsById } from "@utils";

interface MicroItemWrapperProps {
  micronutrient: Micronutrient;
  micronutrientsProduct: Nutrient[];
}

export const MicroItemWrapper: FC<MicroItemWrapperProps> = ({
  micronutrient,
  micronutrientsProduct,
}) => {
  const { isPolishChosen } = useAppTranslation();

  const micronutrientProduct: Nutrient | undefined = useMemo(
    () =>
      micronutrientsProduct.find(
        micronutrientProductItem =>
          micronutrientProductItem.id === micronutrient.id,
      ),
    [micronutrient, micronutrientsProduct],
  );

  const roundedValue =
    roundNutrientDigitsById(
      micronutrientProduct?.value ?? 0,
      micronutrient.nutrientCategoryId,
      micronutrient.id,
    ) || "-";

  const { isEmpty, getNorm } = useContext(NormsContext);

  const norm = getNorm(micronutrient.id);

  return (
    <MicroItem
      key={micronutrient.id}
      name={
        isPolishChosen
          ? micronutrient.descriptionPl
          : micronutrient.descriptionEn
      }
      units={micronutrient.units}
      displayedValue={roundedValue}
      value={micronutrientProduct?.value}
      norm={!isEmpty ? norm?.value ?? 0 : undefined}
    />
  );
};
