import { Box, CircularProgress, Stack } from "@mui/material";
import { getProgressColor } from "@views/dietician/PatientMonitoring2/components/CalendarDays/CalendarDaysUtils";
import {
  CalendarDayNotification,
  Circle,
} from "@views/dietician/PatientMonitoring2/components/CalendarDays/CalendarDayItem.styled";

type CalendarDayItem = {
  text: string;
  progress: number;
  selected: boolean;
  notification: boolean;
  today: boolean;
};
const CalendarDayItemMonth = ({
  text,
  progress,
  selected,
  notification,
  today,
}: CalendarDayItem) => {
  return (
    <Stack
      spacing="8px"
      alignItems="center"
      direction="row"
      justifyContent="center"
      sx={{ cursor: "pointer" }}
    >
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          variant="determinate"
          size={32}
          thickness={4}
          value={100}
          sx={{
            color: selected ? "#FFF" : "#F7F5FF",
          }}
        />
        <CircularProgress
          variant="determinate"
          sx={{
            color: getProgressColor(!!today, progress),
            position: "absolute",
            left: 0,
          }}
          size={32}
          value={progress}
          thickness={4}
        />
        {notification ? (
          <CalendarDayNotification>
            <Circle />
          </CalendarDayNotification>
        ) : null}
      </Box>
      <Box>
        <span>{text}</span>
      </Box>
    </Stack>
  );
};

export default CalendarDayItemMonth;
