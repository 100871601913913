import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  postProgramDietToBase,
  PostProgramDietToBaseRequest,
} from "@client/postProgramDietToBase";
import { MutationOptions } from "./types";
import { searchDietsQueryKeyNew } from "./useSearchDietsQueryNew";

export const usePostProgramDietToBaseMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, PostProgramDietToBaseMutationVariables>(
    ({ programId, programDayId, payload }) =>
      postProgramDietToBase(programId, programDayId, payload),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries([searchDietsQueryKeyNew]);
      },
    },
  );
};

interface PostProgramDietToBaseMutationVariables {
  programId: string;
  programDayId: string;
  payload: PostProgramDietToBaseRequest;
}
