import { Loupe, Plus } from "@assets/icons";
import { AutosaveWatchComponent } from "@components/AutosaveWatchComponent";
import { TabsChipsFiltersForm } from "@components/Filters/TabsChipsFilter/TabsChipsFilterForm";
import { SingleCardPageLayout } from "@components/PageLayout";
import { ITEMS_ON_PAGE } from "@components/PaginationNew";
import { ProgramsDataGrid, mapProgramRows } from "@components/ProgramsDataGrid";
import {
  DEFAULT_PROGRAMS_MACROS_RANGE,
  ProgramsFilters,
  ProgramsFiltersFormInput,
  useProgramsFiltersForm,
} from "@components/ProgramsFilters";
import { FormTextFieldClearableStyled } from "@components/ProgramsFilters/ProgramsFilters.styled";
import { ListTabs, useAppTranslation, useListTabs, useUserRoles } from "@hooks";
import { useSearchProgramsQueryNew } from "@hooks/queries";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";
import { Button, InputAdornment } from "@mui/material";
import { omit } from "lodash";
import { useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import { ThemeProviderWrapperNew } from "themeNew";
import { ProgramsViewWrapper } from "./ProgramsNew.styled";
import { useNavigate } from "react-router-dom";
import { PROGRAM_NEW } from "@routes";
import ProgramSearchPreviewDrawer from "@components/ProgramSearchPreview/ProgramSearchPreviewDrawer";
import ProgramSearchPreview from "@components/ProgramSearchPreview/ProgramSearchPreview";

export const ProgramsNew = () => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const form = useProgramsFiltersForm();
  const { programs, isFetching, meta, submit } = useSearchProgramsQueryNew();
  const { account } = useFetchDietitianAccountQuery();
  const { adminTabs, regularTabs } = useListTabs();
  const { isAdminClinicMember } = useUserRoles();
  const tab = form.watch("tabs");
  const [selectedProgramId, setSelectedProgramId] = useState<number | null>(
    null,
  );

  const tabs: { label: string; id: ListTabs }[] = useMemo(
    () => (isAdminClinicMember ? adminTabs : regularTabs),
    [t, isAdminClinicMember],
  );

  const tabsWithCount = useMemo(
    () =>
      tabs.map(option => ({
        ...option,
        label:
          option.id === tab && !!meta?.total
            ? `${option.label} (${meta?.total})`
            : option.label,
      })),
    [meta, t],
  );
  const mappedRows = useMemo(
    () => mapProgramRows(programs, account?.id ?? 0),
    [programs, mapProgramRows, account?.id],
  );

  const handleChangePage = (id: number) => {
    submit(state => ({ ...state, page: id + 1 }));
  };
  const handleChangePerPage = (perPage: number) => {
    submit(state => ({ ...state, perPage }));
  };

  const handleChangeTab = (tab: string) => {
    submit(state => ({
      ...state,
      tabs: tab as ListTabs,
      page: undefined,
    }));
    form.setValue("page", undefined);
  };

  const handleSubmitForm = (data: ProgramsFiltersFormInput) => {
    const nutrientsWithNonDefaultValues = Object.entries(
      data.nutrients || {},
    ).reduce((acc, [key, value]) => {
      const defaultValue = DEFAULT_PROGRAMS_MACROS_RANGE[key];
      if (
        !defaultValue ||
        defaultValue[0] !== value[0] ||
        defaultValue[1] !== value[1]
      ) {
        acc[key] = value;
      }
      return acc;
    }, {} as { [id: string]: [number, number] });

    submit({
      ...omit(data, ["nutrients"]),
      page: undefined,
      nutrients: nutrientsWithNonDefaultValues,
    });
  };

  return (
    <ThemeProviderWrapperNew>
      <ProgramsViewWrapper>
        <SingleCardPageLayout
          rebranding
          title={t("programs.nutritional_programs")}
          childrenClassName="flex flex-col gap-[24px]"
          extra={
            <Button
              variant="contained"
              onClick={() => navigate(PROGRAM_NEW)}
              startIcon={<Plus size="w-3 h-3" className="stroke-current" />}
            >
              {t("programs.add")}
            </Button>
          }
        >
          <FormProvider {...form}>
            <div className="grid gap-[16px]">
              <div className="flex gap-[4px]">
                <FormTextFieldClearableStyled
                  name="query"
                  size="small"
                  placeholder={t("programs.search")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Loupe />
                      </InputAdornment>
                    ),
                  }}
                />
                <ProgramsFilters total={meta?.total} />
              </div>
              <TabsChipsFiltersForm
                name="tabs"
                options={tabsWithCount}
                onChangeExternal={handleChangeTab}
              />
              <AutosaveWatchComponent
                isLoading={isFetching}
                onSubmit={handleSubmitForm}
                isDirtyCondition={false}
              />
              <ProgramsDataGrid
                rows={mappedRows}
                isOnePage={false}
                perPage={meta?.perPage ?? ITEMS_ON_PAGE.TWENTY_FIVE}
                onPageChange={handleChangePage}
                page={meta?.currentPage ? meta.currentPage - 1 : 0}
                setPerPage={handleChangePerPage}
                rowCount={meta?.total}
                loading={isFetching}
                onSelectRow={(id: number | null) => setSelectedProgramId(id)}
              />

              <ProgramSearchPreviewDrawer
                programId={selectedProgramId}
                onClose={() => {
                  setSelectedProgramId(null);
                }}
              >
                {selectedProgramId !== null && (
                  <ProgramSearchPreview programId={selectedProgramId} />
                )}
              </ProgramSearchPreviewDrawer>
            </div>
          </FormProvider>
        </SingleCardPageLayout>
      </ProgramsViewWrapper>
    </ThemeProviderWrapperNew>
  );
};
