import useFetchTagsProgramPreview from "@hooks/queries/program/preview/useFetchTagsProgramPreview";
import { Box, Chip, CircularProgress, Stack } from "@mui/material";
import { useAppTranslation } from "@hooks";
import { LanguagesSlugs } from "@typeDefinitions";
import { TagProgramPreviewSchema } from "@client/program/preview/fetchTagsProgramPreview";

type ProgramTagsProps = {
  programId: number;
};

const ProgramTags = ({ programId }: ProgramTagsProps) => {
  const { data } = useFetchTagsProgramPreview(programId, {
    staleTime: Infinity,
  });
  const { currentLanguage } = useAppTranslation();

  if (data === undefined) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  const tags = data.data;

  return (
    <Stack direction="row" gap="8px" flexWrap="wrap">
      {tags.map(tag => (
        <Chip
          key={tag.id}
          label={getName(tag, currentLanguage)}
          color="primary"
        />
      ))}
    </Stack>
  );
};

const getName = (tag: TagProgramPreviewSchema, lang: LanguagesSlugs) => {
  return tag.translations.find(t => t.lang === lang)?.name ?? "";
};

export default ProgramTags;
