import * as yup from "yup";
import { LanguagesSlugs } from "@typeDefinitions";
import { translationLang } from "@utils/yup_schema";

export interface ProgramPreviewSearchResource {
  id: number;
  name: string;
  translations: {
    lang: LanguagesSlugs;
    name: string | null;
  }[];
  duration: number | null;
  language: LanguagesSlugs;
  programDays: ProgramDaySearchPreview[];
}

export interface ProgramDaySearchPreview {
  id: number;
  name: string;
  translations: {
    lang: LanguagesSlugs;
    name: string | null;
  }[];
  frequency: number[];
  diet: {
    id: number;
  } | null;
}

const programDaySearchPreviewSchema = yup.object({
  id: yup.number().required(),
  name: yup.string().required(),
  translations: yup
    .array()
    .of(
      yup.object({
        lang: yup
          .mixed<LanguagesSlugs>()
          .oneOf(Object.values(LanguagesSlugs))
          .required(),
        name: yup.string().nullable().defined(),
      }),
    )
    .required(),
  frequency: yup.array().of(yup.number().required()).required(),
  diet: yup
    .object({
      id: yup.number().required(),
    })
    .defined()
    .nullable(),
});

export const programPreviewSearchSchema = yup.object({
  id: yup.number().required(),
  name: yup.string().required(),
  translations: yup
    .array()
    .of(
      yup.object({
        lang: translationLang,
        name: yup.string().nullable().defined(),
      }),
    )
    .required(),
  duration: yup.number().nullable().defined(),
  language: translationLang.default(LanguagesSlugs.PL),
  programDays: yup.array().of(programDaySearchPreviewSchema).defined(),
});
