import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const CheckedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20">
      <g clipPath="url(#clip0_130_71438)">
        <path
          d="M17 7L10 14L3 7"
          fill="none"
          stroke={props.fill ?? "currentColor"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_130_71438">
          <rect
            width="20"
            height="20"
            fill="currentcolor"
            transform="translate(20 20) rotate(-180)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
