import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const postDietitianActivation = async (
  id: string,
  payload: PostDietitianActivationDto,
) => {
  return await fetchData(
    `/dietitian/clinic/dietitians/${id}/activate`,
    APIMethods.POST,
    payload,
  );
};

export interface PostDietitianActivationDto {
  active: boolean;
}
