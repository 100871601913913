import { FetchClientResponse } from "@client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { paymentsOptions } from "./components/ClientPaymentsView";
import { useEffect } from "react";
import dayjs from "dayjs";
import { useAppTranslation } from "@hooks";

export const useEditClientPaymentsForm = (
  data?: EditClientPaymentsFormInput,
) => {
  const { t } = useAppTranslation();
  const resolver = yup.object().shape({
    isMobileAppActive: yup.boolean().required(),
    chat: yup.boolean().required(),
    recipeAlloweat: yup.boolean().required(),
    recipeClinic: yup.boolean().required(),
    activeUntil: yup
      .string()
      .nullable()
      .test(
        "is-future-date",
        t("client_profile.edit.payments.date_today_future"),
        value => {
          if (value === null) return true;
          return dayjs(value).isValid() && dayjs(value).isAfter(dayjs());
        },
      )
      .default(null),
  });

  const form = useForm<EditClientPaymentsFormInput>({
    defaultValues: data,
    resolver: yupResolver(resolver),
  });

  useEffect(() => {
    if (data) form.reset(data);
  }, [data]);
  return form;
};

export interface EditClientPaymentsFormInput {
  isActive: boolean;
  isMobileAppActive: boolean;
  activeUntil: string | null;
  indefinite: paymentsOptions;
  chat: boolean;
  recipeAlloweat: boolean;
  recipeClinic: boolean;
  addProducts: boolean;
}

export const mapEditClientPaymentsForm = (
  data?: FetchClientResponse,
): EditClientPaymentsFormInput | undefined => {
  if (!data) return;
  return {
    isActive: !!data.active,
    activeUntil: data.active?.until ?? null,
    indefinite: data.active?.until
      ? paymentsOptions.UNTIL
      : paymentsOptions.INDEFINITE,
    isMobileAppActive: data.active?.mobileApp ?? false,
    chat: data.profile.applicationModules.chat,
    recipeAlloweat: data.profile.applicationModules.accessRecipeBase.alloweat,
    recipeClinic: data.profile.applicationModules.accessRecipeBase.clinic,
    addProducts: data.profile.applicationModules.foodCreator,
  };
};
