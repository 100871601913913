import { Autocomplete, TextField, styled } from "@mui/material";

export const StyledAutocomplete = styled(Autocomplete)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.whiteBackground};
  border-radius: 6px;

  &.MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0.7rem 0.75rem;
  }

  &.MuiAutocomplete-root .MuiSvgIcon-root {
    width: 1.5rem;
    height: 1.5rem;
    color: ${({ theme }) => theme.palette.primary.main};
  }
` as typeof Autocomplete;

export const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    & fieldset {
      top: -7px;
    }
  }
`;
