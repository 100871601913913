import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const HeadWithShouldersInJacket = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      style={{ fill: "none" }}
      width="14"
      height="16"
      viewBox="0 0 14 16"
    >
      <path
        d="M3 4C3.02083 2.875 3.40625 1.92708 4.15625 1.15625C4.92708 0.40625 5.875 0.0208333 7 0C8.125 0.0208333 9.07292 0.40625 9.84375 1.15625C10.5938 1.92708 10.9792 2.875 11 4C10.9792 5.125 10.5938 6.07292 9.84375 6.84375C9.07292 7.59375 8.125 7.97917 7 8C5.875 7.97917 4.92708 7.59375 4.15625 6.84375C3.40625 6.07292 3.02083 5.125 3 4ZM9.5 4C9.47917 3.29167 9.23958 2.69792 8.78125 2.21875C8.30208 1.76042 7.70833 1.52083 7 1.5C6.29167 1.52083 5.69792 1.76042 5.21875 2.21875C4.76042 2.69792 4.52083 3.29167 4.5 4C4.52083 4.70833 4.76042 5.30208 5.21875 5.78125C5.69792 6.23958 6.29167 6.47917 7 6.5C7.70833 6.47917 8.30208 6.23958 8.78125 5.78125C9.23958 5.30208 9.47917 4.70833 9.5 4ZM14 14.9688C14 15.2604 13.9062 15.5 13.7188 15.6875C13.5312 15.875 13.2917 15.9688 13 15.9688H1C0.708333 15.9688 0.46875 15.875 0.28125 15.6875C0.09375 15.5 0 15.2604 0 14.9688V14.7188C0.0208333 13.4062 0.447917 12.2708 1.28125 11.3125C2.09375 10.375 3.14583 9.79167 4.4375 9.5625L7 13L9.5625 9.5625C10.8542 9.79167 11.9062 10.375 12.7188 11.3125C13.5521 12.2708 13.9792 13.4062 14 14.7188V14.9688ZM3.84375 11.2812C3.17708 11.5521 2.63542 11.9688 2.21875 12.5312C1.80208 13.0938 1.5625 13.7396 1.5 14.4688H6.21875L3.84375 11.2812ZM10.1562 11.2812L7.75 14.4688H12.5C12.4375 13.7396 12.1979 13.0938 11.7812 12.5312C11.3646 11.9688 10.8229 11.5521 10.1562 11.2812Z"
        fill="#727272"
      />
    </SvgIcon>
  );
};
