import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export async function resendActivationLinkRequest(data: { email: string }) {
  return await fetchData(
    "/dietitian/resend-verification-email",
    APIMethods.POST,
    data,
  );
}
