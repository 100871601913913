import { memo, useCallback } from "react";
import { useController } from "react-hook-form";

import { useAppTranslation } from "@hooks";
import { ProgramFormInput } from "@hooks/useProgramForm";
import { AbsoluteWrapper } from "./WorkModelSelect.styled";
import { Diets, Macros } from "@assets/icons/Program";
import { Checkbox } from "@components/Checkbox";
import { MuiInfoClickTooltip } from "@components/MuiInfoClickTooltip";
import { Labeled } from "@components/Labeled";

export const WorkModelSelect = memo(() => {
  const { t } = useAppTranslation();
  const {
    field: { value, onChange },
  } = useController<ProgramFormInput>({ name: "workModel" });
  const handleDiets = useCallback(() => onChange("diets"), [onChange]);
  const handleMacro = useCallback(() => onChange("macros"), [onChange]);

  return (
    <Labeled
      className={"gap-5"}
      label={`${t("program.info.choose_work_model")}:`}
      tooltip={
        <p>
          <span className="font-semibold">
            {t("program.info.work_model_tooltip_first_bold")}
          </span>
          {t("program.info.work_model_tooltip_first")}
          <br /> <br />
          <span className="font-semibold">
            {t("program.info.work_model_tooltip_second_bold")}
          </span>
          {t("program.info.work_model_tooltip_second")}
        </p>
      }
    >
      <div className="flex flex-wrap gap-7">
        <div className="w-96">
          <Checkbox
            fullWidth
            withBackground
            value={value === "diets"}
            onChange={handleDiets}
            label={t("program.info.diets_work_model")}
            iconEnd={<Diets size="w-10 h-10" />}
          />
        </div>
        <div className="w-96 relative">
          <AbsoluteWrapper>
            <MuiInfoClickTooltip
              iconSize="small"
              content={
                <p className="flex flex-col text-start">
                  <span>{t("common.in_preparation")}</span>
                </p>
              }
            />
          </AbsoluteWrapper>

          <Checkbox
            disabled
            fullWidth
            withBackground
            value={value === "macros"}
            onChange={handleMacro}
            label={t("program.info.macros_work_model")}
            iconEnd={<Macros size="w-10 h-10" className="stroke-current" />}
          />
        </div>
      </div>
    </Labeled>
  );
});
