import { createContext, ReactNode, useCallback, useMemo } from "react";

import _ from "lodash";

import { useAppTranslation } from "@hooks";
import { useFetchClinicProducts } from "@hooks/queries";

interface ClinicProductsContextIf {
  getClinicProductName: (id?: number) => string;
}

export const ClinicProductsContext = createContext<ClinicProductsContextIf>({
  getClinicProductName: () => "",
});

interface ClinicProductsContextProviderProps {
  children: ReactNode;
}

export const ClinicProductsContextProvider = ({
  children,
}: ClinicProductsContextProviderProps) => {
  const { clinicProducts } = useFetchClinicProducts();
  const { isPolishChosen } = useAppTranslation();
  const clinicProductsDict = useMemo(
    () =>
      _(clinicProducts)
        .groupBy("id")
        .mapValues(products => products[0])
        .mapValues(product =>
          isPolishChosen
            ? product.translations.pl.name
            : product.translations.en.name,
        )
        .value(),
    [clinicProducts, isPolishChosen],
  );

  const getClinicProductName = useCallback(
    (id?: number) => {
      if (id && clinicProductsDict[id])
        return clinicProductsDict[id.toString()];
      return "";
    },
    [clinicProductsDict, isPolishChosen],
  );

  const contextValue = {
    getClinicProductName,
  };

  return (
    <ClinicProductsContext.Provider value={contextValue}>
      {children}
    </ClinicProductsContext.Provider>
  );
};
