export const ClientSilhouettePhotoConst = {
  FRONT: 1,
  BACK: 2,
  SIDE: 3,
};

export const ClientSilhouettePhotoArray = [
  ClientSilhouettePhotoConst.FRONT,
  ClientSilhouettePhotoConst.SIDE,
  ClientSilhouettePhotoConst.BACK,
];
