import {
  UpdateSurveySubgroupQuestionSortPaylod,
  updateSurveySubgroupQuestionsSort,
} from "@client/surveys";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "../types";
import { fetchSurveyFullQueryKey } from "./useFetchSurveyFullQuery";

export const useUpdateSurveySubgroupQuestionsSortMutation = (
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    UpdateSurveySubgroupQuestionsSortMutationPayload
  >(({ id, payload }) => updateSurveySubgroupQuestionsSort(id, payload), {
    onSuccess: (data, variables) => {
      options?.onSuccess && options.onSuccess(data, variables);

      queryClient.invalidateQueries([fetchSurveyFullQueryKey]);
    },
    ...options,
  });
};

interface UpdateSurveySubgroupQuestionsSortMutationPayload {
  id: string;
  payload: UpdateSurveySubgroupQuestionSortPaylod;
}
