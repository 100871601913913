import {
  useFetchPatientProgramQuery,
  useFetchProgramQuery,
} from "@hooks/queries";
import { useParams } from "react-router-dom";

export function useProgram() {
  const { patientId, programId } = useParams();

  if (patientId) {
    return useFetchPatientProgramQuery(patientId, programId);
  } else {
    return useFetchProgramQuery(parseInt(programId ?? ""));
  }
}
