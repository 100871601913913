import {
  OptionsWrapper,
  Label,
} from "@components/Filters/SingleSelectChipFilter/SingleSelectChipFilter.styled";
import { ListTabs, useAppTranslation, useListTabs, useUserRoles } from "@hooks";
import { useMemo } from "react";
import { TabChip } from "./TabChip";

export const TabsField = () => {
  const { t } = useAppTranslation();
  const { adminTabs, regularTabs } = useListTabs();
  const { isAdminClinicMember } = useUserRoles();
  const tabs: { label: string; id: ListTabs }[] = useMemo(
    () => (isAdminClinicMember ? adminTabs : regularTabs),
    [t, isAdminClinicMember],
  );

  return (
    <div className="grid gap-[4px]">
      <Label>{t("common.programs")}</Label>

      <OptionsWrapper>
        {tabs.map(({ id, label }) => {
          return <TabChip key={id} label={label} formField={id} />;
        })}
      </OptionsWrapper>
    </div>
  );
};
