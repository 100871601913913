import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

import {
  MediaDto,
  mediaSchema,
  NutrientDto,
  nutrientSchema,
  TagDto,
  tagSchema,
} from "./common";

export async function fetchMealPattern(
  mealPatternId: number,
): Promise<ApiResponse<MealPatternDto>> {
  const rsp: ApiResponse<MealPatternDto> = await fetchData(
    `/dietitian/meal-patterns/${mealPatternId}`,
    APIMethods.GET,
  );
  return await getMealPatternResponseSchema.validate(rsp);
}

interface MeasureDto {
  id: number;
  description: string;
  descriptionEn: string;
  sortOrder: number;
  grams: number;
}

const measureSchema = yup.object().shape({
  id: yup.number().required(),
  description: yup.string().required(),
  descriptionEn: yup.string().required(),
  sortOrder: yup.number().required(),
  grams: yup.number().required(),
});

interface FoodDto {
  id: number;
  description: string | null;
  descriptionPl: string;
  nutrients: NutrientDto[];
  measures: MeasureDto[];
}

const foodSchema = yup.object().shape({
  id: yup.number().required(),
  description: yup.string().nullable().default(null),
  descriptionPl: yup.string().required(),
  nutrients: yup.array().of(nutrientSchema).required(),
  measures: yup.array().of(measureSchema).required(),
});

interface FoodRecipeDto {
  id: number;
  grams: number;
  foodMeasureId: number;
  food: FoodDto;
}

const foodRecipeSchema = yup.object().shape({
  id: yup.number().required(),
  grams: yup.number().required(),
  foodMeasureId: yup.number().required(),
  food: foodSchema.required(),
});

export interface MealPatternRecipeDto {
  id: number;
  name: string;
  nameEn?: string;
  description?: string;
  descriptionEn?: string;
  reviewed: boolean;
  isComplex: boolean;
  servings: number;
  tags: TagDto[];
  foodRecipe: FoodRecipeDto[];
  nutrients: NutrientDto[];
  media: MediaDto | null;
  comment: string | null;
  commentEn: string | null;
}

export interface MealPatternConfiguredRecipeDto {
  id: number;
  servings: number;
  recipe: MealPatternRecipeDto;
}

const recipeSchema = yup.object().shape({
  id: yup.number().required(),
  servings: yup.number().required(),
  recipe: yup
    .object()
    .shape({
      id: yup.number().required(),
      name: yup.string().required(),
      nameEn: yup.string().ensure(),
      description: yup.string().ensure(),
      descriptionEn: yup.string().ensure(),
      reviewed: yup.boolean().required(),
      isComplex: yup.boolean().required(),
      servings: yup.number().required(),
      tags: yup.array().of(tagSchema).required(),
      foodRecipe: yup.array().of(foodRecipeSchema).required(),
      nutrients: yup.array().of(nutrientSchema).required(),
      media: mediaSchema.nullable().default(null),
      comment: yup.string().nullable().default(null),
      commentEn: yup.string().nullable().default(null),
    })
    .required(),
});

interface ProductDto {
  id: number;
  description: string;
  descriptionEn?: string;
  nutrients: NutrientDto[];
  measures: MeasureDto[];
  config: {
    grams: number;
    foodMeasure: {
      id: number;
    };
  };
}

const configSchema = yup.object().shape({
  grams: yup.number().required(),
  foodMeasure: yup
    .object()
    .shape({
      id: yup.number().required(),
    })
    .required(),
});

const productSchema = yup.object().shape({
  id: yup.number().required(),
  description: yup.string().required(),
  descriptionEn: yup.string().ensure(),
  nutrients: yup.array().of(nutrientSchema).required(),
  measures: yup.array().of(measureSchema).required(),
  config: configSchema.required(),
});

export interface MealPatternDto {
  id: number;
  name: string;
  nameEn?: string;
  description?: string;
  descriptionEn?: string;
  reviewed: boolean;
  food: ProductDto[];
  recipes: MealPatternConfiguredRecipeDto[];
  tags: TagDto[];
  nutrients: NutrientDto[];
  version?: number | null;
}

const mealPatternDto = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  nameEn: yup.string().ensure(),
  description: yup.string().ensure(),
  descriptionEn: yup.string().ensure(),
  reviewed: yup.boolean().required(),
  food: yup.array().of(productSchema).required(),
  recipes: yup.array().of(recipeSchema).required(),
  tags: yup.array().of(tagSchema).required(),
  nutrients: yup.array().of(nutrientSchema).required(),
  version: yup.number().nullable(),
});

const getMealPatternResponseSchema = yup.object().shape({
  data: mealPatternDto.required(),
});
