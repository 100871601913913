import { useEffect } from "react";
import { useForm } from "react-hook-form";

import * as yup from "yup";

import {
  TagsAnswerQuestionDto,
  UpdateSingedSurveyRequest,
} from "@client/surveys";
import { yupResolver } from "@hookform/resolvers/yup";

export const useTagAnswerForm = (
  values: TagAnswerFormInputs | undefined,
  required: boolean,
) => {
  const resolver = yup.object().shape({
    value: required ? yup.boolean().required() : yup.boolean().nullable(),
    tagsId: yup.lazy((_, { parent }) =>
      parent.value
        ? yup.array().of(yup.number().required()).required().min(1)
        : yup.array().of(yup.number().required()).required(),
    ),
  });

  const form = useForm<TagAnswerFormInputs>({
    resolver: yupResolver(resolver),
  });

  useEffect(() => {
    if (values) form.reset(values, { keepDirtyValues: true });
  }, [values]);

  return form;
};

export const mapTagAnswerForm = (
  data?: TagsAnswerQuestionDto,
): TagAnswerFormInputs => {
  if (!data) return { value: null, description: null, tagsId: [] };
  return {
    ...data,
    value: data.value,
    tagsId: data.tagsId.map(id => id.toString()),
  };
};

export const mapTagAnswerRequest = (
  data: TagAnswerFormInputs,
  questionId: number,
): UpdateSingedSurveyRequest => {
  const { description, tagsId, value } = data;

  if (!value)
    return {
      questionId,
      answer: {
        tagsId: [],
        description: null,
        value: false,
      },
    };

  return {
    questionId,
    answer: {
      tagsId: tagsId.map(id => Number(id)),
      description,
      value: value,
    },
  };
};

export interface TagAnswerFormInputs {
  value: boolean | null;
  description: string | null;
  tagsId: string[];
}
