import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  LabeledDatePickerWrapper,
  MacrosListWrapper,
  MicrosListWrapper,
  MonitoringChartSectionStyled,
  ResponsiveContainerWrapper,
} from "./MonitoringChartSection.styled";
import { useContext, useMemo, useState } from "react";
import {
  MonitoringContext,
  ToggleButtonValue,
} from "../context/MonitoringContext";
import _, { round } from "lodash";
import dayjs from "dayjs";
import {
  ToggleButtonGroup,
  Typography,
  useTheme,
  Tooltip as TooltipMui,
} from "@mui/material";
import { useVisibleNutrients } from "@context/VisibleNutrientsContext";
import { useAppParams, useAppTranslation, useNutrientDictionary } from "@hooks";
import {
  CaptionStyled,
  StyledToggleButton,
} from "./MonitoringDayDetails.styled";
import { MACROS_ARRAY } from "@utils/macros";
import { Nutrient } from "@typeDefinitions";
import { NutrientItem } from "./NutrientItem";
import { MicronutrientPreview } from "@components/MicronutrientsModal";
import {
  useFetchMonitoringProgramDayCalendarQuery,
  useFetchMonitoringScheduleCalendarQuery,
} from "@hooks/queries";
import { MonitoringChartTooltip } from "./MonitoringChartTooltip";
import { MonitoringChartLegend } from "./MonitoringChartLegend";
import { MonitoringChartDatePicker } from "./MonitoringChartDatePicker";

export const MonitoringChartSection = () => {
  enum SummaryModes {
    AVG = "average",
    SUM = "sum",
    BALANCE = "balance",
  }
  const { patientId } = useAppParams();
  const { t } = useAppTranslation();
  const { palette, colors } = useTheme();

  const { toggleButton } = useContext(MonitoringContext);
  const [selectedNutrient, setSelectedNutrient] = useState<number>(
    MACROS_ARRAY[0],
  );

  const [summaryMode, setSummaryMode] = useState(SummaryModes.AVG);
  const { nutrients: visibleNutrients } = useVisibleNutrients();
  const { getNutrient } = useNutrientDictionary();

  const selectedNutrientDetails = getNutrient(selectedNutrient);

  const [startDate, setStartDate] = useState<Date | null>(
    dayjs().subtract(1, "week").toDate(),
  );
  const [endDate, setEndDate] = useState<Date | null>(dayjs().toDate());
  const enabled = !!startDate && !!endDate;
  const { programDayDays } = useFetchMonitoringProgramDayCalendarQuery(
    patientId,
    dayjs(startDate).format("YYYY-MM-DD"),
    dayjs(endDate).format("YYYY-MM-DD"),
    { enabled: enabled && toggleButton === ToggleButtonValue.PROGRAM },
  );
  const { scheduleDays } = useFetchMonitoringScheduleCalendarQuery(
    patientId,
    dayjs(startDate).format("YYYY-MM-DD"),
    dayjs(endDate).format("YYYY-MM-DD"),
    { enabled: enabled && toggleButton === ToggleButtonValue.SCHEDULE },
  );

  const days = useMemo(
    () =>
      toggleButton === ToggleButtonValue.PROGRAM
        ? programDayDays
        : scheduleDays,
    [programDayDays, scheduleDays, toggleButton],
  );

  const sumNutrients = (nutrientsLists?: Nutrient[][]) => {
    return _(nutrientsLists)
      .flatten()
      .groupBy(n => n.id)
      .mapValues(list => _(list).sumBy(l => l.value))
      .value();
  };
  const avgNutrients = (nutrientsLists?: Nutrient[][]) => {
    return _(nutrientsLists)
      .flatten()
      .groupBy(n => n.id)
      .mapValues(list => _(list).sumBy(l => l.value) / list.length)
      .value();
  };

  const dayNutrients = useMemo(
    () =>
      summaryMode === SummaryModes.AVG
        ? avgNutrients(days?.map(d => d.foodDiary?.nutrients ?? []))
        : sumNutrients(days?.map(d => d.foodDiary?.nutrients ?? [])),
    [days, summaryMode],
  );

  const dayNutrientsTarget = useMemo(
    () =>
      summaryMode === SummaryModes.AVG
        ? avgNutrients(days?.map(d => d.target?.nutrients ?? []))
        : sumNutrients(days?.map(d => d.target?.nutrients ?? [])),
    [days, summaryMode],
  );

  const getValue = (dict: Record<string, number>, id: string) => {
    if (dict[id]) return dict[id];
    return;
  };

  const mapNutrientsArray = (dict: Record<string, number>) =>
    Object.keys(dict).map(id => ({
      id: Number(id),
      value: round(dict[id], 2),
    }));

  const mappedDays = days?.map(day => {
    if (!day) return;
    const { date, foodDiary, target } = day;

    return {
      date,
      label: dayjs(date).format("DD.MM"),
      unit: selectedNutrientDetails?.unit ?? "",
      value: foodDiary?.nutrients.find(n => n.id === selectedNutrient)?.value,
      target: target?.nutrients.find(n => n.id === selectedNutrient)?.value,
      dayName: foodDiary?.programDay?.name,
      dayNameTarget: target?.programDay?.name,
    };
  });

  const onRangeChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <MonitoringChartSectionStyled>
      <div className="flex justify-center">
        <Typography variant="h6">{t("common.average_values")}:</Typography>
      </div>

      <LabeledDatePickerWrapper>
        <Typography>{t("monitoring.show_data_for")}:</Typography>
        <MonitoringChartDatePicker
          startDate={startDate}
          endDate={endDate}
          onRangeChange={onRangeChange}
        />
      </LabeledDatePickerWrapper>

      <div className="w-full overflow-auto">
        <ToggleButtonGroup
          onChange={(_, val) => val && setSelectedNutrient(Number(val))}
          value={selectedNutrient}
          exclusive
        >
          {[...MACROS_ARRAY, ...visibleNutrients].map(n => {
            const details = getNutrient(Number(n));
            return (
              <StyledToggleButton key={n} value={Number(n)}>
                <TooltipMui title={details?.name}>
                  <CaptionStyled>{details?.short}</CaptionStyled>
                </TooltipMui>
              </StyledToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </div>

      <ResponsiveContainerWrapper>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={mappedDays}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="label" />
            <YAxis
              dx={2}
              label={{
                value: selectedNutrientDetails?.unit,
                angle: -90,
                position: "insideLeft",
              }}
              domain={["dataMin", "auto"]}
            />
            <Tooltip
              content={props => {
                const details = props.payload?.find(
                  p => p.payload.label === props.label,
                );

                if (!details) return <></>;

                const date = dayjs(details.payload.date);
                const { payload } = details;

                return (
                  <MonitoringChartTooltip
                    date={date}
                    dayName={payload.dayName}
                    dayNameTarget={payload.dayNameTarget}
                    target={payload.target}
                    value={payload.value}
                    unit={payload.unit}
                  />
                );
              }}
            />
            <Line
              type="monotone"
              dataKey="value"
              stroke={palette.primary.main}
              activeDot={{ r: 8 }}
            />
            <Line
              type="monotone"
              dataKey="target"
              stroke={colors.chartLightPurple}
            />
            <Legend content={<MonitoringChartLegend />} />
          </LineChart>
        </ResponsiveContainer>
      </ResponsiveContainerWrapper>

      <ToggleButtonGroup
        exclusive
        value={summaryMode}
        onChange={(_, val) => val && setSummaryMode(val)}
      >
        <StyledToggleButton value={SummaryModes.AVG}>
          <CaptionStyled>{t("common.average")}</CaptionStyled>
        </StyledToggleButton>
        <StyledToggleButton value={SummaryModes.SUM}>
          <CaptionStyled>{t("common.sum")}</CaptionStyled>
        </StyledToggleButton>
        <StyledToggleButton value={SummaryModes.BALANCE}>
          <CaptionStyled>{t("common.balance")}</CaptionStyled>
        </StyledToggleButton>
      </ToggleButtonGroup>

      <div className="flex items-end">
        <MacrosListWrapper>
          {MACROS_ARRAY.map(nutrient => {
            const value = getValue(dayNutrients, nutrient.toString());
            const target = getValue(dayNutrientsTarget, nutrient.toString());

            return (
              <NutrientItem
                key={nutrient}
                id={Number(nutrient)}
                target={target}
                value={value}
                chart
                difference={summaryMode === SummaryModes.BALANCE}
              />
            );
          })}
        </MacrosListWrapper>

        {summaryMode !== SummaryModes.BALANCE && (
          <div className="p-4">
            <MicronutrientPreview
              means={mapNutrientsArray(dayNutrients)}
              targetNutrients={mapNutrientsArray(dayNutrientsTarget)}
            />
          </div>
        )}
      </div>

      <MicrosListWrapper>
        {visibleNutrients.map(nutrient => {
          const value = getValue(dayNutrients, nutrient);
          const target = getValue(dayNutrientsTarget, nutrient);

          return (
            <NutrientItem
              key={nutrient}
              id={Number(nutrient)}
              target={target}
              value={value}
              difference={summaryMode === SummaryModes.BALANCE}
            />
          );
        })}
      </MicrosListWrapper>
    </MonitoringChartSectionStyled>
  );
};
