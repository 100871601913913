import { Calculator } from "@assets/icons/DesignSystem";
import { MeasureDto } from "@client";
import { SectionTitleWrapper } from "@components/PreviewDrawer/common/SectionTitleWrapper";
import { useAppTranslation, useMealClientContext } from "@hooks";
import { Nutrient } from "@typeDefinitions";

import { ProductRow } from "./ProductRow";
import { ProductsSummary } from "./ProductsSummary";
import { TableHeaders } from "./TableHeaders";
import { useEffect, useMemo } from "react";
import { usePostPatientProductsContextMutation } from "@hooks/queries";

interface ProductsTableProps {
  products: ProductIf[];
  nutrients: Nutrient[];
}

export interface ProductIf {
  id: number;
  foodId: number;
  nutrients: Nutrient[];
  name: string;
  grams: number;
  measureId: number;
  measures: MeasureDto[];
}

export const ProductsTable = ({ products, nutrients }: ProductsTableProps) => {
  const { t } = useAppTranslation();
  const foodIds = useMemo(() => products.map(({ id }) => id), [products]);
  const { mutate: fetchProducts } = usePostPatientProductsContextMutation();

  useEffect(() => {
    if (foodIds.length) fetchProducts({ payload: foodIds });
  }, [foodIds.length]);

  return (
    <SectionTitleWrapper
      Icon={Calculator}
      title={t("common.energy_efficiency")}
    >
      <div className="grid gap-[8px]">
        <TableHeaders />
        {products.map((p, idx) => (
          <ProductRow {...p} key={idx} />
        ))}
        <ProductsSummary nutrients={nutrients} />
      </div>
    </SectionTitleWrapper>
  );
};
