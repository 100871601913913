import { useEffect } from "react";
import { useForm } from "react-hook-form";

import * as yup from "yup";

import {
  TableAnswerQuestionDto,
  TableConfigDto,
  UpdateSingedSurveyRequest,
} from "@client/surveys";
import { yupResolver } from "@hookform/resolvers/yup";

export const useTableAnswerForm = (
  values: TableAnswerFormInputs | undefined,
  required: boolean,
) => {
  const cellSchema = yup.object().shape({
    value: yup.string().nullable(),
  });
  const resolver = yup.object().shape({
    comment: yup.string().nullable(),
    cells: required
      ? yup
          .array()
          .of(cellSchema)
          .test(value => value?.some(cell => !!cell.value))
      : yup.array().of(cellSchema),
  });

  const form = useForm<TableAnswerFormInputs>({
    resolver: yupResolver(resolver),
  });

  useEffect(() => {
    if (values) form.reset(values, { keepDirtyValues: true });
  }, [values]);

  return form;
};

export const mapTableAnswerForm = (
  config: TableConfigDto,
  answers?: TableAnswerQuestionDto,
): TableAnswerFormInputs => {
  const { rows, columns } = config;
  const cells = rows.flatMap(row =>
    columns.map(column => {
      const value =
        answers?.data.find(
          a => a.column.uuid === column.uuid && a.row.uuid === row.uuid,
        )?.value ?? null;
      return { column, row, value };
    }),
  );

  return {
    cells,
    comment: answers?.comment ?? null,
  };
};

export const mapTableAnswerRequest = (
  data: TableAnswerFormInputs,
  questionId: number,
): UpdateSingedSurveyRequest => {
  const { cells, comment } = data;

  return {
    questionId,
    answer: {
      data: cells.map(({ column, row, value }) => ({
        column: { uuid: column.uuid },
        row: { uuid: row.uuid },
        value,
      })),
      comment,
    },
  };
};

interface UuidIf {
  uuid: string;
}
interface CellIf {
  row: UuidIf;
  column: UuidIf;
  value: string | null;
}

export interface TableAnswerFormInputs {
  comment: string | null;
  cells: CellIf[];
}
