import { TabContext } from "@mui/lab";
import { MouseEvent, useState } from "react";
import {
  CustomTabPanel,
  CustomToggleButton,
  CustomToggleButtonGroup,
} from "@views/dietician/PatientMonitoring2/components/TabPanel.styled";
import AverageNutrientsValues from "@views/dietician/PatientMonitoring2/components/NutrientValues/NutrientValues/AverageNutrientsValues";
import SumNutrientsValues from "@views/dietician/PatientMonitoring2/components/NutrientValues/NutrientValues/SumNutrientsValues";
import { useFetchDictionaryNutrients } from "@hooks/queries/dictionaries";
import { useAppTranslation } from "@hooks";
import { NutrientsMonitoringResource } from "@client/resources/NutrientsMonitoringResource";
import { Box } from "@mui/material";

type NutrientsValuesSectionProps = {
  data: NutrientsMonitoringResource[];
};

const NutrientsValuesSectionByDays = ({
  data,
}: NutrientsValuesSectionProps) => {
  const [tab, setTab] = useState("1");
  const { t } = useAppTranslation();

  const { data: nutrients } = useFetchDictionaryNutrients();

  const handleChange = (
    event: MouseEvent<HTMLElement>,
    newValue: string | null,
  ) => {
    newValue && setTab(newValue);
  };

  return (
    <TabContext value={tab}>
      <Box>
        <CustomToggleButtonGroup value={tab} exclusive onChange={handleChange}>
          <CustomToggleButton value="1">
            {t("monitoring2.nutritional_box.values_tabs.average")}
          </CustomToggleButton>
          <CustomToggleButton value="2">
            {t("monitoring2.nutritional_box.values_tabs.sum")}
          </CustomToggleButton>
        </CustomToggleButtonGroup>
      </Box>
      <CustomTabPanel value="1">
        <AverageNutrientsValues data={data} nutrients={nutrients ?? []} />
      </CustomTabPanel>
      <CustomTabPanel value="2">
        <SumNutrientsValues data={data} nutrients={nutrients ?? []} />
      </CustomTabPanel>
    </TabContext>
  );
};

export default NutrientsValuesSectionByDays;
