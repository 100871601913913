import { LoadingButton } from "@mui/lab";
import { Typography, styled } from "@mui/material";

export const ContentWrapper = styled("div")`
  padding: 0.75rem 0;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.medium[600]};
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 200%;
  padding: 0 0.75rem;
`;

export const MenuButton = styled(LoadingButton)`
  justify-content: start;
  padding: 0 0.75rem;
  width: 100%;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
  line-height: 150%;
`;
