import { Card, Typography, styled } from "@mui/material";

export const EmptyStateCard = styled(Card)`
  border-radius: 14px;
  box-shadow: unset;
  height: 100%;
  padding: 18px 0;
`;

export const ContentWrapper = styled("div")`
  display: grid;
  place-items: center;
  gap: 8px;
`;

export const StyledTitle = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;

export const StyledSubTitle = styled(Typography)`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.4px;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
  white-space: pre-line;
`;
