import { useFormContext } from "react-hook-form";

import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";

import { useAppParams, useAppTranslation } from "@hooks";

import { ProductId } from "../ProductEditDrawer";
import { mapProductEditRequest, ProductEditInput } from "../useProductEditForm";
import { Footer } from "./DrawerFooter.styled";
import { useUpdateDietMealProductMutation } from "@views/dietician/DietCreator/hooks";

interface DrawerFooterProps {
  onClose: () => void;
  mealId: string;
  productIds: ProductId | null;
}

export const DrawerFooter = ({
  onClose,
  mealId,
  productIds,
}: DrawerFooterProps) => {
  const { t } = useAppTranslation();
  const { dietId } = useAppParams();
  const { handleSubmit } = useFormContext<ProductEditInput>();
  const { mutate: updateProduct, isLoading: isUpdating } =
    useUpdateDietMealProductMutation({ onSuccess: onClose });
  const productExist = productIds !== null;

  const handleEdit = (onSuccess: () => void) =>
    handleSubmit(
      data =>
        productExist
          ? updateProduct(
              {
                dietId,
                mealId,
                productId: productIds.dietProductId,
                payload: mapProductEditRequest(data),
              },
              { onSuccess },
            )
          : {},
      err => console.log(err),
    )();

  const onSubmit = () => handleEdit(onClose);

  return (
    <Footer>
      <Button variant="outlined" onClick={onClose}>
        {t("common.cancel")}
      </Button>
      <LoadingButton
        variant="contained"
        onClick={onSubmit}
        loading={isUpdating}
      >
        {t("common.save")}
      </LoadingButton>
    </Footer>
  );
};
