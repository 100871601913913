import { useEffect } from "react";
import { useForm } from "react-hook-form";

import * as yup from "yup";

import {
  FoodAnswerQuestionDto,
  UpdateSingedSurveyRequest,
} from "@client/surveys";
import { yupResolver } from "@hookform/resolvers/yup";

export const useFoodAnswerForm = (
  values: FoodAnswerFormInputs | undefined,
  required: boolean,
) => {
  const resolver = yup.object().shape({
    value: required ? yup.boolean().required() : yup.boolean().nullable(),
    foodGroupsId: yup.lazy((_, { parent }) =>
      parent.value
        ? yup.array().of(yup.number().required()).required().min(1)
        : yup.array().of(yup.number().required()).required(),
    ),
  });

  const form = useForm<FoodAnswerFormInputs>({
    resolver: yupResolver(resolver),
  });

  useEffect(() => {
    if (values) form.reset(values, { keepDirtyValues: true });
  }, [values]);

  return form;
};

export const mapFoodAnswerForm = (
  data?: FoodAnswerQuestionDto,
): FoodAnswerFormInputs => {
  if (!data) return { value: null, description: null, foodGroupsId: [] };
  return {
    ...data,
    value: data.value,
    foodGroupsId: data.foodGroupsId.map(id => id.toString()),
  };
};

export const mapFoodAnswerRequest = (
  data: FoodAnswerFormInputs,
  questionId: number,
): UpdateSingedSurveyRequest => {
  const { description, foodGroupsId, value } = data;

  if (!value)
    return {
      questionId,
      answer: {
        foodId: [],
        foodGroupsId: [],
        description: null,
        value: false,
      },
    };

  return {
    questionId,
    answer: {
      foodId: [],
      foodGroupsId: foodGroupsId.map(id => Number(id)),
      description,
      value: value,
    },
  };
};

export interface FoodAnswerFormInputs {
  value: boolean | null;
  description: string | null;
  foodGroupsId: string[];
}
