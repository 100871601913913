import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  fetchCreatorRecipe,
  FetchCreatorRecipeResponse,
} from "@client/meals/fetchCreatorRecipe";
import { QueryOptionsTyped, fetchCreatorMealQueryKey } from "@hooks/queries";
import { ApiResponse } from "@typeDefinitions";
import { FetchMealResponse } from "@client/meals";
import { useMealVersion } from "@views/dietician/MealEdit/hooks";

export const fetchCreatorRecipeQueryKey = "fetchCreatorRecipeQueryKey";

export const useFetchCreatorRecipeQuery = (
  mealId: string,
  recipeId: string,
  options?: QueryOptionsTyped<ApiResponse<FetchCreatorRecipeResponse>>,
) => {
  const queryClient = useQueryClient();
  const { version } = useMealVersion();

  const { data, ...rest } = useQuery(
    [fetchCreatorRecipeQueryKey, mealId, recipeId],
    () => fetchCreatorRecipe(mealId, recipeId, version),
    {
      ...options,
      onSuccess: recipe => {
        options?.onSuccess && options.onSuccess(recipe);
        queryClient.setQueryData<ApiResponse<FetchMealResponse>>(
          [fetchCreatorMealQueryKey, mealId],
          old => {
            if (!old) return;
            return {
              ...old,
              data: {
                ...old.data,
                recipes: old?.data.recipes.map(r => {
                  if (r.id.toString() === recipeId) return recipe.data;
                  else return r;
                }),
              },
            };
          },
        );
      },
    },
  );

  return {
    recipe: data?.data,
    ...rest,
  };
};
