import { t } from "i18next";

import { useSearchDietsPreviewMealQuery } from "@hooks/queries/diets";

import { ProductAccordion } from "./Accordions/ProductAccordion";
import { RecipeAccordion } from "./Accordions/RecipeAccordion";
import { Title } from "./MealDetails.styled";
import { NutrientsList } from "./NutrientsList";

interface MealDetailsProps {
  dietId: string;
  mealId: string;
}
export const MealDetails = ({ dietId, mealId }: MealDetailsProps) => {
  const { meal } = useSearchDietsPreviewMealQuery(dietId, mealId);

  return (
    <div className="grid gap-[16px]">
      <NutrientsList nutrients={meal?.nutrients ?? []} />

      {!!meal?.recipes.length && (
        <>
          <Title>{t("common.recipes")}</Title>
          {meal.recipes.map(r => (
            <RecipeAccordion
              key={r.id}
              recipe={r}
              dietId={dietId}
              mealId={mealId}
            />
          ))}
        </>
      )}

      {!!meal?.products.length && (
        <>
          <Title>{t("common.products")}</Title>
          {meal.products.map(p => (
            <ProductAccordion
              key={p.id}
              product={p}
              dietId={dietId}
              mealId={mealId}
            />
          ))}
        </>
      )}
    </div>
  );
};
