import React from "react";
import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const DietTemplates = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 93 73">
    <g
      id="icon-shablony_diety"
      data-name="icon-shablony diety"
      transform="translate(-126 -144)"
      opacity="0.4"
    >
      <rect
        id="Rectangle_1175"
        data-name="Rectangle 1175"
        width="93"
        height="73"
        transform="translate(126 144)"
        fill="#fff"
        opacity="0"
      />
      <line
        id="Line_327"
        data-name="Line 327"
        x2="32"
        transform="translate(170 165)"
        fill="none"
        stroke="current"
        strokeWidth="2"
      />
      <line
        id="Line_328"
        data-name="Line 328"
        x2="22"
        transform="translate(170 175)"
        fill="none"
        stroke="current"
        strokeWidth="2"
      />
      <line
        id="Line_329"
        data-name="Line 329"
        x2="32"
        transform="translate(170 185)"
        fill="none"
        stroke="current"
        strokeWidth="2"
      />
      <line
        id="Line_330"
        data-name="Line 330"
        x2="22"
        transform="translate(170 195)"
        fill="none"
        stroke="current"
        strokeWidth="2"
      />
      <g
        id="Rectangle_1011"
        data-name="Rectangle 1011"
        transform="translate(142 163)"
        fill="none"
        stroke="current"
        strokeWidth="1.5"
      >
        <rect width="15" height="15" rx="3" stroke="none" />
        <rect
          x="0.75"
          y="0.75"
          width="13.5"
          height="13.5"
          rx="2.25"
          fill="none"
        />
      </g>
      <g
        id="Rectangle_1012"
        data-name="Rectangle 1012"
        transform="translate(142 182)"
        fill="none"
        stroke="current"
        strokeWidth="1.5"
      >
        <rect width="15" height="15" rx="3" stroke="none" />
        <rect
          x="0.75"
          y="0.75"
          width="13.5"
          height="13.5"
          rx="2.25"
          fill="none"
        />
      </g>
    </g>
  </SvgIcon>
);
