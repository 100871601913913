import { useMediaQuery, useTheme } from "@mui/material";

import { useAppTranslation } from "@hooks";

import {
  CardStyled,
  LayoutWithButton,
  TextStyled,
  TextWrapper,
  TitleStyled,
} from "./IntroductionView.styled";

export const OutroView = () => {
  const { breakpoints } = useTheme();
  const downSmall = useMediaQuery(`${breakpoints.down("smallNew")}`);
  const { t } = useAppTranslation();

  if (downSmall)
    return (
      <>
        <TextWrapper>
          <TitleStyled>{t("questionnaires.outro.title")}</TitleStyled>

          <TextStyled>{t("questionnaires.outro.text")}</TextStyled>
        </TextWrapper>
      </>
    );
  return (
    <CardStyled>
      <LayoutWithButton>
        <TextWrapper>
          <TitleStyled>{t("questionnaires.outro.title")}</TitleStyled>

          <TextStyled>{t("questionnaires.outro.text")}</TextStyled>
        </TextWrapper>
      </LayoutWithButton>
    </CardStyled>
  );
};
