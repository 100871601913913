import { HTMLAttributes } from "react";

import { MacrosText } from "@components/MacrosText";

import { ClientContextIf } from "@typeDefinitions";
import { OptionText, Wrapper } from "./CustomOption.styled";
import { OptionsIf } from "./SearchAutocomplete";

interface OptionsClientContextIf extends OptionsIf {
  clientContext?: ClientContextIf;
}

export const CustomOption = (
  props: HTMLAttributes<HTMLLIElement>,
  option: OptionsClientContextIf,
) => {
  return (
    <Wrapper {...props} key={option.id}>
      <OptionText className="self-start">{option.label}</OptionText>
      <div>
        <MacrosText
          nutrients={option.nutrients}
          userTags={{
            allergen: option.clientContext?.hasAllergens,
            disliked: option.clientContext?.hasUnliked,
            liked: option.clientContext?.hasLiked,
          }}
        />
      </div>
    </Wrapper>
  );
};
