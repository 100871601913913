import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MutationOptions } from "../types";
import { fetchClinicProductsQueryKey } from "../useFetchClinicProducts";
import { patchClinicProduct } from "@client/settings";

interface PatchClinicProductMutationInterface {
  id: number;
  isActive: boolean;
}

export const usePatchClinicProductMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, PatchClinicProductMutationInterface>(
    ({ id, isActive }) => patchClinicProduct(id, isActive),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries([fetchClinicProductsQueryKey]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};
