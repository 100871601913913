import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const updateClientFoodCategoriesPreferences = async (
  patientId: string,
  payload: UpdateClientFoodCategoriesPreferencesRequest,
) => {
  await fetchData(
    `/dietitian/patients/${patientId}/food-group-preferences`,
    APIMethods.PUT,
    payload,
  );
};

export interface UpdateClientFoodCategoriesPreferencesRequest {
  isLiked: boolean;
  categoriesId: number[];
}
