import { Drawer, styled } from "@mui/material";
import MealsListDrawer from "@views/dietician/PatientMonitoring2/components/MealsBox/MealsListDrawer";
import { useState } from "react";
import ShowMore from "@views/dietician/PatientMonitoring2/components/ShowMore";
import { useAppTranslation } from "@hooks";

const MealsBoxShowMore = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { t } = useAppTranslation();
  return (
    <>
      <ShowMore
        label={t("monitoring2.meals_box.details")}
        onClick={() => setOpenDrawer(true)}
      />
      <CustomDrawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        anchor="right"
        PaperProps={{
          sx: { width: "640px", padding: "16px", scrollbarWidth: "thin" },
        }}
      >
        <MealsListDrawer onClose={() => setOpenDrawer(false)} />
      </CustomDrawer>
    </>
  );
};

const CustomDrawer = styled(Drawer)`
  .MuiBackdrop-root {
    background: transparent;
  }
` as typeof Drawer;

export default MealsBoxShowMore;
