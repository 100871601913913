import { MenuItem, styled } from "@mui/material";

export const PatientProgramsGridStyled = styled("span")`
  color: #141414;
  font-family: Figtree, serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
`;

export const SecondText = styled("span")`
  color: #727272;
  font-family: Figtree, serif;
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0.4px;
`;

export const CustomMenuItem = styled(MenuItem)`
  .MuiListItemIcon-root {
    min-width: auto;
  }
  gap: 8px;
  align-items: center;
` as typeof MenuItem;
