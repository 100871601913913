import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteProps,
  Divider,
  styled,
  Table,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { DragHandle } from "@assets/icons";
import { FormTextField } from "@components/FormTextField";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { SelectOption } from "@components/FormAutocomplete";

export const StyledDragHandle = styled(DragHandle)`
  cursor: pointer;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.palette.grey.A400};
`;

export const StyledTable = styled(Table)`
  border-collapse: separate;
`;

export const StyledTableContainer = styled(TableContainer)`
  max-height: 600px;
`;

interface StyledTableCellProps {
  withBorder?: boolean;
  minWidth?: string;
  width?: string;
  bold?: boolean;
  sticky?: boolean;
  textAlign?: "left" | "right";
}

export const StyledTableCell = styled(TableCell, {
  shouldForwardProp: propName =>
    !["withBorder", "bold", "sticky"].includes(propName as string),
})<StyledTableCellProps>`
  padding: 0.25rem 0.5rem;
  border-right: ${({ withBorder, theme }) =>
    withBorder && `1px solid ${theme.palette.divider}`};
  min-width: ${({ minWidth }) => minWidth};
  font-weight: ${({ bold }) => (bold ? "600" : "")};
  position: ${({ sticky }) => (sticky ? "sticky" : "")};
  left: ${({ sticky }) => (sticky ? "0" : "")};
  background-color: ${({ sticky }) => (sticky ? "white" : "")};
  z-index: ${({ sticky }) => (sticky ? "99" : "")};
`;

export const StyledTableFooterCell = styled(StyledTableCell)`
  border-bottom: none;
`;

export const StickySummaryCell = styled(TableCell)`
  padding: 0.75rem 0.935rem;
  position: sticky;
  left: 0;
  background-color: ${({ theme }) => theme.colors.solidLightPurple};
  border-bottom: none;
`;

export const StyledTableHeaderCell = styled(TableCell, {
  shouldForwardProp: propName =>
    !["withBorder", "minWidth", "width", "sticky", "textAlign"].includes(
      propName as string,
    ),
})<StyledTableCellProps>`
  font-weight: 600;
  padding: 0.25rem 1rem;
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "left")};
  min-width: ${({ minWidth }) => minWidth};
  width: ${({ width }) => width};
  border-top: ${({ withBorder, theme }) =>
    withBorder && `1px solid ${theme.palette.divider}`};
  position: ${({ sticky }) => (sticky ? "sticky" : "")};
  left: ${({ sticky }) => (sticky ? "0" : "")};
  background-color: ${({ sticky }) => (sticky ? "white" : "")};
  z-index: ${({ sticky }) => (sticky ? "100" : "")};
`;

export const TableRowSummaryStyled = styled(TableRow)`
  background-color: ${({ theme }) => theme.colors.solidLightPurple};
`;

export const CellWrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

export const ProductInputWrapper = styled("div")`
  display: grid;
  gap: 0.25rem;
  width: 100%;
`;

export const AutocompleteWrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

export const StyledFormTextField = styled(TextField)`
  width: 6.25rem;

  & .MuiInputBase-input.MuiInput-input {
    padding: 0.875rem 0.25rem 0.7rem 0.75rem;
  }
`;

export const ServingsFormTextField = styled(FormTextField)`
  justify-self: start;
  width: 7rem;
`;

export const MeasureTextField = styled(TextField)`
  width: 5rem;

  & .MuiInputBase-input.MuiInput-input {
    padding: 0.875rem 0.25rem 0.7rem 0.75rem;
  }
`;

export const TableCellTitle = styled(TableCell, {
  shouldForwardProp: propName => propName !== "withBorder",
})<StyledTableCellProps>`
  font-weight: 600;
  padding-left: 0.375rem;
  padding-right: 0.375rem;
  border-top: ${({ withBorder, theme }) =>
    withBorder && `1px solid ${theme.palette.divider}`};
`;

interface MicrosTableCellProps {
  fontBold: boolean;
  skipBorderBottom: boolean;
}
export const MicrosTableCell = styled(TableCell, {
  shouldForwardProp: propName =>
    !["fontBold", "skipBorderBottom"].includes(propName as string),
})<MicrosTableCellProps>`
  font-size: 0.875rem;
  min-width: 5.7rem;
  padding-left: 0.375rem;
  padding-right: 0.375rem;
  font-weight: ${({ fontBold }) => (fontBold ? "bold" : "normal")};
  border-bottom: ${({ skipBorderBottom }) => skipBorderBottom && "none"};
`;

export const TableRowStyled = styled(TableRow)`
  & .MuiTableCell-root {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }
`;

export const MeasureSuffixStyled = styled("span")`
  color: ${({ theme }) => theme.palette.text.disabled};
  padding-left: 0.25rem;
`;

export const TitleWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 0 0 1.25rem;
`;

export const StyledTableFooter = styled("tfoot")`
  position: sticky;
  bottom: 0;
  background: white;
  z-index: 100;
`;

export const StyledTableHeader = styled(TableHead)`
  position: relative;
`;

export const PlaceholderCell = styled(TableCell, {
  shouldForwardProp: propName => propName !== "skipBorderBottom",
})<Omit<MicrosTableCellProps, "fontBold">>`
  border-bottom: ${({ skipBorderBottom }) => skipBorderBottom && "none"};
`;

export const EmptyTablePlaceholder = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 17.25rem;
`;

export const StyledDivider = styled(Divider)`
  max-width: 20rem;
  width: 100%;
  justify-self: center;
`;

interface StyledChartAutocompleteProps
  extends AutocompleteProps<SelectOption, false, true, false> {
  setWidth?: boolean;
}

type AutocompleteChangeHandler = (
  event: React.SyntheticEvent,
  value: SelectOption | null,
  reason: AutocompleteChangeReason,
  details?: AutocompleteChangeDetails<SelectOption> | undefined,
) => void;

export const StyledChartAutocomplete = styled(Autocomplete, {
  shouldForwardProp: propName => propName !== "setWidth",
})<StyledChartAutocompleteProps>`
  width: ${({ setWidth }) => (setWidth ? "10.375rem" : "auto")};
` as React.ComponentType<
  StyledChartAutocompleteProps & { onChange: AutocompleteChangeHandler }
>;

interface ColumnDirectionProps {
  directionColumn?: boolean;
}

export const NutrientsWrapper = styled("div", {
  shouldForwardProp: propName => propName !== "directionColumn",
})<ColumnDirectionProps>`
  display: flex;
  gap: 1rem;
  flex-direction: ${({ directionColumn }) =>
    directionColumn ? "column" : "row"};
`;
