import { Card, Typography, styled } from "@mui/material";
import { TABLET_MAX_CONTENT_WIDTH, LAPTOP_MAX_CONTENT_WIDTH } from "@utils";

export const CalendarHeader = styled(Typography)`
  font-size: 1.25rem;
  font-weight: 600;
  width: 11rem;
  text-align: center;
`;

export const BoldTypography = styled(Typography)`
  font-weight: 600;
`;

export const StyledTH = styled("th")`
  border: 1px solid ${({ theme }) => theme.colors.borderGray};
  padding: 0.5rem 0;
`;

export const StyledTD = styled("td")`
  border: 1px solid ${({ theme }) => theme.colors.borderGray};
  padding: 5px;
`;

interface DayTile {
  background?: string;
  selected?: boolean;
  pointer?: boolean;
}

export const DayTile = styled("div")<DayTile>`
  display: grid;
  background-color: ${({ background }) => background};
  border-radius: 0.25rem;
  padding: 0.375rem;
  outline: ${({ selected, theme }) =>
    selected && `1px solid ${theme.palette.primary.main}`};
  cursor: ${({ pointer }) => pointer && "pointer"};

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallMax}px) {
    width: 4.45rem;
    height: 4.45rem;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.medium}px) and (max-width: ${({ theme }) =>
      theme.breakpoints.values.desktopMax}px) {
    width: 5.5rem;
    height: 5.5rem;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.largeDesktop}px) {
    width: 6rem;
    height: 6rem;
  }
`;

interface PercentageBarProps {
  width: string;
  background: string;
  fullRadius?: boolean;
}

export const PercentageBarLeft = styled("div")<PercentageBarProps>`
  height: 0.25rem;
  width: ${({ width }) => width};
  background: ${({ background }) => background};
  border-radius: ${({ fullRadius }) => (fullRadius ? "5px" : "5px 0 0 5px")};
`;

export const PercentageBarRight = styled("div")<PercentageBarProps>`
  height: 3px;
  width: ${({ width }) => width};
  background: ${({ background }) => background};
  border-radius: 0 5px 5px 0;
`;

export const PercentageBarWrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: 1px;
  width: 100%;
  border-radius: 5px;
`;

interface DayNumberWrapperProps {
  marked?: boolean;
}

export const DayNumberWrapper = styled("div")<DayNumberWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.0625rem 0;
  border-radius: 100%;
  background-color: ${({ marked, theme }) =>
    marked && theme.palette.primary.dark};
  color: ${({ marked, theme }) =>
    marked && theme.palette.secondary.contrastText};
`;

export const LegendDot = styled("div")<DayTile>`
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 100%;
  background-color: ${({ background }) => background};
`;

export const LegendCategory = styled(Typography)`
  width: 8.68rem;
`;

export const CalendarWrapper = styled("div")`
  display: flex;
  gap: 0.75rem;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallMax}px) {
    flex-direction: column;
    max-width: 40.125rem;
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.medium}px) and (max-width: ${({ theme }) =>
      theme.breakpoints.values.laptopMax}px) {
    max-width: ${LAPTOP_MAX_CONTENT_WIDTH};
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.desktop}px) and (max-width: ${({ theme }) =>
      theme.breakpoints.values.desktopMax}px) {
    height: 55.375rem;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.largeDesktop}px) {
    height: 51.875rem;
  }
`;

export const DayTileTypography = styled(Typography)`
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallMax}px) {
    font-size: 0.75rem;
  }
`;

export const DayNoteStyled = styled("div")`
  display: flex;
  flex-direction: column;
  height: 12.5rem;
  overflow: auto;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallMax}px) {
    width: 38.125rem;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.medium}px) and (max-width: ${({ theme }) =>
      theme.breakpoints.values.laptopMax}px) {
    max-width: 20rem;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.desktop}px) and (max-width: ${({ theme }) =>
      theme.breakpoints.values.desktopMax}px) {
    max-width: 16.75rem;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.largeDesktop}px) {
    max-width: 20rem;
  }
`;

export const DayTypeSectionWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const MonitoringDayRow = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledDayDetailsCard = styled(Card)`
  display: flex;
  padding: 1rem;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallMax}px) {
    width: ${TABLET_MAX_CONTENT_WIDTH};
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.largeDesktop}px) {
    flex: 1;
  }
`;

export const CalendarTableWrapper = styled(Card)`
  padding: 1rem;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallMax}px) {
    width: ${TABLET_MAX_CONTENT_WIDTH};
  }
`;
