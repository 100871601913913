import { useQuery } from "@tanstack/react-query";

import { QueryOptionsTyped } from "../types";
import { buildDefaultQueryOptions } from "../common";
import { ApiResponse } from "@typeDefinitions";
import { FetchClinicNameResponse, fetchClinicName } from "@client/settings";

export const fetchClinicNameQueryKey = "fetchClinicNameQueryKey";

export const useFetchClinicNameQuery = (
  options?: QueryOptionsTyped<ApiResponse<FetchClinicNameResponse>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchClinicNameQueryKey],
    fetchClinicName,
    buildDefaultQueryOptions(options),
  );

  return {
    name: data?.data,
    ...rest,
  };
};
