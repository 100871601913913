import { Typography } from "@mui/material";

import { useAppTranslation } from "@hooks";

import {
  ColumnsStyled,
  MeasureElement,
  TypographyStyled,
} from "./MeasuresDetails.styled";

interface MeasuresDetailsProps {
  measures: MeasureLabel[];
}

export const MeasuresDetails = ({ measures }: MeasuresDetailsProps) => {
  const { t } = useAppTranslation();

  return (
    <>
      <Typography variant="h6">{t("common.home_measurement")}</Typography>
      <ColumnsStyled>
        {measures.map(({ name, value }, index) => (
          <MeasureElement key={index}>
            <Typography variant="body2">{name}</Typography>
            <TypographyStyled variant="subtitle2">{value} g</TypographyStyled>
          </MeasureElement>
        ))}
      </ColumnsStyled>
    </>
  );
};

interface MeasureLabel {
  name: string;
  value: number;
}
