import { fetchFoodNutrientsNew } from "@client/food";
import { useQuery } from "@tanstack/react-query";
import { QueryOptionsTyped } from "../types";
import { ApiResponse } from "@typeDefinitions";
import { NutrientDto } from "@client";

export const fetchFoodNutrientsNewQueryKey = "fetchFoodNutrientsNewQueryKey";

export const useFetchFoodNutrientsNewQuery = (
  id: string,
  options?: QueryOptionsTyped<ApiResponse<NutrientDto[]>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchFoodNutrientsNewQueryKey, id],
    () => fetchFoodNutrientsNew(id),
    options,
  );

  return {
    nutrients: data?.data,
    ...rest,
  };
};
