import { DietSearchResourceDto } from "@client/diets";

import { DietRowIf } from "./DietsDataGrid";

export const mapDietRows = (
  diets: DietSearchResourceDto[] | undefined,
  userId: number,
): DietRowIf[] => {
  if (!diets) return [];

  return diets.map(
    ({
      id,
      favorite,
      actions,
      macros,
      tags,
      namePl,
      nameEn,
      mealsCount,
      image,
    }) => {
      return {
        id,
        namePl,
        nameEn: nameEn ?? namePl,
        tags: tags.map(t => t.id.toString()),
        nutrients: macros,
        isFavorite: favorite.includes(userId),
        actions,
        mealsCount,
        imgUrl: image?.url ?? undefined,
      };
    },
  );
};
