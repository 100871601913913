import { styled } from "@mui/material";
import { LARGE_DESKTOP_MAX_CONTENT_WIDTH } from "@utils";

export const ProgramsViewWrapper = styled("div")`
  margin: 0 auto;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.laptop}px) {
    max-width: ${LARGE_DESKTOP_MAX_CONTENT_WIDTH};
  }
`;
