import { useAppParams } from "@hooks";
import { ReactNode, createContext, useState } from "react";

interface ValueDietProps {
  [programId: string]: {
    warningBorder: boolean;
  };
}
interface ProgramEmptyDietProps {
  setWarningBorder: () => void;
  hideWarningBorder: () => void;
  programInfo: {
    warningBorder: boolean;
  };
}
const defaultValue = { warningBorder: false };

export const ProgramEmptyDietContext = createContext<ProgramEmptyDietProps>({
  setWarningBorder: () => {
    null;
  },
  hideWarningBorder: () => {
    null;
  },
  programInfo: defaultValue,
});

interface ProgramEmptyDietProviderProps {
  children: ReactNode;
}

export const ProgramEmptyDietProvider = ({
  children,
}: ProgramEmptyDietProviderProps) => {
  const { programId } = useAppParams();

  const [value, setValue] = useState<ValueDietProps>({});

  const setWarningBorder = () =>
    setValue(value => ({ ...value, [programId]: { warningBorder: true } }));

  const hideWarningBorder = () =>
    setValue(value => ({ ...value, [programId]: { warningBorder: false } }));

  const contextValue = {
    setWarningBorder,
    hideWarningBorder,
    programInfo: value?.[programId] ?? defaultValue,
  };

  return (
    <ProgramEmptyDietContext.Provider value={contextValue}>
      {children}
    </ProgramEmptyDietContext.Provider>
  );
};
