import { memo } from "react";
import { useTranslation } from "react-i18next";

import { ArrowLeft } from "@assets/icons";
import { ActionButton } from "@components/ListModules";

export const ProgramInfoNavigation = memo(
  (props: ProgramInfoNavigationProps) => {
    const {
      onBack,
      onSubmit,
      isSubmitting,
      submitButtonText = "common.proceed",
    } = props;
    const { t } = useTranslation();

    return (
      <div className="flex gap-12 items-center justify-center mb-10">
        <ActionButton
          lowHeight
          variant="contained"
          color="inherit"
          size="large"
          className="mr-6"
          onClick={onBack}
          disabled={isSubmitting}
          style={{ width: 200 }}
          startIcon={<ArrowLeft size="w-4 h-4" className="stroke-current" />}
        >
          {t("common.back")}
        </ActionButton>
        <ActionButton
          lowHeight
          variant="contained"
          size="large"
          className="ml-6"
          loading={isSubmitting}
          style={{ width: 200 }}
          onClick={onSubmit}
          endIcon={
            <ArrowLeft
              size="w-4 h-4"
              className="transform rotate-180 stroke-current"
            />
          }
        >
          {t(submitButtonText)}
        </ActionButton>
      </div>
    );
  },
);

interface ProgramInfoNavigationProps {
  onBack: () => void;
  onSubmit: () => void;
  isSubmitting?: boolean;
  submitButtonText?: string;
}
