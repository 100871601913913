import { Dialog, MenuItem } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  CancelButton,
  CloseButton,
  EditOfficeModalWrapper,
  StyledDialogActions,
  Title,
  TitleWrapper,
} from "./EditClientOfficeModal.styled";
import {
  UpdateClientFormInput,
  mapUpdateClientRequest,
  useAppTranslation,
  useClientParams,
  useMapClientAutocompleteOptions,
  useUpdateClientForm,
} from "@hooks";
import { Close } from "@assets/icons";
import { useCallback } from "react";
import {
  useFetchClientQuery,
  useFetchClientSourcesQuery,
  useFetchSchedulesDictionariesQuery,
  useUpdateClientMutation,
} from "@hooks/queries";
import { LoadingButton } from "@mui/lab";
import { FormSelect } from "@components/FormSelectNew";
import { FormAutocomplete, SelectOption } from "@components/FormAutocomplete";
import { ClientProfileCardItem } from "@views/dietician/ClientProfile/components/ClientProfileCardItem";

interface EditClientOfficeModalProps {
  open: boolean;
  onClose: () => void;
}

export const EditClientOfficeModal = ({
  open,
  onClose,
}: EditClientOfficeModalProps) => {
  const { t } = useAppTranslation();
  const { sources } = useFetchClientSourcesQuery();
  const id = useClientParams();
  const { client } = useFetchClientQuery(id);
  const { control, handleSubmit, watch, reset, setValue, getFieldState } =
    useUpdateClientForm(client);
  const sex = watch("sex");
  const { mapOptions } = useMapClientAutocompleteOptions(sex);
  const { schedulesDictionaries } = useFetchSchedulesDictionariesQuery();
  const { mutate, isLoading } = useUpdateClientMutation(id);

  const onCloseWithReset = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  const dietitiansMapped =
    schedulesDictionaries?.dietitians.map(dietitian => ({
      id: dietitian.id.toString(),
      label: `${dietitian.firstName} ${dietitian.lastName}`,
    })) ?? [];

  const facilitiesMapped =
    schedulesDictionaries?.facilities.map(clinic => ({
      id: clinic.id.toString(),
      label: clinic.city,
    })) ?? [];

  const submit = useCallback(
    (data: UpdateClientFormInput) => {
      mutate(mapUpdateClientRequest(data), { onSuccess: onCloseWithReset });
    },
    [mutate, mapUpdateClientRequest, onCloseWithReset],
  );
  return (
    <Dialog open={open} disableEscapeKeyDown>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <EditOfficeModalWrapper>
          <TitleWrapper>
            <Title>{t("client_profile.office.office")}</Title>
            <CloseButton size="small" onClick={onCloseWithReset}>
              <Close size="w-3 h-3" />
            </CloseButton>
          </TitleWrapper>
          <div className="grid gap-6">
            <ClientProfileCardItem
              title={t("visits.add.dietitian")}
              subtitle={
                <FormAutocomplete
                  control={control}
                  name="dietitian"
                  id="dietitian"
                  key={`dietitian-${watch("dietitian")}`}
                  size="small"
                  className="w-full"
                  options={dietitiansMapped}
                />
              }
            />
            <ClientProfileCardItem
              title={t("client_profile.source")}
              subtitle={
                <FormSelect
                  fullWidth
                  control={control}
                  name="source"
                  id="source"
                  size="small"
                >
                  {mapOptions(sources).map(param => (
                    <MenuItem value={param.id} key={param.label}>
                      {param.label}
                    </MenuItem>
                  ))}
                </FormSelect>
              }
            />
            <ClientProfileCardItem
              title={t("visits.add.location")}
              subtitle={
                <FormAutocomplete
                  control={control}
                  name="facility"
                  id="facility"
                  key={`facility-${watch("facility")}`}
                  size="small"
                  className="w-full"
                  options={facilitiesMapped}
                />
              }
            />
          </div>
        </EditOfficeModalWrapper>
        <StyledDialogActions>
          <CancelButton variant="outlined" onClick={onCloseWithReset}>
            {t("common.cancel")}
          </CancelButton>
          <LoadingButton
            variant="contained"
            loading={isLoading}
            onClick={handleSubmit(submit, e => console.log("e", e))}
          >
            {t("common.save")}
          </LoadingButton>
        </StyledDialogActions>
      </LocalizationProvider>
    </Dialog>
  );
};
