import { memo, useCallback } from "react";
import { Path, useController } from "react-hook-form";

import { useAppTranslation } from "@hooks";
import { ProgramFormInput } from "@hooks/useProgramForm";
import { Poland, UK } from "@assets/icons/Program";
import { Labeled } from "@components/Labeled";
import { Checkbox } from "@components/Checkbox";

interface ILanguageSelectProps<T extends ProgramFormInput> {
  name: Path<T>;
}

export const LanguageSelect = memo(
  <T extends ProgramFormInput>({ name }: ILanguageSelectProps<T>) => {
    const { t } = useAppTranslation();
    const {
      field: { value, onChange },
    } = useController<ProgramFormInput>({ name: name });
    const handlePlLang = useCallback(() => onChange("pl"), [onChange]);
    const handleEnLang = useCallback(() => onChange("en"), [onChange]);

    return (
      <Labeled
        className="gap-5"
        label={`${t("program.info.choose_program_language")}`}
        tooltip={<p>{t("program.info.language_program_tooltip")}</p>}
      >
        <div className={"flex flex-wrap gap-7"}>
          <div className="w-96">
            <Checkbox
              fullWidth
              withBackground
              value={value === "pl"}
              onChange={handlePlLang}
              label={t("program.info.pl_language")}
              iconEnd={<Poland size="w-10 h-10" className="fill-white" />}
            />
          </div>
          <div className="w-96 relative">
            <Checkbox
              fullWidth
              withBackground
              value={value === "en"}
              onChange={handleEnLang}
              label={t("program.info.en_language")}
              iconEnd={<UK size="w-10 h-10" />}
            />
          </div>
        </div>
      </Labeled>
    );
  },
);
