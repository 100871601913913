import { useMemo } from "react";

import {
  GridCallbackDetails,
  GridColumns,
  GridValidRowModel,
} from "@mui/x-data-grid";

import {
  GridPaginationWrapper,
  ITEMS_ON_PAGE,
} from "@components/PaginationNew";
import { ListTabs, useAppTranslation } from "@hooks";
import { Actions, Nutrient } from "@typeDefinitions";

import { useMediaQuery, useTheme } from "@mui/material";
import { FiltersNoResults } from "@views/emptyStates/FiltersNoResults";
import { useFormContext } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { ActionsColumnCell } from "./ActionsColumnCell";
import { CustomHeader } from "./CustomHeader/CustomHeader";
import { CustomRow } from "./CustomRow";
import { NameColumnCell } from "./NameColumnCell";
import {
  EmptyStateCardForGrid,
  StyledMuiDataGrid,
} from "./DietsDataGrid.styled";
import { SingleColumnCell } from "./SingleColumnCell/SingleColumnCell";
import { TagsColumnCell } from "./TagsColumnCell";
import { GRID_ROW_BIG, GRID_ROW_SMALL } from "@utils";
import { ProgramsFiltersFormInput } from "@components/ProgramsFilters";
import { defaultFilters } from "@components/ProgramsFilters/programsFiltersUtils";

export interface DietRowIf extends GridValidRowModel {
  id: number;
  namePl: string;
  nameEn: string;
  tags: string[];
  nutrients: Nutrient[];
  isFavorite: boolean;
  actions: Actions;
  mealsCount: number;
  imgUrl?: string;
}

interface DietsDataGridProps {
  rows: DietRowIf[];
  onPageChange?: (page: number, details: GridCallbackDetails<any>) => void;
  page: number;
  perPage: number;
  setPerPage: (value: number) => void;
  isOnePage: boolean;
  rowCount?: number;
  loading?: boolean;
  onSelectRow: (id: number | null) => void;
  resetFilters: () => void;
}
export const DietsDataGrid = ({
  rows,
  onPageChange,
  page,
  perPage,
  setPerPage,
  isOnePage,
  rowCount,
  loading,
  onSelectRow,
}: DietsDataGridProps) => {
  const { t, isPolishChosen } = useAppTranslation();
  const { breakpoints } = useTheme();
  const downMedium = useMediaQuery(`${breakpoints.down("medium")}`);
  const { reset, setValue } = useFormContext<ProgramsFiltersFormInput>();
  const [_, setSearchParams] = useSearchParams();

  const handleResetFilters = () => {
    reset(
      {
        tabs: ListTabs.ALL,
        ...defaultFilters,
      },
      { keepDirty: true },
    );
    setValue("page", undefined, { shouldDirty: true });

    setSearchParams(prev => {
      const params = new URLSearchParams();

      const perPage = prev.get("perPage");

      if (perPage) params.set("perPage", perPage);

      return params;
    });
  };

  const columns = useMemo<GridColumns<DietRowIf>>(() => {
    return [
      {
        field: "name",
        headerName: t("diets.diet_name"),
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
        renderCell: ({
          row: { id, namePl, nameEn, nutrients, mealsCount },
        }) => (
          <NameColumnCell
            name={isPolishChosen ? namePl : nameEn}
            nutrients={nutrients}
            onSelect={() => onSelectRow(id)}
            mealsCount={mealsCount}
          />
        ),
      },
      {
        field: "tags",
        headerName: t("common.tags"),
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
        renderCell: ({ row: { tags } }) => <TagsColumnCell tags={tags} />,
      },
      {
        field: "actions",
        headerName: t("programs.actions"),
        headerAlign: "right",
        disableColumnMenu: true,
        sortable: false,
        width: 200,
        renderCell: ({
          row: { id, isFavorite: isFavorite, actions, namePl, nameEn },
        }) => (
          <ActionsColumnCell
            id={id}
            isFavorite={isFavorite}
            actions={actions}
            namePl={namePl}
            nameEn={nameEn}
          />
        ),
      },
    ];
  }, [t]);

  const singleColumn = useMemo<GridColumns<DietRowIf>>(() => {
    return [
      {
        field: "combinedColumn",
        renderHeader: ({ colDef }) => <CustomHeader {...colDef} />,
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <SingleColumnCell {...row} onSelect={() => onSelectRow(row.id)} />
        ),
      },
    ];
  }, [t]);

  return (
    <StyledMuiDataGrid
      columns={downMedium ? singleColumn : columns}
      rows={rows}
      page={page}
      onPageChange={onPageChange}
      rowCount={rowCount}
      pageSize={perPage}
      disableSelectionOnClick
      getRowSpacing={() => ({
        top: 8,
      })}
      autoHeight
      rowHeight={downMedium ? GRID_ROW_BIG : GRID_ROW_SMALL}
      components={{
        Pagination: GridPaginationWrapper,
        Row: CustomRow,
        NoRowsOverlay: () => (
          <EmptyStateCardForGrid className="emptyState">
            <FiltersNoResults onClick={handleResetFilters} />
          </EmptyStateCardForGrid>
        ),
      }}
      componentsProps={{
        pagination: {
          setPerPage,
          perPage,
          isOnePage,
          rowsPerPageOptions: [ITEMS_ON_PAGE.TEN, ITEMS_ON_PAGE.TWENTY_FIVE],
        },
      }}
      paginationMode="server"
      loading={loading}
    />
  );
};
