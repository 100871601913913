import React, { useMemo, useState } from "react";

import { Nutrient } from "@typeDefinitions";
import { useAppTranslation, useBasicNutrientsGetter } from "@hooks";

import { PCFValue } from "../../../../../PCFValue";
import { RecipeProductRowWrapper } from "../RecipeProductRowWrapper.styled";
import { RecipeNameWrapper } from "../RecipeNameWrapper.styled";
import { NutrientWrapper } from "../NutrientWrapper.styled";
import { FoodMeasureEditor } from "./components/FoodMeasureEditor";
import { RecipeUsesWrapper } from "../RecipeUsesWrapper.styled";
import { SimplifiedRecipeOrProductData } from "../../RecipeChangeSidePanel";

export const FoodModalEntry = ({
  food,
  onSelect,
  optionsInModal,
  uses,
}: FoodModalEntryProps) => {
  const { t, isPolishChosen } = useAppTranslation();

  const { id, measures, config, nutrients, description, descriptionEn } = food;

  const [grams, setGrams] = useState(config.grams);
  const [measureId, setMeasure] = useState(config.foodMeasure.id);

  const name = useMemo(
    () => (isPolishChosen ? description : descriptionEn ?? description),
    [isPolishChosen],
  );
  const modifier = useMemo(() => grams / config.grams, [grams]);

  const { kcal, protein, fat, carbs } = useBasicNutrientsGetter(
    nutrients,
    modifier,
  );

  const handleSelect = () => {
    onSelect({ id, name, grams, foodMeasureId: measureId });
  };

  return (
    <RecipeProductRowWrapper>
      <div className="w-full">
        <RecipeNameWrapper hover onClick={handleSelect}>
          {name || t("patient.schedule.unnamed_food")}
          {!!uses && uses > 0 && (
            <RecipeUsesWrapper>
              {`${t("common.used")}: ${uses}`}
            </RecipeUsesWrapper>
          )}
        </RecipeNameWrapper>
        <div className="flex text-sm">
          <NutrientWrapper>
            <PCFValue value={kcal} name="kcal" />
          </NutrientWrapper>
          <NutrientWrapper>
            <PCFValue value={protein} name="protein" />
          </NutrientWrapper>
          <NutrientWrapper>
            <PCFValue value={fat} name="fats" />
          </NutrientWrapper>
          <NutrientWrapper>
            <PCFValue value={carbs} name="carbs" />
          </NutrientWrapper>
        </div>
      </div>
      {!optionsInModal && (
        <FoodMeasureEditor
          measures={measures}
          grams={grams}
          measureId={measureId}
          setMeasure={setMeasure}
          setGrams={setGrams}
          className="w-1/2"
        />
      )}
    </RecipeProductRowWrapper>
  );
};

export interface FoodModalEntryProps {
  onSelect: (product: SimplifiedRecipeOrProductData) => void;
  optionsInModal?: boolean;
  uses?: number;
  food: {
    id: number;
    description: string;
    descriptionEn?: string;
    measures: FoodMeasure[];
    config: FoodConfig;
    nutrients: Nutrient[];
  };
}

interface FoodMeasure {
  id: number;
  description: string;
  descriptionEn: string;
  sortOrder: number;
  grams: number;
}

interface FoodConfig {
  grams: number;
  foodMeasure: {
    id: number;
  };
}
