import { Fragment, MouseEventHandler, useMemo } from "react";

import { useMediaQuery, useTheme } from "@mui/material";
import { chunk, range } from "lodash";

import { Plus } from "@assets/icons";
import { MACROS_ARRAY } from "@utils/macros";

import { Chart } from "../Chart";
import { NutrientWithTarget } from "./MacrosTabPanel";
import { ButtonStyled, DividerStyled } from "./TabPanels.styled";
import { t } from "i18next";
import { useAppParams } from "@hooks";

interface MacrosTabPanelProps {
  nutrients: Map<number, NutrientWithTarget>;
  onClickSelect: MouseEventHandler<HTMLButtonElement>;
}
export const MicrosTabPanel = ({
  nutrients,
  onClickSelect,
}: MacrosTabPanelProps) => {
  const { patientId } = useAppParams();
  const { breakpoints } = useTheme();
  const upMedium = useMediaQuery(`${breakpoints.up("medium")}`);

  const micros = Array.from(nutrients)
    .filter(([id]) => !MACROS_ARRAY.includes(id))
    .map(([_, values]) => values)
    .filter(({ visible }) => visible);
  const columsNumber = upMedium ? 4 : 2;
  const nutrientsArray = useMemo(
    () => chunk(micros, columsNumber),
    [micros, columsNumber],
  );
  const arrayLength = Math.max(nutrientsArray.length - 1, 0);
  const placeForButton = nutrientsArray[arrayLength]?.length < columsNumber;
  const showButton = !patientId;

  const addButton = (
    <ButtonStyled variant="outlined" onClick={onClickSelect}>
      <Plus />
      {t("diet.select_to_track")}
    </ButtonStyled>
  );
  return (
    <div className="grid gap-[24px]">
      {nutrientsArray.map((group, gIdx) => (
        <div className="flex gap-[40px]" key={gIdx}>
          {group.map((m, mIdx) => (
            <Fragment key={mIdx}>
              {mIdx !== 0 && <DividerStyled />}
              <Chart key={m.id} {...m} />
            </Fragment>
          ))}
          {showButton && placeForButton && gIdx === arrayLength && (
            <>
              <DividerStyled />
              {addButton}
            </>
          )}
        </div>
      ))}

      {showButton && !placeForButton && (
        <div className="flex gap-[40px]">
          {range(columsNumber - 1).map(c => (
            <div className="flex-1" key={c} />
          ))}
          {range(columsNumber - 1).map(c => (
            <div key={c} />
          ))}
          {addButton}
        </div>
      )}
    </div>
  );
};
