import { useAppTranslation } from "@hooks";
import { Typography, useTheme } from "@mui/material";
import {
  LegendItemLine,
  LegendItemStyled,
  MonitoringChartLegendStyled,
} from "./MonitoringChartSection.styled";

export const MonitoringChartLegend = () => {
  const { t } = useAppTranslation();
  const { palette, colors } = useTheme();

  return (
    <MonitoringChartLegendStyled>
      <Typography variant="caption">{t("common.legend")}:</Typography>
      <div className="flex gap-4">
        <LegendItemStyled>
          <LegendItemLine background={palette.primary.main} />
          <Typography variant="caption">{t("monitoring.completed")}</Typography>
        </LegendItemStyled>
        <LegendItemStyled>
          <LegendItemLine background={colors.chartLightPurple} />
          <Typography variant="caption">{t("monitoring.target")}</Typography>
        </LegendItemStyled>
      </div>
    </MonitoringChartLegendStyled>
  );
};
