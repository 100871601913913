import { Control, useController } from "react-hook-form";

import { ChipsSelect } from "@components/ChipsSelect";
import { OuterLabel } from "@components/OuterLabel";
import { useAppTranslation } from "@hooks";

import { DietNameInputs, langOptions } from "./useDietNameForm";

interface LangChipsSelectProps {
  control: Control<DietNameInputs>;
}
export const LangChipsSelect = ({ control }: LangChipsSelectProps) => {
  const { t } = useAppTranslation();
  const {
    field: { value, onChange },
  } = useController<DietNameInputs, "lang">({ control, name: "lang" });

  const handleChangeLang = (langId: number) => {
    onChange(
      value.includes(langId)
        ? value.length
          ? value.filter(id => id !== langId)
          : value
        : [...value, langId],
    );
  };

  return (
    <OuterLabel label={t("diets.add_modal.select_lang")}>
      <ChipsSelect
        options={langOptions}
        selectedId={value}
        onChange={handleChangeLang}
        size="medium"
      />
    </OuterLabel>
  );
};
