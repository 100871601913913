import { useAppTranslation } from "@hooks";
import {
  ConfirmSignUpViewWrapper,
  ContentText,
  SignUpViewTitle,
  TopSignUpViewSection,
} from "./ConfirmRecoveryView.styled";
import img from "@assets/img/GirlWithLetter.png";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { LOGIN } from "@routes";

export const ConfirmRecoveryView = () => {
  const { t } = useAppTranslation();

  return (
    <ConfirmSignUpViewWrapper>
      <img src={img} width={236} height={210} className="mx-auto" />
      <TopSignUpViewSection>
        <SignUpViewTitle>{t("auth.recovery.confirm.title")}</SignUpViewTitle>
        <ContentText>{t("auth.recovery.confirm.content")}</ContentText>
      </TopSignUpViewSection>
      <Link to={LOGIN}>
        <Button color="primary">{t("auth.recovery.back_to_log_in")}</Button>
      </Link>
    </ConfirmSignUpViewWrapper>
  );
};
