import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const V = (props: SvgIconProps) => {
  return (
    <SvgIcon width="10" height="13" viewBox="0 0 10 13">
      <path
        d="M0.500263 0.875983C0.91413 0.703327 1.38893 0.898835 1.56159 1.3127L4.87507 9.26251L8.18854 1.3127C8.3612 0.898835 8.83601 0.703327 9.24987 0.875983C9.66374 1.04864 9.85925 1.52344 9.68659 1.93731L5.62409 11.6873C5.49714 11.9895 5.20261 12.1875 4.87507 12.1875C4.54753 12.1875 4.25046 11.9895 4.12604 11.6873L0.0635442 1.93731C-0.109112 1.52344 0.0863958 1.04864 0.500263 0.875983Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
