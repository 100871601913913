import { useEffect } from "react";
import { useForm } from "react-hook-form";

import * as yup from "yup";

import { useAppTranslation } from "@hooks/useAppTranslation";
import { DetailedSection } from "@typeDefinitions";
import { SectionFormInput } from "@typeDefinitions/types";
import { yupResolver } from "@hookform/resolvers/yup";

export function useSectionForm(defaultSection?: DetailedSection) {
  const { t } = useAppTranslation();
  const form = useForm<SectionFormInput>({
    defaultValues: {
      language: "PL",
    },
    resolver: yupResolver(
      yup.object().shape({
        namePl: yup.string().required(t("sections.name_required")),
        nameEn: yup.string(),
        contentPl: yup.string().required(t("sections.content_required")),
        contentEn: yup.string(),
        reviewed: yup.boolean(),
        sectionCategoryId: yup
          .number()
          .required(t("sections.category_required"))
          .nullable()
          .positive(),
      }),
    ),
  });

  useEffect(() => {
    if (defaultSection) {
      form.reset({
        namePl: defaultSection.namePl,
        nameEn: defaultSection.nameEn ?? "",
        language: "PL",
        contentPl: defaultSection.contentPl ?? "",
        contentEn: defaultSection.contentEn ?? "",
        categories: defaultSection.categories[0],
        categoryName: defaultSection.categoryName,
        reviewed: defaultSection.reviewed,
        sectionCategoryId: defaultSection.categories[0]?.toString(),
      });
    }
  }, [defaultSection]);

  return form;
}
