import styled from "@emotion/styled";

export const CardContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.whiteBackground};
  border-radius: 5px;
  box-shadow: 0 2px 10px #0000000f;
`;

//bg-white font-roman flex flex-col
// rounded-md
// shadow-paper
// sh
