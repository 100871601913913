import { LangDto } from "@client";
import {
  FetchCreatorDietResponseDto,
  fetchCreatorDiet,
} from "@client/diets/creator";
import { QueryOptionsTyped } from "@hooks/queries/types";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export const fetchCreatorDietQueryKey = "fetchCreatorDietQueryKey";

export const useFetchCreatorDietQuery = (
  dietId: string,
  options?: QueryOptionsTyped<FetchCreatorDietResponseDto>,
) => {
  const { data, ...rest } = useQuery(
    [fetchCreatorDietQueryKey, dietId],
    () => fetchCreatorDiet(dietId),
    options,
  );

  return {
    diet: data?.data,
    headers: data?.headers,
    ...rest,
  };
};

export const useCreatorDietLanguageVersion = (dietId: string) => {
  const queryClient = useQueryClient();
  const dietData = queryClient.getQueryData<FetchCreatorDietResponseDto>([
    fetchCreatorDietQueryKey,
    dietId,
  ])?.data;
  const translations = dietData?.translations;
  const languages =
    translations
      ?.map(({ name, langId }) => !!name && langId)
      .filter((langId): langId is LangDto => !!langId) ?? [];

  return {
    languages,
  };
};
