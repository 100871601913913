import { Card, LinearProgress, styled } from "@mui/material";

export const CardStyled = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  border-radius: 8px;
  padding: 64px 64px 16px;
  box-shadow: ${({ theme }) => theme.boxShadows.card};
`;

export const Text = styled("span")`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;

export const LinearProgressStyled = styled(LinearProgress)`
  width: 184px;
  &.MuiLinearProgress-root {
    height: 12px;
    border-radius: 12px;
    background: ${({ theme }) => theme.palette.primary.medium};
  }
  & .MuiLinearProgress-bar {
    border-radius: 12px;

    &.MuiLinearProgress-barColorWarning {
      background: ${({ theme }) => theme.colors.survey.yellow.dark};
    }
  }
`;
