import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { logFetchValidateError } from "@utils/validate";
import * as yup from "yup";
import {
  PatientHydrationProductResource,
  patientHydrationProductSchema,
} from "@client/resources/PatientHydrationProductResource";

export type FetchPatientHydrationProductParams = {
  patientId: number;
};

export type FetchPatientHydrationProductResponse =
  ApiResponse<PatientHydrationProductResource>;

export const fetchPatientHydrationProducts = async (
  params: FetchPatientHydrationProductParams,
): Promise<FetchPatientHydrationProductResponse> => {
  const response = await fetchData(
    "/dietitian/patients/{patient_id}/hydration-product".replace(
      "{patient_id}",
      params.patientId.toString(),
    ),
    APIMethods.GET,
  );

  return await schema
    .validate(response)
    .catch(
      logFetchValidateError(
        "/dietitian/patients/{patient_id}/hydration-product",
      ),
    );
};

const schema = yup.object({
  data: patientHydrationProductSchema.defined(),
});
