import styled from "@emotion/styled";
import { Button as MuiButton } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { Button } from "@components/Button";

interface IButtonProps {
  size?: string;
  color?: string;
}

export const TopLineHeaderCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const RightSideTopLineHeader = styled.div`
  display: flex;
  gap: 12px;
`;

export const ActiveButton = styled(MuiButton)`
  border-color: currentColor;
  border-radius: 6px;
`;

export const LoadingButtonStyled = styled(LoadingButton)`
  border-color: currentColor;
  border-radius: 6px;
`;

export const MiddleCardWrapper = styled.div`
  display: flex;
  gap: 32px;
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const NutrientsSectionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

export const ToolsSectionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

export const SectionLabel = styled.span`
  font: 500 14px/18px Figtree, sans-serif;
`;

export const NutrientLabel = styled.span`
  font: 600 14px/18px Figtree, sans-serif;
`;

export const TitleLabel = styled.span`
  font: 600 20px/24px Figtree, sans-serif;
  color: #131313;
`;

export const StyledButton = styled(Button)<IButtonProps>`
  position: absolute;
  color: ${({ color }) => color};
  size: ${({ size }) => size};
  padding: 0.75rem;
  bottom: 0.5rem;
  border-style: none;
  right: 0.5rem;
`;
