import { styled, Tab } from "@mui/material";
import { TabPanel } from "@components/Alloweat/Tabs/TabPanel";

export const CustomTabPanel = styled("div")`
  padding: 0px;
`;

export const CustomTab = styled(Tab)`
  line-height: 24px;
  font-size: 14px;
  font-weight: 500;
  font-family: Figtree, serif;
  color: #727272;
  .Mui-selected {
    color: #7448d0;
    border-bottom: 2px solid #7448d0;
  }
`;
