import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAppTranslation } from "@hooks";
import { FetchClinicProductDto } from "@client";
import { useEffect } from "react";
import { Currencies } from "@utils/scheduleEvent";

export function useProductForm(
  product: FetchClinicProductDto | undefined,
  isPln: boolean,
  isEur: boolean,
  isUsd: boolean,
  isGbp: boolean,
) {
  const { t } = useAppTranslation();

  const currencySchema = yup
    .number()
    .typeError(t("common.wrong_value"))
    .integer(t("common.wrong_value"))
    .required();

  const resolver = yupResolver(
    yup.object().shape({
      name: yup.string().required(t("common.required_field")),
      nameEn: yup.string().required(t("common.required_field")),
      status: yup
        .mixed()
        .oneOf(["active", "inactive"], t("common.required_field"))
        .required(t("common.required_field")),
      pln: isPln ? currencySchema : yup.string().optional(),
      eur: isEur ? currencySchema : yup.string().optional(),
      usd: isUsd ? currencySchema : yup.string().optional(),
      gbp: isGbp ? currencySchema : yup.string().optional(),
    }),
  );

  const form = useForm<ProductFormInput>({
    defaultValues,
    resolver,
  });

  useEffect(() => {
    if (product) {
      const getCurrency = (currency: Currencies) => {
        const amount = product.prices.find(
          price => price.currency === currency,
        )?.amount;

        if (!amount) {
          return;
        }

        return parseInt(amount.toString()).toString() ?? "";
      };

      form.reset(
        {
          name: product.translations.pl.name,
          nameEn: product.translations.en.name,
          status: product.isActive ? "active" : "inactive",
          pln: getCurrency(Currencies.PLN),
          eur: getCurrency(Currencies.EUR),
          usd: getCurrency(Currencies.USD),
          gbp: getCurrency(Currencies.GBP),
        },
        {
          keepDefaultValues: true,
        },
      );
    }
  }, [product]);

  return form;
}

interface ProductFormInput {
  name: string;
  nameEn: string;
  status: "active" | "inactive";
  pln: string;
  eur: string;
  usd: string;
  gbp: string;
}

const defaultValues: ProductFormInput = {
  name: "",
  nameEn: "",
  status: "active",
  pln: "",
  eur: "",
  usd: "",
  gbp: "",
};
