import { Button } from "@mui/material";
import { FC } from "react";
import { useAppTranslation } from "@hooks";

type PropsType = {
  onCancel: () => void;
};

const GeneratingPdfPlaceholder: FC<PropsType> = ({ onCancel }) => {
  const { t } = useAppTranslation();

  return (
    <div
      className={
        "flex flex-col items-center content-center gap-[24px] mb-[16px]"
      }
      style={{ width: "478px" }}
    >
      <img src="/static/pdf/loading.gif" width={"116px"} height={"116px"} />
      <span
        style={{
          font: "600 18px/20px Figtree, sans-serif",
        }}
      >
        {t("pdf.downloading_progress")}
      </span>
      <Button variant="outlined" onClick={onCancel}>
        {t("common.cancel")}
      </Button>
    </div>
  );
};

export default GeneratingPdfPlaceholder;
