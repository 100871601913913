import { styled } from "@mui/material";

export const FatTissueHeader = styled("span")`
  font-size: 1.143rem;
  line-height: 1.25rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const FatTissueWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
