import { TagsColumnCell } from "@components/ProgramsDataGrid/TagsColumnCell";
import { RowType } from "@views/dietician/PatientProgramChoice/PatientProgramChoiceGrid";
import { useAppTranslation } from "@hooks";
import { useMemo } from "react";

type TagsColumnProps = {
  row: RowType;
};
const TagsColumn = ({ row }: TagsColumnProps) => {
  const tags = useMemo(() => {
    return row.tags.map(tag => {
      return tag.id.toString();
    });
  }, [row]);

  return <TagsColumnCell tags={tags} />;
};

export default TagsColumn;
