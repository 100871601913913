import { useEffect, useMemo, useState } from "react";

import { omit, range } from "lodash";
import { TextField, Typography, useMediaQuery, useTheme } from "@mui/material";

import { Nutrient } from "@typeDefinitions";
import { percentageFromNutrients } from "@components/MacrosSliders";
import { MacrosBarChart, NutrientDetails } from "@components/MacrosBarChart";
import { useAppTranslation } from "@hooks";
import { SelectOption } from "@components/FormAutocomplete";
import { calcProportionalNutrients } from "@components/RecipeCompositionSection/nutrientsUtils";
import { PieChartWrapper } from "@components/MacrosBarChart/PieChartWrapper";
import { LabeledDetail } from "@components/PreviewDetails";

import {
  NutrientsWrapper,
  StyledChartAutocomplete,
  StyledDivider,
} from "./RecipeTableForm.styled";

interface ChartCardProps {
  nutrients: Nutrient[];
  totalServings: number;
  pie?: boolean;
  setWidth?: boolean;
  withDivider?: boolean;
  defaultSelect?: number;
  controlledServing?: SelectOption;
  preview?: boolean;
}

export const ChartCard = ({
  nutrients,
  totalServings,
  pie = false,
  setWidth,
  withDivider = true,
  defaultSelect,
  controlledServing,
  preview,
}: ChartCardProps) => {
  const { t } = useAppTranslation();
  const { breakpoints } = useTheme();
  const isLaptopSize = useMediaQuery(`${breakpoints.up("laptop")}`);
  const macrosPercentage = useMemo(
    () => percentageFromNutrients(nutrients),
    [nutrients],
  );

  const defaultServings = {
    id: `${defaultSelect ? defaultSelect : totalServings}`,
    label: `${defaultSelect ? defaultSelect : totalServings}/${totalServings}`,
  };
  const [selectedServings, setSelectedServing] =
    useState<SelectOption>(defaultServings);

  const optionsArray: SelectOption[] = useMemo(
    () =>
      range(1, totalServings + 1).map(num => ({
        id: `${num}`,
        label: `${num}/${totalServings}`,
      })),
    [totalServings],
  );

  const proportion = useMemo(
    () =>
      controlledServing
        ? Number(controlledServing.id) / totalServings
        : Number(selectedServings.id) / totalServings,
    [selectedServings, controlledServing],
  );

  const proportionalNutrients = calcProportionalNutrients(
    nutrients,
    100,
    proportion,
  );

  useEffect(() => {
    if (Number(selectedServings.id) <= optionsArray.length)
      setSelectedServing(optionsArray[Number(selectedServings.id) - 1]);
    else setSelectedServing(optionsArray[optionsArray.length - 1]);
  }, [totalServings]);

  return (
    <>
      {pie ? (
        <PieChartWrapper nutrients={nutrients} />
      ) : (
        <MacrosBarChart {...macrosPercentage} />
      )}
      {withDivider && <StyledDivider />}
      <NutrientsWrapper directionColumn={pie}>
        {pie && (
          <Typography variant="h6" sx={{ height: "1.8rem" }}>
            {!preview && t("recipe_new.number_of_portions")}
          </Typography>
        )}

        {preview && (
          <LabeledDetail
            label={t("recipe_new.number_of_portions")}
            value={controlledServing?.label ?? selectedServings.label}
          />
        )}

        {!preview && (
          <StyledChartAutocomplete
            setWidth={setWidth === undefined ? !pie : setWidth}
            disabled={totalServings === 1}
            disableClearable
            size="small"
            options={optionsArray}
            value={controlledServing || selectedServings}
            onChange={(_, value: SelectOption | null) =>
              setSelectedServing(value ?? defaultServings)
            }
            renderInput={params => (
              <TextField
                {...omit(params, "InputLabelProps")}
                label={t("recipe_new.number_of_portions")}
              />
            )}
          />
        )}

        <NutrientDetails
          compact={!isLaptopSize}
          nutrients={proportionalNutrients}
          table={pie}
        />
      </NutrientsWrapper>
    </>
  );
};
