import {
  SelectedDaysType,
  Type,
} from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import { Dayjs } from "dayjs";

const mapSelectedFromDayToDate = (selectDays: SelectedDaysType) => {
  return mapSelectedDayToDate(selectDays.from);
};

const mapSelectedToDayToDate = (selectDays: SelectedDaysType) => {
  return mapSelectedDayToDate(selectDays.to);
};

const mapSelectedDayToDate = (day: Dayjs) => day.format("YYYY-MM-DD");

const mapSelectedDaysToRequestQueryParams = (selectDays: SelectedDaysType) => {
  return {
    dateFrom: mapSelectedFromDayToDate(selectDays),
    dateTo: mapSelectedToDayToDate(selectDays),
  };
};

const mapSelectedDaysByTypeDayToRequestQueryParams = (
  selectDays: SelectedDaysType,
) => {
  return {
    dateFrom: mapSelectedDayToDate(selectDays.from.clone().subtract(3, "day")),
    dateTo: mapSelectedDayToDate(selectDays.from.clone().add(3, "day")),
  };
};

const mapSelectedDaysByType = (type: Type, selectDays: SelectedDaysType) => {
  return type === "day"
    ? mapSelectedDaysByTypeDayToRequestQueryParams(selectDays)
    : mapSelectedDaysToRequestQueryParams(selectDays);
};

export {
  mapSelectedFromDayToDate,
  mapSelectedToDayToDate,
  mapSelectedDaysToRequestQueryParams,
  mapSelectedDaysByTypeDayToRequestQueryParams,
  mapSelectedDaysByType,
};
