import { Outlet, useLocation } from "react-router-dom";

import { ProgramEmptyDietProvider } from "@context";

import { NavigationHeader } from "./NavigationHeader";
import { useAppParams } from "@hooks";

export const PatientProgramLayout = () => {
  const { patientId } = useAppParams();
  const { pathname } = useLocation();
  const returnLink = `patients/${patientId}/programs`;
  const isDietCreatorOpened = pathname.includes("diet");

  return (
    <div className="grid gap-7">
      <NavigationHeader returnTo={returnLink} hideArrow={isDietCreatorOpened} />

      <ProgramEmptyDietProvider>
        <Outlet />
      </ProgramEmptyDietProvider>
    </div>
  );
};
