import { styled } from "@mui/material";

export const NutrientRowStyled = styled("div")`
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  background: ${({ theme }) => theme.palette.primary.light};
  border-radius: 8px;
`;

export const TextStyled = styled("span")`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;
