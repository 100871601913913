import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const FlameFilled = (props: SvgIconProps) => {
  return (
    <SvgIcon width="12" height="12" viewBox="0 0 12 12" {...props}>
      <path
        d="M4.41138 0.127148C4.6029 -0.0439371 4.9 -0.0415935 5.09152 0.129492C5.7692 0.736493 6.40513 1.39037 6.99933 2.09814C7.26942 1.76066 7.57634 1.39271 7.90781 1.09273C8.10179 0.919297 8.40134 0.919297 8.59531 1.09507C9.44487 1.86847 10.1643 2.89029 10.6701 3.86056C11.1685 4.81676 11.5 5.79406 11.5 6.48308C11.5 9.47356 9.04955 12 6 12C2.91607 12 0.5 9.47122 0.5 6.48074C0.5 5.58079 0.937054 4.48162 1.61473 3.39417C2.29978 2.29032 3.26719 1.1396 4.41138 0.127148ZM6.04174 9.75011C6.66295 9.75011 7.21295 9.58606 7.73103 9.25795C8.76473 8.56892 9.04219 7.19086 8.42098 6.1081C8.31049 5.89718 8.02812 5.88311 7.86853 6.06123L7.24978 6.74792C7.08772 6.92603 6.79554 6.92134 6.6433 6.7362C6.23817 6.24403 5.51384 5.36517 5.10134 4.86598C4.94665 4.67849 4.65201 4.67614 4.49487 4.86363C3.66496 5.85968 3.24754 6.48777 3.24754 7.19321C3.25 8.7986 4.49241 9.75011 6.04174 9.75011Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
