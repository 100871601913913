import { MouseEventHandler, useEffect, useRef, useState } from "react";

import { Chip, Fade, Popper } from "@mui/material";

import { useAppTranslation } from "@hooks";
import { useTagsNew } from "@hooks/useTagsNew";

import {
  CardStyled,
  ExpandableTagsStyled,
  PopperStyled,
  ShowMoreChip,
  TagsWrapper,
} from "./ExpandableTags.styled";

interface ExpandableTagsProps {
  tags: number[];
  showCount?: number;
}
export const ExpandableTags = ({
  tags,
  showCount = 2,
}: ExpandableTagsProps) => {
  const { t } = useAppTranslation();
  const { tagNameDictionary } = useTagsNew();

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const toggleAnchor: MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation();
    anchorEl ? setAnchorEl(null) : setAnchorEl(e.currentTarget);
  };

  const handleMouseLeave = () => setAnchorEl(null);

  return (
    <ExpandableTagsStyled onMouseLeave={handleMouseLeave}>
      <TagsWrapper>
        {tags.slice(0, showCount).map(t => (
          <Chip
            key={t}
            color="primary"
            label={tagNameDictionary.get(Number(t))}
          />
        ))}
      </TagsWrapper>
      {tags.length > showCount && (
        <ShowMoreChip
          color="primary"
          variant="outlined"
          label={t("common.more").toLocaleLowerCase()}
          onClick={toggleAnchor}
        />
      )}
      {tags.length > showCount && (
        <PopperStyled open={!!anchorEl} anchorEl={anchorEl} transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} in={!!anchorEl}>
              <CardStyled>
                {tags.map(t => (
                  <Chip
                    key={`card-${t}`}
                    color="primary"
                    label={tagNameDictionary.get(Number(t))}
                  />
                ))}
              </CardStyled>
            </Fade>
          )}
        </PopperStyled>
      )}
    </ExpandableTagsStyled>
  );
};
