import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const PieChart = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="14" viewBox="0 0 14 14" {...props}>
      <path
        d="M7.02674 6.59375V0.921484C7.02674 0.692969 7.20758 0.5 7.44008 0.5C10.6357 0.5 13.2268 3.04668 13.2268 6.1875C13.2268 6.41602 13.0305 6.59375 12.798 6.59375H7.02674ZM0 7.40625C0 4.32637 2.32761 1.77715 5.34756 1.3709C5.58523 1.33789 5.78673 1.52578 5.78673 1.76191V7.8125L9.82969 11.7861C10.0028 11.9563 9.98986 12.2355 9.79094 12.3727C8.77826 13.0836 7.53825 13.5 6.20007 13.5C2.77711 13.5 0 10.773 0 7.40625ZM13.5988 7.8125C13.8391 7.8125 14.0277 8.01055 13.9967 8.24414C13.7977 9.66348 13.1028 10.9254 12.0876 11.8572C11.9325 11.9994 11.6897 11.9893 11.5399 11.8395L7.44008 7.8125H13.5988Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
