import { Control, FieldValues, Path, useController } from "react-hook-form";

import { SliderFilter, SliderFilterProps } from "./SliderFilter";

export interface SliderFilterFormProps<T extends FieldValues>
  extends Omit<SliderFilterProps, "value" | "setValue"> {
  control: Control<T>;
}

export const SliderFilterForm = <T extends FieldValues>({
  nutrientId,
  control,
  label,
  maxValue,
  step,
  decimalInput,
}: SliderFilterFormProps<T>) => {
  const {
    field: { value, onChange },
  } = useController({ control, name: `nutrients.${nutrientId}` as Path<T> });

  return (
    <SliderFilter
      label={label}
      value={value}
      setValue={onChange}
      maxValue={maxValue}
      step={step}
      nutrientId={nutrientId}
      decimalInput={decimalInput}
    />
  );
};
