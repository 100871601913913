import { ChangeEventHandler, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";

import { ToggleButton, ToggleButtonGroup } from "@mui/material";

import { PolandFlag, Reviewed, Trash, UploadImgFrame } from "@assets/icons";
import { LangDto } from "@client";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormExpandableTagSelector } from "@components/FormExpandableTagSelector";
import { useAppParams, useAppTranslation, useUserRoles } from "@hooks";

import { ConfirmationIconButton } from "@components/ConfirmationIconButton";
import {
  useDeleteFoodImageMutation,
  usePostFoodImageMutation,
} from "@hooks/queries/food";
import { barcodeInput } from "@utils/inputs";
import { DietitianProductFormInput } from "../../ProductForm";
import {
  ImgWrapper,
  ProductDetailsWrapper,
  ProductEditorActionWrapper,
  StyledConfirmationIconButton,
  StyledFormTextField,
  StyledIconButton,
  StyledImg,
  StyledImgInput,
} from "./EditProductDetails.styled";
import { FormCategorySelect } from "./FormCategorySelect";
import { ProductShopsAutocomplete } from "./ProductShopsAutocomplete";

interface EditProductDetailsProps {
  imageUrl?: string | null;
}
export const EditProductDetails = ({ imageUrl }: EditProductDetailsProps) => {
  const { t } = useAppTranslation();
  const { productId } = useAppParams();
  const { control, formState } = useFormContext<DietitianProductFormInput>();
  const { isAdminClinicMember } = useUserRoles();
  const [lang, setLang] = useState<LangDto>(LangDto.PL);
  const { errors } = formState;

  const [submitting, setSubmitting] = useState(false);
  const { mutate: deleteImage } = useDeleteFoodImageMutation();
  const { mutate: uploadImage, isLoading: isUploading } =
    usePostFoodImageMutation({
      onSuccess: () => setSubmitting(false),
      onError: () => setSubmitting(false),
    });

  const onChange: ChangeEventHandler<HTMLInputElement> = async event => {
    if (submitting) return;
    setSubmitting(true);

    const formData = new FormData();
    event.target.files && formData.set("image", event.target.files[0]);

    uploadImage({ foodId: productId, formData });
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadFileButtonClick = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  const handleDeleteImg = () => {
    deleteImage(productId);
  };

  useEffect(() => {
    if (errors.namePl) setLang(LangDto.PL);
  }, [errors]);

  return (
    <div className="flex gap-[16px]">
      {imageUrl ? (
        <ImgWrapper>
          <StyledImg
            src={imageUrl}
            srcSet={imageUrl}
            alt={t("recipe.recipe_img")}
          />
          <StyledConfirmationIconButton onClick={e => e.stopPropagation()}>
            <ConfirmationIconButton
              icon={<Trash size="w-5 h-5" />}
              onConfirm={handleDeleteImg}
              confirmationText={t("confirmation.question_delete")}
              submitText={t("confirmation.delete")}
            />
          </StyledConfirmationIconButton>
        </ImgWrapper>
      ) : (
        <>
          <StyledIconButton onClick={handleUploadFileButtonClick}>
            <StyledImgInput
              name="image"
              type="file"
              accept="image/*"
              ref={fileInputRef}
              disabled={isUploading}
              onChange={onChange}
            />
            <UploadImgFrame className="w-full h-full" />
          </StyledIconButton>
        </>
      )}
      <ProductDetailsWrapper>
        <div className="flex justify-between">
          <StyledFormTextField
            name={lang === LangDto.PL ? "namePl" : "nameEn"}
            key={lang === LangDto.PL ? "namePl" : "nameEn"}
            variant="outlined"
            label={t("product.edit.name")}
            placeholder={t("product.edit.start_typing")}
          />
          <div className="self-start">
            <ToggleButtonGroup
              value={lang}
              exclusive
              disabled={!!errors.namePl}
              onChange={(_, value) => value && setLang(value)}
            >
              <ToggleButton value={LangDto.PL}>
                {LangDto.PL.toUpperCase()}
              </ToggleButton>
              <ToggleButton value={LangDto.EN}>
                {LangDto.EN.toUpperCase()}
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>

        <div className="flex justify-between">
          <StyledFormTextField
            name="producer"
            variant="outlined"
            label={t("product.edit.producer")}
            placeholder={t("product.edit.start_typing")}
          />

          <div className="flex gap-4">
            <FormCheckbox
              name="plOnly"
              control={control}
              label={
                <ProductEditorActionWrapper>
                  {t("products.pl_only")}
                  <PolandFlag size="w-5 h-5" />
                </ProductEditorActionWrapper>
              }
            />
            {isAdminClinicMember && (
              <FormCheckbox
                name="reviewed"
                control={control}
                label={
                  <ProductEditorActionWrapper>
                    {t("products.reviewed")}
                    <Reviewed size="w-5 h-5" />
                  </ProductEditorActionWrapper>
                }
              />
            )}
          </div>
        </div>
        <ProductShopsAutocomplete />
        <FormCategorySelect />
        <StyledFormTextField
          name="barcode"
          variant="outlined"
          label={t("product.edit.barcode")}
          placeholder={t("product.edit.start_typing")}
          InputProps={{
            inputComponent: barcodeInput,
          }}
        />
        <FormExpandableTagSelector name="tags" tagCategory="product" />
      </ProductDetailsWrapper>
    </div>
  );
};
