import { Button, Divider, styled } from "@mui/material";

export const Title = styled("h1")`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;

export const Content = styled("p")`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const StyledDivider = styled(Divider)`
  border-color: ${({ theme }) => theme.colors.neutral.medium[400]};
`;

export const Wrapper = styled("div")`
  display: grid;
  justify-items: center;
  align-content: space-between;
  padding: 68px 16px 20px 16px;
  text-align: center;
  height: 100%;
`;

export const ContactUsSection = styled("div")`
  display: grid;
  gap: 16px;
  width: 100%;
  padding-bottom: 38px;
`;

export const StyledButton = styled(Button)`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 0;
  margin-top: -4px;
`;
