import { IconButton, Paper, styled } from "@mui/material";

interface SelectOpenedProps {
  opened: boolean;
}

export const StyledIconButton = styled(IconButton, {
  shouldForwardProp: prop => prop !== "opened",
})<SelectOpenedProps>`
  transform: rotate(${({ opened }) => (opened ? 180 : 0)}deg);
`;

export const StyledSelect = styled("div", {
  shouldForwardProp: prop => prop !== "opened",
})<SelectOpenedProps>`
  border-color: ${({ opened, theme }) =>
    opened ? theme.palette.primary.main : theme.colors.border};

  &:hover {
    border-color: ${({ theme }) => theme.colors.focusedBorder};
  }

  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom-width: 1px;
  display: flex;
  padding-right: 0.2rem;
  align-items: center;
`;

interface StyledSelectTextProps {
  bold: boolean;
}

export const StyledSelectText = styled("span")<StyledSelectTextProps>`
  padding: 1rem 0.75rem 0.7rem 0.75rem;
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  font-size: 0.875rem;
  flex: 1;
`;

interface StyledMenuProps {
  width?: number;
}

export const StyledPopperPaper = styled(Paper)<StyledMenuProps>`
  width: ${({ width }) => width}px;
  max-height: 15.625rem;
  overflow-y: auto;
`;
