import { styled } from "@mui/material";

export const LayoutWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: ${({ theme }) => theme.palette.primary.light};
  min-height: 100%;
`;

export const Header = styled("div")`
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  position: sticky;
  top: 0;
  z-index: 2;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallNew}px) {
    background: ${({ theme }) => theme.colors.survey.light};
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.smallNew}px) {
    background: ${({ theme }) => theme.palette.primary.light};
  }
`;

export const ViewWrapper = styled("div")`
  display: grid;
  place-items: center;
`;

export const ContentWrapper = styled("div")`
  display: grid;
  flex: 1;
  padding: 0 1.5rem;
`;

export const PacmanBackground = styled("div")`
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 0;
  top: 5rem;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallNew}px) {
    display: none;
  }
`;
