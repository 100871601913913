import { FormProvider, useForm } from "react-hook-form";

import * as yup from "yup";

import { ProductsReplacementRequest } from "@client/diets";
import { yupResolver } from "@hookform/resolvers/yup";

export const useExchangeProductForm = (
  defaultValues?: ExchangeProductFormInput,
) => {
  const productSchema = yup.object().shape({
    fromProduct: yup.number().required(),
    toProduct: yup.number().required(),
  });
  const resolver = yup.object().shape({
    products: yup.array().of(productSchema).required(),
  });
  const form = useForm<ExchangeProductFormInput>({
    defaultValues,
    resolver: yupResolver(resolver),
  });

  return form;
};

export interface Product {
  unit: Unit;
  fromProduct: number | null;
  toProduct: number | null;
  measureId: number | null;
  ratio: number;
}

export interface ExchangeProductFormInput {
  products: Product[];
}

export enum Unit {
  G = "g",
  KCAL = "kcal",
}

export const mapExchangeProductFormRequest = ({
  products,
}: ExchangeProductFormInput): ProductsReplacementRequest => {
  return {
    data: products.map(p => ({
      foodId: p.fromProduct ?? 0,
      replacement: {
        foodId: p.toProduct ?? 0,
        ratio: p.ratio,
        foodMeasureId: 1,
      },
    })),
  };
};
