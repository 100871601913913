import { styled } from "@mui/material";

export const EventWrapper = styled("div")`
  position: relative;
  display: flex;
  margin: 2px 0;
  &:hover {
    & .notificationWrapper {
      background: ${({ theme }) => theme.palette.primary.light};
    }
    & .timeShower {
      display: none;
    }

    & .notificationMenu {
      display: inline-flex;
    }
  }
`;
