import React from "react";
import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const NewDiet = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 93 73">
    <g id="icon-nowa-dieta" transform="translate(30.998 14.5)" opacity="0.4">
      <rect
        id="Rectangle_1174"
        data-name="Rectangle 1174"
        width="93"
        height="73"
        transform="translate(-30.998 -14.5)"
        fill="#fff"
        opacity="0"
      />
      <g id="Group_4511" data-name="Group 4511" transform="translate(-2 0)">
        <path
          id="Path_923"
          data-name="Path 923"
          d="M3435.613,1487.1v-5.984L3424.5,1470h-24.79a1.71,1.71,0,0,0-1.71,1.71v43.581a1.71,1.71,0,0,0,1.71,1.71h25.645"
          transform="translate(-3398 -1470)"
          fill="rgba(0,0,0,0)"
          stroke="current"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_924"
          data-name="Path 924"
          d="M3533.113,1506.274h-10.258a.855.855,0,0,1-.855-.855V1502"
          transform="translate(-3495.5 -1495.161)"
          fill="none"
          stroke="current"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
      <g
        id="Group_4514"
        data-name="Group 4514"
        transform="translate(-345 -143.5)"
      >
        <path
          id="Path_925"
          data-name="Path 925"
          d="M376.436,181.678,370,183.517l1.839-6.437,12.414-12.414a2.276,2.276,0,0,1,3.218,0l1.379,1.379a2.276,2.276,0,0,1,0,3.218Z"
          fill="none"
          stroke="current"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          id="Line_336"
          data-name="Line 336"
          x1="4.598"
          y1="4.598"
          transform="translate(382.414 166.505)"
          fill="none"
          stroke="current"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
      <g
        id="Group_4512"
        data-name="Group 4512"
        transform="translate(4.839 8.113)"
      >
        <line
          id="Line_331"
          data-name="Line 331"
          x2="17"
          transform="translate(7.162 9.887)"
          fill="none"
          stroke="current"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          id="Line_332"
          data-name="Line 332"
          x2="12"
          transform="translate(7.162 15.887)"
          fill="none"
          stroke="current"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          id="Line_333"
          data-name="Line 333"
          x2="8"
          transform="translate(7.162 22.887)"
          fill="none"
          stroke="current"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          id="Line_334"
          data-name="Line 334"
          x2="8"
          transform="translate(7.162 29.887)"
          fill="none"
          stroke="current"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          id="Line_335"
          data-name="Line 335"
          x2="9"
          transform="translate(0.162 1.887)"
          fill="none"
          stroke="current"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <ellipse
          id="Ellipse_111"
          data-name="Ellipse 111"
          cx="1"
          cy="0.5"
          rx="1"
          ry="0.5"
          transform="translate(0.162 8.887)"
          fill="none"
          stroke="current"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <circle
          id="Ellipse_112"
          data-name="Ellipse 112"
          cx="1"
          cy="1"
          r="1"
          transform="translate(0.162 14.887)"
          fill="none"
          stroke="current"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <circle
          id="Ellipse_113"
          data-name="Ellipse 113"
          cx="1"
          cy="1"
          r="1"
          transform="translate(0.162 21.887)"
          fill="none"
          stroke="current"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <circle
          id="Ellipse_114"
          data-name="Ellipse 114"
          cx="1"
          cy="1"
          r="1"
          transform="translate(0.162 28.887)"
          fill="none"
          stroke="current"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
      </g>
      <path
        id="Union_21"
        data-name="Union 21"
        d="M26125.5-1969.5h0v0h0v0Z"
        transform="translate(-26138 1972.5)"
        fill="none"
        stroke="current"
        strokeWidth="1.5"
      />
    </g>
  </SvgIcon>
);
