import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";

import {
  MealPatternNewDto,
  searchMealPatternsNew,
} from "@client/searchMealPatternsNew";
import {
  RecipesFiltersFormInput,
  convertSearchParamsToObj,
} from "@components/RecipesFiltersNew";
import { ApiResponse } from "@typeDefinitions";
import {
  parseNumber,
  parseNutrientsList,
  parseString,
  parseTagsList,
} from "@utils/filters";
import { useSearchParams } from "react-router-dom";
import { QueryOptionsTyped } from "./types";
import { omitDefaultNutrients, parseTabName } from "./useSearchRecipesQueryNew";

const parseMealsFilters = (filters?: RecipesFiltersFormInput) => {
  const output = new URLSearchParams();

  parseTagsList(filters?.tags, output);
  parseNutrientsList(filters?.nutrients, output);
  parseTabName(filters?.tabs, output);
  parseString(filters?.query || undefined, "query", output);
  parseNumber(filters?.page, "page", output);
  parseNumber(filters?.perPage, "perPage", output);

  return output;
};

export const searchMealPatternsQueryNewKey = "searchMealPatternsQueryNewKey";

export function useSearchMealPatternsQueryNew(
  options?: QueryOptionsTyped<ApiResponse<MealPatternNewDto[]>>,
) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [committedInputs, setCommittedInputs] =
    useState<RecipesFiltersFormInput>(
      omitDefaultNutrients(convertSearchParamsToObj(searchParams)),
    );
  const params = useMemo(
    () => parseMealsFilters(committedInputs),
    [committedInputs],
  );

  useEffect(() => {
    setSearchParams(params, { replace: true });
  }, [params]);

  const { data, ...rest } = useQuery(
    [searchMealPatternsQueryNewKey, params.toString()],
    () => searchMealPatternsNew(params),
    options,
  );

  return {
    meals: data?.data,
    links: data?.meta?.links,
    submit: setCommittedInputs,
    ...rest,
    meta: data?.meta,
  };
}

const searchMealPatternsInfiniteQueryKey = "searchMealPatternsInfiniteQueryKey";

export const useSearchMealPatternsInfiniteQuery = (
  defaultInputs: RecipesFiltersFormInput | undefined = {},
  options?: QueryOptionsTyped<ApiResponse<MealPatternNewDto[]>>,
) => {
  const [committedInputs, setCommittedInputs] =
    useState<RecipesFiltersFormInput>(defaultInputs);
  const params = useMemo(
    () => parseMealsFilters(omitDefaultNutrients(committedInputs)),
    [committedInputs],
  );

  const fetchMealPatterns = ({ pageParam = 1 }) => {
    const paginatedParams = new URLSearchParams(params);
    paginatedParams.set("page", pageParam.toString());
    return searchMealPatternsNew(paginatedParams);
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    ...rest
  } = useInfiniteQuery(
    [searchMealPatternsInfiniteQueryKey, params.toString()],
    fetchMealPatterns,
    {
      getNextPageParam: ({ meta }) => {
        if (!meta) return;
        const { currentPage, total, perPage } = meta;
        const nextPage = currentPage + 1;
        return nextPage <= Math.ceil(total / (perPage || 1))
          ? nextPage
          : undefined;
      },
      enabled: options?.enabled,
      keepPreviousData: options?.keepPreviousData,
      refetchOnMount: options?.refetchOnMount,
    },
  );

  const meals = data?.pages.flatMap(page => page.data) || [];
  const lastMeta = data ? data.pages[data.pages.length - 1].meta : undefined;

  return {
    meals,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    submit: setCommittedInputs,
    meta: lastMeta,
    ...rest,
  };
};
