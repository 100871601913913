import { Alert, styled, Typography } from "@mui/material";

export const StyledInfoAlert = styled(Alert)`
  background-color: rgba(156, 156, 155, 0.04);
  padding: 0.875rem 1rem;
  min-width: 13rem;
  flex: 1;

  & .MuiAlert-message {
    display: flex;
    gap: 0.25rem;
    padding: unset;
    color: ${({ theme }) => theme.palette.text.primary};
    flex-direction: column;
  }
`;

export const StyledTypography = styled(Typography)`
  font-size: 1.5rem;
`;
export const StyledTitle = styled(Typography)`
  font-size: 1.25rem;
`;

export const ScheduleSummaryWrapper = styled("div")`
  display: flex;
  gap: 0.75rem;
  flex-direction: column;
  padding: 1.75rem;
  background-color: ${({ theme }) => theme.palette.background.paper};
  box-shadow: ${({ theme }) => theme.boxShadows.light};
`;

export const ScheduleSummaryNutrientWrapper = styled("div")`
  display: grid;
  width: 9.375rem;
`;

export const SummaryTitleWrapper = styled("div")`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const SummaryNutrientsWrapper = styled("div")`
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
`;

export const SummaryMacrosWrapper = styled("div")`
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
`;

export const MacrosValue = styled("span")`
  font-weight: 600;
`;
