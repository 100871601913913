import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

import { QueryOptionsTyped } from "../types";
import { ApiResponse } from "@typeDefinitions";
import dayjs from "dayjs";
import {
  DietitianProgramScheduleDay,
  fetchDietitianClientProgramScheduleDays,
} from "@client/schedule";

export const fetchDietitianClientProgramScheduleKey =
  "fetchDietitianClientProgramScheduleKey";

export const useFetchDietitianClientProgramScheduleQuery = (
  patientId: string,
  programId: string,
  periodDays: number,
  date: dayjs.Dayjs,
  options?: QueryOptionsTyped<ApiResponse<DietitianProgramScheduleDay[]>>,
) => {
  const params = useMemo(
    () =>
      new URLSearchParams({
        periodDays: periodDays.toString(),
        date: date.format("YYYY-MM-DD"),
      }),
    [periodDays, date],
  );

  const { data, ...rest } = useQuery(
    [fetchDietitianClientProgramScheduleKey, programId, params.toString()],
    () => fetchDietitianClientProgramScheduleDays(patientId, programId, params),
    options,
  );

  return {
    scheduleData: data?.data,
    ...rest,
  };
};

export const NO_SCHEDULE_ERROR = "First initialize the schedule";
