import { IconButton, styled } from "@mui/material";

export const TrashButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.primary.main};
  background: ${({ theme }) => theme.colors.neutral.light[100]};
  border: ${({ theme }) => `1px solid ${theme.palette.primary.medium}`};
  width: 40px;
  height: 100%;
`;

export const Description = styled("div")`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
`;

export const SectionLabel = styled("div")`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
  display: inline-flex;
  gap: 8px;
  align-items: center;
`;

export const SystemTagsList = styled("div")`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding-bottom: 16px;
`;

export const FormTagsList = styled("div")`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const FormTagInputRow = styled("div")`
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
  width: 100%;
`;
