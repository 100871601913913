import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import { object } from "yup";
import type { DietMealListItemCreatorDto } from "./fetchCreatorDietMeals";
import { dietMealListItemCreatorSchema } from "./fetchCreatorDietMeals";

export const fetchCreatorDietMeal = async (
  dietId: string,
  mealId: string,
): Promise<ApiResponse<DietMealListItemCreatorDto>> => {
  const response = await fetchData(
    `/dietitian/creator-2/diets/${dietId}/meals/${mealId}`,
    APIMethods.GET,
  );

  return await apiResponse.validate(response);
};

const apiResponse = object({
  data: dietMealListItemCreatorSchema.required(),
});
