import { useFetchProgramSectionsQuery } from "@hooks/queries";
import _ from "lodash";
import { useMemo } from "react";

export const useProgramSectionsDetailedDictionary = (programId: number) => {
  const { sections: detailedSections, ...rest } =
    useFetchProgramSectionsQuery(programId);

  const sectionsDictionary = useMemo(
    () =>
      _(detailedSections)
        .groupBy(template => template.id)
        .mapValues(template => template[0])
        .value(),
    [detailedSections],
  );

  return { sectionsDictionary, detailedSections, ...rest };
};
