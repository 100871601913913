import MonitoringDivider from "@views/dietician/PatientMonitoring2/components/MonitoringDivider";
import { Box, Modal, Stack, styled } from "@mui/material";
import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import { useFetchMonitoringMeasurementsPhotos } from "@hooks/queries/client/monitoring";
import { mapSelectedDaysToRequestQueryParams } from "@views/dietician/PatientMonitoring2/PatientMonitoringUtils";
import { useAppTranslation } from "@hooks";
import { ImageMonitoring, ListMonitoring } from "@assets/icons/Monitoring";
import { useState } from "react";
import {
  BoxContent,
  BoxContentLabel,
  CapImage,
  Image,
  ImageContainer,
  Label,
  ModalBox,
  ShowMoreLabel,
} from "@views/dietician/PatientMonitoring2/components/MeasurementsBox/MeasurementsPhotosSection.styled";
import PhotosShowMoreButton from "@views/dietician/PatientMonitoring2/components/MeasurementsBox/Buttons/PhotosShowMoreButton";

const MeasurementsPhotosSection = () => {
  const { t } = useAppTranslation();
  const { patientId, selectedDays, type } = useMonitoring();
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const { data } = useFetchMonitoringMeasurementsPhotos({
    patientId,
    ...mapSelectedDaysToRequestQueryParams(selectedDays.selectDays),
  });

  if (!data || data.data.length === 0) {
    return null;
  }

  return (
    <>
      <MonitoringDivider />
      <Stack direction="column" spacing="12px">
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ cursor: "pointer" }}
        >
          <Label>{t("monitoring2.measurements_box.photo_section.label")}</Label>
          <PhotosShowMoreButton />
        </Stack>
        <Stack
          spacing="24px"
          direction="row"
          overflow="auto"
          className="hide-scrollbar"
          justifyContent="center"
        >
          {[1, 2, 3].map(type => {
            const typeImage = data.data.find(t => t.type.id === type);
            return (
              <ImageContainer
                key={type}
                onClick={() => {
                  setImagePreview(
                    typeImage !== undefined ? typeImage.url : null,
                  );
                }}
                sx={{ cursor: typeImage !== undefined ? "pointer" : "default" }}
              >
                {typeImage ? (
                  <Image src={typeImage.url} />
                ) : (
                  <CapImage>
                    <ImageMonitoring fill="#7448D0" size="" />
                  </CapImage>
                )}
                <BoxContent>
                  <BoxContentLabel>
                    {t(`monitoring2.measurements_box.photo_section.${type}`)}
                  </BoxContentLabel>
                </BoxContent>
              </ImageContainer>
            );
          })}
        </Stack>
      </Stack>
      <Modal open={imagePreview != null} onClose={() => setImagePreview(null)}>
        <ModalBox>{imagePreview && <img src={imagePreview} />}</ModalBox>
      </Modal>
    </>
  );
};

export default MeasurementsPhotosSection;
