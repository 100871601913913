import { Checkbox, FormControlLabel } from "@mui/material";
import { useAppTranslation } from "@hooks";
import { Controller, useFormContext } from "react-hook-form";
import { FormPops } from "@views/dietician/ClientHealth/ProfileTab/NutrientsSection/Dialog/NutrientsForm";
import NutrientsLabelLayout from "@views/dietician/ClientHealth/ProfileTab/NutrientsSection/Dialog/NutrientsLabelLayout";

const ImportantField = () => {
  const { t } = useAppTranslation();
  const { control, watch } = useFormContext<FormPops>();
  const importantNutrientsId = watch("importantNutrientsId");
  const importantNutrientsLength = Object.values(importantNutrientsId).filter(
    item => item,
  ).length;

  return (
    <Controller
      control={control}
      name="important"
      render={({ field: { onChange, value } }) => (
        <FormControlLabel
          sx={{ margin: "unset", alignItems: "self-start" }}
          control={
            <Checkbox
              sx={{ marginTop: "3px" }}
              checked={value}
              onChange={onChange}
            />
          }
          label={
            <NutrientsLabelLayout
              label={
                t(
                  "patient.health_and_goal.dietary_profile_tab.nutritional_values_modal.important",
                ) + ` (${importantNutrientsLength}/10)`
              }
              description={t(
                "patient.health_and_goal.dietary_profile_tab.nutritional_values_modal.important_desc",
              )}
            />
          }
        />
      )}
    />
  );
};

export default ImportantField;
