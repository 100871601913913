import { fetchData } from "@utils/api";
import { APIMethods, NoContentApiResponse } from "@typeDefinitions";

export type UpdatePatientImportantNutrientsParams = {
  patientId: number;
};

export type UpdatePatientImportantNutrientsResponse = NoContentApiResponse;

export type UpdatePatientImportantNutrientsPayload = {
  nutrientsId: number[];
};

export const updatePatientImportantNutrients = async (
  params: UpdatePatientImportantNutrientsParams,
  payload: UpdatePatientImportantNutrientsPayload,
): Promise<UpdatePatientImportantNutrientsResponse> => {
  return await fetchData(
    "/dietitian/patients/{patient_id}/important-nutrients".replace(
      "{patient_id}",
      params.patientId.toString(),
    ),
    APIMethods.PUT,
    payload,
  );
};
