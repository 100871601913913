import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const WeightMonitoring = (props: SvgIconProps) => (
  <SvgIcon width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path
      d="M3.25 4.28125C3.25 3.5519 3.53973 2.85243 4.05546 2.33671C4.57118 1.82098 5.27065 1.53125 6 1.53125C6.72935 1.53125 7.42882 1.82098 7.94454 2.33671C8.46027 2.85243 8.75 3.5519 8.75 4.28125C8.75 5.0106 8.46027 5.71007 7.94454 6.22579C7.42882 6.74152 6.72935 7.03125 6 7.03125C5.27065 7.03125 4.57118 6.74152 4.05546 6.22579C3.53973 5.71007 3.25 5.0106 3.25 4.28125ZM8.91758 1.875C8.22363 1.03496 7.1752 0.5 6 0.5C4.8248 0.5 3.77637 1.03496 3.08242 1.875H1.875C1.1166 1.875 0.5 2.4916 0.5 3.25V10.125C0.5 10.8834 1.1166 11.5 1.875 11.5H10.125C10.8834 11.5 11.5 10.8834 11.5 10.125V3.25C11.5 2.4916 10.8834 1.875 10.125 1.875H8.91758ZM6.85938 5.3125C6.85938 5.08477 6.77129 4.87852 6.6252 4.72383L7.34707 3.0416C7.42227 2.86758 7.34062 2.66563 7.1666 2.59043C6.99258 2.51523 6.79062 2.59687 6.71543 2.7709L5.99355 4.45312C5.5209 4.45527 5.14062 4.83984 5.14062 5.3125C5.14062 5.7873 5.5252 6.17188 6 6.17188C6.4748 6.17188 6.85938 5.7873 6.85938 5.3125Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
