export const BodyMeasurementConst = {
  height: 1,
  weight: 2,
  bodyFatLevel: 3,
  arm: 4,
  tightBiceps: 5,
  waist: 6,
  hip: 7,
  thigh: 8,
  calf: 9,
  water: 10,
  bodyFatMass: 11,
  skeletalMuscleMass: 12,
  boneTissueMass: 13,
  chest: 14,
  abdominal: 15,
};
