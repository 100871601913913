import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const QuestionMarkInSquare = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      style={{ fill: "none" }}
      width="24"
      height="28"
      viewBox="0 0 24 28"
    >
      <path
        d="M11.9999 14.5267C11.7735 14.5264 11.5564 14.4364 11.3963 14.2763C11.2362 14.1162 11.1461 13.8991 11.1459 13.6727V12.6877C11.1454 12.4169 11.2075 12.1496 11.3273 11.9067C11.4472 11.6639 11.6216 11.452 11.8369 11.2877C12.1453 11.061 12.4813 10.8745 12.8369 10.7327C13.6469 10.3747 13.9909 10.1667 14.1579 9.74768C14.2568 9.51991 14.2905 9.26914 14.2552 9.02334C14.2198 8.77754 14.1169 8.54639 13.9579 8.35568C13.7522 8.1226 13.498 7.93729 13.2132 7.81275C12.9283 7.68821 12.6197 7.62743 12.3089 7.63468C11.1189 7.63468 10.6389 7.97168 10.4299 8.25468C10.3449 8.36991 10.2834 8.50073 10.2489 8.63968L10.2279 8.77068C10.2217 8.99336 10.1291 9.20489 9.96969 9.36053C9.81031 9.51618 9.59665 9.60373 9.37389 9.60468C9.26069 9.60759 9.14809 9.58738 9.04297 9.5453C8.93785 9.50322 8.84241 9.44015 8.76248 9.35994C8.68255 9.27974 8.61982 9.18408 8.57811 9.07881C8.5364 8.97354 8.51659 8.86087 8.51989 8.74768L8.53088 8.57568C8.54269 8.46007 8.56274 8.34544 8.59088 8.23268C8.67819 7.87352 8.83665 7.53553 9.05688 7.23868C9.44128 6.7835 9.92889 6.42677 10.4791 6.19821C11.0293 5.96965 11.6261 5.87587 12.2199 5.92468C12.8002 5.90889 13.3767 6.02257 13.9076 6.25747C14.4384 6.49237 14.9103 6.84259 15.2889 7.28268C15.637 7.71044 15.8632 8.22428 15.9434 8.76994C16.0237 9.31559 15.9551 9.87279 15.7449 10.3827C15.5391 10.819 15.2469 11.209 14.8859 11.5291C14.5249 11.8492 14.1027 12.0926 13.6449 12.2447C13.3828 12.3412 13.1328 12.4677 12.8999 12.6217L12.8529 13.6757C12.8518 13.9014 12.7616 14.1175 12.6018 14.277C12.442 14.4364 12.2256 14.5262 11.9999 14.5267Z"
        fill="#801FFF"
      />
      <path
        d="M12.0004 18.4656C11.7666 18.4656 11.5381 18.3962 11.3437 18.2664C11.1493 18.1365 10.9978 17.9519 10.9083 17.7359C10.8189 17.5199 10.7955 17.2823 10.8411 17.053C10.8867 16.8237 10.9993 16.6131 11.1646 16.4478C11.3299 16.2825 11.5405 16.1699 11.7698 16.1243C11.999 16.0787 12.2367 16.1021 12.4527 16.1915C12.6687 16.281 12.8533 16.4325 12.9832 16.6269C13.113 16.8213 13.1824 17.0498 13.1824 17.2836C13.1818 17.5969 13.0571 17.8972 12.8356 18.1188C12.614 18.3403 12.3137 18.465 12.0004 18.4656Z"
        fill="#801FFF"
      />
      <path
        d="M3.534 27.0007C2.64841 27.0472 1.78027 26.7422 1.11845 26.1519C0.456632 25.5616 0.0546672 24.7339 0 23.8487V4.15273C0.0546672 3.2676 0.456632 2.43986 1.11845 1.84958C1.78027 1.2593 2.64841 0.954224 3.534 1.00073H20.466C21.3516 0.954224 22.2197 1.2593 22.8815 1.84958C23.5434 2.43986 23.9453 3.2676 24 4.15273V22.8647C23.985 23.1044 23.8762 23.3285 23.697 23.4884C23.5178 23.6483 23.2828 23.731 23.043 23.7187H1.914V23.8497C1.93927 24.2553 2.12362 24.6345 2.42698 24.9049C2.73034 25.1754 3.12818 25.3151 3.534 25.2937H23.043C23.1632 25.2801 23.285 25.2919 23.4003 25.3286C23.5156 25.3652 23.6219 25.4258 23.7121 25.5064C23.8024 25.587 23.8746 25.6857 23.924 25.7962C23.9735 25.9066 23.9991 26.0262 23.9991 26.1472C23.9991 26.2682 23.9735 26.3878 23.924 26.4983C23.8746 26.6087 23.8024 26.7074 23.7121 26.788C23.6219 26.8686 23.5156 26.9292 23.4003 26.9659C23.285 27.0025 23.1632 27.0144 23.043 27.0007H3.534ZM3.534 2.70773C3.12818 2.68638 2.73034 2.8261 2.42698 3.09651C2.12362 3.36691 1.93927 3.74613 1.914 4.15173V22.0107H22.086V4.15273C22.0607 3.74713 21.8764 3.36791 21.573 3.09751C21.2697 2.8271 20.8718 2.68738 20.466 2.70873L3.534 2.70773Z"
        fill="#801FFF"
      />
    </SvgIcon>
  );
};
