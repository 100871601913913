import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchClientMeasurementListQueryInfiniteKey } from "@hooks/queries";
import {
  createPatientBodyMeasurement,
  CreatePatientBodyMeasurementParams,
  CreatePatientBodyMeasurementPayload,
} from "@client/patient/bodyMeasurement/createPatientBodyMeasurement";
import { patientBodyMeasurementKeys } from "@hooks/queries/client/bodyMeasurement/patientBodyMeasurementKey";

const useCreatePatientBodyMeasurementMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      params,
      payload,
    }: {
      params: CreatePatientBodyMeasurementParams & { patientId: number };
      payload: CreatePatientBodyMeasurementPayload;
    }) => {
      return createPatientBodyMeasurement(params, payload);
    },
    onSuccess: async (_, variables) => {
      const patientId = variables.params.patientId;

      queryClient.invalidateQueries({
        queryKey: patientBodyMeasurementKeys.list(patientId),
      });

      queryClient.invalidateQueries({
        queryKey: patientBodyMeasurementKeys.last(patientId),
      });

      queryClient.invalidateQueries([
        fetchClientMeasurementListQueryInfiniteKey,
        patientId.toString(),
      ]);
    },
  });
};

export default useCreatePatientBodyMeasurementMutation;
