import SvgIcon from "@mui/material/SvgIcon";
import { SvgIconProps } from "@mui/material";

export const CloudAdd = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M7.27113 11.6189C3.84558 11.8523 3.84558 16.6696 7.27113 16.9031H8.67649M7.30773 11.6189C4.95815 5.30188 14.8688 2.7765 16.0034 9.41181C19.1728 9.80088 20.4537 13.8825 18.0529 15.9127C17.3209 16.5564 16.3767 16.9101 15.3885 16.9031H15.3227"
      stroke="#727272"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.7745 15.2691C14.7745 15.7473 14.6711 16.1996 14.4772 16.6002C14.4255 16.7165 14.3674 16.8264 14.3028 16.9298C14.0188 17.4085 13.615 17.8051 13.1312 18.0804C12.6474 18.3558 12.1002 18.5004 11.5436 18.5C10.3675 18.5 9.34011 17.8667 8.78439 16.9298C8.71978 16.8264 8.66162 16.7165 8.60993 16.6002C8.41607 16.1996 8.31268 15.7473 8.31268 15.2691C8.31268 13.4856 9.76013 12.0382 11.5436 12.0382C13.327 12.0382 14.7745 13.4856 14.7745 15.2691Z"
      stroke="#727272"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.5354 15.2691L11.1751 15.9088L12.5515 14.6358"
      stroke="#727272"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </SvgIcon>
);
