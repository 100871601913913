import { GeometricFigures } from "@assets/icons";
import { SectionTitleWrapper } from "@components/PreviewDrawer/common/SectionTitleWrapper";
import { useAppTranslation } from "@hooks";
import { scaleNutrients } from "@utils";
import { Measure, Nutrient } from "@typeDefinitions";

import { Header } from "./NutrientsByProduct.styled";
import { ProductAccordion } from "./ProductAccordion";

export interface ProductWithNutrients {
  id: number;
  name: string;
  nutrients: Nutrient[];
  grams: number;
  measure?: Measure;
}

export interface NutrientsByProductProps {
  products: ProductWithNutrients[];
}

export const NutrientsByProduct = ({ products }: NutrientsByProductProps) => {
  const { t } = useAppTranslation();

  return (
    <SectionTitleWrapper
      Icon={GeometricFigures}
      title={t("common.micronutrients")}
    >
      <div className="grid gap-[8px]">
        <Header>{t("common.product")}</Header>

        {products.map((p, idx) => (
          <ProductAccordion
            key={idx}
            name={p.name}
            nutrients={scaleNutrients(p.nutrients, p.grams)}
            grams={p.grams}
            measure={p.measure}
          />
        ))}
      </div>
    </SectionTitleWrapper>
  );
};
