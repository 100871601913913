import {
  QuestionCheckboxDto,
  CheckboxAnswerResourceDto,
} from "@client/surveys";
import { useAppTranslation } from "@hooks";

import { OuterLabel } from "@components/OuterLabel";

import { QuestionPreview } from "../../QuestionPreview";
import { TextStyled } from "./AnswerCheckbox.styled";

interface AnswerCheckboxProps {
  question: QuestionCheckboxDto;
  answer?: CheckboxAnswerResourceDto;
  listIndex: number;
  totalQuestions: number;
}

export const AnswerCheckbox = ({
  answer,
  question,
  listIndex,
  totalQuestions,
}: AnswerCheckboxProps) => {
  const { title, description, config } = question;
  const { t } = useAppTranslation();

  return (
    <QuestionPreview
      title={title}
      description={description}
      listIndex={listIndex}
      totalQuestions={totalQuestions}
    >
      <OuterLabel label={t("questionnaires.questions.answer")}>
        {config.options.map(o => (
          <TextStyled bold={answer?.answer.uuids.includes(o.uuid)}>
            {o.name}
          </TextStyled>
        ))}
        {config.other?.uuid && (
          <TextStyled bold={answer?.answer.uuids.includes(config.other.uuid)}>
            {config.other?.name}: {answer?.answer.text}
          </TextStyled>
        )}
      </OuterLabel>
    </QuestionPreview>
  );
};
