import { useMemo } from "react";

import { CircularProgress } from "@mui/material";
import { isNumber, round } from "lodash";

import {
  DEFAULT_MACROS_RANGE,
  RecipesFiltersFormInput,
} from "@components/RecipesFiltersNew";
import { ListTabs } from "@hooks";
import { useFetchCreatorDietMealQuery } from "@hooks/queries/diets/creator";
import { ENERGY_ID } from "@utils";
import {
  AddElementSidePanel,
  AddElementSidePanelProps,
} from "@views/dietician/DietCreator/components/RecipesTab/AddElementSidePanel/AddElementSidePanel";
import { StyledDrawer } from "@views/dietician/DietCreator/components/RecipesTab/AddElementSidePanel/AddElementSidePanel.styled";
import { ItemsContextProvider } from "@views/dietician/DietCreator/components/RecipesTab/ItemsContext";

interface AddElementSidePanelProxyProps
  extends Omit<AddElementSidePanelProps, "defaultFilters"> {
  dietId: string;
}
export const AddElementSidePanelProxy = ({
  mealId,
  dietId,
  ...rest
}: AddElementSidePanelProxyProps) => {
  const { data, isLoading } = useFetchCreatorDietMealQuery(
    dietId,
    mealId?.toString() ?? "",
  );

  const defaultFilters: RecipesFiltersFormInput | undefined = useMemo(() => {
    if (!data) return;

    const { type, targets } = data;
    const energyValue = targets.find(n => n.id === ENERGY_ID)?.value ?? 0;

    return {
      tabs: ListTabs.ALL,
      page: 1,
      query: "",
      tags: type ? [type.id.toString()] : [],
      nutrients: {
        ...DEFAULT_MACROS_RANGE,
        [ENERGY_ID]: energyValue
          ? [
              Math.max(round(energyValue - KCAL_MARGIN_VALUE), 0),
              round(energyValue + KCAL_MARGIN_VALUE),
            ]
          : DEFAULT_MACROS_RANGE[ENERGY_ID],
      },
    };
  }, [data]);

  if (isLoading)
    return (
      <StyledDrawer
        open={isNumber(mealId)}
        variant="persistent"
        anchor="right"
        onClose={() => rest.onClose()}
      >
        <div className="grid place-items-center h-full">
          <CircularProgress />
        </div>
      </StyledDrawer>
    );

  return (
    <ItemsContextProvider singleSelect>
      <AddElementSidePanel
        mealId={mealId}
        defaultFilters={defaultFilters}
        {...rest}
      />
    </ItemsContextProvider>
  );
};

const KCAL_MARGIN_VALUE = 50;
