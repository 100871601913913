import { styled } from "@mui/material";
import { IconButton } from "@components/IconButton";
import { MenuItem } from "@mui/material";

export const TasksViewSettingsStyledIconButton = styled(IconButton)(
  ({ theme }) => ({
    borderColor: theme.colors.primaryLightNew,
    width: "40px",
    height: "40px",
    borderRadius: "8px",
  }),
);

export const TasksViewSettingsStyledLabel = styled("label")(({ theme }) => ({
  marginRight: "auto",
  fontSize: "0.86rem",
  color: theme.colors.neutral.medium["800"],
}));

export const TasksViewSettingsStyledMenuItem = styled(MenuItem)`
  padding: 0;
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
`;
