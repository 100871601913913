import { Person } from "@assets/icons";
import {
  VisitOverviewItemContent,
  VisitOverviewItemText,
} from "./ScheduleVisitOverviewModal.styled";
import { useFetchClientQueryNew } from "@hooks/queries";

interface PatientItemContentProps {
  id: number | null;
}
export const PatientItemContent = ({ id }: PatientItemContentProps) => {
  const { data } = useFetchClientQueryNew(id ?? 0, { enabled: id !== null });

  const fullName = data?.data
    ? [data.data.firstName, data.data.lastName].join(" ")
    : "-";
  return (
    <VisitOverviewItemContent>
      <Person className="text-xl text-gray-450" />
      <VisitOverviewItemText>{fullName}</VisitOverviewItemText>
    </VisitOverviewItemContent>
  );
};
