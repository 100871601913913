import { useCallback, useState } from "react";

export const useCalendarActions = () => {
  const [selectedEventId, setSelectedEventId] = useState<number | null>(null);
  const [selectedGoogleId, setSelectedGoogleId] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleEventClick = useCallback(
    (
      event: React.MouseEvent<HTMLElement>,
      eventId: number | null,
      googleId?: string,
    ) => {
      setSelectedGoogleId(googleId ?? null);
      setSelectedEventId(eventId);
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleCloseModal = useCallback(() => {
    setSelectedEventId(null);
    setSelectedGoogleId(null);
    setAnchorEl(null);
  }, []);

  return {
    handleCloseModal,
    handleEventClick,
    selectedEventId,
    anchorEl,
    selectedGoogleId,
  };
};
