import {
  DTagResourceDto,
  SystemQuestionDto,
  TagAllergenAnswerResourceDto,
} from "@client/surveys";
import { OuterLabel } from "@components/OuterLabel";
import { useAppTranslation } from "@hooks";
import { LangDto } from "@client";
import { getTranslatedName } from "@utils";

import { QuestionPreview } from "../../QuestionPreview/QuestionPreview";
import { StyledAnswer } from "./SystemAnswerTagAllergen.styled";

interface SystemAnswerTagAllergenProps {
  question: SystemQuestionDto;
  answer?: TagAllergenAnswerResourceDto;
  listIndex: number;
  totalQuestions: number;
  options?: DTagResourceDto[];
}

export const SystemAnswerTagAllergen = ({
  answer,
  question,
  listIndex,
  totalQuestions,
  options,
}: SystemAnswerTagAllergenProps) => {
  const { title, description } = question;
  const { t, i18n } = useAppTranslation();

  const noAnswer = answer?.answer.value === undefined;
  const answerYes = answer?.answer.value === true;
  const answerNo = answer?.answer.value === false;

  const selectedOptions = options?.filter(o =>
    answer?.answer.tagsId.includes(Number(o.id)),
  );

  return (
    <QuestionPreview
      title={title}
      description={description}
      listIndex={listIndex}
      totalQuestions={totalQuestions}
      systemQuestion
    >
      <OuterLabel label={t("questionnaires.questions.answer")}>
        <StyledAnswer>
          {noAnswer && t("questionnaires.questions.no_answer")}
          {answerYes &&
            t(
              "questionnaires.questions.system_user_preferences_food_unliked.yes",
            )}
          {answerNo &&
            t(
              "questionnaires.questions.system_user_preferences_food_unliked.no",
            )}
          <div className="grid">
            {selectedOptions?.map(o => (
              <StyledAnswer>
                {getTranslatedName(o.translations, i18n.language as LangDto)}
              </StyledAnswer>
            ))}
          </div>
        </StyledAnswer>
      </OuterLabel>
      {answer?.answer.description && (
        <OuterLabel
          label={t(
            "questionnaires.questions.system_user_preferences_food_unliked.comment",
          )}
        >
          <StyledAnswer>{answer.answer.description}</StyledAnswer>
        </OuterLabel>
      )}
    </QuestionPreview>
  );
};
