import { Divider, styled, Typography } from "@mui/material";
import { BorderColor } from "@mui/icons-material";

export const NutrientsGroups = styled("div")`
  columns: 20rem auto;
  padding: 0 1rem;
  column-gap: 2rem;
`;

export const TypographyStyled = styled(Typography)`
  font-weight: 600;
`;

interface StyledElementProps {
  disabled?: boolean;
}

export const StyledElement = styled("div")<StyledElementProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.border}`};
  padding: 0.5rem 1rem 0.3rem;
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.colors.yellowLight : ""};
`;

interface GroupWrapperProps {
  biggerPadding?: boolean;
}

export const GroupWrapper = styled("div", {
  shouldForwardProp: propName => propName !== "biggerPadding",
})<GroupWrapperProps>`
  break-inside: avoid;
  padding-bottom: ${({ biggerPadding }) =>
    biggerPadding ? "3.5rem" : "2.5rem"};
`;

interface BorderColor {
  borderColor: string;
}

export const DividerStyled = styled(Divider, {
  shouldForwardProp: (propName: PropertyKey) => propName !== "borderColor",
})<BorderColor>`
  border-width: 1px;
  border-color: ${({ borderColor }) => borderColor};
`;

export const TypographyContentLabel = styled(Typography)`
  font-size: 0.75rem;
`;

export const GroupLabelWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem 0.25rem;
`;
