import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";
import { Currencies } from "@utils/scheduleEvent";
import { LangDto } from "../common";

export const updateClinicProduct = async (
  id: number,
  payload: UpdateClinicProductRequest,
) => {
  return await fetchData(
    `/dietitian/clinic/products/${id}`,
    APIMethods.PUT,
    payload,
  );
};

export interface UpdateClinicProductRequest {
  isActive: boolean;
  prices: PriceDto[];
  translations: TranslationDto[];
}

interface PriceDto {
  amount: number;
  currency: Currencies;
}

interface TranslationDto {
  lang: LangDto;
  name: string;
}
