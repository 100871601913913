import { RecipeSearchResourceDto } from "@client";
import { RecipeRowIf } from "./RecipesDataGrid";

export const mapRecipeRows = (
  recipes: RecipeSearchResourceDto[] | undefined,
  userId: number,
): RecipeRowIf[] => {
  if (!recipes) return [];

  return recipes.map(
    ({
      id,
      title,
      titleEn,
      tags,
      macros,
      servings,
      favorite,
      actions,
      media,
    }) => ({
      id: id,
      namePl: title,
      nameEn: titleEn ?? title,
      tags: tags.map(t => t.id.toString()),
      nutrients: macros,
      servings: servings,
      isFavorite: favorite.includes(userId),
      actions: actions,
      imgUrl: media.url ?? null,
    }),
  );
};
