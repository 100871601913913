import { useMemo } from "react";
import { useAppTranslation } from "@hooks/useAppTranslation";
import { useFetchNutrientCategoriesQuery } from "@hooks/queries";

export function useNutrientDictionary() {
  const { isPolishChosen } = useAppTranslation();
  const { nutrientCategories } = useFetchNutrientCategoriesQuery();

  const nutrientDictionary = useMemo<NutrientDictionary>(
    () =>
      Object.fromEntries(
        nutrientCategories
          ?.flatMap(category => category.nutrients)
          .map(nutrient => [
            nutrient.id,
            {
              name: isPolishChosen
                ? nutrient.descriptionPl
                : nutrient.descriptionEn,
              unit: nutrient.units,
              short: isPolishChosen
                ? nutrient.shortNamePl ?? nutrient.descriptionPl.charAt(0)
                : nutrient.shortNameEn ?? nutrient.descriptionEn.charAt(0),
              categoryId: nutrient.nutrientCategoryId,
            },
          ]) ?? [],
      ),
    [nutrientCategories, isPolishChosen],
  );

  const getNutrient = (id: number) =>
    nutrientDictionary[id] ? nutrientDictionary[id] : undefined;

  return {
    nutrientDictionary,
    getNutrient,
  };
}

export interface NutrientDetails {
  name: string;
  short: string;
  unit: string;
  categoryId: number;
}
export interface NutrientDictionary {
  [id: number]: NutrientDetails;
}
