export const itemFontColors = [
  // Base Color
  "#71CF84",
  "#8AB4F3",
  "#5474BE",
  "#BA98F5",
  "#FE85C9",
  "#DE81EC",
  "#FFAE53",
  "#D9AB11",
  "#44CEBB",
  "#50BEE1",
  "#FF5656",
  "#97AC00",
  "#BC6161",
  "#8E8E8E",
  "#1E5B00",
  "#0059DE",
  "#002A8B",
  "#4F00D9",
  "#E50081",
  "#B000CB",
  "#DB7400",
  "#9B7700",
  "#007161",
  "#00769B",
  "#B90101",
  "#667400",
  "#540000",
  "#000000",
];

export const itemBgColors = [
  // Light background
  "#F0FAF2",
  "#F3F7FD",
  "#EDF1F8",
  "#F8F4FE",
  "#FEF2F9",
  "#FBF2FD",
  "#FFF6ED",
  "#FBF6E7",
  "#ECFAF8",
  "#EDF8FC",
  "#FFEEEE",
  "#F4F6E5",
  "#F8EFEF",
  "#F3F3F3",
  "#E8EEE5",
  "#E5EEFB",
  "#E5E9F3",
  "#EDE5FB",
  "#FCE5F2",
  "#F7E5F9",
  "#FBF1E5",
  "#F5F1E5",
  "#E5F0EF",
  "#E5F1F5",
  "#F8E5E5",
  "#EFF1E5",
  "#EDE5E5",
  "#E5E5E5",
  // Darker background
  "#DBF3E0",
  "#E1ECFC",
  "#D4DCEE",
  "#EDE5FC",
  "#FEE0F1",
  "#F6DFFA",
  "#FFEAD4",
  "#F5EAC3",
  "#D0F2EE",
  "#D3EEF7",
  "#FFD4D4",
  "#E5EABF",
  "#EED7D7",
  "#E2E2E2",
  "#C6D6BF",
  "#BFD5F6",
  "#BFC9E2",
  "#D3BFF5",
  "#F8BFDF",
  "#EBBFF2",
  "#F6DCBF",
  "#E6DDBF",
  "#BFDBD7",
  "#BFDCE6",
  "#EDBFBF",
  "#D8DCBF",
  "#D4BFBF",
  "#BFBFBF",
  // "Pastel, White text"
  "#B8E7C1",
  "#C4D9F9",
  "#A9B9DE",
  "#DCCBFA",
  "#FEC2E4",
  "#EEC0F5",
  "#FFD6A9",
  "#ECD588",
  "#A1E6DD",
  "#A7DEF0",
  "#FFAAAA",
  "#CBD57F",
  "#DDB0B0",
  "#C6C6C6",
  "#8EAD7F",
  "#7FACEE",
  "#7F94C5",
  "#A77FEC",
  "#F27FC0",
  "#D77FE5",
  "#EDB97F",
  "#CDBB7F",
  "#7FB8B0",
  "#7FBACD",
  "#DC8080",
  "#B2B97F",
  "#A97F7F",
  "#7F7F7F",
  // "Rich, White text"
  "#7FD390",
  "#95BBF4",
  "#6581C4",
  "#C0A2F6",
  "#FE91CE",
  "#E18DED",
  "#FFB664",
  "#DCB328",
  "#56D2C1",
  "#61C4E4",
  "#FF6666",
  "#A1B419",
  "#C27070",
  "#999999",
  "#346B19",
  "#1969E1",
  "#193F96",
  "#6019DC",
  "#E7198D",
  "#B719D0",
  "#DE8119",
  "#A58419",
  "#197F70",
  "#1983A5",
  "#C01A1A",
  "#758119",
  "#651919",
  "#191919",
];

export const whiteTextBgIndex = 56;
