import { Button, styled, Tooltip } from "@mui/material";

interface BuyButtonProps {
  onClick: () => void;
  disabled?: boolean;
}
export const BuyButton = ({ onClick, disabled }: BuyButtonProps) => {
  if (disabled)
    return (
      <Tooltip title="Oczekujemy na płatność za kupiony plan">
        <div className="w-full flex">
          <ButtonStyled variant="contained" onClick={onClick} disabled>
            Kup teraz
          </ButtonStyled>
        </div>
      </Tooltip>
    );

  return (
    <ButtonStyled variant="contained" onClick={onClick}>
      Kup teraz
    </ButtonStyled>
  );
};

const ButtonStyled = styled(Button)`
  font-family: Figtree;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  height: unset;
  padding: 8px;
  flex: 1;

  &.Mui-disabled {
    background: rgba(116, 72, 208, 0.5);
    color: #fff;
  }
`;
