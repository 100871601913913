import * as yup from "yup";

import { IdDto, idSchema } from "@client/common";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const fetchDietNutrientsNorm = async (
  dietId: string,
): Promise<ApiResponse<DietNutrientsNormDto>> => {
  const response = await fetchData(
    `/dietitian/diets/${dietId}/nutrients-norm`,
    APIMethods.GET,
  );

  return responseSchema.validate(response);
};

export interface DietNutrientDto {
  nutrient: IdDto;
  value: number;
  show: boolean;
}

export interface DietNutrientsNormDto {
  norm: IdDto | null;
  nutrients: DietNutrientDto[];
}

const dietNutrientNormSchema = yup.object().shape({
  nutrient: idSchema.required(),
  value: yup.number().required(),
  show: yup.boolean().required(),
});

const dietNutientsNormSchema = yup.object().shape({
  norm: idSchema.nullable().default(null),
  nutrients: yup.array().of(dietNutrientNormSchema).required(),
});

const responseSchema = yup.object().shape({
  data: dietNutientsNormSchema.required(),
});
