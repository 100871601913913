import { useAppParams } from "@hooks";
import { MutationOptions } from "@hooks/queries";
import { useUpdateCreatorDietMealRecipeMutation } from "@hooks/queries/diets/creator";
import { useUpdateProgramDietMealRecipeMutation } from "@hooks/queries/program/dietCreator";

export const useUpdateDietMealRecipeMutation = (options?: MutationOptions) => {
  const { programId } = useAppParams();

  return programId
    ? useUpdateProgramDietMealRecipeMutation(programId, options)
    : useUpdateCreatorDietMealRecipeMutation(options);
};
