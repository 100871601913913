import { ChangeEvent, FC, useState } from "react";
import {
  PdfTemplatePickerAddItemContainerStyled,
  PdfTemplatePickerAddItemInfoStyled,
  PdfTemplatePickerAddItemInputStyled,
  PdfTemplatePickerAddItemStyled,
  PdfTemplatePickerAddItemSubtitleStyled,
  PdfTemplatePickerAddItemTextContainerStyled,
  PdfTemplatePickerAddItemTitleStyled,
} from "@Pdf/components/PdfTemplatePicker/PdfTemplatePickerAddItem.styled";
import { UploadFile } from "@assets/icons/UploadFile";
import { useAppTranslation } from "@hooks";
import { Info } from "@assets/icons";
import { CircularProgress } from "@mui/material";
import { useAddPdfImageMutation } from "@Pdf/hooks/query/useAddPdfImagesQuery";
import { toast } from "react-toastify";

const PdfTemplatePickerAddItem: FC = () => {
  const [submitting, setSubmitting] = useState(false);
  const { t } = useAppTranslation();

  const { mutate } = useAddPdfImageMutation({
    onSuccess: async () => {
      toast.success(t("pdf.upload_image_success"));
      setSubmitting(false);
    },
    onError: () => {
      toast.error(t("pdf.upload_image_error"));
      setSubmitting(false);
    },
  });

  const onChange = async (event: ChangeEvent) => {
    if (submitting) return;

    const file = (event.target as HTMLInputElement).files?.[0] as File;

    if (!file) return;

    if (file.size > 5242880) {
      toast.error(t("pdf.upload_image_too_large"));
      return;
    }

    if (file.type !== "image/png" && file.type !== "image/jpeg") {
      toast.error(t("pdf.upload_image_type"));
      return;
    }

    setSubmitting(true);

    const formData = new FormData();
    formData.set("file", file);

    mutate(formData);
  };
  return (
    <PdfTemplatePickerAddItemStyled>
      {submitting && (
        <div className="grid place-items-center flex-1">
          <CircularProgress />
        </div>
      )}
      {!submitting && (
        <PdfTemplatePickerAddItemContainerStyled>
          <PdfTemplatePickerAddItemTextContainerStyled>
            <UploadFile
              size={""}
              width={"24px"}
              height={"24px"}
              style={{ marginBottom: "16px" }}
            />
            <PdfTemplatePickerAddItemTitleStyled>
              {t("pdf.add_photo")}
            </PdfTemplatePickerAddItemTitleStyled>
            <PdfTemplatePickerAddItemSubtitleStyled>
              PNG, JPG (max 5MB)
            </PdfTemplatePickerAddItemSubtitleStyled>
            <PdfTemplatePickerAddItemSubtitleStyled>
              min 2480x3508px
            </PdfTemplatePickerAddItemSubtitleStyled>
            <PdfTemplatePickerAddItemInfoStyled>
              <Info
                style={{
                  fill: "#727272",
                  flexShrink: 0,
                  margin: "4px 4px",
                }}
                size={""}
                width={"16px"}
                height={"16px"}
              />
              <PdfTemplatePickerAddItemSubtitleStyled
                style={{ marginTop: "4px" }}
              >
                {t("pdf.add_photo_info")}
              </PdfTemplatePickerAddItemSubtitleStyled>
            </PdfTemplatePickerAddItemInfoStyled>
          </PdfTemplatePickerAddItemTextContainerStyled>
          <PdfTemplatePickerAddItemInputStyled
            type="file"
            accept="image/png, image/jpeg"
            size={5242880}
            onChange={onChange}
          />
        </PdfTemplatePickerAddItemContainerStyled>
      )}
    </PdfTemplatePickerAddItemStyled>
  );
};

export default PdfTemplatePickerAddItem;
