import { useAppParams } from "@hooks";
import { useDeleteCreatorDietMealItemsMutation } from "@hooks/queries/diets/creator";
import { useDeleteProgramDietMealItemsMutation } from "@hooks/queries/program/dietCreator";

export const useDeleteDietMealItemsMutation = () => {
  const { programId } = useAppParams();

  return programId
    ? useDeleteProgramDietMealItemsMutation(programId)
    : useDeleteCreatorDietMealItemsMutation();
};
