import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deletePdfMainImageAPI } from "@Pdf/api/pdfAPI";
import { MutationOptions } from "@hooks/queries";
import { pdfImagesQueryKey } from "@Pdf/hooks/query/usePdfImagesQuery";
import { PdfImageResponseType } from "@Pdf/types/pdfTypes";

const useDeletePdfImageMutation = (options: MutationOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation(deletePdfMainImageAPI, {
    ...options,
    onSuccess: (_, id) => {
      queryClient.setQueryData<Array<PdfImageResponseType>>(
        [pdfImagesQueryKey],
        oldData => {
          if (oldData) {
            return oldData.filter(i => i.id !== id);
          }

          return [];
        },
      );

      options.onSuccess?.();
    },
  });
};

export { useDeletePdfImageMutation };
