import { styled } from "@mui/material";

export const Title = styled("span")`
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const Header = styled("span")`
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
  padding-left: 16px;
`;
