import { styled } from "@mui/material";

interface PriceMonthlyProps {
  value: number;
  showNet?: boolean;
}
export const PriceNet = ({ value, showNet = false }: PriceMonthlyProps) => {
  return (
    <div className="flex items-end gap-[4px]">
      <Value>
        {value}zł {showNet && "netto"}
      </Value>
      <SmallText>/ miesiąc</SmallText>
    </div>
  );
};

const Value = styled("span")`
  font-family: Figtree;
  font-size: 40px;
  font-weight: 600;
  line-height: 40px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #2d1d51;
`;

const SmallText = styled("span")`
  font-family: Figtree;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #2d1d51;
`;
