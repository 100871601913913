import { useState } from "react";
import type { FC, MouseEvent } from "react";
import { Checkbox, Menu, MenuItem, Stack, Tooltip } from "@mui/material";
import { Plus } from "@assets/icons";
import {
  DietitianFilterStyledDiv,
  DietitianFilterStyledAvatarContainer,
  DietitianFilterStyledIconButton,
  DietitianFilterStyledAvatarMui,
  DietitianFilterTooltipWrapper,
} from "./DietitianFilter.styled";
import { Controller, useFormContext } from "react-hook-form";
import { useTaskFilterOptions } from "@hooks/tasks/useTaskFilterOptions";
import {
  findDietitian,
  handleToggleDietitian,
  processDietitians,
} from "./DietitianFilter.utils";
import { Avatar } from "@components/AvatarNew";
import { useFetchClinicDietitiansQuery } from "@hooks/queries/settings";
import { useAppTranslation } from "@hooks";

interface DietitianFilterProps {
  name?: string;
  setIsInitialLoading?: (value: boolean) => void;
}

export const DietitianFilter: FC<DietitianFilterProps> = ({
  name = "dietitiansIds",
  setIsInitialLoading,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useAppTranslation();
  const { control } = useFormContext();
  const { dietitiansOptions, isClinicAdmin, account } = useTaskFilterOptions();
  const { dietitians } = useFetchClinicDietitiansQuery({
    enabled: isClinicAdmin,
  });

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const avatarsCountToShow = 3;

  const getDietitianAvatar = (dietitianId: number) => {
    if (isClinicAdmin) {
      return dietitians?.find(({ id }) => id === dietitianId)?.avatar ?? "";
    }
    return account?.avatar ?? "";
  };

  return (
    <DietitianFilterStyledDiv>
      <DietitianFilterStyledAvatarContainer>
        <p className="font-semibold">{t("tasks.dietitian")}</p>
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange } }) => {
            const pickedDietitians = value || [];
            const { avatarsToShow, extraAvatarsCount, hiddenDietitians } =
              processDietitians(pickedDietitians, avatarsCountToShow);

            return (
              <>
                <Stack direction="row" spacing={-0.9}>
                  {avatarsToShow.map(id => {
                    const dietitian = findDietitian(dietitiansOptions, id);

                    return dietitian ? (
                      <Tooltip
                        key={id}
                        title={
                          <div className="h-[15px]">{dietitian.label}</div>
                        }
                        arrow
                        placement="top"
                      >
                        <div>
                          <Avatar
                            key={id}
                            image={getDietitianAvatar(Number(id))}
                            fullName={dietitian.label}
                            size="small"
                            className="border border-white"
                          />
                        </div>
                      </Tooltip>
                    ) : null;
                  })}
                  {extraAvatarsCount > 0 && (
                    <Tooltip
                      title={
                        <DietitianFilterTooltipWrapper className="custom-scrollbar custom-scrollbar-gray">
                          {hiddenDietitians.map(id => {
                            const dietitian = findDietitian(
                              dietitiansOptions,
                              id,
                            );
                            return (
                              dietitian && (
                                <div
                                  key={dietitian.id}
                                  className="flex items-center gap-2"
                                >
                                  <Avatar
                                    size="small"
                                    image={getDietitianAvatar(Number(id))}
                                    fullName={dietitian.label}
                                  />
                                  <span>{dietitian.label}</span>
                                </div>
                              )
                            );
                          })}
                        </DietitianFilterTooltipWrapper>
                      }
                      arrow
                      placement="top"
                    >
                      <DietitianFilterStyledAvatarMui size="small">
                        {`+${extraAvatarsCount}`}
                      </DietitianFilterStyledAvatarMui>
                    </Tooltip>
                  )}
                </Stack>
                <DietitianFilterStyledIconButton onClick={openMenu}>
                  <Plus width="10" height="10" />
                </DietitianFilterStyledIconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={closeMenu}
                >
                  {dietitiansOptions?.map(({ label, id }) => (
                    <MenuItem
                      key={id}
                      onClick={() => {
                        setIsInitialLoading?.(true);
                        handleToggleDietitian(pickedDietitians, id, onChange);
                      }}
                    >
                      <Checkbox checked={pickedDietitians.includes(id)} />
                      {label}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            );
          }}
        />
      </DietitianFilterStyledAvatarContainer>
    </DietitianFilterStyledDiv>
  );
};
