import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import * as yup from "yup";

export async function createCustomTag(
  newTagData: NewTag,
): Promise<ApiResponse<NewTagResponse>> {
  const data = await fetchData(
    `/dietitian/clinic/tags`,
    APIMethods.POST,
    newTagData,
  );
  await newTagResponseSchema.validate(data);
  return data;
}

const newTagResponseSchema = yup.object().shape({
  data: yup
    .object()
    .shape({
      id: yup.number().required(),
      name: yup.string().required(),
      nameEn: yup.string().nullable(),
      clinic: yup.object().shape({
        id: yup.number().nullable(),
      }),
      tagCategory: yup.object().shape({
        id: yup.number().required(),
      }),
    })
    .required(),
});

interface NewTagResponse {
  id: number;
  name: string;
  nameEn?: string;
  clinic: {
    id: number | null;
  };
  tagCategory: {
    id: number;
  };
}

export interface NewTag {
  tagCategory: {
    id: number;
  };
  descriptionPl: string;
  descriptionEn?: string;
}
