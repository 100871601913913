import { useTranslation } from "react-i18next";

export const ApiError = ({ message }: ApiErrorProps) => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex items-center p-8 font-roman">
      <p>{message ?? t("common.error")}</p>
    </div>
  );
};

interface ApiErrorProps {
  message?: string;
}
