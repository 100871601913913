import { NumberTypes } from "@typeDefinitions";

/**
 * Parses string value number to number
 * @param strValue String numeric value
 * @param parseTo number type flag
 * @returns parsed number
 */
export const parseValueToNumber = (
  strValue: string,
  parseTo: NumberTypes = NumberTypes.INT,
): number => {
  if (!strValue) return 0;
  if (parseTo === NumberTypes.FLOAT) return parseFloat(strValue);
  return parseInt(strValue);
};

export const parseValueToString = (numValue: number): string => {
  if (numValue === 0 || isNaN(numValue)) return "";
  return numValue.toString();
};
