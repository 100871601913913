import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const fetchDietProductItems = async (
  dietId: string,
  productId: string,
): Promise<ApiResponse<ProductItemDto[]>> => {
  const params = new URLSearchParams();
  params.set("productId", productId);

  const response = await fetchData(
    `/dietitian/diets/${dietId}/products-list-items`,
    APIMethods.GET,
    undefined,
    params,
  );

  return responseSchema.validate(response);
};

interface TranslationLangDto {
  lang: string;
  name: string;
}

interface MealDto {
  id: number;
  translations: TranslationLangDto[];
}

interface ItemDto {
  id: number;
  type: "product" | "recipe";
}

interface ItemTranslationDto {
  id: number;
  translations: TranslationLangDto[];
}
export interface RecipeDto extends ItemDto {
  type: "recipe";
  recipe: ItemTranslationDto;
}

export interface ProductDto extends ItemDto {
  type: "product";
  product: ItemTranslationDto;
}

export interface ProductItemDto {
  exchange: boolean;
  meal: MealDto;
  item: RecipeDto | ProductDto;
}

const translationLangSchema = yup.object().shape({
  lang: yup.string().required(),
  name: yup.string().ensure(),
});

const mealSchema = yup.object().shape({
  id: yup.number().required(),
  translations: yup.array().of(translationLangSchema).required(),
});

const itemTranslationSchema = yup.object().shape({
  id: yup.number().required(),
  translations: yup.array().of(translationLangSchema).required(),
});

const recipeSchema = yup.object().shape({
  id: yup.number().required(),
  type: yup.string<"recipe">().required(),
  recipe: itemTranslationSchema.required(),
});

const productSchema = yup.object().shape({
  id: yup.number().required(),
  type: yup.string<"product">().required(),
  product: itemTranslationSchema.required(),
});

const productItemSchema = yup.object().shape({
  exchange: yup.boolean().required(),
  meal: mealSchema.required(),
  item: yup.lazy(value => {
    if (value?.type === "recipe") return recipeSchema.required();
    return productSchema.required();
  }),
});

const responseSchema = yup.object().shape({
  data: yup.array().of(productItemSchema).required(),
});
