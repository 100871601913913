import {
  DietMealListItemCreatorDto,
  fetchCreatorDietMeals,
} from "@client/diets/creator";
import { QueryOptionsTyped } from "@hooks/queries/types";
import { useQuery } from "@tanstack/react-query";
import { ApiResponse } from "@typeDefinitions";

export const fetchCreatorDietMealsQueryKey = "fetchCreatorDietMealsQueryKey";

export const useFetchCreatorDietMealsQuery = (
  dietId: string,
  options?: QueryOptionsTyped<ApiResponse<DietMealListItemCreatorDto[]>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchCreatorDietMealsQueryKey, dietId],
    () => fetchCreatorDietMeals(dietId),
    options,
  );

  return {
    meals: data?.data,
    ...rest,
  };
};
