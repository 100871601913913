import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import * as yup from "yup";
import { ProgramDayDto, tagCategorySchema } from "@client/program";

import {
  LangDto,
  NutrientDto,
  nutrientSchema,
  TagExtendedDto,
  tagExtendedSchema,
} from "./common";

export const fetchClientPrograms2 = async (
  id: number | string,
  params?: URLSearchParams,
): Promise<ApiResponse<ClientProgram2Dto[]>> => {
  const response = await fetchData(
    `/dietitian/patients/${id}/programs`,
    APIMethods.GET,
    undefined,
    params,
  );

  return fetchClientProgramsResponse.validate(response);
};

export interface ClientProgram2Dto {
  createdBy: AuthorDto | null;
  endDate: Date | null;
  id: number;
  isActive: boolean;
  language: LangDto;
  nutrients: NutrientDto[];
  programDays: ProgramDayDto[];
  startDate: Date;
  tags: TagExtendedDto[];
  translations: TranslationDto[];
}

interface TranslationDto {
  lang: LangDto;
  name: string;
}

interface AuthorDto {
  firstName: string;
  lastName: string;
  id: number;
}

const translationSchema = yup.object().shape({
  lang: yup.mixed<LangDto>().oneOf(Object.values(LangDto)).required(),
  name: yup.string().ensure(),
});

const authorSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  id: yup.number().required(),
});

const programDayDietSchema = yup.object().shape({
  id: yup.number().required(),
  mealsCount: yup.number().required(),
  nutrients: yup.array().of(nutrientSchema).required(),
  tags: yup.array().of(tagExtendedSchema).required(),
  translations: yup.array().of(translationSchema).required().min(1),
  tagCategory: tagCategorySchema.required(),
});

const programDaySchema = yup.object().shape({
  diet: programDayDietSchema.nullable().default(null),
  frequency: yup.array().of(yup.number().required()).required(),
  id: yup.number().required(),
  translations: yup.array().of(translationSchema).required().min(1),
});

const clientProgram2Schema = yup.object().shape({
  createdBy: authorSchema.nullable().default(null),
  endDate: yup.date().nullable().default(null),
  id: yup.number().required(),
  isActive: yup.boolean().required(),
  language: yup.mixed<LangDto>().oneOf(Object.values(LangDto)).required(),
  nutrients: yup.array().of(nutrientSchema).required(),
  programDays: yup.array().of(programDaySchema).required(),
  startDate: yup.date().required(),
  tags: yup.array().of(tagExtendedSchema).required(),
  translations: yup.array().of(translationSchema).required().min(1),
});

const fetchClientProgramsResponse = yup.object().shape({
  data: yup.array().of(clientProgram2Schema).required(),
});
