import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import { validateAndFixNutrients } from "./utils/validateCatching";
import { Currencies } from "@utils/scheduleEvent";

export const fetchCurrencies = async (): Promise<
  ApiResponse<CurrenciesDto[]>
> => {
  const response = await fetchData(
    `/dietitian/clinic/currencies`,
    APIMethods.GET,
  );
  return await validateAndFixNutrients(fetchCurrenciesResponseSchema, response);
};

const currenciesSchema = yup.object().shape({
  currency: yup.string().required(),
  isDefault: yup.boolean().required(),
});

const fetchCurrenciesResponseSchema = yup.object().shape({
  data: yup.array().of(currenciesSchema).required(),
});

export interface CurrenciesDto {
  currency: Currencies;
  isDefault: boolean;
}
