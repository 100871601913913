import { useCallback } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { FormProvider } from "react-hook-form";

import { FullSurveyDto } from "@client/surveys";
import { OuterLabel } from "@components/OuterLabel";
import { useAppParams, useAppTranslation } from "@hooks";
import {
  fetchSurveyFullQueryKey,
  useUpdateSurveyMutation,
} from "@hooks/queries/surveys";
import { ApiResponse } from "@typeDefinitions";
import { AutosaveWatchComponent } from "@components/AutosaveWatchComponent";
import { FormSwitchMui } from "@components/FormSwitchMui";
import { FormTextField } from "@components/FormTextField";

import { StyledFormControlLabel } from "./IntroductionCard.styled";
import { QuestionnaireCardWrapper } from "./QuestionnaireCardWrapper";
import {
  IntroductionFormInputs,
  useIntroductionForm,
} from "./useIntroductionForm";

export const IntroductionCard = () => {
  const { t } = useAppTranslation();
  const { questionnaireId } = useAppParams();
  const { mutate, isLoading } = useUpdateSurveyMutation();

  const queryClient = useQueryClient();
  const data = queryClient.getQueryData<ApiResponse<FullSurveyDto>>([
    fetchSurveyFullQueryKey,
    questionnaireId,
  ]);

  const { control, ...rest } = useIntroductionForm(data?.data.introduction);

  const handleUpdateIntroduction = useCallback(
    (values: IntroductionFormInputs) => {
      if (!data?.data.introduction) return;

      const { langId, name, introduction, reviewed } = data.data;

      mutate({
        id: questionnaireId,
        payload: {
          langId,
          name,
          reviewed,
          introduction: {
            ...introduction,
            ...values,
          },
        },
      });
    },
    [data?.data],
  );

  return (
    <FormProvider control={control} {...rest}>
      <QuestionnaireCardWrapper
        title={t("questionnaires.introduction")}
        actions={
          <StyledFormControlLabel
            control={<FormSwitchMui control={control} name="on" />}
            label={t("questionnaires.visible_for_client")}
          />
        }
      >
        <OuterLabel label={t("common.title")}>
          <FormTextField
            name="title"
            control={control}
            size="small"
            fullWidth
          />
        </OuterLabel>
        <OuterLabel label={t("common.content")}>
          <FormTextField
            name="description"
            control={control}
            multiline
            size="small"
            fullWidth
          />
        </OuterLabel>

        <AutosaveWatchComponent
          isLoading={isLoading}
          onSubmit={handleUpdateIntroduction}
        />
      </QuestionnaireCardWrapper>
    </FormProvider>
  );
};
