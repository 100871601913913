import { styled } from "@mui/material";

export const Title = styled("span")`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;

export const IconWrapper = styled("div")`
  display: grid;
  place-items: center;
  font-size: 16px;
`;
