import { createContext, useContext, useState } from "react";
import type { Dispatch, FC, ReactNode, SetStateAction } from "react";
import { SortByEnum, GroupByEnum } from "@hooks/tasks/useTaskFilterOptions";

interface ModalContextType {
  hideFinished: boolean;
  groupBy: GroupByEnum;
  sortBy: SortByEnum;
  setHideFinished: Dispatch<SetStateAction<boolean>>;
  setGroupBy: Dispatch<SetStateAction<GroupByEnum>>;
  setSortBy: Dispatch<SetStateAction<SortByEnum>>;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const useTaskViewSettingsContext = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error(
      "useTaskViewSettingsContext must be used within a TasksViewSettingsContextProvider",
    );
  }

  return context;
};

export const TasksViewSettingsContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [hideFinished, setHideFinished] = useState(false);
  const [groupBy, setGroupBy] = useState(GroupByEnum.Status);
  const [sortBy, setSortBy] = useState(SortByEnum.PriorityDesc);

  return (
    <ModalContext.Provider
      value={{
        hideFinished,
        groupBy,
        sortBy,
        setHideFinished,
        setGroupBy,
        setSortBy,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
