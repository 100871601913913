import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { useAppTranslation } from "@hooks";
import { fetchDiet } from "@client";

export const fetchDietQueryKey = "fetchDietitianDietDetails";

export const useFetchDietitianDietDetails = (id: number, enabled = true) => {
  const { t } = useAppTranslation();
  const { data: dietDetails, ...rest } = useQuery(
    [fetchDietQueryKey, id],
    () => fetchDiet(id),
    {
      enabled,
      refetchOnWindowFocus: false,
      onError: e => {
        console.log(e);
        toast.error(t("common.error"));
      },
    },
  );
  return { dietDetails, ...rest };
};
