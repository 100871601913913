import { ReactNode, useEffect, useMemo, useState } from "react";

import { Chip, Collapse, TextField, useTheme } from "@mui/material";
import _, { compact, values } from "lodash";

import { ArrowDown } from "@assets/icons";
import { DeleteIcon } from "@assets/icons/Chip";
import { SelectOption } from "@components/FormAutocomplete";
import { useAppTranslation } from "@hooks";
import { useTagsNew } from "@hooks/useTagsNew";
import { DISABLED_SYSTEM_TAGS, filterTagsTypeDictionary } from "@utils/tagsNew";
import { SearchNoResults } from "@views/emptyStates/SearchNoResults";

import {
  AutocompleteStyled,
  CollapseWrapper,
  SelectedTagsWrapper,
  ShowTagsButton,
} from "./ExpandableTagAutocomplete.styled";
import { TagCategory } from "./TagCategory";

interface ExpandableTagAutocompleteProps {
  tagCategories: number[];
  selectedTags: string[];
  onChange: (value: string[]) => void;
  children?: ReactNode;
  readOnlyCategories?: number[];
  onFocus?: () => void;
  onBlur?: () => void;
  defaultExpanded?: boolean;
  searchFilter?: boolean;
}

interface SelectOptionTag extends SelectOption {
  systemId: number | null;
}

export const ExpandableTagAutocomplete = ({
  selectedTags,
  onChange,
  children,
  readOnlyCategories,
  tagCategories,
  onFocus,
  onBlur,
  defaultExpanded = false,
  searchFilter,
}: ExpandableTagAutocompleteProps) => {
  const {
    colors: { neutral },
  } = useTheme();
  const [showTags, setShowTags] = useState(defaultExpanded);
  const [inputValue, setInputValue] = useState("");
  const { t } = useAppTranslation();
  const { tagCategoryDictionary, tagNameDictionary, tagDictionary } =
    useTagsNew();

  const options: SelectOptionTag[] = useMemo(
    () =>
      _(tagCategories)
        .flatMap(id => tagCategoryDictionary.get(id)?.tags ?? [])
        .map(tag => ({
          id: tag.id.toString(),
          label: tag.name,
          systemId: tag.systemId,
        }))
        .uniqBy(tag => tag.id)
        .value(),
    [tagCategories, tagCategoryDictionary],
  );

  const isInReadOnlyCategory = (id: string) => {
    const isReadOnlyCategory = readOnlyCategories?.some(c =>
      tagCategoryDictionary.get(c)?.tags.some(t => t.id === Number(id)),
    );

    return !!isReadOnlyCategory;
  };

  const isReadOnlySystemTag = (systemId: number | null) =>
    !!systemId && DISABLED_SYSTEM_TAGS.includes(systemId);

  const readOnlyOptions = useMemo(
    () =>
      _(tagCategories)
        .flatMap(id => {
          const category = tagCategoryDictionary.get(id);
          if (!category) return [];
          return category.tags.filter(
            tag =>
              isInReadOnlyCategory(tag.id.toString()) ||
              isReadOnlySystemTag(tag.systemId),
          );
        })
        .map(tag => ({
          id: tag.id.toString(),
          label: tag.name,
          systemId: tag.systemId,
        }))
        .uniqBy(tag => tag.id)
        .value(),
    [tagCategories, tagCategoryDictionary],
  );

  const selectedTagsOptions = compact(
    selectedTags.map(tag =>
      options.find(option => option.id === tag.toString()),
    ),
  );

  const filteredCategories = useMemo(
    () =>
      filterTagsTypeDictionary(
        tagCategoryDictionary,
        tagCategories,
        searchFilter ? inputValue : undefined,
      ),
    [tagCategoryDictionary, tagCategories, inputValue, searchFilter],
  );

  const handleOnChange = (value: string[]) => {
    onChange(value);
    setInputValue("");
  };

  const handleRemoveTag = (id: string) => {
    onFocus?.();
    handleOnChange(selectedTags.filter(tag => tag !== id));
  };

  const handleClearTags = () => {
    onFocus?.();
    handleOnChange(
      selectedTags.filter(
        t =>
          !!tagDictionary.get(Number(t))?.systemId ||
          readOnlyOptions.some(o => o.id === t),
      ),
    );
  };

  const handleToggleTag = (id: number) => {
    const isSelected = selectedTags.some(tag => `${tag}` === id.toString());
    const tag = tagNameDictionary.get(Number(id));

    if (!isSelected && tag) {
      handleOnChange([...selectedTags, id.toString()]);
    }
    if (isSelected && tag) {
      handleOnChange(selectedTags.filter(tag => `${tag}` !== id.toString()));
    }
  };

  const hasDeletable = () =>
    selectedTagsOptions.some(
      t => !isInReadOnlyCategory(t.id) && !isReadOnlySystemTag(t.systemId),
    );

  useEffect(() => {
    if (
      selectedTagsOptions.filter(
        o => !readOnlyOptions.some(selected => selected.id === o.id),
      ).length === 0
    )
      onBlur?.();
  }, [selectedTagsOptions, readOnlyOptions]);

  return (
    <div className="grid gap-3 w-full">
      <div className="flex justify-between">
        <AutocompleteStyled
          onFocus={onFocus}
          onBlur={onBlur}
          size="small"
          noOptionsText={<SearchNoResults />}
          className="tagsInput"
          value={selectedTagsOptions}
          options={options}
          multiple
          disableClearable
          getOptionDisabled={o =>
            isInReadOnlyCategory(o.id) || isReadOnlySystemTag(o.systemId)
          }
          inputValue={inputValue}
          renderTags={(value: SelectOptionTag[]): ReactNode => (
            <SelectedTagsWrapper onMouseOut={onBlur}>
              {value.map(tag => (
                <Chip
                  key={tag.id}
                  label={tag.label}
                  onDelete={
                    isInReadOnlyCategory(tag.id) ||
                    isReadOnlySystemTag(tag.systemId)
                      ? undefined
                      : () => handleRemoveTag(tag.id)
                  }
                  variant="filled"
                  color="primary"
                />
              ))}
              {value.length && hasDeletable() && (
                <Chip
                  label={t("product.edit.clear_all")}
                  onDelete={handleClearTags}
                  deleteIcon={<DeleteIcon fill={neutral.light[100]} />}
                  variant="outlined"
                />
              )}
            </SelectedTagsWrapper>
          )}
          onChange={(_, value: SelectOption[]) => {
            onFocus?.();
            handleOnChange(value.map(tag => tag.id));
          }}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              onChange={v => setInputValue(v.target.value)}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.label}
            </li>
          )}
        />
        {children}
      </div>
      <div>
        <Collapse in={showTags}>
          <CollapseWrapper>
            {values(filteredCategories).map(category => (
              <TagCategory
                key={category.id}
                category={category}
                selectedTags={selectedTagsOptions}
                toggleTag={handleToggleTag}
                readOnly={readOnlyCategories?.includes(category.type)}
                onFocus={onFocus}
                onBlur={onBlur}
              />
            ))}
          </CollapseWrapper>
        </Collapse>
        <ShowTagsButton size="small" onClick={() => setShowTags(val => !val)}>
          {t("product.edit.tags_show")}
          <ArrowDown
            size="h-[16px] w-[16px]"
            className={showTags ? "rotate-180" : ""}
          />
        </ShowTagsButton>
      </div>
    </div>
  );
};
