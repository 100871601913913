import { BarChart, PieChart } from "./components";
import { memo, ReactNode } from "react";
import {
  StyledLinesSection,
  StyledPieChartSection,
  StyledWrapper,
} from "./DietBalanceChart.styled";

interface NutrientValue {
  name: string;
  value: number;
  maxValue: number;
  unit: string;
}

interface DietBalanceChartProps {
  macros: NutrientValue[];
  macrosCount?: number;
  micros?: NutrientValue[];
  daysView?: boolean;
  microPreview?: ReactNode;
}

export const DietBalanceChart = memo(
  ({
    macros,
    macrosCount = 4,
    micros = [],
    daysView,
    microPreview,
  }: DietBalanceChartProps) => {
    return (
      <StyledWrapper daysView={daysView}>
        <StyledPieChartSection>
          {macros.slice(0, macrosCount).map((macro, id) => (
            <PieChart
              {...macro}
              scale={id === 0 ? 150 : 130}
              colors={colors[id % colors.length]}
              key={id}
            />
          ))}
          {microPreview}
        </StyledPieChartSection>
        {!!micros?.length && (
          <StyledLinesSection daysView={daysView}>
            {micros.map((micro, id) => (
              <BarChart
                daysView={daysView}
                key={id}
                {...micro}
                colors={colors[id % colors.length]}
              />
            ))}
          </StyledLinesSection>
        )}
      </StyledWrapper>
    );
  },
);

const colors = [
  { fill: "#ffcc24", shadow: "#fff1d6" },
  { fill: "#801fff", shadow: "#e9d8ff" },
  { fill: "#fb931f", shadow: "#ffe4c7" },
  { fill: "#88d89c", shadow: "#e2f3e6" },
];
