import { MacrosText } from "@components/MacrosText";

import { DifferenceCellStyled } from "./DifferenceCell.styled";
import { useSelectedContext } from "./SelectedContext";

export const DifferenceCell = () => {
  const { nutrientsDifference, targetNutrients } = useSelectedContext();
  return (
    <DifferenceCellStyled>
      <MacrosText
        nutrients={nutrientsDifference}
        config={{
          zeros: true,
          pluses: true,
          precision: 1,
        }}
        targets={targetNutrients ?? undefined}
        targetVariant="percentage"
      />
    </DifferenceCellStyled>
  );
};
