import { NutrientsMonitoringResource } from "@client/resources/NutrientsMonitoringResource";

type TypeNutrient = Pick<
  NutrientsMonitoringResource,
  | "dietitianTargetNutrients"
  | "patientTargetNutrients"
  | "patientEatenNutrients"
>;

export const getSumNutrientValue = (
  data: NutrientsMonitoringResource[],
  nutrientId: number,
  from: keyof TypeNutrient,
) =>
  data.reduce(
    (accumulator, currentValue) =>
      accumulator +
      (currentValue[from].find(n => n.id === nutrientId)?.value ?? 0),
    0,
  );

export const getAverageNutrientValue = (
  data: NutrientsMonitoringResource[],
  nutrientId: number,
  from: keyof TypeNutrient,
) => getSumNutrientValue(data, nutrientId, from) / Math.max(data.length, 1);
