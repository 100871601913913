import { memo, MouseEventHandler, useCallback, useMemo } from "react";
import { FormProvider } from "react-hook-form";

import { Button } from "@mui/material";

import { Plus } from "@assets/icons";
import {
  useAppParams,
  useAppTranslation,
  useModalState,
  useNutrients,
} from "@hooks";

import { AutosaveOnBlurProvider } from "@components/AutosaveOnBlur";
import {
  useFetchDietNutrientsNormQuery,
  useUpdateDietNutrientsNormCombinedMutation,
} from "../../hooks";
import { Label } from "../MealScheduleCard/ScheduleRow";
import { CardWrapper } from "../common";
import { FormNormAutocomplete } from "./FormNormAutocomplete";
import { ButtonStyled } from "./NutrientsCard.styled";
import { SelectedNutrientsList } from "./SelectedNutrientsList";
import { SidePanel } from "./SidePanel";
import {
  NutrientNormInputs,
  mapNutrientsNormForm,
  mapNutrientsNormRequest,
  useNutrientsNormForm,
} from "./useNutrientsNormForm";
import { Nutrient } from "@typeDefinitions";

interface NutrientsCardProps {
  targetMacros: Nutrient[];
}
export const NutrientsCard = ({ targetMacros }: NutrientsCardProps) => {
  const { t } = useAppTranslation();
  const [open, onOpen, onClose] = useModalState();
  const { dietId } = useAppParams();
  const { norm } = useFetchDietNutrientsNormQuery(dietId);
  const { nutrientDict } = useNutrients();
  const { mutate, isLoading } = useUpdateDietNutrientsNormCombinedMutation();

  const mappedNormForm = useMemo(
    () => mapNutrientsNormForm(norm, Array.from(nutrientDict.keys())),
    [norm, nutrientDict],
  );

  const form = useNutrientsNormForm(mappedNormForm, true);

  const toggleOpen: MouseEventHandler<HTMLButtonElement> = useCallback(
    e => {
      e.stopPropagation();
      open ? onClose() : onOpen();
    },
    [open, onClose, onOpen],
  );

  const handleSubmit = (data: NutrientNormInputs) => {
    const updatedNutrients = data.nutrients.map(nutrient => {
      const matchingMacro = targetMacros.find(
        macro => macro.id === nutrient.id,
      );
      if (matchingMacro) {
        return {
          ...nutrient,
          value: matchingMacro.value.toString(),
          show: false,
        };
      }
      return nutrient;
    });

    mutate({
      dietId,
      payload: mapNutrientsNormRequest({
        ...data,
        nutrients: updatedNutrients,
      }),
    });
  };

  return (
    <>
      <AutosaveOnBlurProvider>
        <FormProvider {...form}>
          <AutosaveOnBlurProvider.Watch
            isLoading={isLoading}
            onSubmit={handleSubmit}
          />
          <CardWrapper
            title={t("diet.other_nutrients")}
            extras={
              <Button onClick={toggleOpen}>{t("diet.show_norms")}</Button>
            }
          >
            <Label label={t("diet.select_norms")} className="w-[304px]">
              <FormNormAutocomplete />
            </Label>

            <SelectedNutrientsList
              mappedNormForm={mappedNormForm}
              norm={norm}
            />

            <ButtonStyled onClick={toggleOpen}>
              <Plus />
              {t("diet.select_to_track")}
            </ButtonStyled>
          </CardWrapper>
        </FormProvider>
      </AutosaveOnBlurProvider>
      <SidePanel open={open} onClose={onClose} />
    </>
  );
};
