import { useQuery } from "@tanstack/react-query";

import { fetchScheduleRecipe } from "@client/schedule";
import { buildDefaultQueryOptions } from "../common";

export const useFetchScheduleRecipeQuery = (
  programId: string | number,
  dayId: string | number,
  mealId: string | number,
) => {
  const { data, ...rest } = useQuery(
    [fetchScheduleRecipeQueryKey, programId, dayId, mealId],
    () => fetchScheduleRecipe(Number(programId), Number(dayId), Number(mealId)),
    buildDefaultQueryOptions({
      refetchOnWindowFocus: false,
    }),
  );
  return { recipe: data?.data, ...rest };
};

export const fetchScheduleRecipeQueryKey = "fetchScheduleRecipeQueryKey";
