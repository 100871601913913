import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const updateDietCreatorTags = async (
  dietId: string,
  payload: UpdateDietCreatorTagsPayloadIf,
  version: string,
) => {
  console.log(payload);
  return await fetchData(
    `/dietitian/creator-2/diets/${dietId}/tags`,
    APIMethods.PUT,
    payload,
    undefined,
    { "Alloweat-Creator-Version": version },
  );
};

export interface UpdateDietCreatorTagsPayloadIf {
  tags: number[];
}
