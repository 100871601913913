import * as yup from "yup";

import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";

import {
  ActionsDto,
  actionsSchema,
  SectionCategoryDto,
  sectionSearchCategorySchema,
} from "./common";
import { validateAndFixNutrients } from "./utils/validateCatching";

export const fetchSection = async (
  sectionId: number,
): Promise<ApiResponse<FetchSectionResponse>> => {
  const data = await fetchData(
    `/dietitian/program-section-templates/${sectionId}`,
    APIMethods.GET,
  );
  return await validateAndFixNutrients(fetchSectionSchema, data);
};

const fetchSectionSchema = yup.object().shape({
  data: yup.object().shape({
    id: yup.number().required(),
    title: yup.string().required(),
    titleEn: yup.string().nullable(),
    content: yup.string().required(),
    contentEn: yup.string().nullable(),
    programSectionCategory: sectionSearchCategorySchema.required(),
    reviewed: yup.boolean().required(),
    actions: actionsSchema.required(),
  }),
});

interface FetchSectionResponse {
  id: number;
  title: string;
  titleEn?: string;
  content: string;
  contentEn?: string;
  programSectionCategory: SectionCategoryDto;
  reviewed: boolean;
  actions: ActionsDto;
}
