import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material";

export const useTitleSubstring = (
  mobile?: number,
  small?: number,
  tablet?: number,
  middle?: number,
  desk?: number,
) => {
  const { breakpoints } = useTheme();
  const isMatchesMobile = useMediaQuery(`${breakpoints.down("mobile")}`);
  const isMatchesSmall = useMediaQuery(
    `${breakpoints.between("mobile", "small")}`,
  );
  const isMatchesSmallTab = useMediaQuery(
    `${breakpoints.between("small", "tablet")}`,
  );
  const isMatchesMiddle = useMediaQuery(
    `${breakpoints.between("tablet", "laptop")}`,
  );
  const isMatchesDesktop = useMediaQuery(`${breakpoints.up("desktop")}`);

  return {
    changedTitleFactory: (title: string) => {
      if (mobile && isMatchesMobile && title.length >= mobile) {
        return `${title.substring(0, mobile)}...`;
      } else if (small && isMatchesSmall && title.length >= small) {
        return `${title.substring(0, small)}...`;
      } else if (tablet && isMatchesSmallTab && title.length >= tablet) {
        return `${title.substring(0, tablet)}...`;
      } else if (middle && isMatchesMiddle && title.length >= middle) {
        return `${title.substring(0, middle)}...`;
      } else if (desk && isMatchesDesktop && title.length >= desk) {
        return `${title.substring(0, desk)}...`;
      } else {
        return title;
      }
    },
  };
};
