import { useMemo } from "react";

import _, { round, sumBy } from "lodash";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Tooltip, Typography } from "@mui/material";

import { useAppTranslation, useMeasures, useNutrientDictionary } from "@hooks";

import {
  MicrosTableCellTitle,
  MicrosValue,
  StickyProductCell,
  StickyTableCell,
  StickyTopHeaderTableCell,
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableRow,
  TopHeaderTableCell,
} from "./RecipeTable.styled";
import { ProductCell } from "./ProductCell";

import { useVisibleNutrients } from "@context/VisibleNutrientsContext/VisibleNutrientsContext";
import { MicrosRow } from "@components/RecipeCompositionSection/MicrosRow";
import { RecipeSumRow } from "@components/RecipeCompositionSection/RecipeSumRow";
import { Row } from "@components/RecipeCompositionSection/RecipeCompositionSection";

import { calcProportionalNutrients } from "./nutrientsUtils";
import { Nutrient } from "@typeDefinitions";
import { GLYCEMIC_INDEX_ID } from "@utils";

interface RecipeTableProps {
  rows: Row[];
  portionRatio: number;
  recipeNutrients: Nutrient[];
  hideMicros?: boolean;
  calculateNutrients?: boolean;
  stickyHeader?: boolean;
}

export const RecipeTable = ({
  rows,
  portionRatio,
  recipeNutrients,
  hideMicros = false,
  calculateNutrients = true,
  stickyHeader = true,
}: RecipeTableProps) => {
  const { t, isPolishChosen } = useAppTranslation();
  const { getMeasure } = useMeasures();

  const { nutrientDictionary } = useNutrientDictionary();
  const getNutrient = (id: number) =>
    nutrientDictionary[id] ? nutrientDictionary[id] : undefined;
  const { nutrients: visibleNutrients } = useVisibleNutrients();
  const visibleNutrientsMapped = visibleNutrients?.map(n => Number(n)) ?? [];

  const nutrientsSum = useMemo(
    () =>
      _(recipeNutrients)
        .map(n => {
          if (n.id === GLYCEMIC_INDEX_ID) return n;
          return {
            id: n.id,
            value: n.value * portionRatio,
          };
        })
        .value(),
    [recipeNutrients, _, portionRatio],
  );
  const gramsSum = useMemo(
    () =>
      _(rows)
        .map(row => row.grams * portionRatio)
        .sum(),
    [rows, _, portionRatio],
  );

  return (
    <>
      <TableContainer>
        <StyledTable
          padding="none"
          aria-label="simple table"
          compact={hideMicros}
        >
          <TableHead>
            <TableRow>
              <TopHeaderTableCell align="center" colSpan={1} />
              <TopHeaderTableCell colSpan={3} />
              {!hideMicros && !!visibleNutrients?.length && (
                <StickyTopHeaderTableCell align="center">
                  <Typography variant="button">
                    {t("recipe.ingredients_table.micros")}
                  </Typography>
                </StickyTopHeaderTableCell>
              )}
            </TableRow>
            <StyledTableRow>
              <StickyTableCell sticky={stickyHeader}>
                {t("recipe.ingredients_table.product")}
              </StickyTableCell>
              <TableCell align="center">
                {t("recipe.ingredients_table.grams")}
              </TableCell>
              <TableCell align="center">
                {t("recipe.ingredients_table.amount")}
              </TableCell>
              <TableCell align="center">
                {t("recipe.ingredients_table.measure")}
              </TableCell>

              {!hideMicros &&
                visibleNutrientsMapped.map(id => {
                  const nutrient = getNutrient(id);
                  return (
                    <Tooltip key={id} title={nutrient?.name}>
                      <MicrosTableCellTitle align="center" key={id}>
                        {nutrient?.short}
                      </MicrosTableCellTitle>
                    </Tooltip>
                  );
                })}
            </StyledTableRow>
          </TableHead>
          <StyledTableBody>
            {rows.map((row, i) => {
              const calculatedNutrients = calculateNutrients
                ? calcProportionalNutrients(
                    row.food.nutrients,
                    row.grams,
                    portionRatio,
                  )
                : row.food.nutrients;

              const calculatedGrams = row.grams * portionRatio;
              const measureGrams = row.food.measures.find(
                measure => measure.id === row.foodMeasureId,
              )?.grams;
              const measureCount = calculatedGrams / (measureGrams || 1);

              return (
                <TableRow key={i}>
                  <StickyProductCell
                    withBorder
                    component="th"
                    scope="row"
                    sticky={stickyHeader}
                  >
                    <ProductCell
                      name={
                        (isPolishChosen
                          ? row.food.descriptionPl
                          : row.food.description ?? row.food.descriptionPl) ??
                        ""
                      }
                      nutrients={calculatedNutrients}
                      productId={row.food.id}
                    />
                  </StickyProductCell>

                  <TableCell align="center">
                    {calculatedGrams.toFixed()} g
                  </TableCell>
                  <TableCell align="center">{round(measureCount, 1)}</TableCell>
                  <StyledTableCell
                    withBorder={!!visibleNutrients.length}
                    align="center"
                  >
                    {getMeasure(row.foodMeasureId)?.name}
                  </StyledTableCell>

                  {!hideMicros && (
                    <MicrosRow
                      nutrients={calculatedNutrients}
                      visibleNutrients={visibleNutrientsMapped}
                    />
                  )}
                </TableRow>
              );
            })}
            <TableRow>
              <StickyProductCell
                purple
                withBorder
                component="th"
                scope="row"
                sticky={stickyHeader}
              >
                <RecipeSumRow nutrients={nutrientsSum} />
              </StickyProductCell>
              <TableCell align="center">
                <MicrosValue>{gramsSum.toFixed()} g</MicrosValue>
              </TableCell>
              <TableCell />
              <StyledTableCell withBorder={!visibleNutrients} />

              {!hideMicros && (
                <MicrosRow
                  nutrients={nutrientsSum}
                  visibleNutrients={visibleNutrientsMapped}
                />
              )}
            </TableRow>
          </StyledTableBody>
        </StyledTable>
      </TableContainer>
    </>
  );
};
