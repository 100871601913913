import { useContext } from "react";
import { FieldValues } from "react-hook-form";

import {
  IMuiTimeDatePickerProps,
  MuiTimeDatePicker,
} from "@components/MuiTimeDatePicker";

import { BlurContext } from "./BlurContext";

export const DatePickerWrapper = <T extends FieldValues>(
  props: IMuiTimeDatePickerProps<T>,
) => {
  const { handleBlur, handleFocus } = useContext(BlurContext);

  return (
    <MuiTimeDatePicker {...props} onBlur={handleBlur} onFocus={handleFocus} />
  );
};
