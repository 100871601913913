import { useCallback } from "react";

import { Dialog } from "@mui/material";

import { useAppTranslation } from "@hooks";
import { FormTextField } from "@components/FormTextField";
import {
  useFetchClinicNameQuery,
  usePatchClinicNameMutation,
} from "@hooks/queries/settings";
import { BrandNameFormInput, useBrandNameForm } from "@hooks/settings";

import {
  ActionButtonsWrapper,
  EditBrandNameModalWrapper,
  HeaderStyled,
  StyledCancelBtn,
  StyledSubmitBtn,
} from "./EditBrandNameModal.styled";

interface EditBrandNameModalProps {
  open: boolean;
  onClose: () => void;
}

export const EditBrandNameModal = ({
  open,
  onClose,
}: EditBrandNameModalProps) => {
  const { t } = useAppTranslation();
  const { name } = useFetchClinicNameQuery();
  const { mutate: updateName } = usePatchClinicNameMutation({
    onSuccess: onClose,
  });
  const { control, handleSubmit, reset } = useBrandNameForm(name);

  const closeWithoutSubmit = useCallback(() => {
    onClose();
    reset(name);
  }, [onClose, name]);
  const submit = useCallback(
    (name: BrandNameFormInput) => updateName(name.name),
    [updateName],
  );

  return (
    <Dialog open={open} onClose={closeWithoutSubmit}>
      <EditBrandNameModalWrapper>
        <HeaderStyled>{t("office_modal.edit_office_name_title")}</HeaderStyled>
        <FormTextField
          control={control}
          name="name"
          size="small"
          label={t("company_settings.brand")}
          variant="outlined"
        />

        <ActionButtonsWrapper>
          <StyledCancelBtn variant="contained" onClick={closeWithoutSubmit}>
            {t("common.cancel")}
          </StyledCancelBtn>
          <StyledSubmitBtn variant="contained" onClick={handleSubmit(submit)}>
            {t("common.save")}
          </StyledSubmitBtn>
        </ActionButtonsWrapper>
      </EditBrandNameModalWrapper>
    </Dialog>
  );
};
