import { useMemo } from "react";
import { FormProvider } from "react-hook-form";

import {
  FoodGroupResourceDto,
  SystemQuestionDto,
  UserPreferencesFoodLikedAnswerResourceDto,
} from "@client/surveys";
import { useUpdateSingedSurveyMutation } from "@hooks/queries/surveys";

import { useUpdateValidationContext } from "../../context/useUpdateValidationContext";
import { useParseUrl } from "../../useParseUrl";
import { AutosaveOnBlurWatch } from "../AutosaveOnBlurWatch";
import { BlurContextProvider } from "../BlurContext";
import { Question } from "../Question/Question";
import {
  FoodAnswerFormInputs,
  mapFoodAnswerForm,
  mapFoodAnswerRequest,
  useFoodAnswerForm,
} from "../forms";
import { FoodRadioGroup } from "./FoodRadioGroup";

interface SystemQuestionUserPreferencesFoodLikedProps {
  question: SystemQuestionDto;
  answer?: UserPreferencesFoodLikedAnswerResourceDto;
  listIndex: number;
  totalQuestions: number;
  readOnly?: boolean;
  options?: FoodGroupResourceDto[];
}

export const SystemQuestionUserPreferencesFoodLiked = ({
  question: {
    title,
    config: { required },
    id,
  },
  answer,
  readOnly,
  options,
  ...rest
}: SystemQuestionUserPreferencesFoodLikedProps) => {
  const { url } = useParseUrl();
  const mappedForm = useMemo(
    () => mapFoodAnswerForm(answer?.answer),
    [answer?.answer],
  );
  const { formState, handleSubmit, control, ...form } = useFoodAnswerForm(
    mappedForm,
    required,
  );
  const { mutate, isLoading } = useUpdateSingedSurveyMutation();

  const onSubmit = (data: FoodAnswerFormInputs) => {
    if (url) mutate({ payload: mapFoodAnswerRequest(data, id), url });
  };

  const { errors, touchedFields } = formState;
  const hasError = !!Object.values(errors).length;

  useUpdateValidationContext(id, touchedFields, handleSubmit);
  return (
    <FormProvider
      formState={formState}
      handleSubmit={handleSubmit}
      control={control}
      {...form}
    >
      <BlurContextProvider>
        <Question
          {...rest}
          title={title}
          description={null}
          required={required}
          hasError={hasError}
          readOnly={readOnly}
        >
          <FoodRadioGroup readOnly={readOnly} options={options} />
        </Question>

        <AutosaveOnBlurWatch onSubmit={onSubmit} isLoading={isLoading} />
      </BlurContextProvider>
    </FormProvider>
  );
};
