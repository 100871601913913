import { useController } from "react-hook-form";

import { ExpandableTagAutocomplete } from "@components/ExpandableTagAutocomplete";
import { OuterLabel } from "@components/OuterLabel";
import { useAppTranslation } from "@hooks";
import { SPECIFIC_TAG_CATEGORIES_TYPE, TagCategoryType } from "@utils/tagsNew";

import { useAutosaveOnBlurContext } from "@components/AutosaveOnBlur";
import { TagsInput } from "./useTagsForm";

export const TagsControlled = () => {
  const { t } = useAppTranslation();
  const {
    field: { value, onChange },
  } = useController<TagsInput, "tags">({ name: "tags" });
  const { onBlur, onFocus } = useAutosaveOnBlurContext();

  if (value === undefined) return <></>;

  return (
    <OuterLabel label={t("diet.select_tags")} className="max-w-[708px]">
      <ExpandableTagAutocomplete
        selectedTags={value}
        onChange={tags => onChange(tags)}
        tagCategories={SPECIFIC_TAG_CATEGORIES_TYPE["diet"]}
        readOnlyCategories={[TagCategoryType.ALLERGENS]}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </OuterLabel>
  );
};
