import { floor, round } from "lodash";

import { ENERGY_ID, MACROS_ARRAY } from "@utils/macros";
import { NutrientDictionary } from "@hooks";
import { NutrientDto, ProgramDayNutrientDto } from "@client";
import { DayDto, ProgramMealDto } from "@client/program";

const getNutrientName = (id: number, nutrientDictionary: NutrientDictionary) =>
  nutrientDictionary[id]?.name ?? "";

const getNutrientValue = (id: number, simpleDietNutrients: NutrientDto[]) =>
  round(simpleDietNutrients.find(n => n.id === id)?.value ?? 0);

const getNutrientMaxValue = (
  id: number,
  programDayNutrients: ProgramDayNutrientDto[],
) => floor(programDayNutrients.find(n => n.id === id)?.value ?? 0);

const getNutrientUnit = (id: number, nutrientDictionary: NutrientDictionary) =>
  nutrientDictionary[id]?.unit ?? "";

export const createChartMacros = (
  nutrientDictionary: NutrientDictionary,
  simpleDietNutrients: NutrientDto[],
  programDayNutrients: ProgramDayNutrientDto[],
) =>
  MACROS_ARRAY.map(id => ({
    name: getNutrientName(id, nutrientDictionary),
    value: getNutrientValue(id, simpleDietNutrients),
    maxValue: getNutrientMaxValue(id, programDayNutrients),
    unit: getNutrientUnit(id, nutrientDictionary),
  }));

export const createChartMicros = (
  nutrientDictionary: NutrientDictionary,
  simpleDietNutrients: NutrientDto[],
  programDayNutrients: ProgramDayNutrientDto[],
) =>
  programDayNutrients
    .filter(({ visible, id }) => visible && !MACROS_ARRAY.includes(id))
    .map(({ id }) => ({
      name: getNutrientName(id, nutrientDictionary),
      value: getNutrientValue(id, simpleDietNutrients),
      maxValue: getNutrientMaxValue(id, programDayNutrients),
      unit: getNutrientUnit(id, nutrientDictionary),
    }));

export const validateDayMeals = (
  dayMeals: ProgramMealDto[] | undefined,
): boolean => {
  const mealsNutrients = dayMeals?.map(meal => meal.nutrients);

  if (!mealsNutrients) return false;

  return (
    mealsNutrients.every(nutrients => {
      const kcal = nutrients.find(nutrient => nutrient.id === ENERGY_ID);
      return nutrients.length === 0 || kcal?.value === 0;
    }) ||
    mealsNutrients.every(nutrients => {
      const kcal = nutrients.find(nutrient => nutrient.id === ENERGY_ID);
      return !!kcal?.value;
    })
  );
};

export const findInvalidDay = (program: { days: DayDto[] } | undefined) =>
  program?.days.find(day =>
    day.diet ? !validateDayMeals(day.diet.meals) : false,
  )?.id;
