import { useCallback, useState } from "react";
import { useController, UseFormReturn } from "react-hook-form";

import { SingleSelectFilterDefinition } from "@typeDefinitions";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";

import { BaseFilterSelect } from "./BaseFilterSelect";
import { FieldValues } from "react-hook-form/dist/types";

interface SingleFilterProps<T extends FieldValues> {
  form: UseFormReturn<T>;
  filter: SingleSelectFilterDefinition<T>;
}

export const SingleFilter = <T extends FieldValues>({
  form: { control },
  filter: { name, label, options },
}: SingleFilterProps<T>) => {
  const {
    field: { onChange },
    fieldState: { isDirty },
  } = useController({ name, control });
  const [open, setOpen] = useState(false);
  const onSelect = useCallback((value: string) => {
    onChange(value);
    setOpen(false);
  }, []);

  return (
    <BaseFilterSelect
      name={label}
      selected={isDirty}
      open={open}
      setOpen={setOpen}
    >
      <List>
        {options.map((option, i) => (
          <ListItem key={i} disablePadding>
            <ListItemButton onClick={() => onSelect(option.value)}>
              <ListItemText primary={option.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </BaseFilterSelect>
  );
};
