import { Tag } from "@assets/icons";
import {
  AppetizersPlate,
  Apple,
  BittenCookie,
  BittenMeat,
  BreadSlice,
  Cake,
  Carrot,
  CerealProducts,
  Children,
  Cup,
  Drop,
  Egg,
  Fish,
  HandWithHeart,
  HandWithPlus,
  IceCream,
  Jar,
  LeafySprout,
  OilBottle,
  Pepper,
  PersonWithCheck,
  PieceOfCake,
  Pills,
  Pizza,
  RestaurantDish,
  Shrimp,
  SoupBowl,
  SplitSeed,
  V,
  Wheat,
} from "@assets/icons/Categories";
import { SvgIconProps } from "@components/SvgIcon";
import { ProductCategories } from "@utils/products";

export const useProductsCategories = () => {
  const mappedIcons = new Map<
    ProductCategories,
    (props: SvgIconProps) => JSX.Element
  >([
    [ProductCategories.RESTAURANT_DISHES, RestaurantDish],
    [ProductCategories.READY_MEALS, Pizza],
    [ProductCategories.EGGS, Egg],
    [ProductCategories.MEAT, BittenMeat],
    [ProductCategories.DAIRY, PieceOfCake],
    [ProductCategories.BEVERAGES, Cup],
    [ProductCategories.NUTS_AND_SEEDS, SplitSeed],
    [ProductCategories.FRUITS, Apple],
    [ProductCategories.SEAFOOD, Shrimp],
    [ProductCategories.BREAD, BreadSlice],
    [ProductCategories.FOODS_FOR_VEGETARIANS_AND_VEGANS, V],
    [ProductCategories.BRAND_FOODS, Tag],
    [ProductCategories.CEREAL_PRODUCTS, Wheat],
    [ProductCategories.BREAKFAST_CEREALS, CerealProducts],
    [ProductCategories.SNACKS, BittenCookie],
    [ProductCategories.SPICES_AND_HERBS, Pepper],
    [ProductCategories.APPETIZERS_AND_SIDE_DISHES, AppetizersPlate],
    [ProductCategories.BEANS, LeafySprout],
    [ProductCategories.FISH, Fish],
    [ProductCategories.SWEETS, IceCream],
    [ProductCategories.SPREADS_AND_PASTES, Jar],
    [ProductCategories.SAUCES, Drop],
    [ProductCategories.SUPPLEMENTS, Pills],
    [ProductCategories.FATS_AND_OILS, OilBottle],
    [ProductCategories.VEGETABLES, Carrot],
    [ProductCategories.OWN, PersonWithCheck],
    [ProductCategories.BAKED_PRODUCTS, Cake],
    [ProductCategories.SOUPS, SoupBowl],
    [ProductCategories.DIETETIC_FOOD, HandWithHeart],
    [ProductCategories.BABY_FOODS, Children],
    [ProductCategories.FOODS_FOR_SPECIAL_MEDICAL_PURPOSES, HandWithPlus],
  ]);

  return {
    mappedIcons,
  };
};
