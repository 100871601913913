import { Typography, styled } from "@mui/material";

export const NameTypography = styled(Typography)`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const DetailsTypography = styled(Typography)`
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;

export const NameCellStyled = styled("div")`
  display: grid;
  gap: 8px;
  width: 100%;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
`;
