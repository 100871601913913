import { SvgIcon, SvgIconProps } from "@mui/material";

export const People = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M16.0908 19.3636V17.7272C16.0908 16.8593 15.746 16.0268 15.1323 15.4131C14.5185 14.7993 13.6861 14.4545 12.8181 14.4545H6.27266C5.40468 14.4545 4.57225 14.7993 3.9585 15.4131C3.34474 16.0268 2.99994 16.8593 2.99994 17.7272V19.3636"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M9.54543 11.1818C11.3529 11.1818 12.8181 9.71659 12.8181 7.90912C12.8181 6.10165 11.3529 4.6364 9.54543 4.6364C7.73795 4.6364 6.27271 6.10165 6.27271 7.90912C6.27271 9.71659 7.73795 11.1818 9.54543 11.1818Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M21 19.3636V17.7272C20.9995 17.0021 20.7581 16.2977 20.3139 15.7246C19.8696 15.1515 19.2476 14.7422 18.5455 14.5609"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M15.2727 4.74277C15.9767 4.92301 16.6006 5.33243 17.0462 5.90647C17.4918 6.48052 17.7337 7.18653 17.7337 7.91322C17.7337 8.6399 17.4918 9.34591 17.0462 9.91996C16.6006 10.494 15.9767 10.9034 15.2727 11.0837"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
