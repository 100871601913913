import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Close = (props: SvgIconProps) => (
  <SvgIcon width="10" height="11" viewBox="0 0 10 11" {...props}>
    <path
      d="M9.75 9.71875C10.0417 10.0729 10.0417 10.4271 9.75 10.7812C9.39583 11.0729 9.04167 11.0729 8.6875 10.7812L5 7.0625L1.28125 10.7812C0.927083 11.0729 0.572917 11.0729 0.21875 10.7812C-0.0729167 10.4271 -0.0729167 10.0729 0.21875 9.71875L3.9375 6L0.21875 2.25C-0.0729167 1.89583 -0.0729167 1.54167 0.21875 1.1875C0.572917 0.895833 0.927083 0.895833 1.28125 1.1875L5 4.9375L8.71875 1.21875C9.07292 0.927083 9.42708 0.927083 9.78125 1.21875C10.0729 1.57292 10.0729 1.92708 9.78125 2.28125L6.0625 6L9.75 9.71875Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
