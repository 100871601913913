import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Note = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
      <path
        d="M7 6.5C6.725 6.5 6.5 6.725 6.5 7V17C6.5 17.275 6.725 17.5 7 17.5H14V15C14 14.4469 14.4469 14 15 14H17.5V7C17.5 6.725 17.275 6.5 17 6.5H7ZM14 19H7C5.89688 19 5 18.1031 5 17V7C5 5.89688 5.89688 5 7 5H17C18.1031 5 19 5.89688 19 7V14V14.1719C19 14.7031 18.7906 15.2125 18.4156 15.5875L15.5875 18.4156C15.2125 18.7906 14.7031 19 14.1719 19H14Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
