import { useMemo } from "react";
import { TreeSelectOption } from "./TreeNode";
import { FlatTreeSelectOption } from "./TreeSelect";

export function useTreeSelectTitle(
  options: TreeSelectOption[],
  value?: string,
) {
  const flatOptions = useMemo(() => {
    const flattenOption = (
      os: TreeSelectOption[],
      parents: string[],
    ): FlatTreeSelectOption[] =>
      os.flatMap(o => [
        { value: o.value, title: o.title, parents },
        ...flattenOption(o.children, [...parents, o.title]),
      ]);
    return flattenOption(options, []);
  }, [options]);

  return useMemo(() => {
    const title = flatOptions.find(o => o.value === value)?.title ?? "";
    const parents = flatOptions.find(o => o.value === value)?.parents ?? [];
    const path = [...parents, title];
    return path.join(" ➔ ");
  }, [flatOptions, value]);
}
