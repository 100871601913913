import { FormProvider, useForm } from "react-hook-form";
import {
  ProgramFiltersFormPops,
  ProgramsFiltersFormDefaultValues,
} from "@views/dietician/Programs/ProgramsFiltersFormType";
import { ReactNode } from "react";

type PatientProgramChoiceGridFilterFormProps = {
  children: ReactNode;
};

const PatientProgramChoiceGridFilterForm = ({
  children,
}: PatientProgramChoiceGridFilterFormProps) => {
  const form = useForm<ProgramFiltersFormPops>({
    defaultValues: ProgramsFiltersFormDefaultValues,
  });

  return <FormProvider {...form}>{children}</FormProvider>;
};

export default PatientProgramChoiceGridFilterForm;
