import React, { ReactElement, useState } from "react";
import { TrashNew } from "@assets/icons";
import { useAppTranslation } from "@hooks";
import { ConfirmationModal } from "@components/ConfirmationModal";
import { TrashButton } from "@components/CustomTagsManageModal/CustomTagsManageModal.styled";
import { Trans } from "react-i18next";

export interface DeleteTagButtonProps {
  index: number;
  id: number | null;
  label: string;
  onDelete: (index: number) => void;
}

export const CustomTagDeleteButton = ({
  id,
  index,
  label,
  onDelete,
}: DeleteTagButtonProps): ReactElement => {
  const { t } = useAppTranslation();

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const handleDeleteClick = () => {
    if (!id) {
      onDelete(index);
      return;
    }

    setDeleteModalOpen(true);
  };

  const handleDeleteCancel = () => {
    setDeleteModalOpen(false);
  };

  const handleDeleteConfirm = () => {
    setDeleteModalOpen(false);
    onDelete(index);
  };

  return (
    <>
      <TrashButton onClick={handleDeleteClick}>
        <TrashNew className="text-primary" />
      </TrashButton>
      <ConfirmationModal
        open={deleteModalOpen}
        title={t("custom_tags.delete_modal.title")}
        description={
          <>
            <div>
              <Trans
                i18nKey={t("custom_tags.delete_modal.question", {
                  variable: label,
                })}
                components={{
                  bold: <strong />,
                }}
              />
            </div>
            <div>{t("custom_tags.delete_modal.description")}</div>
          </>
        }
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        confirmText={t("custom_tags.delete_modal.confirm")}
      />
    </>
  );
};
