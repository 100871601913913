import MealsSummaryCardOfDay from "@views/dietician/PatientMonitoring2/components/SummaryCard/Meals/MealsSummaryCardOfDay";
import MealsSummaryCardOfDays from "@views/dietician/PatientMonitoring2/components/SummaryCard/Meals/MealsSummaryCardOfDays";
import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";

const MealsSummaryCardFactory = () => {
  const { type } = useMonitoring();

  if (type.type === "day") {
    return <MealsSummaryCardOfDay />;
  }

  return <MealsSummaryCardOfDays />;
};

export default MealsSummaryCardFactory;
