import { uniqBy } from "lodash";

import { SelectOption } from "@components/FormAutocomplete";

export const mapProducts = (
  products: FoodProductDto[] | undefined,
  selectedProduct: SelectOption | null,
  isPolishChosen: boolean,
) => {
  const mapped =
    products?.map(product => ({
      id: product.id.toString(),
      label:
        (isPolishChosen ? product.descriptionPl : product.description) ??
        product.id.toString(),
    })) ?? [];

  return selectedProduct
    ? uniqBy([selectedProduct, ...mapped], product => product.id)
    : mapped;
};

export interface FoodProductDto {
  id: number;
  description?: string;
  descriptionPl: string;
}
