import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const EmptyCalendar = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="14" height="16" viewBox="0 0 14 16">
      <path
        d="M11.5498 2.80004H2.4499C1.73193 2.80004 1.1499 3.38207 1.1499 4.10003V13.2C1.1499 13.918 1.73193 14.5 2.4499 14.5H11.5498C12.2678 14.5 12.8498 13.918 12.8498 13.2V4.10003C12.8498 3.38207 12.2678 2.80004 11.5498 2.80004Z"
        stroke={props.fill ?? "currentColor"}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M9.59961 1.5V4.09999"
        stroke={props.fill ?? "currentColor"}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M4.39941 1.5V4.09999"
        stroke={props.fill ?? "currentColor"}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M1.1499 6.69994H12.8498"
        stroke={props.fill ?? "currentColor"}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};
