import { MagicWand } from "@assets/icons";
import { useAppParams, useAppTranslation, useModalState } from "@hooks";
import { useFetchCreatorDietMealsQuery } from "@hooks/queries/diets/creator";

import { AutogenerateDietModal } from "../AutogenerateModal";
import { ItemsContextProvider } from "../RecipesTab/ItemsContext";
import { ButtonStyled } from "./AutogenerateDietButton.styled";
import { Tooltip } from "@mui/material";

export const AutogenerateDietButton = () => {
  const { t } = useAppTranslation();
  const { dietId } = useAppParams();
  const [
    openAutogenerateDiet,
    onOpenAutogenerateDiet,
    onCloseAutogenerateDiet,
  ] = useModalState();
  const { meals } = useFetchCreatorDietMealsQuery(dietId);
  const disabledButton = meals?.some(({ type }) => type === null);

  return (
    <>
      {disabledButton ? (
        <Tooltip title={t("diet.diet_no_meal_type")}>
          <div>
            <ButtonStyled
              variant="contained"
              onClick={onOpenAutogenerateDiet}
              disabled
            >
              <MagicWand />
              {t("diet.generate_diet.title")}
            </ButtonStyled>
          </div>
        </Tooltip>
      ) : (
        <ButtonStyled
          variant="contained"
          onClick={onOpenAutogenerateDiet}
          disabled={disabledButton}
        >
          <MagicWand />
          {t("diet.generate_diet.title")}
        </ButtonStyled>
      )}

      <ItemsContextProvider>
        <AutogenerateDietModal
          open={openAutogenerateDiet}
          onClose={onCloseAutogenerateDiet}
          tags={[]}
        />
      </ItemsContextProvider>
    </>
  );
};
