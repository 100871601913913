import { fetchData } from "@utils/api";
import { FoodGroupTreeResponse } from "@typeDefinitions/responses/dictionaries";
import { APIMethods } from "@typeDefinitions";

export async function fetchFoodGroupsTree(): Promise<FoodGroupTreeResponse[]> {
  const { data } = await fetchData(
    `/dictionaries/food-groups/categories`,
    APIMethods.GET,
  );
  return data;
}
