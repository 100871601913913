import { BackendMeasurements } from "@typeDefinitions";
import * as yup from "yup";

export interface Visit {
  id: number;
  dateStart: string;
  dateEnd: string;
  generalData: {
    measurement: BackendMeasurements;
    note: string | null;
  };
  tags: VisitTag[];
  food: VisitFood[];
  foodGroups: VisitFoodGroup[];
  boxes: VisitBoxData[];
  files: FileData[];
  // isFirst?: boolean;
}

export interface FoodPayload {
  isFavourite: boolean;
}

export interface FileData {
  id: number;
  clinicId: number;
  collection: string;
  name: string;
  originalName: string;
  mineType: string;
  size: number;
  path: string;
  url: string;
}

export interface VisitFood {
  isFavourite: boolean;
  food: {
    id: number;
    name: string;
    nameEn: string;
  };
}

export interface VisitFoodGroup {
  isFavourite: boolean;
  foodGroup: {
    id: number;
    name?: string;
    nameEn: string;
  };
}

export interface VisitBoxData {
  value: string;
  box: {
    id: number;
  };
}

export interface VisitTag {
  id: number;
  clinicId: number | null;
  tagCategoryId: number;
  descriptionPl: string;
  descriptionEn: string;
  namePl: string;
  nameEn: string;
}

export interface Measurements {
  body: {
    weight: number | null;
    fat: number | null;
    targetWeight: number | null;
  };
  dateOfLastTest: string | null;
}

export const measurementsSchema = yup.object().shape({
  dateOfLastTest: yup.string().nullable().default(null),
  body: yup
    .object()
    .shape({
      weight: yup.number().nullable().default(null),
      fat: yup.number().nullable().default(null),
      targetWeight: yup.number().nullable().default(null),
    })
    .required(),
});

const visitFoodSchema = yup.object().shape({
  isFavourite: yup.boolean().required(),
  food: yup
    .object()
    .shape({
      id: yup.number().required(),
      name: yup.string().required(),
      nameEn: yup.string().ensure(),
    })
    .required(),
});

const visitFoodGroupSchema = yup.object().shape({
  isFavourite: yup.boolean().required(),
  foodGroup: yup
    .object()
    .shape({
      id: yup.number().required(),
      name: yup.string().ensure(),
      nameEn: yup.string().required(),
    })
    .required(),
});

const visitTagSchema = yup.object().shape({
  id: yup.number().required(),
  clinicId: yup.number().nullable().default(null),
  tagCategoryId: yup.number().required(),
  descriptionPl: yup.string().required(),
  descriptionEn: yup.string().required(),
  namePl: yup.string().required(),
  nameEn: yup.string().required(),
});
const visitFileSchema = yup.object().shape({
  id: yup.number().required(),
  clinicId: yup.number().required(),
  collection: yup.string().required(),
  name: yup.string().required(),
  originalName: yup.string().required(),
  mineType: yup.string().required(),
  size: yup.number().required(),
  path: yup.string().required(),
  url: yup.string().required(),
});

const visitBoxDataSchema = yup.object().shape({
  box: yup
    .object()
    .shape({
      id: yup.number().required(),
    })
    .required(),
  value: yup.string().ensure(),
});

export const visitSchema = yup.object().shape({
  id: yup.number().required(),
  dateStart: yup.string().required(),
  dateEnd: yup.string().required(),
  generalData: yup.object().shape({
    measurement: measurementsSchema,
    note: yup.string().nullable().default(null),
  }),
  tags: yup.array().of(visitTagSchema).required(),
  food: yup.array().of(visitFoodSchema).required(),
  foodGroups: yup.array().of(visitFoodGroupSchema).required(),
  boxes: yup.array().of(visitBoxDataSchema).required(),
  files: yup.array().of(visitFileSchema).required(),
  // isFirst: yup.boolean(),
});

export const fileDataSchema = yup.object().shape({
  id: yup.number().required(),
  clinicId: yup.number().required(),
  collection: yup.string().required(),
  name: yup.string().required(),
  originalName: yup.string().required(),
  mineType: yup.string().required(),
  size: yup.number().required(),
  path: yup.string().required(),
  url: yup.string().required(),
});
