import DietMealsTabs from "@components/ProgramSearchPreview/Components/DietMeals/DietMealsTabs";
import { DietProgramSearchPreview } from "@client/program/preview/fetchDietProgramPreview";

type MealsProps = {
  diet: DietProgramSearchPreview;
};

const DietMeals = ({ diet }: MealsProps) => {
  if (!diet.meals.length) {
    return null;
  }

  return <DietMealsTabs diet={diet} />;
};

export default DietMeals;
