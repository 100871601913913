import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import { TagResourceDto, tagResourceSchema } from "./searchRecipesNew";
import {
  ActionsDto,
  ImageDto,
  NutrientDto,
  actionsSchema,
  imageSchema,
  nutrientSchema,
} from "./common";
import { ClientContextDto, clientContextSchema } from "./diets/creator";

export const searchFood = async (
  params?: URLSearchParams,
): Promise<
  ApiResponse<(FoodSearchResourceDto | ClientFoodSearchResourceDto)[]>
> => {
  const response = await fetchData(
    "/dietitian/food/search-new",
    APIMethods.GET,
    undefined,
    params,
  );

  return await apiResponseSchema(params).validate(response);
};

const categorySchema = yup.object({
  description: yup.string().ensure(),
  descriptionEn: yup.string().ensure(),
  id: yup.number().required(),
});

const apiResponseSchema = (params?: URLSearchParams) => {
  const hasPatient = params?.has("patient");

  return yup.object({
    data: yup
      .array()
      .of(
        yup.object({
          id: yup.number().required(),
          namePl: yup.string().required(),
          nameEn: yup.string().nullable().default(null),
          favorite: yup.array().of(yup.number().required()).required(),
          tags: yup.array().of(tagResourceSchema).required(),
          macros: yup.array().of(nutrientSchema).required(),
          actions: actionsSchema.required(),
          clinicId: yup.number().nullable().default(null),
          hide: yup.boolean().required(),
          plOnly: yup.boolean().required(),
          reviewed: yup.boolean().required(),
          category: categorySchema.nullable().default(null),
          image: imageSchema.nullable().default(null),
          ...(hasPatient
            ? { clientContext: clientContextSchema.required() }
            : {}),
        }),
      )
      .required(),
  });
};

export interface FoodSearchResourceDto {
  id: number;
  namePl: string;
  nameEn: string | null;
  favorite: number[];
  tags: TagResourceDto[];
  macros: NutrientDto[];
  actions: ActionsDto;
  clinicId: number | null;
  hide: boolean;
  plOnly: boolean;
  reviewed: boolean;
  category: CategoryDto | null;
  image: ImageDto | null;
}

export interface ClientFoodSearchResourceDto extends FoodSearchResourceDto {
  clientContext: ClientContextDto;
}

interface CategoryDto {
  description: string;
  descriptionEn: string;
  id: number;
}
