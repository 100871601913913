import { MouseEvent, ReactNode, useState } from "react";

import { NOTIFICATION_EVENT_STATUS } from "@utils";

import {
  ButtonWrapper,
  Event,
  EventContentWrapper,
  EventName,
  EventTime,
  EventTypeWrapper,
  NotificationButton,
  StyledBadge,
} from "./EventItem.styled";
import { ThreeGreyPoints } from "@assets/icons";
import { useTheme } from "@mui/material";
import { NotificationsItemActions } from "@components/NotificationMenu/components/NotificationsItemActions";
import { usePostNotificationAsRead } from "@hooks/queries";

interface EventItemProps {
  status?: NOTIFICATION_EVENT_STATUS | any;
  createdAt?: string;
  name?: string;
  icon?: ReactNode;
  children?: ReactNode;
  event?: string;
  eventName?: string;
  id: string;
  readAt: string | null;
}

export const EventItem = ({
  status,
  createdAt,
  name,
  icon,
  children,
  event,
  eventName,
  id,
  readAt,
}: EventItemProps) => {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState<
    HTMLButtonElement | HTMLDivElement | null
  >(null);
  const open = Boolean(anchorEl);
  const { mutate, isLoading } = usePostNotificationAsRead(id);

  const handleClickActions = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <div onClick={() => mutate()} className="w-full">
        <EventTypeWrapper status={status}>
          <StyledBadge color="info" variant="dot" invisible={!!readAt}>
            {icon}
          </StyledBadge>
        </EventTypeWrapper>
        <EventContentWrapper className="notificationWrapper">
          <div>
            <EventName>{name}</EventName>
            <Event>
              {event} {eventName}
            </Event>
          </div>
          <ButtonWrapper onClick={handleClickActions}>
            <EventTime className="timeShower">{createdAt}</EventTime>
            <NotificationButton className="notificationMenu">
              <ThreeGreyPoints fill={palette.primary.main} size="small" />
            </NotificationButton>
          </ButtonWrapper>
          {children}
        </EventContentWrapper>
      </div>
      <NotificationsItemActions
        open={open}
        anchorEl={anchorEl}
        id={id}
        setAnchorEl={setAnchorEl}
        readAt={readAt}
      />
    </>
  );
};
