import { useState } from "react";

import { useMediaQuery, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { useAppParams, useAppTranslation } from "@hooks";

import {
  useFetchClientBalanceQuery,
  useUpdateClientBalanceMutation,
} from "@hooks/queries";
import { Weekday, WeekdaySelector } from "@components/WeekdaySelector";
import { ConfirmationWrapper } from "@components/ConfirmationWrapper";

import {
  MultiLanguageInputStyled,
  ProgramDayConfigStyled,
  WeekdaySection,
} from "./PatientEnergyBalance.styled";

interface BalanceProgramDayConfigProps {
  onChange: (active: Weekday[]) => void;
  selectedDays: number[];
  handleMultiLangChange: (lang: string, value?: string) => void;
  langValue: (lang: string) => string;
}

export const BalanceProgramDayConfig = ({
  onChange,
  selectedDays,
  handleMultiLangChange,
  langValue,
}: BalanceProgramDayConfigProps) => {
  const { t } = useAppTranslation();
  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(`${breakpoints.up("desktop")}`);

  const { patientId, dayId } = useAppParams();

  const { balanceData, isLoading } = useFetchClientBalanceQuery(patientId);
  const { mutate: updateBalance } = useUpdateClientBalanceMutation(patientId);
  const [isDeleting, setIsDeleting] = useState(false);

  const lastDay = balanceData?.days.length === 1;

  const handleDeleteBalanceDay = () => {
    if (!balanceData) return;
    setIsDeleting(true);
    updateBalance(
      {
        type: balanceData.type,
        days: balanceData.days.filter(day => day.id.toString() !== dayId),
      },
      {
        onSettled: () => setIsDeleting(false),
      },
    );
  };

  return (
    <>
      <ProgramDayConfigStyled>
        <MultiLanguageInputStyled
          label={t("program.days.name")}
          languages={["pl", "en"]}
          onChange={handleMultiLangChange}
          value={langValue}
        />
        <WeekdaySection>
          {isDesktop && (
            <WeekdaySelector
              active={selectedDays}
              size="large"
              onChange={onChange}
            />
          )}
          <ConfirmationWrapper
            onConfirm={handleDeleteBalanceDay}
            confirmationText={t("program.days.delete_confirmation")}
            submitText={t("common.yes")}
            disabled={isDeleting || isLoading || lastDay}
          >
            <LoadingButton
              variant="outlined"
              color="error"
              size="small"
              loading={isDeleting}
              disabled={lastDay}
            >
              {t("program.days.delete")}
            </LoadingButton>
          </ConfirmationWrapper>
        </WeekdaySection>
      </ProgramDayConfigStyled>
      {!isDesktop && (
        <WeekdaySelector
          active={selectedDays}
          size="large"
          onChange={onChange}
        />
      )}
    </>
  );
};
