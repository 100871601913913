import { ThemeProviderWrapperNew } from "themeNew";
import { BigCalendar } from "../Home/components/Calendar/BigCalendar/BigCalendar";
import { CalendarFiltersProvider } from "../Home/components/Calendar/_hooks/CalendarFilters.context";
import { ScheduleVisitModalProvider } from "../Home/components/ScheduleVisit/ScheduleVisitModalContext";
import { ScheduleActionModal } from "../Home/components/ScheduleVisit/_components/ScheduleActionModal";
import { CalendarColorsProvider } from "./CalendarColors.context";

export const Calendar = () => {
  return (
    <CalendarColorsProvider>
      <ScheduleVisitModalProvider>
        <CalendarFiltersProvider>
          <BigCalendar />
          <ThemeProviderWrapperNew>
            <ScheduleActionModal />
          </ThemeProviderWrapperNew>
        </CalendarFiltersProvider>
      </ScheduleVisitModalProvider>
    </CalendarColorsProvider>
  );
};
