import { DocWithApple } from "@assets/icons/DesignSystem";
import { MacrosText } from "@components/MacrosText";
import { TagWithIcon } from "@components/TagWithIcon";
import { useAppTranslation } from "@hooks";
import { Nutrient } from "@typeDefinitions";

import { CellWrapper, DetailsWrapper, Title } from "./NameColumnCell.styled";

interface NameColumnCellProps {
  name: string;
  nutrients: Nutrient[];
  onSelect: () => void;
  totalItems: number;
}
export const NameColumnCell = ({
  name,
  nutrients,
  onSelect,
  totalItems,
}: NameColumnCellProps) => {
  const { t } = useAppTranslation();

  return (
    <CellWrapper onClick={onSelect}>
      <Title>{name}</Title>
      <DetailsWrapper>
        <MacrosText nutrients={nutrients} />
        <TagWithIcon
          Icon={DocWithApple}
          label={t("collections.propositions", { count: totalItems })}
        />
      </DetailsWrapper>
    </CellWrapper>
  );
};
