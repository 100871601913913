import { useRef, useState } from "react";
import { OptionIf } from "../FiltersBuilder";
import { CustomChip } from "./TabsChipsFilter.styled";
import { CheckNew } from "@assets/icons";
import { ArrowDown } from "@assets/icons/Arrows";
import { StyledCustomChip } from "./TabsChipsFilter.styled";
import { useVisibleChips } from "@hooks/useVisibleChips";
import { Fragment } from "react";
export interface TabsChipsFilterProps {
  options: OptionIf[];
  selected?: string | string[];
  setSelected: (id: string | string[]) => void;
  multiple?: boolean;
  className?: string;
  isWrapped?: boolean;
}

export const TabsChipsFilter = ({
  options,
  selected,
  setSelected,
  multiple,
  className,
  isWrapped = true,
}: TabsChipsFilterProps) => {
  const [expanded, setExpanded] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const { visibleCount, collapsedVisibleCount, handleExpand } = useVisibleChips(
    {
      options,
      containerRef,
      expanded,
      setExpanded,
    },
  );

  const handleChange = (id: string | undefined) => {
    if (!id) return;

    if (multiple) {
      const currentSelected = Array.isArray(selected) ? selected : [];
      const newSelected = currentSelected.includes(id)
        ? currentSelected.filter(value => value !== id)
        : [...currentSelected, id];
      setSelected(newSelected);
    } else {
      setSelected(id);
    }
  };

  const Wrapper = isWrapped ? "div" : Fragment;
  const wrapperProps = isWrapped
    ? {
        className: `flex gap-[8px] flex-wrap ${className ?? ""}`,
        ref: containerRef,
      }
    : {};

  return (
    <Wrapper {...wrapperProps}>
      {options
        .slice(0, expanded ? options.length : visibleCount)
        .map(({ id, label }, index) => {
          const isSelected =
            id &&
            selected &&
            (Array.isArray(selected) ? selected.includes(id) : selected === id);

          return (
            <CustomChip
              key={id + label + index}
              data-chip="true"
              color={isSelected ? "primary" : "default"}
              onClick={() => handleChange(id)}
              variant="outlined"
              label={
                <div className="flex items-center gap-2">
                  {isSelected && <CheckNew size="w-[12px] h-[12px]" />}
                  {label}
                </div>
              }
            />
          );
        })}

      {!expanded && visibleCount < options.length && (
        <StyledCustomChip
          label={
            <p className="flex gap-2 items-centwr">
              <span>+{options.length - visibleCount}</span>
              <ArrowDown />
            </p>
          }
          onClick={handleExpand}
        />
      )}

      {expanded && (
        <StyledCustomChip
          label={
            <p className="flex gap-2 items-centwr">
              <span>-{collapsedVisibleCount}</span>
              <ArrowDown className="rotate-180" />
            </p>
          }
          onClick={handleExpand}
          sx={{ cursor: "pointer", color: "primary.main" }}
        />
      )}
    </Wrapper>
  );
};
