import { Card, styled } from "@mui/material";

export const TitleStyled = styled("span")`
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const CardStyled = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 510px;
  padding: 24px 16px;
  border-radius: 12px;
`;
