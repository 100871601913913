import { LoadingButton } from "@mui/lab";
import React from "react";
import { useAppTranslation } from "@hooks";
import { useFormState } from "react-hook-form";

type FormCancelButtonProps = {
  name?: string;
  onClick?: () => void;
};
const FormCancelButton = ({ name, onClick }: FormCancelButtonProps) => {
  const { t } = useAppTranslation();
  const { isSubmitting } = useFormState();

  return (
    <LoadingButton onClick={onClick} loading={isSubmitting} variant="outlined">
      {name ?? t("common.cancel")}
    </LoadingButton>
  );
};

export default FormCancelButton;
