import { Program } from "@typeDefinitions";
import { omit, pick } from "lodash";

export const GENERAL_INFORMATION_SECTION_CATEGORY = 1;
export const CUSTOM_SECTION_CATEGORY_ID = 7;

export const getProgramSteps = (
  program: Program,
): Array<Record<string, string | number>> => {
  const toolsPlain = pick(
    program,
    "hasDiets",
    "hasMonitoring",
    "hasEducation",
    "hasTasks",
  );
  const toolsParsed = Object.entries(toolsPlain)
    .map(([k, v]) => ({
      ...PROGRAMS_STEPS_MAP[k],
      value: v,
    }))
    .filter(el => el.value)
    .map(el => omit(el, "value"));
  return [...PROGRAM_BASIC_STEPS, ...toolsParsed];
};

export const PROGRAM_BASIC_STEPS = [
  {
    namePl: "Informacje podstawowe",
    nameEn: "Basic info",
    step: 1,
  },
  {
    namePl: "Ustawienia diety/makroskładników",
    nameEn: "Diet/macroelements settings",
    step: 2,
  },
];

export const PROGRAMS_STEPS_MAP: Record<
  string,
  Record<string, string | number>
> = {
  hasDiets: {
    namePl: "Ustawienia harmonogramu",
    nameEn: "Schedule settings",
    step: 3,
  },
  hasMonitoring: {
    namePl: "Ustawienia monitoringu",
    nameEn: "Monitoring settings",
    step: 4,
  },
  hasEducation: {
    namePl: "Ustawienia edukacji",
    nameEn: "Education settings",
    step: 5,
  },
  hasTasks: {
    namePl: "Ustawienia zadań",
    nameEn: "Tasks settings",
    step: 6,
  },
};
