import React from "react";
import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const CheckboxEmpty = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 20" fill="none">
    <path
      d="M4 1H16C17.6569 1 19 2.34315 19 4V16C19 17.6569 17.6569 19 16 19H4C2.34315 19 1 17.6569 1 16V4C1 2.34315 2.34315 1 4 1Z"
      stroke="currentColor"
      strokeWidth="2"
      fill="#FFF"
    />
  </SvgIcon>
);
