import { NutrientDto } from "@client/common";
import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const updateCreatorDietTargetNutrients = async (
  dietId: string,
  payload: UpdateCreatorDietTargetNutrientsRequest,
  version: string,
) => {
  await fetchData(
    `/dietitian/creator-2/diets/${dietId}/target-nutrients`,
    APIMethods.PUT,
    payload,
    undefined,
    { "Alloweat-Creator-Version": version },
  );
};

export interface UpdateCreatorDietTargetNutrientsRequest {
  nutrients: NutrientDto[];
}
