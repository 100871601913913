import { FormTextField } from "@components/FormTextField";
import { useAppTranslation, useUpdateClientForm } from "@hooks";
import { useEffect, useState } from "react";
import { Control } from "react-hook-form";
import { Chip } from "@mui/material";
import { phoneInputFactory } from "@utils/inputs";
import {
  EmailVerifyWrapper,
  PhoneRowWrapper,
  SplitRowStyled,
  StyledAlert,
  StyledFormCountryAutocomplete,
} from "./ClientContactsView.styled";
import { FetchClientResponse } from "@client";
import { ClientProfileCardItem } from "@views/dietician/ClientProfile/components/ClientProfileCardItem";

interface ClientContactsViewProps {
  control: Control<any>;
  client?: FetchClientResponse;
}

export const ClientContactsView = ({
  control,
  client,
}: ClientContactsViewProps) => {
  const { t } = useAppTranslation();
  const { watch } = useUpdateClientForm(client);
  const emailAddressChanges = client?.remainingNumberOfEmailAddressChanges;

  const emailNotEdited = emailAddressChanges === 2;
  const emailOnceEdited = emailAddressChanges === 1;
  const emailEditLimit = emailAddressChanges === 0;

  const emailVerified = client?.emailVerified;
  const email = watch("email");
  const [isEmailExist, setIsEmailExist] = useState(false);

  useEffect(() => {
    if (!email || email.trim() === "" || email === null) {
      setIsEmailExist(false);
      return;
    }
    setIsEmailExist(true);
  }, [email]);

  return (
    <div className="grid gap-4">
      <PhoneRowWrapper>
        <ClientProfileCardItem
          title={t("common.prefix")}
          extraEl={
            <StyledFormCountryAutocomplete
              control={control}
              name="phonePrefix"
              id="phonePrefix"
              size="small"
            />
          }
        />
        <ClientProfileCardItem
          title={t("common.phone_number")}
          extraEl={
            <FormTextField
              control={control}
              name="phoneNumber"
              size="small"
              variant="outlined"
              InputProps={{ inputComponent: phoneInputFactory() as any }}
            />
          }
        />
      </PhoneRowWrapper>
      <ClientProfileCardItem
        title={t("common.email_address")}
        extraEl={
          <>
            <FormTextField
              InputProps={{
                readOnly: emailEditLimit,
              }}
              control={control}
              name="email"
              size="small"
              variant="outlined"
            />
            {emailNotEdited && (
              <StyledAlert severity="info">
                {t("client_profile.edit.info.email_not_edited_reminder")}
              </StyledAlert>
            )}
            {emailOnceEdited && (
              <StyledAlert severity="info">
                {t("client_profile.edit.info.email_once_edited_reminder")}
              </StyledAlert>
            )}
            {emailEditLimit && (
              <StyledAlert severity="warning">
                {t("client_profile.edit.info.email_edit_warning_first")}
                <a href="mailto: support@alloweat.com">
                  {t("client_profile.edit.info.email_edit_warning_second")}
                </a>
              </StyledAlert>
            )}

            {email && isEmailExist && !emailVerified && (
              <EmailVerifyWrapper>
                <Chip
                  color="warning"
                  size="small"
                  label={t("client_profile.info.unverified")}
                />
              </EmailVerifyWrapper>
            )}
          </>
        }
      />
      <ClientProfileCardItem
        title={t("client_profile.street_name")}
        extraEl={
          <FormTextField
            control={control}
            name="streetName"
            size="small"
            variant="outlined"
          />
        }
      />
      <SplitRowStyled>
        <ClientProfileCardItem
          title={t("client_profile.edit.info.building_no")}
          extraEl={
            <FormTextField
              control={control}
              name="streetNumber"
              size="small"
              variant="outlined"
            />
          }
        />
        <ClientProfileCardItem
          title={t("client_profile.edit.info.flat_no")}
          extraEl={
            <FormTextField
              control={control}
              name="houseNumber"
              size="small"
              variant="outlined"
            />
          }
        />
      </SplitRowStyled>

      <SplitRowStyled>
        <ClientProfileCardItem
          title={t("client_profile.edit.info.postal_code")}
          extraEl={
            <FormTextField
              control={control}
              name="postcode"
              size="small"
              variant="outlined"
            />
          }
        />
        <ClientProfileCardItem
          title={t("client_profile.city")}
          extraEl={
            <FormTextField
              control={control}
              name="city"
              size="small"
              variant="outlined"
            />
          }
        />
      </SplitRowStyled>
    </div>
  );
};
