import { IdDto } from "@client";
import { PostCreatorDietRequest, postCreatorDiet } from "@client/diets/creator";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ApiResponse } from "@typeDefinitions";
import { MutationOptions, searchDietsQueryKeyNew } from "@hooks/queries";

export const usePostCreatorDietMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation<ApiResponse<IdDto>, unknown, PostCreatorDietRequest>(
    payload => postCreatorDiet(payload),
    {
      ...options,
      onSuccess: (d, v) => {
        options?.onSuccess && options.onSuccess(d, v);
        queryClient.invalidateQueries([searchDietsQueryKeyNew]);
      },
    },
  );
};
