import { StyledDiv, StyledSpan } from "./Badge.styled";
import { useAppTranslation } from "@hooks";

interface BadgeProps {
  success: boolean;
}

export const Badge = ({ success }: BadgeProps) => {
  const { t } = useAppTranslation();
  return (
    <StyledDiv success={success}>
      <StyledSpan success={success}>
        {success
          ? t("products_settings.active")
          : t("products_settings.inactive")}
      </StyledSpan>
    </StyledDiv>
  );
};
