import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MutationOptions } from "./types";
import { postNotificationAsUnread } from "@client";
import { fetchAccountNotificationQueryKey } from "./useFetchAccountNotificationsQuery";
import { fetchUnreadNotificationsQueryKey } from "./useFetchAccountUnreadNotifications";
import {
  accountNotificationQueryKey,
  accountUnreadNotificationQueryKey,
} from "@components/NotificationMenu/components/hooks";

export const usePostNotificationAsUnread = (
  id: string,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();
  return useMutation(() => postNotificationAsUnread(id), {
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries([fetchAccountNotificationQueryKey]);
      queryClient.invalidateQueries([fetchUnreadNotificationsQueryKey]);
      queryClient.invalidateQueries([accountNotificationQueryKey]);
      queryClient.invalidateQueries([accountUnreadNotificationQueryKey]);
      options?.onSuccess && options?.onSuccess();
    },
  });
};
