import { useMemo, useState } from "react";

import { CircularProgress } from "@mui/material";
import { TabContext } from "@mui/lab";

import { useSearchDietsPreviewRecipeItemQuery } from "@hooks/queries/diets";
import { TabSwitch } from "@components/TabSwitch/TabSwitch";
import { ServingContextProvider } from "@components/PreviewDrawer/Recipe/ServingsContext";
import { RecipeTabPanels, RecipeTabs } from "@components/PreviewDrawer/Recipe";
import { useAppTranslation } from "@hooks";
import { ProductIf } from "@components/PreviewDrawer/Recipe/NutrientsTab/ProductsTable";
import { LangDto } from "@client";

import { TabsWrapper } from "./common.styled";
import { NutrientsByProduct } from "../NutrientsByProduct/NutrientsByProduct";

interface RecipeDetailsProps {
  dietId: string;
  mealId: string;
  itemId: string;
}

export const RecipeDetails = ({
  dietId,
  mealId,
  itemId,
}: RecipeDetailsProps) => {
  const { t, isPolishChosen } = useAppTranslation();
  const { recipe, isLoading } = useSearchDietsPreviewRecipeItemQuery(
    dietId,
    mealId,
    itemId,
  );
  const [tab, setTab] = useState(RecipeTabs.NUTRITIONAL_VALUES);
  const tabs = useMemo(
    () => [
      {
        label: t("common.nutrients_values"),
        id: RecipeTabs.NUTRITIONAL_VALUES,
      },
      {
        label: t("common.preparation"),
        id: RecipeTabs.PREPARATION,
      },
      {
        label: t("common.tags"),
        id: RecipeTabs.TAGS,
      },
    ],
    [t],
  );

  const tagsMapped = useMemo(
    () => recipe?.recipe.tags.map(t => t.id) ?? [],
    [recipe?.recipe.tags],
  );

  const productsMapped = useMemo<ProductIf[]>(
    () =>
      recipe?.recipe.products.map(
        ({
          id,
          grams,
          measure,
          product: { id: foodId, name, nutrients, translations, measures },
        }) => {
          const nameEn =
            translations.find(t => t.lang === LangDto.EN)?.name ?? name;

          return {
            id: foodId,
            grams,
            measureId: measure.id,
            measures,
            name: isPolishChosen ? name : nameEn,
            nutrients,
            foodId,
          };
        },
      ) ?? [],
    [recipe?.recipe.products, isPolishChosen],
  );

  const descriptionEn = useMemo(
    () =>
      recipe?.recipe.translations.find(t => t.lang === LangDto.EN)
        ?.description ?? "",
    [recipe?.recipe.translations],
  );

  if (isLoading || !recipe)
    return (
      <div className="grid place-content-center">
        <CircularProgress />
      </div>
    );

  return (
    <>
      <TabsWrapper>
        <TabSwitch value={tab} onChange={v => setTab(v)} tabs={tabs} />
      </TabsWrapper>
      <TabContext value={tab}>
        <ServingContextProvider
          totalServings={recipe.servings}
          servings={recipe.servings}
        >
          <RecipeTabPanels
            tags={tagsMapped}
            products={productsMapped}
            nutrients={recipe.recipe.nutrients}
            hideSlider
            description={recipe.recipe.description ?? ""}
            descriptionEn={descriptionEn}
            NutrientsByProductComponent={NutrientsByProduct}
            comment={recipe.recipe.comment}
            commentEn={recipe.recipe.comment}
          />
        </ServingContextProvider>
      </TabContext>
    </>
  );
};
