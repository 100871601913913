import { useMemo } from "react";
import { FormProvider } from "react-hook-form";

import { CircularProgress } from "@mui/material";

import { useAppParams, useAppTranslation } from "@hooks";
import { useFetchCreatorDietMealProductQuery } from "@hooks/queries/diets/creator";
import { MacrosChart } from "@components/PreviewDrawer/common/MacrosChart";
import { NutrientsSection } from "@components/PreviewDrawer/common";

import { ProductId } from "../ProductEditDrawer";
import { mapProductEditForm, useProductEditForm } from "../useProductEditForm";
import { DrawerFooter } from "./DrawerFooter";
import { DrawerHeader } from "./DrawerHeader";
import { CardStyled, DividerStyled } from "./ProductEditContent.styled";
import { MeasureSelectForm } from "./MeasureSelectForm";
import { getTranslation } from "@utils/translations";
import { ENERGY_ID, getNutrientValue, scaleNutrients } from "@utils";

interface ProductEditContentProps {
  mealId: string;
  productIds: ProductId | null;
  onClose: () => void;
}

export const ProductEditContent = ({
  mealId,
  productIds,
  onClose,
}: ProductEditContentProps) => {
  const { dietId } = useAppParams();
  const { currentLanguage } = useAppTranslation();

  const productExist = productIds !== null;
  const { data, isLoading } = useFetchCreatorDietMealProductQuery(
    dietId,
    mealId,
    productIds?.dietProductId ?? "",
    { enabled: productExist },
  );

  const defaultValues = useMemo(() => mapProductEditForm(data), [data]);
  const nutrients = data?.food.nutrients ?? [];
  const form = useProductEditForm(defaultValues);
  const grams = form.watch("grams");
  const scaledNutrients = useMemo(
    () => scaleNutrients(nutrients, grams),
    [nutrients, grams],
  );

  if (productExist && isLoading)
    return (
      <div className="grid h-full place-items-center">
        <CircularProgress />
      </div>
    );

  return (
    <FormProvider {...form}>
      <DrawerHeader
        onClose={onClose}
        productName={getTranslation(data?.food.translations, currentLanguage)}
        kcal={getNutrientValue(data?.food.nutrients, ENERGY_ID)}
        categoryId={data?.food.foodGroup.id}
        producer={data?.food.producer}
      />
      <div className="grid gap-[16px] p-[16px]">
        <MeasureSelectForm productId={productIds?.productId} />

        <CardStyled>
          <MacrosChart nutrients={scaledNutrients} />

          <DividerStyled />

          <NutrientsSection nutrients={scaledNutrients} />
        </CardStyled>
      </div>

      <DrawerFooter onClose={onClose} mealId={mealId} productIds={productIds} />
    </FormProvider>
  );
};

export enum Tab {
  INFO = "information",
  PRODUCTS = "products",
  TAGS = "tags",
}
