import { useQuery } from "@tanstack/react-query";
import { fetchFood, FetchFoodDetailsResponse } from "@client";
import { buildDefaultQueryOptions } from "@hooks/queries/common";
import { QueryOptions, QueryOptionsTyped } from "@hooks/queries/types";
import { ApiResponse } from "@typeDefinitions";

export const fetchProductQueryKey = "fetchProduct";

export const useFetchProductQuery = (
  id: number | string,
  options?: QueryOptionsTyped<ApiResponse<FetchFoodDetailsResponse>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchProductQueryKey, id.toString()],
    () => fetchFood(id),
    options,
  );
  return { product: data?.data, ...rest };
};
