import { useMutation, useQueryClient } from "@tanstack/react-query";

import { CreatorDietMealProductRequest } from "@client/diets/creator";
import { updateProgramDietMealProduct } from "@client/program/dietCreator";
import {
  fetchDietProductItemsQueryKey,
  fetchDietProductsListQueryKey,
  fetchDietsItemsProductsRemovedQueryKey,
  fetchDietsItemsProductsReplacementQueryKey,
} from "@hooks/queries/diets";
import {
  fetchCreatorDietMealProductQueryKey,
  fetchCreatorDietMealsItemsQuery,
  fetchCreatorDietMealsQueryKey,
} from "@hooks/queries/diets/creator";
import { useCreatorVersion } from "@hooks/queries/diets/creator/useCreatorVersion";
import { MutationOptions } from "@hooks/queries/types";

export const useUpdateProgramDietMealProductMutation = (
  programId: string,
  options?: MutationOptions,
) => {
  const { version, updateVersion } = useCreatorVersion();
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    UseUpdateProgramDietMealProductMutationPayload
  >(
    ({ dietId, mealId, productId, payload }) =>
      updateProgramDietMealProduct(
        programId,
        dietId,
        mealId,
        productId,
        payload,
        version,
      ),
    {
      ...options,
      onMutate: updateVersion,
      onSuccess: (d, v) => {
        options?.onSuccess && options.onSuccess(d, v);
        queryClient.invalidateQueries([
          fetchCreatorDietMealsItemsQuery,
          v.dietId,
          v.mealId,
        ]);
        queryClient.invalidateQueries([
          fetchCreatorDietMealProductQueryKey,
          v.dietId,
          v.mealId,
          v.productId,
        ]);
        queryClient.invalidateQueries([
          fetchCreatorDietMealsQueryKey,
          v.dietId,
        ]);
        queryClient.invalidateQueries([
          fetchDietsItemsProductsRemovedQueryKey,
          v.dietId,
        ]);
        queryClient.invalidateQueries([
          fetchDietsItemsProductsReplacementQueryKey,
          v.dietId,
        ]);
        queryClient.invalidateQueries([
          fetchDietProductItemsQueryKey,
          v.dietId,
        ]);
        queryClient.invalidateQueries([
          fetchDietProductsListQueryKey,
          v.dietId,
        ]);
      },
    },
  );
};

interface UseUpdateProgramDietMealProductMutationPayload {
  dietId: string;
  mealId: string;
  productId: string;
  payload: CreatorDietMealProductRequest;
}
