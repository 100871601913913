import { useMemo } from "react";

import { Button, InputAdornment } from "@mui/material";

import { OuterLabel } from "@components/OuterLabel";
import { useAppTranslation, useMeasures } from "@hooks";
import { useFetchFoodMeasuresQuery } from "@hooks/queries";
import {
  decimalFourDigitsInput,
  decimalInput1ThreeDigits,
} from "@utils/inputs";

import {
  AmountTextField,
  AutocompleteStyled,
  GramsTextField,
  MeasuresTextField,
  SquareIconButton,
} from "./MeasureSelect.styled";
import { Plus } from "@assets/icons";
import { Minus } from "@assets/icons/DesignSystem";

interface MeasureSelectProps {
  measure: string;
  grams: number;
  onMeasureChange: (id: string) => void;
  onGramsChange: (value: number) => void;
  id: number;
}

interface OptionIf {
  label: string;
  grams: number;
  id: string;
}

export const MeasureSelect = ({
  measure,
  grams,
  onGramsChange,
  onMeasureChange,
  id,
}: MeasureSelectProps) => {
  const { t } = useAppTranslation();
  const { measureOptions } = useMeasures();
  const { measures } = useFetchFoodMeasuresQuery(id.toString());
  const productMeasureOptions = useMemo<OptionIf[]>(
    () =>
      measureOptions
        ?.filter(m =>
          measures?.some(measure => measure.measure.id.toString() === m.id),
        )
        .map(m => {
          const mGrams =
            measures?.find(measure => measure.measure.id.toString() === m.id)
              ?.grams || 1;
          return {
            ...m,
            label: `${m.label} (${mGrams}g)`,
            grams: mGrams,
          };
        }) ?? [],
    [measureOptions, measures],
  );
  const selectedMeasure = useMemo(
    () => productMeasureOptions.find(m => m.id === measure),
    [productMeasureOptions, measure],
  );

  const measureAmount = useMemo(
    () => grams / (selectedMeasure?.grams || 1),
    [grams, selectedMeasure],
  );

  const handleChangeAmount = (difference: number) => {
    const amount =
      difference > 0
        ? Math.floor(measureAmount + difference)
        : Math.ceil(measureAmount + difference);
    onGramsChange(amount * (selectedMeasure?.grams || 0));
  };

  return (
    <div className="flex gap-[12px]" key={productMeasureOptions.length}>
      <div className="flex">
        <OuterLabel label={t("products.measure_value")}>
          <AutocompleteStyled
            disableClearable
            fullWidth
            size="small"
            options={productMeasureOptions}
            value={selectedMeasure}
            onChange={(_, v) => onMeasureChange(v.id)}
            renderInput={p => <MeasuresTextField {...p} />}
            filterOptions={o => o}
          />
        </OuterLabel>
        <OuterLabel label={t("common.amount")}>
          <AmountTextField
            size="small"
            InputProps={{
              inputComponent: decimalInput1ThreeDigits,
              endAdornment: (
                <InputAdornment position="end">
                  <SquareIconButton
                    color="primary"
                    onClick={() => handleChangeAmount(1)}
                  >
                    <Plus />
                  </SquareIconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <SquareIconButton
                    color="primary"
                    onClick={() => handleChangeAmount(-1)}
                  >
                    <Minus />
                  </SquareIconButton>
                </InputAdornment>
              ),
            }}
            value={measureAmount}
            onBlur={e => !e.target.value && onGramsChange(0)}
            onChange={e =>
              e.target.value &&
              onGramsChange(
                Number(e.target.value) * (selectedMeasure?.grams || 0),
              )
            }
          />
        </OuterLabel>
      </div>
      <OuterLabel label={t("product.weights_table.grammage")}>
        <GramsTextField
          onChange={e =>
            e.target.value && onGramsChange(Number(e.target.value))
          }
          value={grams}
          size="small"
          InputProps={{
            endAdornment: <InputAdornment position="end">g</InputAdornment>,
            inputComponent: decimalFourDigitsInput,
          }}
        />
      </OuterLabel>
    </div>
  );
};
