import MuiIconButton from "@mui/material/IconButton";
import { styled } from "@mui/material";

export const IconButton = styled(MuiIconButton)`
  padding: 0;
  width: unset;
  height: unset;
  svg {
    width: 1em;
    height: 1em;
    font-size: 24px;
  }
` as typeof MuiIconButton;
