import { DataGridProps } from "@mui/x-data-grid/models/props/DataGridProps";
import { StyledDataGrid } from "./MuiDataGrid.styled";

interface MuiDataGridProps extends DataGridProps {
  stickyFirstCell?: boolean;
  stickyLastCell?: boolean;
}

export const MuiDataGrid = (props: MuiDataGridProps) => {
  return <StyledDataGrid {...props} />;
};
