import image from "@assets/img/monitoringEmptyState.png";
import {
  ContentWrapper,
  EmptyStateCard,
  MonitoringEmptyStateStyled,
  StyledLoadingButton,
} from "./MeasurementsEmptyState.styled";
import { useAppTranslation } from "@hooks";
import { StyledTitle, StyledSubTitle } from "@views/emptyStates/common.styled";
import { Plus } from "@assets/icons";
import { Tooltip } from "@mui/material";

interface MeasurementsEmptyStateProps {
  onClick: () => void;
  disabled?: boolean;
  isLoading?: boolean;
}

export const MeasurementsEmptyState = ({
  onClick,
  disabled,
  isLoading,
}: MeasurementsEmptyStateProps) => {
  const { t } = useAppTranslation();

  return (
    <EmptyStateCard>
      <MonitoringEmptyStateStyled>
        <ContentWrapper>
          <img src={image} alt={t("client_profile.health.no_measurements")} />

          <StyledTitle>
            {t("client_profile.health.no_measurements")}
          </StyledTitle>

          <StyledSubTitle maxWidth="40rem">
            {t("client_profile.health.add_first_measurement")}
          </StyledSubTitle>
        </ContentWrapper>
        <Tooltip
          title={t("common.access_functionality_tooltip")}
          disableHoverListener={!disabled}
          arrow
          placement="top"
        >
          <div>
            <StyledLoadingButton
              startIcon={<Plus size="w-4 h-4" />}
              onClick={onClick}
              variant="contained"
              disabled={disabled}
              loading={isLoading}
              size="large"
            >
              {t("client_profile.health.add_measurement_button")}
            </StyledLoadingButton>
          </div>
        </Tooltip>
      </MonitoringEmptyStateStyled>
    </EmptyStateCard>
  );
};
