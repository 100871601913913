import { Link, useNavigate } from "react-router-dom";

import { Button, Tooltip } from "@mui/material";

import { PreviewDetails } from "@components/PreviewDetails";
import { Nutrient } from "@typeDefinitions";
import { useAppParams, useAppTranslation } from "@hooks";
import { MEALS } from "@routes";
import { useDeleteMealPatternMutation } from "@hooks/queries";
import { ExpandableChips } from "@components/ExpandableChips";

interface MealDetailsProps {
  name: string;
  nutrients: Nutrient[];
  tags: string[];
  canEdit: boolean;
  canDelete: boolean;
  canToggleVisibility: boolean;
}

export const MealDetails = ({
  name,
  nutrients,
  tags,
  canEdit,
  canDelete,
  canToggleVisibility,
}: MealDetailsProps) => {
  const { mutate, isLoading } = useDeleteMealPatternMutation({
    onSuccess: () => navigate(MEALS, { replace: true }),
  });
  const { mealId } = useAppParams();
  const { t } = useAppTranslation();
  const navigate = useNavigate();

  const handleDelete = () => mutate(Number(mealId));

  return (
    <>
      <PreviewDetails
        name={name ?? "---"}
        nutrients={nutrients}
        id={mealId}
        path={MEALS}
        actions={{
          canEdit,
          canDelete,
          canVisible: canToggleVisibility,
        }}
        onDelete={handleDelete}
        editTooltip={t("common.cannot_edit")}
        deleteTooltip={t("common.cannot_delete")}
        isLoading={isLoading}
      />
      <div className="flex justify-between">
        {tags.length ? (
          <ExpandableChips showCount={10} variant="newFilled" labels={tags} />
        ) : (
          <div />
        )}
        {canEdit ? (
          <Link to={`/meals/${mealId}/edit`}>
            <Button variant="outlined">{t("common.edit")}</Button>
          </Link>
        ) : (
          <Tooltip title={t("common.cannot_edit")}>
            <div>
              <Button disabled variant="outlined">
                {t("common.edit")}
              </Button>
            </div>
          </Tooltip>
        )}
      </div>
    </>
  );
};
