import { FetchSingedSurveysResponse } from "@client/surveys";
import { useContentSelector } from "@context/TableOfContentsContext";
import { useAppParams } from "@hooks";
import { fetchPatientSurveyQueryKey } from "@hooks/queries/surveys";
import { useQueryClient } from "@tanstack/react-query";
import { ApiResponse } from "@typeDefinitions";
import { AnswerFactory } from "./Answers/AnswerFactory";
import { SurveyContentWrapper } from "./PatientSurveyModal.styled";

interface SubgroupContentProps {
  surveyId: string;
}
export const SubgroupContent = ({ surveyId }: SubgroupContentProps) => {
  const { patientId } = useAppParams();
  const { groupId, subgroupId } = useContentSelector();
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData<
    ApiResponse<FetchSingedSurveysResponse>
  >([fetchPatientSurveyQueryKey, patientId, surveyId]);

  const subgroupData = data?.data.survey.groups
    .find(g => g.id === groupId)
    ?.sections.find(s => s.id === subgroupId);
  const answers = data?.data.answers;
  const dictionaires = data?.data.dictionaries;

  const getAnswer = (id: number) => answers?.find(a => a.question.id === id);

  if (!subgroupData) return <></>;

  return (
    <SurveyContentWrapper>
      {subgroupData.questions.map((q, idx) => (
        <AnswerFactory
          key={q.id}
          listIndex={idx}
          totalQuestions={subgroupData.questions.length}
          question={q}
          answer={getAnswer(q.id)}
          dictionaires={dictionaires}
        />
      ))}
    </SurveyContentWrapper>
  );
};
