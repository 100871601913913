import * as yup from "yup";

import {
  IdDto,
  LangDto,
  NutrientDto,
  TranslationDto,
  idSchema,
  nutrientSchema,
} from "@client/common";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const fetchCreatorDietMeals = async (
  dietId: string,
): Promise<ApiResponse<DietMealListItemCreatorDto[]>> => {
  const response = await fetchData(
    `/dietitian/creator-2/diets/${dietId}/meals`,
    APIMethods.GET,
  );

  return apiReponseSchema.validate(response);
};

export interface DietMealListItemCreatorDto {
  id: number;
  translations: TranslationExtendedDto[];
  hour: string | null;
  nutrients: NutrientDto[];
  targets: NutrientDto[];
  type: IdDto | null;
  itemsCount: number;
}

export interface TranslationExtendedDto extends TranslationDto {
  description: string | null;
}

const translationExtendedSchema = yup.object().shape({
  langId: yup.mixed<LangDto>().oneOf(Object.values(LangDto)).required(),
  name: yup.string().ensure(),
  description: yup.string().nullable().default(null),
});

export const dietMealListItemCreatorSchema = yup.object().shape({
  id: yup.number().required(),
  translations: yup.array().of(translationExtendedSchema.required()).required(),
  hour: yup.string().nullable().defined(),
  nutrients: yup.array().of(nutrientSchema.required()).required(),
  targets: yup.array().of(nutrientSchema.required()).required(),
  type: idSchema.nullable().default(null),
  itemsCount: yup.number().required(),
});

const apiReponseSchema = yup.object().shape({
  data: yup.array().of(dietMealListItemCreatorSchema.required()).required(),
});
