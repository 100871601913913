import { Alert, AlertTitle, IconButton, styled } from "@mui/material";

export const StyledInfoAlert = styled(Alert)`
  justify-content: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.lightPurple};
  color: ${({ theme }) => theme.palette.text.primary};
  padding: 0.638rem 1.375rem;

  & .MuiAlert-message {
    text-align: center;
    padding: unset;
  }
`;

export const AlertTitleStyled = styled(AlertTitle)`
  font-weight: 600;
`;

export const ApplyIconButton = styled(IconButton)`
  align-self: center;
`;
