import { ModalShell } from "@components/ModalShell";
import {
  mapUpdateBalanceClientInfoForm,
  mapUpdateBalanceClientInfoRequest,
  mapUpdateClientForm,
  mapUpdateClientRequest,
  useAppTranslation,
  useUpdateBalanceClientInfoForm,
} from "@hooks";
import { useCallback, useMemo } from "react";
import { SectionWrapper } from "./EditBalanceClientProfileModal.styled";
import { ClientProfileCardItem } from "@views/dietician/ClientProfile/components/ClientProfileCardItem";
import { MuiTimeDatePicker } from "@components/MuiTimeDatePicker";
import { FormTextField } from "@components/FormTextField";
import { decimalInput1ThreeDigits } from "@utils/inputs";
import { useEmptyDataString } from "@hooks/helpers";
import {
  useCreateClientMeasurementMutation,
  useFetchClientLastMeasurementQuery,
  useFetchClientQuery,
  useUpdateClientMeasurementMutation,
  useUpdateClientMutation,
} from "@hooks/queries";
import { FormSelect } from "@components/FormSelectNew";
import { MenuItem } from "@mui/material";

interface EditBalanceClientProfileModalProps {
  open: boolean;
  onClose: () => void;
  id: string;
}
export const EditBalanceClientProfileModal = ({
  open,
  onClose,
  id,
}: EditBalanceClientProfileModalProps) => {
  const { t } = useAppTranslation();
  const { client } = useFetchClientQuery(id);
  const { mutate, isLoading } = useUpdateClientMeasurementMutation();
  const {
    mutate: createClientMeasurementMutate,
    isLoading: isLoadingClientMeasurement,
  } = useCreateClientMeasurementMutation(id);
  const { mutate: updateClientMutate, isLoading: isLoadingUpdateClient } =
    useUpdateClientMutation(Number(id));
  const { lastMeasurement } = useFetchClientLastMeasurementQuery(id);
  const measurementId = lastMeasurement?.id;
  const isPending =
    isLoading || isLoadingUpdateClient || isLoadingClientMeasurement;

  const mappedForm = useMemo(
    () => mapUpdateBalanceClientInfoForm(client, lastMeasurement),
    [client, lastMeasurement],
  );
  const { control, handleSubmit, reset } =
    useUpdateBalanceClientInfoForm(mappedForm);

  const gender = client?.profile.sex ?? null;

  enum genderOptions {
    WOMAN = "w",
    MAN = "m",
    NOT_EXIST = "",
  }

  const genderList = useMemo(
    () => [
      { id: genderOptions.WOMAN, label: t("client_profile.edit.info.woman") },
      { id: genderOptions.MAN, label: t("client_profile.edit.info.man") },
      {
        id: genderOptions.NOT_EXIST,
        label: t("client_profile.edit.info.not_specified"),
      },
    ],
    [t],
  );

  const handleEditClientBalanceInfo = () => {
    handleSubmit(
      data => {
        if (measurementId) {
          mutate(
            {
              id: measurementId,
              payload: mapUpdateBalanceClientInfoRequest(data),
            },
            {
              onSuccess: onCloseWithReset,
            },
          );
        } else {
          createClientMeasurementMutate(
            mapUpdateBalanceClientInfoRequest(data),
            {
              onSuccess: onCloseWithReset,
            },
          );
        }
        const mappedClientData = mapUpdateClientForm(client);
        if (mappedClientData) {
          updateClientMutate(
            mapUpdateClientRequest({
              ...mappedClientData,
              targetWeight: data.targetWeight ?? undefined,
              sex: data.sex || null,
              birthDate: data.birthDate ?? null,
            }),
            { onSuccess: onCloseWithReset },
          );
        }
      },
      e => console.log("e", e),
    )();
  };

  const onCloseWithReset = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);
  return (
    <ModalShell
      title={t("client_profile.health.goals_and_measurements")}
      open={open}
      onClose={onCloseWithReset}
      isLoading={isPending}
      onSubmit={handleEditClientBalanceInfo}
    >
      <SectionWrapper>
        <ClientProfileCardItem
          title={t("client_profile.edit.info.date_of_birth")}
          extraEl={
            <MuiTimeDatePicker dateVersion name="birthDate" control={control} />
          }
        />
        <ClientProfileCardItem
          title={t("client_profile.health.height")}
          extraEl={
            <FormTextField
              control={control}
              name="growth"
              size="small"
              variant="outlined"
              placeholder={
                useEmptyDataString(lastMeasurement?.growth, "cm") ??
                t("client_profile.health.empty_placeholders.type_height_in_cm")
              }
              InputProps={{
                inputComponent: decimalInput1ThreeDigits,
              }}
            />
          }
        />
        <ClientProfileCardItem
          title={t("client_profile.health.weight")}
          extraEl={
            <FormTextField
              control={control}
              name="weight"
              size="small"
              variant="outlined"
              placeholder={
                useEmptyDataString(lastMeasurement?.weight, "kg") ??
                t("client_profile.health.empty_placeholders.type_weight_in_kg")
              }
              InputProps={{
                inputComponent: decimalInput1ThreeDigits,
              }}
            />
          }
        />
        <ClientProfileCardItem
          title={t("client_profile.health.target_body_weight")}
          extraEl={
            <FormTextField
              control={control}
              name="targetWeight"
              size="small"
              variant="outlined"
              placeholder={
                useEmptyDataString(client?.profile.targetWeight, "kg") ??
                t(
                  "client_profile.health.empty_placeholders.type_target_weight_in_kg",
                )
              }
              InputProps={{
                inputComponent: decimalInput1ThreeDigits,
              }}
            />
          }
        />
        <ClientProfileCardItem
          title={t("client_profile.health.body_fat")}
          extraEl={
            <FormTextField
              control={control}
              name="bodyFatLevel"
              size="small"
              variant="outlined"
              placeholder={
                useEmptyDataString(lastMeasurement?.bodyFatLevel, "%") ??
                t("client_profile.health.empty_placeholders.type_body_fat_in_%")
              }
              InputProps={{
                inputComponent: decimalInput1ThreeDigits,
              }}
            />
          }
        />
        <ClientProfileCardItem
          title={t("client_profile.info.gender")}
          extraEl={
            <FormSelect
              displayEmpty
              defaultValue={gender}
              control={control}
              name="sex"
              id="sex"
              size="small"
            >
              {genderList.map(param => (
                <MenuItem value={param.id} key={param.label}>
                  {param.label}
                </MenuItem>
              ))}
            </FormSelect>
          }
        />
      </SectionWrapper>
    </ModalShell>
  );
};
