import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const deletePatientProgram = async (
  patientId: number,
  programId: number,
) =>
  await fetchData(
    `/dietitian/patients/${patientId}/programs/${programId}`,
    APIMethods.DELETE,
  );
