import { useController } from "react-hook-form";

import { Checkbox, InputAdornment } from "@mui/material";

import { useNutrients } from "@hooks";
import { decimalFourDigitsInput } from "@utils/inputs";

import {
  FormControlLabelStyled,
  NormNutrientRowStyled,
  TextFieldStyled,
} from "./NormNutrientRow.styled";
import { NutrientNormInputs } from "./useNutrientsNormForm";

export interface NormNutrientRowProps {
  id: number;
  idx: number;
  value?: number;
}
export const NormNutrientRow = ({ id, idx }: NormNutrientRowProps) => {
  const { nutrientDict } = useNutrients();
  const nutrient = nutrientDict.get(id);
  const {
    field: { value, onChange },
  } = useController<NutrientNormInputs>({ name: `nutrients.${idx}.value` });
  const {
    field: { value: show, onChange: onChangeShow },
  } = useController<NutrientNormInputs>({ name: `nutrients.${idx}.show` });

  return (
    <NormNutrientRowStyled>
      <FormControlLabelStyled
        label={nutrient?.name}
        control={
          <Checkbox
            id={`nutrients.${idx}`}
            checked={Boolean(show)}
            onChange={(e, v) => onChangeShow(v)}
          />
        }
      />
      <TextFieldStyled
        size="small"
        value={value}
        onChange={e => onChange(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{nutrient?.unit}</InputAdornment>
          ),
          inputComponent: decimalFourDigitsInput,
        }}
      />
    </NormNutrientRowStyled>
  );
};
