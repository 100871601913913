import { useMemo } from "react";

import { useTheme } from "@mui/material";
import { round } from "lodash";

import { FlameFilled } from "@assets/icons";
import { useNutrients } from "@hooks";
import { Nutrient } from "@typeDefinitions";
import { ENERGY_ID, MACROS_ARRAY, macrosSortKey } from "@utils/macros";

import {
  LetterCircle,
  TextSemiBold,
  Text,
  TextBold,
  Wrapper,
} from "./MacrosSummary.styled";

interface MacrosSummaryProps {
  nutrients: Nutrient[];
  targets: Nutrient[];
  showTarget: boolean;
}

interface TargetNutrient extends Nutrient {
  target: number;
}

export const MacrosSummary = ({
  nutrients,
  targets,
  showTarget,
}: MacrosSummaryProps) => {
  const {
    colors: { neutral },
  } = useTheme();
  const { nutrientDict } = useNutrients();

  const mappedNutrients = useMemo(() => {
    return MACROS_ARRAY.reduce((acc: TargetNutrient[], id: number) => {
      const nutrient = nutrients.find(n => n.id === id);
      const targetValue = targets.find(n => n.id === id)?.value ?? 0;

      acc.push({
        id,
        value: nutrient?.value ?? 0,
        target: targetValue,
      });

      return acc;
    }, []).sort((a, b) => macrosSortKey(a.id, b.id));
  }, [nutrients, targets, nutrientDict]);

  return (
    <div className="flex gap-[16px]">
      {mappedNutrients.map(({ id, value, target }) => {
        const nutrientDetails = nutrientDict.get(id);

        if (id === ENERGY_ID)
          return (
            <Wrapper key={id}>
              <FlameFilled fill={neutral.dark[700]} className="mr-[4px]" />
              <TextBold>{round(value)}</TextBold>
              <Text>
                / {target} {nutrientDetails?.unit}
              </Text>
            </Wrapper>
          );

        return (
          <Wrapper key={id}>
            <LetterCircle>{nutrientDetails?.short}</LetterCircle>
            <TextBold>{round(value)}</TextBold>
            <Text>
              {showTarget && `/ ${target}`} {nutrientDetails?.unit}
            </Text>
          </Wrapper>
        );
      })}
    </div>
  );
};
