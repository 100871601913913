import {
  fetchRequiredBillData,
  FetchRequiredBillDataResponse,
} from "@client/clinic";
import { QueryOptionsTyped } from "@hooks/queries/types";
import { useQuery } from "@tanstack/react-query";
import { clinicKeys } from "./clinicKeys";

export const useFetchRequiredBillDataQuery = (
  options?: QueryOptionsTyped<FetchRequiredBillDataResponse>,
) => {
  return useQuery({
    queryKey: clinicKeys.billData(),
    queryFn: () => fetchRequiredBillData(),
    ...options,
  });
};
