import { WeekdayButtonStyled, WeekdayIcon } from "./WeekdaySelector.styled";

import dayjs from "dayjs";
import { Weekday } from "./WeekdaySelector";

interface WeekdayButtonProps {
  active: boolean;
  onChange: (qwe: boolean) => void;
  weekday: Weekday;
  size: "large" | "small";
  className?: string;
}

export const WeekdayButton = ({
  active,
  onChange,
  weekday,
  size,
  className,
}: WeekdayButtonProps) => {
  return (
    <div className={className}>
      {size === "large" ? (
        <WeekdayButtonStyled
          onClick={() => onChange(active)}
          variant={active ? "contained" : "outlined"}
        >
          {dayjs().day(weekday).format("dd")}
        </WeekdayButtonStyled>
      ) : (
        <WeekdayIcon checked={active}>
          {dayjs().day(weekday).format("dd")}
        </WeekdayIcon>
      )}
    </div>
  );
};
