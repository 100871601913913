import { useFetchMonitoringMealsClient } from "@hooks/queries/client/monitoring";
import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import MealsSummaryCardOf from "@views/dietician/PatientMonitoring2/components/SummaryCard/Meals/MealsSummaryCardOf";
import { mapSelectedDaysToRequestQueryParams } from "@views/dietician/PatientMonitoring2/PatientMonitoringUtils";
import { Dayjs } from "dayjs";
import { ApiResponse } from "@typeDefinitions";
import { PatientMealsMonitoringResource } from "@client/resources/PatientMealsMonitoringResource";

const MealsSummaryCardOfDay = () => {
  const { patientId, selectedDays, type } = useMonitoring();

  const { data } = useFetchMonitoringMealsClient(
    {
      patientId,
      ...mapSelectedDaysToRequestQueryParams(selectedDays.selectDays),
    },
    { enabled: type.type === "day" },
  );

  return (
    <MealsSummaryCardOf
      actualValue={getActualValue(data, selectedDays.selectDays.from)}
      targetValue={getTargetValue(data, selectedDays.selectDays.from)}
    />
  );
};

const getActualValue = (
  data: ApiResponse<PatientMealsMonitoringResource[]> | undefined,
  day: Dayjs,
): number => {
  return (data?.data ?? [])
    .filter(d => d.date === day.format("YYYY-MM-DD"))
    .reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.meals.filter(m => m.isEaten).length,
      0,
    );
};

const getTargetValue = (
  data: ApiResponse<PatientMealsMonitoringResource[]> | undefined,
  day: Dayjs,
) => {
  const filteredDay = (data?.data ?? []).filter(
    d => d.date === day.format("YYYY-MM-DD"),
  );

  if (filteredDay.length === 0) {
    return null;
  }

  return filteredDay.reduce(
    (accumulator, currentValue) => accumulator + currentValue.meals.length,
    0,
  );
};

export default MealsSummaryCardOfDay;
