import {
  DialogSectionItemLabel,
  DialogSectionItemLayout,
} from "@views/dietician/ClientHealth/ClientHealthNew.styled";
import { Controller, useFormContext } from "react-hook-form";
import { NormAutocomplete } from "@views/dietician/DietCreator/components/NutrientsCard/NormAutocomplete";
import {
  Button,
  InputAdornment,
  OutlinedInput,
  Stack,
  styled,
  Tab,
} from "@mui/material";
import { useAppTranslation } from "@hooks";
import { useCategoriesNutrientsFetchQuery } from "@hooks/queries/dictionaries";
import { TabList } from "@components/Alloweat/Tabs/TabList";
import { TabPanel } from "@components/Alloweat/Tabs/TabPanel";
import { TabContext } from "@mui/lab";
import { useEffect, useMemo, useState } from "react";
import { useFetchNormNutrients } from "@hooks/queries";
import { CategoryNutrientsResponse } from "@typeDefinitions/responses/dictionaries";
import { ArrowsRotateIcon } from "@icons/index";
import { NutrientCategoryConst } from "@consts/NutrientCategoryConst";

const NutritionalNormField = () => {
  const [selectedNorm, setSelectedNorm] = useState<string | null>(null);
  const { t } = useAppTranslation();
  const { data } = useCategoriesNutrientsFetchQuery();
  const form = useFormContext();
  const nutrientsNormId = form.watch("nutrientsNormId", "0");

  const { nutrients: normNutrients } = useFetchNormNutrients(nutrientsNormId, {
    enabled: !(nutrientsNormId === "0" || nutrientsNormId === null),
  });

  useEffect(() => {
    if (!normNutrients || !selectedNorm) {
      return;
    }

    handleRevertNormOnClick();
  }, [normNutrients, selectedNorm]);

  const categories = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.filter(
      category =>
        ![
          NutrientCategoryConst.MACRO_ELEMENTS,
          NutrientCategoryConst.ENERGY,
        ].includes(category.id),
    );
  }, [data]);

  const handleRevertNormOnClick = () => {
    if (!normNutrients) {
      return;
    }
    for (const [key, value] of Object.entries(
      form.getValues("nutrientsNorm"),
    )) {
      form.setValue(`nutrientsNorm.${key}`, "0");
    }
    normNutrients.forEach(nutrient =>
      form.setValue(`nutrientsNorm.${nutrient.id}`, nutrient.value),
    );
  };

  return (
    <>
      <DialogSectionItemLayout>
        <DialogSectionItemLabel>
          {t(
            "patient.health_and_goal.dietary_profile_tab.dietary_profile_modal.dietary_standard",
          )}
        </DialogSectionItemLabel>
        <Stack
          display="flex"
          direction="row"
          gap="8px"
          justifyContent="space-between"
          alignItems="center"
        >
          <Controller
            render={({ field }) => {
              return (
                <NormAutocomplete
                  value={field.value}
                  onChange={id => {
                    field.onChange(id);
                    setSelectedNorm(id);
                  }}
                />
              );
            }}
            name="nutrientsNormId"
          />
          <RevertNormButton
            onClick={handleRevertNormOnClick}
            variant="text"
            startIcon={<ArrowsRotateIcon color="#7448D0" fontSize="24px" />}
          >
            {t(
              "patient.health_and_goal.dietary_profile_tab.dietary_profile_modal.revert",
            )}
          </RevertNormButton>
        </Stack>
      </DialogSectionItemLayout>
      {categories.length > 0 && <TabsSection categories={categories} />}
    </>
  );
};

type TabsSectionProps = {
  categories: CategoryNutrientsResponse[];
};

const TabsSection = ({ categories }: TabsSectionProps) => {
  const { isPolishChosen } = useAppTranslation();
  const form = useFormContext();
  const [category, setCategory] = useState<string>(categories[0].id.toString());

  return (
    <TabContext value={category}>
      <TabList
        onChange={(_, newValue) => setCategory(newValue)}
        scrollButtons={"auto"}
        variant="scrollable"
      >
        {categories.map(category => {
          return (
            <Tab
              key={category.id}
              label={isPolishChosen ? category.namePl : category.nameEn}
              value={category.id.toString()}
            />
          );
        })}
      </TabList>
      {categories.map(category => {
        return (
          <TabPanel key={category.id} value={category.id.toString()}>
            <Stack
              direction="column"
              gap="8px"
              height="300px"
              sx={{ overflowY: "auto" }}
              className={"scrollbar-thin"}
            >
              {category.nutrients.map((nutrient, index) => (
                <Stack
                  key={nutrient.id}
                  direction="row"
                  justifyContent="space-between"
                  gap="8px"
                  alignItems="center"
                >
                  <Label>
                    {isPolishChosen
                      ? nutrient.descriptionPl
                      : nutrient.descriptionEn}
                  </Label>
                  <CustomOutlinedInput
                    {...form.register(`nutrientsNorm.${nutrient.id}`)}
                    sx={{ flex: 1 }}
                    endAdornment={
                      <InputAdornment position="end">
                        {nutrient.units}
                      </InputAdornment>
                    }
                  />
                </Stack>
              ))}
            </Stack>
          </TabPanel>
        );
      })}
    </TabContext>
  );
};

const RevertNormButton = styled(Button)`
  font: var(--font-figtree-medium);
  font-size: 14px;
  line-height: 24px;
  white-space: nowrap;
  min-width: unset;
` as typeof Button;

const Label = styled("span")`
  font: var(--font-figtree-medium);
  font-size: 14px;
  line-height: 24px;
  color: #333333;
  flex: 2;
`;

const CustomOutlinedInput = styled(OutlinedInput)`
  .MuiInputBase-input {
    padding: 8px 12px;
    height: unset;
    font: var(--font-figtree-semi-bold);
    font-size: 14px;
    line-height: 24px;
    color: #333333;
  }
`;

export default NutritionalNormField;
