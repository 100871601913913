import { Stack } from "@mui/material";
import ProgramName from "@components/ProgramSearchPreview/Components/Head/ProgramName";
import { ProgramSearchPreview } from "@client/program/preview/fetchProgramPreview";
import ProgramHeadDays from "@components/ProgramSearchPreview/Components/Head/ProgramHeadDays.styled";
import { ReactNode } from "react";
import { useAppTranslation } from "@hooks";

type ProgramHeadLayoutProps = {
  program: Pick<ProgramSearchPreview, "translations" | "name" | "programDays">;
  actionButtons: ReactNode;
};

const ProgramHeadLayout = ({
  program,
  actionButtons,
}: ProgramHeadLayoutProps) => {
  const { t } = useAppTranslation();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="top"
      gap="16px"
    >
      <Stack direction="column" gap="4px">
        <ProgramName program={program} />
        <ProgramHeadDays>
          {t("program_preview.type_days.days.c", {
            count: program.programDays.length,
            variable: program.programDays.length,
          })}
        </ProgramHeadDays>
      </Stack>
      <Stack direction="row" gap="4px">
        {actionButtons}
      </Stack>
    </Stack>
  );
};

export default ProgramHeadLayout;
