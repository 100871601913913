import { MediumThumb } from "@assets/icons/SliderThumb";
import { SliderThumb } from "@mui/material";
import { HTMLAttributes } from "react";

export const MediumThumbComponent = (props: HTMLAttributes<unknown>) => {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <MediumThumb size="w-6 h-6" />
    </SliderThumb>
  );
};
