import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const updateProgramDays = async (
  id: string,
  programDays: UpdateProgramDaysRequestDto,
) => {
  await fetchData(
    `/dietitian/programs/${id}/days`,
    APIMethods.PUT,
    programDays,
  );
};

export interface UpdateProgramDaysRequestDto {
  days: {
    id: number | null;
    name: string;
    nameEn: string | null;
    frequency: number[];
  }[];
}

export const updateProgramDaysInitial = async (
  id: string,
  programDays: UpdateProgramDaysInitialRequestDto,
) => {
  await fetchData(
    `/dietitian/programs/${id}/days/initial`,
    APIMethods.PUT,
    programDays,
  );
};

export interface UpdateProgramDaysInitialRequestDto {
  days: {
    id: number | null;
    name: string;
    nameEn: string | null;
    frequency: number[];
    norm: { id: number } | null;
    targetNutrients: {
      id: number;
      value: number;
      visible: boolean;
    }[];
  }[];
}
