import { memo, ReactNode, useCallback } from "react";

import { requestRecipesNutrients } from "@client";

import { useAppTranslation } from "@hooks";
import { DietitianRecipe, Nutrient } from "@typeDefinitions";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { ListElement, ListElementProps } from "./ListElement";
import { SwappedProductDot } from "@components/SwappedProductDot";
import { MicronutrientPreview } from "@components/MicronutrientsModal";
import { ExpandableTagsList } from "./ExpandableTagsList";
import { RecipeDetails } from "@components/RecipeDetails";

import { Error, Pacman } from "@assets/icons";

interface RecipeListElementProps
  extends Omit<ListElementProps, "title" | "body" | "macros" | "micros"> {
  isGeneralList?: boolean;
  recipe: DietitianRecipe;
  children?: ReactNode;
  duplicateWarning?: boolean;
  noTags?: boolean;
  selectInput?: ReactNode;
  numOfServings?: number;
  selectedServings?: number;
  micros?: Nutrient[];
  error?: boolean;
  hasSubstitutes?: boolean;
}

export const RecipeListElement = memo(
  ({
    recipe,
    children,
    duplicateWarning = false,
    noTags = false,
    numOfServings,
    selectedServings,
    micros,
    error,
    hasSubstitutes,
    isGeneralList,
    ...rest
  }: RecipeListElementProps) => {
    const { t, isPolishChosen } = useAppTranslation();
    const query = useCallback(
      () => requestRecipesNutrients(recipe.id),
      [recipe.id],
    );

    return (
      <ListElement
        {...rest}
        title={
          isGeneralList ? (
            <Link to={`/recipes/${recipe.id}`}>
              <div className="flex gap-2 items-center">
                {isPolishChosen ? recipe.namePl : recipe.nameEn}
                {recipe.isComplex ? <Pacman /> : ""}
              </div>
            </Link>
          ) : (
            <div className="flex gap-2 items-center">
              {isPolishChosen ? recipe.namePl : recipe.nameEn}
              {recipe.isComplex ? <Pacman /> : ""}
            </div>
          )
        }
        suffix={
          <div>
            {hasSubstitutes && (
              <SwappedProductDot title={t("common.contains_substitutes")} />
            )}
            {error && (
              <Tooltip
                title={`${t("recipes.invalid_recipe")}`}
                placement={"top"}
              >
                <div>
                  <Error className="text-red" />
                </div>
              </Tooltip>
            )}
            {duplicateWarning && (
              <Tooltip
                title={<p>{t("diets.already_used")}</p>}
                placement={"top"}
              >
                <div>
                  <Error className="text-orange" />
                </div>
              </Tooltip>
            )}
          </div>
        }
        macros={recipe.macros}
        micros={<MicronutrientPreview means={micros} query={query} />}
        body={<ExpandableTagsList tags={recipe.tags} />}
      >
        {!children ? <RecipeDetails recipeId={recipe.id} /> : children}
      </ListElement>
    );
  },
);
