import { useMemo } from "react";

import { Nutrient } from "@typeDefinitions";
import { useNutrientDictionary } from "@hooks";
import {
  MicrosTableCell,
  PlaceholderCell,
} from "@components/RecipeEditForm/components/RecipeTableForm.styled";
import { mapVisibleNutrientsDetails } from "@context/VisibleNutrientsContext";

interface MicrosRowProps {
  nutrients: Nutrient[];
  visibleNutrients: number[];
  bold?: boolean;
  skipBorderBottom?: boolean;
}
export const MicrosRow = ({
  nutrients,
  visibleNutrients,
  bold = false,
  skipBorderBottom = false,
}: MicrosRowProps) => {
  const { nutrientDictionary } = useNutrientDictionary();
  const getNutrient = (id: number) =>
    nutrientDictionary[id] ? nutrientDictionary[id] : undefined;

  const mappedMicros = useMemo(
    () => mapVisibleNutrientsDetails(visibleNutrients, nutrients, getNutrient),
    [nutrients, getNutrient, nutrientDictionary],
  );

  return (
    <>
      {mappedMicros.map(micro => (
        <MicrosTableCell
          align="right"
          key={micro.id}
          fontBold={bold}
          skipBorderBottom={skipBorderBottom}
        >
          {micro.value}&nbsp;{micro.unit}
        </MicrosTableCell>
      ))}
      <PlaceholderCell skipBorderBottom={skipBorderBottom} />
    </>
  );
};
