import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import * as yup from "yup";
import { FileData, fileDataSchema } from "./common";

export async function fetchVisitFiles(
  patientId: string,
): Promise<ApiResponse<FileData[]>> {
  const data = await fetchData(
    `/dietitian/patients/${patientId}/schedules/visits/files`,
    APIMethods.GET,
  );
  await visitTagsSchema.validate(data);
  return data;
}

const visitTagsSchema = yup.object().shape({
  data: yup.array().of(fileDataSchema),
});
