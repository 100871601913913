import { useAppTranslation } from "@hooks";
import { TooltipProps } from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { StyledItem, Tooltip } from "./SimulationChart.styled";

export const CustomTooltip = ({
  active,
  payload,
}: TooltipProps<ValueType, NameType>) => {
  const { t } = useAppTranslation();

  if (active && payload?.length) {
    return (
      <Tooltip>
        <p>
          {payload[0].payload.name === "0"
            ? t("balance.start")
            : t("common.week") + " " + `${payload[0].payload.name}`}
        </p>

        {!!payload[1] && (
          <>
            <StyledItem>{`${t("common.bodyWeight")}: ${
              payload[1].value
            } kg`}</StyledItem>
            <StyledItem>{`${t("balance.simulation")}: ${
              payload[0].value
            } kg`}</StyledItem>
          </>
        )}

        {!payload[1] &&
          (payload[0].dataKey === "kg" ? (
            <>
              {" "}
              <StyledItem>{`${t("common.bodyWeight")}: ${
                payload[0].value
              } kg`}</StyledItem>
            </>
          ) : (
            <>
              {" "}
              <StyledItem>{`${t("balance.simulation")}: ${
                payload[0].value
              } kg`}</StyledItem>
            </>
          ))}
      </Tooltip>
    );
  }
  return null;
};
