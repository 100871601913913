import { WhatsNewList } from "@components/WhatsNewList";
import { useWhatsNew } from "@hooks";
import { ClickAwayListener } from "@mui/material";
import { MouseEvent, ReactNode, useState } from "react";

interface WhatsNewActionWrapperProps {
  children: ReactNode;
}
export const WhatsNewActionWrapper = ({
  children,
}: WhatsNewActionWrapperProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { unreadNews, handleButtonClick } = useWhatsNew();

  const [unread, setUnread] = useState(unreadNews);
  const open = !!unread || Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setUnread(0);
    handleButtonClick();
  };

  const toggleListButton = (e: MouseEvent<HTMLDivElement>) => {
    anchorEl && !unread ? handleClose() : handleClick(e);
    if (unread) {
      handleClose();
    }
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <div className="w-full" onClick={toggleListButton}>
          {children}
        </div>
      </ClickAwayListener>
      <WhatsNewList onClose={handleClose} opened={open} />
    </>
  );
};
