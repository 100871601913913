import { useQuery } from "@tanstack/react-query";

import { ApiResponse } from "@typeDefinitions";

import { QueryOptionsTyped } from "./types";
import { fetchAccountNotifications, NotificationType } from "@client";
import { useMemo, useState } from "react";
import { AccountNotificationInput } from "@typeDefinitions/types";
import { parseNumber } from "@utils";

export const fetchAccountNotificationQueryKey =
  "fetchAccountNotificationQueryKey";

export const useFetchAccountNotificationsQuery = (
  options?: QueryOptionsTyped<ApiResponse<NotificationType[]>>,
) => {
  const [committedInputs, setCommittedInputs] =
    useState<AccountNotificationInput>();

  const params = useMemo(
    () => parseAccountNotifications(committedInputs),
    [committedInputs],
  );

  const { data, ...rest } = useQuery(
    [fetchAccountNotificationQueryKey, params.toString()],
    () => fetchAccountNotifications(params),
    options,
  );

  return {
    notifications: data?.data,
    meta: data?.meta,
    submit: setCommittedInputs,
    ...rest,
  };
};

const parseAccountNotifications = (filters?: AccountNotificationInput) => {
  const search = new URLSearchParams();
  parseNumber(filters?.read, "read", search);
  parseNumber(filters?.page, "page", search);

  return search;
};
