import * as yup from "yup";

export interface MealsStatsMonitoringResource {
  days: number;
  meals: {
    all: number;
    eaten: number;
    tasty: number;
    notTasty: number;
    items: number;
  };
  tags: { id: number }[];
}

const mealsSchema = yup.object({
  all: yup.number().required(),
  eaten: yup.number().required(),
  tasty: yup.number().required(),
  notTasty: yup.number().required(),
  items: yup.number().required(),
});

const tagsSchema = yup.object({
  id: yup.number().required(),
});

export const mealsStatsMonitoringSchema = yup
  .object({
    days: yup.number().required(),
    meals: mealsSchema,
    tags: yup.array().of(tagsSchema).defined(),
  })
  .required();
