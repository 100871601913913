import { useMemo } from "react";
import { FormProvider } from "react-hook-form";

import {
  PatientProfileTargetResourceDto,
  SexAnswerResourceDto,
  SystemQuestionDto,
} from "@client/surveys";
import { useUpdateSingedSurveyMutation } from "@hooks/queries/surveys";

import { useAppTranslation } from "@hooks";
import { useUpdateValidationContext } from "../../context/useUpdateValidationContext";
import { useParseUrl } from "../../useParseUrl";
import { AutosaveOnBlurWatch } from "../AutosaveOnBlurWatch";
import { BlurContextProvider } from "../BlurContext";
import { Question } from "../Question/Question";
import {
  SexAnswerFormInputs,
  mapSexAnswerForm,
  mapSexAnswerRequest,
  useSexAnswerForm,
} from "../forms";
import { SexRadioGroup } from "./SexRadioGroup";

interface SystemQuestionSexProps {
  question: SystemQuestionDto;
  answer?: SexAnswerResourceDto;
  listIndex: number;
  totalQuestions: number;
  readOnly?: boolean;
  options?: PatientProfileTargetResourceDto[];
}

export const SystemQuestionSex = ({
  question: {
    title,
    config: { required },
    id,
  },
  answer,
  readOnly,
  options,
  ...rest
}: SystemQuestionSexProps) => {
  const { t } = useAppTranslation();
  const { url } = useParseUrl();
  const mappedForm = useMemo(
    () => mapSexAnswerForm(answer?.answer),
    [answer?.answer],
  );
  const { formState, handleSubmit, control, ...form } = useSexAnswerForm(
    mappedForm,
    required,
  );
  const { mutate, isLoading } = useUpdateSingedSurveyMutation();

  const onSubmit = (data: SexAnswerFormInputs) => {
    if (url) mutate({ payload: mapSexAnswerRequest(data, id), url });
  };

  const { errors, touchedFields } = formState;
  const hasError = !!Object.values(errors).length;

  useUpdateValidationContext(id, touchedFields, handleSubmit);
  return (
    <FormProvider
      formState={formState}
      handleSubmit={handleSubmit}
      control={control}
      {...form}
    >
      <BlurContextProvider>
        <Question
          {...rest}
          title={title}
          description={null}
          required={required}
          hasError={hasError}
          readOnly={readOnly}
        >
          <SexRadioGroup readOnly={readOnly} />
        </Question>

        <AutosaveOnBlurWatch onSubmit={onSubmit} isLoading={isLoading} />
      </BlurContextProvider>
    </FormProvider>
  );
};
