import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const ArrowCircledCheck = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 28 28">
      <g id="ic-exchange" transform="translate(-663 -115)">
        <rect
          id="Rectangle_1219"
          data-name="Rectangle 1219"
          width="28"
          height="28"
          transform="translate(663 115)"
          fill="#fff"
          opacity="0"
        />
        <g id="_1" data-name=" 1" transform="translate(666 117.231)">
          <path
            id="Path_1786"
            data-name="Path 1786"
            d="M40.269,50.39a.879.879,0,0,1-.786-.485A11,11,0,0,1,57.088,37.2a.879.879,0,0,1-1.242,1.242A9.239,9.239,0,0,0,41.054,49.117a.879.879,0,0,1-.785,1.273Z"
            transform="translate(-38.313 -33.212)"
            fill="#40c862"
          />
          <path
            id="Path_1787"
            data-name="Path 1787"
            d="M74.4,103.091a10.96,10.96,0,0,1-7.774-3.215.879.879,0,1,1,1.242-1.243A9.238,9.238,0,0,0,82.663,87.961a.879.879,0,0,1,1.571-.788,10.99,10.99,0,0,1-9.83,15.918Z"
            transform="translate(-63.403 -80.338)"
            fill="#40c862"
          />
          <path
            id="Path_1788"
            data-name="Path 1788"
            d="M167.122,32.531a.879.879,0,0,1-.086-1.753l2.778-.277-.557-2.792a.879.879,0,0,1,1.723-.343l.746,3.745a.879.879,0,0,1-.69,1.034.836.836,0,0,1-.085.013l-3.742.373Z"
            transform="translate(-152.712 -26.668)"
            fill="#40c862"
          />
          <path
            id="Path_1789"
            data-name="Path 1789"
            d="M67.993,199.5a.878.878,0,0,1-.861-.707l-.746-3.742a.879.879,0,0,1,.69-1.033.85.85,0,0,1,.085-.013l3.742-.373a.879.879,0,0,1,.176,1.749l-2.778.277.556,2.792a.878.878,0,0,1-.69,1.033A.868.868,0,0,1,67.993,199.5Z"
            transform="translate(-63.4 -175.964)"
            fill="#40c862"
          />
          <rect
            id="Rectangle_829"
            data-name="Rectangle 829"
            width="9.803"
            height="11.437"
            transform="translate(6.479 6.819)"
            fill="#fff"
            opacity="0"
          />
          <path
            id="check_icon"
            data-name="check icon"
            d="M0,2.8,2.8,5.6,8.4,0"
            transform="translate(6.829 8.861)"
            fill="none"
            stroke="#3cc95f"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </SvgIcon>
  );
};
