export enum SubscriptionTypes {
  TRIAL = 1,
  UNLIMITED_GROWTH = 2,
  GROWTH = 3,
  STARTER = 4,
  GROWTH_10 = 5,
  GROWTH_30 = 6,
  GROWTH_60 = 7,
  ENTERPRISE = 8,
  STUDENT = 9,
}
