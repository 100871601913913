import { useQuery } from "@tanstack/react-query";
import { fetchIsConnect } from "@client/schedule";

export const fetchIsConnectedQueryKey = "fetchIsConnected";

export const useFetchIsConnectedQuery = (
  programId: string | number,
  options?: { enabled: boolean },
) => {
  const { data: isConnected, ...rest } = useQuery(
    [fetchIsConnectedQueryKey, programId],
    ({ signal }) => fetchIsConnect(programId, signal),
    {
      ...options,
      onError: e => {
        console.log(e);
      },
    },
  );
  return { isConnected: isConnected?.data.isConnect, ...rest };
};
