import { styled } from "@mui/material";
import { MultiLanguageInput } from "@components/MultiLanguageInput";

export const MultiLanguageInputStyled = styled(MultiLanguageInput)`
  flex: 1;
  min-width: 30.437rem;
  max-width: 47rem;
`;

export const ProgramDayConfigStyled = styled("div")`
  display: flex;
  gap: 4rem;
  padding-top: 1rem;
  align-items: center;
`;

export const WeekdaySection = styled("div")`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;
