import { useAppParams } from "@hooks";
import { useFetchClientQuery } from "@hooks/queries";
import { useMemo } from "react";
import PatientMonitoringOn from "@views/dietician/PatientMonitoring2/PatientMonitoringOn";
import PatientMonitoringOff from "@views/dietician/PatientMonitoring2/PatientMonitoringOff";
import { Spinner } from "@components/Spinner";

export const PatientMonitoringNew = () => {
  const { patientId } = useAppParams();
  const { client } = useFetchClientQuery(patientId, {
    enabled: !!patientId,
  });

  const hasAccess = useMemo(() => {
    if (!client) {
      return false;
    }
    return (
      !!client?.active &&
      !!client?.email &&
      !!client?.mobileApplication?.firstActivatedAt
    );
  }, [client]);

  if (!client) {
    return <Spinner />;
  }

  return hasAccess ? (
    <PatientMonitoringOn />
  ) : (
    <PatientMonitoringOff client={client} />
  );
};
