import { useQuery } from "@tanstack/react-query";

import { ApiResponse } from "@typeDefinitions";
import {
  DietProductSearchPreviewResourceDto,
  searchDietsPreviewProductItem,
} from "@client/diets/searchDietsPreviewProductItem";

import { QueryOptionsTyped } from "../types";

export const searchDietsPreviewProductItemQueryKey =
  "searchDietsPreviewProductItemQueryKey";

export const useSearchDietsPreviewProductItemQuery = (
  dietId: string,
  mealId: string,
  itemId: string,
  options?: QueryOptionsTyped<ApiResponse<DietProductSearchPreviewResourceDto>>,
) => {
  const { data, ...rest } = useQuery(
    [searchDietsPreviewProductItemQueryKey, dietId, mealId, itemId],
    () => searchDietsPreviewProductItem(dietId, mealId, itemId),
    options,
  );

  return {
    product: data?.data,
    ...rest,
  };
};
