import { ENERGY_ID } from "@utils/macros";
import {
  FetchPatientProgramResponse,
  FetchProgramResponse,
} from "@client/program";
import { DayTabProps } from "@components/DayTabs";

export const programToDayTabs = (
  program: FetchProgramResponse | FetchPatientProgramResponse,
): DayTabProps[] =>
  program.days.map(({ id, name, diet, frequency }) => ({
    id: id.toString(),
    name: name,
    currentEnergy:
      diet?.nutrients.find(({ id }) => id === ENERGY_ID)?.value ?? null,
    targetEnergy:
      diet?.nutrients?.find(({ id }) => id === ENERGY_ID)?.value ?? null,
    frequency: frequency,
  }));
