import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material";
import { Link } from "react-router-dom";

export const LoginFormWrapper = styled("form")`
  display: grid;
  gap: 24px;
  align-items: center;
  width: 510px;
  padding: 24px;
  background: ${({ theme }) => theme.palette.primary.light};
  border-radius: 12px;
  z-index: 1;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.smallNew}px) {
    background: ${({ theme }) => theme.colors.neutral.light[100]};
  }
`;

export const TopLoginSection = styled("div")`
  display: grid;
  gap: 12px;
  text-align: center;
`;

export const LoginTitle = styled("h1")`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
`;

export const StyledLink = styled(Link)`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary.main};
  width: fit-content;
  margin: 0 auto;
`;

export const SubmitButton = styled(LoadingButton)`
  font-size: 14px;
  line-height: 24px;
`;

export const ResendButton = styled("div")`
  display: inline;
  text-decoration: underline;
  cursor: pointer;
`;
