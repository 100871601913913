import {
  BackCircularProgress,
  RunningCircularProgress,
  StyledDialogModal,
} from "./FullWidthSpinner.styled";

interface IFullWIdthSpinnerProps {
  onClose?: () => void;
  open?: boolean;
}

export const FullWidthSpinner = ({
  onClose,
  open = true,
}: IFullWIdthSpinnerProps) => {
  return (
    <StyledDialogModal scroll="paper" onClose={onClose} open={open}>
      <BackCircularProgress variant="determinate" size={80} value={100} />
      <RunningCircularProgress variant="indeterminate" size={80} />
    </StyledDialogModal>
  );
};
