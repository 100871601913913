import { Alert, Button, styled } from "@mui/material";

export const TabWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: calc(100vh - 214px);
`;

export const FiltersInfoAlert = styled(Alert)`
  &.MuiAlert-filledInfo {
    background-color: ${({ theme }) => theme.palette.info.light};
    padding: 6px 16px;
  }
`;

export const AlertButton = styled(Button)`
  height: 24px;
`;
