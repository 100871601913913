import { Button } from "@mui/material";

import {
  CreatorDietMealDto,
  ItemDto,
  ProductItemDto,
  RecipeItemDto,
} from "@client/diets/creator";
import { useAppParams, useAppTranslation } from "@hooks";

import { usePostDietMealsItemsMutation } from "@views/dietician/DietCreator/hooks";
import {
  ItemsListCallback,
  ProductItem,
  RecipeItem,
  useItemsContext,
} from "../ItemsContext";
import { ActionButtonsWrapper, Bold, Text } from "./ActionButtons.styled";
import { LoadingButton } from "@mui/lab";

interface ActionButtonsProps {
  onClose: () => void;
  onSuccess: () => void;
  onSubmit?: ItemsListCallback;
  dietId: string;
  loading?: boolean;
}

export const mapRequest = (
  items: (ProductItem | RecipeItem)[],
): CreatorDietMealDto[] => {
  return items.map(item => {
    const { mealId } = item;
    const baseItem: ItemDto = {
      type: item.type,
      id: item.id,
    };

    if (item.type === "recipe") {
      const recipeItem: RecipeItemDto = {
        ...baseItem,
        type: "recipe",
        servings: item.servings,
      };
      return {
        meal: { id: mealId },
        item: recipeItem,
      };
    } else {
      const productItem: ProductItemDto = {
        ...baseItem,
        type: "product",
        grams: item.grams,
        measure: { id: item.measure },
      };
      return {
        meal: { id: mealId },
        item: productItem,
      };
    }
  });
};
export const ActionButtons = ({
  onClose,
  onSuccess,
  dietId,
  onSubmit,
  loading,
}: ActionButtonsProps) => {
  const { t } = useAppTranslation();

  const { submit, itemsCount } = useItemsContext();
  const { mutate, isLoading } = usePostDietMealsItemsMutation();

  const handleSubmit = () => {
    if (onSubmit) return submit(onSubmit);

    submit(d =>
      mutate({ dietId, payload: { data: mapRequest(d) } }, { onSuccess }),
    );
  };

  return (
    <ActionButtonsWrapper>
      <div>
        <Bold>{t("common.selected")}&nbsp;</Bold>
        <Text>({t("diet.proposals", { count: itemsCount })})</Text>
      </div>

      <div className="flex gap-[8px]">
        <Button variant="outlined" onClick={onClose}>
          {t("common.cancel")}
        </Button>
        <LoadingButton
          variant="contained"
          onClick={handleSubmit}
          loading={loading || isLoading}
        >
          {t("common.add")}
        </LoadingButton>
      </div>
    </ActionButtonsWrapper>
  );
};
