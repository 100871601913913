import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useAppTranslation } from "@hooks/useAppTranslation";
import { buildDefaultQueryOptions } from "../common";
import { fetchUsedRecipesQueryKey } from "./useFetchUsedRecipesQuery";
import { fetchDietitianProgramScheduleKey } from "./useFetchDietitianProgramScheduleQuery";
import { fetchDietitianClientProgramScheduleKey } from "./useFetchDietitianClientProgramScheduleQuery";
import {
  ProgramDayMealFoodUpdatePayload,
  updateProgramDayMealFood,
} from "@client/schedule";

export const useProgramDayMealFoodMutation = (
  programId: number,
  dayId: number,
) => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();

  return useMutation(
    ({ mealId, ...payload }: ProgramDayMealRecipeUpdateExtendedPayload) =>
      updateProgramDayMealFood(programId, dayId, mealId, payload),
    buildDefaultQueryOptions({
      errorToastMessage: t("patient.schedule.update_error"),
      onSuccess: () => {
        queryClient.invalidateQueries([fetchUsedRecipesQueryKey]);
        queryClient.invalidateQueries([fetchDietitianProgramScheduleKey]);
        queryClient.invalidateQueries([fetchDietitianClientProgramScheduleKey]);
      },
    }),
  );
};

interface ProgramDayMealRecipeUpdateExtendedPayload
  extends ProgramDayMealFoodUpdatePayload {
  mealId: number;
}
