import {
  Box,
  Chip,
  IconButton,
  styled,
  Typography,
  Menu,
  Divider,
} from "@mui/material";

export const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    width: 400px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }
`;

export const VisitOverViewContent = styled(Box)`
  padding: 16px;
  padding-top: 0;
  width: 100%;
`;

export const VisitOverViewHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 0;
  margin-bottom: 16px;
`;

export const HeaderTitle = styled(Typography)`
  font-size: 18px;
  min-height: 35px;
  padding-top: 4px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.dark["900"]};
`;

export const VisitOverviewWrapper = styled(Box)`
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`;

export const VisitOverviewItem = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

export const VisitOverviewItemContent = styled(Box)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const VisitOverviewItemLabel = styled(Typography)`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.neutral.medium["800"]};
  text-align: left;
`;

export const VisitOverviewItemText = styled(Typography)`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.neutral.dark["900"]};
`;

export const VisitOverviewStatusChip = styled(Chip)<{ approved?: string }>`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.4px;
  background-color: ${({ theme, approved }) =>
    approved === "true"
      ? theme.palette.success.light
      : theme.palette.warning.light};
  color: ${({ theme, approved }) =>
    approved === "true" ? theme.palette.success.main : theme.colors.orangeDark};
`;

export const VisitOverviewButton = styled("div")`
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 600;
  padding-left: 0;
  cursor: pointer;
`;

export const VisitOverviewIconButton = styled(IconButton)`
  padding: 0;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 24px;
  &:hover {
    background-color: transparent;
  }
`;

export const VisitOverviewButtonText = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const VisitOverviewNavigationIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.colors.neutral.dark["700"]};
  font-size: 12px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral.light["200"]};
  }
  height: 35px;
  width: 35px;
`;

export const DividerStyled = styled(Divider)`
  border-color: ${({ theme }) => theme.colors.neutral.light[200]} !important;
  width: 100%;
`;
