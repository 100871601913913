import { SvgIcon, SvgIconProps } from "@mui/material";

export const Survey = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M15.55 4.9H17.325C17.7958 4.9 18.2473 5.08701 18.5801 5.41989C18.913 5.75276 19.1 6.20424 19.1 6.675V19.1C19.1 19.5708 18.913 20.0222 18.5801 20.3551C18.2473 20.688 17.7958 20.875 17.325 20.875H6.67502C6.20427 20.875 5.75279 20.688 5.41991 20.3551C5.08703 20.0222 4.90002 19.5708 4.90002 19.1V6.675C4.90002 6.20424 5.08703 5.75276 5.41991 5.41989C5.75279 5.08701 6.20427 4.9 6.67502 4.9H8.45002"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M14.6625 3.125H9.33751C8.84736 3.125 8.45001 3.52235 8.45001 4.0125V5.7875C8.45001 6.27765 8.84736 6.675 9.33751 6.675H14.6625C15.1527 6.675 15.55 6.27765 15.55 5.7875V4.0125C15.55 3.52235 15.1527 3.125 14.6625 3.125Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
