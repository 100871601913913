import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const HandWithPlus = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="12" viewBox="0 0 14 12">
      <path
        d="M5.55556 0.763835V2.02772H4.29167C3.99149 2.02772 3.75 2.26922 3.75 2.56939V3.65272C3.75 3.9529 3.99149 4.19439 4.29167 4.19439H5.55556V5.45828C5.55556 5.75845 5.79705 5.99995 6.09722 5.99995H7.18056C7.48073 5.99995 7.72222 5.75845 7.72222 5.45828V4.19439H8.98611C9.28628 4.19439 9.52778 3.9529 9.52778 3.65272V2.56939C9.52778 2.26922 9.28628 2.02772 8.98611 2.02772H7.72222V0.763835C7.72222 0.463661 7.48073 0.222168 7.18056 0.222168H6.09722C5.79705 0.222168 5.55556 0.463661 5.55556 0.763835ZM13.1321 9.0739C13.5339 8.77824 13.6196 8.21401 13.324 7.81227C13.0283 7.41054 12.4641 7.32477 12.0623 7.62043L9.36076 9.61106H6.63889C6.44028 9.61106 6.27778 9.44856 6.27778 9.24995C6.27778 9.05133 6.44028 8.88883 6.63889 8.88883H7H8.44444C8.84392 8.88883 9.16667 8.56609 9.16667 8.16661C9.16667 7.76713 8.84392 7.44439 8.44444 7.44439H7H6.63889H4.8717C4.21493 7.44439 3.57847 7.66783 3.06615 8.07633L2.05278 8.88883H1.22222C0.822743 8.88883 0.5 9.21158 0.5 9.61106V11.0555C0.5 11.455 0.822743 11.7777 1.22222 11.7777H4.83333H8.45573C9.11024 11.7777 9.74896 11.5678 10.2771 11.1796L13.1344 9.0739H13.1321ZM4.84913 8.88883H4.86944C4.86267 8.88883 4.8559 8.88883 4.84913 8.88883Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
