import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  FetchCreatorDietResponseDto,
  UpdateCreatorDietShowMacrosPayload,
  updateCreatorDietShowMacros,
} from "@client/diets/creator";
import { MutationOptions } from "@hooks/queries/types";

import {
  ALLOWEAT_CREATOR_VERSION,
  useCreatorVersion,
} from "./useCreatorVersion";
import { fetchCreatorDietQueryKey } from "./useFetchCreatorDietQuery";
import { fetchCreatorDietMealsQueryKey } from "./useFetchCreatorDietMealsQuery";

export const useUpdateCreatorDietShowMacrosMutation = (
  options?: MutationOptions,
) => {
  const { version } = useCreatorVersion();
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    UseUpdateCreatorDietShowMacrosMutationPayload,
    MutationContextIf
  >(
    ({ dietId, payload }) =>
      updateCreatorDietShowMacros(dietId, payload, version),
    {
      ...options,
      onMutate: async ({ dietId, payload }) => {
        await queryClient.cancelQueries([fetchCreatorDietQueryKey, dietId]);
        const previousData =
          queryClient.getQueryData<FetchCreatorDietResponseDto>([
            fetchCreatorDietQueryKey,
            dietId,
          ]);

        if (!previousData?.data) return { previousData, invalidate: true };

        queryClient.setQueryData<FetchCreatorDietResponseDto>(
          [fetchCreatorDietQueryKey, dietId],
          oldData => {
            if (!oldData) return previousData;

            return {
              data: {
                ...oldData.data,
                showMacros: payload.show,
              },
              headers: {
                ...oldData.headers,
                [ALLOWEAT_CREATOR_VERSION]: `${
                  Number(oldData.headers[ALLOWEAT_CREATOR_VERSION]) + 1
                }`,
              },
            };
          },
        );
      },
      onSettled: (d, e, v, context) => {
        if (context?.invalidate) {
          queryClient.invalidateQueries([fetchCreatorDietQueryKey, v.dietId]);
        }
        queryClient.invalidateQueries([
          fetchCreatorDietMealsQueryKey,
          v.dietId,
        ]);
      },
    },
  );
};

interface UseUpdateCreatorDietShowMacrosMutationPayload {
  dietId: string;
  payload: UpdateCreatorDietShowMacrosPayload;
}

interface MutationContextIf {
  previousData?: FetchCreatorDietResponseDto;
  invalidate?: boolean;
}
