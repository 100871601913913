import React from "react";
import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Add = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 12 13">
    <g transform="translate(1.2 1.2)">
      <path
        d="M.476,0V10"
        transform="translate(4.529)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
      <path
        d="M10.01.476H0"
        transform="translate(0 4.524)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
    </g>
  </SvgIcon>
);
