import { Divider } from "@mui/material";
import { DateDividerWrapper, DateWrapper } from "./DateDivider.styled";
import { ReactNode } from "react";

interface DateDividerProps {
  children?: ReactNode;
}

export const DateDivider = ({ children }: DateDividerProps) => {
  return (
    <DateDividerWrapper>
      <Divider />
      <DateWrapper>{children}</DateWrapper>
      <Divider />
    </DateDividerWrapper>
  );
};
