import { styled } from "@mui/material";

export const GroupHeaderStyled = styled("div")`
  position: sticky;
  top: -0.5rem;
  padding: 0.25rem 0.625rem;
  color: ${({ theme }) => theme.colors.whiteBackground};
  background-color: ${({ theme }) => theme.palette.primary.main};
  z-index: 999;
`;

export const GroupItemsStyled = styled("ul")`
  padding: 0;
`;

export const ListItemStyled = styled("li")`
  display: flex;
  gap: 0.5rem;
`;
