import { Chip, Dialog } from "@mui/material";

import { OuterLabel } from "@components/OuterLabel";

import { QuestionPreviewProps } from "./QuestionPreview";
import {
  CardTitle,
  DialogContentStyled,
  TextStyled,
} from "./QuestionPreview.styled";

interface ModalQuestionPreviewProps extends QuestionPreviewProps {
  open: boolean;
  onClose: () => void;
}
export const ModalQuestionPreview = ({
  listIndex,
  totalQuestions,
  systemQuestion,
  actionComponents,
  title,
  description,
  children,
  open,
  onClose,
}: ModalQuestionPreviewProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContentStyled>
        <div className="flex justify-between">
          <div className="flex gap-2 items-center">
            <CardTitle>
              Pytanie {listIndex + 1}/{totalQuestions}
            </CardTitle>

            <Chip
              color={systemQuestion ? "primary" : "default"}
              label={systemQuestion ? "Systemowe" : "Własne"}
            />
          </div>

          {actionComponents}
        </div>
        <OuterLabel label="Treść pytania">
          <TextStyled>{title}</TextStyled>
        </OuterLabel>

        {!systemQuestion && (
          <OuterLabel label="Opis pytania">
            <TextStyled>{description ?? "Brak"}</TextStyled>
          </OuterLabel>
        )}

        {children}
      </DialogContentStyled>
    </Dialog>
  );
};
