import { Button, styled } from "@mui/material";

export const NavigationBarWrapperMobile = styled("div")`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0.5rem 0.5rem 2.375rem 0.5rem;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral.light[100]};
  box-shadow: ${({ theme }) => theme.boxShadows.mobileNavigation};
`;

export const ButtonIconStyled = styled(Button)`
  width: 2.5rem;
  height: 2.5rem;
  min-width: unset;
  padding: unset;
`;

export const NavigationBarWrapper = styled("div")`
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0px -2px 4px 0px #0000000a;
  background: ${({ theme }) => theme.palette.primary.medium};
  padding: 0.75rem 1rem;
  z-index: 2;
`;

export const ButtonStretched = styled(Button)`
  flex: 1;
  & svg {
    width: 1rem;
    height: 1rem;
  }
`;

export const ButtonStyled = styled(Button)`
  & svg {
    width: 1rem;
    height: 1rem;
  }
`;
