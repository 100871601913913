import {
  SectionHeadEditLayout,
  SectionHeadLayout,
  SectionLayout,
  SectionTitle,
  TabHeadActionEditLabel,
} from "@views/dietician/ClientHealth/ClientHealthNew.styled";
import { useAppTranslation } from "@hooks";
import DisplayForCustomerItemSection from "@views/dietician/ClientHealth/ProfileTab/NutrientsSection/DisplayForCustomerItemSection";
import ImportantNutrientsItemSection from "@views/dietician/ClientHealth/ProfileTab/NutrientsSection/ImportantNutrientsItemSection";
import NutrientsDialog from "@views/dietician/ClientHealth/ProfileTab/NutrientsSection/Dialog/NutrientsDialog";
import { useState } from "react";
import { EditIcon } from "@icons/index";

const NutrientsSection = () => {
  const { t } = useAppTranslation();
  const [open, setOpen] = useState(false);

  return (
    <SectionLayout>
      <SectionHeadLayout>
        <SectionTitle>
          {t(
            "patient.health_and_goal.dietary_profile_tab.nutritional_values_section.section_title",
          )}
        </SectionTitle>
        <SectionHeadEditLayout onClick={() => setOpen(true)}>
          <EditIcon color="#7448D0" />
          <TabHeadActionEditLabel>{t("common.edit")}</TabHeadActionEditLabel>
        </SectionHeadEditLayout>
      </SectionHeadLayout>

      <DisplayForCustomerItemSection />
      <ImportantNutrientsItemSection />
      <NutrientsDialog open={open} setOpen={setOpen} />
    </SectionLayout>
  );
};

export default NutrientsSection;
