import { fetchData } from "@utils/api";
import { APIMethods, ApiEndpoints } from "@typeDefinitions";

export const updateNote = async ({
  id,
  ...payload
}: UpdateNoteRequestPayload) => {
  return await fetchData(
    `${ApiEndpoints.AccountNotes}/${id}`,
    APIMethods.PUT,
    payload,
  );
};

export interface UpdateNoteRequestPayload {
  id: string;
  note: string;
}
