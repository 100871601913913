import { FormSelect } from "@components/FormSelectNew";
import { ModalShell } from "@components/ModalShell";
import {
  mapUpdateClientForm,
  mapUpdateClientRequest,
  useAppTranslation,
} from "@hooks";
import { useFetchClientQuery, useUpdateClientMutation } from "@hooks/queries";
import { MenuItem } from "@mui/material";
import { ClientProfileCardItem } from "@views/dietician/ClientProfile/components/ClientProfileCardItem";
import { FormEvent, useMemo } from "react";
import { useEditHealthStatusForm } from "./useEditHealthStatusForm";
import { SelectOption } from "@components/FormAutocomplete";

interface EditHealthStatusModalProps {
  open: boolean;
  onClose: () => void;
  id: string;
}

export const EditHealthStatusModal = ({
  open,
  onClose,
  id,
}: EditHealthStatusModalProps) => {
  const { t } = useAppTranslation();
  const { client } = useFetchClientQuery(id);
  const additionalInfoId = client?.profile.additionalFeatures.length
    ? client?.profile.additionalFeatures[0].id
    : Number(AdditionalOptions.NONE);
  const { mutate: updateClientMutate, isLoading } = useUpdateClientMutation(
    Number(id),
  );
  const { control, handleSubmit } = useEditHealthStatusForm({
    additionalInfo: additionalInfoId,
  });

  const handleEditClientAdditionalInfo = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit(
      data => {
        const mappedClientData = mapUpdateClientForm(client);
        if (mappedClientData) {
          updateClientMutate(
            mapUpdateClientRequest({
              ...mappedClientData,
              additionalFeature: data.additionalInfo ?? undefined,
            }),
            { onSuccess: onClose },
          );
        }
      },
      e => console.log(e),
    )();
  };

  const optionsList = useMemo<SelectOption[]>(
    () => [
      {
        id: AdditionalOptions.PREGNANT,
        label: t("client_profile.health.pregnant"),
      },
      {
        id: AdditionalOptions.LACTATING,
        label: t("client_profile.health.lactating"),
      },
      { id: AdditionalOptions.NONE, label: t("client_profile.health.none") },
    ],
    [t],
  );

  return (
    <ModalShell
      title={t("client_profile.health.health_status")}
      open={open}
      onClose={onClose}
      isLoading={isLoading}
      onSubmit={handleEditClientAdditionalInfo}
      typeSubmit
    >
      <ClientProfileCardItem
        title={t("client_profile.health.additional_information")}
        extraEl={
          <FormSelect
            control={control}
            name="additionalInfo"
            id="additionalInfo"
            size="small"
          >
            {optionsList?.map(param => (
              <MenuItem value={param.id} key={param.label}>
                {param.label}
              </MenuItem>
            ))}
          </FormSelect>
        }
      />
    </ModalShell>
  );
};

export enum AdditionalOptions {
  PREGNANT = "9",
  LACTATING = "10",
  NONE = "5",
}
