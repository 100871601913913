import { useQuery } from "@tanstack/react-query";

import { QueryOptionsTyped } from "./types";
import { buildDefaultQueryOptions } from "./common";
import { ApiResponse } from "@typeDefinitions";
import {
  FetchSimpleProgramDietResponse,
  fetchSimpleProgramDiet,
} from "@client";

export const fetchSimpleProgramDietQueryKey = "fetchSimpleProgramDietQueryKey";

export const useFetchSimpleProgramDietQuery = (
  programId: string,
  dayId: string,
  options?: QueryOptionsTyped<ApiResponse<FetchSimpleProgramDietResponse>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchSimpleProgramDietQueryKey, programId, dayId],
    () => fetchSimpleProgramDiet(programId, dayId),
    buildDefaultQueryOptions(options),
  );
  return { simpleDiet: data?.data, ...rest };
};
