import { ProductInDietDto } from "@client/diets";
import { useAppParams } from "@hooks";
import {
  fetchDietProductsListQueryKey,
  useFetchDietProductsListQuery,
} from "@hooks/queries/diets";
import { useQueryClient } from "@tanstack/react-query";
import { ApiResponse } from "@typeDefinitions";

export const useAlreadyUsedProducts = () => {
  const { dietId } = useAppParams();
  const queryClient = useQueryClient();
  const cacheData = queryClient.getQueryData<ApiResponse<ProductInDietDto[]>>([
    fetchDietProductsListQueryKey,
    dietId,
  ]);
  const { data } = useFetchDietProductsListQuery(dietId, {
    enabled: !cacheData && !!dietId,
  });

  const products = cacheData?.data ?? data;

  return {
    alreadyUsedProducts: new Set(products?.map(({ product }) => product.id)),
  };
};
