import { useMeasures } from "@hooks";
import { NutrientRowStyled, TextStyled } from "./MeasurementRow.styled";

interface MeasurementRowProps {
  id: number;
  value: number;
}
export const MeasurementRow = ({ id, value }: MeasurementRowProps) => {
  const { getMeasure } = useMeasures();
  const measures = getMeasure(id);
  return (
    <NutrientRowStyled>
      <TextStyled>{measures?.name}</TextStyled>
      <TextStyled>{value} g</TextStyled>
    </NutrientRowStyled>
  );
};
