import { Autocomplete, styled } from "@mui/material";

export const StyledAutocomplete = styled(Autocomplete)`
  min-height: 40px;

  &.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
    right: 12px;
    top: calc(50% - 12px);
  }

  &.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
    .MuiOutlinedInput-root {
    padding: 3px 48px 3px 9px;
  }

  &
    .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator {
    & svg {
      width: 16px;
      height: 16px;
    }
  }
` as typeof Autocomplete;
