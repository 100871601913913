import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Edit } from "@assets/icons";
import { useAppTranslation } from "@hooks";
import { weekdays } from "@utils/weekdays";
import { Minus } from "@assets/icons/Program";
import { usePatientProgramSummaryParams } from "@views/dietician/PatientProgram";
import { useDeleteProgramDayDietMutation } from "@hooks/queries";
import { ConfirmationIconButton } from "@components/ConfirmationIconButton";
import {
  ProgramDayDiet,
  ProgramDietDetails,
} from "@components/ProgramDietDetails";
import { Button } from "@components/Button";
import { AddDietModal } from "@components/modals/AddDietModal";
import { IconButton } from "@components/IconButton";

import {
  ProgramListElementSection,
  ProgramListElementWrapper,
} from "./ProgramDietsCard.styled";

export interface ProgramDay {
  id: number;
  name: string;
  frequency: number[];
  diet: ProgramDayDiet | null;
}

interface ProgramListElementProps {
  day: ProgramDay;
  canEdit?: boolean;
}

export const ProgramListElement = ({
  day: { id, name, frequency, diet },
  canEdit,
}: ProgramListElementProps) => {
  const { t, isPolishChosen } = useAppTranslation();
  const { patientId, programId } = usePatientProgramSummaryParams();
  const [modal, setModal] = useState(false);

  const close = useCallback(() => setModal(false), []);
  const open = useCallback(() => setModal(true), []);

  const navigate = useNavigate();

  const { mutate: removeDiet } = useDeleteProgramDayDietMutation();

  const onEditDiet = useCallback(() => {
    if (diet) {
      navigate(
        patientId
          ? `/patients/${patientId}/nutritional-programs/${programId}/days/${id}/diet/${diet.id}/edit`
          : `/programs/${programId}/days/${id}/diet/${diet.id}/edit`,
      );
    }
  }, [navigate, diet]);

  const onDeleteDiet = useCallback(() => {
    removeDiet({ programId: programId.toString(), dayId: id.toString() });
  }, [removeDiet]);

  const days = frequency
    .map(f => weekdays.find(w => w.id === f))
    .filter(f => !!f)
    .map(f => (isPolishChosen ? f!.shortPl : f!.shortEn))
    .join(", ");

  return (
    <ProgramListElementWrapper>
      <ProgramListElementSection>
        <div className="grid gap-1">
          <div className="flex gap-3">
            <p className="w-24">{t("addNutritionalProgram.step2.dayName")}:</p>
            <p className="font-semibold">{name ?? "-"}</p>
            {!!days.length && (
              <>
                <p className="pl-2">{t("common.frequency")}:</p>
                <p className="font-semibold">{days ?? "-"}</p>
              </>
            )}
          </div>

          <ProgramDietDetails diet={diet} titleClassName="w-24" />
        </div>

        {!diet && (
          <Button
            fontLight
            color="secondary"
            style={{ width: 250 }}
            onClick={open}
          >
            {t("program.diets.diet_day_card.choose_diet")}
          </Button>
        )}

        {modal && (
          <AddDietModal
            programId={programId}
            dietDayId={id}
            onClose={close}
            patientId={patientId}
          />
        )}

        {diet && (
          <div className="flex">
            <IconButton onClick={onEditDiet} disabled={!canEdit}>
              <Edit size="w-5 h-5" />
            </IconButton>
            <ConfirmationIconButton
              disabled={!canEdit}
              icon={<Minus size="w-5 h-5" />}
              onConfirm={onDeleteDiet}
              confirmationText={t("confirmation.question_delete")}
              submitText={t("confirmation.delete")}
            />
          </div>
        )}
      </ProgramListElementSection>
    </ProgramListElementWrapper>
  );
};
