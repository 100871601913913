import { uniq } from "lodash";
import { TreeItemOption } from "./TreeSelectSearch";
import { Dispatch, SetStateAction } from "react";

export const handleCheckboxChange = (
  id: string,
  selected: string[],
  options: TreeItemOption[],
  onChange: Dispatch<SetStateAction<string[]>>,
  singleSelect?: boolean,
) => {
  const isAlreadySelected = selected.includes(id);

  const getAllChildrenIds = (children?: TreeItemOption[]) => {
    if (!children) return [];

    let childrenIds: string[] = [];
    children.forEach(child => {
      childrenIds.push(child.id);
      if (child.children) {
        childrenIds = [...childrenIds, ...getAllChildrenIds(child.children)];
      }
    });
    return childrenIds;
  };

  const dictionary = buildDictionary(options);

  const updateParentSelection = (currentParentId: string, id: string) => {
    if (singleSelect) return;

    const currentParent = dictionary.get(currentParentId);

    if (!currentParent) return;

    const siblings = currentParent.children?.map(child => child.id) || [];

    if (siblings.every(sibId => [...selected, id].includes(sibId))) {
      if (!selected.includes(currentParentId)) {
        onChange(state => uniq([...state, currentParentId]));
        updateParentSelection(currentParent.parentId, currentParent.id);
      }
    }
  };

  const currentElement = dictionary.get(id);

  if (singleSelect && currentElement) {
    const allChildrenIdsToAdd = getAllChildrenIds(currentElement.children);
    onChange(() => uniq([id, ...allChildrenIdsToAdd]));
  }

  if (singleSelect && isAlreadySelected) onChange([]);

  if (!singleSelect && isAlreadySelected && currentElement) {
    const allChildrenIdsToRemove = getAllChildrenIds(currentElement.children);
    onChange(state =>
      state.filter(
        s =>
          s !== id &&
          !allChildrenIdsToRemove.includes(s) &&
          !currentElement.allParents.includes(s),
      ),
    );
  }

  if (!isAlreadySelected && currentElement) {
    const allChildrenIdsToAdd = getAllChildrenIds(currentElement.children);
    onChange(state => uniq([...state, id, ...allChildrenIdsToAdd]));

    updateParentSelection(currentElement.parentId, id);
  }
};

export const simplifySelectedIds = (
  selectedIds: string[],
  options: TreeItemOption[],
): string[] => {
  const parentIdMap = new Map<string, string[]>();

  const buildParentMap = (options: TreeItemOption[]) => {
    options.forEach(option => {
      if (option.children && option.children.length > 0) {
        parentIdMap.set(
          option.id,
          option.children.map(child => child.id),
        );
        buildParentMap(option.children);
      }
    });
  };

  buildParentMap(options);

  const allChildrenSelected = (parentId: string) => {
    const childrenIds = parentIdMap.get(parentId);
    return childrenIds && childrenIds.every(id => selectedIds.includes(id));
  };

  const findHighestSelectedParent = (currentId: string): string => {
    let highestParentId = currentId;
    Array.from(parentIdMap.entries()).forEach(([parentId, childrenIds]) => {
      if (
        childrenIds.includes(highestParentId) &&
        allChildrenSelected(parentId)
      ) {
        highestParentId = findHighestSelectedParent(parentId);
      }
    });
    return highestParentId;
  };

  const simplifiedIds = new Set<string>();

  selectedIds.forEach(id => {
    const highestParentId = findHighestSelectedParent(id);
    simplifiedIds.add(highestParentId);
  });

  return Array.from(simplifiedIds);
};

export const mapAllSelectedIds = (
  selectedParentIds: string[],
  options: TreeItemOption[],
): string[] => {
  const optionMap = buildDictionary(options);

  const collectChildrenIds = (parentId: string): string[] => {
    const option = optionMap.get(parentId);
    if (!option || !option.children) return [];

    const ids: string[] = [];
    option.children.forEach(child => {
      ids.push(child.id, ...collectChildrenIds(child.id));
    });
    return ids;
  };

  const allIds = new Set<string>();
  selectedParentIds.forEach(parentId => {
    allIds.add(parentId);
    collectChildrenIds(parentId).forEach(id => allIds.add(id));
  });

  return Array.from(allIds);
};

export const buildDictionary = (options: TreeItemOption[]) => {
  const buildMap = (
    options: TreeItemOption[],
    map: Map<string, TreeItemOption> = new Map(),
  ): Map<string, TreeItemOption> => {
    options.forEach(option => {
      map.set(option.id, option);
      if (option.children) {
        buildMap(option.children, map);
      }
    });
    return map;
  };

  return buildMap(options);
};

export function filterData(
  data: TreeItemOption[],
  searchValue: string,
  setExpanded: Dispatch<SetStateAction<string[]>>,
): TreeItemOption[] {
  return data
    .map(node => {
      if (node.name.toLowerCase().includes(searchValue.toLowerCase())) {
        return node;
      }
      if (node.children) {
        const filteredChildren = filterData(
          node.children,
          searchValue,
          setExpanded,
        );
        if (filteredChildren.length > 0) {
          setExpanded(ids => [...ids, node.id.toString()]);
          return { ...node, children: filteredChildren };
        }
      }
      return null;
    })
    .filter((node): node is TreeItemOption => node !== null);
}
