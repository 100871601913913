import styled from "@emotion/styled";
import { Checkbox } from "@components/Checkbox";

export const CheckboxWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 2.5rem;
  padding: 0 0.75rem;
`;

export const CheckboxStyled = styled(Checkbox)`
  padding: 0.5rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;
