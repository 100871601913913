import { Drawer, IconButton, styled } from "@mui/material";

export const StyledDrawer = styled(Drawer)`
  & .MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper {
    display: flex;
    flex-direction: column;
    border-radius: 0;
    margin-top: 56px;
    max-height: calc(100% - 56px);
    width: 740px;
  }
`;

export const Header = styled("div")`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.palette.primary.light};
`;

export const Title = styled("span")`
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;
