import { Typography, styled } from "@mui/material";

export const ProductCellTitle = styled(Typography)`
  max-width: 23rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.small}px) {
    max-width: 33rem;
  }
`;

export const ProductCellWrapper = styled("div")`
  display: flex;
  position: relative;
`;

export const TotalTitle = styled(Typography)`
  position: absolute;
  width: 1.7rem;
  max-height: 1rem;
  height: 100%;
  font-size: 0.625rem;
  left: -1.25rem;
  top: 0.5rem;
  rotate: -90deg;
  overflow: visible;
`;
