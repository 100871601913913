import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const DollarInGreenRound = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      style={{ fill: "none" }}
      width="20"
      height="21"
      viewBox="0 0 20 21"
    >
      <rect x="1" y="1.5" width="18" height="18" rx="9" fill="#2A856C" />
      <rect
        x="1"
        y="1.5"
        width="18"
        height="18"
        rx="9"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M10.5625 6.71094C11.2188 6.80469 11.7812 6.90625 12.25 7.01562C12.5625 7.10938 12.6953 7.33594 12.6484 7.69531C12.5391 8.02344 12.3203 8.16406 11.9922 8.11719C11.6172 8.02344 11.1875 7.94531 10.7031 7.88281C10.2344 7.80469 9.77344 7.78125 9.32031 7.8125C8.86719 7.84375 8.50781 7.94531 8.24219 8.11719C8.00781 8.25781 7.85156 8.49219 7.77344 8.82031C7.72656 9.08594 7.74219 9.28125 7.82031 9.40625C7.88281 9.53125 8.01562 9.66406 8.21875 9.80469C8.67188 10.0547 9.3125 10.2734 10.1406 10.4609H10.1875C10.5625 10.5547 10.9375 10.6641 11.3125 10.7891C11.7031 10.9141 12.0547 11.0703 12.3672 11.2578C12.7109 11.4453 12.9844 11.7266 13.1875 12.1016C13.3594 12.4766 13.4062 12.9062 13.3281 13.3906C13.125 14.2188 12.625 14.7656 11.8281 15.0312C11.4375 15.1875 11.0156 15.2734 10.5625 15.2891V16.4375C10.5312 16.7812 10.3438 16.9688 10 17C9.65625 16.9688 9.46875 16.7812 9.4375 16.4375V15.2656C9.35938 15.2656 9.28125 15.2578 9.20312 15.2422H9.17969C8.88281 15.1953 8.52344 15.1172 8.10156 15.0078C7.66406 14.8984 7.28125 14.7734 6.95312 14.6328C6.65625 14.4609 6.5625 14.2188 6.67188 13.9062C6.84375 13.5938 7.09375 13.4922 7.42188 13.6016C7.6875 13.7266 8.00781 13.8359 8.38281 13.9297C8.75781 14.0234 9.08594 14.0938 9.36719 14.1406C10.2109 14.2344 10.8906 14.1875 11.4062 14C11.8906 13.7969 12.1641 13.5234 12.2266 13.1797C12.2734 12.8984 12.2578 12.7031 12.1797 12.5938C12.1172 12.4688 11.9844 12.3359 11.7812 12.1953C11.3125 11.9453 10.6719 11.7266 9.85938 11.5391H9.8125C9.4375 11.4453 9.0625 11.3359 8.6875 11.2109C8.29688 11.0859 7.94531 10.9375 7.63281 10.7656C7.28906 10.5625 7.01562 10.2734 6.8125 9.89844C6.625 9.52344 6.57812 9.09375 6.67188 8.60938C6.8125 7.9375 7.14062 7.45312 7.65625 7.15625C8.14062 6.875 8.66406 6.71875 9.22656 6.6875C9.28906 6.6875 9.35938 6.6875 9.4375 6.6875V5.5625C9.46875 5.21875 9.65625 5.03125 10 5C10.3438 5.03125 10.5312 5.21875 10.5625 5.5625V6.71094Z"
        fill="white"
      />
    </SvgIcon>
  );
};
