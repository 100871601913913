import { useForm } from "react-hook-form";
import { useEffect } from "react";

export const useMacrosSlidersForm = (
  defaultValues?: MacrosSlidersFormInput,
) => {
  const form = useForm<MacrosSlidersFormInput>({
    defaultValues: {
      kcal: 500,
      protein: 0,
      fat: 0,
      carbs: 0,
    },
  });

  useEffect(() => {
    if (defaultValues) {
      form.reset(defaultValues);
    }
  }, [defaultValues]);

  return form;
};

export interface MacrosSlidersFormInput {
  kcal: number;
  protein: number;
  fat: number;
  carbs: number;
}
