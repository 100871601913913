import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const CheckInGreenRound = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      style={{ fill: "none" }}
      width="24"
      height="25"
      viewBox="0 0 24 25"
    >
      <rect x="2" y="2.5" width="20" height="20" rx="10" fill="#2A856C" />
      <rect
        x="2"
        y="2.5"
        width="20"
        height="20"
        rx="10"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M16.1992 9.80078C16.3164 9.93099 16.375 10.0807 16.375 10.25C16.375 10.4193 16.3164 10.569 16.1992 10.6992L11.1992 15.6992C11.069 15.8164 10.9193 15.875 10.75 15.875C10.5807 15.875 10.431 15.8164 10.3008 15.6992L7.80078 13.1992C7.68359 13.069 7.625 12.9193 7.625 12.75C7.625 12.5807 7.68359 12.431 7.80078 12.3008C7.93099 12.1836 8.08073 12.125 8.25 12.125C8.41927 12.125 8.56901 12.1836 8.69922 12.3008L10.7305 14.3711L15.3008 9.80078C15.431 9.68359 15.5807 9.625 15.75 9.625C15.9193 9.625 16.069 9.68359 16.1992 9.80078Z"
        fill="white"
      />
    </SvgIcon>
  );
};
