import { Typography } from "@mui/material";

import { useAppTranslation } from "@hooks";

import { LegendCategory, LegendDot } from "./MonitoringCalendar.styled";
import { PERCENTAGE_BAR_COLORS } from "../monitoringUtils";
import {
  CalendarLegendWrapper,
  StyledRow,
  LegendItem,
} from "./CalendarLegend.styled";

export const CalendarLegend = () => {
  const { t } = useAppTranslation();

  return (
    <CalendarLegendWrapper>
      <StyledRow>
        <LegendCategory variant="caption">
          {t("monitoring.client_active_day")}:
        </LegendCategory>

        <div className="flex">
          <LegendItem>
            <LegendDot background={PERCENTAGE_BAR_COLORS.purple} />
            <Typography variant="caption">
              {t("monitoring.no_set_target")}
            </Typography>
          </LegendItem>

          <LegendItem>
            <LegendDot background={PERCENTAGE_BAR_COLORS.green} />
            <Typography variant="caption">
              {t("monitoring.to_5_percent")}
            </Typography>
          </LegendItem>

          <LegendItem>
            <LegendDot background={PERCENTAGE_BAR_COLORS.orange} />
            <Typography variant="caption">
              {t("monitoring.to_10_percent")}
            </Typography>
          </LegendItem>

          <LegendItem>
            <LegendDot background={PERCENTAGE_BAR_COLORS.red} />
            <Typography variant="caption">
              {t("monitoring.above_10_percent")}
            </Typography>
          </LegendItem>
        </div>
      </StyledRow>

      <StyledRow>
        <LegendCategory variant="caption">
          {t("monitoring.client_inactive_day")}:
        </LegendCategory>

        <div className="flex gap-2 ml-4">
          <LegendDot background={PERCENTAGE_BAR_COLORS.gray} />
          <Typography variant="caption">{t("monitoring.inactive")}</Typography>
        </div>
      </StyledRow>
    </CalendarLegendWrapper>
  );
};
