import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { updateTask } from "@client/tasks/updateTask";
import { TaskType } from "@views/dietician/Tasks/components/TasksAccordion/TasksAccordion";
import type { UpdateTaskRequestPayload } from "@client/tasks/updateTask";
import { ApiEndpoints } from "@typeDefinitions";
import { useAppTranslation } from "@hooks/useAppTranslation";

export const useUpdateTaskMutation = () => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation<
    AxiosResponse,
    AxiosError,
    UpdateTaskRequestPayload
  >(data => updateTask(data), {
    onSuccess: () => {
      queryClient.invalidateQueries([ApiEndpoints.Tasks]);
      queryClient.invalidateQueries([ApiEndpoints.TasksCount]);

      toast.success(t("tasks.updateSuccess"));
    },
  });

  const handleUpdateTask = async (data: UpdateTaskRequestPayload) => {
    try {
      await mutateAsync(data);
    } catch {
      toast.error(t("tasks.updateFailure"));
    }
  };

  return { handleUpdateTask };
};
