import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import KcalSummaryCardOf from "@views/dietician/PatientMonitoring2/components/SummaryCard/Kcal/KcalSummaryCardOf";
import { useFetchMonitoringNutrients } from "@hooks/queries/client/monitoring";
import { Dayjs } from "dayjs";
import { ENERGY_ID } from "@utils/macros";
import { NutrientsMonitoringResource } from "@client/resources/NutrientsMonitoringResource";

const KcalSummaryCardOfDay = () => {
  const { patientId, type, selectedDays } = useMonitoring();

  const { data } = useFetchMonitoringNutrients(
    {
      patientId,
      dateFrom: selectedDays.selectDays.from
        .clone()
        .add(-3, "days")
        .format("YYYY-MM-DD"),
      dateTo: selectedDays.selectDays.to
        .clone()
        .add(3, "days")
        .format("YYYY-MM-DD"),
    },
    { enabled: type.type === "day" },
  );

  return (
    <KcalSummaryCardOf
      actualValue={getActualValue(data?.data, selectedDays.selectDays.from)}
      targetValue={getTargetValue(data?.data, selectedDays.selectDays.from)}
    />
  );
};

const getActualValue = (
  data: NutrientsMonitoringResource[] | undefined,
  selectedDay: Dayjs,
) => {
  const day = (data ?? []).find(
    d => d.date === selectedDay.format("YYYY-MM-DD"),
  );

  if (day === undefined) {
    return 0;
  }

  return day.patientNutrients.find(n => n.id === ENERGY_ID)?.value ?? 0;
};

const getTargetValue = (
  data: NutrientsMonitoringResource[] | undefined,
  selectedDay: Dayjs,
) => {
  const day = (data ?? []).find(
    d => d.date === selectedDay.format("YYYY-MM-DD"),
  );
  if (day === undefined) {
    return null;
  }

  return day.dietitianNutrients.find(n => n.id === ENERGY_ID)?.value ?? null;
};

export default KcalSummaryCardOfDay;
