import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Referral = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 26 26">
    <g id="ic-menu-referral">
      <path
        id="Path_1665"
        data-name="Path 1665"
        d="M4.381,26A3.284,3.284,0,0,1,1.1,22.718V9.768A3.284,3.284,0,0,1,4.381,6.487h7.77a.65.65,0,0,1,.691.691.65.65,0,0,1-.691.691h-8.7l8.656,8.648a1.9,1.9,0,0,0,2.661.005l2.272-2.271a.679.679,0,0,1,.48-.226.787.787,0,0,1,.686.49.626.626,0,0,1-.178.722l-2.226,2.226a3.378,3.378,0,0,1-2.376.989,3.26,3.26,0,0,1-2.3-.951L2.481,8.84V22.718a1.9,1.9,0,0,0,1.9,1.9H22.512a1.9,1.9,0,0,0,1.9-1.9v-5.18a.691.691,0,1,1,1.381,0v5.18A3.284,3.284,0,0,1,22.512,26ZM25.123,13.05a1.982,1.982,0,0,1-1.986-1.73L23.1,11,19.8,9.424l-.271.152a1.957,1.957,0,0,1-2.887-1.751,1.883,1.883,0,0,1,1.986-1.986,1.948,1.948,0,0,1,1.074.368l.283.2,3.132-1.431V4.587A1.979,1.979,0,0,1,25.124,2.6a1.925,1.925,0,0,1,1.385.584A1.99,1.99,0,0,1,25.1,6.573a1.95,1.95,0,0,1-.981-.292l-.275-.164-4,1.82,3.792,1.739.287-.212A1.968,1.968,0,0,1,25.1,9.077a1.987,1.987,0,0,1,.021,3.973Z"
        transform="translate(-0.9 -2.424)"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
);
