import { useMemo, useState } from "react";

import { useQuery } from "@tanstack/react-query";

import { QueryOptionsTyped } from "./types";
import { ApiResponse } from "@typeDefinitions";
import { ClientChatSearchInput } from "@typeDefinitions/types";
import { parseString } from "@utils/filters";
import { ClientForChatDto, fetchClientsForChat } from "@client";

export const fetchClientsForChatQueryKey = "fetchClientsForChatQueryKey";

export const useFetchClientsForChatQuery = (
  options?: QueryOptionsTyped<ApiResponse<ClientForChatDto[]>>,
) => {
  const [committedInputs, setCommittedInputs] =
    useState<ClientChatSearchInput>();

  const params = useMemo(
    () => parseClinicClientsSearch(committedInputs),
    [committedInputs],
  );

  const { data, ...rest } = useQuery(
    [fetchClientsForChatQueryKey, params.toString()],
    () => fetchClientsForChat(params),
    options,
  );
  return {
    clients: data?.data,
    submit: setCommittedInputs,
    ...rest,
  };
};

const parseClinicClientsSearch = (filters?: ClientChatSearchInput) => {
  const search = new URLSearchParams();

  if (filters?.text?.trim()) {
    parseString(filters?.text, "text", search);
  }

  return search;
};
