import { useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";

import every from "lodash/every";
import includes from "lodash/includes";

import { ProductContextDto, RecipeContextDto } from "@client";
import { ClientContextDto } from "@client/diets/creator";
import { ApiResponse } from "@typeDefinitions";

import {
  usePostPatientProductsContextMutation,
  usePostPatientProductsContextMutationKey,
  usePostPatientRecipesContextMutation,
  usePostPatientRecipesContextMutationKey,
} from "./queries";
import { useAppParams } from "./useAppParams";
import { useAppTranslation } from "./useAppTranslation";

export const useMealClientContext = () => {
  const { t } = useAppTranslation();
  const { patientId } = useAppParams();
  const queryClient = useQueryClient();

  const { mutate: mutateProducts } = usePostPatientProductsContextMutation();
  const { mutate: mutateRecipes } = usePostPatientRecipesContextMutation();

  const productsContext = queryClient.getQueryData<
    ApiResponse<ProductContextDto[]>
  >([usePostPatientProductsContextMutationKey, patientId])?.data;
  const recipesContext = queryClient.getQueryData<
    ApiResponse<RecipeContextDto[]>
  >([usePostPatientRecipesContextMutationKey, patientId])?.data;

  const productsIdsContext = useMemo(
    () => productsContext?.map(({ id }) => id) ?? [],
    [productsContext],
  );
  const recipesIdsContext = useMemo(
    () => recipesContext?.map(({ id }) => id) ?? [],
    [recipesContext],
  );

  const productsClientContextMap = useMemo(
    () => mapListToClientContextMap(productsContext ?? []),
    [productsContext],
  );
  const recipesClientContextMap = useMemo(
    () => mapListToClientContextMap(recipesContext ?? []),
    [recipesContext],
  );

  const getPriorityColor = ({
    hasAllergens,
    hasUnliked,
    hasLiked,
  }: Partial<ClientContextDto>) => {
    if (hasAllergens) return "error";
    if (hasUnliked) return "warning";
    if (hasLiked) return "success";
    return;
  };
  const getPriorityText = ({
    hasAllergens,
    hasUnliked,
    hasLiked,
  }: Partial<ClientContextDto>) => {
    if (hasAllergens) return t("user_tags_tooltip.allergen");
    if (hasUnliked) return t("user_tags_tooltip.disliked");
    if (hasLiked) return t("user_tags_tooltip.liked");
    return;
  };

  const getSeverity = (
    props: Partial<ClientContextDto>,
  ): {
    color: "error" | "warning" | "success" | undefined;
    alert: string | undefined;
  } => {
    const color = getPriorityColor(props);
    const alert = getPriorityText(props);

    return { color, alert };
  };

  const getProducts = (payload: number[]) => {
    if (patientId && payload.length && !isSubset(payload, productsIdsContext))
      mutateProducts({ patientId, payload });
  };

  const getRecipes = (payload: number[]) => {
    if (patientId && payload.length && !isSubset(payload, recipesIdsContext))
      mutateRecipes({ patientId, payload });
  };

  const invalidateRecipes = () => {
    if (!recipesContext?.length) return;
    mutateRecipes({ patientId, payload: recipesContext.map(({ id }) => id) });
  };

  const invalidateProducts = () => {
    if (!productsContext?.length) return;
    mutateProducts({ patientId, payload: productsContext.map(({ id }) => id) });
  };

  const clearCache = () => {
    if (!patientId) return;

    queryClient.removeQueries([
      usePostPatientProductsContextMutationKey,
      patientId,
    ]);
    queryClient.removeQueries([
      usePostPatientRecipesContextMutationKey,
      patientId,
    ]);
  };

  return {
    productsClientContextMap,
    recipesClientContextMap,
    getSeverity,
    getProducts,
    getRecipes,
    invalidateRecipes,
    invalidateProducts,
    clearCache,
  };
};

const mapListToClientContextMap = (
  list: ProductContextDto[] | RecipeContextDto[],
): Map<number, ClientContextDto> => {
  const newMap = new Map<number, ClientContextDto>();

  for (const element of list) {
    newMap.set(element.id, element.clientContext);
  }

  return newMap;
};

function isSubset(subset: number[], mainSet: number[]) {
  return every(subset, id => includes(mainSet, id));
}
