import { useMemo, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { round } from "lodash";

import { TranslationDto } from "@client";
import { FetchCreatorDietResponseDto } from "@client/diets/creator";
import {
  DEFAULT_MACROS_RANGE,
  RecipesFiltersFormInput,
} from "@components/RecipesFiltersNew";
import { ListTabs, useAppParams, useAppTranslation } from "@hooks";
import {
  fetchCreatorDietQueryKey,
  useFetchCreatorDietMealsQuery,
} from "@hooks/queries/diets/creator";
import { Nutrient } from "@typeDefinitions";
import { ENERGY_ID } from "@utils";
import { getTranslation } from "@utils/translations";

import { AddElementSidePanel } from "./AddElementSidePanel/AddElementSidePanel";
import { ChartCard } from "./ChartsCard";
import { ItemsContextProvider } from "./ItemsContext";
import { MealRow } from "./MealRow";
import { NutrientsSidePanelContextProvider } from "./NutrientsSidePanel/NutrientsSidePanelContextProvider";

export const RecipesTab = () => {
  const { dietId } = useAppParams();
  const { currentLanguage } = useAppTranslation();
  const { meals } = useFetchCreatorDietMealsQuery(dietId);
  const [mealsToExpand, setMealsToExpand] = useState<number[]>([]);
  const [mealId, setMealId] = useState<number | null>(null);
  const [mealName, setMealName] = useState<string | null>(null);
  const [defaultFilters, setDefaultFilters] =
    useState<RecipesFiltersFormInput | null>(null);

  const queryClient = useQueryClient();
  const data = queryClient.getQueryData<FetchCreatorDietResponseDto>([
    fetchCreatorDietQueryKey,
    dietId,
  ]);
  const showTarget = useMemo(
    () => !!data?.data.showMacros,
    [data?.data.showMacros],
  );

  const handleOpenModal = (
    id: number,
    nutrients: Nutrient[],
    type: number | undefined,
    translations: TranslationDto[],
  ) => {
    const energyValue = nutrients.find(n => n.id === ENERGY_ID)?.value ?? 0;

    setDefaultFilters({
      tabs: ListTabs.ALL,
      page: 1,
      query: "",
      tags: type ? [type.toString()] : [],
      nutrients: {
        ...DEFAULT_MACROS_RANGE,
        [ENERGY_ID]: energyValue
          ? [Math.max(round(energyValue - 50), 0), round(energyValue + 50)]
          : DEFAULT_MACROS_RANGE[ENERGY_ID],
      },
    });
    setMealId(id);
    setMealName(getTranslation(translations, currentLanguage));
  };

  const handleClose = (expand?: boolean) => {
    setMealId(null);
    setDefaultFilters(null);

    if (expand && mealId) setMealsToExpand([mealId]);
  };

  return (
    <NutrientsSidePanelContextProvider>
      <div className="grid gap-[16px]">
        <ChartCard />

        {meals?.map(
          ({
            id,
            translations,
            hour,
            itemsCount,
            nutrients,
            targets,
            type,
          }) => (
            <MealRow
              key={id}
              hour={hour}
              mealId={id}
              onAddRecipes={() =>
                handleOpenModal(id, targets, type?.id, translations)
              }
              itemsCount={itemsCount}
              nutrients={nutrients}
              targets={targets}
              controlledExpanded={mealsToExpand.includes(id)}
              showTarget={showTarget}
              translations={translations}
              type={type?.id ?? null}
            />
          ),
        )}

        <ItemsContextProvider>
          <AddElementSidePanel
            mealId={mealId}
            onClose={handleClose}
            defaultFilters={defaultFilters ?? undefined}
            mealName={mealName}
          />
        </ItemsContextProvider>
      </div>
    </NutrientsSidePanelContextProvider>
  );
};
