import { ReactNode } from "react";

import { IconButton, InputAdornment, OutlinedInputProps } from "@mui/material";

import { OutlinedInputStyled } from "../CaloricNeedsForm.styled";
import { BalanceValueMethod } from "@utils/balance";
import { Edit, Reload } from "@assets/icons";

interface InputSuffixIconButtonProps
  extends Omit<OutlinedInputProps, "endAdornment"> {
  suffix?: ReactNode;
  method: BalanceValueMethod | null;
  onIconClick: () => void;
  iconDisabled?: boolean;
}

export const InputSuffixIconButton = ({
  suffix,
  method,
  onIconClick,
  iconDisabled,
  ...props
}: InputSuffixIconButtonProps) => {
  return (
    <OutlinedInputStyled
      {...props}
      endAdornment={
        <InputAdornment position="end">
          {suffix}
          <IconButton onClick={onIconClick} disabled={iconDisabled}>
            {method === BalanceValueMethod.MANUAL ? <Reload /> : <Edit />}
          </IconButton>
        </InputAdornment>
      }
    />
  );
};
