import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const MeetingPhone = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        d="M9.15264 5.67324C8.91201 5.16467 8.27764 4.89398 7.67139 5.0389L4.92139 5.69512C4.37764 5.82636 3.99951 6.25837 3.99951 6.75054C3.99951 13.5151 10.2683 19 17.9995 19C18.562 19 19.0558 18.6692 19.2058 18.1934L19.9558 15.7873C20.1214 15.2568 19.812 14.7018 19.2308 14.4912L16.2308 13.3975C15.7214 13.2116 15.1308 13.3401 14.7839 13.7147L13.5214 15.0627C11.3214 14.1522 9.54014 12.5936 8.49951 10.6687L10.0401 9.56682C10.4683 9.26059 10.6151 8.74655 10.4026 8.30086L9.15264 5.67598V5.67324Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
