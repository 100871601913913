import { useMemo } from "react";
import { useAppTranslation } from "@hooks/useAppTranslation";
import {
  fetchNutrientCategoriesQueryKey,
  useFetchNutrientCategoriesQuery,
} from "@hooks/queries";
import { fractionNutrientDigitsById } from "@utils";
import { useQueryClient } from "@tanstack/react-query";
import { NutrientCategory } from "@typeDefinitions";

export function useNutrients() {
  const { isPolishChosen } = useAppTranslation();
  const queryClient = useQueryClient();
  const cacheData = queryClient.getQueryData<NutrientCategory[]>([
    fetchNutrientCategoriesQueryKey,
  ]);
  const { nutrientCategories } = useFetchNutrientCategoriesQuery({
    enabled: !cacheData,
  });
  const categories = cacheData ?? nutrientCategories;

  const nutrientCategoriesDict = useMemo(() => {
    const categoriesMap = new Map<number, NutrientDetails[]>();

    categories
      ?.flatMap(category => category.nutrients)
      .forEach(nutrient => {
        const nutrientDetail: NutrientDetails = {
          id: nutrient.id,
          name: isPolishChosen
            ? nutrient.descriptionPl
            : nutrient.descriptionEn,
          unit: nutrient.units,
          short: isPolishChosen
            ? nutrient.shortNamePl ?? nutrient.descriptionPl.charAt(0)
            : nutrient.shortNameEn ?? nutrient.descriptionEn.charAt(0),
          categoryId: nutrient.nutrientCategoryId,
          roundPrecision: fractionNutrientDigitsById(
            nutrient.nutrientCategoryId,
            nutrient.id,
          ),
        };

        const categoryList =
          categoriesMap.get(nutrient.nutrientCategoryId) || [];
        categoryList.push(nutrientDetail);
        categoriesMap.set(nutrient.nutrientCategoryId, categoryList);
      });

    return categoriesMap;
  }, [categories, isPolishChosen]);

  const nutrientDict = useMemo(() => {
    const nutrientsMap = new Map<number, NutrientDetails>();

    categories
      ?.flatMap(category => category.nutrients)
      .forEach(nutrient => {
        const nutrientDetail = {
          id: nutrient.id,
          name: isPolishChosen
            ? nutrient.descriptionPl
            : nutrient.descriptionEn,
          unit: nutrient.units,
          short: isPolishChosen
            ? nutrient.shortNamePl ?? nutrient.descriptionPl.charAt(0)
            : nutrient.shortNameEn ?? nutrient.descriptionEn.charAt(0),
          categoryId: nutrient.nutrientCategoryId,
          roundPrecision: fractionNutrientDigitsById(
            nutrient.nutrientCategoryId,
            nutrient.id,
          ),
        };

        nutrientsMap.set(nutrient.id, nutrientDetail);
      });

    return nutrientsMap;
  }, [categories, isPolishChosen]);

  const sortOrder = useMemo(() => {
    const idMap = new Map<number, number>();
    Array.from(nutrientDict).forEach(([key, value], idx) => {
      idMap.set(value.id, idx);
    });

    return idMap;
  }, [nutrientDict]);

  return {
    categoryDict: nutrientCategoriesDict,
    nutrientDict,
    sortOrder,
  };
}

interface NutrientDetails {
  id: number;
  name: string;
  short: string;
  unit: string;
  categoryId: number;
  roundPrecision: number;
}
