import { useCallback, useRef } from "react";

import { CircularProgress } from "@mui/material";

import { InfiniteListStyled } from "./InfiniteList.styled";
import { FiltersNoResults } from "@views/emptyStates/FiltersNoResults";

interface InfiniteListProps<T> {
  list: T[];
  Element: (props: T) => JSX.Element;
  isFetchingNextPage: boolean;
  isLoading: boolean;
  hasNextPage: boolean | undefined;
  fetchNextPage: () => void;
  clearFilters?: () => void;
}
export const InfiniteList = <T,>({
  list,
  Element,
  isFetchingNextPage,
  isLoading,
  hasNextPage,
  fetchNextPage,
  clearFilters,
}: InfiniteListProps<T>) => {
  const observer = useRef<IntersectionObserver | null>(null);
  const lastRecipeElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (isFetchingNextPage) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });
      if (node) observer.current?.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage],
  );

  if (isLoading)
    return (
      <div className="grid place-items-center">
        <CircularProgress />
      </div>
    );

  return (
    <InfiniteListStyled>
      {!list.length && <FiltersNoResults onClick={clearFilters} />}

      {!!list.length &&
        list?.map((el, idx) => {
          if (list.length === idx + 1)
            return (
              <div key={idx} ref={lastRecipeElementRef}>
                <Element key={idx} {...el} />
              </div>
            );

          return <Element key={idx} {...el} />;
        })}

      {isFetchingNextPage && (
        <div className="grid place-items-center">
          <CircularProgress />
        </div>
      )}
    </InfiniteListStyled>
  );
};
