import { Nutrient } from "@typeDefinitions";
import { GLYCEMIC_INDEX_ID } from "@utils";

export const calcProportionalNutrients = (
  nutrients: Nutrient[],
  grams: number,
  portionRatio: number,
) => {
  const multiplier = grams / 100;

  return nutrients.map(nutrient => {
    if (nutrient.id !== GLYCEMIC_INDEX_ID) {
      return {
        id: nutrient.id,
        value: nutrient.value * multiplier * portionRatio,
      };
    } else {
      return nutrient;
    }
  });
};
