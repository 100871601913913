import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const updateDietNutrientsNorm = async (
  dietId: string,
  payload: DietNutrientsNormRequest,
) => {
  await fetchData(
    `/dietitian/diets/${dietId}/nutrients-norm`,
    APIMethods.PUT,
    payload,
  );
};

export interface DietNutrientsNormRequest {
  normId: number;
  nutrients: NormNutrientDto[];
}

interface NormNutrientDto {
  nutrientId: number;
  value: number;
  show: boolean;
}
