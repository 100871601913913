import { useEffect, useState } from "react";

import Talk from "talkjs";

import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";

export const useSession = () => {
  const { account } = useFetchDietitianAccountQuery();
  const [session, setSession] = useState<Talk.Session>();
  useEffect(() => {
    if (!account || !Talk?.User) return;
    const dietitian = new Talk.User({
      id: `${account.id}`,
      name: `${account.firstName + " " + account.lastName}`,
      email: `${account.email}`,
      photoUrl: null,
      role: "default",
      locale: account.language,
    });
    const session = new Talk.Session({
      appId: import.meta.env.VITE_APP_TALKJS_APP_ID,
      me: dietitian,
    });

    setSession(session);
  }, [account?.id, Talk?.User]);

  return {
    session,
  };
};
