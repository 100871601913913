import { useEffect } from "react";
import { useForm } from "react-hook-form";

import * as yup from "yup";

import {
  UserTargetBodyWeightAnswerQuestionDto,
  UpdateSingedSurveyRequest,
} from "@client/surveys";
import { yupResolver } from "@hookform/resolvers/yup";

export const useTargetBodyWeightAnswerForm = (
  values: TargetBodyWeightAnswerFormInputs | undefined,
  required: boolean,
) => {
  const resolver = yup.object().shape({
    value: required ? yup.string().required() : yup.string().nullable(),
  });

  const form = useForm<TargetBodyWeightAnswerFormInputs>({
    resolver: yupResolver(resolver),
  });

  useEffect(() => {
    if (values) form.reset(values);
  }, [values]);

  return form;
};

export const mapTargetBodyWeightAnswerForm = (
  data?: UserTargetBodyWeightAnswerQuestionDto,
): TargetBodyWeightAnswerFormInputs => {
  if (!data) return { value: null };
  return { value: String(data.value) };
};

export const mapTargetBodyWeightAnswerRequest = (
  data: TargetBodyWeightAnswerFormInputs,
  questionId: number,
): UpdateSingedSurveyRequest => {
  if (!data.value) return { questionId, answer: { value: null } };
  return { questionId, answer: { value: Number(data.value) } };
};

export interface TargetBodyWeightAnswerFormInputs {
  value: string | null;
}
