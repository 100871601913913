import { round, uniq } from "lodash";

import {
  CARBS_ID,
  ENERGY_PER_GRAM_OF_CARB,
  ENERGY_PER_GRAM_OF_FAT,
  ENERGY_PER_GRAM_OF_PROTEIN,
  FATS_ID,
  MACROS_ARRAY,
  PROTEIN_ID,
} from "@utils/macros";
import { MacrosUnits, Nutrient } from "@typeDefinitions";
import { NormNutrientDto, ProgramDayNutrientDto } from "@client";

export function sumMacros(fat?: number, carbs?: number, protein?: number) {
  return (
    (fat ?? 0) * ENERGY_PER_GRAM_OF_FAT +
    (carbs ?? 0) * ENERGY_PER_GRAM_OF_CARB +
    (protein ?? 0) * ENERGY_PER_GRAM_OF_PROTEIN
  );
}

export function calculatePercentage(sum: number, energy: number) {
  return round((sum / (energy || 1)) * 100);
}

export const percentageFromNutrients = (nutrients: Nutrient[] | undefined) => {
  const findNutrient = (idToFind: number) =>
    nutrients?.find(({ id }) => id === idToFind)?.value ?? 0;

  const proteinKcal = findNutrient(PROTEIN_ID) * ENERGY_PER_GRAM_OF_PROTEIN;
  const fatKcal = findNutrient(FATS_ID) * ENERGY_PER_GRAM_OF_FAT;
  const carbsKcal = findNutrient(CARBS_ID) * ENERGY_PER_GRAM_OF_CARB;

  const sum = proteinKcal + fatKcal + carbsKcal;

  return {
    protein: calculatePercentage(proteinKcal, sum),
    fat: calculatePercentage(fatKcal, sum),
    carbs: calculatePercentage(carbsKcal, sum),
  };
};

export const percentageFromNutrientsArray = (
  nutrients: Nutrient[] | undefined,
): { id: number; value: number }[] => {
  const findNutrient = (idToFind: number) =>
    nutrients?.find(({ id }) => id === idToFind)?.value ?? 0;

  const proteinKcal = findNutrient(PROTEIN_ID) * ENERGY_PER_GRAM_OF_PROTEIN;
  const fatKcal = findNutrient(FATS_ID) * ENERGY_PER_GRAM_OF_FAT;
  const carbsKcal = findNutrient(CARBS_ID) * ENERGY_PER_GRAM_OF_CARB;

  const sum = proteinKcal + fatKcal + carbsKcal;

  return [
    { id: PROTEIN_ID, value: calculatePercentage(proteinKcal, sum) },
    { id: FATS_ID, value: calculatePercentage(fatKcal, sum) },
    { id: CARBS_ID, value: calculatePercentage(carbsKcal, sum) },
  ];
};

export function labelFactory(
  value: number,
  min: number,
  max: number,
  percent?: boolean,
) {
  if (!value) return "0";
  if (value < min) return `<${min}${percent ? "%" : ""}`;
  if (value > max) return `>${max}${percent ? "%" : ""}`;
  return `${value}${percent ? "%" : ""}`;
}

export function calculateSliderValue(
  energy: number,
  value: number,
  multiplier: number,
) {
  return energy !== 0 ? round(((value * multiplier) / energy) * 100) : 0;
}

export function displayValueFactory(
  unit: string,
  value: number,
  sliderValue: number,
  weight?: number,
) {
  if (unit === MacrosUnits.GRAMS) return value;
  if (unit === MacrosUnits.PERCENTAGES) return sliderValue;
  if (unit === MacrosUnits.GRAMS_PER_KILO)
    return round(value / (weight ?? 1), 1);
  else return 0;
}

export function valueChangeHandler(
  value: string,
  unit: string,
  multiplier: number,
  energy: number,
  weight: number | undefined,
) {
  const parsedValue = parseFloat(value.replace(",", ".")) || 0;
  if (unit === MacrosUnits.GRAMS) {
    return parsedValue;
  }
  if (unit === MacrosUnits.PERCENTAGES) {
    return round((energy * parsedValue) / 100 / multiplier);
  }
  if (unit === MacrosUnits.GRAMS_PER_KILO) {
    return round(parsedValue * (weight ?? 1));
  } else return;
}

export function calculateValueFromSlider(
  energy: number,
  value: number,
  multiplier: number,
) {
  return round((energy * value) / 100 / multiplier);
}

export function normNutrientsMapper(
  nutrients: number[],
  programNutrients: ProgramDayNutrientDto[] | undefined,
  normNutrients: NormNutrientDto[] | undefined,
): { id: number; value: number; visible: boolean }[] {
  return nutrients.map(nId => {
    const fromProgram = programNutrients?.find(({ id }) => nId === id);
    const fromNorm = normNutrients?.find(({ id }) => nId === id);

    if (MACROS_ARRAY.includes(nId)) {
      return {
        id: nId,
        value: fromProgram?.value ?? 0,
        visible: true,
      };
    } else {
      return {
        id: nId,
        value: fromNorm?.value ?? 0,
        visible: !!fromProgram?.visible,
      };
    }
  });
}

export function nutrientsIdMapper(
  normNutrients?: NormNutrientDto[],
  programNutrients?: ProgramDayNutrientDto[],
): number[] {
  return uniq([
    ...(normNutrients?.map(n => n.id) ?? []),
    ...(programNutrients?.map(n => n.id) ?? []),
  ]);
}
