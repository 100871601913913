import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const RestaurantDish = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="10" viewBox="0 0 14 10">
      <path
        d="M5.98438 0.125C5.64668 0.125 5.375 0.39668 5.375 0.734375C5.375 1.07207 5.64668 1.34375 5.98438 1.34375H6.39062V2.18926C3.53672 2.49141 1.3125 4.90859 1.3125 7.84375H12.6875C12.6875 4.90859 10.4633 2.49141 7.60938 2.18926V1.34375H8.01562C8.35332 1.34375 8.625 1.07207 8.625 0.734375C8.625 0.39668 8.35332 0.125 8.01562 0.125H7H5.98438ZM1.10938 8.65625C0.77168 8.65625 0.5 8.92793 0.5 9.26562C0.5 9.60332 0.77168 9.875 1.10938 9.875H12.8906C13.2283 9.875 13.5 9.60332 13.5 9.26562C13.5 8.92793 13.2283 8.65625 12.8906 8.65625H1.10938Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
