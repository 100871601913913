import { fetchData } from "@utils/api";
import { APIMethods, ApiEndpoints } from "@typeDefinitions";
import { Task, taskSchema } from "./fetchTasks";
import { object } from "yup";

export const fetchSingleTask = async (id: string) => {
  const response = await fetchData<SingleTaskResponse>(
    `${ApiEndpoints.Tasks}/${id}`,
    APIMethods.GET,
  );

  return singleTaskResponseSchema.validate(response);
};

export interface SingleTaskResponse {
  data: Task;
}

const singleTaskResponseSchema = object({
  data: taskSchema,
});
