import React from "react";
import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Chevron = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path
      id="Stroke_1"
      data-name="Stroke 1"
      d="M14,0,7,7,0,0"
      transform="translate(3 7)"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </SvgIcon>
);
