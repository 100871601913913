import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const LikeMonitoring = (props: SvgIconProps) => (
  <SvgIcon width="14" height="12" viewBox="0 0 14 12" {...props}>
    <path
      d="M2.9375 4.5C3.36914 4.5 3.75 4.88086 3.75 5.3125V10.9492C3.75 11.3809 3.36914 11.7363 2.9375 11.7363H1.3125C0.855469 11.7363 0.5 11.3809 0.5 10.9492V5.28711C0.5 4.85547 0.855469 4.5 1.3125 4.5H2.9375ZM13.5 5.38867C13.5 5.99805 13.1699 6.50586 12.7129 6.81055C12.7383 6.96289 12.7637 7.08984 12.7637 7.2168C12.7637 7.80078 12.459 8.30859 12.0273 8.61328C12.0273 8.68945 12.0273 8.76562 12.0273 8.8418C12.0273 9.42578 11.748 9.93359 11.291 10.2383C11.2402 11.127 10.5039 11.8125 9.61523 11.8125H8.26953C7.35547 11.8125 6.4668 11.5332 5.73047 10.9746L4.79102 10.2891C4.63867 10.1621 4.53711 9.98438 4.53711 9.78125C4.53711 9.47656 4.81641 9.17188 5.17188 9.17188C5.29883 9.17188 5.42578 9.22266 5.52734 9.29883L6.4668 10.0098C6.97461 10.3906 7.60938 10.6191 8.26953 10.6191H9.61523C9.86914 10.6191 10.0977 10.3906 10.0977 10.1367C10.0977 10.0352 10.0469 10.0098 10.0469 9.88281C10.0469 9.19727 10.8086 9.42578 10.8086 8.8418C10.8086 8.61328 10.6562 8.5625 10.6562 8.2832C10.6562 7.52148 11.5449 7.85156 11.5449 7.2168C11.5449 6.88672 11.2402 6.88672 11.2402 6.48047C11.2402 6.17578 11.4941 5.89648 11.8242 5.87109C12.0781 5.87109 12.2812 5.64258 12.2812 5.38867C12.2812 5.13477 12.0527 4.93164 11.7734 4.90625H8.29492C7.96484 4.90625 7.68555 4.67773 7.68555 4.32227C7.68555 4.2207 7.71094 4.11914 7.76172 4.01758C8.21875 3.20508 8.42188 2.39258 8.42188 2.18945C8.42188 1.98633 8.26953 1.68164 7.83789 1.68164C7 1.68164 7.48242 3.23047 5.52734 4.7793C5.42578 4.88086 5.29883 4.90625 5.17188 4.90625C4.81641 4.90625 4.5625 4.62695 4.5625 4.29688C4.5625 3.61133 5.27344 3.99219 6.08594 1.93555C6.33984 1.27539 6.66992 0.462891 7.83789 0.462891C8.95508 0.462891 9.64062 1.32617 9.64062 2.18945C9.64062 2.51953 9.48828 3.07812 9.25977 3.71289H11.7988C12.7129 3.71289 13.5 4.47461 13.5 5.38867Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
