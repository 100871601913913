import {
  DialogSectionItemLabel,
  DialogSectionItemLayout,
} from "@views/dietician/ClientHealth/ClientHealthNew.styled";
import { useAppTranslation } from "@hooks";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  Stack,
  styled,
} from "@mui/material";
import {
  Control,
  Controller,
  FieldPath,
  useFormContext,
} from "react-hook-form";
import { FormPops } from "@views/dietician/ClientHealth/ProfileTab/NutrientsSection/Dialog/NutrientsForm";
import ImportantField from "@views/dietician/ClientHealth/ProfileTab/NutrientsSection/Dialog/ImportantField";
import FormControl from "@components/Alloweat/Inputs/FormControl";
import MealsMacroTargetField from "@views/dietician/ClientHealth/ProfileTab/NutrientsSection/Dialog/MealsMacroTargetField";
import NutrientsLabelLayout from "@views/dietician/ClientHealth/ProfileTab/NutrientsSection/Dialog/NutrientsLabelLayout";

const ShowNutrientsField = () => {
  const { t } = useAppTranslation();
  const form = useFormContext<FormPops>();

  return (
    <DialogSectionItemLayout>
      <DialogSectionItemLabel>
        {t(
          "patient.health_and_goal.dietary_profile_tab.nutritional_values_modal.visible_to_client",
        )}
      </DialogSectionItemLabel>
      <Stack direction="column" gap="16px">
        <CheckboxController
          control={form.control}
          name="kcal"
          label={t(
            "patient.health_and_goal.dietary_profile_tab.nutritional_values_modal.kcal",
          )}
          description={t(
            "patient.health_and_goal.dietary_profile_tab.nutritional_values_modal.kcal_desc",
          )}
        />
        <CheckboxController
          control={form.control}
          name="macro"
          label={t(
            "patient.health_and_goal.dietary_profile_tab.nutritional_values_modal.macro",
          )}
          description={t(
            "patient.health_and_goal.dietary_profile_tab.nutritional_values_modal.macro_desc",
          )}
        />
        <MealsMacroTargetField />
        <CheckboxController
          control={form.control}
          name="micro"
          label={t(
            "patient.health_and_goal.dietary_profile_tab.nutritional_values_modal.micro",
          )}
          description={t(
            "patient.health_and_goal.dietary_profile_tab.nutritional_values_modal.micro_desc",
          )}
        />
        <ImportantField />
      </Stack>
    </DialogSectionItemLayout>
  );
};

type CheckboxControllerProps = {
  control: Control<FormPops, any>;
  name: FieldPath<FormPops>;
  label: string;
  description: string;
};
const CheckboxController = ({
  control,
  name,
  label,
  description,
}: CheckboxControllerProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <FormControlLabel
          sx={{ margin: "unset", alignItems: "self-start" }}
          control={
            <Checkbox
              checked={!!value}
              onChange={onChange}
              sx={{ marginTop: "3px" }}
            />
          }
          label={
            <NutrientsLabelLayout label={label} description={description} />
          }
        />
      )}
    />
  );
};

export default ShowNutrientsField;
