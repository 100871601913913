import { IrrigationMonitoring } from "@assets/icons/Monitoring";
import SummaryCard from "@views/dietician/PatientMonitoring2/components/SummaryCard/SummaryCard";
import { useAppTranslation } from "@hooks";
import { useTheme } from "@mui/material";

type IrrigationSummaryCardOfProps = {
  actualValue: number;
  targetValue: number;
};

const IrrigationSummaryCardOf = ({
  actualValue,
  targetValue,
}: IrrigationSummaryCardOfProps) => {
  const { t } = useAppTranslation();
  const { palette } = useTheme();

  return (
    <SummaryCard
      boxId="monotoring_irrigation_box"
      icon={<IrrigationMonitoring fill="#5252E5" />}
      title={t("monitoring2.summary_cards.irrigation_title")}
      actualValue={actualValue}
      targetValue={targetValue}
      unit="l"
      color={palette.info.main}
      round={value => value.toFixed(2)}
    />
  );
};

export default IrrigationSummaryCardOf;
