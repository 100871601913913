import { ClickAwayListener, IconButton } from "@mui/material";
import { ReactNode } from "react";
import { Header, StyledDrawer, Title } from "./SidePanelWrapper.styled";
import { Close } from "@assets/icons/DesignSystem";
import { ThemeProviderWrapperNew } from "themeNew";

interface SidePanelWrapperProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  title?: string | ReactNode;
}
export const SidePanelWrapper = ({
  open,
  onClose,
  children,
  title,
}: SidePanelWrapperProps) => {
  return (
    <ClickAwayListener onClickAway={onClose}>
      <div>
        <ThemeProviderWrapperNew>
          <StyledDrawer
            open={open}
            variant="persistent"
            anchor="right"
            onClose={onClose}
          >
            <Header>
              <Title>{title}</Title>

              <IconButton size="small" onClick={onClose}>
                <Close size="w-[12px] h-[12px]" />
              </IconButton>
            </Header>
            {children}
          </StyledDrawer>
        </ThemeProviderWrapperNew>
      </div>
    </ClickAwayListener>
  );
};
