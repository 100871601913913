import { ReactElement } from "react";
import { Check } from "@assets/icons";
import { ColorButton, ColorCircle } from "./ColorPicker.styled";

export interface ColorPickerButtonProps {
  color: string;
  onChange: (color: string) => void;
  outlined?: boolean;
  selected?: boolean;
}

export const ColorPickerButton = ({
  color,
  onChange,
  outlined = false,
  selected,
}: ColorPickerButtonProps): ReactElement => {
  const onClick = () => {
    onChange(color);
  };

  return (
    <ColorButton onClick={onClick} outlined={outlined} selected={selected}>
      <ColorCircle backgroundColor={color}>
        {selected && !outlined && <Check />}
      </ColorCircle>
    </ColorButton>
  );
};
