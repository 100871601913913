import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

import { ActionsDto, actionsSchema } from "./common";
import { ClientContextDto, clientContextSchema } from "./diets/creator";

export async function searchRecipesNew(
  params?: URLSearchParams,
): Promise<
  ApiResponse<(RecipeSearchResourceDto | ClientRecipeSearchResourceDto)[]>
> {
  const data = await fetchData(
    "/dietitian/recipes/search-new",
    APIMethods.GET,
    undefined,
    params,
  );

  return await apiResponseSchema(params).validate(data);
}

export const tagResourceSchema = yup.object({
  id: yup.number().required(),
  systemId: yup.number().nullable().default(null),
  descriptionPl: yup.string().required(),
  descriptionEn: yup.string().required(),
  clinicId: yup.number().nullable().default(null),
  tagCategoryId: yup.number().required(),
});

const nutrientValueResourceSchema = yup.object({
  id: yup.number().required(),
  value: yup.number().required(),
});

const mediaSchema = yup.object({
  url: yup.string().nullable().default(null),
});

const apiResponseSchema = (params?: URLSearchParams) => {
  const hasPatient = params?.has("patient");

  return yup.object({
    data: yup
      .array()
      .of(
        yup.object({
          id: yup.number().required(),
          title: yup.string().required(),
          titleEn: yup.string().nullable().default(null),
          servings: yup.number().required(),
          favorite: yup.array().of(yup.number().required()).required(),
          tags: yup.array().of(tagResourceSchema).required(),
          macros: yup.array().of(nutrientValueResourceSchema).required(),
          actions: actionsSchema.required(),
          media: mediaSchema.required(),
          ...(hasPatient
            ? { clientContext: clientContextSchema.required() }
            : {}),
        }),
      )
      .required(),
  });
};

export interface RecipeSearchResourceDto {
  id: number;
  title: string;
  titleEn: string | null;
  servings: number;
  favorite: number[];
  tags: TagResourceDto[];
  macros: NutrientValueResourceDto[];
  actions: ActionsDto;
  media: MediaDto;
}

export interface ClientRecipeSearchResourceDto extends RecipeSearchResourceDto {
  clientContext: ClientContextDto;
}

interface MediaDto {
  url: string | null;
}
export interface TagResourceDto {
  id: number;
  systemId: number | null;
  descriptionPl: string;
  descriptionEn: string;
  clinicId: number | null;
  tagCategoryId: number;
}

interface NutrientValueResourceDto {
  id: number;
  value: number;
}
