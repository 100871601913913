import { Drawer, IconButton, styled } from "@mui/material";

export const StyledDrawer = styled(Drawer)`
  .MuiBackdrop-root {
    background: transparent;
  }
` as typeof Drawer;

export const DrawerHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
`;

export const StyledListWrapper = styled("div")`
  overflow-y: auto;
  scrollbar-width: none;
  flex: 1;
`;
export const StyledList = styled("ul")`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
`;

export const CloseButton = styled(IconButton)`
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;
