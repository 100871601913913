import { useEffect, useState } from "react";
import { useBlocker, useNavigate } from "react-router-dom";

import { useIsMutating } from "@tanstack/react-query";

import { useAppTranslation } from "@hooks";

import {
  AutosaveStatusWrapper,
  TypographyStyled,
} from "./AutosaveStatus.styled";
import { useMediaQuery, useTheme } from "@mui/material";

interface AutosaveStatusProps {
  savingText?: string;
  savedText?: string;
  compact?: boolean;
}

export const AutosaveStatus = ({
  savingText = "common.saving",
  savedText = "common.saved",
  compact,
}: AutosaveStatusProps) => {
  const isMutating = useIsMutating();
  const { t } = useAppTranslation();
  const navigate = useNavigate();

  const { breakpoints } = useTheme();
  const downSmall = useMediaQuery(`${breakpoints.down("smallNew")}`);

  const [nextPath, setNextPath] = useState<string | null>(null);

  useBlocker(args => {
    !!isMutating && setNextPath(args.nextLocation.pathname);
    return !!isMutating;
  });

  useEffect(() => {
    if (nextPath !== null && !isMutating) {
      navigate(nextPath);
      setNextPath(null);
    }
  }, [nextPath, isMutating]);

  if (isMutating && (downSmall || compact))
    return <img src="/static/cloudchange.svg" alt="saving" />;

  if (isMutating)
    return (
      <AutosaveStatusWrapper>
        <img src="/static/cloudchange.svg" alt="saving" />
        <TypographyStyled>{t(savingText)}...</TypographyStyled>
      </AutosaveStatusWrapper>
    );

  if (downSmall || compact)
    return <img src="/static/cloudadd.svg" alt="saved" />;

  return (
    <AutosaveStatusWrapper>
      <img src="/static/cloudadd.svg" alt="saved" />
      <TypographyStyled>{t(savedText)}</TypographyStyled>
    </AutosaveStatusWrapper>
  );
};
