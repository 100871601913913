import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const ArrowLeft = (props: SvgIconProps) => {
  return (
    <SvgIcon width="8" height="14" viewBox="0 0 8 14" {...props}>
      <path
        d="M6.46875 13.5312L0.75 7.5625C0.583333 7.35417 0.5 7.16667 0.5 7C0.5 6.8125 0.572917 6.63542 0.71875 6.46875L6.4375 0.5C6.77083 0.208333 7.125 0.208333 7.5 0.5C7.79167 0.833333 7.79167 1.1875 7.5 1.5625L2.28125 7L7.53125 12.4688C7.82292 12.8438 7.82292 13.1979 7.53125 13.5312C7.15625 13.8229 6.80208 13.8229 6.46875 13.5312Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
