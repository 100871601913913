import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "./types";
import { fetchProgramQueryKey } from "./useFetchProgramQuery";
import { fetchPatientProgramQueryKey } from "./useFetchPatientProgramQuery";
import {
  fetchDietitianClientProgramScheduleKey,
  fetchDietitianProgramScheduleKey,
} from "./schedule";
import { deleteProgramDayDiet, DeleteProgramDayDietRequest } from "@client";

export const deleteProgramDayDietMutation = "deleteProgramDayDietMutation";

export const useDeleteProgramDayDietMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ programId, dayId }: DeleteProgramDayDietRequest) =>
      deleteProgramDayDiet({ programId, dayId }),
    {
      ...options,
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries([
          fetchProgramQueryKey,
          parseInt(variables?.programId),
        ]);
        queryClient.invalidateQueries([fetchPatientProgramQueryKey]);
        queryClient.invalidateQueries([fetchDietitianProgramScheduleKey]);
        queryClient.invalidateQueries([fetchDietitianClientProgramScheduleKey]);
        options?.onSuccess && options.onSuccess();
      },
      mutationKey: [deleteProgramDayDietMutation],
    },
  );
};
