import { useQuery } from "@tanstack/react-query";
import { buildDefaultQueryOptions } from "./common";
import { SectionDto, fetchProgramSections } from "@client";
import { ApiResponse } from "@typeDefinitions";
import { QueryOptionsTyped } from "./types";

export const useFetchProgramSectionsQueryKey =
  "fetchDietitianProgramSectionsCategory";

export const useFetchProgramSectionsQuery = (
  programId: number,
  options?: QueryOptionsTyped<ApiResponse<SectionDto[]>>,
) => {
  const { data, ...rest } = useQuery(
    [useFetchProgramSectionsQueryKey, programId],
    () => fetchProgramSections(programId),
    buildDefaultQueryOptions(options),
  );

  return { sections: data?.data, ...rest };
};
