import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { ApiEndpoints } from "@typeDefinitions";
import { useAppTranslation } from "@hooks/useAppTranslation";

import { createNote } from "@client/notes/createNote";
import type { CreateNoteRequestPayload } from "@client/notes/createNote";

export const useCreateNoteMutation = () => {
  const queryClient = useQueryClient();
  const { t } = useAppTranslation();

  const { mutateAsync } = useMutation<
    AxiosResponse,
    AxiosError,
    CreateNoteRequestPayload
  >(payload => createNote(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries([ApiEndpoints.AccountNotes]);

      toast.success(t("notes.createSuccess"));
    },
  });

  const handleCreateNote = async (payload: CreateNoteRequestPayload) => {
    try {
      await mutateAsync(payload);
    } catch (error) {
      toast.error(t("notes.createFailure"));
    }
  };

  return { handleCreateNote };
};
