import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import * as yup from "yup";

export const fetchClientMeasurement = async (
  id: string,
): Promise<ApiResponse<FetchClientMeasurementResponse>> => {
  const response = await fetchData(
    `/dietitian/patient-measurements/${id}`,
    APIMethods.GET,
  );
  return await fetchClientMeasurementSchema.validate(response);
};

export enum MeasurementSource {
  DIETITIAN = 1,
  PATIENT_SURVEY = 2,
  PATIENT_MOBILE_APP = 3,
}

const idSchema = yup.object().shape({
  id: yup.number().required(),
});

const sourceSchema = yup.object().shape({
  id: yup
    .number()
    .oneOf([
      MeasurementSource.DIETITIAN,
      MeasurementSource.PATIENT_SURVEY,
      MeasurementSource.PATIENT_MOBILE_APP,
    ])
    .required(),
});

const mediaSchema = yup.object().shape({
  originalName: yup.string().required(),
});

const photoSchema = yup.object().shape({
  id: yup.number().required(),
  url: yup.string().required(),
  type: idSchema.required(),
  name: yup.string().nullable().default(null),
  media: mediaSchema.required(),
});

const clientMeasurementSchema = yup.object().shape({
  id: yup.string().required(),
  source: sourceSchema.required(),
  date: yup.string().nullable().default(null),
  growth: yup.number().nullable().default(null),
  weight: yup.number().nullable().default(null),
  bodyFatLevel: yup.number().nullable().default(null),
  arm: yup.number().nullable().default(null),
  tightBiceps: yup.number().nullable().default(null),
  waist: yup.number().nullable().default(null),
  hip: yup.number().nullable().default(null),
  thigh: yup.number().nullable().default(null),
  calf: yup.number().nullable().default(null),
  photos: yup.array().of(photoSchema).required(),
});

const fetchClientMeasurementSchema = yup.object().shape({
  data: clientMeasurementSchema.required(),
});

export interface FetchClientMeasurementResponse {
  id: string;
  source: SourceDto;
  date: string | null;
  growth: number | null;
  weight: number | null;
  bodyFatLevel: number | null;
  arm: number | null;
  tightBiceps: number | null;
  waist: number | null;
  hip: number | null;
  thigh: number | null;
  calf: number | null;
  photos: PhotoDto[];
}

interface SourceDto {
  id: MeasurementSource;
}
interface TypeDto {
  id: number;
}
interface MediaDto {
  originalName: string;
}

interface PhotoDto {
  id: number;
  url: string;
  type: TypeDto;
  name: string | null;
  media: MediaDto;
}
