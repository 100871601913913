import {
  type FC,
  RefObject,
  useEffect,
  useRef,
  useState,
  createRef,
} from "react";
import { format, addDays, startOfWeek, isSameDay, isToday } from "date-fns";
import { Typography } from "@mui/material";
import { EventWithType } from "@typeDefinitions";
import { ArrowPrev } from "@assets/icons";

import {
  WeekViewWrapper,
  WeekDay,
  EventList,
  EventItem,
  EventTime,
  EventTitle,
  WeekDayButton,
  StyledDayDivider,
  EventItemText,
  EventItemTextDay,
  EventItemTextWrapper,
  CalendarContentWrapper,
} from "../MiniCalendar.styled";
import { CalendarEmptyState } from "./CalendarEmptyState";
import { DietitianFilter } from "@views/dietician/Tasks/components/TaskDietitianFilter/DietitianFilter";
import { Spinner } from "@components/Spinner";
import { useCalendarActions } from "../../_hooks/useCalendarActions";
import { ScheduleVisitOverviewModal } from "../../../ScheduleVisit/_components/ScheduleVisitOverviewModal";
import { useCalendarColors } from "@views/dietician/Calendar";
import { getEventIcon } from "../../_utils/getEventIcon";
import { formatDayName } from "./MiniCalendarDayView";
import { ScheduleGoogleEventOverviewModal } from "../../../ScheduleVisit/_components/ScheduleGoogleEventOverviewModal";

interface MiniCalendarWeekViewProps {
  events: EventWithType[];
  currentDate: Date;
  setCurrentDate: React.Dispatch<React.SetStateAction<Date>>;
  locale: Locale;
  onDayClick?: (date: Date) => void;
  isLoading: boolean;
  dietitians: number;
}

export const MiniCalendarWeekView: FC<MiniCalendarWeekViewProps> = ({
  events,
  currentDate,
  setCurrentDate,
  locale,
  isLoading,
  dietitians,
}) => {
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const dayRefs = useRef<Record<string, HTMLDivElement | null>>({});

  const {
    handleCloseModal,
    handleEventClick,
    selectedEventId,
    anchorEl,
    selectedGoogleId,
  } = useCalendarActions();

  const { getNewDietitianColor, getEventStyles } = useCalendarColors();

  const handlePrevious = () => {
    setIsInitialLoading(true);
    setCurrentDate(addDays(currentDate, -7));
  };

  const handleNext = () => {
    setIsInitialLoading(true);
    setCurrentDate(addDays(currentDate, 7));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsInitialLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [currentDate, dietitians]);

  useEffect(() => {
    const todayDate = format(new Date(), "dd");

    if (dayRefs.current[todayDate]) {
      const dateRef = dayRefs.current[todayDate];
      const container = dateRef?.closest(".custom-scrollbar");
      if (container && dateRef) {
        const containerRect = container.getBoundingClientRect();
        const elementRect = dateRef.getBoundingClientRect();
        const relativeTop =
          elementRect.top - containerRect.top + container.scrollTop;

        container.scrollTo({
          top: relativeTop,
          behavior: "smooth",
        });
      }
    }
  }, [Object.keys(dayRefs.current).length]);

  const weekStart = startOfWeek(currentDate, { weekStartsOn: 1 });

  const hasEventsInWeek =
    !isLoading &&
    !isInitialLoading &&
    events.some(event => {
      const eventDate = new Date(event.start);
      return eventDate >= weekStart && eventDate < addDays(weekStart, 7);
    });

  const handlePickDay = (day: Date) => {
    if (day) {
      const formattedDate = format(day, "dd");
      const dateRef = dayRefs.current[formattedDate];

      if (dateRef) {
        const container = dateRef.closest(".custom-scrollbar");
        if (container) {
          const containerRect = container.getBoundingClientRect();
          const elementRect = dateRef.getBoundingClientRect();
          const relativeTop =
            elementRect.top - containerRect.top + container.scrollTop;

          container.scrollTo({
            top: relativeTop,
            behavior: "smooth",
          });
        }
      }
    }
  };

  return (
    <WeekViewWrapper>
      <WeekDay>
        <WeekDayButton onClick={handlePrevious}>
          <ArrowPrev />
        </WeekDayButton>
        {Array.from({ length: 7 }).map((_, index) => {
          const day = addDays(weekStart, index);
          const isDayToday = isToday(day);
          return (
            <EventItemTextWrapper
              key={index}
              isToday={isDayToday ? "true" : "false"}
              onClick={() => handlePickDay(day)}
            >
              <EventItemText variant="body2">
                {formatDayName(day, locale)}
              </EventItemText>
              <EventItemTextDay isToday={isDayToday ? "true" : "false"}>
                {format(day, "dd")}
              </EventItemTextDay>
            </EventItemTextWrapper>
          );
        })}
        <WeekDayButton onClick={handleNext}>
          <ArrowPrev className="rotate-180" />
        </WeekDayButton>
      </WeekDay>
      <DietitianFilter
        name="dietitians"
        setIsInitialLoading={setIsInitialLoading}
      />
      {isInitialLoading || isLoading ? (
        <div className="flex justify-center items-center">
          <Spinner />
        </div>
      ) : hasEventsInWeek ? (
        <CalendarContentWrapper className="custom-scrollbar mt-[28px]">
          {Array.from({ length: 7 }).map((_, index) => {
            const day = addDays(weekStart, index);
            const currentDayEvents = events.filter(event =>
              isSameDay(new Date(event.start), day),
            );
            const formattedDate = format(day, "dd");

            return (
              currentDayEvents.length > 0 && (
                <div
                  key={index}
                  ref={el => {
                    dayRefs.current[formattedDate] = el;
                  }}
                >
                  <div className="flex items-center gap-3">
                    <Typography variant="body1" fontWeight="600">
                      {format(day, "dd.MM")}
                    </Typography>
                    <StyledDayDivider />
                  </div>
                  <EventList>
                    {currentDayEvents.map((event, eventIndex) => {
                      const theme = getNewDietitianColor(event.dietitianId);
                      const { backgroundColor, border } = getEventStyles(
                        theme?.backgroundColor,
                        !!event.googleId,
                      );

                      return (
                        <EventItem
                          key={eventIndex}
                          onClick={e =>
                            handleEventClick(e, event.id, event.googleId)
                          }
                          style={{
                            backgroundColor,
                            border,
                            minHeight: "70px",
                          }}
                        >
                          <EventTime>
                            {getEventIcon(event.type, {
                              color: theme?.color,
                            })}
                            {`${format(
                              new Date(event.start),
                              "HH:mm",
                            )} - ${format(new Date(event.end), "HH:mm")}`}
                          </EventTime>
                          <EventTitle>{event.title}</EventTitle>
                        </EventItem>
                      );
                    })}
                  </EventList>
                </div>
              )
            );
          })}
        </CalendarContentWrapper>
      ) : (
        <CalendarEmptyState />
      )}
      {selectedEventId && (
        <ScheduleVisitOverviewModal
          anchorEl={anchorEl}
          onClose={handleCloseModal}
          eventId={selectedEventId}
        />
      )}
      {selectedGoogleId && (
        <ScheduleGoogleEventOverviewModal
          anchorEl={anchorEl}
          onClose={handleCloseModal}
          googleId={selectedGoogleId}
        />
      )}
    </WeekViewWrapper>
  );
};
