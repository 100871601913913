import { SvgIcon, SvgIconProps } from "@mui/material";

export const ExclamationMarkTriangle = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M10.5496 5.13934L3.36551 17.1327C3.21739 17.3892 3.13902 17.68 3.13819 17.9762C3.13736 18.2724 3.2141 18.5637 3.36079 18.821C3.50747 19.0783 3.71897 19.2928 3.97426 19.443C4.22955 19.5932 4.51972 19.674 4.81591 19.6772H19.1842C19.4803 19.674 19.7705 19.5932 20.0258 19.443C20.2811 19.2928 20.4926 19.0783 20.6393 18.821C20.786 18.5637 20.8627 18.2724 20.8619 17.9762C20.861 17.68 20.7827 17.3892 20.6345 17.1327L13.4504 5.13934C13.2992 4.89006 13.0863 4.68396 12.8323 4.54093C12.5782 4.3979 12.2916 4.32275 12 4.32275C11.7085 4.32275 11.4218 4.3979 11.1678 4.54093C10.9137 4.68396 10.7008 4.89006 10.5496 5.13934Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M12 9.49896V12.8917"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M12 16.2846H12.0081"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
