import { memo, ReactNode } from "react";

import { AnimatePresence } from "framer-motion";
import { TagsContainer } from "./ListTag.styled";

interface ListTagProps {
  children: string | ReactNode;
  /** additional classes */
  className?: string;
}
/** single tag showed in a universal list component */
export const ListTag = memo((props: ListTagProps) => {
  const { children } = props;

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/*@ts-ignore*/}
      <AnimatePresence>
        <TagsContainer>{children}</TagsContainer>
      </AnimatePresence>
    </>
  );
});
