import { Autocomplete, styled, TextField } from "@mui/material";

export const StyledTextField = styled(TextField)`
  width: 6.625rem;
`;

export const StyledAutocomplete = styled(Autocomplete)`
  max-width: 40rem;
  min-width: 5.625rem;
  flex: 1;
` as typeof Autocomplete;

interface ProductWrapperProps {
  isMobile: boolean;
}
export const ProductWrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const ContentWrapper = styled("div")<ProductWrapperProps>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
  align-items: ${({ isMobile }) => (isMobile ? "start" : "center")};
  gap: ${({ isMobile }) => (isMobile ? "0.5rem" : "")};
  flex: 1;
`;

export const ProductItemBody = styled("div")`
  display: flex;
  flex: 1;
  gap: 1.5rem;
`;
