import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const deleteCreatorDietMealProduct = (
  dietId: string,
  mealId: string,
  productId: string,
  version: string,
) => {
  return fetchData(
    `/dietitian/creator-2/diets/${dietId}/meals/${mealId}/products/${productId}`,
    APIMethods.DELETE,
    undefined,
    undefined,
    { "Alloweat-Creator-Version": version },
  );
};
