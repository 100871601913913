import { useState } from "react";
import {
  DeepPartial,
  DefaultValues,
  FieldValues,
  Path,
  PathValue,
  useFormContext,
} from "react-hook-form";

import { TabContext } from "@mui/lab";
import { Button, ClickAwayListener } from "@mui/material";

import { Close } from "@assets/icons";

import { useAppTranslation } from "@hooks";
import { useSearchParams } from "react-router-dom";
import {
  TagAutocompleteFilterWrapper,
  FiltersBuilder,
  FoodTreeSelectFilterWrapper,
  MultiSelectFilterWrapper,
  OnlyPolishFilterWrapper,
  SingleAutocompleteFilterWrapper,
  SingleSelectChipFilterWrapper,
  SingleSelectFilterWrapper,
  SliderFilterWrapper,
  AutocompleteFilterWrapper,
} from "./FiltersBuilder";
import {
  BuilderWrapper,
  DrawerActions,
  DrawerHeader,
  IconButtonStyled,
  StyledDrawer,
  TabPanelStyled,
  TabStyled,
  TabsStyled,
  TitleStyled,
} from "./FiltersDrawer.styled";
import { isNumber } from "lodash";

interface FiltersDrawerProps<T extends FieldValues> {
  open: boolean;
  tabs: string[];
  filtersLists: (
    | TagAutocompleteFilterWrapper
    | AutocompleteFilterWrapper<T>
    | SingleAutocompleteFilterWrapper<T>
    | MultiSelectFilterWrapper<T>
    | SingleSelectFilterWrapper<T>
    | SliderFilterWrapper<T>
    | OnlyPolishFilterWrapper<T>
    | FoodTreeSelectFilterWrapper<T>
    | SingleSelectChipFilterWrapper<T>
  )[][];
  onSubmit?: (values: T) => void;
  onClear?: () => void;
  onClose: () => void;
  total?: number;
  resetValues?: DefaultValues<T>;
}

interface FiltersFieldValues extends FieldValues {
  page?: number;
  perPage?: number;
  tags?: string[];
}
export const FiltersDrawer = <T extends FiltersFieldValues>({
  tabs,
  open,
  onClose,
  filtersLists,
  total,
  resetValues,
  onSubmit,
  onClear,
}: FiltersDrawerProps<T>) => {
  const { t } = useAppTranslation();
  const [tab, setTab] = useState("0");
  const { reset, setValue, handleSubmit } = useFormContext<T>();
  const [searchParams, setSearchParams] = useSearchParams();

  const clearFilters = () => {
    if (onClear) {
      onClear();
      return;
    }
    reset(resetValues);
    setValue("page" as Path<T>, undefined as PathValue<T, Path<T>>, {
      shouldDirty: true,
    });

    setSearchParams(prev => {
      const params = new URLSearchParams();

      const tabs = prev.get("tabs");
      const perPage = prev.get("perPage");

      if (tabs) params.set("tabs", tabs);
      if (perPage) params.set("perPage", perPage);

      return params;
    });
  };

  const handleClickAway = () => open && onClose();

  const submit = () => {
    onSubmit &&
      handleSubmit(
        v => onSubmit(v),
        e => console.log(e),
      )();
    onClose();
  };
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="max-w-[440px]">
        <TabContext value={tab}>
          <StyledDrawer
            variant="persistent"
            anchor="right"
            open={open}
            onClose={onClose}
          >
            <IconButtonStyled onClick={onClose} size="small">
              <Close size="w-[12px] h-[12px]" />
            </IconButtonStyled>
            <DrawerHeader>
              <TitleStyled>{t("filters.filters")}</TitleStyled>

              <TabsStyled value={tab} onChange={(_, v) => setTab(v)}>
                {tabs.map((tab, idx) => (
                  <TabStyled key={idx} label={tab} value={String(idx)} />
                ))}
              </TabsStyled>
            </DrawerHeader>

            {filtersLists.map((list, idx) => (
              <TabPanelStyled key={idx} value={String(idx)}>
                <BuilderWrapper>
                  <FiltersBuilder filters={list} />
                </BuilderWrapper>
              </TabPanelStyled>
            ))}

            <DrawerActions>
              <Button variant="outlined" onClick={clearFilters}>
                {t("filters.clean_all")}
              </Button>
              <Button variant="contained" onClick={submit}>
                {t("common.show")} {isNumber(total) && `(${total})`}
              </Button>
            </DrawerActions>
          </StyledDrawer>
        </TabContext>
      </div>
    </ClickAwayListener>
  );
};
