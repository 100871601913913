import { useCallback } from "react";

import { Spinner } from "@components/Spinner";
import { ViewError } from "@components/ViewError";
import { ProgramView } from "@views/dietician/Program/components/ProgramView";
import {
  useFetchProgramQuery,
  useUpdateProgramSectionsMutation,
} from "@hooks/queries";

import { useProgramSummaryParams } from "./useProgramSummaryParams";

export const Program = () => {
  const { programId } = useProgramSummaryParams();

  const { program, isLoading, isError } = useFetchProgramQuery(programId);

  const { mutate: UpdateProgramSections } = useUpdateProgramSectionsMutation();

  const handleRemoveSection = useCallback(
    (sectionId: number) => {
      if (!program) return;
      return UpdateProgramSections({
        programId,
        payload: {
          sections:
            program.sections.filter(section => section !== sectionId) ?? [],
        },
      });
    },
    [program],
  );

  if (isLoading || !program)
    return <Spinner className="w-full h-screen bg-transparent" />;

  if (isError) return <ViewError />;

  return (
    <ProgramView
      programId={programId}
      dietitianProgram={program}
      handleRemoveSection={handleRemoveSection}
    />
  );
};
