import { useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";

import { Drawer, InputAdornment } from "@mui/material";

import { TabsChipsFiltersForm } from "@components/Filters/TabsChipsFilter/TabsChipsFilterForm";
import {
  DEFAULT_MACROS_RANGE,
  RecipesFiltersFormInput,
} from "@components/RecipesFiltersNew/useRecipesFiltersFormNew";

import { Loupe } from "@assets/icons";
import { AutosaveWatchComponent } from "@components/AutosaveWatchComponent";
import { ITEMS_ON_PAGE } from "@components/PaginationNew";
import { ProductPreview } from "@components/PreviewDrawer/Product";
import { ProductsDataGrid } from "@components/ProductsDataGrid";
import { mapProductRows } from "@components/ProductsDataGrid/productsDataGridUtils";
import { ProductsFiltersNew } from "@components/ProductsFiltersNew";
import { useProductsFiltersForm } from "@components/ProductsFiltersNew/useProductsFiltersForm";
import { FormTextFieldClearableStyled } from "@components/RecipesFiltersNew/RecipesFilters.styled";
import { ListTabs, useAppTranslation } from "@hooks";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";
import { useSearchFoodQuery } from "@hooks/queries/useSearchFoodQuery";
import { omit } from "lodash";
import { ThemeProviderWrapperNew } from "themeNew";
import { DevTool } from "@hookform/devtools";
import { useSearchMealPatternsQueryNew } from "@hooks/queries";
import { MealPatternsDataGrid } from "@components/MealPatternsDataGrid/MealPatternsDataGrid";
import { mapMealRows } from "@components/MealPatternsDataGrid/mealPatternsDataGridUtils";
import { RecipesFiltersNew } from "@components/RecipesFiltersNew";

export const TestFilters = () => {
  const { t } = useAppTranslation();
  const form = useProductsFiltersForm();
  const { meals, meta, submit, isFetching } = useSearchMealPatternsQueryNew();

  const { account } = useFetchDietitianAccountQuery();
  const [selectedId, setSelectedId] = useState<number | null>(null);

  const tab = form.watch("tabs");

  const options: { label: string; id: ListTabs }[] = [
    {
      label: t("filters.tabs.all"),
      id: ListTabs.ALL,
    },
    {
      label: t("filters.tabs.latest"),
      id: ListTabs.LATEST,
    },
    {
      label: t("filters.tabs.favourite"),
      id: ListTabs.FAVORITE,
    },
    {
      label: t("filters.tabs.own"),
      id: ListTabs.OWN,
    },
  ];

  const optionsWithCount = useMemo(
    () =>
      options.map(option => ({
        ...option,
        label:
          option.id === tab && !!meta?.total
            ? `${option.label} (${meta?.total})`
            : option.label,
      })),
    [meta, t],
  );

  const mappedRows = useMemo(
    () => mapMealRows(meals, account?.id ?? 0),
    [meals, mapProductRows],
  );

  const handleChangePage = (id: number) => {
    submit(state => ({ ...state, page: id + 1 }));
  };
  const handleChangePerPage = (perPage: number) => {
    submit(state => ({ ...state, perPage }));
  };

  const handleChangeTab = (tab: string) => {
    submit(state => ({
      ...state,
      tabs: tab as ListTabs,
      page: undefined,
    }));
    form.setValue("page", undefined);
  };

  const handleSubmitForm = (data: RecipesFiltersFormInput) => {
    const nutrientsWithNonDefaultValues = Object.entries(
      data.nutrients || {},
    ).reduce((acc, [key, value]) => {
      const defaultValue = DEFAULT_MACROS_RANGE[key];
      if (
        !defaultValue ||
        defaultValue[0] !== value[0] ||
        defaultValue[1] !== value[1]
      ) {
        acc[key] = value;
      }
      return acc;
    }, {} as { [id: string]: [number, number] });

    submit({
      ...omit(data, ["nutrients"]),
      page: undefined,
      nutrients: nutrientsWithNonDefaultValues,
    });
  };

  return (
    <ThemeProviderWrapperNew>
      <FormProvider {...form}>
        <div className="grid gap-[16px]">
          {/* <DevTool control={form.control} /> */}
          <div className="flex gap-[4px]">
            <FormTextFieldClearableStyled
              name="query"
              size="small"
              placeholder={t("filters.search_collection")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Loupe />
                  </InputAdornment>
                ),
              }}
            />
            <RecipesFiltersNew total={meta?.total} />
          </div>

          <TabsChipsFiltersForm
            name="tabs"
            options={optionsWithCount}
            onChangeExternal={handleChangeTab}
          />
          <AutosaveWatchComponent
            isLoading={isFetching}
            onSubmit={handleSubmitForm}
            isDirtyCondition={false}
          />

          {/* <MealPatternsDataGrid
            rows={mappedRows}
            isOnePage={false}
            perPage={meta?.perPage ?? ITEMS_ON_PAGE.TWENTY_FIVE}
            onPageChange={handleChangePage}
            page={meta?.currentPage ? meta.currentPage - 1 : 0}
            setPerPage={handleChangePerPage}
            rowCount={meta?.total}
            loading={isFetching}
            onSelectRow={(id: number | null) => setSelectedId(id)}
          /> */}

          <Drawer open={!!selectedId} variant="persistent" anchor="right">
            {/* <ProductPreview
              key={selectedId}
              productId={selectedId}
              onClose={() => setSelectedId(null)}
            /> */}
          </Drawer>
        </div>
      </FormProvider>
    </ThemeProviderWrapperNew>
  );
};
