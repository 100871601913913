import { useTheme } from "@mui/material";

import { Bookmark } from "@assets/icons";
import { FullSurveyDto } from "@client/surveys";
import { useContentSelector } from "@context/TableOfContentsContext";

import {
  AlertCardStyled,
  TextContent,
  TitleStyled,
} from "./QuestionnaireAnswerPreviewModal.styled";

interface AlertCardProps {
  survey?: FullSurveyDto;
}

export const AlertCard = ({ survey }: AlertCardProps) => {
  const { groupId, subgroupId, isIntroduction, isGroup, isSubgroup } =
    useContentSelector();
  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  const getAlertData = () => {
    const fallback = {
      title: "",
      description: "",
    };

    if (!survey) return fallback;

    const { introduction, groups } = survey;

    if (isIntroduction)
      return {
        title: introduction.title,
        description: introduction.description,
      };

    const group = groups.find(g => g.id === groupId);

    if (isGroup && group) {
      const { title, description } = group;
      return { title, description };
    }

    const subgroup = group?.sections.find(s => s.id === subgroupId);
    if (isSubgroup && subgroup) {
      const { title, description } = subgroup;

      return { title, description };
    }

    return fallback;
  };

  const { title, description } = getAlertData();

  return (
    <AlertCardStyled>
      <div className="flex gap-1">
        <Bookmark className="m-1 self-start" fill={main} />

        <TitleStyled>{title}</TitleStyled>
      </div>

      <TextContent>{description}</TextContent>
    </AlertCardStyled>
  );
};
