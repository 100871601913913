import { SvgIcon, SvgIconProps } from "@components/SvgIcon";
import { useTheme } from "@mui/material";

export const HeadWithShoulders = (props: SvgIconProps) => {
  const { colors } = useTheme();

  return (
    <SvgIcon {...props} width="14" height="15" viewBox="0 0 14 15">
      <path
        d="M8.3125 9.0625C9.67969 9.09896 10.8099 9.57292 11.7031 10.4844C12.6146 11.3776 13.0885 12.5078 13.125 13.875C13.125 14.1302 13.043 14.3398 12.8789 14.5039C12.7148 14.668 12.5052 14.75 12.25 14.75H1.75C1.49479 14.75 1.28516 14.668 1.12109 14.5039C0.957031 14.3398 0.875 14.1302 0.875 13.875C0.911458 12.5078 1.38542 11.3776 2.29688 10.4844C3.1901 9.57292 4.32031 9.09896 5.6875 9.0625H8.3125ZM2.21484 13.4375H11.7852C11.6576 12.5625 11.2747 11.8333 10.6367 11.25C9.9987 10.6849 9.22396 10.3932 8.3125 10.375H5.6875C4.77604 10.3932 4.0013 10.6849 3.36328 11.25C2.72526 11.8333 2.34245 12.5625 2.21484 13.4375ZM7 7.75C6.01562 7.73177 5.1862 7.39453 4.51172 6.73828C3.85547 6.0638 3.51823 5.23438 3.5 4.25C3.51823 3.26562 3.85547 2.4362 4.51172 1.76172C5.1862 1.10547 6.01562 0.768229 7 0.75C7.98438 0.768229 8.8138 1.10547 9.48828 1.76172C10.1445 2.4362 10.4818 3.26562 10.5 4.25C10.4818 5.23438 10.1445 6.0638 9.48828 6.73828C8.8138 7.39453 7.98438 7.73177 7 7.75ZM7 2.0625C6.38021 2.08073 5.86068 2.29036 5.44141 2.69141C5.04036 3.11068 4.83073 3.63021 4.8125 4.25C4.83073 4.86979 5.04036 5.38932 5.44141 5.80859C5.86068 6.20964 6.38021 6.41927 7 6.4375C7.61979 6.41927 8.13932 6.20964 8.55859 5.80859C8.95964 5.38932 9.16927 4.86979 9.1875 4.25C9.16927 3.63021 8.95964 3.11068 8.55859 2.69141C8.13932 2.29036 7.61979 2.08073 7 2.0625Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
