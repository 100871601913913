import { Autocomplete, Button, styled, Typography } from "@mui/material";

export const AutocompleteStyled = styled(Autocomplete)`
  flex: 1;
` as typeof Autocomplete;

export const TagsLabel = styled(Typography)`
  font-weight: 600;
  font-size: 0.75rem;
`;

export const SelectedTagsWrapper = styled("div")`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

export const ShowTagsButton = styled(Button)`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
`;

export const CollapseWrapper = styled("div")`
  display: grid;
  gap: 0.75rem;
  padding-bottom: 0.75rem;
`;

export const TagsWrapper = styled("div")`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;
