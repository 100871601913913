import { object } from "yup";

import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";

import { IdDto, idSchema } from "./common";

export const postProgramFromDiet = async (
  clientId: string,
  payload: PostProgramFromDietRequest,
): Promise<ApiResponse<PostProgramFromDietResponse>> => {
  const response = await fetchData(
    `/dietitian/patients/${clientId}/program-from-diet`,
    APIMethods.POST,
    payload,
  );

  return postProgramFromDietResponseSchema.validate(response);
};

export interface PostProgramFromDietRequest {
  diet: IdDto;
  program: ProgramDates;
}

interface ProgramDates {
  startDate: string;
  endDate: string;
}

export interface PostProgramFromDietResponse {
  id: number;
}

const postProgramFromDietResponseSchema = object({
  data: idSchema.required(),
});

export interface ErrorData {
  message: string;
  code: null;
}

export const ALREADY_EXIST_RESPONSE_ERROR_MESSAGE =
  "Exists program in the date range";
