import { ReactElement } from "react";

import CheckInCircleIcon from "@assets/icons_new/checkInCircle.svg?react";
import IInCircleIcon from "@assets/icons_new/iInCircle2.svg?react";
import { styled, useTheme } from "@mui/material";

interface TooltipProps {
  children: ReactElement<any, any>;
}
export interface FeatureDto {
  label: string;
  Tooltip?: ({ children }: TooltipProps) => JSX.Element;
}
interface FeatureSectionProps {
  features: FeatureDto[];
  title: string;
}
export const FeatureSection = ({ features, title }: FeatureSectionProps) => {
  const { palette } = useTheme();

  return (
    <div className="flex flex-col gap-[16px] items-start">
      <Title>{title}</Title>

      <div className="flex flex-col gap-[12px]">
        {features.map(({ label, Tooltip }, idx) => (
          <div key={idx} className="flex gap-[10px] items-start">
            <IconWrapper>
              <CheckInCircleIcon color={palette.primary.main} />
            </IconWrapper>
            <Label>{label}</Label>
            {Tooltip && (
              <Tooltip>
                <IconWrapper>
                  <IInCircleIcon color={palette.primary.main} fontSize={18} />
                </IconWrapper>
              </Tooltip>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const Title = styled("span")`
  font-family: Figtree;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

const Label = styled("span")`
  font-family: Figtree;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #483a68;
`;

const IconWrapper = styled("div")`
  display: grid;
  place-items: center;
  width: 24px;
  min-width: 24px;
  height: 24px;
`;
