import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  DietNutrientsNormRequest,
  updateDietNutrientsNorm,
} from "@client/diets/creator";
import { MutationOptions } from "@hooks/queries/types";

import { fetchDietNutrientsNormQueryKey } from "./useFetchDietNutrientsNormQuery";
import { searchDietsQueryKeyNew } from "@hooks/queries/useSearchDietsQueryNew";
import { fetchCreatorDietQueryKey } from "./useFetchCreatorDietQuery";

export const useUpdateDietNutrientsNormMutation = (
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    UseUpdateDietNutrientsNormMutationPayload
  >(({ dietId, payload }) => updateDietNutrientsNorm(dietId, payload), {
    ...options,
    onSuccess: (d, v) => {
      options?.onSuccess && options.onSuccess(d, v);
      queryClient.invalidateQueries([fetchDietNutrientsNormQueryKey, v.dietId]);
      queryClient.invalidateQueries([fetchCreatorDietQueryKey, v.dietId]);
      queryClient.invalidateQueries([searchDietsQueryKeyNew]);
    },
  });
};

export interface UseUpdateDietNutrientsNormMutationPayload {
  dietId: string;
  payload: DietNutrientsNormRequest;
}
