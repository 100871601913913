import { QueryOptionsTyped } from "@hooks/queries";
import { useQuery } from "@tanstack/react-query";
import {
  fetchMonitoringMeasurementsPhotos,
  FetchMonitoringMeasurementsPhotosParams,
  FetchMonitoringMeasurementsPhotosResponse,
} from "@client/patient/monitoring/fetchMonitoringMeasurementsPhotos";
import { patientMonitoringKeys } from "@hooks/queries/client/monitoring/patientMonitoringKey";

export const useFetchMonitoringMeasurementsPhotos = (
  params: FetchMonitoringMeasurementsPhotosParams,
  options?: QueryOptionsTyped<FetchMonitoringMeasurementsPhotosResponse>,
) => {
  return useQuery({
    queryKey: patientMonitoringKeys.measurementsPhotos(params),
    queryFn: () => fetchMonitoringMeasurementsPhotos(params),
    ...options,
  });
};
