import { ChangeEvent, useEffect } from "react";
import { useController, useWatch } from "react-hook-form";
import { round } from "lodash";

import { useAppTranslation } from "@hooks";
import { CaloricNeedsFormInputs } from "../useCaloricNeedsForm";
import { InputSuffixIconButton } from "./InputSuffixIconButton";

import { BalanceValueMethod } from "@utils/balance";
import { decimalInputFactory } from "@utils/inputs";

import { StyledCPM, SummaryBoxStyled } from "../CaloricNeedsForm.styled";

const decimalInput = decimalInputFactory(0) as any;

export const CpmFormInput = () => {
  const { t } = useAppTranslation();

  const {
    field: {
      value: { value, method },
      onChange,
    },
  } = useController<CaloricNeedsFormInputs, "cpm">({ name: "cpm" });

  const spm = useWatch<CaloricNeedsFormInputs, "spm.value">({
    name: "spm.value",
  });
  const pal = useWatch<CaloricNeedsFormInputs, "pal.value">({
    name: "pal.value",
  });

  const handleChangeMethod = () => {
    if (method === BalanceValueMethod.AUTO) {
      onChange({ value, method: BalanceValueMethod.MANUAL });
    } else {
      onChange({
        value: round((spm ?? 0) * (pal ?? 0)),
        method: BalanceValueMethod.AUTO,
      });
    }
  };

  const handleChangeValue = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    onChange({ value: Number(e.target.value), method });
  };

  useEffect(() => {
    if (method === BalanceValueMethod.AUTO) {
      onChange({ value: round((spm ?? 0) * (pal ?? 0)), method });
    }
  }, [spm, pal]);

  return (
    <SummaryBoxStyled>
      <StyledCPM>{t("balance.tdee")}:</StyledCPM>
      <InputSuffixIconButton
        suffix="kcal"
        method={method}
        onIconClick={handleChangeMethod}
        value={value ?? ""}
        onChange={handleChangeValue}
        disabled={method === BalanceValueMethod.AUTO}
        iconDisabled={false}
        inputComponent={decimalInput}
      />
    </SummaryBoxStyled>
  );
};
