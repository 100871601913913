import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const ThreeVerticalPoints = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      style={{ fill: "none" }}
      width="4"
      height="19"
      viewBox="0 0 4 19"
    >
      <path
        d="M2 3.5C2.55228 3.5 3 3.05228 3 2.5C3 1.94772 2.55228 1.5 2 1.5C1.44772 1.5 1 1.94772 1 2.5C1 3.05228 1.44772 3.5 2 3.5Z"
        fill="#727272"
        stroke="#727272"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 10.5C2.55228 10.5 3 10.0523 3 9.5C3 8.94772 2.55228 8.5 2 8.5C1.44772 8.5 1 8.94772 1 9.5C1 10.0523 1.44772 10.5 2 10.5Z"
        fill="#727272"
        stroke="#727272"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 17.5C2.55228 17.5 3 17.0523 3 16.5C3 15.9477 2.55228 15.5 2 15.5C1.44772 15.5 1 15.9477 1 16.5C1 17.0523 1.44772 17.5 2 17.5Z"
        fill="#727272"
        stroke="#727272"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
