import { QueryOptionsTyped } from "@hooks/queries";
import { useQuery } from "@tanstack/react-query";
import { patientKeys } from "@hooks/queries/client/patientKeys";
import {
  FetchPatientHydrationProductParams,
  FetchPatientHydrationProductResponse,
  fetchPatientHydrationProducts,
} from "@client/patient/hydrationProduct/fetchPatientHydrationProduct";

const useFetchPatientHydrationProduct = (
  params: FetchPatientHydrationProductParams,
  options?: QueryOptionsTyped<FetchPatientHydrationProductResponse>,
) => {
  return useQuery({
    queryKey: patientKeys.hydrationProduct(params.patientId),
    queryFn: () => fetchPatientHydrationProducts(params),
    ...options,
  });
};

export default useFetchPatientHydrationProduct;
