import { Button, Chip, styled } from "@mui/material";
import { WHATS_NEW_TYPE } from "@utils";

export const WhatsNewItemWrapper = styled("div")`
  display: grid;
  gap: 16px;
  outline: 1px solid ${({ theme }) => theme.colors.neutral.light[200]};
  border-radius: 8px;
  padding: 12px;
`;

export const ContentWrapper = styled("div")`
  display: grid;
  gap: 12px;
`;

export const ChipSection = styled("div")`
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
`;

export const SectionTitle = styled("h2")`
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;
export const SectionSubTitle = styled("p")`
  font-size: 14px;
  line-height: 20px;
`;

interface WhatsNewChipWrapperProps {
  type?: WHATS_NEW_TYPE;
}

export const WhatsNewChip = styled(Chip, {
  shouldForwardProp: prop => prop !== "type",
})<WhatsNewChipWrapperProps>`
  height: 28px;
  font-size: 12px;
  line-height: 20px;
  ${({ type, theme }) => {
    switch (type) {
      case WHATS_NEW_TYPE.NEW:
        return `
          background: ${theme.colors.survey.light};  
          color: ${theme.palette.primary.main}; 
          border: 1px solid ${theme.palette.primary.main};
      `;
      case WHATS_NEW_TYPE.ADDS:
        return `
        background: ${theme.palette.success.light}; 
        color: ${theme.palette.success.main}; 
          `;
      case WHATS_NEW_TYPE.DEVELOPMENT:
        return `
        background: ${theme.palette.info.light};  
        color: ${theme.palette.info.main}; 
      `;
      case WHATS_NEW_TYPE.INCOMING:
        return `
          background: ${theme.colors.survey.pink.light};  
          color: ${theme.colors.survey.pink.dark}; 
      `;
      case WHATS_NEW_TYPE.ALL:
        return `
          background: ${theme.colors.survey.pink.light};  
          color: ${theme.colors.survey.pink.dark}; 
      `;
      default:
        return `
        background: black;  
        color: blue; 
      `;
    }
  }};
`;

export const StyledList = styled("ul")`
  list-style-type: disc;
  margin-left: 22px;
  font-size: 14px;
  line-height: 20px;
`;

export const LearnMoreButton = styled(Button)`
  height: 24px;
  padding: 0;
`;
