import { Button, Card, IconButton, styled } from "@mui/material";

export const PurpleCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  background: ${({ theme }) => theme.palette.primary.medium};
  height: 150px;
  position: relative;
  z-index: 1;
  margin-bottom: 24px;
`;

export const TitleYellow = styled("span")`
  font-family: ${({ theme }) => theme.fontFamily.sueEllenFrancisco};
  font-size: 22px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 1.2px;
  color: ${({ theme }) => theme.palette.secondary.main};
  z-index: 10000 !important;
`;

export const Text = styled("span")`
  font-family: Figtree;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
  z-index: 1;
`;

export const ButtonStyled = styled(Button)`
  display: flex;
  gap: 4px;
  border-radius: 8px;
  padding: 3px 12px;
  height: 30px;
  z-index: 1;
` as typeof Button;

export const ImgAbsolute = styled("img")`
  position: absolute;
  top: 0;
  right: 0;
  translate: 80% -100%;
  z-index: 1;
`;

export const ImgFlipped = styled("img")`
  transform: scaleX(-1);
  z-index: 1;
`;

export const PacmanBackground = styled("div")`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  z-index: 0;
`;

export const IconButtonAbsolute = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 10;
`;
