import { IconButton, Tooltip } from "@mui/material";

import {
  DuplicateFiles,
  MagicWand,
  Pen,
  Plus,
  SpeechBubble,
  SpeechBubbleWithDots,
  ThreeGreyPoints,
  TrashNew,
} from "@assets/icons";
import { ArrowRight } from "@assets/icons/Arrows";

import { CloudWithCheck, Eraser } from "@assets/icons/DesignSystem";
import { DropDownMenu, MenuItemIf } from "@components/DropDownMenu";
import { useAppParams, useAppTranslation, useModalState } from "@hooks";
import { Nutrient } from "@typeDefinitions";
import { MouseEventHandler, useEffect, useMemo, useState } from "react";
import {
  AccordionDetailsStyled,
  AccordionStyled,
  AccordionSummaryStyled,
  ButtonStyled,
  HourText,
  MealNameText,
  StickySummary,
  SummaryBold,
  SummaryStyled,
  SummaryText,
} from "./MealRow.styled";
import { MealRowDetails } from "./MealRowDetails";
import { MacrosSummary } from "./components/MacrosSummary/MacrosSummary";
import {
  DeleteMealModal,
  DeleteMealModalItems,
  DeleteMealModalItemsByOne,
  SaveMealToBaseModal,
} from "./components/Modals";
import { CommentModal } from "../CommentModal";
import { TranslationExtendedDto } from "@client/diets/creator";
import { getTranslation } from "@utils/translations";
import { AutogenerateMealModal } from "../../AutogenerateModal";
import { ItemsContextProvider } from "../ItemsContext";
import { MicrosSummary } from "./components/MacrosSummary";

interface MealRowProps {
  hour: string | null;
  mealId: number;
  onAddRecipes: () => void;
  itemsCount: number;
  targets: Nutrient[];
  showTarget: boolean;
  nutrients: Nutrient[];
  controlledExpanded?: boolean;
  translations: TranslationExtendedDto[];
  type: number | null;
}

export const MealRow = ({
  hour,
  mealId,
  onAddRecipes,
  itemsCount,
  nutrients,
  targets,
  showTarget,
  controlledExpanded,
  translations,
  type,
}: MealRowProps) => {
  const { t, currentLanguage } = useAppTranslation();
  const { programId } = useAppParams();
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openDeleteMeal, onOpenDeleteMeal, onCloseDeleteMeal] = useModalState();
  const [openDeleteItems, onOpenDeleteItems, onCloseDeleteItems] =
    useModalState();
  const [
    openDeleteItemsByOne,
    onOpenDeleteItemsByOne,
    onCloseDeleteItemsByOne,
  ] = useModalState();
  const [openBaseModal, onOpenBaseModal, onCloseBaseModal] = useModalState();
  const [openComment, onOpenComment, onCloseComment] = useModalState();
  const [openAutogenerate, onOpenAutogenerate, onCloseAutogenerate] =
    useModalState();
  const name = getTranslation(translations, currentLanguage);

  const handleOpenMenu: MouseEventHandler<HTMLButtonElement> = e => {
    setAnchorEl(e.currentTarget);
    e.stopPropagation();
  };

  const hasAnyComment = useMemo(
    () => translations.some(t => !!t.description),
    [translations],
  );

  const programMenuOptions: MenuItemIf[][] = [
    [
      {
        text: t("diet.dropdown.delete_all"),
        onClick: onOpenDeleteItemsByOne,
        Icon: Eraser,
      },
    ],
    [
      {
        text: t("diet.dropdown.save_as"),
        onClick: onOpenBaseModal,
        Icon: CloudWithCheck,
      },
      {
        text: t("diet.dropdown.delete"),
        onClick: onOpenDeleteMeal,
        Icon: TrashNew,
      },
    ],
  ];

  const menuOptions: MenuItemIf[][] = [
    [
      {
        text: t("diet.dropdown.delete_all"),
        onClick: onOpenDeleteItems,
        Icon: Eraser,
      },
    ],
    [
      {
        text: t("diet.dropdown.save_as"),
        onClick: onOpenBaseModal,
        Icon: CloudWithCheck,
      },
      {
        text: t("diet.dropdown.delete"),
        onClick: onOpenDeleteMeal,
        Icon: TrashNew,
      },
    ],
  ];

  const handleClickWithPropagation =
    (callback?: () => void): MouseEventHandler<HTMLButtonElement> =>
    e => {
      e.stopPropagation();
      callback?.();
    };

  useEffect(() => {
    if (controlledExpanded) setExpanded(true);
  }, [controlledExpanded]);

  return (
    <>
      <AccordionStyled
        TransitionProps={{ unmountOnExit: true }}
        expanded={expanded}
        onChange={(_, v) => setExpanded(v)}
      >
        <StickySummary expanded={expanded}>
          <AccordionSummaryStyled expandIcon={<ArrowRight />}>
            <div className="flex">
              <MealNameText>{name}</MealNameText>
              <HourText>, {hour}</HourText>
            </div>

            <div className="flex">
              {type === null ? (
                <Tooltip title={t("diet.meal_no_meal_type")}>
                  <div>
                    <ButtonStyled
                      onClick={handleClickWithPropagation(onOpenAutogenerate)}
                      disabled
                    >
                      <MagicWand />
                      {t("diet.autogenerate")}
                    </ButtonStyled>
                  </div>
                </Tooltip>
              ) : (
                <ButtonStyled
                  onClick={handleClickWithPropagation(onOpenAutogenerate)}
                >
                  <MagicWand />
                  {t("diet.autogenerate")}
                </ButtonStyled>
              )}

              <ButtonStyled onClick={handleClickWithPropagation(onAddRecipes)}>
                <Plus />
                {t("diet.add_recipes")}
              </ButtonStyled>

              <ButtonStyled onClick={handleClickWithPropagation(onOpenComment)}>
                {hasAnyComment ? <SpeechBubbleWithDots /> : <SpeechBubble />}
                {hasAnyComment
                  ? t("diet.comment_modal.edit")
                  : t("diet.comment_modal.add")}
              </ButtonStyled>

              <IconButton onClick={handleOpenMenu}>
                <ThreeGreyPoints />
              </IconButton>
            </div>
          </AccordionSummaryStyled>

          <SummaryStyled expanded={expanded}>
            {!expanded && (
              <SummaryText>
                <SummaryBold>{t("common.summary")}</SummaryBold> (
                {t("diet.proposals", { count: itemsCount })})
              </SummaryText>
            )}
            <div className="grid gap-[12px]">
              <MacrosSummary
                nutrients={nutrients}
                targets={targets}
                showTarget={showTarget}
              />
              {expanded && <MicrosSummary mealId={mealId} />}
            </div>
          </SummaryStyled>
        </StickySummary>

        <AccordionDetailsStyled>
          <MealRowDetails mealId={mealId.toString()} />
        </AccordionDetailsStyled>
      </AccordionStyled>

      <DropDownMenu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        options={programId ? programMenuOptions : menuOptions}
      />

      <DeleteMealModal
        open={openDeleteMeal}
        onClose={onCloseDeleteMeal}
        mealId={mealId}
        name={name}
        hour={hour ?? undefined}
      />
      <DeleteMealModalItems
        open={openDeleteItems}
        onClose={onCloseDeleteItems}
        mealId={mealId}
        name={name}
        hour={hour ?? undefined}
      />
      <DeleteMealModalItemsByOne
        open={openDeleteItemsByOne}
        onClose={onCloseDeleteItemsByOne}
        mealId={mealId}
        name={name}
        hour={hour ?? undefined}
      />
      <SaveMealToBaseModal
        open={openBaseModal}
        onClose={onCloseBaseModal}
        mealId={mealId}
        name={name}
      />

      {openComment && (
        <CommentModal
          open
          onClose={onCloseComment}
          mealId={mealId}
          translations={translations}
        />
      )}
      <ItemsContextProvider>
        <AutogenerateMealModal
          open={openAutogenerate}
          onClose={onCloseAutogenerate}
          mealId={mealId}
          mealTitle={`${name} ${hour}`}
          tags={[]}
          onSuccess={() => setExpanded(true)}
        />
      </ItemsContextProvider>
    </>
  );
};
