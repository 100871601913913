import { useMemo } from "react";
import { FormProvider } from "react-hook-form";

import { QuestionType, SystemQuestionDto } from "@client/surveys";
import { useAppTranslation } from "@hooks";
import {
  useFetchSurveyDictionariesQuery,
  useUpdateSurveySubgroupQuestionMutation,
} from "@hooks/queries/surveys";

import { LangDto } from "@client";
import { Question } from "../Question/Question";
import { QuestionEditWatch } from "../QuestionEditWatch";
import { RequiredConfigFormInputs } from "../forms";
import {
  mapSystemQuestionConfigForm,
  mapSystemQuestionConfigRequest,
  useSystemQuestionConfigForm,
} from "../forms/useSystemQuestionConfigForm";
import {
  CheckboxStyled,
  OptionText,
  OptionWrapper,
  OtherLabel,
  RadioStyled,
} from "./SystemQuestionTagAllergen.styled";
import { OuterLabel } from "@components/OuterLabel";
import { TextField } from "@mui/material";

interface SystemQuestionTagAllergenProps {
  listIndex: number;
  totalQuestions: number;
  question: SystemQuestionDto;
  onTypeChange: (currentType: QuestionType, newType: QuestionType) => void;
  questionnaireLang?: LangDto;
}

export const SystemQuestionTagAllergen = ({
  listIndex,
  totalQuestions,
  question,
  onTypeChange,
  questionnaireLang,
}: SystemQuestionTagAllergenProps) => {
  const { t } = useAppTranslation();
  const mappedForm = useMemo(
    () =>
      mapSystemQuestionConfigForm({
        ...question,
        title: t("questionnaires.questions.system_tag_allergen.question", {
          lng: questionnaireLang,
        }),
      }),
    [question],
  );
  const form = useSystemQuestionConfigForm(mappedForm);
  const { mutate } = useUpdateSurveySubgroupQuestionMutation();
  const { data, isLoading } = useFetchSurveyDictionariesQuery();
  const allergenList = data?.systemTagAllergen;
  const onSubmit = (data: RequiredConfigFormInputs) => {
    mutate({
      id: question.id.toString(),
      payload: mapSystemQuestionConfigRequest(data, question),
    });
  };

  if (isLoading) return <></>;

  return (
    <FormProvider {...form}>
      <Question
        id={question.id}
        listIndex={listIndex}
        totalQuestions={totalQuestions}
        onTypeChange={onTypeChange}
        questionType={question.type}
        systemQuestion
        infoText={t("questionnaires.questions.system_tag_allergen.info")}
      >
        <div className="grid gap-6">
          <div className="grid gap-3">
            <OptionWrapper>
              <RadioStyled disabled />

              <OptionText>
                {t("questionnaires.questions.system_tag_allergen.no")}
              </OptionText>
            </OptionWrapper>
            <OptionWrapper>
              <RadioStyled disabled checked />

              <OptionText>
                {t("questionnaires.questions.system_tag_allergen.yes")}
              </OptionText>
            </OptionWrapper>
          </div>

          <div className="grid gap-3">
            {allergenList?.map(allergen => (
              <OptionWrapper key={allergen.id}>
                <CheckboxStyled disabled />

                <OptionText>{allergen.name}</OptionText>
              </OptionWrapper>
            ))}
          </div>

          <div className="grid gap-4">
            <OtherLabel>
              {t("questionnaires.questions.system_tag_allergen.other")}:
            </OtherLabel>
            <TextField
              size="small"
              fullWidth
              disabled
              placeholder={t(
                "questionnaires.questions.system_tag_allergen.placeholder_other",
              )}
            />
          </div>
        </div>
      </Question>

      <QuestionEditWatch isLoading={false} onSubmit={onSubmit} />
    </FormProvider>
  );
};
