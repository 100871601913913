import * as yup from "yup";

import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { MEETING_TYPE, MEETING_MODE } from "@utils/scheduleEvent";
import { ApiEndpoints } from "@typeDefinitions";
import qs from "qs";

export interface ScheduleEventsQueryParams {
  product?: (string | undefined)[] | undefined;
  dietitians: (string | undefined)[];
  date: string;
  time_unit: string;
  search: string | null;
  patients: (number | undefined)[];
  all_day: number | null;
  meeting_types: (MEETING_TYPE | undefined)[];
  meeting_modes: (MEETING_MODE | undefined)[];
  facilities: (number | undefined)[];
}

export const searchScheduleEvents = async (
  params?: ScheduleEventsQueryParams,
): Promise<
  ApiResponse<(SearchScheduleEventDto | GoogleScheduleEventDto)[]>
> => {
  const queryStringParams = qs.stringify(params, {
    arrayFormat: "brackets",
    skipNulls: true,
  });

  const data = await fetchData<
    (SearchScheduleEventDto | GoogleScheduleEventDto)[]
  >(
    `${ApiEndpoints.SearchScheduleEvents}?${queryStringParams}`,
    APIMethods.GET,
  );

  return await searchScheduleEventsResponseSchema.validate(data);
};

const patientSchema = yup.object({
  id: yup.number().nullable().defined(),
});

const dietitianSchema = yup.object({
  id: yup.number().required(),
});

const meetingTypeSchema = yup.object({
  id: yup.number().required(),
});

const facilitySchema = yup.object({
  id: yup.number().nullable().defined(),
});

const searchScheduleEventSchema = yup.object({
  id: yup.number().nullable().default(null),
  dietitian: dietitianSchema.required(),
  title: yup.string().ensure(),
  dateStart: yup.string().required(),
  dateEnd: yup.string().required(),
  isAllDay: yup.boolean().required(),
  meetingType: meetingTypeSchema.nullable().default(null),
  facility: facilitySchema.nullable().default(null),
  additionalInfo: yup.string().nullable().optional(),
});

const googleScheduleEventSchema = searchScheduleEventSchema.shape({
  googleId: yup.string().required(),
  hangoutLink: yup.string().nullable(),
  fromGoogle: yup.boolean().required(),
  patient: patientSchema.required(),
});

const searchScheduleEventsResponseSchema = yup.object({
  data: yup
    .array()
    .of(
      yup.lazy((value: unknown) => {
        if (value && typeof value === "object" && "googleId" in value) {
          return googleScheduleEventSchema;
        }
        return searchScheduleEventSchema;
      }),
    )
    .required(),
});
export interface SearchScheduleEventDto {
  id: number | null;
  dietitian: DietitianDto;
  title: string;
  dateStart: string;
  dateEnd: string;
  isAllDay: boolean;
  meetingType: MeetingTypeDto | null;
  facility: FacilityDto | null;
  additionalInfo?: string | null;
}

export interface GoogleScheduleEventDto extends SearchScheduleEventDto {
  id: null;
  googleId: string;
  hangoutLink: string | null;
  fromGoogle: boolean;
  patient: PatientDto;
}

interface DietitianDto {
  id: number;
}
interface PatientDto {
  id: number | null;
}

interface MeetingTypeDto {
  id: MEETING_TYPE;
}

interface FacilityDto {
  id: number | null;
}
