import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import * as yup from "yup";
import {
  CalendarDayMonitoringResource,
  calendarDayMonitoringSchema,
} from "@client/resources/CalendarDayMonitoringResource";
import { logFetchValidateError } from "@utils/validate";
import {
  CalendarMonthMonitoringResource,
  calendarMonthMonitoringSchema,
} from "@client/resources/CalendarMonthMonitoringResource";

export type FetchMonitoringCalendarDaysByMonthsParams = {
  patientId: number;
  dateFrom: string;
  dateTo: string;
};

export type FetchMonitoringCalendarDaysByMonthsResponse = ApiResponse<
  CalendarMonthMonitoringResource[]
>;

export const fetchMonitoringCalendarDaysByMonths = async (
  params: FetchMonitoringCalendarDaysByMonthsParams,
): Promise<FetchMonitoringCalendarDaysByMonthsResponse> => {
  const response = await fetchData(
    `/dietitian/patients/{patient_id}/monitoring2/days-for-months`.replace(
      "{patient_id}",
      params.patientId.toString(),
    ),
    APIMethods.GET,
    undefined,
    mapUrlParams(params),
  );

  return await schema
    .validate(response)
    .catch(
      logFetchValidateError(
        "/dietitian/patients/{patient_id}/monitoring2/days-for-months",
      ),
    );
};

const mapUrlParams = (params: FetchMonitoringCalendarDaysByMonthsParams) => {
  const urlParams = new URLSearchParams();
  urlParams.set("date-from", params.dateFrom);
  urlParams.set("date-to", params.dateTo);

  return urlParams;
};

const schema = yup.object({
  data: yup.array().of(calendarMonthMonitoringSchema).defined(),
});
