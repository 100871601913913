import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
} from "@mui/material";

export const MenuItemStyled = styled(MenuItem)`
  padding: 8px 12px;
  min-height: unset;
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

export const MenuStyled = styled(Menu)`
  & .MuiList-root.MuiList-padding.MuiMenu-list {
    padding: 4px 0;
    gap: 4px;
  }
`;

export const ListItemIconStyled = styled(ListItemIcon)`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.MuiListItemIcon-root {
    min-width: 24px;
  }
`;

export const ListItemTextStyled = styled(ListItemText)`
  & .MuiTypography-root {
    color: ${({ theme }) => theme.colors.neutral.dark[800]};
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
`;
export const DescriptionStyled = styled("span")`
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  max-width: 354px;
  white-space: pre-wrap;
`;
