import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const StarEmpty = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="14" height="14" viewBox="0 0 14 14">
      <path
        d="M7.00021 0.5C7.22683 0.5 7.43374 0.632021 7.53227 0.842748L9.22203 4.43017L12.9957 5.00396C13.2174 5.03696 13.4021 5.19691 13.4711 5.41779C13.54 5.63867 13.4834 5.87733 13.3257 6.03982L10.5891 8.83765L11.2345 12.7881C11.2714 13.0166 11.1803 13.2477 10.998 13.3848C10.8157 13.5219 10.5719 13.5371 10.3748 13.4279L7.00021 11.5695L3.62808 13.4254C3.42856 13.5346 3.18716 13.5193 3.00488 13.3822C2.82261 13.2451 2.72901 13.0141 2.76595 12.7856L3.41131 8.83511L0.674687 6.03982C0.514578 5.87733 0.460388 5.63614 0.529357 5.41779C0.598327 5.19945 0.783068 5.0395 1.00476 5.00396L4.7784 4.43017L6.46816 0.842748C6.56915 0.632021 6.7736 0.5 7.00021 0.5ZM7.00021 2.50571L5.70703 5.25277C5.62081 5.43303 5.45578 5.55997 5.26119 5.59044L2.34721 6.0322L4.46311 8.19278C4.59858 8.33242 4.66263 8.53045 4.6306 8.72594L4.13057 11.765L6.72187 10.3381C6.89676 10.2416 7.10613 10.2416 7.27856 10.3381L9.86985 11.765L9.37228 8.72848C9.34026 8.53299 9.40184 8.33496 9.53978 8.19532L11.6557 6.03474L8.7417 5.59044C8.54957 5.55997 8.38207 5.43556 8.29586 5.25277L7.00021 2.50571Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
