import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const postDietsProductsReplacementClearHistory = async (
  dietId: string,
) => {
  return await fetchData(
    `/dietitian/diets/${dietId}/products-replacement-clear-history`,
    APIMethods.POST,
  );
};
