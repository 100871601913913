import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const deleteCreatorMealProduct = async (
  mealId: string,
  productId: string,
  version: number,
) => {
  return await fetchData(
    `/dietitian/creator/meals/${mealId}/products/${productId}`,
    APIMethods.DELETE,
    undefined,
    undefined,
    { "Alloweat-Creator-Version": `${version}` },
  );
};
