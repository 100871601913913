import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MutationOptions } from "./types";
import { fetchClientQueryKey } from "./useFetchClientQuery";
import { fetchPatientProgramQueryKey } from "./useFetchPatientProgramQuery";
import { fetchClientProgramsQueryKey } from "./useFetchClientProgramsQuery";
import {
  CreateActivateClientProgramRequest,
  postClientActivateProgram,
} from "@client";

export const usePostClientActivateProgram = (
  id: number,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, CreateActivateClientProgramRequest>(
    ({ activate, patientId }) =>
      postClientActivateProgram(id, activate, patientId),
    {
      ...options,
      onSuccess: (_, { activate, patientId }) => {
        queryClient.invalidateQueries([fetchClientQueryKey, id]);
        queryClient.invalidateQueries([
          fetchPatientProgramQueryKey,
          patientId.toString(),
          id.toString(),
        ]);
        queryClient.invalidateQueries([fetchClientProgramsQueryKey, patientId]);
        queryClient.invalidateQueries([fetchClientProgramsQueryKey]);
        options?.onSuccess && options?.onSuccess(_, { activate, patientId });
      },
    },
  );
};
