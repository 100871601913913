import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import {
  DietitianProgramScheduleDayVariant,
  mealDescriptionSchema,
  mealRecipeSchema,
} from "./common";
import { nutrientSchema } from "../common";
import * as yup from "yup";
import { validateAndFixNutrients } from "../utils/validateCatching";
import { ImportanceType } from "./optimizeProgramSchedule";

export async function fetchSampleScheduleDayVariants(
  programId: number,
  dayId: number,
  payload: DayVariantDrawRequestBody,
): Promise<ApiResponse<DietitianProgramScheduleDayVariant[]>> {
  const data = await fetchData(
    `/dietitian/programs/${programId}/days/${dayId}/schedule/draw`,
    APIMethods.POST,
    payload,
  );
  return await validateAndFixNutrients(dietitianDietDayVariantsSchema, data);
}

const dietitianDietDayVariantsSchema = yup.object().shape({
  data: yup.array().of(
    yup.object().shape({
      id: yup.number().nullable(),
      date: yup.string().required(),
      program: yup
        .object()
        .shape({
          id: yup.number().required(),
        })
        .required(),
      programDay: yup
        .object()
        .shape({
          id: yup.number().required(),
          diet: yup
            .object()
            .shape({
              id: yup.number().required(),
            })
            .required(),
        })
        .required(),
      nutrients: yup.array().of(nutrientSchema).required(),
      meals: yup.array().of(
        yup.object().shape({
          id: yup.number().nullable(),
          dietMeal: mealDescriptionSchema.required(),
          recipe: mealRecipeSchema.nullable().default(null),
        }),
      ),
      actions: yup
        .object()
        .shape({ canCopy: yup.boolean().required() })
        .required(),
    }),
  ),
});

export interface DayVariantDrawRequestBody {
  nutrients: { id: number; distribution: number; tolerance: number }[];
  balances: { type: ImportanceType; value: number }[];
}
