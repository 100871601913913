import { ClientDto, FetchScheduleEventResponse } from "@client";

import dayjs from "dayjs";
import { MEETING_MODE, MEETING_TYPE } from "@utils/scheduleEvent";

export const mapClientsToOptions = (clients: ClientDto[] | undefined) =>
  clients?.map(client => ({
    id: client.id.toString(),
    label: `${client.firstName} ${client.lastName}`,
    email: client.email,
  })) ?? [];

export const mapAddVisitForm = (data?: FetchScheduleEventResponse) => {
  if (!data) return;
  const isRemote = data.meetingMode?.id === MEETING_MODE.ONLINE;
  return {
    eventType: data.meetingType.id as MEETING_TYPE,
    patientId: data.patient?.id ?? null,
    eventName: data.title,
    isAllDay: data.isAllDay,
    startDate: dayjs(data.dateStart).toDate(),
    finishDate: dayjs(data.dateEnd).toDate(),
    dietitianId: data.dietitian.id,
    clinicFacilityId: isRemote
      ? EVENT_ONLINE_LOCATION
      : data.clinicFacility?.id ?? null,
    clinicProductId: data.clinicProduct?.id.toString() ?? null,
    price: data.price?.value ?? null,
    currency: data.price?.currency ?? null,
    additionalInfo: data.additionalInfo ?? null,
    notification: null,
  };
};

export const EVENT_ONLINE_LOCATION = -1;
