import { useAppTranslation } from "@hooks";
import {
  Layout,
  Value,
  Label,
} from "@components/Client/BodyMeasurementsTabs/BodyMeasurementItem.styled";

type AgeProps = {
  value: number | null;
};
const Age = ({ value }: AgeProps) => {
  const { t } = useAppTranslation();

  return (
    <Layout>
      <Label>
        {t(
          "patient.health_and_goal.body_measurement_tab.section_last_measurement.age",
        )}
      </Label>
      <Value>{value ?? "-"}</Value>
    </Layout>
  );
};

export default Age;
