import { useState } from "react";

import { useAppTranslation } from "@hooks";

import { TabContext, TabList } from "@mui/lab";
import { Box } from "@mui/material";

import { OfficesTab } from "@layouts/DieticianSettingsLayout/components/OfficesTab";
import { DietitiansTab } from "@layouts/DieticianSettingsLayout/components/DietitiansTab";
import { ProductsTab } from "@layouts/DieticianSettingsLayout/components/ProductsTab";
import { BillingTab } from "@layouts/DieticianSettingsLayout/components/BillingTab";

import { StyledTab, StyledTabPanel } from "./DieticianSettingsTabs.styled";
import { GoogleCard } from "@views/dietician/Integrations/GoogleCard";
import { ThemeProviderWrapperNew } from "themeNew";

export const DieticianSettingsTabs = () => {
  const { t } = useAppTranslation();
  const [value, setValue] = useState(SettingsTabs.OFFICES);

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: SettingsTabs,
  ) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="flex flex-col w-full font-roman">
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange}>
              <StyledTab
                label={t("company_settings.offices")}
                value={SettingsTabs.OFFICES}
              />
              <StyledTab
                label={t("company_settings.dieticians")}
                value={SettingsTabs.DIETITIANS}
              />
              <StyledTab
                label={t("company_settings.products")}
                value={SettingsTabs.PRODUCTS}
              />
              <StyledTab
                label={t("company_settings.finances")}
                value={SettingsTabs.FINANCES}
              />
              <StyledTab
                label={"Integracje"}
                value={SettingsTabs.INTEGRATIONS}
              />
            </TabList>
          </Box>
          <StyledTabPanel value={SettingsTabs.OFFICES}>
            <OfficesTab />
          </StyledTabPanel>
          <StyledTabPanel value={SettingsTabs.DIETITIANS}>
            <DietitiansTab />
          </StyledTabPanel>
          <StyledTabPanel value={SettingsTabs.PRODUCTS}>
            <ProductsTab />
          </StyledTabPanel>
          <StyledTabPanel value={SettingsTabs.FINANCES}>
            <BillingTab />
          </StyledTabPanel>
          <StyledTabPanel value={SettingsTabs.INTEGRATIONS}>
            <ThemeProviderWrapperNew>
              <GoogleCard />
            </ThemeProviderWrapperNew>
          </StyledTabPanel>
        </TabContext>
      </div>
    </>
  );
};

enum SettingsTabs {
  OFFICES = "1",
  DIETITIANS = "2",
  PRODUCTS = "3",
  FINANCES = "4",
  INTEGRATIONS = "5",
}
