import { object, string } from "yup";

import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const fetchOauthGoogleDeauthorize =
  async (): Promise<OauthGoogleDeauthorizeDto> => {
    const response = await fetchData(
      "/dietitian/oauth/google/deauthorize",
      APIMethods.GET,
    );

    return response;
  };

export interface OauthGoogleDeauthorizeDto {
  message: string;
}
