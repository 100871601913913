import * as yup from "yup";

import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { ImportanceType } from "./optimizeProgramSchedule";

export async function getOptimizerSettings(
  programId: number | string,
): Promise<ApiResponse<OptimizerSettingsResponse[]>> {
  const response = await fetchData(
    `/dietitian/programs/${programId}/schedule/settings`,
    APIMethods.GET,
  );

  return optimizerSettingsResponseSchema.validate(response);
}

export interface OptimizerSettingsResponse {
  id: string;
  program: {
    id: string;
  };
  programDay: {
    id: string;
  };
  balances: {
    type: { id: ImportanceType };
    value: number;
  }[];
  nutrients: {
    nutrient: {
      id: string;
    };
    value: number;
    distribution: number;
    tolerance: number;
  }[];
}

const optimizerSettingsResponseSchema = yup.object().shape({
  data: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required(),
        program: yup.object().shape({
          id: yup.string().required(),
        }),
        programDay: yup.object().shape({
          id: yup.string().required(),
        }),
        balances: yup
          .array()
          .of(
            yup.object().shape({
              type: yup.object().shape({
                id: yup
                  .number()
                  .oneOf([ImportanceType.MEALS, ImportanceType.NUTRIENTS])
                  .required(),
              }),
              value: yup.number().required(),
            }),
          )
          .required(),
        nutrients: yup
          .array()
          .of(
            yup.object().shape({
              nutrient: yup.object().shape({
                id: yup.string().required(),
              }),
              value: yup.number().required(),
              distribution: yup.number().required(),
              tolerance: yup.number().required(),
            }),
          )
          .required(),
      }),
    )
    .required(),
});
