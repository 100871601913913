import { CrossedArrows } from "@assets/icons";
import { useAppTranslation } from "@hooks";

import {
  ButtonStyled,
  GenerateButton,
  ImageStyled,
  ImageWrapper,
  TextStyled,
} from "./GenerateImageButton.styled";
import { Tooltip } from "@mui/material";

interface GenerateImageButtonProps {
  img?: string | null;
  isLoading: boolean;
  onClick: () => void;
  disabled?: boolean;
  disabledTooltip?: string;
}

export const GenerateImageButton = ({
  img,
  isLoading,
  onClick,
  disabled,
  disabledTooltip,
}: GenerateImageButtonProps) => {
  const { t } = useAppTranslation();

  if (img)
    return (
      <ImageWrapper>
        <ImageStyled src={img} />

        <ButtonStyled onClick={onClick} loading={isLoading} disabled={disabled}>
          <CrossedArrows size="w-[16px] h-[16px]" />
          {t("meal.generate_new")}
        </ButtonStyled>
      </ImageWrapper>
    );

  if (disabled)
    return (
      <Tooltip title={disabledTooltip}>
        <div>
          <GenerateButton disabled>
            <CrossedArrows size="w-[16px] h-[16px]" />

            <TextStyled>{t("meal.generate_cover")}</TextStyled>
          </GenerateButton>
        </div>
      </Tooltip>
    );

  return (
    <GenerateButton onClick={onClick} loading={isLoading} disabled={disabled}>
      <CrossedArrows size="w-[16px] h-[16px]" />

      <TextStyled>{t("meal.generate_cover")}</TextStyled>
    </GenerateButton>
  );
};
