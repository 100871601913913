import { useCategoriesNutrientsFetchQuery } from "@hooks/queries/dictionaries";
import { useMemo } from "react";
import { TabPanel } from "@components/Alloweat/Tabs/TabPanel";
import { NutrientCategoryConst } from "@consts/NutrientCategoryConst";
import NutrientBox from "@components/Nutrients2/NutrientBox";
import { NutrientsList } from "@components/Nutrients2/NutrientsList";
import { TabPanelProps } from "@mui/lab/TabPanel/TabPanel";
import { NutrientValueType } from "@components/Nutrients2/Types";

export type NutrientsBoxProps = {
  values: NutrientValueType[];
} & Omit<TabPanelProps, "value">;

const BaseNutrientsTabsPanel2 = ({ values, ...props }: NutrientsBoxProps) => {
  const { data: dataNutrientsCategories } = useCategoriesNutrientsFetchQuery();

  const nutrientsCategories = useMemo(() => {
    if (!dataNutrientsCategories) {
      return [];
    }

    return dataNutrientsCategories.filter(
      c =>
        ![
          NutrientCategoryConst.ENERGY,
          NutrientCategoryConst.MACRO_ELEMENTS,
        ].includes(c.id),
    );
  }, [dataNutrientsCategories]);

  return (
    <>
      {nutrientsCategories?.map(category => (
        <TabPanel
          {...props}
          key={category.id.toString()}
          value={category.id.toString()}
        >
          <NutrientsList>
            {category.nutrients.map(nutrient => {
              const value = values.find(value => value.id === nutrient.id);

              return (
                <NutrientBox
                  key={nutrient.id}
                  nutrient={nutrient}
                  value={value}
                />
              );
            })}
          </NutrientsList>
        </TabPanel>
      ))}
    </>
  );
};

export default BaseNutrientsTabsPanel2;
