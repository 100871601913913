import { memo } from "react";
import { useFormContext } from "react-hook-form";

import { useAppTranslation } from "@hooks";
import { Labeled } from "@components/Labeled";
import { FormCheckbox } from "@components/FormCheckbox";

import { MicrosSelect } from "./MicrosSelect";

export const ShowToPatientSelect = memo(() => {
  const { t } = useAppTranslation();
  const { control } = useFormContext();

  return (
    <Labeled
      className={"gap-5"}
      label={`${t("program.info.show_to_patient")}:`}
    >
      <FormCheckbox
        name="showCals"
        control={control}
        label={t("common.energy_efficiency")}
      />
      <FormCheckbox
        name="showMacros"
        control={control}
        label={t("program.info.macroelements_values")}
      />
      <MicrosSelect />
    </Labeled>
  );
});
