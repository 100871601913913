import { useQuery } from "@tanstack/react-query";
import {
  fetchPatientProgramsSearch,
  FetchPatientProgramsSearchParams,
  FetchPatientProgramsSearchResponse,
} from "@client/patient/programs/fetchPatientProgramsSearch";
import { QueryOptionsTyped } from "@hooks/queries";

export const patientProgramsSearchKeys = {
  base: (patientId: number) => ["patient_programs_search", patientId] as const,
  search: (params: FetchPatientProgramsSearchParams) =>
    [...patientProgramsSearchKeys.base(params.patientId), params] as const,
};

const useFetchClientPrograms = (
  params: FetchPatientProgramsSearchParams,
  options?: QueryOptionsTyped<FetchPatientProgramsSearchResponse>,
) => {
  return useQuery({
    queryKey: patientProgramsSearchKeys.search(params),
    queryFn: () => fetchPatientProgramsSearch(params),
    ...options,
  });
};

export default useFetchClientPrograms;
