import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MutationOptions } from "./types";
import { fetchProgramQueryKey } from "./useFetchProgramQuery";
import { fetchPatientProgramQueryKey } from "./useFetchPatientProgramQuery";
import { updateProgramName, UpdateProgramNameRequestDto } from "@client";

export const useUpdateProgramName = (id: number, options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation(
    (request: UpdateProgramNameRequestDto) => updateProgramName(id, request),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries([fetchProgramQueryKey, id]);
        queryClient.invalidateQueries([fetchPatientProgramQueryKey]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};
