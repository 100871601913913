import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const PlusInCircle = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="14" viewBox="0 0 14 14" {...props}>
      <path
        fill="none"
        d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"
        stroke={props.fill ?? "currentColor"}
        strokeWidth="1.05"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 4.12003V9.88003"
        stroke={props.fill ?? "currentColor"}
        strokeWidth="0.96"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.12012 7H9.88012"
        stroke={props.fill ?? "currentColor"}
        strokeWidth="0.96"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
