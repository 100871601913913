import { useQuery } from "@tanstack/react-query";
import { QueryOptions } from "./types";
import { buildDefaultQueryOptions } from "./common";
import { fetchScheduleEvent } from "@client";

export const fetchScheduleEventQueryKey = "fetchScheduleEventQueryKey";

export const useFetchScheduleEvent = (id: number, options?: QueryOptions) => {
  const { data, ...rest } = useQuery({
    queryKey: [fetchScheduleEventQueryKey, id],
    queryFn: () => fetchScheduleEvent(id),
    ...buildDefaultQueryOptions(options),
    retry: (failureCount, error) => {
      // Retry if the error status is not 500
      if (error?.response?.status !== 500) {
        return failureCount < 3;
      }
      return false;
    },
  });

  return { scheduleEvent: data?.data, ...rest };
};
