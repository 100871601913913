import React from "react";
import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Show = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <rect
      id="Rectangle_967"
      data-name="Rectangle 967"
      width="20"
      height="20"
      fill="#fff"
      opacity="0"
    />
    <path
      id="Show"
      d="M9.711,15.995C5.806,15.865,2.252,13.107.2,8.617L.061,8.294a.743.743,0,0,1,0-.589A14.272,14.272,0,0,1,4.254,2.059,9.337,9.337,0,0,1,10,0l.286,0c3.9.131,7.459,2.89,9.509,7.381l.139.313a.746.746,0,0,1,0,.6,14.282,14.282,0,0,1-4.194,5.647A9.337,9.337,0,0,1,10,16ZM5.33,3.118a12.322,12.322,0,0,0-3.6,4.548L1.569,8l.169.361a12.5,12.5,0,0,0,3.379,4.361,7.979,7.979,0,0,0,4.362,1.77l.266.014.257,0,.255,0a7.94,7.94,0,0,0,4.634-1.795,12.666,12.666,0,0,0,3.526-4.687L18.429,8A12.8,12.8,0,0,0,15,3.379a8.026,8.026,0,0,0-4.49-1.87l-.266-.014-.239-.005A7.827,7.827,0,0,0,5.33,3.118ZM6.088,8A3.911,3.911,0,1,1,10,11.886,3.9,3.9,0,0,1,6.088,8Zm1.5,0A2.412,2.412,0,1,0,10,5.6,2.406,2.406,0,0,0,7.588,8Z"
      transform="translate(0 2)"
      fill="#801fff"
    />
  </SvgIcon>
);
