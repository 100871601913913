import { useMemo } from "react";
import { FormProvider } from "react-hook-form";

import { InputAdornment, useMediaQuery, useTheme } from "@mui/material";
import dayjs from "dayjs";

import { TrashNew } from "@assets/icons";
import { DietMealListItemCreatorDto } from "@client/diets/creator";
import {
  AutosaveOnBlurProvider,
  useAutosaveOnBlurContext,
} from "@components/AutosaveOnBlur";
import { FormTextField } from "@components/FormTextField";
import { ModalWrapper } from "@components/ModalWrapperNew";
import { MuiTimeDatePicker } from "@components/MuiTimeDatePicker";
import { useAppParams, useAppTranslation, useModalState } from "@hooks";
import { decimalFourDigitsInput } from "@utils/inputs";
import { getTranslation } from "@utils/translations";

import { Label } from "../../common/Label";
import {
  useDeleteDietMealMutation,
  useUpdateDietMealMutation,
} from "../../../hooks";
import { MealTypeTextField } from "./MealTypeTextField";
import { PercentageTextField } from "./PercentageTextField";
import {
  DeleteModalWrapper,
  FlexColumn,
  FlexRow,
  ModalText,
  ModalTextBold,
  TrashButton,
} from "./ScheduleRow.styled";
import {
  ScheduleRowInputs,
  mapScheduleRequest,
  mapScheduleRowForm,
  useScheduleRowForm,
} from "./useScheduleRowForm";

interface ScheduleRowProps {
  showMacros: boolean;
  showLabel?: boolean;
  data: DietMealListItemCreatorDto | undefined;
}

export const ScheduleRow = ({
  showMacros,
  showLabel = true,
  data,
}: ScheduleRowProps) => {
  const { t, currentLanguage } = useAppTranslation();
  const { dietId } = useAppParams();
  const { breakpoints } = useTheme();
  const upMedium = useMediaQuery(`${breakpoints.up("medium")}`);

  const defaultValues = useMemo(() => mapScheduleRowForm(data), [data]);
  const mealId = useMemo(() => data?.id ?? 0, [data?.id]);
  const mealName = useMemo(
    () => getTranslation(data?.translations, currentLanguage),
    [data?.translations, currentLanguage],
  );

  const { control, ...rest } = useScheduleRowForm(defaultValues);
  const { onBlur, onFocus } = useAutosaveOnBlurContext();
  const [open, onOpen, onClose] = useModalState();

  const { mutate, isLoading } = useUpdateDietMealMutation();
  const { mutate: deleteMeal, isLoading: isDeleting } =
    useDeleteDietMealMutation();
  const handleDelete = () =>
    deleteMeal({ dietId, mealId: mealId.toString() }, { onSuccess: onClose });

  const onSubmit = (values: ScheduleRowInputs) =>
    mutate({
      dietId,
      mealId: mealId.toString(),
      payload: mapScheduleRequest(values, data?.translations),
    });

  const isValidHour =
    data?.hour && dayjs(data.hour, "HH:mm").format("HH:mm") !== "Invalid Date";

  const DeleteModal = (
    <ModalWrapper
      title={t("diet.delete_meal.title")}
      open={open}
      onClose={onClose}
      onSubmit={handleDelete}
      color="error"
      confirmText={t("diet.delete_meal.title")}
    >
      <DeleteModalWrapper>
        <ModalText>
          {t("diet.delete_meal.question")}:{" "}
          <ModalTextBold>
            {mealName}
            {isValidHour
              ? `, ${dayjs(data.hour, "HH:mm").format("HH:mm")}`
              : ""}
          </ModalTextBold>
          ?
        </ModalText>
        <ModalText>{t("diet.delete_meal.description")}</ModalText>
      </DeleteModalWrapper>
    </ModalWrapper>
  );

  if (upMedium)
    return (
      <FormProvider control={control} {...rest}>
        {DeleteModal}
        <AutosaveOnBlurProvider.Watch
          isLoading={isLoading}
          onSubmit={onSubmit}
        />
        <FlexRow>
          <Label label={showLabel ? t("diet.meal_type") : false} flex={2}>
            <MealTypeTextField mealId={mealId} />
          </Label>
          <Label label={showLabel ? t("diet.hour") : false}>
            <MuiTimeDatePicker
              timeVersion
              name="hour"
              step={5}
              control={control}
              onBlur={onBlur}
              onFocus={onFocus}
              fullWidth
            />
          </Label>
          <Label label={showLabel ? t("common.energy_efficiency") : false}>
            <FormTextField
              control={control}
              name="kcal"
              fullWidth
              size="small"
              InputProps={{
                inputComponent: decimalFourDigitsInput,
                endAdornment: (
                  <InputAdornment position="end">kcal</InputAdornment>
                ),
              }}
              onBlur={onBlur}
              onFocus={onFocus}
            />
          </Label>
          <PercentageTextField showLabel={showLabel} mealId={mealId} />
          {!showMacros && (
            <TrashButton color="primary" onClick={onOpen} disabled={isDeleting}>
              <TrashNew size="w-[14px] h-[14px]" />
            </TrashButton>
          )}
          <Label
            label={showLabel ? t("common.protein") : false}
            hidden={!showMacros}
          >
            <FormTextField
              control={control}
              name="protein"
              fullWidth
              size="small"
              InputProps={{
                inputComponent: decimalFourDigitsInput,
                endAdornment: <InputAdornment position="end">g</InputAdornment>,
              }}
              onBlur={onBlur}
              onFocus={onFocus}
            />
          </Label>
          <Label
            label={showLabel ? t("common.fats") : false}
            hidden={!showMacros}
          >
            <FormTextField
              control={control}
              name="fat"
              fullWidth
              size="small"
              InputProps={{
                inputComponent: decimalFourDigitsInput,
                endAdornment: <InputAdornment position="end">g</InputAdornment>,
              }}
              onBlur={onBlur}
              onFocus={onFocus}
            />
          </Label>
          <Label
            label={showLabel ? t("common.carbs") : false}
            hidden={!showMacros}
          >
            <FormTextField
              control={control}
              name="carb"
              fullWidth
              size="small"
              InputProps={{
                inputComponent: decimalFourDigitsInput,
                endAdornment: <InputAdornment position="end">g</InputAdornment>,
              }}
              onBlur={onBlur}
              onFocus={onFocus}
            />
          </Label>
          {showMacros && (
            <TrashButton color="primary" onClick={onOpen} disabled={isDeleting}>
              <TrashNew size="w-[14px] h-[14px]" />
            </TrashButton>
          )}
        </FlexRow>
      </FormProvider>
    );

  return (
    <FormProvider control={control} {...rest}>
      {DeleteModal}
      <AutosaveOnBlurProvider.Watch isLoading={isLoading} onSubmit={onSubmit} />
      <FlexColumn>
        <FlexRow>
          <Label label={t("diet.meal_type")} flex={3}>
            <MealTypeTextField mealId={mealId} />
          </Label>
          <Label label={t("diet.hour")}>
            <MuiTimeDatePicker
              timeVersion
              name="hour"
              step={5}
              control={control}
              onBlur={onBlur}
              onFocus={onFocus}
            />
          </Label>
          <TrashButton color="primary" onClick={onOpen} disabled={isDeleting}>
            <TrashNew size="w-[14px] h-[14px]" />
          </TrashButton>
        </FlexRow>

        <div className="flex gap-[8px]">
          <Label label={t("common.energy_efficiency")}>
            <FormTextField
              control={control}
              name="kcal"
              fullWidth
              size="small"
              InputProps={{
                inputComponent: decimalFourDigitsInput,
                endAdornment: (
                  <InputAdornment position="end">kcal</InputAdornment>
                ),
              }}
              onBlur={onBlur}
              onFocus={onFocus}
            />
          </Label>
          <PercentageTextField mealId={mealId} />
          <Label label={t("common.protein")} hidden={!showMacros}>
            <FormTextField
              control={control}
              name="protein"
              fullWidth
              size="small"
              InputProps={{
                inputComponent: decimalFourDigitsInput,
                endAdornment: <InputAdornment position="end">g</InputAdornment>,
              }}
              onBlur={onBlur}
              onFocus={onFocus}
            />
          </Label>
          <Label label={t("common.fats")} hidden={!showMacros}>
            <FormTextField
              control={control}
              name="fat"
              fullWidth
              size="small"
              InputProps={{
                inputComponent: decimalFourDigitsInput,
                endAdornment: <InputAdornment position="end">g</InputAdornment>,
              }}
              onBlur={onBlur}
              onFocus={onFocus}
            />
          </Label>
          <Label label={t("common.carbs")} hidden={!showMacros}>
            <FormTextField
              control={control}
              name="carb"
              fullWidth
              size="small"
              InputProps={{
                inputComponent: decimalFourDigitsInput,
                endAdornment: <InputAdornment position="end">g</InputAdornment>,
              }}
              onBlur={onBlur}
              onFocus={onFocus}
            />
          </Label>
        </div>
      </FlexColumn>
    </FormProvider>
  );
};
