import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useAppTranslation } from "@hooks";
import { MutationOptions } from "../types";
import { fetchClinicProductsQueryKey } from "../useFetchClinicProducts";
import { deleteClinicProduct } from "@client/settings";
import { toast } from "react-toastify";

export const useDeleteClinicProductMutation = (options?: MutationOptions) => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, number>(id => deleteClinicProduct(id), {
    ...options,
    onSuccess: () => {
      toast.success(t("products_settings.product_removed"));
      queryClient.invalidateQueries([fetchClinicProductsQueryKey]);
      options?.onSuccess && options.onSuccess();
    },
  });
};
