import { IconButton } from "@mui/material";
import {
  ButtonContainer,
  ButtonContainerRight,
  ContainerDays,
  LeftArrowButton,
} from "@views/dietician/PatientMonitoring2/components/CalendarDays/CalendarDays.styled";
import { ArrowMonitoring } from "@assets/icons/Monitoring";
import { FC, ReactNode } from "react";

type CalendarDaysProps = {
  addNextDays: () => void;
  addPrevDays: () => void;
  children: ReactNode;
};

const CalendarDays: FC<CalendarDaysProps> = ({
  addNextDays,
  addPrevDays,
  children,
}) => {
  return (
    <>
      <ButtonContainer>
        <LeftArrowButton onClick={addPrevDays}>
          <ArrowMonitoring fill="#7448D0" />
        </LeftArrowButton>
      </ButtonContainer>
      <ContainerDays className="hide-scrollbar">{children}</ContainerDays>
      <ButtonContainerRight>
        <IconButton onClick={addNextDays}>
          <ArrowMonitoring fill="#7448D0" />
        </IconButton>
      </ButtonContainerRight>
    </>
  );
};

export default CalendarDays;
