import { Box, styled } from "@mui/material";

const ProgramHighlightedBox = styled(Box)`
  background: #fbfafc;
  margin: -16px -16px 0;
  padding: 16px 16px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: sticky;
  top: -16px;
  z-index: 1;
` as typeof Box;

export default ProgramHighlightedBox;
