import {
  ChildrenTabWrapper,
  EmptyDiv,
  StyledNutrients,
  StyledTitle,
} from "./WeekdaySelectorTab.styled";
import { Weekday, WeekdaySelector } from "../WeekdaySelector/WeekdaySelector";
import { buildTabLabel, buildTargetTabLabel } from "./utils/tabLabelBuilder";

interface WeekdaySelectorTabProps {
  title?: string | null;
  energy?: number | null;
  cpm?: number | null;
  weekdays: Weekday[];
  withTarget?: boolean;
}

export const ProgramDayTab = ({
  title,
  energy,
  cpm,
  weekdays,
  withTarget = false,
}: WeekdaySelectorTabProps) => {
  const showTargetLabel = withTarget;
  const showTabLabel = !withTarget && (!!energy ?? !!cpm);
  const showPlaceholder = !showTargetLabel && !showTabLabel;

  return (
    <ChildrenTabWrapper>
      <StyledTitle>{title ?? ""}</StyledTitle>
      {showTargetLabel && (
        <StyledNutrients>{buildTargetTabLabel(energy, cpm)}</StyledNutrients>
      )}

      {showTabLabel && (
        <StyledNutrients>{buildTabLabel(energy, cpm)}</StyledNutrients>
      )}

      {showPlaceholder && <EmptyDiv />}
      <WeekdaySelector className="justify-end" active={weekdays} size="small" />
    </ChildrenTabWrapper>
  );
};
