import { Card, styled } from "@mui/material";

export const StyledCard = styled(Card)`
  display: grid;
  gap: 1rem;
  padding: 1.25rem;
`;
export const TableCard = styled(Card)`
  display: grid;
  gap: 1rem;
  width: 100%;
`;

export const StyledChartCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  min-width: 12rem;
`;
