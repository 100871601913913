import { memo, useCallback, useEffect } from "react";
import { FormProvider } from "react-hook-form";

import { useAppTranslation, useSectionForm } from "@hooks";
import { CUSTOM_SECTION_CATEGORY_ID } from "@utils/program";
import { SectionFormInput } from "@typeDefinitions/types";
import { useCreateSectionMutation } from "@hooks/queries";
import { Modal } from "@components/Modal";
import { SectionForm } from "@components/SectionForm";

interface SectionModalProps {
  hide: () => void;
}

export const CreateSectionModal = memo(({ hide }: SectionModalProps) => {
  const { t } = useAppTranslation();
  const form = useSectionForm();
  const { mutate: createSectionMutation } = useCreateSectionMutation();

  useEffect(
    () =>
      form.setValue("sectionCategoryId", CUSTOM_SECTION_CATEGORY_ID.toString()),
    [],
  );

  const submit = useCallback(
    (d: SectionFormInput) => {
      createSectionMutation(
        {
          title: d.namePl,
          titleEn: d.nameEn ?? null,
          content: d.contentPl,
          contentEn: d.contentEn ?? null,
          programSectionCategoryId: parseInt(d.sectionCategoryId),
        },
        { onSuccess: hide },
      );
    },
    [createSectionMutation],
  );

  return (
    <FormProvider {...form}>
      <Modal
        onSubmit={() => form.handleSubmit(submit)()}
        onClose={hide}
        title={t("addNutritionalProgram.customSectionModal.title")}
      >
        <div className="p-7">
          <SectionForm />
        </div>
      </Modal>
    </FormProvider>
  );
});
