import dayjs from "dayjs";

export const dayIndexFactory = (
  date: string,
  startDate: string | undefined,
) => {
  return (
    dayjs(date).endOf("day").diff(dayjs(startDate).endOf("day"), "day") + 1
  );
};
