import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const NoParkingGreySign = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      style={{ fill: "none" }}
      width="20"
      height="21"
      viewBox="0 0 20 21"
    >
      <rect x="1" y="1.5" width="18" height="18" rx="9" fill="#F1F1F1" />
      <rect
        x="1"
        y="1.5"
        width="18"
        height="18"
        rx="9"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M16.5 10.625C16.4831 11.8438 16.1868 12.9355 15.6113 13.9004C15.0358 14.8822 14.2572 15.6608 13.2754 16.2363C12.3105 16.8118 11.2188 17.1081 10 17.125C8.78125 17.1081 7.68945 16.8118 6.72461 16.2363C5.74284 15.6608 4.96419 14.8822 4.38867 13.9004C3.81315 12.9355 3.51693 11.8438 3.5 10.625C3.51693 9.40625 3.81315 8.31445 4.38867 7.34961C4.96419 6.36784 5.74284 5.58919 6.72461 5.01367C7.68945 4.43815 8.78125 4.14193 10 4.125C11.2188 4.14193 12.3105 4.43815 13.2754 5.01367C14.2572 5.58919 15.0358 6.36784 15.6113 7.34961C16.1868 8.31445 16.4831 9.40625 16.5 10.625ZM5.86133 7.34961C5.11654 8.26367 4.73568 9.35547 4.71875 10.625C4.7526 12.1146 5.26888 13.3587 6.26758 14.3574C7.26628 15.3561 8.51042 15.8724 10 15.9062C11.2695 15.8893 12.3613 15.5085 13.2754 14.7637L5.86133 7.34961ZM15.2812 10.625C15.2474 9.13542 14.7311 7.89128 13.7324 6.89258C12.7337 5.89388 11.4896 5.3776 10 5.34375C8.73047 5.36068 7.63867 5.74154 6.72461 6.48633L14.1387 13.9004C14.8835 12.9863 15.2643 11.8945 15.2812 10.625Z"
        fill="#727272"
      />
    </SvgIcon>
  );
};
