import { Micro } from "@assets/icons";
import { SvgIconProps } from "@components/SvgIcon";
import { IconButton, styled } from "@mui/material";

interface MicroButtonProps {
  noPadding?: boolean;
  autoMargin?: boolean;
}

export const StyledMicroIconButton = styled(IconButton, {
  shouldForwardProp: propName =>
    propName !== "noPadding" && propName !== "autoMargin",
})<MicroButtonProps>`
  padding: ${({ noPadding }) => (noPadding ? 0 : "")};
  margin: ${({ autoMargin }) => (autoMargin ? "auto 0" : "")};
  color: ${({ theme }) => theme.palette.primary.main};
  &.MuiButtonBase-root.MuiIconButton-root:hover {
    background-color: ${({ theme }) => theme.colors.hoverBtnPurple};
  }
`;

interface CompactProps extends SvgIconProps {
  compact?: boolean;
}

export const StyledMicro = styled(Micro, {
  shouldForwardProp: propName => propName !== "compact",
})<CompactProps>`
  width: ${({ compact }) => (compact ? "1.5rem" : "1.75rem")};
  height: ${({ compact }) => (compact ? "1.5rem" : "1.75rem")};
`;
