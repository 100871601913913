import { TabPanel } from "@mui/lab";
import { styled } from "@mui/material";

export const Wrapper = styled("div")`
  width: 780px;
  position: relative;
`;

export const DetailsWrapper = styled("div")`
  display: grid;
  gap: 18px;
  position: sticky;
  top: 0;
  z-index: 1;
  background: ${({ theme }) => theme.palette.primary.light};
  padding: 16px 16px 0 16px;
`;

export const TagIconWrapper = styled("div")`
  display: flex;
  gap: 6px;
  align-items: center;
`;

export const Label = styled("span")`
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
`;

export const ImgStyled = styled("img")`
  height: 220px;
  width: 100%;
  object-fit: cover;
`;

interface TabPanelStyledProps {
  padding?: string;
}
export const TabPanelStyled = styled(TabPanel, {
  shouldForwardProp: prop => prop !== "padding",
})<TabPanelStyledProps>`
  min-height: max-content;
  &.MuiTabPanel-root {
    padding: ${({ padding }) => padding ?? "16px"};
  }
`;

export const LoadingWrapper = styled("div")`
  display: grid;
  place-items: center;
  height: 100vh;
`;
