import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const BlankTablet = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="12" height="16" viewBox="0 0 12 16">
      <path
        d="M8.59982 2.8H9.89982C10.2446 2.8 10.5753 2.93697 10.8191 3.18076C11.0629 3.42456 11.1998 3.75522 11.1998 4.10001V13.2C11.1998 13.5448 11.0629 13.8755 10.8191 14.1193C10.5753 14.3631 10.2446 14.5 9.89982 14.5H2.09981C1.75503 14.5 1.42436 14.3631 1.18057 14.1193C0.936769 13.8755 0.799805 13.5448 0.799805 13.2V4.10001C0.799805 3.75522 0.936769 3.42456 1.18057 3.18076C1.42436 2.93697 1.75503 2.8 2.09981 2.8H3.39981"
        stroke={props.fill ?? "currentColor"}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M7.9504 1.5H4.05039C3.69141 1.5 3.40039 1.79102 3.40039 2.15V3.45C3.40039 3.80899 3.69141 4.1 4.05039 4.1H7.9504C8.30938 4.1 8.6004 3.80899 8.6004 3.45V2.15C8.6004 1.79102 8.30938 1.5 7.9504 1.5Z"
        stroke={props.fill ?? "currentColor"}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};
