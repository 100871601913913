import { Close, EmptyCircle } from "@assets/icons/DesignSystem";
import { Dialog, DialogTitle, useTheme } from "@mui/material";
import { ReactNode } from "react";
import {
  AnswerStyled,
  DialogContentStyled,
  DotWrapper,
  IconButtonStyled,
  ModalTitleWrapper,
  QuestionWrapper,
  StyledQuestion,
} from "./ProgramEmptyState.styled";
import { useAppTranslation } from "@hooks";

interface EmptyStateDialogProps {
  open: boolean;
  onClose: () => void;
  icon: ReactNode;
  title: string;
  question: string;
  answer: string;
  instructionQuestion: string;
  instruction: string;
}

export const EmptyStateDialog = ({
  open,
  onClose,
  icon,
  title,
  question,
  answer,
  instructionQuestion,
  instruction,
}: EmptyStateDialogProps) => {
  const { t } = useAppTranslation();
  const { palette } = useTheme();
  const instructionSplit = instruction.split(";");

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <ModalTitleWrapper>
          {icon}
          {title}
        </ModalTitleWrapper>
      </DialogTitle>
      <IconButtonStyled onClick={onClose} size="small">
        <Close size="w-3 h-3" />
      </IconButtonStyled>
      <DialogContentStyled>
        <div className="grid gap-8">
          <div className="grid gap-4">
            <QuestionWrapper>
              <EmptyCircle size="w-4 h-4" />

              <StyledQuestion>{question}</StyledQuestion>
            </QuestionWrapper>

            <AnswerStyled>{answer}</AnswerStyled>
          </div>

          <div className="grid gap-4">
            <QuestionWrapper>
              <EmptyCircle size="w-4 h-4" />

              <StyledQuestion>{instructionQuestion}</StyledQuestion>
            </QuestionWrapper>

            <div className="grid gap-2">
              {instructionSplit.map((instruction, idx) => (
                <div className="flex" key={idx}>
                  <DotWrapper>•</DotWrapper>
                  <AnswerStyled>
                    {t("empty_states.modal.step")} {idx + 1}. {instruction}
                  </AnswerStyled>
                </div>
              ))}
            </div>
          </div>
        </div>
      </DialogContentStyled>
    </Dialog>
  );
};
