import { useMutation, useQueryClient } from "@tanstack/react-query";
import { patientKeys } from "@hooks/queries/client/patientKeys";
import {
  updatePatientProductExchangers,
  UpdatePatientProductExchangersParams,
  UpdatePatientProductExchangersPayload,
} from "@client/patient/updatePatientProductExchangers";
import { FetchPatientProductExchangersResponse } from "@client/patient/fetchPatientProductExchangers";

const useUpdatePatientProductExchangersMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      params,
      payload,
    }: {
      params: UpdatePatientProductExchangersParams;
      payload: UpdatePatientProductExchangersPayload;
    }) => {
      return updatePatientProductExchangers(params, payload);
    },
    onSuccess: async (_, variables) => {
      queryClient.setQueryData(
        patientKeys.productExchangers(variables.params.patientId),
        (oldData: FetchPatientProductExchangersResponse | undefined) => {
          if (oldData === undefined) {
            return undefined;
          }

          return {
            ...oldData,
            data: variables.payload.tags_id.map(id => ({ tag: { id } })),
          };
        },
      );
    },
  });
};

export default useUpdatePatientProductExchangersMutation;
