import { Card, IconButton, styled } from "@mui/material";
import { TABLET_MAX_CONTENT_WIDTH, LAPTOP_MAX_CONTENT_WIDTH } from "@utils";

export const MealsSectionWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const HeaderWrapper = styled("div")`
  display: flex;
  width: 100%;
`;

export const HeaderItem = styled("div")`
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const DietMealsSumWrapper = styled("div")`
  display: grid;
  background-color: ${({ theme }) => theme.colors.subtlePurple};
  padding: 0.5rem;
`;

export const MealsSectionStyled = styled(Card)`
  display: flex;
  padding: 1rem;
  min-height: 14rem;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallMax}px) {
    width: ${TABLET_MAX_CONTENT_WIDTH};
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.medium}px) and (max-width: ${({ theme }) =>
      theme.breakpoints.values.laptopMax}px) {
    width: ${LAPTOP_MAX_CONTENT_WIDTH};
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.desktop}px) and (max-width: ${({ theme }) =>
      theme.breakpoints.values.desktopMax}px) {
    flex: 1;
    height: 55.375rem;
    overflow-y: auto;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.largeDesktop}px) {
    flex: 1;
    height: 51.875rem;
    overflow-y: auto;
  }
`;

interface StyledIconButtonProps {
  rotate?: boolean;
}
export const StyledIconButton = styled(IconButton, {
  shouldForwardProp: prop => prop !== "rotate",
})<StyledIconButtonProps>`
  justify-self: flex-end;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  ${({ rotate }) => rotate && "transform: rotate(180deg)"};
`;
