import { FormControlLabel, TextField, styled } from "@mui/material";

export const NormNutrientRowStyled = styled("div")`
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
`;

export const FormControlLabelStyled = styled(FormControlLabel)`
  margin-left: 0;

  & .MuiFormControlLabel-label {
    color: ${({ theme }) => theme.colors.neutral.dark[800]};
    font-weight: 500;
  }
`;

export const TextFieldStyled = styled(TextField)`
  min-width: 148px;
  width: 148px;

  & input {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.neutral.dark[800]};
  }
`;
