import { useQueryClient } from "@tanstack/react-query";
import {
  fetchProductShopsQueryKey,
  useFetchProductShopsQuery,
} from "./queries/dictionaries";
import { ApiResponse } from "@typeDefinitions";
import { ProductShopDictionaryDto } from "@client/dictionaries";
import { useMemo } from "react";

export const useProductShops = () => {
  const queryClient = useQueryClient();
  const cacheData = queryClient.getQueryData<
    ApiResponse<ProductShopDictionaryDto[]>
  >([fetchProductShopsQueryKey]);
  const { data } = useFetchProductShopsQuery({ enabled: !cacheData });

  const shops = cacheData?.data ?? data ?? [];

  const shopDictionary = useMemo(
    () => new Map(shops.map(shop => [shop.id, shop.name])),
    [shops],
  );
  const options = useMemo(
    () => shops.map(({ id, name }) => ({ id: id.toString(), label: name })),
    [shops],
  );
  return {
    shopDictionary,
    options,
  };
};
