import { number, object } from "yup";

export interface NutrientValueResource {
  id: number;
  value: number | null;
}

export const nutrientValueSchema = object({
  id: number().required(),
  value: number().nullable().required(),
});
