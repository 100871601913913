import { useCallback, useMemo, useState } from "react";

import { GridRenderCellParams } from "@mui/x-data-grid";

import { Buildings, Edit, Plus } from "@assets/icons";
import { dietitianClinicLogoUrl } from "@client";
import { FetchClinicFacilitiesResponse } from "@client/settings";
import { Avatar } from "@components/Avatar";
import { Spinner } from "@components/Spinner";
import { AddOfficeModal } from "@components/AddOfficeModal";
import { useAppTranslation, useImageUpload } from "@hooks";
import { mapAddressString } from "@utils/settings";
import {
  useFetchClinicFacilitiesQuery,
  useFetchClinicNameQuery,
} from "@hooks/queries/settings";
import { EditBrandNameModal } from "@views/dietician/Diet/components/EditBrandNameModal";

import { OfficeAction } from "./OfficeAction";
import {
  AvatarSection,
  ContentSection,
  InfoOfficeSection,
  OfficeListSection,
  OfficesWrapper,
  StyledDataGrid,
  StyledIconButton,
  StyledSubtitle,
  SubtitleDescr,
} from "./OfficesTab.styled";
import { AddOfficeButton } from "../DieticianSettingsTabs/DieticianSettingsTabs.styled";
import { AddressCell } from "./AddressCell";

export const OfficesTab = () => {
  const { t, isPolishChosen } = useAppTranslation();
  const [openEditNameModal, setOpenEditNameModal] = useState(false);
  const handleCloseEditNameModal = useCallback(
    () => setOpenEditNameModal(false),
    [],
  );
  const handleOpenEditNameModal = useCallback(
    () => setOpenEditNameModal(true),
    [],
  );

  const [openEditOfficeModal, setOpenEditOfficeModal] = useState(false);
  const handleCloseEditOfficeModal = useCallback(
    () => setOpenEditOfficeModal(false),
    [],
  );
  const handleOpenEditOfficeModal = useCallback(
    () => setOpenEditOfficeModal(true),
    [],
  );

  const [officeId, setOfficeId] = useState<number | undefined>(undefined);
  const handleSetOfficeId = useCallback((id?: number) => setOfficeId(id), []);

  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = useCallback(() => setOpenModal(false), []);

  const { name, isLoading: isNameLoading } = useFetchClinicNameQuery();
  const { facilities, isLoading: isFacilitiesLoading } =
    useFetchClinicFacilitiesQuery();
  const isLoading = isNameLoading || isFacilitiesLoading;

  const columns = useMemo(
    () => [
      {
        field: "officeName",
        headerName: t("company_settings.name"),
        flex: 1,
        minWidth: 200,
        disableColumnMenu: true,
      },
      {
        field: "isMain",
        headerName: t("company_settings.isMain"),
        type: "boolean",
        disableColumnMenu: true,
      },
      {
        field: "email",
        headerName: t("company_settings.email"),
        flex: 0.8,
        minWidth: 200,
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: "tel",
        headerName: t("company_settings.phone"),
        minWidth: 170,
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: "address",
        headerName: t("company_settings.address"),
        flex: 1,
        minWidth: 200,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => (
          <AddressCell address={params.row.fullAddress} />
        ),
      },
      {
        field: "actions",
        headerName: t("company_settings.options"),
        type: "actions",
        minWidth: 50,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => (
          <OfficeAction
            id={params.id}
            setOfficeId={handleSetOfficeId}
            openEditModal={handleOpenEditOfficeModal}
            isMain={params.row.isMain}
          />
        ),
      },
    ],
    [isPolishChosen],
  );

  const { isUploading, imgUrl, handleUploadAvatar } = useImageUpload({
    url: dietitianClinicLogoUrl,
  });

  if (isLoading) return <Spinner />;

  return (
    <div className={"flex flex-col"}>
      <AddOfficeModal open={openModal} onClose={handleCloseModal} />
      <AddOfficeButton
        variant="contained"
        onClick={() => setOpenModal(true)}
        startIcon={<Plus size="w-3 h-3" className="stroke-current" />}
      >
        {t("company_settings.add_office")}
      </AddOfficeButton>
      <OfficesWrapper>
        <InfoOfficeSection>
          <AvatarSection>
            <Avatar
              isEditable
              size="extra-big"
              isUploading={isUploading}
              onFileUpload={handleUploadAvatar}
              image={imgUrl}
              fullName={name?.name ?? ""}
            />
          </AvatarSection>

          <ContentSection>
            <div>
              <Buildings className="self-start" size="w-5 h-5" />
            </div>

            <SubtitleDescr>
              <StyledSubtitle>{t("company_settings.brand")}</StyledSubtitle>
              <p className="text-base leading-none">{name?.name}</p>
            </SubtitleDescr>

            <StyledIconButton onClick={handleOpenEditNameModal} color="primary">
              <Edit className="p-0" size="w-5 h-5" />
            </StyledIconButton>
          </ContentSection>
        </InfoOfficeSection>
        <OfficeListSection>
          <StyledDataGrid
            autoHeight
            rows={mapFacilitiesDataGrid(facilities, isPolishChosen)}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            getRowSpacing={params => ({
              top: 8,
            })}
          />
        </OfficeListSection>
      </OfficesWrapper>
      <EditBrandNameModal
        open={openEditNameModal}
        onClose={handleCloseEditNameModal}
      />
      <AddOfficeModal
        open={openEditOfficeModal}
        onClose={handleCloseEditOfficeModal}
        officeId={officeId}
      />
    </div>
  );
};

const mapFacilitiesDataGrid = (
  data?: FetchClinicFacilitiesResponse[],
  isPolish = true,
) => {
  if (!data) return [];
  return data.map(facility => ({
    id: facility.id,
    officeName: facility.name ?? "",
    email: facility.email,
    tel: (facility.phone.prefix ?? "") + " " + (facility.phone.number ?? ""),
    address: mapAddressString(
      { ...facility.address, postcode: facility.address.postCode },
      isPolish,
    ),
    fullAddress: facility.address,
    isMain: facility.isMain,
  }));
};
