import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { FormProvider } from "react-hook-form";

import { useAppTranslation } from "@hooks";
import { RecipeProductsContextProvider } from "@context";
import { useFetchScheduleRecipeQuery } from "@hooks/queries/schedule";
import { SingleCardPageLayout } from "@components/PageLayout";
import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";
import { RecipeFormWrapper } from "@views/dietician/Recipe/RecipeEdit.styled";
import { BreadcrumbsPath } from "@components/BreadcrumbsPath";
import { AutosaveStatus } from "@components/AutosaveStatus";
import { RecipeEditForm } from "@components/RecipeEditForm";
import { Food, Users } from "@assets/icons/Menu";
import {
  mapScheduleRecipeForm,
  useRecipeEditForm,
} from "@components/RecipeForm/useRecipeEditForm";
import { ScheduleRecipeEditWatch } from "@views/dietician/ScheduleRecipe/ScheduleRecipeEditWatch";
import { useFetchClientQuery, useFetchProgramQuery } from "@hooks/queries";

type RecipeParams = {
  programId: string;
  dayId: string;
  mealId: string;
  patientId?: string;
};

export const ScheduleRecipeEdit = () => {
  const { t, isPolishChosen } = useAppTranslation();
  const { programId, dayId, mealId, patientId } = useParams<RecipeParams>();

  const programIdParsed = useMemo(
    () => (programId ? parseInt(programId) : 0),
    [programId],
  );
  const dayIdParsed = useMemo(() => (dayId ? parseInt(dayId) : 0), [dayId]);
  const mealIdParsed = useMemo(() => (mealId ? parseInt(mealId) : 0), [mealId]);

  const { recipe, isLoading, isError } = useFetchScheduleRecipeQuery(
    programIdParsed,
    dayIdParsed,
    mealIdParsed,
  );
  const {
    program,
    isLoading: isLoadingProgram,
    isError: isErrorProgram,
  } = useFetchProgramQuery(programIdParsed);
  const programName = isPolishChosen
    ? program?.name
    : program?.nameEn || program?.name;
  const formRecipe = useMemo(() => mapScheduleRecipeForm(recipe), [recipe]);
  const form = useRecipeEditForm(formRecipe);

  const { client } = useFetchClientQuery(patientId ?? "", {
    enabled: !!patientId,
  });

  const breadcrumbs = useMemo(() => {
    if (!patientId) {
      return [
        {
          icon: <Food />,
          backTo: "/programs",
          title: t("programs.title"),
        },
        {
          backTo: `/programs/${programId}`,
          title: programName ?? "",
        },
        {
          backTo: `/programs/${programId}/schedule`,
          title: t("program.schedule"),
        },
        {
          backTo: `/programs/${programId}/schedule/day/${dayId}/meal/${mealId}/edit`,
          title: t("recipes.edit_recipe"),
        },
      ];
    } else {
      return [
        {
          icon: <Users />,
          backTo: "/patients",
          title: t("patients.title"),
        },
        {
          backTo: `/patients/${patientId}`,
          title: `${client?.firstName ?? ""} ${client?.lastName ?? ""}`,
        },
        {
          backTo: `/patients/${patientId}/nutritional-programs/${programId}/schedule`,
          title: t("program.schedule"),
        },
        {
          backTo: `/patients/${patientId}/nutritional-programs/${programId}/schedule/day/${dayId}/meal/${mealId}/edit`,
          title: t("recipes.edit_recipe"),
        },
      ];
    }
  }, [isPolishChosen, programId, dayId, mealId, program, client, patientId]);

  if (isLoading || isLoadingProgram) {
    return (
      <SingleCardPageLayout title={t("recipe_edit.title")}>
        <Spinner className="w-full h-56" />
      </SingleCardPageLayout>
    );
  }

  if (isErrorProgram || isError || !recipe) {
    return (
      <SingleCardPageLayout title={t("recipe_edit.title")}>
        <ApiError />
      </SingleCardPageLayout>
    );
  }

  return (
    <RecipeFormWrapper>
      <div className="flex justify-between">
        <BreadcrumbsPath breadcrumbs={breadcrumbs} />
        <AutosaveStatus />
      </div>

      <RecipeProductsContextProvider recipe={recipe}>
        <FormProvider {...form}>
          <ScheduleRecipeEditWatch
            recipe={recipe}
            recipeLoading={isLoading}
            programId={programIdParsed}
            dayId={dayIdParsed}
            mealId={mealIdParsed}
          />
          <RecipeEditForm recipe={recipe} />
        </FormProvider>
      </RecipeProductsContextProvider>
    </RecipeFormWrapper>
  );
};
