import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import * as yup from "yup";

export async function fetchVisitTags(): Promise<ApiResponse<VisitTagGroup[]>> {
  const data = await fetchData(
    `/dietitian/schedules/visit/tags`,
    APIMethods.GET,
  );
  await visitTagsSchema.validate(data);
  return data;
}

const visitTagsSchema = yup.object().shape({
  data: yup.array().of(
    yup.object().shape({
      id: yup.number().required(),
      name: yup.string().required(),
      nameEn: yup.string().required(),
      type: yup
        .object()
        .shape({
          id: yup.number().required(),
          name: yup.string().required(),
        })
        .required(),
      tags: yup.array().of(
        yup.object().shape({
          id: yup.number().required(),
          clinic_id: yup.number().nullable(),
          tagCategoryId: yup.number().required(),
          descriptionPl: yup.string().nullable(),
          descriptionEn: yup.string().nullable(),
          namePl: yup.string().nullable(),
          nameEn: yup.string().nullable(),
        }),
      ),
    }),
  ),
});

interface VisitTagGroup {
  id: number;
  name: string;
  nameEn: string;
  type: {
    id: number;
    name: string;
  };
  tags: VisitTag[];
}

interface VisitTag {
  id: number;
  clinicId: number | null;
  tagCategoryId: number;
  descriptionPl: string | null;
  descriptionEn: string | null;
  namePl: string | null;
  nameEn: string | null;
}
