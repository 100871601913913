import { useMutation } from "@tanstack/react-query";

import {
  postDietAutoGenerateMeal,
  PostDietAutoGenerateMealRequest,
  GeneratedMealDto,
} from "@client/diets";
import { ApiResponse } from "@typeDefinitions";

export const usePostDietAutoGenerateMealMutation = () => {
  return useMutation<
    ApiResponse<GeneratedMealDto>,
    unknown,
    UsePostDietAutoGenerateMealMutationPayload
  >(({ dietId, payload }) => postDietAutoGenerateMeal(dietId, payload));
};

interface UsePostDietAutoGenerateMealMutationPayload {
  dietId: string;
  payload: PostDietAutoGenerateMealRequest;
}
