import { Divider, styled } from "@mui/material";

export const Title = styled("span")`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
  text-transform: uppercase;
`;

export const DividerStyled = styled(Divider)`
  border-color: ${({ theme }) => theme.colors.neutral.light[200]};
`;

export const PreparationText = styled("span")`
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
  white-space: pre-line;
`;

export const Dot = styled("div")`
  height: 10px;
  width: 10px;
  background: ${({ theme }) => theme.palette.primary.medium};
  border-radius: 100%;
  margin: 7px;
`;

export const ShoppingListElementStyled = styled("div")`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const NameText = styled("span")`
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const ValueText = styled("span")`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const TitleWrapper = styled("div")`
  display: grid;
  gap: 16px;
  width: 100%;
  text-align: center;
`;
