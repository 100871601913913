import {
  DialogSectionItemLabel,
  DialogSectionItemLayout,
} from "@views/dietician/ClientHealth/ClientHealthNew.styled";
import { Box, Stack, styled } from "@mui/material";
import { useTagsUtils } from "@hooks/useTagsUtils";
import { TagSystemIdCorrect } from "@utils/tagsNew";
import { useMemo } from "react";
import { FetchTagCategoriesTag } from "@client";
import { Controller, useFormContext } from "react-hook-form";
import { useAppTranslation } from "@hooks";
import { FormPops } from "@views/dietician/ClientHealth/ProfileTab/ProfileSection/Dialog/ProfileSectionForm";

const ProductExchangesField = () => {
  const { t, currentLanguage } = useAppTranslation();
  const form = useFormContext<FormPops>();
  const { getTagBySystemId, getTagName } = useTagsUtils();
  const productExchangers = useMemo(() => {
    return [
      getTagBySystemId(TagSystemIdCorrect.DIET_TYPE_GLUTEN_FREE),
      getTagBySystemId(TagSystemIdCorrect.DIET_TYPE_LACTOSE_FREE),
      getTagBySystemId(TagSystemIdCorrect.DIET_TYPE_VEGAN),
      getTagBySystemId(TagSystemIdCorrect.DIET_TYPE_VEGETARIAN),
    ].filter(tag => !!tag) as FetchTagCategoriesTag[];
  }, [getTagBySystemId]);

  return (
    <DialogSectionItemLayout>
      <DialogSectionItemLabel>
        {t(
          "patient.health_and_goal.dietary_profile_tab.dietary_profile_modal.exchanges",
        )}
      </DialogSectionItemLabel>
      <Controller
        control={form.control}
        render={({ field }) => {
          return (
            <Stack direction="row" gap="8px" flexWrap="wrap">
              <Item
                label={t(
                  "patient.health_and_goal.dietary_profile_tab.section_dietary_profile.regular",
                )}
                selected={field.value.length === 0}
                onClick={() => {
                  field.onChange([]);
                }}
              />
              {productExchangers.map(tag => {
                const handleOnClick = () => {
                  const newValue = field.value.includes(tag.id)
                    ? field.value.filter(tagId => tagId !== tag.id)
                    : [...field.value, tag.id];

                  field.onChange(newValue);
                };

                return (
                  <Item
                    key={tag.systemId}
                    label={getTagName(tag, currentLanguage)}
                    selected={field.value.includes(tag.id)}
                    onClick={handleOnClick}
                  />
                );
              })}
            </Stack>
          );
        }}
        name="productExchangersId"
      />
    </DialogSectionItemLayout>
  );
};

type ItemProps = {
  label?: string;
  selected: boolean;
  onClick: () => void;
};

const Item = ({ label, selected, onClick }: ItemProps) => {
  return (
    <ItemBox
      onClick={onClick}
      sx={{
        color: selected ? "#7448D0" : "#727272",
        background: selected ? "#F5F1FE" : "transparent",
        borderColor: selected ? "#7448D0" : "#F1F1F1",
      }}
    >
      {label}
    </ItemBox>
  );
};

const ItemBox = styled(Box)`
  border: 1px solid #f1f1f1;
  border-radius: 8px;
  font: var(--font-figtree-medium);
  font-size: 12px;
  line-height: 20px;
  color: #727272;
  background: transparent;
  padding: 8px;
  cursor: pointer;
` as typeof Box;

export default ProductExchangesField;
