import { useController } from "react-hook-form";
import { Button, ButtonGroup } from "@mui/material";
import { MEETING_TYPE } from "@utils/scheduleEvent";
import { Calendar, Clock, PaperNote } from "@assets/icons";
import { useAppTranslation } from "@hooks";

interface EventTypeCardsProps {
  disabled: boolean;
}

export const EventTypeCards = ({ disabled }: EventTypeCardsProps) => {
  const { t } = useAppTranslation();

  const {
    field: { value, onChange },
  } = useController({ name: "eventType" });

  const isConsultation = value === MEETING_TYPE.CONSULTATION;
  const isTodo = value === MEETING_TYPE.TODO;
  const isOther = value === MEETING_TYPE.OTHER;

  return (
    <ButtonGroup
      fullWidth
      variant="outlined"
      aria-label="outlined primary button group"
    >
      <Button
        disabled={disabled && !isConsultation}
        onClick={() => onChange(MEETING_TYPE.CONSULTATION)}
        startIcon={<Calendar />}
        variant={!isConsultation ? "outlined" : "contained"}
      >
        {t("visits.add.visit")}
      </Button>
      {/* Info: The button is commented out for now due to new create task modal */}
      {/* <Button
        disabled={disabled && !isTodo}
        onClick={() => onChange(MEETING_TYPE.TODO)}
        startIcon={<Clock />}
        variant={!isTodo ? "outlined" : "contained"}
      >
        {t("visits.add.to_do")}
      </Button> */}
      <Button
        disabled={disabled && !isOther}
        onClick={() => onChange(MEETING_TYPE.OTHER)}
        startIcon={<PaperNote />}
        variant={!isOther ? "outlined" : "contained"}
      >
        {t("visits.add.other")}
      </Button>
    </ButtonGroup>
  );
};
