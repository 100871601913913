import * as yup from "yup";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import { NutrientDto, nutrientSchema } from "./common";

export const fetchSimpleProgramDiet = async (
  programId: string,
  dayId: string,
): Promise<ApiResponse<FetchSimpleProgramDietResponse>> => {
  const response = await fetchData(
    `/dietitian/programs/${programId}/days/${dayId}/diet/simply`,
    APIMethods.GET,
  );
  return await fetchSimpleProgramDietResponseSchema.validate(response);
};

export interface FetchSimpleProgramDietResponse {
  id: string;
  title: string;
  titleEn: string;
  nutrients: NutrientDto[];
  meals: {
    id: string;
    title: string;
    titleEn: string;
    nutrients: NutrientDto[];
  }[];
}

const fetchSimpleProgramDietResponseSchema = yup.object().shape({
  data: yup
    .object()
    .shape({
      id: yup.string().required(),
      title: yup.string().ensure(),
      titleEn: yup.string().ensure(),
      nutrients: yup.array().of(nutrientSchema).default([]),
      meals: yup
        .array()
        .of(
          yup.object().shape({
            id: yup.string().required(),
            title: yup.string().ensure(),
            titleEn: yup.string().ensure(),
            nutrients: yup.array().of(nutrientSchema).default([]),
          }),
        )
        .default([]),
    })
    .required(),
});
