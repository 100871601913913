import { useMemo } from "react";

import { Autocomplete, TextField } from "@mui/material";

import {
  FieldValues,
  Path,
  useController,
  useFormContext,
} from "react-hook-form";
import { OptionIf } from "../FiltersBuilder";
import { Label, Wrapper } from "./AutocompleteFilter.styled";

export interface SingleAutocompleteFilterProps<T extends FieldValues> {
  label: string;
  options: OptionIf[];
  placeholder: string;
  name?: Path<T>;
}

export const SingleAutocompleteFilter = <T extends FieldValues>({
  label,
  options,
  placeholder,
  name,
}: SingleAutocompleteFilterProps<T>) => {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ control, name: name ?? "tags" });

  const selected = value as string;

  const selectedOption = useMemo(
    () => options.find(o => o.id === selected),
    [options, selected],
  );

  return (
    <Wrapper>
      <Label>{label}</Label>
      <Autocomplete
        key={selectedOption?.id}
        size="small"
        options={options}
        value={selectedOption}
        onChange={(e, value) => onChange(value?.id)}
        renderInput={params => (
          <TextField {...params} placeholder={placeholder} />
        )}
        ChipProps={{
          color: "primary",
        }}
      />
    </Wrapper>
  );
};
