import { styled } from "@mui/material";

interface LabelSpaceProps {
  labelSpace?: boolean;
}
export const IconWrapper = styled("div")<LabelSpaceProps>`
  padding: ${({ labelSpace }) => (labelSpace ? "30px 6px 6px" : "6px")};
`;
export const ButtonWrapper = styled("div")<LabelSpaceProps>`
  padding-top: ${({ labelSpace }) => (labelSpace ? "24px" : "")};
`;

export const RowStyled = styled("div")`
  display: flex;
  align-items: start;
  gap: 8px;
`;

export const RowMobile = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: ${({ theme }) => theme.palette.primary.light};
  padding: 12px;
  border-radius: 8px;
`;
export const TabsMobile = styled("div")`
  display: flex;
  align-items: baseline;
  gap: 8px;
`;
