import { Box } from "@mui/material";
import {
  Close,
  Shortcut,
  EmptyCalendar,
  Person,
  Location,
  Dolar,
} from "@assets/icons";
import { useAppTranslation } from "@hooks";
import { ScheduleVisitDropdown } from "./SchduleVisitDropdown";
import { FC } from "react";
import {
  VisitOverviewButton,
  VisitOverviewButtonText,
  VisitOverViewContent,
  VisitOverViewHeader,
  VisitOverviewIconButton,
  VisitOverviewItem,
  VisitOverviewItemLabel,
  VisitOverviewItemText,
  VisitOverviewNavigationIconButton,
  VisitOverviewStatusChip,
  VisitOverviewWrapper,
  StyledMenu,
  HeaderTitle,
  VisitOverviewItemContent,
} from "./ScheduleVisitOverviewModal.styled";
import { format } from "date-fns";
import { useFetchScheduleEvent } from "@hooks/queries";
import { useNavigate } from "react-router-dom";
import { EVENT_STATUS, MEETING_TYPE } from "@utils";

interface ScheduleVisitOverviewModalProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  eventId: number;
}

export const ScheduleVisitOverviewModal: FC<
  ScheduleVisitOverviewModalProps
> = ({ anchorEl, onClose, eventId }) => {
  const { t, isPolishChosen } = useAppTranslation();
  const navigate = useNavigate();

  const { scheduleEvent } = useFetchScheduleEvent(eventId);

  if (!scheduleEvent) return null;

  const {
    patient,
    title,
    clinicProduct,
    price,
    clinicFacility,
    dietitian,
    additionalInfo,
    status,
    meetingType,
  } = scheduleEvent;

  const isVisit = meetingType.id === MEETING_TYPE.CONSULTATION;

  const patientFullName =
    patient?.firstName || patient?.lastName
      ? `${patient?.firstName ?? ""} ${patient?.lastName ?? ""}`.trim()
      : "-";

  const product = clinicProduct?.translations.find(
    ({ lang }) => (isPolishChosen ? "pl" : "en") === lang,
  );

  const productAndPrice = `${product?.name ?? "-"}, ${
    price ? price.value + " zł" : "-"
  } `;

  const dateAndTime = format(
    new Date(scheduleEvent.dateStart),
    "dd.MM.yyyy, HH:mm",
  );

  const dietitianFullName = `${dietitian?.firstName} ${dietitian?.lastName}`;

  const handleNavigateToAppointments = () => {
    if (patient?.id) {
      navigate(`/patients/${patient.id}/appointments#${eventId}`);
      onClose();
    }
  };

  const unconfirmedMessage = isVisit
    ? t("visits.unconfirmed")
    : t("calendar.event_unconfirmed");

  const confirmedMessage = isVisit
    ? t("visits.confirmed")
    : t("calendar.confirmed");

  const getScheduleStatus = () => {
    switch (status.id) {
      case EVENT_STATUS.CONFIRMED:
        return confirmedMessage;
      case EVENT_STATUS.UNCONFIRMED:
        return unconfirmedMessage;
      default:
        return unconfirmedMessage;
    }
  };

  const isVisitConfirmed = status.id === EVENT_STATUS.CONFIRMED;

  const isOutdated = new Date(scheduleEvent.dateEnd) < new Date();

  return (
    <StyledMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
      <VisitOverViewContent>
        <VisitOverViewHeader>
          <HeaderTitle>{title}</HeaderTitle>
          <Box display="flex" alignItems="start">
            <ScheduleVisitDropdown
              eventId={eventId}
              isConfirmed={isVisitConfirmed}
              onClose={onClose}
              isVisit={isVisit}
              isOutdated={isOutdated}
            />

            <VisitOverviewNavigationIconButton onClick={onClose}>
              <Close />
            </VisitOverviewNavigationIconButton>
          </Box>
        </VisitOverViewHeader>
        <VisitOverviewWrapper>
          {isVisit && (
            <VisitOverviewStatusChip
              approved={isVisitConfirmed ? "true" : "false"}
              label={getScheduleStatus()}
            />
          )}
          <VisitOverviewItem>
            <VisitOverviewItemLabel>
              {t("calendar.client")}
            </VisitOverviewItemLabel>
            <VisitOverviewItemContent>
              <Person className="text-xl text-gray-450" />
              <VisitOverviewItemText>{patientFullName}</VisitOverviewItemText>
            </VisitOverviewItemContent>
          </VisitOverviewItem>
          {isVisit && (
            <VisitOverviewItem>
              <VisitOverviewItemLabel>
                {t("calendar.productAndPrice")}
              </VisitOverviewItemLabel>
              <VisitOverviewItemContent>
                <Dolar className="mr-[2px] text-gray-450" />
                <VisitOverviewItemText>{productAndPrice}</VisitOverviewItemText>
              </VisitOverviewItemContent>
            </VisitOverviewItem>
          )}
          <VisitOverviewItem>
            <VisitOverviewItemLabel>
              {t("calendar.deadlineAndTime")}
            </VisitOverviewItemLabel>
            <VisitOverviewItemContent>
              <EmptyCalendar className="text-gray-450 mx-[1px]" />
              <VisitOverviewItemText>{dateAndTime}</VisitOverviewItemText>
            </VisitOverviewItemContent>
          </VisitOverviewItem>
          <VisitOverviewItem>
            <VisitOverviewItemLabel>
              {t("calendar.dietitian")}
            </VisitOverviewItemLabel>
            <VisitOverviewItemContent>
              <Person className="text-xl text-gray-450" />
              <VisitOverviewItemText>{dietitianFullName}</VisitOverviewItemText>
            </VisitOverviewItemContent>
          </VisitOverviewItem>
          {isVisit && (
            <VisitOverviewItem>
              <VisitOverviewItemLabel>
                {t("calendar.location")}
              </VisitOverviewItemLabel>
              <VisitOverviewItemContent>
                <Location className="text-gray-450 text-xl" />
                <VisitOverviewItemText>
                  {clinicFacility?.address ?? "-"}
                </VisitOverviewItemText>
              </VisitOverviewItemContent>
            </VisitOverviewItem>
          )}
          <VisitOverviewItem>
            <VisitOverviewItemLabel>
              {t("calendar.additionalInfo")}
            </VisitOverviewItemLabel>
            <VisitOverviewItemText>
              {additionalInfo ?? "-"}
            </VisitOverviewItemText>
          </VisitOverviewItem>
          {isVisit && (
            <VisitOverviewButton onClick={handleNavigateToAppointments}>
              <VisitOverviewIconButton size="small">
                <Shortcut />
              </VisitOverviewIconButton>
              <VisitOverviewButtonText>
                {t("calendar.goToVisit")}
              </VisitOverviewButtonText>
            </VisitOverviewButton>
          )}
        </VisitOverviewWrapper>
      </VisitOverViewContent>
    </StyledMenu>
  );
};
