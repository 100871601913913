import { useMediaQuery, useTheme } from "@mui/material";

import { Nutrient } from "@typeDefinitions";
import { ENERGY_ID } from "@utils";
import { CARBS_ID, FATS_ID, PROTEIN_ID } from "@utils/macros";

import { Chart } from "../Chart";
import { DividerStyled } from "./TabPanels.styled";

export interface NutrientWithTarget extends Nutrient {
  target: number;
  visible: boolean;
}

interface MacrosTabPanelProps {
  nutrients: Map<number, NutrientWithTarget>;
}

export const MacrosTabPanel = ({ nutrients }: MacrosTabPanelProps) => {
  const { breakpoints } = useTheme();
  const upMedium = useMediaQuery(`${breakpoints.up("medium")}`);

  if (upMedium)
    return (
      <div className="flex gap-[40px]">
        <Chart
          id={ENERGY_ID}
          target={nutrients.get(ENERGY_ID)?.target}
          value={nutrients.get(ENERGY_ID)?.value}
        />

        <DividerStyled orientation="vertical" />

        <Chart
          id={PROTEIN_ID}
          target={nutrients.get(PROTEIN_ID)?.target}
          value={nutrients.get(PROTEIN_ID)?.value}
        />

        <DividerStyled orientation="vertical" />

        <Chart
          id={FATS_ID}
          target={nutrients.get(FATS_ID)?.target}
          value={nutrients.get(FATS_ID)?.value}
        />

        <DividerStyled orientation="vertical" />

        <Chart
          id={CARBS_ID}
          target={nutrients.get(CARBS_ID)?.target}
          value={nutrients.get(CARBS_ID)?.value}
        />
      </div>
    );

  return (
    <div className="grid gap-[12px]">
      <div className="flex gap-[40px]">
        <Chart
          id={ENERGY_ID}
          target={nutrients.get(ENERGY_ID)?.target}
          value={nutrients.get(ENERGY_ID)?.value}
        />

        <DividerStyled orientation="vertical" />

        <Chart
          id={PROTEIN_ID}
          target={nutrients.get(PROTEIN_ID)?.target}
          value={nutrients.get(PROTEIN_ID)?.value}
        />
      </div>
      <div className="flex gap-[40px]">
        <Chart
          id={FATS_ID}
          target={nutrients.get(FATS_ID)?.target}
          value={nutrients.get(FATS_ID)?.value}
        />

        <DividerStyled orientation="vertical" />

        <Chart
          id={CARBS_ID}
          target={nutrients.get(CARBS_ID)?.target}
          value={nutrients.get(CARBS_ID)?.value}
        />
      </div>
    </div>
  );
};
