import { Close, Pencil } from "@assets/icons";
import { IconButton, Tooltip } from "@mui/material";
import { useProgramSearchPreviewDrawer } from "@components/ProgramSearchPreview/Context/ProgramSearchPreviewDrawerContext";
import { useAppTranslation } from "@hooks";

const CloseDrawerAction = () => {
  const { t } = useAppTranslation();
  const { onClose } = useProgramSearchPreviewDrawer();

  return (
    <Tooltip title={t("patient.program_preview.menu_actions.close")} arrow>
      <IconButton onClick={() => onClose()}>
        <Close
          size=""
          fill="#727272"
          style={{ width: "32px", height: "32px" }}
        />
      </IconButton>
    </Tooltip>
  );
};

export default CloseDrawerAction;
