import { useCallback, useEffect, useState } from "react";

import {
  mapCreateClientRequest,
  useAppTranslation,
  useCreateClientForm,
  useModal,
  useUpdateClientMobileAppAccess,
  useUpdateClientPayments,
} from "@hooks";

import { CreateClientResponse } from "@client";
import { TAKEN_EMAIL_ERROR } from "@utils/clients";
import {
  Dialog,
  Divider,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

import {
  AccordionContent,
  AccordionSummaryStyled,
  AddClientModalWrapper,
  CancelButton,
  CloseButton,
  PhoneRowWrapper,
  SectionTitle,
  SexButtonsWrapper,
  StyledAccordion,
  StyledAlert,
  StyledDialogActions,
  StyledFormCountryAutocomplete,
  Title,
  TitleWrapper,
} from "./AddClientModal.styled";
import { useNavigate } from "react-router-dom";
import { useCreateClientMutation } from "@hooks/queries";
import { CheckedIcon, Close, DollarSign, PaperPlane } from "@assets/icons";
import { LoadingButton } from "@mui/lab";
import { ClientProfileCardItem } from "@views/dietician/ClientProfile/components/ClientProfileCardItem";
import { FormTextField } from "@components/FormTextField";
import { FormSelect } from "@components/FormSelectNew";
import { StyledTextArea } from "@components/EditClientModal";
import { SexSelector } from "@components/SexSelectorNew";
import { FormCheckboxMui } from "@components/FormCheckboxMui";
import { PATIENTS } from "@routes";
import { useFetchClinicDietitiansSimpleListQuery } from "@hooks/queries/settings";
import { phoneInputFactory } from "@utils/inputs";
import { SendQuestionnaireModal } from "@views/dietician/Questionnaires/SendQuestionnaireModal";
import { getSubscriptionInfo } from "@utils/subscription";

interface AddClientModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess?: (data?: CreateClientResponse) => void;
  transitionDuration?: number | Record<string, number>;
}

export const AddClientModal = ({
  open,
  onClose,
  onSuccess,
  transitionDuration,
}: AddClientModalProps) => {
  const [addedNewClient, setAddedNewClient] = useState<{
    client: CreateClientResponse;
    openSurveyModal: boolean;
    redirect: boolean;
  } | null>(null);
  const [canFocus, setCanFocus] = useState(true);
  const [isEmailExist, setIsEmailExist] = useState(false);
  const { dietitians } = useFetchClinicDietitiansSimpleListQuery();

  const { t } = useAppTranslation();
  const { isTrial } = getSubscriptionInfo();
  const { colors } = useTheme();

  const { mutate, isLoading } = useCreateClientMutation();
  const {
    mutate: mutateMobileAppAccess,
    isLoading: isLoadingUpdateClientMobileAppAccess,
  } = useUpdateClientMobileAppAccess();
  const { modalOpened, onModalClose, onModalOpen } = useModal();
  const {
    mutate: mutateClientActivation,
    isLoading: isLoadingClientActivation,
  } = useUpdateClientPayments();
  const navigate = useNavigate();

  const {
    control,
    setValue,
    watch,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useCreateClientForm();

  const onCloseWithReset = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  const questionnaireOnModalClose = () => {
    setAddedNewClient(null);
    onModalClose();
  };

  const isActivated = watch("activation");
  const sendQuestionnaire = watch("questionnaire");
  const sendEmail = watch("sendEmail");
  const email = watch("email");
  const hasQuestionnaireRequirements =
    sendQuestionnaire && isActivated && isEmailExist;
  const hasEmailRequirements = sendEmail && isActivated && isEmailExist;
  const hasShown =
    ((!isActivated || isActivated) && !isEmailExist) ||
    (isActivated && isEmailExist);

  const languages = [
    { id: "pl", label: t("patients.add.personal_info.language.pl") },
    { id: "en", label: t("patients.add.personal_info.language.en") },
  ];

  useEffect(() => {
    if (email.trim() === "") {
      setIsEmailExist(false);
      return;
    }
    setIsEmailExist(true);
  }, [email]);

  useEffect(() => {
    if (
      addedNewClient === null ||
      isLoading ||
      isLoadingUpdateClientMobileAppAccess ||
      isLoadingClientActivation
    ) {
      return;
    }

    onSuccess && onSuccess(addedNewClient.client);
    onCloseWithReset();

    if (addedNewClient.openSurveyModal) {
      onModalOpen();
      return;
    }

    if (addedNewClient.redirect) {
      navigate(`${PATIENTS}/${addedNewClient.client.id}/info-and-services`);
      return;
    }
    setAddedNewClient(null);
  }, [
    addedNewClient,
    isLoading,
    isLoadingUpdateClientMobileAppAccess,
    isLoadingClientActivation,
  ]);

  const handleCreateClient = () => {
    handleSubmit(
      data => {
        mutate(mapCreateClientRequest(data), {
          onSuccess: successData => {
            setAddedNewClient({
              client: successData,
              openSurveyModal: data.questionnaire,
              redirect: data.activation,
            });
            if (!data.activation) {
              return;
            }
            mutateClientActivation(
              {
                id: successData.id.toString(),
                payload: {
                  activeUntil: null,
                  isActive: true,
                },
              },
              {
                onSuccess: () => {
                  if (data.sendEmail) {
                    mutateMobileAppAccess({
                      id: successData.id.toString(),
                      payload: { hasAccess: true },
                    });
                  }
                },
              },
            );
          },
          onError: err => {
            if (err.response?.data.errors.email?.includes(TAKEN_EMAIL_ERROR))
              setError("email", { message: t("common.email_duplicate") ?? "" });
            setCanFocus(true);
          },
        });
      },
      e => console.log("e", e),
    )();
  };

  useEffect(() => {
    if (errors && canFocus) {
      const elements = Object.keys(errors)
        .map(name => document.getElementsByName(name)[0])
        .filter(el => !!el);
      elements.sort(
        (a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top,
      );

      if (elements.length > 0) {
        const errorElement = elements[0];
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" }); // scrollIntoView options are not supported in Safari
        errorElement.focus({ preventScroll: true });
        setCanFocus(false);
      }
    }
  }, [errors, canFocus]);

  useEffect(() => {
    if (!isActivated) {
      setValue("questionnaire", false);
      setValue("sendEmail", false);
    }
  }, [isActivated, isEmailExist]);

  if (modalOpened)
    return (
      <SendQuestionnaireModal
        onClose={questionnaireOnModalClose}
        open={modalOpened}
        newClientId={addedNewClient?.client.id.toString()}
      />
    );

  return (
    <Dialog
      open={open}
      transitionDuration={transitionDuration}
      disableEscapeKeyDown
    >
      <form>
        <AddClientModalWrapper>
          <TitleWrapper>
            <Title>{t("client_add.add_client")}</Title>
            <CloseButton size="small" onClick={onCloseWithReset}>
              <Close size="w-3 h-3" />
            </CloseButton>
          </TitleWrapper>

          <SectionTitle>{t("client_add.client_information")}</SectionTitle>
          <div className="grid gap-4">
            <ClientProfileCardItem
              title={t("common.first_name")}
              extraEl={
                <FormTextField
                  control={control}
                  name="firstName"
                  size="small"
                  variant="outlined"
                />
              }
            />
            <ClientProfileCardItem
              title={t("common.last_name")}
              extraEl={
                <FormTextField
                  control={control}
                  name="lastName"
                  size="small"
                  variant="outlined"
                />
              }
            />
            <ClientProfileCardItem
              title={t("client_add.email")}
              extraEl={
                <FormTextField
                  control={control}
                  name="email"
                  size="small"
                  variant="outlined"
                />
              }
            />

            {dietitians && dietitians?.length > 1 && (
              <ClientProfileCardItem
                title={t("client_add.lead_dietitian")}
                extraEl={
                  <FormSelect
                    control={control}
                    name="dietitian"
                    id="dietitian"
                    size="small"
                  >
                    {dietitians?.map(param => (
                      <MenuItem value={param.id} key={param.name}>
                        {param.name}
                      </MenuItem>
                    ))}
                  </FormSelect>
                }
              />
            )}
            <ClientProfileCardItem
              title={t("client_add.gender")}
              extraEl={
                <SexButtonsWrapper>
                  <SexSelector control={control} name="sex" />
                </SexButtonsWrapper>
              }
            />
            <StyledAccordion disableGutters>
              <AccordionSummaryStyled expandIcon={<CheckedIcon />}>
                {t("client_add.additional_information")}
              </AccordionSummaryStyled>

              <AccordionContent>
                <PhoneRowWrapper>
                  <ClientProfileCardItem
                    title={t("common.prefix")}
                    extraEl={
                      <StyledFormCountryAutocomplete
                        control={control}
                        name="phonePrefix"
                        id="phonePrefix"
                        size="small"
                      />
                    }
                  />
                  <ClientProfileCardItem
                    title={t("common.phone_number")}
                    extraEl={
                      <FormTextField
                        control={control}
                        name="phoneNumber"
                        size="small"
                        variant="outlined"
                        InputProps={{
                          inputComponent: phoneInputFactory() as any,
                        }}
                      />
                    }
                  />
                </PhoneRowWrapper>

                <ClientProfileCardItem
                  title={t("client_add.lang")}
                  extraEl={
                    <FormSelect
                      control={control}
                      name="lang"
                      id="lang"
                      size="small"
                    >
                      {languages.map(param => (
                        <MenuItem value={param.id} key={param.label}>
                          {param.label}
                        </MenuItem>
                      ))}
                    </FormSelect>
                  }
                />

                <div className="grid gap-3">
                  <Typography
                    variant="body2"
                    color={colors.neutral.medium[800]}
                  >
                    {t("client_add.notes_and_comments")}
                  </Typography>
                  <StyledTextArea
                    placeholder={t("client_add.type_notes")}
                    control={control}
                    name="description"
                    multiline
                    rows={5}
                    size="small"
                  />
                </div>
              </AccordionContent>
            </StyledAccordion>

            <Divider />

            <SectionTitle>
              {t("client_add.activation_and_services")}
            </SectionTitle>
            {!isEmailExist && (
              <StyledAlert variant="filled">
                {t("client_add.fill_email")}
              </StyledAlert>
            )}
            <div className="grid gap-1">
              <FormCheckboxMui
                rebranding
                control={control}
                label={t("client_add.activate_client")}
                name="activation"
              />
              {isActivated && !isTrial && (
                <StyledAlert
                  variant="filled"
                  icon={<DollarSign size="w-3.5 h-3.5" />}
                >
                  {t("client_add.activated_client")}
                </StyledAlert>
              )}
            </div>
            <Tooltip
              title={t("client_add.check_activate_client")}
              arrow
              placement="top"
              disableInteractive={hasShown}
              disableHoverListener={hasShown}
              disableFocusListener={hasShown}
              disableTouchListener={hasShown}
            >
              <div className="grid gap-4">
                <FormCheckboxMui
                  rebranding
                  control={control}
                  label={t("client_add.send_email")}
                  name="sendEmail"
                  disabled={!isEmailExist || !isActivated}
                  checked={hasEmailRequirements}
                />
                <div className="grid gap-1">
                  <FormCheckboxMui
                    rebranding
                    control={control}
                    label={t("client_add.send_questionnaire")}
                    name="questionnaire"
                    disabled={!isEmailExist || !isActivated}
                    checked={hasQuestionnaireRequirements}
                  />
                  {hasQuestionnaireRequirements && (
                    <StyledAlert
                      variant="filled"
                      icon={<PaperPlane size="w-3.5 h-3.5" />}
                    >
                      {t("client_add.questionnaires_checked")}
                    </StyledAlert>
                  )}
                </div>
              </div>
            </Tooltip>
          </div>
        </AddClientModalWrapper>
        <StyledDialogActions>
          <CancelButton variant="outlined" onClick={onCloseWithReset}>
            {t("common.cancel")}
          </CancelButton>
          <LoadingButton
            variant="contained"
            loading={isLoading}
            onClick={handleCreateClient}
          >
            {t("client_add.save")}
          </LoadingButton>
        </StyledDialogActions>
      </form>
    </Dialog>
  );
};
