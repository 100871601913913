import { Button, styled } from "@mui/material";

export const HeaderStyled = styled("span")`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.medium[600]};
`;

export const AlignedButton = styled(Button)`
  align-self: flex-start;
`;
