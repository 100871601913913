import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const HandWithHeart = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="12" viewBox="0 0 14 12">
      <path
        d="M4.29934 3.33678C3.65952 2.68825 3.65952 1.63493 4.29934 0.986398C4.93917 0.337867 5.97508 0.337867 6.6149 0.986398L7.0001 1.37813L7.38531 0.986398C8.02513 0.337867 9.06104 0.337867 9.70087 0.986398C10.3407 1.63493 10.3407 2.68825 9.70087 3.33678L7.48977 5.5827C7.35484 5.7198 7.17856 5.78727 7.0001 5.78727C6.82165 5.78727 6.64537 5.7198 6.51044 5.5827L4.29934 3.33678ZM13.098 7.67628C13.3831 8.06366 13.3004 8.60773 12.9131 8.89282L10.1579 10.9233C9.64863 11.2976 9.03492 11.5 8.40163 11.5H4.91088H1.42883C1.04363 11.5 0.732422 11.1888 0.732422 10.8036V9.41077C0.732422 9.02557 1.04363 8.71436 1.42883 8.71436H2.2297L3.20685 7.9309C3.70087 7.53482 4.31458 7.32155 4.94787 7.32155H6.6519H7.0001H8.39292C8.77812 7.32155 9.08933 7.63275 9.08933 8.01795C9.08933 8.40316 8.77812 8.71436 8.39292 8.71436H7.0001H6.6519C6.46039 8.71436 6.30369 8.87106 6.30369 9.06257C6.30369 9.25408 6.46039 9.41077 6.6519 9.41077H9.27649L11.8815 7.49129C12.2689 7.2062 12.8129 7.2889 13.098 7.67628ZM4.9457 8.71436H4.92611C4.93264 8.71436 4.93917 8.71436 4.9457 8.71436Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
