import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import * as yup from "yup";
import { LangDto } from "./common";
import { translationSchema } from "./program";

export const fetchClientFoodGroupPreferences = async (
  patientId: string,
): Promise<ApiResponse<FetchClientFoodGroupPreferencesResponse[]>> => {
  const response = await fetchData(
    `/dietitian/patients/${patientId}/food-group-preferences`,
    APIMethods.GET,
  );

  return await fetchClientFoodGroupPreferencesSchema.validate(response);
};

const foodGroupSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  translations: yup.array().of(translationSchema).required(),
});

const clientFoodGroupPreferencesSchema = yup.object().shape({
  foodGroup: foodGroupSchema,
  isLiked: yup.boolean().required(),
});

const fetchClientFoodGroupPreferencesSchema = yup.object().shape({
  data: yup.array().of(clientFoodGroupPreferencesSchema).required(),
});
interface TranslationDto {
  lang: LangDto;
  name: string;
}

interface FoodGroupDto {
  id: number;
  name: string;
  translations: TranslationDto[];
}

export interface FetchClientFoodGroupPreferencesResponse {
  foodGroup: FoodGroupDto;
  isLiked: boolean;
}
