import { useAppParams, useAppTranslation } from "@hooks";
import { Nutrient } from "@typeDefinitions";
import { Card } from "@components/Card";
import { Button } from "@components/Button";
import { Link } from "@components/Link";

import { ProgramDaySummary } from "./components/ProgramDaySummary";

interface ProgramSummaryProps {
  programId: number;
  programDays: {
    id: number;
    name: string;
    frequency: number[];
    diet: {
      id: number;
      title?: string;
      titleEn?: string;
      mealsCount: number;
      nutrients: Nutrient[];
    } | null;
  }[];
}

export const ProgramSummary = (props: ProgramSummaryProps) => {
  const { programId, programDays } = props;

  const { patientId } = useAppParams();
  const { t } = useAppTranslation();

  const filteredProgramDays = programDays.filter(day => day.frequency.length);

  return (
    <Card
      header={
        filteredProgramDays.length > 1
          ? t("patient.schedule.rotation_diet")
          : t("patient.schedule.simple_diet")
      }
      headerSize="medium"
      action={
        filteredProgramDays?.length === 1 && (
          <div className="flex gap-4">
            <Button
              className="step-six"
              color="secondary"
              size="large"
              to={
                patientId
                  ? `/patients/${patientId}/nutritional-programs/${programId}/days/${filteredProgramDays[0].id}/diet/${filteredProgramDays[0].diet?.id}/edit/recipes`
                  : `/programs/${programId}/days/${filteredProgramDays[0].id}/diet/${filteredProgramDays[0].diet?.id}/edit/recipes`
              }
            >
              {t("patient.schedule.edit_meals")}
            </Button>
          </div>
        )
      }
      className="mb-7"
    >
      <div>
        {filteredProgramDays.map(({ id, diet, name, frequency }, i) => (
          <ProgramDaySummary
            indexOnList={i}
            key={id}
            programId={programId}
            programDayId={id}
            dayName={name}
            dietId={diet?.id ?? 0}
            dietName={diet?.title || ""}
            frequency={frequency}
            useInRowEditButton={filteredProgramDays?.length > 1}
          />
        ))}
        {!filteredProgramDays.length && (
          <h3 className="text-2xl w-full text-center">
            {t("patient.schedule.no_diet") + " "}
            <Link
              to={`/patients/${patientId}/nutritional-programs/${programId}/diets`}
            >
              {t("patient.schedule.no_diet_2")}
            </Link>
          </h3>
        )}
      </div>
    </Card>
  );
};
