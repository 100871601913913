import { FetchFoodDetailsResponse } from "@client";
import { useFetchProductQuery } from "@hooks/queries/food";
import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";
import { ProductDetails } from "@views/dietician/product-views/components/ProductDetails/ProductDetails";

export const ProductDetailsQueryWrapper = ({
  productId,
}: ProductDetailsProps) => {
  const { product, isLoading, isError } = useFetchProductQuery(productId);

  const parseProduct = (product: FetchFoodDetailsResponse) => {
    return {
      ...product,
      values: product.nutrients,
    };
  };

  if (isLoading) return <Spinner className="h-52" />;

  if (isError || !product || isNaN(productId)) return <ApiError />;

  return <ProductDetails product={parseProduct(product)} />;
};

interface ProductDetailsProps {
  productId: number;
}
