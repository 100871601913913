import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useDebounce } from "react-use";

import { Autocomplete, styled, TextField } from "@mui/material";

import { ModalWrapper } from "@components/ModalWrapperNew";
import { OuterLabel } from "@components/OuterLabel";
import { useFetchClientsQuery } from "@hooks/queries";
import { usePostPatientSendSurveyMutation } from "@hooks/queries/surveys";
import { useAppTranslation } from "@hooks";

interface SendSelectedQuestionnaireModalProps {
  open: boolean;
  onClose: () => void;
  id: number;
  name: string;
}

interface ClientOption {
  id: number;
  label: string;
}
export const SendSelectedQuestionnaireModal = ({
  open,
  onClose,
  name,
  id,
}: SendSelectedQuestionnaireModalProps) => {
  const { t } = useAppTranslation();
  const [query, setQuery] = useState("");
  const [selectedClient, setSelectedClient] = useState<
    ClientOption | undefined
  >(undefined);
  const { clients, isLoading, submit } = useFetchClientsQuery({ active: 1 });
  const { mutate } = usePostPatientSendSurveyMutation();

  const mappedClient: ClientOption[] = useMemo(
    () =>
      clients?.map(({ id, lastName, firstName }) => ({
        id: id,
        label: [firstName, lastName].join(" "),
      })) ?? [],
    [clients],
  );
  const onSearch = (search: string) => setQuery(search);

  const handleReset = () => {
    setQuery("");
    setSelectedClient(undefined);
  };

  const handleSubmit = () => {
    if (!selectedClient) return;
    mutate(
      { patientId: selectedClient.id.toString(), surveyId: id },
      {
        onSuccess: () => {
          handleReset();
          toast.success(t("questionnaires.send_modal.success_toast"));
          onClose();
        },
      },
    );
  };

  useDebounce(() => submit({ search: query, active: 1 }), 500, [query]);

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      title={t("questionnaires.send_modal.send")}
      confirmText={t("questionnaires.send_modal.send")}
      onSubmit={handleSubmit}
      disabled={!selectedClient}
    >
      <div className="flex flex-col gap-[16px]">
        <Name>{name}</Name>
        <OuterLabel label={t("questionnaires.send_modal.select_client")}>
          <Autocomplete
            disableClearable
            fullWidth
            size="small"
            options={mappedClient}
            value={selectedClient}
            placeholder={t("common.choose")}
            renderInput={params => (
              <TextField {...params} onChange={e => onSearch(e.target.value)} />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.label}
              </li>
            )}
            loading={isLoading}
            onBlur={() => !selectedClient && submit({ search: undefined })}
            onChange={(e, value) => setSelectedClient(value)}
          />
        </OuterLabel>
      </div>
    </ModalWrapper>
  );
};

const Name = styled("span")`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;
