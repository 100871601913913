import { styled } from "@mui/material";
import { FC, ReactNode } from "react";

type CircleProps = {
  color: string;
  children?: ReactNode;
  size: number;
};

export const Circle: FC<CircleProps> = styled("div")((props: CircleProps) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: props.size + "px",
  height: props.size + "px",
  borderRadius: props.size * 2 + "px",
  backgroundColor: props.color,
}));
