import { useQuery } from "@tanstack/react-query";

import { QueryOptions } from "./types";
import { buildDefaultQueryOptions } from "./common";
import { fetchClientTargets } from "@client";

const fetchClientTargetsQueryKey = "fetchClientTargetsQuery";

export const useFetchClientTargetsQuery = (options?: QueryOptions) => {
  const { data, ...rest } = useQuery(
    [fetchClientTargetsQueryKey],
    fetchClientTargets,
    buildDefaultQueryOptions(options),
  );

  return {
    targets: data?.data,
    ...rest,
  };
};
