import classNames from "classnames";
import { motion } from "framer-motion";
import { round } from "lodash";
import { useCallback } from "react";

import { useAppTranslation } from "@hooks";
import { VARIANTS } from "@utils/constants";
import { Macros, MACROS_ORDER } from "@utils/macros";
import { requestFoodNutrients } from "@client";
import { GlycaemicIndicators } from "../IngredientsTable";
import { MacrosList } from "@components/MacrosList";
import { MicronutrientPreview } from "@components/MicronutrientsModal";

export interface IngredientDetails {
  grams: number;
  food: Food;
  foodMeasureId: number;
}

interface Food {
  id: number;
  descriptionPl: string;
  description?: string | null;
  measures: Measure[];
  nutrients: Nutrient[];
}

interface Nutrient {
  id: number;
  value: number;
}

interface Measure {
  id: number;
  grams: number;
  description: string;
}

interface IngredientLineProps {
  index: number;
  ingredient: IngredientDetails;
  glycaemicIndicators?: GlycaemicIndicators;
}

export const IngredientLine = ({
  index,
  ingredient: {
    food: { descriptionPl, description, nutrients, measures, id },
    grams,
    foodMeasureId,
  },
}: IngredientLineProps) => {
  const { isPolishChosen } = useAppTranslation();

  const sortedMacros = nutrients
    .filter(n => MACROS_ORDER[n.id as Macros] !== undefined)
    .sort(
      (a, b) => MACROS_ORDER[a.id as Macros] - MACROS_ORDER[b.id as Macros],
    );

  const query = useCallback(() => requestFoodNutrients(id), []);

  const measure = measures.find(m => m.id === foodMeasureId) ?? measures[0];

  return (
    <motion.div
      key={`ingredient-${index}`}
      custom={index}
      variants={VARIANTS}
      initial="hidden"
      animate="visible"
      className={classNames({
        "col-span-12 grid grid-cols-12 border-b border-r border-l border-gray-400":
          true,
        "border-t": index === 0,
      })}
    >
      <div className="col-span-5 p-4">
        <p>{isPolishChosen ? descriptionPl : description ?? descriptionPl}</p>
      </div>
      <div className="col-span-4 grid grid-cols-12 gap-4 p-4 border-l border-gray-400">
        <p className="col-span-3 text-center">{grams}</p>
        <p className="col-span-3 text-center">
          {round(grams / (measure.grams !== 0 ? measure.grams : 1), 1) ?? "-"}
        </p>
        <p className="col-span-6 text-center">{measure.description || "-"}</p>
      </div>
      <div className="col-span-3 flex justify-between gap-1 border-l border-gray-400 text-sm px-2 items-center">
        <MacrosList nutrients={sortedMacros} compact />
        <div className="flex-none">
          <MicronutrientPreview query={query} factor={grams} />
        </div>
      </div>
    </motion.div>
  );
};
