import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const fetchSystemNotifications = async (): Promise<
  ApiResponse<SystemNotificationDto[]>
> => {
  const response = await fetchData(
    "/dietitian/system-notifications",
    APIMethods.GET,
  );
  return await fetchSystemNotificationResponseSchema.validate(response);
};

const clientSchema = yup.object().shape({
  id: yup.number().required(),
  text: yup.string().required(),
  textEn: yup.string().required(),
  startedAt: yup.string().required(),
  endedAt: yup.string().required(),
});

const fetchSystemNotificationResponseSchema = yup.object().shape({
  data: yup.array().of(clientSchema).required(),
});

export interface SystemNotificationDto {
  id: number;
  text: string;
  textEn: string;
  startedAt: string;
  endedAt: string;
}
