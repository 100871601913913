import React, { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ContainerWrapper } from "@views/public/components";
import { CloseRound } from "@assets/icons";
import { useAppTranslation } from "@hooks";
import { LOGIN } from "@routes";
import { useResendActivationLinkRequestMutation } from "@hooks/queries";
import { Button } from "@components/Button";

import { TextWrapper } from "./components/TextWrapper.styled";

export const LinkResend = () => {
  const { t } = useAppTranslation();
  const { search } = useLocation();
  const navigate = useNavigate();

  const { mutate } = useResendActivationLinkRequestMutation();

  const email = useMemo(
    () => new URLSearchParams(search).get("email"),
    [search],
  );

  useEffect(() => {
    if (!email) navigate(LOGIN, { replace: true });
  }, [email]);

  const handleLinkResend = () => {
    if (email) mutate({ email });
  };

  return (
    <ContainerWrapper className="px-12">
      <CloseRound size="w-14 h-auto" className="mb-7" />

      <TextWrapper>
        <p>{t("auth.activation.resend.title")}</p>
        <p className="mb-6">{t("auth.activation.resend.info")}</p>
        <p>{t("auth.activation.resend.button_prompt")}</p>
      </TextWrapper>

      <Button color="primary" onClick={handleLinkResend} className="mb-6">
        {t("auth.activation.resend.button")}
      </Button>

      <Button color="background" to={LOGIN} className="text-primary">
        {t("auth.activation.resend.back")}
      </Button>
    </ContainerWrapper>
  );
};
