import { useQuery } from "@tanstack/react-query";

import { fetchClientTags, FetchClientTagsResponse } from "@client";
import { ApiResponse } from "@typeDefinitions";

import { QueryOptionsTyped } from "./types";

export const fetchClientTagsQueryKey = "fetchClientTagsQueryKey";

export const useFetchClientTags = (
  id: string,
  options?: QueryOptionsTyped<ApiResponse<FetchClientTagsResponse[]>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchClientTagsQueryKey, id],
    () => fetchClientTags(id),
    options,
  );

  return {
    clientTags: data?.data,
    ...rest,
  };
};
