import { ArrowDown } from "@assets/icons/Arrows";
import { Card, IconButton, Popover, Typography, styled } from "@mui/material";

export const CardStyled = styled(Card)`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 1.5rem;
  padding: 1.5rem;
  border-radius: 1rem;
  min-width: 13.75rem;
  box-shadow: ${({ theme }) => theme.boxShadows.card};
  background: ${({ theme }) => theme.gradients.surveySemiTransparent};
  backdrop-filter: blur(0.75rem);

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallMax}px) {
    max-width: 13.75rem;
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.medium}px) {
    width: 19.875rem;
  }
`;

export const SmallCardStyled = styled(Card)`
  display: grid;
  place-items: center;
  width: 2.5rem;
  height: 2.5rem;
  box-shadow: ${({ theme }) => theme.boxShadows.card};
`;

export const HeaderText = styled("span")`
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const TitleStyled = styled(Typography)`
  font-weight: 600;
  line-height: 1.5rem;
  flex: 1;
`;

export const IntroductionTitle = styled(Typography)`
  font-weight: 600;
  line-height: 1.5rem;
  cursor: pointer;
`;

export const SubTitleStyled = styled(Typography)`
  font-weight: 500;
  line-height: 1.5rem;
`;

interface ListElementStyledProps {
  selected?: boolean;
}
export const ListElementStyled = styled("li")<ListElementStyledProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0;
  border-radius: 0.5rem;
  cursor: pointer;

  & .dragHandle-group {
    opacity: 0;
  }
  &:hover {
    & .dragHandle-group {
      opacity: 1;
    }
  }

  ${({ selected, theme }) =>
    selected
      ? `
    background: ${theme.palette.primary.medium};
    color: ${theme.palette.primary.main};
  `
      : `
    color: ${theme.colors.neutral.dark[800]};
  `}
`;
export const SubgroupStyled = styled("li")<ListElementStyledProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0 0.25rem 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;

  ${({ selected, theme }) =>
    selected
      ? `
    background: ${theme.palette.primary.medium};
    color: ${theme.palette.primary.main};
  `
      : `
    color: ${theme.colors.neutral.dark[800]};
  `}

  & .dragHandle-subgroup {
    opacity: 0;
  }
  &:hover {
    & .dragHandle-subgroup {
      opacity: 1;
    }
  }
`;

interface StyledArrowDownProps {
  expanded?: boolean;
}

export const StyledArrowDown = styled(ArrowDown, {
  shouldForwardProp: prop => prop !== "expanded",
})<StyledArrowDownProps>`
  margin-left: 0.25rem;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  rotate: ${({ expanded }) => (expanded ? "360deg" : "270deg")};
`;

export const CloseIconButton = styled(IconButton)`
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;

  & svg {
    height: 0.75rem;
    width: 0.75rem;
  }
`;

export const HandleWrapper = styled("div")`
  display: flex;
  align-items: center;
  height: 1.5rem;
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: grab;
  padding-right: 0.5rem;
`;

export const SubgroupName = styled("span")`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
`;
