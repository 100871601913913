import { AxiosError } from "axios";
import { useMutation } from "@tanstack/react-query";

import {
  registerClinic,
  RegisterClinicRequest,
  RegisterClinicResponse,
} from "@client";
import { ApiErrorIf, ApiResponse } from "@typeDefinitions";

import { MutationOptions } from "./types";

export const useRegisterClinicMutation = (
  options?: MutationOptions<
    ApiResponse<RegisterClinicResponse>,
    RegisterClinicRequest
  >,
) => {
  const { mutate, ...rest } = useMutation<
    ApiResponse<RegisterClinicResponse>,
    AxiosError<ApiErrorIf>,
    RegisterClinicRequest
  >(payload => registerClinic(payload), options);

  return {
    registerClinic: mutate,
    ...rest,
  };
};
