import { Typography } from "@mui/material";

import { Actions, Nutrient } from "@typeDefinitions";
import { MacrosListMui } from "@components/MacrosList";
import { DetailsActionButtons } from "@components/PreviewDetails/DetailsActionButtons";
import { TitleWrapper } from "@components/PreviewDetails/PreviewDetails.styled";

interface PreviewDetailsSectionProps {
  name: string;
  nutrients: Nutrient[];
  id: string;
  path: string;
  actions: Actions;
  onDelete?: (id: string) => void;
  editTooltip?: string;
  deleteTooltip?: string;
  isLoading?: boolean;
}

export const PreviewDetails = ({
  name,
  nutrients,
  path,
  id,
  actions,
  onDelete,
  editTooltip,
  deleteTooltip,
  isLoading,
}: PreviewDetailsSectionProps) => {
  return (
    <div className="flex justify-between">
      <TitleWrapper>
        <Typography variant="h5">{name}</Typography>
        <MacrosListMui nutrients={nutrients} />
      </TitleWrapper>

      <DetailsActionButtons
        id={id}
        path={path}
        canEdit={actions.canEdit}
        canDelete={actions.canDelete}
        canToggleVisibility={actions.canVisible}
        onDelete={onDelete}
        editTooltip={editTooltip}
        deleteTooltip={deleteTooltip}
        isLoading={isLoading}
      />
    </div>
  );
};
