import { Close } from "@assets/icons/DesignSystem";
import { useAppTranslation } from "@hooks";
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { Controller, ControllerProps } from "react-hook-form";

export type FormTextFieldClearableProps = Omit<ControllerProps<any>, "render"> &
  Omit<TextFieldProps, "onChange" | "error" | "helperText">;

export const FormTextFieldClearable = (props: FormTextFieldClearableProps) => {
  const { t } = useAppTranslation();

  return (
    <Controller
      {...props}
      render={({
        field: { onChange, value, onBlur, ref },
        fieldState: { error },
      }) => (
        <TextField
          ref={ref}
          {...props}
          InputProps={{
            endAdornment: (
              <div className="flex">
                {!!value && (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={() => onChange("")}>
                      <Close size="w-[12px] h-[12px]" />
                    </IconButton>
                  </InputAdornment>
                )}
                {props.InputProps?.endAdornment}
              </div>
            ),
          }}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          error={!!error}
          helperText={error?.message ? t(error.message) : undefined}
        />
      )}
    />
  );
};
