import { TextField } from "@mui/material";
import { useState } from "react";
import { Dayjs } from "dayjs";
import { useAppTranslation } from "@hooks";
import DataPickerWrapper from "@components/Alloweat/DataPicker/DataPickerWrapper";
import DataPicker from "@components/Alloweat/DataPicker/DataPicker";

type DatePickerProps = {
  value: Dayjs | null;
  setValue: (value: Dayjs | null) => void;
};
const ProgramStartDatePicker = ({ value, setValue }: DatePickerProps) => {
  const { t } = useAppTranslation();
  const [openDataPicker, setOpenDataPicker] = useState<boolean>(false);

  return (
    <DataPickerWrapper label={t("patient.programs_choice.dialog.date_start")}>
      <DataPicker
        views={["month", "day"]}
        value={value}
        disablePast
        open={openDataPicker}
        onClose={() => setOpenDataPicker(false)}
        onChange={newValue => {
          setValue(newValue);
        }}
        onOpen={() => setOpenDataPicker(true)}
        inputFormat="DD/MM/YYYY"
        InputAdornmentProps={{
          position: "start",
        }}
        renderInput={params => (
          <TextField {...params} onClick={() => setOpenDataPicker(true)} />
        )}
      />
    </DataPickerWrapper>
  );
};
export default ProgramStartDatePicker;
