import { array, number, object, string } from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import { logFetchValidateError } from "@utils/validate";

export const fetchProductShops = async (): Promise<
  ApiResponse<ProductShopDictionaryDto[]>
> => {
  const response = await fetchData(
    "/dietitian/dictionaries/product-shops",
    APIMethods.GET,
  );

  return responseSchema
    .validate(response)
    .catch(logFetchValidateError("/dietitian/dictionaries/product-shop"));
};

export interface ProductShopDictionaryDto {
  id: number;
  name: string;
}

const productShopDictionarySchema = object({
  id: number().required(),
  name: string().required(),
});

const responseSchema = object({
  data: array().of(productShopDictionarySchema).required(),
});
