import { Checkbox, IconButton, Radio } from "@mui/material";
import { MainText, Subtext, TextWrapper } from "./OtherOption.styled";
import { TrashNew } from "@assets/icons";
import { useWatch } from "react-hook-form";
import { OptionsWithOtherConfigFormInputs } from "../forms/useOptionsWithOtherConfigForm";
import { TextFieldStyled } from "./CheckboxOption.styled";
import { useAppTranslation } from "@hooks";

interface OtherOptionProps {
  onDelete: () => void;
}

export const OtherOption = ({ onDelete }: OtherOptionProps) => {
  const { t } = useAppTranslation();
  const value = useWatch<OptionsWithOtherConfigFormInputs>({
    name: "other.name",
  });
  return (
    <TextFieldStyled
      InputProps={{
        startAdornment: <Checkbox disabled />,
        endAdornment: (
          <IconButton onClick={onDelete} color="primary">
            <TrashNew />
          </IconButton>
        ),
        inputComponent: () => (
          <TextWrapper>
            <MainText>{value as string}</MainText>
            <Subtext>
              ({t("questionnaires.questions.radio.other_text")})
            </Subtext>
          </TextWrapper>
        ),
      }}
      size="small"
    />
  );
};
