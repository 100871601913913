import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const EyeMonitoring = (props: SvgIconProps) => (
  <SvgIcon width="19" height="14" viewBox="0 0 19 14" {...props}>
    <path
      d="M10 1.5C7.9375 1.5 6.28125 2.4375 5 3.625C3.78125 4.75 2.96875 6.0625 2.53125 7C2.96875 7.9375 3.78125 9.28125 5 10.4062C6.28125 11.5938 7.9375 12.5 10 12.5C12.0312 12.5 13.6875 11.5938 14.9687 10.4063C16.1875 9.28125 17.0312 7.9375 17.4375 7C17.0312 6.0625 16.1875 4.75 15 3.625C13.6875 2.4375 12.0312 1.5 10 1.5ZM3.96875 2.53125C5.4375 1.15625 7.46875 0 10 0C12.5 0 14.5312 1.15625 16 2.53125C17.4688 3.90625 18.4375 5.5 18.9062 6.625C19 6.875 19 7.15625 18.9062 7.40625C18.4375 8.5 17.4688 10.125 16 11.5C14.5312 12.875 12.5 14 10 14C7.46875 14 5.4375 12.875 3.96875 11.5C2.5 10.125 1.53125 8.5 1.0625 7.40625C0.96875 7.15625 0.96875 6.875 1.0625 6.625C1.53125 5.5 2.5 3.875 3.96875 2.53125ZM10 9.5C11.375 9.5 12.5 8.40625 12.5 7C12.5 5.625 11.375 4.5 10 4.5C9.96875 4.5 9.9375 4.5 9.9375 4.5C9.96875 4.6875 10 4.84375 10 5C10 6.125 9.09375 7 8 7C7.8125 7 7.65625 7 7.5 6.9375C7.5 6.96875 7.5 7 7.5 7C7.5 8.40625 8.59375 9.5 10 9.5ZM10 3C11.4062 3 12.7188 3.78125 13.4375 5C14.1562 6.25 14.1562 7.78125 13.4375 9C12.7187 10.25 11.4062 11 10 11C8.5625 11 7.25 10.25 6.53125 9C5.8125 7.78125 5.8125 6.25 6.53125 5C7.25 3.78125 8.5625 3 10 3Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
