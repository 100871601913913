import { ChangeEvent, memo, useEffect, useState } from "react";
import { useController, useFormContext } from "react-hook-form";

import { Alert } from "@mui/material";
import dayjs from "dayjs";

import { ChipsSelect } from "@components/ChipsSelect";
import { Labeled } from "@components/Labeled";
import { MuiTimeDatePicker } from "@components/MuiTimeDatePicker";
import {
  PatientProgramFormInput,
  useAppTranslation,
  useProgramLocalStorage,
} from "@hooks";

import { ThemeProviderWrapperNew } from "themeNew";
import {
  DurationCheckboxWrapperStyled,
  DurationSelectWrapperStyled,
} from "./PatientProgramDurationSelect.styled";
import {
  getDateNextYear,
  getLatestDate,
  isDateMoreThanOneYearLater,
} from "./dateUtils";
import { FormCheckbox } from "@components/FormCheckbox";

interface PatientProgramDurationSelectProps {
  edit?: boolean;
}

export const PatientProgramDurationSelect = memo(
  ({ edit = false }: PatientProgramDurationSelectProps) => {
    const { t } = useAppTranslation();
    const [prevFinishDate, setPrevFinishDate] = useState<Date | undefined>();
    const { control } = useFormContext<PatientProgramFormInput>();
    const {
      field: { onChange: onFinishDate, value: finishDateValue },
      fieldState: { isDirty: isFinishDateDirty },
    } = useController<PatientProgramFormInput, "finishDate">({
      name: "finishDate",
    });
    const {
      field: { value: IndefiniteValue, onChange: onIsIndefinite },
      fieldState: { isDirty: isIndefiniteDirty },
    } = useController<PatientProgramFormInput, "isIndefinite">({
      name: "isIndefinite",
    });
    const {
      field: { onChange: onChangeStartDate, value: startDate },
      fieldState: { isDirty: isStartDateDirty },
    } = useController<PatientProgramFormInput, "startDate">({
      name: "startDate",
    });
    const { markModified } = useProgramLocalStorage();

    const handleIsIndefiniteToggle = (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      if (!checked) {
        onFinishDate(
          prevFinishDate ?? dayjs().startOf("day").add(1, "month").toDate(),
        );
        onIsIndefinite(false);
        return;
      }
      setPrevFinishDate(finishDateValue ?? undefined);
      onFinishDate(null);
      onIsIndefinite(true);
    };

    const isDirty = isIndefiniteDirty || isFinishDateDirty || isStartDateDirty;

    const durationOptions = [
      {
        id: Durations.ONE,
        label: t("common.months", { count: Durations.ONE }),
      },
      {
        id: Durations.TWO,
        label: t("common.months", { count: Durations.TWO }),
      },
      {
        id: Durations.THREE,
        label: t("common.months", { count: Durations.THREE }),
      },
      {
        id: Durations.OTHER,
        label: t("addNutritionalProgram.step1.other_duration"),
      },
    ];
    const [duration, setDuration] = useState(Durations.OTHER);

    const handleSelectDuration = (id: number) => {
      setDuration(id);

      if (id === Durations.OTHER) return;

      if (startDate)
        return onFinishDate(dayjs(startDate).add(id, "months").toDate());

      onChangeStartDate(dayjs().toDate());
      onFinishDate(dayjs().add(id, "months").toDate());
    };

    useEffect(() => {
      if (edit && isDirty) {
        markModified();
      }
    }, [edit, isDirty]);

    useEffect(() => {
      const selectedDuration = dayjs(finishDateValue).diff(
        startDate,
        "month",
        true,
      );
      const durationsValues = Object.values(Durations).filter(
        value => typeof value === "number",
      );

      const matchingDuration =
        Number.isInteger(selectedDuration) &&
        durationsValues.includes(selectedDuration)
          ? selectedDuration
          : Durations.OTHER;

      setDuration(matchingDuration);
    }, []);

    return (
      <Labeled label={t("addNutritionalProgram.step1.specifyDurationTime")}>
        <div className="grid gap-[12px] pt-[8px]">
          <ThemeProviderWrapperNew>
            <ChipsSelect
              options={durationOptions}
              selectedId={duration}
              onChange={handleSelectDuration}
              size="medium"
              hideIcon
              borderRadius="8px"
            />
          </ThemeProviderWrapperNew>
          <div className="flex gap-6 items-baseline">
            <DurationSelectWrapperStyled>
              <div className="col-span-1 lg:col-span-1">
                <p className="text-sm pb-2">
                  {t("addNutritionalProgram.step1.programStart")}
                </p>
                <MuiTimeDatePicker
                  dateVersion
                  variant="standard"
                  name="startDate"
                  control={control}
                  disabled={IndefiniteValue}
                />
              </div>

              <div className="col-span-1">
                <p className="text-sm pb-2">
                  {" "}
                  {t("addNutritionalProgram.step1.programEnd")}
                </p>
                <MuiTimeDatePicker
                  dateVersion
                  variant="standard"
                  name="finishDate"
                  control={control}
                  disabled={IndefiniteValue}
                  maxDate={getDateNextYear(getLatestDate(startDate))}
                  controlledError={
                    isDateMoreThanOneYearLater(
                      finishDateValue,
                      getLatestDate(startDate),
                    )
                      ? t("addNutritionalProgram.step1.date_out_of_range")
                      : undefined
                  }
                  onChangeCallback={() => setDuration(4)}
                />
              </div>
              <DurationCheckboxWrapperStyled>
                <FormCheckbox
                  fullWidth
                  name="isIndefinite"
                  control={control}
                  className="pb-3"
                  onChange={handleIsIndefiniteToggle}
                  value={IndefiniteValue}
                  label={t("addNutritionalProgram.step1.indefiniteProgram")}
                />
              </DurationCheckboxWrapperStyled>
            </DurationSelectWrapperStyled>
          </div>
          {edit && isDirty && (
            <div className="w-1/2">
              <Alert severity="warning">
                {t("addNutritionalProgram.step1.dates_change_info")}
              </Alert>
            </div>
          )}
        </div>
      </Labeled>
    );
  },
);

enum Durations {
  ONE = 1,
  TWO = 2,
  THREE = 3,
  OTHER = 4,
}
