import { Link } from "react-router-dom";

import { Actions } from "@typeDefinitions";
import { FavoriteToggleButton } from "@components/FavoriteToggleButton";
import { DuplicateFiles, Pen } from "@assets/icons";
import { Close } from "@assets/icons/DesignSystem";

import {
  ButtonsWrapper,
  IconButtonStyled,
} from "./PreviewActionButtons.styled";

interface ActionsIf extends Omit<Actions, "canVisible"> {
  canVisible?: boolean | null;
}
interface PreviewActionButtonsProps {
  withImg?: boolean;
  onToggle: () => void;
  isLoading: boolean;
  isFavorite?: boolean;
  showFavorite?: boolean;
  actions?: ActionsIf;
  editLink: string;
  copyLink: string;
  onClose: () => void;
}

export const PreviewActionButtons = ({
  withImg,
  onToggle,
  onClose,
  isLoading,
  isFavorite,
  showFavorite,
  actions,
  editLink,
  copyLink,
}: PreviewActionButtonsProps) => {
  const canEdit = actions?.canEdit;

  if (withImg)
    return (
      <ButtonsWrapper>
        <div>
          {(showFavorite === undefined || showFavorite) && (
            <FavoriteToggleButton
              onChange={onToggle}
              isLoading={isLoading}
              isFavorite={!!isFavorite}
            />
          )}
        </div>

        <div className="flex gap-[8px]">
          {canEdit && (
            <Link to={editLink}>
              <IconButtonStyled>
                <Pen />
              </IconButtonStyled>
            </Link>
          )}
          <Link to={copyLink}>
            <IconButtonStyled>
              <DuplicateFiles />
            </IconButtonStyled>
          </Link>
          <IconButtonStyled onClick={onClose}>
            <Close />
          </IconButtonStyled>
        </div>
      </ButtonsWrapper>
    );

  return (
    <div className="flex gap-[8px]">
      <FavoriteToggleButton
        onChange={onToggle}
        isLoading={isLoading}
        isFavorite={!!isFavorite}
      />
      {canEdit && (
        <Link to={editLink}>
          <IconButtonStyled>
            <Pen />
          </IconButtonStyled>
        </Link>
      )}
      <Link to={copyLink}>
        <IconButtonStyled>
          <DuplicateFiles />
        </IconButtonStyled>
      </Link>
      <IconButtonStyled onClick={onClose}>
        <Close />
      </IconButtonStyled>
    </div>
  );
};
