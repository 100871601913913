import { styled } from "@mui/material";

export const InputsContainer = styled("div")`
  display: grid;
  grid-template-columns: repeat(9, minmax(0, 1fr));
  width: 100%;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 0.5rem;
  justify-items: center;
`;
