import * as yup from "yup";

import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";

export const cloneClientProgramToClient = async (
  patientId: number,
  programId: number,
  payload: CloneClientProgramToClientRequest,
): Promise<ApiResponse<CloneClientProgramToClientResponse>> => {
  const response = await fetchData(
    `/dietitian/patients/${patientId}/programs/${programId}/clone`,
    APIMethods.POST,
    payload,
  );
  await apiResponseSchema.validate(response);
  return response;
};

export interface CloneClientProgramToClientRequest {
  patientId: number;
  startDate: string;
}

export interface CloneClientProgramToClientResponse {
  id: number;
}

const cloneClientProgramToClientResponseSchema = yup.object().shape({
  id: yup.number().required(),
});

const apiResponseSchema = yup.object().shape({
  data: cloneClientProgramToClientResponseSchema.required(),
});
