import { useEffect, useMemo } from "react";
import { FormProvider, UseFormReturn, useController } from "react-hook-form";

import { IconButton, InputAdornment } from "@mui/material";
import { isNumber } from "lodash";

import { Close, Loupe } from "@assets/icons";
import { AutosaveWatchComponent } from "@components/AutosaveWatchComponent";
import { ProductsFiltersNew } from "@components/ProductsFiltersNew";
import { ProductsFiltersFormInput } from "@components/ProductsFiltersNew/useProductsFiltersForm";
import { ListTabs, useAppTranslation } from "@hooks";
import { useSearchFoodInfiniteQuery } from "@hooks/queries/useSearchFoodQuery";

import {
  ProductAccordion,
  ProductAccordionProps,
} from "../ElementsAccordions/ProductAccordion";
import { FormTextFieldStyled } from "./AddElementSidePanel.styled";
import { TabWrapper } from "./common.styled";
import { InfiniteList } from "./InfiniteList";
import { defaultFilters as productResetFilters } from "@components/ProductsFiltersNew/productsFiltersUtils";

interface ProductsTabProps {
  form: UseFormReturn<ProductsFiltersFormInput, any>;
  setTotal: (total: number | undefined) => void;
  defaultFilters?: ProductsFiltersFormInput;
  mealId: number;
}

export const ProductsTab = ({
  form,
  setTotal,
  defaultFilters,
  mealId,
}: ProductsTabProps) => {
  const { isPolishChosen, t } = useAppTranslation();
  const { products, submit, isFetching, meta, ...rest } =
    useSearchFoodInfiniteQuery(defaultFilters, { refetchOnMount: false });

  const resetValues = {
    ...productResetFilters,
    patient: defaultFilters?.patient,
    tabs: ListTabs.ALL,
  };

  const {
    field: { value, onChange },
  } = useController({ control: form.control, name: "query" });
  const handleClearQuery = () => onChange("");

  useEffect(() => {
    if (isNumber(meta?.total)) setTotal(meta?.total);
  }, [meta?.total]);

  const mappedProducts = useMemo<ProductAccordionProps[]>(
    () =>
      products.map(({ tags, category, image, ...rest }) => ({
        ...rest,
        tags: tags.map(t => t.id),
        categoryName:
          (isPolishChosen ? category?.description : category?.descriptionEn) ??
          "",
        imgUrl: image?.url,
        clientContext: "clientContext" in rest ? rest.clientContext : undefined,
        mealId,
      })) ?? [],
    [products, isPolishChosen, mealId],
  );

  const handleReset = () => {
    form.reset(resetValues);
    submit(resetValues);
  };

  return (
    <TabWrapper>
      <div className="flex justify-between">
        <div className="flex gap-[4px]">
          <FormProvider {...form}>
            <AutosaveWatchComponent isLoading={isFetching} onSubmit={submit} />
            <FormTextFieldStyled
              control={form.control}
              name="query"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Loupe />
                  </InputAdornment>
                ),
                endAdornment: value && (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={handleClearQuery}>
                      <Close size="w-[10px] h-[10px]" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder={t("common.search")}
            />
            <ProductsFiltersNew
              unshiftTabs
              total={meta?.total}
              resetValues={resetValues}
            />
          </FormProvider>
        </div>
      </div>

      <InfiniteList
        list={mappedProducts}
        Element={ProductAccordion}
        clearFilters={handleReset}
        {...rest}
      />
    </TabWrapper>
  );
};
