import { fetchData } from "@utils/api";
import { APIMethods, NoContentApiResponse } from "@typeDefinitions";

export const changeSharedProgram = async ({
  patientId,
  programId,
  payload,
}: {
  patientId: number;
  programId: number;
  payload: { shared: boolean };
}) => {
  return await fetchData<NoContentApiResponse>(
    `/dietitian/patients/{patient_id}/programs/{program_id}/shared`
      .replace("{patient_id}", patientId.toString())
      .replace("{program_id}", programId.toString()),
    APIMethods.POST,
    payload,
  );
};
