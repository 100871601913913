import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { logFetchValidateError } from "@utils/validate";
import * as yup from "yup";
import {
  BodyMeasurementMonitoringResource,
  bodyMeasurementMonitoringSchema,
} from "@client/resources/BodyMeasurementMonitoringResource";

export type FetchMonitoringMeasurementsFirstParams = {
  patientId: number;
};

export type FetchMonitoringMeasurementsFirstResponse =
  ApiResponse<BodyMeasurementMonitoringResource | null>;

export const fetchMonitoringMeasurementsFirst = async (
  params: FetchMonitoringMeasurementsFirstParams,
): Promise<FetchMonitoringMeasurementsFirstResponse> => {
  const response = await fetchData(
    `/dietitian/patients/{patient_id}/monitoring2/body-measurements/first`.replace(
      "{patient_id}",
      params.patientId.toString(),
    ),
    APIMethods.GET,
    undefined,
  );

  return await schema
    .validate(response)
    .catch(
      logFetchValidateError(
        "/dietitian/patients/{patient_id}/monitoring2/body-measurements/first",
      ),
    );
};

const schema = yup.object({
  data: bodyMeasurementMonitoringSchema.defined().nullable(),
});
