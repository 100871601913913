import { FC, useMemo } from "react";
import { SectionTitle } from "@views/dietician/ClientHealth";
import { ColorPicker } from "@components/ColorPicker";
import PdfTemplatePicker from "@Pdf/components/PdfTemplatePicker/PdfTemplatePicker";
import { useAppTranslation, useModalState } from "@hooks";
import { Controller, useFormContext } from "react-hook-form";
import { PdfExportPdfFormStateType } from "@Pdf/types/pdfTypes";
import usePdfColorsQuery from "@Pdf/hooks/query/usePdfColorsQuery";
import usePdfImagesQuery from "@Pdf/hooks/query/usePdfImagesQuery";
import { Plus } from "@assets/icons";
import { Button } from "@mui/material";
import DeletePdfColorButton from "@Pdf/components/Buttons/DeletePdfColorButton";
import ColorPickerModal from "@Pdf/components/ColorPickerModal/ColorPickerModal";

type PropsType = {
  enterprise: boolean;
};
const PdfTemplateTab: FC<PropsType> = ({ enterprise }) => {
  const form = useFormContext<PdfExportPdfFormStateType>();
  const { t } = useAppTranslation();
  const colorsQuery = usePdfColorsQuery();
  const imagesQuery = usePdfImagesQuery();
  const watchedValues = form.watch();

  const [openedModal, onOpenModal, onCloseModal] = useModalState();

  const colors = useMemo(
    () => (colorsQuery.data ?? []).map(item => item.color),
    [colorsQuery.data],
  );

  const colorId = useMemo(
    () =>
      (colorsQuery.data ?? []).find(
        ({ color, action }) => action.delete && color === watchedValues.color,
      )?.id,
    [watchedValues.color, colors],
  );

  const coverPages = useMemo(
    () =>
      (imagesQuery.data ?? []).map((item, i) => ({
        _id: item.id,
        id: item.url,
        name: `${t("pdf.cover")} ${i + 1}`,
        url: item.url,
        delete: item.action.delete,
      })),
    [imagesQuery],
  );

  const onSubmittedColor = (color: string) => {
    form.setValue("color", color);
  };

  return (
    <div className={"flex flex-col gap-[16px]"} style={{ width: "736px" }}>
      <SectionTitle className={"font-semibold text-neutral-dark-700"}>
        {t("pdf.template.choose_color")}
      </SectionTitle>
      <Controller
        name="color"
        control={form.control}
        render={({ field }) => (
          <ColorPicker
            {...field}
            colors={colors}
            outlined
            onChange={value => {
              form.setValue("color", value);
            }}
          />
        )}
      />
      {enterprise && (
        <div className={"flex gap-[8px]"}>
          <DeletePdfColorButton colorId={colorId} disabled={!colorId} />
          <Button
            style={{ width: "fit-content" }}
            variant="text"
            onClick={onOpenModal}
            startIcon={<Plus size="w-3 h-3" className="stroke-current" />}
          >
            {t("pdf.add_color")}
          </Button>
          <ColorPickerModal
            open={openedModal}
            onClose={onCloseModal}
            onSubmitted={onSubmittedColor}
          />
        </div>
      )}
      <SectionTitle className={"font-semibold text-neutral-dark-700"}>
        {t("pdf.template.choose_template")}
      </SectionTitle>
      <Controller
        name="coverPageType"
        control={form.control}
        render={({ field }) => (
          <PdfTemplatePicker
            {...field}
            templates={[
              {
                id: "photo",
                url: "/static/pdf/photo-cover.jpg",
                name: t("pdf.template.photo"),
                delete: false,
              },
              {
                id: "hand-drawn",
                url: "/static/pdf/hand-drawn-cover.jpg",
                name: t("pdf.template.handDrawn"),
                delete: false,
              },
              {
                id: "print",
                url: "/static/pdf/print-cover.jpg",
                name: t("pdf.template.print"),
                delete: false,
              },
            ]}
            onChange={value => {
              form.setValue("coverPageType", value);
            }}
          />
        )}
      />
      {watchedValues.coverPageType === "photo" && (
        <>
          <SectionTitle className={"font-semibold text-neutral-dark-700"}>
            {t("pdf.template.choose_photo")}
          </SectionTitle>
          <Controller
            name="coverPageId"
            control={form.control}
            render={({ field }) => (
              <PdfTemplatePicker
                {...field}
                addable={enterprise}
                templates={coverPages}
                onChange={value => {
                  form.setValue("coverPageId", value);
                }}
              />
            )}
          />
        </>
      )}
    </div>
  );
};

export default PdfTemplateTab;
