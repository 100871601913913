import {
  SystemQuestionDto,
  PregnantWomanAnswerResourceDto,
  PregnantWomanValue,
} from "@client/surveys";
import { OuterLabel } from "@components/OuterLabel";
import { useAppTranslation } from "@hooks";

import { QuestionPreview } from "../../QuestionPreview/QuestionPreview";
import { StyledAnswer } from "./SystemAnswerPregnantWoman.styled";
import { useMemo } from "react";

interface SystemAnswerPregnantWomanProps {
  question: SystemQuestionDto;
  answer?: PregnantWomanAnswerResourceDto;
  listIndex: number;
  totalQuestions: number;
}

export const SystemAnswerPregnantWoman = ({
  answer,
  question,
  listIndex,
  totalQuestions,
}: SystemAnswerPregnantWomanProps) => {
  const { title, description } = question;
  const { t } = useAppTranslation();

  const convertToRoman = (trimester?: number | null) => {
    if (!trimester) return "";
    if (trimester === 1) return "I";
    if (trimester === 2) return "II";
    if (trimester === 3) return "III";
    return "";
  };
  const pregnantOptions = useMemo(
    () => ({
      [PregnantWomanValue.LACK]: t(
        "questionnaires.questions.system_pregnant_woman.none",
      ),
      [PregnantWomanValue.PREGNANT]: `${t(
        "questionnaires.questions.system_pregnant_woman.pregnant",
      )}, ${convertToRoman(answer?.answer.trimester)} ${t(
        "questionnaires.questions.system_pregnant_woman.trimester",
      )}`,
      [PregnantWomanValue.LACTATION]: t(
        "questionnaires.questions.system_pregnant_woman.lactating",
      ),
    }),
    [t],
  );

  return (
    <QuestionPreview
      title={title}
      description={description}
      listIndex={listIndex}
      totalQuestions={totalQuestions}
      systemQuestion
    >
      <OuterLabel label={t("questionnaires.questions.answer")}>
        <StyledAnswer>
          {answer?.answer.value
            ? pregnantOptions[answer.answer.value]
            : t("questionnaires.questions.no_answer")}
        </StyledAnswer>
      </OuterLabel>
    </QuestionPreview>
  );
};
