import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { IconButton } from "@components/IconButton";
import { Close } from "@assets/icons";
import { useAppTranslation } from "@hooks";
import {
  ScheduleVisitModalActionType,
  useScheduleVisitModalContext,
} from "../ScheduleVisitModalContext";
import {
  ModalCancelButton,
  ModalDeleteActions,
  ModalDeleteButton,
} from "./ScheduleActionModal.styled";
import {
  useDeleteScheduleEvent,
  useFetchScheduleEvent,
  usePatchScheduleEvent,
} from "@hooks/queries";
import { EVENT_STATUS } from "@utils";
import { FormLabeledCheckboxMui } from "@components/FormCheckboxMui";
import { useScheduleActionModalForm } from "./ScheduleActionModal.hooks";
import { FormProvider } from "react-hook-form";
import { useWatch } from "react-hook-form";

export const ScheduleActionModal = () => {
  const { t } = useAppTranslation();
  const { isActionModalOpen, handleCloseActionModal, actionType, eventId } =
    useScheduleVisitModalContext();

  const form = useScheduleActionModalForm();

  const eventIdWithFallback = eventId ?? 0;

  const { mutateAsync: patchScheduleEvent } =
    usePatchScheduleEvent(eventIdWithFallback);

  const { mutateAsync: deleteScheduleEvent } = useDeleteScheduleEvent();

  const { scheduleEvent } = useFetchScheduleEvent(eventIdWithFallback, {
    enabled: !!eventId,
  });

  const notification = useWatch({
    control: form.control,
    name: "cancelConfirmation",
  });

  const handleCancelVisit = async () => {
    try {
      await patchScheduleEvent({ status: EVENT_STATUS.CANCELED, notification });
      handleCloseActionModal();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteVisit = async () => {
    await deleteScheduleEvent(eventIdWithFallback);
    handleCloseActionModal();
  };

  const getModalContent = (actionType: ScheduleVisitModalActionType | null) => {
    switch (actionType) {
      case ScheduleVisitModalActionType.DELETE_VISIT:
        return {
          title: t("calendar.delete_visit_action"),
          description: t("calendar.delete_visit"),
          action: t("calendar.delete_visit_action"),
          actionFn: handleDeleteVisit,
        };
      case ScheduleVisitModalActionType.CANCEL_VISIT:
        return {
          title: t("calendar.cancel_visit_action"),
          description: t("calendar.cancel_visit"),
          action: t("calendar.cancel_visit_action"),
          actionFn: handleCancelVisit,
        };
      case ScheduleVisitModalActionType.DELETE_EVENT:
        return {
          title: t("calendar.delete_event_action"),
          description: t("calendar.delete_event"),
          action: t("calendar.delete_event_action"),
          actionFn: handleDeleteVisit,
        };
      default:
        return {
          title: "",
          description: "",
          action: "",
          actionFn: () => ({}),
        };
    }
  };

  const { title, description, action, actionFn } = getModalContent(actionType);

  return (
    <Dialog
      open={isActionModalOpen}
      onClose={handleCloseActionModal}
      fullWidth
      className="max-w-2xl mx-auto"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        paddingRight="16px"
      >
        <DialogTitle id="task-delete-dialog-title">{title}</DialogTitle>
        <IconButton onClick={handleCloseActionModal}>
          <Close className="text-gray-450" />
        </IconButton>
      </Box>
      <DialogContent sx={{ padding: 0 }}>
        <Box paddingX="14px" paddingY={1}>
          <Typography variant="body1">
            <span>{description}</span>
            <span className="font-semibold ml-1">{scheduleEvent?.title}</span>
            <span>?</span>
          </Typography>
          {actionType === ScheduleVisitModalActionType.CANCEL_VISIT && (
            <FormProvider {...form}>
              <div className="mt-5 ml-[10px]">
                <FormLabeledCheckboxMui
                  label={t("calendar.cancelConfirmation")}
                  name="cancelConfirmation"
                />
              </div>
            </FormProvider>
          )}
        </Box>
        <ModalDeleteActions>
          <ModalCancelButton
            size="large"
            onClick={handleCloseActionModal}
            aria-label={t("tasks.taskDeleteModal.cancelButton")}
          >
            {t("tasks.taskDeleteModal.cancelButton")}
          </ModalCancelButton>
          <ModalDeleteButton onClick={actionFn} className="py-2 px-3 text-base">
            {action}
          </ModalDeleteButton>
        </ModalDeleteActions>
      </DialogContent>
    </Dialog>
  );
};
