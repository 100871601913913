import { useFormContext, useWatch } from "react-hook-form";

import {
  Alert,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { DayDto } from "@client/program";
import { useAppParams, useAppTranslation } from "@hooks";
import useFetchPatientImportantNutrients from "@hooks/queries/client/nutrient/useFetchPatientImportantNutrients";
import { macrosSortKey } from "@utils/macros";

import { OptimizerDayTableNutrient } from "./OptimizerDayTableNutrient";
import { StyledTableCell } from "./OptimizerSettingsModal.styled";

interface OptimizerDayTableProps {
  day: DayDto;
  namePrefix: string;
}

export const OptimizerDayTable = ({
  day,
  namePrefix,
}: OptimizerDayTableProps) => {
  const { t } = useAppTranslation();
  const { patientId } = useAppParams();
  const { setValue } = useFormContext();

  const { data: importants } = useFetchPatientImportantNutrients(
    { patientId: Number(patientId) },
    { enabled: !!patientId },
  );

  const dayTargetNutrients = day.targetNutrients.nutrients.filter(
    ({ visible }) => visible,
  );

  const patientImportantNutrients =
    importants?.data.filter(
      ({ id }) => !dayTargetNutrients.map(({ id }) => id).includes(id),
    ) ?? [];

  const visibleNutrients = [
    ...dayTargetNutrients,
    ...patientImportantNutrients,
  ].sort((a, b) => macrosSortKey(a.id, b.id));

  const onSelectAll = (checked: boolean) => {
    visibleNutrients.forEach(({ id }) => {
      setValue(`${namePrefix}.nutrients.${id}.used`, checked);
    });
  };

  const nutrientsWatch = useWatch({ name: `${namePrefix}.nutrients` }) as {
    [id: string]: { used: boolean; distribution: string };
  };

  const allSelected = Object.entries(nutrientsWatch ?? [])
    .map(([_, value]) => value.used)
    .reduce((a, b) => a && b, true);

  const distributionSum = Object.entries(nutrientsWatch ?? [])
    .map(([_, value]) => value.distribution)
    .reduce((a, b) => a + parseInt(b ?? 0), 0);

  const sumNotEqualHundred = distributionSum !== 100;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              checked={allSelected ?? false}
              onChange={(_, checked) => onSelectAll(checked)}
            />
          </TableCell>
          <TableCell />
          <TableCell align="left">
            {t("patient.schedule.optimization.values")}
          </TableCell>
          <TableCell align="left">
            {t("patient.schedule.optimization.distribution")}
          </TableCell>
          <TableCell align="left">
            {t("patient.schedule.optimization.tolerance")}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {visibleNutrients.map(({ id }, idx) => (
          <OptimizerDayTableNutrient
            key={idx}
            id={id}
            namePrefix={namePrefix}
          />
        ))}
        <TableRow>
          <StyledTableCell />
          <StyledTableCell />
          <StyledTableCell />
          <StyledTableCell hasError={sumNotEqualHundred} align="center">
            {`${t("common.sum")}: `}
            <Typography variant="button">
              {isNaN(distributionSum) ? "______" : `${distributionSum}%`}
            </Typography>
          </StyledTableCell>
        </TableRow>
        <StyledTableCell colSpan={5}>
          <Alert severity={sumNotEqualHundred ? "error" : "success"}>
            {sumNotEqualHundred
              ? t("patient.schedule.optimization.distribution_error_sum")
              : t("patient.schedule.optimization.distribution_success_sum")}
          </Alert>
        </StyledTableCell>
      </TableBody>
    </Table>
  );
};
