import { FoodGroupResourceDto, fetchFoodGroupsTree } from "@client";
import { useQuery } from "@tanstack/react-query";
import { QueryOptionsTyped } from "./types";
import { ApiResponse } from "@typeDefinitions";

export const fetchFoodGroupsTreeQueryKey = "fetchFoodGroupsTreeQueryKey";

export const useFetchFoodGroupsTreeQuery = (
  options?: QueryOptionsTyped<ApiResponse<FoodGroupResourceDto[]>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchFoodGroupsTreeQueryKey],
    fetchFoodGroupsTree,
    options,
  );

  return {
    data: data?.data,
    ...rest,
  };
};
