import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import classNames from "classnames";
import { Card } from "@components/Card";
import { Button } from "@components/Button";

export const FormCommitFooter = memo(
  ({ actionText, isLoading }: MealFormProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const navigateCallback = useCallback(() => navigate(-1), [navigate]);
    const ref = useRef<HTMLDivElement>(null);
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        const screenH = window.innerHeight;
        const bottomH = ref.current?.getBoundingClientRect().bottom ?? 0;
        setIsSticky(Math.abs(screenH - bottomH - 10) < 1);
      };

      handleScroll();

      window.addEventListener("scroll", handleScroll, true);
      window.addEventListener("resize", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll, true);
        window.removeEventListener("resize", handleScroll);
      };
    }, []);

    return (
      <div
        className={"self-center sticky flex justify-center"}
        style={{ bottom: "10px" }}
        ref={ref}
      >
        <Card
          noShadow={!isSticky}
          className={classNames({
            "bg-transparent": !isSticky,
          })}
          containerClassName={"flex justify-center font-semibold"}
        >
          <Button
            fontLight
            color="gray"
            size="large"
            className="mr-6"
            style={{ width: 200 }}
            onClick={navigateCallback}
          >
            {t("common.cancel")}
          </Button>
          <Button
            fontLight
            size="large"
            style={{ width: 200 }}
            disabled={isLoading}
            isLoading={isLoading}
            submit
          >
            {actionText}
          </Button>
        </Card>
      </div>
    );
  },
);

interface MealFormProps {
  actionText: string;
  isLoading: boolean;
}
