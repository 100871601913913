import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import * as yup from "yup";
import {
  ProgramOptimizationSettings,
  ProgramOptimizationSettingsResponse,
  programOptimizationSettingsSchema,
} from "./common";
import { validateAndFixNutrients } from "../utils/validateCatching";

export async function updateProgramOptimizationSettings(
  programId: number,
  payload: OptimizedProgramScheduleRequestBody,
): Promise<ApiResponse<ProgramOptimizationSettingsResponse[]>> {
  const data = await fetchData(
    `/dietitian/programs/${programId}/schedule/settings`,
    APIMethods.PUT,
    payload,
  );
  return await programOptimizationSettingsSchemaResponse.validate(data);
}

const programOptimizationSettingsSchemaResponse = yup.object().shape({
  data: yup.array().of(programOptimizationSettingsSchema).required(),
});

interface OptimizedProgramScheduleRequestBody {
  settings: ProgramOptimizationSettings[];
}
