import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "../types";
import { fetchClinicDietitiansQueryKey } from "./useFetchClinicDietitiansQuery";
import {
  UpdateClinicDietitianRequest,
  updateClinicDietitian,
} from "@client/settings";

export const useUpdateClinicDietitianMutation = (
  id: number,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, UpdateClinicDietitianRequest>(
    payload => updateClinicDietitian(id, payload),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries([fetchClinicDietitiansQueryKey]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};
