import * as yup from "yup";

import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { AddressDto, addressSchema, PhoneDto, phoneSchema } from "../common";

export const fetchClinicFacilities = async (): Promise<
  ApiResponse<FetchClinicFacilitiesResponse[]>
> => {
  const response = await fetchData(
    "/dietitian/clinic/facilities",
    APIMethods.GET,
  );
  await fetchClinicFacilitiesSchema.validate(response);

  return response;
};

export interface FetchClinicFacilitiesResponse {
  id: number;
  name?: string; //temp
  email: string;
  phone: PhoneDto;
  address: AddressDto;
  isMain: boolean;
}

const clinicFacilitySchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().nullable(), //temp
  email: yup.string().required(),
  phone: phoneSchema.required(),
  address: addressSchema.required(),
  isMain: yup.boolean().required(),
});

const fetchClinicFacilitiesSchema = yup.object().shape({
  data: yup.array().of(clinicFacilitySchema).required(),
});
