import { IconButton, Slider, styled } from "@mui/material";

export const IconButtonStyled = styled(IconButton)`
  &.MuiButtonBase-root {
    background: ${({ theme }) => theme.colors.neutral.light[100]};
    border: 1px solid ${({ theme }) => theme.palette.primary.medium};
    height: 40px;
    width: 40px;
  }
`;

export const ContentStyled = styled("div")`
  display: flex;
  width: 100%;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallMax}px) {
    gap: 24px;
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.medium}px) {
    gap: 8px;
  }
`;

export const SliderStyled = styled(Slider)`
  & .MuiSlider-valueLabel.MuiSlider-valueLabelOpen {
    transform: translateY(100%) scale(1);
    top: 0px;
    ::before {
      top: -8px;
      bottom: unset;
    }
  }
`;

export const InputWithSliderStyled = styled("div")`
  display: grid;
  width: 100%;
  margin-bottom: 16px;
`;
