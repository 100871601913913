import { Dialog, useTheme } from "@mui/material";

import { IInCricle } from "@assets/icons";
import { useAppTranslation } from "@hooks";

import { QuestionProps } from "./Question";
import {
  DialogContentStyled,
  ErrorAlert,
  QuestionDescription,
  QuestionNumberText,
  QuestionTitle,
  RequiredInfo,
} from "./Question.styled";

interface QuestionModalProps extends QuestionProps {
  open: boolean;
  onClose: () => void;
}

export const QuestionModal = ({
  listIndex,
  totalQuestions,
  required,
  description,
  title,
  children,
  hasError,
  readOnly,
  actionComponents,
  open,
  onClose,
}: QuestionModalProps) => {
  const {
    palette: {
      error: { main },
    },
  } = useTheme();
  const { t } = useAppTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContentStyled>
        <div className="flex justify-between">
          <div className="flex gap-1">
            <QuestionNumberText>
              {t("questionnaires.question")} {listIndex + 1}/{totalQuestions}
            </QuestionNumberText>

            {required && (
              <RequiredInfo>
                ({t("questionnaires.questions.required")})
              </RequiredInfo>
            )}
          </div>

          {actionComponents}
        </div>

        {!readOnly && hasError && (
          <div className="flex gap-1 items-end">
            <IInCricle fill={main} className="m-1" />
            <ErrorAlert>
              {t("questionnaires.questions.answer_required")}
            </ErrorAlert>
          </div>
        )}

        <QuestionTitle>{title}</QuestionTitle>

        {description && (
          <QuestionDescription>{description}</QuestionDescription>
        )}

        {children}
      </DialogContentStyled>
    </Dialog>
  );
};
