import { CSSProperties, FunctionComponent } from "react";

import {
  DASHBOARD,
  DIETS,
  MEALS,
  PATIENTS,
  PRODUCTS,
  PROGRAMS,
  RECIPES,
  SECTIONS,
} from "@routes";

import { KnowledgeBaseMenu } from "./KnowledgeBaseMenu";
import { ExclamationMarkButton } from "./ExclamationMarkButton";
import {
  Book,
  Carrot,
  FatNotebook,
  Folder,
  Food,
  Home,
  Meals,
  Referral,
  Users,
} from "@assets/icons/Menu";

export type MenuButtonType = {
  link: string | null;
  label: string;
  labelEn: string;
  primaryColor?: boolean;
  icon: FunctionComponent<any>;
  onClick?: () => void;
  style?: CSSProperties;
};

const triggerJira = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.showCollectorDialog && window.showCollectorDialog();
};

export const defaultPaths = [
  DASHBOARD,
  DIETS,
  MEALS,
  PATIENTS,
  PRODUCTS,
  PROGRAMS,
  RECIPES,
  SECTIONS,
];

export const menuButtons: Array<MenuButtonType | "separator"> = [
  {
    link: DASHBOARD,
    label: "Home",
    labelEn: "Home",
    icon: Home,
  },
  {
    link: PATIENTS,
    label: "Klienci",
    labelEn: "Clients",
    icon: Users,
  },
  {
    link: PRODUCTS,
    label: "Baza produktów",
    labelEn: "Products",
    icon: Carrot,
  },
  {
    link: RECIPES,
    label: "Baza przepisów",
    labelEn: "Recipes",
    icon: Food,
  },
  {
    link: MEALS,
    label: "Baza schematów posiłków",
    labelEn: "Meal schemes",
    icon: Meals,
  },
  {
    link: DIETS,
    label: "Baza diet",
    labelEn: "Diet plans",
    icon: Book,
  },
  {
    link: PROGRAMS,
    label: "Programy żywieniowe",
    labelEn: "Nutrition programs",
    icon: FatNotebook,
    primaryColor: true,
  },
  {
    link: SECTIONS,
    label: "Baza sekcji",
    labelEn: "Content base",
    icon: Folder,
  },
  "separator",
  {
    link: "./",
    label: "Referral",
    labelEn: "Referral",
    icon: Referral,
  },
  {
    link: null,
    label: "Baza wiedzy",
    labelEn: "Knowledge base",
    icon: () => <KnowledgeBaseMenu />,
  },
  {
    link: null,
    label: "Zgłoś błąd",
    labelEn: "Report a bug",
    icon: () => <ExclamationMarkButton />,
    onClick: triggerJira,
  },
];
