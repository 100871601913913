import { useFormContext } from "react-hook-form";

import { useAppTranslation } from "@hooks";

import { FormTextFieldWrapper } from "../BlurContext";
import { CooperationGoalsAnswerFormInputs, OTHER_ID } from "../forms";
import { Title } from "./OtherOption.styled";

interface OtherOptionProps {
  readOnly?: boolean;
}

export const OtherOption = ({ readOnly }: OtherOptionProps) => {
  const { t } = useAppTranslation();
  const { control, watch } = useFormContext<CooperationGoalsAnswerFormInputs>();
  const isOtherSelected = watch("id") === OTHER_ID;

  if (!isOtherSelected) return <></>;

  return (
    <div className="grid gap-4">
      <Title>
        {t("questionnaires.questions.system_cooperation_goals.what_goal")}
      </Title>

      <FormTextFieldWrapper
        control={control}
        name="other"
        size="small"
        placeholder={t(
          "questionnaires.questions.system_cooperation_goals.other_placeholder",
        )}
        FormHelperTextProps={{ hidden: true }}
        InputProps={{ readOnly }}
      />
    </div>
  );
};
