import { Dispatch, MouseEvent, SetStateAction } from "react";
import { Popover } from "@mui/material";
import {
  ContentWrapper,
  MenuButton,
  Title,
} from "./NotificationsItemActions.styled";
import { ConfirmationWrapper } from "@components/ConfirmationWrapper";
import { useAppTranslation } from "@hooks";
import {
  useDeleteAccountNotification,
  usePostNotificationAsUnread,
} from "@hooks/queries";

interface ClientsListActionModalProps {
  id: string;
  anchorEl?: HTMLElement | null;
  open: boolean;
  setAnchorEl: Dispatch<
    SetStateAction<HTMLDivElement | HTMLButtonElement | null>
  >;
  readAt: string | null;
}

export const NotificationsItemActions = ({
  open,
  anchorEl,
  setAnchorEl,
  id,
  readAt,
}: ClientsListActionModalProps) => {
  const { t } = useAppTranslation();

  const {
    mutate: deleteNotificationMutation,
    isLoading: isLoadingDeleteClient,
  } = useDeleteAccountNotification();

  const {
    mutate: markAsUnreadNotification,
    isLoading: isLoadingMarkAsUnreadNotification,
  } = usePostNotificationAsUnread(id);

  const markAsUnread = (e: MouseEvent<HTMLButtonElement>) => {
    if (readAt) {
      e.stopPropagation();
      e.preventDefault();
      markAsUnreadNotification();
      setAnchorEl(null);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRemove = () => {
    deleteNotificationMutation(id);
    setAnchorEl(null);
  };

  const disablePopoverClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClick={e => disablePopoverClick(e)}
    >
      <ContentWrapper>
        <div className="grid">
          <Title variant="body2"> {t("notifications.notification")}</Title>

          <MenuButton
            onClick={markAsUnread}
            disabled={isLoadingMarkAsUnreadNotification || !readAt}
          >
            {t("notifications.mark_as_unread")}
          </MenuButton>
          <ConfirmationWrapper
            onConfirm={handleRemove}
            confirmationText={t("notifications.confirmation_delete")}
            submitText={t("clients.actions.yes")}
          >
            <MenuButton
              loading={isLoadingDeleteClient}
              onClick={e => e.preventDefault()}
            >
              {t("notifications.delete")}
            </MenuButton>
          </ConfirmationWrapper>
        </div>
      </ContentWrapper>
    </Popover>
  );
};
