import { SyntheticEvent, useState } from "react";
import { useAppTranslation } from "@hooks";
import { VisitPastNotesType, VisitSegment } from "@typeDefinitions";
import parseNote from "html-react-parser";
import { PastNotesCardTitleWrapper } from "./components/TitleWrapper.styled";
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledTitle,
} from "./PastNotesCard.styled";

export interface PastNotesCardProps {
  segment: VisitSegment;
}

export const PastNotesCard = ({ segment }: PastNotesCardProps) => {
  const { t } = useAppTranslation();

  const [visibleCard, setVisibleCard] = useState<number | false>(0);

  const handleVisitVisibility =
    (index: number) => (event: SyntheticEvent, isVisible: boolean) => {
      setVisibleCard(isVisible ? index : false);
    };

  return (
    <div className="shadow shadow-lg">
      <PastNotesCardTitleWrapper>
        {t("patient.visits.pastNotes")}
      </PastNotesCardTitleWrapper>
      {segment.value.map((note: VisitPastNotesType, index: number) => {
        const { id, note: noteContent, dateOfVisit } = note;

        return (
          <StyledAccordion
            key={id}
            expanded={index === visibleCard}
            defaultExpanded={index === visibleCard}
            onChange={handleVisitVisibility(index)}
          >
            <StyledAccordionSummary aria-controls={`${index}`} id={`${index}`}>
              <StyledTitle>
                {`${t("patients.visit_no")} ${id} - ${dateOfVisit}`}
              </StyledTitle>
            </StyledAccordionSummary>

            <StyledAccordionDetails>
              {parseNote(noteContent)}
            </StyledAccordionDetails>
          </StyledAccordion>
        );
      })}
    </div>
  );
};
