import { Button, styled } from "@mui/material";

export const ButtonGroupWrapper = styled("div")`
  display: grid;
  gap: 0.5rem;
  align-items: center;
`;

export const WeekdayButtonStyled = styled(Button)`
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  padding: 0;
  min-width: 2rem;
  &.MuiButtonBase-root.MuiTab-root {
    text-align: end;
  }
`;

interface WeekdayIconProps {
  checked?: boolean;
}

export const WeekdayIcon = styled("div")<WeekdayIconProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  padding: 0;
  font-weight: 600;
  font-size: 0.5rem;
  background: ${({ theme, checked }) =>
    checked ? theme.palette.primary.main : "transparent"};
  border: ${({ theme, checked }) =>
    checked ? "unset" : `1px solid ${theme.palette.grey[400]}`};
  color: ${({ theme, checked }) =>
    checked ? theme.colors.whiteBackground : theme.palette.grey[400]};
  text-transform: none;
`;
