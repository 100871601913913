import { MouseEvent, ReactElement, useEffect, useState } from "react";
import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  CheckboxChecked,
  CheckboxUnChecked,
  Gear,
  Pen,
  Tag,
} from "@assets/icons";
import { useAppTranslation } from "@hooks";
import { CustomTag } from "@client/customTags/fetchCustomTags";
import { useFetchCustomTagsQuery } from "@hooks/queries/customTags/useFetchCustomTagsQuery";
import {
  EditButton,
  ManageTagsMenuItem,
  TriggerButton,
} from "@components/CustomTagsDropdown/CustomTagsDropdown.styled";
import { CustomTagsManageModal } from "@components/CustomTagsManageModal";
import { CustomTagEditModal } from "@components/CustomTagEditModal";

export interface CustomTagsDropdownProps {
  defaultSelectedTagsIds: number[];
  onClosed: (selectedTags: CustomTag[]) => void;
  triggerClassName?: string;
}

export const CustomTagsDropdown = ({
  defaultSelectedTagsIds,
  onClosed,
  triggerClassName,
}: CustomTagsDropdownProps): ReactElement => {
  const { t } = useAppTranslation();

  const [selectedTagsIds, setSelectedTagsIds] = useState<number[]>(
    defaultSelectedTagsIds,
  );

  const { tags } = useFetchCustomTagsQuery();

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [editTag, setEditTag] = useState<CustomTag | null>(null);

  const menuOpen = Boolean(menuAnchorEl);

  const isSelected = (tag: CustomTag) => {
    return selectedTagsIds.includes(tag.id);
  };

  const handleOpenMenuClick = (event: MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleTagClick = (tag: CustomTag) => {
    setSelectedTagsIds(
      isSelected(tag)
        ? [...selectedTagsIds].filter(id => id !== tag.id)
        : [...selectedTagsIds, tag.id],
    );
  };

  const handleAddTagClick = () => {
    setAddModalOpen(true);
  };

  const handleEditTagClick = (e: MouseEvent, tag: CustomTag) => {
    e.stopPropagation();
    setEditTag(tag);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    onClosed(tags.filter(isSelected));
  };

  const handleAddTagClose = () => {
    setAddModalOpen(false);
  };

  const handleEditTagClose = () => {
    setEditTag(null);
  };

  useEffect(() => {
    setSelectedTagsIds(defaultSelectedTagsIds);
  }, [defaultSelectedTagsIds]);

  return (
    <>
      <TriggerButton onClick={handleOpenMenuClick} className={triggerClassName}>
        <Tag />
      </TriggerButton>
      <Menu
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            style: {
              maxHeight: 550,
            },
          },
        }}
      >
        {tags.map((tag: CustomTag) => (
          <MenuItem
            key={`custom-tag-${tag.id}`}
            className="group"
            onClick={() => handleTagClick(tag)}
          >
            <ListItemIcon>
              <Checkbox
                icon={<CheckboxUnChecked />}
                checkedIcon={<CheckboxChecked />}
                checked={isSelected(tag)}
              />
            </ListItemIcon>
            <ListItemText>{tag.name}</ListItemText>
            {!tag.isSystem && (
              <EditButton
                disableRipple
                className="invisible group-hover:visible"
                onClick={e => handleEditTagClick(e, tag)}
              >
                <Pen />
              </EditButton>
            )}
          </MenuItem>
        ))}
        <ManageTagsMenuItem onClick={handleAddTagClick}>
          <ListItemIcon>
            <Gear className="w-[12px] h-[12px]" />
          </ListItemIcon>
          <ListItemText>{t("custom_tags.actions.manage")}</ListItemText>
        </ManageTagsMenuItem>
      </Menu>
      <CustomTagsManageModal open={addModalOpen} onClose={handleAddTagClose} />
      <CustomTagEditModal
        tag={editTag}
        open={!!editTag}
        onClose={handleEditTagClose}
      />
    </>
  );
};
