import { Table, TableBody, TableCell, TableRow, styled } from "@mui/material";

interface StyledTableCellProps {
  withBorder?: boolean;
  purple?: boolean;
  sticky?: boolean;
}

export const StyledTableCell = styled(TableCell, {
  shouldForwardProp: propName => propName !== "withBorder",
})<StyledTableCellProps>`
  padding: 0.5px 1rem;
  border-right: ${({ withBorder, theme }) =>
    withBorder ? `1px solid ${theme.palette.divider}` : "unset"};
`;

export const StyledTableRow = styled(TableRow)`
  & th {
    padding: 0.25rem 1rem;
  }
`;

export const TopHeaderTableCell = styled(TableCell)`
  padding: 0;
  border-bottom: unset;
  line-height: unset;
`;

export const StickyTopHeaderTableCell = styled(TableCell)`
  padding: 0;
  border-bottom: unset;
  line-height: unset;
  position: sticky;
  left: 70%;
`;

export const StyledTableBody = styled(TableBody)`
  & tr:last-child {
    background-color: ${({ theme }) => theme.colors.lightPurple};
  }
`;

export const MicrosTableCellTitle = styled(TableCell)`
  font-family: ${({ theme }) => theme.fontFamily.regular};
`;

export const MicrosTableCell = styled(TableCell)`
  font-size: 0.75rem;
  min-width: 4.25rem;
`;

export const MicrosValue = styled("span")`
  font-weight: 600;
`;

interface StyledTableProps {
  compact?: boolean;
}

export const StyledTable = styled(Table, {
  shouldForwardProp: prop => prop.toString() !== "compact",
})<StyledTableProps>`
  min-width: ${({ compact }) => !compact && "56rem"};
  border-collapse: separate;
`;

export const StickyTableCell = styled(TableCell, {
  shouldForwardProp: propName =>
    !["withBorder", "purple", "sticky"].includes(propName as string),
})<StyledTableCellProps>`
  ${({ sticky }) => sticky && "position: sticky"};
  left: 0;
  background-color: white;
`;

export const StickyProductCell = styled(TableCell, {
  shouldForwardProp: propName =>
    !["withBorder", "purple", "sticky"].includes(propName as string),
})<StyledTableCellProps>`
  ${({ sticky }) => sticky && "position: sticky"};
  left: 0;
  background-color: ${({ theme, purple }) =>
    purple ? theme.colors.solidLightPurple : theme.colors.whiteBackground};
  border-right: ${({ withBorder, theme }) =>
    withBorder ? `1px solid ${theme.palette.divider}` : "unset"};
  padding: 0.55rem 1rem 0.5px;
  width: 25rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.small}px) {
    width: 35rem;
  }
`;
