import { useMemo, useState } from "react";

import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";

import { RecipesSearchView, ProductsSearchView } from "./";
import { useAppParams, useAppTranslation } from "@hooks";
import { useFetchCreatorMealQuery } from "@hooks/queries";
import { ItemSelectWrapper } from "./SearchListElement.styled";

export const SearchItemsCard = () => {
  const [itemsSearched, setItemsSearched] = useState<"recipes" | "products">(
    "recipes",
  );
  const { t, isPolishChosen } = useAppTranslation();
  const { mealId } = useAppParams();
  const { meal } = useFetchCreatorMealQuery(mealId);

  const showRecipes = itemsSearched === "recipes";
  const showProducts = itemsSearched === "products";

  const actionComponent = useMemo(
    () => (
      <ItemSelectWrapper>
        <Typography variant="body2">{t("meal.search")}:</Typography>
        <ToggleButtonGroup
          value={itemsSearched}
          exclusive
          className="h-[40px]"
          onChange={(_, value) => value && setItemsSearched(value)}
        >
          <ToggleButton color="primary" value="recipes">
            {t("meal.recipes")}
          </ToggleButton>
          <ToggleButton color="primary" value="products">
            {t("meal.products")}
          </ToggleButton>
        </ToggleButtonGroup>
      </ItemSelectWrapper>
    ),
    [itemsSearched, setItemsSearched, isPolishChosen],
  );

  const selectedRecipes = useMemo(
    () =>
      meal?.recipes.map(r => (r.recipe.isPlaceholder ? "" : r.recipe.name)) ??
      [],
    [meal?.recipes],
  );
  const selectedProducts = useMemo(
    () => meal?.products.map(p => (p.isPlaceholder ? p.id : p.food.id)) ?? [],
    [meal?.products],
  );

  return (
    <div className="grid gap-2">
      <Typography variant="h6">{t("meal.add_recipes_products")}</Typography>

      <div className="grid">
        {showRecipes && (
          <RecipesSearchView
            actionComponent={actionComponent}
            disabledRecipes={selectedRecipes}
          />
        )}

        {showProducts && (
          <ProductsSearchView
            actionComponent={actionComponent}
            disabledProducts={selectedProducts}
          />
        )}
      </div>
    </div>
  );
};
