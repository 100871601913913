import { Outlet } from "react-router-dom";

import { ProgramEmptyDietProvider } from "@context";
import { ProgramNavigation } from "@layouts/ProgramLayout/components/ProgramNavigation";
import { LayoutWrapper } from "./ProgramLayout.styled";

export const ProgramLayout = () => {
  return (
    <LayoutWrapper>
      <ProgramNavigation />

      <ProgramEmptyDietProvider>
        <Outlet />
      </ProgramEmptyDietProvider>
    </LayoutWrapper>
  );
};
