import React, { useState, useRef } from "react";
import { toast } from "react-toastify";

import { VisitFile } from "@typeDefinitions";
import { Trash } from "@assets/icons";
import { useAppTranslation } from "@hooks";
import {
  useAddVisitFileMutation,
  useRemoveVisitFileMutation,
  useUploadVisitFileMutation,
} from "@hooks/queries/visits";
import { useTitleSubstring } from "@hooks/helpers";
import { Link } from "@components/Link";
import { IconButton } from "@components/IconButton";
import { Button } from "@components/Button";
import { Spinner } from "@components/Spinner";

import { FileUploaderWrapper } from "./components/FileUploaderWrapper.styled";

const MAX_FILE_SIZE = 5 * 1024 * 1024;
const AVAILABLE_MIME = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

interface VisitFileUploadProps {
  files?: VisitFile[];
  patientId: number;
  visitId: number;
}

export const VisitFileUpload = ({
  files: defaultFiles,
  visitId,
  patientId,
}: VisitFileUploadProps) => {
  const { t } = useAppTranslation();

  const { mutateAsync: saveFile, isLoading: isUploading } =
    useUploadVisitFileMutation(patientId);
  const { mutateAsync: removeFile } = useRemoveVisitFileMutation(
    patientId,
    visitId,
  );
  const { mutate } = useAddVisitFileMutation(patientId, visitId);

  const [files, setFiles] = useState<VisitFile[]>(defaultFiles || []);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleUploadFileButtonClick = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const target = event.target;
    if (target.files?.[0] && target.files[0]) {
      const file = target.files[0] as File;

      if (file.size > MAX_FILE_SIZE) {
        toast.error(t("common.file_upload.too_large"));
        target.value = "";
        return;
      }

      if (!AVAILABLE_MIME.includes(file.type)) {
        toast.error(t("common.file_upload.wrong_mime"));
        target.value = "";
        return;
      }

      const fileForm = new FormData();
      fileForm.append("file", file);

      try {
        const response = await saveFile(fileForm);
        if (response?.data) {
          setFiles(fs => [...fs, response?.data]);

          mutate({ fileId: response?.data?.id });
        }
      } catch (e) {
        return;
      }
    }
  };

  const handleFileRemove = (fileId: number) => async () => {
    await removeFile({ fileId });
    setFiles(files.filter(f => f.id !== fileId));
  };

  const { changedTitleFactory } = useTitleSubstring(5, 10, 20, 38, 58);

  return (
    <FileUploaderWrapper center={files?.length > 1}>
      <div className="flex flex-col">
        {files?.map((file: VisitFile) => (
          <div key={file.id} className="flex w-max items-center">
            <Link href={file.url} newPage className="text-sm text-primary">
              {changedTitleFactory(file.originalName)}
            </Link>
            <IconButton
              size="small"
              className="text-red text-xs"
              onClick={handleFileRemove(file.id)}
            >
              <Trash />
            </IconButton>
          </div>
        ))}
      </div>
      <div>
        <input
          type="file"
          ref={fileInputRef}
          accept={AVAILABLE_MIME.join(",")}
          disabled={isUploading}
          onChange={handleFileUpload}
          style={{ display: "none" }}
        />
        <Button
          icon={isUploading && <Spinner size="w-3 h-3" />}
          onClick={handleUploadFileButtonClick}
        >
          {t("common.file_upload.button")}
        </Button>
      </div>
    </FileUploaderWrapper>
  );
};
