import { Dispatch, SetStateAction, useContext, useEffect } from "react";

import { Close } from "@assets/icons/DesignSystem";
import {
  INTRODUCTION,
  TableOfContentsContext,
} from "@context/TableOfContentsContext";
import { useAppTranslation } from "@hooks";

import { GroupElementPopover } from "./GroupElementPopover";
import { GroupIf } from "@components/TableOfContents/TableOfContents";
import {
  CloseIconButton,
  HeaderText,
  IntroductionTitle,
  PopoverStyled,
} from "./TableOfContentsPopover.styled";

interface TableOfContentsPopoverProps {
  groups: GroupIf[];
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
}
export const TableOfContentsPopover = ({
  groups,
  anchorEl,
  setAnchorEl,
}: TableOfContentsPopoverProps) => {
  const { t } = useAppTranslation();
  const { selectedId, setSelectedId } = useContext(TableOfContentsContext);
  const onSelectIntroduction = () => {
    setSelectedId(INTRODUCTION);
  };

  useEffect(() => {
    if (selectedId.split("-").length > 1 || selectedId === INTRODUCTION)
      setAnchorEl(null);
  }, [selectedId]);

  return (
    <PopoverStyled open={!!anchorEl} anchorEl={anchorEl}>
      <HeaderText>
        {t("questionnaires.table_of_contents").toUpperCase()}
      </HeaderText>

      <CloseIconButton onClick={() => setAnchorEl(null)} color="primary">
        <Close />
      </CloseIconButton>

      <div className="flex flex-col gap-4">
        <IntroductionTitle
          onClick={onSelectIntroduction}
          color={selectedId === "introduction" ? "primary" : ""}
        >
          {t("questionnaires.introduction")}
        </IntroductionTitle>
        <div className="grid gap-4">
          {groups.map(({ id, name, subgroups }) => (
            <GroupElementPopover
              id={id.toString()}
              key={id}
              subgroups={subgroups}
              title={name}
            />
          ))}
        </div>
      </div>
    </PopoverStyled>
  );
};
