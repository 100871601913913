import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "../types";
import { fetchClinicFacilitiesQueryKey } from "./useFetchClinicFacilitiesQuery";
import { fetchClinicFacilityQueryKey } from "./useFetchClinicFacilityQuery";
import {
  UpdateClinicFacilityRequest,
  updateClinicFacility,
} from "@client/settings";

export const useUpdateClinicFacility = (
  id: number,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, UpdateClinicFacilityRequest>(
    payload => updateClinicFacility(id, payload),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries([fetchClinicFacilitiesQueryKey]);
        queryClient.invalidateQueries([fetchClinicFacilityQueryKey, id]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};
