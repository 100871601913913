import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  styled,
} from "@mui/material";

interface StyledAccordionProps {
  isMargin?: boolean;
}

export const StyledAccordion = styled(Accordion, {
  shouldForwardProp: propName => propName !== "isMargin",
})<StyledAccordionProps>`
  background: transparent;
  flex-direction: column-reverse;
  display: flex;

  & .MuiButtonBase-root.MuiAccordionSummary-root {
    width: 4rem;
    margin-left: auto;
  }
`;

export const StyledTypography = styled(Typography)`
  font-weight: 600;
  min-width: max-content;
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  &.MuiAccordionDetails-root {
    display: grid;
    gap: 1rem;
  }
`;

export const CountsWrapper = styled("div")`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const AccordionSummaryPlaceholder = styled(AccordionSummary)`
  display: none;
`;

export const ExpandButtonWrapper = styled("div")`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
