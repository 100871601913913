import {
  Alert,
  Autocomplete,
  OutlinedInput,
  Slider,
  styled,
  Typography,
} from "@mui/material";

export const StyledText = styled(Typography)`
  width: 15.625rem;
  min-width: 15.625rem;
  text-align: right;
  white-space: pre-line;
`;

export const FlexRow = styled("div")`
  display: flex;
  gap: 1.5rem;
  align-items: center;
`;

export const LabelWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
`;

export const SummaryBoxStyled = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 0.75rem;
  background-color: ${({ theme }) => theme.colors.lightPurple};
`;

export const CaloricNeedsFormWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 0.75rem;
`;

export const InputsWrapper = styled("div")`
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
`;

export const StyledSlider = styled(Slider)`
  min-width: 13.75rem;
  flex: 1;
`;

export const OutlinedInputStyled = styled(OutlinedInput)`
  padding-right: unset;
  width: 13.75rem;
`;

export const AutocompleteStyled = styled(Autocomplete)`
  width: 13.75rem;
`;

export const StyledCPM = styled(Typography)`
  font-weight: 600;
`;

export const StyledAlert = styled(Alert)`
  padding: 0.5rem 1.536rem;
  align-items: center;
  background: ${({ theme }) => theme.palette.info.light};
  color: ${({ theme }) => theme.palette.info.main};
  line-height: 1.125rem;

  &.MuiPaper-root {
    border-radius: 0.25rem;
  }

  & .MuiAlert-icon {
    padding: 0;
    & svg {
      width: unset;
      height: unset;
    }
  }

  & .MuiAlert-message {
    padding: 0;
  }

  & .MuiAlert-action {
    padding: 0;
    margin-right: 0;
  }
`;
