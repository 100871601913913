import { FC, useState } from "react";
import { useDeletePdfColorMutation } from "@Pdf/hooks/query/useDeletePdfColorQuery";
import { toast } from "react-toastify";
import { useAppTranslation } from "@hooks";
import { LoadingButton } from "@mui/lab";
import { TrashNew } from "@assets/icons";

type PropsType = {
  colorId?: number;
  disabled: boolean;
};

const DeletePdfColorButton: FC<PropsType> = ({ colorId, disabled }) => {
  const [submitting, setSubmitting] = useState(false);
  const { t } = useAppTranslation();

  const { mutate } = useDeletePdfColorMutation({
    onSuccess: async () => {
      toast.success(t("pdf.delete_color_success"));
      setSubmitting(false);
    },
    onError: () => {
      toast.error(t("pdf.delete_color_error"));
      setSubmitting(false);
    },
  });

  const onClick = () => {
    colorId && mutate(colorId);
  };

  return (
    <LoadingButton
      style={{ width: "fit-content" }}
      variant="text"
      loading={submitting}
      onClick={onClick}
      disabled={disabled}
      startIcon={<TrashNew size="w-3 h-3" className="stroke-current" />}
    >
      {t("pdf.delete_color")}
    </LoadingButton>
  );
};

export default DeletePdfColorButton;
