import { matchPath, useLocation } from "react-router-dom";

import { useAppTranslation } from "@hooks";

import { MenuButtonType } from "../menuButtons";
import { MuiButtonWithTooltip } from "components/MuiButtonWithTooltip/MuiButtonWithTooltip";
import { useTheme } from "@mui/material";
import { useState } from "react";

export const MenuButton = ({
  onClick,
  link,
  label,
  labelEn,
  icon: Icon,
  primaryColor,
}: MenuButtonType) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const { isPolishChosen } = useAppTranslation();
  const { pathname } = useLocation();
  const { palette } = useTheme();
  const iconColors = matchPath(pathname, link ?? "")
    ? palette.primary.main
    : primaryColor
    ? "#ffaa00"
    : "inherit";

  return (
    <MuiButtonWithTooltip
      style={{ color: iconColors }}
      onClick={() => {
        onClick && onClick();
        handleClose();
      }}
      icon={<Icon />}
      to={link ?? undefined}
      placement="right"
      tooltipTitle={isPolishChosen ? label : labelEn}
      squareBorder
      open={open}
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
    />
  );
};
