import React, { FC, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Pencil, TrashNew, NotAllowed, CheckNew } from "@assets/icons";
import { useAppTranslation } from "@hooks";
import { VisitOverviewNavigationIconButton } from "./ScheduleVisitOverviewModal.styled";
import {
  ScheduleVisitDropdownMenu,
  ScheduleVisitMenuItem,
  ScheduleVisitMenuItemIconButton,
  ScheduleVisitMenuItemText,
  ScheduleVisitMenuDivider,
} from "./ScheduleVisitDropdown.styled";

import { EVENT_STATUS, MEETING_TYPE } from "@utils";
import { usePatchScheduleEvent } from "@hooks/queries";

import {
  ScheduleVisitModalActionType,
  useScheduleVisitModalContext,
} from "../ScheduleVisitModalContext";
import { Box } from "@mui/material";

interface ScheduleVisitDropdownProps {
  eventId: number;
  isConfirmed: boolean;
  onClose: () => void;
  isVisit: boolean;
  isOutdated: boolean;
}

export const ScheduleVisitDropdown: FC<ScheduleVisitDropdownProps> = ({
  eventId,
  isConfirmed,
  onClose,
  isVisit,
  isOutdated,
}) => {
  const { t } = useAppTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const {
    handleOpenActionModal,
    handleSetActionType,
    handleSetEventId,
    handleVisitModalOpen,
    handleSetActiveTab,
  } = useScheduleVisitModalContext();

  const { mutateAsync: patchScheduleEvent } = usePatchScheduleEvent(eventId);

  const open = Boolean(anchorEl);

  const handleOpenDropdown = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropDown = () => {
    setAnchorEl(null);
  };

  const handleConfirmScheduleStatus = async (status: EVENT_STATUS) => {
    try {
      await patchScheduleEvent({ status });
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleAction = async (actionType: ScheduleVisitModalActionType) => {
    handleOpenActionModal();
    handleSetActionType(actionType);
    handleSetEventId(eventId);
    handleCloseDropDown();
    onClose();
  };

  const handleEdit = (activeTab: MEETING_TYPE) => {
    handleSetEventId(eventId);
    handleSetActiveTab(activeTab);
    handleVisitModalOpen();
    handleCloseDropDown();
    onClose();
  };

  const currentDeleteAction = isVisit
    ? ScheduleVisitModalActionType.DELETE_VISIT
    : ScheduleVisitModalActionType.DELETE_EVENT;

  return (
    <div>
      {isVisit ? (
        <div>
          <VisitOverviewNavigationIconButton
            size="small"
            onClick={handleOpenDropdown}
          >
            <MoreVertIcon />
          </VisitOverviewNavigationIconButton>
          <ScheduleVisitDropdownMenu
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseDropDown}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <ScheduleVisitMenuItem
              disabled={isOutdated}
              onClick={() =>
                handleAction(ScheduleVisitModalActionType.CANCEL_VISIT)
              }
            >
              <ScheduleVisitMenuItemIconButton size="small">
                <NotAllowed />
              </ScheduleVisitMenuItemIconButton>
              <ScheduleVisitMenuItemText>
                {t("calendar.cancel")}
              </ScheduleVisitMenuItemText>
            </ScheduleVisitMenuItem>
            <ScheduleVisitMenuItem
              disabled={isConfirmed}
              onClick={() =>
                handleConfirmScheduleStatus(EVENT_STATUS.CONFIRMED)
              }
            >
              <ScheduleVisitMenuItemIconButton size="small">
                <CheckNew />
              </ScheduleVisitMenuItemIconButton>
              <ScheduleVisitMenuItemText>
                {t("calendar.confirm")}
              </ScheduleVisitMenuItemText>
            </ScheduleVisitMenuItem>
            <ScheduleVisitMenuDivider />
            <ScheduleVisitMenuItem
              onClick={() => handleEdit(MEETING_TYPE.CONSULTATION)}
            >
              <ScheduleVisitMenuItemIconButton size="small">
                <Pencil />
              </ScheduleVisitMenuItemIconButton>
              <ScheduleVisitMenuItemText>
                {t("calendar.edit")}
              </ScheduleVisitMenuItemText>
            </ScheduleVisitMenuItem>
            <ScheduleVisitMenuItem
              onClick={() => handleAction(currentDeleteAction)}
            >
              <ScheduleVisitMenuItemIconButton size="small">
                <TrashNew />
              </ScheduleVisitMenuItemIconButton>
              <ScheduleVisitMenuItemText>
                {t("calendar.delete")}
              </ScheduleVisitMenuItemText>
            </ScheduleVisitMenuItem>
          </ScheduleVisitDropdownMenu>
        </div>
      ) : (
        <Box display="flex">
          <VisitOverviewNavigationIconButton
            onClick={() => handleEdit(MEETING_TYPE.OTHER)}
          >
            <Pencil />
          </VisitOverviewNavigationIconButton>
          <VisitOverviewNavigationIconButton
            onClick={() => handleAction(currentDeleteAction)}
          >
            <TrashNew />
          </VisitOverviewNavigationIconButton>
        </Box>
      )}
    </div>
  );
};
