import { useContext } from "react";
import { useController } from "react-hook-form";

import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

import { ItemOptionConfigDto } from "@client/surveys";
import { FormTextField } from "@components/FormTextField";
import { useAppTranslation } from "@hooks";

import { BlurContext } from "../BlurContext";
import { RadioAnswerFormInputs } from "../forms";
import { SAFARI_TAB_INDEX } from "@utils";

interface ControlledRadioContentProps {
  options: ItemOptionConfigDto[];
  other: ItemOptionConfigDto | null;
  readOnly?: boolean;
}

export const ControlledRadioContent = ({
  options,
  other,
  readOnly,
}: ControlledRadioContentProps) => {
  const { t } = useAppTranslation();
  const { handleBlur, handleFocus } = useContext(BlurContext);

  const {
    field: { value, onChange },
  } = useController<RadioAnswerFormInputs>({ name: "uuid" });

  if (value === undefined) return <></>;

  return (
    <>
      <RadioGroup
        tabIndex={SAFARI_TAB_INDEX}
        onBlur={handleBlur}
        onFocus={handleFocus}
        value={value}
        onChange={(_, v) => !readOnly && onChange(v)}
      >
        {options.map(({ uuid, name }) => (
          <FormControlLabel
            key={uuid}
            value={uuid}
            control={<Radio />}
            label={name}
          />
        ))}
        {other?.name && other.uuid && (
          <FormControlLabel
            value={other?.uuid}
            control={<Radio />}
            label={other?.name}
          />
        )}
      </RadioGroup>

      {value === other?.uuid && (
        <FormTextField
          onBlur={handleBlur}
          onFocus={handleFocus}
          name="text"
          size="small"
          FormHelperTextProps={{ hidden: true }}
          placeholder={t("questionnaires.questions.radio.placeholder")}
          InputProps={{ readOnly }}
        />
      )}
    </>
  );
};
