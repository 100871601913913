import { CheckNew } from "@assets/icons";
import { styled } from "@mui/material";

export const CheckIcon = styled(CheckNew)`
  &.MuiChip-icon {
    margin-left: 0.5rem;
  }
`;

export const ActiveServicesContent = styled("div")`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`;
