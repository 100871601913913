import { IconButton, styled } from "@mui/material";
import { getHexColorOpacity } from "@utils";

export const ContainerStyled = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 24px;
  border-radius: 8px;
  border: 1px dashed
    ${({ theme }) => getHexColorOpacity(theme.palette.primary.main, 30)};
  box-shadow: ${({ theme }) => theme.boxShadows.card};
`;
export const ImgContainer = styled("div")`
  position: relative;
  flex: 1;
  border-radius: 8px;
  border: 1px dashed
    ${({ theme }) => getHexColorOpacity(theme.palette.primary.main, 30)};
  box-shadow: ${({ theme }) => theme.boxShadows.card};
  height: 207px;
  max-height: 207px;
`;

export const ImgStyled = styled("img")`
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 8px;
`;
export const InputStyled = styled("input")`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
`;

export const Title = styled("span")`
  font: 400 12px/20px Figtree, sans-serif;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};

  & strong {
    font-weight: 600;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const Description = styled("span")`
  display: flex;
  font: 400 10px/18px Figtree, sans-serif;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;

export const DotStyled = styled("div")`
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: ${({ theme }) => theme.colors.neutral.medium[400]};
  margin: 6px;
`;

export const DeleteButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  margin: 8px;
  border-radius: 100%;
  background: ${({ theme }) =>
    getHexColorOpacity(theme.palette.primary.light, 70)};
`;
