import {
  APIMethods,
  MetadataSchema,
  PaginationApiResponse,
} from "@typeDefinitions";
import { fetchData } from "@utils/api";
import { logFetchValidateError } from "@utils/validate";
import { object } from "yup";
import {
  PatientProgramSearchNewResource,
  patientProgramsSearchSchema,
} from "@client/resources/PatientProgramSearchNewResource";

export type FetchPatientProgramsSearchParams = {
  patientId: number;
  page: number;
  perPage: number;
};

export type FetchPatientProgramsSearchResponse = PaginationApiResponse<
  PatientProgramSearchNewResource[]
>;

export const fetchPatientProgramsSearch = async (
  params: FetchPatientProgramsSearchParams,
): Promise<FetchPatientProgramsSearchResponse> => {
  const searchParams = new URLSearchParams();
  searchParams.set("page", params.page.toString());
  searchParams.set("perPage", params.perPage.toString());

  const response = await fetchData(
    "/dietitian/patients/{patient_id}/programs-search".replace(
      "{patient_id}",
      params.patientId.toString(),
    ),
    APIMethods.GET,
    undefined,
    searchParams,
  );

  return await schema
    .validate(response)
    .catch(
      logFetchValidateError("/dietitian/patients/{patient_id}/programs-search"),
    );
};

const schema = object({
  data: patientProgramsSearchSchema.defined(),
  meta: MetadataSchema.defined(),
});
