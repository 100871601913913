import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import * as yup from "yup";
import { logFetchValidateError } from "@utils/validate";
import {
  MeasurementPhotoResource,
  measurementPhotoSchema,
} from "@client/resources/MeasurementPhotoResource";

export type FetchMonitoringMeasurementsPhotosParams = {
  patientId: number;
  dateFrom: string;
  dateTo: string;
};

export type FetchMonitoringMeasurementsPhotosResponse = ApiResponse<
  MeasurementPhotoResource[]
>;

export const fetchMonitoringMeasurementsPhotos = async (
  params: FetchMonitoringMeasurementsPhotosParams,
): Promise<FetchMonitoringMeasurementsPhotosResponse> => {
  const response = await fetchData(
    `/dietitian/patients/{patient_id}/monitoring2/body-measurements/photos`.replace(
      "{patient_id}",
      params.patientId.toString(),
    ),
    APIMethods.GET,
    undefined,
    mapUrlParams(params),
  );

  return await schema
    .validate(response)
    .catch(
      logFetchValidateError(
        "/dietitian/patients/{patient_id}/monitoring2/body-measurements/photos",
      ),
    );
};

const mapUrlParams = (params: FetchMonitoringMeasurementsPhotosParams) => {
  const urlParams = new URLSearchParams();
  urlParams.set("date-from", params.dateFrom);
  urlParams.set("date-to", params.dateTo);

  return urlParams;
};

const schema = yup.object({
  data: yup.array().of(measurementPhotoSchema).defined(),
});
