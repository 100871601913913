import body_measurement_tab from "@locales/patient/health_and_goal/body_measurement_tab";
import dietary_profile_tab from "@locales/patient/health_and_goal/dietary_profile_tab";

export default {
  pl: {
    tab_section_edit_button: "Edytuj",
    body_measurement_tab: body_measurement_tab.pl,
    dietary_profile_tab: dietary_profile_tab.pl,
  },
  en: {
    tab_edit_button: "Edit",
    body_measurement_tab: body_measurement_tab.en,
    dietary_profile_tab: dietary_profile_tab.en,
  },
};
