import { SvgIcon, SvgIconProps } from "@components/SvgIcon";
import { useTheme } from "@mui/material";

export const EmptyCircle = (props: SvgIconProps) => {
  const { palette } = useTheme();

  return (
    <SvgIcon width="13" height="12" viewBox="0 0 13 12" {...props}>
      <path
        d="M6.5 12C5.375 11.9844 4.36719 11.7109 3.47656 11.1797C2.57031 10.6484 1.85156 9.92969 1.32031 9.02344C0.789062 8.13281 0.515625 7.125 0.5 6C0.515625 4.875 0.789062 3.86719 1.32031 2.97656C1.85156 2.07031 2.57031 1.35156 3.47656 0.820312C4.36719 0.289062 5.375 0.0156247 6.5 -2.84124e-07C7.625 0.0156248 8.63281 0.289062 9.52344 0.820312C10.4297 1.35156 11.1484 2.07031 11.6797 2.97656C12.2109 3.86719 12.4844 4.875 12.5 6C12.4844 7.125 12.2109 8.13281 11.6797 9.02344C11.1484 9.92969 10.4297 10.6484 9.52344 11.1797C8.63281 11.7109 7.625 11.9844 6.5 12ZM11.375 6C11.3437 4.625 10.8672 3.47656 9.94531 2.55469C9.02344 1.63281 7.875 1.15625 6.5 1.125C5.125 1.15625 3.97656 1.63281 3.05469 2.55469C2.13281 3.47656 1.65625 4.625 1.625 6C1.65625 7.375 2.13281 8.52344 3.05469 9.44531C3.97656 10.3672 5.125 10.8437 6.5 10.875C7.875 10.8437 9.02344 10.3672 9.94531 9.44531C10.8672 8.52344 11.3437 7.375 11.375 6Z"
        fill={props.fill ?? palette.primary.main}
      />
    </SvgIcon>
  );
};
