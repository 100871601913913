import { UIEventHandler, useRef, useState } from "react";

import { ArrowRight } from "@assets/icons/Arrows";
import { ColumnTableDto, RowTableDto } from "@client/surveys";

import {
  ColumnHeaderStyled,
  FabStyled,
  RowHeaderStyled,
  StyledFormTextField,
  TableAnswerInputsWrapper,
} from "./QuestionTable.styled";

interface TableAnswerInputsProps {
  columns: ColumnTableDto[];
  rows: RowTableDto[];
  readOnly?: boolean;
  showFab?: boolean;
}
export const TableAnswerInputs = ({
  columns,
  rows,
  readOnly,
  showFab = false,
}: TableAnswerInputsProps) => {
  const cellsById = Object.assign(
    {},
    ...rows.map((row, rowIdx) => ({
      [row.uuid]: Object.assign(
        {},
        ...columns.map((col, colIdx) => ({
          [col.uuid]: colIdx + rowIdx * columns.length,
        })),
      ),
    })),
  );
  const getCellId = (rowUuid: string, colUuid: string) => {
    if (!cellsById?.[rowUuid]?.[colUuid]) return 0;
    return cellsById[rowUuid][colUuid];
  };

  const wrapperRef = useRef<HTMLDivElement>(null);
  const fabVisible = !!showFab && columns.length > 2;
  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);

  const scrollToEnd = () => {
    const wrapper = wrapperRef.current;
    if (wrapper) {
      wrapper.scrollTo({
        left: wrapper.scrollWidth - wrapper.clientWidth,
        behavior: "smooth",
      });
      setIsScrolledToEnd(true);
    }
  };

  const handleOnScroll: UIEventHandler<HTMLDivElement> | undefined = e =>
    setIsScrolledToEnd(
      Math.ceil(e.currentTarget.scrollLeft + e.currentTarget.clientWidth) >=
        e.currentTarget.scrollWidth,
    );

  return (
    <TableAnswerInputsWrapper ref={wrapperRef} onScroll={handleOnScroll}>
      <div className="flex gap-2">
        <RowHeaderStyled />
        {columns.map(col => (
          <ColumnHeaderStyled key={col.uuid}>{col.name}</ColumnHeaderStyled>
        ))}
      </div>

      {rows.map(row => (
        <div key={row.uuid} className="flex gap-[8px]">
          <RowHeaderStyled key={row.uuid}>{row.name}</RowHeaderStyled>

          {columns.map(col => (
            <StyledFormTextField
              multiline
              key={col.uuid}
              name={`cells.${getCellId(row.uuid, col.uuid)}.value`}
              size="small"
              InputProps={{ readOnly }}
            />
          ))}
        </div>
      ))}
      {fabVisible && !isScrolledToEnd && (
        <FabStyled size="small" color="primary" onClick={scrollToEnd}>
          <ArrowRight />
        </FabStyled>
      )}
    </TableAnswerInputsWrapper>
  );
};
