import * as yup from "yup";

import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { validateAndFixNutrients } from "./utils/validateCatching";

export const fetchDietProducts = async (
  id: number,
): Promise<ApiResponse<FetchDietProductsResponse[]>> => {
  const rsp = await fetchData(
    `/dietitian/diets/${id}/food/replacement`,
    APIMethods.GET,
  );
  return await validateAndFixNutrients(fetchDietProductsResponseSchema, rsp);
};

const dietMeasuresSchema = yup.object().shape({
  id: yup.number().required(),
  grams: yup.number().required(),
});

const dietNutrientSchema = yup.object().shape({
  id: yup.number().required(),
  value: yup.number().nullable(),
});

const fetchDietProductsResponseSchema = yup.object().shape({
  data: yup.array().of(
    yup.object().shape({
      id: yup.number().required(),
      description: yup.string().required(),
      descriptionEn: yup.string().required(),
      measures: yup.array().of(dietMeasuresSchema),
      nutrients: yup.array().of(dietNutrientSchema),
    }),
  ),
});

interface FetchDietProductsResponse {
  id: number;
  description: string;
  descriptionEn: string;
  measures: DietMeasuresDto[];
  nutrients: DietNutrientDto[];
}

interface DietMeasuresDto {
  id: number;
  grams: number;
}

interface DietNutrientDto {
  id: number;
  value?: number;
}
