import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useAppTranslation } from "@hooks";
import { MutationOptions } from "./types";
import { requestDeleteProgram } from "@client";
import { toast } from "react-toastify";
import { searchProgramsQueryKeyNew } from "./useSearchProgramsQueryNew";

export const useDeleteProgramMutation = (options?: MutationOptions) => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();

  return useMutation((id: number) => requestDeleteProgram(id), {
    ...options,
    onSuccess: () => {
      toast.success(t("programs.program_removed"));
      queryClient.invalidateQueries([searchProgramsQueryKeyNew]);
      options?.onSuccess && options.onSuccess();
    },
  });
};
