import { useEffect, useRef } from "react";

export const useInfiniteScroll = (callback: () => void) => {
  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    if (!observer.current) {
      observer.current = new IntersectionObserver(
        entry => {
          if (entry[0].isIntersecting) {
            callback();
          }
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 1.0,
        },
      );
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [callback]);

  const observeElement = (element: Element | null) => {
    if (observer.current && element) {
      observer.current.observe(element);
    }
  };

  return observeElement;
};
