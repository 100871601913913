import { useForm } from "react-hook-form";
import { useEffect } from "react";

import dayjs from "dayjs";
import * as yup from "yup";

import {
  CreateScheduleEventRequest,
  FetchScheduleEventResponse,
} from "@client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppTranslation } from "@hooks";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";
import { Currencies, MEETING_MODE, MEETING_TYPE } from "@utils/scheduleEvent";

export interface AddEventModalInput {
  eventType: MEETING_TYPE;
  patientId: number | null;
  eventName: string;
  isAllDay: boolean;
  startDate: Date;
  finishDate: Date;
  dietitianId: number | null;
  clinicFacilityId: number | null;
  clinicProductId: string | null;
  price: number | null;
  currency: Currencies | null;
  additionalInfo: string | null;
  notification: boolean | null;
}

export const useAddVisitForm = (defaultValues?: AddEventModalInput) => {
  const { t } = useAppTranslation();
  const date = dayjs();
  const minutes = date.minute() % 5;
  const { account } = useFetchDietitianAccountQuery();

  const basicEventSchema = yup.object().shape({
    eventType: yup.number().required(t("common.required_field")),
    patientId: yup.number().nullable(),
    eventName: yup.string().required(t("common.required_field")),
    isAllDay: yup.boolean().required(t("common.required_field")),
    startDate: yup
      .date()
      .typeError(t("common.required_field"))
      .required(t("common.required_field"))
      .test(
        MINUTES_VALIDATION,
        t("visits.minutes_by_5"),
        validateFiveMinutesStep,
      ),
    finishDate: yup
      .date()
      .typeError(t("common.required_field"))
      .required(t("common.required_field"))
      .test(
        MINUTES_VALIDATION,
        t("visits.minutes_by_5"),
        validateFiveMinutesStep,
      ),
    dietitianId: yup
      .number()
      .typeError(t("common.required_field"))
      .required(t("common.required_field")),
    clinicFacilityId: yup.number().nullable(),
    clinicProductId: yup.number().nullable(),
    price: yup.number().nullable(),
    currency: yup.string().nullable(),
    additionalInfo: yup.string().nullable(),
  });

  const visitEventSchema = yup.object().shape({
    eventType: yup
      .number()
      .typeError(t("common.required_field"))
      .required(t("common.required_field")),
    patientId: yup
      .number()
      .typeError(t("common.required_field"))
      .required(t("common.required_field")),
    eventName: yup.string().required(t("common.required_field")),
    isAllDay: yup.boolean().required(t("common.required_field")),
    startDate: yup
      .date()
      .typeError(t("common.required_field"))
      .required(t("common.required_field"))
      .test(
        MINUTES_VALIDATION,
        t("visits.minutes_by_5"),
        validateFiveMinutesStep,
      ),
    finishDate: yup
      .date()
      .typeError(t("common.required_field"))
      .required(t("common.required_field"))
      .test(
        MINUTES_VALIDATION,
        t("visits.minutes_by_5"),
        validateFiveMinutesStep,
      ),
    dietitianId: yup
      .number()
      .typeError(t("common.required_field"))
      .required(t("common.required_field")),
    clinicFacilityId: yup.number().nullable(),
    clinicProductId: yup
      .number()
      .typeError(t("common.required_field"))
      .required(t("common.required_field")),
    price: yup.number().nullable(),
    currency: yup.string().nullable(),
    additionalInfo: yup.string().nullable(),
    notification: yup.boolean().nullable(),
  });

  const pricedVisitEventSchema = yup.object().shape({
    eventType: yup
      .number()
      .typeError(t("common.required_field"))
      .required(t("common.required_field")),
    patientId: yup
      .number()
      .typeError(t("common.required_field"))
      .required(t("common.required_field")),
    eventName: yup.string().required(t("common.required_field")),
    isAllDay: yup.boolean().required(t("common.required_field")),
    startDate: yup
      .date()
      .typeError(t("common.required_field"))
      .required(t("common.required_field"))
      .test(
        MINUTES_VALIDATION,
        t("visits.minutes_by_5"),
        validateFiveMinutesStep,
      ),
    finishDate: yup
      .date()
      .typeError(t("common.required_field"))
      .required(t("common.required_field"))
      .test(
        MINUTES_VALIDATION,
        t("visits.minutes_by_5"),
        validateFiveMinutesStep,
      ),
    dietitianId: yup
      .number()
      .typeError(t("common.required_field"))
      .required(t("common.required_field")),
    clinicFacilityId: yup.number().nullable(),
    clinicProductId: yup
      .number()
      .typeError(t("common.required_field"))
      .required(t("common.required_field")),
    price: yup
      .number()
      .typeError(t("common.required_field"))
      .required(t("common.required_field")),
    currency: yup.string().required(t("common.required_field")),
    additionalInfo: yup.string().nullable(),
  });

  const addEventSchema = yup.lazy((value: any) => {
    if (value.eventType === MEETING_TYPE.CONSULTATION) {
      if (value.price) return pricedVisitEventSchema;
      else return visitEventSchema;
    } else {
      return basicEventSchema;
    }
  });

  const form = useForm<AddEventModalInput>({
    defaultValues: {
      eventType: MEETING_TYPE.CONSULTATION,
      startDate: date.subtract(minutes, "minutes").add(1, "hour").toDate(),
      finishDate: date.subtract(minutes, "minutes").add(2, "hour").toDate(),
      isAllDay: false,
      dietitianId: account?.id ?? null,
      price: null,
      clinicFacilityId: null,
      additionalInfo: "",
      currency: null,
      clinicProductId: null,
      eventName: "",
      patientId: null,
      notification: false,
    },
    resolver: yupResolver(addEventSchema),
  });

  useEffect(() => {
    if (defaultValues) form.reset(defaultValues);
  }, [defaultValues]);

  return form;
};

export const mapAddVisitRequest = (
  data: AddEventModalInput,
): CreateScheduleEventRequest => {
  const dateStart = data.isAllDay
    ? dayjs(data.startDate)
        .set("hour", 0)
        .set("minutes", 0)
        .format("YYYY-MM-DD HH:mm")
    : dayjs(data.startDate).format("YYYY-MM-DD HH:mm");
  const dateEnd = `${dayjs(data.startDate).format("YYYY-MM-DD")} ${
    data.isAllDay
      ? dayjs(data.finishDate)
          .set("hour", 23)
          .set("minutes", 55)
          .format("HH:mm")
      : dayjs(data.finishDate).format("HH:mm")
  }`;
  const isRemote = data.clinicFacilityId === EVENT_ONLINE_LOCATION;

  if (!data.patientId) console.log("patientId cannot be null", data.patientId);
  if (!data.dietitianId)
    console.log("dietitianId cannot be null", data.dietitianId);

  return {
    meetingTypeId: data.eventType,
    dietitianId: data.dietitianId ?? 0,
    patientId: data.patientId ?? 0,
    title: data.eventName,
    dateStart,
    dateEnd,
    isAllDay: data.isAllDay ? 1 : 0,
    additionalInfo: data.additionalInfo ?? null,
    clinicFacilityId: isRemote ? null : data.clinicFacilityId,
    clinicProductId: parseInt(data.clinicProductId ?? "") ?? null,
    meetingModeId: isRemote ? MEETING_MODE.ONLINE : MEETING_MODE.OFFICE,
    price:
      data.eventType === MEETING_TYPE.CONSULTATION && data.price
        ? {
            value: data.price,
            currency: data.currency!,
          }
        : null,
    notification:
      data.eventType === MEETING_TYPE.CONSULTATION ? data.notification : null,
  };
};

export const mapAddVisitForm = (
  data?: FetchScheduleEventResponse,
): AddEventModalInput | undefined => {
  if (!data) return;
  const isRemote = data.meetingMode?.id === MEETING_MODE.ONLINE;
  return {
    eventType: data.meetingType.id as MEETING_TYPE,
    patientId: data.patient?.id ?? null,
    eventName: data.title,
    isAllDay: data.isAllDay,
    startDate: dayjs(data.dateStart).toDate(),
    finishDate: dayjs(data.dateEnd).toDate(),
    dietitianId: data.dietitian.id,
    clinicFacilityId: isRemote
      ? EVENT_ONLINE_LOCATION
      : data.clinicFacility?.id ?? null,
    clinicProductId: data.clinicProduct?.id.toString() ?? null,
    price: data.price?.value ?? null,
    currency: data.price?.currency ?? null,
    additionalInfo: data.additionalInfo ?? null,
    notification: null,
  };
};

export const EVENT_ONLINE_LOCATION = -1;

const validateFiveMinutesStep = (value: Date) =>
  dayjs(value).minute() % 5 === 0;
export const MINUTES_VALIDATION = "MINUTES_VALIDATION";
