import styled from "@emotion/styled";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

interface Ordered {
  order: number;
}

export const SearchBarWrapper = styled("div")<Ordered>`
  order: ${({ order }) => order};
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;

export const FilterTagsWrapper = styled("div")<Ordered>`
  order: ${({ order }) => order};
`;

export const StyledAccordion = styled(Accordion)<Ordered>`
  background-color: ${({ theme }) => theme.colors.gray200} !important;
  border-radius: 0.375rem;
  padding: 1rem;
  order: ${({ order }) => order};
  margin-bottom: 1.5rem;

  &.MuiAccordion-root.Mui-expanded {
    padding-bottom: 2rem;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  min-height: 1.875rem;
  padding: 0;

  & .MuiAccordionSummary-content {
    margin: 0;
  }
`;

export const StyledTitle = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.regular};
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
`;
