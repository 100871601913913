import { styled } from "@mui/material";

export const ChatboxContainer = styled("div")`
  height: 550px;
  width: 350px;
  bottom: 0px;
  box-shadow: ${({ theme }) => theme.boxShadows.card};
`;

export const ChatsWrapperStyled = styled("div")`
  display: flex;
  position: absolute;
  bottom: 0px;
  right: 16px;
  gap: 16px;
  z-index: 1000;
`;
