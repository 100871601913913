import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { QuestionDateDto } from "@client/surveys";

import { QuestionFormInputs } from "../Question/useQuestionForm";

export const useRequiredConfigForm = (values?: RequiredConfigFormInputs) => {
  const [init, setInit] = useState(true);
  const form = useForm<RequiredConfigFormInputs>({
    defaultValues: {
      title: "",
      description: null,
      required: false,
    },
  });

  useEffect(() => {
    if (init && values) {
      form.reset(values);
      setInit(false);
    }
  }, [values]);

  return form;
};

export interface RequiredConfigFormInputs extends QuestionFormInputs {
  required: boolean;
}

export const mapRequiredConfigForm = ({
  title,
  description,
  config,
}: QuestionDateDto): RequiredConfigFormInputs => {
  return {
    title,
    description,
    required: config.required,
  };
};

export const mapRequiredConfigRequest = (
  { description, required, title }: RequiredConfigFormInputs,
  { id, type }: QuestionDateDto,
): QuestionDateDto => {
  return {
    id,
    title,
    description,
    config: { required },
    type,
  };
};
