import { RadioGroup, TextField, Typography, styled } from "@mui/material";

export const PaginationTypography = styled(Typography)`
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;

export const ModalLabel = styled("span")`
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;

export const TextFieldStyled = styled(TextField)`
  width: 30rem;
`;

export const RadioGroupStyled = styled(RadioGroup)`
  gap: 0.5rem;
`;

export const PaginationWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
