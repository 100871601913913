import { RecipeWrapperDto } from "@client/diets";
import {
  AccordionDetailsStyled,
  AccordionStyled,
  AccordionSummaryStyled,
} from "./common.styled";
import { SyntheticEvent, useRef } from "react";
import { ArrowRight, Clock, Cutlery, PieChart } from "@assets/icons";
import { AccordionTitle } from "../AccordionTitle/AccordionTitle";
import { TagWithIcon } from "@components/TagWithIcon";
import { useTagsNew } from "@hooks/useTagsNew";
import { TagCategoryType } from "@utils/tagsNew";
import { LangDto } from "@client";
import { useAppTranslation } from "@hooks";
import { RecipeDetails } from "./RecipeDetails";

interface RecipeAccordionProps {
  recipe: RecipeWrapperDto;
  dietId: string;
  mealId: string;
}

export const RecipeAccordion = ({
  recipe: { id, recipe, servings },
  dietId,
  mealId,
}: RecipeAccordionProps) => {
  const { t, isPolishChosen } = useAppTranslation();
  const anchorEl = useRef<null | HTMLDivElement>(null);
  const { tagCategoryDictionary, tagNameDictionary } = useTagsNew();
  const totalServings = recipe.servings;
  const hasMultipleServings = totalServings > 1;

  const difficultyOfPreparationTagList = tagCategoryDictionary.get(
    TagCategoryType.DIFFICULTY_OF_PREPARATION,
  )?.tags;
  const preparationTimeTagList = tagCategoryDictionary.get(
    TagCategoryType.PREPARATION_TIME,
  )?.tags;

  const difficultyOfPreparationTag = recipe.tags?.find(tag =>
    difficultyOfPreparationTagList?.some(t => t.id === tag.id),
  );
  const preparationTimeTag = recipe.tags?.find(tag =>
    preparationTimeTagList?.some(t => t.id === tag.id),
  );

  const namePl =
    recipe.translations.find(t => t.lang === LangDto.PL)?.name ?? "";
  const nameEn =
    recipe.translations.find(t => t.lang === LangDto.PL)?.name ?? "";
  const recipeName = isPolishChosen ? namePl : nameEn ?? namePl;

  const handleChange = (
    e: SyntheticEvent<Element, Event>,
    isExpanded: boolean,
  ) => {
    const current = anchorEl.current;
    if (isExpanded && current) {
      setTimeout(
        () => current.scrollIntoView({ behavior: "smooth", block: "center" }),
        350,
      );
    }
  };

  return (
    <AccordionStyled
      key={id}
      TransitionProps={{ unmountOnExit: true }}
      ref={anchorEl}
      onChange={handleChange}
    >
      <AccordionSummaryStyled
        expandIcon={<ArrowRight size="w-[16px] h-[16px]" />}
      >
        <AccordionTitle
          img={recipe.image?.url ?? ""}
          name={recipeName}
          tags={
            <>
              {!!preparationTimeTag && (
                <TagWithIcon
                  Icon={Clock}
                  label={tagNameDictionary.get(Number(preparationTimeTag))}
                />
              )}
              {!!difficultyOfPreparationTag && (
                <TagWithIcon
                  Icon={Cutlery}
                  label={tagNameDictionary.get(
                    Number(difficultyOfPreparationTag),
                  )}
                />
              )}
              {hasMultipleServings && (
                <TagWithIcon
                  Icon={PieChart}
                  label={`${servings}/${totalServings} ${t("recipe.portions")}`}
                />
              )}
            </>
          }
          nutrients={recipe.nutrients}
        />
      </AccordionSummaryStyled>
      <AccordionDetailsStyled>
        <RecipeDetails dietId={dietId} mealId={mealId} itemId={id.toString()} />
      </AccordionDetailsStyled>
    </AccordionStyled>
  );
};
