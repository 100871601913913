import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { fetchSection } from "@client";
import { useAppTranslation } from "@hooks";

export const fetchSectionQueryKey = "sectionDetails";

export const useFetchSectionQuery = (sectionId: number) => {
  const { t } = useAppTranslation();
  const { data: section, ...rest } = useQuery(
    [fetchSectionQueryKey, sectionId],
    () => fetchSection(sectionId),
    {
      onError: e => {
        console.log(e);
        toast.error(t("common.error"));
      },
    },
  );

  return { section, ...rest };
};
