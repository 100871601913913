import { styled } from "@mui/material";
import { Link } from "react-router-dom";

export const ConfirmSignUpViewWrapper = styled("div")`
  display: grid;
  gap: 24px;
  align-items: center;
  width: 510px;
  padding: 24px;
  background: ${({ theme }) => theme.colors.neutral.light[100]};
  border-radius: 12px;
  z-index: 1;
  text-align: center;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.smallNew}px) {
    padding: 24px 44px;
  }
`;

export const TopSignUpViewSection = styled("form")`
  display: grid;
  gap: 12px;
  text-align: center;
`;

export const SignUpViewTitle = styled("h1")`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  white-space: pre-line;
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const ContentText = styled("span")`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.4px;
  white-space: pre-line;
`;

export const ConfirmEmailWrapper = styled("div")`
  display: grid;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.smallNew}px) {
    display: flex;
  }
`;
