import { useCallback } from "react";
import { FormProvider } from "react-hook-form";

import { useAppTranslation } from "@hooks";
import {
  useFetchNutrientCategoriesQuery,
  useFetchProgramDayNutrientsQuery,
  useProgramDayNutrientsMutation,
} from "@hooks/queries";
import { useMicrosForm } from "./useMicrosForm";

import { IdMapContextProvider } from "@context";
import { Modal } from "@components/Modal";
import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";
import { MicronutrientsList } from "@components/modals/DietDayNutritionModal/components";

import { NutritionModalFlex } from "../MacrosSliders.styled";

interface FormMicrosModalProps {
  onClose: () => void;
  dayId: number;
  programId: number;
  normId: number;
}

export const FormMicrosModal = ({
  onClose,
  dayId,
  programId,
  normId,
}: FormMicrosModalProps) => {
  const { t } = useAppTranslation();

  const {
    nutrients,
    isLoading: isNutrientLoading,
    isError: isNutrientError,
    isSuccess: isNutrientSuccess,
  } = useFetchProgramDayNutrientsQuery(programId.toString(), dayId.toString());

  const {
    form,
    idMap,
    isLoading: isNutrientsNormLoading,
  } = useMicrosForm(normId, programId, dayId, nutrients?.data);

  const {
    nutrientCategories,
    isLoading: isCategoriesLoading,
    isSuccess: isCategoriesSuccess,
  } = useFetchNutrientCategoriesQuery();

  const mutation = useProgramDayNutrientsMutation();

  const submit = useCallback(
    () =>
      form.handleSubmit(data => {
        if (nutrients && nutrients?.data.norm) {
          mutation.mutate({
            programId: programId.toString(),
            dayId: dayId.toString(),
            payload: {
              normId: nutrients?.data.norm.id,
              nutrients: data.nutrients,
            },
          });
        }
        onClose();
      })(),
    [dayId, programId, mutation, form.handleSubmit],
  );

  const isLoading =
    isNutrientLoading || isCategoriesLoading || isNutrientsNormLoading;
  const isError = isNutrientError;
  const isOK = isCategoriesSuccess && isNutrientSuccess;

  return (
    <Modal
      onSubmit={submit}
      onClose={onClose}
      title={t("macros_sliders.change_micros")}
      className={"h-screen-4/5 w-screen max-w-4xl"}
    >
      {isLoading && <Spinner />}
      {isError && <ApiError />}

      {isOK && (
        <NutritionModalFlex>
          <IdMapContextProvider idMap={idMap}>
            <FormProvider {...form}>
              {isOK && nutrientCategories && (
                <MicronutrientsList categories={nutrientCategories} />
              )}
            </FormProvider>
          </IdMapContextProvider>
        </NutritionModalFlex>
      )}
    </Modal>
  );
};
