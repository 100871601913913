import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MutationOptions } from "./types";
import { fetchProgramQueryKey } from "./useFetchProgramQuery";
import { updateProgram, UpdateProgramRequest } from "@client";

export const useUpdateProgramMutation = (
  id: number,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (request: UpdateProgramRequest) => updateProgram(id, request),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries([fetchProgramQueryKey, id]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};
