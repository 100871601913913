import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import * as yup from "yup";
import { NOTIFICATION_EVENT_STATUS } from "@utils";

export type NotificationType =
  | NotificationProgramsEndsDto
  | NotificationCooperationEndsDto
  | NotificationSurveyDto
  | NotificationPatientBirthdayDto;

export const fetchAccountNotifications = async (
  params?: URLSearchParams,
): Promise<ApiResponse<NotificationType[]>> => {
  const response = await fetchData(
    `/dietitian/account/notifications`,
    APIMethods.GET,
    undefined,
    params,
  );

  return await fetchAccountNotificationsSchema.validate(response);
};

const patientSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
});

const surveySchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
});

const programsEndsDataSchema = yup.object().shape({
  patient: patientSchema.required(),
  programId: yup.string().required(),
  finishedAt: yup.string().required(),
});

const cooperationEndsDataSchema = yup.object().shape({
  patient: patientSchema.required(),
  finishedAt: yup.string().required(),
});

const surveyReportDataSchema = yup.object().shape({
  patient: patientSchema.required(),
  survey: surveySchema.required(),
});

const birthdayDataSchema = yup.object().shape({
  patient: patientSchema.required(),
});

const programsEndsSchema = yup.object().shape({
  id: yup.string().required(),
  type: yup.number<NOTIFICATION_EVENT_STATUS.PROGRAM_ENDS>().required(),
  readAt: yup.string().nullable().default(null),
  createdAt: yup.string().required(),
  data: programsEndsDataSchema.required(),
});

const cooperationEndsSchema = yup.object().shape({
  id: yup.string().required(),
  type: yup
    .number<NOTIFICATION_EVENT_STATUS.PATIENT_COOPERATION_ENDS>()
    .required(),
  readAt: yup.string().nullable().default(null),
  createdAt: yup.string().required(),
  data: cooperationEndsDataSchema.required(),
});

const surveyReportSchema = yup.object().shape({
  id: yup.string().required(),
  type: yup.number<NOTIFICATION_EVENT_STATUS.SURVEY_REPORT>().required(),
  readAt: yup.string().nullable().default(null),
  createdAt: yup.string().required(),
  data: surveyReportDataSchema.required(),
});

const birthdaySchema = yup.object().shape({
  id: yup.string().required(),
  type: yup.number<NOTIFICATION_EVENT_STATUS.PATIENT_BIRTHDAY>().required(),
  readAt: yup.string().nullable().default(null),
  createdAt: yup.string().required(),
  data: birthdayDataSchema.required(),
});

const fetchAccountNotificationsSchema = yup.object().shape({
  data: yup
    .array()
    .of(
      yup.lazy(value => {
        switch (value.type) {
          case NOTIFICATION_EVENT_STATUS.PROGRAM_ENDS:
            return programsEndsSchema.required();
          case NOTIFICATION_EVENT_STATUS.PATIENT_COOPERATION_ENDS:
            return cooperationEndsSchema.required();
          case NOTIFICATION_EVENT_STATUS.SURVEY_REPORT:
            return surveyReportSchema.required();
          case NOTIFICATION_EVENT_STATUS.PATIENT_BIRTHDAY:
            return birthdaySchema.required();
          default:
            return birthdaySchema.required();
        }
      }),
    )
    .required(),
});

export interface EventTypeDto {
  id: number;
}

export interface NotificationDto {
  id: string;
  type: NOTIFICATION_EVENT_STATUS;
  readAt: string | null;
  createdAt: string;
  data:
    | PatientProgramEndsVODto
    | PatientCooperationEndsVODto
    | PatientSurveyReportVODto
    | PatientBirthdayVODto;
}

export interface PatientDto {
  id: number;
  name: string;
}

interface SurveyDto {
  id: number;
  name: string;
}
export interface NotificationProgramsEndsDto extends NotificationDto {
  type: NOTIFICATION_EVENT_STATUS.PROGRAM_ENDS;
  data: PatientProgramEndsVODto;
}

export interface NotificationCooperationEndsDto extends NotificationDto {
  type: NOTIFICATION_EVENT_STATUS.PATIENT_COOPERATION_ENDS;
  data: PatientCooperationEndsVODto;
}

export interface NotificationSurveyDto extends NotificationDto {
  type: NOTIFICATION_EVENT_STATUS.SURVEY_REPORT;
  data: PatientSurveyReportVODto;
}
export interface NotificationPatientBirthdayDto extends NotificationDto {
  type: NOTIFICATION_EVENT_STATUS.PATIENT_BIRTHDAY;
  data: PatientBirthdayVODto;
}

interface PatientProgramEndsVODto {
  programId: string;
  patient: PatientDto;
  finishedAt: string;
}

interface PatientCooperationEndsVODto {
  patient: PatientDto;
  finishedAt: string;
}

interface PatientSurveyReportVODto {
  patient: PatientDto;
  survey: SurveyDto;
}

interface PatientBirthdayVODto {
  patient: PatientDto;
}
