import { Weekday } from "@typeDefinitions";

export const weekdays: Weekday[] = [
  {
    id: 1,
    labelPl: "Poniedziałek",
    labelEn: "Monday",
    shortPl: "Pon",
    shortEn: "Mon",
  },
  {
    id: 2,
    labelPl: "Wtorek",
    labelEn: "Tuesday",
    shortPl: "Wt",
    shortEn: "Tue",
  },
  {
    id: 3,
    labelPl: "Środa",
    labelEn: "Wednesday",
    shortPl: "Śr",
    shortEn: "Wed",
  },
  {
    id: 4,
    labelPl: "Czwartek",
    labelEn: "Thursday",
    shortPl: "Czw",
    shortEn: "Thu",
  },
  {
    id: 5,
    labelPl: "Piątek",
    labelEn: "Friday",
    shortPl: "Pt",
    shortEn: "Fri",
  },
  {
    id: 6,
    labelPl: "Sobota",
    labelEn: "Saturday",
    shortPl: "Sb",
    shortEn: "Sat",
  },
  {
    id: 7,
    labelPl: "Niedziela",
    labelEn: "Sunday",
    shortPl: "Nd",
    shortEn: "Sun",
  },
  {
    id: 8,
    labelPl: "Codziennie",
    labelEn: "Everyday",
    shortPl: "Codziennie",
    shortEn: "Everyday",
  },
];

export const WEEKDAYS_VALUES = [1, 2, 3, 4, 5, 6, 7];
