import { LangDto } from "@client";
import { TranslationLegacyDto } from "@client/surveys";
import { LanguagesSlugs } from "@typeDefinitions";

export const getTranslatedValue = (
  translations: TranslationLegacyDto[],
  lang: LanguagesSlugs,
) => {
  const langDto = LangDto[lang.toUpperCase() as "PL" | "EN"];
  return translations.find(t => t.lang === langDto)?.value;
};
