import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useAppTranslation } from "@hooks";
import { LoginFormInput } from "@typeDefinitions/types";
import { emailSchemaFactory } from "@utils";
import { LangDto } from "@client";

export const useLoginForm = () => {
  const { t, currentLanguage } = useAppTranslation();

  const resolver = yup.object().shape({
    email: emailSchemaFactory(
      t("auth.register_clinic.validation.incorrect_email"),
    ).required(t("auth.register_clinic.validation.email")),
    password: yup.string().required(t("auth.login.password_required")),
    lang: yup.mixed<LangDto>().oneOf(Object.values(LangDto)).required(),
  });

  return useForm<LoginFormInput>({
    defaultValues: {
      email: "",
      password: "",
      lang: (currentLanguage as unknown as LangDto) ?? LangDto.PL,
    },
    resolver: yupResolver(resolver),
  });
};
