import { useContext } from "react";

import { FormTextField, FormTextFieldProps } from "@components/FormTextField";

import { BlurContext } from "./BlurContext";

export const FormTextFieldWrapper = (props: FormTextFieldProps) => {
  const { handleBlur, handleFocus } = useContext(BlurContext);

  return <FormTextField {...props} onFocus={handleFocus} onBlur={handleBlur} />;
};
