import { Stack } from "@mui/material";
import { useState } from "react";
import MeasurementsBodyTabs from "@views/dietician/PatientMonitoring2/components/MeasurementsBox/MeasurementsBodyTabs";
import MeasurementChart from "@views/dietician/PatientMonitoring2/components/MeasurementsBox/MeasurementChart";
import { Goals } from "@views/dietician/PatientMonitoring2/components/MeasurementsBox/MeasurementUtil";
import { BodyMeasurementMonitoringResource } from "@client/resources/BodyMeasurementMonitoringResource";

export type BodyButtonType =
  | "weight"
  | "bodyFatLevel"
  | "arm"
  | "waist"
  | "hip"
  | "thigh"
  | "tightBiceps"
  | "calf";

type MeasurementsBodySectionProps = {
  data: BodyMeasurementMonitoringResource[] | undefined;
  latest: BodyMeasurementMonitoringResource | null;
  goals: Goals;
  tooltipLabelFormatter: (date: string) => string;
  xAxisTickFormatter: (date: string) => string;
};
const MeasurementsBodySection = ({
  data,
  latest,
  goals,
  tooltipLabelFormatter,
  xAxisTickFormatter,
}: MeasurementsBodySectionProps) => {
  const [type, setType] = useState<number | null>(null);

  return (
    <Stack spacing="16px" direction="column">
      <MeasurementsBodyTabs
        type={type}
        setType={setType}
        measurements={latest}
      />
      <MeasurementChart
        data={data ?? []}
        type={type}
        goals={goals}
        xAxisTickFormatter={xAxisTickFormatter}
        tooltipLabelFormatter={tooltipLabelFormatter}
      />
    </Stack>
  );
};

export default MeasurementsBodySection;
