import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const updateProgramName = async (
  id: number,
  programName: UpdateProgramNameRequestDto,
) => {
  await fetchData(
    `/dietitian/programs/${id}/name`,
    APIMethods.PATCH,
    programName,
  );
};

export interface UpdateProgramNameRequestDto {
  name: string;
  nameEn?: string;
}
