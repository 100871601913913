import { ModalWrapper } from "@components/ModalWrapperNew";
import { useAppParams, useAppTranslation } from "@hooks";
import { useDeleteDietProductRemovedMutation } from "../../hooks";

interface DeleteDietProductModalProps {
  open: boolean;
  onClose: () => void;
  name: string;
  productId: string;
}

export const DeleteDietProductModal = ({
  open,
  onClose,
  name,
  productId,
}: DeleteDietProductModalProps) => {
  const { t } = useAppTranslation();
  const { dietId } = useAppParams();
  const { mutate, isLoading } = useDeleteDietProductRemovedMutation({
    onSuccess: onClose,
  });

  const handleDelete = () => {
    mutate({ dietId, productId });
  };
  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      onSubmit={handleDelete}
      color="error"
      title={t("diet.delete_diet_product_modal.title")}
      confirmText={t("diet.delete_diet_product_modal.title")}
      loading={isLoading}
    >
      <div className="max-w-[478px]">
        <p>
          {t("diet.delete_diet_product_modal.question")}:{" "}
          <strong>{name}</strong>?
        </p>
        <p>{t("diet.delete_diet_product_modal.info")}</p>
      </div>
    </ModalWrapper>
  );
};
