import * as yup from "yup";

export interface ClientContextDto {
  hasAllergens: boolean;
  hasEliminations: boolean;
  hasLiked: boolean;
  hasUnliked: boolean;
}

export const clientContextSchema = yup.object({
  hasAllergens: yup.boolean().required(),
  hasEliminations: yup.boolean().required(),
  hasLiked: yup.boolean().required(),
  hasUnliked: yup.boolean().required(),
});
