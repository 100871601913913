import { useAppTranslation, useUpdateClientMobileAppAccess } from "@hooks";
import { MonitoringEmptyState } from "@views/emptyStates/MonitoringEmptyState";

import { FetchClientResponse } from "@client";

type PatientMonitoringOff = {
  client: FetchClientResponse;
};

const PatientMonitoringOff = ({ client }: PatientMonitoringOff) => {
  const { t } = useAppTranslation();

  const hasAccess = !!client?.active;
  const hasEmail = !!client?.email;

  const { mutate, isLoading } = useUpdateClientMobileAppAccess();

  const tooltip = () => {
    if (!hasEmail && !hasAccess)
      return t("empty_states.monitoring_tooltip_both");
    if (!hasEmail) return t("empty_states.monitoring_tooltip_email");
    if (!hasAccess) return t("empty_states.monitoring_tooltip_activation");
    return "";
  };

  return (
    <MonitoringEmptyState
      disabled={!hasAccess || !hasEmail}
      isLoading={isLoading}
      onClick={() =>
        mutate({ id: client.id.toString(), payload: { hasAccess: true } })
      }
      tooltip={tooltip()}
    />
  );
};

export default PatientMonitoringOff;
