import { useEffect } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import "core-js/proposals/promise-with-resolvers";

import { ResetWrappedInTheme } from "@views/public/Reset";
import { Activation, LinkResend } from "@views/public/Activation";
import { Pin } from "@views/public/Pin";
import { Register } from "@views/public/Register";
import { RegisterPatient } from "@views/public/RegisterPatient";
import { VisitCancel, VisitConfirm } from "@views/public/Visit";
import { Home } from "@views/dietician/Home";
import { ClientProfile } from "@views/dietician/ClientProfile";
import { PatientVisits } from "@views/dietician/PatientVisits";
import { PatientEnergyBalance } from "@views/dietician/PatientEnergyBalance";
import { PatientMonitoring } from "@views/dietician/PatientMonitoring";
import { ProgramEdit } from "@views/dietician/ProgramEdit";
import { PatientProgramDays } from "@views/dietician/PatientProgramDays";
import {
  ClientProgramSchedule,
  ProgramSchedule,
} from "@views/dietician/ProgramSchedule";
import { ProgramDietsNew } from "@views/dietician/ProgramDietsNew";
import { Program } from "@views/dietician/Program";
import { PatientProgram } from "@views/dietician/PatientProgram";
import { PatientAddProgram } from "@views/dietician/PatientAddProgram";
import { Diet } from "@views/dietician/Diet";
import { Meal } from "@views/dietician/Meal";
import { MealCopy, MealEdit, MealNew } from "@views/dietician/MealEdit";
import { RecipesAndCollections } from "@views/dietician/Recipes";
import { Recipe, RecipeEdit, RecipeNew } from "@views/dietician/Recipe";
import { ProductsNew } from "@views/dietician/product-views/Products";
import { ProductNew } from "@views/dietician/product-views/ProductNew";
import { Product } from "@views/dietician/product-views/Product";
import { ProductEdit } from "@views/dietician/product-views/ProductEdit";
import { Sections } from "@views/dietician/Sections";
import { Section } from "@views/dietician/Section";
import { SectionNew } from "@views/dietician/SectionNew";
import { SectionEdit } from "@views/dietician/SectionEdit";
import { SectionCopy } from "@views/dietician/SectionCopy";
import { Facility } from "@views/dietician/Facility";
import { SettingsAccount } from "@views/dietician/SettingsAccount";
import { TestForm } from "@views/dev/TestForm";
import { TestSettings } from "@views/dev/TestSettings";
import { TestChartsPage } from "@views/dev/TestCharts";
import { Landing } from "@layouts/Landing";
import { Dashboard } from "@layouts/Dashboard";
import { ProgramLayout } from "@layouts/ProgramLayout";
import { PatientProgramLayout } from "@layouts/PatientProgramLayout";
import { SettingsLayout } from "@layouts/SettingsLayout";
import { DieticianSettingsLayout } from "@layouts/DieticianSettingsLayout";
import {
  ACTIVATION,
  AUTH,
  CLONE_PRODUCT,
  COPY_DIET,
  COPY_RECIPE,
  DASHBOARD,
  DIET,
  DIETICIAN_SETTINGS,
  DIETS,
  EDIT_DIET,
  EDIT_PRODUCT,
  EDIT_RECIPE,
  FACILITY,
  LINK_RESEND,
  LOGIN,
  MEAL,
  MEAL_COPY,
  MEAL_EDIT,
  MEAL_NEW,
  MEALS,
  NEW_DIET,
  NEW_PRODUCT,
  NEW_RECIPE,
  PATIENT_NEW_PROGRAM,
  PATIENT_PROGRAM,
  PATIENT_PROGRAM_DAY_DIET_EDIT,
  PATIENT_PROGRAM_DAY_DIET_NEW,
  PATIENT_PROGRAM_EDIT,
  PATIENT_PROGRAM_SCHEDULE,
  PATIENT_PROGRAM_SCHEDULE_RECIPE_EDIT,
  PATIENTS,
  PIN,
  PRODUCT,
  PRODUCTS,
  PROGRAM,
  PROGRAM_COPY,
  PROGRAM_DAYS_ALL,
  PROGRAM_DIET_EDIT,
  PROGRAM_DIET_NEW,
  PROGRAM_EDIT,
  PROGRAM_NEW,
  PROGRAM_SCHEDULE,
  PROGRAM_SCHEDULE_RECIPE_EDIT,
  PROGRAMS,
  PROGRAMS2,
  ProtectedRoute,
  PUBLIC_VISIT,
  RECIPE,
  RECIPES,
  REGISTER,
  REGISTER_PATIENT,
  RESET,
  RETRIEVE,
  SECTION,
  SECTION_COPY,
  SECTION_EDIT,
  SECTION_NEW,
  SECTIONS,
  SETTINGS,
  VISIT_CANCEL,
  VISIT_CONFIRM,
  PATIENT_PROGRAM_DAYS_ALL,
  CHAT,
  CHAT_MAIN,
  QUESTIONNAIRES,
  QUESTIONNAIRE_EDIT,
  QUESTIONNAIRE,
  INFO_AND_SERVICES_VIEW,
  APPOINTMENTS_VIEW,
  HEALTH_AND_GOALS_VIEW,
  ENERGY_BALANCE_VIEW,
  NUTRITIONAL_PROGRAMS_VIEW,
  PROGRESS_TRACKING_VIEW,
  CLIENT,
  PATIENT_ENERGY_BALANCE,
  CALENDAR,
  QUESTIONNAIRE_ANSWERS,
  REGISTER_CLINIC,
  EDIT_DIET_SETTINGS,
  EDIT_DIET_RECIPES,
  EDIT_DIET_PRODUCTS,
  EDIT_DIET_TAGS,
  PROGRAM_DIET_EDIT_SETTINGS,
  PROGRAM_DIET_EDIT_RECIPES,
  PROGRAM_DIET_EDIT_PRODUCTS,
  PROGRAM_DIET_EDIT_TAGS,
  PATIENT_MONITORING,
  PATIENT_PROGRAM_DAY_DIET_EDIT_SETTINGS,
  PATIENT_PROGRAM_DAY_DIET_EDIT_RECIPES,
  PATIENT_PROGRAM_DAY_DIET_EDIT_PRODUCTS,
  PATIENT_PROGRAM_DAY_DIET_EDIT_TAGS,
  CLIENT_PROGRAMS_NEW,
  PATIENT_CHOICE_PROGRAM,
  INTEGRATIONS,
  GOOGLE_CALLBACK,
  WORK_IN_PROGRESS,
  PRICING,
  REJESTRACJA,
} from "@routes";
import { ProgramNew } from "@views/dietician/ProgramNew";
import { PatientProgramEdit } from "views/dietician/PatientProgramEdit/PatientProgramEdit";
import { ScheduleRecipeEdit } from "@views/dietician/ScheduleRecipe";
import { Roles } from "@utils/settings";
import { ChatPage } from "@views/dietician/Chat";
import { ProductCopy } from "@views/dietician/product-views/ProductCopy";
import { RecipeCopy } from "@views/dietician/Recipe/RecipeCopy";
import { Error404 } from "@views/emptyStates/Error404/Error404";
import { QuestionnairesList } from "@views/dietician/Questionnaires";
import { TestEmpty } from "@views/dev/TestEmpty";
import { ErrorBoundaryEmptyState } from "@views/emptyStates/Error503";
import { Clients } from "@views/dietician/Clients";
import { QuestionnaireEdit } from "@views/dietician/Questionnaires/QuestionnaireEdit";
import { QuestionnaireLayout } from "@views/public/QuestionnaireAnswer/QuestionnaireLayout";
import { ClientLayout } from "@layouts/Dashboard/ClientLayout";
import { getCSRFToken } from "@utils/csrf";
import { Calendar } from "@views/dietician/Calendar";
import { ClientHealth } from "@views/dietician/ClientHealth";
import { PatientSurveyAnswer } from "@views/dietician/PatientSurveyModal";
import { TestFilters } from "@views/dev/TestFilters";
import { RegisterClinic } from "@views/public/RegisterClinic";
import { Login } from "@views/public/LoginNew";
import { Recovery } from "@views/public/Recovery";
import { ProgramsNew } from "@views/dietician/ProgramsNew";
import ProgramsView from "@views/dietician/Programs/ProgramsView";
import { default as PatientProgramsNew } from "@views/dietician/PatientProgramsNew/PatientProgramsView";
import { DietsNew } from "@views/dietician/DietsNew/DietsNew";
import { DietClone, DietCreator } from "@views/dietician/DietCreator";
import { SettingsTab } from "@views/dietician/DietCreator/components/SettingsTab";
import { RecipesTab } from "@views/dietician/DietCreator/components/RecipesTab";
import { ProductsTab } from "@views/dietician/DietCreator/components/ProductsTab";
import { TagsTab } from "@views/dietician/DietCreator/components/TagsTab";
import { PatientMonitoringNew } from "@views/dietician/PatientMonitoring2";
import { Integrations } from "@views/dietician/Integrations/Integrations";
import { CallbackPage } from "@views/dietician/Integrations";
import PatientProgramChoice from "@views/dietician/PatientProgramChoice/PatientProgramChoice";
import ClientHealthNew from "@views/dietician/ClientHealth/ClientHealthNew";
import NewMobileAppFeatureRoute from "@features/NewMobileAppFeatureRoute";
import { ErrorBoundaryRoute } from "@routes/ErrorBoundaryRoute";
import { Pricing } from "@views/dietician/Pricing";
import { isLocal } from "@utils/configEnv";
import TrashView from "@views/public/Develop/TrashView";

/**
 * Alloweat application wrapper
 */
export function App() {
  useEffect(() => {
    setInterval(() => getCSRFToken(), 300000);
  }, []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        {/** public access routes */}
        {isLocal() && <Route path={"/develop-trash"} element={<TrashView />} />}

        <Route
          path={WORK_IN_PROGRESS}
          element={<ErrorBoundaryRoute defaultOpen />}
        />
        <Route
          path={REJESTRACJA}
          element={
            <Landing authPage>
              <RegisterClinic />
            </Landing>
          }
        />
        <Route
          path={AUTH}
          element={
            <ErrorBoundaryRoute>
              <Landing authPage />
            </ErrorBoundaryRoute>
          }
        >
          <Route path={LOGIN} element={<Login />} />
          <Route path={RESET} element={<ResetWrappedInTheme />} />
          <Route path={ACTIVATION} element={<Activation />} />
          <Route path={LINK_RESEND} element={<LinkResend />} />
          <Route path={PIN} element={<Pin />} />
          <Route path={RETRIEVE} element={<Recovery />} />
          <Route path={REGISTER} element={<Register />} />
          <Route path={REGISTER_CLINIC} element={<RegisterClinic />} />
          <Route path={REGISTER_PATIENT} element={<RegisterPatient />} />
        </Route>
        <Route
          path={PUBLIC_VISIT}
          element={
            <ErrorBoundaryRoute>
              <Landing />
            </ErrorBoundaryRoute>
          }
        >
          <Route path={VISIT_CONFIRM} element={<VisitConfirm />} />
          <Route path={VISIT_CANCEL} element={<VisitCancel />} />
        </Route>

        <Route
          path={QUESTIONNAIRE}
          element={
            <ErrorBoundaryRoute>
              <QuestionnaireLayout />
            </ErrorBoundaryRoute>
          }
          errorElement={<ErrorBoundaryEmptyState />}
        />

        <Route
          element={
            <ErrorBoundaryRoute>
              <ProtectedRoute
                allowedUserTypes={[
                  Roles.DIETITIAN_OWNER,
                  Roles.DIETITIAN_WORKER,
                  Roles.DIETITIAN_ASSISTANT,
                ]}
              ></ProtectedRoute>
            </ErrorBoundaryRoute>
          }
          errorElement={<ErrorBoundaryEmptyState />}
        >
          <Route
            path={QUESTIONNAIRE_ANSWERS}
            element={<PatientSurveyAnswer />}
          />
        </Route>

        {/** dietician dashboard routes */}
        <Route
          element={
            <ErrorBoundaryRoute>
              <ProtectedRoute
                allowedUserTypes={[
                  Roles.DIETITIAN_OWNER,
                  Roles.DIETITIAN_WORKER,
                  Roles.DIETITIAN_ASSISTANT,
                ]}
              >
                <Dashboard />
              </ProtectedRoute>
            </ErrorBoundaryRoute>
          }
          errorElement={<ErrorBoundaryEmptyState />}
        >
          <Route path="*" element={<Error404 />} />
          <Route path={DASHBOARD} element={<Home />} />

          <Route path={CALENDAR} element={<Calendar />} />

          <Route path={PATIENTS} element={<Clients />} />

          <Route path={CHAT_MAIN} element={<ChatPage />} />
          <Route path={CHAT} element={<ChatPage />} />

          <Route path={CLIENT} element={<ClientLayout />}>
            <Route
              path={HEALTH_AND_GOALS_VIEW}
              element={
                <NewMobileAppFeatureRoute
                  oldVersion={<ClientHealth />}
                  newVersion={<ClientHealthNew />}
                />
              }
            />
            <Route path={INFO_AND_SERVICES_VIEW} element={<ClientProfile />} />
            <Route path={APPOINTMENTS_VIEW} element={<PatientVisits />} />
            <Route
              path={ENERGY_BALANCE_VIEW}
              element={<PatientEnergyBalance />}
            />
            <Route
              path={CLIENT_PROGRAMS_NEW}
              element={<PatientProgramsNew />}
            />
            <Route
              path={PROGRESS_TRACKING_VIEW}
              element={
                <NewMobileAppFeatureRoute
                  oldVersion={<PatientMonitoring />}
                  newVersion={null}
                />
              }
            />
            <Route
              path={PATIENT_MONITORING}
              element={
                <NewMobileAppFeatureRoute
                  oldVersion={null}
                  newVersion={<PatientMonitoringNew />}
                />
              }
            />
            <Route path={PATIENT_PROGRAM} element={<PatientProgram />} />
          </Route>
          <Route
            path={PATIENT_CHOICE_PROGRAM}
            element={<PatientProgramChoice />}
          />
          <Route element={<ProgramLayout />}>
            <Route path={PROGRAM_NEW} element={<ProgramNew />} />
            <Route path={PROGRAM_EDIT} element={<ProgramEdit />} />
            <Route path={PROGRAM_COPY} element={<ProgramEdit copy />} />
            <Route path={PROGRAM_DAYS_ALL} element={<PatientProgramDays />} />
            <Route path={PROGRAM_SCHEDULE} element={<ProgramSchedule />} />
            <Route path={PROGRAM_DIET_NEW} element={<ProgramDietsNew />} />

            <Route path={PROGRAM} element={<Program />} />
          </Route>

          <Route element={<PatientProgramLayout />}>
            <Route
              path={PATIENT_PROGRAM_DAY_DIET_NEW}
              element={<ProgramDietsNew />}
            />
            {/* <Route path={PATIENT_PROGRAM} element={<PatientProgram />} /> */}
            <Route
              path={PATIENT_PROGRAM_DAYS_ALL}
              element={<PatientProgramDays />}
            />
            <Route
              path={PATIENT_ENERGY_BALANCE}
              element={<PatientEnergyBalance />}
            />
            <Route
              path={PATIENT_PROGRAM_DAY_DIET_EDIT}
              element={<DietCreator />}
            >
              <Route
                path={PATIENT_PROGRAM_DAY_DIET_EDIT_SETTINGS}
                element={<SettingsTab />}
              />
              <Route
                path={PATIENT_PROGRAM_DAY_DIET_EDIT_RECIPES}
                element={<RecipesTab />}
              />
              <Route
                path={PATIENT_PROGRAM_DAY_DIET_EDIT_PRODUCTS}
                element={<ProductsTab />}
              />
              <Route
                path={PATIENT_PROGRAM_DAY_DIET_EDIT_TAGS}
                element={<TagsTab />}
              />
            </Route>
            <Route
              path={PATIENT_PROGRAM_EDIT}
              element={<PatientProgramEdit />}
            />
            <Route path={PATIENT_NEW_PROGRAM} element={<PatientAddProgram />} />
            <Route
              path={PATIENT_PROGRAM_SCHEDULE}
              element={<ClientProgramSchedule />}
            />
            <Route
              path={PATIENT_PROGRAM_SCHEDULE_RECIPE_EDIT}
              element={<ScheduleRecipeEdit />}
            />
          </Route>

          <Route path={DIETS} element={<DietsNew />} />

          <Route path={EDIT_DIET} element={<DietCreator />}>
            <Route path={EDIT_DIET_SETTINGS} element={<SettingsTab />} />
            <Route path={EDIT_DIET_RECIPES} element={<RecipesTab />} />
            <Route path={EDIT_DIET_PRODUCTS} element={<ProductsTab />} />
            <Route path={EDIT_DIET_TAGS} element={<TagsTab />} />
          </Route>

          <Route path={COPY_DIET} element={<DietClone />} />

          <Route path={DIET} element={<Diet />} />

          <Route path={MEAL} element={<Meal />} />
          <Route path={MEALS} element={<RecipesAndCollections collections />} />
          <Route path={MEAL_NEW} element={<MealNew />} />
          <Route path={MEAL_EDIT} element={<MealEdit />} />
          <Route path={MEAL_COPY} element={<MealCopy />} />

          <Route path={RECIPES} element={<RecipesAndCollections />} />
          <Route path={RECIPE} element={<Recipe />} />
          <Route path={NEW_RECIPE} element={<RecipeNew />} />
          <Route path={EDIT_RECIPE} element={<RecipeEdit />} />
          <Route path={COPY_RECIPE} element={<RecipeCopy />} />

          <Route path={PRODUCTS} element={<ProductsNew />} />
          <Route path={NEW_PRODUCT} element={<ProductNew />} />
          <Route path={PRODUCT} element={<Product />} />
          <Route path={EDIT_PRODUCT} element={<ProductEdit />} />
          <Route path={CLONE_PRODUCT} element={<ProductCopy />} />

          <Route path={PROGRAMS} element={<ProgramsView />} />
          <Route path={PROGRAMS2} element={<ProgramsNew />} />
          <Route
            path={PROGRAM_SCHEDULE_RECIPE_EDIT}
            element={<ScheduleRecipeEdit />}
          />

          <Route path={PROGRAM_DIET_EDIT} element={<DietCreator />}>
            <Route
              path={PROGRAM_DIET_EDIT_SETTINGS}
              element={<SettingsTab />}
            />
            <Route path={PROGRAM_DIET_EDIT_RECIPES} element={<RecipesTab />} />
            <Route
              path={PROGRAM_DIET_EDIT_PRODUCTS}
              element={<ProductsTab />}
            />
            <Route path={PROGRAM_DIET_EDIT_TAGS} element={<TagsTab />} />
          </Route>

          <Route path={SECTIONS} element={<Sections />} />
          <Route path={SECTION} element={<Section />} />
          <Route path={SECTION_NEW} element={<SectionNew />} />
          <Route path={SECTION_EDIT} element={<SectionEdit />} />
          <Route path={SECTION_COPY} element={<SectionCopy />} />

          <Route path={FACILITY} element={<Facility />} />

          <Route element={<SettingsLayout />}>
            <Route path={SETTINGS} element={<SettingsAccount />} />
          </Route>

          <Route
            path={DIETICIAN_SETTINGS}
            element={<DieticianSettingsLayout />}
          />

          <Route path={QUESTIONNAIRES} element={<QuestionnairesList />} />
          <Route path={QUESTIONNAIRE_EDIT} element={<QuestionnaireEdit />} />

          {import.meta.env.VITE_APP_ENVIRONMENT !== "prod" && (
            <Route path="/test">
              <Route path="/test/form" element={<TestForm />} />
              <Route path="/test/settings" element={<TestSettings />} />
              <Route path="/test/it-332" element={<TestChartsPage />} />
              <Route path="/test/empty" element={<TestEmpty />} />
              <Route path="/test/filters" element={<TestFilters />} />
            </Route>
          )}
          <Route path={INTEGRATIONS} element={<Integrations />} />
          <Route path={GOOGLE_CALLBACK} element={<CallbackPage />} />
        </Route>
        <Route
          element={
            <ErrorBoundaryRoute>
              <ProtectedRoute allowedUserTypes={[Roles.DIETITIAN_OWNER]}>
                <Dashboard />
              </ProtectedRoute>
            </ErrorBoundaryRoute>
          }
          errorElement={<ErrorBoundaryEmptyState />}
        >
          <Route
            path={DIETICIAN_SETTINGS}
            element={<DieticianSettingsLayout />}
          />
          <Route path={PRICING} element={<Pricing />} />
        </Route>
      </>,
    ),
  );

  return <RouterProvider router={router} />;
}

export default App;
