import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const UploadFile = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M13 7.41501V14.9998C13 15.5529 12.5531 15.9998 12 15.9998C11.4469 15.9998 11 15.5529 11 14.9998V7.41501L8.70625 9.70887C8.31563 10.0995 7.68125 10.0995 7.29063 9.70887C6.9 9.31823 6.9 8.68382 7.29063 8.29318L11.2906 4.29298C11.6813 3.90234 12.3156 3.90234 12.7063 4.29298L16.7063 8.29318C17.0969 8.68382 17.0969 9.31823 16.7063 9.70887C16.3156 10.0995 15.6812 10.0995 15.2906 9.70887L13 7.41501ZM6 14.9998H10C10 16.1029 10.8969 16.9999 12 16.9999C13.1031 16.9999 14 16.1029 14 14.9998H18C19.1031 14.9998 20 15.8967 20 16.9999V17.9999C20 19.1031 19.1031 20 18 20H6C4.89688 20 4 19.1031 4 17.9999V16.9999C4 15.8967 4.89688 14.9998 6 14.9998ZM17.5 18.2499C17.6989 18.2499 17.8897 18.1709 18.0303 18.0302C18.171 17.8896 18.25 17.6988 18.25 17.4999C18.25 17.301 18.171 17.1102 18.0303 16.9695C17.8897 16.8289 17.6989 16.7498 17.5 16.7498C17.3011 16.7498 17.1103 16.8289 16.9697 16.9695C16.829 17.1102 16.75 17.301 16.75 17.4999C16.75 17.6988 16.829 17.8896 16.9697 18.0302C17.1103 18.1709 17.3011 18.2499 17.5 18.2499Z"
      fill="#7448D0"
    />
  </SvgIcon>
);
