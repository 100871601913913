import { useClientParams, useResizeObserver } from "@hooks";
import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";
import { useFetchClientQuery } from "@hooks/queries";

import {
  ClientHealthWrapper,
  HealthSectionsWrapper,
} from "./ClientHealth.styled";
import { ClientSexDto, FetchClientResponse } from "@client";
import { useRef } from "react";
import { HistorySection } from "../ClientProfile/components/HistorySection";
import { HealthSection } from "./components/HealthSection";
import { GoalsSection } from "./components/GoalsSection";
import { AdditionalOptions } from "@components/EditHealthStatusModal";
import { FoodAndAllergensSection } from "./components/FoodAndAllergensSection";

export const noData = "-";

export interface ProfileSectionProps {
  account: FetchClientResponse;
}

export const ClientHealth = () => {
  const id = useClientParams();
  const { client, isLoading, isError } = useFetchClientQuery(id);
  const infoSectionRef = useRef<HTMLDivElement>(null);
  const clientHeight = useResizeObserver(infoSectionRef);
  const isWoman = client?.profile.sex === ClientSexDto.W;
  const additionalInfo =
    client?.profile.additionalFeatures[0]?.id ?? Number(AdditionalOptions.NONE);

  if (isLoading) return <Spinner />;

  if (isError || !client) return <ApiError />;

  return (
    <ClientHealthWrapper>
      <HealthSectionsWrapper>
        {isWoman && <HealthSection additionalInfo={additionalInfo} />}
        <FoodAndAllergensSection />
      </HealthSectionsWrapper>
      <HealthSectionsWrapper>
        <GoalsSection
          infoSectionRef={infoSectionRef}
          account={client}
          id={id}
        />
      </HealthSectionsWrapper>
      <HistorySection id={id} maxHeight={clientHeight} />
    </ClientHealthWrapper>
  );
};
