import { useContext } from "react";

import {
  FormAutocomplete,
  FormAutocompleteProps,
} from "@components/FormAutocomplete";

import { BlurContext } from "./BlurContext";

export const FormAutocompleteWrapper = (props: FormAutocompleteProps) => {
  const { handleBlur, handleFocus } = useContext(BlurContext);
  return (
    <FormAutocomplete {...props} onBlur={handleBlur} onFocus={handleFocus} />
  );
};
