import { Fragment, useEffect, useMemo, useState } from "react";
import { ReactSortable } from "react-sortablejs";

import { CircularProgress, ClickAwayListener } from "@mui/material";

import { Carrot } from "@assets/icons/DesignSystem";
import {
  useAppParams,
  useAppTranslation,
  useMealClientContext,
  useModalState,
} from "@hooks";
import { useTagsNew } from "@hooks/useTagsNew";
import { getTranslation } from "@utils/translations";
import { detectMovedElement } from "@views/dietician/product-views/components/ProductMeasures/utils/move";

import { ListTitle } from "./MealRow.styled";
import { DeleteMealElementModal } from "./components/Modals";
import { DraggableItem } from "./components/DraggableItem";
import { useSpecialTags, useMealData } from "./hooks";
import {
  RecipeEditDrawer,
  RecipeId,
} from "../../RecipeDrawer/RecipeEditDrawer";
import { RecipePreview } from "@components/PreviewDrawer/Recipe";
import { RecipePreviewDrawer } from "../../RecipeDrawer/RecipePreviewDrawer/RecipePreviewDrawer";
import { isEqual } from "lodash";
import {
  ProductEditDrawer,
  ProductId,
} from "../../ProductDrawer/ProductEditDrawer";
import { CopyRecipeModal } from "../CopyRecipeModal/CopyRecipeModal";
import { string } from "yup";
import { Nutrient } from "@typeDefinitions";
import {
  usePostPatientProductsContextMutation,
  usePostPatientRecipesContextMutation,
} from "@hooks/queries";
import { Scales } from "@assets/icons";

interface MealRowDetailsProps {
  mealId: string;
}
export const MealRowDetails = ({ mealId }: MealRowDetailsProps) => {
  const { currentLanguage, isPolishChosen, t } = useAppTranslation();
  const { dietId } = useAppParams();
  const [modalVariant, setModalVariant] = useState<"product" | "recipe">(
    "product",
  );
  const [open, onOpen, onClose] = useModalState();
  const [openPreview, onOpenPreview, onClosePreview] = useModalState();
  const [openEditRecipe, onOpenEditRecipe, onCloseEditRecipe] = useModalState();
  const [openEditProduct, onOpenEditProduct, onCloseEditProduct] =
    useModalState();
  const [openCopyRecipe, onOpenCopyRecipe, onCloseCopyRecipe] = useModalState();
  const [deleteId, setDeleteId] = useState({ product: "", recipe: "" });
  const [selectedRecipeIds, setSelectedRecipeIds] = useState<RecipeId>({
    dietRecipeId: "",
    recipeId: "",
  });
  const [recipeToCopy, setRecipeToCopy] = useState({
    id: 0,
    name: "",
    servings: 1,
    nutrients: [] as Nutrient[],
    tags: [] as number[],
  });
  const [selectedProductIds, setSelectedProductIds] = useState({
    dietProductId: "",
    productId: "",
  });
  const { tagCategoryDictionary, tagNameDictionary } = useTagsNew();
  const { mutate: fetchProducts } = usePostPatientProductsContextMutation();
  const { mutate: fetchRecipes } = usePostPatientRecipesContextMutation();

  const { recipes, products, isLoading, updateRecipes, updateProducts } =
    useMealData(mealId, dietId);

  const recipesIds = useMemo(() => recipes.map(r => r.recipe.id), [recipes]);
  const productsIds = useMemo(
    () => products.map(p => p.product.id),
    [products],
  );
  const { productsClientContextMap, recipesClientContextMap } =
    useMealClientContext();

  const getRecipeSpecialTags = useSpecialTags(
    tagCategoryDictionary,
    tagNameDictionary,
    t,
  );

  const handleOpenDeleteModal = (variant: "product" | "recipe", id: string) => {
    setModalVariant(variant);
    setDeleteId(ids => ({ ...ids, [variant]: id }));
    onOpen();
  };

  const handleOpenRecipePreview =
    (recipeId: number, dietRecipeId: number) => () => {
      const alreadySelected = isEqual(
        {
          recipeId: recipeId.toString(),
          dietRecipeId: dietRecipeId.toString(),
        },
        selectedRecipeIds,
      );

      if (openEditRecipe) return;

      if (alreadySelected) {
        setSelectedRecipeIds({
          dietRecipeId: "",
          recipeId: "",
        });
        onClosePreview();

        return;
      }
      setSelectedRecipeIds({
        recipeId: recipeId.toString(),
        dietRecipeId: dietRecipeId.toString(),
      });
      onCloseEditRecipe();
      onOpenPreview();
    };

  const handleOpenProductEdit =
    (productId: number, dietProductId: number) => () => {
      setSelectedProductIds({
        productId: productId.toString(),
        dietProductId: dietProductId.toString(),
      });
      onOpenEditProduct();
    };

  useEffect(() => {
    if (productsIds.length) fetchProducts({ payload: productsIds });
  }, [products.length]);

  useEffect(() => {
    if (recipesIds.length) fetchRecipes({ payload: recipesIds });
  }, [recipes.length]);

  if (isLoading)
    return (
      <div className="grid place-items-center">
        <CircularProgress />
      </div>
    );

  return (
    <ClickAwayListener onClickAway={onClosePreview}>
      <div className="grid gap-[16px]">
        {!!recipes.length && (
          <div className="grid gap-[8px]">
            <ListTitle>
              {t("common.recipes")} ({recipes.length})
            </ListTitle>

            <ReactSortable
              forceFallback={false}
              scrollSensitivity={100}
              animation={200}
              handle=".dragHandle"
              list={recipes}
              setList={newOrder => {
                const result = detectMovedElement(
                  recipes.map(r => r.id.toString()),
                  newOrder.map(n => n.id.toString()),
                );

                if (result)
                  updateRecipes({
                    dietId,
                    mealId,
                    payload: { ids: newOrder.map(g => g.id) },
                  });
              }}
              className="grid gap-[8px]"
            >
              {recipes.map(r => {
                const tagWithIcons = getRecipeSpecialTags(
                  r.recipe.tags.map(t => t.id),
                  r.recipe.servings,
                  r.servings,
                );

                return (
                  <DraggableItem
                    key={r.id}
                    name={getTranslation(
                      r.recipe.translations,
                      currentLanguage,
                    )}
                    nutrients={r.nutrients}
                    tags={r.recipe.tags.map(t => t.id)}
                    tagsWithIcons={tagWithIcons}
                    onDelete={() =>
                      handleOpenDeleteModal("recipe", r.id.toString())
                    }
                    imgUrl={r.recipe.image?.url}
                    onEdit={() => {
                      setSelectedRecipeIds({
                        recipeId: r.recipe.id.toString(),
                        dietRecipeId: r.id.toString(),
                      });
                      onClosePreview();
                      onOpenEditRecipe();
                    }}
                    onClick={handleOpenRecipePreview(r.recipe.id, r.id)}
                    clientContext={recipesClientContextMap.get(r.recipe.id)}
                    onCopy={() => {
                      onOpenCopyRecipe();
                      setRecipeToCopy({
                        id: r.recipe.id,
                        name: getTranslation(
                          r.recipe.translations,
                          currentLanguage,
                        ),
                        nutrients: r.nutrients,
                        tags: r.recipe.tags.map(t => t.id),
                        servings: r.recipe.servings,
                      });
                    }}
                  />
                );
              })}
            </ReactSortable>
          </div>
        )}

        {!!products.length && (
          <div className="grid gap-[8px]">
            <ListTitle>
              {t("common.products")} ({products.length})
            </ListTitle>

            <ReactSortable
              forceFallback={false}
              scrollSensitivity={100}
              animation={200}
              handle=".dragHandle"
              list={products}
              setList={newOrder => {
                const result = detectMovedElement(
                  products.map(p => p.id.toString()),
                  newOrder.map(n => n.id.toString()),
                );

                if (result)
                  updateProducts({
                    dietId,
                    mealId,
                    payload: { ids: newOrder.map(g => g.id) },
                  });
              }}
              className="grid gap-[8px]"
            >
              {products.map(p => {
                const { description, descriptionEn } = p.product.category;
                return (
                  <DraggableItem
                    key={p.id}
                    name={getTranslation(
                      p.product.translations,
                      currentLanguage,
                    )}
                    nutrients={p.nutrients}
                    tags={p.product.tags.map(t => t.id)}
                    tagsWithIcons={[
                      {
                        Icon: Carrot,
                        id: "1",
                        label: isPolishChosen ? description : descriptionEn,
                      },
                      {
                        Icon: Scales,
                        id: "1",
                        label: `${p.grams}g`,
                      },
                    ]}
                    onDelete={() =>
                      handleOpenDeleteModal("product", p.id.toString())
                    }
                    onEdit={handleOpenProductEdit(p.product.id, p.id)}
                    onClick={handleOpenProductEdit(p.product.id, p.id)}
                    imgUrl={p.product.image?.url}
                    clientContext={productsClientContextMap.get(p.product.id)}
                  />
                );
              })}
            </ReactSortable>
          </div>
        )}

        <DeleteMealElementModal
          open={open}
          onClose={onClose}
          ids={deleteId}
          mealId={mealId}
          variant={modalVariant}
        />

        <RecipeEditDrawer
          open={openEditRecipe}
          onClose={onCloseEditRecipe}
          mealId={mealId}
          recipeIds={selectedRecipeIds}
        />
        <ProductEditDrawer
          open={openEditProduct}
          onClose={onCloseEditProduct}
          mealId={mealId}
          productIds={selectedProductIds}
        />

        <RecipePreviewDrawer
          open={openPreview}
          onClose={onClosePreview}
          onEdit={() => {
            onClosePreview();
            onOpenEditRecipe();
          }}
          recipeId={selectedRecipeIds.recipeId}
        />

        <CopyRecipeModal
          open={openCopyRecipe}
          onClose={onCloseCopyRecipe}
          recipe={recipeToCopy}
        />
      </div>
    </ClickAwayListener>
  );
};
