import { IconButton, TextField, styled } from "@mui/material";

import { FormTextField } from "@components/FormTextField";

export const TextFieldStyled = styled(TextField)`
  pointer-events: none;
  opacity: 0.5;
  & input {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }
`;

export const FormTextFieldStyled = styled(FormTextField)`
  & input {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }
`;

export const TrashButton = styled(IconButton)`
  &.MuiButtonBase-root {
    background: ${({ theme }) => theme.colors.neutral.light[100]};
    border: 1px solid ${({ theme }) => theme.palette.primary.medium};
    height: 40px;
    width: 40px;
  }
`;

export const ModalText = styled("span")`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;
export const ModalTextBold = styled("strong")`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const SelectedNutrientWrapper = styled("div")`
  display: flex;
  gap: 8px;
  width: 508px;
  align-items: flex-end;
`;

export const DeleteModalWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  max-width: 478px;
`;
