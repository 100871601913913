import { styled } from "@mui/material";

export const ChartEmpty = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 1rem;
  width: 8rem;
  background: ${({ theme }) => theme.colors.borderGray};
  border-radius: 1rem;
`;

interface ChartFullProps {
  width: string;
  background: string;
}

export const ChartFull = styled("div")<ChartFullProps>`
  background: ${({ background }) => background};
  border-radius: 1rem;
  height: 1rem;
  width: ${({ width }) => width};
`;

export const ItemWithChartWrapper = styled("div")`
  display: grid;
  background: ${({ theme }) => theme.colors.gray200};
  border-radius: 0.25rem;
  padding: 0.375rem 1rem;
`;

export const NutrientItemValueWrapper = styled("div")`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`;
