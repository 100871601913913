import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const updateSurveySubgroupQuestionsSort = async (
  subgroupId: string,
  payload: UpdateSurveySubgroupQuestionSortPaylod,
) => {
  await fetchData(
    `/dietitian/survey-sections/${subgroupId}/questions-sort`,
    APIMethods.PUT,
    payload,
  );
};

export interface UpdateSurveySubgroupQuestionSortPaylod {
  ids: number[];
}
