import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "../types";
import { fetchDietitianProgramScheduleKey } from "./useFetchDietitianProgramScheduleQuery";
import { fetchDietitianClientProgramScheduleKey } from "./useFetchDietitianClientProgramScheduleQuery";
import {
  CreateProgramDayMealPayload,
  createProgramDayMeal,
} from "@client/schedule";

export const useCreateProgramDayMealMutation = (
  programId: number,
  dayId: number,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CreateProgramDayMealPayload) =>
      createProgramDayMeal(programId, dayId, payload),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries([fetchDietitianProgramScheduleKey]);
        queryClient.invalidateQueries([fetchDietitianClientProgramScheduleKey]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};
