import { CountLabel, Pagination } from "@components/PaginationNew";

import { FiltersNoResults } from "@views/emptyStates/FiltersNoResults";
import { DietTile } from "./DietTile";
import {
  CardStyled,
  GridWrapper,
  PaginationWrapper,
} from "./DietsGridLayout.styled";
import { DietRowIf } from "@components/DietsDataGrid";

interface DietsGridLayoutProps {
  diets: DietRowIf[];
  onPageChange?: (page: number) => void;
  page: number;
  perPage: number;
  setPerPage: (value: number) => void;
  isOnePage: boolean;
  rowCount?: number;
  loading?: boolean;
  onDietSelect: (id: number) => void;
  resetFilters: () => void;
  isLoading: boolean;
}

export const DietsGridLayout = ({
  diets,
  onPageChange,
  page,
  perPage,
  setPerPage,
  isOnePage,
  rowCount,
  onDietSelect,
  resetFilters,
  isLoading,
}: DietsGridLayoutProps) => {
  return (
    <div className="grid gap-[7px]">
      {!diets.length && !isLoading && (
        <CardStyled>
          <FiltersNoResults onClick={resetFilters} />
        </CardStyled>
      )}
      {!!diets.length && (
        <GridWrapper>
          {diets.map((r, idx) => (
            <DietTile
              {...r}
              key={`${idx}-${r.id}`}
              onSelect={() => onDietSelect(r.id)}
            />
          ))}
        </GridWrapper>
      )}

      <PaginationWrapper>
        <CountLabel count={rowCount ?? 1} page={page} perPage={perPage} />
        <div className="flex items-center">
          <Pagination
            isOnePage={isOnePage}
            page={page}
            onPageChange={(_, newPage) => onPageChange && onPageChange(newPage)}
            count={rowCount}
            perPage={perPage}
            setPerPage={v => setPerPage(v as number)}
            rowsPerPageOptions={[10, 25]}
          />
        </div>
      </PaginationWrapper>
    </div>
  );
};
