import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const SoupBowl = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="14" viewBox="0 0 14 14">
      <path
        d="M13.3045 0.782522C13.0252 0.460078 12.5479 0.406761 12.2051 0.658115L6.90605 4.56298H10.5877L13.2613 1.88695C13.5609 1.58736 13.5813 1.10497 13.302 0.782522H13.3045ZM1.3125 5.37544C0.863086 5.37544 0.5 5.7385 0.5 6.18789C0.5 6.63728 0.863086 7.00035 1.3125 7.00035C1.3125 9.09496 2.41445 10.7503 4.4584 11.4739C4.17656 11.8268 3.96582 12.2432 3.85156 12.6952C3.73984 13.1293 4.11309 13.5 4.5625 13.5H9.4375C9.88691 13.5 10.2602 13.1293 10.151 12.6952C10.0367 12.2432 9.82598 11.8294 9.54414 11.4739C11.5855 10.7503 12.6875 9.09496 12.6875 7.00035C13.1369 7.00035 13.5 6.63728 13.5 6.18789C13.5 5.7385 13.1369 5.37544 12.6875 5.37544H1.3125Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
