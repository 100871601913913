import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "../types";
import { UpdateSurveyGroupRequest, updateSurveyGroup } from "@client/surveys";
import { fetchSurveyFullQueryKey } from "./useFetchSurveyFullQuery";

export const useUpdateSurveyGroupMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, UpdateSurveyGroupMutationPayload>(
    ({ id, payload }) => updateSurveyGroup(id, payload),
    {
      ...options,
      onSuccess: (data, variables) => {
        options?.onSuccess && options.onSuccess(data, variables);

        queryClient.invalidateQueries([fetchSurveyFullQueryKey]);
      },
    },
  );
};

interface UpdateSurveyGroupMutationPayload {
  id: string;
  payload: UpdateSurveyGroupRequest;
}
