import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MutationOptions } from "./types";
import { searchScheduleEventsQueryKey } from "./useSearchScheduleEvents";
import { deleteScheduleEvent } from "@client";
import { useAppTranslation } from "@hooks";
import { toast } from "react-toastify";

export const useDeleteScheduleEvent = (options?: MutationOptions) => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();

  return useMutation((id: number) => deleteScheduleEvent(id), {
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries([searchScheduleEventsQueryKey]);
      options?.onSuccess && options.onSuccess();

      toast.success(t("visits.deleted_confirmation"));
    },
  });
};
