import React, { useMemo } from "react";

import { FoodMeasure } from "@typeDefinitions";
import { useAppTranslation } from "@hooks";
import { useFoodMeasuresQuery } from "@hooks/queries/dictionaries";
import { Input } from "@components/Input";

import { ProductMeasureSchema } from "./ProductMeasureSchema";

export const ProductMeasureShow: React.FC<{ id: number; grams: number }> = ({
  id,
  grams,
}) => {
  const { isPolishChosen } = useAppTranslation();
  const { data } = useFoodMeasuresQuery();

  const measure: FoodMeasure | undefined = useMemo(
    () => (data ?? []).find(item => item.id === id),
    [data, id],
  );

  return (
    <ProductMeasureSchema
      measure={
        <Input
          fullWidth
          readOnly
          name="measureId"
          value={
            isPolishChosen ? measure?.description : measure?.descriptionEn ?? ""
          }
        />
      }
      grams={<Input fullWidth readOnly name="grams" value={grams} />}
    />
  );
};
