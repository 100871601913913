import * as yup from "yup";

export interface MeasurementPhotoResource {
  id: number;
  url: string;
  type: {
    id: number;
  };
  name: string | null;
  media: {
    originalName: string;
  };
}

export const measurementPhotoSchema = yup.object({
  id: yup.number().required(),
  url: yup.string().required(),
  type: yup
    .object({
      id: yup.number().required(),
    })
    .required(),
  name: yup.string().defined().nullable(),
  media: yup
    .object({
      originalName: yup.string().defined(),
    })
    .defined(),
});
