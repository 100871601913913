import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const ArrowRight = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <rect
      id="icon_20x20_odstep_18px"
      data-name="icon 20x20 / odstep 18px"
      width="20"
      height="20"
      fill="none"
    />
    <path
      id="Stroke_1"
      data-name="Stroke 1"
      d="M11,0,5.5,5.5,0,0"
      transform="translate(7.5 15.5) rotate(-90)"
      fill="none"
      stroke="#801fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </SvgIcon>
);
