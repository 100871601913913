import dayjs from "dayjs";

export function getLatestDate(
  dateString: string | Date | undefined | null,
): Date {
  const inputDate = dayjs(dateString);
  const currentDate = dayjs();

  const latestDate = inputDate.isAfter(currentDate) ? inputDate : currentDate;

  return latestDate.toDate();
}

export function getDateNextYear(dateString: Date): Date {
  const inputDate = dayjs(dateString);

  const nextYearDate = inputDate.add(365, "days");

  return nextYearDate.toDate();
}

export function isDateMoreThanOneYearLater(
  dateString: string | Date | null | undefined,
  compateTo?: string | Date | null,
): boolean {
  const inputDate = dayjs(dateString);
  const oneYearFromToday = dayjs(compateTo).add(365, "day");

  return dateString ? inputDate.isAfter(oneYearFromToday) : false;
}
