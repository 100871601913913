import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { FetchDietResponse, FoodRecipeDto, MealPatternDto } from "@client";
import { RecipeDto } from "@client/schedule";

export interface Product {
  id: number;
  description?: string;
  descriptionPl: string;
  measures: {
    id: number;
    grams: number;
    description: string;
    descriptionEn: string;
    sortOrder: number;
  }[];
  nutrients: {
    id: number;
    value: number;
  }[];
}

export type RecipeProductsDict = Record<number, Product>;
export type GetProductFunc = (id?: number) => Product | null;

export interface RecipeProductsContextIf {
  getProduct: GetProductFunc;
  addProduct: (product: Product) => void;
  hasProducts: boolean;
}

export const recipeProductsContextDefault: RecipeProductsContextIf = {
  getProduct: () => null,
  addProduct: () => null,
  hasProducts: false,
};

export const RecipeProductsContext = createContext<RecipeProductsContextIf>(
  recipeProductsContextDefault,
);

interface RecipeProductsContextProviderProps {
  recipe?: RecipeDto;
  mealPattern?: MealPatternDto;
  diet?: FetchDietResponse;
  children: ReactNode;
}

export const RecipeProductsContextProvider = ({
  recipe,
  mealPattern,
  diet,
  children,
}: RecipeProductsContextProviderProps) => {
  const [products, setProducts] = useState<RecipeProductsDict>({});

  useEffect(() => {
    if (recipe?.foodRecipe) {
      setProducts(products => ({
        ...products,
        ...recipeDtoToProductsDict(recipe.foodRecipe),
      }));
    }

    if (mealPattern) {
      setProducts(products => ({
        ...products,
        ...mealDtoToProductsDict(mealPattern),
      }));
    }

    if (diet) {
      setProducts(products => ({
        ...products,
        ...dietDtoToProductsDict(diet),
      }));
    }
  }, [recipe?.foodRecipe, mealPattern?.recipes, diet]);

  const addProduct = useCallback(
    (product: Product) =>
      setProducts(products => ({ ...products, [product.id]: product })),
    [],
  );

  const getProduct = useCallback(
    (id?: number) => (id ? products[id] : null),
    [products],
  );

  const context = useMemo(
    () => ({
      getProduct,
      addProduct,
      hasProducts: !!Object.keys(products).length,
    }),
    [getProduct, addProduct, products],
  );

  return (
    <RecipeProductsContext.Provider value={context}>
      {children}
    </RecipeProductsContext.Provider>
  );
};

function dietDtoToProductsDict(diet: FetchDietResponse): RecipeProductsDict {
  return Object.fromEntries([
    ...diet.meals.flatMap(meal => [
      ...meal.recipes.flatMap(recipe =>
        recipe.recipe.foodRecipe.map(foodRecipe => [
          foodRecipe.food.id,
          foodRecipe.food,
        ]),
      ),
      ...meal.food.map(food => [
        food.id,
        { ...food, descriptionPl: food.description },
      ]),
    ]),
  ]);
}

function mealDtoToProductsDict(meal: MealPatternDto): RecipeProductsDict {
  return Object.fromEntries([
    ...meal.recipes.flatMap(recipe =>
      recipe.recipe.foodRecipe.map(foodRecipe => [
        foodRecipe.food.id,
        foodRecipe.food,
      ]),
    ),
    ...meal.food.map(food => [
      food.id,
      { ...food, descriptionPl: food.description },
    ]),
  ]);
}

function recipeDtoToProductsDict(foodRecipe: FoodRecipeDto[]) {
  return Object.fromEntries(
    foodRecipe.map(foodRecipe => [foodRecipe.food.id, foodRecipe.food]),
  );
}
