import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import { AddressDto, PhoneDto, addressSchema, phoneSchema } from "./common";
import * as yup from "yup";
import { isArray } from "lodash";

export const fetchProgramPdfFacility = async (
  programId: string,
): Promise<ApiResponse<FacilityDetailsDto | null>> => {
  const response = await fetchData(
    `/dietitian/programs/${programId}/pdf-facility`,
    APIMethods.GET,
  );

  return await apiResponseSchema.validate(response);
};

export interface FacilityDetailsDto {
  id: number;
  name: string;
  email: string;
  isMain: boolean;
  phone: PhoneDto;
  address: AddressDto;
  quantity: QuantityDto;
}

interface QuantityDto {
  patients: number | null;
  dietitians: number | null;
}

const quantitySchema = yup.object().shape({
  patients: yup.number().nullable().default(null),
  dietitians: yup.number().nullable().default(null),
});

const facilityDetailsSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  email: yup.string().required(),
  isMain: yup.boolean().required(),
  phone: phoneSchema.required(),
  address: addressSchema.required(),
  quantity: quantitySchema.required(),
});

const apiResponseSchema = yup.object().shape({
  data: facilityDetailsSchema
    .nullable()
    .default(null)
    .transform(curr => (isArray(curr) && !curr.length ? null : curr)),
});
