import { ReactNode } from "react";

import { Nutrient } from "@typeDefinitions";
import { useVisibleNutrients } from "@context/VisibleNutrientsContext";
import { MACROS_ARRAY } from "@utils/macros";

import { MicrosRow } from "./MicrosRow";
import {
  StickySummaryCell,
  StyledTableCell,
  StyledTableFooterCell,
  TableRowSummaryStyled,
} from "./RecipeTableForm.styled";
import { useAppTranslation } from "@hooks";

interface TableRowSummaryProps {
  nutrients: Nutrient[];
  grams: number;
  children: ReactNode;
  isEmpty: boolean;
}

export const TableRowSummary = ({
  nutrients,
  grams,
  children,
  isEmpty,
}: TableRowSummaryProps) => {
  const { t } = useAppTranslation();
  const { nutrients: visibleNutrients } = useVisibleNutrients();
  const visibleNutrientsMapped = visibleNutrients?.map(n => Number(n)) ?? [];

  return (
    <TableRowSummaryStyled>
      <StickySummaryCell>{children}</StickySummaryCell>
      <StyledTableFooterCell />
      <StyledTableFooterCell align="right" bold>
        {t("common.sum")}:
      </StyledTableFooterCell>
      <StyledTableFooterCell align="right" sx={{ paddingRight: "1rem" }} bold>
        {isEmpty ? "-" : grams} g
      </StyledTableFooterCell>
      <MicrosRow
        nutrients={isEmpty ? [] : nutrients}
        visibleNutrients={[...MACROS_ARRAY, ...visibleNutrientsMapped]}
        bold
        skipBorderBottom
      />
    </TableRowSummaryStyled>
  );
};
