import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Reviewed = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 338 343">
    <image
      id="image0"
      width="338"
      height="343"
      x="0"
      y="0"
      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAVIAAAFXCAMAAADHxjZuAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAllBMVEX///+AH/+AH/+AH/+A
H/+AH/+AH/+AH/+AH/+AH/+AH/+AH/+AH/+AH/+AH/+AH/8eBzwQBCAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABEEIeAH/9oGc9gF79QE58AAAAQBB8gCD84DW9A
D38YBi8wDF9IEY8IAg94He9YFa////8yayLcAAAAInRSTlMAIEBQgJDAEGCg0DCwcODw+6VQEMCA
MOCg8EAgcNCQYLCIXN4xwwAAAAFiS0dEAIgFHUgAAAAJcEhZcwAALEoAACxKAXd6dE0AAAAHdElN
RQfmChsILgTDGAaNAAAMIElEQVR42u2d6Xba2BKFkUASIIPutcEMxpiOHXWSm+7b7/90LYExk4Yz
lE4Nh/3Ta8UJX2rYdSZ6PcoKwrA/KBTF54rKH4VhmGD/8xgpCIeDUTz+1q6S7/DOtklpf/CggvIG
7cOgH2D/48kpnIxMYF6AjYYp9scgomAY2dI859r3vBAEw9EUDOdRY2+xJv0IHucJa4j9+VwrmDx0
hvNT2cPQn2BNJ3DFs1nxxAcnEAy6S/cqjYVTTZzF57kEV4B+5/WzTtlIYrcKOuzvKppOhIUqXoCe
aSRnukomuAF60niIzQJEQZRhkzzTdMA+/4MRNsRrZbyhpuSA7jVia1XTGJudMKj0Uv4SKrv0Jw70
G7uamgywgSlBnWBzUteEkm1q0rSPjUpNIRVjr6KYQZ8KKIyeOoqol9QBl5w/KSOd/SHGcqi96GZ/
EmGzMVU2wGZXLVZt6Vpjggt/fEP0U+QClXWIHjSmVVG5h+helKaplGejv9UDFY865OdF65SR2ElN
yK85aYlAlxKT9EfF2MkvKOmPynAtqohOfyPEremE7uaSnUZYRFP+9r5OY5yC2pdXRk+aYhTUIfan
7lYIq6iy3GiVHDcpYf6+WpFTotL8fbUcNn5PiDpcRhE3g9bLkZlKJZsnFKZeEXXC1DOi377Fd6LA
6nxd6k70TvROlJg6JxrciQLLm5npTpQt0Z7UTRE8oj6s5rklKnMrFJOo8F0RBKIp9kcUR9Q3Q9o9
Ud/sk4MTPNxuMtEnyuI+KCuiIfZnFEfUs9bk4iSkX63JBVG/piYXRPvYH1Ic0cSrQurkRLlXC3pO
iE6wP6U4omIXS94/PnCIivVP3/NSf/74+evj3S1RqYPogehR//v914crolLT/pLoQf/57+OTA6RC
076K6F6z+XPHRIWmfS3RUovlY4dEha6WNBIttVquu0Iq0+S3Et3H6vylC6IyZ3slovu6Cl8AEpG3
Q5WJlqG6AbYAInuTDtGyqs4hoQbYn54A0VJLuKIqsTcZEAWM1BD741MhuocKglTg3GRKtGxUAN1f
4JEyC6KFZtYlVZ6BsiNayDL75Rkoa6J5/mqzpCJvBw+AqF2gigtSGKJFoJpWVHFBCkW08FOGrV9a
kMIRLbS9Bykw0SL5DYYpYUEKTLTw/dqdX1iQghM1KKiyBqcOiBZ600MqanDqhqimQxUVpF0RzfOl
BlJJ66TdEdVhGmJjYEJUg6mgy8zdElVmKmjHqWuiqj1Kjs3vnqiilxLjoFwQzXMFzy/mgIkbovmq
fTaVcvnWEdGCadsaSoKNghvRPH9tQSrk9ohDoq3rpzI2750SbWlRMk7mOyaar5r2o0Rcv3VNNM9n
wk2pe6JNU5SEvMcgmue17lRA3uMQrXdS/PMeiWieb6TmPRrRuiGKfd7jEa1bO+We95hE87zq8hn3
xWdcopXmlPl830r0dTafv60/tZkvZytYphVzKe91vUaiq92mKi+f1vMZHNLF7V+ADaUroott0zLx
0+MSKlpvdk1Yr+fXE1W5s/wGE6s3YcrZQtURVb4E9rzsIkwZL5XWEd1qHAR9AYjUq6bPeIukhqju
RaX1wprpZZHhW0qria42ekALPW1tkV6OUGxLaTVRsytKj7bN/6LScC2l1UR3hneTn1/tkF6kBjYa
UKI6Zz+vkt+O6bmPCrHZ0CBqzfSs3vAc8OGJ2jI929RnOeB3QbRwqDY9anX6PRzXSmt6vSXRwqDa
hOmXNeVo9Gv8KMA7JDb+9CvzQ2w+UERziJfdnizmqK+ez6871RA1ujMLmvrHns/uzkMNUYi0L2Wx
hnJ0+9zuOtWtPWneQ+wiTHefvwIbERDRmQ3GC5lX008bxWxztHYNH+7V0Y15mB6KaYgNCYYoXJD2
nsyRHoopq4Zfv88EVUlLmY+lh+mN0/2xeqILS4oXmhsjPYxvjBp+w+4yzFuDn7Lo+fs/z2f9uekE
BOjjzRbFdN8ksUGBELVfL7mQOdI3TkgbT+no7981ynyAKgtQiI0Kgihs3tsgnfFBinzaUVllBeJh
S7kQ3bd8FraUD1EuSBkRLV0UA6fPiSgPpKyIlkfMySPlRbQ0ptR3nJkRLZFiIxNGlD5SdkTJI+VH
lDrSn9h8DDQjfXrnNzYeM6QhNjdhREkj5UmUMlKmRPMlWaRciRYdnyhStkTJIn3HBiMPKUePTx0p
X6Z0kbJlShgpV6YbwkiZMl2TXjZhyZQ2UpZMiSPlyJQ6UoZMn6kj5ce0R//E7vvf2JC0kZLfx2fG
lANSZkx7LN43YMWUyck9RkxnTJC2M7V9gANUPL4ms43pyh4EnEJsWjBMIe872orL6+8tTAFv5doL
mxUQU7i74/4gbWFKKUzpe301poTClIHXV2JKKExZGFMVpnSaPg9jqsAU6lGjKxn8T4XYmMCY7joA
+jQzuEVN+dCuJlP41N+/vqnwRcNXyrApgTFV+EZgPT2vzH4tHxfVzhS2nB4fjHrV/bXs3itvYKr9
4Rv0tDM2aKxavjumF4/Caz46GWITosj0LERNOh82IGCmEO+cvN28Ca3XoqjvO2syXVlP++uK52L0
Oh+7N2FbmFo+bfayq/ylWhWFX39qY2r2LSUHoLXf/qSzv8VlYV+Daa76/VlXWjd9nZbOZMpsflJi
ujKYTtu+9E1jMuU2PykxzRdvWpH6vG395geNyZTTkqkG03y1VWXwvFF6AFq9RYXYaLpiWkDYtFN9
3Co/qK0+mWKT6ZBpUQCWb7Xu/+Vxq/dkofJkyrWYqjHdh9d2vl6fpe3L+nG+NfkaYtWux7aYqjM9
Ba3llzkqlmemztSMqaVUJ1OmzhSFqeIjySzHfCymapMpzzEfi6nSZMpsmxSbqdLyIWMbhcBUaTLl
8SQ0GaYqkymzryhCZ7pTCFOOuyWYTBUmU/aZ75pp+2TKP/MdM1VoUfwz3zXT1hYlIPMdM22dTCVk
vmOmrZMpo0P7VJi2TabM53wUpm2TKesVPhymbW2f9wofDtOWyZT12j4S07YOJcGaumU6a/OmQhqU
O6YK6/tCGpQrpio7JuxuQqAyVdqDkjFBOWKqeEhCio/qnqny8fUQmwMXphrnrLlv6zliutQ4utrH
xsCBqeYJa+rfpUeAqe7lCjl2vyum+leAZIUpOFOT+z/CwhSW6crslpqwMIVkujO8nyotTMGYLswv
p0oLUximJnfTviTLm8IwXRneoDxK1AgFwdQWqLBJ356pPdCelC19GKYLmNenJK2b2jHdgT04KWh5
34LpYg7xTsqnEjm7UMZMl/oPwzVKnN/XZLrTu8yvJHlGSp1pFzx7go6eaDJdbIHz/UwSO1Tbeyi7
N8B+dKtE3KjfyPR12y3OveSN+tVM//7x868/Oqd5kMAZ6oLpnz9+//r+8f/9D4dukIo0pyXTX/98
fLxf/dARU6GpXy1HTIWmPiZTqamPyTTE/pgCmco0/LhMxZzfp8M09aqcumEqcpkPmamkk9FEmCZ+
lVMnTD0rp06YejWYOmLK+TFOqky9GvbdMPWtRblgGnjWolww9a3tu2Dq2RR1Z8qUqWeT6Z0pU6a+
2VMHTL2zp3emd6YsdGd6Z8pBd6bgGnWO1DemDoh6xtQJ0YKpP57fEdGeP7OpoyOSHjF1SdSLtb6s
75RowVT6On+WOiYqfu9kHDgn2usFks3UQ4JAVLSZilCAlhJ6DiVz2+ov1ZdYUKfuG9O5UnkFNcYp
oycl0lz/ABloqYmk5M9CbJx7CUp+9KQ/KpFyP2qCTfJMIjr/GLfTX0uA7Y+oJP2XmK+jTGn0pUsF
nAOVXogexLaijimG6EFMfT8Fd1+vkJ9HjTFWRrXEbJiaut4OMRGn7M8GRNvStVIuryCOyOf8SX0O
78vFdPt8pYbUoZL09i0aUO5TU8y9EHMlZKEyBXqASjH9GQPdi1xNjTkY0TaolCzVA8OmVKWUiPnP
IkY+tE0Uiup4yGRSUlYfdTk1GwnJ+EsFE6xQlRegJ6WRe6s6HQiqoJXqj1xSnUa0tj27o+qmAvjC
86B00PXyfzyRnu+3SoadBes06svtRy1KJw/QWKejoX/heaVgGEEVgdjj6LxROImslgKyOBr61ItU
FYSDUaxrsaZxNAnvsdmsNJwUaFvYZnEcDYah92VTV0FYaHCp8kd0Qf4LS2EiCCbUiY0AAAAldEVY
dGRhdGU6Y3JlYXRlADIwMjItMTAtMjdUMDg6NDY6MDQrMDA6MDB95Qg9AAAAJXRFWHRkYXRlOm1v
ZGlmeQAyMDIyLTEwLTI3VDA4OjQ2OjA0KzAwOjAwDLiwgQAAAABJRU5ErkJggg=="
    />
  </SvgIcon>
);
