import { Link } from "react-router-dom";

import { useMediaQuery, useTheme } from "@mui/material";

import stars from "@assets/img/stars.png";
import arrow from "@assets/img/twistedArrowDownwards.png";
import { useAppTranslation } from "@hooks";

import {
  CardStyled,
  CheckInCircleStyled,
  CtaText,
  CtaWrapper,
  FixedButtonWrapper,
  ImagesWrapper,
  LinksWrapper,
  MobileWrapperPaper,
  SuccessInfoWrapper,
  SuccessViewWrapper,
  Text,
  Title,
  TitleWrapper,
} from "./Reset.styled";

export const SuccessViewClient = () => {
  const { t } = useAppTranslation();
  const { breakpoints } = useTheme();
  const isDownSmall = useMediaQuery(`${breakpoints.down("smallNew")}`);

  if (isDownSmall)
    return (
      <>
        <MobileWrapperPaper>
          <SuccessViewWrapper>
            <SuccessInfoWrapper>
              <TitleWrapper>
                <CheckInCircleStyled />
                <Title>{t("auth.reset.success_title")}</Title>
              </TitleWrapper>
              <Text>{t("auth.reset.download_app_info")}</Text>
            </SuccessInfoWrapper>

            <CtaWrapper>
              <div className="flex w-full">
                <div className="w-1/2" />
                <CtaText>{t("auth.reset.whisper")}</CtaText>
              </div>
              <ImagesWrapper>
                <img src={stars} width={49} height={40} />
                <img src={arrow} width={74} height={49} />
              </ImagesWrapper>
              <LinksWrapper>
                <Link to="https://apps.apple.com/us/app/alloweat/id1660780901">
                  <img src="https://alloweat.s3.eu-central-1.amazonaws.com/public/mail/ios.png" />
                </Link>
                <Link to="https://play.google.com/store/apps/details?id=com.alloweat&gl=PL">
                  <img src="https://alloweat.s3.eu-central-1.amazonaws.com/public/mail/android.png" />
                </Link>
              </LinksWrapper>
            </CtaWrapper>
          </SuccessViewWrapper>
        </MobileWrapperPaper>
        <FixedButtonWrapper />
      </>
    );
  return (
    <CardStyled>
      <SuccessViewWrapper>
        <SuccessInfoWrapper>
          <TitleWrapper>
            <CheckInCircleStyled />
            <Title>{t("auth.reset.success_title")}</Title>
          </TitleWrapper>
          <Text>{t("auth.reset.download_app_info")}</Text>
        </SuccessInfoWrapper>

        <CtaWrapper>
          <div className="flex w-full">
            <div className="w-1/2" />
            <CtaText>{t("auth.reset.whisper")}</CtaText>
          </div>
          <ImagesWrapper>
            <img src={stars} width={49} height={40} />
            <img src={arrow} width={74} height={49} />
          </ImagesWrapper>
          <LinksWrapper>
            <Link to="https://apps.apple.com/us/app/alloweat/id1660780901">
              <img src="https://alloweat.s3.eu-central-1.amazonaws.com/public/mail/ios.png" />
            </Link>
            <Link to="https://play.google.com/store/apps/details?id=com.alloweat&gl=PL">
              <img src="https://alloweat.s3.eu-central-1.amazonaws.com/public/mail/android.png" />
            </Link>
          </LinksWrapper>
        </CtaWrapper>
      </SuccessViewWrapper>
    </CardStyled>
  );
};
