import { FilterChip } from "@components/Filters/SingleSelectChipFilter/SingleSelectChipFilter.styled";
import { ListTabs } from "@hooks";
import { useController, useFormContext } from "react-hook-form";
import { useSingleTabSelect } from "./useSingleTabSelect";

type TabChipType = {
  label: string;
  formField: ListTabs;
};

export const TabChip = ({ label, formField }: TabChipType) => {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ control, name: formField });
  const isSelected = value === "1";
  const { singleSelectOnChange } = useSingleTabSelect(formField, onChange);

  const handleOnClick = () => {
    singleSelectOnChange(isSelected ? null : "1");
  };

  return (
    <FilterChip
      color="primary"
      selected={isSelected}
      variant="outlined"
      label={label}
      onClick={handleOnClick}
    />
  );
};
