import { Stack } from "@mui/material";
import ProgramsGrid from "@views/dietician/Programs/ProgramsGrid";
import { useSearchProgramsDefaultQueryNew } from "@hooks/queries";
import ProgramsSearch from "@views/dietician/Programs/ProgramsSearch";
import ProgramsTabsFilters from "@views/dietician/Programs/ProgramsTabsFilters";
import useFetchSearchFilter from "@views/dietician/Programs/hooks/useFetchSearchFilter";
import useFormDataMapper from "@views/dietician/Programs/hooks/useFormDataMapper";
import usePaginationFields from "@views/dietician/Programs/hooks/usePaginationFields";
import useSetProgramsFiltersSearchParams from "@views/dietician/Programs/hooks/useSetProgramsFiltersSearchParams";
import { useState } from "react";
import ProgramFiltersDrawer from "@components/ProgramsFilters/ProgramFiltersDrawer";
import useClearProgramsSearchQuery from "@views/dietician/Programs/hooks/useClearProgramsSearchQuery";

const Programs = () => {
  const { requestParams } = useFormDataMapper();

  useClearProgramsSearchQuery();

  const {
    data: programs,
    isFetching,
    refetch,
    isFetched,
  } = useSearchProgramsDefaultQueryNew(requestParams, {
    keepPreviousData: true,
    enabled: false,
  });

  useFetchSearchFilter({ requestParams, isFetching, refetch, isFetched });
  useSetProgramsFiltersSearchParams();
  const { pageField, perPageField } = usePaginationFields();
  const [filterDrawerOpen, setFilterDrawerOpen] = useState<boolean>(false);

  return (
    <Stack direction="column" gap="16px">
      <ProgramsSearch
        open={filterDrawerOpen}
        setOpen={() => setFilterDrawerOpen(true)}
      />
      <ProgramsTabsFilters total={programs?.meta.total ?? 0} />
      <ProgramsGrid
        programs={programs}
        isFetching={isFetching}
        pageField={pageField}
        perPageField={perPageField}
      />
      <ProgramFiltersDrawer
        total={programs?.meta.total ?? 0}
        onClose={() => setFilterDrawerOpen(false)}
        open={filterDrawerOpen}
      />
    </Stack>
  );
};

export default Programs;
