import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { SearchClientsAutocomplete } from "@components/SearchClientsAutocomplete";
import { Plus } from "@assets/icons";
import type { AddEventModalInput } from "../_hooks/useScheduleVisitForm";
import { Box, IconButton, styled } from "@mui/material";
import { ScheduleVisitModalLabel } from "../ScheduleVisitModal.styled";
import { AddButtonWrapper } from "../../Calendar/MiniCalendar/MiniCalendar.styled";
import { CheckedIcon } from "@assets/icons";
import { useAppTranslation } from "@hooks";
import { useScheduleVisitModalContext } from "../ScheduleVisitModalContext";
import { useFetchClientQuery, useFetchScheduleEvent } from "@hooks/queries";

interface ClientSectionProps {
  onlyDates: boolean;
  defaultClient?: number;
  openAddClientModal: () => void;
  isVisitTabActive: boolean;
}

const ClientWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const ScheduleModalClientSection: React.FC<ClientSectionProps> = ({
  onlyDates,
  defaultClient,
  openAddClientModal,
  isVisitTabActive,
}) => {
  const { t } = useAppTranslation();
  const { eventId, clientId } = useScheduleVisitModalContext();
  const { control, setValue } = useFormContext<AddEventModalInput>();

  const { scheduleEvent } = useFetchScheduleEvent(eventId ?? 0, {
    enabled: !!eventId,
  });

  const { client } = useFetchClientQuery(clientId ?? 0, {
    enabled: !!clientId,
  });

  useEffect(() => {
    if (clientId) {
      setValue("patientId", clientId);
    }
  }, [clientId]);

  const defaultSearch = eventId
    ? scheduleEvent?.patient?.firstName ?? ""
    : clientId
    ? client?.firstName ?? "" + client?.lastName ?? ""
    : "";

  return (
    <ClientWrapper order={isVisitTabActive ? 0 : 2}>
      <ScheduleVisitModalLabel>{t("calendar.client")}</ScheduleVisitModalLabel>
      <Box width="100%" display="flex" alignItems="center" gap="8px">
        <SearchClientsAutocomplete
          control={control}
          name="patientId"
          disabled={onlyDates || !!defaultClient}
          popupIcon={<CheckedIcon className="text-gray-450" />}
          defaultQuery={defaultSearch}
        />
        <AddButtonWrapper
          onClick={openAddClientModal}
          disabled={onlyDates || !!defaultClient}
        >
          <Plus size="w-3 h-3" className="stroke-current" />
        </AddButtonWrapper>
      </Box>
    </ClientWrapper>
  );
};
