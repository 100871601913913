import { LOGIN } from "@routes";
import { useAppTranslation } from "@hooks";

import {
  ContentText,
  RecoveryFormWrapper,
  RecoveryTitle,
  StyledLink,
  SubmitButton,
  TopRecoverySection,
} from "./RecoveryForm.styled";
import { Dispatch, FormEvent, SetStateAction } from "react";
import img from "@assets/img/BoyWithLoupeAndQuestionMarks.png";
import { FormItem } from "@components/FormItem";
import { FormTextField } from "@components/FormTextField";
import { ErrorText } from "@components/ErrorText";
import { UseMutateFunction } from "@tanstack/react-query";
import { ApiResponse } from "@typeDefinitions";
import { useRecoveryPasswordForm } from "./useRecoveryPasswordForm";

interface RecoveryFormProps {
  onClick: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  recoveryPassMutation: UseMutateFunction<
    ApiResponse<void>,
    any,
    {
      email: string;
    },
    unknown
  >;
}

export const RecoveryForm = ({
  onClick,
  recoveryPassMutation,
  isLoading,
}: RecoveryFormProps) => {
  const { t } = useAppTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useRecoveryPasswordForm();

  const handleRetrievePassword = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit(
      data =>
        recoveryPassMutation(data, {
          onSuccess: () => {
            onClick(true);
          },
        }),
      e => console.log(e),
    )();
  };

  return (
    <RecoveryFormWrapper onSubmit={handleRetrievePassword}>
      <TopRecoverySection>
        <img src={img} width={212} height={202} className="mx-auto" />
        <RecoveryTitle>{t("auth.recovery.password_recovery")}</RecoveryTitle>
        <ContentText>{t("auth.recovery.content")}</ContentText>
      </TopRecoverySection>

      <FormItem
        title={t("common.email_address")}
        extraEl={
          <>
            <FormTextField
              control={control}
              name="email"
              size="small"
              variant="outlined"
              placeholder={t("auth.recovery.placeholder_email")}
              FormHelperTextProps={{ hidden: true }}
            />
            <ErrorText content={errors.email?.message} />
          </>
        }
      />

      <SubmitButton
        type="submit"
        loading={isLoading || isSubmitting}
        fullWidth
        variant="contained"
        size="large"
      >
        {t("auth.recovery.send")}
      </SubmitButton>

      <StyledLink className="text-center" color="primary" to={LOGIN}>
        {t("auth.recovery.back_to_log_in")}
      </StyledLink>
    </RecoveryFormWrapper>
  );
};
