import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const PointerArrowRight = (props: SvgIconProps) => {
  return (
    <SvgIcon width="15" height="14" viewBox="0 0 15 14" {...props}>
      <path
        d="M8.53125 1.21875L14.0312 6.46875C14.1771 6.61458 14.25 6.79167 14.25 7C14.25 7.20833 14.1771 7.38542 14.0312 7.53125L8.53125 12.7812C8.15625 13.0729 7.80208 13.0729 7.46875 12.7812C7.17708 12.4062 7.17708 12.0521 7.46875 11.7188L11.625 7.75H1C0.541667 7.70833 0.291667 7.45833 0.25 7C0.291667 6.54167 0.541667 6.29167 1 6.25H11.625L7.46875 2.28125C7.17708 1.94792 7.17708 1.59375 7.46875 1.21875C7.80208 0.927083 8.15625 0.927083 8.53125 1.21875Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};

export const PointerArrowRightRounded = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="12" viewBox="0 0 14 12" {...props}>
      <path
        d="M13.2279 6.64714C13.5907 6.28921 13.5907 5.70793 13.2279 5.34999L8.58483 0.76845C8.22208 0.410517 7.63299 0.410517 7.27024 0.76845C6.9075 1.12638 6.9075 1.70767 7.27024 2.0656L10.3318 5.08369L1.42862 5.08369C0.914978 5.08369 0.5 5.49317 0.5 6C0.5 6.50683 0.914978 6.91631 1.42862 6.91631L10.3318 6.91631L7.27315 9.9344C6.9104 10.2923 6.9104 10.8736 7.27315 11.2316C7.63589 11.5895 8.22498 11.5895 8.58773 11.2316L13.2308 6.65001L13.2279 6.64714Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
