import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import { SectionRequestDto } from "./common";

export const createSection = async (section: SectionRequestDto) => {
  return await fetchData(
    "/dietitian/program-section-templates",
    APIMethods.POST,
    section,
  );
};

export const updateSection = async (id: number, section: SectionRequestDto) => {
  return await fetchData(
    `/dietitian/program-section-templates/${id}`,
    APIMethods.PUT,
    section,
  );
};
