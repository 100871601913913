import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const deleteCreatorDietMeal = async (
  dietId: string,
  mealId: string,
  version: string,
) => {
  return await fetchData(
    `/dietitian/creator-2/diets/${dietId}/meals/${mealId}`,
    APIMethods.DELETE,
    undefined,
    undefined,
    { "Alloweat-Creator-Version": version },
  );
};
