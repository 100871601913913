import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const RectangularArrows = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="14" viewBox="0 0 14 14">
      <path
        d="M0.5 6.23088C0.5 6.68029 0.863086 7.04338 1.3125 7.04338C1.76191 7.04338 2.125 6.68029 2.125 6.23088C2.125 4.88518 3.2168 3.79338 4.5625 3.79338H8.625V4.60588C8.625 4.93342 8.82305 5.23049 9.12773 5.35744C9.43242 5.4844 9.78027 5.4133 10.0139 5.18225L11.6389 3.55725C11.9563 3.23987 11.9563 2.72444 11.6389 2.40705L10.0139 0.782053C9.78027 0.548459 9.43242 0.479905 9.12773 0.606858C8.82305 0.733811 8.625 1.02834 8.625 1.35588V2.16838H4.5625C2.31797 2.16838 0.5 3.98635 0.5 6.23088ZM13.5 7.85588C13.5 7.40647 13.1369 7.04338 12.6875 7.04338C12.2381 7.04338 11.875 7.40647 11.875 7.85588C11.875 9.20158 10.7832 10.2934 9.4375 10.2934H5.375V9.48088C5.375 9.15334 5.17695 8.85627 4.87227 8.72932C4.56758 8.60236 4.21973 8.67346 3.98613 8.90451L2.36113 10.5295C2.04375 10.8469 2.04375 11.3623 2.36113 11.6797L3.98613 13.3047C4.21973 13.5383 4.56758 13.6069 4.87227 13.4799C5.17695 13.353 5.375 13.0584 5.375 12.7283V11.9184H9.4375C11.682 11.9184 13.5 10.1004 13.5 7.85588Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
