import { Typography } from "@mui/material";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";

import {
  BoldTypography,
  DayTypeSectionWrapper,
} from "./MonitoringCalendar.styled";
import { DayTypeWrapper } from "./DayTypeSection.styled";

interface DayTypeSectionProps {
  targetDayType?: string;
  diaryDayName?: string;
}

export const DayTypeSection = ({
  diaryDayName,
  targetDayType,
}: DayTypeSectionProps) => {
  return (
    <DayTypeSectionWrapper>
      {targetDayType !== diaryDayName && (
        <>
          <DayTypeWrapper>
            <Typography variant="body2">{targetDayType}</Typography>
          </DayTypeWrapper>
          <ArrowForwardRoundedIcon color="primary" />
        </>
      )}
      <DayTypeWrapper>
        <BoldTypography variant="body2">{diaryDayName ?? "-"}</BoldTypography>
      </DayTypeWrapper>
    </DayTypeSectionWrapper>
  );
};
