import { Plus } from "@assets/icons";
import { AddClientModal } from "@components/AddClientModalNew";
import { SingleCardPageLayout } from "@components/PageLayout";
import { useAppTranslation, useModal } from "@hooks";
import { Button } from "@mui/material";
import { ThemeProviderWrapperNew } from "themeNew";
import { ClientsFilters } from "./components/ClientsFilters";
import { ClientsTable } from "./components/ClientsTable";
import {
  searchClientsQueryKey,
  useFetchDietitianAccountQuery,
  useSearchClientsQuery,
} from "@hooks/queries/settings";
import { useState } from "react";
import { ClientsListWrapper } from "./Clients.styled";
import { useClientsFiltersForm } from "./components/ClientsFilters/useClientsFiltersForm";
import { useQueryClient } from "@tanstack/react-query";
import { ApiResponse } from "@typeDefinitions";
import { ClinicClientDtoNew } from "@client/settings";
import { ClientsEmptyState } from "@views/emptyStates/ClientsEmptyState";
import { FormProvider } from "react-hook-form";
import { CustomTag } from "@client/customTags/fetchCustomTags";

export const Clients = () => {
  const { t } = useAppTranslation();
  const { modalOpened, onModalClose, onModalOpen } = useModal();
  const {
    modalOpened: emptyStateModalOpened,
    onModalClose: onModalCloseEmptyState,
    onModalOpen: onModalOpenEmptyState,
  } = useModal();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const { account } = useFetchDietitianAccountQuery();

  const { clients, meta, queryKey, submit, submitSort, isFetching, isLoading } =
    useSearchClientsQuery(page, perPage, {
      enabled: !!account?.clinic?.id,
      keepPreviousData: true,
    });

  const { reset, ...rest } = useClientsFiltersForm();

  const DEFAULT_KEY = [searchClientsQueryKey, "", 0, 10];
  const queryClient = useQueryClient();

  const data =
    queryClient.getQueryData<ApiResponse<ClinicClientDtoNew[]>>(DEFAULT_KEY);
  const hasClients = !!data?.data.length;

  const handleResetFilters = () => reset({ programs: [], dietitians: [] });

  const handleTagsUpdated = (clientId: number, tags: CustomTag[]) => {
    queryClient.setQueryData<ApiResponse<ClinicClientDtoNew[]>>(
      queryKey,
      oldData => {
        if (!oldData) {
          return;
        }

        return {
          ...oldData,
          data: oldData.data.map(item => {
            if (item.id !== clientId) {
              return item;
            }

            return {
              ...item,
              tags,
            };
          }),
        };
      },
    );
  };

  if (!isFetching && !hasClients)
    return (
      <div className="h-full">
        <ClientsEmptyState onClick={onModalOpenEmptyState} />
        <ThemeProviderWrapperNew>
          <AddClientModal
            open={emptyStateModalOpened}
            onClose={onModalCloseEmptyState}
          />
        </ThemeProviderWrapperNew>
      </div>
    );

  return (
    <ThemeProviderWrapperNew>
      <ClientsListWrapper>
        <SingleCardPageLayout
          rebranding
          title={t("clients.title")}
          childrenClassName="flex flex-col gap-6"
          extra={
            <div className="flex gap-2">
              {/* <ClientsCounter /> */}
              <Button
                variant="contained"
                onClick={onModalOpen}
                startIcon={<Plus size="w-3 h-3" className="stroke-current" />}
              >
                {t("patients.addPatient")}
              </Button>
            </div>
          }
        >
          <FormProvider {...rest} reset={reset}>
            <ClientsFilters submit={submit} meta={meta} isLoading={isLoading} />
          </FormProvider>

          <ClientsTable
            clients={clients}
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
            isFetching={isFetching}
            meta={meta}
            submitSort={submitSort}
            clearFilters={handleResetFilters}
            onTagsUpdated={handleTagsUpdated}
          />
        </SingleCardPageLayout>

        <AddClientModal open={modalOpened} onClose={onModalClose} />
      </ClientsListWrapper>
    </ThemeProviderWrapperNew>
  );
};
