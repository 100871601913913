import { ChangeEvent, memo, useContext, useState } from "react";
import { MealElementContext } from "./MealElementContext";

import { DietitianRecipe } from "@typeDefinitions";
import { RecipeListElement } from "@components/ListModules";

import { BorderedContainer } from "./InputElement.styled";
import { Checkbox, CircularProgress, IconButton } from "@mui/material";
import { Add } from "@mui/icons-material";
import { usePostCreatorMealRecipeMutation } from "@hooks/queries/meals";
import { useAppParams } from "@hooks";

interface RecipesInputElementProps {
  recipe: DietitianRecipe;
  disabled?: boolean;
  oldRequest?: boolean;
}

export const RecipesInputElement = memo(
  ({ recipe, disabled, oldRequest }: RecipesInputElementProps) => {
    const { mealId } = useAppParams();

    const { mutate, isLoading } = usePostCreatorMealRecipeMutation(mealId);

    const handleAddRecipe = (id: number) => mutate({ id });

    const onAdd = () => handleAddRecipe(recipe.id);

    const { selected, onChanged } = useContext(MealElementContext);

    const [loading, setLoading] = useState(false);

    const onClick = async (event: ChangeEvent<HTMLInputElement>) => {
      try {
        setLoading(true);
        await onChanged(recipe.id, event.target.checked);
      } finally {
        setLoading(false);
      }
    };

    return (
      <BorderedContainer disabled={disabled}>
        {oldRequest ? (
          <Checkbox
            value={selected.includes(recipe.id)}
            onChange={onClick}
            disabled={loading}
          />
        ) : (
          <IconButton
            disabled={disabled || isLoading}
            onClick={onAdd}
            color="primary"
          >
            {isLoading ? <CircularProgress size="1.5rem" /> : <Add />}
          </IconButton>
        )}

        <div className="flex-grow">
          <RecipeListElement recipe={recipe} key={recipe.id} />
        </div>
      </BorderedContainer>
    );
  },
);
