import { Divider, Typography, styled } from "@mui/material";

export const DateRangePickerStyled = styled("div")`
  & .react-datepicker__aria-live {
    display: none;
  }

  & .react-datepicker__day--range-end,
  .react-datepicker__day--range-start {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }

  .react-datepicker__day {
    cursor: default;
  }
`;

export const WeeksButtonWrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
`;

export const CalendarMonthLabel = styled(Typography)`
  flex: 1;
  text-align: center;
  font-weight: 600;
`;

export const DividerWithMargin = styled(Divider)`
  margin-top: 0.5rem;
`;

interface CalendarWrapperProps {
  wideGap?: boolean;
}
export const CalendarWrapper = styled("div")<CalendarWrapperProps>`
  display: flex;
  gap: ${({ wideGap }) => (wideGap ? "3.5rem" : "0.5rem")};
  min-height: 20.25rem;

  & .react-datepicker__day--in-selecting-range {
    background: ${({ theme }) => theme.palette.primary.light};
  }
  & .react-datepicker__day--range-end,
  .react-datepicker__day--range-start {
    background: ${({ theme }) => theme.palette.primary.main};
  }
  & .react-datepicker__day--selecting-range-end,
  .react-datepicker__day--selecting-range-start {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`;
