import { MacrosText } from "@components/MacrosText";
import { Box, CircularProgress, Stack } from "@mui/material";
import DietMealItem from "@components/ProgramSearchPreview/Components/DietMealItems/DietMealItem";
import useFetchDietMealProgramPreview from "@hooks/queries/program/preview/useFetchDietMealProgramPerview";

type ProgramDietMealProp = {
  programId: number;
  dietMealId: number;
};

const DietMealItems = ({ programId, dietMealId }: ProgramDietMealProp) => {
  const { data } = useFetchDietMealProgramPreview(
    { programId, dietMealId },
    { staleTime: Infinity },
  );
  const meal = data?.data;

  if (meal === undefined) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Stack direction="column" gap="16px">
      <MacrosText nutrients={meal.nutrients} />
      <ul>
        {meal.recipes.map(item => (
          <DietMealItem
            key={"r" + item.id}
            image={item.recipe.image?.url ?? null}
            name={item.recipe.name}
            nutrients={item.nutrients}
            translations={item.recipe.translations}
          />
        ))}
        {meal.products.map(item => (
          <DietMealItem
            key={"p" + item.id}
            image={item.product.image?.url ?? null}
            name={item.product.name}
            nutrients={item.nutrients}
            translations={item.product.translations}
          />
        ))}
      </ul>
    </Stack>
  );
};

export default DietMealItems;
