import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import {
  CreateRecipeFoodRecipeRequest,
  CreateRecipeRequest,
  FoodRecipeDto,
  NewRecipeDto,
} from "@client";
import { useAppTranslation } from "@hooks";
import { GetProductFunc } from "@context";
import { scaleNutrients, sumNutrient } from "@utils";
import { RecipeDto } from "@client/schedule";
import { RecipeDto as MealRecipeDto } from "@client/meals";

export const useRecipeEditForm = (initValues?: RecipeEditInput) => {
  const { t } = useAppTranslation();

  const ingredientSchema = yup.object().shape({
    grams: yup
      .string()
      .test("max-value", "", value => Number(value) <= 99999)
      .required(t("common.required_field")),
    measureId: yup.string().required(t("common.required_field")),
    productId: yup.string().required(t("common.required_field")),
    wrapperId: yup.string().nullable().default(null),
  });

  const recipeInputSchema = yup.object().shape({
    namePl: yup.string().required(t("common.required_field")),
    ingredients: yup.array().of(ingredientSchema),
    numOfPortions: yup.string().required(t("common.required_field")),
  });

  const form = useForm<RecipeEditInput>({
    defaultValues: initValues ?? defaultValues,
    resolver: yupResolver(recipeInputSchema),
  });

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (initValues && !initialized) {
      setInitialized(true);
      form.reset(initValues, { keepDirtyValues: true });
    }
  }, [initValues]);

  return form;
};

export interface RecipeEditInput {
  namePl: string;
  nameEn: string;
  tags: string[];
  reviewed: boolean;
  numOfPortions: string;
  mediaId: number | null;
  descriptionPl: string;
  descriptionEn: string;
  ingredients: IngredientInput[];
  comment: string | null;
  commentEn: string | null;
}

interface IngredientInput {
  grams: string;
  measureId: string;
  productId: string;
  wrapperId: string | null;
}

const defaultValues: RecipeEditInput = {
  namePl: "",
  nameEn: "",
  ingredients: [],
  numOfPortions: "1",
  reviewed: false,
  mediaId: null,
  tags: [],
  descriptionPl: "",
  descriptionEn: "",
  comment: null,
  commentEn: null,
};

export const mapRecipeForm = (
  recipe: NewRecipeDto | undefined,
): RecipeEditInput | undefined => {
  if (!recipe) return;

  return {
    namePl: recipe.name,
    nameEn: recipe.nameEn ?? "",
    descriptionPl: recipe.description ?? "",
    descriptionEn: recipe.descriptionEn ?? "",
    tags: recipe.tags.map(tag => tag.id.toString()),
    mediaId: recipe.media?.id ?? null,
    reviewed: recipe.reviewed,
    numOfPortions: recipe.servings.toString(),
    ingredients: mapRecipeIngredientsForm(recipe.foodRecipe),
    comment: recipe.comment,
    commentEn: recipe.commentEn,
  };
};

export const mapScheduleRecipeForm = (
  recipe: RecipeDto | undefined,
): RecipeEditInput | undefined => {
  if (!recipe) return;

  return {
    namePl: recipe.name,
    nameEn: recipe.nameEn ?? "",
    descriptionPl: recipe.description ?? "",
    descriptionEn: recipe.descriptionEn ?? "",
    tags: recipe.tags.map(t => t.id.toString()),
    mediaId: recipe.media?.id ?? null,
    reviewed: recipe.reviewed,
    numOfPortions: recipe.servings.toString(),
    ingredients: mapRecipeIngredientsForm(recipe.foodRecipe),
    comment: recipe.comment,
    commentEn: recipe.commentEn,
  };
};

export const mapMealRecipeForm = (
  recipe: MealRecipeDto | undefined,
): RecipeEditInput | undefined => {
  if (!recipe) return;

  return {
    namePl: recipe.name,
    nameEn: recipe.nameEn ?? "",
    descriptionPl: recipe.description ?? "",
    descriptionEn: recipe.descriptionEn ?? "",
    tags: recipe.tags.map(t => t.id.toString()),
    mediaId: recipe.media?.id ?? null,
    reviewed: recipe.reviewed,
    numOfPortions: recipe.servings.toString(),
    ingredients: mapRecipeIngredientsForm(
      recipe.foodRecipe.map(r => ({ ...r, grams: r.grams ?? 0 })),
    ),
    comment: recipe.comment,
    commentEn: recipe.commentEn,
  };
};

export const mapRecipeIngredientsForm = (
  recipeFood: FoodRecipeDto[] | undefined,
): IngredientInput[] => {
  if (!recipeFood) return [];

  return recipeFood.map(food => {
    return {
      grams: food.grams.toString(),
      measureId: food.foodMeasureId.toString(),
      productId: food.food.id.toString(),
      wrapperId: food.id.toString(),
    };
  });
};

export const mapRecipeRequest = (
  data: RecipeEditInput,
  id: number | null,
  version: number | null,
): CreateRecipeRequest => {
  return {
    id,
    description: data.descriptionPl,
    descriptionEn: data.descriptionEn,
    name: data.namePl,
    nameEn: data.nameEn,
    version,
    tags: data.tags.map(tag => Number(tag)),
    reviewed: data.reviewed,
    isComplex: Number(data.numOfPortions) > 1,
    servings: Number(data.numOfPortions),
    media: data.mediaId ? { id: data.mediaId } : null,
    foodRecipe: data.ingredients.map((ingredient, i) =>
      mapRecipeIngredientRequest(ingredient, i + 1),
    ),
    comment: data.comment,
    commentEn: data.commentEn,
  };
};

export const mapRecipeIngredientRequest = (
  ingredient: IngredientInput,
  index: number,
): CreateRecipeFoodRecipeRequest => {
  return {
    id: ingredient.wrapperId ? Number(ingredient.wrapperId) : null,
    grams: Number(ingredient.grams),
    foodMeasureId: Number(ingredient.measureId),
    foodId: Number(ingredient.productId),
    sortOrder: index,
  };
};
export function sumNutrients(
  ingredients: IngredientInput[],
  getProduct: GetProductFunc,
) {
  return sumNutrient(
    ingredients?.map(f =>
      scaleNutrients(
        getProduct(Number(f.productId))?.nutrients ?? [],
        Number(f.grams),
      ),
    ),
  );
}
