import { useAppTranslation } from "@hooks";
import { Control, useController } from "react-hook-form";
import { Divider, Switch } from "@mui/material";
import { FormControlLabelSwitchStyled } from "@components/EditClientPaymentsModal/EditClientPaymentsModal.styled";
import { FormCheckboxMui } from "@components/FormCheckboxMui";
import { EditClientPaymentsFormInput } from "@components/EditClientPaymentsModal/useEditClientPaymentsForm";

interface ClientContactsViewProps {
  control: Control<EditClientPaymentsFormInput>;
}

export const ClientMobileAppView = ({ control }: ClientContactsViewProps) => {
  const { t } = useAppTranslation();

  const {
    field: { value, onChange },
  } = useController({ control, name: "isMobileAppActive" });

  return (
    <div className="grid gap-4">
      <FormControlLabelSwitchStyled
        value={value}
        checked={value}
        control={<Switch color="primary" onChange={onChange} />}
        label={
          value
            ? t("client_profile.payments.status_active")
            : t("client_profile.payments.status_inactive")
        }
        labelPlacement="start"
      />
      {value && (
        <div className="grid gap-4">
          <Divider />
          <FormCheckboxMui
            rebranding
            control={control}
            label={t("client_profile.edit.payments.chat")}
            name="chat"
          />
          <FormCheckboxMui
            rebranding
            control={control}
            label={t("client_profile.edit.payments.alloweat_recipes")}
            name="recipeAlloweat"
          />
          <FormCheckboxMui
            rebranding
            control={control}
            label={t("client_profile.edit.payments.dietitians_recipes")}
            name="recipeClinic"
          />
        </div>
      )}
    </div>
  );
};
