import { useAppTranslation } from "@hooks";
import {
  ConfirmEmailWrapper,
  ConfirmSignUpViewWrapper,
  ContentText,
  SignUpViewTitle,
  TopSignUpViewSection,
} from "./ConfirmSignUpView.styled";
import img from "@assets/img/GirlWithLetter.png";
import { Button, useMediaQuery, useTheme } from "@mui/material";
import { LOGIN } from "@routes";
import { useResendRegisterEmailMutation } from "@hooks/queries";

export const ConfirmSignUpView = (props: { resendEmail: string }) => {
  const { t } = useAppTranslation();
  const { mutate } = useResendRegisterEmailMutation();
  const { breakpoints } = useTheme();
  const isDownSmall = useMediaQuery(`${breakpoints.down("smallNew")}`);

  const resendEmail = () => mutate({ email: props.resendEmail });

  return (
    <ConfirmSignUpViewWrapper>
      <img src={img} width={236} height={210} className="mx-auto" />
      <TopSignUpViewSection>
        <SignUpViewTitle>
          {isDownSmall
            ? t("auth.register_clinic.confirm.title_mobile")
            : t("auth.register_clinic.confirm.title")}
        </SignUpViewTitle>
        <ContentText>{t("auth.register_clinic.confirm.content")}</ContentText>
      </TopSignUpViewSection>

      <ConfirmEmailWrapper>
        <ContentText>
          {t("auth.register_clinic.confirm.not_receive_message")}{" "}
        </ContentText>
        <Button color="primary" onClick={resendEmail}>
          {t("auth.register_clinic.confirm.send_email_again")}
        </Button>
      </ConfirmEmailWrapper>
      <Button color="primary" href={LOGIN}>
        {t("auth.register_clinic.confirm.back_to_login")}
      </Button>
    </ConfirmSignUpViewWrapper>
  );
};
