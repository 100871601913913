import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useAppTranslation } from "@hooks";
import { fetchCustomTagsQueryKey } from "@hooks/queries/customTags/useFetchCustomTagsQuery";
import { MutationOptions } from "@hooks/queries";
import {
  SyncCustomTagRequest,
  syncCustomTags,
} from "@client/customTags/syncCustomTags";

export const useSyncCustomTagsMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation(
    (request: SyncCustomTagRequest) => syncCustomTags(request),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries([fetchCustomTagsQueryKey]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};
