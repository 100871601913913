import dayjs from "dayjs";
import { TagCategoriesDict } from "@hooks";
import {
  MacrosRangeInput,
  OwnershipInput,
  SearchInput,
  TagFiltersInput,
  TypeTagsInput,
} from "@typeDefinitions/types";
import { compact } from "lodash";

export const mealTypeCategoryId = 1;
export const mealKindCategoryId = 8;
export const otherCategoryId = 18;
export const baseCategoryId = 22;
export const seasonalityCategoryId = 7;
export const equipmentCategoryId = 10;
export const preparationTimeCategoryId = 2;
export const difficultyCategoryId = 21;
export const cuisineCategoryId = 19;
export const allergenCategoryId = 13;
export const dietTypeCategoryId = 23;
export const eliminationCategoryId = 15;
export const ownCategoryId = 30;
export const tastePreferenceId = 31;
export const dietTypeId = 32;

export const parseNumber = (
  input: number | undefined | null,
  name: string,
  params: URLSearchParams,
) => {
  if (input !== undefined && input !== null) {
    params.append(name, input.toString());
  }
};

export const parseString = (
  input: string | undefined | null,
  name: string,
  params: URLSearchParams,
) => {
  if (input !== undefined && input !== null && !!input.trim()) {
    params.append(name, input.trim());
  }
};

export const parseTags = (
  input: TagFiltersInput[] | undefined,
  categoryId: number,
  params: URLSearchParams,
) => {
  if (input !== undefined) {
    input.forEach(t => params.append(`tags[${categoryId}][]`, t.toString()));
  }
};

export const parseNumberArray = (
  input: number[] | undefined | null,
  name: string,
  params: URLSearchParams,
) => {
  if (input !== undefined && input !== null) {
    input.forEach(t => params.append(`${name}[]`, t.toString()));
  }
};
export const parseStringArray = (
  input: string[] | undefined | null,
  name: string,
  params: URLSearchParams,
) => {
  if (input !== undefined && input !== null) {
    input.forEach(t => params.append(`${name}[]`, t));
  }
};

export const parseSearchInput = (
  input: SearchInput | undefined,
  params: URLSearchParams,
) => {
  if (input?.name) {
    params.append("query", input?.name);
  }
};

export const parseMacrosRangeInput = (
  input: MacrosRangeInput | undefined,
  params: URLSearchParams,
) => {
  parseNumber(input?.kcal?.from, "kcal_from", params);
  parseNumber(input?.kcal?.to, "kcal_to", params);
  parseNumber(input?.protein?.from, "protein_from", params);
  parseNumber(input?.protein?.to, "protein_to", params);
  parseNumber(input?.fat?.from, "fat_from", params);
  parseNumber(input?.fat?.to, "fat_to", params);
  parseNumber(input?.carb?.from, "carb_from", params);
  parseNumber(input?.carb?.to, "carb_to", params);
};

export const parseOwnershipInput = (
  input: OwnershipInput | undefined,
  params: URLSearchParams,
) => {
  if (input?.owner) {
    params.append("owner", input.owner);
  }
};

export const parseTypeTagsInput = (
  input: TypeTagsInput | undefined,
  params: URLSearchParams,
) => {
  parseTags(input?.type_tags, dietTypeCategoryId, params);
};

export const parseArrayInput = (
  input: number[],
  name: string,
  params: URLSearchParams,
) => {
  if (input?.length) {
    input.forEach(value => {
      params.append(name + "[]", value.toString());
    });
  }
};

export const parseDatejsDate = (
  input: dayjs.Dayjs | undefined,
  name: string,
  params: URLSearchParams,
) => {
  if (input) params.append(name, input.format("YYYY-MM-DD"));
};

export const optionsForId = (tagCategories: TagCategoriesDict, id: number) =>
  tagCategories[id]?.tags.map(tag => ({
    label: tag.name,
    value: tag.id,
  })) ?? [];

export const parseTimePeriod = (
  input: string | undefined,
  params: URLSearchParams,
) => {
  if (input) {
    if (input === "month") params.append("time_unit", "month");
    else if (input === "work_week") params.append("time_unit", "week");
    else if (input === "day") params.append("time_unit", "day");
  }
};

export const handleCheckFilters = (
  committedInputs: Record<string, any> | undefined,
) =>
  committedInputs ? !!compact(Object.values(committedInputs)).length : false;

export const mapTagOptions = (tagCategories: TagCategoriesDict, id: number) =>
  tagCategories[id]?.tags.map(tag => ({
    id: String(tag.id),
    label: tag.name,
  })) ?? [];

export const getTagName = (tagCategories: TagCategoriesDict, id: number) =>
  tagCategories[id]?.name;

export const parseTagsList = (
  input: string[] | undefined,
  params: URLSearchParams,
) => {
  if (input !== undefined && !!input.length) {
    params.append(
      "tags",
      input
        .sort((a, b) => parseInt(a) - parseInt(b))
        .map(t => t.toString())
        .join(","),
    );
  }
};

export const parseNutrientsList = (
  input: { [id: string]: [number, number] } | undefined,
  params: URLSearchParams,
) => {
  if (input !== undefined) {
    const map = new Map<string, [number, number]>();
    for (const [key, value] of Object.entries(input)) map.set(key, value);

    const stringArray: string[] = [];

    map.forEach((value, key) => {
      if (!value || !value[1]) return;

      stringArray.push(`${key}|${value[0] ?? null}|${value[1] ?? null}`);
    });

    if (compact(stringArray).length)
      params.append("nutrients", stringArray.join(","));
  }
};

export const parseBooleanString = (
  input: "0" | "1" | undefined,
  name: string,
  params: URLSearchParams,
) => {
  if (!input || input === "0") return;

  return params.append(name, "1");
};

export const parseCategory = (
  input: string[] | string | null | undefined,
  name: string,
  params: URLSearchParams,
) => {
  if (!input || !input[0]) return;

  return params.append(name, input[0]);
};

export const parseStringArrayNew = (
  input: string[] | undefined,
  name: string,
  params: URLSearchParams,
) => {
  if (input !== undefined && !!input.length) {
    params.append(
      name,
      input.sort((a, b) => parseInt(a) - parseInt(b)).join(","),
    );
  }
};
