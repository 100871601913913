import {
  Divider,
  TabHeadLayout,
  TabLayout,
  TabTitle,
} from "@views/dietician/ClientHealth/ClientHealthNew.styled";
import { useAppTranslation } from "@hooks";
import GoalSection from "@views/dietician/ClientHealth/BodyMeasurementTab/GoalSection/GoalSection";
import MeasurementSection from "@views/dietician/ClientHealth/BodyMeasurementTab/MeasurementSection/MeasurementSection";
import ImagesSection from "@views/dietician/ClientHealth/BodyMeasurementTab/ImageSection/ImagesSection";
import SettingsSection from "@views/dietician/ClientHealth/BodyMeasurementTab/SettingsSection/SettingsSection";

const BodyMeasurementTab = () => {
  const { t } = useAppTranslation();
  return (
    <TabLayout>
      <TabHeadLayout>
        <TabTitle>
          {t("patient.health_and_goal.body_measurement_tab.tab_title")}
        </TabTitle>
      </TabHeadLayout>

      <GoalSection />
      <Divider />
      <MeasurementSection />
      <Divider />
      <ImagesSection />
      <Divider />
      <SettingsSection />
    </TabLayout>
  );
};

export default BodyMeasurementTab;
