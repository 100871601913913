import { fetchData } from "@utils/api";
import { IdDto, idSchema } from "./common";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import * as yup from "yup";

export const fetchProgramPdfSettings = async (
  programId: string,
): Promise<ApiResponse<FetchProgramPdfSettingsResponse>> => {
  const response = await fetchData(
    `/dietitian/programs/${programId}/pdf-settings`,
    APIMethods.GET,
  );

  return await apiResponseSchema.validate(response);
};

export interface FetchProgramPdfSettingsResponse {
  layout: PdfLayoutDto | null;
  showClinicName: boolean;
  showClinicFacility: boolean;
  showClinicPhoneNumber: boolean;
  showClinicEmail: boolean;
  showScheduleDate: boolean;
  showScheduleDayName: boolean;
  showMealHour: boolean;
  frequencyShoppingList: number;
  scheduleStartedAt: string | null;
  scheduleEndedAt: string | null;
}

interface PdfLayoutDto {
  id: number;
  clinic: IdDto;
  color: ColorListDto;
}

interface ColorListDto {
  primary: ColorDto;
  secondary: ColorDto;
}

interface ColorDto {
  color: string;
}

const colorSchema = yup.object().shape({
  color: yup.string().required(),
});

const colorListSchema = yup.object().shape({
  primary: colorSchema.required(),
  secondary: colorSchema.required(),
});

const pdfLayoutSchema = yup.object().shape({
  id: yup.number().required(),
  clinic: idSchema.required(),
  color: colorListSchema.required(),
});

const fetchProgramPdfSettingsSchema = yup.object().shape({
  layout: pdfLayoutSchema.nullable().default(null),
  showClinicName: yup.boolean().required(),
  showClinicFacility: yup.boolean().required(),
  showClinicPhoneNumber: yup.boolean().required(),
  showClinicEmail: yup.boolean().required(),
  showScheduleDate: yup.boolean().required(),
  showScheduleDayName: yup.boolean().required(),
  showMealHour: yup.boolean().required(),
  frequencyShoppingList: yup.number().required(),
  scheduleStartedAt: yup.string().nullable().default(null),
  scheduleEndedAt: yup.string().nullable().default(null),
});

const apiResponseSchema = yup.object().shape({
  data: fetchProgramPdfSettingsSchema.required(),
});
