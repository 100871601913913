import useFetchDietProgramPreview from "@hooks/queries/program/preview/useFetchDietProgramPerview";
import { Box, CircularProgress, Stack } from "@mui/material";
import Divider from "@components/Alloweat/Divider";
import DietName from "@components/ProgramSearchPreview/Components/Diet/DietName";
import DietNutrients from "@components/ProgramSearchPreview/Components/DietNutrients/DietNutrients";
import DietMeals from "@components/ProgramSearchPreview/Components/DietMeals/DietMeals";

type ProgramDietProps = {
  programId: number;
  dietId: number;
};

const Diet = ({ programId, dietId }: ProgramDietProps) => {
  const { data, isFetching } = useFetchDietProgramPreview(
    { programId, dietId },
    { staleTime: Infinity },
  );

  if (data === undefined || isFetching) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Stack direction="column" gap="24px">
      <DietName {...data.data} />
      <DietNutrients nutrients={data.data.nutrients} />
      <Divider />
      <DietMeals diet={data.data} />
    </Stack>
  );
};

export default Diet;
