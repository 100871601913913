export const Divider = (props: DividerProps) => {
  const { parentPadding, marginTop, marginBottom, margin = 6 } = props;

  return (
    <hr
      className={`border-gray-500 -mx-${parentPadding} mt-${
        marginTop || margin
      } mb-${marginBottom || margin}`}
      style={{ width: `calc(100% + ${parentPadding * 2 * 0.25}rem)` }}
    />
  );
};

interface DividerProps {
  parentPadding: number;
  marginTop?: number;
  marginBottom?: number;
  margin?: number;
}
