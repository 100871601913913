import * as yup from "yup";
import { fetchData } from "@utils/api";
import {
  APIMethods,
  MetadataSchema,
  PaginationApiResponse,
} from "@typeDefinitions";
import { logFetchValidateError } from "@utils/validate";
import {
  PatientMealsMonitoringResource,
  patientMealsMonitoringSchema,
} from "@client/resources/PatientMealsMonitoringResource";

export interface FetchMonitoringMealsClientParams {
  patientId: number;
  dateFrom: string;
  dateTo: string;
  page?: number;
  rating?: number[];
  tags?: number[];
  symptoms?: number;
}

export type FetchMonitoringMealsClientResponse = PaginationApiResponse<
  PatientMealsMonitoringResource[]
>;

export const fetchMonitoringMealsClient = async (
  params: FetchMonitoringMealsClientParams,
): Promise<FetchMonitoringMealsClientResponse> => {
  const resource = await fetchData(
    `/dietitian/patients/{patient_id}/monitoring2/meals-client`.replace(
      "{patient_id}",
      params.patientId.toString(),
    ),
    APIMethods.GET,
    undefined,
    mapParamsToUrlParams(params),
  );

  return await schema
    .validate(resource)
    .catch(
      logFetchValidateError(
        "/dietitian/patients/{patient_id}/monitoring2/meals-client",
      ),
    );
};

const mapParamsToUrlParams = (
  params: FetchMonitoringMealsClientParams,
): URLSearchParams => {
  const urlParams = new URLSearchParams();
  urlParams.set("date-from", params.dateFrom);
  urlParams.set("date-to", params.dateTo);
  if (params.page) {
    urlParams.set("page", params.page.toString());
  }
  if (params.tags) {
    urlParams.set("tags", params.tags.toString());
  }
  if (params.rating) {
    urlParams.set("rating", params.rating.toString());
  }
  if (params.symptoms) {
    urlParams.set("symptoms", params.symptoms.toString());
  }

  return urlParams;
};

const schema = yup.object({
  data: yup.array().of(patientMealsMonitoringSchema).required(),
  meta: MetadataSchema.defined(),
});
