import { ListTabs } from "@hooks";
import { ProgramFiltersFormPops } from "@views/dietician/Programs/ProgramsFiltersFormType";
import { useFormContext } from "react-hook-form";

export const useSingleTabSelect = (
  name: ListTabs,
  onChange: (...event: any[]) => void,
) => {
  const { setValue } = useFormContext<ProgramFiltersFormPops>();

  const singleSelectOnChange = (newValue: string | null) => {
    const restTabs = [
      ListTabs.UNVERIFIED,
      ListTabs.OWN,
      ListTabs.FAVORITE,
      ListTabs.LATEST,
      ListTabs.ALL,
    ].filter(t => t !== name);

    restTabs.forEach(key => {
      setValue(key, null);
    });

    onChange(newValue);
  };

  return { singleSelectOnChange };
};
