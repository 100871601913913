import { forwardRef } from "react";

import classNames from "classnames";

/**
 * universal radio tile component
 */
export const RadioTile = forwardRef<HTMLInputElement, RadioTileProps>(
  (props, ref) => {
    const {
      label,
      name,
      value,
      selectedValue,
      onChange,
      disabled,
      className,
      variant = "standard",
      ...rest
    } = props;
    const checked = selectedValue === value;

    return (
      <label
        className={classNames(
          {
            "flex relative font-roman items-center border rounded-md py-1 px-6 text-sm box-border":
              true,
            "bg-primary text-white": checked && variant !== "outlined",
            "bg-white text-primary border-primary":
              checked && variant === "outlined",
            "bg-gray-700": !checked && variant !== "outlined",
            "bg-gray-500 border-transparent":
              !checked && variant === "outlined",
          },
          className,
        )}
      >
        <input
          ref={ref}
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
          type="radio"
          className={classNames({
            "opacity-0 absolute left-0 w-full max-w-prose h-6 cursor-pointer":
              true,
            "cursor-not-allowed": disabled,
          })}
          {...rest}
          onChange={!disabled && onChange ? () => onChange(value) : undefined}
        />
        <span className="w-full">{label}</span>
      </label>
    );
  },
);

export interface RadioTileProps {
  /** label of a radio */
  label?: string;
  /** value of the radio */
  value?: number | string;
  /** name of radio input element */
  name?: string;
  /** callback fired on selecting radio */
  onChange?: (val?: number | string) => void;
  /** is disabled */
  disabled?: boolean;
  /** selected value of the radio group */
  selectedValue?: number | string;
  /** additional container classNames */
  className?: string;
  /** style variant */
  variant?: "standard" | "outlined";
}
