import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  CreatorDietMealRecipeRequest,
  updateCreatorDietMealRecipe,
} from "@client/diets/creator";
import { MutationOptions } from "@hooks/queries/types";

import { useCreatorVersion } from "./useCreatorVersion";
import { fetchCreatorDietMealsItemsQuery } from "./useFetchCreatorDietMealsItemsQuery";
import { fetchCreatorDietMealRecipeQueryKey } from "./useFetchCreatorDietMealRecipeQuery";
import { fetchCreatorDietMealsQueryKey } from "./useFetchCreatorDietMealsQuery";
import { fetchDietsItemsProductsRemovedQueryKey } from "../useFetchDietsItemsProductsRemovedQuery";
import { fetchDietsItemsProductsReplacementQueryKey } from "../useFetchDietsItemsProductsReplacementQuery";
import { fetchDietProductItemsQueryKey } from "../useFetchDietProductItemsQuery";
import { fetchDietProductsListQueryKey } from "../useFetchDietProductsListQuery";

export const useUpdateCreatorDietMealRecipeMutation = (
  options?: MutationOptions,
) => {
  const { version, updateVersion } = useCreatorVersion();
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    UseUpdateCreatorDietMealRecipeMutationPayload
  >(
    ({ dietId, mealId, recipeId, payload }) =>
      updateCreatorDietMealRecipe(dietId, mealId, recipeId, payload, version),
    {
      ...options,
      onMutate: updateVersion,
      onSuccess: (d, v) => {
        options?.onSuccess && options.onSuccess(d, v);
        queryClient.invalidateQueries([
          fetchCreatorDietMealsItemsQuery,
          v.dietId,
          v.mealId,
        ]);
        queryClient.invalidateQueries([
          fetchCreatorDietMealRecipeQueryKey,
          v.dietId,
          v.mealId,
          v.recipeId,
        ]);
        queryClient.invalidateQueries([
          fetchCreatorDietMealsQueryKey,
          v.dietId,
        ]);
        queryClient.invalidateQueries([
          fetchDietsItemsProductsRemovedQueryKey,
          v.dietId,
        ]);
        queryClient.invalidateQueries([
          fetchDietsItemsProductsReplacementQueryKey,
          v.dietId,
        ]);
        queryClient.invalidateQueries([
          fetchDietProductItemsQueryKey,
          v.dietId,
        ]);
        queryClient.invalidateQueries([
          fetchDietProductsListQueryKey,
          v.dietId,
        ]);
      },
    },
  );
};

interface UseUpdateCreatorDietMealRecipeMutationPayload {
  dietId: string;
  mealId: string;
  recipeId: string;
  payload: CreatorDietMealRecipeRequest;
}
