import { useEffect, useMemo, useState } from "react";

import { CircularProgress } from "@mui/material";

import { RemovedProductDto } from "@client/diets";
import { useAppParams, useAppTranslation, useMealClientContext } from "@hooks";
import { useFetchDietProductsRemovedQuery } from "@hooks/queries/diets";
import { getTranslation } from "@utils/translations";

import { CategoryTitle } from "../CategoryTitle";
import { NoProductsEmptyState } from "../EmptyState";
import { groupProductsByCategory } from "../productsListUtils";
import { RemovedProductAccordion } from "./RemovedProductAccordion";
import { getClientContextSummary } from "../productListsUtils";
import { ChipFilters } from "../ChipFilters";
import { usePostPatientProductsContextMutation } from "@hooks/queries";

interface RemovedListProps {
  group: boolean;
  query?: string;
  setCount: (count: number) => void;
  changeTab: () => void;
}

export const RemovedList = ({
  group,
  query,
  setCount,
  changeTab,
}: RemovedListProps) => {
  const { currentLanguage, t } = useAppTranslation();
  const { dietId } = useAppParams();
  const { data, isLoading } = useFetchDietProductsRemovedQuery(dietId, {
    onSuccess: d => setCount(d?.data.length ?? 0),
  });
  const foodIds = data?.map(p => p.from.id) ?? [];
  const { mutate: fetchProducts } = usePostPatientProductsContextMutation();

  const { productsClientContextMap } = useMealClientContext();
  const clientContextSummary = getClientContextSummary(
    foodIds,
    productsClientContextMap,
  );
  const [filterList, setFilterList] = useState<number[]>([]);

  const filteredProducts = useMemo(
    () =>
      data
        ?.filter(({ from, mainProductCategory }) => {
          const queryLowerCase = query?.toLowerCase() ?? "";
          const queryInPrev = getTranslation(from.translations, currentLanguage)
            .toLowerCase()
            .includes(queryLowerCase);
          const queryInCategory =
            group &&
            mainProductCategory.description
              .toLowerCase()
              .includes(queryLowerCase);

          return queryInPrev || queryInCategory;
        })
        .filter(({ from }) =>
          filterList.length ? filterList.includes(from.id) : true,
        ),
    [data, group, query, currentLanguage],
  );

  const groupedProducts = useMemo(
    () => groupProductsByCategory(filteredProducts ?? []),
    [filteredProducts],
  );

  const productsSorted = useMemo(
    () =>
      filteredProducts?.sort((a, b) => {
        const aName = getTranslation(a.from.translations, currentLanguage);
        const bName = getTranslation(b.from.translations, currentLanguage);
        return aName.localeCompare(bName);
      }) ?? [],
    [filteredProducts, currentLanguage],
  );

  const sortProducts = (a: RemovedProductDto, b: RemovedProductDto) => {
    const aName = getTranslation(a.from.translations, currentLanguage);
    const bName = getTranslation(b.from.translations, currentLanguage);
    return aName.localeCompare(bName);
  };

  useEffect(() => {
    if (foodIds.length) fetchProducts({ payload: foodIds });
  }, [foodIds.length]);

  if (isLoading)
    return (
      <div className="grid place-items-center">
        <CircularProgress />
      </div>
    );

  if (!data?.length)
    return (
      <NoProductsEmptyState
        infoText={t("diet.empty_state.delete_info")}
        onClick={changeTab}
      />
    );

  if (group)
    return (
      <>
        <ChipFilters
          summary={clientContextSummary}
          setFilterList={setFilterList}
        />
        {Object.entries(groupedProducts).map(([categoryId, data]) => {
          const { categoryNamePl, categoryNameEn, products } = data;
          const mainCategoryId = data.products[0].mainProductCategory.id;

          return (
            <div key={categoryId} className="grid gap-[16px]">
              <CategoryTitle
                id={mainCategoryId}
                nameEn={categoryNameEn}
                namePl={categoryNamePl}
              />

              <ul className="grid gap-[8px]">
                {products.sort(sortProducts).map(({ from }, idx) => (
                  <RemovedProductAccordion
                    key={idx}
                    previousName={getTranslation(
                      from.translations,
                      currentLanguage,
                    )}
                    from={from.id}
                  />
                ))}
              </ul>
            </div>
          );
        })}
      </>
    );

  return (
    <>
      <ChipFilters
        summary={clientContextSummary}
        setFilterList={setFilterList}
      />
      {productsSorted?.map(({ from }, idx) => (
        <RemovedProductAccordion
          key={idx}
          previousName={getTranslation(from.translations, currentLanguage)}
          from={from.id}
        />
      ))}
    </>
  );
};
