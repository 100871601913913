import {
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from "@mui/material";

export const CustomDialogTitle = styled(DialogTitle)`
  width: unset;
  height: unset;
  padding: unset;
  font-family: Figtree, serif;
  line-height: 20px;
  font-weight: 600;
  font-size: 16px;
  color: #333333;
` as typeof DialogTitle;

export const CustomDialogContent = styled(DialogContent)`
  padding: 0 16px 24px;
` as typeof DialogContent;

export const CustomDialogActions = styled(DialogActions)`
  padding: 12px 16px;
` as typeof DialogActions;
