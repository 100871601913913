import { useMutation, useQueryClient } from "@tanstack/react-query";

import { postToggleFavoriteDiet } from "@client";

import { MutationOptions } from "./types";
import { searchDietsQueryKeyNew } from "./useSearchDietsQueryNew";
import { fetchDietQueryKey } from "./useFetchDietitianDietDetails";

export const usePostToggleFavoriteDietMutation = (
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, string>(
    programId => postToggleFavoriteDiet(programId),
    {
      ...options,
      onSuccess: (d, programId) => {
        options?.onSuccess && options.onSuccess(d, programId);
        queryClient.invalidateQueries([searchDietsQueryKeyNew]);
        queryClient.invalidateQueries([fetchDietQueryKey, Number(programId)]);
      },
    },
  );
};
