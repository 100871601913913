import { Alert, AlertTitle } from "@mui/material";
import { useAppTranslation } from "@hooks";

interface ClipboardInfoProps {
  content: string;
}

export const ClipboardInfo = ({ content }: ClipboardInfoProps) => {
  const { t } = useAppTranslation();

  return (
    <Alert severity="info" variant="outlined">
      <AlertTitle>{t("schedule.clipboard")}:</AlertTitle>
      {content}
    </Alert>
  );
};
