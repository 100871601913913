import styled from "@emotion/styled";
import { AccordionSummary, AccordionDetails, Accordion } from "@mui/material";

export const StyledAccordion = styled(Accordion)`
  background-color: ${({ theme }) => theme.colors.gray200};
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 1rem;

  & .MuiAccordionSummary-content {
    margin: 0;
  }
`;

export const StyledTitle = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  font-size: initial;
  border-bottom: 1px solid ${({ theme }) => theme.colors.tableBorder};
`;
