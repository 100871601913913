import { Dispatch, SetStateAction, useState } from "react";

import { AccordionDetails, Radio } from "@mui/material";

import { SystemQuestionType } from "@client/surveys";

import {
  AccordionStyled,
  AccordionSummaryStyled,
} from "./QuestionAccordion.styled";
import { uniq } from "lodash";

interface QuestionAccordionProps {
  title: string;
  type: SystemQuestionType;
  setSelectedType: Dispatch<SetStateAction<SystemQuestionType[]>>;
  selectedType: SystemQuestionType[];
  disabled: boolean;
}

export const QuestionAccordion = ({
  title,
  setSelectedType,
  type,
  selectedType,
  disabled,
}: QuestionAccordionProps) => {
  const [expanded, setExpanded] = useState(false);
  const isSelected = selectedType?.includes(type);
  const handleSelect = () =>
    isSelected
      ? setSelectedType(state => state.filter(t => t !== type))
      : setSelectedType(state => uniq([...state, type]));

  return (
    <AccordionStyled
      disabled={disabled}
      expanded={false}
      onClick={() => !disabled && handleSelect()}
    >
      <AccordionSummaryStyled expandIcon={<></>}>
        <div className="flex items-center">
          <Radio
            checked={isSelected}
            onClick={handleSelect}
            disabled={disabled}
          />
          {title}
        </div>
      </AccordionSummaryStyled>
      <AccordionDetails></AccordionDetails>
    </AccordionStyled>
  );
};
