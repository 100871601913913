import { useContext } from "react";

import { TableOfContentsContext } from "@context/TableOfContentsContext";
import { SubgroupName, SubgroupStyled } from "./TableOfContentsPopover.styled";

interface SubgroupElementPopoverProps {
  id: string;
  groupId: string;
  title: string;
}
export const SubgroupElementPopover = ({
  id,
  groupId,
  title,
}: SubgroupElementPopoverProps) => {
  const { selectedId, setSelectedId, setSubgroupId, setGroupId } = useContext(
    TableOfContentsContext,
  );

  const selected = selectedId.split("-")[1] === id;

  const handleSelect = () => {
    setSelectedId(`${groupId}-${id}`);
    setGroupId(Number(groupId));
    setSubgroupId(Number(id));
  };

  return (
    <SubgroupStyled selected={selected} onClick={handleSelect}>
      <SubgroupName>{title}</SubgroupName>
    </SubgroupStyled>
  );
};
