import { useEffect } from "react";
import { FieldValues, Path, useForm } from "react-hook-form";

import * as yup from "yup";

import { LangDto, TranslationDto } from "@client";
import { PostCreatorDietRequest } from "@client/diets/creator";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppTranslation } from "@hooks";
import { getTranslation } from "@utils/translations";
import { compact } from "lodash";

export const useDietNameForm = (defaultValues?: DietNameInputs) => {
  const { t } = useAppTranslation();

  const resolver = yup.object().shape({
    lang: yup.array().of(yup.number().required()).required(),
    namePl: yup.string().required(t("common.required_field")),
    nameEn: yup.string().when("lang", {
      is: (lang: number[]) => !!lang && lang.includes(langOptions[1].id),
      then: (
        schema: yup.StringSchema<
          string | undefined,
          yup.AnyObject,
          undefined,
          ""
        >,
      ) => schema.required(t("common.required_field")),
      otherwise: (
        schema: yup.StringSchema<
          string | undefined,
          yup.AnyObject,
          undefined,
          ""
        >,
      ) => schema.nullable().default(null),
    }),
  });

  const form = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(resolver),
  });

  useEffect(() => {
    if (defaultValues) form.reset(defaultValues);
  }, [defaultValues]);

  return form;
};

export interface DietNameInputs extends FieldValues {
  lang: number[];
  namePl: string | null;
  nameEn: string | null;
}

const LANG_ID = {
  pl: 1,
  en: 2,
};

const DEFAULT_VALUES: DietNameInputs = {
  lang: [LANG_ID.pl],
  namePl: "",
  nameEn: "",
};

interface LangOptionIf {
  id: number;
  label: string;
  pathName: Path<DietNameInputs>;
}

export const langOptions: LangOptionIf[] = [
  {
    id: LANG_ID.pl,
    label: "PL",
    pathName: "namePl",
  },
  {
    id: LANG_ID.en,
    label: "EN",
    pathName: "nameEn",
  },
];

export const mapDietNameRequest = (
  values: DietNameInputs,
  showMacros?: boolean,
): PostCreatorDietRequest => {
  const translations = compact([
    { langId: LangDto.PL, name: values.namePl || values.nameEn || "" },
    values.nameEn && { langId: LangDto.EN, name: values.nameEn },
  ]);

  return {
    translations,
    showMacros: !!showMacros,
  };
};

export const mapDietNameForm = (
  data: TranslationDto[] | undefined,
): DietNameInputs | undefined => {
  if (!data) return;

  return {
    namePl: getTranslation(data, "pl"),
    nameEn: getTranslation(data, "en"),
    lang: data.filter(t => t.name).map(t => LANG_ID[t.langId]),
  };
};
