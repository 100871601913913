import { NutrientValueResource } from "@client/resources/NutrientValueResource";
import { useAppParams } from "@hooks";
import useFetchPatientImportantNutrients from "@hooks/queries/client/nutrient/useFetchPatientImportantNutrients";
import useFetchPatientNormNutrients from "@hooks/queries/client/nutrient/useFetchPatientNormNutrients";
import { useMemo } from "react";

export const usePatientImportantNutrients = () => {
  const { patientId } = useAppParams();

  const { data: importants } = useFetchPatientImportantNutrients(
    { patientId: Number(patientId) },
    { enabled: !!patientId },
  );
  const { data: norm } = useFetchPatientNormNutrients(
    { patientId: Number(patientId) },
    { enabled: !!patientId },
  );

  const importantNutrients = useMemo(() => {
    const normMap = new Map(
      norm?.data.nutrients.map(({ id, value }) => [id, value ?? 0]),
    );

    return importants?.data.map(({ id }) => ({
      id,
      value: normMap.get(id) ?? 0,
    }));
  }, [norm, importants]);

  return {
    importantNutrients,
  };
};
