import { IconButton, styled } from "@mui/material";

export const GoogleMeetComponentWrapper = styled("div")`
  display: flex;
  gap: 4px;
  align-items: flex-start;
`;

export const Title = styled("span")`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: ${({ theme }) => theme.palette.primary.main};
`;
export const UrlText = styled("span")`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #4d516e;
`;
