import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const TwoSquares = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 53 54">
    <path
      d="M35.3334 28.9878V38.2628C35.3334 45.992 32.2417 49.0837 24.5126 49.0837H15.2376C7.50841 49.0837 4.41675 45.992 4.41675 38.2628L4.41675 28.9878C4.41675 21.2587 7.50841 18.167 15.2376 18.167H24.5126C32.2417 18.167 35.3334 21.2587 35.3334 28.9878Z"
      stroke="#CFABFF"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48.5834 15.7378V25.0128C48.5834 32.742 45.4917 35.8337 37.7626 35.8337H35.3334L35.3334 28.9878C35.3334 21.2587 32.2417 18.167 24.5126 18.167H17.6667V15.7378C17.6667 8.00866 20.7584 4.91699 28.4876 4.91699L37.7626 4.91699C45.4917 4.91699 48.5834 8.00866 48.5834 15.7378Z"
      stroke="#CFABFF"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
