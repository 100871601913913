import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const updateCreatorDietMeal = (
  dietId: string,
  mealId: string,
  payload: SaveDietMealCreatorRequest,
  version: string,
) => {
  return fetchData(
    `/dietitian/creator-2/diets/${dietId}/meals/${mealId}`,
    APIMethods.PUT,
    payload,
    undefined,
    { "Alloweat-Creator-Version": version },
  );
};

interface TranslationDto {
  langId: string;
  name: string | null;
  description: string | null;
}

export interface SaveDietMealCreatorRequest {
  translations: TranslationDto[];
  mealTypeId: number | null;
  hour: string | null;
  targetKcal: number;
  targetProtein: number;
  targetFat: number;
  targetCarb: number;
}
