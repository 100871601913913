import { TabPanel } from "@mui/lab";
import { Drawer, IconButton, Tab, Tabs, styled } from "@mui/material";

export const StyledDrawer = styled(Drawer)`
  & .MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper {
    display: flex;
    flex-direction: column;
    border-radius: 0;
    margin-top: 56px;
    max-height: calc(100% - 56px);
    width: 440px;
  }
`;

export const DrawerHeader = styled("div")`
  display: grid;
  gap: 12px;
  padding: 16px 16px 0 16px;
  background: ${({ theme }) => theme.palette.primary.light};
  font-size: 20px;
  font-weight: 600;
`;

export const TitleStyled = styled("span")`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;

export const DrawerActions = styled("div")`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 12px 16px;
  background: ${({ theme }) => theme.palette.primary.light};
  width: 100%;
  z-index: 100;
  bottom: 0;
  position: sticky;
`;

export const TabPanelStyled = styled(TabPanel)`
  min-height: max-content;
  flex: 1;
`;

export const TabStyled = styled(Tab)`
  &.MuiButtonBase-root {
    text-transform: unset;
    font-size: 1rem;
    line-height: 24px;
    font-weight: 500;
    min-width: unset;
  }
  &.MuiTab-root {
    padding: 6px 0;
    min-height: unset;
  }
`;

export const TabsStyled = styled(Tabs)`
  &.MuiTabs-root {
    min-height: unset;
  }
  & .MuiTabs-flexContainer {
    gap: 24px;
  }
`;

export const IconButtonStyled = styled(IconButton)`
  position: absolute;
  right: 7px;
  top: 7px;
`;

export const BuilderWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
