import { IconButton, styled } from "@mui/material";
import { getHexColorOpacity } from "@utils";

export const ButtonsWrapper = styled("div")`
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 16px 16px 0 16px;

  & .MuiButtonBase-root.MuiIconButton-root {
    background: ${({ theme }) =>
      getHexColorOpacity(theme.palette.primary.light, 85)};
  }
`;

export const IconButtonStyled = styled(IconButton)`
  background: ${({ theme }) =>
    getHexColorOpacity(theme.palette.primary.light, 70)};
`;
