import * as yup from "yup";
import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";
import { CustomTag, customTagSchema } from "@client/customTags/fetchCustomTags";
import {
  PaginationLinks,
  Metadata,
  MetadataSchema,
  PaginationLinksSchema,
} from "@typeDefinitions";

export const searchClientsNew = async (
  params?: URLSearchParams,
): Promise<ClinicClientDtoNewResponse> => {
  const response = await fetchData(
    "/dietitian/clinic/patients-new",
    APIMethods.GET,
    undefined,
    params,
  );

  return await fetchClinicClientsSchema.validate(response);
};

export interface ClinicClientDtoNew {
  id: number;
  name: string;
  isMobileAppActive: boolean;
  isActive: boolean;
  avatar?: string | null;
  profile: ProfileDto;
  activeUntil: string | null;
  email: string | null;
  tags: CustomTag[];
}

interface ProfileDto {
  dietitian?: IdNameDto | null;
  program: ProgramDto | null;
  schedule: ScheduleDto | null;
}

interface ScheduleDto extends IdDto {
  startedAt: string;
  status: IdDto;
}

interface IdDto {
  id: number;
}

interface ProgramDto extends IdDto {
  startedAt: string;
  finishedAt: string;
  status: IdDto;
}

interface IdNameDto extends IdDto {
  name: string;
}

// Updated to reflect the array of clients
export interface ClinicClientDtoNewResponse {
  data: ClinicClientDtoNew[]; // Change from single object to array
  links: PaginationLinks;
  meta: Metadata;
}

const idSchema = yup.object().shape({
  id: yup.number().required(),
});

const idNameSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
});

const scheduleSchema = yup.object().shape({
  id: yup.number().required(),
  startedAt: yup.string().required(),
  status: idSchema.required(),
});

const programSchema = yup.object().shape({
  id: yup.number().required(),
  startedAt: yup.string().required(),
  finishedAt: yup.string().required(),
  status: idSchema.required(),
});

const profileSchema = yup.object().shape({
  dietitian: idNameSchema.nullable(),
  program: programSchema.nullable(),
  schedule: scheduleSchema.nullable(),
});

const clinicClientSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  isMobileAppActive: yup.boolean().required(),
  isActive: yup.boolean().required(),
  avatar: yup.string().nullable(),
  profile: profileSchema.required(),
  activeUntil: yup.string().nullable().default(null),
  email: yup.string().nullable().default(null),
  tags: yup.array().of(customTagSchema).required(),
});

const fetchClinicClientsSchema = yup.object().shape({
  data: yup.array().of(clinicClientSchema).required(),
  links: PaginationLinksSchema.defined(),
  meta: MetadataSchema.defined(),
});
