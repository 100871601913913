import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import {
  NutrientDto,
  TranslationLangDto,
  nutrientSchema,
  translationLangSchema,
} from "./common";
import { ClientContextDto, clientContextSchema } from "./diets/creator";

export const fetchRecipeProductsSearch = async (
  params: URLSearchParams,
  signal?: AbortSignal,
): Promise<
  ApiResponse<
    RecipeProductSearchItemDto[] | RecipeProductSearchItemClientContextDto[]
  >
> => {
  const response = await fetchData(
    `/dietitian/recipe-products-search`,
    APIMethods.GET,
    undefined,
    params,
    undefined,
    undefined,
    signal,
  );

  return await apiResponseSchema(params).validate(response);
};

const apiResponseSchema = (params: URLSearchParams) => {
  const hasClient = params?.has("client");

  return yup.object({
    data: yup
      .array()
      .of(
        yup.object({
          id: yup.number().required(),
          name: yup.string().required(),
          translations: yup.array().of(translationLangSchema).required(),
          nutrients: yup.array().of(nutrientSchema).required(),
          ...(hasClient
            ? { clientContext: clientContextSchema.required() }
            : {}),
        }),
      )
      .required(),
  });
};

export interface RecipeProductSearchItemDto {
  id: number;
  name: string;
  translations: TranslationLangDto[];
  nutrients: NutrientDto[];
}

export interface RecipeProductSearchItemClientContextDto
  extends RecipeProductSearchItemDto {
  clientContext: ClientContextDto;
}
