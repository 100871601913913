import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  CloneScheduleIntervalRequest,
  duplicateScheduleInterval,
} from "@client/schedule";
import { fetchUsedRecipesQueryKey } from "@hooks/queries/schedule/useFetchUsedRecipesQuery";

import { MutationOptions } from "../types";
import { fetchDietitianProgramScheduleKey } from "./useFetchDietitianProgramScheduleQuery";
import { fetchDietitianClientProgramScheduleKey } from "./useFetchDietitianClientProgramScheduleQuery";

export const useDuplicateScheduleInterval = (options?: MutationOptions) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ programId, payload }: CloneScheduleIntervalPayload) =>
      duplicateScheduleInterval(programId, payload),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries([fetchUsedRecipesQueryKey]);
        queryClient.invalidateQueries([fetchDietitianProgramScheduleKey]);
        queryClient.invalidateQueries([fetchDietitianClientProgramScheduleKey]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};

interface CloneScheduleIntervalPayload {
  programId: string;
  payload: CloneScheduleIntervalRequest;
}
