import { Dispatch, SetStateAction, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import {
  CheckboxChecked,
  CheckboxUnChecked,
  Close,
  EmptyCalendar,
  HeadWithShoulders,
  HeadWithShouldersInJacket,
  Loupe,
  Notebook,
  WateringCan,
} from "@assets/icons";
import { SelectOption } from "@components/FormAutocomplete";
import { useTheme } from "@emotion/react";
import { useAppTranslation, useUserRoles } from "@hooks";
import { useFetchClinicDietitiansSimpleListQuery } from "@hooks/queries/settings";
import { Checkbox, useMediaQuery } from "@mui/material";
import { Metadata } from "@typeDefinitions";
import { ClinicClientsFiltersInput } from "@typeDefinitions/types";
import { useState } from "react";
import { FormProvider } from "react-hook-form";
import {
  CheckCounter,
  ClientsAutocomplete,
  FiltersWrapper,
  MobileAppCheckboxWrapper,
  ProgramsTextField,
  StyledButton,
  StyledFormCheckboxMui,
} from "./ClientsFilters.styled";
import { ClientsFiltersWatch } from "./ClientsFiltersWatch";
import { TabsChipsFiltersForm } from "@components/Filters/TabsChipsFilter/TabsChipsFilterForm";
import { useFetchCustomTagsQuery } from "@hooks/queries/customTags/useFetchCustomTagsQuery";
import useNewMobileAppFeatureEnabled from "@features/useNewMobileAppFeatureEnabled";

interface ClientsFiltersProps {
  submit: Dispatch<SetStateAction<ClinicClientsFiltersInput | undefined>>;
  isLoading: boolean;
  meta?: Metadata;
}

export const ClientsFilters = ({
  submit,
  isLoading,
  meta,
}: ClientsFiltersProps) => {
  const { t } = useAppTranslation();
  const newMobileFeature = useNewMobileAppFeatureEnabled();
  const { colors, palette, breakpoints } = useTheme();
  const { control, watch, resetField, setValue, reset, ...rest } =
    useFormContext();
  const [showFilters, setShowFilters] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const { dietitians } = useFetchClinicDietitiansSimpleListQuery();
  const { tags: customTags } = useFetchCustomTagsQuery();
  const startsFromTablet = useMediaQuery(`${breakpoints.up("medium")}`);
  const { isSchool } = useUserRoles();

  const programsCounter = watch("programs")?.length;
  const checkedMobileApp = watch("activeMobileApp");
  const dietitianArray = watch("dietitians");
  const dietitiansCounter = dietitianArray.length;

  const activeMobileAppLabel = checkedMobileApp
    ? `${t("clients.filters.active_mobile_app")} (${meta?.total})`
    : `${t("clients.filters.active_mobile_app")}`;

  const handleShowSearch = () => {
    setShowSearch(true);
    setShowFilters(false);
  };

  const closeWithReset = () => {
    setShowSearch(false);
    setValue("search", "");
  };

  const cooperationMapped = useMemo<SelectOption[]>(
    () => [
      {
        id: "active-subscription",
        label: t("clients.filters.active_cooperation"),
      },
      {
        id: "inactive-subscription",
        label: t("clients.filters.inactive_cooperation"),
      },
      { id: "all", label: t("clients.filters.all_clients") },
    ],
    [t],
  );

  const programsMapped = useMemo<SelectOption[]>(
    () => [
      { id: "1", label: t("clients.filters.active") },
      { id: "2", label: t("clients.filters.inactive") },
      { id: "3", label: t("clients.filters.starts_in") },
      { id: "4", label: t("clients.filters.ending") },
      { id: "5", label: t("clients.filters.finished") },
      { id: "6", label: t("clients.filters.no_program") },
    ],
    [t],
  );

  const appointmentsMapped = useMemo<SelectOption[]>(
    () => [
      { id: "1", label: t("clients.filters.appointment_added") },
      { id: "2", label: t("clients.filters.upcoming") },
      { id: "3", label: t("clients.filters.canceled") },
      { id: "4", label: t("clients.filters.no_appointment") },
    ],
    [t],
  );

  const dietitiansMapped = useMemo<SelectOption[]>(
    () => [
      ...(dietitians?.map(dietitian => ({
        id: dietitian.id.toString() ?? "",
        label: dietitian.name,
      })) ?? []),
    ],
    [dietitians],
  );

  const customTagsMapped = useMemo<SelectOption[]>(
    () => [
      ...(customTags?.map(tag => ({
        id: tag.id.toString() ?? "",
        label: tag.name,
      })) ?? []),
    ],
    [customTags],
  );

  return (
    <FormProvider
      control={control}
      watch={watch}
      resetField={resetField}
      setValue={setValue}
      reset={reset}
      {...rest}
    >
      <ClientsFiltersWatch submit={submit} isLoading={isLoading} />
      <FiltersWrapper>
        {showSearch && (
          <>
            <ProgramsTextField
              control={control}
              id="search"
              name="search"
              size="small"
              placeholder={t("common.searchPatient")}
              inputRef={input => input && input.focus()}
            />

            <StyledButton onClick={closeWithReset} disableFocusRipple>
              <Close fill={palette.primary.main} />
            </StyledButton>
          </>
        )}
        {!showSearch && (
          <>
            {!startsFromTablet && (
              <div className="flex gap-2">
                <StyledButton
                  onClick={() => setShowFilters(!showFilters)}
                  disableFocusRipple
                >
                  <WateringCan />
                </StyledButton>
                <StyledButton onClick={handleShowSearch} disableFocusRipple>
                  <Loupe fill={palette.primary.main} />
                </StyledButton>
              </div>
            )}

            <ClientsAutocomplete
              id="client"
              name="client"
              control={control}
              options={cooperationMapped}
              size="small"
              placeholder={t("clients.cooperation")}
              startIcon={<HeadWithShoulders />}
              getOptionLabel={options =>
                meta?.total
                  ? `${options.label} (${meta?.total})`
                  : `${options.label}`
              }
              disablePortal
              rebranding
              wide
            />
            {!newMobileFeature && (
              <div className="relative h-[2.5rem]">
                {!!programsCounter && (
                  <CheckCounter>{programsCounter}</CheckCounter>
                )}

                <ClientsAutocomplete
                  id="programs"
                  name="programs"
                  control={control}
                  options={programsMapped}
                  size="small"
                  openOnFocus
                  placeholder={t("clients.filters.nutritional_program")}
                  startIcon={<Notebook />}
                  disableCloseOnSelect
                  multiple
                  placeholderColor={!!programsCounter}
                  renderOption={(props, option, { selected }) => {
                    return (
                      <li {...props}>
                        <Checkbox
                          icon={<CheckboxUnChecked />}
                          checkedIcon={<CheckboxChecked />}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    );
                  }}
                  disablePortal
                />
              </div>
            )}
            {(showFilters || startsFromTablet) && (
              <>
                <MobileAppCheckboxWrapper placeholderColor={!!checkedMobileApp}>
                  <StyledFormCheckboxMui
                    id="activeMobileApp"
                    name="activeMobileApp"
                    control={control}
                    rebranding
                    label={activeMobileAppLabel}
                  />
                </MobileAppCheckboxWrapper>
                <ClientsAutocomplete
                  id="appointment"
                  name="appointment"
                  placeholder={t("clients.visit")}
                  control={control}
                  options={appointmentsMapped ?? []}
                  size="small"
                  startIcon={<EmptyCalendar fill={colors.neutral.dark[700]} />}
                  getOptionLabel={options =>
                    meta?.total
                      ? `${options.label} (${meta?.total})`
                      : `${options.label}`
                  }
                  disablePortal
                  rebranding
                />

                {!isSchool && (
                  <div className="relative h-[2.5rem]">
                    {!!dietitiansCounter && (
                      <CheckCounter>{dietitiansCounter}</CheckCounter>
                    )}

                    <ClientsAutocomplete
                      id="dietitians"
                      name="dietitians"
                      control={control}
                      options={dietitiansMapped}
                      size="small"
                      openOnFocus
                      placeholder={t("clients.filters.lead_dietitian")}
                      startIcon={<HeadWithShouldersInJacket />}
                      disableCloseOnSelect
                      disablePortal
                      multiple
                      placeholderColor={!!dietitiansCounter}
                      renderOption={(props, option, { selected }) => {
                        return (
                          <li {...props} key={option.id}>
                            <Checkbox
                              icon={<CheckboxUnChecked />}
                              checkedIcon={<CheckboxChecked />}
                              checked={selected}
                            />
                            {option.label}
                          </li>
                        );
                      }}
                    />
                  </div>
                )}
              </>
            )}
            {startsFromTablet && (
              <div className="flex gap-2">
                <StyledButton onClick={handleShowSearch} disableFocusRipple>
                  <Loupe fill={palette.primary.main} />
                </StyledButton>
              </div>
            )}
          </>
        )}
      </FiltersWrapper>
      {customTagsMapped.length && (
        <TabsChipsFiltersForm
          name="customTags"
          options={customTagsMapped}
          multiple
        />
      )}
    </FormProvider>
  );
};
