import { useMemo } from "react";
import { FormProvider } from "react-hook-form";

import { QuestionTextDto, TextAnswerResourceDto } from "@client/surveys";
import { useUpdateSingedSurveyMutation } from "@hooks/queries/surveys";

import { useUpdateValidationContext } from "../../context/useUpdateValidationContext";
import { useParseUrl } from "../../useParseUrl";
import { AutosaveOnBlurWatch } from "../AutosaveOnBlurWatch";
import { BlurContextProvider, FormTextFieldWrapper } from "../BlurContext";
import { Question } from "../Question/Question";
import {
  TextAnswerFormInputs,
  mapTextAnswerForm,
  mapTextAnswerRequest,
  useTextAnswerForm,
} from "../forms";

interface QuestionTextProps {
  question: QuestionTextDto;
  answer?: TextAnswerResourceDto;
  listIndex: number;
  totalQuestions: number;
  readOnly?: boolean;
}
export const QuestionText = ({
  question: {
    title,
    description,
    config: { placeholder, required },
    id,
  },
  answer,
  readOnly,
  ...rest
}: QuestionTextProps) => {
  const { url } = useParseUrl();
  const mappedForm = useMemo(
    () => mapTextAnswerForm(answer?.answer),
    [answer?.answer],
  );
  const { formState, handleSubmit, ...form } = useTextAnswerForm(
    mappedForm,
    required,
    true,
  );
  const { mutate, isLoading } = useUpdateSingedSurveyMutation();

  const onSubmit = (data: TextAnswerFormInputs) => {
    if (url) mutate({ payload: mapTextAnswerRequest(data, id), url });
  };

  const { errors, touchedFields } = formState;
  const hasError = !!Object.values(errors).length;
  const isLengthError = errors.value?.type === "max";

  useUpdateValidationContext(id, touchedFields, handleSubmit);
  return (
    <FormProvider formState={formState} handleSubmit={handleSubmit} {...form}>
      <BlurContextProvider>
        <Question
          {...rest}
          title={title}
          description={description}
          required={required}
          hasError={hasError}
          readOnly={readOnly}
        >
          <FormTextFieldWrapper
            control={form.control}
            name="value"
            size="small"
            placeholder={placeholder ?? undefined}
            FormHelperTextProps={{ hidden: !isLengthError }}
            InputProps={{ readOnly, error: !readOnly && hasError }}
          />
        </Question>

        <AutosaveOnBlurWatch onSubmit={onSubmit} isLoading={isLoading} />
      </BlurContextProvider>
    </FormProvider>
  );
};
