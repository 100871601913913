import { useEffect, useState } from "react";
import { useDebounce } from "react-use";
import { FieldValues, useFormContext, useWatch } from "react-hook-form";

interface AutosaveWatchComponentProps<T> {
  onSubmit: (data: T) => void;
  isLoading: boolean;
  isDirtyCondition?: boolean;
}

export function AutosaveWatchComponent<T extends FieldValues>({
  onSubmit,
  isLoading,
  isDirtyCondition = true,
}: AutosaveWatchComponentProps<T>) {
  const values = useWatch<T>();
  const {
    handleSubmit,
    formState: { isDirty },
  } = useFormContext<T>();

  const [queued, setQueued] = useState(false);

  const update = () => {
    setQueued(false);
    handleSubmit(
      data => onSubmit(data),
      e => console.log(e),
    )();
  };

  const [isDirtyLatch, setIsDirtyLatch] = useState(isDirty);

  useDebounce(
    () => {
      if (isLoading) {
        setQueued(true);
      }

      if (
        (isDirty || isDirtyLatch || !isDirtyCondition) &&
        values &&
        !isLoading
      ) {
        setIsDirtyLatch(true);
        update();
      }
    },
    1000,
    [JSON.stringify(values)],
  );

  useEffect(() => {
    if (queued && !isLoading) {
      update();
    }
  }, [queued, isLoading]);

  return <></>;
}
