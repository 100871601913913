import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const postMediaRecipeUpload = async (
  formData: FormData,
  signal?: AbortSignal,
): Promise<ApiResponse<MediaResourceDto>> => {
  const response = await fetchData(
    `/dietitian/media/recipe/upload`,
    APIMethods.POST,
    formData,
    undefined,
    { "Content-Type": "multipart/form-data" },
    undefined,
    signal,
  );

  return responseSchema.validate(response);
};

enum CollectionType {
  PROGRAM_SECTION_TEMPLATE = "program-section-template",
  VISIT_SCHEDULE = "visit-schedule",
  CLINIC_LOGO = "clinic-logo",
  USER_AVATAR = "user-avatar",
  FOOD_GROUP = "food-group",
  RECIPE = "recipe",
  COLLECTION = "collection",
  DIET_MEAL = "diet-meal",
  DIET = "diet",
  PRODUCT = "product",
  PROGRAM_PDF = "program_pdf",
  PROGRAM_PDF_IMAGES = "program_pdf_images",
  PATIENT_MEASUREMENT_SILHOUETTE = "patient-measurement-silhouette",
  PATIENT_FILE = "patient-file",
}

export interface MediaResourceDto {
  id: number;
  clinicId: number | null;
  name: string;
  originalName: string;
  mineType: string;
  collection: CollectionType;
  size: number;
  path: string;
  url: string;
  s3Url: string;
}

const mediaResourceSchema = yup.object().shape({
  id: yup.number().required(),
  clinicId: yup.number().nullable().default(null),
  name: yup.string().required(),
  originalName: yup.string().required(),
  mineType: yup.string().required(),
  collection: yup
    .mixed<CollectionType>()
    .oneOf(Object.values(CollectionType))
    .required(),
  size: yup.number().required(),
  path: yup.string().required(),
  url: yup.string().url().required(),
  s3Url: yup.string().url().required(),
});

const responseSchema = yup.object().shape({
  data: mediaResourceSchema.required(),
});
