import { LoadingButton } from "@mui/lab";
import { Button, Card, styled, Tab } from "@mui/material";

export const HeadSection = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledCard = styled(Card)`
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.tablet}px) {
    display: flex;
  }
`;

export const TabsContainer = styled("div")`
  background-color: ${({ theme }) => theme.colors.lightPurple};
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.tablet}px) {
    border-right: solid 1px ${({ theme }) => theme.palette.divider};
    padding-left: 1.75rem;
  }
`;

export const StyledTab = styled(Tab)`
  padding: 0.75rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.tablet}px) {
    padding-left: 0;
  }
`;

export const ButtonStyled = styled(LoadingButton)`
  margin-right: 1.75rem;
  margin-bottom: 1.75rem;
`;

export const StyledHeadAddBtn = styled(Button)`
  height: 1.75rem;
`;

export const ViewWrapper = styled("div")`
  display: grid;
  gap: 2rem;
`;

export const DeskDivider = styled("div")`
  width: 1px;
  height: 100%;
  background: ${({ theme }) => theme.palette.grey[300]};
`;
