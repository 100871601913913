import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MutationOptions } from "./types";
import { fetchClientQueryKey } from "./useFetchClientQuery";
import { fetchPatientProgramQueryKey } from "./useFetchPatientProgramQuery";
import { updateClient, UpdateClientRequest } from "@client";
import { AxiosError } from "axios";
import { ApiErrorIf } from "@typeDefinitions";

export const useUpdateClientMutation = (
  id: number,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, AxiosError<ApiErrorIf>, UpdateClientRequest>(
    payload => updateClient(id, payload),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries([fetchClientQueryKey, id]);
        queryClient.invalidateQueries([fetchPatientProgramQueryKey, id]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};
