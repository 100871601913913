import { useContext, useMemo } from "react";
import { useController, UseFieldArrayRemove } from "react-hook-form";

import {
  Autocomplete,
  IconButton,
  InputAdornment,
  TableRow,
  TextField,
} from "@mui/material";
import { isArray } from "lodash";

import { RecipeEditInput } from "@components/RecipeForm/useRecipeEditForm";
import { useAppTranslation } from "@hooks";
import { Close } from "@assets/icons";
import { RecipeProductsContext } from "@context";
import { fetchFood } from "@client";
import { decimalInput0 } from "@utils/inputs";
import { useVisibleNutrients } from "@context/VisibleNutrientsContext";

import { SearchProductsAutocomplete } from "./SearchProductsAutocomplete/SearchProductAutocomplete";
import {
  AutocompleteWrapper,
  CellWrapper,
  MeasureSuffixStyled,
  ProductInputWrapper,
  StyledDragHandle,
  StyledFormTextField,
  StyledTableCell,
} from "./RecipeTableForm.styled";
import { MeasureAmountTextField } from "./MeasureAmountTextField";
import { GLYCEMIC_INDEX_ID } from "@utils";
import { MACROS_ARRAY } from "@utils/macros";

import { MicrosRow } from "./MicrosRow";
import { StepsArrowButtons } from "@components/StepsArrowButtons";

interface RecipeTableRowProps {
  index: number;
  remove: UseFieldArrayRemove;
}

export const RecipeTableRow = ({ index, remove }: RecipeTableRowProps) => {
  const { t, isPolishChosen } = useAppTranslation();
  const {
    field: {
      onChange,
      value: { grams, productId, measureId, wrapperId },
    },
    fieldState: { error },
  } = useController<RecipeEditInput, `ingredients.${number}`>({
    name: `ingredients.${index}`,
  });

  const { nutrients: visibleNutrients } = useVisibleNutrients();
  const visibleNutrientsMapped = visibleNutrients?.map(n => Number(n)) ?? [];
  const { getProduct, addProduct } = useContext(RecipeProductsContext);
  const product = getProduct(Number(productId));

  const measures = useMemo(
    () =>
      product?.measures.map(m => ({
        id: m.id.toString(),
        label: isPolishChosen ? m.description : m.descriptionEn,
        suffix: `(${m.grams} g)`,
      })) ?? [],
    [product, isPolishChosen],
  );

  const productName = isPolishChosen
    ? product?.descriptionPl
    : product?.description;

  const calculatedNutrients = useMemo(() => {
    return (
      product?.nutrients.map(nutrient => {
        if (nutrient.id === GLYCEMIC_INDEX_ID)
          return {
            id: nutrient.id,
            value: nutrient.value,
          };
        else
          return {
            id: nutrient.id,
            value: nutrient.value * (Number(grams) / 100),
          };
      }) ?? []
    );
  }, [grams, product]);

  const selectedProduct = product && {
    id: product.id.toString(),
    label: productName ?? product.id.toString(),
  };

  const removeIngredient = () => remove(index);
  const handleSelect = async (id: string[] | string | null) => {
    if (isArray(id) || !id) return;
    const product = await fetchFood(id);

    addProduct({
      id: product.data.id,
      descriptionPl: product.data.descriptionPl ?? "",
      description: product.data.description ?? "",
      measures: product.data.measures,
      nutrients: product.data.nutrients,
    });

    onChange({
      grams: grams,
      productId: product.data.id,
      wrapperId: wrapperId,
      measureId: product.data.measures[0]?.id.toString(),
    });
  };

  const handleGrams = (value: string) => {
    onChange({
      grams: value === "" ? "0" : value,
      productId: productId,
      wrapperId: wrapperId,
      measureId: measureId,
    });
  };

  const handleMeasures = (measureId: string) => {
    onChange({
      grams: grams,
      productId: productId,
      wrapperId: wrapperId,
      measureId: measureId,
    });
  };

  const onIncrementGrams = () => {
    onChange({
      grams: `${Number(grams) + 1}`,
      productId,
      wrapperId,
      measureId,
    });
  };

  const onDecrementGrams = () => {
    const parsedGrams = Number(grams);
    onChange({
      grams: `${parsedGrams > 0 ? parsedGrams - 1 : 0}`,
      productId,
      wrapperId,
      measureId,
    });
  };

  return (
    <TableRow>
      <StyledTableCell sticky>
        <CellWrapper>
          <StyledDragHandle className="dragHandle" />
          <ProductInputWrapper>
            <AutocompleteWrapper>
              <SearchProductsAutocomplete
                selectedProduct={selectedProduct}
                onSelect={handleSelect}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      color="primary"
                      onClick={removeIngredient}
                      size="small"
                    >
                      <Close />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </AutocompleteWrapper>
          </ProductInputWrapper>
        </CellWrapper>
      </StyledTableCell>
      <StyledTableCell>
        <MeasureAmountTextField
          onChange={handleGrams}
          index={index}
          product={product}
        />
      </StyledTableCell>
      <StyledTableCell>
        <Autocomplete
          value={measures.find(({ id }) => id === measureId)}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              placeholder={t("recipe.ingredients_table.other_measure")}
              InputProps={{
                ...params.InputProps,
                renderSuffix: () => (
                  <MeasureSuffixStyled>
                    {measures.find(({ id }) => id === measureId)?.suffix}
                  </MeasureSuffixStyled>
                ),
              }}
            />
          )}
          options={measures ?? []}
          onChange={(_, { id }) => handleMeasures(id)}
          disableClearable
          clearOnBlur
          clearOnEscape
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              <span className="flex-1">{option.label}</span>
              <MeasureSuffixStyled>{option.suffix}</MeasureSuffixStyled>
            </li>
          )}
        />
      </StyledTableCell>
      <StyledTableCell>
        <StyledFormTextField
          focused={!!error}
          variant="standard"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                g
                <StepsArrowButtons
                  clickTop={onIncrementGrams}
                  clickDown={onDecrementGrams}
                />
              </InputAdornment>
            ),
            inputComponent: decimalInput0,
          }}
          value={grams}
          onChange={e => handleGrams(e.target.value)}
          error={!!error}
        />
      </StyledTableCell>
      <MicrosRow
        nutrients={calculatedNutrients}
        visibleNutrients={[...MACROS_ARRAY, ...visibleNutrientsMapped]}
      />
    </TableRow>
  );
};
