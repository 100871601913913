import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import * as yup from "yup";
import { IdDto, idSchema } from "./common";

export const fetchClientTags = async (
  patientId: string,
): Promise<ApiResponse<FetchClientTagsResponse[]>> => {
  const response = await fetchData(
    `/dietitian/patients/${patientId}/tags`,
    APIMethods.GET,
  );

  return await fetchClientTagsSchema.validate(response);
};

const clientTagSchema = yup.object().shape({
  id: yup.number().required(),
  category: idSchema.required(),
});

const fetchClientTagsSchema = yup.object().shape({
  data: yup.array().of(clientTagSchema).required(),
});

export interface FetchClientTagsResponse {
  id: number;
  category: IdDto;
}
