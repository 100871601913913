import { useMemo } from "react";
import { FormProvider } from "react-hook-form";

import { QuestionType, SystemQuestionDto } from "@client/surveys";
import { useAppTranslation } from "@hooks";
import { useUpdateSurveySubgroupQuestionMutation } from "@hooks/queries/surveys";

import { Question } from "../Question/Question";
import { QuestionEditWatch } from "../QuestionEditWatch";
import { RequiredConfigFormInputs } from "../forms";
import {
  mapSystemQuestionConfigForm,
  mapSystemQuestionConfigRequest,
  useSystemQuestionConfigForm,
} from "../forms/useSystemQuestionConfigForm";
import {
  OptionText,
  OptionWrapper,
  RadioStyled,
} from "./SystemQuestionsSex.styled";
import { LangDto } from "@client";

interface SystemQuestionSexProps {
  listIndex: number;
  totalQuestions: number;
  question: SystemQuestionDto;
  onTypeChange: (currentType: QuestionType, newType: QuestionType) => void;
  questionnaireLang?: LangDto;
}

export const SystemQuestionSex = ({
  listIndex,
  totalQuestions,
  question,
  onTypeChange,
  questionnaireLang,
}: SystemQuestionSexProps) => {
  const { t } = useAppTranslation();
  const mappedForm = useMemo(
    () =>
      mapSystemQuestionConfigForm({
        ...question,
        title: t("questionnaires.questions.system_sex.question", {
          lng: questionnaireLang,
        }),
      }),
    [question],
  );
  const form = useSystemQuestionConfigForm(mappedForm);
  const { mutate } = useUpdateSurveySubgroupQuestionMutation();

  const onSubmit = (data: RequiredConfigFormInputs) => {
    mutate({
      id: question.id.toString(),
      payload: mapSystemQuestionConfigRequest(data, question),
    });
  };

  return (
    <FormProvider {...form}>
      <Question
        id={question.id}
        listIndex={listIndex}
        totalQuestions={totalQuestions}
        onTypeChange={onTypeChange}
        questionType={question.type}
        systemQuestion
        infoText={t("questionnaires.questions.system_sex.info")}
      >
        <OptionWrapper>
          <RadioStyled disabled />

          <OptionText>
            {t("questionnaires.questions.system_sex.woman")}
          </OptionText>
        </OptionWrapper>
        <OptionWrapper>
          <RadioStyled disabled />

          <OptionText>
            {t("questionnaires.questions.system_sex.man")}
          </OptionText>
        </OptionWrapper>
        <OptionWrapper>
          <RadioStyled disabled />

          <OptionText>
            {t("questionnaires.questions.system_sex.dont_specify")}
          </OptionText>
        </OptionWrapper>
      </Question>

      <QuestionEditWatch isLoading={false} onSubmit={onSubmit} />
    </FormProvider>
  );
};
