import { useCallback, useContext, useEffect, useMemo } from "react";

import { useAppTranslation, useClientBalanceForecast } from "@hooks";

import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";
import { ONE_KG_IN_KCAL } from "@components/MassForecast";
import { SimulationSlidersContext } from "@views/dietician/PatientEnergyBalance/context/SimulationSlidersContext";

import { SingleSimulationSlider } from "./SingleSimulationSlider";
import { SlidersWrapper } from "./SimulationSliders.styled";
import { round } from "lodash";
import { labelFactory } from "@components/MacrosSliders";

interface SimulationSlidersProps {
  pace: number;
  setPace: (pace: number) => void;
}

export const SimulationSliders = ({
  pace,
  setPace,
}: SimulationSlidersProps) => {
  const { t } = useAppTranslation();
  const { massToChange, isError, isLoading } = useClientBalanceForecast();
  const massAbsolute = Math.abs(massToChange ?? 1);
  const { setTotalKcalDiff, setWeeksToGoal, weeksToGoal } = useContext(
    SimulationSlidersContext,
  );

  useEffect(() => {
    setTotalKcalDiff(pace * ONE_KG_IN_KCAL);
    setWeeksToGoal(Math.ceil(massAbsolute / pace));
  }, [pace, massAbsolute]);

  useEffect(() => {
    setPace(SAFE_PACE);
  }, []);

  const handleChangePace = useCallback(
    (val: number) => setPace(val),
    [massAbsolute],
  );

  const handleChangeGoal = useCallback(
    (val: number) => setPace(val && massAbsolute / val),
    [massAbsolute],
  );

  const weeksValueLabelFormat = useMemo(
    () => labelFactory(weeksToGoal, 1, 50),
    [weeksToGoal],
  );

  if (isLoading) return <Spinner />;

  if (isError) return <ApiError />;

  return (
    <SlidersWrapper>
      <SingleSimulationSlider
        value={weeksToGoal}
        onChange={handleChangeGoal}
        title={t("balance.target_time")}
        unit={t("balance.weeks", { count: weeksToGoal })}
        min={DEFAULT_INT}
        max={MAX_WEEKS_RANGE}
        disabled={!massToChange}
        valueLabelFormat={weeksValueLabelFormat}
      />
      <SingleSimulationSlider
        value={round(pace, 2)}
        onChange={handleChangePace}
        title={t("balance.pace_change")}
        unit={t("balance.kg_per_week")}
        min={DEFAULT_FLOAT}
        max={MAX_KG_RANGE}
        step={DEFAULT_FLOAT}
        float
        disabled={!massToChange}
      />
    </SlidersWrapper>
  );
};

const DEFAULT_FLOAT = 0.01;
const DEFAULT_INT = 1;
const SAFE_PACE = 0.5;
const MAX_WEEKS_RANGE = 50;
const MAX_KG_RANGE = 5;
