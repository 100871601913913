import { Box, Stack, Tooltip } from "@mui/material";
import { useAppTranslation } from "@hooks";
import { InfoIcon } from "@icons/index";

const SharedColumnName = () => {
  const { t } = useAppTranslation();

  return (
    <Stack direction="row" alignItems="center">
      <span>{t("patient.programs_grid.columns.visible_app")}</span>
      <Tooltip
        title={t("patient.programs_grid.row.shared.tooltip")}
        arrow
        placement="top"
      >
        <Box>
          <InfoIcon fontSize="24px" color="#727272" />
        </Box>
      </Tooltip>
    </Stack>
  );
};

export default SharedColumnName;
