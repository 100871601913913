import { memo } from "react";

import { useAppTranslation } from "@hooks";
import { NutrientCategory } from "@typeDefinitions";
import { useIdMapContext } from "@context";
import { getCategoryColor } from "@utils/constants";

import { MicronutrientElement } from "./MicronutrientsElement";

interface MicronutrientsCategoryProps {
  category: NutrientCategory;
}

export const MicronutrientsCategory = memo(
  ({ category }: MicronutrientsCategoryProps) => {
    const { t, isPolishChosen } = useAppTranslation();
    const { idMap } = useIdMapContext();

    return (
      <div className="col-span-1">
        <div
          className="h-8 border-b-2 grid grid-cols-7 "
          style={{ borderColor: getCategoryColor(category.id) }}
        >
          <span className="font-semibold text-sm col-span-5 flex justify-start items-center">
            {isPolishChosen ? category.namePl : category.nameEn}
          </span>
          <span className="text-sm col-span-2 flex justify-end items-center">
            {t("patients.programs.dietitian_visible")}
          </span>
        </div>
        <div className="flex flex-col">
          {Object.keys(idMap).length &&
            category.nutrients.map(nutrient => (
              <MicronutrientElement element={nutrient} key={nutrient.id} />
            ))}
        </div>
      </div>
    );
  },
);
