import { UpdateSurveyRequest, updateSurvey } from "@client/surveys";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "../types";
import { fetchSurveyFullQueryKey } from "./useFetchSurveyFullQuery";

export const useUpdateSurveyMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, UpdateSurveyMutationPayload>(
    ({ id, payload }) => updateSurvey(id, payload),
    {
      ...options,
      onSuccess: (data, variables) => {
        options?.onSuccess && options.onSuccess(data, variables);
        queryClient.invalidateQueries([fetchSurveyFullQueryKey, variables.id]);
      },
    },
  );
};

interface UpdateSurveyMutationPayload {
  id: string;
  payload: UpdateSurveyRequest;
}
