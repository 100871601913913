import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const cloneClientProgramToBase = async (
  patientId: number,
  programId: number,
  payload: CloneClientProgramToBaseRequest,
) => {
  return await fetchData(
    `/dietitian/patients/${patientId}/programs/${programId}/store-in-base`,
    APIMethods.POST,
    payload,
  );
};

export interface CloneClientProgramToBaseRequest {
  name: string;
}
