import { useFormContext, useWatch } from "react-hook-form";
import {
  ClientsFiltersInputNew,
  mapPatientsFiltersParams,
} from "./useClientsFiltersForm";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ClinicClientsFiltersInput } from "@typeDefinitions/types";
import { useDebounce } from "react-use";

interface ClientsFiltersWatchProps {
  submit: Dispatch<SetStateAction<ClinicClientsFiltersInput | undefined>>;
  isLoading: boolean;
}
export const ClientsFiltersWatch = ({
  submit,
  isLoading,
}: ClientsFiltersWatchProps) => {
  const values = useWatch<ClientsFiltersInputNew>();
  const { handleSubmit } = useFormContext<ClientsFiltersInputNew>();

  const [queued, setQueued] = useState(false);

  const update = () => {
    setQueued(false);
    handleSubmit(
      data => submit(mapPatientsFiltersParams(data)),
      err => console.log(err),
    )();
  };

  useDebounce(
    () => {
      if (isLoading) {
        setQueued(true);
      }

      if (values && !isLoading) {
        update();
      }
    },
    500,
    [JSON.stringify(values)],
  );

  useEffect(() => {
    if (queued) {
      update();
    }
  }, [queued]);

  return <></>;
};
