import { Link, useNavigate } from "react-router-dom";

import { Button, Tooltip } from "@mui/material";

import { useFoodGroupTreeFetchQuery } from "@hooks/queries/dictionaries";
import { useFoodGroupTreeOptions } from "@components/FoodGroupSelect";
import { useTreeSelectTitle } from "@components/TreeSelect";
import { LabeledDetail, PreviewDetails } from "@components/PreviewDetails";
import { ExpandableChips } from "@components/ExpandableChips";
import { Nutrient } from "@typeDefinitions";
import { useAppParams, useAppTranslation } from "@hooks";
import { useDeleteProductMutation } from "@hooks/queries";
import { PRODUCTS } from "@routes";

interface ProductMainDetailsProps {
  name: string | null | undefined;
  producer: string | null;
  categoryId: string;
  nutrients: Nutrient[];
  tags?: string[];
  canEdit: boolean;
  canDelete: boolean;
  canToggleVisibility: boolean;
}

export const ProductMainDetails = ({
  name,
  producer,
  nutrients,
  tags,
  categoryId,
  canEdit,
  canDelete,
  canToggleVisibility,
}: ProductMainDetailsProps) => {
  const { productId } = useAppParams();
  const { t } = useAppTranslation();
  const navigate = useNavigate();

  const { data: foodGroupTree, isLoading } = useFoodGroupTreeFetchQuery();
  const options = useFoodGroupTreeOptions(foodGroupTree);
  const categoryPath = useTreeSelectTitle(options, categoryId);
  const { mutate } = useDeleteProductMutation({
    onSuccess: () => navigate("/products"),
  });

  return (
    <>
      <PreviewDetails
        name={name ?? "---"}
        nutrients={nutrients}
        id={productId}
        path={PRODUCTS}
        actions={{
          canEdit,
          canDelete,
          canVisible: canToggleVisibility,
        }}
        onDelete={id => mutate(Number(id))}
        editTooltip={t("product.edit.unable")}
        deleteTooltip={t("product.delete.unable")}
      />
      <div className="flex justify-between">
        <div className="flex gap-4">
          <LabeledDetail
            label={t("product.producer")}
            value={producer ? producer : "---"}
          />
          <LabeledDetail
            label={t("product.category")}
            value={isLoading ? "..." : categoryPath}
          />
        </div>
        {canEdit ? (
          <Link to={`/products/${productId}/edit`}>
            <Button variant="outlined">{t("common.edit")}</Button>
          </Link>
        ) : (
          <Tooltip title={t("product.edit.unable")}>
            <div>
              <Button disabled variant="outlined">
                {t("common.edit")}
              </Button>
            </div>
          </Tooltip>
        )}
      </div>
      {tags && <ExpandableChips variant="newFilled" labels={tags} />}
    </>
  );
};
