import * as yup from "yup";

import { NutrientDto, nutrientSchema } from "@client/common";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const fetchCreatorDietNutrients = async (
  dietId: string,
): Promise<ApiResponse<NutrientDto[]>> => {
  const response = await fetchData(
    `/dietitian/creator-2/diets/${dietId}/nutrients`,
    APIMethods.GET,
  );

  return creatorDietNutrientsResponseSchema.validate(response);
};

export interface CreatorDietNutrientsResponse {
  data: NutrientDto[];
}

export const creatorDietNutrientsResponseSchema = yup.object().shape({
  data: yup.array().of(nutrientSchema).required(),
});
