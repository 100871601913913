import { useQuery } from "@tanstack/react-query";
import { QueryOptions } from "./types";
import { buildDefaultQueryOptions } from "./common";
import { fetchClientSources } from "@client";

const fetchClientSourcesQueryKey = "fetchClientSourcesQueryKey";

export const useFetchClientSourcesQuery = (options?: QueryOptions) => {
  const { data, ...rest } = useQuery(
    [fetchClientSourcesQueryKey],
    fetchClientSources,
    buildDefaultQueryOptions(options),
  );

  return {
    sources: data?.data,
    ...rest,
  };
};
