import { memo, useMemo } from "react";

import classNames from "classnames";

import { Chevron } from "@assets/icons";

const renderPageLabel = (label: string): JSX.Element | string => {
  const labelLowercased = label.toLowerCase();
  if (labelLowercased.includes("previous"))
    return <Chevron className="stroke-current transform rotate-90" />;
  if (labelLowercased.includes("next"))
    return <Chevron className="stroke-current transform -rotate-90" />;
  return label;
};

/** Pagination for lists */
export const Pagination = memo((props: PaginationProps) => {
  const { links, onClick } = props;

  const showPagination = useMemo((): boolean => {
    const numOfLinks = links.filter(
      link =>
        !link.label.toLowerCase().includes("previous") &&
        !link.label.toLowerCase().includes("next"),
    ).length;
    return numOfLinks > 1;
  }, [links]);

  return (
    <div className="w-full flex justify-center pt-3 mt-3">
      {showPagination &&
        links.map((link, idx) => (
          <div
            key={idx}
            onClick={() => onClick(link.url, idx)}
            className={classNames({
              "py-4 px-5 rounded-md": true,
              "cursor-pointer hover:text-primary": link.url,
              "cursor-not-allowed": !link.url,
              "mr-2": idx !== links.length - 1,
              "text-primary": link.active,
            })}
          >
            {renderPageLabel(link.label)}
          </div>
        ))}
    </div>
  );
});

export interface PaginationLink {
  /** Page number or label */
  label: string;
  /** Is link active */
  active: boolean;
  /** URL to paginated page */
  url: string | null;
}

export interface PaginationProps {
  /** Pagination links */
  links: PaginationLink[];
  /** On click handler */
  onClick: (url: string | null, page?: number) => void;
}
