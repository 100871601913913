import { styled } from "@mui/material";

interface StyledElementProps {
  disabled?: boolean;
}

export const StyledElement = styled("div")<StyledElementProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.border}`};
  padding: 0.5rem 1rem 0.3rem;
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.colors.yellowLight : ""};
`;

export const NutrientWrapper = styled("div")`
  width: 8rem;
  min-width: 6rem;
  height: 2.75rem;
`;
