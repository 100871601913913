import { postRenderMainPhoto } from "@client/meals";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "../types";
import { fetchCreatorMealQueryKey } from "../useFetchCreatorMealQuery";

export const usePostRenderMainPhotoMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, string>(
    mealId => postRenderMainPhoto(mealId),
    {
      ...options,
      onSuccess: (d, mealId) => {
        options?.onSuccess && options.onSuccess(d, mealId);
        queryClient.invalidateQueries([fetchCreatorMealQueryKey, mealId]);
      },
    },
  );
};
