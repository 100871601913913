import { useContext, useMemo } from "react";

import dayjs from "dayjs";
import { capitalize, chunk, range } from "lodash";
import { useMediaQuery, useTheme } from "@mui/material";

import { useAppParams } from "@hooks";
import {
  useFetchMonitoringProgramDayCalendarQuery,
  useFetchMonitoringScheduleCalendarQuery,
} from "@hooks/queries";

import {
  BoldTypography,
  StyledTD,
  StyledTH,
} from "./MonitoringCalendar.styled";
import { MonitoringDay } from "./MonitoringDay";
import { mapEmpyCalendarDays } from "../monitoringUtils";
import { MonitoringContext } from "../context/MonitoringContext";

export const MonitoringCalendarTable = () => {
  const { patientId } = useAppParams();
  const { breakpoints } = useTheme();
  const downMedium = useMediaQuery(`${breakpoints.down("medium")}`);

  const today = dayjs();
  const { month, selectedDayDate, setSelectedDayDate, toggleButton } =
    useContext(MonitoringContext);

  const { programDayDays } = useFetchMonitoringProgramDayCalendarQuery(
    patientId,
    month.startOf("month").format("YYYY-MM-DD"),
    month.endOf("month").format("YYYY-MM-DD"),
    { enabled: toggleButton === "program" },
  );
  const { scheduleDays } = useFetchMonitoringScheduleCalendarQuery(
    patientId,
    month.startOf("month").format("YYYY-MM-DD"),
    month.endOf("month").format("YYYY-MM-DD"),
    { enabled: toggleButton === "schedule" },
  );

  const { prevMonthEmptyDays, currMonthEmptyDays, nextMonthEmptyDays } =
    mapEmpyCalendarDays(month);
  const currMonthDays = useMemo(
    () =>
      (toggleButton === "program" ? programDayDays : scheduleDays) ??
      currMonthEmptyDays,
    [currMonthEmptyDays, programDayDays, scheduleDays, toggleButton],
  );

  const handleDaySelect = (date: string) => setSelectedDayDate(date);

  return (
    <table>
      <thead>
        <tr>
          {range(7).map((weekday, idx) => (
            <StyledTH key={idx}>
              <BoldTypography>
                {capitalize(
                  today.weekday(weekday).format(downMedium ? "dd" : "dddd"),
                )}
              </BoldTypography>
            </StyledTH>
          ))}
        </tr>
      </thead>
      <tbody>
        {chunk(
          [...prevMonthEmptyDays, ...currMonthDays, ...nextMonthEmptyDays],
          7,
        ).map((week, weekIdx) => (
          <tr key={weekIdx}>
            {week.map((day, idx) => {
              if (typeof day === "number")
                return (
                  <StyledTD key={idx}>
                    <MonitoringDay key={idx} dayNumber={day + 1} />
                  </StyledTD>
                );
              else
                return (
                  <StyledTD key={idx}>
                    <MonitoringDay
                      dayNumber={Number(day.date.slice(-2))}
                      date={day.date}
                      nutrients={day.foodDiary?.nutrients}
                      targetNutrients={day.target?.nutrients}
                      hasNote={!!day.foodDiary?.note.patient}
                      isReported={day.foodDiary?.isReported}
                      isToday={today.format("YYYY-MM-DD") === day.date}
                      onClick={() => handleDaySelect(day.date)}
                      selected={selectedDayDate === day.date}
                    />
                  </StyledTD>
                );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
