import { Card, styled } from "@mui/material";
import { getHexColorOpacity } from "@utils";

export const Title = styled("span")`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const CardStyled = styled(Card)`
  display: grid;
  gap: 16px;
  border-radius: 16px;
  padding: 24px;
  box-shadow: ${({ theme }) => theme.boxShadows.card};
  border: 2px solid ${({ theme }) => theme.colors.neutral.light[100]};
  background: radial-gradient(
    100% 1757.1% at 0% 0%,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(255, 255, 255, 0) 100%
  );
`;
