import { IconButton, styled } from "@mui/material";

export const StyledArrowButton = styled(IconButton)`
  height: 14px;
  padding: 0;
  border-radius: 4px;
  width: 16px;

  & svg {
    height: 18px;
    width: 18px;
  }
`;
