import { useState } from "react";
import { Control, useController } from "react-hook-form";

import { InputAdornment, TextField, useTheme } from "@mui/material";
import dayjs from "dayjs";

import { Calendar } from "@assets/icons/DrawerMenu";
import { DateRangePicker } from "@components/DateRangePicker";
import { CalendarButtonProps } from "@components/DateRangePicker/CalendarCustomButtons";
import { useAppTranslation } from "@hooks";

import { ErrorText } from "@components/ErrorText";
import { AutogenerateFormInputs } from "./useAutogenerateForm";

interface MealDateRangePickerProps {
  control: Control<AutogenerateFormInputs>;
}

export const MealDateRangePicker = ({ control }: MealDateRangePickerProps) => {
  const { t } = useAppTranslation();
  const { colors } = useTheme();
  const {
    field: { value: startDate, onChange: setStartDate },
    fieldState: { error },
  } = useController({ control, name: "startDate" });
  const {
    field: { value: endDate, onChange: setEndDate },
  } = useController({ control, name: "endDate" });
  const daysDiff = dayjs(endDate).diff(startDate, "days");
  const daysDiffIncludingLastDay = daysDiff + LAST_DAY_REPRESENTATION;
  const [selectedButton, setSelectedButton] = useState<number | null>(
    PROGRAM_WEEKS_LIST.findIndex(d => d === daysDiff) ?? null,
  );

  const handleSelectRange = ([start, end]: [Date | null, Date | null]) => {
    setStartDate(start);
    setEndDate(end);
  };

  const handleRangeButtonSelect = (idx: number) => {
    const selectedWeekDays = PROGRAM_WEEKS_LIST[idx];

    handleSelectRange([
      dayjs(startDate).toDate(),
      dayjs(startDate).add(selectedWeekDays, "days").toDate(),
    ]);
    setSelectedButton(idx);
  };

  const customButtons: CalendarButtonProps[] = PROGRAM_WEEKS_LIST.map(
    (days, idx) => {
      const daysIncludingLastDay = days + LAST_DAY_REPRESENTATION;
      return {
        id: idx,
        label: `${daysIncludingLastDay} ${t("common.days", {
          count: daysIncludingLastDay,
        })}`,
        onClick: () => handleRangeButtonSelect(idx),
        selected:
          PROGRAM_WEEKS_LIST.includes(daysDiff) && selectedButton === idx,
      };
    },
  );

  return (
    <div className="w-full">
      <DateRangePicker
        startDate={startDate ?? null}
        endDate={endDate ?? null}
        minDate={new Date()}
        portalId="autogenerate-modal"
        customInput={
          <TextField
            helperText={<ErrorText content={error?.message} />}
            error={!!error}
            fullWidth
            size="small"
            InputProps={{
              startAdornment: (
                <Calendar
                  style={{ marginRight: "8px" }}
                  fill={colors.neutral.dark[700]}
                />
              ),
              endAdornment: !!daysDiff && (
                <InputAdornment position="end">
                  ({daysDiffIncludingLastDay}{" "}
                  {t("common.days", { count: daysDiffIncludingLastDay })})
                </InputAdornment>
              ),
            }}
            placeholder={t("autogenerate.select_date_range")}
          />
        }
        onRangeChange={handleSelectRange}
        customButtons={customButtons}
      />
    </div>
  );
};

export const LAST_DAY_REPRESENTATION = 1;

export enum ProgramLengthWeeks {
  ONE = 6,
  TWO = 13,
  THREE = 20,
  FOUR = 27,
}

export const PROGRAM_WEEKS_LIST = [
  ProgramLengthWeeks.ONE,
  ProgramLengthWeeks.TWO,
  ProgramLengthWeeks.THREE,
  ProgramLengthWeeks.FOUR,
];
