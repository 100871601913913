import { useQuery } from "@tanstack/react-query";

import {
  fetchClientLastMeasurement,
  FetchClientLastMeasurementResponse,
} from "@client";
import { ApiResponse } from "@typeDefinitions";

import { QueryOptionsTyped } from "./types";

export const fetchClientLastMeasurementQueryKey =
  "fetchClientLastMeasurementQueryKey";

export const useFetchClientLastMeasurementQuery = (
  id: string,
  options?: QueryOptionsTyped<ApiResponse<FetchClientLastMeasurementResponse>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchClientLastMeasurementQueryKey, id],
    () => fetchClientLastMeasurement(id),
    options,
  );

  return {
    lastMeasurement: data?.data,
    ...rest,
  };
};
