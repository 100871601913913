import { useQuery } from "@tanstack/react-query";
import { QueryOptions, QueryOptionsTyped } from "./types";
import { buildDefaultQueryOptions } from "./common";
import { NormNutrientDto, fetchNormNutrients } from "@client";
import { ApiResponse } from "@typeDefinitions";

const fetchNormNutrientsQueryKey = "fetchNormNutrientsQueryKey";

export const useFetchNormNutrients = (
  id: number,
  options?: QueryOptionsTyped<ApiResponse<NormNutrientDto[]>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchNormNutrientsQueryKey, id],
    () => fetchNormNutrients(id),
    options,
  );
  return { nutrients: data?.data, ...rest };
};
