import { MealPatternNewDto } from "@client/searchMealPatternsNew";
import { MealRowIf } from "./MealPatternsDataGrid";

export const mapMealRows = (
  meals: MealPatternNewDto[] | undefined,
  userId: number,
): MealRowIf[] => {
  if (!meals) return [];

  return meals.map(
    ({
      id,
      name,
      nameEn,
      tags,
      macros,
      favorite,
      actions,
      media,
      numberOfItems,
    }) => ({
      id: id,
      namePl: name,
      nameEn: nameEn ?? name,
      tags: tags.map(t => t.id.toString()),
      nutrients: macros,
      isFavorite: favorite.includes(userId),
      actions: actions,
      imgUrl: media?.url ?? null,
      totalItems: numberOfItems,
    }),
  );
};
