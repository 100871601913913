import { useMutation } from "@tanstack/react-query";

import { MutationOptions } from "./types";
import { postClientSendEmailVerification } from "@client";

export const usePostClientEmailVerification = (
  patientId: string,
  options?: MutationOptions,
) => {
  return useMutation(() => postClientSendEmailVerification(patientId), {
    ...options,
    onSuccess: () => {
      options?.onSuccess && options?.onSuccess();
    },
  });
};
