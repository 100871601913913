import styled from "@emotion/styled";

export const ScheduleGridRowWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  background-color: ${({ theme }) => theme.colors.tableBackground};
  border-bottom: 2px solid ${({ theme }) => theme.colors.tableBorder};
  padding: 0.75rem 0;
  width: max-content;
  min-width: 100%;
  height: max-content;
  min-height: 200px;
`;
