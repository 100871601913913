import { useQuery } from "@tanstack/react-query";

import { CategoryDto, fetchDietsProductsForReplacement } from "@client/diets";
import { ApiResponse } from "@typeDefinitions";

import { QueryOptionsTyped } from "../types";

export const searchDietProductsReplacementQueryKey =
  "searchDietProductsReplacementQueryKey";

export const useFetchDietsProductsForReplacementQuery = (
  dietId: string,
  options?: QueryOptionsTyped<ApiResponse<CategoryDto[]>>,
) => {
  const { data, ...rest } = useQuery(
    [searchDietProductsReplacementQueryKey, dietId],
    () => fetchDietsProductsForReplacement(dietId),
    options,
  );

  return {
    data: data?.data,
    ...rest,
  };
};
