import { FieldValues, useController, useFormContext } from "react-hook-form";

import { MACROS_ARRAY } from "@utils/macros";
import {
  TagAutocompleteFilter,
  TagAutocompleteFilterProps,
  SingleAutocompleteFilter,
  SingleAutocompleteFilterProps,
  AutocompleteFilterProps,
  AutocompleteFilter,
} from "./AutocompleteFilter";
import {
  MultiSelectFilter,
  MultiSelectFilterProps,
  SingleSelectFilter,
  SingleSelectFilterProps,
} from "./MultiSelectFilter";
import { ShowMoreNutrients } from "./ShowMoreNutrients";
import { SliderFilterForm, SliderFilterFormProps } from "./SliderFilter";
import {
  OnlyPolishFilter,
  OnlyPolishFilterProps,
} from "./OnlyPolishFilter/OnlyPolishFilter";
import {
  FoodTreeSelectFilter,
  FoodTreeSelectFilterProps,
} from "./FoodTreeSelectFilter";
import {
  SingleSelectChipFilter,
  SingleSelectChipFilterProps,
} from "./SingleSelectChipFilter";

interface GenericFilter {
  type: FilterType;
}
export interface TagAutocompleteFilterWrapper extends GenericFilter {
  type: FilterType.TagAutocomplete;
  props: Omit<TagAutocompleteFilterProps, "selected" | "setSelected">;
}
export interface SingleAutocompleteFilterWrapper<T extends FieldValues>
  extends GenericFilter {
  type: FilterType.SingleAutocomplete;
  props: Omit<SingleAutocompleteFilterProps<T>, "selected" | "setSelected">;
}
export interface AutocompleteFilterWrapper<T extends FieldValues>
  extends GenericFilter {
  type: FilterType.Autocomplete;
  props: AutocompleteFilterProps<T>;
}
export interface MultiSelectFilterWrapper<T extends FieldValues>
  extends GenericFilter {
  type: FilterType.MultiSelect;
  props: MultiSelectFilterProps<T>;
}
export interface SingleSelectFilterWrapper<T extends FieldValues>
  extends GenericFilter {
  type: FilterType.SingleSelect;
  props: SingleSelectFilterProps<T>;
}
export interface SliderFilterWrapper<T extends FieldValues>
  extends GenericFilter {
  type: FilterType.Slider;
  props: Omit<SliderFilterFormProps<T>, "control">;
}
export interface OnlyPolishFilterWrapper<T extends FieldValues>
  extends GenericFilter {
  type: FilterType.OnlyPolish;
  props: OnlyPolishFilterProps<T>;
}
export interface FoodTreeSelectFilterWrapper<T extends FieldValues>
  extends GenericFilter {
  type: FilterType.FoodTreeSelect;
  props: Omit<FoodTreeSelectFilterProps<T>, "control">;
}
export interface SingleSelectChipFilterWrapper<T extends FieldValues>
  extends GenericFilter {
  type: FilterType.SingleSelectChip;
  props: Omit<SingleSelectChipFilterProps<T>, "control">;
}

interface FiltersBuilderProps<T extends FieldValues> {
  filters: (
    | TagAutocompleteFilterWrapper
    | AutocompleteFilterWrapper<T>
    | SingleAutocompleteFilterWrapper<T>
    | MultiSelectFilterWrapper<T>
    | SingleSelectFilterWrapper<T>
    | SliderFilterWrapper<T>
    | OnlyPolishFilterWrapper<T>
    | FoodTreeSelectFilterWrapper<T>
    | SingleSelectChipFilterWrapper<T>
  )[];
}

export const FiltersBuilder = <T extends FieldValues>({
  filters,
}: FiltersBuilderProps<T>) => {
  const { control } = useFormContext();
  const {
    field: { value: tags, onChange: onChangeTags },
  } = useController({ control, name: "tags" });

  const nutrientFilters = filters.filter(
    f =>
      f.type === FilterType.Slider &&
      !MACROS_ARRAY.includes(Number(f.props.nutrientId)),
  );

  return (
    <>
      {filters.map(({ type, props }, idx) => {
        if (type === FilterType.TagAutocomplete)
          return (
            <TagAutocompleteFilter
              {...props}
              selected={tags}
              setSelected={onChangeTags}
              key={idx}
            />
          );
        if (type === FilterType.Autocomplete)
          return <AutocompleteFilter {...props} key={idx} />;
        if (type === FilterType.SingleAutocomplete)
          return <SingleAutocompleteFilter {...props} key={idx} />;
        if (type === FilterType.MultiSelect)
          return <MultiSelectFilter {...props} key={idx} />;
        if (type === FilterType.SingleSelect)
          return <SingleSelectFilter {...props} key={idx} />;
        if (
          type === FilterType.Slider &&
          MACROS_ARRAY.includes(Number(props.nutrientId))
        )
          return <SliderFilterForm {...props} control={control} key={idx} />;
        if (type === FilterType.OnlyPolish)
          return <OnlyPolishFilter {...props} key={idx} />;
        if (type === FilterType.FoodTreeSelect)
          return (
            <FoodTreeSelectFilter {...props} control={control} key={idx} />
          );
        if (type === FilterType.SingleSelectChip)
          return <SingleSelectChipFilter {...props} key={idx} />;
      })}

      {!!nutrientFilters.length && (
        <ShowMoreNutrients>
          <div className="grid gap-[16px]">
            {nutrientFilters.map(({ type, props }, idx) => {
              if (
                type === FilterType.Slider &&
                !MACROS_ARRAY.includes(Number(props.nutrientId))
              )
                return (
                  <SliderFilterForm {...props} control={control} key={idx} />
                );
            })}
          </div>
        </ShowMoreNutrients>
      )}
    </>
  );
};

export enum FilterType {
  Autocomplete,
  TagAutocomplete,
  SingleAutocomplete,
  MultiSelect,
  SingleSelect,
  Slider,
  TabSwitch,
  OnlyPolish,
  FoodTreeSelect,
  SingleSelectChip,
}
export interface OptionIf {
  id: string;
  label: string;
}
