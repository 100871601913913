import { useMemo } from "react";

import { IconButton } from "@mui/material";

import { Pen } from "@assets/icons";
import { useAppTranslation, useModalState } from "@hooks";
import { useUpdateCreatorDietMutation } from "@hooks/queries/diets/creator";

import { TranslationDto } from "@client";
import { DietNameModal } from "../DietNameModal";
import {
  DietNameInputs,
  mapDietNameForm,
  mapDietNameRequest,
} from "../DietNameModal/useDietNameForm";

interface DietNameEditButtonProps {
  translations: TranslationDto[] | undefined;
  dietId: string;
}

export const DietNameEditButton = ({
  translations,
  dietId,
}: DietNameEditButtonProps) => {
  const [open, onOpen, onClose] = useModalState();
  const { t } = useAppTranslation();
  const mappedForm = useMemo(
    () => mapDietNameForm(translations),
    [translations],
  );
  const { mutate, isLoading } = useUpdateCreatorDietMutation();

  const handleEditName = (values: DietNameInputs) => {
    mutate(
      { dietId, payload: mapDietNameRequest(values) },
      { onSuccess: onClose },
    );
  };

  return (
    <>
      <IconButton onClick={onOpen}>
        <Pen />
      </IconButton>
      <DietNameModal
        key={JSON.stringify(mappedForm)}
        open={open}
        onClose={onClose}
        title={t("diet.edit_name.title")}
        confirmText={t("common.save")}
        onSubmit={handleEditName}
        loading={isLoading}
        defaultValues={mappedForm}
      />
    </>
  );
};
