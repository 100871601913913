import { useMemo } from "react";

import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { Typography, useTheme } from "@mui/material";

import { Nutrient } from "@typeDefinitions";
import { percentageFromNutrientsArray } from "@components/MacrosSliders";
import { useAppTranslation } from "@hooks";
import { Circle, LegendWrapper, MacroLabel } from "./RecipeChart.styled";

interface PieChartProps {
  nutrients: Nutrient[];
}

export const PieChartWrapper = ({ nutrients }: PieChartProps) => {
  const { t } = useAppTranslation();
  const macrosPercentage = useMemo(
    () => percentageFromNutrientsArray(nutrients),
    [nutrients],
  );
  const {
    colors: { chartPurple, chartOrange, chartGreen },
  } = useTheme();

  const PROTEIN = "203";
  const FATS = "204";
  const CARBS = "205";

  const colorsById: Record<string, string> = {
    [PROTEIN]: chartPurple,
    [FATS]: chartOrange,
    [CARBS]: chartGreen,
  };

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }: Record<string, number>) => {
    const iRadius = Number(innerRadius) || 0;
    const oRadius = Number(outerRadius) || 0;
    const mAngle = Number(midAngle) || 0;
    const chartX = Number(cx) || 0;
    const chartY = Number(cy) || 0;
    const percentage = Number(percent) || 0;

    const radius = iRadius + (oRadius - iRadius) * 0.3;
    const x = chartX + radius * Math.cos(-mAngle * RADIAN);
    const y = chartY + radius * Math.sin(-mAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > chartX ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percentage * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div className="grid gap-2">
      <Typography variant="h6">
        {t("recipe.energy_distribution_cut")}
      </Typography>
      <ResponsiveContainer width={160} height={160}>
        <PieChart>
          <Pie
            outerRadius={80}
            data={macrosPercentage}
            dataKey="value"
            cx="50%"
            cy="50%"
            labelLine={false}
            isAnimationActive={false}
            label={renderCustomizedLabel}
          >
            {macrosPercentage.map((n, index) => (
              <Cell key={`cell-${index}`} fill={colorsById[n.id]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <LegendWrapper vertical>
        <MacroLabel>
          <Circle color={chartPurple} />
          <Typography variant="body2">{t("recipe.protein")}</Typography>
        </MacroLabel>
        <MacroLabel>
          <Circle color={chartOrange} />
          <Typography variant="body2">{t("recipe.fat")}</Typography>
        </MacroLabel>
        <MacroLabel>
          <Circle color={chartGreen} />
          <Typography variant="body2">{t("recipe.carbs")}</Typography>
        </MacroLabel>
      </LegendWrapper>
    </div>
  );
};
