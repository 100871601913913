import { useTagsNew } from "@hooks/useTagsNew";
import { Stack, useTheme } from "@mui/material";
import { TagCategoryType } from "@utils/tagsNew";

import { Clock, Cutlery, EmptyCalendar } from "@assets/icons";
import { Carrot } from "@assets/icons/DesignSystem";
import { Nutrient } from "@typeDefinitions";
import { useAppTranslation, useFoodGroup } from "@hooks";

import {
  CellWrapper,
  DetailsWrapper,
  Label,
  TagIconWrapper,
  Title,
} from "./NameColumnCell.styled";
import { MacrosText } from "@components/MacrosText";
import { TagWithIcon } from "@components/TagWithIcon";
import { Calendar } from "@assets/icons/DrawerMenu";

interface NameColumnCellProps {
  name: string;
  nutrients: Nutrient[];
  onSelect: () => void;
  numberOfDayTypes: number;
  programLength: number | null;
}

export const NameColumnCell = ({
  name,
  nutrients,
  onSelect,
  numberOfDayTypes,
  programLength,
}: NameColumnCellProps) => {
  const { t } = useAppTranslation();

  return (
    <Stack direction="column" gap="4px">
      <Title>{name}</Title>
      <DetailsWrapper>
        <MacrosText nutrients={nutrients} />
        <TagWithIcon
          Icon={EmptyCalendar}
          label={t("programs.day_types", { count: numberOfDayTypes })}
        />
        <TagWithIcon
          Icon={Clock}
          label={
            programLength
              ? t("programs.days", { count: programLength })
              : t("programs.indefinite")
          }
        />
      </DetailsWrapper>
    </Stack>
  );
};
