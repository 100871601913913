import { IdDto, idSchema } from "@client/common";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import * as yup from "yup";

export const postDietClone = async (
  dietId: string,
): Promise<ApiResponse<IdDto>> => {
  const response = await fetchData(
    `/dietitian/diets/${dietId}/clone`,
    APIMethods.POST,
  );

  return responseSchema.validate(response);
};

const responseSchema = yup.object({
  data: idSchema.required(),
});
