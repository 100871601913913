import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import {
  mealDescriptionSchema,
  mealRecipeSchema,
  DietitianProgramScheduleMeal,
} from "./common";
import * as yup from "yup";
import { validateAndFixNutrients } from "../utils/validateCatching";

export async function createProgramDayMeal(
  programId: number,
  dayId: number,
  payload: CreateProgramDayMealPayload,
): Promise<ApiResponse<ProgramDayMealUpdateResponse>> {
  const data = await fetchData(
    `/dietitian/programs/${programId}/schedule/days/${dayId}/meals`,
    APIMethods.POST,
    { ...payload },
  );
  return await validateAndFixNutrients(
    updateProgramDayMealResponseSchema,
    data,
  );
}

const updateProgramDayMealResponseSchema = yup.object().shape({
  data: yup.object().shape({
    id: yup.number().required(),
    mealDescription: mealDescriptionSchema.required(),
    recipe: mealRecipeSchema.nullable(),
    servings: yup.number(),
    isCustom: yup.boolean(),
  }),
});

export interface CreateProgramDayMealPayload {
  title: string;
  description: string | null;
  hour: string | null;
}

interface ProgramDayMealUpdateResponse {
  id: number;
  dietMeal: DietitianProgramScheduleMeal["dietMeal"];
  recipe?: DietitianProgramScheduleMeal["recipe"];
  servings?: number;
  isCustom?: boolean;
}
