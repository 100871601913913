import { NutrientDto } from "@client";
import { UpdateCreatorDietTargetNutrientsRequest } from "@client/diets/creator";
import { CARBS_ID, ENERGY_ID, FATS_ID, PROTEIN_ID } from "@utils/macros";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { findNutrient } from "../common";

export const useMacrosForm = (defaultValues?: MacrosFormInput) => {
  const form = useForm<MacrosFormInput>({
    defaultValues: {
      kcal: 500,
      protein: 0,
      fat: 0,
      carbs: 0,
    },
  });

  useEffect(() => {
    if (defaultValues) {
      form.reset(defaultValues, { keepDirtyValues: true });
    }
  }, [defaultValues]);

  return form;
};

export interface MacrosFormInput {
  kcal: number;
  protein: number;
  fat: number;
  carbs: number;
}

export const mapMacrosRequest = (
  data: MacrosFormInput,
): UpdateCreatorDietTargetNutrientsRequest => {
  return {
    nutrients: [
      { id: ENERGY_ID, value: data.kcal },
      { id: PROTEIN_ID, value: data.protein },
      { id: FATS_ID, value: data.fat },
      { id: CARBS_ID, value: data.carbs },
    ],
  };
};

export const mapMacrosForm = (data: NutrientDto[]): MacrosFormInput => {
  return {
    kcal: findNutrient(ENERGY_ID, data),
    protein: findNutrient(PROTEIN_ID, data),
    fat: findNutrient(FATS_ID, data),
    carbs: findNutrient(CARBS_ID, data),
  };
};
