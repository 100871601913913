import { useMutation, useQueryClient } from "@tanstack/react-query";

import { AxiosError } from "axios";

import { useAppTranslation } from "@hooks";
import { MutationOptions } from "./types";
import { fetchClientsQueryKey } from "./useFetchClientsQuery";
import {
  fetchClinicClientsStatisticsQueryKey,
  searchClientsQueryKey,
} from "./settings";
import {
  createClient,
  CreateClientRequest,
  CreateClientResponse,
} from "@client";
import { ApiErrorIf } from "@typeDefinitions";
import { toast } from "react-toastify";

export const useCreateClientMutation = (
  options?: MutationOptions<CreateClientResponse>,
) => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();

  return useMutation<
    CreateClientResponse,
    AxiosError<ApiErrorIf>,
    CreateClientRequest
  >(request => createClient(request), {
    ...options,
    onSuccess: () => {
      toast.success(t("patient.confirm.client_added"));
      queryClient.invalidateQueries([fetchClientsQueryKey]);
      queryClient.invalidateQueries([fetchClinicClientsStatisticsQueryKey]);
      queryClient.invalidateQueries([searchClientsQueryKey]);
      options?.onSuccess && options.onSuccess();
    },
    onError: err => {
      if (err.response?.status === 422) {
        return false;
      }
    },
  });
};
