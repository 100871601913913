import { MacrosText } from "@components/MacrosText";
import { useSearchDietsPreviewMealQuery } from "@hooks/queries/diets";
import { Box, CircularProgress, Stack, styled } from "@mui/material";
import { PlaceholderStyled } from "@components/PreviewDrawer/Diet/MealDetails/AccordionTitle/AccordionTitle.styled";
import cupcake from "@assets/img/productTile/cupcake.png";
import {
  ImgStyled,
  ImgBackground,
  MealsListItem,
  Title,
} from "@components/ProgramSearchPreview/Components/DietMealItems/DietMealItems.styled";
import { DietMealProgramPreview } from "@client/program/preview/fetchDietMealProgramPreview";
import { useAppTranslation } from "@hooks";
import { useMemo } from "react";

type DietMealItemProps = Pick<
  DietMealProgramPreview,
  "name" | "translations" | "nutrients"
> & {
  image: string | null;
};

const DietMealItem = ({
  image,
  name,
  translations,
  nutrients,
}: DietMealItemProps) => {
  const { currentLanguage } = useAppTranslation();

  const nameItem = useMemo(() => {
    return translations.find(t => t.lang === currentLanguage)?.name ?? name;
  }, [name, translations, currentLanguage]);

  return (
    <MealsListItem>
      {image ? (
        <ImgStyled src={image} loading="lazy" />
      ) : (
        <ImgBackground>
          <PlaceholderStyled src={cupcake} />
        </ImgBackground>
      )}
      <Stack direction="column" gap="4px">
        <Title>{nameItem}</Title>
        <MacrosText nutrients={nutrients} />
      </Stack>
    </MealsListItem>
  );
};

export default DietMealItem;
