import { memo } from "react";
import { useController } from "react-hook-form";

import { useAppTranslation } from "@hooks";
import { ProgramFormInput } from "@hooks/useProgramForm";
import { useFetchDietitianNutrientsCategoriesQuery } from "@hooks/queries/useFetchDietitianNutrientsCategoriesQuery";
import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";
import { Modal } from "@components/Modal";
import { Labeled } from "@components/Labeled";
import { Checkbox } from "@components/Checkbox";

interface NutrientsSelectModalProps {
  hide: () => any;
}

export const NutrientsModal = memo(({ hide }: NutrientsSelectModalProps) => {
  const { t } = useAppTranslation();
  const { isPolishChosen } = useAppTranslation();
  const {
    field: { value, onChange },
  } = useController<ProgramFormInput>({ name: "showMicros" });
  const micros = value as number[] | undefined;

  const {
    nutrientCategories,
    isLoading: isCategoriesLoading,
    isError: isCategoriesError,
  } = useFetchDietitianNutrientsCategoriesQuery();

  const onClick = (id: number) => {
    if (micros?.includes(id)) {
      onChange(micros.filter(m => m !== id));
    } else {
      onChange([...(micros ?? []), id]);
    }
  };

  if (isCategoriesLoading) return <Spinner />;

  if (isCategoriesError || !nutrientCategories) return <ApiError />;

  return (
    <Modal
      closeButton={false}
      submitButton={false}
      onClose={hide}
      title={t("addNutritionalProgram.microelementsModal.title")}
    >
      <div className="flex flex-col p-6 gap-6">
        {nutrientCategories
          .filter(nc => !categoriesBlackList.includes(nc.id))
          .map(category => (
            <Labeled label={isPolishChosen ? category.namePl : category.nameEn}>
              <div className="grid grid-cols-3 gap-3">
                {category.nutrients.map(n => (
                  <Checkbox
                    value={micros?.includes(n.id)}
                    onChange={() => onClick(n.id)}
                    label={isPolishChosen ? n.namePl : n.nameEn}
                  />
                ))}
              </div>
            </Labeled>
          ))}
      </div>
    </Modal>
  );
});

const categoriesBlackList = [1, 2];
