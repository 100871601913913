import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  FetchMealResponse,
  updateCreatorMealProductsSort,
} from "@client/meals";
import { fetchCreatorMealQueryKey, MutationOptions } from "@hooks/queries";
import { ApiResponse } from "@typeDefinitions";
import { useMealVersion } from "@views/dietician/MealEdit/hooks";

export const useUpdateCreatorMealProductsSortMutation = (
  mealId: string,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();
  const { version, updateVersion } = useMealVersion();

  const mutation = useMutation<unknown, unknown, number[]>(
    products => updateCreatorMealProductsSort(mealId, { products }, version),
    {
      ...options,
      onMutate: async productsOrder => {
        await queryClient.cancelQueries([fetchCreatorMealQueryKey, mealId]);
        queryClient.setQueryData<ApiResponse<FetchMealResponse>>(
          [fetchCreatorMealQueryKey, mealId],
          old => {
            if (!old) return;
            return {
              ...old,
              data: {
                ...old.data,
                products: old?.data.products.sort((a, b) => {
                  const indexA = productsOrder.indexOf(a.id);
                  const indexB = productsOrder.indexOf(b.id);

                  return indexA - indexB;
                }),
              },
            };
          },
        );
        updateVersion();
      },
      onError: () => {
        queryClient.invalidateQueries([fetchCreatorMealQueryKey, mealId]);
      },
    },
  );

  return mutation;
};
