import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";
import { BodyMeasurementResource } from "@client/dictionaries";
import { Controller, useController, useFormContext } from "react-hook-form";
import { useAppTranslation } from "@hooks";
import { FormPops } from "@views/dietician/ClientHealth/BodyMeasurementTab/SettingsSection/Dialog/SettingSectionForm";

type BodyFieldProps = {
  body: BodyMeasurementResource;
};

const BodyField = ({ body }: BodyFieldProps) => {
  const { currentLanguage } = useAppTranslation();
  const controller = useFormContext<FormPops>();
  const getMeasurementBodyName = (bodyMeasurement: BodyMeasurementResource) => {
    const translation = bodyMeasurement.translations.find(
      translation => translation.lang === currentLanguage,
    );
    if (!translation) {
      return bodyMeasurement.name;
    }

    return translation.name;
  };

  return (
    <Controller
      control={controller.control}
      name="measurementsBodyId"
      render={({ field }) => (
        <FormControlLabel
          key={body.id}
          label={getMeasurementBodyName(body)}
          sx={{ margin: "0px" }}
          control={
            <Checkbox
              checked={field.value.includes(body.id)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.checked) {
                  field.onChange([...field.value, body.id]);
                } else {
                  field.onChange(field.value.filter(id => id != body.id));
                }
              }}
            />
          }
        />
      )}
    />
  );
};

export default BodyField;
