import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import { logFetchValidateError } from "@utils/validate";
import { object, boolean } from "yup";

export type FetchRequiredBillDataResponse = ApiResponse<RequiredDto>;

export const fetchRequiredBillData =
  async (): Promise<FetchRequiredBillDataResponse> => {
    const response = await fetchData(
      "/dietitian/clinic/required-bill-data",
      APIMethods.GET,
    );

    return await schema
      .validate(response)
      .catch(logFetchValidateError("/dietitian/clinic/required-bill-data"));
  };

interface RequiredDto {
  required: boolean;
}

const schema = object({
  data: object({
    required: boolean().required(),
  }),
});
