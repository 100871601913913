import { QueryOptionsTyped } from "@hooks/queries";
import { useQuery } from "@tanstack/react-query";
import {
  fetchDietProgramPreview,
  FetchDietProgramPreviewParams,
  FetchDietProgramPreviewResponse,
} from "@client/program/preview/fetchDietProgramPreview";
import { programPreviewKeys } from "@hooks/queries/program/preview/programPreviewKeys";
import {
  fetchDietMealProgramPreview,
  FetchDietMealProgramPreviewParams,
  FetchDietMealProgramPreviewResponse,
} from "@client/program/preview/fetchDietMealProgramPreview";

export const getKey = (params: FetchDietMealProgramPreviewParams) =>
  programPreviewKeys.programDietMeal({
    programId: params.programId,
    dietMealId: params.dietMealId,
  });

const useFetchDietMealProgramPreview = (
  params: FetchDietMealProgramPreviewParams,
  options?: QueryOptionsTyped<FetchDietMealProgramPreviewResponse>,
) => {
  return useQuery({
    queryKey: getKey(params),
    queryFn: () => fetchDietMealProgramPreview(params),
    ...options,
  });
};

export default useFetchDietMealProgramPreview;
