import { ReactNode } from "react";

import { isString } from "lodash";
import { useTheme } from "@mui/material";

import { SvgIconProps } from "@components/SvgIcon";

import { Label, TagIconWrapper } from "./TagWithIcon.styled";

interface TagWithIconProps {
  label: string | ReactNode;
  Icon?: (props: SvgIconProps) => JSX.Element;
}
export const TagWithIcon = ({ label, Icon }: TagWithIconProps) => {
  const {
    colors: { neutral },
  } = useTheme();

  return (
    <TagIconWrapper>
      {Icon && <Icon fill={neutral.dark[700]} />}
      {isString(label) ? <Label>{label}</Label> : label}
    </TagIconWrapper>
  );
};
