import * as yup from "yup";
import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { logFetchValidateError } from "@utils/validate";
import {
  NutrientsMonitoringResource,
  nutrientsMonitoringSchema,
} from "@client/resources/NutrientsMonitoringResource";

export type FetchMonitoringNutrientsParams = {
  patientId: number;
  dateFrom: string;
  dateTo: string;
};

export type FetchMonitoringNutrientsResponse = ApiResponse<
  NutrientsMonitoringResource[]
>;

export const fetchMonitoringNutrients = async (
  params: FetchMonitoringNutrientsParams,
): Promise<FetchMonitoringNutrientsResponse> => {
  const response = await fetchData(
    `/dietitian/patients/{patient_id}/monitoring2/nutrients`.replace(
      "{patient_id}",
      params.patientId.toString(),
    ),
    APIMethods.GET,
    undefined,
    mapUrlParams(params),
  );

  return await schema
    .validate(response)
    .catch(
      logFetchValidateError(
        "/dietitian/patients/{patient_id}/monitoring2/nutrients",
      ),
    );
};

const mapUrlParams = (params: FetchMonitoringNutrientsParams) => {
  const urlParams = new URLSearchParams();
  urlParams.set("date-from", params.dateFrom);
  urlParams.set("date-to", params.dateTo);

  return urlParams;
};

const schema = yup.object({
  data: yup.array().of(nutrientsMonitoringSchema).defined(),
});
