import { styled } from "@mui/material";
import { TabList as TabListMaterial, TabListTypeMap } from "@mui/lab";

export const TabList = styled(TabListMaterial)`
  border: 1px solid #f5f1fe;
  border-radius: 8px;
  padding: 4px;
  width: fit-content;
  max-width: 100%;
  .Mui-selected {
    border-radius: 8px;
    border: none;
    background: #f5f1fe;
  }

  .MuiTabs-indicator {
    display: none;
  }

  .MuiTab-root.MuiButtonBase-root {
    font-family: Figtree, serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    padding: 6px 8px;
  }

  .MuiTabs-flexContainer {
    gap: 4px;
  }

  .MuiTabs-scrollButtons {
    width: 20px;
  }
  .MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.3;
  }
` as typeof TabListMaterial;
