import { useEffect } from "react";
import { useForm } from "react-hook-form";

import * as yup from "yup";

import {
  TextAnswerQuestionDto,
  UpdateSingedSurveyRequest,
} from "@client/surveys";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppTranslation } from "@hooks";

export const useTextAnswerForm = (
  values: TextAnswerFormInputs | undefined,
  required: boolean,
  shortAnswer: boolean,
) => {
  const { t } = useAppTranslation();
  const resolver = yup.object().shape({
    value: (required ? yup.string().required() : yup.string().nullable()).max(
      shortAnswer ? 160 : 600,
      `${t("questionnaires.max_chars_error")} ${shortAnswer ? 160 : 600}`,
    ),
  });

  const form = useForm<TextAnswerFormInputs>({
    resolver: yupResolver(resolver),
  });

  useEffect(() => {
    if (values) form.reset(values);
  }, [values]);

  return form;
};

export const mapTextAnswerForm = (
  data?: TextAnswerQuestionDto,
): TextAnswerFormInputs => {
  if (!data) return { value: null };
  return data;
};

export const mapTextAnswerRequest = (
  data: TextAnswerFormInputs,
  questionId: number,
): UpdateSingedSurveyRequest => {
  if (!data.value) return { questionId, answer: { value: null } };
  return { questionId, answer: data };
};

export interface TextAnswerFormInputs {
  value: string | null;
}
