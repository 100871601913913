import { Bookmark } from "@assets/icons";
import { AlertCardStyled, TextContent, TitleStyled } from "./AlertCard.styled";

import { useContentSelector } from "@context/TableOfContentsContext";
import { useTheme } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FetchSingedSurveysResponse } from "@client/surveys";
import { ApiResponse } from "@typeDefinitions";
import { fetchSingedSurveysQueryKey } from "@hooks/queries/surveys";
import { useParseUrl } from "../useParseUrl";

export const AlertCard = () => {
  const {
    selectedId,
    groupId,
    subgroupId,
    isIntroduction,
    isGroup,
    isSubgroup,
  } = useContentSelector();
  const {
    palette: {
      primary: { main },
    },
  } = useTheme();
  const { url } = useParseUrl();
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData<
    ApiResponse<FetchSingedSurveysResponse>
  >([fetchSingedSurveysQueryKey, url]);

  const getAlertData = () => {
    const fallback = {
      title: "",
      description: "",
    };

    if (!data?.data) return fallback;

    const { introduction, groups } = data.data.survey;

    if (isIntroduction)
      return {
        title: introduction.title,
        description: introduction.description,
      };

    const group = groups.find(g => g.id === groupId);

    if (isGroup && group) {
      const { title, description } = group;
      return { title, description };
    }

    const subgroup = group?.sections.find(s => s.id === subgroupId);
    if (isSubgroup && subgroup) {
      const { title, description } = subgroup;

      return { title, description };
    }

    return fallback;
  };

  const { title, description } = getAlertData();

  return (
    <AlertCardStyled>
      <div className="flex gap-1">
        <Bookmark className="m-1 self-start" fill={main} />

        <TitleStyled>{title}</TitleStyled>
      </div>

      <TextContent>{description}</TextContent>
    </AlertCardStyled>
  );
};
