import { MuiDataGrid } from "@components/MuiDataGrid";
import { IconButton, Typography, styled } from "@mui/material";

interface CellWrapperProps {
  centered?: boolean;
}

export const CellWrapper = styled("div")<CellWrapperProps>`
  display: grid;
  justify-content: ${({ centered }) => centered && "center"};
  align-items: center;
  width: 100%;
  height: 3.19rem;
  border-radius: 0.25rem;
  padding: 0.35rem;

  &:hover {
    background: ${({ theme }) => theme.palette.primary.light};
  }
`;

export const StyledDietitian = styled("p")`
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
  font-weight: 500;
  line-height: 142.857%;
`;

export const StyledIconButton = styled(IconButton)`
  width: 2rem;
  height: 2rem;
  padding: 0.55rem;
  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const StyledDate = styled(Typography)`
  font-weight: 500;
  line-height: 150%;
`;

export const StyledMuiDataGrid = styled(MuiDataGrid)`
  & .MuiDataGrid-iconButtonContainer {
    display: none;
  }
  min-height: 27rem;
`;
