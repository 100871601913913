import { StyledTasksGrid } from "../TasksTable/TasksTable.styled";
import { useAppTranslation } from "@hooks";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid";
import { SuggestedTasksType } from "./TasksSuggestedRejectedAccordion";
import { Dispatch, SetStateAction, useMemo, type FC } from "react";
import { RestoreArrows } from "@assets/icons/RestoreArrows";
import { Avatar } from "@components/AvatarNew";
import { Box, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";

import type { SuggestedTask } from "@client/suggestedTasks/fetchSuggestedTasksList";
import { IconButton } from "@components/IconButton";
import { Close, Checklist } from "@assets/icons";
import { useToggleSuggestedTasksMutation } from "@hooks/queries/suggestedTasks/useToggleSuggestedTasksMutation";
import { GridPaginationWrapper } from "@components/PaginationNew/Pagination";
import { Metadata } from "@typeDefinitions";
import { useTasksContext } from "../../Tasks.context";
import { useTasksSuggested } from "./TasksSuggestedPanel.context";

interface TasksTableProps {
  suggestedTasks: SuggestedTask[];
  isFetching: boolean;

  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  meta: Metadata;
  tagId?: number;
  tagName?: string;
}

export enum TagId {
  EndingPartnership = 742,
  UpcomingVisit = 745,
}

export const TasksSuggestedTable: FC<TasksTableProps> = ({
  suggestedTasks,
  isFetching,
  tagId,
  tagName,
  page,
  setPage,
  meta,
}) => {
  const { t } = useAppTranslation();
  const { handleUpdateTaskStatus } = useToggleSuggestedTasksMutation();
  const { handleOpenCreateModal, setCurrentTaskId } = useTasksContext();
  const { setTagId, setClientId, setDueDate, setSuggestedTaskId } =
    useTasksSuggested();
  const isOnePage = !!meta?.lastPage && meta?.lastPage <= 1;

  const handleComposeNewTask = (
    tagId: number,
    clientId: string,
    dueDate: string,
    suggestedTaskId: string,
  ) => {
    setCurrentTaskId(null);
    handleOpenCreateModal();
    setTagId(tagId);
    setClientId(clientId);
    setDueDate(dueDate);
    setSuggestedTaskId(suggestedTaskId);
  };

  const columns: GridColumns = useMemo(
    () => [
      {
        field: "patient",
        headerName: t("tasks.tasksTable.client"),
        sortable: false,
        disableColumnMenu: true,
        minWidth: 200,
        renderCell: (params: GridRenderCellParams) => {
          const fullName = `${params.value.firstName} ${params.value.lastName}`;
          return (
            <Box display="flex" alignItems="center" gap={1}>
              <Avatar image={params.value?.image} fullName={fullName ?? ""} />
              <Box>
                <Typography variant="subtitle2" fontWeight="bold">
                  {fullName ?? "-"}
                </Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  className="flex gap-x-1"
                >
                  <span>{t("tasks.tasksTable.partnership")}</span>
                  <span>
                    {params.value.subscription.expired
                      ? dayjs(params.value.subscription.expired).format(
                          "DD.MM.YYYY",
                        )
                      : t("tasks.indefinitely")}
                  </span>
                </Typography>
              </Box>
            </Box>
          );
        },
      },
      {
        field: "item",
        renderHeader: () => (
          <span className="truncate">
            {tagId === TagId.EndingPartnership
              ? t("tasks.dates.receivedDate")
              : t("tasks.dates.endDate")}
          </span>
        ),

        sortable: false,
        disableColumnMenu: true,
        maxWidth: 90,
        align: "center",
        renderCell: (params: GridRenderCellParams) => {
          const dueDate =
            params.row.item?.finishedAt ?? params.row.item?.createdAt ?? "";
          return (
            <Typography variant="body2">
              {params.value ? dayjs(dueDate).format("DD.MM.YYYY") : "-"}
            </Typography>
          );
        },
      },
      {
        field: "dietitian",
        headerName: t("tasks.dietitianHeader.header"),
        maxWidth: 90,
        sortable: false,
        disableColumnMenu: true,
        cellClassName: "px-0",
        align: "center",
        renderCell: (params: GridRenderCellParams) => {
          const fullName = `${params.value.firstName} ${params.value.lastName}`;
          return (
            <Avatar image={params.value?.image} fullName={fullName ?? ""} />
          );
        },
      },
      {
        field: "actions",
        headerName: t("tasks.tasksTable.actions"),
        sortable: false,
        colSpan: 2,
        headerAlign: "center",
        disableColumnMenu: true,
        align: "center",
        renderCell: (params: GridRenderCellParams) => {
          const clientId = params.row.patient?.id ?? "";
          const dueDate =
            params.row.item?.finishedAt ?? params.row.item?.createdAt ?? "";
          const currentTagId = params.row.tag?.id;

          return (
            <Box display="flex" alignItems="center" gap={0.5}>
              <Tooltip
                title={
                  tagId ? t("tasks.actions.reject") : t("tasks.actions.restore")
                }
                arrow
              >
                <div>
                  <IconButton
                    size="medium"
                    color="gray"
                    onClick={() =>
                      handleUpdateTaskStatus({
                        id: Number(params.id),
                        hidden: tagId
                          ? SuggestedTasksType.Hidden
                          : SuggestedTasksType.Visible,
                      })
                    }
                  >
                    {tagId ? <Close /> : <RestoreArrows className="text-2xl" />}
                  </IconButton>
                </div>
              </Tooltip>
              {tagId && (
                <Tooltip title={t("tasks.actions.assign")} arrow>
                  <div>
                    <IconButton
                      size="xlarge"
                      color="gray"
                      onClick={() => {
                        handleComposeNewTask(
                          currentTagId,
                          clientId,
                          dueDate,
                          params.id as string,
                        );
                      }}
                    >
                      <Checklist />
                    </IconButton>
                  </div>
                </Tooltip>
              )}
            </Box>
          );
        },
      },
    ],
    [t],
  );

  const perPageOptions = [10];

  return (
    <StyledTasksGrid
      sx={{ cursor: "default" }}
      columns={columns}
      rows={suggestedTasks}
      loading={isFetching}
      autoHeight
      rowCount={meta.total ?? 0}
      rowHeight={55}
      pageSize={meta.perPage}
      disableSelectionOnClick
      getRowSpacing={() => ({ top: 8 })}
      className="custom-scrollbar"
      page={page}
      onPageChange={setPage}
      paginationMode="server"
      rowsPerPageOptions={perPageOptions}
      components={{
        Pagination: GridPaginationWrapper,
      }}
      componentsProps={{
        pagination: { isOnePage, perPage: meta.perPage },
      }}
    />
  );
};
