import { Broom } from "@assets/icons/DesignSystem";
import { ModalWrapper } from "@components/ModalWrapperNew";
import { useAppParams, useAppTranslation, useModalState } from "@hooks";
import {
  usePostDietsProductsRemovedClearHistoryMutation,
  usePostDietsProductsReplacementClearHistoryMutation,
} from "@hooks/queries/diets";

import { Tab } from "../ProductsTab";
import { ButtonStyled } from "./ClearHistoryButton.styled";
import { useHistoryData } from "./useHistoryData";

interface ClearHistoryButtonProps {
  variant: string;
}
export const ClearHistoryButton = ({ variant }: ClearHistoryButtonProps) => {
  const [open, onOpen, onClose] = useModalState();
  const { dietId } = useAppParams();
  const { t } = useAppTranslation();
  const { mutate: clearRemoved, isLoading: removedLoading } =
    usePostDietsProductsRemovedClearHistoryMutation({ onSuccess: onClose });
  const { mutate: clearReplaced, isLoading: replacedLoading } =
    usePostDietsProductsReplacementClearHistoryMutation({ onSuccess: onClose });
  const { hasHistory } = useHistoryData(dietId);

  const handleClear = () => {
    if (variant === Tab.DELETED) clearRemoved(dietId);
    if (variant === Tab.REPLACED) clearReplaced(dietId);
  };

  const translationVariant = variant === Tab.REPLACED ? "exchange" : "delete";

  return (
    <>
      <ButtonStyled
        onClick={onOpen}
        loading={removedLoading || replacedLoading}
        disabled={!hasHistory(variant)}
      >
        <Broom />
        {t("diet.clear_history")}
      </ButtonStyled>

      <ModalWrapper
        open={open}
        onClose={onClose}
        onSubmit={handleClear}
        title={t(`diet.clear_${translationVariant}_history_modal.title`)}
        color="error"
        confirmText={t("diet.clear_history")}
      >
        <p>{t(`diet.clear_${translationVariant}_history_modal.question`)}</p>
        <p>{t(`diet.clear_${translationVariant}_history_modal.info`)}</p>
      </ModalWrapper>
    </>
  );
};
