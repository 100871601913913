import { useAppParams, useAppTranslation } from "@hooks";
import { MealRecipe, RecipeItem } from "./RecipeItem";
import { useState } from "react";
import { CardTitle } from "../../Meal.styled";
import { ArrowDown } from "@assets/icons";
import { StyledTypography } from "./RecipeItem.styled";
import {
  AddMealButton,
  RecipeMealHeaderWrapper,
  StyledExpandButton,
} from "./MealRecipes.styled";
import { Link } from "react-router-dom";

interface MealRecipesProps {
  recipes: MealRecipe[];
  canEdit?: boolean;
}

export const MealRecipes = ({ recipes, canEdit }: MealRecipesProps) => {
  const { t } = useAppTranslation();
  const { mealId } = useAppParams();
  const [openedRecipes, setOpenedRecipes] = useState<number[]>([]);
  const isAllOpened = openedRecipes.length === recipes.length;
  const recipesId = recipes.map(r => r.id);

  const toggleItems = () => setOpenedRecipes(isAllOpened ? [] : recipesId);

  return (
    <div className="grid">
      <RecipeMealHeaderWrapper justifyOff={!recipes.length}>
        <CardTitle variant="h4">
          {t("common.recipes")} ({recipes.length})
        </CardTitle>
        {recipes.length ? (
          <StyledExpandButton
            disableRipple
            isOpen={isAllOpened}
            onClick={toggleItems}
            endIcon={<ArrowDown className="arrow-down" />}
          >
            <StyledTypography variant="caption">
              {!isAllOpened ? t("common.expand_all") : t("common.roll_up_all")}
            </StyledTypography>
          </StyledExpandButton>
        ) : canEdit ? (
          <Link to={`/meals/${mealId}/edit`}>
            <AddMealButton variant="text">
              {t("meals.add_recipes")}
            </AddMealButton>
          </Link>
        ) : (
          <AddMealButton disabled variant="text">
            {t("meals.add_recipes")}
          </AddMealButton>
        )}
      </RecipeMealHeaderWrapper>
      {recipes.map(recipe => (
        <RecipeItem
          {...recipe}
          key={recipe.id}
          setAllOpened={setOpenedRecipes}
          allOpened={openedRecipes}
        />
      ))}
    </div>
  );
};
