import { ReactElement, useMemo } from "react";
import { CustomTag } from "@client/customTags/fetchCustomTags";
import { CustomTagChip } from "components/CustomTagChip";
import { CustomTagsDropdown } from "@components/CustomTagsDropdown";
import { OverflowingList } from "@components/OverflowingList";
import { useUpdateClientCustomTagsMutation } from "@hooks/queries/useUpdateClientCustomTagsMutation";

export interface ClientCustomTagsProps {
  clientId: number;
  tags: CustomTag[];
  onUpdated?: (clientId: number, tags: CustomTag[]) => void;
  editable?: boolean;
  triggerClassName?: string;
  maxRowsCount: number;
}

export const mapTagsToIds = (tags: CustomTag[]): number[] =>
  tags.map((tag: CustomTag) => tag.id);

export const ClientCustomTags = ({
  clientId,
  tags,
  onUpdated,
  maxRowsCount,
  editable = false,
  triggerClassName,
}: ClientCustomTagsProps): ReactElement => {
  const { mutate } = useUpdateClientCustomTagsMutation();

  const handleDelete = (id: number) => {
    const nextTags = tags.filter((tag: CustomTag) => tag.id !== id);

    mutate({
      clientId,
      tags: mapTagsToIds(nextTags),
    });

    if (onUpdated) {
      onUpdated(clientId, nextTags);
    }
  };

  const handleDropdownClosed = (selectedTags: CustomTag[]) => {
    mutate({ clientId, tags: mapTagsToIds(selectedTags) });

    if (onUpdated) {
      onUpdated(clientId, selectedTags);
    }
  };

  const tagsIds = useMemo(() => mapTagsToIds(tags), [tags]);

  return (
    <OverflowingList
      items={tags}
      renderItem={(tag: CustomTag, index) => (
        <CustomTagChip
          key={tag.id + index}
          id={tag.id}
          label={tag.name}
          color={tag.color}
          onDelete={handleDelete}
        />
      )}
      addonAfter={
        <>
          {editable && (
            <CustomTagsDropdown
              defaultSelectedTagsIds={tagsIds}
              onClosed={handleDropdownClosed}
              triggerClassName={triggerClassName}
            />
          )}
        </>
      }
      itemHeight={28}
      gap={8}
      maxRowsCount={maxRowsCount}
    />
  );
};
