import { useAppParams } from "@hooks";
import { MutationOptions } from "@hooks/queries";
import { useUpdateCreatorDietMealProductMutation } from "@hooks/queries/diets/creator";
import { useUpdateProgramDietMealProductMutation } from "@hooks/queries/program/dietCreator";

export const useUpdateDietMealProductMutation = (options?: MutationOptions) => {
  const { programId } = useAppParams();

  return programId
    ? useUpdateProgramDietMealProductMutation(programId, options)
    : useUpdateCreatorDietMealProductMutation(options);
};
