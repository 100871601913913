import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@mui/material";

import { useAppTranslation, useModal } from "@hooks";
import { useCategoriesNutrientsFetchQuery } from "@hooks/queries/dictionaries";
import { ENERGY_CATEGORY_ID, MACROELEMENTS_CATEGORY_ID } from "@utils";

import {
  ColumnsWrapper,
  NutrientsViewSettingsWrapper,
  SpinnerWrapper,
} from "./NutrientsViewSettings.styled";
import { NutrientGroup } from "./NutrientGroup";
import { ApiError } from "@components/ApiError";
import { Gear } from "@assets/icons";
import { StyledHeaderButton } from "@layouts/Dashboard/components";
import { ThemeProviderWrapper } from "theme";

export const NutrientsViewSettings = () => {
  const { t } = useAppTranslation();
  const { onModalOpen, modalOpened, onModalClose } = useModal();
  const {
    data: nutrientCategories,
    isLoading,
    isError,
    isSuccess,
  } = useCategoriesNutrientsFetchQuery({ enabled: modalOpened });

  const categoriesToHide = [MACROELEMENTS_CATEGORY_ID, ENERGY_CATEGORY_ID];

  return (
    <NutrientsViewSettingsWrapper>
      <Tooltip title={t("common.nutrients_view_settins.title")}>
        <div>
          <StyledHeaderButton size="small" onClick={onModalOpen}>
            <Gear />
          </StyledHeaderButton>
        </div>
      </Tooltip>
      <ThemeProviderWrapper>
        <Dialog open={modalOpened} onClose={onModalClose}>
          <DialogTitle>{t("common.nutrients_view_settins.view")}:</DialogTitle>
          <DialogContent>
            {isLoading && (
              <SpinnerWrapper>
                <CircularProgress />
              </SpinnerWrapper>
            )}
            {isError && <ApiError />}
            {isSuccess && (
              <ColumnsWrapper>
                {nutrientCategories
                  ?.filter(category => !categoriesToHide.includes(category.id))
                  .map(category => (
                    <NutrientGroup key={category.id} category={category} />
                  ))}
              </ColumnsWrapper>
            )}
          </DialogContent>
        </Dialog>
      </ThemeProviderWrapper>
    </NutrientsViewSettingsWrapper>
  );
};
