import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";
import { NutrientDto } from "./common";

export const updatePatientProgram = async (
  programId: number,
  patientId: number,
  payload: UpdatePatientProgramRequest,
) => {
  await fetchData(
    `/dietitian/patients/${patientId}/programs/${programId}`,
    APIMethods.PUT,
    payload,
  );
};

export interface UpdatePatientProgramRequest {
  name: string;
  nameEn?: string;
  showNutrients: number[];
  showCals: boolean;
  showMacros: boolean;
  showMeasurements: boolean;
  hasDiets: boolean;
  hasTasks: boolean;
  hasDiary: boolean;
  hasChat: boolean;
  hasEducation: boolean;
  hasMacros: boolean;
  hasMonitoring: boolean;
  hasNewsfeed: boolean;
  hasSubstitutes: boolean;
  hasShoppingList: boolean;
  langId: "pl" | "en";
  sections: number[];
  days: {
    id: number;
    name: string;
    frequency: number[];
    diet: {
      id: number;
      title?: string;
      titleEn?: string;
      mealsCount: number;
      nutrients: NutrientDto[];
    } | null;
  }[];
  startDate?: string;
  finishDate?: string | null;
}
