import {
  Tooltip,
  Button,
  FormControlLabel,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";
import { FormTextField } from "@components/FormTextField";
import { FormCountryAutocomplete } from "@components/FormCountryAutocomplete";
import { Calendar } from "@assets/icons";
import { LoadingButton } from "@mui/lab";

export const AddClientModalWrapper = styled("div")`
  display: grid;
  gap: 1.5rem;
  padding: 1.5rem 2rem;
  width: 28.125rem;
`;

export const HeaderStyled = styled("h2")`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: 1.5rem;
  line-height: 150%;
  letter-spacing: -0.022em;
  color: ${({ theme }) => theme.colors.gray500};
`;

export const StyledTextArea = styled(FormTextField)`
  padding: unset;
  & legend {
    display: none;
  }

  & label {
    margin-top: 0.5rem;
    position: unset;
    transform: translate(14px, -4px) scale(0.75);
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  & span {
    font-size: 0.875rem;
  }
`;

export const StyledCancelBtn = styled(Button)`
  background-color: ${({ theme }) => theme.palette.grey[300]};
  color: currentColor;
  text-transform: none;
  font-weight: 600;
  &:hover {
    background-color: ${({ theme }) => theme.colors.cancelButtonHover};
  }
`;

export const StyledSubmitBtn = styled(LoadingButton)`
  text-transform: none;
  font-weight: 600;
  &:hover {
    background-color: ${({ theme }) => theme.colors.submitButtonHover};
  }
`;

export const StyledAddVisitButton = styled(Button)`
  display: flex;
  justify-self: center;
  align-items: center;
  gap: 0.25rem;
  width: 15rem;
  font-weight: 600;
  text-transform: none;

  svg {
    margin-bottom: 0.125rem;
  }
`;

export const CalendarIconStyled = styled(Calendar)`
  font-size: 1.25rem;
`;

export const StyledLabel = styled("span")`
  display: block;
  position: unset;
  line-height: 1.4375em;
  font-size: 1rem;
  transform: translate(10px, -4px) scale(0.75);
  color: ${({ theme }) => theme.colors.labelGray};
  margin-top: 0.5rem;
`;

export const SexButtonsWrapper = styled("div")`
  display: grid;
  justify-items: start;
`;

export const Divider = styled("div")`
  border-top: 1px solid ${({ theme }) => theme.colors.cancelButton};
  width: 18.5rem;
  justify-self: center;
`;

interface Flex {
  flex: number;
}
export const StyledPhoneTextField = styled(FormTextField)<Flex>`
  flex: ${({ flex }) => flex};
`;

export const StyledFormCountryAutocomplete = styled(
  FormCountryAutocomplete,
)<Flex>`
  flex: ${({ flex }) => flex};
`;

export const ActionButtonsWrapper = styled("div")`
  display: flex;
  justify-self: center;
  gap: 0.5rem;
  align-items: center;
`;

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
  [`& .${tooltipClasses.tooltipPlacementTop}`]: {
    top: "1rem",
    position: "relative",
  },
});
