import {
  FieldValues,
  Path,
  useController,
  useFormContext,
} from "react-hook-form";

import { OptionIf } from "../FiltersBuilder";
import {
  FilterChip,
  Label,
  OptionsWrapper,
} from "./SingleSelectChipFilter.styled";
import { Chip } from "@mui/material";

export interface SingleSelectChipFilterProps<T extends FieldValues> {
  label: string;
  options: OptionIf[];
  name?: Path<T>;
}
export const SingleSelectChipFilter = <T extends FieldValues>({
  label,
  options,
  name,
}: SingleSelectChipFilterProps<T>) => {
  const { control } = useFormContext();
  const defaultName = "tags";
  const {
    field: { value, onChange },
  } = useController({ control, name: name ?? defaultName });
  const selected = value as string;

  const toggleSelect = (id: string) => {
    if (selected === id) return;
    return onChange(id);
  };

  return (
    <div className="grid gap-[4px]">
      <Label>{label}</Label>

      <OptionsWrapper>
        {options.map(o => {
          const isSelected = !!o.id && selected?.includes(o.id);
          return (
            <FilterChip
              color="primary"
              selected={isSelected}
              variant="outlined"
              key={o.id}
              label={o.label}
              onClick={() => toggleSelect(o.id ?? "")}
            />
          );
        })}
      </OptionsWrapper>
    </div>
  );
};
