import { PageLayout } from "@components/PageLayout";
import { ThemeProviderWrapperNew } from "themeNew";
import { GoogleCard } from "./GoogleCard";
import { useAppTranslation } from "@hooks";

export const Integrations = () => {
  const { t } = useAppTranslation();

  return (
    <ThemeProviderWrapperNew>
      <PageLayout title={t("integrations.title")}>
        <GoogleCard />
      </PageLayout>
    </ThemeProviderWrapperNew>
  );
};
