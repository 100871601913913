import { useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Drawer, InputAdornment } from "@mui/material";
import { omit } from "lodash";

import { List, Loupe } from "@assets/icons";
import { FourSquares } from "@assets/icons/DesignSystem";
import { AutosaveWatchComponent } from "@components/AutosaveWatchComponent";
import {
  DietRowIf,
  DietsDataGrid,
  mapDietRows,
} from "@components/DietsDataGrid";
import {
  DEFAULT_DIETS_MACROS_RANGE,
  DietsFilters,
  DietsFiltersFormInput,
  useDietsFiltersForm,
} from "@components/DietsFilters";
import { FormTextFieldClearableStyled } from "@components/DietsFilters/DietsFilters.styled";
import { defaultFilters } from "@components/DietsFilters/dietsFiltersUtils";
import { DietsGridLayout } from "@components/DietsGridLayout";
import { TabsChipsFiltersForm } from "@components/Filters/TabsChipsFilter/TabsChipsFilterForm";
import { SingleCardPageLayout } from "@components/PageLayout";
import { ITEMS_ON_PAGE } from "@components/PaginationNew";
import { DietPreview } from "@components/PreviewDrawer/Diet";
import { TabSwitch } from "@components/TabSwitch/TabSwitch";
import {
  ListTabs,
  useAppTranslation,
  useListTabs,
  useModalState,
  useUserRoles,
} from "@hooks";
import { useSearchDietsQueryNew } from "@hooks/queries";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";

import {
  AutogenereteMealScheduleButton,
  DefaultValuesIf,
} from "@components/AutogenerateMealScheduleModal";
import { ProgramLengthWeeks } from "@components/AutogenerateMealScheduleModal/MealDateRangePicker";
import dayjs from "dayjs";
import { ThemeProviderWrapperNew } from "themeNew";
import { AddDietButton } from "./AddDietButton";
import { DietsViewWrapper } from "./DietsNew.styled";

export const DietsNew = () => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const form = useDietsFiltersForm();
  const [view, setView] = useState<ViewTabs>(ViewTabs.GRID);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openModal, onOpenModal, onCloseModal] = useModalState();
  const { diets, isFetching, meta, submit } = useSearchDietsQueryNew();
  const { account } = useFetchDietitianAccountQuery();
  const { adminTabs, regularTabs } = useListTabs();
  const { isAdminClinicMember } = useUserRoles();
  const tab = form.watch("tabs");

  const [selectedId, setSelectedId] = useState<number | null>(null);

  const tabs: { label: string; id: ListTabs }[] = useMemo(
    () => (isAdminClinicMember ? adminTabs : regularTabs),
    [t, isAdminClinicMember],
  );

  const tabsWithCount = useMemo(
    () =>
      tabs.map(option => ({
        ...option,
        label:
          option.id === tab && !!meta?.total
            ? `${option.label} (${meta?.total})`
            : option.label,
      })),
    [meta, t],
  );
  const mappedRows = useMemo<DietRowIf[]>(
    () => mapDietRows(diets, account?.id ?? 0),
    [diets, mapDietRows, account?.id, t],
  );

  const handleChangePage = (id: number) => {
    submit(state => ({ ...state, page: id + 1 }));
  };
  const handleChangePerPage = (perPage: number) => {
    submit(state => ({ ...state, perPage }));
  };

  const handleChangeTab = (tab: string) => {
    submit(state => ({
      ...state,
      tabs: tab as ListTabs,
      page: undefined,
    }));
    form.setValue("page", undefined);
  };

  const handleSubmitForm = (data: DietsFiltersFormInput) => {
    const nutrientsWithNonDefaultValues = Object.entries(
      data.nutrients || {},
    ).reduce((acc, [key, value]) => {
      const defaultValue = DEFAULT_DIETS_MACROS_RANGE[key];
      if (
        !defaultValue ||
        defaultValue[0] !== value[0] ||
        defaultValue[1] !== value[1]
      ) {
        acc[key] = value;
      }
      return acc;
    }, {} as { [id: string]: [number, number] });

    submit({
      ...omit(data, ["nutrients"]),
      page: undefined,
      nutrients: nutrientsWithNonDefaultValues,
    });
  };

  const handleResetFilters = () => {
    form.reset({ tabs: ListTabs.ALL, ...defaultFilters }, { keepDirty: true });
    form.setValue("page", undefined, { shouldDirty: true });

    setSearchParams(prev => {
      const params = new URLSearchParams();

      const perPage = prev.get("perPage");

      if (perPage) params.set("perPage", perPage);

      return params;
    });
  };

  const autogenerateDefaultValues: DefaultValuesIf = {
    startDate: dayjs().toDate(),
    endDate: dayjs().add(ProgramLengthWeeks.TWO, "days").toDate(),
  };

  return (
    <ThemeProviderWrapperNew>
      <DietsViewWrapper>
        <SingleCardPageLayout
          rebranding
          title={t("common.diets")}
          childrenClassName="flex flex-col gap-[24px]"
          extra={
            <div className="flex gap-[16px]">
              <AutogenereteMealScheduleButton
                defaultValues={autogenerateDefaultValues}
              />
              <AddDietButton />
            </div>
          }
        >
          <FormProvider {...form}>
            <div className="grid gap-[16px]">
              <div className="flex gap-[4px]">
                <FormTextFieldClearableStyled
                  name="query"
                  size="small"
                  placeholder={t("diets.searchDiet")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Loupe />
                      </InputAdornment>
                    ),
                  }}
                />
                <DietsFilters total={meta?.total} />
              </div>
              <div className="flex justify-between">
                <TabsChipsFiltersForm
                  name="tabs"
                  options={tabsWithCount}
                  onChangeExternal={handleChangeTab}
                />
                <TabSwitch
                  onChange={val => setView(val)}
                  tabs={[
                    {
                      id: ViewTabs.LIST,
                      label: <List size="w-[16px] h-[16px]" />,
                    },
                    {
                      id: ViewTabs.GRID,
                      label: <FourSquares size="w-[16px] h-[16px]" />,
                    },
                  ]}
                  value={view}
                />
              </div>
              <AutosaveWatchComponent
                isLoading={isFetching}
                onSubmit={handleSubmitForm}
                isDirtyCondition={false}
              />
              {view === ViewTabs.LIST && (
                <DietsDataGrid
                  rows={mappedRows}
                  isOnePage={false}
                  perPage={meta?.perPage ?? ITEMS_ON_PAGE.TWENTY_FIVE}
                  onPageChange={handleChangePage}
                  page={meta?.currentPage ? meta.currentPage - 1 : 0}
                  setPerPage={handleChangePerPage}
                  rowCount={meta?.total}
                  loading={isFetching}
                  onSelectRow={(id: number | null) => setSelectedId(id)}
                  resetFilters={handleResetFilters}
                />
              )}

              {view === ViewTabs.GRID && (
                <DietsGridLayout
                  diets={mappedRows}
                  isOnePage={false}
                  perPage={meta?.perPage ?? ITEMS_ON_PAGE.TWENTY_FIVE}
                  onPageChange={handleChangePage}
                  page={meta?.currentPage ? meta.currentPage - 1 : 0}
                  setPerPage={handleChangePerPage}
                  rowCount={meta?.total}
                  isLoading={isFetching}
                  onDietSelect={(id: number | null) => setSelectedId(id)}
                  resetFilters={handleResetFilters}
                />
              )}

              <Drawer open={!!selectedId} variant="persistent" anchor="right">
                <DietPreview
                  key={selectedId}
                  dietId={selectedId}
                  onClose={() => setSelectedId(null)}
                />
              </Drawer>
            </div>
          </FormProvider>
        </SingleCardPageLayout>
      </DietsViewWrapper>
    </ThemeProviderWrapperNew>
  );
};

enum ViewTabs {
  LIST = "1",
  GRID = "2",
}
