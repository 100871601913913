import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
} from "@mui/material";

export const AccordionStyled = styled(Accordion)`
  &.MuiAccordion-root {
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.neutral.light[200]};
    background: ${({ theme }) => theme.colors.neutral.light[100]};
    box-shadow: none;

    &.MuiPaper-root.MuiAccordion-root.Mui-expanded {
      margin: 0;
    }
  }

  :before {
    display: none;
  }
`;

export const AccordionDetailsStyled = styled(AccordionDetails)`
  padding: 0;
`;

export const AccordionSummaryStyled = styled(AccordionSummary)`
  padding: 8px;
  align-items: flex-start;
  & .Mui-expanded {
    margin: 0;
  }

  &.MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-gutters {
    min-height: unset;
  }
  & .MuiAccordionSummary-content.Mui-expanded {
    min-height: unset;
    margin: 0;
  }
  & .MuiAccordionSummary-content {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.neutral.dark[800]};
    margin: 0;
  }

  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
  }
  & .MuiAccordionSummary-expandIconWrapper {
    padding: 8px;
  }
`;

export const TitleStyled = styled("span")`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
  text-overflow: ellipsis;
  overflow: hidden;
  width: 590px;
`;

export const ImageStyled = styled("img")`
  width: 60px;
  min-width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 8px;
`;

export const ImgBackground = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.palette.primary.light2};
  height: 60px;
  width: 60px;
  min-width: 60px;
  border-radius: 8px;
`;

export const ImagePlaceholder = styled("img")`
  height: 52px;
`;

interface SummaryWrapperProps {
  paddingLeft?: boolean;
}

export const SummaryWrapper = styled("div")<SummaryWrapperProps>`
  display: flex;
  gap: 12px;
  ${({ paddingLeft }) => paddingLeft && "padding-left: 24px;"}
`;

export const CollectionContentStyled = styled("div")`
  display: grid;
  gap: 8px;
  padding: 8px;
`;

export const TitleUppercase = styled("span")`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
  text-transform: uppercase;
`;
