import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "../types";
import { deleteSurveySubgroupQuestion } from "@client/surveys";
import { fetchSurveyFullQueryKey } from "./useFetchSurveyFullQuery";

export const useDeleteSurveySubgroupQuestionMutation = (
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, string>(
    id => deleteSurveySubgroupQuestion(id),
    {
      ...options,
      onSuccess: (data, variables) => {
        options?.onSuccess && options.onSuccess(data, variables);

        queryClient.invalidateQueries([fetchSurveyFullQueryKey]);
      },
    },
  );
};
