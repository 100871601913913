import { Link } from "react-router-dom";
import { useState } from "react";

import { Button } from "@mui/material";
import dayjs from "dayjs";

import {
  EmptyEventDto,
  SurveyRespondedEventDto,
  SurveySentEventDto,
} from "@client/fetchClientEvents";
import { useAppParams, useAppTranslation } from "@hooks";
import { CLIENT_PROFILE_EVENT_STATUS } from "@utils";
import { PatientSurveyModal } from "@views/dietician/PatientSurveyModal";
import { PATIENTS } from "@routes";

import { EventWrapper } from "./HistoryItem.styled";
import { EventItem, useEventVariantData } from "./components/EventItem";

interface HistoryItemProps {
  event: SurveySentEventDto | SurveyRespondedEventDto | EmptyEventDto;
}

export const HistoryItem = ({ event }: HistoryItemProps) => {
  const { t } = useAppTranslation();
  const { type, createdAt, data } = event;
  const { icon, name } = useEventVariantData(type);
  const { patientId } = useAppParams();
  const eventDate = dayjs(createdAt).add(1, "hour").format("HH:mm");

  const [surveyId, setSurveyId] = useState<number | null>(null);
  const onShowSurveyResponse = (id?: number) => {
    if (!id) return;

    setSurveyId(id);
  };

  const onCloseSurveyResponse = () => setSurveyId(null);

  if (type === CLIENT_PROFILE_EVENT_STATUS.SURVEY_RESPONDED)
    return (
      <EventWrapper>
        <EventItem
          icon={icon}
          status={type}
          name={`${name} ${data.name}`}
          createdAt={eventDate}
        >
          <Link
            to={`${PATIENTS}/${patientId}/questionnaire/${data.id}`}
            onClick={e => e.preventDefault()}
          >
            <Button onClick={() => onShowSurveyResponse(data.id)}>
              {t("client_profile.history.see_responses")}
            </Button>
          </Link>
        </EventItem>
        <PatientSurveyModal
          onClose={onCloseSurveyResponse}
          open={!!surveyId}
          surveyId={surveyId?.toString()}
        />
      </EventWrapper>
    );

  if (type === CLIENT_PROFILE_EVENT_STATUS.SURVEY_SENT)
    return (
      <EventWrapper>
        <EventItem
          icon={icon}
          status={type}
          name={`${name} ${data.name}`}
          createdAt={eventDate}
        />
      </EventWrapper>
    );

  return (
    <EventWrapper>
      <EventItem icon={icon} status={type} name={name} createdAt={eventDate} />
    </EventWrapper>
  );
};
