import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { IdDto, idSchema } from "@client";
import * as yup from "yup";

export const postCreatorMealRecipe = async (
  mealId: string,
  payload: PostCreatorMealRecipeRequest,
  version: number,
): Promise<ApiResponse<IdDto>> => {
  const response = await fetchData(
    `/dietitian/creator/meals/${mealId}/recipe-add`,
    APIMethods.POST,
    payload,
    undefined,
    { "Alloweat-Creator-Version": `${version}` },
  );

  return apiResponseSchema.validate(response);
};

export interface PostCreatorMealRecipeRequest {
  id: number;
}

const apiResponseSchema = yup
  .object()
  .shape({
    data: idSchema.required(),
  })
  .required();
