import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAppTranslation } from "@hooks";

interface FailedOptimizationModalProps {
  onClose: () => void;
  onDaysView: () => void;
  onSettingsModal: () => void;
  open: boolean;
  optimizedDays: number;
  daysToOptimization: number;
}

export const FailedOptimizationModal = ({
  onClose,
  onSettingsModal,
  onDaysView,
  open,
  optimizedDays,
  daysToOptimization,
}: FailedOptimizationModalProps) => {
  const { t } = useAppTranslation();
  const { palette } = useTheme();
  const leftDaysToOptimization = daysToOptimization - optimizedDays;
  return (
    <Dialog onClose={onClose} open={open} maxWidth="small">
      <DialogTitle>
        {t("patient.schedule.optimization.alert_title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="whitespace-pre-line">
          <p>
            <span style={{ color: palette.success.light }}>
              {t("patient.schedule.optimization.alert_1", {
                count: optimizedDays,
              })}
              &nbsp;
            </span>
            {t("patient.schedule.optimization.corresponds_days")}
          </p>
          <p>
            <span style={{ color: palette.error.light }}>
              {t("patient.schedule.optimization.alert_1", {
                count: leftDaysToOptimization,
              })}
              &nbsp;
            </span>{" "}
            {t("patient.schedule.optimization.not", {
              count: leftDaysToOptimization,
            }) +
              " " +
              t("patient.schedule.optimization.corresponds_days")}
          </p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} variant="contained" color="inherit">
          {t("patient.schedule.optimization.skip")}
        </Button>
        <Button onClick={onSettingsModal} variant="contained" color="inherit">
          {t("patient.schedule.optimization.change_margin")}
        </Button>
        <LoadingButton onClick={onDaysView} variant="contained">
          {t("patient.schedule.optimization.change_diet_settings")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
