import * as yup from "yup";

import { TranslationLangDto, translationLangSchema } from "@client/common";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const fetchDietRecipeUsed = async (
  dietId: string,
): Promise<ApiResponse<UsedRecipeDto[]>> => {
  const response = await fetchData(
    `/dietitian/diets/${dietId}/recipe-used`,
    APIMethods.GET,
  );

  return responseSchema.validate(response);
};

export interface UsedRecipeDto {
  translations: TranslationLangDto[];
}

const usedRecipeSchema = yup.object({
  translations: yup.array().of(translationLangSchema).required(),
});

const responseSchema = yup.object({
  data: yup.array().of(usedRecipeSchema).required(),
});
