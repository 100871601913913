import { Dispatch, ReactNode, SetStateAction, useRef, useState } from "react";

import { ClickAwayListener, Popper } from "@mui/material";
import { CheckedIcon } from "@assets/icons";

import {
  StyledIconButton,
  StyledPopperPaper,
  StyledSelect,
  StyledSelectText,
} from "./BaseFilterSelect.styled";

interface CustomSelectProps {
  name: string;
  selected: boolean;
  children: ReactNode;
  open?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
}

export const BaseFilterSelect = ({
  name,
  selected,
  children,
  open: openExternal,
  setOpen: setOpenExternal,
}: CustomSelectProps) => {
  const [openInternal, setOpenInternal] = useState(false);
  const open = openExternal ?? openInternal;
  const setOpen = setOpenExternal ?? setOpenInternal;
  const anchor = useRef<HTMLDivElement>(null);
  const handleToggle = () => setOpen(state => !state);
  const handleClose = () => setOpen(false);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <StyledSelect ref={anchor} opened={open} onClick={handleToggle}>
          <StyledSelectText bold={selected}>{name}</StyledSelectText>
          <StyledIconButton size="small" opened={open}>
            <CheckedIcon />
          </StyledIconButton>
        </StyledSelect>
        <Popper
          open={open}
          anchorEl={anchor.current}
          placement="bottom"
          sx={{ zIndex: 999 }}
        >
          <StyledPopperPaper width={anchor.current?.clientWidth}>
            {children}
          </StyledPopperPaper>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};
