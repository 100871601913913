import { useCallback, useEffect, useState } from "react";

type EffectCallback = (opened: boolean) => void;

type UseModalReturn = {
  modalOpened: boolean;
  onModalClose: () => void;
  onModalOpen: () => void;
};

/**
 * @deprecated Use useModalState instead
 */
export function useModal(
  effectCallback: EffectCallback = () => undefined,
): UseModalReturn {
  const [modalOpened, setModalOpened] = useState(false);
  const onModalClose = useCallback(() => setModalOpened(false), []);
  const onModalOpen = useCallback(() => setModalOpened(true), []);
  useEffect(() => effectCallback(modalOpened), [modalOpened]);

  return { modalOpened, onModalClose, onModalOpen };
}
