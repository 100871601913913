import { useEffect, useMemo } from "react";
import { FormProvider } from "react-hook-form";

import { Plus } from "@assets/icons";
import { ModalWrapper } from "@components/ModalWrapperNew";
import {
  useAppParams,
  useAppTranslation,
  useControlledFieldArray,
  useMealClientContext,
} from "@hooks";

import { ButtonStyled } from "./ExchangeProductModal.styled";
import { ExchangeRow } from "./ExchangeRow";
import {
  ExchangeProductFormInput,
  Product,
  Unit,
  mapExchangeProductFormRequest,
  useExchangeProductForm,
} from "./useExchangeProductForm";
import { Tooltip } from "@mui/material";
import { usePostDietsProductsReplacementMutation } from "@views/dietician/DietCreator/hooks";
import { usePostPatientProductsContextMutation } from "@hooks/queries";

interface ExchangeProductModalProps {
  open: boolean;
  onClose: () => void;
  defaultProductId?: number;
}

export const ExchangeProductModal = ({
  open,
  onClose,
  defaultProductId,
}: ExchangeProductModalProps) => {
  const { t } = useAppTranslation();
  const { dietId } = useAppParams();
  const defaultValues = useMemo<ExchangeProductFormInput | undefined>(
    () =>
      defaultProductId !== undefined
        ? {
            products: [
              {
                ...NEW_ROW,
                fromProduct: defaultProductId,
              },
            ],
          }
        : undefined,
    [defaultProductId],
  );
  const { control, handleSubmit, watch, ...rest } =
    useExchangeProductForm(defaultValues);
  const { fields, append, remove } =
    useControlledFieldArray<ExchangeProductFormInput>({
      name: "products",
      control,
      watch,
    });
  const { mutate } = usePostDietsProductsReplacementMutation();
  const { mutate: fetchProducts } = usePostPatientProductsContextMutation();

  const onSubmit = () => {
    handleSubmit(
      d =>
        mutate(
          { dietId, payload: mapExchangeProductFormRequest(d) },
          { onSuccess: onClose },
        ),
      e => console.log(e),
    )();
  };

  const selectedDietProducts = useMemo(
    () =>
      fields.map(f => f.fromProduct).filter((id): id is number => id !== null),
    [fields],
  );

  const isMaxRows = fields.length >= 10;
  const foodIds = fields
    .flatMap(({ fromProduct, toProduct }) => [
      Number(fromProduct),
      Number(toProduct),
    ])
    .filter((id): id is number => id !== null);

  useEffect(() => {
    if (foodIds.length) fetchProducts({ payload: foodIds });
  }, [foodIds.length]);

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      title={t("diet.exchange_modal.title")}
    >
      <div className="grid gap-[20px]">
        <FormProvider
          control={control}
          watch={watch}
          handleSubmit={handleSubmit}
          {...rest}
        >
          <div className="grid gap-[16px]">
            {fields.map((f, idx) => (
              <ExchangeRow
                key={f.id}
                showLabel={idx === 0}
                idx={idx}
                onDelete={() => remove(idx)}
                selectedDietProducts={selectedDietProducts}
              />
            ))}
          </div>
        </FormProvider>

        <Tooltip
          title={t("diet.exchange_modal.tooltip")}
          placement="bottom-start"
          disableHoverListener={!isMaxRows}
        >
          <div>
            <ButtonStyled onClick={() => append(NEW_ROW)} disabled={isMaxRows}>
              <Plus />
              {t("diet.exchange_modal.exchange_another")}
            </ButtonStyled>
          </div>
        </Tooltip>
      </div>
    </ModalWrapper>
  );
};

const NEW_ROW: Product = {
  fromProduct: null,
  toProduct: null,
  measureId: null,
  ratio: 1,
  unit: Unit.G,
};
