import {
  ChipItemsWrapper,
  SectionItemChips,
  SectionItemLabel,
  SectionItemLayout,
  TabSectionLayout,
} from "@views/dietician/ClientHealth/ClientHealthNew.styled";
import { useAppTranslation, useClientParams } from "@hooks";
import { TagsUtilsReturn, useTagsUtils } from "@hooks/useTagsUtils";
import useFetchPatientProductExchangers from "@hooks/queries/client/useFetchPatientProductExchangers";
import { useMemo } from "react";
import { FetchPatientProductExchangersResponse } from "@client/patient/fetchPatientProductExchangers";
import { FetchTagCategoriesCategory } from "@client";
import { Chip } from "@mui/material";

const ProductExchangersItemSection = () => {
  const { t, currentLanguage } = useAppTranslation();
  const id = useClientParams();
  const { getTagById, getTagName } = useTagsUtils();
  const { data } = useFetchPatientProductExchangers({ patientId: id });

  const exchangers = useMemo(() => {
    if (!data) {
      return [];
    }

    const tags = mapProductExchangersToTags(data, getTagById);
    return tags
      .map(tag => getTagName(tag, currentLanguage))
      .filter(name => name !== undefined) as string[];
  }, [data, getTagById, currentLanguage]);

  return (
    <SectionItemLayout>
      <SectionItemLabel>
        {t(
          "patient.health_and_goal.dietary_profile_tab.section_dietary_profile.exchangers",
        )}
      </SectionItemLabel>
      <SectionItemChips>
        {exchangers.map((name, index) => (
          <Chip key={index} color="primary" label={name} />
        ))}
        {exchangers.length === 0 && (
          <Chip
            color="primary"
            label={t(
              "patient.health_and_goal.dietary_profile_tab.section_dietary_profile.regular",
            )}
          />
        )}
      </SectionItemChips>
    </SectionItemLayout>
  );
};

const mapProductExchangersToTags = (
  data: FetchPatientProductExchangersResponse,
  getTagById: TagsUtilsReturn["getTagById"],
): FetchTagCategoriesCategory["tags"] => {
  return data.data
    .map(exchange => getTagById(exchange.tag.id))
    .filter(tag => tag !== null) as FetchTagCategoriesCategory["tags"];
};

export default ProductExchangersItemSection;
