import { useMutation } from "@tanstack/react-query";
import { cancelVisit, CancelVisitPayload } from "@client";
import { MutationOptions } from "./types";

/**
 * @deprecated Change hook like everywhere instead of that. Use invalidation if necessary.
 */

export function useCancelVisitMutation(options?: MutationOptions) {
  return useMutation(
    (args: { url: string; data: CancelVisitPayload }) =>
      cancelVisit(args.url, args.data),
    {
      ...options,
      onSuccess: () => {
        options?.onSuccess && options.onSuccess();
      },
    },
  );
}
