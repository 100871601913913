import { useNavigate } from "react-router-dom";

import { Button, useMediaQuery, useTheme } from "@mui/material";

import { LOGIN } from "@routes";
import { useAppTranslation } from "@hooks";

import {
  CardStyled,
  CheckInCircleStyled,
  FixedButtonWrapper,
  MobileWrapperPaper,
  SuccessInfoWrapper,
  SuccessViewWrapper,
  Text,
  Title,
  TitleWrapper,
} from "./Reset.styled";
import { useEffect } from "react";

export const SuccessViewDietitian = () => {
  const { t } = useAppTranslation();
  const { breakpoints } = useTheme();
  const isDownSmall = useMediaQuery(`${breakpoints.down("smallNew")}`);
  const navigate = useNavigate();

  const navigateToLogin = () => navigate(LOGIN);

  useEffect(() => {
    const timeout = setTimeout(navigateToLogin, 10000);

    return () => clearTimeout(timeout);
  }, []);

  if (isDownSmall)
    return (
      <>
        <MobileWrapperPaper>
          <SuccessViewWrapper>
            <SuccessInfoWrapper>
              <TitleWrapper>
                <CheckInCircleStyled />
                <Title>{t("auth.reset.success_title")}</Title>
              </TitleWrapper>
              <Text>{t("auth.reset.login_info")}</Text>
              <Button variant="contained" onClick={navigateToLogin}>
                {t("auth.reset.login")}
              </Button>
            </SuccessInfoWrapper>
          </SuccessViewWrapper>
        </MobileWrapperPaper>
        <FixedButtonWrapper />
      </>
    );
  return (
    <CardStyled>
      <SuccessViewWrapper>
        <SuccessInfoWrapper>
          <TitleWrapper>
            <CheckInCircleStyled />
            <Title>{t("auth.reset.success_title")}</Title>
          </TitleWrapper>
          <Text>{t("auth.reset.login_info")}</Text>
          <Button variant="contained" onClick={navigateToLogin}>
            {t("auth.reset.login")}
          </Button>
        </SuccessInfoWrapper>
      </SuccessViewWrapper>
    </CardStyled>
  );
};
