export enum ProductCategories {
  RESTAURANT_DISHES = 3600,
  READY_MEALS = 5577808,
  EGGS = 901021,
  MEAT = 5578028,
  DAIRY = 100,
  BEVERAGES = 1400,
  NUTS_AND_SEEDS = 1200,
  FRUITS = 900,
  SEAFOOD = 5577739,
  BREAD = 5577598,
  FOODS_FOR_VEGETARIANS_AND_VEGANS = 5577537,
  BRAND_FOODS = 1000005,
  CEREAL_PRODUCTS = 2000,
  BREAKFAST_CEREALS = 800,
  SNACKS = 2500,
  SPICES_AND_HERBS = 200,
  APPETIZERS_AND_SIDE_DISHES = 2200,
  BEANS = 1600,
  FISH = 1500,
  SWEETS = 1900,
  SPREADS_AND_PASTES = 5577540,
  SAUCES = 5577743,
  SUPPLEMENTS = 5577831,
  FATS_AND_OILS = 400,
  VEGETABLES = 1100,
  OWN = 5578006,
  BAKED_PRODUCTS = 1800,
  SOUPS = 600,
  DIETETIC_FOOD = 5577834,
  BABY_FOODS = 300,
  FOODS_FOR_SPECIAL_MEDICAL_PURPOSES = 5577869,
}
