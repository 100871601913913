import useFetchProgramPreview from "@hooks/queries/program/preview/useFetchProgramPerview";
import useProgramSearchPreviewRemoveQueries from "@components/ProgramSearchPreview/hooks/useProgramSearchPreviewRemoveQueries";
import { Box, CircularProgress } from "@mui/material";
import { ProgramLayout } from "@components/ProgramSearchPreview/Components/ProgramLayout.styled";
import ProgramHeadLayout from "@components/ProgramSearchPreview/Components/Head/ProgramHeadLayout";
import ProgramActionButtons from "@components/ProgramSearchPreview/Components/ActionButton/ProgramActionButtons";
import ProgramPeriod from "@components/ProgramSearchPreview/Components/ProgramPeriod";
import ProgramHighlightedBox from "@components/ProgramSearchPreview/Components/ProgramHighlightedBox.styled";
import ProgramComponentsTabs from "@components/ProgramSearchPreview/Components/ProgramComponentsTabs/ProgramComponentsTabs";
import { useState } from "react";
import ProgramComponentsTabsPanels from "@components/ProgramSearchPreview/Components/ProgramComponentsTabs/ProgramComponentsTabsPanels";

type ProgramPreviewProps = {
  programId: number;
};

const ProgramSearchPreview = ({ programId }: ProgramPreviewProps) => {
  const [tabVisible, setTabVisible] = useState("1");
  useProgramSearchPreviewRemoveQueries({ programId });
  const { data, isFetching } = useFetchProgramPreview(
    { programId: programId },
    { staleTime: Infinity },
  );
  const program = data?.data;

  if (program === undefined || isFetching) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ProgramLayout>
      <ProgramHighlightedBox>
        <ProgramHeadLayout
          program={program}
          actionButtons={<ProgramActionButtons programId={programId} />}
        />
        <ProgramPeriod {...program} />
        <ProgramComponentsTabs setValue={setTabVisible} value={tabVisible} />
      </ProgramHighlightedBox>
      <ProgramComponentsTabsPanels program={program} value={tabVisible} />
    </ProgramLayout>
  );
};

export default ProgramSearchPreview;
