import { MouseEventHandler, useEffect, useMemo, useState } from "react";
import { useController } from "react-hook-form";

import {
  ClickAwayListener,
  Divider,
  IconButton,
  InputAdornment,
} from "@mui/material";

import { Pen } from "@assets/icons";
import { ArrowDown } from "@assets/icons/Arrows";
import { LangDto } from "@client";
import { useAppTranslation, useModalState } from "@hooks";
import { useTagsNew } from "@hooks/useTagsNew";
import { TagCategoryType } from "@utils/tagsNew";
import { getTranslation } from "@utils/translations";
import { useAutosaveOnBlurContext } from "@components/AutosaveOnBlur";

import { AddOwnType } from "./AddOwnType";
import { ListOption } from "./ListOption";
import {
  CardStyled,
  PopperStyled,
  TextFieldStyled,
} from "./MealTypeTexField.styled";
import { OwnTypeModal } from "./OwnTypeModal";
import {
  NAME_EN_PLACEHOLDER,
  NAME_PL_PLACEHOLDER,
  ScheduleRowInputs,
} from "./useScheduleRowForm";

interface MealTypeTextFieldProps {
  mealId: number;
}

export const MealTypeTextField = ({ mealId }: MealTypeTextFieldProps) => {
  const { currentLanguage, isPolishChosen } = useAppTranslation();
  const { onBlur, onFocus } = useAutosaveOnBlurContext();

  const {
    field: { value: namePl, onChange: changeNamePl },
  } = useController<ScheduleRowInputs, "namePl">({ name: "namePl" });
  const {
    field: { value: nameEn, onChange: changeNameEn },
  } = useController<ScheduleRowInputs, "nameEn">({ name: "nameEn" });
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController<ScheduleRowInputs, "mealTypeId">({ name: "mealTypeId" });

  const { getTagCategoryOptions } = useTagsNew();
  const [open, onOpen, onClose] = useModalState();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>();
  const [popoverWidth, setPopoverWidth] = useState<number | undefined>();
  const [popoverHeight, setPopoverHeight] = useState<number | undefined>();
  const options = getTagCategoryOptions(TagCategoryType.MEAL) ?? [];

  const isPlaceholder = useMemo(
    () => namePl === NAME_PL_PLACEHOLDER && nameEn === NAME_EN_PLACEHOLDER,
    [namePl, nameEn],
  );
  const hasCustomName = useMemo(
    () =>
      !options.some(o => o.label === (isPolishChosen ? namePl : nameEn)) &&
      !isPlaceholder,
    [options, isPolishChosen, namePl, nameEn, isPlaceholder],
  );
  const textValue = isPolishChosen ? namePl : nameEn;

  const handleClick: MouseEventHandler<HTMLDivElement> = e => {
    if (anchorEl) return setAnchorEl(null);

    const element = e.currentTarget;

    setAnchorEl(element);
    setPopoverWidth(element.clientWidth);

    const { top, height } = element.getBoundingClientRect();
    const marginOffset = 16;
    setPopoverHeight(window.innerHeight - top - height - marginOffset);

    return;
  };

  const handleSelect = (id: string) => {
    const option = options.find(o => o.id === id);
    const labelPl = getTranslation(option?.translations, LangDto.PL);
    const labelEn = getTranslation(option?.translations, LangDto.EN);

    onChange(id);
    changeNamePl(labelPl);
    changeNameEn(labelEn);
    handleClose();
    onBlur();
  };

  const handleClose = () => setAnchorEl(null);

  const handleOpen = () => {
    onOpen();
    setAnchorEl(null);
    onFocus();
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <div className="w-full">
          <TextFieldStyled
            key={currentLanguage}
            size="small"
            fullWidth
            value={isPlaceholder ? "" : textValue}
            placeholder={isPlaceholder ? (textValue as string) : undefined}
            onClick={handleClick}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  {hasCustomName && (
                    <IconButton
                      onClick={e => {
                        e.stopPropagation();
                        handleOpen();
                      }}
                    >
                      <Pen />
                    </IconButton>
                  )}
                  <IconButton>
                    <ArrowDown className={anchorEl ? "rotate-180" : ""} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={!!error}
            helperText={error?.message}
            onFocus={onFocus}
          />

          <PopperStyled
            open={!!anchorEl}
            anchorEl={anchorEl}
            width={popoverWidth}
            height={popoverHeight}
          >
            <CardStyled>
              {options.map(({ id, label }) => (
                <ListOption
                  key={id}
                  label={label}
                  onClick={() => handleSelect(id)}
                  className={value === id ? "Mui-selected" : ""}
                />
              ))}

              <Divider
                sx={({ colors }) => ({
                  borderColor: colors.neutral.light[200],
                })}
              />

              <AddOwnType onClick={handleOpen} />
            </CardStyled>
          </PopperStyled>
        </div>
      </ClickAwayListener>
      {open && <OwnTypeModal open={open} onClose={onClose} mealId={mealId} />}
    </>
  );
};
