import { useFormContext } from "react-hook-form";

import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";

import { useAppParams, useAppTranslation } from "@hooks";
import {
  useCloneRecipeToBaseMutation,
  useCreateRecipeMutation,
} from "@hooks/queries";
import { useCreatorVersion } from "@hooks/queries/diets/creator/useCreatorVersion";

import {
  RecipeEditInput,
  mapRecipeCreateRequest,
  mapRecipeEditRequest,
} from "../useRecipeEditForm";
import { Footer } from "./DrawerFooter.styled";
import { RecipeId } from "../RecipeEditDrawer";
import { toast } from "react-toastify";
import { useUpdateDietMealRecipeMutation } from "@views/dietician/DietCreator/hooks";

interface DrawerFooterProps {
  onClose: () => void;
  mealId: string;
  recipeIds: RecipeId | null;
  dietId: string;
}

export const DrawerFooter = ({
  onClose,
  mealId,
  recipeIds,
  dietId,
}: DrawerFooterProps) => {
  const { t } = useAppTranslation();
  const { handleSubmit } = useFormContext<RecipeEditInput>();
  const { version, invalidateVersion } = useCreatorVersion();
  const { mutate: updateRecipe, isLoading: isUpdating } =
    useUpdateDietMealRecipeMutation({
      onSuccess: onClose,
      onError: e => {
        toast.error(t("diet.recipe_sidepanel.error_toast"));
        invalidateVersion(e);
      },
    });
  const { mutate: saveToBase, isLoading: isSavingToBase } =
    useCloneRecipeToBaseMutation({
      onSuccess: () => {
        toast.success(t("diet.recipe_sidepanel.add_to_base_toast"));
      },
    });
  const { mutate: createRecipe, isLoading } = useCreateRecipeMutation();
  const recipeExist = recipeIds !== null;

  const handleEdit = (onSuccess: () => void) =>
    handleSubmit(
      data =>
        recipeExist
          ? updateRecipe(
              {
                dietId,
                mealId,
                recipeId: recipeIds.dietRecipeId,
                payload: mapRecipeEditRequest(
                  data,
                  Number(version),
                  recipeIds.dietRecipeId,
                ),
              },
              { onSuccess },
            )
          : {},
      err => console.log(err),
    )();

  const handleCreate = (onSuccess: () => void) =>
    handleSubmit(
      data =>
        createRecipe(
          {
            payload: mapRecipeCreateRequest(data),
          },
          {
            onSuccess: () => {
              toast.success(t("common.saved"));
              onSuccess();
            },
          },
        ),
      err => console.log(err),
    )();

  const handleSaveToBase = () =>
    handleEdit(() => recipeExist && saveToBase(recipeIds.recipeId));
  const onSubmit = () =>
    recipeExist ? handleEdit(onClose) : handleCreate(onClose);

  return (
    <Footer>
      <Button variant="outlined" onClick={onClose}>
        {t("common.cancel")}
      </Button>
      {recipeExist && (
        <LoadingButton
          variant="outlined"
          onClick={handleSaveToBase}
          loading={isSavingToBase}
        >
          {t("diet.recipe_sidepanel.add_to_base")}
        </LoadingButton>
      )}
      <LoadingButton
        variant="contained"
        onClick={onSubmit}
        loading={isUpdating}
      >
        {t("common.save")}
      </LoadingButton>
    </Footer>
  );
};
