import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import { logFetchValidateError } from "@utils/validate";
import * as yup from "yup";
import {
  PatientBodyMeasurementsSettingsResource,
  patientBodyMeasurementsSettingsSchema,
} from "@client/resources/PatientBodyMeasurementsSettingsResource";

export type FetchPatientBodyMeasurementSettingsParams = {
  patientId: number;
};

export type FetchPatientBodyMeasurementSettingsResponse =
  ApiResponse<PatientBodyMeasurementsSettingsResource>;

export const fetchPatientBodyMeasurementSettings = async (
  params: FetchPatientBodyMeasurementSettingsParams,
): Promise<FetchPatientBodyMeasurementSettingsResponse> => {
  const response = await fetchData(
    "/dietitian/patients/{patient_id}/body-measurements-settings".replace(
      "{patient_id}",
      params.patientId.toString(),
    ),
    APIMethods.GET,
  );

  return await schema
    .validate(response)
    .catch(
      logFetchValidateError(
        "/dietitian/patients/{patient_id}/body-measurements-settings",
      ),
    );
};

const schema = yup.object({
  data: patientBodyMeasurementsSettingsSchema.defined(),
});
