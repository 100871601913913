import {
  ENERGY_PER_GRAM_OF_CARB,
  ENERGY_PER_GRAM_OF_FAT,
  ENERGY_PER_GRAM_OF_PROTEIN,
} from "@utils/macros";

type MacroProps = {
  value: number;
  target?: number;
};

export const getMacroKcal = ({
  protein,
  fat,
  carb,
}: {
  protein: MacroProps;
  fat: MacroProps;
  carb: MacroProps;
}): MacroProps => {
  const value =
    protein.value * ENERGY_PER_GRAM_OF_PROTEIN +
    fat.value * ENERGY_PER_GRAM_OF_FAT +
    carb.value * ENERGY_PER_GRAM_OF_CARB;

  let target = undefined;
  if (protein.target && fat.target && carb.target) {
    target =
      protein.target * ENERGY_PER_GRAM_OF_PROTEIN +
      fat.target * ENERGY_PER_GRAM_OF_FAT +
      carb.target * ENERGY_PER_GRAM_OF_CARB;
  }

  return {
    value,
    target,
  };
};
