import { useEffect } from "react";
import { useForm } from "react-hook-form";

import * as yup from "yup";

import {
  RadioAnswerQuestionDto,
  UpdateSingedSurveyRequest,
} from "@client/surveys";
import { yupResolver } from "@hookform/resolvers/yup";

export const useRadioAnswerForm = (
  values: RadioAnswerFormInputs | undefined,
  required: boolean,
) => {
  const resolver = yup.object().shape({
    uuid: required ? yup.string().required() : yup.string().nullable(),
    text: yup.string().nullable(),
  });

  const form = useForm<RadioAnswerFormInputs>({
    resolver: yupResolver(resolver),
  });

  useEffect(() => {
    if (values) form.reset(values);
  }, [values]);

  return form;
};

export const mapRadioAnswerForm = (
  data?: RadioAnswerQuestionDto,
): RadioAnswerFormInputs => {
  if (!data) return { text: null, uuid: null };
  return data;
};

export const mapRadioAnswerRequest = (
  data: RadioAnswerFormInputs,
  questionId: number,
): UpdateSingedSurveyRequest => {
  if (!data.uuid) return { questionId, answer: { text: null, uuid: null } };
  return { questionId, answer: data };
};

export interface RadioAnswerFormInputs {
  uuid: string | null;
  text: string | null;
}
