import { ListTabs } from "@hooks";
import { useController, useFormContext } from "react-hook-form";
import { CheckNew } from "@assets/icons";
import { CustomChip } from "@components/Filters/TabsChipsFilter/TabsChipsFilter.styled";

type FilterTabChipType = {
  label: string;
  formField: ListTabs;
  total: number;
};

const FilterTabChip = ({ label, formField, total }: FilterTabChipType) => {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ control, name: formField });
  const handleOnClick = () => {
    onChange(value === "1" ? null : "1");
  };

  return (
    <CustomChip
      data-chip="true"
      color={value === "1" ? "primary" : "default"}
      onClick={handleOnClick}
      variant="outlined"
      label={
        <div className="flex items-center gap-2">
          {value === "1" && <CheckNew size="w-[12px] h-[12px]" />}
          {value === "1" ? `${label} (${total})` : label}
        </div>
      }
    />
  );
};

export default FilterTabChip;
