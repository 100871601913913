import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Folder = (props: SvgIconProps) => (
  <SvgIcon {...props} width="26" height="26" viewBox="0 0 26 26">
    <g id="ic-menu-patient-content-base">
      <rect
        id="Rectangle_1215"
        data-name="Rectangle 1215"
        width="26"
        height="26"
        fill="#fff"
        opacity="0"
      />
      <path
        id="Path_1796"
        data-name="Path 1796"
        d="M19.053,60.534a3.246,3.246,0,0,1-3.242-3.242V42.674a3.246,3.246,0,0,1,3.242-3.242H23.4a3.247,3.247,0,0,1,3.054,2.156l.48,1.5H34.89a3.246,3.246,0,0,1,3.242,3.242v1.631h2.849a.8.8,0,0,1,.63.3l.007.008a.8.8,0,0,1,.177.685L39.75,57.993a3.225,3.225,0,0,1-3.156,2.541ZM22.693,58l0,.009a1.319,1.319,0,0,1-.1.325l-.271.586H36.589a1.622,1.622,0,0,0,1.591-1.273l1.858-8.08H24.57Zm-3.64-16.96a1.633,1.633,0,0,0-1.631,1.631V57.292a1.633,1.633,0,0,0,1.631,1.631h.481a1.622,1.622,0,0,0,1.591-1.273l1.986-9.066a.8.8,0,0,1,.785-.626H36.52V46.329A1.633,1.633,0,0,0,34.89,44.7H26.373a.8.8,0,0,1-.716-.547l-.657-2a1.63,1.63,0,0,0-1.546-1.114l-.074,0Z"
        transform="translate(-15.932 -37.811)"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
);
