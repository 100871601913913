import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const CrossedCircle = (props: SvgIconProps) => {
  return (
    <SvgIcon width="12" height="12" viewBox="0 0 12 12">
      <path
        d="M3.64709 2.73218L9.31132 8.3964C9.78528 7.73413 10.0625 6.92163 10.0625 6.04354C10.0625 3.8007 8.2428 1.98104 5.99996 1.98104C5.12187 1.98104 4.30937 2.25822 3.64709 2.73218ZM2.6886 3.69067C2.21464 4.35295 1.93746 5.16545 1.93746 6.04354C1.93746 8.28638 3.75712 10.106 5.99996 10.106C6.87805 10.106 7.69055 9.82886 8.35282 9.3549L2.6886 3.69067ZM11.4166 6.04354C11.4166 7.48013 10.8459 8.85788 9.83012 9.8737C8.8143 10.8895 7.43655 11.4602 5.99996 11.4602C4.56337 11.4602 3.18562 10.8895 2.1698 9.8737C1.15398 8.85788 0.583293 7.48013 0.583293 6.04354C0.583293 4.60695 1.15398 3.2292 2.1698 2.21338C3.18562 1.19755 4.56337 0.626872 5.99996 0.626872C7.43655 0.626872 8.8143 1.19755 9.83012 2.21338C10.8459 3.2292 11.4166 4.60695 11.4166 6.04354Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
