import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { logFetchValidateError } from "@utils/validate";
import * as yup from "yup";
import {
  PatientImportantNutrientResource,
  patientImportantNutrientSchema,
} from "@client/resources/PatientImportantNutrientResource";

export type FetchPatientImportantNutrientsParams = {
  patientId: number;
};

export type FetchPatientImportantNutrientsResponse = ApiResponse<
  PatientImportantNutrientResource[]
>;

export const fetchPatientImportantNutrients = async (
  params: FetchPatientImportantNutrientsParams,
): Promise<FetchPatientImportantNutrientsResponse> => {
  const response = await fetchData(
    "/dietitian/patients/{patient_id}/important-nutrients".replace(
      "{patient_id}",
      params.patientId.toString(),
    ),
    APIMethods.GET,
  );

  return await schema
    .validate(response)
    .catch(
      logFetchValidateError(
        "/dietitian/patients/{patient_id}/important-nutrients",
      ),
    );
};

const schema = yup.object({
  data: yup.array().of(patientImportantNutrientSchema).defined(),
});
