import { useRef } from "react";

import { round } from "lodash";
import { Tooltip, Typography } from "@mui/material";

import { ENERGY_ID } from "@utils/macros";
import {
  MacroWrapper,
  StyledTypography,
} from "@components/MacrosList/MacrosListMui.styled";
import { useNutrientDictionary } from "@hooks";
import { Nutrient } from "@typeDefinitions";

interface MacrosListElementProps {
  macro: Nutrient;
  compact?: boolean;
  fixed?: boolean;
}
export const MacrosListElement = ({
  macro,
  compact,
  fixed,
}: MacrosListElementProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const isEllipsisActive = (e: HTMLDivElement | null) => {
    if (!e) return false;
    return e.offsetWidth < e.scrollWidth;
  };
  const { nutrientDictionary } = useNutrientDictionary();

  const getNutrient = (id: number) => {
    if (nutrientDictionary[id]) return nutrientDictionary[id];
    return;
  };

  const isEllipsis = isEllipsisActive(containerRef.current);

  if (macro.id === ENERGY_ID)
    return (
      <Tooltip
        title={`${round(macro.value)} ${getNutrient(macro.id)?.unit}`}
        disableHoverListener={!isEllipsis}
      >
        <MacroWrapper key={macro.id} compact={compact} fixed={fixed}>
          <StyledTypography
            ref={containerRef}
            className="nutrient-text"
            variant="subtitle2"
          >
            {round(macro.value)}
          </StyledTypography>
          <Typography variant="body2" className="nutrient-text">
            {getNutrient(macro.id)?.unit}
          </Typography>
        </MacroWrapper>
      </Tooltip>
    );
  else
    return (
      <Tooltip
        title={`${getNutrient(macro.id)?.short}: ${round(macro.value)} ${
          getNutrient(macro.id)?.unit
        }`}
        disableHoverListener={!isEllipsis}
      >
        <MacroWrapper key={macro.id} compact={compact} fixed={fixed}>
          <Typography variant="body2" className="nutrient-text">
            {getNutrient(macro.id)?.short}:
          </Typography>
          <StyledTypography
            ref={containerRef}
            variant="subtitle2"
            className="nutrient-text"
          >
            {round(macro.value)} {getNutrient(macro.id)?.unit}
          </StyledTypography>
        </MacroWrapper>
      </Tooltip>
    );
};
