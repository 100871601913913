import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MutationOptions } from "@hooks/queries/types";
import { deleteProgramDietMealProduct } from "@client/program/dietCreator";
import { useCreatorVersion } from "@hooks/queries/diets/creator/useCreatorVersion";
import {
  fetchCreatorDietMealNutrientsQueryKey,
  fetchCreatorDietMealsItemsQuery,
  fetchCreatorDietMealsQueryKey,
  fetchCreatorDietNutrientsQueryKey,
  fetchCreatorDietQueryKey,
} from "@hooks/queries/diets/creator";
import { DietMealItemCreatorResponseDto } from "@client/diets/creator";
import { ApiResponse } from "@typeDefinitions";

export const useDeleteProgramDietMealProductMutation = (
  programId: string,
  options?: MutationOptions,
) => {
  const { version } = useCreatorVersion();
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    unknown,
    UseDeleteProgramDietMealProductMutationPayload
  >(
    ({ dietId, mealId, productId }) =>
      deleteProgramDietMealProduct(
        programId,
        dietId,
        mealId,
        productId,
        version,
      ),
    {
      ...options,
      onMutate: async ({ dietId, mealId, productId }) => {
        const previousData = queryClient.getQueryData<
          ApiResponse<DietMealItemCreatorResponseDto>
        >([fetchCreatorDietMealsItemsQuery, dietId, mealId]);

        queryClient.setQueryData<ApiResponse<DietMealItemCreatorResponseDto>>(
          [fetchCreatorDietMealsItemsQuery, dietId, mealId],
          oldData => {
            if (!oldData) return previousData;

            return {
              ...oldData,
              data: {
                recipes: oldData?.data.recipes,
                products: oldData?.data.products.filter(
                  ({ id }) => id.toString() !== productId,
                ),
              },
            };
          },
        );
      },
      onError: (e, { dietId, mealId }) => {
        options?.onError?.(e);
        queryClient.invalidateQueries([
          fetchCreatorDietMealsItemsQuery,
          dietId,
          mealId,
        ]);
      },
      onSuccess: (d, v) => {
        options?.onSuccess && options.onSuccess(d, v);
        queryClient.invalidateQueries([
          fetchCreatorDietMealsItemsQuery,
          v.dietId,
        ]);
        queryClient.invalidateQueries([
          fetchCreatorDietMealsQueryKey,
          v.dietId,
        ]);
        queryClient.invalidateQueries([fetchCreatorDietQueryKey, v.dietId]);
        queryClient.invalidateQueries([
          fetchCreatorDietNutrientsQueryKey,
          v.dietId,
        ]);
        queryClient.invalidateQueries([
          fetchCreatorDietMealNutrientsQueryKey,
          v.dietId,
        ]);
      },
    },
  );
};

interface UseDeleteProgramDietMealProductMutationPayload {
  dietId: string;
  mealId: string;
  productId: string;
}
