import {
  NutrientValueResource,
  nutrientValueSchema,
} from "@client/resources/NutrientValueResource";
import * as yup from "yup";

export interface PatientNormNutrientResource {
  norm: {
    id: number;
  } | null;
  isEdited: boolean;
  nutrients: NutrientValueResource[];
}

export const patientNormNutrientsSchema = yup.object({
  norm: yup
    .object({
      id: yup.number().required(),
    })
    .nullable()
    .defined(),
  isEdited: yup.boolean().required(),
  nutrients: yup.array().of(nutrientValueSchema).defined(),
});
