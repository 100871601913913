import { createContext, ReactNode, useContext } from "react";

type ContextProps = {
  onClose: () => void;
  programId: number | null;
};

const ProgramSearchPreviewDrawerContext = createContext<ContextProps>({
  onClose: () => undefined,
  programId: null,
});

type ProviderProps = {
  children: ReactNode;
  onClose: () => void;
  programId: number | null;
};
export const ProgramSearchPreviewDrawerContextProvider = ({
  programId,
  onClose,
  children,
}: ProviderProps) => {
  return (
    <ProgramSearchPreviewDrawerContext.Provider
      value={{
        onClose,
        programId,
      }}
    >
      {children}
    </ProgramSearchPreviewDrawerContext.Provider>
  );
};

export const useProgramSearchPreviewDrawer = () => {
  const context = useContext(ProgramSearchPreviewDrawerContext);
  if (context === undefined) {
    throw new Error(
      "usePreviewProgramDrawer must be used within a ProgramSearchPreviewDrawerContext",
    );
  }
  return context;
};
