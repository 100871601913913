import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const deleteCreatorDietMealRecipe = (
  dietId: string,
  mealId: string,
  recipeId: string,
  version: string,
) => {
  return fetchData(
    `/dietitian/creator-2/diets/${dietId}/meals/${mealId}/recipes/${recipeId}`,
    APIMethods.DELETE,
    undefined,
    undefined,
    { "Alloweat-Creator-Version": version },
  );
};
