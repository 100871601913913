import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export async function updateVisitBoxValue(
  scheduleId: number,
  boxId: number,
  payload: BoxValuePayload,
): Promise<ApiResponse<boolean>> {
  return fetchData(
    `/dietitian/schedules/${scheduleId}/visit/boxes/${boxId}`,
    APIMethods.PUT,
    payload,
  );
}

export interface BoxValuePayload {
  value: string;
}
