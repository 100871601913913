import { useNavigate } from "react-router-dom";

import { useAppTranslation } from "@hooks";
import { Button } from "@components/Button";

/**
 * page showing 404 error
 */
export const NotFound = () => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center bg-gray-300">
      <p className="text-7xl font-semibold">404</p>
      <p className="text-lg mb-4">{t("errors.not_found")}</p>
      <Button size="large" onClick={() => navigate(-1)}>
        {t("common.back")}
      </Button>
    </div>
  );
};
