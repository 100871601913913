import { handleDayBarColor, handleDayBarWidth } from "../monitoringUtils";
import {
  PercentageBarLeft,
  PercentageBarRight,
  PercentageBarWrapper,
} from "./MonitoringCalendar.styled";

interface PercentageBarProps {
  kcal: number | undefined;
  targetKcal: number | undefined;
}

export const PercentageBar = ({ kcal, targetKcal }: PercentageBarProps) => {
  const percentage = kcal && targetKcal && (kcal / targetKcal) * 100;

  const { left: leftColor, right: rightColor } = handleDayBarColor(
    kcal,
    targetKcal,
  );
  const { left: leftWidth, right: rightWidth } = handleDayBarWidth(percentage);

  return (
    <PercentageBarWrapper>
      <PercentageBarLeft
        background={leftColor}
        width={`${leftWidth}%`}
        fullRadius={leftWidth === 100}
      />
      {!!rightWidth && (
        <PercentageBarRight background={rightColor} width={`${rightWidth}%`} />
      )}
    </PercentageBarWrapper>
  );
};
