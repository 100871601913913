import {
  SectionHeadEditLayout,
  SectionHeadLayout,
  SectionLayout,
  SectionTitle,
  TabHeadActionEditLabel,
} from "@views/dietician/ClientHealth/ClientHealthNew.styled";
import { useAppTranslation, useClientParams } from "@hooks";
import { useState } from "react";
import DateMeasurement from "@views/dietician/ClientHealth/BodyMeasurementTab/MeasurementSection/DateMeasurement";
import { Box } from "@mui/material";
import { MeasurementList } from "@views/dietician/ClientHealth/components/MeasurementList";
import { useFetchClientQueryNew } from "@hooks/queries";
import LastMeasurementBodyTypeTabs from "@views/dietician/ClientHealth/BodyMeasurementTab/MeasurementSection/LastMeasurementBodyTypeTabs";
import CreateBodyMeasurementsModal from "@components/Client/FormBodyMeasurementsModal/CreateBodyMeasurementsModal";
import { MenuHamburgerIcon, PlusIcon } from "@icons/index";

const MeasurementSection = () => {
  const { t } = useAppTranslation();
  const id = useClientParams();
  const { data: client } = useFetchClientQueryNew(id);

  const [open, setOpen] = useState(false);
  const [openCreator, setOpenCreator] = useState(false);

  return (
    <>
      <SectionLayout>
        <SectionHeadLayout>
          <SectionTitle>
            {t(
              "patient.health_and_goal.body_measurement_tab.section_last_measurement.section_title",
            )}
          </SectionTitle>
          <Box display="flex" flexDirection="row" gap="16px">
            <SectionHeadEditLayout onClick={() => setOpenCreator(true)}>
              <PlusIcon color="#7448D0" />
              <TabHeadActionEditLabel>
                {t(
                  "patient.health_and_goal.body_measurement_tab.section_last_measurement.create_button",
                )}
              </TabHeadActionEditLabel>
            </SectionHeadEditLayout>
            <SectionHeadEditLayout onClick={() => setOpen(true)}>
              <MenuHamburgerIcon color="#7448D0" />
              <TabHeadActionEditLabel>
                {t(
                  "patient.health_and_goal.body_measurement_tab.section_last_measurement.show_button",
                )}
              </TabHeadActionEditLabel>
            </SectionHeadEditLayout>
          </Box>
        </SectionHeadLayout>

        <DateMeasurement />
        <LastMeasurementBodyTypeTabs />
      </SectionLayout>
      <CreateBodyMeasurementsModal
        open={openCreator}
        setOpen={setOpenCreator}
      />
      <MeasurementList
        onClose={() => setOpen(false)}
        opened={open}
        clientId={id}
        active={!!client?.data.active}
      />
    </>
  );
};

export default MeasurementSection;
