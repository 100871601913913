import { useMemo, useState } from "react";
import { Control, FieldValues, Path, useController } from "react-hook-form";
import { useDebounce } from "react-use";

import { Autocomplete, TextField } from "@mui/material";

import { OptionIf } from "@components/Filters";
import { useAppTranslation } from "@hooks";
import { useSearchDietsInfiniteQuery } from "@hooks/queries";
import { MacrosText } from "@components/MacrosText";
import { LiStyled } from "./DietSearchAutocomplete.styled";
import { TagWithIcon } from "@components/TagWithIcon";
import { FoodBowl } from "@assets/icons/DesignSystem";

interface DietSearchAutocompleteProps<T extends FieldValues> {
  control?: Control<T>;
  name: Path<T>;
  defaultQuery?: string;
  placeholder?: string;
}

export const DietSearchAutocomplete = <T extends FieldValues>({
  control,
  name,
  defaultQuery,
  placeholder,
}: DietSearchAutocompleteProps<T>) => {
  const { isPolishChosen, t } = useAppTranslation();
  const {
    field: { value, onChange },
  } = useController<T>({ control, name });

  const { diets, submit, isLoading, fetchNextPage } =
    useSearchDietsInfiniteQuery({}, { keepPreviousData: true });

  const options = useMemo(
    () =>
      diets?.map(({ id, namePl, nameEn, macros, mealsCount }) => ({
        id: id.toString(),
        label: (isPolishChosen ? namePl : nameEn) ?? "",
        macros,
        mealsCount,
      })) ?? [],
    [diets, isPolishChosen],
  );

  const selectedOption = useMemo(
    () => options.find(({ id }) => id === value),
    [value, options],
  );

  const [query, setQuery] = useState(defaultQuery);

  useDebounce(
    () => {
      if (query) return submit({ query });

      submit(undefined);
    },
    200,
    [query],
  );

  return (
    <Autocomplete
      key={selectedOption?.id}
      size="small"
      fullWidth
      options={options}
      renderInput={p => (
        <TextField
          onChange={e => setQuery(e.target.value)}
          placeholder={placeholder}
          {...p}
        />
      )}
      value={selectedOption}
      onChange={(e, v) => v && onChange(v.id)}
      filterOptions={o => o}
      loading={isLoading}
      ListboxProps={{
        onScroll: (event: React.SyntheticEvent) => {
          const listboxNode = event.currentTarget;
          if (
            listboxNode.scrollHeight -
              (listboxNode.scrollTop + listboxNode.clientHeight) <
            SCROLL_TOLERANCE
          ) {
            fetchNextPage();
          }
        },
      }}
      renderOption={(props, option) => (
        <LiStyled {...props} key={option.id} className="">
          {option.label}
          <div className="flex gap-[9px]">
            <MacrosText nutrients={option.macros} />
            <TagWithIcon
              label={`${option.mealsCount} ${t("diets.meals", {
                count: option.mealsCount,
              })}`}
              Icon={FoodBowl}
            />
          </div>
        </LiStyled>
      )}
    />
  );
};

const SCROLL_TOLERANCE = 3;
