import { AppTranslationProps, useAppTranslation } from "@hooks";
import {
  CARBS_ID,
  ENERGY_ID,
  FATS_ID,
  MACROS_ARRAY,
  MicrosFilters,
  PROTEIN_ID,
} from "@utils/macros";
import { decimalInput1ThreeDigits } from "@utils/inputs";
import { useMemo } from "react";
import { SliderFilterForm } from "@components/Filters/SliderFilter";
import { useFormContext } from "react-hook-form";
import { ShowMoreNutrients } from "@components/Filters/ShowMoreNutrients";

const ProgramNutrientsTabContent = () => {
  const { t } = useAppTranslation();
  const { control } = useFormContext();
  const nutrients = useMemo(() => nutrientOptions({ t }), [t]);
  const macroNutrients = useMemo(
    () => nutrients.filter(n => MACROS_ARRAY.includes(Number(n.nutrientId))),
    [nutrients],
  );
  const otherNutrients = useMemo(
    () => nutrients.filter(n => !MACROS_ARRAY.includes(Number(n.nutrientId))),
    [nutrients],
  );

  return (
    <>
      {macroNutrients.map(nutrient => (
        <SliderFilterForm
          key={nutrient.nutrientId}
          control={control}
          {...nutrient}
        />
      ))}
      <ShowMoreNutrients>
        <div className="grid gap-[16px]">
          {otherNutrients.map(nutrient => (
            <SliderFilterForm
              key={nutrient.nutrientId}
              control={control}
              {...nutrient}
            />
          ))}
        </div>
      </ShowMoreNutrients>
    </>
  );
};

const nutrientOptions = ({ t }: { t: AppTranslationProps["t"] }) => [
  {
    nutrientId: ENERGY_ID.toString(),
    label: t("common.energy_efficiency"),
    maxValue: 4500,
    step: 50,
  },
  {
    nutrientId: PROTEIN_ID.toString(),
    maxValue: 200,
    step: 5,
  },
  {
    nutrientId: FATS_ID.toString(),
    maxValue: 200,
    step: 5,
  },
  {
    nutrientId: CARBS_ID.toString(),
    maxValue: 800,
    step: 5,
  },
  {
    nutrientId: MicrosFilters.SATURATED_FAT.toString(),
    maxValue: 30,
    step: 1,
  },
  {
    nutrientId: MicrosFilters.CHOLESTEROL.toString(),
    maxValue: 500,
    step: 5,
  },
  {
    nutrientId: MicrosFilters.DIETARY_FIBER.toString(),
    maxValue: 50,
    step: 1,
  },
  {
    nutrientId: MicrosFilters.SODIUM.toString(),
    maxValue: 2500,
    step: 5,
  },
  {
    nutrientId: MicrosFilters.POTASSIUM.toString(),
    maxValue: 4000,
    step: 5,
  },
  {
    nutrientId: MicrosFilters.CALCIUM.toString(),
    maxValue: 1300,
    step: 5,
  },
  {
    nutrientId: MicrosFilters.PHOSPHORUS.toString(),
    maxValue: 1500,
    step: 5,
  },
  {
    nutrientId: MicrosFilters.MAGNESIUM.toString(),
    maxValue: 500,
    step: 5,
  },
  {
    nutrientId: MicrosFilters.IRON.toString(),
    maxValue: 30,
    step: 1,
  },
  {
    nutrientId: MicrosFilters.TOTAL_FOLATE.toString(),
    maxValue: 600,
    step: 5,
  },
  {
    nutrientId: MicrosFilters.KOBALAMINA.toString(),
    maxValue: 3,
    step: 0.1,
    decimalInput: decimalInput1ThreeDigits,
  },
  {
    nutrientId: MicrosFilters.GLYCEMIC_LOAD.toString(),
    maxValue: 200,
    step: 1,
  },
  {
    nutrientId: MicrosFilters.CARBOHYDRATE_EXCHANGERS.toString(),
    maxValue: 25,
    step: 1,
  },
];

export default ProgramNutrientsTabContent;
