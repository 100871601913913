import { ReactElement } from "react";
import { useClientParams } from "@hooks";
import useFetchPatientImportantNutrients from "@hooks/queries/client/nutrient/useFetchPatientImportantNutrients";
import useFetchPatientShowNutrients from "@hooks/queries/client/nutrient/useFetchPatientShowNutrients";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import useUpdatePatientShowNutrientsMutation from "@hooks/queries/client/nutrient/useUpdatePatientShowNutrientsMutation";
import useUpdatePatientImportantNutrientsMutation from "@hooks/queries/client/nutrient/useUpdatePatientImportantNutrientsMutation";
import { PatientImportantNutrientResource } from "@client/resources/PatientImportantNutrientResource";
import { PatientProfileShowNutrientResource } from "@client/resources/PatientProfileShowNutrientResource";

type NutrientsFormProps = {
  showNutrients: PatientProfileShowNutrientResource;
  importantNutrients: PatientImportantNutrientResource[];
  children: ReactElement | ReactElement[];
  onSuccess: () => void;
};

export type FormPops = {
  kcal: boolean;
  macro: boolean;
  micro: boolean;
  important: boolean;
  targetMeals: boolean;
  importantNutrientsId: {
    [key: string]: boolean;
  };
};

const DefaultValues: FormPops = {
  kcal: true,
  macro: true,
  micro: false,
  important: false,
  targetMeals: false,
  importantNutrientsId: {},
};

const NutrientsFormDetail = ({
  children,
  onSuccess,
}: Pick<NutrientsFormProps, "children" | "onSuccess">) => {
  const id = useClientParams();
  const { data: showNutrients } = useFetchPatientShowNutrients(
    { patientId: id },
    { staleTime: Infinity },
  );
  const { data: importantNutrients } = useFetchPatientImportantNutrients(
    { patientId: id },
    { staleTime: Infinity },
  );

  if (!showNutrients || !importantNutrients) {
    return null;
  }

  return (
    <NutrientsForm
      showNutrients={showNutrients.data}
      importantNutrients={importantNutrients.data}
      onSuccess={onSuccess}
    >
      {children}
    </NutrientsForm>
  );
};

const NutrientsForm = ({
  children,
  onSuccess,
  importantNutrients,
  showNutrients,
}: NutrientsFormProps) => {
  const id = useClientParams();

  const showNutrientsMutation = useUpdatePatientShowNutrientsMutation();
  const importantNutrientsMutation =
    useUpdatePatientImportantNutrientsMutation();

  const form = useForm<FormPops>({
    defaultValues: {
      kcal: showNutrients.kcal,
      macro: showNutrients.macro,
      micro: showNutrients.micro,
      important: showNutrients.important,
      targetMeals: showNutrients.targetMeals,
      importantNutrientsId: Object.fromEntries(
        importantNutrients.map(item => [item.id, true]),
      ),
    },
  });

  const onSubmit: SubmitHandler<FormPops> = async data => {
    const importantNutrientsId: number[] = [];
    Object.keys(data.importantNutrientsId).forEach(key => {
      if (!data.importantNutrientsId[key]) {
        return;
      }

      importantNutrientsId.push(parseInt(key));
    });

    const promise1 = showNutrientsMutation.mutateAsync({
      params: {
        patientId: id,
      },
      payload: {
        kcal: data.kcal,
        macro: data.macro,
        micro: data.micro,
        targetMeals: data.macro && data.targetMeals,
        important: data.important && !!importantNutrientsId.length,
      },
    });

    const promise2 = importantNutrientsMutation.mutateAsync({
      params: {
        patientId: id,
      },
      payload: {
        nutrientsId: data.important ? importantNutrientsId : [],
      },
    });

    await Promise.all([promise1, promise2]).then(() => {
      onSuccess();
    });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>{children}</form>
    </FormProvider>
  );
};

export default NutrientsFormDetail;
