import * as yup from "yup";

import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { ActionsDto, actionsSchema, IdDto, idSchema } from "@client/common";

export async function fetchFood(
  id: string | number,
): Promise<ApiResponse<FetchFoodDetailsResponse>> {
  const data = await fetchData(`/dietitian/food/${id}`, APIMethods.GET);
  return fetchFoodResponseSchema.validate(data);
}

const foodGroupSchema = yup.object().shape({
  id: yup.number().required(),
  parentId: yup.number().required(),
  description: yup.string().nullable().default(null),
  descriptionPl: yup.string().nullable().default(null),
});

const nutrientSchema = yup.object().shape({
  id: yup.number().required(),

  // TODO: value should be nullable at some point
  value: yup
    .number()
    .transform((_, value: null | number) => (value === null ? 0 : value))
    .required(),
});

const measureSchema = yup.object().shape({
  id: yup.number().required(),
  description: yup.string().required(),
  descriptionEn: yup.string().required(),
  grams: yup.number().required(),
  sortOrder: yup.number().required(),
});

const newTagSchema = yup.object().shape({
  id: yup.number().required(),
  clinicId: yup.number().nullable().default(null),
  tagCategoryId: yup.number().required(),
  descriptionPl: yup.string().required(),
  descriptionEn: yup.string().required(),
});

const imageSchema = yup.object({
  id: yup.number().required(),
  url: yup.string().required(),
});

const fetchFoodResponseSchema = yup.object().shape({
  data: yup.object().shape({
    id: yup.number().required(),
    clinicId: yup.number().required(),
    description: yup.string().nullable().default(null),
    descriptionPl: yup.string().nullable().default(null),
    reviewed: yup.boolean().required(),
    plOnly: yup.boolean().required(),
    producer: yup.string().nullable().default(null),
    foodGroup: foodGroupSchema.required(),
    tags: yup.array().of(newTagSchema).required(),
    nutrients: yup.array().of(nutrientSchema).required(),
    measures: yup.array().of(measureSchema).required(),
    version: yup.number().required(),
    actions: actionsSchema.required(),
    favorite: yup.array().of(yup.number().required()).required(),
    shops: yup.array().of(idSchema.required()).required(),
    barcode: yup.string().nullable().default(null),
    image: imageSchema.nullable().default(null),
  }),
});

interface NewTagDto {
  id: number;
  clinicId: number | null;
  tagCategoryId: number;
  descriptionPl: string;
  descriptionEn: string;
}

export interface FetchFoodDetailsResponse {
  id: number;
  clinicId: number;
  description: string | null;
  descriptionPl: string | null;
  reviewed: boolean;
  plOnly: boolean;
  producer: string | null;
  foodGroup: FoodGroupDto;
  tags: NewTagDto[];
  nutrients: ProductNutrientDto[];
  measures: MeasureDto[];
  version: number;
  actions: ActionsDto;
  favorite: number[];
  shops: IdDto[];
  barcode: string | null;
  image: ImageDto | null;
}

interface ImageDto {
  id: number;
  url: string;
}
export interface ProductNutrientDto {
  id: number;

  // TODO: value should be nullable at some point
  value: number;
}

interface MeasureDto {
  id: number;
  description: string;
  descriptionEn: string;
  grams: number;
  sortOrder: number;
}

interface FoodGroupDto {
  id: number;
  parentId: number;
  description: string | null;
  descriptionPl: string | null;
}
