import { styled } from "@mui/material";

export const Title = styled("span")`
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
  width: 100%;
`;
