import React, { useMemo, useState } from "react";

import dayjs from "dayjs";
import { IconButton } from "@mui/material";

import { useAppTranslation } from "@hooks";
import { Edit } from "@assets/icons";
import { AddVisitModal } from "@views/dietician/Diet/components/AddVisitModal";

import { VisitDateTimeWrapper } from "./components/VisitDateTimeWrapper.styled";

export interface VisitTitleProps {
  visitId: number;
  startDate: string;
  visitOrder: number;
}

export const VisitTitle = ({
  visitId,
  startDate,
  visitOrder,
}: VisitTitleProps) => {
  const { t } = useAppTranslation();

  const [modalVisible, showModal] = useState(false);

  const parsedStartDate = useMemo(
    () =>
      dayjs(new Date(startDate.replace(/ GMT.*/, ""))).format(
        "DD.MM.YYYY - HH:mm",
      ),
    [startDate],
  );

  const handleModalOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    showModal(true);
  };

  return (
    <div className="flex items-center">
      <span className="text-xl">
        {`${t("patients.visit_no")} ${visitOrder + 1}`}
      </span>
      <VisitDateTimeWrapper>{parsedStartDate}</VisitDateTimeWrapper>
      <IconButton size="small" color="primary" onClick={handleModalOpen}>
        <Edit />
      </IconButton>

      {modalVisible && (
        <AddVisitModal
          onOpen={modalVisible}
          onClose={() => showModal(false)}
          id={visitId}
          edit
          onlyDates
        />
      )}
    </div>
  );
};
