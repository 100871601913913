import { FormProvider } from "react-hook-form";
import { Button, Dialog, DialogContent } from "@mui/material";

import { Close } from "@assets/icons/DesignSystem";
import { useAppParams, useAppTranslation } from "@hooks";

import {
  DialogActionsStyled,
  DialogTitleStyled,
  IconButtonStyled,
} from "./AddQuestionnaireModal.styled";
import { NewQuestionnaireContent } from "./NewQuestionnaireContent";
import {
  langDict,
  useQuestionnaireModalForm,
} from "./useQuestionnaireModalForm";
import {
  fetchSurveyFullQueryKey,
  useUpdateSurveyMutation,
} from "@hooks/queries/surveys";
import { useQueryClient } from "@tanstack/react-query";
import { ApiResponse } from "@typeDefinitions";
import { FullSurveyDto } from "@client/surveys";

interface QuestionnaireValue {
  name: string;
  langId: number;
}
interface EditQuestionnaireModalProps {
  open: boolean;
  onClose: () => void;
  questionnaireValue: QuestionnaireValue;
}

export const EditQuestionnaireModal = ({
  onClose,
  open,
  questionnaireValue,
}: EditQuestionnaireModalProps) => {
  const { t } = useAppTranslation();
  const { questionnaireId } = useAppParams();
  const form = useQuestionnaireModalForm(questionnaireValue);
  const { mutate } = useUpdateSurveyMutation();
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData<ApiResponse<FullSurveyDto>>([
    fetchSurveyFullQueryKey,
    questionnaireId,
  ]);

  const handleCloseModal = () => {
    onClose();
    form.reset();
  };

  const handleSubmitModal = () => {
    if (!data?.data.introduction) return;
    const { introduction, reviewed } = data.data;

    form.handleSubmit(
      d => {
        mutate(
          {
            id: questionnaireId,
            payload: {
              langId: langDict[d.langId],
              name: d.name,
              introduction,
              reviewed,
            },
          },
          { onSuccess: handleCloseModal },
        );
      },
      e => console.log(e),
    )();
  };

  return (
    <Dialog open={open} onClose={handleCloseModal}>
      <DialogTitleStyled>{t("questionnaires.settings")}</DialogTitleStyled>

      <IconButtonStyled onClick={handleCloseModal} size="small">
        <Close size="w-3 h-3" />
      </IconButtonStyled>

      <DialogContent>
        <FormProvider {...form}>
          <NewQuestionnaireContent />
        </FormProvider>
      </DialogContent>

      <DialogActionsStyled>
        <Button variant="outlined" onClick={handleCloseModal}>
          {t("common.cancel")}
        </Button>
        <Button variant="contained" onClick={handleSubmitModal}>
          {t("common.save")}
        </Button>
      </DialogActionsStyled>
    </Dialog>
  );
};
