import React from "react";

export const ProductMeasureSchema: React.FC<ProductMeasureSchemaProps> = ({
  measure,
  grams,
  remove,
  dragHandle,
}) => {
  return (
    <div className={"col-span-full flex gap-4"}>
      <div className={"w-3/4 flex items-center"}>
        {dragHandle && <div>{dragHandle}</div>}
        <div className={"flex-1"}>{measure}</div>
      </div>
      <div className={"w-1/8 flex-1"}>{grams}</div>
      {remove && <div className={"w-1/8"}>{remove}</div>}
    </div>
  );
};

interface ProductMeasureSchemaProps {
  measure: React.ReactElement;
  grams: React.ReactElement;
  remove?: React.ReactElement;
  dragHandle?: React.ReactElement;
}
