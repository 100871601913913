import { DEFAULT_PROGRAMS_MACROS_RANGE } from "@components/ProgramsFilters";

export type ProgramFiltersFormPops = {
  page: number;
  perPage: number;
  query?: string;
  typeDays?: number[];
  authorId?: number;
  nutrients?: { [name: string]: [number, number] };
  days?: number;
  tags?: number[];
  diet_allergen_tags?: number[];
  diet_elimination_tags?: number[];
  diet_type_tags?: number[];
  numberOfDietMeals?: number[];
  all?: string | null;
  latest?: string | null;
  favorite?: string | null;
  own?: string | null;
  reviewed?: number | null;
};

export const ProgramsFiltersFormDefaultValues: ProgramFiltersFormPops = {
  page: 0,
  perPage: 10,
  query: undefined,
  typeDays: undefined,
  authorId: undefined,
  nutrients: DEFAULT_PROGRAMS_MACROS_RANGE,
  days: undefined,
  tags: [],
  diet_allergen_tags: [],
  diet_elimination_tags: [],
  diet_type_tags: [],
  numberOfDietMeals: [],
  all: "1",
  latest: undefined,
  favorite: undefined,
  own: undefined,
  reviewed: undefined,
};

export const ProgramFiltersFormStringKeys: Array<keyof ProgramFiltersFormPops> =
  ["query"];

export const ProgramFiltersFormNumberKeys: Array<keyof ProgramFiltersFormPops> =
  [
    "page",
    "perPage",
    "authorId",
    "days",
    "reviewed",
    "latest",
    "favorite",
    "own",
  ];

export const ProgramFiltersFormArrayNumberKeys: Array<
  keyof ProgramFiltersFormPops
> = [
  "tags",
  "diet_type_tags",
  "diet_allergen_tags",
  "diet_elimination_tags",
  "numberOfDietMeals",
  "typeDays",
];

export const ProgramFiltersFormArrayNutrientsKeys: Array<
  keyof ProgramFiltersFormPops
> = ["nutrients"];

export const ProgramFiltersFormKeys: Array<keyof ProgramFiltersFormPops> = [
  ...ProgramFiltersFormStringKeys,
  ...ProgramFiltersFormNumberKeys,
  ...ProgramFiltersFormArrayNumberKeys,
  ...ProgramFiltersFormArrayNutrientsKeys,
];
