import { FormControlLabel, IconButton, styled } from "@mui/material";

export const AnswerTitle = styled("span")`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.medium[600]};
`;

export const FormControlLabelStyled = styled(FormControlLabel)`
  height: 1.5rem;
  gap: 0.5rem;
  margin: unset;

  & .MuiCheckbox-root {
    margin: unset;
  }

  & .MuiTypography-root {
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: ${({ theme }) => theme.colors.neutral.dark[800]};
  }
`;

export const IconButtonStyled = styled(IconButton)`
  width: 2.125rem;
  height: 2.125rem;
  padding: unset;
`;

export const DefinedText = styled("span")`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const InfoAlert = styled("span")`
  font-size: 0.857rem;
  line-height: 1.429rem;
  color: ${({ theme }) => theme.palette.info.main};
  width: 100%;
`;
