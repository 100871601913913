import { ReactNode, useRef } from "react";

import { CircularProgress, IconButton, Menu } from "@mui/material";

import { ThreeGreyPoints } from "@assets/icons";

interface MenuMuiWrapperProps {
  children: ReactNode;
  isLoading: boolean;
  onClose: () => void;
  onOpen: () => void;
  open: boolean;
}
export const MenuMuiWrapper = ({
  children,
  isLoading,
  onClose,
  onOpen,
  open,
}: MenuMuiWrapperProps) => {
  const anchorEl = useRef<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    anchorEl.current = event.currentTarget;
    onOpen();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress size="2rem" />
      ) : (
        <IconButton onClick={handleClick}>
          <ThreeGreyPoints size="h-4 w-4" />
        </IconButton>
      )}
      <Menu anchorEl={anchorEl.current} open={open} onClose={handleClose}>
        {children}
      </Menu>
    </>
  );
};
