import { round } from "lodash";
import { Typography } from "@mui/material";

import { BulletWarning } from "@assets/icons/StatusBullets";

import { ChartLabel, ChartLabelBold } from "./MonitoringDayDetails.styled";
import { PERCENTAGE_BAR_COLORS } from "../monitoringUtils";

interface VerticalChartLabelProps {
  value: number | null;
  targetValue: number | null;
  unit: string;
  label: string;
}

export const VerticalChartLabel = ({
  label,
  targetValue,
  unit,
  value,
}: VerticalChartLabelProps) => {
  const showWarning =
    !!targetValue &&
    !!value &&
    (value > 1.05 * targetValue || value < 0.95 * targetValue);

  return (
    <>
      <Typography variant="caption" color="text.secondary">
        {label}
      </Typography>
      <div className="flex items-center">
        {showWarning && (
          <BulletWarning
            fill={PERCENTAGE_BAR_COLORS.red}
            size="w-[10px] h-[10px] mr-[2px]"
          />
        )}
        <ChartLabelBold variant="subtitle2">
          {value ? round(value) : "-"}&nbsp;
        </ChartLabelBold>
        <ChartLabel variant="body2">
          / {targetValue ? round(targetValue) : "-"} {unit}
        </ChartLabel>
      </div>
    </>
  );
};
