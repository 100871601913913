import { object, string } from "yup";

export interface PaginationLinks {
  first?: string | null;
  last?: string | null;
  prev?: string | null;
  next?: string | null;
}

export const PaginationLinksSchema = object({
  first: string().nullable(),
  last: string().nullable(),
  prev: string().nullable(),
  next: string().nullable(),
});
