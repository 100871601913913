import styled from "@emotion/styled";
import { motion } from "framer-motion";

export const TagsContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  border-radius: 0.125rem;
`;
