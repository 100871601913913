import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Jar = (props: SvgIconProps) => {
  return (
    <SvgIcon width="10" height="14" viewBox="0 0 10 14">
      <path
        d="M1.75 1.3125C1.75 0.863086 2.11309 0.5 2.5625 0.5H7.4375C7.88691 0.5 8.25 0.863086 8.25 1.3125C8.25 1.76191 7.88691 2.125 7.4375 2.125H2.5625C2.11309 2.125 1.75 1.76191 1.75 1.3125ZM0.9375 4.5625C0.9375 3.66621 1.66621 2.9375 2.5625 2.9375H7.4375C8.33379 2.9375 9.0625 3.66621 9.0625 4.5625V11.875C9.0625 12.7713 8.33379 13.5 7.4375 13.5H2.5625C1.66621 13.5 0.9375 12.7713 0.9375 11.875V4.5625ZM3.375 6.1875C2.92559 6.1875 2.5625 6.55059 2.5625 7V9.4375C2.5625 9.88691 2.92559 10.25 3.375 10.25H6.625C7.07441 10.25 7.4375 9.88691 7.4375 9.4375V7C7.4375 6.55059 7.07441 6.1875 6.625 6.1875H3.375Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
