import { styled } from "@mui/material";

export const TagIconWrapper = styled("div")`
  display: flex;
  gap: 6px;
  align-items: center;
`;

export const Label = styled("span")`
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
`;
