import { useState } from "react";

import { GroupedNutrients } from "@components/GroupedNutrients";
import { useAppTranslation } from "@hooks";
import { LangDto, MediaExtendedDto, NutrientDto } from "@client";
import { RecipeMethodDescription } from "@components/RecipeMethodDescription";
import { RecipeEditDetails } from "@components/RecipeEditDetails";

import { RecipeTableForm } from "./components/RecipeTableForm";
import {
  StyledCard,
  StyledChartCard,
  TableCard,
} from "./RecipeEditForm.styled";
import { ChartCard } from "./components/ChartCard";
import { styled, TextField, useMediaQuery, useTheme } from "@mui/material";
import { OuterLabel } from "@components/OuterLabel";
import { FormTextFieldCharCounter } from "@components/FormTextField";
import { ThemeProviderWrapperNew } from "themeNew";

interface BaseRecipe {
  nutrients: NutrientDto[];
  media: MediaExtendedDto | null;
  servings: number;
}

interface RecipeEditFormProps {
  recipe: BaseRecipe;
}

export const RecipeEditForm = ({ recipe }: RecipeEditFormProps) => {
  const { t } = useAppTranslation();
  const [lang, setLang] = useState<LangDto>(LangDto.PL);
  const { breakpoints } = useTheme();
  const isDesktopSize = useMediaQuery(`${breakpoints.up("desktop")}`);

  return (
    <div className="grid gap-4">
      <StyledCard>
        <RecipeEditDetails
          mediaUrl={recipe.media?.url}
          lang={lang}
          setLang={setLang}
        />
      </StyledCard>
      {isDesktopSize ? (
        <div className="flex gap-4">
          <TableCard>
            <RecipeTableForm recipeNutrients={recipe.nutrients} />
          </TableCard>
          <StyledChartCard>
            <ChartCard
              nutrients={recipe.nutrients}
              totalServings={recipe.servings}
              pie
            />
          </StyledChartCard>
        </div>
      ) : (
        <TableCard>
          <RecipeTableForm recipeNutrients={recipe.nutrients} />
        </TableCard>
      )}
      {!isDesktopSize && (
        <StyledCard>
          <ChartCard
            nutrients={recipe.nutrients}
            totalServings={recipe.servings}
          />
        </StyledCard>
      )}
      <StyledCard>
        <RecipeMethodDescription isPl={lang === LangDto.PL} />

        <ThemeProviderWrapperNew>
          <OuterLabelStyled label={t("recipe.comment")}>
            <FormTextFieldCharCounter
              key={lang === LangDto.PL ? "comment" : "commentEn"}
              name={lang === LangDto.PL ? "comment" : "commentEn"}
              maxLength={600}
              multiline
              rows={5}
              placeholder={t("recipe.comment")}
            />
          </OuterLabelStyled>
        </ThemeProviderWrapperNew>
      </StyledCard>
      <StyledCard>
        <GroupedNutrients
          title={t("recipe.nutrients_title")}
          nutrients={recipe.nutrients}
        />
      </StyledCard>
    </div>
  );
};

const OuterLabelStyled = styled(OuterLabel)`
  width: 50%;
`;
