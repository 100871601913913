import { memo } from "react";

import { MACROS_ARRAY, macrosSortKey } from "@utils/macros";
import { Nutrient } from "@typeDefinitions";
import { MacroDivider, MacrosListRow } from "./MacrosList.styled";
import { MacroEntry } from "./MacroEntry";

interface MacrosListProps {
  nutrients: Nutrient[];
  compact?: boolean;
  show?: number[];
  regularFont?: boolean;
}

export const MacrosList = memo(
  ({
    regularFont,
    nutrients,
    compact = false,
    show = MACROS_ARRAY,
  }: MacrosListProps) => {
    const macros = nutrients
      .filter(m => show.includes(m.id) && MACROS_ARRAY.includes(m.id))
      .sort((a, b) => macrosSortKey(a.id, b.id));

    const micros = nutrients
      .filter(m => show.includes(m.id) && !MACROS_ARRAY.includes(m.id))
      .sort((a, b) => macrosSortKey(a.id, b.id));

    return (
      <MacrosListRow regularFont={regularFont}>
        {macros.length === 0 && <span>-</span>}
        {macros.map(macro => (
          <MacroEntry key={macro.id} nutrient={macro} compact={compact} />
        ))}

        {!!micros.length && <MacroDivider />}

        {micros.map(micro => (
          <MacroEntry key={micro.id} nutrient={micro} compact={compact} />
        ))}
      </MacrosListRow>
    );
  },
);
