import { Button, styled } from "@mui/material";
import { Modal } from "@components/Modal";

interface SelectDietWrapperProps {
  warning?: boolean;
}

export const SelectDietWrapper = styled("div")<SelectDietWrapperProps>`
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  ${({ warning }) =>
    warning &&
    `
  border: 2px solid red;
  border-radius: 6px;
  height: 14.375rem;
  margin: auto;
  width: 38.125rem;
  `}
`;

export const DietSelectButton = styled(Button)`
  flex-direction: column;
  height: 10rem;
  width: 10rem;
`;

export const StyledDayDietModal = styled(Modal)`
  box-shadow: 0px 0px 50px 45px #0000000f;
`;
