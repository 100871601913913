import { styled } from "@mui/material";

export const ClipboardInfoWrapperStyled = styled("div")`
  display: grid;
  position: fixed;
  gap: 1rem;
  z-index: 20;
  bottom: 1rem;
  left: 6rem;
`;
