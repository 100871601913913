import { Button, styled } from "@mui/material";

export const DietitiansTabStyled = styled("div")`
  display: flex;
  flex-direction: column;
`;
export const AddDietitianButton = styled(Button)`
  font-size: 1rem;
  font-weight: 600;
  margin-left: auto;
  margin-bottom: 1rem;
`;
