import { useQuery } from "@tanstack/react-query";

import { FetchTagCategoriesCategory, fetchTagCategories } from "@client";
import { ApiResponse } from "@typeDefinitions";
import { buildDefaultQueryOptions } from "./common";
import { QueryOptionsTyped } from "./types";

export const useFetchTagCategoriesQueryKey = "useFetchTagCategoriesQueryKey";

export function useFetchTagCategoriesQuery(
  options?: QueryOptionsTyped<ApiResponse<FetchTagCategoriesCategory[]>>,
) {
  const { data: tagCategoriesApiResponse, ...rest } = useQuery(
    [useFetchTagCategoriesQueryKey],
    fetchTagCategories,
    buildDefaultQueryOptions(options),
  );

  return {
    tagCategories: tagCategoriesApiResponse?.data,
    meta: tagCategoriesApiResponse?.meta,
    ...rest,
  };
}
