import React, { CSSProperties, forwardRef, Fragment } from "react";

import classNames from "classnames";

/**
 * universal textarea component
 */
export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => {
    const {
      disabled,
      className,
      fullWidth,
      infoTag,
      error,
      width,
      height,
      minHeight,
      clean,
      ...rest
    } = props;

    return (
      <Fragment>
        <div
          className={classNames(
            {
              "h-max text-sm flex flex-col items-center": true,
              "border border-gray-500 border-solid rounded-md p-3": !clean,
              "focus-within:border-primary hover:border-secondary focus-within:shadow":
                !disabled && !clean,
              "bg-gray-200": disabled,
              "w-full": fullWidth,
            },
            className,
          )}
        >
          {infoTag && (
            <div className="bg-gray-300 rounded-md w-full p-4 font-sm mb-2">
              {infoTag}
            </div>
          )}
          <textarea
            ref={ref}
            disabled={disabled}
            className={classNames(
              {
                "outline-none placeholder-gray font-roman w-full rounded-b-md":
                  true,
                "text-gray-700 cursor-default": disabled,
              },
              `w-${width} h-${height}`,
              minHeight,
            )}
            {...rest}
          />
        </div>
        {!!error && (
          <p className="text-xs text-light text-red pt-1 pl-2">{error}</p>
        )}
      </Fragment>
    );
  },
);

export interface TextareaProps {
  /** is button disabled */
  disabled?: boolean;
  /** vame attribute */
  name?: string;
  /** value of input */
  value?: string | number;
  /** error message */
  error?: string;
  /** placehold value for input */
  placeholder?: string;
  /** additional classNames */
  className?: string;
  /** callback fired on focusing input */
  onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  /** callback fired on bluring input */
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  /** callback fired when input value changes */
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  /** if true, button will be stretched to fit parent width */
  fullWidth?: boolean;
  /** additional information to show in a dedicated block within textarea */
  infoTag?: string;
  /** additional styles applied to textarea */
  style?: CSSProperties;
  /** Removes border & shadows from textarea container */
  clean?: boolean;
  /** textarea width  */
  width?: string | number;
  /** textarea height */
  height?: string | number;
  /** textarea min height */
  minHeight?: string | number;
}

Textarea.defaultProps = {
  disabled: false,
  fullWidth: false,
  clean: false,
};
