import { type FC, useCallback, useMemo } from "react";
import {
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isSameMonth,
  isToday,
  getDay,
  addDays,
  subDays,
  isSameDay,
  startOfWeek,
  addHours,
} from "date-fns";
import { EventWithType } from "@typeDefinitions";
import { Plus } from "@assets/icons";
import { BigCalendarDropdown } from "./BigCalendarDropdown";
import { CalendarTimeUnit } from "../../MiniCalendar/_components/CalendarTabs";
import {
  AddNewEventButton,
  EventItemMonthViewContent,
  EventItemMonthViewTextWrapper,
  GridBody,
  GridContainer,
  GridHeaderContainer,
  GridHeaderItem,
  GridHeaderItemText,
  GridItem,
  GridItemText,
} from "./BigCalendarMonthView.styled";
import { useCalendarActions } from "../../_hooks/useCalendarActions";
import { ScheduleVisitOverviewModal } from "../../../ScheduleVisit/_components/ScheduleVisitOverviewModal";
import { useAppTranslation } from "@hooks";
import { useCalendarColors } from "@views/dietician/Calendar";
import { getEventIcon } from "../../_utils/getEventIcon";
import { formatDayName } from "../../MiniCalendar/_components/MiniCalendarDayView";
import { ScheduleGoogleEventOverviewModal } from "../../../ScheduleVisit/_components/ScheduleGoogleEventOverviewModal";

interface BigCalendarMonthViewProps {
  events: EventWithType[];
  currentDate: Date;
  setCurrentDate: (newDate: Date) => void;
  setCurrentTimeUnit: (newTimeUnit: CalendarTimeUnit) => void;
  locale: Locale;
  onVisitModalOpen: () => void;
  handleSetStartDate: (startDate: Date) => void;
}

const getAdjustedDay = (day: number) => (day === 0 ? 6 : day - 1);

export const BigCalendarMonthView: FC<BigCalendarMonthViewProps> = ({
  events,
  currentDate,
  setCurrentDate,
  setCurrentTimeUnit,
  onVisitModalOpen,
  handleSetStartDate,
  locale,
}) => {
  const { t } = useAppTranslation();

  const { getNewDietitianColor, getEventStyles } = useCalendarColors();

  const weekStart = startOfWeek(currentDate, { weekStartsOn: 1 });

  const monthStart = startOfMonth(currentDate);
  const monthEnd = endOfMonth(currentDate);

  const daysOfWeek = useMemo(() => {
    return Array.from({ length: 7 }).map((_, index) => {
      const day = addDays(weekStart, index);

      return formatDayName(day, locale);
    });
  }, [weekStart, locale]);

  const {
    handleCloseModal,
    handleEventClick,
    selectedEventId,
    anchorEl,
    selectedGoogleId,
  } = useCalendarActions();

  const calendarDays = useMemo(() => {
    const startDay = getAdjustedDay(getDay(monthStart));
    const daysInMonth = eachDayOfInterval({ start: monthStart, end: monthEnd });
    const prefixDays = startDay;
    const totalDays = prefixDays + daysInMonth.length;

    const daysCountInWeek = 7;

    const suffixDays =
      totalDays % daysCountInWeek === 0
        ? 0
        : daysCountInWeek - (totalDays % daysCountInWeek);

    const prevMonthDays = Array.from({ length: prefixDays }).map((_, index) =>
      subDays(monthStart, prefixDays - index),
    );

    const nextMonthDays = Array.from({ length: suffixDays }).map((_, index) =>
      addDays(monthEnd, index + 1),
    );

    return [...prevMonthDays, ...daysInMonth, ...nextMonthDays];
  }, [monthStart, monthEnd]);

  const handleNavigateToCurrentDate = (day: Date) => {
    setCurrentDate(day);
    setCurrentTimeUnit(CalendarTimeUnit.TODAY);
  };

  const getEventsForDay = useCallback(
    (day: Date) => {
      return events.filter(event => isSameDay(new Date(event.start), day));
    },
    [events],
  );

  const handleCreateVisit = (startDate: Date) => {
    const initialDateAndHour = addHours(startDate, 8);

    onVisitModalOpen();
    handleSetStartDate(initialDateAndHour);
  };

  return (
    <GridContainer>
      <GridHeaderContainer>
        {daysOfWeek.map((dayLabel, index) => (
          <GridHeaderItem key={index}>
            <GridHeaderItemText>{dayLabel}</GridHeaderItemText>
          </GridHeaderItem>
        ))}
      </GridHeaderContainer>
      <GridBody>
        {calendarDays.map((day, index) => {
          const eventsForDay = getEventsForDay(day);
          return (
            <GridItem
              key={index}
              isCurrentMonth={isSameMonth(day, currentDate)}
              className="group relative"
            >
              {eventsForDay.length === 0 && (
                <AddNewEventButton
                  className="hidden group-hover:flex"
                  onClick={() => handleCreateVisit(day)}
                >
                  <Plus width="8px" height="8px" />
                  <span>{t("calendar.add_visit")}</span>
                </AddNewEventButton>
              )}
              <GridItemText
                isToday={isToday(day)}
                onClick={() => handleNavigateToCurrentDate(day)}
              >
                {format(day, "d")}
              </GridItemText>
              <EventItemMonthViewTextWrapper>
                {eventsForDay.slice(0, 3).map((event, idx) => {
                  const theme = getNewDietitianColor(event.dietitianId);
                  const { backgroundColor, border } = getEventStyles(
                    theme?.backgroundColor,
                    !!event.googleId,
                  );

                  return (
                    <EventItemMonthViewContent
                      key={idx}
                      onClick={e =>
                        handleEventClick(e, event.id, event.googleId)
                      }
                      className="custom-scrollbar"
                      style={{
                        backgroundColor,
                        border,
                      }}
                    >
                      {getEventIcon(event.type, {
                        color: theme?.color,
                      })}
                      <span className="text-gray-450">
                        {format(event.start, "HH:mm")}
                      </span>
                      <span className="block max-w-[130px] truncate">
                        {event.title}
                      </span>
                    </EventItemMonthViewContent>
                  );
                })}
                <BigCalendarDropdown
                  day={day}
                  todayEvents={eventsForDay}
                  handleEventClick={handleEventClick}
                />
              </EventItemMonthViewTextWrapper>
            </GridItem>
          );
        })}
        {selectedEventId && (
          <ScheduleVisitOverviewModal
            anchorEl={anchorEl}
            onClose={handleCloseModal}
            eventId={selectedEventId}
          />
        )}
        {selectedGoogleId && (
          <ScheduleGoogleEventOverviewModal
            anchorEl={anchorEl}
            onClose={handleCloseModal}
            googleId={selectedGoogleId}
          />
        )}
      </GridBody>
    </GridContainer>
  );
};
