import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const deleteProgramDietMealProduct = (
  programId: string,
  dietId: string,
  mealId: string,
  productId: string,
  version: string,
) => {
  return fetchData(
    `/dietitian/program/${programId}/diet-creator/${dietId}/meal-items-product/${productId}`,
    APIMethods.DELETE,
    undefined,
    undefined,
    { "Alloweat-Creator-Version": version },
  );
};
