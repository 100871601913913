import { SyntheticEvent, useMemo } from "react";
import { FieldError } from "react-hook-form";

import { Autocomplete, TextField } from "@mui/material";

import { GroupOptionIf, useNorms } from "@hooks/useNorms";
import {
  AccordionDetailsStyled,
  AccordionStyled,
  AccordionSummaryStyled,
  PopperStyled,
} from "./NormAutocomplete.styled";

interface NormAutocompleteProps {
  value: string | null;
  onChange: (id: string) => void;
  error?: FieldError;
}
export const NormAutocomplete = ({
  value,
  onChange,
  error,
}: NormAutocompleteProps) => {
  const { options } = useNorms();
  const selected = useMemo(
    () => options.find(o => o.id === value),
    [options, value],
  );

  const handleChange = (_: SyntheticEvent, value: GroupOptionIf | null) => {
    if (!value) return;
    onChange && onChange(value.id);
  };

  return (
    <Autocomplete
      size="small"
      disablePortal
      disableClearable
      fullWidth
      options={options}
      groupBy={option => option.group}
      renderInput={params => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            value: selected ? `${selected?.group} ➔ ${selected?.label}` : "",
          }}
          error={!!error}
          helperText={error?.message}
        />
      )}
      onChange={handleChange}
      renderGroup={params => (
        <li {...params} key={params.key}>
          <AccordionStyled defaultExpanded={params.group === selected?.group}>
            <AccordionSummaryStyled>{params.group}</AccordionSummaryStyled>
            <AccordionDetailsStyled>
              <ul>{params.children}</ul>
            </AccordionDetailsStyled>
          </AccordionStyled>
        </li>
      )}
      PopperComponent={PopperStyled}
    />
  );
};
