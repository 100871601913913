import { useAppTranslation } from "@hooks";
import { Container, Text, Title } from "./EmptyState.styled";
import img from "@assets/img/autogenerate/autogenerateEmptyState.png";

export const EmptyState = () => {
  const { t } = useAppTranslation();

  return (
    <Container>
      <img src={img} />

      <Title>{t("diet.autogenerate_empty.title")}</Title>

      <Text>{t("diet.autogenerate_empty.text")}</Text>
    </Container>
  );
};
