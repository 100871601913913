import { useState } from "react";
import { FormProvider } from "react-hook-form";

import { Typography } from "@mui/material";

import { DuplicateFiles } from "@assets/icons";
import { PlusInCircle } from "@assets/icons/PlusInCircle";
import { useAppTranslation } from "@hooks";
import {
  usePostSurveyCloneMutation,
  usePostSurveyMutation,
} from "@hooks/queries/surveys";

import { ModalWrapper } from "../components/ModalWrapper/ModalWrapper";
import { BigButton } from "./AddQuestionnaireModal.styled";
import { NewQuestionnaireContent } from "./NewQuestionnaireContent";
import { TemplateQuestionnaireContent } from "./TemplateQuestionnaireContent";
import {
  langDict,
  useQuestionnaireModalForm,
} from "./useQuestionnaireModalForm";
import { useNavigate } from "react-router-dom";

interface AddQuestionnaireModalProps {
  open: boolean;
  onClose: () => void;
}
export const AddQuestionnaireModal = ({
  open,
  onClose,
}: AddQuestionnaireModalProps) => {
  const { t } = useAppTranslation();
  enum Mode {
    TEMPLATE,
    NEW,
  }
  const [mode, setMode] = useState<Mode>(Mode.TEMPLATE);
  const [selectedTemplate, setSelectedTemplate] = useState<number | null>(null);
  const navigate = useNavigate();

  const form = useQuestionnaireModalForm();

  const handleCloseModal = () => {
    setSelectedTemplate(null);
    form.reset();
    onClose();
  };

  const { mutate: postNew } = usePostSurveyMutation({
    onSuccess: handleCloseModal,
  });
  const { mutate: postTemplate } = usePostSurveyCloneMutation({
    onSuccess: handleCloseModal,
  });

  const getButtonVariant = (option: Mode) =>
    option === mode ? "outlined" : "text";
  const toggleButton = (option: Mode) => setMode(option);

  const handleSubmitModal = () => {
    if (mode === Mode.TEMPLATE && selectedTemplate) {
      postTemplate(`${selectedTemplate}`, {
        onSuccess: ({ data }) => data.id && navigate(`${data.id}/edit`),
      });
      return;
    }

    form.handleSubmit(
      d =>
        postNew(
          {
            langId: langDict[d.langId],
            name: d.name,
            introduction: {
              on: true,
              description: "",
              title: t("questionnaires.introduction"),
            },
          },
          { onSuccess: ({ data }) => navigate(`${data.id}/edit`) },
        ),
      err => console.log(err),
    )();
  };

  return (
    <ModalWrapper
      onClose={handleCloseModal}
      onSubmit={handleSubmitModal}
      open={open}
      submitText={t("questionnaires.add_new")}
      title={t("questionnaires.add")}
    >
      <div className="grid gap-6">
        <div className="grid gap-4">
          <Typography lineHeight="1.5rem">
            {t("questionnaires.select_mode")}
          </Typography>

          <div className="flex gap-4">
            <BigButton
              onClick={() => toggleButton(Mode.TEMPLATE)}
              variant={getButtonVariant(Mode.TEMPLATE)}
              startIcon={<DuplicateFiles />}
            >
              {t("questionnaires.use_template")}
            </BigButton>
            <BigButton
              onClick={() => toggleButton(Mode.NEW)}
              variant={getButtonVariant(Mode.NEW)}
              startIcon={<PlusInCircle />}
            >
              {t("questionnaires.create_new")}
            </BigButton>
          </div>
        </div>

        {mode === Mode.NEW ? (
          <FormProvider {...form}>
            <NewQuestionnaireContent />
          </FormProvider>
        ) : (
          <TemplateQuestionnaireContent
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
          />
        )}
      </div>
    </ModalWrapper>
  );
};
