import { QueryOptionsTyped } from "@hooks/queries";
import { useQuery } from "@tanstack/react-query";
import {
  fetchDietProgramPreview,
  FetchDietProgramPreviewParams,
  FetchDietProgramPreviewResponse,
} from "@client/program/preview/fetchDietProgramPreview";
import { programPreviewKeys } from "@hooks/queries/program/preview/programPreviewKeys";

export const getKey = (params: FetchDietProgramPreviewParams) =>
  programPreviewKeys.programDiet({
    programId: params.programId,
    dietId: params.dietId,
  });

const useFetchDietProgramPreview = (
  params: FetchDietProgramPreviewParams,
  options?: QueryOptionsTyped<FetchDietProgramPreviewResponse>,
) => {
  return useQuery({
    queryKey: getKey(params),
    queryFn: () => fetchDietProgramPreview(params),
    ...options,
  });
};

export default useFetchDietProgramPreview;
