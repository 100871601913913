import { fetchOauthGoogleDeauthorize } from "@client/oauth";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "../types";
import { fetchGoogleCalendarListQueryKey } from "./useFetchGoogleCalendarListQuery";

export const useFetchOauthGoogleDeauthorizeMutation = (
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, void>(fetchOauthGoogleDeauthorize, {
    ...options,
    onSuccess: (data, variables) => {
      options?.onSuccess?.(data, variables);
      queryClient.invalidateQueries([fetchGoogleCalendarListQueryKey]);
    },
  });
};
