import React, { ReactElement } from "react";
import { Popover } from "@mui/material";
import { ColorPicker } from "@components/ColorPicker";
import { Controller } from "react-hook-form";
import { FormColorPickerProps } from "@components/FormColorPicker/FormColorPicker";
import {
  ColorPickerDropdownButton,
  ColorPickerDropdownButtonCircle,
} from "@components/FormColorPicker/FormColorPicker.styled";
import { FieldValues } from "react-hook-form/dist/types";

export const FormColorPickerDropdown = <T extends FieldValues>({
  outlined,
  ...rest
}: FormColorPickerProps<T>): ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Controller
      {...rest}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <ColorPickerDropdownButton onClick={handleClick}>
            <ColorPickerDropdownButtonCircle
              style={{ backgroundColor: value }}
            />
          </ColorPickerDropdownButton>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <ColorPicker
              className="p-[8px]"
              onChange={onChange}
              value={value}
            />
          </Popover>
        </>
      )}
    />
  );
};
