import { styled } from "@mui/material";
import { Link } from "react-router-dom";

interface StyledNameProps {
  isPatient?: boolean;
}

export const StyledName = styled(Link)<StyledNameProps>`
  font-size: 1.125rem;
  line-height: 150%;
  letter-spacing: -0.022em;
  color: #131313;
  cursor: default;

  ${({ isPatient, theme }) =>
    isPatient &&
    `
  
  :hover {
  color: ${theme.palette.primary.main};
  cursor: pointer;
}
  `}
`;

export const StyledContact = styled("span")`
  font-size: 0.75rem;
  line-height: 150%;
  font-weight: 400;
  letter-spacing: -0.022em;
  color: #00000099;
`;

export const PersonCellStyled = styled("div")`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 0.5rem 0;
`;
