export enum BalanceActivityDaily {
  VERY_LOW = 1,
  LOW = 2,
  MEDIUM = 3,
  HIGH = 4,
}

export enum BalanceActivityTraining {
  VERY_LOW = 1,
  LOW = 2,
  MEDIUM = 3,
  HIGH = 4,
  VERY_HIGH = 5,
}

export enum BalanceFormula {
  BW_MIFFLIN_1990 = 1,
  BW_HARRIS_BENEDICT_1918 = 2,
  BW_FAO_WHO_UNU_1985 = 3,
  BW_OWEN_1987 = 4,
  BW_SCHOFIELD_1985 = 5,
  LBM_CUNNINGHAM_1991 = 6,
  LBM_MIFFLIN_1990 = 7,
  LBM_CUNNINGHAM_1980 = 8,
  LBM_OWEN_1987 = 9,
  LBM_WANG_2000 = 10,
  // AGE_ROBERTSON_REID_1952 = 11,
}

export enum BalanceType {
  PAL = 1,
  MANUAL = 2,
}

export enum BalanceValueMethod {
  AUTO = 1,
  MANUAL = 2,
}

export enum FrequencyDay {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 7,
}

export enum formulaGroup {
  BW = "bw",
  LBM = "lbm",
  AGE = "age",
}

export const FORMULAS: FormulaOption[] = [
  { id: 1, label: "Mifflin-St Jeor (1990)", group: formulaGroup.BW },
  { id: 2, label: "Harris-Benedict (1918/1919)", group: formulaGroup.BW },
  { id: 3, label: "FAO/WHO/UNU (1985)", group: formulaGroup.BW },
  { id: 4, label: "Owen (1987)", group: formulaGroup.BW },
  { id: 5, label: "Schofield (1985)", group: formulaGroup.BW },
  { id: 6, label: "Cunningham (1991)", group: formulaGroup.LBM },
  { id: 7, label: "Mifflin (1990)", group: formulaGroup.LBM },
  { id: 8, label: "Cunningham (1980)", group: formulaGroup.LBM },
  { id: 9, label: "Owen (1987)", group: formulaGroup.LBM },
  { id: 10, label: "Wang (2000)", group: formulaGroup.LBM },
  // { id: 11, label: "Robertson-Reid (1952)", group: formulaGroup.AGE },
];

export interface FormulaOption {
  id: number;
  label: string;
  group: formulaGroup;
}

export const DEFAULT_BALANCE_FORMULA = 2;
export const DEFAULT_BALANCE_ACTIVITY = 2;
export const DEFAULT_BALANCE_PAL = 1.6;
export const DEFAULT_BALANCE_MACROS = {
  kcal: 2500,
  protein: 94,
  fat: 97,
  carb: 313,
};
