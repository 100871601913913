import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";

export async function requestPasswordRetrieval(
  payload: RequestPasswordRetrievalPayload,
): Promise<ApiResponse<void>> {
  return await fetchData("/dietitian/reset-password", APIMethods.POST, payload);
}

export interface RequestPasswordRetrievalPayload {
  email: string;
}
