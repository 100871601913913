import { useFormContext, useWatch } from "react-hook-form";
import { RecipeEditInput } from "@components/RecipeForm/useRecipeEditForm";
import { useEffect, useState } from "react";
import { useDebounce } from "react-use";
import { useUpdateCreatorMealRecipeMutation } from "@hooks/queries/meals/useUpdateCreatorMealRecipeMutation";
import { useAppParams } from "@hooks";
import { UpdateCreatorMealRecipeRequest } from "@client/meals/updateCreatorMealRecipe";
import { MediaExtendedDto } from "@client";
import { useMealVersion } from "../../hooks";

interface MealRecipeFormWatchProps {
  wrapperId: string;
  selectedServings: number;
  media: MediaExtendedDto | null;
}

export const MealRecipeFormWatch = ({
  wrapperId,
  selectedServings,
  media,
}: MealRecipeFormWatchProps) => {
  const { mealId } = useAppParams();
  const { version } = useMealVersion();

  const values = useWatch<RecipeEditInput>();
  const {
    handleSubmit,
    formState: { isDirty },
  } = useFormContext<RecipeEditInput>();

  const { mutate, isLoading } = useUpdateCreatorMealRecipeMutation(
    mealId,
    wrapperId,
  );
  const [queued, setQueued] = useState(false);

  const createRequest = (
    data: RecipeEditInput,
  ): UpdateCreatorMealRecipeRequest => {
    return {
      servings: selectedServings,
      recipe: {
        id: Number(wrapperId),
        media: media,
        name: data.namePl,
        nameEn: data.nameEn,
        description: data.descriptionPl,
        descriptionEn: data.descriptionEn,
        version,
        servings: Number(data.numOfPortions),
        isComplex: Number(data.numOfPortions) > 1,
        tags: data.tags.map(t => Number(t)),
        reviewed: data.reviewed,
        foodRecipe: data.ingredients.map(i => {
          return {
            id: i.wrapperId ? Number(i.wrapperId) : null,
            foodId: Number(i.productId),
            grams: Number(i.grams),
            foodMeasureId: Number(i.measureId),
          };
        }),
        comment: data.comment,
        commentEn: data.commentEn,
      },
    };
  };

  const update = () => {
    setQueued(false);
    handleSubmit(
      data => mutate(createRequest(data)),
      err => console.log(err),
    )();
  };

  const [isDirtyLatch, setIsDirtyLatch] = useState(isDirty);

  useDebounce(
    () => {
      if (isLoading) {
        setQueued(true);
      }

      if ((isDirty || isDirtyLatch) && values && !isLoading) {
        setIsDirtyLatch(true);
        update();
      }
    },
    1000,
    [JSON.stringify(values)],
  );

  useEffect(() => {
    if (queued && !isLoading) {
      update();
    }
  }, [queued, isLoading]);

  return <></>;
};
