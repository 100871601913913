import { TableConfigDto, TableRowAswerQuestionDto } from "@client/surveys";
import {
  ColumnHeaderStyled,
  RowHeaderStyled,
  StyledTextField,
  TableAnswerPreviewWrapper,
} from "./TableAnswerPreview.styled";

interface TableAnswerPreviewProps {
  config: TableConfigDto;
  cells?: TableRowAswerQuestionDto[];
}
export const TableAnswerPreview = ({
  config: { columns, rows },
  cells = [],
}: TableAnswerPreviewProps) => {
  const getCellValue = (rowUuid: string, colUuid: string): string => {
    return (
      cells.find(
        cell => cell.row.uuid === rowUuid && cell.column.uuid === colUuid,
      )?.value ?? ""
    );
  };

  return (
    <TableAnswerPreviewWrapper>
      <div className="flex gap-2">
        <RowHeaderStyled />
        {columns.map(col => (
          <ColumnHeaderStyled key={col.uuid}>{col.name}</ColumnHeaderStyled>
        ))}
      </div>

      {rows.map(row => (
        <div key={row.uuid} className="flex gap-2">
          <RowHeaderStyled key={row.uuid}>{row.name}</RowHeaderStyled>

          {columns.map(col => (
            <StyledTextField
              multiline
              key={col.uuid}
              defaultValue={getCellValue(row.uuid, col.uuid)}
              size="small"
              disabled
            />
          ))}
        </div>
      ))}
    </TableAnswerPreviewWrapper>
  );
};
