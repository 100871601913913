import { styled } from "@mui/material";

export const StyledButton = styled("button")`
  width: 3.25rem;
  height: 100%;
`;

export const KnowledgeBaseMenuWrapper = styled("div")`
  width: 3.25rem;
  height: 3.25rem;
`;
