import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";

export type SelectedDaysType = {
  from: Dayjs;
  to: Dayjs;
};
export type SetSelectedDaysType = Dispatch<SelectedDaysType>;

export type Type = "day" | "week" | "month" | "custom";
export type SetType = Dispatch<Type>;

export const MonitoringContext = createContext<
  | {
      patientId: number;
      selectedDays: {
        selectDays: SelectedDaysType;
        setSelectDays: Dispatch<SelectedDaysType>;
      };
      type: {
        type: Type;
        setType: Dispatch<Type>;
      };
    }
  | undefined
>(undefined);

type MonitorContextProviderProps = {
  children: ReactNode;
};
export const MonitorContextProvider = ({
  children,
}: MonitorContextProviderProps) => {
  const { patientId } = useParams();

  if (patientId === undefined) {
    throw new Error("Patient not found");
  }

  const [selectDays, setSelectDays] = useState(() => ({
    from: dayjs().startOf("day"),
    to: dayjs().endOf("day"),
  }));

  const [type, setType] = useState<Type>("day");

  const value = {
    patientId: parseInt(patientId),
    selectedDays: {
      selectDays,
      setSelectDays,
    },
    type: {
      type,
      setType,
    },
  };

  return (
    <MonitoringContext.Provider value={value}>
      {children}
    </MonitoringContext.Provider>
  );
};

export const useMonitoring = () => {
  const context = useContext(MonitoringContext);
  if (context === undefined) {
    throw new Error(
      "useMonitoring must be used within a MonitorContextProvider",
    );
  }
  return context;
};
