import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Apple = (props: SvgIconProps) => {
  return (
    <SvgIcon width="12" height="14" viewBox="0 0 12 14">
      <path
        d="M5.99878 3.34375C5.77534 3.34375 5.59253 3.16094 5.59253 2.9375V2.53125C5.59253 1.40898 6.50151 0.5 7.62378 0.5H8.03003C8.25347 0.5 8.43628 0.682812 8.43628 0.90625V1.3125C8.43628 2.43477 7.5273 3.34375 6.40503 3.34375H5.99878ZM0.311279 7.8125C0.311279 5.8752 1.21772 3.75 3.15503 3.75C3.84819 3.75 4.67085 4.01152 5.25483 4.24004C5.73218 4.42539 6.26792 4.42539 6.74526 4.24004C7.32671 4.01406 8.1519 3.75 8.84507 3.75C10.7824 3.75 11.6888 5.8752 11.6888 7.8125C11.6888 11.0625 9.65757 13.5 7.62632 13.5C7.20737 13.5 6.65894 13.3324 6.3187 13.2131C6.11304 13.142 5.8896 13.142 5.68394 13.2131C5.3437 13.3324 4.79526 13.5 4.37632 13.5C2.34253 13.5 0.311279 11.0625 0.311279 7.8125Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
