import { styled } from "@mui/material";

export const ChartEmpty = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 1rem;
  height: 6.25rem;
  background: ${({ theme }) => theme.colors.borderGray};
  border-radius: 1rem;
`;

interface ChartFullProps {
  height: string;
  background: string;
}

export const ChartFull = styled("div")<ChartFullProps>`
  background: ${({ background }) => background};
  border-radius: 1rem;
  width: 1rem;
  height: ${({ height }) => height};
`;
