import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deletePdfColorAPI } from "@Pdf/api/pdfAPI";
import { MutationOptions } from "@hooks/queries";
import { pdfColorsQueryKey } from "@Pdf/hooks/query/usePdfColorsQuery";
import { PdfColorResponseType } from "@Pdf/types/pdfTypes";

const useDeletePdfColorMutation = (options: MutationOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation(deletePdfColorAPI, {
    ...options,
    onSuccess: (_, id) => {
      queryClient.setQueryData<Array<PdfColorResponseType>>(
        [pdfColorsQueryKey],
        oldData => {
          if (oldData) {
            return oldData.filter(i => i.id !== id);
          }

          return [];
        },
      );

      options.onSuccess?.();
    },
  });
};

export { useDeletePdfColorMutation };
