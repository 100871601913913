import { useAppTranslation } from "@hooks";
import { WHATS_NEW_TYPE } from "@utils";
import { useMemo } from "react";

export const useWhatsNewTypeName = (type?: WHATS_NEW_TYPE) => {
  const { t } = useAppTranslation();
  const typeVariantsDict = useMemo(
    () => ({
      [WHATS_NEW_TYPE.NEW]: {
        typeName: t("whatsNew.type.new"),
      },
      [WHATS_NEW_TYPE.ADDS]: {
        typeName: t("whatsNew.type.adds"),
      },
      [WHATS_NEW_TYPE.DEVELOPMENT]: {
        typeName: t("whatsNew.type.development"),
      },
      [WHATS_NEW_TYPE.INCOMING]: {
        typeName: t("whatsNew.type.incoming"),
      },
      [WHATS_NEW_TYPE.ALL]: {
        typeName: t("whatsNew.type.all"),
      },
    }),
    [t, type],
  );

  if (!type || !typeVariantsDict[type]) return { typeName: undefined };
  return typeVariantsDict[type];
};
