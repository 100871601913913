import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export async function requestHideMealPattern(id: number) {
  await fetchData(`/dietitian/meal-patterns/${id}/hide`, APIMethods.PUT);
}

export async function requestDeletePattern(id: number) {
  await fetchData(`/dietitian/meal-patterns/${id}`, APIMethods.DELETE);
}

export async function requestShowMealPattern(id: number) {
  await fetchData(`/dietitian/meal-patterns/${id}/show`, APIMethods.PUT);
}
