import { useMutation, useQueryClient } from "@tanstack/react-query";

import { optimizeProgramSchedule } from "@client/schedule";

import { MutationOptions } from "../types";
import { fetchUsedRecipesQueryKey } from "./useFetchUsedRecipesQuery";
import { fetchDietitianProgramScheduleKey } from "./useFetchDietitianProgramScheduleQuery";
import { fetchDietitianClientProgramScheduleKey } from "./useFetchDietitianClientProgramScheduleQuery";
import { fetchIsConnectedQueryKey } from "@hooks/queries";

export const useOptimizedProgramScheduleMutation = (
  programId: number | string,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation(() => optimizeProgramSchedule(programId), {
    ...options,
    onSuccess: data => {
      queryClient.invalidateQueries([fetchIsConnectedQueryKey, programId]);
      queryClient.invalidateQueries([fetchUsedRecipesQueryKey]);
      queryClient.invalidateQueries([
        fetchDietitianProgramScheduleKey,
        `${programId}`,
      ]);
      queryClient.invalidateQueries([fetchDietitianClientProgramScheduleKey]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};
