import * as yup from "yup";

import {
  IdDto,
  MeasureDto,
  NutrientDto,
  TranslationDto,
  idSchema,
  measureSchema,
  nutrientSchema,
  translationSchema,
} from "@client/common";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

import { TagResourceDto, tagResourceSchema } from "./searchDietsPreviewDiet";
import { ImageUrl, imageSchema } from "./searchDietsPreviewMeal";

export const searchDietsPreviewProductItem = async (
  dietId: string,
  mealId: string,
  itemId: string,
): Promise<ApiResponse<DietProductSearchPreviewResourceDto>> => {
  const response = await fetchData(
    `/dietitian/diets/search-preview/${dietId}/meals/${mealId}/product-items/${itemId}`,
    APIMethods.GET,
  );

  return apiResponseSchema.validate(response);
};

const nestedFoodItemSearchPreviewResourceSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  producer: yup.string().nullable().default(null),
  category: idSchema.required(),
  translations: yup.array().of(translationSchema.required()).required(),
  nutrients: yup.array().of(nutrientSchema.required()).required(),
  tags: yup.array().of(tagResourceSchema.required()).required(),
  measures: yup.array().of(measureSchema.required()).required(),
  image: imageSchema.nullable().default(null),
});

const dietProductSearchPreviewResourceSchema = yup.object().shape({
  id: yup.number().required(),
  grams: yup.number().required(),
  measure: idSchema.required(),
  product: nestedFoodItemSearchPreviewResourceSchema.required(),
});

const apiResponseSchema = yup.object().shape({
  data: dietProductSearchPreviewResourceSchema.required(),
});

export interface DietProductSearchPreviewResourceDto {
  id: number;
  grams: number;
  measure: IdDto;
  product: NestedFoodSearchPreviewResourceDto;
}

interface NestedFoodSearchPreviewResourceDto {
  id: number;
  name: string;
  producer: string | null;
  category: IdDto;
  translations: TranslationDto[];
  nutrients: NutrientDto[];
  tags: TagResourceDto[];
  measures: MeasureDto[];
  image: ImageUrl | null;
}
