import { useQuery } from "@tanstack/react-query";
import dictionaryKeys from "@hooks/queries/dictionaries/dictionaryKeys";
import { fetchBodyMeasurements } from "@client/dictionaries";

export function useFetchBodyMeasurementsQuery() {
  return useQuery({
    queryKey: dictionaryKeys.bodyMeasurements(),
    queryFn: () => fetchBodyMeasurements(),
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
  });
}
