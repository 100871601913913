import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ReactSortable } from "react-sortablejs";
import { useFormContext } from "react-hook-form";

import { InputAdornment, TableCell, TableRow, Typography } from "@mui/material";
import { Search } from "@mui/icons-material";
import _, { isArray } from "lodash";

import { useAppTranslation, useControlledFieldArray } from "@hooks";
import { fetchFood } from "@client";
import { RecipeEditInput } from "@components/RecipeForm/useRecipeEditForm";
import { detectMovedElement } from "@views/dietician/product-views/components/ProductMeasures/utils/move";
import { Nutrient } from "@typeDefinitions";
import { RecipeProductsContext } from "@context";

import { RecipeTableRow, TableHeaderRow, TableRowSummary } from "./";
import {
  EmptyTablePlaceholder,
  ServingsFormTextField,
  StyledTable,
  StyledTableContainer,
  StyledTableFooter,
  StyledTableHeader,
  TitleWrapper,
} from "./RecipeTableForm.styled";
import { SearchProductsAutocomplete } from "./SearchProductsAutocomplete";

interface RecipeTableFormProps {
  recipeNutrients: Nutrient[];
  inline?: boolean;
}
export const RecipeTableForm = ({
  recipeNutrients,
  inline = false,
}: RecipeTableFormProps) => {
  const { t } = useAppTranslation();
  const { control, watch } = useFormContext<RecipeEditInput>();
  const { fields, move, remove, append } = useControlledFieldArray<
    RecipeEditInput,
    "ingredients"
  >({
    name: "ingredients",
    control,
    watch,
  });
  const { addProduct, hasProducts } = useContext(RecipeProductsContext);
  const isEmpty = !fields.length || !hasProducts;

  const gramsSum = useMemo(() => {
    return _(fields)
      .map(field => Number(field.grams))
      .sum();
  }, [fields]);

  const handleNewProduct = useCallback(async (id: string) => {
    const product = await fetchFood(id);

    addProduct({
      ...product.data,
      description: product.data.description ?? undefined,
      descriptionPl: product.data.descriptionPl ?? "",
    });

    append({
      productId: product.data.id.toString(),
      grams: String(product.data.measures?.[0]?.grams ?? 0),
      measureId: product.data.measures?.[0].id.toString(),
      wrapperId: null,
    });
  }, []);

  const handleSelectNewProduct = (id: string[] | string | null) => {
    setShouldScroll(true);
    return id && !isArray(id) && handleNewProduct(id);
  };

  const ref = useRef<HTMLDivElement>(null);

  const [shouldScroll, setShouldScroll] = useState(false);

  useEffect(() => {
    if (ref.current && fields.length && shouldScroll) {
      ref.current.scroll({
        left: 0,
        top: ref.current.scrollHeight,
        behavior: "smooth",
      });
    }
    setShouldScroll(false);
  }, [ref.current, ref.current?.scrollHeight]);

  return (
    <div className="flex flex-col gap-4 min-w-0">
      {!inline && (
        <>
          <TitleWrapper>
            <Typography variant="h6">
              {t("recipe.ingredients_table.composition")}
            </Typography>
          </TitleWrapper>
          <div className="pl-5">
            <ServingsFormTextField
              control={control}
              name="numOfPortions"
              label={t("recipe.ingredients_table.servings")}
            />
          </div>
        </>
      )}
      <StyledTableContainer ref={ref}>
        <StyledTable stickyHeader>
          <StyledTableHeader>
            <TableHeaderRow />
          </StyledTableHeader>
          {isEmpty && (
            <tbody>
              <tr>
                <TableCell colSpan={15}>
                  <EmptyTablePlaceholder>
                    {t("recipes.empty_recipe")}
                  </EmptyTablePlaceholder>
                </TableCell>
              </tr>
            </tbody>
          )}
          {!isEmpty && (
            <>
              <ReactSortable
                scrollSensitivity={100}
                forceFallback={true}
                animation={200}
                handle=".dragHandle"
                list={fields}
                setList={newOrder => {
                  const result = detectMovedElement(
                    fields.map(({ id }) => id),
                    newOrder.map(({ id }) => id),
                  );

                  if (result) {
                    move(result.from, result.to);
                  }
                }}
                tag="tbody"
              >
                {fields.map((field, i) => (
                  <RecipeTableRow key={field.id} index={i} remove={remove} />
                ))}
              </ReactSortable>

              <tbody>
                <tr
                  style={{
                    height: `${Math.max(0, 275 - 53 * fields.length)}px`,
                  }}
                />
              </tbody>
            </>
          )}

          <StyledTableFooter>
            {!isEmpty && <TableHeaderRow compact withTopBorder />}
            <TableRowSummary
              grams={gramsSum}
              nutrients={recipeNutrients}
              isEmpty={isEmpty}
            >
              <SearchProductsAutocomplete
                key={fields.length}
                selectedProduct={null}
                onSelect={handleSelectNewProduct}
                variant="outlined"
                endAdornment={
                  <InputAdornment position={"end"}>
                    <Search />
                  </InputAdornment>
                }
              />
            </TableRowSummary>
          </StyledTableFooter>
        </StyledTable>
      </StyledTableContainer>
    </div>
  );
};
