import { useFetchClientQuery } from "@hooks/queries";
import { useParams } from "react-router-dom";

export function usePatientForBalance() {
  const { patientId } = useParams();
  const patientIdParsed = parseInt(patientId ?? "0");
  const { client, isLoading } = useFetchClientQuery(patientIdParsed);

  const patientDataFilled =
    !!client &&
    !isLoading &&
    !!client.profile.weight &&
    !!client.profile.birthDate &&
    !!client.profile.height &&
    !!client.profile.sex;

  return { client, patientDataFilled, isLoading };
}
