import {
  NutrientValueResource,
  nutrientValueSchema,
} from "@client/resources/NutrientValueResource";
import * as yup from "yup";

export interface NutrientsMonitoringResource {
  date: string;
  dietitianNutrients: NutrientValueResource[];
  patientNutrients: NutrientValueResource[];
  patientEatenNutrients: NutrientValueResource[];
  patientTargetNutrients: NutrientValueResource[];
  dietitianTargetNutrients: NutrientValueResource[];
  programDay: {
    name: string;
  } | null;
}

export const nutrientsMonitoringSchema = yup.object({
  date: yup.string().required(),
  dietitianNutrients: yup.array().of(nutrientValueSchema).required(),
  patientNutrients: yup.array().of(nutrientValueSchema).required(),
  patientEatenNutrients: yup.array().of(nutrientValueSchema).required(),
  patientTargetNutrients: yup.array().of(nutrientValueSchema).required(),
  dietitianTargetNutrients: yup.array().of(nutrientValueSchema).required(),
  programDay: yup
    .object({
      name: yup.string().required(),
    })
    .nullable()
    .defined(),
});
