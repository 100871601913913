import { ReactNode, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { LanguagesSlugs } from "@typeDefinitions";
import { LangDto } from "@client";

/**
 * Custom hook for i18n
 * @returns
 * t -> translation function
 * i18n -> i18next instance
 * isPolishChosen -> checks if current language is Polish - if false English is chosen
 */
export const useAppTranslation = () => {
  const { t, i18n } = useTranslation();

  const isPolishChosen = useMemo(
    (): boolean => i18n.language === LanguagesSlugs.PL,
    [i18n.language],
  );

  const currentLanguage = useMemo(() => {
    if (isPolishChosen) return LanguagesSlugs.PL;
    return LanguagesSlugs.EN;
  }, [i18n.language]);

  const currentLanguageDTO = useMemo(() => {
    if (currentLanguage === LanguagesSlugs.PL) return LangDto.PL;
    return LangDto.EN;
  }, [currentLanguage]);

  const secondLanguage = useMemo(() => {
    if (i18n.language === "pl") return LanguagesSlugs.EN;
    return LanguagesSlugs.PL;
  }, [i18n.language]);

  const switchLanguage = (): LanguagesSlugs => {
    if (isPolishChosen) return LanguagesSlugs.EN;
    return LanguagesSlugs.PL;
  };

  const isCurrentLanguage = useCallback(
    (lang: LanguagesSlugs) => {
      return currentLanguage === lang;
    },
    [currentLanguage],
  );

  return {
    t: (
      name: string,
      options?: { count?: number; lng?: string; variable?: string | ReactNode },
    ) => t(name, options ?? {}) ?? "",
    switchLanguage,
    i18n,
    currentLanguage,
    secondLanguage,
    isPolishChosen,
    isCurrentLanguage,
    currentLanguageDTO,
  };
};

export interface AppTranslationProps {
  t: (
    name: string,
    options?: { count?: number; lng?: string; variable?: string | ReactNode },
  ) => string;
}
