import { useParams } from "react-router-dom";

type AppParams = { [key: string]: string };

export const useAppParams = (): AppParams => {
  const params = useParams();
  return new Proxy(params, {
    get(target, prop) {
      return target[prop.toString()] ?? "";
    },
  }) as AppParams;
};
