import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Female = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    fill={props.fill}
    width="17"
    height="24"
    viewBox="0 0 17 24"
  >
    <path
      d="M3.40909 8.40625C3.40909 7.74554 3.54077 7.09129 3.79661 6.48087C4.05246 5.87045 4.42745 5.31581 4.90018 4.84862C5.37292 4.38142 5.93414 4.01082 6.55179 3.75798C7.16945 3.50514 7.83145 3.375 8.5 3.375C9.16855 3.375 9.83055 3.50514 10.4482 3.75798C11.0659 4.01082 11.6271 4.38142 12.0998 4.84862C12.5726 5.31581 12.9475 5.87045 13.2034 6.48087C13.4592 7.09129 13.5909 7.74554 13.5909 8.40625C13.5909 9.06696 13.4592 9.72121 13.2034 10.3316C12.9475 10.942 12.5726 11.4967 12.0998 11.9639C11.6271 12.4311 11.0659 12.8017 10.4482 13.0545C9.83055 13.3074 9.16855 13.4375 8.5 13.4375C7.83145 13.4375 7.16945 13.3074 6.55179 13.0545C5.93414 12.8017 5.37292 12.4311 4.90018 11.9639C4.42745 11.4967 4.05246 10.942 3.79661 10.3316C3.54077 9.72121 3.40909 9.06696 3.40909 8.40625ZM9.95455 16.1822C13.6773 15.5084 16.5 12.283 16.5 8.40625C16.5 4.03984 12.9182 0.5 8.5 0.5C4.08182 0.5 0.5 4.03984 0.5 8.40625C0.5 12.283 3.32273 15.5084 7.04545 16.1822V17.75H5.59091C4.78636 17.75 4.13636 18.3924 4.13636 19.1875C4.13636 19.9826 4.78636 20.625 5.59091 20.625H7.04545V22.0625C7.04545 22.8576 7.69545 23.5 8.5 23.5C9.30455 23.5 9.95455 22.8576 9.95455 22.0625V20.625H11.4091C12.2136 20.625 12.8636 19.9826 12.8636 19.1875C12.8636 18.3924 12.2136 17.75 11.4091 17.75H9.95455V16.1822Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
