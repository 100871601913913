import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { fetchMealPattern } from "@client";
import { useAppTranslation } from "@hooks";

export const fetchMealPatternQueryKey = "fetchMealPattern";

export function useFetchMealPatternQuery(mealPatternId: number) {
  const { t } = useAppTranslation();
  const { data: mealPatternApiResponse, ...rest } = useQuery(
    [fetchMealPatternQueryKey],
    () => fetchMealPattern(mealPatternId),
    {
      refetchOnWindowFocus: false,
      onError: e => {
        console.log(e);
        toast.error(t("common.error"));
      },
    },
  );

  return {
    mealPattern: mealPatternApiResponse?.data,
    meta: mealPatternApiResponse?.meta,
    ...rest,
  };
}
