import { memo, useEffect, useMemo } from "react";
import LogRocket from "logrocket";
import { Box, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { IconButton } from "@components/IconButton";
import { DASHBOARD, DIETICIAN_SETTINGS, RESET, SETTINGS } from "@routes";
import {
  BookWithQuestionMark,
  BracketWithArrow,
  Gear,
  HeadWithShoulders,
  Logo,
  Checklist,
  Note,
} from "@assets/icons";
import { useAppEnvironment, useAppTranslation, useUserRoles } from "@hooks";
import { useTourTriggerContext } from "@context";
import { useFeatureFlags } from "@utils/featureFlags";
import { ChatList } from "@views/dietician/Chat";
import { DropMenu } from "@components/DropMenu";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";
import { useLogoutMutation } from "@hooks/queries";
import { TrialCountdownBanner } from "./TrialCountdownBanner";
import { OfflineBackdrop } from "./OfflineBackdrop";
import { NutrientsViewSettings } from "@components/NutrientsViewSettings";
import { useTasksContext } from "@views/dietician/Tasks/Tasks.context";
import { useNotesSidePanelContext } from "@components/NotesSidePanel/NotesSidePanel.context";
import {
  DropMenuContentWrapper,
  HeaderButton,
  HeaderStyled,
  NameWrapper,
  StyledDivider,
  StyledLImg,
  TourButton,
} from "./Header.styled";
import { ClientsSearch } from "./ClientsSearch";
import { Avatar } from "@components/AvatarNew";
import { NotificationMenu } from "@components/NotificationMenu";
import { Link, To, useLocation } from "react-router-dom";
import { UserSettingsDropDown } from "./UserSettingsDropDown";

export const Header = memo(() => {
  const { account } = useFetchDietitianAccountQuery();
  const { isAdminClinicMember } = useUserRoles();
  const { pathname } = useLocation();
  const { logout } = useLogoutMutation();
  const { t, i18n, secondLanguage } = useAppTranslation();
  const { handleOpenCreateModal } = useTasksContext();
  const { handleOpenNotesPanel } = useNotesSidePanelContext();
  const { isClinicAdmin } = useUserRoles();
  const { breakpoints, colors } = useTheme();
  const downMedium = useMediaQuery(`${breakpoints.down("medium")}`);
  const { isProd } = useAppEnvironment();
  const userFullName = useMemo(() => {
    if (!account) return "";
    return account.firstName + " " + account.lastName;
  }, [account]);
  const imgUrl = account?.avatar;
  if (import.meta.env.VITE_APP_ENABLE_LOGROCKET === "true") {
    useEffect(() => {
      if (
        account &&
        !account.email.endsWith("@alloweat.com") &&
        !account.email.endsWith("@becompleat.pl") &&
        !isAdminClinicMember
      ) {
        LogRocket.identify(account.id.toString(), {
          firstName: account.firstName,
          lastName: account.lastName,
          name: userFullName,
          email: account.email,
          clinicId: account.clinic?.id ?? 0,
        });
        LogRocket.init("ru7dkj/alloweatbe");
        console.log("Log rocket initialization success");
      } else if (account) {
        LogRocket.identify(account.id.toString(), {
          firstName: account.firstName,
          lastName: account.lastName,
          name: userFullName,
          email: account.email,
          clinicId: account.clinic?.id ?? 0,
        });
        LogRocket.init("fy7ls2/demo");
        console.log("Tester's log rocket initialization success");
      }
    }, [account]);
  }
  if (import.meta.env.VITE_APP_ENABLE_USERCOM === "true") {
    useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (account && window?.UE?.pageHit && !pathname.startsWith(RESET)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.UE.pageHit({
          apiKey: "kjcaol",
          name: `${account.firstName} ${account.lastName}`,
          user_id: account.id,
          email: account.email,
        });
        console.log("User initialization success");
      }
    }, [account, pathname]);
  }

  const { notify, enabled } = useTourTriggerContext();
  const handleClickStart = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    notify();
  };
  const { chat } = useFeatureFlags();

  return (
    <>
      <HeaderStyled>
        <Link to={DASHBOARD}>
          <StyledLImg
            src="/static/Logo@2x.png"
            srcSet="/static/Logo@2x.png"
            alt="Alloweat logo"
          />
        </Link>
        {!isProd && (
          <IconButton
            size="small"
            onClick={() => i18n.changeLanguage(secondLanguage)}
          >
            <p>{secondLanguage.toUpperCase()}</p>
          </IconButton>
        )}
        <ClientsSearch />
        <div className="flex items-center">
          <OfflineBackdrop />
          {!downMedium && <TrialCountdownBanner />}
          {enabled && (
            <TourButton id="show-tour" size="small" onClick={handleClickStart}>
              <BookWithQuestionMark />
            </TourButton>
          )}
          <div className="flex items-center divide-x divide-neutral-medium-400 mx-6">
            <Box display="flex" alignItems="center">
              <Tooltip title={t("tasks.addTask")} arrow className="pr-2.5">
                <Box>
                  <IconButton
                    color="neutral-dark"
                    onClick={handleOpenCreateModal}
                    className="px-0"
                  >
                    <Checklist className="text-neutral-dark-800 text-2xl" />
                  </IconButton>
                </Box>
              </Tooltip>
              <Tooltip title={t("tasks.addNote")}>
                <Box>
                  <IconButton
                    color="neutral-dark"
                    onClick={handleOpenNotesPanel}
                    className="p-0 mr-4"
                  >
                    <Note className="text-2xl" />
                  </IconButton>
                </Box>
              </Tooltip>
            </Box>

            <div className="flex items-center pl-5">
              <NotificationMenu />
              {chat && <ChatList />}
            </div>
            <NutrientsViewSettings />
          </div>
          <Avatar
            size="small"
            image={imgUrl}
            fullName={userFullName}
            status={undefined}
          />
          {account && <UserSettingsDropDown fullName={userFullName} />}
          {/* {account && (
            <>
              {!downMedium && <NameWrapper>{userFullName}</NameWrapper>}
              <DropMenu>
                <DropMenuContentWrapper>
                  <Link to={SETTINGS}>
                    <HeaderButton
                      startIcon={
                        <HeadWithShoulders fill={colors.neutral.dark[800]} />
                      }
                    >
                      {t("common.profile")}
                    </HeaderButton>
                  </Link>
                  <Link to={(isClinicAdmin && DIETICIAN_SETTINGS) as To}>
                    <HeaderButton
                      startIcon={<Gear />}
                      disabled={!isClinicAdmin}
                    >
                      {t("common.company_settings")}
                    </HeaderButton>
                  </Link>
                  <Link
                    to="https://alloweat.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <HeaderButton startIcon={<Logo />}>
                      {t("common.about_alloweat")}
                    </HeaderButton>
                  </Link>
                  <StyledDivider />
                  <HeaderButton
                    startIcon={<BracketWithArrow size="w-4 h-4" />}
                    onClick={() => logout()}
                  >
                    {t("common.logout")}
                  </HeaderButton>
                </DropMenuContentWrapper>
              </DropMenu>
            </>
          )} */}
        </div>
      </HeaderStyled>
    </>
  );
});
