import { Tooltip, Typography } from "@mui/material";
import { round } from "lodash";

import { Check } from "@mui/icons-material";

import {
  useAppParams,
  useAppTranslation,
  useBalanceClientValues,
} from "@hooks";
import {
  useFetchClientBalanceQuery,
  useUpdateClientBalanceMutation,
} from "@hooks/queries";
import { MuiInfoClickTooltip } from "@components/MuiInfoClickTooltip";

import { Spinner } from "../Spinner/Spinner";
import {
  AlertTitleStyled,
  ApplyIconButton,
  StyledInfoAlert,
} from "./BalanceAvgCpm.styled";
import { scaleMacros } from "./scaleMacros";

export const BalanceAvgCpm = () => {
  const { patientId } = useAppParams();
  const { t } = useAppTranslation();
  const { isLoading, cpmAvg, suggestedAvg } = useBalanceClientValues();
  const defaultDays = 7;
  const { mutate } = useUpdateClientBalanceMutation(patientId);
  const { balanceData } = useFetchClientBalanceQuery(patientId);

  const handleApplyMacros = () => {
    if (balanceData) {
      mutate({
        ...balanceData,
        days: balanceData.days.map(day => ({
          ...day,
          suggestedEnergy: scaleMacros(day.suggestedEnergy, suggestedAvg),
        })),
      });
    }
  };

  return (
    <div className="grid gap-4">
      <div className="flex items-center">
        <Typography variant="h6">{t("balance.balance_average")}</Typography>
        <MuiInfoClickTooltip
          content={
            <p>
              {t("balance.average_for")} {defaultDays}{" "}
              {t("common.days", { count: defaultDays })}
            </p>
          }
        />
      </div>
      {isLoading && <Spinner />}

      {!isLoading && (
        <div className="flex gap-4">
          <StyledInfoAlert icon={false}>
            <AlertTitleStyled>
              {t("balance.energy_expenditure")}:
            </AlertTitleStyled>
            <Typography variant="h5">{round(cpmAvg)} kcal</Typography>
          </StyledInfoAlert>
          <StyledInfoAlert icon={false}>
            <AlertTitleStyled>
              {t("balance.suggested_energy_avg")}:
            </AlertTitleStyled>
            <Typography variant="h5">{round(suggestedAvg)} kcal</Typography>
          </StyledInfoAlert>
          <Tooltip title={t("balance.apply_diet_settings")}>
            <ApplyIconButton
              disabled={!cpmAvg}
              onClick={handleApplyMacros}
              color="primary"
            >
              <Check />
            </ApplyIconButton>
          </Tooltip>
        </div>
      )}
    </div>
  );
};
