import { createContext } from "react";

export const DEFAULT_TOTAL_KCAL = 3850;
export const DEFAULT_DAY_KCAL = 550;
export const DEFUALT_WEEKS = 1;

interface SimulationSlidersContextIf {
  totalKcalDiff: number;
  setTotalKcalDiff: (val: number) => void;
  dayKcalDiff: number;
  setDayKcalDiff: (val: number) => void;
  weeksToGoal: number;
  setWeeksToGoal: (val: number) => void;
}

export const SimulationSlidersContext =
  createContext<SimulationSlidersContextIf>({
    totalKcalDiff: DEFAULT_TOTAL_KCAL,
    setTotalKcalDiff: () => null,
    dayKcalDiff: DEFAULT_DAY_KCAL,
    setDayKcalDiff: () => null,
    weeksToGoal: DEFUALT_WEEKS,
    setWeeksToGoal: () => null,
  });
