import { LangDto, TranslationDto } from "@client";

export const getTranslatedName = (
  translations: TranslationDto[],
  lang: LangDto,
) => translations.find(t => t.langId === lang)?.name;

export const getHexColorOpacity = (hex: string, opacity: number): string => {
  let normalizedHex = hex.startsWith("#") ? hex.substring(1) : hex;
  if (![3, 6].includes(normalizedHex.length)) {
    throw new Error("Invalid hex color format");
  }

  if (normalizedHex.length === 3)
    normalizedHex = normalizedHex
      .split("")
      .map(c => c + c)
      .join("");

  const opacityHex = Math.floor((opacity / 100) * 255)
    .toString(16)
    .padStart(2, "0");

  return `#${normalizedHex}${opacityHex}`;
};
