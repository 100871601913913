import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const postDietsProductsReplacement = async (
  dietId: string,
  payload: ProductsReplacementRequest,
) => {
  const response = await fetchData(
    `/dietitian/diets/${dietId}/products-replacement`,
    APIMethods.POST,
    payload,
  );

  return response;
};

interface ReplacementDto {
  foodId: number;
  foodMeasureId: number;
  ratio: number;
}
interface ProductDto {
  foodId: number;
  replacement: ReplacementDto;
}
export interface ProductsReplacementRequest {
  data: ProductDto[];
}
