import { Card, styled } from "@mui/material";
import { TABLET_MAX_CONTENT_WIDTH, LAPTOP_MAX_CONTENT_WIDTH } from "@utils";

export const StyledFullWidthCard = styled(Card)`
  display: flex;
  padding: 1rem;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallMax}px) {
    width: ${TABLET_MAX_CONTENT_WIDTH};
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.medium}px) and (max-width: ${({ theme }) =>
      theme.breakpoints.values.laptopMax}px) {
    width: ${LAPTOP_MAX_CONTENT_WIDTH};
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.desktop}px) {
    flex: 1;
  }
`;

export const MonthNavigationWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2;
`;

export const ToggleButtonsWrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;
