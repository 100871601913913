import { Button } from "@mui/material";
import { PROGRAM_NEW } from "@routes";
import { Plus } from "@assets/icons";
import { ThemeProviderWrapperNew } from "../../../themeNew";
import { ProgramsViewWrapper } from "@views/dietician/ProgramsNew/ProgramsNew.styled";
import { SingleCardPageLayout } from "@components/PageLayout";
import { useAppTranslation } from "@hooks";
import { useNavigate } from "react-router-dom";
import ProgramsFiltersForm from "@views/dietician/Programs/ProgramsFiltersForm";
import Programs from "@views/dietician/Programs/Programs";

const ProgramsView = () => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();

  return (
    <ThemeProviderWrapperNew>
      <ProgramsViewWrapper>
        <SingleCardPageLayout
          rebranding
          title={t("programs.nutritional_programs")}
          childrenClassName="flex flex-col gap-[24px]"
          extra={
            <Button
              variant="contained"
              onClick={() => navigate(PROGRAM_NEW)}
              startIcon={<Plus size="w-3 h-3" className="stroke-current" />}
            >
              {t("programs.add")}
            </Button>
          }
        >
          <ProgramsFiltersForm>
            <Programs />
          </ProgramsFiltersForm>
        </SingleCardPageLayout>
      </ProgramsViewWrapper>
    </ThemeProviderWrapperNew>
  );
};

export default ProgramsView;
