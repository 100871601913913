import { ReactNode, useMemo, useState } from "react";
import { BlurContext } from "./BlurContext";

interface BlurContextProviderProps {
  children: ReactNode;
}
export const BlurContextProvider = ({ children }: BlurContextProviderProps) => {
  const [isBlurred, setIsBlurred] = useState(false);

  const handleBlur = () => setIsBlurred(true);
  const handleFocus = () => setIsBlurred(false);

  const value = useMemo(
    () => ({
      isBlurred,
      handleBlur,
      handleFocus,
    }),
    [isBlurred, handleBlur, handleFocus],
  );

  return <BlurContext.Provider value={value}>{children}</BlurContext.Provider>;
};
