import {
  ArrayPath,
  Path,
  PathValue,
  useFieldArray,
  UseFormWatch,
} from "react-hook-form";
import {
  FieldArrayPath,
  FieldValues,
  UseFieldArrayProps,
  UseFieldArrayReturn,
} from "react-hook-form/dist/types";

interface UseControlledFieldArrayProps<
  A extends FieldValues = FieldValues,
  B extends FieldArrayPath<A> = FieldArrayPath<A>,
  C extends string = "id",
> extends UseFieldArrayProps<A, B, C> {
  watch: UseFormWatch<A>;
}

export const useControlledFieldArray = <
  A extends FieldValues = FieldValues,
  B extends FieldArrayPath<A> = FieldArrayPath<A>,
  C extends string = "id",
>({
  control,
  name,
  watch,
}: UseControlledFieldArrayProps<A, B, C>): UseFieldArrayReturn<A, B, C> => {
  const fieldsArray = useFieldArray<A, B, C>({ name, control });
  const watchFieldsArray = watch(name as Path<A>) as PathValue<A, B>;

  return {
    ...fieldsArray,
    fields: fieldsArray.fields.map((field, index) => ({
      ...field,
      ...watchFieldsArray[index],
    })),
  };
};
