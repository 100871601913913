import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import * as yup from "yup";
import { logFetchValidateError } from "@utils/validate";
import {
  BodyMeasurementMonitoringResource,
  bodyMeasurementMonitoringSchema,
} from "@client/resources/BodyMeasurementMonitoringResource";

export type FetchMonitoringMeasurementsParams = {
  patientId: number;
  dateFrom: string;
  dateTo: string;
};

export type FetchMonitoringMeasurementsResponse = ApiResponse<
  BodyMeasurementMonitoringResource[]
>;

export const fetchMonitoringMeasurements = async (
  params: FetchMonitoringMeasurementsParams,
): Promise<FetchMonitoringMeasurementsResponse> => {
  const resource = await fetchData(
    `/dietitian/patients/{patient_id}/monitoring2/body-measurements`.replace(
      "{patient_id}",
      params.patientId.toString(),
    ),
    APIMethods.GET,
    undefined,
    mapUrlParams(params),
  );

  return await schema
    .validate(resource)
    .catch(
      logFetchValidateError(
        "/dietitian/patients/{patient_id}/monitoring2/body-measurements",
      ),
    );
};

const mapUrlParams = (params: FetchMonitoringMeasurementsParams) => {
  const urlParams = new URLSearchParams();
  urlParams.set("date-from", params.dateFrom);
  urlParams.set("date-to", params.dateTo);

  return urlParams;
};

const schema = yup.object({
  data: yup.array().of(bodyMeasurementMonitoringSchema).defined(),
});
