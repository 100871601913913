import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Alert, Button as MuiButton, MenuItem, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import _ from "lodash";

import { TAG_COLORS } from "@utils/constants";
import { BlueArrow, BulletMenu, Star } from "@assets/icons";
import { CARBS_ID, ENERGY_ID, FATS_ID, PROTEIN_ID } from "@utils/macros";
import {
  ApiResponse,
  Nutrient,
  OptimizerSettings,
  OptimizerSettingsDayGroup,
  RecipeColor,
  ScheduleViews,
  VisibleNutrient,
} from "@typeDefinitions";
import {
  useAppParams,
  useAppTranslation,
  useProgramLocalStorage,
} from "@hooks";
import { useFetchMultipleProgramDayNutrientsQuery } from "@hooks/queries/useFetchMultipleProgramDayNutrientsQuery";
import { ProgramScheduleNavigationContext } from "@views/dietician/ProgramSchedule/context";
import { DropMenuMui } from "@components/DropMenuMui";
import { Spinner } from "@components/Spinner";
import { MuiWhiteTooltip } from "@components/MuiButtonWithTooltip";
import {
  DietitianProgramScheduleDay,
  OptimizationDto,
  OptimizerSettingsResponse,
} from "@client/schedule";
import { DayDto } from "@client/program";
import {
  useClearScheduleMutation,
  useDuplicateScheduleInterval,
  useFetchOptimizerSettingsQuery,
} from "@hooks/queries/schedule";
import { ProgramInfoNavigation } from "@components/forms/ProgramInfoNavigation";

import { ScheduleGridRow } from "./component/ScheduleGridRow/ScheduleGridRow";
import { ColoredCircle } from "../ColoredCircle";
import {
  ScheduleRangeLocalStorageKey,
  ScheduleRangeSwitch,
} from "./component/ScheduleRangeSwitch";
import { OptimizerSettingsModal } from "./component/OptimizerSettingsModal/OptimizerSettingsModal";
import { ScheduleClearAlert } from "./component/ScheduleClearAlert/ScheduleClearAlert";
import { ClipboardInfoWrapper } from "./component/ClipboardInfoWrapper/ClipboardInfoWrapper";
import { ScheduleSummary } from "./component/ScheduleSummary/ScheduleSummary";
import { OptimizationWarningModal } from "./component/OptimizationWarningModal";
import { FailedOptimizationModal } from "./component/FailedOptimizationModal";
import { EmptyScheduleGridRow } from "./component/ScheduleGridRow/EmptyScheduleGridRow";
import { useFetchIsConnectedQuery } from "@hooks/queries";
import { useLocalStorage } from "react-use";

interface ProgramScheduleProps {
  program?: {
    id: number;
    showNutrients: number[];
    days: ProgramDay[];
    finishDate?: string | null;
    durationDays?: number | null;
  };
  scheduleData?: DietitianProgramScheduleDay[];
  recipeColors: RecipeColor[];
  isLoading?: boolean;
  patientId?: number;
}

export type DraggedRecipeData = {
  dietId: number;
  dayId: number;
  mealId: number;
};

interface ProgramDay {
  id: number;
  name: string;
  frequency: number[];
  diet: {
    id: number;
    title?: string;
    titleEn?: string;
    mealsCount: number;
    nutrients: Nutrient[];
  } | null;
}

export const ProgramScheduleGrid = (props: ProgramScheduleProps) => {
  const {
    program,
    scheduleData,
    recipeColors,
    patientId,
    isLoading: isScheduleLoading,
  } = props;
  const { programId } = useAppParams();

  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const [checkedDay, setCheckedDay] = useLocalStorage(
    ScheduleRangeLocalStorageKey,
  );

  const { mutate: clearSchedule, isLoading } = useClearScheduleMutation(
    parseInt(programId),
  );
  const allTargetNutrients = useFetchMultipleProgramDayNutrientsQuery(
    programId,
    program?.days?.map(day => day.id.toString()),
  );

  const [draggedRecipe, setDraggedRecipe] = useState<DraggedRecipeData>();
  const [settingsModalVisible, showSettingsModal] = useState(false);
  const [optimizationAlert, showOptimizationAlert] = useState(false);
  const [optimizationAlertHard, showOptimizationAlertHard] = useState(false);
  const [optimizeDaysModal, showOptimizeDaysModal] = useState(false);
  const [clearAlertVisible, showClearAlert] = useState(false);
  const [optimizerSettings, setOptimizerSettings] = useState<
    OptimizerSettingsDayGroup[]
  >([]);
  const [daysStats, setDaysStats] = useState({
    optimizedDays: 0,
    daysToOptimization: 0,
  });

  const {
    programInfo: { modified },
    markOptimized,
  } = useProgramLocalStorage();

  const schedulerContainer = useRef<HTMLDivElement>(null);

  const { settings: optimizationSettings } =
    useFetchOptimizerSettingsQuery(programId);

  useEffect(() => {
    if (modified) {
      showOptimizeDaysModal(true);
    }
  }, []);

  const hasMissingTargetNutrients = useMemo(
    () =>
      !optimizationSettings?.length &&
      allTargetNutrients?.find(
        targetNutrients => !targetNutrients?.nutrients?.length,
      ),
    [optimizerSettings, allTargetNutrients],
  );
  const filteredProgramDays =
    program?.days.filter(day => day.frequency.length) ?? [];

  useEffect(() => {
    if (
      allTargetNutrients &&
      optimizationSettings &&
      !optimizerSettings?.length &&
      program?.days
    )
      setOptimizerSettings(
        getDefaultOptimizerSettings(
          filteredProgramDays,
          allTargetNutrients ?? [],
          optimizationSettings ?? [],
        ),
      );
  }, [allTargetNutrients, optimizationSettings, program]);

  const parsedOptimizerSettings = useMemo(
    () =>
      optimizerSettings?.map(({ programDay, nutrients }) => ({
        programDay: { id: programDay.id },
        nutrients: nutrients
          .filter(({ used }) => used)
          .map(({ id, distribution, tolerance, value }) => ({
            id,
            distribution,
            tolerance,
            value,
          })),
      })),
    [optimizerSettings],
  );

  const onBack = patientId
    ? () => {
        navigate(
          `/patients/${patientId}/nutritional-programs/${programId}/days`,
        );
        setCheckedDay(ScheduleViews.WEEK);
      }
    : () => {
        navigate(`/programs/${programId}/days`);
        setCheckedDay(ScheduleViews.WEEK);
      };

  const handleSettingsModal = (data?: ApiResponse<OptimizationDto[]>) => {
    showSettingsModal(state => !state);

    if (data) {
      const optimizedDays = _(data.data)
        .map(day => day.numberOfDaysOptimized)
        .sum();
      const daysToOptimization = _(data.data)
        .map(day => day.numberOfDaysToOptimize)
        .sum();
      setDaysStats({
        daysToOptimization,
        optimizedDays,
      });

      if (daysToOptimization !== optimizedDays) {
        showOptimizationAlertHard(!!daysToOptimization);
      }
    }
  };

  const openSettingsModal = () => {
    if (optimizationAlert) showOptimizationAlert(false);
    if (optimizationAlertHard) showOptimizationAlertHard(false);
    showSettingsModal(true);
  };

  const handleScheduleClear = () => {
    showClearAlert(false);
    clearSchedule();
  };

  const handleOptimizationWarningModal = () => {
    if (modified) {
      markOptimized();
    }
    showSettingsModal(true);
    showOptimizeDaysModal(false);
  };

  const hideOptimizationAlertHard = () => {
    showOptimizationAlertHard(false);
  };

  const handleScheduleClearAlert = () => {
    if (isLoading) return;
    showClearAlert(!clearAlertVisible);
  };

  const hideOptimizeDaysModal = () => {
    if (modified) {
      markOptimized();
    }
    showOptimizeDaysModal(false);
  };

  const { mutate: duplicateInterval, isLoading: isMutationLoading } =
    useDuplicateScheduleInterval({
      onSuccess: () => {
        toast.success(t("patient.schedule.days_duplicate_success"));
      },
    });
  const programScheduleDays = useMemo(
    () => scheduleData?.map(day => ({ id: day.id })),
    [scheduleData],
  );

  const handleDuplicateInterval = (days: number) => {
    duplicateInterval({
      programId,
      payload: {
        programScheduleDays: programScheduleDays?.slice(0, days) ?? [],
        period: days as 7 | 14 | 28,
      },
    });
  };

  const { view, programStartDate, currentRangeEnd, currentRangeStart } =
    useContext(ProgramScheduleNavigationContext);
  const disableDuplicate =
    dayjs(currentRangeStart).endOf("day").isBefore(dayjs()) && !!patientId;

  const isPast =
    !!patientId &&
    program &&
    dayjs(program.finishDate).endOf("day").isBefore(dayjs());

  const getDateArray = (
    rangeStart = currentRangeStart,
    rangeEnd = currentRangeEnd,
  ): dayjs.Dayjs[] =>
    rangeStart && rangeEnd && !rangeStart.isAfter(rangeEnd)
      ? [rangeStart, ...getDateArray(rangeStart.add(1, "day"), rangeEnd)]
      : [];

  const { isConnected } = useFetchIsConnectedQuery(programId);

  return (
    <>
      {isConnected === false && (
        <Alert className="mb-4" severity="error">
          {t("patient.schedule.optimisation_alert")}
        </Alert>
      )}
      <ClipboardInfoWrapper />
      <h3 className="mb-2 flex items-center">
        <BlueArrow className="mr-4 text-2xl" />
        {t("patient.schedule.recipe_click")}
      </h3>
      <div className="flex items-center justify-between mb-2">
        <div className="flex flex-nowrap items-center">
          <span>{t("patient.schedule.legend") + ": "}</span>
          {filteredProgramDays.map(({ id, name }, index) => {
            return (
              <div key={id} className="flex items-center mx-4 my-2">
                <ColoredCircle index={index}>
                  {name.slice(0, 1).toUpperCase()}
                </ColoredCircle>
                <span className="ml-2" style={{ color: TAG_COLORS[index] }}>
                  {name}
                </span>
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex items-center justify-between mb-2">
        <ScheduleRangeSwitch />

        <div className="flex itemsCenter gap-2">
          <DropMenuMui
            icon={
              isLoading || isMutationLoading ? (
                <Spinner size="w-5 h-5" />
              ) : (
                <BulletMenu size="w-5 h-5" className="step-three" />
              )
            }
            size="medium"
          >
            <MenuItem onClick={handleScheduleClearAlert}>
              {t("patient.schedule.clear.all")}
            </MenuItem>
            {[7, 14, 28].map(days =>
              view < days ? (
                <Tooltip
                  key={days}
                  title={t("patient.schedule.invalid_schedule_length", {
                    count: days,
                  })}
                  placement="right"
                >
                  <span>
                    <MenuItem disabled>
                      {t("common.duplicate")} {days} {t("common.days")}
                    </MenuItem>
                  </span>
                </Tooltip>
              ) : disableDuplicate ? (
                <Tooltip
                  title={t("patient.schedule.invalid_duplicate_date")}
                  placement="right"
                >
                  <span>
                    <MenuItem disabled>
                      {t("common.duplicate")} {days} {t("common.days")}
                    </MenuItem>
                  </span>
                </Tooltip>
              ) : (
                <MenuItem
                  key={days}
                  onClick={() => handleDuplicateInterval(days)}
                >
                  {t("common.duplicate")} {days} {t("common.days")}
                </MenuItem>
              ),
            )}
          </DropMenuMui>

          {!isPast && (
            <MuiWhiteTooltip
              arrow
              placement="bottom"
              title={t(
                `patient.schedule.optimization.${
                  hasMissingTargetNutrients
                    ? "tooltip_no_assumed_values"
                    : "tooltip"
                }`,
              )}
            >
              <MuiButton
                className="step-seven"
                variant="contained"
                color="secondary"
                startIcon={<Star />}
                onClick={() => showSettingsModal(true)}
              >
                {t("patient.schedule.optimization.button")}
              </MuiButton>
            </MuiWhiteTooltip>
          )}

          {isPast && (
            <Tooltip
              arrow
              placement="bottom"
              title={t("patient.schedule.optimization.past_program")}
            >
              <div>
                <MuiButton
                  className="step-seven"
                  variant="contained"
                  color="secondary"
                  startIcon={<Star />}
                  onClick={() => showSettingsModal(true)}
                  disabled
                >
                  {t("patient.schedule.optimization.button")}
                </MuiButton>
              </div>
            </Tooltip>
          )}

          <OptimizerSettingsModal
            open={settingsModalVisible}
            onClose={handleSettingsModal}
          />

          {optimizationAlertHard && (
            <FailedOptimizationModal
              onClose={hideOptimizationAlertHard}
              onDaysView={onBack}
              onSettingsModal={openSettingsModal}
              open={optimizationAlertHard}
              optimizedDays={daysStats.optimizedDays}
              daysToOptimization={daysStats.daysToOptimization}
            />
          )}

          {optimizeDaysModal && (
            <OptimizationWarningModal
              open={optimizeDaysModal}
              onClose={hideOptimizeDaysModal}
              onClick={handleOptimizationWarningModal}
            />
          )}

          {clearAlertVisible && (
            <ScheduleClearAlert
              variant="all"
              onSubmit={handleScheduleClear}
              onClose={handleScheduleClearAlert}
            />
          )}
        </div>
      </div>

      <div className="w-full overflow-x-auto" ref={schedulerContainer}>
        {isScheduleLoading && (
          <Spinner className="bg-transparent w-full h-96" />
        )}
        <div className="w-max min-w-full">
          {program &&
            scheduleData &&
            getDateArray().map(date => {
              const scheduleDay = scheduleData?.find(day =>
                dayjs(day.date).startOf("day").isSame(date.startOf("day")),
              );

              if (!scheduleDay)
                return (
                  <EmptyScheduleGridRow
                    key={`${date}`}
                    color="grey"
                    date={date.toString()}
                    startDate={programStartDate.toString()}
                  />
                );

              const dietDayIndex = program.days.findIndex(
                dietDay => scheduleDay.programDay.id === dietDay?.id,
              );

              const dietDay = program.days[dietDayIndex];

              const nutrientData = allTargetNutrients?.find(
                target => target.dayId === dietDay?.id.toString(),
              );

              const optimizerSettings =
                parsedOptimizerSettings?.find(
                  ({ programDay }) =>
                    scheduleDay.programDay.id === programDay.id,
                )?.nutrients || [];

              return (
                <ScheduleGridRow
                  programId={program.id}
                  key={scheduleDay.id}
                  scheduleDay={scheduleDay}
                  color={TAG_COLORS[dietDayIndex]}
                  dietDay={dietDay}
                  draggedRecipe={draggedRecipe}
                  setDraggedRecipe={setDraggedRecipe}
                  recipeColors={recipeColors}
                  optimizerSettings={optimizerSettings}
                  normId={
                    nutrientData?.normId
                      ? parseInt(nutrientData?.normId)
                      : undefined
                  }
                  targetNutrients={nutrientData?.nutrients}
                  startDate={programStartDate.toString()}
                />
              );
            })}
        </div>
      </div>
      <div className="py-4">
        <ScheduleSummary
          scheduleData={scheduleData ?? []}
          programDays={(program?.days as DayDto[]) ?? []}
        />
      </div>
      <div className="py-4">
        <ProgramInfoNavigation
          onBack={onBack}
          onSubmit={() => {
            const path = window.location.pathname?.replace("/schedule", "");
            navigate(path);
            toast.success(t("programs.add_program_success"));
            setCheckedDay(ScheduleViews.WEEK);
          }}
          isSubmitting={isScheduleLoading}
        />
      </div>
    </>
  );
};

const getDefaultOptimizerSettings = (
  days: ProgramDay[],
  targetNutrients: { dayId: string; nutrients?: VisibleNutrient[] }[],
  savedSettings: OptimizerSettingsResponse[],
) => {
  const defaultOrder = [ENERGY_ID, PROTEIN_ID, FATS_ID, CARBS_ID];

  return days.map(day => {
    let nutrients =
      targetNutrients.find(t => t.dayId === day.id.toString())?.nutrients ?? [];
    if (!nutrients.length)
      nutrients = defaultOrder.map(id => ({ id, value: 0, visible: true }));

    return {
      programDay: { id: day.id, name: day.name },
      nutrients: nutrients
        ?.filter(nutrient => nutrient.visible)
        .sort((a, b) => {
          if (defaultOrder.indexOf(a.id) < 0) return 1;
          if (defaultOrder.indexOf(b.id) < 0) return -1;
          return defaultOrder.indexOf(a.id) > defaultOrder.indexOf(b.id)
            ? 1
            : -1;
        })
        .map(({ id, value }, index) => {
          const savedDataProgram = savedSettings.find(
            ({ programDay }) => programDay.id === day.id.toString(),
          );
          const savedData = savedDataProgram?.nutrients.find(
            ({ nutrient }) => nutrient.id === id.toString(),
          );
          const used =
            !!savedData || (!savedSettings?.length && id === ENERGY_ID);
          return {
            id,
            used,
            value: savedData?.value || Math.round(value),
            distribution: savedData?.distribution || (used ? 100 : 0),
            tolerance: savedData?.tolerance || 3,
            order: index + 1,
          } as OptimizerSettings;
        }),
    };
  });
};
