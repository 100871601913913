import * as yup from "yup";

import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";

export const cloneProgramToClient = async (
  id: number,
  payload: CloneProgramToClientRequest,
): Promise<ApiResponse<CloneProgramToClientResponse>> => {
  const response = await fetchData(
    `/dietitian/patient/programs/${id}/clone`,
    APIMethods.POST,
    payload,
  );
  await apiResponseSchema.validate(response);
  return response;
};

export interface CloneProgramToClientRequest {
  patientId: number;
  startDate: string;
}

export interface CloneProgramToClientResponse {
  id: number;
}

const cloneProgramResponseSchema = yup.object().shape({
  id: yup.number().required(),
});

const apiResponseSchema = yup.object().shape({
  data: cloneProgramResponseSchema.required(),
});
