import { useCallback } from "react";
import {
  FieldValues,
  Path,
  PathValue,
  UseFormReturn,
  useWatch,
} from "react-hook-form";

import { useAppTranslation } from "@hooks";
import { FilterDefinition, FilterType, NumberRange } from "@typeDefinitions";
import { Chip } from "@mui/material";
import { TreeSelectOption } from "@components/TreeSelect";
import { Button } from "@components/Button";

interface SearchFilterTagsProps<T extends FieldValues> {
  form: UseFormReturn<T>;
  filters: FilterDefinition<T>[];
  onSearch: () => any;
}

interface FlatTreeSelectOption {
  value: string;
  title: string;
}

export const SearchFilterTags = <T extends FieldValues>({
  form,
  filters,
  onSearch,
}: SearchFilterTagsProps<T>) => {
  const { t } = useAppTranslation();
  const _ = useWatch({ control: form.control });

  const tags = filters.flatMap(filter => {
    if (filter === "spacer") return [];

    if (filter.type === FilterType.SINGLE) {
      const value = form.watch(filter.name);

      return value !== undefined
        ? [
            {
              category: t(filter.label),
              title: filter.options.find(o => o.value === value)?.label ?? "",
              onRemove: () => {
                form.resetField(filter.name);
                if (tags.length === 1) {
                  onSearch();
                }
              },
            },
          ]
        : [];
    }

    if (filter.type === FilterType.TREE_SELECT) {
      const value = form.watch(filter.name);

      const flattenOption = (os: TreeSelectOption[]): FlatTreeSelectOption[] =>
        os.flatMap(o => [
          { value: o.value, title: o.title },
          ...flattenOption(o.children),
        ]);
      const flatOptions = flattenOption(filter.options);

      return value !== undefined
        ? [
            {
              category: t(filter.label),
              title:
                flatOptions.find(o => o.value === `${value}`)?.title ??
                `${value}`,
              onRemove: () => {
                form.resetField(filter.name);
                if (tags.length === 1) {
                  onSearch();
                }
              },
            },
          ]
        : [];
    }

    if (filter.type === FilterType.MULTI) {
      const values = (form.watch(filter.name) ?? []) as any[];

      return values.map(value => ({
        category: t(filter.label),
        title: filter.options.find(o => o.value === value)?.label ?? "",
        onRemove: () => {
          form.setValue(
            filter.name,
            values.filter(v => v !== value) as PathValue<number, Path<number>>,
          );
          if (tags.length === 1) {
            onSearch();
          }
        },
      }));
    }

    if (filter.type === FilterType.RANGE) {
      const range = form.watch(filter.name) as NumberRange | undefined;

      const toTag = () => [
        {
          category: `${t(filter.label)} ${t("common.to").toLowerCase()}`,
          title: range!.to!.toString(),
          onRemove: () => {
            form.resetField(`${filter.name}.to` as Path<T>);
            if (tags.length === 1) {
              onSearch();
            }
          },
        },
      ];

      const fromTag = () => [
        {
          category: `${t(filter.label)} ${t("common.from").toLowerCase()}`,
          title: range!.from!.toString(),
          onRemove: () => {
            form.resetField(`${filter.name}.from` as Path<T>);
            if (tags.length === 1) {
              onSearch();
            }
          },
        },
      ];

      return [...(range?.from ? fromTag() : []), ...(range?.to ? toTag() : [])];
    } else {
      return [];
    }
  });

  const handleClearAll = useCallback(() => {
    form.reset();
    onSearch();
  }, [tags]);

  return (
    <>
      {tags.length !== 0 && (
        <div className="flex flex-wrap items-center gap-3">
          {tags.map(tag => (
            <Chip
              key={`${tag.category}${tag.title}`}
              label={`${tag.category}: ${tag.title}`}
              onDelete={tag.onRemove}
              onClick={tag.onRemove}
            />
          ))}
          {tags.length !== 0 && (
            <>
              <Chip
                label={t("recipes.filters.clear_all")}
                variant="outlined"
                color="primary"
                onDelete={handleClearAll}
                onClick={handleClearAll}
              />
              <Button onClick={onSearch}>{t("common.search")}</Button>
            </>
          )}
        </div>
      )}
    </>
  );
};
