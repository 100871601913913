import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const ExclamationMarkInCircle = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="14" height="14" viewBox="0 0 14 14">
      <path
        d="M7 0.625C8.21875 0.641927 9.31055 0.938151 10.2754 1.51367C11.2572 2.08919 12.0358 2.86784 12.6113 3.84961C13.1868 4.81445 13.4831 5.90625 13.5 7.125C13.4831 8.34375 13.1868 9.43555 12.6113 10.4004C12.0358 11.3822 11.2572 12.1608 10.2754 12.7363C9.31055 13.3118 8.21875 13.6081 7 13.625C5.78125 13.6081 4.68945 13.3118 3.72461 12.7363C2.74284 12.1608 1.96419 11.3822 1.38867 10.4004C0.813151 9.43555 0.516927 8.34375 0.5 7.125C0.516927 5.90625 0.813151 4.81445 1.38867 3.84961C1.96419 2.86784 2.74284 2.08919 3.72461 1.51367C4.68945 0.938151 5.78125 0.641927 7 0.625ZM7 12.4062C8.48958 12.3724 9.73372 11.8561 10.7324 10.8574C11.7311 9.85872 12.2474 8.61458 12.2812 7.125C12.2474 5.63542 11.7311 4.39128 10.7324 3.39258C9.73372 2.39388 8.48958 1.8776 7 1.84375C5.51042 1.8776 4.26628 2.39388 3.26758 3.39258C2.26888 4.39128 1.7526 5.63542 1.71875 7.125C1.7526 8.61458 2.26888 9.85872 3.26758 10.8574C4.26628 11.8561 5.51042 12.3724 7 12.4062ZM7 8.34375C6.6276 8.3099 6.42448 8.10677 6.39062 7.73438V4.48438C6.42448 4.11198 6.6276 3.90885 7 3.875C7.3724 3.90885 7.57552 4.11198 7.60938 4.48438V7.73438C7.57552 8.10677 7.3724 8.3099 7 8.34375ZM7 9.18164C7.22005 9.18164 7.40625 9.25781 7.55859 9.41016C7.71094 9.5625 7.78711 9.75716 7.78711 9.99414C7.78711 10.2142 7.71094 10.4004 7.55859 10.5527C7.40625 10.7051 7.22005 10.7812 7 10.7812C6.77995 10.7812 6.59375 10.7051 6.44141 10.5527C6.28906 10.4004 6.21289 10.2142 6.21289 9.99414C6.21289 9.75716 6.28906 9.5625 6.44141 9.41016C6.59375 9.25781 6.77995 9.18164 7 9.18164Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
