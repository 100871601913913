import { memo, useMemo } from "react";

import { useAppTranslation, useBasicNutrientsGetter } from "@hooks";
import { Nutrient, VisibleNutrient } from "@typeDefinitions";
import { Card } from "@components/Card";
import { MicronutrientPreview } from "@components/MicronutrientsModal";
import {
  BulletAlert,
  BulletSuccess,
  BulletWarning,
} from "@assets/icons/StatusBullets";

import { SummaryKcalWrapper } from "./components/SummaryKcalWrapper.styled";

interface ScheduleGridSummaryProps {
  normId?: number;
  nutrients: Nutrient[];
  targetNutrients?: VisibleNutrient[];
}

export const ScheduleGridSummary = memo((props: ScheduleGridSummaryProps) => {
  const { nutrients, targetNutrients, normId } = props;

  const { t } = useAppTranslation();

  const { kcal, protein, fat, carbs } = useBasicNutrientsGetter(nutrients);
  const { kcal: maxKcal } = useBasicNutrientsGetter(targetNutrients);
  const kcalDiff = !maxKcal ? 0 : maxKcal !== 0 && 100 - (kcal / maxKcal) * 100;

  const color: "success" | "info" | "danger" | undefined = useMemo(() => {
    if (kcalDiff === false) return;
    if (kcalDiff >= -5 && kcalDiff <= 5) return "success";
    if (kcalDiff < -10 || kcalDiff > 10) return "danger";

    return "info";
  }, [kcalDiff]);

  return (
    <>
      <Card
        square
        noContainerPaddings
        className="w-32 min-w-32 border-t-0 ml-auto flex flex-col justify-center items-center ml-1.5 -my-3 h-auto  sticky right-0 z-10"
        containerClassName="text-sm flex flex-col pt-7 px-5 w-full items-start"
      >
        <SummaryKcalWrapper style={{ color: color }}>
          {"Kcal: "}
          <span className="font-semibold ml-1">{kcal}</span>
          <span className="text-lg ml-2">
            {color === "success" && <BulletSuccess />}
            {color === "info" && <BulletWarning />}
            {color === "danger" && <BulletAlert />}
          </span>
        </SummaryKcalWrapper>

        <span className="mb-3">
          {t(`common.protein_short`) + ": "}
          <span className="font-semibold ml-1">{`${protein} g`}</span>
        </span>
        <span className="mb-3">
          {t(`common.fat_short`) + ": "}
          <span className="font-semibold ml-1">{`${fat} g`}</span>
        </span>
        <span className="mb-3">
          {t(`common.carbs_short`) + ": "}
          <span className="font-semibold ml-1">{`${carbs} g`}</span>
        </span>

        <MicronutrientPreview means={nutrients} noPadding normId={normId} />
      </Card>
    </>
  );
});
