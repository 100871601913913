import { Slider, SliderProps } from "@mui/material";
import { Control, useController } from "react-hook-form";

interface FormSliderProps extends Omit<SliderProps, "value" | "onChange"> {
  control?: Control<any>;
  name: string;
}

export const FormSlider = ({ control, name, ...rest }: FormSliderProps) => {
  const {
    field: { value, onChange },
  } = useController({ control, name });
  return <Slider value={value} onChange={onChange} {...rest} />;
};
