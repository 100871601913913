import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

export const useIntroductionForm = (values?: IntroductionFormInputs) => {
  const resolver = yup.object().shape({
    on: yup.boolean().required(),
    title: yup.lazy((_, { parent }) =>
      parent.on ? yup.string().required() : yup.string().nullable(),
    ),
    description: yup.string().nullable(),
  });

  const { reset, ...rest } = useForm<IntroductionFormInputs>({
    defaultValues: {
      on: true,
      description: "",
      title: "",
    },
    resolver: yupResolver(resolver),
  });

  useEffect(() => {
    if (values) reset(values, { keepDirtyValues: true });
  }, [values]);

  return {
    reset,
    ...rest,
  };
};

export interface IntroductionFormInputs {
  on: boolean;
  description: string | null;
  title: string | null;
}
