import { useForm } from "react-hook-form";

import * as yup from "yup";

import { FetchMealResponse, UpdateCreatorMealRequest } from "@client/meals";
import { LangDto } from "@client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppTranslation } from "@hooks";

export const useMealNameForm = (defaultValues: MealNameInputs) => {
  const { t } = useAppTranslation();
  const mealNameSchema = yup.object().shape({
    namePl: yup.string().required(t("common.required_field")),
  });

  const { reset, ...rest } = useForm<MealNameInputs>({
    defaultValues,
    resolver: yupResolver(mealNameSchema),
  });

  return {
    reset,
    ...rest,
  };
};

export interface MealNameInputs {
  namePl: string;
  nameEn: string | null;
  reviewed: boolean;
}

export const createMealNameRequest = (
  data: MealNameInputs,
): UpdateCreatorMealRequest => {
  return {
    reviewed: data.reviewed,
    translations: [
      { langId: LangDto.PL, name: data.namePl },
      { langId: LangDto.EN, name: data.nameEn ?? "" },
    ],
  };
};

export const createMealNameForm = (data: FetchMealResponse): MealNameInputs => {
  const namePl =
    data.translations.find(t => t.langId === LangDto.PL)?.name ?? "";
  const nameEn =
    data.translations.find(t => t.langId === LangDto.EN)?.name ?? null;

  return {
    reviewed: data.reviewed,
    namePl,
    nameEn,
  };
};
