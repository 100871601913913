import { useMutation, useQueryClient } from "@tanstack/react-query";

import { requestUpdateFoodEditor } from "@client/food";
import { fetchProductQueryKey } from "@hooks/queries/food/useFetchProductQuery";
import { StoreFoodRequest } from "@typeDefinitions/requests";
import { MutationOptions } from "../types";
import { searchFoodQueryKey } from "../useSearchFoodQuery";

export interface UpdateFoodEditorMutationParams {
  id: number;
  data: StoreFoodRequest;
}

export function useUpdateFoodEditorMutation(options?: MutationOptions) {
  const queryClient = useQueryClient();
  return useMutation(
    (data: UpdateFoodEditorMutationParams) =>
      requestUpdateFoodEditor(data.id, data.data),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries([fetchProductQueryKey]);
        queryClient.invalidateQueries([searchFoodQueryKey]);
        options?.onSuccess && options.onSuccess();
      },
      onError: () => {
        queryClient.invalidateQueries([fetchProductQueryKey]);
      },
    },
  );
}
