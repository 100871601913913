import { Select, SelectProps } from "@components/Select";
import { Control, Controller, Path } from "react-hook-form";

import { FieldValues } from "react-hook-form/dist/types";

/** select component with React Hook Form Controller */
export const FormSelect = <T extends FieldValues>(
  props: FormSelectProps<T>,
) => {
  const { name, control, error, ...rest } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onBlur, onChange, value, ref },
        fieldState: { error: errors },
      }) => (
        <Select
          ref={ref}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          value={value as string | number | undefined}
          error={error ?? (errors?.message as string)}
          {...rest}
        />
      )}
    />
  );
};

interface FormSelectProps<T extends FieldValues> extends SelectProps {
  /** input name attribute */
  name: Path<T>;
  /** react hook form controller */
  control: Control<T>;
  /** error message */
  error?: string;
}
