import {
  AccordionDetails,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import { ArrowDown } from "@assets/icons";
import { useSuggestedTasksQuery } from "@hooks/queries/suggestedTasks/useSuggestedTasksQuery";
import { useSuggestedTasksCountQuery } from "@hooks/queries/suggestedTasks/useSuggestedTasksCountQuery";
import { useState, type FC } from "react";
import { TasksSuggestedTable } from "./TasksSuggestedTable";

import {
  AccordionHeader,
  AccordionStyled,
  AccordionSummaryStyled,
} from "../TasksAccordion/TasksAccordion.styled";

interface TasksSuggestedAccordionProps {
  id: number;
  isExpanded: boolean;
  name: string;
}

export const TasksSuggestedAccordion: FC<TasksSuggestedAccordionProps> = ({
  id: tagId,
  isExpanded,
  name,
}) => {
  const [page, setPage] = useState(0);

  const { data, isLoading } = useSuggestedTasksQuery(page, { tagId });

  const { data: tasks } = useSuggestedTasksCountQuery({ tagId });

  if (isLoading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding={2}
      >
        <CircularProgress />
      </Box>
    );

  if (!data?.tasks.length || !tasks?.count) return null;

  return (
    <AccordionStyled defaultExpanded={isExpanded}>
      <AccordionSummaryStyled expandIcon={<ArrowDown />}>
        <AccordionHeader>
          <span> {`${name} (${tasks.count})`}</span>
        </AccordionHeader>
      </AccordionSummaryStyled>
      <AccordionDetails>
        <TasksSuggestedTable
          suggestedTasks={data.tasks}
          isFetching={false}
          tagId={tagId}
          tagName={name}
          page={page}
          setPage={setPage}
          meta={data.meta}
        />
      </AccordionDetails>
    </AccordionStyled>
  );
};
