import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MutationOptions } from "../types";
import { fetchDietitianProgramScheduleKey } from "./useFetchDietitianProgramScheduleQuery";
import { fetchDietitianClientProgramScheduleKey } from "./useFetchDietitianClientProgramScheduleQuery";
import { clearScheduleDay } from "@client/schedule";

export const useClearScheduleDayMutation = (
  programId: number,
  dayId: number,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation(() => clearScheduleDay(programId, dayId), {
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries([fetchDietitianProgramScheduleKey]);
      queryClient.invalidateQueries([fetchDietitianClientProgramScheduleKey]);
      options?.onSuccess && options.onSuccess();
    },
  });
};
