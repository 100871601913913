import { createContext } from "react";
import { LangDto } from "@client";

interface LangContextIf {
  lang: LangDto;
  isPolish: boolean;
}

const langContext: LangContextIf = {
  lang: LangDto.PL,
  isPolish: true,
};

export const LangContext = createContext(langContext);
