import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const postDietMealsSaveToCollectionBase = async (
  dietId: string,
  mealId: string,
  payload: PostDietMealsSaveToCollectionBaseRequest,
) => {
  return await fetchData(
    `/dietitian/diets/${dietId}/meals/${mealId}/save-to-collection-base`,
    APIMethods.POST,
    payload,
  );
};

export interface PostDietMealsSaveToCollectionBaseRequest {
  name: string;
}
