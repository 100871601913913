import { useEffect, useState } from "react";
import { Route, Routes, useMatch, useNavigate } from "react-router-dom";

import { Alert, Tabs, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { difference } from "lodash";

import { useAppTranslation, useAppParams } from "@hooks";
import {
  BalanceType,
  BalanceValueMethod,
  DEFAULT_BALANCE_ACTIVITY,
  DEFAULT_BALANCE_FORMULA,
  DEFAULT_BALANCE_PAL,
} from "@utils/balance";
import {
  ButtonStyled,
  StyledHeadAddBtn,
  StyledTab,
  TabsContainer,
  StyledCard,
} from "@views/dietician/PatientProgramDays";
import { PATIENT_ENERGY_BALANCE } from "@routes";
import { usePatientForBalance } from "@components/CaloricNeedsForm/components";
import { Weekday } from "@components/WeekdaySelector";
import {
  useFetchClientBalanceQuery,
  useUpdateClientBalanceMutation,
} from "@hooks/queries";
import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";
import { BalanceAvgCpm } from "@components/BalanceAvgCpm";
import { MassForecast } from "@components/MassForecast";
import { ClientProfileSection } from "@components/ClientProfileSection";
import { SimulationSection } from "@components/SimulationSection";
import { ProgramDayTab } from "@components/WeekdaySelectorTab";
import { WEEKDAYS_VALUES } from "@utils/weekdays";

import {
  DeskDivider,
  StyledTopBalanceCard,
  ViewWrapper,
  TopSectionWrapper,
} from "./PatientEnergyBalance.styled";
import { BalanceDay } from "./BalanceDay";
import { SimulationSlidersContextProvider } from "./context/SimulationSlidersContextProvider";
import { ThemeProviderWrapper } from "theme";

export const PatientEnergyBalance = () => {
  const { t, currentLanguage, isPolishChosen } = useAppTranslation();
  const { breakpoints } = useTheme();
  const isUnmatchSmartphone = useMediaQuery(`${breakpoints.down("small")}`);
  const isMatchTablet = useMediaQuery(`${breakpoints.up("tablet")}`);
  const isMatchSmartphone = useMediaQuery(
    `${breakpoints.between("small", "tablet")}`,
  );
  const isMatchLaptop = useMediaQuery(`${breakpoints.up("laptop")}`);
  const { patientId } = useAppParams();
  const { patientDataFilled, client } = usePatientForBalance();
  const [selectedDays, setSelectedDays] = useState<Weekday[]>([]);
  const [isCalculating, setIsCalculating] = useState(false);

  const navigate = useNavigate();

  const { balanceData, isLoading, isError } =
    useFetchClientBalanceQuery(patientId);
  const { mutate: updateBalance } = useUpdateClientBalanceMutation(patientId);

  const match = useMatch(PATIENT_ENERGY_BALANCE);

  const firstDay = balanceData?.days.length === 0;

  useEffect(() => {
    setSelectedDays(balanceData?.days.flatMap(day => day.frequency) ?? []);
  }, [balanceData]);

  const handleAddDay = () => {
    if (!balanceData) return;
    setDaysCount(1);

    updateBalance({
      type: balanceData.type,
      days: [
        ...balanceData.days,
        {
          id: null,
          type: BalanceType.PAL,
          frequency: difference(WEEKDAYS_VALUES, selectedDays),
          translations: [
            { name: firstDay ? "Codziennie" : "Kolejny dzien", locale: "pl" },
            { name: firstDay ? "Every day" : "Another day", locale: "en" },
          ],
          suggestedEnergy: {
            kcal: null,
            protein: null,
            fat: null,
            carb: null,
          },
          energyDemand: {
            spm: { value: null, method: null },
            pal: {
              value: DEFAULT_BALANCE_PAL,
              method: BalanceValueMethod.AUTO,
            },
            pattern: DEFAULT_BALANCE_FORMULA,
            cpm: { value: null, method: null },
            activity: {
              daily: DEFAULT_BALANCE_ACTIVITY,
              training: DEFAULT_BALANCE_ACTIVITY,
            },
          },
        },
      ],
    });
  };

  const matchingDayIndex = balanceData?.days.findIndex(
    day => `days/${day.id}` === match?.params["*"],
  );

  const [daysCount, setDaysCount] = useState<number | null>(null);

  useEffect(() => {
    if (matchingDayIndex === -1 && balanceData && balanceData?.days?.[0]) {
      setDaysCount(balanceData.days.length);
      navigate(`days/${balanceData.days?.[0]?.id}`, {
        preventScrollReset: true,
      });
    } else if (!!balanceData && !balanceData?.days?.length) {
      handleAddDay();
      if (patientDataFilled) setIsCalculating(true);
    }

    const isNewDayCreated =
      balanceData?.days.length &&
      balanceData.days[balanceData.days.length - 1] &&
      daysCount &&
      daysCount < balanceData.days.length;

    if (isNewDayCreated) {
      const latestDayId = balanceData.days[balanceData.days.length - 1].id;

      setDaysCount(balanceData.days.length);
      navigate(`days/${latestDayId}`, {
        replace: true,
        preventScrollReset: true,
      });
    }
  }, [matchingDayIndex, balanceData?.days.length]);

  useEffect(() => {
    const hasDataAndLoading =
      isCalculating && balanceData?.days[0]?.suggestedEnergy?.kcal;

    if (hasDataAndLoading) setIsCalculating(false);
  }, [balanceData, isCalculating]);

  useEffect(() => {
    const isFilledWithNoData =
      patientDataFilled &&
      !balanceData?.days[0]?.suggestedEnergy?.kcal &&
      !isCalculating;

    if (isFilledWithNoData) setIsCalculating(true);
  }, [client?.profile]);

  if (isLoading) return <Spinner />;

  if (isError || !balanceData) return <ApiError />;

  return (
    <ThemeProviderWrapper>
      <ViewWrapper>
        {isUnmatchSmartphone ? (
          <Alert severity="error">{t("common.device_to_narrow")}</Alert>
        ) : (
          <>
            <StyledTopBalanceCard>
              {!isCalculating && (
                <SimulationSlidersContextProvider>
                  <TopSectionWrapper>
                    <div className="grid gap-8">
                      <BalanceAvgCpm />
                      <MassForecast />
                    </div>
                    {isMatchLaptop && <DeskDivider />}
                    <ClientProfileSection />
                  </TopSectionWrapper>
                  <SimulationSection />
                </SimulationSlidersContextProvider>
              )}
              {isCalculating && <Spinner />}
            </StyledTopBalanceCard>
            {isMatchSmartphone && (
              <Tooltip
                title={`${t("program.days.all_days_taken")}`}
                placement="bottom-start"
              >
                <div>
                  <StyledHeadAddBtn
                    size="small"
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={handleAddDay}
                  >
                    {t("program.days.add")}
                  </StyledHeadAddBtn>
                </div>
              </Tooltip>
            )}

            <StyledCard>
              <TabsContainer>
                <Tabs
                  color="prime"
                  variant="scrollable"
                  scrollButtons="auto"
                  value={balanceData.days.findIndex(
                    day => `days/${day.id}` === match?.params["*"],
                  )}
                  onChange={(_, value) => {
                    navigate(`days/${balanceData.days?.[value]?.id}`, {
                      preventScrollReset: true,
                    });
                  }}
                  orientation={isMatchSmartphone ? "horizontal" : "vertical"}
                >
                  {balanceData.days.map(day => (
                    <StyledTab
                      key={day.id}
                      label={
                        <ProgramDayTab
                          title={
                            firstDay
                              ? isPolishChosen
                                ? "Codziennie"
                                : "Every day"
                              : day.translations.find(
                                  t => t.locale === currentLanguage,
                                )?.name ?? ""
                          }
                          energy={day.suggestedEnergy.kcal ?? undefined}
                          cpm={day.energyDemand.cpm.value ?? undefined}
                          weekdays={day.frequency}
                        />
                      }
                    />
                  ))}
                </Tabs>
                {isMatchTablet && (
                  <ButtonStyled
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={handleAddDay}
                  >
                    {t("program.days.add")}
                  </ButtonStyled>
                )}
              </TabsContainer>

              <div className="flex-grow flex">
                <Routes>
                  <Route path="/days/:dayId" element={<BalanceDay />} />
                </Routes>
              </div>
            </StyledCard>
          </>
        )}
      </ViewWrapper>
    </ThemeProviderWrapper>
  );
};
