import { Card, Popover, styled } from "@mui/material";
import { LoadingButton } from "@mui/lab";

interface StyledCardProps {
  padding?: string;
}

export const StyledCard = styled(Card)<StyledCardProps>`
  display: grid;
  padding: ${({ padding }) => padding ?? "1.5rem 2rem"};
  justify-items: center;
  gap: 1rem;
  max-width: 25rem;
`;

export const StyledPopover = styled(Popover)`
  z-index: 1500;
`;

export const StyledButton = styled(LoadingButton)`
  padding: 0 1.5rem;
`;
