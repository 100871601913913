import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { ProgramFiltersFormPops } from "@views/dietician/Programs/ProgramsFiltersFormType";

const usePaginationFields = () => {
  const { watch, setValue } = useFormContext<ProgramFiltersFormPops>();
  const [page, perPage] = watch(["page", "perPage"]);

  const pageField = useMemo(() => {
    return {
      page: page,
      setPage: (value: number) => setValue("page", value),
    };
  }, [page]);

  const perPageField = useMemo(() => {
    return {
      perPage: perPage,
      setPerPage: (value: number) => setValue("perPage", value),
    };
  }, [perPage]);

  return {
    pageField,
    perPageField,
  };
};

export default usePaginationFields;
