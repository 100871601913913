import { PurpleStar } from "@assets/icons";
import { Button, styled } from "@mui/material";

export const MenuWrapper = styled("div")`
  position: fixed;
  width: 5rem;
  height: 100%;
  padding: 1.75rem 0.875rem;
  background-color: ${({ theme }) => theme.colors.whiteBackground};
  z-index: 50;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  overflow-y: auto;
`;

export const StyledIconsList = styled("div")`
  max-height: 48rem;
  min-height: 48rem;
  height: 100%;
  position: absolute;

  @media screen and (min-height: 880px) {
    display: grid;
    gap: 0.375rem;
  }
`;

interface TrialBannerProps {
  warning: boolean;
}

export const TrialBanner = styled("div")<TrialBannerProps>`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  background: ${({ warning, theme }) =>
    warning ? theme.palette.warning.light : theme.palette.primary.medium};
  color: ${({ warning, theme }) =>
    warning ? theme.palette.warning.dark : theme.palette.primary.main};
  border-radius: 8px;
  margin-right: 24px;
  padding: 8px;
`;

export const TrialExpiredBanner = styled("div")`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.alertTomato};
  margin-right: 3rem;
`;

export const TrialBannerContent = styled("span")`
  font-size: 12px;
  font-weight: 600;
  line-height: 19px;
`;

export const TitleWrapper = styled("div")`
  display: grid;
  justify-items: center;
  font-family: ${({ theme }) => theme.fontFamily.regular};
`;

export const StyledMuiButton = styled(Button)`
  color: ${({ theme }) => theme.colors.alertTomato};
  border-color: ${({ theme }) => theme.colors.alertTomato};
  font-weight: 600;
  &.MuiButtonBase-root.MuiButton-root:hover {
    border-color: red;
  }
`;

interface SpanProps {
  bold?: boolean;
}
export const SpanStyled = styled("span")<SpanProps>`
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 0.75rem;
`;

export const UnlimitedGrowthBanner = styled("div")`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-right: 3rem;
`;

export const PurpleStarStyled = styled(PurpleStar)`
  font-size: 1.125rem;
`;

interface DashboardContentProps {
  indentLeft?: boolean;
}

export const MENU_OPENED_WIDTH = 203.875;
export const MENU_CLOSED_WIDTH = 49;

export const DashboardContent = styled("div")<DashboardContentProps>`
  display: flex;
  flex-direction: column;
  width: ${({ indentLeft }) =>
    indentLeft
      ? `calc(100% - ${MENU_OPENED_WIDTH}px)`
      : `calc(100% - ${MENU_CLOSED_WIDTH}px)`};
  height: 100%;
  overflow: auto;
  background: ${({ theme }) => theme.colors.primaryLightNew};
  margin-left: ${({ indentLeft }) =>
    indentLeft ? MENU_OPENED_WIDTH : MENU_CLOSED_WIDTH}px;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding-top: 4rem;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallNew + MENU_OPENED_WIDTH}px) {
    margin-left: ${MENU_CLOSED_WIDTH}px;
    width: calc(100% - ${MENU_CLOSED_WIDTH}px);
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.medium}px) and (max-width: ${({ theme }) =>
      theme.breakpoints.values.medium + MENU_OPENED_WIDTH}px) {
    margin-left: ${MENU_CLOSED_WIDTH}px;
    width: calc(100% - ${MENU_CLOSED_WIDTH}px);
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.desktop}px) and (max-width: ${({ theme }) =>
      theme.breakpoints.values.desktop + MENU_OPENED_WIDTH}px) {
    margin-left: ${MENU_CLOSED_WIDTH}px;
    width: calc(100% - ${MENU_CLOSED_WIDTH}px);
  }
`;

export const PageLayoutWrapper = styled("div")`
  flex: 1 1 0%;
  padding: 1.5rem 1.75rem;
`;
