import { round } from "lodash";
import {
  ENERGY_PER_GRAM_OF_CARB,
  ENERGY_PER_GRAM_OF_FAT,
  ENERGY_PER_GRAM_OF_PROTEIN,
} from "@utils/macros";

export enum Unit {
  GRAMS = "g",
  PERCENTAGE = "%",
}
export function sumMacros(fat = 0, carbs = 0, protein = 0): number {
  return (
    fat * ENERGY_PER_GRAM_OF_FAT +
    carbs * ENERGY_PER_GRAM_OF_CARB +
    protein * ENERGY_PER_GRAM_OF_PROTEIN
  );
}

export function labelFactory(
  value: number,
  min: number,
  max: number,
  percent = false,
): string {
  if (value <= 0) return "0";
  if (value < min) return `<${min}${percent ? " %" : ""}`;
  if (value > max) return `>${max}${percent ? " %" : ""}`;
  return `${value}${percent ? " %" : ""}`;
}

export function calculateSliderValue(
  energy: number,
  value: number,
  multiplier: number,
) {
  return energy ? round(((value * multiplier) / energy) * 100) : 0;
}

export function displayValueFactory(
  unit: Unit,
  value: number,
  sliderValue: number,
) {
  if (unit === Unit.GRAMS) return value;
  if (unit === Unit.PERCENTAGE) return sliderValue;
  return 0;
}

export function valueChangeHandler(
  value: string,
  unit: Unit,
  multiplier: number,
  energy: number,
) {
  const parsedValue = parseFloat(value.replace(",", ".")) || 0;
  if (unit === Unit.GRAMS) return parsedValue;
  if (unit === Unit.PERCENTAGE) {
    return round((energy * parsedValue) / 100 / multiplier);
  }
  return 0;
}

export function calculateValueFromSlider(
  energy: number,
  value: number,
  multiplier: number,
) {
  return round((energy * value) / 100 / multiplier);
}

export const kcalMinInput = 100;
export const kcalMin = 500;
export const kcalMax = 6000;
export const percentageMin = 0;
export const percentageMax = 100;
