import { useMemo } from "react";

import { Typography } from "@mui/material";
import sanitizeHtml from "sanitize-html";

import { useAppTranslation } from "@hooks";

interface RecipeDescriptionProps {
  descriptionPl: string | null;
  descriptionEn: string | null;
}

export const RecipeDescription = ({
  descriptionPl,
  descriptionEn,
}: RecipeDescriptionProps) => {
  const { isPolishChosen, t } = useAppTranslation();

  const sanitized = useMemo(() => {
    if (isPolishChosen) return sanitizeHtml(descriptionPl ?? "");
    else return sanitizeHtml(descriptionEn ?? "");
  }, [isPolishChosen, descriptionEn, descriptionPl]);

  return (
    <>
      <Typography variant="h6">{t("recipes.preparation")}</Typography>
      {!!sanitized && (
        <Typography
          className="px-4"
          variant="body1"
          dangerouslySetInnerHTML={{
            __html: sanitized.replaceAll("\n", "<br />"),
          }}
        />
      )}
      {!sanitized && (
        <Typography variant="body2" color="grey" className="text-center">
          {t("recipe_new.no_description")}
        </Typography>
      )}
    </>
  );
};
