import { useMutation, useQueryClient } from "@tanstack/react-query";

import { IdDto } from "@client";
import {
  SurveySubgroupQuestionRequest,
  postSurveySubgroupQuestion,
} from "@client/surveys";
import { ApiResponse } from "@typeDefinitions";

import { MutationOptions } from "../types";
import { fetchSurveyFullQueryKey } from "./useFetchSurveyFullQuery";

export const postSurveySubgroupQuestionMutationKey =
  "postSurveySubgroupQuestionMutationKey";

export const usePostSurveySubgroupQuestionMutation = (
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation<
    ApiResponse<IdDto>,
    unknown,
    PostSurveySubgroupQuestionMutationPayload
  >(
    [postSurveySubgroupQuestionMutationKey],
    ({ id, payload }) => postSurveySubgroupQuestion(id, payload),
    {
      onSuccess: (data, variables) => {
        options?.onSuccess && options.onSuccess(data, variables);

        queryClient.invalidateQueries([fetchSurveyFullQueryKey]);
      },
      ...options,
    },
  );
};

interface PostSurveySubgroupQuestionMutationPayload {
  id: string;
  payload: SurveySubgroupQuestionRequest;
}
