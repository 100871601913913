import { useMemo, useState } from "react";

import { TabContext } from "@mui/lab";

import { Clock, Cutlery, PieChart } from "@assets/icons";
import { TabSwitch } from "@components/TabSwitch";
import { useAppTranslation } from "@hooks";
import { useTagsNew } from "@hooks/useTagsNew";
import { TagCategoryType } from "@utils/tagsNew";

import { AccordionWrapper } from "./AccordionWrapper";
import { RecipeContent } from "./RecipeContent";
import { ClientContextIf, Nutrient } from "@typeDefinitions";
import { RecipeItem, useItemsContext } from "../ItemsContext";
import {
  DefinedLabelSpecialTagIf,
  SpecialTagIf,
  SpecialTags,
} from "@components/TagWithIcon";
import { mapUserChips } from "@components/ExpandableChips";
import { useDietUsedRecipes } from "@hooks/useDietUsedRecipes";
import { RecipeCollectionContent } from "./RecipeCollectionContent";

export interface RecipeAccordionProps {
  id: number;
  macros: Nutrient[];
  tags: number[];
  imgUrl: string | undefined;
  title: string;
  titleEn: string | null;
  servings: number;
  clientContext?: ClientContextIf;
  mealId: number;
  collectionId?: number;
  itemId?: number;
}

export const RecipeAccordion = ({
  id,
  macros,
  tags,
  imgUrl,
  title,
  titleEn,
  servings,
  clientContext,
  mealId,
  collectionId,
  itemId,
}: RecipeAccordionProps) => {
  const { t, isPolishChosen } = useAppTranslation();
  const [tab, setTab] = useState(Tabs.NUTRIENTS);
  const tabs = useMemo(
    () => [
      { id: Tabs.NUTRIENTS, label: t("common.nutrients_values") },
      { id: Tabs.PREPARATION, label: t("common.preparation") },
      { id: Tabs.TAGS, label: t("common.tags") },
    ],
    [t],
  );

  const { tagCategoryDictionary, tagNameDictionary } = useTagsNew();
  const { toggleItems, updateItem, isChecked, singleSelect, itemsCount } =
    useItemsContext();
  const { isUsedRecipe } = useDietUsedRecipes();
  const [selectedServings, setSelectedServings] = useState(servings);
  const item = useMemo<RecipeItem>(
    () => ({
      type: "recipe",
      id,
      servings: selectedServings,
      mealId,
    }),
    [selectedServings, id, mealId],
  );
  const shouldHideCheckbox = singleSelect && !!itemsCount && !isChecked(item);

  const preparationTimeTagList = tagCategoryDictionary.get(
    TagCategoryType.PREPARATION_TIME,
  )?.tags;
  const preparationTimeTag = tags?.find(tag =>
    preparationTimeTagList?.some(t => t.id === tag),
  );
  const preparationTimeName =
    !!preparationTimeTag && tagNameDictionary.get(preparationTimeTag);

  const difficultyOfPreparationTagList = tagCategoryDictionary.get(
    TagCategoryType.DIFFICULTY_OF_PREPARATION,
  )?.tags;
  const difficultyOfPreparationTag = tags?.find(tag =>
    difficultyOfPreparationTagList?.some(t => t.id === tag),
  );

  const difficultyOfPreparationName =
    !!difficultyOfPreparationTag &&
    tagNameDictionary.get(difficultyOfPreparationTag);

  const servingsLabel =
    servings > 1 && `${t("recipe.recipe_complex", { count: servings })}`;

  const specialTags = useMemo(
    () =>
      [
        { id: "1", label: preparationTimeName, Icon: Clock },
        { id: "2", label: difficultyOfPreparationName, Icon: Cutlery },
        { id: "3", label: servingsLabel, Icon: PieChart },
      ].filter((t): t is DefinedLabelSpecialTagIf => !!t.label),
    [],
  );

  const userTags = useMemo(
    () =>
      mapUserChips({
        already_used: isUsedRecipe(isPolishChosen ? title : titleEn ?? title),
        disliked: clientContext?.hasUnliked,
        liked: clientContext?.hasLiked,
        allergen: clientContext?.hasAllergens,
      }),
    [title, titleEn, isPolishChosen, clientContext],
  );

  const toggleCheck = () => toggleItems([item]);

  const updateServings = (servings: number) => {
    updateItem({ ...item, servings });
    setSelectedServings(servings);
  };

  const name = useMemo(
    () => (isPolishChosen ? title : titleEn ?? title),
    [isPolishChosen, title, titleEn],
  );
  return (
    <AccordionWrapper
      withImage
      checked={isChecked(item)}
      extras={<SpecialTags tags={specialTags} />}
      nutrients={macros}
      onCheck={toggleCheck}
      tags={tags}
      title={name}
      image={imgUrl}
      userTags={userTags}
      hiddenCheckbox={shouldHideCheckbox}
    >
      <div className="pl-[8px]">
        <TabSwitch tabs={tabs} value={tab} onChange={setTab} />
      </div>
      <TabContext value={tab}>
        {collectionId && itemId ? (
          <RecipeCollectionContent
            id={id}
            updateServings={updateServings}
            collectionId={collectionId}
            itemId={itemId}
          />
        ) : (
          <RecipeContent id={id} updateServings={updateServings} />
        )}
      </TabContext>
    </AccordionWrapper>
  );
};

enum Tabs {
  NUTRIENTS = "0",
  PREPARATION = "1",
  TAGS = "2",
}
