import { BodyButtonType } from "@views/dietician/PatientMonitoring2/components/MeasurementsBox/MeasurementsBodySection";
import { FetchClientResponse } from "@client";
import { BodyMeasurementConst } from "@consts/BodyMeasurementConst";
import { BodyMeasurementMonitoringResource } from "@client/resources/BodyMeasurementMonitoringResource";

type MappedItem = {
  type: BodyButtonType;
  apiFieldName: keyof Exclude<
    BodyMeasurementMonitoringResource["measurements"],
    null
  >;
  unit: "kg" | "cm" | "%";
  translateKey: string;
};

export const MappedFields: MappedItem[] = [
  {
    type: "weight",
    apiFieldName: "weight",
    unit: "kg",
    translateKey:
      "monitoring2.measurements_box.measurement_changes_type.weight",
  },
  {
    type: "bodyFatLevel",
    apiFieldName: "bodyFatLevel",
    unit: "%",
    translateKey:
      "monitoring2.measurements_box.measurement_changes_type.bodyFatLevel",
  },
  {
    type: "arm",
    apiFieldName: "arm",
    unit: "cm",
    translateKey: "monitoring2.measurements_box.measurement_changes_type.arm",
  },
  {
    type: "waist",
    apiFieldName: "waist",
    unit: "cm",
    translateKey: "monitoring2.measurements_box.measurement_changes_type.waist",
  },
  {
    type: "hip",
    apiFieldName: "hip",
    unit: "cm",
    translateKey: "monitoring2.measurements_box.measurement_changes_type.hip",
  },
  {
    type: "thigh",
    apiFieldName: "thigh",
    unit: "cm",
    translateKey: "monitoring2.measurements_box.measurement_changes_type.thigh",
  },
  {
    type: "tightBiceps",
    apiFieldName: "tightBiceps",
    unit: "cm",
    translateKey:
      "monitoring2.measurements_box.measurement_changes_type.tightBiceps",
  },
  {
    type: "calf",
    apiFieldName: "calf",
    unit: "cm",
    translateKey: "monitoring2.measurements_box.measurement_changes_type.calf",
  },
];

export const getApiFieldNameWithType = (
  type: BodyButtonType,
): Exclude<MappedItem["apiFieldName"], "bodyMeasurements"> => {
  const name = MappedFields.find(i => i.type === type)?.apiFieldName;

  if (name === undefined) {
    throw new Error("Not implemented");
  }

  if (name === "bodyMeasurements") {
    throw new Error("Wrong name");
  }

  return name;
};

export const getUnit = (type: BodyButtonType): MappedItem["unit"] => {
  const item = MappedFields.find(i => i.type === type);

  if (item === undefined) {
    throw new Error("Not implemented");
  }

  return item.unit;
};

export const getMappedItemFromApiFieldName = (
  field: MappedItem["apiFieldName"],
): MappedItem => {
  const item = MappedFields.find(i => i.apiFieldName === field);

  if (item === undefined) {
    throw new Error("Not implemented");
  }

  return item;
};

export type Goals = { id: number; value: number | null }[];

export const mapClientDataToGoals = (
  data: FetchClientResponse | undefined,
): Goals => {
  if (!data) {
    return [];
  }

  const targetWeight = data.profile.targetWeight;
  if (!targetWeight) {
    return [];
  }

  return [
    {
      id: BodyMeasurementConst.weight,
      value: targetWeight,
    },
  ];
};
