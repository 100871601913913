import { Card, styled, Typography } from "@mui/material";
import { Spinner } from "@components/Spinner";

export const StyledCard = styled(Card)`
  display: grid;
  gap: 1rem;
  padding: 1rem;
`;

export const MealWrapper = styled("div")`
  display: grid;
  padding: 1.75rem;
  gap: 0.75rem;
`;

export const FullSpinner = styled(Spinner)`
  width: 100%;
  height: 100vh;
  background-color: transparent;
`;

export const ProductTitle = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 500;
`;

export const RowNutrientsWrapper = styled("div")`
  display: flex;
  gap: 0.5rem;
  margin-top: 0.25rem;
`;

export const CardTitle = styled(Typography)`
  font-size: 1.25rem;
  font-weight: 600;
`;
