import { Chip } from "@mui/material";
import type { ReactElement } from "react";

export interface CustomTagProps {
  id: number;
  label: string;
  color: string;
  onDelete?: (id: number) => void;
  deleteIcon?: ReactElement;
}

export const CustomTagChip = ({
  id,
  label,
  color,
  onDelete,
  deleteIcon,
}: CustomTagProps): ReactElement => {
  const handleDeleteClick = () => {
    if (onDelete) {
      onDelete(id);
    }
  };

  return (
    <Chip
      size="small"
      label={label}
      sx={{
        color,
        "backgroundColor": `color-mix(in srgb, ${color} 10%, white)`,
        "& .MuiChip-label": {
          textOverflow: "ellipsis",
          overflow: "hidden",
        },
      }}
      deleteIcon={deleteIcon}
      onDelete={onDelete ? handleDeleteClick : undefined}
    />
  );
};
