import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const QuestionMark = (props: SvgIconProps) => (
  <SvgIcon {...props} width="26" height="26" viewBox="0 0 26 26">
    <g id="Group_5208" data-name="Group 5208" transform="translate(-13 -13)">
      <path
        id="Path_1703"
        data-name="Path 1703"
        d="M13.08,14.451a.855.855,0,0,1-.854-.854v-.985a1.757,1.757,0,0,1,.691-1.4,4.51,4.51,0,0,1,1-.555c.81-.358,1.154-.566,1.321-.985a1.34,1.34,0,0,0-.2-1.392,2.133,2.133,0,0,0-1.649-.721h0c-1.19,0-1.67.337-1.879.62a1.092,1.092,0,0,0-.181.385l-.021.131a.858.858,0,0,1-.854.834A.833.833,0,0,1,9.6,8.672L9.611,8.5a2.44,2.44,0,0,1,.06-.343,2.764,2.764,0,0,1,.466-.994A3.739,3.739,0,0,1,13.3,5.849a3.908,3.908,0,0,1,3.069,1.358,3.062,3.062,0,0,1,.456,3.1,3.564,3.564,0,0,1-2.1,1.862,3.617,3.617,0,0,0-.745.377L13.933,13.6A.855.855,0,0,1,13.08,14.451Z"
        transform="translate(12.92 14.074)"
        fill="#111"
      />
      <path
        id="Path_1704"
        data-name="Path 1704"
        d="M12.532,15.964a1.182,1.182,0,1,1,1.182-1.182A1.184,1.184,0,0,1,12.532,15.964Z"
        transform="translate(13.469 16.501)"
        fill="#111"
      />
      <path
        id="Path"
        d="M15.886,0H5.725C2.2,0,0,1.963,0,5.572v10.86C0,20.1,2.2,22.1,5.725,22.1h10.15c3.535,0,5.725-1.974,5.725-5.583V5.572C21.611,1.963,19.421,0,15.886,0Z"
        transform="translate(15 14.895)"
        fill="none"
        stroke="#131313"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
    </g>
  </SvgIcon>
);
