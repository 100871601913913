import { OutlinedInput, Slider, styled, Typography } from "@mui/material";

export const StyledSlider = styled(Slider)`
  flex: 1;
`;

export const StyledTypography = styled(Typography)`
  width: 6.563rem;
  text-align: right;
`;

export const StyledOutlinedInput = styled(OutlinedInput)`
  width: 7.5rem;
`;
