import { useState } from "react";
import type { MouseEvent } from "react";
import { Menu, MenuItem, Checkbox, FormControlLabel } from "@mui/material";
import {
  TasksViewSettingsStyledIconButton,
  TasksViewSettingsStyledLabel,
  TasksViewSettingsStyledMenuItem,
} from "./TasksViewSettings.styled";
import { SettingsNew, Buffer, ArrowProgress } from "@assets/icons";
import { Controller, useFormContext } from "react-hook-form";
import { FormSelect } from "@components/FormSelectNew";
import { useTaskFilterOptions } from "@hooks/tasks/useTaskFilterOptions";
import { useAppTranslation } from "@hooks";

export const TasksViewSettings = () => {
  const { t } = useAppTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { sortByOptions, groupByOptions } = useTaskFilterOptions();
  const { control } = useFormContext();

  const handleMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <TasksViewSettingsStyledIconButton
        color="secondary-purple"
        onClick={handleMenuOpen}
      >
        <SettingsNew className="text-3xl text-white" />
      </TasksViewSettingsStyledIconButton>
      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="p-4 w-72">
          <p className="uppercase text-gray-450 font-medium mb-2">
            {t("tasks.viewSettings")}
          </p>

          <MenuItem>
            <Controller
              name="hideFinished"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <FormControlLabel
                    control={<Checkbox checked={value} onChange={onChange} />}
                    label={t("tasks.hideCompleted")}
                  />
                );
              }}
            ></Controller>
          </MenuItem>

          <TasksViewSettingsStyledMenuItem>
            <TasksViewSettingsStyledLabel htmlFor="group-by">
              {t("tasks.groupBy.title")}
            </TasksViewSettingsStyledLabel>
            <FormSelect
              id="group-by"
              name="groupBy"
              control={control}
              fullWidth
              startAdornment={<Buffer className="text-5xl text-gray-450" />}
            >
              {groupByOptions.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </FormSelect>
          </TasksViewSettingsStyledMenuItem>
          <TasksViewSettingsStyledMenuItem>
            <TasksViewSettingsStyledLabel htmlFor="sort-by">
              {t("tasks.sortBy.title")}
            </TasksViewSettingsStyledLabel>
            <FormSelect
              id="sort-by"
              name="sortBy"
              control={control}
              fullWidth
              startAdornment={
                <ArrowProgress className="text-5xl text-gray-450" />
              }
            >
              {sortByOptions.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </FormSelect>
          </TasksViewSettingsStyledMenuItem>
        </div>
      </Menu>
    </div>
  );
};
