import { Chip, styled } from "@mui/material";
import useProgramDate from "@hooks/program/useProgramDate";
import { useAppTranslation } from "@hooks";

type StatusChipProps = {
  program: {
    startDate: string;
    endDate: string | null;
  };
};

export const StatusChip = ({ program }: StatusChipProps) => {
  const { t } = useAppTranslation();
  const { isPlanned, isActive } = useProgramDate({
    startDate: program.startDate,
    endDate: program.endDate,
  });

  if (isPlanned) {
    return (
      <CustomChip
        sx={{
          background: "#F2F2FF",
          color: "#4242B8",
        }}
        label={t("patient.programs_grid.row.status.upcoming")}
      />
    );
  }

  if (isActive) {
    return (
      <CustomChip
        sx={{
          background: "#EFF9F7",
          color: "#46BB63",
        }}
        label={t("patient.programs_grid.row.status.active")}
      />
    );
  }

  return (
    <CustomChip
      sx={{
        background: "#F1F1F1",
        color: "#727272",
      }}
      label={t("patient.programs_grid.row.status.completed")}
    />
  );
};

const CustomChip = styled(Chip)`
  line-height: 20px;
  font-size: 12px;
  letter-spacing: 0.4px;
  font-weight: 600;
  font-family: Figtree, serif;
  .MuiChip-label {
    padding: 4px 8px;
  }
` as typeof Chip;
