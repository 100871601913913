import { useQueryClient } from "@tanstack/react-query";

import { useContentSelector } from "@context/TableOfContentsContext";
import { ApiResponse } from "@typeDefinitions";
import { FetchSingedSurveysResponse } from "@client/surveys";
import { fetchSingedSurveysQueryKey } from "@hooks/queries/surveys";

import { useParseUrl } from "../useParseUrl";
import { QuestionFactory } from "../Questions/QuestionFactory";

export const SubgroupContent = () => {
  const { groupId, subgroupId } = useContentSelector();
  const { url } = useParseUrl();
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData<
    ApiResponse<FetchSingedSurveysResponse>
  >([fetchSingedSurveysQueryKey, url]);

  const subgroupData = data?.data.survey.groups
    .find(g => g.id === groupId)
    ?.sections.find(s => s.id === subgroupId);
  const answers = data?.data.answers;
  const dictionaires = data?.data.dictionaries;

  const getAnswer = (id: number) => answers?.find(a => a.question.id === id);

  if (!subgroupData) return <></>;

  return (
    <>
      {subgroupData.questions.map((q, idx) => (
        <QuestionFactory
          key={q.id}
          listIndex={idx}
          totalQuestions={subgroupData.questions.length}
          question={q}
          answer={getAnswer(q.id)}
          dictionaires={dictionaires}
        />
      ))}
    </>
  );
};
