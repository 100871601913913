import { CSSProperties, ReactElement, ReactNode, MouseEvent } from "react";
import { useTranslation } from "react-i18next";

import classNames from "classnames";

import { Button } from "@components/Button";
import { Close } from "@assets/icons";
import ReactDOM from "react-dom";

/**
 * universal modal component
 */
export const Modal = (props: ModalProps) => {
  const {
    title,
    style,
    className,
    children,
    closeIcon = true,
    submitButton = true,
    closeButton = true,
    closeButtonText,
    submitButtonText,
    submitButtonDisabled,
    customButton,
    zIndex = 999,
    onSubmit,
    onClose,
  } = props;

  const { t } = useTranslation();

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if (!e || e?.target === e?.currentTarget) onClose && onClose();
  };

  return ReactDOM.createPortal(
    <>
      <div
        className="justify-center items-center flex overflow-x-hidden fixed inset-0 outline-none focus:outline-none"
        style={{ zIndex }}
        onClick={!closeIcon && !closeButton ? handleClick : undefined}
      >
        <div className="relative w-auto my-6 mx-auto max-w-max">
          {/*content*/}
          <div
            className={classNames(
              "min-w-31rem border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none font-roman max-h-9/10",
              className,
            )}
            style={style}
          >
            {/*header*/}
            {(title || closeIcon) && (
              <div
                className={classNames({
                  "flex items-center px-6 pt-6 pb-4 rounded-t": true,
                  "justify-between": title && closeIcon,
                  "justify-end": closeIcon && !title,
                  "border-b border-solid border-gray-300": title,
                })}
              >
                {title && <h3 className="text-2xl">{title}</h3>}
                {closeIcon && (
                  <Button
                    size="square"
                    color="background"
                    onClick={onClose ?? undefined}
                    className={classNames({
                      "border border-gray-300 w-9 h-9": true,
                    })}
                  >
                    <Close />
                  </Button>
                )}
              </div>
            )}
            {/*body*/}
            <div className="relative flex-auto overflow-auto">{children}</div>
            {/*footer*/}
            {(closeButton || submitButton) && (
              <div className="flex items-center justify-center p-6 rounded-b">
                {customButton}
                {closeButton && (
                  <Button
                    size="large"
                    color="gray"
                    fontLight
                    onClick={onClose ?? undefined}
                    className={classNames({
                      "mr-6": submitButton,
                    })}
                  >
                    {closeButtonText ?? t("common.cancel")}
                  </Button>
                )}
                {submitButton && (
                  <Button
                    size="large"
                    fontLight
                    onClick={onSubmit ?? undefined}
                    disabled={submitButtonDisabled}
                  >
                    {submitButtonText ?? t("common.save")}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* overlay */}
      <div
        className="opacity-25 fixed inset-0 bg-black"
        style={{ zIndex: zIndex - 1 }}
      />
    </>,
    document.querySelector("body") as Element,
  );
};

export interface ModalProps {
  /** modal title */
  title?: string | ReactElement;
  /** submitButton */
  submitButton?: boolean;
  /** submit modal button text */
  submitButtonText?: string;
  /** disabled property on submit button */
  submitButtonDisabled?: boolean;
  /** on modal submit function */
  onSubmit?: (value?: number) => void;
  /** show close button */
  closeButton?: boolean;
  /** additional Button component to render */
  customButton?: ReactNode;
  /** close modal button tetx */
  closeButtonText?: string;
  /** on close or cancel button click  */
  onClose?: () => void;
  /** close icon in right top corner */
  closeIcon?: boolean;
  /** modal children */
  children: ReactNode;
  /** additional classes */
  className?: string;
  /** additional styles */
  style?: CSSProperties;
  zIndex?: number;
}
