import { TabContext } from "@mui/lab";
import { MouseEvent, useCallback, useState } from "react";
import {
  CustomTabPanel,
  CustomToggleButton,
  CustomToggleButtonGroup,
} from "@views/dietician/PatientMonitoring2/components/TabPanel.styled";
import NutrientChart from "@views/dietician/PatientMonitoring2/components/NutrientValues/NutrientChart";
import { useAppTranslation } from "@hooks";
import { CARBS_ID, ENERGY_ID, FATS_ID, PROTEIN_ID } from "@utils/macros";
import { NutrientsMonitoringResource } from "@client/resources/NutrientsMonitoringResource";
import {
  tooltipLabelFormatter,
  xAxisTickFormatter,
} from "@views/dietician/PatientMonitoring2/utils/ChartUtils";

type NutrientsChartsSectionPros = {
  data: NutrientsMonitoringResource[];
};
const NutrientsChartsSection = ({ data }: NutrientsChartsSectionPros) => {
  const [tab, setTab] = useState("1");
  const { t } = useAppTranslation();

  const handleChange = (
    event: MouseEvent<HTMLElement>,
    newValue: string | null,
  ) => {
    newValue && setTab(newValue);
  };

  const getXAxisTickFormatter = useCallback(
    (date: string) => xAxisTickFormatter(t, date, data.length),
    [t, data],
  );

  const getTooltipLabelFormatter = useCallback(
    (date: string) => tooltipLabelFormatter(t, date, data.length),
    [t, data],
  );

  return (
    <TabContext value={tab}>
      <div>
        <CustomToggleButtonGroup value={tab} exclusive onChange={handleChange}>
          <CustomToggleButton value="1">
            {t("monitoring2.nutritional_box.chart_tabs.kcal")}
          </CustomToggleButton>
          <CustomToggleButton value="2">
            {t("monitoring2.nutritional_box.chart_tabs.protein")}
          </CustomToggleButton>
          <CustomToggleButton value="3">
            {t("monitoring2.nutritional_box.chart_tabs.fat")}
          </CustomToggleButton>
          <CustomToggleButton value="4">
            {t("monitoring2.nutritional_box.chart_tabs.carb")}
          </CustomToggleButton>
        </CustomToggleButtonGroup>
      </div>
      <CustomTabPanel value="1">
        <NutrientChart
          data={data}
          nutrientId={ENERGY_ID}
          tooltipLabelFormatter={getTooltipLabelFormatter}
          xAxisTickFormatter={getXAxisTickFormatter}
        />
      </CustomTabPanel>
      <CustomTabPanel value="2">
        <NutrientChart
          data={data}
          nutrientId={PROTEIN_ID}
          tooltipLabelFormatter={getTooltipLabelFormatter}
          xAxisTickFormatter={getXAxisTickFormatter}
        />
      </CustomTabPanel>
      <CustomTabPanel value="3">
        <NutrientChart
          data={data}
          nutrientId={FATS_ID}
          tooltipLabelFormatter={getTooltipLabelFormatter}
          xAxisTickFormatter={getXAxisTickFormatter}
        />
      </CustomTabPanel>
      <CustomTabPanel value="4">
        <NutrientChart
          data={data}
          nutrientId={CARBS_ID}
          tooltipLabelFormatter={getTooltipLabelFormatter}
          xAxisTickFormatter={getXAxisTickFormatter}
        />
      </CustomTabPanel>
    </TabContext>
  );
};

export default NutrientsChartsSection;
