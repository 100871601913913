import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  FetchCreatorDietResponseDto,
  UpdateCreatorDietShowMacrosPayload,
  updateCreatorDietShowMacros,
} from "@client/diets/creator";
import { MutationOptions } from "@hooks/queries/types";
import {
  ALLOWEAT_CREATOR_VERSION,
  useCreatorVersion,
} from "@hooks/queries/diets/creator/useCreatorVersion";
import {
  fetchCreatorDietMealsQueryKey,
  fetchCreatorDietQueryKey,
} from "@hooks/queries/diets/creator";
import { updateProgramDietShowMacros } from "@client/program/dietCreator";

export const useUpdateProgramDietShowMacrosMutation = (
  programId: string,
  options?: MutationOptions,
) => {
  const { version } = useCreatorVersion();
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    UseUpdateProgramDietShowMacrosMutationPayload,
    MutationContextIf
  >(
    ({ dietId, payload }) =>
      updateProgramDietShowMacros(programId, dietId, payload, version),
    {
      ...options,
      onMutate: async ({ dietId, payload }) => {
        await queryClient.cancelQueries([fetchCreatorDietQueryKey, dietId]);
        const previousData =
          queryClient.getQueryData<FetchCreatorDietResponseDto>([
            fetchCreatorDietQueryKey,
            dietId,
          ]);

        if (!previousData?.data) return { previousData, invalidate: true };

        queryClient.setQueryData<FetchCreatorDietResponseDto>(
          [fetchCreatorDietQueryKey, dietId],
          oldData => {
            if (!oldData) return previousData;

            return {
              data: {
                ...oldData.data,
                showMacros: payload.show,
              },
              headers: {
                ...oldData.headers,
                [ALLOWEAT_CREATOR_VERSION]: `${
                  Number(oldData.headers[ALLOWEAT_CREATOR_VERSION]) + 1
                }`,
              },
            };
          },
        );
      },
      onSettled: (d, e, v, context) => {
        if (context?.invalidate) {
          queryClient.invalidateQueries([fetchCreatorDietQueryKey, v.dietId]);
        }
        queryClient.invalidateQueries([
          fetchCreatorDietMealsQueryKey,
          v.dietId,
        ]);
      },
    },
  );
};

interface UseUpdateProgramDietShowMacrosMutationPayload {
  dietId: string;
  payload: UpdateCreatorDietShowMacrosPayload;
}

interface MutationContextIf {
  previousData?: FetchCreatorDietResponseDto;
  invalidate?: boolean;
}
