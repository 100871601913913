import {
  ReactSortable,
  ReactSortableProps,
  ItemInterface,
} from "react-sortablejs";

import classnames from "classnames";

export const SortableList = <T extends ItemInterface>(
  props: SortableListProps<T>,
) => {
  const {
    children,
    defaultItemList,
    onListReorder = () => null,
    className,
    cols = 2,
    gap = 4,
    flexBox,
    ...reactSortableProps
  } = props;

  return (
    <ReactSortable<T>
      list={defaultItemList}
      animation={200}
      setList={onListReorder}
      filter="input, textarea"
      preventOnFilter={false}
      delay={2}
      className={classnames(
        flexBox ? `w-full grid grid-cols-${cols} gap-${gap}` : "flex w-full",
        className,
      )}
      {...reactSortableProps}
    >
      {children}
    </ReactSortable>
  );
};

interface SortableListProps<T extends ItemInterface>
  extends Omit<
    ReactSortableProps<T>,
    | "list"
    | "setList"
    | "clone"
    | "delay"
    | "animation"
    | "onStart"
    | "onEnd"
    | "onMove"
  > {
  defaultItemList: T[];
  children: React.ReactNode;
  onListReorder?: (newOrder: T[]) => void;
  cols?: number;
  gap?: number;
  flexBox?: boolean;
}
