import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Dolar = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 14 14">
    <g
      id="_14-icon-dolar"
      data-name="14-icon-dolar"
      transform="translate(-500 -466)"
    >
      <rect
        id="Rectangle_1155"
        data-name="Rectangle 1155"
        width="14"
        height="14"
        transform="translate(500 466)"
        fill="#fff"
        opacity="0"
      />
      <path
        id="Path_938"
        data-name="Path 938"
        d="M33.762,31.307a10.807,10.807,0,0,0-2.294-.711,12.151,12.151,0,0,1-1.56-.482,2.034,2.034,0,0,1-.779-.5,1.058,1.058,0,0,1-.254-.712,1.081,1.081,0,0,1,.5-.963,2.438,2.438,0,0,1,1.378-.32,2.279,2.279,0,0,1,1.307.343,1.278,1.278,0,0,1,.619.849.46.46,0,0,0,.46.275h1.492a.321.321,0,0,0,.32-.32,2.54,2.54,0,0,0-.413-1.194,3.514,3.514,0,0,0-1.124-1.078,4.389,4.389,0,0,0-1.768-.62V24.745a.343.343,0,0,0-.29-.39.442.442,0,0,0-.1,0h-.985a.4.4,0,0,0-.276,0,.366.366,0,0,0,0,.275v1.079a4.164,4.164,0,0,0-2.433,1.033,2.769,2.769,0,0,0-.894,2.087,2.57,2.57,0,0,0,.871,2.088,6.736,6.736,0,0,0,2.731,1.171c.75.2,1.314.367,1.7.5a2.3,2.3,0,0,1,.871.506.954.954,0,0,1,.276.711,1.174,1.174,0,0,1-.55,1.009,3.007,3.007,0,0,1-1.652.367,2.793,2.793,0,0,1-1.538-.367,1.693,1.693,0,0,1-.733-.895.74.74,0,0,0-.206-.229.5.5,0,0,0-.3,0H26.718a.17.17,0,1,0-.252.229,2.672,2.672,0,0,0,.459,1.378,3.347,3.347,0,0,0,1.239,1.078,5.1,5.1,0,0,0,1.95.55v1.1a.379.379,0,0,0,0,.275.37.37,0,0,0,.276,0h.985a.344.344,0,0,0,.391-.29.324.324,0,0,0,0-.1V37a4.712,4.712,0,0,0,2.662-1.032,2.8,2.8,0,0,0,.986-2.294,2.736,2.736,0,0,0-.39-1.538A2.93,2.93,0,0,0,33.762,31.307Z"
        transform="translate(476.554 441.669)"
      />
    </g>
  </SvgIcon>
);
