import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useAppTranslation } from "@hooks";
import { MutationOptions } from "../types";
import { fetchDietitianAccountQueryKey } from "./useFetchDietitianAccountQuery";
import {
  UpdateDietitianAccount,
  updateDietitianAccount,
} from "@client/settings";
import { toast } from "react-toastify";

export const useUpdateDietitianAccountMutation = (
  options?: MutationOptions,
) => {
  const { t, i18n } = useAppTranslation();
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, UpdateDietitianAccount>(
    payload => updateDietitianAccount(payload),
    {
      ...options,
      onSuccess: (_, variable) => {
        i18n.changeLanguage(variable.language);
        queryClient.invalidateQueries([fetchDietitianAccountQueryKey]);
        options?.onSuccess && options.onSuccess();
        toast.success(t("common.save_changes"));
      },
    },
  );
};
