import styled from "@emotion/styled";

export const MicroValues = styled.span`
  width: 100%;
  min-width: min-content;
  max-width: 8rem;
`;

interface StyledWrapperProps {
  daysView?: boolean;
}

export const MicroSectionWrapper = styled.div<StyledWrapperProps>`
  display: flex;
  gap: 1rem;
  width: 100%;
  align-items: center;
  ${({ theme, daysView }) =>
    daysView &&
    `
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  font-family: ${theme.fontFamily.regular}`}
`;
