import { Autocomplete, IconButton, styled } from "@mui/material";
import { FormTextField } from "@components/FormTextField";
import { TreeSelectMui } from "@components/TreeSelect";
import { FormAutocomplete } from "@components/FormAutocomplete";

export const TagsWrapper = styled("div")`
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
`;

export const ProductDetailsWrapper = styled("div")`
  display: grid;
  gap: 2rem;
`;

export const StyledFormTextField = styled(FormTextField)`
  width: 40rem;
`;

export const StyledTreeSelectMui = styled(TreeSelectMui)`
  width: 40rem;
`;

export const StyledAutocomplete = styled(Autocomplete)`
  width: 40rem;
` as typeof Autocomplete;

export const ProductEditorActionWrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const ImgWrapper = styled("div")`
  position: relative;
  width: 144px;
  height: 144px;
`;

export const StyledImg = styled("img")`
  width: 100%;
  height: 100%;
  min-width: 144px;
  border-radius: 4px;
`;

export const StyledConfirmationIconButton = styled("div")`
  position: absolute;
  background: white;
  border-radius: 50%;
  bottom: -8px;
  right: -8px;
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0;
  width: 144px;
  height: 144px;
  &.MuiIconButton-root:hover {
    border-radius: 0;
  }
`;

export const StyledImgInput = styled("input")`
  display: none;
`;
