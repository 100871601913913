import { Alert, IconButton, styled } from "@mui/material";

export const StyledActionButton = styled(IconButton)`
  padding: 0px;
  height: 45px;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;

export const StyledAlert = styled(Alert)`
  font-size: 0.714rem;
  padding: 0.2rem 0.4rem;
  align-items: center;
  background: ${({ theme }) => theme.palette.info.light};
  color: ${({ theme }) => theme.palette.info.main};
  line-height: 1.286rem;
  letter-spacing: 0.4px;

  &.MuiPaper-root {
    border-radius: 0.286rem;
  }

  & .MuiAlert-icon {
    padding: 0;
  }

  & .MuiAlert-message {
    padding: 0;
  }
`;

export const ButtonsWrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: 4px;
  padding-right: 4px;
`;
