import { omit, round } from "lodash";
import { FetchProgramDayNutrientsResponse } from "@client";

export enum Macros {
  ENERGY = 208,
  PROTEIN = 203,
  FATS = 204,
  CARBS = 205,
}

export const ENERGY_ID = Macros.ENERGY;
export const PROTEIN_ID = Macros.PROTEIN;
export const FATS_ID = Macros.FATS;
export const CARBS_ID = Macros.CARBS;

export const MACROS_ORDER = {
  [Macros.ENERGY]: 0,
  [Macros.PROTEIN]: 1,
  [Macros.FATS]: 2,
  [Macros.CARBS]: 3,
};

export const ENERGY_PER_GRAM_OF_PROTEIN = 4;
export const ENERGY_PER_GRAM_OF_CARB = 4;
export const ENERGY_PER_GRAM_OF_FAT = 9;
export const MACROS_ARRAY = [ENERGY_ID, PROTEIN_ID, FATS_ID, CARBS_ID];

export const getEnergyPerGramOfNutrient = (nutrientId: number) => {
  let kcalPerOneGram = 1;
  switch (nutrientId) {
    case PROTEIN_ID:
      kcalPerOneGram = ENERGY_PER_GRAM_OF_PROTEIN;
      break;
    case FATS_ID:
      kcalPerOneGram = ENERGY_PER_GRAM_OF_FAT;
      break;
    case CARBS_ID:
      kcalPerOneGram = ENERGY_PER_GRAM_OF_CARB;
      break;
  }

  return kcalPerOneGram;
};

export const macrosSortKey = (a: Macros, b: Macros) =>
  (MACROS_ORDER[a] ?? a) - (MACROS_ORDER[b] ?? b);

/**
 * counts grams of given macroelement per kilo of patient's body weight
 * @param value macroelement value in grams
 * @param patientMass patient's body mass in kilograms
 * @returns grams of macroelement per kilo of patient's body weight
 */
export const countMacroValuePerKilo = (
  value: number,
  patientMass: number,
): number => round(value / patientMass, 2);

/**
 *
 * @param valuePerKilo value of macroelement per kilo of body weight
 * @param patientMass patient's body mass in kilograms
 * @returns grams of macroelement per full body mass of patient's body weight
 */
export const countMacroValuePerFullMass = (
  valuePerKilo: number,
  patientMass: number,
): number => round(valuePerKilo * patientMass);

/**
 * gets kcal of energy from 1 gram of given macroelement
 * @param macro one of macroelement: PROTEIN, FATS, CARBS
 * @returns number of kcal from a gram of macroelement
 */
export const getEnergyPerGramFromMacroelement = (
  macro: Macros.PROTEIN | Macros.FATS | Macros.CARBS,
): number => {
  if (macro === Macros.PROTEIN) return ENERGY_PER_GRAM_OF_PROTEIN;
  if (macro === Macros.CARBS) return ENERGY_PER_GRAM_OF_CARB;
  return ENERGY_PER_GRAM_OF_FAT;
};

/**
 * calculates total percentage share of macroelement in energy
 * @param energyTotal total value of energy in kcals
 * @param macro one of macroelement: PROTEIN, FATS, CARBS
 * @param macroValueInGrams value of macroelement in grams
 * @returns % of macroelement in energy
 */
export const calculateMacroPercentageShareInEnergy = (
  energyTotal: number,
  macro: Macros.PROTEIN | Macros.FATS | Macros.CARBS,
  macroValueInGrams: number,
): number => {
  if (!energyTotal) return 0;
  const energyPerGramOfMacro = getEnergyPerGramFromMacroelement(macro);
  return round(
    ((macroValueInGrams * energyPerGramOfMacro) / energyTotal) * 100,
  );
};

/**
 * counts grams of macroelement from percentage share
 * @param macro one of macroelement: PROTEIN, CARBS, FATS
 * @param percentageValue % value of given macroelement
 * @param energyTotal total value of energy in kcals
 * @returns
 */
export const countGramsForMacroFromPercentage = (
  macro: Macros.PROTEIN | Macros.CARBS | Macros.FATS,
  percentageValue: number,
  energyTotal: number,
): number => {
  const energyPerGramOfMacro = getEnergyPerGramFromMacroelement(macro);
  return round(((percentageValue / 100) * energyTotal) / energyPerGramOfMacro);
};

/**
 * calculates total energy in kcal from other macroelements' values
 * @param proteinValueInGrams value of protein in grams
 * @param carbsValueInGrams value of carbs in grams
 * @param fatsValueInGrams value of fats in grams
 * @returns
 */
export const calculateTotalEnergy = (
  proteinValueInGrams: number,
  carbsValueInGrams: number,
  fatsValueInGrams: number,
): number => {
  return round(
    proteinValueInGrams * ENERGY_PER_GRAM_OF_PROTEIN +
      carbsValueInGrams * ENERGY_PER_GRAM_OF_CARB +
      fatsValueInGrams * ENERGY_PER_GRAM_OF_FAT,
  );
};

/**
 * calculates total protein value in grams from other macroelements' values
 * @param energyValueInKcal total value of energy in kcal
 * @param carbsValueInGrams value of carbs in grams
 * @param fatsValueInGrams value of fats in grams
 * @returns
 */
export const calculateTotalProtein = (
  energyValueInKcal: number,
  carbsValueInGrams: number,
  fatsValueInGrams: number,
): number => {
  return round(
    (energyValueInKcal -
      carbsValueInGrams * ENERGY_PER_GRAM_OF_CARB -
      fatsValueInGrams * ENERGY_PER_GRAM_OF_FAT) /
      ENERGY_PER_GRAM_OF_PROTEIN,
  );
};

/**
 * calculates total carbs value in grams from other macroelements' values
 * @param energyValueInKcal total value of energy in kcal
 * @param proteinValueInGrams value of protein in grams
 * @param fatsValueInGrams value of fats in grams
 * @returns
 */
export const calculateTotalCarbs = (
  energyValueInKcal: number,
  proteinValueInGrams: number,
  fatsValueInGrams: number,
): number => {
  return round(
    (energyValueInKcal -
      proteinValueInGrams * ENERGY_PER_GRAM_OF_PROTEIN -
      fatsValueInGrams * ENERGY_PER_GRAM_OF_FAT) /
      ENERGY_PER_GRAM_OF_CARB,
  );
};

export const mapDefaultMacrosValue = (
  values: FetchProgramDayNutrientsResponse,
) => {
  if (values.nutrients.length) {
    return {
      energyKcal:
        values.nutrients.find(nutrient => nutrient.id === ENERGY_ID)?.value ??
        0,
      protein:
        values.nutrients.find(nutrient => nutrient.id === PROTEIN_ID)?.value ??
        0,
      fats:
        values.nutrients.find(nutrient => nutrient.id === FATS_ID)?.value ?? 0,
      carbs:
        values.nutrients.find(nutrient => nutrient.id === CARBS_ID)?.value ?? 0,
    };
  } else return DEFAULT_MACROS_VALUE;
};

const DEFAULT_MACROS_VALUE = {
  energyKcal: 2500,
  protein: 94,
  fats: 97,
  carbs: 313,
};

export enum MicrosFilters {
  SATURATED_FAT = 606,
  CHOLESTEROL = 601,
  DIETARY_FIBER = 291,
  SODIUM = 307,
  POTASSIUM = 306,
  CALCIUM = 301,
  PHOSPHORUS = 305,
  MAGNESIUM = 304,
  IRON = 303,
  VITAMIN_A = 318,
  TOTAL_FOLATE = 417,
  VITAMIN_C = 401,
  KOBALAMINA = 418,
  GLYCEMIC_LOAD = 1111,
  CARBOHYDRATE_EXCHANGERS = 1112,
}
