import React, { ReactElement } from "react";

export const Nutrient: React.FC<NutrientProps> = ({ title, value, units }) => {
  return (
    <div className="flex flex-row items-center gap-4">
      <span className="text-sm">{title}</span>
      <span className="font-normal">{value}</span>
      <span>{units}</span>
    </div>
  );
};

interface NutrientProps {
  title: string;
  value: ReactElement;
  units: string | number | null;
}
