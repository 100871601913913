import {
  MicroListSection,
  StyledItem,
  StyledTitle,
} from "./MicroListDayView.styled";
import { useFetchSimpleProgramDietQuery } from "@hooks/queries/useFetchSimpleProgramDietQuery";
import { useAppParams, useAppTranslation } from "@hooks";
import { CARBS_ID, ENERGY_ID, FATS_ID, PROTEIN_ID } from "@utils/macros";

export const MicroListDayView = () => {
  const { programId, dayId } = useAppParams();
  const { t, isPolishChosen } = useAppTranslation();

  const { simpleDiet } = useFetchSimpleProgramDietQuery(programId, dayId);

  const data =
    simpleDiet?.meals.map(meal => ({
      id: meal.id,
      mealType: isPolishChosen ? meal.title : meal.titleEn,
      protein: meal.nutrients.find(({ id }) => id === PROTEIN_ID)?.value,
      fat: meal.nutrients.find(({ id }) => id === FATS_ID)?.value,
      carbohydrates: meal.nutrients.find(({ id }) => id === CARBS_ID)?.value,
      energy: meal.nutrients.find(({ id }) => id === ENERGY_ID)?.value,
    })) ?? [];

  return (
    <div className="p-4">
      <MicroListSection>
        <StyledTitle>{t("patient.programs.filters.meal")}</StyledTitle>
        <StyledTitle>{t("patient.programs.filters.proteins")}</StyledTitle>
        <StyledTitle>{t("patient.programs.filters.fats")}</StyledTitle>
        <StyledTitle>{t("patient.programs.filters.carbs")}</StyledTitle>
        <StyledTitle>{t("patient.programs.filters.kcal")}</StyledTitle>
      </MicroListSection>

      <ul>
        {data.map(el => {
          const { id, mealType, protein, fat, carbohydrates, energy } = el;
          const alert =
            protein === undefined ||
            fat === undefined ||
            carbohydrates === undefined ||
            energy === undefined;
          return (
            <li key={id}>
              <MicroListSection missing={alert}>
                <StyledItem missing={alert}>{mealType}</StyledItem>
                <StyledItem missing={protein === undefined}>
                  {protein || protein === 0 ? `${protein?.toFixed()} g` : "- !"}
                </StyledItem>
                <StyledItem missing={fat === undefined}>
                  {fat || fat === 0 ? `${fat?.toFixed()} g` : "- !"}
                </StyledItem>
                <StyledItem missing={carbohydrates === undefined}>
                  {carbohydrates || carbohydrates === 0
                    ? `${carbohydrates?.toFixed()} g`
                    : "- !"}
                </StyledItem>
                <StyledItem missing={energy === undefined}>
                  {energy || energy === 0 ? `${energy?.toFixed()} kcal` : "- !"}
                </StyledItem>
              </MicroListSection>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
