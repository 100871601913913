import { CreatorDietDto } from "@client/diets/creator";
import { useAppParams, useAppTranslation } from "@hooks";
import { fetchCreatorDietQueryKey } from "@hooks/queries/diets/creator";
import { InputAdornment, TextField } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { ApiResponse } from "@typeDefinitions";
import { decimalPercentageInput1 } from "@utils/inputs";
import { ENERGY_ID } from "@utils/macros";
import { round } from "lodash";
import { ChangeEventHandler, useMemo } from "react";
import { useController } from "react-hook-form";
import { Label } from "../../common/Label";
import { ScheduleRowInputs } from "./useScheduleRowForm";
import { useAutosaveOnBlurContext } from "@components/AutosaveOnBlur";

interface PercentageTextFieldProps {
  showLabel?: boolean;
  mealId: number;
}
export const PercentageTextField = ({
  showLabel = true,
  mealId,
}: PercentageTextFieldProps) => {
  const { t } = useAppTranslation();
  const { dietId } = useAppParams();
  const queryClient = useQueryClient();
  const targetNutrients = queryClient.getQueryData<ApiResponse<CreatorDietDto>>(
    [fetchCreatorDietQueryKey, dietId],
  )?.data.targetNutrients;
  const targetEnergy = targetNutrients?.find(n => n.id === ENERGY_ID)?.value;

  const {
    field: { value, onChange },
  } = useController<ScheduleRowInputs>({ name: "kcal" });
  const { onBlur, onFocus } = useAutosaveOnBlurContext();

  const percentageValue = useMemo(() => {
    const valueParsed = Number(value);
    if (!targetEnergy || !valueParsed) return "";

    return round((valueParsed / targetEnergy) * 100, 1);
  }, [targetNutrients, value]);

  const handleChangePercentage: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = e => {
    const value = e.target.value;

    const percentage = Number(value);

    if (!targetEnergy || !percentage) return onChange("");

    const energy = (targetEnergy * percentage) / 100;
    return onChange(`${energy}`);
  };

  return (
    <Label label={showLabel ? t("diet.percentage") : false}>
      <TextField
        fullWidth
        size="small"
        value={percentageValue}
        onChange={handleChangePercentage}
        InputProps={{
          inputComponent: decimalPercentageInput1,
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </Label>
  );
};
