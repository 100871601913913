import { useMemo, useState } from "react";

import { Typography } from "@mui/material";
import { ProductDto, ItemPlaceholder, RecipeWrapperDto } from "@client/meals";
import { ReactSortable } from "react-sortablejs";
import { detectMovedElement } from "@views/dietician/product-views/components/ProductMeasures/utils/move";
import { useUpdateCreatorMealProductsSortMutation } from "@hooks/queries/meals/useUpdateCreatorMealProductsSortMutation";
import { useAppParams, useAppTranslation } from "@hooks";
import { useUpdateCreatorMealRecipesSortMutation } from "@hooks/queries/meals/useUpdateCreatorMealRecipesSortMutation";
import { SelectedRecipeEditProxy } from "@views/dietician/MealEdit/components/SelectedRecipeEditProxy";
import { SelectedProductEditProxy } from "@views/dietician/MealEdit/components/SelectedProductEditProxy";
import { MealAverageNutrients } from "./MealAverageNutrients";
import { ArrowDown } from "@assets/icons";
import { StyledTypography } from "@views/dietician/Meal/components/MealRecipes/RecipeItem.styled";
import { StyledExpandButton } from "@views/dietician/Meal/components/MealRecipes/MealRecipes.styled";
import { AddRecipeButton, ItemsHeader } from "./SelectedItems.styled";
import { NutrientDto } from "@client";
import { percentageFromNutrients } from "@components/MacrosSliders";
import { MacrosBarChart } from "@components/MacrosBarChart";
import { Add } from "@mui/icons-material";

interface SelectedItemsProps {
  products?: (ProductDto | ItemPlaceholder)[];
  recipes?: RecipeWrapperDto[];
  numberOfItems?: number;
  nutrients?: NutrientDto[];
}

export const SelectedItems = ({
  numberOfItems = 0,
  recipes,
  products,
  nutrients,
}: SelectedItemsProps) => {
  const [openedRecipes, setOpenedRecipes] = useState<number[]>([]);
  const isAllOpened = openedRecipes.length === recipes?.length;
  const recipesIds = recipes?.map(r => r.id) ?? [];
  const toggleItems = () => setOpenedRecipes(isAllOpened ? [] : recipesIds);

  const { t } = useAppTranslation();
  const { mealId } = useAppParams();
  const productsAmount = products?.length ?? 0;
  const recipesAmount = recipes?.length ?? 0;
  const { mutate: productsSort } =
    useUpdateCreatorMealProductsSortMutation(mealId);
  const { mutate: recipesSort } =
    useUpdateCreatorMealRecipesSortMutation(mealId);

  const macrosPercentage = useMemo(
    () => percentageFromNutrients(nutrients),
    [percentageFromNutrients, nutrients],
  );

  return (
    <div className="grid gap-4">
      <Typography variant="h6">
        {t("meal.selected_items")} ({numberOfItems})
      </Typography>

      <MealAverageNutrients />
      <MacrosBarChart
        {...macrosPercentage}
        title={
          <Typography variant="body2">
            {t("meal.meal_energy_distribution")}:
          </Typography>
        }
      />

      {!!productsAmount && (
        <ItemsHeader variant="h5">
          {t("meal.products")} ({productsAmount})
        </ItemsHeader>
      )}
      {!!products?.length && (
        <ReactSortable
          scrollSensitivity={100}
          forceFallback={true}
          animation={200}
          handle=".dragHandle"
          list={products}
          setList={newOrder => {
            const result = detectMovedElement(
              products?.map(({ id }) => String(id)) ?? [],
              newOrder.map(({ id }) => String(id)),
            );

            if (result) {
              const newOrderIds = newOrder.map(product => Number(product.id));
              productsSort(newOrderIds);
            }
          }}
          className="grid gap-4"
        >
          {products?.map(product => (
            <SelectedProductEditProxy key={product.id} product={product} />
          ))}
        </ReactSortable>
      )}
      {!!recipesAmount && (
        <div className="flex justify-between">
          <ItemsHeader variant="h5">
            {t("meal.recipes")} ({recipesAmount})
          </ItemsHeader>
          <StyledExpandButton
            disableRipple
            isOpen={isAllOpened}
            onClick={toggleItems}
            endIcon={<ArrowDown className="arrow-down" />}
          >
            <StyledTypography variant="caption">
              {!isAllOpened ? t("common.expand_all") : t("common.roll_up_all")}
            </StyledTypography>
          </StyledExpandButton>
        </div>
      )}
      {!!recipes?.length && (
        <ReactSortable
          scrollSensitivity={100}
          forceFallback={true}
          animation={200}
          handle=".dragHandle"
          list={recipes}
          setList={newOrder => {
            const result = detectMovedElement(
              recipes?.map(({ id }) => String(id)) ?? [],
              newOrder.map(({ id }) => String(id)),
            );

            if (result) {
              const newOrderIds = newOrder.map(wrapper => Number(wrapper.id));
              recipesSort(newOrderIds);
            }
          }}
          className="grid gap-4"
        >
          {recipes?.map(recipeWrapper => (
            <SelectedRecipeEditProxy
              key={recipeWrapper.id}
              openedRecipes={openedRecipes}
              setOpenedRecipes={setOpenedRecipes}
              recipeWrapper={recipeWrapper}
            />
          ))}
        </ReactSortable>
      )}
      {!recipesAmount && !productsAmount && false && (
        <div className="flex">
          <a href="#search">
            <AddRecipeButton variant="outlined">
              <Add /> {t("meal.add_recipe")}
            </AddRecipeButton>
          </a>
        </div>
      )}
    </div>
  );
};
