import {
  UpdateProgramPdfSettingsRequest,
  updateProgramPdfSettings,
} from "@client";
import { useMutation } from "@tanstack/react-query";
import { MutationOptions } from "./queries";

export const useUpdateProgramPdfSettings = (
  programId: string,
  options?: MutationOptions,
) => {
  return useMutation<unknown, unknown, UpdateProgramPdfSettingsRequest>(
    paylod => updateProgramPdfSettings(programId, paylod),
    options,
  );
};
