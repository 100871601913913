import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { logFetchValidateError } from "@utils/validate";
import { object } from "yup";
import {
  PatientNormNutrientResource,
  patientNormNutrientsSchema,
} from "@client/resources/PatientNormNutrientResource";

export type FetchPatientNormNutrientsParams = {
  patientId: number;
};

export type FetchPatientNormNutrientsResponse =
  ApiResponse<PatientNormNutrientResource>;

export const fetchPatientNormNutrients = async (
  params: FetchPatientNormNutrientsParams,
): Promise<FetchPatientNormNutrientsResponse> => {
  const response = await fetchData(
    "/dietitian/patients/{patient_id}/norm-nutrients".replace(
      "{patient_id}",
      params.patientId.toString(),
    ),
    APIMethods.GET,
  );

  return await schema
    .validate(response)
    .catch(
      logFetchValidateError("/dietitian/patients/{patient_id}/norm-nutrients"),
    );
};

const schema = object({
  data: patientNormNutrientsSchema.defined(),
});
