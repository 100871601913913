import React, {
  CSSProperties,
  forwardRef,
  memo,
  ReactElement,
  SyntheticEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";

import classNames from "classnames";

import { InputComponentProps } from "../InputComponentProps";
import { useAppTranslation } from "@hooks/useAppTranslation";
import { SelectOption, SelectOptionProps } from "@components/SelectOption";
import { Spinner } from "@components/Spinner";
import { Input } from "@components/Input";
import { InputEndIcon } from "@components/InputEndIcon";
import { PopMenu } from "@components/PopMenu";

/**
 * universal select component
 */
export const Select = memo(
  forwardRef<HTMLInputElement, SelectProps>((props, ref) => {
    const {
      onChange,
      onFocus,
      value,
      children,
      variant = "standard",
      filterOptions = false,
      disabledOptions,
      noInputValue = false,
      fullWidth = false,
      className,
      disabled = false,
      closeOnSelect = true,
      placement = "bottom-start",
      menuStyle,
      menuMatchWidth = true,
      readOnly,
      endIcon,
      allowCustom = false,
      noChangeOnCustomCreate = false,
      noInput = false,
      noPencilIcon = false,
      restrictedCustomOptions,
      onCustomCreate,
      inputProps = {},
      isLoading = false,
      ...rest
    } = props;

    const { t } = useAppTranslation();

    const [localValue, setValue] = useState(value);
    const [valueRendered, setValueRendered] = useState(false);
    const [inputValue, setInputValue] = useState<string>("");
    const [focused, setFocus] = useState(false);

    const containerRef = useRef<HTMLDivElement>(null);

    const freeSolo = useMemo(() => allowCustom && !!onCustomCreate, []);
    // feel free to update this memo, but tbh, such settings should not change after initializing the component

    useEffect(() => {
      setValue(value);
      return () => {
        setInputValue("");
      };
    }, [value]);

    const handleFocus = (e: SyntheticEvent<HTMLInputElement>) => {
      setFocus(true);
      onFocus && onFocus(e);
    };

    const handleChange = (v?: number | string) => {
      setValue(v);
      setValueRendered(false);
      closeOnSelect && setFocus(false);
      if (noInputValue) setInputValue("");
      onChange && onChange(v);
    };

    const handleNewOptionCreation = async () => {
      if (!onCustomCreate) return;

      const createdValue = await onCustomCreate(inputValue);
      if (!createdValue) toast.error(`Cannot add option ${inputValue}`);

      if (!noChangeOnCustomCreate) handleChange(createdValue);
    };

    const handleInputChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
      if (filterOptions) setInputValue(e.currentTarget.value);
    };

    const handleKeyboardActions = (e: React.KeyboardEvent) => {
      const target = e.target as Element;
      e.preventDefault();
      switch (e.key) {
        case " ":
          if (target instanceof HTMLButtonElement) {
            handleChange(target?.value);
          }
          break;
        case "ArrowDown":
          (target.nextSibling as HTMLOptionElement)?.focus();
          break;
        case "ArrowUp":
          (target.previousSibling as HTMLOptionElement)?.focus();
          break;
      }
    };

    const childrenWithProps = React.Children.map(children, child => {
      if (React.isValidElement<SelectOptionProps>(child)) {
        const selected = !noInputValue && localValue === child.props.value;
        if (
          selected &&
          !noInputValue &&
          (!filterOptions || !valueRendered) &&
          inputValue !== child.props.children
        ) {
          setInputValue(child.props.children || "");
          setValueRendered(true);
        }
        return React.cloneElement(child, {
          selected,
          onClick: handleChange,
        });
      }
    })?.filter(child => {
      if (!filterOptions) return true;

      return child.props.children
        ?.toString()
        .toLowerCase()
        .includes(inputValue.toLowerCase());
    });

    if (isLoading) {
      return <Spinner className="w-full" />;
    }

    return (
      <div
        className={classNames(
          {
            "w-full": fullWidth,
          },
          className,
        )}
        ref={containerRef}
      >
        <Input
          ref={ref}
          value={props.label || inputValue}
          onFocus={!readOnly ? handleFocus : undefined}
          variant={variant}
          focused={focused}
          disabled={disabled}
          onChange={handleInputChange}
          bgTransparent={true}
          suffix={
            !readOnly && !noPencilIcon ? (
              <InputEndIcon
                variant={variant}
                focused={focused}
                disabled={disabled}
                icon={endIcon}
                onMouseUp={() => setFocus(!focused)}
              />
            ) : null
          }
          {...inputProps}
          {...rest}
        />

        <PopMenu
          anchor={containerRef}
          open={focused}
          onClose={() => setFocus(false)}
          placement={placement}
          offset={1}
          className="w-full"
          onKeyDown={handleKeyboardActions}
          matchWidth={menuMatchWidth}
          style={{ ...menuStyle, zIndex: 10000 }}
        >
          {!isLoading && childrenWithProps}
          {!childrenWithProps?.length &&
            !isLoading &&
            (freeSolo &&
            !restrictedCustomOptions?.includes(inputValue) &&
            inputValue?.length ? (
              <SelectOption
                value={inputValue}
                onClick={handleNewOptionCreation}
              >
                {`${t("common.newOption")} "${inputValue}"`}
              </SelectOption>
            ) : (
              <SelectOption disabled>{t("common.noOptions")}</SelectOption>
            ))}
        </PopMenu>
      </div>
    );
  }),
);

export interface SelectProps extends InputComponentProps {
  /** menu option click action */
  onChange?: (value?: string | number) => void;
  /** select options to render */
  children?:
    | ReactElement<SelectOptionProps>
    | Array<ReactElement<SelectOptionProps>>;
  /** value of input */
  value?: string | number;
  /** callback fired on focusing input */
  onFocus?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
  /** callback fired on bluring input */
  onBlur?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
  /** callback fired when clicking within input's container */
  onClick?: (event: React.SyntheticEvent<HTMLDivElement>) => void;
  /** text or icon to show before input */
  prefix?: string | React.ReactNode;
  /** allows changing value in Input component and filtering list of options based */
  filterOptions?: boolean;
  /** turns off showing selected value in input */
  noInputValue?: boolean;
  /** */
  closeOnSelect?: boolean;
  /** pop menu placement */
  placement?:
    | "bottom-start"
    | "bottom"
    | "bottom-end"
    | "left-start"
    | "left"
    | "left-end"
    | "right-start"
    | "right"
    | "right-end";
  /** menu match width */
  menuMatchWidth?: boolean;
  /** menu additional styles */
  menuStyle?: CSSProperties;
  /** alternative icon at the end of an input */
  endIcon?: React.ReactNode;
  /** Allows free input; If passed alongside valid "onCustomCreate" prop, "Create" button will appear on the list */
  allowCustom?: boolean;
  /** Callback fired when user clicks "Create" button on custom option; used only with "allowCustom" prop */
  onCustomCreate?: (label: string) => Promise<string | number | undefined>;
  /** List of values that should not be rendered */
  disabledOptions?: (string | number)[];
  /** Prevents from firing "onChange" event after creating new option; used only with "allowCustom" prop */
  noChangeOnCustomCreate?: boolean;
  /** List of phrases that should not trigger "onCustomCreate" action */
  restrictedCustomOptions?: string[];
  inputProps?: Record<string, Record<string, string> | boolean | string>;
  label?: string;
  /** isLoading determines whether to show a spinner in list or not */
  isLoading?: boolean;
  noInput?: boolean;
  noPencilIcon?: boolean;
}
