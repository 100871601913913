import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export async function duplicatePreviousVisitData(
  scheduleId: number,
): Promise<ApiResponse<void>> {
  return fetchData(
    `/dietitian/schedules/${scheduleId}/visit/copy-from-before`,
    APIMethods.PUT,
  );
}
