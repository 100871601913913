import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const fetchFoodGroupsTree = async (): Promise<
  ApiResponse<FoodGroupResourceDto[]>
> => {
  const response = await fetchData(
    "/dietitian/dictionaries/food-groups/tree",
    APIMethods.GET,
  );

  return fetchFoodGroupsTreeSchema.validate(response);
};

export interface FoodGroupResourceDto {
  id: number;
  parentId: number;
  description: string;
  descriptionPl: string | null;
  children: FoodGroupResourceDto[];
}

const foodGroupResourceSchema: yup.ObjectSchema<FoodGroupResourceDto> = yup
  .object()
  .shape({
    id: yup.number().required(),
    parentId: yup.number().required(),
    description: yup.string().required(),
    descriptionPl: yup.string().nullable().default(null),
    children: yup
      .array()
      .of(yup.lazy(() => foodGroupResourceSchema.required()))
      .required(),
  });

const fetchFoodGroupsTreeSchema = yup.object().shape({
  data: yup.array().of(foodGroupResourceSchema).required(),
});
