import { useQuery } from "@tanstack/react-query";

import { QueryOptionsTyped } from "../types";
import { buildDefaultQueryOptions } from "../common";
import { fetchClinicDietitian } from "@client/settings";

export const fetchClinicDietitianQueryKey = "fetchClinicDietitianQueryKey";

export const useFetchClinicDietitianQuery = (
  id: number,
  options?: QueryOptionsTyped<any>,
) => {
  const { data, ...rest } = useQuery(
    [fetchClinicDietitianQueryKey, id],
    () => fetchClinicDietitian(id),
    buildDefaultQueryOptions(options),
  );

  return {
    dietitian: data?.data,
    ...rest,
  };
};
