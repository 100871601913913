import { FavoriteToggleButton } from "@components/FavoriteToggleButton";
import { Card, styled } from "@mui/material";
import { getHexColorOpacity } from "@utils";

export const CardStyled = styled(Card)`
  display: flex;
  flex-direction: column;
  position: relative;
  background: ${({ theme }) => theme.colors.neutral.light[100]};
  box-shadow: ${({ theme }) => theme.boxShadows.card};
  border-radius: 16px;
  cursor: pointer;

  &:hover .buttonsWrapper {
    opacity: 1;
  }

  &:hover .favoriteButton {
    opacity: 1;
  }

  &:hover {
    box-shadow: ${({ theme }) => theme.boxShadows.cardDark};
  }

  max-width: 450px;
`;

export const ButtonsWrapper = styled("div")`
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 16px 16px 0 16px;
  z-index: 1;

  & .MuiButtonBase-root.MuiIconButton-root {
    background: ${({ theme }) =>
      getHexColorOpacity(theme.palette.primary.light, 85)};
  }
`;

export const ImgStyled = styled("img")`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const TitleStyled = styled("span")`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  height: 40px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const ContentWrapper = styled("div")`
  display: grid;
  gap: 16px;
  padding: 16px;
`;

export const ActionsWrapper = styled("div")`
  display: flex;
  gap: 8px;
  transition: opacity 100ms;
  opacity: 0;
`;

interface FavoriteToggleButtonStyledProps {
  favorite?: boolean;
}

export const FavoriteToggleButtonStyled = styled(
  FavoriteToggleButton,
)<FavoriteToggleButtonStyledProps>`
  transition: opacity 100ms;
  opacity: ${({ favorite }) => (favorite ? "1" : "0")};
`;
