import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import * as yup from "yup";
import {
  CalendarDayMonitoringResource,
  calendarDayMonitoringSchema,
} from "@client/resources/CalendarDayMonitoringResource";
import { logFetchValidateError } from "@utils/validate";

export type FetchMonitoringCalendarDaysParams = {
  patientId: number;
  dateFrom: string;
  dateTo: string;
};

export type FetchMonitoringCalendarDaysResponse = ApiResponse<
  CalendarDayMonitoringResource[]
>;

export const fetchMonitoringCalendarDays = async (
  params: FetchMonitoringCalendarDaysParams,
): Promise<FetchMonitoringCalendarDaysResponse> => {
  const response = await fetchData(
    `/dietitian/patients/{patient_id}/monitoring2/days`.replace(
      "{patient_id}",
      params.patientId.toString(),
    ),
    APIMethods.GET,
    undefined,
    mapUrlParams(params),
  );

  return await schema
    .validate(response)
    .catch(
      logFetchValidateError(
        "/dietitian/patients/{patient_id}/monitoring2/days",
      ),
    );
};

const mapUrlParams = (params: FetchMonitoringCalendarDaysParams) => {
  const urlParams = new URLSearchParams();
  urlParams.set("date-from", params.dateFrom);
  urlParams.set("date-to", params.dateTo);

  return urlParams;
};

const schema = yup.object({
  data: yup.array().of(calendarDayMonitoringSchema).defined(),
});
