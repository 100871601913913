import { MEETING_TYPE } from "@utils";
import { createContext, useContext, useState } from "react";

export enum ScheduleVisitModalActionType {
  DELETE_VISIT = "DELETE_VISIT",
  DELETE_EVENT = "DELETE_EVENT",
  CANCEL_VISIT = "CANCEL_VISIT",
}

interface ScheduleVisitModalContextProps {
  isActionModalOpen: boolean;
  isVisitModalOpen: boolean;
  actionType: ScheduleVisitModalActionType | null;
  eventId: number | null;
  startDate: Date | null;
  clientId: number | null;
  activeTab: MEETING_TYPE | null;
  handleCloseActionModal: () => void;
  handleSetActionType: (actionType: ScheduleVisitModalActionType) => void;
  handleSetEventId: (eventId: number) => void;
  handleOpenActionModal: () => void;
  handleVisitModalOpen: () => void;
  handleVisitModalClose: () => void;
  handleSetStartDate: (startDate: Date) => void;
  handleSetClientId: (clientId: number | undefined) => void;
  handleSetActiveTab: (activeTab: MEETING_TYPE) => void;
}

export const ScheduleVisitModalContext =
  createContext<ScheduleVisitModalContextProps>({
    isActionModalOpen: false,
    isVisitModalOpen: false,
    eventId: null,
    actionType: null,
    startDate: null,
    clientId: null,
    activeTab: null,
    handleSetActionType: () => ({}),
    handleSetEventId: () => ({}),
    handleSetActiveTab: () => ({}),
    handleOpenActionModal: () => ({}),
    handleVisitModalOpen: () => ({}),
    handleVisitModalClose: () => ({}),
    handleCloseActionModal: () => ({}),
    handleSetStartDate: () => ({}),
    handleSetClientId: () => ({}),
  });

export const useScheduleVisitModalContext = () => {
  const context = useContext(ScheduleVisitModalContext);
  return context;
};

export const ScheduleVisitModalProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isActionModalOpen, setIsActionModalOpen] = useState(false);
  const [actionType, setActionType] =
    useState<ScheduleVisitModalActionType | null>(null);
  const [eventId, setEventId] = useState<number | null>(null);
  const [isVisitModalOpen, setIsVisitModalOpen] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [clientId, setClientId] = useState<number | null>(null);
  const [activeTab, setActiveTab] = useState<MEETING_TYPE | null>(null);

  const handleCloseActionModal = () => {
    setActionType(null);
    setEventId(null);
    setStartDate(null);
    setClientId(null);
    setActiveTab(null);
    setIsActionModalOpen(false);
  };

  const handleOpenActionModal = () => {
    setIsActionModalOpen(true);
  };

  const handleSetActionType = (actionType: ScheduleVisitModalActionType) => {
    setActionType(actionType);
  };

  const handleSetEventId = (eventId: number) => {
    setEventId(eventId);
  };

  const handleVisitModalOpen = () => {
    setIsVisitModalOpen(true);
  };

  const handleVisitModalClose = () => {
    setEventId(null);
    setStartDate(null);
    setClientId(null);
    setActiveTab(null);
    setIsVisitModalOpen(false);
  };

  const handleSetStartDate = (startDate: Date) => {
    setStartDate(startDate);
  };

  const handleSetClientId = (clientId: number | undefined) => {
    setClientId(clientId ?? null);
  };

  const handleSetActiveTab = (activeTab: MEETING_TYPE) => {
    setActiveTab(activeTab);
  };

  return (
    <ScheduleVisitModalContext.Provider
      value={{
        isActionModalOpen,
        isVisitModalOpen,
        actionType,
        eventId,
        startDate,
        clientId,
        activeTab,
        handleSetActionType,
        handleSetEventId,
        handleSetStartDate,
        handleSetClientId,
        handleSetActiveTab,
        handleOpenActionModal,
        handleCloseActionModal,
        handleVisitModalOpen,
        handleVisitModalClose,
      }}
    >
      {children}
    </ScheduleVisitModalContext.Provider>
  );
};
