import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const DragHandle = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 22 24">
    <rect width="22" height="22" rx="2" fill="currentColor" opacity="0" />
    <path
      d="M165,1049.5a1.5,1.5,0,1,1,1.5,1.5A1.5,1.5,0,0,1,165,1049.5Zm-5,0a1.5,1.5,0,1,1,1.5,1.5A1.5,1.5,0,0,1,160,1049.5Zm5-5a1.5,1.5,0,1,1,1.5,1.5A1.5,1.5,0,0,1,165,1044.5Zm-5,0a1.5,1.5,0,1,1,1.5,1.5A1.5,1.5,0,0,1,160,1044.5Zm5-5a1.5,1.5,0,1,1,1.5,1.5A1.5,1.5,0,0,1,165,1039.5Zm-5,0a1.5,1.5,0,1,1,1.5,1.5A1.5,1.5,0,0,1,160,1039.5Z"
      transform="translate(-153 -1033)"
      fill="currentColor"
    />
  </SvgIcon>
);
