import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const ImageMonitoring = (props: SvgIconProps) => (
  <SvgIcon width="18" height="15" viewBox="0 0 18 15" {...props}>
    <path
      d="M15.1799 1.85931H2.82011C2.53043 1.85931 2.30512 2.11681 2.30512 2.3743V12.6741V12.7063L2.43387 12.5132L5.00882 8.90825C5.16976 8.68294 5.39506 8.55419 5.65256 8.55419C5.87787 8.55419 6.13536 8.68294 6.26411 8.87606L7.26191 10.2601L9.93342 6.8161C10.0622 6.62298 10.2875 6.49423 10.545 6.49423C10.7703 6.49423 10.9956 6.62298 11.1565 6.8161L15.5339 12.481L15.6949 12.6741V2.3743C15.6949 2.11681 15.4374 1.85931 15.1799 1.85931ZM2.82011 0.314341H15.1799C16.3064 0.314341 17.2399 1.24776 17.2399 2.3743V12.6741C17.2399 13.8328 16.3064 14.7341 15.1799 14.7341H2.82011C1.66138 14.7341 0.760147 13.8328 0.760147 12.6741V2.3743C0.760147 1.24776 1.66138 0.314341 2.82011 0.314341ZM5.39506 6.49423C4.8157 6.49423 4.3329 6.20455 4.04321 5.72174C3.75353 5.27113 3.75353 4.65958 4.04321 4.17677C4.3329 3.72615 4.8157 3.40429 5.39506 3.40429C5.94224 3.40429 6.42505 3.72615 6.71473 4.17677C7.00441 4.65958 7.00441 5.27113 6.71473 5.72174C6.42505 6.20455 5.94224 6.49423 5.39506 6.49423Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
