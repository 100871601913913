import { CircularArrows } from "@assets/icons/Arrows";
import {
  CrossedCircle,
  ThumbsDown,
  ThumbsUp,
} from "@assets/icons/DesignSystem";
import { SvgIconProps } from "@components/SvgIcon";

import { ChipStyled } from "./UserChips.styled";
import { ExclamationMarkInCircle } from "@assets/icons";
import { Tooltip } from "@mui/material";
import { useAppTranslation } from "@hooks";

interface UserChipsProps {
  tags: UserTagType[] | undefined;
}

export const UserChips = ({ tags }: UserChipsProps) => {
  const { t } = useAppTranslation();

  if (!tags || !tags.length) return null;

  return (
    <>
      {tags.map(tag => {
        const Icon = icons[tag];

        return (
          <Tooltip key={tag} title={t(`user_tags_tooltip.${tag}`)}>
            <div>
              <ChipStyled color={colors[tag]} label={<Icon />} />
            </div>
          </Tooltip>
        );
      })}
    </>
  );
};

type ChipColors =
  | "default"
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning";

export enum UserTagType {
  EXCHANGED = "exchanged",
  LIKED = "liked",
  DISLIKED = "disliked",
  ALLERGEN = "allergen",
  ALREADY_USED = "already_used",
}

const colors: Record<UserTagType, ChipColors> = {
  [UserTagType.EXCHANGED]: "info",
  [UserTagType.LIKED]: "success",
  [UserTagType.DISLIKED]: "warning",
  [UserTagType.ALLERGEN]: "error",
  [UserTagType.ALREADY_USED]: "info",
};

const icons: Record<UserTagType, (props: SvgIconProps) => JSX.Element> = {
  [UserTagType.EXCHANGED]: CircularArrows,
  [UserTagType.LIKED]: ThumbsUp,
  [UserTagType.DISLIKED]: ThumbsDown,
  [UserTagType.ALLERGEN]: CrossedCircle,
  [UserTagType.ALREADY_USED]: ExclamationMarkInCircle,
};

export const mapUserChips = (
  tagStatus: Partial<Record<UserTagType, boolean>>,
): UserTagType[] => {
  return Object.values(UserTagType)
    .filter(key => tagStatus[key as UserTagType] === true)
    .map(key => key as UserTagType);
};
