import { Button, styled } from "@mui/material";

export const EditBrandNameModalWrapper = styled("div")`
  display: grid;
  gap: 1.5rem;
  padding: 1.5rem 2rem;
  width: 28.125rem;
`;

export const HeaderStyled = styled("h2")`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: 1.5rem;
  line-height: 150%;
  letter-spacing: -0.022em;
  color: ${({ theme }) => theme.colors.gray500};
`;

export const StyledCancelBtn = styled(Button)`
  background-color: ${({ theme }) => theme.palette.grey[300]};
  color: currentColor;
  text-transform: none;
  font-weight: 600;
  &:hover {
    background-color: ${({ theme }) => theme.colors.cancelButtonHover};
  }
`;

export const StyledSubmitBtn = styled(Button)`
  text-transform: none;
  font-weight: 600;
  &:hover {
    background-color: ${({ theme }) => theme.colors.submitButtonHover};
  }
`;

export const ActionButtonsWrapper = styled("div")`
  display: flex;
  justify-self: center;
  gap: 0.5rem;
  align-items: center;
`;
