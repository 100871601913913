import { RecipeIngredientFormInput } from "@typeDefinitions/types";

export const NEW_INGREDIENT_DEFAULT_VALUE: RecipeIngredientFormInput = {
  id: 0,
  foodRecipeId: null,
  name: "",
  grams: 0,
  quantity: "0",
  weight: null,
  otherWeights: [],
  macrosRef: [],
  macros: [
    {
      id: 203,
      value: 0,
    },
    {
      id: 204,
      value: 0,
    },
    {
      id: 205,
      value: 0,
    },
    {
      id: 208,
      value: 0,
    },
  ],
};
