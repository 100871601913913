import { resetPassword, ResetPasswordPayload } from "@client";
import { useAppTranslation } from "@hooks";
import { useMutation } from "@tanstack/react-query";
import { MutationOptions } from "./types";

export function useResetPasswordMutation(options?: MutationOptions) {
  const { t } = useAppTranslation();

  return useMutation(
    (args: { url: string; data: ResetPasswordPayload }) =>
      resetPassword(args.url, args.data),
    {
      ...options,
    },
  );
}
