import {
  GridColDef,
  GridRenderCellParams,
  GridSelectionModel,
} from "@mui/x-data-grid";
import { FetchPatientProgramsSearchResponse } from "@client/patient/programs/fetchPatientProgramsSearch";
import NameColumn from "@views/dietician/PatientProgramsNew/Grid/NameColumn";
import DurationColumn from "@views/dietician/PatientProgramsNew/Grid/DurationColumn";
import PeriodColumn from "@views/dietician/PatientProgramsNew/Grid/PeriodColumn";
import StatusColumn from "@views/dietician/PatientProgramsNew/Grid/StatusColumn";
import ActionsColumn from "@views/dietician/PatientProgramsNew/Grid/ActionsColumn";
import { Dispatch, useMemo, useState } from "react";
import {
  CustomDataGrid,
  FlexDataGrid,
} from "@components/Alloweat/DataGrid/DataGrid";
import {
  CheckedMonitoring,
  UncheckedMonitoring,
} from "@assets/icons/Monitoring";
import { PatientProgramsGridStyled } from "@views/dietician/PatientProgramsNew/PatientProgramsGrid.styled";
import ProgramSearchPreviewDrawer from "@components/ProgramSearchPreview/ProgramSearchPreviewDrawer";
import PatientProgramSearchPreview from "@components/PatientProgramSearchPreview/PatientProgramSearchPreview";
import { useAppTranslation } from "@hooks";
import SharedColumn from "@views/dietician/PatientProgramsNew/Grid/SharedColumn";
import AddPatientProgramButton from "@views/dietician/PatientProgramsNew/AddPatientProgramDialog/AddPatientProgramButton";
import useNewMobileAppFeatureEnabled from "@features/useNewMobileAppFeatureEnabled";
import SharedColumnName from "@views/dietician/PatientProgramsNew/Grid/SharedColumnName";

type PatientProgramsGridType = {
  patientId: number;
  page: number;
  setPage: Dispatch<number>;
  perPage: number;
  setPerPage: Dispatch<number>;
  isFetching: boolean;
  programs: FetchPatientProgramsSearchResponse | undefined;
};
const PatientProgramsGrid = ({
  patientId,
  page,
  perPage,
  setPage,
  setPerPage,
  isFetching,
  programs,
}: PatientProgramsGridType) => {
  const { t } = useAppTranslation();
  const newMobileAppFeatureEnabled = useNewMobileAppFeatureEnabled();
  const [selectedProgramId, setSelectedProgramId] = useState<number | null>(
    null,
  );
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const columns: GridColDef[] = useMemo(() => {
    if (!newMobileAppFeatureEnabled) {
      return [
        {
          field: "name",
          flex: 5,
          headerName: t("patient.programs_grid.columns.name"),
          sortable: false,
          renderCell: (
            params: GridRenderCellParams<
              any,
              FetchPatientProgramsSearchResponse["data"][0]
            >,
          ) => {
            return <NameColumn program={params.row} />;
          },
          minWidth: 250,
        },
        {
          field: "duration",
          flex: 2,
          headerName: t("patient.programs_grid.columns.duration"),
          sortable: false,
          renderCell: (
            params: GridRenderCellParams<
              any,
              FetchPatientProgramsSearchResponse["data"][0]
            >,
          ) => {
            return <PeriodColumn program={params.row} />;
          },
          minWidth: 160,
        },
        {
          field: "length",
          flex: 1,
          headerName: t("patient.programs_grid.columns.length"),
          sortable: false,
          renderCell: (
            params: GridRenderCellParams<
              any,
              FetchPatientProgramsSearchResponse["data"][0]
            >,
          ) => {
            return <DurationColumn program={params.row} />;
          },
          minWidth: 100,
        },
        {
          field: "language",
          flex: 1,
          headerName: t("patient.programs_grid.columns.language"),
          sortable: false,
          renderCell: (
            params: GridRenderCellParams<
              any,
              FetchPatientProgramsSearchResponse["data"][0]
            >,
          ) => {
            return (
              <PatientProgramsGridStyled>
                {params.row.language.toUpperCase()}
              </PatientProgramsGridStyled>
            );
          },
          minWidth: 60,
        },
        {
          field: "isActive",
          flex: 1,
          align: "center",
          headerAlign: "center",
          headerName: t("patient.programs_grid.columns.active"),
          sortable: false,
          renderCell: (
            params: GridRenderCellParams<
              any,
              FetchPatientProgramsSearchResponse["data"][0]
            >,
          ) => {
            return params.row.isActive ? (
              <CheckedMonitoring fill="#3AB795" size="" />
            ) : (
              <UncheckedMonitoring fill="#FFFFFF" size="" />
            );
          },
          minWidth: 100,
        },
        {
          field: "actions",
          flex: 1,
          align: "right",
          headerAlign: "right",
          headerName: t("patient.programs_grid.columns.actions"),
          sortable: false,
          renderCell: (
            params: GridRenderCellParams<
              any,
              FetchPatientProgramsSearchResponse["data"][0]
            >,
          ) => {
            return <ActionsColumn program={params.row} patientId={patientId} />;
          },
          minWidth: 50,
        },
      ];
    }

    return [
      {
        field: "name",
        flex: 5,
        headerName: t("patient.programs_grid.columns.name"),
        sortable: false,
        renderCell: (
          params: GridRenderCellParams<
            any,
            FetchPatientProgramsSearchResponse["data"][0]
          >,
        ) => {
          return <NameColumn program={params.row} />;
        },
        minWidth: 250,
      },
      {
        field: "duration",
        flex: 2,
        headerName: t("patient.programs_grid.columns.duration"),
        sortable: false,
        renderCell: (
          params: GridRenderCellParams<
            any,
            FetchPatientProgramsSearchResponse["data"][0]
          >,
        ) => {
          return <PeriodColumn program={params.row} />;
        },
        minWidth: 160,
      },
      {
        field: "length",
        flex: 1,
        headerName: t("patient.programs_grid.columns.length"),
        sortable: false,
        renderCell: (
          params: GridRenderCellParams<
            any,
            FetchPatientProgramsSearchResponse["data"][0]
          >,
        ) => {
          return <DurationColumn program={params.row} />;
        },
        minWidth: 100,
      },
      {
        field: "language",
        flex: 1,
        headerName: t("patient.programs_grid.columns.language"),
        sortable: false,
        renderCell: (
          params: GridRenderCellParams<
            any,
            FetchPatientProgramsSearchResponse["data"][0]
          >,
        ) => {
          return (
            <PatientProgramsGridStyled>
              {params.row.language.toUpperCase()}
            </PatientProgramsGridStyled>
          );
        },
        minWidth: 60,
      },
      {
        field: "shared",
        flex: 2,
        align: "center",
        headerAlign: "center",
        renderHeader: () => <SharedColumnName />,
        sortable: false,
        renderCell: (
          params: GridRenderCellParams<
            any,
            FetchPatientProgramsSearchResponse["data"][0]
          >,
        ) => {
          return <SharedColumn program={params.row} patientId={patientId} />;
        },
        minWidth: 180,
      },
      {
        field: "status",
        flex: 2,
        headerName: t("patient.programs_grid.columns.status"),
        sortable: false,
        renderCell: (
          params: GridRenderCellParams<
            any,
            FetchPatientProgramsSearchResponse["data"][0]
          >,
        ) => {
          return <StatusColumn program={params.row} />;
        },
        minWidth: 160,
      },
      {
        field: "actions",
        flex: 1,
        align: "right",
        headerAlign: "right",
        headerName: t("patient.programs_grid.columns.actions"),
        sortable: false,
        renderCell: (
          params: GridRenderCellParams<
            any,
            FetchPatientProgramsSearchResponse["data"][0]
          >,
        ) => {
          return <ActionsColumn program={params.row} patientId={patientId} />;
        },
        minWidth: 50,
      },
    ];
  }, [t]);

  return (
    <>
      <AddPatientProgramButton patientId={patientId} />
      <FlexDataGrid>
        <CustomDataGrid
          rows={programs?.data ?? []}
          columns={columns}
          onPageChange={setPage}
          pageSize={perPage}
          page={page}
          rowCount={programs?.meta.total ?? 0}
          loading={isFetching}
          componentsProps={{
            pagination: { setPerPage, perPage, rowsPerPageOptions: [10, 25] },
          }}
          onRowClick={(params, e, details) => {
            setSelectedProgramId(parseInt(params.row.id.toString()));
          }}
          onSelectionModelChange={newSelectionModel => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
        />
      </FlexDataGrid>
      <ProgramSearchPreviewDrawer
        programId={selectedProgramId}
        onClose={() => {
          setSelectedProgramId(null);
          setSelectionModel([]);
        }}
      >
        {selectedProgramId !== null && (
          <PatientProgramSearchPreview
            programId={selectedProgramId}
            patientId={patientId}
          />
        )}
      </ProgramSearchPreviewDrawer>
    </>
  );
};

export default PatientProgramsGrid;
