import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import * as yup from "yup";

export const fetchClientLastMeasurement = async (
  id: string,
): Promise<ApiResponse<FetchClientLastMeasurementResponse | null>> => {
  const response = await fetchData(
    `/dietitian/patients/${id}/last-measurement`,
    APIMethods.GET,
  );
  return await fetchClientLastMeasurementSchema.validate(response);
};

const idSchema = yup.object().shape({
  id: yup.number().required(),
});

const mediaSchema = yup.object().shape({
  originalName: yup.string().required(),
});

const photoSchema = yup.object().shape({
  id: yup.number().required(),
  url: yup.string().required(),
  type: idSchema.required(),
  name: yup.string().nullable().default(null),
  media: mediaSchema.required(),
});

const clientLastMeasurementSchema = yup.object().shape({
  id: yup.string().required(),
  source: idSchema.required(),
  date: yup.date().nullable().default(null),
  growth: yup.number().nullable().default(null),
  weight: yup.number().nullable().default(null),
  bodyFatLevel: yup.number().nullable().default(null),
  arm: yup.number().nullable().default(null),
  tightBiceps: yup.number().nullable().default(null),
  waist: yup.number().nullable().default(null),
  hip: yup.number().nullable().default(null),
  thigh: yup.number().nullable().default(null),
  calf: yup.number().nullable().default(null),
  photos: yup.array().of(photoSchema).required(),
});

const fetchClientLastMeasurementSchema = yup.object().shape({
  data: clientLastMeasurementSchema.nullable().default(null),
});

export interface FetchClientLastMeasurementResponse {
  id: string;
  source: SourceDto;
  date: Date | null;
  growth: number | null;
  weight: number | null;
  bodyFatLevel: number | null;
  arm: number | null;
  tightBiceps: number | null;
  waist: number | null;
  hip: number | null;
  thigh: number | null;
  calf: number | null;
  photos: PhotoDto[];
}

interface SourceDto {
  id: number;
}
interface TypeDto {
  id: number;
}
interface MediaDto {
  originalName: string;
}

interface PhotoDto {
  id: number;
  url: string;
  type: TypeDto;
  name: string | null;
  media: MediaDto;
}
