import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MutationOptions } from "./types";
import { fetchProgramQueryKey } from "./useFetchProgramQuery";
import { cloneProgram, createProgram, CreateProgramResponseDto } from "@client";

export const useCreateProgramMutation = (
  options?: MutationOptions<CreateProgramResponseDto>,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    () =>
      createProgram({
        name: "",
        hasDiary: true,
        durationDays: null,
        showNutrients: [],
        sections: [],
      }),
    {
      ...options,
      onSuccess: data => {
        queryClient.invalidateQueries([fetchProgramQueryKey, data?.id]);
        options?.onSuccess && options.onSuccess(data);
      },
    },
  );
};

export const useCloneProgramMutation = (
  options?: MutationOptions<CreateProgramResponseDto>,
) => {
  const queryClient = useQueryClient();

  return useMutation((id: number) => cloneProgram(id), {
    ...options,
    onSuccess: data => {
      queryClient.invalidateQueries([fetchProgramQueryKey, data?.id]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};
