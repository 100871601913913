import { IdDto, idSchema } from "@client/common";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import * as yup from "yup";

export const postSurveyClone = async (
  id: string,
): Promise<ApiResponse<IdDto>> => {
  const response = await fetchData(
    `/dietitian/surveys/${id}/clone`,
    APIMethods.POST,
  );

  return postSurveyResponseCloneSchema.validate(response);
};

const postSurveyResponseCloneSchema = yup.object().shape({
  data: idSchema.required(),
});
