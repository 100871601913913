import { useCallback, useMemo } from "react";

import { useAppTranslation } from "@hooks";
import { useFetchClientTargetsQuery } from "./queries";
import { clientDictionariesMapper } from "@utils/clients";

export const useClientTargets = () => {
  const { targets, isSuccess } = useFetchClientTargetsQuery();
  const { i18n } = useAppTranslation();

  const targetsDict = useMemo(() => {
    if (isSuccess && targets) return clientDictionariesMapper(targets);
    return;
  }, [targets]);

  const getTargetName = useCallback(
    (id: number) => {
      if (
        targetsDict &&
        targetsDict[id] &&
        targetsDict[id].names[i18n.language]
      ) {
        return targetsDict[id].names[i18n.language];
      }
      return "";
    },
    [targetsDict, i18n.language],
  );

  return { getTargetName };
};
