import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const cloneScheduleDay = (
  programId: number,
  payload: CloneScheduleDayRequest,
) => {
  return fetchData(
    `/dietitian/programs/${programId}/schedule/days/clone-day`,
    APIMethods.PUT,
    payload,
  );
};

export interface CloneScheduleDayRequest {
  programScheduleDay: DayDto;
  daysToReplace: DayDto[];
}

interface DayDto {
  id: number;
}
