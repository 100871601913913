import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const NotAllowed = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="14" viewBox="0 0 14 14" {...props}>
      <path
        d="M10.0406 11.2793L2.7207 3.95938C2.1082 4.81523 1.75 5.86523 1.75 7C1.75 9.89844 4.10156 12.25 7 12.25C8.13477 12.25 9.18477 11.8918 10.0406 11.2793ZM11.2793 10.0406C11.8918 9.18477 12.25 8.13477 12.25 7C12.25 4.10156 9.89844 1.75 7 1.75C5.86523 1.75 4.81523 2.1082 3.95938 2.7207L11.2793 10.0406ZM0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7C14 8.85652 13.2625 10.637 11.9497 11.9497C10.637 13.2625 8.85652 14 7 14C5.14348 14 3.36301 13.2625 2.05025 11.9497C0.737498 10.637 0 8.85652 0 7Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
