import { memo } from "react";

import { useAppTranslation } from "@hooks";

/**
 * Component showing error in a view
 */
export const ViewError = memo(() => {
  const { t } = useAppTranslation();

  return (
    <div className="w-full h-screen flex justify-center items-center bg-transparent">
      <p className="text-2xl font-sans text-gray-700">{t("errors.view")}</p>
    </div>
  );
});
