import * as yup from "yup";
import {
  ProgramPreviewSearchResource,
  programPreviewSearchSchema,
} from "@client/resources/ProgramPreviewSearchResource";

export interface PatientProgramPreviewSearchResource
  extends ProgramPreviewSearchResource {
  shared: boolean;
  isActive: boolean; //todo remove
  startDate: string;
  endDate: string | null;
}

export const patientProgramSearchPreviewSchema =
  programPreviewSearchSchema.shape({
    shared: yup.boolean().required(),
    isActive: yup.boolean().required(),
    startDate: yup.string().required(),
    endDate: yup.string().nullable().defined(),
  });
