import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import { NutrientsMonitoringResource } from "@client/resources/NutrientsMonitoringResource";
import NutrientsValuesSectionByDay from "@views/dietician/PatientMonitoring2/components/NutrientValues/NutrientsValuesSectionByDay";
import NutrientsValuesSectionByDays from "@views/dietician/PatientMonitoring2/components/NutrientValues/NutrientsValuesSectionByDays";

type NutrientsValuesSectionProps = {
  data: NutrientsMonitoringResource[];
};

const NutrientsValuesSectionFactory = ({
  data,
}: NutrientsValuesSectionProps) => {
  const { type } = useMonitoring();

  return type.type === "day" ? (
    <NutrientsValuesSectionByDay data={data} />
  ) : (
    <NutrientsValuesSectionByDays data={data} />
  );
};

export default NutrientsValuesSectionFactory;
