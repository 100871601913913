import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "./types";
import { fetchRecipeQueryKey } from "./useFetchRecipeQuery";
import {
  CreateRecipeRequest,
  requestCreateRecipe,
  requestUpdateRecipe,
} from "@client";
import { searchRecipesInfiniteQueryKey } from "./useSearchRecipesQueryNew";

export const useCreateRecipeMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: { payload: CreateRecipeRequest }) =>
      requestCreateRecipe(data.payload),
    {
      ...options,
      onSuccess: data => {
        queryClient.invalidateQueries([fetchRecipeQueryKey]);
        queryClient.invalidateQueries([searchRecipesInfiniteQueryKey]);
        options?.onSuccess && options.onSuccess(data.data);
      },
    },
  );
};

export const useUpdateRecipeMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: { id: number; payload: CreateRecipeRequest }) =>
      requestUpdateRecipe(data.id, data.payload),
    {
      ...options,
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries([fetchRecipeQueryKey, variables?.id]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};
