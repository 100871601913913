import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

import {
  ActionsDto,
  ClinicIdDto,
  ImageDto,
  LangDto,
  NutrientDto,
  actionsSchema,
  clinicIdSchema,
  imageSchema,
  nutrientSchema,
} from "../common";

export const searchDietsPreviewDiet = async (
  dietId: string,
): Promise<ApiResponse<DietSearchPreviewResourceDto>> => {
  const data = await fetchData(
    `/dietitian/diets/search-preview/${dietId}`,
    APIMethods.GET,
  );
  return apiResponseSchema.validate(data);
};

export interface DietSearchPreviewResourceDto {
  id: string;
  clinic: ClinicIdDto;
  name: string;
  translations: TranslationDto[];
  nutrients: NutrientDto[];
  tags: TagResourceDto[];
  meals: MealDto[];
  favorite: number[];
  actions: Omit<ActionsDto, "canVisible">;
  image: ImageDto | null;
}

export interface TagResourceDto {
  id: number;
  systemId: number | null;
  clinicId: number | null;
  namePl: string;
  nameEn: string;
  tagCategoryId: number;
}

export interface TranslationDto {
  lang: LangDto;
  name: string | null;
}

export interface MealDto {
  id: number;
  name: string;
  translations: TranslationDto[];
  itemsCount: number;
}

export const tagResourceSchema = yup.object().shape({
  id: yup.number().required(),
  systemId: yup.number().nullable().default(null),
  clinicId: yup.number().nullable().default(null),
  namePl: yup.string().required(),
  nameEn: yup.string().required(),
  tagCategoryId: yup.number().required(),
});

export const translationSchema = yup.object().shape({
  lang: yup.mixed<LangDto>().oneOf(Object.values(LangDto)).required(),
  name: yup.string().nullable().default(null),
});

const mealSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  translations: yup.array().of(translationSchema.required()).required(),
  itemsCount: yup.number().required(),
});

export const dietSearchPreviewResourceSchema = yup.object().shape({
  id: yup.string().required(),
  clinic: clinicIdSchema.required(),
  name: yup.string().required(),
  translations: yup.array().of(translationSchema.required()).required(),
  nutrients: yup.array().of(nutrientSchema.required()).required(),
  tags: yup.array().of(tagResourceSchema.required()).required(),
  meals: yup.array().of(mealSchema.required()).required(),
  favorite: yup.array().of(yup.number().required()).required(),
  actions: actionsSchema.omit(["canVisible"]).required(),
  image: imageSchema.nullable().default(null),
});

const apiResponseSchema = yup.object().shape({
  data: dietSearchPreviewResourceSchema.required(),
});
