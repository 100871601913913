import { useEffect, useMemo, useState } from "react";

import { useInfiniteQuery, useQuery } from "@tanstack/react-query";

import { DietSearchResourceDto, searchDietsNew } from "@client/diets";
import {
  DEFAULT_DIETS_MACROS_RANGE,
  DietsFiltersFormInput,
  convertSearchParamsToObj,
} from "@components/DietsFilters";
import { ListTabs, usePagination } from "@hooks";
import { ApiResponse } from "@typeDefinitions";
import {
  parseNumber,
  parseNutrientsList,
  parseString,
  parseStringArrayNew,
  parseTagsList,
} from "@utils/filters";
import { omit } from "lodash";
import { useSearchParams } from "react-router-dom";
import { QueryOptionsTyped } from "./types";

const parseTabName = (input: ListTabs | undefined, params: URLSearchParams) => {
  if (input === undefined || input === ListTabs.ALL) return;
  if (input === ListTabs.LATEST) params.append(ListTabs.LATEST, "1");
  if (input === ListTabs.FAVORITE) params.append(ListTabs.FAVORITE, "1");
  if (input === ListTabs.OWN) params.append("owner", ListTabs.OWN);
  if (input === ListTabs.UNVERIFIED) params.append("reviewed", "0");
};

export const parseDietsFilters = (filters?: DietsFiltersFormInput) => {
  const output = new URLSearchParams();

  parseTagsList(filters?.tags, output);
  parseNutrientsList(filters?.nutrients, output);
  parseTabName(filters?.tabs, output);
  parseString(filters?.query || undefined, "query", output);
  parseNumber(filters?.page, "page", output);
  parseNumber(filters?.perPage, "perPage", output);
  parseStringArrayNew(filters?.numberOfMeals, "numberOfMeals", output);

  return output;
};

export const searchDietsQueryKeyNew = "searchDietsQueryKeyNew";

export function useSearchDietsQueryNew(
  options?: QueryOptionsTyped<ApiResponse<DietSearchResourceDto[]>>,
) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [committedInputs, setCommittedInputs] = useState<DietsFiltersFormInput>(
    omitDefaultNutrients(convertSearchParamsToObj(searchParams)),
  );
  const params = useMemo(
    () => parseDietsFilters(committedInputs),
    [committedInputs],
  );

  useEffect(() => {
    setSearchParams(params, { replace: true });
  }, [params]);

  const { handlePaginationClick, paginatedParams } = usePagination(params);
  const { data, ...rest } = useQuery(
    [searchDietsQueryKeyNew, paginatedParams.toString()],
    () => searchDietsNew(paginatedParams),
    options,
  );

  return {
    diets: data?.data,
    links: data?.meta?.links,
    submit: setCommittedInputs,
    ...rest,
    handlePaginationClick,
    meta: data?.meta,
  };
}

const omitDefaultNutrients = (data: DietsFiltersFormInput) => {
  const nutrientsWithNonDefaultValues = Object.entries(
    data.nutrients || {},
  ).reduce((acc, [key, value]) => {
    const defaultValue = DEFAULT_DIETS_MACROS_RANGE[key];
    if (
      !defaultValue ||
      defaultValue[0] !== value[0] ||
      defaultValue[1] !== value[1]
    )
      acc[key] = value;

    return acc;
  }, {} as { [id: string]: [number, number] });

  return {
    ...omit(data, "nutrients"),
    nutrients: nutrientsWithNonDefaultValues,
  };
};

export const searchDietsInfiniteQueryKey = "searchDietsInfiniteQueryKey";

export const useSearchDietsInfiniteQuery = (
  defaultInputs: DietsFiltersFormInput | undefined = {},
  options?: QueryOptionsTyped<ApiResponse<DietSearchResourceDto[]>>,
) => {
  const [committedInputs, setCommittedInputs] = useState<
    DietsFiltersFormInput | undefined
  >(defaultInputs);
  const params = useMemo(
    () => parseDietsFilters(omitDefaultNutrients(committedInputs ?? {})),
    [committedInputs],
  );

  const fetchDiets = ({ pageParam = 1 }) => {
    const paginatedParams = new URLSearchParams(params);
    paginatedParams.set("page", pageParam.toString());
    return searchDietsNew(paginatedParams);
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    ...rest
  } = useInfiniteQuery(
    [searchDietsInfiniteQueryKey, params.toString()],
    fetchDiets,
    {
      getNextPageParam: ({ meta }) => {
        if (!meta) return;
        const { currentPage, total, perPage } = meta;
        const nextPage = currentPage + 1;
        return nextPage <= Math.ceil(total / (perPage || 1))
          ? nextPage
          : undefined;
      },
      enabled: options?.enabled,
      keepPreviousData: options?.keepPreviousData,
      refetchOnMount: options?.refetchOnMount,
    },
  );

  const diets = data?.pages.flatMap(page => page.data) || [];
  const lastMeta = data ? data.pages[data.pages.length - 1].meta : undefined;

  return {
    diets,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    submit: setCommittedInputs,
    meta: lastMeta,
    ...rest,
  };
};
