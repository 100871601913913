import { ClientContextDto } from "@client/diets/creator";

export const getClientContextSummary = (
  ids: number[],
  contextMap: Map<number, ClientContextDto>,
): ContextSummary => {
  return ids.reduce(
    (summary: ContextSummary, id: number) => {
      const clientContext = contextMap.get(id);
      if (!clientContext) return summary;

      if (clientContext.hasLiked) summary.hasLiked.push(id);
      if (clientContext.hasUnliked) summary.hasUnliked.push(id);
      if (clientContext.hasAllergens) summary.hasAllergens.push(id);

      return summary;
    },
    { hasLiked: [], hasUnliked: [], hasAllergens: [] },
  );
};

export interface ContextSummary {
  hasLiked: number[];
  hasUnliked: number[];
  hasAllergens: number[];
}
