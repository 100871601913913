import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  DietMealListItemCreatorDto,
  deleteCreatorDietMeal,
} from "@client/diets/creator";
import { MutationOptions } from "@hooks/queries/types";
import { useCreatorVersion } from "./useCreatorVersion";
import { fetchCreatorDietMealsQueryKey } from "./useFetchCreatorDietMealsQuery";
import { fetchCreatorDietQueryKey } from "./useFetchCreatorDietQuery";
import { searchDietsQueryKeyNew } from "@hooks/queries/useSearchDietsQueryNew";
import { ApiResponse } from "@typeDefinitions";
import { deleteDietMeal } from "./optimisticUpdateUtils";
import { fetchCreatorDietNutrientsQueryKey } from "./useFetchCreatorDietNutrientsQuery";

export const useDeleteCreatorDietMealMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();
  const { version } = useCreatorVersion();

  return useMutation<
    unknown,
    unknown,
    UseDeleteCreatorDietMealMutationIf,
    MutationContextIf
  >(({ dietId, mealId }) => deleteCreatorDietMeal(dietId, mealId, version), {
    ...options,
    onSuccess: (d, v) => {
      options?.onSuccess && options.onSuccess(d, v);
      queryClient.invalidateQueries([fetchCreatorDietQueryKey, v.dietId]);
      queryClient.invalidateQueries([searchDietsQueryKeyNew]);
      queryClient.invalidateQueries([
        fetchCreatorDietNutrientsQueryKey,
        v.dietId,
      ]);
    },
    onMutate: async ({ dietId, mealId }) => {
      await queryClient.cancelQueries([fetchCreatorDietMealsQueryKey, dietId]);
      const previousData = queryClient.getQueryData<
        ApiResponse<DietMealListItemCreatorDto[]>
      >([fetchCreatorDietMealsQueryKey, dietId]);

      const hasMeal = previousData?.data.some(
        meal => meal.id.toString() === mealId,
      );

      if (!hasMeal) return { previousData, invalidate: true };

      queryClient.setQueryData<ApiResponse<DietMealListItemCreatorDto[]>>(
        [fetchCreatorDietMealsQueryKey, dietId],
        oldData => {
          if (!oldData) return previousData;

          return deleteDietMeal(oldData, mealId);
        },
      );
    },
    onSettled: (d, e, v, context) => {
      if (context?.invalidate) {
        queryClient.invalidateQueries([
          fetchCreatorDietMealsQueryKey,
          v.dietId,
        ]);
      }
    },
    onError: (_, { dietId }) => {
      queryClient.invalidateQueries([fetchCreatorDietMealsQueryKey, dietId]);
    },
  });
};

interface UseDeleteCreatorDietMealMutationIf {
  dietId: string;
  mealId: string;
}
interface MutationContextIf {
  previousData?: ApiResponse<DietMealListItemCreatorDto[]>;
  invalidate?: boolean;
}
