import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const BlueArrow = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 22 22">
    <path
      d="M8.362,2.14a1.307,1.307,0,0,0-2.525.676l.339,1.263A1.307,1.307,0,0,0,8.7,3.4Zm-5.546,3.7A1.307,1.307,0,0,0,2.14,8.362L3.4,8.7A1.308,1.308,0,0,0,4.08,6.174Zm11.52-.744a1.307,1.307,0,1,0-1.848-1.848l-.924.924a1.307,1.307,0,0,0,1.848,1.848ZM5.094,14.336l.924-.924A1.307,1.307,0,0,0,4.17,11.563l-.925.924a1.307,1.307,0,0,0,1.85,1.848ZM9.277,7.576a1.308,1.308,0,0,0-1.7,1.7l5.229,13.071a1.307,1.307,0,0,0,2.383.1l1.8-3.607,3.946,3.946a1.307,1.307,0,0,0,1.848-1.848l-3.945-3.948,3.605-1.8a1.308,1.308,0,0,0-.1-2.384Z"
      transform="translate(-1.17 -1.17)"
      fill="#67a9fa"
    />
  </SvgIcon>
);
