import { Tooltip } from "@mui/material";

import { useAppTranslation } from "@hooks";

import { ArrowCircledCheck } from "@assets/icons";

interface FoodFromIf {
  id: number;
  description: string;
  descriptionEn?: string;
}

interface SwappedProductDotProps {
  previousFood?: FoodFromIf;
  title?: string;
}

export const SwappedProductDot = ({
  previousFood,
  title,
}: SwappedProductDotProps) => {
  const { t, isPolishChosen } = useAppTranslation();

  return (
    <Tooltip
      title={
        title ??
        `${t("products.substitute_for_info")}: ${
          isPolishChosen
            ? previousFood?.description
            : previousFood?.descriptionEn || previousFood?.description
        }`
      }
    >
      <span className="p-1">
        <ArrowCircledCheck size="w-5 h-5" />
      </span>
    </Tooltip>
  );
};
