import { useEffect } from "react";
import { useForm } from "react-hook-form";

import * as yup from "yup";

import { useAppTranslation } from "@hooks";
import { DetailedSection } from "@typeDefinitions";
import { DietitianProgramSectionFormInput } from "@typeDefinitions/types";
import { yupResolver } from "@hookform/resolvers/yup";

export function useProgramSectionForm(defaultSection?: DetailedSection) {
  const { t } = useAppTranslation();
  const form = useForm<DietitianProgramSectionFormInput>({
    defaultValues: {
      language: "PL",
    },
    resolver: yupResolver(
      yup.object().shape({
        namePl: yup.string().required(t("sections.name_required")),
        nameEn: yup.string().nullable(),
        contentPl: yup.string().required(t("sections.content_required")),
        contentEn: yup.string().nullable(),
      }),
    ),
  });

  useEffect(() => {
    if (defaultSection) {
      form.reset({
        namePl: defaultSection.namePl,
        nameEn: defaultSection.nameEn ?? "",
        language: "PL",
        contentPl: defaultSection.contentPl ?? "",
        contentEn: defaultSection.contentEn ?? "",
      });
    }
  }, [defaultSection]);

  return form;
}
