type UrlParams = Record<string, string | string[]>;
type UseParseUrlParamsProps = [UrlParams, number];

export const useParseUrlParams = (url: string): UseParseUrlParamsProps => {
  const arrayCharacters = "%5B%5D";
  const params: UrlParams = {};
  const urlParts = url.split("?");

  if (urlParts.length > 1) {
    const queryString = urlParts[1];
    const pairs = queryString.split("&");

    pairs.forEach(pair => {
      const [key, value] = pair.split("=");
      const decodedValue = decodeURIComponent(value).replace("+", " ");

      if (key && decodedValue) {
        if (key.includes(arrayCharacters)) {
          const fixedKey = key.replace(arrayCharacters, "");
          if (Array.isArray(params[fixedKey])) {
            params[fixedKey] = [
              ...(params[fixedKey] as string[]),
              decodedValue,
            ];
          } else {
            params[fixedKey] = [decodedValue];
          }
        } else {
          params[key] = decodedValue;
        }
      }
    });
  }

  return [params, Object.keys(params).length];
};
