import { styled } from "@mui/material";

export const Title = styled("span")`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const KcalText = styled("span")`
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const MacrosText = styled("span")`
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;

export const NutrientsTextWrapper = styled("div")`
  display: flex;
  gap: 6px;
  align-items: center;
`;

export const NutrientsStringWrapper = styled("div")`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const DetailsWrapper = styled("div")`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const TagIconWrapper = styled("div")`
  display: flex;
  gap: 6px;
  align-items: center;
`;

export const Label = styled("span")`
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
`;

export const CellWrapper = styled("div")`
  display: grid;
  gap: 4px;
  width: 100%;
  padding: 0 16px;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
`;
