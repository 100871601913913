import {
  MacroChip,
  MacroName,
  MacroValue,
  MacroWrapper,
} from "@views/dietician/PatientMonitoring2/components/MealsBox/Client/MealItem.styled";

type MacroProps = {
  label: string;
  value: number;
};

const Macro = ({ label, value }: MacroProps) => {
  return (
    <MacroWrapper>
      <MacroChip label={<MacroName>{label}</MacroName>} />
      <MacroValue>{value}</MacroValue>
    </MacroWrapper>
  );
};

export default Macro;
