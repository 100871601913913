import { useCallback, useMemo } from "react";

import { useAppTranslation } from "@hooks";
import { useFetchClientAdditionalFeaturesQuery } from "./queries";
import { clientDictionariesMapper } from "@utils/clients";

export const useClientAdditionalFeatures = () => {
  const { additionalFeatures, isSuccess } =
    useFetchClientAdditionalFeaturesQuery();
  const { i18n } = useAppTranslation();

  const additionalFeaturesDict = useMemo(() => {
    if (isSuccess && additionalFeatures)
      return clientDictionariesMapper(additionalFeatures);
    return;
  }, [additionalFeatures]);

  const getAdditionalFeatureName = useCallback(
    (id: number) => {
      if (
        additionalFeaturesDict &&
        additionalFeaturesDict[id] &&
        additionalFeaturesDict[id].names[i18n.language]
      ) {
        return additionalFeaturesDict[id].names[i18n.language];
      }
      return "";
    },
    [additionalFeaturesDict, i18n.language],
  );

  return { getAdditionalFeatureName };
};
