import { useEffect, useMemo, useState } from "react";

import { TabContext } from "@mui/lab";

import { TabSwitch } from "@components/TabSwitch/TabSwitch";
import { useVisibleNutrients } from "@context/VisibleNutrientsContext";
import { useAppTranslation, useNutrients } from "@hooks";
import {
  categoriesNutrientsFetchQueryKey,
  useCategoriesNutrientsFetchQuery,
} from "@hooks/queries/dictionaries";
import { Nutrient } from "@typeDefinitions";
import {
  ENERGY_CATEGORY_ID,
  MACROELEMENTS_CATEGORY_ID,
  buildNutrientsDict,
  getNutrientValue,
} from "@utils";

import { round } from "lodash";
import { NutrientRow, NutrientRowProps } from "./NutrientRow";
import { TabPanelStyled } from "./NutrientRow.styled";
import { useQueryClient } from "@tanstack/react-query";
import { CategoryNutrientsResponse } from "@typeDefinitions/responses/dictionaries";

interface NutrientsProps {
  values: Nutrient[];
  targets?: Nutrient[];
  importantNutrients?: number[];
  NutrientRowComponent?: ({ id, value }: NutrientRowProps) => JSX.Element;
}

export const Nutrients = ({
  values,
  targets,
  importantNutrients,
  NutrientRowComponent = NutrientRow,
}: NutrientsProps) => {
  const { isPolishChosen, t } = useAppTranslation();
  const { nutrients: visibleNutrients } = useVisibleNutrients();
  const queryClient = useQueryClient();
  const cacheData = queryClient.getQueryData<CategoryNutrientsResponse[]>([
    categoriesNutrientsFetchQueryKey,
  ]);
  const { data } = useCategoriesNutrientsFetchQuery({ enabled: !cacheData });
  const categories = cacheData ?? data;
  const { categoryDict, nutrientDict } = useNutrients();

  const valuesDict = useMemo(
    () => buildNutrientsDict(values),
    [values, buildNutrientsDict],
  );

  const [selectedTab, setSelectedTab] = useState(() => {
    return visibleNutrients.length ? FAVORITE_TAB_ID : "";
  });
  const categoriesToHide = [MACROELEMENTS_CATEGORY_ID, ENERGY_CATEGORY_ID];

  const mappedCategoriesArray = useMemo(
    () =>
      categories
        ?.filter(c => !categoriesToHide.includes(c.id))
        .map(c => ({
          id: c.id.toString(),
          label: isPolishChosen ? c.namePl : c.nameEn,
        })) ?? [],
    [categories, isPolishChosen],
  );

  const withFavorites = useMemo(() => {
    const tabs = [];
    if (importantNutrients?.length) {
      tabs.push({ id: IMPORTANT_TAB_ID, label: t("diet.selected_values") });
    }
    if (visibleNutrients.length > 0) {
      tabs.push({ id: FAVORITE_TAB_ID, label: t("common.favourite") });
    }
    return [...tabs, ...mappedCategoriesArray];
  }, [mappedCategoriesArray, t, visibleNutrients]);

  useEffect(() => {
    if (importantNutrients?.length) return setSelectedTab(IMPORTANT_TAB_ID);
    if (visibleNutrients.length > 0) return setSelectedTab(FAVORITE_TAB_ID);
    return setSelectedTab(mappedCategoriesArray[0]?.id ?? "");
  }, [visibleNutrients, mappedCategoriesArray, importantNutrients]);

  const selectedTabValue = useMemo(() => {
    if (withFavorites.find(i => i.id === selectedTab) !== undefined) {
      return selectedTab;
    }

    return mappedCategoriesArray[0]?.id ?? "";
  }, [selectedTab, withFavorites]);

  return (
    <TabContext value={selectedTabValue}>
      <div className="flex flex-col gap-[8px]">
        <div className="w-full place-self-start">
          <TabSwitch
            tabs={withFavorites}
            value={selectedTabValue}
            onChange={setSelectedTab}
            variant="scrollable"
          />
        </div>
        {!!importantNutrients?.length && (
          <TabPanelStyled value={IMPORTANT_TAB_ID}>
            <div className="grid gap-[8px]">
              {importantNutrients.map(nutrient => (
                <NutrientRowComponent
                  key={nutrient}
                  id={nutrient}
                  value={round(
                    valuesDict.get(nutrient) ?? 0,
                    nutrientDict.get(nutrient)?.roundPrecision,
                  )}
                  target={
                    targets === undefined
                      ? undefined
                      : getNutrientValue(targets, nutrient)
                  }
                />
              ))}
            </div>
          </TabPanelStyled>
        )}
        {visibleNutrients.length > 0 && (
          <TabPanelStyled value={FAVORITE_TAB_ID}>
            <div className="grid gap-[8px]">
              {visibleNutrients.map(nutrient => (
                <NutrientRowComponent
                  key={nutrient}
                  id={Number(nutrient)}
                  value={round(
                    valuesDict.get(Number(nutrient)) ?? 0,
                    nutrientDict.get(Number(nutrient))?.roundPrecision,
                  )}
                  target={
                    targets === undefined
                      ? undefined
                      : getNutrientValue(targets, Number(nutrient))
                  }
                />
              ))}
            </div>
          </TabPanelStyled>
        )}
        {mappedCategoriesArray.map(category => (
          <TabPanelStyled value={category.id} key={category.id}>
            <div className="grid gap-[8px]">
              {categoryDict.get(Number(category.id))?.map(nutrient => (
                <NutrientRowComponent
                  key={nutrient.id}
                  id={nutrient.id}
                  value={round(
                    valuesDict.get(nutrient.id) ?? 0,
                    nutrient.roundPrecision,
                  )}
                  target={
                    targets === undefined
                      ? undefined
                      : getNutrientValue(targets, nutrient.id)
                  }
                />
              ))}
            </div>
          </TabPanelStyled>
        ))}
      </div>
    </TabContext>
  );
};

const FAVORITE_TAB_ID = "favorite";
const IMPORTANT_TAB_ID = "important";
