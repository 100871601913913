import { useMemo } from "react";
import dayjs from "dayjs";

const useProgramDate = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string | null;
}) => {
  const today = useMemo(() => dayjs().startOf("day"), []);
  const start = useMemo(
    () => dayjs(startDate, "YYYY-MM-DD").startOf("day"),
    [startDate],
  );
  const end = useMemo(() => {
    if (!endDate) {
      return null;
    }
    return dayjs(endDate, "YYYY-MM-DD").endOf("day");
  }, [endDate]);

  const isFinished = useMemo(() => {
    if (end === null) {
      return false;
    }

    return end.isBefore(today);
  }, [end]);

  const isPlanned = useMemo(() => start.isAfter(today), [start]);

  const isActive = useMemo(() => {
    if (start.isAfter(today)) {
      return false;
    }

    if (!end) {
      return true;
    }

    return end.isAfter(today);
  }, [end]);

  const durationDays = useMemo(() => {
    if (!end) {
      return null;
    }

    return Math.abs(Math.round(start.diff(end, "day", true)));
  }, [start, end]);

  return {
    start,
    end,
    isActive,
    isFinished,
    isPlanned,
    durationDays,
  };
};

export default useProgramDate;
