import { FetchClientResponse } from "@client";
import dayjs from "dayjs";

export const getAge = (client: FetchClientResponse) => {
  const birthday = client.profile.birthDate;
  if (!birthday) {
    return null;
  }

  return dayjs().diff(dayjs(birthday, "YYYY-MM-DD"), "years");
};
