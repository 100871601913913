import React, { useCallback, useState } from "react";
import { TabContext } from "@mui/lab";
import { TabList } from "@components/Alloweat/Tabs/TabList";
import PatientNutrientsTabPanel from "@components/Nutrients2/PatientNutrientsTabPanel";
import DietitianNutrientsTabPanel from "@components/Nutrients2/DietitianNutrientsTabPanel";
import BaseNutrientsTabsPanel2 from "@components/Nutrients2/BaseNutrientsTabsPanel2";
import useFetchPatientImportantNutrients from "@hooks/queries/client/nutrient/useFetchPatientImportantNutrients";
import { useVisibleNutrients } from "@context/VisibleNutrientsContext";
import useNutrientsCategories from "@components/Nutrients2/useNutrientsCategories";
import { PatientImportantNutrientResource } from "@client/resources/PatientImportantNutrientResource";
import { CategoryNutrientsResponse } from "@typeDefinitions/responses/dictionaries";
import { useAppTranslation } from "@hooks";
import { getCategoryName } from "@utils/dictionaries/categoriesNutrientsDictionaryUtils";
import { NutrientValueType } from "@components/Nutrients2/Types";
import { NutrientTabs } from "@components/Nutrients2/NutrientTab";
import { Box, Tab } from "@mui/material";

export const PatientNutrientTabValue = "patient";
export const DietitianNutrientTabValue = "dietitian";

type Props = {
  patientId: number;
  values: NutrientValueType[];
};

const PatientNutrientsTabs = ({ patientId, values }: Props) => {
  const { data: patientImportantNutrients } = useFetchPatientImportantNutrients(
    { patientId: patientId },
  );
  const { nutrients: dietitianFavoriteNutrients } = useVisibleNutrients();
  const nutrientsCategories = useNutrientsCategories();

  if (!patientImportantNutrients?.data.length && !nutrientsCategories.length) {
    return null;
  }

  return (
    <PatientNutrientsTabsWrapped
      patientId={patientId}
      values={values}
      patientImportantNutrients={patientImportantNutrients?.data ?? []}
      dietitianFavoriteNutrients={dietitianFavoriteNutrients}
      nutrientsCategories={nutrientsCategories}
    />
  );
};

type PropsWrapped = Props & {
  patientImportantNutrients: PatientImportantNutrientResource[];
  dietitianFavoriteNutrients: string[];
  nutrientsCategories: CategoryNutrientsResponse[];
};

const PatientNutrientsTabsWrapped = ({
  patientId,
  values,
  patientImportantNutrients,
  dietitianFavoriteNutrients,
  nutrientsCategories,
}: PropsWrapped) => {
  const { t, currentLanguage } = useAppTranslation();
  const [selected, setSelected] = useState<string>(() => {
    if (patientImportantNutrients.length) {
      return PatientNutrientTabValue;
    }
    if (dietitianFavoriteNutrients.length) {
      return DietitianNutrientTabValue;
    }
    if (!nutrientsCategories.length) {
      return "";
    }
    return nutrientsCategories[0].id.toString();
  });

  const handleChange = useCallback((_: any, newValue: string) => {
    setSelected(newValue);
  }, []);

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <TabContext value={selected}>
        <NutrientTabs
          onChange={handleChange}
          scrollButtons={"auto"}
          variant="scrollable"
        >
          {!!patientImportantNutrients.length && (
            <Tab label={t("common.keys")} value={PatientNutrientTabValue} />
          )}
          {!!dietitianFavoriteNutrients.length && (
            <Tab
              label={t("common.favourite")}
              value={DietitianNutrientTabValue}
            />
          )}
          {nutrientsCategories.map(category => (
            <Tab
              key={category.id.toString()}
              label={getCategoryName(category, currentLanguage)}
              value={category.id.toString()}
            />
          ))}
        </NutrientTabs>
        <PatientNutrientsTabPanel patientId={patientId} values={values} />
        <DietitianNutrientsTabPanel values={values} />
        <BaseNutrientsTabsPanel2 values={values} />
      </TabContext>
    </Box>
  );
};

export default PatientNutrientsTabs;
