import { ReactNode, useEffect, useMemo, useState } from "react";
import {
  AutosaveOnBlurContext,
  useAutosaveOnBlurContext,
} from "./AutosaveOnBlurContext";
import { FieldValues, useFormContext, useWatch } from "react-hook-form";

interface AutosaveOnBlurProviderProps {
  children: ReactNode;
}

export const AutosaveOnBlurProvider = ({
  children,
}: AutosaveOnBlurProviderProps) => {
  const [isBlurred, setIsBlurred] = useState(false);

  const onBlur = () => {
    console.log("onBlur");
    setIsBlurred(true);
  };
  const onFocus = () => {
    console.log("onFocus");
    setIsBlurred(false);
  };

  const value = useMemo(
    () => ({
      isBlurred,
      onBlur,
      onFocus,
    }),
    [isBlurred, onBlur, onFocus],
  );

  return (
    <AutosaveOnBlurContext.Provider value={value}>
      {children}
    </AutosaveOnBlurContext.Provider>
  );
};

interface WatchProps<T> {
  onSubmit: (data: T) => void;
  isLoading: boolean;
}

AutosaveOnBlurProvider.Watch = function <T extends FieldValues>({
  onSubmit,
  isLoading,
}: WatchProps<T>) {
  const { isBlurred } = useAutosaveOnBlurContext();
  const values = useWatch<T>();
  const {
    handleSubmit,
    formState: { isDirty },
  } = useFormContext<T>();

  const [queued, setQueued] = useState(false);

  const update = () => {
    setQueued(false);
    handleSubmit(
      data => onSubmit(data),
      e => console.log(e),
    )();
  };

  useEffect(() => {
    if (!isBlurred) return;

    if (isLoading) setQueued(true);

    if (isDirty && values && !isLoading) update();
  }, [isBlurred]);

  useEffect(() => {
    if (queued && !isLoading) {
      update();
    }
  }, [queued, isLoading]);

  return <></>;
};
