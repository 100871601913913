import { useLocation, useNavigate } from "react-router-dom";

import { useAppParams, useAppTranslation } from "@hooks";
import {
  useDeleteProgramDayDietMutation,
  usePostProgramDayDietMutation,
} from "@hooks/queries";

import { ThemeProviderWrapperNew } from "themeNew";
import {
  DietNameInputs,
  DietNameModal,
  mapDietNameRequest,
} from "../DietCreator/components/DietNameModal";

export const ProgramDietsNew = () => {
  const { t } = useAppTranslation();
  const { programId, dayId } = useAppParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { mutate: createProgramDiet, isLoading: isCreatingProgramDiet } =
    usePostProgramDayDietMutation();
  const { mutateAsync, isLoading } = useDeleteProgramDayDietMutation();

  const handleCreateDiet = async (values: DietNameInputs) => {
    if (!programId || !dayId) return;
    await mutateAsync({ programId, dayId });

    createProgramDiet(
      { programId, dayId, payload: mapDietNameRequest(values) },
      {
        onSuccess: async () => {
          const currentPath = location.pathname;
          const newPath = currentPath.split("/").slice(0, -2).join("/");
          navigate(newPath);
        },
      },
    );
  };
  return (
    <ThemeProviderWrapperNew>
      <DietNameModal
        open
        onClose={() => navigate(-1)}
        title={t("diets.add_modal.title")}
        confirmText={t("diets.add_modal.submit")}
        onSubmit={handleCreateDiet}
        loading={isLoading || isCreatingProgramDiet}
      />
    </ThemeProviderWrapperNew>
  );
};
