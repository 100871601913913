import CalendarDays from "@views/dietician/PatientMonitoring2/components/CalendarDays/CalendarDays";
import { CalendarDaysOfProps } from "@views/dietician/PatientMonitoring2/components/CalendarDays/CalendarDayOfType";

const CalendarDaysOfCustom = (props: CalendarDaysOfProps) => {
  return (
    <CalendarDays
      addNextDays={() => {
        console.log("todo");
      }}
      addPrevDays={() => {
        console.log("todo");
      }}
    >
      <div>todo</div>
    </CalendarDays>
  );
};

export default CalendarDaysOfCustom;
