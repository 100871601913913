import { useClientParams } from "@hooks";
import { useFetchClientQuery } from "@hooks/queries";
import ProfileTab from "@views/dietician/ClientHealth/ProfileTab/ProfileTab";
import { FoodAndAllergensSection } from "@views/dietician/ClientHealth/components/FoodAndAllergensSection";
import BodyMeasurementTab from "@views/dietician/ClientHealth/BodyMeasurementTab/BodyMeasurementTab";
import Grid from "@mui/material/Unstable_Grid2";

const ClientHealthNew = () => {
  const id = useClientParams();
  const { client, isLoading, isError } = useFetchClientQuery(id);

  if (isLoading) {
    return <div></div>;
  }

  if (!client) {
    return null;
  }

  return (
    <Grid container spacing="16px">
      <Grid smallNew={6} medium={4}>
        <ProfileTab />
      </Grid>
      <Grid smallNew={6} medium={4}>
        <FoodAndAllergensSection />
      </Grid>
      <Grid smallNew={6} medium={4}>
        <BodyMeasurementTab />
      </Grid>
    </Grid>
  );
};

export default ClientHealthNew;
