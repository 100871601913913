import { APIMethods, ApiResponse, LanguagesSlugs } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import * as yup from "yup";
import {
  DietSearchPreviewResourceDto,
  dietSearchPreviewResourceSchema,
} from "@client/diets";
import { object } from "yup";
import { logFetchValidateError } from "@utils/validate";

export interface FetchProgramPreviewParams {
  programId: number;
}

export type FetchProgramPreviewResponse = ApiResponse<BaseProgramSearchPreview>;

export const fetchProgramPreview = async ({
  programId,
}: FetchProgramPreviewParams): Promise<FetchProgramPreviewResponse> => {
  const response = await fetchData(
    `/dietitian/programs-search-preview/${programId}`,
    APIMethods.GET,
  );

  return await schema
    .validate(response)
    .catch(logFetchValidateError("fetchProgramPreview"));
};

export interface BaseProgramSearchPreview extends ProgramSearchPreview {
  duration: number | null;
}
export interface ProgramSearchPreview {
  id: number;
  name: string;
  translations: {
    lang: LanguagesSlugs;
    name: string | null;
  }[];
  language: LanguagesSlugs;
  programDays: ProgramDaySearchPreview[];
}

export interface ProgramDaySearchPreview {
  id: number;
  name: string;
  translations: {
    lang: LanguagesSlugs;
    name: string | null;
  }[];
  frequency: number[];
  diet: {
    id: number;
  } | null;
}

const programDaySearchPreviewSchema = yup.object({
  id: yup.number().required(),
  name: yup.string().required(),
  translations: yup
    .array()
    .of(
      yup.object({
        lang: yup
          .mixed<LanguagesSlugs>()
          .oneOf(Object.values(LanguagesSlugs))
          .required(),
        name: yup.string().nullable().defined(),
      }),
    )
    .required(),
  frequency: yup.array().of(yup.number().required()).required(),
  diet: yup
    .object({
      id: yup.number().required(),
    })
    .defined()
    .nullable(),
});

export const programSearchPreviewSchema = yup.object({
  id: yup.number().required(),
  name: yup.string().required(),
  translations: yup
    .array()
    .of(
      yup.object({
        lang: yup
          .mixed<LanguagesSlugs>()
          .oneOf(Object.values(LanguagesSlugs))
          .required(),
        name: yup.string().nullable().defined(),
      }),
    )
    .required(),
  language: yup
    .mixed<LanguagesSlugs>()
    .oneOf(Object.values(LanguagesSlugs))
    .required()
    .default(LanguagesSlugs.PL),
  programDays: yup.array().of(programDaySearchPreviewSchema).defined(),
});

const schema = object({
  data: programSearchPreviewSchema
    .shape({
      duration: yup.number().nullable().defined(),
    })
    .defined(),
});
