import { useTagsUtils } from "@hooks/useTagsUtils";
import { useMemo } from "react";
import { TagCategoryType } from "@utils/tagsNew";
import { useAppTranslation } from "@hooks";
import { FetchTagCategoriesCategory } from "@client";
import { MultiAutocompleteFilter } from "@components/Filters/AutocompleteFilter/MultiAutocompleteFilter";

const DietAllergenField = () => {
  const { t, currentLanguage } = useAppTranslation();
  const { getCategoryByType, getTagName, getCategoryName } = useTagsUtils();
  const category = useMemo(
    () => getCategoryByType(TagCategoryType.ALLERGENS),
    [getCategoryByType],
  );

  const options = useMemo(
    () =>
      mapOptions({
        category,
        getTagName: (tag: FetchTagCategoriesCategory["tags"][0]) =>
          getTagName(tag, currentLanguage),
      }),
    [currentLanguage, category],
  );

  const categoryName = useMemo(
    () => (category ? getCategoryName(category, currentLanguage) : "") ?? "",
    [category, currentLanguage],
  );

  return (
    <MultiAutocompleteFilter
      label={categoryName}
      options={options}
      name="diet_allergen_tags"
      placeholder={t("diets.allergens_placeholder")}
    />
  );
};

const mapOptions = ({
  category,
  getTagName,
}: {
  category: FetchTagCategoriesCategory | null;
  getTagName: (
    tag: FetchTagCategoriesCategory["tags"][0],
  ) => string | undefined;
}) => {
  if (!category) {
    return [];
  }

  return category.tags.map(tag => ({
    id: tag.id.toString(),
    label: getTagName(tag) ?? "",
  }));
};

export default DietAllergenField;
