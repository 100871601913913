import { Box, Button, IconButton, Stack, styled } from "@mui/material";
import { CustomChip } from "@components/Filters/TabsChipsFilter/TabsChipsFilter.styled";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { Close } from "@assets/icons";
import { useTagsUtils } from "@hooks/useTagsUtils";
import { TagCategoryType } from "@utils/tagsNew";
import { useAppTranslation } from "@hooks";
import {
  FooterWrapper,
  Header,
  HeadWrapper,
  Label,
} from "@views/dietician/PatientMonitoring2/components/MealsBox/MealsListFilterDrawer.styled";

type MealsListFilterDrawerProps = {
  onClose: () => void;
  tagsId: number[];
  setTagsId: Dispatch<SetStateAction<number[]>>;
};

const MealsListFilterDrawer = ({
  onClose,
  tagsId,
  setTagsId,
}: MealsListFilterDrawerProps) => {
  const { t, isPolishChosen } = useAppTranslation();
  const { getCategoryByType } = useTagsUtils();

  const mealsTags = useMemo(() => {
    return (
      getCategoryByType(TagCategoryType.MEAL)?.tags.map(t => ({
        id: t.id,
        name: (isPolishChosen ? t.namePl : t.nameEn) ?? "",
      })) ?? []
    );
  }, [getCategoryByType, isPolishChosen]);

  const symptomsAfterMeal = useMemo(() => {
    return (
      getCategoryByType(TagCategoryType.SYMPTOMS_AFTER_MEAL)?.tags.map(t => ({
        id: t.id,
        name: (isPolishChosen ? t.namePl : t.nameEn) ?? "",
      })) ?? []
    );
  }, [getCategoryByType, isPolishChosen]);

  const emotionsAfterMeal = useMemo(() => {
    return (
      getCategoryByType(TagCategoryType.EMOTIONS_AFTER_MEAL)?.tags.map(t => ({
        id: t.id,
        name: (isPolishChosen ? t.namePl : t.nameEn) ?? "",
      })) ?? []
    );
  }, [getCategoryByType, isPolishChosen]);

  return (
    <Stack direction="column" alignContent="space-between" height="100%">
      <HeadWrapper
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Header>
          {t("monitoring2.meals_box.meals_list_filter_drawer.header")}
        </Header>
        <IconButton onClick={onClose}>
          <Close fill="#727272" />
        </IconButton>
      </HeadWrapper>
      <Stack direction="column" spacing="24px" flex={1} padding="16px">
        <DictionaryWrapper
          label={t("monitoring2.meals_box.meals_list_filter_drawer.meal")}
          items={mealsTags}
          tagsId={tagsId}
          setTagsId={setTagsId}
        />
        <DictionaryWrapper
          label={t(
            "monitoring2.meals_box.meals_list_filter_drawer.symptoms_after_meal",
          )}
          items={symptomsAfterMeal}
          tagsId={tagsId}
          setTagsId={setTagsId}
        />
        <DictionaryWrapper
          label={t(
            "monitoring2.meals_box.meals_list_filter_drawer.emotions_after_meal",
          )}
          items={emotionsAfterMeal}
          tagsId={tagsId}
          setTagsId={setTagsId}
        />
      </Stack>
      <FooterWrapper direction="row" spacing="8px" justifyContent="end">
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setTagsId([])}
        >
          {t("monitoring2.meals_box.meals_list_filter_drawer.button_clear")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            onClose();
          }}
        >
          {t("monitoring2.meals_box.meals_list_filter_drawer.button_show")}
        </Button>
      </FooterWrapper>
    </Stack>
  );
};

type DictionaryWrapperProps = {
  label: string;
  items: { id: number; name: string }[];
  tagsId: number[];
  setTagsId: Dispatch<SetStateAction<number[]>>;
};
const DictionaryWrapper = ({
  label,
  items,
  tagsId,
  setTagsId,
}: DictionaryWrapperProps) => {
  const isChecked = (id: number) => tagsId.find(t => t === id) != undefined;
  const onClickHandle = (id: number) => {
    setTagsId((prev: number[]) => {
      if (prev.find(t => t === id) != undefined) {
        return prev.filter(t => t !== id);
      }
      return [...prev, id];
    });
  };

  return (
    <Stack direction="column" spacing="8px">
      <Label>{label}</Label>
      <Box display="flex" flexDirection="row" gap="8px" flexWrap="wrap">
        {items.map(i => (
          <DictionaryItem
            key={i.id}
            isChecked={isChecked(i.id)}
            label={i.name}
            onClick={() => onClickHandle(i.id)}
          />
        ))}
      </Box>
    </Stack>
  );
};

type DictionaryItemProp = {
  isChecked: boolean;
  onClick: () => void;
  label: string;
};
const DictionaryItem = ({ isChecked, onClick, label }: DictionaryItemProp) => {
  return (
    <CustomChip
      variant={isChecked ? "filled" : "outlined"}
      onClick={onClick}
      label={label}
      color={isChecked ? "primary" : "default"}
    />
  );
};

export default MealsListFilterDrawer;
