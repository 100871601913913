import { ReactNode } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { LOGIN } from "@routes";
import { Roles } from "@utils/settings";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";
import { Spinner } from "@components/Spinner";

import { DASHBOARD } from "./routes";

export const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { children, allowedUserTypes } = props;
  const { account, isLoading, isError } = useFetchDietitianAccountQuery();

  /** helpers */
  const isAuthenticated = account && !isLoading;
  const isUserTypeAllowed =
    account &&
    allowedUserTypes &&
    allowedUserTypes.some(role => account.roles.includes(role));

  /** check if user is loading */
  if (isLoading) return <Spinner className="h-screen" />;

  /** if user not authenticated - redirect to login page */
  if (!isAuthenticated || isError) return <Navigate to={LOGIN} />;

  /** if user is not allowed to visit this page - redirect to homepage */
  if (!isUserTypeAllowed) return <Navigate to={DASHBOARD} />;

  /** if user is allowed and has permissions - redirect to page */
  return children ? <>{children}</> : <Outlet />;
};

interface ProtectedRouteProps {
  /** child components such as layouts */
  children?: ReactNode | ReactNode[];
  /** list of allowed user typeDefinitions to visit this route */
  allowedUserTypes?: Roles[];
}
