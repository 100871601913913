import styled from "@emotion/styled";

interface FileUploaderWrapperProps {
  center?: boolean;
}

export const FileUploaderWrapper = styled.div<FileUploaderWrapperProps>`
  display: flex;
  justify-content: space-between;
  align-items: ${({ center }) => center && "center"};
`;
