import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const MagicWand = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16" height="14" viewBox="0 0 16 14" fill="none" {...props}>
      <path
        d="M6.96476 1.16855L5.91753 1.5541C5.8342 1.58418 5.77865 1.66348 5.77865 1.75098C5.77865 1.83848 5.8342 1.91777 5.91753 1.94785L6.96476 2.3334L7.35642 3.36426C7.38698 3.44629 7.46753 3.50098 7.55642 3.50098C7.64531 3.50098 7.72587 3.44629 7.75642 3.36426L8.14809 2.3334L9.19531 1.94785C9.27865 1.91777 9.3342 1.83848 9.3342 1.75098C9.3342 1.66348 9.27865 1.58418 9.19531 1.5541L8.14809 1.16855L7.75642 0.137695C7.72587 0.0556641 7.64531 0.000976562 7.55642 0.000976562C7.46753 0.000976562 7.38698 0.0556641 7.35642 0.137695L6.96476 1.16855ZM1.72587 10.8127C1.20642 11.324 1.20642 12.1553 1.72587 12.6693L2.68698 13.6154C3.20642 14.1268 4.05087 14.1268 4.57309 13.6154L15.1648 3.18652C15.6842 2.6752 15.6842 1.84395 15.1648 1.32988L14.2036 0.386523C13.6842 -0.124805 12.8398 -0.124805 12.3175 0.386523L1.72587 10.8127ZM13.9064 2.25957L10.9898 5.13066L10.3425 4.49356L13.2592 1.62246L13.9064 2.25957ZM0.653646 3.20566C0.528646 3.25215 0.445312 3.36973 0.445312 3.50098C0.445312 3.63223 0.528646 3.7498 0.653646 3.79629L2.22309 4.37598L2.81198 5.9209C2.8592 6.04395 2.97865 6.12598 3.11198 6.12598C3.24531 6.12598 3.36476 6.04395 3.41198 5.9209L4.00087 4.37598L5.57031 3.79629C5.69531 3.7498 5.77865 3.63223 5.77865 3.50098C5.77865 3.36973 5.69531 3.25215 5.57031 3.20566L4.00087 2.62598L3.41198 1.08105C3.36476 0.958008 3.24531 0.875977 3.11198 0.875977C2.97865 0.875977 2.8592 0.958008 2.81198 1.08105L2.22309 2.62598L0.653646 3.20566ZM10.4314 10.2057C10.3064 10.2521 10.2231 10.3697 10.2231 10.501C10.2231 10.6322 10.3064 10.7498 10.4314 10.7963L12.0009 11.376L12.5898 12.9209C12.637 13.0439 12.7564 13.126 12.8898 13.126C13.0231 13.126 13.1425 13.0439 13.1898 12.9209L13.7786 11.376L15.3481 10.7963C15.4731 10.7498 15.5564 10.6322 15.5564 10.501C15.5564 10.3697 15.4731 10.2521 15.3481 10.2057L13.7786 9.62598L13.1898 8.08106C13.1425 7.95801 13.0231 7.87598 12.8898 7.87598C12.7564 7.87598 12.637 7.95801 12.5898 8.08106L12.0009 9.62598L10.4314 10.2057Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
