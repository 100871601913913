import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { programPreviewKeys } from "@hooks/queries/program/preview/programPreviewKeys";

const useProgramSearchPreviewRemoveQueries = ({
  programId,
}: {
  programId: number;
}) => {
  const query = useQueryClient();

  useEffect(() => {
    return () => {
      query.removeQueries(programPreviewKeys.program(programId));
    };
  }, []);
};

export default useProgramSearchPreviewRemoveQueries;
