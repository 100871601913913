import { styled } from "@mui/material";

export const StyledH2 = styled("h2")`
  font-size: 1.25rem;
  line-height: 150%;
  letter-spacing: -0.022em;
  color: ${({ theme }) => theme.colors.gray500};
  flex: 1;
  text-align: center;
`;
export const StyledLabel = styled("h3")`
  font-size: 0.75rem;
  line-height: 150%;
  letter-spacing: -0.022em;
  color: #00000099;
`;
export const StyledContent = styled("span")`
  font-size: 1rem;
  line-height: 150%;
  letter-spacing: -0.022em;
  color: #000000dd;
`;
export const BillingTabStyled = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 1.75rem;
  gap: 1rem;
  box-shadow: ${({ theme }) => theme.boxShadows.light};
  background-color: ${({ theme }) => theme.colors.whiteBackground};
  min-width: 27.625rem;
`;

export const HeaderWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e3e1db;
  height: 2.25rem;
`;

export const RowWrapper = styled("div")`
  display: flex;
  align-items: flex-end;
  gap: 0.75rem;
`;
