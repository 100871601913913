import { ReactNode } from "react";
import { Button, DialogActions, DialogTitle } from "@mui/material";

import { useAppTranslation } from "@hooks";
import {
  DialogContentStyled,
  DialogStyled,
  IconButtonStyled,
} from "./ConfirmationModal.styled";
import { Close } from "@assets/icons";

interface ConfirmationModalProps {
  open: boolean;
  title: string;
  description: string | ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
  confirmText: string;
}
export const ConfirmationModal = ({
  open,
  title,
  description,
  confirmText,
  onCancel,
  onConfirm,
}: ConfirmationModalProps) => {
  const { t } = useAppTranslation();

  const handleConfirm = () => {
    onConfirm();
    onCancel();
  };

  return (
    <DialogStyled open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>

      <IconButtonStyled onClick={onCancel} size="small">
        <Close size="w-3 h-3" />
      </IconButtonStyled>

      <DialogContentStyled>{description}</DialogContentStyled>
      <DialogActions>
        <Button onClick={onCancel} variant="outlined">
          {t("common.cancel")}
        </Button>
        <Button onClick={handleConfirm} variant="contained" color="error">
          {confirmText}
        </Button>
      </DialogActions>
    </DialogStyled>
  );
};
