import { Radio, TextField, styled } from "@mui/material";

export const TextFieldStyled = styled(TextField)`
  & .MuiInputBase-root {
    padding-left: 0.5rem;
    padding-right: unset;
  }
  &.MuiInputBase-root.Mui-disabled {
    border-color: red;
  }
`;
export const OptionWrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.neutral.medium[400]};
  background: ${({ theme }) => theme.colors.neutral.light[100]};
  padding: 0.5rem 0.75rem;
  opacity: 0.4;
`;

export const RadioStyled = styled(Radio)`
  padding: 0.25rem;
`;

export const OptionText = styled("span")`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;
