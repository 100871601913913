import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import { CustomTag, customTagSchema } from "@client/customTags/fetchCustomTags";
import * as yup from "yup";

export async function updateCustomTag({
  id,
  payload,
}: UpdateCustomTagRequest): Promise<ApiResponse<CustomTag>> {
  const response = await fetchData(
    `/dietitian/custom-tags/${id}`,
    APIMethods.PUT,
    payload,
  );

  return await updateCustomTagResponseSchema.validate(response);
}

export const updateCustomTagResponseSchema = yup.object().shape({
  data: customTagSchema.required(),
});

export interface UpdateCustomTagRequest {
  id: number;
  payload: UpdateCustomTagPayload;
}

export interface UpdateCustomTagPayload {
  name: string;
  color: string;
}
