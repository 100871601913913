import { useInfiniteQuery } from "@tanstack/react-query";
import { ApiEndpoints } from "@typeDefinitions";
import { fetchNotes } from "@client/notes/fetchNotes";

export const useNotesInfiniteQuery = () => {
  const { data, ...queryParams } = useInfiniteQuery({
    queryKey: [ApiEndpoints.AccountNotes, "infinite"],
    queryFn: async ({ pageParam = 1 }) => {
      return await fetchNotes(pageParam);
    },
    getNextPageParam: ({ meta: { to: nextPage } }) => nextPage,
  });

  const transformedNotes = data?.pages
    .map(({ data: notes }) =>
      notes.map(note => ({
        ...note,
        isEditing: false,
        originalNote: note.note,
      })),
    )
    .flat();

  return {
    notes: transformedNotes,
    ...queryParams,
  };
};
