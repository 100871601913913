import {
  MonitoringScheduleMealsResponse,
  fetchMonitoringScheduleMeals,
} from "@client";
import { useQuery } from "@tanstack/react-query";
import { QueryOptionsTyped } from "./types";
import { ApiResponse } from "@typeDefinitions";

export const fetchMonitoringScheduleMealsQueryKey =
  "fetchMonitoringScheduleMealsQueryKey";

export const useFetchMonitoringScheduleMealsQuery = (
  patientId: string,
  date: string,
  options?: QueryOptionsTyped<ApiResponse<MonitoringScheduleMealsResponse>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchMonitoringScheduleMealsQueryKey, patientId, date],
    () => fetchMonitoringScheduleMeals(patientId, date),
    options,
  );

  return {
    scheduleMeals: data?.data,
    ...rest,
  };
};
