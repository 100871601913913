import { useController } from "react-hook-form";

import { useAppTranslation } from "@hooks";
import { useFoodGroupTreeOptions } from "@components/FoodGroupSelect";
import { useFoodGroupTreeFetchQuery } from "@hooks/queries/dictionaries";

import { StyledTreeSelectMui } from "./EditProductDetails.styled";
import { DietitianProductFormInput } from "../../ProductForm";

export const FormCategorySelect = () => {
  const { t } = useAppTranslation();
  const { data: foodGroupTree } = useFoodGroupTreeFetchQuery();
  const options = useFoodGroupTreeOptions(foodGroupTree);
  const {
    field: { value: category, onChange: onChangeCategory },
    fieldState: { error },
  } = useController<DietitianProductFormInput, "categoryId">({
    name: "categoryId",
  });

  return (
    <StyledTreeSelectMui
      className="w-full"
      menuSx={{
        ".MuiPaper-root": {
          height: "50vh",
          width: "40rem",
          maxWidth: "40rem",
        },
      }}
      onSelectOption={id => onChangeCategory(id)}
      options={options}
      placeholder={t("product.edit.select")}
      value={category ?? undefined}
      variant="outlined"
      label={t("product.edit.category")}
      error={!!error}
      helperText={error?.message}
    />
  );
};
