import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const ExclamationMarkInTriangle = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="14" height="12" viewBox="0 0 14 12">
      <path
        d="M13.3477 10.2129C13.5508 10.5853 13.5508 10.9408 13.3477 11.2793C13.1615 11.6178 12.8483 11.7956 12.4082 11.8125H1.5918C1.15169 11.7956 0.838542 11.6178 0.652344 11.2793C0.449219 10.9408 0.440755 10.5853 0.626953 10.2129L6.03516 0.970703C6.28906 0.615234 6.61068 0.4375 7 0.4375C7.38932 0.4375 7.70247 0.615234 7.93945 0.970703L13.3477 10.2129ZM1.8457 10.5938H12.1543L6.97461 1.7832L1.8457 10.5938ZM7 8.18164C7.22005 8.18164 7.40625 8.25781 7.55859 8.41016C7.71094 8.5625 7.78711 8.75716 7.78711 8.99414C7.78711 9.21419 7.71094 9.40039 7.55859 9.55273C7.40625 9.70508 7.22005 9.78125 7 9.78125C6.77995 9.78125 6.59375 9.70508 6.44141 9.55273C6.28906 9.40039 6.21289 9.21419 6.21289 8.99414C6.21289 8.75716 6.28906 8.5625 6.44141 8.41016C6.59375 8.25781 6.77995 8.18164 7 8.18164ZM6.39062 4.29688C6.42448 3.92448 6.6276 3.72135 7 3.6875C7.3724 3.72135 7.57552 3.92448 7.60938 4.29688V6.73438C7.57552 7.10677 7.3724 7.3099 7 7.34375C6.6276 7.3099 6.42448 7.10677 6.39062 6.73438V4.29688Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
