import { FullSurveyDto, fetchSurveyFull } from "@client/surveys";
import { useQuery } from "@tanstack/react-query";
import { QueryOptionsTyped } from "../types";
import { ApiResponse } from "@typeDefinitions";

export const fetchSurveyFullQueryKey = "fetchSurveyFullQueryKey";

export const useFetchSurveyFullQuery = (
  surveyId: string,
  options?: QueryOptionsTyped<ApiResponse<FullSurveyDto>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchSurveyFullQueryKey, surveyId],
    () => fetchSurveyFull(surveyId),
    options,
  );

  return {
    survey: data?.data,
    ...rest,
  };
};
