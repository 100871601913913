import { Drawer, styled } from "@mui/material";

export const StyledDrawer = styled(Drawer)`
  & .MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    margin-top: 56px;
    padding-bottom: 60px;
    max-height: calc(100% - 56px);
  }
`;
