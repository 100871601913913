import { useAppTranslation } from "@hooks";
import { useMemo } from "react";
import { Stack } from "@mui/material";
import MeasurementCompareItem from "@views/dietician/PatientMonitoring2/components/MeasurementsBox/Compare/MeasurementCompareItem";
import {
  getDecreases,
  getEquals,
  getIncreases,
} from "@views/dietician/PatientMonitoring2/components/MeasurementsBox/Compare/MeasurementCompareUtils";
import {
  ArrowMeasureMonitoring,
  DoubleArrowMeasureMonitoring,
} from "@assets/icons/Monitoring";
import { BodyMeasurementMonitoringResource } from "@client/resources/BodyMeasurementMonitoringResource";

type MeasurementCompareProps = {
  first: Exclude<BodyMeasurementMonitoringResource["measurements"], null>;
  second: Exclude<BodyMeasurementMonitoringResource["measurements"], null>;
};
const MeasurementCompareItems = ({
  first,
  second,
}: MeasurementCompareProps) => {
  const { t } = useAppTranslation();
  const decreasesItems = useMemo(
    () => getDecreases({ first, second }),
    [first, second],
  );
  const increasesItems = useMemo(
    () => getIncreases({ first, second }),
    [first, second],
  );
  const equalsItems = useMemo(
    () => getEquals({ first, second }),
    [first, second],
  );

  return (
    <Stack spacing="24px" direction="column">
      {decreasesItems.length > 0 && (
        <MeasurementCompareItem
          title={t("monitoring2.measurements_box.measurement_changes.decrease")}
          icon={
            <ArrowMeasureMonitoring
              fill="#7448D0"
              style={{ transform: "rotate(-180deg)" }}
              size=""
            />
          }
          values={decreasesItems}
        />
      )}
      {increasesItems.length > 0 && (
        <MeasurementCompareItem
          title={t("monitoring2.measurements_box.measurement_changes.increase")}
          icon={<ArrowMeasureMonitoring fill="#7448D0" size="" />}
          values={increasesItems}
        />
      )}
      {equalsItems.length > 0 && (
        <MeasurementCompareItem
          title={t("monitoring2.measurements_box.measurement_changes.equal")}
          icon={<DoubleArrowMeasureMonitoring fill="#7448D0" size="" />}
          values={equalsItems}
        />
      )}
    </Stack>
  );
};

export default MeasurementCompareItems;
