import React from "react";

import { useAppTranslation } from "@hooks";
import { Modal } from "@components/Modal";

export interface ScheduleClearAlertProps {
  onClose: () => void;
  onSubmit: () => void;
  variant: "day" | "all";
}

export const ScheduleClearAlert = ({
  variant,
  onClose,
  onSubmit,
}: ScheduleClearAlertProps) => {
  const { t } = useAppTranslation();

  return (
    <Modal
      title={t("patient.schedule.clear.alert.title")}
      submitButtonText={t(`patient.schedule.clear.${variant}`)}
      onClose={onClose}
      onSubmit={onSubmit}
      style={{ width: 320 }}
    >
      <div className="p-6 flex flex-col">
        {t(`patient.schedule.clear.alert.text_${variant}`)}
      </div>
    </Modal>
  );
};
