import { useMutation } from "@tanstack/react-query";

import { GeneratedMealDto } from "@client/diets";
import {
  postDietAutoGenerateDiet,
  PostDietAutoGenerateDietRequest,
} from "@client/diets/postDietAutoGenerateDiet";
import { ApiResponse } from "@typeDefinitions";

export const usePostDietAutoGenerateDietMutation = () => {
  return useMutation<
    ApiResponse<GeneratedMealDto[]>,
    unknown,
    UsePostDietAutoGenerateDietMutationPayload
  >(({ dietId, payload }) => postDietAutoGenerateDiet(dietId, payload));
};

interface UsePostDietAutoGenerateDietMutationPayload {
  dietId: string;
  payload: PostDietAutoGenerateDietRequest;
}
