import { ToggleButton } from "@mui/material";

import { MacrosUnits } from "@typeDefinitions";
import { ToggleButtonGroupStyled } from "../MacrosSliders.styled";

interface ToggleUnitProps {
  onChange: (unit: MacrosUnits) => void;
  value: MacrosUnits;
  clientWeight: boolean;
}

export const ToggleUnit = ({
  onChange,
  value,
  clientWeight,
}: ToggleUnitProps) => {
  return (
    <ToggleButtonGroupStyled
      value={value}
      onChange={(_, value) => (value ? onChange(value) : null)}
      exclusive
    >
      <ToggleButton value={MacrosUnits.GRAMS}>g</ToggleButton>
      <ToggleButton disabled={!clientWeight} value={MacrosUnits.GRAMS_PER_KILO}>
        g/kg
      </ToggleButton>
      <ToggleButton value={MacrosUnits.PERCENTAGES}>%</ToggleButton>
    </ToggleButtonGroupStyled>
  );
};
