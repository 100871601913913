import { useEffect } from "react";
import { useForm } from "react-hook-form";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useAppTranslation } from "@hooks";

export const useBrandNameForm = (defaultValues?: BrandNameFormInput) => {
  const { t } = useAppTranslation();

  const brandNameInputSchema = yup.object().shape({
    name: yup.string().required(t("common.required_field")),
  });

  const form = useForm<BrandNameFormInput>({
    defaultValues,
    resolver: yupResolver(brandNameInputSchema),
  });

  useEffect(() => {
    if (defaultValues) form.reset(defaultValues);
  }, [defaultValues]);

  return form;
};

export interface BrandNameFormInput {
  name: string;
}
