import { styled } from "@mui/material";

export const StyledRow = styled("div")`
  display: flex;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallMax}px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

export const CalendarLegendWrapper = styled("div")`
  display: grid;
  gap: 1rem;
  justify-items: start;
  padding: 0 1.5rem;
`;

export const LegendItem = styled("div")`
  display: flex;
  gap: 0.5rem;
  margin-left: 1rem;
`;
