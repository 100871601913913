import { createContext, ReactNode, useMemo } from "react";

interface ProgramDaySubmitContextIf {
  submit: () => void;
}

export const ProgramDaySubmitContext = createContext<ProgramDaySubmitContextIf>(
  {
    submit: () => null,
  },
);

interface ProgramDaySubmitContextProviderProps {
  submit: () => void;
  children: ReactNode;
}

export const ProgramDaySubmitContextProvider = ({
  submit,
  children,
}: ProgramDaySubmitContextProviderProps) => {
  const context = useMemo(() => ({ submit }), [submit]);
  return (
    <ProgramDaySubmitContext.Provider value={context}>
      {children}
    </ProgramDaySubmitContext.Provider>
  );
};
