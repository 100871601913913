export const WEEK_DAYS_SHORT_MAP = [
  "Nd",
  "Pn",
  "Wt",
  "Śr",
  "Czw",
  "Pt",
  "Sb",
  "Nd",
];
