import {
  Control,
  Controller,
  ControllerProps,
  FieldValues,
} from "react-hook-form";

import { omit } from "lodash";
import RadioGroup from "@mui/material/RadioGroup";
import { RadioGroupProps } from "@mui/material";

interface FormRadioGroupProps<T extends FieldValues>
  extends Omit<RadioGroupProps, "onChange" | "value" | "defaultValue" | "name">,
    Omit<ControllerProps<T>, "render"> {
  control: Control<any, FieldValues>;
  error?: string;
}

export const FormRadioGroup = <T extends FieldValues>({
  control,
  ...rest
}: FormRadioGroupProps<T>) => {
  return (
    <Controller
      name={rest.name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <RadioGroup
          name={rest.name}
          onChange={onChange}
          value={value}
          {...omit(rest, "name")}
        />
      )}
    />
  );
};
