import { FormTextFieldClearableStyled } from "@components/ProgramsFilters/ProgramsFilters.styled";
import { InputAdornment, Stack } from "@mui/material";
import { Loupe } from "@assets/icons";
import { useAppTranslation } from "@hooks";
import { useFormContext } from "react-hook-form";
import { ProgramFiltersFormPops } from "@views/dietician/Programs/ProgramsFiltersFormType";
import { FunnelButton } from "@components/Filters";
import { useMemo } from "react";

type PatientProgramChoiceSearchProps = {
  open: boolean;
  setOpen: () => void;
};
const ProgramsSearch = ({ open, setOpen }: PatientProgramChoiceSearchProps) => {
  const { t } = useAppTranslation();

  return (
    <Stack direction="row" gap="16px">
      <FormTextFieldClearableStyled
        name="query"
        size="small"
        placeholder={t("programs.search")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Loupe />
            </InputAdornment>
          ),
        }}
        sx={{ height: "46px" }}
      />
      <FilterButton open={open} setOpen={setOpen} />
    </Stack>
  );
};

const FilterButton = ({
  open,
  setOpen,
}: Pick<PatientProgramChoiceSearchProps, "open" | "setOpen">) => {
  const { watch } = useFormContext<ProgramFiltersFormPops>();
  const watchFields = watch();
  const allTags = useMemo(() => {
    return (
      Number(!!watchFields.authorId) +
      Number(!!watchFields.days) +
      (watchFields.diet_elimination_tags?.length ?? 0) +
      (watchFields.diet_allergen_tags?.length ?? 0) +
      (watchFields.diet_type_tags?.length ?? 0) +
      (watchFields.typeDays?.length ?? 0) +
      (watchFields.numberOfDietMeals?.length ?? 0)
    );
  }, [watchFields]);

  return <FunnelButton allTags={allTags} open={open} setOpen={setOpen} />;
};

export default ProgramsSearch;
