import {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useContext,
} from "react";
import {
  Badge,
  Popover,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  ContentWrapper,
  MenuButton,
  Title,
} from "./ClientsListActionModal.styled";
import { Link } from "react-router-dom";
import { ConfirmationWrapper } from "@components/ConfirmationWrapper";
import { useAppTranslation, useUpdateClientMobileAppAccess } from "@hooks";
import { ChatboxContext } from "@context/ChatboxContext";
import useNewMobileAppFeatureEnabled from "@features/useNewMobileAppFeatureEnabled";

interface ClientsListActionModalProps {
  anchorEl?: HTMLElement | null;
  open: boolean;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  handleClose: Dispatch<SetStateAction<boolean>>;
  id: number;
  isActiveApp: boolean;
  hasMessage: boolean | null;
  onClientDeactivate?: MouseEventHandler<HTMLButtonElement>;
  handleRemove: () => void;
  isLoadingDeleteClient: boolean;
  isLoadingDeactivate: boolean;
  hasEmail: string | null;
  onOpenChat?: () => void;
}

export const ClientsListActionModal = ({
  open,
  anchorEl,
  handleClose,
  setAnchorEl,
  id,
  isActiveApp,
  hasMessage,
  onClientDeactivate,
  handleRemove,
  isLoadingDeleteClient,
  isLoadingDeactivate,
  hasEmail,
  onOpenChat,
}: ClientsListActionModalProps) => {
  const { t } = useAppTranslation();
  const newMobileFeature = useNewMobileAppFeatureEnabled();
  const { breakpoints } = useTheme();
  const isMatchTablet = useMediaQuery(`${breakpoints.up("medium")}`);
  const { openChat } = useContext(ChatboxContext);

  const { mutate: mutateMobileAppAccess, isLoading: isLoadingMobileAppAccess } =
    useUpdateClientMobileAppAccess();
  const handleAccess = useCallback(() => {
    mutateMobileAppAccess({
      id: id.toString(),
      payload: { hasAccess: !isActiveApp },
    });
    setAnchorEl(null);
  }, [mutateMobileAppAccess, isActiveApp]);

  const handleOpenChat = () => {
    onOpenChat && onOpenChat();
    handleClose(false);
  };
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <ContentWrapper>
        {!isMatchTablet && (
          <div className="grid">
            <Title variant="body2">{t("clients.table.actions")}</Title>
            <Link to={`${id}/monitoring`}>
              <MenuButton>{t("clients.actions.go_to_monitoring")}</MenuButton>
            </Link>
            <Link to={`/chat/${id}`} onClick={e => e.preventDefault()}>
              <MenuButton
                onClick={handleOpenChat}
                endIcon={
                  <Badge color="error" variant="dot" invisible={!hasMessage} />
                }
              >
                {t("clients.actions.go_to_chat")}
              </MenuButton>
            </Link>
          </div>
        )}

        <div className="grid">
          <Title variant="body2">{t("clients.actions.process")}</Title>
          <MenuButton
            onClick={onClientDeactivate}
            disabled={isLoadingMobileAppAccess || isLoadingDeactivate}
          >
            {t("clients.actions.deactivate_client")}
          </MenuButton>
          <Tooltip
            placement="top"
            title={t("client_profile.edit.payments.mobile_app_access_tooltip")}
            disableHoverListener={!!hasEmail}
            arrow
          >
            <div>
              <MenuButton
                onClick={handleAccess}
                disabled={isLoadingMobileAppAccess || !hasEmail}
              >
                {isActiveApp
                  ? t("clients.actions.deactivate_mobile_app")
                  : t("clients.actions.activate_mobile_app")}
              </MenuButton>
            </div>
          </Tooltip>
          <Link
            to={
              newMobileFeature
                ? `/patients/${id}/programs`
                : `/patients/${id}/nutritional-programs`
            }
          >
            <MenuButton>{t("clients.actions.add_program")}</MenuButton>
          </Link>
        </div>

        <div className="grid">
          <Title variant="body2">{t("clients.actions.profile")}</Title>
          <Link to={`/patients/${id}/info-and-services`}>
            <MenuButton>{t("clients.actions.edit")}</MenuButton>
          </Link>
          <ConfirmationWrapper
            onConfirm={handleRemove}
            confirmationText={t("clients.actions.confirmation")}
            submitText={t("clients.actions.yes")}
          >
            <MenuButton loading={isLoadingDeleteClient}>
              {t("clients.actions.delete")}
            </MenuButton>
          </ConfirmationWrapper>
        </div>
      </ContentWrapper>
    </Popover>
  );
};
