import { useLocalStorage } from "react-use";

import { Button, Dialog, IconButton, Link } from "@mui/material";

import CloseIcon from "@assets/icons_new/closeClean.svg?react";
import PlayIcon from "@assets/icons_new/playTriangle.svg?react";
import OpenInWindowIcon from "@assets/icons_new/squareWithArrow.svg?react";
import { useAppTranslation } from "@hooks";

import { ARTICLE_LINK, VIDEO_LINK } from "../CloseableBanner/CloseableBanner";
import { BoxElement } from "./BoxElement";
import { Description, Title, Wrapper } from "./LaunchModal.styled";

export const LaunchModalLocalStorageKey = "showLaunchModal";
export const LaunchModal = () => {
  const { t } = useAppTranslation();
  const [open, setOpen] = useLocalStorage(LaunchModalLocalStorageKey, true);

  return (
    <Dialog open={!!open}>
      <div className="p-[8px] w-full flex justify-end">
        <IconButton size="small" onClick={() => setOpen(false)}>
          <CloseIcon fontSize={10} />
        </IconButton>
      </div>
      <Wrapper>
        <img src="/static/launchBanner/baloons.png" width={164} height={164} />

        <div className="flex flex-col items-center gap-[8px]">
          <div className="flex flex-col items-center gap-[12px]">
            <Title>{t("empty_states.launch_modal.title")}</Title>
            <Description>{t("empty_states.launch_modal.desc")}</Description>
          </div>

          <div className="flex flex-col items-center gap-[8px]">
            <BoxElement text={t("empty_states.launch_modal.point_1")} />
            <BoxElement text={t("empty_states.launch_modal.point_2")} />
            <BoxElement text={t("empty_states.launch_modal.point_3")} />
          </div>
        </div>

        <div className="flex flex-col gap-[16px] items-center">
          <Description>{t("empty_states.launch_modal.learn_more")}</Description>

          <div className="flex gap-[16px]">
            <Button
              variant="contained"
              LinkComponent={Link}
              href={VIDEO_LINK}
              target="_blank"
              sx={{ display: "flex", gap: "8px" }}
            >
              <PlayIcon />
              {t("empty_states.launch_modal.watch_video")}
            </Button>
            <Button
              variant="outlined"
              LinkComponent={Link}
              href={ARTICLE_LINK}
              target="_blank"
              sx={{ display: "flex", gap: "8px" }}
            >
              {t("empty_states.launch_modal.read_more")}
              <OpenInWindowIcon />
            </Button>
          </div>
        </div>
      </Wrapper>
    </Dialog>
  );
};
