import { styled } from "@mui/material";
import { useAppTranslation } from "@hooks";
import FormLabel from "@components/Alloweat/Inputs/FormLabel";
import FormControl from "@components/Alloweat/Inputs/FormControl";
import { useState } from "react";
import DataPicker from "@components/Alloweat/DataPicker/DataPicker";
import TextField from "@components/Alloweat/Inputs/TextField/TextField";
import { Controller } from "react-hook-form";

const DateMeasurementField = () => {
  4;
  const { t } = useAppTranslation();
  const [openDataPicker, setOpenDataPicker] = useState<boolean>(false);

  return (
    <FormControl>
      <FormLabel>
        {t(
          "patient.health_and_goal.body_measurement_tab.section_last_measurement.measurement_date",
        )}
      </FormLabel>
      <Controller
        render={({ field }) => {
          return (
            <DataPicker
              views={["month", "day"]}
              value={field.value}
              disablePast
              open={openDataPicker}
              onClose={() => setOpenDataPicker(false)}
              onChange={newValue => {
                field.onChange(newValue);
              }}
              onOpen={() => setOpenDataPicker(true)}
              inputFormat="DD.MM.YYYY"
              InputAdornmentProps={{
                position: "start",
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  onClick={() => setOpenDataPicker(true)}
                />
              )}
            />
          );
        }}
        name="date"
      />
    </FormControl>
  );
};

export default DateMeasurementField;
