import {
  GridColDef,
  GridRenderCellParams,
  GridSelectionModel,
} from "@mui/x-data-grid";
import { useMemo, useState } from "react";
import { useAppTranslation } from "@hooks";
import { NameColumnCell } from "@components/ProgramsDataGrid/NameColumnCell";
import { TagsColumnCell } from "@components/ProgramsDataGrid/TagsColumnCell";
import { ActionsColumnCell } from "@components/ProgramsDataGrid/ActionsColumnCell";
import {
  CustomDataGrid,
  FlexDataGrid,
} from "@components/Alloweat/DataGrid/DataGrid";
import ProgramSearchPreviewDrawer from "@components/ProgramSearchPreview/ProgramSearchPreviewDrawer";
import ProgramSearchPreview from "@components/ProgramSearchPreview/ProgramSearchPreview";
import { SearchProgramsPaginationResponse } from "@client/searchProgramsNew";
import useProgramDate from "@hooks/program/useProgramDate";

type ProgramsGridType = {
  programs: SearchProgramsPaginationResponse | undefined;
  isFetching: boolean;
  pageField: {
    page: number;
    setPage: (value: number) => void;
  };
  perPageField: {
    perPage: number;
    setPerPage: (value: number) => void;
  };
};

const ProgramsGrid = ({
  programs,
  isFetching,
  pageField: { page, setPage },
  perPageField: { perPage, setPerPage },
}: ProgramsGridType) => {
  const { t, currentLanguageDTO } = useAppTranslation();
  const [selectedProgramId, setSelectedProgramId] = useState<number | null>(
    null,
  );
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: "name",
        flex: 3,
        headerName: t("programs.program_name"),
        sortable: false,
        renderCell: (
          params: GridRenderCellParams<
            any,
            SearchProgramsPaginationResponse["data"][0]
          >,
        ) => {
          const { durationDays } = useProgramDate({
            startDate: params.row.startDate,
            endDate: params.row.endDate,
          });

          const name =
            params.row.translations.find(t => t.lang === currentLanguageDTO)
              ?.name ?? "";

          return (
            <NameColumnCell
              name={name}
              nutrients={params.row.nutrients}
              onSelect={() => undefined}
              numberOfDayTypes={params.row.numberOfProgramDays}
              programLength={durationDays}
            />
          );
        },
        // minWidth: 250,
      },
      {
        field: "tags",
        headerName: t("common.tags"),
        sortable: false,
        flex: 2,
        align: "left",
        renderCell: (
          params: GridRenderCellParams<
            any,
            SearchProgramsPaginationResponse["data"][0]
          >,
        ) => (
          <TagsColumnCell tags={params.row.tags.map(t => t.id.toString())} />
        ),
      },
      {
        field: "actions",
        headerName: t("programs.actions"),
        headerAlign: "right",
        align: "right",
        sortable: false,
        width: 165,
        renderCell: ({
          row: { id, isFavorite: isFavorite, actions, namePl, nameEn },
        }) => (
          <ActionsColumnCell
            id={id}
            isFavorite={isFavorite}
            actions={actions}
            namePl={namePl}
            nameEn={nameEn}
          />
        ),
      },
    ];
  }, [currentLanguageDTO]);

  const total = programs?.meta.total ?? 0;

  return (
    <>
      <FlexDataGrid>
        <CustomDataGrid
          rowHeight={64}
          rows={programs?.data ?? []}
          columns={columns}
          onPageChange={setPage}
          pageSize={perPage}
          page={page}
          rowCount={total}
          loading={isFetching}
          componentsProps={{
            pagination: { setPerPage, perPage, rowsPerPageOptions: [10, 25] },
          }}
          onRowClick={(params, e, details) => {
            setSelectedProgramId(parseInt(params.row.id.toString()));
          }}
          onSelectionModelChange={newSelectionModel => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
        />
      </FlexDataGrid>
      <ProgramSearchPreviewDrawer
        programId={selectedProgramId}
        onClose={() => {
          setSelectedProgramId(null);
          setSelectionModel([]);
        }}
      >
        {selectedProgramId !== null && (
          <ProgramSearchPreview programId={selectedProgramId} />
        )}
      </ProgramSearchPreviewDrawer>
    </>
  );
};

export default ProgramsGrid;
