import { TaskPriority } from "@views/dietician/Tasks/components/TaskPrioritySelect";
import { ScaleLow, ScaleMedium, ScaleHigh } from "@assets/icons";
import { ReactElement } from "react";

export const getTaskPriorityIcon = (priority: TaskPriority): ReactElement => {
  switch (priority) {
    case TaskPriority.High:
      return <ScaleHigh className="text-3xl text-gray-450" />;
    case TaskPriority.Medium:
      return <ScaleMedium className="text-3xl text-gray-450" />;
    case TaskPriority.Low:
      return <ScaleLow className="text-3xl text-gray-450" />;
  }
};
