import { useMemo } from "react";

import { Roles } from "@utils/settings";
import { useFetchDietitianAccountQuery } from "./queries/settings";
import { ClinicType } from "@client/settings";

export const useUserRoles = () => {
  const { account } = useFetchDietitianAccountQuery();
  const isAdmin = account?.roles.includes(Roles.DIETITIAN_OWNER);
  const isAdminClinicMember = account?.clinic?.id === 1;
  const isSchool = account?.clinic?.type === ClinicType.SCHOOL;
  return useMemo(
    () => ({
      isSuperAdmin: isAdmin && isAdminClinicMember,
      isAdminClinicMember,
      isClinicAdmin: isAdmin,
      isWorker: account?.roles.includes(Roles.DIETITIAN_WORKER),
      isAssistant: account?.roles.includes(Roles.DIETITIAN_ASSISTANT),
      isPatient: account?.roles.includes(Roles.PATIENT),
      isSchool,
    }),
    [account],
  );
};
