import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import KcalSummaryCardOf from "@views/dietician/PatientMonitoring2/components/SummaryCard/Kcal/KcalSummaryCardOf";
import { useFetchMonitoringNutrients } from "@hooks/queries/client/monitoring";
import { mapSelectedDaysToRequestQueryParams } from "@views/dietician/PatientMonitoring2/PatientMonitoringUtils";
import { ENERGY_ID } from "@utils/macros";
import { NutrientsMonitoringResource } from "@client/resources/NutrientsMonitoringResource";

const KcalSummaryCardOfDays = () => {
  const { patientId, type, selectedDays } = useMonitoring();

  const { data } = useFetchMonitoringNutrients(
    {
      patientId,
      ...mapSelectedDaysToRequestQueryParams(selectedDays.selectDays),
    },
    { enabled: type.type !== "day" },
  );

  return (
    <KcalSummaryCardOf
      actualValue={getActualValue(data?.data)}
      targetValue={getTargetValue(data?.data)}
    />
  );
};

const getActualValue = (data: NutrientsMonitoringResource[] | undefined) => {
  return Math.round(
    (data ?? [])
      .filter(i => i.patientNutrients.length > 0)
      .reduce(
        (accumulator, currentValue) =>
          accumulator +
          (currentValue.patientNutrients.find(n => n.id === ENERGY_ID)?.value ??
            0),
        0,
      ) /
      Math.max(
        (data ?? []).filter(i => i.patientNutrients.length > 0).length,
        1,
      ),
  );
};

const getTargetValue = (data: NutrientsMonitoringResource[] | undefined) => {
  return Math.round(
    (data ?? [])
      .filter(i => i.patientNutrients.length > 0)
      .reduce(
        (accumulator, currentValue) =>
          accumulator +
          (currentValue.dietitianNutrients.find(n => n.id === ENERGY_ID)
            ?.value ?? 0),
        0,
      ) /
      Math.max(
        (data ?? []).filter(i => i.patientNutrients.length > 0).length,
        1,
      ),
  );
};

export default KcalSummaryCardOfDays;
