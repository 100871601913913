import { useQuery } from "@tanstack/react-query";

import { ApiResponse } from "@typeDefinitions";
import { buildDefaultQueryOptions, QueryOptionsTyped } from "@hooks/queries";
import { CustomTag, fetchCustomTags } from "@client/customTags/fetchCustomTags";

export const fetchCustomTagsQueryKey = "fetchCustomTagsQueryKey";

export const useFetchCustomTagsQuery = (
  options?: QueryOptionsTyped<ApiResponse<CustomTag[]>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchCustomTagsQueryKey],
    () => fetchCustomTags(),
    buildDefaultQueryOptions(options),
  );
  return {
    tags: data?.data ?? [],
    ...rest,
  };
};
