import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Message = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 42.503 38.102">
    <g id="icon-message" transform="translate(1.25 1.25)">
      <path
        id="Path_445"
        d="M23.532,0,14.66,7.143a4.624,4.624,0,0,1-5.712,0L0,0"
        transform="translate(8.225 11.567)"
        fill="none"
        stroke="#801fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
      <path
        id="Rectangle_511"
        d="M10.167,0h19.61a10.311,10.311,0,0,1,7.447,3.308,10.435,10.435,0,0,1,2.758,7.7V24.59a10.435,10.435,0,0,1-2.758,7.7A10.311,10.311,0,0,1,29.777,35.6H10.167C4.093,35.6,0,30.661,0,24.59V11.012C0,4.941,4.093,0,10.167,0Z"
        transform="translate(0 0)"
        fill="none"
        stroke="#801fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
    </g>
  </SvgIcon>
);
