import { Dialog, DialogContent, IconButton, styled } from "@mui/material";

export const DescriptionStyled = styled("span")`
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

export const DialogStyled = styled(Dialog)`
  & .MuiPaper-root {
    width: 42.5rem;
  }
`;

export const DialogContentStyled = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const IconButtonStyled = styled(IconButton)`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
`;
