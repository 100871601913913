import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { CreateClientRequest } from "@client";
import { useAppTranslation } from "@hooks";
import { POLISH_PHONE_PREFIX } from "@utils/clients";
import { emailSchemaFactory } from "@utils/validateEmailRegEx";
import { useFetchDietitianAccountQuery } from "./queries/settings";

export const useCreateClientForm = () => {
  const { t } = useAppTranslation();
  const { account } = useFetchDietitianAccountQuery();

  const resolver = yup.object().shape({
    firstName: yup.string().required(t("common.required_field")),
    lastName: yup.string().required(t("common.required_field")),
    email: emailSchemaFactory(t("common.email_invalid")),
    lang: yup
      .mixed()
      .oneOf(Object.values(Lang))
      .required(t("common.required_field")),
    sex: yup.mixed().oneOf(Object.values(Sex)).nullable().default(null),
    description: yup.string().nullable(),
    sendEmail: yup.boolean().required(t("common.required_field")),
    phonePrefix: yup.string().nullable(),
    phoneNumber: yup.string().nullable(),
    dietitian: yup.number().required(),
  });

  return useForm<CreateClientFormInput>({
    defaultValues: {
      lang: Lang.PL,
      sex: null,
      sendEmail: false,
      phonePrefix: POLISH_PHONE_PREFIX,
      firstName: "",
      lastName: "",
      email: "",
      dietitian: account?.id,
    },
    resolver: yupResolver(resolver),
    shouldFocusError: false,
  });
};

export interface DietitianDto {
  id: number;
}
export interface CreateClientFormInput {
  firstName: string;
  lastName: string;
  email: string;
  phonePrefix?: string;
  phoneNumber?: string;
  lang: Lang;
  sex: Sex | null;
  description?: string;
  sendEmail: boolean;
  activation: boolean;
  questionnaire: boolean;
  dietitian: number;
}

export enum Lang {
  PL = "pl",
  EN = "en",
}

export enum Sex {
  M = "m",
  W = "w",
}

export const mapCreateClientRequest = (
  data: CreateClientFormInput,
): CreateClientRequest => {
  return {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email ?? null,
    lang: data.lang,
    sex: data.sex ?? null,
    sendEmail: data.sendEmail,
    description: data.description ?? null,
    phone:
      data.phonePrefix && data.phoneNumber
        ? {
            prefix: data.phonePrefix,
            number: data.phoneNumber,
          }
        : {
            prefix: null,
            number: null,
          },
    dietitian: { id: data.dietitian },
  };
};
