import { useQuery } from "@tanstack/react-query";

import { NormLocationDto, fetchNorms } from "@client";
import { ApiResponse } from "@typeDefinitions";

import { QueryOptionsTyped } from "./types";

export const fetchNormsQueryKey = "fetchNorms";

export const useFetchNormsQuery = (
  patientId?: string,
  options?: QueryOptionsTyped<ApiResponse<NormLocationDto[]>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchNormsQueryKey, patientId],
    () => fetchNorms(patientId),
    options,
  );
  return { norms: data?.data, ...rest };
};

export const getNormDescriptionByAgeId = (
  norms: NormLocationDto[],
  normAgeId: number,
): {
  location: NormLocationDto | undefined;
  age: NormLocationDto["normAge"][0] | undefined;
} => {
  let normLocationTmp: NormLocationDto | undefined = undefined;
  let normAgeTmp: NormLocationDto["normAge"][0] | undefined = undefined;

  norms.find(location => {
    normAgeTmp = location.normAge.find(normAge => normAge.id === normAgeId);

    if (normAgeTmp) {
      normLocationTmp = location;
    }
    return !!normAgeTmp;
  });

  return {
    location: normLocationTmp,
    age: normAgeTmp,
  };
};
