import { useMutation, useQueryClient } from "@tanstack/react-query";
import { patientKeys } from "@hooks/queries/client/patientKeys";
import {
  updatePatientImportantNutrients,
  UpdatePatientImportantNutrientsParams,
  UpdatePatientImportantNutrientsPayload,
} from "@client/patient/nutrients/updatePatientImportantNutrients";
import { FetchPatientImportantNutrientsResponse } from "@client/patient/nutrients/fetchPatientImportantNutrients";
import { patientNutrientKeys } from "@hooks/queries/client/nutrient/patientNutrientKeys";

const useUpdatePatientImportantNutrientsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      params,
      payload,
    }: {
      params: UpdatePatientImportantNutrientsParams;
      payload: UpdatePatientImportantNutrientsPayload;
    }) => {
      return updatePatientImportantNutrients(params, payload);
    },
    onSuccess: async (_, variables) => {
      queryClient.setQueryData(
        patientNutrientKeys.important(variables.params.patientId),
        (oldData: FetchPatientImportantNutrientsResponse | undefined) => {
          if (oldData === undefined) {
            return undefined;
          }

          return {
            ...oldData,
            data: variables.payload.nutrientsId.map(id => ({ id })),
          };
        },
      );
    },
  });
};

export default useUpdatePatientImportantNutrientsMutation;
