import * as yup from "yup";

import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import {
  Currencies,
  EVENT_STATUS,
  MEETING_MODE,
  MEETING_TYPE,
} from "@utils/scheduleEvent";
import { validateAndFixNutrients } from "./utils/validateCatching";

export const fetchScheduleEvent = async (
  id: number,
): Promise<ApiResponse<FetchScheduleEventResponse>> => {
  const data = await fetchData(`/dietitian/schedules/${id}`, APIMethods.GET);
  return await validateAndFixNutrients(fetchScheduleEventResponseSchema, data);
};

export const meetingTypeSchema = yup.object().shape({
  id: yup.number().required(),
});

const clinicSchema = yup.object().shape({
  id: yup.number().required(),
});

export const dietitianSchema = yup.object().shape({
  id: yup.number().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
});

const patientSchema = yup.object().shape({
  id: yup.number().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().nullable(),
  phone: yup.string().nullable(),
});

export const meetingModeSchema = yup.object().shape({
  id: yup.number().required(),
});

export const clinicFacilitySchema = yup.object().shape({
  id: yup.number().required(),
  address: yup.string().required(),
});

export const clinicProductTranslationSchema = yup.object({
  lang: yup.string().defined(),
  name: yup.string().defined(),
});

export const clinicProductSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  translations: yup.array().of(clinicProductTranslationSchema).defined(),
});

export const priceSchema = yup.object().shape({
  value: yup.number().required(),
  currency: yup.mixed<Currencies>().oneOf(Object.values(Currencies)).required(),
});

export const statusSchema = yup.object().shape({
  id: yup.number().required(),
});

const fetchScheduleEventSchema = yup.object().shape({
  id: yup.number().required(),
  meetingType: meetingTypeSchema.required(),
  clinic: clinicSchema.required(),
  dietitian: dietitianSchema.required(),
  patient: patientSchema.nullable(),
  title: yup.string().required(),
  dateStart: yup.string().required(),
  dateEnd: yup.string().required(),
  isAllDay: yup.boolean().required(),
  additionalInfo: yup.string().nullable(),
  meetingMode: meetingModeSchema.nullable(),
  clinicFacility: clinicFacilitySchema.nullable(),
  clinicProduct: clinicProductSchema.nullable(),
  price: priceSchema.nullable(),
  status: statusSchema.required(),
});

const fetchScheduleEventResponseSchema = yup.object().shape({
  data: fetchScheduleEventSchema,
});

export interface FetchScheduleEventResponse {
  id: number;
  meetingType: MeetingTypeDto;
  clinic: ClinicDto;
  dietitian: DietitianDto;
  patient?: PatientDto;
  title: string;
  dateStart: string;
  dateEnd: string;
  isAllDay: boolean;
  additionalInfo?: string;
  meetingMode?: MeetingModeDto;
  clinicFacility?: ClinicFacilityDto;
  clinicProduct?: ClinicProductDto;
  price?: PriceDto;
  status: StatusDto;
}

export interface MeetingTypeDto {
  id: MEETING_TYPE;
}

interface ClinicDto {
  id: number;
}

export interface DietitianDto {
  id: number;
  firstName: string;
  lastName: string;
}

interface PatientDto {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export interface MeetingModeDto {
  id: MEETING_MODE;
}

export interface ClinicFacilityDto {
  id: number;
  address: string;
}

export interface ClinicProductTranslation {
  lang: string;
  name: string;
}
export interface ClinicProductDto {
  id: number;
  name: string;
  translations: ClinicProductTranslation[];
}

export interface PriceDto {
  value: number;
  currency: Currencies;
}

export interface StatusDto {
  id: EVENT_STATUS;
}
