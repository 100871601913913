import { useForm } from "react-hook-form";
import { useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useAppTranslation } from "@hooks/useAppTranslation";
import { useFetchProgramSectionsCategoryQuery } from "@hooks/queries";
import { getDefaultProgramSections } from "@Pdf/utils/programForm";

export interface ProgramFormInput {
  namePl: string;
  nameEn: string;
  workModel: "diets" | "macros";
  programLanguage: "pl" | "en";
  hasDiary: boolean;
  hasMonitoring: boolean;
  hasEducation: boolean;
  hasTasks: boolean;
  hasShoppingList: boolean;
  hasNewsfeed: boolean;
  hasChat: boolean;
  showCals: boolean;
  showMacros: boolean;
  duration: number | null;
  showMicros: number[];
  sectionIds: number[];
  reviewed?: boolean;
}

export function useProgramForm(programValues?: ProgramFormInput) {
  const { t } = useAppTranslation();
  const { sections } = useFetchProgramSectionsCategoryQuery();
  const resolver = yupResolver(
    yup.object().shape({
      namePl: yup.string().required(t("meals.require_title")),
      duration: yup
        .number()
        .min(1, t("meals.duration_value_min"))
        .max(90, t("meals.duration_value_max"))
        .typeError(t("common.required_field"))
        .required(t("common.required_field")),
    }),
  );

  const form = useForm<ProgramFormInput>({
    defaultValues: programValues ?? defaultValues,
    resolver,
  });

  useEffect(() => {
    if (programValues) form.reset(programValues);
  }, [programValues]);

  useEffect(() => {
    if (sections && !programValues) {
      // TODO: Those values should not be hardcoded, it should come only from the API!
      form.setValue("sectionIds", getDefaultProgramSections(sections));
    }
  }, [sections]);

  return form;
}

const defaultValues: ProgramFormInput = {
  namePl: "",
  nameEn: "",
  workModel: "diets",
  programLanguage: "pl",
  hasDiary: true,
  hasMonitoring: false,
  hasEducation: false,
  hasTasks: false,
  hasShoppingList: false,
  hasNewsfeed: false,
  hasChat: false,
  showCals: true,
  showMacros: true,
  duration: 30,
  showMicros: [],
  sectionIds: [],
  reviewed: undefined,
};
