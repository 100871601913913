import { Download } from "@assets/icons";
import { useAppTranslation, useModalState } from "@hooks";
import { LoadingButtonStyled } from "./ProgramInfoCard.styled";
import ExportPdfModal from "@Pdf/components/ExportPdfModal/ExportPdfModal";

interface DownloadProgramPdf {
  programId: number;
  patientId?: number;
}

export const DownloadProgramPdf = ({
  programId,
  patientId,
}: DownloadProgramPdf) => {
  const { t } = useAppTranslation();

  const [openedModal, onOpenModal, onCloseModal] = useModalState();

  return (
    <>
      {openedModal && (
        <ExportPdfModal
          open={openedModal}
          programId={programId}
          patientId={patientId}
          onClose={onCloseModal}
        />
      )}
      <LoadingButtonStyled
        startIcon={<Download />}
        variant="outlined"
        onClick={onOpenModal}
      >
        {t("program.summary.program_info_card.save_to_pdf")}
      </LoadingButtonStyled>
    </>
  );
};
