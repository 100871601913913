import { Dialog, styled } from "@mui/material";

// w-[600px] p flex flex-col gap-[32px] items-center
export const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  width: 600px;
  padding: 0 24px 24px;
`;

export const Title = styled("span")`
  font-family: Figtree;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;

export const Description = styled("span")`
  font-family: Figtree;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.4px;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;
