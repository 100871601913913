import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const CrossedArrows = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16" height="14" viewBox="0 0 16 14" {...props}>
      <path
        d="M12.6163 0.0767168C12.9912 -0.0794866 13.4193 0.00798724 13.7067 0.292278L15.7063 2.29168C15.8938 2.47913 16 2.73218 16 2.99772C16 3.26327 15.8938 3.51632 15.7063 3.70376L13.7067 5.70317C13.4193 5.99058 12.9912 6.07493 12.6163 5.91873C12.2414 5.76252 11.9977 5.40013 11.9977 4.994V4.00055H10.9979C10.6823 4.00055 10.3855 4.14738 10.198 4.40043L8.87327 6.16553L7.62351 4.5004L8.59832 3.20079C9.16384 2.44476 10.0543 2.00114 10.9979 2.00114H11.9977V1.00144C11.9977 0.598436 12.2414 0.23292 12.6163 0.0767168ZM5.124 7.83378L6.37376 9.49891L5.39895 10.7985C4.83343 11.5545 3.94298 11.9982 2.99941 11.9982H0.999805C0.446788 11.9982 0 11.5514 0 10.9985C0 10.4455 0.446788 9.99876 0.999805 9.99876H2.99941C3.31498 9.99876 3.61179 9.85193 3.79926 9.59888L5.124 7.83378ZM13.7036 13.707C13.4161 13.9944 12.9881 14.0788 12.6132 13.9226C12.2382 13.7664 11.9945 13.404 11.9945 12.9979V11.9982H10.9979C10.0543 11.9982 9.16384 11.5545 8.59832 10.7985L3.79926 4.40043C3.61179 4.14738 3.31498 4.00055 2.99941 4.00055H0.999805C0.446788 4.00055 0 3.55381 0 3.00085C0 2.44789 0.446788 2.00114 0.999805 2.00114H2.99941C3.94298 2.00114 4.83343 2.44476 5.39895 3.20079L10.198 9.59888C10.3855 9.85193 10.6823 9.99876 10.9979 9.99876H11.9977V8.99906C11.9977 8.59605 12.2414 8.23054 12.6163 8.07433C12.9912 7.91813 13.4193 8.0056 13.7067 8.2899L15.7063 10.2893C15.8938 10.4767 16 10.7298 16 10.9953C16 11.2609 15.8938 11.5139 15.7063 11.7014L13.7067 13.7008L13.7036 13.707Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
