import { Switch, SwitchProps } from "@components/Switch";
import { Control, Controller, Path } from "react-hook-form";

import { FieldValues } from "react-hook-form/dist/types";

/** multi select component with React Hook Form Controller */
export const FormSwitch = <T extends FieldValues>(
  props: FormSwitchProps<T>,
) => {
  const { name, control, error, ...rest } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ref } }) => (
        <Switch
          ref={ref}
          name={name}
          value={value as boolean | undefined}
          onChange={onChange}
          {...rest}
        />
      )}
    />
  );
};

interface FormSwitchProps<T extends FieldValues> extends SwitchProps {
  /** name attribute */
  name: Path<T>;
  /** react hook form control */
  control: Control<T>;
  /** error message */
  error?: string;
}
