import { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import { RegisterPatientFormInput } from "@typeDefinitions/types";

/**
 * Custom hook for RegisterPatient view
 */
export const useRegisterPatient = () => {
  const { search } = useLocation();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showpasswordConfirmation, setShowpasswordConfirmation] =
    useState(false);

  /**
   * verification token from URL
   */
  const verificationToken =
    new URLSearchParams(search).get("verification_token") ?? "";
  const email = new URLSearchParams(search).get("email") ?? "";

  /**
   * RHF
   */
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<RegisterPatientFormInput>({
    resolver: yupResolver(registerPatientFormSchema),
    defaultValues: {
      password: "",
      passwordConfirmation: "",
    },
  });

  /**
   * Handles registering new patient in system
   */

  return {
    control,
    errors,
    status: "pending",
    apiErrors: null,
    isSubmitting,
    showPassword,
    showpasswordConfirmation,
    setShowPassword,
    setShowpasswordConfirmation,
    handleSubmit,
    handleRegisterPatient: (data: any) => null,
  };
};

const registerPatientFormSchema = yup.object().shape({
  password: yup.string().required("Hasło jest wymagane"),
  passwordConfirmation: yup
    .string()
    .required("Hasło jest wymagane")
    .oneOf([yup.ref("password"), ""], "Hasła nie pasują"),
});
