import { fetchData } from "@utils/api";
import { CategoryNutrientsResponse } from "@typeDefinitions/responses/dictionaries";
import { APIMethods } from "@typeDefinitions";

export async function nutrientsCategories(): Promise<
  CategoryNutrientsResponse[]
> {
  const { data } = await fetchData(
    `/dictionaries/nutrient-categories/nutrients`,
    APIMethods.GET,
  );
  return data;
}
