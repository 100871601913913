import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import dayjs from "dayjs";
import { useEffect } from "react";
import {
  FetchClientLastMeasurementResponse,
  FetchClientResponse,
  UpdateClientMeasurementRequest,
} from "@client";
import { Sexes } from "./useUpdateClientForm";

export const useUpdateBalanceClientInfoForm = (
  values?: UpdateBalanceClientInfoFormInput,
) => {
  const resolver = yup.object().shape({
    sex: yup.mixed().oneOf(Object.values(Sexes)).nullable(),
  });

  const form = useForm<UpdateBalanceClientInfoFormInput>({
    defaultValues: values,
    resolver: yupResolver(resolver),
    shouldFocusError: false,
  });

  useEffect(() => {
    if (values) form.reset(values);
  }, [values]);

  return form;
};

export const mapUpdateBalanceClientInfoRequest = (
  data: UpdateBalanceClientInfoFormInput,
): UpdateClientMeasurementRequest => {
  return {
    date: data.date
      ? dayjs(data.date).format("YYYY-MM-DD")
      : dayjs().format("YYYY-MM-DD"),
    growth: data.growth ?? null,
    weight: data.weight ?? null,
    bodyFatLevel: data.bodyFatLevel ?? null,
    arm: data.arm ?? null,
    tightBiceps: data.tightBiceps ?? null,
    waist: data.waist ?? null,
    hip: data.hip ?? null,
    thigh: data.thigh ?? null,
    calf: data.calf ?? null,
  };
};

export const mapUpdateBalanceClientInfoForm = (
  data: FetchClientResponse | undefined,
  lastMeasurement: FetchClientLastMeasurementResponse | undefined,
): UpdateBalanceClientInfoFormInput | undefined => {
  if (!data) return;
  return {
    date: lastMeasurement?.date ?? null,
    growth: lastMeasurement?.growth ?? null,
    weight: lastMeasurement?.weight ?? null,
    bodyFatLevel: lastMeasurement?.bodyFatLevel ?? null,
    arm: lastMeasurement?.arm ?? null,
    tightBiceps: lastMeasurement?.tightBiceps ?? null,
    waist: lastMeasurement?.waist ?? null,
    hip: lastMeasurement?.hip ?? null,
    thigh: lastMeasurement?.thigh ?? null,
    calf: lastMeasurement?.calf ?? null,
    targetWeight:
      data.profile.targetWeight === 0
        ? null
        : data.profile.targetWeight?.toString() ?? null,
    goal: data.profile.targets[0]?.id ?? null,
    sex: (data.profile.sex ? data.profile.sex : Sexes.n) as unknown as Sexes,
    birthDate: data.profile.birthDate ?? null,
  };
};

export interface UpdateBalanceClientInfoFormInput {
  date: Date | null;
  growth: number | null;
  weight: number | null;
  bodyFatLevel: number | null;
  arm: number | null;
  tightBiceps: number | null;
  waist: number | null;
  hip: number | null;
  thigh: number | null;
  calf: number | null;
  targetWeight: string | null;
  goal: number | null;
  sex: Sexes | null;
  birthDate: string | null;
}
