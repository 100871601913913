import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Drop = (props: SvgIconProps) => {
  return (
    <SvgIcon width="10" height="14" viewBox="0 0 10 14">
      <path
        d="M5 13.5C2.30859 13.5 0.125 11.3164 0.125 8.625C0.125 6.30938 3.43086 1.96504 4.35508 0.79707C4.50742 0.606641 4.7334 0.5 4.97715 0.5H5.02285C5.2666 0.5 5.49258 0.606641 5.64492 0.79707C6.56914 1.96504 9.875 6.30938 9.875 8.625C9.875 11.3164 7.69141 13.5 5 13.5ZM2.5625 9.03125C2.5625 8.80781 2.37969 8.625 2.15625 8.625C1.93281 8.625 1.75 8.80781 1.75 9.03125C1.75 10.6029 3.02207 11.875 4.59375 11.875C4.81719 11.875 5 11.6922 5 11.4688C5 11.2453 4.81719 11.0625 4.59375 11.0625C3.47148 11.0625 2.5625 10.1535 2.5625 9.03125Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
