import { GeometricFigures } from "@assets/icons";
import { Nutrients } from "@components/Nutrients";
import { SectionTitleWrapper } from "@components/PreviewDrawer/common/SectionTitleWrapper";
import { useAppTranslation } from "@hooks";
import { Nutrient } from "@typeDefinitions";

interface NutrientsSectionProps {
  nutrients: Nutrient[];
}
export const NutrientsSection = ({ nutrients }: NutrientsSectionProps) => {
  const { t } = useAppTranslation();

  return (
    <SectionTitleWrapper
      Icon={GeometricFigures}
      title={t("common.micronutrients")}
    >
      <Nutrients values={nutrients} />
    </SectionTitleWrapper>
  );
};
