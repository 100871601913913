import { Button, Stack } from "@mui/material";
import AddPatientProgramDialog from "@views/dietician/PatientProgramsNew/AddPatientProgramDialog/AddPatientProgramDialog";
import { useAppTranslation } from "@hooks";
import { useState } from "react";
import { PlusIcon } from "@icons/index";

type AddPatientProgramButtonProps = {
  patientId: number;
};

const AddPatientProgramButton = ({
  patientId,
}: AddPatientProgramButtonProps) => {
  const { t } = useAppTranslation();
  const [openAddPatientProgramDialog, setOpenAddPatientProgramDialog] =
    useState(false);
  return (
    <Stack direction="row" justifyContent="start">
      <Button
        sx={{
          "&.MuiButtonBase-root": {
            height: "unset",
            padding: "7px 12px",
            lineHeight: "24px",
          },
          ".MuiButton-startIcon": { marginRight: "4px", fontSize: "24px" },
          ".MuiButton-startIcon>*:nth-of-type(1)": { fontSize: "24px" },
        }}
        startIcon={<PlusIcon color="#7448D0" />}
        variant="outlined"
        onClick={() => setOpenAddPatientProgramDialog(true)}
        color="primary"
      >
        {t("patient.programs.create_modal.button")}
      </Button>
      <AddPatientProgramDialog
        patientId={patientId}
        open={openAddPatientProgramDialog}
        setOpen={setOpenAddPatientProgramDialog}
      />
    </Stack>
  );
};

export default AddPatientProgramButton;
