import { Stack } from "@mui/material";
import { Dispatch } from "react";
import TypeDayChip from "@components/ProgramSearchPreview/Components/TypeDaysChips/TypeDayChip";
import { PatientProgramPreviewSearchResource } from "@client/resources/PatientProgramPreviewSearchResource";

type TypeDaysChipsProps = {
  programDays: PatientProgramPreviewSearchResource["programDays"];
  selectedDay: number;
  setSelectedDay: Dispatch<number>;
};

const TypeDays = ({
  programDays,
  selectedDay,
  setSelectedDay,
}: TypeDaysChipsProps) => {
  return (
    <Stack direction="row" gap="8px" flexWrap="wrap">
      {programDays.map(day => (
        <TypeDayChip
          key={day.id}
          day={day}
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
        />
      ))}
    </Stack>
  );
};

export default TypeDays;
