import * as yup from "yup";

import {
  IdDto,
  MainProductCategoryDto,
  TranslationDto,
  mainProductCategorySchema,
  translationSchema,
} from "@client/common";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const fetchDietProductsList = async (
  dietId: string,
): Promise<ApiResponse<ProductInDietDto[]>> => {
  const response = await fetchData(
    `/dietitian/diets/${dietId}/products-list`,
    APIMethods.GET,
  );

  return responseSchema.validate(response);
};

interface TranslationLangDto {
  lang: string;
  name: string;
}

interface TagCategoryDto {
  id: number | null;
}

interface TagDto {
  id: number;
  systemId: number | null;
  name: string;
  translations: TranslationDto[];
  clinicN: IdDto | null;
  tagCategory: TagCategoryDto;
}

interface ProductDto {
  id: number;
  translations: TranslationLangDto[];
  tags: TagDto[];
}

export interface ProductInDietDto {
  product: ProductDto;
  mainProductCategory: MainProductCategoryDto;
  grams: number;
  wasExchange: boolean;
}

const translationLangSchema = yup.object({
  lang: yup.string().required(),
  name: yup.string().ensure(),
});

const idSchema = yup.object({
  id: yup.number().required(),
});

const tagCategorySchema = yup.object({
  id: yup.number().nullable().default(null),
});

const tagSchema = yup.object({
  id: yup.number().required(),
  systemId: yup.number().nullable().default(null),
  name: yup.string().required(),
  translations: yup.array().of(translationSchema).required(),
  clinicN: idSchema.nullable().default(null),
  tagCategory: tagCategorySchema,
});

const productSchema = yup.object({
  id: yup.number().required(),
  translations: yup.array().of(translationLangSchema).required(),
  tags: yup.array().of(tagSchema).required(),
});

const productInDietSchema = yup.object({
  product: productSchema.required(),
  mainProductCategory: mainProductCategorySchema.required(),
  grams: yup.number().required(),
  wasExchange: yup.boolean().required(),
});

const responseSchema = yup.object().shape({
  data: yup.array().of(productInDietSchema).required(),
});
