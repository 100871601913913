import { useQuery } from "@tanstack/react-query";

import { QueryOptions } from "./types";
import { buildDefaultQueryOptions } from "./common";
import { fetchClientAdditionalFeatures } from "@client";

const fetchClientAdditionalFeaturesQueryKey =
  "fetchClientAdditionalFeaturesQueryKey";

export const useFetchClientAdditionalFeaturesQuery = (
  options?: QueryOptions,
) => {
  const { data, ...rest } = useQuery(
    [fetchClientAdditionalFeaturesQueryKey],
    fetchClientAdditionalFeatures,
    buildDefaultQueryOptions(options),
  );

  return {
    additionalFeatures: data?.data,
    ...rest,
  };
};
