import { motion } from "framer-motion";

import { useAppTranslation } from "@hooks";
import { VARIANTS } from "@utils/constants";
import { RecipeIngredientMacroFormInput } from "@typeDefinitions/types";

import { IngredientDetails, IngredientLine } from "./components";
import { Spinner } from "@components/Spinner";
import { MacrosList } from "@components/MacrosList";

export const IngredientsTable = (props: IngredientsTableProps) => {
  const {
    ingredients,
    ingredientsError,
    ingredientsStatus,
    macrosSummed,
    glycaemicIndicators,
  } = props;

  const { t } = useAppTranslation();

  if (ingredientsStatus === "loading")
    return <Spinner className="w-full h-96 rounded-md" />;

  if (ingredientsError) return <p className="mb-6">{t("common.error")}</p>;

  if (ingredients && ingredients.length === 0)
    return <p className="mb-6">{t("errors.noResults")}</p>;

  return (
    <div className="grid grid-cols-12 mb-6">
      {/** header */}
      <div className="col-span-12 grid grid-cols-12 bg-gray-300 px-3 h-14 rounded-t-md border-t border-r border-l border-gray-400 items-center">
        <div className="col-span-5">{t("recipe.ingredients_table.title")}</div>

        <div className="col-span-4 grid grid-cols-12 text-center">
          <p className="col-span-3">{t("recipe.ingredients_table.grams")}</p>
          <p className="col-span-3">{t("recipe.ingredients_table.amount")}</p>
          <p className="col-span-6">
            {t("recipe.ingredients_table.other_measure")}
          </p>
        </div>

        <div className="col-span-3 text-center">
          {t("recipe.ingredients_table.nutritional_values")}
        </div>
      </div>
      {/** body */}
      {ingredients &&
        ingredients.map((ingredient, index) => (
          <IngredientLine
            ingredient={ingredient}
            key={index}
            index={index}
            glycaemicIndicators={
              glycaemicIndicators ? glycaemicIndicators[index] : undefined
            }
          />
        ))}
      {/** summary */}
      <motion.div
        custom={ingredients ? ingredients.length + 1 : 1}
        variants={VARIANTS}
        initial="hidden"
        animate="visible"
        className="col-span-12 grid grid-cols-12"
      >
        <div className="col-span-9 flex items-center justify-end font-semibold p-3">
          {t("recipe.ingredients_table.sum")}:
        </div>

        <div className="col-span-3 grid grid-cols-6 bg-gray-300 border-b border-l border-r border-gray-400 rounded-b-md">
          <div className="col-span-6 text-sm gap-1 px-2 flex items-center ">
            <MacrosList nutrients={macrosSummed} compact />
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export interface GlycaemicIndicators {
  ig?: number;
  ww?: number;
}

interface IngredientsTableProps {
  ingredients: IngredientDetails[];
  ingredientsStatus: "success" | "loading" | "error";
  ingredientsError: any;
  macrosSummed: RecipeIngredientMacroFormInput[];
  glycaemicIndicators?: GlycaemicIndicators[];
  glycaemicIndex?: number;
  glycaemicLoad?: number;
  wwPerRecipe?: number;
}
