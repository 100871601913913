import { styled } from "@mui/material";

export const ChartWrapper = styled("div")`
  display: flex;
  width: 100%;
  overflow: hidden;
  border-radius: 70px;
  height: 8px;
`;

interface BarPartProps {
  width?: number;
  background?: string;
}
export const BarPart = styled("div")<BarPartProps>`
  height: 100%;
  width: ${({ width }) => `${width}%`};
  background: ${({ background }) => background};
`;
