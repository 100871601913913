import { useDebounce } from "react-use";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { FetchFoodDetailsResponse } from "@client/fetchFood";
import {
  UpdateFoodEditorMutationParams,
  useUpdateFoodEditorMutation,
} from "@hooks/queries/food";

import { DietitianProductFormInput, mapProductRequest } from "../ProductForm";

interface ProductEditWatchProps {
  product: FetchFoodDetailsResponse;
  productLoading: boolean;
}

export const ProductEditWatch = ({
  product,
  productLoading,
}: ProductEditWatchProps) => {
  const values = useWatch();
  const {
    handleSubmit,
    formState: { isDirty },
  } = useFormContext<DietitianProductFormInput>();
  const { mutate, isLoading } = useUpdateFoodEditorMutation();

  const [queued, setQueued] = useState(false);

  const createRequest = (
    data: DietitianProductFormInput,
    product: FetchFoodDetailsResponse,
  ): UpdateFoodEditorMutationParams => {
    return {
      id: product.id,
      data: mapProductRequest(data, product),
    };
  };

  const update = () => {
    setQueued(false);
    handleSubmit(
      data => mutate(createRequest(data, product)),
      err => console.log(err),
    )();
  };

  const [isDirtyLatch, setIsDirtyLatch] = useState(isDirty);

  useDebounce(
    () => {
      if (isLoading || productLoading) {
        setQueued(true);
      }

      if (
        (isDirty || isDirtyLatch) &&
        values &&
        product &&
        !(isLoading || productLoading)
      ) {
        setIsDirtyLatch(true);
        update();
      }
    },
    1000,
    [JSON.stringify(values)],
  );

  useEffect(() => {
    if (queued && !(isLoading || productLoading)) {
      update();
    }
  }, [queued, isLoading || productLoading]);

  return <></>;
};
