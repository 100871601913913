import { IdDto, LangDto, idSchema } from "@client/common";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import * as yup from "yup";

export const postSurvey = async (
  payload: PostSurveyRequest,
): Promise<ApiResponse<IdDto>> => {
  const response = await fetchData(
    "/dietitian/surveys",
    APIMethods.POST,
    payload,
  );

  return postSurveyResponseSchema.validate(response);
};

export interface PostSurveyRequest {
  langId: LangDto;
  name: string;
  introduction: IntroductionDto;
}

interface IntroductionDto {
  on: boolean;
  title: string | null;
  description: string | null;
}

const postSurveyResponseSchema = yup.object().shape({
  data: idSchema.required(),
});
