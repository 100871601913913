import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  FetchMealResponse,
  patchCreatorRecipeServings,
  PatchCreatorRecipeServingsRequest,
} from "@client/meals";
import { MutationOptions, fetchCreatorMealQueryKey } from "@hooks/queries";
import { ApiResponse } from "@typeDefinitions";
import { useFetchMealTagsQueryKey } from "./useFetchCreatorMealTags";
import { fetchCreatorMealNutrientsQueryKey } from "./useFetchCreatorMealNutrientsQuery";
import { useMealVersion } from "@views/dietician/MealEdit/hooks";

export const usePatchCreatorRecipeServingsMutation = (
  mealId: string,
  recipeId: string,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();
  const { version } = useMealVersion();

  const mutation = useMutation<
    unknown,
    unknown,
    PatchCreatorRecipeServingsRequest
  >(payload => patchCreatorRecipeServings(mealId, recipeId, version, payload), {
    ...options,
    onSuccess: (data, variables) => {
      options?.onSuccess && options.onSuccess(data, variables);
      queryClient.invalidateQueries([useFetchMealTagsQueryKey, mealId]);
      queryClient.invalidateQueries([
        fetchCreatorMealNutrientsQueryKey,
        mealId,
      ]);
    },
    onMutate: async servingsRequest => {
      await queryClient.cancelQueries([fetchCreatorMealQueryKey, mealId]);
      queryClient.setQueryData<ApiResponse<FetchMealResponse>>(
        [fetchCreatorMealQueryKey, mealId],
        old => {
          if (!old) return;
          return {
            ...old,
            data: {
              ...old.data,
              version: old.data.version + 1,
              recipes: old.data.recipes.map(r => {
                if (r.id.toString() !== recipeId) return r;
                else
                  return {
                    ...r,
                    servings: servingsRequest.servings,
                  };
              }),
            },
          };
        },
      );
    },
    onError: () => {
      queryClient.invalidateQueries([fetchCreatorMealQueryKey, mealId]);
    },
  });

  return mutation;
};
