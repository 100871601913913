import { Box, IconButton } from "@mui/material";
import { ScheduleVisitModalTitle } from "../ScheduleVisitModal.styled";
import { Close } from "@assets/icons";
import type { FC } from "react";
import { useAppTranslation } from "@hooks";
interface ScheduleModalHeaderProps {
  onCloseWithoutSubmit: () => void;
}

export const ScheduleModalHeader: FC<ScheduleModalHeaderProps> = ({
  onCloseWithoutSubmit,
}) => {
  const { t } = useAppTranslation();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding="16px"
      paddingBottom="0"
    >
      <ScheduleVisitModalTitle>{t("calendar.create")}</ScheduleVisitModalTitle>
      <IconButton onClick={onCloseWithoutSubmit}>
        <Close />
      </IconButton>
    </Box>
  );
};
