import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "../types";
import { fetchPatientVisitsQueryKey } from "./useFetchPatientVisitsQuery";
import { addFoodGroupToVisit } from "@client/visits";

interface FoodPayload {
  foodGroupId: number;
  isFavourite: boolean;
}

export const useAddVisitFoodGroupMutation = (
  patientId: number,
  scheduleId: number,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ foodGroupId, isFavourite }: FoodPayload) =>
      addFoodGroupToVisit(scheduleId, foodGroupId, { isFavourite }),
    {
      ...options,
      onSuccess() {
        queryClient.invalidateQueries([fetchPatientVisitsQueryKey, patientId]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};
