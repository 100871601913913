import { Calendar } from "@assets/icons";
import { DateRangePicker, WEEKS_LIST } from "@components/DateRangePicker";
import { CalendarButtonProps } from "@components/DateRangePicker/CalendarCustomButtons";
import { useAppTranslation } from "@hooks";
import { TextField, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";

interface MonitoringChartDatePickerProps {
  startDate: Date | null;
  endDate: Date | null;
  onRangeChange: (dates: [Date | null, Date | null]) => void;
}

export const MonitoringChartDatePicker = ({
  startDate,
  endDate,
  onRangeChange,
}: MonitoringChartDatePickerProps) => {
  const { palette } = useTheme();
  const { t } = useAppTranslation();
  const [selectedButton, setSelectedButton] = useState(1);

  const handleRangeSubtraction = (buttonId: number) => {
    const days = buttonId * 7;
    onRangeChange([dayjs().subtract(days, "days").toDate(), dayjs().toDate()]);
    setSelectedButton(buttonId);
  };

  const customButtons: CalendarButtonProps[] = WEEKS_LIST.map((week, id) => ({
    id,
    label: `${week} ${t("monitoring.untill_today")}`,
    onClick: () => handleRangeSubtraction(id),
    selected: selectedButton === id,
  }));

  return (
    <DateRangePicker
      endDate={endDate}
      startDate={startDate}
      onRangeChange={onRangeChange}
      inline={false}
      maxDate={dayjs(startDate).add(31, "day").toDate()}
      customInput={
        <TextField
          size="small"
          InputProps={{
            endAdornment: (
              <Calendar size="w-5 h-5" fill={palette.primary.main} />
            ),
          }}
        />
      }
      customButtons={customButtons}
    />
  );
};
