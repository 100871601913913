import { Collapse, styled, useTheme } from "@mui/material";
import CalendarIcon from "@assets/icons_new/calendar.svg?react";
import PieChartIcon from "@assets/icons_new/pieChart.svg?react";
import dayjs from "dayjs";

interface UsedInEntry {
  date: Date;
  name: string;
  hour: string;
  selectedServings: number;
}
interface RecipeCollapseProps {
  open: boolean;
  totalServings: number;
  meals: UsedInEntry[];
}
export const RecipeCollapse = ({
  open,
  meals,
  totalServings,
}: RecipeCollapseProps) => {
  const { colors } = useTheme();
  return (
    <Collapse in={open}>
      <Wrapper>
        {meals.map(({ date, name, hour, selectedServings }) => (
          <Item key={date.toString() + hour}>
            <CalendarIcon fontSize={14} color={colors.neutral.dark[700]} />
            <Text>
              {dayjs(date).format("dd DD")}, {name} {hour},
            </Text>
            <PieChartIcon fontSize={14} color={colors.neutral.dark[700]} />
            <Text>
              {selectedServings}/{totalServings}
            </Text>
          </Item>
        ))}
      </Wrapper>
    </Collapse>
  );
};

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const Item = styled("div")`
  display: flex;
  gap: 6px;
  align-items: center;
  padding: 12px;
  background: #fff;
  border-radius: 8px;
`;

const Text = styled("span")`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;
