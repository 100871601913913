import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { ApiEndpoints } from "@typeDefinitions";
import { useAppTranslation } from "@hooks/useAppTranslation";
import { updateNote } from "@client/notes/updateNote";
import type { UpdateNoteRequestPayload } from "@client/notes/updateNote";

export const useUpdateNoteMutation = () => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation<
    AxiosResponse,
    AxiosError,
    UpdateNoteRequestPayload
  >(data => updateNote(data), {
    onSuccess: () => {
      queryClient.invalidateQueries([ApiEndpoints.AccountNotes]);

      toast.success(t("notes.updateSuccess"));
    },
  });

  const handleUpdateNote = async (data: UpdateNoteRequestPayload) => {
    try {
      await mutateAsync(data);
    } catch {
      toast.error(t("notes.updateFailure"));
    }
  };

  return { handleUpdateNote };
};
