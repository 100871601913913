import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import * as yup from "yup";
import { validateAndFixNutrients } from "./utils/validateCatching";

export async function requestDietNutrients(
  id: number,
): Promise<FetchNutrientsResponse[]> {
  const { data } = await fetchData(
    `/dietitian/diets/${id}/nutrients`,
    APIMethods.GET,
  );
  return await validateAndFixNutrients(fetchNutrientsResponseSchema, data);
}

export async function requestRecipesNutrients(
  id: number,
): Promise<FetchNutrientsResponse[]> {
  const { data } = await fetchData(
    `/dietitian/recipes/${id}/nutrients`,
    APIMethods.GET,
  );
  return await validateAndFixNutrients(fetchNutrientsResponseSchema, data);
}

export async function requestMealPatternsNutrients(
  id: number,
): Promise<FetchNutrientsResponse[]> {
  const { data } = await fetchData(
    `/dietitian/meal-patterns/${id}/nutrients`,
    APIMethods.GET,
  );
  return await validateAndFixNutrients(fetchNutrientsResponseSchema, data);
}

export async function requestFoodNutrients(
  id: number,
): Promise<FetchNutrientsResponse[]> {
  const { data } = await fetchData(
    `/dietitian/food/${id}/nutrients`,
    APIMethods.GET,
  );
  return await validateAndFixNutrients(fetchNutrientsResponseSchema, data);
}

const fetchNutrientsResponseSchema = yup.array().of(
  yup.object().shape({
    units: yup.string().nullable(),
    value: yup.number().required(),
    id: yup.number().required(),
    nutrientCategoryId: yup.number().required(),
    descriptionEn: yup.string().required(),
    descriptionPl: yup.string().required(),
    dietitianVisible: yup.boolean().required(),
    patientVisible: yup.boolean().required(),
    diaryVisible: yup.boolean().required(),
  }),
);

interface FetchNutrientsResponse {
  id: number;
  nutrientCategoryId: number;
  descriptionEn: string;
  descriptionPl: string;
  dietitianVisible: boolean;
  patientVisible: boolean;
  diaryVisible: boolean;
  units?: string;
  value: number;
}
