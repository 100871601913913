import { useMemo, useState } from "react";
import { useWatch } from "react-hook-form";

import {
  TagAutocompleteFilterWrapper,
  FilterType,
  FunnelButton,
  MultiSelectFilterWrapper,
  SliderFilterWrapper,
} from "@components/Filters";
import { FiltersDrawer } from "@components/Filters/FiltersDrawer";
import { ListTabs, useAppTranslation, useListTabs, useUserRoles } from "@hooks";
import { useFetchClinicDietitiansSimpleListQuery } from "@hooks/queries/settings";
import { useTagsNew } from "@hooks/useTagsNew";
import { decimalInput1ThreeDigits } from "@utils/inputs";
import {
  CARBS_ID,
  ENERGY_ID,
  FATS_ID,
  MicrosFilters,
  PROTEIN_ID,
} from "@utils/macros";
import { TagCategoryType } from "@utils/tagsNew";

import { defaultFilters } from "./dietsFiltersUtils";
import {
  DEFAULT_DIETS_MACROS_RANGE,
  DietsFiltersFormInput,
} from "./useDietsFiltersForm";

interface DietsFiltersProps {
  total?: number;
  onSubmit?: (values: DietsFiltersFormInput) => void;
  unshiftTabs?: boolean;
}

export const DietsFilters = ({
  total,
  onSubmit,
  unshiftTabs,
}: DietsFiltersProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useAppTranslation();
  const { unshiftListTabFilter } = useListTabs();
  const { isAdminClinicMember } = useUserRoles();
  const tags = useWatch<DietsFiltersFormInput, "tags">({ name: "tags" });
  const nutrients = useWatch<DietsFiltersFormInput, "nutrients">({
    name: "nutrients",
  });
  const tabs = useWatch<DietsFiltersFormInput, "tabs">({
    name: "tabs",
  });
  const numberOfMeals = useWatch<DietsFiltersFormInput, "numberOfMeals">({
    name: "numberOfMeals",
  });

  const { dietitians } = useFetchClinicDietitiansSimpleListQuery();
  const { getTagCategoryOptions, getTagCategoryName } = useTagsNew();

  const countPositiveValues = useMemo(() => {
    if (!nutrients) return 0;

    return Object.entries(nutrients).reduce((acc, [key, value]) => {
      if (!value) return acc;

      const [min, max] = value;
      const defaultValue = DEFAULT_DIETS_MACROS_RANGE[key];

      const isDefaultValue = min === defaultValue[0] && max === defaultValue[1];
      const isEmptyValue = max === 0;

      if (!isDefaultValue && !isEmptyValue) acc += 1;

      return acc;
    }, 0);
  }, [nutrients]);

  const tabsTag = unshiftTabs ? Number(tabs !== ListTabs.ALL) : 0;

  const allTags =
    (tags?.length ?? 0) +
    countPositiveValues +
    Number(numberOfMeals?.length) +
    tabsTag;

  const dietsMapped = useMemo(
    () => getTagCategoryOptions(TagCategoryType.DIET) ?? [],
    [t],
  );

  const eliminationsMapped = useMemo(
    () => getTagCategoryOptions(TagCategoryType.ELIMINATIONS) ?? [],
    [t],
  );

  const allergendMapped = useMemo(
    () => getTagCategoryOptions(TagCategoryType.ALLERGENS) ?? [],
    [t],
  );

  const dietitiansMapped = useMemo(
    () => dietitians?.map(d => ({ id: d.id.toString(), label: d.name })) ?? [],
    [dietitians],
  );

  const programFilters: (
    | MultiSelectFilterWrapper<DietsFiltersFormInput>
    | TagAutocompleteFilterWrapper
  )[] = useMemo(
    () => [
      {
        type: FilterType.TagAutocomplete,
        props: {
          label: getTagCategoryName(TagCategoryType.DIET) ?? "",
          options: dietsMapped,
          placeholder: t("diets.diet_placeholder"),
        },
      },
      {
        type: FilterType.MultiSelect,
        props: {
          label: getTagCategoryName(TagCategoryType.ELIMINATIONS) ?? "",
          options: eliminationsMapped,
        },
      },
      {
        type: FilterType.TagAutocomplete,
        props: {
          label: getTagCategoryName(TagCategoryType.ALLERGENS) ?? "",
          options: allergendMapped,
          placeholder: t("diets.allergens_placeholder"),
        },
      },
      {
        type: FilterType.MultiSelect,
        props: {
          label: t("diets.meals_number"),
          options: [
            { id: MealsCount.ONE, label: MealsCount.ONE },
            { id: MealsCount.TWO, label: MealsCount.TWO },
            { id: MealsCount.THREE, label: MealsCount.THREE },
            { id: MealsCount.FOUR, label: MealsCount.FOUR },
            { id: MealsCount.FIVE, label: MealsCount.FIVE },
            { id: MealsCount.SIX, label: MealsCount.SIX },
            { id: MealsCount.SEVEN, label: MealsCount.SEVEN },
            { id: MealsCount.MORE, label: `>${MealsCount.SEVEN}` },
          ],
          name: "numberOfMeals",
        },
      },
    ],
    [dietitiansMapped, t],
  );

  const nutrientsFilters: SliderFilterWrapper<DietsFiltersFormInput>[] = [
    {
      type: FilterType.Slider,
      props: {
        nutrientId: ENERGY_ID.toString(),
        label: t("common.energy_efficiency"),
        maxValue: 4500,
        step: 50,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: PROTEIN_ID.toString(),
        maxValue: 200,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: FATS_ID.toString(),
        maxValue: 200,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: CARBS_ID.toString(),
        maxValue: 800,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.SATURATED_FAT.toString(),
        maxValue: 30,
        step: 1,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.CHOLESTEROL.toString(),
        maxValue: 500,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.DIETARY_FIBER.toString(),
        maxValue: 50,
        step: 1,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.SODIUM.toString(),
        maxValue: 2500,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.POTASSIUM.toString(),
        maxValue: 4000,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.CALCIUM.toString(),
        maxValue: 1300,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.PHOSPHORUS.toString(),
        maxValue: 1500,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.MAGNESIUM.toString(),
        maxValue: 500,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.IRON.toString(),
        maxValue: 30,
        step: 1,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.TOTAL_FOLATE.toString(),
        maxValue: 600,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.KOBALAMINA.toString(),
        maxValue: 3,
        step: 0.1,
        decimalInput: decimalInput1ThreeDigits,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.GLYCEMIC_LOAD.toString(),
        maxValue: 200,
        step: 1,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.CARBOHYDRATE_EXCHANGERS.toString(),
        maxValue: 25,
        step: 1,
      },
    },
  ];

  return (
    <>
      <FunnelButton allTags={allTags} open={open} setOpen={setOpen} />
      <FiltersDrawer
        tabs={[t("common.diet"), t("recipes.filters.nutrients")]}
        filtersLists={[
          unshiftTabs
            ? unshiftListTabFilter(
                programFilters,
                t("common.diets"),
                isAdminClinicMember,
              )
            : programFilters,
          nutrientsFilters,
        ]}
        onClose={() => setOpen(false)}
        onSubmit={onSubmit}
        open={open}
        total={total}
        resetValues={{
          tabs: tabs,
          ...defaultFilters,
        }}
      />
    </>
  );
};

export enum MealsCount {
  ONE = "1",
  TWO = "2",
  THREE = "3",
  FOUR = "4",
  FIVE = "5",
  SIX = "6",
  SEVEN = "7",
  MORE = "8",
}
