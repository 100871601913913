import _ from "lodash";

import { ApiResponse } from "@typeDefinitions";
import {
  UpdateProgramDayNutrientsRequest,
  UpdateProgramDaysInitialRequestDto,
  UpdateProgramDaysRequestDto,
} from "@client";
import { FetchProgramResponse } from "@client/program";

export function programOptimisticUpdate(
  request: UpdateProgramDaysRequestDto,
  old?: ApiResponse<FetchProgramResponse>,
): ApiResponse<FetchProgramResponse> | undefined {
  if (!old) return;

  const oldDays = _(old.data.days)
    .map(day => ({ id: day.id, diet: day.diet, target: day.targetNutrients }))
    .groupBy("id")
    .mapValues(val => val[0])
    .value();

  const getOldDay = (id: number | null) => {
    if (!id || !oldDays[id]) return;
    return oldDays[id];
  };

  return {
    ...old,
    data: {
      ...old.data,
      days: request.days.map(day => {
        const oldDayData = getOldDay(day.id);

        return {
          id: day.id ?? 0,
          name: day.name,
          nameEn: day.nameEn || day.name,
          frequency: day.frequency,
          diet: oldDayData?.diet ? oldDayData.diet : null,
          targetNutrients: oldDayData?.target
            ? oldDayData.target
            : {
                norm: null,
                nutrients: [],
              },
        };
      }),
    },
  };
}

export function programOptimisticUpdateInitial(
  request: UpdateProgramDaysInitialRequestDto,
  old?: ApiResponse<FetchProgramResponse>,
): ApiResponse<FetchProgramResponse> | undefined {
  if (!old) return;

  const oldDays = _(old.data.days)
    .map(day => ({ id: day.id, diet: day.diet, target: day.targetNutrients }))
    .groupBy("id")
    .mapValues(val => val[0])
    .value();

  const getOldDay = (id: number | null) => {
    if (!id || !oldDays[id]) return;
    return oldDays[id];
  };

  return {
    ...old,
    data: {
      ...old.data,
      days: request.days.map(day => {
        const oldDayData = getOldDay(day.id);

        return {
          id: day.id ?? 0,
          name: day.name,
          nameEn: day.nameEn || day.name,
          frequency: day.frequency,
          diet: oldDayData?.diet ? oldDayData.diet : null,
          targetNutrients: oldDayData?.target
            ? oldDayData.target
            : {
                norm: day.norm,
                nutrients: day.targetNutrients,
              },
        };
      }),
    },
  };
}

export function programNutrientsOptimisticUpdate(
  request: UpdateProgramDayNutrientsRequest,
  dayId: string,
  old?: ApiResponse<FetchProgramResponse>,
): ApiResponse<FetchProgramResponse> | undefined {
  if (!old) return;

  return {
    ...old,
    data: {
      ...old.data,
      days: old.data.days.map(day => {
        if (day.id.toString() === dayId) {
          return {
            ...day,
            targetNutrients: {
              norm: { id: parseInt(request.normId) ?? null },
              nutrients: request.nutrients,
            },
          };
        }
        return day;
      }),
    },
  };
}
