import { Typography, styled } from "@mui/material";

export const TextFieldWrapper = styled("div")`
  position: relative;

  & .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
    color: ${({ theme }) => theme.palette.grey[500]};
    white-space: pre-line;
  }
`;

export const StyledTypography = styled(Typography)`
  position: absolute;
  width: max-content;
  padding: 0.25rem;
  left: 0.625rem;
  top: -0.625rem;
  background: ${({ theme }) => theme.colors.whiteBackground};
  font-size: 0.75rem;
  color: ${({ theme }) => theme.palette.grey[700]};
`;
