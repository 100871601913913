import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const PercentageCircle = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      style={{ fill: "none" }}
      width="21"
      height="21"
      viewBox="0 0 21 21"
    >
      <path
        d="M7.49875 13.3619L13.2212 7.63937M7.8575 9.07438C8.14294 9.07438 8.41669 8.96099 8.61852 8.75915C8.82036 8.55731 8.93375 8.28356 8.93375 7.99813C8.93375 7.71269 8.82036 7.43894 8.61852 7.2371C8.41669 7.03527 8.14294 6.92188 7.8575 6.92188C7.57206 6.92188 7.29831 7.03527 7.09648 7.2371C6.89464 7.43894 6.78125 7.71269 6.78125 7.99813C6.78125 8.28356 6.89464 8.55731 7.09648 8.75915C7.29831 8.96099 7.57206 9.07438 7.8575 9.07438ZM13.58 14.0794C13.8654 14.0794 14.1392 13.966 14.341 13.7641C14.5429 13.5623 14.6562 13.2886 14.6562 13.0031C14.6562 12.7177 14.5429 12.4439 14.341 12.2421C14.1392 12.0403 13.8654 11.9269 13.58 11.9269C13.2946 11.9269 13.0208 12.0403 12.819 12.2421C12.6171 12.4439 12.5037 12.7177 12.5037 13.0031C12.5037 13.2886 12.6171 13.5623 12.819 13.7641C13.0208 13.966 13.2946 14.0794 13.58 14.0794Z"
        stroke="#801FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 19.25C15.3326 19.25 19.25 15.3326 19.25 10.5C19.25 5.66738 15.3326 1.75 10.5 1.75C5.66738 1.75 1.75 5.66738 1.75 10.5C1.75 15.3326 5.66738 19.25 10.5 19.25Z"
        stroke="#801FFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
