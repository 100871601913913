import { Path } from "react-hook-form";
import { TreeSelectOption } from "@components/TreeSelect";

export enum FilterType {
  SINGLE,
  MULTI,
  RANGE,
  TREE_SELECT,
}

export interface NumberRange {
  from?: number;
  to?: number;
}

export interface Option {
  value?: any;
  label: string;
}

interface BaseFilterDefinition<T> {
  type: FilterType;
  name: Path<T>;
  label: string;
}

export interface SingleSelectFilterDefinition<T>
  extends BaseFilterDefinition<T> {
  type: FilterType.SINGLE;
  options: Option[];
  adminOnly?: boolean;
  clinicOnly?: boolean;
}

export interface MultiSelectFilterDefinition<T>
  extends BaseFilterDefinition<T> {
  type: FilterType.MULTI;
  options: Option[];
}

export interface RangeFilterDefinition<T> extends BaseFilterDefinition<T> {
  type: FilterType.RANGE;
}

export interface TreeSelectFilterDefinition<T> extends BaseFilterDefinition<T> {
  type: FilterType.TREE_SELECT;
  options: TreeSelectOption[];
}

export type FilterDefinition<T> =
  | "spacer"
  | SingleSelectFilterDefinition<T>
  | MultiSelectFilterDefinition<T>
  | RangeFilterDefinition<T>
  | TreeSelectFilterDefinition<T>;
