import { CSSProperties, useCallback, useRef, useState } from "react";

import { TreeView } from "@mui/lab";
import { TreeNode, TreeSelectOption } from "./TreeNode";
import { useTreeSelectTitle } from "./useTreeSelectTitle";
import { Input, InputProps } from "@components/Input";
import { InputEndIcon } from "@components/InputEndIcon";
import { PopMenu } from "@components/PopMenu";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export interface FlatTreeSelectOption {
  value: string;
  title: string;
  parents: string[];
}

interface TreeSelectProps extends InputProps {
  variant?: "standard" | "outlined" | "clean";
  menuStyle?: CSSProperties;
  value?: string;
  onChange: (value: string) => void;
  options: TreeSelectOption[];
  error?: string;
  inputValue?: string;
  className?: string;
}

export const TreeSelect = ({
  variant = "standard",
  menuStyle,
  options,
  onChange,
  value,
  error,
  inputValue,
  className,
  ...rest
}: TreeSelectProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [focused, setFocus] = useState(false);

  const enableFocus = useCallback(() => setFocus(true), []);
  const disableFocus = useCallback(() => setFocus(false), []);
  const toggleFocus = useCallback(() => setFocus(focus => !focus), []);

  const onSelect = useCallback(
    (value: string) => {
      onChange(value);
      disableFocus();
    },
    [onChange],
  );

  const title = useTreeSelectTitle(options, value);

  return (
    <div ref={containerRef} className={className}>
      <Input
        variant={variant}
        value={inputValue ?? title}
        readOnly
        onFocus={enableFocus}
        focused={focused}
        bgTransparent={true}
        suffix={
          <InputEndIcon
            variant={variant}
            focused={focused}
            onMouseUp={toggleFocus}
          />
        }
        error={error}
        {...rest}
      />
      <PopMenu
        anchor={containerRef}
        open={focused}
        onClose={disableFocus}
        offset={1}
        className="w-full"
        matchWidth={true}
        style={{ ...menuStyle, zIndex: 10000 }}
        itemsCenter={false}
      >
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          sx={{
            "flexGrow": 1,
            "overflowY": "auto",
            ".MuiTreeItem-content": { py: "4px" },
            ".MuiTreeItem-label": { fontSize: ".875rem !important" },
          }}
        >
          <TreeNode options={options} onClick={onSelect} />
        </TreeView>
      </PopMenu>
    </div>
  );
};
