import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useAppTranslation } from "@hooks";
import { toast } from "react-toastify";
import { MutationOptions } from "../types";
import { fetchClinicFacilitiesQueryKey } from "./useFetchClinicFacilitiesQuery";
import {
  CreateClinicFacilityRequest,
  createClinicFacility,
} from "@client/settings";

export const useCreateClinicFacility = (options?: MutationOptions) => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, CreateClinicFacilityRequest>(
    data => createClinicFacility(data),
    {
      ...options,
      onSuccess: () => {
        toast.success(t("company_settings.office_added"));
        queryClient.invalidateQueries([fetchClinicFacilitiesQueryKey]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};
