import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const updateSurveyGroup = async (
  surveyId: string,
  payload: UpdateSurveyGroupRequest,
) => {
  await fetchData(
    `/dietitian/survey-groups/${surveyId}`,
    APIMethods.PUT,
    payload,
  );
};

export interface UpdateSurveyGroupRequest {
  title: string;
  description: string | null;
}
