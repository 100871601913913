import { Close, EmptyCalendar, Person } from "@assets/icons";
import { GoogleScheduleEventDto, SearchScheduleEventDto } from "@client";
import { useAppTranslation } from "@hooks";
import {
  searchScheduleEventsQueryKey,
  useFetchClientQuery,
  useFetchClientQueryNew,
} from "@hooks/queries";
import { Box, Divider } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { ApiResponse } from "@typeDefinitions";
import { format } from "date-fns";
import { FC } from "react";
import { useCalendarFilters } from "../../Calendar/_hooks/CalendarFilters.context";
import { useCalendarFilterOptions } from "../../Calendar/_hooks/useCalendarFilterOptions";
import {
  DividerStyled,
  HeaderTitle,
  StyledMenu,
  VisitOverViewContent,
  VisitOverViewHeader,
  VisitOverviewItem,
  VisitOverviewItemContent,
  VisitOverviewItemLabel,
  VisitOverviewItemText,
  VisitOverviewNavigationIconButton,
  VisitOverviewWrapper,
} from "./ScheduleVisitOverviewModal.styled";
import { GoogleMeetComponent } from "./GoogleMeetComponent";
import { PatientItemContent } from "./PatientItemContent";

interface ScheduleGoogleEventOverviewModalProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  googleId: string;
}

export const ScheduleGoogleEventOverviewModal: FC<
  ScheduleGoogleEventOverviewModalProps
> = ({ anchorEl, onClose, googleId }) => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();
  const [params] = useCalendarFilters();
  const data = queryClient.getQueryData<
    ApiResponse<(SearchScheduleEventDto | GoogleScheduleEventDto)[]>
  >([searchScheduleEventsQueryKey, JSON.stringify(params)])?.data;
  const { dietitiansOptionsForAdmin } = useCalendarFilterOptions();
  const scheduleEvent = data?.find(
    (event): event is GoogleScheduleEventDto =>
      "googleId" in event && event.googleId === googleId,
  );

  if (!scheduleEvent) return null;

  const {
    title,
    dietitian,
    additionalInfo,
    meetingType,
    hangoutLink,
    dateStart,
    patient,
  } = scheduleEvent;

  const isOccupied = title === OCCUPIED_TITLE;

  const dietitianFullName =
    dietitiansOptionsForAdmin?.find(({ id }) => id === dietitian.id)?.label ??
    "-";

  const dateAndTime = format(new Date(dateStart), "dd.MM.yyyy, HH:mm");

  return (
    <StyledMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
      <VisitOverViewContent>
        <VisitOverViewHeader>
          <HeaderTitle>{title}</HeaderTitle>
          <Box display="flex" alignItems="start">
            <VisitOverviewNavigationIconButton onClick={onClose}>
              <Close />
            </VisitOverviewNavigationIconButton>
          </Box>
        </VisitOverViewHeader>
        <VisitOverviewWrapper>
          {!isOccupied && (
            <VisitOverviewItem>
              <VisitOverviewItemLabel>
                {t("calendar.client")}
              </VisitOverviewItemLabel>
              <PatientItemContent id={patient.id} />
            </VisitOverviewItem>
          )}
          <VisitOverviewItem>
            <VisitOverviewItemLabel>
              {t("calendar.deadlineAndTime")}
            </VisitOverviewItemLabel>
            <VisitOverviewItemContent>
              <EmptyCalendar className="text-gray-450 mx-[1px]" />
              <VisitOverviewItemText>{dateAndTime}</VisitOverviewItemText>
            </VisitOverviewItemContent>
          </VisitOverviewItem>
          <VisitOverviewItem>
            <VisitOverviewItemLabel>
              {t("calendar.dietitian")}
            </VisitOverviewItemLabel>
            <VisitOverviewItemContent>
              <Person className="text-xl text-gray-450" />
              <VisitOverviewItemText>{dietitianFullName}</VisitOverviewItemText>
            </VisitOverviewItemContent>
          </VisitOverviewItem>
          {!isOccupied && (
            <VisitOverviewItem>
              <VisitOverviewItemLabel>
                {t("calendar.additionalInfo")}
              </VisitOverviewItemLabel>
              <VisitOverviewItemText>
                {additionalInfo ?? "-"}
              </VisitOverviewItemText>
            </VisitOverviewItem>
          )}
          {hangoutLink && (
            <>
              <DividerStyled />
              <VisitOverviewItem>
                <GoogleMeetComponent url={hangoutLink} />
              </VisitOverviewItem>
            </>
          )}
        </VisitOverviewWrapper>
      </VisitOverViewContent>
    </StyledMenu>
  );
};

const OCCUPIED_TITLE = "Zajęty";
