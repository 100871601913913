import { Chip, styled } from "@mui/material";

export const CustomChip = styled(Chip)`
  &.MuiChip-root {
    height: 2rem;
  }

  & .MuiChip-label {
    line-height: 20px;
    font-size: 12px;
    padding: 4px 8px;
  }

  &.MuiChip-colorPrimary {
    background: ${({ theme }) => theme.palette.primary.medium};
    border-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
    border: 1px solid;
  }

  &.MuiChip-colorDefault {
    background: ${({ theme }) => theme.palette.background.default};
    border-color: ${({ theme }) => theme.palette.primary.medium};
    color: ${({ theme }) => theme.colors.neutral.dark[700]};
  }
` as typeof Chip;

export const StyledCustomChip = styled(CustomChip)`
  .MuiChip-root {
    cursor: pointer;
  }

  &.MuiChip-colorDefault {
    color: ${({ theme }) => theme.palette.primary.main};
    border: 1px solid ${({ theme }) => theme.palette.primary.medium};
  }
`;
