import { styled } from "@mui/material";

export const OuterLabelWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
`;

export const StyledLabel = styled("span")`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral.medium[800]};
`;
