import { yupResolver } from "@hookform/resolvers/yup";
import { useAppTranslation } from "@hooks";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

import * as yup from "yup";
import {
  NAME_EN_PLACEHOLDER,
  NAME_PL_PLACEHOLDER,
  ScheduleRowInputs,
} from "./useScheduleRowForm";

export const useOwnTypeModalForm = (defaultValues?: OwnTypeModalInputs) => {
  const { t } = useAppTranslation();

  const ownTypeModalSchema = yup.object().shape({
    mealTypeId: yup.string().required(t("common.required_field")),
    namePl: yup.string().required(t("common.required_field")),
    nameEn: yup.string().required(t("common.required_field")),
  });

  const form = useForm<OwnTypeModalInputs>({
    resolver: yupResolver(ownTypeModalSchema),
  });

  useEffect(() => {
    if (defaultValues) form.reset(defaultValues);
  }, [defaultValues]);

  return form;
};

export interface OwnTypeModalInputs {
  mealTypeId: string | null;
  namePl: string | null;
  nameEn: string | null;
}

export const mapOwnTypeForm = ({
  mealTypeId,
  namePl,
  nameEn,
}: ScheduleRowInputs): OwnTypeModalInputs | undefined => {
  return {
    mealTypeId,
    namePl: namePl === NAME_PL_PLACEHOLDER ? "" : namePl,
    nameEn: nameEn === NAME_EN_PLACEHOLDER ? "" : nameEn,
  };
};
