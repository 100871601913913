import { IconButton, MenuItem, styled } from "@mui/material";

export const TriggerButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.primary.main};
  padding: 0;
  width: 28px;
  height: 28px;
  min-width: 28px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.light[200]};
  border-radius: 100%;
`;

export const EditButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.primary.main};

  &.MuiButtonBase-root.MuiIconButton-root:hover {
    background-color: transparent;
  }
`;

export const ManageTagsMenuItem = styled(MenuItem)`
  color: ${({ theme }) => theme.palette.primary.main};
`;
