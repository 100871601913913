import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Star = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="14" viewBox="0 0 14 14" {...props}>
      <path
        d="M6.95724 0.0217285C7.18386 0.0217285 7.39077 0.15375 7.4893 0.364476L9.17906 3.9519L12.9527 4.52569C13.1744 4.55869 13.3591 4.71864 13.4281 4.93952C13.4971 5.1604 13.4404 5.39906 13.2828 5.56154L10.5461 8.35938L11.1915 12.3099C11.2285 12.5384 11.1373 12.7694 10.955 12.9065C10.7728 13.0436 10.5289 13.0588 10.3318 12.9497L6.95724 11.0912L3.58511 12.9471C3.38559 13.0563 3.14419 13.0411 2.96192 12.904C2.77964 12.7669 2.68604 12.5358 2.72298 12.3073L3.36835 8.35684L0.631718 5.56154C0.471609 5.39906 0.417419 5.15786 0.486389 4.93952C0.555359 4.72118 0.740099 4.56123 0.961788 4.52569L4.73543 3.9519L6.42519 0.364476C6.52618 0.15375 6.73063 0.0217285 6.95724 0.0217285ZM6.95724 2.02744L5.66406 4.77449C5.57785 4.95475 5.41281 5.0817 5.21822 5.11216L2.30424 5.55393L4.42014 7.71451C4.55561 7.85415 4.61966 8.05218 4.58764 8.24767L4.0876 11.2867L6.6789 9.85985C6.85379 9.76338 7.06316 9.76338 7.23559 9.85985L9.82688 11.2867L9.32932 8.25021C9.29729 8.05472 9.35887 7.85668 9.49681 7.71705L11.6127 5.55647L8.69873 5.11216C8.5066 5.0817 8.33911 4.95729 8.25289 4.77449L6.95724 2.02744Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
