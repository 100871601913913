import { useForm } from "react-hook-form";
import { PdfColorPickerFormStateType } from "@Pdf/types/pdfTypes";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { useAppTranslation } from "@hooks";

const usePdfColorPickerForm = () => {
  const { t } = useAppTranslation();

  const resolver = object().shape({
    color: string()
      .required(t("common.required_field"))
      .matches(
        /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
        t("common.invalid_color_format"),
      ),
  });

  return useForm<PdfColorPickerFormStateType>({
    defaultValues: {
      color: "",
    },
    resolver: yupResolver(resolver),
    shouldFocusError: false,
  });
};

export default usePdfColorPickerForm;
