import { TabList, TabPanel } from "@mui/lab";
import {
  Badge,
  Box,
  Card,
  IconButton,
  Popper,
  Tab,
  styled,
} from "@mui/material";

export const NotificationsMenuCard = styled(Card)`
  display: grid;
  border-radius: 16px;
`;

export const StyledBadge = styled(Badge)`
  & .MuiBadge-badge {
    height: 12px;
    min-width: 12px;
    padding: 0;
    font-size: 10px;
    font-weight: 500;
    top: 4px;
    right: 3px;
  }
`;

export const DrawerHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 8px;
  background: ${({ theme }) => theme.palette.primary.light};
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
`;

export const NotificationsListActionButton = styled(IconButton)`
  padding: 0;
  height: 24px;
  width: 24px;
`;

export const StyledBox = styled(Box)`
  background: ${({ theme }) => theme.palette.primary.light};
`;

export const StyledTabPanel = styled(TabPanel)`
  height: fit-content;
  max-height: 584px;
  width: 390px;
  padding: 24px 8px 8px;
  overflow-y: auto;
`;

export const StyledTab = styled(Tab)`
  min-height: 36px;
  text-transform: unset;
  flex: 1;
  font-size: 14px;
  padding: 0;
`;

export const StyledTabList = styled(TabList)`
  min-height: 36px;
`;

export const NotificationSectionWrapper = styled("div")`
  grid-row-start: 1;
  grid-column-start: 2;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.medium}px) {
    grid-row-start: unset;
    grid-column-start: unset;
  }
`;

export const NotificationCard = styled(Card)`
  display: grid;
  width: 100%;
  margin: 0 auto;
  box-shadow: unset;
  height: fit-content;
`;

export const StyledPopper = styled(Popper)`
  z-index: 1300;
`;
