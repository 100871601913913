import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";
import { LangDto } from "../common";

export const createClinicDietitian = async (
  payload: CreateClinicDietitianRequest,
) => {
  return await fetchData(
    "/dietitian/clinic/dietitians",
    APIMethods.POST,
    payload,
  );
};

export interface CreateClinicDietitianRequest {
  firstName: string;
  lastName: string;
  email: string;
  facility: FacilityId;
  phone: PhoneDto;
  language: LangDto;
  sendAccessEmail: boolean;
}

interface PhoneDto {
  prefix: string | null;
  number: string | null;
}

interface FacilityId {
  id: number;
}
