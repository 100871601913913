import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Shrimp = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="12" viewBox="0 0 14 12">
      <path
        d="M2.125 0.3125C1.22871 0.3125 0.5 1.04121 0.5 1.9375C0.5 2.83379 1.22871 3.5625 2.125 3.5625H2.15039C2.24434 5.81973 4.10547 7.625 6.39062 7.625H7.8125V2.75H7.20312H2.75469H2.125C1.67559 2.75 1.3125 2.38691 1.3125 1.9375C1.3125 1.48809 1.67559 1.125 2.125 1.125H12.2812C12.5047 1.125 12.6875 0.942187 12.6875 0.71875C12.6875 0.495312 12.5047 0.3125 12.2812 0.3125H2.125ZM6.1875 11.0781C6.1875 11.4158 6.45918 11.6875 6.79688 11.6875H8.625V9.8543L6.99746 9.28555C6.68008 9.17383 6.33223 9.34141 6.22051 9.65879C6.10879 9.97617 6.27637 10.324 6.59375 10.4357L6.70801 10.4764C6.41602 10.517 6.1875 10.7709 6.1875 11.0781ZM9.4375 11.6697C10.3617 11.5859 11.2021 11.2229 11.8775 10.6643L9.68125 9.78574C9.60508 9.83145 9.52383 9.86699 9.4375 9.89492V11.6697ZM12.4996 10.0371C13.1242 9.26777 13.5 8.2877 13.5 7.21875C13.5 6.90645 13.467 6.60176 13.4061 6.30723L10.2195 8.51621C10.2398 8.60254 10.25 8.69395 10.25 8.78535C10.25 8.90215 10.2322 9.01387 10.2018 9.11797L12.4996 10.0371ZM9.03125 2.75H8.625V7.625H9.08965C9.34101 7.625 9.57461 7.70625 9.76504 7.84082L13.1547 5.49473C12.4793 3.88242 10.8873 2.75 9.03125 2.75ZM4.76562 4.375C4.76562 4.21338 4.82983 4.05839 4.94411 3.94411C5.05839 3.82983 5.21338 3.76562 5.375 3.76562C5.53662 3.76562 5.69161 3.82983 5.80589 3.94411C5.92017 4.05839 5.98438 4.21338 5.98438 4.375C5.98438 4.53662 5.92017 4.69161 5.80589 4.80589C5.69161 4.92017 5.53662 4.98438 5.375 4.98438C5.21338 4.98438 5.05839 4.92017 4.94411 4.80589C4.82983 4.69161 4.76562 4.53662 4.76562 4.375Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
