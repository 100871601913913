import { Button, Drawer, IconButton, styled } from "@mui/material";

export const StyledDrawer = styled(Drawer)`
  & .MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper {
    border-radius: 0;
    gap: unset;
    width: 440px;
    padding: 0;
    height: 100%;
    max-height: calc(100% - 56px);
  }
`;

export const DrawerHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: ${({ theme }) => theme.palette.primary.medium};
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
`;

export const StyledList = styled("ul")`
  display: grid;
  gap: 8px;
  padding: 16px;
`;

export const CloseButton = styled(IconButton)`
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
  padding: 0;
  width: 24px;
  height: 24px;
`;

export const KnowledgeButton = styled(Button)`
  height: 24px;
`;
