import { useFormContext, useWatch } from "react-hook-form";
import {
  MealNameInputs,
  createMealNameRequest,
} from "@views/dietician/MealEdit/forms";
import { useUpdateCreatorMealMutation } from "@hooks/queries/meals/useUpdateCreatorMealMutation";
import { useEffect, useState } from "react";
import { useDebounce } from "react-use";
import { useAppParams } from "@hooks";
import { useFetchCreatorMealQuery } from "@hooks/queries";

export const MealNameFormWatch = () => {
  const { mealId } = useAppParams();
  const { meal, isLoading: isMealLoading } = useFetchCreatorMealQuery(mealId);
  const values = useWatch<MealNameInputs>();
  const {
    handleSubmit,
    formState: { isDirty },
  } = useFormContext<MealNameInputs>();

  const { mutate, isLoading } = useUpdateCreatorMealMutation(mealId);
  const [queued, setQueued] = useState(false);

  const [isDirtyLatch, setIsDirtyLatch] = useState(isDirty);

  const update = () => {
    setQueued(false);
    handleSubmit(
      data => mutate(createMealNameRequest(data)),
      err => console.log(err),
    )();
  };

  useDebounce(
    () => {
      if (isLoading || isMealLoading) {
        setQueued(true);
      }

      if (
        (isDirty || isDirtyLatch) &&
        values &&
        meal &&
        !(isLoading || isMealLoading)
      ) {
        setIsDirtyLatch(true);
        update();
      }
    },
    1000,
    [JSON.stringify(values)],
  );

  useEffect(() => {
    if (queued && !(isLoading || isMealLoading)) {
      update();
    }
  }, [queued, isLoading || isMealLoading]);

  return <></>;
};
