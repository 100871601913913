import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const AvailableUserMonitoring = (props: SvgIconProps) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path
      d="M2.23056 2.11213C1.93889 2.83158 1.77778 3.61769 1.77778 4.44547C1.77778 5.27324 1.93889 6.05935 2.23056 6.7788C2.41389 7.23435 2.19444 7.75102 1.73889 7.93713C1.28333 8.12324 0.766667 7.90102 0.580556 7.44547C0.205556 6.51769 0 5.5038 0 4.44547C0 3.38713 0.205556 2.37324 0.580556 1.44547C0.766667 0.98991 1.28333 0.770465 1.73889 0.953799C2.19444 1.13713 2.41389 1.65658 2.23056 2.11213ZM15.4194 1.44547C15.7944 2.37324 16 3.38713 16 4.44547C16 5.5038 15.7944 6.51769 15.4194 7.44547C15.2361 7.90102 14.7167 8.12047 14.2611 7.93713C13.8056 7.7538 13.5861 7.23435 13.7694 6.7788C14.0611 6.05935 14.2222 5.27324 14.2222 4.44547C14.2222 3.61769 14.0611 2.83158 13.7694 2.11213C13.5861 1.65658 13.8056 1.13991 14.2611 0.953799C14.7167 0.767688 15.2333 0.98991 15.4194 1.44547ZM9.77778 4.44547C9.77778 5.1038 9.41944 5.6788 8.88889 5.98435V14.2232C8.88889 14.7149 8.49167 15.1121 8 15.1121C7.50833 15.1121 7.11111 14.7149 7.11111 14.2232V5.98435C6.58056 5.67602 6.22222 5.1038 6.22222 4.44547C6.22222 3.46491 7.01944 2.66769 8 2.66769C8.98056 2.66769 9.77778 3.46491 9.77778 4.44547ZM4.73889 3.02324C4.55 3.45658 4.44444 3.93713 4.44444 4.44547C4.44444 4.9538 4.55 5.43435 4.73889 5.86769C4.93611 6.31769 4.73056 6.84269 4.28056 7.03713C3.83056 7.23158 3.30556 7.0288 3.11111 6.5788C2.825 5.92324 2.66667 5.20102 2.66667 4.44547C2.66667 3.68991 2.825 2.96769 3.11111 2.31213C3.30833 1.86213 3.83056 1.65658 4.28056 1.8538C4.73056 2.05102 4.93611 2.57324 4.73889 3.02324ZM12.8889 2.31213C13.175 2.96769 13.3333 3.68991 13.3333 4.44547C13.3333 5.20102 13.175 5.92324 12.8889 6.5788C12.6917 7.0288 12.1694 7.23435 11.7194 7.03713C11.2694 6.83991 11.0639 6.31769 11.2611 5.86769C11.45 5.43435 11.5556 4.9538 11.5556 4.44547C11.5556 3.93713 11.45 3.45658 11.2611 3.02324C11.0639 2.57324 11.2694 2.04824 11.7194 1.8538C12.1694 1.65935 12.6944 1.86213 12.8889 2.31213Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
