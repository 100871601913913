import { Box, styled, Typography, Button } from "@mui/material";

export const BigCalendarTopography = styled(Typography)`
  font-weight: 600;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.neutral.dark["900"]};
`;

export const BigCalendarWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 1700px;
  margin: 0 auto;
`;

export const BigCalendarAddButton = styled(Button)`
  background-color: white;
  color: ${({ theme }) => theme.palette.primary.main};
  border: 1px solid ${({ theme }) => theme.palette.primary.medium};
  border-radius: 8px;
  width: 90px;
  height: 40px;
  gap: 4px;
`;

export const BigCalendarTodayButton = styled(Button)`
  background-color: white;
  color: ${({ theme }) => theme.palette.primary.main};
  border: 1px solid ${({ theme }) => theme.palette.primary.medium};
  border-radius: 8px;
  height: 42px;
`;
