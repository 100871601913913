import { useEffect } from "react";
import { useForm } from "react-hook-form";

import * as yup from "yup";

import { LangDto } from "@client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppTranslation } from "@hooks";

export const useQuestionnaireModalForm = (
  values?: QuestionnaireModalFormInputs,
) => {
  const { t } = useAppTranslation();

  const form = useForm<QuestionnaireModalFormInputs>({
    defaultValues: {
      langId: 1,
      name: "",
    },
    resolver: yupResolver(
      yup.object().shape({
        langId: yup.number().required(),
        name: yup.string().required(t("common.required_field")),
      }),
    ),
  });

  useEffect(() => {
    if (values) form.reset(values);
  }, [values]);

  return form;
};

export interface QuestionnaireModalFormInputs {
  name: string;
  langId: number;
}

export const langDict: Record<number, LangDto> = {
  1: LangDto.PL,
  2: LangDto.EN,
};
