import { Outlet } from "react-router-dom";

import { useAppTranslation } from "@hooks";

import { ThemeProviderWrapperNew } from "themeNew";
import { LayoutTitle } from "./SettingsLayout.styled";

export const SettingsLayout = () => {
  const { t } = useAppTranslation();

  return (
    <ThemeProviderWrapperNew>
      <LayoutTitle>{t("common.settings")}</LayoutTitle>
      <Outlet />
    </ThemeProviderWrapperNew>
  );
};
