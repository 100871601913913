import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const IrrigationMonitoring = (props: SvgIconProps) => (
  <SvgIcon width="12" height="16" viewBox="0 0 12 16" {...props}>
    <path
      d="M1.00033 0C0.722208 0 0.456583 0.115625 0.269083 0.31875C0.0815833 0.521875 -0.0184167 0.79375 0.00345832 1.07187L0.903458 13.6781C0.997208 14.9875 2.08471 16 3.39721 16H8.60346C9.91596 16 11.0035 14.9875 11.0972 13.6781L11.9972 1.07187C12.016 0.79375 11.9222 0.521875 11.7316 0.31875C11.541 0.115625 11.2785 0 11.0003 0H1.00033ZM2.28158 4.89062L2.07533 2H9.92846L9.71908 4.89062L8.96283 5.26875C8.35658 5.57188 7.64408 5.57188 7.03783 5.26875C6.38471 4.94375 5.61596 4.94375 4.96283 5.26875C4.35658 5.57188 3.64408 5.57188 3.03783 5.26875L2.28158 4.89062Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
