import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import { FormTextField } from "@components/FormTextField";

export const TaskCreationStyledLabel = styled("label")(({ theme }) => ({
  marginRight: "auto",
  fontSize: "0.85rem",
  color: theme.colors.neutral.medium["800"],
}));

export const TaskCreationStyledBox = styled(Box)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  border-right: 1px solid #D1D5DB;
  padding-right: ${theme.spacing(2)};
  margin-right: ${theme.spacing(2)};
  color: #6B7280;
`,
);

export const TaskCreationStyledTextField = styled(FormTextField)`
  & .MuiInputBase-input::placeholder {
    color: ${({ theme }) => theme.colors.neutral.dark["700"]};
  }

  & .MuiInputBase-inputMultiline::placeholder {
    color: ${({ theme }) => theme.colors.neutral.dark["700"]};
    opacity: 1;
  }
`;
