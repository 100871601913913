import { useContext } from "react";
import { useParams } from "react-router-dom";

import { useFetchClientQuery } from "@hooks/queries";
import { useBalanceClientValues } from "@hooks/useBalanceClientValues";
import {
  calculateForecast,
  calculateMassDifference,
} from "@components/MassForecast";
import { SimulationSlidersContext } from "@views/dietician/PatientEnergyBalance/context/SimulationSlidersContext";

export function useClientBalanceForecast(daysCount = 7) {
  const { patientId } = useParams();
  const { totalKcalDiff, setDayKcalDiff } = useContext(
    SimulationSlidersContext,
  );
  setDayKcalDiff(totalKcalDiff / daysCount);

  const {
    difference,
    isLoading: isBalanceLoading,
    isError: isBalanceError,
  } = useBalanceClientValues();

  const {
    client,
    isLoading: isClientLoading,
    isError: isClientError,
  } = useFetchClientQuery(parseInt(patientId ?? "0"));

  const massToChange = calculateMassDifference(
    client?.profile.weight,
    client?.profile.targetWeight,
  );

  const calculations = calculateForecast(
    difference,
    daysCount,
    client?.profile.weight ?? 1,
  );

  const direction =
    !!client?.profile.targetWeight && !!client?.profile.weight
      ? client.profile.targetWeight > client?.profile.weight
      : difference > 0;

  return {
    ...calculations,
    client,
    direction,
    isLoading: isClientLoading || isBalanceLoading,
    isError: isClientError || isBalanceError,
    massToChange,
  };
}
