import * as yup from "yup";

export interface PatientBodyMeasurementsSettingsResource {
  important: { id: number }[];
  requiredPhotos: boolean;
}

export const patientBodyMeasurementsSettingsSchema = yup.object({
  important: yup
    .array()
    .of(
      yup.object({
        id: yup.number().required(),
      }),
    )
    .defined(),
  requiredPhotos: yup.boolean().required(),
});
