import { fetchData } from "@utils/api";
import { APIMethods, ApiEndpoints } from "@typeDefinitions";
import { Task, taskSchema } from "./fetchTasks";
import { array, number, object } from "yup";
import qs from "qs";

export interface QueryParams {
  dietitians_ids: string | number[] | undefined;
  due_date_from: string | undefined;
  due_date_to: string | undefined;
}

export const fetchTasksCount = async (queryParams: Partial<QueryParams>) => {
  const composedQueryString = qs.stringify(queryParams, {
    arrayFormat: "comma",
  });

  const response = await fetchData<FetchTasksCountResponse>(
    `${ApiEndpoints.TasksCount}?${composedQueryString}`,
    APIMethods.GET,
  );

  return fetchTasksCountResponseSchema.validate(response);
};

interface Tag {
  id: number;
}

interface TaskCount {
  tag: Tag;
  count: number;
}

export interface FetchTasksCountResponse {
  data: TaskCount[];
}

const tagSchema = object({
  id: number().required(),
});

const taskCountSchema = object({
  tag: tagSchema,
  count: number().required(),
});

const fetchTasksCountResponseSchema = object({
  data: array().of(taskCountSchema),
});
