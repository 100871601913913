import { StyledExclamationButton } from "@layouts/Dashboard/ExclamationMarkButton.styled";
import { ExclamationMark } from "@assets/icons/Menu";

export const ExclamationMarkButton = () => {
  return (
    <StyledExclamationButton>
      <ExclamationMark size="w-6 h-6" />
    </StyledExclamationButton>
  );
};
