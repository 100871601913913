import { Dispatch, ReactNode, SetStateAction } from "react";

import { Checkbox } from "@mui/material";

import { ArrowRight } from "@assets/icons/Arrows";
import {
  ExpandableTags,
  UserChips,
  UserTagType,
} from "@components/ExpandableChips";
import { MacrosText } from "@components/MacrosText";
import { Nutrient } from "@typeDefinitions";
import cupcake from "@assets/img/productTile/cupcake.png";

import {
  AccordionDetailsStyled,
  AccordionStyled,
  AccordionSummaryStyled,
  ImagePlaceholder,
  ImageStyled,
  ImgBackground,
  SummaryWrapper,
  TitleStyled,
} from "./AccordionWrapper.styled";

export interface AccordionData {
  title: string;
  checked: boolean;
  indeterminate?: boolean;
  onCheck: (value: boolean) => void;
  nutrients: Nutrient[];
  tags: number[];
  userTags?: UserTagType[];
  image?: string;
}

interface AccordionWrapperProps extends AccordionData {
  extras?: ReactNode;
  children: ReactNode;
  withImage?: boolean;
  expanded?: boolean | undefined;
  setExpanded?: Dispatch<SetStateAction<boolean | undefined>>;
  hiddenCheckbox?: boolean;
}

export const AccordionWrapper = ({
  title,
  checked,
  indeterminate,
  nutrients,
  onCheck,
  tags,
  extras,
  children,
  withImage = false,
  image,
  expanded,
  setExpanded,
  hiddenCheckbox,
  userTags,
}: AccordionWrapperProps) => {
  return (
    <AccordionStyled
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded}
      onChange={(e, v) => setExpanded && setExpanded(v)}
    >
      <AccordionSummaryStyled
        expandIcon={<ArrowRight size="w-[16px] h-[16px]" />}
      >
        <div className="grid gap-[8px]">
          <div className="flex">
            <Checkbox
              indeterminate={indeterminate}
              checked={checked}
              onChange={(e, v) => onCheck(v)}
              onClick={e => e.stopPropagation()}
              disabled={hiddenCheckbox}
              sx={{ opacity: Number(!hiddenCheckbox) }}
            />
            <TitleStyled>{title}</TitleStyled>
          </div>
          <SummaryWrapper paddingLeft={!withImage}>
            {withImage && image && <ImageStyled src={image} />}
            {withImage && !image && (
              <ImgBackground>
                <ImagePlaceholder src={cupcake} />
              </ImgBackground>
            )}

            <div className="grid gap-[8px]">
              <div className="flex gap-[16px]">
                <MacrosText nutrients={nutrients} />

                {extras}
              </div>

              <div className="flex gap-[8px]">
                <UserChips tags={userTags} />
                <ExpandableTags tags={tags} showCount={3} />
              </div>
            </div>
          </SummaryWrapper>
        </div>
      </AccordionSummaryStyled>
      <AccordionDetailsStyled>{children}</AccordionDetailsStyled>
    </AccordionStyled>
  );
};
