import { MouseEventHandler, useMemo, useState } from "react";

import { TabContext } from "@mui/lab";
import { CircularProgress, IconButton } from "@mui/material";

import { Eye } from "@assets/icons";
import { TabIf, TabSwitch } from "@components/TabSwitch";
import { useAppParams, useAppTranslation, useModalState } from "@hooks";
import {
  useFetchCreatorDietNutrientsQuery,
  useFetchCreatorDietQuery,
} from "@hooks/queries/diets/creator";

import { useFetchDietNutrientsNormQuery } from "@views/dietician/DietCreator/hooks";
import { SidePanel } from "../../NutrientsCard/SidePanel";
import { useNutrientsSidePanelContext } from "../NutrientsSidePanel";
import { MacrosTabPanel, MicrosTabPanel } from "../TabPanels";
import { ChartsCard, TabPanelStyled } from "./ChartsCard.styled";
import { mapTargetNutrients } from "./chartsCardUtils";
import { usePatientImportantNutrients } from "@views/dietician/ProgramSchedule/components/ProgramScheduleGrid/component/OptimizerSettingsModal/usePatientImportantNutrients";

export const ChartCard = () => {
  const { t } = useAppTranslation();
  const { dietId, patientId } = useAppParams();
  const [tab, setTab] = useState(Tab.MACROS);
  const [openEditNutrients, onOpenEditNutrients, onCloseEditNutrients] =
    useModalState();
  const { onOpen } = useNutrientsSidePanelContext();

  const tabs = useMemo<TabIf[]>(
    () => [
      { id: Tab.MACROS, label: t("diet.energy_and_nutrients") },
      { id: Tab.MICROS, label: t("diet.selected_values") },
    ],
    [t],
  );
  const { nutrients, isLoading } = useFetchCreatorDietNutrientsQuery(dietId);
  const { diet, isLoading: isDietLoading } = useFetchCreatorDietQuery(dietId);
  const { norm, isLoading: isNormLoading } =
    useFetchDietNutrientsNormQuery(dietId);
  const { importantNutrients } = usePatientImportantNutrients();

  const nutrientsWithTarget = useMemo(
    () =>
      mapTargetNutrients(
        norm?.nutrients,
        diet?.targetNutrients,
        nutrients,
        importantNutrients,
      ),
    [norm?.nutrients, diet?.targetNutrients, nutrients, importantNutrients],
  );

  const handleOpenNutrients: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();
    onOpen(nutrients);
  };

  const handleOpenEditNutrients: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();
    onOpenEditNutrients();
  };

  if (isLoading || isNormLoading || isDietLoading)
    return (
      <div className="grid place-items-center">
        <CircularProgress />
      </div>
    );

  return (
    <ChartsCard>
      <div className="flex justify-between items-start">
        <TabSwitch tabs={tabs} value={tab} onChange={setTab} size="small" />

        <IconButton size="small" color="primary" onClick={handleOpenNutrients}>
          <Eye size="w-[16px] h-[16px]" />
        </IconButton>
      </div>

      <TabContext value={tab}>
        <TabPanelStyled value={Tab.MACROS}>
          <MacrosTabPanel nutrients={nutrientsWithTarget} />
        </TabPanelStyled>
        <TabPanelStyled value={Tab.MICROS}>
          <MicrosTabPanel
            nutrients={nutrientsWithTarget}
            onClickSelect={handleOpenEditNutrients}
          />
        </TabPanelStyled>
      </TabContext>

      <SidePanel open={openEditNutrients} onClose={onCloseEditNutrients} />
    </ChartsCard>
  );
};

enum Tab {
  MACROS = "1",
  MICROS = "2",
}
