import { styled } from "@mui/material";

interface TextProps {
  bold?: boolean;
}
export const Text = styled("span")<TextProps>`
  font-size: 14px;
  font-weight: ${({ bold }) => (bold ? "600" : "400")};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const Title = styled("span")`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;
