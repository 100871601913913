import { useEffect } from "react";
import { useForm } from "react-hook-form";

import * as yup from "yup";

import {
  SurveySexDto,
  SexAnswerQuestionDto,
  UpdateSingedSurveyRequest,
} from "@client/surveys";
import { yupResolver } from "@hookform/resolvers/yup";

export const useSexAnswerForm = (
  values: SexAnswerFormInputs | undefined,
  required: boolean,
) => {
  const resolver = yup.object().shape({
    sex: required
      ? yup.string<SurveySexDto>().required()
      : yup.string<SurveySexDto>().nullable(),
  });

  const form = useForm<SexAnswerFormInputs>({
    resolver: yupResolver(resolver),
  });

  useEffect(() => {
    if (values) form.reset(values, { keepDirtyValues: true });
  }, [values]);

  return form;
};

export const mapSexAnswerForm = (
  data?: SexAnswerQuestionDto,
): SexAnswerFormInputs => {
  if (!data) return { sex: null };
  return {
    sex: data.sex,
  };
};

export const mapSexAnswerRequest = (
  data: SexAnswerFormInputs,
  questionId: number,
): UpdateSingedSurveyRequest => {
  const { sex } = data;

  return {
    questionId,
    answer: {
      sex,
    },
  };
};

export interface SexAnswerFormInputs {
  sex: SurveySexDto | null;
}
