import { useInfiniteProgress } from "@hooks";

import {
  CircularProgressBackgroundStyled,
  CircularProgressStyled,
  InfiniteCircularProgressStyled,
} from "./InfiniteCircularProgress.styled";

interface InfiniteCircularProgressProps {
  start: boolean;
}

export const InfiniteCircularProgress = ({
  start,
}: InfiniteCircularProgressProps) => {
  const [yValue] = useInfiniteProgress(start, 20);

  return (
    <InfiniteCircularProgressStyled>
      <CircularProgressBackgroundStyled
        variant="determinate"
        thickness={8}
        size="1.25rem"
        value={100}
      />
      <CircularProgressStyled
        variant="determinate"
        thickness={8}
        size="1.25rem"
        value={yValue}
      />
    </InfiniteCircularProgressStyled>
  );
};
