import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import { ListTabs } from "@hooks";
import { Macros, MicrosFilters } from "@utils/macros";

export const useRecipesFiltersForm = (
  defaultValues?: RecipesFiltersFormInput,
) => {
  const form = useForm<RecipesFiltersFormInput>({ defaultValues });
  const [init, setInit] = useState(true);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (init) {
      form.reset({
        tabs: ListTabs.ALL,
        page: 1,
        nutrients: DEFAULT_MACROS_RANGE,
        ...convertSearchParamsToObj(searchParams),
      });
      setInit(false);
    }

    if (defaultValues) {
      form.reset({
        tabs: ListTabs.ALL,
        page: 1,
        ...defaultValues,
      });
    }
  }, [init, defaultValues]);

  return form;
};

export interface RecipesFiltersFormInput {
  query?: string;
  tags?: string[];
  nutrients?: { [id: string]: [number, number] };
  tabs?: ListTabs;
  page?: number;
  perPage?: number;
  patient?: number;
}

export const convertSearchParamsToObj = (
  searchParams: URLSearchParams,
): RecipesFiltersFormInput => {
  const result: RecipesFiltersFormInput = { nutrients: {} };

  result.tags = searchParams.get("tags")?.split(",") ?? [];
  result.query = searchParams.get("query") ?? "";
  result.page = searchParams.get("page")
    ? Number(searchParams.get("page"))
    : undefined;
  result.perPage = searchParams.get("perPage")
    ? Number(searchParams.get("perPage"))
    : undefined;
  result.patient = searchParams.get("patient")
    ? Number(searchParams.get("patient"))
    : undefined;

  searchParams.forEach((value, key) => {
    if (key.startsWith("nutrients")) {
      const nutrients = value.split(",");

      result.nutrients = { ...DEFAULT_MACROS_RANGE };

      nutrients.forEach(nutrientString => {
        const [nutrientId, from, to] = nutrientString.split("|");

        if (nutrientId) {
          if (!result.nutrients) result.nutrients = {};

          if (!result.nutrients[nutrientId])
            result.nutrients[nutrientId] = [0, 0];

          const array: [number, number] = [Number(from || 0), Number(to || 0)];
          result.nutrients[nutrientId] = array;
        }
      });
    }

    if (key.startsWith("owner")) result.tabs = value as ListTabs;
    if (key.startsWith(ListTabs.LATEST))
      result.tabs = ListTabs.LATEST as ListTabs;
    if (key.startsWith(ListTabs.FAVORITE))
      result.tabs = ListTabs.FAVORITE as ListTabs;
    if (key.startsWith(ListTabs.UNVERIFIED))
      result.tabs = ListTabs.UNVERIFIED as ListTabs;
  });

  if (Object.keys(result.nutrients!).length === 0) delete result.nutrients;

  return result;
};

export const DEFAULT_MACROS_RANGE: { [id: string]: [number, number] } = {
  [Macros.ENERGY]: [0, 1000],
  [Macros.PROTEIN]: [0, 60],
  [Macros.FATS]: [0, 50],
  [Macros.CARBS]: [0, 100],
  [MicrosFilters.SATURATED_FAT]: [0, 5],
  [MicrosFilters.CHOLESTEROL]: [0, 100],
  [MicrosFilters.DIETARY_FIBER]: [0, 20],
  [MicrosFilters.SODIUM]: [0, 1000],
  [MicrosFilters.POTASSIUM]: [0, 1000],
  [MicrosFilters.CALCIUM]: [0, 500],
  [MicrosFilters.PHOSPHORUS]: [0, 500],
  [MicrosFilters.MAGNESIUM]: [0, 200],
  [MicrosFilters.IRON]: [0, 10],
  [MicrosFilters.VITAMIN_A]: [0, 200],
  [MicrosFilters.TOTAL_FOLATE]: [0, 150],
  [MicrosFilters.VITAMIN_C]: [0, 100],
  [MicrosFilters.KOBALAMINA]: [0, 1],
  [MicrosFilters.GLYCEMIC_LOAD]: [0, 30],
  [MicrosFilters.CARBOHYDRATE_EXCHANGERS]: [0, 10],
};
