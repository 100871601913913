import { useMemo, useState } from "react";

import { MealPatternNewDto } from "@client";
import { useAppTranslation } from "@hooks";

import { AccordionWrapper } from "./AccordionWrapper";
import { CollectionContent } from "./CollectionContent";
import { useItemsContext } from "../ItemsContext";
import { DefinedLabelSpecialTagIf, SpecialTags } from "@components/TagWithIcon";
import { DocWithApple } from "@assets/icons/DesignSystem";
interface CollectionAccordionProps extends MealPatternNewDto {
  mealId: number;
}
export const CollectionAccordion = ({
  macros,
  tags,
  name,
  nameEn,
  id,
  numberOfItems,
  mealId,
}: CollectionAccordionProps) => {
  const { t, isPolishChosen } = useAppTranslation();
  const title = isPolishChosen ? name : nameEn ?? name;
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean | undefined>(false);
  const { getCollectionStatus, addCollection, singleSelect } =
    useItemsContext();
  const { checked, indeterminate } = getCollectionStatus(id);

  const onSuccess = () => setSelectAll(false);

  const expandAndAdd = () => {
    setSelectAll(true);
    setExpanded(true);
    addCollection(id);
  };

  const specialTags = useMemo(
    () =>
      [
        {
          id: "1",
          label: t("collections.propositions", { count: numberOfItems }),
          Icon: DocWithApple,
        },
      ].filter((t): t is DefinedLabelSpecialTagIf => !!t.label),
    [t, numberOfItems],
  );

  return (
    <AccordionWrapper
      checked={checked}
      indeterminate={indeterminate}
      extras={<SpecialTags tags={specialTags} />}
      nutrients={macros}
      onCheck={expandAndAdd}
      tags={tags.map(t => t.id)}
      title={title}
      expanded={expanded}
      setExpanded={setExpanded}
      hiddenCheckbox={singleSelect}
    >
      <CollectionContent
        selectAll={selectAll}
        onSuccess={onSuccess}
        id={id}
        mealId={mealId}
      />
    </AccordionWrapper>
  );
};
