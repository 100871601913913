import { useQuery } from "@tanstack/react-query";

import { QueryOptions } from "./types";
import { buildDefaultQueryOptions } from "./common";
import { fetchProgram } from "@client/program";

export const fetchProgramQueryKey = "programDetails";

export const useFetchProgramQuery = (
  programId: number,
  options?: QueryOptions,
) => {
  const { data: program, ...rest } = useQuery(
    [fetchProgramQueryKey, programId],
    () => fetchProgram(programId),
    buildDefaultQueryOptions(options),
  );

  return { program: program?.data, ...rest };
};
