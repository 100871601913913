import image from "@assets/img/noResults.svg";
import { ThemeProviderWrapperNew } from "themeNew";
import {
  ContentWrapper,
  NoResultsInfo,
  NoResultsTitle,
  SearchNoResultsStyled,
} from "./SearchNoResults.styled";
import { useAppTranslation } from "@hooks";

export const SearchNoResults = () => {
  const { t } = useAppTranslation();

  return (
    <ThemeProviderWrapperNew>
      <SearchNoResultsStyled>
        <img src={image} alt={t("empty_states.alt.search")} />

        <ContentWrapper>
          <NoResultsTitle>{t("empty_states.no_results")}</NoResultsTitle>

          <NoResultsInfo>{t("empty_states.search_info")}</NoResultsInfo>
        </ContentWrapper>
      </SearchNoResultsStyled>
    </ThemeProviderWrapperNew>
  );
};
