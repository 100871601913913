import { Dialog } from "@mui/material";
import { ThemeProviderWrapperNew } from "themeNew";
import {
  CloseButton,
  ContentSection,
  ContentText,
  ModalContent,
  OnClickButton,
  StyledDialogTitle,
  TrialTopSection,
} from "./SubscriptionTrialModalWrapper.styled";
import { Close } from "@assets/icons";

interface ConfirmSubscriptionModalWrapperProps {
  open: boolean;
  img?: string;
  onClose: () => void;
  title?: string;
  content?: string;
  buttonText?: string;
  iconButton?: JSX.Element;
  onClick?: () => void;
  width?: string | number;
  height?: string | number;
  showCloseButton?: boolean;
}

export const SubscriptionTrialModalWrapper = ({
  img,
  open,
  onClose,
  title,
  content,
  iconButton,
  onClick,
  buttonText,
  width = 166,
  height = 152,
  showCloseButton = true,
}: ConfirmSubscriptionModalWrapperProps) => {
  return (
    <ThemeProviderWrapperNew>
      <Dialog open={open} disableEscapeKeyDown>
        <TrialTopSection>
          {showCloseButton && (
            <CloseButton size="small" onClick={onClose}>
              <Close size="w-[13px] h-[13px]" />
            </CloseButton>
          )}
        </TrialTopSection>
        <ModalContent>
          <img src={img} width={width} height={height} className="mx-auto" />
          <ContentSection>
            <StyledDialogTitle>{title}</StyledDialogTitle>

            <ContentText>{content}</ContentText>
          </ContentSection>
          {onClick && (
            <OnClickButton
              variant="contained"
              onClick={onClick}
              startIcon={iconButton}
            >
              {buttonText}
            </OnClickButton>
          )}
        </ModalContent>
      </Dialog>
    </ThemeProviderWrapperNew>
  );
};
