import {
  CategoryNutrientsResponse,
  NutrientResponse,
} from "@typeDefinitions/responses/dictionaries";
import { LanguagesSlugs } from "@typeDefinitions";

export const getNutrientName = (nutrient: NutrientResponse, lang: string) => {
  if (lang === LanguagesSlugs.PL) {
    return nutrient.descriptionPl;
  }

  return nutrient.descriptionEn;
};

export const getCategoryName = (
  category: CategoryNutrientsResponse,
  lang: string,
) => {
  if (lang === LanguagesSlugs.PL) {
    return category.namePl;
  }

  return category.nameEn;
};
