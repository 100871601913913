import { useQuery } from "@tanstack/react-query";
import { requestFoodMeasures } from "@client";
import { QueryOptionsTyped } from "../types";
import { FoodMeasure } from "@typeDefinitions";

export const foodMeasuresQueryKey = "foodMeasuresQueryKey";

export function useFoodMeasuresQuery(
  options?: QueryOptionsTyped<FoodMeasure[]>,
) {
  return useQuery({
    queryKey: [foodMeasuresQueryKey],
    queryFn: requestFoodMeasures,
    staleTime: Infinity,
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    ...options,
  });
}
