import { ModalShell } from "@components/ModalShell";
import { useAppTranslation, useMealClientContext } from "@hooks";
import {
  fetchClientFoodGroupPreferencesQueryKey,
  fetchClientFoodPreferencesQueryKey,
  fetchClientTagsQueryKey,
  useFetchClientFoodGroupPreferences,
  useFetchFoodGroupsTreeQuery,
  useUpdateClientFoodCategoriesPreferences,
  useUpdateClientFoodPreferences,
  useUpdateClientTagsMutation,
} from "@hooks/queries";
import { ClientProfileCardItem } from "@views/dietician/ClientProfile/components/ClientProfileCardItem";
import { FormEvent, useCallback, useMemo } from "react";
import {
  mapEditAllergensRequest,
  mapEditDietTypeRequest,
  mapEditDislikedCategoriesRequest,
  mapEditDislikedProductsRequest,
  mapEditFoodAndAllergensForm,
  mapEditLikeCategoriesRequest,
  mapEditLikedProductsRequest,
  useEditFoodAndAllergensForm,
} from "./useEditFoodAndAllergensForm";
import { FormMultipleAutocomplete } from "@components/FormMultipleAutocomplete";
import {
  FetchClientFoodPreferencesResponse,
  FetchClientTagsResponse,
  FoodGroupResourceDto,
} from "@client";
import {
  FormTreeSelectSearch,
  TreeItemOption,
} from "@components/TreeSelectSearch";
import { SearchProductsMultipleFormAutocomplete } from "@components/SearchProductsMultipleFormAutocomplete";
import { FoodAndAllergensInputComponent } from "./components";
import { useQueryClient } from "@tanstack/react-query";

interface TabIf {
  id: string;
  label: string;
}
interface EditFoodAndAllergensModalProps {
  open: boolean;
  onClose: () => void;
  id: string;
  allergensTabsList?: TabIf[];
  dietTypesTabsList?: TabIf[];
  likedProducts?: FetchClientFoodPreferencesResponse[];
  dislikedProducts?: FetchClientFoodPreferencesResponse[];
  clientTags?: FetchClientTagsResponse[];
}

export const EditFoodAndAllergensModal = ({
  open,
  onClose,
  id,
  allergensTabsList,
  dietTypesTabsList,
  likedProducts,
  dislikedProducts,
  clientTags,
}: EditFoodAndAllergensModalProps) => {
  const { t, isPolishChosen } = useAppTranslation();
  const { data } = useFetchFoodGroupsTreeQuery();

  const { clientFoodGroupPreferences } = useFetchClientFoodGroupPreferences(id);
  const queryClient = useQueryClient();
  const { invalidateProducts, invalidateRecipes } = useMealClientContext();
  const defaultFoodAndAllergensValue = useMemo(
    () =>
      mapEditFoodAndAllergensForm(
        clientTags,
        dislikedProducts,
        likedProducts,
        clientFoodGroupPreferences,
      ),
    [clientTags, dislikedProducts, likedProducts, clientFoodGroupPreferences],
  );

  const { control, handleSubmit, watch, reset } = useEditFoodAndAllergensForm(
    defaultFoodAndAllergensValue,
  );

  const invalidateClientContext = () => {
    invalidateProducts();
    invalidateRecipes();
  };

  const { mutate: allergensAndDietTypeMutate, isLoading } =
    useUpdateClientTagsMutation({
      onSuccess: invalidateClientContext,
    });
  const { mutate: foodPreferencesMutate, isLoading: isLoadingFoodPreferences } =
    useUpdateClientFoodPreferences({
      onSuccess: invalidateClientContext,
    });
  const {
    mutate: foodCategoriesPreferencesMutate,
    isLoading: isLoadingFoodCategoriesPreferences,
  } = useUpdateClientFoodCategoriesPreferences({
    onSuccess: invalidateClientContext,
  });

  const onCloseWithReset = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  const handleEditClientAdditionalInfo = (e: FormEvent<HTMLFormElement>) => {
    handleSubmit(
      data => {
        allergensAndDietTypeMutate({
          id,
          payload: mapEditAllergensRequest(data),
        });
        allergensAndDietTypeMutate(
          { id, payload: mapEditDietTypeRequest(data) },
          {
            onSuccess: () => {
              queryClient.invalidateQueries([fetchClientTagsQueryKey]);
            },
          },
        );
        foodPreferencesMutate({
          id,
          payload: mapEditDislikedProductsRequest(data),
        });
        foodPreferencesMutate(
          {
            id,
            payload: mapEditLikedProductsRequest(data),
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries([
                fetchClientFoodPreferencesQueryKey,
              ]);
            },
          },
        );

        foodCategoriesPreferencesMutate({
          id,
          payload: mapEditDislikedCategoriesRequest(data),
        });
        foodCategoriesPreferencesMutate(
          {
            id,
            payload: mapEditLikeCategoriesRequest(data),
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries([
                fetchClientFoodGroupPreferencesQueryKey,
              ]);
              onCloseWithReset();
            },
          },
        );
      },
      e => console.log(e),
    )();
  };

  const mapTreeSelectOptions = (
    options: FoodGroupResourceDto[] | undefined,
  ): TreeItemOption[] => {
    if (!options) return [];

    const mapItem = (
      {
        id,
        description,
        descriptionPl,
        children,
        parentId,
      }: FoodGroupResourceDto,
      parentIds: string[],
    ): TreeItemOption => ({
      id: id.toString(),
      name: isPolishChosen ? descriptionPl || description : description,
      children: children?.length
        ? children.map(c => mapItem(c, [...parentIds, parentId.toString()]))
        : [],
      allParents: [...parentIds, parentId.toString()],
      parentId: parentId.toString(),
    });

    return options.map(o => mapItem(o, []));
  };

  const mappedOptions = useMemo(
    () => mapTreeSelectOptions(data),
    [data, isPolishChosen],
  );

  return (
    <ModalShell
      title={t("client_profile.health.food_and_allergens")}
      open={open}
      onClose={onCloseWithReset}
      isLoading={isLoading}
      onSubmit={handleEditClientAdditionalInfo}
    >
      <ClientProfileCardItem
        title={t("client_profile.health.allergens")}
        extraEl={
          <FormMultipleAutocomplete
            control={control}
            key="allergens"
            name="allergens"
            options={allergensTabsList ?? []}
            color="error"
            placeholder={t(
              "client_profile.health.edit_placeholders.search_allergens",
            )}
          />
        }
      />

      <ClientProfileCardItem
        title={t("client_profile.health.disliked_product_categories")}
        extraEl={
          <FormTreeSelectSearch
            options={mappedOptions}
            control={control}
            name="dislikedCategories"
            placeholder={t(
              "client_profile.health.edit_placeholders.disliked_categories",
            )}
            color="warning"
            startAdornment={<></>}
            InputComponent={FoodAndAllergensInputComponent}
          />
        }
      />

      <ClientProfileCardItem
        title={t("client_profile.health.disliked_product")}
        extraEl={
          <SearchProductsMultipleFormAutocomplete
            control={control}
            placeholder={t(
              "client_profile.health.edit_placeholders.disliked_products",
            )}
            key="dislikedProducts"
            name="dislikedProducts"
            productsPreferences={dislikedProducts ?? []}
            color="warning"
          />
        }
      />

      <ClientProfileCardItem
        title={t("client_profile.health.liked_product_categories")}
        extraEl={
          <FormTreeSelectSearch
            key="likedCategories"
            options={mappedOptions}
            control={control}
            name="likedCategories"
            placeholder={t(
              "client_profile.health.edit_placeholders.liked_categories",
            )}
            color="success"
            InputComponent={FoodAndAllergensInputComponent}
            startAdornment={<></>}
          />
        }
      />

      <ClientProfileCardItem
        title={t("client_profile.health.liked_product")}
        extraEl={
          <SearchProductsMultipleFormAutocomplete
            control={control}
            placeholder={t(
              "client_profile.health.edit_placeholders.liked_products",
            )}
            key="likedProducts"
            name="likedProducts"
            productsPreferences={likedProducts ?? []}
            color="success"
          />
        }
      />

      <ClientProfileCardItem
        title={t("client_profile.health.diet_type")}
        extraEl={
          <FormMultipleAutocomplete
            control={control}
            key="dietType"
            name="dietType"
            options={dietTypesTabsList ?? []}
            color="primary"
            placeholder={t("client_profile.health.edit_placeholders.diet_type")}
          />
        }
      />
    </ModalShell>
  );
};
