import { IconButton } from "@components/IconButton";
import { Drawer, List, ListItem, styled, TextField } from "@mui/material";

export const NotesSidePanelStyledIconButton = styled(IconButton)`
  padding: 0;
  padding-right: 80px;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  width: 405px;
  display: flex;
  justify-content: start;
  border-radius: 0;
`;

export const NotesSidePanelStyledListItem = styled(ListItem)`
  position: relative;
  &.MuiListItem-padding {
    padding: 16px 0;
  }
`;

export const NotesSidePanelStyledList = styled(List)`
  padding: 8px 0;
  margin: 0 16px;
`;

export const NotesSidePanelStyledDrawer = styled(Drawer)`
  .MuiPaper-root {
    width: 440px;
    padding: 0.2rem;
  }
`;

export const NotesSidePanelStyledContainer = styled("div")`
  position: absolute;
  right: 0;
  top: 10px;
  align-items: center;
  justify-content: center;
`;

export const NotesSidePanelStyledWrapper = styled("div")`
  overflow-x: hidden;
  overflow-y: auto;
`;

export const NotesSidePanelStyledTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    & fieldset {
      border: none;
    }

    &.Mui-focused fieldset {
      border: none;
    }
    padding: 0;
    color: ${({ theme }) => theme.colors.neutral.dark["800"]};
    font-weight: 500;
  }

  & .MuiInputBase-input {
    padding: 0;
  }

  & .MuiInputBase-root {
    height: auto;
    font-size: 14px;
    line-height: inherit;
    width: 325px;
  }
`;
