import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const updateCreatorDietMealRecipesSort = async (
  dietId: string,
  mealId: string,
  payload: UpdateRecipesSortPayload,
  version: string,
) => {
  return await fetchData(
    `/dietitian/creator-2/diets/${dietId}/meals/${mealId}/recipes-sort-update`,
    APIMethods.PUT,
    payload,
    undefined,
    { "Alloweat-Creator-Version": version },
  );
};

export interface UpdateRecipesSortPayload {
  ids: number[];
}
