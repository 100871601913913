import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const ScalesWithArrow = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      style={{ fill: "none" }}
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        d="M8.75 19.25H12.25C16.625 19.25 18.375 17.5 18.375 13.125V7.875C18.375 3.5 16.625 1.75 12.25 1.75H8.75C4.375 1.75 2.625 3.5 2.625 7.875V13.125C2.625 17.5 4.375 19.25 8.75 19.25Z"
        stroke="#801FFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0938 7.2538C13.8278 6.12921 12.1933 5.50806 10.5 5.50806C8.80668 5.50806 7.17219 6.12921 5.90625 7.2538L7.81375 10.3163C8.5532 9.65698 9.5093 9.29263 10.5 9.29263C11.4907 9.29263 12.4468 9.65698 13.1862 10.3163L15.0938 7.2538Z"
        stroke="#801FFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clipPath="url(#clip0_597_68811)">
        <path
          d="M11.4113 12.7295L13.6875 14.4999L11.4113 16.2703M7.3125 14.4999H13.6238"
          stroke="#801FFF"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_597_68811">
          <rect width="9" height="7" fill="white" transform="translate(6 11)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
