import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Confirm = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 21.536 15.016">
    <g
      id="_20-icon-confirm"
      data-name="20-icon-confirm"
      transform="translate(1.768 1.768)"
    >
      <path
        id="check_icon"
        data-name="check icon"
        d="M0,6l6,6L18,0"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
    </g>
  </SvgIcon>
);
