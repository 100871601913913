import { InputAdornment } from "@mui/material";
import { Controller } from "react-hook-form";
import TextField from "@components/Alloweat/Inputs/TextField/TextField";
import { useAppTranslation } from "@hooks";
import FormLabel from "@components/Alloweat/Inputs/FormLabel";
import FormControl from "@components/Alloweat/Inputs/FormControl";

const TargetBodyWeightField = () => {
  const { t } = useAppTranslation();

  return (
    <FormControl>
      <FormLabel>
        {t(
          "patient.health_and_goal.body_measurement_tab.section_goal_modal.target_weight",
        )}
      </FormLabel>
      <Controller
        render={({ field, fieldState }) => {
          return (
            <TextField
              error={fieldState.invalid}
              {...field}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">kg</InputAdornment>
                ),
              }}
            />
          );
        }}
        name="targetBodyWeight"
      />
    </FormControl>
  );
};

export default TargetBodyWeightField;
