import { styled } from "@mui/material";

interface HomeWrapperProps {
  heightWithBanner?: boolean;
}

export const HomeWrapper = styled("div")<HomeWrapperProps>`
  display: grid;
  grid-template-rows: 152px 122px 1fr;
  gap: 20px;
  height: ${({ heightWithBanner }) =>
    heightWithBanner ? "calc(100% - 68px)" : "100%"};

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.medium}px) {
    grid-template-rows: unset;
    grid-template-columns: 1fr 432px;
    gap: 24px;
  }
`;
