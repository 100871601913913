import * as yup from "yup";

import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";

import { PhoneDto, phoneSchema } from "../common";

export const searchClients = async (
  params?: URLSearchParams,
): Promise<ApiResponse<ClinicClientDto[]>> => {
  const response = await fetchData(
    "/dietitian/clinic/patients",
    APIMethods.GET,
    undefined,
    params,
  );
  await fetchClinicClientsSchema.validate(response);

  return response;
};

export interface ClinicClientDto {
  id: number;
  name: string;
  email: string | null;
  isActive: boolean;
  avatar?: string;
  phone: PhoneDto;
  schedules: SchedulesDto;
  profile: ProfileDto;
}

interface ProfileDto {
  location?: IdNameDto;
  dietitian?: IdNameDto;
}

interface SchedulesDto {
  todo?: IdDto;
  visit?: VisitDto;
}

interface IdDto {
  id: number;
}

interface IdNameDto extends IdDto {
  name: string;
}

interface VisitDto {
  id: number;
  status: IdDto;
  location?: IdNameDto;
  dietitian?: IdNameDto;
  startDate: string;
}

const idSchema = yup.object().shape({
  id: yup.number().required(),
});

const idNameSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
});

const visitSchema = yup.object().shape({
  id: yup.number().required(),
  status: idSchema.required(),
  location: idNameSchema.nullable(),
  dietitian: idNameSchema.nullable(),
  startDate: yup.string().required(),
});

const scheduleSchema = yup.object().shape({
  todo: idSchema.nullable(),
  visit: visitSchema.nullable(),
});

const profileSchema = yup.object().shape({
  location: idNameSchema.nullable(),
  dietitian: idNameSchema.nullable(),
});

const clinicClientSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  email: yup.string().nullable().default(null),
  isActive: yup.boolean().required(),
  avatar: yup.string().nullable(),
  phone: phoneSchema.required(),
  schedules: scheduleSchema.required(),
  profile: profileSchema.required(),
});
const fetchClinicClientsSchema = yup.object().shape({
  data: yup.array().of(clinicClientSchema).required(),
});
