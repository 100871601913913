import { useMutation } from "@tanstack/react-query";
import { MutationOptions } from "./types";
import { register, RegisterRequest } from "@client";

export const useRegisterMutation = (options?: MutationOptions) => {
  const { mutate, ...rest } = useMutation<unknown, unknown, RegisterRequest>(
    payload => register(payload),
    options,
  );

  return {
    register: mutate,
    ...rest,
  };
};
