import { ModalWrapper } from "@components/ModalWrapperNew";
import { OuterLabel } from "@components/OuterLabel";
import {
  useFetchRequiredBillDataQuery,
  usePostStoreBillDataMutation,
} from "@hooks/queries/clinic";
import { FormControlLabel, Radio, RadioGroup, styled } from "@mui/material";

import { FormProvider, useController } from "react-hook-form";
import { FormTextField } from "@components/FormTextField";
import {
  mapStoreBillData,
  useBuyPackageForm,
} from "@views/dietician/Pricing/components/useBuyPackageForm";
import { useModalState } from "@hooks";

export const BillDataModal = () => {
  const [open, onOpen, onClose] = useModalState();
  useFetchRequiredBillDataQuery({
    onSuccess: data => {
      if (data?.data.required) onOpen();
    },
  });

  const { mutate } = usePostStoreBillDataMutation();
  const { control, reset, handleSubmit, ...rest } = useBuyPackageForm();
  const {
    field: { value: buyer, onChange: setBuyer },
  } = useController({ control, name: "buyer" });

  const handleOnClose = () => {
    onClose();
    reset();
  };

  const handleSubmitWrapper = () => {
    handleSubmit(
      values =>
        mutate(mapStoreBillData(values), {
          onSuccess: handleOnClose,
        }),
      err => console.log(err),
    )();
  };

  return (
    <FormProvider
      control={control}
      reset={reset}
      handleSubmit={handleSubmit}
      {...rest}
    >
      <ModalWrapper
        open={open}
        onClose={handleOnClose}
        onSubmit={handleSubmitWrapper}
        title={<ModalTitle>Kup pakiet</ModalTitle>}
        confirmText={"Kup pakiet"}
      >
        <Wrapper>
          <OuterLabel label="Kupuję jako">
            <RadioGroupStyled
              defaultValue={buyer}
              onChange={(e, value) => setBuyer(value as "person" | "company")}
            >
              <FormControlLabelStyled
                value="person"
                control={<Radio />}
                label="Osoba prywatna"
              />
              <FormControlLabelStyled
                value="company"
                control={<Radio />}
                label="Firma"
              />
            </RadioGroupStyled>
          </OuterLabel>
          {buyer === "person" && (
            <>
              <OuterLabel label="Imię">
                <FormTextField name="firstName" fullWidth size="small" />
              </OuterLabel>
              <OuterLabel label="Nazwisko">
                <FormTextField name="lastName" fullWidth size="small" />
              </OuterLabel>
            </>
          )}
          {buyer === "company" && (
            <>
              <OuterLabel label="Firma">
                <FormTextField name="companyName" fullWidth size="small" />
              </OuterLabel>
              <OuterLabel label="NIP">
                <FormTextField name="nip" fullWidth size="small" />
              </OuterLabel>
            </>
          )}

          <AddressText>Adres</AddressText>

          <OuterLabel label="Ulica">
            <FormTextField name="street" fullWidth size="small" />
          </OuterLabel>

          <div className="flex gap-[24px] flex-1">
            <OuterLabelFlex1 label="Numer budynku">
              <FormTextField name="streetNumber" fullWidth size="small" />
            </OuterLabelFlex1>
            <OuterLabelFlex1 label="Numer lokalu">
              <FormTextField name="houseNumber" fullWidth size="small" />
            </OuterLabelFlex1>
          </div>

          <div className="flex gap-[24px] flex-1">
            <OuterLabelFlex1 label="Kod pocztowy">
              <FormTextField name="postcode" fullWidth size="small" />
            </OuterLabelFlex1>
            <OuterLabelFlex1 label="Miejscowość">
              <FormTextField name="city" fullWidth size="small" />
            </OuterLabelFlex1>
          </div>
        </Wrapper>
      </ModalWrapper>
    </FormProvider>
  );
};

const RadioGroupStyled = styled(RadioGroup)`
  &.MuiFormGroup-root {
    display: flex;
    gap: 24px;
    flex-direction: row;
  }
`;

const FormControlLabelStyled = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-left: unset;
    margin-right: unset;
  }
`;

const AddressText = styled("span")`
  font-family: Figtree;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.4px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;

const OuterLabelFlex1 = styled(OuterLabel)`
  flex: 1;
`;

const ModalTitle = styled("span")`
  font-family: Figtree;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
  padding: 18px 24px;
`;

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 552px;
  padding: 0 8px;
`;
