import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const ProfilClientCalendar = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      style={{ fill: "none" }}
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        d="M7 1.75V4.375M14 1.75V4.375M3.0625 7.95375H17.9375M18.375 7.4375V14.875C18.375 17.5 17.0625 19.25 14 19.25H7C3.9375 19.25 2.625 17.5 2.625 14.875V7.4375C2.625 4.8125 3.9375 3.0625 7 3.0625H14C17.0625 3.0625 18.375 4.8125 18.375 7.4375Z"
        stroke="#801FFF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4962 11.9875H10.5049M7.25781 11.9875H7.26656M7.25781 14.6125H7.26656"
        stroke="#801FFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
