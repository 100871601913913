import { useFieldArray, useForm } from "react-hook-form";

import { Button } from "@components/Button";
import { FormInput } from "@components/FormInput";

interface TestFormInput {
  test: {
    firstName: string;
  }[];
}

export const TestChartsPage = () => {
  const { control, handleSubmit, setValue } = useForm<TestFormInput>({
    defaultValues: {
      test: [],
    },
  });

  const { fields, append, update } = useFieldArray({
    control,
    name: "test",
  });

  return (
    <form onSubmit={handleSubmit(data => console.log(data))}>
      {fields.map((item, index) => (
        <FormInput
          key={item.id}
          name={`test.${index}.firstName`}
          control={control}
        />
      ))}

      <Button onClick={() => append({ firstName: "bill" })}>append</Button>

      <Button onClick={() => setValue("test.0", { firstName: "123" })}>
        setValue
      </Button>

      <Button onClick={() => update(0, { firstName: "123" })}>update</Button>

      <Button submit>submit</Button>
    </form>
  );
};
