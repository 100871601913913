import { useMemo } from "react";
import { FormProvider } from "react-hook-form";

import { QuestionType, SystemQuestionDto } from "@client/surveys";
import { useAppTranslation } from "@hooks";
import { useUpdateSurveySubgroupQuestionMutation } from "@hooks/queries/surveys";

import { LangDto } from "@client";
import { Question } from "../Question/Question";
import { QuestionEditWatch } from "../QuestionEditWatch";
import { RequiredConfigFormInputs } from "../forms";
import {
  mapSystemQuestionConfigForm,
  mapSystemQuestionConfigRequest,
  useSystemQuestionConfigForm,
} from "../forms/useSystemQuestionConfigForm";
import {
  OptionText,
  OptionWrapper,
  RadioStyled,
} from "./SystemQuestionUserPreferencesFoodUnliked.styled";

interface SystemQuestionUserPreferencesFoodUnlikedProps {
  listIndex: number;
  totalQuestions: number;
  question: SystemQuestionDto;
  onTypeChange: (currentType: QuestionType, newType: QuestionType) => void;
  questionnaireLang?: LangDto;
}

export const SystemQuestionUserPreferencesFoodUnliked = ({
  listIndex,
  totalQuestions,
  question,
  onTypeChange,
  questionnaireLang,
}: SystemQuestionUserPreferencesFoodUnlikedProps) => {
  const { t } = useAppTranslation();
  const mappedForm = useMemo(
    () =>
      mapSystemQuestionConfigForm({
        ...question,
        title: t(
          "questionnaires.questions.system_user_preferences_food_unliked.question",
          {
            lng: questionnaireLang,
          },
        ),
      }),
    [question],
  );
  const form = useSystemQuestionConfigForm(mappedForm);
  const { mutate } = useUpdateSurveySubgroupQuestionMutation();

  const onSubmit = (data: RequiredConfigFormInputs) => {
    mutate({
      id: question.id.toString(),
      payload: mapSystemQuestionConfigRequest(data, question),
    });
  };

  return (
    <FormProvider {...form}>
      <Question
        id={question.id}
        listIndex={listIndex}
        totalQuestions={totalQuestions}
        onTypeChange={onTypeChange}
        questionType={question.type}
        systemQuestion
        infoText={t(
          "questionnaires.questions.system_user_preferences_food_unliked.info",
        )}
      >
        <OptionWrapper>
          <RadioStyled disabled />

          <OptionText>
            {t(
              "questionnaires.questions.system_user_preferences_food_unliked.yes",
            )}
          </OptionText>
        </OptionWrapper>
        <OptionWrapper>
          <RadioStyled disabled />

          <OptionText>
            {t(
              "questionnaires.questions.system_user_preferences_food_unliked.no",
            )}
          </OptionText>
        </OptionWrapper>
      </Question>

      <QuestionEditWatch isLoading={false} onSubmit={onSubmit} />
    </FormProvider>
  );
};
