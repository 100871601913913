import _, { round } from "lodash";

interface SimulationCalculatorProps {
  duration: number;
  clientWeight: number;
  balancePace: number;
  simulationPace: number;
  simulationDuration: number;
  positive: boolean;
}

export const simulationCalculator = ({
  duration,
  clientWeight,
  balancePace,
  positive,
  simulationPace,
  simulationDuration,
}: SimulationCalculatorProps) => {
  const sign = positive ? 1 : -1;
  const weeks = Math.max(simulationDuration, duration);
  const differenceInPercentage = (balancePace / clientWeight) * 100;

  return _.range(weeks + 1).map(week => ({
    week: week,
    balance:
      week <= duration
        ? {
            weight: round(clientWeight + balancePace * week, 2),
            kgChange: balancePace,
            percentChange: differenceInPercentage,
          }
        : null,
    simulation:
      week <= simulationDuration
        ? {
            weight: round(clientWeight + simulationPace * sign * week, 2),
            kgChange: simulationPace * sign,
            percentChange: clientWeight
              ? (simulationPace / clientWeight) * 100 * sign
              : 0,
          }
        : null,
  }));
};
