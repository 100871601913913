import {
  MouseEvent,
  forwardRef,
  useState,
  CSSProperties,
  ReactNode,
} from "react";
import { useNavigate } from "react-router-dom";

import classNames from "classnames";

import { ButtonLoader } from "./ButtonLoader";

/**
 * Universal button component
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      id,
      children,
      contentPosition = "center",
      color = "primary",
      size = "medium",
      to,
      name,
      icon,
      iconEnd,
      disabled = false,
      className,
      style,
      submit = false,
      fontLight = false,
      fullWidth = false,
      isLoading = false,
      onClickEvent,
      onClick,
    },
    ref,
  ) => {
    const navigate = useNavigate();

    const [effect, setEffect] = useState(false);

    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
      setEffect(true);
      if (to) navigate(to);
      onClick && onClick();
      onClickEvent && onClickEvent(e);
    };

    return (
      <button
        id={id}
        ref={ref}
        name={name}
        type={submit ? "submit" : "button"}
        className={classNames(
          {
            "flex items-center": true,
            "rounded-md": size !== "small-vertical",
            "font-semibold": !fontLight,
            "w-full": fullWidth,
            "w-max": !fullWidth && size !== "square",
            "bg-primary hover:bg-secondary text-white":
              !disabled && color === "primary",
            "bg-white hover:bg-primary-opacity hover:text-primary-dark text-purple-dark border border-solid border-purple-dark":
              !disabled && color === "secondary-purple",
            "bg-alert hover:bg-alert/80 text-white":
              !disabled && color === "danger",
            "bg-red-medium hover:bg-red-medium/80 text-white":
              !disabled && color === "error",
            "bg-white hover:bg-secondary-lighter text-primary border border-solid border-primary":
              !disabled && color === "secondary",
            "bg-transparent hover:bg-transparent-tint":
              !disabled && color === "background",
            "bg-gray-400 text-black hover:bg-gray-700": color === "gray",
            "bg-gray-200 text-gray-700 cursor-not-allowed hover:bg-gray-200":
              disabled,
            "bg-primary-light hover:bg-primary-light cursor-not-allowed":
              isLoading,
            "text-xs py-2 px-4": size === "small",
            "text-sm py-2.5 px-6": size === "medium",
            "text-base py-3 px-6": size === "large",
            "text-sm": size === "square",
            "text-xs py-5 px-3": size === "small-vertical",
            "animate-pulse": effect,
            "justify-center": contentPosition === "center",
            "justify-start": contentPosition === "left",
            "justify-end": contentPosition === "right",
          },
          className,
        )}
        style={style}
        disabled={isLoading || disabled}
        onClick={!isLoading && !disabled ? handleClick : undefined}
        onAnimationEnd={() => setEffect(false)}
        aria-hidden="false"
      >
        {isLoading ? (
          <ButtonLoader />
        ) : (
          <>
            {icon && (
              <span className={children ? "pr-2 flex" : undefined}>{icon}</span>
            )}
            {children}
            {iconEnd && (
              <span className={children ? "pl-2 flex" : undefined}>
                {iconEnd}
              </span>
            )}
          </>
        )}
      </button>
    );
  },
);

export type ButtonContentPosition = "left" | "center" | "right";
export type ButtonSize =
  | "small"
  | "medium"
  | "large"
  | "square"
  | "small-vertical";
export type ButtonColor =
  | "primary"
  | "secondary"
  | "background"
  | "gray"
  | "danger"
  | "secondary-purple"
  | "error";

export interface ButtonProps {
  id?: string;
  /** text or other button content */
  children?: string | ReactNode;
  /** button disabled property */
  disabled?: boolean;
  /** additional classes */
  className?: string;
  /** additional styles */
  style?: CSSProperties;
  /** button name attribute */
  name?: string;
  /** button inner content position */
  contentPosition?: ButtonContentPosition;
  /** button size */
  size?: ButtonSize;
  /** button color */
  color?: ButtonColor;
  /** icon shown before text */
  icon?: ReactNode;
  /** icon shown after text */
  iconEnd?: ReactNode;
  /** if true, button text will not be boldened */
  fontLight?: boolean;
  /** if true, button will be stretched to fit parent width */
  fullWidth?: boolean;
  /** link to application view */
  to?: string;
  /** Sets button type to submit */
  submit?: boolean;
  /** if true, animated dots will be displayed and button will be disabled */
  isLoading?: boolean;
  /** callback fired on clicking the button */
  onClick?: () => void;
  onClickEvent?: (e: MouseEvent<HTMLButtonElement>) => void;
}
