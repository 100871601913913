import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const updateClientTags = async (
  patientId: string,
  payload: UpdateClientTagsRequest,
) => {
  await fetchData(
    `/dietitian/patients/${patientId}/tags/sync-by-category`,
    APIMethods.PUT,
    payload,
  );
};

export interface UpdateClientTagsRequest {
  categoryType: number;
  tagsId: number[];
}
