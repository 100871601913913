import { ReactNode, useMemo, useState } from "react";
import { INTRODUCTION, TableOfContentsContext } from "./TableOfContentsContext";
import { useQueryClient } from "@tanstack/react-query";
import { fetchSurveyFullQueryKey } from "@hooks/queries/surveys";
import { useAppParams } from "@hooks";
import { ApiResponse } from "@typeDefinitions";
import { FullSurveyDto } from "@client/surveys";

interface TableOfContentsContextProps {
  children: ReactNode;
  defaultGroupId?: number | null;
  defaultSubgroupId?: number | null;
  definedQuestionnaireId?: string;
}

export const TableOfContentsContextProvider = ({
  children,
  defaultGroupId = null,
  defaultSubgroupId = null,
  definedQuestionnaireId,
}: TableOfContentsContextProps) => {
  const handleDefaultSelect = () => {
    if (defaultSubgroupId && defaultGroupId)
      return `${defaultGroupId}-${defaultSubgroupId}`;

    if (defaultGroupId) return `${defaultGroupId}`;

    return INTRODUCTION;
  };

  const { questionnaireId } = useAppParams();
  const questionnaireIdEnsured = definedQuestionnaireId ?? questionnaireId;
  const [selectedId, setSelectedId] = useState<string>(handleDefaultSelect());
  const [groupId, setGroupId] = useState<number | null>(defaultGroupId);
  const [subgroupId, setSubgroupId] = useState<number | null>(
    defaultSubgroupId,
  );

  const queryClient = useQueryClient();
  const data = queryClient.getQueryData<ApiResponse<FullSurveyDto>>([
    fetchSurveyFullQueryKey,
    questionnaireIdEnsured,
  ]);
  const groupsList = useMemo(
    () =>
      data?.data.groups.map(g => ({
        id: g.id,
        subgroupsList: g.sections.map(s => s.id),
      })) ?? [],
    [data],
  );

  const getNearGroupId = (currId: number | null) => {
    const currIndex = groupsList?.findIndex(({ id }) => id === currId);

    if (!groupsList.length) return 0;

    if (currIndex === undefined) return groupsList[0].id;

    if (currIndex === 0) return groupsList[1].id;
    else return groupsList[currIndex - 1].id;
  };

  const getNearSubgroupId = (currId: number | null) => {
    const subgroupsList = groupsList?.find(
      ({ id }) => id === groupId,
    )?.subgroupsList;
    const currIndex = subgroupsList?.findIndex(id => id === currId);

    if (!subgroupsList?.length) return 0;

    if (currIndex === undefined) return subgroupsList[0];

    if (currIndex === 0) return subgroupsList[1];
    else return subgroupsList[currIndex - 1];
  };

  const value = {
    selectedId,
    setSelectedId,
    groupId,
    setGroupId,
    subgroupId,
    setSubgroupId,
    getNearGroupId,
    getNearSubgroupId,
  };

  return (
    <TableOfContentsContext.Provider value={value}>
      {children}
    </TableOfContentsContext.Provider>
  );
};
