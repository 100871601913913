import { CheckedIcon } from "@assets/icons";
import { useAppTranslation } from "@hooks";
import {
  MenuItem,
  PaginationItem,
  Select,
  SelectChangeEvent,
  TablePaginationProps,
} from "@mui/material";
import { GridPagination } from "@mui/x-data-grid";
import { Dispatch, MouseEvent, SetStateAction, useState } from "react";
import {
  PerPageWrapper,
  StyledMuiPagination,
  TextStyled,
} from "./Pagination.styled";

interface CustomPaginationProps
  extends Pick<TablePaginationProps, "page" | "onPageChange" | "className"> {
  setPerPage?: Dispatch<SetStateAction<number>>;
  perPage?: number;
  isOnePage: boolean;
  definedPageCount?: number;
  rowsPerPageOptions?: number[];
  count?: number;
}

export enum ITEMS_ON_PAGE {
  TEN = 10,
  TWENTY_FIVE = 25,
  FIFTY = 50,
}
//TODO: Move to utility functions

export const getEnumValuesAsNumbers = <T extends object>(
  enumObject: T,
): number[] => {
  return Object.values(enumObject).filter(
    (value): value is number => typeof value === "number",
  );
};

export const Pagination = ({
  page,
  onPageChange,
  className,
  setPerPage,
  perPage,
  isOnePage,
  definedPageCount,
  rowsPerPageOptions = getEnumValuesAsNumbers(ITEMS_ON_PAGE),
  count = 1,
}: CustomPaginationProps) => {
  const { t } = useAppTranslation();
  const showPerPageSelect = !!setPerPage && !!perPage;
  const calculatedPages = perPage ? Math.ceil(count / perPage) : 1;
  const pageCount = definedPageCount ?? calculatedPages ?? 1;
  const [open, setOpen] = useState(false);

  const siblingCount = 0;
  const boundaryCount = 2;

  const handleChange = (event: SelectChangeEvent<number>) => {
    showPerPageSelect && setPerPage(Number(event.target.value));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const PreviousArr = () => {
    return <CheckedIcon className="rotate-90" size="w-4 h-4" />;
  };

  const NextArr = () => {
    return <CheckedIcon className="-rotate-90" size="w-4 h-4" />;
  };

  return (
    <>
      <StyledMuiPagination
        className={className}
        count={pageCount}
        page={page + 1}
        siblingCount={siblingCount}
        boundaryCount={boundaryCount}
        onChange={(event, newPage) => {
          onPageChange(event as MouseEvent<HTMLButtonElement>, newPage - 1);
        }}
        renderItem={item => (
          <PaginationItem
            slots={{ previous: PreviousArr, next: NextArr }}
            {...item}
          />
        )}
        isOnePage={isOnePage}
      />

      {showPerPageSelect && (
        <PerPageWrapper>
          <TextStyled>{t("clients.pagination.items_on_page")}</TextStyled>
          <Select
            displayEmpty
            id="perPage"
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={perPage}
            onChange={handleChange}
          >
            {rowsPerPageOptions.map(option => (
              <MenuItem value={option} key={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </PerPageWrapper>
      )}
    </>
  );
};

export const GridPaginationWrapper = (props: CustomPaginationProps) => {
  return (
    <GridPagination
      ActionsComponent={params => <Pagination {...params} {...props} />}
    />
  );
};
