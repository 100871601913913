import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { FormProvider } from "react-hook-form";
import { toast } from "react-toastify";

import { useAppTranslation, useSectionForm, useSectionParams } from "@hooks";
import { DetailedSection } from "@typeDefinitions";
import { SectionFormInput } from "@typeDefinitions/types";
import { useCreateSectionMutation, useFetchSectionQuery } from "@hooks/queries";
import { NotFound } from "@views/error/NotFound";
import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";
import { SingleCardPageLayout } from "@components/PageLayout";
import { EditorHeaderReviewedToggle } from "@components/EditorHeaderReviewedToggle";
import { SectionForm } from "@components/SectionForm";
import { FormCommitFooter } from "@components/MealForm";

export const SectionCopy = () => {
  const { t, isPolishChosen } = useAppTranslation();
  const sectionId = useSectionParams();
  const navigate = useNavigate();
  const { section, isError, isLoading } = useFetchSectionQuery(sectionId);
  const parsedSection = useMemo((): DetailedSection | undefined => {
    if (!section) return undefined;
    return {
      id: section.data.id,
      categories: [section.data.programSectionCategory.id],
      nameEn: `${section.data.titleEn ?? section.data.title} (Copy)`,
      namePl: `${section.data.title} (Kopia)`,
      contentEn: section.data.contentEn,
      contentPl: section.data.content,
      categoryName: isPolishChosen
        ? section.data.programSectionCategory.description
        : section.data.programSectionCategory.descriptionEn,
      reviewed: section.data.reviewed,
    };
  }, [section]);
  const form = useSectionForm(parsedSection);
  const { mutate: createSectionMutation, isLoading: mutationLoading } =
    useCreateSectionMutation({
      onSuccess: () => toast.success(t("sections.success_copy")),
    });

  const submit = useCallback(
    (d: SectionFormInput) => {
      createSectionMutation(
        {
          title: d.namePl,
          titleEn: d.nameEn,
          content: d.contentPl,
          contentEn: d.contentEn,
          programSectionCategoryId: parseInt(d.sectionCategoryId),
          reviewed: !!d.reviewed,
        },
        { onSuccess: () => navigate(-1) },
      );
    },
    [createSectionMutation],
  );

  if (isNaN(sectionId)) {
    return <NotFound />;
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return <ApiError />;
  }

  return (
    <FormProvider {...form}>
      <SingleCardPageLayout
        title={t("sections.copy_section")}
        extra={<EditorHeaderReviewedToggle name={"reviewed"} />}
      >
        <form
          className="flex flex-col gap-7 p-7"
          onSubmit={form.handleSubmit(submit)}
        >
          <SectionForm withCategory />

          <FormCommitFooter
            actionText={t("common.save")}
            isLoading={mutationLoading}
          />
        </form>
      </SingleCardPageLayout>
    </FormProvider>
  );
};
