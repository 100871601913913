import { ReactNode } from "react";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";

import { Close } from "@assets/icons/DesignSystem";
import { useAppTranslation } from "@hooks";

import { DialogTitleStyled, IconButtonStyled } from "./ModalWrapper.styled";

interface ModalWrapperProps {
  children: ReactNode;
  open: boolean;
  onClose: () => void;
  title: string;
  cancelText?: string;
  submitText: string;
  onSubmit: () => void;
  disableSubmit?: boolean;
}

export const ModalWrapper = ({
  children,
  open,
  onClose,
  onSubmit,
  title,
  cancelText,
  submitText,
  disableSubmit,
}: ModalWrapperProps) => {
  const { t } = useAppTranslation();
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitleStyled>{title}</DialogTitleStyled>

      <IconButtonStyled onClick={onClose} size="small">
        <Close size="w-3 h-3" />
      </IconButtonStyled>

      <DialogContent>{children}</DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {cancelText ?? t("common.cancel")}
        </Button>
        <Button variant="contained" onClick={onSubmit} disabled={disableSubmit}>
          {submitText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
