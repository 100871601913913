import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import * as yup from "yup";
import {
  PatientHydrationProductsMonitoringResource,
  patientHydrationProductsMonitoringSchema,
} from "@client/resources/PatientHydrationProductsMonitoringResource";
import { logFetchValidateError } from "@utils/validate";

export type FetchMonitoringIrrigationParams = {
  patientId: number;
  dateFrom: string;
  dateTo: string;
};

export type FetchMonitoringIrrigationResponse = ApiResponse<
  PatientHydrationProductsMonitoringResource[]
>;

export const fetchMonitoringIrrigation = async (
  params: FetchMonitoringIrrigationParams,
): Promise<FetchMonitoringIrrigationResponse> => {
  const response = await fetchData(
    `/dietitian/patients/{patient_id}/monitoring2/irrigation`.replace(
      "{patient_id}",
      params.patientId.toString(),
    ),
    APIMethods.GET,
    undefined,
    mapUrlParams(params),
  );

  return await schema
    .validate(response)
    .catch(
      logFetchValidateError(
        "/dietitian/patients/{patient_id}/monitoring2/irrigation",
      ),
    );
};

const mapUrlParams = (params: FetchMonitoringIrrigationParams) => {
  const urlParams = new URLSearchParams();
  urlParams.set("date-from", params.dateFrom);
  urlParams.set("date-to", params.dateTo);

  return urlParams;
};

const schema = yup.object({
  data: yup.array().of(patientHydrationProductsMonitoringSchema).defined(),
});
