import { fetchCreatorMealQueryKey, MutationOptions } from "@hooks/queries";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteCreatorMealProduct, FetchMealResponse } from "@client/meals";
import { useFetchMealTagsQueryKey } from "@hooks/queries/meals/useFetchCreatorMealTags";
import { fetchCreatorMealNutrientsQueryKey } from "@hooks/queries/meals/useFetchCreatorMealNutrientsQuery";
import { ApiResponse } from "@typeDefinitions";
import { useMealVersion } from "@views/dietician/MealEdit/hooks";

export const useDeleteCreatorMealProductMutation = (
  mealId: string,
  productId: string,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();
  const { version, updateVersion } = useMealVersion();

  const mutation = useMutation(
    () => deleteCreatorMealProduct(mealId, productId, version),
    {
      ...options,
      onSuccess: (data, variables) => {
        options?.onSuccess && options.onSuccess(data, variables);
        queryClient.invalidateQueries([useFetchMealTagsQueryKey, mealId]);
        queryClient.invalidateQueries([
          fetchCreatorMealNutrientsQueryKey,
          mealId,
        ]);
      },
      onMutate: async () => {
        await queryClient.cancelQueries([fetchCreatorMealQueryKey, mealId]);
        queryClient.setQueryData<ApiResponse<FetchMealResponse>>(
          [fetchCreatorMealQueryKey, mealId],
          old => {
            if (!old) return;
            return {
              ...old,
              data: {
                ...old?.data,
                products: old?.data.products.filter(
                  p => p.id.toString() !== productId,
                ),
                numberOfItems: old.data.numberOfItems - 1,
              },
            };
          },
        );
        updateVersion();
      },
      onError: () => {
        queryClient.invalidateQueries([fetchCreatorMealQueryKey, mealId]);
      },
    },
  );

  return mutation;
};
