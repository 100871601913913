import { ChangeEvent } from "react";
import { useController } from "react-hook-form";

import { BalanceValueMethod } from "@utils/balance";
import { useAppTranslation } from "@hooks";
import { InputSuffixIconButton } from "./InputSuffixIconButton";
import { LabeledSlider } from "./LabeledSlider";
import { CaloricNeedsFormInputs } from "../useCaloricNeedsForm";
import { getPal } from "@views/dietician/PatientEnergyBalance";

import { decimalInput1 } from "@utils/inputs";

import { FlexRow, StyledText } from "../CaloricNeedsForm.styled";

export const PalFormInput = () => {
  const { t } = useAppTranslation();

  const {
    field: {
      value: { value, method, training, daily },
      onChange,
    },
  } = useController<CaloricNeedsFormInputs, "pal">({ name: "pal" });

  const handleChangeMethod = () => {
    if (method === BalanceValueMethod.AUTO) {
      onChange({ value, method: BalanceValueMethod.MANUAL, training, daily });
    } else {
      onChange({
        value: getPal(daily, training),
        method: BalanceValueMethod.AUTO,
        training,
        daily,
      });
    }
  };

  const handleChangeValue = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    onChange({ value: Number(e.target.value), method, training, daily });
  };

  const handleChangeDaily = (daily: number) => {
    onChange({
      value: getPal(daily, training),
      method,
      training,
      daily,
    });
  };

  const handleChangeTraining = (training: number) => {
    onChange({
      value: getPal(daily, training),
      method,
      training,
      daily,
    });
  };

  return (
    <>
      <FlexRow>
        <StyledText variant="body2">{t("balance.pal")}</StyledText>
        <InputSuffixIconButton
          method={method}
          onIconClick={handleChangeMethod}
          value={value ?? ""}
          onChange={handleChangeValue}
          disabled={method === BalanceValueMethod.AUTO}
          iconDisabled={false}
          inputComponent={decimalInput1}
        />
      </FlexRow>
      <FlexRow>
        <StyledText variant="body2">{t("balance.daily_activity")}</StyledText>
        <LabeledSlider
          step={33.3}
          value={daily ?? 0}
          onChange={handleChangeDaily}
          disabled={method === BalanceValueMethod.MANUAL}
        />
      </FlexRow>
      <FlexRow>
        <StyledText variant="body2">
          {t("balance.training_activity")}
        </StyledText>
        <LabeledSlider
          step={25}
          value={training ?? 0}
          onChange={handleChangeTraining}
          disabled={method === BalanceValueMethod.MANUAL}
        />
      </FlexRow>
    </>
  );
};
