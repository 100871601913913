import { useAppTranslation } from "@hooks";
import {
  SimulationHeaderSection,
  SimulationListSection,
  StyledItem,
  StyledTitle,
} from "./SimulationTable.styled";

interface SimulationTableProps {
  data: {
    week: number;
    balance: {
      weight: number;
      kgChange: number;
      percentChange: number;
    } | null;
    simulation: {
      weight: number;
      kgChange: number;
      percentChange: number;
    } | null;
  }[];
}

export const SimulationTable = ({ data }: SimulationTableProps) => {
  const { t } = useAppTranslation();
  return (
    <div className="h-[260px] overflow-y-auto">
      <SimulationHeaderSection>
        <div />
        <StyledTitle className="col-span-3">Bilans</StyledTitle>
        <StyledTitle className="col-span-3">Symulacja</StyledTitle>

        <StyledTitle>{t("common.week")}</StyledTitle>
        <StyledTitle>{t("common.bodyWeight")}</StyledTitle>
        <StyledTitle>{t("balance.kg_change")}</StyledTitle>
        <StyledTitle>{t("balance.percent_change")}</StyledTitle>

        <StyledTitle>{t("common.bodyWeight")}</StyledTitle>
        <StyledTitle>{t("balance.kg_change")}</StyledTitle>
        <StyledTitle>{t("balance.percent_change")}</StyledTitle>
      </SimulationHeaderSection>

      <ul>
        {data.map(({ balance, simulation, week }, index) => (
          <li key={index}>
            <SimulationListSection>
              <StyledItem>{`${t("common.week")} ${week}`}</StyledItem>
              <StyledItem>
                {balance ? `${balance.weight.toFixed(2)} kg` : "-"}
              </StyledItem>
              <StyledItem>
                {balance ? `${balance.kgChange.toFixed(2)} kg` : "-"}
              </StyledItem>
              <StyledItem>
                {balance ? `${balance.percentChange.toFixed(2)} %` : "-"}
              </StyledItem>

              <StyledItem>
                {simulation ? `${simulation.weight.toFixed(2)} kg` : "-"}
              </StyledItem>
              <StyledItem>
                {simulation ? `${simulation.kgChange.toFixed(2)} kg` : "-"}
              </StyledItem>
              <StyledItem>
                {simulation ? `${simulation.percentChange.toFixed(2)} %` : "-"}
              </StyledItem>
            </SimulationListSection>
          </li>
        ))}
      </ul>
    </div>
  );
};
