import { Dispatch, MouseEvent, SetStateAction } from "react";
import {
  StyledButton,
  StyledCard,
  StyledPopover,
} from "./ConfirmationPopover.styled";

interface ConfirmationPopoverProps {
  onConfirm: (e: MouseEvent<HTMLButtonElement>) => void;
  anchor?: HTMLElement;
  setAnchor: Dispatch<SetStateAction<(EventTarget & HTMLElement) | undefined>>;
  confirmationText: string;
  submitText: string;
  loading?: boolean;
}

export const ConfirmationPopover = ({
  onConfirm,
  anchor,
  setAnchor,
  confirmationText,
  submitText,
  loading,
}: ConfirmationPopoverProps) => {
  const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchor(undefined);
    onConfirm(e);
  };
  return (
    <StyledPopover
      open={!!anchor}
      anchorEl={anchor}
      onClose={() => setAnchor(undefined)}
    >
      <StyledCard padding="1rem">
        <p>{confirmationText}</p>
        <StyledButton
          variant="contained"
          data-cy="button-confirm"
          onClick={handleSubmit}
          loading={loading}
        >
          {submitText}
        </StyledButton>
      </StyledCard>
    </StyledPopover>
  );
};
