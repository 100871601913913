import { useMemo } from "react";
import { FormProvider } from "react-hook-form";

import { QuestionType, SystemQuestionDto } from "@client/surveys";
import { useAppTranslation } from "@hooks";
import {
  useFetchSurveyDictionariesQuery,
  useUpdateSurveySubgroupQuestionMutation,
} from "@hooks/queries/surveys";

import { LangDto } from "@client";
import { Question } from "../Question/Question";
import { QuestionEditWatch } from "../QuestionEditWatch";
import { RequiredConfigFormInputs } from "../forms";
import {
  mapSystemQuestionConfigForm,
  mapSystemQuestionConfigRequest,
  useSystemQuestionConfigForm,
} from "../forms/useSystemQuestionConfigForm";
import {
  CheckboxStyled,
  OptionText,
  OptionWrapper,
  RadioStyled,
} from "./SystemQuestionTagDietType.styled";

interface SystemQuestionTagDietTypeProps {
  listIndex: number;
  totalQuestions: number;
  question: SystemQuestionDto;
  onTypeChange: (currentType: QuestionType, newType: QuestionType) => void;
  questionnaireLang?: LangDto;
}

export const SystemQuestionTagDietType = ({
  listIndex,
  totalQuestions,
  question,
  onTypeChange,
  questionnaireLang,
}: SystemQuestionTagDietTypeProps) => {
  const { t } = useAppTranslation();
  const mappedForm = useMemo(
    () =>
      mapSystemQuestionConfigForm({
        ...question,
        title: t("questionnaires.questions.system_tag_diet_type.question", {
          lng: questionnaireLang,
        }),
      }),
    [question],
  );
  const form = useSystemQuestionConfigForm(mappedForm);
  const { mutate } = useUpdateSurveySubgroupQuestionMutation();
  const { data, isLoading } = useFetchSurveyDictionariesQuery();
  const dietList = data?.systemTagDietType;
  const onSubmit = (data: RequiredConfigFormInputs) => {
    mutate({
      id: question.id.toString(),
      payload: mapSystemQuestionConfigRequest(data, question),
    });
  };

  if (isLoading) return <></>;

  return (
    <FormProvider {...form}>
      <Question
        id={question.id}
        listIndex={listIndex}
        totalQuestions={totalQuestions}
        onTypeChange={onTypeChange}
        questionType={question.type}
        systemQuestion
        infoText={t("questionnaires.questions.system_tag_diet_type.info")}
      >
        <div className="grid gap-[24px]">
          <div className="grid gap-[12px]">
            <OptionWrapper>
              <RadioStyled disabled />

              <OptionText>
                {t("questionnaires.questions.system_tag_allergen.no")}
              </OptionText>
            </OptionWrapper>
            <OptionWrapper>
              <RadioStyled disabled checked />

              <OptionText>
                {t("questionnaires.questions.system_tag_allergen.yes")}
              </OptionText>
            </OptionWrapper>
          </div>

          <div className="grid gap-[12px]">
            {dietList?.map(diet => (
              <OptionWrapper key={diet.id}>
                <CheckboxStyled disabled />

                <OptionText>{diet.name}</OptionText>
              </OptionWrapper>
            ))}
          </div>
        </div>
      </Question>

      <QuestionEditWatch isLoading={false} onSubmit={onSubmit} />
    </FormProvider>
  );
};
