import { useQuery } from "@tanstack/react-query";

import { DictionariesDto, fetchSurveyDictionaries } from "@client/surveys";
import { ApiResponse } from "@typeDefinitions";

import { QueryOptionsTyped } from "../types";

const fetchSurveyDictionariesQueryKey = "fetchSurveyDictionariesQueryKey";

export const useFetchSurveyDictionariesQuery = (
  options?: QueryOptionsTyped<ApiResponse<DictionariesDto>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchSurveyDictionariesQueryKey],
    fetchSurveyDictionaries,
    options,
  );

  return {
    data: data?.data,
    ...rest,
  };
};
