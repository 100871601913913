import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import {
  FetchSingedSurveysResponse,
  fetchSignedSurveysResponseSchema,
} from "./fetchSingedSurveys";

export const fetchPatientSurvey = async (
  patientId: string,
  surveyId: string,
): Promise<ApiResponse<FetchSingedSurveysResponse>> => {
  const response = await fetchData(
    `/dietitian/patients/${patientId}/surveys/${surveyId}`,
    APIMethods.GET,
  );

  return fetchSignedSurveysResponseSchema.validate(response);
};
