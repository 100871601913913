import { useQuery } from "@tanstack/react-query";
import { fetchClientBalance, FetchClientBalanceResponse } from "@client";
import { QueryOptionsTyped } from "@hooks/queries/types";
import { ApiResponse } from "@typeDefinitions";

export const fetchClientBalanceQueryKey = "fetchClientBalanceQueryKey";

export const useFetchClientBalanceQuery = (
  patientId: string,
  options?: QueryOptionsTyped<ApiResponse<FetchClientBalanceResponse>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchClientBalanceQueryKey, patientId],
    () => fetchClientBalance(patientId),
    options,
  );

  return {
    balanceData: data?.data,
    ...rest,
  };
};
