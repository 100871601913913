import { useParams } from "react-router-dom";
import PatientPrograms from "@views/dietician/PatientProgramsNew/PatientPrograms";
import { Stack } from "@mui/material";

const PatientProgramsView = () => {
  const { patientId } = useParams();

  if (patientId === undefined) {
    return null;
  }

  return (
    <Stack direction="column" gap="16px">
      <PatientPrograms patientId={Number.parseInt(patientId)} />
    </Stack>
  );
};

export default PatientProgramsView;
