export const useGlycaemicIndicators = (ingredients: Ingredient[]) => {
  const glycaemicIndicators = ingredients.map(ingredient => {
    const carbo = ingredient.nutrients?.find(n => n.id === 205)?.value;
    const fiber = ingredient.nutrients?.find(n => n.id === 291)?.value;
    const dc = !!carbo && !!fiber ? carbo - fiber : undefined;
    const grams = ingredient?.grams;

    const dcPerPortion = !!dc && !!grams ? dc / (grams / 100) : undefined;
    const ig = ingredient.nutrients?.find(n => n.id === 1109)?.value;

    return {
      ig,
      dc,
      ww: dc ? dc / 10 : undefined,
      lg: !!ig && !!dcPerPortion ? ig / dcPerPortion / 100 : undefined,
    };
  });

  const digestibleCarbo =
    glycaemicIndicators
      .map(i => i.dc)
      .filter(dc => dc !== undefined)
      .reduce((a, b) => a! + b!, 0) ?? 0;

  const glycaemicIndex =
    glycaemicIndicators
      .filter(i => i.dc !== undefined && i.ig !== undefined)
      .map(i => (i.dc! * i.ig!) / digestibleCarbo)
      .reduce((a, b) => a! + b!, 0) ?? 0;

  const glycaemicLoad =
    glycaemicIndicators
      .filter(i => i.lg !== undefined)
      .map(i => i.lg)
      .reduce((a, b) => a! + b!, 0) ?? 0;

  const wwPerRecipe = digestibleCarbo ? digestibleCarbo / 10 : undefined;

  return {
    glycaemicIndicators,
    glycaemicIndex,
    glycaemicLoad,
    wwPerRecipe,
  };
};

interface Ingredient {
  grams: number;
  nutrients: {
    id: number;
    value: number;
  }[];
}
