import { fetchData } from "@utils/api";
import { APIMethods, NoContentApiResponse } from "@typeDefinitions";

export type UpdatePatientBodyMeasurementParams = {
  bodyMeasurementId: number;
};

export type UpdatePatientBodyMeasurementResponse = NoContentApiResponse;

export type UpdatePatientBodyMeasurementPayload = {
  date: string | null;
  bodyMeasurements?: { id: number; value: number | null }[];
};

export const updatePatientBodyMeasurement = async (
  params: UpdatePatientBodyMeasurementParams,
  payload: UpdatePatientBodyMeasurementPayload,
): Promise<UpdatePatientBodyMeasurementResponse> => {
  return await fetchData(
    "/dietitian/patient-measurements/{measurement_body_id}".replace(
      "{measurement_body_id}",
      params.bodyMeasurementId.toString(),
    ),
    APIMethods.PUT,
    payload,
  );
};
