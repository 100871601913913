import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  styled,
} from "@mui/material";

export const BorderedAccordion = styled(Accordion)`
  background: inherit;

  :not(:last-of-type) {
    border-bottom: 1px ${({ theme }) => theme.colors.border} solid;
  }
  .Mui-focusVisible {
    background-color: unset;
  }
`;

export const BorderedAccordionDetails = styled(AccordionDetails)`
  margin-left: 1.75rem;
  margin-bottom: 1rem;
  border-left: 1px ${({ theme }) => theme.colors.border} solid;
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  font-size: 1rem;

  & .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  & .MuiAccordionSummary-root {
    margin-right: 2rem;
  }
`;

export const StyledInfoSection = styled("div")`
  display: grid;
  min-width: 26.875rem;
  max-width: 31.25rem;
  width: 100%;
`;

export const StyledWrapper = styled("div")`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-weight: 600;
`;

interface ActionButtonProps {
  lowHeight?: boolean;
}

export const ActionButton = styled(LoadingButton, {
  shouldForwardProp: propName => propName !== "lowHeight",
})<ActionButtonProps>`
  height: ${({ lowHeight }) => (lowHeight ? "2.5rem" : "2.625rem")};
  font-size: 1rem;

  & .MuiButton-startIcon {
    margin-left: 0;
  }
`;
