import { round } from "lodash";

import { Nutrient } from "@typeDefinitions";
import { ENERGY_ID } from "@utils";
import { CheckRounded, Chat } from "@assets/icons";

import {
  BoldTypography,
  DayNumberWrapper,
  DayTile,
  DayTileTypography,
  MonitoringDayRow,
} from "./MonitoringCalendar.styled";
import { PERCENTAGE_BAR_COLORS, handleDayTileColor } from "../monitoringUtils";
import { PercentageBar } from "./PercentageBar";
import { BulletWarning } from "@assets/icons/StatusBullets";

interface MonitoringDayProps {
  dayNumber: number;
  date?: string;
  nutrients?: Nutrient[];
  targetNutrients?: Nutrient[];
  hasNote?: boolean;
  isReported?: boolean;
  isToday?: boolean;
  onClick?: () => void;
  selected?: boolean;
}

export const MonitoringDay = ({
  dayNumber,
  date,
  nutrients,
  targetNutrients,
  hasNote = false,
  isReported = false,
  isToday = false,
  onClick,
  selected,
}: MonitoringDayProps) => {
  const kcal = nutrients?.find(n => n.id === ENERGY_ID)?.value;
  const targetKcal = targetNutrients?.find(n => n.id === ENERGY_ID)?.value;

  const kcalPercentage = kcal && targetKcal && (kcal / targetKcal) * 100;

  if (!date)
    return (
      <DayTile>
        <DayNumberWrapper>
          <BoldTypography variant="subtitle2">{dayNumber}</BoldTypography>
        </DayNumberWrapper>
      </DayTile>
    );

  return (
    <DayTile
      background={handleDayTileColor(kcal, targetKcal)}
      onClick={onClick}
      selected={selected}
      pointer
    >
      <MonitoringDayRow>
        <DayNumberWrapper marked={isToday}>
          <BoldTypography variant="subtitle2">{dayNumber}</BoldTypography>
        </DayNumberWrapper>
        <div className="w-4">{isReported && <CheckRounded />}</div>
        <div className="w-4">{hasNote && <Chat />}</div>
      </MonitoringDayRow>

      <DayTileTypography>{kcal ? round(kcal) : "-"} kcal</DayTileTypography>

      <MonitoringDayRow>
        <DayTileTypography>
          {kcalPercentage ? round(kcalPercentage) : "-"} %
        </DayTileTypography>
        {!!kcalPercentage && kcalPercentage > 200 && (
          <BulletWarning fill={PERCENTAGE_BAR_COLORS.red} />
        )}
      </MonitoringDayRow>

      <PercentageBar kcal={kcal} targetKcal={targetKcal} />
    </DayTile>
  );
};
