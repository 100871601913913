import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import * as yup from "yup";
import { LangDto } from "./common";
import { translationSchema } from "./program";

export const fetchClientFoodPreferences = async (
  patientId: string,
  params?: URLSearchParams,
): Promise<ApiResponse<FetchClientFoodPreferencesResponse[]>> => {
  const response = await fetchData(
    `/dietitian/patients/${patientId}/food-preferences`,
    APIMethods.GET,
    undefined,
    params,
  );

  return await fetchClientFoodPreferencesSchema.validate(response);
};

const foodSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  translations: yup.array().of(translationSchema).required(),
});

const clientFoodPreferencesSchema = yup.object().shape({
  food: foodSchema,
  isLiked: yup.boolean().required(),
});

const fetchClientFoodPreferencesSchema = yup.object().shape({
  data: yup.array().of(clientFoodPreferencesSchema).required(),
});
interface TranslationDto {
  lang: LangDto;
  name: string;
}

interface FoodDto {
  id: number;
  name: string;
  translations: TranslationDto[];
}

export interface FetchClientFoodPreferencesResponse {
  food: FoodDto;
  isLiked: boolean;
}
