import { FetchCreatorMealResponse } from "@client/meals";
import { useAppParams } from "@hooks";
import { fetchCreatorMealQueryKey } from "@hooks/queries";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useMemo } from "react";

export const useMealVersion = () => {
  const queryClient = useQueryClient();
  const { mealId } = useAppParams();
  const data = queryClient.getQueryData<FetchCreatorMealResponse>([
    fetchCreatorMealQueryKey,
    mealId,
  ]);
  const version = useMemo(
    () => Number(data?.headers[ALLOWEAT_CREATOR_VERSION] ?? "1"),
    [data?.headers[ALLOWEAT_CREATOR_VERSION]],
  );

  const updateVersion = () => {
    queryClient.setQueryData<FetchCreatorMealResponse>(
      [fetchCreatorMealQueryKey, mealId],
      oldData => {
        if (!oldData) return;
        const updatedVersion =
          Number(oldData.headers[ALLOWEAT_CREATOR_VERSION]) + 1;

        return {
          data: {
            ...oldData.data,
            version: updatedVersion,
          },
          headers: {
            ...oldData.headers,
            [ALLOWEAT_CREATOR_VERSION]: `${updatedVersion}`,
          },
        };
      },
    );
  };

  const invalidateVersion = (e: AxiosError) => {
    if (e?.response?.data === INCORRECT_VERSION_ERROR)
      queryClient.invalidateQueries([fetchCreatorMealQueryKey, mealId]);
  };

  return { version, updateVersion, invalidateVersion };
};

export const ALLOWEAT_CREATOR_VERSION = "alloweat-creator-version";

const INCORRECT_VERSION_ERROR = "Version is incorrect";
