import { memo } from "react";

import { InputAdornment, TextField } from "@mui/material";

import { FormTextField } from "@components/FormTextField";
import { decimalInputDict } from "@utils/inputs";
import { FetchFoodDetailsResponse } from "@client";
import { fractionNutrientDigitsById } from "@utils";
import { useAppTranslation } from "@hooks";
import { MuiWhiteTooltip } from "@components/MuiButtonWithTooltip";
import { GroupedNutrients } from "@components/GroupedNutrients";

import { EditProductDetails } from "../components/EditProductDetails";
import { ProductMeasuresList } from "../components/ProductMeasures";
import { StyledCard } from "../ProductEdit/ProductEdit.styled";
import { NutrientWrapper, StyledElement } from "./ProductFormBody.styled";

interface ProductEditFormBodyProps {
  product?: FetchFoodDetailsResponse;
}

export const ProductEditFormBody = memo(
  ({ product }: ProductEditFormBodyProps) => {
    const { t } = useAppTranslation();
    const disabledNutrients = [1110, 1111, 1112, 1113];

    const tooltips: Record<number, string> = {
      1110: t("product.nutrients.tooltip_1110"),
      1111: t("product.nutrients.tooltip_1111"),
      1112: t("product.nutrients.tooltip_1112"),
      1113: t("product.nutrients.tooltip_1113"),
    };

    const getTooltip = (id: number): string | undefined => {
      if (tooltips[id]) return tooltips[id];
      return;
    };

    return (
      <div className="grid gap-4">
        <StyledCard>
          <EditProductDetails imageUrl={product?.image?.url} />
        </StyledCard>

        <StyledCard>
          <ProductMeasuresList />
        </StyledCard>

        <StyledCard>
          <GroupedNutrients
            title={t("product.nutrients.nutritional_values_per")}
            biggerPadding
            renderTooltip={(nutrientId, children) => {
              const allowTooltip = disabledNutrients.includes(nutrientId);

              return (
                <MuiWhiteTooltip
                  title={getTooltip(nutrientId)}
                  disableTouchListener={!allowTooltip}
                  disableInteractive={!allowTooltip}
                  disableFocusListener={!allowTooltip}
                  disableHoverListener={!allowTooltip}
                >
                  <StyledElement key={nutrientId} disabled={allowTooltip}>
                    {children}
                  </StyledElement>
                </MuiWhiteTooltip>
              );
            }}
            renderNutrient={(categoryId, nutrientId, unit) => {
              if (!disabledNutrients.includes(nutrientId)) {
                return (
                  <NutrientWrapper>
                    <FormTextField
                      name={`categories.${categoryId}.${nutrientId}`}
                      variant="standard"
                      InputProps={{
                        endAdornment: unit && (
                          <InputAdornment position="end">{unit}</InputAdornment>
                        ),
                        inputComponent:
                          decimalInputDict[
                            fractionNutrientDigitsById(categoryId, nutrientId)
                          ],
                      }}
                    />
                  </NutrientWrapper>
                );
              } else if (product) {
                return (
                  <NutrientWrapper>
                    <TextField
                      disabled
                      variant="standard"
                      value={
                        product.nutrients.find(({ id }) => id === nutrientId)
                          ?.value ?? ""
                      }
                      InputProps={{
                        endAdornment: unit && (
                          <InputAdornment position="end">{unit}</InputAdornment>
                        ),
                        inputComponent:
                          decimalInputDict[
                            fractionNutrientDigitsById(categoryId, nutrientId)
                          ],
                      }}
                    />
                  </NutrientWrapper>
                );
              }
            }}
          />
        </StyledCard>
      </div>
    );
  },
);
