import { Dispatch, SetStateAction } from "react";
import { Nutrient } from "@typeDefinitions";
import { useAppTranslation } from "@hooks";
import { LabeledDetail } from "@components/PreviewDetails";

import {
  HeaderWrapper,
  PreviewWrapper,
  RecipeDetailsWrapper,
  StyledExpandButton,
  StyledImagePlaceholder,
  StyledImg,
  TitleWrapper,
} from "./MealRecipes.styled";
import { ImageOutlined } from "@mui/icons-material";
import { AccordionSummary, Typography } from "@mui/material";
import { MacrosListMui } from "@components/MacrosList";

import { Divider } from "@mui/material";
import { RecipeTable } from "@components/RecipeCompositionSection/RecipeTable";
import { FoodRecipeDto } from "@client";
import { RecipeDescription } from "@views/dietician/Recipe/components/RecipeDescription";
import { MicronutrientPreview } from "@components/MicronutrientsModal";
import {
  AccordionSummaryPlaceholder,
  CountsWrapper,
  ExpandButtonWrapper,
  StyledAccordion,
  StyledAccordionDetails,
  StyledTypography,
} from "./RecipeItem.styled";
import { ArrowDown, Pacman } from "@assets/icons";

interface RecipeItemProps extends MealRecipe {
  allOpened: number[];
  setAllOpened: Dispatch<SetStateAction<number[]>>;
}
export interface MealRecipe {
  id: number;
  selectedServings: number;
  namePl: string;
  nameEn: string;
  nutrients: Nutrient[];
  servings: number;
  productsCount: number;
  mediaUrl: string;
  rows: FoodRecipeDto[];
  descriptionPl: string;
  descriptionEn: string;
  isComplex: boolean;
}
export const RecipeItem = ({
  id,
  selectedServings,
  namePl,
  nameEn,
  nutrients,
  servings,
  productsCount,
  mediaUrl,
  rows,
  descriptionPl,
  descriptionEn,
  isComplex,
  allOpened,
  setAllOpened,
}: RecipeItemProps) => {
  const { t } = useAppTranslation();
  const { isPolishChosen } = useAppTranslation();

  const isOpened = allOpened.includes(id);

  const handleChange = () => {
    setAllOpened(state =>
      state.includes(id) ? state.filter(el => el !== id) : [...state, id],
    );
  };

  const recipeName = isPolishChosen ? namePl : nameEn;

  return (
    <>
      <RecipeDetailsWrapper>
        <HeaderWrapper>
          {mediaUrl && (
            <StyledImg
              src={mediaUrl}
              srcSet={mediaUrl}
              alt={t("recipe.recipe_img")}
            />
          )}
          {!mediaUrl && (
            <StyledImagePlaceholder>
              <ImageOutlined color="disabled" />
            </StyledImagePlaceholder>
          )}
          <PreviewWrapper>
            <div className="flex justify-between">
              <TitleWrapper>
                <Typography variant="h5">{recipeName}</Typography>
                <MacrosListMui nutrients={nutrients} />
              </TitleWrapper>
            </div>
            <CountsWrapper>
              {isComplex && <Pacman />}
              <LabeledDetail
                label={`${t("recipe.servings_count")}:`}
                value={`${selectedServings} / ${servings} ${t(
                  "recipe.portions",
                )}`}
              />
              <LabeledDetail
                label={`${t("recipe.products_count")}:`}
                value={productsCount.toString()}
              />
              <MicronutrientPreview means={nutrients} />
            </CountsWrapper>
          </PreviewWrapper>
          <ExpandButtonWrapper>
            <StyledExpandButton
              disableRipple
              isOpen={isOpened}
              onClick={handleChange}
              endIcon={<ArrowDown className="arrow-down" />}
            >
              <StyledTypography variant="caption">
                {!isOpened ? t("common.expand") : t("common.roll_up")}
              </StyledTypography>
            </StyledExpandButton>
          </ExpandButtonWrapper>
        </HeaderWrapper>
      </RecipeDetailsWrapper>

      <StyledAccordion
        isMargin={isOpened}
        expanded={isOpened}
        onChange={handleChange}
      >
        <AccordionSummaryPlaceholder expandIcon={<></>} />
        <StyledAccordionDetails>
          {!!rows.length && (
            <RecipeTable
              rows={rows}
              portionRatio={1}
              recipeNutrients={nutrients}
            />
          )}
          <RecipeDescription
            descriptionPl={descriptionPl}
            descriptionEn={descriptionEn}
          />
        </StyledAccordionDetails>
      </StyledAccordion>

      <Divider />
    </>
  );
};
