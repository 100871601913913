import { ReactNode, useEffect, useRef } from "react";

import { useTheme } from "@mui/material";

import { IInCricle } from "@assets/icons";
import { useAppTranslation } from "@hooks";

import {
  ErrorAlert,
  QuestionCard,
  QuestionDescription,
  QuestionNumberText,
  QuestionTitle,
  RequiredInfo,
} from "./Question.styled";

export interface QuestionProps {
  listIndex: number;
  totalQuestions: number;
  required: boolean;
  title: string;
  description: string | null;
  children: ReactNode;
  hasError?: boolean;
  readOnly?: boolean;
  actionComponents?: ReactNode;
}

export const Question = ({
  listIndex,
  totalQuestions,
  required,
  description,
  title,
  children,
  hasError,
  readOnly,
  actionComponents,
}: QuestionProps) => {
  const {
    palette: {
      error: { main },
    },
  } = useTheme();
  const { t } = useAppTranslation();

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!readOnly && hasError) ref.current?.scrollIntoView();
  }, [hasError]);

  return (
    <QuestionCard ref={ref} isError={!readOnly && hasError}>
      <div className="flex justify-between">
        <div className="flex gap-1">
          <QuestionNumberText>
            {t("questionnaires.question")} {listIndex + 1}/{totalQuestions}
          </QuestionNumberText>

          {required && (
            <RequiredInfo>
              ({t("questionnaires.questions.required")})
            </RequiredInfo>
          )}
        </div>

        {actionComponents}
      </div>

      {!readOnly && hasError && (
        <div className="flex gap-1 items-end">
          <IInCricle fill={main} className="m-1" />
          <ErrorAlert>
            {t("questionnaires.questions.answer_required")}
          </ErrorAlert>
        </div>
      )}

      <QuestionTitle>{title}</QuestionTitle>

      {description && <QuestionDescription>{description}</QuestionDescription>}

      {children}
    </QuestionCard>
  );
};
