import { SetStateAction } from "react";

import { Typography } from "@mui/material";
import { round } from "lodash";

import { useAppTranslation } from "@hooks";
import { Nutrient } from "@typeDefinitions";
import { ENERGY_ID } from "@utils";

import {
  CaptionStyled,
  MonitoringDayKcalSectionWrapper,
} from "./MonitoringDayDetails.styled";
import { BoldTypography } from "./MonitoringCalendar.styled";

interface MonitoringDayKcalSectionProps {
  getNutrientValue: (array: Nutrient[], nutrientId: number) => number | null;
  getNutrientTarget: (nutrientId: number) => number | null;
  nutrients: Nutrient[];
}
export const MonitoringDayKcalSection = ({
  getNutrientValue,
  getNutrientTarget,
  nutrients,
}: MonitoringDayKcalSectionProps) => {
  const { t } = useAppTranslation();
  const value = getNutrientValue(nutrients, ENERGY_ID);
  const target = getNutrientTarget(ENERGY_ID);

  return (
    <MonitoringDayKcalSectionWrapper>
      <div className="flex">
        <BoldTypography>{value ? round(value) : "-"}&nbsp;</BoldTypography>
        <Typography>/ {target ? round(target) : "-"} kcal</Typography>
      </div>

      <div className="flex gap-3">
        <CaptionStyled color="text.secondary">
          {t("monitoring.kcal_completed")}
        </CaptionStyled>
        <CaptionStyled color="text.secondary">
          {t("monitoring.kcal_set")}
        </CaptionStyled>
      </div>
    </MonitoringDayKcalSectionWrapper>
  );
};
