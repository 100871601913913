import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const PieceOfCake = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="12" viewBox="0 0 14 12">
      <path
        d="M13.5 5.59883V6H0.5C0.5 5.49219 0.753906 5.01738 1.17539 4.73555L7.47988 0.533398C7.69824 0.388672 7.95215 0.3125 8.21367 0.3125C11.1336 0.3125 13.5 2.67891 13.5 5.59883ZM13.5 6.8125V10.0625C13.5 10.9588 12.7713 11.6875 11.875 11.6875H2.125C1.22871 11.6875 0.5 10.9588 0.5 10.0625V6.8125H13.5Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
