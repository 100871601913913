import { Header } from "@layouts/Landing/components";
import { useQueryClient } from "@tanstack/react-query";
import { ReactNode, useState } from "react";
import { NewMobileAppBanner } from "./NewMobileAppBanner";
import { ThemeProviderWrapperNew } from "themeNew";

interface ErrorBoundaryRouteProps {
  children?: ReactNode;
  defaultOpen?: boolean;
}

export const ErrorBoundaryRoute = ({
  children,
  defaultOpen = false,
}: ErrorBoundaryRouteProps) => {
  const [showBanner, setShowBanner] = useState(defaultOpen);
  const queryClient = useQueryClient();
  const queryOptions = queryClient.defaultQueryOptions();

  queryClient.setDefaultOptions({
    queries: {
      ...queryOptions,
      onError: (e: any) => {
        if (e?.response?.status === 503) setShowBanner(true);
        queryOptions.onError && queryOptions.onError(e);
      },
    },
  });

  if (showBanner)
    return (
      <ThemeProviderWrapperNew>
        <Header />
        <NewMobileAppBanner />
      </ThemeProviderWrapperNew>
    );

  return <>{children}</>;
};
