import { Accordion } from "@mui/material";

import { useFetchProgramDietDetailsQuery } from "@hooks/queries/useFetchProgramDietDetails";
import { Nutrient } from "@typeDefinitions";
import { useFetchProgramDayNutrientsQuery } from "@hooks/queries";
import { Divider } from "@components/Divider";
import { Spinner } from "@components/Spinner";

import { ProgramSummaryTableContent } from "./ProgramSummaryTableContent";
import {
  ProgramSummaryTitle,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "./ProgramDaySummary.styled";

export interface ProgramDaySummaryProps {
  indexOnList: number;
  programId: number;
  programDayId: number;
  dietId: number;
  frequency: number[];
  nutrients?: Nutrient[];
  dietName: string;
  dayName: string;
  useInRowEditButton?: boolean;
}

export const ProgramDaySummary = (props: ProgramDaySummaryProps) => {
  const {
    indexOnList,
    programId,
    programDayId,
    frequency,
    dayName,
    dietId,
    dietName,
    useInRowEditButton,
  } = props;

  const { dietDetails, isLoading } = useFetchProgramDietDetailsQuery(
    programId,
    programDayId,
    dietId,
  );

  const { nutrients } = useFetchProgramDayNutrientsQuery(
    programId.toString(),
    programDayId.toString(),
  );

  return (
    <>
      {indexOnList > 0 && (
        <Divider marginTop={4} marginBottom={4} parentPadding={3.5} />
      )}

      <Accordion>
        <StyledAccordionSummary>
          <ProgramSummaryTitle
            index={indexOnList}
            programId={programId}
            dietId={dietId}
            dietName={dietName}
            dayId={programDayId}
            dayName={dayName}
            frequency={frequency}
            targetNutrients={nutrients?.data.nutrients}
            useEditButton={useInRowEditButton}
          />
        </StyledAccordionSummary>

        <StyledAccordionDetails>
          {isLoading ? (
            <Spinner className="bg-transparent w-full h-96" />
          ) : (
            <ProgramSummaryTableContent meals={dietDetails?.data.meals} />
          )}
        </StyledAccordionDetails>
      </Accordion>
    </>
  );
};
