import { styled } from "@mui/material";
import { Spinner } from "@components/Spinner";

interface BorderedContainerProps {
  disabled?: boolean;
}

export const BorderedContainer = styled("div")<BorderedContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
  background: ${({ disabled, theme }) =>
    disabled ? theme.colors.gray200 : theme.colors.whiteBackground};

  :not(:last-of-type) {
    border-top: 1px ${({ theme }) => theme.colors.border} solid;
  }
`;
