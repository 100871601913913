import { useState } from "react";
import { useAppParams, useAppTranslation } from "@hooks";
import { useProgram } from "@hooks/resources";
import { LoadingButton } from "@mui/lab";
import { Autocomplete, Button, TextField } from "@mui/material";
import _ from "lodash";
import { mapDaysToDiets } from "./utils/mapDaysToDiets";

import { ActionButtonsSection } from "./DayDietSelect.styled";
import { SearchNoResults } from "@views/emptyStates/SearchNoResults";

interface OptionIf {
  id: number;
  label: string;
}

interface DayDietSelectProps {
  onClose: () => void;
  onDietSelect: (id: number) => void;
  isLoading: boolean;
}

export const DayDietSelect = ({
  onClose,
  onDietSelect,
  isLoading,
}: DayDietSelectProps) => {
  const { dayId } = useAppParams();
  const { program } = useProgram();
  const { t, isPolishChosen } = useAppTranslation();

  const diets = program?.days
    ? mapDaysToDiets(program.days, dayId, isPolishChosen)
    : [];

  const [value, setValue] = useState<OptionIf | null>(diets[0]);

  return (
    <div className="grid gap-4">
      <Autocomplete
        noOptionsText={<SearchNoResults />}
        size="small"
        className="w-full"
        value={value}
        onChange={(event: any, newValue: OptionIf | null) => {
          setValue(newValue);
        }}
        options={diets}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={params => (
          <TextField
            {...params}
            value={value?.label}
            label={t("program.diets.add_diet_modal.select_diet")}
          />
        )}
      />

      <ActionButtonsSection>
        <Button variant="contained" color="inherit" onClick={onClose}>
          {t("common.cancel")}
        </Button>

        <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={() => onDietSelect(value?.id ?? 0)}
          disabled={!value}
          autoFocus
        >
          {t("program.diets.add_diet_modal.copy_selected")}
        </LoadingButton>
      </ActionButtonsSection>
    </div>
  );
};
