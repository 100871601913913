import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export async function addFileToVisit(
  scheduleId: number,
  fileId: number,
): Promise<ApiResponse<void>> {
  return fetchData(
    `/dietitian/schedules/${scheduleId}/visit/files/${fileId}`,
    APIMethods.PUT,
  );
}
