import { ReactNode } from "react";
import { TabStyled, TabsStyled } from "./TabSwitch.styled";
import { ArrowLeft, ArrowRight } from "@assets/icons/Arrows";
import { useTheme } from "@mui/material";

export interface TabIf {
  id: string;
  label: ReactNode;
  disabled?: boolean;
}
interface TabSwitchProps<T extends string> {
  value: T | undefined;
  onChange: (value: T) => void;
  tabs: TabIf[];
  disabled?: boolean;
  variant?: "scrollable" | "standard" | "fullWidth" | undefined;
  size?: "small";
}
export const TabSwitch = <T extends string>({
  value,
  onChange,
  tabs,
  disabled,
  variant,
  size,
}: TabSwitchProps<T>) => {
  const {
    palette: { primary },
  } = useTheme();

  return (
    <TabsStyled
      value={value}
      onChange={(e, v) => onChange(v)}
      variant={variant}
      slots={{
        StartScrollButtonIcon: ArrowLeft,
        EndScrollButtonIcon: ArrowRight,
      }}
      slotProps={{
        startScrollButtonIcon: {
          fill: primary.main,
        },
        endScrollButtonIcon: {
          fill: primary.main,
        },
      }}
    >
      {tabs.map(t => (
        <TabStyled
          key={t.id}
          label={t.label}
          value={t.id}
          disabled={t.disabled || disabled}
          size={size}
        />
      ))}
    </TabsStyled>
  );
};
