import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import { useAppTranslation } from "@hooks/useAppTranslation";
import { NutrientDto } from "@client";
import dayjs from "dayjs";
import { useFetchProgramSectionsCategoryQuery } from "@hooks/queries";
import { getDefaultProgramSections } from "@Pdf/utils/programForm";

export interface PatientProgramFormInput {
  namePl: string;
  nameEn?: string;
  workModel: "diets" | "macros";
  showCals: boolean;
  showMacros: boolean;
  showMicros: number[];
  showMeasurements: boolean;
  hasDiets: boolean;
  hasTasks: boolean;
  hasDiary: boolean;
  hasChat: boolean;
  hasEducation: boolean;
  hasMacros: boolean;
  hasMonitoring: boolean;
  hasNewsfeed: boolean;
  hasSubstitutes: boolean;
  hasShoppingList: boolean;
  sectionIds: number[];
  programLanguage: "pl" | "en";
  days: {
    id: number;
    name: string;
    frequency: number[];
    diet: {
      id: number;
      title?: string;
      titleEn?: string;
      mealsCount: number;
      nutrients: NutrientDto[];
    } | null;
  }[];
  startDate?: Date;
  finishDate?: Date | null;
  isIndefinite?: boolean;
}

export function usePatientProgramForm(
  patientProgramValues?: PatientProgramFormInput,
) {
  const { t } = useAppTranslation();
  const { sections } = useFetchProgramSectionsCategoryQuery();

  const resolver = yupResolver(
    yup.object().shape({
      namePl: yup.string().required(t("meals.require_title")),
      finishDate: yup
        .date()
        .min(
          yup.ref("startDate"),
          t("addNutritionalProgram.step1.finish_date_error"),
        )
        .nullable(),
    }),
  );
  const form = useForm<PatientProgramFormInput>({
    defaultValues: patientProgramValues ?? defaultValues,
    resolver,
  });

  useEffect(() => {
    if (sections && !patientProgramValues) {
      // TODO: Those values should not be hardcoded, it should come only from the API!
      form.setValue("sectionIds", getDefaultProgramSections(sections));
    }
  }, [sections]);

  useEffect(() => {
    if (patientProgramValues) form.reset(patientProgramValues);
  }, [patientProgramValues]);

  return form;
}

const defaultValues: PatientProgramFormInput = {
  namePl: "",
  nameEn: "",
  workModel: "diets",
  hasDiary: true,
  hasMonitoring: false,
  hasEducation: false,
  hasTasks: false,
  hasShoppingList: false,
  hasNewsfeed: false,
  hasChat: false,
  showCals: true,
  showMacros: true,
  showMicros: [],
  sectionIds: [],
  showMeasurements: false,
  hasDiets: false,
  hasMacros: false,
  hasSubstitutes: false,
  programLanguage: "pl",
  days: [],
  startDate: dayjs().toDate(),
  finishDate: dayjs().add(1, "month").toDate(),
};
