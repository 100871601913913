import { Alert, IconButton, styled } from "@mui/material";

export const ExtraButtonsWrapper = styled("div")`
  display: flex;
  gap: 0.5rem;
`;

export const ChatButton = styled(IconButton)`
  background: ${({ theme }) => theme.colors.neutral.light[100]};
  border: 1px solid ${({ theme }) => theme.palette.primary.medium};
  color: ${({ theme }) => theme.palette.primary.main};

  &.Mui-disabled {
    background: ${({ theme }) => theme.colors.neutral.light[100]};
    border-color: rgba(0, 0, 0, 0.12);
  }
`;

export const StyledAlert = styled(Alert)`
  font-size: 0.714rem;
  padding: 0.2rem 0.4rem;
  align-items: center;
  background: ${({ theme }) => theme.palette.info.light};
  color: ${({ theme }) => theme.palette.info.main};
  line-height: 1.286rem;
  letter-spacing: 0.4px;

  &.MuiPaper-root {
    border-radius: 0.286rem;
  }

  & .MuiAlert-icon {
    padding: 0;
  }

  & .MuiAlert-message {
    padding: 0;
  }
`;

export const ActionButtons = styled(IconButton)`
  padding: 0.25rem;
  width: 1.5rem;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;
