import { Button, styled } from "@mui/material";
import { FormLabeledCheckboxMui } from "@components/FormCheckboxMui";

export const StyledAddBtn = styled(Button)`
  min-width: 0;
  width: 27px;
  height: 24px;
  padding: 0;
  margin-top: 0.5rem;
`;

export const StyledP = styled("p")`
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.022em;
  font-weight: 600;
  text-align: center;
`;

export const Divider = styled("div")`
  border-bottom: 1px solid ${({ theme }) => theme.colors.cancelButton};
  width: 65%;
  justify-self: center;
`;

export const RegularSpan = styled("span")`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: 1rem;
  height: 150%;
  letter-spacing: -0.022em;
`;
export const BoldSpan = styled("span")`
  font-weight: 600;
  font-size: 1rem;
  height: 150%;
  letter-spacing: -0.022em;
`;

export const FormCheckboxMuiStyled = styled(FormLabeledCheckboxMui)`
  & .MuiTypography-root {
    font-size: 1rem;
  }
`;
