import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const ArrowsUpDown = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="14" height="14" viewBox="0 0 14 14">
      <path
        d="M7.5 4.53125C7.14583 4.82292 6.79167 4.8125 6.4375 4.5L4.75 2.65625V13.25C4.70833 13.7083 4.45833 13.9583 4 14C3.54167 13.9583 3.29167 13.7083 3.25 13.25V2.65625L1.5625 4.5C1.39583 4.66667 1.20833 4.75 1 4.75C0.8125 4.75 0.645833 4.67708 0.5 4.53125C0.1875 4.19792 0.166667 3.84375 0.4375 3.46875L3.4375 0.21875C3.60417 0.0729167 3.79167 0 4 0C4.20833 0 4.39583 0.0729167 4.5625 0.21875L7.5625 3.46875C7.83333 3.84375 7.8125 4.19792 7.5 4.53125ZM13.5 9.46875C13.8125 9.80208 13.8125 10.1562 13.5 10.5312L10.5312 13.7812C10.3646 13.9271 10.1771 14 9.96875 14C9.76042 14 9.57292 13.9271 9.40625 13.7812L6.4375 10.5312C6.16667 10.1562 6.1875 9.80208 6.5 9.46875C6.64583 9.32292 6.8125 9.25 7 9.25C7.20833 9.25 7.39583 9.33333 7.5625 9.5L9.25 11.3438V0.75C9.29167 0.291667 9.54167 0.0416667 10 0C10.4583 0.0416667 10.7083 0.291667 10.75 0.75V11.3438L12.4375 9.5C12.7917 9.1875 13.1458 9.17708 13.5 9.46875Z"
        fill={props.fill ? props.fill : "currentColor"}
      />
    </SvgIcon>
  );
};
