import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MutationOptions } from "../types";
import { fetchClinicNameQueryKey } from "./useFetchClinicNameQuery";
import { patchClinicName } from "@client/settings";

export const usePatchClinicNameMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, string>(name => patchClinicName(name), {
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries([fetchClinicNameQueryKey]);
      options?.onSuccess && options.onSuccess();
    },
  });
};
