import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Copy = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20.323 21.5">
    <g
      id="_20-icon-copy"
      data-name="20-icon-copy"
      transform="translate(-500.25 -360.25)"
    >
      <g
        id="Group_4817"
        data-name="Group 4817"
        transform="translate(501 365.118)"
      >
        <path
          id="Path_1109"
          data-name="Path 1109"
          d="M512.765,383.882h-9.412A2.353,2.353,0,0,1,501,381.529V370.353A2.353,2.353,0,0,1,503.353,368h5.639a.587.587,0,0,1,.416.172l5.538,5.538a.588.588,0,0,1,.172.416v7.4A2.353,2.353,0,0,1,512.765,383.882Z"
          transform="translate(-501 -368)"
          fill="none"
          stroke="#801fff"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_1110"
          data-name="Path 1110"
          d="M515,368.5v3.235a2.353,2.353,0,0,0,2.353,2.353h3.235"
          transform="translate(-506.765 -368.206)"
          fill="none"
          stroke="#801fff"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
      <g
        id="Group_4818"
        data-name="Group 4818"
        transform="translate(505.706 361)"
      >
        <path
          id="Path_1111"
          data-name="Path 1111"
          d="M509,365.118v-1.765A2.353,2.353,0,0,1,511.353,361h5.639a.587.587,0,0,1,.416.172l5.538,5.538a.588.588,0,0,1,.172.416v7.4a2.353,2.353,0,0,1-2.353,2.353h-2.353"
          transform="translate(-509 -361)"
          fill="none"
          stroke="#801fff"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_1112"
          data-name="Path 1112"
          d="M523,361.5v3.235a2.353,2.353,0,0,0,2.353,2.353h3.235"
          transform="translate(-514.765 -361.206)"
          fill="none"
          stroke="#801fff"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </g>
  </SvgIcon>
);
