import { useEffect, useMemo, useState } from "react";
import { FieldValues, useController } from "react-hook-form";

import { compact, uniqBy } from "lodash";

import { useAppTranslation } from "@hooks";

import { StyledAutocomplete } from "./SearchProductsMultipleFormAutocomplete.styled";
import { TextField, useTheme } from "@mui/material";
import { SearchNoResults } from "@views/emptyStates/SearchNoResults";
import { useDebounce } from "react-use";
import { useSearchFoodQuery } from "@hooks/queries/useSearchFoodQuery";
import { SelectOption } from "@components/FormAutocomplete";
import { FormMultipleAutocompleteProps } from "@components/FormMultipleAutocomplete";
import { FetchClientFoodPreferencesResponse } from "@client";
import { Spinner } from "@components/Spinner";

interface SearchProductsMultipleFormAutocompleteProps<T extends FieldValues>
  extends Omit<FormMultipleAutocompleteProps<T>, "options"> {
  productsPreferences: FetchClientFoodPreferencesResponse[];
}

export const SearchProductsMultipleFormAutocomplete = <T extends FieldValues>({
  productsPreferences,
  ...rest
}: SearchProductsMultipleFormAutocompleteProps<T>) => {
  const { control, name, label, placeholder, color } = rest;
  const { colors } = useTheme();
  const [inputValue, setInputValue] = useState("");
  const [newAddedTags, setNewAddedTags] = useState<SelectOption[]>([]);
  const { isPolishChosen } = useAppTranslation();
  const {
    field: { value: fieldValue, onChange, onBlur: controllerOnBlur },
  } = useController({ control, name });
  const selectedTags = fieldValue as string[];

  const { products, submit, isFetching } = useSearchFoodQuery();

  const addedTags: SelectOption[] =
    selectedTags &&
    compact(
      selectedTags.map(tag =>
        products
          ?.map(p => ({
            id: p.id.toString(),
            label: `${isPolishChosen ? p.namePl : p.nameEn}`,
          }))
          .find(option => option.id === tag.toString()),
      ),
    );

  useEffect(() => {
    setNewAddedTags(state =>
      uniqBy([...state, ...addedTags], options => options.id),
    );
  }, [selectedTags]);

  const productsMappedNew = useMemo(
    () => [
      ...(products?.map(p => ({
        id: p.id.toString(),
        label: `${isPolishChosen ? p.namePl : p.nameEn}`,
      })) ?? []),
      ...productsPreferences.map(el => {
        const name = el.food.translations.find(t => t.lang === "pl")?.name;
        const nameEn = el.food.translations.find(t => t.lang === "en")?.name;
        return {
          id: el.food.id.toString(),
          label: `${isPolishChosen ? name : nameEn}`,
        };
      }),
      ...newAddedTags,
    ],
    [products],
  );

  const selectedTagsOptions: SelectOption[] =
    selectedTags &&
    compact(
      selectedTags.map(tag =>
        productsMappedNew.find(option => option.id === tag.toString()),
      ),
    );

  const handleOnChange = (value: string[]) => {
    onChange(value);
  };

  useDebounce(() => submit({ query: inputValue ?? "" }), 500, [inputValue]);

  return (
    <StyledAutocomplete
      disableCloseOnSelect
      noOptionsText={<SearchNoResults />}
      value={selectedTagsOptions}
      options={productsMappedNew}
      multiple
      inputValue={inputValue}
      loading={isFetching}
      loadingText={<Spinner />}
      ChipProps={{
        color: color !== "warning" ? color : undefined,
        sx: {
          backgroundColor:
            color === "warning" ? colors.orange.light : undefined,
          color: color === "warning" ? colors.orange.dark : undefined,
        },
      }}
      onChange={(e, value) => handleOnChange(value.map(tag => tag.id))}
      onBlur={controllerOnBlur}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          size="small"
          InputLabelProps={{ shrink: true }}
          placeholder={placeholder}
          onChange={e => setInputValue(e.target.value)}
          onBlur={controllerOnBlur}
        />
      )}
    />
  );
};
