import { useMutation, useQueryClient } from "@tanstack/react-query";

import { fetchProgramDayNutrientsQueryKey } from "@hooks/queries/useFetchProgramDayNutrientsQuery";
import { programNutrientsOptimisticUpdate } from "@hooks/utils";
import { useAppParams } from "@hooks";
import { MutationOptions } from "./types";
import { fetchProgramQueryKey } from "./useFetchProgramQuery";
import { fetchPatientProgramQueryKey } from "./useFetchPatientProgramQuery";
import {
  fetchDietitianClientProgramScheduleKey,
  fetchDietitianProgramScheduleKey,
} from "./schedule";
import { ApiResponse } from "@typeDefinitions";
import {
  FetchProgramDayNutrientsResponse,
  updateProgramDayNutrients,
  UpdateProgramDayNutrientsRequest,
} from "@client";
import { FetchProgramResponse } from "@client/program";

export function useProgramDayNutrientsMutation(options?: MutationOptions) {
  const queryClient = useQueryClient();
  const { patientId } = useAppParams();

  return useMutation(
    (args: {
      programId: string;
      dayId: string;
      payload: UpdateProgramDayNutrientsRequest;
    }) => updateProgramDayNutrients(args.programId, args.dayId, args.payload),
    {
      ...options,
      onMutate: async variables => {
        await queryClient.cancelQueries([
          fetchProgramDayNutrientsQueryKey,
          variables.programId,
          variables.dayId,
        ]);
        await queryClient.cancelQueries([
          fetchProgramQueryKey,
          parseInt(variables.programId),
        ]);
        await queryClient.cancelQueries([
          fetchPatientProgramQueryKey,
          patientId,
          variables.programId,
        ]);

        queryClient.setQueryData<ApiResponse<FetchProgramDayNutrientsResponse>>(
          [
            fetchProgramDayNutrientsQueryKey,
            variables.programId,
            variables.dayId,
          ],
          {
            data: {
              norm: { id: variables.payload.normId },
              nutrients: variables.payload.nutrients,
            },
          },
        );
        queryClient.setQueryData<ApiResponse<FetchProgramResponse>>(
          [fetchProgramQueryKey, parseInt(variables.programId)],
          old =>
            programNutrientsOptimisticUpdate(
              variables.payload,
              variables.dayId,
              old,
            ),
        );
        queryClient.setQueryData<ApiResponse<FetchProgramResponse>>(
          [fetchPatientProgramQueryKey, patientId, variables.programId],
          old =>
            programNutrientsOptimisticUpdate(
              variables.payload,
              variables.dayId,
              old,
            ),
        );
      },
      onError: (_, variables) => {
        queryClient.invalidateQueries([
          fetchProgramDayNutrientsQueryKey,
          variables.programId,
          variables.dayId,
        ]);
        queryClient.invalidateQueries([
          fetchProgramQueryKey,
          parseInt(variables.programId),
        ]);
        queryClient.invalidateQueries([
          fetchPatientProgramQueryKey,
          patientId,
          variables.programId,
        ]);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([fetchDietitianProgramScheduleKey]);
        queryClient.invalidateQueries([fetchDietitianClientProgramScheduleKey]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );
}
