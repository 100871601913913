import { useRequestPasswordRetrievalMutation } from "@hooks/queries";

import { useState } from "react";
import { RecoveryForm } from "@components/forms/RecoveryForm";
import { ConfirmRecoveryView } from "./components";
import { AlloweatLayout } from "@layouts/AlloweatLayout";

export const Recovery = () => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const { mutate, isLoading } = useRequestPasswordRetrievalMutation();

  return (
    <AlloweatLayout>
      {openConfirm && !isLoading ? (
        <ConfirmRecoveryView />
      ) : (
        <RecoveryForm
          onClick={setOpenConfirm}
          isLoading={isLoading}
          recoveryPassMutation={mutate}
        />
      )}
    </AlloweatLayout>
  );
};
