import {
  CollectionSearchPreviewDto,
  fetchCollectionSearchPreview,
} from "@client/collections";
import { useQuery } from "@tanstack/react-query";
import { QueryOptionsTyped } from "../types";
import { ApiResponse } from "@typeDefinitions";

export const fetchCollectionSearchPreviewQueryKey =
  "fetchCollectionSearchPreviewQueryKey";

export const useFetchCollectionSearchPreviewQuery = (
  collectionId: string,
  options?: QueryOptionsTyped<ApiResponse<CollectionSearchPreviewDto>>,
) => {
  const { data, ...rest } = useQuery({
    queryFn: () => fetchCollectionSearchPreview(collectionId),
    queryKey: [fetchCollectionSearchPreviewQueryKey, collectionId],
    ...options,
  });

  return {
    data: data?.data,
    ...rest,
  };
};
