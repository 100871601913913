import { Nutrient } from "@typeDefinitions";
import { CARBS_ID, ENERGY_ID, FATS_ID, PROTEIN_ID } from "@utils";
import { round } from "lodash";

export interface GetNutrientStatusIf {
  value: number;
  percentage: number;
  color: "info" | "success" | "warning" | "error";
  deviation: number;
}
export const getNutrientStatus = (
  id: number,
  nutrients: Nutrient[] | undefined,
  targetNutrients: Nutrient[] | undefined,
): GetNutrientStatusIf | null => {
  const nutrient = nutrients?.find(n => n.id === id);
  const targetNutrient = targetNutrients?.find(t => t.id === id);

  if (!nutrient || !targetNutrient) return null;

  const value = nutrient.value;
  const targetValue = targetNutrient.value || 1;
  const percentage = (value / targetValue) * 100;
  let color: "info" | "success" | "warning" | "error" = "success";

  const deviation = Math.abs(percentage - 100);
  const isKcal = id === ENERGY_ID;

  if (deviation > 5 && deviation <= 10) color = "warning";
  if (deviation > 10) color = "error";
  if (!isKcal && deviation > 5) color = "info";

  return {
    value: round(value),
    percentage: round(percentage, 1),
    color,
    deviation: 100 - percentage,
  };
};
