import { Box, styled } from "@mui/material";

export const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 50%;
  display: flex;
  justify-content: center;
  :focus-visible {
    border: none;
    outline: none;
  }
  img {
    border-radius: 16px;
  }
` as typeof Box;

export const ShowMoreLabel = styled("span")`
  font-family: Figtree, serif;
  font-weight: 500;
  color: #7448d0;
  font-size: 14px;
  line-height: 24px;
`;

export const Label = styled("span")`
  font-family: Figtree, serif;
  font-weight: 600;
  color: #333333;
  font-size: 14px;
  line-height: 24px;
`;

export const CapImage = styled("div")`
  height: 216px;
  border: 2px solid #fff;
  background: #fcfbfd;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  border-radius: 16px 16px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled("img")`
  height: 220px;
  border-radius: 16px 16px 0 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  object-fit: cover;
`;

export const ImageContainer = styled("div")`
  display: flex;
  flex-direction: column;
`;

export const BoxContent = styled("div")`
  padding: 16px;
  border: 2px solid #fff;
  text-align: center;
  background: #fcfbfd;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  border-radius: 0 0 16px 16px;
  width: 280px;
`;

export const BoxContentLabel = styled("span")`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  font-family: Figtree, serif;
  color: #333333;
`;
