import { useAppTranslation } from "@hooks";
import { CheckRound } from "@assets/icons";
import { TextWrapper } from "./components/TextWrapper.styled";
import { ContainerWrapper } from "@views/public/components";

export const Activation = () => {
  const { t } = useAppTranslation();

  return (
    <ContainerWrapper className="px-40 shadow-paper">
      <CheckRound size="w-14 h-auto" className="mb-7" />

      <TextWrapper className="font-bold">
        {t("auth.activation.success.info")}
      </TextWrapper>
    </ContainerWrapper>
  );
};
