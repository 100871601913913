import { styled } from "@mui/material";
import {
  ActiveClients,
  ButtonStyled,
  CardStyled,
  Description,
  DividerStyled,
  FeatureSection,
  Title,
} from "./common";
import { usePostSubscriptionPurchase } from "@hooks/queries";
import { SubscriptionPackage } from "@utils";

export const EnterpriseCard = () => {
  const { subscriptionPurchaseMutate } = usePostSubscriptionPurchase();
  const ACTIVE_CLIENTS = "Ustalane indywidualnie";
  const FEATURES = [
    { label: "Monetyzacja społeczności i skalowanie biznesu dietetycznego" },
    { label: "Automatyzacje procesów i sprzedaży" },
    { label: "Rozwiązania whitelabel" },
    { label: "Onboarding całych zespołów dietetyków" },
    { label: "Wsparcie operacyjne działalności" },
  ];

  const handleClick = () => {
    subscriptionPurchaseMutate({
      paymentType: "month",
      subscriptionType: SubscriptionPackage.ENTERPRISE,
    });
    const url = "https://alloweat.com/kontakt/";
    window.open(url, "_blank");
  };

  return (
    <CardStyled>
      <div className="flex flex-col gap-[24px]">
        <div className="h-[180px] flex flex-col gap-[24px]">
          <div className="flex flex-col gap-[16px]">
            <Title>Enterprise</Title>
            <Description>
              Dla biznesów dietetycznych wymagających indywidualnie dopasowanych
              rozwiązań.
            </Description>
          </div>

          <div className="flex flex-col gap-[8px]">
            <CustomPackageText>Indywidualna wycena pakietu</CustomPackageText>
          </div>
        </div>

        <GradientButton variant="contained" onClick={handleClick}>
          Napisz do nas
        </GradientButton>

        <DividerStyled />

        <ActiveClients value={ACTIVE_CLIENTS} />

        <DividerStyled />

        <FeatureSection features={FEATURES} title="W czym możemy Ci pomóc?" />
      </div>
    </CardStyled>
  );
};

const CustomPackageText = styled("span")`
  font-family: Figtree;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #2d1d51;
`;

const GradientButton = styled(ButtonStyled)`
  background: linear-gradient(91.08deg, #485ad0 1.74%, #c977f0 107.23%);
`;
