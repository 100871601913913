import { Button, Card, Divider, styled } from "@mui/material";

export const CardStyled = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 6px;
  border-radius: 16px;
  border: 2px solid #fff;
  background: radial-gradient(
    100% 1757.1% at 0% 0%,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: 0px 0px 10px 0px #0000000d;
  backdrop-filter: blur(24px);
  width: 430px;
`;

export const Title = styled("span")`
  font-family: Figtree;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const Description = styled("span")`
  font-family: Figtree;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #483a68;
`;

export const DividerStyled = styled(Divider)`
  border-color: #f1f1f1;
`;

export const ButtonStyled = styled(Button)`
  font-family: Figtree;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  height: unset;
  padding: 8px;
`;
