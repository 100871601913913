import { useCallback } from "react";

import { requestFoodNutrients } from "@client";
import { Nutrient } from "@typeDefinitions";
import { ListElement } from "./ListElement";
import { MicronutrientPreview } from "@components/MicronutrientsModal";

interface FoodListElementProps {
  id: number;
  description: string;
  nutrients: Nutrient[];
  grams: number;
}

export const FoodListElement = ({
  id,
  description,
  nutrients,
  grams,
}: FoodListElementProps) => {
  const query = useCallback(() => requestFoodNutrients(id), [id]);

  return (
    <ListElement
      key={id}
      title={description}
      macros={nutrients}
      prefix={`${grams} g`}
      micros={<MicronutrientPreview query={query} factor={grams} />}
    />
  );
};
