import { NutrientCategory } from "@typeDefinitions";

import { MicronutrientsCategory } from "./MicronutrientsCategory";

export const MicronutrientsList = ({ categories }: MicronutrientsListProps) => {
  return (
    <div className="grid grid-cols-2 gap-4">
      {categories
        .filter(category => category.id !== 2 && category.id !== 1)
        .map(category => (
          <MicronutrientsCategory category={category} key={category.id} />
        ))}
    </div>
  );
};

interface MicronutrientsListProps {
  categories: NutrientCategory[];
}
