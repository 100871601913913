import { styled } from "@mui/material";

const ProgramHeadDays = styled("span")`
  line-height: 18px;
  font-size: 10px;
  letter-spacing: 0.4px;
  font-weight: normal;
  font-family: Figtree, serif;
  color: #727272;
`;
export default ProgramHeadDays;
