import { round } from "lodash";

import { SuggestedEnergyDto } from "@client";

export const scaleMacros = (
  values: SuggestedEnergyDto,
  newKcal: number,
): SuggestedEnergyDto => {
  if (!values.kcal) return values;
  return {
    kcal: round(newKcal),
    protein: values.protein
      ? round((values.protein / values.kcal) * newKcal)
      : null,
    fat: values.fat ? round((values.fat / values.kcal) * newKcal) : null,
    carb: values.carb ? round((values.carb / values.kcal) * newKcal) : null,
  };
};
