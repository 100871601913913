import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { UpdateClientMeasurementFormInput, useAppTranslation } from "@hooks";
import dayjs from "dayjs";
import { useEffect } from "react";
import { CreateClientMeasurementRequest, FetchClientResponse } from "@client";

export const useCreateClientMeasurementForm = (
  values?: UpdateClientMeasurementFormInput,
) => {
  const { t } = useAppTranslation();

  const resolver = yup.object().shape({
    date: yup
      .date()
      .typeError(t("common.required_field"))
      .required(t("common.required_field")),
  });

  const form = useForm<UpdateClientMeasurementFormInput>({
    defaultValues: values,
    resolver: yupResolver(resolver),
    shouldFocusError: false,
  });

  useEffect(() => {
    if (values) form.reset(values);
  }, [values]);

  return form;
};

export const mapCreateClientMeasurementRequest = (
  data: UpdateClientMeasurementFormInput,
): CreateClientMeasurementRequest => {
  return {
    date: dayjs(data.date).format("YYYY-MM-DD"),
    growth: data.growth ?? null,
    weight: data.weight ?? null,
    bodyFatLevel: data.bodyFatLevel ?? null,
    arm: data.arm ?? null,
    tightBiceps: data.tightBiceps ?? null,
    waist: data.waist ?? null,
    hip: data.hip ?? null,
    thigh: data.thigh ?? null,
    calf: data.calf ?? null,
  };
};

export const mapCreateClientMeasurementForm = (
  data: FetchClientResponse | undefined,
): UpdateClientMeasurementFormInput | undefined => {
  if (!data) return;
  return {
    date: dayjs().toDate(),
    growth: null,
    weight: null,
    bodyFatLevel: null,
    arm: null,
    tightBiceps: null,
    waist: null,
    hip: null,
    thigh: null,
    calf: null,
    targetWeight: data.profile.targetWeight?.toString() ?? null,
    goal: data.profile.targets[0]?.id ?? null,
  };
};
