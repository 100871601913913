import { CardTitle } from "../../Meal.styled";
import { MealProduct, ProductRow } from "./ProductRow";
import { useAppTranslation } from "@hooks";

interface ProductsCardProps {
  products: MealProduct[];
}

export const ProductsCard = ({ products }: ProductsCardProps) => {
  const { t } = useAppTranslation();

  return (
    <div className="grid gap-2">
      <CardTitle variant="h4">
        {t("common.products")} ({products.length})
      </CardTitle>
      {products.map(product => (
        <ProductRow {...product} key={product.id} />
      ))}
    </div>
  );
};
