import { QueryOptionsTyped } from "@hooks/queries";
import { useQuery } from "@tanstack/react-query";
import {
  fetchPatientBodyMeasurement,
  FetchPatientBodyMeasurementParams,
  FetchPatientBodyMeasurementResponse,
} from "@client/patient/bodyMeasurement/fetchPatientBodyMeasurement";
import { patientBodyMeasurementKeys } from "@hooks/queries/client/bodyMeasurement/patientBodyMeasurementKey";

const useFetchPatientBodyMeasurement = (
  params: FetchPatientBodyMeasurementParams,
  options?: QueryOptionsTyped<FetchPatientBodyMeasurementResponse>,
) => {
  return useQuery({
    queryKey: patientBodyMeasurementKeys.id(
      params.patientId,
      params.bodyMeasurementId,
    ),
    queryFn: () => fetchPatientBodyMeasurement(params),
    ...options,
  });
};

export default useFetchPatientBodyMeasurement;
