import programs_grid from "@locales/patient/programs_grid";
import program_preview from "@locales/patient/program_preview";
import programs_choice from "@locales/patient/programs_choice";
import health_and_goal from "@locales/patient/health_and_goal";
import pl from "@locales/patient/pl.json";
import en from "@locales/patient/en.json";

export default {
  pl: {
    programs_grid: programs_grid.pl,
    program_preview: program_preview.pl,
    programs_choice: programs_choice.pl,
    health_and_goal: health_and_goal.pl,
    ...pl,
  },
  en: {
    programs_grid: programs_grid.en,
    program_preview: program_preview.en,
    programs_choice: programs_choice.en,
    health_and_goal: health_and_goal.en,
    ...en,
  },
};
