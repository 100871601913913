import styled from "@emotion/styled";

export const MacroCellWrapper = styled.div<{ bold?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.5rem;
  padding: 0.5rem;
  width: 25%;
  font-weight: ${({ bold }) => bold && "bold"};
`;
