import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const updateProgramPdfSettings = async (
  programId: string,
  payload: UpdateProgramPdfSettingsRequest,
) => {
  return await fetchData(
    `/dietitian/programs/${programId}/pdf-settings`,
    APIMethods.PUT,
    payload,
  );
};

export interface UpdateProgramPdfSettingsRequest {
  layoutId: number | null;
  showClinicName: boolean;
  showClinicFacility: boolean;
  showClinicPhoneNumber: boolean;
  showClinicEmail: boolean;
  showScheduleDate: boolean;
  showScheduleDayName: boolean;
  showMealHour: boolean;
  frequencyShoppingList: number;
  scheduleStartedAt: string | null;
  scheduleEndedAt: string | null;
}
