import { fetchFoodGroupsTree } from "@client/dictionaries";
import { useQuery } from "@tanstack/react-query";

export function useFoodGroupTreeFetchQuery() {
  return useQuery(["food-group-tree"], () => fetchFoodGroupsTree(), {
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
}
