import { useEffect } from "react";
import { useWatch } from "react-hook-form";
import type { Control, UseFormReturn } from "react-hook-form";
import { AddEventModalInput } from "./useScheduleVisitForm";
import { FetchClinicProductDto } from "@client";
import { useFetchClientQuery } from "@hooks/queries";
import { MEETING_TYPE } from "@utils";
import { useFetchClinicDietitianQuery } from "@hooks/queries/settings";
import { useUserRoles } from "@hooks";
import dayjs from "dayjs";

export const useScheduleModalSideEffects = (
  form: UseFormReturn<AddEventModalInput>,
  products: FetchClinicProductDto[] | undefined,
) => {
  const { control, setValue } = form;
  const { isClinicAdmin } = useUserRoles();

  const currentProductId = useWatch({
    control,
    name: "clinicProductId",
  });

  const currentCurrency = useWatch({
    control,
    name: "currency",
  });

  const clientId = useWatch({
    control,
    name: "patientId",
  });

  const eventType = useWatch({
    control,
    name: "eventType",
  });

  const dietitianId = useWatch({
    control,
    name: "dietitianId",
  });

  const startDate = useWatch({ control, name: "startDate" });

  const { dietitian } = useFetchClinicDietitianQuery(dietitianId ?? 0, {
    enabled: !!dietitianId && isClinicAdmin,
  });

  const { client } = useFetchClientQuery(clientId ?? 0, {
    enabled: !!clientId,
  });

  useEffect(() => {
    if (currentProductId) {
      const product = products?.find(
        product => product.id.toString() === currentProductId,
      );

      const plnPrice = product?.prices.find(price => price.currency === "PLN");
      plnPrice && setValue("currency", plnPrice.currency);
    } else {
      setValue("currency", null);
    }
  }, [currentProductId]);

  useEffect(() => {
    if (currentProductId && currentCurrency) {
      const product = products?.find(
        product => product.id.toString() === currentProductId,
      );

      const selectedPrice = product?.prices.find(
        price => price.currency === currentCurrency,
      );

      selectedPrice && setValue("price", selectedPrice.amount);
    } else {
      setValue("price", null);
    }
  }, [currentCurrency, currentProductId]);

  useEffect(() => {
    if (
      client?.firstName &&
      client?.lastName &&
      eventType === MEETING_TYPE.CONSULTATION
    ) {
      setValue("eventName", client.firstName + " " + client.lastName);
    }
  }, [client?.firstName, client?.lastName, eventType]);

  useEffect(() => {
    setValue("clinicFacilityId", dietitian?.facility?.id ?? null);
  }, [dietitian?.id]);

  useEffect(() => {
    const endDate = dayjs(startDate).add(1, "hour");
    setValue("finishDate", endDate.toDate());
  }, [startDate]);
};
