import { useAppTranslation } from "@hooks";
import { Controller, useFormContext } from "react-hook-form";
import { FormPops } from "@views/dietician/ClientHealth/BodyMeasurementTab/SettingsSection/Dialog/SettingSectionForm";
import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";

const RequiredPhotosField = () => {
  const { t } = useAppTranslation();
  const controller = useFormContext<FormPops>();

  return (
    <Controller
      control={controller.control}
      name="requiredPhotos"
      render={({ field }) => (
        <FormControlLabel
          label={t(
            "patient.health_and_goal.body_measurement_tab.section_settings_modal.required_image_label",
          )}
          sx={{ margin: "0px" }}
          control={
            <Checkbox
              checked={field.value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                field.onChange(event.target.checked);
              }}
            />
          }
        />
      )}
    />
  );
};

export default RequiredPhotosField;
