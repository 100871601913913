import { Typography, styled } from "@mui/material";
import { Link } from "react-router-dom";

export const StyledLink = styled(Link)`
  & svg {
    margin-right: 0.5rem;
  }

  & span {
    &:hover {
      border-bottom: 2px solid ${({ theme }) => theme.palette.text.disabled};
    }
  }
`;

export const StyledTypography = styled(Typography)`
  display: flex;
  align-items: center;
`;
