import { FormProvider } from "react-hook-form";

import { TextField } from "@mui/material";

import { QuestionType, SystemQuestionDto } from "@client/surveys";
import { useUpdateSurveySubgroupQuestionMutation } from "@hooks/queries/surveys";

import { Question } from "../Question/Question";
import { QuestionEditWatch } from "../QuestionEditWatch";
import {
  SystemQuestionConfigFormInputs,
  mapSystemQuestionConfigForm,
  mapSystemQuestionConfigRequest,
  useSystemQuestionConfigForm,
} from "../forms/useSystemQuestionConfigForm";
import { useAppTranslation } from "@hooks";
import { LangDto } from "@client";

interface SystemQuestionUserWeightProps {
  listIndex: number;
  totalQuestions: number;
  question: SystemQuestionDto;
  onTypeChange: (currentType: QuestionType, newType: QuestionType) => void;
  questionnaireLang?: LangDto;
}

export const SystemQuestionUserWeight = ({
  listIndex,
  totalQuestions,
  question,
  onTypeChange,
  questionnaireLang,
}: SystemQuestionUserWeightProps) => {
  const { t } = useAppTranslation();
  const form = useSystemQuestionConfigForm(
    mapSystemQuestionConfigForm({
      ...question,
      title: t("questionnaires.questions.system_user_weight.question", {
        lng: questionnaireLang,
      }),
    }),
  );

  const { mutate } = useUpdateSurveySubgroupQuestionMutation();

  const onSubmit = (data: SystemQuestionConfigFormInputs) => {
    mutate({
      id: question.id.toString(),
      payload: mapSystemQuestionConfigRequest(data, question),
    });
  };

  return (
    <FormProvider {...form}>
      <Question
        id={question.id}
        listIndex={listIndex}
        totalQuestions={totalQuestions}
        onTypeChange={onTypeChange}
        questionType={question.type}
        systemQuestion
        infoText={t("questionnaires.questions.system_user_weight.info")}
      >
        <TextField
          size="small"
          disabled
          placeholder={t(
            "questionnaires.questions.system_user_weight.placeholder",
          )}
        />
      </Question>

      <QuestionEditWatch isLoading={false} onSubmit={onSubmit} />
    </FormProvider>
  );
};
