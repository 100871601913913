import dayjs from "dayjs";
import { range, round } from "lodash";

export const handleDayTileColor = (
  kcal: number | undefined,
  targetKcal: number | undefined,
) => {
  if (!kcal) return BACKGROUND_COLORS.gray;
  if (!targetKcal) return BACKGROUND_COLORS.purple;
  const percentage = (kcal / targetKcal) * 100;

  if (percentage > 110 || percentage < 90) return BACKGROUND_COLORS.red;
  else if (percentage > 105 || percentage < 95) return BACKGROUND_COLORS.orange;
  else return BACKGROUND_COLORS.green;
};

export const BACKGROUND_COLORS = {
  gray: "#FAF9F9",
  orange: "#FEF5EB",
  green: "#E2F3E6",
  red: "#FDEFF2",
  purple: "#F5F1FE",
};

export const handleDayBarColor = (
  kcal: number | undefined,
  targetKcal: number | undefined,
) => {
  if (!kcal)
    return {
      left: PERCENTAGE_BAR_COLORS.gray,
      right: PERCENTAGE_BAR_COLORS.gray,
    };
  if (!targetKcal)
    return {
      left: PERCENTAGE_BAR_COLORS.purple,
      right: PERCENTAGE_BAR_COLORS.purple,
    };
  const percentage = (kcal / targetKcal) * 100;

  if (percentage > 110)
    return {
      left: PERCENTAGE_BAR_COLORS.darkRed,
      right: PERCENTAGE_BAR_COLORS.red,
    };
  else if (percentage > 105)
    return {
      left: PERCENTAGE_BAR_COLORS.darkOrange,
      right: PERCENTAGE_BAR_COLORS.orange,
    };
  else if (percentage > 100)
    return {
      left: PERCENTAGE_BAR_COLORS.darkGreen,
      right: PERCENTAGE_BAR_COLORS.green,
    };
  else if (percentage > 95)
    return {
      left: PERCENTAGE_BAR_COLORS.green,
      right: PERCENTAGE_BAR_COLORS.lightGreen,
    };
  else if (percentage > 90)
    return {
      left: PERCENTAGE_BAR_COLORS.orange,
      right: PERCENTAGE_BAR_COLORS.lightOrange,
    };
  else
    return {
      left: PERCENTAGE_BAR_COLORS.red,
      right: PERCENTAGE_BAR_COLORS.lightRed,
    };
};

export const PERCENTAGE_BAR_COLORS = {
  gray: "#D5D5D5",
  lightGreen: "#B5E4C1",
  green: "#46BB63",
  darkGreen: "#2E7D32",
  lightOrange: "#FDD4A5",
  orange: "#FB931F",
  darkOrange: "#EC7100",
  lightRed: "#FABEBD",
  red: "#F25C5A",
  darkRed: "#C62828",
  purple: "#801FFF",
};

export const handleDayBarWidth = (percentage: number | undefined) => {
  if (!percentage || round(percentage) % 100 === 0 || round(percentage) > 199)
    return { left: 100, right: 0 };
  const percentageModulo = percentage % 100;
  return { left: percentageModulo, right: 100 - percentageModulo };
};

export const mapEmpyCalendarDays = (month: dayjs.Dayjs) => {
  const daysInPrevMonth = month.subtract(1, "month").daysInMonth();

  const firstDayInMonth =
    month.startOf("month").day() === 0 ? 7 : month.startOf("month").day();
  const prevMonthEmptyDays = range(
    daysInPrevMonth - firstDayInMonth + 1,
    daysInPrevMonth,
  );

  const currMonth = month.daysInMonth();
  const currMonthEmptyDays = range(currMonth);
  const nextMonthFill = 42 - (Number(prevMonthEmptyDays.length) + currMonth);
  const nextMonthEmptyDays = range(nextMonthFill);

  return {
    prevMonthEmptyDays,
    currMonthEmptyDays,
    nextMonthEmptyDays,
  };
};
