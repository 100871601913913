import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Trash = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <g
      id="_20-icon-delete"
      data-name="20-icon-delete"
      transform="translate(1 -0.001)"
    >
      <rect
        id="Rectangle_1157"
        data-name="Rectangle 1157"
        width="20"
        height="20"
        transform="translate(-1 0.25)"
        fill="#fff"
        opacity="0"
      />
      <path
        id="Stroke_1"
        data-name="Stroke 1"
        d="M14.191,0s-.543,6.735-.858,9.572a2.238,2.238,0,0,1-2.358,2.174c-2.609.047-5.221.05-7.829-.005A2.214,2.214,0,0,1,.857,9.579C.54,6.717,0,0,0,0"
        transform="translate(2.134 7.468)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Stroke_3"
        data-name="Stroke 3"
        d="M16.958.5H0"
        transform="translate(0.75 3.74)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Stroke_5"
        data-name="Stroke 5"
        d="M10.423,3.489A1.648,1.648,0,0,1,8.808,2.165L8.565.949A1.28,1.28,0,0,0,7.328,0H3.1A1.28,1.28,0,0,0,1.858.949L1.615,2.165A1.648,1.648,0,0,1,0,3.489"
        transform="translate(4.018 0.751)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <line
        id="Line_481"
        data-name="Line 481"
        y2="7"
        transform="translate(6.5 8.75)"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <line
        id="Line_482"
        data-name="Line 482"
        y2="7"
        transform="translate(11.5 8.75)"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </g>
  </SvgIcon>
);
