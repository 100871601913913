import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
} from "@mui/material";

export const CustomAccordion = styled(Accordion)`
  display: flex;
  flex-direction: column;
  box-shadow: none;
  border: none;
  padding: 0;
  :before {
    content: none;
  }
  &.Mui-expanded {
    margin: 0;
  }
` as typeof Accordion;

export const CustomAccordionSummary = styled(AccordionSummary)`
  order: 2;
  padding: 0;
  color: #7448d0;
  line-height: 24px;
  font-size: 14px;
  font-weight: 500;
  font-family: Figtree, serif;
  margin: 0;
  min-height: unset;
  .MuiAccordionSummary-content {
    padding: 0;
    margin: 0;
    &.Mui-expanded {
      margin: 0;
    }
  }
  &.Mui-expanded {
    min-height: unset;
  }
` as typeof AccordionSummary;

export const CustomAccordionDetails = styled(AccordionDetails)`
  padding: 0;
  margin-bottom: 16px;
` as typeof AccordionDetails;

export const ShowButton = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;
