import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Fullscreen = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path
        d="M1.14286 0C0.510714 0 0 0.510714 0 1.14286V4.57143C0 5.20357 0.510714 5.71429 1.14286 5.71429C1.775 5.71429 2.28571 5.20357 2.28571 4.57143V2.28571H4.57143C5.20357 2.28571 5.71429 1.775 5.71429 1.14286C5.71429 0.510714 5.20357 0 4.57143 0H1.14286ZM2.28571 11.4286C2.28571 10.7964 1.775 10.2857 1.14286 10.2857C0.510714 10.2857 0 10.7964 0 11.4286V14.8571C0 15.4893 0.510714 16 1.14286 16H4.57143C5.20357 16 5.71429 15.4893 5.71429 14.8571C5.71429 14.225 5.20357 13.7143 4.57143 13.7143H2.28571V11.4286ZM11.4286 0C10.7964 0 10.2857 0.510714 10.2857 1.14286C10.2857 1.775 10.7964 2.28571 11.4286 2.28571H13.7143V4.57143C13.7143 5.20357 14.225 5.71429 14.8571 5.71429C15.4893 5.71429 16 5.20357 16 4.57143V1.14286C16 0.510714 15.4893 0 14.8571 0H11.4286ZM16 11.4286C16 10.7964 15.4893 10.2857 14.8571 10.2857C14.225 10.2857 13.7143 10.7964 13.7143 11.4286V13.7143H11.4286C10.7964 13.7143 10.2857 14.225 10.2857 14.8571C10.2857 15.4893 10.7964 16 11.4286 16H14.8571C15.4893 16 16 15.4893 16 14.8571V11.4286Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
