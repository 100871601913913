import { useEffect, useMemo, useState } from "react";

import { useQuery } from "@tanstack/react-query";

import {
  ProgramSearchResourceDto,
  searchProgramsNew,
  searchProgramsNewDefault,
  SearchProgramsPaginationParams,
  SearchProgramsPaginationResponse,
} from "@client/searchProgramsNew";
import { ListTabs, usePagination } from "@hooks";
import { ApiResponse } from "@typeDefinitions";
import {
  parseNumber,
  parseNutrientsList,
  parseString,
  parseStringArray,
  parseStringArrayNew,
  parseTagsList,
} from "@utils/filters";
import { omit } from "lodash";
import { useSearchParams } from "react-router-dom";
import { QueryOptionsTyped } from "./types";
import {
  DEFAULT_PROGRAMS_MACROS_RANGE,
  ProgramsFiltersFormInput,
  convertSearchParamsToObj,
} from "@components/ProgramsFilters";

const parseTabName = (input: ListTabs | undefined, params: URLSearchParams) => {
  if (input === undefined || input === ListTabs.ALL) return;
  if (input === ListTabs.LATEST) params.append(ListTabs.LATEST, "1");
  if (input === ListTabs.FAVORITE) params.append(ListTabs.FAVORITE, "1");
  if (input === ListTabs.OWN) params.append("owner", ListTabs.OWN);
  if (input === ListTabs.UNVERIFIED) params.append("reviewed", "0");
};

export const parseProgramsFilters = (filters?: ProgramsFiltersFormInput) => {
  const output = new URLSearchParams();

  parseTagsList(filters?.tags, output);
  parseNutrientsList(filters?.nutrients, output);
  parseTabName(filters?.tabs, output);
  parseString(filters?.query || undefined, "query", output);
  parseNumber(filters?.page, "page", output);
  parseNumber(filters?.perPage, "perPage", output);
  parseStringArray(filters?.typeDays, "typeDays", output);
  parseStringArrayNew(filters?.numberOfDietMeals, "numberOfDietMeals", output);
  parseString(filters?.days, "days", output);
  parseString(filters?.authorId, "authorId", output);

  return output;
};

export const searchProgramsQueryKeyNew = "searchProgramsQueryKeyNew";

interface CustomOptions
  extends QueryOptionsTyped<ApiResponse<ProgramSearchResourceDto[]>> {
  disableParams?: boolean;
}
export function useSearchProgramsQueryNew(options?: CustomOptions) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [committedInputs, setCommittedInputs] =
    useState<ProgramsFiltersFormInput>(
      omitDefaultNutrients(convertSearchParamsToObj(searchParams)),
    );
  const params = useMemo(
    () => parseProgramsFilters(committedInputs),
    [committedInputs],
  );

  useEffect(() => {
    if (!options?.disableParams) setSearchParams(params, { replace: true });
  }, [params, options?.disableParams]);

  const { handlePaginationClick, paginatedParams } = usePagination(params);
  const { data, ...rest } = useQuery(
    [searchProgramsQueryKeyNew, paginatedParams.toString()],
    () => searchProgramsNew(paginatedParams),
    options,
  );

  return {
    programs: data?.data,
    links: data?.meta?.links,
    submit: setCommittedInputs,
    ...rest,
    handlePaginationClick,
    meta: data?.meta,
  };
}

export function useSearchProgramsDefaultQueryNew(
  params: SearchProgramsPaginationParams,
  options?: QueryOptionsTyped<SearchProgramsPaginationResponse>,
) {
  return useQuery({
    queryKey: [searchProgramsQueryKeyNew, "default", params],
    queryFn: () => searchProgramsNewDefault(params),
    ...options,
  });
}

const omitDefaultNutrients = (data: ProgramsFiltersFormInput) => {
  const nutrientsWithNonDefaultValues = Object.entries(
    data.nutrients || {},
  ).reduce((acc, [key, value]) => {
    const defaultValue = DEFAULT_PROGRAMS_MACROS_RANGE[key];
    if (
      !defaultValue ||
      defaultValue[0] !== value[0] ||
      defaultValue[1] !== value[1]
    )
      acc[key] = value;

    return acc;
  }, {} as { [id: string]: [number, number] });

  return {
    ...omit(data, "nutrients"),
    nutrients: nutrientsWithNonDefaultValues,
  };
};
