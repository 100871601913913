import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Cooking = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24.689 24.658">
    <g
      id="_24-icon-cook"
      data-name="24-icon-cook"
      transform="translate(-1523.065 -1258.592)"
    >
      <rect
        id="Rectangle_1176"
        data-name="Rectangle 1176"
        width="24"
        height="24"
        transform="translate(1523.065 1258.592)"
        fill="#fff"
        opacity="0"
      />
      <path
        id="Path_1005"
        data-name="Path 1005"
        d="M1542.5,1272.5v8.5a1.5,1.5,0,0,1-1.5,1.5h-11a1.5,1.5,0,0,1-1.5-1.5v-8.5"
        fill="none"
        stroke="#9342fe"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_1006"
        data-name="Path 1006"
        d="M1537.065,1266.092a6.478,6.478,0,0,0-1.956-4.645c-.033-.031-.068-.06-.1-.092-.128-.119-.26-.236-.4-.345-.053-.043-.11-.082-.166-.124-.122-.092-.247-.181-.376-.263-.066-.043-.134-.083-.2-.123-.125-.074-.252-.144-.382-.209-.074-.038-.149-.074-.225-.109-.13-.059-.262-.114-.4-.164-.079-.03-.158-.061-.239-.088-.139-.046-.279-.084-.422-.121-.081-.022-.16-.045-.242-.063-.154-.034-.313-.058-.471-.081-.072-.011-.143-.025-.217-.033a6.349,6.349,0,0,0-.708-.04,6.5,6.5,0,0,0-2,12.685"
        fill="none"
        stroke="#9342fe"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_1007"
        data-name="Path 1007"
        d="M1535.634,1262.03a6.407,6.407,0,0,1,4.87-2.22,6.5,6.5,0,0,1,2,12.69"
        fill="none"
        stroke="#9342fe"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <line
        id="Line_430"
        data-name="Line 430"
        x2="13"
        transform="translate(1528.564 1277.592)"
        fill="none"
        stroke="#994dfe"
        strokeWidth="1.5"
      />
    </g>
  </SvgIcon>
);
