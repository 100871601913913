import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Backdrop } from "@mui/material";

import { useAppTranslation, useUserRoles } from "@hooks";
import { useFetchOauthGoogleHandleCallbackQuery } from "@hooks/queries/oauth";
import { DIETICIAN_SETTINGS, INTEGRATIONS } from "@routes";

import { CardStyled, TitleStyled } from "./CallbackPage.styled";
import { Integrations } from "./Integrations";
import { toast } from "react-toastify";

export const CallbackPage = () => {
  const { t } = useAppTranslation();
  const { isClinicAdmin } = useUserRoles();
  const navigate = useNavigate();

  const onSettled = () => {
    isClinicAdmin ? navigate(DIETICIAN_SETTINGS) : navigate(INTEGRATIONS);
  };
  const params = new URLSearchParams(window.location.search);
  const accessGranted = params.has(CODE_PARAM);
  const accessDenied = params.get(ERROR_PARAM) === "access_denied";

  useFetchOauthGoogleHandleCallbackQuery(params, {
    onSuccess: onSettled,
    onError: onSettled,
    enabled: accessGranted,
  });

  useEffect(() => {
    if (accessDenied) {
      toast.error(t("integrations.calendar.calendar_sync_error"));
      onSettled();
    }
  }, [accessDenied]);

  if (accessGranted)
    return (
      <>
        <Backdrop open sx={{ zIndex: 999 }}>
          <CardStyled>
            <TitleStyled>
              {t("integrations.google_calendar.loading_text")}
            </TitleStyled>
            <img
              src="/static/googleCalendar/googleLoading.gif"
              width={150}
              height={150}
            />
          </CardStyled>
        </Backdrop>
        <Integrations />
      </>
    );

  return <Integrations />;
};

const CODE_PARAM = "code";
const ERROR_PARAM = "error";
