import AccordionMui from "@mui/material/Accordion";
import { styled } from "@mui/material";

const Accordion = styled(AccordionMui)`
  &.MuiAccordion-root {
    box-shadow: unset;
    border: 1px solid #f1f1f1;
    border-radius: 8px;
    background: #ffffff;
    :before {
      content: unset;
    }
  }
  &.Mui-expanded {
    margin: unset;
  }

  .MuiAccordionSummary-root {
    padding: 12px;
    min-height: unset;
    .MuiAccordionSummary-content {
      margin: 0;
    }
    &.Mui-expanded {
      min-height: unset;
    }
  }

  .MuiAccordionDetails-root {
    padding: 0 12px 12px;
  }
` as typeof AccordionMui;

export default Accordion;
