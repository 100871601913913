import { ConfirmModalWrapper } from "@components/ConfirmModalWrapper";
import { ConfirmationWrapper } from "@components/ConfirmationWrapper";
import { useAppTranslation } from "@hooks";
import {
  useDeleteClinicDietitianMutation,
  usePostDietitianActivation,
} from "@hooks/queries/settings";
import { ActionButtons } from "@layouts/DieticianSettingsLayout/components/ActionButtons";
import { LoadingButton } from "@mui/lab";
import { ThemeProviderWrapperNew } from "themeNew";

interface DietitianActionsProps {
  id: string | number;
  openEditModal: () => void;
  setDietitianId: (id?: number) => void;
  isAdmin: boolean;
  isActive: boolean;
}

export const DietitianActions = ({
  id,
  openEditModal,
  setDietitianId,
  isAdmin,
  isActive,
}: DietitianActionsProps) => {
  const { t } = useAppTranslation();
  const { mutate: deleteDietitian, isLoading: isLoadingDeleteDietitian } =
    useDeleteClinicDietitianMutation();
  const { mutate: activateDietitian, isLoading: isLoadingActivation } =
    usePostDietitianActivation();

  const handleActivateDietitian = () => {
    activateDietitian({
      id: id.toString(),
      payload: { active: true },
    });
  };

  const deactivateDietitian = () => {
    activateDietitian({
      id: id.toString(),
      payload: { active: false },
    });
  };

  const handleRemove = () => {
    deleteDietitian(Number(id));
  };

  if (!isActive)
    return (
      <>
        <ThemeProviderWrapperNew>
          <ConfirmModalWrapper
            onConfirm={handleActivateDietitian}
            confirmationText={t("dietitians_settings.confirm_activate_tooltip")}
            submitText={t("common.yes")}
          >
            <LoadingButton loading={isLoadingActivation}>
              {t("clients.actions.activate")}
            </LoadingButton>
          </ConfirmModalWrapper>
          <ConfirmationWrapper
            onConfirm={handleRemove}
            confirmationText={t("dietitians_settings.confirm_delete_tooltip")}
            submitText={t("common.yes")}
          >
            <LoadingButton loading={isLoadingDeleteDietitian}>
              {t("common.delete")}
            </LoadingButton>
          </ConfirmationWrapper>
        </ThemeProviderWrapperNew>
      </>
    );

  return (
    <ActionButtons
      id={Number(id)}
      setEditId={setDietitianId}
      openEditModal={openEditModal}
      deleteMutation={deleteDietitian}
      disableDelete={isAdmin}
      deactivateDietitian={deactivateDietitian}
    />
  );
};
