import { useQuery } from "@tanstack/react-query";

import { fetchSectionCategories } from "@client";

export function useFetchSectionCategoriesQuery() {
  const { data: sectionCategories, ...rest } = useQuery(
    ["sectionCategories"],
    fetchSectionCategories,
  );
  return { sectionCategories, ...rest };
}
