import classnames from "classnames";

export type SelectOptionProps = {
  /** option value */
  value?: string | number;
  /** option text */
  children?: string;
  /** option selected */
  selected?: boolean;
  /** menu option click action */
  onClick?: (value?: string | number) => void;
  /** will remove hover & focus colors and prevent selection */
  disabled?: boolean;
  /** will center the values */
  textCenter?: boolean;
};

/**
 * option button shown in select component
 */
export const SelectOption = (props: SelectOptionProps) => {
  const {
    value,
    selected = false,
    children,
    onClick,
    disabled,
    textCenter,
  } = props;

  return (
    <button
      className={classnames({
        "w-full py-2.5 px-2.5 outline-none font-roman text-sm appearance-none":
          true,
        "bg-gray-400 opacity-50": disabled,
        "bg-gray-400": selected,
        "focus:bg-gray-300 hover:bg-gray-300": !disabled && !selected,
        "text-center": textCenter,
        "text-left": !textCenter,
      })}
      type="button"
      onClick={!disabled && onClick ? () => onClick(value) : undefined}
      tabIndex={0}
      value={value}
    >
      {children}
    </button>
  );
};
