import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import { logFetchValidateError } from "@utils/validate";
import * as yup from "yup";
import {
  PatientMeasurementResource,
  patientMeasurementSchema,
} from "@client/resources/PatientMeasurementResource";

export type FetchPatientLastBodyMeasurementParams = {
  patientId: number;
};

export type FetchPatientLastBodyMeasurementResponse =
  ApiResponse<PatientMeasurementResource | null>;

export const fetchPatientLastBodyMeasurement = async (
  params: FetchPatientLastBodyMeasurementParams,
): Promise<FetchPatientLastBodyMeasurementResponse> => {
  const response = await fetchData(
    "/dietitian/patients/{patient_id}/last-body-measurements".replace(
      "{patient_id}",
      params.patientId.toString(),
    ),
    APIMethods.GET,
  );

  return await schema
    .validate(response)
    .catch(
      logFetchValidateError(
        "/dietitian/patients/{patient_id}/last-body-measurements",
      ),
    );
};

const schema = yup.object({
  data: patientMeasurementSchema.defined().nullable(),
});
