import { Funnel } from "@assets/icons";
import {
  TagAutocompleteFilterWrapper,
  FilterType,
  FoodTreeSelectFilterWrapper,
  FunnelButton,
  MultiSelectFilterWrapper,
  OnlyPolishFilterWrapper,
  SliderFilterWrapper,
  AutocompleteFilterWrapper,
} from "@components/Filters";
import { FiltersDrawer } from "@components/Filters/FiltersDrawer";
import { ListTabs, useAppTranslation, useListTabs, useUserRoles } from "@hooks";
import { useTagsNew } from "@hooks/useTagsNew";
import { Chip } from "@mui/material";
import { decimalInput1ThreeDigits } from "@utils/inputs";
import {
  CARBS_ID,
  ENERGY_ID,
  FATS_ID,
  MicrosFilters,
  PROTEIN_ID,
} from "@utils/macros";
import { TagCategoryType } from "@utils/tagsNew";
import { MouseEventHandler, useMemo, useState } from "react";
import { useWatch } from "react-hook-form";
import { IconButtonStyled } from "./ProductsFilters.styled";
import { defaultFilters } from "./productsFiltersUtils";
import {
  DEFAULT_MACROS_RANGE,
  ProductsFiltersFormInput,
} from "./useProductsFiltersForm";
import { useProductShops } from "@hooks/useProductShops";

interface ProductsFiltersProps {
  total?: number;
  unshiftTabs?: boolean;
  onSubmit?: (values: ProductsFiltersFormInput) => void;
  resetValues?: ProductsFiltersFormInput;
}

export const ProductsFiltersNew = ({
  total,
  unshiftTabs,
  onSubmit,
  resetValues,
}: ProductsFiltersProps) => {
  const { getTagCategoryName, getTagCategoryOptions } = useTagsNew();
  const { options: shopOptions } = useProductShops();
  const [open, setOpen] = useState(false);
  const { t } = useAppTranslation();
  const { unshiftListTabFilter } = useListTabs();
  const { isAdminClinicMember } = useUserRoles();
  const tags = useWatch<ProductsFiltersFormInput, "tags">({ name: "tags" });
  const shops = useWatch<ProductsFiltersFormInput, "shops">({ name: "shops" });
  const nutrients = useWatch<ProductsFiltersFormInput, "nutrients">({
    name: "nutrients",
  });
  const tabs = useWatch<ProductsFiltersFormInput, "tabs">({
    name: "tabs",
  });
  const onlyPl = useWatch<ProductsFiltersFormInput, "onlyPl">({
    name: "onlyPl",
  });
  const foodGroup = useWatch<ProductsFiltersFormInput, "foodGroup">({
    name: "foodGroup",
  });

  const countPositiveValues = useMemo(
    () =>
      Object.entries(nutrients || {})?.reduce((acc, [key, value]) => {
        if (!value) return 0;
        const [min, max] = value;
        const defaultValue = DEFAULT_MACROS_RANGE[key];

        const isDefaultValue =
          min === defaultValue[0] && max === defaultValue[1];
        const isEmptyValue = max === 0;

        if (!isDefaultValue && !isEmptyValue) acc += 1;

        return acc;
      }, 0),
    [nutrients],
  );

  const tabsTag = unshiftTabs ? Number(tabs !== ListTabs.ALL) : 0;

  const allTags =
    (tags?.length ?? 0) +
    (shops?.length ?? 0) +
    countPositiveValues +
    Number(onlyPl) +
    Number(foodGroup?.length) +
    tabsTag;

  const categoriesFilters: (
    | FoodTreeSelectFilterWrapper<ProductsFiltersFormInput>
    | OnlyPolishFilterWrapper<ProductsFiltersFormInput>
    | AutocompleteFilterWrapper<ProductsFiltersFormInput>
  )[] = [
    {
      type: FilterType.FoodTreeSelect,
      props: {
        name: "foodGroup",
        placeholder: t("product.choose_group"),
        label: t("product.product_category"),
      },
    },
    {
      type: FilterType.OnlyPolish,
      props: {
        label: t("products.brands"),
        name: "onlyPl",
      },
    },
    {
      type: FilterType.Autocomplete,
      props: {
        name: "shops",
        label: t("product.shops"),
        options: shopOptions,
        placeholder: t("product.shops"),
      },
    },
  ];
  const nutrientsFilters: SliderFilterWrapper<ProductsFiltersFormInput>[] = [
    {
      type: FilterType.Slider,
      props: {
        nutrientId: ENERGY_ID.toString(),
        label: t("common.energy_efficiency"),
        maxValue: 1000,
        step: 50,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: PROTEIN_ID.toString(),
        maxValue: 60,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: FATS_ID.toString(),
        maxValue: 50,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: CARBS_ID.toString(),
        maxValue: 100,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.SATURATED_FAT.toString(),
        maxValue: 5,
        step: 1,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.CHOLESTEROL.toString(),
        maxValue: 100,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.DIETARY_FIBER.toString(),
        maxValue: 20,
        step: 1,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.SODIUM.toString(),
        maxValue: 1000,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.POTASSIUM.toString(),
        maxValue: 1000,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.CALCIUM.toString(),
        maxValue: 500,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.PHOSPHORUS.toString(),
        maxValue: 500,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.MAGNESIUM.toString(),
        maxValue: 200,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.IRON.toString(),
        maxValue: 10,
        step: 1,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.VITAMIN_A.toString(),
        maxValue: 200,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.TOTAL_FOLATE.toString(),
        maxValue: 150,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.VITAMIN_C.toString(),
        maxValue: 100,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.KOBALAMINA.toString(),
        maxValue: 1,
        step: 0.1,
        decimalInput: decimalInput1ThreeDigits,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.GLYCEMIC_LOAD.toString(),
        maxValue: 30,
        step: 1,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.CARBOHYDRATE_EXCHANGERS.toString(),
        maxValue: 10,
        step: 1,
      },
    },
  ];

  const otherFilters: (
    | TagAutocompleteFilterWrapper
    | MultiSelectFilterWrapper<ProductsFiltersFormInput>
  )[] = [
    {
      type: FilterType.MultiSelect,
      props: {
        label: getTagCategoryName(TagCategoryType.ELIMINATIONS) ?? "",
        options: getTagCategoryOptions(TagCategoryType.ELIMINATIONS) ?? [],
      },
    },
    {
      type: FilterType.TagAutocomplete,
      props: {
        label: getTagCategoryName(TagCategoryType.ALLERGENS) ?? "",
        options: getTagCategoryOptions(TagCategoryType.ALLERGENS) ?? [],
        placeholder: t("recipes.allergens_placeholder"),
      },
    },
  ];

  return (
    <>
      <FunnelButton allTags={allTags} open={open} setOpen={setOpen} />
      <FiltersDrawer
        tabs={[
          t("product.categories_brands"),
          t("recipes.filters.nutrients"),
          t("recipes.filters.other"),
        ]}
        filtersLists={[
          unshiftTabs
            ? unshiftListTabFilter(
                categoriesFilters,
                t("common.recipes"),
                isAdminClinicMember,
              )
            : categoriesFilters,
          nutrientsFilters,
          otherFilters,
        ]}
        onClose={() => setOpen(false)}
        open={open}
        total={total}
        resetValues={
          resetValues ?? {
            tabs: tabs,
            ...defaultFilters,
          }
        }
        onSubmit={onSubmit}
      />
    </>
  );
};
