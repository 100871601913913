import { DialogContent, DialogTitle, styled } from "@mui/material";

export const ScheduleVisitModalWrapper = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0;
  max-height: 850px;
`;

export const ScheduleVisitModalTitle = styled(DialogTitle)`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.dark["800"]};
  padding: 0;
`;

export const ScheduleVisitModalLabel = styled("p")`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.neutral.medium["800"]};
  padding-bottom: 4px;
`;

export const ScheduleVisitModalFormWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 22px;
  padding: 0 16px;
`;

export const ScheduleVisitModalDivider = styled("div")`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral.medium["400"]};
`;
