import { ProgramRowType } from "@views/dietician/PatientProgramsNew/Grid/RowType";
import { useMemo } from "react";
import dayjs from "dayjs";
import { Stack } from "@mui/material";
import {
  PatientProgramsGridStyled,
  SecondText,
} from "@views/dietician/PatientProgramsNew/PatientProgramsGrid.styled";
import useProgramDate from "@hooks/program/useProgramDate";
import { useAppTranslation } from "@hooks";

const DATE_FORMAT = "DD.MM.YYYY";

const PeriodColumn = ({ program }: ProgramRowType) => {
  const { t } = useAppTranslation();
  const { start, end, isActive, isPlanned } = useProgramDate({
    startDate: program.startDate,
    endDate: program.endDate,
  });

  const period = useMemo(() => {
    const s = start.format(DATE_FORMAT);
    const e = end?.format(DATE_FORMAT);

    return `${s} - ${e ?? "..."}`;
  }, [start, end]);

  const desc = useMemo(() => {
    const today = dayjs().startOf("day");

    if (isActive && end === null) {
      return (
        <SecondText>
          ({t("patient.programs_grid.row.duration.indefinitely")})
        </SecondText>
      );
    }

    if (isActive) {
      const duration = Math.round(Math.abs(today.diff(end, "day", true)));
      return (
        <SecondText>
          (
          {t("patient.programs_grid.row.duration.left.c", {
            count: duration,
            variable: duration,
          })}
          )
        </SecondText>
      );
    }

    if (isPlanned) {
      const duration = Math.round(Math.abs(today.diff(start, "day", true)));
      return (
        <SecondText>
          (
          {t("patient.programs_grid.row.duration.start.c", {
            count: duration,
            variable: duration,
          })}
          )
        </SecondText>
      );
    }

    return null;
  }, [start, end, isActive, isPlanned, t]);

  return (
    <Stack>
      <PatientProgramsGridStyled>{period}</PatientProgramsGridStyled>
      {desc}
    </Stack>
  );
};

export default PeriodColumn;
