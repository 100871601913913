import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const updateProgram = async (
  programId: number,
  payload: UpdateProgramRequest,
) =>
  await fetchData(`/dietitian/programs/${programId}`, APIMethods.PUT, payload);

export interface UpdateProgramRequest {
  name: string;
  nameEn?: string;
  hasDiets: boolean;
  hasMacros: boolean;
  hasDiary: boolean;
  hasMonitoring: boolean;
  hasEducation: boolean;
  hasTasks: boolean;
  hasShoppingList: boolean;
  hasChat: boolean;
  hasNewsfeed: boolean;
  showCals: boolean;
  showMacros: boolean;
  reviewed: boolean;
  showNutrients: number[];
  sections: number[];
  durationDays: number | null;
  langId: "pl" | "en";
}
