import { useContext, useMemo } from "react";
import { FormProvider } from "react-hook-form";

import { useTheme } from "@emotion/react";
import { styled, useMediaQuery } from "@mui/material";

import {
  mapMealRecipeForm,
  useRecipeEditForm,
} from "@components/RecipeForm/useRecipeEditForm";
import { RecipeProductsContextProvider } from "@context";
import { RecipeTableForm } from "@components/RecipeEditForm/components";
import { ChartCard } from "@components/RecipeEditForm/components/ChartCard";
import { RecipeMethodDescription } from "@components/RecipeMethodDescription";
import { RecipeDto } from "@client/meals";
import { LangDto, MediaExtendedDto } from "@client";
import { useAppTranslation } from "@hooks";
import { SelectOption } from "@components/FormAutocomplete";

import { MealRecipeFormWatch } from "./watches/MealRecipeFormWatch";
import { LangContext } from "../context/LangContext";
import {
  ChartWrapper,
  DescriptionChartWrapper,
  NameFormTextField,
  NameSectionWrapper,
  PortionsFormTextField,
} from "./SelectedRecipeEdit.styled";
import { ThemeProviderWrapperNew } from "themeNew";
import { OuterLabel } from "@components/OuterLabel";
import { FormTextFieldCharCounter } from "@components/FormTextField";

interface SelectedRecipeEditFormProps {
  recipe: RecipeDto;
  wrapperId: number;
  selectedServings: number;
  selectedServingOption: SelectOption;
  media: MediaExtendedDto | null;
}
export const SelectedRecipeEditForm = ({
  recipe,
  wrapperId,
  selectedServings,
  selectedServingOption,
  media,
}: SelectedRecipeEditFormProps) => {
  const { t } = useAppTranslation();
  const { breakpoints } = useTheme();
  const defaultFormValues = useMemo(() => mapMealRecipeForm(recipe), [recipe]);
  const form = useRecipeEditForm(defaultFormValues);
  const { isPolish } = useContext(LangContext);
  const isSmall = useMediaQuery(`${breakpoints.down("small")}`);

  const recipeMemo = useMemo(
    () => ({
      ...recipe,
      foodRecipe: recipe.foodRecipe.map(r => ({
        ...r,
        grams: r.grams ?? 0,
      })),
    }),
    [recipe],
  );

  return (
    <FormProvider {...form}>
      <NameSectionWrapper>
        <NameFormTextField
          name={isPolish ? "namePl" : "nameEn"}
          key={isPolish ? "namePl" : "nameEn"}
          label={t("meal.recipe_name")}
        />
        <PortionsFormTextField
          name="numOfPortions"
          label={t("meal.servings_in_recipe")}
        />
      </NameSectionWrapper>
      <MealRecipeFormWatch
        wrapperId={wrapperId.toString()}
        selectedServings={selectedServings}
        media={media}
      />
      <RecipeProductsContextProvider recipe={recipeMemo}>
        <RecipeTableForm inline recipeNutrients={recipe.nutrients} />
        <DescriptionChartWrapper isSmall={isSmall}>
          <div className="flex-1 flex flex-col gap-[16px]">
            <RecipeMethodDescription isPl={isPolish} classNames="w-full" />
            <ThemeProviderWrapperNew>
              <OuterLabel label={t("recipe.comment")}>
                <FormTextFieldCharCounter
                  key={isPolish ? "comment" : "commentEn"}
                  name={isPolish ? "comment" : "commentEn"}
                  maxLength={600}
                  multiline
                  rows={5}
                  placeholder={t("recipe.comment")}
                />
              </OuterLabel>
            </ThemeProviderWrapperNew>
          </div>
          <ChartWrapper isSmall={isSmall}>
            <ChartCard
              nutrients={recipe.nutrients}
              totalServings={recipe.servings}
              setWidth={false}
              pie
              withDivider={false}
              controlledServing={selectedServingOption}
              preview
            />
          </ChartWrapper>
        </DescriptionChartWrapper>
      </RecipeProductsContextProvider>
    </FormProvider>
  );
};
