import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { deleteSuggestedTask } from "@client/suggestedTasks/deleteSuggestedTask";
import { ApiEndpoints } from "@typeDefinitions";
import { useAppTranslation } from "@hooks/useAppTranslation";

export const useDeleteSuggestedTaskMutation = () => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation<
    AxiosResponse,
    AxiosError,
    { id: string }
  >(data => deleteSuggestedTask(data.id), {
    onSuccess: () => {
      const queryKeys = [
        [ApiEndpoints.SuggestedTasks],
        [ApiEndpoints.SuggestedTasksList],
        [ApiEndpoints.SuggestedTasksCount],
      ];

      queryKeys.forEach(queryKey => {
        queryClient.invalidateQueries({ queryKey });
      });
    },
  });

  const handleDeleteSuggestedTask = async (id: string) => {
    try {
      await mutateAsync({ id });
    } catch {
      toast.error(t("tasks.deleteFailure"));
    }
  };

  return { handleDeleteSuggestedTask };
};
