import { Dispatch, SetStateAction } from "react";

import {
  Label,
  FilterButton,
  OptionsWrapper,
} from "./MultiSelectFilter.styled";
import { OptionIf } from "../FiltersBuilder";
import {
  FieldValue,
  FieldValues,
  Path,
  useController,
  useFormContext,
} from "react-hook-form";

export interface MultiSelectFilterProps<T extends FieldValues> {
  label: string;
  options: OptionIf[];
  name?: Path<T>;
}

export const MultiSelectFilter = <T extends FieldValues>({
  label,
  options,
  name,
}: MultiSelectFilterProps<T>) => {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ control, name: name ?? "tags" });
  const selected = value as string[] | undefined;

  const toggleSelect = (id: string) => {
    if (selected?.includes(id)) return onChange(selected.filter(s => s !== id));
    return onChange([...(selected ?? []), id]);
  };

  return (
    <div className="grid gap-[4px]">
      <Label>{label}</Label>

      <OptionsWrapper>
        {options.map(o => (
          <FilterButton
            key={o.id}
            variant="outlined"
            selected={!!o.id && selected?.includes(o.id)}
            onClick={() => toggleSelect(o.id ?? "")}
          >
            {o.label}
          </FilterButton>
        ))}
      </OptionsWrapper>
    </div>
  );
};
