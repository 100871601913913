import { Close } from "@assets/icons";
import { IconButton } from "@mui/material";
import { useProgramSearchPreviewDrawer } from "@components/ProgramSearchPreview/Context/ProgramSearchPreviewDrawerContext";

const CloseDrawerActionButton = () => {
  const { onClose } = useProgramSearchPreviewDrawer();

  return (
    <IconButton onClick={() => onClose()}>
      <Close size="" fill="#727272" style={{ width: "32px", height: "32px" }} />
    </IconButton>
  );
};

export default CloseDrawerActionButton;
