import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "../types";
import { fetchPatientVisitsQueryKey } from "./useFetchPatientVisitsQuery";
import { fetchVisitNoteQueryKey } from "./useFetchVisitNoteQuery";
import { NotePayload, updatePatientNote } from "@client/visits";

export const useUpdatePatientNoteMutation = (
  patientId: number,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: NotePayload) => updatePatientNote(patientId, data),
    {
      ...options,
      onSuccess() {
        queryClient.invalidateQueries([fetchPatientVisitsQueryKey, patientId]);
        queryClient.invalidateQueries([fetchVisitNoteQueryKey, patientId]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};
