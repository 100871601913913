import { Calculator } from "@assets/icons/DesignSystem";
import { SectionTitleWrapper } from "@components/PreviewDrawer/common/SectionTitleWrapper";
import { useAppTranslation, useNutrients } from "@hooks";
import { useTheme } from "@mui/material";
import { Nutrient } from "@typeDefinitions";
import { buildNutrientsDict } from "@utils";
import {
  CARBS_ID,
  ENERGY_ID,
  ENERGY_PER_GRAM_OF_CARB,
  ENERGY_PER_GRAM_OF_FAT,
  ENERGY_PER_GRAM_OF_PROTEIN,
  FATS_ID,
  PROTEIN_ID,
} from "@utils/macros";
import { round } from "lodash";
import { useMemo } from "react";
import { MacroLabel } from "../MacroLabel/MacroLabel";
import { BarPart, ChartWrapper } from "./MacrosChart.styled";

interface MacrosChartProps {
  nutrients: Nutrient[];
  perKg?: boolean;
}

export const MacrosChart = ({ nutrients, perKg = false }: MacrosChartProps) => {
  const {
    colors: { survey },
    palette: { warning, success },
  } = useTheme();
  const { t } = useAppTranslation();
  const { nutrientDict } = useNutrients();
  const valuesDict = useMemo(() => buildNutrientsDict(nutrients), [nutrients]);
  const percentages = useMemo(
    () => calculateAndRoundMacroPercentages(valuesDict),
    [valuesDict],
  );

  const BAR_COLORS: Record<number, string> = {
    [PROTEIN_ID]: survey.yellow.dark,
    [FATS_ID]: warning.main,
    [CARBS_ID]: success.main,
  };

  return (
    <SectionTitleWrapper
      Icon={Calculator}
      title={
        <div className="flex justify-between">
          <span>{t("common.energy_efficiency")}</span>
          <span>
            {round(
              valuesDict.get(ENERGY_ID) ?? 0,
              nutrientDict.get(ENERGY_ID)?.roundPrecision,
            )}{" "}
            kcal {perKg && "/ 100 g"}
          </span>
        </div>
      }
    >
      <ChartWrapper>
        {Object.keys(percentages).map(key => (
          <BarPart
            key={key}
            width={percentages[Number(key)]}
            background={BAR_COLORS[Number(key)]}
          />
        ))}
      </ChartWrapper>

      <div className="flex justify-between">
        {Object.keys(percentages).map(key => (
          <MacroLabel
            key={key}
            color={BAR_COLORS[Number(key)]}
            id={Number(key)}
            percentage={percentages[Number(key)]}
            value={round(
              valuesDict.get(Number(key)) ?? 0,
              nutrientDict.get(Number(key))?.roundPrecision,
            )}
          />
        ))}
      </div>
    </SectionTitleWrapper>
  );
};

const calculateAndRoundMacroPercentages = (
  micros: Map<number, number>,
): Record<number, number> => {
  const protein = micros.get(PROTEIN_ID) ?? 0;
  const fat = micros.get(FATS_ID) ?? 0;
  const carb = micros.get(CARBS_ID) ?? 0;

  const proteinCalories = protein * ENERGY_PER_GRAM_OF_PROTEIN;
  const fatCalories = fat * ENERGY_PER_GRAM_OF_FAT;
  const carbCalories = carb * ENERGY_PER_GRAM_OF_CARB;

  const totalCalories = proteinCalories + fatCalories + carbCalories;

  const proteinPercentage = round((proteinCalories / totalCalories) * 100) || 0;
  const fatPercentage = round((fatCalories / totalCalories) * 100) || 0;
  const carbPercentage =
    protein || fat || carb ? 100 - proteinPercentage - fatPercentage : 0;

  return {
    [PROTEIN_ID]: proteinPercentage,
    [FATS_ID]: fatPercentage,
    [CARBS_ID]: carbPercentage,
  };
};
