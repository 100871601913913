import { Dispatch, SetStateAction } from "react";
import { Popover } from "@mui/material";
import {
  ContentWrapper,
  MenuButton,
  Title,
} from "./NotificationsTitleActions.styled";
import { useAppTranslation } from "@hooks";
import { usePostAllNotificationAsRead } from "@hooks/queries";

interface ClientsListActionModalProps {
  anchorEl?: HTMLElement | null;
  open: boolean;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  handleClose: Dispatch<SetStateAction<boolean>>;
}

export const NotificationsTitleActions = ({
  open,
  anchorEl,
  setAnchorEl,
}: ClientsListActionModalProps) => {
  const { t } = useAppTranslation();
  const { mutate, isLoading } = usePostAllNotificationAsRead();

  const onMarkAllNotificationsAsRead = () => {
    mutate();
    setAnchorEl(null);
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <ContentWrapper>
        <div className="grid">
          <Title variant="body2"> {t("notifications.title")}</Title>

          <MenuButton
            onClick={onMarkAllNotificationsAsRead}
            disabled={isLoading}
          >
            {t("notifications.mark_all_as_read")}
          </MenuButton>
        </div>
      </ContentWrapper>
    </Popover>
  );
};
