import { ReactNode, useState } from "react";

import { ArrowDown } from "@assets/icons/Arrows";

import {
  ArrowDownStyled,
  ButtonStyled,
  CollapseStyled,
} from "./ShowMoreNutrients.styled";
import { useAppTranslation } from "@hooks";

interface ShowMoreNutrients {
  children: ReactNode;
}
export const ShowMoreNutrients = ({ children }: ShowMoreNutrients) => {
  const { t } = useAppTranslation();
  const [open, setOpen] = useState(false);

  return (
    <>
      <ButtonStyled
        onClick={() => setOpen(!open)}
        endIcon={<ArrowDownStyled rotate={open} />}
      >
        {t("recipes.filters.micronutrients")}
      </ButtonStyled>
      <CollapseStyled in={open}>{children}</CollapseStyled>
    </>
  );
};
