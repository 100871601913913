import styled from "@emotion/styled";

//flex flex-col font-roman mt-2
export const ProgramListElementWrapper = styled.div`
  display: grid;
  margin-top: 0.5rem;
`;

//flex gap-3 pt-2"
export const ProgramDietDetailsWrapper = styled.div`
  display: flex;
  gap: 0.75rem;
  padding-top: 0.5rem;
`;

//flex justify-between items-center
export const ProgramListElementSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

//flex items-center gap-3 text-sm
export const MicronutrientsSection = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  gap: 0.75rem;
`;
