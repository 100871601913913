import { KcalMonitoring } from "@assets/icons/Monitoring";
import SummaryCard, {
  SummaryCardProps,
} from "@views/dietician/PatientMonitoring2/components/SummaryCard/SummaryCard";
import { useAppTranslation } from "@hooks";

const KcalSummaryCardOf = ({
  actualValue,
  targetValue,
}: Pick<SummaryCardProps, "actualValue" | "targetValue">) => {
  const { t } = useAppTranslation();
  return (
    <SummaryCard
      icon={<KcalMonitoring fill="#7448D0" size="" />}
      title={t("monitoring2.summary_cards.kcal_title")}
      actualValue={Math.round(actualValue)}
      targetValue={targetValue ? Math.round(targetValue) : targetValue}
    />
  );
};

export default KcalSummaryCardOf;
