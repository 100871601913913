import { IconButton, styled } from "@mui/material";

export const MacrosListWrapper = styled("div")`
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallMax}px) {
    display: flex;
    flex-direction: column;
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.medium}px) {
    display: flex;
  }

  gap: 24px;
`;

export const ProteinWrapper = styled("div")`
  flex: 1;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallMax}px) {
    display: grid;
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.medium}px) {
    display: flex;
    align-items: flex-start;
    max-width: 360px;
  }
  gap: 8px;
`;

export const TabSwitchWrapper = styled("div")`
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.medium}px) {
    margin-top: 24px;
  }
`;

export const IconButtonStyled = styled(IconButton)`
  padding: 4px;
`;
