import { Button, styled } from "@mui/material";

export const PageTitleWrapper = styled("div")`
  display: flex;
  gap: 4px;
  align-items: center;
`;
export const TitleStyled = styled("span")`
  display: flex;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;
