import { useEffect, useRef } from "react";
import { QuestionMarkInSquare } from "@assets/icons";
import { Trans } from "react-i18next";
import { useLocalStorage, useSetState } from "react-use";
import ReactJoyride, {
  CallBackProps,
  STATUS,
  StoreHelpers,
} from "react-joyride";
import { useTourTriggerContext } from "@context";
import { PROGRAM_SCHEDULE_TOUR } from "@views/dietician/ProgramSchedule";
import { TourTooltip } from "components/TourTooltip";

import { Img } from "./ProgramScheduleTour.styled";
import screenshot from "@assets/img/scheduleScreenshot.png";

export const ProgramScheduleTour = () => {
  const transComponents = {
    paragraph: <p className="pb-2 last:pb-0" />,
    bold: <strong />,
    questionMark: <QuestionMarkInSquare size="w-5 h-5" />,
    list: <li className="block pb-2 last:pb-0" />,
    element: <ul className="list-disc list-item	ml-8" />,
  };

  const [{ run, steps }, setState] = useSetState({
    run: false,
    steps: [
      {
        disableBeacon: true,
        target: "#show-tour",
        title: <Trans i18nKey="program.tours.start_tour.title" />,
        content: (
          <Trans
            i18nKey="program.tours.start_tour.content"
            components={transComponents}
          />
        ),
        floaterProps: {
          disableAnimation: true,
        },
      },
      {
        target: ".step-one",
        title: <Trans i18nKey="program.tours.step_two.title" />,
        content: (
          <Trans
            i18nKey="program.tours.step_two.content"
            components={transComponents}
          />
        ),
        placement: "bottom" as const,
      },
      {
        target: ".step-two",
        title: <Trans i18nKey="program.tours.step_three.title" />,
        content: (
          <Trans
            i18nKey="program.tours.step_three.content"
            components={transComponents}
          />
        ),
        placement: "bottom" as const,
      },
      {
        target: ".step-three",
        title: <Trans i18nKey="program.tours.step_four.title" />,
        content: (
          <Trans
            i18nKey="program.tours.step_four.content"
            components={transComponents}
          />
        ),
      },
      {
        target: ".step-four",
        title: <Trans i18nKey="program.tours.step_five.title" />,
        content: (
          <Trans
            i18nKey="program.tours.step_five.content"
            components={transComponents}
          />
        ),
        placement: "right" as const,
      },
      {
        target: ".step-five",
        title: <Trans i18nKey="program.tours.step_six.title" />,
        content: (
          <Trans
            i18nKey="program.tours.step_six.content"
            components={transComponents}
          />
        ),
      },
      {
        target: ".step-six",
        title: <Trans i18nKey="program.tours.step_seven.title" />,
        content: (
          <Trans
            i18nKey="program.tours.step_seven.content"
            components={transComponents}
          />
        ),
      },
      {
        target: ".step-seven",
        title: <Trans i18nKey="program.tours.step_eight.title" />,
        content: (
          <>
            <Trans
              i18nKey="program.tours.step_eight.content"
              components={transComponents}
            />
            <Img src={screenshot} />
          </>
        ),
      },
    ],
  });

  const [firstTime, setFirstTime] = useLocalStorage(
    PROGRAM_SCHEDULE_TOUR,
    true,
  );

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    const options: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (firstTime && STATUS.FINISHED === status) {
      setFirstTime(false);
    }

    if (options.includes(status)) {
      setState({ run: false });
    }
  };

  const setHelpers = (storeHelpers: StoreHelpers) => {
    helpers.current = storeHelpers;
  };

  const helpers = useRef<StoreHelpers>();

  const { register } = useTourTriggerContext();

  useEffect(() => register(() => setState({ run: true })), []);

  useEffect(() => {
    if (firstTime) {
      setState({ run: true });
    }
  }, [firstTime]);

  return (
    <>
      <ReactJoyride
        scrollOffset={100}
        continuous
        steps={steps}
        callback={handleJoyrideCallback}
        getHelpers={setHelpers}
        run={run}
        hideCloseButton
        scrollToFirstStep
        disableCloseOnEsc={firstTime}
        disableOverlayClose={firstTime}
        showProgress
        spotlightPadding={8}
        styles={{
          options: {
            zIndex: 2147483645,
          },
        }}
        tooltipComponent={TourTooltip}
      />
    </>
  );
};
