import { Accordion, AccordionSummary, styled } from "@mui/material";

export const AccordionSummaryStyled = styled(AccordionSummary)`
  padding: 10px 16px;

  & .Mui-expanded {
    margin: 0;
  }

  &.MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-gutters {
    min-height: unset;
  }
  & .MuiAccordionSummary-content.Mui-expanded {
    min-height: unset;
    margin: 0;
  }
  & .MuiAccordionSummary-content {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.neutral.dark[800]};
    margin: 0;
  }

  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
  }
`;

export const AccordionStyled = styled(Accordion)`
  background: ${({ theme }) => theme.palette.primary.light};
  border-radius: 8px;
  box-shadow: none;
  &::before {
    display: none;
  }
  &.MuiAccordion-root.Mui-expanded {
    margin: 0;
  }
`;
