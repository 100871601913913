import { TabList, TabPanel } from "@mui/lab";
import {
  Button,
  DialogActions,
  FormControlLabel,
  IconButton,
  Tab,
  styled,
} from "@mui/material";

export const EditClientModalWrapper = styled("div")`
  display: grid;
  gap: 1rem;
  padding: 1rem;
  width: 32rem;
`;

export const TitleWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled("h3")`
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.25rem;
`;

export const CloseButton = styled(IconButton)`
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;

export const StyledDialogActions = styled(DialogActions)`
  padding: 0.75rem 1rem;
  background: ${({ theme }) => theme.palette.primary.light};
`;

export const CancelButton = styled(Button)`
  background: ${({ theme }) => theme.colors.neutral.light[100]};
  border: 1px solid ${({ theme }) => theme.palette.primary.main};

  &:hover {
    background: ${({ theme }) => theme.colors.neutral.light[100]};
  }
`;

export const StyledTabList = styled(TabList)`
  min-height: 2.5rem;
  width: fit-content;
  background: ${({ theme }) => theme.colors.neutral.light[100]};
  padding: 0.25rem;
  border: 1px solid ${({ theme }) => theme.palette.primary.medium};
  border-radius: 0.5rem;

  & .MuiTabs-indicator {
    display: none;
  }

  & .MuiTabs-flexContainer {
    gap: 0.25rem;

    & button {
      min-height: 2rem;
      border-radius: 0.5rem;
      padding: 0.375rem 0.5rem;
      color: ${({ theme }) => theme.colors.neutral.dark[700]};

      &.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
        background: ${({ theme }) => theme.palette.primary.medium};
        color: ${({ theme }) => theme.palette.primary.main};
      }
    }
  }
`;

export const StyledTab = styled(Tab)`
  color: ${({ theme }) => theme.palette.grey[900]};
  text-transform: unset;
  font-size: 1rem;
  background: ${({ theme }) => theme.colors.neutral.light[100]};
`;

export const FormControlLabelStyled = styled(FormControlLabel)`
  height: 1.5rem;
  margin: unset;
`;

export const FormControlLabelSwitchStyled = styled(FormControlLabelStyled)`
  justify-content: space-between;
`;

export const StyledTabPanel = styled(TabPanel)`
  padding: 0;
`;
