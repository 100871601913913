import { ShowMoreLabel } from "@views/dietician/PatientMonitoring2/components/MeasurementsBox/MeasurementsPhotosSection.styled";
import { useAppTranslation, useClientParams } from "@hooks";
import PatientBodyMeasurementsGalleryModal from "@components/Client/PatientBodyMeasurementsGalleryModal/PatientBodyMeasurementsGalleryModal";
import { useState } from "react";
import { ListMonitoring } from "@assets/icons/Monitoring";
import { Box } from "@mui/material";

const PhotosShowMoreButton = () => {
  const { t } = useAppTranslation();
  const id = useClientParams();
  const [open, setOpen] = useState(false);

  return (
    <Box display="flex" flexDirection="row" gap="4px" alignItems="center">
      <ListMonitoring fill="#7448D0" />
      <ShowMoreLabel onClick={() => setOpen(true)}>
        {t("monitoring2.measurements_box.photo_section.show_more")}
      </ShowMoreLabel>
      <PatientBodyMeasurementsGalleryModal
        open={open}
        setOpen={setOpen}
        patientId={id}
      />
    </Box>
  );
};

export default PhotosShowMoreButton;
