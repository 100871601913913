import { fetchData } from "@utils/api";
import { APIMethods, ApiEndpoints } from "@typeDefinitions";

export const createNote = async (payload: CreateNoteRequestPayload) => {
  return await fetchData(ApiEndpoints.AccountNotes, APIMethods.POST, payload);
};

export interface CreateNoteRequestPayload {
  note: string;
}
