import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const CircularArrows = (props: SvgIconProps) => {
  return (
    <SvgIcon width="12" height="10" viewBox="0 0 12 10">
      <path
        d="M9.19287 6.12988C9.02995 6.59115 8.76546 7.0249 8.39307 7.39518C7.07064 8.71761 4.92725 8.71761 3.60482 7.39518L3.243 7.03125H3.96875C4.34326 7.03125 4.64583 6.72868 4.64583 6.35417C4.64583 5.97966 4.34326 5.67708 3.96875 5.67708H1.60954H1.60107C1.22656 5.67708 0.923991 5.97966 0.923991 6.35417V8.72396C0.923991 9.09847 1.22656 9.40104 1.60107 9.40104C1.97559 9.40104 2.27816 9.09847 2.27816 8.72396V7.97917L2.64844 8.35156C4.49984 10.203 7.50016 10.203 9.35156 8.35156C9.86784 7.83529 10.2402 7.22803 10.4688 6.57845C10.5936 6.2251 10.4074 5.84001 10.0562 5.71517C9.70492 5.59033 9.31771 5.77653 9.19287 6.12777V6.12988ZM10.5915 4.29541C10.6973 4.26367 10.7988 4.20654 10.8813 4.12191C10.966 4.03727 11.0231 3.93571 11.0527 3.82568C11.0591 3.80029 11.0654 3.77279 11.0697 3.74528C11.076 3.70931 11.0781 3.67334 11.0781 3.63737L11.0781 1.27604C11.0781 0.901531 10.7756 0.59896 10.401 0.59896C10.0265 0.59896 9.72396 0.901531 9.72396 1.27604L9.72396 2.01872L9.35156 1.64844C7.50016 -0.200845 4.49984 -0.200845 2.65055 1.64844C2.13428 2.16471 1.75977 2.77197 1.53125 3.41943C1.40641 3.77279 1.59261 4.15788 1.94385 4.28272C2.29508 4.40755 2.68229 4.22136 2.80713 3.87012C2.97005 3.40886 3.23454 2.9751 3.60693 2.60482C4.92936 1.28239 7.07275 1.28239 8.39518 2.60482L8.3973 2.60693L8.75911 2.96875H8.03125C7.65674 2.96875 7.35417 3.27132 7.35417 3.64583C7.35417 4.02035 7.65674 4.32292 8.03125 4.32292H10.3926C10.4264 4.32292 10.4603 4.3208 10.4941 4.31657C10.528 4.31234 10.5597 4.30599 10.5915 4.29541Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
