import { Controller, Path, useFormContext } from "react-hook-form";

import {
  ASSIMILABLE_CARBOHYDRATES_ID,
  CARBOHYDRATE_EXCHANGERS_ID,
  GLYCEMIC_LOAD_ID,
  PROTEIN_AND_FAT_EXCHANGERS_ID,
} from "@utils/nutrients";
import { useIdMapContext } from "@context";
import { Error } from "@assets/icons";
import { NumericInput } from "@components/Input";

import { ProductFormInput } from "../../ProductForm";

interface NutrientValueFormProps {
  id: number;
}

export const NutrientValueForm = ({ id }: NutrientValueFormProps) => {
  const form = useFormContext<ProductFormInput>();
  const { idMap } = useIdMapContext();
  const nutrientId = idMap[id];

  if (nutrientId === undefined) {
    return <Error className="text-red" />;
  }

  return (
    <Controller
      name={`nutrients.${nutrientId}.value` as Path<ProductFormInput>}
      control={form.control}
      render={({ field, fieldState: { error } }) => (
        <NumericInput
          {...field}
          value={field.value as number}
          textCenter
          variant="outlined"
          className="w-16"
          readOnly={readOnlyNutrients.includes(id)}
          precision={3}
          error={error?.message}
        />
      )}
    />
  );
};
const readOnlyNutrients = [
  ASSIMILABLE_CARBOHYDRATES_ID,
  GLYCEMIC_LOAD_ID,
  CARBOHYDRATE_EXCHANGERS_ID,
  PROTEIN_AND_FAT_EXCHANGERS_ID,
];
