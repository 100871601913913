import { styled } from "@mui/material";

export const PacmanBackground = styled("div")`
  display: flex;
  position: fixed;
  top: 70px;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  z-index: 0;
`;

export const BackgroundsImg = styled("img")`
  height: 82%;
  margin-top: 45px;
`;
