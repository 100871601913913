import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

import { LangDto } from "../common";

export const updateClinicDietitian = async (
  id: number,
  payload: UpdateClinicDietitianRequest,
) => {
  return await fetchData(
    `/dietitian/clinic/dietitians/${id}`,
    APIMethods.PUT,
    payload,
  );
};

export interface UpdateClinicDietitianRequest {
  firstName: string;
  lastName: string;
  facility: FacilityId;
  phone: PhoneDto;
  language: LangDto;
}

interface PhoneDto {
  prefix: string | null;
  number: string | null;
}

interface FacilityId {
  id: number;
}
