import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import { useFetchMonitoringMealsStats } from "@hooks/queries/client/monitoring";
import { mapSelectedDaysToRequestQueryParams } from "@views/dietician/PatientMonitoring2/PatientMonitoringUtils";
import MealsSummaryCardOf from "@views/dietician/PatientMonitoring2/components/SummaryCard/Meals/MealsSummaryCardOf";

const MealsSummaryCardOfDays = () => {
  const { patientId, selectedDays, type } = useMonitoring();

  const { data } = useFetchMonitoringMealsStats(
    {
      patientId,
      ...mapSelectedDaysToRequestQueryParams(selectedDays.selectDays),
    },
    { enabled: type.type !== "day" },
  );

  return (
    <MealsSummaryCardOf
      actualValue={Math.ceil(
        (data?.data?.meals.eaten ?? 0) / Math.max(data?.data?.days ?? 0, 1),
      )}
      targetValue={Math.ceil(
        (data?.data?.meals.all ?? 0) / Math.max(data?.data?.days ?? 0, 1),
      )}
    />
  );
};

export default MealsSummaryCardOfDays;
