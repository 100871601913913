import { Pencil } from "@assets/icons";
import {
  ClientProfileCard,
  EditButton,
  SectionWrapper,
  TitleSection,
} from "@views/dietician/ClientProfile";
import { ClientProfileCardItem } from "../ClientProfileCardItem";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  useAppParams,
  useAppTranslation,
  useModal,
  useResizeObserver,
} from "@hooks";
import { ExpandedButton, StyledChip } from "./FoodAndAllergensSection.styled";
import {
  useFetchClientFoodGroupPreferences,
  useFetchClientFoodPreferences,
  useFetchClientTags,
} from "@hooks/queries";
import { useTagsNew } from "@hooks/useTagsNew";
import { TagCategoryType } from "@utils/tagsNew";
import { noData } from "../../ClientHealth";
import { useRef, useState } from "react";
import { EditFoodAndAllergensModal } from "@components/EditFoodAndAllergensModal";
import { allergenCategoryId, dietTypeId } from "@utils";
import { ChipWithTooltip } from "./ChipWithTooltip";

export const FoodAndAllergensSection = () => {
  const { t, isPolishChosen } = useAppTranslation();
  const { patientId } = useAppParams();
  const { breakpoints } = useTheme();
  const isDownMedium = useMediaQuery(`${breakpoints.down("medium")}`);
  const allergensRef = useRef<HTMLDivElement>(null);
  const dislikedProductsRef = useRef<HTMLDivElement>(null);
  const likedProductsRef = useRef<HTMLDivElement>(null);
  const dislikedCategoriesRef = useRef<HTMLDivElement>(null);
  const likedCategoriesRef = useRef<HTMLDivElement>(null);
  const dietTypesRef = useRef<HTMLDivElement>(null);
  const allergensHeight = useResizeObserver(allergensRef);
  const dislikedProductsHeight = useResizeObserver(dislikedProductsRef);
  const likedProductsHeight: number = useResizeObserver(likedProductsRef);
  const dislikedCategoriesHeight = useResizeObserver(dislikedCategoriesRef);
  const likedCategoriesHeight = useResizeObserver(likedCategoriesRef);
  const dietTypesHeight = useResizeObserver(dietTypesRef);
  const { modalOpened, onModalOpen, onModalClose } = useModal();
  const { clientTags } = useFetchClientTags(patientId);
  const { clientFoodPreferences: likedProducts } =
    useFetchClientFoodPreferences(patientId, 1);
  const { clientFoodPreferences: dislikedProducts } =
    useFetchClientFoodPreferences(patientId, 0);
  const { clientFoodGroupPreferences } =
    useFetchClientFoodGroupPreferences(patientId);
  const { getTagCategoryOptions } = useTagsNew();

  const allergens = clientTags?.filter(
    el => el.category.id === allergenCategoryId,
  );
  const allergenIdsList = allergens?.map(el => el.id);
  const allergensTabsList = getTagCategoryOptions(TagCategoryType.ALLERGENS);
  const filteredAllergens = allergensTabsList?.filter(product =>
    allergenIdsList?.includes(Number(product.id)),
  );

  const dietTypes = clientTags?.filter(el => el.category.id === dietTypeId);
  const dietTypesIdsList = dietTypes?.map(el => el.id);
  const dietTypesTabsList = getTagCategoryOptions(TagCategoryType.DIET_TYPE);
  const filteredDietTypes = dietTypesTabsList?.filter(product =>
    dietTypesIdsList?.includes(Number(product.id)),
  );

  const dislikedCategories = clientFoodGroupPreferences?.filter(
    el => !el.isLiked,
  );
  const likedCategories = clientFoodGroupPreferences?.filter(el => el.isLiked);

  const openShowMore = (sectionHeight: number) => {
    return isDownMedium ? sectionHeight > 64 : sectionHeight > 28;
  };

  const [isAllergensExpanded, setIsAllergensExpanded] = useState(true);
  const toggleAllergensExpand = () => {
    setIsAllergensExpanded(!isAllergensExpanded);
  };
  const [isDisLikedExpanded, setIsDisLikedExpanded] = useState(true);
  const toggleDislikedExpand = () => {
    setIsDisLikedExpanded(!isDisLikedExpanded);
  };

  const [isDisLikedCategoriesExpanded, setIsDisLikedCategoriesExpanded] =
    useState(true);
  const toggleDislikedCategoriesExpand = () => {
    setIsDisLikedCategoriesExpanded(!isDisLikedCategoriesExpanded);
  };

  const [isLikedCategoriesExpanded, setIsLikedCategoriesExpanded] =
    useState(true);
  const toggleLikedCategoriesExpand = () => {
    setIsLikedCategoriesExpanded(!isLikedCategoriesExpanded);
  };

  const [isLikedExpanded, setIsLikedExpanded] = useState(true);
  const toggleLikedExpand = () => {
    setIsLikedExpanded(!isLikedExpanded);
  };
  const [isDietTypesExpanded, setIsDietTypesExpanded] = useState(true);
  const toggleDietTypesExpand = () => {
    setIsDietTypesExpanded(!isDietTypesExpanded);
  };

  return (
    <>
      <ClientProfileCard>
        <TitleSection>
          <p>{t("client_profile.health.food_and_allergens")}</p>
          <EditButton
            onClick={onModalOpen}
            startIcon={<Pencil size="w-4 h-4" className="stroke-current" />}
            disableRipple
          >
            {t("common.edit")}
          </EditButton>
        </TitleSection>

        <SectionWrapper>
          <div>
            <ClientProfileCardItem
              tagsRef={allergensRef}
              hide={isAllergensExpanded && openShowMore(allergensHeight)}
              title={t("client_profile.health.allergens")}
              extraEl={
                filteredAllergens?.length
                  ? filteredAllergens?.map(({ id, label }) => (
                      <ChipWithTooltip
                        key={id}
                        className="w-fit"
                        label={label}
                        color="error"
                      />
                    ))
                  : noData
              }
            />
            {filteredAllergens && openShowMore(allergensHeight) && (
              <ExpandedButton
                fullWidth
                disableRipple
                onClick={toggleAllergensExpand}
              >
                {!isAllergensExpanded
                  ? t("common.show_less")
                  : t("common.show_more")}
              </ExpandedButton>
            )}
          </div>
          <div>
            <ClientProfileCardItem
              tagsRef={dislikedCategoriesRef}
              hide={
                isDisLikedCategoriesExpanded &&
                openShowMore(dislikedCategoriesHeight)
              }
              title={t("client_profile.health.disliked_product_categories")}
              extraEl={
                dislikedCategories?.length
                  ? dislikedCategories.map(el => {
                      const name = el.foodGroup.translations.find(
                        t => t.lang === "pl",
                      )?.name;
                      const nameEn = el.foodGroup.translations.find(
                        t => t.lang === "en",
                      )?.name;
                      return (
                        <ChipWithTooltip
                          key={el.foodGroup.id}
                          className="w-fit"
                          label={isPolishChosen ? name : nameEn}
                          color="warning"
                        />
                      );
                    })
                  : noData
              }
            />
            {dislikedCategories && openShowMore(dislikedCategoriesHeight) && (
              <ExpandedButton
                fullWidth
                disableRipple
                onClick={toggleDislikedCategoriesExpand}
              >
                {!isDisLikedCategoriesExpanded
                  ? t("common.show_less")
                  : t("common.show_more")}
              </ExpandedButton>
            )}
          </div>
          <div>
            <ClientProfileCardItem
              tagsRef={dislikedProductsRef}
              hide={isDisLikedExpanded && openShowMore(dislikedProductsHeight)}
              title={t("client_profile.health.disliked_product")}
              extraEl={
                dislikedProducts?.length
                  ? dislikedProducts.map(el => {
                      const name = el.food.translations.find(
                        t => t.lang === "pl",
                      )?.name;
                      const nameEn = el.food.translations.find(
                        t => t.lang === "en",
                      )?.name;
                      return (
                        <ChipWithTooltip
                          key={el.food.id}
                          className="w-fit"
                          label={isPolishChosen ? name : nameEn}
                          color="warning"
                        />
                      );
                    })
                  : noData
              }
            />
            {dislikedProducts && openShowMore(dislikedProductsHeight) && (
              <ExpandedButton
                fullWidth
                disableRipple
                onClick={toggleDislikedExpand}
              >
                {!isDisLikedExpanded
                  ? t("common.show_less")
                  : t("common.show_more")}
              </ExpandedButton>
            )}
          </div>

          <div>
            <ClientProfileCardItem
              tagsRef={likedCategoriesRef}
              hide={
                isLikedCategoriesExpanded && openShowMore(likedCategoriesHeight)
              }
              title={t("client_profile.health.liked_product_categories")}
              extraEl={
                likedCategories?.length
                  ? likedCategories.map(el => {
                      const name = el.foodGroup.translations.find(
                        t => t.lang === "pl",
                      )?.name;
                      const nameEn = el.foodGroup.translations.find(
                        t => t.lang === "en",
                      )?.name;
                      return (
                        <ChipWithTooltip
                          key={el.foodGroup.id}
                          className="w-fit"
                          label={isPolishChosen ? name : nameEn}
                          color="success"
                        />
                      );
                    })
                  : noData
              }
            />
            {likedCategories && openShowMore(likedCategoriesHeight) && (
              <ExpandedButton
                fullWidth
                disableRipple
                onClick={toggleLikedCategoriesExpand}
              >
                {!isLikedCategoriesExpanded
                  ? t("common.show_less")
                  : t("common.show_more")}
              </ExpandedButton>
            )}
          </div>
          <div>
            <ClientProfileCardItem
              tagsRef={likedProductsRef}
              hide={isLikedExpanded && openShowMore(likedProductsHeight)}
              title={t("client_profile.health.liked_product")}
              extraEl={
                likedProducts?.length
                  ? likedProducts.map(el => {
                      const name = el.food.translations.find(
                        t => t.lang === "pl",
                      )?.name;
                      const nameEn = el.food.translations.find(
                        t => t.lang === "en",
                      )?.name;
                      return (
                        <ChipWithTooltip
                          key={el.food.id}
                          className="w-fit"
                          label={isPolishChosen ? name : nameEn}
                          color="success"
                        />
                      );
                    })
                  : noData
              }
            />
            {likedProducts && openShowMore(likedProductsHeight) && (
              <ExpandedButton
                fullWidth
                disableRipple
                onClick={toggleLikedExpand}
              >
                {!isLikedExpanded
                  ? t("common.show_less")
                  : t("common.show_more")}
              </ExpandedButton>
            )}
          </div>

          <div>
            <ClientProfileCardItem
              tagsRef={dietTypesRef}
              hide={isDietTypesExpanded && openShowMore(dietTypesHeight)}
              title={t("client_profile.health.diet_type")}
              extraEl={
                filteredDietTypes?.length
                  ? filteredDietTypes.map(({ id, label }) => (
                      <ChipWithTooltip
                        key={id}
                        className="w-fit"
                        label={label}
                        color="primary"
                      />
                    ))
                  : noData
              }
            />
            {filteredDietTypes && openShowMore(dietTypesHeight) && (
              <ExpandedButton
                fullWidth
                disableRipple
                onClick={toggleDietTypesExpand}
              >
                {!isDietTypesExpanded
                  ? t("common.show_less")
                  : t("common.show_more")}
              </ExpandedButton>
            )}
          </div>
        </SectionWrapper>
      </ClientProfileCard>

      <EditFoodAndAllergensModal
        open={modalOpened}
        onClose={onModalClose}
        id={patientId}
        allergensTabsList={allergensTabsList}
        dietTypesTabsList={dietTypesTabsList}
        dislikedProducts={dislikedProducts}
        likedProducts={likedProducts}
        clientTags={clientTags}
      />
    </>
  );
};
