import { styled } from "@mui/material";

export const TitleStyled = styled("span")`
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;

export const PageNavigationHeaderWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
