import { DragHandle } from "@assets/icons";
import styled from "@emotion/styled";
import { AccordionSummary, AccordionDetails } from "@mui/material";

export const SortableItem = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;
`;

export const StyledDragHandle = styled(DragHandle)`
  font-size: 1.5rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.cancelButtonHover};
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  min-height: 3.875rem;
  padding: 1rem;
  border-radius: 0.375rem;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.0588235);

  & .MuiAccordionSummary-content {
    margin: 0;
  }
`;

export const StyledTitle = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: initial;
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  display: grid;
  gap: 1.5rem;
  padding: 1.5rem;
`;
