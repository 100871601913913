import { useForm } from "react-hook-form";
import * as yup from "yup";

import { UsePostProgramFromDietMutationPayload } from "@hooks/queries";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppTranslation } from "@hooks";
import dayjs from "dayjs";

export const useAutogenerateForm = (defaultValues?: AutogenerateFormInputs) => {
  const { t } = useAppTranslation();
  const validatonSchema = yup.object({
    clientId: yup.string().required(t("common.required_field")),
    dietId: yup.string().required(t("common.required_field")),
    startDate: yup.string().required(t("common.required_field")),
    endDate: yup.string().required(t("common.required_field")),
  });

  const form = useForm<AutogenerateFormInputs>({
    resolver: yupResolver(validatonSchema),
    defaultValues,
  });

  return form;
};

export interface AutogenerateFormInputs {
  clientId?: string;
  dietId?: string;
  startDate?: Date;
  endDate?: Date;
}

export const mapAutogenerateRequest = ({
  clientId,
  dietId,
  startDate,
  endDate,
}: AutogenerateFormInputs):
  | UsePostProgramFromDietMutationPayload
  | undefined => {
  if (!clientId || !dietId || !startDate || !endDate) return;
  return {
    clientId,
    payload: {
      diet: {
        id: Number(dietId),
      },
      program: {
        startDate: dayjs(startDate).format("YYYY-MM-DD"),
        endDate: dayjs(endDate).format("YYYY-MM-DD"),
      },
    },
  };
};
