import { useMutation, useQueryClient } from "@tanstack/react-query";

import { postToggleFavoriteRecipe } from "@client";

import { MutationOptions } from "./types";
import { fetchRecipeQueryKey } from "./useFetchRecipeQuery";
import { searchRecipesQueryKeyNew } from "./useSearchRecipesQueryNew";

export const usePostToggleFavoriteRecipeMutation = (
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, string>(
    recipeId => postToggleFavoriteRecipe(recipeId),
    {
      ...options,
      onSuccess: (d, recipeId) => {
        options?.onSuccess && options.onSuccess(d, recipeId);
        queryClient.invalidateQueries([searchRecipesQueryKeyNew]);
        queryClient.invalidateQueries([fetchRecipeQueryKey, Number(recipeId)]);
      },
    },
  );
};
