import { CountLabel, Pagination } from "@components/PaginationNew";
import { RecipeRowIf } from "@components/RecipesDataGrid/RecipesDataGrid";
import { FiltersNoResults } from "@views/emptyStates/FiltersNoResults";
import { RecipeTile } from "./RecipeTile";
import { CardStyled, GridWrapper } from "./RecipesGridLayout.styled";

interface RecipesGridLayoutProps {
  recipes: RecipeRowIf[];
  onPageChange?: (page: number) => void;
  page: number;
  perPage: number;
  setPerPage: (value: number) => void;
  isOnePage: boolean;
  rowCount?: number;
  loading?: boolean;
  onRecipeSelect: (id: number) => void;
  resetFilters: () => void;
  isLoading: boolean;
}

export const RecipesGridLayout = ({
  recipes,
  onPageChange,
  page,
  perPage,
  setPerPage,
  isOnePage,
  rowCount,
  onRecipeSelect,
  resetFilters,
  isLoading,
}: RecipesGridLayoutProps) => {
  return (
    <div className="grid gap-[7px]">
      {!recipes.length && !isLoading && (
        <CardStyled>
          <FiltersNoResults onClick={resetFilters} />
        </CardStyled>
      )}
      {!!recipes.length && (
        <GridWrapper>
          {recipes.map((r, idx) => (
            <RecipeTile
              {...r}
              key={`${idx}-${r.id}`}
              onSelect={() => onRecipeSelect(r.id)}
            />
          ))}
        </GridWrapper>
      )}

      <div className="flex justify-between items-center">
        <CountLabel count={rowCount ?? 1} page={page} perPage={perPage} />
        <div className="flex items-center">
          <Pagination
            isOnePage={isOnePage}
            page={page}
            onPageChange={(_, newPage) => onPageChange && onPageChange(newPage)}
            count={rowCount}
            perPage={perPage}
            setPerPage={v => setPerPage(v as number)}
            rowsPerPageOptions={[10, 25]}
          />
        </div>
      </div>
    </div>
  );
};
