import { fetchData } from "@utils/api";
import { APIMethods, NoContentApiResponse } from "@typeDefinitions";

export type UpdatePatientBodyMeasurementSettingsParams = {
  patientId: number;
};

export type UpdatePatientBodyMeasurementSettingsResponse = NoContentApiResponse;

export type UpdatePatientBodyMeasurementSettingsPayload = {
  bodyMeasurementsId: number[];
  requiredPhotos: boolean;
};

export const updatePatientBodyMeasurementSettings = async (
  params: UpdatePatientBodyMeasurementSettingsParams,
  payload: UpdatePatientBodyMeasurementSettingsPayload,
): Promise<UpdatePatientBodyMeasurementSettingsResponse> => {
  return await fetchData(
    "/dietitian/patients/{patient_id}/body-measurements-settings".replace(
      "{patient_id}",
      params.patientId.toString(),
    ),
    APIMethods.PUT,
    payload,
  );
};
