import {
  VisitMeasurements,
  Visits,
  VisitSegment,
  VisitTagGroup,
} from "@typeDefinitions";
import { MeasurementContent } from "../VisitDetailSegment/components/MeasurementContent/MeasurementContent";
import { VisitDetailSegment } from "../VisitDetailSegment/VisitDetailSegment";
import { VisitFileUpload } from "../VisitFileUpload/VisitFileUpload";
import { Separator } from "./components/Separator.styled";
import { VisitSegmentCard } from "./components/VisitSegmentCard/VisitSegmentCard";

export interface VisitDetailSegmentBuilderProps {
  segment: VisitSegment;
  patientId: number;
  visitId: number;
  isLeft?: boolean;
  isFirst?: boolean;
  tags?: VisitTagGroup[];
  patientVisits: Visits[];
}

export const VisitDetailSegmentBuilder = ({
  segment,
  patientId,
  visitId,
  isLeft,
  isFirst,
  tags,
  patientVisits,
  ...fileUploadProps
}: VisitDetailSegmentBuilderProps) => {
  const { value: content, type, defaultOpen, ...passableProps } = segment;

  return (
    <VisitSegmentCard
      title={segment.name}
      draggable={segment.isDraggable}
      collapsable={type !== "measurements"}
      defaultVisibility={defaultOpen}
    >
      {/* {type === "measurements" && (
        <MeasurementContent
          patientId={patientId}
          visitId={visitId}
          visitIsFirst={isFirst}
          lastMeasurementData={segment.value as VisitMeasurements}
          patientVisits={patientVisits}
          name={segment.name}
        />
      )} */}
      {type !== "measurements" && (
        <VisitDetailSegment
          type={type}
          tags={tags}
          value={content}
          patientId={patientId}
          visitId={visitId}
          {...passableProps}
        />
      )}
      {segment.hasFiles && (
        <>
          <Separator />
          <VisitFileUpload
            files={segment.files}
            patientId={patientId}
            visitId={visitId}
            {...fileUploadProps}
          />
        </>
      )}
    </VisitSegmentCard>
  );
};
