import { useLocalStorage } from "react-use";
import { createContext, ReactNode, useContext, useEffect } from "react";

interface FeatureFlagsIf {
  chat: boolean;
}

export const FeatureFlagsContext = createContext<FeatureFlagsIf>({
  chat: false,
});

export function useFeatureFlags() {
  return useContext(FeatureFlagsContext);
}

interface FeatureFlagsContextProviderProps {
  children: ReactNode;
}

export function FeatureFlagsContextProvider({
  children,
}: FeatureFlagsContextProviderProps) {
  const [chat, setChat] = useLocalStorage("featureFlag/chat", false);

  useEffect(() => {
    (window as any).enableChat = () => {
      console.log("Feature flag [chat] enabled");
      setChat(true);
    };

    (window as any).disableChat = () => {
      console.log("Feature flag [chat] disabled");
      setChat(false);
    };
  }, []);

  return (
    <FeatureFlagsContext.Provider value={{ chat: true }}>
      {children}
    </FeatureFlagsContext.Provider>
  );
}
