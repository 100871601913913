import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const LeafySprout = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="14" viewBox="0 0 14 14">
      <path
        d="M13.5 1.3125C13.5 4.19687 11.352 6.58105 8.56914 6.94922C8.38887 5.59336 7.79219 4.36953 6.91113 3.4123C7.88359 1.67559 9.74219 0.5 11.875 0.5H12.6875C13.1369 0.5 13.5 0.863086 13.5 1.3125ZM0.5 2.9375C0.5 2.48809 0.863086 2.125 1.3125 2.125H2.125C5.26582 2.125 7.8125 4.67168 7.8125 7.8125V8.625V12.6875C7.8125 13.1369 7.44941 13.5 7 13.5C6.55059 13.5 6.1875 13.1369 6.1875 12.6875V8.625C3.04668 8.625 0.5 6.07832 0.5 2.9375Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
