import * as yup from "yup";

import {
  ClinicIdDto,
  ImageDto,
  LangDto,
  NutrientDto,
  TagCategoryDto,
  TagDto,
  TranslationDto,
  clinicIdSchema,
  imageSchema,
  nutrientSchema,
  tagCategorySchema,
  translationSchema,
} from "@client/common";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const fetchCreatorDietMealsItems = async (
  dietId: string,
  mealId: string,
): Promise<ApiResponse<DietMealItemCreatorResponseDto>> => {
  const response = await fetchData(
    `/dietitian/creator-2/diets/${dietId}/meals/${mealId}/items`,
    APIMethods.GET,
  );

  return responseSchema.validate(response);
};

export interface DietMealItemCreatorResponseDto {
  recipes: MealRecipeCreatorDto[];
  products: MealProductCreatorDto[];
}

interface TranslationLangDto {
  lang: LangDto;
  name: string;
}

interface MealRecipeCreatorDto {
  id: number;
  servings: number;
  recipe: RecipeDto;
  nutrients: NutrientDto[];
}

interface RecipeDto {
  id: number;
  name: string;
  translations: TranslationLangDto[];
  tags: TagExtendedDto[];
  servings: number;
  image: ImageDto | null;
}

interface MealProductCreatorDto {
  id: number;
  grams: number;
  product: ProductDto;
  nutrients: NutrientDto[];
}

interface TagWithDescriptionDto extends TagDto {
  description: string;
  descriptionEn: string;
}

interface TagExtendedDto {
  clinic: ClinicIdDto | null;
  id: number;
  name: string;
  nameEn: string;
  systemId: number | null;
  tagCategory: TagCategoryDto;
  translations: TranslationDto[];
}
interface ProductDto {
  id: number;
  name: string;
  translations: TranslationLangDto[];
  tags: TagExtendedDto[];
  category: TagWithDescriptionDto;
  image: ImageDto | null;
}

const translationLangSchema = yup.object().shape({
  lang: yup.mixed<LangDto>().required(),
  name: yup.string().ensure(),
});

const tagExtendedSchema = yup.object().shape({
  clinic: clinicIdSchema.nullable().default(null),
  id: yup.number().required(),
  name: yup.string().required(),
  nameEn: yup.string().required(),
  systemId: yup.number().nullable().default(null),
  tagCategory: tagCategorySchema.required(),
  translations: yup.array().of(translationSchema).required(),
});

const tagWithDescriptionSchema = yup.object().shape({
  id: yup.number().required(),
  description: yup.string().required(),
  descriptionEn: yup.string().required(),
});

const recipeSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  translations: yup.array().of(translationLangSchema).required(),
  tags: yup.array().of(tagExtendedSchema).required(),
  servings: yup.number().required(),
  image: imageSchema.nullable(),
});

const productSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  translations: yup.array().of(translationLangSchema).required(),
  tags: yup.array().of(tagExtendedSchema).required(),
  category: tagWithDescriptionSchema.required(),
  image: imageSchema.nullable(),
});

const mealRecipeCreatorSchema = yup.object().shape({
  id: yup.number().required(),
  servings: yup.number().required(),
  recipe: recipeSchema.required(),
  nutrients: yup.array().of(nutrientSchema).required(),
});

const mealProductCreatorSchema = yup.object().shape({
  id: yup.number().required(),
  grams: yup.number().required(),
  product: productSchema.required(),
  nutrients: yup.array().of(nutrientSchema).required(),
});

const dietMealItemCreatorResponseSchema = yup.object().shape({
  recipes: yup.array().of(mealRecipeCreatorSchema).required(),
  products: yup.array().of(mealProductCreatorSchema).required(),
});

const responseSchema = yup.object().shape({
  data: dietMealItemCreatorResponseSchema.required(),
});
