import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { FileData, fileDataSchema } from "./visits";
import * as yup from "yup";

export const postClientAvatar = async (
  id: string,
  file?: FormData,
): Promise<ApiResponse<FileData>> => {
  const response = await fetchData(
    `/dietitian/patients/${id}/avatar`,
    APIMethods.POST,
    file,
    undefined,
    { "Content-Type": "multipart/form-data" },
  );

  await savedFileDataSchema.validate(response);
  return response;
};

const savedFileDataSchema = yup.object().shape({
  data: fileDataSchema.required(),
});

export const parseFileToData = (file: File | null): FormData | undefined => {
  const formDataName = "file";
  const formData = new FormData();

  file && formData.append(formDataName, file, file.name);

  return formData;
};
