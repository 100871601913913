import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const PurpleStar = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="18" height="18" viewBox="0 0 18 18">
      <path
        d="M9 13.3275L13.635 16.125L12.405 10.8525L16.5 7.305L11.1075 6.8475L9 1.875L6.8925 6.8475L1.5 7.305L5.595 10.8525L4.365 16.125L9 13.3275Z"
        fill="#801FFF"
      />
    </SvgIcon>
  );
};
