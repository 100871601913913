import { Stack, styled } from "@mui/material";
import img from "@assets/img/EmptyHere.png";

type EmptyHereProps = {
  title: string;
  description: string;
};
const EmptyHere = ({ title, description }: EmptyHereProps) => {
  return (
    <Stack direction="column" gap="24px" alignItems="center">
      <img src={img} width="160px" />
      <Stack direction="column" gap="8px" alignItems="center">
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Stack>
    </Stack>
  );
};

const Title = styled("span")`
  font-family: Figtree, serif;
  color: #141414;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
`;

const Description = styled("span")`
  font-family: Figtree, serif;
  color: #141414;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
`;

export default EmptyHere;
