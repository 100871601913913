import { useCallback, useState } from "react";
import { useController, UseFormReturn } from "react-hook-form";

import { TreeSelectFilterDefinition } from "@typeDefinitions";
import { TreeView } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { BaseFilterSelect } from "./BaseFilterSelect";
import { FieldValues } from "react-hook-form/dist/types";
import { TreeNode } from "@components/TreeSelect";

interface TreeSelectFilterProps<T extends FieldValues> {
  form: UseFormReturn<T>;
  filter: TreeSelectFilterDefinition<T>;
}

export const TreeSelectFilter = <T extends FieldValues>({
  form: { control },
  filter: { label, options, name },
}: TreeSelectFilterProps<T>) => {
  const {
    field: { onChange },
    fieldState: { isDirty },
  } = useController({ control, name: name });

  const [open, setOpen] = useState(false);
  const onSelect = useCallback((value: string) => {
    onChange(Number.parseInt(value));
    setOpen(false);
  }, []);

  return (
    <BaseFilterSelect
      name={label}
      selected={isDirty}
      open={open}
      setOpen={setOpen}
    >
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        sx={{
          "flexGrow": 1,
          "overflowY": "auto",
          ".MuiTreeItem-content": { py: "8px" },
          ".MuiTreeItem-label": { fontSize: ".875rem !important" },
          ".MuiPopover-paper.MuiPaper-root": { width: "min-content" },
        }}
      >
        <TreeNode options={options} onClick={onSelect} />
      </TreeView>
    </BaseFilterSelect>
  );
};
