import { useFetchDietitianAccountQuery } from "./queries/settings";

export const useAppEnvironment = () => {
  const { account } = useFetchDietitianAccountQuery();
  const environment = import.meta.env.VITE_APP_ENVIRONMENT;
  const isProd = environment === "prod";

  return {
    isProd,
    enableProdFlag: (isProd && account?.clinic?.id === 18) || !isProd,
    environment,
  };
};
