import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MutationOptions } from "./types";
import { fetchScheduleEventQueryKey } from "./useFetchScheduleEvent";
import { searchScheduleEventsQueryKey } from "./useSearchScheduleEvents";
import { fetchPatientVisitsQueryKey } from "./visits";
import { updateScheduleEvent, UpdateScheduleEventRequest } from "@client";

export const useUpdateScheduleEvent = (
  id: number,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, UpdateScheduleEventRequest>(
    payload => updateScheduleEvent(id, payload),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries([fetchScheduleEventQueryKey, id]);
        queryClient.invalidateQueries([searchScheduleEventsQueryKey]);
        queryClient.invalidateQueries([fetchPatientVisitsQueryKey]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};
