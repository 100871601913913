import { useEffect, useMemo, useState } from "react";

import _, { isEqual } from "lodash";

import { useAppParams, useProgramLocalStorage } from "@hooks";
import {
  updateProgramDaysMutationKey,
  fetchPatientProgramQueryKey,
  useFetchClientQuery,
  useFetchNormsQuery,
  useFetchProgramDayNutrientsQuery,
  useProgramDayNutrientsMutation,
} from "@hooks/queries";
import {
  CARBS_ID,
  ENERGY_ID,
  FATS_ID,
  MACROS_ARRAY,
  mapDefaultMacrosValue,
  PROTEIN_ID,
} from "@utils/macros";
import { DEFAULT_NORM_ID } from "@utils/nutrients";
import { fetchNormNutrients } from "@client";
import { useProgram } from "@hooks/resources/useProgram";
import { MacrosSliders, MacrosSliderUpdate } from "@components/MacrosSliders";
import { Spinner } from "@components/Spinner";
import { useIsFetching, useIsMutating } from "@tanstack/react-query";
import { useUpdateCreatorDietTargetNutrientsMutation } from "@hooks/queries/diets/creator";

interface ClientProgramMacrosSlidersProps {
  dietId: number | undefined;
}

export const ClientProgramMacrosSliders = ({
  dietId,
}: ClientProgramMacrosSlidersProps) => {
  const { programId, dayId, patientId } = useAppParams();
  const loadingPutDay = useIsMutating({
    mutationKey: [updateProgramDaysMutationKey, programId],
  });
  const fetchingPutDay = useIsFetching({
    queryKey: [fetchPatientProgramQueryKey, patientId, programId],
  });
  const { nutrients, isLoading } = useFetchProgramDayNutrientsQuery(
    programId,
    dayId,
  );
  const { client } = useFetchClientQuery(parseInt(patientId), {
    enabled: !!patientId,
  });

  const [id, setId] = useState<string | null | undefined>(patientId);

  const { norms, isLoading: isNormsLoading } = useFetchNormsQuery(
    id ?? undefined,
    {
      onError: err => {
        if (err.response.status === 422) {
          setId(null);
        }
      },
      retry: false,
    },
  );

  const isLoadingDay =
    isLoading || isNormsLoading || !!loadingPutDay || !!fetchingPutDay;

  const clientWeight = useMemo(() => client?.profile.weight, [client]);
  const macrosValues = useMemo(() => {
    if (nutrients) {
      const values = mapDefaultMacrosValue(nutrients.data);
      const mappedValues = {
        kcal: values.energyKcal,
        protein: values.protein,
        fat: values.fats,
        carbs: values.carbs,
      };
      const isFallbackValue = isEqual(
        { kcal: 500, fat: 0, carbs: 0, protein: 0 },
        mappedValues,
      );

      return isFallbackValue
        ? { kcal: 2000, fat: 67, carbs: 249, protein: 100 }
        : mappedValues;
    }
    return;
  }, [nutrients]);

  const { mutate } = useProgramDayNutrientsMutation();
  const { mutate: mutateDiet } = useUpdateCreatorDietTargetNutrientsMutation();

  const { markModified } = useProgramLocalStorage();

  const handleChange = ({
    energy,
    fat,
    carbs,
    protein,
  }: MacrosSliderUpdate) => {
    if (programId && dayId && nutrients) {
      const updatedMacros = [
        { id: ENERGY_ID, value: energy },
        { id: FATS_ID, value: fat },
        { id: CARBS_ID, value: carbs },
        { id: PROTEIN_ID, value: protein },
      ];
      const updated = _.uniqBy(
        [
          ...updatedMacros.map(n => ({ ...n, visible: true })),
          ...nutrients.data.nutrients,
        ],
        el => el.id,
      );

      mutate(
        {
          programId,
          dayId,
          payload: {
            normId: nutrients.data.norm?.id ?? DEFAULT_NORM_ID.toString(),
            nutrients: updated,
          },
        },
        { onSuccess: () => markModified() },
      );
      dietId &&
        mutateDiet({
          dietId: dietId.toString(),
          payload: { nutrients: updatedMacros },
        });
    }
  };

  const { program } = useProgram();

  useEffect(() => {
    async function setDefaultNorm() {
      if (nutrients && norms && program) {
        const normId = id
          ? norms[0]?.normAge[0]?.id ?? DEFAULT_NORM_ID
          : DEFAULT_NORM_ID;
        const normNutrients = await fetchNormNutrients(normId);

        if (
          !nutrients.data.norm?.id &&
          !nutrients.data.nutrients.length &&
          programId &&
          dayId
        ) {
          mutate({
            programId,
            dayId,
            payload: {
              normId: normId.toString(),
              nutrients: [
                { id: ENERGY_ID, value: 2000, visible: true },
                { id: FATS_ID, value: 67, visible: true },
                { id: CARBS_ID, value: 249, visible: true },
                { id: PROTEIN_ID, value: 100, visible: true },
                ...normNutrients.data.map(n => ({
                  ...n,
                  visible: program.showNutrients.includes(n.id),
                })),
              ],
            },
          });
        } else if (
          !nutrients.data.norm?.id &&
          nutrients.data.nutrients.length &&
          programId &&
          dayId
        ) {
          mutate({
            programId,
            dayId,
            payload: {
              normId: normId.toString(),
              nutrients: [
                ...nutrients.data.nutrients,
                ...normNutrients.data
                  .filter(n => !MACROS_ARRAY.includes(n.id))
                  .map(n => ({
                    ...n,
                    visible: program.showNutrients.includes(n.id),
                  })),
              ],
            },
          });
        }
      }
    }

    setDefaultNorm();
  }, [nutrients, norms, program]);

  if (isLoadingDay) return <Spinner />;

  return (
    <MacrosSliders
      clientWeight={clientWeight ?? 0}
      defaultValues={macrosValues}
      onChange={handleChange}
      normError={id === null}
      showNorms={!patientId}
    />
  );
};
