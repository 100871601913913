import { useQuery } from "@tanstack/react-query";
import { useQueryParams } from "../useQueryParams";
import { ApiEndpoints } from "@typeDefinitions";
import { fetchTasksCount } from "@client/tasks/fetchTasksCount";
import { normalizeToNumberArray } from "@hooks/queries/tasks/useTasksQuery";

export const useTasksCountQuery = () => {
  const { get } = useQueryParams("dietitiansIds", "dueDateFrom", "dueDateTo");

  const { dietitiansIds, dueDateFrom, dueDateTo } = get<{
    dietitiansIds: string[] | undefined;
    dueDateFrom: string | undefined;
    dueDateTo: string | undefined;
  }>();

  const queryParams = {
    dietitians_ids: normalizeToNumberArray(dietitiansIds),
    due_date_from: dueDateFrom,
    due_date_to: dueDateTo,
  };

  return useQuery({
    queryKey: [ApiEndpoints.TasksCount, JSON.stringify(queryParams)],
    queryFn: async () => {
      return await fetchTasksCount(queryParams);
    },
  });
};
