import React, { useMemo } from "react";

import { useAppTranslation, useBasicNutrientsGetter } from "@hooks";
import { DietMealDto } from "@client";

import { PCFValue } from "../../PCFValue";
import { MacroCellWrapper } from "./MacroCellWrapper.styled";
import { TableContentWrapper } from "./TableContentWrapper.styled";
import { CARBS_ID, ENERGY_ID, FATS_ID, PROTEIN_ID } from "@utils/macros";

export const ProgramMealValuesTotal = ({
  meals,
}: ProgramMealValuesTotalProps) => {
  const { t } = useAppTranslation();

  const mergedNutrients = useMemo(
    () =>
      meals?.reduce(
        (total, meal) =>
          total.map(({ id, value }) => ({
            id,
            value:
              value +
              (meal.nutrients.find(nutrient => nutrient.id === id)?.value || 0),
          })),
        [
          { id: CARBS_ID, value: 0 },
          { id: ENERGY_ID, value: 0 },
          { id: FATS_ID, value: 0 },
          { id: PROTEIN_ID, value: 0 },
        ],
      ),
    [meals],
  );

  const totalTargetKcal = useMemo(
    () => meals?.reduce((total, meal) => total + meal.kcal, 0),
    [meals],
  );

  const { kcal, protein, fat, carbs } =
    useBasicNutrientsGetter(mergedNutrients);

  return (
    <>
      <TableContentWrapper colSpan={3} justifyEnd borderTop>
        {t("common.sum")}
        {": "}
      </TableContentWrapper>
      <TableContentWrapper colSpan={2} border bold borderTop>
        {totalTargetKcal && `${totalTargetKcal.toFixed()} kcal`}
      </TableContentWrapper>
      <TableContentWrapper colSpan={7} borderTop>
        <MacroCellWrapper bold>
          {kcal && `${kcal.toFixed()} kcal`}
        </MacroCellWrapper>
        <MacroCellWrapper>
          <PCFValue value={protein} name="protein" />
        </MacroCellWrapper>
        <MacroCellWrapper>
          <PCFValue value={fat} name="fats" />
        </MacroCellWrapper>
        <MacroCellWrapper>
          <PCFValue value={carbs} name="carbs" />
        </MacroCellWrapper>
      </TableContentWrapper>
    </>
  );
};

export interface ProgramMealValuesTotalProps {
  meals?: DietMealDto[];
}
