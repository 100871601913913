import { LastLoginSummaryCard } from "@views/dietician/PatientMonitoring2/components/SummaryCard/LastLoginSummaryCard";
import KcalSummaryCardFactory from "@views/dietician/PatientMonitoring2/components/SummaryCard/Kcal/KcalSummaryCardFactory";
import MealsSummaryCardFactory from "@views/dietician/PatientMonitoring2/components/SummaryCard/Meals/MealsSummaryCardFactory";
import IrrigationSummaryCardFactory from "@views/dietician/PatientMonitoring2/components/SummaryCard/Irrigation/IrrigationSummaryCardFactory";
import { Stack, styled } from "@mui/material";

const SummaryCardsBox = () => {
  return (
    <SummaryCardsContainer className="hide-scrollbar">
      <LastLoginSummaryCard />
      <KcalSummaryCardFactory />
      <MealsSummaryCardFactory />
      <IrrigationSummaryCardFactory />
    </SummaryCardsContainer>
  );
};

const SummaryCardsContainer = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 12px;
  overflow-x: auto;
`;

export default SummaryCardsBox;
