import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MutationOptions } from "./types";
import { fetchClientQueryKey } from "./useFetchClientQuery";
import { fetchPatientProgramQueryKey } from "./useFetchPatientProgramQuery";
import { updateClientChat, UpdateClientChatRequest } from "@client";
import { fetchClientEventsQueryKey } from "./useFetchClientEventsQuery";

export const useUpdateClientChatMutation = (
  id: number,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, UpdateClientChatRequest>(
    payload => updateClientChat(id, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([fetchClientQueryKey, id]);
        queryClient.invalidateQueries([fetchPatientProgramQueryKey, id]);
        queryClient.invalidateQueries([fetchClientEventsQueryKey, id]);
        options?.onSuccess && options.onSuccess();
      },
      ...options,
    },
  );
};
