import { useQuery } from "@tanstack/react-query";

import {
  DietProductRemovedItemDto,
  fetchDietItemsProductsRemoved,
} from "@client/diets";
import { ApiResponse } from "@typeDefinitions";

import { QueryOptionsTyped } from "../types";

export const fetchDietsItemsProductsRemovedQueryKey =
  "fetchDietsItemsProductsRemovedQueryKey";

export const useFetchDietsItemsProductsRemovedQuery = (
  dietId: string,
  from: string,
  options?: QueryOptionsTyped<ApiResponse<DietProductRemovedItemDto[]>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchDietsItemsProductsRemovedQueryKey, dietId, from],
    () => fetchDietItemsProductsRemoved(dietId, from),
    options,
  );

  return {
    data: data?.data,
    ...rest,
  };
};
