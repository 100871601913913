import { styled } from "@mui/material";
import { BaseProgramSearchPreview } from "@client/program/preview/fetchProgramPreview";
import { useMemo } from "react";

type ProgramPeriodProps = Pick<BaseProgramSearchPreview, "duration">;

const ProgramPeriod = ({ duration }: ProgramPeriodProps) => {
  const value = useMemo(() => {
    if (duration === null) {
      return "bezterminowy";
    }

    return duration;
  }, []);

  return <Period>{value}</Period>;
};

const Period = styled("span")`
  color: #333333;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  font-family: Figtree, serif;
`;

export default ProgramPeriod;
