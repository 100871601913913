import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";
import { useAppParams } from "@hooks";
import {
  useFetchProductQuery,
  useStoreFoodEditorMutation,
} from "@hooks/queries/food";
import { useCategoriesNutrientsFetchQuery } from "@hooks/queries/dictionaries";

import { ProductCopyWrapper } from "./ProductCopy.styled";
import {
  mapProductForm,
  mapProductRequest,
  useProductForm,
} from "../ProductForm";

export const ProductCopy = () => {
  const [init, setInit] = useState(true);
  const { productId } = useAppParams();
  const navigate = useNavigate();
  const copiedProductId = productId ? parseInt(productId) : NaN;

  const {
    product: copiedProduct,
    isLoading,
    isError,
  } = useFetchProductQuery(copiedProductId, { enabled: !!copiedProductId });

  const { data: nutrientCategories } = useCategoriesNutrientsFetchQuery();
  const { mutate: createProduct, isLoading: isCopyLoading } =
    useStoreFoodEditorMutation({
      onSuccess: data => {
        setInit(false);
        navigate(`/products/${data?.id}/edit`, { replace: true });
      },
    });

  const mappedFormValues = useMemo(() => {
    if (init) {
      const mapped = mapProductForm(copiedProduct, nutrientCategories);
      return (
        mapped && {
          ...mapped,
          namePl: mapped.namePl + " (Kopia)",
          nameEn: mapped.nameEn + " (Copy)",
        }
      );
    }
  }, [copiedProduct, nutrientCategories, init]);
  const form = useProductForm(mappedFormValues);

  const { handleSubmit, formState } = form;
  const { isValid } = formState;

  useEffect(() => {
    if (init && copiedProduct && isValid)
      handleSubmit(
        data => createProduct(mapProductRequest(data, copiedProduct)),
        err => console.log(err),
      )();
  }, [copiedProduct, isValid]);

  if (init || isLoading || isCopyLoading)
    return (
      <ProductCopyWrapper>
        <Spinner />
      </ProductCopyWrapper>
    );

  if (isError)
    return (
      <ProductCopyWrapper>
        <ApiError />
      </ProductCopyWrapper>
    );

  return <></>;
};
