import { useForm } from "react-hook-form";
import { memo } from "react";

import { Modal } from "@components/Modal";
import { Labeled } from "@components/Labeled";
import { FormInput } from "@components/FormInput";

export const ProgramTitleEditModal = memo(
  ({ onClose, onSubmit, defaultTitle }: ProgramTitleEditModalProps) => {
    const { control, handleSubmit } = useForm<TitleInput>({
      defaultValues: { title: defaultTitle },
    });

    return (
      <Modal
        title={"Nazwij program"}
        onSubmit={() => handleSubmit(d => onSubmit(d))()}
        onClose={onClose}
      >
        <Labeled className="m-7" label={"Nowa nazwa programu"}>
          <FormInput name={"title"} control={control} />
        </Labeled>
      </Modal>
    );
  },
);

interface ProgramTitleEditModalProps {
  onClose: () => void;
  onSubmit: (data: TitleInput) => void;
  defaultTitle: string;
}

export interface TitleInput {
  title: string;
}
