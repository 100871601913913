import { AutocompleteProps, TextField } from "@mui/material";

import { Scales } from "@assets/icons";
import { MacrosText } from "@components/MacrosText";
import { TagWithIcon } from "@components/TagWithIcon";
import { Nutrient } from "@typeDefinitions";

import {
  AutocompleteStyled,
  AutocompleteWrapper,
  TagLabel,
  TextFieldStyled,
} from "./SearchAutocomplete.styled";
import { useAppTranslation } from "@hooks";
import { ReactNode } from "react";

interface SearchAutocompleteProps
  extends Omit<
    AutocompleteProps<OptionsIf, false, true, false>,
    "renderInput"
  > {
  onSearch?: (query: string) => void;
  grams: number;
  helperText?: ReactNode;
}
export const SearchAutocomplete = ({
  onSearch,
  value,
  grams,
  helperText,
  ...rest
}: SearchAutocompleteProps) => {
  const { t } = useAppTranslation();
  return (
    <AutocompleteWrapper>
      <AutocompleteStyled
        size="small"
        disableClearable
        renderInput={params => (
          <TextFieldStyled
            {...params}
            onChange={e => onSearch && onSearch(e.target.value)}
            placeholder={t("diet.exchange_modal.select")}
            helperText={helperText}
          />
        )}
        filterOptions={o => o}
        value={value ?? undefined}
        {...rest}
      />

      <div className="flex gap-[8px]">
        <MacrosText
          nutrients={value?.nutrients ?? []}
          config={{ zeros: true }}
        />
        <TagWithIcon label={<TagLabel>{grams} g</TagLabel>} Icon={Scales} />
      </div>
    </AutocompleteWrapper>
  );
};
export interface OptionsIf {
  id: string;
  label: string;
  nutrients: Nutrient[];
  category?: string;
}
