import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";

export async function clearSchedule(
  programId: number,
): Promise<ApiResponse<void>> {
  return await fetchData(
    `/dietitian/programs/${programId}/schedule/days/clear`,
    APIMethods.PUT,
  );
}
