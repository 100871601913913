import { createContext, useContext } from "react";

import { CreatorDietMealRecipeDto } from "@client/diets/creator";
import { Lang } from "@hooks";
import {
  ClientContextIf,
  Measure,
  Nutrient,
  Translation,
} from "@typeDefinitions";
import { OptionIf } from "@components/Filters";
import { getTranslation } from "@utils/translations";
import { NewRecipeDto } from "@client";

export interface ProductsContextIf {
  addProduct: (data: ProductItemIf) => void;
  getNutrients: (id: number) => Nutrient[];
  products: Map<number, ProductItemIf>;
  productsOptions: ProductOptionIf[];
}

export interface ProductOptionIf extends OptionIf {
  nutrients: Nutrient[];
  clientContext?: ClientContextIf;
  alreadyInDiet?: boolean;
}

export interface MeasureExtended extends Measure {
  sortOrder: number;
}
export interface ProductItemIf {
  foodId: number;
  nutrients: Nutrient[];
  translations: Translation[];
  measures: MeasureExtended[];
  clientContext?: ClientContextIf;
}

export const ProductsContext = createContext<ProductsContextIf | undefined>(
  undefined,
);

export const useProductsContext = () => {
  const context = useContext(ProductsContext);

  if (!context) {
    throw new Error("useProductsContext must be used within a ProductsContext");
  }

  return context;
};

export const mapDefaultProductsContext = (
  data?: CreatorDietMealRecipeDto,
): Map<number, ProductItemIf> | undefined => {
  if (!data) return;

  const productMap = new Map<number, ProductItemIf>();

  data.recipe.foodRecipe.forEach(({ food }) => {
    const productItem: ProductItemIf = {
      foodId: food.id,
      nutrients: food.nutrients.map(nutrient => ({
        id: nutrient.id,
        value: nutrient.value,
      })),
      translations: [
        { lang: Lang.PL, name: food.descriptionPl },
        { lang: Lang.EN, name: food.description },
      ],
      measures: food.measures.map(({ id, grams, sortOrder }) => ({
        id,
        grams,
        sortOrder,
      })),
    };

    if (!productMap.has(food.id)) productMap.set(food.id, productItem);
  });

  return productMap;
};
export const mapScheduleDefaultProductsContext = (
  data?: NewRecipeDto,
): Map<number, ProductItemIf> | undefined => {
  if (!data) return;

  const productMap = new Map<number, ProductItemIf>();

  data.foodRecipe.forEach(({ food }) => {
    const productItem: ProductItemIf = {
      foodId: food.id,
      nutrients: food.nutrients.map(nutrient => ({
        id: nutrient.id,
        value: nutrient.value,
      })),
      translations: [
        { lang: Lang.PL, name: food.descriptionPl ?? "" },
        { lang: Lang.EN, name: food.description ?? "" },
      ],
      measures: food.measures.map(({ id, grams, sortOrder }) => ({
        id,
        grams,
        sortOrder,
      })),
    };

    if (!productMap.has(food.id)) productMap.set(food.id, productItem);
  });

  return productMap;
};

export const mapProductsOptions = (
  products: Map<number, ProductItemIf> | undefined,
  lang: string,
): ProductOptionIf[] => {
  if (!products) return [];

  const options: ProductOptionIf[] = [];

  products.forEach(({ foodId, translations, nutrients, clientContext }) => {
    const translation = getTranslation(translations, lang);
    if (translation) {
      options.push({
        id: foodId.toString(),
        label: translation,
        nutrients: nutrients,
        clientContext: clientContext,
      });
    }
  });

  return options;
};
