import { Box } from "@mui/material";
import { ScheduleVisitModalLabel } from "../ScheduleVisitModal.styled";
import { FormAutocomplete } from "@components/FormAutocomplete";
import { FormTextField } from "@components/FormTextField";
import { useFormContext } from "react-hook-form";
import { AddEventModalInput } from "../_hooks/useScheduleVisitForm";
import { CheckedIcon } from "@assets/icons";
import type { SelectOption } from "@components/FormAutocomplete";
import type { FC } from "react";
import { useAppTranslation } from "@hooks";
interface ScheduleModalPriceSectionProps {
  onlyDates: boolean;
  currenciesMapped?: SelectOption[];
}

export const ScheduleModalPriceSection: FC<ScheduleModalPriceSectionProps> = ({
  currenciesMapped,
  onlyDates,
}) => {
  const { t } = useAppTranslation();
  const { control } = useFormContext<AddEventModalInput>();

  return (
    <Box>
      <ScheduleVisitModalLabel>{t("calendar.price")}</ScheduleVisitModalLabel>
      <Box display="flex" gap={1}>
        <FormAutocomplete
          control={control}
          name="currency"
          id="currency"
          size="small"
          popupIcon={<CheckedIcon className="text-gray-450" />}
          className="w-[100px]"
          options={currenciesMapped ?? []}
          disabled={onlyDates}
        />
        <FormTextField
          control={control}
          name="price"
          type="number"
          size="small"
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          disabled={onlyDates}
        />
      </Box>
    </Box>
  );
};
