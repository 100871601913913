import { TagResourceDto, fetchDietCreatorTags } from "@client/diets/creator";
import { QueryOptionsTyped } from "@hooks/queries/types";
import { useQuery } from "@tanstack/react-query";
import { ApiResponse } from "@typeDefinitions";

export const fetchDietCreatorTagsQueryKey = "fetchDietCreatorTagsQueryKey";

export const useFetchDietCreatorTagsQuery = (
  dietId: string,
  options?: QueryOptionsTyped<ApiResponse<TagResourceDto[]>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchDietCreatorTagsQueryKey, dietId],
    () => fetchDietCreatorTags(dietId),
    options,
  );

  return {
    data: data?.data,
    ...rest,
  };
};
