import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useAppTranslation } from "@hooks";
import { buildDefaultQueryOptions } from "../common";
import { fetchUsedRecipesQueryKey } from "./useFetchUsedRecipesQuery";
import { fetchDietitianProgramScheduleKey } from "./useFetchDietitianProgramScheduleQuery";
import { fetchDietitianClientProgramScheduleKey } from "./useFetchDietitianClientProgramScheduleQuery";
import {
  deleteProgramDayMealRecipe,
  ProgramDayMealRecipeUpdatePayload,
  updateProgramDayMealRecipe,
} from "@client/schedule";
import { MutationOptions } from "@hooks/queries";

export const useProgramDayMealRecipeMutation = (
  programId: number,
  dayId: number,
) => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();

  return useMutation(
    ({ mealId, ...payload }: ProgramDayMealRecipeUpdateExtendedPayload) =>
      updateProgramDayMealRecipe(programId, dayId, mealId, payload),
    buildDefaultQueryOptions({
      errorToastMessage: t("patient.schedule.update_error"),
      onSuccess: () => {
        queryClient.invalidateQueries([fetchUsedRecipesQueryKey]);
        queryClient.invalidateQueries([fetchDietitianProgramScheduleKey]);
        queryClient.invalidateQueries([fetchDietitianClientProgramScheduleKey]);
      },
    }),
  );
};

export const useDeleteProgramDayMealRecipeMutation = (
  programId: number,
  dayId: number,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (mealId: number) => deleteProgramDayMealRecipe(programId, dayId, mealId),
    {
      ...options,
      onSuccess: () => {
        options?.onSuccess && options.onSuccess();
        queryClient.invalidateQueries([fetchUsedRecipesQueryKey]);
        queryClient.invalidateQueries([fetchDietitianProgramScheduleKey]);
        queryClient.invalidateQueries([fetchDietitianClientProgramScheduleKey]);
      },
    },
  );
};

interface ProgramDayMealRecipeUpdateExtendedPayload
  extends ProgramDayMealRecipeUpdatePayload {
  mealId: number;
}
