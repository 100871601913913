import { useMutation, useQueryClient } from "@tanstack/react-query";
import { patientKeys } from "@hooks/queries/client/patientKeys";
import {
  updatePatientShowNutrients,
  UpdatePatientShowNutrientsParams,
  UpdatePatientShowNutrientsPayload,
} from "@client/patient/nutrients/updatePatientShowNutrients";
import { FetchPatientShowNutrientsResponse } from "@client/patient/nutrients/fetchPatientShowNutrients";
import { patientNutrientKeys } from "@hooks/queries/client/nutrient/patientNutrientKeys";

const useUpdatePatientShowNutrientsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      params,
      payload,
    }: {
      params: UpdatePatientShowNutrientsParams;
      payload: UpdatePatientShowNutrientsPayload;
    }) => {
      return updatePatientShowNutrients(params, payload);
    },
    onSuccess: async (_, variables) => {
      queryClient.setQueryData(
        patientNutrientKeys.show(variables.params.patientId),
        (oldData: FetchPatientShowNutrientsResponse | undefined) => {
          if (oldData === undefined) {
            return undefined;
          }

          return {
            ...oldData,
            data: variables.payload,
          };
        },
      );
    },
  });
};

export default useUpdatePatientShowNutrientsMutation;
