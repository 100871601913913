import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const SpeechBubble = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="16" height="15" viewBox="0 0 16 15">
      <path
        d="M8.00001 0.5C9.47918 0.520833 10.8229 0.8125 12.0313 1.375C13.2188 1.95833 14.1667 2.73958 14.875 3.71875C15.5833 4.69792 15.9479 5.79167 15.9688 7C15.9479 8.20833 15.5833 9.30208 14.875 10.2812C14.1667 11.2604 13.2188 12.0417 12.0313 12.625C10.8229 13.1875 9.47918 13.4792 8.00001 13.5C6.95835 13.5 5.9896 13.3542 5.09376 13.0625C4.6146 13.3958 4.01043 13.7188 3.28126 14.0312C2.5521 14.3229 1.70835 14.4792 0.750014 14.5C0.437514 14.4792 0.208348 14.3229 0.0625144 14.0312C-0.0624856 13.7396 -0.0104023 13.4688 0.218764 13.2188C0.239598 13.1979 0.416681 12.9688 0.750014 12.5312C1.08335 12.1146 1.38543 11.5833 1.65626 10.9375C0.593764 9.83333 0.041681 8.52083 1.43612e-05 7C0.0208477 5.79167 0.385431 4.69792 1.09376 3.71875C1.8021 2.73958 2.76043 1.95833 3.96876 1.375C5.15626 0.8125 6.50001 0.520833 8.00001 0.5ZM8.00001 12C9.83335 11.9583 11.3646 11.4688 12.5938 10.5312C13.8229 9.59375 14.4583 8.41667 14.5 7C14.4583 5.58333 13.8229 4.40625 12.5938 3.46875C11.3646 2.53125 9.83335 2.04167 8.00001 2C6.16668 2.04167 4.63543 2.53125 3.40626 3.46875C2.1771 4.40625 1.54168 5.58333 1.50001 7C1.52085 7.66667 1.65626 8.23958 1.90626 8.71875C2.1771 9.21875 2.45835 9.61458 2.75001 9.90625L3.37501 10.5938L3.06251 11.4688C2.87501 11.9271 2.66668 12.3542 2.43751 12.75C3.18751 12.4792 3.78126 12.1771 4.21876 11.8438L4.84376 11.4062L5.56251 11.625C6.35418 11.875 7.16668 12 8.00001 12Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
