import { QueryOptionsTyped } from "@hooks/queries";
import { useQuery } from "@tanstack/react-query";
import {
  fetchPatientNormNutrients,
  FetchPatientNormNutrientsParams,
  FetchPatientNormNutrientsResponse,
} from "@client/patient/nutrients/fetchPatientNormNutrients";
import { patientKeys } from "@hooks/queries/client/patientKeys";
import { patientNutrientKeys } from "@hooks/queries/client/nutrient/patientNutrientKeys";

const useFetchPatientNormNutrients = (
  params: FetchPatientNormNutrientsParams,
  options?: QueryOptionsTyped<FetchPatientNormNutrientsResponse>,
) => {
  return useQuery({
    queryKey: patientNutrientKeys.norm(params.patientId),
    queryFn: () => fetchPatientNormNutrients(params),
    ...options,
  });
};

export default useFetchPatientNormNutrients;
