import { Dialog, IconButton, styled } from "@mui/material";
import CloseIcon from "@assets/icons_new/closeClean.svg?react";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";

interface PurchaseSuccessModalProps {
  open: boolean;
  onClose: () => void;
}
export const PurchaseSuccessModal = ({
  open,
  onClose,
}: PurchaseSuccessModalProps) => {
  const { account } = useFetchDietitianAccountQuery();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionProps={{ unmountOnExit: true }}
    >
      <DialogWrapper>
        <IconButtonStyled onClick={onClose}>
          <CloseIcon />
        </IconButtonStyled>
        <img src="static/pricing/success.png" width={180} height={163} />

        <Wrapper>
          <Title>Dziękujemy za zakup!</Title>
          <Text>
            Twój pakiet jest już aktywny i możesz z niego korzystać. Fakturę
            otrzymasz w ciągu 48 godzin na wskazany adres email.
          </Text>
          <EmailWrapper>
            <EmailTextBold>Adres email:</EmailTextBold>
            <EmailText>{account?.email}</EmailText>
          </EmailWrapper>
        </Wrapper>
      </DialogWrapper>
    </Dialog>
  );
};

const DialogWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  width: 552px;
  padding: 56px 24px 24px;
  position: relative;
`;
const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  width: 552px;
`;

const Title = styled("span")`
  font-family: Figtree;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;

const Text = styled("span")`
  font-family: Figtree;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.4px;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

const EmailWrapper = styled("div")`
  display: flex;
  gap: 6px;
  min-width: 313px;
  padding: 16px;
  border-radius: 8px;
  background: ${({ theme }) => theme.palette.primary.light};
`;

const EmailText = styled("div")`
  font-family: Figtree;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

const EmailTextBold = styled(EmailText)`
  font-weight: 600;
`;

const IconButtonStyled = styled(IconButton)`
  position: absolute;
  top: 16px;
  right: 16px;
`;
