import { defaultFilters } from "@components/DietsFilters/dietsFiltersUtils";
import {
  DEFAULT_MACROS_RANGE,
  RecipesFiltersFormInput,
} from "@components/RecipesFiltersNew";
import { useTagsNew } from "@hooks/useTagsNew";
import { TagCategoryType } from "@utils/tagsNew";
import { compact, isEqual } from "lodash";
import { useState } from "react";
import { AlertButton, FiltersInfoAlert } from "./common.styled";
import { useAppTranslation } from "@hooks";

interface FiltersAlertProps {
  defaultFilters: RecipesFiltersFormInput | undefined;
  onClear: () => void;
}
export const FiltersAlert = ({
  defaultFilters,
  onClear,
}: FiltersAlertProps) => {
  const { getTagCategoryOptions } = useTagsNew();
  const { t } = useAppTranslation();
  const defaultNutrientsFilters =
    !!defaultFilters?.nutrients &&
    !isEqual(defaultFilters.nutrients, DEFAULT_MACROS_RANGE)
      ? t("diet.energy")
      : undefined;
  const defualtDietTypeFilers = defaultFilters?.tags?.some(tag =>
    getTagCategoryOptions(TagCategoryType.DIET_TYPE)?.some(
      ({ id }) => id === tag,
    ),
  )
    ? t("diet.diet_type")
    : undefined;
  const defualtMealTypeFilers = defaultFilters?.tags?.some(tag =>
    getTagCategoryOptions(TagCategoryType.MEAL)?.some(({ id }) => id === tag),
  )
    ? t("diet.meal_type")
    : undefined;

  const [show, setShow] = useState(
    !!defaultNutrientsFilters ||
      !!defualtDietTypeFilers ||
      !!defualtMealTypeFilers,
  );

  const appliedFilters = [
    defaultNutrientsFilters,
    defualtDietTypeFilers,
    defualtMealTypeFilers,
  ];
  const handleClear = () => {
    onClear();
    setShow(false);
  };
  if (!show) return null;

  return (
    <FiltersInfoAlert
      severity="info"
      variant="filled"
      action={
        <AlertButton color="info" onClick={handleClear}>
          {t("diet.clear_filters")}
        </AlertButton>
      }
    >
      {t("diet.filters_applied")}:{" "}
      {compact(appliedFilters).join(", ").toLocaleLowerCase()}
    </FiltersInfoAlert>
  );
};
