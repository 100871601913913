import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 2rem;
  border-bottom-width: 1px;
  border-color: rgba(184, 183, 179, 1);
`;

export const ContentSection = styled.span`
  display: flex;
  align-items: center;
  font-size: 0.75rem;
`;

export const RedPoint = styled.span`
  border-radius: 0.25rem;
  background-color: rgba(252, 72, 72, 1);
  width: 0.25rem;
  height: 0.25rem;
  margin-right: 0.25rem;
`;
