import { Card, styled } from "@mui/material";

export const CardStyled = styled(Card)`
  padding: 16px;
  border-radius: 16px;
  box-shadow: ${({ theme }) => theme.boxShadows.card};
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Header = styled("div")`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.palette.primary.light};
`;

export const Title = styled("span")`
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const NormText = styled("span")`
  font-family: Figtree;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;
