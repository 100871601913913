import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const CheckboxChecked = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      style={{ fill: "none" }}
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4Z"
        fill="#7448D0"
      />
      <path
        d="M12.5742 5.17578C12.6914 5.30599 12.75 5.45573 12.75 5.625C12.75 5.79427 12.6914 5.94401 12.5742 6.07422L7.57422 11.0742C7.44401 11.1914 7.29427 11.25 7.125 11.25C6.95573 11.25 6.80599 11.1914 6.67578 11.0742L4.17578 8.57422C4.05859 8.44401 4 8.29427 4 8.125C4 7.95573 4.05859 7.80599 4.17578 7.67578C4.30599 7.55859 4.45573 7.5 4.625 7.5C4.79427 7.5 4.94401 7.55859 5.07422 7.67578L7.10547 9.74609L11.6758 5.17578C11.806 5.05859 11.9557 5 12.125 5C12.2943 5 12.444 5.05859 12.5742 5.17578Z"
        fill="white"
      />
    </SvgIcon>
  );
};
