import MacroCircularProgressChart from "@components/MacroCircularProgress/MacroCircularProgressChart";
import { Box } from "@mui/material";
import { MacroCircularProgressProps } from "@components/MacroCircularProgress/MacroCircularProgress";

type Props = {
  kcal: MacroCircularProgressProps;
  protein: MacroCircularProgressProps;
  carb: MacroCircularProgressProps;
  fat: MacroCircularProgressProps;
};

const MacrosCircularProgresses = ({ kcal, protein, carb, fat }: Props) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap="16px"
      justifyContent="space-between"
    >
      <MacroCircularProgressChart {...kcal} />
      <MacroCircularProgressChart {...protein} />
      <MacroCircularProgressChart {...fat} />
      <MacroCircularProgressChart {...carb} />
    </Box>
  );
};

export default MacrosCircularProgresses;
