import { MouseEvent, useCallback, useState } from "react";

import { IconButton, Popover, useTheme } from "@mui/material";

import { ThreeGreyPoints } from "@assets/icons";
import { useAppTranslation } from "@hooks";

import { SettingsModalWrapper, StyledButton } from "./ActionsButtons.styled";
import { ConfirmationWrapper } from "@components/ConfirmationWrapper";

interface ActionButtonsProps {
  id: number;
  openEditModal: () => void;
  setEditId: (id?: number) => void;
  deleteMutation: (id: number) => void;
  disableDelete?: boolean;
  deactivateDietitian?: () => void;
}

export const ActionButtons = ({
  id,
  openEditModal,
  setEditId,
  deleteMutation,
  disableDelete = false,
  deactivateDietitian,
}: ActionButtonsProps) => {
  const { t } = useAppTranslation();
  const { colors } = useTheme();
  const [anchorEl, setAnchorEl] = useState<EventTarget & HTMLButtonElement>();
  const handleOpenActionsModal = (e: MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(e.currentTarget);

  const handleCloseActions = useCallback(() => setAnchorEl(undefined), []);
  const handleDelete = useCallback(() => {
    deleteMutation(id);
    handleCloseActions();
  }, [deleteMutation, id]);

  const handleEdit = useCallback(() => {
    handleCloseActions();
    openEditModal();
    setEditId(parseInt(id.toString() ?? ""));
  }, [openEditModal, setEditId]);
  return (
    <div>
      <IconButton onClick={handleOpenActionsModal}>
        <ThreeGreyPoints size="h-4" />
      </IconButton>

      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleCloseActions}
      >
        <SettingsModalWrapper>
          <StyledButton onClick={deactivateDietitian} color="inherit">
            {t("common.deactivate")}
          </StyledButton>
          <StyledButton color="inherit" onClick={handleEdit}>
            {t("common.edit")}
          </StyledButton>
          <ConfirmationWrapper
            onConfirm={handleDelete}
            confirmationText={t("dietitians_settings.confirm_delete_tooltip")}
            submitText={t("common.yes")}
          >
            <StyledButton
              className="w-full"
              color="inherit"
              disabled={disableDelete}
            >
              {t("common.delete")}
            </StyledButton>
          </ConfirmationWrapper>
        </SettingsModalWrapper>
      </Popover>
    </div>
  );
};
