import { useContext, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

import { Button, useMediaQuery, useTheme } from "@mui/material";

import { List } from "@assets/icons";
import { GroupIf } from "@components/TableOfContents";
import { TableOfContentsContext } from "@context/TableOfContentsContext";
import { ApiResponse } from "@typeDefinitions";
import { FetchSingedSurveysResponse } from "@client/surveys";
import { fetchSingedSurveysQueryKey } from "@hooks/queries/surveys";

import { useParseUrl } from "../useParseUrl";
import {
  ButtonStretched,
  ButtonIconStyled,
  NavigationBarWrapper,
  NavigationBarWrapperMobile,
  ButtonStyled,
} from "./NavigationBar.styled";
import { TableOfContentsPopover } from "@components/TableOfContentsPopover";
import { PointerArrowLeft, PointerArrowRight } from "@assets/icons/Arrows";
import { ValidationContext } from "../context";
import { useAppTranslation } from "@hooks";

interface NavigationBarProps {
  groups: GroupIf[];
}

export const NavigationBar = ({ groups }: NavigationBarProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { breakpoints } = useTheme();
  const downSmall = useMediaQuery(`${breakpoints.down("smallNew")}`);
  const { subgroupId, setSubgroupId, setGroupId, setSelectedId, selectedId } =
    useContext(TableOfContentsContext);
  const { handleSubmit } = useContext(ValidationContext);
  const { url } = useParseUrl();
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData<
    ApiResponse<FetchSingedSurveysResponse>
  >([fetchSingedSurveysQueryKey, url]);
  const subgroupsList =
    data?.data.survey.groups.flatMap(g =>
      g.sections.map(s => ({ id: s.id, groupId: g.id })),
    ) ?? [];

  const currentSubgroupIndex = subgroupsList.findIndex(
    s => s.id === subgroupId,
  );
  const isSubgroupSelected = selectedId.split("-").length === 2;
  const hasNext =
    currentSubgroupIndex !== -1 &&
    currentSubgroupIndex + 1 < subgroupsList.length;
  const hasPrev = currentSubgroupIndex !== -1 && currentSubgroupIndex > 0;
  const isSend = !hasNext && isSubgroupSelected;

  const handleNext = () => {
    if (!subgroupsList.length || !subgroupsList[currentSubgroupIndex + 1])
      return;
    if (!subgroupId) return setSubgroupId(subgroupsList[0].id);

    const { id, groupId } = subgroupsList[currentSubgroupIndex + 1];

    setSubgroupId(id);
    setGroupId(groupId);
    setSelectedId(`${groupId}-${id}`);
  };

  const handlePrev = () => {
    if (
      !subgroupsList.length ||
      !subgroupId ||
      !subgroupsList[currentSubgroupIndex - 1]
    )
      return;

    const { id, groupId } = subgroupsList[currentSubgroupIndex - 1];

    setSubgroupId(id);
    setGroupId(groupId);
    setSelectedId(`${groupId}-${id}`);
  };

  if (downSmall)
    return (
      <NavigationBarWrapperMobile>
        <TableOfContentsPopover
          groups={groups}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
        <ButtonIconStyled
          variant="outlined"
          onClick={e => setAnchorEl(e.currentTarget)}
        >
          <List />
        </ButtonIconStyled>
        <ButtonStretched
          variant="outlined"
          disabled={!hasPrev}
          onClick={handlePrev}
          startIcon={<PointerArrowLeft />}
        >
          {t("questionnaires.previous")}
        </ButtonStretched>
        <ButtonStretched
          variant="contained"
          onClick={isSend ? handleSubmit : handleNext}
          endIcon={<PointerArrowRight />}
        >
          {isSend ? t("common.send") : t("questionnaires.next")}
        </ButtonStretched>
      </NavigationBarWrapperMobile>
    );

  return (
    <NavigationBarWrapper>
      <ButtonStyled
        variant="outlined"
        disabled={!hasPrev}
        onClick={handlePrev}
        startIcon={<PointerArrowLeft />}
      >
        {t("questionnaires.previous")}
      </ButtonStyled>
      <ButtonStyled
        variant="contained"
        onClick={isSend ? handleSubmit : handleNext}
        endIcon={<PointerArrowRight />}
      >
        {isSend ? t("common.send") : t("questionnaires.next")}
      </ButtonStyled>
    </NavigationBarWrapper>
  );
};
