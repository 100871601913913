import { useQuery } from "@tanstack/react-query";

import { fetchClientMeasurementsCharts } from "@client/fetchClientMeasurementsChart";

export const fetchClientMeasurementsChartQueryKey =
  "fetchClientMeasurementsChartQueryKey";

export const useFetchClientMeasurementsChart = (id: string) => {
  return useQuery([fetchClientMeasurementsChartQueryKey, id], () =>
    fetchClientMeasurementsCharts(id),
  );
};
