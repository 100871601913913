import { useController, useFormContext } from "react-hook-form";

import { InputAdornment } from "@mui/material";

import { TrashNew } from "@assets/icons";
import { ModalWrapper } from "@components/ModalWrapperNew";
import { decimalFourDigitsInput } from "@utils/inputs";
import { useAppTranslation, useModalState, useNutrients } from "@hooks";

import { Label } from "../common/Label";
import {
  DeleteModalWrapper,
  FormTextFieldStyled,
  ModalText,
  ModalTextBold,
  SelectedNutrientWrapper,
  TextFieldStyled,
  TrashButton,
} from "./SelectedNutrient.styled";
import { NutrientNormInputs } from "./useNutrientsNormForm";
import { useAutosaveOnBlurContext } from "@components/AutosaveOnBlur";

interface SelectedNutrientProps {
  nutrientId: number;
  formIdx: number;
  showLabel?: boolean;
}
export const SelectedNutrient = ({
  nutrientId,
  formIdx,
  showLabel,
}: SelectedNutrientProps) => {
  const { t } = useAppTranslation();
  const { nutrientDict } = useNutrients();
  const nutrient = nutrientDict.get(nutrientId);
  const [open, onOpen, onClose] = useModalState();
  const { onBlur, onFocus } = useAutosaveOnBlurContext();

  const { control } = useFormContext<NutrientNormInputs>();

  const {
    field: { onChange: onHide },
  } = useController<NutrientNormInputs>({
    control,
    name: `nutrients.${formIdx}.show`,
  });

  const handleDelete = () => {
    onHide(false);
    onClose();
    onBlur();
  };

  return (
    <SelectedNutrientWrapper>
      <Label flex={2} label={!!showLabel && t("diet.name")}>
        <TextFieldStyled
          fullWidth
          size="small"
          InputProps={{ readOnly: true }}
          value={nutrient?.name}
        />
      </Label>
      <Label label={!!showLabel && t("diet.norm")}>
        <FormTextFieldStyled
          control={control}
          name={`nutrients.${formIdx}.value`}
          key={`nutrients.${formIdx}.value`}
          fullWidth
          size="small"
          InputProps={{
            inputComponent: decimalFourDigitsInput,
            endAdornment: (
              <InputAdornment position="end">{nutrient?.unit}</InputAdornment>
            ),
          }}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </Label>
      <TrashButton color="primary" onClick={onOpen} onFocus={onFocus}>
        <TrashNew />
      </TrashButton>

      <ModalWrapper
        title={t("diet.delete_nutrient.title")}
        open={open}
        onClose={onClose}
        onSubmit={handleDelete}
        color="error"
        confirmText={t("common.delete")}
      >
        <DeleteModalWrapper>
          <ModalText>
            {t("diet.delete_nutrient.question")}:{" "}
            <ModalTextBold>{nutrientDict.get(nutrientId)?.name}</ModalTextBold>?
          </ModalText>
        </DeleteModalWrapper>
      </ModalWrapper>
    </SelectedNutrientWrapper>
  );
};
