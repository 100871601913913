import React from "react";

import { Nutrient, ProgramDietDaySummary } from "@typeDefinitions";
import { useAppTranslation, useBasicNutrientsGetter } from "@hooks";
import { TAG_COLORS } from "@utils/constants";
import { Button } from "@components/Button";

import { WEEK_DAYS_SHORT_MAP } from "../../../common";
import { PCFValue } from "../../PCFValue";
import { ColoredCircle } from "../../ColoredCircle";
import { ProgramSummaryTitleWrapper } from "./ProgramSummaryTitleWrapper.styled";

export const ProgramSummaryTableTitle = (
  props: ProgramSummaryTableTitleProps,
) => {
  const {
    index,
    dietName,
    frequency,
    dayName,
    targetNutrients,
    useEditButton,
    dayId,
    dietId,
    programId,
  } = props;

  const { t } = useAppTranslation();

  const { kcal, protein, fat, carbs } =
    useBasicNutrientsGetter(targetNutrients);

  return (
    <ProgramSummaryTitleWrapper>
      <div className="flex items-center">
        <ColoredCircle index={index}>{index + 1}</ColoredCircle>
        <div className="ml-4 font-semibold">
          {dietName?.length ? (
            <>{dietName}</>
          ) : (
            t("patient.schedule.unnamed_diet")
          )}
        </div>
        <div className="mx-4 border-r border-gray-400 h-7" />
        <ColoredCircle index={index}>
          {dayName?.slice(0, 1).toUpperCase()}
        </ColoredCircle>
        <div className="ml-4" style={{ color: TAG_COLORS[index] }}>
          {dayName}
        </div>
        {!!(protein || fat || carbs) && (
          <>
            <div className="mx-4 border-r border-gray-400 h-7" />
            <div className="flex items-center">
              <span className="mr-1">
                {t("programs.target_values_short")}
                {": "}
              </span>
              {!!kcal && (
                <div className="mr-3 flex items-center">
                  <PCFValue value={kcal} name="kcal" />
                </div>
              )}
              {protein && (
                <div className="mr-3 flex items-center">
                  <PCFValue noLongNames value={protein} name="protein" />
                </div>
              )}
              {fat && (
                <div className="mr-3 flex items-center">
                  <PCFValue noLongNames value={fat} name="fats" />
                </div>
              )}
              {carbs && (
                <div className="mr-3 flex items-center">
                  <PCFValue noLongNames value={carbs} name="carbs" />
                </div>
              )}
            </div>
          </>
        )}
        {!!frequency?.length && (
          <>
            <div className="mx-4 border-r border-gray-400 h-7" />
            <div>
              {t("patient.schedule.days")}
              <span className="font-semibold">
                {frequency?.length === 7
                  ? t("patient.schedule.daily")
                  : frequency
                      ?.filter(day => !!WEEK_DAYS_SHORT_MAP[day])
                      ?.map(day => WEEK_DAYS_SHORT_MAP[day]?.toLowerCase())
                      ?.join(", ")}
              </span>
            </div>
          </>
        )}
      </div>
      {useEditButton && (
        <Button
          size="small"
          color="secondary"
          to={`/programs/${programId}/days/${dayId}/diet/${dietId}/edit/recipes`}
          className="step-six ml-2 mr-6"
        >
          {t("patient.schedule.edit_meals")}
        </Button>
      )}
    </ProgramSummaryTitleWrapper>
  );
};

interface ProgramSummaryTableTitleProps {
  /** Order of the diet day type */
  index: number;
  dietName?: string;
  dayName?: string;
  dayId: number;
  programId: number;
  dietId: number;
  frequency?: ProgramDietDaySummary["frequency"];
  targetNutrients?: Nutrient[];
  useEditButton?: boolean;
}
