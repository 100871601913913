import {
  Layout,
  Value,
  Label,
} from "@components/Client/BodyMeasurementsTabs/BodyMeasurementItem.styled";
import { useMemo } from "react";
import { BodyMeasurementConst } from "@consts/BodyMeasurementConst";
import { calculateBmi } from "@views/dietician/PatientEnergyBalance";
import { PatientMeasurementResource } from "@client/resources/PatientMeasurementResource";

type BMIProps = {
  patientBodyMeasurement: Pick<
    PatientMeasurementResource,
    "bodyMeasurements"
  > | null;
};

const BMI = ({ patientBodyMeasurement }: BMIProps) => {
  const bmi = useMemo(() => {
    if (!patientBodyMeasurement) {
      return "-";
    }

    const getMeasurementValue = (id: number) => {
      return (
        patientBodyMeasurement?.bodyMeasurements.find(
          measurement => measurement.body.id === id,
        )?.value ?? null
      );
    };

    const weight = getMeasurementValue(BodyMeasurementConst.weight);
    const growth = getMeasurementValue(BodyMeasurementConst.height);
    if (!weight || !growth) {
      return "-";
    }

    return calculateBmi(weight, growth).toFixed(2);
  }, [patientBodyMeasurement]);

  return (
    <Layout>
      <Label>BMI</Label>
      <Value>{bmi}</Value>
    </Layout>
  );
};

export default BMI;
