import { GridStateColDef } from "@mui/x-data-grid";
import { RecipeRowIf } from "../RecipesDataGrid";
import { CustomHeaderWrapper } from "./CustomHeader.styled";
import { useAppTranslation } from "@hooks";

export const CustomHeader = ({
  computedWidth,
}: GridStateColDef<RecipeRowIf, any, any>) => {
  const { t } = useAppTranslation();

  return (
    <CustomHeaderWrapper width={computedWidth}>
      <span>{t("recipes.recipe_name")}</span>
      <span>{t("recipes.recipe_tags")}</span>
      <span>{t("recipes.actions")}</span>
    </CustomHeaderWrapper>
  );
};
