import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  colors,
  styled,
} from "@mui/material";

export const AccordionStyled = styled(Accordion)`
  &.MuiAccordion-root {
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.neutral.light[200]};
    background: ${({ theme }) => theme.colors.neutral.light[100]};
    box-shadow: none;

    &.MuiPaper-root.MuiAccordion-root.Mui-expanded {
      margin: 0;
    }
  }

  :before {
    display: none;
  }
`;

export const AccordionDetailsStyled = styled(AccordionDetails)`
  padding: 0;
`;

export const AccordionSummaryStyled = styled(AccordionSummary)`
  padding: 16px;

  & .Mui-expanded {
    margin: 0;
  }

  &.MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-gutters {
    min-height: unset;
  }
  & .MuiAccordionSummary-content.Mui-expanded {
    min-height: unset;
    margin: 0;
  }
  & .MuiAccordionSummary-content {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.neutral.dark[800]};
    margin: 0;
  }

  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
  }
`;

export const Label = styled("span")`
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const TagIconWrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const TabsWrapper = styled("div")`
  display: flex;
  align-items: center;
  padding: 0 16px;
`;
