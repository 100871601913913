import { useMemo } from "react";
import { useFetchNormsQuery } from "./queries";
import { useAppTranslation } from "./useAppTranslation";

export const useNorms = () => {
  const { isPolishChosen } = useAppTranslation();
  const { norms } = useFetchNormsQuery();

  const mappedNorms = useMemo<GroupOptionIf[]>(
    () =>
      norms?.flatMap(normLocation =>
        normLocation.normAge.map(norm => ({
          id: norm.id.toString(),
          label: isPolishChosen ? norm.name : norm.nameEn,
          group: normLocation.name,
        })),
      ) ?? [],
    [norms, isPolishChosen],
  );

  return {
    options: mappedNorms,
  };
};

export interface GroupOptionIf {
  id: string;
  label: string;
  group: string;
}
