import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import * as yup from "yup";

export interface CustomTag {
  id: number;
  isSystem: boolean;
  name: string;
  color: string;
}

export async function fetchCustomTags(): Promise<ApiResponse<CustomTag[]>> {
  const response = await fetchData(
    `/dietitian/custom-tags`,
    APIMethods.GET,
    undefined,
  );

  return await fetchCustomTagResponseSchema.validate(response);
}

export const customTagSchema = yup.object().shape({
  id: yup.number().required(),
  isSystem: yup.boolean().required(),
  name: yup.string().required(),
  color: yup.string().required(),
});

export const fetchCustomTagResponseSchema = yup.object().shape({
  data: yup.array().of(customTagSchema).required(),
});
