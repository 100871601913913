import { WORK_IN_PROGRESS } from "@routes";
import axios from "@services/axios";

/**
 * Loads token to prevent CSRF attacks
 */
export const getCSRFToken = async (): Promise<void> => {
  try {
    await axios.get(`${import.meta.env.VITE_APP_API_URL}/csrf-cookie`);
  } catch (err: any) {
    console.error(err);
    if (err?.response?.status === 503) navigateToSubpage(WORK_IN_PROGRESS);
  }
};

function navigateToSubpage(newSubpage: string) {
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);

  url.pathname = newSubpage;

  window.location.href = url.toString();
}
