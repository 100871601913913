import { useQuery } from "@tanstack/react-query";
import { buildDefaultQueryOptions } from "./common";
import { searchScheduleEvents } from "@client";
import type {
  GoogleScheduleEventDto,
  SearchScheduleEventDto,
} from "@client/searchScheduleEvents";
import type { QueryOptionsTyped } from "./types";
import { format } from "date-fns";
import { useCalendarFilters } from "@views/dietician/Home/components/Calendar/_hooks/CalendarFilters.context";
import { useLocation } from "react-router-dom";

export const searchScheduleEventsQueryKey = "searchScheduleEventsQueryKey";

export const useSearchScheduleEvents = (
  options?: QueryOptionsTyped<
    (SearchScheduleEventDto | GoogleScheduleEventDto)[]
  >,
) => {
  const [params] = useCalendarFilters();
  const { pathname } = useLocation();

  const isCalendarPath = pathname.startsWith("/calendar");

  const finalParams = {
    ...params.filters,
    date: format(new Date(params.filters.date), "yyyy-MM-dd"),
    meeting_types: isCalendarPath ? params.filters.meeting_types : [],
    facilities: isCalendarPath ? params.filters.facilities : [],
  };

  const { data, ...rest } = useQuery(
    [searchScheduleEventsQueryKey, JSON.stringify(params)],
    () =>
      searchScheduleEvents({
        ...finalParams,
      }),
    buildDefaultQueryOptions({
      keepPreviousData: true,
      ...options,
    }),
  );

  return {
    scheduleEvents: data?.data,
    ...rest,
  };
};
