import { styled } from "@mui/material";

export const PdfTemplatePickerAddItemStyled = styled("div")`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.light["200"]};
  cursor: pointer;
`;

export const PdfTemplatePickerAddItemContainerStyled = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 34px 24px;
  border-radius: 8px;
  border: 1px dashed #7448d04d;
  box-shadow: 0px 0px 10px 0px #0000000d;
  cursor: pointer;
`;

export const PdfTemplatePickerAddItemTextContainerStyled = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const PdfTemplatePickerAddItemTitleStyled = styled("div")`
  font: 600 12px/20px Figtree, sans-serif;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const PdfTemplatePickerAddItemSubtitleStyled = styled("div")`
  font: 400 10px/18px Figtree, sans-serif;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.neutral.dark["700"]};
`;

export const PdfTemplatePickerAddItemInfoStyled = styled("div")`
  display: flex;
  align-items: flex-start;
`;

export const PdfTemplatePickerAddItemInputStyled = styled("input")`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
`;
