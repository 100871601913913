import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { NutrientDto, nutrientSchema } from "@client";
import * as yup from "yup";

export const fetchCreatorMealNutrients = async (
  mealId: string,
  version: number,
): Promise<ApiResponse<NutrientDto[]>> => {
  const response = await fetchData(
    `/dietitian/creator/meals/${mealId}/nutrients`,
    APIMethods.GET,
    undefined,
    undefined,
    { "Alloweat-Creator-Version": `${version}` },
  );

  return apiResponse.validate(response);
};

const apiResponse = yup.object().shape({
  data: yup.array().of(nutrientSchema).required(),
});
