import { yupResolver } from "@hookform/resolvers/yup";
import { useAppTranslation } from "@hooks";
import { useForm } from "react-hook-form";
import * as yup from "yup";

export const useResetPasswordForm = () => {
  const { t } = useAppTranslation();
  const resetFormSchema = yup.object().shape({
    password: yup
      .string()
      .required(t("auth.login.password_required"))
      .min(8, t("auth.reset.password_info")),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password"), ""], t("common.password_mismatch"))
      .required(t("common.required_field")),
  });
  const form = useForm<ResetPasswordInputs>({
    resolver: yupResolver(resetFormSchema),
  });

  return form;
};
export interface ResetPasswordInputs {
  password: string;
  passwordConfirmation: string;
}
