import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const PieChartOutlined = (props: SvgIconProps) => {
  return (
    <SvgIcon width="15" height="14" viewBox="0 0 15 14" {...props}>
      <path
        d="M12.1543 5.5C11.8242 3.69727 10.377 2.25 8.57422 1.91992V5.5H12.1543ZM8.57422 6.71875H7.35547V5.5V1.05664C7.35547 0.828125 7.5332 0.625 7.76172 0.625C10.8848 0.625 13.4492 3.18945 13.4492 6.3125C13.4492 6.54102 13.2461 6.71875 13.0176 6.71875H8.57422ZM5.27344 8.80078C5.04492 8.57227 4.91797 8.26758 4.91797 7.9375V2.93555C3.01367 3.62109 1.66797 5.42383 1.66797 7.53125C1.66797 10.2227 3.85156 12.4062 6.54297 12.4062C7.22852 12.4062 7.88867 12.2793 8.47266 12.0254L5.27344 8.80078ZM5.70508 1.51367C5.93359 1.46289 6.13672 1.66602 6.13672 1.89453V7.9375L10.0977 11.9238C10.2754 12.1016 10.25 12.3809 10.0723 12.5078C9.05664 13.2188 7.83789 13.625 6.54297 13.625C3.16602 13.625 0.449219 10.9082 0.449219 7.53125C0.449219 4.45898 2.73438 1.91992 5.70508 1.51367ZM13.8047 7.9375V7.96289C14.0332 7.96289 14.2363 8.14062 14.1855 8.39453C14.0078 9.79102 13.3223 11.0605 12.3066 12C12.1543 12.127 11.9258 12.127 11.7734 11.9746L7.76172 7.9375H13.8047Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
