import { Currencies } from "@utils/scheduleEvent";
import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const updateClinicCurrencies = (
  request: UpdateClinicCurrenciesRequest,
) => fetchData("/dietitian/clinic/currencies", APIMethods.PUT, request);

export interface UpdateClinicCurrenciesRequest {
  currencies: Currencies[];
  default: Currencies;
}
