import { Lang } from "@hooks";
import * as yup from "yup";
import {
  NutrientValueResource,
  nutrientValueSchema,
} from "@client/resources/NutrientValueResource";

export interface DietitianMealsMonitoringResource {
  id: number;
  date: string;
  meals: Meal[];
}

interface Meal {
  id: number;
  name: string;
  time: string | null;
  items: MealItem[];
}

interface Item {
  id: number;
}

interface Translation {
  lang: Lang;
  name: string | null;
}

interface Recipe {
  id: number;
  servings: number;
  recipe: {
    id: number;
    name: string;
    servings: number;
    translations: Translation[];
    image: string | null;
    nutrients: NutrientValueResource[];
  };
}
export interface ItemRecipe extends Item {
  type: "recipe";
  item: Recipe;
}

type MealItem = ItemRecipe;

const itemSchema = yup.object({
  id: yup.number().required(),
});

const recipeItemSchema = yup
  .object({
    id: yup.number().required(),
    type: yup.mixed<"recipe">().required(),
    item: yup
      .object({
        id: yup.number().required(),
        servings: yup.number().required(),
        recipe: yup
          .object({
            id: yup.number().required(),
            name: yup.string().required(),
            translations: yup
              .array()
              .of(
                yup.object({
                  lang: yup
                    .mixed<Lang>()
                    .oneOf(Object.values(Lang).map(e => e as Lang))
                    .required(),
                  name: yup.string().required().nullable(),
                }),
              )
              .required(),
            servings: yup.number().required(),
            image: yup.string().required().nullable(),
            nutrients: yup.array().of(nutrientValueSchema).defined(),
          })
          .required(),
      })
      .required(),
  })
  .concat(itemSchema);

const mealSchema = yup.object({
  id: yup.number().required(),
  name: yup.string().required(),
  time: yup.string().required().nullable(),
  items: yup
    .array()
    .of(
      yup.lazy(item => {
        if (item.type === "recipe") return recipeItemSchema;
        throw new Error("Not implemented");
      }),
    )
    .defined(),
});

export const dietitianMealsMonitoringSchema = yup.object({
  id: yup.number().required(),
  date: yup.string().required(),
  meals: yup.array().of(mealSchema).defined(),
});
