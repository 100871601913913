import { IconButton } from "@components/Alloweat/IconButton";
import EllipsisVerticalIcon from "@icons/ellipsis_vertical.svg?react";
import EditIcon from "@icons/edit.svg?react";
import TrashIcon from "@icons/trash.svg?react";
import { MouseEvent, useState } from "react";
import { Box, ListItemIcon, Menu, MenuItem } from "@mui/material";
import UpdateBodyMeasurementsModal from "@components/Client/FormBodyMeasurementsModal/UpdateBodyMeasurementsModal";
import DeletePatientBodyMeasurementModal from "@components/Client/DeleteBodyMeasurementModal/DeletePatientBodyMeasurementModal";
import { useAppTranslation } from "@hooks";
import { FetchClientMeasurementListResponse } from "@client";
import { PatientMeasurementSource } from "@consts/PatientMeasurementSourceEnum";

type MoreActionButtonProps = {
  clientId: number;
  measurement: Pick<
    FetchClientMeasurementListResponse,
    "id" | "date" | "source"
  >;
};

const MoreActionButton = ({ clientId, measurement }: MoreActionButtonProps) => {
  const { t } = useAppTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (e: MouseEvent<HTMLElement>) => {
    handleClick(e);
  };

  const deleteHandleOnClick = () => {
    setOpenDeleteModal(true);
    handleClose();
  };

  const editHandleOnClick = () => {
    setOpenUpdateModal(true);
    handleClose();
  };

  return (
    <Box>
      <IconButton onClick={handleMenuClick}>
        <EllipsisVerticalIcon color="#727272" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        closeAfterTransition={false}
      >
        <MenuItem onClick={editHandleOnClick}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          {t(
            "patient.health_and_goal.body_measurement_tab.measurements_list.edit_action",
          )}
        </MenuItem>
        <MenuItem
          onClick={deleteHandleOnClick}
          disabled={
            measurement.source.id !== PatientMeasurementSource.DIETITIAN
          }
        >
          <ListItemIcon>
            <TrashIcon />
          </ListItemIcon>
          {t(
            "patient.health_and_goal.body_measurement_tab.measurements_list.delete_action",
          )}
        </MenuItem>
      </Menu>
      {openUpdateModal && (
        <UpdateBodyMeasurementsModal
          open={openUpdateModal}
          setOpen={setOpenUpdateModal}
          patientBodyMeasurementId={measurement.id}
        />
      )}
      {openDeleteModal && (
        <DeletePatientBodyMeasurementModal
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          patientBodyMeasurement={measurement}
          patientId={clientId}
        />
      )}
    </Box>
  );
};

export default MoreActionButton;
