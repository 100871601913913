import { styled } from "@mui/material";

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  width: 348px;
  margin: 0 auto 120px;
`;

export const Title = styled("span")`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;
export const Text = styled("span")`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.4px;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;
