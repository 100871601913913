import { useQuery } from "@tanstack/react-query";
import { fetchProgramSectionCategoriesDictionary } from "@client/dictionaries/fetchProgramSectionCategoriesDictionary";

export const useFetchProgramSectionCategoriesQueryKey =
  "useFetchProgramSectionCategoriesQueryKey";

export const useFetchProgramSectionCategoriesQuery = () => {
  return useQuery({
    queryKey: [useFetchProgramSectionCategoriesQueryKey],
    queryFn: fetchProgramSectionCategoriesDictionary,
    staleTime: 60 * 60 * 1000,
  });
};
