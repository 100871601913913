import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";

export async function updateProgramDayMealFood(
  programId: number,
  dayId: number,
  mealId: number,
  payload: ProgramDayMealFoodUpdatePayload,
): Promise<ApiResponse<void>> {
  return await fetchData(
    `/dietitian/programs/${programId}/schedule/days/${dayId}/meals/${mealId}/food`,
    APIMethods.PUT,
    { ...payload },
  );
}

export interface ProgramDayMealFoodUpdatePayload {
  id: number | null;
  grams?: number;
  foodMeasureId?: number;
}
