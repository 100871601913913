import { MouseEventHandler, useMemo } from "react";

import { Chip, IconButton, useMediaQuery, useTheme } from "@mui/material";

import { TrashNew } from "@assets/icons";
import { ArrowRight, RectangularArrows } from "@assets/icons/Arrows";
import {
  ExpandableTags,
  mapUserChips,
  UserChips,
  UserTagType,
} from "@components/ExpandableChips";
import { useAppTranslation, useMealClientContext, useModalState } from "@hooks";

import { DeleteDietProductModal } from "./DeleteDietProductModal";
import { ExchangeProductModal } from "./ExchangeProductModal";
import {
  AccordionDetailsStyled,
  AccordionStyled,
  AccordionSummaryStyled,
  Text,
} from "./ProductAccordion.styled";
import { ProductAccordionContent } from "./ProductAccordionContent";

interface ProductAccordionProps {
  name: string;
  grams: number;
  tags: number[];
  wasExchange: boolean;
  id: number;
}

export const ProductAccordion = ({
  name,
  grams,
  tags,
  wasExchange,
  id,
}: ProductAccordionProps) => {
  const { t } = useAppTranslation();
  const [openExchange, onOpenExchange, onCloseExchange] = useModalState();
  const [openDelete, onOpenDelete, onCloseDelete] = useModalState();
  const { breakpoints } = useTheme();
  const upMedium = useMediaQuery(`${breakpoints.up("medium")}`);
  const { productsClientContextMap } = useMealClientContext();
  const clientContext = useMemo(
    () => productsClientContextMap.get(id),
    [productsClientContextMap, id],
  );
  const userTags = useMemo(
    () =>
      mapUserChips({
        exchanged: wasExchange,
        disliked: clientContext?.hasUnliked,
        liked: clientContext?.hasLiked,
        allergen: clientContext?.hasAllergens,
      }),
    [clientContext, wasExchange],
  );

  const openModalWrapper =
    (fn: () => void): MouseEventHandler<HTMLButtonElement> =>
    e => {
      e.stopPropagation();
      fn();
    };
  return (
    <>
      {openExchange && (
        <ExchangeProductModal
          open
          onClose={onCloseExchange}
          defaultProductId={id}
        />
      )}
      <DeleteDietProductModal
        open={openDelete}
        onClose={onCloseDelete}
        name={name}
        productId={id.toString()}
      />
      <AccordionStyled TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummaryStyled
          expandIcon={<ArrowRight size="w-[16px] h-[16px]" />}
        >
          {upMedium && (
            <>
              <Text className="flex-3">{name}</Text>
              <Text className="flex-1">{grams}g</Text>
              <div className="flex-3 flex gap-[8px]">
                <UserChips
                  tags={userTags.filter(tag => tag !== UserTagType.EXCHANGED)}
                />
                <ExpandableTags showCount={3} tags={tags} />
              </div>
              <div className="flex items-center">
                <Chip
                  color="info"
                  label={t("diet.exchanged_product")}
                  size="small"
                  sx={{ opacity: wasExchange ? 1 : 0 }}
                />
                <IconButton onClick={openModalWrapper(onOpenExchange)}>
                  <RectangularArrows />
                </IconButton>
                <IconButton onClick={openModalWrapper(onOpenDelete)}>
                  <TrashNew />
                </IconButton>
              </div>
            </>
          )}

          {!upMedium && (
            <div className="grid w-full">
              <div className="flex">
                <Text className="flex-3">{name}</Text>
                <Text className="flex-1">{grams}g</Text>
                <IconButton onClick={openModalWrapper(onOpenExchange)}>
                  <RectangularArrows />
                </IconButton>
                <IconButton onClick={openModalWrapper(onOpenDelete)}>
                  <TrashNew />
                </IconButton>
              </div>

              <div className="flex gap-[8px]">
                <UserChips tags={userTags} />
                <ExpandableTags showCount={3} tags={tags} />
              </div>
            </div>
          )}
        </AccordionSummaryStyled>

        <AccordionDetailsStyled>
          <ProductAccordionContent id={id} />
        </AccordionDetailsStyled>
      </AccordionStyled>
    </>
  );
};
