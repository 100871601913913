import { useAppParams } from "@hooks";
import { MutationOptions } from "@hooks/queries";
import { useDeleteCreatorDietMealRecipeMutation } from "@hooks/queries/diets/creator";
import { useDeleteProgramDietMealRecipeMutation } from "@hooks/queries/program/dietCreator";

export const useDeleteDietMealRecipeMutation = (options?: MutationOptions) => {
  const { programId } = useAppParams();

  return programId
    ? useDeleteProgramDietMealRecipeMutation(programId, options)
    : useDeleteCreatorDietMealRecipeMutation(options);
};
