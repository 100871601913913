import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { useController, useFormContext } from "react-hook-form";

import { LangDto } from "@client";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useAppTranslation, useImageUpload, useUserRoles } from "@hooks";
import { Reviewed, Trash, UploadImgFrame } from "@assets/icons";
import { FormExpandableTagSelector } from "@components/FormExpandableTagSelector";
import { ConfirmationIconButton } from "@components/ConfirmationIconButton";
import { RecipeEditInput } from "@components/RecipeForm/useRecipeEditForm";

import {
  ContentWrapper,
  ImgWrapper,
  InputsContainer,
  MainSectionWrapper,
  RecipeVerifiedWrapper,
  StyledConfirmationIconButton,
  StyledFormCheckboxMui,
  StyledFormTextField,
  StyledIconButton,
  StyledImg,
  StyledImgInput,
  ToggleButtonGroupWrapper,
} from "./RecipeEditDetails.styled";

interface RecipeEditDetailsProps {
  mediaUrl?: string | null;
  lang: LangDto;
  setLang: Dispatch<SetStateAction<LangDto>>;
}

export const RecipeEditDetails = ({
  mediaUrl,
  lang,
  setLang,
}: RecipeEditDetailsProps) => {
  const { isAdminClinicMember } = useUserRoles();
  const {
    control,
    formState: { errors },
  } = useFormContext<RecipeEditInput>();
  const { t } = useAppTranslation();
  const {
    field: { onChange },
  } = useController<RecipeEditInput, "mediaId">({ control, name: "mediaId" });

  useEffect(() => {
    if (errors.namePl) setLang(LangDto.PL);
  }, [errors]);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const {
    isUploading,
    imgUrl,
    handleUploadAvatar,
    uploadImgResponse,
    resetImageUploadResponse,
  } = useImageUpload({
    url: `${import.meta.env.VITE_APP_API_URL}/dietitian/media/recipe/upload`,
    formDataName: "image",
  });

  const handleUploadFileButtonClick = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  const handleDeleteImg = () => {
    resetImageUploadResponse();
    onChange(null);
  };

  useEffect(() => {
    if (uploadImgResponse) onChange(uploadImgResponse.id);
  }, [uploadImgResponse]);

  return (
    <div className="flex gap-4">
      {mediaUrl || uploadImgResponse?.url ? (
        <ImgWrapper>
          <StyledImg
            src={imgUrl}
            srcSet={uploadImgResponse?.url || mediaUrl || ""}
            alt={t("recipe.recipe_img")}
          />
          <StyledConfirmationIconButton onClick={e => e.stopPropagation()}>
            <ConfirmationIconButton
              icon={<Trash size="w-5 h-5" />}
              onConfirm={handleDeleteImg}
              confirmationText={t("confirmation.question_delete")}
              submitText={t("confirmation.delete")}
            />
          </StyledConfirmationIconButton>
        </ImgWrapper>
      ) : (
        <>
          <StyledIconButton onClick={handleUploadFileButtonClick}>
            <StyledImgInput
              name="image"
              type="file"
              accept="image/*"
              ref={fileInputRef}
              disabled={isUploading}
              onChange={handleUploadAvatar}
            />
            <UploadImgFrame className="w-full h-full" />
          </StyledIconButton>
        </>
      )}

      <ContentWrapper>
        <MainSectionWrapper>
          <InputsContainer>
            <StyledFormTextField
              name={lang === LangDto.PL ? "namePl" : "nameEn"}
              key={lang === LangDto.PL ? "namePl" : "nameEn"}
              variant="outlined"
              label={t("recipes.recipe_name")}
              placeholder={t("recipes.start_typing")}
            />

            <ToggleButtonGroupWrapper>
              <ToggleButtonGroup
                value={lang}
                exclusive
                className="h-[40px]"
                onChange={(_, value) => value && setLang(value)}
              >
                <ToggleButton color="primary" value={LangDto.PL}>
                  {LangDto.PL.toUpperCase()}
                </ToggleButton>
                <ToggleButton color="primary" value={LangDto.EN}>
                  {LangDto.EN.toUpperCase()}
                </ToggleButton>
              </ToggleButtonGroup>
            </ToggleButtonGroupWrapper>
          </InputsContainer>

          <FormExpandableTagSelector
            control={control}
            name="tags"
            tagCategory="recipe"
          >
            {isAdminClinicMember && (
              <StyledFormCheckboxMui
                name="reviewed"
                control={control}
                label={
                  <RecipeVerifiedWrapper>
                    {t("recipes.verified")}
                    <Reviewed size="w-6 h-6" />
                  </RecipeVerifiedWrapper>
                }
              />
            )}
          </FormExpandableTagSelector>
        </MainSectionWrapper>
      </ContentWrapper>
    </div>
  );
};
