import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import { mapSelectedDaysToRequestQueryParams } from "@views/dietician/PatientMonitoring2/PatientMonitoringUtils";
import IrrigationSummaryCardOf from "@views/dietician/PatientMonitoring2/components/SummaryCard/Irrigation/IrrigationSummaryCardOf";
import { sumBy } from "lodash";
import { useMemo } from "react";
import { useFetchMonitoringIrrigation } from "@hooks/queries/client/monitoring";
import { PatientHydrationProductsMonitoringResource } from "@client/resources/PatientHydrationProductsMonitoringResource";

const IrrigationSummaryCardOfDays = () => {
  const { patientId, selectedDays } = useMonitoring();
  const { data } = useFetchMonitoringIrrigation({
    patientId,
    ...mapSelectedDaysToRequestQueryParams(selectedDays.selectDays),
  });

  const values = useMemo(() => getValues(data?.data), [data]);

  return (
    <IrrigationSummaryCardOf
      actualValue={values.actual}
      targetValue={values.target}
    />
  );
};

const getValues = (
  data: PatientHydrationProductsMonitoringResource[] | undefined,
) => {
  const length = Math.max(
    (data ?? []).filter(irrigation => irrigation.goal !== null).length,
    1,
  );

  const actualValue =
    sumBy(data ?? [], i => sumBy(i.products, p => p.milliliters)) / length;

  const targetValue = sumBy(data ?? [], i => i.goal ?? 0) / length;

  return {
    actual: actualValue / 1000,
    target: targetValue / 1000,
  };
};

export default IrrigationSummaryCardOfDays;
