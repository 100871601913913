import { useMutation, useQueryClient } from "@tanstack/react-query";

import { postToggleFavoriteMealPattern } from "@client";

import { MutationOptions } from "./types";
import { searchMealPatternsQueryNewKey } from "./useSearchMealPatternsQueryNew";
import { fetchMealPatternQueryKey } from "./useFetchMealPatternQuery";

export const usePostToggleFavoriteMealPatternMutation = (
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, string>(
    recipeId => postToggleFavoriteMealPattern(recipeId),
    {
      ...options,
      onSuccess: (d, recipeId) => {
        options?.onSuccess && options.onSuccess(d, recipeId);
        queryClient.invalidateQueries([searchMealPatternsQueryNewKey]);
        queryClient.invalidateQueries([fetchMealPatternQueryKey]);
      },
    },
  );
};
