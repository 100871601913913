import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const updateProgramDietShowMacros = async (
  programId: string,
  dietId: string,
  payload: UpdateProgramDietShowMacrosPayload,
  version: string,
) => {
  return await fetchData(
    `/dietitian/program/${programId}/diet-creator/${dietId}/show-macros`,
    APIMethods.PUT,
    payload,
    undefined,
    { "Alloweat-Creator-Version": version },
  );
};

export interface UpdateProgramDietShowMacrosPayload {
  show: boolean;
}
