import { useController } from "react-hook-form";

import { Checkbox, FormControlLabel } from "@mui/material";
import { isArray } from "lodash";

import { ItemOptionConfigDto } from "@client/surveys";
import { FormTextField } from "@components/FormTextField";

import { CheckboxAnswerFormInputs } from "../forms";
import { OptionsWrapper } from "./QuestionCheckbox.styled";
import { useAppTranslation } from "@hooks";
import { useContext } from "react";
import { BlurContext } from "../BlurContext";
import { SAFARI_TAB_INDEX } from "@utils";

interface ControlledCheckboxContentProps {
  options: ItemOptionConfigDto[];
  other: ItemOptionConfigDto | null;
  readOnly?: boolean;
}
export const ControlledCheckboxContent = ({
  options,
  other,
  readOnly,
}: ControlledCheckboxContentProps) => {
  const { t } = useAppTranslation();
  const { handleBlur, handleFocus } = useContext(BlurContext);
  const {
    field: { value, onChange },
  } = useController<CheckboxAnswerFormInputs>({ name: "uuids" });

  const handleChange = (checked: boolean, uuid: string) => {
    if (!value || !isArray(value)) return;

    if (value.includes(uuid) && !checked)
      onChange(value.filter(id => id !== uuid));
    if (!value.includes(uuid) && checked) onChange([...value, uuid]);
  };

  if (!isArray(value)) return <></>;

  return (
    <>
      <OptionsWrapper>
        {options.map(({ uuid, name }) => (
          <FormControlLabel
            tabIndex={SAFARI_TAB_INDEX}
            onFocus={handleFocus}
            onBlur={handleBlur}
            key={uuid}
            control={
              <Checkbox
                checked={value.includes(uuid)}
                onChange={(_, checked) =>
                  !readOnly && handleChange(checked, uuid)
                }
              />
            }
            label={name}
          />
        ))}
        {other?.name && other.uuid && (
          <FormControlLabel
            tabIndex={SAFARI_TAB_INDEX}
            onFocus={handleFocus}
            onBlur={handleBlur}
            control={
              <Checkbox
                checked={value.includes(other.uuid)}
                onChange={(_, checked) =>
                  !readOnly && handleChange(checked, other.uuid)
                }
              />
            }
            label={other?.name}
          />
        )}
      </OptionsWrapper>

      {other?.uuid && value.includes(other.uuid) && (
        <FormTextField
          onFocus={handleFocus}
          onBlur={handleBlur}
          name="text"
          size="small"
          FormHelperTextProps={{ hidden: true }}
          placeholder={t("questionnaires.questions.checkbox.placeholder")}
          InputProps={{ readOnly }}
        />
      )}
    </>
  );
};
