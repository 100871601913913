import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "../types";
import { fetchPatientVisitsQueryKey } from "./useFetchPatientVisitsQuery";
import { fetchVisitTagsQueryKey } from "./useFetchVisitTagsQuery";
import { NewTag, createCustomTag } from "@client/visits";
import { toast } from "react-toastify";
import { useAppTranslation } from "@hooks/useAppTranslation";

export const useCreateCustomTagMutation = (options?: MutationOptions) => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();

  return useMutation(
    (args: { payload: NewTag }) => createCustomTag(args.payload),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries([fetchPatientVisitsQueryKey]);
        queryClient.invalidateQueries([fetchVisitTagsQueryKey]);
        options?.onSuccess && options.onSuccess();
      },

      onError: err => {
        toast.error(t("patient.visits.tag_already_exist"));
      },
    },
  );
};
