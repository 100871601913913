import { Typography } from "@mui/material";

interface LabeledDetailProps {
  label: string;
  value: string;
}

export const LabeledDetail = ({ label, value }: LabeledDetailProps) => {
  return (
    <div>
      <Typography variant="caption">{label}</Typography>
      <Typography variant="body2">{value}</Typography>
    </div>
  );
};
