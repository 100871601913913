import { useAppTranslation } from "@hooks";
import { ENERGY_ID } from "@utils/macros";
import { MacrosChartData } from "./MacrosChart";

export const useMacrosChart = <T extends { chartNumberOfPortions: number }>(
  data: MacrosChartData[],
  numOfPortions: number,
) => {
  const { isPolishChosen } = useAppTranslation();

  const RADIAN = Math.PI / 180;
  const PIE_CHART_COLORS = ["#801FFF", "#FFA746", "#88D89C"];

  const translateNumOfPortions = (numOfPortions: number): string => {
    if (isPolishChosen) {
      if (numOfPortions === 1) return "porcja";
      if ([2, 3, 4].includes(numOfPortions)) return "porcje";
      return "porcji";
    }
    if (numOfPortions === 1) return "portion";
    return "portions";
  };

  const macros = data.filter(macro => macro.id !== ENERGY_ID);
  const caloriesSummed = data.find(macro => macro.id === ENERGY_ID)?.value ?? 0;

  const numOfPortionsAvaliable = Array.from(
    { length: numOfPortions },
    (_, i) => i + 1,
  );

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: Record<string, number>) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        className="text-sm"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${macros[index].prefix}: ${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return {
    RADIAN,
    PIE_CHART_COLORS,
    macros,
    caloriesSummed,
    numOfPortionsAvaliable,
    translateNumOfPortions,
    renderCustomizedLabel,
  };
};
