import { ActionButtons } from "../ActionButtons/ActionButtons";
import { useDeleteClinicProductMutation } from "@hooks/queries/settings";

interface ProductActionsProps {
  id: string | number;
  openEditModal: () => void;
  setProductId: (id?: number) => void;
}

export const ProductActions = ({
  setProductId,
  id,
  openEditModal,
}: ProductActionsProps) => {
  const { mutate } = useDeleteClinicProductMutation();

  return (
    <ActionButtons
      id={parseInt(id.toString())}
      setEditId={setProductId}
      openEditModal={openEditModal}
      deleteMutation={mutate}
    />
  );
};
