import { type FC, useState } from "react";
import type { EventWithType } from "@typeDefinitions";
import { format } from "date-fns";
import { pl } from "date-fns/locale";
import { Close } from "@assets/icons";
import {
  CounterBubbleButton,
  MenuHeader,
  MenuHeaderTitle,
  MenuItemEventContent,
  MenuItemEvents,
  MenuStyledIconButton,
  MenuWrapper,
} from "./BigCalendarDropdown.styled";
import { useCalendarColors } from "@views/dietician/Calendar";
import { getEventIcon } from "../../_utils/getEventIcon";

interface BigCalendarDropdownProps {
  todayEvents: EventWithType[];
  day: Date;
  maxCount?: number;
  handleEventClick: (
    event: React.MouseEvent<HTMLElement>,
    eventId: number | null,
    googleId?: string,
  ) => void;
}

export const BigCalendarDropdown: FC<BigCalendarDropdownProps> = ({
  todayEvents,
  day,
  maxCount = 3,
  handleEventClick,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const { getNewDietitianColor } = useCalendarColors();

  const handleOpenDropdown = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropDown = () => {
    setAnchorEl(null);
  };

  if (todayEvents.length <= maxCount) return null;

  const leftEventsCount = todayEvents.length - maxCount;

  const formattedDate = format(day, "EEE, dd.MM", { locale: pl }).replace(
    ".",
    "",
  );

  return (
    <div>
      <CounterBubbleButton onClick={handleOpenDropdown}>
        +{leftEventsCount}
      </CounterBubbleButton>
      <MenuWrapper
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseDropDown}
      >
        <MenuHeader>
          <MenuHeaderTitle>{formattedDate}</MenuHeaderTitle>
          <MenuStyledIconButton size="small" onClick={handleCloseDropDown}>
            <Close />
          </MenuStyledIconButton>
        </MenuHeader>
        <MenuItemEvents>
          {todayEvents.map(event => {
            const theme = getNewDietitianColor(event.dietitianId);
            return (
              <MenuItemEventContent
                key={event.id}
                onClick={e => handleEventClick(e, event.id, event.googleId)}
              >
                {getEventIcon(event.type, {
                  color: theme?.color,
                })}
                <span className="text-gray-450">
                  {format(event.start, "HH:mm")}
                </span>
                <span className="block max-w-[260px] truncate">
                  {event.title}
                </span>
              </MenuItemEventContent>
            );
          })}
        </MenuItemEvents>
      </MenuWrapper>
    </div>
  );
};
