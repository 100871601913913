import { useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDeepCompareEffect } from "react-use";
import { useTaskViewSettingsContext } from "@views/dietician/Tasks/components/TaskViewSettings.context";
import { SortByEnum, GroupByEnum } from "./useTaskFilterOptions";

const getItemsFromLocalStorage = () => {
  return {
    hideFinished: JSON.parse(localStorage.getItem("hideFinished") ?? "false"),
    groupBy: (localStorage.getItem("groupBy") ?? "status") as GroupByEnum,
    sortBy: (localStorage.getItem("sortBy") ??
      SortByEnum.PriorityDesc) as SortByEnum,
  };
};

const storeItemsInLocalStorage = (
  hideFinished: boolean,
  groupBy: GroupByEnum,
  sortBy: SortByEnum,
) => {
  localStorage.setItem("hideFinished", JSON.stringify(hideFinished));
  localStorage.setItem("groupBy", groupBy);
  localStorage.setItem("sortBy", sortBy);
};
getItemsFromLocalStorage();
export const useSettingsTaskForm = () => {
  const { setHideFinished, setGroupBy, setSortBy } =
    useTaskViewSettingsContext();

  const resolver = yup.object().shape({
    hideFinished: yup.boolean(),
    groupBy: yup.string().oneOf(Object.values(GroupByEnum)),
    sortBy: yup.string().oneOf(Object.values(SortByEnum)),
  });

  type TaskFilterFormSchema = yup.InferType<typeof resolver>;

  const form = useForm<TaskFilterFormSchema>({
    defaultValues: getItemsFromLocalStorage(),
    resolver: yupResolver(resolver),
    mode: "onChange",
  });

  const formData = useWatch({ control: form.control });

  useDeepCompareEffect(() => {
    setHideFinished(!!formData.hideFinished);
    setGroupBy(formData.groupBy ?? GroupByEnum.Status);
    setSortBy(formData.sortBy ?? SortByEnum.PriorityDesc);

    if (formData.groupBy && formData.sortBy) {
      storeItemsInLocalStorage(
        !!formData.hideFinished,
        formData.groupBy,
        formData.sortBy,
      );
    }
  }, [formData]);

  return form;
};
