import { ChangeEvent, useMemo } from "react";
import { useWatch } from "react-hook-form";

import { InputAdornment } from "@mui/material";
import { round } from "lodash";

import { RecipeEditInput } from "@components/RecipeForm/useRecipeEditForm";
import { Product } from "@context";
import {
  decimalFourDigitsInput,
  decimalInput1FourDigitsFixed,
} from "@utils/inputs";

import { CellWrapper, MeasureTextField } from "./RecipeTableForm.styled";
import { StepsArrowButtons } from "@components/StepsArrowButtons";
import { DEFAULT_GRAMS } from "@utils";

interface MeasureAmountFieldProps {
  index: number;
  product: Product | null;
  onChange: (value: string) => void;
}
export const MeasureAmountTextField = ({
  index,
  product,
  onChange,
}: MeasureAmountFieldProps) => {
  const grams = useWatch<RecipeEditInput, `ingredients.${number}.grams`>({
    name: `ingredients.${index}.grams`,
  });

  const AMOUNT_LIMIT_GRAMS = 9999;
  const MEASURE_ID_ML = 4;

  const measureId = useWatch<
    RecipeEditInput,
    `ingredients.${number}.measureId`
  >({
    name: `ingredients.${index}.measureId`,
  });

  const measureGrams = useMemo(
    () =>
      product?.measures.find(m => m.id.toString() === measureId)?.grams || 1,
    [measureId, product],
  );

  const value = round(Number(grams) / measureGrams, 1);
  const calcGrams = (val: number) => round(val * measureGrams).toString();

  const onAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const parsedValue = Number(value);
    if (!isNaN(parsedValue)) {
      onChange(calcGrams(parsedValue));
    }
  };

  const onIncrement = () => {
    if (value !== round(value) && !isNaN(value)) {
      onChange(calcGrams(Math.ceil(value)));
    } else if (isNaN(value)) {
      onChange(calcGrams(0));
    } else if (value + 1 <= AMOUNT_LIMIT_GRAMS) {
      onChange(calcGrams(value + 1));
    } else if (
      (measureGrams === DEFAULT_GRAMS ?? Number(measureId) === MEASURE_ID_ML) &&
      value + 1 > AMOUNT_LIMIT_GRAMS
    ) {
      onChange(calcGrams(AMOUNT_LIMIT_GRAMS));
    }
  };

  const onDecrement = () => {
    if (value !== round(value) && !isNaN(value))
      onChange(calcGrams(Math.floor(value)));
    else if (isNaN(value)) onChange(calcGrams(0));
    else if (value - 1 >= 0) onChange(calcGrams(value - 1));
  };

  return (
    <CellWrapper>
      <MeasureTextField
        value={`${isNaN(value) ? 0 : value}`}
        onChange={onAmountChange}
        variant="standard"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <StepsArrowButtons
                clickTop={onIncrement}
                clickDown={onDecrement}
              />
            </InputAdornment>
          ),
          inputComponent:
            measureGrams === DEFAULT_GRAMS
              ? decimalFourDigitsInput
              : decimalInput1FourDigitsFixed,
        }}
      />
    </CellWrapper>
  );
};
