import { forwardRef } from "react";
import { InputAttributes, PatternFormat } from "react-number-format";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const phoneInputFactory = () =>
  forwardRef<InputAttributes, CustomProps>(function NumberFormatCustom(
    props,
    ref,
  ) {
    const { onChange, ...other } = props;

    return (
      <PatternFormat
        {...other}
        format="### ### ### #"
        valueIsNumericString
        getInputRef={ref}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
      />
    );
  });
