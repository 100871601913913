import { FoodGroupResourceDto } from "@client";
import { useAppTranslation } from "@hooks";
import {
  fetchFoodGroupsTreeQueryKey,
  useFetchFoodGroupsTreeQuery,
} from "@hooks/queries";
import { useQueryClient } from "@tanstack/react-query";
import { ApiResponse } from "@typeDefinitions";

export const useFoodGroup = () => {
  const { isPolishChosen } = useAppTranslation();
  const queryClient = useQueryClient();
  const dataCached = queryClient.getQueryData<
    ApiResponse<FoodGroupResourceDto[]>
  >([fetchFoodGroupsTreeQueryKey]);
  const { data } = useFetchFoodGroupsTreeQuery({ enabled: !dataCached?.data });
  const foodGroupsData = dataCached?.data ?? data;

  function generateFlatOptions() {
    if (!foodGroupsData) return new Map<string, FlatTreeSelectOption>();

    const flattenOption = (
      option: FoodGroupResourceDto[],
      parents: string[],
    ): Map<string, FlatTreeSelectOption> =>
      option.reduce((acc, o) => {
        const currentOption: FlatTreeSelectOption = {
          title: (isPolishChosen ? o.descriptionPl : o.description) ?? "",
          parents,
        };
        acc.set(o.id.toString(), currentOption);

        const childrenOptions = flattenOption(o.children, [
          ...parents,
          currentOption.title,
        ]);
        childrenOptions.forEach((value, key) => acc.set(key, value));

        return acc;
      }, new Map<string, FlatTreeSelectOption>());

    return flattenOption(foodGroupsData, []);
  }

  const flatOptions = generateFlatOptions();

  return { foodGroupDict: flatOptions };
};

interface FlatTreeSelectOption {
  title: string;
  parents: string[];
}
