import { useMemo } from "react";
import { useParams } from "react-router-dom";

export function useSectionParams() {
  const { sectionId } = useParams<SectionParams>();
  return useMemo(() => (sectionId ? parseInt(sectionId) : NaN), [sectionId]);
}

type SectionParams = {
  sectionId: string;
};
