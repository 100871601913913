import { styled } from "@mui/material";

export const MainText = styled("span")`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const Subtext = styled("span")`
  font-size: 0.857rem;
  line-height: 1.25rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;

export const TextWrapper = styled("div")`
  display: flex;
  align-items: baseline;
  flex: 1;
  gap: 0.25rem;
`;
