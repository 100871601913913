import { styled } from "@mui/material";
import DialogMui from "@mui/material/Dialog";

const Dialog = styled(DialogMui)`
  .MuiDialog-paper {
    border-radius: 12px;
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.05));
  }
` as typeof DialogMui;

export default Dialog;
