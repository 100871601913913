import { useFetchTagsCategoriesQuery } from "@hooks/queries/dictionaries";
import { useCallback } from "react";
import { FetchTagCategoriesCategory } from "@client";
import { TagCategoryType } from "@utils/tagsNew";
import { ApiResponse, LanguagesSlugs } from "@typeDefinitions";

type Data = ApiResponse<FetchTagCategoriesCategory[]> | undefined;

export type TagsUtilsReturn = {
  getCategoriesWithTags: () => FetchTagCategoriesCategory[];
  getCategoryById: (id: number) => FetchTagCategoriesCategory | null;
  getCategoryByType: (
    typeId: TagCategoryType,
  ) => FetchTagCategoriesCategory | null;
  getCategoryName: (
    category: FetchTagCategoriesCategory,
    lang: LanguagesSlugs,
  ) => string | undefined;

  getTags: () => FetchTagCategoriesCategory["tags"];
  getTagById: (id: number) => FetchTagCategoriesCategory["tags"][0] | null;
  getTagBySystemId: (
    systemId: number,
  ) => FetchTagCategoriesCategory["tags"][0] | null;

  getTagName: (
    tag: FetchTagCategoriesCategory["tags"][0],
    lang: LanguagesSlugs,
  ) => string | undefined;
};
export const useTagsUtils = (): TagsUtilsReturn => {
  const { data } = useFetchTagsCategoriesQuery();

  const getCategoriesWithTags = useCallback(
    (): FetchTagCategoriesCategory[] => getCategoriesWithTagsUtil(data),
    [data?.data],
  );

  const getCategoryById = useCallback(
    (id: number): FetchTagCategoriesCategory | null =>
      getCategoryUtil(data, id),
    [data?.data],
  );

  const getCategoryByType = useCallback(
    (typeId: TagCategoryType): FetchTagCategoriesCategory | null =>
      getCategoryBySystemIdUtil(data, typeId),
    [data?.data],
  );

  const getCategoryName = useCallback(
    (category: FetchTagCategoriesCategory, lang: LanguagesSlugs) => {
      switch (lang) {
        case LanguagesSlugs.PL:
          return category.name;
        case LanguagesSlugs.EN:
          return category.nameEn;
        default:
          return category.name;
      }
    },
    [],
  );

  const getTags = useCallback(
    (): FetchTagCategoriesCategory["tags"] => getTagsUtil(data),
    [data?.data],
  );

  const getTagById = useCallback(
    (id: number): FetchTagCategoriesCategory["tags"][0] | null =>
      getTagByIdUtil(data, id),
    [data?.data],
  );

  const getTagBySystemId = useCallback(
    (systemId: number): FetchTagCategoriesCategory["tags"][0] | null =>
      getTagBySystemIdUtil(data, systemId),
    [data?.data],
  );

  const getTagName = useCallback(
    (tag: FetchTagCategoriesCategory["tags"][0], lang: LanguagesSlugs) => {
      switch (lang) {
        case LanguagesSlugs.PL:
          return tag.namePl;
        case LanguagesSlugs.EN:
          return tag.nameEn;
        default:
          return tag.namePl;
      }
    },
    [],
  );

  return {
    getCategoriesWithTags,
    getCategoryById,
    getCategoryByType,
    getCategoryName,

    getTags,
    getTagById,
    getTagBySystemId,

    getTagName,
  };
};

const getCategoriesWithTagsUtil = (
  data: Data,
): FetchTagCategoriesCategory[] => {
  if (data?.data === undefined) {
    return [];
  }

  return data.data;
};

const getCategoryUtil = (
  data: Data,
  id: number,
): FetchTagCategoriesCategory | null => {
  const categories = getCategoriesWithTagsUtil(data);

  if (categories.length === 0) {
    return null;
  }

  return categories.find(category => category.id === id) || null;
};

const getCategoryBySystemIdUtil = (
  data: Data,
  typeId: TagCategoryType,
): FetchTagCategoriesCategory | null => {
  const categories = getCategoriesWithTagsUtil(data);

  if (categories.length === 0) {
    return null;
  }

  return categories.find(category => category.type === typeId) || null;
};

const getTagsUtil = (data: Data): FetchTagCategoriesCategory["tags"] => {
  if (data?.data === undefined) {
    return [];
  }

  return data.data.map(category => category.tags).flat();
};

const getTagByIdUtil = (
  data: Data,
  id: number,
): FetchTagCategoriesCategory["tags"][0] | null => {
  const tags = getTagsUtil(data);
  if (tags.length === 0) {
    return null;
  }

  return tags.find(tag => tag.id === id) || null;
};

const getTagBySystemIdUtil = (
  data: Data,
  systemId: number,
): FetchTagCategoriesCategory["tags"][0] | null => {
  const tags = getTagsUtil(data);
  if (tags.length === 0) {
    return null;
  }

  return tags.find(tag => tag.systemId === systemId) || null;
};
