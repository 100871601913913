import { useQuery } from "@tanstack/react-query";
import { QueryOptions } from "../types";
import { buildDefaultQueryOptions } from "../common";
import { fetchPatientVisits } from "@client/visits";

export const fetchPatientVisitsQueryKey = "fetchPatientVisits";

export const useFetchPatientVisitsQuery = (
  patientId: number,
  options?: QueryOptions,
) => {
  const { data: patientVisits, ...rest } = useQuery(
    [fetchPatientVisitsQueryKey, patientId],
    () => fetchPatientVisits(patientId),
    buildDefaultQueryOptions(options),
  );

  return { patientVisits, ...rest };
};
