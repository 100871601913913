import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export interface ResendRegisterEmailProps {
  email: string;
}

export const resendRegisterEmail = async (
  payload: ResendRegisterEmailProps,
) => {
  return await fetchData(
    `/register-clinic/resend-email`,
    APIMethods.POST,
    payload,
  );
};
