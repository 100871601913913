import { FormEvent, useState } from "react";
import { useAppTranslation } from "@hooks/useAppTranslation";
import { Eye } from "@assets/icons";
import {
  LoginFormWrapper,
  LoginTitle,
  ResendButton,
  StyledLink,
  SubmitButton,
  TopLoginSection,
} from "./LoginForm.styled";
import img from "@assets/img/dashboardEmptyState.png";
import { REGISTER_CLINIC, RETRIEVE } from "@routes";
import { FormItem } from "@components/FormItem";
import { FormTextField } from "@components/FormTextField";
import { ErrorText } from "@components/ErrorText";
import { IconButton, InputAdornment } from "@mui/material";
import { useLoginForm } from "./useLoginForm";
import { UseMutateFunction } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { ApiErrorIf } from "@typeDefinitions";
import { LoginRequest } from "@client";
import { useResendRegisterEmailMutation } from "@hooks/queries";

interface LoginFormProps {
  onSubmit: UseMutateFunction<
    unknown,
    AxiosError<ApiErrorIf, any>,
    LoginRequest,
    unknown
  >;

  isLoading: boolean;
}

export const LoginForm = ({ onSubmit, isLoading }: LoginFormProps) => {
  const { t } = useAppTranslation();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [verifiedError, setVerifiedError] = useState(false);
  const { mutate } = useResendRegisterEmailMutation();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useLoginForm();

  const resendEmail = () => mutate({ email: watch("email") });

  const toggleShowPassword = () => setShowPassword(!showPassword);

  const handleLogin = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit(
      data => {
        onSubmit(data, {
          onError: err => {
            if (err.response?.data.errors.email_verification)
              setVerifiedError(true);
            if (err.response?.data.errors.email) setVerifiedError(false);
            setError("email", {
              message: t("auth.login.invalid_data_error"),
            });
            setError("password", {
              message: t("auth.login.invalid_data_error"),
            });
          },
        });
      },
      e => console.log(e),
    )();
  };

  return (
    <LoginFormWrapper onSubmit={handleLogin}>
      <TopLoginSection>
        <img src={img} width={180} height={180} className="mx-auto" />
        <LoginTitle>{t("auth.login.log_in")}</LoginTitle>
      </TopLoginSection>

      <FormItem
        title={t("common.email_address")}
        extraEl={
          <>
            <FormTextField
              control={control}
              name="email"
              size="small"
              variant="outlined"
              placeholder={t("auth.login.placeholder_email")}
              FormHelperTextProps={{ hidden: true }}
            />
            <ErrorText content={errors.email?.message} />
          </>
        }
      />

      <FormItem
        title={t("common.password")}
        extraEl={
          <>
            <FormTextField
              control={control}
              name="password"
              type={showPassword ? "text" : "password"}
              size="small"
              fullWidth
              FormHelperTextProps={{ hidden: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={toggleShowPassword}
                      edge="end"
                      size="small"
                      color="primary"
                    >
                      <Eye size="w-[20px] h-[20px]" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder={t("auth.login.placeholder_password")}
            />
            <ErrorText content={errors.password?.message} />
          </>
        }
      />

      {verifiedError && (
        <>
          <div>
            <ErrorText
              content={`${t("auth.login.email_verification")} `}
              triangle
              extra={
                <ResendButton onClick={resendEmail}>
                  {t("auth.login.send_email_again")}
                </ResendButton>
              }
            />
          </div>
        </>
      )}
      <SubmitButton
        type="submit"
        loading={isLoading}
        fullWidth
        variant="contained"
        size="large"
      >
        {t("auth.login.log_in")}
      </SubmitButton>

      <StyledLink className="text-center" color="primary" to={RETRIEVE}>
        {t("auth.login.forgot")}
      </StyledLink>
      <p className="text-center	">
        {t("auth.login.no_account")}{" "}
        <StyledLink color="primary" to={REGISTER_CLINIC}>
          {t("auth.login.sign_up")}
        </StyledLink>
      </p>
    </LoginFormWrapper>
  );
};
