import { ListItemIcon, ListItemText } from "@mui/material";
import { ProgramRowType } from "@views/dietician/PatientProgramsNew/Grid/RowType";
import { useChangeSharedProgramMutation } from "@hooks/queries/client/program/useChangeSharedProgramMutation";
import { CustomMenuItem } from "@views/dietician/PatientProgramsNew/PatientProgramsGrid.styled";
import { Hide, Mobile } from "@assets/icons";
import { useAppTranslation } from "@hooks";

type SharedActionType = {
  program: ProgramRowType["program"];
  onSuccess: () => void;
  patientId: number;
};

const SharedAction = ({ program, onSuccess, patientId }: SharedActionType) => {
  const { t } = useAppTranslation();
  const { mutate, isLoading } = useChangeSharedProgramMutation({
    onSuccess: () => {
      onSuccess();
    },
  });

  const handleOnClick = () => {
    mutate({
      programId: program.id,
      patientId: patientId,
      payload: {
        shared: !program.shared,
      },
    });
  };

  return (
    <>
      {program.shared && (
        <CustomMenuItem onClick={handleOnClick} disabled={isLoading}>
          <ListItemIcon>
            <Hide fill="#727272" style={{ width: "24px" }} />
          </ListItemIcon>
          <ListItemText>
            {t("patient.programs_grid.row.actions.hide_in_app")}
          </ListItemText>
        </CustomMenuItem>
      )}
      {!program.shared && (
        <CustomMenuItem onClick={handleOnClick} disabled={isLoading}>
          <ListItemIcon>
            <Mobile size="" fill="#727272" style={{ width: "24px" }} />
          </ListItemIcon>
          <ListItemText>
            {t("patient.programs_grid.row.actions.show_in_app")}
          </ListItemText>
        </CustomMenuItem>
      )}
    </>
  );
};

export default SharedAction;
