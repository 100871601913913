import { useEffect, useMemo } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { AutosaveStatus } from "@components/AutosaveStatus";
import { PageLayout } from "@components/PageLayout";
import { TabIf, TabSwitch } from "@components/TabSwitch/TabSwitch";
import {
  useAppParams,
  useAppTranslation,
  useMealClientContext,
  useModalState,
} from "@hooks";
import { useFetchCreatorDietQuery } from "@hooks/queries/diets/creator";
import { getTranslation } from "@utils/translations";

import { ThemeProviderWrapperNew } from "themeNew";
import { PageTitleWrapper, TitleStyled } from "./DietCreator.styled";
import { AutogenerateDietButton } from "./components/AutogenerateDietButton";
import { DietNameEditButton } from "./components/DietNameEditButton";
import { usePostDietRenderMainPhotoMutation } from "@hooks/queries/diets";

export const DietCreator = () => {
  const { dietId } = useAppParams();
  const { currentLanguage, t } = useAppTranslation();
  const { diet } = useFetchCreatorDietQuery(dietId);
  const { clearCache } = useMealClientContext();
  const { mutate: mutateImage } = usePostDietRenderMainPhotoMutation();

  const navigate = useNavigate();
  const name = useMemo(
    () => getTranslation(diet?.translations, currentLanguage),
    [currentLanguage, diet?.translations],
  );
  const { pathname } = useLocation();

  const tabs = useMemo<TabIf[]>(
    () => [
      { id: DietEditTabs.SETTINGS, label: t("diet.settings") },
      { id: DietEditTabs.RECIPES, label: t("common.recipes") },
      { id: DietEditTabs.PRODUCTS, label: t("common.products") },
      { id: DietEditTabs.TAGS, label: t("common.tags") },
    ],
    [t],
  );

  const tab = useMemo(
    () => pathname.split("/").find(key => tabs.map(t => t.id).includes(key)),
    [pathname],
  );

  const translations = useMemo(() => diet?.translations, [diet?.translations]);

  const title = useMemo(
    () => (
      <PageTitleWrapper>
        <TitleStyled>{name}</TitleStyled>
        <AutosaveStatus compact />
      </PageTitleWrapper>
    ),
    [name],
  );

  const extras = useMemo(
    () => <DietNameEditButton translations={translations} dietId={dietId} />,
    [translations, dietId],
  );

  const isSomeTab = tabs.some(tab => pathname.includes(tab.id));

  const handleChangeBase = (base: string) => {
    if (tab !== base) {
      navigate(`${base}`, { replace: true });
    }
  };

  useEffect(() => {
    if (!isSomeTab) navigate(DietEditTabs.SETTINGS, { replace: true });
  }, [pathname]);

  useEffect(() => {
    return () => {
      clearCache();
      if (diet && !diet.image) mutateImage(diet.id.toString());
    };
  }, [diet]);

  if (!isSomeTab) return <></>;

  return (
    <ThemeProviderWrapperNew>
      <PageLayout title={title} returnButton extras={extras}>
        <div className="flex justify-between">
          <TabSwitch tabs={tabs} value={tab} onChange={handleChangeBase} />

          {tab === DietEditTabs.RECIPES && <AutogenerateDietButton />}
        </div>

        <Outlet />
      </PageLayout>
    </ThemeProviderWrapperNew>
  );
};

enum DietEditTabs {
  SETTINGS = "settings",
  RECIPES = "recipes",
  PRODUCTS = "products",
  TAGS = "tags",
}
