import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";

export const ClientsEmptyStateStyled = styled("div")`
  display: grid;
  place-items: center;
  place-content: center;
  gap: 1.5rem;
  height: 100%;
`;

export const ContentWrapper = styled("div")`
  display: grid;
  place-items: center;
  place-content: center;
  gap: 1rem;
`;

export const ButtonWithCtaWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40rem;
`;

export const CtaStyled = styled("div")`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 20rem;
`;

export const CtaText = styled(Typography)`
  font-family: ${({ theme }) => theme.fontFamily.rockSalt};
  font-size: 0.75rem;
  line-height: 1.789rem;
  color: ${({ theme }) => theme.palette.secondary.main};
  align-self: center;
`;
