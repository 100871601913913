import { MouseEventHandler, useState } from "react";
import { Link } from "react-router-dom";

import {
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import {
  DuplicateFiles,
  Pen,
  ThreeVerticalPoints,
  TrashNew,
} from "@assets/icons";
import { ConfirmationModal } from "@components/ConfirmationModal";
import { useAppTranslation, useModal } from "@hooks";
import {
  useDeleteProgramMutation,
  usePostToggleFavoriteProgramMutation,
} from "@hooks/queries";
import { Actions } from "@typeDefinitions";
import { FavoriteToggleButton } from "@components/FavoriteToggleButton";

import { LaptopWrapper } from "./ActionsColumnCell.styled";

interface ActionsColumnCellProps {
  id: number;
  isFavorite: boolean;
  actions: Actions;
  namePl: string;
  nameEn: string;
}

export const ActionsColumnCell = ({
  id,
  isFavorite,
  actions,
  namePl,
  nameEn,
}: ActionsColumnCellProps) => {
  const { canEdit, canDelete } = actions;
  const { isPolishChosen, t } = useAppTranslation();
  const { breakpoints } = useTheme();
  const downMedium = useMediaQuery(`${breakpoints.down("medium")}`);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { modalOpened, onModalOpen, onModalClose } = useModal();
  const { mutate: deleteProgram, isLoading: isDeleting } =
    useDeleteProgramMutation();
  const { mutate, isLoading: isLoadingToggle } =
    usePostToggleFavoriteProgramMutation();

  const handleDelete = () => deleteProgram(id);
  const handleToggle = () => mutate(id.toString());
  const handleShowMore: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => setAnchorEl(null);

  if (downMedium)
    return (
      <div className="flex items-start">
        <FavoriteToggleButton
          onChange={handleToggle}
          isLoading={isLoadingToggle}
          isFavorite={isFavorite}
        />

        <IconButton size="small" onClick={handleShowMore}>
          {isDeleting ? (
            <CircularProgress size={16} color="inherit" />
          ) : (
            <ThreeVerticalPoints />
          )}
        </IconButton>
        <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={handleCloseMenu}>
          <MenuItem
            component={Link}
            to={`/programs/${id}/edit`}
            disabled={!canEdit}
          >
            {t("common.edit")}
          </MenuItem>
          <MenuItem component={Link} to={`/programs/${id}/copy`}>
            {t("common.copy")}
          </MenuItem>
          <MenuItem
            onClick={e => {
              e.stopPropagation();
              onModalOpen();
            }}
            disabled={!canDelete || isDeleting}
          >
            {t("common.delete")}
          </MenuItem>
        </Menu>
        <ConfirmationModal
          confirmText={t("common.delete")}
          description={t("programs.delete_modal.question", {
            variable: isPolishChosen ? namePl : nameEn,
          })}
          onCancel={onModalClose}
          open={modalOpened}
          onConfirm={handleDelete}
          title={t("programs.delete_modal.delete")}
        />
      </div>
    );

  return (
    <LaptopWrapper>
      <FavoriteToggleButton
        onChange={handleToggle}
        isLoading={isLoadingToggle}
        isFavorite={isFavorite}
      />

      {canEdit && (
        <Link to={`/programs/${id}/edit`}>
          <IconButton size="small">
            <Pen />
          </IconButton>
        </Link>
      )}
      {!canEdit && (
        <IconButton size="small" disabled>
          <Pen />
        </IconButton>
      )}

      <Link to={`/programs/${id}/copy`}>
        <IconButton size="small">
          <DuplicateFiles />
        </IconButton>
      </Link>

      <IconButton
        size="small"
        onClick={e => {
          e.stopPropagation();
          onModalOpen();
        }}
        disabled={!canDelete || isDeleting}
      >
        {isDeleting ? (
          <CircularProgress size={16} color="inherit" />
        ) : (
          <TrashNew />
        )}
      </IconButton>
      <ConfirmationModal
        confirmText={t("common.delete")}
        description={t("programs.delete_modal.question", {
          variable: isPolishChosen ? namePl : nameEn,
        })}
        onCancel={onModalClose}
        open={modalOpened}
        onConfirm={handleDelete}
        title={t("programs.delete_modal.delete")}
      />
    </LaptopWrapper>
  );
};
