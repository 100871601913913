import { Tab } from "@components/Tabs";
import { MealTypes } from "@typeDefinitions";

import { RecipeModalEntry } from "../RecipeModalEntry";
import { FoodModalEntry } from "../FoodModalEntry";
import { DietMealRecipeDto, ProductDto } from "@client";
import { NoElementsWrapper } from "../NoElementsWrapper.styled";
import { UsedRecipeDto } from "@client/schedule";
import { useAppTranslation } from "@hooks";
import { SimplifiedRecipeOrProductData } from "../../RecipeChangeSidePanel";
import { ThemeProviderWrapperNew } from "themeNew";
import { Chip, styled } from "@mui/material";
import { useMemo, useState } from "react";

interface MealTabProps {
  category: MealTypes;
  recipes: DietMealRecipeDto[];
  food: ProductDto[];
  usedRecipes: UsedRecipeDto[] | undefined;
  handleRecipeOrProductSelect: (
    data: SimplifiedRecipeOrProductData,
    type?: MealTypes,
  ) => void;
}

export const MealTab = ({
  category,
  recipes,
  food,
  usedRecipes,
  handleRecipeOrProductSelect,
}: MealTabProps) => {
  const { t } = useAppTranslation();
  const [showUnused, setShowUnused] = useState(false);

  const getUsedRecipe = (recipeName: string) => {
    return usedRecipes?.find(
      ({ title }) => title.toLowerCase() === recipeName.toLowerCase(),
    );
  };

  const filteredRecipes = useMemo(() => {
    if (!showUnused) return recipes;

    return recipes.filter(({ recipe: { name, servings } }) => {
      const usedServings = getUsedRecipe(name)?.servings ?? servings;
      return usedServings % servings !== 0;
    });
  }, [showUnused, recipes]);

  return (
    <div className="p-2">
      <ThemeProviderWrapperNew>
        <CustomChip
          label="Niewykorzystane porcje"
          data-chip="true"
          color={showUnused ? "primary" : "default"}
          onClick={() => setShowUnused(!showUnused)}
          variant="outlined"
        />
      </ThemeProviderWrapperNew>
      {category === MealTypes.RECIPE && !recipes?.length && (
        <NoElementsWrapper>
          {t("patient.schedule.no_recipes")}
        </NoElementsWrapper>
      )}
      {category === MealTypes.FOOD && !food?.length && (
        <NoElementsWrapper>
          {t("patient.schedule.no_products")}
        </NoElementsWrapper>
      )}
      {category === MealTypes.RECIPE
        ? filteredRecipes.map(({ recipe, servings }) => {
            const usedRecipe = getUsedRecipe(recipe.name);
            return (
              <RecipeModalEntry
                key={recipe.id}
                recipe={recipe}
                onSelect={handleRecipeOrProductSelect}
                uses={usedRecipe?.quantity}
                totalServings={recipe.servings}
                usedServings={usedRecipe?.servings}
                defaultSelectedServings={servings}
              />
            );
          })
        : food.map(food => (
            <FoodModalEntry
              key={food.id}
              food={food}
              onSelect={handleRecipeOrProductSelect}
              uses={getUsedRecipe(food.description)?.quantity}
            />
          ))}
    </div>
  );
};

export const CustomChip = styled(Chip)`
  margin-top: 4px;

  &.MuiChip-root {
    height: 2rem;
  }

  & .MuiChip-label {
    line-height: 20px;
    font-size: 12px;
    padding: 4px 8px;
  }

  &.MuiChip-colorPrimary {
    background: ${({ theme }) => theme.palette.primary.medium};
    border-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
    border: 1px solid;
  }

  &.MuiChip-colorDefault {
    background: ${({ theme }) => theme.palette.background.default};
    border-color: ${({ theme }) => theme.palette.primary.medium};
    color: ${({ theme }) => theme.colors.neutral.dark[700]};
  }
` as typeof Chip;
