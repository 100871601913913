import * as yup from "yup";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import {
  IdDto,
  idSchema,
  ImageDto,
  imageSchema,
  NutrientDto,
  nutrientSchema,
  TranslationLangDto,
  translationLangSchema,
} from "@client/common";

export const postDietAutoGenerateMeal = async (
  dietId: string,
  payload: PostDietAutoGenerateMealRequest,
): Promise<ApiResponse<GeneratedMealDto>> => {
  const response = await fetchData(
    `/dietitian/diets/${dietId}/auto-generate-meal`,
    APIMethods.POST,
    payload,
  );

  return responseSchema.validate(response);
};

export interface PostDietAutoGenerateMealRequest {
  tagsId: number[];
  mealId: number;
}

interface MealDto {
  id: number;
  name: string;
  translations: TranslationLangDto[];
}

interface ItemDto {
  type: "recipe" | "product";
}

export interface RecipeSearchItemDto extends ItemDto {
  type: "recipe";
  servings: number;
  recipe: RecipePreviewDto;
}

interface RecipePreviewDto {
  id: number;
  name: string;
  servings: number;
  translations: TranslationLangDto[];
  nutrients: NutrientDto[];
  tags: IdDto[];
  image: ImageDto | null;
}

export interface ProductSearchItemDto extends ItemDto {
  type: "product";
  grams: number;
  measure: IdDto;
  product: ProductPreviewDto;
}

interface ProductPreviewDto {
  id: number;
  name: string;
  translations: TranslationLangDto[];
  nutrients: NutrientDto[];
  image: ImageDto;
}

export interface GeneratedMealDto {
  meal: MealDto;
  items: (ProductSearchItemDto | RecipeSearchItemDto)[];
}

const recipePreviewSchema = yup.object({
  id: yup.number().required(),
  name: yup.string().required(),
  servings: yup.number().required(),
  translations: yup.array().of(translationLangSchema).required(),
  nutrients: yup.array().of(nutrientSchema).required(),
  tags: yup.array().of(idSchema).required(),
  image: imageSchema.nullable().default(null),
});

const productPreviewSchema = yup.object({
  id: yup.number().required(),
  name: yup.string().required(),
  translations: yup.array().of(translationLangSchema).required(),
  nutrients: yup.array().of(nutrientSchema).required(),
  image: imageSchema.required(),
});

const recipeItemSchema = yup.object({
  type: yup.string().oneOf(["recipe"]).required(),
  servings: yup.number().required(),
  recipe: recipePreviewSchema.required(),
});

const productItemSchema = yup.object({
  type: yup.string().oneOf(["product"]).required(),
  grams: yup.number().required(),
  measure: yup
    .object({
      id: yup.number().required(),
    })
    .required(),
  product: productPreviewSchema.required(),
});

const mealSchema = yup.object({
  id: yup.number().required(),
  name: yup.string().required(),
  translations: yup.array().of(translationLangSchema).required(),
});

export const generatedMealSchema = yup.object({
  meal: mealSchema.required(),
  items: yup
    .array()
    .of(
      yup.lazy(value => {
        if (value?.type === "recipe") return recipeItemSchema;
        return productItemSchema;
      }),
    )
    .required(),
});

const responseSchema = yup.object({
  data: generatedMealSchema.required(),
});
