import { useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { useTaskFilterOptions } from "./useTaskFilterOptions";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDeepCompareEffect } from "react-use";
import { useQueryParams } from "@hooks/queries/useQueryParams";
import { useMemo } from "react";

enum LocalStorageKey {
  DietitianIds = "dietitianIds",
}

interface ParsedSavedDietitianIds {
  accountId: string;
  dietitiansIds: string[];
}

export const useFilterTaskForm = () => {
  const { deadlineOptions, defaultDietitianIds, account, isClinicAdmin } =
    useTaskFilterOptions();

  const { update } = useQueryParams();

  const parsedSavedDietitianIds: ParsedSavedDietitianIds | null =
    useMemo(() => {
      const savedDietitianIds = localStorage.getItem(
        LocalStorageKey.DietitianIds,
      );
      if (savedDietitianIds) {
        try {
          return JSON.parse(savedDietitianIds) as ParsedSavedDietitianIds;
        } catch (error) {
          return null;
        }
      }
      return null;
    }, []);

  const resolver = yup.object().shape({
    deadline: yup
      .string()
      .oneOf(deadlineOptions.map(option => option.id))
      .nullable(),
    customTagsIds: yup.array().of(yup.string()),
    dietitiansIds: yup.array().of(yup.string()),
    dueDateFrom: yup.string().nullable(),
    dueDateTo: yup.string().nullable(),
  });

  type TaskFilterFormSchema = yup.InferType<typeof resolver>;

  const hasCurrentAccountSavedDietitians =
    parsedSavedDietitianIds?.accountId !== account?.id;

  const defaultValues = {
    deadline: "",
    customTagsIds: [],
    dietitiansIds: isClinicAdmin ? parsedSavedDietitianIds?.dietitiansIds : [],
    dueDateFrom: null,
    dueDateTo: null,
  };

  const form = useForm<TaskFilterFormSchema>({
    defaultValues: defaultValues,
    resolver: yupResolver(resolver),
    mode: "onChange",
  });

  const formData = useWatch({ control: form.control });

  useDeepCompareEffect(() => {
    if (hasCurrentAccountSavedDietitians && isClinicAdmin) {
      form.reset({
        ...form.getValues(),
        dietitiansIds: defaultDietitianIds,
      });
    }
  }, [
    defaultDietitianIds,
    parsedSavedDietitianIds,
    hasCurrentAccountSavedDietitians,
    isClinicAdmin,
  ]);

  useDeepCompareEffect(() => {
    const { dietitiansIds } = formData;
    if (dietitiansIds && account?.id && isClinicAdmin) {
      localStorage.setItem(
        LocalStorageKey.DietitianIds,
        JSON.stringify({ accountId: account.id, dietitiansIds }),
      );
    }
    update(formData);
  }, [formData]);

  return form;
};
