import { useQuery } from "@tanstack/react-query";

import { fetchClient, FetchClientResponse } from "@client";
import { ApiResponse } from "@typeDefinitions";

import { QueryOptionsTyped } from "./types";

export const fetchClientQueryKey = "fetchClientQueryKey";

export const useFetchClientQuery = (
  id: number | string,
  options?: QueryOptionsTyped<ApiResponse<FetchClientResponse>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchClientQueryKey, Number(id)],
    () => fetchClient(id),
    options,
  );

  return {
    client: data?.data,
    ...rest,
  };
};

export const useFetchClientQueryNew = (
  id: number | string,
  options?: QueryOptionsTyped<ApiResponse<FetchClientResponse>>,
) => {
  return useQuery({
    queryKey: [fetchClientQueryKey, Number(id)],
    queryFn: () => fetchClient(id),
    ...options,
  });
};
