import dayjs from "dayjs";

import { useAppTranslation } from "@hooks";
import { useFetchClientEventsQuery } from "@hooks/queries/useFetchClientEventsQuery";

import { ClientProfileCard, TitleSection } from "../../ClientProfile.styled";
import { HistoryItem } from "./HistoryItem";
import { HistorySectionWrapper } from "./HistorySection.styled";
import { DateDivider } from "./components/DateDivider";

interface HistorySectionProps {
  id: number;
  maxHeight?: number;
  flexVariant?: boolean;
}

export const HistorySection = ({
  id,
  maxHeight,
  flexVariant = false,
}: HistorySectionProps) => {
  const { t } = useAppTranslation();
  const { events } = useFetchClientEventsQuery(id.toString());

  let currentDate = "";

  return (
    <HistorySectionWrapper flexVariant={flexVariant}>
      <ClientProfileCard maxHeight={maxHeight}>
        <TitleSection>
          <p>{t("client_profile.history.client_history")}</p>
        </TitleSection>
        <div>
          {events?.map(event => {
            const { createdAt } = event;
            const eventDate = dayjs(createdAt);

            if (currentDate !== eventDate.format("DD.MM")) {
              currentDate = eventDate.format("DD.MM");
              return (
                <div key={event.id}>
                  <DateDivider>{eventDate.format("DD.MM")}</DateDivider>
                  <HistoryItem event={event} />
                </div>
              );
            }
            return <HistoryItem key={event.id} event={event} />;
          })}
        </div>
      </ClientProfileCard>
    </HistorySectionWrapper>
  );
};
