import { FoodSearchResourceDto } from "@client";
import { ProductRowIf } from "./ProductsDataGrid";

export const mapProductRows = (
  products: FoodSearchResourceDto[] | undefined,
  userId: number,
): ProductRowIf[] => {
  if (!products) return [];

  return products.map(
    ({ id, namePl, nameEn, tags, macros, favorite, actions, category }) => ({
      id: id,
      namePl: namePl,
      nameEn: nameEn ?? namePl,
      tags: tags.map(t => t.id.toString()),
      nutrients: macros,
      isFavorite: favorite.includes(userId),
      actions: actions,
      categoryId: category?.id ?? null,
    }),
  );
};
