import { MouseEvent, MouseEventHandler, useContext, useState } from "react";
import {
  ActionButtons,
  ChatButton,
  ExtraButtonsWrapper,
  StyledAlert,
} from "./ExtraButtons.styled";
import {
  DollarSign,
  PaperPlane,
  Plus,
  SpeechBubble,
  ThreeVerticalPoints,
} from "@assets/icons";
import { Button, Tooltip } from "@mui/material";
import { useAppTranslation, useUpdateClientPayments } from "@hooks";
import { Link, To } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { ClientProfileActionButtons } from "../ClientProfileActionButtons";
import { ConfirmModalWrapper } from "@components/ConfirmModalWrapper";
import { getSubscriptionInfo } from "@utils/subscription";

interface ExtraButtonsProps {
  chat?: boolean;
  id: number;
  onVisitModal?: MouseEventHandler<HTMLButtonElement>;
  onQuestionnaireModal?: MouseEventHandler<HTMLButtonElement>;
  hasEmail?: boolean;
  isActive?: boolean;
  onChatOpen?: () => void;
}
export const ExtraButtons = ({
  chat,
  id,
  onVisitModal,
  onQuestionnaireModal,
  hasEmail,
  isActive,
  onChatOpen,
}: ExtraButtonsProps) => {
  const { t } = useAppTranslation();
  const { isTrial } = getSubscriptionInfo();
  const { mutate, isLoading } = useUpdateClientPayments();
  const disabled = !isActive || !hasEmail || (isActive && !hasEmail);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActivateClient = () => {
    mutate({
      id: id.toString(),
      payload: { activeUntil: null, isActive: true },
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ExtraButtonsWrapper>
        <Link
          to={(chat ? `/chat/${id}` : undefined) as To}
          onClick={e => e.preventDefault()}
        >
          <ChatButton disabled={!chat} onClick={onChatOpen}>
            <SpeechBubble />
          </ChatButton>
        </Link>
        <Tooltip
          placement="top"
          arrow
          title={
            !hasEmail
              ? t("client_profile.provide_email")
              : t("client_profile.activate_client_tooltip")
          }
          disableHoverListener={!disabled}
        >
          <div>
            <Button
              disabled={!hasEmail || !isActive}
              variant="outlined"
              onClick={onQuestionnaireModal}
              startIcon={
                <PaperPlane size="w-4.5 h-4.5" className="stroke-current" />
              }
            >
              {t("client_profile.send_questionnaire")}
            </Button>
          </div>
        </Tooltip>
        {isActive ? (
          <>
            <Button
              className="w-max"
              variant="contained"
              onClick={onVisitModal}
              startIcon={<Plus size="w-3 h-3" className="stroke-current" />}
            >
              {t("common.newVisit")}
            </Button>
            <ActionButtons onClick={handleClick}>
              <ThreeVerticalPoints />
            </ActionButtons>
          </>
        ) : (
          <>
            <Button
              className="w-max"
              variant="outlined"
              onClick={onVisitModal}
              startIcon={<Plus size="w-3 h-3" className="stroke-current" />}
            >
              {t("common.newVisit")}
            </Button>
            <ConfirmModalWrapper
              onConfirm={handleActivateClient}
              confirmationText={t("client_profile.confirm_activate_tooltip")}
              submitText={t("client_profile.activate_client")}
              extraContent={
                !isTrial && (
                  <StyledAlert
                    variant="filled"
                    icon={<DollarSign size="w-4 h-4" />}
                  >
                    {t("client_profile.confirm_activate_context")}
                  </StyledAlert>
                )
              }
            >
              <LoadingButton
                className="w-max"
                variant="contained"
                loading={isLoading}
              >
                {t("client_profile.activate")}
              </LoadingButton>
            </ConfirmModalWrapper>
          </>
        )}
      </ExtraButtonsWrapper>
      <ClientProfileActionButtons
        open={open}
        handleClose={handleClose}
        anchorEl={anchorEl}
        id={id}
        setAnchorEl={setAnchorEl}
        hasEmail={hasEmail}
      />
    </>
  );
};
