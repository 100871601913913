import { Badge, styled } from "@mui/material";

export const StyledBadge = styled(Badge)`
  & .MuiBadge-badge {
    height: 0.75rem;
    min-width: 0.75rem;
    padding: 0;
    font-size: 0.625rem;
    top: 4px;
    right: 3px;
  }
`;
