import { useMemo } from "react";
import { FormProvider } from "react-hook-form";

import {
  SystemQuestionDto,
  UserHeightAnswerResourceDto,
} from "@client/surveys";
import { useUpdateSingedSurveyMutation } from "@hooks/queries/surveys";
import { decimalThreeDigitsInput } from "@utils/inputs";

import { useAppTranslation } from "@hooks";
import { useUpdateValidationContext } from "../../context/useUpdateValidationContext";
import { useParseUrl } from "../../useParseUrl";
import { AutosaveOnBlurWatch } from "../AutosaveOnBlurWatch";
import { BlurContextProvider } from "../BlurContext";
import { FormTextFieldWrapper } from "../BlurContext/FormTextFieldWrapper";
import { Question } from "../Question/Question";
import {
  HeightAnswerFormInputs,
  mapHeightAnswerForm,
  mapHeightAnswerRequest,
  useHeightAnswerForm,
} from "../forms/useHeightAnswerForm";

interface SystemQuestionUserHeightProps {
  question: SystemQuestionDto;
  answer?: UserHeightAnswerResourceDto;
  listIndex: number;
  totalQuestions: number;
  readOnly?: boolean;
}
export const SystemQuestionUserHeight = ({
  question: {
    title,
    config: { required },
    id,
  },
  answer,
  readOnly,
  ...rest
}: SystemQuestionUserHeightProps) => {
  const { t } = useAppTranslation();
  const { url } = useParseUrl();
  const mappedForm = useMemo(
    () => mapHeightAnswerForm(answer?.answer),
    [answer?.answer],
  );
  const { formState, handleSubmit, ...form } = useHeightAnswerForm(
    mappedForm,
    required,
  );
  const { mutate, isLoading } = useUpdateSingedSurveyMutation();

  const onSubmit = (data: HeightAnswerFormInputs) => {
    if (url) mutate({ payload: mapHeightAnswerRequest(data, id), url });
  };

  const { errors, touchedFields } = formState;
  const hasError = !!Object.values(errors).length;

  useUpdateValidationContext(id, touchedFields, handleSubmit);
  return (
    <FormProvider formState={formState} handleSubmit={handleSubmit} {...form}>
      <BlurContextProvider>
        <Question
          {...rest}
          title={title}
          description={null}
          required={required}
          hasError={hasError}
          readOnly={readOnly}
        >
          <FormTextFieldWrapper
            control={form.control}
            name="value"
            size="small"
            placeholder={t(
              "questionnaires.questions.system_user_height.placeholder",
            )}
            FormHelperTextProps={{ hidden: true }}
            InputProps={{
              readOnly,
              error: !readOnly && hasError,
              inputComponent: decimalThreeDigitsInput,
            }}
          />
        </Question>

        <AutosaveOnBlurWatch onSubmit={onSubmit} isLoading={isLoading} />
      </BlurContextProvider>
    </FormProvider>
  );
};
