import { Card, Chip, Popper, styled } from "@mui/material";

export const CardStyled = styled(Card)`
  display: flex;
  flex-wrap: wrap;
  width: max-content;
  max-width: 380px;
  padding: 16px;
  gap: 8px;
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.boxShadows.card};
`;

export const ShowMoreChip = styled(Chip)`
  &.MuiChip-outlined {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    border: none;
    background: transparent;

    &:hover {
      background-color: ${({ theme }) => theme.palette.primary.medium};
    }
  }
`;

export const TagsWrapper = styled("div")`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  gap: 8px;
`;

export const ExpandableTagsStyled = styled("div")`
  display: flex;
  gap: 8px;
  height: 28px;
  overflow: hidden;
`;

export const PopperStyled = styled(Popper)`
  z-index: 10000;
`;
