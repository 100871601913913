import dayjs from "dayjs";
import { MEETING_TYPE } from "@utils/scheduleEvent";
import { AddEventModalInput } from "../_hooks/useScheduleVisitForm";

export const validateFiveMinutesStep = (value: Date) =>
  dayjs(value).minute() % 5 === 0;

export const getDefaultFormValues = (
  accountId: number | undefined,
  passedStartDate: Date | null,
): AddEventModalInput => {
  const date = dayjs();
  const minutes = date.minute() % 5;

  const startDate = passedStartDate
    ? dayjs(passedStartDate)
    : dayjs().subtract(minutes, "minutes").add(1, "hour");

  const endDate = startDate.add(1, "hour");

  return {
    eventType: MEETING_TYPE.CONSULTATION,
    startDate: startDate.toDate(),
    finishDate: endDate.toDate(),
    isAllDay: false,
    dietitianId: accountId ?? null,
    price: null,
    clinicFacilityId: null,
    additionalInfo: "",
    currency: null,
    clinicProductId: null,
    eventName: "",
    patientId: null,
    notification: false,
  };
};
