import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const CheckInCircle = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="14" viewBox="0 0 14 14" {...props}>
      <path
        d="M6.69531 9.25781C6.54297 9.39323 6.3737 9.46094 6.1875 9.46094C6.0013 9.46094 5.83203 9.39323 5.67969 9.25781L4.05469 7.63281C3.91927 7.48047 3.85156 7.3112 3.85156 7.125C3.85156 6.9388 3.91927 6.76953 4.05469 6.61719C4.20703 6.48177 4.3763 6.41406 4.5625 6.41406C4.7487 6.41406 4.91797 6.48177 5.07031 6.61719L6.1875 7.73438L8.92969 4.99219C9.08203 4.85677 9.2513 4.78906 9.4375 4.78906C9.6237 4.78906 9.79297 4.85677 9.94531 4.99219C10.0807 5.14453 10.1484 5.3138 10.1484 5.5C10.1484 5.6862 10.0807 5.85547 9.94531 6.00781L6.69531 9.25781ZM13.5 7.125C13.4831 8.34375 13.1868 9.43555 12.6113 10.4004C12.0358 11.3822 11.2572 12.1608 10.2754 12.7363C9.31055 13.3118 8.21875 13.6081 7 13.625C5.78125 13.6081 4.68945 13.3118 3.72461 12.7363C2.74284 12.1608 1.96419 11.3822 1.38867 10.4004C0.813151 9.43555 0.516927 8.34375 0.5 7.125C0.516927 5.90625 0.813151 4.81445 1.38867 3.84961C1.96419 2.86784 2.74284 2.08919 3.72461 1.51367C4.68945 0.938151 5.78125 0.641927 7 0.625C8.21875 0.641927 9.31055 0.938151 10.2754 1.51367C11.2572 2.08919 12.0358 2.86784 12.6113 3.84961C13.1868 4.81445 13.4831 5.90625 13.5 7.125ZM7 1.84375C5.51042 1.8776 4.26628 2.39388 3.26758 3.39258C2.26888 4.39128 1.7526 5.63542 1.71875 7.125C1.7526 8.61458 2.26888 9.85872 3.26758 10.8574C4.26628 11.8561 5.51042 12.3724 7 12.4062C8.48958 12.3724 9.73372 11.8561 10.7324 10.8574C11.7311 9.85872 12.2474 8.61458 12.2812 7.125C12.2474 5.63542 11.7311 4.39128 10.7324 3.39258C9.73372 2.39388 8.48958 1.8776 7 1.84375Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
