import { useMemo } from "react";
import { FormProvider } from "react-hook-form";

import { QuestionRadioDto, RadioAnswerResourceDto } from "@client/surveys";
import { useUpdateSingedSurveyMutation } from "@hooks/queries/surveys";

import { useUpdateValidationContext } from "../../context/useUpdateValidationContext";
import { useParseUrl } from "../../useParseUrl";
import { AutosaveOnBlurWatch } from "../AutosaveOnBlurWatch";
import { BlurContextProvider } from "../BlurContext";
import { Question } from "../Question/Question";
import {
  RadioAnswerFormInputs,
  mapRadioAnswerForm,
  mapRadioAnswerRequest,
  useRadioAnswerForm,
} from "../forms";
import { ControlledRadioContent } from "./ControlledRadioContent";

interface QuestionRadioProps {
  question: QuestionRadioDto;
  answer?: RadioAnswerResourceDto;
  listIndex: number;
  totalQuestions: number;
  readOnly?: boolean;
}
export const QuestionRadio = ({
  question: {
    title,
    description,
    config: { required, options, other },
    id,
  },
  answer,
  readOnly,
  ...rest
}: QuestionRadioProps) => {
  const { url } = useParseUrl();
  const mappedForm = useMemo(
    () => mapRadioAnswerForm(answer?.answer),
    [answer?.answer],
  );
  const { handleSubmit, formState, control, ...form } = useRadioAnswerForm(
    mappedForm,
    required,
  );
  const { mutate, isLoading } = useUpdateSingedSurveyMutation();

  const onSubmit = (data: RadioAnswerFormInputs) => {
    if (url) mutate({ payload: mapRadioAnswerRequest(data, id), url });
  };

  const { errors, touchedFields } = formState;
  const hasError = !!Object.values(errors).length;

  useUpdateValidationContext(id, touchedFields, handleSubmit);

  return (
    <FormProvider
      handleSubmit={handleSubmit}
      formState={formState}
      control={control}
      {...form}
    >
      <BlurContextProvider>
        <Question
          {...rest}
          title={title}
          description={description}
          required={required}
          hasError={hasError}
          readOnly={readOnly}
        >
          <ControlledRadioContent
            options={options}
            other={other}
            readOnly={readOnly}
          />
        </Question>

        <AutosaveOnBlurWatch onSubmit={onSubmit} isLoading={isLoading} />
      </BlurContextProvider>
    </FormProvider>
  );
};
