import { Card, styled } from "@mui/material";
import { FormTextField } from "@components/FormTextField";

export const MealFormWrapper = styled("div")`
  display: grid;
  gap: 0.75rem;
`;

export const StyledCard = styled(Card)`
  display: grid;
  gap: 1rem;
  padding: 1.25rem;
`;

export const StyledFormTextField = styled(FormTextField)`
  max-width: 30rem;
  flex: 1;
  height: 2.5rem;
`;
