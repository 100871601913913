import { useAppTranslation } from "@hooks";
import { BinaryAnswer } from "@utils";
import {
  FieldValues,
  Path,
  useController,
  useFormContext,
} from "react-hook-form";
import { FilterButton, FiltersWrapper, Label } from "./OnlyPolishFilter.styled";

export interface OnlyPolishFilterProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
}
export const OnlyPolishFilter = <T extends FieldValues>({
  name,
  label,
}: OnlyPolishFilterProps<T>) => {
  const { t } = useAppTranslation();
  const { control } = useFormContext<T>();

  const {
    field: { value, onChange },
  } = useController<T>({ control, name });

  const toggleSelect = () => {
    const newValue =
      value === BinaryAnswer.YES ? BinaryAnswer.NO : BinaryAnswer.YES;
    onChange(newValue);
  };

  if (!value) return <></>;

  return (
    <FiltersWrapper>
      <Label>{label}</Label>
      <FilterButton
        variant="outlined"
        selected={value === BinaryAnswer.YES}
        onClick={toggleSelect}
      >
        {t("products.polish_only")}
      </FilterButton>
    </FiltersWrapper>
  );
};
