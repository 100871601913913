import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const cloneScheduleMeal = (
  programId: number,
  payload: CloneScheduleMealRequest,
) => {
  return fetchData(
    `/dietitian/programs/${programId}/schedule/meals/clone-meal`,
    APIMethods.PUT,
    payload,
  );
};

export interface CloneScheduleMealRequest {
  programScheduleMeal: MealDto;
  mealsToReplace: MealDto[];
}

interface MealDto {
  id: number;
}
