import { TextField, styled } from "@mui/material";

export const ButtonPlaceholder = styled("div")`
  width: 40px;
  height: 40px;
`;

export const Title = styled("span")`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
  text-transform: uppercase;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.medium}px) {
    padding: 8px 8px 8px 16px;
  }
`;

export const SummaryWrapper = styled("div")`
  display: flex;
  gap: 8px;
  padding: 8px 0;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallMax}px) {
    flex-direction: column;
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.medium}px) {
    align-items: flex-end;
    background: ${({ theme }) => theme.palette.primary.light2};
    border-radius: 8px;
  }
`;

export const TextFieldStyled = styled(TextField)`
  pointer-events: none;

  & .MuiOutlinedInput-root.MuiInputBase-colorSuccess {
    border-radius: 8px;
    background: ${({ theme }) => theme.palette.success.light};

    & fieldset {
      border-color: ${({ theme }) => theme.palette.success.dark};
    }
  }
  & .MuiOutlinedInput-root.MuiInputBase-colorWarning {
    border-radius: 8px;
    background: ${({ theme }) => theme.palette.warning.light};

    & fieldset {
      border-color: ${({ theme }) => theme.palette.warning.dark};
    }
  }
  & .MuiOutlinedInput-root.MuiInputBase-colorError {
    border-radius: 8px;
    background: ${({ theme }) => theme.palette.error.light};

    & fieldset {
      border-color: ${({ theme }) => theme.palette.error.dark};
    }
  }
`;
