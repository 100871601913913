import { ExpandableChips } from "@components/ExpandableChips";
import { useAppParams, useAppTranslation } from "@hooks";
import { LabeledDetail } from "@components/PreviewDetails/LabeledDetail";
import { PreviewDetails } from "@components/PreviewDetails";
import { Actions, Nutrient } from "@typeDefinitions";

import {
  HeaderWrapper,
  PreviewWrapper,
  RecipeDetailsWrapper,
  StyledImagePlaceholder,
  StyledImg,
} from "./RecipeDetails.styled";
import { useDeleteRecipeMutation } from "@hooks/queries";
import { useNavigate } from "react-router-dom";
import { RECIPES } from "@routes";
import { ImageOutlined } from "@mui/icons-material";

interface RecipeDetailsProps {
  name: string;
  nutrients: Nutrient[];
  actions: Actions;
  tags: string[];
  productsCount: number;
  servings: number;
  mediaUrl?: string | null;
}

export const RecipeDetails = ({
  name,
  nutrients,
  actions,
  tags,
  productsCount,
  servings,
  mediaUrl,
}: RecipeDetailsProps) => {
  const { t } = useAppTranslation();
  const { recipeId } = useAppParams();
  const navigate = useNavigate();
  const { mutate } = useDeleteRecipeMutation();
  const handleDelete = (id: string) => {
    mutate(Number(id), { onSuccess: () => navigate(RECIPES) });
  };

  return (
    <RecipeDetailsWrapper>
      <HeaderWrapper>
        {mediaUrl && (
          <StyledImg
            src={mediaUrl}
            srcSet={mediaUrl}
            alt={t("recipe.recipe_img")}
          />
        )}
        {!mediaUrl && (
          <StyledImagePlaceholder>
            <ImageOutlined color="disabled" />
          </StyledImagePlaceholder>
        )}
        <PreviewWrapper>
          <PreviewDetails
            id={recipeId}
            path="/recipes"
            name={name}
            onDelete={handleDelete}
            nutrients={nutrients}
            actions={actions}
          />
          <div className="flex justify-between">
            <div className="flex gap-4">
              <LabeledDetail
                label={`${t("recipe.servings_count")}:`}
                value={t("recipe.recipe_complex", { count: servings })}
              />
              <LabeledDetail
                label={`${t("recipe.products_count")}:`}
                value={productsCount.toString()}
              />
            </div>
          </div>
        </PreviewWrapper>
      </HeaderWrapper>
      <ExpandableChips variant="newFilled" labels={tags} />
    </RecipeDetailsWrapper>
  );
};
