import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { SystemQuestionDto } from "@client/surveys";

import { QuestionFormInputs } from "../Question/useQuestionForm";

export const useSystemQuestionConfigForm = (
  values?: SystemQuestionConfigFormInputs,
) => {
  const [init, setInit] = useState(true);
  const form = useForm<SystemQuestionConfigFormInputs>({
    defaultValues: {
      title: "",
      description: null,
      required: false,
    },
  });

  useEffect(() => {
    if (init && values) {
      form.reset(values);
      setInit(false);
    }
  }, [values]);

  return form;
};

export interface SystemQuestionConfigFormInputs extends QuestionFormInputs {
  required: boolean;
}

export const mapSystemQuestionConfigForm = ({
  title,
  description,
  config,
}: SystemQuestionDto): SystemQuestionConfigFormInputs => {
  return {
    title,
    description,
    required: config.required,
  };
};

export const mapSystemQuestionConfigRequest = (
  { description, required, title }: SystemQuestionConfigFormInputs,
  { id, type }: SystemQuestionDto,
): SystemQuestionDto => {
  return {
    id,
    title,
    description,
    config: { required },
    type,
  };
};
