type ProgramId = number;
type DietId = number;
type DietMealId = number;

export const programPreviewKeys = {
  base: ["program_preview"] as const,
  program: (id: ProgramId) => [...programPreviewKeys.base, id],
  programDiet: ({
    programId,
    dietId,
  }: {
    programId: ProgramId;
    dietId: DietId;
  }) => [...programPreviewKeys.program(programId), "diets", dietId],
  programDietMeal: ({
    programId,
    dietMealId,
  }: {
    programId: ProgramId;
    dietMealId: DietMealId;
  }) => [...programPreviewKeys.program(programId), "diet_meals", dietMealId],
  programSections: (id: ProgramId) => [
    ...programPreviewKeys.program(id),
    "sections",
  ],
  programTags: (id: ProgramId) => [...programPreviewKeys.program(id), "tags"],
};
