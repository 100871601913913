import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const updateClinicFacility = async (
  id: number,
  payload: UpdateClinicFacilityRequest,
) => {
  return await fetchData(
    `/dietitian/clinic/facilities/${id}`,
    APIMethods.PUT,
    payload,
  );
};

export interface UpdateClinicFacilityRequest {
  name: string;
  email: string;
  isMain: boolean;
  phone: PhoneDto;
  address: AddressDto;
}

interface PhoneDto {
  prefix: string | null;
  number: string | null;
}

interface AddressDto {
  street: string | null;
  streetNumber: string | null;
  houseNumber: string | null;
  postCode: string | null;
  city: string | null;
}
