import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import * as yup from "yup";
import { CLIENT_PROFILE_EVENT_STATUS } from "@utils";

export const fetchClientEvents = async (
  id: string,
): Promise<
  ApiResponse<(SurveySentEventDto | SurveyRespondedEventDto | EmptyEventDto)[]>
> => {
  const response = await fetchData(
    `/dietitian/patients/${id}/events`,
    APIMethods.GET,
  );

  return await fetchClientEventsSchema.validate(response);
};

const eventDataSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
});

const clientEventSchema = yup.object().shape({
  id: yup.string().required(),
  data: eventDataSchema.required(),
  type: yup
    .number<
      | CLIENT_PROFILE_EVENT_STATUS.SURVEY_SENT
      | CLIENT_PROFILE_EVENT_STATUS.SURVEY_RESPONDED
    >()
    .required(),
  createdAt: yup.string().required(),
});

const clientEmptyEventSchema = yup.object().shape({
  id: yup.string().required(),
  data: yup.number().nullable().default(null),
  type: yup.number<EmptyTypes>().required(),
  createdAt: yup.string().required(),
});

const fetchClientEventsSchema = yup.object().shape({
  data: yup
    .array()
    .of(
      yup.lazy(value => {
        switch (value.type) {
          case CLIENT_PROFILE_EVENT_STATUS.SURVEY_SENT:
            return clientEventSchema.required();
          case CLIENT_PROFILE_EVENT_STATUS.SURVEY_RESPONDED:
            return clientEventSchema.required();
          default:
            return clientEmptyEventSchema.required();
        }
      }),
    )
    .required(),
});

export interface EventTypeDto {
  id: number;
}

export interface EventDto<T> {
  id: string;
  data: T;
  type: CLIENT_PROFILE_EVENT_STATUS;
  createdAt: string;
}

export interface SurveyVODto {
  id: number;
  name: string;
}
export interface SurveySentEventDto extends EventDto<SurveyVODto> {
  type: CLIENT_PROFILE_EVENT_STATUS.SURVEY_SENT;
}

export interface SurveyRespondedEventDto extends EventDto<SurveyVODto> {
  type: CLIENT_PROFILE_EVENT_STATUS.SURVEY_RESPONDED;
}
export interface EmptyEventDto extends EventDto<number | null> {
  type: EmptyTypes;
}

export type EmptyTypes =
  | CLIENT_PROFILE_EVENT_STATUS.CREATED
  | CLIENT_PROFILE_EVENT_STATUS.ACTIVATED
  | CLIENT_PROFILE_EVENT_STATUS.DEACTIVATED
  | CLIENT_PROFILE_EVENT_STATUS.VISIT_CREATED
  | CLIENT_PROFILE_EVENT_STATUS.SUBSCRIPTION_ACTIVATED
  | CLIENT_PROFILE_EVENT_STATUS.PROGRAM_CREATED
  | CLIENT_PROFILE_EVENT_STATUS.MOBILE_APP_ACTIVATED
  | CLIENT_PROFILE_EVENT_STATUS.MOBILE_APP_DEACTIVATED;
