import { Box, styled } from "@mui/material";
import { TabList } from "@mui/lab";
import { Tab } from "@mui/material";

export const TabsWrapper = styled("div")`
  display: grid;
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily.regular};
`;

export const StyledBox = styled(Box)`
  max-width: fit-content;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;

export const StyledTabList = styled(TabList)`
  min-height: 2.5rem;
  width: 100%;
  background: ${({ theme }) => theme.colors.neutral.light[100]};
  padding: 0.25rem;
  border: 1px solid ${({ theme }) => theme.palette.primary.medium};
  border-radius: 0.5rem;

  & .MuiTabs-indicator {
    display: none;
  }

  & .MuiTabs-flexContainer {
    gap: 0.25rem;

    & button {
      min-height: 2rem;
      border-radius: 0.5rem;
      padding: 0.375rem 0.5rem;
      color: ${({ theme }) => theme.colors.neutral.dark[700]};

      &.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
        background: ${({ theme }) => theme.palette.primary.medium};
        color: ${({ theme }) => theme.palette.primary.main};
      }
    }
  }
`;

export const StyledTab = styled(Tab)`
  color: ${({ theme }) => theme.palette.grey[900]};
  text-transform: unset;
  font-size: 1rem;
  background: ${({ theme }) => theme.colors.neutral.light[100]};
`;

export const WhatsNewLabel = styled("span")`
  font: var(--font-figtree-medium);
  font-size: 14px;
  line-height: 24px;
  background: linear-gradient(#485ad0, #c977f0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-wrap: nowrap;
`;
