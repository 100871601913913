import _, { omit } from "lodash";
import { memo, useEffect, useMemo, useState } from "react";
import { useController } from "react-hook-form";

import { Accordion } from "@mui/material";

import {
  ProgramFormInput,
  useAppTranslation,
  useProgramSectionsDetailedDictionary,
} from "@hooks";
import { Edit, Plus } from "@assets/icons";
import { GENERAL_INFORMATION_SECTION_CATEGORY } from "@utils/program";
import { useFetchProgramSectionsCategoryQuery } from "@hooks/queries";
import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";
import { Labeled } from "@components/Labeled";
import { SortableList } from "@components/SortableList";
import { Checkbox } from "@components/Checkbox";
import { IconButton } from "@components/IconButton";
import { Button } from "@components/Button";

import { EditSectionModal } from "./EditSectionModal";
import { CreateSectionModal } from "./CreateSectionModal";
import {
  SortableItem,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledDragHandle,
  StyledTitle,
} from "./SectionsSelect.styled";

interface SectionsSelectProps {
  programId: number;
}

export const SectionsSelect = memo(({ programId }: SectionsSelectProps) => {
  const { t, isPolishChosen } = useAppTranslation();
  const { sections, isLoading, isError } =
    useFetchProgramSectionsCategoryQuery();
  const {
    field: { value, onChange },
  } = useController<ProgramFormInput>({ name: "sectionIds" });
  const sectionIds = value as number[] | undefined;

  const sectionCategories = useMemo(
    () =>
      _(sections)
        .uniqBy(sc => sc.id)
        .sortBy(sc => sc.id)
        .value(),
    [sections],
  );

  const { sectionsDictionary, isSuccess: isSectionsSuccess } =
    useProgramSectionsDetailedDictionary(programId);
  const flatTemplates = sectionCategories.flatMap(el => el.templates);

  const [editedSectionsDictionary, setEditedSectionsDictionary] =
    useState(sectionsDictionary);

  useEffect(() => {
    if (sectionsDictionary) {
      setEditedSectionsDictionary(sectionsDictionary);
    }
  }, [isSectionsSuccess, sectionsDictionary]);

  const programSectionsDictionary = useMemo(
    () => ({
      ..._(flatTemplates)
        .groupBy(template => template.id)
        .mapValues(template => template[0])
        .value(),
      ...editedSectionsDictionary,
    }),
    [flatTemplates, sectionsDictionary, editedSectionsDictionary],
  );

  const toggleSection = (id: number) => {
    if (sectionIds?.includes(id)) {
      setEditedSectionsDictionary(omit(editedSectionsDictionary, id));
      onChange(sectionIds.filter(m => m !== id));
    } else {
      onChange([...(sectionIds ?? []), id]);
    }
  };

  const [sectionToEdit, setSectionToEdit] = useState<number | null>(null);
  const [createSection, setCreateSection] = useState(false);

  if (isLoading) return <Spinner />;

  if (isError) return <ApiError />;
  return (
    <Labeled
      className="gap-5"
      label={`${t("program.info.choose_program_sections")}:`}
      tooltip={t("program.info.program_sections_tooltip")}
    >
      {!!sectionIds?.length && (
        <SortableList
          defaultItemList={sectionIds.map(sid => ({ id: sid }))}
          onListReorder={ss => onChange(ss.map(ss => ss.id))}
          className="grid gap-2"
        >
          {sectionIds.map(sid => (
            <SortableItem key={sid}>
              <StyledDragHandle className="dragHandle" />
              <Checkbox
                label={
                  (isPolishChosen
                    ? programSectionsDictionary[sid]?.title
                    : programSectionsDictionary[sid]?.titleEn ??
                      programSectionsDictionary[sid]?.title) ?? ""
                }
                value={true}
                onChange={() => toggleSection(sid)}
              />
              {!programSectionsDictionary[sid]?.isSystemic && (
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => setSectionToEdit(sid)}
                >
                  <Edit size="w-5 h-5" />
                </IconButton>
              )}
            </SortableItem>
          ))}
        </SortableList>
      )}

      {sectionCategories.map(category => (
        <Accordion
          className="mb-4"
          key={category.id}
          defaultExpanded={category.id === GENERAL_INFORMATION_SECTION_CATEGORY}
        >
          <StyledAccordionSummary>
            <StyledTitle>
              {isPolishChosen ? category.description : category.descriptionEn}
              {category.id === ownCategoryId && (
                <Button
                  icon={<Plus className="stroke-current" />}
                  onClick={() => setCreateSection(true)}
                >
                  <h2>{t("program.info.add_your_own")}</h2>
                </Button>
              )}
            </StyledTitle>
          </StyledAccordionSummary>

          <StyledAccordionDetails>
            {category.templates.map(({ title, titleEn, id }) => (
              <Checkbox
                key={id}
                label={isPolishChosen ? title : titleEn ?? title}
                value={sectionIds?.includes(id)}
                onChange={() => toggleSection(id)}
              />
            ))}
          </StyledAccordionDetails>
        </Accordion>
      ))}

      {!!sectionToEdit && isSectionsSuccess && (
        <EditSectionModal
          hide={() => setSectionToEdit(null)}
          sectionId={sectionToEdit}
          programId={programId}
        />
      )}

      {createSection && (
        <CreateSectionModal hide={() => setCreateSection(false)} />
      )}
    </Labeled>
  );
});

const ownCategoryId = 7;
