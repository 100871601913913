import { useTagsNew } from "@hooks/useTagsNew";
import { useTheme } from "@mui/material";
import { TagCategoryType } from "@utils/tagsNew";

import { Clock, Cutlery } from "@assets/icons";
import { Carrot } from "@assets/icons/DesignSystem";
import { Nutrient } from "@typeDefinitions";
import { useFoodGroup } from "@hooks";

import {
  CellWrapper,
  DetailsWrapper,
  Label,
  TagIconWrapper,
  Title,
} from "./NameColumnCell.styled";
import { MacrosText } from "@components/MacrosText";

interface NameColumnCellProps {
  name: string;
  tags: string[];
  nutrients: Nutrient[];
  onSelect: () => void;
  categoryId: number | null;
}

export const NameColumnCell = ({
  name,
  tags,
  nutrients,
  onSelect,
  categoryId,
}: NameColumnCellProps) => {
  const {
    colors: { neutral },
  } = useTheme();
  const { tagCategoryDictionary, tagNameDictionary } = useTagsNew();

  const difficultyOfPreparationTagList = tagCategoryDictionary.get(
    TagCategoryType.DIFFICULTY_OF_PREPARATION,
  )?.tags;
  const preparationTimeTagList = tagCategoryDictionary.get(
    TagCategoryType.PREPARATION_TIME,
  )?.tags;

  const difficultyOfPreparationTag = tags?.find(tag =>
    difficultyOfPreparationTagList?.some(t => t.id.toString() === tag),
  );
  const preparationTimeTag = tags?.find(tag =>
    preparationTimeTagList?.some(t => t.id.toString() === tag),
  );

  const { foodGroupDict } = useFoodGroup();
  const categoryName = categoryId
    ? foodGroupDict.get(categoryId.toString())?.title
    : undefined;

  return (
    <CellWrapper onClick={onSelect}>
      <Title>{name}</Title>
      <DetailsWrapper>
        <MacrosText nutrients={nutrients} />
        {!!preparationTimeTag && (
          <TagIconWrapper>
            <Clock fill={neutral.dark[700]} />
            <Label>{tagNameDictionary.get(Number(preparationTimeTag))}</Label>
          </TagIconWrapper>
        )}
        {!!difficultyOfPreparationTag && (
          <TagIconWrapper>
            <Cutlery fill={neutral.dark[700]} />
            <Label>
              {tagNameDictionary.get(Number(difficultyOfPreparationTag))}
            </Label>
          </TagIconWrapper>
        )}
        {!!categoryName && (
          <TagIconWrapper>
            <Carrot fill={neutral.dark[700]} size="w-[16px] h-[16px]" />
            <Label>{categoryName}</Label>
          </TagIconWrapper>
        )}
      </DetailsWrapper>
    </CellWrapper>
  );
};
