import { useEffect, useMemo, useState } from "react";

import { useAppTranslation } from "@hooks";
import { Accordion, StepContent, StepLabel, Stepper } from "@mui/material";
import {
  ProgramNavigationWrapper,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledContent,
  StyledStep,
  StyledTitle,
} from "./ProgramNavigation.styled";
import { useMatch } from "react-router-dom";
import {
  PROGRAM_DAYS_ALL,
  PROGRAM_NEW,
  PROGRAM_EDIT,
  PROGRAM_COPY,
  PATIENT_NEW_PROGRAM,
  PROGRAM_SCHEDULE,
  PROGRAM,
  PATIENT_PROGRAM_EDIT,
  PATIENT_PROGRAM_DAYS,
  PATIENT_PROGRAM_SCHEDULE,
  PATIENT_PROGRAM,
} from "@routes";

export const ProgramNavigation = () => {
  const { t } = useAppTranslation();

  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("programNavigation") !== "false") {
      const timeout = setTimeout(() => setOpened(true), 500);
      return () => clearTimeout(timeout);
    }
  }, []);

  const programNew = useMatch(PROGRAM_NEW);
  const programDays = useMatch(PROGRAM_DAYS_ALL);
  const programEdit = useMatch(PROGRAM_EDIT);
  const programCopy = useMatch(PROGRAM_COPY);
  const programSchedule = useMatch(PROGRAM_SCHEDULE);
  const patientProgramNew = useMatch(PATIENT_NEW_PROGRAM);
  const patientProgramEdit = useMatch(PATIENT_PROGRAM_EDIT);
  const patientProgramDays = useMatch(PATIENT_PROGRAM_DAYS);
  const patientProgramSchedule = useMatch(PATIENT_PROGRAM_SCHEDULE);
  const program = useMatch(PROGRAM);
  const patientProgram = useMatch(PATIENT_PROGRAM);

  const stepOne =
    programNew ||
    programEdit ||
    programCopy ||
    patientProgramNew ||
    patientProgramEdit;
  const stepTwo = programDays || patientProgramDays;
  const stepThree = programSchedule || patientProgramSchedule;
  const stepFour = program || patientProgram;

  const activeStep = useMemo(() => {
    if (stepOne) return 0;

    if (stepTwo) return 1;

    if (stepThree) return 2;

    if (stepFour) return 3;
  }, [stepOne, stepTwo, stepThree, stepFour]);

  const stepsContent = [
    {
      label: t("common.navigation.step_one.label"),
      description: t("common.navigation.step_one.description"),
    },
    {
      label: t("common.navigation.step_two.label"),
      description: t("common.navigation.step_two.description"),
    },
    {
      label: t("common.navigation.step_three.label"),
      description: t("common.navigation.step_three.description"),
    },
    {
      label: t("common.navigation.step_four.label"),
      description: t("common.navigation.step_four.description"),
    },
  ];

  return (
    <ProgramNavigationWrapper>
      <Accordion
        expanded={opened}
        onChange={(_, expanded) => {
          setOpened(expanded);
          localStorage.setItem("programNavigation", String(expanded));
        }}
      >
        <StyledAccordionSummary>
          <StyledTitle>{t("common.navigation.next_steps")}</StyledTitle>
        </StyledAccordionSummary>

        <StyledAccordionDetails>
          <Stepper activeStep={activeStep} orientation="vertical">
            {stepsContent.map(step => (
              <StyledStep key={step.label}>
                <StepLabel>{step.label}</StepLabel>
                <StepContent>
                  <StyledContent>{step.description}</StyledContent>
                </StepContent>
              </StyledStep>
            ))}
          </Stepper>
        </StyledAccordionDetails>
      </Accordion>
    </ProgramNavigationWrapper>
  );
};
