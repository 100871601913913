import { Typography, styled } from "@mui/material";

export const TypographyStyled = styled(Typography)`
  font-size: 0.75rem;
`;

export const StyledImg = styled("img")`
  height: 8.5rem;
  width: 8.5rem;
  border-radius: 0.25rem;
`;

export const StyledImagePlaceholder = styled("div")`
  min-height: 8.5rem;
  min-width: 8.5rem;
  border-radius: 0.25rem;
  background: ${({ theme }) => theme.palette.action.hover};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RecipeDetailsWrapper = styled("div")`
  display: grid;
  gap: 1rem;
  width: 100%;
`;

export const HeaderWrapper = styled("div")`
  display: flex;
  gap: 1rem;
  width: 100%;
`;

export const PreviewWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
`;
