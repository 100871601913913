import { useIrrigationProductsQuery } from "@hooks/queries/dictionaries/useIrrigationProductsQuery";
import { useCallback } from "react";
import { IrrigationProduct } from "@client/dictionaries/irrigationProducts";
import { Language } from "@typeDefinitions";

export const useIrrigationProducts = () => {
  const { data } = useIrrigationProductsQuery();

  const getAll = useCallback(() => getAllUtil(data), [data]);
  const getById = useCallback((id: number) => getByIdUtil(data, id), [data]);
  const getTranslationById = useCallback(
    (id: number, lang: Language) => getTranslationByIdUtil(data, id, lang),
    [data],
  );
  const getTranslation = useCallback(
    (product: IrrigationProduct, lang: Language) =>
      getTranslationUtil(product, lang),
    [data],
  );

  return {
    getAll,
    getById,
    getTranslationById,
    getTranslation,
  };
};

type DataType = IrrigationProduct[] | undefined;
const getAllUtil = (data: DataType): IrrigationProduct[] => data ?? [];
const getByIdUtil = (data: DataType, id: number): IrrigationProduct | null =>
  getAllUtil(data).find(i => i.id === id) ?? null;
const getTranslationByIdUtil = (
  data: DataType,
  id: number,
  lang: Language,
): IrrigationProduct["translations"][0] | null => {
  const product = getByIdUtil(data, id);
  if (!product) {
    return null;
  }

  return getTranslationUtil(product, lang);
};

const getTranslationUtil = (
  product: IrrigationProduct,
  lang: Language,
): IrrigationProduct["translations"][0] | null => {
  return product.translations.find(t => t.lang === lang.toLowerCase()) ?? null;
};
