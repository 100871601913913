import { useEffect } from "react";
import { useForm } from "react-hook-form";

import * as yup from "yup";

import {
  CooperationGoalsAnswerQuestionDto,
  UpdateSingedSurveyRequest,
} from "@client/surveys";
import { yupResolver } from "@hookform/resolvers/yup";

export const useCooperationGoalsAnswerForm = (
  values: CooperationGoalsAnswerFormInputs | undefined,
  required: boolean,
) => {
  const resolver = yup.object().shape({
    id: required ? yup.string().required() : yup.string().nullable(),
    other: yup.lazy((_, { parent }) =>
      parent.id === OTHER_ID && required
        ? yup.string().required()
        : yup.string().nullable(),
    ),
  });

  const form = useForm<CooperationGoalsAnswerFormInputs>({
    resolver: yupResolver(resolver),
  });

  useEffect(() => {
    if (values) form.reset(values);
  }, [values]);

  return form;
};

export const mapCooperationGoalsAnswerForm = (
  data?: CooperationGoalsAnswerQuestionDto,
): CooperationGoalsAnswerFormInputs => {
  if (!data?.id) return { id: null, other: null };
  return { id: data.id.toString(), other: data.other };
};

export const mapCooperationGoalsAnswerRequest = (
  data: CooperationGoalsAnswerFormInputs,
  questionId: number,
): UpdateSingedSurveyRequest => {
  const { id, other } = data;

  return {
    questionId,
    answer: {
      id: id ? Number(id) : null,
      other,
    },
  };
};

export interface CooperationGoalsAnswerFormInputs {
  id: string | null;
  other: string | null;
}

export const OTHER_ID = "12";
