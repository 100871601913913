import { Card, DialogContent, styled } from "@mui/material";

export const CardTitle = styled("span")`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.714rem;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;

export const QuestionPreviewCard = styled(Card)`
  display: grid;
  gap: 1.5rem;
  padding: 1.5rem;
  border-radius: 1rem;
  max-width: 41rem;
  box-shadow: 0px 0px 10px 0px #0000000d;
  background: ${({ theme }) => theme.gradients.surveySemiTransparent};
  position: relative;
`;

export const DialogContentStyled = styled(DialogContent)`
  display: grid;
  gap: 1.5rem;
  padding: 1.5rem;
  border-radius: 1rem;
  box-shadow: 0px 0px 10px 0px #0000000d;
  background: ${({ theme }) => theme.gradients.surveySemiTransparent};
`;

export const TextStyled = styled("span")`
  font-size: 1rem;
  line-height: 1.714rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
  white-space: pre-line;
`;
