import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { searchProgramsQueryKeyNew } from "@hooks/queries";

const useClearProgramsSearchQuery = () => {
  const query = useQueryClient();

  useEffect(() => {
    return () => {
      query.removeQueries([searchProgramsQueryKeyNew, "default"]);
    };
  }, []);
};

export default useClearProgramsSearchQuery;
