import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useAppTranslation } from "@hooks";

const CancelButton = () => {
  const { t } = useAppTranslation();
  const { patientId } = useParams();
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(`/patients/${patientId}/programs`);
  };

  return (
    <Button onClick={handleOnClick} variant="outlined" color="primary">
      {t("patient.programs_choice.cancel_button")}
    </Button>
  );
};

export default CancelButton;
