import { useMemo, useState } from "react";

import { useAppParams, useAppTranslation } from "@hooks";
import { useFetchCreatorMealQuery } from "@hooks/queries";
import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";
import { NotFound } from "@views/error/NotFound";
import {
  MealFormWrapper,
  StyledCard,
} from "@views/dietician/MealEdit/MealEdit.styled";
import { BreadcrumbsPath } from "@components/BreadcrumbsPath";
import { AutosaveStatus } from "@components/AutosaveStatus";
import { Food } from "@assets/icons/Menu";
import { MealEditDetails } from "@views/dietician/MealEdit/components/MealEditDetails";
import { LangDto } from "@client";
import { SelectedItems } from "@views/dietician/MealEdit/components/SelectedItems";
import { SearchItemsCard } from "@views/dietician/MealEdit/components/SearchItemsCard";
import { useFetchCreatorMealNutrientsQuery } from "@hooks/queries/meals/useFetchCreatorMealNutrientsQuery";
import { LangContextProvider } from "@views/dietician/MealEdit/context/LangContextProvider";

export const MealEdit = () => {
  const { t, currentLanguage } = useAppTranslation();
  const { mealId } = useAppParams();
  const { meal, isError, isLoading } = useFetchCreatorMealQuery(mealId);
  const { nutrients } = useFetchCreatorMealNutrientsQuery(mealId);
  const [lang, setLang] = useState(LangDto.PL);

  const mealName = useMemo(
    () =>
      meal?.translations.find(t => t.langId === (currentLanguage as string))
        ?.name ?? "",
    [currentLanguage, meal?.translations[0]?.name, meal?.translations[1]?.name],
  );

  const breadcrumbs = useMemo(
    () => [
      {
        icon: <Food />,
        backTo: "/meals",
        title: t("meals.title"),
      },
      {
        backTo: `/meals/${mealId}`,
        title: mealName,
      },
      {
        backTo: `/meals/${mealId}/edit`,
        title: t("meals.editTitle"),
      },
    ],
    [mealId, t, mealName],
  );

  if (isNaN(Number(mealId))) {
    return <NotFound />;
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (isError || !meal) {
    return <ApiError />;
  }

  return (
    <MealFormWrapper>
      <LangContextProvider lang={lang}>
        <div className="flex justify-between">
          <BreadcrumbsPath breadcrumbs={breadcrumbs} />
          <AutosaveStatus />
        </div>

        <div className="grid gap-4">
          <StyledCard>
            <MealEditDetails lang={lang} setLang={setLang} meal={meal} />
          </StyledCard>
          <StyledCard>
            <SelectedItems
              products={meal?.products}
              recipes={meal?.recipes}
              numberOfItems={meal?.numberOfItems}
              nutrients={nutrients}
            />
          </StyledCard>
          <StyledCard>
            <SearchItemsCard />
          </StyledCard>
        </div>
      </LangContextProvider>
    </MealFormWrapper>
  );
};
