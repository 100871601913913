import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import * as yup from "yup";

export const fetchIsConnect = async (
  programId: string | number,
  signal?: AbortSignal,
): Promise<ApiResponse<IsConnectResponse>> => {
  const data = await fetchData(
    `/dietitian/programs/${programId}/schedule/is-connect`,
    APIMethods.GET,
    undefined,
    undefined,
    undefined,
    undefined,
    signal,
  );
  return isConnectSchema.validate(data);
};

interface IsConnectResponse {
  isConnect: boolean;
}

const isConnectSchema = yup.object().shape({
  data: yup
    .object()
    .shape({
      isConnect: yup.boolean().required(),
    })
    .required(),
});
