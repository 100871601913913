import { styled } from "@mui/material";

export const SectionWrapper = styled("div")`
  display: grid;
  gap: 1rem;
  width: 100%;
`;

export const TopPart = styled("div")`
  display: flex;
  justify-content: space-between;
  height: 2.5rem;
`;
