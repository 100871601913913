import { useParams, useSearchParams } from "react-router-dom";
import { useMemo } from "react";

export type PatientProgramParams = {
  patientId: string;
};

export const usePatientProgramNewParams = () => {
  const { patientId } = useParams<PatientProgramParams>();
  const [searchParams] = useSearchParams();
  const programIdParsed = useMemo(
    () => parseInt(searchParams.get("programId") ?? ""),
    [searchParams.get("programId")],
  );
  const patientIdParsed = useMemo(() => parseInt(patientId ?? ""), [patientId]);

  return { patientId: patientIdParsed, programId: programIdParsed };
};
