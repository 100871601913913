import { LoadingButton, TabList, TabPanel } from "@mui/lab";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tab,
  styled,
} from "@mui/material";

export const ModalWrapper = styled(DialogContent)`
  display: grid;
  gap: 1rem;
  padding: 16px;
  width: 823px;
`;

export const TitleWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled(DialogTitle)`
  padding: 0;
  font-weight: 500;
`;

export const CloseButton = styled(IconButton)`
  width: 24px;
  height: 24px;
  padding: 0;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;

export const StyledDialogActions = styled(DialogActions)`
  padding: 12px 16px;
  background: ${({ theme }) => theme.palette.primary.light};
`;

export const CancelButton = styled(Button)`
  height: 40px;
  background: ${({ theme }) => theme.colors.neutral.light[100]};
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  padding: 8px 12px;

  &:hover {
    background: ${({ theme }) => theme.colors.neutral.light[100]};
  }
`;

export const StyledLoadingButton = styled(LoadingButton)`
  height: 40px;
  padding: 8px 12px;
`;

export const StyledTabList = styled(TabList)`
  min-height: 40px;
  min-width: 64px;
  align-items: center;
  width: fit-content;
  background: ${({ theme }) => theme.colors.neutral.light[100]};
  padding: 4px;
  outline: 1px solid ${({ theme }) => theme.palette.primary.medium};
  border-radius: 8px;

  & .MuiTabs-indicator {
    display: none;
  }

  & .MuiTabs-flexContainer {
    gap: 4px;

    & button {
      min-height: 32px;
      border-radius: 8px;
      padding: 6px 8px;
      color: ${({ theme }) => theme.colors.neutral.dark[700]};
      font-size: 12px;
      line-height: 20px;

      &.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
        background: ${({ theme }) => theme.palette.primary.medium};
        color: ${({ theme }) => theme.palette.primary.main};
      }
    }
  }
`;

export const StyledTab = styled(Tab)`
  color: ${({ theme }) => theme.palette.grey[900]};
  text-transform: unset;
  font-size: 1rem;
  background: ${({ theme }) => theme.colors.neutral.light[100]};
`;

export const StyledTabPanel = styled(TabPanel)`
  padding: 0;
`;
