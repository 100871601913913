import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const updateCreatorDietShowMacros = async (
  dietId: string,
  payload: UpdateCreatorDietShowMacrosPayload,
  version: string,
) => {
  return await fetchData(
    `/dietitian/creator-2/diets/${dietId}/show-macros`,
    APIMethods.PUT,
    payload,
    undefined,
    { "Alloweat-Creator-Version": version },
  );
};

export interface UpdateCreatorDietShowMacrosPayload {
  show: boolean;
}
