import styled from "@emotion/styled";
import { IconButton } from "@components/IconButton";
import { AvatarMui } from "@components/AvatarNew";

export const DietitianFilterStyledDiv = styled("div")`
  display: flex;
  align-items: center;
  align-self: start;
  max-width: max-content;
  padding: 0.2rem 0.2rem;
  border: 1px solid #f5f1fe;
  border-radius: 1.5rem;
  background-color: white;
`;

export const DietitianFilterStyledAvatarContainer = styled("div")`
  padding-left: 0.4rem;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  color: ${({ theme }) => theme.colors.neutral.dark["700"]};
`;

export const DietitianFilterStyledIconButton = styled(IconButton)`
  border: 1px solid #f5f1fe;
  border-radius: 50%;
`;

export const DietitianFilterStyledAvatarMui = styled(AvatarMui)`
  border: 1px solid ${({ theme }) => theme.palette.primary.medium};
  background-color: "white";
  color: ${({ theme }) => theme.palette.primary.main};
  z-index: 0;
`;

export const DietitianFilterTooltipWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  gap: 1rem;
  max-height: 200px;
  overflow-y: auto;
`;
