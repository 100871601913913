import { useContext } from "react";

import { IconButton, ToggleButtonGroup } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import dayjs from "dayjs";
import { capitalize } from "lodash";

import { useAppTranslation } from "@hooks";

import { CalendarHeader } from "./MonitoringCalendar.styled";
import {
  CaptionStyled,
  StyledToggleButton,
} from "./MonitoringDayDetails.styled";
import { MonitoringContext } from "../context/MonitoringContext";
import {
  StyledFullWidthCard,
  MonthNavigationWrapper,
  ToggleButtonsWrapper,
} from "./NavigationSection.styled";

enum ButtonActions {
  ADD = "add",
  SUB = "subtract",
}

export const NavigationSection = () => {
  const { t } = useAppTranslation();
  const { toggleButton, setToggleButton, month, setMonth, setSelectedDayDate } =
    useContext(MonitoringContext);

  const handleMonthChange = (action: ButtonActions) => {
    if (action === "add") {
      setMonth(month => month.add(1, "month"));
      setSelectedDayDate(date =>
        dayjs(date).add(1, "month").format("YYYY-MM-DD"),
      );
    } else {
      setMonth(month => month.subtract(1, "month"));
      setSelectedDayDate(date =>
        dayjs(date).subtract(1, "month").format("YYYY-MM-DD"),
      );
    }
  };

  return (
    <StyledFullWidthCard>
      <MonthNavigationWrapper>
        <IconButton
          color="primary"
          onClick={() => handleMonthChange(ButtonActions.SUB)}
        >
          <ChevronLeft />
        </IconButton>
        <CalendarHeader variant="h4">
          {capitalize(month.format("MMMM"))} {month.format("YYYY")}
        </CalendarHeader>
        <IconButton
          color="primary"
          onClick={() => handleMonthChange(ButtonActions.ADD)}
        >
          <ChevronRight />
        </IconButton>
      </MonthNavigationWrapper>

      <ToggleButtonsWrapper>
        <CaptionStyled color="text.secondary">
          {t("monitoring.target_from")}:
        </CaptionStyled>
        <ToggleButtonGroup
          value={toggleButton}
          exclusive
          onChange={(_, val) => val && setToggleButton(val)}
          size="small"
        >
          <StyledToggleButton value="program">
            <CaptionStyled>{t("monitoring.diet_set")}</CaptionStyled>
          </StyledToggleButton>
          <StyledToggleButton value="schedule">
            <CaptionStyled>{t("monitoring.meal_plan")}</CaptionStyled>
          </StyledToggleButton>
        </ToggleButtonGroup>
      </ToggleButtonsWrapper>
    </StyledFullWidthCard>
  );
};
