import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const DollarSign = (props: SvgIconProps) => (
  <SvgIcon {...props} width="8" height="14" viewBox="0 0 8 14">
    <path
      d="M4.625 2.23435C5.35417 2.33852 5.97917 2.45137 6.5 2.5729C6.84722 2.67706 6.99479 2.9288 6.94271 3.3281C6.82118 3.69269 6.57812 3.84894 6.21354 3.79685C5.79687 3.69269 5.31944 3.60588 4.78125 3.53644C4.26042 3.44963 3.74826 3.42359 3.24479 3.45831C2.74132 3.49304 2.34201 3.60588 2.04687 3.79685C1.78646 3.9531 1.61285 4.21352 1.52604 4.5781C1.47396 4.87324 1.49132 5.09026 1.57812 5.22915C1.64757 5.36804 1.79514 5.5156 2.02083 5.67185C2.52431 5.94963 3.23611 6.19269 4.15625 6.40102H4.20833C4.625 6.50519 5.04167 6.62672 5.45833 6.7656C5.89236 6.90449 6.28299 7.0781 6.63021 7.28644C7.01215 7.49477 7.31597 7.80727 7.54167 8.22394C7.73264 8.6406 7.78472 9.11804 7.69792 9.65623C7.47222 10.5764 6.91667 11.184 6.03125 11.4791C5.59722 11.6528 5.12847 11.7482 4.625 11.7656V13.0416C4.59028 13.4236 4.38194 13.6319 4 13.6666C3.61806 13.6319 3.40972 13.4236 3.375 13.0416V11.7396C3.28819 11.7396 3.20139 11.7309 3.11458 11.7135H3.08854C2.75868 11.6614 2.35937 11.5746 1.89062 11.4531C1.40451 11.3316 0.979167 11.1927 0.614583 11.0364C0.284722 10.8455 0.180556 10.5764 0.302083 10.2291C0.493056 9.88192 0.770833 9.76908 1.13542 9.8906C1.43056 10.0295 1.78646 10.151 2.20312 10.2552C2.61979 10.3594 2.98437 10.4375 3.29687 10.4896C4.23437 10.5937 4.98958 10.5416 5.5625 10.3333C6.10069 10.1076 6.40451 9.8038 6.47396 9.42185C6.52604 9.10935 6.50868 8.89234 6.42187 8.77081C6.35243 8.63192 6.20486 8.48435 5.97917 8.3281C5.45833 8.05033 4.74653 7.80727 3.84375 7.59894H3.79167C3.375 7.49477 2.95833 7.37324 2.54167 7.23435C2.10764 7.09547 1.71701 6.93054 1.36979 6.73956C0.987847 6.51387 0.684028 6.19269 0.458333 5.77602C0.25 5.35935 0.197917 4.88192 0.302083 4.34373C0.458333 3.5972 0.822917 3.05901 1.39583 2.72915C1.93403 2.41665 2.51562 2.24304 3.14062 2.20831C3.21007 2.20831 3.28819 2.20831 3.375 2.20831V0.958313C3.40972 0.576369 3.61806 0.368035 4 0.333313C4.38194 0.368035 4.59028 0.576369 4.625 0.958313V2.23435Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
