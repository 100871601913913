import axios from "@services/axios";
import Compressor from "compressorjs";

export const blobToBase64 = (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });

const imgToBase64 = (imgPath: string): Promise<string> =>
  new Promise((resolve, reject) =>
    axios(imgPath, {
      responseType: "blob",
      withCredentials: false,
      withXSRFToken: false,
    })
      .then(async response => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(response.data);
      })
      .catch(e => reject(e)),
  );

export const updateImages = async (imageRefs: Record<string, string>) => {
  const requests = [];

  for (const key in imageRefs) {
    const imgUrl = imageRefs[key];

    if (key === "coverPage") {
      requests.push(
        imgToBase64(imageRefs[key] as string).then(response => {
          imageRefs[key] = response;
        }),
      );
    } else {
      const withCredentials = imgUrl.includes("api.alloweat");
      requests.push(
        axios(imgUrl, {
          responseType: "blob",
          withCredentials,
          withXSRFToken: withCredentials,
        })
          .then(
            async response =>
              // eslint-disable-next-line no-async-promise-executor
              new Promise(async (resolve, reject) => {
                try {
                  if (
                    response.data.type !== "image/png" &&
                    response.data.type !== "image/jpg"
                  ) {
                    new Compressor(response.data, {
                      mimeType: "image/jpg",
                      convertTypes: [response.data.type],
                      quality: 1,
                      async success(result) {
                        imageRefs[key] = await blobToBase64(result);

                        resolve(imageRefs[key]);
                      },
                      error(err) {
                        reject(err);
                      },
                    });
                  } else {
                    imageRefs[key] = await blobToBase64(response.data);

                    resolve(imageRefs[key]);
                  }
                } catch (err) {
                  reject(err);
                }
              }),
          )
          .catch(async () => {
            // This should never happen, but in case that img is coming from different env it may...
            imageRefs[key] = await imgToBase64("/static/pdf/broken-image.jpg");
          }),
      );
    }
  }

  await Promise.all(requests);
};

export const getImageDimension = (
  src: string,
): Promise<{ width: number; height: number } | null> =>
  new Promise(resolve => {
    const img = new Image();

    img.onload = function () {
      const dimensions = { width: img.width, height: img.height };

      img.onload = null;
      img.onerror = null;
      img.src = "";

      resolve(dimensions);
    };

    img.onerror = function () {
      img.onload = null;
      img.onerror = null;
      img.src = "";

      resolve(null);
    };

    img.src = src;
  });
