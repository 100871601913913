import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import { TagCategoryType } from "@utils/tagsNew";

export async function fetchTagCategories(): Promise<
  ApiResponse<FetchTagCategoriesCategory[]>
> {
  const rsp = await fetchData("/tag_categories", APIMethods.GET);
  await fetchTagCategoriesResponseSchema.validate(rsp.data);
  return { data: rsp.data };
}

export interface FetchTagCategoriesTag {
  id: number;
  namePl?: string;
  nameEn?: string;
  systemId: number | null;
}

export interface FetchTagCategoriesCategory {
  id: number;
  name: string;
  nameEn?: string;
  tags: FetchTagCategoriesTag[];
  type: TagCategoryType;
}

const fetchTagCategoriesResponseSchema = yup.array().of(
  yup.object().shape({
    id: yup.number().required(),
    type: yup.number().required(),
    name: yup.string().required(),
    nameEn: yup.string().nullable(),
    tags: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.number().required(),
          namePl: yup.string().nullable(),
          nameEn: yup.string().nullable(),
          systemId: yup.number().nullable().default(null),
        }),
      )
      .required(),
  }),
);
