import { FC, useMemo, useState } from "react";
import { Controller, useController, useFormContext } from "react-hook-form";
import { PdfExportPdfFormStateType } from "@Pdf/types/pdfTypes";
import { FormTextField } from "@components/FormTextField";
import { useAppTranslation } from "@hooks";
import { range } from "lodash";
import { FormAutocomplete } from "@components/FormAutocomplete";
import dayjs from "dayjs";
import { DateRangePicker, Weeks } from "@components/DateRangePicker";
import { TextField } from "@mui/material";
import { Calendar } from "@assets/icons";
import { FetchPatientProgramResponse } from "@client/program";
import CheckboxFieldset from "@Pdf/components/CheckboxFieldset/CheckboxFieldset";
import LabelField from "@Pdf/components/LabelField/LabelField";
import { PDF_DISPLAY_CODE } from "@Pdf/defaults/pdfDefaults";
import { SectionTitle } from "@views/dietician/ClientHealth";

type PropsType = {
  enterprise: boolean;
  program: FetchPatientProgramResponse;
};

const PdfSettingsTab: FC<PropsType> = ({ enterprise, program }) => {
  const form = useFormContext<PdfExportPdfFormStateType>();
  const { t } = useAppTranslation();

  const {
    field: { value: startDate, onChange: setStartDate },
  } = useController<PdfExportPdfFormStateType, "startDate">({
    name: "startDate",
  });

  const {
    field: { value: endDate, onChange: setEndDate },
  } = useController<PdfExportPdfFormStateType, "endDate">({
    name: "endDate",
  });

  const [selectedButton, setSelectedButton] = useState(0);

  const programLength = useMemo(() => {
    const now = new Date();
    let startDate = program.startDate
      ? dayjs(program.startDate).toDate()
      : new Date();
    if (startDate.getTime() < now.getTime()) startDate = now;

    const finishDate = program.finishDate
      ? dayjs(program.finishDate).toDate()
      : dayjs(startDate).add(3, "months").toDate();

    if (finishDate.getTime() < now.getTime()) return 1;

    return dayjs(finishDate).diff(startDate, "days") + 1;
  }, [program.startDate, program.finishDate]);

  const daysOptions = useMemo(
    () =>
      range(1, 31).map(day => ({
        id: day.toString(),
        label: day.toString(),
      })),
    [],
  );

  const displayOptions = useMemo(() => {
    const tmp = [
      { id: PDF_DISPLAY_CODE.WEEKDAY, name: t("pdf.settings.weekday") },
      { id: PDF_DISPLAY_CODE.DATE, name: t("pdf.settings.date") },
      {
        id: PDF_DISPLAY_CODE.DAY_TYPE,
        name: t("pdf.settings.day_type"),
      },
      {
        id: PDF_DISPLAY_CODE.MEAL_HOUR,
        name: t("pdf.settings.meal_hour"),
      },
    ];

    if (enterprise) {
      tmp.push({
        id: PDF_DISPLAY_CODE.LOGO,
        name: t("pdf.settings.display_logo"),
      });
    }

    return tmp;
  }, [enterprise]);

  const displayClinicOptions = useMemo(() => {
    const tmp = [];

    if (enterprise) {
      tmp.push({
        id: PDF_DISPLAY_CODE.CLINIC_LOGO,
        name: t("pdf.settings.display_clinic_logo"),
      });
    }

    tmp.push(
      { id: PDF_DISPLAY_CODE.CLINIC_NAME, name: t("pdf.settings.clinic_name") },
      {
        id: PDF_DISPLAY_CODE.CLINIC_ADDRESS,
        name: t("pdf.settings.clinic_address"),
      },
      {
        id: PDF_DISPLAY_CODE.CLINIC_EMAIL,
        name: t("pdf.settings.clinic_email"),
      },
      {
        id: PDF_DISPLAY_CODE.CLINIC_PHONE,
        name: t("pdf.settings.clinic_phone"),
      },
    );

    return tmp;
  }, [enterprise]);

  const days = useMemo(
    () => [programLength, Weeks.ONE, Weeks.TWO, Weeks.FOUR],
    [programLength],
  );

  const handleRangeChange = (dates: [Date | null, Date | null]) => {
    const [newStartDate, newEndDate] = dates;
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setSelectedButton(-1); // Reset selection
  };

  const handleButtonClick = (buttonId: number) => {
    setSelectedButton(buttonId);

    const now = new Date();
    let startDate = dayjs(program.startDate).toDate();
    if (startDate.getTime() < now.getTime()) startDate = now;

    setStartDate(startDate);
    setEndDate(
      dayjs(startDate)
        .add(days[buttonId] - 1, "days")
        .toDate(),
    );
  };

  const customButtons = days.map((button, id) => {
    if (id === 0)
      return {
        id,
        label: t("pdf_modal.all_days"),
        onClick: () => handleButtonClick(id),
        selected: selectedButton === id,
      };
    else
      return {
        id,
        label: `${t("pdf_modal.first")} ${button} ${t("common.days")}`,
        onClick: () => handleButtonClick(id),
        selected: selectedButton === id,
      };
  });

  return (
    <div className={"flex flex-col gap-[16px]"} style={{ width: "436px" }}>
      <LabelField label={t("pdf.settings.title")}>
        <FormTextField
          control={form.control}
          inputProps={{ maxLength: 160 }}
          name="title"
          size="small"
          variant="outlined"
        />
      </LabelField>
      <LabelField label={t("pdf.settings.frequency")}>
        <FormAutocomplete
          disableClearable
          size="small"
          options={daysOptions}
          control={form.control}
          id="frequency"
          name="frequency"
        />
      </LabelField>
      <LabelField label={t("pdf.settings.menu_scope")}>
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          minDate={new Date()}
          portalId={"export-pdf-modal"}
          customInput={
            <TextField
              style={{ width: "100%" }}
              size="small"
              InputProps={{
                startAdornment: (
                  <Calendar
                    style={{ marginRight: "8px" }}
                    size="w-5 h-5"
                    fill={"#333333"}
                  />
                ),
              }}
            />
          }
          onRangeChange={handleRangeChange}
          customButtons={customButtons}
        />
      </LabelField>
      <Controller
        name="options"
        control={form.control}
        render={({ field }) => (
          <CheckboxFieldset
            {...field}
            label={t("pdf.settings.display")}
            options={displayOptions}
          />
        )}
      />
      <SectionTitle className={"font-semibold text-neutral-dark-700"}>
        {t("pdf.settings.clinic_data")}
      </SectionTitle>
      <Controller
        name="clinicOptions"
        control={form.control}
        render={({ field }) => (
          <CheckboxFieldset
            {...field}
            label={t("pdf.settings.display")}
            options={displayClinicOptions}
          />
        )}
      />
    </div>
  );
};

export default PdfSettingsTab;
