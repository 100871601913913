import { useMutation, useQueryClient } from "@tanstack/react-query";

import { deleteProgramDietMealRecipe } from "@client/program/dietCreator";
import {
  fetchCreatorDietMealNutrientsQueryKey,
  fetchCreatorDietMealsItemsQuery,
  fetchCreatorDietMealsQueryKey,
  fetchCreatorDietNutrientsQueryKey,
  fetchCreatorDietQueryKey,
} from "@hooks/queries/diets/creator";
import { useCreatorVersion } from "@hooks/queries/diets/creator/useCreatorVersion";
import { MutationOptions } from "@hooks/queries/types";
import { ApiResponse } from "@typeDefinitions";
import { DietMealItemCreatorResponseDto } from "@client/diets/creator";

export const useDeleteProgramDietMealRecipeMutation = (
  programId: string,
  options?: MutationOptions,
) => {
  const { version } = useCreatorVersion();
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    unknown,
    UseDeleteProgramDietMealRecipeMutationPayload
  >(
    ({ dietId, mealId, recipeId }) =>
      deleteProgramDietMealRecipe(programId, dietId, mealId, recipeId, version),
    {
      ...options,
      onMutate: async ({ dietId, mealId, recipeId }) => {
        const previousData = queryClient.getQueryData<
          ApiResponse<DietMealItemCreatorResponseDto>
        >([fetchCreatorDietMealsItemsQuery, dietId, mealId]);

        queryClient.setQueryData<ApiResponse<DietMealItemCreatorResponseDto>>(
          [fetchCreatorDietMealsItemsQuery, dietId, mealId],
          oldData => {
            if (!oldData) return previousData;

            return {
              ...oldData,
              data: {
                recipes: oldData?.data.recipes.filter(
                  ({ id }) => id.toString() !== recipeId,
                ),
                products: oldData?.data.products,
              },
            };
          },
        );
      },
      onError: (e, { dietId, mealId }) => {
        options?.onError?.(e);
        queryClient.invalidateQueries([
          fetchCreatorDietMealsItemsQuery,
          dietId,
          mealId,
        ]);
      },
      onSuccess: (d, v) => {
        options?.onSuccess && options.onSuccess(d, v);
        queryClient.invalidateQueries([
          fetchCreatorDietMealsItemsQuery,
          v.dietId,
        ]);
        queryClient.invalidateQueries([
          fetchCreatorDietMealsQueryKey,
          v.dietId,
        ]);
        queryClient.invalidateQueries([fetchCreatorDietQueryKey, v.dietId]);
        queryClient.invalidateQueries([
          fetchCreatorDietNutrientsQueryKey,
          v.dietId,
        ]);
        queryClient.invalidateQueries([
          fetchCreatorDietMealNutrientsQueryKey,
          v.dietId,
        ]);
      },
    },
  );
};

interface UseDeleteProgramDietMealRecipeMutationPayload {
  dietId: string;
  mealId: string;
  recipeId: string;
}
