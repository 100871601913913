import { Nutrient } from "@typeDefinitions";
import { MACROS_ARRAY, macrosSortKey } from "@utils/macros";

import { MacrosListWrapper } from "@components/MacrosList/MacrosListMui.styled";
import { MacrosListElement } from "@components/MacrosList/MacrosListElement";

interface MacrosListMuiProps {
  nutrients: Nutrient[];
  compact?: boolean;
  fixed?: boolean;
}

export const MacrosListMui = ({
  nutrients,
  compact,
  fixed,
}: MacrosListMuiProps) => {
  const macros = nutrients
    .filter(m => MACROS_ARRAY.includes(m.id) && MACROS_ARRAY.includes(m.id))
    .sort((a, b) => macrosSortKey(a.id, b.id));

  return (
    <MacrosListWrapper fixed={fixed}>
      {macros.map(macro => (
        <MacrosListElement
          key={macro.id}
          macro={macro}
          compact={compact}
          fixed={fixed}
        />
      ))}
    </MacrosListWrapper>
  );
};
