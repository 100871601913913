import { useEffect } from "react";
import { useForm } from "react-hook-form";

import * as yup from "yup";

import {
  PregnantWomanAnswerQuestionDto,
  PregnantWomanValue,
  UpdateSingedSurveyRequest,
} from "@client/surveys";
import { yupResolver } from "@hookform/resolvers/yup";

export const usePregnantWomanAnswerForm = (
  values: PregnantWomanAnswerFormInputs | undefined,
  required: boolean,
) => {
  const resolver = yup.object().shape({
    value: required ? yup.string().required() : yup.string().nullable(),
    trimester: yup.lazy((_, { parent }) =>
      parent.value === `${PregnantWomanValue.PREGNANT}` && required
        ? yup.string().required()
        : yup.string().nullable(),
    ),
  });

  const form = useForm<PregnantWomanAnswerFormInputs>({
    resolver: yupResolver(resolver),
  });

  useEffect(() => {
    if (values) form.reset(values, { keepDirtyValues: true });
  }, [values]);

  return form;
};

export const mapPregnantWomanAnswerForm = (
  data?: PregnantWomanAnswerQuestionDto,
): PregnantWomanAnswerFormInputs => {
  if (!data) return { value: null, trimester: null };
  return {
    value: data.value ? `${data.value}` : null,
    trimester: data.trimester ? `${data.trimester}` : null,
  };
};

export const mapPregnantWomanAnswerRequest = (
  data: PregnantWomanAnswerFormInputs,
  questionId: number,
): UpdateSingedSurveyRequest => {
  const { trimester, value } = data;

  if (value === `${PregnantWomanValue.PREGNANT}` && trimester)
    return {
      questionId,
      answer: {
        value: value ? Number(value) : null,
        trimester: Number(trimester),
        comment: null,
      },
    };

  return {
    questionId,
    answer: {
      value: value ? Number(value) : null,
      trimester: null,
      comment: null,
    },
  };
};

export interface PregnantWomanAnswerFormInputs {
  value: string | null;
  trimester: string | null;
}
