import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const GeometricFigures = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="14" viewBox="0 0 14 14" {...props}>
      <path
        d="M8.5082 0.937012C8.36348 0.693262 8.09688 0.543457 7.8125 0.543457C7.52812 0.543457 7.26152 0.693262 7.1168 0.937012L4.6793 4.99951C4.52949 5.25088 4.52441 5.56318 4.66914 5.81709C4.81387 6.071 5.08301 6.22842 5.375 6.22842H10.25C10.542 6.22842 10.8137 6.071 10.9559 5.81709C11.098 5.56318 11.0955 5.25088 10.9457 4.99951L8.5082 0.937012ZM7.8125 8.46533V12.1216C7.8125 12.6827 8.26699 13.1372 8.82812 13.1372H12.4844C13.0455 13.1372 13.5 12.6827 13.5 12.1216V8.46533C13.5 7.9042 13.0455 7.44971 12.4844 7.44971H8.82812C8.26699 7.44971 7.8125 7.9042 7.8125 8.46533ZM3.75 13.5435C4.61195 13.5435 5.4386 13.201 6.0481 12.5916C6.65759 11.9821 7 11.1554 7 10.2935C7 9.4315 6.65759 8.60485 6.0481 7.99536C5.4386 7.38587 4.61195 7.04346 3.75 7.04346C2.88805 7.04346 2.0614 7.38587 1.4519 7.99536C0.84241 8.60485 0.5 9.4315 0.5 10.2935C0.5 11.1554 0.84241 11.9821 1.4519 12.5916C2.0614 13.201 2.88805 13.5435 3.75 13.5435Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
