import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import KcalSummaryCardOfDays from "@views/dietician/PatientMonitoring2/components/SummaryCard/Kcal/KcalSummaryCardOfDays";
import KcalSummaryCardOfDay from "@views/dietician/PatientMonitoring2/components/SummaryCard/Kcal/KcalSummaryCardOfDay";

const KcalSummaryCardFactory = () => {
  const { type } = useMonitoring();

  if (type.type === "day") {
    return <KcalSummaryCardOfDay />;
  }

  return <KcalSummaryCardOfDays />;
};

export default KcalSummaryCardFactory;
