import { getSectionsByCategoriesId } from "@components/ProgramSearchPreview/utils/ProgramSectionsUtil";
import { SectionProgramPreviewSchema } from "@client/program/preview/fetchSectionsProgramPreview";
import { TabPanel } from "@components/Alloweat/Tabs/TabPanel";
import {
  MaterialItem,
  MaterialItemChip,
  MaterialItemTitle,
} from "@components/ProgramSearchPreview/Components/ProgramMaterials/ProgramMaterials.styled";
import { useMemo } from "react";
import MaterialListItem from "@components/ProgramSearchPreview/Components/ProgramMaterials/MaterialListItem";

type MaterialTabPanelProps = {
  materials: SectionProgramPreviewSchema[];
  categoryId: number;
};

const MaterialTabPanel = ({ materials, categoryId }: MaterialTabPanelProps) => {
  const components = useMemo(() => {
    const filteredMaterials = getSectionsByCategoriesId(materials, categoryId);
    return filteredMaterials.map((material, index) => (
      <MaterialListItem key={index} material={material} />
    ));
  }, [materials, categoryId]);

  return (
    <TabPanel value={categoryId.toString()}>
      <ul>{components}</ul>
    </TabPanel>
  );
};

export default MaterialTabPanel;
