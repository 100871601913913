import { useAppTranslation } from "@hooks/useAppTranslation";
import { Sexes } from "./useUpdateClientForm";

interface ArrayElementIf {
  id: number;
  names: {
    lang: "pl" | "en";
    value: string;
  }[];
  sex?: "m" | "w";
}

export const useMapClientAutocompleteOptions = (sex?: Sexes | null) => {
  const { isPolishChosen } = useAppTranslation();

  const mapOptions = (array?: ArrayElementIf[]) => {
    if (!array) return [];

    return array
      .filter(el => (el.sex ? el.sex === sex : true))
      .map(({ id, names }) => ({
        id: id.toString(),
        label: isPolishChosen
          ? names.find(el => el.lang === "pl")?.value ?? ""
          : names.find(el => el.lang === "en")?.value ?? "",
      }));
  };

  return { mapOptions };
};
