import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Restore = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <g
      id="_20-arrow_next_right"
      data-name="20-arrow next right"
      transform="translate(20 20) rotate(180)"
    >
      <rect
        id="icon_20x20_odstep_18px"
        data-name="icon 20x20 / odstep 18px"
        width="20"
        height="20"
        fill="#fff"
        opacity="0"
      />
      <g
        id="Arrow_-_Right"
        data-name="Arrow - Right"
        transform="translate(1.25 16.799) rotate(-90)"
      >
        <path
          id="Stroke_1"
          data-name="Stroke 1"
          d="M.5,15V0"
          transform="translate(6.274 0.75)"
          fill="none"
          stroke="#801fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Stroke_3"
          data-name="Stroke 3"
          d="M12.049,0,6.025,6.05,0,0"
          transform="translate(0.75 9.7)"
          fill="none"
          stroke="#801fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </g>
  </SvgIcon>
);
