import { useState, useRef } from "react";
import { useNotesInfiniteQuery } from "@hooks/queries/notes/useNotesInfiniteQuery";
import { useCreateNoteMutation } from "@hooks/queries/notes/useCreateNoteMutation";
import { useUpdateNoteMutation } from "@hooks/queries/notes/useUpdateNoteMutation";
import { useDeleteNoteMutation } from "@hooks/queries/notes/useDeleteNoteMutation";
import { useDeepCompareEffect } from "react-use";

interface Note {
  id: number;
  note: string;
  isEditing: boolean;
  originalNote?: string;
}

export const useNotesManager = () => {
  const { notes: allNotes } = useNotesInfiniteQuery();
  const { handleCreateNote } = useCreateNoteMutation();
  const { handleUpdateNote } = useUpdateNoteMutation();
  const { handleDeleteNote: handleDelete } = useDeleteNoteMutation();
  const [newNote, setNewNote] = useState("");

  const [notes, setNotes] = useState<Note[]>(allNotes || []);
  const textFieldRefs = useRef<Array<HTMLInputElement | null>>([]);

  useDeepCompareEffect(() => {
    if (allNotes) {
      setNotes(
        allNotes.map(note => ({
          ...note,
          isEditing: false,
        })),
      );
    }
  }, [allNotes]);

  useDeepCompareEffect(() => {
    notes.forEach((note, index) => {
      if (note.isEditing && textFieldRefs.current[index]) {
        const input = textFieldRefs.current[index];
        if (input) {
          const length = input.value.length;
          input.setSelectionRange(length, length);
          input.focus();
        }
      }
    });
  }, [notes]);

  const handleEdit = (id: number) => {
    setNotes(prev =>
      prev.map(note =>
        note.id === id
          ? { ...note, isEditing: true }
          : { ...note, isEditing: false },
      ),
    );
  };

  const handleChange = (id: number, newText: string) => {
    setNotes(prev =>
      prev.map(note => (note.id === id ? { ...note, note: newText } : note)),
    );
  };

  const handleBlur = (id: number) => {
    const noteToUpdate = notes.find(note => note.id === id);
    if (noteToUpdate && !noteToUpdate.note.trim().length) {
      handleDelete(String(id));
      return;
    }

    if (noteToUpdate?.originalNote === noteToUpdate?.note) {
      setNotes(prev =>
        prev.map(note =>
          note.id === id ? { ...note, isEditing: false } : note,
        ),
      );
      return;
    }

    if (noteToUpdate) {
      handleUpdateNote({ id: String(id), note: noteToUpdate.note });
      setNotes(prev =>
        prev.map(note =>
          note.id === id
            ? { ...note, isEditing: false, originalNote: note.note }
            : note,
        ),
      );
    }
  };

  const handleAdd = () => {
    if (!newNote.trim().length) return;
    handleCreateNote({ note: newNote });
    setNewNote("");
  };

  return {
    notes,
    setNotes,
    handleEdit,
    handleChange,
    handleBlur,
    handleDelete,
    handleAdd,
    textFieldRefs,
    newNote,
    setNewNote,
  };
};
