import { styled } from "@mui/material";

interface HistorySectionWrapperProps {
  flexVariant: boolean;
}

export const HistorySectionWrapper = styled("div")<HistorySectionWrapperProps>`
  ${({ flexVariant, theme }) =>
    flexVariant
      ? `
      width: 100%;
      min-width: 19.125rem; 
      max-width: 28.75rem;
    `
      : `
      grid-row-start: 1;
      grid-column-start: 2;

      @media screen and (min-width: ${theme.breakpoints.values.medium}px) {
      grid-row-start: unset;
      grid-column-start: unset;
      }
    `}
`;
