import { useState } from "react";

const useToggleMeals = () => {
  const [expandedMeals, setExpandedMeals] = useState<number[]>([]);
  const [mealsId, setMealsId] = useState<number[]>([]);
  const handleOnClickExpanded = () => {
    if (mealsId.length === 0) {
      setExpandedMeals([]);
    }
    expandedMeals.length < mealsId.length
      ? setExpandedMeals(mealsId)
      : setExpandedMeals([]);
  };

  const toggleMeal = (id: number) => {
    isOpenMeal(id)
      ? setExpandedMeals(prev => prev.filter(i => i !== id))
      : setExpandedMeals(prev => [...prev, id]);
  };
  const isOpenMeal = (id: number) => expandedMeals.includes(id);
  const isOpen = () =>
    expandedMeals.length !== 0 && expandedMeals.length === mealsId.length;

  const handleSetMealsId = (mealsId: number[]) => {
    setExpandedMeals([]);
    setMealsId(mealsId);
  };

  const showExpandedAll = () => mealsId.length > 0;

  return {
    setMealsId: handleSetMealsId,
    toggleExpandedMeal: toggleMeal,
    toggleExpandedAll: handleOnClickExpanded,
    isExpandedMeal: isOpenMeal,
    isExpandedAll: isOpen,
    showExpandedAll: showExpandedAll,
  };
};

export default useToggleMeals;
