import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const deleteDietProductRemoved = async (
  dietId: string,
  productId: string,
) => {
  return await fetchData(
    `/dietitian/diets/${dietId}/product-removed`,
    APIMethods.DELETE,
    { productId },
  );
};
