import { forwardRef, ChangeEvent, Fragment, memo } from "react";

import classNames from "classnames";

import { Check } from "@assets/icons";

export const Checkbox = memo(
  forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
    const {
      label,
      error,
      disabled,
      withBackground,
      iconEnd,
      fullWidth,
      className,
      onChange,
      preventDefault,
      round,
      value,
      ...rest
    } = props;

    return (
      <Fragment>
        <div
          className={classNames({
            "w-full": fullWidth,
            "w-max": !fullWidth,
          })}
        >
          <label
            className={classNames(
              {
                "relative flex items-center font-roman text-sm": true,
                "bg-gray-300 border border-gray-500 rounded-lg w-full h-full p-6 justify-between":
                  withBackground,
                "cursor-pointer": !disabled,
                "cursor-not-allowed": disabled,
              },
              className,
            )}
          >
            <div className="flex items-center">
              <input
                ref={ref}
                type="checkbox"
                disabled={disabled}
                className={classNames({
                  "opacity-0 absolute left-0 w-full h-6 cursor-pointer": true,
                  "cursor-not-allowed": disabled,
                })}
                checked={value}
                onChange={onChange}
                {...rest}
              />
              <div
                className={classNames({
                  "flex flex-shrink-0 justify-center items-center": true,
                  "border rounded-md w-6 h-6 mr-3": !round,
                  "w-11 h-11 rounded-full": round,
                  "text-white": round && value,
                  "bg-gray-600": round && !value,
                  "bg-white border-gray-700": !round && !value,
                  "!bg-gray-700": disabled,
                })}
              >
                {round ? label : <Check />}
              </div>
              {!round && label}
            </div>
            {withBackground && iconEnd && (
              <span
                className={classNames({
                  "ml-8 justify-self-end": true,
                  "text-primary": value,
                  "text-gray-700": !value,
                })}
              >
                {iconEnd}
              </span>
            )}
          </label>
        </div>
        {!!error && (
          <p className="text-xs text-light text-red pt-1 pl-2">{error}</p>
        )}
      </Fragment>
    );
  }),
);

export interface CheckboxProps {
  name?: string;
  label?: string | JSX.Element;
  error?: string;
  value?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  withBackground?: boolean;
  iconEnd?: React.ReactNode;
  fullWidth?: boolean;
  className?: string;
  preventDefault?: boolean;
  round?: boolean;
}
