import { memo } from "react";

import { format } from "date-fns";

import { WEEKDAYS_MAP, WEEKDAYS_MAP_EN } from "@utils/constants";
import { useAppParams, useAppTranslation } from "@hooks";
import { Card } from "@components/Card";

import { dayIndexFactory } from "./scheduleDayUtils";

interface EmptyScheduleGridDayProps {
  date: string;
  color: string;
  startDate?: string;
}

export const EmptyScheduleGridDay = memo(
  ({ date, color, startDate }: EmptyScheduleGridDayProps) => {
    const { t, isPolishChosen } = useAppTranslation();

    const dateObj = new Date(date);

    const { patientId } = useAppParams();
    const programDayIdx = dayIndexFactory(date, startDate);

    return (
      <Card
        square
        noContainerPaddings
        className="min-w-[8rem] border-l-4 mr-1.5 -my-3 h-auto sticky left-0 z-10"
        style={{ borderColor: color }}
        containerClassName="items-center justify-center text-sm flex flex-col"
      >
        <div className="grid justify-items-center">
          <span className="font-semibold mb-1">
            {
              ((isPolishChosen ? WEEKDAYS_MAP : WEEKDAYS_MAP_EN) as any)[
                dateObj.getDay()
              ]
            }
          </span>
          <span className="mb-2">
            {patientId
              ? format(dateObj, "dd.MM.yyyy")
              : startDate && `${t("common.day")} ${programDayIdx}`}
          </span>
        </div>
      </Card>
    );
  },
);
