import { styled } from "@mui/material";
import { GridRow } from "@mui/x-data-grid";

export const GridRowStyled = styled(GridRow)`
  &.MuiDataGrid-row {
    margin: 0 auto;
    width: calc(100% - 4px);
    border-radius: 8px;
    outline: 1px solid ${({ theme }) => theme.colors.neutral.light[200]};
  }
`;
