import { ClinicIdDto, LangDto, clinicIdSchema } from "@client/common";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import * as yup from "yup";

export const fetchSurveys = async (): Promise<ApiResponse<SurveyDto[]>> => {
  const response = await fetchData("/dietitian/surveys", APIMethods.GET);

  return await fetchSurveysSchema.validate(response);
};

interface AuthorDto {
  id: number;
  fullName: string;
}
export interface SurveyDto {
  id: number;
  clinic: ClinicIdDto;
  langId: LangDto;
  name: string;
  introduction: IntroductionDto;
  groups: unknown[];
  numberOfQuestions: number;
  reviewed: boolean;
  author: AuthorDto | null;
}

export interface IntroductionDto {
  on: boolean;
  title: string | null;
  description: string | null;
}

const authorSchema = yup.object().shape({
  id: yup.number().required(),
  fullName: yup.string().required(),
});

export const introductionSchema = yup.object().shape({
  on: yup.boolean().required(),
  title: yup.string().nullable().default(null),
  description: yup.string().nullable().default(null),
});

const surveySchema = yup.object().shape({
  id: yup.number().required(),
  clinic: clinicIdSchema.required(),
  langId: yup.string().oneOf(Object.values(LangDto)).required(),
  name: yup.string().required(),
  introduction: introductionSchema.required(),
  groups: yup.array<[]>().required(),
  numberOfQuestions: yup.number().required(),
  reviewed: yup.boolean().required(),
  author: authorSchema.nullable().default(null),
});

export const fetchSurveysSchema = yup.object().shape({
  data: yup.array().of(surveySchema).required(),
});
