import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Popper,
  styled,
} from "@mui/material";

export const PopperStyled = styled(Popper)`
  & .MuiAutocomplete-listbox {
    padding: 0 !important;
  }
`;

export const AccordionStyled = styled(Accordion)`
  &.MuiAccordion-root {
    border-radius: 8px;
    box-shadow: none;

    &.MuiPaper-root.MuiAccordion-root.Mui-expanded {
      margin: 0;
    }
  }

  :before {
    display: none;
  }
`;

export const AccordionDetailsStyled = styled(AccordionDetails)`
  padding: 0;
`;

export const AccordionSummaryStyled = styled(AccordionSummary)`
  padding: 8px 16px;
  position: sticky;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.neutral.light[100]};

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }

  & .Mui-expanded {
    margin: 0;
  }

  &.MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-gutters {
    min-height: unset;
  }
  & .MuiAccordionSummary-content.Mui-expanded {
    min-height: unset;
    margin: 0;
  }
  & .MuiAccordionSummary-content {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.neutral.dark[700]};
    margin: 0;
  }
`;
