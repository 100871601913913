import { useQuery } from "@tanstack/react-query";

import { fetchSchedulesDictionaries } from "@client";
import { QueryOptions } from "./types";
import { buildDefaultQueryOptions } from "./common";

export const fetchSchedulesDictionariesQueryKey = "fetchSchedulesDictionaries";

export const useFetchSchedulesDictionariesQuery = (options?: QueryOptions) => {
  const { data, ...rest } = useQuery(
    [fetchSchedulesDictionariesQueryKey],
    () => fetchSchedulesDictionaries(),
    buildDefaultQueryOptions(options),
  );

  return { schedulesDictionaries: data, ...rest };
};
