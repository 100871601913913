import { Dispatch, SetStateAction, useState } from "react";
import { Popover, Tooltip } from "@mui/material";
import {
  ContentWrapper,
  MenuButton,
  Title,
} from "./ClientProfileActionButtons.styled";
import { useNavigate } from "react-router-dom";
import { ConfirmationWrapper } from "@components/ConfirmationWrapper";
import {
  useAppParams,
  useAppTranslation,
  useUpdateClientPayments,
} from "@hooks";
import {
  useDeleteClientMutation,
  useFetchClientQuery,
  usePostClientEmailVerification,
} from "@hooks/queries";
import { toast } from "react-toastify";
import { PATIENTS } from "@routes";

interface ClientsListActionModalProps {
  id: number;
  anchorEl?: HTMLElement | null;
  open: boolean;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  handleClose: Dispatch<SetStateAction<boolean>>;
  hasEmail?: boolean;
}

export const ClientProfileActionButtons = ({
  open,
  anchorEl,
  setAnchorEl,
  id,
  hasEmail,
}: ClientsListActionModalProps) => {
  const { t } = useAppTranslation();
  const { client } = useFetchClientQuery(id);
  const { programId } = useAppParams();
  const navigate = useNavigate();
  const emailVerified = client?.emailVerified;
  const isActive = !!client?.active;
  const activatedMobileApp = !!client?.active?.mobileApp;
  const [isSent, setIsSent] = useState(false);
  const disable = !emailVerified && !isSent && activatedMobileApp;
  const alreadySent = isSent
    ? t("client_profile.already_sent_link_tooltip")
    : t("client_profile.already_confirmed_email_tooltip");

  const { mutate: deleteClientMutation, isLoading: isLoadingDeleteClient } =
    useDeleteClientMutation({ onSuccess: () => navigate(PATIENTS) });

  const { mutate, isLoading } = useUpdateClientPayments();
  const { mutate: EmailVerificationMutate } = usePostClientEmailVerification(
    id.toString(),
    {
      onSuccess: () => {
        toast.success(t("client_profile.resend_success"));
        setIsSent(true);
      },
    },
  );

  const onClientDeactivate = () => {
    mutate({
      id: id.toString(),
      payload: { activeUntil: null, isActive: false },
    });
    setAnchorEl(null);
  };

  const handleRemove = () => {
    deleteClientMutation(id);
    setAnchorEl(null);
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <ContentWrapper>
        <div className="grid">
          <Title variant="body2"> {t("common.client")}</Title>
          <Tooltip
            title={t("client_profile.cant_deactivate_client_tooltip")}
            disableHoverListener={!programId}
            arrow
            placement="top"
          >
            <div>
              <MenuButton
                onClick={onClientDeactivate}
                disabled={isLoading || !!programId}
              >
                {t("common.deactivate")}
              </MenuButton>
            </div>
          </Tooltip>
          {hasEmail && isActive && (
            <Tooltip
              title={
                !activatedMobileApp
                  ? t("client_profile.cannot_sent_link_inactive_app_tooltip")
                  : alreadySent
              }
              disableHoverListener={disable}
              arrow
              placement="top"
            >
              <div>
                <MenuButton
                  disabled={!disable}
                  onClick={() => EmailVerificationMutate()}
                >
                  {t("client_profile.info.resend_activation_link")}
                </MenuButton>
              </div>
            </Tooltip>
          )}
          <ConfirmationWrapper
            onConfirm={handleRemove}
            confirmationText={t("clients.actions.confirmation")}
            submitText={t("clients.actions.yes")}
          >
            <MenuButton loading={isLoadingDeleteClient}>
              {t("clients.actions.delete")}
            </MenuButton>
          </ConfirmationWrapper>
        </div>
      </ContentWrapper>
    </Popover>
  );
};
