import { APIMethods, ApiResponse, LanguagesSlugs } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import * as yup from "yup";
import { logFetchValidateError } from "@utils/validate";
import { ImageDto, NutrientDto, nutrientSchema } from "@client";

export interface FetchDietProgramPreviewParams {
  programId: number;
  dietId: number;
}

export type FetchDietProgramPreviewResponse =
  ApiResponse<DietProgramSearchPreview>;

export const fetchDietProgramPreview = async ({
  programId,
  dietId,
}: FetchDietProgramPreviewParams): Promise<FetchDietProgramPreviewResponse> => {
  const response = await fetchData(
    `/dietitian/programs-search-preview/${programId}/diets/${dietId}`,
    APIMethods.GET,
  );

  return await responseSchema
    .validate(response)
    .catch(logFetchValidateError("fetchDietProgramPreview"));
};

export interface DietProgramSearchPreview {
  id: string;
  name: string;
  translations: TranslationDto[];
  nutrients: NutrientDto[];
  meals: MealDto[];
  image: ImageDto | null;
}

export interface TranslationDto {
  lang: LanguagesSlugs;
  name: string | null;
}

export interface MealDto {
  id: number;
  name: string;
  translations: TranslationDto[];
  itemsCount: number;
}

export const translationSchema = yup.object().shape({
  lang: yup
    .mixed<LanguagesSlugs>()
    .oneOf(Object.values(LanguagesSlugs))
    .required(),
  name: yup.string().nullable().default(null),
});

const mealSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  translations: yup.array().of(translationSchema.required()).required(),
  itemsCount: yup.number().required(),
});

export const dietProgramSearchPreviewSchema = yup.object({
  id: yup.string().required(),
  name: yup.string().required(),
  translations: yup.array().of(translationSchema.required()).required(),
  nutrients: yup.array().of(nutrientSchema.required()).required(),
  meals: yup.array().of(mealSchema).required(),
  image: yup
    .object({
      url: yup.string().required(),
    })
    .nullable()
    .defined(),
});

const responseSchema = yup.object({
  data: dietProgramSearchPreviewSchema.required(),
});
