import { SvgIcon, SvgIconProps } from "@mui/material";

export const ClosedBook = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M7.1875 3.25H19V20.75H7.1875C6.60734 20.75 6.05094 20.5195 5.6407 20.1093C5.23047 19.6991 5 19.1427 5 18.5625V5.4375C5 4.85734 5.23047 4.30094 5.6407 3.8907C6.05094 3.48047 6.60734 3.25 7.1875 3.25Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M5 18.5625C5 17.9823 5.23047 17.4259 5.6407 17.0157C6.05094 16.6055 6.60734 16.375 7.1875 16.375H19"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
