import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const CloudWithCheck = (props: SvgIconProps) => {
  return (
    <SvgIcon width="18" height="16" viewBox="0 0 18 16" {...props}>
      <path
        d="M4.27132 7.61887C0.845759 7.85231 0.845759 12.6696 4.27132 12.9031H5.67667M4.30792 7.61887C1.95833 1.30188 11.869 -1.2235 13.0036 5.41181C16.1729 5.80088 17.4539 9.88252 15.053 11.9127C14.3211 12.5564 13.3769 12.9101 12.3887 12.9031H12.3228"
        stroke={props.fill ?? "currentColor"}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M11.7743 11.2691C11.7743 11.7473 11.6709 12.1996 11.4771 12.6002C11.4254 12.7165 11.3672 12.8264 11.3026 12.9298C11.0186 13.4085 10.6148 13.8051 10.131 14.0804C9.64722 14.3558 9.10006 14.5004 8.5434 14.5C7.36735 14.5 6.33993 13.8667 5.78421 12.9298C5.71959 12.8264 5.66144 12.7165 5.60974 12.6002C5.41589 12.1996 5.3125 11.7473 5.3125 11.2691C5.3125 9.48564 6.75994 8.03819 8.5434 8.03819C10.3269 8.03819 11.7743 9.48564 11.7743 11.2691Z"
        stroke={props.fill ?? "currentColor"}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M7.53516 11.2691L8.17487 11.9088L9.55124 10.6358"
        stroke={props.fill ?? "currentColor"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};
