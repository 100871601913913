import { useQueryClient } from "@tanstack/react-query";

import { ProductExchangeDto, RemovedProductDto } from "@client/diets";
import {
  fetchDietProductsRemovedQueryKey,
  fetchDietsProductsReplacementQueryKey,
} from "@hooks/queries/diets";
import { ApiResponse } from "@typeDefinitions";

import { Tab } from "../ProductsTab";

export const useHistoryData = (dietId: string) => {
  const queryClient = useQueryClient();

  const removedData = queryClient.getQueryData<
    ApiResponse<RemovedProductDto[]>
  >([fetchDietProductsRemovedQueryKey, dietId]);
  const replacedData = queryClient.getQueryData<
    ApiResponse<ProductExchangeDto[]>
  >([fetchDietsProductsReplacementQueryKey, dietId]);

  const hasHistory = (variant: string) => {
    if (variant === Tab.DELETED) return !!removedData?.data.length;
    if (variant === Tab.REPLACED) return !!replacedData?.data.length;
  };

  return {
    hasHistory,
  };
};
