import { CSSProperties, useCallback, useRef, useState } from "react";

import {
  IconButton,
  InputAdornment,
  OutlinedTextFieldProps,
  Popover,
  SxProps,
  Theme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { ArrowDown } from "@assets/icons";

import { TreeNode, TreeSelectOption } from "./TreeNode";
import { useTreeSelectTitle } from "./useTreeSelectTitle";
import { StyledTreeView, TextFieldStyled } from "./TreeSelectMui.styled";

interface TreeSelectProps extends OutlinedTextFieldProps {
  menuStyle?: CSSProperties;
  value?: string;
  options: TreeSelectOption[];
  error?: boolean;
  inputValue?: string;
  className?: string;
  onSelectOption: (value: string) => void;
  menuSx?: SxProps<Theme>;
}

export const TreeSelectMui = ({
  menuStyle,
  options,
  onChange,
  value,
  error,
  inputValue,
  className,
  onSelectOption,
  menuSx,
  ...rest
}: TreeSelectProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [focused, setFocus] = useState(false);

  const enableFocus = () => setFocus(true);
  const disableFocus = () => setFocus(false);
  const toggleFocus = () => setFocus(!focused);

  const onSelect = useCallback(
    (value: string) => {
      onSelectOption(value);
      disableFocus();
    },
    [onChange],
  );

  const title = useTreeSelectTitle(options, value);

  return (
    <div ref={containerRef} className={className}>
      <TextFieldStyled
        value={inputValue ?? title}
        onClick={enableFocus}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={toggleFocus}>
                <ArrowDown size="h-5 w-5" />
              </IconButton>
            </InputAdornment>
          ),
        }}
        error={error}
        {...rest}
      />
      <Popover
        anchorEl={containerRef.current}
        open={focused}
        onClose={disableFocus}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        sx={menuSx}
      >
        <StyledTreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          <TreeNode options={options} onClick={onSelect} />
        </StyledTreeView>
      </Popover>
    </div>
  );
};
