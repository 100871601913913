import { ListTabs } from "@hooks";
import { useController, useFormContext } from "react-hook-form";
import { CheckNew } from "@assets/icons";
import { CustomChip } from "@components/Filters/TabsChipsFilter/TabsChipsFilter.styled";
import { useEffect } from "react";

type AllFilterTabChipType = {
  label: string;
  total: number;
};

const AllFilterTabChip = ({ label, total }: AllFilterTabChipType) => {
  const { setValue, watch, control } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ control, name: ListTabs.ALL });
  const [unverified, own, favorite, latest] = watch([
    ListTabs.UNVERIFIED,
    ListTabs.OWN,
    ListTabs.FAVORITE,
    ListTabs.LATEST,
  ]);

  useEffect(() => {
    let value: string | null = "1";
    if (unverified || own || favorite || latest) {
      value = null;
    }
    onChange(value);
  }, [unverified, own, favorite, latest]);

  const handleOnClick = () => {
    setValue(ListTabs.OWN, null);
    setValue(ListTabs.LATEST, null);
    setValue(ListTabs.FAVORITE, null);
    setValue(ListTabs.UNVERIFIED, null);
    setValue(ListTabs.ALL, "1");
  };

  return (
    <CustomChip
      data-chip="true"
      color={value === "1" ? "primary" : "default"}
      onClick={handleOnClick}
      variant="outlined"
      label={
        <div className="flex items-center gap-2">
          {value === "1" && <CheckNew size="w-[12px] h-[12px]" />}
          {value === "1" ? `${label} (${total})` : label}
        </div>
      }
    />
  );
};

export default AllFilterTabChip;
