import * as yup from "yup";

import { NutrientDto, nutrientSchema } from "@client/common";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const fetchFoodNutrientsNew = async (
  id: string,
): Promise<ApiResponse<NutrientDto[]>> => {
  const response = await fetchData(
    `/dietitian/food/${id}/nutrients-new`,
    APIMethods.GET,
  );

  return responseSchema.validate(response);
};

const responseSchema = yup.object().shape({
  data: yup.array().of(nutrientSchema.required()).required(),
});
