import { Button, useMediaQuery, useTheme } from "@mui/material";

import { PlayInCircle } from "@assets/icons";
import { useAppTranslation } from "@hooks";

import {
  CardStyled,
  FixedButtonWrapper,
  LayoutWithButton,
  SmallCard,
  TextStyled,
  TextWrapper,
  TitleStyled,
} from "./IntroductionView.styled";

interface IntroductionViewProps {
  onClick: () => void;
  introductionTitle: string;
  introductionText: string;
  show?: boolean;
}

export const IntroductionView = ({
  onClick,
  introductionTitle,
  introductionText,
  show,
}: IntroductionViewProps) => {
  const { breakpoints } = useTheme();
  const downSmall = useMediaQuery(`${breakpoints.down("smallNew")}`);
  const { t } = useAppTranslation();

  if (downSmall)
    return (
      <>
        {show && (
          <TextWrapper>
            <TitleStyled>{introductionTitle}</TitleStyled>

            <TextStyled>{introductionText}</TextStyled>
          </TextWrapper>
        )}

        <FixedButtonWrapper>
          <Button
            fullWidth
            startIcon={<PlayInCircle size="w-4 h-4" />}
            onClick={onClick}
            variant="contained"
          >
            {t("questionnaires.start")}
          </Button>
        </FixedButtonWrapper>
      </>
    );

  if (!show)
    return (
      <SmallCard>
        <Button
          startIcon={<PlayInCircle size="w-4 h-4" />}
          onClick={onClick}
          variant="contained"
        >
          {t("questionnaires.start")}
        </Button>
      </SmallCard>
    );
  return (
    <CardStyled>
      <LayoutWithButton>
        <TextWrapper>
          <TitleStyled>{introductionTitle}</TitleStyled>

          <TextStyled>{introductionText}</TextStyled>
        </TextWrapper>
        <Button
          startIcon={<PlayInCircle size="w-4 h-4" />}
          onClick={onClick}
          variant="contained"
        >
          {t("questionnaires.start")}
        </Button>
      </LayoutWithButton>
    </CardStyled>
  );
};
