import { ListItemIcon, ListItemText } from "@mui/material";
import { useDeletePatientProgramMutation } from "@hooks/queries";
import { TrashNew } from "@assets/icons";
import { CustomMenuItem } from "@views/dietician/PatientProgramsNew/PatientProgramsGrid.styled";
import { useAppTranslation } from "@hooks";
import DeleteModal from "@components/modals/DeleteModal/DeleteModal";
import { useState } from "react";
import { useProgramSearchPreviewDrawer } from "@components/ProgramSearchPreview/Context/ProgramSearchPreviewDrawerContext";

type DeleteActionProps = {
  onSuccess: () => void;
  patientId: number;
  programId: number;
};

const DeleteAction = ({
  onSuccess,
  patientId,
  programId,
}: DeleteActionProps) => {
  const { t } = useAppTranslation();
  const { onClose } = useProgramSearchPreviewDrawer();
  const [open, setOpen] = useState(false);
  const { mutate, isLoading: isDeleting } =
    useDeletePatientProgramMutation(patientId);

  const handleRemove = () => {
    setOpen(true);

    if (isDeleting) {
      return;
    }

    mutate(programId, {
      onSuccess: () => {
        onSuccess();
        setOpen(false);
        onClose();
      },
    });
  };

  return (
    <>
      <CustomMenuItem disabled={isDeleting} onClick={() => setOpen(true)}>
        <ListItemIcon>
          <TrashNew fill="#727272" style={{ width: "24px" }} />
        </ListItemIcon>
        <ListItemText>
          {t("patient.programs_grid.row.actions.delete")}
        </ListItemText>
      </CustomMenuItem>
      <DeleteModal
        open={open}
        onClose={() => setOpen(false)}
        isLoading={isDeleting}
        confirmOnClick={handleRemove}
        content={t("patient.programs_grid.row.actions.delete_confirm_question")}
        title={t("common.remove")}
      />
    </>
  );
};

export default DeleteAction;
