import { useEffect } from "react";
import { useForm } from "react-hook-form";

import * as yup from "yup";

import {
  CheckboxAnswerQuestionDto,
  UpdateSingedSurveyRequest,
} from "@client/surveys";
import { yupResolver } from "@hookform/resolvers/yup";

export const useCheckboxAnswerForm = (
  values: CheckboxAnswerFormInputs | undefined,
  required: boolean,
) => {
  const resolver = yup.object().shape({
    uuids: required
      ? yup.array().of(yup.string().required()).min(1)
      : yup.array().of(yup.string().required()),
    text: yup.string().nullable(),
  });

  const form = useForm<CheckboxAnswerFormInputs>({
    resolver: yupResolver(resolver),
  });

  useEffect(() => {
    if (values) form.reset(values);
  }, [values]);

  return form;
};

export const mapCheckboxAnswerForm = (
  data?: CheckboxAnswerQuestionDto,
): CheckboxAnswerFormInputs => {
  if (!data) return { text: null, uuids: [] };
  return data;
};

export const mapCheckboxAnswerRequest = (
  data: CheckboxAnswerFormInputs,
  questionId: number,
): UpdateSingedSurveyRequest => {
  if (!data.uuids) return { questionId, answer: { text: null, uuids: [] } };
  return { questionId, answer: data };
};

export interface CheckboxAnswerFormInputs {
  uuids: string[];
  text: string | null;
}
