import { lazy } from "yup";
import dayjs, { Dayjs } from "dayjs";
import { useClientParams } from "@hooks";
import {
  useCreateClientMeasurementMutation,
  useUpdateClientMeasurementMutation,
} from "@hooks/queries";
import { useFetchBodyMeasurementsQuery } from "@hooks/queries/dictionaries";
import { BodyMeasurementResource } from "@client/dictionaries";
import { ReactElement, useEffect } from "react";
import * as yup from "yup";
import { mapObjectDynamicFieldsRules } from "@utils/yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { BodyMeasurementConst } from "@consts/BodyMeasurementConst";
import {
  mapFormDataToBodyRequest,
  measurementSchema,
} from "@components/Client/FormBodyMeasurementsModal/utils/BodyMeasurementsFormUtils";
import useFetchPatientBodyMeasurement from "@hooks/queries/client/bodyMeasurement/useFetchPatientBodyMeasurement";
import { PatientMeasurementResource } from "@client/resources/PatientMeasurementResource";
import useUpdatePatientBodyMeasurementMutation from "@hooks/queries/client/bodyMeasurement/useUpdatePatientBodyMeasurementMutation";

export type FormPops = {
  measurementBody: {
    [key: string]: string;
  };
  date: Dayjs;
};

const UpdatePatientBodyMeasurementFormWrapper = ({
  children,
  onSuccess,
  patientBodyMeasurementId,
}: Pick<UpdatePatientBodyMeasurementFormProps, "children" | "onSuccess"> & {
  patientBodyMeasurementId: number;
}) => {
  const id = useClientParams();

  const { data: bodyMeasurements } = useFetchBodyMeasurementsQuery();
  const { data: patientBodyMeasurement } = useFetchPatientBodyMeasurement(
    {
      patientId: id,
      bodyMeasurementId: patientBodyMeasurementId,
    },
    {
      staleTime: 0,
    },
  );

  if (!bodyMeasurements || !patientBodyMeasurement) {
    return null;
  }

  return (
    <UpdatePatientBodyMeasurementForm
      onSuccess={onSuccess}
      bodyMeasurements={bodyMeasurements.data}
      clientId={id}
      patientBodyMeasurement={patientBodyMeasurement.data}
    >
      {children}
    </UpdatePatientBodyMeasurementForm>
  );
};

type UpdatePatientBodyMeasurementFormProps = {
  bodyMeasurements: BodyMeasurementResource[];
  clientId: number;
  patientBodyMeasurement: PatientMeasurementResource;
  children: ReactElement | ReactElement[];
  onSuccess: () => void;
};

const formSchema = yup.object({
  measurementBody: lazy(map =>
    yup.object(mapObjectDynamicFieldsRules(map, measurementSchema)).required(),
  ),
});

const UpdatePatientBodyMeasurementForm = ({
  bodyMeasurements,
  clientId,
  children,
  onSuccess,
  patientBodyMeasurement,
}: UpdatePatientBodyMeasurementFormProps) => {
  const { mutate, isLoading } = useUpdatePatientBodyMeasurementMutation();

  const getMapDefaultValues = () => {
    return {
      measurementBody: Object.fromEntries(
        bodyMeasurements.map(body => {
          const bodyMeasurementValue =
            patientBodyMeasurement.bodyMeasurements.find(
              bm => bm.body.id === body.id,
            );

          return [body.id, bodyMeasurementValue?.value?.toString() ?? ""];
        }),
      ),
      date: dayjs(patientBodyMeasurement.date),
    };
  };

  const form = useForm<FormPops>({
    defaultValues: getMapDefaultValues(),
    resolver: yupResolver(formSchema),
    mode: "onChange",
  });

  useEffect(() => {
    form.reset(getMapDefaultValues());
  }, [patientBodyMeasurement]);

  const onSubmit: SubmitHandler<FormPops> = async data => {
    const requestPayload = mapFormDataToBodyRequest(data);
    if (!requestPayload.bodyMeasurements.length) {
      onSuccess();
      return;
    }

    if (isLoading) {
      return;
    }

    await new Promise<void>(resolve => {
      mutate(
        {
          params: {
            bodyMeasurementId: patientBodyMeasurement.id,
            patientId: clientId,
          },
          payload: requestPayload,
        },
        {
          onSuccess: () => {
            resolve();
            onSuccess();
          },
        },
      );
    });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>{children}</form>
    </FormProvider>
  );
};

export default UpdatePatientBodyMeasurementFormWrapper;
