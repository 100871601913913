import { Card, styled } from "@mui/material";

export const GridWrapper = styled("div")`
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
`;

export const CardStyled = styled(Card)`
  box-shadow: ${({ theme }) => theme.boxShadows.card};
  padding-top: 8px;
`;

export const PaginationWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
