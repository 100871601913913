import { Button, Divider, styled } from "@mui/material";
import { getHexColorOpacity } from "@utils";

export const DividerStyled = styled(Divider)`
  border-style: dashed;
  border-width: 1px;
  border-color: ${({ theme }) =>
    getHexColorOpacity(theme.colors.neutral.dark[700], 10)};
  height: 100%;
`;

export const MicrosWrapper = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const ButtonStyled = styled(Button)`
  display: flex;
  gap: 16px;
  flex: 1;
  padding: 0;
  align-self: center;
  max-width: 320px;
  &.MuiButton-outlined {
    border: unset;
  }
`;
