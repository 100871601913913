import * as yup from "yup";
import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse, LanguagesSlugs } from "@typeDefinitions";
import { logFetchValidateError } from "@utils/validate";

export type FetchBodyMeasurementsDictionaryResponse = ApiResponse<
  BodyMeasurementResource[]
>;

export async function fetchBodyMeasurements(): Promise<FetchBodyMeasurementsDictionaryResponse> {
  const response = await fetchData(
    `/dietitian/dictionaries/body-measurements`,
    APIMethods.GET,
  );

  return schema
    .validate(response)
    .catch(logFetchValidateError("/dietitian/dictionaries/body-measurements"));
}

export interface BodyMeasurementResource {
  id: number;
  name: string;
  translations: {
    lang: LanguagesSlugs;
    name: string;
  }[];
  unit: string | null;
}

const bodyMeasurementSchema = yup.object({
  id: yup.number().required().integer(),
  name: yup.string().required(),
  translations: yup
    .array()
    .of(
      yup.object({
        lang: yup
          .mixed<LanguagesSlugs>()
          .oneOf(Object.values(LanguagesSlugs).map(e => e as LanguagesSlugs))
          .required(),
        name: yup.string().required(),
      }),
    )
    .required(),
  unit: yup.string().nullable().defined(),
});

const schema = yup.object({
  data: yup.array().of(bodyMeasurementSchema).defined(),
});
