import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const RadioChecked = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16">
    <rect
      x="0.5"
      y="0.5"
      width="15"
      height="15"
      rx="7.5"
      fill="none"
      stroke={props.fill ?? "currentColor"}
    />
    <path
      d="M3 8C3 5.23858 5.23858 3 8 3C10.7614 3 13 5.23858 13 8C13 10.7614 10.7614 13 8 13C5.23858 13 3 10.7614 3 8Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
