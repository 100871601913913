import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "../types";
import { fetchPatientVisitsQueryKey } from "./useFetchPatientVisitsQuery";
import { removeFoodFromVisit } from "@client/visits";

export const useRemoveVisitFoodMutation = (
  patientId: number,
  scheduleId: number,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: { foodId: number }) =>
      removeFoodFromVisit(scheduleId, payload.foodId),
    {
      ...options,
      onSuccess() {
        queryClient.invalidateQueries([fetchPatientVisitsQueryKey, patientId]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};
