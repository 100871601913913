import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchClientBalanceQueryKey } from "./useFetchClientBalanceQuery";
import { ApiResponse } from "@typeDefinitions";
import { clientBalanceOptimisticUpdate } from "@hooks/utils/clientBalanceOptimisticUpdate";
import {
  FetchClientBalanceResponse,
  updateClientBalance,
  UpdateClientBalanceRequest,
} from "@client";

export const useUpdateClientBalanceMutation = (patientId: string) => {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    unknown,
    UpdateClientBalanceRequest,
    { invalidate: boolean }
  >(payload => updateClientBalance(patientId, payload), {
    onMutate: async payload => {
      await queryClient.cancelQueries([fetchClientBalanceQueryKey, patientId]);

      const hasNewDay = payload.days.some(day => day.id === null);

      if (!hasNewDay) {
        queryClient.setQueryData<ApiResponse<FetchClientBalanceResponse>>(
          [fetchClientBalanceQueryKey, patientId],
          () => clientBalanceOptimisticUpdate(payload),
        );
      }

      return { invalidate: hasNewDay };
    },
    onError: () => {
      queryClient.invalidateQueries([fetchClientBalanceQueryKey]);
    },
    onSettled: (data, error, variables, context) => {
      if (context?.invalidate) {
        queryClient.invalidateQueries([fetchClientBalanceQueryKey, patientId]);
      }
    },
  });
};
