import { Card, styled } from "@mui/material";

import { MuiDataGrid } from "@components/MuiDataGrid";

export const EmptyStateCardForGrid = styled(Card)`
  display: grid;
  place-content: center;
  border-radius: 14px;
  box-shadow: ${({ theme }) => theme.boxShadows.card};
  height: 100%;
  padding: 28px 0;
  margin-top: 7px;
`;

export const StyledMuiDataGrid = styled(MuiDataGrid)`
  min-height: 378px;
`;
