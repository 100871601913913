import { FormTextField } from "@components/FormTextField";
import {
  Button,
  Chip,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Typography,
  styled,
} from "@mui/material";

export const BigButton = styled(Button)`
  gap: 0.25rem;
  flex-direction: column;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.4px;
  width: 14.5rem;
  height: 5.125rem;
  border-radius: 0.75rem;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
  text-align: center;

  & .MuiButton-startIcon {
    color: ${({ theme }) => theme.colors.neutral.dark[700]};
  }

  &.MuiButton-outlined {
    background-color: ${({ theme }) => theme.palette.primary.medium};
    color: ${({ theme }) => theme.palette.primary.main};
    border: solid ${({ theme }) => theme.palette.primary.main} 1px;
    font-weight: 600;

    & .MuiButton-startIcon {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;

export const CustomChip = styled(Chip)`
  & .MuiChip-label {
    padding: 0 7px;
  }

  &.MuiChip-colorPrimary {
    background: ${({ theme }) => theme.palette.primary.medium};
    border-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
  }

  &.MuiChip-colorDefault {
    background: ${({ theme }) => theme.palette.background.default};
    border-color: ${({ theme }) => theme.palette.primary.medium};
    color: ${({ theme }) => theme.colors.neutral.dark[700]};
  }
`;

export const FormControlLabelStyled = styled(FormControlLabel)`
  padding: 0.5rem 0.75rem;
  border: 1px solid ${({ theme }) => theme.colors.neutral.light[200]};
  border-radius: 0.5rem;
  height: 2.5rem;
  margin: unset;
`;

export const PaginationTypography = styled(Typography)`
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;

export const DialogActionsStyled = styled(DialogActions)`
  background: ${({ theme }) => theme.palette.primary.light};
  padding: 0.75rem 1rem;
`;

export const ModalLabel = styled("span")`
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;

export const DialogTitleStyled = styled(DialogTitle)`
  font-size: 1.125rem;
  line-height: 1.25rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;

export const IconButtonStyled = styled(IconButton)`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
`;

export const StyledFormTextField = styled(FormTextField)`
  width: 30rem;
`;
