import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";

import {
  fetchRecipeProductsSearch,
  RecipeProductSearchItemClientContextDto,
  RecipeProductSearchItemDto,
} from "@client";
import { parseNumber, parseString } from "@utils";
import { QueryOptionsTyped } from "./types";
import { ApiResponse } from "@typeDefinitions";

export const fetchRecipeProductsSearchQuery = "fetchRecipeProductsSearchQuery";

export const useFetchRecipeProductsSearchQuery = (
  defaultInputs?: RecipeProductsInput,
  options?: QueryOptionsTyped<
    ApiResponse<
      RecipeProductSearchItemDto[] | RecipeProductSearchItemClientContextDto[]
    >
  >,
) => {
  const [committedInputs, setCommittedInputs] = useState<
    RecipeProductsInput | undefined
  >(defaultInputs);
  const params = useMemo(() => {
    return parseRecipeProductsFilters(committedInputs);
  }, [committedInputs]);

  const { data, ...rest } = useQuery({
    queryKey: [fetchRecipeProductsSearchQuery, params.toString()],
    queryFn: () => fetchRecipeProductsSearch(params),
    ...options,
  });

  return {
    data: data?.data,
    meta: data?.meta,
    submit: setCommittedInputs,
    ...rest,
  };
};

export const parseRecipeProductsFilters = (filters?: RecipeProductsInput) => {
  const search = new URLSearchParams();

  parseString(filters?.query, "query", search);
  parseNumber(filters?.page, "page", search);
  parseNumber(filters?.client, "client", search);

  return search;
};

interface RecipeProductsInput {
  query?: string;
  page?: number;
  client?: number;
}
