import { ModalWrapper } from "@components/ModalWrapperNew";
import { OuterLabel } from "@components/OuterLabel";
import { useAppParams, useAppTranslation } from "@hooks";
import { useCloneClientProgramToBaseMutation } from "@hooks/queries";
import { TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";

interface SaveToBaseModalProps {
  open: boolean;
  onClose: () => void;
  defaultName: string;
  programId: number;
}

export const SaveToBaseModal = ({
  open,
  onClose,
  defaultName,
  programId,
}: SaveToBaseModalProps) => {
  const [value, setValue] = useState(defaultName);
  const { patientId } = useAppParams();
  const patientIdParsed = patientId ? Number(patientId) : 0;
  const { t } = useAppTranslation();
  const { mutate: cloneProgramToBase, isLoading } =
    useCloneClientProgramToBaseMutation(patientIdParsed, programId, {
      onSuccess: () => {
        toast.success(t("program.summary.save_success"));
        onClose();
      },
    });

  const handleSubmit = () => cloneProgramToBase({ name: value });

  const handleClose = () => {
    setValue(defaultName);
    onClose();
  };

  return (
    <ModalWrapper
      title={t("program.summary.program_info_card.save_program_to_base")}
      onClose={handleClose}
      open={open}
      onSubmit={handleSubmit}
      loading={isLoading}
      disabled={!value}
    >
      <OuterLabel label={t("program.summary.program_info_card.program_name")}>
        <TextField
          fullWidth
          size="small"
          value={value}
          onChange={e => setValue(e.target.value)}
        />
      </OuterLabel>
    </ModalWrapper>
  );
};
