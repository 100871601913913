import { Card, Popper, TextField, styled } from "@mui/material";

export const ListElement = styled("li")`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 16px;
  margin: 4px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }
  &.Mui-selected {
    background-color: ${({ theme }) => theme.palette.primary.medium};
  }
`;

interface PopperStyledProps {
  width?: number;
  height?: number;
}
export const PopperStyled = styled(Popper, {
  shouldForwardProp: prop => prop !== "width",
})<PopperStyledProps>`
  & .MuiPaper-root {
    width: ${({ width }) => width}px;
    max-height: ${({ height }) => height}px;
  }
  z-index: 100000;
`;

export const CardStyled = styled(Card)`
  overflow-y: auto;
`;

export const TextFieldStyled = styled(TextField)`
  & .MuiInputBase-root.MuiOutlinedInput-root {
    padding-right: 0;
  }
`;
