import { useFetchDietRecipeUsedQuery } from "./queries/diets";
import { useAppParams } from "./useAppParams";

export const useDietUsedRecipes = () => {
  const { dietId } = useAppParams();
  const { data } = useFetchDietRecipeUsedQuery(dietId, { enabled: !!dietId });

  const isUsedRecipe = (name: string) =>
    !!data?.some(recipe => recipe.translations.some(t => t.name === name));

  const isDoubledRecipe = (name: string) => {
    const occurrences =
      data?.flatMap(recipe => recipe.translations).filter(t => t.name === name)
        .length || 0;

    return occurrences > 1;
  };
  return { isUsedRecipe, isDoubledRecipe };
};
