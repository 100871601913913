import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  SurveySubgroupQuestionRequest,
  updateSurveySubgroupQuestion,
} from "@client/surveys";

import { MutationOptions } from "../types";
import { fetchSurveyFullQueryKey } from "./useFetchSurveyFullQuery";

export const useUpdateSurveySubgroupQuestionMutation = (
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    UpdateSurveySubgroupQuestionMutationPayload
  >(({ id, payload }) => updateSurveySubgroupQuestion(id, payload), {
    ...options,
    onSuccess: (data, variables) => {
      options?.onSuccess && options.onSuccess(data, variables);

      queryClient.invalidateQueries([fetchSurveyFullQueryKey]);
    },
  });
};

interface UpdateSurveySubgroupQuestionMutationPayload {
  id: string;
  payload: SurveySubgroupQuestionRequest;
}
