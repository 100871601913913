import { useQuery } from "@tanstack/react-query";
import { irrigationProducts } from "@client/dictionaries/irrigationProducts";

export const IrrigationProductsQueryKey = "IrrigationProductsQueryKey";

export function useIrrigationProductsQuery() {
  return useQuery({
    queryKey: [IrrigationProductsQueryKey],
    queryFn: () => irrigationProducts(),
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: 60 * 60 * 1000,
  });
}
