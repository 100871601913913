import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export async function requestHideDiet(id: number) {
  await fetchData(`/dietitian/diets/${id}/hide`, APIMethods.PUT);
}

export async function requestDeleteDiet(id: number) {
  await fetchData(`/dietitian/diets/${id}`, APIMethods.DELETE);
}

export async function requestShowDiet(id: number) {
  await fetchData(`/dietitian/diets/${id}/show`, APIMethods.PUT);
}
