import React, {
  forwardRef,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";

import { isArray } from "lodash";

import { InputComponentProps } from "../InputComponentProps";
import { Spinner } from "@components/Spinner";
import { Tag } from "@components/Tag";
import { SelectOption } from "@components/SelectOption";
import { Select } from "@components/Select";

/**
 * universal multi select component
 */

export interface MultiSelectProps extends InputComponentProps {
  /** list of options to render */
  options: optionTypes[];
  /** callback returning list of selected options' identifiers (defined by "idKey" props) */
  onChange?: (value: any[]) => void;
  /** key that defines unique identifier of each option; used when options prop is an array of objects; defaults to "id" */
  idKey?: string;
  // /** key that defines value of each option; used when options prop is an array of objects; defaults to "idKey" prop */
  // valueKey?: string;
  /** key that defines label of each option; defaults to "idKey" prop */
  labelKey?: string;
  /** key that defines label of each selected option; defaults to "labelKey" prop */
  tagLabelKey?: string;
  /** list of selected options' identifiers (defined by "idKey" props) */
  value?: any[];
  /** maximum number of options that could be selected at a time */
  maxSelected?: number;
  /** values that can be excluded from options */
  excludedValues?: number[];
  /** alternative icon at the end of an input */
  endIcon?: React.ReactNode;
  /** Allows free input; If passed alongside valid "onCustomCreate" prop, "Create" button will appear on the list */
  allowCustom?: boolean;
  /** Callback fired when user clicks "Create" button on custom option; used only with "allowCustom" prop */
  onCustomCreate?: (label: string) => Promise<string | number | undefined>;
  /** isLoading determines whether to show a spinner in list or not */
  isLoading?: boolean;
  onRemove?: (value: any[]) => void;
  noPencilIcon?: boolean;
  children?: ReactNode;
}

export const MultiSelect = forwardRef<HTMLInputElement, MultiSelectProps>(
  (props, ref) => {
    const {
      options: defaultOptions,
      onChange,
      onFocus,
      value,
      children,
      idKey = "id" as string,
      labelKey,
      tagLabelKey,
      maxSelected,
      placeholder,
      error,
      excludedValues,
      allowCustom,
      onRemove,
      isLoading = false,
      onCustomCreate,
      disabled,
      ...rest
    } = props;

    const [values, setValues] = useState<typeof defaultOptions>(value || []);
    const [options, setOptions] =
      useState<typeof defaultOptions>(defaultOptions);

    useEffect(() => setOptions(defaultOptions), [defaultOptions]);

    useEffect(() => {
      if (isArray(value) && options.length) {
        const parsed: optionTypes[] = options.filter(o =>
          value.includes(o[idKey]),
        );
        setValues(parsed);
      }
    }, [value]);

    const handleChange = (
      value?: string | number,
      updatedOptions?: typeof defaultOptions,
    ) => {
      const match = (updatedOptions || options).find(o => o[idKey] == value);
      if (match && (!maxSelected || values.length < maxSelected)) {
        const vals = [...values, match];
        setValues(vals);
        onChange && onChange(vals.map(v => v[idKey]));
      }
    };

    const handleNewOptionCreation = async (label: string) => {
      if (!onCustomCreate) return undefined;

      const newIdentifier = await onCustomCreate(label);
      if (!newIdentifier) return undefined;

      const updatedOptions = [
        ...options,
        {
          [labelKey || idKey]: label,
          [idKey]: newIdentifier,
        },
      ];
      setOptions(updatedOptions);
      handleChange(newIdentifier, updatedOptions);

      return newIdentifier;
    };

    const handleRemove = (value: string | number) => () => {
      const vals = values.filter(v => v[idKey] !== value);
      setValues(vals);
      if (onRemove) {
        onRemove([value]);
      } else if (onChange) {
        onChange(vals.map(v => v[idKey]));
      }
    };

    const selectedTags = useMemo(
      () => (
        <div className="flex max-w-3/4 flex-wrap flex-shrink-0">
          {isLoading && <Spinner className="w-full" />}
          {!isLoading &&
            values.map((o: optionTypes) => {
              const label =
                o[tagLabelKey ? tagLabelKey : idKey] || o[labelKey || idKey];
              const value = o[idKey];

              return (
                <Tag
                  key={value}
                  title={label}
                  onRemove={handleRemove(value)}
                  className="my-0.5 ml-1 font-roman"
                  disabled={disabled}
                />
              );
            })}
        </div>
      ),
      [values, isLoading],
    );

    const filteredOptions = useMemo(
      () =>
        options
          .filter(o => !values.find(v => v[idKey] === o[idKey]))
          .filter(o =>
            excludedValues ? !excludedValues.includes(o[idKey]) : o,
          ),
      [values, excludedValues, options],
    );

    const restrictedCustomOptions = useMemo(
      () => values.map(v => v[labelKey || idKey]),
      [values],
    );

    return (
      <>
        <Select
          noInputValue
          filterOptions
          ref={ref}
          prefix={selectedTags}
          onChange={handleChange}
          closeOnSelect={false}
          placeholder={value && value?.length > 0 ? "" : placeholder}
          allowCustom={allowCustom}
          onCustomCreate={allowCustom ? handleNewOptionCreation : undefined}
          restrictedCustomOptions={restrictedCustomOptions}
          isLoading={isLoading}
          disabled={disabled}
          {...rest}
        >
          {filteredOptions.map((o: optionTypes) => {
            const label = o[labelKey || idKey];
            const value = o[idKey];

            return (
              <SelectOption {...o} key={value} value={value}>
                {label}
              </SelectOption>
            );
          })}
        </Select>
        {error && (
          <p className="text-xs text-light text-red pt-1 pl-2">{error}</p>
        )}
      </>
    );
  },
);

interface optionTypes {
  [key: string]: any;
}
