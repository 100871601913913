import { useRef, useState } from "react";
import { Link } from "react-router-dom";

import { CircularProgress, Menu, MenuItem, Tooltip } from "@mui/material";

import { ThreeGreyPoints } from "@assets/icons";
import { ConfirmationWrapper } from "@components/ConfirmationWrapper";
import { useAppTranslation } from "@hooks";

import { IconButtonStyled } from "./DetailsActionButtons.styled";

interface DetailsActionButtonsProps {
  canEdit: boolean;
  canDelete: boolean;
  canToggleVisibility: boolean;
  path: string;
  onDelete?: (id: string) => void;
  id: string;
  editTooltip?: string;
  deleteTooltip?: string;
  isLoading?: boolean;
}

export const DetailsActionButtons = ({
  canEdit,
  canDelete,
  canToggleVisibility,
  path,
  onDelete,
  id,
  editTooltip,
  deleteTooltip,
  isLoading = false,
}: DetailsActionButtonsProps) => {
  const [open, setOpen] = useState(false);

  const { t } = useAppTranslation();
  const anchorEl = useRef<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    anchorEl.current = event.currentTarget;
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (isLoading)
    return (
      <IconButtonStyled disabled>
        <CircularProgress size="1rem" />
      </IconButtonStyled>
    );

  return (
    <>
      <IconButtonStyled onClick={handleClick}>
        <ThreeGreyPoints size="h-4 w-4" />
      </IconButtonStyled>
      <Menu anchorEl={anchorEl.current} open={open} onClose={handleClose}>
        {canEdit ? (
          <MenuItem component={Link} to={`${path}/${id}/edit`}>
            {t("common.edit")}
          </MenuItem>
        ) : (
          <Tooltip title={editTooltip ?? t("common.cannot_edit")}>
            <div>
              <MenuItem disabled>{t("common.edit")}</MenuItem>
            </div>
          </Tooltip>
        )}
        <MenuItem component={Link} to={`${path}/${id}/copy`}>
          {t("common.copy")}
        </MenuItem>
        {canDelete && onDelete ? (
          <ConfirmationWrapper
            onConfirm={() => onDelete(id)}
            confirmationText={t("program_list.confirmation")}
            submitText={t("common.yes")}
          >
            <MenuItem disabled={!canDelete}>{t("common.delete")}</MenuItem>
          </ConfirmationWrapper>
        ) : (
          <Tooltip title={deleteTooltip ?? t("common.cannot_delete")}>
            <div>
              <MenuItem disabled>{t("common.delete")}</MenuItem>
            </div>
          </Tooltip>
        )}
      </Menu>
    </>
  );
};
