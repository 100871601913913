import styled from "@emotion/styled";
import { AccordionSummary, AccordionDetails, Accordion } from "@mui/material";

export const StyledAccordion = styled(Accordion)`
  background-color: unset;
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  min-height: 3.875rem;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.whiteBackground};
  border-radius: 0.375rem;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.0588235);

  & .MuiAccordionSummary-content {
    margin: 0;
  }
`;

export const StyledTitle = styled.div`
  display: flex;
  gap: 0.75rem;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: initial;
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  display: grid;
  gap: 1.5rem;
  padding: 1rem 0;
  font-family: ${({ theme }) => theme.fontFamily.regular};
`;
