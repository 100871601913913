import { IdDto } from "@client";
import { PostSurveyGroupRequest, postSurveyGroup } from "@client/surveys";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ApiResponse } from "@typeDefinitions";
import { MutationOptions } from "../types";
import { fetchSurveyFullQueryKey } from "./useFetchSurveyFullQuery";

export const usePostSurveyGroupMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();
  return useMutation<
    ApiResponse<IdDto>,
    unknown,
    PostSurveyGroupMutationPayload
  >(({ id, payload }) => postSurveyGroup(id, payload), {
    ...options,
    onSuccess: (data, variables) => {
      options?.onSuccess && options.onSuccess(data, variables);
      queryClient.invalidateQueries([fetchSurveyFullQueryKey]);
    },
  });
};

interface PostSurveyGroupMutationPayload {
  id: string;
  payload: PostSurveyGroupRequest;
}
