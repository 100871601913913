import ShowMore from "@views/dietician/PatientMonitoring2/components/ShowMore";
import { useState } from "react";
import { MeasurementList } from "@views/dietician/ClientHealth/components/MeasurementList";
import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";

const MeasurementDrawer = () => {
  const { patientId } = useMonitoring();
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <>
      <ShowMore label="Zobacz historię" onClick={() => setOpenDrawer(true)} />
      <MeasurementList
        clientId={patientId}
        onClose={() => setOpenDrawer(false)}
        opened={openDrawer}
        active={true}
      />
    </>
  );
};

export default MeasurementDrawer;
