import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const postSingedSurveyReport = async (url: string) => {
  await fetchData(
    url,
    APIMethods.POST,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    "",
  );
};
