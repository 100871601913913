import PeopleIcon from "@assets/icons_new/people.svg?react";
import { styled, useTheme } from "@mui/material";

interface ActiveClientsProps {
  value: number | string;
}

export const ActiveClients = ({ value }: ActiveClientsProps) => {
  const { colors } = useTheme();
  return (
    <div className="flex gap-[10px] items-center">
      <PeopleIcon color={colors.neutral.dark[700]} />

      <Text>
        {typeof value === "string" ? value : `${value} aktywnych klientów`}
      </Text>
    </div>
  );
};

const Text = styled("span")`
  font-family: Figtree;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #483a68;
`;
