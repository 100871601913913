import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const DoubleArrowMonitoring = (props: SvgIconProps) => (
  <SvgIcon width="14" height="12" viewBox="0 0 14 12" {...props}>
    <path
      d="M2.28125 11.5312H2.25C1.96875 11.8438 1.5 11.8438 1.21875 11.5312C0.90625 11.25 0.90625 10.7812 1.21875 10.4688L5.6875 6L1.21875 1.53125C0.90625 1.25 0.90625 0.78125 1.21875 0.46875C1.5 0.1875 1.96875 0.1875 2.28125 0.46875L7.28125 5.5C7.5625 5.78125 7.5625 6.25 7.28125 6.53125L2.28125 11.5312ZM8.28125 11.5312H8.25C7.96875 11.8438 7.5 11.8438 7.21875 11.5312C6.90625 11.25 6.90625 10.7812 7.21875 10.4688L11.6875 6L7.21875 1.53125C6.90625 1.25 6.90625 0.78125 7.21875 0.46875C7.5 0.1875 7.96875 0.1875 8.28125 0.46875L13.2812 5.5C13.5625 5.78125 13.5625 6.25 13.2812 6.53125L8.28125 11.5312Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
