import { Autocomplete, styled, TextField } from "@mui/material";

export const ProductAutocomplete = styled(Autocomplete)`
  width: 179px;
` as typeof Autocomplete;

export const ProductTextField = styled(TextField)`
  & .MuiFormHelperText-root {
    margin: 0;
  }
`;
