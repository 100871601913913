import { useQuery } from "@tanstack/react-query";

import { QueryOptionsTyped } from "../types";
import { buildDefaultQueryOptions } from "../common";
import { ApiResponse } from "@typeDefinitions";
import {
  FetchClinicFacilityResponse,
  fetchClinicFacility,
} from "@client/settings";

export const fetchClinicFacilityQueryKey = "fetchClinicFacilityQueryKey";

export const useFetchClinicFacilityQuery = (
  id: number,
  options?: QueryOptionsTyped<ApiResponse<FetchClinicFacilityResponse>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchClinicFacilityQueryKey, id],
    () => fetchClinicFacility(id),
    buildDefaultQueryOptions(options),
  );

  return {
    facility: data?.data,
    ...rest,
  };
};
