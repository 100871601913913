import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  CreatorDietMealProductRequest,
  updateCreatorDietMealProduct,
} from "@client/diets/creator";
import { MutationOptions } from "@hooks/queries/types";

import { useCreatorVersion } from "./useCreatorVersion";
import { fetchCreatorDietMealProductQueryKey } from "./useFetchCreatorDietMealProductQuery";
import { fetchCreatorDietMealsItemsQuery } from "./useFetchCreatorDietMealsItemsQuery";
import { fetchCreatorDietMealsQueryKey } from "./useFetchCreatorDietMealsQuery";
import { fetchDietsItemsProductsRemovedQueryKey } from "../useFetchDietsItemsProductsRemovedQuery";
import { fetchDietsItemsProductsReplacementQueryKey } from "../useFetchDietsItemsProductsReplacementQuery";
import { fetchDietProductItemsQueryKey } from "../useFetchDietProductItemsQuery";
import { fetchDietProductsListQueryKey } from "../useFetchDietProductsListQuery";

export const useUpdateCreatorDietMealProductMutation = (
  options?: MutationOptions,
) => {
  const { version, updateVersion } = useCreatorVersion();
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    useUpdateCreatorDietMealProductMutationPayload
  >(
    ({ dietId, mealId, productId, payload }) =>
      updateCreatorDietMealProduct(dietId, mealId, productId, payload, version),
    {
      ...options,
      onMutate: updateVersion,
      onSuccess: (d, v) => {
        options?.onSuccess && options.onSuccess(d, v);
        queryClient.invalidateQueries([
          fetchCreatorDietMealsItemsQuery,
          v.dietId,
          v.mealId,
        ]);
        queryClient.invalidateQueries([
          fetchCreatorDietMealProductQueryKey,
          v.dietId,
          v.mealId,
          v.productId,
        ]);
        queryClient.invalidateQueries([
          fetchCreatorDietMealsQueryKey,
          v.dietId,
        ]);
        queryClient.invalidateQueries([
          fetchDietsItemsProductsRemovedQueryKey,
          v.dietId,
        ]);
        queryClient.invalidateQueries([
          fetchDietsItemsProductsReplacementQueryKey,
          v.dietId,
        ]);
        queryClient.invalidateQueries([
          fetchDietProductItemsQueryKey,
          v.dietId,
        ]);
        queryClient.invalidateQueries([
          fetchDietProductsListQueryKey,
          v.dietId,
        ]);
      },
    },
  );
};

interface useUpdateCreatorDietMealProductMutationPayload {
  dietId: string;
  mealId: string;
  productId: string;
  payload: CreatorDietMealProductRequest;
}
