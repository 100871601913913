import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useAppTranslation } from "@hooks";
import { MutationOptions } from "./types";
import { searchSectionsQueryKey } from "./useSearchSectionsQuery";
import { deleteSection } from "@client";
import { toast } from "react-toastify";

export const useDeleteSectionMutation = (options?: MutationOptions) => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();

  return useMutation((id: number) => deleteSection(id), {
    ...options,
    onSuccess: () => {
      toast.success(t("sections.success_removed"));
      queryClient.invalidateQueries([searchSectionsQueryKey]);
      options?.onSuccess && options.onSuccess();
    },
  });
};
