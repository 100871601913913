import React, { forwardRef, ReactNode, SyntheticEvent } from "react";

import classNames from "classnames";

import { InputComponentProps } from "../InputComponentProps";

export interface InputProps extends InputComponentProps {
  value?: string | number;
  defaultValue?: string | number;
  onFocus?: (event: SyntheticEvent<HTMLInputElement>) => void;
  onBlur?: (event: SyntheticEvent<HTMLInputElement>) => void;
  onChange?: (event: React.SyntheticEvent<HTMLInputElement> | any) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onClick?: (event: SyntheticEvent<HTMLDivElement>) => void;
  prefix?: string | ReactNode;
  suffix?: string | ReactNode;
  textCenter?: boolean;
  fontBold?: boolean;
  focused?: boolean;
  type?: React.InputHTMLAttributes<unknown>["type"];
  min?: string | number;
  max?: string | number;
  step?: number;
  maxLength?: number;
  style?: React.CSSProperties | Record<string, string>;
  isSelect?: boolean;
  padding?: number;
  noGreyDisabled?: boolean;
}

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    bgTransparent,
    className,
    disabled = false,
    error,
    focused = false,
    fontBold,
    fullWidth = false,
    isSelect = false,
    onBlur,
    onClick,
    padding = 3,
    prefix,
    readOnly,
    suffix,
    textCenter,
    noGreyDisabled,
    variant = "standard",
    ...rest
  } = props;

  return (
    <>
      <div
        className={classNames(
          {
            "group text-sm flex flex-shrink-0 items-center": true,
            "border-solid": variant !== "clean",
            "focus-within:border-primary": !readOnly && variant !== "clean",
            "hover:border-secondary":
              !readOnly && variant !== "clean" && !disabled,
            "w-full": fullWidth,
            "border-gray-500": !focused && variant !== "clean",
            "border-primary": focused && variant !== "clean",
            "border-b": variant === "standard",
            "border focus-within:shadow": !disabled && variant === "outlined",
            "rounded-md": variant === "outlined",
          },
          className,
        )}
        onClick={!disabled && !readOnly ? onClick : undefined}
      >
        {prefix}
        <input
          min={0}
          ref={ref}
          disabled={disabled || readOnly}
          className={classNames(
            {
              "pt-0.5 outline-none w-full min-h-10": true,
              "rounded-md pt-0.5": !disabled && variant === "outlined",
              "ml-1.5 pl-1.5": !!prefix,
              "mr-1.5 pr-1.5": !!suffix,
              "text-gray-700 cursor-default": disabled,
              "bg-gray-200": disabled && !noGreyDisabled,
              "font-sans": fontBold,
              "font-roman": !fontBold,
              "text-center": textCenter,
              "bg-transparent": bgTransparent || readOnly,
              "border-b border-solid focus-within:border-primary hover:border-secondary":
                variant === "clean",
              "border-primary": focused && variant === "clean",
              "placeholder-gray": !isSelect,
              "placeholder-black gb-gray-200": isSelect,
            },
            [`${padding}` ? `px-${padding}` : "px-3"],
          )}
          {...rest}
          onBlur={onBlur}
        />
        {suffix}
      </div>
      {error && (
        <p className="text-xs text-light text-red pt-1 pl-2">{error}</p>
      )}
    </>
  );
});
