import { useMemo, type FC, useEffect, useRef, useLayoutEffect } from "react";
import { startOfWeek, addDays, format, isToday, isSameDay } from "date-fns";
import { useAppTranslation } from "@hooks";
import { pl, enUS } from "date-fns/locale";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";
import { getSubscriptionInfo } from "@utils/subscription";
import { useSearchScheduleEvents } from "@hooks/queries";
import { EventWithType } from "@typeDefinitions";
import { mapCalendarScheduleEvents } from "@utils";
import {
  EventItem,
  EventTime,
  EventTitle,
} from "../../MiniCalendar/MiniCalendar.styled";
import { Plus } from "@assets/icons";
import {
  AddEventButton,
  DayColumn,
  GridContainer,
  GridHeaderContainer,
  GridHeaderItem,
  GridHeaderItemText,
  GridItem,
  GridItemText,
  MainContainer,
  ScrollContainer,
} from "./BigCalendarWeekView.styled";
import { ScheduleVisitOverviewModal } from "../../../ScheduleVisit/_components/ScheduleVisitOverviewModal";
import { formatDayName } from "../../MiniCalendar/_components/MiniCalendarDayView";
import { useCalendarActions } from "../../_hooks/useCalendarActions";
import { CalendarTimeUnit } from "../../MiniCalendar/_components/CalendarTabs";
import { useCalendarColors } from "@views/dietician/Calendar";
import { getEventIcon } from "../../_utils/getEventIcon";
import { max } from "lodash";
import { ScheduleGoogleEventOverviewModal } from "../../../ScheduleVisit/_components/ScheduleGoogleEventOverviewModal";

interface BigCalendarWeekViewProps {
  currentDate: Date;
  onVisitModalOpen: () => void;
  setCurrentDate: (date: Date) => void;
  setCurrentTimeUnit: (timeUnit: CalendarTimeUnit) => void;
  handleSetStartDate: (startDate: Date) => void;
}

interface ProcessedEventWithType extends EventWithType {
  column: number;
  totalColumns: number;
}

const getWidthPerEventsCount = (maxEventsPerDay: number) => {
  switch (maxEventsPerDay) {
    case 5:
      return 300;
    case 6:
      return 400;
    case 7:
      return 450;
    case 8:
      return 500;
    default:
      return 550;
  }
};
const HOURS = Array.from({ length: 24 }, (_, i) => i);
const DEFAULT_START_HOUR = 8;

export const BigCalendarWeekView: FC<BigCalendarWeekViewProps> = ({
  currentDate,
  onVisitModalOpen,
  setCurrentDate,
  setCurrentTimeUnit,
  handleSetStartDate,
}) => {
  const { t, isPolishChosen } = useAppTranslation();
  const locale = isPolishChosen ? pl : enUS;

  const { getNewDietitianColor, getEventStyles } = useCalendarColors();
  const currentTimeLineRef = useRef<HTMLDivElement>(null);
  const {
    handleCloseModal,
    handleEventClick,
    selectedEventId,
    anchorEl,
    selectedGoogleId,
  } = useCalendarActions();

  const weekStart = startOfWeek(currentDate, { weekStartsOn: 1 });

  const { account } = useFetchDietitianAccountQuery();
  const { hasSubscription } = getSubscriptionInfo();

  const enabled = !!account?.clinic?.id && hasSubscription !== null;

  const { scheduleEvents } = useSearchScheduleEvents({
    enabled,
  });

  const calendarEvents = useMemo(
    () => mapCalendarScheduleEvents(scheduleEvents ?? []),
    [scheduleEvents],
  );

  const events = useMemo(() => [...calendarEvents], [calendarEvents]);

  const daysOfWeek = useMemo(() => {
    return Array.from({ length: 7 }).map((_, index) => {
      const day = addDays(weekStart, index);
      const active = isToday(day);
      return {
        dayLabel: `${formatDayName(day, locale)} ${format(day, "dd")}`,
        active,
        day,
      };
    });
  }, [weekStart, locale]);

  const groupEventsByDay = useMemo(() => {
    return daysOfWeek.map(({ day }) => {
      const dayEvents = events.filter(event =>
        isSameDay(new Date(event.start), day),
      );
      const allDayEvents = dayEvents.filter(event => event.allDay);
      const timedEvents = dayEvents.filter(event => !event.allDay);
      return { day, allDayEvents, timedEvents };
    });
  }, [daysOfWeek, events]);

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollContainerRef.current) {
      const scrollPosition = DEFAULT_START_HOUR * 90;
      scrollContainerRef.current.scrollTop = scrollPosition;
    }
  }, []);

  const handleNavigateToCurrentDate = (day: Date) => {
    setCurrentDate(day);
    setCurrentTimeUnit(CalendarTimeUnit.TODAY);
  };

  const handleCreateVisit = (startDate: Date) => {
    onVisitModalOpen();
    handleSetStartDate(startDate);
  };

  useLayoutEffect(() => {
    const updateCurrentTimeLine = () => {
      if (currentTimeLineRef.current && isToday(currentDate)) {
        const now = new Date();
        const currentHour = now.getHours();
        const currentMinute = now.getMinutes();

        const minHour = HOURS[0];
        const hourHeight = 90;

        const top = (currentHour - minHour + currentMinute / 60) * hourHeight;

        currentTimeLineRef.current.style.top = `${top}px`;
        currentTimeLineRef.current.style.display = "block";
      } else if (currentTimeLineRef.current) {
        currentTimeLineRef.current.style.display = "none";
      }
    };

    updateCurrentTimeLine();
    const interval = setInterval(updateCurrentTimeLine, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [currentDate]);

  const processEvents = (events: EventWithType[]): ProcessedEventWithType[] => {
    const sortedEvents = [...events].sort((a, b) => {
      const startDiff =
        new Date(a.start).getTime() - new Date(b.start).getTime();
      if (startDiff === 0) {
        return (
          new Date(a.end).getTime() -
          new Date(a.start).getTime() -
          (new Date(b.end).getTime() - new Date(b.start).getTime())
        );
      }
      return startDiff;
    }) as ProcessedEventWithType[];

    let maxColumns = 0;

    const isOverlapping = (event1: EventWithType, event2: EventWithType) => {
      const start1 = new Date(event1.start).getTime();
      const end1 = new Date(event1.end).getTime();
      const start2 = new Date(event2.start).getTime();
      const end2 = new Date(event2.end).getTime();
      return start1 === start2 || (start2 < end1 && start1 < end2);
    };

    sortedEvents.forEach((event, index) => {
      if (event.column !== undefined) return;

      const overlappingGroup = [event];

      for (let i = index + 1; i < sortedEvents.length; i++) {
        const nextEvent = sortedEvents[i];

        const overlapsWithGroup = overlappingGroup.some(groupEvent =>
          isOverlapping(groupEvent, nextEvent),
        );

        if (overlapsWithGroup) {
          overlappingGroup.push(nextEvent);
        }
      }

      overlappingGroup.sort((a, b) => {
        const sameStart =
          new Date(a.start).getTime() === new Date(b.start).getTime();
        if (sameStart) {
          return (
            new Date(a.end).getTime() -
            new Date(a.start).getTime() -
            (new Date(b.end).getTime() - new Date(b.start).getTime())
          );
        }
        return new Date(a.start).getTime() - new Date(b.start).getTime();
      });

      overlappingGroup.forEach((groupEvent, groupIndex) => {
        groupEvent.column = groupIndex;
      });

      maxColumns = Math.max(maxColumns, overlappingGroup.length);
    });

    return sortedEvents.map(event => ({
      ...event,
      totalColumns: maxColumns,
    }));
  };

  const containerHeight = HOURS.length * 90;

  const calculateTop = (eventStart: Date, day: Date) => {
    const dayStart = new Date(day);
    dayStart.setHours(0, 0, 0, 0);
    const minutesFromDayStart =
      (eventStart.getTime() - dayStart.getTime()) / (1000 * 60);
    return (minutesFromDayStart / (24 * 60)) * containerHeight;
  };

  const calculateHeight = (eventStart: Date, eventEnd: Date) => {
    const eventDurationInMinutes =
      (eventEnd.getTime() - eventStart.getTime()) / (1000 * 60);
    return (eventDurationInMinutes / (24 * 60)) * containerHeight;
  };

  const calculateMaxOverlap = (events: EventWithType[]) => {
    const times: { time: number; value: number }[] = [];

    events.forEach(event => {
      times.push({ time: new Date(event.start).getTime(), value: 1 });
      times.push({ time: new Date(event.end).getTime(), value: -1 });
    });

    times.sort((a, b) => {
      if (a.time === b.time) {
        return a.value - b.value;
      }
      return a.time - b.time;
    });

    let currentOverlap = 0;
    let maxOverlap = 0;

    times.forEach(point => {
      currentOverlap += point.value;
      maxOverlap = Math.max(maxOverlap, currentOverlap);
    });

    return maxOverlap;
  };

  const getColumnWidth = useMemo(() => {
    const maxEventsPerDay = Math.max(
      ...groupEventsByDay.map(({ timedEvents }) => {
        const maxOverlap = calculateMaxOverlap(timedEvents);
        return maxOverlap;
      }),
    );

    const smallColumnWidth = 170;

    return {
      width:
        maxEventsPerDay > 3
          ? getWidthPerEventsCount(maxEventsPerDay)
          : smallColumnWidth,
      maxEventsPerDay,
    };
  }, [groupEventsByDay]);

  return (
    <MainContainer>
      <ScrollContainer ref={scrollContainerRef} className="custom-scrollbar">
        <GridContainer>
          <GridHeaderContainer
            style={{
              gridTemplateColumns: `50px repeat(7, minmax( ${getColumnWidth.width}px, 1fr))`,
            }}
          >
            <GridHeaderItem />
            {daysOfWeek.map(({ dayLabel, day, active }, index) => (
              <GridHeaderItem
                key={index}
                onClick={() => handleNavigateToCurrentDate(day)}
              >
                <GridHeaderItemText active={active}>
                  {dayLabel}
                </GridHeaderItemText>
              </GridHeaderItem>
            ))}
          </GridHeaderContainer>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: `50px repeat(7, minmax( ${getColumnWidth.width}px, 1fr))`,
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateRows: `repeat(${HOURS.length}, 90px)`,
              }}
            >
              {HOURS.map((hour, rowIndex) => (
                <GridItem key={`time-${rowIndex}`}>
                  <GridItemText>{`${hour}:00`}</GridItemText>
                </GridItem>
              ))}
            </div>

            {daysOfWeek.map(({ day }, colIndex) => {
              const { allDayEvents, timedEvents } = groupEventsByDay[colIndex];

              const processedEvents = processEvents(timedEvents);

              return (
                <DayColumn key={`day-${colIndex}`} className="relative">
                  {isToday(day) && (
                    <div
                      ref={currentTimeLineRef}
                      className="absolute left-0 right-0 h-[2px] bg-purple-dark z-10"
                    />
                  )}
                  {HOURS.map((hour, rowIndex) => {
                    const timeSlot = new Date(day);
                    timeSlot.setHours(hour, 0, 0, 0);

                    return (
                      <GridItem
                        key={`day-${colIndex}-hour-${rowIndex}`}
                        style={{ position: "relative", height: 90 }}
                      >
                        <div className="group h-full relative">
                          <AddEventButton
                            className="hidden group-hover:flex"
                            onClick={() => handleCreateVisit(timeSlot)}
                          >
                            <Plus width="8px" height="8px" />
                            <span>{t("calendar.add_visit")}</span>
                          </AddEventButton>
                        </div>
                      </GridItem>
                    );
                  })}

                  {allDayEvents.map((event, index) => {
                    const theme = getNewDietitianColor(event.dietitianId);
                    const totalEvents = allDayEvents.length;

                    const eventWidth = `calc(${100 / totalEvents}% - 4px)`;
                    const leftPosition = `${(index * 100) / totalEvents}%`;
                    const { backgroundColor, border } = getEventStyles(
                      theme?.backgroundColor,
                      !!event.googleId,
                    );
                    return (
                      <EventItem
                        key={index}
                        style={{
                          position: "absolute",
                          top: 0,
                          height: "100%",
                          left: leftPosition,
                          width: eventWidth,
                          margin: "0 2px",
                          zIndex: 1,
                          backgroundColor,
                          border,
                        }}
                        onClick={e =>
                          handleEventClick(e, event.id, event.googleId)
                        }
                      >
                        <EventTime>
                          {getEventIcon(event.type, {
                            color: theme?.color,
                            fontSize: 14,
                          })}
                          {t("calendar.allDay")}
                        </EventTime>
                        <EventTitle>{event.title}</EventTitle>
                      </EventItem>
                    );
                  })}

                  {processedEvents.map((event, idx) => {
                    const eventStart = new Date(event.start);
                    const eventEnd = new Date(event.end);

                    const top = calculateTop(eventStart, day);
                    const height = calculateHeight(eventStart, eventEnd);

                    const column = event.column;

                    const width =
                      getColumnWidth.maxEventsPerDay > 3
                        ? `${
                            60 -
                            Math.abs(3 - getColumnWidth.maxEventsPerDay) * 12
                          }%`
                        : "60%";

                    const left = `${column * 12}%`;

                    const isShorter = height < 50;
                    const theme = getNewDietitianColor(event.dietitianId);

                    const additionalStyles = isShorter
                      ? {
                          padding: "0 8px",
                          display: "flex",
                          alignItems: "center",
                        }
                      : {};

                    const { backgroundColor, border } = getEventStyles(
                      theme?.backgroundColor,
                      !!event.googleId,
                    );

                    return (
                      <EventItem
                        key={idx}
                        style={{
                          position: "absolute",
                          top,
                          height,
                          left,
                          width,
                          margin: "0 2px",
                          zIndex: column + 1,
                          backgroundColor,
                          border,
                          ...additionalStyles,
                        }}
                        onClick={e =>
                          handleEventClick(e, event.id, event.googleId)
                        }
                      >
                        <EventTime>
                          {getEventIcon(event.type, {
                            color: theme?.color,
                            fontSize: 14,
                          })}
                          {event.allDay
                            ? t("calendar.allDay")
                            : `${format(eventStart, "HH:mm")} - ${format(
                                eventEnd,
                                "HH:mm",
                              )}`}
                        </EventTime>
                        {!isShorter && <EventTitle>{event.title}</EventTitle>}
                      </EventItem>
                    );
                  })}
                </DayColumn>
              );
            })}
          </div>
        </GridContainer>
      </ScrollContainer>

      {selectedEventId && (
        <ScheduleVisitOverviewModal
          anchorEl={anchorEl}
          onClose={handleCloseModal}
          eventId={selectedEventId}
        />
      )}
      {selectedGoogleId && (
        <ScheduleGoogleEventOverviewModal
          anchorEl={anchorEl}
          onClose={handleCloseModal}
          googleId={selectedGoogleId}
        />
      )}
    </MainContainer>
  );
};
