import React, { forwardRef, ReactNode, useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";

import classNames from "classnames";

/**
 * universal link component
 */
export const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const {
    color = "text",
    to,
    href,
    children,
    className,
    onClick,
    noUnderline = false,
    newPage,
  } = props;

  const classes = classNames(
    {
      "no-underline cursor-pointer": true,
      "hover:underline": !noUnderline,
      "text-primary": color === "primary",
      "text-secondary": color === "secondary",
    },
    className,
  );

  const target = useMemo(() => (newPage ? "_blank" : undefined), [newPage]);

  return to ? (
    <RouterLink
      ref={ref}
      className={classes}
      to={to}
      onClick={onClick}
      target={target}
    >
      {children}
    </RouterLink>
  ) : (
    <a
      ref={ref}
      className={classes}
      href={href}
      onClick={onClick}
      target={target}
    >
      {children}
    </a>
  );
});

type LinkColor = "primary" | "secondary" | "text";

export interface LinkProps {
  /** additional classes */
  className?: string;
  /** do not show underline */
  noUnderline?: boolean;
  /** name attribute */
  name?: string;
  /** link to application view */
  to?: string;
  /** link to resource outside of the application */
  href?: string;
  /** link color */
  color?: LinkColor;
  /** text content or other nested components */
  children?: string | ReactNode;
  /** */
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  newPage?: boolean;
}
