import { styled } from "@mui/material";

export const FiltersNoResultsStyled = styled("div")`
  display: grid;
  place-items: center;
  gap: 0.75rem;
`;

export const ContentWrapper = styled("div")`
  display: grid;
  place-items: center;
  gap: 0.5rem;
`;

export const NoResultsText = styled("span")`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;

export const NoResultsInfo = styled("span")`
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;
