import { Button } from "@mui/material";

import { Confirm } from "@assets/icons";
import { Currencies } from "@utils/scheduleEvent";
import {
  useCreateClinicDietitianMutation,
  useCreateClinicFacility,
  useCreateClinicProductMutation,
  useDeleteClinicDietitianMutation,
  useDeleteClinicFacility,
  useDeleteClinicProductMutation,
  useFetchClinicBillingDataQuery,
  useFetchClinicClientsStatisticsQuery,
  useFetchClinicDietitianQuery,
  useFetchClinicDietitiansQuery,
  useFetchClinicFacilitiesQuery,
  useFetchClinicFacilityQuery,
  useFetchClinicNameQuery,
  useFetchDietitianAccountQuery,
  usePatchClinicNameMutation,
  usePatchClinicProductMutation,
  usePatchDietitianPasswordMutation,
  useUpdateClinicBillingDataMutation,
  useUpdateClinicDietitianMutation,
  useUpdateClinicFacility,
  useUpdateClinicProductMutation,
  useUpdateDietitianAccountMutation,
} from "@hooks/queries/settings";
import { useFetchClinicProducts } from "@hooks/queries";
import {
  CreateClinicDietitianRequest,
  CreateClinicFacilityRequest,
  CreateClinicProductRequest,
  FetchDietitianAccountResponse,
  PatchDietitianPasswordRequest,
  UpdateClinicBillingDataRequest,
  UpdateClinicDietitianRequest,
  UpdateClinicFacilityRequest,
  UpdateClinicProductRequest,
  UpdateDietitianAccount,
} from "@client/settings";
import { LangDto } from "@client";

export const TestSettings = () => {
  //clinic
  const { mutate: patchName } = usePatchClinicNameMutation();
  // const { mutate: updateLogo } = useUpdateClinicLogoMutation();
  const { mutate: createFacility } = useCreateClinicFacility();
  const { mutate: updateFacility } = useUpdateClinicFacility(5);
  const { mutate: deleteFacility } = useDeleteClinicFacility();
  const { refetch: fetchName } = useFetchClinicNameQuery({ enabled: false });
  const { refetch: fetchFacilities } = useFetchClinicFacilitiesQuery({
    enabled: false,
  });
  const { refetch: fetchFacility } = useFetchClinicFacilityQuery(5, {
    enabled: false,
  });

  const handlePatchClinicName = () => patchName("Be Compleat");
  const handleFetchClinicName = () => fetchName();
  const handleFetchClinicFacilities = () => fetchFacilities();
  const handleFetchClinicFacility = () => fetchFacility();
  const handleCreateClinicFacility = () => createFacility(NEW_FACILITY);
  const handleUpdateClinicFacility = () => updateFacility(UPDATED_FACILITY);
  const handleDeleteClinicFacility = () => deleteFacility(5);

  // const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
  //   const { files } = event.target;
  //   if (!files || files.length === 0) return;
  //   const file = files[0];
  //   const form = new FormData();
  //   const blob = new Blob([file], {type: "image/*"})
  //   form.append("file", blob);
  //   await axios.put(`${import.meta.env.VITE_APP_API_URL}/dietitian/clinic/logo`, form);
  // };

  //dietitian

  const { refetch: fetchAccount, account } = useFetchDietitianAccountQuery({
    enabled: false,
  });
  const { mutate: updateAccount } = useUpdateDietitianAccountMutation();
  const { mutate: patchPassword } = usePatchDietitianPasswordMutation();

  const handleFetchDietitianAccount = () => fetchAccount();
  const handleUpdateDietitianAccount = () =>
    updateAccount(mapUpdateAccountRequest(account!));
  const handlePatchDietitianPassword = () => patchPassword(PATCH_PASSWORD);

  //dietitians

  const { refetch: fetchDietitians } = useFetchClinicDietitiansQuery({
    enabled: false,
  });
  const { refetch: fetchDietitian } = useFetchClinicDietitianQuery(414, {
    enabled: false,
  });
  const { mutate: createDietitian } = useCreateClinicDietitianMutation();
  const { mutate: updateDietitian } = useUpdateClinicDietitianMutation(414);
  const { mutate: deleteDietitian } = useDeleteClinicDietitianMutation();

  const handleFetchClinicDietitians = () => fetchDietitians();
  const handleFetchClinicDietitian = () => fetchDietitian();
  const handleCreateClinicDietitian = () => createDietitian(NEW_DIETITIAN);
  const handleUpdateClinicDietitian = () => updateDietitian(UPDATED_DIETITIAN);
  const handleDeleteClinicDietitian = () => deleteDietitian(414);

  //clients

  const { refetch: fetchStats } = useFetchClinicClientsStatisticsQuery();

  const handleFetchClinicClientsStats = () => fetchStats();

  //billing

  const { refetch: fetchBillingData } = useFetchClinicBillingDataQuery({
    enabled: false,
  });
  const { mutate: updateBillingData } = useUpdateClinicBillingDataMutation();

  const handleFetchBillingData = () => fetchBillingData();
  const handleUpdateBillingData = () => updateBillingData(UPDATED_BILLING_DATA);

  //products

  const { refetch: fetchClinicProducts } = useFetchClinicProducts({
    enabled: false,
  });
  const { mutate: createProduct } = useCreateClinicProductMutation();
  const { mutate: updateProduct } = useUpdateClinicProductMutation(22);
  const { mutate: patchProduct } = usePatchClinicProductMutation();
  const { mutate: deleteProduct } = useDeleteClinicProductMutation();

  const handleFetchClinicProducts = () => fetchClinicProducts();
  const handleCreateClinicProduct = () => createProduct(NEW_PRODUCT);
  const handleUpdateClinicProduct = () => updateProduct(UPDATED_PRODUCT);
  const handlePatchClinicProduct = () =>
    patchProduct({ id: 22, isActive: false });
  const handleDeleteClinicProduct = () => deleteProduct(22);

  return (
    <div className={"flex gap-2 p-8"}>
      <div className={"flex flex-col"}>
        <p>Klinika</p>
        <Button onClick={handlePatchClinicName}>
          PATCH name
          <Confirm />
        </Button>
        <Button onClick={handleFetchClinicName}>
          GET name
          <Confirm />
        </Button>
        {/*{(isNameSuccess) && <Avatar*/}
        {/*  isEditable*/}
        {/*  fullName={name?.name ?? ""}*/}
        {/*  onFileUplaod={handleFileUpload}*/}
        {/*  image={`${import.meta.env.VITE_APP_API_URL}/dietitian/clinic/logo`}*/}
        {/*  size="small"*/}
        {/*/>}*/}
        <Button onClick={handleFetchClinicFacilities}>
          GET facilities
          <Confirm />
        </Button>
        <Button onClick={handleFetchClinicFacility}>
          GET facility
          <Confirm />
        </Button>
        <Button onClick={handleCreateClinicFacility}>POST facility</Button>
        <Button onClick={handleUpdateClinicFacility}>
          UPDATE facility
          <Confirm />
        </Button>
        <Button onClick={handleDeleteClinicFacility}>
          DELETE facility
          <Confirm />
        </Button>
      </div>

      <div className={"flex flex-col"}>
        <p>Dietetyk</p>
        <Button onClick={handleFetchDietitianAccount}>
          GET account
          <Confirm />
        </Button>
        <Button onClick={handleUpdateDietitianAccount}>
          PUT account
          <Confirm />
        </Button>
        <Button onClick={handlePatchDietitianPassword}>
          PATCH password
          <Confirm />
        </Button>
      </div>

      <div className={"flex flex-col"}>
        <p>Dietetycy</p>
        <Button onClick={handleFetchClinicDietitians}>
          GET dietitians
          <Confirm />
        </Button>
        <Button onClick={handleFetchClinicDietitian}>
          GET dietitian
          <Confirm />
        </Button>
        <Button onClick={handleCreateClinicDietitian}>
          POST dietitian
          <Confirm />
        </Button>
        <Button onClick={handleUpdateClinicDietitian}>
          PUT dietitian
          <Confirm />
        </Button>
        <Button onClick={handleDeleteClinicDietitian}>DELETE dietitian</Button>
      </div>

      <div className={"flex flex-col"}>
        <p>Klienci</p>
        <Button>
          GET clients
          <Confirm />
        </Button>
        <Button onClick={handleFetchClinicClientsStats}>
          GET clients stats
          <Confirm />
        </Button>
      </div>

      <div className={"flex flex-col"}>
        <p>Finanse</p>
        <Button onClick={handleFetchBillingData}>
          GET billing data
          <Confirm />
        </Button>
        <Button onClick={handleUpdateBillingData}>
          PUT billing data
          <Confirm />
        </Button>
      </div>

      <div className={"flex flex-col"}>
        <p>Produkty</p>
        <Button onClick={handleFetchClinicProducts}>
          GET clinic products
          <Confirm />
        </Button>
        <Button onClick={handleCreateClinicProduct}>
          POST clinic product
          <Confirm />
        </Button>
        <Button onClick={handleUpdateClinicProduct}>
          PUT clinic product
          <Confirm />
        </Button>
        <Button onClick={handlePatchClinicProduct}>
          PATCH clinic product
          <Confirm />
        </Button>
        <Button onClick={handleDeleteClinicProduct}>
          DELETE clinic product
        </Button>
      </div>
    </div>
  );
};

const NEW_FACILITY: CreateClinicFacilityRequest = {
  name: "Nowa klinika",
  email: "123@qwe.rt",
  isMain: false,
  phone: {
    prefix: "+48",
    number: "123123123",
  },
  address: {
    street: null,
    streetNumber: null,
    houseNumber: null,
    postCode: null,
    city: "Kraków",
  },
};

const UPDATED_FACILITY: UpdateClinicFacilityRequest = {
  name: "Nowa klinika",
  email: "123@qwe.rt",
  isMain: false,
  phone: {
    prefix: "+48",
    number: "123123123",
  },
  address: {
    street: null,
    streetNumber: null,
    houseNumber: null,
    postCode: null,
    city: "Kraków",
  },
};

const PATCH_PASSWORD: PatchDietitianPasswordRequest = {
  oldPassword: "123",
  password: "Alloweat",
  passwordConfirmation: "Alloweat",
};

const NEW_DIETITIAN: CreateClinicDietitianRequest = {
  firstName: "Nowy",
  lastName: "Dietetyk",
  email: "email@qwe.rty",
  facility: {
    id: 2,
  },
  phone: {
    prefix: "+48",
    number: "098756321",
  },
  language: LangDto.PL,
  sendAccessEmail: false,
};

const UPDATED_DIETITIAN: UpdateClinicDietitianRequest = {
  firstName: "Nowy",
  lastName: "Dietetyk123",
  facility: {
    id: 2,
  },
  phone: {
    prefix: "+48",
    number: "098756321",
  },
  language: LangDto.EN,
};

const UPDATED_BILLING_DATA: UpdateClinicBillingDataRequest = {
  vatId: "8732999444",
  name: null,
  email: "kontakt@becompleat.pl",
  phone: {
    prefix: "+48",
    number: "694932247",
  },
  address: {
    street: "Hamernia 31C/2",
    streetNumber: null,
    houseNumber: null,
    city: "Kraków",
    postCode: "31-135",
  },
};

const NEW_PRODUCT: CreateClinicProductRequest = {
  prices: [{ amount: 0, currency: Currencies.PLN }],
  translations: [
    { lang: LangDto.PL, name: "polska nazwa" },
    { lang: LangDto.EN, name: "english name" },
  ],
  isActive: false,
};

const UPDATED_PRODUCT: UpdateClinicProductRequest = {
  prices: [{ amount: 100, currency: Currencies.PLN }],
  translations: [
    { lang: LangDto.PL, name: "polska nazwa" },
    { lang: LangDto.EN, name: "english name" },
  ],
  isActive: false,
};

const mapUpdateAccountRequest = (
  data: FetchDietitianAccountResponse,
): UpdateDietitianAccount => {
  return {
    firstName: data.firstName,
    lastName: data.lastName,
    language: data.language,
    phone: {
      prefix: data.phone.prefix ?? null,
      number: data.phone.number ?? null,
    },
  };
};
