import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";

import { omit, round } from "lodash";

import { useAppTranslation, useGlycaemicIndicators } from "@hooks";
import { useFetchRecipeQuery } from "@hooks/queries";
import { RecipeType } from "@typeDefinitions";
import { RecipeIngredientMacroFormInput } from "@typeDefinitions/types";
import { NEW_INGREDIENT_DEFAULT_VALUE } from "@utils/recipes";
import { NUTRITION_NAMES_MAP } from "@utils/constants";
import {
  ENERGY_ID,
  getEnergyPerGramFromMacroelement,
  MACROS_ARRAY,
} from "@utils/macros";
import { scaleNutrients, sumNutrient } from "@utils/nutrients";

export const useRecipeDetails = (recipeId: number) => {
  const { isPolishChosen } = useAppTranslation();
  const { recipe, status, error } = useFetchRecipeQuery(recipeId);
  const ingredients = recipe?.foodRecipe;
  const { control, setValue, watch } = useForm({
    defaultValues: {
      chartNumberOfPortions: 1,
      type: RecipeType.SIMPLE,
      numOfPortions: 1,
    },
  });

  useEffect(() => {
    if (recipe) {
      setValue("chartNumberOfPortions", recipe.servings);
      setValue("numOfPortions", recipe.servings);
      setValue(
        "type",
        recipe.isComplex ? RecipeType.COMPLEX : RecipeType.SIMPLE,
      );
    }
  }, [recipe]);

  const macrosSummed = useMemo(() => {
    if (ingredients) {
      const scaledNutrients = ingredients
        .map(i => ({ grams: i.grams, nutrients: i.food.nutrients }))
        .map(({ grams, nutrients }) => scaleNutrients(nutrients, grams));

      return sumNutrient(scaledNutrients)
        .map(macro => ({
          ...macro,
          sortOrder: NUTRITION_NAMES_MAP[macro.id]?.order ?? 1,
        }))
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map(macro => omit(macro, "sortOrder"))
        .filter(macro => MACROS_ARRAY.includes(macro.id));
    } else {
      return NEW_INGREDIENT_DEFAULT_VALUE.macros;
    }
  }, [ingredients]);

  const formValues = watch();

  const chartData = useMemo(() => {
    const portionFraction =
      formValues?.chartNumberOfPortions / formValues?.numOfPortions ?? 1;
    return macrosSummed.map((macro: RecipeIngredientMacroFormInput) => ({
      ...macro,
      valueInGrams: round(macro.value * portionFraction),
      value:
        macro.id === ENERGY_ID
          ? round(macro.value * portionFraction)
          : round(
              macro.value *
                getEnergyPerGramFromMacroelement(macro.id) *
                portionFraction,
            ),
      name: isPolishChosen
        ? NUTRITION_NAMES_MAP[macro.id]?.title
        : NUTRITION_NAMES_MAP[macro.id]?.titleEn,
      prefix: isPolishChosen
        ? NUTRITION_NAMES_MAP[macro.id]?.shortName
        : NUTRITION_NAMES_MAP[macro.id]?.shortNameEn,
    }));
  }, [
    isPolishChosen,
    formValues.chartNumberOfPortions,
    formValues.numOfPortions,
    macrosSummed,
  ]);

  const ingredientsParsed = useMemo(() => {
    if (!ingredients) return;

    return ingredients.map(ingredient => ({
      ...ingredient,
      food: {
        ...ingredient.food,
        nutrients: scaleNutrients(ingredient.food.nutrients, ingredient.grams),
        descriptionPl: ingredient.food.descriptionPl ?? "",
      },
    }));
  }, [ingredients]);

  const { glycaemicIndicators, glycaemicIndex, glycaemicLoad, wwPerRecipe } =
    useGlycaemicIndicators(
      ingredients?.map(i => ({
        grams: i.grams,
        nutrients: i.food.nutrients,
      })) ?? [],
    );

  return {
    recipe,
    ingredients: ingredientsParsed,
    status,
    error,
    macrosSummed,
    control,
    chartData,
    glycaemicIndicators,
    glycaemicIndex,
    glycaemicLoad,
    wwPerRecipe,
  };
};
