import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

import { IdDto, idSchema } from "./common";

export const fetchFoodMeasures = async (
  foodId: string,
): Promise<ApiResponse<FoodMeasureDto[]>> => {
  const response = await fetchData(
    `/dietitian/food/${foodId}/measures`,
    APIMethods.GET,
  );

  return responseSchema.validate(response);
};

export interface FoodMeasureDto {
  grams: number;
  measure: IdDto;
}

const foodMeasureSchema = yup.object().shape({
  grams: yup.number().required(),
  measure: idSchema.required(),
});

const responseSchema = yup.object().shape({
  data: yup.array().of(foodMeasureSchema).required(),
});
