import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import * as yup from "yup";

export const fetchClientMeasurementsCharts = async (
  id: string,
): Promise<ApiResponse<FetchClientMeasurementChartResponse[]>> => {
  const response = await fetchData(
    `/dietitian/patients/${id}/chart-measurements`,
    APIMethods.GET,
  );
  return await responseSchema.validate(response);
};

const measurementSchema = yup.number().nullable().defined();

const clientMeasurementSchema = yup.object({
  date: yup.string().required(),
  growth: measurementSchema,
  weight: measurementSchema,
  bodyFatLevel: measurementSchema,
  arm: measurementSchema,
  tightBiceps: measurementSchema,
  waist: measurementSchema,
  hip: measurementSchema,
  thigh: measurementSchema,
  calf: measurementSchema,
  bodyMeasurements: yup
    .array()
    .of(
      yup.object({
        body: yup
          .object({
            id: yup.number().required(),
          })
          .required(),
        value: measurementSchema,
      }),
    )
    .defined(),
});

const responseSchema = yup.object({
  data: yup.array().of(clientMeasurementSchema).defined(),
});

export interface FetchClientMeasurementChartResponse {
  date: string;
  growth: number | null;
  weight: number | null;
  bodyFatLevel: number | null;
  arm: number | null;
  tightBiceps: number | null;
  waist: number | null;
  hip: number | null;
  thigh: number | null;
  calf: number | null;
  bodyMeasurements: { body: { id: number }; value: number | null }[];
}
