import * as yup from "yup";
import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";

export async function optimizeProgramSchedule(
  programId: number | string,
): Promise<ApiResponse<OptimizationDto[]>> {
  const data = await fetchData(
    `/dietitian/programs/${programId}/schedule/draw/optimize`,
    APIMethods.PUT,
  );
  return await optimizeProgramScheduleResponseSchema.validate(data);
}

const optimizationSchema = yup.object().shape({
  numberOfDaysToOptimize: yup.number().required(),
  numberOfDaysOptimized: yup.number().required(),
  programDay: yup.object().shape({
    id: yup.number().required(),
  }),
});

const optimizeProgramScheduleResponseSchema = yup.object().shape({
  data: yup.array().of(optimizationSchema).required(),
});

export interface OptimizationDto {
  numberOfDaysToOptimize: number;
  numberOfDaysOptimized: number;
  programDay: { id: number };
}

export enum ImportanceType {
  NUTRIENTS = 1,
  MEALS = 2,
}

export interface OptimizedProgramScheduleRequestBody {
  searchOptions: {
    programDay: {
      id: number;
    };
    nutrients: {
      id: number | string;
      value: number;
      distribution: number;
      tolerance: number;
    }[];
    balances: {
      type: ImportanceType;
      value: number;
    }[];
  }[];
}
