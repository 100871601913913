import {
  DEFAULT_PROGRAMS_MACROS_RANGE,
  ProgramsFiltersFormInput,
} from "./useProgramsFiltersForm";

export const defaultFilters: ProgramsFiltersFormInput = {
  tags: [],
  nutrients: DEFAULT_PROGRAMS_MACROS_RANGE,
  query: "",
  authorId: undefined,
  days: undefined,
  typeDays: [],
};
