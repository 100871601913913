import { CustomTagChip } from "@components/CustomTagChip";
import type { CustomTag } from "@client/tasks/fetchTasks";
import type { Task } from "@client/tasks/fetchTasks";
import { mapCreateTaskFormToPayload } from "@utils/tasks/mapCreateTaskFormToPayload";

export const renderTagChips = (
  tags: CustomTag[],
  handleDelete: (id: number) => void,
) => {
  return tags.map(({ id, name, color }) => (
    <CustomTagChip
      key={id}
      label={name}
      id={id}
      color={color}
      onDelete={() => handleDelete(id)}
    />
  ));
};

export const mapTaskData = (data: Task, leftTags: string[]) => {
  return mapCreateTaskFormToPayload({
    name: data.name,
    description: data.description ?? undefined,
    relatedLink: data.relatedLink ?? undefined,
    dueDate: data.dueDate,
    priority: data.priority,
    dietitianId: String(data.dietitian?.id),
    clientId: String(data.client?.id),
    customTagsIds: leftTags,
  });
};

export const mapTagsToStringIds = (tags: CustomTag[]) =>
  tags.map(({ id }) => String(id));
