import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  styled,
} from "@mui/material";

export const AccordionStyled = styled(Accordion)`
  background: radial-gradient(
    100% 1757.1% at 0% 0%,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(255, 255, 255, 0) 100%
  );

  box-shadow: ${({ theme }) => theme.boxShadows.card};
  &.MuiAccordion-root {
    border-radius: 12px;
    border: 2px solid ${({ theme }) => theme.colors.neutral.light[100]};

    &.MuiAccordion-root.Mui-expanded {
      margin: 0;
    }

    ::before {
      display: none;
    }
  }
`;

export const AccordionSummaryStyled = styled(AccordionSummary)`
  &.MuiButtonBase-root.MuiAccordionSummary-root {
    flex-direction: row-reverse;
    gap: 8px;
  }
  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
  }
  & .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
  &.MuiAccordionSummary-root {
    min-height: 48px;
  }
  & .MuiAccordionSummary-content {
    margin: 0;
    justify-content: space-between;
    align-items: center;
  }
`;

export const AccordionDetailsStyled = styled(AccordionDetails)`
  border-top: 2px solid ${({ theme }) => theme.colors.neutral.light[100]};
`;

export const MealNameText = styled("span")`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;
export const HourText = styled("span")`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;

export const ButtonStyled = styled(Button)`
  display: flex;
  gap: 8px;
`;

export const ListTitle = styled("span")`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
  text-transform: uppercase;
`;

export const SummaryText = styled("span")`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const SummaryBold = styled(SummaryText)`
  font-weight: 600;
`;

interface SummaryStyledProps {
  expanded: boolean;
}

export const SummaryStyled = styled("div")<SummaryStyledProps>`
  background: ${({ theme, expanded }) =>
    expanded ? "transparent" : theme.colors.survey.light};
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallMax}px) {
    flex-direction: column;
  }
`;

interface StickySummaryProps {
  expanded: boolean;
}
export const StickySummary = styled("div")<StickySummaryProps>`
  overflow: hidden;
  position: sticky;
  top: 0;
  z-index: 1;
  background: ${({ theme }) => theme.colors.neutral.light[100]};
  border-radius: ${({ expanded }) => (expanded ? "12px 12px 0 0" : "12px")};
`;
