import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse, LanguagesSlugs } from "@typeDefinitions";
import * as yup from "yup";
import { logFetchValidateError } from "@utils/validate";

export type FetchSectionsProgramPreviewParams = number;
export type FetchSectionsProgramPreviewResponse = ApiResponse<
  SectionProgramPreviewSchema[]
>;
export const fetchSectionsProgramPreview = async (
  programId: FetchSectionsProgramPreviewParams,
): Promise<FetchSectionsProgramPreviewResponse> => {
  const response = await fetchData(
    `/dietitian/programs-search-preview/${programId}/sections`,
    APIMethods.GET,
  );

  return await fetchSectionsProgramPreviewSchema
    .validate(response)
    .catch(logFetchValidateError("fetchSectionsProgramPreview"));
};

export interface SectionProgramPreviewSchema {
  section: {
    id: number;
    isSystem: boolean;
    category: {
      id: number;
    };
  };
  name: string;
  translations: {
    lang: LanguagesSlugs;
    name: string | null;
  }[];
}

const fetchSectionsProgramPreviewSchema = yup.object({
  data: yup
    .array()
    .of(
      yup.object({
        section: yup
          .object({
            id: yup.number().required(),
            isSystem: yup.boolean().required(),
            category: yup
              .object({
                id: yup.number().required(),
              })
              .required(),
          })
          .required(),
        name: yup.string().required(),
        translations: yup
          .array()
          .of(
            yup.object({
              lang: yup
                .mixed<LanguagesSlugs>()
                .oneOf(Object.values(LanguagesSlugs))
                .required(),
              name: yup.string().nullable().defined(),
            }),
          )
          .required(),
      }),
    )
    .defined(),
});
