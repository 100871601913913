import { Control, Controller } from "react-hook-form";

import { omit } from "lodash";

import { FieldValues } from "react-hook-form/dist/types";
import {
  RadioTileGroup,
  RadioTileGroupProps,
} from "@components/RadioTileGroup";

/**
 * radio tile group component with React Hook Form Controller
 */
export const FormRadioTileGroup = <T extends FieldValues>(
  props: FormRadioTileGroupProps<T>,
) => {
  const { control, ...rest } = props;

  return (
    <Controller
      name={rest.name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <RadioTileGroup
          name={rest.name}
          onChange={onChange}
          defaultValue={value as string | number | undefined}
          {...omit(rest, "name")}
        />
      )}
    />
  );
};

interface FormRadioTileGroupProps<T extends FieldValues>
  extends RadioTileGroupProps<T> {
  /** react hook form controller */
  control: Control<T>;
}
