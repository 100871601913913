import { ThemeProviderWrapperNew } from "themeNew";
import { Chatbox } from "./Chatbox";
import { ChatsWrapperStyled } from "./Chatbox.styled";
import { UserChatIf } from "./ChatboxContext";
import { isString } from "lodash";

interface ChatsWrapperProps {
  selectedUsers: (UserChatIf | string)[];
}

export const ChatsWrapper = ({ selectedUsers }: ChatsWrapperProps) => {
  if (!selectedUsers.length) return <></>;

  return (
    <ThemeProviderWrapperNew>
      <ChatsWrapperStyled>
        {selectedUsers.map(chat => {
          if (isString(chat))
            return <Chatbox key={chat} conversationId={chat} />;
          return <Chatbox key={chat.id} user={chat} />;
        })}
      </ChatsWrapperStyled>
    </ThemeProviderWrapperNew>
  );
};
