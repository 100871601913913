import { useMemo } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Box } from "@mui/material";
import { useAppTranslation } from "@hooks";
import {
  CustomLegend,
  LegendCircle,
  LegendText,
  LegendItem,
} from "@views/dietician/PatientMonitoring2/components/CustomChartLegend";
import { Props } from "recharts/types/component/DefaultLegendContent";
import { NutrientsMonitoringResource } from "@client/resources/NutrientsMonitoringResource";

type NutrientChartProps = {
  data: NutrientsMonitoringResource[];
  nutrientId: number;
  tooltipLabelFormatter: (date: string) => string;
  xAxisTickFormatter: (date: string) => string;
};
const NutrientChart = ({
  data,
  nutrientId,
  tooltipLabelFormatter,
  xAxisTickFormatter,
}: NutrientChartProps) => {
  const { t } = useAppTranslation();
  const preparedData = useMemo(
    () => prepareData(data, nutrientId),
    [data, nutrientId],
  );

  const renderColorfulLegendText = (value: string) => {
    return (
      <span
        style={{ color: "#2F303A", fontStyle: "12px", marginRight: "24px" }}
      >
        {value}
      </span>
    );
  };

  const renderLegend = (props: Props) => {
    const { payload } = props;

    return (
      <CustomLegend>
        {payload?.map((entry, index) => (
          <LegendItem key={`item-${index}`}>
            <LegendCircle style={{ background: entry.color }} />
            <LegendText>{entry.value}</LegendText>
          </LegendItem>
        ))}
      </CustomLegend>
    );
  };

  return (
    <Box width="100%" height="200px">
      <ResponsiveContainer>
        <LineChart
          data={preparedData}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <XAxis
            dataKey="date"
            color="#F0F0F0"
            stroke="#A3A3A3"
            tickFormatter={xAxisTickFormatter}
          />
          <YAxis
            color="#F0F0F0"
            stroke="#A3A3A3"
            domain={[
              0,
              (dataMax: number) => Math.round(dataMax + dataMax * 0.05),
            ]}
          />
          <CartesianGrid color="#F0F0F0" />
          <Legend
            iconType="circle"
            iconSize={8}
            formatter={renderColorfulLegendText}
            content={renderLegend}
          />
          <Tooltip
            cursor={{ fill: "#FBFAFC", stroke: "#F0F0F0" }}
            wrapperStyle={{
              background: "#fff",
              borderColor: "#F0F0F0",
              outline: "none",
            }}
            contentStyle={{
              background: "#fff",
              borderColor: "#F0F0F0",
              outline: "none",
            }}
            labelFormatter={tooltipLabelFormatter}
          />
          <Line
            type="monotone"
            name={t("monitoring2.nutritional_box.chart_values.realized")}
            dataKey="c"
            stroke="#7448D0"
            strokeWidth={2}
            dot={{ fill: "#7448D0" }}
          />
          <Line
            type="monotone"
            name={t("monitoring2.nutritional_box.chart_values.planned")}
            dataKey="d"
            stroke="#7448D070"
            strokeWidth={2}
            dot={{ fill: "#7448D070" }}
            connectNulls
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

const prepareData = (
  data: NutrientChartProps["data"],
  nutrientId: NutrientChartProps["nutrientId"],
) =>
  data?.map(item => ({
    date: item.date,
    c: item.patientNutrients.find(n => n.id === nutrientId)?.value ?? 0,
    d: item.dietitianNutrients.find(n => n.id === nutrientId)?.value ?? 0,
  })) ?? [];

export default NutrientChart;
