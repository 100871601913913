import { memo } from "react";

import { useAppTranslation } from "@hooks";
import { Chevron } from "@assets/icons";

import { MenuWrapper, StyledIconsList } from "../Dashboard.styled";
import { MenuButton } from "./MenuButton";
import { MenuDivider, TopMenuButton } from "./Menu.styled";
import { menuButtons } from "../menuButtons";

export const Menu = memo(() => {
  const { t } = useAppTranslation();

  return (
    <MenuWrapper>
      <StyledIconsList>
        <TopMenuButton
          variant="contained"
          endIcon={<Chevron size="w-3.5 h-3.5" />}
        >
          {t("common.menu")}
        </TopMenuButton>
        {menuButtons.map((menuEntry, index) => {
          if (menuEntry === "separator") {
            return <MenuDivider key={index} />;
          } else {
            return (
              <MenuButton
                style={{ fontSize: "1.5rem" }}
                key={index}
                {...menuEntry}
              />
            );
          }
        })}
      </StyledIconsList>
    </MenuWrapper>
  );
});
