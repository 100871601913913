import { memo, ReactElement } from "react";

import classnames from "classnames";

import { Remove } from "@assets/icons";

/**
 * component for showing single value tag
 */

interface TagProps {
  /** main text shown in component */
  title: string;
  /** tags color */
  color?: TagColor;
  /** additional text shown in component */
  category?: string | ReactElement;
  /** id of a tag; passed in "onRemove" callback */
  id?: string | number;
  /** callback fired on tag removal; if present remove button will be shown */
  onRemove?: (id: number | string) => void;
  onClick?: (id: number | string) => void;
  /** Additional classes for tag container */
  className?: string;
  disabled?: boolean;
}

export const Tag = memo((props: TagProps): ReactElement => {
  const {
    id,
    title,
    color = "gray",
    category,
    onRemove,
    onClick = () => null,
    className,
    disabled = false,
  } = props;

  return (
    <div
      className={classnames(
        {
          "py-2 px-3 flex items-center w-max rounded-md border": true,
          "bg-gray-400": color === "gray",
          "bg-primary text-white": color === "primary",
          "bg-white text-primary border-primary": color === "secondary",
        },
        className,
      )}
    >
      {category && <span className="mr-1 text-xs">{category}:</span>}
      <span
        onClick={() => onClick(id || title)}
        className="font-semibold text-xs"
      >
        {title}
      </span>
      {onRemove && !disabled ? (
        <span
          className="ml-2 cursor-pointer text-2xs"
          onClick={() => onRemove(id || title)}
        >
          <Remove />
        </span>
      ) : null}
    </div>
  );
});

type TagColor = "gray" | "primary" | "secondary";
