import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export async function requestHideProgram(id: number) {
  await fetchData(`/dietitian/programs/${id}/hide`, APIMethods.PUT);
}

export async function requestDeleteProgram(id: number) {
  await fetchData(`/dietitian/programs/${id}`, APIMethods.DELETE);
}

export async function requestShowProgram(id: number) {
  await fetchData(`/dietitian/programs/${id}/show`, APIMethods.PUT);
}
