import { useMemo } from "react";

import { IconButton, Tab, Tabs } from "@mui/material";

import { Close } from "@assets/icons/DesignSystem";
import { useAppTranslation } from "@hooks";

import { Header, Title } from "./DrawerHeader.styled";
import { Tab as TabEnum } from "./RecipeEditContent";

interface DrawerHeaderProps {
  title?: string;
  tab: TabEnum;
  setTab: (tab: TabEnum) => void;
  onClose: () => void;
}
export const DrawerHeader = ({
  title,
  tab,
  setTab,
  onClose,
}: DrawerHeaderProps) => {
  const { t } = useAppTranslation();

  const tabs = useMemo(
    () => [
      { id: TabEnum.INFO, label: t("diet.recipe_sidepanel.info_tab") },
      { id: TabEnum.PRODUCTS, label: t("diet.recipe_sidepanel.products_tab") },
      { id: TabEnum.TAGS, label: t("diet.recipe_sidepanel.tags_tab") },
    ],
    [t],
  );

  return (
    <Header>
      <div className="flex justify-between">
        <Title>{title ?? t("diet.recipe_sidepanel.add_recipe")}</Title>

        <IconButton size="small" onClick={onClose}>
          <Close size="w-[12px] h-[12px]" />
        </IconButton>
      </div>

      <Tabs value={tab} onChange={(e, tab) => setTab(tab)}>
        {tabs.map(t => (
          <Tab key={t.id} label={t.label} value={t.id} />
        ))}
      </Tabs>
    </Header>
  );
};
