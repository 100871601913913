import { useContext, useEffect, useState } from "react";
import { ReactSortable } from "react-sortablejs";

import { Collapse } from "@mui/material";

import { DragHandle } from "@assets/icons/DesignSystem";
import { useUpdateSurveySubgroupsSortMutation } from "@hooks/queries/surveys";
import { detectMovedElement } from "@views/dietician/product-views/components/ProductMeasures/utils/move";

import { TableOfContentsContext } from "@context/TableOfContentsContext";
import { SubgroupElement } from "./SubgroupElement";
import {
  HandleWrapper,
  ListElementStyled,
  StyledArrowDown,
  TitleStyled,
} from "./TableOfContents.styled";

interface SubgroupIf {
  id: number;
  name: string;
}

interface GroupElementProps {
  id: string;
  title: string;
  subgroups: SubgroupIf[];
  edit?: boolean;
}

export const GroupElement = ({
  id,
  title,
  subgroups,
  edit,
}: GroupElementProps) => {
  const { selectedId, setSelectedId, setGroupId, groupId, subgroupId } =
    useContext(TableOfContentsContext);

  const selected = selectedId === id;
  const [expanded, setExpanded] = useState(groupId === Number(id));

  const { mutate: updateSort } = useUpdateSurveySubgroupsSortMutation();

  const handleToggleExpand = () => {
    if ((groupId === Number(id) && id === selectedId) || !expanded)
      setExpanded(!expanded);
    setSelectedId(id);
    setGroupId(Number(id));
  };

  useEffect(() => {
    if (groupId === Number(id) && !expanded) setExpanded(true);
  }, [subgroupId]);

  return (
    <div className="grid">
      <ListElementStyled selected={selected} onClick={handleToggleExpand}>
        <StyledArrowDown expanded={expanded} />
        <TitleStyled>{title}</TitleStyled>
        {edit && (
          <HandleWrapper className="dragHandle-group">
            <DragHandle />
          </HandleWrapper>
        )}
      </ListElementStyled>
      <Collapse in={expanded}>
        <ReactSortable
          disabled={!edit}
          forceFallback
          scrollSensitivity={100}
          animation={200}
          handle=".dragHandle-subgroup"
          list={subgroups}
          setList={newOrder => {
            const result = detectMovedElement(
              subgroups.map(subgroup => subgroup.id.toString()),
              newOrder.map(subgroup => subgroup.id.toString()),
            );

            if (result)
              updateSort({
                id,
                payload: { ids: newOrder.map(subgroup => subgroup.id) },
              });
          }}
        >
          {subgroups.map(({ id: subgroupId, name }) => (
            <SubgroupElement
              key={subgroupId}
              id={`${subgroupId}`}
              groupId={id}
              title={name}
              edit={edit}
            />
          ))}
        </ReactSortable>
      </Collapse>
    </div>
  );
};
