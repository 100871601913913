import { useEffect, useMemo, useState } from "react";

import flatMap from "lodash/flatMap";
import sum from "lodash/sum";
import range from "lodash/range";

import { GeneratedMealDto } from "@client/diets/postDietAutoGenerateMeal";
import { ModalWrapper } from "@components/ModalWrapperNew";
import { useAppParams, useAppTranslation, useMealClientContext } from "@hooks";
import { usePostDietAutoGenerateDietMutation } from "@hooks/queries/diets";
import { getTranslation } from "@utils/translations";

import { MagicWand } from "@assets/icons";
import { TabSwitch } from "@components/TabSwitch";
import { TabContext } from "@mui/lab";
import { usePostDietMealsItemsMutation } from "../../hooks";
import { GeneratingBackdrop } from "../GeneratingBackdrop";
import { mapRequest } from "../RecipesTab/AddElementSidePanel/ActionButtons";
import { RecipeAccordion } from "../RecipesTab/ElementsAccordions";
import { ProductAccordion } from "../RecipesTab/ElementsAccordions/ProductAccordion";
import {
  ProductItem,
  RecipeItem,
  useItemsContext,
} from "../RecipesTab/ItemsContext";
import {
  ContentWrapper,
  GenerateButton,
  LabelUppercase,
  SkeletonStyled,
  StickyHeader,
  TabPanelStyled,
  Text,
} from "./common.styled";
import { EmptyState } from "./EmptyState";
import { SelectedItemsCounter } from "./SelectedItemsCounter";

interface AutogenerateDietModalProps {
  open: boolean;
  onClose: () => void;
  tags: number[];
}

export const AutogenerateDietModal = ({
  open,
  onClose,
  tags,
}: AutogenerateDietModalProps) => {
  const { dietId } = useAppParams();
  const { t, currentLanguage } = useAppTranslation();
  const { mutate, isLoading: isLoadingItems } =
    usePostDietAutoGenerateDietMutation();
  const [meals, setMeals] = useState<GeneratedMealDto[] | null>(null);
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [showEmptyState, setShowEmptyState] = useState(false);
  const { submit, addItems, clearValues } = useItemsContext();
  const { mutate: mutateItems, isLoading: isSavingItems } =
    usePostDietMealsItemsMutation();
  const {
    getRecipes,
    getProducts,
    productsClientContextMap,
    recipesClientContextMap,
  } = useMealClientContext();

  const tabs = useMemo(
    () =>
      meals?.map(({ meal, items }) => ({
        id: meal.id.toString(),
        label: `${getTranslation(meal.translations, currentLanguage)} (${
          items.length ?? 0
        })`,
      })) ?? [],
    [meals, currentLanguage],
  );
  const [tab, setTab] = useState<string | undefined>();
  const totalItems = useMemo(
    () => sum(flatMap(meals, ({ items }) => items.length)),
    [meals, sum, flatMap],
  );
  const handleSubmit = () => {
    submit(d =>
      mutateItems(
        { dietId, payload: { data: mapRequest(d) } },
        { onSuccess: onClose },
      ),
    );
  };

  const handleGenerate = () => {
    setShowPlaceholder(true);
    clearValues();
    mutate(
      {
        dietId,
        payload: {
          tagsId: tags,
        },
      },
      {
        onSuccess: data => {
          setMeals(data.data);
          setTab(data.data[0]?.meal.id.toString());

          const mappedItems: (ProductItem | RecipeItem)[] = data.data.flatMap(
            ({ items, meal }) => {
              return items.map(item => {
                if (item.type === "recipe")
                  return {
                    type: "recipe",
                    id: item.recipe.id,
                    servings: item.servings,
                    mealId: meal.id,
                  };

                return {
                  type: "product",
                  id: item.product.id,
                  grams: item.grams,
                  measure: item.measure.id,
                  mealId: meal.id,
                };
              });
            },
          );
          const recipesIds = mappedItems
            .filter((item): item is RecipeItem => item.type === "recipe")
            .map(({ id }) => id);
          const productsIds = mappedItems
            .filter((item): item is ProductItem => item.type === "product")
            .map(({ id }) => id);
          getProducts(productsIds);
          getRecipes(recipesIds);
          setShowEmptyState(mappedItems.length === 0);
          addItems(mappedItems);
        },
        onError: () => {
          setShowEmptyState(true);
        },
      },
    );
  };

  useEffect(() => {
    if (!open) return;

    handleGenerate();
  }, [open]);

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={`${t("diet.generate_diet.title")}${
        meals
          ? `, ${meals?.length} ${t("diets.meals", { count: meals?.length })}`
          : ""
      }`}
      confirmText={t("diet.generate_meal.submit")}
      disabled={showPlaceholder}
      loading={isSavingItems}
      header={
        <StickyHeader>
          <Text>{t("diet.generate_diet.text")}</Text>

          <div className="flex justify-between">
            <LabelUppercase>
              {t("diet.generate_diet.proposals")}{" "}
              {!showPlaceholder && `(${totalItems})`}
            </LabelUppercase>
            <GenerateButton onClick={handleGenerate}>
              <MagicWand />
              {t("diet.generate_diet.again")}
            </GenerateButton>
          </div>
          {!showPlaceholder && (
            <TabSwitch tabs={tabs} value={tab} onChange={setTab} size="small" />
          )}
        </StickyHeader>
      }
      footer={
        <div className="flex-1">
          {!showPlaceholder && <SelectedItemsCounter />}
        </div>
      }
    >
      <ContentWrapper>
        <div className="flex flex-col gap-[8px]">
          {!showPlaceholder && showEmptyState && <EmptyState />}
          {showPlaceholder &&
            range(0, 20).map(id => (
              <SkeletonStyled
                key={id}
                variant="rectangular"
                width={666.5}
                height={108}
              />
            ))}

          <div>
            <TabContext value={tab ?? ""}>
              {!showPlaceholder &&
                meals?.map(({ meal, items }) => (
                  <TabPanelStyled key={meal.id} value={meal.id.toString()}>
                    {!showPlaceholder && !items.length && <EmptyState />}

                    {items?.map(item => {
                      if (item.type === "product") {
                        const { id, name, translations, image, nutrients } =
                          item.product;
                        return (
                          <ProductAccordion
                            key={id}
                            id={id}
                            namePl={name}
                            nameEn={getTranslation(
                              translations,
                              currentLanguage,
                            )}
                            imgUrl={image.url}
                            macros={nutrients}
                            tags={[]}
                            mealId={meal.id}
                            clientContext={productsClientContextMap.get(id)}
                          />
                        );
                      }

                      const {
                        id,
                        nutrients,
                        image,
                        servings,
                        translations,
                        name,
                        tags,
                      } = item.recipe;
                      return (
                        <RecipeAccordion
                          key={id}
                          id={id}
                          macros={nutrients}
                          imgUrl={image?.url}
                          servings={servings}
                          tags={tags.map(({ id }) => id)}
                          title={name}
                          titleEn={getTranslation(
                            translations,
                            currentLanguage,
                          )}
                          mealId={meal.id}
                          clientContext={recipesClientContextMap.get(id)}
                        />
                      );
                    })}
                  </TabPanelStyled>
                ))}
            </TabContext>
          </div>
        </div>
      </ContentWrapper>
      {showPlaceholder && (
        <GeneratingBackdrop
          open={isLoadingItems}
          onCancel={onClose}
          onClose={() => setShowPlaceholder(false)}
        />
      )}
    </ModalWrapper>
  );
};
