import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const CheckboxUnChecked = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16">
      <path
        d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H12C13.933 0.5 15.5 2.067 15.5 4V12C15.5 13.933 13.933 15.5 12 15.5H4C2.067 15.5 0.5 13.933 0.5 12V4Z"
        fill="white"
        stroke={props.fill ?? "#D0D0D0"}
      />
      <g clipPath="url(#clip0_2381_17532)">
        <path
          d="M14.75 3.21875C15.0625 3.57292 15.0625 3.91667 14.75 4.25L6.5 12.5C6.16667 12.8125 5.82292 12.8125 5.46875 12.5L1.21875 8.25C0.927083 7.91667 0.927083 7.57292 1.21875 7.21875C1.57292 6.92708 1.92708 6.92708 2.28125 7.21875L6 10.9375L13.7188 3.21875C14.0729 2.92708 14.4167 2.92708 14.75 3.21875Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2381_17532">
          <rect x="2" y="2" width="12" height="12" rx="6" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
