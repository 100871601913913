import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { useAppTranslation } from "@hooks";
import { SectionsFiltersInput } from "@typeDefinitions/types";
import {
  useDeleteSectionMutation,
  useHideSectionMutation,
  useSearchSectionsQuery,
  useShowSectionMutation,
} from "@hooks/queries";
import { SectionDetails } from "@views/dietician/Section/components";
import { SingleCardPageLayout } from "@components/PageLayout";
import { ListElement, ListHeader } from "@components/ListModules";
import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";
import { Pagination } from "@components/Pagination";

import { SectionFilters } from "./components";
import { FiltersNoResults } from "@views/emptyStates/FiltersNoResults";
import { clearFormInputs } from "@utils";

export const Sections = () => {
  const { t, isPolishChosen } = useAppTranslation();
  const form = useForm<SectionsFiltersInput>();

  const { sections, links, handlePaginationClick, submit, isLoading, isError } =
    useSearchSectionsQuery();
  const hideSectionMutation = useHideSectionMutation();
  const deleteSectionMutation = useDeleteSectionMutation();
  const showSectionMutation = useShowSectionMutation();

  const handleClearInputs = () => clearFormInputs(form.reset, submit);

  return (
    <SingleCardPageLayout title={t("sections.title")}>
      <div className="p-7">
        <SectionFilters form={form} onSubmit={form.handleSubmit(submit)} />
      </div>

      <ListHeader
        name={t("sections.section_name")}
        body={t("sections.category")}
      />

      {isLoading && <Spinner className="h-52" />}

      {isError && <ApiError />}

      {!sections?.length && !isLoading && (
        <div className="py-8">
          <FiltersNoResults onClick={handleClearInputs} />
        </div>
      )}

      {sections?.map(section => (
        <ListElement
          key={section.id}
          title={
            <Link to={`/sections/${section.id}`}>
              {isPolishChosen ? section.title : section.titleEn}
            </Link>
          }
          body={
            <p>
              {isPolishChosen
                ? section.programSectionCategory.description
                : section.programSectionCategory.descriptionEn}
            </p>
          }
          onCopy={`/sections/${section.id}/copy`}
          onEdit={
            section.actions.canEdit ? `/sections/${section.id}/edit` : null
          }
          onShow={
            section.hide && section.actions.canVisible
              ? () => showSectionMutation.mutateAsync(section.id)
              : undefined
          }
          onHide={
            !section.hide && section.actions.canVisible
              ? () => hideSectionMutation.mutateAsync(section.id)
              : undefined
          }
          onDelete={
            section.actions.canDelete
              ? () => deleteSectionMutation.mutateAsync(section.id)
              : undefined
          }
        >
          <SectionDetails sectionId={section.id} />
        </ListElement>
      ))}

      {sections && sections.length > 0 && links && (
        <Pagination links={links} onClick={handlePaginationClick} />
      )}
    </SingleCardPageLayout>
  );
};
