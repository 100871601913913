import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FetchPatientNormNutrientsResponse } from "@client/patient/nutrients/fetchPatientNormNutrients";
import { patientKeys } from "@hooks/queries/client/patientKeys";
import {
  updatePatientNormNutrients,
  UpdatePatientNormNutrientsParams,
  UpdatePatientNormNutrientsPayload,
} from "@client/patient/nutrients/updatePatientNormNutrients";
import { patientNutrientKeys } from "@hooks/queries/client/nutrient/patientNutrientKeys";

const useUpdatePatientNormNutrientsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      params,
      payload,
    }: {
      params: UpdatePatientNormNutrientsParams;
      payload: UpdatePatientNormNutrientsPayload;
    }) => {
      return updatePatientNormNutrients(params, payload);
    },
    onSuccess: async (_, variables) => {
      queryClient.invalidateQueries(
        patientNutrientKeys.norm(variables.params.patientId),
      );
    },
  });
};

export default useUpdatePatientNormNutrientsMutation;
