import { useQuery, useQueryClient } from "@tanstack/react-query";

import { FetchMealResponse, fetchCreatorMealTags } from "@client/meals";
import { QueryOptionsTyped } from "@hooks/queries/types";
import { ApiResponse } from "@typeDefinitions";
import { TagExtendedNewDto } from "@client";
import { fetchCreatorMealQueryKey } from "../useFetchCreatorMealQuery";
import { update } from "lodash";

export const useFetchMealTagsQueryKey = "fetchMealTagsQueryKey";

export const useFetchCreatorMealTagsQuery = (
  mealId: string,
  options?: QueryOptionsTyped<ApiResponse<TagExtendedNewDto[]>>,
) => {
  const { data, ...rest } = useQuery(
    [useFetchMealTagsQueryKey, mealId],
    () => fetchCreatorMealTags(mealId),
    {
      ...options,
      onSuccess: data => {
        options?.onSuccess && options.onSuccess(data);
        queryClient.setQueryData<ApiResponse<FetchMealResponse>>(
          [fetchCreatorMealQueryKey, mealId],
          old => {
            if (!old) return;
            return update(old, "data.tags", () => data.data);
          },
        );
      },
    },
  );
  const queryClient = useQueryClient();

  return {
    mealTags: data?.data,
    ...rest,
  };
};
