import { LangDto } from "@client";
import { Dispatch, SetStateAction } from "react";
import { FormProvider } from "react-hook-form";
import { useAppParams, useAppTranslation, useUserRoles } from "@hooks";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { FormExpandableTagSelector } from "@components/FormExpandableTagSelector";
import {
  RecipeVerifiedWrapper,
  StyledFormCheckboxMui,
} from "@components/RecipeEditDetails/RecipeEditDetails.styled";
import { Reviewed } from "@assets/icons";
import { StyledFormTextField } from "@views/dietician/MealEdit/MealEdit.styled";
import { FetchMealResponse } from "@client/meals";
import {
  createMealNameForm,
  useMealNameForm,
  useMealTagsForm,
} from "@views/dietician/MealEdit/forms";
import { useFetchCreatorMealTagsQuery } from "@hooks/queries/meals/useFetchCreatorMealTags";
import { MealNameFormWatch } from "@views/dietician/MealEdit/components/watches/MealNameFormWatch";
import { MealTagsFormWatch } from "@views/dietician/MealEdit/components/watches/MealTagsFormWatch";
import { ToggleButtonsWrapper } from "./MealAverageNutrients.styled";

import { ThemeProviderWrapperNew } from "themeNew";
import { usePostRenderMainPhotoMutation } from "@hooks/queries/meals";
import { GenerateImageButton } from "@components/GenerateImageButton";

interface MealEditDetailsProps {
  lang: LangDto;
  setLang: Dispatch<SetStateAction<LangDto>>;
  meal: FetchMealResponse;
}

export const MealEditDetails = ({
  lang,
  setLang,
  meal,
}: MealEditDetailsProps) => {
  const { t } = useAppTranslation();
  const { isAdminClinicMember } = useUserRoles();
  const { mealId } = useAppParams();
  const { mealTags } = useFetchCreatorMealTagsQuery(mealId);

  const { control: nameControl, ...restName } = useMealNameForm(
    createMealNameForm(meal),
  );
  const { control: tagsControl, ...restTags } = useMealTagsForm(mealTags);

  const { mutate, isLoading } = usePostRenderMainPhotoMutation();
  const handleGenerate = () => mutate(mealId);

  return (
    <div className="flex gap-[32px]">
      <ThemeProviderWrapperNew>
        <GenerateImageButton
          img={meal.media?.url}
          onClick={handleGenerate}
          isLoading={isLoading}
        />
      </ThemeProviderWrapperNew>
      <div className="grid gap-8">
        <FormProvider control={nameControl} {...restName}>
          <MealNameFormWatch />
        </FormProvider>
        <FormProvider control={tagsControl} {...restTags}>
          <MealTagsFormWatch />
        </FormProvider>

        <ToggleButtonsWrapper>
          <StyledFormTextField
            control={nameControl}
            name={lang === LangDto.PL ? "namePl" : "nameEn"}
            key={lang === LangDto.PL ? "namePl" : "nameEn"}
            label={t("meals.meal_name")}
          />
          <ToggleButtonGroup
            value={lang}
            exclusive
            className="h-[40px]"
            onChange={(_, value) => value && setLang(value)}
          >
            <ToggleButton color="primary" value={LangDto.PL}>
              {LangDto.PL.toUpperCase()}
            </ToggleButton>
            <ToggleButton color="primary" value={LangDto.EN}>
              {LangDto.EN.toUpperCase()}
            </ToggleButton>
          </ToggleButtonGroup>
        </ToggleButtonsWrapper>
        <FormExpandableTagSelector
          control={tagsControl}
          name="tags"
          tagCategory="meal"
        >
          {isAdminClinicMember && (
            <StyledFormCheckboxMui
              name="reviewed"
              control={nameControl}
              label={
                <RecipeVerifiedWrapper>
                  {t("recipes.verified")}
                  <Reviewed size="w-6 h-6" />
                </RecipeVerifiedWrapper>
              }
            />
          )}
        </FormExpandableTagSelector>
      </div>
    </div>
  );
};
