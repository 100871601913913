import { useQuery } from "@tanstack/react-query";

import { fetchUsedRecipes, FetchUsedRecipesResponse } from "@client/schedule";

import { QueryOptionsTyped } from "../types";
import { useMemo, useState } from "react";
import { parseString } from "@utils";

export const fetchUsedRecipesQueryKey = "fetchUsedRecipesQueryKey";

export const useFetchUsedRecipesQuery = (
  programId: number,
  defaultInputs: FetchUsedRecipesInputs = {},
  options?: QueryOptionsTyped<FetchUsedRecipesResponse>,
) => {
  const [inputs, setInputs] = useState<FetchUsedRecipesInputs>(defaultInputs);
  const searchParams = useMemo(() => parseInputs(inputs), [inputs]);

  const { data, ...rest } = useQuery(
    [fetchUsedRecipesQueryKey, programId.toString(), searchParams.toString()],
    () => fetchUsedRecipes(programId, searchParams),
    options,
  );

  return { recipes: data?.data, submit: setInputs, ...rest };
};

interface FetchUsedRecipesInputs {
  from?: string;
  to?: string;
}

const parseInputs = (input: FetchUsedRecipesInputs) => {
  const search = new URLSearchParams();
  parseString(input?.from, "from", search);
  parseString(input?.to, "to", search);

  return search;
};
