import { LoadingButton } from "@mui/lab";
import { SelectedProgramType } from "@views/dietician/PatientProgramChoice/PatientProgramChoice";
import { useState } from "react";
import DialogConfirmProgram from "@views/dietician/PatientProgramChoice/ChoiceButton/DialogConfirmProgram";
import { useAppTranslation } from "@hooks";

type ChoiceButtonProps = {
  selectedProgram: SelectedProgramType;
};
const ChoiceButton = ({ selectedProgram }: ChoiceButtonProps) => {
  const { t } = useAppTranslation();
  const [open, setOpen] = useState(false);

  return (
    <>
      <LoadingButton
        variant="contained"
        disabled={!selectedProgram}
        onClick={() => setOpen(true)}
      >
        {t("patient.programs_choice.confirm_button")}
      </LoadingButton>
      {!!selectedProgram && (
        <DialogConfirmProgram
          selectedProgram={selectedProgram}
          open={open}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
};

export default ChoiceButton;
