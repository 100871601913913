import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from "@mui/material";

export const DialogStyled = styled(Dialog)`
  & .MuiPaper-root {
    width: 31.875rem;
  }
`;

export const DialogContentStyled = styled(DialogContent)`
  font-size: 0.875rem;
  line-height: 1.25rem;
`;
export const IconButtonStyled = styled(IconButton)`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
`;
