import { useForm } from "react-hook-form";
import { useEffect } from "react";

import dayjs from "dayjs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { CloneClientProgramToClientRequest } from "@client";
import { useAppTranslation } from "@hooks";
import { FetchPatientProgramResponse } from "@client/program";

export const useProgramCopyForm = (defaultValues?: ProgramCopyInput) => {
  const { t } = useAppTranslation();
  const programCopySchema = yup.object().shape({
    clientId: yup
      .string()
      .typeError(t("common.required_field"))
      .required(t("common.required_field")),
    startDate: yup
      .date()
      .typeError(t("common.required_field"))
      .required(t("common.required_field")),
    isIndefinite: yup.boolean().required(t("common.required_field")),
  });

  const form = useForm<ProgramCopyInput>({
    defaultValues,
    resolver: yupResolver(programCopySchema),
  });

  useEffect(() => {
    if (defaultValues) form.reset(defaultValues);
  }, [defaultValues]);

  return form;
};

interface ProgramCopyInput {
  clientId: string;
  startDate: Date;
  isIndefinite: boolean;
}

export const mapProgramCopyForm = (
  patientId: number,
  data?: FetchPatientProgramResponse,
): ProgramCopyInput => {
  return {
    clientId: patientId.toString(),
    isIndefinite: !data?.finishDate,
    startDate: dayjs().toDate(),
  };
};

export const mapProgramCopyRequest = (
  data: ProgramCopyInput,
): CloneClientProgramToClientRequest => {
  return {
    startDate: dayjs(data.startDate).format("YYYY-MM-DD"),
    patientId: parseInt(data.clientId),
  };
};
