import { styled } from "@mui/material";

interface LabelWrapperProps {
  flex: number;
  hidden?: boolean;
}

export const LabelWrapper = styled("div")<LabelWrapperProps>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  flex: ${({ flex }) => flex};
  opacity: ${({ hidden }) => (hidden ? "0" : "1")};
`;

export const StyledLabel = styled("span")`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral.medium[800]};
`;
