import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Buildings = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      style={{ fill: "none" }}
      width="21"
      height="21"
      viewBox="0 0 21 21"
    >
      <path
        d="M5.8625 15.75H3.63125C2.38 15.75 1.75 15.12 1.75 13.8688V3.63125C1.75 2.38 2.38 1.75 3.63125 1.75H7.39375C8.645 1.75 9.275 2.38 9.275 3.63125V5.25"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1995 7.3675V17.1325C15.1995 18.5412 14.4995 19.25 13.0908 19.25H7.98078C6.57203 19.25 5.86328 18.5412 5.86328 17.1325V7.3675C5.86328 5.95875 6.57203 5.25 7.98078 5.25H13.0908C14.4995 5.25 15.1995 5.95875 15.1995 7.3675V7.3675Z"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.725 5.25V3.63125C11.725 2.38 12.355 1.75 13.6063 1.75H17.3688C18.62 1.75 19.25 2.38 19.25 3.63125V13.8688C19.25 15.12 18.62 15.75 17.3688 15.75H15.1988M8.75 9.625H12.25M8.75 12.25H12.25M10.5 19.25V16.625"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
