import { useMutation } from "@tanstack/react-query";

import { MutationOptions } from "./types";
import { AxiosError } from "axios";
import { ApiErrorIf } from "@typeDefinitions";
import {
  UpdateClientCustomTagRequest,
  updateClientCustomTags,
} from "@client/updateClientCustomTags";

export const useUpdateClientCustomTagsMutation = (
  options?: MutationOptions,
) => {
  return useMutation<
    unknown,
    AxiosError<ApiErrorIf>,
    UpdateClientCustomTagRequest
  >(
    (request: UpdateClientCustomTagRequest) => updateClientCustomTags(request),
    {
      onSuccess: (data, variables) => {
        options?.onSuccess && options.onSuccess(data, variables);
      },
      ...options,
    },
  );
};
