import { fetchCreatorDietMealNutrients } from "@client/diets/creator";
import { useQuery } from "@tanstack/react-query";

export const fetchCreatorDietMealNutrientsQueryKey =
  "fetchCreatorDietMealNutrientsQueryKey";

export const useFetchCreatorDietMealNutrientsQuery = (
  dietId: string,
  mealId: string,
) => {
  const { data, ...rest } = useQuery({
    queryKey: [fetchCreatorDietMealNutrientsQueryKey, dietId, mealId],
    queryFn: () => fetchCreatorDietMealNutrients(dietId, mealId),
  });

  return {
    data: data?.data,
    ...rest,
  };
};
