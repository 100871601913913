import { createContext, ReactNode, useMemo } from "react";

interface FormNameProviderContextIf {
  getName: (name: string) => string;
}

const formNameProviderContextDefaultValues: FormNameProviderContextIf = {
  getName: (name: string) => name,
};

export const FormNameProviderContext = createContext<FormNameProviderContextIf>(
  formNameProviderContextDefaultValues,
);

interface FormNameProviderProps {
  name: string;
  children: ReactNode;
}

export const FormNameProvider = ({ name, children }: FormNameProviderProps) => {
  const context = useMemo(
    () => ({ getName: (fieldName: string) => `${name}.${fieldName}` }),
    [name],
  );

  return (
    <FormNameProviderContext.Provider value={context}>
      {children}
    </FormNameProviderContext.Provider>
  );
};
