import { SelectOption } from "@components/FormAutocomplete";

export const handleToggleDietitian = (
  pickedDietitians: string[],
  id: string,
  onChange: (value: string[]) => void,
) => {
  const updatedDietitians = pickedDietitians.includes(id)
    ? pickedDietitians.filter(dietitianId => dietitianId !== id)
    : [...pickedDietitians, id];

  onChange(updatedDietitians);
};

export const processDietitians = (
  pickedDietitians: string[],
  maxAvatarsToShow: number,
) => {
  const avatarsToShow = pickedDietitians.slice(0, maxAvatarsToShow);
  const extraAvatarsCount = pickedDietitians.length - maxAvatarsToShow;
  const hiddenDietitians = pickedDietitians.slice(maxAvatarsToShow);

  return {
    avatarsToShow,
    extraAvatarsCount,
    hiddenDietitians,
  };
};

export const findDietitian = (
  dietitiansOptions: SelectOption[] | undefined,
  id: string,
) => {
  return dietitiansOptions?.find(d => d.id === id);
};
