import { Button, Tooltip, Chip } from "@mui/material";
import { ChipWrapper } from "./NutritionProgramComponent.styled";
import { useAppTranslation } from "@hooks";
import { Link } from "react-router-dom";
import { useMemo } from "react";
import dayjs from "dayjs";
import { PATIENT_PROFILE_PROGRAM_STATUS } from "@utils";
import useNewMobileAppFeatureEnabled from "@features/useNewMobileAppFeatureEnabled";

interface NutritionProgramComponentProps {
  status: PATIENT_PROFILE_PROGRAM_STATUS | null;
  id: number;
  programStarts?: string;
  programEnds?: string;
  isActiveClient: boolean;
}
export const NutritionProgramComponent = ({
  status,
  id,
  programStarts,
  programEnds,
  isActiveClient,
}: NutritionProgramComponentProps) => {
  const { t } = useAppTranslation();
  const newMobileFeature = useNewMobileAppFeatureEnabled();

  const startDate = dayjs(programStarts);
  const currDate = dayjs();

  const subscriptionsStartsIn = startDate.diff(currDate, "days");

  const subscriptionPeriod = `${dayjs(programStarts).format(
    "DD.MM.YYYY",
  )} - ${dayjs(programEnds).format("DD.MM.YYYY")}`;

  const getChipVariant = useMemo(() => {
    switch (status) {
      case PATIENT_PROFILE_PROGRAM_STATUS.ACTIVE:
        return (
          <Chip
            color="success"
            size="small"
            label={t("clients.filters.active")}
          />
        );
      case PATIENT_PROFILE_PROGRAM_STATUS.PENDING_ACTIVE:
        return (
          <Chip
            color="warning"
            size="small"
            label={t("clients.filters.inactive")}
          />
        );
      case PATIENT_PROFILE_PROGRAM_STATUS.PENDING:
        return (
          <Chip
            color="default"
            size="small"
            label={t("clients.table.starts_in", {
              count: subscriptionsStartsIn,
            })}
          />
        );
      case PATIENT_PROFILE_PROGRAM_STATUS.ENDING:
        return (
          <Chip
            color="warning"
            size="small"
            label={t("clients.filters.ending")}
          />
        );
      case PATIENT_PROFILE_PROGRAM_STATUS.FINISHED:
        return (
          <Chip
            color="info"
            size="small"
            label={t("clients.filters.finished")}
          />
        );

      default:
        return (
          <Chip
            color="default"
            size="small"
            label={t("clients.filters.active")}
          />
        );
    }
  }, [status, t]);

  if (!isActiveClient) return <></>;

  return (
    <Tooltip
      title={
        status &&
        status !== PATIENT_PROFILE_PROGRAM_STATUS.NO_PROGRAM &&
        subscriptionPeriod
      }
      arrow
      placement="top"
    >
      <ChipWrapper>
        {status && status !== PATIENT_PROFILE_PROGRAM_STATUS.NO_PROGRAM ? (
          <div className="grid">{getChipVariant}</div>
        ) : (
          <Link
            to={
              newMobileFeature
                ? `/patients/${id}/programs`
                : `/patients/${id}/nutritional-programs`
            }
          >
            <Button>{t("patient.programs.add_new_program_button")}</Button>
          </Link>
        )}
      </ChipWrapper>
    </Tooltip>
  );
};
