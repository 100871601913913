import { styled } from "@mui/material";

export const CustomLegend = styled("ul")`
  display: flex;
  flex-direction: row;
  gap: 0 24px;
  justify-content: center;
  border: none;
  flex-wrap: wrap;
`;

export const LegendItem = styled("li")`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const LegendCircle = styled("div")`
  width: 8px;
  height: 8px;
  border-radius: 16px;
`;
export const LegendText = styled("div")`
  font-size: 12px;
  line-height: 20px;
  color: #2f303a;
`;
