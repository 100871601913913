import { ChangeEvent, useRef } from "react";

import classNames from "classnames";

import { COLORS } from "@utils/constants";
import { IconButton } from "@components/IconButton";
import { Spinner } from "@components/Spinner";
import { Edit } from "@assets/icons";

/**
 * standardized component for displaying user's initials or image
 */
export const Avatar = (props: AvatarProps) => {
  const {
    image,
    size = "medium",
    color = COLORS[0],
    fullName = "Anna Kowalska",
    isEditable = false,
    isUploading = false,
    onFileUpload,
  } = props;

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleUploadFileButtonClick = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  return (
    <div
      className={classNames({
        "relative rounded-full": true,
        "bg-opacity-25": !image,
        "shadow-avatar": image,
        "w-12 h-12 p-1": size === "small",
        "w-16 h-16 p-1": size === "large",
        "w-18 h-18 p-1": size === "big",
        "w-32 h-32 p-2": size === "extra-big",
      })}
      style={{
        backgroundColor: color + "33",
        color,
      }}
    >
      {image ? (
        <img
          data-testid="AVATAR_IMAGE"
          src={image}
          alt={fullName}
          className="object-cover w-full h-full rounded-full overflow-hidden"
        />
      ) : (
        <span
          className="rounded-full text-lg border border-solid h-full w-full flex justify-center items-center"
          style={{ borderColor: color }}
        >
          {fullName
            .split(" ")
            .filter(w => w)
            .map(w => w[0].toUpperCase())
            .slice(0, 2)
            .join("")}
        </span>
      )}
      {isEditable && (
        <IconButton
          disabled={isUploading}
          onClick={handleUploadFileButtonClick}
          className="bg-white p-1 absolute -bottom-2 -right-2"
        >
          {isUploading ? (
            <Spinner
              size="w-6 h-6"
              borderWidth={2}
              className="rounded-full bg-transparent"
            />
          ) : (
            <>
              <Edit size="w-5 h-5" />
              <input
                type="file"
                accept="image/png,image/jpg,image/jpeg,image/webp"
                ref={fileInputRef}
                disabled={isUploading}
                onChange={onFileUpload}
                style={{ display: "none" }}
              />
            </>
          )}
        </IconButton>
      )}
    </div>
  );
};

type AvatarSize = "small" | "medium" | "large" | "big" | "extra-big";

export interface AvatarProps {
  /** full name of the person; used to show initials or as an alt name if "image" prop is provided */
  fullName: string;
  // /** first name of the person; used to show initials */
  // firstName?: string,
  // /** last name of the person; used to show initials */
  // lastName?: string,
  /** avatar image; will be shown instead of letters */
  image?: string;
  /** size variant of avatar */
  size?: AvatarSize;
  /** hex color code of the avatar; is used when no "image" prop is passed */
  color?: string;
  /** if set true, user can upload new avatar */
  isEditable?: boolean;
  /** is uplaoding */
  isUploading?: boolean;
  /** handles file uplaod */
  onFileUpload?: (event: ChangeEvent<HTMLInputElement>) => Promise<void> | void;
}
