import { Dispatch, SetStateAction } from "react";
import { ViewType } from "../Pricing";
import { Chip, styled, Switch } from "@mui/material";

interface BillingPeriodToggleProps {
  value: ViewType;
  setValue: Dispatch<SetStateAction<ViewType>>;
}
export const BillingPeriodToggle = ({
  value,
  setValue,
}: BillingPeriodToggleProps) => {
  const isAnnually = value === ViewType.annually;

  return (
    <div className="flex gap-[8px] items-center">
      <Label active={!isAnnually} onClick={() => setValue(ViewType.monthly)}>
        Miesięcznie
      </Label>
      <SingleColorSwitch
        checked={Boolean(value)}
        onChange={(e, v) => setValue(Number(v))}
      />
      <Label active={isAnnually} onClick={() => setValue(ViewType.annually)}>
        Rocznie
      </Label>
      <img
        src={
          isAnnually
            ? "static/pricing/priceChipColor.svg"
            : "static/pricing/priceChipGrey.svg"
        }
      />
    </div>
  );
};

interface LabelProps {
  active?: boolean;
}

const Label = styled("span")<LabelProps>`
  font-size: 16px;
  line-height: 20px;
  color: ${({ active, theme }) =>
    active ? theme.colors.neutral.dark[800] : "#483A68"};
  opacity: ${({ active }) => (active ? "1" : "0.7")};
  font-weight: ${({ active }) => (active ? 500 : 400)};
  transition: all 0.2s;
`;

const SingleColorSwitch = styled(Switch)`
  & .MuiSwitch-track {
    opacity: 1;
  }
`;

const ChipStyled = styled(Chip)`
  &.MuiChip-colorPrimary {
    background: ${({ theme }) => theme.colors.survey.light};
    position: relative;
  }
  height: 26px;
  &.MuiChip-label {
    padding: unset;
  }
`;
