import * as yup from "yup";

import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";

import { MediaDto, NutrientDto } from "./common";

export async function createDiet(
  diet: CreateDietRequest,
): Promise<ApiResponse<CreateDietResponse>> {
  const rsp = await fetchData(`/dietitian/creator/diets`, APIMethods.POST, {
    ...diet,
    id: null,
  });
  await createDietResponseSchema.validate(rsp.data);
  return rsp;
}

export async function updateProgramDiet(
  programId: number,
  dayId: number,
  diet: CreateDietRequest,
): Promise<ApiResponse<CreateDietResponse>> {
  const rsp = await fetchData(
    `/dietitian/programs/${programId}/days/${dayId}/diet`,
    APIMethods.PUT,
    {
      ...diet,
      id: null,
    },
  );
  await createDietResponseSchema.validate(rsp.data);
  return rsp;
}

export async function updateDiet(
  id: number,
  diet: CreateDietRequest,
): Promise<ApiResponse<CreateDietResponse>> {
  const rsp = await fetchData(`/dietitian/diets/${id}`, APIMethods.PUT, {
    ...diet,
    id,
  });
  await createDietResponseSchema.validate(rsp.data);
  return rsp;
}

interface CreateRecipeFoodRecipeRequest {
  id: number | null;
  foodId: number;
  grams: number;
  foodMeasureId: number;
  sortOrder: number;
}

interface CreateRecipeRequest {
  servings: number;
  recipe: {
    id: number | null;
    name: string;
    nameEn: string;
    description: string;
    descriptionEn?: string;
    isComplex: boolean;
    servings: number;
    reviewed: boolean;
    foodRecipe: CreateRecipeFoodRecipeRequest[];
    tags: number[];
    media: MediaDto | null;
  };
}

export interface CreateDietMealFoodRequest {
  foodId: number;
  grams: number;
  foodMeasureId: number;
}

export interface CreateDietMealRequest {
  id: number | null;
  name: string;
  nameEn: string;
  kcal: number;
  hour: string | null;
  description: string | null;
  descriptionEn: string | null;
  food: CreateDietMealFoodRequest[];
  recipes: CreateRecipeRequest[];
}

export interface CreateDietRequest {
  id: number | null;
  version: number | null;
  name: string;
  nameEn: string | null;
  isReviewed: boolean;
  tags: number[];
  targetNutrients: NutrientDto[];
  meals: CreateDietMealRequest[];
}

export interface CreateDietResponse {
  id: number;
}

const createDietResponseSchema = yup.object().shape({
  id: yup.number().required(),
});
