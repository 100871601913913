import { Chip, styled } from "@mui/material";
import { Dispatch, useMemo } from "react";
import { useAppTranslation } from "@hooks";
import { ProgramSearchPreview } from "@client/program/preview/fetchProgramPreview";

type TypeDaysChipsProps = {
  day: ProgramSearchPreview["programDays"][0];
  selectedDay: number;
  setSelectedDay: Dispatch<number>;
};

const TypeDayChip = ({
  day,
  selectedDay,
  setSelectedDay,
}: TypeDaysChipsProps) => {
  const { t, currentLanguage } = useAppTranslation();

  const name = useMemo(() => {
    const dayName =
      day.translations.find(t => t.lang === currentLanguage)?.name ?? day.name;

    const weeksDays = day.frequency.map(id =>
      t("program_preview.weekdays." + id),
    );
    return weeksDays.join(", ") + ` (${dayName})`;
  }, [day, currentLanguage]);

  return (
    <CustomChip
      key={day.id}
      label={name}
      variant={selectedDay === day.id ? "filled" : "outlined"}
      onClick={() => setSelectedDay(day.id)}
    />
  );
};

const CustomChip = styled(Chip)`
  line-height: 20px;
  font-size: 12px;
  letter-spacing: 0.4px;
  font-weight: 600;
  font-family: Figtree, serif;
  border: 1px solid #f5f1fe;
  background: #fff;
  color: #727272;
  .MuiChip-label {
    padding: 4px 8px;
  }
  &.MuiChip-filled {
    background: #f5f1fe;
    color: #7448d0;
    border-color: #7448d0;
  }
`;

export default TypeDayChip;
