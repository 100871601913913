import {
  BirthdayAnswerResourceDto,
  CheckboxAnswerResourceDto,
  CooperationGoalsAnswerResourceDto,
  DateAnswerResourceDto,
  DateTimeAnswerResourceDto,
  DictionariesDto,
  MeasurementAnswerResourceDto,
  PregnantWomanAnswerResourceDto,
  QuestionCheckboxDto,
  QuestionDateDto,
  QuestionEmptyDto,
  QuestionRadioDto,
  QuestionSelectDto,
  QuestionSliderDto,
  QuestionSwitchDto,
  QuestionTableDto,
  QuestionTextAreaDto,
  QuestionTextDto,
  QuestionType,
  RadioAnswerResourceDto,
  SelectAnswerResourceDto,
  SexAnswerResourceDto,
  SwitchAnswerResourceDto,
  SystemQuestionDto,
  TableAnswerResourceDto,
  TagAllergenAnswerResourceDto,
  TagDietTypeAnswerResourceDto,
  TextAnswerResourceDto,
  TextAreaAnswerResourceDto,
  TimeAnswerResourceDto,
  UserFatTissueAnswerResourceDto,
  UserHeightAnswerResourceDto,
  UserPreferencesFoodLikedAnswerResourceDto,
  UserPreferencesFoodUnlikedAnswerResourceDto,
  UserTargetBodyWeightAnswerResourceDto,
  UserWeightAnswerResourceDto,
} from "@client/surveys";
import { AnswerText } from "./AnswerText/AnswerText";
import { AnswerTextArea } from "./AnswerTextArea/AnswerTextArea";
import { AnswerRadio } from "./AnswerRadio/AnswerRadio";
import { AnswerCheckbox } from "./AnswerCheckbox/AnswerCheckbox";
import { AnswerDate } from "./AnswerDate";
import { AnswerTable } from "./AnswerTable";
import { SystemAnswerUserWeight } from "./SystemAnswerUserWeight/SystemAnswerUserWeight";
import { SystemAnswerUserHeight } from "./SystemAnswerUserHeight/SystemAnswerUserHeight";
import { SystemAnswerUserFatTissue } from "./SystemAnswerUserFatTissue/SystemAnswerUserFatTissue";
import { SystemAnswerMeasurements } from "./SystemAnswerMeasurements";
import { SystemAnswerUserBirthday } from "./SystemAnswerUserBirthday";
import { SystemAnswerCooperationGoals } from "./SystemAnswerCooperationGoals";
import { SystemAnswerUserBodyTargetWeight } from "./SystemAnswerUserTargetBodyWeight";
import { SystemAnswerPregnantWoman } from "./SystemAnswerPregnantWoman";
import { SystemAnswerSex } from "./SystemAnswerSex";
import { SystemAnswerUserPreferencesFoodUnliked } from "./SystemAnswerUserPreferencesFoodUnliked";
import { SystemAnswerUserPreferencesFoodLiked } from "./SystemAnswerUserPreferencesFoodLiked";
import { SystemAnswerTagAllergen } from "./SystemAnswerTagAllergen";
import { SystemAnswerTagDietType } from "./SystemAnswerTagDietType";

interface AnswerFactoryProps {
  question:
    | QuestionTextDto
    | QuestionTextAreaDto
    | QuestionCheckboxDto
    | QuestionRadioDto
    | QuestionDateDto
    | QuestionSwitchDto
    | QuestionSelectDto
    | QuestionSliderDto
    | QuestionTableDto
    | SystemQuestionDto
    | QuestionEmptyDto;
  answer?:
    | TextAnswerResourceDto
    | TextAreaAnswerResourceDto
    | CheckboxAnswerResourceDto
    | RadioAnswerResourceDto
    | SwitchAnswerResourceDto
    | DateAnswerResourceDto
    | TimeAnswerResourceDto
    | DateTimeAnswerResourceDto
    | SelectAnswerResourceDto
    | TableAnswerResourceDto
    | UserWeightAnswerResourceDto
    | UserHeightAnswerResourceDto
    | UserFatTissueAnswerResourceDto
    | MeasurementAnswerResourceDto
    | BirthdayAnswerResourceDto
    | CooperationGoalsAnswerResourceDto
    | UserTargetBodyWeightAnswerResourceDto
    | PregnantWomanAnswerResourceDto
    | SexAnswerResourceDto
    | UserPreferencesFoodUnlikedAnswerResourceDto
    | UserPreferencesFoodLikedAnswerResourceDto
    | TagAllergenAnswerResourceDto
    | TagDietTypeAnswerResourceDto;
  listIndex: number;
  totalQuestions: number;
  dictionaires: DictionariesDto | undefined;
}

export const AnswerFactory = ({
  question,
  answer,
  dictionaires,
  ...rest
}: AnswerFactoryProps) => {
  const checkType = (type: QuestionType) =>
    question.type === type && (answer === undefined || answer.type === type);

  if (checkType(QuestionType.TEXT))
    return (
      <AnswerText
        question={question as QuestionTextDto}
        answer={answer as TextAnswerResourceDto}
        {...rest}
      />
    );

  if (checkType(QuestionType.TEXTAREA))
    return (
      <AnswerTextArea
        question={question as QuestionTextAreaDto}
        answer={answer as TextAreaAnswerResourceDto}
        {...rest}
      />
    );

  if (checkType(QuestionType.RADIO))
    return (
      <AnswerRadio
        question={question as QuestionRadioDto}
        answer={answer as RadioAnswerResourceDto}
        {...rest}
      />
    );

  if (checkType(QuestionType.CHECKBOX))
    return (
      <AnswerCheckbox
        question={question as QuestionCheckboxDto}
        answer={answer as CheckboxAnswerResourceDto}
        {...rest}
      />
    );

  if (checkType(QuestionType.DATE))
    return (
      <AnswerDate
        question={question as QuestionDateDto}
        answer={answer as DateAnswerResourceDto}
        {...rest}
      />
    );

  if (checkType(QuestionType.TABLE))
    return (
      <AnswerTable
        question={question as QuestionTableDto}
        answer={answer as TableAnswerResourceDto}
        {...rest}
      />
    );

  if (checkType(QuestionType.SYSTEM_USER_WEIGHT))
    return (
      <SystemAnswerUserWeight
        question={question as SystemQuestionDto}
        answer={answer as UserWeightAnswerResourceDto}
        {...rest}
      />
    );

  if (checkType(QuestionType.SYSTEM_USER_HEIGHT))
    return (
      <SystemAnswerUserHeight
        question={question as SystemQuestionDto}
        answer={answer as UserHeightAnswerResourceDto}
        {...rest}
      />
    );

  if (checkType(QuestionType.SYSTEM_USER_FAT_TISSUE))
    return (
      <SystemAnswerUserFatTissue
        question={question as SystemQuestionDto}
        answer={answer as UserFatTissueAnswerResourceDto}
        {...rest}
      />
    );

  if (checkType(QuestionType.SYSTEM_MEASUREMENTS))
    return (
      <SystemAnswerMeasurements
        question={question as SystemQuestionDto}
        answer={answer as MeasurementAnswerResourceDto}
        {...rest}
      />
    );

  if (checkType(QuestionType.SYSTEM_USER_BIRTHDAY))
    return (
      <SystemAnswerUserBirthday
        question={question as SystemQuestionDto}
        answer={answer as BirthdayAnswerResourceDto}
        {...rest}
      />
    );

  if (checkType(QuestionType.SYSTEM_COOPERATION_GOALS))
    return (
      <SystemAnswerCooperationGoals
        question={question as SystemQuestionDto}
        answer={answer as CooperationGoalsAnswerResourceDto}
        options={dictionaires?.systemCooperationGoals}
        {...rest}
      />
    );

  if (checkType(QuestionType.SYSTEM_USER_TARGET_BODY_WEIGHT))
    return (
      <SystemAnswerUserBodyTargetWeight
        question={question as SystemQuestionDto}
        answer={answer as UserTargetBodyWeightAnswerResourceDto}
        {...rest}
      />
    );

  if (checkType(QuestionType.SYSTEM_PREGNANT_WOMAN))
    return (
      <SystemAnswerPregnantWoman
        question={question as SystemQuestionDto}
        answer={answer as PregnantWomanAnswerResourceDto}
        {...rest}
      />
    );

  if (checkType(QuestionType.SYSTEM_SEX))
    return (
      <SystemAnswerSex
        question={question as SystemQuestionDto}
        answer={answer as SexAnswerResourceDto}
        {...rest}
      />
    );

  if (checkType(QuestionType.SYSTEM_USER_PREFERENCES_FOOD_UNLIKED))
    return (
      <SystemAnswerUserPreferencesFoodUnliked
        question={question as SystemQuestionDto}
        answer={answer as UserPreferencesFoodUnlikedAnswerResourceDto}
        options={dictionaires?.foodGroups}
        {...rest}
      />
    );

  if (checkType(QuestionType.SYSTEM_USER_PREFERENCES_FOOD_LIKED))
    return (
      <SystemAnswerUserPreferencesFoodLiked
        question={question as SystemQuestionDto}
        answer={answer as UserPreferencesFoodLikedAnswerResourceDto}
        options={dictionaires?.foodGroups}
        {...rest}
      />
    );

  if (checkType(QuestionType.SYSTEM_TAG_ALLERGEN))
    return (
      <SystemAnswerTagAllergen
        question={question as SystemQuestionDto}
        answer={answer as TagAllergenAnswerResourceDto}
        options={dictionaires?.systemTagAllergen}
        {...rest}
      />
    );

  if (checkType(QuestionType.SYSTEM_TAG_DIET_TYPE))
    return (
      <SystemAnswerTagDietType
        question={question as SystemQuestionDto}
        answer={answer as TagDietTypeAnswerResourceDto}
        options={dictionaires?.systemTagDietType}
        {...rest}
      />
    );

  return <></>;
};
