import React from "react";
import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Calendar = (props: SvgIconProps) => (
  <SvgIcon {...props} className="calendarIcon" viewBox="0 0 20 20">
    <g id="w" transform="translate(1 2.326)" fill="none">
      <path
        d="M4.291,0h9.036c2.768,0,4.289,1.734,4.281,4.964V12.7c0,3.236-1.527,4.979-4.291,4.979H4.291C1.536,17.674,0,15.894,0,12.6V4.966C0,1.739,1.53,0,4.291,0Z"
        stroke="none"
      />
      <path
        d="M 4.291001319885254 1.5 C 3.35444164276123 1.5 2.68446159362793 1.747319221496582 2.242771148681641 2.256098747253418 C 1.756851196289062 2.815829277038574 1.500001907348633 3.753059387207031 1.500001907348633 4.966468811035156 L 1.500001907348633 12.59915924072266 C 1.500001907348633 13.85065937042236 1.761200904846191 14.81943893432617 2.25537109375 15.40076923370361 C 2.697781562805176 15.92120933532715 3.363641738891602 16.17419815063477 4.291001319885254 16.17419815063477 L 13.31705093383789 16.17419815063477 C 14.25384140014648 16.17419815063477 14.92373085021973 15.92638874053955 15.36498165130615 15.41661930084229 C 15.85113143920898 14.85498905181885 16.10809135437012 13.91409873962402 16.10809135437012 12.69566917419434 L 16.10809135437012 4.959678649902344 C 16.11041069030762 4.059429168701172 15.96891117095947 2.841678619384766 15.2816915512085 2.15269947052002 C 14.84970092773438 1.719598770141602 14.19203186035156 1.5 13.32694149017334 1.5 L 4.291001319885254 1.5 M 4.291001319885254 0 L 13.32694149017334 0 C 16.09471130371094 0 17.61638069152832 1.734079360961914 17.60809135437012 4.963528633117676 L 17.60809135437012 12.69566917419434 C 17.60809135437012 15.93136882781982 16.08118057250977 17.67419815063477 13.31705093383789 17.67419815063477 L 4.291001319885254 17.67419815063477 C 1.536340713500977 17.67419815063477 1.9073486328125e-06 15.89411926269531 1.9073486328125e-06 12.59915924072266 L 1.9073486328125e-06 4.966468811035156 C 1.9073486328125e-06 1.738509178161621 1.529840469360352 0 4.291001319885254 0 Z"
        stroke="none"
        fill={props.fill ? props.fill : "currentColor"}
      />
    </g>
    <g id="z" transform="translate(5.646 0)">
      <path
        id="Line_202"
        d="M.961,0A.957.957,0,0,1,1.09,1.9l-.129.009A.957.957,0,0,1,.821.009Z"
        transform="translate(6.285 8.279)"
        fill={props.fill ? props.fill : "currentColor"}
      />
      <path
        id="Line_203"
        d="M.961,0A.957.957,0,0,1,1.09,1.9l-.141.009A.957.957,0,0,1,.821.009Z"
        transform="translate(0 8.279)"
        fill={props.fill ? props.fill : "currentColor"}
      />
      <path
        id="Line_205"
        d="M.961,0A.957.957,0,0,1,1.09,1.9l-.129.009A.957.957,0,0,1,.821.009Z"
        transform="translate(6.285 13.238)"
        fill={props.fill ? props.fill : "currentColor"}
      />
      <path
        id="Line_206"
        d="M.961,0A.957.957,0,0,1,1.09,1.9l-.141.009A.957.957,0,0,1,.821.009Z"
        transform="translate(0 13.238)"
        fill={props.fill ? props.fill : "currentColor"}
      />
      <path
        id="Line_207"
        d="M.949,0A.952.952,0,0,1,1.89.827L1.9.957v4.2a.949.949,0,0,1-1.89.13L0,5.155V.957A.953.953,0,0,1,.949,0Z"
        transform="translate(6.539 0)"
        fill={props.fill ? props.fill : "currentColor"}
      />
      <path
        id="Line_208"
        d="M.949,0A.952.952,0,0,1,1.89.827L1.9.957v4.2a.949.949,0,0,1-1.89.13L0,5.155V.957A.953.953,0,0,1,.949,0Z"
        transform="translate(0.039 0)"
        fill={props.fill ? props.fill : "currentColor"}
      />
    </g>
  </SvgIcon>
);
