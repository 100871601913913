import { useAppTranslation, useClientParams } from "@hooks";
import {
  ChipItemsWrapper,
  SectionItemChips,
  SectionItemLabel,
  SectionItemLayout,
  TabSectionLayout,
} from "@views/dietician/ClientHealth/ClientHealthNew.styled";
import useFetchPatientShowNutrients from "@hooks/queries/client/nutrient/useFetchPatientShowNutrients";
import { Chip } from "@mui/material";

const DisplayForCustomerItemSection = () => {
  const { t } = useAppTranslation();
  const id = useClientParams();
  const { data } = useFetchPatientShowNutrients(
    { patientId: id },
    { staleTime: Infinity },
  );

  return (
    <SectionItemLayout>
      <SectionItemLabel>
        {t(
          "patient.health_and_goal.dietary_profile_tab.nutritional_values_section.visible_to_client",
        )}
      </SectionItemLabel>
      <SectionItemChips>
        {data?.data.kcal && (
          <Chip
            color="primary"
            label={t(
              "patient.health_and_goal.dietary_profile_tab.nutritional_values_section.kcal",
            )}
          />
        )}
        {data?.data.macro && (
          <Chip
            color="primary"
            label={t(
              "patient.health_and_goal.dietary_profile_tab.nutritional_values_section.macro",
            )}
          />
        )}
        {data?.data.targetMeals && (
          <Chip
            color="primary"
            label={t(
              "patient.health_and_goal.dietary_profile_tab.nutritional_values_section.meals_target_option",
            )}
          />
        )}
        {data?.data.micro && (
          <Chip
            color="primary"
            label={t(
              "patient.health_and_goal.dietary_profile_tab.nutritional_values_section.micro",
            )}
          />
        )}
        {data?.data.important && (
          <Chip
            color="primary"
            label={t(
              "patient.health_and_goal.dietary_profile_tab.nutritional_values_section.important",
            )}
          />
        )}
      </SectionItemChips>
    </SectionItemLayout>
  );
};

export default DisplayForCustomerItemSection;
