import { useMutation, useQueryClient } from "@tanstack/react-query";

import { postToggleFavoriteProgram } from "@client";

import { MutationOptions } from "./types";
import { fetchProgramQueryKey } from "./useFetchProgramQuery";
import { searchProgramsQueryKeyNew } from "./useSearchProgramsQueryNew";

export const usePostToggleFavoriteProgramMutation = (
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, string>(
    programId => postToggleFavoriteProgram(programId),
    {
      ...options,
      onSuccess: (d, programId) => {
        options?.onSuccess && options.onSuccess(d, programId);
        queryClient.invalidateQueries([searchProgramsQueryKeyNew]);
        queryClient.invalidateQueries([
          fetchProgramQueryKey,
          Number(programId),
        ]);
      },
    },
  );
};
