import { ReactNode } from "react";
import { BackgroundsImg, PacmanBackground } from "./AlloweatLayout.styled";
import pacmanLeft from "@assets/img/pacmanBackgroundLeft.png";
import pacmanRight from "@assets/img/pacmanBackgroundRight.png";
import { ThemeProviderWrapperNew } from "themeNew";

export const AlloweatLayout = (props: { children: ReactNode }) => {
  return (
    <ThemeProviderWrapperNew>
      <PacmanBackground>
        <BackgroundsImg src={pacmanLeft} />
        <BackgroundsImg src={pacmanRight} />
      </PacmanBackground>
      {props.children}
    </ThemeProviderWrapperNew>
  );
};
