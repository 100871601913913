import { useEffect, useMemo } from "react";
import { Path, useController } from "react-hook-form";

import { useAppParams, useAppTranslation, useMealClientContext } from "@hooks";
import { useFetchDietsProductsForReplacementQuery } from "@hooks/queries/diets";
import { getTranslation } from "@utils/translations";

import { CustomOption } from "./CustomOption";
import { SearchAutocomplete } from "./SearchAutocomplete";
import { ExchangeProductFormInput } from "./useExchangeProductForm";
import { useSelectedContext } from "./SelectedContext/SelectedContext";
import { StyledPopper } from "./SearchAutocomplete.styled";
import { Alert } from "@mui/material";

interface SearchDietProductsAutocompleteProps {
  idx: number;
}
export const SearchDietProductsAutocomplete = ({
  idx,
}: SearchDietProductsAutocompleteProps) => {
  const { currentLanguage, isPolishChosen } = useAppTranslation();
  const { dietId } = useAppParams();
  const { setSelectedCurrent, selectedDietProducts } = useSelectedContext();
  const { data, status } = useFetchDietsProductsForReplacementQuery(dietId);
  const {
    field: { value, onChange },
  } = useController<ExchangeProductFormInput, `products.${number}.fromProduct`>(
    { name: `products.${idx}.fromProduct` },
  );
  const { productsClientContextMap, getSeverity, getProducts } =
    useMealClientContext();
  const clientContext = useMemo(
    () => (value ? productsClientContextMap.get(value) : undefined),
    [productsClientContextMap, value],
  );
  const { color, alert } = getSeverity(clientContext ?? {});

  const optionsMapped = useMemo(
    () =>
      data?.flatMap(category =>
        category.products.map(product => ({
          id: product.id.toString(),
          label: getTranslation(product.translations, currentLanguage),
          nutrients: product.nutrients,
          category:
            category.mainCategory[
              isPolishChosen ? "descriptionPl" : "description"
            ],
          clientContext: productsClientContextMap.get(product.id),
        })),
      ) ?? [],
    [data, currentLanguage],
  );

  const selected = useMemo(
    () => optionsMapped.find(o => value && o.id === value.toString()),
    [optionsMapped, value],
  );

  const handleChange = (id: number) => {
    onChange(id);
    getProducts([id]);
  };

  useEffect(() => {
    const details = data
      ?.flatMap(c => c.products)
      .find(p => p.id.toString() === selected?.id);
    setSelectedCurrent(details ?? null);
  }, [selected]);

  return (
    <SearchAutocomplete
      key={status}
      options={optionsMapped}
      groupBy={o => o.category ?? ""}
      getOptionDisabled={o => selectedDietProducts.includes(Number(o.id))}
      onChange={(e, v) => handleChange(Number(v.id))}
      value={selected}
      renderOption={CustomOption}
      grams={selected ? 100 : 0}
      filterOptions={undefined}
      PopperComponent={StyledPopper}
      helperText={alert && <Alert severity={color}>{alert}</Alert>}
    />
  );
};
