import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { boolean, InferType, object } from "yup";

const resolver = object({
  cancelConfirmation: boolean().defined(),
});

type FormSchema = InferType<typeof resolver>;

export const useScheduleActionModalForm = () => {
  return useForm<FormSchema>({
    mode: "onChange",
    resolver: yupResolver(resolver),
    defaultValues: {
      cancelConfirmation: false,
    },
  });
};
