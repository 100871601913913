import React from "react";
import { ProductMeasureSchema } from "./ProductMeasureSchema";
import { ProductMeasureHead } from "./ProductMeasureHead";
import { ProductMeasureShow } from "./ProductMeasureShow";
import { useAppTranslation } from "@hooks";

export const ProductMeasuresShow: React.FC<ProductMeasuresShowProps> = ({
  measures,
}) => {
  const { t } = useAppTranslation();

  return (
    <div className={"flex flex-col gap-4"}>
      <table
        className={"table-fixed border-separate"}
        style={{ borderSpacing: "1rem" }}
      >
        <thead>
          <ProductMeasureSchema
            measure={<ProductMeasureHead text={t("products.measure")} />}
            grams={<ProductMeasureHead text={t("products.grams")} />}
          />
        </thead>
        <tbody>
          {measures.map((field, index) => (
            <ProductMeasureShow key={index} id={field.id} grams={field.grams} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export interface ProductMeasuresShowProps {
  measures: Measure[];
}

interface Measure {
  id: number;
  grams: number;
}
