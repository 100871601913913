import useProgramDate from "@hooks/program/useProgramDate";
import { useMemo } from "react";
import { RowType } from "@views/dietician/PatientProgramChoice/PatientProgramChoiceGrid";
import { useAppTranslation } from "@hooks";
import { Clock } from "@assets/icons";
import { TagWithIcon } from "@components/TagWithIcon";

type DurationProps = {
  program: Pick<RowType, "startDate" | "endDate">;
};
const Duration = ({ program }: DurationProps) => {
  const { t } = useAppTranslation();
  const { start, end } = useProgramDate({
    startDate: program.startDate,
    endDate: program.endDate,
  });

  const duration = useMemo(() => {
    if (!end) {
      return t("programs.indefinite");
    }

    const days = Math.abs(Math.round(start.diff(end, "day", true)));
    return t("programs.days", { count: days });
  }, [start, end, t]);

  return <TagWithIcon Icon={Clock} label={duration} />;
};

export default Duration;
