import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
} from "@mui/material";

export const AccordionStyled = styled(Accordion)`
  &.MuiAccordion-root {
    border-radius: 16px;
    border: 1px solid ${({ theme }) => theme.colors.neutral.light[200]};
    background: ${({ theme }) => theme.colors.neutral.light[100]};
    box-shadow: none;

    &.MuiPaper-root.MuiAccordion-root.Mui-expanded {
      margin: 0;
    }
  }

  :before {
    display: none;
  }
`;

export const AccordionDetailsStyled = styled(AccordionDetails)`
  padding: 16px;
`;

export const AccordionSummaryStyled = styled(AccordionSummary)`
  &.MuiButtonBase-root.MuiAccordionSummary-root {
    flex-direction: row-reverse;
    gap: 8px;
  }
  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
  }
  & .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
  &.MuiAccordionSummary-root {
    min-height: 48px;
  }
  & .MuiAccordionSummary-content {
    margin: 0;
    justify-content: space-between;
    align-items: center;
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallMax}px) {
    &.MuiButtonBase-root.MuiAccordionSummary-root {
      align-items: flex-start;
    }
    & .MuiAccordionSummary-expandIconWrapper {
      padding: 4px;
    }
    &.MuiButtonBase-root.MuiAccordionSummary-root {
      padding: 16px;
    }
  }
`;

export const Text = styled("span")`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
`;

export const SmallText = styled("span")`
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

interface TitleProps {
  gray?: boolean;
}

export const Title = styled("span")<TitleProps>`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ gray, theme }) =>
    gray ? theme.colors.neutral.dark[700] : theme.colors.neutral.dark[800]};
`;
