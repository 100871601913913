import { useQuery } from "@tanstack/react-query";

import {
  FetchClientFoodGroupPreferencesResponse,
  fetchClientFoodGroupPreferences,
} from "@client";
import { ApiResponse } from "@typeDefinitions";

import { QueryOptionsTyped } from "./types";

export const fetchClientFoodGroupPreferencesQueryKey =
  "fetchClientFoodGroupPreferencesQueryKey";

export const useFetchClientFoodGroupPreferences = (
  id: string,
  options?: QueryOptionsTyped<
    ApiResponse<FetchClientFoodGroupPreferencesResponse[]>
  >,
) => {
  const { data, ...rest } = useQuery(
    [fetchClientFoodGroupPreferencesQueryKey, id],
    () => fetchClientFoodGroupPreferences(id),
    options,
  );

  return {
    clientFoodGroupPreferences: data?.data,
    ...rest,
  };
};
