import { useMemo, useState } from "react";

import { TabContext } from "@mui/lab";
import { CircularProgress, ClickAwayListener, useTheme } from "@mui/material";
import { round } from "lodash";

import { Clock, Cutlery } from "@assets/icons";
import { Carrot } from "@assets/icons/DesignSystem";
import { useAppTranslation, useFoodGroup, useNutrients } from "@hooks";
import { usePostToggleFavoriteFoodMutation } from "@hooks/queries";
import { useFetchProductQuery } from "@hooks/queries/food";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";
import { useTagsNew } from "@hooks/useTagsNew";
import { ENERGY_ID, buildNutrientsDict } from "@utils";
import { TagCategoryType } from "@utils/tagsNew";

import { DetailsSection } from "../common/DetailsSection";
import { PreviewActionButtons } from "../common/PreviewActionButtons";
import { NutrientsTab } from "./NutrientsTab";
import {
  DetailsWrapper,
  Label,
  LoadingWrapper,
  TabPanelStyled,
  TabStyled,
  TabsStyled,
  TagIconWrapper,
  Wrapper,
} from "./ProductPreview.styled";
import { TagsTab } from "./TagsTab";
import { TabsWrapper } from "../common/TabsWrapper";
import { ProductTabPanels } from "./ProductTabPanels";

interface ProductPreviewProps {
  productId: number | null;
  onClose: () => void;
}

export const ProductPreview = ({ productId, onClose }: ProductPreviewProps) => {
  const { isPolishChosen, t } = useAppTranslation();
  const { account } = useFetchDietitianAccountQuery();
  const { product, isLoading } = useFetchProductQuery(productId ?? 0, {
    enabled: !!productId,
  });
  const { mutate, isLoading: isLoadingToggle } =
    usePostToggleFavoriteFoodMutation();

  const [tab, setTab] = useState(ProductTabs.NUTRITIONAL_VALUES);

  const { tagCategoryDictionary, tagNameDictionary } = useTagsNew();
  const { nutrientDict } = useNutrients();
  const { foodGroupDict } = useFoodGroup();
  const {
    colors: { neutral },
  } = useTheme();
  const difficultyOfPreparationTagList = tagCategoryDictionary.get(
    TagCategoryType.DIFFICULTY_OF_PREPARATION,
  )?.tags;
  const preparationTimeTagList = tagCategoryDictionary.get(
    TagCategoryType.PREPARATION_TIME,
  )?.tags;

  const difficultyOfPreparationTag = product?.tags?.find(tag =>
    difficultyOfPreparationTagList?.some(t => t.id === tag.id),
  );
  const preparationTimeTag = product?.tags?.find(tag =>
    preparationTimeTagList?.some(t => t.id === tag.id),
  );

  const productNutrientsDict = useMemo(
    () => buildNutrientsDict(product?.nutrients ?? []),
    [product],
  );

  const roundedKcal = useMemo(
    () =>
      round(
        productNutrientsDict.get(ENERGY_ID) ?? 0,
        nutrientDict.get(ENERGY_ID)?.roundPrecision,
      ),
    [productNutrientsDict, nutrientDict],
  );

  const isFavorite = useMemo(
    () => !!product?.favorite.includes(account?.id ?? 0),
    [product],
  );

  const categoryString = useMemo(() => {
    if (!product?.foodGroup.id) return "";

    const categoryDetails = foodGroupDict.get(product.foodGroup.id.toString());
    const parents = categoryDetails?.parents ?? [];
    return [...parents, categoryDetails?.title].join(" ➔ ");
  }, [product, foodGroupDict]);

  const tabs = useMemo(
    () => [
      {
        label: t("common.nutrients_values"),
        value: ProductTabs.NUTRITIONAL_VALUES,
      },
      {
        label: t("common.tags"),
        value: ProductTabs.TAGS,
      },
    ],
    [t],
  );

  if (isLoading)
    return (
      <Wrapper>
        <LoadingWrapper>
          <CircularProgress />;
        </LoadingWrapper>
      </Wrapper>
    );

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Wrapper>
        <TabContext value={tab}>
          <DetailsWrapper>
            <DetailsSection
              titlePl={product?.descriptionPl ?? ""}
              titleEn={product?.description ?? ""}
              kcal={roundedKcal}
              actions={
                <PreviewActionButtons
                  onToggle={() => mutate(productId?.toString() ?? "")}
                  isLoading={isLoadingToggle}
                  isFavorite={isFavorite}
                  actions={product?.actions}
                  editLink={`/products/${productId}/edit`}
                  copyLink={`/products/${productId}/copy`}
                  onClose={onClose}
                />
              }
              tags={
                <>
                  {!!preparationTimeTag && (
                    <TagIconWrapper>
                      <Clock fill={neutral.dark[700]} />
                      <Label>
                        {tagNameDictionary.get(Number(preparationTimeTag.id))}
                      </Label>
                    </TagIconWrapper>
                  )}
                  {!!difficultyOfPreparationTag && (
                    <TagIconWrapper>
                      <Cutlery fill={neutral.dark[700]} />
                      <Label>
                        {tagNameDictionary.get(
                          Number(difficultyOfPreparationTag.id),
                        )}
                      </Label>
                    </TagIconWrapper>
                  )}

                  {!!categoryString && (
                    <TagIconWrapper>
                      <Carrot
                        fill={neutral.dark[700]}
                        size="w-[16px] h-[16px]"
                      />
                      <Label>{categoryString}</Label>
                    </TagIconWrapper>
                  )}

                  {!!product?.producer && (
                    <Label>
                      {t("product.producent")}: {product?.producer}
                    </Label>
                  )}
                </>
              }
            />

            <TabsWrapper onChange={v => setTab(v)} tabs={tabs} value={tab} />
          </DetailsWrapper>

          <ProductTabPanels
            nutrients={product?.nutrients ?? []}
            measures={product?.measures ?? []}
            tags={product?.tags.map(t => t.id) ?? []}
            producer={product?.producer}
            shops={product?.shops}
          />
        </TabContext>
      </Wrapper>
    </ClickAwayListener>
  );
};

export enum ProductTabs {
  NUTRITIONAL_VALUES = "0",
  TAGS = "1",
}
