import { IconButton, styled } from "@mui/material";

export const ContainerDays = styled("div")`
  display: flex;
  flex-direction: row;
  background: ${({ theme }) => theme.colors.neutral.light[100]};
  overflow-x: auto;
  flex: 1;
  border: solid 1px ${({ theme }) => theme.palette.primary.medium};
  border-right: unset;
`;

export const ButtonContainer = styled("div")`
  background: #fff;
  display: flex;
  align-items: center;
  height: auto;
  border-top: solid 1px ${({ theme }) => theme.palette.primary.medium};
  border-bottom: solid 1px ${({ theme }) => theme.palette.primary.medium};
`;

export const ButtonContainerRight = styled(ButtonContainer)`
  border: solid 1px ${({ theme }) => theme.palette.primary.medium};
  border-bottom-right-radius: 12px;
  border-top-right-radius: 12px;
`;

export const LeftArrowButton = styled(IconButton)`
  transform: rotate(180deg);
` as typeof IconButton;
interface CalendarDayWrapperProps {
  onClick: () => void;
  selected: boolean;
}
export const CalendarDayWrapper = styled("div")(
  ({ selected }: CalendarDayWrapperProps) => ({
    background: selected ? "#F2F2FF" : "#FFF",
    padding: "4px 8px",
    flex: "1",
    borderRight: "1px solid #F5F1FE",
    borderTop: "1px solid #F5F1FE",
    borderLeft: "none",
    [":first-of-type"]: {
      marginLeft: "auto",
    },
    [":last-of-type"]: {
      marginRight: "auto",
    },
  }),
);

export const CalendarDayTitle = styled("span")`
  line-height: 20px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
  font-family: Figtree, serif;
  font-style: normal;
  font-weight: 500;
  padding: 8px 8px;
  border-right: solid 1px ${({ theme }) => theme.palette.primary.medium};
  cursor: pointer;
`;
