import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";
import { usePostCreatorMealCloneMutation } from "@hooks/queries/meals";
import { useAppParams } from "@hooks";

export const MealCopy = () => {
  const [init, setInit] = useState(true);
  const navigate = useNavigate();
  const { mealId } = useAppParams();
  const { mutate, isLoading, isError } = usePostCreatorMealCloneMutation({
    onSuccess: data => {
      setInit(false);
      navigate(`/meals/${data.data.id}/edit`, { replace: true });
    },
  });

  useEffect(() => {
    if (init) mutate(mealId);
  }, [init]);

  if (init || isLoading) return <Spinner />;

  if (isError) return <ApiError />;

  return <></>;
};
