import {
  getSectionCategoryName,
  getSectionsByCategoriesId,
} from "@components/ProgramSearchPreview/utils/ProgramSectionsUtil";
import { Tab } from "@mui/material";
import { SectionProgramPreviewSchema } from "@client/program/preview/fetchSectionsProgramPreview";
import { useProgramSectionCategories } from "@hooks/dictionary/useProgramSectionCategories";
import { useMemo } from "react";
import { useAppTranslation } from "@hooks";

type MaterialsTabsProps = {
  materials: SectionProgramPreviewSchema[];
  categoryId: number;
  value: string;
};

const MaterialTab = ({
  materials,
  categoryId,
  value,
  ...props
}: MaterialsTabsProps) => {
  const { currentLanguage } = useAppTranslation();
  const { find: findCategory } = useProgramSectionCategories();

  const filteredMaterials = useMemo(
    () => getSectionsByCategoriesId(materials, categoryId),
    [materials, categoryId],
  );

  const categoryName = useMemo(() => {
    const category = findCategory(categoryId);
    return getSectionCategoryName(category, currentLanguage);
  }, [categoryId, findCategory, currentLanguage]);

  return (
    <Tab
      value={categoryId.toString()}
      label={categoryName + ` (${filteredMaterials.length})`}
      {...props}
    />
  );
};

export default MaterialTab;
