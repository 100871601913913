import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "./types";
import {
  postSubscriptionPurchase,
  postSubscriptionPurchaseRequest,
} from "@client";
import { fetchDietitianAccountQueryKey } from "./settings";

export const usePostSubscriptionPurchase = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation<
    unknown,
    unknown,
    postSubscriptionPurchaseRequest
  >(payload => postSubscriptionPurchase(payload), {
    ...options,
    onSuccess: (d, v) => {
      queryClient.invalidateQueries([fetchDietitianAccountQueryKey]);
      options?.onSuccess && options.onSuccess(d, v);
    },
  });

  return {
    subscriptionPurchaseMutate: mutate,
    ...rest,
  };
};
