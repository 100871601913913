import { dateFnsLocalizer, EventPropGetter } from "react-big-calendar";

import { pl, enUS } from "date-fns/locale";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";

import { EventWithType } from "@typeDefinitions";

export const locales = {
  pl: pl,
  en: enUS,
};

export const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

export const formats = {
  dateFormat: "d",
  dayFormat: "EEEE",
  timeGutterFormat: "H:mm",
  dayHeaderFormat: "EEEE",
};

export const eventStyleGetter: (
  bordered?: boolean,
) => EventPropGetter<EventWithType> =
  (bordered = false) =>
  (
    event: EventWithType,
    // start: Date | undefined,
    // end: Date | undefined,
    // isSelected: boolean
  ) => ({
    style: {
      backgroundColor: bordered ? "transparent" : event.tintColor,
      color: event.color,
      borderTopLeftRadius: bordered ? 0 : undefined,
      borderBottomLeftRadius: bordered ? 0 : undefined,
      // opacity: 0.8,
      border: "0px",
      borderLeft: bordered ? `8px solid ${event.tintColor}` : undefined,
      display: "block",
    },
  });

export const min = new Date();
min.setHours(0, 0, 0);
export const max = new Date();
max.setHours(23, 59, 59);
