import { Dispatch, SetStateAction, useMemo } from "react";

import { TabContext } from "@mui/lab";
import { IconButton } from "@mui/material";
import { lowerFirst } from "lodash";

import { Close } from "@assets/icons/DesignSystem";
import { defaultFilters as productResetFilters } from "@components/ProductsFiltersNew/productsFiltersUtils";
import { useProductsFiltersForm } from "@components/ProductsFiltersNew/useProductsFiltersForm";
import {
  RecipesFiltersFormInput,
  useRecipesFiltersForm,
} from "@components/RecipesFiltersNew";
import { defaultFilters as recipeResetFilters } from "@components/RecipesFiltersNew/recipesFiltersUtils";
import { TabIf, TabSwitch } from "@components/TabSwitch";
import { useAppParams, useAppTranslation } from "@hooks";
import { useFetchClientTags } from "@hooks/queries";
import { TagSystemId } from "@utils/tagsNew";

import { ItemsListCallback } from "../ItemsContext";
import { ActionButtons } from "./ActionButtons";
import { Tabs, TotalIf } from "./AddElementSidePanel";
import {
  Body,
  Header,
  TabPanelStyled,
  Title,
} from "./AddElementSidePanel.styled";
import { CollectionsTab } from "./CollectionsTab";
import { ProductsTab } from "./ProductsTab";
import { RecipesTab } from "./RecipesTab";

interface DrawerContetProps {
  mealId: number;
  tab: Tabs;
  setTab: Dispatch<SetStateAction<Tabs>>;
  tabs: TabIf[];
  setTotal: Dispatch<SetStateAction<TotalIf>>;
  onClose: () => void;
  defaultFilters: RecipesFiltersFormInput;
  onSuccess: () => void;
  mealName: string;
  onAddOwnRecipe: () => void;
  dietId: string;
  onSubmit?: ItemsListCallback;
  loading?: boolean;
}

export const DrawerContent = ({
  mealId,
  tab,
  setTab,
  tabs,
  setTotal,
  onClose,
  defaultFilters,
  onSuccess,
  mealName,
  onAddOwnRecipe,
  dietId,
  onSubmit,
  loading,
}: DrawerContetProps) => {
  const { t } = useAppTranslation();
  const { patientId } = useAppParams();
  const patient = patientId ? Number(patientId) : undefined;
  const { clientTags } = useFetchClientTags(patientId, {
    enabled: !!patientId,
  });
  const dietTypeTags = useMemo(
    () =>
      clientTags
        ?.filter(tag => Object.values(TagSystemId).includes(tag.id))
        .map(tag => tag.id.toString()) ?? [],
    [clientTags],
  );

  const recipeDefaultFilters = useMemo(
    () => ({
      ...recipeResetFilters,
      ...defaultFilters,
      tags: defaultFilters.tags
        ? [...dietTypeTags, ...defaultFilters.tags]
        : dietTypeTags,
      patient,
    }),
    [recipeResetFilters, defaultFilters, dietTypeTags, patient],
  );
  const productDefaultFilters = useMemo(
    () => ({ ...productResetFilters, patient }),
    [productResetFilters, patient],
  );

  const formRecipes = useRecipesFiltersForm(recipeDefaultFilters);
  const formProducts = useProductsFiltersForm(productDefaultFilters);

  const recipeFilters = useMemo(() => {
    return formRecipes.getValues();
  }, [formRecipes.getValues()]);

  const productFilters = useMemo(() => {
    return formProducts.getValues();
  }, [formProducts.getValues()]);

  const closeWithReset = () => {
    onClose();
    formRecipes.reset({});
    formProducts.reset({});
  };
  return (
    <>
      <Header>
        <Title>
          {t("common.add")} {lowerFirst(mealName)}
        </Title>

        <IconButton size="small" onClick={closeWithReset}>
          <Close size="w-[12px] h-[12px]" />
        </IconButton>
      </Header>

      <Body>
        <div className="flex justify-between">
          <TabSwitch tabs={tabs} value={tab} onChange={setTab} />

          {/* <FormControlLabel label="Ukryj wykorzystane" control={<Checkbox />} /> */}
        </div>

        {mealId && (
          <TabContext value={tab}>
            <TabPanelStyled value={Tabs.RECIPES}>
              <RecipesTab
                form={formRecipes}
                setTotal={recipes => setTotal(state => ({ ...state, recipes }))}
                defaultFilters={recipeFilters}
                onAddOwnRecipe={onAddOwnRecipe}
                mealId={mealId}
              />
            </TabPanelStyled>
            <TabPanelStyled value={Tabs.PRODUCTS}>
              <ProductsTab
                form={formProducts}
                defaultFilters={productFilters}
                setTotal={products =>
                  setTotal(state => ({ ...state, products }))
                }
                mealId={mealId}
              />
            </TabPanelStyled>
            <TabPanelStyled value={Tabs.COLLECTIONS}>
              <CollectionsTab
                form={formRecipes}
                setTotal={collections =>
                  setTotal(state => ({ ...state, collections }))
                }
                defaultFilters={recipeFilters}
                mealId={mealId}
              />
            </TabPanelStyled>
          </TabContext>
        )}

        <ActionButtons
          onClose={closeWithReset}
          onSuccess={onSuccess}
          dietId={dietId}
          onSubmit={onSubmit}
          loading={loading}
        />
      </Body>
    </>
  );
};
