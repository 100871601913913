import { styled } from "@mui/material";
import { useAppTranslation } from "@hooks";
import { useMemo } from "react";
import { DietProgramSearchPreview } from "@client/program/preview/fetchDietProgramPreview";

type DietNameProps = {
  translations: DietProgramSearchPreview["translations"];
  name: DietProgramSearchPreview["name"];
};
const DietName = ({ translations, name }: DietNameProps) => {
  const { currentLanguage } = useAppTranslation();

  const value = useMemo(() => {
    const translation = translations.find(t => t.lang === currentLanguage);
    return translation?.name ?? name;
  }, [translations, name, currentLanguage]);

  return <Name>{value}</Name>;
};

const Name = styled("span")`
  line-height: 16px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.4px;
  font-family: Figtree, serif;
  color: #333333;
`;

export default DietName;
