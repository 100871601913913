import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { logFetchValidateError } from "@utils/validate";
import * as yup from "yup";
import {
  PatientProductExchangerResource,
  patientProductExchangerSchema,
} from "@client/resources/PatientProductExchangerResource";

export type FetchPatientProductExchangersParams = {
  patientId: number;
};

export type FetchPatientProductExchangersResponse = ApiResponse<
  PatientProductExchangerResource[]
>;

export const fetchPatientProductExchangers = async (
  params: FetchPatientProductExchangersParams,
): Promise<FetchPatientProductExchangersResponse> => {
  const response = await fetchData(
    "/dietitian/patients/{patient_id}/product-exchanger-type".replace(
      "{patient_id}",
      params.patientId.toString(),
    ),
    APIMethods.GET,
  );

  return await schema
    .validate(response)
    .catch(
      logFetchValidateError(
        "/dietitian/patients/{patient_id}/product-exchanger-type",
      ),
    );
};

const schema = yup.object({
  data: yup.array().of(patientProductExchangerSchema).defined(),
});
