import { BodyMeasurementConst } from "@consts/BodyMeasurementConst";
import { FormPops } from "@components/Client/FormBodyMeasurementsModal/UpdateBodyMeasurementsForm";
import * as yup from "yup";
import { lazy } from "yup";
import { mapObjectDynamicFieldsRules } from "@utils/yup";

export const mapFormDataToBodyRequest = (data: FormPops) => {
  const getMeasurement = (measurement: number) =>
    data.measurementBody[BodyMeasurementConst.height]
      ? parseFloat(data.measurementBody[measurement])
      : null;

  return {
    date: data.date.format("YYYY-MM-DD"),
    growth: getMeasurement(BodyMeasurementConst.height),
    weight: getMeasurement(BodyMeasurementConst.weight),
    bodyFatLevel: getMeasurement(BodyMeasurementConst.bodyFatLevel),
    arm: getMeasurement(BodyMeasurementConst.arm),
    tightBiceps: getMeasurement(BodyMeasurementConst.tightBiceps),
    waist: getMeasurement(BodyMeasurementConst.waist),
    hip: getMeasurement(BodyMeasurementConst.hip),
    thigh: getMeasurement(BodyMeasurementConst.thigh),
    calf: getMeasurement(BodyMeasurementConst.calf),
    water: getMeasurement(BodyMeasurementConst.water),
    bodyFatMass: getMeasurement(BodyMeasurementConst.bodyFatMass),
    skeletalMuscleMass: getMeasurement(BodyMeasurementConst.skeletalMuscleMass),
    boneTissueMass: getMeasurement(BodyMeasurementConst.boneTissueMass),
    chest: getMeasurement(BodyMeasurementConst.chest),
    abdominal: getMeasurement(BodyMeasurementConst.abdominal),
    bodyMeasurements: Object.keys(data.measurementBody)
      .map(bodyId => {
        return {
          id: parseInt(bodyId),
          value: data.measurementBody[bodyId]
            ? parseFloat(data.measurementBody[bodyId])
            : null,
        };
      })
      .filter(measurement => measurement.value != null),
  };
};

export const measurementSchema = yup
  .number()
  .transform((val, orig) => (orig == "" ? null : val))
  .nullable()
  .test(
    "is-decimal",
    "The amount should be a decimal with maximum two digits after comma",
    (val: any) => {
      if (val !== null) {
        return /^\d+(\.\d{0,2})?$/.test(val);
      }
      return true;
    },
  )
  .defined();
