import { styled } from "@mui/material";

export const DifferenceCellStyled = styled("div")`
  display: flex;
  border: 1px solid ${({ theme }) => theme.palette.primary.medium};
  border-radius: 8px;
  padding: 8px;
  background: ${({ theme }) => theme.palette.primary.light};
  height: 40px;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallMax}px) {
    width: 100%;
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.medium}px) {
    width: 260px;
  }
`;
