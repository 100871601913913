import { ProductInDietDto, fetchDietProductsList } from "@client/diets";
import { useQuery } from "@tanstack/react-query";
import { QueryOptionsTyped } from "../types";
import { ApiResponse } from "@typeDefinitions";

export const fetchDietProductsListQueryKey = "fetchDietProductsListQueryKey";

export const useFetchDietProductsListQuery = (
  dietId: string,
  options?: QueryOptionsTyped<ApiResponse<ProductInDietDto[]>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchDietProductsListQueryKey, dietId],
    () => fetchDietProductsList(dietId),
    options,
  );

  return {
    data: data?.data,
    ...rest,
  };
};
