import { PercentLabel } from "@views/dietician/PatientMonitoring2/components/MealsBox/MealBoxByDays.styled";
import { Box } from "@mui/material";

type PercentCircleProps = {
  percent: number;
};
const PercentCircle = ({ percent }: PercentCircleProps) => {
  return (
    <Box
      sx={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <PercentLabel>{percent} %</PercentLabel>
    </Box>
  );
};

export default PercentCircle;
