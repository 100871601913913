import * as yup from "yup";

export interface PatientHydrationProductsMonitoringResource {
  date: string;
  products: IrrigationProduct[];
  goal: number | null;
}

interface IrrigationProduct {
  id: number;
  product: {
    id: number;
  };
  milliliters: number;
}

const productSchema = yup.object({
  id: yup.number().required(),
  product: yup
    .object({
      id: yup.number().required(),
    })
    .required(),
  milliliters: yup.number().required(),
});

export const patientHydrationProductsMonitoringSchema = yup.object({
  date: yup.string().required(),
  products: yup.array().of(productSchema).defined(),
  goal: yup.number().nullable().defined(),
});
