import {
  DietNutrientsNormDto,
  fetchDietNutrientsNorm,
} from "@client/diets/creator";
import { QueryOptionsTyped } from "@hooks/queries/types";
import { useQuery } from "@tanstack/react-query";
import { ApiResponse } from "@typeDefinitions";

export const fetchDietNutrientsNormQueryKey = "fetchDietNutrientsNormQueryKey";

export const useFetchDietNutrientsNormQuery = (
  dietId: string,
  options?: QueryOptionsTyped<ApiResponse<DietNutrientsNormDto>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchDietNutrientsNormQueryKey, dietId],
    () => fetchDietNutrientsNorm(dietId),
    options,
  );

  return {
    norm: data?.data,
    ...rest,
  };
};
