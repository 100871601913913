import { useEffect, useMemo, useState } from "react";

export function usePagination(params: URLSearchParams) {
  const [page, setPage] = useState<string | null>();

  const handlePaginationClick = (url: string | null) => {
    if (!url) return;
    const params = new URLSearchParams(url.split("?")[1]);
    const page = params.get("page");
    if (page) setPage(page);
  };

  useEffect(() => setPage(null), [params]);

  const paginatedParams = useMemo(() => {
    if (page) {
      const paginatedParams = new URLSearchParams(params);
      paginatedParams.append("page", page);
      return paginatedParams;
    }
    return params;
  }, [params, page]);

  return { handlePaginationClick, paginatedParams };
}
