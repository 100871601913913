import useNewMobileAppFeatureEnabled from "@features/useNewMobileAppFeatureEnabled";
import { ReactElement, ReactNode } from "react";

type Props = {
  newVersion: ReactElement | null;
  oldVersion: ReactElement | null;
};
const NewMobileAppFeatureRoute = ({ newVersion, oldVersion }: Props) => {
  const newMobileAppFeatureEnabled = useNewMobileAppFeatureEnabled();

  return newMobileAppFeatureEnabled ? newVersion : oldVersion;
};

export default NewMobileAppFeatureRoute;
