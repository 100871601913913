import { QuestionRadioDto, RadioAnswerResourceDto } from "@client/surveys";
import { useAppTranslation } from "@hooks";

import { OuterLabel } from "@components/OuterLabel";

import { QuestionPreview } from "../../QuestionPreview";
import { TextStyled } from "./AnswerRadio.styled";

interface AnswerRadioProps {
  question: QuestionRadioDto;
  answer?: RadioAnswerResourceDto;
  listIndex: number;
  totalQuestions: number;
}

export const AnswerRadio = ({
  answer,
  question,
  listIndex,
  totalQuestions,
}: AnswerRadioProps) => {
  const { title, description, config } = question;
  const { t } = useAppTranslation();

  return (
    <QuestionPreview
      title={title}
      description={description}
      listIndex={listIndex}
      totalQuestions={totalQuestions}
    >
      <OuterLabel label={t("questionnaires.questions.answer")}>
        {config.options.map(o => (
          <TextStyled bold={o.uuid === answer?.answer.uuid}>
            {o.name}
          </TextStyled>
        ))}
        {config.other?.uuid && (
          <TextStyled bold={config.other.uuid === answer?.answer.uuid}>
            {config.other?.name}: {answer?.answer.text}
          </TextStyled>
        )}
      </OuterLabel>
    </QuestionPreview>
  );
};
