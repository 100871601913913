import { ReactElement, ReactNode } from "react";
import { useModal } from "@hooks";
import { ClickAwayListener, TooltipProps } from "@mui/material";
import { MuiWhiteTooltip } from "@components/MuiButtonWithTooltip";

import { StyledIconButton } from "./MuiInfoClickTooltip.styled";
import { Info } from "@assets/icons";

interface MuiClickTooltipProps {
  content: string | ReactNode;
  placement?: TooltipProps["placement"];
  iconSize?: "small" | "medium" | "large";
  noIcon?: boolean;
  onHoverOff?: boolean;
  children?: ReactElement<any, any>;
}

export const MuiInfoClickTooltip = ({
  content,
  placement = "right",
  iconSize,
  noIcon,
  onHoverOff,
  children,
}: MuiClickTooltipProps) => {
  const { modalOpened, onModalOpen, onModalClose } = useModal();
  return (
    <ClickAwayListener onClickAway={onModalClose}>
      <div>
        <MuiWhiteTooltip
          PopperProps={{
            disablePortal: true,
          }}
          disableFocusListener
          disableHoverListener={!onHoverOff}
          disableTouchListener
          open={modalOpened}
          onClose={onModalClose}
          title={content}
          placement={placement}
          arrow
        >
          {!noIcon ? (
            <StyledIconButton onClick={onModalOpen}>
              <Info size={iconSize === "small" ? "w-5 h-5" : undefined} />
            </StyledIconButton>
          ) : (
            <div onClick={onModalOpen}>{children}</div>
          )}
        </MuiWhiteTooltip>
      </div>
    </ClickAwayListener>
  );
};
