import { CSSProperties } from "react";

import classNames from "classnames";

/**
 * universal loader component
 */
export const Spinner = (props: SpinnerProps) => {
  const {
    size = "w-12 h-12",
    borderWidth = 4,
    className,
    style,
    color = "#801FFF",
  } = props;

  return (
    <div
      className={classNames(
        "bg-transparent flex items-center justify-center",
        className,
      )}
      style={style}
    >
      <div
        className={classNames(
          {
            "animate-spin rounded-full border-gray-300": true,
          },
          size,
        )}
        style={{ borderTopColor: color, borderWidth }}
      />
    </div>
  );
};

interface SpinnerProps {
  /** additional styles */
  className?: string;
  /** custom styles */
  style?: CSSProperties;
  /** size of spinner */
  size?: string;
  /** border with */
  borderWidth?: string | number;
  color?: string;
}
