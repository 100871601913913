import { useAppTranslation } from "@hooks";
import Dialog from "@components/Alloweat/Dialog/Dialog";
import DialogTitle from "@components/Alloweat/Dialog/DialogTitle";
import { Button, DialogContent, Stack } from "@mui/material";
import DialogActions from "@components/Alloweat/Dialog/DialogActions";
import React, { Dispatch } from "react";
import ShowNutrientsField from "@views/dietician/ClientHealth/ProfileTab/NutrientsSection/Dialog/ShowNutrientsField";
import ImportantNutrientsField from "@views/dietician/ClientHealth/ProfileTab/NutrientsSection/Dialog/ImportantNutrientsField";
import { IconButton } from "@components/Alloweat/IconButton";
import { CloseIcon } from "@icons/index";
import { FormCancelButton, FormSubmitButton } from "@components/FormButtons";
import NutrientsFormDetail from "@views/dietician/ClientHealth/ProfileTab/NutrientsSection/Dialog/NutrientsForm";

type NutrientsDialogProps = {
  open: boolean;
  setOpen: Dispatch<boolean>;
};

const NutrientsDialog = ({ open, setOpen }: NutrientsDialogProps) => {
  const { t } = useAppTranslation();
  const title = t(
    "patient.health_and_goal.dietary_profile_tab.nutritional_values_modal.title",
  ).toLowerCase();
  return (
    <Dialog open={open} fullWidth PaperProps={{ sx: { maxWidth: "510px" } }}>
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {title.charAt(0).toUpperCase() + title.slice(1)}
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon color="727272" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <NutrientsFormDetail onSuccess={() => setOpen(false)}>
        <DialogContent>
          <Stack direction="column" gap="16px">
            <ShowNutrientsField />
            <ImportantNutrientsField />
          </Stack>
        </DialogContent>
        <DialogActions>
          <FormCancelButton onClick={() => setOpen(false)} />
          <FormSubmitButton />
        </DialogActions>
      </NutrientsFormDetail>
    </Dialog>
  );
};

export default NutrientsDialog;
