import { CSSProperties, memo, ReactNode, useState } from "react";

import classNames from "classnames";
import classnames from "classnames";
import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion";

import { VARIANTS } from "@utils/constants";
import { ArrowDown, DragHandle } from "@assets/icons";
import { IconButton } from "@components/IconButton";

/**
 * Component showing content in card
 */
export const Card = memo((props: CardProps) => {
  const {
    noShadow,
    noContainerPaddings,
    header,
    headerClassName,
    containerClassName,
    children,
    className,
    style,
    index,
    draggable,
    dragClass,
    isReadOnly,
    action,
    defaultOpen = true,
    collapse,
    square,
    headerSize = "large",
    ...containerDivProps
  } = props;

  const [isOpen, setIsOpen] = useState<boolean>(collapse ? defaultOpen : true);

  return (
    <motion.div
      custom={index ?? 0}
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={VARIANTS}
      draggable={dragClass ? !dragClass : undefined}
      className={classNames(
        {
          "bg-white font-roman flex flex-col": true,
          "rounded-md": !square,
          "shadow-paper": !noShadow,
          "shadow-none": noShadow,
        },
        className,
      )}
      style={style}
      {...containerDivProps}
    >
      {header && (
        <div
          className={classnames({
            "flex justify-between items-center border-b border-gray-400": true,
            "py-5 px-7": headerSize === "large",
            "py-3 px-6": headerSize === "medium",
            "py-2 px-4": headerSize === "small",
            "bg-transparent": isReadOnly,
          })}
          onClick={collapse ? () => setIsOpen(prev => !prev) : undefined}
        >
          <div
            className={classnames(
              {
                "flex items-center w-full font-semibold h-8.5": true,
                "text-2xl": headerSize === "large",
                "text-xl": headerSize === "medium",
                "text-lg": headerSize === "small",
              },
              headerClassName,
            )}
          >
            {draggable && (
              <div
                className={classNames(
                  dragClass,
                  "cursor-pointer text-gray flex items-center text-2xl -ml-4",
                )}
              >
                <DragHandle />
              </div>
            )}
            {header}
          </div>
          {action}
          {collapse && (
            <IconButton size="medium">
              <ArrowDown
                className={classNames({
                  "transform transition duration-100 ease-in ": true,
                  "rotate-180": isOpen,
                })}
              />
            </IconButton>
          )}
        </div>
      )}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/*@ts-ignore*/}
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            key="children"
            initial={defaultOpen ? "visible" : "collapsed"}
            animate="open"
            exit="collapsed"
            className={classNames(
              {
                "font-light": true,
                "px-7 py-5": !noContainerPaddings,
              },
              containerClassName,
            )}
            variants={{
              open: {
                opacity: 1,
                height: "100%",
              },
              collapsed: {
                opacity: 0,
                height: 0,
              },
            }}
            transition={{ duration: 0.2 }}
          >
            {children}
          </motion.section>
        )}
      </AnimatePresence>
    </motion.div>
  );
});

interface CardProps extends HTMLMotionProps<"div"> {
  /** show card without shadow around it */
  noShadow?: boolean;
  /** remove paddings from card's container */
  noContainerPaddings?: boolean;
  /** card's header title */
  header?: string | ReactNode;
  /** card's inner content */
  children?: ReactNode | Array<ReactNode>;
  /** card's additional classes */
  className?: string;
  /** additional classes for header */
  headerClassName?: string;
  /** card's inner container class names */
  containerClassName?: string;
  /** card's additional styles */
  style?: CSSProperties;
  /** index for variant animations */
  index?: number;
  /** Renders drag handle */
  draggable?: boolean;
  /** Optional className of drag handle (rendered with "draggable" prop) */
  dragClass?: string;
  /** Optional action button rendered on the right side of card title */
  action?: ReactNode;
  /** size of header text */
  headerSize?: "small" | "medium" | "large";
  /** Allows collapsing card content & adds button to trigger such action */
  collapse?: boolean;
  /** Default card open state if "collapse" is present */
  defaultOpen?: boolean;
  /** Removes rounded corners */
  square?: boolean;
  /** If Card is readonly */
  isReadOnly?: boolean;
}
