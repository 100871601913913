import { useController, useFormContext } from "react-hook-form";

import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

import { SurveySexDto } from "@client/surveys";
import { useAppTranslation } from "@hooks";

import { SexAnswerFormInputs } from "../forms";
import { useContext } from "react";
import { BlurContext } from "../BlurContext";
import { SAFARI_TAB_INDEX } from "@utils";

interface SexRadioGroupProps {
  readOnly?: boolean;
}
export const SexRadioGroup = ({ readOnly }: SexRadioGroupProps) => {
  const { t } = useAppTranslation();
  const { handleBlur, handleFocus } = useContext(BlurContext);
  const { control } = useFormContext<SexAnswerFormInputs>();
  const {
    field: { value, onChange },
  } = useController<SexAnswerFormInputs>({ control, name: "sex" });

  if (value === undefined) return <></>;

  return (
    <RadioGroup
      tabIndex={SAFARI_TAB_INDEX}
      onBlur={handleBlur}
      onFocus={handleFocus}
      value={value}
      onChange={(_, v) => !readOnly && onChange(v)}
    >
      <FormControlLabel
        value={SurveySexDto.M}
        control={<Radio />}
        label={t("questionnaires.questions.system_sex.man")}
      />
      <FormControlLabel
        value={SurveySexDto.W}
        control={<Radio />}
        label={t("questionnaires.questions.system_sex.woman")}
      />
      <FormControlLabel
        value={SurveySexDto.O}
        control={<Radio />}
        label={t("questionnaires.questions.system_sex.dont_specify")}
      />
    </RadioGroup>
  );
};
