import { ReactNode, useState } from "react";
import { NutrientsSidePanelContext } from "./NutrientsSidePanelContext";
import { useAppParams, useModalState } from "@hooks";
import { Nutrient } from "@typeDefinitions";
import { NutrientsSidePanel } from "./NutrientsSidePanel";
import { useFetchDietNutrientsNormQuery } from "@views/dietician/DietCreator/hooks";
import useFetchPatientImportantNutrients from "@hooks/queries/client/nutrient/useFetchPatientImportantNutrients";

interface NutrientsSidePanelContextProviderProps {
  children: ReactNode;
}
export const NutrientsSidePanelContextProvider = ({
  children,
}: NutrientsSidePanelContextProviderProps) => {
  const { dietId, patientId } = useAppParams();
  const [open, onOpen, onClose] = useModalState();
  const [values, setValues] = useState<Nutrient[]>();
  const { norm } = useFetchDietNutrientsNormQuery(dietId);
  const { data } = useFetchPatientImportantNutrients(
    { patientId: Number(patientId) },
    { enabled: !!patientId },
  );
  const handleOpen = (values: Nutrient[] | undefined) => {
    onOpen();
    setValues(values);
  };

  return (
    <NutrientsSidePanelContext.Provider value={{ onOpen: handleOpen }}>
      {children}
      <NutrientsSidePanel
        values={values}
        targets={norm?.nutrients?.map(n => ({
          id: n.nutrient.id,
          value: n.value,
        }))}
        open={open}
        onClose={onClose}
        importantNutrients={data?.data.map(({ id }) => id)}
        normId={norm?.norm?.id}
      />
    </NutrientsSidePanelContext.Provider>
  );
};
