import { useSearchParams } from "react-router-dom";

export const useParseUrl = () => {
  const URL_HASH_PARAM = "hash";
  const REPORT_HASH_PARAM = "hash2";
  const [searchParams] = useSearchParams();
  const urlBase64 = searchParams.get(URL_HASH_PARAM);
  const reportBase64 = searchParams.get(REPORT_HASH_PARAM);

  const url = isBase64(urlBase64) ? atob(urlBase64 ?? "") : undefined;
  const report = isBase64(reportBase64) ? atob(reportBase64 ?? "") : undefined;

  return {
    url,
    reportUrl: report,
  };
};

function isBase64(str: string | null) {
  if (!str || str === "" || str.trim() === "") return false;
  try {
    return btoa(atob(str)) == str;
  } catch (err) {
    return false;
  }
}
