import { FormProvider, useForm } from "react-hook-form";
import { ReactNode } from "react";
import useGetProgramFiltersSearchParams from "@views/dietician/Programs/hooks/useGetProgramFiltersSearchParams";
import { ProgramFiltersFormPops } from "@views/dietician/Programs/ProgramsFiltersFormType";

type ProgramsFormFiltersProps = {
  children: ReactNode;
};

const ProgramsFiltersForm = ({ children }: ProgramsFormFiltersProps) => {
  const { values } = useGetProgramFiltersSearchParams();

  const form = useForm<ProgramFiltersFormPops>({
    defaultValues: values,
  });

  return <FormProvider {...form}>{children}</FormProvider>;
};

export default ProgramsFiltersForm;
