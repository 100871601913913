import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import {
  fetchMonitoringMealsClient,
  FetchMonitoringMealsClientParams,
} from "client/patient/monitoring";
import { patientMonitoringKeys } from "@hooks/queries/client/monitoring/patientMonitoringKey";

export const useFetchMonitoringMealsClient = (
  params: FetchMonitoringMealsClientParams,
  options?: {
    enabled: boolean;
  },
) => {
  return useQuery({
    queryKey: patientMonitoringKeys.mealsClient(params),
    queryFn: () => fetchMonitoringMealsClient(params),
    ...options,
  });
};

export const useFetchMonitoringMealsClientInfinite = (
  params: FetchMonitoringMealsClientParams,
) => {
  return useInfiniteQuery({
    queryKey: patientMonitoringKeys.infiniteMealsClient(params),
    queryFn: ({ pageParam = 1 }) => {
      return fetchMonitoringMealsClient({
        ...params,
        page: pageParam,
      });
    },
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.meta.lastPage <= lastPage.meta.currentPage) {
        return undefined;
      }
      return lastPage.meta.currentPage + 1;
    },
    refetchOnMount: false,
    cacheTime: 0,
  });
};
