import { createContext, ReactNode, useState } from "react";

interface ScheduleClipboardContextIf {
  day?: DayIf;
  meal?: MealIf;
  copyDay: (data?: DayIf) => void;
  copyMeal: (data?: MealIf) => void;
}

export const ScheduleClipboardContext =
  createContext<ScheduleClipboardContextIf>({
    copyDay: data => {
      return;
    },
    copyMeal: data => {
      return;
    },
  });

interface ScheduleClipboardContextProviderProps {
  children: ReactNode;
}

interface DayIf {
  id: number;
  date: string;
  mealsCount: number;
  programDayId: number;
}

interface MealIf {
  id: number;
  namePl: string;
  nameEn: string;
}

export const ScheduleClipboardContextProvider = ({
  children,
}: ScheduleClipboardContextProviderProps) => {
  const [day, setDay] = useState<DayIf | undefined>();
  const [meal, setMeal] = useState<MealIf | undefined>();

  const handleCopyMeal = (data?: MealIf) => setMeal(data);
  const handleCopyDay = (data?: DayIf) => setDay(data);

  const value: ScheduleClipboardContextIf = {
    day,
    meal,
    copyMeal: handleCopyMeal,
    copyDay: handleCopyDay,
  };

  return (
    <ScheduleClipboardContext.Provider value={value}>
      {children}
    </ScheduleClipboardContext.Provider>
  );
};
