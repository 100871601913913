import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import { useAppParams, useAppTranslation, useModal } from "@hooks";
import { ProgramEmptyDietContext } from "@context";
import { useProgram } from "@hooks/resources";
import { findInvalidDay } from "@views/dietician/PatientProgramDays/components/utils";
import { EmptyDietWarningDialog } from "@components/EmptyDietWarningDialog";
import { ProgramInfoNavigation } from "@components/forms/ProgramInfoNavigation";

export const PatientProgramDaysNavigation = () => {
  const { t } = useAppTranslation();
  const { program } = useProgram();
  const navigate = useNavigate();
  const { modalOpened, onModalClose, onModalOpen } = useModal();

  const noDietDayId = program?.days.find(day => !day.diet)?.id;
  const [isWaiting, setWaiting] = useState<boolean | null>(null);

  const { setWarningBorder } = useContext(ProgramEmptyDietContext);
  const queryClient = useQueryClient();

  const { patientId, programId } = useAppParams();

  const invalidDayId = findInvalidDay(program);

  const handleAccept = () => {
    onModalClose();
    setWaiting(null);
    if (noDietDayId) {
      setWarningBorder();
      if (patientId) {
        navigate(
          `/patients/${patientId}/nutritional-programs/${programId}/days/${noDietDayId}`,
        );
      } else {
        navigate(`/programs/${programId}/days/${noDietDayId}`);
      }
    }
    if (invalidDayId) {
      if (patientId) {
        navigate(
          `/patients/${patientId}/nutritional-programs/${programId}/days/${invalidDayId}`,
        );
      } else {
        navigate(`/programs/${programId}/days/${invalidDayId}`);
      }
    }
  };

  const navigateToSchedule = () => {
    if (noDietDayId) {
      onModalOpen();
    } else {
      if (patientId) {
        navigate(
          `/patients/${patientId}/nutritional-programs/${programId}/schedule`,
        );
      } else {
        navigate(`/programs/${programId}/schedule`);
      }
    }
  };

  const handleSchedule = () => {
    if (queryClient.isMutating()) {
      setWaiting(true);
      setTimeout(() => handleSchedule(), 100);
    } else {
      setWaiting(false);
    }
  };

  useEffect(() => {
    if (isWaiting === false) {
      navigateToSchedule();
    }
  }, [isWaiting]);

  return (
    <>
      <EmptyDietWarningDialog
        open={modalOpened}
        onAccept={handleAccept}
        onCancel={onModalClose}
        title={t("addNutritionalProgram.step2.not_all_days_config")}
        content={t("addNutritionalProgram.step2.add_diet_to_days")}
      />

      {patientId ? (
        <ProgramInfoNavigation
          onBack={() =>
            navigate(
              `/patients/${patientId}/nutritional-programs/${programId}/edit`,
            )
          }
          onSubmit={handleSchedule}
          isSubmitting={!!isWaiting}
        />
      ) : (
        <ProgramInfoNavigation
          onBack={() => navigate(`/programs/${programId}/edit`)}
          onSubmit={handleSchedule}
          isSubmitting={!!isWaiting}
        />
      )}
    </>
  );
};
