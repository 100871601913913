import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Edit = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <rect
      id="icon_20x20_odstep_18px"
      data-name="icon 20x20 / odstep 18px"
      width="20"
      height="20"
      fill="#fff"
      opacity="0"
    />
    <path
      id="Pencil2"
      d="M42,1881v-2.828l8.379-8.379,2.828,2.828L44.829,1881Zm9.793-12.621.793-.793a2,2,0,1,1,2.828,2.828l-.793.793Z"
      transform="translate(-39 -1864)"
      fill="currentColor"
      stroke="rgba(0,0,0,0)"
      strokeWidth="1"
    />
  </SvgIcon>
);
