import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import CalendarDaysOfDay from "@views/dietician/PatientMonitoring2/components/CalendarDays/CalendarDaysOfDay";
import CalendarDaysOfWeek from "@views/dietician/PatientMonitoring2/components/CalendarDays/CalendarDaysOfWeek";
import CalendarDaysOfMonth from "@views/dietician/PatientMonitoring2/components/CalendarDays/CalendarDaysOfMonth";
import CalendarDaysOfCustom from "@views/dietician/PatientMonitoring2/components/CalendarDays/CalendarDaysOfCustom";

const components = {
  day: CalendarDaysOfDay,
  week: CalendarDaysOfWeek,
  month: CalendarDaysOfMonth,
  custom: CalendarDaysOfCustom,
};

const CalendarDaysOfFactory = () => {
  const { type: hookType, selectedDays: hookSelectedDays } = useMonitoring();

  const SpecificCalendarDay = components[hookType.type];

  return (
    <SpecificCalendarDay
      type={{ setValue: hookType.setType }}
      selectedDays={{
        value: hookSelectedDays.selectDays,
        setValue: hookSelectedDays.setSelectDays,
      }}
    />
  );
};

export default CalendarDaysOfFactory;
