import { QueryOptionsTyped } from "@hooks/queries";
import { useQuery } from "@tanstack/react-query";
import {
  fetchPatientBodyMeasurementSettings,
  FetchPatientBodyMeasurementSettingsParams,
  FetchPatientBodyMeasurementSettingsResponse,
} from "@client/patient/bodyMeasurement/fetchPatientBodyMeasurementSettings";
import { patientBodyMeasurementKeys } from "@hooks/queries/client/bodyMeasurement/patientBodyMeasurementKey";

const useFetchPatientHydrationProduct = (
  params: FetchPatientBodyMeasurementSettingsParams,
  options?: QueryOptionsTyped<FetchPatientBodyMeasurementSettingsResponse>,
) => {
  return useQuery({
    queryKey: patientBodyMeasurementKeys.settings(params.patientId),
    queryFn: () => fetchPatientBodyMeasurementSettings(params),
    ...options,
  });
};

export default useFetchPatientHydrationProduct;
