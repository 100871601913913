import { useCallback, useEffect, useState } from "react";

type EffectCallback = (opened: boolean) => void;
type ModalOpenCallback = () => void;
type ModalCloseCallback = () => void;

export function useModalState(
  effectCallback: EffectCallback = () => undefined,
): [boolean, ModalOpenCallback, ModalCloseCallback] {
  const [modalOpened, setModalOpened] = useState(false);
  const onModalClose = useCallback(() => setModalOpened(false), []);
  const onModalOpen = useCallback(() => setModalOpened(true), []);
  useEffect(() => effectCallback(modalOpened), [modalOpened]);

  return [modalOpened, onModalOpen, onModalClose];
}
