import { useMemo, useState } from "react";
import { useDebounce } from "react-use";
import { Link, useNavigate } from "react-router-dom";

import { TextField } from "@mui/material";

import { useFetchClientsQuery } from "@hooks/queries";
import { PATIENTS } from "@routes";
import { Loupe } from "@assets/icons";
import { Avatar } from "@components/AvatarNew";

import { ThemeProviderWrapperNew } from "themeNew";
import {
  EmailTypography,
  NameTypography,
  StyledAutocomplete,
} from "./ClientsSearch.styled";
import { useAppTranslation } from "@hooks";
import { SearchNoResults } from "@views/emptyStates/SearchNoResults";

export const ClientsSearch = () => {
  const { t } = useAppTranslation();
  const [query, setQuery] = useState("");
  const { clients, submit, isLoading } = useFetchClientsQuery();
  const navigate = useNavigate();
  const [key, setKey] = useState(0);
  const mappedClients: ClientOptionIf[] = useMemo(
    () =>
      clients?.map(({ id, firstName, lastName, email, avatar }) => {
        return {
          id: id.toString(),
          fullName: [firstName, lastName].join(" "),
          email,
          avatar,
        };
      }) ?? [],
    [clients],
  );

  useDebounce(() => submit({ search: query ?? "" }), 1000, [query]);

  const resetValue = () => {
    setKey(prevKey => prevKey + 1);
    setQuery("");
  };

  return (
    <ThemeProviderWrapperNew>
      <StyledAutocomplete
        key={key}
        noOptionsText={<SearchNoResults />}
        disablePortal
        autoHighlight
        clearOnEscape
        options={mappedClients}
        loading={isLoading}
        size="small"
        popupIcon={<></>}
        renderInput={params => {
          return (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: <Loupe className="mr-2" />,
              }}
              placeholder={t("common.search_clients")}
              onChange={e => setQuery(e.target.value)}
            />
          );
        }}
        renderOption={(props, option) => {
          const { id, fullName, email, avatar } = option;
          return (
            <Link to={`${PATIENTS}/${id}/info-and-services`} key={props.id}>
              <li {...props}>
                <div className="flex gap-2">
                  <div className="w-10 h-10">
                    <Avatar
                      fullName={fullName}
                      image={avatar ?? undefined}
                      status
                    />
                  </div>
                  <div className="grid">
                    <NameTypography>{fullName}</NameTypography>
                    <EmailTypography>{email}</EmailTypography>
                  </div>
                </div>
              </li>
            </Link>
          );
        }}
        onChange={(e, value) => {
          if (e.type !== "click" && value?.id !== undefined)
            navigate(`${PATIENTS}/${value.id}`);
          resetValue();
        }}
        onBlur={() => submit({ search: "" })}
        getOptionLabel={selected => selected.fullName}
        filterOptions={options => options}
      />
    </ThemeProviderWrapperNew>
  );
};

interface ClientOptionIf {
  id: string;
  fullName: string;
  email: string | null;
  avatar: string | null;
}
