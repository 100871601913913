import {
  BalanceActivityDaily,
  BalanceActivityTraining,
  BalanceFormula,
} from "@utils/balance";

export function bwMifflin1990(
  sex: "m" | "w",
  weight: number,
  age: number,
  height: number,
) {
  if (sex === "m") {
    return 9.99 * weight + 6.25 * height - 4.92 * age + 5;
  } else {
    return 9.99 * weight + 6.25 * height - 4.92 * age - 161;
  }
}

export function bwHarrisBenedict1918(
  sex: "m" | "w",
  weight: number,
  age: number,
  height: number,
) {
  if (sex === "m") {
    return 66.473 + 13.7516 * weight + 5.0033 * height - 6.755 * age;
  } else {
    return 655.0955 + 9.5634 * weight + 1.8496 * height - 4.6756 * age;
  }
}

export function bwFaoWhoUnu1985(sex: "m" | "w", weight: number, age: number) {
  if (sex === "m") {
    if (age >= 0 && age < 3) {
      return 60.9 * weight - 54;
    } else if (age >= 3 && age < 10) {
      return 27.7 * weight + 495;
    } else if (age >= 10 && age < 18) {
      return 17.5 * weight + 651;
    } else if (age >= 18 && age < 30) {
      return 15.3 * weight + 679;
    } else if (age >= 30 && age < 60) {
      return 11.6 * weight + 879;
    } else if (age >= 60) {
      return 13.5 * weight + 487;
    }
  } else {
    if (age >= 0 && age < 3) {
      return 61 * weight - 51;
    } else if (age >= 3 && age < 10) {
      return 22.5 * weight + 499;
    } else if (age >= 10 && age < 18) {
      return 12.2 * weight + 746;
    } else if (age >= 18 && age < 30) {
      return 14.7 * weight + 496;
    } else if (age >= 30 && age < 60) {
      return 8.7 * weight + 829;
    } else if (age >= 60) {
      return 10.5 * weight + 596;
    }
  }
}

export function bwOwen1987(sex: "m" | "w", weight: number) {
  if (sex === "m") {
    return 10.2 * weight + 879;
  } else {
    return 7.18 * weight + 795;
  }
}

export function bwSchofield1985(sex: "m" | "w", weight: number, age: number) {
  if (sex === "m") {
    if (age >= 18 && age < 30) {
      return 15.057 * weight + 692.2;
    } else if (age >= 30 && age < 60) {
      return 11.472 * weight + 873.1;
    } else if (age >= 60) {
      return 1.711 * weight + 587.7;
    }
  } else {
    if (age >= 18 && age < 30) {
      return 14.818 * weight + 486.6;
    } else if (age >= 30 && age < 60) {
      return 8.126 * weight + 845.6;
    } else if (age >= 60) {
      return 9.082 * weight + 658.5;
    }
  }
}

export function lbmCunningham1991(lbm: number) {
  return 370 + 21.6 * lbm;
}

export function lbmMifflin1990(lbm: number) {
  return 19.7 * lbm + 413;
}

export function lbmCunningham1980(lbm: number) {
  return 22 * lbm + 500;
}

export function lbmOwen1987(lbm: number, sex: "m" | "w") {
  if (sex === "m") {
    return 22.3 * lbm + 290;
  } else {
    return 19.7 * lbm + 334;
  }
}

export function lbmWang2000(lbm: number) {
  return 24.6 * lbm + 175;
}

export function getPal(
  dailyActivity: BalanceActivityDaily,
  trainingActivity: BalanceActivityTraining,
) {
  const palValues = {
    1: {
      1: 1.4,
      2: 1.5,
      3: 1.6,
      4: 1.7,
      5: 1.9,
    },
    2: {
      1: 1.5,
      2: 1.6,
      3: 1.7,
      4: 1.8,
      5: 2,
    },
    3: {
      1: 1.6,
      2: 1.7,
      3: 1.8,
      4: 1.9,
      5: 2.2,
    },
    4: {
      1: 1.7,
      2: 1.8,
      3: 1.9,
      4: 2.1,
      5: 2.3,
    },
  };

  return palValues[dailyActivity][trainingActivity];
}

export function calculateSpm(
  id: BalanceFormula,
  sex: "m" | "w" | undefined = "m",
  weight: number | undefined = 0,
  age: number | undefined = 0,
  height: number | undefined = 0,
  lbm: number | undefined = 0,
) {
  const formulasById = {
    1: bwMifflin1990(sex, weight, age, height),
    2: bwHarrisBenedict1918(sex, weight, age, height),
    3: bwFaoWhoUnu1985(sex, weight, age),
    4: bwOwen1987(sex, weight),
    5: bwSchofield1985(sex, weight, age),
    6: lbmCunningham1991(lbm),
    7: lbmMifflin1990(lbm),
    8: lbmCunningham1980(lbm),
    9: lbmOwen1987(lbm, sex),
    10: lbmWang2000(lbm),
  };

  return formulasById[id];
}

export function calculateLbm(
  weight: number | null,
  percentBodyFat: number | null,
) {
  if (weight === null || percentBodyFat === null) return 0;
  return (weight * (100 - percentBodyFat)) / 100;
}

export function calculateBmi(weight: number | null, height: number | null) {
  if (weight === null || height === null || height === 0) return 0;
  return weight / (height / 100) ** 2;
}
