import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "./types";
import { fetchPatientProgramQueryKey } from "./useFetchPatientProgramQuery";
import { fetchProgramQueryKey } from "./useFetchProgramQuery";
import {
  fetchDietitianClientProgramScheduleKey,
  fetchDietitianProgramScheduleKey,
} from "./schedule";
import { updateProgramDayDiet } from "@client";

interface UseUpdateProgramDayDietMutationProps {
  programId: string;
  dayId: string;
  dietId: string;
}

export const updateProgramDayDietMutation = "updateProgramDayDietMutation";

export function useUpdateProgramDayDietMutation(options?: MutationOptions) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ programId, dayId, dietId }: UseUpdateProgramDayDietMutationProps) =>
      updateProgramDayDiet(programId, dayId, dietId),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries([fetchPatientProgramQueryKey]);
        queryClient.invalidateQueries([fetchProgramQueryKey]);
        queryClient.invalidateQueries([fetchDietitianProgramScheduleKey]);
        queryClient.invalidateQueries([fetchDietitianClientProgramScheduleKey]);
        options?.onSuccess && options.onSuccess();
      },
      mutationKey: [updateProgramDayDietMutation],
    },
  );
}
