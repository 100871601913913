import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import {
  NutrientDto,
  TranslationDto,
  nutrientSchema,
  translationSchema,
} from "./common";

export const fetchMonitoringProgramDayCalendar = async (
  patientId: string,
  from: string,
  to: string,
): Promise<ApiResponse<MonitoringDayDto[]>> => {
  const response = await fetchData(
    `/dietitian/patients/${patientId}/monitoring/type/program-day/calendar?from=${from}&to=${to}`,
    APIMethods.GET,
  );

  return await fetchMonitoringCalendarSchema.validate(response);
};

export const fetchMonitoringScheduleCalendar = async (
  patientId: string,
  from: string,
  to: string,
): Promise<ApiResponse<MonitoringDayDto[]>> => {
  const response = await fetchData(
    `/dietitian/patients/${patientId}/monitoring/type/schedule/calendar?from=${from}&to=${to}`,
    APIMethods.GET,
  );

  return await fetchMonitoringCalendarSchema.validate(response);
};

export interface MonitoringDayDto {
  date: string;
  target: TargetDto | null;
  foodDiary: FoodDiaryDto | null;
}

interface TargetDto {
  nutrients: NutrientDto[];
  programDay: ProgramDayDto | null;
}

interface ProgramDayDto {
  id: number;
  name: string;
  translations: TranslationDto[];
}

interface FoodDiaryDto {
  nutrients: NutrientDto[];
  note: NoteDto;
  isReported: boolean;
  programDay: ProgramDayDto | null;
}

interface NoteDto {
  dietitian: string | null;
  patient: string | null;
}

const noteNewSchema = yup.object().shape({
  dietitian: yup.string().nullable().default(null),
  patient: yup.string().nullable().default(null),
});

const programDaySchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  translations: yup.array().of(translationSchema).required(),
});

const foodDiarySchema = yup.object().shape({
  nutrients: yup.array().of(nutrientSchema).required(),
  note: noteNewSchema.required(),
  isReported: yup.boolean().required(),
  programDay: programDaySchema.nullable().default(null),
});

const targetSchema = yup.object().shape({
  nutrients: yup.array().of(nutrientSchema).required(),
  programDay: programDaySchema.nullable().default(null),
});

const monitoringDaySchema = yup.object().shape({
  date: yup.string().required(),
  target: targetSchema.nullable().default(null),
  foodDiary: foodDiarySchema.nullable().default(null),
});

const fetchMonitoringCalendarSchema = yup.object().shape({
  data: yup.array().of(monitoringDaySchema).required(),
});
