import { Button, styled } from "@mui/material";

export const ButtonStyled = styled(Button)`
  display: flex;
  gap: 8px;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallMax}px) {
    width: 150px;
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.medium}px) {
    width: 290px;
  }
`;
