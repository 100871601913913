import { useFormContext } from "react-hook-form";

import { v4 as uuid } from "uuid";
import { IconButton } from "@mui/material";

import { TrashNew } from "@assets/icons";
import { PlusRounded } from "@assets/icons/PlusRounded";
import { FormTextField } from "@components/FormTextField";
import { useAppTranslation, useControlledFieldArray } from "@hooks";

import { TableConfigFormInputs } from "../forms/useTableConfigForm";
import { AlignedButton, HeaderStyled } from "./QuestionTable.styled";

export const ControlledTable = () => {
  const { t } = useAppTranslation();
  const { watch, control } = useFormContext<TableConfigFormInputs>();

  const {
    fields: columns,
    remove: removeCol,
    append: appendCol,
  } = useControlledFieldArray<TableConfigFormInputs>({
    name: "columns",
    control,
    watch,
  });
  const {
    fields: rows,
    remove: removeRow,
    append: appendRow,
  } = useControlledFieldArray<TableConfigFormInputs>({
    name: "rows",
    control,
    watch,
  });

  return (
    <div className="flex gap-3">
      <div className="flex-1 flex flex-col gap-3">
        <HeaderStyled>{t("questionnaires.questions.table.rows")}</HeaderStyled>
        <div className="flex flex-col gap-2">
          {rows.map((row, idx) => (
            <FormTextField
              key={row.id}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => removeRow(idx)} color="primary">
                    <TrashNew />
                  </IconButton>
                ),
              }}
              size="small"
              name={`rows.${idx}.name`}
            />
          ))}
        </div>
        <AlignedButton
          onClick={() =>
            appendRow({ name: `Wiersz ${rows.length + 1}`, uuid: uuid() })
          }
          startIcon={<PlusRounded size="w-3 h-3" />}
        >
          {t("questionnaires.questions.table.add_row")}
        </AlignedButton>
      </div>
      <div className="flex-1 flex flex-col gap-3">
        <HeaderStyled>
          {t("questionnaires.questions.table.columns")}
        </HeaderStyled>
        <div className="flex flex-col gap-2">
          {columns.map((col, idx) => (
            <FormTextField
              key={col.id}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => removeCol(idx)} color="primary">
                    <TrashNew />
                  </IconButton>
                ),
              }}
              size="small"
              name={`columns.${idx}.name`}
            />
          ))}
        </div>
        <AlignedButton
          onClick={() =>
            appendCol({ name: `Kolumna ${columns.length + 1}`, uuid: uuid() })
          }
          startIcon={<PlusRounded size="w-3 h-3" />}
        >
          {t("questionnaires.questions.table.add_column")}
        </AlignedButton>
      </div>
    </div>
  );
};
