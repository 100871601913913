import {
  Button,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from "@mui/material";

export const TrialTopSection = styled("div")`
  display: grid;
  padding: 1rem;
  justify-content: end;
  height: 56px;
`;

export const ModalContent = styled(DialogContent)`
  display: grid;
  gap: 24px;
  justify-content: center;
  justify-items: center;
  width: 600px;
  padding-top: 0;
`;

export const TitleWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledDialogTitle = styled(DialogTitle)`
  font-size: 18px;
  line-height: 20px;
  padding: 0;
`;

export const ContentText = styled("span")`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  white-space: pre-line;
  width: 90%;
  margin: 0 auto;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.smallNew}px) {
    width: 100%;
  }
`;

export const ContentSection = styled("div")`
  display: grid;
  gap: 12px;
  text-align: center;
`;

export const ModalTitleWrapper = styled("div")``;

export const CloseButton = styled(IconButton)`
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;

export const OnClickButton = styled(Button)`
  height: 40px;
  width: fit-content;
  padding: 8px 12px;

  & svg {
    width: 12px;
    height: 17px;
  }
`;
