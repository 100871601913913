import { Select, SelectProps } from "@mui/material";
import { Control, Controller, Path } from "react-hook-form";

import { FieldValues } from "react-hook-form/dist/types";

interface FormSelectProps<T extends FieldValues> extends SelectProps {
  control: Control<T>;
  name: Path<T>;
  error?: boolean;
}
export const FormSelect = <T extends FieldValues>({
  control,
  name,
  error,
  ...rest
}: FormSelectProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onBlur, onChange, value, ref },
        fieldState: { error: errors },
      }) => (
        <Select
          ref={ref}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          value={value as string | number | undefined}
          error={!!error ?? (errors?.message as string)}
          {...rest}
        />
      )}
    />
  );
};
