import { SvgIcon, SvgIconProps } from "@mui/material";

export const OpenBook = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M3 3.9H8.4C9.35478 3.9 10.2705 4.27928 10.9456 4.95441C11.6207 5.62954 12 6.54522 12 7.5V20.1C12 19.3839 11.7155 18.6972 11.2092 18.1908C10.7028 17.6845 10.0161 17.4 9.3 17.4H3V3.9Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M21 3.9H15.6C14.6452 3.9 13.7295 4.27928 13.0544 4.95441C12.3793 5.62954 12 6.54522 12 7.5V20.1C12 19.3839 12.2845 18.6972 12.7908 18.1908C13.2972 17.6845 13.9839 17.4 14.7 17.4H21V3.9Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
