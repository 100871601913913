import { TabPanel } from "@mui/lab";
import { Divider, styled } from "@mui/material";
import { getHexColorOpacity } from "@utils";

export const ChartsCard = styled("div")`
  display: grid;
  gap: 16px;
  padding: 16px;
  border-radius: 16px;
  border: 2px solid ${({ theme }) => theme.colors.neutral.light[100]};
  box-shadow: ${({ theme }) => theme.boxShadows.card};
  background: ${({ theme }) => theme.colors.survey.light};
`;

export const TabPanelStyled = styled(TabPanel)`
  &.MuiTabPanel-root {
    padding: unset;
  }
`;
