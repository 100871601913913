import { Avatar } from "@components/Avatar";

import {
  PersonCellStyled,
  StyledContact,
  StyledName,
} from "./PersonDetailsCell.styled";
import { To } from "react-router-dom";

interface PersonDataCellProps {
  id: number;
  name: string;
  phone?: string;
  email: string;
  avatarUrl?: string;
  patient?: boolean;
}

export const PersonDetailsCell = ({
  id,
  name,
  phone,
  email,
  avatarUrl,
  patient = false,
}: PersonDataCellProps) => {
  const contact = [phone, email];

  return (
    <PersonCellStyled>
      <div className="flex-shrink-0">
        <Avatar size="large" image={avatarUrl} fullName={name} />
      </div>
      <div className="flex flex-col gap-2">
        <StyledName
          isPatient={patient}
          to={((patient && `/patients/${id}`) as To) ?? undefined}
        >
          {name}
        </StyledName>
        <StyledContact>
          {contact.filter(el => el).join("\u00a0 • \u00a0")}
        </StyledContact>
      </div>
    </PersonCellStyled>
  );
};
