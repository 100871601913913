import { ClientAddressDto, PhoneDto } from "@client";
import { trim } from "lodash";

export enum Roles {
  DIETITIAN_OWNER = "dietitian_owner",
  DIETITIAN_WORKER = "dietitian_worker",
  DIETITIAN_ASSISTANT = "dietitian_assistant",
  PATIENT = "patient",
}

export enum ClientStatus {
  NOT_ACTIVE,
  ACTIVE,
}

export enum TodoStatus {
  NO,
  YES,
}

export const mapAddressString = (
  address?: ClientAddressDto,
  polish = true,
): string => {
  if (!address) return "";

  const { streetName, number, postCode, city } = addressConsts(address, polish);

  const streetWithNumber = [streetName, number]
    .filter(string => !!string.replaceAll(" ", ""))
    .join(" ");

  return [streetWithNumber, postCode, city]
    .filter(string => !!string.replaceAll(" ", ""))
    .join(", ");
};

export const mapAddressArray = (
  address?: ClientAddressDto,
  polish = true,
): string[] => {
  if (!address) return [];

  const { streetName, number, postCode, city } = addressConsts(address, polish);

  const streetWithNumber = [streetName, number]
    .filter(string => !!string.replaceAll(" ", ""))
    .join(" ");
  const cityWithPostcode = [postCode, city]
    .filter(string => !!string.replaceAll(" ", ""))
    .join(" ");

  return [streetWithNumber, cityWithPostcode].filter(
    string => !!string.replaceAll(" ", ""),
  );
};

const addressConsts = (address: ClientAddressDto, polish = true) => {
  const street = address.street ?? "";
  const streetName = polish
    ? `${street ? `ul. ${street}` : ""}`
    : `${street ? `${street} st.` : ""}`;

  const streetNumber = address.streetNumber ?? "";
  const houseNumber = address.houseNumber ?? "";
  const number = `${streetNumber}${houseNumber ? `/${houseNumber}` : ""}`;

  const city = address.city ?? "";
  const postCode = address.postcode ?? "";

  return {
    street,
    streetName,
    streetNumber,
    houseNumber,
    number,
    city,
    postCode,
  };
};

export const INVALID_OLD_PASSWORD_ERROR = "The old_password is incorrect";

export const mapPhoneString = (phone?: PhoneDto) => {
  const phoneString = trim(`${phone?.prefix ?? ""} ${phone?.number ?? ""}`);

  if (!phone || !phoneString) return;
  return phoneString;
};
