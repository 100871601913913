import { AccordionDetails, Box, CircularProgress } from "@mui/material";
import { ArrowDown } from "@assets/icons";
import { useSuggestedTasksQuery } from "@hooks/queries/suggestedTasks/useSuggestedTasksQuery";
import { TasksSuggestedTable } from "./TasksSuggestedTable";
import {
  AccordionHeader,
  AccordionStyled,
  AccordionSummaryStyled,
} from "../TasksAccordion/TasksAccordion.styled";
import { useState } from "react";
import { useAllTags } from "@hooks/useAllTags";
import { TabsChipsFiltersForm } from "@components/Filters/TabsChipsFilter/TabsChipsFilterForm";
import { useSuggestedTasksFiltersForm } from "@hooks/suggestedTasks/useSuggestedTasksFiltersForm";
import { FormProvider } from "react-hook-form";
import { useQueryParams } from "@hooks/queries/useQueryParams";
import { useAppTranslation } from "@hooks";
import { TagId } from "./TasksSuggestedTable";

export enum SuggestedTasksType {
  Hidden = 1,
  Visible = 0,
}

export const TasksSuggestedRejectedAccordion = () => {
  const [page, setPage] = useState(0);
  const form = useSuggestedTasksFiltersForm();

  const { systemTags } = useAllTags();
  const { t } = useAppTranslation();

  const { get } = useQueryParams("tagId");

  const { tagId } = get<{
    tagId: number[] | undefined;
  }>();

  const tagOptions = systemTags
    .map(({ id, name }) => ({
      id: String(id),
      label: name,
    }))
    .filter(({ id }) => Number(id) !== TagId.UpcomingVisit);

  const { data, isLoading } = useSuggestedTasksQuery(page, {
    hidden: SuggestedTasksType.Hidden,
  });

  if (isLoading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding={2}
      >
        <CircularProgress />
      </Box>
    );

  if (!data?.tasks.length && !tagId) return null;

  const isAccordionExpanded = !!tagId;

  return (
    <AccordionStyled defaultExpanded={isAccordionExpanded}>
      <AccordionSummaryStyled expandIcon={<ArrowDown />}>
        <AccordionHeader>
          <span> {`${t("tasks.rejected")} (${data?.tasks.length ?? ""})`}</span>
        </AccordionHeader>
      </AccordionSummaryStyled>
      <AccordionDetails>
        <FormProvider {...form}>
          <Box display="flex" flexDirection="column" gap={2}>
            <TabsChipsFiltersForm
              name="tagId"
              options={tagOptions}
              multiple
              className="flex-nowrap overflow-auto custom-scrollbar"
            />
            {data?.tasks && (
              <TasksSuggestedTable
                suggestedTasks={data?.tasks}
                page={page}
                setPage={setPage}
                isFetching={false}
                meta={data.meta}
              />
            )}
          </Box>
        </FormProvider>
      </AccordionDetails>
    </AccordionStyled>
  );
};
