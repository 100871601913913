import { useQuery } from "@tanstack/react-query";

import { getPdfCoverImagesAPI } from "@Pdf/api/pdfAPI";
import { buildDefaultQueryOptions, QueryOptionsTyped } from "@hooks/queries";
import { PdfImageResponseType } from "@Pdf/types/pdfTypes";

export const pdfImagesQueryKey = "pdfImagesQueryKey";

const usePdfImagesQuery = (
  options?: QueryOptionsTyped<Array<PdfImageResponseType>>,
) =>
  useQuery(
    [pdfImagesQueryKey],
    getPdfCoverImagesAPI,
    buildDefaultQueryOptions(options),
  );

export default usePdfImagesQuery;
