import { Box, Button, Stack, styled } from "@mui/material";

export const TodayButton = styled(Button)`
  padding: 4px 6px;
  min-width: unset;
  min-height: unset;
  height: unset;
  font-size: 10px;
  line-height: 24px;
  font-family: Figtree, serif;
  font-style: normal;
  font-weight: 500;
  margin-right: 8px;
  height: 20px;
` as typeof Button;

export const CustomButton = styled(Button)`
  min-width: 32px;
  height: 32px;
  padding: 0px;
  font-family: Figtree, serif;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  font-size: 12px;
  border-radius: 8px;
  color: #727272;
  &.MuiButton-containedPrimary {
    background: #f5f1fe;
    color: #7448d0;
  }
` as typeof Button;

export const BoxWrapper = styled(Box)`
  border: solid 1px #f5f1fe;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  background: ${({ theme }) => theme.colors.neutral.light[100]};
` as typeof Box;

export const Title = styled("div")`
  color: #2f303a;
  line-height: 20px;
  font-size: 12px;
  font-family: Figtree, serif;
  font-style: normal;
  font-weight: 600;
  padding: 8px;
`;

export const ButtonList = styled(Stack)`
  padding: 4px 4px;
  border-top: solid 1px #f5f1fe;
` as typeof Stack;
