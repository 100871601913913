import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import MeasurementsBodySectionOfDays from "@views/dietician/PatientMonitoring2/components/MeasurementsBox/MeasurementsBodySectionOfDays";
import MeasurementsBodySectionOfDay from "@views/dietician/PatientMonitoring2/components/MeasurementsBox/MeasurementsBodySectionOfDay";

const MeasurementsBodySectionFactory = () => {
  const { type } = useMonitoring();

  return type.type === "day" ? (
    <MeasurementsBodySectionOfDay />
  ) : (
    <MeasurementsBodySectionOfDays />
  );
};

export default MeasurementsBodySectionFactory;
