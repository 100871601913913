import { QueryOptionsTyped } from "@hooks/queries";
import {
  fetchMonitoringMeasurementsLast,
  FetchMonitoringMeasurementsLastParams,
  FetchMonitoringMeasurementsLastResponse,
} from "client/patient/monitoring";
import { useQuery } from "@tanstack/react-query";
import { patientMonitoringKeys } from "@hooks/queries/client/monitoring/patientMonitoringKey";

export const useFetchMonitoringMeasurementLast = (
  params: FetchMonitoringMeasurementsLastParams,
  options?: QueryOptionsTyped<FetchMonitoringMeasurementsLastResponse>,
) => {
  return useQuery({
    queryKey: patientMonitoringKeys.measurementLast(params),
    queryFn: () => fetchMonitoringMeasurementsLast(params),
    ...options,
  });
};
