import { ReactNode } from "react";
import { Text } from "./MealDetails.styled";
import { isString, round } from "lodash";

interface NutrientLabelProps {
  label: ReactNode | string;
  value: number;
  unit: string;
}
export const NutrientLabel = ({ label, value, unit }: NutrientLabelProps) => {
  return (
    <div className="flex gap-[4px] items-center">
      {isString(label) ? <Text>{label}</Text> : label}

      <div className="flex">
        <Text bold>{round(value)}</Text>
        <Text>&nbsp;{unit}</Text>
      </div>
    </div>
  );
};
