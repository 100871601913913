import * as yup from "yup";

import { IdDto, idSchema } from "@client/common";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const postCreatorMealClone = async (
  mealId: string,
): Promise<ApiResponse<IdDto>> => {
  const response = await fetchData(
    `/dietitian/creator/meals/${mealId}/clone`,
    APIMethods.POST,
  );

  return postCreateMealResponseSchema.validate(response);
};

const postCreateMealResponseSchema = yup.object().shape({
  data: idSchema.required(),
});
