import { useController, useFormContext } from "react-hook-form";
import { StyledAutocomplete } from "./EditProductDetails.styled";
import { DietitianProductFormInput } from "../../ProductForm";
import { useFetchProductShopsQuery } from "@hooks/queries/dictionaries";
import { ReactNode, useMemo } from "react";
import { useAppTranslation } from "@hooks";
import { Chip, TextField } from "@mui/material";
import { omit } from "lodash";
import { SelectOption } from "@components/FormAutocomplete";
import { Cancel } from "@mui/icons-material";

export const ProductShopsAutocomplete = () => {
  const { t } = useAppTranslation();
  const { control } = useFormContext<DietitianProductFormInput>();
  const {
    field: { value, onChange },
  } = useController<DietitianProductFormInput, "shops">({
    control,
    name: "shops",
  });
  const { data: shops } = useFetchProductShopsQuery({ refetchOnMount: false });

  const shopsOptions = useMemo(
    () =>
      shops?.map(({ id, name }) => ({ id: id.toString(), label: name })) ?? [],
    [shops],
  );
  const selectedShops = useMemo(
    () => shopsOptions?.filter(({ id }) => value.includes(id)),
    [shops, value],
  );
  const handleDelete = (id: string) => {
    onChange(value.filter(selected => selected !== id));
  };
  return (
    <StyledAutocomplete
      value={selectedShops}
      onChange={(e, value) => onChange(value.map(({ id }) => id))}
      options={shopsOptions}
      size="small"
      placeholder={t("product.edit.start_typing")}
      multiple
      renderInput={params => (
        <TextField
          {...omit(params, "InputLabelProps")}
          label={t("product.edit.shops")}
        />
      )}
      renderTags={(value: SelectOption[]): ReactNode => (
        <div className="flex flex-wrap gap-[8px]">
          {value.map(tag => (
            <Chip
              key={tag.id}
              label={tag.label}
              onDelete={() => handleDelete(tag.id)}
              deleteIcon={<Cancel color="primary" />}
              variant="newFilled"
              color="primary"
            />
          ))}
        </div>
      )}
    />
  );
};
