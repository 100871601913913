import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppTranslation } from "@hooks";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";
import { Currencies, MEETING_TYPE } from "@utils/scheduleEvent";
import { createAddEventSchema } from "../_utils/formSchema";
import { getDefaultFormValues } from "../_utils/formActions";
import { useFetchScheduleEvent } from "@hooks/queries";
import { useScheduleVisitModalContext } from "../ScheduleVisitModalContext";
import { mapAddVisitForm } from "../_utils/scheduleVisitFormActions";
import { mapAddVisitRequest } from "@views/dietician/Diet/components/AddVisitModal";
import { useScheduleVisitMutations } from "./useScheduleVisitMutations";
import { useEffect } from "react";

export interface AddEventModalInput {
  eventType: MEETING_TYPE;
  patientId: number | null;
  eventName: string;
  isAllDay: boolean;
  startDate: Date;
  finishDate: Date;
  dietitianId: number | null;
  clinicFacilityId: number | null;
  clinicProductId: string | null;
  price: number | null;
  currency: Currencies | null;
  additionalInfo: string | null;
  notification: boolean | null;
}

export const useScheduleVisitForm = (navigateToVisit?: boolean) => {
  const { t } = useAppTranslation();
  const { account } = useFetchDietitianAccountQuery();
  const { eventId } = useScheduleVisitModalContext();
  const { handleVisitModalClose, startDate } = useScheduleVisitModalContext();

  const idWithFallback = eventId ?? 0;

  const { createScheduleEvent, updateScheduleEvent } =
    useScheduleVisitMutations(idWithFallback, navigateToVisit);

  const { scheduleEvent } = useFetchScheduleEvent(idWithFallback, {
    enabled: !!eventId,
  });

  const schema = createAddEventSchema(t);

  const defaultValues = getDefaultFormValues(account?.id, startDate);

  const form = useForm<AddEventModalInput>({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
    resetOptions: {
      keepDirtyValues: false,
      keepErrors: false,
    },
  });

  const isEditMode = !!eventId;

  useEffect(() => {
    if (eventId) {
      form.reset(mapAddVisitForm(scheduleEvent));
    } else {
      form.reset(defaultValues);
    }
  }, [eventId, startDate]);

  const onFormSubmit = async (data: AddEventModalInput) => {
    isEditMode
      ? await updateScheduleEvent(mapAddVisitRequest(data))
      : await createScheduleEvent(mapAddVisitRequest(data));
    form.reset();
    handleVisitModalClose();
  };

  return { form, onFormSubmit };
};
