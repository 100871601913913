import { Drawer, Stack } from "@mui/material";
import MealItem from "@views/dietician/PatientMonitoring2/components/MealsBox/Client/MealItem";
import { useFetchMonitoringMealsClient } from "@hooks/queries/client/monitoring";
import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import { mapSelectedDaysToRequestQueryParams } from "@views/dietician/PatientMonitoring2/PatientMonitoringUtils";
import {
  Comment,
  CommentContainer,
  CommentLabel,
} from "@views/dietician/PatientMonitoring2/components/MealsBox/Client/MealItem.styled";
import { RecipePreview } from "@components/PreviewDrawer/Recipe";
import { useEffect, useState } from "react";
import MealSkeleton from "@views/dietician/PatientMonitoring2/components/MealsBox/MealsSkeleton";
import MealListItem from "@views/dietician/PatientMonitoring2/components/MealsBox/MealListItem";
import { useAppTranslation } from "@hooks";
import { flatten } from "lodash";
import NoResult from "@views/dietician/PatientMonitoring2/components/MealsBox/NoResult";
import { ProductPreview } from "@components/PreviewDrawer/Product";

type MealsBoxClientProps = {
  toggleMeal: {
    setMealsId: (mealsId: number[]) => void;
    toggleExpandedMeal: (mealId: number) => void;
    isExpandedMeal: (mealId: number) => boolean;
  };
};

export type DrawerMealItem = {
  id: number;
  type: "product" | "recipe";
} | null;

const MealsBoxClient = ({ toggleMeal }: MealsBoxClientProps) => {
  const { type, patientId, selectedDays } = useMonitoring();
  const { t } = useAppTranslation();
  const [drawerSelected, setDrawerSelected] = useState<DrawerMealItem>(null);

  const { data } = useFetchMonitoringMealsClient(
    {
      patientId,
      ...mapSelectedDaysToRequestQueryParams(selectedDays.selectDays),
    },
    { enabled: type.type === "day" },
  );

  useEffect(() => {
    toggleMeal.setMealsId(
      flatten(
        data?.data.map(i =>
          i.meals.filter(m => m.items.length > 0).map(m => m.id),
        ) ?? [],
      ),
    );
  }, [data?.data]);

  const day = data?.data[0];

  if (data === undefined) {
    return <MealSkeleton />;
  }

  return (
    <>
      <Stack spacing="24px">
        {data?.data[0]?.comment && (
          <CommentContainer>
            <CommentLabel>
              {t("monitoring2.meals_box.comment_day")}
            </CommentLabel>
            <Comment>{day?.comment}</Comment>
          </CommentContainer>
        )}
        {(day?.meals ?? []).length > 0 ? (
          <ul>
            {day?.meals.map(meal => (
              <MealListItem key={meal.id}>
                <MealItem
                  meal={meal}
                  onClick={setDrawerSelected}
                  expanded={toggleMeal.isExpandedMeal(meal.id)}
                  toggleExpanded={() => toggleMeal.toggleExpandedMeal(meal.id)}
                />
              </MealListItem>
            ))}
          </ul>
        ) : (
          <NoResult
            title={t("monitoring2.meals_box.no_results.title")}
            text={t("monitoring2.meals_box.no_results.desc")}
          />
        )}
      </Stack>
      <Drawer
        open={!!drawerSelected}
        onClose={() => setDrawerSelected(null)}
        variant="persistent"
        anchor="right"
        PaperProps={{ sx: { maxWidth: "796px", width: "100%" } }}
      >
        {drawerSelected?.type === "recipe" && (
          <RecipePreview
            recipeId={drawerSelected.id}
            onClose={() => setDrawerSelected(null)}
            actions={{
              like: false,
              delete: false,
              edit: false,
              favorite: false,
            }}
          />
        )}
        {drawerSelected?.type === "product" && (
          <ProductPreview
            productId={drawerSelected.id}
            onClose={() => setDrawerSelected(null)}
          />
        )}
      </Drawer>
    </>
  );
};

export default MealsBoxClient;
