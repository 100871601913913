import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useIrrigationProducts } from "@hooks/dictionary/useIrrigationProducts";
import { useMemo } from "react";
import { Box } from "@mui/material";
import {
  CustomLegend,
  LegendCircle,
  LegendItem,
  LegendText,
} from "@views/dietician/PatientMonitoring2/components/CustomChartLegend";
import { Props } from "recharts/types/component/DefaultLegendContent";
import { IrrigationProduct } from "@client/dictionaries/irrigationProducts";
import { Modify } from "@utils/customTypeScript";
import { PatientHydrationProductsMonitoringResource } from "@client/resources/PatientHydrationProductsMonitoringResource";

type IrrigationChartProps = {
  data: PatientHydrationProductsMonitoringResource[];
  tooltipLabelFormatter: (date: string) => string;
  xAxisTickFormatter: (date: string) => string;
};

const IrrigationChart = ({
  data,
  tooltipLabelFormatter,
  xAxisTickFormatter,
}: IrrigationChartProps) => {
  const { getTranslationById, getAll } = useIrrigationProducts();

  const dataForCharts = useMemo(
    () => mapDataToDataChart(data, getAll()),
    [data, getAll],
  );

  const bars = useMemo(() => {
    const products = getAll();
    return products
      .filter(p => p.parentId === null || p.parentId === undefined)
      .map(p => (
        <Bar
          key={p.id}
          dataKey={"p_" + p.id.toString()}
          name={p.name}
          stackId="a"
          fill={p.color ?? "#4242B8"}
        />
      ));
  }, [getAll, getTranslationById]);

  const renderLegend = (props: Props) => {
    const { payload } = props;
    return (
      <CustomLegend>
        {payload?.map((entry, index) => (
          <LegendItem key={`item-${index}`}>
            <LegendCircle style={{ background: entry.color }} />
            <LegendText>{entry.value}</LegendText>
          </LegendItem>
        ))}
      </CustomLegend>
    );
  };

  const renderTick = (text: string) => {
    return text.substring(5);
  };

  return (
    <Box width="100%" height="200px">
      <ResponsiveContainer>
        <ComposedChart
          data={dataForCharts}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
          barSize={8}
        >
          <XAxis
            dataKey="date"
            color="#F0F0F0"
            stroke="#A3A3A3"
            tickFormatter={xAxisTickFormatter}
          />
          <YAxis
            color="#F0F0F0"
            stroke="#A3A3A3"
            domain={[
              (dataMin: number) => Math.round(dataMin * 0.95),
              (dataMax: number) => Math.round(dataMax * 1.05),
            ]}
          />
          <CartesianGrid color="#F0F0F0" />
          <Tooltip
            cursor={{ fill: "#FBFAFC", stroke: "#F0F0F0" }}
            wrapperStyle={{
              background: "#fff",
              borderColor: "#F0F0F0",
              outline: "none",
            }}
            contentStyle={{
              background: "#fff",
              borderColor: "#F0F0F0",
              outline: "none",
            }}
            filterNull
            labelFormatter={tooltipLabelFormatter}
          />
          <Legend iconType="circle" iconSize={8} content={renderLegend} />
          <Line
            type="monotone"
            dataKey="target"
            stroke="#8884d8"
            name="Cel"
            dot={false}
            connectNulls
          />
          {bars}
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
};

const mapDataToDataChart = (
  data: IrrigationChartProps["data"],
  products: IrrigationProduct[],
) => {
  return data.map(item => {
    const result: {
      [index: string]: number | string | null;
      date: string;
    } = {
      date: item.date,
      target: item.goal ?? 0,
    };

    products
      .filter(p => p.parentId === null || p.parentId === undefined)
      .forEach(p => {
        result["p_" + p.id.toString()] = item.products
          .filter(product => product.product.id === p.id)
          .reduce((accumulator, currentValue) => {
            return accumulator + currentValue.milliliters;
          }, 0);
      });

    const hasParent = (
      p: IrrigationProduct,
    ): p is Modify<
      IrrigationProduct,
      {
        parentId: number;
      }
    > => !!p.parentId;

    products.filter(hasParent).forEach(p => {
      const value = result["p_" + p.parentId.toString()];
      result["p_" + p.parentId.toString()] =
        (value ? parseInt(value.toString()) : 0) +
        item.products
          .filter(product => product.product.id === p.id)
          .reduce((accumulator, currentValue) => {
            return accumulator + currentValue.milliliters;
          }, 0);
    });

    for (const [key, value] of Object.entries(result)) {
      if (["date", "goal"].includes(key)) {
        continue;
      }

      if (result[key] === 0) {
        result[key] = null;
      }
    }

    return result;
  });
};

export default IrrigationChart;
