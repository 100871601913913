import { RowType } from "@views/dietician/PatientProgramChoice/PatientProgramChoiceGrid";
import { Stack } from "@mui/material";
import Name from "@views/dietician/PatientProgramChoice/Grid/DetailsColumn/Name";
import { MacrosText } from "@components/MacrosText";
import { TagWithIcon } from "@components/TagWithIcon";
import { Clock, EmptyCalendar } from "@assets/icons";
import { useAppTranslation } from "@hooks";
import Duration from "@views/dietician/PatientProgramChoice/Grid/DetailsColumn/Duration";

type DetailsColumnProps = {
  row: RowType;
};

const DetailsColumn = ({ row }: DetailsColumnProps) => {
  const { t } = useAppTranslation();

  return (
    <Stack direction="column" gap="2px">
      <Name program={row} />
      <Stack direction="row" gap="16px">
        <MacrosText nutrients={row.nutrients} />
        <TagWithIcon
          Icon={EmptyCalendar}
          label={t("programs.day_types", { count: row.numberOfProgramDays })}
        />
        <Duration program={row} />
      </Stack>
    </Stack>
  );
};

export default DetailsColumn;
