import { ApiResponse } from "@typeDefinitions";
import * as yup from "yup";
import { fetchData } from "@utils/api";
import { CurrenciesDto } from "../fetchCurrencies";

export const fetchClinicCurrencies = async (): Promise<
  ApiResponse<CurrenciesDto[]>
> => {
  const response = await fetchData(`/dietitian/clinic/currencies`);
  await clinicCurrenciesSchema.validate(response.data);
  return response;
};

const currencies = ["PLN", "EUR", "USD", "GBP"];

const currencySchema = yup.object().shape({
  currency: yup.mixed().oneOf(currencies).required(),
  isDefault: yup.boolean().required(),
});

const clinicCurrenciesSchema = yup.array().of(currencySchema).required();
