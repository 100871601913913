import { styled } from "@mui/material";

interface CharCounterProps {
  error?: boolean;
}
export const CharCounter = styled("span")<CharCounterProps>`
  font-size: 10px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.4px;
  text-align: right;
  color: ${({ error, theme }) =>
    error ? theme.palette.error.main : theme.colors.neutral.dark[800]};
`;
