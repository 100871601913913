import KcalMacro from "@views/dietician/PatientMonitoring2/components/MealsBox/Client/KcalMacro";
import Macro from "@views/dietician/PatientMonitoring2/components/MealsBox/Client/Macro";
import { Stack } from "@mui/material";
import { useNutrients } from "@hooks";
import { CARBS_ID, FATS_ID, PROTEIN_ID } from "@utils/macros";

type MacrosProps = {
  kcal: number;
  protein: number;
  fat: number;
  carb: number;
};

const Macros = ({ kcal, protein, fat, carb }: MacrosProps) => {
  const { nutrientDict } = useNutrients();

  return (
    <Stack direction="row" spacing="8px" alignItems="center">
      <KcalMacro value={kcal} />
      <Macro
        label={nutrientDict.get(PROTEIN_ID)?.short ?? "B"}
        value={protein}
      />
      <Macro label={nutrientDict.get(FATS_ID)?.short ?? "T"} value={fat} />
      <Macro label={nutrientDict.get(CARBS_ID)?.short ?? "W"} value={carb} />
    </Stack>
  );
};

export default Macros;
