import { useFetchClinicDietitiansSimpleListQuery } from "@hooks/queries/settings";
import { useMemo } from "react";
import { SingleAutocompleteFilter } from "@components/Filters/AutocompleteFilter";
import { useAppTranslation } from "@hooks";

const DietitiansField = () => {
  const { t } = useAppTranslation();
  const { dietitians } = useFetchClinicDietitiansSimpleListQuery();
  const dietitiansMapped = useMemo(
    () =>
      (dietitians ?? []).map(dietitian => ({
        id: dietitian.id.toString(),
        label: dietitian.name,
      })),
    [dietitians],
  );

  return (
    <SingleAutocompleteFilter
      label={t("programs.author")}
      name="authorId"
      placeholder=""
      options={dietitiansMapped}
    />
  );
};

export default DietitiansField;
