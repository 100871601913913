import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useAppTranslation } from "@hooks";
import { emailSchemaFactory } from "@utils";

export const useRecoveryPasswordForm = () => {
  const { t } = useAppTranslation();

  const resolver = yup.object().shape({
    email: emailSchemaFactory(
      t("auth.recovery.validation.incorrect_email"),
    ).required(t("auth.recovery.validation.email")),
  });

  return useForm<RecoverFormInput>({
    resolver: yupResolver(resolver),
    reValidateMode: "onChange",
  });
};

interface RecoverFormInput {
  email: string;
}
