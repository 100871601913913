import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "./types";
import {
  cloneClientProgramToBase,
  CloneClientProgramToBaseRequest,
} from "@client";

export const useCloneClientProgramToBaseMutation = (
  patientId: number,
  programId: number,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, CloneClientProgramToBaseRequest>(
    payload => cloneClientProgramToBase(patientId, programId, payload),
    {
      ...options,
      onSuccess: () => {
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};
