import { Button, styled } from "@mui/material";

export const ContentEllipsis = styled("p")`
  max-width: 25.714rem;
  overflow: hidden;
`;

export const WithoutBackgroundButton = styled(Button)`
  justify-content: unset;
  padding: 0;
`;
