import { NutrientDto } from "@client";
import { DietNutrientDto } from "@client/diets/creator";
import { MACROS_ARRAY } from "@utils/macros";

import { NutrientWithTarget } from "../TabPanels";

export const mapTargetNutrients = (
  normNutrients: DietNutrientDto[] | undefined,
  dietTargets: NutrientDto[] | undefined,
  nutrients: NutrientDto[] | undefined,
  importantNutrients?: NutrientDto[],
) => {
  const nutrientsMap = new Map<number, NutrientWithTarget>();

  if (!normNutrients || !dietTargets) return nutrientsMap;

  const targetsMap = new Map<number, { value: number; show: boolean }>();

  normNutrients.forEach(({ nutrient: { id }, value, show }) => {
    if (!MACROS_ARRAY.includes(id)) targetsMap.set(id, { value, show });
  });

  dietTargets.forEach(({ id, value }) => {
    if (MACROS_ARRAY.includes(id))
      targetsMap.set(id, { value: value ?? 0, show: true });
  });

  importantNutrients?.forEach(({ id, value }) => {
    if (targetsMap.has(id)) {
      const target = targetsMap.get(id)!;
      targetsMap.set(id, { value: value ?? target.value, show: true });
    } else {
      targetsMap.set(id, { value: value ?? 0, show: true });
    }
  });

  if (nutrients) {
    nutrients.forEach(({ id, value }) => {
      const target = targetsMap.get(id);
      nutrientsMap.set(id, {
        id,
        value,
        target: target?.value || 0,
        visible: !!target?.show,
      });
    });
  }

  targetsMap.forEach((target, id) => {
    if (!nutrientsMap.has(id)) {
      nutrientsMap.set(id, {
        id,
        value: 0,
        target: target.value,
        visible: target.show,
      });
    }
  });

  return nutrientsMap;
};
