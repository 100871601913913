import { styled } from "@mui/material";

export const Img = styled("img")`
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: 0.25rem;
  margin: 1.5rem auto;
  height: 22.5rem;
`;

export const ListElement = styled("ul")`
  display: list-item;
  list-style: disc;
  padding-left: 2rem;
`;
