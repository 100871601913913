import { useQuery } from "@tanstack/react-query";

import { QueryOptions } from "./types";
import { buildDefaultQueryOptions } from "./common";
import { fetchCurrencies } from "@client";

export const fetchCurrenciesQueryKey = "fetchCurrencies";

export const useFetchCurrencies = (options?: QueryOptions) => {
  const { data, ...rest } = useQuery(
    [fetchCurrenciesQueryKey],
    () => fetchCurrencies(),
    buildDefaultQueryOptions(options),
  );

  return { currencies: data?.data, ...rest };
};
