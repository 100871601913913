import { styled, Typography } from "@mui/material";
import { CreateEventButton } from "../../MiniCalendar/MiniCalendar.styled";

export const GridContainer = styled("div")`
  background-color: white;
  border-radius: 16px;
  width: 100%;
`;

export const GridHeaderContainer = styled("div")`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
`;

export const GridHeaderItem = styled("div")`
  border-right: 1px solid ${({ theme }) => theme.colors.gray200};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
  padding: 6px;
`;

export const GridHeaderItemText = styled(Typography)<{ active?: boolean }>`
  background-color: ${({ theme }) => theme.palette.primary.light};
  color: ${({ theme }) => theme.colors.neutral.dark["900"]};
  margin: 0;
  padding: 8px 0;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.4px;
`;

export const GridBody = styled("div")`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
`;

export const GridItem = styled("div")<{ isCurrentMonth: boolean }>`
  border-right: 1px solid ${({ theme }) => theme.colors.gray200};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
  height: 160px;
  padding: 4px 8px;
  opacity: ${({ isCurrentMonth }) => (isCurrentMonth ? 1 : 0.5)};
`;

export const GridItemText = styled(Typography)<{ isToday: boolean }>`
  font-size: 14px;
  font-weight: ${({ isToday }) => (isToday ? 700 : 600)};
  color: ${({ theme, isToday }) =>
    isToday ? theme.palette.primary.main : "inherit"};
  text-align: right;
  padding-top: 8px;
  cursor: pointer;
`;

export const EventItemMonthViewTextWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 4px;
`;

export const EventItemMonthViewContent = styled("p")`
  display: flex;
  gap: 4px;
  align-items: center;
  background-color: #fff5e6;
  padding: 2px;
  border-radius: 4px;
`;

export const AddNewEventButton = styled(CreateEventButton)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
