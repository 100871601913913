import { forwardRef, ReactNode } from "react";

import classNames from "classnames";

/**
 * universal tile button with icon inside
 */
export const TileButton = forwardRef<HTMLDivElement, TileButtonProps>(
  (props, ref) => {
    const { text, onClick, icon, className, disabled } = props;

    return (
      <div
        ref={ref}
        onClick={!disabled ? onClick : undefined}
        className={classNames(
          "text-center text-gray-700 hover:text-primary cursor-pointer",
          className,
        )}
      >
        <div
          className={classNames({
            "mb-2 rounded-md p-8 bg-gray-200 border border-gray-600 hover:border-primary hover:bg-secondary-lighter":
              true,
          })}
        >
          {icon}
        </div>
        {text && <p className="w-full text-sm">{text}</p>}
      </div>
    );
  },
);

interface TileButtonProps {
  /** on click handler */
  onClick?: () => void;
  /** text under tile */
  text?: string;
  /** icon inside tile */
  icon: ReactNode;
  /** additional class names */
  className?: string;
  /** disabled flag */
  disabled?: boolean;
}
