import { useCallback, useMemo } from "react";
import { Control, Path, useController, useWatch } from "react-hook-form";

import {
  ENERGY_PER_GRAM_OF_CARB,
  ENERGY_PER_GRAM_OF_FAT,
  ENERGY_PER_GRAM_OF_PROTEIN,
} from "@utils/macros";

import { MacroSlider } from "./";
import { MacrosSlidersFormInput } from "../";
import { labelFactory, sumMacros } from "../macrosUtils";
import { FieldValues } from "react-hook-form/dist/types";
import { round } from "lodash";

interface FormEnergySliderProps<T extends FieldValues> {
  control: Control<T>;
  label: string;
  unit: string;
}

export const FormEnergySlider = <T extends MacrosSlidersFormInput>({
  control,
  unit,
  label,
}: FormEnergySliderProps<T>) => {
  const {
    field: { value, onChange },
  } = useController({
    control,
    name: "kcal" as Path<T>,
  });

  const protein = useWatch({ control, name: "protein" as Path<T> });
  const fat = useWatch({ control, name: "fat" as Path<T> });
  const carbs = useWatch({ control, name: "carbs" as Path<T> });

  const valueLabelFormat = useMemo(
    () => labelFactory(round(value), kcalMin, kcalMax),
    [value],
  );

  const onSliderChange = useCallback(
    (newValue: number) => onChange(newValue),
    [onChange],
  );
  const onInputChange = useCallback(
    (newValue: string) => onChange(parseInt(newValue) || 0),
    [onChange],
  );

  const onCalc = useCallback(() => {
    const sum = sumMacros(fat, carbs, protein);
    onChange(Math.max(kcalMinInput, sum));
  }, [
    fat,
    carbs,
    protein,
    onChange,
    ENERGY_PER_GRAM_OF_CARB,
    ENERGY_PER_GRAM_OF_FAT,
    ENERGY_PER_GRAM_OF_PROTEIN,
  ]);

  return (
    <MacroSlider
      unit={unit}
      label={label}
      min={kcalMin}
      max={kcalMax}
      value={value}
      onCalc={onCalc}
      step={100}
      onSliderChange={onSliderChange}
      onInputChange={onInputChange}
      valueLabelFormat={valueLabelFormat}
    />
  );
};

const kcalMinInput = 100;
const kcalMin = 500;
const kcalMax = 6000;
