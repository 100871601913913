import {
  TagAutocompleteFilterWrapper,
  FilterType,
  FoodTreeSelectFilterWrapper,
  MultiSelectFilterWrapper,
  OnlyPolishFilterWrapper,
  SingleAutocompleteFilterWrapper,
  SingleSelectChipFilterWrapper,
  SingleSelectFilterWrapper,
  SliderFilterWrapper,
  AutocompleteFilterWrapper,
} from "@components/Filters";
import { useAppTranslation } from "./useAppTranslation";
import { FieldValues, Path } from "react-hook-form";

export const useListTabs = () => {
  const { t } = useAppTranslation();

  const regularTabs = [
    {
      label: t("filters.tabs.all"),
      id: ListTabs.ALL,
    },
    {
      label: t("filters.tabs.latest"),
      id: ListTabs.LATEST,
    },
    {
      label: t("filters.tabs.favourite"),
      id: ListTabs.FAVORITE,
    },
    {
      label: t("filters.tabs.own"),
      id: ListTabs.OWN,
    },
  ];

  const adminTabs = [
    ...regularTabs,
    {
      label: t("filters.tabs.unverified"),
      id: ListTabs.UNVERIFIED,
    },
  ];

  const unshiftListTabFilter = <T extends FieldValues>(
    list: (
      | TagAutocompleteFilterWrapper
      | AutocompleteFilterWrapper<T>
      | SingleAutocompleteFilterWrapper<T>
      | MultiSelectFilterWrapper<T>
      | SingleSelectFilterWrapper<T>
      | SliderFilterWrapper<T>
      | OnlyPolishFilterWrapper<T>
      | FoodTreeSelectFilterWrapper<T>
      | SingleSelectChipFilterWrapper<T>
    )[],
    label: string,
    isAdmin?: boolean,
  ): (
    | TagAutocompleteFilterWrapper
    | AutocompleteFilterWrapper<T>
    | SingleAutocompleteFilterWrapper<T>
    | MultiSelectFilterWrapper<T>
    | SingleSelectFilterWrapper<T>
    | SliderFilterWrapper<T>
    | OnlyPolishFilterWrapper<T>
    | FoodTreeSelectFilterWrapper<T>
    | SingleSelectChipFilterWrapper<T>
  )[] => {
    return [
      {
        type: FilterType.SingleSelectChip,
        props: {
          label,
          options: isAdmin ? adminTabs : regularTabs,
          name: "tabs" as Path<T>,
        },
      },
      ...list,
    ];
  };

  return {
    regularTabs,
    adminTabs,
    unshiftListTabFilter,
  };
};

export enum ListTabs {
  ALL = "all",
  LATEST = "latest",
  FAVORITE = "favorite",
  OWN = "own",
  UNVERIFIED = "reviewed",
}
