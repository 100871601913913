import { useContext, useState } from "react";
import { ReactSortable } from "react-sortablejs";

import { LoadingButton } from "@mui/lab";
import { IconButton, useMediaQuery, useTheme } from "@mui/material";

import { List } from "@assets/icons";
import { Close } from "@assets/icons/DesignSystem";
import { PlusRounded } from "@assets/icons/PlusRounded";
import { useAppParams, useAppTranslation } from "@hooks";
import { useUpdateSurveyGroupsSortMutation } from "@hooks/queries/surveys";
import { usePostSurveyGroupMutation } from "@hooks/queries/surveys/usePostSurveyGroupMutation";
import { detectMovedElement } from "@views/dietician/product-views/components/ProductMeasures/utils/move";

import {
  INTRODUCTION,
  TableOfContentsContext,
} from "@context/TableOfContentsContext";
import { GroupElement } from "./GroupElement";
import {
  CardStyled,
  CloseIconButton,
  HeaderText,
  IntroductionTitle,
  SmallCardStyled,
} from "./TableOfContents.styled";

interface SubgroupsIf {
  id: number;
  name: string;
}
export interface GroupIf {
  id: number;
  name: string;
  subgroups: SubgroupsIf[];
}
interface TableOfContentsProps {
  groups: GroupIf[];
  edit?: boolean;
  hideIntroduction?: boolean;
}
export const TableOfContents = ({
  groups,
  edit,
  hideIntroduction,
}: TableOfContentsProps) => {
  const { t } = useAppTranslation();
  const { selectedId, setSelectedId, setGroupId } = useContext(
    TableOfContentsContext,
  );
  const { breakpoints } = useTheme();
  const isDownMedium = useMediaQuery(`${breakpoints.down("medium")}`);
  const [hideTable, setHideTable] = useState(false);
  const { questionnaireId } = useAppParams();
  const { mutate: updateSort } = useUpdateSurveyGroupsSortMutation();
  const { mutate: createGroup, isLoading } = usePostSurveyGroupMutation();
  const handleNewGroup = () => {
    createGroup(
      {
        id: questionnaireId,
        payload: { description: null, title: t("questionnaires.new_group") },
      },
      {
        onSuccess: ({ data: { id } }) => {
          setGroupId(id);
          setSelectedId(id.toString());
        },
      },
    );
  };

  const onSelectIntroduction = () => {
    setSelectedId(INTRODUCTION);
  };

  if (isDownMedium && hideTable)
    return (
      <SmallCardStyled>
        <IconButton color="primary" onClick={() => setHideTable(false)}>
          <List />
        </IconButton>
      </SmallCardStyled>
    );

  return (
    <CardStyled hidden={hideTable}>
      <HeaderText>
        {t("questionnaires.table_of_contents").toUpperCase()}
      </HeaderText>

      {isDownMedium && (
        <CloseIconButton onClick={() => setHideTable(true)} color="primary">
          <Close />
        </CloseIconButton>
      )}
      <div className="grid gap-4">
        {!hideIntroduction && (
          <IntroductionTitle
            onClick={onSelectIntroduction}
            color={selectedId === "introduction" ? "primary" : ""}
          >
            {t("questionnaires.introduction")}
          </IntroductionTitle>
        )}
        <ReactSortable
          disabled={!edit}
          forceFallback
          scrollSensitivity={100}
          animation={200}
          handle=".dragHandle-group"
          list={groups}
          setList={newOrder => {
            const result = detectMovedElement(
              groups.map(l => l.id.toString()),
              newOrder.map(n => n.id.toString()),
            );

            if (result)
              updateSort({
                id: questionnaireId,
                payload: { ids: newOrder.map(g => g.id) },
              });
          }}
          className="grid gap-4"
        >
          {groups.map(({ id, name, subgroups }) => (
            <GroupElement
              id={id.toString()}
              key={id}
              subgroups={subgroups}
              title={name}
              edit={edit}
            />
          ))}
        </ReactSortable>
      </div>

      {edit && (
        <LoadingButton
          variant="outlined"
          loading={isLoading}
          onClick={handleNewGroup}
          startIcon={<PlusRounded size="w-3 h-3" className="mr-[0.375rem]" />}
        >
          {t("questionnaires.add_group")}
        </LoadingButton>
      )}
    </CardStyled>
  );
};
