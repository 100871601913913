import { fetchData } from "@utils/api";
import { APIMethods, NoContentApiResponse } from "@typeDefinitions";

export type UpdatePatientHydrationProductParams = {
  patientId: number;
};

export type UpdatePatientHydrationProductResponse = NoContentApiResponse;

export type UpdatePatientHydrationProductPayload = {
  hydration_product: {
    id: number;
  };
  goal: number;
};

export const updatePatientHydrationProducts = async (
  params: UpdatePatientHydrationProductParams,
  payload: UpdatePatientHydrationProductPayload,
): Promise<UpdatePatientHydrationProductResponse> => {
  return await fetchData(
    "/dietitian/patients/{patient_id}/hydration-product".replace(
      "{patient_id}",
      params.patientId.toString(),
    ),
    APIMethods.PUT,
    payload,
  );
};
