import { useQuery } from "@tanstack/react-query";

import { QueryOptionsTyped } from "./types";
import { buildDefaultQueryOptions } from "./common";
import { fetchProgramPdfBasic, FetchProgramPdfBasicResponse } from "@client";

export const fetchProgramPdfBasicQuery = "fetchProgramPdfBasicQuery";

export const useFetchProgramPdfBasicQuery = (
  id: number,
  options?: QueryOptionsTyped<FetchProgramPdfBasicResponse>,
) => {
  const { data, ...rest } = useQuery(
    [fetchProgramPdfBasicQuery, id],
    () => fetchProgramPdfBasic(id),
    buildDefaultQueryOptions(options),
  );
  return {
    pdfData: data?.data,
    ...rest,
  };
};
