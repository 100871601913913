import MeasurementPhotosItem from "@components/Client/PatientBodyMeasurementsGalleryModal/MeasurementPhotosItem";
import { CircularProgress, Stack } from "@mui/material";
import { Dispatch, useCallback, useRef } from "react";
import { useAppTranslation } from "@hooks";
import { ImagePreview } from "@components/Client/PatientBodyMeasurementsGalleryModal/MeasurementPhotosContent";
import { FetchClientMeasurementListResponse } from "@client";

type LeftSideProps = {
  imagePreview: ImagePreview;
  setImagePreview: Dispatch<ImagePreview>;
  measurements: FetchClientMeasurementListResponse[];
  request: {
    hasNextPage?: boolean;
    fetchNextPage: () => void;
    isFetching: boolean;
    isLoading: boolean;
  };
};

const LeftSide = ({
  imagePreview,
  setImagePreview,
  measurements,
  request,
}: LeftSideProps) => {
  const observer = useRef<IntersectionObserver>();

  const { t } = useAppTranslation();

  const lastElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (request.isLoading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(entries => {
        if (
          entries[0].isIntersecting &&
          request.hasNextPage &&
          !request.isFetching
        ) {
          request.fetchNextPage();
        }
      });

      if (node) observer.current.observe(node);
    },
    [
      request.fetchNextPage,
      request.hasNextPage,
      request.isFetching,
      request.isLoading,
    ],
  );

  return (
    <Stack
      direction="column"
      gap="8px"
      overflow="auto"
      className="scrollbar-thin"
      height="100%"
    >
      {measurements.map((measurement, index) => (
        <div
          ref={index + 1 === measurements.length ? lastElementRef : undefined}
        >
          <MeasurementPhotosItem
            key={measurement.id}
            measurement={measurement}
            imagePreview={imagePreview}
            setImagePreview={setImagePreview}
          />
        </div>
      ))}
      {(request.isLoading || request.isFetching) && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      )}
    </Stack>
  );
};

export default LeftSide;
