import { MouseEvent, ReactNode, useState } from "react";
import { ConfirmationPopover } from "@components/ConfirmationPopper";
import { IconButton } from "@mui/material";

interface ConfirmationIconButtonProps {
  icon: ReactNode;
  onConfirm: () => void;
  confirmationText: string;
  submitText: string;
  disabled?: boolean;
  closeTooltip?: () => void;
  loading?: boolean;
  color?:
    | "primary"
    | "inherit"
    | "default"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | undefined;
}

export const ConfirmationIconButton = (props: ConfirmationIconButtonProps) => {
  const {
    icon,
    onConfirm,
    confirmationText,
    submitText,
    disabled = false,
    closeTooltip,
    loading = false,
    color = "primary",
  } = props;

  const [anchorEl, setAnchorEl] = useState<EventTarget & HTMLElement>();
  const handleClickAnchor = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
    closeTooltip && closeTooltip();
  };

  return (
    <>
      <IconButton onClick={handleClickAnchor} disabled={disabled} color={color}>
        {icon}
      </IconButton>
      <ConfirmationPopover
        onConfirm={onConfirm}
        anchor={anchorEl}
        confirmationText={confirmationText}
        submitText={submitText}
        setAnchor={setAnchorEl}
        loading={loading}
      />
    </>
  );
};
