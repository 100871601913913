import { useMemo, useState } from "react";

import { TabContext } from "@mui/lab";
import { CircularProgress } from "@mui/material";

import { ProductTabs } from "@components/PreviewDrawer/Product";
import { ProductTabPanels } from "@components/PreviewDrawer/Product/ProductTabPanels";
import { TabSwitch } from "@components/TabSwitch/TabSwitch";
import { useAppTranslation } from "@hooks";
import { useSearchDietsPreviewProductItemQuery } from "@hooks/queries/diets";

import { TabsWrapper } from "./common.styled";

interface ProductDetailsProps {
  dietId: string;
  mealId: string;
  itemId: string;
}

export const ProductDetails = ({
  dietId,
  mealId,
  itemId,
}: ProductDetailsProps) => {
  const { t } = useAppTranslation();
  const { product, isLoading } = useSearchDietsPreviewProductItemQuery(
    dietId,
    mealId,
    itemId,
  );
  const [tab, setTab] = useState(ProductTabs.NUTRITIONAL_VALUES);

  const tabs = useMemo(
    () => [
      {
        label: t("common.nutrients_values"),
        id: ProductTabs.NUTRITIONAL_VALUES,
      },
      {
        label: t("common.tags"),
        id: ProductTabs.TAGS,
      },
    ],
    [t],
  );

  const tagsMapped = useMemo(
    () => product?.product.tags.map(t => t.id) ?? [],
    [product?.product.tags],
  );

  if (isLoading || !product)
    return (
      <div className="grid place-content-center">
        <CircularProgress />
      </div>
    );

  const { nutrients, measures } = product.product;

  return (
    <>
      <TabsWrapper>
        <TabSwitch value={tab} onChange={v => setTab(v)} tabs={tabs} />
      </TabsWrapper>
      <TabContext value={tab}>
        <ProductTabPanels
          nutrients={nutrients}
          tags={tagsMapped}
          measures={measures}
        />
      </TabContext>
    </>
  );
};
