import { Button, Card, Dialog, IconButton, styled } from "@mui/material";

export const ContentSelectorWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex: 1;
`;

export const AlertCardStyled = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex: 1;
  padding: 1.5rem;
  border-radius: 1rem;
  max-width: 41rem;
  background: ${({ theme }) => theme.palette.primary.medium};
  box-shadow: ${({ theme }) => theme.boxShadows.card};
`;

export const TitleStyled = styled("span")`
  align-self: center;
  width: 100%;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const TextContent = styled("span")`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
  white-space: pre-line;
`;

export const QuestionnaireAnswerStyled = styled("div")`
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallNew}px) {
    max-width: 41rem;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.smallNew}px) {
    padding-bottom: 4rem;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.smallNew}px) and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallMax}px) {
    max-width: 56.25rem;
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.medium}px) {
    max-width: 62.375rem;
  }
`;

export const TableOfContentsWrapper = styled("div")`
  position: sticky;
  top: 0;
`;

export const DialogStyled = styled(Dialog)`
  & .MuiDialogContent-root {
    width: 64.375rem;
  }
`;
export const ButtonStyled = styled(Button)`
  & svg {
    width: 1rem;
    height: 1rem;
  }
`;

export const IconButtonStyled = styled(IconButton)`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
`;
