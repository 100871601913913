import { Autocomplete, styled, TextField } from "@mui/material";
import classnames from "classnames";
import { range } from "lodash";

import { useAppTranslation } from "@hooks";
import { ThemeProviderWrapperNew } from "themeNew";

export const RecipeServingsEditor = ({
  servings,
  totalServings,
  setServings,
  className,
}: RecipeServingsEditorProps) => {
  const { t } = useAppTranslation();

  const options = range(0, totalServings).map(servings => ({
    id: servings + 1,
    label: `${t("recipe.recipe_complex", { count: servings + 1 })}`,
  }));
  const value = options.find(({ id }) => id === servings);
  return (
    <div className={classnames("w-[130px] grid gap-[4px]", className)}>
      <span className="font-semibold text-xs">{t("products.portions")}</span>
      <ThemeProviderWrapperNew>
        <Autocomplete
          size="small"
          fullWidth
          options={options}
          value={value}
          renderInput={p => <TextField {...p} />}
          onChange={(e, value) => value && setServings(value?.id)}
          renderOption={(props, option) => (
            <OptionStyled {...props} key={option.id}>
              {option.label}
            </OptionStyled>
          )}
          disableClearable
        />
      </ThemeProviderWrapperNew>
    </div>
  );
};

export interface RecipeServingsEditorProps {
  servings: number;
  totalServings: number;
  setServings: (servings: number) => void;
  className?: string;
}

const OptionStyled = styled("li")`
  white-space: nowrap;
`;
