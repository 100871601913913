import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export interface SectionDto {
  id: number;
  title: string;
  titleEn: string;
  content: string;
  contentEn: string;
  isSystemic: boolean;
}

export const fetchProgramSections = async (
  programId: number,
): Promise<ApiResponse<ContentlessSectionDto[]>> => {
  const response = await fetchData(
    `/dietitian/programs/${programId}/sections/categories`,
    APIMethods.GET,
  );
  return await FetchDietitianProgramSectionsCategorySchema.validate(response);
};

interface ContentlessSectionDto {
  id: number;
  title: string | null;
  titleEn: string | null;
  isSystemic: boolean;
}

const sectionSchema = yup.object().shape({
  id: yup.number().required(),
  title: yup.string().nullable().default(null),
  titleEn: yup.string().nullable().default(null),
  isSystemic: yup.boolean().required(),
});

const FetchDietitianProgramSectionsCategorySchema = yup.object().shape({
  data: yup.array().of(sectionSchema).required(),
});
