import { memo } from "react";

import { useAppTranslation } from "@hooks";
import { Tag } from "@components/Tag";
import { MuiInfoClickTooltip } from "@components/MuiInfoClickTooltip";

interface ExpandableTagsListProps {
  tags: string[];
  maxCount?: number;
}

export const ExpandableRawTagsList = memo(
  ({ tags, maxCount = 4 }: ExpandableTagsListProps) => {
    const { t } = useAppTranslation();

    return (
      <div className={"flex gap-3 flex-wrap"}>
        {tags.slice(0, maxCount).map((t, i) => (
          <Tag key={i} title={t} />
        ))}
        {tags.length > maxCount && (
          <MuiInfoClickTooltip
            onHoverOff
            noIcon
            placement="bottom"
            content={
              <div className="flex flex-wrap gap-3">
                {tags.slice(maxCount, tags.length).map((t, i) => (
                  <Tag key={i} title={t} />
                ))}
              </div>
            }
          >
            <p>{t("common.more").toLowerCase()}</p>
          </MuiInfoClickTooltip>
        )}
      </div>
    );
  },
);
