import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useAppTranslation } from "@hooks";
import { Box, styled, Tooltip } from "@mui/material";
import {
  CARBS_ID,
  ENERGY_ID,
  ENERGY_PER_GRAM_OF_CARB,
  ENERGY_PER_GRAM_OF_FAT,
  ENERGY_PER_GRAM_OF_PROTEIN,
  FATS_ID,
  getEnergyPerGramOfNutrient,
  PROTEIN_ID,
} from "@utils/macros";
import { InfoIcon } from "@icons/index";
import { IconButton } from "@components/Alloweat/IconButton";
import { useMemo } from "react";
import { getMacroKcal } from "@views/dietician/PatientMonitoring2/components/NutrientValues/MacroTable/EnergyUtils";

type MacroTableProps = {
  nutrients: {
    nutrient: { id: number };
    patient: {
      eaten: number;
      target: number;
    };
    dietitian: {
      target: number;
    };
  }[];
};

const MacroTable = ({ nutrients }: MacroTableProps) => {
  const { t } = useAppTranslation();

  const macroKcal = useMemo(() => {
    const protein = nutrients.find(n => n.nutrient.id === PROTEIN_ID);
    const fat = nutrients.find(n => n.nutrient.id === FATS_ID);
    const carb = nutrients.find(n => n.nutrient.id === CARBS_ID);

    const macroKcal = getMacroKcal({
      protein: {
        value: protein?.patient.eaten ?? 0,
        target: protein?.dietitian.target,
      },
      fat: {
        value: fat?.patient.eaten ?? 0,
        target: fat?.dietitian.target,
      },
      carb: {
        value: carb?.patient.eaten ?? 0,
        target: carb?.dietitian.target,
      },
    });

    return Math.max(macroKcal.target ?? macroKcal.value, 1);
  }, [nutrients]);

  return (
    <CustomTable sx={{ borderCollapse: "separate", borderSpacing: "0px 8px" }}>
      <TableHead>
        <TableRow className={"custom-table-head"}>
          <TableCell colSpan={1}></TableCell>
          <TableCell colSpan={2} sx={{ position: "relative" }}>
            <FromAppBorder />
            <FromAppLabel>
              {t("monitoring2.nutritional_box.table.from_app")}
            </FromAppLabel>
          </TableCell>
          <TableCell colSpan={2}></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("monitoring2.nutritional_box.table.value")}</TableCell>
          <TableCell align="right">
            {t("monitoring2.nutritional_box.table.eaten")}
          </TableCell>
          <TableCell align="right">
            {t("monitoring2.nutritional_box.table.planned")}
          </TableCell>
          <TableCell align="right">
            {t("monitoring2.nutritional_box.table.from_dietitian")}
          </TableCell>
          <TableCell align="right">
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="end"
              gap="4px"
            >
              <span>{t("monitoring2.nutritional_box.table.bilans")}</span>
              <Tooltip title={t("monitoring2.nutritional_box.table.tooltip")}>
                <IconButton>
                  <InfoIcon color="#727272" />
                </IconButton>
              </Tooltip>
            </Box>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {renderRow({
          color: "#7448D0",
          name: t("common.calories"),
          nutrients: nutrients,
          nutrientId: ENERGY_ID,
          macroKcal,
        })}
        {renderRow({
          color: "#FADA69",
          name: t("common.proteins"),
          nutrients: nutrients,
          nutrientId: PROTEIN_ID,
          macroKcal,
        })}
        {renderRow({
          color: "#FAB569",
          name: t("common.fats"),
          nutrients: nutrients,
          nutrientId: FATS_ID,
          macroKcal,
        })}
        {renderRow({
          color: "#3AB795",
          name: t("common.carbs"),
          nutrients: nutrients,
          nutrientId: CARBS_ID,
          macroKcal,
        })}
      </TableBody>
    </CustomTable>
  );
};

const renderRow = ({
  color,
  name,
  nutrientId,
  nutrients,
  macroKcal,
}: {
  color: string;
  name: string;
  nutrientId: number;
  nutrients: MacroTableProps["nutrients"];
  macroKcal: number;
}) => {
  const getNutrient = () => nutrients.find(n => n.nutrient.id === nutrientId);
  const getPatientEaten = () => Math.round(getNutrient()?.patient.eaten ?? 0);

  const getPatientTarget = () => Math.round(getNutrient()?.patient.target ?? 0);
  const getDietitianTarget = () =>
    Math.round(getNutrient()?.dietitian.target ?? 0);

  const getMacroPercent = () => {
    if (nutrientId === ENERGY_ID) {
      return "";
    }

    const kcalPerOneGram = getEnergyPerGramOfNutrient(nutrientId);

    const percent =
      getDietitianTarget() > 0
        ? Math.round(
            ((getDietitianTarget() * kcalPerOneGram) / macroKcal) * 100,
          )
        : Math.round(((getPatientEaten() * kcalPerOneGram) / macroKcal) * 100);

    return ` (${percent}%)`;
  };

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <NameCellBox>
          <Circle sx={{ background: color }} />
          {name}
          {getMacroPercent()}
        </NameCellBox>
      </TableCell>
      <TableCell align="right">{getPatientEaten()}</TableCell>
      <TableCell align="right">{getPatientTarget()}</TableCell>
      <TableCell align="right">{getDietitianTarget()}</TableCell>
      <TableCell align="right">
        <BilansValue>
          {getDietitianTarget() - getPatientEaten() < 0 ? "+" : ""}
          {(getDietitianTarget() - getPatientEaten()) * -1}{" "}
        </BilansValue>
        {nutrientId === ENERGY_ID ? "kcal" : "g"}
      </TableCell>
    </TableRow>
  );
};

const Circle = styled("div")`
  margin: 4px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: block;
`;

const FromAppLabel = styled("span")`
  position: absolute;
  top: -5px;
  left: 4px;
  font: var(--font-figtree-medium);
  font-size: 10px;
  color: #727272;
  background: #fff;
`;

const FromAppBorder = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  border: dashed 1px #7448d030;
  height: 260px;
  width: 100%;
  border-radius: 8px;
`;

const CustomTable = styled(Table)`
  .MuiTableCell-root {
    padding: 10px;
    border: none;
    line-height: 20px;
    font-size: 12px;
    font: var(--font-figtree-medium);
  }
  .MuiTableCell-root:first-child {
    padding-left: 12px;
  }
  .MuiTableCell-root:last-child {
    padding-right: 12px;
  }

  .MuiTableHead-root {
    .MuiTableCell-root {
      color: #727272;
    }
    .custom-table-head {
      .MuiTableCell-root {
        padding: 0;
      }
    }
  }

  .MuiTableBody-root {
    .MuiTableRow-root {
    }
    .MuiTableCell-root {
      color: #333333;
      background: #fbfafc;
    }
    .MuiTableCell-root:first-child {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }
    .MuiTableCell-root:last-child {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
`;

const NameCellBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const BilansValue = styled("span")`
  font: var(--font-figtree-semi-bold);
  font-size: 12px;
  line-height: 20px;
`;

export default MacroTable;
