import { useMutation } from "@tanstack/react-query";
import { MutationOptions } from "./types";
import { login, LoginRequest } from "@client";
import { AxiosError } from "axios";
import { ApiErrorIf } from "@typeDefinitions";

export const useLoginMutation = (options?: MutationOptions) => {
  const { mutate, ...rest } = useMutation<
    unknown,
    AxiosError<ApiErrorIf>,
    LoginRequest
  >(payload => login(payload), options);

  return {
    login: mutate,
    ...rest,
  };
};
