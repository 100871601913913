import { createContext, PropsWithChildren, useContext } from "react";

export const IdMapContext = createContext<IdMapContextIf>({ idMap: {} });
export const useIdMapContext = () => useContext(IdMapContext);

export const IdMapContextProvider = ({
  children,
  idMap,
}: PropsWithChildren<IdMapContextIf>) => {
  return (
    <IdMapContext.Provider value={{ idMap }}>{children}</IdMapContext.Provider>
  );
};

interface IdMapContextIf {
  idMap: Record<string, number>;
}
