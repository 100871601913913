import React from "react";

import { useBasicNutrientsGetter } from "@hooks";
import { Nutrient } from "@typeDefinitions";

import { PCFValue } from "../../PCFValue";
import { MacroCellWrapper } from "./MacroCellWrapper.styled";
import { MealCellWrapper } from "./MealCellWrapper.styled";
import { TableContentWrapper } from "./TableContentWrapper.styled";

export interface ProgramMealValuesProps {
  name: string;
  hour: string | null; //"hh:mm"
  nutrients?: Nutrient[];
  targetKcal: number;
}

export const ProgramMealValues = ({
  name,
  hour,
  nutrients,
  targetKcal,
}: ProgramMealValuesProps) => {
  const { kcal, protein, fat, carbs } = useBasicNutrientsGetter(nutrients);

  return (
    <>
      <TableContentWrapper colSpan={3} justifyStart>
        <MealCellWrapper className="font-semibold">{name}</MealCellWrapper>
        <MealCellWrapper>{hour?.slice(0, 5)}</MealCellWrapper>
      </TableContentWrapper>
      <TableContentWrapper colSpan={2} border bold>
        {targetKcal && `${targetKcal.toFixed()} kcal`}
      </TableContentWrapper>
      <TableContentWrapper colSpan={7}>
        <MacroCellWrapper bold>
          {kcal && `${kcal.toFixed()} kcal`}
        </MacroCellWrapper>
        <MacroCellWrapper>
          <PCFValue value={protein} name="protein" />
        </MacroCellWrapper>
        <MacroCellWrapper>
          <PCFValue value={fat} name="fats" />
        </MacroCellWrapper>
        <MacroCellWrapper>
          <PCFValue value={carbs} name="carbs" />
        </MacroCellWrapper>
      </TableContentWrapper>
    </>
  );
};
