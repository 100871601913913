import { Control, Path } from "react-hook-form";

import classNames from "classnames";

import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

import { useAppTranslation } from "@hooks";
import { useMacrosChart } from "./useMacrosChart";
import { FieldValues } from "react-hook-form/dist/types";
import { FormSelect } from "@components/FormSelect";
import { SelectOption } from "@components/SelectOption";

export const MacrosChart = <T extends { chartNumberOfPortions: number }>(
  props: MacrosChartProps<T>,
) => {
  const { data, control, numOfPortions } = props;
  const { t } = useAppTranslation();

  const {
    PIE_CHART_COLORS,
    macros,
    caloriesSummed,
    numOfPortionsAvaliable,
    renderCustomizedLabel,
    translateNumOfPortions,
  } = useMacrosChart(data, numOfPortions);

  return (
    <div className="w-full border border-gray-400 rounded-md">
      <div className="flex items-center justify-between w-full bg-gray-300 border-b border-gray-400 px-4 h-14">
        <p className="text-lg">{t("recipe.macros_chart.title")}</p>
        <FormSelect
          disabled={numOfPortions === 1}
          textCenter
          bgTransparent
          name={"chartNumberOfPortions" as Path<T>}
          control={control}
          className="w-28"
        >
          {numOfPortionsAvaliable.map((option, index) => (
            <SelectOption textCenter key={`option-${index}`} value={option}>
              {`${option.toString()} ${translateNumOfPortions(option)}`}
            </SelectOption>
          ))}
        </FormSelect>
      </div>
      <div className="flex flex-col items-center justify-center w-full p-4">
        <div className="flex flex-col w-full">
          <p className="text-lg mb-2">
            <span className="mr-1">Kcal:</span>
            <span className="font-sans">{caloriesSummed}</span>
          </p>
          <div className="flex">
            {macros.map((macro, index) => (
              <div
                key={`macro-legend-${index}`}
                className={classNames({
                  "flex items-center": true,
                  "mr-4": index !== data.length - 1,
                })}
              >
                <div
                  className="w-4 h-4 rounded-full mr-1"
                  style={{ backgroundColor: PIE_CHART_COLORS[index] }}
                />
                <p>
                  <span className="mr-1">{macro.prefix}: </span>
                  <span className="font-sans">{macro.valueInGrams}</span>
                  <span className="font-sans">g</span>
                </p>
              </div>
            ))}
          </div>
        </div>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart height={300}>
            <Pie
              data={macros.filter(macro => macro.value)}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              fill="#82ca9d"
              labelLine={false}
              isAnimationActive={false}
              label={renderCustomizedLabel}
            >
              {data.map((_, index) => (
                <Cell key={`cell-${index}`} fill={PIE_CHART_COLORS[index]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export interface MacrosChartData {
  id: number;
  name: string;
  value: number;
  valueInGrams: number;
  prefix: string;
}

export interface MacrosChartProps<T extends FieldValues> {
  control: Control<T>;
  data: MacrosChartData[];
  numOfPortions: number;
}
