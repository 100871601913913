import { ReactNode } from "react";

import { useAppTranslation } from "@hooks";

export const ListHeader = (props: ListHeaderProps) => {
  const { name, body } = props;
  const { t } = useAppTranslation();
  return (
    <div className="bg-gray-300 w-full flex items-center px-4 py-6 border-b border-gray-300 font-semibold">
      <span style={{ width: 376 }}>{name}</span>
      <div className="flex-grow">{body}</div>
      <div
        style={{ width: 400 }}
        className="grid grid-cols-4 place-items-center"
      >
        <span>{t("common.edit")}</span>
        <span>{t("common.copy")}</span>
        <span>{`${t("common.delete")}/${t("common.hide")}`}</span>
        <span>{t("common.details")}</span>
      </div>
    </div>
  );
};

interface ListHeaderProps {
  name: string;
  body: ReactNode;
}
