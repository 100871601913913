import React from "react";
import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Pacman = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path
      id="_20-icon-graph"
      data-name="20-icon-graph"
      d="M18.741,10.707l-7.581-.5a1.165,1.165,0,0,0-1.145.74,2.26,2.26,0,0,0-.113.74c-.155,2.417-.32,4.833-.474,7.291a1.328,1.328,0,0,1-.144.3,1.245,1.245,0,0,1-1.361.7A10.123,10.123,0,0,1,0,10.092,7.184,7.184,0,0,1,.073,8.936,9.829,9.829,0,0,1,1.239,5.259,9.97,9.97,0,0,1,12.166.237,10.074,10.074,0,0,1,20,9.426a1.246,1.246,0,0,1-.345.883,1.222,1.222,0,0,1-.862.378h0Z"
      transform="translate(20 20) rotate(180)"
      fill="#801fff"
    />
  </SvgIcon>
);
