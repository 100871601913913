import { styled } from "@mui/material";
import { TabList } from "@components/Alloweat/Tabs/TabList";
import { TabPanel } from "@components/Alloweat/Tabs/TabPanel";

export const NutrientTabs = styled(TabList)`
  font: var(--font-body-text-12-figtree-medium);
  .MuiTab-root.MuiButtonBase-root {
    padding: 2px 6px;
  }
` as typeof TabList;
