import { Backdrop, Card, Typography } from "@mui/material";
import { onlineManager } from "@tanstack/react-query";

import { useAppTranslation } from "@hooks";
import { useEffect, useState } from "react";

export const OfflineBackdrop = () => {
  const { t } = useAppTranslation();
  const [offline, setOffline] = useState(false);

  useEffect(
    () => onlineManager.subscribe(() => setOffline(!onlineManager.isOnline())),
    [],
  );

  if (offline)
    return (
      <Backdrop open={true}>
        <Card sx={{ padding: "1rem" }}>
          <Typography>{t("common.no_internet")}</Typography>
        </Card>
      </Backdrop>
    );

  return <></>;
};
