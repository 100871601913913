import { FormTextField } from "@components/FormTextField";
import { TextField, styled } from "@mui/material";

export const FormTextFieldStyled = styled(FormTextField)`
  & .MuiInputBase-root {
    padding-left: 1rem;
    padding-right: unset;
  }
`;
export const TextFieldStyled = styled(TextField)`
  & .MuiInputBase-root {
    padding-left: 1rem;
    padding-right: unset;
  }
`;
