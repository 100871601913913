import { Box, styled } from "@mui/material";
import { useAppTranslation } from "@hooks";
import NutrientTargetValue from "@components/Nutrients2/NutrientTargetValue";
import { NutrientResponse } from "@typeDefinitions/responses/dictionaries";
import { getNutrientName } from "@utils/dictionaries/categoriesNutrientsDictionaryUtils";
import { NutrientValueType } from "@components/Nutrients2/Types";

type Props = {
  nutrient: NutrientResponse;
  value?: NutrientValueType | null;
};

const NutrientBox = ({ nutrient, value }: Props) => {
  const { currentLanguage } = useAppTranslation();

  return (
    <BoxWrapper>
      <Name>{getNutrientName(nutrient, currentLanguage)}</Name>
      {value && (
        <NutrientTargetValue
          nutrient={{
            value: value.value,
            target: value.target ?? 0,
            units: nutrient.units,
          }}
        />
      )}
    </BoxWrapper>
  );
};

const BoxWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
` as typeof Box;

const Name = styled("span")`
  font: var(--font-figtree-medium);
  font-size: 12px;
  line-height: 24px;
`;

export default NutrientBox;
