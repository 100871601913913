import { Box } from "@mui/material";
import { ScheduleVisitModalLabel } from "../ScheduleVisitModal.styled";
import {
  TabButton,
  TabsWrapper,
} from "../../Calendar/MiniCalendar/MiniCalendar.styled";
import type { FC } from "react";
import { useAppTranslation } from "@hooks";
import { MEETING_TYPE } from "@utils";

interface ScheduleModalTabsProps {
  activeTab: MEETING_TYPE;
  setActiveTab: (tab: MEETING_TYPE) => void;
  eventId: number | null;
}

export const ScheduleModalTabs: FC<ScheduleModalTabsProps> = ({
  activeTab,
  setActiveTab,
  eventId,
}) => {
  const { t } = useAppTranslation();

  return (
    <Box paddingX="16px">
      <ScheduleVisitModalLabel>{t("calendar.type")}</ScheduleVisitModalLabel>
      <TabsWrapper className="max-w-max">
        <TabButton
          onClick={() => setActiveTab(MEETING_TYPE.CONSULTATION)}
          active={activeTab === MEETING_TYPE.CONSULTATION}
          disabled={!!eventId && activeTab === MEETING_TYPE.OTHER}
        >
          {t("calendar.visit")}
        </TabButton>
        <TabButton
          onClick={() => setActiveTab(MEETING_TYPE.OTHER)}
          active={activeTab === MEETING_TYPE.OTHER}
          disabled={!!eventId && activeTab === MEETING_TYPE.CONSULTATION}
        >
          {t("calendar.event")}
        </TabButton>
      </TabsWrapper>
    </Box>
  );
};
