import { useEffect } from "react";
import { useController, useFormContext, useFormState } from "react-hook-form";

import { useTheme } from "@mui/material";
import { useFetchSectionCategoriesQuery } from "@hooks/queries/useFetchSectionCategoriesQuery";
import { useAppTranslation } from "@hooks";
import { Labeled } from "@components/Labeled";
import { FormInput } from "@components/FormInput";
import { FormRadioTileGroup } from "@components/FormRadioTileGroup";
import { RadioTile } from "@components/RadioTile";
import { FormTinyMceEditor } from "@components/FormTinyMceEditor";

import { StyledFormAutocomplete } from "./SectionForm.styled";
import { CheckedIcon } from "@assets/icons";

interface ISectionFormProps {
  withCategory?: boolean;
}

export const SectionForm = ({ withCategory }: ISectionFormProps) => {
  const { t, isPolishChosen } = useAppTranslation();
  const { control } = useFormContext();

  const { sectionCategories } = useFetchSectionCategoriesQuery();
  const { palette } = useTheme();

  const categoriesMapped = sectionCategories?.map(
    ({ description, descriptionEn, id }) => ({
      id: id.toString(),
      label: isPolishChosen ? description : descriptionEn,
    }),
  );

  const {
    field: { onChange, value: language },
  } = useController({ control, name: "language" });

  const languageFormState = useFormState({
    control,
    name: ["contentPl", "contentEn"],
  });

  const plErrors = languageFormState.errors["contentPl"];
  const enErrors = languageFormState.errors["contentEn"];

  useEffect(() => {
    if (plErrors) {
      onChange("PL");
    } else if (enErrors) {
      onChange("EN");
    }
  }, [plErrors, enErrors]);

  return (
    <>
      <div className="flex gap-7">
        <Labeled className="w-1/2" label={t("sections.section_name")}>
          <FormInput
            fullWidth
            name="namePl"
            control={control}
            variant="outlined"
            placeholder={t("sections.section_name")}
          />
        </Labeled>

        <Labeled className="w-1/2" label={t("sections.section_name_eng")}>
          <FormInput
            fullWidth
            name="nameEn"
            control={control}
            variant="outlined"
            placeholder={t("sections.section_name_eng")}
          />
        </Labeled>
      </div>

      {withCategory && (
        <Labeled label={t("sections.category")}>
          <StyledFormAutocomplete
            control={control}
            name="sectionCategoryId"
            id="sectionCategoryId"
            size="small"
            popupIcon={<CheckedIcon fill={palette.primary.dark} />}
            options={categoriesMapped ?? []}
          />
        </Labeled>
      )}

      <Labeled label={t("common.content")}>
        <div className="flex flex-col gap-3">
          <FormRadioTileGroup
            variant="outlined"
            name="language"
            control={control}
          >
            <RadioTile label="PL" value="PL" />
            <RadioTile label="EN" value="EN" />
          </FormRadioTileGroup>

          <div className={"flex"}>
            <div>
              <div hidden={language === "EN"}>
                <FormTinyMceEditor name="contentPl" control={control} />
              </div>
              <div hidden={language === "PL"}>
                <FormTinyMceEditor name="contentEn" control={control} />
              </div>
            </div>
          </div>
        </div>
      </Labeled>
    </>
  );
};
