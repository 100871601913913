import { styled } from "@mui/material";

export const ExpandableChipsStyled = styled("div")`
  display: flex;
  gap: 0.25rem;
  flex-wrap: wrap;
  width: 100%;
  min-width: 34rem;
  align-items: center;
`;

export const TooltipWrapper = styled("div")`
  display: flex;
  gap: 0.75rem;
  flex-wrap: wrap;
`;
