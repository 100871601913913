import { useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDeepCompareEffect } from "react-use";
import { useQueryParams } from "@hooks/queries/useQueryParams";

export const useSuggestedTasksFiltersForm = () => {
  const { update } = useQueryParams();

  const resolver = yup.object().shape({
    tagId: yup.array().of(yup.string()),
  });

  type TaskFilterFormSchema = yup.InferType<typeof resolver>;

  const form = useForm<TaskFilterFormSchema>({
    defaultValues: {
      tagId: [],
    },
    resolver: yupResolver(resolver),
    mode: "onChange",
  });

  const formData = useWatch({ control: form.control });

  useDeepCompareEffect(() => {
    update(formData);
  }, [formData]);

  return form;
};
