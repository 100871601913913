import { useEffect, useRef } from "react";
import { isToday } from "date-fns";

export const useCalendarTimeline = (currentDate: Date) => {
  const currentTimeLineRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateCurrentTimeLine = () => {
      if (currentTimeLineRef.current && isToday(currentDate)) {
        const now = new Date();
        const minutesSinceMidnight = now.getHours() * 60 + now.getMinutes();
        const top = (minutesSinceMidnight / 60) * 90;
        currentTimeLineRef.current.style.top = `${top}px`;
        currentTimeLineRef.current.style.display = "block";
      } else if (currentTimeLineRef.current) {
        currentTimeLineRef.current.style.display = "none";
      }
    };

    updateCurrentTimeLine();
    const interval = setInterval(updateCurrentTimeLine, 60000);

    return () => clearInterval(interval);
  }, [currentDate, currentTimeLineRef.current]);

  return { currentTimeLineRef };
};
