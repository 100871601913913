import { QueryOptionsTyped } from "@hooks/queries";
import { useQuery } from "@tanstack/react-query";
import {
  FetchPatientProgramPreviewSearchParams,
  fetchPatientProgramSearchPreview,
  FetchPatientProgramPreviewSearchResponse,
} from "@client/patient/programs/fetchPatientProgramSearchPreview";
import { patientProgramPreviewKeys } from "@hooks/queries/client/program/preview/patientProgramPreviewKeys";

export const getKey = (params: FetchPatientProgramPreviewSearchParams) =>
  patientProgramPreviewKeys.program({
    patientId: params.patientId,
    programId: params.programId,
  });

const useFetchPatientProgramSearchPreview = (
  params: FetchPatientProgramPreviewSearchParams,
  options?: QueryOptionsTyped<FetchPatientProgramPreviewSearchResponse>,
) => {
  return useQuery({
    queryKey: getKey(params),
    queryFn: () => fetchPatientProgramSearchPreview(params),
    ...options,
  });
};

export default useFetchPatientProgramSearchPreview;
