import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const DragHandle = (props: SvgIconProps) => {
  return (
    <SvgIcon width="10" height="14" viewBox="0 0 10 14" {...props}>
      <path
        d="M1.25 10H2.75C3.44062 10 4 10.5594 4 11.25V12.75C4 13.4406 3.44062 14 2.75 14H1.25C0.559375 14 0 13.4406 0 12.75V11.25C0 10.5594 0.559375 10 1.25 10ZM7.25 10H8.75C9.44063 10 10 10.5594 10 11.25V12.75C10 13.4406 9.44063 14 8.75 14H7.25C6.55937 14 6 13.4406 6 12.75V11.25C6 10.5594 6.55937 10 7.25 10ZM1.25 9C0.559375 9 0 8.44063 0 7.75V6.25C0 5.55937 0.559375 5 1.25 5H2.75C3.44062 5 4 5.55937 4 6.25V7.75C4 8.44063 3.44062 9 2.75 9H1.25ZM7.25 5H8.75C9.44063 5 10 5.55937 10 6.25V7.75C10 8.44063 9.44063 9 8.75 9H7.25C6.55937 9 6 8.44063 6 7.75V6.25C6 5.55937 6.55937 5 7.25 5ZM1.25 4C0.559375 4 0 3.44062 0 2.75V1.25C0 0.559375 0.559375 0 1.25 0H2.75C3.44062 0 4 0.559375 4 1.25V2.75C4 3.44062 3.44062 4 2.75 4H1.25ZM7.25 0H8.75C9.44063 0 10 0.559375 10 1.25V2.75C10 3.44062 9.44063 4 8.75 4H7.25C6.55937 4 6 3.44062 6 2.75V1.25C6 0.559375 6.55937 0 7.25 0Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
