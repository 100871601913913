import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  programOptimisticUpdate,
  programOptimisticUpdateInitial,
} from "@hooks/utils";
import { useParams } from "react-router-dom";
import { MutationOptions } from "./types";
import { fetchPatientProgramQueryKey } from "./useFetchPatientProgramQuery";
import { fetchProgramQueryKey } from "./useFetchProgramQuery";
import {
  fetchDietitianClientProgramScheduleKey,
  fetchDietitianProgramScheduleKey,
} from "./schedule";
import { ApiResponse } from "@typeDefinitions";
import {
  updateProgramDays,
  updateProgramDaysInitial,
  UpdateProgramDaysInitialRequestDto,
  UpdateProgramDaysRequestDto,
} from "@client";
import { FetchProgramResponse } from "@client/program";

export const updateProgramDaysMutationKey = "addProgramDay";

export const useUpdateProgramDays = (id: string, options?: MutationOptions) => {
  const queryClient = useQueryClient();
  const { patientId } = useParams();

  return useMutation(
    [updateProgramDaysMutationKey, id],
    (request: UpdateProgramDaysRequestDto) => {
      return updateProgramDays(id, request);
    },
    {
      ...options,
      onMutate: async request => {
        await queryClient.cancelQueries([
          fetchPatientProgramQueryKey,
          patientId,
          id,
        ]);
        await queryClient.cancelQueries([fetchProgramQueryKey, parseInt(id)]);

        const hasNewDay = request.days.some(day => day.id === null);

        if (!hasNewDay) {
          queryClient.setQueryData<ApiResponse<FetchProgramResponse>>(
            [fetchPatientProgramQueryKey, patientId, id],
            old => programOptimisticUpdate(request, old),
          );
          queryClient.setQueryData<ApiResponse<FetchProgramResponse>>(
            [fetchProgramQueryKey, parseInt(id)],
            old => programOptimisticUpdate(request, old),
          );
        }

        return {
          invalidate: hasNewDay,
        };
      },
      onError: () => {
        queryClient.invalidateQueries([
          fetchPatientProgramQueryKey,
          patientId,
          id,
        ]);
        queryClient.invalidateQueries([fetchProgramQueryKey, parseInt(id)]);
      },
      onSettled: (data, error, variables, context) => {
        if (context?.invalidate) {
          queryClient.invalidateQueries([
            fetchPatientProgramQueryKey,
            patientId,
            id,
          ]);
          queryClient.invalidateQueries([fetchProgramQueryKey, parseInt(id)]);
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries([fetchDietitianProgramScheduleKey]);
        queryClient.invalidateQueries([fetchDietitianClientProgramScheduleKey]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};

export const useUpdateProgramDaysInitial = (
  id: string,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();
  const { patientId } = useParams();

  return useMutation(
    (request: UpdateProgramDaysInitialRequestDto) =>
      updateProgramDaysInitial(id, request),
    {
      ...options,
      onMutate: async request => {
        await queryClient.cancelQueries([
          fetchPatientProgramQueryKey,
          patientId,
          id,
        ]);
        await queryClient.cancelQueries([fetchProgramQueryKey, parseInt(id)]);

        const hasNewDay = request.days.some(day => day.id === null);

        if (!hasNewDay) {
          queryClient.setQueryData<ApiResponse<FetchProgramResponse>>(
            [fetchPatientProgramQueryKey, patientId, id],
            old => programOptimisticUpdateInitial(request, old),
          );
          queryClient.setQueryData<ApiResponse<FetchProgramResponse>>(
            [fetchProgramQueryKey, parseInt(id)],
            old => programOptimisticUpdateInitial(request, old),
          );
        }

        return {
          invalidate: hasNewDay,
        };
      },
      onError: () => {
        queryClient.invalidateQueries([
          fetchPatientProgramQueryKey,
          patientId,
          id,
        ]);
        queryClient.invalidateQueries([fetchProgramQueryKey, parseInt(id)]);
      },
      onSettled: (data, error, variables, context) => {
        if (context?.invalidate) {
          queryClient.invalidateQueries([
            fetchPatientProgramQueryKey,
            patientId,
            id,
          ]);
          queryClient.invalidateQueries([fetchProgramQueryKey, parseInt(id)]);
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries([fetchDietitianProgramScheduleKey]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};
