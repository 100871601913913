import { SyntheticEvent, useRef } from "react";

import { ArrowRight } from "@assets/icons";
import { LangDto } from "@client";
import { FoodWrapperDto } from "@client/diets";
import { TagWithIcon } from "@components/TagWithIcon";
import { useAppTranslation } from "@hooks";

import { AccordionTitle } from "../AccordionTitle/AccordionTitle";
import { ProductDetails } from "./ProductDetails";
import {
  AccordionDetailsStyled,
  AccordionStyled,
  AccordionSummaryStyled,
} from "./common.styled";

interface ProductAccordionProps {
  product: FoodWrapperDto;
  dietId: string;
  mealId: string;
}

export const ProductAccordion = ({
  product: { id, grams, product },
  dietId,
  mealId,
}: ProductAccordionProps) => {
  const { t, isPolishChosen } = useAppTranslation();
  const anchorEl = useRef<null | HTMLDivElement>(null);

  const namePl =
    product.translations.find(t => t.lang === LangDto.PL)?.name ?? "";
  const nameEn =
    product.translations.find(t => t.lang === LangDto.PL)?.name ?? "";
  const productName = isPolishChosen ? namePl : nameEn ?? namePl;

  const handleChange = (
    e: SyntheticEvent<Element, Event>,
    isExpanded: boolean,
  ) => {
    const current = anchorEl.current;
    if (isExpanded && current) {
      setTimeout(
        () => current.scrollIntoView({ behavior: "smooth", block: "center" }),
        350,
      );
    }
  };

  return (
    <AccordionStyled
      key={id}
      TransitionProps={{ unmountOnExit: true }}
      ref={anchorEl}
      onChange={handleChange}
    >
      <AccordionSummaryStyled
        expandIcon={<ArrowRight size="w-[16px] h-[16px]" />}
      >
        <AccordionTitle
          img={product.image?.url ?? ""}
          name={productName}
          tags={<TagWithIcon label={`${grams} g`} />}
          nutrients={product.nutrients}
        />
      </AccordionSummaryStyled>
      <AccordionDetailsStyled>
        <ProductDetails
          dietId={dietId}
          mealId={mealId}
          itemId={id.toString()}
        />
      </AccordionDetailsStyled>
    </AccordionStyled>
  );
};
