import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Fish = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="8" viewBox="0 0 14 8">
      <path
        d="M4.57433 1.41617C5.45894 0.671469 6.65272 0.0283203 8.08344 0.0283203C9.51417 0.0283203 10.7079 0.671469 11.5926 1.41617C12.4749 2.16087 13.1023 3.04999 13.4205 3.66832C13.5265 3.87593 13.5265 4.12191 13.4205 4.32952C13.1023 4.94785 12.4749 5.83697 11.5926 6.58167C10.7079 7.32863 9.51417 7.97178 8.08344 7.97178C6.65272 7.97178 5.45894 7.32863 4.57433 6.58393C4.20875 6.27477 3.88605 5.94078 3.61074 5.61131L1.58651 6.78928C1.30443 6.95402 0.945618 6.90889 0.713181 6.67871C0.480745 6.44853 0.433355 6.09198 0.595835 5.80764L1.62939 4.00005L0.595835 2.19246C0.433355 1.90812 0.483002 1.55157 0.715438 1.32139C0.947874 1.09121 1.30443 1.04608 1.58877 1.21081L3.61299 2.39105C3.88831 2.06157 4.21101 1.72759 4.57659 1.41843L4.57433 1.41617ZM10.6109 4.00005C10.6109 3.80853 10.5348 3.62485 10.3994 3.48942C10.264 3.354 10.0803 3.27792 9.88877 3.27792C9.69725 3.27792 9.51358 3.354 9.37815 3.48942C9.24272 3.62485 9.16664 3.80853 9.16664 4.00005C9.16664 4.19157 9.24272 4.37525 9.37815 4.51067C9.51358 4.6461 9.69725 4.72218 9.88877 4.72218C10.0803 4.72218 10.264 4.6461 10.3994 4.51067C10.5348 4.37525 10.6109 4.19157 10.6109 4.00005Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
