import dayjs from "dayjs";

import {
  SystemQuestionDto,
  CooperationGoalsAnswerQuestionDto,
  CooperationGoalsAnswerResourceDto,
  PatientProfileTargetResourceDto,
} from "@client/surveys";
import { OuterLabel } from "@components/OuterLabel";
import { useAppTranslation } from "@hooks";

import { QuestionPreview } from "../../QuestionPreview/QuestionPreview";
import { StyledAnswer } from "./SystemAnswerCooperationGoals.styled";
import { getTranslatedValue } from "@views/public/QuestionnaireAnswer/Questions/utils";

interface SystemAnswerCooperationGoalsProps {
  question: SystemQuestionDto;
  answer?: CooperationGoalsAnswerResourceDto;
  listIndex: number;
  totalQuestions: number;
  options?: PatientProfileTargetResourceDto[];
}

export const SystemAnswerCooperationGoals = ({
  answer,
  question,
  listIndex,
  totalQuestions,
  options,
}: SystemAnswerCooperationGoalsProps) => {
  const { title, description } = question;
  const { t, currentLanguage } = useAppTranslation();
  const answerTranslations = options?.find(
    option => option.id === answer?.answer.id,
  )?.translations;
  const answerName =
    answerTranslations &&
    getTranslatedValue(answerTranslations, currentLanguage);
  const answerOther = answer?.answer.other;

  return (
    <QuestionPreview
      title={title}
      description={description}
      listIndex={listIndex}
      totalQuestions={totalQuestions}
      systemQuestion
    >
      <OuterLabel label={t("questionnaires.questions.answer")}>
        <StyledAnswer>
          {answerName}
          {answerOther && `: ${answerOther}`}
        </StyledAnswer>
      </OuterLabel>
    </QuestionPreview>
  );
};
