import { styled, Typography } from "@mui/material";

export const StyledTypography = styled(Typography)`
  font-weight: 600;
`;

interface LabelDotProps {
  background: string;
}

export const LabelDot = styled("div")<LabelDotProps>`
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 100%;
  margin: 0.75rem;
  background: ${({ background }) => background};
`;

export const LabelShortWrapper = styled("div")`
  display: flex;
  align-items: center;
  width: 8.25rem;
`;

export const LabelLongWrapper = styled("div")`
  display: flex;
  align-items: center;
  width: 10.125rem;
`;

export const NameTypographyStyled = styled(Typography)`
  margin-right: 2px;
`;
