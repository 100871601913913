import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";
import { BodyMeasurementResource } from "@client/dictionaries";
import { useFormContext } from "react-hook-form";
import { useAppTranslation } from "@hooks";
import { FormPops } from "@views/dietician/ClientHealth/BodyMeasurementTab/SettingsSection/Dialog/SettingSectionForm";

type AllBodyFieldProps = {
  bodyMeasurements: BodyMeasurementResource[];
};

const AllBodyField = ({ bodyMeasurements }: AllBodyFieldProps) => {
  const { t } = useAppTranslation();
  const { setValue, watch } = useFormContext<FormPops>();

  const measurementsBodyId = watch("measurementsBodyId");

  return (
    <FormControlLabel
      label={t(
        "patient.health_and_goal.body_measurement_tab.section_settings_modal.select_all",
      )}
      sx={{ margin: "0px" }}
      control={
        <Checkbox
          checked={
            measurementsBodyId.length === bodyMeasurements.length &&
            measurementsBodyId.length > 0
          }
          indeterminate={
            measurementsBodyId.length < (bodyMeasurements.length ?? 0) &&
            measurementsBodyId.length > 0
          }
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
              setValue(
                "measurementsBodyId",
                bodyMeasurements.map(b => b.id),
              );
            } else {
              setValue("measurementsBodyId", []);
            }
          }}
        />
      }
    />
  );
};

export default AllBodyField;
