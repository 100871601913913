import { ReactNode } from "react";
import { useController, useFormContext } from "react-hook-form";

import {
  Autocomplete,
  Checkbox,
  Chip,
  TextField,
  useTheme,
} from "@mui/material";

import { FormTextField } from "@components/FormTextField";
import { OuterLabel } from "@components/OuterLabel";
import { useAppTranslation, useModal } from "@hooks";
import { useDeleteSurveySubgroupQuestionMutation } from "@hooks/queries/surveys";

import { IInCricle, TrashNew } from "@assets/icons";
import { QuestionType } from "@client/surveys";
import { QuestionnaireCardWrapper } from "../../components/Cards";
import { ConfirmationModal } from "@components/ConfirmationModal";
import {
  AnswerTitle,
  DefinedText,
  FormControlLabelStyled,
  IconButtonStyled,
  InfoAlert,
} from "./Question.styled";
import { QuestionFormInputs } from "./useQuestionForm";

interface QuestionProps {
  children?: ReactNode;
  id: number;
  listIndex: number;
  totalQuestions: number;
  systemQuestion?: boolean;
  onTypeChange: (currentType: QuestionType, newType: QuestionType) => void;
  questionType: QuestionType;
  infoText?: string;
}

export const Question = ({
  children,
  id,
  listIndex,
  totalQuestions,
  systemQuestion,
  onTypeChange,
  questionType,
  infoText = "",
}: QuestionProps) => {
  const { t } = useAppTranslation();
  const {
    palette: { info },
  } = useTheme();
  const { modalOpened, onModalOpen, onModalClose } = useModal();
  const { mutate: deleteQuestion } = useDeleteSurveySubgroupQuestionMutation();
  const { control, watch } = useFormContext<QuestionFormInputs>();

  const {
    field: { value, onChange },
  } = useController({ control, name: "required" });

  const handleDelete = () => {
    deleteQuestion(id.toString());
    onModalClose();
  };

  const answerTypeLabelList = [
    {
      id: QuestionType.TEXT,
      label: t("questionnaires.questions.text.list_label"),
    },
    {
      id: QuestionType.TEXTAREA,
      label: t("questionnaires.questions.textarea.list_label"),
    },
    {
      id: QuestionType.RADIO,
      label: t("questionnaires.questions.radio.list_label"),
    },
    {
      id: QuestionType.CHECKBOX,
      label: t("questionnaires.questions.checkbox.list_label"),
    },
    {
      id: QuestionType.DATE,
      label: t("questionnaires.questions.date.list_label"),
    },
    {
      id: QuestionType.TABLE,
      label: t("questionnaires.questions.table.list_label"),
    },
  ];

  const systemAnswerTypeLabelList = [
    {
      id: QuestionType.SYSTEM_USER_WEIGHT,
      label: t("questionnaires.questions.system_user_weight.list_label"),
    },
    {
      id: QuestionType.SYSTEM_USER_HEIGHT,
      label: t("questionnaires.questions.system_user_height.list_label"),
    },
    {
      id: QuestionType.SYSTEM_USER_FAT_TISSUE,
      label: t("questionnaires.questions.system_user_fat_tissue.list_label"),
    },
    {
      id: QuestionType.SYSTEM_MEASUREMENTS,
      label: t("questionnaires.questions.system_measurements.list_label"),
    },
    {
      id: QuestionType.SYSTEM_USER_BIRTHDAY,
      label: t("questionnaires.questions.system_user_birthday.list_label"),
    },
    {
      id: QuestionType.SYSTEM_COOPERATION_GOALS,
      label: t("questionnaires.questions.system_cooperation_goals.list_label"),
    },
    {
      id: QuestionType.SYSTEM_USER_TARGET_BODY_WEIGHT,
      label: t(
        "questionnaires.questions.system_user_target_body_weight.list_label",
      ),
    },
    {
      id: QuestionType.SYSTEM_PREGNANT_WOMAN,
      label: t("questionnaires.questions.system_pregnant_woman.list_label"),
    },
    {
      id: QuestionType.SYSTEM_SEX,
      label: t("questionnaires.questions.system_sex.list_label"),
    },
    {
      id: QuestionType.SYSTEM_USER_PREFERENCES_FOOD_UNLIKED,
      label: t(
        "questionnaires.questions.system_user_preferences_food_unliked.list_label",
      ),
    },
    {
      id: QuestionType.SYSTEM_USER_PREFERENCES_FOOD_LIKED,
      label: t(
        "questionnaires.questions.system_user_preferences_food_liked.list_label",
      ),
    },
    {
      id: QuestionType.SYSTEM_TAG_ALLERGEN,
      label: t("questionnaires.questions.system_tag_allergen.list_label"),
    },
  ];

  return (
    <QuestionnaireCardWrapper
      dragHandle
      title={
        <>
          {t("questionnaires.question")} {listIndex + 1}/{totalQuestions}
          {systemQuestion ? (
            <Chip color="primary" label={t("questionnaires.system")} />
          ) : (
            <Chip label={t("questionnaires.own")} />
          )}
        </>
      }
      actions={
        <div className="flex items-center">
          <FormControlLabelStyled
            checked={value}
            onChange={(_, val) => onChange(val)}
            control={<Checkbox />}
            label={t("questionnaires.questions.required")}
          />
          <IconButtonStyled onClick={onModalOpen} color="primary">
            <TrashNew />
          </IconButtonStyled>
        </div>
      }
    >
      <OuterLabel label={t("questionnaires.questions.question")}>
        {!systemQuestion && (
          <FormTextField
            control={control}
            name="title"
            size="small"
            fullWidth
            placeholder={t("questionnaires.questions.question_sample")}
          />
        )}
        {systemQuestion && <DefinedText>{watch("title")}</DefinedText>}
      </OuterLabel>

      {systemQuestion && (
        <div className="flex gap-1">
          <IInCricle fill={info.main} className="m-1 self-start" />
          <InfoAlert>{infoText}</InfoAlert>
        </div>
      )}
      {!systemQuestion && (
        <OuterLabel label={t("questionnaires.questions.descripiton")}>
          <FormTextField
            multiline
            control={control}
            name="description"
            size="small"
            fullWidth
            placeholder={t("questionnaires.questions.description_sample")}
          />
        </OuterLabel>
      )}

      <OuterLabel label={t("questionnaires.questions.answer_type")}>
        {!systemQuestion && (
          <Autocomplete
            fullWidth
            size="small"
            options={answerTypeLabelList}
            value={answerTypeLabelList.find(a => a.id === questionType)}
            onChange={(_, val) =>
              val ? onTypeChange(questionType, val.id) : undefined
            }
            renderInput={params => <TextField {...params} />}
            filterOptions={o => o}
          />
        )}
        {systemQuestion && (
          <DefinedText>
            {systemAnswerTypeLabelList.find(a => a.id === questionType)?.label}
          </DefinedText>
        )}
      </OuterLabel>

      {children && (
        <div className="grid gap-[10px]">
          <AnswerTitle>{t("questionnaires.answers")}</AnswerTitle>

          {children}
        </div>
      )}

      <ConfirmationModal
        open={modalOpened}
        onCancel={onModalClose}
        onConfirm={handleDelete}
        confirmText={t("questionnaires.delete_modal_question.button")}
        description={t("questionnaires.delete_modal_question.desc", {
          variable: watch("title"),
        })}
        title={t("questionnaires.delete_modal_question.title")}
      />
    </QuestionnaireCardWrapper>
  );
};
