import { useQuery } from "@tanstack/react-query";

import { QueryOptionsTyped } from "../types";
import { buildDefaultQueryOptions } from "../common";
import { ApiResponse } from "@typeDefinitions";
import {
  FetchClinicClientsStatisticsResponse,
  fetchClinicClientsStatistics,
} from "@client/settings";

export const fetchClinicClientsStatisticsQueryKey =
  "fetchClinicClientsStatisticsQueryKey";

export const useFetchClinicClientsStatisticsQuery = (
  options?: QueryOptionsTyped<
    ApiResponse<FetchClinicClientsStatisticsResponse>
  >,
) => {
  const { data, ...rest } = useQuery(
    [fetchClinicClientsStatisticsQueryKey],
    fetchClinicClientsStatistics,
    buildDefaultQueryOptions(options),
  );

  return {
    clientsStatistics: data?.data,
    ...rest,
  };
};
