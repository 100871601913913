import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MutationOptions } from "./types";
import { fetchSectionQueryKey } from "./useFetchSectionQuery";
import { searchSectionsQueryKey } from "./useSearchSectionsQuery";
import { programCategoriesQuery } from "./useFetchProgramSectionsCategoryQuery";
import { createSection, SectionRequestDto } from "@client";

export function useCreateSectionMutation(options?: MutationOptions) {
  const queryClient = useQueryClient();

  return useMutation(async (input: SectionRequestDto) => createSection(input), {
    onSuccess: () => {
      queryClient.invalidateQueries([fetchSectionQueryKey]);
      queryClient.invalidateQueries([searchSectionsQueryKey]);
      queryClient.invalidateQueries([programCategoriesQuery]);
      options?.onSuccess && options.onSuccess();
    },
  });
}
