import React from "react";
import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Minus = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 26 26">
    <g
      id="_26-button_minus"
      data-name="26-button minus"
      transform="translate(-1408.5 -89)"
    >
      <g
        id="_26-button-minus"
        data-name="26-button-minus"
        transform="translate(1408.5 89)"
      >
        <rect
          id="icon_20x20_odstep_12px"
          data-name="icon 20x20 / odstep 12px"
          width="26"
          height="26"
          fill="#fff"
          opacity="0"
        />
        <g
          id="circle_20x20"
          data-name="circle 20x20"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        >
          <rect width="26" height="26" rx="13" stroke="none" />
          <rect x="1" y="1" width="24" height="24" rx="12" fill="none" />
        </g>
        <path
          id="Line_186"
          d="M10.4.476H0"
          transform="translate(7.8 12.519)"
          fill="rgba(0,0,0,0)"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </g>
  </SvgIcon>
);
