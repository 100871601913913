import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Globe = (props: SvgIconProps) => {
  return (
    <SvgIcon width="18" height="16" viewBox="0 0 18 16" {...props}>
      <path
        d="M9 14.5044C9.21875 14.5044 9.84375 14.2856 10.4688 13.0044C10.75 12.4419 11 11.7856 11.1562 11.0044H6.8125C7 11.7856 7.21875 12.4419 7.5 13.0044C8.15625 14.2856 8.75 14.5044 9 14.5044ZM6.5625 9.50439H11.4062C11.4688 9.03564 11.5 8.53564 11.5 8.00439C11.5 7.50439 11.4688 7.00439 11.4062 6.50439H6.5625C6.5 7.00439 6.5 7.50439 6.5 8.00439C6.5 8.53564 6.5 9.03564 6.5625 9.50439ZM6.8125 5.00439H11.1562C11 4.25439 10.75 3.59814 10.4688 3.03564C9.84375 1.75439 9.21875 1.50439 9 1.50439C8.75 1.50439 8.15625 1.75439 7.5 3.03564C7.21875 3.59814 7 4.25439 6.8125 5.00439ZM12.9062 6.50439C12.9688 7.00439 12.9688 7.50439 12.9688 8.00439C12.9688 8.53564 12.9688 9.03564 12.9062 9.50439H15.3125C15.4375 9.03564 15.5 8.53564 15.5 8.00439C15.5 7.50439 15.4375 7.00439 15.3125 6.50439H12.9062ZM14.75 5.00439C14.0938 3.72314 13 2.69189 11.6875 2.09814C12.125 2.91064 12.4688 3.91064 12.6875 5.00439H14.75ZM5.28125 5.00439C5.5 3.91064 5.84375 2.91064 6.28125 2.09814C4.96875 2.69189 3.875 3.72314 3.21875 5.00439H5.28125ZM2.65625 6.50439C2.5625 7.00439 2.5 7.50439 2.5 8.00439C2.5 8.53564 2.53125 9.03564 2.65625 9.50439H5.0625C5 9.03564 5 8.53564 5 8.00439C5 7.50439 5 7.00439 5.0625 6.50439H2.65625ZM11.6875 13.9419C13 13.3481 14.0938 12.3169 14.75 11.0044H12.6875C12.4688 12.1294 12.125 13.1294 11.6875 13.9419ZM6.28125 13.9419C5.84375 13.1294 5.5 12.1294 5.28125 11.0044H3.21875C3.875 12.3169 4.96875 13.3481 6.28125 13.9419ZM9 16.0044C6.125 16.0044 3.5 14.5044 2.0625 12.0044C0.625 9.53564 0.625 6.50439 2.0625 4.00439C3.5 1.53564 6.125 0.00439453 9 0.00439453C11.8438 0.00439453 14.4688 1.53564 15.9062 4.00439C17.3438 6.50439 17.3438 9.53564 15.9062 12.0044C14.4688 14.5044 11.8438 16.0044 9 16.0044Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
