import {
  MonitoringProgramDayMealsResponse,
  fetchMonitoringProgramDayMeals,
} from "@client";
import { useQuery } from "@tanstack/react-query";
import { QueryOptionsTyped } from "./types";
import { ApiResponse } from "@typeDefinitions";

export const fetchMonitoringProgramDayMealsQueryKey =
  "fetchMonitoringProgramDayMealsQueryKey";

export const useFetchMonitoringProgramDayMealsQuery = (
  patientId: string,
  date: string,
  options?: QueryOptionsTyped<ApiResponse<MonitoringProgramDayMealsResponse>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchMonitoringProgramDayMealsQueryKey, patientId, date],
    () => fetchMonitoringProgramDayMeals(patientId, date),
    options,
  );

  return {
    programDayMeals: data?.data,
    ...rest,
  };
};
