import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useAppTranslation } from "@hooks";
import { MutationOptions } from "./types";
import { requestDeleteProduct } from "@client";
import { toast } from "react-toastify";
import { searchFoodQueryKey } from "./useSearchFoodQuery";

export const useDeleteProductMutation = (options?: MutationOptions) => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();

  return useMutation((id: number) => requestDeleteProduct(id), {
    ...options,
    onSuccess: () => {
      toast.success(t("products_settings.product_removed"));
      queryClient.invalidateQueries([searchFoodQueryKey]);
      options?.onSuccess && options.onSuccess();
    },
    onError: e => {
      options?.onError && options.onError(e);
      toast.error(t("products.error_delete"));
    },
  });
};
