import * as yup from "yup";
import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import {
  MealsStatsMonitoringResource,
  mealsStatsMonitoringSchema,
} from "@client/resources/MealsStatsMonitoringResource";
import { FetchMonitoringIrrigationParams } from "@client/patient/monitoring/fetchMonitoringIrrigation";
import { logFetchValidateError } from "@utils/validate";

export type FetchMonitoringMealsStatsParams = {
  patientId: number;
  dateFrom: string;
  dateTo: string;
};

export type FetchMonitoringMealsStatsResponse =
  ApiResponse<MealsStatsMonitoringResource>;
export const fetchMonitoringMealsStats = async (
  params: FetchMonitoringMealsStatsParams,
): Promise<FetchMonitoringMealsStatsResponse> => {
  const response = await fetchData(
    `/dietitian/patients/{patient_id}/monitoring2/meals-stats`.replace(
      "{patient_id}",
      params.patientId.toString(),
    ),
    APIMethods.GET,
    undefined,
    mapUrlParams(params),
  );

  return await schema
    .validate(response)
    .catch(
      logFetchValidateError(
        "/dietitian/patients/{patient_id}/monitoring2/meals-stats",
      ),
    );
};

const mapUrlParams = (params: FetchMonitoringIrrigationParams) => {
  const urlParams = new URLSearchParams();
  urlParams.set("date-from", params.dateFrom);
  urlParams.set("date-to", params.dateTo);

  return urlParams;
};

const schema = yup.object({
  data: mealsStatsMonitoringSchema.defined(),
});
