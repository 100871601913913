import { LoadingButton } from "@mui/lab";
import React from "react";
import { useAppTranslation } from "@hooks";
import { useFormState } from "react-hook-form";

type FormSubmitButtonProps = {
  name?: string;
};
const FormSubmitButton = ({ name }: FormSubmitButtonProps) => {
  const { t } = useAppTranslation();
  const { isSubmitting } = useFormState();

  return (
    <LoadingButton loading={isSubmitting} type="submit" variant="contained">
      {name ?? t("common.save")}
    </LoadingButton>
  );
};

export default FormSubmitButton;
