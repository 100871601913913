import { type FC, RefObject, useEffect, useRef, useState } from "react";
import { Typography, Grid, Box } from "@mui/material";
import { EventWithType } from "@typeDefinitions";
import {
  WeekViewWrapper,
  EventList,
  EventItem,
  EventTime,
  EventTitle,
  StyledDayDivider,
  WeekDayButton,
} from "../MiniCalendar.styled";
import dayjs, { Dayjs } from "dayjs";
import { PickersDay } from "@mui/x-date-pickers";
import { ArrowPrev } from "@assets/icons";
import { DietitianFilter } from "@views/dietician/Tasks/components/TaskDietitianFilter/DietitianFilter";
import { CalendarEmptyState } from "./CalendarEmptyState";
import { Spinner } from "@components/Spinner";
import {
  StyledActiveDaySpan,
  StyledCalendarPicker,
} from "./MiniCalendarMonthView.styled";
import { useCalendarActions } from "../../_hooks/useCalendarActions";
import { ScheduleVisitOverviewModal } from "../../../ScheduleVisit/_components/ScheduleVisitOverviewModal";
import { useCalendarColors } from "@views/dietician/Calendar";
import { getEventIcon } from "../../_utils/getEventIcon";
import { useAppTranslation } from "@hooks";
import { CalendarContentWrapper } from "../MiniCalendar.styled";
import { ScheduleGoogleEventOverviewModal } from "../../../ScheduleVisit/_components/ScheduleGoogleEventOverviewModal";

interface MiniCalendarMonthViewProps {
  events: EventWithType[];
  currentDate: Date;
  setCurrentDate: React.Dispatch<React.SetStateAction<Date>>;
  locale: Locale;
  isLoading: boolean;
  dietitians: number;
}

const groupEventsByDate = (events: EventWithType[]) => {
  return events.reduce((acc, event) => {
    const eventDate = dayjs(event.start).format("YYYY-MM-DD");
    if (!acc[eventDate]) {
      acc[eventDate] = [];
    }
    acc[eventDate].push(event);
    return acc;
  }, {} as Record<string, EventWithType[]>);
};

export const MiniCalendarMonthView: FC<MiniCalendarMonthViewProps> = ({
  events,
  currentDate,
  setCurrentDate,
  isLoading,
  dietitians,
}) => {
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const {
    handleCloseModal,
    handleEventClick,
    selectedEventId,
    anchorEl,
    selectedGoogleId,
  } = useCalendarActions();
  const { isPolishChosen } = useAppTranslation();
  const dateRefs = useRef<Record<string, HTMLDivElement | null>>({});

  const { getNewDietitianColor, getEventStyles } = useCalendarColors();

  const [displayedMonth, setDisplayedMonth] = useState<Dayjs>(
    dayjs(currentDate),
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsInitialLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [dietitians, displayedMonth]);

  const handleDateChange = (newDate: Dayjs) => {
    if (newDate) {
      const formattedDate = newDate.format("YYYY-MM-DD");
      const dateRef = dateRefs.current[formattedDate];

      if (dateRef) {
        const container = dateRef.closest(".custom-scrollbar");
        if (container) {
          const containerRect = container.getBoundingClientRect();
          const elementRect = dateRef.getBoundingClientRect();
          const relativeTop =
            elementRect.top - containerRect.top + container.scrollTop;

          container.scrollTo({
            top: relativeTop,
            behavior: "smooth",
          });
        }
      }
    }
  };

  useEffect(() => {
    const todayDate = dayjs().format("YYYY-MM-DD");
    const dateRef = dateRefs.current[todayDate];

    if (dateRef) {
      const container = dateRef.closest(".custom-scrollbar");
      if (container) {
        const containerRect = container.getBoundingClientRect();
        const elementRect = dateRef.getBoundingClientRect();
        const relativeTop =
          elementRect.top - containerRect.top + container.scrollTop;

        container.scrollTo({
          top: relativeTop,
          behavior: "smooth",
        });
      }
    }
  }, [Object.keys(dateRefs.current).length]);

  const selectedMonthEvents = events.filter(event =>
    dayjs(event.start).isSame(displayedMonth, "month"),
  );

  const hasEventsInCurrentMonth =
    !isLoading && !isInitialLoading && selectedMonthEvents.length > 0;

  const hasEvents = (events: EventWithType[], day: Dayjs) => {
    return events.some(event => dayjs(event.start).isSame(day, "day"));
  };

  const goToPreviousMonth = () => {
    setIsInitialLoading(true);
    setDisplayedMonth(displayedMonth.subtract(1, "month"));
  };

  const goToNextMonth = () => {
    setIsInitialLoading(true);
    setDisplayedMonth(displayedMonth.add(1, "month"));
  };

  const composeEventTimeToDisplay = (event: EventWithType) => {
    return `${dayjs(event.start).format("HH:mm")} - ${dayjs(event.end).format(
      "HH:mm",
    )}`;
  };

  useEffect(() => {
    if (displayedMonth) {
      setCurrentDate(displayedMonth.toDate());
    }
  }, [displayedMonth]);
  return (
    <WeekViewWrapper>
      <Grid container justifyContent="space-between" alignItems="center">
        <WeekDayButton onClick={goToPreviousMonth}>
          <ArrowPrev />
        </WeekDayButton>
        <Typography variant="h6" textTransform="capitalize">
          {displayedMonth
            .locale(isPolishChosen ? "pl" : "en")
            .format("MMMM YYYY")}
        </Typography>
        <WeekDayButton onClick={goToNextMonth}>
          <ArrowPrev className="rotate-180" />
        </WeekDayButton>
      </Grid>

      <StyledCalendarPicker
        date={displayedMonth}
        onChange={newDate => handleDateChange(newDate as Dayjs)}
        disablePast
        dayOfWeekFormatter={day => day}
        showDaysOutsideCurrentMonth
        disableHighlightToday
        disabled
        renderDay={(day, _, pickersDayProps) => {
          const currentDay = day as Dayjs;
          const isEventDay = hasEvents(events, day as Dayjs);
          return (
            <PickersDay
              {...pickersDayProps}
              disabled={false}
              day={day}
              key={pickersDayProps.key}
            >
              <div style={{ position: "relative" }}>
                <Typography component="span" variant="body2">
                  {currentDay.format("D")}
                </Typography>
                {isEventDay && <StyledActiveDaySpan />}
              </div>
            </PickersDay>
          );
        }}
      />
      <DietitianFilter
        name="dietitians"
        setIsInitialLoading={setIsInitialLoading}
      />
      {isInitialLoading || isLoading ? (
        <div className="flex justify-center items-center">
          <Spinner />
        </div>
      ) : hasEventsInCurrentMonth ? (
        <CalendarContentWrapper className="custom-scrollbar mt-[24px] !max-h-[550px]">
          {Object.entries(groupEventsByDate(selectedMonthEvents)).map(
            ([date, eventsOnDate], index) => (
              <div
                key={index}
                ref={el => {
                  dateRefs.current[date] = el;
                }}
              >
                <div className="flex items-center gap-3">
                  <Typography variant="body1" fontWeight="600">
                    {dayjs(date).format("DD.MM.YYYY")}
                  </Typography>

                  <StyledDayDivider />
                </div>
                <EventList>
                  {eventsOnDate.map((event, eventIndex) => {
                    const theme = getNewDietitianColor(event.dietitianId);
                    const { backgroundColor, border } = getEventStyles(
                      theme?.backgroundColor,
                      !!event.googleId,
                    );

                    return (
                      <EventItem
                        key={eventIndex}
                        onClick={e =>
                          handleEventClick(e, event.id, event.googleId)
                        }
                        style={{
                          backgroundColor,
                          border,
                          minHeight: "70px",
                        }}
                      >
                        <EventTime>
                          {getEventIcon(event.type, {
                            color: theme?.color,
                          })}
                          <p>{composeEventTimeToDisplay(event)}</p>
                        </EventTime>
                        <EventTitle>{event.title}</EventTitle>
                      </EventItem>
                    );
                  })}
                </EventList>
              </div>
            ),
          )}
        </CalendarContentWrapper>
      ) : (
        <CalendarEmptyState />
      )}
      {selectedEventId && (
        <ScheduleVisitOverviewModal
          anchorEl={anchorEl}
          onClose={handleCloseModal}
          eventId={selectedEventId}
        />
      )}
      {selectedGoogleId && (
        <ScheduleGoogleEventOverviewModal
          anchorEl={anchorEl}
          onClose={handleCloseModal}
          googleId={selectedGoogleId}
        />
      )}
    </WeekViewWrapper>
  );
};
