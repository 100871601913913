import image from "@assets/img/noResults.svg";

import { Button } from "@mui/material";

import { useAppTranslation } from "@hooks";

import { Info, Title, Wrapper } from "./NoProductsEmptyState.styled";

interface NoProductsEmptyStateProps {
  infoText: string;
  onClick?: () => void;
  buttonText?: string;
}
export const NoProductsEmptyState = ({
  infoText,
  onClick,
  buttonText,
}: NoProductsEmptyStateProps) => {
  const { t } = useAppTranslation();

  return (
    <Wrapper>
      <img src={image} alt={t("empty_states.alt.filters")} />

      <Title>{t("diet.empty_state.no_products")}</Title>

      <div className="flex flex-col items-center gap-[12px]">
        <Info>{infoText}</Info>

        {onClick && (
          <Button onClick={onClick}>
            {buttonText ?? t("diet.empty_state.go_to_products")}
          </Button>
        )}
      </div>
    </Wrapper>
  );
};
