import useFetchPatientImportantNutrients from "@hooks/queries/client/nutrient/useFetchPatientImportantNutrients";
import { NutrientsList } from "@components/Nutrients2/NutrientsList";
import NutrientBox from "@components/Nutrients2/NutrientBox";
import { TabPanel } from "@components/Alloweat/Tabs/TabPanel";
import { useCategoriesNutrientsFetchQuery } from "@hooks/queries/dictionaries";
import { TabPanelProps } from "@mui/lab/TabPanel/TabPanel";
import { PatientNutrientTabValue } from "@components/Nutrients2/PatientNutrientsTabs";
import { NutrientValueType } from "@components/Nutrients2/Types";
import { useMemo } from "react";

type Props = {
  patientId: number;
  values: NutrientValueType[];
} & Omit<TabPanelProps, "value">;

const PatientNutrientsTabPanel = ({ patientId, values, ...other }: Props) => {
  const { data } = useFetchPatientImportantNutrients({ patientId: patientId });
  const { data: dataNutrientsCategories } = useCategoriesNutrientsFetchQuery();

  const dataNutrients = useMemo(() => {
    if (!dataNutrientsCategories) {
      return dataNutrientsCategories;
    }

    return dataNutrientsCategories.map(c => c.nutrients).flat();
  }, [dataNutrientsCategories]);

  if (!data || data.data.length === 0 || !dataNutrients) {
    return null;
  }

  return (
    <TabPanel {...other} value={PatientNutrientTabValue}>
      <NutrientsList>
        {data.data.map(importantNutrient => {
          const value = values.find(value => value.id === importantNutrient.id);
          const nutrient = dataNutrients.find(
            n => n.id === importantNutrient.id,
          );
          if (!nutrient) {
            return null;
          }
          return (
            <NutrientBox key={nutrient.id} nutrient={nutrient} value={value} />
          );
        })}
      </NutrientsList>
    </TabPanel>
  );
};

export default PatientNutrientsTabPanel;
