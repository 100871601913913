import * as yup from "yup";

import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";

import { NutrientDto, nutrientSchema } from "./common";

export const fetchFoodReplacement = async (
  id: number,
): Promise<ApiResponse<GroupDto[]>> => {
  const response = await fetchData(
    `/dietitian/diets/${id}/food/replacement2`,
    APIMethods.GET,
  );
  return foodReplacementResponseSchema.validate(response);
};

export interface GroupDto {
  id: number;
  name: string;
  nameEn: string;
  products: ProductDto[];
}

interface ProductDto {
  id: number;
  description: string;
  descriptionEn?: string;
  measures: MeasureDto[];
  nutrients: NutrientDto[];
}

interface MeasureDto {
  id: number;
  grams: number;
}

const measureDto = yup.object().shape({
  id: yup.number().required(),
  grams: yup.number().required(),
});

const productSchema = yup.object().shape({
  id: yup.number().required(),
  description: yup.string().required(),
  descriptionEn: yup.string().ensure(),
  measures: yup.array().of(measureDto).required(),
  nutrients: yup.array().of(nutrientSchema).required(),
});

const groupSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().ensure(),
  nameEn: yup.string().ensure(),
  products: yup.array().of(productSchema).required(),
});

const foodReplacementResponseSchema = yup.object().shape({
  data: yup.array().of(groupSchema).required(),
});
