import { Fragment, ReactNode } from "react";

import { Typography } from "@mui/material";

import { getCategoryColor, roundNutrientDigitsById } from "@utils";
import { useAppTranslation } from "@hooks";
import { CategoryNutrientsResponse } from "@typeDefinitions/responses/dictionaries";
import { Nutrient } from "@typeDefinitions";

import {
  DividerStyled,
  GroupLabelWrapper,
  GroupWrapper,
  StyledElement,
  TypographyContentLabel,
  TypographyStyled,
} from "./GroupedNutrients.styled";

interface NutrientGroup {
  biggerPadding?: boolean;
  category: CategoryNutrientsResponse;
  renderNutrient?: (
    categoryId: number,
    nutrientId: number,
    unit: string,
  ) => ReactNode;
  renderTooltip?: (nutrientId: number, children: ReactNode) => ReactNode;
  nutrients?: Nutrient[];
}

export const NutrientGroup = ({
  biggerPadding,
  category,
  nutrients,
  renderNutrient = (categoryId, nutrientId, unit) => (
    <TypographyStyled variant="subtitle2">
      {roundNutrientDigitsById(
        nutrients?.find(({ id }) => id === nutrientId)?.value,
        categoryId,
        nutrientId,
      ) ?? "-"}{" "}
      {unit}
    </TypographyStyled>
  ),
  renderTooltip = (nutrientId, children) => (
    <StyledElement>{children}</StyledElement>
  ),
}: NutrientGroup) => {
  const { isPolishChosen, t } = useAppTranslation();

  return (
    <GroupWrapper biggerPadding={biggerPadding}>
      <GroupLabelWrapper>
        <Typography variant="body1">
          {isPolishChosen ? category.namePl : category.nameEn}
        </Typography>
        <TypographyContentLabel>{t("product.content")}</TypographyContentLabel>
      </GroupLabelWrapper>
      <DividerStyled borderColor={getCategoryColor(category.id) ?? ""} />
      {category.nutrients.map(nutrient => (
        <Fragment key={nutrient.id}>
          {renderTooltip(
            nutrient.id,
            <>
              <Typography variant="body2">
                {isPolishChosen
                  ? nutrient.descriptionPl
                  : nutrient.descriptionEn}
              </Typography>

              {renderNutrient(category.id, nutrient.id, nutrient.units)}
            </>,
          )}
        </Fragment>
      ))}
    </GroupWrapper>
  );
};
