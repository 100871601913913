import { Typography, Box } from "@mui/material";
import { ArrowPrev, Plus } from "@assets/icons";
import { useEffect, useMemo } from "react";
import { CalendarTabs } from "../MiniCalendar/_components/CalendarTabs";
import { CalendarTimeUnit } from "../MiniCalendar/_components/CalendarTabs";
import { ThemeProviderWrapperNew } from "themeNew";
import { FormProvider, useWatch } from "react-hook-form";
import { WeekDayButton } from "@views/dietician/Home/components/Calendar/MiniCalendar/MiniCalendar.styled";
import { BigCalendarDayView } from "./_components/BigCalendarDayView";
import {
  addDays,
  format,
  isToday,
  startOfWeek,
  endOfWeek,
  addMonths,
} from "date-fns";
import { pl, enUS } from "date-fns/locale";
import { BigCalendarWeekView } from "./_components/BigCalendarWeekView";
import { BigCalendarMonthView } from "./_components/BigCalendarMonthView";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";
import { getSubscriptionInfo } from "@utils/subscription";
import { mapCalendarScheduleEvents, MEETING_TYPE } from "@utils";
import { useSearchScheduleEvents } from "@hooks/queries";
import { useCalendarFiltersForm } from "@components/CalendarFilters";
import { ScheduleVisitModal } from "../../ScheduleVisit/ScheduleVisitModal";
import { useScheduleVisitModalContext } from "../../ScheduleVisit/ScheduleVisitModalContext";
import {
  BigCalendarTopography,
  BigCalendarWrapper,
  BigCalendarAddButton,
  BigCalendarTodayButton,
} from "./BigCalendar.styled";
import { useAppTranslation } from "@hooks";
import { CalendarFilters } from "@components/CalendarFilters";

export const BigCalendar = () => {
  const { t, isPolishChosen } = useAppTranslation();

  const form = useCalendarFiltersForm();
  const { control, setValue } = form;

  const { handleVisitModalOpen: onVisitOpen, handleSetStartDate } =
    useScheduleVisitModalContext();

  const { account } = useFetchDietitianAccountQuery();
  const { hasSubscription } = getSubscriptionInfo();

  const enabled = !!account?.clinic?.id && hasSubscription !== null;

  const { scheduleEvents } = useSearchScheduleEvents({
    enabled,
  });

  const calendarEvents = useMemo(
    () => mapCalendarScheduleEvents(scheduleEvents ?? []),
    [scheduleEvents],
  );

  const events = useMemo(() => [...calendarEvents], [calendarEvents]);

  const timeUnit = useWatch({ control, name: "time_unit" });
  const currentDate = useWatch({ control, name: "date" });

  const setCurrentDate = (newDate: Date) => {
    setValue("date", newDate);
  };

  const setCurrentTimeUnit = (newTimeUnit: CalendarTimeUnit) => {
    setValue("time_unit", newTimeUnit);
  };

  const handlePreviousDay = () => {
    setCurrentDate(addDays(currentDate, -1));
  };

  const handleNextDay = () => {
    setCurrentDate(addDays(currentDate, 1));
  };

  const handlePreviousWeek = () => {
    setCurrentDate(addDays(currentDate, -7));
  };

  const handleNextWeek = () => {
    setCurrentDate(addDays(currentDate, 7));
  };

  const weekStart = startOfWeek(currentDate, { weekStartsOn: 1 });
  const weekEnd = endOfWeek(currentDate, { weekStartsOn: 1 });

  const weekRange = `${format(weekStart, "dd.MM.yyyy")} - ${format(
    weekEnd,
    "dd.MM.yyyy",
  )}`;

  const handlePreviousMonth = () => {
    setCurrentDate(addMonths(currentDate, -1));
  };

  const handleNextMonth = () => {
    setCurrentDate(addMonths(currentDate, 1));
  };

  const currentLocale = isPolishChosen ? pl : enUS;
  const currentMonth = format(currentDate, "LLLL yyyy", {
    locale: currentLocale,
  });
  let formattedDate = format(currentDate, "EEE dd MMM", {
    locale: currentLocale,
  });

  formattedDate = (
    formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)
  ).replace(".", ",");

  const handleNavigateToCurrentDate = () => {
    setCurrentDate(new Date());
    setCurrentTimeUnit(CalendarTimeUnit.TODAY);
  };

  const facilitiesAndMeetings = useMemo(() => {
    return events.map(({ facilityId, type }) => ({
      facilityId,
      type,
    }));
  }, [events]);

  useEffect(() => {
    handleNavigateToCurrentDate();
  }, []);

  return (
    <ThemeProviderWrapperNew>
      <FormProvider {...form}>
        <BigCalendarWrapper>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center" gap={1}>
              <BigCalendarTopography variant="h6">
                {t("sidemenu.agenda")}
              </BigCalendarTopography>
            </Box>
            <BigCalendarAddButton onClick={onVisitOpen}>
              <Plus width="10px" height="10px" />
              <span>{t("calendar.add_visit")}</span>
            </BigCalendarAddButton>
          </Box>
          <Box display="flex" gap="18px" alignItems="center">
            <CalendarTabs />
            <BigCalendarTodayButton onClick={handleNavigateToCurrentDate}>
              {t("calendar.todayDay")}
            </BigCalendarTodayButton>
            {timeUnit === CalendarTimeUnit.TODAY && (
              <Box display="flex" alignItems="center" gap="10px">
                <WeekDayButton onClick={handlePreviousDay}>
                  <ArrowPrev />
                </WeekDayButton>
                <Typography
                  variant="body1"
                  fontWeight="600"
                >{`${formattedDate} ${
                  isToday(currentDate) ? `(${t("calendar.todayDay")})` : ""
                }`}</Typography>
                <WeekDayButton onClick={handleNextDay}>
                  <ArrowPrev className="rotate-180" />
                </WeekDayButton>
              </Box>
            )}
            {timeUnit === CalendarTimeUnit.WEEK && (
              <Box display="flex" alignItems="center" gap="10px">
                <WeekDayButton onClick={handlePreviousWeek}>
                  <ArrowPrev />
                </WeekDayButton>
                <Typography variant="body1" fontWeight="600">
                  {weekRange}
                </Typography>
                <WeekDayButton onClick={handleNextWeek}>
                  <ArrowPrev className="rotate-180" />
                </WeekDayButton>
              </Box>
            )}
            {timeUnit === CalendarTimeUnit.MONTH && (
              <Box display="flex" alignItems="center" gap="10px">
                <WeekDayButton onClick={handlePreviousMonth}>
                  <ArrowPrev />
                </WeekDayButton>
                <Typography
                  variant="body1"
                  fontWeight="600"
                  textTransform="capitalize"
                >
                  {currentMonth}
                </Typography>
                <WeekDayButton onClick={handleNextMonth}>
                  <ArrowPrev className="rotate-180" />
                </WeekDayButton>
              </Box>
            )}
          </Box>
          <CalendarFilters facilitiesAndMeetings={facilitiesAndMeetings} />
          <div className="w-full overflow-y-auto custom-scrollbar">
            <div className="w-full min-w-[1000px]">
              {timeUnit === CalendarTimeUnit.TODAY && (
                <BigCalendarDayView
                  currentDate={currentDate}
                  onVisitModalOpen={onVisitOpen}
                  handleSetStartDate={handleSetStartDate}
                />
              )}
              {timeUnit === CalendarTimeUnit.WEEK && (
                <BigCalendarWeekView
                  currentDate={currentDate}
                  onVisitModalOpen={onVisitOpen}
                  setCurrentDate={setCurrentDate}
                  setCurrentTimeUnit={setCurrentTimeUnit}
                  handleSetStartDate={handleSetStartDate}
                />
              )}
              {timeUnit === CalendarTimeUnit.MONTH && (
                <BigCalendarMonthView
                  currentDate={currentDate}
                  events={events}
                  setCurrentDate={setCurrentDate}
                  setCurrentTimeUnit={setCurrentTimeUnit}
                  locale={currentLocale}
                  onVisitModalOpen={onVisitOpen}
                  handleSetStartDate={handleSetStartDate}
                />
              )}
            </div>
          </div>
        </BigCalendarWrapper>
      </FormProvider>
      <ScheduleVisitModal />
    </ThemeProviderWrapperNew>
  );
};
