import { useMutation } from "@tanstack/react-query";
import { MutationOptions } from "../types";
import { postSingedSurveyReport } from "@client/surveys";

export const usePostSingedSurveyReportMutation = (
  options?: MutationOptions,
) => {
  return useMutation<unknown, unknown, PostSingedSurveyReportMutationPayload>(
    ({ url }) => postSingedSurveyReport(url),
    options,
  );
};

interface PostSingedSurveyReportMutationPayload {
  url: string;
}
