import { useEffect, useRef, useState } from "react";

import Quill from "quill";

import { SAFARI_TAB_INDEX } from "@utils";

import "quill/dist/quill.snow.css";

interface QuillEditorProps {
  value?: string;
  defaultValue?: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  placeholder?: string;
}

export const QuillEditor = ({
  value,
  defaultValue,
  onChange,
  onBlur,
  placeholder,
}: QuillEditorProps) => {
  const quillRef = useRef<HTMLDivElement>(null);
  const toolbarRef = useRef<HTMLDivElement>(null);
  const quillInstance = useRef<Quill | null>(null);
  const [editorFocus, setEditorFocus] = useState(false);
  const [toolbarFocus, setToolbarFocus] = useState(false);
  const focus = editorFocus || toolbarFocus;

  useEffect(() => {
    if (quillRef.current && !quillInstance.current) {
      quillInstance.current = new Quill(quillRef.current, {
        theme: "snow",
        modules: {
          toolbar: toolbarRef.current,
        },
        placeholder: placeholder || "",
      });

      if (defaultValue) {
        quillInstance.current.clipboard.dangerouslyPasteHTML(defaultValue);
      }

      quillInstance.current.on("text-change", () => {
        const value = quillInstance.current?.getSemanticHTML() ?? "";
        onChange(value);
        !focus && setEditorFocus(true);
      });

      const handleFocus = () => !editorFocus && setEditorFocus(true);
      const handleBlur = () => setEditorFocus(false);

      quillInstance.current.root.addEventListener("focusin", handleFocus);
      quillInstance.current.root.addEventListener("click", handleFocus);
      quillInstance.current.root.addEventListener("focusout", handleBlur);
    }

    if (toolbarRef.current) {
      const toolbar = toolbarRef.current;
      const handleFocus = () => setToolbarFocus(true);
      const handleBlur = (e: FocusEvent) => {
        if (toolbar && !toolbar.contains(e.relatedTarget as Node))
          setToolbarFocus(false);
      };

      toolbar.addEventListener("focusin", handleFocus);
      toolbar.addEventListener("focusout", handleBlur);
    }
  }, [defaultValue, placeholder, onChange, focus]);

  useEffect(() => {
    if (quillInstance.current && value !== undefined) {
      if (value !== quillInstance.current.root.innerHTML) {
        quillInstance.current.root.innerHTML = value;
      }
    }
  }, [value]);

  return (
    <>
      <div
        ref={toolbarRef}
        data-ql-hidden={focus ? "false" : "true"}
        tabIndex={SAFARI_TAB_INDEX}
      >
        <span className="ql-formats">
          <button className="ql-bold" />
          <button className="ql-italic" />
          <button className="ql-underline" />
          <button className="ql-strike" />
        </span>

        <span className="ql-formats">
          <button className="ql-link" />
        </span>

        <span className="ql-formats">
          <select className="ql-color" />
          <select className="ql-background" />
        </span>

        <span className="ql-formats">
          <button className="ql-clean" />
        </span>

        <span className="ql-formats">
          <button className="ql-list" value="ordered" />
          <button className="ql-list" value="bullet" />
        </span>
      </div>

      <div ref={quillRef} onBlur={onBlur} />
    </>
  );
};
