import { ValidationError } from "yup";

export const logFetchValidateError = (url: string) => (e: ValidationError) => {
  console.error({
    name: e.name,
    url: url,
    errors: e.errors,
  });
  throw e;
};
