import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

import { DictionariesDto, dictionariesSchema } from "./fetchSingedSurveys";

export const fetchSurveyDictionaries = async (): Promise<
  ApiResponse<DictionariesDto>
> => {
  const response = await fetchData(
    "/dietitian/survey-dictionaries",
    APIMethods.GET,
  );

  return surveyDictionariesSchema.validate(response);
};

const surveyDictionariesSchema = yup.object().shape({
  data: dictionariesSchema.required(),
});
