import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  createClientMeasurement,
  CreateClientMeasurementRequest,
  CreateClientMeasurementResponse,
} from "@client";
import { MutationOptions } from "./types";
import {
  fetchClientMeasurementListQueryInfiniteKey,
  fetchClientMeasurementListQueryKey,
} from "./useFetchClientMeasurementList";
import { fetchClientLastMeasurementQueryKey } from "./useFetchClientLastMeasurementQuery";
import { AxiosError } from "axios";
import { ApiErrorIf } from "@typeDefinitions";
import { patientKeys } from "@hooks/queries/client/patientKeys";
import { patientBodyMeasurementKeys } from "@hooks/queries/client/bodyMeasurement/patientBodyMeasurementKey";

export const useCreateClientMeasurementMutation = (
  clientId: string,
  options?: MutationOptions<CreateClientMeasurementResponse>,
) => {
  const queryClient = useQueryClient();

  return useMutation<
    CreateClientMeasurementResponse,
    AxiosError<ApiErrorIf>,
    CreateClientMeasurementRequest
  >(request => createClientMeasurement(clientId, request), {
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries([
        fetchClientLastMeasurementQueryKey,
        clientId,
      ]);
      queryClient.invalidateQueries([
        fetchClientMeasurementListQueryKey,
        clientId,
      ]);
      queryClient.invalidateQueries([
        fetchClientMeasurementListQueryInfiniteKey,
        clientId,
      ]);
      queryClient.invalidateQueries(
        patientBodyMeasurementKeys.last(parseInt(clientId)),
      );
      options?.onSuccess && options.onSuccess();
    },
  });
};
