import { MouseEventHandler } from "react";
import { IconButton } from "@mui/material";
import { WandWithStars } from "@assets/icons/DesignSystem";
import { useAppTranslation, useModalState } from "@hooks";
import {
  AutogenerateMealScheduleModal,
  DefaultValuesIf,
} from "./AutogenerateMealScheduleModal";
import {
  ButtonStyled,
  MenuItemStyled,
} from "./AutogenerateMealScheduleModal.styled";

interface AutogenereteMealScheduleButtonProps {
  variant?: "text" | "outlined" | "contained" | "icon" | "menu";
  defaultValues?: DefaultValuesIf;
}

export const AutogenereteMealScheduleButton = ({
  variant = "outlined",
  defaultValues,
}: AutogenereteMealScheduleButtonProps) => {
  const { t } = useAppTranslation();
  const [openModal, onOpenModal, onCloseModal] = useModalState();

  const handleOpen: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();
    onOpenModal();
  };

  const handleOpenMenu: MouseEventHandler<HTMLLIElement> = e => {
    e.stopPropagation();
    onOpenModal();
  };

  switch (variant) {
    case "icon":
      return (
        <>
          <IconButton onClick={handleOpen}>
            <WandWithStars />
          </IconButton>
          {openModal && (
            <AutogenerateMealScheduleModal
              open
              onClose={onCloseModal}
              defaultValues={defaultValues}
            />
          )}
        </>
      );
    case "menu":
      return (
        <>
          <MenuItemStyled onClick={handleOpenMenu}>
            <WandWithStars />
            {t("autogenerate.submit")}
          </MenuItemStyled>
          {openModal && (
            <AutogenerateMealScheduleModal
              open
              onClose={onCloseModal}
              defaultValues={defaultValues}
            />
          )}
        </>
      );
    default:
      return (
        <>
          <ButtonStyled variant={variant} onClick={handleOpen}>
            <WandWithStars />
            {t("autogenerate.submit")}
          </ButtonStyled>
          {openModal && (
            <AutogenerateMealScheduleModal
              open
              onClose={onCloseModal}
              defaultValues={defaultValues}
            />
          )}
        </>
      );
  }
};
