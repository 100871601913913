import { useMemo, useState } from "react";

import { TabContext } from "@mui/lab";
import { CircularProgress, ClickAwayListener, useTheme } from "@mui/material";
import { round } from "lodash";

import { Clock, Cutlery } from "@assets/icons";
import { useAppTranslation, useNutrients } from "@hooks";
import {
  useFetchRecipeQuery,
  usePostToggleFavoriteRecipeMutation,
} from "@hooks/queries";
import { useTagsNew } from "@hooks/useTagsNew";
import { ENERGY_ID, buildNutrientsDict } from "@utils";
import { TagCategoryType } from "@utils/tagsNew";

import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";
import { t } from "i18next";
import { DetailsSection } from "../common/DetailsSection";
import { PreviewActionButtons } from "../common/PreviewActionButtons";
import { TabsWrapper } from "../common/TabsWrapper";
import { ProductIf } from "./NutrientsTab/ProductsTable";
import {
  DetailsWrapper,
  ImgStyled,
  Label,
  LoadingWrapper,
  TagIconWrapper,
  Wrapper,
} from "./RecipeReview.styled";
import { RecipeTabPanels } from "./RecipeTabPanels";
import { ServingContextProvider } from "./ServingsContext";

interface RecipePreviewProps {
  recipeId: number | null;
  onClose: () => void;
  ActionButtons?: (props: { hasImg: boolean }) => JSX.Element;
  actions?: {
    like: boolean;
    edit: boolean;
    delete: boolean;
    favorite: boolean;
  };
}
export const RecipePreview = ({
  recipeId,
  onClose,
  ActionButtons,
  actions,
}: RecipePreviewProps) => {
  const { isPolishChosen } = useAppTranslation();
  const { account } = useFetchDietitianAccountQuery();
  const { recipe, isLoading } = useFetchRecipeQuery(recipeId as number, {
    enabled: !!recipeId,
  });
  const { mutate, isLoading: isLoadingToggle } =
    usePostToggleFavoriteRecipeMutation();
  const [tab, setTab] = useState(RecipeTabs.NUTRITIONAL_VALUES);

  const { tagCategoryDictionary, tagNameDictionary } = useTagsNew();
  const { nutrientDict } = useNutrients();
  const {
    colors: { neutral },
  } = useTheme();
  const difficultyOfPreparationTagList = tagCategoryDictionary.get(
    TagCategoryType.DIFFICULTY_OF_PREPARATION,
  )?.tags;
  const preparationTimeTagList = tagCategoryDictionary.get(
    TagCategoryType.PREPARATION_TIME,
  )?.tags;

  const difficultyOfPreparationTag = recipe?.tags?.find(tag =>
    difficultyOfPreparationTagList?.some(t => t.id === tag.id),
  );
  const preparationTimeTag = recipe?.tags?.find(tag =>
    preparationTimeTagList?.some(t => t.id === tag.id),
  );

  const recipeNutrientsDict = useMemo(
    () => buildNutrientsDict(recipe?.nutrients ?? []),
    [recipe],
  );

  const roundedKcal = useMemo(
    () =>
      round(
        recipeNutrientsDict.get(ENERGY_ID) ?? 0,
        nutrientDict.get(ENERGY_ID)?.roundPrecision,
      ),
    [recipeNutrientsDict, nutrientDict],
  );
  const img = recipe?.media?.s3Url;

  const productsMapped = useMemo<ProductIf[]>(
    () =>
      recipe?.foodRecipe.map(f => ({
        id: f.food.id,
        grams: f.grams,
        measureId: f.foodMeasureId,
        name:
          (isPolishChosen ? f.food.descriptionPl : f.food.description) ?? "",
        nutrients: f.food.nutrients,
        measures: f.food.measures,
        foodId: f.food.id,
      })) ?? [],
    [recipe, isPolishChosen],
  );

  const tabs = useMemo(
    () => [
      {
        label: t("common.nutrients_values"),
        value: RecipeTabs.NUTRITIONAL_VALUES,
      },
      {
        label: t("common.preparation"),
        value: RecipeTabs.PREPARATION,
      },
      {
        label: t("common.tags"),
        value: RecipeTabs.TAGS,
      },
    ],
    [t],
  );

  const ActionButtonsComponent = ActionButtons ? (
    <ActionButtons hasImg={!!img} />
  ) : (
    <PreviewActionButtons
      onToggle={() => mutate(recipeId?.toString() ?? "")}
      isLoading={isLoadingToggle}
      isFavorite={!!recipe?.favorite.includes(account?.id ?? 0)}
      actions={
        actions
          ? {
              canEdit: actions.edit && !!recipe?.actions.canEdit,
              canDelete: actions.delete && !!recipe?.actions.canDelete,
              canVisible: recipe?.actions.canVisible,
            }
          : recipe?.actions
      }
      editLink={`/recipes/${recipeId}/edit`}
      copyLink={`/recipes/${recipeId}/copy`}
      onClose={onClose}
      withImg={!!img}
      showFavorite={actions ? actions.favorite : true}
    />
  );

  if (isLoading)
    return (
      <Wrapper>
        <LoadingWrapper>
          <CircularProgress />;
        </LoadingWrapper>
      </Wrapper>
    );

  return (
    <ServingContextProvider totalServings={recipe?.servings ?? 1}>
      <ClickAwayListener onClickAway={onClose}>
        <Wrapper>
          <TabContext value={tab}>
            {img && ActionButtonsComponent}

            {img && <ImgStyled src={img} />}

            <DetailsWrapper>
              <DetailsSection
                titlePl={recipe?.name ?? ""}
                titleEn={recipe?.name ?? ""}
                kcal={roundedKcal}
                actions={<>{!img && ActionButtonsComponent}</>}
                tags={
                  <>
                    {!!preparationTimeTag && (
                      <TagIconWrapper>
                        <Clock fill={neutral.dark[700]} />
                        <Label>
                          {tagNameDictionary.get(Number(preparationTimeTag.id))}
                        </Label>
                      </TagIconWrapper>
                    )}
                    {!!difficultyOfPreparationTag && (
                      <TagIconWrapper>
                        <Cutlery fill={neutral.dark[700]} />
                        <Label>
                          {tagNameDictionary.get(
                            Number(difficultyOfPreparationTag.id),
                          )}
                        </Label>
                      </TagIconWrapper>
                    )}
                  </>
                }
              />

              <TabsWrapper onChange={v => setTab(v)} tabs={tabs} value={tab} />
            </DetailsWrapper>

            <RecipeTabPanels
              nutrients={recipe?.nutrients ?? []}
              products={productsMapped}
              description={recipe?.description ?? ""}
              descriptionEn={recipe?.descriptionEn ?? ""}
              tags={recipe?.tags.map(t => t.id) ?? []}
              comment={recipe?.comment}
              commentEn={recipe?.commentEn}
            />
          </TabContext>
        </Wrapper>
      </ClickAwayListener>
    </ServingContextProvider>
  );
};

export enum RecipeTabs {
  NUTRITIONAL_VALUES = "0",
  PREPARATION = "1",
  TAGS = "2",
}
