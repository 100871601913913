import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

const WhiteTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "30rem",
    maxHeight: "21.875rem",
    fontSize: "inherit",
    color: "inherit",
    backgroundColor: theme.colors.whiteBackground,
    fontFamily: theme.fontFamily.regular,
    lineHeight: "inherit",
    boxShadow: "0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px -1px rgb(0 0 0 / 10%)",
    borderRadius: "0.375rem",
    padding: "0.75rem",
    whiteSpace: "pre-line",
  },
  [`& .${tooltipClasses.arrow}`]: {
    "color": theme.colors.whiteBackground,
    "&:before": {
      boxShadow:
        "0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px -1px rgb(0 0 0 / 10%)",
    },
  },
  [`& .${tooltipClasses.tooltipPlacementRight}`]: {
    left: "-0.5rem",
    top: "-0.2rem",
  },
}));

export const MuiWhiteTooltip = (props: TooltipProps) => {
  const { children, ...rest } = props;
  return <WhiteTooltip {...rest}>{children}</WhiteTooltip>;
};
