import { styled } from "@mui/material";
import { IconButton } from "@components/IconButton";
import { Button } from "@components/Button";

export const TasksStyledIconButton = styled(IconButton)(({ theme }) => ({
  borderColor: theme.colors.primaryLightNew,
  width: "40px",
  height: "40px",
  borderRadius: "8px",
}));

export const TaskActionsStyledButton = styled(Button)`
  font-size: 14px;
  font-weight: 500;
  padding: 8.5px 12px;
  border-radius: 8px;
`;
