import { Control, FieldValues, Path, useController } from "react-hook-form";
import { useCallback } from "react";

interface UseInputSliderControllerProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  transformValue: (value: number) => any;
  transformSliderValue: (value: number) => any;
}

interface UseInputSliderControllerReturn {
  value: number;
  onInputChange: (newValue: string) => void;
  onSliderChange: (newValue: number) => void;
}

export const useInputSliderController = <T extends FieldValues>({
  control,
  name,
  transformValue,
  transformSliderValue,
}: UseInputSliderControllerProps<T>): UseInputSliderControllerReturn => {
  const {
    field: { value, onChange },
  } = useController({ control, name });

  const onInputChange = useCallback(
    (newValue: string) => onChange(transformValue(Number(newValue))),
    [onChange, transformValue],
  );

  const onSliderChange = useCallback(
    (newValue: number) => onChange(transformSliderValue(newValue)),
    [onChange, transformValue],
  );

  return {
    value,
    onInputChange,
    onSliderChange,
  };
};
