import { Button, IconButton, styled } from "@mui/material";
import { LARGE_DESKTOP_MAX_CONTENT_WIDTH } from "@utils";

export const ContentSelectorWrapper = styled("div")`
  display: grid;
  gap: 2rem;
  width: 100%;
`;

export const TableOfContentsWrapper = styled("div")`
  display: flex;
  gap: 1.5rem;
  align-items: start;
`;

export const QuestionnaireEditWrapper = styled("div")`
  margin: 0 auto;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.laptop}px) {
    max-width: ${LARGE_DESKTOP_MAX_CONTENT_WIDTH};
  }
`;

export const PreviewButton = styled(IconButton)`
  padding: unset;
  background-color: ${({ theme }) => theme.colors.neutral.light[100]};
  border: 1px solid ${({ theme }) => theme.palette.primary.medium};

  & svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

export const SettingsButton = styled(Button)`
  white-space: nowrap;
`;
