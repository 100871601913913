import { APIMethods, NoContentApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export type DeletePatientBodyMeasurementParams = {
  measurementId: number;
};

export type DeletePatientBodyMeasurementResponse = NoContentApiResponse;

export const deletePatientBodyMeasurement = async (
  params: DeletePatientBodyMeasurementParams,
): Promise<DeletePatientBodyMeasurementResponse> => {
  return await fetchData(
    "/dietitian/patient-measurements/{measurement_id}".replace(
      "{measurement_id}",
      params.measurementId.toString(),
    ),
    APIMethods.DELETE,
  );
};
