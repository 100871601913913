import { CheckedIcon } from "@assets/icons";
import { FetchClientResponse } from "@client";
import { Chip, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  ClientTitleWrapper,
  StyledClientTitle,
  StyledIconButton,
  TitleWrapper,
} from "./ClientTitle.styled";
import { useAppTranslation } from "@hooks";

interface ClientTitleProps {
  client: FetchClientResponse;
}

export const ClientTitle = ({ client }: ClientTitleProps) => {
  const { t } = useAppTranslation();
  const { palette } = useTheme();
  const navigate = useNavigate();

  return (
    <ClientTitleWrapper>
      <StyledIconButton
        size="small"
        onClick={() => navigate("/patients")}
        className="rotate-90"
      >
        <CheckedIcon fill={palette.primary.main} size="w-5 h-5" />
      </StyledIconButton>
      <TitleWrapper>
        <StyledClientTitle>{`${client?.firstName} ${client?.lastName}`}</StyledClientTitle>
      </TitleWrapper>
      <Chip
        className="w-fit"
        color={client.active ? "success" : "default"}
        size="small"
        label={client.active ? t("common.active") : t("common.inactive")}
      />
    </ClientTitleWrapper>
  );
};
