import { DialogActions, DialogTitle, IconButton, styled } from "@mui/material";

export const DialogTitleStyled = styled(DialogTitle)`
  font-size: 1.125rem;
  line-height: 1.25rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;

export const IconButtonStyled = styled(IconButton)`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
`;
