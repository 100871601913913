import { styled } from "@mui/material";

export const TagsTabStyled = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  background: ${({ theme }) => theme.colors.neutral.light[100]};
`;

export const TagsContainer = styled("div")`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  background: ${({ theme }) => theme.colors.neutral.light[100]};
`;

export const LabelStyled = styled("span")`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #4d516e;
`;
