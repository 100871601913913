import _, { sum, sumBy } from "lodash";

import { MACROS_ARRAY } from "@utils/macros";
import { useAppTranslation, useNutrientDictionary } from "@hooks";
import { MicronutrientPreview } from "@components/MicronutrientsModal";
import { DietitianProgramScheduleDay } from "@client/schedule";
import { DayDto } from "@client/program";

import { ScheduleSummaryTile } from "./ScheduleSummaryTile";
import { ScheduleSummaryNutrient } from "./ScheduleSummaryNutrient";
import {
  ScheduleSummaryWrapper,
  StyledTitle,
  SummaryMacrosWrapper,
  SummaryNutrientsWrapper,
  SummaryTitleWrapper,
} from "./ScheduleSummary.styled";

interface ScheduleSummaryProps {
  scheduleData: DietitianProgramScheduleDay[];
  programDays: DayDto[];
}

export const ScheduleSummary = ({
  scheduleData,
  programDays,
}: ScheduleSummaryProps) => {
  const { nutrientDictionary: nutrientDict } = useNutrientDictionary();
  const { t } = useAppTranslation();
  const scheduleLength = scheduleData.length;
  const nutrients = scheduleData.flatMap(day => day.nutrients);
  const meanValuesDict = _(nutrients)
    .groupBy("id")
    .map((nutrient, id) => [id, sumBy(nutrient, "value") / scheduleLength])
    .fromPairs()
    .value();

  const meanTargetDict = _(programDays)
    .flatMap(day =>
      day.frequency.flatMap(weekday => day.targetNutrients.nutrients),
    )
    .groupBy("id")
    .map((nutrient, id) => [
      id,
      sumBy(nutrient, "value") /
        sum(programDays.map(day => day.frequency.length)),
    ])
    .fromPairs()
    .value();

  const daysShowNutrients = programDays
    .flatMap(day =>
      day.targetNutrients.nutrients.filter(nutrient => nutrient.visible),
    )
    .map(nutrient => nutrient.id);

  const showNutrients = _(daysShowNutrients)
    .uniq()
    .filter(nutrient => !MACROS_ARRAY.includes(nutrient))
    .value();

  const meanValuesArray = Object.entries(meanValuesDict).map(([id, value]) => ({
    id: parseInt(id),
    value,
  }));

  const normId = programDays[0]?.targetNutrients.norm?.id;

  return (
    <ScheduleSummaryWrapper>
      <SummaryTitleWrapper>
        <StyledTitle>
          {t("schedule.average_for")}: {scheduleLength}{" "}
          {t("common.days", { count: scheduleLength })}
        </StyledTitle>
        <MicronutrientPreview
          means={meanValuesArray}
          normId={normId}
          noPadding
        />
      </SummaryTitleWrapper>
      <SummaryMacrosWrapper>
        {MACROS_ARRAY.map(macro => (
          <ScheduleSummaryTile
            key={macro}
            name={nutrientDict[macro]?.name ?? ""}
            value={meanValuesDict[macro] ?? 0}
            target={meanTargetDict[macro] ?? 0}
            unit={nutrientDict[macro]?.unit ?? ""}
          />
        ))}
      </SummaryMacrosWrapper>
      <SummaryNutrientsWrapper>
        {showNutrients.map((nutrient, i) => (
          <ScheduleSummaryNutrient
            key={`${nutrient}-${i}`}
            name={nutrientDict[nutrient]?.name ?? ""}
            value={meanValuesDict[nutrient] ?? 0}
            target={meanTargetDict[nutrient] ?? 0}
            unit={nutrientDict[nutrient]?.unit ?? ""}
          />
        ))}
      </SummaryNutrientsWrapper>
    </ScheduleSummaryWrapper>
  );
};
