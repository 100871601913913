import dayjs from "dayjs";

import { GoogleScheduleEventDto, SearchScheduleEventDto } from "@client";
import { EventWithType } from "@typeDefinitions";

export enum MEETING_TYPE {
  CONSULTATION = 1,
  OTHER = 2,
  TODO = 3,
  GOOGLE = 4,
}

export enum MEETING_MODE {
  OFFICE = 1,
  ONLINE = 2,
  OTHER = 3,
}

export enum EVENT_STATUS {
  UNCONFIRMED = 1,
  CONFIRMED = 2,
  CANCELED = 3,
  DONE = 4,
  NOT_DONE = 5,
}

export enum Currencies {
  PLN = "PLN",
  EUR = "EUR",
  USD = "USD",
  GBP = "GBP",
}

export const mapCalendarScheduleEvents = (
  data: (SearchScheduleEventDto | GoogleScheduleEventDto)[],
): EventWithType[] => {
  return data.map(scheduleEvent => {
    const hasGoogleId = "googleId" in scheduleEvent;
    const googleId = hasGoogleId ? { googleId: scheduleEvent.googleId } : {};
    const fallbackType = hasGoogleId
      ? MEETING_TYPE.GOOGLE
      : MEETING_TYPE.CONSULTATION;
    return {
      id: scheduleEvent.id,
      type: scheduleEvent.meetingType?.id ?? fallbackType,
      start: dayjs(scheduleEvent.dateStart).toDate(),
      end: dayjs(scheduleEvent.dateEnd).toDate(),
      label: scheduleEvent.title,
      title: scheduleEvent.title,
      color: "",
      tintColor: "",
      dietitianId: scheduleEvent.dietitian.id,
      facilityId: scheduleEvent.facility?.id ?? null,
      allDay: scheduleEvent.isAllDay,
      desc: scheduleEvent.additionalInfo,
      ...googleId,
    };
  });
};
