import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const XinRedRound = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      style={{ fill: "none" }}
      width="24"
      height="25"
      viewBox="0 0 24 25"
    >
      <rect x="2" y="2.5" width="20" height="20" rx="10" fill="#D44450" />
      <rect
        x="2"
        y="2.5"
        width="20"
        height="20"
        rx="10"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M14.9492 14.8008C15.0664 14.931 15.125 15.0807 15.125 15.25C15.125 15.4193 15.0664 15.569 14.9492 15.6992C14.819 15.8164 14.6693 15.875 14.5 15.875C14.3307 15.875 14.181 15.8164 14.0508 15.6992L12 13.6289L9.94922 15.6992C9.81901 15.8164 9.66927 15.875 9.5 15.875C9.33073 15.875 9.18099 15.8164 9.05078 15.6992C8.93359 15.569 8.875 15.4193 8.875 15.25C8.875 15.0807 8.93359 14.931 9.05078 14.8008L11.1211 12.75L9.05078 10.6992C8.93359 10.569 8.875 10.4193 8.875 10.25C8.875 10.0807 8.93359 9.93099 9.05078 9.80078C9.18099 9.68359 9.33073 9.625 9.5 9.625C9.66927 9.625 9.81901 9.68359 9.94922 9.80078L12 11.8711L14.0508 9.80078C14.181 9.68359 14.3307 9.625 14.5 9.625C14.6693 9.625 14.819 9.68359 14.9492 9.80078C15.0664 9.93099 15.125 10.0807 15.125 10.25C15.125 10.4193 15.0664 10.569 14.9492 10.6992L12.8789 12.75L14.9492 14.8008Z"
        fill="white"
      />
    </SvgIcon>
  );
};
