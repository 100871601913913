import { useAppParams } from "@hooks";
import {
  useFetchDietitianClientProgramScheduleQuery,
  useFetchDietitianProgramScheduleQuery,
} from "@hooks/queries/schedule";
import { ProgramScheduleNavigationContext } from "@views/dietician/ProgramSchedule/context";
import dayjs from "dayjs";
import { useContext, useMemo } from "react";

export const useRecipeEncounter = (query: string) => {
  const { patientId, programId } = useAppParams();
  const { currentRangeStart, currentRangeEnd } = useContext(
    ProgramScheduleNavigationContext,
  );
  const periodDays = currentRangeEnd.diff(currentRangeStart, "day") + 1;

  const { scheduleData } = useFetchDietitianProgramScheduleQuery(
    Number(programId),
    periodDays,
    currentRangeStart,
    { enabled: !patientId },
  );
  const { scheduleData: patientScheduleData } =
    useFetchDietitianClientProgramScheduleQuery(
      patientId,
      programId,
      periodDays,
      currentRangeStart,
      { enabled: !!patientId },
    );

  const encounters = useMemo(() => {
    const data = patientId ? patientScheduleData : scheduleData;

    return (
      data?.flatMap(({ date, meals }) =>
        meals
          .filter(({ recipe }) =>
            [
              recipe?.title.toLocaleLowerCase(),
              recipe?.titleEn?.toLocaleLowerCase(),
            ].includes(query.toLocaleLowerCase()),
          )
          .flatMap(({ dietMeal: { hour, title }, servings }) => ({
            date: dayjs(date).toDate(),
            hour: hour ?? "",
            name: title,
            selectedServings: servings ?? 1,
          })),
      ) ?? []
    );
  }, [scheduleData, patientScheduleData]);

  return { encounters };
};
