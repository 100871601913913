import styled from "@emotion/styled";
import { Button, IconButton, Stack, Typography } from "@mui/material";

export const CalendarWrapper = styled.div`
  width: 100%;
  height: max-content;
  max-height: 1050px;
  background-color: #fff;
  overflow: hidden;
  border-radius: 16px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  padding: 24px;
`;

export const CalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark["900"]};
`;

export const StyledCalendarHeaderBox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TabsWrapper = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.palette.primary.medium};
  border-radius: 8px;
  max-width: max-content;
  padding: 2.5px;
  background-color: white;
`;

export const AddButtonWrapper = styled(IconButton)`
  background-color: white;
  color: ${({ theme }) => theme.palette.primary.main};
  border: 1px solid ${({ theme }) => theme.palette.primary.medium};
  border-radius: 8px;
  width: 42px;
  height: 42px;
`;

export const TabButton = styled(Button)<{ active: boolean }>`
  color: ${({ theme, active }) =>
    active ? theme.palette.primary.main : theme.colors.neutral.dark["700"]};
  background-color: ${props =>
    props.active ? props.theme.palette.primary.medium : "transparent"};
  font-weight: 500;
  font-size: 12px;
`;

export const WeekViewWrapper = styled.div`
  margin-bottom: 16px;
`;

export const WeekDay = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  > div {
    text-align: center;
  }
`;

export const WeekDayButton = styled(IconButton)`
  padding: 0;
  max-width: 20px;
  color: ${({ theme }) => theme.colors.neutral.dark["700"]};

  &:hover {
    color: ${({ theme }) => theme.colors.neutral.dark["900"]};
  }
`;

export const EventList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
  padding-left: 40px;
  margin: 12px 0;
  min-height: 40px;
`;

export const EventListCompact = styled(Stack)`
  margin-left: 6px;
  min-height: 25px;
  height: 100%;
`;

export const EventItem = styled.div`
  background-color: #fff5e6;
  border: 2px solid white;
  border-radius: 12px;
  padding: 8px;
  padding-top: 2px;
  overflow: hidden;
  min-height: 25px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(24px);
`;

export const EventItemTextWrapper = styled.div<{
  active?: string;
  isToday?: string;
}>`
  color: ${({ isToday, theme }) =>
    isToday === "true"
      ? theme.palette.primary.main
      : theme.colors.neutral.dark["700"]};
  background-color: ${({ active, theme }) =>
    active === "true" ? theme.palette.primary.light : "transparent"};
  padding: 8px 10px;
  border-radius: 50%;
  width: 39px;
`;

export const EventItemText = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
`;

export const EventItemTextDay = styled(EventItemText)<{
  active?: string;
  isToday?: string;
}>`
  font-size: 16px;
  font-weight: 600;
  color: ${({ isToday, theme }) =>
    isToday === "true" ? "inherit" : theme.colors.neutral.dark["900"]};
`;

export const EventTime = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.neutral.dark["700"]};
`;

export const EventTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
`;

export const AvatarGroup = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

export const AddButton = styled(Button)`
  min-width: 32px;
  width: 32px;
  height: 32px;
  padding: 0;
  margin-left: 8px;
  border-radius: 50%;
`;

export const StyledDayDivider = styled.div`
  width: 100%;
  border-top: 2px dashed ${({ theme }) => theme.palette.primary.medium};
`;

export const CounterBubble = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  width: 28px;
  height: 28px;
  font-weight: 600;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.primary.medium};
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const CreateEventButton = styled("button")`
  align-items: center;
  padding: 4px;
  gap: 4px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const CalendarContentWrapper = styled("div")`
  overflow-y: auto;
  max-height: 800px;
  scroll-behavior: smooth;
`;
