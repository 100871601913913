import { Link } from "react-router-dom";

import { IconButton } from "@mui/material";

import { DuplicateFiles, Pen, TrashNew } from "@assets/icons";
import { ConfirmationModal } from "@components/ConfirmationModal";
import { ExpandableTags } from "@components/ExpandableChips";
import { TagWithIcon } from "@components/TagWithIcon";
import { useAppTranslation, useModal } from "@hooks";
import {
  useDeleteDietMutation,
  usePostToggleFavoriteDietMutation,
} from "@hooks/queries";

import { FoodBowl } from "@assets/icons/DesignSystem";
import {
  AutogenereteMealScheduleButton,
  DefaultValuesIf,
} from "@components/AutogenerateMealScheduleModal";
import { ProgramLengthWeeks } from "@components/AutogenerateMealScheduleModal/MealDateRangePicker";
import { DietRowIf } from "@components/DietsDataGrid";
import { MacrosText } from "@components/MacrosText";
import dayjs from "dayjs";
import {
  ActionsWrapper,
  ButtonsWrapper,
  CardStyled,
  ContentWrapper,
  FavoriteToggleButtonStyled,
  ImgStyled,
  TitleStyled,
} from "./DietTile.styled";
import { ImagePlaceholder } from "./ImagePlaceholder";

interface DietTileProps extends DietRowIf {
  onSelect: () => void;
}

export const DietTile = ({
  namePl,
  actions,
  id,
  isFavorite,
  nameEn,
  nutrients,
  tags,
  imgUrl,
  onSelect,
  mealsCount,
}: DietTileProps) => {
  const { t, isPolishChosen } = useAppTranslation();
  const { mutate, isLoading: isLoadingToggle } =
    usePostToggleFavoriteDietMutation();
  const { mutate: deleteDiet, isLoading: isDeleting } = useDeleteDietMutation();
  const { modalOpened, onModalOpen, onModalClose } = useModal();
  const handleDelete = () => deleteDiet(id);

  const handleToggle = () => mutate(id?.toString() ?? "");

  const autogenerateDefaultValues: DefaultValuesIf = {
    dietId: id.toString(),
    dietQuery: isPolishChosen ? namePl : nameEn,
    startDate: dayjs().toDate(),
    endDate: dayjs().add(ProgramLengthWeeks.TWO, "days").toDate(),
  };

  return (
    <>
      <CardStyled onClick={onSelect}>
        <ButtonsWrapper className="buttonsWrapper">
          <FavoriteToggleButtonStyled
            onChange={handleToggle}
            isLoading={isLoadingToggle}
            isFavorite={isFavorite}
            favorite={isFavorite}
            className="favoriteButton"
          />

          <ActionsWrapper className="buttonsWrapper">
            <AutogenereteMealScheduleButton
              variant="icon"
              defaultValues={autogenerateDefaultValues}
            />
            {actions.canEdit && (
              <Link to={`/diets/${id}/edit`}>
                <IconButton>
                  <Pen />
                </IconButton>
              </Link>
            )}
            <Link to={`/diets/${id}/copy`}>
              <IconButton>
                <DuplicateFiles />
              </IconButton>
            </Link>
            {actions.canDelete && (
              <IconButton
                onClick={e => {
                  e.stopPropagation();
                  onModalOpen();
                }}
                disabled={isDeleting}
              >
                <TrashNew />
              </IconButton>
            )}
          </ActionsWrapper>
        </ButtonsWrapper>

        <div className="h-[260px] w-full">
          {imgUrl && (
            <ImgStyled
              loading="lazy"
              src={imgUrl}
              alt={isPolishChosen ? namePl : nameEn}
            />
          )}
          {!imgUrl && <ImagePlaceholder />}
        </div>

        <ContentWrapper>
          <ExpandableTags tags={tags.map(t => Number(t))} />

          <TitleStyled>{isPolishChosen ? namePl : nameEn}</TitleStyled>

          <div className="grid gap-[6px]">
            <MacrosText nutrients={nutrients} />

            <div className="flex gap-[16px]">
              <TagWithIcon
                Icon={FoodBowl}
                label={`${mealsCount} ${t("diets.meals", {
                  count: mealsCount,
                })}`}
              />
            </div>
          </div>
        </ContentWrapper>
      </CardStyled>
      <ConfirmationModal
        confirmText={t("common.delete")}
        description={t("diets.delete_modal.question", {
          variable: isPolishChosen ? namePl : nameEn,
        })}
        onCancel={onModalClose}
        open={modalOpened}
        onConfirm={handleDelete}
        title={t("diets.delete_modal.delete")}
      />
    </>
  );
};
