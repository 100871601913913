import { useContext, useMemo, useState } from "react";

import { Typography } from "@mui/material";

import { useAppParams, useAppTranslation } from "@hooks";
import { Row } from "@components/RecipeCompositionSection";
import { LangDto, MealFoodRecipeDto } from "@client";
import {
  useFetchMonitoringScheduleMealsQuery,
  useFetchMonitoringProgramDayMealsQuery,
} from "@hooks/queries";

import { RecipeItem } from "./RecipeItem";
import { MonitoringContext } from "../context/MonitoringContext";
import {
  HeaderItem,
  HeaderWrapper,
  MealsSectionStyled,
  MealsSectionWrapper,
  StyledIconButton,
} from "./MealsSection.styled";
import { DayMealsSum } from "./DayMealsSum";
import { getTranslatedName } from "@utils";
import KeyboardDoubleArrowDownRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowDownRounded";

export const MealsSection = () => {
  const { patientId } = useAppParams();
  const { t } = useAppTranslation();
  const { selectedDayDate, toggleButton, selectedDayDetails } =
    useContext(MonitoringContext);
  const [expandedMeals, setExpandedMeals] = useState<number[]>([]);

  const { scheduleMeals } = useFetchMonitoringScheduleMealsQuery(
    patientId,
    selectedDayDate,
    { enabled: !!patientId && toggleButton === "schedule" },
  );
  const { programDayMeals } = useFetchMonitoringProgramDayMealsQuery(
    patientId,
    selectedDayDate,
    { enabled: !!patientId && toggleButton === "program" },
  );

  const meals = useMemo(
    () => (toggleButton === "program" ? programDayMeals : scheduleMeals),
    [toggleButton, programDayMeals, scheduleMeals],
  );

  const hasMeals = !!meals?.foodDiaryDay?.meals.length;

  const allOpened = expandedMeals.length === meals?.foodDiaryDay?.meals.length;

  const expandAll = () =>
    allOpened
      ? setExpandedMeals([])
      : setExpandedMeals(meals?.foodDiaryDay?.meals.map(m => m.id) ?? []);
  const toggleExpand = (id: number) =>
    expandedMeals.includes(id)
      ? setExpandedMeals(meals => meals.filter(m => m !== id))
      : setExpandedMeals(meals => [...meals, id]);

  const mapIngredients = (
    foodRecipe: MealFoodRecipeDto[] | undefined,
  ): Row[] | undefined => {
    return foodRecipe?.map(ingredient => {
      const { food, nutrients, id, foodMeasure, grams } = ingredient;

      return {
        food: {
          description: getTranslatedName(food.translations, LangDto.EN) ?? null,
          descriptionPl: getTranslatedName(food.translations, LangDto.PL) ?? "",
          id: food.id,
          nutrients,
          measures: food.measures,
        },
        foodMeasureId: foodMeasure.id,
        id,
        grams,
      };
    });
  };

  return (
    <MealsSectionStyled>
      <MealsSectionWrapper>
        <HeaderWrapper>
          <HeaderItem>
            <Typography variant="h6">{t("monitoring.eaten_meals")}:</Typography>
          </HeaderItem>
          {hasMeals && (
            <StyledIconButton
              onClick={expandAll}
              color="primary"
              rotate={allOpened}
            >
              <KeyboardDoubleArrowDownRoundedIcon />
            </StyledIconButton>
          )}
        </HeaderWrapper>
        {meals?.foodDiaryDay?.meals.map((meal, idx) => {
          const isLast = meals.foodDiaryDay?.meals.length
            ? idx === meals.foodDiaryDay.meals.length - 1
            : false;
          const { recipe, id, liked } = meal;

          return (
            <RecipeItem
              key={recipe?.id}
              ingredients={mapIngredients(recipe?.foodRecipe)}
              expanded={expandedMeals.includes(id)}
              onToggleExpand={() => toggleExpand(id)}
              isLiked={liked}
              isLast={isLast}
              meal={meal}
            />
          );
        })}
        {!hasMeals && (
          <div className="w-full text-center">
            <Typography variant="body1" color="text.disabled">
              {t("monitoring.no_meals")}
            </Typography>
          </div>
        )}
        {hasMeals && selectedDayDetails && (
          <DayMealsSum nutrients={selectedDayDetails.foodDiary?.nutrients} />
        )}
      </MealsSectionWrapper>
    </MealsSectionStyled>
  );
};
