import { styled } from "@mui/material";

export const Error503Styled = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  height: 100%;
`;

export const ContentWrapper = styled("div")`
  display: grid;
  place-items: center;
  gap: 1rem;
`;

export const ImageWrapper = styled("div")`
  display: grid;
  place-items: center;
  gap: 2rem;
`;

export const TextWrapper = styled("div")`
  display: grid;
  place-items: center;
  gap: 0.5rem;
`;
