import { useQuery } from "@tanstack/react-query";

import {
  DietMealSearchPreviewResourceDto,
  searchDietsPreviewMeal,
} from "@client/diets";
import { ApiResponse } from "@typeDefinitions";

import { QueryOptionsTyped } from "../types";

export const searchDietsPreviewMealQueryKey = "searchDietsPreviewMealQueryKey";

export const useSearchDietsPreviewMealQuery = (
  dietId: string,
  mealId: string,
  options?: QueryOptionsTyped<ApiResponse<DietMealSearchPreviewResourceDto>>,
) => {
  const { data, ...rest } = useQuery(
    [searchDietsPreviewMealQueryKey, dietId, mealId],
    () => searchDietsPreviewMeal(dietId, mealId),
    options,
  );

  return {
    meal: data?.data,
    ...rest,
  };
};
