import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  DietMealListItemCreatorDto,
  SaveDietMealCreatorRequest,
  updateCreatorDietMeal,
} from "@client/diets/creator";
import { MutationOptions } from "@hooks/queries/types";
import { searchDietsQueryKeyNew } from "@hooks/queries/useSearchDietsQueryNew";
import { ApiResponse } from "@typeDefinitions";

import { updateDietMeal } from "./optimisticUpdateUtils";
import { useCreatorVersion } from "./useCreatorVersion";
import { fetchCreatorDietMealsQueryKey } from "./useFetchCreatorDietMealsQuery";
import { fetchCreatorDietQueryKey } from "./useFetchCreatorDietQuery";

export const useUpdateCreatorDietMealMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();
  const { version } = useCreatorVersion();

  return useMutation<
    unknown,
    unknown,
    UseUpdateCreatorDietMealMutationIf,
    MutationContextIf
  >(
    ({ dietId, mealId, payload }) =>
      updateCreatorDietMeal(dietId, mealId, payload, version),
    {
      ...options,
      onSuccess: (d, v) => {
        options?.onSuccess && options.onSuccess(d, v);
        queryClient.invalidateQueries([fetchCreatorDietQueryKey, v.dietId]);
        queryClient.invalidateQueries([searchDietsQueryKeyNew]);
      },
      onMutate: async ({ dietId, mealId, payload }) => {
        await queryClient.cancelQueries([
          fetchCreatorDietMealsQueryKey,
          dietId,
        ]);
        const previousData = queryClient.getQueryData<
          ApiResponse<DietMealListItemCreatorDto[]>
        >([fetchCreatorDietMealsQueryKey, dietId]);

        const hasMeal = previousData?.data.some(
          meal => meal.id.toString() === mealId,
        );

        if (!hasMeal) return { previousData, invalidate: true };

        queryClient.setQueryData<ApiResponse<DietMealListItemCreatorDto[]>>(
          [fetchCreatorDietMealsQueryKey, dietId],
          oldData => {
            if (!oldData) return previousData;

            return updateDietMeal(oldData, payload, mealId);
          },
        );
      },
      onSettled: (d, e, v, context) => {
        if (context?.invalidate) {
          queryClient.invalidateQueries([
            fetchCreatorDietMealsQueryKey,
            v.dietId,
          ]);
        }
      },
      onError: (_, { dietId }) => {
        queryClient.invalidateQueries([fetchCreatorDietMealsQueryKey, dietId]);
      },
    },
  );
};

interface UseUpdateCreatorDietMealMutationIf {
  dietId: string;
  mealId: string;
  payload: SaveDietMealCreatorRequest;
}

interface MutationContextIf {
  previousData?: ApiResponse<DietMealListItemCreatorDto[]>;
  invalidate?: boolean;
}
