import React, { useContext } from "react";

import dayjs from "dayjs";

import { useAppTranslation } from "@hooks";
import { ScheduleClipboardContext } from "@views/dietician/ProgramSchedule";

import { ClipboardInfoWrapperStyled } from "./ClipboardInfoWrapper.styled";
import { ClipboardInfo } from "../ClipboardInfo";

export const ClipboardInfoWrapper = () => {
  const { isPolishChosen } = useAppTranslation();
  const { day, meal } = useContext(ScheduleClipboardContext);

  return (
    <ClipboardInfoWrapperStyled>
      {meal && (
        <ClipboardInfo content={isPolishChosen ? meal.namePl : meal.nameEn} />
      )}
      {day && (
        <ClipboardInfo
          content={`${dayjs(day.date).format("dddd")}, ${dayjs(day.date).format(
            "L",
          )}`}
        />
      )}
    </ClipboardInfoWrapperStyled>
  );
};
