import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const FourSquares = (props: SvgIconProps) => {
  return (
    <SvgIcon width="12" height="12" viewBox="0 0 12 12" {...props}>
      <path
        d="M0.3125 1.65625C0.329427 1.31771 0.447917 1.02995 0.667969 0.792969C0.904948 0.572917 1.19271 0.454427 1.53125 0.4375H3.96875C4.30729 0.454427 4.59505 0.572917 4.83203 0.792969C5.05208 1.02995 5.17057 1.31771 5.1875 1.65625V4.09375C5.17057 4.43229 5.05208 4.72005 4.83203 4.95703C4.59505 5.17708 4.30729 5.29557 3.96875 5.3125H1.53125C1.19271 5.29557 0.904948 5.17708 0.667969 4.95703C0.447917 4.72005 0.329427 4.43229 0.3125 4.09375V1.65625ZM1.53125 4.09375H3.96875V1.65625H1.53125V4.09375ZM0.3125 8.15625C0.329427 7.81771 0.447917 7.52995 0.667969 7.29297C0.904948 7.07292 1.19271 6.95443 1.53125 6.9375H3.96875C4.30729 6.95443 4.59505 7.07292 4.83203 7.29297C5.05208 7.52995 5.17057 7.81771 5.1875 8.15625V10.5938C5.17057 10.9323 5.05208 11.2201 4.83203 11.457C4.59505 11.6771 4.30729 11.7956 3.96875 11.8125H1.53125C1.19271 11.7956 0.904948 11.6771 0.667969 11.457C0.447917 11.2201 0.329427 10.9323 0.3125 10.5938V8.15625ZM1.53125 10.5938H3.96875V8.15625H1.53125V10.5938ZM10.4688 0.4375C10.8073 0.454427 11.0951 0.572917 11.332 0.792969C11.5521 1.02995 11.6706 1.31771 11.6875 1.65625V4.09375C11.6706 4.43229 11.5521 4.72005 11.332 4.95703C11.0951 5.17708 10.8073 5.29557 10.4688 5.3125H8.03125C7.69271 5.29557 7.40495 5.17708 7.16797 4.95703C6.94792 4.72005 6.82943 4.43229 6.8125 4.09375V1.65625C6.82943 1.31771 6.94792 1.02995 7.16797 0.792969C7.40495 0.572917 7.69271 0.454427 8.03125 0.4375H10.4688ZM10.4688 1.65625H8.03125V4.09375H10.4688V1.65625ZM6.8125 8.15625C6.82943 7.81771 6.94792 7.52995 7.16797 7.29297C7.40495 7.07292 7.69271 6.95443 8.03125 6.9375H10.4688C10.8073 6.95443 11.0951 7.07292 11.332 7.29297C11.5521 7.52995 11.6706 7.81771 11.6875 8.15625V10.5938C11.6706 10.9323 11.5521 11.2201 11.332 11.457C11.0951 11.6771 10.8073 11.7956 10.4688 11.8125H8.03125C7.69271 11.7956 7.40495 11.6771 7.16797 11.457C6.94792 11.2201 6.82943 10.9323 6.8125 10.5938V8.15625ZM8.03125 10.5938H10.4688V8.15625H8.03125V10.5938Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
