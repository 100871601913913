import { Button, DialogContent, DialogTitle } from "@mui/material";
import { TooltipRenderProps } from "react-joyride";
import { useAppTranslation } from "@hooks";
import { useLocalStorage } from "react-use";
import { PROGRAM_SCHEDULE_TOUR } from "@views/dietician/ProgramSchedule";

import {
  StyledDialogActions,
  StyledDialogContentText,
  TooltipWrapper,
} from "./TourTooltip.styled";

export const TourTooltip = ({
  backProps,
  continuous,
  index,
  size,
  isLastStep,
  primaryProps,
  skipProps,
  step,
  tooltipProps,
}: TooltipRenderProps) => {
  const { t } = useAppTranslation();
  const [firstTime] = useLocalStorage<boolean>(PROGRAM_SCHEDULE_TOUR);
  const tourStep = index + 1;
  const notShowButton = firstTime || isLastStep;

  return (
    <TooltipWrapper {...tooltipProps}>
      {step.title && <DialogTitle>{step.title}</DialogTitle>}
      <DialogContent>
        <StyledDialogContentText>{step.content}</StyledDialogContentText>
      </DialogContent>
      <StyledDialogActions disableSpacing>
        {notShowButton ? (
          <div className="w-16" />
        ) : (
          <Button {...skipProps} variant="contained" color="inherit">
            {t("program.tours.skip")}
          </Button>
        )}
        <span>
          {tourStep}/{size}
        </span>
        <div>
          {index > 0 && (
            <Button {...backProps} variant="text">
              {t("program.tours.back")}
            </Button>
          )}
          {continuous && (
            <Button {...primaryProps} variant="contained">
              {isLastStep ? t("program.tours.finish") : t("program.tours.next")}
            </Button>
          )}
        </div>
      </StyledDialogActions>
    </TooltipWrapper>
  );
};
