import { useMemo } from "react";
import {
  useFetchCreatorDietMealsItemsQuery,
  useUpdateCreatorDietMealRecipesSortMutation,
  useUpdateCreatorDietMealProductsSortMutation,
} from "@hooks/queries/diets/creator";

export const useMealData = (mealId: string, dietId: string) => {
  const { data, isLoading } = useFetchCreatorDietMealsItemsQuery(
    dietId,
    mealId,
  );
  const { mutate: updateRecipes } =
    useUpdateCreatorDietMealRecipesSortMutation();
  const { mutate: updateProducts } =
    useUpdateCreatorDietMealProductsSortMutation();

  const recipes = useMemo(() => data?.recipes ?? [], [data?.recipes]);
  const products = useMemo(() => data?.products ?? [], [data?.products]);

  return { recipes, products, isLoading, updateRecipes, updateProducts };
};
