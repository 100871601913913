import { useMutation, useQueryClient } from "@tanstack/react-query";

import { postDietRenderMainPhoto } from "@client/diets";

import { MutationOptions } from "../types";
import { fetchDietQueryKey } from "../useFetchDietitianDietDetails";
import { fetchProgramDietQueryKey } from "../useFetchProgramDietDetails";
import { fetchCreatorDietQueryKey } from "./creator";

export const usePostDietRenderMainPhotoMutation = (
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, string>(
    dietId => postDietRenderMainPhoto(dietId),
    {
      ...options,
      onSuccess: (d, dietId) => {
        options?.onSuccess && options.onSuccess(d, dietId);
        queryClient.invalidateQueries([fetchDietQueryKey, Number(dietId)]);
        queryClient.invalidateQueries([fetchProgramDietQueryKey]);
        queryClient.invalidateQueries([fetchCreatorDietQueryKey, dietId]);
      },
    },
  );
};
