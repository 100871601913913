import {
  CollectionRecipeSearchPreviewDto,
  fetchCollectionSearchPreviewRecipe,
} from "@client/collections";
import { useQuery } from "@tanstack/react-query";
import { QueryOptionsTyped } from "../types";
import { ApiResponse } from "@typeDefinitions";

const fetchCollectionSearchPreviewRecipeQueryKey =
  "fetchCollectionSearchPreviewRecipeQueryKey";

export const useFetchCollectionSearchPreviewRecipeQuery = (
  collectionId: string,
  itemId: string,
  options?: QueryOptionsTyped<ApiResponse<CollectionRecipeSearchPreviewDto>>,
) => {
  const { data, ...rest } = useQuery({
    queryFn: () => fetchCollectionSearchPreviewRecipe(collectionId, itemId),
    queryKey: [
      fetchCollectionSearchPreviewRecipeQueryKey,
      collectionId,
      itemId,
    ],
    ...options,
  });

  return {
    data: data?.data,
    ...rest,
  };
};
