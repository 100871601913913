import { useMemo } from "react";
import { FormProvider } from "react-hook-form";

import { QuestionType, SystemQuestionDto } from "@client/surveys";
import { useAppTranslation } from "@hooks";
import { useUpdateSurveySubgroupQuestionMutation } from "@hooks/queries/surveys";

import { EmptyCalendar } from "@assets/icons";
import { IconButton } from "@mui/material";
import { Question } from "../Question/Question";
import { QuestionEditWatch } from "../QuestionEditWatch";
import { RequiredConfigFormInputs } from "../forms";
import {
  mapSystemQuestionConfigForm,
  mapSystemQuestionConfigRequest,
  useSystemQuestionConfigForm,
} from "../forms/useSystemQuestionConfigForm";
import { TextFieldStyled } from "./SystemQuestionUserBirthday.styled";
import { LangDto } from "@client";

interface SystemQuestionUserBirthdayProps {
  listIndex: number;
  totalQuestions: number;
  question: SystemQuestionDto;
  onTypeChange: (currentType: QuestionType, newType: QuestionType) => void;
  questionnaireLang?: LangDto;
}

export const SystemQuestionUserBirthday = ({
  listIndex,
  totalQuestions,
  question,
  onTypeChange,
  questionnaireLang,
}: SystemQuestionUserBirthdayProps) => {
  const { t } = useAppTranslation();
  const mappedForm = useMemo(
    () =>
      mapSystemQuestionConfigForm({
        ...question,
        title: t("questionnaires.questions.system_user_birthday.question", {
          lng: questionnaireLang,
        }),
      }),
    [question],
  );
  const form = useSystemQuestionConfigForm(mappedForm);
  const { mutate } = useUpdateSurveySubgroupQuestionMutation();

  const onSubmit = (data: RequiredConfigFormInputs) => {
    mutate({
      id: question.id.toString(),
      payload: mapSystemQuestionConfigRequest(data, question),
    });
  };

  return (
    <FormProvider {...form}>
      <Question
        id={question.id}
        listIndex={listIndex}
        totalQuestions={totalQuestions}
        onTypeChange={onTypeChange}
        questionType={question.type}
        systemQuestion
        infoText={t("questionnaires.questions.system_user_birthday.info")}
      >
        <TextFieldStyled
          size="small"
          disabled
          placeholder={t(
            "questionnaires.questions.system_user_birthday.placeholder",
          )}
          InputProps={{
            startAdornment: (
              <IconButton disabled>
                <EmptyCalendar />
              </IconButton>
            ),
          }}
        />
      </Question>

      <QuestionEditWatch isLoading={false} onSubmit={onSubmit} />
    </FormProvider>
  );
};
