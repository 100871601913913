import { postDietsProductsRemovedClearHistory } from "@client/diets";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "../types";
import { fetchDietProductsRemovedQueryKey } from "./useFetchDietProductsRemovedQuery";

export const usePostDietsProductsRemovedClearHistoryMutation = (
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, string>(
    dietId => postDietsProductsRemovedClearHistory(dietId),
    {
      ...options,
      onSuccess: (d, dietId) => {
        options?.onSuccess && options.onSuccess(d, dietId);
        queryClient.invalidateQueries([
          fetchDietProductsRemovedQueryKey,
          dietId,
        ]);
      },
    },
  );
};
