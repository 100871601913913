import { useEffect, useMemo, useState } from "react";
import { Document, Page } from "react-pdf";

import { range } from "lodash";

import { useAppTranslation } from "@hooks";
import { useFetchSectionQuery } from "@hooks/queries";
import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";

import usePdfSectionPreviewExport from "@Pdf/hooks/usePdfSectionPreviewExport";

import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { Button } from "@components/Button";
import usePdfSectionExport from "@Pdf/hooks/usePdfSectionExport";
import { PDF_COLORS, PDF_DISPLAY_CODE } from "@Pdf/defaults/pdfDefaults";
import { toast } from "react-toastify";

export const SectionDetails = ({ sectionId }: SectionDetailsProps) => {
  const { t } = useAppTranslation();
  const {
    section: details,
    isError: isSectionError,
    isLoading: isSectionLoading,
    isSuccess: isSectionSuccess,
  } = useFetchSectionQuery(sectionId);
  const [blob, setBlob] = useState<string>();
  const [error, setError] = useState(false);
  const [pagesNum, setPagesNum] = useState<number | null>(null);

  const isError = isSectionError || (isSectionSuccess && !details) || error;
  const isLoading = (isSectionLoading || !blob) && !error;
  const isSuccess = isSectionSuccess && blob && !!details && !error;

  const { onExport } = usePdfSectionPreviewExport();

  const getBlob = async () => {
    try {
      setError(false);
      const response = await onExport({
        id: 1,
        type: "content",
        name: details!.data.title,
        data: details!.data,
      });

      setBlob(response);
    } catch (e) {
      setError(true);
    }
  };

  useEffect(() => {
    if (!details) return;
    getBlob();
  }, [details]);

  const pages = useMemo(() => {
    if (pagesNum) return range(1, pagesNum + 1);

    return [];
  }, [pagesNum]);

  const [loading, setLoading] = useState(false);
  const handleDownloadButton = async () => {
    try {
      setLoading(true);
      await onExport(
        {
          id: 1,
          type: "content",
          name: details!.data.title,
          data: details!.data,
        },
        true,
      );

      setLoading(false);
    } catch {
      toast.error(t("pdf.upload_pdf_error"));

      setLoading(false);
    }
  };

  return (
    <div className="relative flex justify-center">
      {isLoading && <Spinner />}
      {isError && <ApiError message={t("sections.api_error")} />}
      {isSuccess && (
        <>
          <div className={"absolute right-10 flex justify-end"}>
            <Button isLoading={loading} onClick={handleDownloadButton}>
              {t("pdf.generate")}
            </Button>
          </div>
          <Document
            file={blob}
            onLoadSuccess={({ numPages }) => setPagesNum(numPages)}
          >
            {pages.map(page => (
              <Page pageNumber={page} key={page} className="mb-4 shadow" />
            ))}
          </Document>
        </>
      )}
    </div>
  );
};

interface SectionDetailsProps {
  sectionId: number;
  showHeader?: boolean;
}
