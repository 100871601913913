import { FC, ReactNode, SyntheticEvent } from "react";
import {
  ItemAccordion,
  ItemAccordionDetails,
  ItemAccordionSummary,
} from "@views/dietician/PatientMonitoring2/components/MealsBox/Client/MealItem.styled";
import { SxProps, Theme } from "@mui/material";

type NestedAccordionProps = {
  expanded: boolean;
  handleChangeExpanded: (event: SyntheticEvent, isExpanded: boolean) => void;
  children: ReactNode;
  sx?: SxProps<Theme>;
};

const NestedAccordion: FC<NestedAccordionProps> = ({
  children,
  expanded,
  handleChangeExpanded,
  sx,
}) => {
  return (
    <ItemAccordion
      disableGutters={true}
      expanded={expanded}
      onChange={handleChangeExpanded}
      sx={sx}
    >
      <ItemAccordionSummary />
      <ItemAccordionDetails>{children}</ItemAccordionDetails>
    </ItemAccordion>
  );
};

export default NestedAccordion;
