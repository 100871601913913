import { memo, ReactNode, useCallback, useContext } from "react";

import { requestDietNutrients } from "@client";
import { useAppTranslation } from "@hooks";
import { DietitianTag, Nutrient } from "@typeDefinitions";
import { DEFAULT_NORM_ID } from "@utils/nutrients";
import { ProgramContext } from "@context";
import { Link } from "react-router-dom";
import { ListElement, ListElementProps } from "./ListElement";
import { ExpandableTagsList } from "./ExpandableTagsList";
import { MicronutrientPreview } from "@components/MicronutrientsModal";
import { DietDetails } from "@views/dietician/Diets/components";

interface DietListElementProps
  extends Omit<ListElementProps, "title" | "body" | "macros" | "micros"> {
  isGeneralList?: boolean;
  diet: {
    namePl?: string;
    nameEn?: string;
    id: number;
    mealsCount: number;
    macros: Nutrient[];
    tags: DietitianTag[];
  };
  children?: ReactNode;
}

export const DietListElement = memo(
  ({ diet, children, isGeneralList, ...rest }: DietListElementProps) => {
    const { t, isPolishChosen } = useAppTranslation();
    const query = useCallback(() => requestDietNutrients(diet.id), [diet.id]);
    const { programId, dayId } = useContext(ProgramContext);

    return (
      <ListElement
        {...rest}
        title={
          isGeneralList ? (
            <Link to={`/diets/${diet.id}`}>
              {isPolishChosen ? diet.namePl : diet.nameEn}
            </Link>
          ) : isPolishChosen ? (
            diet.namePl
          ) : (
            diet.nameEn
          )
        }
        body={<ExpandableTagsList tags={diet.tags} />}
        prefix={
          <p>
            <span className="font-semibold mr-1">{diet.mealsCount}</span>
            {t("diets.meals", { count: diet.mealsCount })}
          </p>
        }
        macros={diet.macros}
        micros={
          <MicronutrientPreview
            normId={DEFAULT_NORM_ID}
            query={query}
            programDay={
              !!programId && !!dayId ? { programId, dayId } : undefined
            }
          />
        }
      >
        <DietDetails dietId={diet.id} />
      </ListElement>
    );
  },
);
