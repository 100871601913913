import { MacrosText } from "@components/MacrosText";
import {
  ImgBackground,
  ImgStyled,
  PlaceholderStyled,
  Title,
} from "./AccordionTitle.styled";
import { ReactNode } from "react";
import { Nutrient } from "@typeDefinitions";
import cupcake from "@assets/img/productTile/cupcake.png";

interface AccordionTitleProps {
  name: string;
  tags: ReactNode;
  nutrients: Nutrient[];
  img: string | null;
}
export const AccordionTitle = ({
  name,
  tags,
  nutrients,
  img,
}: AccordionTitleProps) => {
  return (
    <div className="flex gap-4">
      {img ? (
        <ImgStyled src={img} />
      ) : (
        <ImgBackground>
          <PlaceholderStyled src={cupcake} />
        </ImgBackground>
      )}

      <div className="grid gap-[8px]">
        <Title>{name}</Title>
        <div className="flex gap-[16px]">
          <MacrosText nutrients={nutrients} />
          {tags}
        </div>
      </div>
    </div>
  );
};
