import { useEffect, useMemo, useState } from "react";
import {
  VisitSegmentListType,
  VisitTagGroup,
  VisitTagGroupIds,
} from "@typeDefinitions";

import { useAppTranslation } from "@hooks";
import {
  useAddVisitTagMutation,
  useCreateCustomTagMutation,
  useRemoveVisitTagMutation,
} from "@hooks/queries/visits";

import {
  ListOption,
  TagSelectAutocomplete,
} from "./TagSelectAutocomplete/TagSelectAutocomplete";

interface VisitListContentProps {
  patientId: number;
  visitId: number;
  type: VisitSegmentListType;
  value: any;
  tags?: VisitTagGroup[];
}

export const ListContent = ({
  type,
  value,
  patientId,
  visitId,
  tags,
}: VisitListContentProps) => {
  const { isPolishChosen } = useAppTranslation();
  const { mutateAsync, isLoading } = useCreateCustomTagMutation();
  const { mutate: addTagToVisit } = useAddVisitTagMutation(patientId, visitId);
  const { mutate: removeTagFromVisit } = useRemoveVisitTagMutation(
    patientId,
    visitId,
  );

  const tagType = useMemo(() => {
    switch (type) {
      case "disease":
        return VisitTagGroupIds.DISEASE;
      case "meds":
        return VisitTagGroupIds.MEDS;
      case "supplements":
        return VisitTagGroupIds.SUPPLEMENTS;
      case "digestive":
        return VisitTagGroupIds.DIGESTIVE;
      default:
        return VisitTagGroupIds.ALLERGIES;
    }
  }, []);

  const options = useMemo(() => {
    return (
      tags
        ?.find(tagGroup => tagGroup.id === tagType)
        ?.tags.map(tag => {
          const namePlFallback =
            tag.namePl ||
            tag.descriptionPl ||
            tag.nameEn ||
            tag.descriptionEn ||
            "";
          const nameEnFallback =
            tag.nameEn ||
            tag.descriptionEn ||
            tag.namePl ||
            tag.descriptionPl ||
            "";
          return {
            id: tag.id.toString(),
            label: isPolishChosen ? namePlFallback : nameEnFallback,
          };
        }) || []
    );
  }, [tags, isPolishChosen]);

  const parseDefaultValue = () => {
    return value.map(
      (id: string) => options.find(o => o.id === id.toString())!,
    );
  };

  const [selected, setSelected] = useState<ListOption[]>(parseDefaultValue());

  const handleChange = (value: ListOption[]) => {
    if (!value.length) return;
    setSelected(value);
    addTagToVisit({ tagId: parseInt(value.slice(-1)[0]?.id) });
  };

  const handleRemove = (tagId: string) => {
    if (!tagId) return;
    setSelected(selected.filter(o => o.id !== tagId));
    removeTagFromVisit({ tagId: parseInt(tagId) });
  };

  const handleNewTag = async (newTag: string) => {
    const a = await mutateAsync({
      payload: {
        tagCategory: { id: tagType },
        descriptionPl: newTag,
        descriptionEn: newTag,
      },
    });
    return a.data.id;
  };

  useEffect(() => {
    if (options.length) setSelected(parseDefaultValue());
  }, [isPolishChosen]);

  return (
    <TagSelectAutocomplete
      value={selected}
      options={options}
      onChange={handleChange}
      onRemove={handleRemove}
      onTagCreate={handleNewTag}
      isLoading={isLoading}
    />
  );
};
