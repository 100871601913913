import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Scales = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="14" height="14" viewBox="0 0 14 14">
      <path
        d="M3.5 4.8125C3.5 3.88424 3.86875 2.994 4.52513 2.33763C5.1815 1.68125 6.07174 1.3125 7 1.3125C7.92826 1.3125 8.8185 1.68125 9.47487 2.33763C10.1313 2.994 10.5 3.88424 10.5 4.8125C10.5 5.74076 10.1313 6.631 9.47487 7.28737C8.8185 7.94375 7.92826 8.3125 7 8.3125C6.07174 8.3125 5.1815 7.94375 4.52513 7.28737C3.86875 6.631 3.5 5.74076 3.5 4.8125ZM10.7133 1.75C9.83008 0.680859 8.4957 0 7 0C5.5043 0 4.16992 0.680859 3.28672 1.75H1.75C0.784766 1.75 0 2.53477 0 3.5V12.25C0 13.2152 0.784766 14 1.75 14H12.25C13.2152 14 14 13.2152 14 12.25V3.5C14 2.53477 13.2152 1.75 12.25 1.75H10.7133ZM8.09375 6.125C8.09375 5.83516 7.98164 5.57266 7.7957 5.37578L8.71445 3.23477C8.81016 3.01328 8.70625 2.75625 8.48477 2.66055C8.26328 2.56484 8.00625 2.66875 7.91055 2.89023L6.9918 5.03125C6.39023 5.03398 5.90625 5.52344 5.90625 6.125C5.90625 6.7293 6.3957 7.21875 7 7.21875C7.6043 7.21875 8.09375 6.7293 8.09375 6.125Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
