import { Controller } from "react-hook-form";
import { Autocomplete, TextField, Chip, Box } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useMemo } from "react";
import { useTaskFilterOptions } from "@hooks/tasks/useTaskFilterOptions";
import { useAppTranslation } from "@hooks";
import { CustomTagChip } from "@components/CustomTagChip";
import { DeleteIcon } from "@assets/icons/Chip";

interface TagOption {
  id: string;
  label: string;
}
export const TaskTagsComboBox = () => {
  const { control } = useFormContext();
  const { t } = useAppTranslation();

  const { tagsOptions, allTags } = useTaskFilterOptions();

  const optionsMap = useMemo(() => {
    return tagsOptions.reduce<Record<string, TagOption>>((acc, option) => {
      acc[option.id] = option;
      return acc;
    }, {});
  }, [tagsOptions]);

  return (
    <Controller
      name="customTagsIds"
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const selectedOptions = (value || [])
          .map((id: string) => optionsMap[id])
          .filter(Boolean);

        return (
          <Autocomplete
            multiple
            options={tagsOptions}
            getOptionLabel={option => option.label}
            value={selectedOptions}
            size="small"
            onChange={(_, newValue) => {
              const newIds = newValue.map(option => option.id);
              onChange(newIds);
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const tagColor = allTags?.find(
                  ({ id }) => id === Number(option.id),
                )?.color;
                return (
                  <Box key={option.id} margin={0.5}>
                    <CustomTagChip
                      id={option.id}
                      deleteIcon={<DeleteIcon fill="#FFFFFF" size="10" />}
                      color={tagColor ?? ""}
                      label={option.label}
                      {...getTagProps({ index })}
                    />
                  </Box>
                );
              })
            }
            renderInput={params => (
              <TextField
                {...params}
                error={!!error}
                helperText={error ? error.message : null}
                placeholder={t("tasks.addTag")}
              />
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        );
      }}
    />
  );
};
