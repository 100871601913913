import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";
import * as yup from "yup";
import { logFetchValidateError } from "@utils/validate";

interface Translation {
  lang: string;
  name: string;
}

export interface IrrigationProduct {
  id: number;
  name: string;
  parentId: number | null;
  color: string | null;
  translations: Translation[];
}

const productSchema = yup.object({
  id: yup.number().required().integer(),
  name: yup.string().required(),
  parentId: yup.number().nullable().integer().defined(),
  color: yup.string().nullable().defined(),
  translations: yup
    .array()
    .of(
      yup.object({
        lang: yup.string().required(),
        name: yup.string().required(),
      }),
    )
    .required(),
});

const productsSchema = yup.array().of(productSchema).defined();

export async function irrigationProducts(): Promise<IrrigationProduct[]> {
  const { data } = await fetchData(
    `/dietitian/dictionaries/hydration-products`,
    APIMethods.GET,
  );

  return productsSchema
    .validate(data)
    .catch(logFetchValidateError("/dietitian/dictionaries/hydration-products"));
}
