import { useCallback } from "react";

import { ViewError } from "@components/ViewError";
import { ProgramView } from "@views/dietician/Program/components/ProgramView";
import {
  useFetchPatientProgramQuery,
  useUpdateProgramSectionsMutation,
} from "@hooks/queries";

import { usePatientProgramSummaryParams } from "./";
import { StyledSpinner } from "./PatientProgram.styled";

export const PatientProgram = () => {
  const { patientId, programId } = usePatientProgramSummaryParams();
  const { program, isLoading, isError } = useFetchPatientProgramQuery(
    patientId.toString(),
    programId.toString(),
  );
  const { mutate: updateSections } = useUpdateProgramSectionsMutation();

  const handleRemoveSection = useCallback(
    (sectionId: number) => {
      if (!program) return;
      return updateSections({
        programId,
        payload: {
          sections:
            program.sections.filter(section => section !== sectionId) ?? [],
        },
      });
    },
    [program],
  );

  if (isLoading || !program) return <StyledSpinner />;

  if (isError) return <ViewError />;

  return (
    <ProgramView
      patientId={patientId}
      programId={programId}
      dietitianProgram={program}
      handleRemoveSection={handleRemoveSection}
    />
  );
};
