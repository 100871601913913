import { useMemo, useState } from "react";
import { TabContext } from "@mui/lab";
import { TabList } from "@components/Alloweat/Tabs/TabList";
import { Tab } from "@mui/material";
import { TabPanel } from "@components/Alloweat/Tabs/TabPanel";
import DietMealItems from "@components/ProgramSearchPreview/Components/DietMealItems/DietMealItems";
import { DietProgramSearchPreview } from "@client/program/preview/fetchDietProgramPreview";
import { useProgramSearchPreviewDrawer } from "@components/ProgramSearchPreview/Context/ProgramSearchPreviewDrawerContext";
import { useAppTranslation } from "@hooks";

type MealsTabsProps = {
  diet: DietProgramSearchPreview;
};

const DietMealsTabs = ({ diet }: MealsTabsProps) => {
  const { currentLanguage } = useAppTranslation();
  const { programId } = useProgramSearchPreviewDrawer();

  const [value, setValue] = useState(() => diet.meals[0].id.toString());
  const handleChange = (_: any, newValue: string) => {
    setValue(newValue);
  };

  const selected = useMemo(() => {
    const dietMealsId = diet.meals.map(m => m.id);
    if (dietMealsId.includes(parseInt(value))) {
      return value;
    }

    return dietMealsId[0].toString();
  }, [diet, value]);

  if (!programId) {
    return null;
  }

  return (
    <TabContext value={selected}>
      <TabList
        onChange={handleChange}
        scrollButtons={"auto"}
        variant="scrollable"
      >
        {diet.meals.map(meal => {
          const name =
            meal.translations.find(t => t.lang === currentLanguage)?.name ??
            meal.name;
          return (
            <Tab
              key={meal.id}
              label={`${name} (${meal.itemsCount})`}
              value={meal.id.toString()}
            />
          );
        })}
      </TabList>
      {diet.meals.map(meal => {
        return (
          <TabPanel key={meal.id} value={meal.id.toString()}>
            <DietMealItems programId={programId} dietMealId={meal.id} />
          </TabPanel>
        );
      })}
    </TabContext>
  );
};

export default DietMealsTabs;
