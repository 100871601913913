import { LinearProgress, Theme, styled } from "@mui/material";

export const ValueText = styled("span")`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;

type Colors = "success" | "warning" | "error" | "info";
interface TextProps {
  color?: Colors;
}
export const Text = styled("span")<TextProps>`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ color, theme }) =>
    color === "error"
      ? theme.palette.error.main
      : theme.colors.neutral.dark[800]};
`;

interface LetterIconProps {
  color: Colors;
}

const getColor = (theme: Theme, color: Colors) => {
  if (color === "success") return theme.palette.success.main;
  if (color === "warning") return theme.colors.survey.yellow.dark;
  if (color === "error") return theme.palette.error.main;
  return theme.palette.primary.main;
};
export const LetterIcon = styled("div")<LetterIconProps>`
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  color: ${({ theme, color }) =>
    color === "error"
      ? theme.colors.neutral.light[100]
      : theme.colors.neutral.dark[800]};
  border-radius: 100%;
  border: 2px solid ${({ theme }) => theme.palette.primary.light};
  background: ${({ theme, color }) => getColor(theme, color)};
  width: 24px;
  height: 24px;
  display: grid;
  place-items: center;
`;

export const LinearProgressStyled = styled(LinearProgress)`
  &.MuiLinearProgress-root {
    height: 8px;
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.neutral.light[100]};
  }
  & .MuiLinearProgress-bar {
    border-radius: 8px;

    &.MuiLinearProgress-barColorWarning {
      background: ${({ theme }) => theme.colors.survey.yellow.dark};
    }
    &.MuiLinearProgress-barColorInfo {
      background: ${({ theme }) => theme.colors.neutral.medium[400]};
    }
  }
`;

export const NutrientLabel = styled("span")`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;
