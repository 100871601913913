import { styled, Switch } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

interface VatToggleProps {
  value: boolean;
  setValue: Dispatch<SetStateAction<boolean>>;
}

export const VatToggle = ({ value, setValue }: VatToggleProps) => {
  return (
    <div className="flex gap-[8px] items-center">
      <Switch checked={value} onChange={(e, checked) => setValue(checked)} />
      <Label onClick={() => setValue(!value)}>
        Chcesz widzieć ceny z VAT-em? Kliknij i przełącz na brutto!
      </Label>
    </div>
  );
};

const Label = styled("span")`
  font-family: Figtree;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;
