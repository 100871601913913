import { useQuery } from "@tanstack/react-query";
import { buildDefaultQueryOptions } from "../common";
import { fetchVisitFiles } from "@client/visits";

export const fetchVisitFilesQueryKey = "fetchVisitFiles";

export const useFetchVisitFilesQuery = (
  patientId: string,
  options?: { enabled?: boolean },
) => {
  const { data, ...rest } = useQuery(
    [fetchVisitFilesQueryKey, patientId],
    () => fetchVisitFiles(patientId),
    buildDefaultQueryOptions(options),
  );

  return { files: data, ...rest };
};
