import { HTMLAttributes, ReactNode, Ref, useMemo } from "react";
import { Control, Path, useController } from "react-hook-form";

import { useAppTranslation } from "@hooks";
import { Spinner } from "@components/Spinner";
import { omit } from "lodash";
import {
  Autocomplete,
  TextField,
  AutocompleteRenderOptionState,
  InputAdornment,
  useTheme,
  OutlinedInputProps,
  InputProps,
  FilledInputProps,
  FilterOptionsState,
} from "@mui/material";
import { SearchNoResults } from "@views/emptyStates/SearchNoResults";
import { Check } from "@assets/icons";

export interface SelectOption {
  id: string;
  label: string;
}

export interface FormAutocompleteProps {
  id: string;
  name: Path<any>;
  control: Control<any>;
  options: SelectOption[];
  multiple?: boolean;
  limitTags?: number;
  size?: "small" | "medium";
  popupIcon?: ReactNode;
  className?: string;
  label?: string | null;
  placeholder?: string | undefined;
  startIcon?: ReactNode;
  fullWidth?: boolean;
  disabled?: boolean;
  renderOption?: (
    props: HTMLAttributes<HTMLLIElement>,
    option: any,
    state: AutocompleteRenderOptionState,
  ) => ReactNode;
  disableClearable?: boolean;
  onSearch?: (data: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  isLoading?: boolean;
  variant?: "outlined" | "standard" | "filled";
  filterOptions?: (
    options: SelectOption[],
    state: FilterOptionsState<SelectOption>,
  ) => SelectOption[];
  onSelect?: (id: string[] | string | null) => void;
  disableCloseOnSelect?: boolean;
  openOnFocus?: boolean;
  blurOnSelect?: boolean;
  ref?: Ref<unknown>;
  defaultValue?: SelectOption | SelectOption[] | null | undefined;
  getOptionLabel?: (option: SelectOption) => string;
  disablePortal?: boolean;
  rebranding?: boolean;
  readOnly?: boolean;
  hideHelperText?: boolean;
  InputProps?:
    | Partial<OutlinedInputProps>
    | Partial<InputProps>
    | Partial<FilledInputProps>
    | undefined;
}

export const FormAutocomplete = ({
  id,
  name,
  control,
  options,
  multiple,
  limitTags,
  size,
  popupIcon,
  className,
  label,
  placeholder,
  startIcon,
  fullWidth,
  disabled,
  renderOption,
  disableClearable,
  onSearch,
  onBlur,
  onFocus,
  isLoading,
  variant,
  filterOptions,
  onSelect,
  disableCloseOnSelect,
  openOnFocus,
  blurOnSelect,
  ref,
  defaultValue,
  getOptionLabel,
  disablePortal,
  rebranding,
  readOnly,
  hideHelperText = false,
  InputProps,
}: FormAutocompleteProps) => {
  const { t } = useAppTranslation();
  const { colors } = useTheme();

  const optionsMap = useMemo(
    () => Object.fromEntries(options.map(o => [o.id, o])),
    [options],
  );

  const muiToForm = (
    mui: SelectOption[] | SelectOption | null,
  ): string[] | string | null => {
    if (mui === null || mui === undefined) {
      return null;
    } else if (multiple && Array.isArray(mui)) {
      return mui.map(option => option.id);
    } else {
      return (mui as unknown as SelectOption).id;
    }
  };

  const formToMui = (
    form: string[] | string | null,
  ): SelectOption[] | SelectOption | null => {
    if (form === null || form === undefined) {
      return null;
    } else if (multiple && Array.isArray(form)) {
      return form.map((id: string) => optionsMap[id]);
    } else {
      return optionsMap[form as unknown as string];
    }
  };

  const {
    field: { value, onChange, onBlur: controllerOnBlur },
    fieldState: { error },
  } = useController({ name, control });

  return (
    <Autocomplete
      noOptionsText={<SearchNoResults />}
      id={id}
      ref={ref}
      fullWidth={fullWidth}
      limitTags={limitTags}
      multiple={multiple}
      options={options}
      disableCloseOnSelect={disableCloseOnSelect}
      disablePortal={disablePortal}
      openOnFocus={openOnFocus}
      blurOnSelect={blurOnSelect}
      defaultValue={defaultValue}
      disabled={disabled}
      key={JSON.stringify(formToMui(value))}
      value={formToMui(value)}
      onChange={(e, value) => {
        onChange(muiToForm(value));
        onSelect && onSelect(muiToForm(value));
      }}
      onBlur={onBlur}
      onFocus={onFocus}
      renderInput={params => (
        <TextField
          {...omit(params, "InputLabelProps")}
          InputProps={{
            ...params.InputProps,
            ...InputProps,
            startAdornment: startIcon && (
              <InputAdornment position="start">{startIcon}</InputAdornment>
            ),
          }}
          error={!!error}
          label={label}
          placeholder={placeholder}
          helperText={
            error?.message && !hideHelperText ? t(error.message) : undefined
          }
          onChange={e => onSearch && onSearch(e.target.value)}
          onFocus={() =>
            onSearch &&
            params.inputProps.value &&
            onSearch(params.inputProps.value.toString())
          }
          onBlur={onBlur ?? controllerOnBlur}
          variant={variant}
        />
      )}
      size={size}
      popupIcon={
        <div>{isLoading ? <Spinner size="w-5 h-5" /> : popupIcon}</div>
      }
      className={className}
      renderOption={
        renderOption
          ? renderOption
          : (props, option, { selected }) => (
              <li {...props} key={option.id}>
                {option.label}
                {selected && rebranding && (
                  <Check className="ml-auto" fill={colors.neutral.dark[700]} />
                )}
              </li>
            )
      }
      disableClearable={disableClearable}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      readOnly={readOnly}
    />
  );
};
