import type { CreateTaskFormSchema } from "@hooks/tasks/useCreateTaskForm";
import type { CreateTaskRequestPayload } from "@client/tasks/createTask";
import { TaskPriority } from "@views/dietician/Tasks/components/TaskPrioritySelect";
import dayjs from "dayjs";

const toNumberOrNull = (value?: string | number | null): number | null => {
  if (value === null || value === undefined || value === "") return null;
  const num = typeof value === "number" ? value : Number(value);
  return isNaN(num) ? null : num;
};

export const mapCreateTaskFormToPayload = (
  formData: CreateTaskFormSchema,
): CreateTaskRequestPayload => {
  return {
    name: formData.name ?? "",
    description: formData.description ?? null,
    relatedLink: formData.relatedLink ?? null,
    dueDate: formData.dueDate
      ? dayjs(formData.dueDate).format("YYYY-MM-DD")
      : null,
    priority: formData.priority as TaskPriority,
    dietitianId: toNumberOrNull(formData.dietitianId),
    clientId: toNumberOrNull(formData.clientId),
    customTagsIds: (formData.customTagsIds ?? []).map(
      id => toNumberOrNull(id) ?? 0,
    ),
  };
};
