import { APIMethods, ApiResponse, NewVisit } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export async function removeFileFromVisit(
  scheduleId: number,
  fileId: number,
): Promise<ApiResponse<void>> {
  return fetchData(
    `/dietitian/schedules/${scheduleId}/visit/files/${fileId}`,
    APIMethods.DELETE,
  );
}
