import { styled } from "@mui/material";

export const Title = styled("span")`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.4px;
  text-align: left;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
  text-transform: uppercase;
`;
