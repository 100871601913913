import { ReactNode, useMemo, useState } from "react";
import { Control, Path, useController } from "react-hook-form";

import { Chip, Collapse, TextField } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import _, { compact, values } from "lodash";

import { ArrowDown } from "@assets/icons";
import { useAppTranslation } from "@hooks";
import {
  SPECIFIC_TAG_CATEGORIES_TYPE,
  TagCategoryBase,
  filterTagsTypeDictionary,
} from "@utils/tagsNew";
import { TagCategory } from "@views/dietician/product-views/components/EditProductDetails/TagCategory";

import {
  SelectedTagsWrapper,
  TagsLabel,
  StyledAutocomplete,
  ShowTagsButton,
  CollapseWrapper,
} from "./FormExpandableTagSelector.styled";
import { SelectOption } from "@components/FormAutocomplete";
import { SearchNoResults } from "@views/emptyStates/SearchNoResults";
import { useTagsNew } from "@hooks/useTagsNew";

interface FormExpandableTagSelectorProps {
  control?: Control<any>;
  name: Path<any>;
  tagCategory: TagCategoryBase;
  children?: ReactNode;
}
export const FormExpandableTagSelector = ({
  control,
  name,
  tagCategory,
  children,
}: FormExpandableTagSelectorProps) => {
  const [showTags, setShowTags] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const { t } = useAppTranslation();
  const { tagCategoryDictionary, tagNameDictionary } = useTagsNew();
  const {
    field: { value: valueAny, onChange },
  } = useController({ control, name });
  const selectedTags = valueAny as string[];
  const options: SelectOption[] = _(SPECIFIC_TAG_CATEGORIES_TYPE[tagCategory])
    .flatMap(id => tagCategoryDictionary.get(id)?.tags ?? [])
    .map(tag => ({ id: tag.id.toString(), label: tag.name }))
    .uniqBy(tag => tag.id)
    .value();
  const selectedTagsOptions = compact(
    selectedTags.map(tag =>
      options.find(option => option.id === tag.toString()),
    ),
  );

  const filteredCategories = useMemo(
    () => filterTagsTypeDictionary(tagCategoryDictionary, tagCategory),
    [filterTagsTypeDictionary, tagCategoryDictionary, tagCategory],
  );

  const handleOnChange = (value: string[]) => {
    onChange(value);
    setInputValue("");
  };

  const handleRemoveTag = (id: string) =>
    handleOnChange(selectedTags.filter(tag => tag !== id));
  const handleClearTags = () => handleOnChange([]);
  const handleToggleTag = (id: number) => {
    const isSelected = selectedTags.some(tag => `${tag}` === id.toString());
    const tag = tagNameDictionary.get(Number(id));

    if (!isSelected && tag) {
      handleOnChange([...selectedTags, id.toString()]);
    }
    if (isSelected && tag) {
      handleOnChange(selectedTags.filter(tag => `${tag}` !== id.toString()));
    }
  };

  return (
    <div className="grid gap-3">
      <div className="flex justify-between">
        <StyledAutocomplete
          noOptionsText={<SearchNoResults />}
          className="tagsInput"
          value={selectedTagsOptions}
          options={options}
          multiple
          disableClearable
          inputValue={inputValue}
          renderTags={(value: SelectOption[]): ReactNode => (
            <SelectedTagsWrapper>
              {value.map(tag => (
                <Chip
                  key={tag.id}
                  label={tag.label}
                  onDelete={() => handleRemoveTag(tag.id)}
                  deleteIcon={<Cancel color="primary" />}
                  variant="newFilled"
                  color="primary"
                />
              ))}
              {value.length && (
                <Chip
                  label={t("product.edit.clear_all")}
                  onDelete={handleClearTags}
                  deleteIcon={<Cancel color="primary" />}
                  variant="newOutlined"
                />
              )}
            </SelectedTagsWrapper>
          )}
          onChange={(_, value: SelectOption[]) =>
            handleOnChange(value.map(tag => tag.id))
          }
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              label={t("product.edit.tags")}
              size="small"
              InputLabelProps={{ shrink: true }}
              placeholder={t("product.edit.type_or_select")}
              onChange={v => setInputValue(v.target.value)}
            />
          )}
        />
        {children}
      </div>
      <div>
        <Collapse in={showTags}>
          <CollapseWrapper>
            {values(filteredCategories).map(category => (
              <TagCategory
                key={category.id}
                category={category}
                selectedTags={selectedTagsOptions}
                toggleTag={handleToggleTag}
              />
            ))}
          </CollapseWrapper>
        </Collapse>
        <ShowTagsButton size="small" onClick={() => setShowTags(val => !val)}>
          {!showTags && (
            <>
              <TagsLabel variant="subtitle2">
                {t("product.edit.tags_show")}
              </TagsLabel>
              <ArrowDown size="h-5 w-5" />
            </>
          )}
          {showTags && (
            <>
              <TagsLabel variant="subtitle2">
                {t("product.edit.tags_hide")}
              </TagsLabel>
              <ArrowDown size="h-5 w-5" className="rotate-180" />
            </>
          )}
        </ShowTagsButton>
      </div>
    </div>
  );
};
