import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

import {
  ActionsDto,
  actionsSchema,
  NutrientDto,
  nutrientSchema,
} from "./common";
import { TagResourceDto, tagResourceSchema } from "./searchRecipesNew";

export const searchMealPatternsNew = async (
  params?: URLSearchParams,
): Promise<ApiResponse<MealPatternNewDto[]>> => {
  const data = await fetchData(
    "/dietitian/meal-patterns/search-new",
    APIMethods.GET,
    undefined,
    params,
  );
  return searchMealPatternsSchema.validate(data);
};

const mediaSchema = yup.object().shape({
  url: yup.string().required(),
});

const mealPatternSchema = yup.object().shape({
  id: yup.number().required(),
  clinicId: yup.number().nullable().default(null),
  name: yup.string().required(),
  nameEn: yup.string().nullable().default(null),
  hide: yup.boolean().required(),
  numberOfItems: yup.number().required(),
  tags: yup.array().of(tagResourceSchema).required(),
  macros: yup.array().of(nutrientSchema).required(),
  favorite: yup.array().of(yup.number().required()).required(),
  actions: actionsSchema.required(),
  media: mediaSchema.nullable().default(null),
});

const searchMealPatternsSchema = yup.object().shape({
  data: yup.array().of(mealPatternSchema).required(),
});

export interface MealPatternNewDto {
  id: number;
  clinicId: number | null;
  name: string;
  nameEn: string | null;
  hide: boolean;
  numberOfItems: number;
  tags: TagResourceDto[];
  macros: NutrientDto[];
  favorite: number[];
  actions: ActionsDto;
  media: MediaDto | null;
}

interface MediaDto {
  url: string;
}
