import { SvgIcon, SvgIconProps } from "@mui/material";

export const Calendar = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M17.9982 5.14493H6.0018C5.05531 5.14493 4.28802 5.91221 4.28802 6.8587V18.8551C4.28802 19.8016 5.05531 20.5689 6.0018 20.5689H17.9982C18.9447 20.5689 19.712 19.8016 19.712 18.8551V6.8587C19.712 5.91221 18.9447 5.14493 17.9982 5.14493Z"
      stroke={props.fill ?? "currentColor"}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M15.4277 3.43109V6.85864"
      stroke={props.fill ?? "currentColor"}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M8.57239 3.43109V6.85864"
      stroke={props.fill ?? "currentColor"}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M4.28802 10.2862H19.712"
      stroke={props.fill ?? "currentColor"}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
