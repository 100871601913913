import React from "react";
import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Meals = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 28 28">
    <g id="bg-menu-icon">
      <rect
        id="Rectangle_789"
        data-name="Rectangle 789"
        width="28"
        height="28"
        rx="6"
        fill="#f8f5fd"
        opacity="0"
      />
    </g>
    <g id="ic-menu-dzienniczek">
      <rect
        id="Rectangle_791"
        data-name="Rectangle 791"
        width="26"
        height="26"
        fill="#fff"
        opacity="0"
      />
      <g id="dzienniczek" transform="translate(-3397 -1514.439)">
        <g id="apple" transform="translate(3410.602 1521.671)">
          <path
            id="Path_562"
            data-name="Path 562"
            d="M3539.415,1593.021a4.911,4.911,0,0,1,2.182-.35,4.223,4.223,0,0,1,3.509,1.9,6.127,6.127,0,0,1-.316,6.551,11.192,11.192,0,0,1-1.7,2.2,4.477,4.477,0,0,1-1.561,1.26c-.773.239-1.517-.326-2.3-.326h-.059c-.778,0-1.524.565-2.3.326a4.469,4.469,0,0,1-1.56-1.26,11.231,11.231,0,0,1-1.7-2.2,6.131,6.131,0,0,1-.316-6.551,4.224,4.224,0,0,1,3.51-1.9"
            transform="translate(-3532.501 -1588.436)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.3"
          />
          <path
            id="Path_563"
            data-name="Path 563"
            d="M3587.848,1614.893a3.478,3.478,0,0,0,3.027,0"
            transform="translate(-3582.662 -1608.58)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Path_564"
            data-name="Path 564"
            d="M3591.591,1549.5c1.383-2.98,4.836-1.724,4.836-1.724a13.024,13.024,0,0,1-1.728,2.161,2.91,2.91,0,0,1-2.392.568"
            transform="translate(-3586.055 -1547.51)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_565"
            data-name="Path 565"
            d="M3585.4,1563a6.674,6.674,0,0,0-2.16-3.457"
            transform="translate(-3578.488 -1558.417)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
        <g
          id="Group_4097"
          data-name="Group 4097"
          transform="translate(3398 1516.439)"
        >
          <g id="Group_4095" data-name="Group 4095" transform="translate(0 0)">
            <path
              id="Path_566"
              data-name="Path 566"
              d="M3414.49,1522.3V1519H3398.75a.806.806,0,0,0-.75.853v19.5a.806.806,0,0,0,.75.852h11.243"
              transform="translate(-3398 -1519)"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
          </g>
          <g
            id="Group_4096"
            data-name="Group 4096"
            transform="translate(2.998 4.582)"
          >
            <line
              id="Line_276"
              data-name="Line 276"
              x2="7.496"
              transform="translate(2.998 0.426)"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
            <line
              id="Line_277"
              data-name="Line 277"
              x2="5.622"
              transform="translate(2.998 3.836)"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
            <line
              id="Line_278"
              data-name="Line 278"
              x2="3.748"
              transform="translate(2.998 7.246)"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
            <line
              id="Line_279"
              data-name="Line 279"
              x2="3.748"
              transform="translate(2.998 10.656)"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
            <ellipse
              id="Ellipse_74"
              data-name="Ellipse 74"
              cx="0.375"
              cy="0.426"
              rx="0.375"
              ry="0.426"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeWidth="1.5"
            />
            <ellipse
              id="Ellipse_75"
              data-name="Ellipse 75"
              cx="0.375"
              cy="0.426"
              rx="0.375"
              ry="0.426"
              transform="translate(0 3.41)"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeWidth="1.5"
            />
            <ellipse
              id="Ellipse_76"
              data-name="Ellipse 76"
              cx="0.375"
              cy="0.426"
              rx="0.375"
              ry="0.426"
              transform="translate(0 6.82)"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeWidth="1.5"
            />
            <ellipse
              id="Ellipse_77"
              data-name="Ellipse 77"
              cx="0.375"
              cy="0.426"
              rx="0.375"
              ry="0.426"
              transform="translate(0 10.23)"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeWidth="1.5"
            />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);
