import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const postClientActivateProgram = async (
  id: number,
  activate: boolean,
  patientId: number,
) => {
  await fetchData(
    `/dietitian/patient/programs/${id}/activate`,
    APIMethods.POST,
    { activate, patientId },
  );
};

export interface CreateActivateClientProgramRequest {
  patientId: number;
  activate: boolean;
}
