import styled from "@emotion/styled";
import { FormAutocomplete } from "components/FormAutocomplete";

export const StyledFormAutocomplete = styled(FormAutocomplete)`
  & .MuiInputBase-root {
    border-radius: 6px;
    height: 2.75rem;
    font-size: inherit;
  }

  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: ${({ theme }) => theme.palette.grey[300]};
    }
    &:hover fieldset {
      border-color: ${({ theme }) => theme.palette.primary.main};
    }
    &.Mui-focused fieldset {
      border-width: 1px;
    }
  }
`;
