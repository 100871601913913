import { isArray } from "lodash";
import { Button } from "@mui/material";

import { useAppTranslation } from "@hooks";
import { MacrosUnits } from "@typeDefinitions";
import { decimalInput1, decimalInputFactory } from "@utils/inputs";

import {
  LabelStyled,
  MacroOutlinedInputStyled,
  RowStyled,
  SliderStyled,
} from "../MacrosSliders.styled";

const decimalInputMax = decimalInputFactory(0, 20000) as any;

export interface MacroSliderProps {
  label: string;
  unit: string;
  min?: number;
  max?: number;
  value: number;
  onCalc: () => void;
  step?: number;
  onSliderChange: (value: number) => void;
  onInputChange: (value: string) => void;
  sliderValue?: number;
  valueLabelFormat?: string;
}

export const MacroSlider = ({
  label,
  unit,
  min,
  max,
  value,
  onCalc,
  step,
  onSliderChange,
  onInputChange,
  sliderValue,
  valueLabelFormat,
}: MacroSliderProps) => {
  const { t } = useAppTranslation();

  return (
    <RowStyled>
      <LabelStyled variant="body2">{label}</LabelStyled>
      <MacroOutlinedInputStyled
        value={value}
        endAdornment={<span>{unit}</span>}
        onChange={event => onInputChange(event.target.value)}
        inputComponent={
          unit === MacrosUnits.GRAMS_PER_KILO ? decimalInput1 : decimalInputMax
        }
      />
      <SliderStyled
        value={sliderValue ?? value}
        valueLabelDisplay="on"
        onChange={(_, value) =>
          !isArray(value) ? onSliderChange(value) : null
        }
        min={min}
        max={max}
        step={step}
        valueLabelFormat={valueLabelFormat}
      />
      <Button onClick={onCalc}>{t("macros_sliders.calculate")}</Button>
    </RowStyled>
  );
};
