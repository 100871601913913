import { IconButton, styled } from "@mui/material";

export const MenuButtonWrapper = styled("div")`
  position: absolute;
  top: 0;
  right: 0;
`;

export const StyledOrangeIconButton = styled(IconButton)`
  position: absolute;
  bottom: 1.5rem;
  left: 2.75rem;
  padding: 0;
`;
