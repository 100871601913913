import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Location = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="25" viewBox="0 0 24 25" {...props}>
      <path
        d="M12.7369 20.4511C14.332 18.4448 17.9701 13.5823 17.9701 10.8511C17.9701 7.53857 15.296 4.85107 11.9999 4.85107C8.70391 4.85107 6.02979 7.53857 6.02979 10.8511C6.02979 13.5823 9.66785 18.4448 11.263 20.4511C11.6455 20.9292 12.3544 20.9292 12.7369 20.4511ZM11.9999 8.85107C12.5277 8.85107 13.0339 9.06179 13.4071 9.43686C13.7803 9.81193 13.99 10.3206 13.99 10.8511C13.99 11.3815 13.7803 11.8902 13.4071 12.2653C13.0339 12.6404 12.5277 12.8511 11.9999 12.8511C11.4721 12.8511 10.966 12.6404 10.5928 12.2653C10.2195 11.8902 10.0099 11.3815 10.0099 10.8511C10.0099 10.3206 10.2195 9.81193 10.5928 9.43686C10.966 9.06179 11.4721 8.85107 11.9999 8.85107Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
