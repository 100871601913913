import {
  DietitianProgramScheduleDay,
  DietitianProgramScheduleDayVariant,
} from "@client/schedule";

export const updateProgramDayOptimisticUpdate = (
  input: { data: DietitianProgramScheduleDay[] } | undefined,
  scheduleDay: DietitianProgramScheduleDay,
  v: DietitianProgramScheduleDayVariant,
) => {
  const day = input?.data.find(entry => entry.date === scheduleDay.date);
  if (day)
    day.meals = day.meals.filter(meal => {
      const newMeal = v.meals.find(
        m =>
          m.dietMeal.title.toLowerCase() === meal.dietMeal.title.toLowerCase(),
      );
      if (newMeal) {
        meal.recipe = newMeal.recipe;
        return true;
      }
    });
  return input;
};
