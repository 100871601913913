import { Box, styled } from "@mui/material";

export const CalendarDayNotification = styled("div")`
  position: absolute;
  background: ${({ theme }) => theme.colors.neutral.light[100]};
  width: 12px;
  height: 12px;
  border-radius: 100px;
  top: 0px;
  right: 0px;
  padding: 2px;
`;
export const Circle = styled("div")`
  width: 8px;
  height: 8px;
  background: #5252e5;
  border-radius: 100px;
`;

export const CalendarDayValue = styled(Box)`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
` as typeof Box;
