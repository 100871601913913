import * as yup from "yup";

export interface PatientHydrationProductResource {
  hydrationProduct: {
    id: number;
  };
  goal: number;
}

export const patientHydrationProductSchema = yup.object({
  hydrationProduct: yup
    .object({
      id: yup.number().required(),
    })
    .required(),
  goal: yup.number().required(),
});
