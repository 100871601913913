import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const ArrowMonitoring = (props: SvgIconProps) => (
  <SvgIcon width="8" height="15" viewBox="0 0 8 15" {...props}>
    <path
      d="M7.48779 7.78125L1.48779 13.7812C1.20654 14.0937 0.737793 14.0937 0.425293 13.7812C0.144043 13.5 0.144043 13.0312 0.425293 12.7187L5.92529 7.25L0.456542 1.78125C0.144042 1.5 0.144042 1.03125 0.456542 0.71875C0.737792 0.4375 1.20654 0.4375 1.48779 0.71875L7.48779 6.75C7.80029 7.03125 7.80029 7.5 7.48779 7.78125Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
