import { useAppTranslation, useNutrients } from "@hooks";
import { Nutrient } from "@typeDefinitions";
import { ENERGY_ID, MACROS_ARRAY, macrosSortKey } from "@utils/macros";
import { round } from "lodash";
import { useMemo } from "react";
import {
  AmountCell,
  BoldText,
  NameCell,
  NutrientWrapper,
  NutrientsListWrapper,
  ProductsSummaryStyled,
  SummaryName,
  Text,
} from "./ProductRow.styled";

interface ProductsSummaryProps {
  nutrients: Nutrient[];
}

export const ProductsSummary = ({ nutrients }: ProductsSummaryProps) => {
  const { t } = useAppTranslation();
  const { nutrientDict } = useNutrients();
  const nutrientsFiltered = useMemo(
    () =>
      nutrients
        .filter(n => MACROS_ARRAY.includes(n.id))
        .sort((a, b) => macrosSortKey(a.id, b.id)),
    [nutrients],
  );

  return (
    <ProductsSummaryStyled>
      <NameCell>
        <SummaryName>{t("recipes.sum")}</SummaryName>
      </NameCell>

      <AmountCell />

      <NutrientsListWrapper>
        {nutrientsFiltered.map((n, idx) => {
          const details = nutrientDict.get(n.id);
          if (n.id === ENERGY_ID)
            return (
              <NutrientWrapper key={idx}>
                <BoldText>
                  {round(n.value, details?.roundPrecision)}
                  {details?.unit}
                </BoldText>
              </NutrientWrapper>
            );
          return (
            <NutrientWrapper key={idx}>
              <Text>{nutrientDict.get(n.id)?.short}:</Text>
              <BoldText>
                {round(n.value, details?.roundPrecision)}
                {details?.unit}
              </BoldText>
            </NutrientWrapper>
          );
        })}
      </NutrientsListWrapper>
    </ProductsSummaryStyled>
  );
};
