import { ArraySchema, ObjectSchema, ValidationError } from "yup";

export async function validateAndFixNutrients<T>(
  schema: ObjectSchema<any> | ArraySchema<any, any, any>,
  data: T,
): Promise<T> {
  try {
    await schema.validate(data);
    return data;
  } catch (e) {
    if (e instanceof ValidationError) {
      const fixedData = traverseData(data);
      await schema.validate(fixedData);
      return fixedData;
    } else {
      throw e;
    }
  }
}

function traverseData(data: any, level = 0): any {
  if (!isArray(data) && isObject(data)) {
    const entries = Object.entries(data).map(([key, value]) => {
      if (nutrientsKeys.includes(key) && isArray(value)) {
        return [key, filterNullNutrients(value as Array<Nutrient>)];
      } else {
        return [key, traverseData(value, level + 1)];
      }
    });

    return Object.fromEntries(entries);
  } else if (isArray(data) && level !== 0) {
    return data.map((value: any) => traverseData(value, level + 1));
  } else if (isArray(data) && level === 0) {
    return traverseData(
      filterNullNutrients(data as Array<Nutrient>),
      level + 1,
    );
  } else {
    return data;
  }
}

function isObject(data: any): boolean {
  return typeof data === "object" && data !== null;
}

function isArray(data: any): boolean {
  return Array.isArray(data);
}

function filterNullNutrients(data: Array<Nutrient>): Array<Nutrient> {
  return data.map(nutrient => {
    if (nutrient.value === null || isNaN(nutrient.value)) {
      return { ...nutrient, value: 0 };
    } else {
      return nutrient;
    }
  });
}

type Nutrient = { value: number | null };

const nutrientsKeys = ["macros", "nutrients", "targetNutrients"];
