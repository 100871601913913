import DietitiansField from "@components/ProgramsFilters/Filters/Fields/DietitiansField";
import ProgramDaysField from "@components/ProgramsFilters/Filters/Fields/ProgramDaysField";
import ProgramDurationField from "@components/ProgramsFilters/Filters/Fields/ProgramDurationField";
import ProgramTabContent from "@components/ProgramsFilters/Filters/ProgramTabContent";
import { TabsField } from "./Fields/TabsField/TabsField";

interface ProgramFilterTabContentProps {
  unshiftTabs?: boolean;
}
const ProgramFilterTabContent = ({
  unshiftTabs,
}: ProgramFilterTabContentProps) => {
  return (
    <ProgramTabContent>
      {unshiftTabs && <TabsField />}
      <DietitiansField />
      <ProgramDaysField />
      <ProgramDurationField />
    </ProgramTabContent>
  );
};

export default ProgramFilterTabContent;
