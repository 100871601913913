import React from "react";
import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Food = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 26 26">
    <g id="burger" transform="translate(1 3)">
      <path
        id="Path_481"
        data-name="Path 481"
        d="M1248.5,563.5h-24c0-2.485,1.565-8,12.522-8S1248.5,561.015,1248.5,563.5Z"
        transform="translate(-1224.5 -555.5)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_482"
        data-name="Path 482"
        d="M1243.283,569.5h5.217c0,2.49-1.043,4.5-12,4.5s-12-2.01-12-4.5h12.522"
        transform="translate(-1224.5 -554.696)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <line
        id="Line_225"
        data-name="Line 225"
        x2="13"
        transform="translate(0 11.478)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_483"
        data-name="Path 483"
        d="M1242.5,566.5l-3,4.125-2-4.125"
        transform="translate(-1223.778 -554.885)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <line
        id="Line_226"
        data-name="Line 226"
        x1="5"
        transform="translate(19 11.478)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  </SvgIcon>
);
