import { Button, Card, styled } from "@mui/material";

export const PageWrapper = styled("div")`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
`;

export const CardStyled = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  border-radius: 12px;
`;

export const MobileWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  padding: 128px 24px 24px;
  border-radius: 12px;
  width: 100%;
  height: 100%;
`;

export const TextContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

export const Title = styled("span")`
  font-family: Figtree;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #141414;
`;

export const Text = styled("span")`
  font-family: Figtree;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #333333;
`;

export const ContainerWithButton = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  max-width: 458px;
`;

export const ButtonStyled = styled(Button)`
  background: linear-gradient(91.08deg, #485ad0 1.74%, #c977f0 107.23%);
  border-radius: 8px;
  padding: 8px 12px;
  height: 30px;
`;
