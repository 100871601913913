import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

import {
  IdDto,
  NutrientDto,
  TranslationDto,
  TranslationTitleDto,
  idSchema,
  nutrientSchema,
  translationSchema,
  translationTitleSchema,
} from "./common";

export const fetchMonitoringScheduleMeals = async (
  patientId: string,
  date: string,
): Promise<ApiResponse<MonitoringScheduleMealsResponse>> => {
  const response = await fetchData(
    `/dietitian/patients/${patientId}/monitoring/type/schedule/meals/${date}`,
    APIMethods.GET,
  );

  return await apiResponseSchema.validate(response);
};

export interface MonitoringScheduleMealsResponse {
  foodDiaryDay: FoodDiaryDayDto | null;
}

interface FoodDiaryDayDto {
  nutrients: NutrientDto[];
  meals: MealDto[];
}

interface MealDto {
  id: number;
  servings: number;
  nutrients: NutrientDto[];
  meal: MealDetailsDto;
  recipe: MealRecipeDto | null;
  liked: boolean;
}

interface MealDetailsDto {
  title: string;
  hour: string | null;
  translations: TranslationTitleDto[];
}

interface MealRecipeDto {
  id: number;
  title: string;
  translations: TranslationTitleDto[];
  foodRecipe: FoodRecipeDto[];
  servings: number;
}

interface FoodRecipeDto {
  id: number;
  nutrients: NutrientDto[];
  foodMeasure: IdDto;
  food: FoodDto;
  grams: number;
}

interface FoodDto {
  id: number;
  name: string;
  translations: TranslationDto[];
  measures: MeasureDto[];
}

interface MeasureDto {
  id: number;
  grams: number;
}

const measureSchema = yup.object().shape({
  id: yup.number().required(),
  grams: yup.number().required(),
});

const mealDetailsSchema = yup.object().shape({
  title: yup.string().required(),
  hour: yup.string().nullable().default(null),
  translations: yup.array().of(translationTitleSchema).required(),
});

const foodSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  translations: yup.array().of(translationSchema).required(),
  measures: yup.array().of(measureSchema).required(),
});

const foodRecipeSchema = yup.object().shape({
  id: yup.number().required(),
  nutrients: yup.array().of(nutrientSchema).required(),
  foodMeasure: idSchema.required(),
  food: foodSchema.required(),
  grams: yup.number().required(),
});

const mealRecipeSchema = yup.object().shape({
  id: yup.number().required(),
  title: yup.string().required(),
  translations: yup.array().of(translationTitleSchema).required(),
  foodRecipe: yup.array().of(foodRecipeSchema).required(),
  servings: yup.number().required(),
});

const mealSchema = yup.object().shape({
  id: yup.number().required(),
  servings: yup.number().required(),
  nutrients: yup.array().of(nutrientSchema).required(),
  meal: mealDetailsSchema.required(),
  recipe: mealRecipeSchema.nullable().default(null),
  liked: yup.boolean().required(),
});

const foodDiaryDaySchema = yup.object().shape({
  nutrients: yup.array().of(nutrientSchema).required(),
  meals: yup.array().of(mealSchema).required(),
});

const monitoringScheduleMealsSchema = yup.object().shape({
  foodDiaryDay: foodDiaryDaySchema.nullable().default(null),
});

const apiResponseSchema = yup.object().shape({
  data: monitoringScheduleMealsSchema,
});
