import { styled } from "@mui/material";

export const DateDividerWrapper = styled("div")`
  display: grid;
  grid-template-columns: 1fr minmax(0, max-content) 1fr;
  align-items: center;
`;

export const DateWrapper = styled("p")`
  text-align: center;
  padding: 0 0.75rem;
  font-size: 0.75rem;
  line-height: 1.125rem;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;
