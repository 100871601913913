import { EventWrapper } from "./HistoryItem.styled";
import { EventItem } from "./components/EventItem";
import { NotificationType } from "@client";
import { NOTIFICATION_EVENT_STATUS } from "@utils";
import { Link } from "react-router-dom";
import { PATIENTS } from "@routes";
import { useNotificationVariantData } from "../hooks";
import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
import { Clock, EmptyBookOpened } from "@assets/icons";
import { useAppTranslation } from "@hooks";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(isSameOrAfter);
dayjs.extend(tz);

interface HistoryItemProps {
  notification: NotificationType;
}

export const HistoryItem = ({ notification }: HistoryItemProps) => {
  const { t } = useAppTranslation();
  const { type, createdAt, data } = notification;
  const { icon, event } = useNotificationVariantData(type);
  const eventDate = dayjs.tz(createdAt, "UTC").local().format("HH:mm");

  if (notification.type === NOTIFICATION_EVENT_STATUS.PROGRAM_ENDS) {
    const endsIn = dayjs
      .tz(notification.data.finishedAt, "UTC")
      .local()
      .diff(dayjs.tz(notification.createdAt, "UTC").local(), "days", true);

    if (
      dayjs(notification.createdAt).isSameOrAfter(notification.data.finishedAt)
    ) {
      return (
        <Link to={`${PATIENTS}/${data.patient.id}/info-and-services`}>
          <EventWrapper>
            <EventItem
              icon={<EmptyBookOpened size="w5 h-5" />}
              status={type}
              name={data.patient.name}
              createdAt={eventDate}
              event={t("notifications.nutrition_program_ended")}
              id={notification.id}
              readAt={notification.readAt}
            />
          </EventWrapper>
        </Link>
      );
    }

    if (
      dayjs
        .tz(notification.createdAt, "UTC")
        .local()
        .isSame(dayjs.tz(notification.data.finishedAt, "UTC"), "day")
    ) {
      return (
        <Link to={`${PATIENTS}/${data.patient.id}/info-and-services`}>
          <EventWrapper>
            <EventItem
              icon={<EmptyBookOpened size="w5 h-5" />}
              status={type}
              name={data.patient.name}
              createdAt={eventDate}
              event={t("notifications.end_of_nutrition_program_today")}
              id={notification.id}
              readAt={notification.readAt}
            />
          </EventWrapper>
        </Link>
      );
    }

    return (
      <Link to={`${PATIENTS}/${data.patient.id}/info-and-services`}>
        <EventWrapper>
          <EventItem
            icon={<EmptyBookOpened size="w5 h-5" />}
            status={type}
            name={data.patient.name}
            createdAt={eventDate}
            event={t("notifications.end_of_nutrition_program_in_many", {
              count: Math.floor(endsIn),
            })}
            id={notification.id}
            readAt={notification.readAt}
          />
        </EventWrapper>
      </Link>
    );
  }

  if (
    notification.type === NOTIFICATION_EVENT_STATUS.PATIENT_COOPERATION_ENDS
  ) {
    const endsIn = dayjs
      .tz(notification.data.finishedAt, "UTC")
      .local()
      .diff(dayjs.tz(notification.createdAt, "UTC").local(), "days", true);

    if (
      dayjs(notification.createdAt).isSameOrAfter(notification.data.finishedAt)
    ) {
      return (
        <Link to={`${PATIENTS}/${data.patient.id}/info-and-services`}>
          <EventWrapper>
            <EventItem
              icon={<Clock size="w5 h-5" />}
              status={type}
              name={data.patient.name}
              createdAt={eventDate}
              event={t("notifications.cooperation_ended")}
              id={notification.id}
              readAt={notification.readAt}
            />
          </EventWrapper>
        </Link>
      );
    }

    if (
      dayjs
        .tz(notification.createdAt, "UTC")
        .local()
        .isSame(dayjs.tz(notification.data.finishedAt, "UTC"), "day")
    ) {
      return (
        <Link to={`${PATIENTS}/${data.patient.id}/info-and-services`}>
          <EventWrapper>
            <EventItem
              icon={<Clock size="w5 h-5" />}
              status={type}
              name={data.patient.name}
              createdAt={eventDate}
              event={t("notifications.end_of_cooperation_today")}
              id={notification.id}
              readAt={notification.readAt}
            />
          </EventWrapper>
        </Link>
      );
    }

    return (
      <Link to={`${PATIENTS}/${data.patient.id}/info-and-services`}>
        <EventWrapper>
          <EventItem
            icon={<Clock size="w5 h-5" />}
            status={type}
            name={data.patient.name}
            createdAt={eventDate}
            event={t("notifications.end_of_cooperation_in_many", {
              count: Math.floor(endsIn),
            })}
            id={notification.id}
            readAt={notification.readAt}
          />
        </EventWrapper>
      </Link>
    );
  }

  if (notification.type === NOTIFICATION_EVENT_STATUS.SURVEY_REPORT) {
    return (
      <Link to={`${PATIENTS}/${data.patient.id}/info-and-services`}>
        <EventWrapper>
          <EventItem
            icon={icon}
            status={type}
            name={data.patient.name}
            createdAt={eventDate}
            event={event}
            eventName={notification.data.survey.name}
            id={notification.id}
            readAt={notification.readAt}
          />
        </EventWrapper>
      </Link>
    );
  }

  if (notification.type === NOTIFICATION_EVENT_STATUS.PATIENT_BIRTHDAY) {
    return (
      <Link to={`${PATIENTS}/${data.patient.id}/info-and-services`}>
        <EventWrapper>
          <EventItem
            icon={icon}
            status={type}
            name={data.patient.name}
            createdAt={eventDate}
            event={event}
            id={notification.id}
            readAt={notification.readAt}
          />
        </EventWrapper>
      </Link>
    );
  }

  return <></>;
};
