import { FormProvider, useController } from "react-hook-form";

import { Button } from "@mui/material";
import { v4 as uuid } from "uuid";

import { QuestionRadioDto, QuestionType } from "@client/surveys";
import { useUpdateSurveySubgroupQuestionMutation } from "@hooks/queries/surveys";
import { useAppTranslation, useControlledFieldArray } from "@hooks";

import { Question } from "../Question/Question";
import { QuestionEditWatch } from "../QuestionEditWatch";
import {
  OptionsWithOtherConfigFormInputs,
  mapOptionsWithOtherConfigForm,
  mapOptionsWithOtherConfigRequest,
  useOptionsWithOtherConfigForm,
} from "../forms/useOptionsWithOtherConfigForm";
import { RadioOption } from "./RadioOption";
import { OtherOption } from "./OtherOption";
import { useMemo } from "react";

interface QuestionRadioProps {
  listIndex: number;
  totalQuestions: number;
  question: QuestionRadioDto;
  onTypeChange: (currentType: QuestionType, newType: QuestionType) => void;
}

export const QuestionRadio = ({
  listIndex,
  totalQuestions,
  question,
  onTypeChange,
}: QuestionRadioProps) => {
  const { t } = useAppTranslation();
  const mappedForm = useMemo(
    () => mapOptionsWithOtherConfigForm(question),
    [question],
  );
  const { control, watch, ...rest } = useOptionsWithOtherConfigForm(mappedForm);
  const { mutate } = useUpdateSurveySubgroupQuestionMutation();
  const { fields, append, remove } =
    useControlledFieldArray<OptionsWithOtherConfigFormInputs>({
      control,
      name: "options",
      watch,
    });

  const {
    field: { value, onChange },
  } = useController<OptionsWithOtherConfigFormInputs>({
    name: "other",
    control,
  });

  const onSubmit = (data: OptionsWithOtherConfigFormInputs) => {
    mutate({
      id: question.id.toString(),
      payload: mapOptionsWithOtherConfigRequest(data, question),
    });
  };

  const addOption = () => {
    append({
      name: `${t("questionnaires.questions.radio.option")} ${
        fields.length + 1
      }`,
      uuid: uuid(),
    });
  };

  const addOther = () => {
    onChange({
      name: t("questionnaires.questions.radio.other_response"),
      uuid: uuid(),
    });
  };

  return (
    <FormProvider control={control} watch={watch} {...rest}>
      <Question
        id={question.id}
        listIndex={listIndex}
        totalQuestions={totalQuestions}
        onTypeChange={onTypeChange}
        questionType={question.type}
      >
        {fields.map((f, i) => (
          <RadioOption
            key={f.id}
            name={`options.${i}.name`}
            onDelete={() => remove(i)}
          />
        ))}

        {value && <OtherOption onDelete={() => onChange(null)} />}
        <div className="flex items-center">
          <Button onClick={addOption}>
            {t("questionnaires.questions.radio.add_option")}
          </Button>
          {t("common.or")}
          <Button disabled={!!value} onClick={addOther}>
            {t("questionnaires.questions.radio.add_other")}
          </Button>
        </div>
      </Question>

      <QuestionEditWatch isLoading={false} onSubmit={onSubmit} />
    </FormProvider>
  );
};
