import { object, string } from "yup";

import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const fetchOauthGoogleLink = async (): Promise<OauthGoogleLinkDto> => {
  const response = await fetchData(
    "/dietitian/oauth/google/get-auth-link",
    APIMethods.GET,
  );

  return oauthGoogleLinkSchema.validate(response);
};

export interface OauthGoogleLinkDto {
  link: string;
}

const oauthGoogleLinkSchema = object({
  link: string().required(),
});
