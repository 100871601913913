import { Typography } from "@mui/material";

interface CustomRadioLabelProps {
  label: string;
}
export const CustomRadioLabel = ({ label }: CustomRadioLabelProps) => {
  return (
    <div className="flex items-center">
      <Typography
        fontWeight={500}
        width="24.375rem"
        color={({ colors }) => colors.neutral.dark[900]}
      >
        {label}
      </Typography>
    </div>
  );
};
