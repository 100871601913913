import { buildDefaultQueryOptions, QueryOptionsTyped } from "@hooks/queries";
import { ApiResponse } from "@typeDefinitions";
import { fetchTagCategories, FetchTagCategoriesCategory } from "@client";
import { useQuery } from "@tanstack/react-query";

export const useFetchTagsCategoriesQueryKey = "useFetchTagsCategoriesQueryKey";

export const useFetchTagsCategoriesQuery = () => {
  return useQuery({
    queryKey: [useFetchTagsCategoriesQueryKey],
    queryFn: fetchTagCategories,
    staleTime: 60 * 60 * 1000,
  });
};
