import { SelectedProgramType } from "@views/dietician/PatientProgramChoice/PatientProgramChoice";
import useProgramDate from "@hooks/program/useProgramDate";
import { Button, Dialog, Stack, TextField } from "@mui/material";
import {
  CustomDialogActions,
  CustomDialogContent,
  CustomDialogTitle,
} from "@views/dietician/PatientProgramChoice/ChoiceButton/DialogConfirmProgram.styled";
import { IconButton } from "@components/Alloweat/IconButton";
import CloseIcon from "@icons/close.svg?react";
import { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import ProgramStartDatePicker from "@views/dietician/PatientProgramChoice/ChoiceButton/ProgramStartDatePicker";
import { useCloneProgramToClient } from "@hooks/queries";
import { useNavigate } from "react-router-dom";
import { useAppParams, useAppTranslation } from "@hooks";
import { LoadingButton } from "@mui/lab";
import DataPickerWrapper from "@components/Alloweat/DataPicker/DataPickerWrapper";
import DataPicker from "@components/Alloweat/DataPicker/DataPicker";

type DialogConfirmProps = {
  selectedProgram: NonNullable<SelectedProgramType>;
  open: boolean;
  onClose: () => void;
};

const DialogConfirmProgram = ({
  selectedProgram,
  open,
  onClose,
}: DialogConfirmProps) => {
  const { t } = useAppTranslation();
  const [value, setValue] = useState<Dayjs | null>(() => dayjs());
  const navigate = useNavigate();
  const { patientId } = useAppParams();
  const { durationDays } = useProgramDate(selectedProgram);

  const { mutate, isLoading } = useCloneProgramToClient(selectedProgram.id, {
    onSuccess: data => {
      navigate(
        `/patients/${patientId}/nutritional-programs/${data?.data.id}/edit`,
      );
    },
  });
  const handleConfirm = () => {
    if (value === null) {
      return;
    }

    mutate({
      patientId: parseInt(patientId),
      startDate: value.format("YYYY-MM-DD"),
    });
  };

  if (!patientId) {
    return null;
  }

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        padding="16px"
      >
        <CustomDialogTitle>
          {t("patient.programs_choice.dialog.title")}
        </CustomDialogTitle>
        <IconButton onClick={() => onClose()}>
          <CloseIcon color="#727272" />
        </IconButton>
      </Stack>
      <CustomDialogContent>
        <Stack direction="column" gap="8px">
          <Stack direction="row" gap="16px" paddingTop="8px">
            <ProgramStartDatePicker value={value} setValue={setValue} />
            <DataPickerWrapper
              label={t("patient.programs_choice.dialog.date_end")}
            >
              <DataPicker
                disabled={true}
                value={
                  durationDays ? value?.clone().add(durationDays, "days") : null
                }
                onChange={() => undefined}
                renderInput={params => <TextField {...params} />}
                inputFormat="DD/MM/YYYY"
                InputAdornmentProps={{
                  position: "start",
                }}
              />
            </DataPickerWrapper>
          </Stack>
          <span>
            {t("patient.programs_choice.dialog.duration")}:{" "}
            {durationDays === null
              ? t("patient.programs_choice.dialog.duration_no_limit")
              : `${durationDays} ${t(
                  "patient.programs_choice.dialog.duration_days",
                )}`}
          </span>
        </Stack>
      </CustomDialogContent>
      <CustomDialogActions>
        <LoadingButton
          loading={isLoading}
          variant="outlined"
          color="primary"
          onClick={() => onClose()}
        >
          {t("patient.programs_choice.dialog.button_cancel")}
        </LoadingButton>
        <LoadingButton
          loading={isLoading}
          disabled={value === null}
          variant="contained"
          color="primary"
          onClick={handleConfirm}
          autoFocus
        >
          {t("patient.programs_choice.dialog.button_confirm")}
        </LoadingButton>
      </CustomDialogActions>
    </Dialog>
  );
};

export default DialogConfirmProgram;
