import { Box, CircularProgress, Stack, styled } from "@mui/material";
import { getProgressColor } from "@views/dietician/PatientMonitoring2/components/CalendarDays/CalendarDaysUtils";
import {
  CalendarDayNotification,
  CalendarDayValue,
  Circle,
} from "@views/dietician/PatientMonitoring2/components/CalendarDays/CalendarDayItem.styled";

type CalendarDayItemProps = {
  text: string;
  progress: number;
  selected: boolean;
  notification: boolean;
  today: boolean;
};

const CalendarDayItem = ({
  text,
  progress,
  selected,
  notification,
  today,
}: CalendarDayItemProps) => {
  return (
    <Stack spacing="6px" alignItems="center" sx={{ cursor: "pointer" }}>
      <Box position="relative" display="inline-flex">
        <CircularProgress
          variant="determinate"
          size={32}
          thickness={4}
          value={100}
          sx={{
            color: selected ? "#FFF" : "#F7F5FF",
          }}
        />
        <CircularProgress
          variant="determinate"
          sx={{
            color: getProgressColor(today, progress),
            position: "absolute",
            left: 0,
          }}
          size={32}
          value={progress}
          thickness={4}
        />
        <CalendarDayValue>
          <span>{text}</span>
        </CalendarDayValue>
        {notification ? (
          <CalendarDayNotification>
            <Circle />
          </CalendarDayNotification>
        ) : null}
      </Box>
    </Stack>
  );
};

export default CalendarDayItem;
