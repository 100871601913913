import { IdDto, idSchema } from "@client/common";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import * as yup from "yup";

export const postSurveySubgroup = async (
  groupId: string,
  payload: PostSurveySubgroupRequest,
): Promise<ApiResponse<IdDto>> => {
  const response = await fetchData(
    `/dietitian/survey-groups/${groupId}/sections`,
    APIMethods.POST,
    payload,
  );

  return postSurveySubgroupResponse.validate(response);
};

export interface PostSurveySubgroupRequest {
  title: string;
  description: string | null;
}

const postSurveySubgroupResponse = yup.object().shape({
  data: idSchema.required(),
});
