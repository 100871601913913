import { Dispatch, SetStateAction, createContext } from "react";

export const INTRODUCTION = "introduction";

export const TableOfContentsContext = createContext<TableOfContentsContextIf>({
  selectedId: INTRODUCTION,
  setSelectedId: () => null,
  groupId: null,
  setGroupId: () => null,
  subgroupId: null,
  setSubgroupId: () => null,
  getNearGroupId: (id: number | null) => 0,
  getNearSubgroupId: (id: number | null) => 0,
});

interface TableOfContentsContextIf {
  selectedId: string;
  setSelectedId: Dispatch<SetStateAction<string>>;
  groupId: number | null;
  setGroupId: Dispatch<SetStateAction<number | null>>;
  subgroupId: number | null;
  setSubgroupId: Dispatch<SetStateAction<number | null>>;
  getNearGroupId: (id: number | null) => number;
  getNearSubgroupId: (id: number | null) => number;
}
