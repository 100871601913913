import { useEffect } from "react";
import { useForm } from "react-hook-form";

export const useCardForm = (values?: CardFormInputs) => {
  const { reset, ...rest } = useForm<CardFormInputs>({
    defaultValues: {
      title: "",
      description: null,
    },
  });

  useEffect(() => {
    if (values) reset(values, { keepDirtyValues: true });
  }, [values]);

  return {
    reset,
    ...rest,
  };
};

interface CardFormInputs {
  title: string;
  description: string | null;
}
