import { number, object } from "yup";

export interface PatientBodyMeasurementResource {
  body: {
    id: number;
  };
  value: number | null;
}

export const patientBodyMeasurementSchema = object({
  body: object({
    id: number().required(),
  }).required(),
  value: number().nullable().required(),
});
