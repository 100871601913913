import { useForm } from "react-hook-form";
import { PdfExportPdfFormStateType } from "@Pdf/types/pdfTypes";
import { PDF_COLORS, PDF_DISPLAY_CODE } from "@Pdf/defaults/pdfDefaults";
import { yupResolver } from "@hookform/resolvers/yup";
import { array, boolean, date, number, object, string } from "yup";
import { useAppTranslation } from "@hooks";

export const defaultValues: PdfExportPdfFormStateType = {
  color: PDF_COLORS.PRIMARY,
  coverPageId: "",
  coverPageType: "photo",
  title: "",
  frequency: 7,
  startDate: null,
  endDate: null,
  options: [
    PDF_DISPLAY_CODE.WEEKDAY,
    PDF_DISPLAY_CODE.DATE,
    PDF_DISPLAY_CODE.MEAL_HOUR,
    PDF_DISPLAY_CODE.DAY_TYPE,
    PDF_DISPLAY_CODE.LOGO,
  ],
  clinicOptions: [
    PDF_DISPLAY_CODE.CLINIC_LOGO,
    PDF_DISPLAY_CODE.CLINIC_NAME,
    PDF_DISPLAY_CODE.CLINIC_ADDRESS,
    PDF_DISPLAY_CODE.CLINIC_EMAIL,
    PDF_DISPLAY_CODE.CLINIC_PHONE,
  ],
  kcal: false,
  macros: false,
  micros: [],
};

const usePdfExportForm = () => {
  const { t } = useAppTranslation();

  const resolver = object().shape({
    color: string().required(t("common.required_field")),
    coverPageType: string().required(t("common.required_field")),
    coverPageId: string().required(t("common.required_field")),
    title: string()
      .required(t("common.required_field"))
      .max(160, t("errors.too_long")),
    frequency: number().required(),
    startDate: date().required(t("common.required_field")),
    endDate: date().required(t("common.required_field")),
    options: array(),
    clinicOptions: array(),
    kcal: boolean(),
    macros: boolean(),
    micros: array(),
  });

  return useForm<PdfExportPdfFormStateType>({
    defaultValues,
    resolver: yupResolver(resolver),
    shouldFocusError: false,
  });
};

export default usePdfExportForm;
