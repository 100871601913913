import { Autocomplete, Button, Card, Divider, styled } from "@mui/material";

export const Title = styled("span")`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const Text = styled("span")`
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;
export const TextBold = styled("span")`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const CardStyled = styled(Card)`
  width: 436px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
`;

export const ButtonStyled = styled(Button)`
  height: 24px;
`;

export const AutocompleteStyled = styled(Autocomplete)`
  width: 348px;
` as typeof Autocomplete;

export const DividerStyled = styled(Divider)`
  border-color: ${({ theme }) => theme.colors.neutral.light[200]};
`;
