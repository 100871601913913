import { Autocomplete, Typography, styled } from "@mui/material";

export const StyledAutocomplete = styled(Autocomplete)`
  & .MuiInputBase-root.MuiOutlinedInput-root {
    padding-right: 0;
  }

  & svg {
    color: ${({ theme }) => theme.colors.neutral.dark[700]};
  }

  & ~ .MuiAutocomplete-popper {
    & .MuiAutocomplete-option {
      padding: 0.5rem;
    }
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallMax}px) {
    min-width: 23rem;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.medium}px) {
    min-width: 25rem;
  }
` as typeof Autocomplete;

export const NameTypography = styled(Typography)`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;

export const EmailTypography = styled(Typography)`
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;
