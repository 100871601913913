import { Button, CircularProgress, Tooltip } from "@mui/material";
import {
  CloseButton,
  DrawerHeader,
  StyledDrawer,
  StyledList,
  StyledListWrapper,
} from "./MeasurementList.styled";
import { Close, Plus } from "@assets/icons";
import { useFetchClientMeasurementListInfinite } from "@hooks/queries";
import { useAppTranslation } from "@hooks";
import { MeasurementsEmptyState } from "../MeasurementsEmptyState";
import MeasurementChart from "@views/dietician/ClientHealth/components/MeasurementList/MeasurementChart";
import { useMemo, useState } from "react";
import CreateBodyMeasurementsModal from "@components/Client/FormBodyMeasurementsModal/CreateBodyMeasurementsModal";
import MeasurementItemNew from "@views/dietician/ClientHealth/components/MeasurementItem/MeasurementItemNew";
import useLastElementRefForScrollQueryInfinite from "@hooks/useLastElementRefForScrollQueryInfinite";

interface MeasurementListProps {
  onClose?: () => void;
  opened: boolean;
  clientId: number;
  active: boolean;
}

export const MeasurementList = ({
  onClose,
  opened,
  clientId,
  active,
}: MeasurementListProps) => {
  const { data, isLoading, isFetching, fetchNextPage, hasNextPage } =
    useFetchClientMeasurementListInfinite(clientId.toString());
  const { lastElementRef } = useLastElementRefForScrollQueryInfinite({
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
  });
  const { t } = useAppTranslation();
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const measurements = useMemo(() => {
    return (data?.pages ?? []).map(p => p.data).flat();
  }, [data]);

  return (
    <>
      <StyledDrawer
        anchor="right"
        PaperProps={{
          sx: { width: "640px", padding: "16px", gap: "24px" },
        }}
        open={opened}
        onClose={onClose}
        closeAfterTransition={false}
        aria-hidden={false}
      >
        <DrawerHeader>
          {t("client_profile.health.measurements_list")}
          <div className="flex">
            <Tooltip
              title={t("common.access_functionality_tooltip")}
              disableHoverListener={!!active}
              arrow
              placement="top"
            >
              <div>
                <Button
                  onClick={() => setOpenCreateModal(true)}
                  startIcon={<Plus size="w-3.5 h-3.5" />}
                  disabled={!active}
                >
                  {t("client_profile.health.add_new")}
                </Button>
              </div>
            </Tooltip>
            <CloseButton onClick={onClose}>
              <Close style={{ width: "10px", height: "10px" }} size="" />
            </CloseButton>
          </div>
        </DrawerHeader>
        <MeasurementChart measurement={(measurements ?? [])[0] ?? null} />
        <StyledListWrapper>
          <StyledList>
            {!measurements.length && (
              <MeasurementsEmptyState
                onClick={() => setOpenCreateModal(true)}
                disabled={!active}
              />
            )}
            {(measurements ?? []).map((item, index) => (
              <div
                key={item.id}
                ref={
                  measurements.length === index + 1 ? lastElementRef : undefined
                }
              >
                <MeasurementItemNew measurement={item} clientId={clientId} />
              </div>
            ))}
            {(isLoading || isFetching) && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </div>
            )}
          </StyledList>
        </StyledListWrapper>
      </StyledDrawer>
      <CreateBodyMeasurementsModal
        open={openCreateModal}
        setOpen={setOpenCreateModal}
      />
    </>
  );
};
