import {
  DialogSectionItemLabel,
  DialogSectionItemLayout,
} from "@views/dietician/ClientHealth/ClientHealthNew.styled";
import { MenuItem, Select } from "@mui/material";
import {
  useFetchClientAdditionalFeaturesQuery,
  useFetchClientQuery,
  useFetchClientQueryNew,
} from "@hooks/queries";
import { Controller } from "react-hook-form";
import { useAppTranslation, useClientParams } from "@hooks";
import { useMemo } from "react";
import { getTranslation } from "@utils/dictionaries/clientAdditionalFeaturesDictionaryUtils";

const AdditionalFeatureField = () => {
  const { t, currentLanguage } = useAppTranslation();
  const { additionalFeatures } = useFetchClientAdditionalFeaturesQuery();
  const id = useClientParams();
  const { data: client } = useFetchClientQueryNew(id);

  const clientAdditionalFeatures = useMemo(() => {
    if (!additionalFeatures || !client) {
      return [];
    }

    if (!client.data.profile.sex) {
      return additionalFeatures;
    }

    return additionalFeatures.filter(
      feature => feature.sex === client.data.profile.sex,
    );
  }, [additionalFeatures, client]);

  return (
    <DialogSectionItemLayout>
      <DialogSectionItemLabel>
        {t(
          "patient.health_and_goal.dietary_profile_tab.dietary_profile_modal.additional_information",
        )}
      </DialogSectionItemLabel>
      <Controller
        render={({ field }) => {
          return (
            <Select
              value={field.value}
              onChange={event => field.onChange(event.target.value)}
              placeholder={t(
                "patient.health_and_goal.dietary_profile_tab.dietary_profile_modal.additional_information_placeholder",
              )}
            >
              {clientAdditionalFeatures.map(feature => (
                <MenuItem key={feature.id} value={feature.id.toString()}>
                  {getTranslation(feature, currentLanguage)?.value}
                </MenuItem>
              ))}
            </Select>
          );
        }}
        name="additionalFeatureId"
      />
    </DialogSectionItemLayout>
  );
};

export default AdditionalFeatureField;
