import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { StyledDialog } from "./EmptyDietWarningDialog.styled";

interface EmptyDietWarningDialogProps {
  open: boolean;
  onAccept: () => void;
  onCancel: () => void;
  title: string;
  content: string;
}

export const EmptyDietWarningDialog = ({
  open,
  onAccept,
  onCancel,
  title,
  content,
}: EmptyDietWarningDialogProps) => {
  return (
    <StyledDialog open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onAccept}>OK</Button>
      </DialogActions>
    </StyledDialog>
  );
};
