import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const ArrowRight = (props: SvgIconProps) => {
  return (
    <SvgIcon width="8" height="14" viewBox="0 0 8 14" {...props}>
      <path
        d="M1.53125 0.46875L7.25 6.46875C7.39583 6.61458 7.46875 6.78125 7.46875 6.96875C7.46875 7.15625 7.39583 7.33333 7.25 7.5L1.53125 13.4688C1.19792 13.7604 0.84375 13.7708 0.46875 13.5C0.177083 13.1458 0.177083 12.7917 0.46875 12.4375L5.71875 6.9375L0.46875 1.53125C0.177083 1.15625 0.177083 0.802083 0.46875 0.46875C0.84375 0.177083 1.19792 0.177083 1.53125 0.46875Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
