import { useEffect, useMemo, useState } from "react";
import {
  itemBgColors,
  itemFontColors,
  whiteTextBgIndex,
} from "@utils/itemColors";
import {
  DietitianProgramScheduleDay,
  DietitianProgramScheduleDayVariant,
} from "@client/schedule";

export const useProgramMealColorsExtractor = (
  programScheduleData?: (
    | DietitianProgramScheduleDay
    | DietitianProgramScheduleDayVariant
  )[],
) => {
  const [usedRecipes, setUsedRecipes] = useState<string[]>([]);

  useEffect(() => {
    const newRecipes = programScheduleData
      ?.reduce((recipes, day) => {
        const r: string[] = day.meals
          .filter(meal => meal.recipe?.id)
          .map(meal => meal.recipe?.title as string);
        recipes.push(...r);
        return recipes;
      }, [] as string[])
      .sort((a, b) => (a > b ? 1 : -1))
      .filter(
        (item, pos, ar) =>
          (!pos || item != ar[pos - 1]) && !usedRecipes.includes(item),
      );

    if (newRecipes?.length) setUsedRecipes([...usedRecipes, ...newRecipes]);
  }, [programScheduleData]);

  return useMemo(
    () =>
      usedRecipes.map((recipeName, index) => ({
        recipeName,
        index,
        color:
          index % itemBgColors.length >= whiteTextBgIndex
            ? "#FFFFFF"
            : itemFontColors[index % itemFontColors.length],
        backgroundColor: itemBgColors[index % itemBgColors.length],
      })),
    [usedRecipes],
  );
};
