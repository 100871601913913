import { MouseEventHandler, SetStateAction } from "react";

import { Chip } from "@mui/material";

import { Funnel } from "@assets/icons";
import { useAppTranslation } from "@hooks";

import { ButtonText, IconButtonStyled } from "./FunnelButton.styled";

interface FunnelButtonProps {
  setOpen: (value: SetStateAction<boolean>) => void;
  open: boolean;
  allTags: number;
  compact?: boolean;
}

export const FunnelButton = ({
  setOpen,
  open,
  allTags,
  compact = false,
}: FunnelButtonProps) => {
  const { t } = useAppTranslation();
  const toggleOpen: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();
    setOpen(!open);
  };

  return (
    <IconButtonStyled onClick={toggleOpen} color="primary" compact={compact}>
      <Funnel />
      {!compact && <ButtonText>{t("common.filters")}</ButtonText>}
      {!!allTags && <Chip label={`+${allTags}`} color="primary" />}
    </IconButtonStyled>
  );
};
