import { MealsMonitoring } from "@assets/icons/Monitoring";
import SummaryCard, {
  SummaryCardProps,
} from "@views/dietician/PatientMonitoring2/components/SummaryCard/SummaryCard";
import { useAppTranslation } from "@hooks";

type MealsSummaryCardOfProps = Pick<
  SummaryCardProps,
  "actualValue" | "targetValue"
>;

const MealsSummaryCardOf = ({
  actualValue,
  targetValue,
}: MealsSummaryCardOfProps) => {
  const { t } = useAppTranslation();
  return (
    <SummaryCard
      boxId="monotoring_meals_box"
      icon={<MealsMonitoring fill="#7448D0" />}
      title={t("monitoring2.summary_cards.meals_title")}
      actualValue={actualValue}
      targetValue={targetValue}
    />
  );
};

export default MealsSummaryCardOf;
