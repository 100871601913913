import { Chip, Stack } from "@mui/material";
import { AvailableUserMonitoring } from "@assets/icons/Monitoring";
import {
  SummaryCardWrapper,
  Title,
} from "@views/dietician/PatientMonitoring2/components/SummaryCard/SummaryCard.styled";
import { useAppTranslation } from "@hooks";
import { useFetchClientQuery } from "@hooks/queries";
import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import { useMemo } from "react";
import dayjs from "dayjs";
import { getLastLoginItemConfig } from "@views/dietician/PatientMonitoring2/components/SummaryCard/LastLoginUtils";

export const LastLoginSummaryCard = () => {
  const { t } = useAppTranslation();
  const { patientId } = useMonitoring();
  const { client } = useFetchClientQuery(patientId);

  const config = useMemo(() => {
    if (!client || !client.lastLoggedAt) {
      const config = { ...getLastLoginItemConfig(null) };
      return {
        ...config,
        text: t(config.textKey),
      };
    }

    const lastLoggedAt = dayjs
      .utc(client.lastLoggedAt, "YYYY-MM-DD HH:mm:ss")
      .local();

    const config = getLastLoginItemConfig(lastLoggedAt);

    return {
      ...config,
      text:
        config.days < 3
          ? t(config.textKey, { variable: lastLoggedAt.format("HH:mm") })
          : t(config.textKey, {
              variable: dayjs().diff(lastLoggedAt, "days", false),
            }),
    };
  }, [client?.lastLoggedAt, t]);

  return (
    <SummaryCardWrapper>
      <Stack direction="column" spacing="8px">
        <Stack direction="row" alignItems="center" spacing="6px">
          <AvailableUserMonitoring fill={config.color.text} size="" />
          <Title>{t("monitoring2.summary_cards.last_login_title")}</Title>
        </Stack>
        <Chip
          sx={{ color: config.color.text, background: config.color.background }}
          label={config.text}
        />
      </Stack>
    </SummaryCardWrapper>
  );
};
