import { FC, forwardRef, useMemo } from "react";
import PdfTemplatePickerItem, {
  TemplateType,
} from "@Pdf/components/PdfTemplatePicker/PdfTemplatePickerItem";
import { Box } from "@mui/material";
import PdfTemplatePickerAddItem from "@Pdf/components/PdfTemplatePicker/PdfTemplatePickerAddItem";

type PropsType = {
  value: string;
  templates: Array<TemplateType>;
  addable?: boolean;
  onChange: (value: string) => void;
};

const PdfTemplatePicker: FC<PropsType> = forwardRef(
  ({ value, templates, addable, onChange }, ref) => {
    const custom = useMemo(
      () => templates.filter(item => item.delete),
      [templates],
    );
    const system = useMemo(
      () => templates.filter(item => !item.delete),
      [templates],
    );

    return (
      <Box
        ref={ref}
        display="grid"
        gridTemplateColumns={"1fr 1fr 1fr"}
        className={"gap-[16px]"}
      >
        {addable && custom.length < 20 && <PdfTemplatePickerAddItem />}
        {custom.map(template => (
          <PdfTemplatePickerItem
            _id={template._id}
            checked={template.id === value}
            value={template.id}
            template={template}
            key={template.id}
            deletable
            onChange={onChange}
          />
        ))}
        {system.map(template => (
          <PdfTemplatePickerItem
            _id={template._id}
            checked={template.id === value}
            value={template.id}
            template={template}
            key={template.id}
            onChange={onChange}
          />
        ))}
      </Box>
    );
  },
);

export default PdfTemplatePicker;
