import { fetchData } from "@utils/api";
import { APIMethods, ApiEndpoints } from "@typeDefinitions";

export const updateTaskStatus = async ({
  id,
  isDone,
}: UpdateTaskStatusRequestPayload) => {
  const response = await fetchData(
    `${ApiEndpoints.Tasks}/${id}/status`,
    APIMethods.PUT,
    { isDone },
  );

  return response;
};

export interface UpdateTaskStatusRequestPayload {
  id: number;
  isDone: boolean;
}
