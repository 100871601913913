import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const CommentMonitoring = (props: SvgIconProps) => (
  <SvgIcon width="14" height="12" viewBox="0 0 14 12" {...props}>
    <path
      d="M6.95654 0.4375C10.5366 0.4375 13.4312 2.82422 13.4312 5.71875C13.4312 8.63867 10.5366 11 6.95654 11C6.11865 11 5.30615 10.873 4.59521 10.6445C3.8335 11.1777 2.61475 11.8125 1.06592 11.8125C0.812012 11.8125 0.583496 11.6855 0.481934 11.457C0.405762 11.2285 0.431152 10.9746 0.608887 10.7969C0.634277 10.7969 1.42139 9.93359 1.77686 8.94336C0.938965 8.05469 0.456543 6.9375 0.456543 5.71875C0.456543 2.82422 3.35107 0.4375 6.95654 0.4375ZM6.95654 9.78125C9.85107 9.78125 12.2378 7.97852 12.2378 5.71875C12.2378 3.48438 9.85107 1.65625 6.95654 1.65625C4.03662 1.65625 1.67529 3.48438 1.67529 5.71875C1.67529 6.81055 2.2085 7.62305 2.66553 8.10547L3.19873 8.66406L2.91943 9.375C2.79248 9.73047 2.61475 10.0859 2.41162 10.3906C3.021 10.1875 3.52881 9.9082 3.88428 9.6543L4.3667 9.29883L4.95068 9.47656C5.58545 9.67969 6.271 9.78125 6.95654 9.78125Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
