import { IconButton, styled } from "@mui/material";

export const Header = styled("div")`
  display: grid;
  padding: 16px;
  gap: 16px;
  background: ${({ theme }) => theme.palette.primary.light};
  top: 0;
  right: 0;
  position: sticky;
  width: 740px;
  border-start-start-radius: 8px;
  border-start-end-radius: 8px;
  z-index: 1;
`;

export const Title = styled("span")`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;

export const ProductName = styled("span")`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;

export const IconButtonStyled = styled(IconButton)`
  height: 33px;
`;

export const ProducerText = styled("span")`
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;
