import { useEffect } from "react";
import { useForm } from "react-hook-form";

import * as yup from "yup";

import {
  DateAnswerQuestionDto,
  UpdateSingedSurveyRequest,
} from "@client/surveys";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";

export const useDateAnswerForm = (
  values: DateAnswerFormInputs | undefined,
  required: boolean,
) => {
  const resolver = yup.object().shape({
    value: required ? yup.string().required() : yup.string().nullable(),
  });

  const form = useForm<DateAnswerFormInputs>({
    resolver: yupResolver(resolver),
  });

  useEffect(() => {
    if (values) form.reset(values, { keepDirtyValues: true });
  }, [values]);

  return form;
};

export const mapDateAnswerForm = (
  data?: DateAnswerQuestionDto,
): DateAnswerFormInputs => {
  if (!data) return { value: null };
  return data;
};

export const mapDateAnswerRequest = (
  data: DateAnswerFormInputs,
  questionId: number,
): UpdateSingedSurveyRequest => {
  if (!data.value) return { questionId, answer: { value: null } };
  return {
    questionId,
    answer: { value: dayjs(data.value).format("YYYY-MM-DD") },
  };
};

export interface DateAnswerFormInputs {
  value: string | null;
}
