import { useAppParams } from "@hooks";
import { usePostDietsProductsReplacementMutation as usePostCreatorDietsProductsReplacementMutation } from "@hooks/queries/diets";
import { usePostProgramDietsProductsReplacementMutation } from "@hooks/queries/program/dietCreator";

export const usePostDietsProductsReplacementMutation = () => {
  const { programId } = useAppParams();

  return programId
    ? usePostProgramDietsProductsReplacementMutation(programId)
    : usePostCreatorDietsProductsReplacementMutation();
};
