import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const duplicateScheduleInterval = (
  programId: string,
  payload: CloneScheduleIntervalRequest,
) => {
  return fetchData(
    `/dietitian/programs/${programId}/schedule/days/duplicate-interval`,
    APIMethods.POST,
    payload,
  );
};

export interface CloneScheduleIntervalRequest {
  programScheduleDays: DayIdDto[];
  period: 7 | 14 | 28;
}

interface DayIdDto {
  id: number;
}
