import { memo, PropsWithChildren, ReactNode } from "react";

import { HeaderWrapperStyled } from "./SingleCardPageLayout.styled";

interface PageLayoutProps {
  title: ReactNode;
  extra?: ReactNode;
  childrenClassName?: string;
  rebranding?: boolean;
  childrenDefaultClasses?: boolean;
}

export const SingleCardPageLayout = memo(
  ({
    title,
    children,
    extra,
    childrenClassName,
    rebranding,
    childrenDefaultClasses = true,
  }: PropsWithChildren<PageLayoutProps>) => {
    const handleClasses = (useDefault: boolean, classNames?: string) => {
      if (classNames) return classNames;
      if (useDefault) return "bg-white rounded-md shadow-paper";
      return "";
    };

    const childrenClasses = handleClasses(
      childrenDefaultClasses,
      childrenClassName,
    );

    return (
      <div>
        <HeaderWrapperStyled>
          <h2 className={rebranding ? "text-xl" : "text-2xl"}>{title}</h2>
          {extra}
        </HeaderWrapperStyled>
        <div className={childrenClasses}>{children}</div>
      </div>
    );
  },
);
