import { useAppTranslation, useImageUpload, useModal } from "@hooks";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";
import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";

import { Divider, Typography, useTheme } from "@mui/material";
import {
  SectionTitle,
  SectionWrapper,
  SettingWrapper,
  SettingsCard,
  StyledButton,
  Subtitle,
  TitleSection,
} from "./SettingsAccount.styled";
import { Avatar } from "@components/AvatarNew";
import { Pencil } from "@assets/icons";
import { EditPersonalInfoModal } from "./components/EditPersonalInfoModal";
import { ChangePasswordModal } from "./components/ChangePasswordModal";
import { useEffect } from "react";

export const SettingsAccount = () => {
  const { t } = useAppTranslation();
  const { colors } = useTheme();
  const { modalOpened, onModalClose, onModalOpen } = useModal();
  const {
    modalOpened: passwordModalOpened,
    onModalClose: onPasswordModalClose,
    onModalOpen: onPasswordModalOpen,
  } = useModal();

  const { isUploading, handleUploadAvatar } = useImageUpload({
    url: `${import.meta.env.VITE_APP_API_URL}/dietitian/account/avatar`,
  });
  const { account, isLoading, isError, refetch } =
    useFetchDietitianAccountQuery();

  const hiddenPassword = "********";

  useEffect(() => {
    refetch();
  }, [isUploading]);

  const imgUrl = account?.avatar;

  if (isLoading) return <Spinner className="h-screen" />;

  if (!account || isError) return <ApiError />;

  const {
    firstName,
    lastName,
    language,
    phone: { prefix, number },
    email,
  } = account;

  const selectedLanguage =
    language === "pl"
      ? t("settings.personal_info.language.pl")
      : t("settings.personal_info.language.en");

  return (
    <>
      <SettingWrapper>
        <SettingsCard>
          <TitleSection>
            <p>{t("settings.personal_info.title")}</p>
            <StyledButton
              onClick={onModalOpen}
              startIcon={<Pencil size="w-4 h-4" className="stroke-current" />}
              disableRipple
            >
              {t("common.edit")}
            </StyledButton>
          </TitleSection>
          <div className="flex justify-center items-center">
            <Avatar
              isEditable
              size="huge"
              isUploading={isUploading}
              onFileUpload={handleUploadAvatar}
              image={imgUrl ?? undefined}
              fullName={`${account?.firstName ?? ""} ${
                account?.lastName ?? ""
              }`}
            />
          </div>
          <SectionWrapper>
            <SectionTitle>{t("settings.personal_info.title")}</SectionTitle>

            <div className="grid gap-1">
              <Typography variant="body2" color={colors.neutral.medium[800]}>
                {t("settings.personal_info.name_and_last_name")}
              </Typography>
              <Subtitle>{`${firstName} ${lastName}`}</Subtitle>
            </div>

            <div className="grid gap-1">
              <Typography variant="body2" color={colors.neutral.medium[800]}>
                {t("common.language")}
              </Typography>
              <Subtitle>{selectedLanguage}</Subtitle>
            </div>

            <div className="grid gap-1">
              <Typography variant="body2" color={colors.neutral.medium[800]}>
                {t("common.phone_number")}
              </Typography>
              <Subtitle>{number && `${prefix} ${number}`}</Subtitle>
            </div>

            <div className="grid gap-1">
              <Typography variant="body2" color={colors.neutral.medium[800]}>
                {t("common.email_address")}
              </Typography>
              <Subtitle>{email}</Subtitle>
            </div>
          </SectionWrapper>

          <Divider />

          <TitleSection>
            <SectionTitle>{t("common.password")}</SectionTitle>
            <StyledButton
              onClick={onPasswordModalOpen}
              startIcon={<Pencil size="w-4 h-4" className="stroke-current" />}
              disableRipple
            >
              {t("settings.password_edit.change_password")}
            </StyledButton>
          </TitleSection>

          <SectionWrapper>
            <div className="grid gap-1">
              <Typography variant="body2" color={colors.neutral.medium[800]}>
                {t("common.password")}
              </Typography>
              <Subtitle>{hiddenPassword}</Subtitle>
            </div>
          </SectionWrapper>
        </SettingsCard>
      </SettingWrapper>
      <EditPersonalInfoModal onOpen={modalOpened} onClose={onModalClose} />
      <ChangePasswordModal
        onOpen={passwordModalOpened}
        onClose={onPasswordModalClose}
      />
    </>
  );
};
