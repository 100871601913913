import { useParams } from "react-router-dom";
import { useMemo } from "react";

export function useDietEditorParams() {
  const { dietId } = useParams<DietParams>();
  return useMemo(() => (dietId ? parseInt(dietId) : NaN), [dietId]);
}

type DietParams = {
  dietId: string;
};
