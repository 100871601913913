import React from "react";
import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Home = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 26 26">
    <g id="Home" transform="translate(2 1)">
      <path
        id="Home-2"
        data-name="Home"
        d="M7.989,22.526v-3.68a1.711,1.711,0,0,1,1.709-1.7h3.463a1.712,1.712,0,0,1,1.72,1.7h0v3.692A1.47,1.47,0,0,0,16.324,24h2.309A4.147,4.147,0,0,0,22.8,19.874h0V9.405A2.927,2.927,0,0,0,21.646,7.12l-7.9-6.3a3.816,3.816,0,0,0-4.733,0L1.154,7.131A2.9,2.9,0,0,0,0,9.417V19.874A4.147,4.147,0,0,0,4.167,24H6.476a1.482,1.482,0,0,0,1.489-1.474h0"
        transform="translate(0 0)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  </SvgIcon>
);
