import {
  BlankTablet,
  Clock,
  EmptyBookOpened,
  EmptyCalendar,
  HeadWithShoulders,
  Smartphone,
  StarEmpty,
} from "@assets/icons";
import { useAppTranslation } from "@hooks";
import { CLIENT_PROFILE_EVENT_STATUS } from "@utils";
import { useMemo } from "react";

export const useEventVariantData = (type?: CLIENT_PROFILE_EVENT_STATUS) => {
  const { t } = useAppTranslation();
  const eventVariantsDict = useMemo(
    () => ({
      [CLIENT_PROFILE_EVENT_STATUS.CREATED]: {
        icon: <HeadWithShoulders />,
        name: t("client_profile.history.client_created"),
      },
      [CLIENT_PROFILE_EVENT_STATUS.ACTIVATED]: {
        icon: <StarEmpty />,
        name: t("client_profile.history.client_activated"),
      },
      [CLIENT_PROFILE_EVENT_STATUS.DEACTIVATED]: {
        icon: <StarEmpty />,
        name: t("client_profile.history.client_deactivated"),
      },
      [CLIENT_PROFILE_EVENT_STATUS.SURVEY_SENT]: {
        icon: <BlankTablet />,
        name: t("client_profile.history.sent_questionnaire_name"),
      },
      [CLIENT_PROFILE_EVENT_STATUS.SURVEY_RESPONDED]: {
        icon: <BlankTablet />,
        name: t("client_profile.history.received_questionnaire_name"),
      },
      [CLIENT_PROFILE_EVENT_STATUS.VISIT_CREATED]: {
        icon: <EmptyCalendar />,
        name: t("client_profile.history.appointment_added"),
      },
      [CLIENT_PROFILE_EVENT_STATUS.SUBSCRIPTION_ACTIVATED]: {
        icon: <Clock />,
        name: t("client_profile.history.cooperation_activated"),
      },
      [CLIENT_PROFILE_EVENT_STATUS.PROGRAM_CREATED]: {
        icon: <EmptyBookOpened />,
        name: t("client_profile.history.nutritional_program_added"),
      },
      [CLIENT_PROFILE_EVENT_STATUS.MOBILE_APP_ACTIVATED]: {
        icon: <Smartphone />,
        name: t("client_profile.history.mobile_app_activated"),
      },
      [CLIENT_PROFILE_EVENT_STATUS.MOBILE_APP_DEACTIVATED]: {
        icon: <Smartphone />,
        name: t("client_profile.history.mobile_app_deactivated"),
      },
    }),
    [t, type],
  );

  if (!type || !eventVariantsDict[type])
    return { icon: undefined, name: undefined };
  return eventVariantsDict[type];
};
