import { styled, Typography } from "@mui/material";

interface CircleProps {
  color: string;
}

export const Circle = styled("div")<CircleProps>`
  border-radius: 100%;
  width: 0.75rem;
  height: 0.75rem;
  background-color: ${({ color }) => color};
`;

export const MacroLabel = styled("div")`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

interface LegendWrapperProps {
  vertical?: boolean;
}

export const LegendWrapper = styled("div")<LegendWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  ${({ vertical }) =>
    vertical &&
    `
    flex-direction: column;
    align-items: start;
    gap: 0;
  `}
`;

interface BarPercentProps {
  width: string;
  background: string;
  radius?: string;
}

export const BarPercent = styled("div")<BarPercentProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => width};
  background-color: ${({ background }) => background};
  border-radius: ${({ radius }) => radius ?? "unset"};
  color: ${({ theme }) => theme.colors.gray200};
  padding: 5px 0;
`;

export const TypographyStyled = styled(Typography)`
  font-weight: 600;
`;
