import { useCallback, useEffect, useMemo } from "react";

import { useTheme } from "@mui/material";

import {
  DietitianFormInput,
  mapCreateDietitianRequest,
  mapDietitianForm,
  mapUpdateDietitianRequest,
  useAppTranslation,
  useDietitianForm,
} from "@hooks";
import {
  useCreateClinicDietitianMutation,
  useFetchClinicDietitianQuery,
  useFetchClinicFacilitiesQuery,
  useUpdateClinicDietitianMutation,
} from "@hooks/queries/settings";
import { ModalWrapper } from "@components/ModalWrapper";
import { FormTextField } from "@components/FormTextField";
import { FormAutocomplete } from "@components/FormAutocomplete";
import { CheckedIcon } from "@assets/icons";

import {
  StyledFormCountryAutocomplete,
  StyledPhoneTextField,
} from "./DietitianModal.styled";
import { TAKEN_EMAIL_ERROR } from "@utils/clients";

interface DietitianModalProps {
  open: boolean;
  onClose: () => void;
  edit?: boolean;
  id?: number;
}

export const DietitianModal = ({
  open,
  onClose,
  edit = false,
  id,
}: DietitianModalProps) => {
  const { t } = useAppTranslation();
  const { palette } = useTheme();
  const { dietitian } = useFetchClinicDietitianQuery(id ?? 0, {
    enabled: !!id && edit,
  });
  const { facilities } = useFetchClinicFacilitiesQuery();

  const mappedDietitian = useMemo(
    () => mapDietitianForm(edit ? dietitian : undefined),
    [dietitian, mapDietitianForm, id],
  );

  const { control, reset, handleSubmit, setError } =
    useDietitianForm(mappedDietitian);

  const closeWithReset = useCallback(() => {
    onClose();
    reset();
  }, [reset, onClose]);

  useEffect(reset, [open]);

  const { mutate: updateDietitian } = useUpdateClinicDietitianMutation(
    id ?? 0,
    { onSuccess: closeWithReset },
  );
  const { mutate: createDietitian } = useCreateClinicDietitianMutation({
    onSuccess: closeWithReset,
    onError: err => {
      if (err.response?.data.errors.email?.includes(TAKEN_EMAIL_ERROR))
        setError("email", { message: t("common.email_duplicate") });
    },
  });

  const mappedFacilities = useMemo(() => {
    return facilities?.length
      ? facilities?.map(facility => ({
          label: facility.name ?? "",
          id: facility.id.toString(),
        }))
      : [];
  }, [facilities]);

  const submit = useCallback(
    (data: DietitianFormInput) =>
      edit
        ? updateDietitian(mapUpdateDietitianRequest(data))
        : createDietitian(mapCreateDietitianRequest(data)),
    [
      edit,
      updateDietitian,
      createDietitian,
      mapUpdateDietitianRequest,
      mapCreateDietitianRequest,
    ],
  );

  return (
    <ModalWrapper
      open={open}
      title={
        edit
          ? t("dietitians_settings.modal_title_edit")
          : t("dietitians_settings.modal_title_add")
      }
      onClose={onClose}
      reset={reset}
      onSubmit={handleSubmit(submit, e => console.log(e))}
    >
      <FormTextField
        control={control}
        name="name"
        size="small"
        label={t("dietitians_settings.name")}
        variant="outlined"
      />
      <FormTextField
        control={control}
        name="lastname"
        size="small"
        label={t("dietitians_settings.lastname")}
        variant="outlined"
      />
      <FormTextField
        control={control}
        name="email"
        size="small"
        label={t("dietitians_settings.email")}
        variant="outlined"
        disabled={edit}
      />
      <div className="flex gap-5">
        <StyledFormCountryAutocomplete
          control={control}
          name="prefix"
          id="prefix"
          size="small"
          label={t("dietitians_settings.prefix")}
          flex={1}
        />
        <StyledPhoneTextField
          control={control}
          name="phone"
          size="small"
          label={t("dietitians_settings.phone")}
          variant="outlined"
          flex={3}
        />
      </div>
      <FormAutocomplete
        control={control}
        name="locationId"
        id="locationId"
        size="small"
        popupIcon={<CheckedIcon fill={palette.primary.main} />}
        className="w-full"
        options={mappedFacilities}
        label={t("dietitians_settings.location")}
      />
    </ModalWrapper>
  );
};
