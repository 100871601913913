import { useMemo } from "react";

import { AddressDto } from "@client";
import { mapAddressArray } from "@utils/settings";

interface AddressCellProps {
  address: AddressDto;
}

export const AddressCell = ({ address }: AddressCellProps) => {
  const addressArray = useMemo(
    () => mapAddressArray({ ...address, postcode: address.postCode }),
    [address, mapAddressArray],
  );

  return (
    <div className="flex flex-wrap w-full gap-1">
      {addressArray.map(data => (
        <span key={data.toString()} className={"truncate"}>
          {data}
        </span>
      ))}
    </div>
  );
};
