import styled from "@emotion/styled";

export const TitleBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.tableBorder};
  padding: 0.5rem 1rem;
  height: 3.5rem;
  cursor: pointer;
`;
