import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import {
  useFetchMonitoringMeasurementLast,
  useFetchMonitoringMeasurements,
} from "@hooks/queries/client/monitoring";
import {
  mapSelectedDaysByType,
  mapSelectedDaysByTypeDayToRequestQueryParams,
  mapSelectedDaysToRequestQueryParams,
} from "@views/dietician/PatientMonitoring2/PatientMonitoringUtils";
import { useMemo } from "react";
import {
  getFirstMeasurement,
  getLastMeasurement,
} from "@views/dietician/PatientMonitoring2/components/MeasurementsBox/Compare/MeasurementCompareUtils";
import MeasurementCompareItems from "@views/dietician/PatientMonitoring2/components/MeasurementsBox/Compare/MeasurementCompareItems";

const MeasurementsLastCompare = () => {
  const { patientId, selectedDays, type } = useMonitoring();

  const { data } = useFetchMonitoringMeasurements({
    patientId,
    ...mapSelectedDaysByType(type.type, selectedDays.selectDays),
  });
  const { data: lastData } = useFetchMonitoringMeasurementLast({ patientId });

  const firstMeasurement = useMemo(
    () => getFirstMeasurement(data?.data ?? []),
    [data],
  );
  const lastMeasurement = useMemo(
    () => getLastMeasurement(lastData?.data ? [lastData.data] : []),
    [lastData],
  );

  if (firstMeasurement === null || lastMeasurement === null) {
    return null;
  }

  if (
    firstMeasurement.measurements === null ||
    lastMeasurement.measurements === null
  ) {
    return null;
  }

  return (
    <MeasurementCompareItems
      first={firstMeasurement.measurements}
      second={lastMeasurement.measurements}
    />
  );
};

export default MeasurementsLastCompare;
