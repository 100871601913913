import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useAppTranslation } from "@hooks";
import { MutationOptions } from "../types";
import { fetchClinicProductsQueryKey } from "../useFetchClinicProducts";
import {
  CreateClinicProductRequest,
  createClinicProduct,
} from "@client/settings";
import { toast } from "react-toastify";

export const useCreateClinicProductMutation = (options?: MutationOptions) => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, CreateClinicProductRequest>(
    payload => createClinicProduct(payload),
    {
      ...options,
      onSuccess: () => {
        toast.success(t("products_settings.product_added"));
        queryClient.invalidateQueries([fetchClinicProductsQueryKey]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};
