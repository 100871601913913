import { Dispatch, SetStateAction, useMemo } from "react";

import { Autocomplete, Chip, TextField } from "@mui/material";
import { compact, uniq } from "lodash";

import { Label, Wrapper } from "./AutocompleteFilter.styled";
import { OptionIf } from "../FiltersBuilder";
import {
  FieldValues,
  Path,
  useController,
  useFormContext,
} from "react-hook-form";

export interface MultiAutocompleteFilterProps<T extends FieldValues> {
  label: string;
  options: OptionIf[];
  placeholder: string;
  name: Path<T>;
}

export const MultiAutocompleteFilter = <T extends FieldValues>({
  label,
  options,
  placeholder,
  name,
}: MultiAutocompleteFilterProps<T>) => {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({ control, name });

  const selectedValues = useMemo(() => {
    if (!value || !Array.isArray(value)) {
      return [];
    }

    return options
      .filter(v => value.includes(v.id))
      .sort((a, b) => value.indexOf(a.id) - value.indexOf(b.id));
  }, [options, value]);

  return (
    <Wrapper>
      <Label>{label}</Label>
      <Autocomplete
        size="small"
        options={options}
        value={selectedValues}
        onChange={(e, value) => {
          onChange([...value.map(v => v.id)]);
        }}
        multiple
        renderInput={params => (
          <TextField {...params} placeholder={placeholder} />
        )}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => (
            <Chip
              size="small"
              color="primary"
              {...getTagProps({ index })}
              key={option.id}
              label={option.label}
            />
          ));
        }}
      />
    </Wrapper>
  );
};
