import { useMemo } from "react";

import _ from "lodash";

import {
  foodMeasuresQueryKey,
  useFoodMeasuresQuery,
} from "@hooks/queries/dictionaries";
import { useAppTranslation } from "@hooks/useAppTranslation";
import { useQueryClient } from "@tanstack/react-query";
import { FoodMeasure } from "@typeDefinitions";

export const useMeasures = () => {
  const queryClient = useQueryClient();
  const cacheData = queryClient.getQueryData<FoodMeasure[]>([
    foodMeasuresQueryKey,
  ]);
  const { data } = useFoodMeasuresQuery({ enabled: !cacheData });
  const measures = cacheData ?? data;

  const { isPolishChosen } = useAppTranslation();

  const measuresDict = useMemo<Record<string, DictOptionIf>>(() => {
    return _(measures)
      .map(measure => ({
        id: measure.id,
        name: isPolishChosen ? measure.description : measure.descriptionEn,
      }))
      .groupBy("id")
      .mapValues(measures => measures[0])
      .value();
  }, [measures, isPolishChosen]);

  const measureOptions = useMemo(
    () =>
      measures?.map(measure => ({
        id: measure.id.toString(),
        label: isPolishChosen ? measure.description : measure.descriptionEn,
      })),
    [measures, isPolishChosen],
  );

  const getMeasure = (id: number | string): DictOptionIf | undefined => {
    if (measuresDict[id]) return measuresDict[id];
  };

  return {
    measuresDict,
    measureOptions,
    getMeasure,
  };
};

interface DictOptionIf {
  id: number;
  name: string;
}
