import { styled } from "@mui/material";
import { LARGE_DESKTOP_MAX_CONTENT_WIDTH } from "@utils";

export const TitleStyled = styled("span")`
  display: flex;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;

export const PageLayoutWrapper = styled("div")`
  margin: 0 auto;
  width: 100%;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.laptop}px) {
    max-width: ${LARGE_DESKTOP_MAX_CONTENT_WIDTH};
  }
`;

export const TitleWrapper = styled("div")`
  display: flex;
  gap: 4px;
  align-items: center;
`;
