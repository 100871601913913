import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Checklist = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      d="M8.75422 5.44571C9.06357 5.7238 9.08857 6.19563 8.81047 6.50498L6.56069 9.00473C6.4232 9.15784 6.22947 9.24846 6.02324 9.25158C5.81701 9.25471 5.62015 9.17659 5.47329 9.03286L4.22029 7.78298C3.92969 7.48926 3.92969 7.0143 4.22029 6.72058C4.51089 6.42686 4.98897 6.42686 5.27956 6.72058L5.97012 7.41114L7.69183 5.49882C7.96992 5.18948 8.44175 5.16448 8.7511 5.44258L8.75422 5.44571ZM8.75422 10.4452C9.06357 10.7233 9.08857 11.1951 8.81047 11.5045L6.56069 14.0042C6.4232 14.1574 6.22947 14.248 6.02324 14.2511C5.81701 14.2542 5.62015 14.1761 5.47329 14.0324L4.22029 12.7825C3.92657 12.4888 3.92657 12.0138 4.22029 11.7232C4.51401 11.4326 4.98897 11.4295 5.27956 11.7232L5.97012 12.4138L7.69183 10.5015C7.96992 10.1921 8.44175 10.1671 8.7511 10.4452H8.75422ZM11.0009 7.25178C11.0009 6.69871 11.4477 6.25188 12.0008 6.25188H19.0001C19.5532 6.25188 20 6.69871 20 7.25178C20 7.80485 19.5532 8.25168 19.0001 8.25168H12.0008C11.4477 8.25168 11.0009 7.80485 11.0009 7.25178ZM11.0009 12.2513C11.0009 11.6982 11.4477 11.2514 12.0008 11.2514H19.0001C19.5532 11.2514 20 11.6982 20 12.2513C20 12.8044 19.5532 13.2512 19.0001 13.2512H12.0008C11.4477 13.2512 11.0009 12.8044 11.0009 12.2513ZM9.00107 17.2508C9.00107 16.6977 9.44791 16.2509 10.001 16.2509H19.0001C19.5532 16.2509 20 16.6977 20 17.2508C20 17.8039 19.5532 18.2507 19.0001 18.2507H10.001C9.44791 18.2507 9.00107 17.8039 9.00107 17.2508ZM5.50142 15.7509C5.8992 15.7509 6.2807 15.909 6.56197 16.1902C6.84325 16.4715 7.00127 16.853 7.00127 17.2508C7.00127 17.6486 6.84325 18.0301 6.56197 18.3114C6.2807 18.5926 5.8992 18.7507 5.50142 18.7507C5.10363 18.7507 4.72214 18.5926 4.44086 18.3114C4.15958 18.0301 4.00156 17.6486 4.00156 17.2508C4.00156 16.853 4.15958 16.4715 4.44086 16.1902C4.72214 15.909 5.10363 15.7509 5.50142 15.7509Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
