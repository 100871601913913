import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  styled,
  Typography,
} from "@mui/material";
import { useTasksContext } from "../Tasks.context";
import { IconButton } from "@components/IconButton";
import { Close } from "@assets/icons";
import { useSingleTaskQuery } from "@hooks/queries/tasks/useSingleTaskQuery";
import { Button } from "@components/Button";
import { useDeleteTaskMutation } from "@hooks/queries/tasks/useDeleteTaskMutation";
import { useAppTranslation } from "@hooks";

const StyledTaskDeleteSection = styled("section")(({ theme }) => ({
  display: "flex",
  marginTop: "1.5rem",
  gap: "0.25rem",
  padding: "1rem",
  justifyContent: "flex-end",
  backgroundColor: theme.colors.primaryLightNew,
}));

const TaskDeleteModalStyledButton = styled(Button)`
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 8px;
`;

export const TaskDeleteModal = () => {
  const { t } = useAppTranslation();
  const {
    handleCloseDeleteModal,
    isDeleteModalOpen,
    currentTaskId,
    handleCloseSidePanel,
  } = useTasksContext();

  const { data } = useSingleTaskQuery(currentTaskId ?? "");

  const { handleDeleteTask } = useDeleteTaskMutation();

  const handleConfirmModalDeletion = (id: string | null) => {
    if (id) {
      handleDeleteTask(id);
      handleCloseSidePanel();
      handleCloseDeleteModal();
    }
  };

  return (
    <Dialog
      open={isDeleteModalOpen}
      onClose={handleCloseDeleteModal}
      fullWidth
      className="max-w-2xl mx-auto"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        paddingX={1}
      >
        <DialogTitle id="task-delete-dialog-title">
          {t("tasks.taskDeleteModal.title")}
        </DialogTitle>
        <IconButton onClick={handleCloseDeleteModal}>
          <Close className="text-gray-450" />
        </IconButton>
      </Box>
      <DialogContent sx={{ padding: 0 }}>
        <Box paddingX={3} paddingY={1}>
          <Typography variant="body1">
            <span>{t("tasks.taskDeleteModal.confirmation")}</span>
            <span className="font-semibold ml-1">{data?.name}</span>
            <span>?</span>
          </Typography>
        </Box>
        <StyledTaskDeleteSection>
          <TaskDeleteModalStyledButton
            color="secondary-purple"
            size="large"
            onClick={handleCloseDeleteModal}
            aria-label={t("tasks.taskDeleteModal.cancelButton")}
          >
            {t("tasks.taskDeleteModal.cancelButton")}
          </TaskDeleteModalStyledButton>
          <TaskDeleteModalStyledButton
            color="danger"
            className="py-2 px-3 text-base"
            onClick={() => handleConfirmModalDeletion(currentTaskId)}
          >
            {t("tasks.taskDeleteModal.deleteButton")}
          </TaskDeleteModalStyledButton>
        </StyledTaskDeleteSection>
      </DialogContent>
    </Dialog>
  );
};
