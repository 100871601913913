import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const updateClientPayments = async (
  id: string,
  active: UpdateClientPaymentsDto,
) => {
  return await fetchData(
    `/dietitian/patients/${id}/active`,
    APIMethods.PATCH,
    active,
  );
};

export interface UpdateClientPaymentsDto {
  isActive: boolean;
  activeUntil: string | null;
}
