import { styled } from "@mui/material";

export const ActionButtonsWrapper = styled("div")`
  display: grid;
  place-items: center;
  grid-template-columns: repeat(3, minmax(0, 1fr));

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.tablet}px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
`;
