import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import {
  IdDto,
  MeasureDto,
  NutrientDto,
  idSchema,
  measureSchema,
  nutrientSchema,
} from "@client/common";

import {
  TagResourceDto,
  TranslationDto,
  tagResourceSchema,
  translationSchema,
} from "./searchDietsPreviewDiet";

export const searchDietsPreviewMeal = async (
  dietId: string,
  mealId: string,
): Promise<ApiResponse<DietMealSearchPreviewResourceDto>> => {
  const response = await fetchData(
    `/dietitian/diets/search-preview/${dietId}/meals/${mealId}`,
    APIMethods.GET,
  );

  return apiResponseSchema.validate(response);
};

export const imageSchema = yup.object({
  url: yup.string().required(),
});

export const foodItemSearchPreviewResourceSchema = yup.object({
  id: yup.number().required(),
  name: yup.string().required(),
  translations: yup.array().of(translationSchema.required()).required(),
  nutrients: yup.array().of(nutrientSchema.required()).required(),
  image: imageSchema.nullable().default(null),
});

export const foodWrapperSchema = yup.object({
  id: yup.number().required(),
  grams: yup.number().required(),
  measure: idSchema.required(),
  product: foodItemSearchPreviewResourceSchema.required(),
});

export const recipeItemSearchPreviewResourceSchema = yup.object({
  id: yup.number().required(),
  name: yup.string().required(),
  translations: yup.array().of(translationSchema.required()).required(),
  nutrients: yup.array().of(nutrientSchema.required()).required(),
  tags: yup.array().of(tagResourceSchema.required()).required(),
  image: imageSchema.nullable().default(null),
  servings: yup.number().required(),
});

export const recipeWrapperSchema = yup.object({
  id: yup.number().required(),
  servings: yup.number().required(),
  recipe: recipeItemSearchPreviewResourceSchema.required(),
});

const dietMealSearchPreviewResourceSchema = yup.object({
  id: yup.number().required(),
  name: yup.string().required(),
  translations: yup.array().of(translationSchema.required()).required(),
  nutrients: yup.array().of(nutrientSchema.required()).required(),
  recipes: yup.array().of(recipeWrapperSchema.required()).required(),
  products: yup.array().of(foodWrapperSchema.required()).required(),
});

const apiResponseSchema = yup.object({
  data: dietMealSearchPreviewResourceSchema.required(),
});

export interface DietMealSearchPreviewResourceDto {
  id: number;
  name: string;
  translations: TranslationDto[];
  nutrients: NutrientDto[];
  recipes: RecipeWrapperDto[];
  products: FoodWrapperDto[];
}

export interface RecipeItemSearchPreviewResourceDto {
  id: number;
  name: string;
  servings: number;
  translations: TranslationDto[];
  nutrients: NutrientDto[];
  tags: TagResourceDto[];
  image: ImageUrl | null;
}

export interface RecipeWrapperDto {
  id: number;
  servings: number;
  recipe: RecipeItemSearchPreviewResourceDto;
}

export interface FoodItemSearchPreviewResourceDto {
  id: number;
  name: string;
  translations: TranslationDto[];
  nutrients: NutrientDto[];
  image: ImageUrl | null;
}

export interface FoodWrapperDto {
  id: number;
  grams: number;
  measure: IdDto;
  product: FoodItemSearchPreviewResourceDto;
}

export interface ImageUrl {
  url: string;
}
