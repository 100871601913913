import { useEffect, useState } from "react";
import { QuestionFormInputs } from "../Question/useQuestionForm";
import { useForm } from "react-hook-form";
import { QuestionCheckboxDto, QuestionRadioDto } from "@client/surveys";

export const useOptionsWithOtherConfigForm = (
  values?: OptionsWithOtherConfigFormInputs,
) => {
  const [init, setInit] = useState(true);
  const form = useForm<OptionsWithOtherConfigFormInputs>({
    defaultValues: values ?? {
      title: "",
      description: null,
      required: false,
      options: [],
    },
  });

  useEffect(() => {
    if (init && values) {
      form.reset(values);
      setInit(false);
    }
  }, [values]);

  return form;
};

export interface OptionsWithOtherConfigFormInputs extends QuestionFormInputs {
  options: OptionIf[];
  other: OptionIf | null;
}

interface OptionIf {
  name: string;
  uuid: string;
}

export const mapOptionsWithOtherConfigForm = ({
  title,
  description,
  config,
}:
  | QuestionRadioDto
  | QuestionCheckboxDto): OptionsWithOtherConfigFormInputs => {
  return {
    title,
    description,
    required: config.required,
    options: config.options.map(({ name, uuid }) => ({ name, uuid })),
    other: config.other,
  };
};

export const mapOptionsWithOtherConfigRequest = (
  { title, description, ...rest }: OptionsWithOtherConfigFormInputs,
  { id, type }: QuestionRadioDto | QuestionCheckboxDto,
): QuestionRadioDto | QuestionCheckboxDto => {
  return {
    id,
    title,
    description,
    config: { ...rest },
    type,
  };
};
