import { styled } from "@mui/material";

export const ContentStyled = styled("div")`
  background: ${({ theme }) => theme.palette.primary.light};
  min-height: 100%;
`;

export const TitleStyled = styled("div")`
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.25rem;
  padding: 1rem;
  margin: 0;
  box-shadow: ${({ theme }) => theme.boxShadows.tooltip};
`;

export const ContentWrapper = styled("span")`
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  max-width: 62.375rem;
  margin: 0 auto;
  padding: 2.5rem 0;
`;
