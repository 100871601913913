import { forwardRef, useState, useEffect, ChangeEvent } from "react";

import classNames from "classnames";

/**
 * universal switch component
 */
export const Switch = forwardRef<HTMLInputElement, SwitchProps>(
  (props, ref) => {
    const { disabled, defaultValue, value, className, ...rest } = props;

    const [isOn, setIsOn] = useState<boolean>(defaultValue || value || false);

    useEffect(() => {
      if (defaultValue) setIsOn(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
      if (value) setIsOn(value);
    }, [value]);

    return (
      <div
        className={classNames(
          {
            "w-10 h-5 rounded-full flex transition duration-300 ease-in-out relative":
              true,
            "bg-gray-400 justify-start": !isOn,
            "bg-primary-light justify-end": isOn,
            "cursor-pointer": !disabled,
            "cursor-not-allowed": disabled,
          },
          className,
        )}
        onClick={!disabled ? () => setIsOn(!isOn) : undefined}
      >
        <input
          ref={ref}
          type="checkbox"
          checked={isOn}
          disabled={disabled}
          className="absolute w-full h-full opacity-0 z-20"
          {...rest}
        />
        <div
          className={classNames({
            "dot absolute left-0.5 top-0.5 w-4 h-4 rounded-full transition duration-600 ease-in-out z-10":
              true,
            "bg-white": !isOn,
            "bg-primary": isOn,
            "bg-gray-200": disabled,
          })}
        />
      </div>
    );
  },
);

export interface SwitchProps {
  /** name attr in input element */
  name?: string;
  /** value of input */
  value?: boolean;
  /** onChange function */
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  /** checks if element is clickable */
  disabled?: boolean;
  /** default value of switch */
  defaultValue?: boolean;
  /** additional class names */
  className?: string;
}
