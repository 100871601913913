import { TabPanel } from "@mui/lab";
import { Card, Divider, styled } from "@mui/material";

export const WrapperStyled = styled("div")`
  display: grid;
  gap: 16px;
`;

export const DividerStyled = styled(Divider)`
  border-color: ${({ theme }) => theme.colors.neutral.light[200]};
`;

export const TabPanelStyled = styled(TabPanel)`
  padding: unset;
`;
