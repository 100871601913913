import { ReactSortable } from "react-sortablejs";
import { useDebounce } from "react-use";

import { IconButton, Tooltip } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";

import { TrashNew } from "@assets/icons";
import { FullSurveyDto } from "@client/surveys";
import { FormTextField } from "@components/FormTextField";
import { OuterLabel } from "@components/OuterLabel";
import { useAppParams, useAppTranslation, useModal } from "@hooks";
import {
  fetchSurveyFullQueryKey,
  useUpdateSurveySubgroupMutation,
} from "@hooks/queries/surveys";
import { useDeleteSurveySubgroupMutation } from "@hooks/queries/surveys/useDeleteSurveySubgroupMutation";
import { useUpdateSurveySubgroupQuestionsSortMutation } from "@hooks/queries/surveys/useUpdateSurveySubgroupQuestionsSortMutation";
import { ApiResponse } from "@typeDefinitions";
import { detectMovedElement } from "@views/dietician/product-views/components/ProductMeasures/utils/move";

import { TableOfContentsContext } from "@context/TableOfContentsContext";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { QuestionFactory } from "../../Questions/QuestionFactory";
import { ConfirmationModal } from "@components/ConfirmationModal";
import { QuestionnaireCardWrapper } from "./QuestionnaireCardWrapper";
import { SubgroupCardWrapper } from "./SubgroupCard.styled";
import { useCardForm } from "./useCardForm";
import { AddQuestionButton } from "../../QuestionnaireEdit";

interface SubgroupCardProps {
  groupId: number | null;
  id: number | null;
}
export const SubgroupCard = ({ groupId, id }: SubgroupCardProps) => {
  const { t } = useAppTranslation();
  const { questionnaireId } = useAppParams();
  const { setSubgroupId, setSelectedId, getNearSubgroupId } = useContext(
    TableOfContentsContext,
  );

  const queryClient = useQueryClient();
  const data = queryClient.getQueryData<ApiResponse<FullSurveyDto>>([
    fetchSurveyFullQueryKey,
    questionnaireId,
  ]);
  const groupData = data?.data.groups.find(g => g.id === groupId);
  const subgroupData = useMemo(
    () => groupData?.sections.find(s => s.id === id),
    [groupData?.sections, id],
  );
  const questionsMapped = subgroupData?.questions ?? [];
  const isLastSubgroup = groupData?.sections.length === 1;

  const [questionsLength, setQuestionsLength] = useState(
    questionsMapped.length,
  );

  const {
    control,
    watch,
    formState: { isDirty },
    handleSubmit,
  } = useCardForm(subgroupData);
  const { mutate: deleteSubgroup } = useDeleteSurveySubgroupMutation({
    onSuccess: () => {
      const nextSubgroupId = getNearSubgroupId(id);
      setSubgroupId(nextSubgroupId);
      setSelectedId(`${groupId}-${nextSubgroupId}`);
    },
  });
  const { mutate: updateSort } = useUpdateSurveySubgroupQuestionsSortMutation();
  const { mutate: updateSubgroup } = useUpdateSurveySubgroupMutation();

  const handleDelete = () => {
    if (!isLastSubgroup && !!id) deleteSubgroup(id.toString());
  };

  const { modalOpened, onModalOpen, onModalClose } = useModal();

  useDebounce(
    () => {
      if (isDirty && id)
        handleSubmit(
          data => updateSubgroup({ id: id.toString(), payload: data }),
          e => console.log(e),
        )();
    },
    1000,
    [JSON.stringify(watch())],
  );

  useEffect(() => {
    setQuestionsLength(questionsMapped.length);

    if (questionsLength < questionsMapped.length) scrollOnSuccess();
  }, [questionsMapped.length]);

  const scrollOnSuccess = () =>
    ref.current?.scrollIntoView({ behavior: "smooth" });

  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <SubgroupCardWrapper>
      <QuestionnaireCardWrapper
        title={t("questionnaires.subgroup")}
        actions={
          <Tooltip
            title={t("questionnaires.cant_delete_subgroup")}
            disableHoverListener={!isLastSubgroup}
            arrow
          >
            <div>
              <IconButton
                color="primary"
                onClick={onModalOpen}
                disabled={isLastSubgroup}
              >
                <TrashNew />
              </IconButton>
            </div>
          </Tooltip>
        }
      >
        <OuterLabel label={t("questionnaires.subgroups.title")}>
          <FormTextField
            control={control}
            name="title"
            size="small"
            fullWidth
          />
        </OuterLabel>
        <OuterLabel label={t("questionnaires.subgroups.desc")}>
          <FormTextField
            multiline
            control={control}
            name="description"
            size="small"
            placeholder={t("questionnaires.subgroups.desc_placeholder")}
            fullWidth
          />
        </OuterLabel>
        <ConfirmationModal
          open={modalOpened}
          onCancel={onModalClose}
          onConfirm={handleDelete}
          confirmText={t("questionnaires.delete_modal_subgroup.button")}
          description={t("questionnaires.delete_modal_subgroup.desc", {
            variable: watch("title"),
          })}
          title={t("questionnaires.delete_modal_subgroup.title")}
        />
      </QuestionnaireCardWrapper>
      {!!questionsMapped.length && (
        <ReactSortable
          forceFallback
          scrollSensitivity={100}
          animation={200}
          handle=".dragHandle"
          list={questionsMapped}
          setList={newOrder => {
            const result = detectMovedElement(
              questionsMapped.map(l => l.id.toString()),
              newOrder.map(n => n.id.toString()),
            );

            if (result && id)
              updateSort({
                id: id.toString(),
                payload: { ids: newOrder.map(g => g.id) },
              });
          }}
          className="grid gap-8"
        >
          {questionsMapped.map((question, idx) => (
            <QuestionFactory
              key={`${id}-${question.id}`}
              listIndex={idx}
              totalQuestions={questionsMapped.length}
              question={question}
              questionsSort={questionsMapped.map(q => q.id)}
              autoFocus={
                idx + 1 === questionsMapped.length &&
                questionsLength < questionsMapped.length
              }
              questionnaireLang={data?.data.langId}
            />
          ))}
        </ReactSortable>
      )}

      {id && (
        <AddQuestionButton
          subgroupId={id}
          questionnaireLang={data?.data.langId}
        />
      )}

      <div ref={ref} className="-mb-8" />
    </SubgroupCardWrapper>
  );
};
