import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const updateClinicBillingData = async (
  payload: UpdateClinicBillingDataRequest,
) => {
  return await fetchData(
    "/dietitian/clinic/billing-data",
    APIMethods.PUT,
    payload,
  );
};

export interface UpdateClinicBillingDataRequest {
  name: string | null;
  email: string | null;
  vatId: string | null;
  phone: PhoneDto;
  address: AddressDto;
}

interface PhoneDto {
  prefix: string | null;
  number: string | null;
}

interface AddressDto {
  street: string | null;
  streetNumber: string | null;
  houseNumber: string | null;
  postCode: string | null;
  city: string | null;
}
