import { TasksWrapper } from "./Tasks.styled";

import { TasksPreviewPanel } from "./components/TasksPreviewPanel";

import { TaskDeleteModal } from "./components/TaskDeleteModal";
import { TasksPanel } from "./components/TasksPanel";
import { TasksSuggestedPanel } from "./components/TasksSuggested/TasksSuggestedPanel";

export const Tasks = () => {
  return (
    <TasksWrapper>
      <TasksPanel />
      <TasksSuggestedPanel />
      <TaskDeleteModal />
      <TasksPreviewPanel />
    </TasksWrapper>
  );
};
