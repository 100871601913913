import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export async function updatePatientNote(
  patientId: number,
  payload: NotePayload,
): Promise<ApiResponse<boolean>> {
  return fetchData(
    `/dietitian/patients/${patientId}/note`,
    APIMethods.PATCH,
    payload,
  );
}

export interface NotePayload {
  note: string;
}
