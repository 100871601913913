import { round } from "lodash";
import { ViewType } from "../Pricing";
import {
  ActiveClients,
  BuyButton,
  CardStyled,
  Description,
  DividerStyled,
  FeatureSection,
  PriceGross,
  PriceNet,
  Title,
} from "./common";
import { MONTHS, VAT_PERCENTAGE } from "./consts";

interface StarterCardProps {
  priceVariant: ViewType;
  showVat: boolean;
  onClickBuy: () => void;
  disabled?: boolean;
}

export const STARTER_ANNUALLY_PRICE = 1164;
export const STARTER_MONTHLY_PRICE = 129;

export const StarterCard = ({
  priceVariant,
  showVat,
  onClickBuy,
  disabled,
}: StarterCardProps) => {
  const ACTIVE_CLIENTS = 10;
  const FEATURES = [
    { label: "Inteligentny kreator diet" },
    { label: "Monitoring i analiza postępów" },
    { label: "Interaktywna aplikacja mobilna" },
    { label: "Angielska wersja językowa" },
  ];

  const isAnnually = priceVariant === ViewType.annually;
  const priceMonthly = isAnnually
    ? STARTER_ANNUALLY_PRICE / MONTHS
    : STARTER_MONTHLY_PRICE;

  const withVat = (price: number) =>
    showVat ? round(price + price * VAT_PERCENTAGE, 2) : price;

  return (
    <CardStyled>
      <div className="flex flex-col gap-[24px]">
        <div className="h-[180px] flex flex-col gap-[24px]">
          <div className="flex flex-col gap-[16px]">
            <Title>Starter</Title>
            <Description>
              Dla dietetyków, którzy chcą zacząć pracować nowocześnie.
            </Description>
          </div>

          <div className="flex flex-col gap-[8px]">
            <PriceNet value={withVat(priceMonthly)} />
            {isAnnually && (
              <PriceGross
                value={withVat(STARTER_ANNUALLY_PRICE)}
                grossPrice={showVat}
                view={ViewType.annually}
              />
            )}
          </div>
        </div>

        <BuyButton onClick={onClickBuy} disabled={disabled} />

        <DividerStyled />

        <ActiveClients value={ACTIVE_CLIENTS} />

        <DividerStyled />

        <FeatureSection features={FEATURES} title="Dostępne funkcje:" />
      </div>
    </CardStyled>
  );
};
