import { useQuery } from "@tanstack/react-query";
import { QueryOptions } from "../types";
import { buildDefaultQueryOptions } from "../common";
import { fetchVisitBoxConfig } from "@client/visits";

export const fetchVisitBoxConfigQueryKey = "fetchBoxConfigQuery";

export const useFetchVisitBoxConfigQuery = (options?: QueryOptions) => {
  const { data, ...rest } = useQuery(
    [fetchVisitBoxConfigQueryKey],
    () => fetchVisitBoxConfig(),
    buildDefaultQueryOptions(options),
  );

  return { boxConfig: data, ...rest };
};
