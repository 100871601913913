import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MutationOptions } from "../types";
import { fetchUsedRecipesQueryKey } from "./useFetchUsedRecipesQuery";
import { fetchDietitianProgramScheduleKey } from "./useFetchDietitianProgramScheduleQuery";
import { fetchDietitianClientProgramScheduleKey } from "./useFetchDietitianClientProgramScheduleQuery";
import {
  ProgramDayVariantPayload,
  updateProgramDayVariant,
} from "@client/schedule";

export const useProgramDayVariantMutation = (
  programId: number,
  dayId: number,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: ProgramDayVariantPayload) =>
      updateProgramDayVariant(programId, dayId, payload),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries([fetchUsedRecipesQueryKey]);
        queryClient.invalidateQueries([fetchDietitianProgramScheduleKey]);
        queryClient.invalidateQueries([fetchDietitianClientProgramScheduleKey]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};
