import { OuterLabel } from "@components/OuterLabel";
import { useAppTranslation } from "@hooks";
import { QuestionTextDto, TextAnswerResourceDto } from "@client/surveys";

import { QuestionPreview } from "../../QuestionPreview/QuestionPreview";
import { TextStyled } from "../../QuestionPreview/QuestionPreview.styled";

interface AnswerTextProps {
  question: QuestionTextDto;
  answer?: TextAnswerResourceDto;
  listIndex: number;
  totalQuestions: number;
}

export const AnswerText = ({
  answer,
  question,
  listIndex,
  totalQuestions,
}: AnswerTextProps) => {
  const { title, description } = question;
  const { t } = useAppTranslation();

  return (
    <QuestionPreview
      title={title}
      description={description}
      listIndex={listIndex}
      totalQuestions={totalQuestions}
    >
      <OuterLabel label={t("questionnaires.questions.answer")}>
        <TextStyled>
          {answer?.answer.value ?? t("questionnaires.questions.no_answer")}
        </TextStyled>
      </OuterLabel>
    </QuestionPreview>
  );
};
