import {
  UpdateClientMobileAppRequest,
  updateClientMobileAppAccess,
} from "@client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  MutationOptions,
  fetchClientQueryKey,
  fetchClientsQueryKey,
} from "./queries";
import { fetchClientEventsQueryKey } from "./queries/useFetchClientEventsQuery";
import { searchClientsQueryKey } from "./queries/settings";

export const useUpdateClientMobileAppAccess = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, UpdateClientMobileAppProps>(
    ({ id, payload }) => updateClientMobileAppAccess(id, payload),
    {
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries([fetchClientQueryKey, Number(id)]);
        queryClient.invalidateQueries([fetchClientEventsQueryKey, id]);
        queryClient.invalidateQueries([fetchClientsQueryKey]);
        queryClient.invalidateQueries([searchClientsQueryKey]);
        options?.onSuccess && options?.onSuccess();
      },
      ...options,
    },
  );
};

interface UpdateClientMobileAppProps {
  id: string;
  payload: UpdateClientMobileAppRequest;
}
