import React from "react";
import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Chats = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 46 46">
    <g id="_46-icon-chat" data-name="46-icon-chat" transform="translate(9 6)">
      <rect
        id="Rectangle_1170"
        data-name="Rectangle 1170"
        width="46"
        height="46"
        transform="translate(-9 -6)"
        opacity="0"
      />
      <path
        id="Combined_Shape"
        data-name="Combined Shape"
        d="M14.537,1.866a1.87,1.87,0,1,1,1.871,1.871A1.87,1.87,0,0,1,14.537,1.866Zm-7.268,0A1.869,1.869,0,1,1,9.138,3.737,1.87,1.87,0,0,1,7.269,1.866ZM0,1.866A1.87,1.87,0,1,1,1.869,3.737,1.87,1.87,0,0,1,0,1.866Z"
        transform="translate(11.242 15.489)"
      />
      <path
        id="Stroke_7"
        data-name="Stroke 7"
        d="M16.032-.75A16.528,16.528,0,0,1,27.961,4.267,16.838,16.838,0,0,1,31.476,9.6a16.573,16.573,0,0,1,1.274,6.381A16.965,16.965,0,0,1,31.3,22.957a16.1,16.1,0,0,1-3.816,5.29A17.15,17.15,0,0,1,16,32.75a16.707,16.707,0,0,1-8.253-2.187l-.01-.006a2.92,2.92,0,0,0-1.379-.569,1.208,1.208,0,0,0-.38.064l-.02.006-3.227.958a1.958,1.958,0,0,1-.581.09,1.735,1.735,0,0,1-1.4-.679,1.79,1.79,0,0,1-.275-1.577l0-.014,1.072-3.589.011-.035a.939.939,0,0,0-.044-.8L1.5,24.377A17.653,17.653,0,0,1-.75,16.024,16.669,16.669,0,0,1,.5,9.68,16.9,16.9,0,0,1,3.99,4.33,16.569,16.569,0,0,1,16.032-.75ZM16,31.25A15.25,15.25,0,0,0,16.032.75,15.3,15.3,0,0,0,.75,16.024a16.148,16.148,0,0,0,2.061,7.621,2.427,2.427,0,0,1,.175,2.048L1.921,29.258a.309.309,0,0,0,.025.263.309.309,0,0,0,.341.064l.011,0,3.223-.957a2.718,2.718,0,0,1,.837-.135,4.149,4.149,0,0,1,2.155.785A15.166,15.166,0,0,0,16,31.25Z"
        transform="translate(4 1)"
      />
    </g>
  </SvgIcon>
);
