import { Tabs } from "@mui/material";

import { ProgramDayTab } from "@components/WeekdaySelectorTab";

import { StyledTab, TabsContainer } from "./DayTabs.styled";

export interface DayTabProps {
  id: string;
  name: string;
  currentEnergy: number | null;
  targetEnergy: number | null;
  frequency: number[];
}

interface DayTabsProps {
  selected: number;
  onChange: (selected: number) => void;
  orientation?: "horizontal" | "vertical";
  days: DayTabProps[];
}

export const DayTabs = ({
  selected,
  onChange,
  orientation,
  days,
}: DayTabsProps) => {
  return (
    <TabsContainer>
      <Tabs
        color="prime"
        variant="scrollable"
        scrollButtons="auto"
        value={selected}
        onChange={(_, value) => onChange(value)}
        orientation={orientation}
      >
        {days.map(({ id, name, currentEnergy, targetEnergy, frequency }) => (
          <StyledTab
            key={id}
            label={
              <ProgramDayTab
                title={name}
                energy={currentEnergy}
                cpm={targetEnergy}
                weekdays={frequency}
              />
            }
          />
        ))}
      </Tabs>
    </TabsContainer>
  );
};
