import { TabSwitch } from "@components/TabSwitch/TabSwitch";
import { useState } from "react";
import { MealDetails } from "../MealDetails/MealDetails";

interface MealIf {
  id: string;
  label: string;
}

interface MealsTabProps {
  meals: MealIf[];
  dietId: number;
}
export const MealsTab = ({ meals, dietId }: MealsTabProps) => {
  const [value, setValue] = useState(meals[0].id);

  return (
    <div className="grid gap-[16px]">
      <div className="flex">
        <TabSwitch
          tabs={meals}
          onChange={setValue}
          value={value}
          variant="scrollable"
        />
      </div>
      <MealDetails dietId={dietId.toString()} mealId={value} />
    </div>
  );
};
