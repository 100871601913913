import { useEffect, useState } from "react";

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { MultiLanguageInput } from "@components/MultiLanguageInput";
import { useAppParams, useAppTranslation } from "@hooks";
import { usePostProgramDietToBaseMutation } from "@hooks/queries";

import { DialogStyled, InputWrapper } from "./DietToBaseDialog.styled";

interface DietToBaseDialogProps {
  open: boolean;
  onClose: () => void;
  dietName?: NamesIf;
}

export const DietToBaseDialog = ({
  open,
  onClose,
  dietName = { pl: null, en: null },
}: DietToBaseDialogProps) => {
  const { programId, dayId } = useAppParams();
  const { t } = useAppTranslation();
  const [names, setNames] = useState<NamesIf>(dietName);
  const [error, setError] = useState<string | null>(null);
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const { mutate, isLoading } = usePostProgramDietToBaseMutation();

  const handleNamesChange = (lang: string, value?: string) => {
    setError(null);
    setNames(state => ({ ...state, [lang as "pl" | "en"]: value ?? null }));
  };
  const handleShowName = (lang: string) => names[lang as "pl" | "en"] ?? "";
  const handleSubmit = () => {
    if (!names.pl) {
      setError(t("common.required_field"));
      setErrorFields(["pl"]);
      return;
    }

    mutate(
      {
        programId,
        programDayId: dayId,
        payload: { namePl: names.pl, nameEn: names.en },
      },
      { onSuccess: onClose },
    );
  };

  useEffect(() => {
    if (open) {
      setNames(dietName);
      setError(null);
    }
  }, [open]);

  return (
    <DialogStyled open={open} onClose={onClose} maxWidth="small">
      <DialogTitle>{t("program.days.name_diet")}</DialogTitle>
      <DialogContent>
        <InputWrapper>
          <MultiLanguageInput
            className="w-full"
            languages={Object.keys(names)}
            onChange={handleNamesChange}
            value={handleShowName}
            label={t("program.days.diet_name")}
            error={!!error}
            errorMessage={error ?? undefined}
            errorFields={errorFields}
          />
        </InputWrapper>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="inherit" onClick={onClose}>
          {t("common.cancel")}
        </Button>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={handleSubmit}
        >
          {t("program.days.add_diet_to_base")}
        </LoadingButton>
      </DialogActions>
    </DialogStyled>
  );
};

interface NamesIf {
  pl: string | null;
  en: string | null;
}
