import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Minus = (props: SvgIconProps) => {
  return (
    <SvgIcon width="13" height="2" viewBox="0 0 13 2" {...props}>
      <path
        d="M13 1C13 1.4375 12.6562 1.75 12.25 1.75H0.75C0.3125 1.75 0 1.4375 0 1C0 0.59375 0.3125 0.25 0.75 0.25H12.25C12.6562 0.25 13 0.59375 13 1Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
