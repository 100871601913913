import { Tooltip } from "@mui/material";

import { useAppTranslation, useNutrientDictionary } from "@hooks";
import { useVisibleNutrients } from "@context/VisibleNutrientsContext";
import {
  CARBS_ID,
  ENERGY_ID,
  FATS_ID,
  MACROS_ARRAY,
  PROTEIN_ID,
} from "@utils/macros";

import {
  StyledTableHeaderCell,
  TableCellTitle,
  TableRowStyled,
} from "./RecipeTableForm.styled";

interface TableHeaderRowProps {
  compact?: boolean;
  withTopBorder?: boolean;
}

export const TableHeaderRow = ({
  compact = false,
  withTopBorder = false,
}: TableHeaderRowProps) => {
  const { t, currentLanguage } = useAppTranslation();
  const { nutrients: visibleNutrients } = useVisibleNutrients();
  const visibleNutrientsMapped = visibleNutrients?.map(n => Number(n)) ?? [];
  const { nutrientDictionary } = useNutrientDictionary();
  const getNutrient = (id: number) =>
    nutrientDictionary[id] ? nutrientDictionary[id] : undefined;

  return (
    <TableRowStyled>
      <StyledTableHeaderCell
        width="53rem"
        minWidth="28rem"
        sticky
        withBorder={withTopBorder}
      >
        {!compact && t("recipe.ingredients_table.product")}
      </StyledTableHeaderCell>
      <StyledTableHeaderCell withBorder={withTopBorder}>
        {!compact && t("recipe.ingredients_table.amount")}
      </StyledTableHeaderCell>
      <StyledTableHeaderCell minWidth="15rem" withBorder={withTopBorder}>
        {!compact && t("recipe.ingredients_table.measure")}
      </StyledTableHeaderCell>
      <StyledTableHeaderCell textAlign="right" withBorder={withTopBorder}>
        {t("recipe.ingredients_table.grams")}
      </StyledTableHeaderCell>
      {MACROS_ARRAY.map(id => {
        const nutrient = getNutrient(id);
        return (
          <Tooltip title={nutrient?.name} key={id}>
            <TableCellTitle align="right" withBorder={withTopBorder}>
              {nutrientShortenNames[id][currentLanguage]}
            </TableCellTitle>
          </Tooltip>
        );
      })}
      {visibleNutrientsMapped.map(id => {
        const nutrient = getNutrient(id);
        return (
          <Tooltip title={nutrient?.name} key={id}>
            <TableCellTitle align="right" withBorder={withTopBorder}>
              {nutrient?.short}
            </TableCellTitle>
          </Tooltip>
        );
      })}
      <StyledTableHeaderCell withBorder={withTopBorder} />
    </TableRowStyled>
  );
};

const nutrientShortenNames = {
  [ENERGY_ID]: {
    pl: "Energ.",
    en: "Energ.",
  },
  [PROTEIN_ID]: {
    pl: "Białko",
    en: "Protein",
  },
  [FATS_ID]: {
    pl: "Tłuszcze",
    en: "Fats",
  },
  [CARBS_ID]: {
    pl: "Węgl.",
    en: "Carb.",
  },
};
