import { BalanceDayDto } from "@client";
import { sum } from "lodash";

export enum WeightGoal {
  LOSS = 0,
  GAIN = 1,
  MAINTAIN = 2,
}
export interface BalanceValuesReturn {
  value: number | null;
  frequency: number;
}

export function mapCpmValues(days: BalanceDayDto[]) {
  return days.map(day => ({
    value: day.energyDemand.cpm.value,
    frequency: day.frequency.length,
  }));
}

export function mapSuggestedValues(
  days: BalanceDayDto[],
  goal: WeightGoal,
  kcalDiff: number,
) {
  return days.map(day => ({
    value: calculateDailyKcal(day.energyDemand.cpm.value ?? 0, goal, kcalDiff),
    frequency: day.frequency.length,
  }));
}

export function calculateAverage(values: BalanceValuesReturn[]) {
  const selectedDays =
    values.map(v => v.frequency).reduce((prev, curr) => prev + curr, 0) || 1;
  return (
    sum(values.map(value => (value.value ?? 0) * value.frequency)) /
    selectedDays
  );
}

const calculateDailyKcal = (
  cpm: number,
  goal: WeightGoal,
  kcalDiff: number,
) => {
  if (cpm < 550) return 500;
  if (goal === WeightGoal.GAIN) return cpm + kcalDiff;
  if (goal === WeightGoal.LOSS && cpm - kcalDiff > 0) return cpm - kcalDiff;
  if (cpm - kcalDiff <= 0) return 0;
  else return cpm;
};
