import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from "@mui/material";

export const ModalWrapper = styled(DialogContent)`
  display: grid;
  gap: 1rem;
  padding: 1rem;
  width: 32rem;
`;

export const TitleWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled(DialogTitle)`
  padding: 0;
`;

export const CloseButton = styled(IconButton)`
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;

export const StyledDialogActions = styled(DialogActions)`
  padding: 0.75rem 1rem;
  background: ${({ theme }) => theme.palette.primary.light};
`;

export const CancelButton = styled(Button)`
  background: ${({ theme }) => theme.colors.neutral.light[100]};
  border: 1px solid ${({ theme }) => theme.palette.primary.main};

  &:hover {
    background: ${({ theme }) => theme.colors.neutral.light[100]};
  }
`;
