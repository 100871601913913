import {
  DietMealListItemCreatorDto,
  FetchCreatorDietResponseDto,
} from "@client/diets/creator";
import { useAppParams } from "@hooks";
import {
  fetchCreatorDietMealsQueryKey,
  fetchCreatorDietQueryKey,
} from "@hooks/queries/diets/creator";
import { useQueryClient } from "@tanstack/react-query";
import { ApiResponse } from "@typeDefinitions";
import { useMemo } from "react";

export const useMealData = (mealId: number) => {
  const { dietId } = useAppParams();
  const queryClient = useQueryClient();
  const meals = queryClient.getQueryData<
    ApiResponse<DietMealListItemCreatorDto[]>
  >([fetchCreatorDietMealsQueryKey, dietId]);
  const diet = queryClient.getQueryData<FetchCreatorDietResponseDto>([
    fetchCreatorDietQueryKey,
    dietId,
  ]);

  const dietLang = useMemo(
    () =>
      diet?.data.translations
        .filter(t => !!t.name)
        .map(t => t.langId as string) ?? [],
    [diet],
  );
  return {
    mealData: meals?.data.find(m => m.id === mealId),
    dietLang,
  };
};
