import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MutationOptions } from "./types";
import { UpdateClientTagsRequest, updateClientTags } from "@client";
import { fetchClientTagsQueryKey } from "./useFetchClientTags";
import { fetchClientFoodGroupPreferencesQueryKey } from "./useFetchClientFoodGroupPreferences";
import { AxiosError } from "axios";
import { ApiErrorIf } from "@typeDefinitions";

export const useUpdateClientTagsMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    AxiosError<ApiErrorIf>,
    UpdateClientTagsMutationPayload
  >(({ id, payload }) => updateClientTags(id, payload), {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([fetchClientTagsQueryKey]);
      queryClient.invalidateQueries([fetchClientFoodGroupPreferencesQueryKey]);
      options?.onSuccess && options.onSuccess(data, variables);
    },
    ...options,
  });
};

interface UpdateClientTagsMutationPayload {
  id: string;
  payload: UpdateClientTagsRequest;
}
