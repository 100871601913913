import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export async function syncCustomTags(
  request: SyncCustomTagRequest,
): Promise<void> {
  return fetchData(`/dietitian/custom-tags`, APIMethods.PUT, request);
}

export interface SyncCustomTagsItem {
  id: number | null;
  name: string;
  color: string;
}

export interface SyncCustomTagRequest {
  tags: SyncCustomTagsItem[];
}
