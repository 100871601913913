import React from "react";
import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Remove = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 10 10">
    <g id="close-icon" transform="translate(-420.939 -24.541)">
      <path
        id="Stroke_1"
        data-name="Stroke 1"
        d="M7.992,0,0,7.992"
        transform="translate(422.004 25.605)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Stroke_2"
        data-name="Stroke 2"
        d="M8,8,0,0"
        transform="translate(422 25.602)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  </SvgIcon>
);
