import { useQuery } from "@tanstack/react-query";
import { requestNutrientCategories } from "@client";

export const fetchNutrientCategoriesQueryKey =
  "fetchNutrientCategoriesQueryKey";

export const useFetchNutrientCategoriesQuery = (options?: {
  enabled: boolean;
}) => {
  const { data: nutrientCategories, ...rest } = useQuery(
    [fetchNutrientCategoriesQueryKey],
    requestNutrientCategories,
    { ...options, staleTime: Infinity, refetchOnMount: false },
  );
  return { nutrientCategories, ...rest };
};
