import { useMemo } from "react";

import { CircularProgress } from "@mui/material";

import { Nutrients } from "@components/Nutrients";
import { useFetchFoodNutrientsNewQuery } from "@hooks/queries/food";
import { scaleNutrients } from "@utils";

interface LoadingNutrientProps {
  productId: string;
  grams: number;
}
export const LoadingNutrient = ({ productId, grams }: LoadingNutrientProps) => {
  const { nutrients, isLoading } = useFetchFoodNutrientsNewQuery(productId);

  const scaledNutrients = useMemo(
    () => scaleNutrients(nutrients ?? [], grams),
    [nutrients, grams],
  );

  if (isLoading)
    return (
      <div className="w-full text-center">
        <CircularProgress />
      </div>
    );

  return <Nutrients values={scaledNutrients} />;
};
