import { styled } from "@mui/material";
import TextFieldMui from "@mui/material/TextField";

const TextField = styled(TextFieldMui)`
  border-radius: 8px;
  .MuiInputBase-root {
    height: unset;
    font: var(--font-figtree-medium);
    line-height: 24px;
    font-size: 14px;
  }
  .MuiInputBase-input {
    height: unset;
    padding: 8px 12px;
    font: var(--font-figtree-medium);
    line-height: 24px;
    font-size: 14px;
  }
` as typeof TextFieldMui;

export default TextField;
