import { Alert, styled } from "@mui/material";

export const AlertStyled = styled(Alert)`
  min-width: 9.438rem;
  color: ${({ theme }) => theme.palette.text.primary};
  padding: 0 0.75rem;
  flex: 1;

  & .MuiAlert-message {
    display: grid;
    justify-items: center;
    width: 100%;
  }

  & .MuiTypography-body1 {
    font-size: 0.8rem;
  }

  & .MuiTypography-h5 {
    font-size: 1.25rem;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.tablet}px) {
    & .MuiTypography-body1 {
      font-size: 1rem;
    }

    & .MuiTypography-h5 {
      font-size: 1.5rem;
    }
  }
`;

export const ForecastsWrapper = styled("div")`
  display: flex;
  gap: 1rem;
`;
