import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { ScheduleVisitModalLabel } from "../ScheduleVisitModal.styled";
import { MuiTimeDatePicker } from "@components/MuiTimeDatePicker";
import { useAppTranslation } from "@hooks";
import { useFormContext } from "react-hook-form";
import { AddEventModalInput } from "../_hooks/useScheduleVisitForm";
import { ChangeEvent, FC } from "react";
import { useWatch } from "react-hook-form";
import dayjs from "dayjs";

interface ScheduleTimeSectionProps {
  isVisitTabActive: boolean;
}

export const ScheduleTimeSection: FC<ScheduleTimeSectionProps> = ({
  isVisitTabActive,
}) => {
  const { t } = useAppTranslation();
  const { control, setValue } = useFormContext<AddEventModalInput>();

  const isAllDayChecked = useWatch({ control, name: "isAllDay" });

  const startDate = useWatch({ control, name: "startDate" });

  const onAllDayChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setValue("isAllDay", target.checked);
  };

  return (
    <Box className="flex flex-col gap-[16px]" order={isVisitTabActive ? 0 : 1}>
      <Box>
        <ScheduleVisitModalLabel>
          {t("calendar.deadline")}
        </ScheduleVisitModalLabel>
        <Box display="flex" gap="24px">
          <MuiTimeDatePicker dateVersion name="startDate" control={control} />
          <FormControlLabel
            label={t("visits.add.all_day")}
            className="min-w-[100px] !mr-0"
            control={
              <Checkbox
                size="small"
                onChange={onAllDayChange}
                value={isAllDayChecked}
              />
            }
          />
        </Box>
      </Box>
      <Box display="flex" gap={1}>
        <Box flexGrow={1}>
          <ScheduleVisitModalLabel>
            {t("calendar.from")}
          </ScheduleVisitModalLabel>
          <MuiTimeDatePicker
            timeVersion
            name="startDate"
            step={5}
            control={control}
            disabled={isAllDayChecked}
            fullWidth
          />
        </Box>
        <Box flexGrow={1}>
          <ScheduleVisitModalLabel>{t("calendar.to")}</ScheduleVisitModalLabel>
          <MuiTimeDatePicker
            timeVersion
            name="finishDate"
            step={5}
            control={control}
            disabled={isAllDayChecked}
            fullWidth
            minTime={dayjs(startDate).add(15, "minutes")}
          />
        </Box>
      </Box>
    </Box>
  );
};
