import { useAppParams } from "@hooks";
import { MutationOptions } from "@hooks/queries";
import { useDeleteCreatorDietMealProductMutation } from "@hooks/queries/diets/creator";
import { useDeleteProgramDietMealProductMutation } from "@hooks/queries/program/dietCreator";

export const useDeleteDietMealProductMutation = (options?: MutationOptions) => {
  const { programId } = useAppParams();

  return programId
    ? useDeleteProgramDietMealProductMutation(programId, options)
    : useDeleteCreatorDietMealProductMutation(options);
};
