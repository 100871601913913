import { Stack, styled } from "@mui/material";
import { ReactElement } from "react";

type DataPickerWrapperProps = {
  label: string;
  children: ReactElement;
};
const DataPickerWrapper = ({ label, children }: DataPickerWrapperProps) => {
  return (
    <Stack direction="column" spacing="4px">
      <Label>{label}</Label>
      {children}
    </Stack>
  );
};

const Label = styled("span")`
  font-family: Figtree, serif;
  color: #4d516e;
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
`;

export default DataPickerWrapper;
