import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  deletePatientBodyMeasurement,
  DeletePatientBodyMeasurementParams,
} from "@client/patient/bodyMeasurement/deletePatientBodyMeasurement";
import { fetchClientMeasurementListQueryInfiniteKey } from "@hooks/queries";
import { patientBodyMeasurementKeys } from "@hooks/queries/client/bodyMeasurement/patientBodyMeasurementKey";

const useDeletePatientBodyMeasurementMutation = ({
  patientId,
}: {
  patientId: number;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      params,
    }: {
      params: DeletePatientBodyMeasurementParams;
    }) => {
      return deletePatientBodyMeasurement(params);
    },
    onSuccess: async (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: patientBodyMeasurementKeys.list(patientId),
      });

      queryClient.invalidateQueries({
        queryKey: patientBodyMeasurementKeys.last(patientId),
      });

      queryClient.invalidateQueries({
        queryKey: [
          fetchClientMeasurementListQueryInfiniteKey,
          patientId.toString(),
        ],
      });
    },
  });
};

export default useDeletePatientBodyMeasurementMutation;
