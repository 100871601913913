import { TreeItem, TreeView } from "@mui/lab";
import { Card, Checkbox, InputAdornment, Popper, styled } from "@mui/material";

export const TreeViewStyled = styled(TreeView)`
  & .MuiTreeItem-group {
    margin-left: 1.75rem;
  }
  & .MuiTreeItem-content.Mui-selected {
    background-color: unset;
  }
  & .MuiTreeItem-content.Mui-selected.Mui-focused {
    background-color: unset;
  }
  & .MuiTreeItem-content.Mui-focused {
    background-color: unset;
  }
  & .MuiTreeItem-content:hover {
    background-color: ${({ theme }) => theme.colors.neutral.light[200]};
  }
`;

interface TreeItemStyledProps {
  disabledElement?: boolean;
}

export const TreeItemStyled = styled(TreeItem, {
  shouldForwardProp: prop => prop !== "disabledElement",
})<TreeItemStyledProps>`
  & .MuiTreeItem-content {
    padding: 0.5rem;
  }

  & div .MuiTreeItem-label {
    padding: 0;
  }
`;

export const CheckboxStyled = styled(Checkbox)`
  margin: 0.25rem;
`;

interface PopperStyledProps {
  width?: number;
  height?: number;
}
export const PopperStyled = styled(Popper, {
  shouldForwardProp: prop => prop !== "width",
})<PopperStyledProps>`
  & .MuiPaper-root {
    width: ${({ width }) => width}px;
    max-height: ${({ height }) => height}px;
  }
  z-index: 100000;
`;

export const InputComponentWrapper = styled("div")`
  display: inline-flex;
  align-items: center;
  flex: 1;
  padding: 0.375rem;
  gap: 0.25rem;
  flex-wrap: wrap;
`;

export const StyledCard = styled(Card)`
  overflow-y: auto;
`;

export const InputStyled = styled("input")`
  padding: 0;
  height: 1.75rem;
  flex: 1 1 0%;
  outline: none;
  text-overflow: ellipsis;
`;

interface InputAdornmentStyledProps {
  hidden?: boolean;
}
export const InputAdornmentStyled = styled(InputAdornment, {
  shouldForwardProp: prop => prop !== "hidden",
})<InputAdornmentStyledProps>`
  ${({ hidden }) =>
    hidden &&
    `
    display: none;
  `}
`;

export const LabelWrapper = styled("div")`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;
