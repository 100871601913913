import { Box, CircularProgress, styled } from "@mui/material";
import MacroCircularLabel from "@components/MacroCircularProgress/MacroCircularLabel";

export type MacroCircularProgressProps = {
  percent: number;
  type: "kcal" | "protein" | "fat" | "carb";
  label: {
    value: number;
    assumption: number;
  };
};

const COLORS = {
  default: "#F5F1FE",
  stroke: "#FBFAFC",
  kcal: "#7448D0",
  kcal_second: "#59379E",
  protein: "#FDD835",
  protein_second: "#C9A816",
  fat: "#FAB569",
  fat_second: "#BF8000",
  carb: "#3AB795",
  carb_second: "#2A856C",
};

const MacroCircularProgress = ({
  percent,
  type,
  label,
}: MacroCircularProgressProps) => {
  const color = getColor(type);
  const secondColor = getSecondColor(type);
  const size = 80;
  const thickness = 4;

  return (
    <Box position="relative" display="inline-flex" alignItems="center">
      <CircularProgress
        variant="determinate"
        size={size}
        thickness={thickness}
        value={100}
        sx={{
          color: COLORS.default,
        }}
      />
      {percent > 0 && (
        <CircularProgress
          variant="determinate"
          sx={{
            color: color,
            position: "absolute",
            left: 0,
          }}
          size={size}
          thickness={thickness}
          value={Math.min(percent, 100)}
        />
      )}
      {percent > 100 && (
        <CircularProgress
          variant="determinate"
          sx={{
            color: secondColor,
            position: "absolute",
            left: 0,
          }}
          size={size}
          thickness={thickness}
          value={percent % 100}
        />
      )}
      <MacroCircularLabelWrapper>
        <MacroCircularLabel
          value={label.value}
          assumption={label.assumption}
          type={type}
        />
      </MacroCircularLabelWrapper>
    </Box>
  );
};

const MacroCircularLabelWrapper = styled("div")`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const getColor = (type: MacroCircularProgressProps["type"]) => {
  switch (type) {
    case "kcal":
      return COLORS.kcal;
    case "protein":
      return COLORS.protein;
    case "fat":
      return COLORS.fat;
    case "carb":
      return COLORS.carb;
  }
};

const getSecondColor = (type: MacroCircularProgressProps["type"]) => {
  switch (type) {
    case "kcal":
      return COLORS.kcal_second;
    case "protein":
      return COLORS.protein_second;
    case "fat":
      return COLORS.fat_second;
    case "carb":
      return COLORS.carb_second;
  }
};

export default MacroCircularProgress;
