export function buildTabLabel(energy?: number | null, cpm?: number | null) {
  const subtitleElements = [];

  if (energy !== undefined && energy !== null) {
    subtitleElements.push(`E: ${energy?.toFixed()} kcal`);
  }

  if (cpm !== undefined && cpm !== null) {
    subtitleElements.push(`CPM: ${cpm?.toFixed()} kcal`);
  }

  return subtitleElements.join(" • ");
}

export function buildTargetTabLabel(
  energy?: number | null,
  target?: number | null,
) {
  const subtitleElements = [];

  if (energy !== undefined && energy !== null) {
    subtitleElements.push(energy?.toFixed());
  }

  if (target !== undefined && target !== null) {
    subtitleElements.push(target?.toFixed());
  }

  return !energy && !target ? "" : `E: ${subtitleElements.join(" / ")} kcal`;
}
