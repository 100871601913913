import { useState } from "react";

import { useAppParams } from "@hooks";
import { useUpdateDietShowMacrosMutation } from "../../hooks";

export const useShowMacros = (defaultValue: boolean) => {
  const { dietId } = useAppParams();

  const { mutate, isLoading } = useUpdateDietShowMacrosMutation();

  const [show, setShow] = useState(defaultValue);
  const updateShowMacros = (value: boolean) => {
    setShow(value);
    mutate(
      {
        dietId,
        payload: { show: value },
      },
      {
        onError: (e, v) => setShow(!v.payload.show),
      },
    );
  };

  return {
    updateShowMacros,
    showMacros: show,
    isLoading,
  };
};
