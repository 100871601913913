import { IconButton, TextField, styled } from "@mui/material";
import { getHexColorOpacity } from "@utils";

export const GramsTextField = styled(TextField)`
  width: 60px;
`;

interface IconButtonStyledProps {
  marginTop?: boolean;
}
export const IconButtonStyled = styled(IconButton)<IconButtonStyledProps>`
  width: 24px;
  height: 24px;
  padding: unset;
  margin: ${({ marginTop }) => (marginTop ? "32px 0 8px" : "8px 0")};
  align-self: flex-start;
`;

export const MacrosWrapper = styled("div")`
  display: flex;
  align-items: center;
  width: 238px;
  height: 40px;
  padding: 8px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.primary.medium};
  background: ${({ theme }) => theme.palette.primary.light};
`;

export const RowContainter = styled("div")`
  display: flex;
  gap: 8px;
  position: relative;

  &:hover .dragHandle {
    opacity: 1;
  }
`;

export const DraggableButton = styled(IconButton)`
  position: absolute;
  bottom: 4px;
  left: -20px;
  background: ${({ theme }) =>
    getHexColorOpacity(theme.palette.primary.light, 70)};
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
  height: 32px;
  width: 32px;
  opacity: 0;
  font-size: 28px;
  padding: 0;
  cursor: grab;
  backdrop-filter: blur(1px);
  z-index: 1;

  &:active {
    cursor: grabbing;
    opacity: 1;
  }

  &.MuiButtonBase-root.MuiIconButton-root:hover {
    background: ${({ theme }) =>
      getHexColorOpacity(theme.palette.primary.light, 70)};
  }
`;
