import { useParams } from "react-router-dom";

type ProgramDayParams = {
  dayId: string;
};

export function useDayId() {
  const { dayId } = useParams<ProgramDayParams>();
  return dayId ? parseInt(dayId) : undefined;
}
