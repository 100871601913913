import {
  DollarSign,
  GreyGraph,
  SpeechBubble,
  ThreeVerticalPoints,
} from "@assets/icons";
import { StyledAlert } from "@components/AddClientModalNew";
import {
  AutogenereteMealScheduleButton,
  DefaultValuesIf,
} from "@components/AutogenerateMealScheduleModal";
import { ProgramLengthWeeks } from "@components/AutogenerateMealScheduleModal/MealDateRangePicker";
import { ConfirmationWrapper } from "@components/ConfirmationWrapper";
import { ConfirmModalWrapper } from "@components/ConfirmModalWrapper";
import { useAppTranslation, useUpdateClientPayments } from "@hooks";
import { useDeleteClientMutation } from "@hooks/queries";
import { LoadingButton } from "@mui/lab";
import { Badge, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { getSubscriptionInfo } from "@utils/subscription";
import dayjs from "dayjs";
import { MouseEvent, useState } from "react";
import { Link } from "react-router-dom";
import { ClientsListActionModal } from "../ClientsListActionModal";
import { ButtonsWrapper, StyledActionButton } from "./ActionsComponent.styled";

interface ActionsComponentProps {
  id: number;
  isActiveApp: boolean;
  unreadIds: (string | undefined)[];
  isActiveClient: boolean;
  hasEmail: string | null;
  onOpenChat?: () => void;
  name: string;
}

export const ActionsComponent = ({
  id,
  isActiveApp,
  unreadIds,
  isActiveClient,
  hasEmail,
  onOpenChat,
  name,
}: ActionsComponentProps) => {
  const { t } = useAppTranslation();
  const { isTrial } = getSubscriptionInfo();
  const { breakpoints } = useTheme();
  const isMatchTablet = useMediaQuery(`${breakpoints.up("medium")}`);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const hasMessage = unreadIds.includes(id.toString());
  const { mutate: deleteClientMutation, isLoading: isLoadingDeleteClient } =
    useDeleteClientMutation();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActivateClient = () => {
    mutate({
      id: id.toString(),
      payload: { activeUntil: null, isActive: true },
    });
    handleClose();
  };

  const { mutate, isLoading } = useUpdateClientPayments();

  const handleRemove = () => {
    deleteClientMutation(id);
    setAnchorEl(null);
  };

  const autogenerateDefaultValues: DefaultValuesIf = {
    clientId: id.toString(),
    clientQuery: name,
    startDate: dayjs().toDate(),
    endDate: dayjs().add(ProgramLengthWeeks.TWO, "days").toDate(),
  };

  if (!isActiveClient)
    return (
      <>
        <ConfirmModalWrapper
          onConfirm={handleActivateClient}
          confirmationText={t("client_profile.confirm_activate_tooltip")}
          submitText={t("client_profile.activate_client")}
          extraContent={
            !isTrial && (
              <StyledAlert
                variant="filled"
                icon={<DollarSign size="w-4 h-4" />}
              >
                {t("client_profile.confirm_activate_context")}
              </StyledAlert>
            )
          }
        >
          <LoadingButton loading={isLoading}>
            {t("clients.actions.activate")}
          </LoadingButton>
        </ConfirmModalWrapper>
        <ConfirmationWrapper
          onConfirm={handleRemove}
          confirmationText={t("clients.actions.confirmation")}
          submitText={t("clients.actions.yes")}
        >
          <LoadingButton loading={isLoadingDeleteClient}>
            {t("clients.actions.delete")}
          </LoadingButton>
        </ConfirmationWrapper>
      </>
    );

  return (
    <ButtonsWrapper>
      <div className="w-[24px]">
        <AutogenereteMealScheduleButton
          variant="icon"
          defaultValues={autogenerateDefaultValues}
        />
      </div>
      {isMatchTablet && (
        <>
          <Link to={`${id}/progress-tracking`}>
            <Tooltip
              title={t("clients.actions.monitoring")}
              arrow
              placement="top"
            >
              <div className="w-[24px]">
                <StyledActionButton>
                  <GreyGraph />
                </StyledActionButton>
              </div>
            </Tooltip>
          </Link>
          <Link to={`/chat/${id}`} onClick={e => e.preventDefault()}>
            <Tooltip title={t("clients.actions.chat")} arrow placement="top">
              <div className="w-[24px]">
                <StyledActionButton onClick={onOpenChat}>
                  <Badge
                    color="error"
                    variant="dot"
                    invisible={!hasMessage}
                    role="tooltip"
                  >
                    <SpeechBubble />
                  </Badge>
                </StyledActionButton>
              </div>
            </Tooltip>
          </Link>
        </>
      )}
      <div className="w-[24px]">
        <StyledActionButton onClick={handleClick}>
          {isMatchTablet ? (
            <ThreeVerticalPoints />
          ) : (
            <Badge color="error" variant="dot" invisible={!hasMessage}>
              <ThreeVerticalPoints />
            </Badge>
          )}
        </StyledActionButton>
      </div>

      <ClientsListActionModal
        open={open}
        handleClose={handleClose}
        handleRemove={handleRemove}
        isLoadingDeleteClient={isLoadingDeleteClient}
        anchorEl={anchorEl}
        id={id}
        setAnchorEl={setAnchorEl}
        isActiveApp={isActiveApp}
        hasEmail={hasEmail}
        hasMessage={hasMessage}
        onClientDeactivate={() =>
          mutate({
            id: id.toString(),
            payload: { activeUntil: null, isActive: false },
          })
        }
        isLoadingDeactivate={isLoading}
        onOpenChat={onOpenChat}
      />
    </ButtonsWrapper>
  );
};
