import { useEffect } from "react";
import { useController, useWatch } from "react-hook-form";

import { InputAdornment, TableCell } from "@mui/material";

import { decimalInputFactory, decimalPercentageInput } from "@utils/inputs";
import { useNutrientDictionary } from "@hooks";
import { FormCheckboxMui } from "@components/FormCheckboxMui";
import { FormTextField } from "@components/FormTextField";

import { StyledTableRow } from "./OptimizerSettingsModal.styled";

const decimalInputMax = decimalInputFactory(0, 20000) as any;

interface OptimizerDayTableNutrientProps {
  namePrefix: string;
  id: number;
}

export const OptimizerDayTableNutrient = ({
  namePrefix,
  id,
}: OptimizerDayTableNutrientProps) => {
  const { nutrientDictionary } = useNutrientDictionary();
  const disabled = !useWatch({
    name: `${namePrefix}.nutrients.${id}.used`,
  });
  const {
    field: { onChange },
  } = useController({ name: `${namePrefix}.nutrients.${id}.distribution` });

  useEffect(() => {
    if (disabled) onChange(0);
  }, [disabled]);

  return (
    <StyledTableRow key={id} purple={!disabled}>
      <TableCell padding="checkbox">
        <FormCheckboxMui name={`${namePrefix}.nutrients.${id}.used`} />
      </TableCell>
      <TableCell component="th" scope="row">
        {nutrientDictionary[id]?.name ?? "-"}
      </TableCell>
      <TableCell align="right">
        <FormTextField
          disabled={disabled}
          name={`${namePrefix}.nutrients.${id}.value`}
          variant="standard"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {nutrientDictionary[id]?.unit}
              </InputAdornment>
            ),
            inputComponent: decimalInputMax,
          }}
        />
      </TableCell>
      <TableCell align="right">
        <FormTextField
          disabled={disabled}
          name={`${namePrefix}.nutrients.${id}.distribution`}
          variant="standard"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
            inputComponent: decimalPercentageInput,
          }}
        />
      </TableCell>
      <TableCell align="right">
        <FormTextField
          disabled={disabled}
          name={`${namePrefix}.nutrients.${id}.tolerance`}
          variant="standard"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
            inputComponent: decimalPercentageInput,
          }}
        />
      </TableCell>
    </StyledTableRow>
  );
};
