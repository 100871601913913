import { styled } from "@mui/material";

export const Tooltip = styled("div")`
  min-width: 6rem;
  align-items: center;
  border-radius: 0.375rem;
  background: ${({ theme }) => theme.colors.whiteBackground};
  padding: 0.5rem;
  box-shadow: ${({ theme }) => theme.shadows[10]};
  font-size: 0.75rem;
`;

export const StyledItem = styled("p")`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  color: ${({ theme }) => theme.colors.greyBlue};
`;
