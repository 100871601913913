import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useAppTranslation } from "@hooks";
import { toast } from "react-toastify";
import { MutationOptions } from "./types";
import { requestDeleteDiet } from "@client";
import { searchDietsQueryKeyNew } from "./useSearchDietsQueryNew";

export const useDeleteDietMutation = (options?: MutationOptions) => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();

  return useMutation((id: number) => requestDeleteDiet(id), {
    ...options,
    onSuccess: () => {
      toast.success(t("diets.success_delete"));
      queryClient.invalidateQueries([searchDietsQueryKeyNew]);
      options?.onSuccess && options.onSuccess();
    },
  });
};
