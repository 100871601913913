import { Controller } from "react-hook-form";
import { Chip, Stack, styled } from "@mui/material";
import { useAppTranslation } from "@hooks";
import { useFormContext } from "react-hook-form";
import { getTaskPriorityIcon } from "@utils/tasks/getTaskPriorityIcon";
import Theme from "quill/core/theme";

export enum TaskPriority {
  High = "high",
  Medium = "medium",
  Low = "low",
}

const StyledChip = styled(Chip)`
  &.MuiChip-colorDefault {
    color: ${({ theme }) => theme.colors.neutral.dark["700"]};
  }
`;

export const TaskPrioritySelect = () => {
  const { t } = useAppTranslation();
  const { control } = useFormContext();

  const priorities = Object.values(TaskPriority).map(priority => ({
    value: priority,
    label: t(`tasks.priority.${priority}`),
  }));
  return (
    <Controller
      name="priority"
      control={control}
      render={({ field: { onChange, value } }) => (
        <Stack direction="row" spacing={1} className="h-full flex items-center">
          {priorities.map(priority => (
            <StyledChip
              key={priority.value}
              label={priority.label}
              icon={getTaskPriorityIcon(priority.value)}
              onClick={() => onChange(priority.value)}
              color={value === priority.value ? "primary" : "default"}
              sx={{
                "padding": "0.2rem",
                "backgroundColor": value === priority.value ? "" : "white",
                "border": "1px solid #F1F1F1",
                ":hover": { backgroundColor: "#F5F1FE" },
              }}
            />
          ))}
        </Stack>
      )}
    />
  );
};
