import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Cup = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16" height="12" viewBox="0 0 16 12">
      <path
        d="M2.4 1.20002C2.4 0.757524 2.7575 0.400024 3.2 0.400024H11.2H12.8C14.5675 0.400024 16 1.83252 16 3.60002C16 5.36752 14.5675 6.80002 12.8 6.80002H12C12 8.12502 10.925 9.20002 9.6 9.20002H4.8C3.475 9.20002 2.4 8.12502 2.4 6.80002V1.20002ZM12 5.20002H12.8C13.6825 5.20002 14.4 4.48252 14.4 3.60002C14.4 2.71752 13.6825 2.00002 12.8 2.00002H12V5.20002ZM0.8 10H13.6C14.0425 10 14.4 10.3575 14.4 10.8C14.4 11.2425 14.0425 11.6 13.6 11.6H0.8C0.3575 11.6 0 11.2425 0 10.8C0 10.3575 0.3575 10 0.8 10Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
