import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Wheat = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="14" viewBox="0 0 14 14">
      <path
        d="M13.3215 1.53882C13.5601 1.30014 13.5601 0.914196 13.3215 0.678057C13.0828 0.441919 12.6968 0.43938 12.4607 0.678057L10.2237 2.90995C9.98505 3.14863 9.98505 3.53457 10.2237 3.77071C10.4624 4.00685 10.8483 4.00939 11.0845 3.77071L13.3189 1.53628L13.3215 1.53882ZM8.2559 1.19096C8.09848 1.03353 7.83949 1.03353 7.68206 1.19096L7.3926 1.47788C6.44043 2.43005 6.44043 3.97384 7.3926 4.92601L7.65667 5.19008L6.88224 5.96451C6.79591 5.27133 6.48867 4.59847 5.95546 4.06525L5.66853 3.77833C5.51111 3.6209 5.25212 3.6209 5.09469 3.77833L4.80777 4.06525C3.8556 5.01742 3.8556 6.56121 4.80777 7.51338L5.07184 7.77745L4.29741 8.55188C4.21108 7.8587 3.90384 7.18583 3.37063 6.65262L3.08371 6.36316C2.92628 6.20573 2.66729 6.20573 2.50986 6.36316L2.22294 6.65008C1.27077 7.60225 1.27077 9.14604 2.22294 10.0982L2.48701 10.3623L0.737555 12.1117C0.420164 12.4291 0.420164 12.9446 0.737555 13.262C1.05495 13.5793 1.57039 13.5793 1.88778 13.262L3.63723 11.5125L3.94701 11.8223C4.89918 12.7744 6.44297 12.7744 7.39514 11.8223L7.68206 11.5354C7.83949 11.3779 7.83949 11.1189 7.68206 10.9615L7.39514 10.6746C6.84161 10.1211 6.13573 9.80875 5.41208 9.73765L6.22206 8.92767L6.53184 9.23745C7.48401 10.1896 9.0278 10.1896 9.97997 9.23745L10.2669 8.95052C10.4243 8.7931 10.4243 8.53411 10.2669 8.37668L9.97997 8.08976C9.42644 7.53623 8.72056 7.22392 7.99691 7.15282L8.80689 6.34284L9.11666 6.65262C10.0688 7.60479 11.6126 7.60479 12.5648 6.65262L12.8517 6.36316C13.0091 6.20573 13.0091 5.94674 12.8517 5.78931L12.5648 5.49985C12.4328 5.36782 12.2956 5.25102 12.1484 5.14692L13.3215 3.97638C13.5601 3.7377 13.5601 3.35176 13.3215 3.11562C13.0828 2.87948 12.6968 2.87694 12.4607 3.11562L10.9525 4.62386C10.4294 4.51214 9.88602 4.52991 9.37058 4.67718C9.52546 4.13634 9.53816 3.56504 9.40613 3.01913L10.8839 1.53882C11.1226 1.30014 11.1226 0.914196 10.8839 0.678057C10.6452 0.441919 10.2593 0.43938 10.0231 0.678057L8.85514 1.84098C8.76119 1.71402 8.65708 1.59214 8.54028 1.47788L8.2559 1.19096Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
