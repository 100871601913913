import { HTMLAttributes } from "react";

import { MacrosText } from "@components/MacrosText";

import { ProductOptionIf } from "../../ProductsContext";
import { AlreadyInDietChip } from "./AlreadyInDietChip";
import { OptionText, OptionWrapper, Wrapper } from "./CustomOption.styled";

export const CustomOption = (
  props: HTMLAttributes<HTMLLIElement>,
  { id, label, nutrients, clientContext, alreadyInDiet }: ProductOptionIf,
) => {
  return (
    <Wrapper {...props} key={id}>
      <OptionWrapper>
        <OptionText className="self-start" bold={alreadyInDiet}>
          {label}
        </OptionText>
        <AlreadyInDietChip show={!!alreadyInDiet} />
      </OptionWrapper>
      <div className="self-start">
        <MacrosText
          nutrients={nutrients}
          userTags={{
            liked: clientContext?.hasLiked,
            disliked: clientContext?.hasUnliked,
            allergen: clientContext?.hasAllergens,
          }}
        />
      </div>
    </Wrapper>
  );
};
