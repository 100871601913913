import { useFormContext } from "react-hook-form";

import { useAppTranslation } from "@hooks";
import { LegacyNutrient } from "@typeDefinitions";
import { useIdMapContext } from "@context";
import { FormInput } from "@components/FormInput";
import { FormCheckbox } from "@components/FormCheckbox";

export const MicronutrientElement = ({
  element,
}: MicronutrientElementProps) => {
  const { isPolishChosen } = useAppTranslation();
  const { idMap } = useIdMapContext();
  const { control } = useFormContext();

  return (
    <div className="py-2 border-b border-gray-400 cols-span-1 grid grid-cols-9">
      <span className="col-span-5 flex justify-start items-center">
        {isPolishChosen ? element.descriptionPl : element.descriptionEn}
      </span>
      <div className={"col-span-4 flex justify-end gap-3 items-center"}>
        <FormInput
          key={`nutrients.${idMap[element.id]}.value`}
          className={"w-18"}
          control={control}
          name={`nutrients.${idMap[element.id]}.value`}
          suffix={<span>{element.units}</span>}
        />
        <FormCheckbox
          key={`nutrients.${idMap[element.id]}.visible`}
          control={control}
          name={`nutrients.${idMap[element.id]}.visible`}
        />
      </div>
    </div>
  );
};

interface MicronutrientElementProps {
  element: LegacyNutrient;
}
