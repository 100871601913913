import { useMemo } from "react";

import { useAppParams, useAppTranslation } from "@hooks";
import { useFetchProductQuery } from "@hooks/queries/food";
import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";
import { BreadcrumbsPath } from "@components/BreadcrumbsPath";
import { Carrot } from "@assets/icons/Menu";
import { GroupedNutrients } from "@components/GroupedNutrients";

import { MeasuresDetails } from "../components/MeasuresDetails";
import { ProductWrapper, StyledCard } from "./Product.styled";
import { ProductMainDetails } from "../components/ProductMainDetails";

interface ProductProps {
  externalProductId?: string;
}

export const Product = ({ externalProductId }: ProductProps) => {
  const { t, isPolishChosen } = useAppTranslation();
  const { productId } = useAppParams();
  const effectiveProductId = externalProductId ?? productId;
  const { product, isLoading, isError } =
    useFetchProductQuery(effectiveProductId);

  const name = isPolishChosen ? product?.descriptionPl : product?.description;

  const tagsMapped = useMemo(
    () =>
      product?.tags?.map(tag =>
        isPolishChosen ? tag.descriptionPl : tag.descriptionEn,
      ) ?? [],
    [product?.tags, isPolishChosen],
  );

  const productName =
    (isPolishChosen
      ? product?.descriptionPl
      : product?.description ?? product?.descriptionPl) ?? "";

  const breadcrumbs = useMemo(
    () => [
      {
        icon: <Carrot />,
        backTo: "/products",
        title: t("product.title"),
      },
      {
        backTo: `/products/${effectiveProductId}`,
        title: productName,
      },
    ],
    [effectiveProductId, productName, isPolishChosen],
  );

  const mappedMeasures = useMemo(
    () =>
      product?.measures.map(measure => {
        return {
          name: isPolishChosen ? measure.description : measure.descriptionEn,
          value: measure.grams,
        };
      }) ?? [],
    [product?.measures, isPolishChosen],
  );

  if (isLoading) return <Spinner />;
  else if (isError || !product) return <ApiError />;

  return (
    <ProductWrapper>
      <BreadcrumbsPath breadcrumbs={breadcrumbs} />
      <StyledCard>
        <ProductMainDetails
          producer={product.producer}
          name={name}
          categoryId={product.foodGroup.id.toString()}
          nutrients={product.nutrients}
          tags={tagsMapped}
          canEdit={product.actions.canEdit}
          canDelete={product.actions.canDelete}
          canToggleVisibility={product.actions.canVisible}
        />
      </StyledCard>

      <StyledCard>
        <MeasuresDetails measures={mappedMeasures} />
      </StyledCard>

      <StyledCard>
        <GroupedNutrients
          title={t("product.nutrients.nutritional_values_per")}
          nutrients={product.nutrients}
        />
      </StyledCard>
    </ProductWrapper>
  );
};
