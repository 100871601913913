import { useQuery } from "@tanstack/react-query";

import { SurveyDto, fetchSurveysAlloweat } from "@client/surveys";
import { ApiResponse } from "@typeDefinitions";

import { QueryOptionsTyped } from "../types";

export const fetchSurveysAlloweatQueryKey = "fetchSurveysAlloweatQueryKey";

export const useFetchSurveysAlloweatQuery = (
  options?: QueryOptionsTyped<ApiResponse<SurveyDto[]>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchSurveysAlloweatQueryKey],
    fetchSurveysAlloweat,
    options,
  );

  return {
    surveys: data?.data,
    meta: data?.meta,
    ...rest,
  };
};
