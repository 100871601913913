import { useAppTranslation } from "@hooks";
import Dialog from "@components/Alloweat/Dialog/Dialog";
import DialogTitle from "@components/Alloweat/Dialog/DialogTitle";
import { Button, DialogContent, Stack, Tab } from "@mui/material";
import { IconButton } from "@components/Alloweat/IconButton";
import DialogActions from "@components/Alloweat/Dialog/DialogActions";
import React, { Dispatch } from "react";
import { DialogSectionLayout } from "@views/dietician/ClientHealth/ClientHealthNew.styled";
import CreateBodyMeasurementsForm from "@components/Client/FormBodyMeasurementsModal/CreateBodyMeasurementsForm";
import DateMeasurementField from "@components/Client/FormBodyMeasurementsModal/DateMeasurementField";
import BodyMeasurementsFieldsTabs from "@components/Client/FormBodyMeasurementsModal/BodyMeasurementsFieldsTabs";
import { CloseIcon } from "@icons/index";
import { FormCancelButton, FormSubmitButton } from "@components/FormButtons";

type CreateBodyMeasurementsModalProps = {
  open: boolean;
  setOpen: Dispatch<boolean>;
};

const CreateBodyMeasurementsModal = ({
  open,
  setOpen,
}: CreateBodyMeasurementsModalProps) => {
  const { t } = useAppTranslation();

  return (
    <Dialog open={open} fullWidth PaperProps={{ sx: { maxWidth: "510px" } }}>
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <span>
            {t(
              "patient.health_and_goal.body_measurement_tab.body_measurement_modal.create_title",
            )}
          </span>

          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon color="727272" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <CreateBodyMeasurementsForm onSuccess={() => setOpen(false)}>
        <DialogContent>
          <DialogSectionLayout>
            <DateMeasurementField />
            <BodyMeasurementsFieldsTabs />
          </DialogSectionLayout>
        </DialogContent>
        <DialogActions>
          <FormCancelButton onClick={() => setOpen(false)} />
          <FormSubmitButton />
        </DialogActions>
      </CreateBodyMeasurementsForm>
    </Dialog>
  );
};

export default CreateBodyMeasurementsModal;
