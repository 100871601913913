import { postPatientProductsContext, ProductContextDto } from "@client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ApiResponse } from "@typeDefinitions";
import { MutationOptions } from "./types";
import { useAppParams } from "@hooks/useAppParams";

export const usePostPatientProductsContextMutationKey =
  "usePostPatientProductsContextMutationKey";

export const usePostPatientProductsContextMutation = (
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();
  const { patientId } = useAppParams();

  return useMutation<
    ApiResponse<ProductContextDto[]>,
    unknown,
    UsePostPatientProductsContextMutationPayload
  >(
    ({ patientId: controlledPatientId, payload }) => {
      const productsContext = queryClient.getQueryData<
        ApiResponse<ProductContextDto[]>
      >([
        usePostPatientProductsContextMutationKey,
        controlledPatientId ?? patientId,
      ])?.data;
      const productsIds = productsContext?.map(({ id }) => id);
      const filteredPayload = productsIds?.length
        ? payload.filter(id => !productsIds.includes(id))
        : payload;
      const noPatientId = controlledPatientId === undefined && !patientId;

      if (!filteredPayload.length || noPatientId)
        return Promise.resolve({ data: [] });
      return postPatientProductsContext(
        controlledPatientId ?? patientId,
        filteredPayload,
      );
    },
    {
      ...options,
      onSuccess: (data, variables) => {
        options?.onSuccess?.(data, variables);

        queryClient.setQueryData<ApiResponse<ProductContextDto[]>>(
          [
            usePostPatientProductsContextMutationKey,
            variables.patientId ?? patientId,
          ],
          oldData => {
            const filteredOldData =
              oldData?.data.filter(
                oldProduct =>
                  !data.data.some(
                    newProduct => newProduct.id === oldProduct.id,
                  ),
              ) ?? [];

            let newData = [...filteredOldData, ...data.data];

            if (!data.data.length) return oldData;

            if (newData.length > MAX_LIST_LENGTH) {
              const idsInPayload = new Set(variables.payload);
              newData = newData.filter(
                (item, index) =>
                  idsInPayload.has(item.id) ||
                  index >= newData.length - MAX_LIST_LENGTH,
              );
            }

            return {
              data: newData,
            };
          },
        );
      },
    },
  );
};

interface UsePostPatientProductsContextMutationPayload {
  patientId?: string;
  payload: number[];
}

const MAX_LIST_LENGTH = 1000;
