import { ModalWrapper } from "@components/ModalWrapperNew";
import { useAppParams, useAppTranslation } from "@hooks";
import { useDeleteDietMealMutation } from "@views/dietician/DietCreator/hooks";
import dayjs from "dayjs";

interface DeleteMealModalProps {
  open: boolean;
  onClose: () => void;
  mealId: number | string;
  name: string;
  hour?: string;
}
export const DeleteMealModal = ({
  open,
  onClose,
  mealId,
  name,
  hour,
}: DeleteMealModalProps) => {
  const { t } = useAppTranslation();
  const { dietId } = useAppParams();
  const { mutate, isLoading } = useDeleteDietMealMutation();
  const handleDelete = () =>
    mutate({ dietId, mealId: `${mealId}` }, { onSuccess: onClose });

  const isValidHour =
    hour && dayjs(hour, "HH:mm").format("HH:mm") !== "Invalid Date";

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      onSubmit={handleDelete}
      color="error"
      title={t("diet.delete_meal.title")}
      confirmText={t("diet.delete_meal.title")}
      loading={isLoading}
    >
      <div className="max-w-[478px]">
        <p>
          {t("diet.delete_meal.question")}:{" "}
          <strong>
            {name}
            {isValidHour ? `, ${dayjs(hour, "HH:mm").format("HH:mm")}` : ""}
          </strong>
          ?
        </p>
        <p>{t("diet.delete_meal.description")}</p>
      </div>
    </ModalWrapper>
  );
};
