import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MutationOptions } from "./types";
import {
  UpdateClientFoodPreferencesRequest,
  updateClientFoodPreferences,
} from "@client";
import { fetchClientFoodGroupPreferencesQueryKey } from "./useFetchClientFoodGroupPreferences";
import { fetchClientFoodPreferencesQueryKey } from "./useFetchClientFoodPreferences";
import { AxiosError } from "axios";
import { ApiErrorIf } from "@typeDefinitions";

export const useUpdateClientFoodPreferences = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    AxiosError<ApiErrorIf>,
    UpdateClientFoodPreferencesMutationPayload
  >(({ id, payload }) => updateClientFoodPreferences(id, payload), {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([fetchClientFoodPreferencesQueryKey]);
      queryClient.invalidateQueries([fetchClientFoodGroupPreferencesQueryKey]);
      options?.onSuccess && options.onSuccess(data, variables);
    },
    ...options,
  });
};

interface UpdateClientFoodPreferencesMutationPayload {
  id: string;
  payload: UpdateClientFoodPreferencesRequest;
}
