import { Button, TooltipProps } from "@mui/material";
import { CSSProperties, ReactNode } from "react";
import { NavLink, To } from "react-router-dom";
import {
  StyledIconButton,
  OnlyIconButton,
} from "./MuiButtonWithTooltip.styled";
import { MuiWhiteTooltip } from "./MuiWhiteTooltip";

interface MuiButtonWithTooltipProps {
  icon?: ReactNode;
  onClick: (() => void) | undefined;
  content?: string | null;
  to?: string;
  placement?: TooltipProps["placement"];
  tooltipTitle?: string | null;
  squareBorder?: boolean;
  style?: CSSProperties;
  activeColor?: string;
  open?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClose?: () => void;
}

export const MuiButtonWithTooltip = ({
  icon,
  onClick,
  content,
  to,
  placement,
  tooltipTitle,
  squareBorder,
  style,
  activeColor,
  open,
  onMouseEnter,
  onMouseLeave,
}: MuiButtonWithTooltipProps) => {
  const withIconLink = !!to && !!icon && !content;
  const onlyIcon = !!icon && !to && !content;
  const buttonWithLink = !!to && !!content && !icon;
  const onlyButton = !to && !icon && !!content;
  return (
    <MuiWhiteTooltip
      open={open}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      arrow
      placement={placement}
      title={tooltipTitle}
    >
      <div>
        {withIconLink && (
          <NavLink to={to as To}>
            {({ isActive }) => (
              <StyledIconButton
                style={
                  style ? style : { color: isActive ? activeColor : "inherit" }
                }
                onClick={onClick}
                squareBorder={squareBorder}
              >
                {icon}
              </StyledIconButton>
            )}
          </NavLink>
        )}

        {onlyIcon && (
          <OnlyIconButton style={style} onClick={onClick}>
            {icon}
          </OnlyIconButton>
        )}

        {buttonWithLink && (
          <NavLink to={to as To}>
            {() => <Button onClick={onClick}>{content}</Button>}
          </NavLink>
        )}

        {onlyButton && <Button onClick={onClick}>{content}</Button>}
      </div>
    </MuiWhiteTooltip>
  );
};
