import { QueryOptionsTyped } from "@hooks/queries";
import { useQuery } from "@tanstack/react-query";
import { patientKeys } from "@hooks/queries/client/patientKeys";
import {
  fetchPatientShowNutrients,
  FetchPatientShowNutrientsParams,
  FetchPatientShowNutrientsResponse,
} from "@client/patient/nutrients/fetchPatientShowNutrients";
import { patientNutrientKeys } from "@hooks/queries/client/nutrient/patientNutrientKeys";

const useFetchPatientShowNutrients = (
  params: FetchPatientShowNutrientsParams,
  options?: QueryOptionsTyped<FetchPatientShowNutrientsResponse>,
) => {
  return useQuery({
    queryKey: patientNutrientKeys.show(params.patientId),
    queryFn: () => fetchPatientShowNutrients(params),
    ...options,
  });
};

export default useFetchPatientShowNutrients;
