import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import { Roles } from "@utils/settings";

import {
  DietitianFacilityDto,
  dietitianFacilitySchema,
  PhoneDto,
  phoneSchema,
} from "../common";

export const fetchClinicDietitians = async (): Promise<
  ApiResponse<ClinicDietitianDto[]>
> => {
  const response = await fetchData(
    "/dietitian/clinic/dietitians",
    APIMethods.GET,
  );
  await fetchClinicDietitiansSchema.validate(response);

  return response;
};

export interface ClinicDietitianDto {
  id: number;
  active: boolean;
  firstName: string;
  lastName: string;
  email: string;
  roles: Roles[];
  avatar?: string;
  facility?: DietitianFacilityDto;
  clientsCount: number;
  phone: PhoneDto;
}

const clinicDietitianSchema = yup.object().shape({
  id: yup.number().required(),
  active: yup.boolean().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required(),
  roles: yup
    .array()
    .of(yup.mixed().oneOf(Object.values(Roles)))
    .required(),
  avatar: yup.string().nullable(),
  facility: dietitianFacilitySchema.nullable(),
  clientsCount: yup.number().required(),
  phone: phoneSchema.required(),
});

const fetchClinicDietitiansSchema = yup.object().shape({
  data: yup.array(clinicDietitianSchema).required(),
});
