import styled from "@emotion/styled";

export const DurationSelectWrapperStyled = styled.div`
  display: grid;
  grid-column: span 8 / span 8;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1.75rem;
  margin-bottom: 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.tablet}px) {
    grid-column: span 9 / span 9;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
`;

export const DurationCheckboxWrapperStyled = styled.div`
  display: flex;
  align-items: flex-end;
  grid-column: span 1 / span 1;
`;
