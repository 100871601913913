import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import * as yup from "yup";

export const fetchVisitBoxConfig = async (): Promise<
  ApiResponse<BoxConfig[]>
> => {
  const data = await fetchData(
    `/dietitian/schedules/visit/boxes-config`,
    APIMethods.GET,
  );
  await boxConfigSchema.validate(data);
  return data;
};

const boxConfigSchema = yup.object().shape({
  data: yup.array().of(
    yup
      .object()
      .shape({
        id: yup.number().required(),
        name: yup.string().required(),
        isProtected: yup.boolean().required(),
        hasFiles: yup.boolean().required(),
      })
      .required(),
  ),
});

interface BoxConfig {
  id: number;
  name: string;
  isProtected: boolean;
  hasFiles: boolean;
}
