import { Button, Card, DialogActions, IconButton, styled } from "@mui/material";

export const SettingWrapper = styled("div")`
  display: grid;
  gap: 1.75rem;
`;

export const TitleSection = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.25rem;
`;

export const SettingsCard = styled(Card)`
  display: grid;
  gap: 1.5rem;
  width: 28.75rem;
  margin: 0 auto;
  padding: 1.5rem;
`;

export const StyledButton = styled(Button)`
  height: unset;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  padding: 0;
  color: ${({ theme }) => theme.palette.primary.main};
  &:hover {
    background: unset;
  }
`;

export const SectionWrapper = styled("div")`
  display: grid;
  gap: 1.5rem;
`;

export const SectionTitle = styled("p")`
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;

export const Subtitle = styled("p")`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const SettingsModalWrapper = styled("div")`
  display: grid;
  gap: 1rem;
  width: 32rem;
  padding: 1rem 1rem 1.5rem;
`;

export const TitleWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled("h3")`
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.25rem;
`;

export const CloseButton = styled(IconButton)`
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;

export const SettingsModalCard = styled("form")`
  display: grid;
  gap: 1rem;
  width: 100%;
  margin: 0 auto;
`;

export const StyledDialogActions = styled(DialogActions)`
  padding: 0.75rem 1rem;
  background: ${({ theme }) => theme.palette.primary.light};
`;

export const CancelButton = styled(Button)`
  background: ${({ theme }) => theme.colors.neutral.light[100]};
  border: 1px solid ${({ theme }) => theme.palette.primary.main};

  &:hover {
    background: ${({ theme }) => theme.colors.neutral.light[100]};
  }
`;
