import ProgramTabContent from "@components/ProgramsFilters/Filters/ProgramTabContent";
import DietTypeField from "@components/ProgramsFilters/Filters/Fields/DietTypeField";
import DietEliminationField from "@components/ProgramsFilters/Filters/Fields/DietEliminationField";
import DietAllergenField from "@components/ProgramsFilters/Filters/Fields/DietAllergenField";
import DietMealNumberField from "@components/ProgramsFilters/Filters/Fields/DietMealNumberField";

const ProgramDietTabContent = () => {
  return (
    <ProgramTabContent>
      <DietTypeField />
      <DietEliminationField />
      <DietAllergenField />
      <DietMealNumberField />
    </ProgramTabContent>
  );
};

export default ProgramDietTabContent;
