import { useCallback, useMemo, useState } from "react";

import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid";

import { Plus } from "@assets/icons";
import { FetchClinicProductDto } from "@client";
import { ProductsModal } from "@components/ProductsModal";
import { CurrenciesModal } from "@components/CurrenciesModal";
import { DataGrid } from "@components/DataGrid";
import { Currencies } from "@utils/scheduleEvent";
import { useAppTranslation } from "@hooks";
import { useFetchClinicProducts } from "@hooks/queries";
import { useFetchClinicCurrencies } from "@hooks/queries/settings";
import { Badge } from "@layouts/DieticianSettingsLayout/components/ProductsTab/Badge";

import { AddProductButton } from "./ProductsTab.styled";
import { ProductActions } from "./";

function useModal() {
  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  return { open, handleOpen, handleClose };
}

export const ProductsTab = () => {
  const { t, isPolishChosen } = useAppTranslation();
  const [productId, setProductId] = useState<number | undefined>(undefined);
  const handleSetProductId = useCallback((id?: number) => setProductId(id), []);

  const { clinicProducts, isLoading: isLoadingProducts } =
    useFetchClinicProducts();
  const { currencies, isLoading: isLoadingCurrencies } =
    useFetchClinicCurrencies();

  const mapProductsGrid = (data?: FetchClinicProductDto[]) => {
    if (!data) return [];

    const getPriceString = (
      product: FetchClinicProductDto,
      currency: Currencies,
    ) => {
      const price = product.prices.find(price => price.currency === currency);
      return price?.amount;
    };

    return data.map(product => ({
      id: product.id,
      name: isPolishChosen
        ? product.translations.pl.name
        : product.translations.en.name,
      pln: getPriceString(product, Currencies.PLN),
      eur: getPriceString(product, Currencies.EUR),
      usd: getPriceString(product, Currencies.USD),
      gbp: getPriceString(product, Currencies.GBP),
      status: product.isActive,
    }));
  };

  const productsMapped = useMemo(
    () => mapProductsGrid(clinicProducts),
    [mapProductsGrid, clinicProducts],
  );

  const {
    open: openProductsModal,
    handleOpen: handleOpenProductsModal,
    handleClose: handleCloseProductsModal,
  } = useModal();

  const {
    open: openCurrenciesModal,
    handleOpen: handleOpenCurrenciesModal,
    handleClose: handleCloseCurrenciesModal,
  } = useModal();

  const handleCloseProductsModalWrapper = useCallback(() => {
    handleCloseProductsModal();
    setProductId(undefined);
  }, [handleOpenProductsModal, setProductId]);

  const columns = useMemo<GridColumns>(() => {
    const currencyColumns: GridColumns = [];

    const isCurrencyActive = (c: Currencies) =>
      !!currencies?.find(currency => currency.currency === c);

    if (isCurrencyActive(Currencies.PLN)) {
      currencyColumns.push({
        field: "pln",
        headerName: "PLN",
        width: 150,
        disableColumnMenu: true,
        type: "number",
        renderCell: ({ value }) => (value ? `${value} PLN` : "-"),
      });
    }

    if (isCurrencyActive(Currencies.EUR)) {
      currencyColumns.push({
        field: "eur",
        headerName: "EUR",
        width: 150,
        disableColumnMenu: true,
        type: "number",
        renderCell: ({ value }) => (value ? `${value} EUR` : "-"),
      });
    }

    if (isCurrencyActive(Currencies.USD)) {
      currencyColumns.push({
        field: "usd",
        headerName: "USD",
        width: 150,
        disableColumnMenu: true,
        type: "number",
        renderCell: ({ value }) => (value ? `${value} USD` : "-"),
      });
    }

    if (isCurrencyActive(Currencies.GBP)) {
      currencyColumns.push({
        field: "gbp",
        headerName: "GBP",
        width: 150,
        disableColumnMenu: true,
        type: "number",
        renderCell: ({ value }) => (value ? `${value} GBP` : "-"),
      });
    }

    return [
      {
        field: "name",
        headerName: t("products_settings.product_name"),
        flex: 1,
        minWidth: 200,
        disableColumnMenu: true,
      },
      ...currencyColumns,
      {
        field: "status",
        headerName: t("products_settings.status"),
        width: 150,
        align: "center",
        headerAlign: "center",
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => (
          <Badge success={params.row.status} />
        ),
      },
      {
        field: "actions",
        headerName: t("products_settings.options"),
        type: "actions",
        width: 100,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => (
          <ProductActions
            id={params.row.id}
            setProductId={handleSetProductId}
            openEditModal={handleOpenProductsModal}
          />
        ),
      },
    ];
  }, [currencies]);

  return (
    <div className="grid">
      <ProductsModal
        open={openProductsModal}
        onClose={handleCloseProductsModalWrapper}
        edit={!!productId}
        id={productId}
      />

      <CurrenciesModal
        open={openCurrenciesModal}
        onClose={handleCloseCurrenciesModal}
      />

      <div className="ml-auto mb-4 flex gap-4">
        <AddProductButton
          variant="outlined"
          onClick={handleOpenCurrenciesModal}
        >
          {t("products_settings.currencies_config")}
        </AddProductButton>
        <AddProductButton
          variant="contained"
          onClick={handleOpenProductsModal}
          startIcon={<Plus size="w-3 h-3" className="stroke-current" />}
        >
          {t("products_settings.add_product")}
        </AddProductButton>
      </div>
      <DataGrid
        columns={columns}
        rows={productsMapped}
        getRowSpacing={() => ({ top: 8 })}
        pageSize={10}
        disableSelectionOnClick
        loading={isLoadingProducts || isLoadingCurrencies}
        autoHeight
      />
    </div>
  );
};
