import { useQuery } from "@tanstack/react-query";

import { QueryOptionsTyped } from "./types";
import { ApiResponse } from "@typeDefinitions";
import { ClientProgram2Dto, fetchClientPrograms2 } from "@client";

export const fetchClientProgramsQueryKey = "fetchClientProgramsQueryKey";

export const useFetchClientProgramsQuery = (
  id: number | string,
  page: number,
  options?: QueryOptionsTyped<ApiResponse<ClientProgram2Dto[]>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchClientProgramsQueryKey, Number(id), page],
    () => {
      const paginated = new URLSearchParams({ page: (page + 1).toString() });
      return fetchClientPrograms2(id, paginated);
    },
    options,
  );

  return {
    programs: data?.data,
    meta: data?.meta,
    ...rest,
  };
};
