import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";
import {
  PdfColorResponseType,
  PdfImageResponseType,
  PdfResponseType,
  PdfSectionResponseType,
  UploadConfirmPdfBodyType,
  UploadPdfBodyType,
  UploadPdfResponseType,
} from "@Pdf/types/pdfTypes";

export const getPdfColorsAPI = (): Promise<Array<PdfColorResponseType>> =>
  fetchData("/dietitian/clinic/program-pdf-colors", APIMethods.GET).then(
    response => response.data,
  );

export const getPdfCoverImagesAPI = (): Promise<Array<PdfImageResponseType>> =>
  fetchData("/dietitian/clinic/program-pdf-main-images", APIMethods.GET).then(
    response => response.data,
  );

export const getPdfAPI = (programId: number): Promise<PdfResponseType> =>
  fetchData(`/dietitian/programs/${programId}/pdf`, APIMethods.GET).then(
    response => response.data,
  );

export const getPdfSectionAPI = (
  programId: number,
  sectionId: number,
): Promise<PdfSectionResponseType> =>
  fetchData(
    `/dietitian/programs/${programId}/pdf/section/${sectionId}`,
    APIMethods.GET,
  ).then(response => response.data);

export const uploadPdfAPI = (
  id: number,
  formData: FormData,
  signal: AbortSignal,
) =>
  fetchData(
    `/dietitian/patient-programs/${id}/upload-pdf`,
    APIMethods.POST,
    formData,
    undefined,
    { "Content-Type": "multipart/form-data" },
    undefined,
    signal,
  );

export const uploadConfirmPdfAPI = (
  id: number,
  data: UploadConfirmPdfBodyType,
  signal: AbortSignal,
) =>
  fetchData(
    `/dietitian/patient-programs/${id}/send-uploaded-pdf`,
    APIMethods.POST,
    data,
    undefined,
    {},
    undefined,
    signal,
  );

export const uploadTemporaryPdfAPI = (
  id: number,
  data: UploadPdfBodyType,
  signal: AbortSignal,
): Promise<UploadPdfResponseType> =>
  fetchData(
    `/dietitian/patient-programs/${id}/temporary-upload-pdf`,
    APIMethods.POST,
    data,
    undefined,
    { "Content-Type": "multipart/form-data" },
    undefined,
    signal,
  ).then(response => response.data);

export const uploadS3PdfAPI = (
  url: string,
  formData: FormData,
  signal: AbortSignal,
) =>
  fetch(url, {
    method: APIMethods.PUT,
    body: formData,
    signal,
    headers: { "Content-Type": "multipart/form-data" },
  });

export const uploadPdfMainImageAPI = (
  formData: FormData,
  signal?: AbortSignal,
) =>
  fetchData(
    `/dietitian/clinic/program-pdf-main-images`,
    APIMethods.POST,
    formData,
    undefined,
    { "Content-Type": "multipart/form-data" },
    undefined,
    signal,
  );

export const deletePdfMainImageAPI = (id: number) =>
  fetchData(
    `/dietitian/clinic/program-pdf-main-images/${id}`,
    APIMethods.DELETE,
  );

export const createPdfColorAPI = (data: { color: string }) =>
  fetchData(`/dietitian/clinic/program-pdf-colors`, APIMethods.POST, data);

export const deletePdfColorAPI = (id: number) =>
  fetchData(`/dietitian/clinic/program-pdf-colors/${id}`, APIMethods.DELETE);
