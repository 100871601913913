import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MutationOptions } from "../types";
import { fetchDietitianProgramScheduleKey } from "./useFetchDietitianProgramScheduleQuery";
import { fetchDietitianClientProgramScheduleKey } from "./useFetchDietitianClientProgramScheduleQuery";
import { clearSchedule } from "@client/schedule";

export const useClearScheduleMutation = (
  programId: number,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation(() => clearSchedule(programId), {
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries([fetchDietitianProgramScheduleKey]);
      queryClient.invalidateQueries([fetchDietitianClientProgramScheduleKey]);
      options?.onSuccess && options.onSuccess();
    },
  });
};
