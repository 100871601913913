import { Stack } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useMemo, useState } from "react";
import {
  CalendarDayTitle,
  CalendarDayWrapper,
} from "@views/dietician/PatientMonitoring2/components/CalendarDays/CalendarDays.styled";
import { capitalizeFirstLetter } from "@views/dietician/PatientMonitoring2/components/CalendarDays/CalendarDaysUtils";
import CalendarDays from "@views/dietician/PatientMonitoring2/components/CalendarDays/CalendarDays";
import { CalendarDaysOfProps } from "@views/dietician/PatientMonitoring2/components/CalendarDays/CalendarDayOfType";
import CalendarDayItemMonth from "@views/dietician/PatientMonitoring2/components/CalendarDays/CalendarDayItemMonth";
import { useFetchMonitoringCalendarDaysByMonths } from "@hooks/queries/client/monitoring/useFetchMonitoringCalendarDaysByMonths";
import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import { CalendarMonthMonitoringResource } from "@client/resources/CalendarMonthMonitoringResource";

const CalendarDaysOfMonth = (props: CalendarDaysOfProps) => {
  const { patientId } = useMonitoring();

  const {
    selectedDays: { value: selectDays, setValue: setSelectDays },
  } = props;

  const [periodDays, setPeriodDays] = useState<{
    startDate: Dayjs;
    endDate: Dayjs;
  }>(() => initDays());

  const { data: calendarMonthsData } = useFetchMonitoringCalendarDaysByMonths({
    patientId,
    dateFrom: periodDays.startDate.format("YYYY-MM-DD"),
    dateTo: periodDays.endDate.format("YYYY-MM-DD"),
  });

  const handleOnClickMonth = (item: Dayjs) => {
    if (!(item.isSame(selectDays.from) && item.isSame(selectDays.to))) {
      setSelectDays({
        from: item.clone().startOf("month"),
        to: item.clone().endOf("month"),
      });
    }
  };

  const addMonths = (months: number) => {
    setPeriodDays(rest => ({
      ...rest,
      startDate: rest.startDate.clone().add(months, "month"),
      endDate: rest.endDate.clone().add(months, "month"),
    }));
  };

  const renderMonths = (firstDayOfYear: Dayjs) => {
    const months = [];

    for (
      let firstDayOfMonth = dayjs(periodDays.startDate);
      firstDayOfMonth.isBefore(periodDays.endDate);
      firstDayOfMonth = firstDayOfMonth.add(1, "month")
    ) {
      if (firstDayOfMonth.year() !== firstDayOfYear.year()) {
        continue;
      }
      const apiDay: CalendarMonthMonitoringResource | null =
        calendarMonthsData?.data.find(
          d => d.date === firstDayOfMonth.format("YYYY-MM"),
        ) || null;

      months.push(
        <CalendarDayWrapper
          key={firstDayOfMonth.format("DD.MM.YYYY")}
          onClick={() => handleOnClickMonth(firstDayOfMonth)}
          selected={selectDays.from.isSame(firstDayOfMonth)}
        >
          <CalendarDayItemMonth
            text={capitalizeFirstLetter(firstDayOfMonth.format("MMMM"))}
            progress={apiDay?.progress ?? 0}
            selected={selectDays.from.isSame(firstDayOfMonth)}
            notification={apiDay?.notification ?? false}
            today={firstDayOfMonth.month() === dayjs().month()}
          />
        </CalendarDayWrapper>,
      );
    }

    return months;
  };

  const renderYears = () => {
    const years = [];
    for (
      let firstDayOfYear = dayjs(periodDays.startDate).startOf("year");
      firstDayOfYear.format("YYYY") <= periodDays.endDate.format("YYYY");
      firstDayOfYear = firstDayOfYear.add(1, "year")
    ) {
      let month = 0;
      for (
        let firstDayOfMonth = dayjs(periodDays.startDate);
        firstDayOfMonth.isBefore(periodDays.endDate);
        firstDayOfMonth = firstDayOfMonth.add(1, "month")
      ) {
        if (firstDayOfMonth.year() !== firstDayOfYear.year()) {
          continue;
        }
        month = month + 1;
      }
      years.push(
        <Stack
          key={firstDayOfYear.format("DD.MM.YYYY")}
          direction="column"
          flex={month}
        >
          <CalendarDayTitle>{firstDayOfYear.format("YYYY")}</CalendarDayTitle>
          <Stack direction="row">{renderMonths(firstDayOfYear)}</Stack>
        </Stack>,
      );
    }
    return years;
  };

  const daysComponents = useMemo(
    () => renderYears(),
    [selectDays, periodDays, calendarMonthsData],
  );

  return (
    <CalendarDays
      addNextDays={() => addMonths(1)}
      addPrevDays={() => addMonths(-1)}
    >
      {daysComponents}
    </CalendarDays>
  );
};

const initDays = () => {
  const startOfCurrentMonth = dayjs().startOf("month");

  const startDate = startOfCurrentMonth.clone().add(-2, "month");
  const endDate = startOfCurrentMonth.clone().endOf("month").add(1, "month");

  return {
    startDate: startDate,
    endDate: endDate,
  };
};

export default CalendarDaysOfMonth;
