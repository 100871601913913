import { useMemo } from "react";
import { FormProvider } from "react-hook-form";

import {
  PatientProfileTargetResourceDto,
  SystemQuestionDto,
  UserTargetBodyWeightAnswerResourceDto,
} from "@client/surveys";
import { useUpdateSingedSurveyMutation } from "@hooks/queries/surveys";

import { useAppTranslation } from "@hooks";
import { decimalInput2ThreeDigitsNotFixed } from "@utils/inputs";
import { useUpdateValidationContext } from "../../context/useUpdateValidationContext";
import { useParseUrl } from "../../useParseUrl";
import { AutosaveOnBlurWatch } from "../AutosaveOnBlurWatch";
import { BlurContextProvider, FormTextFieldWrapper } from "../BlurContext";
import { Question } from "../Question/Question";
import {
  TargetBodyWeightAnswerFormInputs,
  mapTargetBodyWeightAnswerForm,
  mapTargetBodyWeightAnswerRequest,
  useTargetBodyWeightAnswerForm,
} from "../forms";

interface SystemQuestionUserTargetBodyWeightProps {
  question: SystemQuestionDto;
  answer?: UserTargetBodyWeightAnswerResourceDto;
  listIndex: number;
  totalQuestions: number;
  readOnly?: boolean;
  options?: PatientProfileTargetResourceDto[];
}

export const SystemQuestionUserTargetBodyWeight = ({
  question: {
    title,
    config: { required },
    id,
  },
  answer,
  readOnly,
  options,
  ...rest
}: SystemQuestionUserTargetBodyWeightProps) => {
  const { t } = useAppTranslation();
  const { url } = useParseUrl();
  const mappedForm = useMemo(
    () => mapTargetBodyWeightAnswerForm(answer?.answer),
    [answer?.answer],
  );
  const { formState, handleSubmit, control, ...form } =
    useTargetBodyWeightAnswerForm(mappedForm, required);
  const { mutate, isLoading } = useUpdateSingedSurveyMutation();

  const onSubmit = (data: TargetBodyWeightAnswerFormInputs) => {
    if (url)
      mutate({ payload: mapTargetBodyWeightAnswerRequest(data, id), url });
  };

  const { errors, touchedFields } = formState;
  const hasError = !!Object.values(errors).length;

  useUpdateValidationContext(id, touchedFields, handleSubmit);

  return (
    <FormProvider
      formState={formState}
      handleSubmit={handleSubmit}
      control={control}
      {...form}
    >
      <BlurContextProvider>
        <Question
          {...rest}
          title={title}
          description={null}
          required={required}
          hasError={hasError}
          readOnly={readOnly}
        >
          <FormTextFieldWrapper
            control={control}
            name="value"
            size="small"
            placeholder={t(
              "questionnaires.questions.system_user_target_body_weight.placeholder",
            )}
            FormHelperTextProps={{ hidden: true }}
            InputProps={{
              readOnly,
              error: !readOnly && hasError,
              inputComponent: decimalInput2ThreeDigitsNotFixed,
            }}
          />
        </Question>

        <AutosaveOnBlurWatch onSubmit={onSubmit} isLoading={isLoading} />
      </BlurContextProvider>
    </FormProvider>
  );
};
