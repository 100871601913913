import { ToggleButton, ToggleButtonGroup, styled } from "@mui/material";

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  gap: 1rem;

  & .MuiToggleButtonGroup-grouped:not(:last-of-type) {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }

  & .MuiToggleButtonGroup-grouped:not(:first-of-type) {
    margin-left: 0;
    border-left: 1px solid transparent;
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;

    &.Mui-selected {
      border-left: 1px solid ${({ theme }) => theme.palette.primary.main};
    }
  }
`;

export const StyledToggleButton = styled(ToggleButton)`
  flex-direction: column;
  gap: 6px;
  align-items: center;
  border-radius: 0.75rem;
  border: 1px solid transparent;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
  background: transparent;
  text-transform: unset;
  padding: 1rem 1.5rem;
  font-weight: 600;
  line-height: 1.25rem;
  letter-spacing: 0.4px;

  &:hover {
    background: ${({ theme }) => theme.palette.primary.base};
  }

  &.Mui-selected {
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
    background: ${({ theme }) => theme.palette.primary.base};
    border-left: 1px solid ${({ theme }) => theme.palette.primary.main};

    &:hover {
      background: ${({ theme }) => theme.palette.primary.base};
    }
  }
`;
