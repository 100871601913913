import { Link, useLocation } from "react-router-dom";
import {
  ListItemButtonStyled,
  ListItemStyled,
  ListItemTextStyled,
  StyledBadge,
} from "./DrawerMenu.styled";
import { ListItemIcon, Tooltip } from "@mui/material";
import { MenuOptionIf } from "./DrawerMenu";
import { useAppTranslation, useWhatsNew } from "@hooks";
import { isSelected } from "./drawerMenuUtils";

interface MenuItemProps {
  data: MenuOptionIf;
  open: boolean;
}

export const MenuItem = ({ data, open }: MenuItemProps) => {
  const { t } = useAppTranslation();
  const { pathname } = useLocation();
  const { unreadNews } = useWhatsNew();

  const {
    icon: Icon,
    link,
    label,
    onClick,
    disabled,
    wrapper: Wrapper,
    activePaths,
  } = data;
  const selected = isSelected(link, pathname, activePaths);

  if (disabled)
    return (
      <ListItemStyled hidden={!open}>
        <Tooltip title={t("common.in_development")} arrow>
          <div className="w-full">
            <ListItemButtonStyled disabled={disabled}>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemTextStyled primary={t(label)} />
            </ListItemButtonStyled>
          </div>
        </Tooltip>
      </ListItemStyled>
    );

  if (Wrapper)
    return (
      <ListItemStyled hidden={!open}>
        <Wrapper>
          <Tooltip
            title={t(label)}
            placement="right"
            disableHoverListener={open}
          >
            <div className="w-full">
              <ListItemButtonStyled onClick={onClick}>
                <ListItemIcon>
                  <StyledBadge badgeContent={unreadNews} color="error">
                    <Icon />
                  </StyledBadge>
                </ListItemIcon>
                <ListItemTextStyled primary={t(label)} />
              </ListItemButtonStyled>
            </div>
          </Tooltip>
        </Wrapper>
      </ListItemStyled>
    );

  if (onClick)
    return (
      <ListItemStyled hidden={!open}>
        <ListItemButtonStyled onClick={onClick}>
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
          <ListItemTextStyled primary={t(label)} />
        </ListItemButtonStyled>
      </ListItemStyled>
    );

  return (
    <Link to={link ?? ""}>
      <ListItemStyled hidden={!open}>
        <ListItemButtonStyled selected={selected}>
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
          <ListItemTextStyled primary={t(label)} selected={selected} />
        </ListItemButtonStyled>
      </ListItemStyled>
    </Link>
  );
};
