import { useAppParams } from "@hooks";
import { useDeleteCreatorDietMealMutation } from "@hooks/queries/diets/creator";
import { useDeleteProgramDietMealMutation } from "@hooks/queries/program/dietCreator";

export const useDeleteDietMealMutation = () => {
  const { programId } = useAppParams();

  return programId
    ? useDeleteProgramDietMealMutation(programId)
    : useDeleteCreatorDietMealMutation();
};
