import { useQuery } from "@tanstack/react-query";

import { QueryOptionsTyped } from "../types";
import { buildDefaultQueryOptions } from "../common";
import {
  OptimizerSettingsResponse,
  getOptimizerSettings,
} from "@client/schedule";
import { ApiResponse } from "@typeDefinitions";

export const fetchFetchOptimizerSettingsQueryKey =
  "fetchFetchOptimizerSettingsQueryKey";

export const useFetchOptimizerSettingsQuery = (
  programId: string,
  options?: QueryOptionsTyped<ApiResponse<OptimizerSettingsResponse[]>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchFetchOptimizerSettingsQueryKey, programId],
    () => getOptimizerSettings(programId),
    buildDefaultQueryOptions(options),
  );
  return { settings: data?.data, ...rest };
};
