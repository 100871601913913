import { styled } from "@mui/material";

export const Wrapper = styled("div")`
  display: flex;
  border-radius: 8px;
  padding: 8px;
  background: ${({ theme }) => theme.palette.primary.light};
  width: 100%;
`;

export const Text = styled("span")`
  font-family: Figtree;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;
