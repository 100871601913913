import { ReactElement } from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { ColorPicker } from "@components/ColorPicker";
import { FormHelperText } from "@mui/material";
import { FieldValues } from "react-hook-form/dist/types";

export interface FormColorPickerProps<T extends FieldValues>
  extends Omit<ControllerProps<T>, "render"> {
  outlined?: boolean;
}

export const FormColorPicker = <T extends FieldValues>({
  outlined,
  ...rest
}: FormColorPickerProps<T>): ReactElement => {
  return (
    <Controller
      {...rest}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <ColorPicker onChange={onChange} value={value} outlined={outlined} />
          <FormHelperText error variant="outlined">
            {error?.message ? error.message : undefined}
          </FormHelperText>
        </>
      )}
    />
  );
};
