import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Logo = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="16" height="14" viewBox="0 0 16 14">
      <g clipPath="url(#clip0_3684_20585)">
        <path
          opacity="0.996"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.45913 -0.0102539C6.79928 -0.0102539 7.1394 -0.0102539 7.47954 -0.0102539C9.18336 0.138592 10.6459 0.809169 11.8673 2.00148C10.221 3.66423 8.57479 5.32699 6.92852 6.98975C8.56146 8.64936 10.2043 10.2984 11.8571 11.937C11.8707 11.9575 11.8707 11.978 11.8571 11.9985C10.6322 13.1748 9.17303 13.8386 7.47954 13.9897C7.1394 13.9897 6.79928 13.9897 6.45913 13.9897C4.35952 13.791 2.66224 12.8604 1.3673 11.198C0.572275 10.1094 0.113092 8.89143 -0.0102539 7.544C-0.0102539 7.1745 -0.0102539 6.805 -0.0102539 6.43549C0.238554 4.13791 1.31338 2.34856 3.21424 1.06746C4.21156 0.446813 5.29319 0.0875751 6.45913 -0.0102539Z"
          fill="#801FFF"
        />
        <path
          opacity="0.99"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.9897 6.35337C15.9897 6.68865 15.9897 7.02395 15.9897 7.35923C15.9696 7.44409 15.9628 7.53306 15.9693 7.62609C14.1053 7.62267 12.2414 7.62609 10.3774 7.63636C10.6584 8.33264 11.1754 8.70898 11.9285 8.76539C12.7445 8.80316 13.4587 8.5534 14.0713 8.01612C14.504 8.51641 14.9258 9.02618 15.3366 9.54545C14.8161 10.0709 14.1971 10.4336 13.4795 10.6334C13.0655 10.7553 12.6437 10.8237 12.2142 10.8387C10.9378 9.55146 9.65893 8.26847 8.37744 6.98973C9.69293 5.67679 11.0059 4.35958 12.3162 3.03812C13.5583 3.11019 14.5617 3.63365 15.3264 4.6085C15.6905 5.13594 15.9116 5.71756 15.9897 6.35337ZM11.8468 5.01905C12.4296 4.97145 12.9228 5.1562 13.3264 5.57331C13.4676 5.76175 13.5527 5.97389 13.5815 6.20967C12.5067 6.23363 11.4319 6.23703 10.357 6.21993C10.5964 5.49676 11.093 5.09647 11.8468 5.01905Z"
          fill="#131413"
        />
      </g>
      <defs>
        <clipPath id="clip0_3684_20585">
          <rect width="16" height="14" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
