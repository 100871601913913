import { useEffect } from "react";
import { Path, useFormContext } from "react-hook-form";
import { ProductFormInput } from "./useProductForm";
import { useIdMapContext } from "@context";

export const useCalcNutrients = () => {
  const { idMap } = useIdMapContext();

  const fieldName = (id: number) =>
    `nutrients.${idMap[id]}.value` as Path<ProductFormInput>;

  const getField = (id: number) => watch(fieldName(id)) as number | undefined;

  const { setValue, watch } = useFormContext<ProductFormInput>();
  const carbValue = getField(CARBS_ID);
  const dietaryValue = getField(DIETARY_FIBER_ID);
  const glycemicIndexValue = getField(GLYCEMIC_INDEX_ID);
  const assimilableCarbohydratesValue = getField(ASSIMILABLE_CARBOHYDRATES_ID);
  const proteinValue = getField(PROTEIN_ID);
  const fatValue = getField(FATS_ID);

  useEffect(() => {
    if (!carbValue || !dietaryValue) {
      setValue(fieldName(ASSIMILABLE_CARBOHYDRATES_ID), 0);
      return;
    }

    const score = calcAssimilableCarbohydrates(carbValue, dietaryValue);
    setValue(
      fieldName(ASSIMILABLE_CARBOHYDRATES_ID),
      score >= 0 ? Math.round(score) : 0,
    );
  }, [carbValue, dietaryValue]);

  useEffect(() => {
    if (!carbValue || !glycemicIndexValue) {
      setValue(fieldName(GLYCEMIC_LOAD_ID), 0);
      return;
    }

    const score = calcGlycemicLoad(
      assimilableCarbohydratesValue ?? 0,
      glycemicIndexValue,
    );
    setValue(fieldName(GLYCEMIC_LOAD_ID), score >= 0 ? Math.round(score) : 0);
  }, [carbValue, glycemicIndexValue]);

  useEffect(() => {
    if (!assimilableCarbohydratesValue) {
      setValue(fieldName(CARBOHYDRATE_EXCHANGERS_ID), 0);
      return;
    }

    const score = calcCarbohydrateExchangers(assimilableCarbohydratesValue);
    setValue(
      fieldName(CARBOHYDRATE_EXCHANGERS_ID),
      score >= 0 ? Math.round(score) : 0,
    );
  }, [assimilableCarbohydratesValue]);

  useEffect(() => {
    if (!proteinValue || !fatValue) {
      setValue(fieldName(PROTEIN_AND_FAT_EXCHANGERS_ID), 0);
      return;
    }

    const score = calcProteinAndFatExchangers(proteinValue, fatValue);
    setValue(
      fieldName(PROTEIN_AND_FAT_EXCHANGERS_ID),
      score >= 0 ? Math.round(score) : 0,
    );
  }, [proteinValue, fatValue]);
};

export const ENERGY_ID = 208;
export const PROTEIN_ID = 203;
export const FATS_ID = 204;
export const CARBS_ID = 205;
export const DIETARY_FIBER_ID = 291;
export const GLYCEMIC_INDEX_ID = 1109;
export const ASSIMILABLE_CARBOHYDRATES_ID = 1110;
export const GLYCEMIC_LOAD_ID = 1111;
export const CARBOHYDRATE_EXCHANGERS_ID = 1112;
export const PROTEIN_AND_FAT_EXCHANGERS_ID = 1113;

export const calcAssimilableCarbohydrates = (
  carb: number,
  dietaryFiber: number,
) => carb - dietaryFiber;
export const calcGlycemicLoad = (carb: number, glycemicIndex: number) =>
  (carb * glycemicIndex) / 100;
export const calcCarbohydrateExchangers = (assimilableCarbohydrates: number) =>
  assimilableCarbohydrates / 10;
export const calcProteinAndFatExchangers = (protein: number, fat: number) =>
  (protein * 4 + fat * 9) / 100;
