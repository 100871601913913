import { useProgramMealColorsExtractor } from "@hooks";
import { Spinner } from "@components/Spinner";
import { ProgramSummary } from "@views/dietician/ProgramSchedule/components/ProgramSummary";
import { ScheduleClipboardContextProvider } from "@views/dietician/ProgramSchedule/ScheduleClipboardContext";
import { ProgramScheduleGrid } from "@views/dietician/ProgramSchedule/components/ProgramScheduleGrid";
import { DietitianProgramScheduleDay } from "@client/schedule";
import {
  FetchPatientProgramResponse,
  FetchProgramResponse,
} from "@client/program";

interface ProgramScheduleViewProps {
  scheduleData: DietitianProgramScheduleDay[] | undefined;
  programId: number;
  clientId: number;
  isLoading?: boolean;
  isProgramLoading?: boolean;
  program: FetchProgramResponse | FetchPatientProgramResponse | undefined;
  isGenerating?: boolean;
}

export const ProgramScheduleView = ({
  scheduleData,
  programId,
  clientId,
  isLoading,
  program,
  isProgramLoading,
  isGenerating,
}: ProgramScheduleViewProps) => {
  const recipeColors = useProgramMealColorsExtractor(scheduleData);

  if (isProgramLoading)
    return <Spinner className="bg-transparent w-full h-96" />;

  return (
    <div className="w-full">
      {!program ? (
        <Spinner className="bg-transparent w-full h-96" />
      ) : (
        program && (
          <ProgramSummary programDays={program.days} programId={programId} />
        )
      )}
      {isGenerating ? (
        <Spinner className="bg-transparent w-full h-96" />
      ) : scheduleData?.length || program ? (
        <ScheduleClipboardContextProvider>
          <ProgramScheduleGrid
            patientId={clientId}
            program={program}
            scheduleData={scheduleData}
            recipeColors={recipeColors}
            isLoading={isLoading}
          />
        </ScheduleClipboardContextProvider>
      ) : undefined}
    </div>
  );
};
