import { array, object } from "yup";

import { NutrientDto, nutrientSchema } from "@client/common";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const fetchCreatorDietMealNutrients = async (
  dietId: string,
  mealId: string,
): Promise<ApiResponse<NutrientDto[]>> => {
  const response = await fetchData(
    `/dietitian/creator-2/diets/${dietId}/meals/${mealId}/nutrients`,
    APIMethods.GET,
  );

  return await reponseSchema.validate(response);
};

export const reponseSchema = object().shape({
  data: array().of(nutrientSchema).required(),
});
