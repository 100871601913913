import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";

export async function cancelVisit(
  url: string,
  payload: CancelVisitPayload,
): Promise<ApiResponse<void>> {
  return await fetchData(
    url,
    APIMethods.POST,
    payload,
    undefined,
    undefined,
    undefined,
    undefined,
    "",
  );
}

export interface CancelVisitPayload {
  reason: string;
}
