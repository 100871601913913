import {
  type FC,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  format,
  addDays,
  startOfWeek,
  isSameDay,
  addMinutes,
  setHours,
  setMinutes,
  isToday,
  areIntervalsOverlapping,
} from "date-fns";
import { Box, Typography } from "@mui/material";
import { EventWithType } from "@typeDefinitions";
import { ArrowPrev, Plus } from "@assets/icons";

import {
  WeekViewWrapper,
  WeekDay,
  EventItem,
  EventTime,
  EventTitle,
  WeekDayButton,
  EventItemText,
  EventItemTextDay,
  EventItemTextWrapper,
  StyledDayDivider,
  EventListCompact,
  CalendarContentWrapper,
} from "../MiniCalendar.styled";
import { CalendarEmptyState } from "./CalendarEmptyState";
import groupBy from "lodash/groupBy";
import { DietitianFilter } from "@views/dietician/Tasks/components/TaskDietitianFilter/DietitianFilter";
import { Spinner } from "@components/Spinner";
import { useCalendarActions } from "../../_hooks/useCalendarActions";
import { ScheduleVisitOverviewModal } from "../../../ScheduleVisit/_components/ScheduleVisitOverviewModal";
import { CreateEventButton } from "../MiniCalendar.styled";
import { useAppTranslation } from "@hooks";
import { useCalendarColors } from "@views/dietician/Calendar";
import { getEventIcon } from "../../_utils/getEventIcon";
import { BigCalendarDropdown } from "../../BigCalendar/_components/BigCalendarDropdown";
import { useCalendarTimeline } from "../../_hooks/useCalendarTimeline";
import { useEventPosition } from "../../_hooks/useEventPosition";
import { ScheduleGoogleEventOverviewModal } from "../../../ScheduleVisit/_components/ScheduleGoogleEventOverviewModal";

interface MiniCalendarDayViewProps {
  events: EventWithType[];
  currentDate: Date;
  setCurrentDate: React.Dispatch<React.SetStateAction<Date>>;
  locale: Locale;
  isLoading: boolean;
  dietitians: number;
  onAddEvent: () => void;
  handleSetStartDate: (startDate: Date) => void;
}

export const formatDayName = (date: Date, locale: Locale) => {
  if (locale.code === "pl") {
    const polishDays = ["Pn", "Wt", "Śr", "Cz", "Pt", "So", "Nd"];
    return polishDays[date.getDay() === 0 ? 6 : date.getDay() - 1];
  }
  const englishDays = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
  return englishDays[date.getDay() === 0 ? 6 : date.getDay() - 1];
};

const HOURS = Array.from({ length: 24 }, (_, i) => i);

export const MiniCalendarDayView: FC<MiniCalendarDayViewProps> = ({
  events,
  currentDate,
  setCurrentDate,
  locale,
  isLoading,
  onAddEvent,
  handleSetStartDate,
  dietitians,
}) => {
  const { t } = useAppTranslation();

  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const {
    handleCloseModal,
    handleEventClick,
    selectedEventId,
    anchorEl,
    selectedGoogleId,
  } = useCalendarActions();
  const currentTimeLineRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const { getNewDietitianColor, getEventStyles } = useCalendarColors();

  const containerSize = 80;
  const marginTop = 10;
  const { calculateEventPosition } = useEventPosition(containerSize, marginTop);

  const handlePrevious = () => {
    setIsInitialLoading(true);
    setCurrentDate(addDays(currentDate, -1));
  };

  const handleNext = () => {
    setIsInitialLoading(true);
    setCurrentDate(addDays(currentDate, 1));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsInitialLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [currentDate, dietitians]);

  const weekStart = startOfWeek(currentDate, { weekStartsOn: 1 });

  const dayEvents = [...events].filter(event =>
    isSameDay(event.start, currentDate),
  );

  const isDayWithoutEvents =
    !isLoading && !isInitialLoading && dayEvents.length === 0;

  const groupEventsByTime = (events: EventWithType[]) => {
    return groupBy(events, event => {
      if (event.allDay) {
        return "allDay";
      } else {
        const start = new Date(event.start);
        const end = new Date(event.end);
        return `${start.getHours()}:${start.getMinutes()}-${end.getHours()}:${end.getMinutes()}`;
      }
    });
  };

  useLayoutEffect(() => {
    const updateCurrentTimeLine = () => {
      if (currentTimeLineRef.current && isToday(currentDate)) {
        const now = new Date();
        const currentHour = now.getHours();
        const currentMinute = now.getMinutes();

        const minHour = HOURS[0];
        const hourHeight = 80;

        const top = (currentHour - minHour + currentMinute / 60) * hourHeight;

        currentTimeLineRef.current.style.top = `${top}px`;
        currentTimeLineRef.current.style.display = "block";
      } else if (currentTimeLineRef.current) {
        currentTimeLineRef.current.style.display = "none";
      }
    };

    updateCurrentTimeLine();
    const interval = setInterval(updateCurrentTimeLine, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [currentTimeLineRef.current]);

  const handleAddEvent = (timeSlot: Date) => {
    handleSetStartDate(timeSlot);
    onAddEvent();
  };

  useEffect(() => {
    if (scrollContainerRef.current) {
      // Scroll to 8 AM by default
      scrollContainerRef.current.scrollTop = 8 * 80;
    }
  }, [scrollContainerRef.current]);

  return (
    <WeekViewWrapper>
      <WeekDay>
        <WeekDayButton onClick={handlePrevious}>
          <ArrowPrev />
        </WeekDayButton>
        {Array.from({ length: 7 }).map((_, index) => {
          const day = addDays(weekStart, index);
          const isCurrentDay = isSameDay(day, currentDate);
          const isDayToday = isToday(day);

          return (
            <EventItemTextWrapper
              key={index}
              onClick={() => {
                setIsInitialLoading(true);
                setCurrentDate(day);
              }}
              active={isCurrentDay ? "true" : "false"}
              isToday={isDayToday ? "true" : "false"}
              className="cursor-pointer"
            >
              <EventItemText variant="body2">
                {formatDayName(day, locale)}
              </EventItemText>
              <EventItemTextDay
                active={isCurrentDay ? "true" : "false"}
                isToday={isDayToday ? "true" : "false"}
              >
                {format(day, "dd")}
              </EventItemTextDay>
            </EventItemTextWrapper>
          );
        })}
        <WeekDayButton onClick={handleNext}>
          <ArrowPrev className="rotate-180" />
        </WeekDayButton>
      </WeekDay>
      <Box>
        <DietitianFilter
          name="dietitians"
          setIsInitialLoading={setIsInitialLoading}
        />
        {isInitialLoading || isLoading ? (
          <div className="flex justify-center items-center">
            <Spinner />
          </div>
        ) : isDayWithoutEvents ? (
          <CalendarEmptyState />
        ) : (
          <CalendarContentWrapper
            ref={scrollContainerRef}
            className="relative mt-[28px] custom-scrollbar"
          >
            <div
              ref={currentTimeLineRef}
              className="absolute left-0 right-0 h-[2px] bg-purple-dark z-10"
              style={{ display: "none" }}
            />
            {HOURS.map((hour, index) => {
              const timeSlot = setHours(setMinutes(currentDate, 0), hour);
              const timeSlotEnd = addMinutes(timeSlot, 59);
              const slotEvents = dayEvents.filter(event => {
                if (event.allDay) {
                  return hour === HOURS[0];
                } else {
                  return areIntervalsOverlapping(
                    { start: event.start, end: event.end },
                    { start: timeSlot, end: timeSlotEnd },
                    { inclusive: true },
                  );
                }
              });

              const groupedEvents = groupEventsByTime(slotEvents);

              return (
                <div key={index} className="h-[80px]">
                  <div className="flex gap-3 items-center">
                    <Typography variant="body1" fontWeight="600">
                      {format(timeSlot, "HH:mm")}
                    </Typography>
                    <StyledDayDivider />
                  </div>
                  <div className="h-full flex justify-center items-center group">
                    {slotEvents.length === 0 && (
                      <CreateEventButton
                        className="hidden group-hover:flex -mt-5"
                        onClick={() => handleAddEvent(timeSlot)}
                      >
                        <Plus width="8px" height="8px" />
                        <span>{t("calendar.add_visit")}</span>
                      </CreateEventButton>
                    )}
                  </div>
                  {Object.entries(groupedEvents).map(
                    ([timeKey, events], index) => {
                      if (timeKey === "allDay") {
                        return (
                          <div
                            key={index}
                            style={{
                              position: "absolute",
                              right: 0,
                              display: "flex",
                              width: `calc(100% - ${60}px)`,
                              justifyContent: "end",
                              top: 0,
                              gap: 4,
                              height: HOURS.length * 80,
                              zIndex: 1,
                            }}
                          >
                            {events.slice(0, 3).map(event => {
                              const theme = getNewDietitianColor(
                                event.dietitianId,
                              );
                              const { backgroundColor, border } =
                                getEventStyles(
                                  theme?.backgroundColor,
                                  !!event.googleId,
                                );
                              return (
                                <EventItem
                                  key={event.id}
                                  onClick={e =>
                                    handleEventClick(
                                      e,
                                      event.id,
                                      event.googleId,
                                    )
                                  }
                                  style={{
                                    backgroundColor,
                                    border,
                                  }}
                                >
                                  <EventTime>
                                    {getEventIcon(event.type, {
                                      color: theme?.color,
                                    })}
                                    {t("calendar.allDay")}
                                  </EventTime>
                                  <EventTitle>{event.title}</EventTitle>
                                </EventItem>
                              );
                            })}
                          </div>
                        );
                      }
                      if (events.length > 1) {
                        const [firstEvent] = events;
                        const theme = getNewDietitianColor(
                          firstEvent.dietitianId,
                        );
                        return (
                          <div
                            key={timeKey + index}
                            className="flex gap-4 items-center"
                            style={{
                              position: "absolute",
                              top: calculateEventPosition(firstEvent).top,
                              height: calculateEventPosition(firstEvent).height,
                              left: "55px",
                              zIndex: 3,
                            }}
                          >
                            <EventListCompact direction="row" spacing={-8}>
                              {events.slice(0, 3).map((event, eventIndex) => {
                                const { backgroundColor, border } =
                                  getEventStyles(
                                    theme?.backgroundColor,
                                    !!event.googleId,
                                  );

                                return (
                                  <EventItem
                                    key={eventIndex}
                                    className="w-[120px] overflow-y-auto"
                                    onClick={e =>
                                      handleEventClick(
                                        e,
                                        firstEvent.id,
                                        firstEvent.googleId,
                                      )
                                    }
                                    style={{
                                      backgroundColor,
                                      border,
                                    }}
                                  >
                                    <EventTime>
                                      {getEventIcon(firstEvent.type, {
                                        color: theme?.color,
                                      })}
                                      {`${format(
                                        new Date(event.start),
                                        "HH:mm",
                                      )} - ${format(
                                        new Date(event.end),
                                        "HH:mm",
                                      )}`}
                                    </EventTime>
                                    <EventTitle>{event.title}</EventTitle>
                                  </EventItem>
                                );
                              })}
                            </EventListCompact>
                            <BigCalendarDropdown
                              day={currentDate}
                              todayEvents={events}
                              maxCount={3}
                              handleEventClick={handleEventClick}
                            />
                          </div>
                        );
                      } else {
                        const [firstEvent] = events;
                        const theme = getNewDietitianColor(
                          firstEvent.dietitianId,
                        );
                        const { backgroundColor, border } = getEventStyles(
                          theme?.backgroundColor,
                          !!firstEvent.googleId,
                        );

                        return (
                          <EventItem
                            key={firstEvent.id}
                            onClick={e =>
                              handleEventClick(
                                e,
                                firstEvent.id,
                                firstEvent.googleId,
                              )
                            }
                            style={{
                              position: "absolute",
                              left: `${60}px`,
                              width: `calc(100% - ${60}px)`,
                              top: calculateEventPosition(firstEvent).top,
                              height: calculateEventPosition(firstEvent).height,
                              zIndex: 2,
                              backgroundColor,
                              border,
                            }}
                          >
                            <EventTime>
                              {getEventIcon(firstEvent.type, {
                                color: theme?.color,
                              })}
                              {`${format(
                                new Date(firstEvent.start),
                                "HH:mm",
                              )} - ${format(
                                new Date(firstEvent.end),
                                "HH:mm",
                              )}`}
                            </EventTime>
                            <EventTitle>{firstEvent.title}</EventTitle>
                          </EventItem>
                        );
                      }
                    },
                  )}
                </div>
              );
            })}
          </CalendarContentWrapper>
        )}
      </Box>
      {selectedEventId && (
        <ScheduleVisitOverviewModal
          anchorEl={anchorEl}
          onClose={handleCloseModal}
          eventId={selectedEventId}
        />
      )}
      {selectedGoogleId && (
        <ScheduleGoogleEventOverviewModal
          anchorEl={anchorEl}
          onClose={handleCloseModal}
          googleId={selectedGoogleId}
        />
      )}
    </WeekViewWrapper>
  );
};
