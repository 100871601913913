import { Box, styled } from "@mui/material";
import image from "@assets/img/noResults.svg";
import {
  Image,
  Text,
  Title,
} from "@views/dietician/PatientMonitoring2/components/MealsBox/NoResult.styled";

type NoResultProps = {
  title: string;
  text: string;
};

const NoResult = ({ title, text }: NoResultProps) => {
  return (
    <Box display="flex" flexDirection="column" gap="16px" alignItems="center">
      <Image src={image} />
      <Title>{title}</Title>
      <Text>{text}</Text>
    </Box>
  );
};

export default NoResult;
