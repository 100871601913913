import { SubscriptionPurchaseRequest } from "@client/resources/SubscriptionPurchaseRequest";
import { APIMethods, NoContentApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const postNewSubscriptionPurchase = async (
  payload: SubscriptionPurchaseRequest,
): Promise<NoContentApiResponse> => {
  return await fetchData(
    "/dietitian/clinic/new-subscription-purchase",
    APIMethods.POST,
    payload,
  );
};
