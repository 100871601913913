import { ModalWrapper } from "@components/ModalWrapperNew";
import { OuterLabel } from "@components/OuterLabel";
import { useAppParams, useAppTranslation } from "@hooks";
import { usePostDietMealsSaveToCollectionBaseMutation } from "@hooks/queries/diets";
import { TextField } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";

interface SaveMealToBaseModalProps {
  open: boolean;
  onClose: () => void;
  mealId: string | number;
  name?: string;
}

export const SaveMealToBaseModal = ({
  open,
  onClose,
  mealId,
  name = "",
}: SaveMealToBaseModalProps) => {
  const { t } = useAppTranslation();
  const { dietId } = useAppParams();
  const [value, setValue] = useState(name);

  const { mutate, isLoading } = usePostDietMealsSaveToCollectionBaseMutation({
    onSuccess: () => {
      onClose();
      toast.success(t("diet.save_as_modal.toast_success"));
    },
  });

  const handleSave = () => {
    mutate({ dietId, mealId: `${mealId}`, payload: { name: value } });
  };

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      onSubmit={handleSave}
      title={t("diet.save_as_modal.title")}
      loading={isLoading}
      disabled={!value}
    >
      <OuterLabel label={t("diet.save_as_modal.collection_name")}>
        <TextField
          placeholder={t("diet.save_as_modal.placeholder")}
          size="small"
          fullWidth
          value={value}
          onChange={e => setValue(e.target.value)}
        />
      </OuterLabel>
    </ModalWrapper>
  );
};
