import { Control, Controller, Path } from "react-hook-form";

import { MultiSelect, MultiSelectProps } from "@components/MultiSelect";
import { FieldValues } from "react-hook-form/dist/types";

/**
 * multi select component with React Hook Form Controller
 */
export const FormMultiselect = <T extends FieldValues>(
  props: FormMultiselectProps<T>,
) => {
  const { name, control, error, onRemove, ...rest } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onBlur, onChange, value, ref } }) => (
        <MultiSelect
          ref={ref}
          name={name}
          onRemove={onRemove}
          onBlur={onBlur}
          onChange={onChange}
          value={value as any[] | undefined}
          error={error}
          {...rest}
        />
      )}
    />
  );
};

interface FormMultiselectProps<T extends FieldValues> extends MultiSelectProps {
  name: Path<T>;
  control: Control<T>;
  error?: string;
  /** isLoading determines whether to show a spinner in list or not */
  isLoading?: boolean;
  noInput?: boolean;
  noPencilIcon?: boolean;
}
