import { QueryOptionsTyped } from "@hooks/queries";
import { useQuery } from "@tanstack/react-query";
import {
  fetchPatientLastBodyMeasurementImages,
  FetchPatientLastBodyMeasurementImagesParams,
  FetchPatientLastBodyMeasurementImagesResponse,
} from "@client/patient/bodyMeasurement/fetchPatientLastBodyMeasurementImages";
import { patientBodyMeasurementKeys } from "@hooks/queries/client/bodyMeasurement/patientBodyMeasurementKey";

const useFetchPatientLastBodyMeasurementImages = (
  params: FetchPatientLastBodyMeasurementImagesParams,
  options?: QueryOptionsTyped<FetchPatientLastBodyMeasurementImagesResponse>,
) => {
  return useQuery({
    queryKey: patientBodyMeasurementKeys.lastImages(params.patientId),
    queryFn: () => fetchPatientLastBodyMeasurementImages(params),
    ...options,
  });
};

export default useFetchPatientLastBodyMeasurementImages;
