import * as Sentry from "@sentry/react";

if (import.meta.env.VITE_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["alloweat.be", /^https:\/\/api\.alloweat\.be\/api/, "alloweat.app", /^https:\/\/api\.alloweat\.app\/api/],
    environment: import.meta.env.VITE_APP_ENVIRONMENT,
    enabled: import.meta.env.VITE_APP_ENVIRONMENT !== "local",
    replaysOnErrorSampleRate: 1.0,
  });
}
