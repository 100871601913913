import dayjs, { Dayjs } from "dayjs";

type LastLoginConfigItemType = {
  days: number;
  color: {
    text: string;
    background: string;
  };
  textKey: string;
};

const LastLoginConfig: LastLoginConfigItemType[] = [
  {
    days: -1,
    color: {
      text: "#B53A45",
      background: "rgba(242, 64, 79, 0.08)",
    },
    textKey: "monitoring2.summary_cards.last_login_days.inactive",
  },
  {
    days: 0,
    color: {
      text: "#2A856C",
      background: "#EFF9F7",
    },
    textKey: "monitoring2.summary_cards.last_login_days.0",
  },
  {
    days: 1,
    color: {
      text: "#2A856C",
      background: "#EFF9F7",
    },
    textKey: "monitoring2.summary_cards.last_login_days.1",
  },
  {
    days: 2,
    color: {
      text: "#2A856C",
      background: "#EFF9F7",
    },
    textKey: "monitoring2.summary_cards.last_login_days.2",
  },
  {
    days: 3,
    color: {
      text: "#BF8000",
      background: "rgba(251, 147, 31, 0.08)",
    },
    textKey: "monitoring2.summary_cards.last_login_days.3",
  },
  {
    days: 11,
    color: {
      text: "#B53A45",
      background: "rgba(242, 64, 79, 0.08)",
    },
    textKey: "monitoring2.summary_cards.last_login_days.3",
  },
];

export const getLastLoginItemConfig = (
  day: Dayjs | null,
): LastLoginConfigItemType => {
  if (day === null) {
    return getConfigEqualDay(-1);
  }

  if (day.isToday()) {
    return getConfigEqualDay(0);
  }

  if (isSameDay(day, -1)) {
    return getConfigEqualDay(1);
  }

  if (isSameDay(day, -2)) {
    return getConfigEqualDay(2);
  }

  const diffDays = dayjs().diff(day, "days", false);

  const config = LastLoginConfig.slice()
    .reverse()
    .find(i => i.days <= diffDays);
  if (!config) {
    throw new Error(`Not implemented by ${day} days`);
  }

  return config;
};

const isSameDay = (day: Dayjs, value: number) => {
  return day
    .clone()
    .startOf("day")
    .isSame(dayjs().startOf("day").add(value, "day"));
};
const getConfigEqualDay = (day: number): LastLoginConfigItemType => {
  const config = LastLoginConfig.find(i => i.days === day);
  if (!config) {
    throw new Error(`Not implemented by ${day} days`);
  }
  return config;
};
