import { useAppTranslation } from "@hooks";
import {
  GridColumnHeaderParams,
  GridColumns,
  GridRenderCellParams,
  GridSortModel,
} from "@mui/x-data-grid";
import { ClinicClientDtoNew } from "@client/settings";
import { StyleHeaders } from "@components/MuiDataGrid";
import { AvatarComponent } from "../AvatarComponent";
import { NutritionProgramComponent } from "../NutritionProgramComponent";
import { ArrowsUpDown } from "@assets/icons";
import {
  StyledDietitian,
  StyledIconButton,
  StyledMuiDataGrid,
} from "./ClientsTable.styled";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { Metadata } from "@typeDefinitions";
import { ActionsComponent } from "../ActionsComponent";
import { ClinicClientsSortInput } from "@typeDefinitions/types";
import { MobileAppComponent } from "../MobileAppComponent";
import { VisitsComponent } from "../VisitsComponent";
import {
  getEnumValuesAsNumbers,
  GridPaginationWrapper,
  ITEMS_ON_PAGE,
} from "@components/PaginationNew";
import { EmptyStateCardForGrid } from "@views/emptyStates/common.styled";
import { FiltersNoResults } from "@views/emptyStates/FiltersNoResults";
import { ChatboxContext } from "@context/ChatboxContext";
import { useUnreadMessages } from "@hooks/talkjs";
import { ClientCustomTags } from "components/ClientCustomTags";
import { CustomTag } from "@client/customTags/fetchCustomTags";
import useNewMobileAppFeatureEnabled from "@features/useNewMobileAppFeatureEnabled";

interface ClientsTableProps {
  clients?: ClinicClientDtoNew[];
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  perPage: number;
  setPerPage: Dispatch<SetStateAction<number>>;
  meta?: Metadata;
  isFetching: boolean;
  clearFilters: () => void;
  submitSort: Dispatch<SetStateAction<ClinicClientsSortInput | undefined>>;
  onTagsUpdated?: (clientId: number, tags: CustomTag[]) => void;
}

export const ClientsTable = ({
  clients,
  page,
  setPage,
  perPage,
  setPerPage,
  meta,
  isFetching,
  submitSort,
  clearFilters,
  onTagsUpdated,
}: ClientsTableProps) => {
  const { t } = useAppTranslation();
  const newMobileFeature = useNewMobileAppFeatureEnabled();
  const { breakpoints } = useTheme();
  const isMatchTablet = useMediaQuery(`${breakpoints.up("medium")}`);
  const isOnePage = !!meta?.lastPage && meta?.lastPage <= 1;
  const [sortName, setSortName] = useState<ClinicClientsSortInput | undefined>(
    undefined,
  );

  const { session } = useContext(ChatboxContext);
  const { unreadIds } = useUnreadMessages(session);
  const { openChat } = useContext(ChatboxContext);
  const getNextSortOrder = (): ClinicClientsSortInput | undefined => {
    if (sortName === undefined) {
      return { orderBy: "name", orderDir: "asc" };
    } else if (sortName.orderDir === "asc") {
      return { orderBy: "name", orderDir: "desc" };
    } else {
      return undefined;
    }
  };

  const handleSortByName = () => {
    const nextSortOrder = getNextSortOrder();
    submitSort(nextSortOrder);
    setSortName(nextSortOrder);
    setPage(0);
  };

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    const programSortModel = sortModel.find(
      el => el.field === "nutritionProgram",
    );
    setPage(0);

    programSortModel
      ? submitSort({
          orderBy: "programs",
          orderDir: programSortModel.sort ?? undefined,
        })
      : submitSort(undefined);
  }, []);

  const columns = useMemo<GridColumns>(() => {
    return [
      {
        field: "fullName",
        headerName: "",
        flex: 1,
        minWidth: 240,
        maxHeight: 76,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => (
          <AvatarComponent
            id={params.row.id}
            fullName={`${params.row.name}`}
            avatarUrl={params.row.avatar}
            subscriptionsEnd={params.row.subscriptionsEnd}
            isActiveClient={params.row.isActiveClient}
            patient
          />
        ),
      },
      {
        field: "tags",
        headerName: t("clients.table.tags"),
        minWidth: 255,
        disableColumnMenu: true,
        sortable: false,
        cellClassName: "group",
        renderCell: (params: GridRenderCellParams) => (
          <ClientCustomTags
            clientId={params.row.id}
            tags={params.row.tags}
            onUpdated={onTagsUpdated}
            editable
            triggerClassName="invisible group-hover:visible"
            maxRowsCount={2}
          />
        ),
      },
      {
        field: "nutritionProgram",
        headerName: t("clients.table.nutrition_program"),
        flex: 1,
        minWidth: 176,
        disableColumnMenu: true,
        sortable: !newMobileFeature,
        renderHeader: (params: GridColumnHeaderParams) => (
          <>
            <p>{t("clients.table.nutrition_program")}</p>
            {!newMobileFeature && (
              <StyledIconButton>
                <ArrowsUpDown />
              </StyledIconButton>
            )}
          </>
        ),
        renderCell: (params: GridRenderCellParams) => (
          <NutritionProgramComponent
            status={params.row.programStatus}
            id={params.row.id}
            programStarts={params.row.programStarts}
            programEnds={params.row.programEnds}
            isActiveClient={params.row.isActiveClient}
          />
        ),
      },
      {
        field: "mobileApp",
        headerName: t("clients.table.mobile_app"),
        flex: 1,
        minWidth: 65,
        maxWidth: 65,
        disableColumnMenu: true,
        sortable: false,
        headerAlign: "center",
        align: "center",
        renderCell: (params: GridRenderCellParams) => (
          <MobileAppComponent
            isMobileAppActive={params.row.isMobileAppActive}
          />
        ),
      },
      {
        field: "visit",
        headerName: t("clients.visit"),
        flex: 1,
        minWidth: 100,
        maxWidth: 100,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => (
          <VisitsComponent
            id={params.row.id}
            scheduleStatus={params.row.scheduleStatus}
            scheduleId={params.row.scheduleId}
            scheduleDate={params.row.scheduleDate}
          />
        ),
      },
      {
        field: "dietitian",
        headerName: t("clients.filters.lead_dietitian"),
        flex: 1,
        minWidth: 160,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => (
          <StyledDietitian>{params.row.leadDietitian}</StyledDietitian>
        ),
      },
      {
        field: "actions",
        headerName: "",
        flex: 1,
        minWidth: isMatchTablet ? 78 : 60,
        disableColumnMenu: true,
        sortable: false,
        headerAlign: "right",
        align: "right",
        renderCell: (params: GridRenderCellParams) => (
          <ActionsComponent
            id={params.row.id}
            isActiveApp={params.row.isMobileAppActive}
            unreadIds={unreadIds}
            isActiveClient={params.row.isActiveClient}
            hasEmail={params.row.hasEmail}
            onOpenChat={() =>
              openChat({
                id: params.row.id.toString(),
                email: params.row.email,
                name: params.row.name,
              })
            }
            name={params.row.name}
          />
        ),
      },
    ];
  }, [t, newMobileFeature]);

  return (
    <div className="relative">
      <StyleHeaders className="headerItem stickyName">
        {t("clients.table.full_name")}
        <StyledIconButton size="small" onClick={handleSortByName}>
          <ArrowsUpDown />
        </StyledIconButton>
      </StyleHeaders>
      <StyleHeaders className="headerItem stickyAction">
        {t("clients.table.actions")}
      </StyleHeaders>

      <StyledMuiDataGrid
        columns={columns}
        rows={mapClientsRows(clients) ?? []}
        onPageChange={setPage}
        page={page}
        rowCount={meta?.total ?? 0}
        loading={isFetching}
        pageSize={perPage}
        getRowSpacing={() => ({
          top: 8,
        })}
        autoHeight
        rowHeight={76}
        disableSelectionOnClick
        rowsPerPageOptions={getEnumValuesAsNumbers(ITEMS_ON_PAGE)}
        sortingMode="server"
        paginationMode="server"
        onSortModelChange={handleSortModelChange}
        stickyFirstCell
        stickyLastCell
        components={{
          Pagination: GridPaginationWrapper,
          NoRowsOverlay: () => (
            <EmptyStateCardForGrid className="emptyState">
              <FiltersNoResults onClick={clearFilters} />
            </EmptyStateCardForGrid>
          ),
        }}
        componentsProps={{
          pagination: { setPerPage, perPage, isOnePage },
        }}
      />
    </div>
  );
};

const mapClientsRows = (data?: ClinicClientDtoNew[]) => {
  if (!data) return [];
  return data.map(client => {
    const {
      id,
      name,
      isMobileAppActive,
      avatar,
      isActive,
      activeUntil,
      email,
      profile: { program, schedule, dietitian },
      tags,
    } = client;
    return {
      id,
      name,
      isMobileAppActive,
      programStarts: program?.startedAt,
      programEnds: program?.finishedAt,
      subscriptionsEnd: activeUntil,
      scheduleDate: schedule?.startedAt,
      scheduleStatus: schedule?.status.id,
      scheduleId: schedule?.id,
      programStatus: program?.status.id,
      leadDietitian: dietitian?.name,
      avatar,
      dietitian: dietitian?.name ?? "-",
      isActiveClient: isActive,
      hasEmail: email,
      tags,
    };
  });
};
