import React, { useMemo } from "react";

import { RecipeColor } from "@typeDefinitions";
import { DietitianProgramScheduleRecipe } from "@client/schedule";
import { MicronutrientPreview } from "@components/MicronutrientsModal";
import { useAppTranslation, useBasicNutrientsGetter } from "@hooks";

import { PCFValue } from "../../../PCFValue";
import { RecipePCFValuesWrapper } from "./components/RecipePCFValuesWrapper.styled";
import { RecipeTitleWrapper } from "./components/RecipeTitleWrapper.styled";
import { RecipeKcalWrapper } from "./components/RecipeKcalWrapper.styled";

export interface ScheduleGridRecipeProps {
  recipe: DietitianProgramScheduleRecipe;
  onChange?: () => void;
  colors?: RecipeColor;
  usedServings: number | null;
}

export const ScheduleGridRecipe = (props: ScheduleGridRecipeProps) => {
  const { recipe, colors, onChange, usedServings } = props;

  const { nutrients, title, titleEn, servings } = recipe;

  const { isPolishChosen } = useAppTranslation();
  const modifier = useMemo(
    () => (usedServings && servings ? usedServings / servings : 1),
    [servings, usedServings],
  );
  const { kcal, protein, fat, carbs } = useBasicNutrientsGetter(
    nutrients,
    modifier,
  );

  return (
    <>
      <RecipeTitleWrapper
        style={{
          backgroundColor: colors?.backgroundColor || "rgba(113,207,132,0.1)",
          color: colors?.color || "#71CF84",
        }}
        onClick={onChange}
      >
        {isPolishChosen ? title : titleEn}
      </RecipeTitleWrapper>
      <div className="flex flex-col">
        <RecipeKcalWrapper>
          <span>
            {"Kcal: "}
            <span className="font-semibold ml-1">{kcal?.toFixed(0) || 0}</span>
          </span>
          {servings && servings > 1 && (
            <span className="font-semibold text-primary">
              {`${usedServings}/${servings}`}
            </span>
          )}
        </RecipeKcalWrapper>
        <RecipePCFValuesWrapper>
          <PCFValue value={protein} name="protein" noLongNames />
          <PCFValue value={fat} name="fats" noLongNames />
          <PCFValue value={carbs} name="carbs" noLongNames />
          <MicronutrientPreview means={nutrients} noPadding />
        </RecipePCFValuesWrapper>
      </div>
    </>
  );
};
