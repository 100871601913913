import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useFetchBodyMeasurementsQuery } from "@hooks/queries/dictionaries";
import { BodyMeasurementResource } from "@client/dictionaries";
import { ReactElement } from "react";
import dayjs, { Dayjs } from "dayjs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { lazy } from "yup";
import { mapObjectDynamicFieldsRules } from "@utils/yup";
import useFetchPatientLastBodyMeasurement from "@hooks/queries/client/bodyMeasurement/useFetchPatientLastBodyMeasurement";
import {
  mapUpdateClientForm,
  mapUpdateClientRequest,
  useClientParams,
} from "@hooks";
import {
  useCreateClientMeasurementMutation,
  useFetchClientQueryNew,
  useUpdateClientMeasurementMutation,
  useUpdateClientMutation,
} from "@hooks/queries";
import { FetchClientResponse } from "@client";
import { BodyMeasurementConst } from "@consts/BodyMeasurementConst";
import { PatientMeasurementResource } from "@client/resources/PatientMeasurementResource";

export type FormPops = {
  measurementBody: {
    [key: string]: string;
  };
  date: Dayjs;
  goalId: number | null;
  targetBodyWeight: string;
};

const GoalFormWrapper = ({
  children,
  onSuccess,
}: Pick<GoalFormProps, "children" | "onSuccess">) => {
  const id = useClientParams();
  const { data: client } = useFetchClientQueryNew(id);
  const { data: bodyMeasurements } = useFetchBodyMeasurementsQuery();
  const { data: lastMeasurement } = useFetchPatientLastBodyMeasurement({
    patientId: id,
  });

  if (!bodyMeasurements || !lastMeasurement || !client) {
    return null;
  }

  return (
    <GoalForm
      onSuccess={onSuccess}
      client={client.data}
      bodyMeasurements={bodyMeasurements.data}
      lastMeasurement={lastMeasurement.data}
    >
      {children}
    </GoalForm>
  );
};

type GoalFormProps = {
  client: FetchClientResponse;
  lastMeasurement: PatientMeasurementResource | null;
  bodyMeasurements: BodyMeasurementResource[];
  children: ReactElement | ReactElement[];
  onSuccess: () => void;
};

const measurementSchema = yup
  .number()
  .transform((val, orig) => (orig == "" ? null : val))
  .nullable()
  .test(
    "is-decimal",
    "The amount should be a decimal with maximum two digits after comma",
    (val: any) => {
      if (val !== null) {
        return /^\d+(\.\d{0,2})?$/.test(val);
      }
      return true;
    },
  )
  .defined();

const formSchema = yup.object({
  measurementBody: lazy(map =>
    yup.object(mapObjectDynamicFieldsRules(map, measurementSchema)).required(),
  ),
  targetBodyWeight: measurementSchema,
});

const GoalForm = ({
  client,
  lastMeasurement,
  bodyMeasurements,
  children,
  onSuccess,
}: GoalFormProps) => {
  const {
    mutateAsync: updateMeasurementMutate,
    isLoading: updateMeasurementIsLoading,
  } = useUpdateClientMeasurementMutation(client.id);
  const {
    mutateAsync: createMeasurementMutate,
    isLoading: createMeasurementIsLoading,
  } = useCreateClientMeasurementMutation(client.id.toString());
  const { mutateAsync: updateClientMutate, isLoading: updateClientIsLoading } =
    useUpdateClientMutation(client.id);

  const form = useForm<FormPops>({
    defaultValues: {
      measurementBody: Object.fromEntries(
        bodyMeasurements.map(body => {
          const lastBodyMeasurement = lastMeasurement?.bodyMeasurements.find(
            lastBodyMeasurement => lastBodyMeasurement.body.id === body.id,
          );

          return [body.id, lastBodyMeasurement?.value?.toString() ?? ""];
        }),
      ),
      date: lastMeasurement?.date ? dayjs(lastMeasurement.date) : dayjs(),
      goalId: client.profile.targets[0]?.id ?? null,
      targetBodyWeight: client.profile.targetWeight?.toString() ?? "",
    },
    resolver: yupResolver(formSchema),
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<FormPops> = async data => {
    if (
      updateClientIsLoading ||
      createMeasurementIsLoading ||
      updateMeasurementIsLoading
    ) {
      return;
    }

    const promise1 = lastMeasurement
      ? updateMeasurementMutate({
          id: lastMeasurement.id.toString(),
          payload: mapFormDataToBodyRequest(data),
        })
      : createMeasurementMutate(mapFormDataToBodyRequest(data));

    const mappedClientData = mapUpdateClientForm(client);

    const promise2 = mappedClientData
      ? updateClientMutate(
          mapUpdateClientRequest({
            ...mappedClientData,
            targetWeight: data.targetBodyWeight,
            goal: data.goalId,
          }),
        )
      : Promise.resolve();

    await Promise.all([promise1, promise2]).then(() => {
      onSuccess();
    });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>{children}</form>
    </FormProvider>
  );
};

const mapFormDataToBodyRequest = (data: FormPops) => {
  const getMeasurement = (measurement: number) =>
    data.measurementBody[BodyMeasurementConst.height]
      ? parseFloat(data.measurementBody[measurement])
      : null;

  return {
    date: data.date.format("YYYY-MM-DD"),
    growth: getMeasurement(BodyMeasurementConst.height),
    weight: getMeasurement(BodyMeasurementConst.weight),
    bodyFatLevel: getMeasurement(BodyMeasurementConst.bodyFatLevel),
    arm: getMeasurement(BodyMeasurementConst.arm),
    tightBiceps: getMeasurement(BodyMeasurementConst.tightBiceps),
    waist: getMeasurement(BodyMeasurementConst.waist),
    hip: getMeasurement(BodyMeasurementConst.hip),
    thigh: getMeasurement(BodyMeasurementConst.thigh),
    calf: getMeasurement(BodyMeasurementConst.calf),
    water: getMeasurement(BodyMeasurementConst.water),
    bodyFatMass: getMeasurement(BodyMeasurementConst.bodyFatMass),
    skeletalMuscleMass: getMeasurement(BodyMeasurementConst.skeletalMuscleMass),
    boneTissueMass: getMeasurement(BodyMeasurementConst.boneTissueMass),
    chest: getMeasurement(BodyMeasurementConst.chest),
    abdominal: getMeasurement(BodyMeasurementConst.abdominal),
    bodyMeasurements: Object.keys(data.measurementBody)
      .map(bodyId => {
        return {
          id: parseInt(bodyId),
          value: data.measurementBody[bodyId]
            ? parseFloat(data.measurementBody[bodyId])
            : null,
        };
      })
      .filter(measurement => measurement.value != null),
  };
};

export default GoalFormWrapper;
