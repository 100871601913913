import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

import {
  NutrientDto,
  TranslationDto,
  nutrientSchema,
  translationSchema,
} from "./common";

export const fetchPatientMonitoring = async (
  patientId: string,
  date: string,
): Promise<ApiResponse<PatientMonitoringDayDto[]>> => {
  const response = await fetchData(
    `/dietitian/patients/${patientId}/monitoring?date=${date}`,
    APIMethods.GET,
  );

  return fetchPatientMonitoringSchema.validate(response);
};

export interface PatientMonitoringDayDto {
  date: string;
  schedule: ScheduleDto | null;
  foodDiary: FoodDiaryDto | null;
}

interface ScheduleDto {
  nutrients: NutrientDto[];
}

interface FoodDiaryDto {
  nutrients: NutrientDto[];
  note: string | null;
  isReported: boolean;
  programDay: ProgramDayDto | null;
}

interface ProgramDayDto {
  id: number;
  name: string;
  translations: TranslationDto[];
  targetNutrients: NutrientDto[];
}

const scheduleSchema = yup.object().shape({
  nutrients: yup.array().of(nutrientSchema).required(),
});

const programDaySchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  translations: yup.array().of(translationSchema).required(),
  targetNutrients: yup.array().of(nutrientSchema).required(),
});

const foodDiarySchema = yup.object().shape({
  nutrients: yup.array().of(nutrientSchema).required(),
  note: yup.string().nullable().default(null),
  isReported: yup.boolean().required(),
  programDay: programDaySchema.nullable().default(null),
});

const patientMonitoringDaySchema = yup.object().shape({
  date: yup.string().required(),
  schedule: scheduleSchema.nullable().default(null),
  foodDiary: foodDiarySchema.nullable().default(null),
});

const fetchPatientMonitoringSchema = yup.object().shape({
  data: yup.array().of(patientMonitoringDaySchema).required(),
});
