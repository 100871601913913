import { useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";

import * as yup from "yup";
import { isEmpty } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { Message, Send } from "@assets/icons";
import { RESET, RETRIEVE } from "@routes";
import { useAppTranslation } from "@hooks";
import { FormInput } from "@components/FormInput";
import { Button } from "@components/Button";
import { Link } from "@components/Link";

interface PinFormInput {
  pin1: string;
  pin2: string;
  pin3: string;
  pin4: string;
}

/** data schema validation */
const pinFormSchema = yup.object().shape({
  pin1: yup
    .string()
    .required()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(1, "Must be exactly 1 digit")
    .max(1, "Must be exactly 1 digit"),
  pin2: yup
    .string()
    .required()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(1, "Must be exactly 1 digit")
    .max(1, "Must be exactly 1 digit"),
  pin3: yup
    .string()
    .required()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(1, "Must be exactly 1 digit")
    .max(1, "Must be exactly 1 digit"),
  pin4: yup
    .string()
    .required()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(1, "Must be exactly 1 digit")
    .max(1, "Must be exactly 1 digit"),
});

export const Pin = () => {
  const {
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid, errors },
  } = useForm<PinFormInput>({
    resolver: yupResolver(pinFormSchema),
  });
  const { t } = useAppTranslation();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSendPin = () => {
    // validate pin with backend
    if (isValid) setFormSubmitted(true);
  };

  if (formSubmitted) return <Navigate to={RESET} />;

  return (
    <div
      className="bg-white rounded-md shadow-paper p-7 flex flex-col justify-center items-center px-48"
      style={{ minWidth: 900 }}
    >
      <Message size="w-8 h-8" />

      <div className="whitespace-pre text-center leading-normal mt-7">
        {t("auth.pin.enter_pin")}
      </div>
      <form
        className="flex flex-col items-center mt-8 w-full"
        onSubmit={handleSubmit(handleSendPin)}
      >
        <div className="flex flex-col">
          <p className="mb-4 mt-6 w-full text-center">
            {t("auth.pin.pin_code")}
          </p>
          <div className="flex">
            <FormInput
              textCenter
              control={control}
              name="pin1"
              variant="outlined"
              className="pin-input w-9 h-24 mr-5"
              maxLength={1}
            />
            <FormInput
              textCenter
              control={control}
              name="pin2"
              variant="outlined"
              className="w-9 mr-5"
              maxLength={1}
            />
            <FormInput
              textCenter
              control={control}
              name="pin3"
              variant="outlined"
              className="w-9 mr-5"
              maxLength={1}
            />
            <FormInput
              textCenter
              control={control}
              name="pin4"
              variant="outlined"
              className="w-9"
              maxLength={1}
            />
          </div>
        </div>

        {/** front-end errors */}
        {!isEmpty(errors) && (
          <p className="text-red py-2">Niepoprawny format PIN'u</p>
        )}

        <Button
          submit
          disabled={isSubmitting}
          className="mt-8 min-w-1/2"
          size="large"
          iconEnd={<Send className="ml-3" />}
        >
          {t("auth.pin.send")}
        </Button>
      </form>
      <div className="flex justify-around w-full mt-6 font-roman">
        <Link color="primary" to={RETRIEVE}>
          {t("auth.pin.back")}
        </Link>
      </div>
    </div>
  );
};
