import { ChangeEvent, useContext, useState } from "react";

import { ActionsDto, NutrientDto, TagDto } from "@client";
import { ProductListElement } from "@components/ListModules";

import { BorderedContainer } from "./InputElement.styled";
import { Checkbox, CircularProgress, IconButton } from "@mui/material";
import { usePostCreatorMealProductMutation } from "@hooks/queries/meals";
import { useAppParams } from "@hooks";
import { Add } from "@mui/icons-material";
import { MealElementContext } from "./MealElementContext";

interface ProductInputElementProps {
  product: SearchProductResponse;
  disabled?: boolean;
  oldRequest?: boolean;
}

export const ProductInputElement = ({
  product,
  disabled,
  oldRequest,
}: ProductInputElementProps) => {
  const { mealId } = useAppParams();

  const { mutate, isLoading } = usePostCreatorMealProductMutation(mealId);

  const handleAddProduct = (id: number) => mutate({ id });

  const onAdd = () => handleAddProduct(product.id);

  const { selected, onChanged } = useContext(MealElementContext);

  const [loading, setLoading] = useState(false);

  const onClick = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      setLoading(true);
      await onChanged(product.id, event.target.checked);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BorderedContainer disabled={disabled}>
      {oldRequest ? (
        <Checkbox
          value={selected.includes(product.id)}
          onChange={onClick}
          disabled={loading}
        />
      ) : (
        <IconButton
          disabled={disabled || isLoading}
          onClick={onAdd}
          color="primary"
        >
          {isLoading ? <CircularProgress size="1.5rem" /> : <Add />}
        </IconButton>
      )}

      <div className="flex-grow">
        <ProductListElement
          product={{ ...product, namePl: product.namePl ?? "" }}
        />
      </div>
    </BorderedContainer>
  );
};

export interface SearchProductResponse {
  id: number;
  clinicId?: number;
  namePl?: string;
  nameEn?: string;
  hide?: boolean;
  plOnly: boolean;
  reviewed: boolean;
  tags: TagDto[];
  macros: NutrientDto[];
  actions: ActionsDto;
}
