import { useQueries, useQuery } from "@tanstack/react-query";

import { FoodMeasureDto, fetchFoodMeasures } from "@client";
import { ApiResponse } from "@typeDefinitions";

import { QueryOptionsTyped } from "./types";

const fetchFoodMeasuresQueryKey = "fetchFoodMeasuresQueryKey";

export const useFetchFoodMeasuresQuery = (
  foodId: string,
  options?: QueryOptionsTyped<ApiResponse<FoodMeasureDto[]>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchFoodMeasuresQueryKey, foodId],
    () => fetchFoodMeasures(foodId),
    options,
  );

  return {
    measures: data?.data,
    ...rest,
  };
};
