import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  deleteCreatorDietMealProduct,
  DietMealItemCreatorResponseDto,
} from "@client/diets/creator";
import { MutationOptions } from "@hooks/queries/types";

import { useCreatorVersion } from "./useCreatorVersion";
import { fetchCreatorDietMealsItemsQuery } from "./useFetchCreatorDietMealsItemsQuery";
import { fetchCreatorDietMealsQueryKey } from "./useFetchCreatorDietMealsQuery";
import { fetchCreatorDietQueryKey } from "./useFetchCreatorDietQuery";
import { fetchCreatorDietNutrientsQueryKey } from "./useFetchCreatorDietNutrientsQuery";
import { fetchDietRecipeUsedQueryKey } from "../useFetchDietRecipeUsedQuery";
import { fetchCreatorDietMealNutrientsQueryKey } from "./useFetchCreatorDietMealNutrientsQuery";
import { ApiResponse } from "@typeDefinitions";

export const useDeleteCreatorDietMealProductMutation = (
  options?: MutationOptions,
) => {
  const { version } = useCreatorVersion();
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    unknown,
    UseDeleteCreatorDietMealProductMutationPayload
  >(
    ({ dietId, mealId, productId }) =>
      deleteCreatorDietMealProduct(dietId, mealId, productId, version),
    {
      ...options,
      onMutate: async ({ dietId, mealId, productId }) => {
        const previousData = queryClient.getQueryData<
          ApiResponse<DietMealItemCreatorResponseDto>
        >([fetchCreatorDietMealsItemsQuery, dietId, mealId]);

        queryClient.setQueryData<ApiResponse<DietMealItemCreatorResponseDto>>(
          [fetchCreatorDietMealsItemsQuery, dietId, mealId],
          oldData => {
            if (!oldData) return previousData;

            return {
              ...oldData,
              data: {
                recipes: oldData?.data.recipes,
                products: oldData?.data.products.filter(
                  ({ id }) => id.toString() !== productId,
                ),
              },
            };
          },
        );
      },
      onError: (e, { dietId, mealId }) => {
        options?.onError?.(e);
        queryClient.invalidateQueries([
          fetchCreatorDietMealsItemsQuery,
          dietId,
          mealId,
        ]);
      },
      onSuccess: (d, v) => {
        options?.onSuccess && options.onSuccess(d, v);
        queryClient.invalidateQueries([
          fetchCreatorDietMealsItemsQuery,
          v.dietId,
        ]);
        queryClient.invalidateQueries([
          fetchCreatorDietMealsQueryKey,
          v.dietId,
        ]);
        queryClient.invalidateQueries([fetchCreatorDietQueryKey, v.dietId]);
        queryClient.invalidateQueries([
          fetchCreatorDietNutrientsQueryKey,
          v.dietId,
        ]);
        queryClient.invalidateQueries([fetchDietRecipeUsedQueryKey, v.dietId]);
        queryClient.invalidateQueries([
          fetchCreatorDietMealNutrientsQueryKey,
          v.dietId,
        ]);
      },
    },
  );
};

interface UseDeleteCreatorDietMealProductMutationPayload {
  dietId: string;
  mealId: string;
  productId: string;
}
