import * as yup from "yup";

import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { validateAndFixNutrients } from "./utils/validateCatching";

interface DietitianNutrientCategoryDto {
  id: number;
  namePl: string;
  nameEn: string;
  nutrients: DietitianNutrientDto[];
}

interface DietitianNutrientDto {
  id: number;
  namePl: string;
  nameEn: string;
  units?: string;
  nutrientCategory: NutrientCategoryDto;
}

interface NutrientCategoryDto {
  id: number;
}

export const fetchDietitianNutrientsCategories = async (): Promise<
  ApiResponse<DietitianNutrientCategoryDto[]>
> => {
  const response = await fetchData(
    `/dietitian/nutrients/categories?type=dietitian`,
    APIMethods.GET,
  );
  return await validateAndFixNutrients(
    fetchDietitianNutrientsCategoriesSchema,
    response,
  );
};

const nutrientCategorySchema = yup.object().shape({
  id: yup.number().required(),
});

const dietitianNutrientSchema = yup.object().shape({
  id: yup.number().required(),
  namePl: yup.string().required(),
  nameEn: yup.string().required(),
  units: yup.string().nullable(),
  nutrientCategory: nutrientCategorySchema,
});

const dietitianNutrientCategorySchema = yup.object().shape({
  id: yup.number().required(),
  namePl: yup.string().required(),
  nameEn: yup.string().required(),
  nutrients: yup.array().of(dietitianNutrientSchema),
});

const fetchDietitianNutrientsCategoriesSchema = yup.object().shape({
  data: yup.array().of(dietitianNutrientCategorySchema),
});
