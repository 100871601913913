import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "./types";
import {
  fetchDietitianClientProgramScheduleKey,
  fetchDietitianProgramScheduleKey,
} from "./schedule";
import { CloneScheduleDayRequest, cloneScheduleDay } from "@client/schedule";

export const useCloneScheduleDayMutation = (
  programId: number,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, CloneScheduleDayRequest>(
    payload => cloneScheduleDay(programId, payload),
    {
      ...options,
      onSuccess: () => {
        options?.onSuccess && options.onSuccess();
        queryClient.invalidateQueries([fetchDietitianProgramScheduleKey]);
        queryClient.invalidateQueries([fetchDietitianClientProgramScheduleKey]);
      },
    },
  );
};
