import { FormCountryAutocomplete } from "@components/FormCountryAutocomplete";
import { FormTextField } from "@components/FormTextField";
import { Button, styled } from "@mui/material";

export const AddOfficeModalWrapper = styled("div")`
  display: grid;
  gap: 1.5rem;
  padding: 1.5rem 2rem 0 2rem;
  width: 28.125rem;
`;

export const HeaderStyled = styled("h2")`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: 1.5rem;
  line-height: 150%;
  letter-spacing: -0.022em;
  color: ${({ theme }) => theme.colors.gray500};
`;

export const StyledCancelBtn = styled(Button)`
  background-color: ${({ theme }) => theme.palette.grey[300]};
  color: currentColor;
  text-transform: none;
  font-weight: 600;
  &:hover {
    background-color: ${({ theme }) => theme.colors.cancelButtonHover};
  }
`;

export const StyledSubmitBtn = styled(Button)`
  text-transform: none;
  font-weight: 600;
  &:hover {
    background-color: ${({ theme }) => theme.colors.submitButtonHover};
  }
`;

export const Divider = styled("div")`
  border-top: 1px solid ${({ theme }) => theme.colors.cancelButton};
  width: 18.5rem;
  justify-self: center;
`;

interface Flex {
  flex: number;
}

export const StyledPhoneTextField = styled(FormTextField)<Flex>`
  flex: ${({ flex }) => flex};
`;

export const StyledFormCountryAutocomplete = styled(
  FormCountryAutocomplete,
)<Flex>`
  flex: ${({ flex }) => flex};
`;

export const ActionButtonsWrapper = styled("div")`
  display: flex;
  justify-self: center;
  align-items: center;
  gap: 0.5rem;
  position: sticky;
  padding: 0.5rem;
  bottom: 1rem;
  background-color: ${({ theme }) => theme.colors.whiteBackground};
  z-index: 100;
  border-radius: 0.375rem;
`;
