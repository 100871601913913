import { ContentTable } from "pdfmake/interfaces";

export const PDF_PAGE_WIDTH = 595.35;
export const PDF_PAGE_WIDTH_WITHOUT_MARGIN = 595.35 - 43 - 43;
export const PDF_PAGE_HEIGHT = 841.995;
export const PDF_PAGE_HEIGHT_WITHOUT_MARGIN = 841.995 - 71 - 43 - 10;
export const PDF_COLORS = {
  PRIMARY: "#7448D0",
  NEUTRAL_DARK_200_COLOR: "#F1F1F1",
  NEUTRAL_DARK_600_COLOR: "#B4B4B4",
  NEUTRAL_DARK_700_COLOR: "#727272",
  NEUTRAL_DARK_800_COLOR: "#333333",
  NEUTRAL_DARK_900_COLOR: "#141414",
};
export const PDF_DISPLAY_CODE = {
  WEEKDAY: "weekday",
  DATE: "date",
  DAY_TYPE: "dayType",
  MEAL_HOUR: "mealHour",
  LOGO: "logo",
  CLINIC_LOGO: "clinicLogo",
  CLINIC_NAME: "clinicName",
  CLINIC_ADDRESS: "clinicAddress",
  CLINIC_EMAIL: "clinicEmail",
  CLINIC_PHONE: "clinicPhone",
};

export const TABLE_LIST_LAYOUT = {
  fillColor(index: number) {
    return index % 2 === 0 ? null : "#FBFAFC";
  },
  hLineWidth() {
    return 0;
  },
  vLineWidth() {
    return 0;
  },
  paddingLeft() {
    return 6;
  },
  paddingRight() {
    return 6;
  },
  paddingTop() {
    return 6;
  },
  paddingBottom() {
    return 6;
  },
};

export const TABLE_LIST_PRINT_LAYOUT = {
  fillColor() {
    return null;
  },
  hLineWidth: function (index: number, node: ContentTable) {
    return index > 0 && index < node.table.body.length ? 1 : null;
  },
  vLineWidth: function () {
    return 0;
  },
  hLineColor: function () {
    return "#F1F1F1";
  },
  paddingLeft() {
    return 6;
  },
  paddingRight() {
    return 6;
  },
  paddingTop() {
    return 6;
  },
  paddingBottom() {
    return 6;
  },
};

export const TABLE_CARD_LIST_LAYOUT = {
  hLineWidth: () => 0,
  vLineWidth: () => 0,
  paddingLeft: () => 0,
  paddingRight: () => 0,
  paddingBottom: () => 0,
  paddingTop: () => 0,
};

export const TABLE_TOC_LAYOUT = {
  hLineWidth() {
    return 0;
  },
  vLineWidth() {
    return 0;
  },
  paddingLeft(index: number) {
    return index > 0 ? 8 : 0;
  },
  paddingRight() {
    return 0;
  },
  paddingTop() {
    return 0;
  },
  paddingBottom() {
    return 32;
  },
};
