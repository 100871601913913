import { Stack, styled } from "@mui/material";
import { Circle } from "@components/Circle/Clircle";
import { ReactNode } from "react";
import MeasurementCompareBodyItemValue from "@views/dietician/PatientMonitoring2/components/MeasurementsBox/Compare/MeasurementCompareBodyItemValue";

export type MeasurementCompareItemProps = {
  title: string;
  values: { body: { id: number }; value: number; icon: string }[];
  icon: ReactNode;
};
const MeasurementCompareItem = ({
  title,
  values,
  icon,
}: MeasurementCompareItemProps) => {
  return (
    <Stack spacing="12px" direction="column">
      <Stack spacing="16px" direction="row" alignItems="center">
        <Circle color="#F5F1FE" size={40}>
          {icon}
        </Circle>
        <Title>{title}:</Title>
      </Stack>
      <List>
        {values.map((i, index) => (
          <MeasurementCompareBodyItemValue key={index} value={i} />
        ))}
      </List>
    </Stack>
  );
};

const Title = styled("span")`
  color: #141414;
  font-size: 14px;
  line-height: 28px;
  font-family: Figtree, serif;
  font-weight: 500;
`;

const List = styled("ul")`
  display: block;
  width: 100%;
`;

export default MeasurementCompareItem;
