import { useForm } from "react-hook-form";

import { useEffect } from "react";
import { useAppTranslation } from "@hooks";
import {
  CreateClinicFacilityRequest,
  FetchClinicFacilityResponse,
} from "@client/settings";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const useClinicFacilityForm = (
  defaultValues?: ClinicFacilityFormInput,
) => {
  const { t } = useAppTranslation();
  const clinicFacilityInputSchema = yup.object().shape({
    name: yup.string().required(t("common.required_field")),
    email: yup
      .string()
      .email(t("common.email_invalid"))
      .required(t("common.required_field")),
    prefix: yup.string().nullable(),
    phone: yup.string().nullable(),
    street: yup.string().nullable(),
    streetNumber: yup.string().nullable(),
    houseNumber: yup.string().nullable(),
    postCode: yup.string().nullable(),
    city: yup.string().required(t("common.required_field")),
    isMain: yup.boolean().required(t("common.required_field")),
  });

  const form = useForm<ClinicFacilityFormInput>({
    defaultValues: defaultValues ?? {
      name: "",
      email: "",
      prefix: "",
      phone: "",
      street: "",
      streetNumber: "",
      houseNumber: "",
      postCode: "",
      city: "",
      isMain: false,
    },
    resolver: yupResolver(clinicFacilityInputSchema),
  });

  useEffect(() => {
    if (defaultValues) {
      form.reset(defaultValues);
    }
  }, [defaultValues]);
  return form;
};

export interface ClinicFacilityFormInput {
  name: string;
  email: string;
  prefix: string | null;
  phone: string | null;
  street?: string;
  streetNumber?: string;
  houseNumber?: string;
  postCode?: string;
  city?: string;
  isMain: boolean;
}

export const mapClinicFacilityRequest = (
  data: ClinicFacilityFormInput,
): CreateClinicFacilityRequest => {
  return {
    email: data.email,
    name: data.name,
    isMain: data.isMain,
    address: {
      street: data.street ?? null,
      streetNumber: data.streetNumber ?? null,
      houseNumber: data.houseNumber ?? null,
      postCode: data.postCode ?? null,
      city: data.city ?? null,
    },
    phone: data.phone
      ? {
          prefix: data.prefix,
          number: data.phone,
        }
      : {
          prefix: null,
          number: null,
        },
  };
};

export const mapClinicFacilityForm = (
  data?: FetchClinicFacilityResponse,
): ClinicFacilityFormInput => {
  return {
    name: data?.name ?? "",
    email: data?.email ?? "",
    phone: data?.phone.number ?? "",
    prefix: data?.phone.prefix ?? "+48",
    isMain: !!data?.isMain,
    street: data?.address.street ?? "",
    streetNumber: data?.address.streetNumber ?? "",
    houseNumber: data?.address.houseNumber ?? "",
    postCode: data?.address.postCode ?? "",
    city: data?.address.city ?? "",
  };
};
