import { styled, Typography } from "@mui/material";

interface NutrientProps {
  wide?: boolean;
}
export const StyledNutrient = styled("div")<NutrientProps>`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  width: ${({ wide }) => (wide ? "13.75rem" : "8.375rem")};
`;

export const StyledCompactNutrient = styled("div")<NutrientProps>`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  width: ${({ wide }) => (wide ? "7.5rem" : "4rem")};
`;

export const TableRowNutrient = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const TableWrapper = styled("div")`
  display: grid;
  width: 9.75rem;
`;

export const BoldTypography = styled(Typography)`
  font-weight: 600;
`;
