import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const UK = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 93 73">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="64px"
        height="64px"
        viewBox="0 0 64 64"
        enableBackground="new 0 0 64 64"
      >
        <image
          id="image0"
          width="64"
          height="64"
          x="0"
          y="0"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
CXBIWXMAAAHfAAAB3wGoGAANAAALO0lEQVR42uWbe1hUdRrHv++ZGyAMCMxMqMiKF1RUUDPt8rTq
85imrteeXN3WsuxiKip572nV3Sdty9tmoLiVmmSWSulurU/bPmlqrjKsDwommpkBKgyjDIPAXM55
9w+uAzMwV7DHl3/g9zvznvfz4Zwzv3N+v0PMjACH1vLtqRHG2UuSQRQH5jgQxQHcFYAKwA0JfNFC
UmeLwDcllq4jJKRQ8+3nlyRJOh0TE1MWyOLkgUjad/gbPQSRn8/P/sskIhoEhTIfhESAAQIAB+nd
BFC3YJYhSESNWbAH2S0WAzNriAhn9RcPPzN3e44kSrt/vJBWeM8K6N07RSWPUE8j8AsCYzQAIiKL
JzkICFJLcqlK4OAmbVoAfxZkwto+yQv/zeAPcVc4fOXKux7lDpiA2EdSg8OswasU4WHzwRzph5qE
YJbJg4KCUFNT49AOYCyBxqIT3+6dvOBtlV2zJS9vjdWnnfny4X5DXx+rtgbnAXgDgD/g64pis1qt
tgiCy/IiCfSWVW4432fwgjG+7MurI2DAQ8sfkFi+lYhmBOoSKgiCKjw83AK0dg2kBDC+7jN4wUHJ
zqneXCO8OQImDx3c5x9gmhEg9oZQqVQqTXREVJsbMj31+vLJH9w8enRaoAUsAJC1e8fcXkOS4goC
LQAAJJYp29pm5eIJx8eE1uhMr6TuufSbfqsDIYAAvA1gGwCBCBF7339J1x4SKsw1FiKZy/7li8cf
fyLc2tm8ZGVPgEMBvPlDXP/taO1DHgmgUXKY7+4GsMyhuZ0khIYGmQRS2F3Bj+ts71y5ZGU8gTs1
1savFPQbsguAwmcBN7rf3X7r8akDuaamqoWbdpAglwkiCcoWApYtqoNftCK+7j/fWJcglIbu3PZo
SUnJez4JuBE7bBkYcyVj+eCShycVdJQEAEFEjV9YS1PGH38ySoxwDk+GTnsyKmW94uOZ+aVbt27N
90rAzdjhU0H01/q/O1qCINQezakpTx4fr5EiKhctcwG/0yzv3Su+SfPWkpKS0R4JKDOb+wQ/PWEe
6kbu94IEIjkvfHnMyYkajqhMWRoPRpgb8AAgZ+bP1q3b291tAeMmbN5sW7U4MuSpCcea93WUhD+t
nFT8h2GxXZ3BQ6Cy0F07K5zAAwDvP3Qmf19W9rtuCej/4OsTqqqsE56YvGmAbfWSsHtBwhsrfnd8
UpQYYpr5Yldn8GG7dppkCb16uoA/kf73bx5n4snOhs0OAkaNWicnYCMAWCx2la8S4Iebre6x0QnT
dehc/lxqsMwqOQ6KBCoL27WjvC34xhbaOmrUOoeaHASUmq3zGOhb/7evEgC4NRhpLWwns2+UPbMo
HszBMm6SrgG+Ty+34Gujf/Ft46tOBfR9bEUYA2uaZ/JFgq9hPZWdZ/zjwrj6qz3V/YDI6AV8bRCv
6z0iRd1CAFXLpwNweuPRERIsp7Lzy2YtiAOzwzkvI1lF6Ifb73gFXxsRZEHDTVOjAAHPtFpQO0pg
kUONsxZ0bw7PMqE6PH2LRd4vwVv4ui0bWQUA6DtkRRcwRrX1uXaUENwcHgIZtF/trQ55/GGNT/C1
m49KSJ7XtUGATJDNgpt3hh1yTRDIoPlyb4WiX+9ImazFddVD+NqMzPKZQN3XVGJi7GibxX7Nk5pS
ln0StmvHajWAY1UHvxzpRMI53fdHEig4KMQneKIyzZd7KxSJfXoCQPPHZOfO//Ld0W/Od4/vofWo
fpVCMRrARmLmaAAGX2osT13XQgIACFER53SnjyRY9ReuGWfNT/QOfk+5YkDfhnNeFEUYDD6V2xDM
rBEADPc1UcTmNSNbOx2kyrueP7klMkb/c7cDPNDyCPAliGg4mTZlLIbF6vGzNGdxd88BDVdV923R
IZMVQxS7epJLOSzpO+WDSdS8XZJRf1PlncsSS3ZP8jkVoFRkUXHsg+8D9II/BLRXmIVKMHx/Hk3g
DwSAtB0N1FHBRFqB7mMBYGgFBt+/AgCtgNrJx/s1tAIIUkdX0YEhCWAu6ugqOioYVCQAVNzRhXhe
uH+mZAlcLBDwqxPgt2AUC8B9fAoQFQnSr/AU8FcIJBXLFYm9f5IMd3ICuSM2VUSyxdrDD6mqoI2s
Jlb+7Je6IiN+ImbWAigJFLw19+KVsklzIsHc9kKH+iC6LYvR/ijeKHmoeZdi/MgicW1qN3/URkQ6
AUApgPwAwkd5BA+ABIi6E1nJQowuu3mf5av/dLm7dNUxP5SXr9PpSomZsf/g2dWSxGM9zTBt8pAu
QSqFsweUsOVevGyYNCfam5VjJCNDzLUzGths1pLHpuSKNw3DGvKSHdVUDcVjI4512rhh5NVrpady
8wtFT/chk9HRl+eM30DMjAFDVveXBPLoKNiw9qljkycOHulveAcBAGCzWUsenZIr3qqVYCErLHXL
DxWPjTgW8s76pPlLPyrNyy9K8MyA1P9yTtoPAgDk/W/9RQAn7wX4FqFQKLUnvxgkPKDRAwA3Gbnb
Tv53ZNWy1blpG2drByR2c38uknH8ck7aD0DTeQFGRgDhfbrfIKVCpTv5xUDSRVZIzUaB3kgg8I76
3xsEVKmVBwHc9hbeeu6Cc3iiik5znj7hi4BGCYfVQs/Y8837PJRQqhA1WS0EXPt2TQ0Y272FL5sy
1yl89P70wqAxv432VQAAQKFAyJ6MBNJE6b2VQKAdTZfXOjxiDSHlegDX/QmvfHio54/DXYTVagUU
ClV41icDvZRwrSpE/lbTBgcBev2aKoE4xW34nAsF7QDfcNLbbLbaX+okCBpNi3FCaxJIwvzC7zdX
uxQAAHnZ648AOOwW/LS5mubwRGSK3p9emHm1ygjA5yXtRcW3z968ZTrrIKBOgjrr40HuSiDQZwXn
t/2r+bZOZxkEiCnr107/unX4F7XO4KM+21GUebXKuHHrUU/m6lyG3S5K46ZuSi4sMpY7CGiQsC9J
0LYuIXlgd71MLlvsnNVJ5Onf+mXKxCHb4eTry5pz4VItvNTZCXxx5pVKv8E3wNhE5bipG0NKSk1n
WnQq5Er1oX1JpNW6lLBt4+wtF/VbbrotoC6+APCaE3hdK/Bl/oavD7vdopw5Jz3ZYDQ7lRB+6GOn
EuwnT5/Q6XT7XOVta6JtK2oXSLuEB1F51IGMgMIDsDLbYLOLqt8/m5ZcZjSfdSVBeEDb0Efg9ISf
L/2ptcTuzDQutpzKft8VfPSBjBuZl80Bg2cGsdR47tvsomrGc2lJZUZzi/82FHKl+sDHyXUS9idc
L1jYVn53BEh5i+a9ChazHFqJyqMP7gwoPACYK6s7S2x1WB5ns4mqGc+lDXIlIfzTvefvRneaDeY2
h+BuzTUP1ettXX7JngumFQAYROXRhzJuZhZUBBQeAMLDgyKZW97tNkqoaDogEpl5iS6u+4tD9Xqb
O/k9mmzvUnjmbWKaHvXp9quZl8yGQMMDAEviHVd9tRLSBxqNlXoAdwRBGBcTE7PVk/werzaIKTzz
+Xs55dM3/e2oKdDwNTU1MBjuGFvbxmYTVTOfTy8tq6wcptVqv/F0H14tt3ht/ujr+dlvThJYehLA
Zb+TMxEzixUVFW1teYUZk8+f2TxhQK9eV73ZlU/rTfJyNhwNQdkAMJYDKPcXv0RQm0wmmSS5vIaZ
CLRULbcmXsnddsSXffm8mFmvz7ABeCf2kdT31Jbg6SA8z8BINHvXwIPgahKtspqa5m+LMRjfQaDd
Spt4MC8vrdLX2v0ioD7q7rIyAWQmPLQqXibJ5jDzRCJKcpscbDELospOVB0KhNa2oZQZa2XAR5dy
t3m0FM6doHZ4fT7Kcuz0MOOzi5OI0YMh9QCoB4BuqH99nlBQQ2KklbiwvV+f/z+KgylwcTpspwAA
ACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMi0xMC0yNlQxMzoxMTowMiswMDowMFkQRXgAAAAldEVYdGRh
dGU6bW9kaWZ5ADIwMjItMTAtMjZUMTM6MTE6MDIrMDA6MDAoTf3EAAAAGXRFWHRTb2Z0d2FyZQB3
d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg=="
        />
      </svg>
    </SvgIcon>
  );
};
