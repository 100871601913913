import { useForm } from "react-hook-form";

import { mixed, object, string } from "yup";

import { StoreBillDataRequest } from "@client/clinic";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppTranslation } from "@hooks";

import { ViewType } from "../Pricing";
import { SubscriptionPurchaseRequest } from "@client/resources/SubscriptionPurchaseRequest";

export const useBuyPackageForm = () => {
  const { t } = useAppTranslation();
  const schema = object<BuyPackageFormInput>({
    buyer: mixed<"company" | "person">()
      .oneOf<"company" | "person">(["company", "person"])
      .required(t("common.required_field")),
    nip: string().when("buyer", (buyer: ("company" | "person")[], schema) =>
      buyer.includes("company")
        ? schema.required(t("common.required_field"))
        : schema,
    ),
    companyName: string().when(
      "buyer",
      (buyer: ("company" | "person")[], schema) =>
        buyer.includes("company")
          ? schema.required(t("common.required_field"))
          : schema,
    ),
    firstName: string().when(
      "buyer",
      (buyer: ("company" | "person")[], schema) =>
        buyer.includes("person")
          ? schema.required(t("common.required_field"))
          : schema,
    ),
    lastName: string().when(
      "buyer",
      (buyer: ("company" | "person")[], schema) =>
        buyer.includes("person")
          ? schema.required(t("common.required_field"))
          : schema,
    ),
    street: string().required(t("common.required_field")),
    streetNumber: string().required(t("common.required_field")),
    city: string().required(t("common.required_field")),
    postcode: string().required(t("common.required_field")),
  });

  const form = useForm<BuyPackageFormInput>({
    defaultValues: {
      buyer: "person",
    },
    resolver: yupResolver(schema),
  });

  return form;
};

interface BuyPackageFormInput {
  buyer: "company" | "person";
  nip: string;
  companyName: string;
  firstName: string;
  lastName: string;
  street: string;
  streetNumber: string;
  houseNumber: string;
  city: string;
  postcode: string;
  isStudent: boolean;
}

export const mapBuyPackageRequest = (
  values: BuyPackageFormInput,
  paymentType: ViewType,
  subscriptionType: "starter" | "scale",
): SubscriptionPurchaseRequest => {
  const {
    buyer,
    city,
    companyName,
    firstName,
    houseNumber,
    lastName,
    nip,
    postcode,
    street,
    streetNumber,
  } = values;
  return {
    paymentType: paymentType === ViewType.monthly ? "month" : "year",
    subscriptionType,
    buyer: {
      type: buyer,
      address: [
        street,
        [streetNumber, houseNumber].filter(Boolean).join("/"),
      ].join(" "),
      city,
      postcode,
      companyName,
      firstName,
      lastName,
      nip,
    },
  };
};

export const mapStoreBillData = (values: BuyPackageFormInput) =>
  mapBuyPackageRequest(
    values,
    ViewType.annually,
    "scale",
  ) as StoreBillDataRequest;
