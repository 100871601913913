import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const HeartWithPlus = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      style={{ fill: "none" }}
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        d="M16.6159 15.1026H13.4834M15.0496 13.5713V16.7126M15.0496 19.2501C16.1635 19.2501 17.2318 18.8076 18.0195 18.0199C18.8071 17.2323 19.2496 16.164 19.2496 15.0501C19.2496 13.9362 18.8071 12.8679 18.0195 12.0802C17.2318 11.2926 16.1635 10.8501 15.0496 10.8501C13.9357 10.8501 12.8674 11.2926 12.0798 12.0802C11.2921 12.8679 10.8496 13.9362 10.8496 15.0501C10.8496 16.164 11.2921 17.2323 12.0798 18.0199C12.8674 18.8076 13.9357 19.2501 15.0496 19.2501V19.2501Z"
        stroke="#801FFF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.25 7.60365C19.25 9.3274 18.8038 10.8499 18.1038 12.1712C17.7118 11.7543 17.2386 11.422 16.7135 11.1948C16.1883 10.9676 15.6222 10.8502 15.05 10.8499C12.7313 10.8499 10.85 12.7312 10.85 15.0499C10.85 16.1262 11.2613 17.1062 11.9263 17.8499C11.6025 17.9987 11.305 18.1212 11.0425 18.2087C10.745 18.3137 10.255 18.3137 9.9575 18.2087C7.42 17.3424 1.75 13.7287 1.75 7.60365C1.75 4.8999 3.92875 2.7124 6.615 2.7124C8.19875 2.7124 9.61625 3.4824 10.5 4.66365C10.9514 4.05886 11.5375 3.56762 12.2119 3.22891C12.8863 2.8902 13.6303 2.71335 14.385 2.7124C17.0713 2.7124 19.25 4.8999 19.25 7.60365Z"
        stroke="#801FFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
