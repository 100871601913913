import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const CompleteCircle = (props: SvgIconProps) => (
  <SvgIcon width="24" height="25" viewBox="0 0 24 25" {...props}>
    <path
      d="M12 20.0435C7.5625 20.0435 4 16.481 4 12.0435C4 7.63721 7.5625 4.04346 12 4.04346C16.4062 4.04346 20 7.63721 20 12.0435C20 16.481 16.4062 20.0435 12 20.0435ZM15.5312 10.5747H15.5C15.8125 10.2935 15.8125 9.82471 15.5 9.51221C15.2188 9.23096 14.75 9.23096 14.4688 9.51221L11 13.0122L9.53125 11.5435C9.21875 11.231 8.75 11.231 8.46875 11.5435C8.15625 11.8247 8.15625 12.2935 8.46875 12.5747L10.4688 14.5747C10.75 14.8872 11.2188 14.8872 11.5312 14.5747L15.5312 10.5747Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
