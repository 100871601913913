import {
  DietProductExchangeItemDto,
  fetchDietsItemsProductsReplacement,
} from "@client/diets";
import { useQuery } from "@tanstack/react-query";
import { QueryOptionsTyped } from "../types";
import { ApiResponse } from "@typeDefinitions";

export const fetchDietsItemsProductsReplacementQueryKey =
  "fetchDietsItemsProductsReplacementQueryKey";

export const useFetchDietsItemsProductsReplacementQuery = (
  dietId: string,
  from: string,
  to: string,
  options?: QueryOptionsTyped<ApiResponse<DietProductExchangeItemDto[]>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchDietsItemsProductsReplacementQueryKey, dietId, from, to],
    () => fetchDietsItemsProductsReplacement(dietId, from, to),
    options,
  );

  return {
    data: data?.data,
    ...rest,
  };
};
