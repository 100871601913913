import { useQuery } from "@tanstack/react-query";
import {
  fetchMonitoringMealsStats,
  FetchMonitoringMealsStatsParams,
  FetchMonitoringMealsStatsResponse,
} from "client/patient/monitoring";
import { QueryOptionsTyped } from "@hooks/queries";
import { patientMonitoringKeys } from "@hooks/queries/client/monitoring/patientMonitoringKey";

export const useFetchMonitoringMealsStats = (
  params: FetchMonitoringMealsStatsParams,
  options?: QueryOptionsTyped<FetchMonitoringMealsStatsResponse>,
) => {
  return useQuery({
    queryKey: patientMonitoringKeys.mealsStats(params),
    queryFn: () => fetchMonitoringMealsStats(params),
    ...options,
  });
};
