import { useMemo } from "react";
import { FormProvider } from "react-hook-form";

import { useAppParams, useAppTranslation } from "@hooks";
import { RecipeProductsContextProvider } from "@context";
import { useFetchRecipeQuery } from "@hooks/queries";
import { SingleCardPageLayout } from "@components/PageLayout";
import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";
import { RecipeEditForm } from "@components/RecipeEditForm";
import { BreadcrumbsPath } from "@components/BreadcrumbsPath";
import { Food } from "@assets/icons/Menu";
import {
  mapRecipeForm,
  useRecipeEditForm,
} from "@components/RecipeForm/useRecipeEditForm";
import { AutosaveStatus } from "@components/AutosaveStatus";

import { RecipeFormWrapper } from "./RecipeEdit.styled";
import { RecipeEditWatch } from "./RecipeEditWatch";

export const RecipeEdit = () => {
  const { t, isPolishChosen } = useAppTranslation();
  const { recipeId } = useAppParams();

  const recipeIdParsed = useMemo(
    () => (recipeId ? parseInt(recipeId) : 0),
    [recipeId],
  );
  const { recipe, isLoading, isFetching, isError } =
    useFetchRecipeQuery(recipeIdParsed);

  const recipeName =
    (isPolishChosen ? recipe?.name : recipe?.nameEn ?? recipe?.name) ?? "";

  const breadcrumbs = useMemo(
    () => [
      {
        icon: <Food />,
        backTo: "/recipes",
        title: t("recipes.title"),
      },
      {
        backTo: `/recipes/${recipeId}`,
        title: recipeName,
      },
      {
        backTo: `/recipes/${recipeId}/edit`,
        title: t("recipes.edit_recipe"),
      },
    ],
    [recipeName, t, recipeId],
  );

  const mappedFormRecipe = useMemo(
    () => mapRecipeForm(recipe),
    [mapRecipeForm, recipe],
  );
  const form = useRecipeEditForm(mappedFormRecipe);

  if (isLoading) {
    return (
      <SingleCardPageLayout title={t("recipe_edit.title")}>
        <Spinner className="w-full h-56" />
      </SingleCardPageLayout>
    );
  }

  if (isError || !recipe) {
    return (
      <SingleCardPageLayout title={t("recipe_edit.title")}>
        <ApiError />
      </SingleCardPageLayout>
    );
  }

  return (
    <RecipeFormWrapper>
      <div className="flex justify-between">
        <BreadcrumbsPath breadcrumbs={breadcrumbs} />
        <AutosaveStatus />
      </div>

      <RecipeProductsContextProvider recipe={recipe}>
        <FormProvider {...form}>
          <RecipeEditWatch recipe={recipe} recipeLoading={isFetching} />
          <RecipeEditForm recipe={recipe} />
        </FormProvider>
      </RecipeProductsContextProvider>
    </RecipeFormWrapper>
  );
};
