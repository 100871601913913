import React from "react";

import {
  VisitSegmentListType,
  VisitSegmentType,
  VisitTagGroup,
} from "@typeDefinitions";
import { ListContent } from "./components/ListContent";
import { TextContent } from "./components/TextContent";
import { ProductContent } from "./components/ProductContent/ProductContent";

export interface VisitDetailSegmentProps {
  patientId: number;
  visitId: number;
  type: VisitSegmentType;
  tags?: VisitTagGroup[];
  mutate?: (newValue: any) => void;
  value: any;
}

export const VisitDetailSegment = ({
  type,
  ...passableProps
}: VisitDetailSegmentProps) => {
  switch (type) {
    case "favoriteProducts":
    case "dislikedProducts":
      return (
        <ProductContent
          type={type as VisitSegmentListType}
          {...passableProps}
        />
      );
    case "text":
      return <TextContent {...passableProps} />;
    case "textLong":
      return <TextContent {...passableProps} long useWysiwyg />;
    default:
      return (
        <ListContent type={type as VisitSegmentListType} {...passableProps} />
      );
  }
};
