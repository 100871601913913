import { MouseEventHandler, useMemo, useState } from "react";
import { useWatch } from "react-hook-form";

import { Chip } from "@mui/material";

import { Funnel } from "@assets/icons";
import {
  TagAutocompleteFilterWrapper,
  FilterType,
  FunnelButton,
  MultiSelectFilterWrapper,
  SingleSelectChipFilterWrapper,
  SliderFilterWrapper,
} from "@components/Filters";
import { FiltersDrawer } from "@components/Filters/FiltersDrawer";
import {
  ListTabs,
  useAppTranslation,
  useListTabs,
  useNutrientDictionary,
  useUserRoles,
} from "@hooks";
import { useTagsNew } from "@hooks/useTagsNew";
import {
  CARBS_ID,
  ENERGY_ID,
  FATS_ID,
  MicrosFilters,
  PROTEIN_ID,
} from "@utils/macros";
import { TagCategoryType } from "@utils/tagsNew";

import { decimalInput1ThreeDigits } from "@utils/inputs";
import { IconButtonStyled } from "./RecipesFilters.styled";
import {
  DEFAULT_MACROS_RANGE,
  RecipesFiltersFormInput,
} from "./useRecipesFiltersFormNew";
import { compact } from "lodash";

interface RecipesFiltersNewProps {
  total?: number;
  compact?: boolean;
  unshiftTabs?: boolean;
  onSubmit?: (values: RecipesFiltersFormInput) => void;
  resetValues?: RecipesFiltersFormInput;
}
export const RecipesFiltersNew = ({
  total,
  compact,
  unshiftTabs,
  onSubmit,
  resetValues,
}: RecipesFiltersNewProps) => {
  const { getTagCategoryName, getTagCategoryOptions } = useTagsNew();
  const [open, setOpen] = useState(false);
  const { t } = useAppTranslation();
  const { unshiftListTabFilter } = useListTabs();
  const { isAdminClinicMember } = useUserRoles();
  const tags = useWatch<RecipesFiltersFormInput, "tags">({ name: "tags" });
  const nutrients = useWatch<RecipesFiltersFormInput, "nutrients">({
    name: "nutrients",
  });
  const tabs = useWatch<RecipesFiltersFormInput, "tabs">({
    name: "tabs",
  });

  const countPositiveValues = useMemo(
    () =>
      Object.entries(nutrients || {})?.reduce((acc, [key, value]) => {
        if (!value) return 0;
        const [min, max] = value;
        const defaultValue = DEFAULT_MACROS_RANGE[key];

        const isDefaultValue =
          min === defaultValue[0] && max === defaultValue[1];
        const isEmptyValue = max === 0;

        if (!isDefaultValue && !isEmptyValue) acc += 1;

        return acc;
      }, 0),
    [nutrients],
  );

  const tabsTag = unshiftTabs ? Number(tabs !== ListTabs.ALL) : 0;

  const allTags = (tags?.length ?? 0) + countPositiveValues + tabsTag;
  const preparationFilters: (
    | TagAutocompleteFilterWrapper
    | MultiSelectFilterWrapper<RecipesFiltersFormInput>
    | SingleSelectChipFilterWrapper<RecipesFiltersFormInput>
  )[] = [
    {
      type: FilterType.MultiSelect,
      props: {
        label: getTagCategoryName(TagCategoryType.MEAL) ?? "",
        options: getTagCategoryOptions(TagCategoryType.MEAL) ?? [],
      },
    },
    {
      type: FilterType.MultiSelect,
      props: {
        label:
          getTagCategoryName(TagCategoryType.DIFFICULTY_OF_PREPARATION) ?? "",
        options:
          getTagCategoryOptions(TagCategoryType.DIFFICULTY_OF_PREPARATION) ??
          [],
      },
    },
    {
      type: FilterType.MultiSelect,
      props: {
        label: getTagCategoryName(TagCategoryType.PREPARATION_TIME) ?? "",
        options: getTagCategoryOptions(TagCategoryType.PREPARATION_TIME) ?? [],
      },
    },
    {
      type: FilterType.MultiSelect,
      props: {
        label: getTagCategoryName(TagCategoryType.TASTE_PREFERENCE) ?? "",
        options: getTagCategoryOptions(TagCategoryType.TASTE_PREFERENCE) ?? [],
      },
    },
    {
      type: FilterType.TagAutocomplete,
      props: {
        label: getTagCategoryName(TagCategoryType.TYPE_OF_RECIPE) ?? "",
        options: getTagCategoryOptions(TagCategoryType.TYPE_OF_RECIPE) ?? [],
        placeholder: t("recipes.type_of_recipe_placeholder"),
      },
    },
    {
      type: FilterType.TagAutocomplete,
      props: {
        label: getTagCategoryName(TagCategoryType.COOKWARE) ?? "",
        options: getTagCategoryOptions(TagCategoryType.COOKWARE) ?? [],
        placeholder: t("recipes.cookware_placeholder"),
      },
    },
    {
      type: FilterType.TagAutocomplete,
      props: {
        label: getTagCategoryName(TagCategoryType.KIND_OF_CUISINE) ?? "",
        options: getTagCategoryOptions(TagCategoryType.KIND_OF_CUISINE) ?? [],
        placeholder: t("recipes.kind_of_cusine_placeholder"),
      },
    },
    {
      type: FilterType.MultiSelect,
      props: {
        label: getTagCategoryName(TagCategoryType.SEASONALITY) ?? "",
        options: getTagCategoryOptions(TagCategoryType.SEASONALITY) ?? [],
      },
    },
    {
      type: FilterType.MultiSelect,
      props: {
        label: getTagCategoryName(TagCategoryType.OTHER) ?? "",
        options: getTagCategoryOptions(TagCategoryType.OTHER) ?? [],
      },
    },
  ];
  const nutrientsFilters: SliderFilterWrapper<RecipesFiltersFormInput>[] = [
    {
      type: FilterType.Slider,
      props: {
        nutrientId: ENERGY_ID.toString(),
        label: t("common.energy_efficiency"),
        maxValue: 1000,
        step: 50,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: PROTEIN_ID.toString(),
        maxValue: 60,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: FATS_ID.toString(),
        maxValue: 50,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: CARBS_ID.toString(),
        maxValue: 100,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.SATURATED_FAT.toString(),
        maxValue: 5,
        step: 1,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.CHOLESTEROL.toString(),
        maxValue: 100,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.DIETARY_FIBER.toString(),
        maxValue: 20,
        step: 1,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.SODIUM.toString(),
        maxValue: 1000,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.POTASSIUM.toString(),
        maxValue: 1000,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.CALCIUM.toString(),
        maxValue: 500,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.PHOSPHORUS.toString(),
        maxValue: 500,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.MAGNESIUM.toString(),
        maxValue: 200,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.IRON.toString(),
        maxValue: 10,
        step: 1,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.VITAMIN_A.toString(),
        maxValue: 200,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.TOTAL_FOLATE.toString(),
        maxValue: 150,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.VITAMIN_C.toString(),
        maxValue: 100,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.KOBALAMINA.toString(),
        maxValue: 1,
        step: 0.1,
        decimalInput: decimalInput1ThreeDigits,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.GLYCEMIC_LOAD.toString(),
        maxValue: 30,
        step: 1,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.CARBOHYDRATE_EXCHANGERS.toString(),
        maxValue: 10,
        step: 1,
      },
    },
  ];

  const otherFilters: (
    | TagAutocompleteFilterWrapper
    | MultiSelectFilterWrapper<RecipesFiltersFormInput>
  )[] = [
    {
      type: FilterType.MultiSelect,
      props: {
        label: getTagCategoryName(TagCategoryType.DIET_TYPE) ?? "",
        options: getTagCategoryOptions(TagCategoryType.DIET_TYPE) ?? [],
      },
    },
    {
      type: FilterType.TagAutocomplete,
      props: {
        label: getTagCategoryName(TagCategoryType.ALLERGENS) ?? "",
        options: getTagCategoryOptions(TagCategoryType.ALLERGENS) ?? [],
        placeholder: t("recipes.allergens_placeholder"),
      },
    },
    {
      type: FilterType.TagAutocomplete,
      props: {
        label: getTagCategoryName(TagCategoryType.BASE_PRODUCT) ?? "",
        options: getTagCategoryOptions(TagCategoryType.BASE_PRODUCT) ?? [],
        placeholder: t("recipes.base_product_placeholder"),
      },
    },
  ];

  return (
    <>
      <FunnelButton
        allTags={allTags}
        open={open}
        setOpen={setOpen}
        compact={compact}
      />
      <FiltersDrawer
        tabs={[
          t("recipes.filters.preparation"),
          t("recipes.filters.nutrients"),
          t("recipes.filters.other"),
        ]}
        filtersLists={[
          unshiftTabs
            ? unshiftListTabFilter(
                preparationFilters,
                t("common.recipes"),
                isAdminClinicMember,
              )
            : preparationFilters,
          nutrientsFilters,
          otherFilters,
        ]}
        onClose={() => setOpen(false)}
        open={open}
        total={total}
        resetValues={
          resetValues ?? {
            tabs: tabs,
            tags: [],
            nutrients: DEFAULT_MACROS_RANGE,
            query: "",
          }
        }
        onSubmit={onSubmit}
      />
    </>
  );
};
