import { useAppTranslation } from "@hooks";
import { Nutrient } from "./Nutrient";
import { NutrientValue } from "./NutrientValue";
import { NutrientValueForm } from "./NutrientValueForm";
import { NutrientCategoryType, NutrientValueType } from "./Types";

export const NutrientCategory: React.FC<NutrientCategoryProps> = ({
  nutrientCategory,
  isForm,
  values,
}) => {
  const { isPolishChosen } = useAppTranslation();
  return (
    <div className={"flex flex-col gap-4 mb-8"}>
      <span className={"font-sans text-lg mb-2"}>
        {isPolishChosen ? nutrientCategory.namePl : nutrientCategory.nameEn}
      </span>
      <div className={"flex flex-row flex-wrap gap-x-8 gap-y-4"}>
        {nutrientCategory.nutrients.map(nutrient => (
          <Nutrient
            key={nutrient.id}
            title={
              isPolishChosen ? nutrient.descriptionPl : nutrient.descriptionEn
            }
            value={
              isForm ? (
                <NutrientValueForm id={nutrient.id} />
              ) : (
                <NutrientValue id={nutrient.id} values={values} />
              )
            }
            units={nutrient.units}
          />
        ))}
      </div>
    </div>
  );
};

interface NutrientCategoryProps {
  nutrientCategory: NutrientCategoryType;
  isForm: boolean;
  values: NutrientValueType[];
}
