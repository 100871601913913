import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Cutlery = (props: SvgIconProps) => {
  return (
    <SvgIcon width="12" height="14" viewBox="0 0 12 14" {...props}>
      <path
        d="M10.7143 0.5C10.3214 0.5 7.57143 1.3125 7.57143 4.96875V7.8125C7.57143 8.70879 8.27612 9.4375 9.14286 9.4375H9.92857V12.6875C9.92857 13.1369 10.2797 13.5 10.7143 13.5C11.1489 13.5 11.5 13.1369 11.5 12.6875V9.4375V6.59375V1.3125C11.5 0.863086 11.1489 0.5 10.7143 0.5ZM2.07143 0.90625C2.07143 0.698047 1.92165 0.525391 1.72031 0.502539C1.51897 0.479688 1.33973 0.616797 1.29554 0.817383L0.551562 4.27813C0.517187 4.43809 0.5 4.60059 0.5 4.76309C0.5 5.92852 1.36183 6.88574 2.46429 6.98984V12.6875C2.46429 13.1369 2.8154 13.5 3.25 13.5C3.6846 13.5 4.03571 13.1369 4.03571 12.6875V6.98984C5.13817 6.88574 6 5.92852 6 4.76309C6 4.60059 5.98281 4.43809 5.94844 4.27813L5.20446 0.817383C5.16027 0.614258 4.97612 0.479688 4.77723 0.502539C4.57835 0.525391 4.42857 0.698047 4.42857 0.90625V4.31367C4.42857 4.45078 4.32054 4.5625 4.18795 4.5625C4.06272 4.5625 3.9596 4.46348 3.94732 4.33398L3.6404 0.870703C3.62321 0.659961 3.45379 0.5 3.25 0.5C3.04621 0.5 2.87679 0.659961 2.8596 0.870703L2.55513 4.33398C2.54286 4.46348 2.43973 4.5625 2.31451 4.5625C2.18192 4.5625 2.07388 4.45078 2.07388 4.31367V0.90625H2.07143ZM3.25737 4.76562H3.25H3.24263L3.25 4.74785L3.25737 4.76562Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
