import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const fetchProgramSection = async (
  programId: string,
  sectionId: string,
): Promise<ApiResponse<ProgramSectionDto>> => {
  const response = await fetchData(
    `/dietitian/programs/${programId}/sections/${sectionId}`,
    APIMethods.GET,
  );

  return responseSchema.validate(response);
};

interface ProgramSectionDto {
  id: number;
  title: string;
  titleEn: string | null;
  content: string | null;
  contentEn: string | null;
  isSystemic: boolean;
}

const programSectionSchema = yup.object({
  id: yup.number().required(),
  title: yup.string().required(),
  titleEn: yup.string().nullable().default(null),
  content: yup.string().nullable().default(null),
  contentEn: yup.string().nullable().default(null),
  isSystemic: yup.boolean().required(),
});

const responseSchema = yup.object({
  data: programSectionSchema.required(),
});
