/** list of user types in application */
export enum UserTypes {
  PATIENT = "patient",
  DIETICIAN = "dietitian",
}

export enum UserSexes {
  MEN = "m",
  WOMAN = "w",
  OTHER = "other",
}

/** list of user persmissions in application */
export enum UserPermissions {
  VIEW_PROGRAMS = "VIEW_PROGRAMS",
  VIEW_CLINIC_PATIENTS = "VIEW_CLINIC_PATIENTS",
  CREATE_CLINIC_PATIENTS = "CREATE_CLINIC_PATIENTS",
  CREATE_PROGRAMS = "CREATE_PROGRAMS",
  VIEW_CLINIC_BILLING_INFORMATION = "VIEW_CLINIC_BILLING_INFORMATION",
}

/** list of nutrition units */
export enum NutritionUnits {
  GRAMS = "GRAMS",
  GRAMS_PER_KILO = "GRAMS_PER_KILO",
  PERCENTAGES = "PERCENTAGES",
}

export enum MacrosUnits {
  GRAMS = "g",
  GRAMS_PER_KILO = "g/kg",
  PERCENTAGES = "%",
}
/** listof avaliable api methods */
export enum APIMethods {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  PATCH = "PATCH",
  DELETE = "DELETE",
}

/**
 * list of Polish number of meals names
 */
export enum PolishNumberOfMeals {
  ONE = "posiłek",
  LESS_THAN_FIVE = "posiłki",
  MORE_THAN_FIVE = "posiłków",
}

/**
 * list of English number of meals names
 */
export enum EnglishNumberOfMeals {
  ONE = "meal",
  MORE_THAN_ONE = "meals",
}

/** list of visits views in visits calendar */
export enum VisitsViews {
  WEEK = "week",
  WORK_WEEK = "work_week",
  MONTH = "month",
}

export enum ScheduleViews {
  WEEK = 7,
  TWO_WEEKS = 14,
  FOUR_WEEKS = 28,
}

export enum MealTypes {
  RECIPE = "recipe",
  FOOD = "food",
}

/** list of languages slugs in appliation */
export enum LanguagesSlugs {
  PL = "pl",
  EN = "en",
}

/** list of patient's additional features */
export enum PatientAdditionalFeatures {
  NO = "NO",
  IS_PREGNANT = "IS_PREGNANT",
  IS_FEEDING = "IS_FEEDING",
  IS_CHILD = "IS_CHILD",
}
export enum NumberTypes {
  INT = "INT",
  FLOAT = "FLOAT",
}

export enum RecipeType {
  SIMPLE = "SIMPLE",
  COMPLEX = "COMPLEX",
}

export enum Ownership {
  ALLOWEAT = "alloweat",
  OWN = "own",
}

export enum DietCategoryRadioInput {
  PRODUCTS,
  RECIPES,
  MEAL_PATTERNS,
}

export enum VisitTagGroupIds {
  DISEASE = 24,
  ALLERGIES = 25,
  SUPPLEMENTS = 26,
  MEDS = 27,
  DIGESTIVE = 29,
}

export enum Status {
  ACTIVE = 0,
  HIDDEN = 1,
}

export enum Reviewed {
  NO = 0,
  YES = 1,
}
