import { NormLocationDto } from "@client";
import { useAppTranslation } from "@hooks";
import { useMemo } from "react";

export const useNormTreeOptions = (norms?: NormLocationDto[]) => {
  const { isPolishChosen } = useAppTranslation();
  return useMemo(
    () =>
      norms?.map(normLocation => {
        return {
          value: `${normLocation.name}-${normLocation.id}`,
          title: normLocation.name,
          children: normLocation.normAge.map(norm => {
            return {
              value: `${norm.id}`,
              title: isPolishChosen ? norm.name : norm.nameEn,
              children: [],
            };
          }),
        };
      }) ?? [],
    [norms, isPolishChosen],
  );
};
