import { ReactNode, memo } from "react";
import { useAppTranslation } from "@hooks";
import { useSearchRecipesQueryNew } from "@hooks/queries";
import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";

import { Pagination } from "@components/Pagination";
import { HeaderWrapper, ResultsHeader } from "./SearchListElement.styled";
import { RecipesInputElement } from "@views/dietician/Diet/components/MealsRecipesTabs/MealsTab";
import { FiltersNoResults } from "@views/emptyStates/FiltersNoResults";
import { clearFormInputs } from "@utils";
import {
  DEFAULT_MACROS_RANGE,
  RecipesFiltersFormInput,
  RecipesFiltersNew,
  useRecipesFiltersForm,
} from "@components/RecipesFiltersNew";
import { FormProvider } from "react-hook-form";
import { ThemeProviderWrapperNew } from "themeNew";
import { FormTextFieldClearableStyled } from "@components/RecipesFiltersNew/RecipesFilters.styled";
import { InputAdornment } from "@mui/material";
import { Loupe } from "@assets/icons";
import { omit } from "lodash";
import { AutosaveWatchComponent } from "@components/AutosaveWatchComponent";

interface RecipesSearchViewProps {
  actionComponent: ReactNode;
  disabledRecipes: string[];
}

export const RecipesSearchView = memo(
  ({ actionComponent, disabledRecipes }: RecipesSearchViewProps) => {
    const { t } = useAppTranslation();
    const form = useRecipesFiltersForm();
    const { recipes, submit, isLoading, isFetching, isError, meta } =
      useSearchRecipesQueryNew({ disableParams: true });

    const handleClearInputs = () => clearFormInputs(form.reset, submit);

    const handleSubmitForm = (data: RecipesFiltersFormInput) => {
      const nutrientsWithNonDefaultValues = Object.entries(
        data.nutrients || {},
      ).reduce((acc, [key, value]) => {
        const defaultValue = DEFAULT_MACROS_RANGE[key];
        if (
          !defaultValue ||
          defaultValue[0] !== value[0] ||
          defaultValue[1] !== value[1]
        ) {
          acc[key] = value;
        }
        return acc;
      }, {} as { [id: string]: [number, number] });

      submit({
        ...omit(data, ["nutrients"]),
        page: undefined,
        nutrients: nutrientsWithNonDefaultValues,
      });
    };

    return (
      <div id="search">
        <div className="flex justify-between">
          <FormProvider {...form}>
            <ThemeProviderWrapperNew>
              <div className="flex gap-[4px]">
                <FormTextFieldClearableStyled
                  name="query"
                  size="small"
                  placeholder={t("filters.search_recipe")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Loupe />
                      </InputAdornment>
                    ),
                  }}
                />
                <RecipesFiltersNew unshiftTabs total={meta?.total} />
              </div>
            </ThemeProviderWrapperNew>

            <AutosaveWatchComponent
              isLoading={isFetching}
              onSubmit={handleSubmitForm}
              isDirtyCondition={false}
            />
          </FormProvider>

          {actionComponent}
        </div>

        {isLoading && <Spinner />}

        {isError && <ApiError />}

        <HeaderWrapper>
          {!!recipes?.length && (
            <ResultsHeader>{t("meal.search_results")}:</ResultsHeader>
          )}

          {!recipes?.length && !isLoading && (
            <FiltersNoResults onClick={handleClearInputs} />
          )}
        </HeaderWrapper>

        {recipes?.map(({ id, title, titleEn, servings, macros, tags }) => (
          <RecipesInputElement
            key={id}
            recipe={{
              id,
              isComplex: servings > 1,
              macros,
              nameEn: titleEn ?? "",
              namePl: title,
              tags,
            }}
            disabled={disabledRecipes.includes(title)}
          />
        ))}

        {recipes && recipes.length > 0 && meta?.links && (
          <Pagination
            links={meta.links}
            onClick={(url, page) => submit(value => ({ ...value, page }))}
          />
        )}
      </div>
    );
  },
);
