import { styled } from "@mui/material";
import { Modal } from "@components/Modal";

export const ActionButtonsSection = styled("div")`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  padding: 0 1rem;
`;

export const StyledModal = styled(Modal)`
  min-width: 36rem;
`;
