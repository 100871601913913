import { useMemo } from "react";
import { FormProvider } from "react-hook-form";

import {
  SystemQuestionDto,
  UserFatTissueAnswerResourceDto,
} from "@client/surveys";
import { useUpdateSingedSurveyMutation } from "@hooks/queries/surveys";

import { useUpdateValidationContext } from "../../context/useUpdateValidationContext";
import { useParseUrl } from "../../useParseUrl";
import { AutosaveOnBlurWatch } from "../AutosaveOnBlurWatch";
import { BlurContextProvider } from "../BlurContext";
import { Question } from "../Question/Question";
import {
  FatTissueAnswerFormInputs,
  mapFatTissueAnswerForm,
  mapFatTissueAnswerRequest,
  useFatTissueAnswerForm,
} from "../forms/useFatTissueAnswerForm";
import { ControlledFatTissueInputs } from "./ControlledFatTissueInputs";

interface SystemQuestionUserFatTissueProps {
  question: SystemQuestionDto;
  answer?: UserFatTissueAnswerResourceDto;
  listIndex: number;
  totalQuestions: number;
  readOnly?: boolean;
}
export const SystemQuestionUserFatTissue = ({
  question: {
    title,
    config: { required },
    id,
  },
  answer,
  readOnly,
  ...rest
}: SystemQuestionUserFatTissueProps) => {
  const { url } = useParseUrl();
  const mappedForm = useMemo(
    () => mapFatTissueAnswerForm(answer?.answer),
    [answer?.answer],
  );
  const { formState, handleSubmit, ...form } = useFatTissueAnswerForm(
    mappedForm,
    required,
  );
  const { mutate, isLoading } = useUpdateSingedSurveyMutation();

  const onSubmit = (data: FatTissueAnswerFormInputs) => {
    if (url) mutate({ payload: mapFatTissueAnswerRequest(data, id), url });
  };

  const { errors, touchedFields } = formState;
  const hasError = !!Object.values(errors).length;

  useUpdateValidationContext(id, touchedFields, handleSubmit);
  return (
    <FormProvider formState={formState} handleSubmit={handleSubmit} {...form}>
      <BlurContextProvider>
        <Question
          {...rest}
          title={title}
          description={null}
          required={required}
          hasError={hasError}
          readOnly={readOnly}
        >
          <ControlledFatTissueInputs readOnly={readOnly} />
        </Question>

        <AutosaveOnBlurWatch onSubmit={onSubmit} isLoading={isLoading} />
      </BlurContextProvider>
    </FormProvider>
  );
};
