import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  updatePatientBodyMeasurementSettings,
  UpdatePatientBodyMeasurementSettingsParams,
  UpdatePatientBodyMeasurementSettingsPayload,
} from "@client/patient/bodyMeasurement/updatePatientBodyMeasurementSettings";
import { FetchPatientBodyMeasurementSettingsResponse } from "@client/patient/bodyMeasurement/fetchPatientBodyMeasurementSettings";
import { patientBodyMeasurementKeys } from "@hooks/queries/client/bodyMeasurement/patientBodyMeasurementKey";

const useUpdatePatientBodyMeasurementSettingsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      params,
      payload,
    }: {
      params: UpdatePatientBodyMeasurementSettingsParams;
      payload: UpdatePatientBodyMeasurementSettingsPayload;
    }) => {
      return updatePatientBodyMeasurementSettings(params, payload);
    },
    onSuccess: async (_, variables) => {
      queryClient.setQueryData(
        patientBodyMeasurementKeys.settings(variables.params.patientId),
        (oldData: FetchPatientBodyMeasurementSettingsResponse | undefined) => {
          if (oldData === undefined) {
            return undefined;
          }

          return {
            ...oldData,
            data: {
              important: variables.payload.bodyMeasurementsId.map(id => ({
                id: id,
              })),
              requiredPhotos: variables.payload.requiredPhotos,
            },
          };
        },
      );
    },
  });
};

export default useUpdatePatientBodyMeasurementSettingsMutation;
