import { useQuery } from "@tanstack/react-query";
import { buildDefaultQueryOptions } from "../common";
import { fetchVisitNote } from "@client/visits";

export const fetchVisitNoteQueryKey = "fetchVisitNote";

export const useFetchVisitNoteQuery = (
  patientId: number,
  options?: { enabled?: boolean },
) => {
  const { data, ...rest } = useQuery(
    [fetchVisitNoteQueryKey, patientId],
    () => fetchVisitNote(patientId),
    buildDefaultQueryOptions(options),
  );

  return { note: data?.data.note, ...rest };
};
