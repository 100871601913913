import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { SelectedContext, SelectedContextIf } from "./SelectedContext";
import { ProductReplacementDto } from "@client/diets";
import { Unit } from "../useExchangeProductForm";
import { getNutrientValue } from "@utils";
import { ENERGY_ID } from "@utils/macros";
import { Nutrient } from "@typeDefinitions";

interface SelectedContextProviderProps {
  children: ReactNode;
  unit: Unit;
  onRatioChange: (value: number) => void;
  selectedDietProducts: number[];
}

export const SelectedContextProvider = ({
  children,
  unit,
  onRatioChange,
  selectedDietProducts,
}: SelectedContextProviderProps) => {
  const [selectedCurrent, setSelectedCurrent] =
    useState<ProductReplacementDto | null>(null);
  const [selectedReplacement, setSelectedReplacement] =
    useState<ProductReplacementDto | null>(null);
  const [nutrientsDifference, setNutrientsDifference] = useState<Nutrient[]>(
    [],
  );
  const ratio = useMemo(() => {
    if (
      unit === Unit.G ||
      !selectedCurrent?.nutrients.length ||
      !selectedReplacement?.nutrients.length
    )
      return 1;

    const currentKcal = getNutrientValue(selectedCurrent.nutrients, ENERGY_ID);
    const replacementKcal = getNutrientValue(
      selectedReplacement.nutrients,
      ENERGY_ID,
    );

    return currentKcal / (replacementKcal || 1);
  }, [unit, selectedCurrent, selectedReplacement]);

  const calculateDifferece = useCallback(
    (replacementNutrients: Nutrient[]) => {
      if (!selectedCurrent) return;

      const replacementNutrientsMap = new Map(
        replacementNutrients.map(nutrient => [nutrient.id, nutrient.value]),
      );

      return setNutrientsDifference(
        selectedCurrent.nutrients.map(({ id, value }) => {
          const replacementValue = replacementNutrientsMap.get(id) || 0;
          return { id, value: replacementValue - value };
        }),
      );
    },
    [selectedCurrent, selectedReplacement],
  );

  useEffect(() => {
    onRatioChange(ratio);
  }, [ratio]);

  const value: SelectedContextIf = {
    setSelectedCurrent,
    setSelectedReplacement,
    nutrientsDifference,
    calculateDifferece,
    selectedDietProducts,
    targetNutrients: selectedCurrent?.nutrients,
  };

  return (
    <SelectedContext.Provider value={value}>
      {children}
    </SelectedContext.Provider>
  );
};
