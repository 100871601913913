import { Card, styled } from "@mui/material";
import { MultiLanguageInput } from "@components/MultiLanguageInput";

export const DeskDivider = styled("div")`
  width: 1px;
  height: 100%;
  background: ${({ theme }) => theme.palette.grey[300]};
`;

export const PatientProgramDayGrid = styled("div")`
  display: grid;
  gap: 2rem;
  padding: 0.5rem 1rem 2.5rem 1rem;
  width: 100%;
`;

interface WrapperSectionProps {
  isDesk?: boolean;
}

export const WrapperSection = styled("div")<WrapperSectionProps>`
  display: ${({ isDesk }) => (isDesk ? "grid" : "flex")};
  gap: 2rem;
`;

export const MultiLanguageInputStyled = styled(MultiLanguageInput)`
  flex: 1;
  min-width: 30.437rem;
  max-width: 47rem;
`;

export const ProgramDayConfigStyled = styled("div")`
  display: flex;
  gap: 4rem;
  padding-top: 1rem;
  align-items: center;
`;

export const WeekdaySection = styled("div")`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const ViewWrapper = styled("div")`
  display: grid;
  gap: 2rem;
`;

export const StyledTopBalanceCard = styled(Card)`
  display: grid;
  padding: 1.5rem;
  gap: 2rem;
`;

export const TopSectionWrapper = styled("div")`
  display: grid;
  gap: 2rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.laptop}px) {
    grid-template-columns: 2fr 1px 1fr;
  }
`;
