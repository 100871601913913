import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";

export async function updateProgramDayMealRecipe(
  programId: number,
  dayId: number,
  mealId: number,
  payload: ProgramDayMealRecipeUpdatePayload,
): Promise<ApiResponse<void>> {
  return await fetchData(
    `/dietitian/programs/${programId}/schedule/days/${dayId}/meals/${mealId}/recipe`,
    APIMethods.PUT,
    { ...payload },
  );
}

export async function deleteProgramDayMealRecipe(
  programId: number,
  dayId: number,
  mealId: number,
): Promise<ApiResponse<void>> {
  return await fetchData(
    `/dietitian/programs/${programId}/schedule/days/${dayId}/meals/${mealId}/recipe`,
    APIMethods.DELETE,
  );
}

export interface ProgramDayMealRecipeUpdatePayload {
  id: number | null;
  servings?: number;
}
