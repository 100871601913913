import { useEffect, useState } from "react";

import range from "lodash/range";

import {
  ProductSearchItemDto,
  RecipeSearchItemDto,
} from "@client/diets/postDietAutoGenerateMeal";
import { ModalWrapper } from "@components/ModalWrapperNew";
import { useAppParams, useAppTranslation, useMealClientContext } from "@hooks";
import { usePostDietAutoGenerateMealMutation } from "@hooks/queries/diets";
import { getTranslation } from "@utils/translations";

import { usePostDietMealsItemsMutation } from "../../hooks";
import { GeneratingBackdrop } from "../GeneratingBackdrop";
import { mapRequest } from "../RecipesTab/AddElementSidePanel/ActionButtons";
import { RecipeAccordion } from "../RecipesTab/ElementsAccordions";
import { ProductAccordion } from "../RecipesTab/ElementsAccordions/ProductAccordion";
import {
  ProductItem,
  RecipeItem,
  useItemsContext,
} from "../RecipesTab/ItemsContext";
import {
  ContentWrapper,
  GenerateButton,
  LabelUppercase,
  SkeletonStyled,
  StickyHeader,
  Text,
} from "./common.styled";
import { MagicWand } from "@assets/icons";
import { EmptyState } from "./EmptyState";
import { SelectedItemsCounter } from "./SelectedItemsCounter";

interface AutogenerateMealModalProps {
  open: boolean;
  onClose: () => void;
  mealId: number;
  mealTitle: string;
  tags: number[];
  onSuccess?: () => void;
}

export const AutogenerateMealModal = ({
  open,
  onClose,
  mealId,
  mealTitle,
  tags,
  onSuccess,
}: AutogenerateMealModalProps) => {
  const { dietId } = useAppParams();
  const { t, currentLanguage } = useAppTranslation();
  const { mutate, isLoading: isLoadingItems } =
    usePostDietAutoGenerateMealMutation();
  const [items, setItems] = useState<
    null | (ProductSearchItemDto | RecipeSearchItemDto)[]
  >(null);
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [showEmptyState, setShowEmptyState] = useState(false);
  const { submit, addItems, clearValues } = useItemsContext();
  const { mutate: mutateItems, isLoading: isSavingItems } =
    usePostDietMealsItemsMutation();
  const {
    getRecipes,
    getProducts,
    productsClientContextMap,
    recipesClientContextMap,
  } = useMealClientContext();

  const handleSubmit = () => {
    submit(d =>
      mutateItems(
        { dietId, payload: { data: mapRequest(d) } },
        {
          onSuccess: () => {
            onSuccess?.();
            onClose();
          },
        },
      ),
    );
  };

  const handleGenerate = () => {
    setShowPlaceholder(true);
    clearValues();
    mutate(
      {
        dietId,
        payload: {
          tagsId: tags,
          mealId: Number(mealId),
        },
      },
      {
        onSuccess: data => {
          setItems(data.data.items);
          setShowEmptyState(data.data.items.length === 0);
          const mealId = data.data.meal.id;
          const mappedItems: (ProductItem | RecipeItem)[] = data.data.items.map(
            item => {
              if (item.type === "recipe")
                return {
                  type: "recipe",
                  id: item.recipe.id,
                  servings: item.servings,
                  mealId,
                };

              return {
                type: "product",
                id: item.product.id,
                grams: item.grams,
                measure: item.measure.id,
                mealId,
              };
            },
          );
          const recipesIds = mappedItems
            .filter((item): item is RecipeItem => item.type === "recipe")
            .map(({ id }) => id);
          const productsIds = mappedItems
            .filter((item): item is ProductItem => item.type === "product")
            .map(({ id }) => id);
          getProducts(productsIds);
          getRecipes(recipesIds);
          addItems(mappedItems);
        },
        onError: () => {
          setShowEmptyState(true);
        },
      },
    );
  };

  useEffect(() => {
    if (!open) return;

    handleGenerate();
  }, [open]);

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={`${t("diet.generate_meal.title")}, ${mealTitle}`}
      confirmText={t("diet.generate_meal.submit")}
      disabled={showPlaceholder}
      loading={isSavingItems}
      header={
        <StickyHeader>
          <Text>{t("diet.generate_meal.text")}</Text>
          <div className="flex justify-between">
            <LabelUppercase>
              {t("diet.generate_meal.proposals")}{" "}
              {!showPlaceholder && `(${items?.length ?? 0})`}
            </LabelUppercase>
            <GenerateButton onClick={handleGenerate}>
              <MagicWand />
              {t("diet.generate_meal.again")}
            </GenerateButton>
          </div>
        </StickyHeader>
      }
      footer={
        <div className="flex-1">
          {!showPlaceholder && <SelectedItemsCounter />}
        </div>
      }
    >
      <ContentWrapper>
        <div className="flex flex-col gap-[8px]">
          {!showPlaceholder && showEmptyState && <EmptyState />}
          {showPlaceholder &&
            range(0, 20).map(id => (
              <SkeletonStyled
                key={id}
                variant="rectangular"
                width={666.5}
                height={108}
              />
            ))}
          {!showPlaceholder &&
            items?.map(item => {
              if (item.type === "product") {
                const { id, name, translations, image, nutrients } =
                  item.product;
                return (
                  <ProductAccordion
                    key={id}
                    id={id}
                    namePl={name}
                    nameEn={getTranslation(translations, currentLanguage)}
                    imgUrl={image.url}
                    macros={nutrients}
                    tags={[]}
                    mealId={mealId}
                    clientContext={productsClientContextMap.get(id)}
                  />
                );
              }

              const {
                id,
                nutrients,
                image,
                servings,
                tags,
                name,
                translations,
              } = item.recipe;
              return (
                <RecipeAccordion
                  key={id}
                  id={id}
                  macros={nutrients}
                  imgUrl={image?.url}
                  servings={servings}
                  tags={tags.map(({ id }) => id)}
                  title={name}
                  titleEn={getTranslation(translations, currentLanguage)}
                  mealId={mealId}
                  clientContext={recipesClientContextMap.get(id)}
                />
              );
            })}
        </div>
      </ContentWrapper>
      {showPlaceholder && (
        <GeneratingBackdrop
          open={isLoadingItems}
          onCancel={onClose}
          onClose={() => setShowPlaceholder(false)}
        />
      )}
    </ModalWrapper>
  );
};
