import { useState } from "react";
import { useAppTranslation } from "@hooks";
import { useMediaQuery, useTheme } from "@mui/material";
import { noData } from "@views/dietician/ClientProfile/ClientProfile";
import {
  ContentEllipsis,
  WithoutBackgroundButton,
} from "./ExpandedTextArea.styled";

interface ExpandedTextAreaProps {
  note: string | null;
}

export const ExpandedTextArea = ({ note }: ExpandedTextAreaProps) => {
  const { t } = useAppTranslation();
  const { breakpoints } = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);
  const fromMedium = useMediaQuery(`${breakpoints.up("medium")}`);
  const maxLines = 3;
  const lettersQuantity = fromMedium ? 42 : 28;
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const showButton = note && note.length > maxLines * lettersQuantity;

  const handleNotes = (note: string | null) => {
    return note
      ? note.length > maxLines * lettersQuantity && !isExpanded
        ? `${note.slice(0, maxLines * lettersQuantity)}...`
        : note
      : noData;
  };
  return (
    <div className="grid">
      <ContentEllipsis>{handleNotes(note)}</ContentEllipsis>
      {showButton && (
        <WithoutBackgroundButton disableRipple onClick={toggleExpand}>
          {isExpanded
            ? t("client_profile.info.collapse")
            : t("client_profile.info.expand")}
        </WithoutBackgroundButton>
      )}
    </div>
  );
};
