import { ChangeEvent, useCallback, useEffect, useState } from "react";

import {
  CreateClientFormInput,
  mapCreateClientRequest,
  useAppTranslation,
  useCreateClientForm,
} from "@hooks";

import { FormAutocomplete } from "@components/FormAutocomplete";
import { FormTextField } from "@components/FormTextField";
import { SexSelector } from "@components/SexSelector";
import { CreateClientResponse } from "@client";
import { TAKEN_EMAIL_ERROR } from "@utils/clients";
import { Checkbox, Dialog, useTheme } from "@mui/material";

import {
  ActionButtonsWrapper,
  AddClientModalWrapper,
  CalendarIconStyled,
  Divider,
  HeaderStyled,
  SexButtonsWrapper,
  StyledAddVisitButton,
  StyledCancelBtn,
  StyledFormControlLabel,
  StyledFormCountryAutocomplete,
  StyledLabel,
  StyledPhoneTextField,
  StyledSubmitBtn,
  StyledTextArea,
  StyledTooltip,
} from "./AddClientModal.styled";
import { useNavigate } from "react-router-dom";
import { useCreateClientMutation } from "@hooks/queries";
import { AddVisitModal } from "@views/dietician/Diet/components/AddVisitModal";

interface AddClientModalProps {
  open: boolean;
  onClose: () => void;
  showAddVisit?: boolean;
  onSuccess?: (data?: CreateClientResponse) => void;
  transitionDuration?: number;
}

export const AddClientModal = ({
  open,
  onClose,
  showAddVisit = false,
  onSuccess,
  transitionDuration,
}: AddClientModalProps) => {
  const [editClientModal, setEditClientModal] = useState(false);
  const [newClientId, setNewClientId] = useState<number | undefined>(undefined);
  const [canFocus, setCanFocus] = useState(true);
  const [isEmailExist, setIsEmailExist] = useState(false);

  const { t } = useAppTranslation();
  const { transitions } = useTheme();

  const { mutate, isLoading } = useCreateClientMutation();
  const navigate = useNavigate();

  const {
    control,
    setValue,
    watch,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useCreateClientForm();

  const onError = () => {
    setCanFocus(true);
  };

  const sendEmail = watch("sendEmail");
  const email = watch("email");

  useEffect(() => {
    if (email.trim() === "") {
      setIsEmailExist(false);
      return;
    }
    setIsEmailExist(true);
  }, [email]);

  const languages = [
    { id: "pl", label: t("patients.add.personal_info.language.pl") },
    { id: "en", label: t("patients.add.personal_info.language.en") },
  ];
  const transitionExit = {
    enter: transitions.duration.enteringScreen,
    exit: 0,
  };
  const transitionEnter = {
    enter: 0,
    exit: transitions.duration.leavingScreen,
  };

  const submit = useCallback(
    (data: CreateClientFormInput) => {
      mutate(mapCreateClientRequest(data), {
        onSuccess: onSuccessData => {
          onClose();
          onSuccess && onSuccess(onSuccessData);
          reset();
        },
        onError: err => {
          if (err.response?.data.errors.email?.includes(TAKEN_EMAIL_ERROR))
            setError("email", { message: t("common.email_duplicate") ?? "" });
          setCanFocus(true);
        },
      });
    },
    [mutate, mapCreateClientRequest, navigate],
  );

  const closeWithoutSubmit = useCallback(() => {
    onClose();
    reset();
  }, [reset, onClose]);

  const handleCheckbox = (event: ChangeEvent<HTMLInputElement>) =>
    setValue("sendEmail", event.target.checked);

  const handleAddVisit = () => {
    handleSubmit(data => {
      mutate(mapCreateClientRequest(data), {
        onSuccess: data => {
          setNewClientId(data.id);
          setEditClientModal(true);
          onClose();
          onSuccess && onSuccess();
          reset();
        },
        onError: err => {
          if (err.response?.data.errors.email?.includes(TAKEN_EMAIL_ERROR))
            setError("email", { message: t("common.email_duplicate") ?? "" });
        },
      });
    })();
  };

  useEffect(() => {
    if (errors && canFocus) {
      const elements = Object.keys(errors)
        .map(name => document.getElementsByName(name)[0])
        .filter(el => !!el);
      elements.sort(
        (a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top,
      );

      if (elements.length > 0) {
        const errorElement = elements[0];
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" }); // scrollIntoView options are not supported in Safari
        errorElement.focus({ preventScroll: true });
        setCanFocus(false);
      }
    }
  }, [errors, canFocus]);

  if (editClientModal)
    return (
      <AddVisitModal
        onClose={() => setEditClientModal(false)}
        onOpen={editClientModal}
        defaultClient={newClientId}
        transitionDuration={transitionEnter}
        navigateToVisit
      />
    );

  return (
    <Dialog
      open={open}
      onClose={closeWithoutSubmit}
      transitionDuration={transitionDuration ?? transitionExit}
    >
      <AddClientModalWrapper>
        <HeaderStyled>{t("patients.add.title")}</HeaderStyled>
        <FormTextField
          control={control}
          name="firstName"
          size="small"
          label={t("patients.add.personal_info.first_name")}
          variant="outlined"
        />
        <FormTextField
          control={control}
          name="lastName"
          size="small"
          label={t("patients.add.personal_info.last_name")}
          variant="outlined"
        />
        <FormTextField
          control={control}
          name="email"
          size="small"
          label={t("patients.add.personal_info.email_address")}
          variant="outlined"
        />
        <div className="flex gap-5">
          <StyledFormCountryAutocomplete
            control={control}
            name="phonePrefix"
            id="phonePrefix"
            size="small"
            label={t("patients.add.personal_info.phone_prefix")}
            flex={1}
          />
          <StyledPhoneTextField
            control={control}
            name="phoneNumber"
            size="small"
            label={t("patients.add.personal_info.phone")}
            variant="outlined"
            flex={3}
          />
        </div>
        <FormAutocomplete
          id="lang"
          name="lang"
          control={control}
          options={languages}
          size="small"
          label={t("patients.add.lang")}
        />
        <SexButtonsWrapper>
          <StyledLabel>{t("patients.add.personal_info.sex.title")}</StyledLabel>
          <SexSelector control={control} name="sex" />
        </SexButtonsWrapper>
        <StyledTextArea
          control={control}
          name="description"
          label={t("patients.add.personal_info.additional_info")}
          multiline
          rows={5}
          size="small"
        />
        <Divider />
        <StyledTooltip
          placement="top"
          title={t("client_profile.disabled_app_msg")}
          disableInteractive={isEmailExist}
          disableHoverListener={isEmailExist}
          disableFocusListener={isEmailExist}
          disableTouchListener={isEmailExist}
        >
          <StyledFormControlLabel
            disabled={!isEmailExist}
            control={
              <Checkbox
                onChange={handleCheckbox}
                checked={sendEmail && isEmailExist}
              />
            }
            label={t("patients.add.personal_info.send_invitation")}
          />
        </StyledTooltip>
        {showAddVisit && (
          <StyledAddVisitButton variant="outlined" onClick={handleAddVisit}>
            <CalendarIconStyled />
            <span>{t("common.addVisit")}</span>
          </StyledAddVisitButton>
        )}
        <ActionButtonsWrapper>
          <StyledCancelBtn variant="contained" onClick={closeWithoutSubmit}>
            {t("common.cancel")}
          </StyledCancelBtn>
          <StyledSubmitBtn
            loading={isLoading}
            variant="contained"
            onClick={handleSubmit(submit, onError)}
          >
            {t("common.save")}
          </StyledSubmitBtn>
        </ActionButtonsWrapper>
      </AddClientModalWrapper>
    </Dialog>
  );
};
