import { useSearchParams } from "react-router-dom";
import { useMemo } from "react";
import {
  ProgramFiltersFormKeys,
  ProgramFiltersFormArrayNumberKeys,
  ProgramFiltersFormNumberKeys,
  ProgramsFiltersFormDefaultValues,
  ProgramFiltersFormStringKeys,
} from "@views/dietician/Programs/ProgramsFiltersFormType";

const useGetProgramFiltersSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const values = useMemo(() => {
    let tmpValue = { ...ProgramsFiltersFormDefaultValues };
    for (const key of ProgramFiltersFormKeys) {
      const paramValue = searchParams.get(key);
      if (!paramValue) {
        continue;
      }
      if (key === "page") {
        tmpValue = Object.assign(tmpValue, { [key]: parseInt(paramValue) - 1 });
        continue;
      }
      if (ProgramFiltersFormNumberKeys.includes(key)) {
        tmpValue = Object.assign(tmpValue, { [key]: paramValue });
      }
      if (ProgramFiltersFormArrayNumberKeys.includes(key)) {
        tmpValue = Object.assign(tmpValue, { [key]: paramValue.split(",") });
      }
      if (ProgramFiltersFormStringKeys.includes(key)) {
        tmpValue = Object.assign(tmpValue, { [key]: paramValue });
      }
    }
    return tmpValue;
  }, []);

  return { values };
};

export default useGetProgramFiltersSearchParams;
