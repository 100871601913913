import * as yup from "yup";

import {
  IdDto,
  LangDto,
  MainProductCategoryDto,
  idSchema,
  mainProductCategorySchema,
} from "@client/common";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const fetchDietsProductsReplacement = async (
  dietId: string,
): Promise<ApiResponse<ProductExchangeDto[]>> => {
  const response = await fetchData(
    `/dietitian/diets/${dietId}/products-replacement`,
    APIMethods.GET,
  );

  return responseSchema.validate(response);
};

interface TranslationLangDto {
  lang: LangDto;
  name: string;
}

interface ProductDto {
  id: number;
  name: string;
  category: IdDto;
  translations: TranslationLangDto[];
}

export interface ProductExchangeDto {
  from: ProductDto;
  to: ProductDto;
  mainProductCategory: MainProductCategoryDto;
}

const translationLangSchema = yup.object({
  lang: yup.mixed<LangDto>().oneOf(Object.values(LangDto)).required(),
  name: yup.string().required(),
});

const productSchema = yup.object({
  id: yup.number().required(),
  name: yup.string().required(),
  category: idSchema.required(),
  translations: yup.array().of(translationLangSchema).required(),
});

const productExchangeSchema = yup.object({
  from: productSchema.required(),
  to: productSchema.required(),
  mainProductCategory: mainProductCategorySchema.required(),
});

const responseSchema = yup.object().shape({
  data: yup.array().of(productExchangeSchema).required(),
});
