import {
  CreatorDietMealProductDto,
  CreatorDietMealProductRequest,
} from "@client/diets/creator";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

export const useProductEditForm = (defaultValues?: ProductEditInput) => {
  const form = useForm<ProductEditInput>({ defaultValues });

  useEffect(() => {
    if (defaultValues) form.reset(defaultValues);
  }, [defaultValues]);
  return form;
};

export interface ProductEditInput {
  foodId: number | null;
  grams: number;
  measureId: number | null;
}

export const mapProductEditForm = (
  data: CreatorDietMealProductDto | undefined,
): ProductEditInput | undefined => {
  if (!data) return;

  const { food, grams, measure } = data;

  return {
    foodId: food.id,
    grams: grams,
    measureId: measure.id,
  };
};

export const mapProductEditRequest = ({
  foodId,
  grams,
  measureId,
}: ProductEditInput): CreatorDietMealProductRequest => {
  return {
    food: { id: foodId ?? 0 },
    grams,
    measure: { id: measureId ?? 0 },
  };
};
