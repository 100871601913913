import { useContentSelector } from "@context/TableOfContentsContext";
import { FullSurveyDto } from "@client/surveys";

import { SubgroupContent } from "./SubgroupContent";

interface ContentSelectorProps {
  survey?: FullSurveyDto;
}
export const ContentSelector = ({ survey }: ContentSelectorProps) => {
  const { isSubgroup } = useContentSelector();

  if (isSubgroup) return <SubgroupContent survey={survey} />;

  return <></>;
};
