import { styled } from "@mui/material";

interface MissingProps {
  missing?: boolean;
}

export const MicroListSection = styled("div")<MissingProps>`
  gap: 1rem;
  display: grid;
  grid-template-columns: 2fr repeat(4, 1fr);
  width: 100%;
  justify-content: space-around;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};
  background: ${({ missing }) => (missing ? "rgb(253, 237, 237)" : "")};
`;

export const StyledTitle = styled("p")`
  padding: 0.5rem;
`;

export const StyledItem = styled("p")<MissingProps>`
  padding: 0.5rem;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  color: ${({ missing, theme }) => (missing ? theme.palette.error.light : "")};
`;
