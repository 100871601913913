import { styled } from "@mui/material";

const MealListItem = styled("li")`
  padding: 12px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.light[200]};
  :last-of-type {
    border-bottom: none;
    padding-bottom: 0px;
  }
  :first-of-type {
    padding-top: 0px;
  }
`;

export default MealListItem;
