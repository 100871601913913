import { useEffect, useState } from "react";

export const useFiniteProgress = (
  start: boolean,
  duration: number,
  startValue = 0,
) => {
  const [yValue, setYValue] = useState(startValue);
  let xValue = startValue;
  let interval: ReturnType<typeof setInterval>;
  const step = (100 - startValue) / (duration / 100);

  const countValue = () => {
    interval = setInterval(() => {
      xValue += step;
      if (xValue >= 100) {
        xValue = 100;
        clearInterval(interval);
      }
      setYValue(xValue);
    }, 100);
  };

  useEffect(() => {
    if (!start) {
      xValue = startValue;
      setYValue(startValue);
    } else {
      countValue();
    }

    return () => clearInterval(interval);
  }, [start, duration]);

  return [yValue];
};
