import { useCallback, useMemo, useState } from "react";

import { IconButton } from "@mui/material";

import {
  Edit,
  Envelope,
  Handset,
  MapMarker,
  ReceiptWithPercent,
} from "@assets/icons";
import { useAppTranslation } from "@hooks";
import { mapAddressString } from "@utils/settings";
import { useFetchClinicBillingDataQuery } from "@hooks/queries/settings";
import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";
import { BillingDataModal } from "@components/BillingDataModal";

import {
  BillingTabStyled,
  HeaderWrapper,
  RowWrapper,
  StyledContent,
  StyledH2,
  StyledLabel,
} from "./BillingTab.styled";

export const BillingTab = () => {
  const { billingData, isLoading, isError, isSuccess } =
    useFetchClinicBillingDataQuery();
  const { t, isPolishChosen } = useAppTranslation();

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = useCallback(() => setOpenModal(true), []);
  const handleCloseModal = useCallback(() => setOpenModal(false), []);

  const mappedAddress = useMemo(
    () =>
      mapAddressString(
        billingData && {
          ...billingData.address,
          postcode: billingData.address.postCode,
        },
        isPolishChosen,
      ),
    [billingData, isPolishChosen],
  );
  const mappedPhone = useMemo(() => {
    if (!billingData) return "";

    const phoneArr = [
      billingData.phone.prefix,
      billingData.phone.number?.match(/.{1,3}/g)?.join(" "),
    ];
    return phoneArr.filter(val => val).join(" ");
  }, [billingData]);

  if (isError || (isSuccess && !billingData)) return <ApiError />;

  if (isLoading) return <Spinner />;

  return (
    <>
      <BillingDataModal open={openModal} onClose={handleCloseModal} />
      <div className="w-full flex justify-start">
        <BillingTabStyled>
          <HeaderWrapper>
            <StyledH2>{t("billing_settings.title")}</StyledH2>
            <IconButton color="primary" onClick={handleOpenModal}>
              <Edit size="w-5 h-5" />
            </IconButton>
          </HeaderWrapper>
          <RowWrapper>
            <Handset size="w-5 h-5" />
            <div className="flex flex-col gap-2">
              <StyledLabel>{t("billing_settings.name")}</StyledLabel>
              <StyledContent>
                {billingData?.name || t("common.not_specified")}
              </StyledContent>
            </div>
          </RowWrapper>
          <RowWrapper>
            <Envelope size="w-5 h-5" />
            <div className="flex flex-col gap-2">
              <StyledLabel>{t("billing_settings.email")}</StyledLabel>
              <StyledContent>
                {billingData?.email || t("common.not_specified")}
              </StyledContent>
            </div>
          </RowWrapper>
          <RowWrapper>
            <Handset size="w-5 h-5" />
            <div className="flex flex-col gap-2">
              <StyledLabel>{t("billing_settings.phone")}</StyledLabel>
              <StyledContent>
                {mappedPhone || t("common.not_specified")}
              </StyledContent>
            </div>
          </RowWrapper>
          <RowWrapper>
            <MapMarker fill="currentColor" size="w-5 h-5" />
            <div className="flex flex-col gap-2">
              <StyledLabel>{t("billing_settings.address")}</StyledLabel>
              <StyledContent>
                {mappedAddress || t("common.not_specified")}
              </StyledContent>
            </div>
          </RowWrapper>
          <RowWrapper>
            <ReceiptWithPercent size="w-5 h-5" />
            <div className="flex flex-col gap-2">
              <StyledLabel>{t("billing_settings.vat_id")}</StyledLabel>
              <StyledContent>
                {billingData?.vatId || t("common.not_specified")}
              </StyledContent>
            </div>
          </RowWrapper>
        </BillingTabStyled>
      </div>
    </>
  );
};
