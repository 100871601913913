import { TagCategorySimplified } from "@hooks/useTagsNew";
import { compact } from "lodash";

export enum TagCategoryType { // ids
  MEAL = 1, // 1
  TYPE_OF_RECIPE = 2, // 8
  OTHER = 3, // 18
  BASE_PRODUCT = 4, // 22
  SEASONALITY = 5, // 7
  COOKWARE = 6, // 10
  PREPARATION_TIME = 7, // 2
  DIFFICULTY_OF_PREPARATION = 8, // 21
  KIND_OF_CUISINE = 9, //19
  ALLERGENS = 10, // 13
  DISEASES = 11, // 24
  ALLERGIES = 12, // 25
  SUPPLEMENTS = 13, // 26
  MEDICINES = 14, // 27
  ELIMINATIONS = 15, // 15
  AILMENTS_GASTROINTESTINAL_TRACT = 16, // 29
  OWN = 17, // 30
  DIET = 18, // 23
  TASTE_PREFERENCE = 19, // 31
  DIET_TYPE = 20, // 32
  SYMPTOMS_AFTER_MEAL = 23,
  EMOTIONS_AFTER_MEAL = 24,
}

export enum TagSystemIdCorrect {
  DIET_TYPE_GLUTEN_FREE = 4000,
  DIET_TYPE_LACTOSE_FREE = 4001,
  DIET_TYPE_VEGAN = 4003,
  DIET_TYPE_VEGETARIAN = 4002,
}

export enum TagSystemId {
  DIET_TYPE_GLUTEN_FREE = 722,
  DIET_TYPE_VEGAN = 723,
  DIET_TYPE_VEGETARIAN = 724,
  DIET_TYPE_LACTOSE_FREE = 725,
  OTHER_MULTI_SERVING = 6000,
}

export const DISABLED_SYSTEM_TAGS = [
  TagSystemId.DIET_TYPE_GLUTEN_FREE,
  TagSystemId.DIET_TYPE_LACTOSE_FREE,
  TagSystemId.DIET_TYPE_VEGETARIAN,
  TagSystemId.DIET_TYPE_VEGAN,
  TagSystemId.OTHER_MULTI_SERVING,
];

const PRODUCT_TAG_CATEGORIES_TYPE = [
  TagCategoryType.ALLERGENS,
  TagCategoryType.ELIMINATIONS,
  TagCategoryType.BASE_PRODUCT,
  TagCategoryType.OWN,
];

export const RECIPE_TAG_CATEGORIES_TYPE = [
  TagCategoryType.MEAL,
  TagCategoryType.PREPARATION_TIME,
  TagCategoryType.SEASONALITY,
  TagCategoryType.TYPE_OF_RECIPE,
  TagCategoryType.COOKWARE,
  TagCategoryType.OTHER,
  TagCategoryType.KIND_OF_CUISINE,
  TagCategoryType.DIFFICULTY_OF_PREPARATION,
  TagCategoryType.TASTE_PREFERENCE,
  TagCategoryType.DIET_TYPE,
  TagCategoryType.ALLERGENS,
  TagCategoryType.BASE_PRODUCT,
  TagCategoryType.OWN,
];

const MEAL_TAG_CATEGORIES_TYPE = [
  TagCategoryType.MEAL,
  TagCategoryType.PREPARATION_TIME,
  TagCategoryType.SEASONALITY,
  TagCategoryType.TYPE_OF_RECIPE,
  TagCategoryType.COOKWARE,
  TagCategoryType.OTHER,
  TagCategoryType.KIND_OF_CUISINE,
  TagCategoryType.DIFFICULTY_OF_PREPARATION,
  TagCategoryType.TASTE_PREFERENCE,
  TagCategoryType.DIET_TYPE,
  TagCategoryType.OWN,
];
const DIET_TAG_CATEGORIES_TYPE = [
  TagCategoryType.ELIMINATIONS,
  TagCategoryType.ALLERGENS,
  TagCategoryType.DIET,
  TagCategoryType.OWN,
];

export const SPECIFIC_TAG_CATEGORIES_TYPE: Record<TagCategoryBase, number[]> = {
  product: PRODUCT_TAG_CATEGORIES_TYPE,
  recipe: RECIPE_TAG_CATEGORIES_TYPE,
  meal: MEAL_TAG_CATEGORIES_TYPE,
  diet: DIET_TAG_CATEGORIES_TYPE,
};

export type TagCategoryBase = "product" | "recipe" | "meal" | "diet";

export const filterTagsTypeDictionary = (
  categories: Map<number, TagCategorySimplified>,
  base: TagCategoryBase | number[],
  filterQuery?: string,
): TagCategorySimplified[] => {
  const types = Array.isArray(base) ? base : SPECIFIC_TAG_CATEGORIES_TYPE[base];
  const lowerCaseFilterQuery = filterQuery?.toLowerCase() || "";
  const filteredCategories = compact(types.map(type => categories.get(type)));

  const categoryMatchesQuery = (category: TagCategorySimplified) =>
    category.name.toLowerCase().includes(lowerCaseFilterQuery);

  const filterTagsInCategory = (category: TagCategorySimplified) =>
    category.tags.filter(tag =>
      tag.name.toLowerCase().includes(lowerCaseFilterQuery),
    );

  const filterCategory = (category: TagCategorySimplified) => {
    const filteredTags = filterTagsInCategory(category);
    const matchesCategory =
      categoryMatchesQuery(category) || filteredTags.length > 0;
    return matchesCategory
      ? {
          ...category,
          tags: filteredTags.length > 0 ? filteredTags : category.tags,
        }
      : null;
  };

  return filterQuery
    ? filteredCategories
        .map(filterCategory)
        .filter(
          (category): category is TagCategorySimplified => category !== null,
        )
    : filteredCategories;
};

export const clearFormInputs = (
  reset: (values: Record<string, never>) => void,
  submit: (value: Record<string, never>) => void,
) => {
  reset({});
  submit({});
};
