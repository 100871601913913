import { ReactNode, useState } from "react";
import { Control, FieldValues, Path, useController } from "react-hook-form";

import { compact } from "lodash";

import { SelectOption } from "@components/FormAutocomplete";
import { StyledAutocomplete } from "./FormMultipleAutocomplete.styled";
import { TextField, useTheme } from "@mui/material";
import { SearchNoResults } from "@views/emptyStates/SearchNoResults";

export interface FormMultipleAutocompleteProps<T extends FieldValues> {
  control?: Control<T>;
  name: Path<T>;
  label?: ReactNode;
  placeholder?: string;
  options: SelectOption[];
  color?: "error" | "warning" | "success" | "primary";
}
export const FormMultipleAutocomplete = <T extends FieldValues>({
  control,
  name,
  label,
  placeholder,
  options,
  color,
}: FormMultipleAutocompleteProps<T>) => {
  const { colors } = useTheme();
  const [inputValue, setInputValue] = useState("");
  const {
    field: { value: fieldValue, onChange },
  } = useController({ control, name });
  const selectedTags = fieldValue as string[];

  const selectedTagsOptions =
    selectedTags &&
    compact(
      selectedTags.map(tag =>
        options.find(option => option.id === tag.toString()),
      ),
    );

  const handleOnChange = (value: string[]) => {
    onChange(value);
    setInputValue("");
  };

  return (
    <StyledAutocomplete
      disableCloseOnSelect
      noOptionsText={<SearchNoResults />}
      value={selectedTagsOptions}
      options={options}
      multiple
      inputValue={inputValue}
      ChipProps={{
        color: color !== "warning" ? color : undefined,
        sx: {
          backgroundColor:
            color === "warning" ? colors.orange.light : undefined,
          color: color === "warning" ? colors.orange.dark : undefined,
        },
      }}
      onChange={(_, value) => handleOnChange(value.map(tag => tag.id))}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          size="small"
          InputLabelProps={{ shrink: true }}
          placeholder={placeholder}
          onChange={v => setInputValue(v.target.value)}
        />
      )}
    />
  );
};
