import { Card, styled, Typography } from "@mui/material";

export const StyledCard = styled(Card)`
  display: grid;
  gap: 1rem;
  padding: 1rem;
`;

export const ProductWrapper = styled("div")`
  display: grid;
  padding: 1.75rem;
  gap: 0.75rem;
`;

export const TypographyStyled = styled(Typography)`
  font-weight: 600;
`;

export const StyledElement = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.border}`};
  padding: 0.5rem 1rem 0.3rem;
`;
