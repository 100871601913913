import { CountLabel, Pagination } from "@components/PaginationNew";

import { MealRowIf } from "@components/MealPatternsDataGrid/MealPatternsDataGrid";
import { FiltersNoResults } from "@views/emptyStates/FiltersNoResults";
import {
  CardStyled,
  GridWrapper,
  PaginationWrapper,
} from "./MealPatternsGridLayout.styled";
import { MealTile } from "./MealTile";

interface MealPatternsGridLayoutProps {
  meals: MealRowIf[];
  onPageChange?: (page: number) => void;
  page: number;
  perPage: number;
  setPerPage: (value: number) => void;
  isOnePage: boolean;
  rowCount?: number;
  loading?: boolean;
  onMealSelect: (id: number) => void;
  resetFilters: () => void;
  isLoading: boolean;
}

export const MealPatternsGridLayout = ({
  meals,
  onPageChange,
  page,
  perPage,
  setPerPage,
  isOnePage,
  rowCount,
  onMealSelect,
  resetFilters,
  isLoading,
}: MealPatternsGridLayoutProps) => {
  return (
    <div className="grid gap-[7px]">
      {!meals.length && !isLoading && (
        <CardStyled>
          <FiltersNoResults onClick={resetFilters} />
        </CardStyled>
      )}
      {!!meals.length && (
        <GridWrapper>
          {meals.map((m, idx) => (
            <MealTile
              {...m}
              key={`${idx}-${m.id}`}
              onSelect={() => onMealSelect(m.id)}
            />
          ))}
        </GridWrapper>
      )}

      <PaginationWrapper>
        <CountLabel count={rowCount ?? 1} page={page} perPage={perPage} />
        <div className="flex items-center">
          <Pagination
            isOnePage={isOnePage}
            page={page}
            onPageChange={(_, newPage) => onPageChange && onPageChange(newPage)}
            count={rowCount}
            perPage={perPage}
            setPerPage={v => setPerPage(v as number)}
            rowsPerPageOptions={[10, 25]}
          />
        </div>
      </PaginationWrapper>
    </div>
  );
};
