import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { TabContext } from "@mui/lab";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
} from "@mui/material";

import { Loupe } from "@assets/icons";
import { Close } from "@assets/icons/DesignSystem";
import { TabSwitch } from "@components/TabSwitch";
import { useAppTranslation } from "@hooks";

import { ClearHistoryButton } from "./ClearHistoryButton/ClearHistoryButton";
import { ExchangedList } from "./ExchangedList";
import { ProductsList } from "./ProductsList";
import {
  IconButtonOutlined,
  SearchField,
  TabPanelStyled,
} from "./ProductsTab.styled";
import { RemovedList } from "./RemovedList/RemovedList";

export const ProductsTab = () => {
  const { t } = useAppTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab") || Tab.ALL;
  const [group, setGroup] = useState(true);
  const [query, setQuery] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [count, setCount] = useState({
    [Tab.ALL]: 0,
    [Tab.REPLACED]: 0,
    [Tab.DELETED]: 0,
  });

  const tabs = useMemo(() => createTabs(tab, count), [t, count, tab]);

  function createTabs(currentTab: string, counts: Count) {
    return [
      {
        id: Tab.ALL,
        label:
          currentTab === Tab.ALL && counts[Tab.ALL]
            ? `${t("common.all")} (${counts[Tab.ALL]})`
            : t("common.all"),
      },
      {
        id: Tab.REPLACED,
        label:
          currentTab === Tab.REPLACED && counts[Tab.REPLACED]
            ? `${t("diet.exchanged")} (${counts[Tab.REPLACED]})`
            : t("diet.exchanged"),
      },
      {
        id: Tab.DELETED,
        label:
          currentTab === Tab.DELETED && counts[Tab.DELETED]
            ? `${t("diet.deleted")} (${counts[Tab.DELETED]})`
            : t("diet.deleted"),
      },
    ];
  }

  const toggleShowSearch = () => setShowSearch(!showSearch);

  const clearQuery = () => setQuery("");

  const handleChangeTab = (value: string) => {
    const params = new URLSearchParams();
    params.set("tab", value);
    setSearchParams(params, { replace: true });
    setQuery("");
  };

  return (
    <div className="grid">
      <div className="flex justify-between">
        <div className="flex gap-[8px] mb-[16px] items-center">
          <TabSwitch value={tab} onChange={handleChangeTab} tabs={tabs} />
          <IconButtonOutlined onClick={toggleShowSearch}>
            <Loupe />
          </IconButtonOutlined>

          {showSearch && (
            <SearchField
              size="small"
              value={query}
              onChange={e => setQuery(e.target.value)}
              placeholder={t("products.search")}
              InputProps={{
                endAdornment: query && (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={clearQuery}>
                      <Close size="w-[10px] h-[10px]" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </div>

        <div className="flex gap-[16px] items-center">
          {tab !== Tab.ALL && <ClearHistoryButton variant={tab} />}
          <FormControlLabel
            label={t("diet.group_by_category")}
            control={
              <Checkbox checked={group} onChange={(e, v) => setGroup(v)} />
            }
          />
        </div>
      </div>

      <TabContext value={tab}>
        <TabPanelStyled value={Tab.ALL}>
          <ProductsList
            group={group}
            query={showSearch ? query : ""}
            setCount={c => setCount(state => ({ ...state, [Tab.ALL]: c }))}
          />
        </TabPanelStyled>

        <TabPanelStyled value={Tab.REPLACED}>
          <ExchangedList
            group={group}
            query={showSearch ? query : ""}
            setCount={c => setCount(state => ({ ...state, [Tab.REPLACED]: c }))}
            changeTab={() => handleChangeTab(Tab.ALL)}
          />
        </TabPanelStyled>

        <TabPanelStyled value={Tab.DELETED}>
          <RemovedList
            group={group}
            query={showSearch ? query : ""}
            setCount={c => setCount(state => ({ ...state, [Tab.DELETED]: c }))}
            changeTab={() => handleChangeTab(Tab.ALL)}
          />
        </TabPanelStyled>
      </TabContext>
    </div>
  );
};

export enum Tab {
  ALL = "all",
  REPLACED = "replaced",
  DELETED = "deleted",
}

interface Count {
  [Tab.ALL]: number;
  [Tab.REPLACED]: number;
  [Tab.DELETED]: number;
}
