import { Divider, FormControlLabel, styled } from "@mui/material";
import { Settings } from "@assets/icons";

export const NutrientsViewSettingsWrapper = styled("div")`
  margin: 0 4px;
  padding-left: 10px;
`;

export const StyledSettings = styled(Settings)`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const ColumnsWrapper = styled("div")`
  columns: 2 auto;
  padding: 0 1rem;
  column-gap: 0.75rem;
`;

export const GroupWrapper = styled("div")`
  display: grid;
  break-inside: avoid;
  margin-bottom: 0.75rem;
  min-width: 18.75rem;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  padding-left: 0.625rem;
`;

export const StyledDivider = styled(Divider)`
  margin-bottom: 0.75rem;
`;

export const SpinnerWrapper = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40.75rem;
  height: 10rem;
`;
