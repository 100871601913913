import { postPatientRecipesContext, RecipeContextDto } from "@client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ApiResponse } from "@typeDefinitions";
import { MutationOptions } from "./types";
import { useAppParams } from "@hooks/useAppParams";

export const usePostPatientRecipesContextMutationKey =
  "usePostPatientRecipesContextMutationKey";

export const usePostPatientRecipesContextMutation = (
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();
  const { patientId } = useAppParams();

  return useMutation<
    ApiResponse<RecipeContextDto[]>,
    unknown,
    UsePostPatientRecipesContextMutationPayload
  >(
    ({ patientId: controlledPatientId, payload }) => {
      const recipesContext = queryClient.getQueryData<
        ApiResponse<RecipeContextDto[]>
      >([
        usePostPatientRecipesContextMutationKey,
        controlledPatientId ?? patientId,
      ])?.data;
      const recipesIds = recipesContext?.map(({ id }) => id);
      const filteredPayload = recipesIds?.length
        ? payload.filter(id => !recipesIds.includes(id))
        : payload;
      const noPatientId = controlledPatientId === undefined && !patientId;

      if (!filteredPayload.length || noPatientId)
        return Promise.resolve({ data: [] });
      return postPatientRecipesContext(
        controlledPatientId ?? patientId,
        filteredPayload,
      );
    },
    {
      ...options,
      onSuccess: (data, variables, c) => {
        options?.onSuccess?.(data, variables);

        queryClient.setQueryData<ApiResponse<RecipeContextDto[]>>(
          [
            usePostPatientRecipesContextMutationKey,
            variables.patientId ?? patientId,
          ],
          oldData => {
            const filteredOldData =
              oldData?.data.filter(
                oldRecipe =>
                  !data.data.some(newRecipe => newRecipe.id === oldRecipe.id),
              ) ?? [];

            let newData = [...filteredOldData, ...data.data];

            if (!data.data.length) return oldData;

            if (newData.length > MAX_LIST_LENGTH) {
              const idsInPayload = new Set(variables.payload);
              newData = newData.filter(
                (item, index) =>
                  idsInPayload.has(item.id) ||
                  index >= newData.length - MAX_LIST_LENGTH,
              );
            }

            return {
              data: newData,
            };
          },
        );
      },
    },
  );
};

interface UsePostPatientRecipesContextMutationPayload {
  patientId?: string;
  payload: number[];
}

const MAX_LIST_LENGTH = 1000;
