import * as yup from "yup";

export enum SectionType {
  CONTENT = "content",
  MEALS_SUGGESTED = "meals_suggested",
  DAYS_SCHEDULE = "days_schedule",
  SHOPPING_LIST = "shipping_list",
  MEALS_SCHEME = "meals_scheme",
}

export enum CollectionType {
  PROGRAM_SECTION_TEMPLATE = "program-section-template",
  VISIT_SCHEDULE = "visit-schedule",
  CLINIC_LOGO = "clinic-logo",
  USER_AVATAR = "user-avatar",
  FOOD_GROUP = "food-group",
  RECIPE = "recipe",
  DIET_MEAL = "diet-meal",
}

export enum LangDto {
  PL = "pl",
  EN = "en",
}

export interface TagDto {
  id: number;
}

export interface TagSystemIdDto extends TagDto {
  systemId: number | null;
}
export interface IdDto {
  id: number;
}

export interface NutrientDto {
  id: number;
  value: number;
}

export interface SectionCategoryDto {
  id: number;
  description: string;
  descriptionEn: string;
}

export interface SectionRequestDto {
  title: string;
  titleEn: string;
  content: string;
  contentEn: string;
  programSectionCategoryId: number;
  reviewed?: boolean;
}

export interface ActionsDto {
  canEdit: boolean;
  canDelete: boolean;
  canVisible: boolean;
}

export interface PhoneDto {
  prefix: string | null;
  number: string | null;
}

export interface DictionaryNameDto {
  lang: "pl" | "en";
  value: string;
}

export interface AddressDto {
  street: string | null;
  streetNumber: string | null;
  houseNumber: string | null;
  city: string | null;
  postCode: string | null;
}

export interface DietitianFacilityDto {
  id: number;
  name: string;
}

export interface MediaDto {
  id: number;
}

export interface MediaExtendedDto {
  clinicId: number | null;
  collection: CollectionType;
  id: number;
  mineType: string;
  name: string;
  originalName: string;
  path: string;
  size: number;
  url: string;
  s3Url: string;
}

export interface ImageDto {
  url: string;
}
export interface ClinicIdDto {
  id: number | null;
}

export interface TranslationDto {
  langId: LangDto;
  name: string;
}
export interface TranslationLangDto {
  lang: LangDto;
  name: string;
}

export interface TranslationTitleDto {
  langId: LangDto;
  title: string;
}
export interface TranslationLangDto {
  lang: LangDto;
  name: string;
}

export interface TagCategoryDto {
  id: number;
}
export interface TagCategoryNullableDto {
  id: number | null;
}

export interface TagExtendedDto extends TagDto {
  clinic: ClinicIdDto;
  name: string;
  nameEn: string;
  tagCategory: TagCategoryDto;
  translations: TranslationDto[];
}

export interface TagExtendedNewDto {
  id: string;
  name: string;
  translations: TranslationDto[];
  clinicN: { id: number } | null;
  tagCategory: { id: number | null };
}

export enum SexDto {
  m = "m",
  w = "w",
}

export interface MeasureDto {
  id: number;
  grams: number;
}

export interface MainProductCategoryDto {
  id: number;
  description: string;
  descriptionPl: string;
  parentId: number;
}

export const tagSchema = yup.object().shape({
  id: yup.number().required(),
});

export const tagSystemIdDtoSchema = yup.object({
  id: yup.number().required(),
  systemId: yup.number().nullable().default(null),
});

export const idSchema = yup.object().shape({
  id: yup.number().required(),
});

export const nutrientSchema = yup.object().shape({
  id: yup.number().required(),
  value: yup
    .number()
    .transform((_, value: null | number) => (value === null ? 0 : value))
    .required(),
});

export const sectionSearchCategorySchema = yup.object().shape({
  id: yup.number().required(),
  description: yup.string().required(),
  descriptionEn: yup.string().required(),
});

export const actionsSchema = yup.object().shape({
  canEdit: yup.boolean().required(),
  canDelete: yup.boolean().required(),
  canVisible: yup.boolean().required(),
});

export const phoneSchema = yup.object().shape({
  prefix: yup.string().nullable().default(null),
  number: yup.string().nullable().default(null),
});

export const dictionaryNameSchema = yup.object({
  lang: yup.mixed().oneOf(["pl", "en"]).required(),
  value: yup.string().required(),
});

export const addressSchema = yup.object().shape({
  street: yup.string().nullable().default(null),
  streetNumber: yup.string().nullable().default(null),
  houseNumber: yup.string().nullable().default(null),
  city: yup.string().nullable().default(null),
  postCode: yup.string().nullable().default(null),
});

export const dietitianFacilitySchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
});

export const mediaSchema = yup.object().shape({
  id: yup.number().required(),
});

export const mediaExtendedSchema = yup.object().shape({
  clinicId: yup.number().nullable().default(null),
  collection: yup
    .mixed<CollectionType>()
    .oneOf(Object.values(CollectionType))
    .required(),
  id: yup.number().required(),
  mineType: yup.string().required(),
  name: yup.string().required(),
  originalName: yup.string().required(),
  path: yup.string().required(),
  size: yup.number().required(),
  url: yup.string().required(),
  s3Url: yup.string().required(),
});

export const imageSchema = yup.object().shape({
  url: yup.string().required(),
});

export const clinicIdSchema = yup.object().shape({
  id: yup.number().nullable().default(null),
});

export const translationSchema = yup.object().shape({
  langId: yup.mixed<LangDto>().oneOf(Object.values(LangDto)).required(),
  name: yup.string().ensure(),
});

export const translationLangSchema = yup.object({
  lang: yup.mixed<LangDto>().oneOf(Object.values(LangDto)).required(),
  name: yup.string().ensure(),
});

export const translationTitleSchema = yup.object().shape({
  langId: yup.mixed<LangDto>().oneOf(Object.values(LangDto)).required(),
  title: yup.string().ensure(),
});

export const tagCategorySchema = yup.object().shape({
  id: yup.number().required(),
});

export const tagExtendedSchema = yup.object().shape({
  id: yup.number().required(),
  clinic: clinicIdSchema.required(),
  name: yup.string().required(),
  nameEn: yup.string().required(),
  tagCategory: tagCategorySchema.required(),
  translations: yup.array().of(translationSchema).required(),
});

export const tagCategoryNullableSchema = yup.object().shape({
  id: yup.number().nullable().default(null),
});

export const tagExtendedNewSchema = yup.object().shape({
  id: yup.string().required(),
  name: yup.string().required(),
  translations: yup.array().of(translationSchema).required(),
  clinicN: idSchema.nullable().default(null),
  tagCategory: tagCategoryNullableSchema.required(),
});

export const sexSchema = yup.mixed<SexDto>().oneOf(Object.values(SexDto));

export const measureSchema = yup.object().shape({
  id: yup.number().required(),
  grams: yup.number().required(),
});

export const mainProductCategorySchema = yup.object().shape({
  id: yup.number().required(),
  descriptionPl: yup.string().required(),
  description: yup.string().ensure(),
  parentId: yup.number().required(),
});
