import { useAppTranslation } from "@hooks";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { CellWrapper, StyledDate } from "../ClientsTable";
import { CooperationDetails } from "../AvatarComponent";
import dayjs from "dayjs";
import { Tooltip } from "@mui/material";

interface VisitsComponentProps {
  id: number;
  scheduleStatus: number;
  scheduleId?: number;
  scheduleDate?: string;
}

export const VisitsComponent = ({
  scheduleStatus,
  id,
  scheduleId,
  scheduleDate,
}: VisitsComponentProps) => {
  const { t } = useAppTranslation();

  const scheduleStatusTranslation = useMemo(() => {
    switch (scheduleStatus) {
      case 1:
        return t("clients.table.unconfirmed");
      case 2:
        return t("clients.table.confirmed");
      case 3:
        return t("clients.filters.canceled");
      case 4:
        return t("clients.filters.no_appointment");

      default:
        break;
    }
  }, [t]);
  return (
    <Link className="w-full" to={`/patients/${id}/appointments#${scheduleId}`}>
      <CellWrapper>
        {scheduleStatus && scheduleDate ? (
          <>
            {scheduleDate && (
              <Tooltip
                title={dayjs(scheduleDate).format("HH:mm")}
                arrow
                placement="top"
              >
                <StyledDate>
                  {dayjs(scheduleDate).format("DD.MM.YYYY")}
                </StyledDate>
              </Tooltip>
            )}
            <CooperationDetails>{scheduleStatusTranslation}</CooperationDetails>
          </>
        ) : (
          t("clients.filters.no_appointment")
        )}
      </CellWrapper>
    </Link>
  );
};
