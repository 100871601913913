import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { TranslationDto } from "@client";
import * as yup from "yup";

export const postCreateMeal = async (
  payload: CreateMealRequest,
): Promise<ApiResponse<CreateMealResponse>> => {
  const response = await fetchData(
    "/dietitian/creator/meals",
    APIMethods.POST,
    payload,
  );
  return apiResponseSchema.validate(response);
};

export interface CreateMealRequest {
  translations: TranslationDto[];
  reviewed: boolean;
}

export interface CreateMealResponse {
  id: number;
}

const createMealSchema = yup.object().shape({
  id: yup.number().required(),
});

const apiResponseSchema = yup.object().shape({
  data: createMealSchema.required(),
});
