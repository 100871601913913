import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Person = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        d="M14.5 8.00439C14.5 7.12939 14 6.31689 13.25 5.84814C12.4688 5.41064 11.5 5.41064 10.75 5.84814C9.96875 6.31689 9.5 7.12939 9.5 8.00439C9.5 8.91064 9.96875 9.72314 10.75 10.1919C11.5 10.6294 12.4688 10.6294 13.25 10.1919C14 9.72314 14.5 8.91064 14.5 8.00439ZM8 8.00439C8 6.59814 8.75 5.28564 10 4.56689C11.2188 3.84814 12.75 3.84814 14 4.56689C15.2188 5.28564 16 6.59814 16 8.00439C16 9.44189 15.2188 10.7544 14 11.4731C12.75 12.1919 11.2188 12.1919 10 11.4731C8.75 10.7544 8 9.44189 8 8.00439ZM6.53125 18.5044H17.4375C17.1562 16.5356 15.4688 15.0044 13.4062 15.0044H10.5625C8.5 15.0044 6.8125 16.5356 6.53125 18.5044ZM5 19.0981C5 16.0044 7.46875 13.5044 10.5625 13.5044H13.4062C16.5 13.5044 19 16.0044 19 19.0981C19 19.5981 18.5625 20.0044 18.0625 20.0044H5.90625C5.40625 20.0044 5 19.5981 5 19.0981Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
