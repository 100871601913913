import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { QueryOptionsTyped } from "../types";
import { ApiResponse } from "@typeDefinitions";
import {
  ProductReplacementClientContextDto,
  ProductReplacementDto,
  searchDietProductsForReplacement,
} from "@client/diets";
import { useMemo, useState } from "react";
import { parseNumber, parseString } from "@utils";

export const searchDietProductsForReplacementQueryKey =
  "searchDietProductsForReplacementQueryKey";

export const useSearchDietProductsForReplacementQuery = (
  options?: QueryOptionsTyped<
    ApiResponse<ProductReplacementDto[] | ProductReplacementClientContextDto[]>
  >,
) => {
  const [committedInputs, setCommittedInputs] =
    useState<SearchDietProductsReplacementInputs>({});
  const params = useMemo(() => parseParams(committedInputs), [committedInputs]);
  const { data, ...rest } = useQuery(
    [searchDietProductsForReplacementQueryKey, params.toString()],
    () => searchDietProductsForReplacement(params),
    options,
  );

  return {
    data: data?.data,
    ...rest,
    submit: setCommittedInputs,
  };
};

const parseParams = (filters: SearchDietProductsReplacementInputs) => {
  const params = new URLSearchParams();

  parseString(filters?.query || undefined, "query", params);
  parseNumber(filters?.client || undefined, "client", params);

  return params;
};

interface SearchDietProductsReplacementInputs {
  query?: string;
  client?: number;
}

export const searchDietProductsForReplacementInfiniteQueryKey =
  "searchDietProductsForReplacementInfiniteQueryKey";

export const useSearchDietProductsForReplacementInfiniteQuery = (
  defaultInputs: SearchDietProductsReplacementInputs | undefined = {},
  options?: QueryOptionsTyped<
    ApiResponse<ProductReplacementDto[] | ProductReplacementClientContextDto[]>
  >,
) => {
  const [committedInputs, setCommittedInputs] =
    useState<SearchDietProductsReplacementInputs>(defaultInputs);
  const params = useMemo(() => parseParams(committedInputs), [committedInputs]);

  const fetchDietProducts = ({ pageParam = 1 }) => {
    const paginatedParams = new URLSearchParams(params);
    paginatedParams.set("page", pageParam.toString());
    return searchDietProductsForReplacement(paginatedParams);
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    ...rest
  } = useInfiniteQuery(
    [searchDietProductsForReplacementInfiniteQueryKey, params.toString()],
    fetchDietProducts,
    {
      getNextPageParam: ({ meta }) => {
        if (!meta) return;
        const { currentPage, total, perPage } = meta;
        const nextPage = currentPage + 1;
        return nextPage <= Math.ceil(total / (perPage || 1))
          ? nextPage
          : undefined;
      },
      enabled: options?.enabled,
      keepPreviousData: options?.keepPreviousData,
      refetchOnMount: options?.refetchOnMount,
    },
  );

  const products = data?.pages.flatMap(page => page.data) || [];
  const lastMeta = data ? data.pages[data.pages.length - 1].meta : undefined;

  return {
    products,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    submit: setCommittedInputs,
    meta: lastMeta,
    ...rest,
  };
};
