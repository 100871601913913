import { useCallback, useMemo } from "react";

import { Dialog } from "@mui/material";

import { useAppTranslation } from "@hooks";
import {
  ClinicFacilityFormInput,
  mapClinicFacilityForm,
  mapClinicFacilityRequest,
  useClinicFacilityForm,
} from "@hooks/settings";
import {
  useCreateClinicFacility,
  useFetchClinicFacilityQuery,
  useUpdateClinicFacility,
} from "@hooks/queries/settings";

import { FormLabeledCheckboxMui } from "@components/FormCheckboxMui";
import { FormTextField } from "@components/FormTextField";
import {
  ActionButtonsWrapper,
  AddOfficeModalWrapper,
  Divider,
  HeaderStyled,
  StyledCancelBtn,
  StyledFormCountryAutocomplete,
  StyledPhoneTextField,
  StyledSubmitBtn,
} from "./AddOfficeModal.styled";

interface AddOfficeModalProps {
  open: boolean;
  onClose: () => void;
  officeId?: number;
}

export const AddOfficeModal = ({
  open,
  onClose,
  officeId,
}: AddOfficeModalProps) => {
  const { t } = useAppTranslation();
  const { facility } = useFetchClinicFacilityQuery(officeId ?? 0, {
    enabled: !!officeId,
  });

  const mappedFacility = useMemo(
    () => mapClinicFacilityForm(facility),
    [facility],
  );

  const { control, reset, handleSubmit } =
    useClinicFacilityForm(mappedFacility);
  const closeWithReset = useCallback(() => {
    onClose();
    reset();
  }, [onClose]);

  const { mutate: createFacility } = useCreateClinicFacility({
    onSuccess: closeWithReset,
  });
  const { mutate: updateFacility } = useUpdateClinicFacility(officeId ?? 0, {
    onSuccess: closeWithReset,
  });

  const submit = useCallback(
    (data: ClinicFacilityFormInput) =>
      officeId
        ? updateFacility(mapClinicFacilityRequest(data))
        : createFacility(mapClinicFacilityRequest(data)),
    [createFacility, mapClinicFacilityRequest, updateFacility, officeId],
  );

  return (
    <Dialog open={open} onClose={closeWithReset}>
      <AddOfficeModalWrapper>
        <HeaderStyled>{t("office_modal.title")}</HeaderStyled>
        <FormTextField
          control={control}
          name="name"
          size="small"
          label={t("office_modal.name")}
          variant="outlined"
        />
        <FormTextField
          control={control}
          name="email"
          size="small"
          label={t("office_modal.email")}
          variant="outlined"
        />
        <div className="flex gap-5">
          <StyledFormCountryAutocomplete
            control={control}
            name="prefix"
            id="prefix"
            size="small"
            label={t("office_modal.phone_prefix")}
            flex={1}
          />
          <StyledPhoneTextField
            control={control}
            name="phone"
            size="small"
            label={t("office_modal.phone")}
            variant="outlined"
            flex={3}
          />
        </div>
        <FormTextField
          control={control}
          name="street"
          size="small"
          label={t("office_modal.street")}
          variant="outlined"
        />
        <div className="flex gap-5">
          <StyledPhoneTextField
            control={control}
            name="streetNumber"
            size="small"
            label={t("office_modal.building_numb")}
            variant="outlined"
            flex={1}
          />
          <StyledPhoneTextField
            control={control}
            name="houseNumber"
            size="small"
            label={t("office_modal.flat_numb")}
            variant="outlined"
            flex={1}
          />
        </div>
        <div className="flex gap-5">
          <StyledPhoneTextField
            control={control}
            name="postCode"
            size="small"
            label={t("office_modal.zip_code")}
            variant="outlined"
            flex={1}
          />
          <StyledPhoneTextField
            control={control}
            name="city"
            size="small"
            label={t("office_modal.city")}
            variant="outlined"
            flex={1}
          />
        </div>
        <Divider />
        <FormLabeledCheckboxMui
          control={control}
          name="isMain"
          label={t("office_modal.is_main_office")}
        />
        <ActionButtonsWrapper>
          <StyledCancelBtn variant="contained" onClick={closeWithReset}>
            {t("common.cancel")}
          </StyledCancelBtn>
          <StyledSubmitBtn variant="contained" onClick={handleSubmit(submit)}>
            {t("common.save")}
          </StyledSubmitBtn>
        </ActionButtonsWrapper>
      </AddOfficeModalWrapper>
    </Dialog>
  );
};
