import { SingleSelectFilter } from "@components/Filters/MultiSelectFilter";
import { AppTranslationProps, useAppTranslation } from "@hooks";
import { useMemo } from "react";

const ProgramDurationField = () => {
  const { t } = useAppTranslation();
  const mappedOptions = useMemo(() => options({ t: t }), [t]);

  return (
    <SingleSelectFilter
      label={t("programs.program_duration")}
      options={mappedOptions}
      name="days"
    />
  );
};

const options = ({ t }: { t: AppTranslationProps["t"] }) =>
  [7, 14, 21, 28, 29].map(day => ({
    id: day.toString(),
    label: t("programs.up_to", { count: day }),
  }));

export default ProgramDurationField;
