import { IconButton, Checkbox } from "@mui/material";
import { FormTextFieldStyled } from "./CheckboxOption.styled";
import { TrashNew } from "@assets/icons";

interface CheckboxOptionProps {
  onDelete: () => void;
  name: string;
}

export const CheckboxOption = ({ name, onDelete }: CheckboxOptionProps) => {
  return (
    <FormTextFieldStyled
      InputProps={{
        startAdornment: <Checkbox disabled />,
        endAdornment: (
          <IconButton onClick={onDelete} color="primary">
            <TrashNew />
          </IconButton>
        ),
      }}
      size="small"
      name={name}
    />
  );
};
