import { CheckNew } from "@assets/icons";

import { CustomChip, LabelWrapper } from "./ChipsSelect.styled";
import { isArray } from "lodash";

interface OptionIf {
  id: number;
  label: string;
}
interface ChipsSelectProps {
  options: OptionIf[];
  selectedId: number | number[] | null;
  onChange: (id: number) => void;
  size?: "small" | "medium";
  hideIcon?: boolean;
  borderRadius?: string;
}
export const ChipsSelect = ({
  options,
  selectedId,
  onChange,
  size = "small",
  hideIcon = false,
  borderRadius,
}: ChipsSelectProps) => {
  return (
    <div className="flex gap-[6px]">
      {options.map(({ id, label }) => {
        const isSelected = isArray(selectedId)
          ? selectedId.includes(id)
          : selectedId === id;

        return (
          <CustomChip
            size={size}
            key={id}
            color={isSelected ? "primary" : "default"}
            onClick={() => onChange(id)}
            variant="outlined"
            label={
              <LabelWrapper>
                {!hideIcon && isSelected && (
                  <CheckNew size="w-[12px] h-[12px]" />
                )}
                {label}
              </LabelWrapper>
            }
            borderRadius={borderRadius}
          />
        );
      })}
    </div>
  );
};
