import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const TrashNew = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path
        d="M1.69995 3.79999H3.09995H14.3"
        stroke={props.fill ?? "currentColor"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M12.8996 3.8V13.6C12.8996 13.9713 12.7521 14.3274 12.4896 14.5899C12.227 14.8525 11.8709 15 11.4996 15H4.49961C4.12831 15 3.77221 14.8525 3.50966 14.5899C3.24711 14.3274 3.09961 13.9713 3.09961 13.6V3.8M5.19961 3.8V2.4C5.19961 2.0287 5.34711 1.6726 5.60966 1.41005C5.87221 1.1475 6.22831 1 6.59961 1H9.39961C9.77091 1 10.127 1.1475 10.3896 1.41005C10.6521 1.6726 10.7996 2.0287 10.7996 2.4V3.8"
        stroke={props.fill ?? "currentColor"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};
