import { useEffect } from "react";
import { useForm } from "react-hook-form";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useAppTranslation } from "@hooks";
import { LangDto } from "@client";
import {
  ClinicDietitianResponse,
  CreateClinicDietitianRequest,
  UpdateClinicDietitianRequest,
} from "@client/settings";

export const useDietitianForm = (defaultValues?: DietitianFormInput) => {
  const { t } = useAppTranslation();

  const dietitianSchema = yup.object().shape({
    name: yup.string().required(t("common.required_field")),
    lastname: yup.string().required(t("common.required_field")),
    email: yup
      .string()
      .email(t("common.email_invalid"))
      .required(t("common.required_field")),
    prefix: yup.string().nullable(),
    phone: yup.string().nullable(),
    locationId: yup
      .number()
      .typeError("common.wrong_value")
      .required(t("common.required_field")),
    sendEmail: yup.boolean().nullable(),
    lang: yup.mixed().oneOf(Object.values(LangDto)).nullable(),
  });

  const form = useForm<DietitianFormInput>({
    defaultValues: {
      prefix: "",
    },
    resolver: yupResolver(dietitianSchema),
  });

  useEffect(() => {
    if (defaultValues) form.reset(defaultValues);
  }, [defaultValues]);

  return form;
};

export interface DietitianFormInput {
  name: string;
  lastname: string;
  email: string;
  prefix: string | null;
  phone: string | null;
  locationId: number | null;
  sendEmail?: boolean;
  lang?: LangDto;
}

export const mapDietitianForm = (
  data?: ClinicDietitianResponse,
): DietitianFormInput => {
  return {
    name: data?.firstName ?? "",
    lastname: data?.lastName ?? "",
    email: data?.email ?? "",
    locationId: data?.facility?.id ?? null,
    phone: data?.phone.number ?? "",
    prefix: data?.phone.prefix ?? "",
    lang: LangDto.PL,
  };
};

export const mapCreateDietitianRequest = (
  data: DietitianFormInput,
): CreateClinicDietitianRequest => {
  return {
    email: data.email,
    facility: {
      id: data.locationId ?? 0,
    },
    firstName: data.name,
    lastName: data.lastname,
    sendAccessEmail: data.sendEmail ?? false,
    phone: {
      prefix: data.prefix,
      number: data.phone,
    },
    language: data.lang ?? LangDto.PL,
  };
};

export const mapUpdateDietitianRequest = (
  data: DietitianFormInput,
): UpdateClinicDietitianRequest => {
  return {
    firstName: data.name,
    lastName: data.lastname,
    facility: {
      id: data.locationId ?? 0,
    },
    phone: {
      prefix: data.prefix,
      number: data.phone,
    },
    language: data.lang ?? LangDto.PL,
  };
};
