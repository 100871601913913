import { ChangeEventHandler, ReactNode, useState } from "react";

import { useTheme } from "@mui/material";

import { Upload } from "@assets/icons/DesignSystem";
import { useAppTranslation } from "@hooks";

import {
  ContainerStyled,
  DeleteButton,
  Description,
  DotStyled,
  ImgContainer,
  ImgStyled,
  InputStyled,
  Title,
} from "./UploadImage.styled";
import { TrashNew } from "@assets/icons";
import { usePostMediaRecipeUploadMutation } from "@hooks/queries";
import { MediaResourceDto } from "@client/postMediaRecipeUpload";

interface ResolutionIf {
  width: number;
  height: number;
}
interface UploadImageProps {
  title?: ReactNode;
  description?: string;
  min?: ResolutionIf;
  img?: string;
  onDelete?: () => void;
  onSuccess?: (media?: MediaResourceDto) => void;
}

export const UploadImage = ({
  title,
  min,
  img,
  onDelete,
  onSuccess,
}: UploadImageProps) => {
  const { palette } = useTheme();
  const { t } = useAppTranslation();

  const [submitting, setSubmitting] = useState(false);

  const { mutate } = usePostMediaRecipeUploadMutation({
    onSuccess: async data => {
      onSuccess?.(data?.data), setSubmitting(false);
    },
    onError: () => setSubmitting(false),
  });

  const onChange: ChangeEventHandler<HTMLInputElement> = async event => {
    if (submitting) return;
    setSubmitting(true);

    const formData = new FormData();
    event.target.files && formData.set("image", event.target.files[0]);

    mutate(formData);
  };

  if (img)
    return (
      <ImgContainer>
        <ImgStyled src={img} />
        <DeleteButton onClick={onDelete} color="primary">
          <TrashNew />
        </DeleteButton>
      </ImgContainer>
    );

  return (
    <ContainerStyled>
      <Upload fill={palette.primary.main} size="w-[16px] h-[16px]" />

      <div className="flex flex-col items-center">
        <Title>{title}</Title>
        <Description>
          PNG, JPG (max 1MB)
          {min && (
            <div className="flex">
              <DotStyled />
              min {min.width}x{min.height}px
            </div>
          )}
        </Description>
      </div>

      <InputStyled
        type="file"
        accept="image/png, image/jpeg"
        size={1024000}
        onChange={onChange}
      />
    </ContainerStyled>
  );
};
