import React from "react";
import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const CheckboxIndeterminate = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 20" fill="none">
    <g clipPath="url(#clip0_3252_9530)">
      <path
        d="M16 0H4C1.79086 0 0 1.79086 0 4V16C0 18.2091 1.79086 20 4 20H16C18.2091 20 20 18.2091 20 16V4C20 1.79086 18.2091 0 16 0Z"
        fill="currentColor"
      />
      <path
        d="M6.00005 10.2938L14.5978 10.3039"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3252_9530">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
