import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { useAppTranslation } from "@hooks";
import { CheckRound } from "@assets/icons";
import { TextWrapper } from "@views/public/Visit/components";
import { ContainerWrapper } from "@views/public/components";
import { useConfirmVisitMutation } from "@hooks/queries";

export const VisitConfirm = () => {
  const { t } = useAppTranslation();
  const { search } = useLocation();
  const { mutate } = useConfirmVisitMutation();

  const url = useMemo(() => new URLSearchParams(search).get("url"), [search]);

  useEffect(() => {
    if (url)
      mutate(atob(url), {
        onError: e => {
          if (e === "Already confirmed") {
            toast.warning(t("visits.canceled_confirmation"));
          }
        },
      });
  }, [url]);

  return (
    <ContainerWrapper className="px-40 shadow-paper">
      <CheckRound size="w-14 h-auto" className="mb-7" />

      <TextWrapper className="font-bold">
        {t("visit.confirm.title")}
      </TextWrapper>
    </ContainerWrapper>
  );
};
