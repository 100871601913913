import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Card,
  ClickAwayListener,
  Popper,
  useTheme,
} from "@mui/material";

import { useAppTranslation } from "@hooks";
import { CHAT_MAIN } from "@routes";
import { SpeechBubble } from "@assets/icons";
import { StyledBadge } from "./ChatList.styled";
import { StyledHeaderButton } from "@layouts/Dashboard/components";
import { ChatboxContext } from "@context/ChatboxContext";
import { useInbox, useUnreadMessages } from "@hooks/talkjs";

export const ChatList = () => {
  const navigate = useNavigate();
  const { session } = useContext(ChatboxContext);
  const { unread } = useUnreadMessages(session);
  const { inbox, inboxRef } = useInbox({
    session,
    theme: "v2_desktop_conversations",
  });
  const { t } = useAppTranslation();
  const { colors } = useTheme();
  const { openChat } = useContext(ChatboxContext);

  useEffect(() => {
    if (!inbox) return;

    inbox.onSelectConversation(e => {
      e.preventDefault();
      const user = e.others[0];
      if (user) openChat(e.conversation.id);
      setChatOpen(false);
    });
    inbox.select(null);
  }, [inbox]);

  const ref = useRef(null);
  const [chatOpen, setChatOpen] = useState(false);

  const handleNavigateChat = () => {
    setChatOpen(false);
    navigate(CHAT_MAIN);
  };

  return (
    <ClickAwayListener onClickAway={() => setChatOpen(false)}>
      <div className="ml-1 mr-5">
        <StyledHeaderButton
          ref={ref}
          size="small"
          onClick={() => setChatOpen(!chatOpen)}
        >
          <StyledBadge badgeContent={unread} color="primary">
            <SpeechBubble size="w-5 h-5" fill={colors.neutral.dark[800]} />
          </StyledBadge>
        </StyledHeaderButton>

        <Popper
          open={chatOpen}
          anchorEl={ref.current}
          placement="bottom-end"
          keepMounted
          style={{ zIndex: 1000 }}
        >
          <Card className="grid gap-1">
            <Button onClick={handleNavigateChat}>
              {t("common.chat_navigate")}
            </Button>
            <div ref={inboxRef} className="h-96" />
          </Card>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};
