import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const BittenMeat = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="14" viewBox="0 0 14 14">
      <path
        d="M4.59497 7.23359C4.59497 7.44941 4.50864 7.65508 4.3563 7.80742L3.67583 8.48789C3.36353 8.8002 2.85063 8.77734 2.42153 8.6707C2.30474 8.64023 2.18286 8.625 2.05591 8.625C1.21548 8.625 0.532471 9.30801 0.532471 10.1484C0.532471 10.9889 1.21548 11.6719 2.05591 11.6719C2.21587 11.6719 2.3606 11.8166 2.3606 11.9766C2.3606 12.817 3.0436 13.5 3.88403 13.5C4.72446 13.5 5.40747 12.817 5.40747 11.9766C5.40747 11.8496 5.39224 11.7277 5.36177 11.6084C5.25513 11.1793 5.22974 10.6664 5.54458 10.3541L6.22505 9.67363C6.37739 9.52129 6.58306 9.43496 6.79888 9.43496H9.06372C9.22368 9.43496 9.37856 9.42734 9.53345 9.40957C9.8356 9.3791 9.94985 9.01602 9.80767 8.74941C9.59185 8.34824 9.46997 7.89121 9.46997 7.40371C9.46997 5.83203 10.742 4.55996 12.3137 4.55996C12.5168 4.55996 12.7124 4.58027 12.9028 4.6209C13.1999 4.68438 13.5147 4.47109 13.4614 4.17402C13.0881 2.08691 11.26 0.5 9.06372 0.5C6.59575 0.5 4.59497 2.50078 4.59497 4.96875V7.23359Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
