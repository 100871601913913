import { useController } from "react-hook-form";

import { NormAutocomplete } from "./NormAutocomplete";
import { NutrientNormInputs } from "./useNutrientsNormForm";

export const FormNormAutocomplete = () => {
  const {
    field: { value, onChange },
  } = useController<NutrientNormInputs, "normId">({ name: "normId" });

  return <NormAutocomplete value={value} onChange={onChange} />;
};
