import { useQuery } from "@tanstack/react-query";
import { fetchTasks } from "@client/tasks/fetchTasks";
import { useQueryParams } from "../useQueryParams";
import { TaskType } from "@views/dietician/Tasks/components/TasksAccordion/TasksAccordion";
import { useTaskViewSettingsContext } from "@views/dietician/Tasks/components/TaskViewSettings.context";
import { SortByEnum } from "@hooks/tasks/useTaskFilterOptions";
import type { Task } from "@client/tasks/fetchTasks";
import dayjs from "dayjs";
import { ApiEndpoints } from "@typeDefinitions";
import type { QueryParams } from "@client/tasks/fetchTasks";

type QueryParam = string | string[] | undefined;

enum TaskStatus {
  UnFinished,
  Finished,
}

const composeTasksQueryParams = (
  type: TaskType,
  initialParams: Pick<QueryParams, "page" | "per_page">,
  additionalParams: Omit<QueryParams, "page" | "per_page">,
) => {
  const composedParams = {
    ...initialParams,
    ...additionalParams,
  };

  switch (type) {
    case TaskType.Todo:
      return {
        ...composedParams,
        done: TaskStatus.UnFinished,
      };
    case TaskType.Completed:
      return { ...initialParams, done: TaskStatus.Finished };
    case TaskType.Tag:
      return composedParams;
    default:
      return initialParams;
  }
};

export const normalizeToNumberArray = (value: QueryParam) => {
  if (typeof value === "undefined") {
    return undefined;
  }

  if (typeof value === "string") {
    return value;
  }

  if (Array.isArray(value)) {
    const numberArray = value
      .map(item => Number(item))
      .filter(item => !isNaN(item));

    if (numberArray.length === 1) {
      return numberArray[0].toString();
    }

    return numberArray.length > 0 ? numberArray : undefined;
  }

  return value;
};

const getSortByParams = (sortBy: SortByEnum) => {
  const [orderBy, orderDir] = sortBy.split("=");
  const queryParams = {
    orderBy,
    orderDir,
  };

  return queryParams;
};

export const useTasksQuery = (
  page: number,
  perPage: number,
  type: TaskType,
  tagId?: string,
) => {
  const { sortBy } = useTaskViewSettingsContext();
  const { orderBy, orderDir } = getSortByParams(sortBy);

  const { get } = useQueryParams(
    "customTagsIds",
    "dietitiansIds",
    "dueDateFrom",
    "dueDateTo",
    "orderBy",
    "orderDir",
  );

  const { customTagsIds, dietitiansIds, dueDateFrom, dueDateTo } = get<{
    customTagsIds: string[] | undefined;
    dietitiansIds: string[] | undefined;
    deadline: string | undefined;
    dueDateFrom: string | undefined;
    dueDateTo: string | undefined;
  }>();

  const initialParams = {
    page: page + 1,
    per_page: perPage,
  };

  const customTags = customTagsIds?.length
    ? type === TaskType.Todo || type === TaskType.Tag
      ? customTagsIds
      : tagId
    : type === TaskType.Tag
    ? tagId
    : undefined;

  const additionalParams = {
    custom_tags_ids: normalizeToNumberArray(customTags),
    dietitians_ids: normalizeToNumberArray(dietitiansIds),
    due_date_from: dueDateFrom,
    due_date_to: dueDateTo,
    order_by: orderBy,
    order_dir: orderDir,
  };

  const finalQueryParams = composeTasksQueryParams(
    type,
    initialParams,
    additionalParams,
  );

  return useQuery({
    queryKey: [ApiEndpoints.Tasks, type, JSON.stringify(finalQueryParams)],
    queryFn: async () => {
      const { data, ...rest } = await fetchTasks(finalQueryParams);

      return { tasks: data, ...rest };
    },
  });
};
