import { useFetchSchedulesDictionariesQuery } from "@hooks/queries";

export const useDietitiansAndFacilities = () => {
  const { schedulesDictionaries } = useFetchSchedulesDictionariesQuery();

  const dietitiansMapped = schedulesDictionaries?.dietitians.map(dietitian => ({
    id: dietitian.id.toString(),
    label: `${dietitian.firstName} ${dietitian.lastName}`,
  }));

  const facilitiesMapped = schedulesDictionaries?.facilities.map(clinic => ({
    id: clinic.id.toString(),
    label: clinic.city,
  }));

  return { schedulesDictionaries, dietitiansMapped, facilitiesMapped };
};
