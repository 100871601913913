import { ReactNode, useMemo, useState } from "react";
import {
  DEFAULT_DAY_KCAL,
  DEFAULT_TOTAL_KCAL,
  DEFUALT_WEEKS,
  SimulationSlidersContext,
} from "./SimulationSlidersContext";

interface SimulationSlidersContextProviderProps {
  children: ReactNode;
}

export const SimulationSlidersContextProvider = ({
  children,
}: SimulationSlidersContextProviderProps) => {
  const [totalKcalDiff, setTotalKcalDiff] =
    useState<number>(DEFAULT_TOTAL_KCAL);
  const [dayKcalDiff, setDayKcalDiff] = useState<number>(DEFAULT_DAY_KCAL);
  const [weeksToGoal, setWeeksToGoal] = useState<number>(DEFUALT_WEEKS);

  const value = useMemo(
    () => ({
      totalKcalDiff,
      setTotalKcalDiff,
      dayKcalDiff,
      setDayKcalDiff,
      weeksToGoal,
      setWeeksToGoal,
    }),
    [
      totalKcalDiff,
      setTotalKcalDiff,
      dayKcalDiff,
      setDayKcalDiff,
      weeksToGoal,
      setWeeksToGoal,
    ],
  );

  return (
    <SimulationSlidersContext.Provider value={value}>
      {children}
    </SimulationSlidersContext.Provider>
  );
};
