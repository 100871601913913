import { ReactNode } from "react";
import { LabelWrapper, StyledLabel } from "./Label.styled";

interface LabelProps {
  label: string | false;
  children: ReactNode;
  maxWidth?: number;
  flex?: number;
  hidden?: boolean;
  className?: string;
}

export const Label = ({
  label,
  children,
  flex = 1,
  hidden,
  className,
}: LabelProps) => {
  return (
    <LabelWrapper flex={flex} hidden={hidden} className={className}>
      {label && <StyledLabel>{label}</StyledLabel>}
      {children}
    </LabelWrapper>
  );
};
