import { IdDto, idSchema } from "@client/common";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import * as yup from "yup";

export const postSurveyGroup = async (
  surveyId: string,
  payload: PostSurveyGroupRequest,
): Promise<ApiResponse<IdDto>> => {
  const response = await fetchData(
    `/dietitian/surveys/${surveyId}/groups`,
    APIMethods.POST,
    payload,
  );

  return postSurveyGroupResponse.validate(response);
};

export interface PostSurveyGroupRequest {
  title: string;
  description: string | null;
}

const postSurveyGroupResponse = yup.object().shape({
  data: idSchema.required(),
});
