import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "../types";
import { fetchPatientVisitsQueryKey } from "./useFetchPatientVisitsQuery";
import { updateVisitBoxValue } from "@client/visits";

interface BoxValuePayload {
  boxId: number;
  value: string;
}

export const useUpdateVisitBoxValueMutation = (
  patientId: number,
  scheduleId: number,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ value, boxId }: BoxValuePayload) =>
      updateVisitBoxValue(scheduleId, boxId, { value }),
    {
      ...options,
      onSuccess() {
        queryClient.invalidateQueries([fetchPatientVisitsQueryKey, patientId]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};
