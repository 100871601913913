import { forwardRef, useCallback, useEffect, useState } from "react";

import { round } from "lodash";
import { Input, InputProps } from "./Input";

interface NumericInputProps extends InputProps {
  precision?: number;
}

export const NumericInput = forwardRef<HTMLInputElement, NumericInputProps>(
  (
    { value, onChange, readOnly, precision = 0, ...rest }: NumericInputProps,
    ref,
  ) => {
    const [text, setText] = useState("");

    const callback = useCallback(
      (e: any) => {
        if (readOnly) {
          return;
        }

        if (e.target.value == null) return;

        const text = e.target.value.replaceAll(",", ".");

        setText(text);

        if (!isNaN(parseFloat(text)) && text >= 0 && onChange) {
          onChange(round(parseFloat(text), precision));
        }
      },
      [value, onChange, readOnly, precision],
    );

    useEffect(() => setText(`${value}`), [value]);

    const blur = useCallback(() => {
      setText(`${value}`);
    }, [value]);

    return (
      <Input
        {...rest}
        value={text}
        onChange={callback}
        onBlur={blur}
        readOnly={readOnly}
        ref={ref}
      />
    );
  },
);
