import { useMemo } from "react";
import { FormProvider } from "react-hook-form";

import { CircularProgress } from "@mui/material";

import { AutosaveOnBlurProvider } from "@components/AutosaveOnBlur";
import { GenerateImageButton } from "@components/GenerateImageButton";
import { OuterLabel } from "@components/OuterLabel";
import { useAppParams, useAppTranslation } from "@hooks";
import { usePostDietRenderMainPhotoMutation } from "@hooks/queries/diets";
import {
  useFetchCreatorDietQuery,
  useFetchDietCreatorTagsQuery,
  useUpdateDietCreatorTagsMutation,
} from "@hooks/queries/diets/creator";

import { CardStyled } from "../common";
import { TagsControlled } from "./TagsControlled";
import {
  TagsInput,
  mapTagsForm,
  mapTagsRequest,
  useTagsForm,
} from "./useTagsForm";

export const TagsTab = () => {
  const { t } = useAppTranslation();
  const { dietId } = useAppParams();
  const { diet, isLoading: isLoadingDiet } = useFetchCreatorDietQuery(dietId);
  const { data: tags, isLoading: isLoadingTags } =
    useFetchDietCreatorTagsQuery(dietId);
  const { mutate: mutateImage, isLoading: isMutatingImage } =
    usePostDietRenderMainPhotoMutation();
  const { mutate: mutateTags, isLoading: isMutatingTags } =
    useUpdateDietCreatorTagsMutation();

  const mappedTags = useMemo(() => mapTagsForm(tags), [tags]);
  const form = useTagsForm(mappedTags);

  const handleGenerate = () => mutateImage(dietId);

  const onSubmit = (values: TagsInput) =>
    mutateTags({ dietId, payload: mapTagsRequest(values) });

  if (isLoadingDiet || isLoadingTags)
    return (
      <CardStyled>
        <CircularProgress />
      </CardStyled>
    );

  return (
    <CardStyled>
      <OuterLabel label={t("diet.generate_cover")}>
        <GenerateImageButton
          img={diet?.image?.url}
          onClick={handleGenerate}
          isLoading={isMutatingImage}
        />
      </OuterLabel>

      <FormProvider {...form}>
        <AutosaveOnBlurProvider>
          <AutosaveOnBlurProvider.Watch
            isLoading={isMutatingTags}
            onSubmit={onSubmit}
          />
          <TagsControlled />
        </AutosaveOnBlurProvider>
      </FormProvider>
    </CardStyled>
  );
};
