import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useContext,
} from "react";

import _ from "lodash";

import { SchedulesDictionariesDto } from "@client";
import { useFetchSchedulesDictionariesQuery } from "@hooks/queries";

interface EventColorStyles {
  border: string | undefined;
  backgroundColor: string | undefined;
}

interface CalendarColorsContextType {
  facilitiesColors?: Record<number, string>;
  dietitiansColors?: Record<number, string>;
  schedulesDictionaries?: SchedulesDictionariesDto;
  getFacilityColor: (id?: number) => string | undefined;
  getDietitianColor: (id?: number) => string | undefined;
  getNewDietitianColor: (
    id?: number,
  ) => { color: string; backgroundColor: string } | undefined;
  getEventStyles: (
    color: string | undefined,
    outlined: boolean,
  ) => EventColorStyles;
}

export const CalendarColorsContext = createContext<CalendarColorsContextType>({
  facilitiesColors: {},
  dietitiansColors: {},
  getFacilityColor: () => undefined,
  getDietitianColor: () => undefined,
  getNewDietitianColor: () => undefined,
  getEventStyles: () => ({ border: "", backgroundColor: "" }),
});

export const useCalendarColors = () => {
  const context = useContext(CalendarColorsContext);

  if (context === undefined) {
    throw new Error(
      "useCalendarColors must be used within a CalendarColorsProvider",
    );
  }

  return context;
};

interface CalendarColorsProviderProps {
  children: ReactNode;
}

export const CalendarColorsProvider = ({
  children,
}: CalendarColorsProviderProps) => {
  const { schedulesDictionaries } = useFetchSchedulesDictionariesQuery();

  const facilitiesDict = useMemo(
    () =>
      _(schedulesDictionaries?.facilities)
        .map((facility, idx) => ({
          id: facility.id,
          color: localizationColors[idx % localizationColors.length],
        }))
        .groupBy("id")
        .mapValues(facility => facility[0].color)
        .value(),
    [schedulesDictionaries?.facilities],
  );

  const dietitiansDict = useMemo(
    () =>
      _(schedulesDictionaries?.dietitians)
        .map((dietitian, idx) => ({
          id: dietitian.id,
          color: dietitianColors[idx % localizationColors.length],
        }))
        .groupBy("id")
        .mapValues(dietitian => dietitian[0].color)
        .value(),
    [schedulesDictionaries?.dietitians],
  );

  const dietitiansColorDict = useMemo(
    () =>
      _(schedulesDictionaries?.dietitians)
        .map((dietitian, idx) => ({
          id: dietitian.id,
          colorPair: dietitianColorPairs[idx % dietitianColorPairs.length],
        }))
        .keyBy("id")
        .mapValues("colorPair")
        .value(),
    [schedulesDictionaries?.dietitians],
  );

  const getNewDietitianColor = useCallback(
    (id?: number) => {
      if (id && dietitiansColorDict[id]) return dietitiansColorDict[id];
      return undefined;
    },
    [dietitiansColorDict],
  );

  const getFacilityColor = useCallback(
    (id?: number) => {
      if (id && facilitiesDict[id]) return facilitiesDict[id];
      return;
    },
    [facilitiesDict],
  );

  const getDietitianColor = useCallback(
    (id?: number) => {
      if (id && dietitiansDict[id]) return dietitiansDict[id];
      return;
    },
    [dietitiansDict],
  );

  const getEventStyles = (
    color: string | undefined,
    outlined: boolean,
  ): EventColorStyles => {
    return {
      backgroundColor: outlined ? "#fff" : color,
      border: outlined ? (color ? `2px solid ${color}` : undefined) : undefined,
    };
  };

  const contextValue = useMemo(
    () => ({
      facilitiesColors: facilitiesDict,
      dietitiansColors: dietitiansDict,
      schedulesDictionaries,
      getFacilityColor,
      getDietitianColor,
      getNewDietitianColor,
      getEventStyles,
    }),
    [
      facilitiesDict,
      dietitiansDict,
      schedulesDictionaries,
      getFacilityColor,
      getDietitianColor,
      getNewDietitianColor,
      getEventStyles,
    ],
  );

  return (
    <CalendarColorsContext.Provider value={contextValue}>
      {children}
    </CalendarColorsContext.Provider>
  );
};

const dietitianColors = [
  "#0A59A933",
  "#A6BD0133",
  "#8AB4F333",
  "#50BEE133",
  "#71CF8433",
  "#44CEBB33",
  "#FE85C933",
  "#A6B2CE33",
  "#7A43BE33",
  "#E9BB2233",
  "#B2B2B233",
  "#00A95033",
];

const localizationColors = [
  "#996CAF",
  "#FB931F",
  "#FFCC24",
  "#F6ED09",
  "#3FBCB9",
  "#F69EBC",
  "#C1DA64",
  "#01A0C0",
  "#C7A263",
  "#FF8C8A",
  "#FFD6BD",
];

const dietitianColorPairs = [
  { color: "#7448D0", backgroundColor: "#F5F1FE" },
  { color: "#FAB569", backgroundColor: "#FFF6ED" },
  { color: "#3AB795", backgroundColor: "#EFF9F7" },
  { color: "#EC407A", backgroundColor: "#FAF0F3" },
  { color: "#5252E5", backgroundColor: "#F2F2FF" },
  { color: "#FDD835", backgroundColor: "#FFFEEF" },
  { color: "#EFB8C8", backgroundColor: "#FBF6F9" },
  { color: "#32ADE6", backgroundColor: "#ECF5FB" },
  { color: "#007AFF", backgroundColor: "#DEEBFD" },
  { color: "#D0BCFF", backgroundColor: "#F9F6FD" },
  { color: "#FF9500", backgroundColor: "#FCEFDF" },
  { color: "#00C7BE", backgroundColor: "#DEF5F5" },
  { color: "#D732A8", backgroundColor: "#F9EBF6" },
  { color: "#4866D0", backgroundColor: "#E6E9F8" },
  { color: "#FFCC00", backgroundColor: "#FCF5DF" },
  { color: "#FF3B30", backgroundColor: "#FCE4E4" },
  { color: "#6B2F45", backgroundColor: "#F0EBEE" },
  { color: "#CEC8D4", backgroundColor: "#F8F7FA" },
  { color: "#307257", backgroundColor: "#E3EBE9" },
  { color: "#A2845E", backgroundColor: "#F1EDEA" },
];
