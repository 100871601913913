import { MouseEvent, useState } from "react";

import { Menu } from "@mui/material";

import { PlusRounded } from "@assets/icons/PlusRounded";
import { QuestionType } from "@client/surveys";
import { useAppTranslation, useModal } from "@hooks";
import { usePostSurveySubgroupQuestionMutation } from "@hooks/queries/surveys";

import { AddSystemQuestionModal } from "../AddSystemQuestionModal/AddSystemQuestionModal";
import {
  LoadingButtonStyled,
  MenuItemStyled,
} from "./AddQuestionButton.styled";
import { LangDto } from "@client";

interface AddQuestionButtonProps {
  subgroupId: number;
  questionnaireLang?: LangDto;
}

export const AddQuestionButton = ({
  subgroupId,
  questionnaireLang,
}: AddQuestionButtonProps) => {
  const { t } = useAppTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClose = () => setAnchorEl(null);
  const { modalOpened, onModalOpen, onModalClose } = useModal();
  const { mutate, isLoading } = usePostSurveySubgroupQuestionMutation();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpen = () => {
    setAnchorEl(null);
    onModalOpen();
  };

  const handleCreateCustom = () => {
    mutate({
      id: subgroupId.toString(),
      payload: {
        title: t("questionnaires.questions.new", { lng: questionnaireLang }),
        type: QuestionType.TEXT,
        description: null,
        config: {
          placeholder: t("questionnaires.questions.text.placeholder", {
            lng: questionnaireLang,
          }),
          required: false,
        },
      },
    });
    setAnchorEl(null);
  };
  return (
    <>
      <LoadingButtonStyled
        variant="outlined"
        loading={isLoading}
        onClick={handleClick}
        startIcon={<PlusRounded size="w-3 h-3" className="mr-[0.375rem]" />}
      >
        {t("questionnaires.questions.add")}
      </LoadingButtonStyled>

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItemStyled onClick={handleOpen}>
          {t("questionnaires.questions.system")}
        </MenuItemStyled>
        <MenuItemStyled onClick={handleCreateCustom}>
          {t("questionnaires.questions.own")}
        </MenuItemStyled>
      </Menu>

      <AddSystemQuestionModal
        onClose={onModalClose}
        open={modalOpened}
        questionnaireLang={questionnaireLang}
      />
    </>
  );
};
