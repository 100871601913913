import { useEffect } from "react";
import { useForm } from "react-hook-form";

import * as yup from "yup";

import {
  FatTissueAnswerQuestionDto,
  UpdateSingedSurveyRequest,
} from "@client/surveys";
import { yupResolver } from "@hookform/resolvers/yup";

export const useFatTissueAnswerForm = (
  values: FatTissueAnswerFormInputs | undefined,
  required: boolean,
) => {
  const resolver = yup.object().shape({
    known: required ? yup.boolean().required() : yup.boolean().nullable(),
    fat: yup.lazy((_, { parent }) =>
      parent.known ? yup.string().required() : yup.string().nullable(),
    ),
  });

  const form = useForm<FatTissueAnswerFormInputs>({
    resolver: yupResolver(resolver),
  });

  useEffect(() => {
    if (values) form.reset(values);
  }, [values]);

  return form;
};

export const mapFatTissueAnswerForm = (
  data?: FatTissueAnswerQuestionDto,
): FatTissueAnswerFormInputs => {
  if (!data) return { fat: null, known: false };
  return {
    fat: data.fat === null ? null : String(data.fat),
    known: Boolean(data.value),
  };
};

export const mapFatTissueAnswerRequest = (
  data: FatTissueAnswerFormInputs,
  questionId: number,
): UpdateSingedSurveyRequest => {
  if (!data) return { questionId, answer: { value: null, fat: null } };
  return {
    questionId,
    answer: data.known
      ? { value: Number(data.known), fat: Number(data.fat) }
      : { value: Number(data.known), fat: null },
  };
};

export interface FatTissueAnswerFormInputs {
  fat: string | null;
  known: boolean;
}
