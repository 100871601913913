import { IconButton, Tooltip } from "@mui/material";
import { Pencil } from "@assets/icons";
import { useNavigate } from "react-router-dom";
import { useAppTranslation } from "@hooks";

type EditActionProps = {
  patientId: number;
  programId: number;
};

const EditAction = ({ patientId, programId }: EditActionProps) => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const handleOnClick = () => {
    navigate(`/patients/${patientId}/nutritional-programs/${programId}/edit`);
  };

  return (
    <Tooltip title={t("patient.program_preview.menu_actions.edit")} arrow>
      <IconButton onClick={handleOnClick}>
        <Pencil
          size=""
          fill="#727272"
          style={{ width: "32px", height: "32px" }}
        />
      </IconButton>
    </Tooltip>
  );
};

export default EditAction;
