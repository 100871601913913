import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Shortcut = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        d="M14 4C13.4469 4 13 4.44687 13 5C13 5.55313 13.4469 6 14 6H16.5844L10.2937 12.2937C9.90312 12.6844 9.90312 13.3188 10.2937 13.7094C10.6844 14.1 11.3187 14.1 11.7094 13.7094L18 7.41563V10C18 10.5531 18.4469 11 19 11C19.5531 11 20 10.5531 20 10V5C20 4.44687 19.5531 4 19 4H14ZM6.5 5C5.11875 5 4 6.11875 4 7.5V17.5C4 18.8813 5.11875 20 6.5 20H16.5C17.8813 20 19 18.8813 19 17.5V14C19 13.4469 18.5531 13 18 13C17.4469 13 17 13.4469 17 14V17.5C17 17.775 16.775 18 16.5 18H6.5C6.225 18 6 17.775 6 17.5V7.5C6 7.225 6.225 7 6.5 7H10C10.5531 7 11 6.55312 11 6C11 5.44687 10.5531 5 10 5H6.5Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
