import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Filters = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 21">
    <rect
      id="icon_18x18_buttons_odstep_12_px"
      data-name="icon 18x18 + buttons  / odstep 12 px"
      width="20"
      height="20"
      fill="#fff"
      opacity="0"
    />
    <g id="filter_1" data-name="filter 1" transform="translate(-499 -170.028)">
      <line
        id="Line_346"
        data-name="Line 346"
        x2="13.75"
        transform="translate(505.25 174.875)"
        fill="none"
        stroke="#131313"
        strokeWidth="1.5"
      />
      <line
        id="Line_347"
        data-name="Line 347"
        x2="2.5"
        transform="translate(499 174.875)"
        fill="none"
        stroke="#131313"
        strokeWidth="1.5"
      />
      <line
        id="Line_348"
        data-name="Line 348"
        x2="2.5"
        transform="translate(516.5 181.125)"
        fill="none"
        stroke="#131313"
        strokeWidth="1.5"
      />
      <line
        id="Line_349"
        data-name="Line 349"
        x2="13.75"
        transform="translate(499 181.125)"
        fill="none"
        stroke="#131313"
        strokeWidth="1.5"
      />
      <line
        id="Line_350"
        data-name="Line 350"
        x2="10"
        transform="translate(509 187.375)"
        fill="none"
        stroke="#131313"
        strokeWidth="1.5"
      />
      <line
        id="Line_351"
        data-name="Line 351"
        x2="6.25"
        transform="translate(499 187.375)"
        fill="none"
        stroke="#131313"
        strokeWidth="1.5"
      />
      <circle
        id="Ellipse_115"
        data-name="Ellipse 115"
        cx="1.875"
        cy="1.875"
        r="1.875"
        transform="translate(501.5 173)"
        fill="none"
        stroke="#131313"
        strokeWidth="1.5"
      />
      <circle
        id="Ellipse_116"
        data-name="Ellipse 116"
        cx="1.875"
        cy="1.875"
        r="1.875"
        transform="translate(512.75 179.25)"
        fill="none"
        stroke="#131313"
        strokeWidth="1.5"
      />
      <circle
        id="Ellipse_117"
        data-name="Ellipse 117"
        cx="1.875"
        cy="1.875"
        r="1.875"
        transform="translate(505.25 185.5)"
        fill="none"
        stroke="#131313"
        strokeWidth="1.5"
      />
    </g>
  </SvgIcon>
);
