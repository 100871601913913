import { useNavigate, useLocation } from "react-router-dom";
import { Fragment, SyntheticEvent, useEffect } from "react";

import { useAppTranslation, useClientParams, useModal } from "@hooks";
import { Visits } from "@typeDefinitions";
import {
  useFetchPatientVisitsQuery,
  useFetchVisitTagsQuery,
} from "@hooks/queries/visits";
import { AddVisitModal } from "@views/dietician/Diet/components/AddVisitModal";
import { Spinner } from "@components/Spinner";
import { ViewError } from "@components/ViewError";

import { PatientVisitDetails } from "./components/PatientVisitDetails/PatientVisitDetails";
import { VisitTitle } from "./components/VisitTitle/VisitTitle";
import { VisitAction } from "./components/VisitAction/VisitAction";
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledTitle,
} from "./PatientVisits.styled";
import { VisitsEmptyState } from "@views/emptyStates/VisitsEmptyState";

const areThereVisits = (visits: Visits[] = []) => !!visits?.length;

export const PatientVisits = () => {
  const { t } = useAppTranslation();
  const clientId = useClientParams();
  const navigate = useNavigate();
  const { hash } = useLocation();
  const { modalOpened, onModalClose, onModalOpen } = useModal();

  const visitIdParsed = hash ? parseInt(hash.slice(1)) : 0;

  const { patientVisits, isLoading, isError } =
    useFetchPatientVisitsQuery(clientId);

  const getVisitId = (id: number) => `visit${id}`;

  const handleVisitVisibility =
    (visitId: number) => (e: SyntheticEvent, isVisible: boolean) => {
      if (
        (e.target as Element).closest(
          "div[role='presentation'], div[role='dialog']",
        )
      )
        return;
      navigate(isVisible ? `#${visitId}` : ".");
    };

  useEffect(() => {
    if (patientVisits?.data?.length && !visitIdParsed) {
      navigate(`#${patientVisits?.data[0].id}`);
    }
  }, [patientVisits]);

  const {
    tags,
    isLoading: isTagsLoading,
    isError: isTagsError,
  } = useFetchVisitTagsQuery();

  if (isTagsLoading || (isLoading && !areThereVisits(patientVisits?.data))) {
    return <Spinner className="bg-transparent h-screen" />;
  }

  if (isError || isTagsError) {
    return <ViewError />;
  }

  if (!areThereVisits(patientVisits?.data))
    return (
      <>
        <VisitsEmptyState onClick={onModalOpen} />
        {modalOpened && (
          <AddVisitModal
            onOpen={modalOpened}
            onClose={onModalClose}
            defaultClient={clientId}
            navigateToVisit
          />
        )}
      </>
    );

  return (
    <div>
      {areThereVisits(patientVisits?.data) &&
        patientVisits?.data.map((visit: Visits, i: number) => {
          const visitOrder = patientVisits.data.length - i - 1;
          const isVisible = visitIdParsed === visit.id;

          return (
            <Fragment key={`visit_${visit.id}`}>
              <StyledAccordion
                id={getVisitId(visit.id)}
                className="mb-4"
                expanded={isVisible}
                onChange={handleVisitVisibility(visit.id)}
                TransitionProps={{
                  mountOnEnter: true,
                  unmountOnExit: true,
                }}
              >
                <StyledAccordionSummary>
                  <StyledTitle>
                    <VisitTitle
                      visitId={visit.id}
                      visitOrder={visitOrder}
                      startDate={visit.dateStart}
                    />
                    {!!visitOrder && (
                      <VisitAction patientId={clientId} visitId={visit.id} />
                    )}
                  </StyledTitle>
                </StyledAccordionSummary>

                <StyledAccordionDetails>
                  <PatientVisitDetails
                    patientId={clientId}
                    patientVisits={patientVisits?.data}
                    visit={visit}
                    visitOrder={visitOrder}
                    tags={tags}
                  />
                </StyledAccordionDetails>
              </StyledAccordion>

              {i === 0 && patientVisits?.data.length > 1 && (
                <h2 className="py-8 text-2xl">
                  {t("patient.visits.previous")}
                </h2>
              )}
            </Fragment>
          );
        })}
    </div>
  );
};
