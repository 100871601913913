import { useMemo } from "react";
import { FormProvider } from "react-hook-form";

import {
  CooperationGoalsAnswerResourceDto,
  PatientProfileTargetResourceDto,
  SystemQuestionDto,
} from "@client/surveys";
import { useUpdateSingedSurveyMutation } from "@hooks/queries/surveys";
import { useAppTranslation } from "@hooks";

import { useUpdateValidationContext } from "../../context/useUpdateValidationContext";
import { useParseUrl } from "../../useParseUrl";
import { AutosaveOnBlurWatch } from "../AutosaveOnBlurWatch";
import { BlurContextProvider, FormAutocompleteWrapper } from "../BlurContext";
import { Question } from "../Question/Question";
import {
  CooperationGoalsAnswerFormInputs,
  mapCooperationGoalsAnswerForm,
  mapCooperationGoalsAnswerRequest,
  useCooperationGoalsAnswerForm,
} from "../forms";
import { getTranslatedValue } from "../utils";
import { OtherOption } from "./OtherOption";

interface SystemQuestionCooperationGoalsProps {
  question: SystemQuestionDto;
  answer?: CooperationGoalsAnswerResourceDto;
  listIndex: number;
  totalQuestions: number;
  readOnly?: boolean;
  options?: PatientProfileTargetResourceDto[];
}

export const SystemQuestionCooperationGoals = ({
  question: {
    title,
    config: { required },
    id,
  },
  answer,
  readOnly,
  options,
  ...rest
}: SystemQuestionCooperationGoalsProps) => {
  const { t, currentLanguage } = useAppTranslation();
  const { url } = useParseUrl();
  const mappedForm = useMemo(
    () => mapCooperationGoalsAnswerForm(answer?.answer),
    [answer?.answer],
  );
  const { formState, handleSubmit, control, ...form } =
    useCooperationGoalsAnswerForm(mappedForm, required);
  const { mutate, isLoading } = useUpdateSingedSurveyMutation();

  const onSubmit = (data: CooperationGoalsAnswerFormInputs) => {
    if (url)
      mutate({ payload: mapCooperationGoalsAnswerRequest(data, id), url });
  };

  const { errors, touchedFields } = formState;
  const hasError = !!Object.values(errors).length;

  useUpdateValidationContext(id, touchedFields, handleSubmit);

  const mappedOptions = useMemo(
    () =>
      options
        ?.filter(el => el.sex === "m")
        .map(el => ({
          id: el.id.toString(),
          label: getTranslatedValue(el.translations, currentLanguage) ?? "",
        })) ?? [],
    [options, currentLanguage, getTranslatedValue],
  );

  return (
    <FormProvider
      formState={formState}
      handleSubmit={handleSubmit}
      control={control}
      {...form}
    >
      <BlurContextProvider>
        <Question
          {...rest}
          title={title}
          description={null}
          required={required}
          hasError={hasError}
          readOnly={readOnly}
        >
          <FormAutocompleteWrapper
            blurOnSelect
            control={control}
            name="id"
            id="id"
            options={mappedOptions}
            size="small"
            readOnly={readOnly}
            placeholder={t(
              "questionnaires.questions.system_cooperation_goals.placeholder",
            )}
            hideHelperText
            InputProps={{
              readOnly,
              error: !readOnly && hasError,
            }}
          />
          <OtherOption readOnly={readOnly} />
        </Question>

        <AutosaveOnBlurWatch onSubmit={onSubmit} isLoading={isLoading} />
      </BlurContextProvider>
    </FormProvider>
  );
};
