import {
  FieldValues,
  Path,
  useController,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { OptionIf } from "../FiltersBuilder";
import {
  FilterButton,
  Label,
  OptionsWrapper,
} from "./MultiSelectFilter.styled";

export interface SingleSelectFilterProps<T extends FieldValues> {
  label: string;
  options: OptionIf[];
  name?: Path<T>;
}

export const SingleSelectFilter = <T extends FieldValues>({
  label,
  options,
  name,
}: SingleSelectFilterProps<T>) => {
  const { control } = useFormContext();
  const defaultName = "tags";
  const {
    field: { value, onChange },
  } = useController({ control, name: name ?? defaultName });
  const selected = useWatch({ name: name ?? defaultName });
  const toggleSelect = (id: string) => {
    if (selected === id) return onChange(undefined);
    return onChange(id);
  };

  return (
    <div className="grid gap-[4px]">
      <Label>{label}</Label>

      <OptionsWrapper>
        {options.map(o => (
          <FilterButton
            key={o.id}
            variant="outlined"
            selected={!!o.id && selected?.includes(o.id)}
            onClick={() => toggleSelect(o.id ?? "")}
          >
            {o.label}
          </FilterButton>
        ))}
      </OptionsWrapper>
    </div>
  );
};
