import React, { ChangeEvent, MouseEvent, FC, useState } from "react";
import { CircularProgress, Radio } from "@mui/material";
import {
  PdfTemplatePickerItemControlStyled,
  PdfTemplatePickerItemDeleteButtonStyled,
  PdfTemplatePickerItemImageStyled,
  PdfTemplatePickerItemStyled,
} from "@Pdf/components/PdfTemplatePicker/PdfTemplatePickerItem.styled";
import { TrashNew } from "@assets/icons";
import { toast } from "react-toastify";
import { useDeletePdfImageMutation } from "@Pdf/hooks/query/useDeletePdfImagesQuery";
import { useAppTranslation } from "@hooks";

export type TemplateType = {
  _id?: number;
  id: string;
  name?: string;
  url: string;
  delete: boolean;
};

type PropsType = {
  _id?: number;
  value: string;
  checked: boolean;
  deletable?: boolean;
  template: TemplateType;
  onChange: (value: string) => void;
};

const PdfTemplatePickerItem: FC<PropsType> = ({
  _id,
  value,
  checked,
  template,
  deletable,
  onChange,
}) => {
  const { t } = useAppTranslation();
  const [submitting, setSubmitting] = useState(false);
  const { mutate } = useDeletePdfImageMutation({
    onSuccess: async () => {
      toast.success(t("pdf.delete_image_success"));
      setSubmitting(false);
    },
    onError: () => {
      toast.error(t("pdf.delete_image_error"));
      setSubmitting(false);
    },
  });

  const onClickDelete = (event: MouseEvent<HTMLButtonElement>) => {
    if (!_id) return;
    event.stopPropagation();
    event.preventDefault();

    mutate(_id);
  };
  const handleChange = (event: ChangeEvent) => {
    onChange((event.target as HTMLInputElement).value);
  };
  const onClickChange = () => {
    onChange(value);
  };

  return (
    <PdfTemplatePickerItemStyled onClick={onClickChange}>
      {submitting && (
        <div className="grid place-items-center flex-1">
          <CircularProgress />
        </div>
      )}
      {!submitting && (
        <>
          {deletable && (
            <PdfTemplatePickerItemDeleteButtonStyled
              color={"background"}
              onClickEvent={onClickDelete}
            >
              <TrashNew className="text-primary" />
            </PdfTemplatePickerItemDeleteButtonStyled>
          )}
          <PdfTemplatePickerItemImageStyled
            src={template.url}
            alt={template.name}
          />
          <PdfTemplatePickerItemControlStyled>
            <Radio checked={checked} value={value} onChange={handleChange} />
            {template.name}
          </PdfTemplatePickerItemControlStyled>
        </>
      )}
    </PdfTemplatePickerItemStyled>
  );
};

export default PdfTemplatePickerItem;
