import { useCallback, useMemo } from "react";
import { Path, useController, useFormContext, useWatch } from "react-hook-form";

import { round } from "lodash";

import { Calculator } from "@assets/icons/DesignSystem";

import { IconButtonStyled } from "./MacroComponent.styled";
import { MacroSliderComponent } from "./MacroSliderComponent";
import {
  Unit,
  calculateSliderValue,
  calculateValueFromSlider,
  displayValueFactory,
  percentageMax,
  percentageMin,
  sumMacros,
  valueChangeHandler,
} from "./macrosUtils";
import { MacrosFormInput } from "./useMacrosForm";
import { useAutosaveOnBlurContext } from "@components/AutosaveOnBlur";

interface MacroComponentProps {
  label: string;
  name: Path<MacrosFormInput>;
  unit: Unit;
  multiplier: number;
  warning?: boolean;
}

export const MacroComponent = ({
  label,
  name,
  unit,
  multiplier,
  warning,
}: MacroComponentProps) => {
  const { onBlur, onFocus } = useAutosaveOnBlurContext();
  const { control } = useFormContext<MacrosFormInput>();

  const {
    field: { value, onChange },
  } = useController({ control, name });

  const protein = useWatch({ control, name: "protein" });
  const energy = useWatch({ control, name: "kcal" });
  const fats = useWatch({ control, name: "fat" });
  const carbs = useWatch({ control, name: "carbs" });

  const onCalc = useCallback(() => {
    const calculateAutoValue = () => {
      const sum = sumMacros(fats, carbs, protein);
      const autoValue = value + (energy - sum) / multiplier;
      return round(Math.max(autoValue, 0));
    };

    return onChange(calculateAutoValue());
  }, [onChange, fats, carbs, protein, value, energy, multiplier]);

  const sliderValue = useMemo(
    () => calculateSliderValue(energy, value, multiplier),
    [energy, value, multiplier],
  );
  const displayValue = useMemo(
    () => displayValueFactory(unit, value, sliderValue),
    [unit, value, sliderValue],
  );

  const button = (
    <IconButtonStyled
      color="primary"
      size="small"
      onClick={onCalc}
      onFocus={onFocus}
      onMouseLeave={onBlur}
      onBlur={onBlur}
    >
      <Calculator size="w-[16px] h-[16px]" />
    </IconButtonStyled>
  );

  return (
    <div className="flex-1">
      <MacroSliderComponent
        name={name}
        label={label}
        unit={unit}
        control={control}
        min={percentageMin}
        max={percentageMax}
        transformValue={value =>
          valueChangeHandler(`${value}`, unit, multiplier, energy)
        }
        transformSliderValue={value =>
          calculateValueFromSlider(energy, value, multiplier)
        }
        button={button}
        sliderValue={sliderValue}
        displayValue={displayValue}
        percent
        step={MACRO_SLIDER_STEP}
        warning={warning}
      />
    </div>
  );
};

const MACRO_SLIDER_STEP = 1;
