import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Egg = (props: SvgIconProps) => {
  return (
    <SvgIcon width="12" height="14" viewBox="0 0 12 14">
      <path
        d="M6.00005 13.5C3.12922 13.5 0.800049 10.7375 0.800049 7.86667C0.800049 4.83333 2.53338 0.5 6.00005 0.5C9.46671 0.5 11.2 4.83333 11.2 7.86667C11.2 10.7375 8.87088 13.5 6.00005 13.5ZM4.99255 3.69583C5.16859 3.53333 5.18213 3.25979 5.01963 3.08375C4.85713 2.90771 4.58359 2.89417 4.40755 3.05667C3.76026 3.64708 3.29442 4.48396 2.99109 5.33708C2.68776 6.19021 2.53338 7.10021 2.53338 7.86667C2.53338 8.105 2.72838 8.3 2.96672 8.3C3.20505 8.3 3.40005 8.105 3.40005 7.86667C3.40005 7.20312 3.53547 6.39333 3.80901 5.62417C4.08255 4.85229 4.48609 4.15896 4.99255 3.69583Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
