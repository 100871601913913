import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "../types";
import {
  UpdateSingedSurveyRequest,
  updateSingedSurveys,
} from "@client/surveys/updateSingedSurveys";
import { fetchSingedSurveysQueryKey } from "./useFetchSingedSurveysQuery";
import { ApiResponse } from "@typeDefinitions";
import {
  BirthdayAnswerResourceDto,
  CheckboxAnswerResourceDto,
  CooperationGoalsAnswerResourceDto,
  DateAnswerResourceDto,
  DateTimeAnswerResourceDto,
  FetchSingedSurveysResponse,
  MeasurementAnswerResourceDto,
  PregnantWomanAnswerResourceDto,
  RadioAnswerResourceDto,
  SelectAnswerResourceDto,
  SexAnswerResourceDto,
  SwitchAnswerResourceDto,
  TableAnswerResourceDto,
  TextAnswerResourceDto,
  TextAreaAnswerResourceDto,
  TimeAnswerResourceDto,
  UserFatTissueAnswerResourceDto,
  UserHeightAnswerResourceDto,
  UserPreferencesFoodUnlikedAnswerResourceDto,
  UserTargetBodyWeightAnswerResourceDto,
  UserWeightAnswerResourceDto,
} from "@client/surveys";

export const useUpdateSingedSurveyMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    UpdateSingedSurveyMutationPayload,
    MutationContextIf
  >(({ url, payload }) => updateSingedSurveys(url, payload), {
    onMutate: async variables => {
      await queryClient.cancelQueries([
        fetchSingedSurveysQueryKey,
        variables.url,
      ]);
      const previousData = queryClient.getQueryData<
        ApiResponse<FetchSingedSurveysResponse>
      >([fetchSingedSurveysQueryKey, variables.url]);

      const answerExists = previousData?.data.answers.some(
        answer => answer.question.id === variables.payload.questionId,
      );

      if (!answerExists) return { previousData, invalidate: true };

      queryClient.setQueryData<ApiResponse<FetchSingedSurveysResponse>>(
        [fetchSingedSurveysQueryKey, variables.url],
        oldData => {
          if (!oldData) return previousData;

          const updatedAnswers = oldData.data.answers.map(answer => {
            if (answer.question.id === variables.payload.questionId) {
              return { ...answer, ...variables.payload } as
                | TextAnswerResourceDto
                | TextAreaAnswerResourceDto
                | CheckboxAnswerResourceDto
                | RadioAnswerResourceDto
                | SwitchAnswerResourceDto
                | DateAnswerResourceDto
                | TimeAnswerResourceDto
                | DateTimeAnswerResourceDto
                | SelectAnswerResourceDto
                | TableAnswerResourceDto
                | UserFatTissueAnswerResourceDto
                | UserWeightAnswerResourceDto
                | UserHeightAnswerResourceDto
                | MeasurementAnswerResourceDto
                | BirthdayAnswerResourceDto
                | CooperationGoalsAnswerResourceDto
                | UserTargetBodyWeightAnswerResourceDto
                | PregnantWomanAnswerResourceDto
                | SexAnswerResourceDto
                | UserPreferencesFoodUnlikedAnswerResourceDto;
            }
            return answer;
          });

          return {
            ...oldData,
            data: {
              ...oldData.data,
              answers: updatedAnswers,
            },
          };
        },
      );
    },
    onSettled: (d, e, variables, context) => {
      if (context?.invalidate) {
        queryClient.invalidateQueries([
          fetchSingedSurveysQueryKey,
          variables.url,
        ]);
      }
    },
    onError: (_, variables) => {
      queryClient.invalidateQueries([
        fetchSingedSurveysQueryKey,
        variables.url,
      ]);
    },
    ...options,
  });
};

interface UpdateSingedSurveyMutationPayload {
  url: string;
  payload: UpdateSingedSurveyRequest;
}

interface MutationContextIf {
  previousData?: ApiResponse<FetchSingedSurveysResponse>;
  invalidate?: boolean;
}
