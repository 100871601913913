import { fetchData } from "@utils/api";
import { APIMethods, UserTypes } from "@typeDefinitions";

export const register = async (payload: RegisterRequest) => {
  return await fetchData("/register", APIMethods.POST, payload);
};

export interface RegisterRequest {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  passwordConfirmation: string;
  clinicName: string;
  policy: boolean;
  consent: boolean;
  registerAs: UserTypes;
}
