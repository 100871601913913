import { useAppTranslation } from "@hooks";
import { CountLabelStyled } from "./CountLabel.styled";

interface CountLabelProps {
  count: number;
  page: number;
  perPage: number;
}
export const CountLabel = ({ count, page, perPage }: CountLabelProps) => {
  const { t } = useAppTranslation();
  const mapElementsCountLabel = () => {
    const start = Math.min(count, 1 + page * perPage);
    const end = Math.min(count, (page + 1) * perPage);

    return `${start}-${end}`;
  };

  return (
    <CountLabelStyled>
      {mapElementsCountLabel()} {t("common.of")} {count}
    </CountLabelStyled>
  );
};
