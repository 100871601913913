import { SvgIcon, SvgIconProps } from "@mui/material";

export const Home = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M3.90002 9.3L12 3L20.1 9.3V19.2C20.1 19.6774 19.9104 20.1352 19.5728 20.4728C19.2353 20.8104 18.7774 21 18.3 21H5.70002C5.22263 21 4.7648 20.8104 4.42723 20.4728C4.08967 20.1352 3.90002 19.6774 3.90002 19.2V9.3Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M9.30005 21V12H14.7V21"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
