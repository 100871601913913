import { useEffect, useRef, useState } from "react";
import Talk from "talkjs";

interface UseInboxProps {
  session?: Talk.Session;
  theme?: string;
}
export const useInbox = ({ session, theme = "default" }: UseInboxProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [inbox, setInbox] = useState<Talk.Inbox>();
  const inboxRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!isLoaded) Talk.ready.then(() => setIsLoaded(true));

    if (isLoaded && session) {
      const inbox = session.createInbox({ theme });
      setInbox(inbox);
    }
  }, [isLoaded, session]);

  useEffect(() => {
    if (isLoaded && inbox && inboxRef.current) {
      inbox.mount(inboxRef.current);
    }
  }, [isLoaded, inbox, inboxRef.current]);

  return {
    inbox,
    inboxRef,
  };
};
