import {
  CheckedMonitoring,
  UncheckedMonitoring,
} from "@assets/icons/Monitoring";
import { IconButton, Tooltip } from "@mui/material";
import { useChangeSharedProgramMutation } from "@hooks/queries/client/program/useChangeSharedProgramMutation";
import { useAppTranslation } from "@hooks";
import { MouseEventHandler } from "react";
import { ConfirmationWrapper } from "@components/ConfirmationWrapper";

type SharedColumnProps = {
  program: {
    id: number;
    shared: boolean;
  };
  patientId: number;
};
const SharedColumn = ({ program, patientId }: SharedColumnProps) => {
  const { t } = useAppTranslation();
  const { mutate, isLoading } = useChangeSharedProgramMutation({});

  const handleOnClick: MouseEventHandler<HTMLAnchorElement> | undefined = e => {
    if (e) {
      e.stopPropagation();
    }
    handleMutate();
  };

  const handleMutate = () => {
    mutate({
      programId: program.id,
      patientId: patientId,
      payload: {
        shared: !program.shared,
      },
    });
  };

  return (
    <>
      {program.shared ? (
        <ConfirmationWrapper
          onConfirm={handleMutate}
          confirmationText={t(
            "patient.programs_grid.row.shared.confirmation_off_question",
          )}
          submitText={t(
            "patient.programs_grid.row.shared.confirmation_off_answer",
          )}
        >
          <IconButton href="">
            <CheckedMonitoring fill="#3AB795" size="" />
          </IconButton>
        </ConfirmationWrapper>
      ) : (
        <ConfirmationWrapper
          onConfirm={handleMutate}
          confirmationText={t(
            "patient.programs_grid.row.shared.confirmation_on_question",
          )}
          submitText={t(
            "patient.programs_grid.row.shared.confirmation_on_answer",
          )}
        >
          <Tooltip
            title={t("patient.programs_grid.row.shared.tooltip")}
            arrow
            placement="top"
          >
            <IconButton href="">
              <UncheckedMonitoring fill="#FFFFFF" size="" />
            </IconButton>
          </Tooltip>
        </ConfirmationWrapper>
      )}
    </>
  );
};

export default SharedColumn;
