import { useNutrients } from "@hooks";

import { NutrientName, NutrientRowStyled } from "./NutrientRow.styled";

export interface NutrientRowProps {
  id: number;
  value?: number;
  target?: number;
}
export const NutrientRow = ({ id, value, target }: NutrientRowProps) => {
  const { nutrientDict } = useNutrients();
  const nutrient = nutrientDict.get(id);

  return (
    <NutrientRowStyled>
      <NutrientName>{nutrient?.name}</NutrientName>
      <NutrientName>
        {value ?? "-"} {target !== undefined && `/ ${target}`} {nutrient?.unit}
      </NutrientName>
    </NutrientRowStyled>
  );
};
