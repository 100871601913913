import * as yup from "yup";

import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";

import { AddressDto, addressSchema, PhoneDto, phoneSchema } from "../common";

export const fetchClinicBillingData = async (): Promise<
  ApiResponse<ClinicBillingDataDto>
> => {
  const response = await fetchData(
    "/dietitian/clinic/billing-data",
    APIMethods.GET,
  );
  await fetchClinicBillingDataSchema.validate(response);

  return response;
};

export interface ClinicBillingDataDto {
  id: number;
  name: string | null;
  email: string | null;
  phone: PhoneDto;
  address: AddressDto;
  vatId: string | null;
}

const clinicBillingDataSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().nullable().default(null),
  email: yup.string().nullable().default(null),
  phone: phoneSchema.required(),
  address: addressSchema.required(),
  vatId: yup.string().nullable().default(null),
});

const fetchClinicBillingDataSchema = yup.object().shape({
  data: clinicBillingDataSchema.required(),
});
