import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Eraser = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16" height="14" viewBox="0 0 16 14" {...props}>
      <path
        d="M7.98076 1.05241L0.693119 7.92584C-0.0878106 8.66238 -0.0878106 9.85558 0.693119 10.5921L3.19209 12.9491C3.56694 13.3026 4.07611 13.5 4.60714 13.5H7.89642H8.19005H14.8935C15.4464 13.5 15.8931 13.0787 15.8931 12.5572C15.8931 12.0358 15.4464 11.6144 14.8935 11.6144H11.017L15.0997 7.76674C15.8806 7.0302 15.8806 5.837 15.0997 5.10045L10.8108 1.05241C10.0299 0.315864 8.76481 0.315864 7.98388 1.05241H7.98076ZM8.19005 11.6174H7.89642H4.60402L2.10504 9.26045L6.00032 5.58657L10.2923 9.63462L8.19005 11.6174Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
