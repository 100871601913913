import { FormControlLabel, RadioGroup, styled } from "@mui/material";

export const Title = styled("span")`
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const RadioGroupStyled = styled(RadioGroup)`
  gap: 0.75rem;
`;

export const FormControlLabelStyled = styled(FormControlLabel)`
  height: 1.5rem;
`;
