import { useAppTranslation } from "@hooks";
import { Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { FormTinyMceEditor } from "@components/FormTinyMceEditor";
import {
  StyledTypography,
  TextFieldWrapper,
} from "./RecipeMethodDescription.styled";
import { FormMultilangDescriptionEditor } from "@components/FormMultilangDescriptionEditor";
import { LanguagesSlugs } from "@typeDefinitions";

interface RecipeMethodDescriptionProps {
  isPl: boolean;
  classNames?: string;
}

export const RecipeMethodDescription = ({
  isPl,
  classNames,
}: RecipeMethodDescriptionProps) => {
  const { control } = useFormContext();
  const { t } = useAppTranslation();
  const placeholder = `1.\n2.\n3.\n4.\n5.`;

  const hiddenTinyMce = true;

  return (
    <div className="grid gap-4">
      <Typography variant="h6">{t("recipes.preparation")}</Typography>
      <TextFieldWrapper>
        {!hiddenTinyMce && (
          <>
            <StyledTypography variant="subtitle2">
              {t("recipes.preparation")}
            </StyledTypography>
            <FormTinyMceEditor
              inline
              outlined
              placeholder={placeholder}
              name={isPl ? "descriptionPl" : "descriptionEn"}
              key={isPl ? "descriptionPl" : "descriptionEn"}
              control={control}
              toolbar="bold italic underline | numlist bullist"
            />
          </>
        )}
        {hiddenTinyMce && (
          <div className={classNames ?? "w-1/2"}>
            <FormMultilangDescriptionEditor
              langOverride={isPl ? LanguagesSlugs.PL : LanguagesSlugs.EN}
              control={control}
              namePl="descriptionPl"
              nameEn="descriptionEn"
              recipeNote
              label={false}
            />
          </div>
        )}
      </TextFieldWrapper>
    </div>
  );
};
