import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const DoubleArrowMeasureMonitoring = (props: SvgIconProps) => (
  <SvgIcon width="16" height="9" viewBox="0 0 16 9" {...props}>
    <path
      d="M12.7076 8.41035L15.7076 5.41035C16.0982 5.01972 16.0982 4.38535 15.7076 3.99472L12.7076 0.99472C12.317 0.604095 11.6826 0.604095 11.292 0.99472C10.9014 1.38535 10.9014 2.01972 11.292 2.41035L12.5857 3.7041H3.41387L4.70762 2.41035C5.09824 2.01972 5.09824 1.38535 4.70762 0.99472C4.31699 0.604095 3.68262 0.604095 3.29199 0.99472L0.291992 3.99472C-0.0986328 4.38535 -0.0986328 5.01972 0.291992 5.41035L3.29199 8.41035C3.68262 8.80097 4.31699 8.80097 4.70762 8.41035C5.09824 8.01972 5.09824 7.38534 4.70762 6.99472L3.41699 5.7041H12.5889L11.2951 6.99785C10.9045 7.38847 10.9045 8.02285 11.2951 8.41347C11.6857 8.8041 12.3201 8.8041 12.7107 8.41347L12.7076 8.41035Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
