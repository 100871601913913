import { CSSProperties, ReactElement, ReactNode } from "react";

import classNames from "classnames";

export type SvgIconProps = {
  xmlns?: string;
  id?: string;
  fill?: string;
  width?: string;
  height?: string;
  size?: string;
  viewBox?: string;
  style?: CSSProperties;
  className?: string;
  children?: ReactNode;
};
export const SvgIcon = (props: SvgIconProps): ReactElement => {
  const {
    xmlns,
    id,
    fill,
    width,
    height,
    viewBox,
    style,
    children,
    className,
    size = "w-em h-em",
    ...otherProps
  } = props;

  return (
    <svg
      viewBox={viewBox}
      className={classNames("fill-current inline-block", size, className)}
      style={style}
      width={width}
      height={height}
      fill={fill}
      id={id}
      xmlns={xmlns}
      {...otherProps}
    >
      {children}
    </svg>
  );
};
