import { Scales } from "@assets/icons";
import { IconButton, styled, Typography } from "@mui/material";

export const SectionTitle = styled(Typography)`
  font-weight: 600;
`;

export const ClientList = styled("ul")`
  display: grid;
  grid-template-columns: unset;
  gap: 1.25rem;
  min-width: 10.5rem;
  font-family: ${({ theme }) => theme.fontFamily.regular};
`;

export const ClientListResponsive = styled(ClientList)`
  grid-template-columns: repeat(4, 1fr);

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.laptop}px) {
    grid-template-columns: unset;
  }
`;

export const ClientItem = styled("li")`
  display: flex;
  align-items: flex-start;
  gap: 0.875rem;

  & svg {
    margin-top: 1rem;
  }
`;

export const ClientListWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
`;

export const ClientListWrapperResponsive = styled("div")`
  display: grid;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.laptop}px) {
    display: flex;
    justify-content: space-between;
  }
`;

export const SubtitleDescr = styled("div")`
  max-width: 40rem;
  min-width: 1rem;
  width: 100%;
`;

export const StyledSubtitle = styled("p")`
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0;
  align-self: flex-start;
`;

export const StyledScales = styled(Scales)`
  margin-top: 1.25rem !important;
`;
