import { styled, Button } from "@mui/material";

export const SubmenuWrapper = styled("div")`
  display: flex;
  gap: 10rem;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  background-color: ${({ theme }) => theme.colors.whiteBackground};
  border-bottom-width: 1px;
  border-color: ${({ theme }) => theme.colors.tableBorder};
  padding: 0.5rem 0 0.5rem 1.5rem;
`;

export const MeanSeaction = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0 0;
`;

export const SetValueSection = styled("div")`
  display: grid;
  gap: 0.5rem;
  opacity: 0.5;
  padding: 0.5rem 0;
`;
