import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Search = (props: SvgIconProps) => (
  <SvgIcon {...props} fill={props.fill} viewBox="0 0 20 20">
    <path
      id="Search"
      d="M17.78,16.746l-3.094-3.025h0l-.073-.11a.729.729,0,0,0-1.023,0h0a7.3,7.3,0,0,1-9.467.306A6.911,6.911,0,0,1,2.588,4.779a7.268,7.268,0,0,1,9.069-2.671,6.967,6.967,0,0,1,3.883,8.447.7.7,0,0,0,.164.694.739.739,0,0,0,.7.209.722.722,0,0,0,.533-.486h0A8.4,8.4,0,0,0,12.435.847,8.779,8.779,0,0,0,1.52,3.677,8.33,8.33,0,0,0,2.744,14.652a8.81,8.81,0,0,0,11.282.464l2.739,2.678a.737.737,0,0,0,1.023,0,.705.705,0,0,0,0-1.008h0Z"
      transform="translate(1 1)"
      fill={props.fill ? props.fill : "currentColor"}
    />
  </SvgIcon>
);
