import { FormTextFieldClearable } from "@components/FormTextField";
import { IconButton, styled } from "@mui/material";

export const IconButtonStyled = styled(IconButton)`
  &.MuiButtonBase-root {
    background: ${({ theme }) => theme.colors.neutral.light[100]};
    border: 1px solid ${({ theme }) => theme.palette.primary.medium};
    height: 40px;
    min-width: 40px;
    width: fit-content;
    gap: 4px;
  }
`;

export const FormTextFieldClearableStyled = styled(FormTextFieldClearable)`
  min-width: 320px;

  & .MuiInputBase-root {
    height: 100%;
  }
`;
