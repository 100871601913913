import { memo } from "react";
import { FormProvider } from "react-hook-form";

import { InputAdornment } from "@mui/material";

import { Loupe } from "@assets/icons";
import { ApiError } from "@components/ApiError";
import {
  DEFAULT_DIETS_MACROS_RANGE,
  DietsFilters,
  DietsFiltersFormInput,
  useDietsFiltersForm,
} from "@components/DietsFilters";
import { DietListElement, ListHeader } from "@components/ListModules";
import { Pagination } from "@components/Pagination";
import { FormTextFieldClearableStyled } from "@components/RecipesFiltersNew/RecipesFilters.styled";
import { Spinner } from "@components/Spinner";
import { useAppTranslation } from "@hooks";
import { useSearchDietsQueryNew } from "@hooks/queries";
import { clearFormInputs } from "@utils";
import { DietDetails } from "@views/dietician/Diets/components";
import { FiltersNoResults } from "@views/emptyStates/FiltersNoResults";

import { ThemeProviderWrapperNew } from "themeNew";
import { WrapperFixed } from "./DayDietSelect.styled";
import { AutosaveWatchComponent } from "@components/AutosaveWatchComponent";
import { omit } from "lodash";

export const DietSelect = memo(({ onDietSelect }: DietSelectProps) => {
  const { t } = useAppTranslation();

  const form = useDietsFiltersForm();

  const {
    diets,
    links,
    handlePaginationClick,
    submit,
    isLoading,
    isError,
    isSuccess,
    isFetching,
    meta,
  } = useSearchDietsQueryNew({ keepPreviousData: true });

  const handleClearInputs = () => clearFormInputs(form.reset, submit);

  const handleSubmitForm = (data: DietsFiltersFormInput) => {
    const nutrientsWithNonDefaultValues = Object.entries(
      data.nutrients || {},
    ).reduce((acc, [key, value]) => {
      const defaultValue = DEFAULT_DIETS_MACROS_RANGE[key];
      if (
        !defaultValue ||
        defaultValue[0] !== value[0] ||
        defaultValue[1] !== value[1]
      ) {
        acc[key] = value;
      }
      return acc;
    }, {} as { [id: string]: [number, number] });

    submit({
      ...omit(data, ["nutrients"]),
      page: undefined,
      nutrients: nutrientsWithNonDefaultValues,
    });
  };

  return (
    <>
      <FormProvider {...form}>
        <ThemeProviderWrapperNew>
          <div className="flex gap-[4px] pb-[8px]">
            <FormTextFieldClearableStyled
              name="query"
              size="small"
              placeholder={t("diets.searchDiet")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Loupe />
                  </InputAdornment>
                ),
              }}
            />
            <WrapperFixed>
              <DietsFilters unshiftTabs total={meta?.total} />
            </WrapperFixed>
          </div>
        </ThemeProviderWrapperNew>

        <AutosaveWatchComponent
          isLoading={isFetching}
          onSubmit={handleSubmitForm}
          isDirtyCondition={false}
        />
      </FormProvider>

      {isSuccess && (
        <ListHeader name={t("recipes.recipe_name")} body={t("common.tags")} />
      )}

      {isLoading && <Spinner className={"py-5"} />}

      {isError && <ApiError />}

      {!diets?.length && !isLoading && (
        <div className="py-8">
          <FiltersNoResults onClick={handleClearInputs} />
        </div>
      )}

      {diets?.map(diet => (
        <DietListElement
          key={diet.id}
          diet={{
            ...diet,
            nameEn: diet.nameEn ?? undefined,
          }}
          onClick={() => onDietSelect(diet.id)}
        >
          <DietDetails dietId={diet.id} />
        </DietListElement>
      ))}

      {diets && diets.length > 0 && links && (
        <Pagination links={links} onClick={handlePaginationClick} />
      )}
    </>
  );
});

interface DietSelectProps {
  onDietSelect: (id: number) => any;
}
