import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  DietMealItemCreatorResponseDto,
  DietMealListItemCreatorDto,
  deleteCreatorDietMealItems,
} from "@client/diets/creator";
import { MutationOptions } from "@hooks/queries/types";
import { searchDietsQueryKeyNew } from "@hooks/queries/useSearchDietsQueryNew";
import { ApiResponse } from "@typeDefinitions";
import { clearItemsCount, deleteAllMealItems } from "./optimisticUpdateUtils";
import { useCreatorVersion } from "./useCreatorVersion";
import { fetchCreatorDietMealsItemsQuery } from "./useFetchCreatorDietMealsItemsQuery";
import { fetchCreatorDietMealsQueryKey } from "./useFetchCreatorDietMealsQuery";
import { fetchCreatorDietNutrientsQueryKey } from "./useFetchCreatorDietNutrientsQuery";
import { fetchCreatorDietQueryKey } from "./useFetchCreatorDietQuery";
import { fetchDietRecipeUsedQueryKey } from "../useFetchDietRecipeUsedQuery";
import { fetchCreatorDietMealNutrientsQueryKey } from "./useFetchCreatorDietMealNutrientsQuery";

export const useDeleteCreatorDietMealItemsMutation = (
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();
  const { version } = useCreatorVersion();

  return useMutation<
    unknown,
    unknown,
    UseDeleteCreatorDietMealItemsMutationIf,
    MutationContextIf
  >(
    ({ dietId, mealId }) => deleteCreatorDietMealItems(dietId, mealId, version),
    {
      ...options,
      onSuccess: (d, v) => {
        options?.onSuccess && options.onSuccess(d, v);
        queryClient.invalidateQueries([fetchCreatorDietQueryKey, v.dietId]);
        queryClient.invalidateQueries([searchDietsQueryKeyNew]);
        queryClient.invalidateQueries([
          fetchCreatorDietNutrientsQueryKey,
          v.dietId,
        ]);
        queryClient.invalidateQueries([fetchDietRecipeUsedQueryKey, v.dietId]);
        queryClient.invalidateQueries([
          fetchCreatorDietMealNutrientsQueryKey,
          v.dietId,
        ]);
      },
      onMutate: async ({ dietId, mealId }) => {
        await queryClient.cancelQueries([
          fetchCreatorDietMealsItemsQuery,
          dietId,
          mealId,
        ]);
        await queryClient.cancelQueries([
          fetchCreatorDietMealsQueryKey,
          dietId,
        ]);

        const previousItemsData = queryClient.getQueryData<
          ApiResponse<DietMealItemCreatorResponseDto>
        >([fetchCreatorDietMealsItemsQuery, dietId, mealId]);

        queryClient.setQueryData<ApiResponse<DietMealItemCreatorResponseDto>>(
          [fetchCreatorDietMealsItemsQuery, dietId, mealId],
          oldData => {
            if (!oldData) return previousItemsData;

            return deleteAllMealItems(oldData);
          },
        );

        const previousMealData = queryClient.getQueryData<
          ApiResponse<DietMealListItemCreatorDto[]>
        >([fetchCreatorDietMealsQueryKey, dietId]);

        const hasMeal = previousMealData?.data.some(
          meal => meal.id.toString() === mealId,
        );

        if (!hasMeal) return { previousMealData, invalidate: true };

        queryClient.setQueryData<ApiResponse<DietMealListItemCreatorDto[]>>(
          [fetchCreatorDietMealsQueryKey, dietId],
          oldData => {
            if (!oldData) return previousMealData;

            return clearItemsCount(oldData, mealId);
          },
        );
      },
      onSettled: (d, e, v, context) => {
        if (context?.invalidate) {
          queryClient.invalidateQueries([
            fetchCreatorDietMealsQueryKey,
            v.dietId,
          ]);
        }
      },
      onError: (_, { dietId, mealId }) => {
        queryClient.invalidateQueries([fetchCreatorDietMealsQueryKey, dietId]);
        queryClient.invalidateQueries([
          fetchCreatorDietMealsItemsQuery,
          dietId,
          mealId,
        ]);
      },
    },
  );
};

interface UseDeleteCreatorDietMealItemsMutationIf {
  dietId: string;
  mealId: string;
}
interface MutationContextIf {
  previousData?: ApiResponse<DietMealListItemCreatorDto[]>;
  invalidate?: boolean;
}
