import { createContext, useEffect, useState } from "react";
import dayjs from "dayjs";

import { ScheduleViews } from "@typeDefinitions";

interface ProgramScheduleNavigationContextIf {
  programStartDate: dayjs.Dayjs;
  currentRangeStart: dayjs.Dayjs;
  currentRangeEnd: dayjs.Dayjs;
  view: ScheduleViews;
  setView: (mode: ScheduleViews) => void;
  onNextPage: () => void;
  onPrevPage: () => void;
  isNextPage: boolean;
  isPrevPage: boolean;
  navigationLabel: string;
}

export const ProgramScheduleNavigationContext =
  createContext<ProgramScheduleNavigationContextIf>({
    programStartDate: dayjs(),
    currentRangeStart: dayjs(),
    currentRangeEnd: dayjs(),
    view: ScheduleViews.WEEK,
    setView: () => null,
    onNextPage: () => null,
    onPrevPage: () => null,
    isNextPage: false,
    isPrevPage: false,
    navigationLabel: "",
  });

interface ContextProps {
  start?: dayjs.Dayjs | null;
  end?: dayjs.Dayjs | null;
  showDates: boolean;
  focusOnToday: boolean;
}

export function useProgramScheduleNavigationContext({
  start,
  end,
  showDates,
  focusOnToday,
}: ContextProps) {
  start = start?.startOf("day");
  end = end?.startOf("day");
  const today = dayjs().startOf("day");

  const [view, setView] = useState<ScheduleViews>(ScheduleViews.WEEK);
  const [curr, setCurr] = useState<dayjs.Dayjs>(
    focusOnToday ? today : start ?? today,
  );

  useEffect(() => {
    const todayIsOutOfRange = dayjs().isAfter(end) || dayjs().isBefore(start);

    if (start && (!focusOnToday || todayIsOutOfRange)) {
      setCurr(start);
    }
  }, [start?.toString()]);

  const startDate = (current: dayjs.Dayjs) => {
    return current.isAfter(start) ? current : start ?? current;
  };

  const endDate = (current: dayjs.Dayjs) => {
    const rangeEnd = current.add(view - 1, "days");
    return !rangeEnd.isBefore(end) && end ? end : rangeEnd;
  };

  const nextDate = (current: dayjs.Dayjs) => current.add(view, "days");
  const prevDate = (current: dayjs.Dayjs) => current.subtract(view, "days");

  const isNextPage = !nextDate(curr).isAfter(end);
  const isPrevPage = !prevDate(curr.add(view - 1, "days")).isBefore(start);

  const onNextPage = () => {
    if (isNextPage) {
      setCurr(current => nextDate(current));
    }
  };

  const onPrevPage = () => {
    if (isPrevPage) {
      setCurr(current => prevDate(current));
    }
  };

  const navigationLabel = showDates
    ? `${startDate(curr).format("L")} - ${endDate(curr).format("L")}`
    : `${curr.diff(start, "days") + 1} - ${
        endDate(curr).diff(start, "days") + 1
      }`;

  return {
    context: {
      programStartDate: start ?? today,
      currentRangeEnd: endDate(curr),
      currentRangeStart: startDate(curr),
      view,
      setView,
      onNextPage,
      onPrevPage,
      isNextPage,
      isPrevPage,
      navigationLabel,
    },
    queryData: {
      view,
      date: curr,
    },
  };
}
