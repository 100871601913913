import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const FlameOutlined = (props: SvgIconProps) => {
  return (
    <SvgIcon width="12" height="14" viewBox="0 0 12 14" {...props}>
      <path
        d="M8.53906 1.91992C9.47005 2.83398 10.2233 3.85807 10.7988 4.99219C11.3743 6.1263 11.6706 7.05729 11.6875 7.78516C11.6706 8.86849 11.4082 9.85026 10.9004 10.7305C10.4095 11.6107 9.73242 12.3132 8.86914 12.8379C8.02279 13.3457 7.06641 13.6081 6 13.625C4.93359 13.6081 3.97721 13.3457 3.13086 12.8379C2.26758 12.3132 1.59049 11.6107 1.09961 10.7305C0.591797 9.85026 0.329427 8.86849 0.3125 7.78516C0.329427 6.83724 0.727214 5.70312 1.50586 4.38281C2.28451 3.07943 3.30859 1.82682 4.57812 0.625C5.5599 1.53906 6.39779 2.47852 7.0918 3.44336C7.51497 2.91862 7.9974 2.41081 8.53906 1.91992ZM6 12.4062C7.26953 12.3724 8.31901 11.9154 9.14844 11.0352C9.99479 10.1719 10.4349 9.08854 10.4688 7.78516C10.4688 7.34505 10.291 6.74414 9.93555 5.98242C9.58008 5.20378 9.10612 4.44206 8.51367 3.69727C8.3444 3.86654 8.19206 4.04427 8.05664 4.23047L7.04102 5.44922L6.12695 4.1543C5.66992 3.54492 5.15365 2.94401 4.57812 2.35156C3.61328 3.40104 2.86849 4.41667 2.34375 5.39844C1.80208 6.38021 1.53125 7.17578 1.53125 7.78516C1.5651 9.08854 2.00521 10.1719 2.85156 11.0352C3.68099 11.9154 4.73047 12.3724 6 12.4062ZM8.28516 6.79492C8.36979 6.94727 8.44596 7.09961 8.51367 7.25195C8.81836 7.8444 8.91146 8.46224 8.79297 9.10547C8.65755 9.73177 8.31901 10.248 7.77734 10.6543C7.28646 10.9928 6.7194 11.1706 6.07617 11.1875C5.24674 11.1875 4.5612 10.9421 4.01953 10.4512C3.46094 9.96029 3.17318 9.27474 3.15625 8.39453C3.15625 7.98828 3.29167 7.5651 3.5625 7.125C3.83333 6.66797 4.23958 6.1263 4.78125 5.5C4.84896 5.58464 5.06055 5.85547 5.41602 6.3125C5.75456 6.7526 6.0931 7.18424 6.43164 7.60742C6.77018 8.0306 6.94792 8.25911 6.96484 8.29297L8.28516 6.79492Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
