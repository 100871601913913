import {
  CollectionProductSearchPreviewDto,
  fetchCollectionSearchPreviewProduct,
} from "@client/collections";
import { useQuery } from "@tanstack/react-query";
import { ApiResponse } from "@typeDefinitions";
import { QueryOptionsTyped } from "../types";

const fetchCollectionSearchPreviewProductQuery =
  "fetchCollectionSearchPreviewProductQuery";

export const useFetchCollectionSearchPreviewProductQuery = (
  collectionId: string,
  itemId: string,
  options?: QueryOptionsTyped<ApiResponse<CollectionProductSearchPreviewDto>>,
) => {
  const { data, ...rest } = useQuery({
    queryFn: () => fetchCollectionSearchPreviewProduct(collectionId, itemId),
    queryKey: [fetchCollectionSearchPreviewProductQuery, collectionId, itemId],
    ...options,
  });

  return {
    data: data?.data,
    ...rest,
  };
};
