import { useCallback, useMemo } from "react";
import { useController, useFormContext, useWatch } from "react-hook-form";

import { round } from "lodash";

import { Calculator } from "@assets/icons/DesignSystem";
import { useAppTranslation } from "@hooks";

import { IconButtonStyled } from "./MacroComponent.styled";
import { MacroSliderComponent } from "./MacroSliderComponent";
import { kcalMax, kcalMin, sumMacros } from "./macrosUtils";
import { MacrosFormInput } from "./useMacrosForm";
import { useAutosaveOnBlurContext } from "@components/AutosaveOnBlur";

export const EnergyComponent = () => {
  const { control } = useFormContext<MacrosFormInput>();
  const { t } = useAppTranslation();
  const { onBlur, onFocus } = useAutosaveOnBlurContext();
  const {
    field: { value, onChange },
  } = useController({ control, name: "kcal" });

  const protein = useWatch({ control, name: "protein" });
  const fats = useWatch({ control, name: "fat" });
  const carbs = useWatch({ control, name: "carbs" });

  const macrosKcalSum = useMemo(
    () => sumMacros(fats, carbs, protein),
    [fats, carbs, protein],
  );

  const onCalc = useCallback(
    () => onChange(Math.max(kcalMin, macrosKcalSum)),
    [onChange, macrosKcalSum],
  );

  const sliderValue = useMemo(
    () => round((macrosKcalSum / (value || 1)) * 100),
    [macrosKcalSum, value],
  );

  const button = (
    <IconButtonStyled
      color="primary"
      size="small"
      onClick={onCalc}
      onFocus={onFocus}
      onMouseLeave={onBlur}
      onBlur={onBlur}
    >
      <Calculator size="w-[16px] h-[16px]" />
    </IconButtonStyled>
  );

  return (
    <div className="flex-1">
      <MacroSliderComponent
        name="kcal"
        label={t("common.energy_efficiency")}
        unit="kcal"
        control={control}
        min={kcalMin}
        max={kcalMax}
        step={ENERGY_SLIDER_STEP}
        button={button}
        valueLabelFormat={
          sliderValue > MAX_PERCENTAGE
            ? `>${MAX_PERCENTAGE} %`
            : `${sliderValue} %`
        }
      />
    </div>
  );
};

const ENERGY_SLIDER_STEP = 10;
const MAX_PERCENTAGE = 500;
