import { TabPanel } from "@mui/lab";
import { styled } from "@mui/material";

export const TabPanelStyled = styled(TabPanel)`
  min-height: max-content;
  &.MuiTabPanel-root {
    padding: 16px;
  }
`;

export const PanelWrapper = styled("div")`
  display: grid;
  gap: 24px;
`;
