import * as yup from "yup";

import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { IdDto, idSchema } from "@client/common";

import {
  EmptyConfigDto,
  OptionsConfigDto,
  OptionsWithOtherConfigDto,
  QuestionType,
  RequiredConfigDto,
  SwitchConfigDto,
  SystemQuestionType,
  TableConfigDto,
  TextConfigDto,
} from "./fetchSurveyFull";

export const postSurveySubgroupQuestion = async (
  subgroupId: string,
  payload: SurveySubgroupQuestionRequest,
): Promise<ApiResponse<IdDto>> => {
  const response = await fetchData(
    `/dietitian/survey-sections/${subgroupId}/questions`,
    APIMethods.POST,
    payload,
  );

  return postSurveySubgroupQuestionSchema.validate(response);
};

const postSurveySubgroupQuestionSchema = yup.object().shape({
  data: idSchema.required(),
});

export type SurveySubgroupQuestionRequest =
  | QuestionText
  | QuestionTextArea
  | QuestionCheckbox
  | QuestionRadio
  | QuestionDate
  | QuestionSwitch
  | QuestionSelect
  | QuestionSlider
  | QuestionTable
  | SystemQuestion
  | QuestionEmpty;

interface SurveyQuestion<T> {
  type: QuestionType;
  title: string;
  description: string | null;
  config: T;
}

interface QuestionText extends SurveyQuestion<TextConfigDto> {
  type: QuestionType.TEXT;
}

interface QuestionTextArea extends SurveyQuestion<TextConfigDto> {
  type: QuestionType.TEXTAREA;
}

interface QuestionCheckbox extends SurveyQuestion<OptionsWithOtherConfigDto> {
  type: QuestionType.CHECKBOX;
}

interface QuestionRadio extends SurveyQuestion<OptionsWithOtherConfigDto> {
  type: QuestionType.RADIO;
}

interface QuestionDate extends SurveyQuestion<RequiredConfigDto> {
  type: QuestionType.DATE;
}

interface QuestionSwitch extends SurveyQuestion<SwitchConfigDto> {
  type: QuestionType.SWITCH;
}

interface QuestionSelect extends SurveyQuestion<OptionsConfigDto> {
  type: QuestionType.SELECT;
}

interface QuestionSlider extends SurveyQuestion<OptionsConfigDto> {
  type: QuestionType.SLIDER;
}

interface QuestionTable extends SurveyQuestion<TableConfigDto> {
  type: QuestionType.TABLE;
}

interface SystemQuestion extends SurveyQuestion<RequiredConfigDto> {
  type: SystemQuestionType;
}

interface QuestionEmpty extends SurveyQuestion<EmptyConfigDto> {
  type: QuestionType.EMPTY;
}
