import { Button, MenuItem, styled } from "@mui/material";

export const Text = styled("span")`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const ButtonStyled = styled(Button)`
  display: flex;
  gap: 8px;
`;
export const MenuItemStyled = styled(MenuItem)`
  display: flex;
  gap: 8px;
`;
