import { IconButton } from "@mui/material";

import { Carrot, Close } from "@assets/icons/DesignSystem";
import { useAppTranslation } from "@hooks";

import {
  Header,
  IconButtonStyled,
  ProducerText,
  ProductName,
  Title,
} from "./DrawerHeader.styled";
import { TagWithIcon } from "@components/TagWithIcon";
import { FlameOutlined } from "@assets/icons";
import { useFoodGroupTreeFetchQuery } from "@hooks/queries/dictionaries";
import { useFoodGroupTreeOptions } from "@components/FoodGroupSelect";
import { useTreeSelectTitle } from "@components/TreeSelect";

interface DrawerHeaderProps {
  onClose: () => void;
  productName: string;
  kcal: number;
  categoryId?: number;
  producer?: string | null;
}
export const DrawerHeader = ({
  onClose,
  productName,
  kcal,
  categoryId,
  producer,
}: DrawerHeaderProps) => {
  const { t } = useAppTranslation();
  const { data: foodGroupTree, isLoading } = useFoodGroupTreeFetchQuery();
  const options = useFoodGroupTreeOptions(foodGroupTree);
  const title = useTreeSelectTitle(options, categoryId?.toString());
  return (
    <Header>
      <div className="flex justify-between h-[24px]">
        <Title>{t("diet.product_sidepanel.edit_product")}</Title>

        <IconButtonStyled size="small" onClick={onClose}>
          <Close size="w-[12px] h-[12px]" />
        </IconButtonStyled>
      </div>

      <div className="grid gap-[8px]">
        <ProductName>{productName}</ProductName>
        <div className="flex gap-[16px]">
          <TagWithIcon label={`${kcal} kcal`} Icon={FlameOutlined} />
          {title && <TagWithIcon label={title} Icon={Carrot} />}
          {producer && <ProducerText>{producer}</ProducerText>}
        </div>
      </div>
    </Header>
  );
};
