import { useAppTranslation } from "@hooks";
import { useFetchBodyMeasurementsQuery } from "@hooks/queries/dictionaries";
import React, { Dispatch, useMemo } from "react";
import { Button, DialogContent, Stack } from "@mui/material";
import DialogTitle from "@components/Alloweat/Dialog/DialogTitle";
import { IconButton } from "@components/Alloweat/IconButton";
import Dialog from "@components/Alloweat/Dialog/Dialog";
import DialogActions from "@components/Alloweat/Dialog/DialogActions";
import SettingSectionFormWrapper from "@views/dietician/ClientHealth/BodyMeasurementTab/SettingsSection/Dialog/SettingSectionForm";
import BodyField from "@views/dietician/ClientHealth/BodyMeasurementTab/SettingsSection/Dialog/BodyField";
import AllBodyField from "@views/dietician/ClientHealth/BodyMeasurementTab/SettingsSection/Dialog/AllBodyField";
import { BodyMeasurementConst } from "@consts/BodyMeasurementConst";
import {
  DialogSectionItemLabel,
  DialogSectionItemLayout,
  DialogSectionLayout,
  Divider,
} from "@views/dietician/ClientHealth/ClientHealthNew.styled";
import RequiredPhotosField from "@views/dietician/ClientHealth/BodyMeasurementTab/SettingsSection/Dialog/RequiredPhotosField";
import { CloseIcon } from "@icons/index";
import { LoadingButton } from "@mui/lab";
import { useFormState } from "react-hook-form";
import { FormCancelButton, FormSubmitButton } from "@components/FormButtons";

type SettingsSectionDialogProps = {
  open: boolean;
  setOpen: Dispatch<boolean>;
};

const SettingsSectionDialog = ({
  open,
  setOpen,
}: SettingsSectionDialogProps) => {
  const { t, currentLanguage } = useAppTranslation();
  const { data: bodyMeasurements } = useFetchBodyMeasurementsQuery();

  const filteredBodyMeasurements = useMemo(() => {
    if (!bodyMeasurements) {
      return [];
    }

    return bodyMeasurements.data.filter(
      body =>
        ![
          BodyMeasurementConst.water,
          BodyMeasurementConst.bodyFatMass,
          BodyMeasurementConst.skeletalMuscleMass,
          BodyMeasurementConst.boneTissueMass,
        ].includes(body.id),
    );
  }, [bodyMeasurements]);

  return (
    <Dialog open={open} fullWidth PaperProps={{ sx: { maxWidth: "510px" } }}>
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <span>
            {t(
              "patient.health_and_goal.body_measurement_tab.section_settings_modal.title",
            )}
          </span>

          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon color="727272" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <SettingSectionFormWrapper onSuccess={() => setOpen(false)}>
        <DialogContent>
          <DialogSectionLayout>
            <DialogSectionItemLayout>
              <DialogSectionItemLabel>
                {t(
                  "patient.health_and_goal.body_measurement_tab.section_settings_modal.monitored_measurements",
                )}
              </DialogSectionItemLabel>
              <Stack direction="column" gap="8px">
                <AllBodyField bodyMeasurements={filteredBodyMeasurements} />
                {filteredBodyMeasurements.map(body => (
                  <BodyField key={body.id} body={body} />
                ))}
              </Stack>
            </DialogSectionItemLayout>
            <Divider />
            <DialogSectionItemLayout>
              <DialogSectionItemLabel>
                {t(
                  "patient.health_and_goal.body_measurement_tab.section_settings_modal.required_photos",
                )}
              </DialogSectionItemLabel>
              <RequiredPhotosField />
            </DialogSectionItemLayout>
          </DialogSectionLayout>
        </DialogContent>
        <DialogActions>
          <FormCancelButton onClick={() => setOpen(false)} />
          <FormSubmitButton />
        </DialogActions>
      </SettingSectionFormWrapper>
    </Dialog>
  );
};

export default SettingsSectionDialog;
