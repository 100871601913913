import {
  memo,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Box, Dialog } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import {
  mapUpdateClientRequest,
  UpdateClientFormInput,
  useAppTranslation,
  useUpdateClientForm,
} from "@hooks";
import {
  useFetchClientAdditionalFeaturesQuery,
  useFetchClientQuery,
  useFetchClientTargetsQuery,
  usePostClientEmailVerification,
  useUpdateClientMutation,
} from "@hooks/queries";

import { Close } from "@assets/icons";

import {
  CancelButton,
  CloseButton,
  EditClientModalWrapper,
  StyledDialogActions,
  StyledTab,
  StyledTabList,
  StyledTabPanel,
  Title,
  TitleWrapper,
} from "./EditClientInfoModal.styled";
import { getFromDict, pairOptions } from "./editClientUtils";
import { LoadingButton, TabContext } from "@mui/lab";
import { ClientInfoView } from "./components/ClientInfoView";
import { ClientContactsView } from "./components/ClientContactsView";
import { TAKEN_EMAIL_ERROR } from "@utils";

interface EditClientModalProps {
  id: number;
  open: boolean;
  initialTab?: string;
  onClose: () => void;
  onSubmitted?: (data: UpdateClientFormInput) => void;
}

export const EditClientInfoModal = memo(
  ({
    initialTab = "1",
    id,
    open,
    onClose,
    onSubmitted,
  }: EditClientModalProps) => {
    const { t } = useAppTranslation();

    const { client } = useFetchClientQuery(id);
    const { mutate, isLoading } = useUpdateClientMutation(id);
    const { additionalFeatures } = useFetchClientAdditionalFeaturesQuery();
    const { targets } = useFetchClientTargetsQuery();
    const [checked, setChecked] = useState(false);
    const { mutate: emailVerificationMutate } = usePostClientEmailVerification(
      id.toString(),
    );

    enum ClientSettingsTabs {
      INFO = "1",
      CONTACTS = "2",
    }

    const {
      control,
      handleSubmit,
      watch,
      reset,
      setValue,
      getFieldState,
      setError,
    } = useUpdateClientForm(client);
    const sex = watch("sex");
    const { isTouched } = getFieldState("sex");

    const submit = useCallback(
      (data: UpdateClientFormInput) => {
        mutate(mapUpdateClientRequest(data), {
          onSuccess: () => {
            onSubmitted?.(data);
            onClose();
          },
          onError: err => {
            if (err.response?.data.errors.email?.includes(TAKEN_EMAIL_ERROR))
              setError("email", { message: t("common.email_duplicate") });
          },
        });
        if (checked) {
          emailVerificationMutate();
          setChecked(false);
        }
      },
      [mutate, mapUpdateClientRequest, checked],
    );

    const onCloseWithReset = useCallback(() => {
      reset();
      onClose();
    }, [onClose, reset]);

    const pairedTargets = useMemo(() => pairOptions(targets), [targets]);
    const pairedAdditionalFeatures = useMemo(
      () => pairOptions(additionalFeatures),
      [additionalFeatures],
    );

    useEffect(() => {
      if (!isTouched || !pairedTargets || !pairedAdditionalFeatures) return;
      const currGoal = watch("goal");
      const currFeature = watch("additionalFeature");
      setValue(
        "additionalFeature",
        getFromDict(currFeature, pairedAdditionalFeatures),
      );
      setValue("goal", getFromDict(currGoal ?? undefined, pairedTargets));
    }, [sex]);

    useEffect(() => {
      open && setTab(initialTab);
    }, [open]);

    const [tab, setTab] = useState(initialTab);

    const handleChange = (
      event: SyntheticEvent,
      newValue: ClientSettingsTabs,
    ) => {
      setTab(newValue);
    };

    return (
      <Dialog open={open} disableEscapeKeyDown>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <EditClientModalWrapper>
            <TitleWrapper>
              <Title>{t("client_profile.info.client_information")}</Title>
              <CloseButton size="small" onClick={onCloseWithReset}>
                <Close size="w-3 h-3" />
              </CloseButton>
            </TitleWrapper>
            <TabContext value={tab}>
              <Box className="mb-2">
                <StyledTabList onChange={handleChange}>
                  <StyledTab
                    label={t("client_profile.edit.info.personal_information")}
                    value={ClientSettingsTabs.INFO}
                  />
                  <StyledTab
                    label={t("client_profile.edit.info.contact_information")}
                    value={ClientSettingsTabs.CONTACTS}
                  />
                </StyledTabList>
              </Box>
              <StyledTabPanel value={ClientSettingsTabs.INFO}>
                <ClientInfoView
                  control={control}
                  lang={client?.profile.lang}
                  gender={client?.profile.sex ?? null}
                />
              </StyledTabPanel>
              <StyledTabPanel value={ClientSettingsTabs.CONTACTS}>
                <ClientContactsView
                  key="clientInfo"
                  control={control}
                  client={client}
                />
              </StyledTabPanel>
            </TabContext>
          </EditClientModalWrapper>
          <StyledDialogActions>
            <CancelButton variant="outlined" onClick={onCloseWithReset}>
              {t("common.cancel")}
            </CancelButton>
            <LoadingButton
              variant="contained"
              loading={isLoading}
              onClick={handleSubmit(submit, e => console.log("e", e))}
            >
              {t("common.save")}
            </LoadingButton>
          </StyledDialogActions>
        </LocalizationProvider>
      </Dialog>
    );
  },
);
