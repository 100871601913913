import { Stack } from "@mui/material";
import SharedAction from "@components/PatientProgramSearchPreview/Components/ActionButtons/SharedAction";
import EditAction from "@components/PatientProgramSearchPreview/Components/ActionButtons/EditAction";
import ExportPdfAction from "@components/PatientProgramSearchPreview/Components/ActionButtons/ExportPdfAction";
import MenuAction from "@components/PatientProgramSearchPreview/Components/ActionButtons/MenuAction";
import CloseDrawerAction from "@components/PatientProgramSearchPreview/Components/ActionButtons/CloseDrawerAction";
import ActiveAction from "@components/PatientProgramSearchPreview/Components/ActionButtons/ActiveAction";
import { PatientProgramPreviewSearchResource } from "@client/resources/PatientProgramPreviewSearchResource";
import useNewMobileAppFeatureEnabled from "@features/useNewMobileAppFeatureEnabled";

type ProgramActionButtonsProps = {
  program: PatientProgramPreviewSearchResource;
  patientId: number;
};

const ProgramActionButtons = ({
  program,
  patientId,
}: ProgramActionButtonsProps) => {
  const feature = useNewMobileAppFeatureEnabled();

  return (
    <Stack direction="row" gap="4px">
      {!feature && <ActiveAction program={program} patientId={patientId} />}
      {feature && <SharedAction program={program} patientId={patientId} />}
      <EditAction patientId={patientId} programId={program.id} />
      <ExportPdfAction patientId={patientId} programId={program.id} />
      <MenuAction program={program} patientId={patientId} />
      <CloseDrawerAction />
    </Stack>
  );
};

export default ProgramActionButtons;
