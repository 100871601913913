import { Card, styled } from "@mui/material";
import {
  LARGE_DESKTOP_MAX_CONTENT_WIDTH,
  LAPTOP_MAX_CONTENT_WIDTH,
  TABLET_MAX_CONTENT_WIDTH,
} from "@utils";

export const StyledCard = styled(Card)`
  padding: 1rem;
`;

export const PatientMonitoringWrapper = styled("div")`
  display: grid;
  gap: 0.75rem;
  margin: 0 auto;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallMax}px) {
    max-width: ${TABLET_MAX_CONTENT_WIDTH};
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.medium}px) and (max-width: ${({ theme }) =>
      theme.breakpoints.values.laptopMax}px) {
    max-width: ${LAPTOP_MAX_CONTENT_WIDTH};
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.largeDesktop}px) {
    max-width: ${LARGE_DESKTOP_MAX_CONTENT_WIDTH};
  }
`;

export const MonitoringSection = styled("div")`
  display: grid;
  gap: 0.75rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.desktop}px) {
    display: flex;
    gap: 0.75rem;
    align-items: start;
  }
`;
