import * as yup from "yup";

import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { AddressDto, addressSchema, PhoneDto, phoneSchema } from "../common";

export const fetchClinicFacility = async (
  id: number,
): Promise<ApiResponse<FetchClinicFacilityResponse>> => {
  const response = await fetchData(
    `/dietitian/clinic/facilities/${id}`,
    APIMethods.GET,
  );
  fetchClinicFacilitySchema.validate(response);

  return response;
};

export interface FetchClinicFacilityResponse {
  id: number;
  name: string;
  email: string;
  phone: PhoneDto;
  address: AddressDto;
  isMain: boolean;
}

const clinicFacilitySchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  email: yup.string().required(),
  phone: phoneSchema.required(),
  address: addressSchema.required(),
  isMain: yup.boolean().required(),
});

const fetchClinicFacilitySchema = yup.object().shape({
  data: clinicFacilitySchema.required(),
});
