import { useFieldArray, useFormContext } from "react-hook-form";
import { ReactSortable } from "react-sortablejs";
import _ from "lodash";

import { Typography } from "@mui/material";

import { useAppTranslation } from "@hooks";
import { Plus } from "@assets/icons";
import { DietitianProductFormInput } from "@views/dietician/product-views/ProductForm";

import { ProductMeasureItem } from "./ProductMeasureItem";
import { AddMeasureBtn, ListWrapper } from "./ProductMeasureItem.styled";
import { detectMovedElement } from "@views/dietician/product-views/components/ProductMeasures/utils/move";

const GRAMS_MEASURE_ID = "1";

export const ProductMeasuresList = () => {
  const { t } = useAppTranslation();
  const { fields, move, remove, append } = useFieldArray<
    DietitianProductFormInput,
    "measures"
  >({ name: "measures" });

  return (
    <ListWrapper>
      <Typography variant="h6">{t("products.measure")}</Typography>
      <div className="grid gap-4">
        <ReactSortable
          animation={200}
          handle=".dragHandleProduct"
          className="flex flex-col gap-4"
          list={fields}
          setList={newOrder => {
            const result = detectMovedElement(
              fields.map(({ id }) => id),
              newOrder.map(({ id }) => id),
            );

            if (result) {
              move(result.from, result.to);
            }
          }}
        >
          {fields.map((field, index) => (
            <ProductMeasureItem
              key={field.id}
              index={index}
              remove={() => remove(index)}
              readOnly={field.remoteId === GRAMS_MEASURE_ID}
              selectedMeasures={fields.map(
                el => el.remoteId?.toString() ?? null,
              )}
            />
          ))}
        </ReactSortable>
      </div>

      <AddMeasureBtn
        variant="contained"
        startIcon={<Plus size="w-3 h-3" className="stroke-current" />}
        onClick={() => append({ remoteId: null, value: "1" })}
      >
        {t("product.weights_table.add_next")}
      </AddMeasureBtn>
    </ListWrapper>
  );
};
