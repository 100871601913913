import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { Controller, useFormContext } from "react-hook-form";
import { TextField } from "@mui/material";
import { useAppTranslation } from "@hooks";

export const TaskDeadlineDatePicker = () => {
  const { t } = useAppTranslation();
  const { control } = useFormContext();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name="dueDate"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <DatePicker
            value={value}
            onChange={onChange}
            inputFormat="DD.MM.YYYY"
            renderInput={({ key, ...params }) => (
              <TextField
                {...params}
                error={!!error}
                helperText={error ? error.message : null}
                size="small"
                inputProps={{
                  ...params.inputProps,
                  placeholder: t("tasks.deadlineSelect.title"),
                }}
              />
            )}
          />
        )}
      />
    </LocalizationProvider>
  );
};
