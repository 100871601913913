import type { FC } from "react";
import { TabButton, TabsWrapper } from "../MiniCalendar.styled";
import { useAppTranslation } from "@hooks";
import { useFormContext, Controller } from "react-hook-form";

export enum CalendarTimeUnit {
  TODAY = "day",
  WEEK = "week",
  MONTH = "month",
}

export const CalendarTabs: FC = () => {
  const { t } = useAppTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      name="time_unit"
      control={control}
      render={({ field: { onChange, value } }) => (
        <TabsWrapper>
          <TabButton
            onClick={() => onChange(CalendarTimeUnit.TODAY)}
            active={value === CalendarTimeUnit.TODAY}
          >
            {t("calendar.today")}
          </TabButton>
          <TabButton
            onClick={() => onChange(CalendarTimeUnit.WEEK)}
            active={value === CalendarTimeUnit.WEEK}
          >
            {t("calendar.week")}
          </TabButton>
          <TabButton
            onClick={() => onChange(CalendarTimeUnit.MONTH)}
            active={value === CalendarTimeUnit.MONTH}
          >
            {t("calendar.month")}
          </TabButton>
        </TabsWrapper>
      )}
    />
  );
};
