import { styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

export const StyledDataGrid = styled(DataGrid)`
  background-color: transparent;
  border: unset;

  & .MuiDataGrid-columnSeparator.MuiDataGrid-columnSeparator--sideRight {
    color: transparent;
  }

  & .MuiDataGrid-row {
    background-color: #fff;
    box-shadow: ${({ theme }) => theme.boxShadows.light};
  }

  & .MuiDataGrid-columnHeaders {
    background-color: #fff;
    border: unset;
    font-weight: 600;
    box-shadow: ${({ theme }) => theme.boxShadows.light};
  }

  & .MuiDataGrid-columnHeader {
    padding: 0 1.75rem;

    &:focus,
    &:focus-within {
      outline: none;
    }
  }

  & .MuiDataGrid-columnHeaderTitle {
    font-weight: inherit;
  }

  & .MuiDataGrid-footerContainer {
    margin-top: 0.5rem;
    background-color: ${({ theme }) => theme.colors.whiteBackground};
    border: unset;
    border-radius: 0 0 0.375rem 0.375rem;
    box-shadow: ${({ theme }) => theme.boxShadows.light};

    & .MuiToolbar-root {
      padding-right: 0.625rem;
    }
  }

  & .MuiDataGrid-cell {
    border-bottom: unset;
    padding: 0 1.75rem;

    &:focus,
    &:focus-within {
      outline: none;
    }
  }

  & .MuiDataGrid-cell--textLeft:first-of-type {
    font-weight: 600;

    &:hover {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
` as typeof DataGrid;
