import cupcake from "@assets/img/productTile/cupcake.png";
import leftPacman from "@assets/img/productTile/pacmanLeftSmall.png";
import rightPacman from "@assets/img/productTile/pacmanRightSmall.png";

import {
  ImagePlaceholerStyled,
  PacmanWrapper,
} from "./ImagePlaceholder.styled";

export const ImagePlaceholder = () => {
  return (
    <ImagePlaceholerStyled>
      <img src={cupcake} />
      <PacmanWrapper>
        <img className="self-center" src={leftPacman} />
        <img className="self-start" src={rightPacman} />
      </PacmanWrapper>
    </ImagePlaceholerStyled>
  );
};
