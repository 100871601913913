import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";
import { ProductDto, ItemPlaceholder } from "@client/meals";
import { useAppParams } from "@hooks";
import { useFetchCreatorProductQuery } from "@hooks/queries/meals";

import { SelectedProductEdit } from "./SelectedProductEdit";
import { useMealVersion } from "../hooks";

interface SelectedProductEditProxyProps {
  product: ProductDto | ItemPlaceholder;
}

export const SelectedProductEditProxy = ({
  product,
}: SelectedProductEditProxyProps) => {
  const { mealId } = useAppParams();

  const isPlaceholder = !!product.isPlaceholder;
  const {
    product: productDetailed,
    isLoading,
    isError,
  } = useFetchCreatorProductQuery(mealId, product.id.toString(), {
    enabled: isPlaceholder,
  });

  if (!isPlaceholder) return <SelectedProductEdit product={product} />;

  if (isLoading) return <Spinner />;

  if (isError || !productDetailed) return <ApiError />;

  return <SelectedProductEdit product={productDetailed} />;
};
