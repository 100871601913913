import { useQuery } from "@tanstack/react-query";
import {
  fetchMonitoringNutrients,
  FetchMonitoringNutrientsResponse,
  FetchMonitoringNutrientsParams,
} from "client/patient/monitoring";
import { QueryOptionsTyped } from "@hooks/queries";
import { patientMonitoringKeys } from "@hooks/queries/client/monitoring/patientMonitoringKey";

export const useFetchMonitoringNutrients = (
  params: FetchMonitoringNutrientsParams,
  options?: QueryOptionsTyped<FetchMonitoringNutrientsResponse>,
) => {
  return useQuery({
    queryKey: patientMonitoringKeys.nutrients(params),
    queryFn: () => fetchMonitoringNutrients(params),
    ...options,
  });
};
