import { CloneRecipeToBaseReponse, cloneRecipeToBase } from "@client";
import { useMutation } from "@tanstack/react-query";
import { MutationOptions } from "./types";
import { ApiResponse } from "@typeDefinitions";

export const useCloneRecipeToBaseMutation = (options?: MutationOptions) => {
  const mutation = useMutation<
    ApiResponse<CloneRecipeToBaseReponse>,
    unknown,
    string
  >(recipeId => cloneRecipeToBase(recipeId), options);

  return mutation;
};
