import { useQuery } from "@tanstack/react-query";
import { buildDefaultQueryOptions } from "../common";
import { fetchVisitTags } from "@client/visits";

export const fetchVisitTagsQueryKey = "fetchVisitTags";

export const useFetchVisitTagsQuery = (options?: { enabled?: boolean }) => {
  const { data, ...rest } = useQuery(
    [fetchVisitTagsQueryKey],
    () => fetchVisitTags(),
    buildDefaultQueryOptions(options),
  );

  return { tags: data?.data, ...rest };
};
