import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

import {
  ActionsDto,
  NutrientDto,
  actionsSchema,
  nutrientSchema,
} from "../common";

export async function searchDietsNew(
  params?: URLSearchParams,
): Promise<ApiResponse<DietSearchResourceDto[]>> {
  const data = await fetchData(
    "/dietitian/diets/search-new",
    APIMethods.GET,
    undefined,
    params,
  );
  return await apiResponseSchema.validate(data);
}

export interface DietSearchResourceDto {
  id: number;
  clinicId: number | null;
  namePl: string;
  nameEn: string | null;
  mealsCount: number;
  hide: boolean;
  tags: TagResourceDto[];
  macros: NutrientDto[];
  favorite: number[];
  actions: ActionsDto;
  image: ImageDto | null;
}

interface ImageDto {
  url: string;
}
interface TagResourceDto {
  id: number;
  systemId: number | null;
  clinicId: number | null;
  namePl: string;
  nameEn: string;
  tagCategoryId: number;
}

const imageSchema = yup.object().shape({
  url: yup.string().required(),
});

const tagResourceSchema = yup.object().shape({
  id: yup.number().required(),
  systemId: yup.number().nullable().default(null),
  clinicId: yup.number().nullable().default(null),
  namePl: yup.string().required(),
  nameEn: yup.string().required(),
  tagCategoryId: yup.number().required(),
});

const dietSearchResourceSchema = yup.object().shape({
  id: yup.number().required(),
  clinicId: yup.number().nullable().default(null),
  namePl: yup.string().required(),
  nameEn: yup.string().nullable().default(null),
  mealsCount: yup.number().required(),
  hide: yup.boolean().required(),
  tags: yup.array(tagResourceSchema.required()).required(),
  macros: yup.array().of(nutrientSchema).required(),
  actions: actionsSchema.required(),
  favorite: yup.array().of(yup.number().required()).required(),
  image: imageSchema.nullable().default(null),
});

const apiResponseSchema = yup.object().shape({
  data: yup.array().of(dietSearchResourceSchema.required()).required(),
});
