import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const List = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16" height="14" viewBox="0 0 16 14" {...props}>
      <path
        d="M1.54839 3.5C1.95904 3.5 2.35288 3.34196 2.64326 3.06066C2.93364 2.77936 3.09677 2.39782 3.09677 2C3.09677 1.60218 2.93364 1.22064 2.64326 0.93934C2.35288 0.658035 1.95904 0.5 1.54839 0.5C1.13773 0.5 0.743891 0.658035 0.453512 0.93934C0.163133 1.22064 0 1.60218 0 2C0 2.39782 0.163133 2.77936 0.453512 3.06066C0.743891 3.34196 1.13773 3.5 1.54839 3.5ZM5.67742 1C5.10645 1 4.64516 1.44687 4.64516 2C4.64516 2.55312 5.10645 3 5.67742 3H14.9677C15.5387 3 16 2.55312 16 2C16 1.44687 15.5387 1 14.9677 1H5.67742ZM5.67742 6C5.10645 6 4.64516 6.44688 4.64516 7C4.64516 7.55312 5.10645 8 5.67742 8H14.9677C15.5387 8 16 7.55312 16 7C16 6.44688 15.5387 6 14.9677 6H5.67742ZM5.67742 11C5.10645 11 4.64516 11.4469 4.64516 12C4.64516 12.5531 5.10645 13 5.67742 13H14.9677C15.5387 13 16 12.5531 16 12C16 11.4469 15.5387 11 14.9677 11H5.67742ZM1.54839 13.5C1.95904 13.5 2.35288 13.342 2.64326 13.0607C2.93364 12.7794 3.09677 12.3978 3.09677 12C3.09677 11.6022 2.93364 11.2206 2.64326 10.9393C2.35288 10.658 1.95904 10.5 1.54839 10.5C1.13773 10.5 0.743891 10.658 0.453512 10.9393C0.163133 11.2206 0 11.6022 0 12C0 12.3978 0.163133 12.7794 0.453512 13.0607C0.743891 13.342 1.13773 13.5 1.54839 13.5ZM3.09677 7C3.09677 6.80302 3.05672 6.60796 2.97891 6.42597C2.9011 6.24399 2.78704 6.07863 2.64326 5.93934C2.49948 5.80005 2.32879 5.68956 2.14093 5.61418C1.95307 5.5388 1.75172 5.5 1.54839 5.5C1.34505 5.5 1.1437 5.5388 0.955845 5.61418C0.767986 5.68956 0.597293 5.80005 0.453512 5.93934C0.309731 6.07863 0.195678 6.24399 0.117864 6.42597C0.0400502 6.60796 -3.02996e-09 6.80302 0 7C-3.02996e-09 7.19698 0.0400502 7.39204 0.117864 7.57403C0.195678 7.75601 0.309731 7.92137 0.453512 8.06066C0.597293 8.19995 0.767986 8.31044 0.955845 8.38582C1.1437 8.4612 1.34505 8.5 1.54839 8.5C1.75172 8.5 1.95307 8.4612 2.14093 8.38582C2.32879 8.31044 2.49948 8.19995 2.64326 8.06066C2.78704 7.92137 2.9011 7.75601 2.97891 7.57403C3.05672 7.39204 3.09677 7.19698 3.09677 7Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
