import { useContext } from "react";

import { DragHandle } from "@assets/icons/DesignSystem";

import { TableOfContentsContext } from "@context/TableOfContentsContext";
import {
  HandleWrapper,
  SubgroupName,
  SubgroupStyled,
} from "./TableOfContents.styled";

interface SubgroupElementProps {
  id: string;
  groupId: string;
  title: string;
  edit?: boolean;
}
export const SubgroupElement = ({
  id,
  groupId,
  title,
  edit,
}: SubgroupElementProps) => {
  const { selectedId, setSelectedId, setSubgroupId, setGroupId } = useContext(
    TableOfContentsContext,
  );

  const selected = selectedId.split("-")[1] === id;

  const handleSelect = () => {
    setSelectedId(`${groupId}-${id}`);
    setGroupId(Number(groupId));
    setSubgroupId(Number(id));
  };

  return (
    <SubgroupStyled selected={selected} onClick={handleSelect}>
      <SubgroupName>{title}</SubgroupName>
      {edit && (
        <HandleWrapper className="dragHandle-subgroup">
          <DragHandle />
        </HandleWrapper>
      )}
    </SubgroupStyled>
  );
};
