import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import {
  CreatorDietMealRecipeDto,
  CreatorDietMealRecipeRequest,
  FoodRecipeDto,
} from "@client/diets/creator";
import {
  CreateRecipeFoodRecipeRequest,
  CreateRecipeRequest,
  NewRecipeDto,
} from "@client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppTranslation } from "@hooks";

export const useRecipeEditForm = (defaultValues?: RecipeEditInput) => {
  const { t } = useAppTranslation();
  const resolver = yupResolver(
    yup.object({
      pl: yup.object({
        name: yup.string().required(t("common.required_field")),
      }),
    }),
  );
  const form = useForm<RecipeEditInput>({ defaultValues, resolver });

  useEffect(() => {
    if (defaultValues) form.reset(defaultValues);
  }, [defaultValues]);
  return form;
};

interface TranslationInput {
  name: string;
  description: string;
  comment: string | null;
}

export interface ProductInput {
  foodId: number | null;
  grams: number;
  measureId: number | null;
}

interface MediaInput {
  id: number;
  url: string;
}

export interface RecipeEditInput {
  pl?: TranslationInput;
  en?: TranslationInput;
  products?: ProductInput[];
  tags?: number[];
  servings?: number;
  media?: MediaInput | null;
}

export const mapRecipeEditForm = (
  data?: CreatorDietMealRecipeDto,
): RecipeEditInput | undefined => {
  if (!data) return;

  const {
    name,
    description,
    nameEn,
    descriptionEn,
    foodRecipe,
    tags,
    comment,
    commentEn,
  } = data.recipe;

  const pl: TranslationInput = {
    name: name,
    description: description,
    comment,
  };

  const en: TranslationInput = {
    name: nameEn,
    description: descriptionEn,
    comment: commentEn,
  };

  const products: ProductInput[] = foodRecipe.map(product => ({
    foodId: product.food.id,
    grams: product.grams ?? 0,
    measureId: product.foodMeasureId,
  }));

  const tagsIds: number[] = tags.map(tag => tag.id);

  return {
    pl,
    en,
    products,
    tags: tagsIds,
    servings: data.servings,
    media: data.recipe.media
      ? {
          id: data.recipe.media.id,
          url: data.recipe.media.s3Url,
        }
      : null,
  };
};

export const mapScheduleRecipeEditForm = (
  data?: NewRecipeDto,
): RecipeEditInput | undefined => {
  if (!data) return;

  const {
    name,
    description,
    nameEn,
    descriptionEn,
    foodRecipe,
    tags,
    comment,
    commentEn,
  } = data;

  const pl: TranslationInput = {
    name: name,
    description: description ?? "",
    comment,
  };

  const en: TranslationInput = {
    name: nameEn ?? "",
    description: descriptionEn ?? "",
    comment: commentEn,
  };

  const products: ProductInput[] = foodRecipe.map(product => ({
    foodId: product.food.id,
    grams: product.grams ?? 0,
    measureId: product.foodMeasureId,
  }));

  const tagsIds: number[] = tags.map(tag => tag.id);

  return {
    pl,
    en,
    products,
    tags: tagsIds,
    servings: data.servings,
    media: data.media
      ? {
          id: data.media.id,
          url: data.media.s3Url,
        }
      : null,
  };
};

export const mapScheduleRecipeEditRequest = (
  values: RecipeEditInput,
  version: number,
  recipeId: string,
): CreateRecipeRequest => {
  const pl = values.pl || { name: "", description: "", comment: null };
  const en = values.en || { name: "", description: "", comment: null };

  const foodRecipe: CreateRecipeFoodRecipeRequest[] =
    values.products?.map((product, idx) => ({
      id: null,
      foodId: product.foodId ?? 0,
      grams: product.grams,
      foodMeasureId: product.measureId ?? 1,
      sortOrder: idx,
    })) || [];

  return {
    servings: values.servings ?? 0,
    id: Number(recipeId),
    name: pl.name,
    nameEn: en.name,
    description: pl.description,
    descriptionEn: en.description,
    reviewed: false,
    tags: values.tags || [],
    media: values.media ?? null,
    foodRecipe: foodRecipe,
    version,
    isComplex: Number(values.servings) > 1,
    comment: pl.comment,
    commentEn: en.comment,
  };
};
export const mapRecipeEditRequest = (
  values: RecipeEditInput,
  version: number,
  recipeId: string,
): CreatorDietMealRecipeRequest => {
  const pl = values.pl || { name: "", description: "", comment: null };
  const en = values.en || { name: "", description: "", comment: null };

  interface DefinedFoodIdProductInput extends ProductInput {
    foodId: number;
    measureId: number;
  }
  const foodRecipe: FoodRecipeDto[] =
    values.products
      ?.filter(
        (product): product is DefinedFoodIdProductInput =>
          product.foodId !== null,
      )
      .map(({ foodId, grams, measureId }) => ({
        id: null,
        foodId: foodId,
        grams: grams,
        foodMeasureId: measureId,
      })) || [];

  return {
    servings: values.servings ?? 0,
    recipe: {
      id: recipeId,
      name: pl.name,
      nameEn: en.name,
      description: pl.description,
      descriptionEn: en.description,
      servings: values.servings ?? 0,
      reviewed: false,
      tags: values.tags || [],
      media: values.media ?? null,
      foodRecipe: foodRecipe,
      version,
      comment: pl.comment,
      commentEn: en.comment,
    },
  };
};
export const mapRecipeCreateRequest = (
  values: RecipeEditInput,
): CreateRecipeRequest => {
  const servings = values.servings ?? 1;
  return {
    id: null,
    name: values.pl?.name ?? "",
    nameEn: values.en?.name ?? "",
    description: values.pl?.description ?? "",
    descriptionEn: values.en?.description ?? "",
    isComplex: servings > 1,
    servings,
    reviewed: false,
    foodRecipe:
      values.products?.map((product, index) => ({
        id: null,
        foodId: product.foodId ?? 0,
        grams: product.grams,
        foodMeasureId: product.measureId ?? 0,
        sortOrder: index,
      })) ?? [],
    tags: values.tags ?? [],
    version: null,
    media: values.media
      ? {
          id: values.media.id,
        }
      : null,
    comment: values.pl?.comment ?? null,
    commentEn: values.en?.comment ?? null,
  };
};
