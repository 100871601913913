import { Card, Typography, styled } from "@mui/material";

export const EmptyStateCard = styled(Card)`
  border-radius: 1rem;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  height: 100%;
  padding: 2rem 0;
`;

export const EmptyStateCardForGrid = styled(Card)`
  display: grid;
  place-content: center;
  border-radius: 1rem;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  height: 100%;
  padding: 2rem 0;
  margin-top: 0.5rem;
`;

export const ErrorPagesWrapper = styled("div")`
  width: 100%;
  height: 100%;
`;

export const StyledTitle = styled(Typography)`
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;

export const StyledSubTitle = styled(Typography)`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;
