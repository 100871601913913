import { RefObject, useEffect, useState } from "react";

export const useResizeObserver = (ref: RefObject<HTMLDivElement> | null) => {
  const [clientHeight, setClientHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (ref?.current) {
        setClientHeight(ref.current.clientHeight);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (ref?.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      if (ref?.current) {
        resizeObserver.unobserve(ref.current);
      }
    };
  }, [ref]);
  return clientHeight;
};
