import { useQuery } from "@tanstack/react-query";

import { QueryOptionsTyped } from "../types";
import { buildDefaultQueryOptions } from "../common";
import { ApiResponse } from "@typeDefinitions";
import {
  FetchClinicFacilitiesResponse,
  fetchClinicFacilities,
} from "@client/settings";

export const fetchClinicFacilitiesQueryKey = "fetchClinicFacilitiesQueryKey";

export const useFetchClinicFacilitiesQuery = (
  options?: QueryOptionsTyped<ApiResponse<FetchClinicFacilitiesResponse>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchClinicFacilitiesQueryKey],
    fetchClinicFacilities,
    buildDefaultQueryOptions(options),
  );

  return {
    facilities: data?.data,
    ...rest,
  };
};
