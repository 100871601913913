import { useCallback, useContext } from "react";

import { useAppTranslation } from "@hooks";
import { requestDietNutrients } from "@client";
import { Nutrient } from "@typeDefinitions";
import { MACROS_ARRAY } from "@utils/macros";
import { ProgramContext } from "@context";
import { MacrosList } from "@components/MacrosList";
import { MicronutrientPreview } from "@components/MicronutrientsModal";

import {
  MicronutrientsSection,
  ProgramDietDetailsWrapper,
} from "./ProgramDietDetails.styled";

export interface ProgramDayDiet {
  id: number;
  title?: string;
  titleEn?: string;
  mealsCount: number;
  nutrients: Nutrient[];
}

interface ProgramDietDetailsProps {
  diet?: ProgramDayDiet | null;
  titleClassName?: string;
  normId?: number;
}

export const ProgramDietDetails = ({
  diet,
  titleClassName,
  normId,
}: ProgramDietDetailsProps) => {
  const { t, isPolishChosen } = useAppTranslation();
  const { microsShownToDietitian, programId, dayId } =
    useContext(ProgramContext);

  const query = useCallback(
    () => requestDietNutrients(diet?.id ?? 0),
    [diet?.id],
  );

  return (
    <ProgramDietDetailsWrapper>
      {diet && (
        <>
          <p className={titleClassName}>{t("common.diet")}:</p>
          <div className="grid">
            <p className="font-semibold">
              {isPolishChosen ? diet.title : diet.titleEn}
            </p>
            <MicronutrientsSection>
              <p>
                <span className="font-semibold mr-1">{diet.mealsCount}</span>
                {t("diets.meals", { count: diet.mealsCount })}
              </p>
              <div className={"w-72"}>
                <MacrosList
                  nutrients={diet.nutrients}
                  show={[...MACROS_ARRAY, ...microsShownToDietitian]}
                />
              </div>
              <MicronutrientPreview
                noPadding
                query={query}
                normId={normId}
                programDay={
                  !!programId && !!dayId ? { programId, dayId } : undefined
                }
              />
            </MicronutrientsSection>
          </div>
        </>
      )}
    </ProgramDietDetailsWrapper>
  );
};
