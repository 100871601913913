import { MouseEvent, useState } from "react";
import { TabContext, TabPanel } from "@mui/lab";
import {
  CustomToggleButton,
  CustomToggleButtonGroup,
} from "@views/dietician/PatientMonitoring2/components/TabPanel.styled";
import MealsBoxClient from "@views/dietician/PatientMonitoring2/components/MealsBox/Client/MealsBoxClient";
import MealsBoxDietitian from "@views/dietician/PatientMonitoring2/components/MealsBox/Dietitian/MealsBoxDietitian";
import { useAppTranslation } from "@hooks";
import { DoubleArrowMonitoring } from "@assets/icons/Monitoring";
import { Box, styled } from "@mui/material";
import useToggleMeals from "@views/dietician/PatientMonitoring2/components/MealsBox/hooks/useToggleMeals";
import MealsBox from "@views/dietician/PatientMonitoring2/components/MealsBox/MealsBox";

const MealsBoxByDay = () => {
  const { t } = useAppTranslation();
  const [tab, setTab] = useState("1");
  const {
    toggleExpandedAll,
    isExpandedAll,
    setMealsId,
    toggleExpandedMeal,
    isExpandedMeal,
    showExpandedAll,
  } = useToggleMeals();

  const handleChange = (
    event: MouseEvent<HTMLElement>,
    newValue: string | null,
  ) => {
    newValue && setTab(newValue);
  };

  return (
    <MealsBox>
      <TabContext value={tab}>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
          alignItems="center"
        >
          <CustomToggleButtonGroup
            value={tab}
            exclusive
            onChange={handleChange}
          >
            <CustomToggleButton value="1">
              {t("monitoring2.meals_box.tabs.first")}
            </CustomToggleButton>
            <CustomToggleButton value="2">
              {t("monitoring2.meals_box.tabs.second")}
            </CustomToggleButton>
          </CustomToggleButtonGroup>
          {showExpandedAll() && (
            <Box
              onClick={toggleExpandedAll}
              display="flex"
              flexDirection="row"
              gap="8px"
              alignItems="center"
              style={{ cursor: "pointer" }}
            >
              <ShowAllLabel>
                {isExpandedAll()
                  ? t("monitoring2.meals_box.hide_all")
                  : t("monitoring2.meals_box.show_all")}
              </ShowAllLabel>
              <DoubleArrowMonitoring
                size=""
                fill="#7448D0"
                style={{ transform: isExpandedAll() ? "rotate(90deg)" : "" }}
              />
            </Box>
          )}
        </Box>
        <TabPanel value="1" style={{ padding: "0px" }}>
          <MealsBoxClient
            toggleMeal={{
              setMealsId,
              toggleExpandedMeal,
              isExpandedMeal,
            }}
          />
        </TabPanel>
        <TabPanel value="2" style={{ padding: "0px" }}>
          <MealsBoxDietitian
            toggleMeal={{
              setMealsId,
              toggleExpandedMeal,
              isExpandedMeal,
            }}
          />
        </TabPanel>
      </TabContext>
    </MealsBox>
  );
};

const ShowAllLabel = styled("span")`
  color: ${({ theme }) => theme.palette.primary.main};
  line-height: 24px;
  font-size: 14px;
  font-weight: 500;
`;

export default MealsBoxByDay;
