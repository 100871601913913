import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { useAppTranslation } from "@hooks";
import { requestDeletePattern } from "@client";

import { MutationOptions } from "./types";
import { searchMealPatternsQueryNewKey } from "./useSearchMealPatternsQueryNew";

export const useDeleteMealPatternMutation = (options?: MutationOptions) => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();

  return useMutation((id: number) => requestDeletePattern(id), {
    ...options,
    onSuccess: () => {
      toast.success(t("meals.success_delete"));
      queryClient.invalidateQueries([searchMealPatternsQueryNewKey]);
      options?.onSuccess && options.onSuccess();
    },
  });
};
