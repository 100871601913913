import { Sexes, useAppTranslation } from "@hooks";
import { Control, Controller } from "react-hook-form";
import {
  StyledToggleButton,
  StyledToggleButtonGroup,
} from "./SexSelector.styled";
import { Female, Male } from "@assets/icons/Patient";

interface SexSelectorProps {
  control: Control<any>;
  name: string;
}

export const SexSelector = ({ control, name }: SexSelectorProps) => {
  const { t } = useAppTranslation();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <StyledToggleButtonGroup
            fullWidth
            exclusive
            value={value}
            onChange={(e, value) => onChange(value)}
          >
            <StyledToggleButton value={Sexes.w}>
              <Female size="large" />
              {t("client_add.woman")}
            </StyledToggleButton>
            <StyledToggleButton value={Sexes.m}>
              <Male size="large" />
              {t("client_add.man")}
            </StyledToggleButton>
          </StyledToggleButtonGroup>
        );
      }}
    />
  );
};
