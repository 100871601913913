import styled from "@emotion/styled";

export const HeaderWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 1rem;
  font-weight: 600;
  align-items: center;
`;
