import { Stack } from "@mui/material";
import { SyntheticEvent, useCallback, useMemo } from "react";
import NestedAccordion from "@views/dietician/PatientMonitoring2/components/MealsBox/NestedAccordion";
import Macros from "@views/dietician/PatientMonitoring2/components/MealsBox/Macros";
import MealItemName from "@views/dietician/PatientMonitoring2/components/MealsBox/MealItemName";
import { CARBS_ID, ENERGY_ID, FATS_ID, PROTEIN_ID } from "@utils/macros";
import { useAppTranslation } from "@hooks";
import { ItemProduct } from "@client/resources/PatientMealsMonitoringResource";
import { useFoodMeasuresQuery } from "@hooks/queries/dictionaries";
import { ProductMeasurementConst } from "@consts/ProductMeasurementConst";

type MealItemProductProps = {
  item: ItemProduct;
  expanded: boolean;
  onClickProduct: (id: number) => void;
  handleChangeExpanded: (event: SyntheticEvent, isExpanded: boolean) => void;
};

const MealItemProduct = ({
  item,
  expanded,
  onClickProduct,
  handleChangeExpanded,
}: MealItemProductProps) => {
  const { currentLanguage } = useAppTranslation();
  const { data: productMeasurements } = useFoodMeasuresQuery();
  const itemProduct = item.item;
  const getNutrientValue = useCallback(
    (id: number) =>
      Math.round(
        (itemProduct.grams / 100) *
          (itemProduct.product.nutrients.find(n => n.id === id)?.value ?? 0),
      ),
    [itemProduct],
  );

  const name = useMemo(() => {
    const name =
      itemProduct.product.translations.find(t => t.lang === currentLanguage)
        ?.name ?? itemProduct.product.name;

    if (!productMeasurements) {
      return name;
    }

    if (itemProduct.measure.id === ProductMeasurementConst.grams) {
      return `${name} (${itemProduct.grams} g)`;
    }

    return `${name} (${itemProduct.grams} g)`;
  }, [itemProduct, currentLanguage, productMeasurements]);

  return (
    <Stack direction="column" spacing="8px">
      <MealItemName
        name={name}
        rating={item.rating}
        onClick={() => onClickProduct(itemProduct.product.id)}
      />
      <NestedAccordion
        expanded={expanded}
        handleChangeExpanded={handleChangeExpanded}
        sx={{
          "&.MuiPaper-root": {
            margin: "0",
          },
        }}
      >
        <Macros
          kcal={getNutrientValue(ENERGY_ID)}
          protein={getNutrientValue(PROTEIN_ID)}
          fat={getNutrientValue(FATS_ID)}
          carb={getNutrientValue(CARBS_ID)}
        />
      </NestedAccordion>
    </Stack>
  );
};

export default MealItemProduct;
