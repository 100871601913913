import React from "react";

import { useAppTranslation } from "@hooks";
import { DietMealDto } from "@client";

import { ProgramMealValues } from "./ProgramMealValues";
import { TableContentWrapper } from "./TableContentWrapper.styled";
import { ProgramMealValuesTotal } from "./ProgramMealValuesTotal";

export const ProgramSummaryTableContent = ({
  meals,
}: ProgramScheduleTableProps) => {
  const { t } = useAppTranslation();

  return (
    <div className="grid w-full grid-cols-12 pt-4">
      <TableContentWrapper colSpan={3} header justifyStart>
        {t("patient.schedule.meals_scheme")}
      </TableContentWrapper>
      <TableContentWrapper colSpan={2} header border>
        {t("patient.schedule.target_values")}
      </TableContentWrapper>
      <TableContentWrapper colSpan={7} header>
        {t("patient.schedule.energy_macronutrients")}
      </TableContentWrapper>
      {meals?.map(({ id, name, hour, kcal, nutrients }) => (
        <ProgramMealValues
          key={id}
          name={name}
          hour={hour}
          targetKcal={kcal}
          nutrients={nutrients}
        />
      ))}
      <ProgramMealValuesTotal meals={meals} />
    </div>
  );
};

interface ProgramScheduleTableProps {
  meals?: DietMealDto[];
}
