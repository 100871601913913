import { Controller } from "react-hook-form";
import { MenuItem, Select } from "@mui/material";
import { useMemo } from "react";
import { useFetchClientTargetsQuery } from "@hooks/queries";
import { useAppTranslation } from "@hooks";
import { getTranslation } from "@utils/dictionaries/clientTargetGoalDictionaryUtils";
import FormLabel from "@components/Alloweat/Inputs/FormLabel";
import FormControl from "@components/Alloweat/Inputs/FormControl";

const GoalField = () => {
  const { t, currentLanguage } = useAppTranslation();
  const { targets } = useFetchClientTargetsQuery({
    staleTime: Infinity,
  });

  return (
    <FormControl>
      <FormLabel>
        {t("patient.health_and_goal.body_measurement_tab.section_goal.goal")}
      </FormLabel>
      <Controller
        render={({ field }) => {
          return (
            <Select
              value={field.value}
              onChange={event => field.onChange(event.target.value)}
            >
              {useMemo(
                () =>
                  targets?.map(target => (
                    <MenuItem key={target.id} value={target.id}>
                      {getTranslation(target, currentLanguage)?.value}
                    </MenuItem>
                  )),
                [targets, currentLanguage],
              )}
            </Select>
          );
        }}
        name="goalId"
      />
    </FormControl>
  );
};

export default GoalField;
