import { useCallback, useEffect, useMemo } from "react";

import { useAppTranslation } from "@hooks";
import {
  useFetchDietitianAccountQuery,
  useUpdateDietitianAccountMutation,
} from "@hooks/queries/settings";
import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";
import { FormTextField } from "@components/FormTextField";
import { FormCountryAutocomplete } from "@components/FormCountryAutocomplete";

import {
  DietitianAccountFormInput,
  mapDietitianAccountForm,
  mapDietitianAccountRequest,
  useDietitianAccountForm,
} from "./useDietitianAccountForm";
import { Dialog, Typography, useTheme } from "@mui/material";
import { FormAutocomplete, SelectOption } from "@components/FormAutocomplete";
import { Close } from "@assets/icons";
import { LangDto } from "@client";
import { LoadingButton } from "@mui/lab";
import {
  CancelButton,
  CloseButton,
  SettingsModalCard,
  SettingsModalWrapper,
  StyledDialogActions,
  Title,
  TitleWrapper,
} from "../../SettingsAccount.styled";

interface EditPersonalInfoModalProps {
  onClose?: () => void;
  onOpen: boolean;
}

export const EditPersonalInfoModal = ({
  onOpen,
  onClose,
}: EditPersonalInfoModalProps) => {
  const { i18n, t } = useAppTranslation();
  const { colors } = useTheme();

  const { account, isLoading, isError } = useFetchDietitianAccountQuery();
  const { mutate: updateDietitianAccount } =
    useUpdateDietitianAccountMutation();

  const mappedDietitianAccount = useMemo(
    () => mapDietitianAccountForm(account),
    [mapDietitianAccountForm, account],
  );

  const handleUpdateUser = useCallback(
    (data: DietitianAccountFormInput) => {
      updateDietitianAccount(mapDietitianAccountRequest(data));
      reset();
      onClose && onClose();
    },
    [updateDietitianAccount, mapDietitianAccountRequest],
  );

  const { control, handleSubmit, reset } = useDietitianAccountForm(
    mappedDietitianAccount,
  );

  const onCloseWithReset = () => {
    reset();
    onClose && onClose();
  };

  useEffect(() => {
    i18n.changeLanguage(account?.language);
  }, [account?.language]);

  const langsMapped = useMemo<SelectOption[]>(
    () =>
      account?.language === LangDto.PL
        ? [{ id: LangDto.EN, label: t("settings.personal_info.language.en") }]
        : [{ id: LangDto.PL, label: t("settings.personal_info.language.pl") }],
    [account?.language, t],
  );

  const defaultLangsMapped = useMemo<SelectOption[]>(
    () =>
      account?.language === LangDto.PL
        ? [{ id: LangDto.PL, label: t("settings.personal_info.language.pl") }]
        : [{ id: LangDto.EN, label: t("settings.personal_info.language.en") }],
    [account?.language, t],
  );

  if (isLoading) return <Spinner className="h-screen" />;

  if (isError) return <ApiError />;

  return (
    <Dialog open={onOpen} disableEscapeKeyDown>
      <SettingsModalWrapper>
        <TitleWrapper>
          <Title>{t("settings.personal_info.edit_info")}</Title>
          <CloseButton onClick={onCloseWithReset}>
            <Close />
          </CloseButton>
        </TitleWrapper>
        <SettingsModalCard>
          <div className="grid gap-1">
            <Typography variant="body2" color={colors.neutral.medium[800]}>
              {t("settings.account.name")}
            </Typography>
            <FormTextField
              name="firstName"
              control={control}
              variant="outlined"
              size="small"
            />
          </div>
          <div className="grid gap-1">
            <Typography variant="body2" color={colors.neutral.medium[800]}>
              {t("settings.account.surname")}
            </Typography>
            <FormTextField
              name="lastName"
              control={control}
              variant="outlined"
              size="small"
            />
          </div>
          <div className="grid gap-1">
            <Typography variant="body2" color={colors.neutral.medium[800]}>
              {t("common.language")}
            </Typography>
            <FormAutocomplete
              defaultValue={defaultLangsMapped[0]}
              control={control}
              name="language"
              id="language"
              size="small"
              options={langsMapped}
            />
          </div>
          <div className="grid gap-1">
            <Typography variant="body2" color={colors.neutral.medium[800]}>
              {t("common.email_address")}
            </Typography>
            <FormTextField
              name="email"
              control={control}
              size="small"
              disabled
            />
          </div>
          <div className="flex gap-5 ">
            <div className="grid gap-1 w-[120px]">
              <Typography variant="body2" color={colors.neutral.medium[800]}>
                {t("settings.personal_info.prefix")}
              </Typography>
              <FormCountryAutocomplete
                control={control}
                name="prefix"
                id="prefix"
                size="small"
              />
            </div>
            <div className="grid gap-1 flex-3">
              <Typography variant="body2" color={colors.neutral.medium[800]}>
                {t("common.phone_number")}
              </Typography>
              <FormTextField
                name="phone"
                control={control}
                variant="outlined"
                size="small"
                fullWidth
              />
            </div>
          </div>
        </SettingsModalCard>
      </SettingsModalWrapper>
      <StyledDialogActions>
        <CancelButton variant="outlined" onClick={onCloseWithReset}>
          {t("common.cancel")}
        </CancelButton>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          onClick={handleSubmit(handleUpdateUser, e => console.log("e", e))}
        >
          {t("common.save")}
        </LoadingButton>
      </StyledDialogActions>
    </Dialog>
  );
};
