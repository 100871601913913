import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const updateClientFoodCreator = async (
  id: number,
  payload: UpdateClientFoodCreatorRequest,
) => {
  await fetchData(
    `/dietitian/patients/${id}/application-modules/food-creator`,
    APIMethods.PUT,
    payload,
  );
};

export interface UpdateClientFoodCreatorRequest {
  isActive: boolean;
}
