import ExportPdfModal from "@Pdf/components/ExportPdfModal/ExportPdfModal";
import { IconButton, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import { DownloadPdf, Hide } from "@assets/icons";
import { useState } from "react";
import { useAppTranslation } from "@hooks";

type ExportPdfActionProps = {
  programId: number;
  patientId: number;
};
const ExportPdfAction = ({ programId, patientId }: ExportPdfActionProps) => {
  const { t } = useAppTranslation();
  const [openExportPdfModal, setOpenExportPdfModal] = useState(false);

  return (
    <>
      <Tooltip title={t("patient.program_preview.menu_actions.save_pdf")} arrow>
        <IconButton onClick={() => setOpenExportPdfModal(true)}>
          <DownloadPdf
            size=""
            fill="#727272"
            style={{ width: "32px", height: "32px" }}
          />
        </IconButton>
      </Tooltip>
      {openExportPdfModal && (
        <ExportPdfModal
          open={openExportPdfModal}
          patientId={patientId}
          programId={programId}
          onClose={() => setOpenExportPdfModal(false)}
        />
      )}
    </>
  );
};

export default ExportPdfAction;
