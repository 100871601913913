import Dialog from "@components/Alloweat/Dialog/Dialog";
import React, { Dispatch } from "react";
import DialogTitle from "@components/Alloweat/Dialog/DialogTitle";
import DialogActions from "@components/Alloweat/Dialog/DialogActions";
import { Button, DialogContent, Stack } from "@mui/material";
import { Divider } from "@views/dietician/ClientHealth/ClientHealthNew.styled";
import { useAppTranslation } from "@hooks";
import ProfileSectionForm from "@views/dietician/ClientHealth/ProfileTab/ProfileSection/Dialog/ProfileSectionForm";
import AdditionalFeatureField from "@views/dietician/ClientHealth/ProfileTab/ProfileSection/Dialog/AdditionalFeatureField";
import ProductExchangesField from "@views/dietician/ClientHealth/ProfileTab/ProfileSection/Dialog/ProductExchangesField";
import NutritionalNormField from "@views/dietician/ClientHealth/ProfileTab/ProfileSection/Dialog/NutritionalNormField";
import { IconButton } from "@components/Alloweat/IconButton";
import { CloseIcon } from "@icons/index";
import { FormCancelButton, FormSubmitButton } from "@components/FormButtons";

type MainDialogProps = {
  open: boolean;
  setOpen: Dispatch<boolean>;
};
const ProfileSectionDialog = ({ open, setOpen }: MainDialogProps) => {
  const { t } = useAppTranslation();
  const title = t(
    "patient.health_and_goal.dietary_profile_tab.dietary_profile_modal.title",
  ).toLowerCase();

  return (
    <Dialog open={open} fullWidth PaperProps={{ sx: { maxWidth: "510px" } }}>
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {title.charAt(0).toUpperCase() + title.slice(1)}
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon color="727272" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <ProfileSectionForm onSuccess={() => setOpen(false)}>
        <DialogContent>
          <Stack direction="column" gap="16px">
            <AdditionalFeatureField />
            <NutritionalNormField />
            <Divider />
            <ProductExchangesField />
          </Stack>
        </DialogContent>
        <DialogActions>
          <FormCancelButton onClick={() => setOpen(false)} />
          <FormSubmitButton />
        </DialogActions>
      </ProfileSectionForm>
    </Dialog>
  );
};

export default ProfileSectionDialog;
