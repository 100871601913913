import { TabPanel } from "@mui/lab";
import { Button, Skeleton, styled } from "@mui/material";

export const GenerateButton = styled(Button)`
  height: 24px;
  display: flex;
  gap: 4px;
`;

export const SkeletonStyled = styled(Skeleton)`
  border-radius: 8px;
`;

export const TabPanelStyled = styled(TabPanel)`
  &.MuiTabPanel-root {
    padding: unset;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;

export const LabelUppercase = styled("span")`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
  text-transform: uppercase;
`;

export const Text = styled("span")`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const ContentWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: 666.5px;
  height: 760px;
`;

export const StickyHeader = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: ${({ theme }) => theme.colors.neutral.light[100]};
  padding: 0px 14px 7px;
`;
