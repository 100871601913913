import { fetchData } from "@utils/api";
import {
  APIMethods,
  ApiEndpoints,
  PaginationLinks,
  Metadata,
} from "@typeDefinitions";
import qs from "qs";

export interface SuggestedTasksQueryParams {
  tagId?: number | number[];
  hidden?: number;
}
//TODO: Validation Schema
export const fetchSuggestedTasksList = async (
  page: number,
  params: SuggestedTasksQueryParams,
) => {
  const queryParams = qs.stringify(
    { page, ...params },
    { arrayFormat: "comma" },
  );

  const response = await fetchData<SuggestedTasksResponse>(
    `${ApiEndpoints.SuggestedTasksList}?${queryParams}`,
    APIMethods.GET,
  );

  return response;
};

interface Dietitian {
  id: number;
  firstName: string;
  lastName: string;
  image: string;
}

interface Patient {
  id: number;
  firstName: string;
  lastName: string;
  image: string;
  subscription: {
    expired: string;
  };
}

interface Tag {
  id: number;
}

interface Item {
  id: number;
  finishedAt: string;
}

export interface SuggestedTask {
  id: number;
  dietitian: Dietitian;
  patient: Patient;
  tag: Tag;
  item: Item;
}

interface SuggestedTasksResponse {
  data: SuggestedTask[];
  links: PaginationLinks;
  meta: Metadata;
}
