import { createContext } from "react";

export interface Norm {
  id: number;
  value: number;
}

interface NormsContextIf {
  isEmpty: boolean;
  getNorm: (id: number) => Norm | undefined;
}

export const NormsContext = createContext<NormsContextIf>({
  isEmpty: true,
  getNorm: () => undefined,
});
