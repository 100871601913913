import { MacrosListMui } from "@components/MacrosList";
import { MicronutrientPreview } from "@components/MicronutrientsModal";
import { Divider, Typography } from "@mui/material";
import { Nutrient } from "@typeDefinitions";
import { DietMealsSumWrapper } from "./MealsSection.styled";
import { useAppTranslation } from "@hooks";
import { MicronutrientsWrapper } from "./DayMealsSum.styled";

interface DayMealsSumProps {
  nutrients?: Nutrient[];
}

export const DayMealsSum = ({ nutrients = [] }: DayMealsSumProps) => {
  const { t } = useAppTranslation();

  return (
    <div>
      <Divider sx={{ borderColor: "black" }} />
      <DietMealsSumWrapper>
        <div className="flex">
          <Typography variant="h6">{t("common.sum")}:</Typography>
        </div>

        <MicronutrientsWrapper>
          <MacrosListMui nutrients={nutrients} fixed />
          <MicronutrientPreview means={nutrients} noPadding />
        </MicronutrientsWrapper>
      </DietMealsSumWrapper>
    </div>
  );
};
