import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { Visit, visitSchema } from "./common";
import * as yup from "yup";

export async function fetchPatientVisits(
  patientId: number,
): Promise<ApiResponse<Visit[]>> {
  const data = await fetchData(
    `/dietitian/patients/${patientId}/schedules/visits`,
    APIMethods.GET,
  );
  return await patientVisitsSchema.validate(data);
}

const patientVisitsSchema = yup.object().shape({
  data: yup.array().of(visitSchema).required(),
});
