import { useController } from "react-hook-form";
import { MeasureSelect } from "../../RecipesTab/ElementsAccordions/MeasureSelect";
import { ProductEditInput } from "../useProductEditForm";

interface MeasureSelectFormProps {
  productId?: string;
}

export const MeasureSelectForm = ({ productId }: MeasureSelectFormProps) => {
  const {
    field: { value: grams, onChange: onChangeGrams },
  } = useController<ProductEditInput>({ name: "grams" });
  const {
    field: { value: measure, onChange: onChangeMeasure },
  } = useController<ProductEditInput>({ name: "measureId" });

  return (
    <MeasureSelect
      id={Number(productId)}
      grams={grams || 1}
      onGramsChange={onChangeGrams}
      measure={`${measure || 1}`}
      onMeasureChange={id => onChangeMeasure(Number(id))}
    />
  );
};
