import { ListElement } from "./MealTypeTexField.styled";

interface ListOptionProps {
  label: string;
  onClick: () => void;
  className?: string;
}

export const ListOption = ({ label, onClick, className }: ListOptionProps) => {
  return (
    <ListElement onClick={onClick} className={className}>
      {label}
    </ListElement>
  );
};
