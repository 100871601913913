import { postMediaRecipeUpload } from "@client";
import { useMutation } from "@tanstack/react-query";
import { MutationOptions } from "./types";
import { ApiResponse } from "@typeDefinitions";
import { MediaResourceDto } from "@client/postMediaRecipeUpload";

export const usePostMediaRecipeUploadMutation = (
  options: MutationOptions<ApiResponse<MediaResourceDto>, FormData> = {},
) => {
  return useMutation(postMediaRecipeUpload, options);
};
