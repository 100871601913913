import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MutationOptions } from "./types";
import { fetchProgramQueryKey } from "./useFetchProgramQuery";
import { fetchPatientProgramQueryKey } from "./useFetchPatientProgramQuery";
import { updateProgramSections } from "@client";

interface UseUpdateProgramSectionsMutationRequest {
  programId: number;
  payload: {
    sections: number[];
  };
}

export const useUpdateProgramSectionsMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ programId, payload }: UseUpdateProgramSectionsMutationRequest) =>
      updateProgramSections(programId, payload),
    {
      ...options,
      onSuccess: (_, values) => {
        queryClient.invalidateQueries([
          fetchProgramQueryKey,
          values?.programId,
        ]);
        queryClient.invalidateQueries([fetchPatientProgramQueryKey]);
        options?.onSuccess && options.onSuccess(_, values);
      },
    },
  );
};
