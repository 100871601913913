import {
  DietMealItemCreatorResponseDto,
  fetchCreatorDietMealsItems,
} from "@client/diets/creator";
import { QueryOptionsTyped } from "@hooks/queries/types";
import { useQuery } from "@tanstack/react-query";
import { ApiResponse } from "@typeDefinitions";

export const fetchCreatorDietMealsItemsQuery =
  "fetchCreatorDietMealsItemsQuery";

export const useFetchCreatorDietMealsItemsQuery = (
  dietId: string,
  mealId: string,
  options?: QueryOptionsTyped<ApiResponse<DietMealItemCreatorResponseDto>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchCreatorDietMealsItemsQuery, dietId, mealId],
    () => fetchCreatorDietMealsItems(dietId, mealId),
    options,
  );

  return {
    data: data?.data,
    ...rest,
  };
};
