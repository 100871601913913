import { useForm } from "react-hook-form";

export const useMealProductForm = (grams: number, measureId: number) => {
  const form = useForm<MealProductInputs>({
    defaultValues: {
      grams: `${grams}`,
      measureId: `${measureId}`,
    },
  });

  return form;
};

export interface MealProductInputs {
  grams: string;
  measureId: string;
}
