import { styled } from "@mui/material";

export const DateDividerWrapper = styled("div")`
  display: grid;
  grid-template-columns: 1fr minmax(0, max-content) 1fr;
  align-items: center;
  width: 96%;
  margin: 0 auto;
`;

export const DateWrapper = styled("p")`
  text-align: center;
  padding: 0 2px;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;
