import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  styled,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@components/Alloweat/Accordion/Accordion";
import { FetchClientMeasurementListResponse } from "@client";
import { Dispatch, useMemo } from "react";
import dayjs from "dayjs";
import { ClientSilhouettePhotoArray } from "@consts/ClientSilhouettePhotoConst";
import {
  BoxContent,
  BoxContentLabel,
  CapImage,
  Image,
  ImageContainer,
} from "@views/dietician/PatientMonitoring2/components/MeasurementsBox/MeasurementsPhotosSection.styled";
import { ImageMonitoring } from "@assets/icons/Monitoring";
import { useAppTranslation } from "@hooks";
import { ImagePreview } from "@components/Client/PatientBodyMeasurementsGalleryModal/MeasurementPhotosContent";
import { MeasurementIcon } from "@icons/index";

type MeasurementPhotosItemProps = {
  measurement: FetchClientMeasurementListResponse;
  imagePreview: ImagePreview;
  setImagePreview: Dispatch<ImagePreview>;
};

const MeasurementPhotosItem = ({
  measurement,
  setImagePreview,
}: MeasurementPhotosItemProps) => {
  const { t } = useAppTranslation();

  const parsedDate = useMemo(() => {
    return dayjs(measurement.date).format("DD.MM.YYYY");
  }, [measurement]);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <MeasurementIcon color="#727272" />
          <Label>
            {t(
              "patient.health_and_goal.body_measurement_tab.image_gallery_modal.measurement",
            )}{" "}
            ({parsedDate})
          </Label>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="column" gap="12px">
          {ClientSilhouettePhotoArray.map(type => {
            const typeImage = measurement.photos.find(
              photo => photo.type.id === type,
            );
            if (!typeImage) {
              return null;
            }
            return (
              <ImageContainer
                key={type}
                onClick={() => {
                  if (!typeImage) {
                    return;
                  }
                  setImagePreview({
                    measurement: measurement,
                    photo: typeImage,
                  });
                }}
                sx={{ cursor: "pointer" }}
              >
                {typeImage ? (
                  <Image src={typeImage.url} loading="lazy" />
                ) : (
                  <CapImage>
                    <ImageMonitoring fill="#7448D0" size="" />
                  </CapImage>
                )}
                <BoxContent sx={{ width: "unset" }}>
                  <BoxContentLabel>
                    {t(`monitoring2.measurements_box.photo_section.${type}`)}
                  </BoxContentLabel>
                </BoxContent>
              </ImageContainer>
            );
          })}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

const Label = styled("span")`
  font: var(--font-figtree-medium);
  font-size: 14px;
  line-height: 24px;
  color: #333333;
`;

export default MeasurementPhotosItem;
