import { styled } from "@mui/material";

export const Wrapper = styled("li")`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 12px;
`;

interface OptionTextProps {
  bold?: boolean;
}

export const OptionText = styled("span")<OptionTextProps>`
  flex: 1;
  font-size: 14px;
  font-weight: ${({ bold }) => (bold ? 600 : 500)};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const OptionWrapper = styled("div")`
  display: flex;
  gap: 8px;
  width: 100%;
  justify-content: flex-start;
  align-items: start;
`;
