import React, { useCallback, useMemo, useState } from "react";
import { Box, Menu, MenuItem, ListItemText, Divider } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import {
  TasksStyledDeadlineButton,
  TasksStyledDatePickerContainer,
} from "./TasksDeadlineSelect.styled";
import { SelectOption } from "@components/FormAutocomplete";
import { EmptyCalendar } from "@assets/icons";
import { useTaskFilterOptions } from "@hooks/tasks/useTaskFilterOptions";
import dayjs from "dayjs";
import { DateRangePicker } from "@components/DateRangePicker";
import { ArrowDown } from "@assets/icons/Arrows";
import { useAppTranslation } from "@hooks";
import { Close } from "@assets/icons";

export const TaskDeadlineSelect = () => {
  const { deadlineOptions, mapDeadlineWithDate } = useTaskFilterOptions();
  const { control, setValue } = useFormContext();
  const { t } = useAppTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const selectedDeadline = useWatch({
    name: "deadline",
    control,
  });

  const dueDateFrom = useWatch({
    name: "dueDateFrom",
    control,
  });

  const dueDateTo = useWatch({
    name: "dueDateTo",
    control,
  });

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClearFilter = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setValue("deadline", null);
      setValue("dueDateFrom", null);
      setValue("dueDateTo", null);
      handleClose();
    },
    [setValue, handleClose],
  );

  const handleSelect = useCallback(
    ({ id }: SelectOption) => {
      setValue("deadline", id);

      if (id === "dateRange") {
        setValue("dueDateFrom", null);
        setValue("dueDateTo", null);
      }

      if (id !== "dateRange") {
        const { dueDateFrom, dueDateTo } = mapDeadlineWithDate(id);
        setValue("dueDateFrom", dueDateFrom || null);
        setValue("dueDateTo", dueDateTo || null);
        handleClose();
      }
    },
    [setValue, mapDeadlineWithDate, handleClose],
  );

  const handleRangeChange = useCallback(
    (dates: [Date | null, Date | null]) => {
      const [start, end] = dates;
      setValue("dueDateFrom", start ? dayjs(start).format("YYYY-MM-DD") : null);
      setValue("dueDateTo", end ? dayjs(end).format("YYYY-MM-DD") : null);
      if (start && end) {
        handleClose();
      }
    },
    [setValue, handleClose],
  );

  const renderButtonLabel = useMemo(() => {
    if (selectedDeadline === "dateRange") {
      if (dueDateFrom && dueDateTo) {
        return `${dayjs(dueDateFrom).format("DD/MM")} - ${dayjs(
          dueDateTo,
        ).format("DD/MM")}`;
      }
      return t("tasks.deadlineSelect.dateRange");
    }

    const selectedOption = deadlineOptions.find(
      opt => opt.id === selectedDeadline,
    );
    return selectedOption?.label || t("tasks.deadlineSelect.title");
  }, [selectedDeadline, deadlineOptions, dueDateFrom, dueDateTo, t]);

  return (
    <Box>
      <TasksStyledDeadlineButton onClick={handleClick}>
        <EmptyCalendar className="text-lg" />
        <p>{renderButtonLabel}</p>
        {selectedDeadline && (
          <span onClick={handleClearFilter}>
            <Close className="text-gray-500 cursor-pointer" />
          </span>
        )}
        <span className="flex items-center gap-x-3">
          <ArrowDown
            className={`${
              open ? "rotate-180" : "rotate-0"
            } transition-transform`}
          />
        </span>
      </TasksStyledDeadlineButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        {deadlineOptions.map(option => (
          <MenuItem
            key={option.id}
            selected={selectedDeadline === option.id}
            onClick={() => handleSelect(option)}
          >
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}

        {selectedDeadline === "dateRange" && (
          <Box>
            <Divider />
            <TasksStyledDatePickerContainer>
              <DateRangePicker
                startDate={dueDateFrom ? dayjs(dueDateFrom).toDate() : null}
                endDate={dueDateTo ? dayjs(dueDateTo).toDate() : null}
                onRangeChange={handleRangeChange}
                inline
                isFooterDisplayed={false}
              />
            </TasksStyledDatePickerContainer>
          </Box>
        )}
      </Menu>
    </Box>
  );
};
