import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import type { AxiosError, AxiosResponse } from "axios";
import type { QueryClient } from "@tanstack/react-query";
import { TaskType } from "@views/dietician/Tasks/components/TasksAccordion/TasksAccordion";
import { updateTaskStatus } from "@client/tasks/updateTaskStatus";
import type { UpdateTaskStatusRequestPayload } from "@client/tasks/updateTaskStatus";
import type { Task, TaskResponseSelected } from "@client/tasks/fetchTasks";
import { ApiEndpoints } from "@typeDefinitions";
import { useAppTranslation } from "@hooks/useAppTranslation";

interface UpdateTaskStatusContext {
  previousTodoTasks: TaskResponseSelected | undefined;
  previousCompletedTasks: TaskResponseSelected | undefined;
}

export const useUpdateTaskStatus = () => {
  const { t } = useAppTranslation();
  const queryClient: QueryClient = useQueryClient();

  const { mutateAsync } = useMutation<
    AxiosResponse,
    AxiosError,
    UpdateTaskStatusRequestPayload,
    UpdateTaskStatusContext
  >(data => updateTaskStatus(data), {
    onMutate: async (newStatus: UpdateTaskStatusRequestPayload) => {
      await queryClient.cancelQueries([[ApiEndpoints.Tasks, TaskType.Tag]]);

      const previousTodoTasks = queryClient.getQueryData<TaskResponseSelected>([
        ApiEndpoints.Tasks,
        TaskType.Todo,
      ]);

      const previousCompletedTasks =
        queryClient.getQueryData<TaskResponseSelected>([
          ApiEndpoints.Tasks,
          TaskType.Completed,
        ]);

      queryClient.setQueryData(
        [ApiEndpoints.Tasks, TaskType.Todo],
        (old: TaskResponseSelected | undefined) => {
          if (!old) return old;
          return {
            ...old,
            data: old.tasks.map((task: Task) =>
              task.id === newStatus.id
                ? { ...task, isDone: newStatus.isDone }
                : task,
            ),
          };
        },
      );

      queryClient.setQueryData(
        [ApiEndpoints.Tasks, TaskType.Completed],
        (old: TaskResponseSelected | undefined) => {
          if (!old) return old;
          return {
            ...old,
            data: old.tasks.map((task: Task) =>
              task.id === newStatus.id
                ? { ...task, isDone: newStatus.isDone }
                : task,
            ),
          };
        },
      );

      return { previousTodoTasks, previousCompletedTasks };
    },

    onError: (_, __, context) => {
      if (context?.previousTodoTasks) {
        queryClient.setQueryData(
          [ApiEndpoints.Tasks, TaskType.Todo],
          context.previousTodoTasks,
        );
      }
      if (context?.previousCompletedTasks) {
        queryClient.setQueryData(
          [ApiEndpoints.Tasks, TaskType.Completed],
          context.previousCompletedTasks,
        );
      }
      toast.error(t("tasks.updateStatusFailure"));
    },
    onSuccess: () => {
      queryClient.invalidateQueries([ApiEndpoints.Tasks]);
      toast.success(t("tasks.updateStatusSuccess"));
    },
  });

  const handleUpdateTaskStatus = async (
    payload: UpdateTaskStatusRequestPayload,
  ) => {
    try {
      await mutateAsync(payload);
    } catch {
      console.error(t("tasks.updateStatusFailure"));
    }
  };

  return { handleUpdateTaskStatus };
};
