import {
  UpdateCreatorDietTargetNutrientsRequest,
  updateCreatorDietTargetNutrients,
} from "@client/diets/creator";
import { MutationOptions } from "@hooks/queries/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchCreatorDietMealsQueryKey } from "./useFetchCreatorDietMealsQuery";
import { fetchCreatorDietQueryKey } from "./useFetchCreatorDietQuery";
import { useCreatorVersion } from "./useCreatorVersion";
import { searchDietsQueryKeyNew } from "@hooks/queries/useSearchDietsQueryNew";

export const useUpdateCreatorDietTargetNutrientsMutation = (
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();
  const { version } = useCreatorVersion();

  return useMutation<
    unknown,
    unknown,
    UpdateCreatorDietTargetNutrientsMutationIf
  >(
    ({ dietId, payload }) =>
      updateCreatorDietTargetNutrients(dietId, payload, version),
    {
      ...options,
      onSuccess: (d, v) => {
        options?.onSuccess && options.onSuccess(d, v);
        queryClient.invalidateQueries([
          fetchCreatorDietMealsQueryKey,
          v.dietId,
        ]);
        queryClient.invalidateQueries([fetchCreatorDietQueryKey, v.dietId]);
        queryClient.invalidateQueries([searchDietsQueryKeyNew]);
      },
    },
  );
};

interface UpdateCreatorDietTargetNutrientsMutationIf {
  dietId: string;
  payload: UpdateCreatorDietTargetNutrientsRequest;
}
