import { Card, styled } from "@mui/material";

export const CardTitleStyled = styled("span")`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
`;

export const QuestionnaireCardStyled = styled(Card)`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 1.5rem 1.5rem;
  border-radius: 1rem;
  min-width: 24.75rem;
  max-width: 41rem;
  box-shadow: ${({ theme }) => theme.boxShadows.card};
  background: ${({ theme }) => theme.gradients.survey};

  & .dragHandle {
    opacity: 0;
    cursor: pointer;
  }
  &:hover {
    & .dragHandle {
      opacity: 1;
    }
  }
`;

export const CardContentWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const DragHandleWrapper = styled("div")`
  display: flex;
  justify-content: center;
  rotate: 90deg;
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: grab;
`;

export const SpacingDiv = styled("div")`
  display: flex;
  justify-content: center;
  height: 1.5rem;
`;
