import { IconButton, Menu } from "@mui/material";
import { MouseEventHandler, ReactNode, useState } from "react";

interface DropMenuMuiProps {
  icon: ReactNode;
  children: ReactNode;
  size?: "small" | "medium" | "large";
}

export const DropMenuMui = ({ icon, children, size }: DropMenuMuiProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const closeMenu = () => setAnchorEl(null);

  return (
    <>
      <IconButton
        size={size}
        onClick={event => {
          event.stopPropagation();
          setAnchorEl(event.currentTarget);
        }}
      >
        {icon}
      </IconButton>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closeMenu}
        onClick={e => {
          e.stopPropagation();
          closeMenu();
        }}
      >
        {children}
      </Menu>
    </>
  );
};
