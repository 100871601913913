import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const SplitSeed = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="12" viewBox="0 0 14 12">
      <path
        d="M4.63247 5.53283C5.39306 6.00001 6.05208 6.61842 6.56892 7.34967C6.72691 7.5731 6.87135 7.80783 7 8.04932C7.12865 7.80557 7.27309 7.5731 7.43108 7.34967C7.94792 6.61842 8.60694 6.00001 9.36753 5.53283C10.3764 4.91217 11.5613 4.55557 12.8229 4.55557H13.0464C13.2969 4.55557 13.5 4.75869 13.5 5.00921C13.5 8.34949 10.7939 11.0556 7.45365 11.0556H7H6.54635C3.20608 11.0556 0.5 8.34949 0.5 5.00921C0.5 4.75869 0.703125 4.55557 0.953646 4.55557H1.17708C2.43872 4.55557 3.62361 4.91217 4.63247 5.53283ZM7.30469 1.07085C7.65903 1.45227 8.68368 2.69133 9.20955 4.78578C8.35191 5.27328 7.59809 5.9323 7 6.71772C6.40191 5.9323 5.64809 5.27553 4.79045 4.78578C5.31406 2.69133 6.33872 1.45227 6.69531 1.07085C6.77431 0.98734 6.8849 0.944458 7 0.944458C7.1151 0.944458 7.22569 0.98734 7.30469 1.07085Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
