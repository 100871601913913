import { useEffect } from "react";
import { useForm } from "react-hook-form";

import dayjs from "dayjs";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useAppTranslation } from "@hooks";
import {
  FetchClientResponse,
  UpdateClientRequest,
  activeSchema,
} from "@client";
import { POLISH_PHONE_PREFIX } from "@utils/clients";
import { parseInt } from "lodash";
import { emailSchemaFactory } from "@utils";

export const useUpdateClientForm = (defaultValues?: FetchClientResponse) => {
  const { t } = useAppTranslation();

  const resolver = yup.object().shape({
    firstName: yup.string().required(t("common.required_field")),
    lastName: yup.string().required(t("common.required_field")),
    email: emailSchemaFactory(t("common.email_invalid")),
    birthDate: yup
      .date()
      .nullable()
      .max(new Date(), t("common.tomorrow_error"))
      .typeError(t("common.invalid_date")),
    height: yup.lazy(value =>
      value === ""
        ? yup.string()
        : yup.number().typeError(t("common.wrong_value")).nullable(),
    ),
    fat: yup.lazy(value =>
      value === ""
        ? yup.string()
        : yup.number().typeError(t("common.wrong_value")).nullable(),
    ),
    weight: yup.lazy(value =>
      value === ""
        ? yup.string()
        : yup.number().typeError(t("common.wrong_value")).nullable(),
    ),
    targetWeight: yup.lazy(value =>
      value === ""
        ? yup.string()
        : yup.number().typeError(t("common.wrong_value")).nullable(),
    ),
    additionalFeature: yup.number().nullable(),
    goal: yup.number().nullable(),
    streetName: yup.string().nullable(),
    streetNumber: yup.string().nullable(),
    houseNumber: yup.string().nullable(),
    city: yup.string().nullable(),
    postcode: yup.string().nullable(),
    source: yup.number().nullable(),
    description: yup.string().nullable(),
    lang: yup
      .mixed()
      .oneOf(Object.values(Lang))
      .required(t("common.required_field")),
    sex: yup.mixed().oneOf(Object.values(Sexes)).nullable().default(null),
    dietitian: yup.string().nullable(),
    facility: yup.string().nullable(),
    active: activeSchema.nullable().default(null),
    phonePrefix: yup.string().nullable(),
    phoneNumber: yup.string().nullable(),
  });

  const form = useForm<UpdateClientFormInput>({
    defaultValues: mapUpdateClientForm(defaultValues),
    resolver: yupResolver(resolver),
  });

  useEffect(() => {
    if (defaultValues) form.reset(mapUpdateClientForm(defaultValues));
  }, [defaultValues]);

  return form;
};

export interface UpdateClientFormInput {
  firstName: string;
  lastName: string;
  email: string | null;
  birthDate: string | null;
  height?: string;
  fat?: string;
  weight?: string;
  targetWeight?: string;
  additionalFeature?: number;
  goal?: number | null;
  phonePrefix?: string;
  phoneNumber: string | null;
  streetName: string | null;
  streetNumber: string | null;
  houseNumber: string | null;
  city: string | null;
  postcode: string | null;
  source?: number;
  description: string | null;
  lang: Lang;
  sex: Sexes | null;
  dietitian: string | null;
  facility: string | null;
  until: string | null;
  mobileApp: boolean | null;
}

enum Lang {
  pl = "pl",
  en = "en",
}

export enum Sexes {
  m = "m",
  w = "w",
  n = "",
}

export const mapUpdateClientForm = (
  data?: FetchClientResponse,
): UpdateClientFormInput | undefined => {
  if (!data) return;
  return {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email ?? null,
    birthDate: data.profile.birthDate,
    height: data.profile.height?.toString(),
    fat: data.profile.bodyFat?.toString(),
    weight: data.profile.weight?.toString(),
    targetWeight: data.profile.targetWeight?.toString(),
    additionalFeature: data.profile.additionalFeatures[0]?.id ?? null,
    goal: data.profile.targets[0]?.id ?? null,
    phonePrefix: data.phone.prefix ?? POLISH_PHONE_PREFIX,
    phoneNumber: data.phone.number,
    streetName: data.profile.address.street,
    streetNumber: data.profile.address.streetNumber,
    houseNumber: data.profile.address.houseNumber,
    city: data.profile.address.city,
    postcode: data.profile.address.postcode,
    source: data.profile.sources[0]?.id ?? null,
    description: data.profile.description,
    lang: Lang[data.profile.lang],
    sex: (data.profile.sex ? data.profile.sex : Sexes.n) as Sexes,
    dietitian: data.dietitian?.id.toString() ?? null,
    facility: data.facility?.id.toString() ?? null,
    until: data.active?.until ?? null,
    mobileApp: data.active?.mobileApp ?? null,
  };
};

export const mapUpdateClientRequest = (
  data: UpdateClientFormInput,
): UpdateClientRequest => {
  return {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    lang: data.lang,
    sex: data.sex === "" ? null : data.sex,
    birthDate: dayjs(data.birthDate).isValid()
      ? dayjs(data.birthDate).format("YYYY-MM-DD")
      : null,
    height: parseFloat(data.height ?? "") ?? null,
    bodyFat: parseFloat(data.fat ?? "") ?? null,
    weight: parseFloat(data.weight ?? "") ?? null,
    targetWeight: parseFloat(data.targetWeight ?? "") ?? null,
    additionalFeatures: data.additionalFeature
      ? [{ id: data.additionalFeature }]
      : [],
    targets: data.goal ? [{ id: data.goal }] : [],
    phone:
      data.phonePrefix && data.phoneNumber
        ? {
            prefix: data.phonePrefix,
            number: data.phoneNumber,
          }
        : {
            prefix: null,
            number: null,
          },
    address: {
      street: data.streetName ?? null,
      streetNumber: data.streetNumber ?? null,
      houseNumber: data.houseNumber ?? null,
      city: data.city ?? null,
      postcode: data.postcode ?? null,
    },
    sources: data.source ? [{ id: data.source }] : [],
    description: data.description ?? null,
    dietitian: { id: parseInt(data.dietitian ?? "") ?? null },
    facility: { id: parseInt(data.facility ?? "") ?? null },
  };
};
