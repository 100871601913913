import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const ArrowLeft = (props: SvgIconProps) => (
  <SvgIcon {...props} fill={props.fill} viewBox="0 0 16.5 14.17">
    <g
      id="arrow-prewiev_2_left"
      data-name="arrow-prewiev 2 left"
      transform="translate(0.75 13.11) rotate(-90)"
    >
      <path
        id="Stroke_1"
        data-name="Stroke 1"
        d="M0,0V15"
        transform="translate(6.025 0)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        fill="none"
        stroke={props.fill ? props.fill : "currentColor"}
      />
      <path
        id="Stroke_3"
        data-name="Stroke 3"
        d="M12.049,6.05,6.025,0,0,6.05"
        transform="translate(0 0)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        fill="none"
        stroke={props.fill ? props.fill : "currentColor"}
      />
    </g>
  </SvgIcon>
);
