import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Bookmark = (props: SvgIconProps) => {
  return (
    <SvgIcon width="12" height="16" viewBox="0 0 12 16" {...props}>
      <path
        d="M0 1.50076C0 0.672638 0.671875 0.000762939 1.5 0.000762939V1.50076V13.7945L5.56563 10.8914C5.825 10.7039 6.17812 10.7039 6.4375 10.8914L10.5 13.7945V1.50076H1.5V0.000762939H10.5C11.3281 0.000762939 12 0.672638 12 1.50076V15.2508C12 15.532 11.8438 15.7883 11.5938 15.9164C11.3438 16.0445 11.0437 16.0226 10.8156 15.8601L6 12.4226L1.18438 15.8601C0.95625 16.0226 0.65625 16.0445 0.40625 15.9164C0.15625 15.7883 0 15.532 0 15.2508V1.50076Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
