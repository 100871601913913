import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Document = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="18" viewBox="0 0 14 18" {...props}>
      <path
        fill="none"
        d="M8.55692 1.21652H2.33013C1.91727 1.21652 1.52132 1.38053 1.22938 1.67247C0.937446 1.9644 0.773438 2.36036 0.773438 2.77322V15.2268C0.773438 15.6396 0.937446 16.0356 1.22938 16.3275C1.52132 16.6195 1.91727 16.7835 2.33013 16.7835H11.6703C12.0832 16.7835 12.4791 16.6195 12.7711 16.3275C13.063 16.0356 13.227 15.6396 13.227 15.2268V5.88661L8.55692 1.21652Z"
        stroke={props.fill ?? "currentColor"}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="none"
        d="M8.55664 1.21652V5.88661H13.2267"
        stroke={props.fill ?? "currentColor"}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="none"
        d="M10.1135 9.77835H3.88672"
        stroke={props.fill ?? "currentColor"}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="none"
        d="M10.1135 12.8917H3.88672"
        stroke={props.fill ?? "currentColor"}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="none"
        d="M5.44341 6.66495H4.66507H3.88672"
        stroke={props.fill ?? "currentColor"}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
