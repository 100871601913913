import { useFormContext } from "react-hook-form";
import { ProgramFiltersFormPops } from "@views/dietician/Programs/ProgramsFiltersFormType";
import { useMemo } from "react";
import { SearchProgramsPaginationParams } from "@client/searchProgramsNew";
import keys from "lodash/keys";
import pickBy from "lodash/pickBy";
import { DEFAULT_PROGRAMS_MACROS_RANGE } from "@components/ProgramsFilters";

const useFormDataMapper = () => {
  const { watch } = useFormContext<ProgramFiltersFormPops>();
  const values = watch();

  const requestParams = useMemo(() => {
    return mapFormValuesToRequestParams(values);
  }, [JSON.stringify(values)]);

  return {
    requestParams,
  };
};

const mapFormValuesToRequestParams = (
  values: ProgramFiltersFormPops,
): SearchProgramsPaginationParams => {
  return {
    page: values.page + 1,
    perPage: values.perPage,
    query: values.query,
    typeDays: values.typeDays,
    authorId: values.authorId,
    days: values.days,
    tags: [
      ...(values.tags ?? []),
      ...(values.diet_allergen_tags ?? []),
      ...(values.diet_elimination_tags ?? []),
      ...(values.diet_type_tags ?? []),
    ],
    numberOfDietMeals: values.numberOfDietMeals,
    owner: values.own ? "own" : undefined,
    reviewed: values.reviewed ? 1 : undefined,
    favorite: values.favorite ? 1 : undefined,
    latest: values.latest ? 1 : undefined,
    nutrients: values.nutrients
      ? keys(pickBy(values.nutrients, correctNutrientValuesFilter)).map(
          nutrientId => {
            if (!values.nutrients) {
              throw new Error("Whoops");
            }

            const nutrientValues = values.nutrients[nutrientId];
            if (!nutrientValues) {
              throw new Error("Whoops");
            }

            return {
              id: parseInt(nutrientId),
              from: nutrientValues[0],
              to: nutrientValues[1],
            };
          },
        )
      : [],
  };
};

const correctNutrientValuesFilter = (
  nutrientValues: [number, number],
  key: string,
) => {
  if (!nutrientValues) {
    return false;
  }

  if (nutrientValues.length < 2) {
    return false;
  }

  if (nutrientValues[0] === 0 && nutrientValues[1] === 0) {
    return false;
  }

  const defaultNutrientValues = DEFAULT_PROGRAMS_MACROS_RANGE[key];
  if (!defaultNutrientValues) {
    return false;
  }

  if (
    nutrientValues[0] === defaultNutrientValues[0] &&
    nutrientValues[1] === defaultNutrientValues[1]
  ) {
    return false;
  }

  return true;
};

export default useFormDataMapper;
