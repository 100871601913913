import { SubscriptionPurchaseRequest } from "@client/resources/SubscriptionPurchaseRequest";
import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const postStoreBillData = async (payload: StoreBillDataRequest) => {
  await fetchData(
    "/dietitian/clinic/store-bill-data",
    APIMethods.POST,
    payload,
  );
};

export interface StoreBillDataRequest extends SubscriptionPurchaseRequest {
  subscriptionType: "scale";
  paymentType: "year";
}
