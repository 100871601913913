import { MouseEventHandler, ReactNode } from "react";

import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions } from "@mui/material";

import { Close } from "@assets/icons/DesignSystem";
import { useAppTranslation } from "@hooks";

import {
  DialogContentStyled,
  DialogTitleStyled,
  IconButtonStyled,
} from "./ModalWrapper.styled";

interface ModalWrapperProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  title: string | ReactNode;
  children: ReactNode | string;
  cancelText?: string;
  confirmText?: string;
  loading?: boolean;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | undefined;
  id?: string;
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
  disabled?: boolean;
  showCloseButton?: boolean;
  header?: ReactNode;
  footer?: ReactNode;
}

export const ModalWrapper = ({
  children,
  open,
  onClose,
  onSubmit,
  title,
  confirmText,
  cancelText,
  loading,
  color,
  id,
  onClick,
  disabled,
  showCloseButton = true,
  header,
  footer,
}: ModalWrapperProps) => {
  const { t } = useAppTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onClick={onClick}
      TransitionProps={{ unmountOnExit: true }}
      id={id}
    >
      {typeof title === "string" ? (
        <DialogTitleStyled compact={!showCloseButton}>
          {title}
        </DialogTitleStyled>
      ) : (
        title
      )}

      {showCloseButton && (
        <IconButtonStyled onClick={onClose} size="small">
          <Close size="w-[12px] h-[12px]" />
        </IconButtonStyled>
      )}

      {header}

      <DialogContentStyled>{children}</DialogContentStyled>

      <DialogActions>
        {footer}

        <Button variant="outlined" onClick={onClose}>
          {cancelText ?? t("common.cancel")}
        </Button>
        <LoadingButton
          variant="contained"
          color={color}
          onClick={onSubmit}
          loading={loading}
          disabled={disabled}
        >
          {confirmText ?? t("common.save")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
