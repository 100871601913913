import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { MutationOptions } from "./types";
import { toast } from "react-toastify";
import { requestPasswordRetrieval } from "@client";

export function useRequestPasswordRetrievalMutation(options?: MutationOptions) {
  const { t } = useTranslation();

  return useMutation(
    (data: { email: string }) => requestPasswordRetrieval(data),
    {
      ...options,
      onSuccess: () => {
        toast.success(t("auth.retrieve.success"));
        options?.onSuccess && options.onSuccess();
      },
    },
  );
}
