import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import { string, number, object, array } from "yup";

export const fetchGoogleCalendarList = async (): Promise<
  ApiResponse<CalendarResourceDto[]>
> => {
  const response = await fetchData(
    "/dietitian/oauth/google/get-calendar-list",
    APIMethods.GET,
  );

  return responseSchema.validate(response);
};

export interface CalendarResourceDto {
  id: string;
  active: number;
  pushActive: number;
  name: string;
}

const calendarResourceSchema = object({
  id: string().required(),
  active: number().required(),
  pushActive: number().required(),
  name: string().required(),
});

const responseSchema = object({
  data: array().of(calendarResourceSchema).defined(),
});
