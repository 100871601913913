import { useCallback } from "react";
import { Clock, Cutlery, PieChart, PieChartOutlined } from "@assets/icons";
import { TagCategoryType } from "@utils/tagsNew";
import { SpecialTagIf } from "@components/TagWithIcon";
import { SvgIconProps } from "@components/SvgIcon";

interface Tag {
  id: string;
  label: string;
  Icon: (props: SvgIconProps) => JSX.Element;
}

type TagCategoryDictionary = Map<number, { tags: { id: number }[] }>;
type TagDictionary = Map<number, string>;

export const useSpecialTags = (
  tagCategoryDictionary: TagCategoryDictionary,
  tagDictionary: TagDictionary,
  t: (key: string, options?: Record<string, any>) => string,
) => {
  type DefinedLabelSpecialTagIf = SpecialTagIf & { label: string };

  return useCallback(
    (tags: number[], totalServings: number, servings: number): Tag[] => {
      const findTag = (categoryType: TagCategoryType) => {
        const tagList = tagCategoryDictionary.get(categoryType)?.tags;
        return tags?.find(tag => tagList?.some(t => t.id === tag));
      };

      const preparationTimeTag = findTag(TagCategoryType.PREPARATION_TIME);
      const difficultyOfPreparationTag = findTag(
        TagCategoryType.DIFFICULTY_OF_PREPARATION,
      );

      const preparationTimeName =
        preparationTimeTag && tagDictionary.get(preparationTimeTag);
      const difficultyOfPreparationName =
        difficultyOfPreparationTag &&
        tagDictionary.get(difficultyOfPreparationTag);

      const servingsLabel =
        totalServings > 1 &&
        totalServings === servings &&
        `${t("recipe.recipe_complex", { count: totalServings })}`;
      const selectedServingsLabel =
        totalServings > 1 &&
        totalServings !== servings &&
        `${servings}/${totalServings} ${t("recipe.portions")}`;

      return [
        { id: "1", label: preparationTimeName, Icon: Clock },
        { id: "2", label: difficultyOfPreparationName, Icon: Cutlery },
        { id: "3", label: servingsLabel, Icon: PieChart },
        { id: "4", label: selectedServingsLabel, Icon: PieChartOutlined },
      ].filter((tag): tag is DefinedLabelSpecialTagIf => !!tag.label);
    },
    [tagCategoryDictionary, tagDictionary, t],
  );
};
