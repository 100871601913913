import { CustomChip } from "./AddQuestionnaireModal.styled";
import { CheckNew } from "@assets/icons";

interface OptionIf {
  id: number;
  label: string;
}
interface ChipsSelectProps {
  options: OptionIf[];
  selectedId: number;
  onChange: (id: number) => void;
}
export const ChipsSelect = ({
  options,
  selectedId,
  onChange,
}: ChipsSelectProps) => {
  return (
    <div className="flex gap-[6px]">
      {options.map(({ id, label }) => {
        const isSelected = selectedId === id;

        return (
          <CustomChip
            key={id}
            color={isSelected ? "primary" : "default"}
            onClick={() => onChange(id)}
            variant="outlined"
            label={
              <div className="flex items-center gap-2">
                {isSelected && <CheckNew size="w-3 h-3" />}
                {label}
              </div>
            }
          />
        );
      })}
    </div>
  );
};
