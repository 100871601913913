import { CalendarResourceDto, fetchGoogleCalendarList } from "@client/oauth";
import { useQuery } from "@tanstack/react-query";
import { ApiResponse } from "@typeDefinitions";
import { QueryOptionsTyped } from "../types";

export const fetchGoogleCalendarListQueryKey =
  "fetchGoogleCalendarListQueryKey";

export const useFetchGoogleCalendarListQuery = (
  options?: QueryOptionsTyped<ApiResponse<CalendarResourceDto[]>>,
) => {
  const { data, ...rest } = useQuery({
    queryFn: fetchGoogleCalendarList,
    queryKey: [fetchGoogleCalendarListQueryKey],
    ...options,
  });

  return { data: data?.data, ...rest };
};
