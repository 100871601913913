import { nutrientsCategories } from "@client/dictionaries";
import { useQuery } from "@tanstack/react-query";
import { QueryOptionsTyped } from "@hooks/queries";
import { CategoryNutrientsResponse } from "@typeDefinitions/responses/dictionaries";

export const categoriesNutrientsFetchQueryKey =
  "categoriesNutrientsFetchQueryKey";

export function useCategoriesNutrientsFetchQuery(
  options?: QueryOptionsTyped<CategoryNutrientsResponse[]>,
) {
  return useQuery({
    queryKey: [categoriesNutrientsFetchQueryKey],
    queryFn: nutrientsCategories,
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    ...options,
  });
}
