import {
  AccordionContent,
  AccordionSummaryStyled,
  SectionTitle,
  StyledAccordion,
} from "./MeasurementSection.styled";
import { useAppTranslation } from "@hooks";
import { ClientProfileCardItem } from "@views/dietician/ClientProfile/components/ClientProfileCardItem";
import { CheckedIcon } from "@assets/icons";
import { Control } from "react-hook-form";
import { FormTextField } from "@components/FormTextField";
import { MuiTimeDatePicker } from "@components/MuiTimeDatePicker";
import { useFetchClientLastMeasurementQuery } from "@hooks/queries";
import { SectionWrapper } from "../../AddClientMeasurementModal.styled";
import { useEmptyDataString } from "@hooks/helpers";
import { decimalInput1ThreeDigits } from "@utils/inputs";

interface MeasurementSectionProps {
  control: Control<any>;
  id: string;
}

export const MeasurementSection = ({
  control,
  id,
}: MeasurementSectionProps) => {
  const { t } = useAppTranslation();
  const { lastMeasurement } = useFetchClientLastMeasurementQuery(id);

  return (
    <SectionWrapper>
      <ClientProfileCardItem
        title={t("client_profile.health.measurement_date")}
        extraEl={
          <MuiTimeDatePicker dateVersion name="date" control={control} />
        }
      />
      <ClientProfileCardItem
        title={t("client_profile.health.height")}
        extraEl={
          <FormTextField
            control={control}
            name="growth"
            size="small"
            variant="outlined"
            placeholder={
              useEmptyDataString(lastMeasurement?.growth, "cm") ??
              t("client_profile.health.empty_placeholders.type_height_in_cm")
            }
            InputProps={{
              inputComponent: decimalInput1ThreeDigits,
            }}
          />
        }
      />
      <ClientProfileCardItem
        title={t("client_profile.health.weight")}
        extraEl={
          <FormTextField
            control={control}
            name="weight"
            size="small"
            variant="outlined"
            placeholder={
              useEmptyDataString(lastMeasurement?.weight, "kg") ??
              t("client_profile.health.empty_placeholders.type_weight_in_kg")
            }
            InputProps={{
              inputComponent: decimalInput1ThreeDigits,
            }}
          />
        }
      />
      <ClientProfileCardItem
        title={t("client_profile.health.body_fat")}
        extraEl={
          <FormTextField
            control={control}
            name="bodyFatLevel"
            size="small"
            variant="outlined"
            placeholder={
              useEmptyDataString(lastMeasurement?.bodyFatLevel, "%") ??
              t("client_profile.health.empty_placeholders.type_body_fat_in_%")
            }
            InputProps={{
              inputComponent: decimalInput1ThreeDigits,
            }}
          />
        }
      />

      <StyledAccordion disableGutters>
        <AccordionSummaryStyled expandIcon={<CheckedIcon />}>
          {t("client_profile.health.body_circumferences")}
        </AccordionSummaryStyled>

        <AccordionContent>
          <div className="grid gap-6">
            <SectionTitle>
              {t("client_profile.health.circumferences")}
            </SectionTitle>
            <ClientProfileCardItem
              title={t("client_profile.health.arm")}
              extraEl={
                <FormTextField
                  control={control}
                  name="arm"
                  size="small"
                  variant="outlined"
                  placeholder={
                    useEmptyDataString(lastMeasurement?.arm, "cm") ??
                    t(
                      "client_profile.health.empty_placeholders.type_circumference_in_cm",
                    )
                  }
                  InputProps={{
                    inputComponent: decimalInput1ThreeDigits,
                  }}
                />
              }
            />
            <ClientProfileCardItem
              title={t("client_profile.health.biceps")}
              extraEl={
                <FormTextField
                  control={control}
                  name="tightBiceps"
                  size="small"
                  variant="outlined"
                  placeholder={
                    useEmptyDataString(lastMeasurement?.tightBiceps, "cm") ??
                    t(
                      "client_profile.health.empty_placeholders.type_circumference_in_cm",
                    )
                  }
                  InputProps={{
                    inputComponent: decimalInput1ThreeDigits,
                  }}
                />
              }
            />
            <ClientProfileCardItem
              title={t("client_profile.health.waist")}
              extraEl={
                <FormTextField
                  control={control}
                  name="waist"
                  size="small"
                  variant="outlined"
                  placeholder={
                    useEmptyDataString(lastMeasurement?.waist, "cm") ??
                    t(
                      "client_profile.health.empty_placeholders.type_circumference_in_cm",
                    )
                  }
                  InputProps={{
                    inputComponent: decimalInput1ThreeDigits,
                  }}
                />
              }
            />
            <ClientProfileCardItem
              title={t("client_profile.health.hips")}
              extraEl={
                <FormTextField
                  control={control}
                  name="hip"
                  size="small"
                  variant="outlined"
                  placeholder={
                    useEmptyDataString(lastMeasurement?.hip, "cm") ??
                    t(
                      "client_profile.health.empty_placeholders.type_circumference_in_cm",
                    )
                  }
                  InputProps={{
                    inputComponent: decimalInput1ThreeDigits,
                  }}
                />
              }
            />
            <ClientProfileCardItem
              title={t("client_profile.health.thigh")}
              extraEl={
                <FormTextField
                  control={control}
                  name="thigh"
                  size="small"
                  variant="outlined"
                  placeholder={
                    useEmptyDataString(lastMeasurement?.thigh, "cm") ??
                    t(
                      "client_profile.health.empty_placeholders.type_circumference_in_cm",
                    )
                  }
                  InputProps={{
                    inputComponent: decimalInput1ThreeDigits,
                  }}
                />
              }
            />
            <ClientProfileCardItem
              title={t("client_profile.health.calf")}
              extraEl={
                <FormTextField
                  control={control}
                  name="calf"
                  size="small"
                  variant="outlined"
                  placeholder={
                    useEmptyDataString(lastMeasurement?.calf, "cm") ??
                    t(
                      "client_profile.health.empty_placeholders.type_circumference_in_cm",
                    )
                  }
                  InputProps={{
                    inputComponent: decimalInput1ThreeDigits,
                  }}
                />
              }
            />
          </div>
        </AccordionContent>
      </StyledAccordion>
    </SectionWrapper>
  );
};
