import { Header } from "@layouts/Landing/components";
import {
  Content,
  Wrapper,
  Title,
  StyledButton,
  ContactUsSection,
} from "./NotSupportedSizeLayout.styled";
import { useAppTranslation } from "@hooks";
import img from "@assets/img/MobileSizeLayout.png";
import { ThemeProviderWrapperNew } from "themeNew";
import { Divider, Typography } from "@mui/material";
import { PaperPlane } from "@assets/icons";

export const NotSupportedSizeLayout = () => {
  const { t } = useAppTranslation();
  return (
    <ThemeProviderWrapperNew>
      <Header />

      <Wrapper>
        <div>
          <img className="my-0 mx-auto" src={img} width={163} height={164} />
          <Title className="mt-[48px]">{t("small_screen.title")}</Title>
          <Content className="mt-[24px]">{t("small_screen.content")}</Content>
        </div>

        <ContactUsSection>
          <Divider className="w-full " />
          <Typography fontSize={16} fontWeight={600} lineHeight="20px">
            {t("small_screen.any_questions")}
          </Typography>
          <Typography fontSize={16} lineHeight="24px">
            {t("small_screen.contact_us")}
          </Typography>
          <StyledButton
            size="small"
            href="mailto:hello@alloweat.com"
            startIcon={<PaperPlane />}
          >
            hello@alloweat.com
          </StyledButton>
        </ContactUsSection>
      </Wrapper>
    </ThemeProviderWrapperNew>
  );
};
