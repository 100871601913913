import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import {
  ProductReplacementDto,
  productReplacementSchema,
} from "./searchDietProductsForReplacement";
import {
  MainProductCategoryDto,
  mainProductCategorySchema,
} from "@client/common";

export const fetchDietsProductsForReplacement = async (
  dietId: string,
): Promise<ApiResponse<CategoryDto[]>> => {
  const response = await fetchData(
    `/dietitian/diets/${dietId}/products-for-replacement`,
    APIMethods.GET,
  );

  return responseSchema.validate(response);
};

export interface CategoryDto {
  mainCategory: MainProductCategoryDto;
  products: ProductReplacementDto[];
}

const categorySchema = yup.object().shape({
  mainCategory: mainProductCategorySchema.required(),
  products: yup.array().of(productReplacementSchema).required(),
});

const responseSchema = yup.object().shape({
  data: yup.array().of(categorySchema).required(),
});
