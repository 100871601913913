import {
  NutrientNormLabel,
  SectionItemLabel,
  SectionItemLayout,
  SectionItemTextValue,
  TabSectionLayout,
} from "@views/dietician/ClientHealth/ClientHealthNew.styled";
import { useAppTranslation, useClientParams } from "@hooks";
import { getNormDescriptionByAgeId, useFetchNormsQuery } from "@hooks/queries";
import useFetchPatientNormNutrients from "@hooks/queries/client/nutrient/useFetchPatientNormNutrients";
import { useMemo } from "react";
import { Chip, Stack } from "@mui/material";

const NutritionalNormItemSection = () => {
  const { t, isPolishChosen } = useAppTranslation();
  const id = useClientParams();
  const { norms } = useFetchNormsQuery();
  const { data } = useFetchPatientNormNutrients({ patientId: id });

  const label = useMemo(() => {
    if (!norms || !data) {
      return "";
    }

    const patientNorm = data.data.norm;
    if (!patientNorm) {
      return "";
    }

    const norm = getNormDescriptionByAgeId(norms, patientNorm.id);
    if (!norm.location || !norm.age) {
      return "";
    }

    return `${isPolishChosen ? norm.age.name : norm.age.nameEn}, ${
      norm.location.name
    }`;
  }, [norms, data, isPolishChosen]);

  return (
    <SectionItemLayout>
      <SectionItemLabel>
        {t(
          "patient.health_and_goal.dietary_profile_tab.section_dietary_profile.dietary_standard",
        )}
      </SectionItemLabel>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <SectionItemTextValue>{label}</SectionItemTextValue>
        {data?.data.isEdited && (
          <Chip
            color="info"
            label={t(
              "patient.health_and_goal.dietary_profile_tab.section_dietary_profile.norm_edited",
            )}
          />
        )}
      </Stack>
    </SectionItemLayout>
  );
};

export default NutritionalNormItemSection;
