import { FormControlLabel, styled } from "@mui/material";

export const RecordWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 16px;
  background: ${({ theme }) => theme.palette.primary.light};
  border-radius: 16px;
  width: 100%;
`;

export const Title = styled("span")`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const FormControlLabelStyled = styled(FormControlLabel)`
  border: 1px solid ${({ theme }) => theme.colors.neutral.light[200]};
  padding: 8px 12px;
  border-radius: 8px;
  width: 100%;
  margin-right: unset;
  margin-left: unset;
`;
