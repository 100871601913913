import { useState } from "react";
import { useForm } from "react-hook-form";

import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import { useAppTranslation } from "@hooks/useAppTranslation";

import { RegisterFormInput } from "@typeDefinitions/types";
import { UserTypes } from "@typeDefinitions";
import { FormInput } from "@components/FormInput";
import { Button } from "@components/Button";
import { FormCheckbox } from "@components/FormCheckbox";
import { Link } from "@components/Link";
import { LOGIN } from "@routes";
import { Show } from "@assets/icons";

export const RegisterForm = (props: RegisterFormProps) => {
  const { onSubmit, isLoading } = props;
  const { t } = useAppTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterFormInput>({
    resolver: yupResolver(registerFormSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      passwordConfirmation: "",
      policy: false,
      consent: false,
      registerAs: UserTypes.DIETICIAN,
      clinicName: "",
    },
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showpasswordConfirmation, setShowpasswordConfirmation] =
    useState<boolean>(false);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col items-center mt-8 w-full"
    >
      <div className="flex flex-col w-full">
        <p className="mb-2 mt-6 w-full">{t("auth.register.first_name")}</p>
        <FormInput
          control={control}
          name="firstName"
          error={errors?.firstName?.message}
          placeholder={t("auth.register.first_name")}
          variant="outlined"
          className="w-full"
        />
      </div>

      <div className="flex flex-col w-full">
        <p className="mb-2 mt-6 w-full">{t("auth.register.last_name")}</p>
        <FormInput
          control={control}
          name="lastName"
          error={errors?.lastName?.message}
          placeholder={t("auth.register.last_name")}
          variant="outlined"
          className="w-full"
        />
      </div>

      <div className="flex flex-col w-full">
        <p className="mb-2 mt-6 w-full">{t("auth.register.email")}</p>
        <FormInput
          control={control}
          name="email"
          error={errors?.email?.message}
          placeholder={t("auth.register.email_placeholder")}
          variant="outlined"
          className="w-full"
        />
      </div>

      <div className="flex flex-col w-full">
        <p className="mb-2 mt-6 w-full">{t("auth.register.phone")}</p>
        <FormInput
          control={control}
          name="phone"
          error={errors?.phone?.message}
          placeholder={t("auth.register.phone_placeholder")}
          variant="outlined"
          className="w-full"
        />
      </div>

      <div className="flex flex-col w-full">
        <p className="mb-2 mt-6 w-full">{t("auth.register.password")}</p>
        <FormInput
          control={control}
          name="password"
          type={showPassword ? "text" : "password"}
          error={errors?.password?.message}
          placeholder={t("auth.register.password")}
          variant="outlined"
          className="w-full"
          suffix={
            <div className="flex items-center text-gray-700">
              <span className="font-roman mr-1">
                {showPassword ? t("common.hide") : t("common.show")}
              </span>
              <div
                className="p-2 cursor-pointer"
                onClick={() => setShowPassword(prev => !prev)}
              >
                <Show size="w-4 h-4" />
              </div>
            </div>
          }
        />
      </div>

      <div className="flex flex-col w-full">
        <p className="mb-2 mt-6 w-full">{t("auth.register.password_repeat")}</p>
        <FormInput
          control={control}
          name="passwordConfirmation"
          type={showpasswordConfirmation ? "text" : "password"}
          error={errors?.passwordConfirmation?.message}
          placeholder={t("auth.register.password_repeat")}
          variant="outlined"
          className="w-full"
          suffix={
            <div className="flex items-center text-gray-700">
              <span className="font-roman mr-1">
                {showpasswordConfirmation ? t("common.hide") : t("common.show")}
              </span>
              <div
                className="p-2 cursor-pointer"
                onClick={() => setShowpasswordConfirmation(prev => !prev)}
              >
                <Show size="w-4 h-4" />
              </div>
            </div>
          }
        />
      </div>

      <div className="flex flex-col w-full mb-4">
        <p className="mb-2 mt-6 w-full">{t("auth.register.clinic_name")}</p>
        <FormInput
          control={control}
          name="clinicName"
          error={errors?.clinicName?.message}
          placeholder={t("auth.register.clinic_name_placeholder")}
          variant="outlined"
          className="w-full"
        />
      </div>

      <div className="flex items-center w-full mb-4">
        <FormCheckbox
          name="policy"
          control={control}
          error={errors?.policy?.message}
          label={<span>{t("auth.register.policy_label")}</span>}
          className="mr-1"
        />
        <span className="font-roman text-sm">
          <Link color="primary" to="/auth/policy">
            {t("auth.register.policy_link")}
          </Link>
        </span>
      </div>

      <div className="flex flex-col w-full">
        <FormCheckbox
          name="consent"
          control={control}
          label={t("auth.register.consent")}
        />
      </div>

      <Button
        submit
        isLoading={isLoading}
        className="mt-8 min-w-1/2"
        size="large"
      >
        {t("auth.register.register")}
      </Button>

      <Link color="primary" className="mt-4" to={LOGIN}>
        {t("auth.register.back")}
      </Link>
    </form>
  );
};

const registerFormSchema = yup.object().shape({
  firstName: yup.string().required("Imię jest wymagane"),
  lastName: yup.string().required("Nazwisko jest wymagane"),
  email: yup
    .string()
    .trim()
    .email("Niepoprawny format maila")
    .required("Email jest wymagany"),
  phone: yup
    .string()
    .required("Numer telefonu jest wymagany")
    .trim()
    .matches(/\d{9}/, "Niepoprawny format"),
  password: yup.string().required("Hasło jest wymagane"),
  passwordConfirmation: yup
    .string()
    .required("Hasło jest wymagane")
    .oneOf([yup.ref("password"), ""], "Hasła nie pasują"),
  clinicName: yup.string().required("Nazwa kliniki jest wymagana"),
  policy: yup.boolean().oneOf([true], "Nalezy zaznaczyć pole"),
  consent: yup.boolean(),
  registerAs: yup.string().required("Nalezy wybrać rolę"),
});

interface RegisterFormProps {
  /** form submit handler */
  onSubmit: (data: RegisterFormInput) => void;
  /** loading state */
  isLoading: boolean;
}
