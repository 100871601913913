import { TabPanel } from "@mui/lab";
import { styled } from "@mui/material";

export const Wrapper = styled("div")`
  width: 780px;
  position: relative;
`;

export const DetailsWrapper = styled("div")`
  display: grid;
  gap: 18px;
  position: sticky;
  top: 0;
  z-index: 1;
  background: ${({ theme }) => theme.palette.primary.light};
  padding: 16px 16px 0 16px;
`;

export const LoadingWrapper = styled("div")`
  display: grid;
  place-items: center;
  height: 100vh;
`;

export const TabPanelStyled = styled(TabPanel)`
  min-height: max-content;
  &.MuiTabPanel-root {
    padding: 16px;
  }
`;

export const TagIconWrapper = styled("div")`
  display: flex;
  gap: 6px;
  align-items: center;
`;

export const ImgStyled = styled("img")`
  height: 220px;
  width: 100%;
  object-fit: cover;
`;
