import { Box, CircularProgress, Stack } from "@mui/material";
import {
  Circle,
  LegendLabel,
  ValueLegendLabel,
  TitleWrapper,
} from "@views/dietician/PatientMonitoring2/components/MealsBox/MealBoxByDays.styled";
import PercentCircle from "@views/dietician/PatientMonitoring2/components/PercentCircle";
import { ReactNode } from "react";
import { useAppTranslation } from "@hooks";

type MealsBoxByDaysItemProps = {
  icon: ReactNode;
  text: ReactNode;
  items: {
    value: number;
    label: string;
  }[];
  percent: number;
};

const MealsBoxByDaysItem = ({
  icon,
  text,
  items,
  percent,
}: MealsBoxByDaysItemProps) => {
  const { t } = useAppTranslation();

  return (
    <Stack direction="column" spacing="16px">
      <TitleWrapper>
        {icon}
        <span>{text}</span>
      </TitleWrapper>
      <Stack spacing="24px" direction="row" alignItems="center">
        <Box position="relative" display="inline-flex">
          <CircularProgress
            variant="determinate"
            size={80}
            thickness={4}
            value={100}
            sx={{
              color: "#F2F2FF",
            }}
          />
          <CircularProgress
            variant="determinate"
            sx={{
              color: "#7448D0",
              position: "absolute",
              left: 0,
            }}
            size={80}
            value={percent}
            thickness={4}
          />
          <PercentCircle percent={percent} />
        </Box>
        <Stack spacing="8px">
          {items.map((item, index) => (
            <LegendItem
              key={index}
              color={index % 2 ? "#F2F2FF" : "#7448D0"}
              label={`${item.label} -`}
              valueLabel={`${item.value} ${t(
                "monitoring2.meals_box.multiple_days.meals.c",
                { count: item.value },
              )}`}
            />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

type LegendItemProps = {
  color: string;
  label: string;
  valueLabel: string;
};

const LegendItem = ({ color, label, valueLabel }: LegendItemProps) => {
  return (
    <Stack spacing="8px" direction="row" alignItems="center">
      <Circle color={color} />
      <LegendLabel>{label}</LegendLabel>
      <ValueLegendLabel>{valueLabel}</ValueLegendLabel>
    </Stack>
  );
};

export default MealsBoxByDaysItem;
