import { ChangeEvent, useMemo } from "react";
import { useController, FormProvider } from "react-hook-form";

import {
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { RemoveCircleOutline } from "@mui/icons-material";
import { round } from "lodash";

import {
  ProductTitle,
  RowNutrientsWrapper,
} from "@views/dietician/Meal/Meal.styled";
import { MacrosListMui } from "@components/MacrosList";
import { MicronutrientPreview } from "@components/MicronutrientsModal";
import { StyledDragHandle } from "@components/RecipeEditForm/components/RecipeTableForm.styled";
import { StepsArrowButtons } from "@components/StepsArrowButtons";
import { decimalInput0, decimalInput1NotFixed } from "@utils/inputs";
import { useAppParams, useAppTranslation, useMeasures } from "@hooks";
import { ProductDto } from "@client/meals";
import { LangDto } from "@client";
import { getTranslatedName, scaleNutrients } from "@utils";
import { useDeleteCreatorMealProductMutation } from "@hooks/queries/meals";

import {
  ContentWrapper,
  ProductItemBody,
  ProductWrapper,
  StyledAutocomplete,
  StyledTextField,
} from "./SelectedProductEdit.styled";
import { useMealProductForm } from "../forms/useMealProductForm";
import { MealProductFormWatch } from "./watches/MealProductFormWatch";
import { SearchNoResults } from "@views/emptyStates/SearchNoResults";

interface SelectedProductEditProps {
  product: ProductDto;
}

export const SelectedProductEdit = ({ product }: SelectedProductEditProps) => {
  const { t, isPolishChosen } = useAppTranslation();
  const { mealId } = useAppParams();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(`${breakpoints.down("small")}`);
  const { mutate: deleteProduct, isLoading } =
    useDeleteCreatorMealProductMutation(mealId, String(product.id));
  const {
    food: { nutrients, translations, name, measures },
    grams,
    measure,
  } = product;
  const { getMeasure, measuresDict } = useMeasures();
  const mappedMeasures = useMemo(
    () =>
      measures.map(m => ({
        id: `${m.id}`,
        label: getMeasure(m.id)?.name ?? "",
      })),
    [measures, measuresDict],
  );

  const productName = isPolishChosen
    ? getTranslatedName(translations, LangDto.PL)
    : getTranslatedName(translations, LangDto.EN);

  const { control, ...rest } = useMealProductForm(grams, measure.id);
  const {
    field: { value: formGrams, onChange: onGramsChange },
  } = useController({ control, name: "grams" });
  const {
    field: { value: measureId, onChange: onMeasureIdChange },
  } = useController({ control, name: "measureId" });

  const measureGrams =
    measures.find(m => m.id.toString() === measureId)?.grams || 1;
  const value = round(Number(formGrams) / measureGrams, 1);
  const calcGrams = (val: number) => round(val * measureGrams).toString();

  const onAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const parsedValue = Number(value);
    if (!isNaN(parsedValue)) {
      onGramsChange(calcGrams(parsedValue));
    }
  };

  const onIncrementAmount = () => {
    if (value !== round(value) && !isNaN(value))
      onGramsChange(calcGrams(Math.ceil(value)));
    else if (isNaN(value)) onGramsChange(calcGrams(0));
    else onGramsChange(calcGrams(value + 1));
  };

  const onDecrementAmount = () => {
    if (value !== round(value) && !isNaN(value))
      onGramsChange(calcGrams(Math.floor(value)));
    else if (isNaN(value)) onGramsChange(calcGrams(0));
    else if (value - 1 >= 0) onGramsChange(calcGrams(value - 1));
  };

  const scaledNutrients = useMemo(
    () => scaleNutrients(nutrients, Number(formGrams)),
    [nutrients, formGrams],
  );

  return (
    <div className="grid gap-4">
      <FormProvider control={control} {...rest}>
        <MealProductFormWatch productId={product.id.toString()} />
      </FormProvider>
      <ProductWrapper>
        <StyledDragHandle className="dragHandle" />
        <ContentWrapper isMobile={isMobile}>
          <div className="grid flex-1">
            <ProductTitle variant="h3">{productName ?? name}</ProductTitle>
            <RowNutrientsWrapper>
              <MacrosListMui nutrients={scaledNutrients} />
              <MicronutrientPreview means={scaledNutrients} noPadding />
            </RowNutrientsWrapper>
          </div>
          <ProductItemBody>
            <StyledTextField
              value={formGrams}
              variant="outlined"
              label={t("meal.weight")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <StepsArrowButtons
                      clickTop={() => onGramsChange(Number(formGrams) + 1)}
                      clickDown={() =>
                        onGramsChange(
                          Number(formGrams) > 0 ? Number(formGrams) - 1 : 0,
                        )
                      }
                    />
                  </InputAdornment>
                ),
                inputComponent: decimalInput0,
              }}
              onChange={e => onGramsChange(e.target.value)}
            />
            <StyledTextField
              variant="outlined"
              label={t("meal.amount")}
              value={value}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <StepsArrowButtons
                      clickTop={onIncrementAmount}
                      clickDown={onDecrementAmount}
                    />
                  </InputAdornment>
                ),
                inputComponent:
                  measureGrams === 1 ? decimalInput0 : decimalInput1NotFixed,
              }}
              onChange={onAmountChange}
            />
            <StyledAutocomplete
              noOptionsText={<SearchNoResults />}
              value={mappedMeasures.find(m => m.id === measureId)}
              size="small"
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={t("meal.measure")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder={t("recipe.ingredients_table.other_measure")}
                />
              )}
              options={mappedMeasures}
              onChange={(_, { id }) => onMeasureIdChange(id)}
              disableClearable
              clearOnBlur
              clearOnEscape
            />
          </ProductItemBody>
        </ContentWrapper>
        <IconButton disabled={isLoading} onClick={() => deleteProduct()}>
          {isLoading ? (
            <CircularProgress size="1.5rem" />
          ) : (
            <RemoveCircleOutline color="primary" />
          )}
        </IconButton>
      </ProductWrapper>
      <Divider />
    </div>
  );
};
