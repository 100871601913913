import {
  FetchMealResponse,
  ProductDto,
  ItemPlaceholder,
  RecipeDto,
} from "@client/meals";
import { compact } from "lodash";

export const getFirstImage = (meal: FetchMealResponse | undefined) => {
  const isRecipe = (
    recipe: RecipeDto | ItemPlaceholder,
  ): recipe is RecipeDto => {
    return (recipe as RecipeDto).isPlaceholder === undefined;
  };
  const isProduct = (
    recipe: ProductDto | ItemPlaceholder,
  ): recipe is ProductDto => {
    return (recipe as ProductDto).isPlaceholder === undefined;
  };
  const recipesImgs =
    meal?.recipes
      .filter(r => isRecipe(r.recipe) && !!r.recipe.media?.s3Url)
      .map(r => r.recipe as RecipeDto)
      .map(r => r.media?.s3Url) ?? [];
  const productsImgs =
    meal?.products
      .filter(p => isProduct(p) && p.food.image?.url)
      .map(p => p as ProductDto)
      .map(p => p.food.image?.url) ?? [];

  const totalImages = compact([...recipesImgs, ...productsImgs]);

  return totalImages.length ? totalImages[0] : null;
};
