import { EmptyCalendar, Pen } from "@assets/icons";
import { IconButtonStyled, RowStyled, Title } from "./UsedInRow.styled";
import { Chip } from "@mui/material";
import { CircularArrows } from "@assets/icons/Arrows";
import { RecipeEditDrawer, RecipeId } from "../RecipeDrawer/RecipeEditDrawer";
import { useModalState } from "@hooks";
import {
  ProductEditDrawer,
  ProductId,
} from "../ProductDrawer/ProductEditDrawer";

export interface UsedInRowProps {
  id: number;
  name: string;
  mealName: string;
  mealId: string;
  exchange: boolean;
  recipeIds?: RecipeId;
  productIds?: ProductId;
}
export const UsedInRow = ({
  name,
  mealName,
  exchange,
  mealId,
  recipeIds,
  productIds,
}: UsedInRowProps) => {
  const [openRecipe, onOpenRecipe, onCloseRecipe] = useModalState();
  const [openProduct, onOpenProduct, onCloseProduct] = useModalState();

  const handleOpenDrawer = () => {
    if (recipeIds) return onOpenRecipe();
    if (productIds) return onOpenProduct();
  };

  return (
    <>
      <RowStyled>
        <Title>{name}</Title>

        <div className="flex gap-[8px]">
          {exchange && <Chip color="info" label={<CircularArrows />} />}
          <div className="flex items-center gap-[8px]">
            <EmptyCalendar size="w-[16px] h-[16px]" />
            <Title>{mealName}</Title>
          </div>
          <IconButtonStyled onClick={handleOpenDrawer}>
            <Pen />
          </IconButtonStyled>
        </div>
      </RowStyled>
      {recipeIds && (
        <RecipeEditDrawer
          open={openRecipe}
          onClose={onCloseRecipe}
          mealId={mealId}
          recipeIds={recipeIds}
        />
      )}
      {productIds && (
        <ProductEditDrawer
          open={openProduct}
          onClose={onCloseProduct}
          mealId={mealId}
          productIds={productIds}
        />
      )}
    </>
  );
};
