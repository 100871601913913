import { Clip, MeetingPhone } from "@assets/icons";
import { MEETING_TYPE } from "@utils";
import type { CSSProperties } from "react";
import CheckedCalendarIcon from "@icons/checkedCalendar.svg?react";

export const getEventIcon = (
  meetingType: MEETING_TYPE,
  style?: CSSProperties,
) => {
  switch (meetingType) {
    case MEETING_TYPE.TODO:
      return <Clip className="text-xl" style={style} />;
    case MEETING_TYPE.OTHER:
      return <Clip className="text-xl" style={style} />;
    case MEETING_TYPE.GOOGLE:
      return <CheckedCalendarIcon className="text-xl" style={style} />;
    default:
      return <MeetingPhone className="text-xl" style={style} />;
  }
};
