import { memo } from "react";
import { MicroSectionWrapper } from "./BarChart.styled";

interface BarChartProps {
  daysView?: boolean;
  name: string;
  value: number;
  maxValue: number;
  unit: string;
  colors: Colors;
}

interface Colors {
  fill: string;
  shadow: string;
}

export const BarChart = memo(
  ({ daysView, name, colors, value, maxValue, unit }: BarChartProps) => {
    const percent = (value / maxValue) * 100;
    const validPercent = percent > 100 ? 100 : percent ? percent : 0;
    const offsetValue = 5;
    const strokeWidth = 10;

    return (
      <div className="w-full flex flex-col items-end">
        {!daysView && (
          <span style={{ marginRight: "calc(5% - 10px)" }}>
            {`${value}${maxValue ? " / " + maxValue : ""} ${unit ?? ""}`}
          </span>
        )}
        <MicroSectionWrapper daysView={daysView}>
          <span className="w-40 " style={{ textAlign: "end" }}>
            {name}
          </span>
          <svg height={30} width={"100%"}>
            <line
              x1={`${offsetValue}%`}
              x2={`${100 - offsetValue}%`}
              y1="50%"
              y2="50%"
              stroke={colors.shadow}
              strokeWidth={strokeWidth}
              strokeLinecap="round"
            />
            <line
              x1={`${offsetValue}%`}
              x2={`${validPercent * 0.9 + offsetValue}%`}
              y1="50%"
              y2="50%"
              stroke={colors.fill}
              strokeWidth={strokeWidth}
              strokeLinecap="round"
            />
          </svg>
          {daysView && (
            <span>
              {`${value}${maxValue ? " / " + maxValue : ""} ${unit ?? ""}`}
            </span>
          )}
        </MicroSectionWrapper>
      </div>
    );
  },
);
