import { fetchAccountNotifications } from "@client";
import { useInfiniteQuery } from "@tanstack/react-query";

export const accountNotificationQueryKey = "notificationsQueryKey";

export const accountUnreadNotificationQueryKey = "notificationsUnreadQueryKey";

type useInfinityNotificationServiceProps =
  | typeof accountNotificationQueryKey
  | typeof accountUnreadNotificationQueryKey;

export const useInfinityNotificationService = (
  queryKey: useInfinityNotificationServiceProps,
) => {
  const fetchNotifications = async ({ pageParam = 1 }) => {
    const params = new URLSearchParams();

    if (queryKey === accountUnreadNotificationQueryKey) {
      params.append("read", "0");
    }

    params.append("page", pageParam.toString());
    return await fetchAccountNotifications(params);
  };

  const { data, fetchNextPage, hasNextPage, isFetching, isLoading } =
    useInfiniteQuery({
      queryKey: [queryKey],
      queryFn: fetchNotifications,
      getNextPageParam: (lastPage, notifications) => {
        if (lastPage.data.length < 20) return undefined;
        return lastPage.meta && lastPage.meta?.currentPage + 1;
      },
    });

  return {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading,
  };
};
