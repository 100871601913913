import { useMemo } from "react";
import { useParams } from "react-router-dom";

export const useClientParams = () => {
  const { patientId } = useParams<ClientParams>();
  return useMemo(() => (patientId ? parseInt(patientId) : NaN), [patientId]);
};

type ClientParams = {
  patientId: string;
};
