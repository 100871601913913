import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MutationOptions } from "../types";
import { fetchFetchOptimizerSettingsQueryKey } from "./useFetchOptimizerSettingsQuery";
import {
  SaveOptimizerSettingsRequestBody,
  saveOptimizerSettings,
} from "@client/schedule";

export const useSaveOptimizerSettingsMutation = (
  programId: number | string,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (searchOptions: SaveOptimizerSettingsRequestBody) =>
      saveOptimizerSettings(programId, searchOptions),
    {
      ...options,
      onSuccess: data => {
        queryClient.invalidateQueries([
          fetchFetchOptimizerSettingsQueryKey,
          programId.toString(),
        ]);
        options?.onSuccess && options.onSuccess(data);
      },
    },
  );
};
