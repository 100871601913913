import { EyeMonitoring } from "@assets/icons/Monitoring";
import { Box, styled } from "@mui/material";

type ShowMoreProps = {
  label: string;
  onClick: () => void;
};

const ShowMore = ({ label, onClick }: ShowMoreProps) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      gap="8px"
      alignItems="center"
      sx={{ cursor: "pointer" }}
      onClick={onClick}
    >
      <EyeMonitoring fill="#7448D0" size="" />
      <Label>{label}</Label>
    </Box>
  );
};

const Label = styled("span")`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  font-family: Figtree, serif;
  color: #7448d0;
`;

export default ShowMore;
