import { useEffect, useRef } from "react";

export const usePdfWorker = () => {
  const workerRef = useRef<Worker | null>(null);

  const terminateWorker = () => {
    workerRef.current?.terminate();
    workerRef.current = null;
  };

  useEffect(() => {
    return terminateWorker;
  }, []);

  const generatePdf = (payload: any) => {
    if (!workerRef.current) {
      workerRef.current = new Worker(
        new URL("../utils/pdfWorker.ts", import.meta.url),
        {
          type: "module",
        },
      );
    }

    return new Promise<string>(resolve => {
      if (workerRef.current) {
        workerRef.current.onmessage = function (event) {
          resolve(event.data);
        };
        workerRef.current.postMessage(payload);
      }
    });
  };

  const generateSectionPdf = (payload: any) => {
    if (!workerRef.current) {
      workerRef.current = new Worker(
        new URL("../utils/pdfSectionWorker.ts", import.meta.url),
        {
          type: "module",
        },
      );
    }

    return new Promise<string>(resolve => {
      if (workerRef.current) {
        workerRef.current.onmessage = function (event) {
          resolve(event.data);
        };
        workerRef.current.postMessage(payload);
      }
    });
  };

  return { generatePdf, generateSectionPdf, terminateWorker };
};
