import { styled } from "@mui/material";

interface LabelProps {
  color?: "success" | "warning" | "error";
}

export const Label = styled("span")<LabelProps>`
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: ${({ color, theme }) =>
    color ? theme.palette[color].dark : "inherit"};
`;

export const KcalText = styled(Label)`
  color: ${({ color, theme }) =>
    color ? theme.palette[color].dark : theme.colors.neutral.dark[800]};
`;

export const Text = styled(Label)`
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
  white-space: nowrap;
`;

const CenteredFlex = styled("div")`
  display: flex;
  align-items: center;
`;

export const MacrosTextWrapper = styled(CenteredFlex)`
  gap: 6px;
  align-items: center;
`;

export const MacrosStringWrapper = styled(CenteredFlex)`
  gap: 4px;
`;
