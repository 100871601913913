import { useEffect, useMemo, useState } from "react";
import { Control, FieldValues, Path } from "react-hook-form";
import { useDebounce } from "react-use";
import { useQueryClient } from "@tanstack/react-query";

import { Person, Search } from "@assets/icons";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";
import { fetchClientsQueryKey, useFetchClientsQuery } from "@hooks/queries";
import type { ReactNode } from "react";
import { StyledAutocomplete } from "./SearchClientsAutocomplete.styled";
import { getSubscription } from "@layouts/Dashboard/components";

interface SearchClientsAutocompleteProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  disabled?: boolean;
  label?: string;
  newClient?: ClientOption;
  active?: 1 | undefined;
  defaultQuery?: string;
  placeholder?: string;
  popupIcon?: ReactNode | null;
}

interface ClientOption {
  id: string;
  label: string;
}

export const SearchClientsAutocomplete = <T extends FieldValues>({
  control,
  name,
  disabled,
  label,
  newClient,
  active = undefined,
  defaultQuery = "",
  placeholder,
  popupIcon = <Search />,
}: SearchClientsAutocompleteProps<T>) => {
  const [query, setQuery] = useState(defaultQuery);
  const [debouncedQuery, setDebouncedQuery] = useState(defaultQuery);
  const queryClient = useQueryClient();
  const { account } = useFetchDietitianAccountQuery();
  const { hasSubscription } = getSubscription(account?.clinic?.subscription);

  const [isReady] = useDebounce(
    () => {
      queryClient.cancelQueries([fetchClientsQueryKey]);
      setDebouncedQuery(query);
    },
    250,
    [query],
  );

  const {
    clients,
    isFetching,
    submit: submitClient,
  } = useFetchClientsQuery(undefined, {
    enabled: !!account?.clinic?.id && !!isReady() && hasSubscription,
    keepPreviousData: true,
  });

  const clientsMapped = useMemo<ClientOption[]>(
    () => [
      ...(clients?.map(client => ({
        id: client.id.toString(),
        label: client.firstName + " " + client.lastName,
      })) ?? []),
      newClient ?? { id: "", label: "" },
    ],
    [clients, newClient],
  );

  const onSearch = (client: string) => setQuery(client);

  useEffect(() => {
    if (defaultQuery) {
      submitClient({ search: defaultQuery, active });
    }
  }, []);

  useEffect(() => {
    submitClient({ search: query, active });
  }, [query]);

  useEffect(() => {
    if (defaultQuery) {
      setQuery(defaultQuery);
    }
  }, [defaultQuery]);
  return (
    <StyledAutocomplete
      placeholder={placeholder}
      control={control}
      name={name}
      id={name}
      size="small"
      popupIcon={popupIcon}
      className="w-full"
      options={clientsMapped}
      onSearch={onSearch}
      onBlur={() => submitClient({ search: undefined })}
      isLoading={isFetching}
      label={label}
      disabled={disabled}
      startIcon={<Person className="text-gray-450 text-2xl" />}
    />
  );
};
