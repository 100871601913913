import { useMutation, useQueryClient } from "@tanstack/react-query";

import { fetchCustomTagsQueryKey } from "@hooks/queries/customTags/useFetchCustomTagsQuery";
import { MutationOptions } from "@hooks/queries";
import {
  updateCustomTag,
  UpdateCustomTagRequest,
} from "@client/customTags/updateCustomTag";

export const useUpdateCustomTagMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation(
    (request: UpdateCustomTagRequest) => updateCustomTag(request),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries([fetchCustomTagsQueryKey]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};
