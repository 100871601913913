import { StyledTasksGrid, StyledTypography } from "./TasksTable.styled";
import {
  GridPaginationWrapper,
  ITEMS_ON_PAGE,
  getEnumValuesAsNumbers,
} from "@components/PaginationNew";
import { useAppTranslation } from "@hooks";
import { IconButton } from "@components/IconButton";
import { LinkAnchor } from "@assets/icons";
import {
  GridColumns,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import { Metadata } from "@typeDefinitions";
import type {
  Task,
  CustomTag,
  Dietitian,
  Client,
} from "@client/tasks/fetchTasks";
import { Dispatch, SetStateAction, useMemo, type FC } from "react";
import { CustomTagChip } from "@components/CustomTagChip";
import { Avatar } from "@components/AvatarNew";
import { Box, Radio, Tooltip, Typography } from "@mui/material";
import { RadioDashed } from "@assets/icons/Radio";
import { CompleteCircle } from "@assets/icons";
import { useUpdateTaskStatus } from "@hooks/queries/tasks/useUpdateTaskStatus";
import { useTasksContext } from "../../Tasks.context";
import dayjs from "dayjs";
import { TaskDropdownMenu } from "../TaskDropdownMenu";
import { composeFullUrl } from "../TasksPreviewPanel";
import { useTheme } from "@mui/material";
import { getTaskPriorityIcon } from "@utils/tasks/getTaskPriorityIcon";
import { TaskPriority } from "../TaskPrioritySelect";
import { TaskType } from "../TasksAccordion/TasksAccordion";
import { Link } from "react-router-dom";
import { TaskTagsSelect } from "../TaskTagsSelect/TaskTagsSelect";

type PageDispatchType = Dispatch<SetStateAction<number>>;
interface TasksTableProps {
  tasks: Task[];
  isFetching: boolean;
  meta: Metadata;
  page: number;
  setPage: PageDispatchType;
  perPage: number;
  setPerPage: PageDispatchType;
  taskType: TaskType;
  totalCount: number;
}

export const TasksTable: FC<TasksTableProps> = ({
  tasks,
  isFetching,
  meta,
  page,
  setPage,
  perPage,
  setPerPage,
  totalCount,
}) => {
  const { t } = useAppTranslation();
  const { handleUpdateTaskStatus } = useUpdateTaskStatus();
  const { handleOpenSidePanel, setCurrentTaskId } = useTasksContext();
  const { palette } = useTheme();
  const isOnePage = !!meta?.lastPage && meta?.lastPage <= 1;

  const columns: GridColumns = useMemo(
    () => [
      {
        field: "name",
        headerName: t("tasks.tasksTable.name"),
        minWidth: 220,
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        cellClassName: "mx-[10.5px] !px-[12px]",
        renderCell: (params: GridRenderCellParams<string>) => (
          <Tooltip
            title={params.value}
            arrow
            enterDelay={500}
            enterNextDelay={500}
          >
            <Typography
              fontWeight={600}
              variant="body2"
              width="100%"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              className="block line-clamp-1"
            >
              {params.value}
            </Typography>
          </Tooltip>
        ),
      },
      {
        field: "customTags",
        headerName: t("tasks.tasksTable.type"),
        minWidth: 280,
        sortable: false,
        disableColumnMenu: true,
        cellClassName: "group/tags",
        renderCell: (params: GridRenderCellParams<CustomTag[]>) => {
          const tags = params.value || [];
          const taskId = params.id as number;
          return <TaskTagsSelect tags={tags} taskId={taskId} editable />;
        },
      },
      {
        field: "client",
        headerName: t("tasks.tasksTable.client"),
        minWidth: 230,
        sortable: false,
        disableColumnMenu: true,
        cellClassName: "!m-0 !p-0",
        renderCell: (params: GridRenderCellParams<Client>) =>
          params.value?.name ? (
            <Box display="flex" alignItems="center" gap={1}>
              <Link
                to={`/patients/${params.value.id}/info-and-services`}
                onClick={e => e.stopPropagation()}
              >
                <Avatar
                  image={params.value?.avatar}
                  fullName={params.value?.name ?? ""}
                />
              </Link>
              <Box>
                <Typography variant="subtitle2" fontWeight="bold">
                  {params.value?.name ?? "-"}
                </Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  className="flex gap-x-1"
                >
                  <span>{t("tasks.tasksTable.partnership")}</span>
                  <span>
                    {params.value?.activeUntil
                      ? dayjs(params.value.activeUntil).format("DD.MM.YYYY")
                      : params.value?.name
                      ? t("tasks.indefinitely")
                      : "-"}
                  </span>
                </Typography>
              </Box>
            </Box>
          ) : (
            <p className="pl-4">-</p>
          ),
      },
      {
        field: "dueDate",
        headerName: t("tasks.tasksTable.deadline"),
        minWidth: 130,
        sortable: false,
        disableColumnMenu: true,
        renderHeader: () => (
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle2">
              {t("tasks.tasksTable.deadline")}
            </Typography>
          </Box>
        ),
        renderCell: (params: GridRenderCellParams<string>) => (
          <Typography variant="body2">
            {params.value ? dayjs(params.value).format("DD.MM.YYYY") : "-"}
          </Typography>
        ),
      },
      {
        field: "dietitian",
        headerName: t("tasks.tasksTable.assignedTo"),
        maxWidth: 100,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<Dietitian>) => (
          <Avatar
            image={params.value?.avatar}
            fullName={params.value?.name ?? ""}
          />
        ),
      },
      {
        field: "priority",
        headerName: t("tasks.tasksTable.priority"),
        minWidth: 100,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<string>) => {
          const priority = params.value as TaskPriority;

          return (
            <Box display="flex" alignItems="center">
              {getTaskPriorityIcon(priority)}
              <Typography variant="body2" className="text-gray-450">
                {priority ? t(`tasks.priority.${priority}`) : "-"}
              </Typography>
            </Box>
          );
        },
      },
      {
        field: "relatedLink",
        headerName: t("tasks.tasksTable.actions"),
        minWidth: 140,
        sortable: false,
        headerAlign: "right",
        align: "center",
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams<string>) => {
          const isDone = params.row.isDone;
          return (
            <Box display="flex" alignItems="center" position="relative">
              <Radio
                checkedIcon={
                  <CompleteCircle
                    fill={palette.success.main}
                    className="text-3xl"
                  />
                }
                className="absolute left-2 top-0"
                icon={<RadioDashed className="text-3xl text-white" />}
                checked={isDone}
                onClick={event => {
                  event.stopPropagation();
                  handleUpdateTaskStatus({
                    id: Number(params.id),
                    isDone: !isDone,
                  });
                }}
              />
              {params.value ? (
                <a
                  href={composeFullUrl(params.value ?? "")}
                  onClick={event => event.stopPropagation()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton
                    color="purple-light"
                    className="hover:bg-transparent"
                  >
                    <LinkAnchor className="text-2xl text-gray-450" />
                  </IconButton>
                </a>
              ) : (
                <IconButton
                  color="purple-light"
                  className="hover:bg-transparent -mr-[8px]"
                  disabled
                >
                  <LinkAnchor className="text-2xl text-gray-450" />
                </IconButton>
              )}

              <TaskDropdownMenu id={String(params.id)} />
            </Box>
          );
        },
      },
    ],
    [t, handleUpdateTaskStatus],
  );

  const handleOpenTaskPreview = (params: GridRowParams) => {
    handleOpenSidePanel();
    setCurrentTaskId(params.row.id);
  };

  return (
    <div className="relative">
      <StyledTasksGrid
        columns={columns}
        rows={tasks}
        onPageChange={setPage}
        page={page}
        rowCount={totalCount ?? 0}
        loading={isFetching}
        pageSize={meta.perPage}
        autoHeight
        rowsPerPageOptions={getEnumValuesAsNumbers(ITEMS_ON_PAGE)}
        rowHeight={55}
        disableSelectionOnClick
        onRowClick={handleOpenTaskPreview}
        sortingMode="server"
        getRowSpacing={() => ({ top: 8 })}
        paginationMode="server"
        className="custom-scrollbar custom-scrollbar-transparent"
        components={{
          Pagination: GridPaginationWrapper,
        }}
        componentsProps={{
          pagination: { setPerPage, perPage, isOnePage },
        }}
      />
    </div>
  );
};
