import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const WandWithStars = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16" height="14" viewBox="0 0 16 14" {...props}>
      <path
        d="M6.96476 1.16862L5.91753 1.55416C5.8342 1.58424 5.77865 1.66354 5.77865 1.75104C5.77865 1.83854 5.8342 1.91783 5.91753 1.94791L6.96476 2.33346L7.35642 3.36432C7.38698 3.44635 7.46753 3.50104 7.55642 3.50104C7.64531 3.50104 7.72587 3.44635 7.75642 3.36432L8.14809 2.33346L9.19531 1.94791C9.27865 1.91783 9.3342 1.83854 9.3342 1.75104C9.3342 1.66354 9.27865 1.58424 9.19531 1.55416L8.14809 1.16862L7.75642 0.137756C7.72587 0.0557251 7.64531 0.0010376 7.55642 0.0010376C7.46753 0.0010376 7.38698 0.0557251 7.35642 0.137756L6.96476 1.16862ZM1.72587 10.8128C1.20642 11.3241 1.20642 12.1553 1.72587 12.6694L2.68698 13.6155C3.20642 14.1268 4.05087 14.1268 4.57309 13.6155L15.1648 3.18658C15.6842 2.67526 15.6842 1.84401 15.1648 1.32994L14.2036 0.386585C13.6842 -0.124744 12.8398 -0.124744 12.3175 0.386585L1.72587 10.8128ZM13.9064 2.25963L10.9898 5.13073L10.3425 4.49362L13.2592 1.62252L13.9064 2.25963ZM0.653646 3.20573C0.528646 3.25221 0.445312 3.36979 0.445312 3.50104C0.445312 3.63229 0.528646 3.74987 0.653646 3.79635L2.22309 4.37604L2.81198 5.92096C2.8592 6.04401 2.97865 6.12604 3.11198 6.12604C3.24531 6.12604 3.36476 6.04401 3.41198 5.92096L4.00087 4.37604L5.57031 3.79635C5.69531 3.74987 5.77865 3.63229 5.77865 3.50104C5.77865 3.36979 5.69531 3.25221 5.57031 3.20573L4.00087 2.62604L3.41198 1.08112C3.36476 0.958069 3.24531 0.876038 3.11198 0.876038C2.97865 0.876038 2.8592 0.958069 2.81198 1.08112L2.22309 2.62604L0.653646 3.20573ZM10.4314 10.2057C10.3064 10.2522 10.2231 10.3698 10.2231 10.501C10.2231 10.6323 10.3064 10.7499 10.4314 10.7964L12.0009 11.376L12.5898 12.921C12.637 13.044 12.7564 13.126 12.8898 13.126C13.0231 13.126 13.1425 13.044 13.1898 12.921L13.7786 11.376L15.3481 10.7964C15.4731 10.7499 15.5564 10.6323 15.5564 10.501C15.5564 10.3698 15.4731 10.2522 15.3481 10.2057L13.7786 9.62604L13.1898 8.08112C13.1425 7.95807 13.0231 7.87604 12.8898 7.87604C12.7564 7.87604 12.637 7.95807 12.5898 8.08112L12.0009 9.62604L10.4314 10.2057Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
