import { ReactNode } from "react";

import { Typography } from "@mui/material";

import { useCategoriesNutrientsFetchQuery } from "@hooks/queries/dictionaries";
import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";
import { Nutrient } from "@typeDefinitions";

import { NutrientGroup } from "./NutrientGroup";
import { NutrientsGroups } from "./GroupedNutrients.styled";

interface GroupedNutrientsProps {
  title: string;
  biggerPadding?: boolean;
  renderNutrient?: (
    categoryId: number,
    nutrientId: number,
    unit: string,
  ) => ReactNode;
  renderTooltip?: (nutrientId: number, children: ReactNode) => ReactNode;
  nutrients?: Nutrient[];
}

export const GroupedNutrients = ({
  title,
  biggerPadding,
  renderNutrient,
  renderTooltip,
  nutrients,
}: GroupedNutrientsProps) => {
  const {
    data: nutrientCategories,
    isLoading,
    isError,
  } = useCategoriesNutrientsFetchQuery();

  if (isLoading) return <Spinner />;

  if (!nutrientCategories || isError) return <ApiError />;

  return (
    <>
      <Typography variant="h6">{title}</Typography>
      <NutrientsGroups>
        {nutrientCategories.map(category => (
          <NutrientGroup
            biggerPadding={biggerPadding}
            key={category.id}
            category={category}
            renderNutrient={renderNutrient}
            renderTooltip={renderTooltip}
            nutrients={nutrients}
          />
        ))}
      </NutrientsGroups>
    </>
  );
};
