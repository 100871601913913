import { useContext } from "react";

import {
  QuestionCheckboxDto,
  QuestionDateDto,
  QuestionEmptyDto,
  QuestionRadioDto,
  QuestionSelectDto,
  QuestionSliderDto,
  QuestionSwitchDto,
  QuestionTableDto,
  QuestionTextAreaDto,
  QuestionTextDto,
  QuestionType,
  SystemQuestionDto,
} from "@client/surveys";
import { TableOfContentsContext } from "@context/TableOfContentsContext";
import {
  useDeleteSurveySubgroupQuestionMutation,
  usePostSurveySubgroupQuestionMutation,
  useUpdateSurveySubgroupQuestionsSortMutation,
} from "@hooks/queries/surveys";

import { QuestionText } from "./QuestionText";
import { QuestionTextArea } from "./QuestionTextArea";
import { QuestionRadio } from "./QuestionRadio/QuestionRadio";
import { QuestionCheckbox } from "./QuestionCheckbox/QuestionCheckbox";
import { QuestionDate } from "./QuestionDate/QuestionDate";
import { QuestionTable } from "./QuestionTable";
import { SystemQuestionUserWeight } from "./SystemQuestionUserWeight/SystemQuestionUserWeight";
import { SystemQuestionUserHeight } from "./SystemQuestionUserHeight/SystemQuestionUserHeight";
import { SystemQuestionUserFatTissue } from "./SystemQuestionUserFatTissue/SystemQuestionUserFatTissue";
import { SystemQuestionMeasurements } from "./SystemQuestionMeasurements";
import { SystemQuestionUserBirthday } from "./SystemQuestionUserBirthday";
import { SystemQuestionCooperationGoals } from "./SystemQuestionCooperationGoals";
import { SystemQuestionUserTargetBodyWeight } from "./SystemQuestionUserTargetBodyWeight";
import { SystemQuestionPregnantWoman } from "./SystemQuestionPregnantWoman";
import { SystemQuestionSex } from "./SystemQuestionSex";
import { LangDto } from "@client";
import { SystemQuestionUserPreferencesFoodUnliked } from "./SystemQuestionUserPreferencesFoodUnliked";
import { SystemQuestionUserPreferencesFoodLiked } from "./SystemQuestionUserPreferencesFoodLiked";
import { SystemQuestionTagAllergen } from "./SystemQuestionTagAllergen";
import { SystemQuestionTagDietType } from "./SystemQuestionTagDietType";

interface QuestionFactoryProps {
  listIndex: number;
  totalQuestions: number;
  questionsSort: number[];
  question:
    | QuestionTextDto
    | QuestionTextAreaDto
    | QuestionCheckboxDto
    | QuestionRadioDto
    | QuestionDateDto
    | QuestionSwitchDto
    | QuestionSelectDto
    | QuestionSliderDto
    | QuestionTableDto
    | SystemQuestionDto
    | QuestionEmptyDto;
  autoFocus?: boolean;
  questionnaireLang?: LangDto;
}
export const QuestionFactory = ({
  question,
  questionsSort,
  listIndex,
  ...rest
}: QuestionFactoryProps) => {
  const { subgroupId } = useContext(TableOfContentsContext);
  const id = subgroupId?.toString() ?? "0";
  const { mutate: createQuestion } = usePostSurveySubgroupQuestionMutation({
    onSuccess: d => {
      changeSortOrder({
        id,
        payload: {
          ids: [
            ...questionsSort.slice(0, listIndex),
            d.data.id,
            ...questionsSort.slice(listIndex),
          ],
        },
      });
    },
  });
  const { mutate: changeSortOrder } =
    useUpdateSurveySubgroupQuestionsSortMutation({
      onSuccess: () => {
        deleteQuestion(question.id.toString());
      },
    });
  const { mutate: deleteQuestion } = useDeleteSurveySubgroupQuestionMutation();

  //temporary
  const onTypeChange = (currentType: QuestionType, newType: QuestionType) => {
    const typesGrouped = [
      [QuestionType.TEXT, QuestionType.TEXTAREA],
      [QuestionType.RADIO, QuestionType.CHECKBOX],
    ];
    const matchingTypes = typesGrouped
      .map(type => type.includes(currentType) && type.includes(newType))
      .some(value => value);

    if (matchingTypes) {
      createQuestion({
        id,
        payload: { ...question, type: newType as any },
      });
      return;
    }

    if (newType === QuestionType.TEXT)
      createQuestion({
        id,
        payload: {
          ...question,
          type: newType,
          config: { placeholder: null, required: false },
        },
      });
    if (newType === QuestionType.TEXTAREA)
      createQuestion({
        id,
        payload: {
          ...question,
          type: newType,
          config: { placeholder: null, required: false },
        },
      });
    if (newType === QuestionType.CHECKBOX)
      createQuestion({
        id,
        payload: {
          ...question,
          type: newType,
          config: { options: [], other: null, required: false },
        },
      });
    if (newType === QuestionType.RADIO)
      createQuestion({
        id,
        payload: {
          ...question,
          type: newType,
          config: { options: [], other: null, required: false },
        },
      });
    if (newType === QuestionType.DATE)
      createQuestion({
        id,
        payload: {
          ...question,
          type: newType,
          config: { required: false },
        },
      });
    if (newType === QuestionType.TABLE)
      createQuestion({
        id,
        payload: {
          ...question,
          type: newType,
          config: { required: false, columns: [], rows: [], heading: "" },
        },
      });
  };

  if (question.type === QuestionType.TEXT)
    return (
      <QuestionText
        {...rest}
        question={question}
        listIndex={listIndex}
        onTypeChange={onTypeChange}
      />
    );

  if (question.type === QuestionType.TEXTAREA)
    return (
      <QuestionTextArea
        {...rest}
        question={question}
        listIndex={listIndex}
        onTypeChange={onTypeChange}
      />
    );

  if (question.type === QuestionType.RADIO)
    return (
      <QuestionRadio
        {...rest}
        question={question}
        listIndex={listIndex}
        onTypeChange={onTypeChange}
      />
    );

  if (question.type === QuestionType.CHECKBOX)
    return (
      <QuestionCheckbox
        {...rest}
        question={question}
        listIndex={listIndex}
        onTypeChange={onTypeChange}
      />
    );

  if (question.type === QuestionType.DATE)
    return (
      <QuestionDate
        {...rest}
        question={question}
        listIndex={listIndex}
        onTypeChange={onTypeChange}
      />
    );

  if (question.type === QuestionType.TABLE)
    return (
      <QuestionTable
        {...rest}
        question={question}
        listIndex={listIndex}
        onTypeChange={onTypeChange}
      />
    );

  if (question.type === QuestionType.SYSTEM_USER_WEIGHT)
    return (
      <SystemQuestionUserWeight
        {...rest}
        question={question}
        listIndex={listIndex}
        onTypeChange={onTypeChange}
      />
    );

  if (question.type === QuestionType.SYSTEM_USER_HEIGHT)
    return (
      <SystemQuestionUserHeight
        {...rest}
        question={question}
        listIndex={listIndex}
        onTypeChange={onTypeChange}
      />
    );

  if (question.type === QuestionType.SYSTEM_USER_FAT_TISSUE)
    return (
      <SystemQuestionUserFatTissue
        {...rest}
        question={question}
        listIndex={listIndex}
        onTypeChange={onTypeChange}
      />
    );

  if (question.type === QuestionType.SYSTEM_MEASUREMENTS)
    return (
      <SystemQuestionMeasurements
        {...rest}
        question={question}
        listIndex={listIndex}
        onTypeChange={onTypeChange}
      />
    );

  if (question.type === QuestionType.SYSTEM_USER_BIRTHDAY)
    return (
      <SystemQuestionUserBirthday
        {...rest}
        question={question}
        listIndex={listIndex}
        onTypeChange={onTypeChange}
      />
    );

  if (question.type === QuestionType.SYSTEM_COOPERATION_GOALS)
    return (
      <SystemQuestionCooperationGoals
        {...rest}
        question={question}
        listIndex={listIndex}
        onTypeChange={onTypeChange}
      />
    );

  if (question.type === QuestionType.SYSTEM_USER_TARGET_BODY_WEIGHT)
    return (
      <SystemQuestionUserTargetBodyWeight
        {...rest}
        question={question}
        listIndex={listIndex}
        onTypeChange={onTypeChange}
      />
    );

  if (question.type === QuestionType.SYSTEM_PREGNANT_WOMAN)
    return (
      <SystemQuestionPregnantWoman
        {...rest}
        question={question}
        listIndex={listIndex}
        onTypeChange={onTypeChange}
      />
    );

  if (question.type === QuestionType.SYSTEM_SEX)
    return (
      <SystemQuestionSex
        {...rest}
        question={question}
        listIndex={listIndex}
        onTypeChange={onTypeChange}
      />
    );

  if (question.type === QuestionType.SYSTEM_USER_PREFERENCES_FOOD_UNLIKED)
    return (
      <SystemQuestionUserPreferencesFoodUnliked
        {...rest}
        question={question}
        listIndex={listIndex}
        onTypeChange={onTypeChange}
      />
    );

  if (question.type === QuestionType.SYSTEM_USER_PREFERENCES_FOOD_LIKED)
    return (
      <SystemQuestionUserPreferencesFoodLiked
        {...rest}
        question={question}
        listIndex={listIndex}
        onTypeChange={onTypeChange}
      />
    );

  if (question.type === QuestionType.SYSTEM_TAG_ALLERGEN)
    return (
      <SystemQuestionTagAllergen
        {...rest}
        question={question}
        listIndex={listIndex}
        onTypeChange={onTypeChange}
      />
    );

  if (question.type === QuestionType.SYSTEM_TAG_DIET_TYPE)
    return (
      <SystemQuestionTagDietType
        {...rest}
        question={question}
        listIndex={listIndex}
        onTypeChange={onTypeChange}
      />
    );
  else return <></>;
};
