import {
  MonitoringCalendarTable,
  CalendarLegend,
  MonitoringDayDetails,
  DayNote,
} from "./";
import {
  StyledDayDetailsCard,
  CalendarTableWrapper,
  CalendarWrapper,
} from "./MonitoringCalendar.styled";

export const MonitoringCalendar = () => {
  return (
    <CalendarWrapper>
      <CalendarTableWrapper>
        <div className="grid gap-4">
          <MonitoringCalendarTable />
          <CalendarLegend />
        </div>
      </CalendarTableWrapper>

      <div className="grid gap-3">
        <StyledDayDetailsCard>
          <MonitoringDayDetails />
        </StyledDayDetailsCard>
        <StyledDayDetailsCard>
          <DayNote />
        </StyledDayDetailsCard>
      </div>
    </CalendarWrapper>
  );
};
