import { Omit } from "lodash";
import {
  FieldValues,
  Path,
  useController,
  useFormContext,
} from "react-hook-form";
import { TabsChipsFilter, TabsChipsFilterProps } from "./TabsChipsFilter";

export interface TabsChipsFiltersForm<T extends FieldValues>
  extends Omit<TabsChipsFilterProps, "selected" | "setSelected"> {
  name: Path<T>;
  onChangeExternal?: (id: string) => void;
  className?: string;
  isWrapped?: boolean;
}
export const TabsChipsFiltersForm = <T extends FieldValues>({
  name,
  onChangeExternal,
  ...rest
}: TabsChipsFiltersForm<T>) => {
  const { control } = useFormContext<T>();
  const {
    field: { value, onChange },
  } = useController<T>({ control, name });

  const handleOnChange = (id: string | string[]) => {
    if (!Array.isArray(id) && onChangeExternal) {
      onChangeExternal(id);
    }
    onChange(id);
  };

  return (
    <TabsChipsFilter selected={value} setSelected={handleOnChange} {...rest} />
  );
};
