import { Nutrient } from "@typeDefinitions";
import { CARBS_ID, ENERGY_ID, FATS_ID, PROTEIN_ID } from "@utils/macros";

export const useBasicNutrientsGetter = (
  nutrients?: Nutrient[],
  modifier = 1,
) => {
  const kcal = Math.round(
    (nutrients?.find(n => n.id === ENERGY_ID)?.value || 0) * modifier,
  );
  const protein = Math.round(
    (nutrients?.find(n => n.id === PROTEIN_ID)?.value || 0) * modifier,
  );
  const fat = Math.round(
    (nutrients?.find(n => n.id === FATS_ID)?.value || 0) * modifier,
  );
  const carbs = Math.round(
    (nutrients?.find(n => n.id === CARBS_ID)?.value || 0) * modifier,
  );

  return {
    kcal,
    protein,
    fat,
    carbs,
  };
};
