import * as yup from "yup";
import {
  PatientBodyMeasurementResource,
  patientBodyMeasurementSchema,
} from "@client/resources/PatientBodyMeasurementResource";

export interface BodyMeasurementMonitoringResource {
  date: string;
  measurements: {
    id: number;
    growth: number | null;
    weight: number | null;
    bodyFatLevel: number | null;
    arm: number | null;
    tightBiceps: number | null;
    waist: number | null;
    hip: number | null;
    thigh: number | null;
    calf: number | null;
    bodyMeasurements: PatientBodyMeasurementResource[];
  } | null;
}

const measurementSchema = yup.number().defined().nullable();

export const bodyMeasurementMonitoringSchema = yup.object({
  date: yup.string().required(),
  measurements: yup
    .object({
      id: yup.number().required(),
      growth: measurementSchema,
      weight: measurementSchema,
      bodyFatLevel: measurementSchema,
      arm: measurementSchema,
      tightBiceps: measurementSchema,
      waist: measurementSchema,
      hip: measurementSchema,
      thigh: measurementSchema,
      calf: measurementSchema,
      bodyMeasurements: yup.array().of(patientBodyMeasurementSchema).defined(),
    })
    .defined()
    .nullable(),
});
