import { Nutrient } from "@typeDefinitions";
import { useQuery } from "@tanstack/react-query";

export const useFetchNutrientsQuery = (
  fetch: () => Promise<Nutrient[]>,
  options?: { enabled: boolean },
) => {
  const { data: nutrients, ...rest } = useQuery(
    ["fetchNutrients", fetch],
    fetch,
    {
      ...options,
      onError: e => {
        console.log(e);
      },
    },
  );
  return { nutrients, ...rest };
};
