import { styled } from "@mui/material";

export const SimulationHeaderSection = styled("div")`
  position: sticky;
  top: 0;
  background: ${({ theme }) => theme.palette.background.default};
  z-index: 1;
  column-gap: 1rem;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
  justify-content: space-around;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};
`;

export const SimulationListSection = styled("div")`
  gap: 1rem;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
  align-content: start;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};
`;

export const StyledTitle = styled("p")`
  padding: 0.5rem;
`;

export const StyledItem = styled("p")`
  padding: 0.5rem;
  font-family: ${({ theme }) => theme.fontFamily.regular};
`;
