import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const KcalMonitoring = (props: SvgIconProps) => (
  <SvgIcon width="12" height="14" viewBox="0 0 12 14" {...props}>
    <path
      d="M4.34961 0.777344C4.55273 0.599609 4.85742 0.599609 5.06055 0.777344C5.74609 1.4375 6.40625 2.14844 7.01562 2.91016C7.29492 2.55469 7.625 2.14844 7.95508 1.81836C8.1582 1.64062 8.46289 1.64062 8.66602 1.81836C9.55469 2.65625 10.291 3.77344 10.8242 4.81445C11.332 5.85547 11.6875 6.92188 11.6875 7.6582C11.6875 10.9082 9.14844 13.625 6 13.625C2.80078 13.625 0.3125 10.9082 0.3125 7.6582C0.3125 6.69336 0.744141 5.5 1.45508 4.30664C2.16602 3.11328 3.15625 1.86914 4.34961 0.777344ZM6.02539 11.1875C6.68555 11.1875 7.24414 11.0098 7.77734 10.6543C8.84375 9.91797 9.12305 8.41992 8.48828 7.25195C8.38672 7.02344 8.08203 6.99805 7.92969 7.20117L7.26953 7.9375C7.11719 8.14062 6.8125 8.14062 6.66016 7.9375C6.22852 7.4043 5.49219 6.43945 5.06055 5.90625C4.9082 5.70312 4.60352 5.70312 4.42578 5.90625C3.5625 6.97266 3.13086 7.6582 3.13086 8.41992C3.15625 10.1719 4.42578 11.1875 6.02539 11.1875Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
