import { useNavigate } from "react-router-dom";

import { Edit } from "@assets/icons";
import { useAppTranslation } from "@hooks";
import { ProgramDay, ProgramListElement } from "./ProgramListElement";
import { useCallback } from "react";

import { BottomBorderDiv } from "./ProgramDiets.styled";
import { ProgramContextProvider } from "@context";
import { usePatientProgramSummaryParams } from "@views/dietician/PatientProgram";
import { Card } from "@components/Card";
import { IconButton } from "@components/IconButton";

interface ProgramDietsCardProps {
  days: ProgramDay[];
  canEdit?: boolean;
}

export const ProgramDiets = ({ days, canEdit }: ProgramDietsCardProps) => {
  const navigate = useNavigate();
  const { t } = useAppTranslation();
  const { patientId, programId } = usePatientProgramSummaryParams();

  const onEdit = useCallback(
    () =>
      navigate(
        patientId
          ? `/patients/${patientId}/nutritional-programs/${programId}/days`
          : `/programs/${programId}/days`,
      ),
    [navigate],
  );

  return (
    <Card
      className="mb-6"
      header={t("program.summary.program_diets_card.diets")}
      action={
        <IconButton onClick={onEdit} disabled={!canEdit}>
          <Edit size="w-5 h-5" className="stroke-current" />
        </IconButton>
      }
    >
      <BottomBorderDiv>
        {days &&
          days
            .filter(day => day.frequency.length)
            .map(day => (
              <div key={day.id}>
                <ProgramContextProvider dayId={day.id}>
                  <ProgramListElement day={day} canEdit={canEdit} />
                </ProgramContextProvider>
              </div>
            ))}
      </BottomBorderDiv>
    </Card>
  );
};
