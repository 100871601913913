import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";

import * as yup from "yup";

import { useAppTranslation } from "@hooks";
import { ContainerWrapper } from "@views/public/components";
import { CloseRound } from "@assets/icons";
import { TextWrapper } from "@views/public/Visit/components";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { StyledFormTextField } from "@views/public/Visit/components";
import { SubmitButton } from "@views/public/Visit/components";
import { useCancelVisitMutation } from "@hooks/queries";
import { toast } from "react-toastify";

interface CancelFormInput {
  reason: string;
}

const cancelFormSchema = yup.object().shape({
  reason: yup.string(),
});

export const VisitCancel = () => {
  const { t } = useAppTranslation();
  const { search } = useLocation();
  const { mutate } = useCancelVisitMutation({
    onSuccess: () => toast.success(t("visits.canceled_confirmation")),
  });

  const url = useMemo(() => new URLSearchParams(search).get("url"), [search]);

  const { control, handleSubmit } = useForm<CancelFormInput>({
    resolver: yupResolver(cancelFormSchema),
  });

  const handleCancellation = (data: CancelFormInput) => {
    if (url) mutate({ url: atob(url), data });
  };

  return (
    <ContainerWrapper className="px-40 shadow-paper">
      <CloseRound size="w-14 h-auto" className="mb-7" />

      <TextWrapper className="font-bold">{t("visit.cancel.title")}</TextWrapper>

      <form
        className="flex flex-col items-center w-full"
        onSubmit={handleSubmit(handleCancellation)}
      >
        <StyledFormTextField
          control={control}
          name="reason"
          label={t("visit.cancel.label")}
          multiline
          rows={5}
          size="small"
          fullWidth
        />

        <SubmitButton color="primary" variant="contained" type="submit">
          {t("visit.cancel.accept")}
        </SubmitButton>
      </form>
    </ContainerWrapper>
  );
};
