import { MuiDataGrid } from "@components/MuiDataGrid";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";

export const StyledTasksGrid = styled(MuiDataGrid)`
  & .MuiDataGrid-row {
    background-color: ${({ theme }) => theme.colors.primaryLightNew};
    box-shadow: unset;
    display: flex;
    justify-content: center;

    &:hover {
      background: ${({ theme }) => theme.colors.neutral.light[200]};
      cursor: pointer;
    }
  }
`;

export const StyledTypography = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  border: 1px solid ${({ theme }) => theme.palette.primary.medium};
  width: 2rem;
  height: 2rem;
  cursor: pointer;
`;
