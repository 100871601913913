import { useAppTranslation, useClientParams, useModal } from "@hooks";
import { useFetchClientQuery } from "@hooks/queries";

import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";
import { EditClientInfoModal } from "@components/EditClientInfoModal";
import { ClientPropsList } from "./ClientPropsList";

import { SectionTitle, StyledIconButton } from "./ClientProfileSection.styled";

import { Edit } from "@assets/icons";
import { EditBalanceClientProfileModal } from "@components/EditBalanceClientProfileModal";
import { ThemeProviderWrapperNew } from "themeNew";

export const ClientProfileSection = () => {
  const { t } = useAppTranslation();
  const { modalOpened, onModalOpen, onModalClose } = useModal();
  const clientId = useClientParams();
  const { client, isLoading, isError } = useFetchClientQuery(clientId);

  if (isLoading) return <Spinner />;
  if (isError || !client) return <ApiError />;

  return (
    <>
      <div className="grid gap-5">
        <div className="flex gap-2">
          <SectionTitle variant="h6">
            {t("client_profile.profile")}
          </SectionTitle>
          <StyledIconButton onClick={onModalOpen} color="primary">
            <Edit size="w-5 h-5" />
          </StyledIconButton>
        </div>
        <ClientPropsList client={client} responsive={true} />
      </div>
      <ThemeProviderWrapperNew>
        <EditBalanceClientProfileModal
          open={modalOpened}
          onClose={onModalClose}
          id={clientId.toString()}
        />
      </ThemeProviderWrapperNew>
    </>
  );
};
