import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export interface DeleteProgramDayDietRequest {
  programId: string;
  dayId: string;
}

export const deleteProgramDayDiet = async ({
  programId,
  dayId,
}: DeleteProgramDayDietRequest) =>
  await fetchData(
    `/dietitian/programs/${programId}/days/${dayId}/diet`,
    APIMethods.DELETE,
  );
