import { useQuery } from "@tanstack/react-query";

import { ApiResponse } from "@typeDefinitions";

import { QueryOptionsTyped } from "./types";
import {
  UnreadNotificationsDto,
  fetchAccountUnreadNotifications,
} from "@client";

export const fetchUnreadNotificationsQueryKey =
  "fetchUnreadNotificationsQueryKey";

export const useFetchAccountUnreadNotifications = (
  options?: QueryOptionsTyped<ApiResponse<UnreadNotificationsDto>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchUnreadNotificationsQueryKey],
    () => fetchAccountUnreadNotifications(),
    options,
  );

  return {
    unreadNotifications: data?.data.notifications,
    ...rest,
  };
};
