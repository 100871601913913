import { ProductDto, ItemPlaceholder, RecipeWrapperDto } from "@client/meals";
import { ProductAccordion } from "./Accordions/ProductAccordion";
import { Title } from "./RecipesAndProducts.styled";
import { RecipeAccordion } from "./Accordions/RecipeAccordion";
import { useAppTranslation } from "@hooks";
import { scaleNutrients } from "@utils";

interface RecipesAndProductsTab {
  products: (ProductDto | ItemPlaceholder)[];
  recipes: RecipeWrapperDto[];
}
export const RecipesAndProductsTab = ({
  products,
  recipes,
}: RecipesAndProductsTab) => {
  const { isPolishChosen, t } = useAppTranslation();

  return (
    <div className="grid gap-[16px]">
      {!!recipes.length && <Title>{t("common.recipes")}</Title>}

      {recipes.map(r => {
        if (!r.recipe.isPlaceholder)
          return (
            <RecipeAccordion
              key={r.id}
              id={r.id}
              description={r.recipe.description ?? ""}
              descriptionEn={r.recipe.descriptionEn ?? ""}
              name={isPolishChosen ? r.recipe.name : r.recipe.nameEn ?? ""}
              nutrients={r.recipe.nutrients}
              tags={r.recipe.tags.map(t => t.id)}
              products={r.recipe.foodRecipe}
              servings={r.servings}
              totalServings={r.recipe.servings}
              img={r.recipe.media?.s3Url ?? null}
              comment={r.recipe.comment}
              commentEn={r.recipe.commentEn}
            />
          );
      })}

      {!!products.length && <Title>{t("common.products")}</Title>}

      {products.map(p => {
        if (!p.isPlaceholder)
          return (
            <ProductAccordion
              id={p.id}
              measures={p.food.measures}
              name={p.food.name}
              nutrients={scaleNutrients(p.food.nutrients, p.grams)}
              tags={p.food.tags.map(t => t.id)}
              grams={p.grams}
              measureId={p.measure.id}
              img={p.food.image?.url ?? null}
            />
          );
      })}
    </div>
  );
};
