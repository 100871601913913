import { MainProductCategoryDto } from "@client";

export interface CategoryWithProducts<T> {
  categoryNamePl: string;
  categoryNameEn: string;
  products: T[];
}

export function groupProductsByCategory<
  T extends { mainProductCategory: MainProductCategoryDto },
>(products: T[]): Record<number, CategoryWithProducts<T>> {
  const groupedProducts = products.reduce((acc, product) => {
    const category = product.mainProductCategory;
    const categoryId = category.id;

    if (!acc[categoryId]) {
      acc[categoryId] = {
        categoryNamePl: category.descriptionPl,
        categoryNameEn: category.description ?? "",
        products: [],
      };
    }

    acc[categoryId].products.push(product);
    return acc;
  }, {} as Record<number, CategoryWithProducts<T>>);

  return groupedProducts;
}
