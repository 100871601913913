import * as yup from "yup";

import { fetchData } from "@utils/api";

import {
  ActionsDto,
  actionsSchema,
  MediaExtendedDto,
  mediaExtendedSchema,
  NutrientDto,
  nutrientSchema,
} from "./common";

export async function fetchRecipe(id: number): Promise<NewRecipeDto> {
  const { data } = await fetchData(`/dietitian/recipes/${id}`);
  return await recipeSchema.validate(data);
}

export const newTagSchema = yup.object().shape({
  id: yup.number().required(),
  clinicId: yup.number().nullable().default(null),
  tagCategoryId: yup.number().required(),
  descriptionPl: yup.string().required(),
  descriptionEn: yup.string().required(),
});

export interface NewTagDto {
  id: number;
  clinicId: number | null;
  tagCategoryId: number;
  descriptionPl: string;
  descriptionEn: string;
}

export interface ExtendedMeasureDto {
  id: number;
  description: string;
  descriptionEn: string;
  sortOrder: number;
  grams: number;
}

const extendedMeasureSchema = yup.object().shape({
  id: yup.number().required(),
  description: yup.string().required(),
  descriptionEn: yup.string().required(),
  sortOrder: yup.number().required(),
  grams: yup.number().required(),
});

interface FoodDto {
  id: number;
  description: string | null;
  descriptionPl: string | null;
  nutrients: NutrientDto[];
  measures: ExtendedMeasureDto[];
}

const foodSchema = yup.object().shape({
  id: yup.number().required(),
  description: yup.string().nullable().default(null),
  descriptionPl: yup.string().nullable().default(null),
  nutrients: yup.array().of(nutrientSchema).required(),
  measures: yup.array().of(extendedMeasureSchema).required(),
});

export interface FoodRecipeDto {
  id: number;
  grams: number;
  foodMeasureId: number;
  food: FoodDto;
}

export const foodRecipeSchema = yup.object().shape({
  id: yup.number().required(),
  grams: yup
    .number()
    .transform((_, value: null | number) => (value === null ? 0 : value))
    .required(),
  foodMeasureId: yup.number().required(),
  food: foodSchema.required(),
});

export interface NewRecipeDto {
  id: number;
  name: string;
  nameEn: string | null;
  description: string | null;
  descriptionEn: string | null;
  reviewed: boolean;
  isComplex: boolean;
  servings: number;
  tags: NewTagDto[];
  foodRecipe: FoodRecipeDto[];
  nutrients: NutrientDto[];
  version: number;
  media: MediaExtendedDto | null;
  actions: ActionsDto;
  favorite: number[];
  comment: string | null;
  commentEn: string | null;
}

export const recipeSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
  nameEn: yup.string().nullable().default(null),
  description: yup.string().nullable().default(null),
  descriptionEn: yup.string().nullable().default(null),
  reviewed: yup.boolean().required(),
  isComplex: yup.boolean().required(),
  servings: yup.number().required(),
  tags: yup.array().of(newTagSchema).required(),
  foodRecipe: yup.array().of(foodRecipeSchema).required(),
  nutrients: yup.array().of(nutrientSchema).required(),
  version: yup.number().required(),
  media: mediaExtendedSchema.nullable().default(null),
  actions: actionsSchema.required(),
  favorite: yup.array().of(yup.number().required()).required(),
  comment: yup.string().nullable().default(null),
  commentEn: yup.string().nullable().default(null),
});
