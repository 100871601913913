import { ReactNode, useState } from "react";

import { differenceWith, find, isEqual, map, unionWith } from "lodash";

import {
  CollectionItem,
  Item,
  ItemsContext,
  ItemsContextIf,
  ItemsListCallback,
  ProductItem,
  RecipeItem,
} from "./ItemsContext";

interface ItemsContextProviderProps {
  children: ReactNode;
  singleSelect?: boolean;
}

export const ItemsContextProvider = ({
  children,
  singleSelect,
}: ItemsContextProviderProps) => {
  const [items, setItems] = useState<(ProductItem | RecipeItem)[]>([]);
  const [collections, setCollections] = useState<CollectionItem[]>([]);

  const handleAddItems = (items: (ProductItem | RecipeItem)[]) =>
    setItems(state => unionWith(state, items, isEqual));

  const handleDeleteItems = (items: Item[]) =>
    setItems(state =>
      differenceWith(
        state,
        items,
        (a, b) => a.id === b.id && a.type === b.type,
      ),
    );

  const handleToggleItems = (items: (ProductItem | RecipeItem)[]) => {
    setItems(state => {
      const comparator = (a: Item, b: Item) =>
        a.id === b.id && a.type === b.type;

      const allItemsExist = items.every(item =>
        state.some(stateItem => comparator(item, stateItem)),
      );

      return allItemsExist
        ? differenceWith(state, items, comparator)
        : unionWith(state, items, comparator);
    });
  };

  const handleUpdateItem = (updated: ProductItem | RecipeItem) =>
    setItems(state =>
      map(state, item => {
        const foundItem = find([updated], { id: item.id, type: item.type });
        return foundItem ? { ...item, ...foundItem } : item;
      }),
    );

  const isChecked = (item: Item) =>
    items.some(i => i.id === item.id && i.type === item.type);

  const addCollection = (itemId: number) => {
    setCollections(state => {
      const item = find(state, { id: itemId });

      if (!item) return [...state, { id: itemId, items: [] }];

      return state;
    });
  };

  const updateCollectionItems = (id: number, collectionItems: number[]) => {
    const collection = find(collections, { id });

    setCollections(state =>
      state.map(c =>
        c.id === id && collection
          ? { ...collection, items: collectionItems }
          : c,
      ),
    );
  };

  const getCollectionStatus = (id: number) => {
    const item = find(collections, { id });
    const hasEverySelected = item?.items.every(i =>
      items.some(selected => selected.id === i),
    );
    const hasSomeSelected = item?.items.some(i =>
      items.some(selected => selected.id === i),
    );
    if (item?.items.length && hasEverySelected)
      return { checked: true, indeterminate: false };
    if (item?.items.length && hasSomeSelected)
      return { checked: false, indeterminate: true };
    return { checked: false, indeterminate: false };
  };

  const handleSubmit = (callback: ItemsListCallback) => callback(items);

  const handleClearValues = () => {
    setItems([]);
    setCollections([]);
  };

  const handleGetRecipeServings = (id: number): number | null => {
    const item = items.find(
      (i): i is RecipeItem => i.type === "recipe" && i.id === id,
    );
    return item ? item.servings : null;
  };

  const value: ItemsContextIf = {
    addItems: handleAddItems,
    deleteItems: handleDeleteItems,
    toggleItems: handleToggleItems,
    updateItem: handleUpdateItem,
    isChecked,
    addCollection,
    getCollectionStatus,
    updateCollectionItems,
    submit: handleSubmit,
    clearValues: handleClearValues,
    itemsCount: items.length,
    getRecipeServings: handleGetRecipeServings,
    singleSelect,
  };

  return (
    <ItemsContext.Provider value={value}>{children}</ItemsContext.Provider>
  );
};
