import { useContext } from "react";
import { getCategoryColor } from "@utils/constants";
import { useAppTranslation } from "@hooks";

import { Micronutrient, Nutrient } from "@typeDefinitions";
import { MicroItemWrapper } from "./MicroItemWrapper";
import { NormsContext } from "../NormsContext";

export interface MicroGroupProps {
  namePl: string;
  nameEn: string;
  elements: Micronutrient[];
  micros: Nutrient[];
  id: number;
}

export const MicroGroup = (props: MicroGroupProps) => {
  const { namePl, nameEn, elements, micros, id } = props;

  const { t, isPolishChosen } = useAppTranslation();

  const filteredElement = elements.filter(
    element => ![211, 212, 213, 214].includes(element.id),
  );

  const { isEmpty } = useContext(NormsContext);

  return (
    <div className="w-1/2 px-5 pt-4">
      <div
        className="w-full h-8 flex justify-between items-center border-b-2"
        style={{ borderColor: getCategoryColor(id) }}
      >
        <span className="text-sm font-semibold">
          {isPolishChosen ? namePl : nameEn}
        </span>
        <span className="text-2xs flex items-center font-roman">
          <span>{t("patient.schedule.content")}</span>
          {!isEmpty && (
            <span className="ml-3">{t("patient.schedule.norm")}</span>
          )}
        </span>
      </div>
      <div>
        {filteredElement.map(micronutrient => (
          <MicroItemWrapper
            key={micronutrient.id}
            micronutrient={micronutrient}
            micronutrientsProduct={micros}
          />
        ))}
      </div>
    </div>
  );
};
