import { useMutation, useQueryClient } from "@tanstack/react-query";

import { IdDto } from "@client";
import { PostSurveyRequest, postSurvey } from "@client/surveys";
import { ApiResponse } from "@typeDefinitions";

import { MutationOptions } from "../types";
import { fetchSurveysQueryKey } from "./useFetchSurveysQuery";

export const usePostSurveyMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation<ApiResponse<IdDto>, unknown, PostSurveyRequest>(
    payload => postSurvey(payload),
    {
      ...options,
      onSuccess: (d, v) => {
        options?.onSuccess && options.onSuccess(d, v);
        queryClient.invalidateQueries([fetchSurveysQueryKey]);
      },
    },
  );
};
