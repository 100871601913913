import { Chip, styled, Tooltip, TooltipProps } from "@mui/material";
import { round } from "lodash";
import { ViewType } from "../Pricing";
import {
  ActiveClients,
  BuyButton,
  CardStyled,
  Description,
  DividerStyled,
  FeatureDto,
  FeatureSection,
  PriceGross,
  PriceNet,
  Title,
} from "./common";
import { MONTHS, VAT_PERCENTAGE } from "./consts";

interface ScaleCardProps {
  priceVariant: ViewType;
  showVat: boolean;
  onClickBuy: () => void;
  disabled?: boolean;
}

export const SCALE_ANNUALLY_PRICE = 2004;
export const SCALE_MONTHLY_PRICE = 199;

export const ScaleCard = ({
  priceVariant,
  showVat,
  onClickBuy,
  disabled,
}: ScaleCardProps) => {
  const ACTIVE_CLIENTS = 100;

  const isAnnually = priceVariant === ViewType.annually;
  const priceMonthly = isAnnually
    ? SCALE_ANNUALLY_PRICE / MONTHS
    : SCALE_MONTHLY_PRICE;

  const tooltipText = isAnnually
    ? `Możliwość dokupienia paczek klientów (maks. 400):

Ceny w rozliczeniu rocznym:
 • 50 klientów (+70 zł)
 • 100 klientów (+100 zł)
 • 200 klientów (+170 zł)
 • 400 klientów (+300 zł)`
    : `Możliwość dokupienia paczek klientów (maks. 400): 

Ceny w rozliczeniu miesięcznym:
 • 50 klientów (+80 zł)
 • 100 klientów (+120 zł)
 • 200 klientów (+200 zł)
 • 400 klientów (+350 zł)`;
  const features: FeatureDto[] = [
    {
      label: "Możliwość dokupienia paczek klientów",
      Tooltip: ({ children }) => (
        <RedTooltip children={children} arrow title={tooltipText} />
      ),
    },
    { label: "+8 funkcji w aplikacji mobilnej klienta" },
    { label: "Autogenerowanie diet" },
    { label: "Automatyzacje systemowe" },
    { label: "Własny branding PDF" },
    { label: "Integracja Google Calendar" },
    { label: "Kolejny dietetyk w zespole +50 zł " },
  ];

  const withVat = (price: number) =>
    showVat ? round(price + price * VAT_PERCENTAGE, 2) : price;

  return (
    <CardPurple>
      <div className="flex flex-col gap-[24px]">
        <div className="h-[180px] flex flex-col gap-[24px]">
          <div className="flex flex-col gap-[16px]">
            <div className="flex justify-between">
              <Title>Scale</Title>
              <ChipStyled
                variant="outlined"
                color="secondary"
                label="Bestseller"
              />
            </div>
            <Description>
              Dla dietetyków, którzy chcą automatyzować pracę i zwiększać skalę
              swojego biznesu.
            </Description>
          </div>

          <div className="flex flex-col gap-[8px]">
            <PriceNet value={withVat(priceMonthly)} />
            {isAnnually && (
              <PriceGross
                value={withVat(SCALE_ANNUALLY_PRICE)}
                grossPrice={showVat}
                view={ViewType.annually}
              />
            )}
          </div>
        </div>
        <BuyButton onClick={onClickBuy} disabled={disabled} />

        <DividerStyled />

        <ActiveClients value={ACTIVE_CLIENTS} />

        <DividerStyled />

        <FeatureSection
          features={features}
          title="Funkcje pakietu Starter oraz:"
        />
      </div>
    </CardPurple>
  );
};

const CardPurple = styled(CardStyled)`
  background: #faf7ff;
`;

const ChipStyled = styled(Chip)`
  background-color: #fff;
  height: 26px;
  &.MuiChip-label {
    padding: unset;
  }
`;

const RedTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "#2D1D51",
    padding: "8px 12px",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    letterSpacing: "0.4px",
    color: "#FFF",
    borderRadius: "8px",
  },
  [`& .MuiTooltip-arrow`]: {
    color: "#2D1D51",
  },
}));
