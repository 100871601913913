import { useMutation, useQueryClient } from "@tanstack/react-query";
``;
import {
  UpdateSurveySubgroupRequest,
  updateSurveySubgroup,
} from "@client/surveys";

import { MutationOptions } from "../types";
import { fetchSurveyFullQueryKey } from "./useFetchSurveyFullQuery";

export const useUpdateSurveySubgroupMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, UpdateSurveySubgroupMutationPayload>(
    ({ id, payload }) => updateSurveySubgroup(id, payload),
    {
      ...options,
      onSuccess: (data, variables) => {
        options?.onSuccess && options.onSuccess(data, variables);

        queryClient.invalidateQueries([fetchSurveyFullQueryKey]);
      },
    },
  );
};

interface UpdateSurveySubgroupMutationPayload {
  id: string;
  payload: UpdateSurveySubgroupRequest;
}
