import { fetchData } from "@utils/api";
import { APIMethods, ApiEndpoints } from "@typeDefinitions";
import { TaskPriority } from "@views/dietician/Tasks/components/TaskPrioritySelect";

export const updateTask = async ({
  id,
  ...payload
}: UpdateTaskRequestPayload) => {
  return await fetchData(
    `${ApiEndpoints.Tasks}/${id}`,
    APIMethods.PUT,
    payload,
  );
};

export interface UpdateTaskRequestPayload {
  id: string;
  name: string;
  description?: string | null;
  relatedLink?: string | null;
  dueDate: string | null;
  priority: TaskPriority;
  dietitianId: number | null;
  clientId: number | null;
  customTagsIds: number[];
}
