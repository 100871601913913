import { deleteProgramDietProductRemoved } from "@client/program/dietCreator";
import { fetchDietProductsListQueryKey } from "@hooks/queries/diets";
import { MutationOptions } from "@hooks/queries/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useDeleteProgramDietProductRemovedMutation = (
  programId: string,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    UseDeleteProgramDietProductRemovedMutationPayload
  >(
    ({ dietId, productId }) =>
      deleteProgramDietProductRemoved(programId, dietId, productId),
    {
      ...options,
      onSuccess: (d, v) => {
        options?.onSuccess && options.onSuccess(d, v);
        queryClient.invalidateQueries([
          fetchDietProductsListQueryKey,
          v.dietId,
        ]);
      },
    },
  );
};

interface UseDeleteProgramDietProductRemovedMutationPayload {
  dietId: string;
  productId: string;
}
