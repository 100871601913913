import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const updateCreatorMealProduct = async (
  mealId: string,
  productId: string,
  payload: UpdateCreatorMealProductRequest,
  version: number,
) => {
  return await fetchData(
    `/dietitian/creator/meals/${mealId}/products/${productId}`,
    APIMethods.PUT,
    payload,
    undefined,
    { "Alloweat-Creator-Version": `${version}` },
  );
};

export interface UpdateCreatorMealProductRequest {
  foodMeasure: { id: number };
  grams: number;
}
