import * as yup from "yup";

import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

import { phoneSchema } from "./common";
import { DietitianDto } from "@hooks";

export const createClient = async (
  payload: CreateClientRequest,
): Promise<CreateClientResponse> => {
  const response = await fetchData(
    `/dietitian/patients`,
    APIMethods.POST,
    payload,
  );
  await createClientResponseSchema.validate(response);
  return response.data;
};

export interface CreateClientRequest {
  firstName: string;
  lastName: string;
  email: string | null;
  phone: PhoneDto;
  lang: "pl" | "en";
  sex: "m" | "w" | null;
  description: string | null;
  sendEmail: boolean;
  dietitian: DietitianDto;
}

interface PhoneDto {
  prefix: string | null;
  number: string | null;
}

const createClientSchema = yup.object().shape({
  id: yup.number().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  phone: phoneSchema.required(),
});

const createClientResponseSchema = yup.object().shape({
  data: createClientSchema.required(),
});

export interface CreateClientResponse {
  id: number;
  firstName: string;
  lastName: string;
  phone: PhoneDto;
}
