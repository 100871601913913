import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const LikeFilledMonitoring = (props: SvgIconProps) => (
  <SvgIcon width="14" height="12" viewBox="0 0 14 12" {...props}>
    <path
      d="M8.44727 0.462891C9.10742 0.615234 9.53906 1.25 9.41211 1.91016L9.33594 2.18945C9.20898 2.875 8.95508 3.50977 8.625 4.09375H12.2812C12.9414 4.09375 13.5 4.65234 13.5 5.3125C13.5 5.79492 13.2207 6.20117 12.8398 6.4043C13.1191 6.63281 13.2969 6.96289 13.2969 7.34375C13.2969 7.95312 12.8652 8.43555 12.3066 8.5625C12.4082 8.74023 12.4844 8.94336 12.4844 9.17188C12.4844 9.73047 12.1289 10.1875 11.6211 10.3398C11.6465 10.416 11.6719 10.5176 11.6719 10.5938C11.6719 11.2793 11.1133 11.8125 10.4531 11.8125H7.96484C7.48242 11.8125 7.02539 11.6855 6.61914 11.4062L5.62891 10.7715C4.96875 10.3145 4.5625 9.55273 4.5625 8.74023V7.75V6.53125V5.92188C4.5625 5.16016 4.89258 4.47461 5.47656 4.01758L5.6543 3.86523C6.31445 3.30664 6.79688 2.57031 6.94922 1.70703L7 1.42773C7.15234 0.767578 7.78711 0.335938 8.44727 0.462891ZM1.3125 4.5H2.9375C3.36914 4.5 3.75 4.88086 3.75 5.3125V11C3.75 11.457 3.36914 11.8125 2.9375 11.8125H1.3125C0.855469 11.8125 0.5 11.457 0.5 11V5.3125C0.5 4.88086 0.855469 4.5 1.3125 4.5Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
