import { Card, Typography, styled } from "@mui/material";
import { TABLET_MAX_CONTENT_WIDTH, LAPTOP_MAX_CONTENT_WIDTH } from "@utils";

export const MonitoringChartSectionStyled = styled(Card)`
  padding: 1rem;
  display: grid;
  gap: 1.5rem;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallMax}px) {
    width: ${TABLET_MAX_CONTENT_WIDTH};
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.medium}px) and (max-width: ${({ theme }) =>
      theme.breakpoints.values.laptopMax}px) {
    width: ${LAPTOP_MAX_CONTENT_WIDTH};
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.desktop}px) {
    flex: 1;
  }

  & .react-datepicker__aria-live {
    display: none;
  }

  & .react-datepicker__day--range-end,
  .react-datepicker__day--range-start {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const ResponsiveContainerWrapper = styled("div")`
  height: 16rem;
  overflow-x: auto;
`;

export const MacrosListWrapper = styled("div")`
  display: grid;
  grid-template-columns: 10.625rem 10.625rem;
  gap: 1rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.medium}px) {
    grid-template-columns: repeat(4, 10.625rem);
  }
`;

export const MonitoringChartTooltipStyled = styled("div")`
  display: grid;
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: 0.25rem;
  box-shadow: ${({ theme }) => theme.boxShadows.light};
`;

interface ColorDotProps {
  background: string;
}
export const ColorDot = styled("div")<ColorDotProps>`
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 100%;
  background-color: ${({ background }) => background};
`;

export const TooltipDayWrapper = styled("div")`
  display: flex;
  padding: 0.25rem;
  gap: 0.5rem;
`;

export const TooltipTypography = styled(Typography)`
  padding: 0.25rem 1rem;
`;

export const TooltipSummaryWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  padding: 0.25rem;
`;

export const BoldTypography = styled(Typography)`
  font-weight: 600;
`;

export const TypographyFill = styled(Typography)`
  flex: 1;
`;

export const MonitoringChartLegendStyled = styled("div")`
  display: flex;
  gap: 1rem;
  margin-left: 3.5rem;
  padding-top: 1rem;
`;

export const LegendItemStyled = styled("div")`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

interface LegendItemLineProps {
  background: string;
}

export const LegendItemLine = styled("div")<LegendItemLineProps>`
  width: 0.75rem;
  height: 3px;
  background-color: ${({ background }) => background};
`;

export const MicrosListWrapper = styled("div")`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`;

export const LabeledDatePickerWrapper = styled("div")`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`;
