import { IconButton, styled } from "@mui/material";

export const TrashButton = styled(IconButton)`
  &.MuiButtonBase-root {
    background: ${({ theme }) => theme.colors.neutral.light[100]};
    border: 1px solid ${({ theme }) => theme.palette.primary.medium};
    height: 40px;
    width: 40px;
  }

  align-self: flex-end;
`;

export const ModalText = styled("span")`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;
export const ModalTextBold = styled("strong")`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const FlexRow = styled("div")`
  display: flex;
  gap: 8px;
  align-items: flex-start;
`;
export const FlexColumn = styled("div")`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

export const DeleteModalWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  max-width: 478px;
`;
