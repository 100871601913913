import * as yup from "yup";

import { IdDto, TranslationDto, idSchema } from "@client/common";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const postCreatorDiet = async (
  payload: PostCreatorDietRequest,
): Promise<ApiResponse<IdDto>> => {
  const response = await fetchData(
    `/dietitian/creator-2/diets`,
    APIMethods.POST,
    payload,
  );

  return postCreatorDietResponseSchema.validate(response);
};

export interface PostCreatorDietRequest {
  translations: TranslationDto[];
  showMacros: boolean;
}

const postCreatorDietResponseSchema = yup.object().shape({
  data: idSchema.required(),
});
