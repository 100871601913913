import {
  SectionHeadEditLayout,
  SectionHeadLayout,
  SectionItemLabel,
  SectionItemLayout,
  SectionItemTextValue,
  SectionLayout,
  SectionTitle,
  TabHeadActionEditLabel,
} from "@views/dietician/ClientHealth/ClientHealthNew.styled";
import { useAppTranslation, useClientParams } from "@hooks";
import { useMemo, useState } from "react";
import {
  useFetchClientQueryNew,
  useFetchClientTargetsQuery,
} from "@hooks/queries";
import * as clientTargetGoal from "@utils/dictionaries/clientTargetGoalDictionaryUtils";
import GoalSectionDialog from "@views/dietician/ClientHealth/BodyMeasurementTab/GoalSection/Dialog/GoalSectionDialog";
import { EditIcon } from "@icons/index";

const GoalSection = () => {
  const { t, currentLanguage } = useAppTranslation();
  const [open, setOpen] = useState(false);
  const id = useClientParams();
  const { data: client } = useFetchClientQueryNew(id);
  const { targets } = useFetchClientTargetsQuery();

  const targetGoalName = useMemo(() => {
    if (!client || !targets) {
      return "-";
    }

    if (!client.data.profile.targets.length) {
      return "-";
    }

    const profileTargets = clientTargetGoal.getTargetsByIds(
      targets,
      client.data.profile.targets.map(target => target.id),
    );
    const profileTarget = profileTargets[0];
    if (!profileTarget) {
      return "-";
    }

    const translation = clientTargetGoal.getTranslation(
      profileTarget,
      currentLanguage,
    );

    return translation?.value ?? "-";
  }, [client, targets, currentLanguage]);

  return (
    <>
      <SectionLayout>
        <SectionHeadLayout>
          <SectionTitle>
            {t(
              "patient.health_and_goal.body_measurement_tab.section_goal.section_title",
            )}
          </SectionTitle>
          <SectionHeadEditLayout onClick={() => setOpen(true)}>
            <EditIcon color="#7448D0" />
            <TabHeadActionEditLabel>{t("common.edit")}</TabHeadActionEditLabel>
          </SectionHeadEditLayout>
        </SectionHeadLayout>
        <SectionItemLayout>
          <SectionItemLabel>
            {t(
              "patient.health_and_goal.body_measurement_tab.section_goal.goal",
            )}
          </SectionItemLabel>
          <SectionItemTextValue>{targetGoalName}</SectionItemTextValue>
        </SectionItemLayout>
        <SectionItemLayout>
          <SectionItemLabel>
            {t(
              "patient.health_and_goal.body_measurement_tab.section_goal.target_weight",
            )}
          </SectionItemLabel>
          <SectionItemTextValue>
            {client?.data.profile.targetWeight
              ? `${client?.data.profile.targetWeight} kg`
              : "-"}
          </SectionItemTextValue>
        </SectionItemLayout>
      </SectionLayout>
      <GoalSectionDialog open={open} setOpen={setOpen} />
    </>
  );
};

export default GoalSection;
