import { Drawer } from "@mui/material";
import { ReactNode } from "react";
import { ProgramSearchPreviewDrawerContextProvider } from "@components/ProgramSearchPreview/Context/ProgramSearchPreviewDrawerContext";

type PatientProgramPreviewDrawerProps = {
  children: ReactNode;
  onClose: () => void;
  programId: number | null;
};

const ProgramSearchPreviewDrawer = ({
  children,
  onClose,
  programId,
}: PatientProgramPreviewDrawerProps) => {
  return (
    <Drawer
      open={!!programId}
      onClose={onClose}
      anchor="right"
      PaperProps={{
        sx: { width: "640px", padding: "16px", borderRadius: "0" },
        className: "scrollbar-thin",
      }}
      slotProps={{
        backdrop: {
          sx: {
            background: "transparent",
          },
        },
      }}
    >
      <ProgramSearchPreviewDrawerContextProvider
        onClose={onClose}
        programId={programId}
      >
        {children}
      </ProgramSearchPreviewDrawerContextProvider>
    </Drawer>
  );
};

export default ProgramSearchPreviewDrawer;
