import {
  SystemQuestionDto,
  MeasurementAnswerResourceDto,
} from "@client/surveys";
import { OuterLabel } from "@components/OuterLabel";
import { useAppTranslation } from "@hooks";

import { QuestionPreview } from "../../QuestionPreview/QuestionPreview";
import { StyledAnswer } from "./SystemAnswerMeasurements.styled";

interface SystemAnswerMeasurementsProps {
  question: SystemQuestionDto;
  answer?: MeasurementAnswerResourceDto;
  listIndex: number;
  totalQuestions: number;
}

export const SystemAnswerMeasurements = ({
  answer,
  question,
  listIndex,
  totalQuestions,
}: SystemAnswerMeasurementsProps) => {
  const { title, description } = question;
  const { t } = useAppTranslation();

  return (
    <QuestionPreview
      title={title}
      description={description}
      listIndex={listIndex}
      totalQuestions={totalQuestions}
      systemQuestion
    >
      <OuterLabel label={t("questionnaires.questions.answer")}>
        <StyledAnswer>
          {t("questionnaires.questions.system_measurements.arm")}:{" "}
          {answer?.answer.arm ?? "-"} cm
        </StyledAnswer>
        <StyledAnswer>
          {t("questionnaires.questions.system_measurements.biceps")}:{" "}
          {answer?.answer.tightBiceps ?? "-"} cm
        </StyledAnswer>
        <StyledAnswer>
          {t("questionnaires.questions.system_measurements.waist")}:{" "}
          {answer?.answer.waist ?? "-"} cm
        </StyledAnswer>
        <StyledAnswer>
          {t("questionnaires.questions.system_measurements.hips")}:{" "}
          {answer?.answer.hip ?? "-"} cm
        </StyledAnswer>
        <StyledAnswer>
          {t("questionnaires.questions.system_measurements.thigh")}:{" "}
          {answer?.answer.thigh ?? "-"} cm
        </StyledAnswer>
        <StyledAnswer>
          {t("questionnaires.questions.system_measurements.calf")}:{" "}
          {answer?.answer.calf ?? "-"} cm
        </StyledAnswer>
      </OuterLabel>
    </QuestionPreview>
  );
};
