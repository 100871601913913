import { useFetchDietProductItemsQuery } from "@hooks/queries/diets";
import { SmallText } from "./ProductAccordion.styled";
import { UsedInRow, UsedInRowProps } from "./UsedInRow";
import { useAppParams, useAppTranslation } from "@hooks";
import { CircularProgress } from "@mui/material";
import { useMemo } from "react";
import { getTranslation } from "@utils/translations";
import { ProductDto, ProductItemDto, RecipeDto } from "@client/diets";

interface ProductAccordionContentProps {
  id: number;
}
export const ProductAccordionContent = ({
  id,
}: ProductAccordionContentProps) => {
  const { currentLanguage, t } = useAppTranslation();
  const { dietId } = useAppParams();
  const { data, isLoading } = useFetchDietProductItemsQuery(
    dietId,
    id.toString(),
  );

  const recipes = useMemo<UsedInRowProps[]>(
    () =>
      data
        ?.filter(
          (d): d is ProductItemDto & { item: RecipeDto } =>
            d.item.type === "recipe",
        )
        .map(({ exchange, item, meal }) => ({
          id: item.id,
          name: getTranslation(item.recipe.translations, currentLanguage),
          mealName: getTranslation(meal.translations, currentLanguage),
          exchange,
          mealId: meal.id.toString(),
          recipeIds: {
            dietRecipeId: item.id.toString(),
            recipeId: item.recipe.id.toString(),
          },
        })) ?? [],
    [data],
  );

  const products = useMemo<UsedInRowProps[]>(
    () =>
      data
        ?.filter(
          (d): d is ProductItemDto & { item: ProductDto } =>
            d.item.type === "product",
        )
        .map(({ exchange, item, meal }) => ({
          id: item.id,
          name: getTranslation(item.product.translations, currentLanguage),
          mealName: getTranslation(meal.translations, currentLanguage),
          exchange,
          mealId: meal.id.toString(),
          productIds: {
            dietProductId: item.id.toString(),
            productId: item.product.id.toString(),
          },
        })) ?? [],
    [data],
  );

  if (isLoading)
    return (
      <div className="grid w-full items-center">
        <CircularProgress />
      </div>
    );

  return (
    <div className="grid gap-[8px]">
      {!!recipes?.length && (
        <div className="grid gap-[8px]">
          <SmallText>
            {t("diet.appears_in_recipes", { count: recipes.length })}:
          </SmallText>
          {recipes.map(props => (
            <UsedInRow key={props.id} {...props} />
          ))}
        </div>
      )}

      {!!products?.length && (
        <div className="grid gap-[8px]">
          <SmallText>{t("diet.appears_as_product")}:</SmallText>
          {products.map(props => (
            <UsedInRow key={props.id} {...props} />
          ))}
        </div>
      )}
    </div>
  );
};
