import { Box, styled } from "@mui/material";
import { FC, ReactNode } from "react";

export const BoxWrapper = styled(Box)`
  padding: 24px;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.neutral.light[100]};
` as typeof Box;

export const BoxTitle = styled("span")`
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
  line-height: 20px;
  font-size: 16px;
  font-family: Figtree, serif;
  font-style: normal;
  font-weight: 600;
`;

type BoxTitleContainer = {
  icon: ReactNode;
  title: string;
};
export const BoxTitleContainer = ({ icon, title }: BoxTitleContainer) => {
  return (
    <Box display="flex" flexDirection="row" gap="8px" alignItems="center">
      {icon}
      <BoxTitle>{title}</BoxTitle>
    </Box>
  );
};
