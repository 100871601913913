import { useNutrientDictionary } from "@hooks";
import { Dot, Name, Percentage, Value } from "./MacroLabel.styled";
import { round } from "lodash";

interface MacroLabelProps {
  color: string;
  id: number;
  value?: number;
  percentage: number;
}
export const MacroLabel = ({
  color,
  id,
  value,
  percentage,
}: MacroLabelProps) => {
  const { getNutrient } = useNutrientDictionary();
  const nutrient = getNutrient(id);

  return (
    <div className="flex gap-[8px]">
      <Dot background={color} />
      <Name>{nutrient?.name}</Name>
      <Percentage>({percentage}%)</Percentage>
      <Value>
        {value ?? 0} {nutrient?.unit}
      </Value>
    </div>
  );
};
