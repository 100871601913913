import { useState } from "react";

import { Menu, MenuItem } from "@mui/material";

import { useAppTranslation } from "@hooks";
import { QuestionMark } from "@assets/icons/Menu";

import {
  KnowledgeBaseMenuWrapper,
  StyledButton,
} from "./KnowledgeBaseMenu.styled";

export const KnowledgeBaseMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useAppTranslation();

  function openLink(link: string) {
    const tempLink = document.createElement("a");
    tempLink.href = link;
    tempLink.target = "_blank";
    tempLink.rel = "noopener noreferrer";
    tempLink.click();
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickApp = () => {
    openLink(
      "https://alloweat.s3.eu-central-1.amazonaws.com/public/Wiki+all.pdf",
    );
    handleClose();
  };

  const onClickMobileApp = () => {
    openLink(
      "https://alloweat.s3.eu-central-1.amazonaws.com/public/Wiki+app+ae_fin.pdf",
    );
    handleClose();
  };

  return (
    <KnowledgeBaseMenuWrapper>
      <StyledButton onClick={handleClick}>
        <QuestionMark size="w-6 h-6" />
      </StyledButton>
      <Menu
        variant="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={onClickApp}>{t("common.app")}</MenuItem>

        <MenuItem onClick={onClickMobileApp}>{t("common.mobile_app")}</MenuItem>
      </Menu>
    </KnowledgeBaseMenuWrapper>
  );
};
