import {
  UIEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useController, useWatch } from "react-hook-form";
import { useDebounce } from "react-use";

import { Alert, CircularProgress } from "@mui/material";
import { round } from "lodash";

import { useAppParams, useAppTranslation, useMealClientContext } from "@hooks";
import { useSearchDietProductsForReplacementInfiniteQuery } from "@hooks/queries/diets";
import { getTranslation } from "@utils/translations";
import { scaleNutrients } from "@utils";

import { CustomOption } from "./CustomOption";
import { SearchAutocomplete } from "./SearchAutocomplete";
import { useSelectedContext } from "./SelectedContext/SelectedContext";
import { ExchangeProductFormInput } from "./useExchangeProductForm";

interface SearchReplacementAutocompleteProps {
  idx: number;
}

export const SearchReplacementAutocomplete = ({
  idx,
}: SearchReplacementAutocompleteProps) => {
  const { currentLanguage } = useAppTranslation();
  const { patientId } = useAppParams();
  const client = patientId ? Number(patientId) : undefined;

  const { setSelectedReplacement, calculateDifferece } = useSelectedContext();
  const [query, setQuery] = useState<string>();
  const listboxRef = useRef<Element | null>(null);
  const { products, submit, isLoading, isFetchingNextPage, fetchNextPage } =
    useSearchDietProductsForReplacementInfiniteQuery(
      { client },
      {
        refetchOnMount: false,
      },
    );

  const optionsMapped = useMemo(
    () =>
      products?.map(p => ({
        id: p.id.toString(),
        label: getTranslation(p.translations, currentLanguage),
        nutrients: p.nutrients,
        clientContext: "clientContext" in p ? p.clientContext : undefined,
      })) ?? [],
    [currentLanguage, products?.length],
  );

  const {
    field: { value, onChange },
  } = useController<ExchangeProductFormInput, `products.${number}.toProduct`>({
    name: `products.${idx}.toProduct`,
  });
  const ratio = useWatch<ExchangeProductFormInput, `products.${number}.ratio`>({
    name: `products.${idx}.ratio`,
  });

  const selected = useMemo(() => {
    const option = optionsMapped.find(o => value && o.id === value.toString());

    if (!option) return;

    const scaledNutrients = scaleNutrients(option.nutrients, ratio, 1);

    calculateDifferece(scaledNutrients);
    return {
      ...option,
      nutrients: scaledNutrients,
    };
  }, [optionsMapped, value, ratio]);

  const handleScroll: UIEventHandler<HTMLUListElement> = useCallback(event => {
    const listboxNode = event.currentTarget;

    if (
      listboxNode.scrollTop + listboxNode.clientHeight >=
      listboxNode.scrollHeight - 1
    )
      fetchNextPage();
  }, []);

  const { productsClientContextMap, getSeverity, getProducts } =
    useMealClientContext();
  const clientContext = useMemo(
    () => (value ? productsClientContextMap.get(Number(value)) : undefined),
    [productsClientContextMap, value],
  );
  const { color, alert } = getSeverity(clientContext ?? {});

  useDebounce(() => submit({ client, query }), 200, [query, client]);

  const handleSearch = (v: string) => {
    if (!v) onChange(null);
    setQuery(v);
  };

  const handleChange = (id: number) => {
    onChange(id);
    getProducts([id]);
  };

  useEffect(() => {
    const details = products?.find(p => p.id.toString() === selected?.id);
    setSelectedReplacement(details ?? null);
  }, [selected]);

  return (
    <SearchAutocomplete
      options={optionsMapped}
      onSearch={handleSearch}
      popupIcon={
        isLoading || isFetchingNextPage ? (
          <CircularProgress size="w-[20px] h-[20px]" />
        ) : undefined
      }
      onChange={(e, v) => handleChange(Number(v.id))}
      value={selected}
      ListboxProps={{
        onScroll: handleScroll,
        ref: listboxRef,
      }}
      onBlur={() => !value && setQuery(undefined)}
      renderOption={CustomOption}
      grams={selected ? round(100 * ratio) : 0}
      helperText={alert && <Alert severity={color}>{alert}</Alert>}
    />
  );
};
