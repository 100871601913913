import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Carrot = (props: SvgIconProps) => (
  <SvgIcon width="17" height="17" viewBox="0 0 17 17" fill="none" {...props}>
    <path
      d="M2.77012 12.6689C2.71701 12.7698 2.69231 12.8821 2.69914 12.9916C2.70596 13.1012 2.74398 13.2031 2.80846 13.2846C2.87293 13.3661 2.96097 13.4234 3.06149 13.4496C3.16201 13.4757 3.27053 13.4693 3.37341 13.4313C5.38518 12.781 7.32503 11.8439 9.13509 10.6478C9.50627 10.4161 9.79514 10.0571 9.94642 9.63968C10.0977 9.22222 10.1008 8.77556 9.95509 8.38508L9.94271 8.35582C9.64864 7.68245 9.1951 7.10927 8.6209 6.68533L8.59588 6.66733C8.25861 6.44744 7.84339 6.37324 7.42959 6.45893C7.0158 6.54463 6.63248 6.78421 6.35299 7.13183C4.9289 8.81565 3.72263 10.6799 2.77012 12.6689Z"
      stroke={props.fill ?? "currentColor"}
      strokeWidth="0.75"
      strokeMiterlimit="10"
      fill="none"
    />
    <path
      d="M9.55119 10.2064L8.59256 8.99536"
      stroke={props.fill ?? "currentColor"}
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinecap="round"
      fill="none"
    />
    <path
      d="M4.74323 9.34119L5.70185 10.5523"
      stroke={props.fill ?? "currentColor"}
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinecap="round"
      fill="none"
    />
    <path
      d="M10.6079 3.01979L10.6042 3.01029C10.5969 2.99231 10.5899 2.97539 10.5824 2.95948C10.5748 2.94357 10.5641 2.91957 10.5537 2.90007C10.5434 2.88058 10.5344 2.86356 10.5254 2.84897L10.5185 2.84035C10.502 2.81272 10.4834 2.7866 10.463 2.76216C10.3244 2.58698 10.1273 2.47301 9.90502 2.43953C9.68274 2.40606 9.44888 2.45514 9.24295 2.57845C9.03702 2.70176 8.87164 2.89175 8.77472 3.11635C8.67781 3.34095 8.65531 3.5864 8.71102 3.81123C8.54426 3.89582 8.40733 4.03861 8.32616 4.21257C8.24498 4.38654 8.22518 4.57962 8.27049 4.75529C8.3158 4.93097 8.4231 5.07705 8.57206 5.1659C8.72102 5.25475 8.90131 5.28021 9.07886 5.23747C9.22371 5.36161 9.3346 5.52309 9.40228 5.70841C9.46995 5.89372 9.49245 6.09751 9.46789 6.30277C10.9617 4.52207 10.788 3.47888 10.6079 3.01979Z"
      stroke={props.fill ?? "currentColor"}
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinecap="round"
      fill="none"
    />
    <path
      d="M13.6963 6.92005L13.7045 6.92559C13.7199 6.93628 13.7344 6.94659 13.7479 6.9572C13.7613 6.96781 13.7817 6.98323 13.798 6.99743C13.8143 7.01162 13.8286 7.02398 13.8406 7.03592L13.8474 7.04451C13.8703 7.06668 13.8914 7.09075 13.9106 7.11649C14.0492 7.29169 14.1208 7.51735 14.1134 7.75543C14.1059 7.9935 14.0199 8.2294 13.8697 8.42322C13.7196 8.61704 13.5146 8.75694 13.2893 8.81929C13.064 8.88164 12.8323 8.86262 12.6332 8.76545C12.526 8.92555 12.3696 9.04437 12.1938 9.09935C12.018 9.15432 11.8349 9.14163 11.6795 9.06369C11.524 8.98574 11.4069 8.84797 11.3503 8.67651C11.2938 8.50505 11.3018 8.31184 11.3728 8.13356C11.2827 7.96069 11.152 7.81684 10.9917 7.71401C10.8314 7.61118 10.6462 7.55236 10.4514 7.54246C12.3629 6.28965 13.3013 6.65235 13.6963 6.92005Z"
      stroke={props.fill ?? "currentColor"}
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinecap="round"
      fill="none"
    />
    <path
      d="M13.6372 6.27745C13.8703 6.06986 14.0294 5.78644 14.0839 5.48181C14.1384 5.17718 14.0843 4.87288 13.9322 4.62748C14.0628 4.43661 14.1335 4.21199 14.1335 3.98816C14.1335 3.76432 14.0627 3.55365 13.932 3.38851C13.8014 3.22337 13.6181 3.11291 13.4104 3.07408C13.2026 3.03526 12.9819 3.07023 12.7821 3.17362C12.5806 2.97213 12.3076 2.86293 12.0154 2.86706C11.7232 2.87118 11.4325 2.98833 11.1994 3.19597"
      stroke={props.fill ?? "currentColor"}
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinecap="round"
      fill="none"
    />
  </SvgIcon>
);
