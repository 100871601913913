import { useController, useFormContext } from "react-hook-form";

import { Radio } from "@mui/material";

import { PregnantWomanValue, Trimester } from "@client/surveys";
import { useAppTranslation } from "@hooks";

import { PregnantWomanAnswerFormInputs } from "../forms";
import {
  FormControlLabelStyled,
  RadioGroupStyled,
  Title,
} from "./TrimesterRadio.styled";
import { useContext } from "react";
import { BlurContext } from "../BlurContext";
import { SAFARI_TAB_INDEX } from "@utils";

export const TrimesterRadio = () => {
  const { t } = useAppTranslation();
  const { handleBlur, handleFocus } = useContext(BlurContext);
  const { control, watch } = useFormContext<PregnantWomanAnswerFormInputs>();
  const isPregnantSelected =
    watch("value") === `${PregnantWomanValue.PREGNANT}`;
  const {
    field: { value, onChange },
  } = useController<PregnantWomanAnswerFormInputs>({
    control,
    name: "trimester",
  });

  if (!isPregnantSelected) return <></>;

  return (
    <div className="grid gap-4">
      <Title>
        {t("questionnaires.questions.system_pregnant_woman.which_trimester")}
      </Title>

      <RadioGroupStyled
        tabIndex={SAFARI_TAB_INDEX}
        onBlur={handleBlur}
        onFocus={handleFocus}
        value={value}
        onChange={(_, v) => onChange(v)}
      >
        <FormControlLabelStyled
          value={`${Trimester.I}`}
          control={<Radio />}
          label={`${Trimester.I} ${t(
            "questionnaires.questions.system_pregnant_woman.trimester",
          )}`}
        />
        <FormControlLabelStyled
          value={`${Trimester.II}`}
          control={<Radio />}
          label={`${Trimester.II} ${t(
            "questionnaires.questions.system_pregnant_woman.trimester",
          )}`}
        />
        <FormControlLabelStyled
          value={`${Trimester.III}`}
          control={<Radio />}
          label={`${Trimester.III} ${t(
            "questionnaires.questions.system_pregnant_woman.trimester",
          )}`}
        />
      </RadioGroupStyled>
    </div>
  );
};
