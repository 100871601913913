import { ReactNode, useMemo, useState } from "react";
import { useDebounce } from "react-use";

import { SelectOption } from "@components/FormAutocomplete";
import { useAppTranslation } from "@hooks";
import { Spinner } from "@components/Spinner";

import {
  StyledAutocomplete,
  StyledTextField,
} from "./SearchProductsAutocomplete.styled";
import { mapProducts } from "./searchProductsUtils";
import { SearchNoResults } from "@views/emptyStates/SearchNoResults";
import { useSearchFoodQuery } from "@hooks/queries/useSearchFoodQuery";

interface SearchProductsAutocompleteProps {
  disabled?: boolean;
  selectedProduct: SelectOption | null;
  onSelect?: (id: string[] | string | null) => void;
  variant?: "standard" | "outlined" | "filled";
  endAdornment?: ReactNode;
}

export const SearchProductsAutocomplete = ({
  selectedProduct,
  onSelect,
  variant = "standard",
  endAdornment,
}: SearchProductsAutocompleteProps) => {
  const { isPolishChosen, t } = useAppTranslation();
  const [query, setQuery] = useState<string | undefined>(undefined);
  const [debouncedQuery, setDebouncedQuery] = useState<string | undefined>(
    selectedProduct?.label,
  );

  useDebounce(
    () => {
      if (query !== undefined) {
        setDebouncedQuery(query);
        submit({ query });
      }
    },
    1000,
    [query],
  );

  const { products, isFetching, isLoading, submit } = useSearchFoodQuery(
    { query: debouncedQuery },
    { keepPreviousData: true, disableParams: true },
  );

  const productsMapped = useMemo(
    () =>
      mapProducts(
        products?.map(({ id, namePl, nameEn }) => ({
          id,
          descriptionPl: namePl,
          description: nameEn ?? undefined,
        })),
        selectedProduct,
        isPolishChosen,
      ),
    [products, isPolishChosen, selectedProduct],
  );

  const onSearch = (query: string) => setQuery(query);

  return (
    <StyledAutocomplete
      noOptionsText={<SearchNoResults />}
      value={selectedProduct ?? undefined}
      loading={isLoading}
      size="small"
      renderInput={params => (
        <StyledTextField
          autoFocus
          {...params}
          variant={variant}
          onChange={e => onSearch(e.target.value)}
          placeholder={t("meals.add_product") ?? ""}
          InputProps={{
            ...params.InputProps,
            endAdornment: isFetching ? (
              <Spinner size="w-4 h-4" />
            ) : (
              endAdornment
            ),
          }}
        />
      )}
      options={productsMapped ?? []}
      onChange={(_, value) => onSelect && onSelect(value?.id ?? null)}
      disableClearable
      clearOnBlur
      clearOnEscape
      forcePopupIcon={false}
      filterOptions={options => options}
      onOpen={() => setQuery(selectedProduct?.label ?? "")}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.label}
        </li>
      )}
    />
  );
};
