import { Box, Typography } from "@mui/material";
import { IconButton } from "@components/IconButton";
import { Close } from "@assets/icons";
import { t } from "i18next";
import { useNotesSidePanelContext } from "./NotesSidePanel.context";
import { useTasksContext } from "@views/dietician/Tasks/Tasks.context";
import { NotesSidePanelItem } from "./NotesSidePanelItem";
import {
  NotesSidePanelStyledDrawer,
  NotesSidePanelStyledList,
  NotesSidePanelStyledListItem,
  NotesSidePanelStyledTextField,
  NotesSidePanelStyledWrapper,
} from "./NotesSidePanel.styled";

import { useNotesManager } from "./NotesSidePanel.hooks";
import { useAppTranslation } from "@hooks";

export const NotesSidePanel = () => {
  const { t } = useAppTranslation();

  const {
    isNotesPanelOpen,
    handleCloseNotesPanel,
    setNoteDescription,
    setNoteId,
  } = useNotesSidePanelContext();
  const { handleOpenCreateModal } = useTasksContext();

  const {
    notes,
    handleEdit,
    handleChange,
    handleBlur,
    handleAdd,
    newNote,
    setNewNote,
    textFieldRefs,
    handleDelete,
  } = useNotesManager();

  return (
    <NotesSidePanelStyledDrawer
      anchor="right"
      slotProps={{
        backdrop: {
          invisible: true,
        },
      }}
      open={isNotesPanelOpen}
      onClose={handleCloseNotesPanel}
    >
      <NotesSidePanelStyledWrapper className="custom-scrollbar">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={1.5}
        >
          <Typography variant="h6" id="tasks-suggested-panel">
            {t("tasks.addNote")}
          </Typography>
          <IconButton color="gray" onClick={handleCloseNotesPanel}>
            <Close className="text-gray-450" />
          </IconButton>
        </Box>
        <NotesSidePanelStyledList className="divide-y-2 divide-dashed divide-primary-opacity">
          <NotesSidePanelStyledListItem>
            <NotesSidePanelStyledTextField
              value={newNote}
              onChange={e => setNewNote(e.target.value)}
              onBlur={handleAdd}
              autoFocus
              size="small"
              placeholder={t("notes.startTyping")}
              inputProps={{ maxLength: 1000 }}
              fullWidth
              multiline
            />
          </NotesSidePanelStyledListItem>
          {notes.map(({ note, id, isEditing }, index) => (
            <NotesSidePanelItem
              key={id}
              id={id}
              note={note}
              isEditing={isEditing}
              onEdit={handleEdit}
              onChange={handleChange}
              onBlur={handleBlur}
              onDelete={id => handleDelete(id)}
              textFieldRef={el => (textFieldRefs.current[index] = el)}
              setNoteDescription={setNoteDescription}
              handleOpenCreateModal={handleOpenCreateModal}
              setNoteId={setNoteId}
            />
          ))}
        </NotesSidePanelStyledList>
      </NotesSidePanelStyledWrapper>
    </NotesSidePanelStyledDrawer>
  );
};
