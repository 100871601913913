import { MouseEvent, ReactNode, useState } from "react";
import { ConfirmationPopover } from "@components/ConfirmationPopper";

interface ConfirmationWrapperProps {
  children: ReactNode;
  onConfirm: (e: MouseEvent<HTMLButtonElement>) => void;
  confirmationText: string;
  submitText: string;
  disabled?: boolean;
}

export const ConfirmationWrapper = ({
  onConfirm,
  children,
  confirmationText,
  submitText,
  disabled,
}: ConfirmationWrapperProps) => {
  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined);

  const handleClickAnchor = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  return (
    <>
      <div onClick={disabled ? () => null : handleClickAnchor}>{children}</div>
      <ConfirmationPopover
        onConfirm={onConfirm}
        confirmationText={confirmationText}
        submitText={submitText}
        setAnchor={setAnchorEl}
        anchor={anchorEl}
      />
    </>
  );
};
