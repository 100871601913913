import { memo, useCallback, useState } from "react";

import classNames from "classnames";

import { ArrowDown, Copy, Edit, Hide, Restore, Trash } from "@assets/icons";
import { IconButton } from "@components/IconButton";
import { Spinner } from "@components/Spinner";
import { ConfirmationIconButton } from "@components/ConfirmationIconButton";
import { useAppTranslation } from "@hooks";
import { ActionButtonsWrapper } from "./ActionButtons.styled";
import { Link } from "react-router-dom";

interface ActionButtonsProps {
  onEdit?: null | string;
  onCopy?: string;
  onHide?: () => Promise<void>;
  onDelete?: () => Promise<void>;
  onShow?: () => Promise<void>;
  onExpand?: () => void;
  collapsed: boolean;
}

export const ActionButtons = memo((props: ActionButtonsProps) => {
  const { onEdit, onCopy, onHide, onDelete, onShow, onExpand, collapsed } =
    props;

  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);
  const [isOnShowInProgress, setIsOnShowInProgress] = useState(false);
  const [isOnHideInProgress, setIsOnHideInProgress] = useState(false);

  const { t } = useAppTranslation();

  const onHideInternal = useCallback(async () => {
    if (onHide) {
      setIsOnHideInProgress(true);
      try {
        await onHide();
      } catch (error) {
        null;
      } finally {
        setIsOnHideInProgress(false);
      }
    }
  }, [onHide, setIsOnHideInProgress]);

  const onDeleteInternal = useCallback(async () => {
    if (onDelete) {
      setIsDeleteInProgress(true);
      try {
        await onDelete();
      } catch (error) {
        null;
      } finally {
        setIsDeleteInProgress(false);
      }
    }
  }, [onDelete, isDeleteInProgress]);

  const onShowInternal = useCallback(async () => {
    if (onShow) {
      setIsOnShowInProgress(true);
      try {
        await onShow();
      } catch (error) {
        null;
      } finally {
        setIsOnShowInProgress(false);
      }
    }
  }, [onShow, isOnHideInProgress]);

  async () => {
    if (onDelete) {
      setIsDeleteInProgress(true);
      try {
        await onDelete();
      } catch (error) {
        null;
      } finally {
        setIsDeleteInProgress(false);
      }
    }
  };

  return (
    <ActionButtonsWrapper>
      {onEdit && (
        <Link to={onEdit} onClick={e => e.stopPropagation()}>
          <IconButton>
            <Edit size="w-5 h-5" />
          </IconButton>
        </Link>
      )}
      {onEdit === null && (
        <div onClick={e => e.stopPropagation()}>
          <IconButton disabled={true}>
            <Edit size="w-5 h-5" />
          </IconButton>
        </div>
      )}
      {onCopy && (
        <Link to={onCopy} onClick={e => e.stopPropagation()}>
          <IconButton>
            <Copy size="w-5 h-5" />
          </IconButton>
        </Link>
      )}
      {onHide &&
        (isOnHideInProgress ? (
          <Spinner size="w-7 h-7" />
        ) : (
          <div onClick={e => e.stopPropagation()}>
            <ConfirmationIconButton
              icon={<Hide size="w-5 h-5" />}
              onConfirm={onHideInternal}
              confirmationText={t("confirmation.question_hide")}
              submitText={t("confirmation.hide")}
            />
          </div>
        ))}
      {onDelete &&
        (isDeleteInProgress ? (
          <Spinner size="w-7 h-7" />
        ) : (
          <div onClick={e => e.stopPropagation()}>
            <ConfirmationIconButton
              icon={<Trash size="w-5 h-5" />}
              onConfirm={onDeleteInternal}
              confirmationText={t("confirmation.question_delete")}
              submitText={t("confirmation.delete")}
            />
          </div>
        ))}
      {onShow &&
        (isOnShowInProgress ? (
          <Spinner size="w-7 h-7" />
        ) : (
          <div onClick={e => e.stopPropagation()}>
            <IconButton onClick={onShowInternal}>
              <Restore size="w-5 h-5" />
            </IconButton>
          </div>
        ))}
      <IconButton onClick={onExpand} className={"col-start-4"}>
        <ArrowDown
          size="w-5 h-5"
          className={classNames("transition-all", {
            "transform rotate-180": !collapsed,
          })}
        />
      </IconButton>
    </ActionButtonsWrapper>
  );
});
