import { ProgramRowType } from "@views/dietician/PatientProgramsNew/Grid/RowType";
import { useMemo } from "react";
import { PatientProgramsGridStyled } from "@views/dietician/PatientProgramsNew/PatientProgramsGrid.styled";
import useProgramDate from "@hooks/program/useProgramDate";
import { useAppTranslation } from "@hooks";

const DurationColumn = ({ program }: ProgramRowType) => {
  const { t } = useAppTranslation();
  const { start, end } = useProgramDate({
    startDate: program.startDate,
    endDate: program.endDate,
  });

  const duration = useMemo(() => {
    if (!end) {
      return t("patient.programs_grid.row.length.indefinitely");
    }

    const days = Math.abs(Math.round(start.diff(end, "day", true)));
    return t("patient.programs_grid.row.length.days.c", {
      count: days,
      variable: days,
    });
  }, [start, end, t]);

  return <PatientProgramsGridStyled>{duration}</PatientProgramsGridStyled>;
};

export default DurationColumn;
