export const isSelected = (
  link: string | null,
  pathname: string,
  activePaths: string[] | undefined,
) => {
  const isHome = link === "/";

  if (isHome) return link === pathname;

  if (link && pathname.startsWith(link)) return true;

  if (activePaths) return activePaths.some(path => pathname.startsWith(path));

  return false;
};
