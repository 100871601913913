import { QuestionTextDto, QuestionType } from "@client/surveys";

import { FormTextField } from "@components/FormTextField";
import { useUpdateSurveySubgroupQuestionMutation } from "@hooks/queries/surveys";

import { FormProvider } from "react-hook-form";
import { Question } from "../Question/Question";
import {
  TextConfigFormInputs,
  mapTextConfigForm,
  mapTextConfigRequest,
  useTextConfigForm,
} from "../forms";
import { QuestionEditWatch } from "../QuestionEditWatch";
import { useMemo } from "react";

interface QuestionTextProps {
  listIndex: number;
  totalQuestions: number;
  question: QuestionTextDto;
  onTypeChange: (currentType: QuestionType, newType: QuestionType) => void;
}

export const QuestionText = ({
  listIndex,
  totalQuestions,
  question,
  onTypeChange,
}: QuestionTextProps) => {
  const mappedForm = useMemo(() => mapTextConfigForm(question), [question]);
  const form = useTextConfigForm(mappedForm);
  const { mutate } = useUpdateSurveySubgroupQuestionMutation();

  const onSubmit = (data: TextConfigFormInputs) => {
    mutate({
      id: question.id.toString(),
      payload: mapTextConfigRequest(data, question),
    });
  };

  return (
    <FormProvider {...form}>
      <Question
        id={question.id}
        listIndex={listIndex}
        totalQuestions={totalQuestions}
        onTypeChange={onTypeChange}
        questionType={question.type}
      >
        <FormTextField disabled name="placeholder" size="small" />
      </Question>

      <QuestionEditWatch isLoading={false} onSubmit={onSubmit} />
    </FormProvider>
  );
};
