import { BinaryAnswer } from "@utils";
import { DEFAULT_MACROS_RANGE } from "./useProductsFiltersForm";

export const defaultFilters = {
  tags: [],
  nutrients: DEFAULT_MACROS_RANGE,
  query: "",
  foodGroup: [],
  onlyPl: BinaryAnswer.NO,
};
