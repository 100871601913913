import { QueryOptionsTyped } from "@hooks/queries";
import { useQuery } from "@tanstack/react-query";
import {
  fetchTagsProgramPreview,
  FetchTagsProgramPreviewParams,
  FetchTagsProgramPreviewResponse,
} from "@client/program/preview/fetchTagsProgramPreview";
import { programPreviewKeys } from "@hooks/queries/program/preview/programPreviewKeys";

export const getKey = (programId: FetchTagsProgramPreviewParams) =>
  programPreviewKeys.programTags(programId);

const useFetchTagsProgramPreview = (
  programId: FetchTagsProgramPreviewParams,
  options?: QueryOptionsTyped<FetchTagsProgramPreviewResponse>,
) => {
  return useQuery({
    queryKey: getKey(programId),
    queryFn: () => fetchTagsProgramPreview(programId),
    ...options,
  });
};

export default useFetchTagsProgramPreview;
