import { IconButton, styled } from "@mui/material";
import { FormTextField } from "@components/FormTextField";
import { FormCheckboxMui } from "@components/FormCheckboxMui";

export const ImgWrapper = styled("div")`
  position: relative;
  width: 9rem;
  height: 9rem;
`;

export const StyledImg = styled("img")`
  width: 100%;
  height: 100%;
  min-width: 9rem;
  border-radius: 0.25rem;
`;

export const StyledConfirmationIconButton = styled("div")`
  position: absolute;
  background: white;
  border-radius: 50%;
  bottom: -0.5rem;
  right: -0.5rem;
`;

export const StyledFormTextField = styled(FormTextField)`
  max-width: 30rem;
  width: 100%;
  height: 2.5rem;
`;

export const ContentWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 2rem;
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0;
  width: 9rem;
  height: 9rem;
  &.MuiIconButton-root:hover {
    border-radius: 0;
  }
`;

export const InputsContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  width: 100%;
`;

export const ToggleButtonGroupWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: end;
`;

export const RecipeVerifiedWrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const StyledFormCheckboxMui = styled(FormCheckboxMui)`
  margin-right: 0;
`;

export const StyledImgInput = styled("input")`
  display: none;
`;

export const MainSectionWrapper = styled("div")`
  display: grid;
  gap: 2rem;

  & .tagsInput {
    max-width: 30rem;
  }
`;
