import { useMutation } from "@tanstack/react-query";
import { useAppTranslation } from "@hooks";
import { MutationOptions } from "./types";
import { toast } from "react-toastify";
import { ResendRegisterEmailProps, resendRegisterEmail } from "@client";

export function useResendRegisterEmailMutation(options?: MutationOptions) {
  const { t } = useAppTranslation();

  return useMutation(
    (data: ResendRegisterEmailProps) => resendRegisterEmail(data),
    {
      ...options,
      onSuccess: () => {
        toast.success(t("auth.register_clinic.confirm.resend_success"));
        options?.onSuccess && options.onSuccess();
      },
    },
  );
}
