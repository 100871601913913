import { Dialog, DialogContent, Stack } from "@mui/material";
import { Dispatch } from "react";
import DialogTitle from "@components/Alloweat/Dialog/DialogTitle";
import MeasurementPhotosContent from "@components/Client/PatientBodyMeasurementsGalleryModal/MeasurementPhotosContent";
import { IconButton } from "@components/Alloweat/IconButton";
import { useAppTranslation } from "@hooks";
import { CloseIcon } from "@icons/index";

type ImageGalleryModalProps = {
  open: boolean;
  setOpen: Dispatch<boolean>;
  patientId: number;
};

const PatientBodyMeasurementsGalleryModal = ({
  open,
  setOpen,
  patientId,
}: ImageGalleryModalProps) => {
  const { t } = useAppTranslation();

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: { height: "calc(100vh - 64px)", background: "#FBFAFC" },
      }}
    >
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <span>
            {t(
              "patient.health_and_goal.body_measurement_tab.image_gallery_modal.title",
            )}
          </span>

          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon color="727272" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ display: "flex" }}>
        <MeasurementPhotosContent patientId={patientId} />
      </DialogContent>
    </Dialog>
  );
};

export default PatientBodyMeasurementsGalleryModal;
