import { memo } from "react";
import { useFormContext, useFormState } from "react-hook-form";

import { Alert, InputAdornment } from "@mui/material";

import { useAppTranslation } from "@hooks";
import { Labeled } from "@components/Labeled";
import { decimalInput0 } from "@utils/inputs";
import { FormTextField } from "@components/FormTextField";

interface DurationSelectProps {
  edit?: boolean;
}

export const DurationSelect = memo(({ edit = false }: DurationSelectProps) => {
  const { t } = useAppTranslation();
  const { isDirty } = useFormState({ name: "duration" });
  const { control } = useFormContext();

  return (
    <Labeled label={t("addNutritionalProgram.step1.specifyDurationTime")}>
      <div className="flex gap-6 items-baseline">
        <FormTextField
          control={control}
          name="duration"
          variant="standard"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{t("common.days")}</InputAdornment>
            ),
            inputComponent: decimalInput0,
          }}
        />
      </div>
      {edit && isDirty && (
        <div className="w-1/2 pt-6">
          <Alert severity="warning">
            {t("addNutritionalProgram.step1.duration_change_info")}
          </Alert>
        </div>
      )}
    </Labeled>
  );
});
