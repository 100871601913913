import { useEffect } from "react";
import { useForm } from "react-hook-form";

import * as yup from "yup";

import {
  WeightAnswerQuestionDto,
  UpdateSingedSurveyRequest,
} from "@client/surveys";
import { yupResolver } from "@hookform/resolvers/yup";

export const useWeightAnswerForm = (
  values: WeightAnswerFormInputs | undefined,
  required: boolean,
) => {
  const resolver = yup.object().shape({
    value: required ? yup.string().required() : yup.string().nullable(),
  });

  const form = useForm<WeightAnswerFormInputs>({
    resolver: yupResolver(resolver),
  });

  useEffect(() => {
    if (values) form.reset(values);
  }, [values]);

  return form;
};

export const mapWeightAnswerForm = (
  data?: WeightAnswerQuestionDto,
): WeightAnswerFormInputs => {
  if (!data) return { value: null };
  return { value: String(data.value) };
};

export const mapWeightAnswerRequest = (
  data: WeightAnswerFormInputs,
  questionId: number,
): UpdateSingedSurveyRequest => {
  if (!data.value) return { questionId, answer: { value: null } };
  return { questionId, answer: { ...data, value: Number(data.value) } };
};

export interface WeightAnswerFormInputs {
  value: string | null;
}
