import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import IrrigationSummaryCardOfDay from "@views/dietician/PatientMonitoring2/components/SummaryCard/Irrigation/IrrigationSummaryCardOfDay";
import IrrigationSummaryCardOfDays from "@views/dietician/PatientMonitoring2/components/SummaryCard/Irrigation/IrrigationSummaryCardOfDays";

const IrrigationSummaryCardFactory = () => {
  const { type } = useMonitoring();

  if (type.type === "day") {
    return <IrrigationSummaryCardOfDay />;
  }

  return <IrrigationSummaryCardOfDays />;
};

export default IrrigationSummaryCardFactory;
