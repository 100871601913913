import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@mui/material";

import { Eye, Gear } from "@assets/icons";
import { PageNavigationHeader } from "@components/PageNavigationHeader";
import {
  useAppParams,
  useAppTranslation,
  useModal,
  useUserRoles,
} from "@hooks";

import { LangDto } from "@client";
import { ApiError } from "@components/ApiError";
import { Spinner } from "@components/Spinner";
import { TableOfContentsContextProvider } from "@context/TableOfContentsContext";
import { useFetchSurveyFullQuery } from "@hooks/queries/surveys";

import { QuestionnaireAnswerPreviewModal } from "@views/public/QuestionnaireAnswerPreviewModal/QuestionnaireAnswerPreviewModal";
import { ThemeProviderWrapperNew } from "themeNew";
import { EditQuestionnaireModal } from "../AddQuestionnaireModal/";
import { ContentSelector } from "./ContentSelector";
import {
  ContentSelectorWrapper,
  PreviewButton,
  QuestionnaireEditWrapper,
  SettingsButton,
  TableOfContentsWrapper,
} from "./QuestionnaireEdit.styled";
import { ReviewedCheckbox } from "./ReviewedCheckbox";
import { SideMenuActions } from "./SideMenuActions";

export const QuestionnaireEdit = () => {
  const { t } = useAppTranslation();
  const { questionnaireId } = useAppParams();
  const { modalOpened, onModalClose, onModalOpen } = useModal();
  const { isAdminClinicMember } = useUserRoles();
  const {
    modalOpened: previewOpen,
    onModalOpen: onPreviewOpen,
    onModalClose: onPreviewClose,
  } = useModal();
  const navigate = useNavigate();
  const { survey, isLoading, isError } =
    useFetchSurveyFullQuery(questionnaireId);

  const questionnaireValue = useMemo(
    () => ({
      name: survey?.name ?? "",
      langId: survey?.langId === LangDto.EN ? 2 : 1,
    }),
    [survey],
  );

  if (isLoading) return <Spinner />;

  if (isError || !survey) return <ApiError />;

  const { name, groups } = survey;

  return (
    <ThemeProviderWrapperNew>
      <QuestionnaireEditWrapper>
        <TableOfContentsContextProvider>
          <div className="grid gap-4">
            <PageNavigationHeader title={name} onClick={() => navigate(-1)}>
              <div className="flex gap-2">
                <PreviewButton color="primary" onClick={onPreviewOpen}>
                  <Eye />
                </PreviewButton>
                <SettingsButton
                  startIcon={<Gear />}
                  variant="outlined"
                  onClick={onModalOpen}
                >
                  {t("questionnaires.settings")}
                </SettingsButton>
              </div>
            </PageNavigationHeader>
            {isAdminClinicMember && (
              <div className="justify-self-end">
                <ReviewedCheckbox defaultChecked={survey.reviewed} />
              </div>
            )}
            <TableOfContentsWrapper>
              <SideMenuActions groups={groups} />

              <ContentSelectorWrapper>
                <ContentSelector />
              </ContentSelectorWrapper>
            </TableOfContentsWrapper>
          </div>

          <EditQuestionnaireModal
            onClose={onModalClose}
            open={modalOpened}
            questionnaireValue={questionnaireValue}
          />
        </TableOfContentsContextProvider>
      </QuestionnaireEditWrapper>
      <QuestionnaireAnswerPreviewModal
        id={Number(questionnaireId)}
        open={previewOpen}
        onClose={onPreviewClose}
      />
    </ThemeProviderWrapperNew>
  );
};
