import { IdDto, postProgramDayDiet, PostProgramDayDietPayload } from "@client";
import { useMutation } from "@tanstack/react-query";
import { MutationOptions } from "./types";
import { ApiResponse } from "@typeDefinitions";

export const usePostProgramDayDietMutation = (options?: MutationOptions) => {
  return useMutation<
    ApiResponse<IdDto>,
    unknown,
    UsePostProgramDayDietMutationPayload
  >(
    ({ programId, dayId, payload }) =>
      postProgramDayDiet(programId, dayId, payload),
    options,
  );
};

interface UsePostProgramDayDietMutationPayload {
  programId: string;
  dayId: string;
  payload: PostProgramDayDietPayload;
}
