import { TabsChipsFiltersForm } from "@components/Filters/TabsChipsFilter/TabsChipsFilterForm";
import { useTaskFilterOptions } from "@hooks/tasks/useTaskFilterOptions";

export const TaskTags = () => {
  const { filteredTagsOptions } = useTaskFilterOptions();

  if (!filteredTagsOptions.length) return null;

  return (
    <TabsChipsFiltersForm
      name="customTagsIds"
      options={filteredTagsOptions}
      multiple
    />
  );
};
