import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useAppTranslation } from "@hooks";

import { LoadingButton } from "@mui/lab";
import { Trans } from "react-i18next";

export interface OptimizationWarningModalProps {
  onClose: () => void;
  onClick: () => void;
  open: boolean;
}

export const OptimizationWarningModal = ({
  onClose,
  onClick,
  open,
}: OptimizationWarningModalProps) => {
  const { t } = useAppTranslation();

  return (
    <Dialog onClose={onClose} open={open} maxWidth="small">
      <DialogTitle>
        {t("patient.schedule.optimization.alert_attention_title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="whitespace-pre-line">
          <Trans i18nKey="patient.schedule.optimization.warning" />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} variant="contained" color="inherit">
          {t("common.cancel")}
        </Button>
        <LoadingButton onClick={onClick} variant="contained">
          {t("patient.schedule.optimization.update")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
