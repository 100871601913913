import { useCallback, useMemo } from "react";

import { useMediaQuery, useTheme } from "@mui/material";
import _ from "lodash";

import { useAppTranslation } from "@hooks";

import { DietMealListItemCreatorDto } from "@client/diets/creator";
import { Nutrient } from "@typeDefinitions";
import { CARBS_ID, ENERGY_ID, FATS_ID, PROTEIN_ID } from "@utils/macros";

import { Label } from "../../common/Label";
import { ButtonPlaceholder, SummaryWrapper, Title } from "./SummaryRow.styled";
import { SummaryTextField } from "./SummaryTextField";
import { getNutrientStatus } from "./summaryRowUtils";

interface SummaryRowProps {
  showMacros: boolean;
  meals: DietMealListItemCreatorDto[] | undefined;
  targetNutrients: Nutrient[];
}
export const SummaryRow = ({
  showMacros,
  meals,
  targetNutrients,
}: SummaryRowProps) => {
  const { breakpoints } = useTheme();
  const { t } = useAppTranslation();
  const upMedium = useMediaQuery(`${breakpoints.up("medium")}`);

  const mealsTargets = useMemo(
    () =>
      _(meals)
        .map(m => m.targets)
        .flatten()
        .groupBy("id")
        .map((items, id) => ({
          id: Number(id),
          value: _.sumBy(items, "value"),
        }))
        .value(),
    [meals],
  );

  const getStatus = useCallback(
    (id: number) => getNutrientStatus(id, mealsTargets, targetNutrients),
    [mealsTargets, targetNutrients],
  );

  if (upMedium)
    return (
      <SummaryWrapper>
        <Label label={false} flex={2}>
          <Title>{t("diet.total")}</Title>
        </Label>
        <Label label={false}>
          <div />
        </Label>
        <Label label={false}>
          <SummaryTextField getStatus={getStatus} id={ENERGY_ID} />
        </Label>
        <Label label={false}>
          <SummaryTextField getStatus={getStatus} id={ENERGY_ID} percentage />
        </Label>
        {!showMacros && <ButtonPlaceholder />}
        <Label label={false} hidden={!showMacros}>
          <SummaryTextField getStatus={getStatus} id={PROTEIN_ID} />
        </Label>
        <Label label={false} hidden={!showMacros}>
          <SummaryTextField getStatus={getStatus} id={FATS_ID} />
        </Label>
        <Label label={false} hidden={!showMacros}>
          <SummaryTextField getStatus={getStatus} id={CARBS_ID} />
        </Label>
        {showMacros && <ButtonPlaceholder />}
      </SummaryWrapper>
    );
  return (
    <SummaryWrapper>
      <div className="flex gap-[8px] items-end">
        <Title>{t("diet.total")}</Title>
      </div>

      <div className="flex gap-[8px]">
        <Label label={t("common.energy_efficiency")}>
          <SummaryTextField getStatus={getStatus} id={ENERGY_ID} />
        </Label>
        <Label label={t("diet.percentage")}>
          <SummaryTextField getStatus={getStatus} id={ENERGY_ID} percentage />
        </Label>
        <Label label={t("common.protein")} hidden={!showMacros}>
          <SummaryTextField getStatus={getStatus} id={PROTEIN_ID} />
        </Label>
        <Label label={t("common.fats")} hidden={!showMacros}>
          <SummaryTextField getStatus={getStatus} id={FATS_ID} />
        </Label>
        <Label label={t("common.carbs")} hidden={!showMacros}>
          <SummaryTextField getStatus={getStatus} id={CARBS_ID} />
        </Label>
      </div>
    </SummaryWrapper>
  );
};
