import { styled } from "@mui/material";

interface DotProps {
  background: string;
}
export const Dot = styled("div")<DotProps>`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: ${({ background }) => background};
  margin-top: 4px;
`;

export const Name = styled("span")`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;
export const Percentage = styled("span")`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;
export const Value = styled("span")`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;
