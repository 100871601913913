import { UpdateClientPaymentsDto, updateClientPayments } from "@client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  MutationOptions,
  fetchClientQueryKey,
  fetchClientsQueryKey,
} from "./queries";
import { fetchClientEventsQueryKey } from "./queries/useFetchClientEventsQuery";
import { searchClientsQueryKey } from "./queries/settings";

export const useUpdateClientPayments = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, UpdateClientPaymentsProps>(
    ({ id, payload }) => updateClientPayments(id, payload),
    {
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries([fetchClientQueryKey, Number(id)]);
        queryClient.invalidateQueries([fetchClientEventsQueryKey, id]);
        queryClient.invalidateQueries([fetchClientsQueryKey]);
        queryClient.invalidateQueries([searchClientsQueryKey]);
        options?.onSuccess && options?.onSuccess();
      },
      ...options,
    },
  );
};

interface UpdateClientPaymentsProps {
  id: string;
  payload: UpdateClientPaymentsDto;
}
