import { useAppTranslation } from "@hooks";
import { ControllerProps, Controller } from "react-hook-form";
import { Box, TextField, TextFieldProps, Typography } from "@mui/material";
import { ReactNode } from "react";

interface AdditionalFormTextFieldProps {
  errorIcon?: ReactNode;
}

export type FormTextFieldProps = Omit<ControllerProps<any>, "render"> &
  Omit<TextFieldProps, "onChange" | "error"> &
  AdditionalFormTextFieldProps;

export const FormTextField = ({ errorIcon, ...props }: FormTextFieldProps) => {
  const { t } = useAppTranslation();

  return (
    <Controller
      {...props}
      render={({
        field: { onChange, value, onBlur, ref },
        fieldState: { error },
      }) => (
        <TextField
          ref={ref}
          {...props}
          value={value}
          onChange={onChange}
          onBlur={e => {
            onBlur();
            props.onBlur && props.onBlur(e);
          }}
          error={!!error}
          helperText={
            error?.message ? (
              <span className="flex items-center gap-1">
                {errorIcon}
                <span className="block py-1">{t(error.message)}</span>
              </span>
            ) : (
              props.helperText
            )
          }
        />
      )}
    />
  );
};
