import React, { ChangeEvent, useMemo } from "react";

import { useAppTranslation } from "@hooks";
import classnames from "classnames";
import { Input } from "@components/Input";
import { Select } from "@components/Select";
import { SelectOption } from "@components/SelectOption";

export const FoodMeasureEditor = ({
  measures,
  setGrams,
  setMeasure,
  grams,
  measureId,
  className,
}: FoodMeasureEditorProps) => {
  const { t, isPolishChosen } = useAppTranslation();

  const selectedMeasure = useMemo(
    () => measures.find(measure => measure.id === measureId),
    [measureId],
  );
  const amount = useMemo(
    () => Math.round((grams / (selectedMeasure?.grams ?? 0)) * 10) / 10,
    [selectedMeasure, grams],
  );

  const handleGramsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setGrams(parseInt(e.target.value) || 1);
  };
  const handleAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
    setGrams((parseInt(e.target.value) || 1) * (selectedMeasure?.grams || 0));
  };
  const handleMeasureChange = (v?: number | string) => {
    if (!v) return;
    setMeasure(typeof v === "number" ? v : parseInt(v));
  };

  return (
    <div className={classnames("grid grid-cols-3 gap-x-3", className)}>
      <span className="font-semibold text-xs">{t("products.grams")}</span>
      <span className="font-semibold text-xs">{t("common.amount")}</span>
      <span className="font-semibold text-xs">{t("products.measure")}</span>
      <Input
        fullWidth
        textCenter
        value={grams}
        type="number"
        min={1}
        onChange={handleGramsChange}
      />
      <Input
        fullWidth
        textCenter
        value={amount}
        type="number"
        min={1}
        onChange={handleAmountChange}
      />
      <Select
        fullWidth
        value={measureId}
        onChange={handleMeasureChange}
        inputProps={{ className: "h-full" }}
      >
        {measures.map(measure => (
          <SelectOption value={measure.id}>
            {isPolishChosen ? measure.description : measure.descriptionEn}
          </SelectOption>
        ))}
      </Select>
    </div>
  );
};

export interface FoodMeasureEditorProps {
  measures: FoodMeasure[];
  grams: number;
  measureId: number;
  setGrams: (grams: number) => void;
  setMeasure: (measureId: number) => void;
  className?: string;
}

interface FoodMeasure {
  id: number;
  description: string;
  descriptionEn: string;
  sortOrder: number;
  grams: number;
}
