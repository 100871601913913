import { LoadingButton } from "@mui/lab";
import { Typography, styled } from "@mui/material";

export const ContentWrapper = styled("div")`
  padding: 4px 0;
  width: 240px;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.medium[600]};
  font-size: 12px;
  font-weight: 500;
  line-height: 200%;
  padding: 8px 12px;
  text-transform: uppercase;
`;

export const MenuButton = styled(LoadingButton)`
  justify-content: start;
  padding: 0 12px;
  width: 100%;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
  line-height: 150%;
  height: 40px;
`;
