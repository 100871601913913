import { Menu, MenuItem, styled } from "@mui/material";

export const MenuStyled = styled(Menu)`
  & .MuiList-root {
    display: grid;
    gap: 4px;
    border-radius: 8px;
    padding: 4px 0;
  }
` as typeof Menu;

export const MenuItemStyled = styled(MenuItem)`
  line-height: 24px;
  min-height: unset;
  padding: 8px 12px;
` as typeof MenuItem;
