import { Typography, useTheme } from "@mui/material";
import { Subtitle } from "@views/dietician/ClientProfile/ClientProfile.styled";
import { ReactNode, Ref } from "react";
import { ExtraSectionWrapper, Wrapper } from "./ClientProfileCardItem.styled";

interface ClientProfileCardItemProps {
  title?: string;
  subtitle?: ReactNode;
  extraEl?: ReactNode;
  hide?: boolean;
  tagsRef?: Ref<HTMLDivElement>;
}

export const ClientProfileCardItem = ({
  title,
  subtitle,
  extraEl,
  hide,
  tagsRef,
}: ClientProfileCardItemProps) => {
  const { colors } = useTheme();
  return (
    <Wrapper hide={hide}>
      <Typography variant="body2" color={colors.neutral.medium[800]}>
        {title}
      </Typography>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {extraEl && (
        <ExtraSectionWrapper ref={tagsRef}>{extraEl}</ExtraSectionWrapper>
      )}
    </Wrapper>
  );
};
