import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Pepper = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="14" viewBox="0 0 14 14">
      <path
        d="M11.3748 0.576413C11.6693 0.413913 12.04 0.518015 12.2025 0.812546L12.3244 1.03344C12.8145 1.9145 12.8271 2.95551 12.4107 3.82641C13.0887 4.55259 13.5 5.52505 13.5 6.59399C13.5 7.06372 13.4213 7.51567 13.274 7.93462C13.1191 8.37387 12.5504 8.34848 12.3396 7.93208L12.0426 7.33794C11.9385 7.13227 11.7277 7.00024 11.4967 7.00024H9.64062C9.30293 7.00024 9.03125 6.72856 9.03125 6.39087V4.35962C9.03125 4.02192 8.75957 3.75024 8.42188 3.75024H7.9877C7.44688 3.75024 7.22598 3.14341 7.71348 2.91489C8.23652 2.6686 8.82051 2.53149 9.4375 2.53149C10.1561 2.53149 10.8289 2.71684 11.4129 3.04438C11.5525 2.58227 11.5068 2.06938 11.2605 1.62759L11.1387 1.40669C10.9762 1.11216 11.0803 0.741452 11.3748 0.578952V0.576413ZM4.84687 9.2727L7.20312 4.56274H8.21875V6.59399C8.21875 7.26684 8.76465 7.81274 9.4375 7.81274H11.3723L11.9791 9.02641C9.95293 11.7711 6.70293 13.5002 3.12031 13.5002H1.62734C1.00527 13.5002 0.5 12.995 0.5 12.3729C0.5 11.8448 0.868164 11.3877 1.38359 11.2735L2.64805 10.9916C3.60273 10.7784 4.4127 10.1487 4.84941 9.2727H4.84687Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
