import { useEffect } from "react";
import { useAppTranslation } from "@hooks";
import { CurrenciesDto } from "@client";
import { useForm } from "react-hook-form";
import { Currencies } from "@utils/scheduleEvent";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export function useCurrenciesForm(currencies?: CurrenciesDto[]) {
  const { t } = useAppTranslation();
  const resolver = yupResolver(
    yup.object().shape({
      active: yup
        .object()
        .shape({
          pln: yup.boolean(),
          eur: yup.boolean(),
          usd: yup.boolean(),
          gbp: yup.boolean(),
        })
        .test(
          "at_least_one_selected",
          t("products_settings.at_least_one"),
          value => {
            const { pln, eur, usd, gbp } = value;
            return pln || eur || usd || gbp;
          },
        ),
      default: yup.mixed().when(["active"], (active: any, schema) => {
        const currencies: Currencies[] = [];

        if (active[0].pln) currencies.push(Currencies.PLN);
        if (active[0].eur) currencies.push(Currencies.EUR);
        if (active[0].usd) currencies.push(Currencies.USD);
        if (active[0].gbp) currencies.push(Currencies.GBP);

        return schema.oneOf(
          currencies,
          t("products_settings.select_from_active"),
        );
      }),
    }),
  );

  const form = useForm<CurrenciesFormInput>({ defaultValues, resolver });

  useEffect(() => {
    if (currencies) {
      form.reset({
        active: {
          pln: !!currencies?.find(c => c.currency === "PLN"),
          eur: !!currencies?.find(c => c.currency === "EUR"),
          usd: !!currencies?.find(c => c.currency === "USD"),
          gbp: !!currencies?.find(c => c.currency === "GBP"),
        },
        default: currencies?.find(c => c.isDefault)?.currency ?? Currencies.PLN,
      });
    }
  }, [currencies]);

  return form;
}

interface CurrenciesFormInput {
  active: ActiveCurrencies;
  default: Currencies;
}

interface ActiveCurrencies {
  pln: boolean;
  eur: boolean;
  usd: boolean;
  gbp: boolean;
}

const defaultValues: CurrenciesFormInput = {
  active: {
    pln: false,
    eur: false,
    usd: false,
    gbp: false,
  },
  default: Currencies.PLN,
};
