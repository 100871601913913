import { styled } from "@mui/material";

export const Wrapper = styled("li")`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px 12px;
`;

export const OptionText = styled("span")`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;
