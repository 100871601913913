import { DayDto } from "@client/program";
import _ from "lodash";

export function mapDaysToDiets(
  days: DayDto[],
  dayId: string | undefined,
  isPolishChosen: boolean,
) {
  return _(days)
    .map(el => {
      if (el.diet && el.id.toString() !== dayId) {
        return {
          id: el.diet.id,
          label: isPolishChosen
            ? `${el.name}, ${el.diet.title}`
            : `${el.nameEn}, ${el.diet.titleEn}`,
        };
      }
    })
    .compact()
    .value();
}
