import { useAppTranslation } from "@hooks";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";
import { DieticianSettingsTabs } from "@layouts/DieticianSettingsLayout/components/DieticianSettingsTabs";

export const DieticianSettingsLayout = () => {
  const { t } = useAppTranslation();
  const { account } = useFetchDietitianAccountQuery();

  return (
    <>
      <h3 className="text-2xl font-medium mb-6">
        {t("company_settings.title")}
      </h3>
      {account && <DieticianSettingsTabs />}
    </>
  );
};
