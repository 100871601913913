import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Broom = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path
        d="M15.7389 2.40657C16.0861 2.05935 16.0861 1.49546 15.7389 1.14823C15.3917 0.801012 14.8278 0.801012 14.4806 1.14823L9.14722 6.48157L8.18333 5.51768C8.06667 5.40101 7.90556 5.33435 7.73889 5.33435C7.39167 5.33435 7.11111 5.6149 7.11111 5.96212V6.77046L10.1194 9.77879H10.9278C11.275 9.77879 11.5556 9.49823 11.5556 9.15101C11.5556 8.98435 11.4889 8.82323 11.3722 8.70657L10.4083 7.74268L15.7417 2.40935L15.7389 2.40657ZM9.475 10.7066L6.18333 7.4149C4.99722 7.31212 3.81667 7.7399 2.96667 8.5899L2.74444 8.81212C2.125 9.43157 1.77778 10.2705 1.77778 11.1455C1.77778 11.3343 1.975 11.4566 2.14444 11.3732L3.56389 10.6649C3.70278 10.5955 3.82778 10.7788 3.71389 10.8843L0.202778 14.0399C0.075 14.1566 0 14.3232 0 14.4982C0 14.8371 0.275 15.1121 0.613889 15.1121H5.42778C6.50556 15.1121 7.53611 14.6843 8.3 13.9232C9.15 13.0732 9.575 11.8927 9.475 10.7066Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
