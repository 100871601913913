import { ITinyMceEditorProps, TinyMceEditor } from "@components/TinyMceEditor";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { EditorWrapper } from "./FormTinyMceEditor.styled";

interface FormTinyMceEditorProps<T extends FieldValues>
  extends ITinyMceEditorProps {
  name: Path<T>;
  control: Control<T>;
  onBlur?: () => void;
  placeholder?: string;
  inline?: boolean;
  outlined?: boolean;
}

export const FormTinyMceEditor = <T extends FieldValues>(
  props: FormTinyMceEditorProps<T>,
) => {
  const { name, control, outlined, ...rest } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <EditorWrapper outlined={outlined}>
            <TinyMceEditor
              {...rest}
              onChange={onChange}
              value={value as string | undefined}
            />
          </EditorWrapper>
        );
      }}
    />
  );
};
