import { useContentSelector } from "@context/TableOfContentsContext";
import { SubgroupContent } from "./SubgroupContent/SubgroupContent";

export const ContentSelector = () => {
  const { isSubgroup } = useContentSelector();

  if (isSubgroup) return <SubgroupContent />;

  return <></>;
};
