import { useNavigate } from "react-router-dom";

import { LoadingButton } from "@mui/lab";

import { Plus } from "@assets/icons";
import { useAppTranslation, useModalState } from "@hooks";
import { usePostCreatorDietMutation } from "@hooks/queries/diets/creator";
import { DIETS } from "@routes";

import { DietNameModal } from "../DietCreator/components/DietNameModal";
import {
  DietNameInputs,
  mapDietNameRequest,
} from "../DietCreator/components/DietNameModal/useDietNameForm";
import { IdDto } from "@client";

interface AddDietButtonProps {
  onSuccess?: (data: IdDto) => Promise<void>;
}
export const AddDietButton = ({ onSuccess }: AddDietButtonProps) => {
  const { t } = useAppTranslation();
  const [open, onOpen, onClose] = useModalState();
  const { mutate, isLoading } = usePostCreatorDietMutation();
  const navigate = useNavigate();

  const handleCreateDiet = (values: DietNameInputs) =>
    mutate(mapDietNameRequest(values), {
      onSuccess: async ({ data }) => {
        await onSuccess?.(data);
        navigate(`${DIETS}/${data.id}/edit`);
      },
    });

  return (
    <>
      <DietNameModal
        open={open}
        onClose={onClose}
        title={t("diets.add_modal.title")}
        confirmText={t("diets.add_modal.submit")}
        onSubmit={handleCreateDiet}
        loading={isLoading}
      />
      <LoadingButton
        variant="contained"
        startIcon={<Plus size="w-[12px] h-[12px]" className="stroke-current" />}
        onClick={onOpen}
        loading={isLoading}
      >
        {t("diets.add_diet")}
      </LoadingButton>
    </>
  );
};
