import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { ReactElement } from "react";
import useFetchPatientBodyMeasurementsSettings from "@hooks/queries/client/bodyMeasurement/useFetchPatientBodyMeasurementsSettings";
import { useClientParams } from "@hooks";
import useUpdatePatientBodyMeasurementSettingsMutation from "@hooks/queries/client/bodyMeasurement/useUpdatePatientBodyMeasurementSettingsMutation";
import { PatientBodyMeasurementsSettingsResource } from "@client/resources/PatientBodyMeasurementsSettingsResource";

export type FormPops = {
  measurementsBodyId: number[];
  requiredPhotos: boolean;
};

const SettingSectionFormWrapper = ({
  children,
  onSuccess,
}: Pick<SettingSectionFormProps, "children" | "onSuccess">) => {
  const id = useClientParams();

  const { data: bodyMeasurementsSettings } =
    useFetchPatientBodyMeasurementsSettings(
      {
        patientId: id,
      },
      { staleTime: Infinity },
    );

  if (!bodyMeasurementsSettings) {
    return null;
  }

  return (
    <SettingSectionForm
      onSuccess={onSuccess}
      bodyMeasurementsSettings={bodyMeasurementsSettings.data}
    >
      {children}
    </SettingSectionForm>
  );
};

type SettingSectionFormProps = {
  bodyMeasurementsSettings: PatientBodyMeasurementsSettingsResource;
  children: ReactElement | ReactElement[];
  onSuccess: () => void;
};

const SettingSectionForm = ({
  children,
  bodyMeasurementsSettings,
  onSuccess,
}: SettingSectionFormProps) => {
  const id = useClientParams();
  const mutation = useUpdatePatientBodyMeasurementSettingsMutation();

  const form = useForm<FormPops>({
    defaultValues: {
      measurementsBodyId: bodyMeasurementsSettings.important.map(
        ({ id }) => id,
      ),
      requiredPhotos: bodyMeasurementsSettings.requiredPhotos,
    },
  });

  const onSubmit: SubmitHandler<FormPops> = async data => {
    if (mutation.isLoading) {
      return;
    }

    return new Promise<void>(resolve => {
      mutation.mutate(
        {
          params: {
            patientId: id,
          },
          payload: {
            bodyMeasurementsId: data.measurementsBodyId,
            requiredPhotos: data.requiredPhotos,
          },
        },
        {
          onSuccess: () => {
            resolve();
            onSuccess();
          },
        },
      );
    });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>{children}</form>
    </FormProvider>
  );
};

export default SettingSectionFormWrapper;
