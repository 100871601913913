import { useMemo } from "react";

import { Measure, Nutrient } from "@typeDefinitions";
import { GLYCEMIC_INDEX_ID } from "@utils";

import { Spoon } from "@assets/icons";
import { SectionTitleWrapper } from "@components/PreviewDrawer/common/SectionTitleWrapper";
import { MacrosChart } from "../../common/MacrosChart/MacrosChart";
import { MeasurementRow } from "./MeasurementRow";
import { NutrientsSection } from "../../common/NutrientsSection";
import { CardStyled, DividerStyled } from "./NutrientsTab.styled";
import { t } from "i18next";

interface NutrientsTabProps {
  nutrients: Nutrient[];
  measures: Measure[];
}

export const NutrientsTab = ({ nutrients, measures }: NutrientsTabProps) => {
  const proportionalNutrients = useMemo(
    () =>
      nutrients.map(n => {
        if (n.id === GLYCEMIC_INDEX_ID) return n;
        return {
          ...n,
          value: n.value,
        };
      }),
    [nutrients],
  );

  return (
    <div className="grid gap-[24px]">
      <CardStyled>
        <MacrosChart nutrients={proportionalNutrients} />

        <DividerStyled />

        <SectionTitleWrapper Icon={Spoon} title={t("common.home_measurement")}>
          <div className="grid gap-[8px]">
            {measures.map(m => (
              <MeasurementRow key={m.id} id={m.id} value={m.grams} />
            ))}
          </div>
        </SectionTitleWrapper>
        <DividerStyled />

        <NutrientsSection nutrients={proportionalNutrients} />
      </CardStyled>
    </div>
  );
};

enum Tabs {
  PRODUCTS = "0",
  GENERAL = "1",
}
