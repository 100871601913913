import React from "react";
import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Users = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 31.264 26">
    <g id="_2_User" data-name="2 User" transform="translate(6.25 1.25)">
      <path
        id="Stroke_1"
        data-name="Stroke 1"
        d="M0,8.376A4.188,4.188,0,0,0,4.188,4.188,4.189,4.189,0,0,0,0,0"
        transform="translate(17.607 2.109)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Stroke_3"
        data-name="Stroke 3"
        d="M0,0A13.845,13.845,0,0,1,2.054.3,3.385,3.385,0,0,1,4.525,1.71a1.992,1.992,0,0,1,0,1.709,3.409,3.409,0,0,1-2.471,1.42"
        transform="translate(19.533 15.037)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Stroke_5"
        data-name="Stroke 5"
        d="M8.69,0c4.687,0,8.692.71,8.692,3.547S13.4,7.119,8.69,7.119C4,7.119,0,6.41,0,3.572S3.977,0,8.69,0Z"
        transform="translate(0.75 15.938)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Stroke_7"
        data-name="Stroke 7"
        d="M5.569,11.139a5.57,5.57,0,1,1,5.57-5.57A5.549,5.549,0,0,1,5.569,11.139Z"
        transform="translate(3.872 0.75)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  </SvgIcon>
);
