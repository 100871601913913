import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Hide = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <g id="Group_5153" data-name="Group 5153" transform="translate(0 0)">
      <path
        id="Path_1659"
        data-name="Path 1659"
        d="M13.75,23.75a10,10,0,1,0-10-10A10.012,10.012,0,0,0,13.75,23.75Zm0-18.462A8.462,8.462,0,1,1,5.288,13.75,8.471,8.471,0,0,1,13.75,5.288Z"
        transform="translate(-3.75 -3.75)"
        fill="currentColor"
      />
    </g>
    <g
      id="Group_5154"
      data-name="Group 5154"
      transform="translate(2.704 2.704)"
    >
      <path
        id="Path_1660"
        data-name="Path 1660"
        d="M8.912,22.736a.767.767,0,0,0,.544-.225L22.51,9.456a.769.769,0,0,0-1.088-1.087L8.368,21.423a.769.769,0,0,0,.543,1.313Z"
        transform="translate(-8.143 -8.143)"
        fill="currentColor"
      />
    </g>
    <g
      id="Group_6280"
      data-name="Group 6280"
      transform="translate(2.704 2.703)"
    >
      <path
        id="Path_1660-2"
        data-name="Path 1660"
        d="M8.912,22.736a.767.767,0,0,0,.544-.225L22.51,9.456a.769.769,0,0,0-1.088-1.087L8.368,21.423a.769.769,0,0,0,.543,1.313Z"
        transform="translate(-8.143 -8.143)"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
);
