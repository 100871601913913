import { CircularProgress, Dialog, Tooltip, Typography } from "@mui/material";
import {
  CloseButton,
  ModalWrapper,
  StyledDialogActions,
  Title,
  TitleWrapper,
} from "@components/BuyPlanModal/BuyPlanModal.styled";
import { Close } from "@assets/icons";
import usePdfExport from "@Pdf/hooks/usePdfExport";
import { TabsWrapper } from "@components/PreviewDrawer/Diet/MealDetails/Accordions/common.styled";
import { TabSwitch } from "@components/TabSwitch/TabSwitch";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import PdfTemplateTab from "@Pdf/components/Tabs/PdfTemplateTab";
import PdfSettingsTab from "@Pdf/components/Tabs/PdfSettingsTab";
import {
  UpdateClientFormInput,
  useAppParams,
  useAppTranslation,
  useModalState,
  useUpdateProgramPdfSettings,
} from "@hooks";
import {
  useFetchClientQuery,
  useFetchPatientProgramQuery,
} from "@hooks/queries";
import { FormProvider } from "react-hook-form";
import usePdfExportForm, {
  defaultValues,
} from "@Pdf/hooks/form/usePdfExportForm";
import { PdfExportPdfFormStateType } from "@Pdf/types/pdfTypes";
import dayjs from "dayjs";
import GeneratingPdfPlaceholder from "@Pdf/components/Placeholders/GeneratingPdfPlaceholder";
import SendingPdfPlaceholder from "@Pdf/components/Placeholders/SendingPdfPlaceholder";
import { toast } from "react-toastify";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";
import { StyledSubmitBtn } from "@components/ModalWrapper";
import { PDF_DISPLAY_CODE } from "@Pdf/defaults/pdfDefaults";
import usePdfImagesQuery from "@Pdf/hooks/query/usePdfImagesQuery";
import { EditClientInfoModal } from "@components/EditClientInfoModal";
import { ConfirmSendToClientModal } from "./ConfirmSendToClientModal";

type PropsType = {
  open: boolean;
  programId: number;
  patientId?: number;
  onClose: () => void;
};

const getPdfSettings = (values: PdfExportPdfFormStateType) => ({
  layoutId: null,
  showClinicName: true,
  showClinicFacility: true,
  showClinicPhoneNumber: true,
  showClinicEmail: true,
  showScheduleDate: true,
  showScheduleDayName: true,
  showMealHour: true,
  frequencyShoppingList: values.frequency,
  scheduleStartedAt: values.startDate
    ? dayjs(values.startDate).format("YYYY-MM-DD")
    : null,
  scheduleEndedAt: values.endDate
    ? dayjs(values.endDate).format("YYYY-MM-DD")
    : null,
});

const ExportPdfModal: FC<PropsType> = ({
  open,
  programId: propsProgramId,
  patientId: propsPatientId,
  onClose,
}) => {
  const { programId: paramProgramId, patientId: paramPatientId } =
    useAppParams();
  const programId = propsProgramId ?? paramProgramId;
  const patientId = propsPatientId ?? paramPatientId;

  const { account } = useFetchDietitianAccountQuery();

  const enterprise = useMemo(
    () => account?.clinic?.subscriptionNew?.programPdfType === "ENTERPRISE",
    [account],
  );

  const [submitting, setSubmitting] = useState(false);
  const shouldDownload = useRef(false);
  const { t } = useAppTranslation();
  const form = usePdfExportForm();
  const { onExport, onCancelExport } = usePdfExport();
  const { mutate } = useUpdateProgramPdfSettings(
    programId ? programId.toString() : "",
    {
      onSuccess: async () => {
        try {
          await onExport(
            programId,
            form.getValues(),
            shouldDownload.current,
            program?.langId,
          );
          setSubmitting(false);

          if (shouldDownload.current) {
            toast.success(t("pdf.upload_pdf_success"));
          } else {
            toast.success(t("pdf.send_to_client_pdf_success"));
          }
          onCloseModal();
        } catch (e) {
          console.error(e);
          setSubmitting(false);

          if (shouldDownload.current) {
            toast.error(t("pdf.upload_pdf_error"));
          } else {
            toast.error(t("pdf.send_to_client_pdf_error"));
          }
        }
      },
      onError: e => {
        console.error(e);
        setSubmitting(false);

        if (shouldDownload.current) {
          toast.error(t("pdf.upload_pdf_error"));
        } else {
          toast.error(t("pdf.send_to_client_pdf_error"));
        }
      },
    },
  );

  const onSendToClient = (email?: boolean) => {
    if (!email) return;

    form.handleSubmit(
      data => {
        shouldDownload.current = false;
        setSubmitting(true);

        mutate(getPdfSettings(data));
      },
      () => {
        setSubmitting(false);
      },
    )();
  };

  const onDownload = () => {
    form.handleSubmit(
      data => {
        shouldDownload.current = true;
        setSubmitting(true);

        mutate(getPdfSettings(data));
      },
      () => {
        shouldDownload.current = false;
        setSubmitting(false);
      },
    )();
  };

  const onCancel = () => {
    onCancelExport();
    setSubmitting(false);
    shouldDownload.current = false;
  };

  const { program } = useFetchPatientProgramQuery(
    patientId.toString(),
    programId ? programId.toString() : undefined,
  );
  const { client } = useFetchClientQuery(patientId);
  const imagesQuery = usePdfImagesQuery();

  useEffect(() => {
    if (!program || !imagesQuery.data) return;

    form.setValue("title", program.name);

    const now = new Date();
    let startDate = new Date();

    if (program.startDate) {
      startDate = new Date(program.startDate);

      if (startDate.getTime() < now.getTime()) startDate = now;

      form.setValue("startDate", startDate);
    } else {
      form.setValue("startDate", startDate);
    }

    if (program.finishDate) {
      let endDate = new Date(program.finishDate);

      if (endDate.getTime() < now.getTime()) {
        endDate = now;
        endDate.setDate(endDate.getDate() + 1);
      }

      form.setValue("endDate", endDate);
    } else {
      form.setValue("endDate", dayjs(startDate).add(3, "months").toDate());
    }

    form.setValue("micros", program.showNutrients);
    form.setValue("macros", program.showMacros);
    form.setValue("kcal", program.showCals);
    form.setValue("coverPageId", imagesQuery.data[0]?.url);
  }, [program, imagesQuery.data]);

  useEffect(() => {
    if (!enterprise) {
      form.setValue("options", [
        PDF_DISPLAY_CODE.WEEKDAY,
        PDF_DISPLAY_CODE.DATE,
        PDF_DISPLAY_CODE.MEAL_HOUR,
        PDF_DISPLAY_CODE.DAY_TYPE,
        PDF_DISPLAY_CODE.LOGO,
      ]);
      form.setValue("clinicOptions", [
        PDF_DISPLAY_CODE.CLINIC_LOGO,
        PDF_DISPLAY_CODE.CLINIC_NAME,
        PDF_DISPLAY_CODE.CLINIC_ADDRESS,
        PDF_DISPLAY_CODE.CLINIC_EMAIL,
        PDF_DISPLAY_CODE.CLINIC_PHONE,
      ]);
    }
  }, [enterprise]);

  const [tab, setTab] = useState("template");

  const tabs = [
    {
      id: "template",
      label: t("pdf.templateTab"),
    },
    {
      id: "settings",
      label: t("pdf.settingsTab"),
    },
  ];

  const onCloseModal = () => {
    if (program) {
      const values = { ...defaultValues };
      values.title = program.name;

      if (program.startDate) {
        values.startDate = new Date(program.startDate);
      }

      if (program.finishDate) {
        values.endDate = new Date(program.finishDate);
      }

      values.micros = program.showNutrients;
      values.macros = program.showMacros;
      values.kcal = program.showCals;

      form.reset(values, {
        keepErrors: false,
      });
    } else {
      form.reset(defaultValues, {
        keepErrors: false,
      });
    }
    onClose();
  };

  const [openedClientModal, onOpenClientModal, onCloseClientModal] =
    useModalState();
  const [openConfirmModal, onOpenConfirmModal, onCloseConfirmModal] =
    useModalState();

  const onClientUpdated = (updatedClient: UpdateClientFormInput) => {
    if (updatedClient.email) {
      onSendToClient(!!updatedClient.email);
    }
  };

  return (
    <>
      <Dialog open={open} id={"export-pdf-modal"}>
        <ModalWrapper
          style={{
            gap: "16px",
            display: "flex",
            flexDirection: "column",
            width: "fit-content",
          }}
        >
          <TitleWrapper>
            {!submitting && <Title>{t("pdf.modalTitle")}</Title>}
            {submitting && <div />}
            <CloseButton size="small" onClick={onCloseModal}>
              <Close size="w-3 h-3" />
            </CloseButton>
          </TitleWrapper>
          {submitting && (
            <>
              {shouldDownload.current && (
                <GeneratingPdfPlaceholder onCancel={onCancel} />
              )}
              {!shouldDownload.current && (
                <SendingPdfPlaceholder onCancel={onCancel} />
              )}
            </>
          )}
          {!submitting && (
            <>
              <TabsWrapper style={{ padding: 0 }}>
                <TabSwitch value={tab} onChange={setTab} tabs={tabs} />
              </TabsWrapper>
              {program && (
                <FormProvider {...form}>
                  {tab === "template" && (
                    <PdfTemplateTab enterprise={enterprise} />
                  )}
                  {tab === "settings" && (
                    <PdfSettingsTab enterprise={enterprise} program={program} />
                  )}
                </FormProvider>
              )}
              {!program && (
                <div className="grid place-items-center">
                  <CircularProgress />
                </div>
              )}
            </>
          )}
        </ModalWrapper>
        {!submitting && (
          <StyledDialogActions>
            <StyledSubmitBtn variant="outlined" onClick={onDownload}>
              {t("pdf.download")}
            </StyledSubmitBtn>
            <Tooltip
              placement={"top"}
              title={
                client?.email ? (
                  ""
                ) : (
                  <div className={"flex"}>
                    <Typography
                      sx={{
                        font: "400 10px/18px, Figtree, sans-serif",
                        letterSpacing: 0.4,
                      }}
                    >
                      {t("pdf.no_email")}
                    </Typography>
                    <button
                      style={{ marginLeft: "2px" }}
                      onClick={onOpenClientModal}
                    >
                      <Typography
                        sx={{
                          font: "400 10px/18px, Figtree, sans-serif",
                          textDecoration: "underline",
                          letterSpacing: 0.4,
                        }}
                      >
                        {t("pdf.no_email_link")}
                      </Typography>
                    </button>
                  </div>
                )
              }
              arrow
            >
              <StyledSubmitBtn variant="contained" onClick={onOpenConfirmModal}>
                {t("pdf.send_to_client")}
              </StyledSubmitBtn>
            </Tooltip>
          </StyledDialogActions>
        )}
      </Dialog>
      {client && (
        <EditClientInfoModal
          open={openedClientModal}
          initialTab={"2"}
          onSubmitted={onClientUpdated}
          onClose={onCloseClientModal}
          id={client.id}
        />
      )}
      <ConfirmSendToClientModal
        open={openConfirmModal}
        onClose={onCloseConfirmModal}
        onSubmit={() => onSendToClient(!!client?.email)}
      />
    </>
  );
};

export default ExportPdfModal;
