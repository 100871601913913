import { ListTabs } from "@hooks";
import {
  DEFAULT_MACROS_RANGE,
  RecipesFiltersFormInput,
} from "./useRecipesFiltersFormNew";

export const defaultFilters: RecipesFiltersFormInput = {
  tabs: ListTabs.ALL,
  tags: [],
  nutrients: DEFAULT_MACROS_RANGE,
  query: "",
};
