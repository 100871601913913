import { useMemo, useRef, useEffect } from "react";
import { isSameDay, format } from "date-fns";
import { EventWithType } from "@typeDefinitions";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";
import { getSubscriptionInfo } from "@utils/subscription";
import { mapCalendarScheduleEvents } from "@utils";
import { useSearchScheduleEvents } from "@hooks/queries";
import { Typography } from "@mui/material";
import {
  CreateEventButton,
  EventItem,
  EventTime,
  EventTitle,
} from "../../MiniCalendar/MiniCalendar.styled";
import { Plus } from "@assets/icons";
import { ScheduleVisitOverviewModal } from "../../../ScheduleVisit/_components/ScheduleVisitOverviewModal";
import { useCalendarActions } from "../../_hooks/useCalendarActions";
import { useTranslation } from "react-i18next";
import { useCalendarColors } from "@views/dietician/Calendar/CalendarColors.context";
import { getEventIcon } from "@views/dietician/Home/components/Calendar/_utils/getEventIcon";
import { useEventPosition } from "../../_hooks/useEventPosition";
import { useCalendarTimeline } from "../../_hooks/useCalendarTimeline";
import { ScheduleGoogleEventOverviewModal } from "../../../ScheduleVisit/_components/ScheduleGoogleEventOverviewModal";

const HOURS = Array.from({ length: 24 }, (_, i) => i);

interface BigCalendarDayViewProps {
  currentDate: Date;
  onVisitModalOpen: () => void;
  handleSetStartDate: (startDate: Date) => void;
}

interface EventWithPosition extends EventWithType {
  column?: number;
  totalColumns?: number;
}

export const BigCalendarDayView = ({
  currentDate,
  onVisitModalOpen,
  handleSetStartDate,
}: BigCalendarDayViewProps) => {
  const { t } = useTranslation();
  const { getNewDietitianColor, getEventStyles } = useCalendarColors();
  const { currentTimeLineRef } = useCalendarTimeline(currentDate);
  const { account } = useFetchDietitianAccountQuery();
  const { hasSubscription } = getSubscriptionInfo();
  const { calculateEventPosition } = useEventPosition();

  const {
    handleCloseModal,
    handleEventClick,
    selectedEventId,
    anchorEl,
    selectedGoogleId,
  } = useCalendarActions();

  const enabled = !!account?.clinic?.id && hasSubscription !== null;

  const { scheduleEvents } = useSearchScheduleEvents({ enabled });

  const calendarEvents = useMemo(
    () => mapCalendarScheduleEvents(scheduleEvents ?? []),
    [scheduleEvents],
  );

  const events = [...calendarEvents];

  const dayEvents = useMemo(() => {
    return events.filter(event => isSameDay(event.start, currentDate));
  }, [events, currentDate]);

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const allDayEvents = useMemo(
    () => dayEvents.filter(event => event.allDay),
    [dayEvents],
  );

  const timeBasedEvents = useMemo(
    () => dayEvents.filter(event => !event.allDay),
    [dayEvents],
  );

  const assignEventsToColumns = (
    events: EventWithType[],
  ): EventWithPosition[] => {
    const sortedEvents = [...events].sort(
      (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime(),
    );

    const eventGroups: EventWithPosition[][] = [];

    sortedEvents.forEach(event => {
      let placed = false;

      for (const group of eventGroups) {
        if (
          group.some(
            e =>
              new Date(event.start).getTime() < new Date(e.end).getTime() &&
              new Date(event.end).getTime() > new Date(e.start).getTime(),
          )
        ) {
          group.push(event);
          placed = true;
          break;
        }
      }

      if (!placed) {
        eventGroups.push([event]);
      }
    });

    const eventsWithColumns: any[] = [];

    eventGroups.forEach(group => {
      const columns: EventWithPosition[][] = [];

      group.forEach(event => {
        let placedInColumn = false;

        for (let i = 0; i < columns.length; i++) {
          const column = columns[i];
          if (
            new Date(event.start).getTime() >=
            new Date(column[column.length - 1].end).getTime()
          ) {
            event.column = i;
            column.push(event);
            placedInColumn = true;
            break;
          }
        }

        if (!placedInColumn) {
          event.column = columns.length;
          columns.push([event]);
        }
      });

      const totalColumns = columns.length;

      group.forEach(event => {
        eventsWithColumns.push({ ...event, totalColumns });
      });
    });

    return eventsWithColumns;
  };

  const eventsWithColumns = useMemo(
    () => assignEventsToColumns(timeBasedEvents),
    [timeBasedEvents],
  ) as EventWithPosition[];

  useEffect(() => {
    if (scrollContainerRef.current) {
      // Scroll to 8 AM by default
      scrollContainerRef.current.scrollTop = 8 * 90;
    }
  }, []);

  const handleCreateVisit = (startDate: Date) => {
    onVisitModalOpen();
    handleSetStartDate(startDate);
  };

  return (
    <div className="relative bg-white rounded-xl h-[calc(100vh-200px)] flex flex-col">
      <div
        ref={scrollContainerRef}
        className="overflow-y-auto relative custom-scrollbar"
      >
        <div className="relative">
          <div
            ref={currentTimeLineRef}
            className="absolute left-0 right-0 h-[2px] bg-purple-dark z-10"
            style={{ display: "none" }}
          />
          {HOURS.map((hour, index) => {
            const timeSlot = new Date(currentDate);
            timeSlot.setHours(hour, 0, 0, 0);

            return (
              <div key={index} className="h-[90px] group relative">
                <div className="border-solid h-full border-t border-gray-200">
                  <div className="h-full w-[80px] text-center border-r-[1px] border-gray-200 pt-[8px] sticky left-0 bg-white">
                    <Typography variant="body1" fontWeight="600">
                      {format(timeSlot, "HH:mm")}
                    </Typography>
                  </div>
                </div>
                <CreateEventButton
                  className="hidden group-hover:flex ml-[100px] translate-y-[-55px]"
                  onClick={() => handleCreateVisit(timeSlot)}
                >
                  <Plus width="8px" height="8px" />
                  <span>{t("calendar.add_visit")}</span>
                </CreateEventButton>
              </div>
            );
          })}
        </div>

        {eventsWithColumns.map((event, idx) => {
          const position = calculateEventPosition(event);
          const theme = getNewDietitianColor(event.dietitianId);
          const { backgroundColor, border } = getEventStyles(
            theme?.backgroundColor,
            !!event.googleId,
          );

          const totalColumns = event.totalColumns;
          const columnIndex = event.column;

          const eventWidth = `calc((100% - 120px) / ${totalColumns})`;
          const eventLeft = `calc(100px + (${columnIndex} * (${eventWidth})))`;

          const isShorter = position.height < 50;

          const additionalStyles = isShorter
            ? {
                display: "flex",
                gap: "4px",
                alignItems: "center",
                padding: "0 8px",
              }
            : {};

          return (
            <EventItem
              key={idx}
              onClick={e => handleEventClick(e, event.id, event.googleId)}
              style={{
                position: "absolute",
                top: position.top,
                height: position.height,
                left: eventLeft,
                width: eventWidth,
                zIndex: 2,
                display: isShorter ? "flex" : "block",
                backgroundColor,
                border,
                ...additionalStyles,
              }}
            >
              <EventTime>
                {getEventIcon(event.type, {
                  color: theme?.color,
                })}
                {`${format(new Date(event.start), "HH:mm")} - ${format(
                  new Date(event.end),
                  "HH:mm",
                )}`}
              </EventTime>
              <EventTitle>{event.title}</EventTitle>
            </EventItem>
          );
        })}
        {allDayEvents.length > 0 && (
          <div
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              width: "100px",
              height: HOURS.length * 90,
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              zIndex: 1,
            }}
          >
            {allDayEvents.map((event, idx) => {
              const theme = getNewDietitianColor(event.dietitianId);
              const { backgroundColor, border } = getEventStyles(
                theme?.backgroundColor,
                !!event.googleId,
              );

              return (
                <EventItem
                  key={idx}
                  onClick={e => handleEventClick(e, event.id, event.googleId)}
                  style={{
                    backgroundColor,
                    border,
                    flex: 1,
                  }}
                >
                  <EventTime>
                    {getEventIcon(event.type, {
                      color: theme?.color,
                    })}
                    {t("calendar.allDay")}
                  </EventTime>
                  <EventTitle>{event.title}</EventTitle>
                </EventItem>
              );
            })}
          </div>
        )}
      </div>

      {selectedEventId && (
        <ScheduleVisitOverviewModal
          anchorEl={anchorEl}
          onClose={handleCloseModal}
          eventId={selectedEventId}
        />
      )}
      {selectedGoogleId && (
        <ScheduleGoogleEventOverviewModal
          anchorEl={anchorEl}
          onClose={handleCloseModal}
          googleId={selectedGoogleId}
        />
      )}
    </div>
  );
};
