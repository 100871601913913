import { SvgIconProps } from "@components/SvgIcon";
import { Divider, useTheme } from "@mui/material";
import { isArray } from "lodash";
import {
  DescriptionStyled,
  ListItemIconStyled,
  ListItemTextStyled,
  MenuItemStyled,
  MenuStyled,
} from "./DropDownMenu.styled";

export interface MenuItemIf {
  text: string;
  description?: string;
  onClick: () => void;
  Icon?: (props: SvgIconProps) => JSX.Element;
}
interface DropDownMenuProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  options: MenuItemIf[] | MenuItemIf[][];
}

export const DropDownMenu = ({
  anchorEl,
  onClose,
  options,
}: DropDownMenuProps) => {
  const {
    colors: { neutral },
  } = useTheme();
  const closeMenu = (fn: () => void) => () => {
    onClose();
    fn();
  };

  const renderOptions = () => {
    const elements: JSX.Element[] = [];

    options.forEach((option, optionIdx) => {
      if (isArray(option)) {
        if (optionIdx > 0) {
          elements.push(<Divider key={`divider-${optionIdx}`} />);
        }
        option.forEach((o, oIdx) => {
          elements.push(
            <MenuItemStyled
              key={`option-${optionIdx}-${oIdx}`}
              onClick={closeMenu(o.onClick)}
            >
              {o.Icon && (
                <ListItemIconStyled>
                  <o.Icon fill={neutral.dark[700]} />
                </ListItemIconStyled>
              )}
              <div className="flex flex-col">
                <ListItemTextStyled>{o.text}</ListItemTextStyled>
                {o.description && (
                  <DescriptionStyled>{o.description}</DescriptionStyled>
                )}
              </div>
            </MenuItemStyled>,
          );
        });
      } else {
        elements.push(
          <MenuItemStyled
            key={`option-${optionIdx}`}
            onClick={closeMenu(option.onClick)}
          >
            {option.Icon && (
              <ListItemIconStyled>
                <option.Icon fill={neutral.dark[700]} />
              </ListItemIconStyled>
            )}
            <div className="flex flex-col">
              <ListItemTextStyled>{option.text}</ListItemTextStyled>
              {option.description && (
                <DescriptionStyled>{option.description}</DescriptionStyled>
              )}
            </div>
          </MenuItemStyled>,
        );
      }
    });

    return elements;
  };

  return (
    <MenuStyled open={Boolean(anchorEl)} onClose={onClose} anchorEl={anchorEl}>
      {renderOptions()}
    </MenuStyled>
  );
};
