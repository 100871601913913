import { MouseEventHandler, useMemo } from "react";

import { Button } from "@mui/material";
import { round } from "lodash";

import { useAppParams, useAppTranslation, useNutrients } from "@hooks";
import { useFetchCreatorDietMealNutrientsQuery } from "@hooks/queries/diets/creator";
import { MACROS_ARRAY } from "@utils/macros";
import { useFetchDietNutrientsNormQuery } from "@views/dietician/DietCreator/hooks";

import { useNutrientsSidePanelContext } from "../../../NutrientsSidePanel";
import { Text, TextBold, TextSemiBold } from "./MacrosSummary.styled";

interface MicrosSummaryProps {
  mealId: number;
}
export const MicrosSummary = ({ mealId }: MicrosSummaryProps) => {
  const { dietId } = useAppParams();
  const { t } = useAppTranslation();
  const { nutrientDict } = useNutrients();
  const { norm, isLoading: isNormLoading } =
    useFetchDietNutrientsNormQuery(dietId);
  const { data, isLoading } = useFetchCreatorDietMealNutrientsQuery(
    dietId,
    mealId.toString(),
  );
  const { onOpen } = useNutrientsSidePanelContext();
  const nutrientsToShowMap = useMemo(
    () =>
      new Map(
        norm?.nutrients?.map(({ nutrient, show }) => [nutrient.id, show]) || [],
      ),
    [norm],
  );
  const filteredNutrients = useMemo(
    () =>
      data?.filter(
        ({ id }) => !MACROS_ARRAY.includes(id) && nutrientsToShowMap.get(id),
      ) ?? [],
    [data],
  );

  const handleOpenNutrients: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();
    onOpen(data);
  };

  if (isLoading || isNormLoading || !filteredNutrients.length) return null;

  return (
    <div className="flex gap-[16px] items-center flex-wrap">
      <div className="flex gap-[16px]">
        {filteredNutrients.map(({ id, value }) => {
          const nutrient = nutrientDict.get(id);

          if (!nutrient) return null;

          const { short, unit } = nutrient;

          return (
            <span key={id}>
              <TextSemiBold>{short}: </TextSemiBold>
              <TextBold>{round(value)}</TextBold>
              {unit && <Text>{unit}</Text>}
            </span>
          );
        })}
      </div>

      <Button onClick={handleOpenNutrients}>{t("common.show_all")}</Button>
    </div>
  );
};
