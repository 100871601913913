import { useEffect, useMemo, useState } from "react";

import { useInfiniteQuery, useQuery } from "@tanstack/react-query";

import { RecipeSearchResourceDto, searchRecipesNew } from "@client";
import {
  DEFAULT_MACROS_RANGE,
  RecipesFiltersFormInput,
  convertSearchParamsToObj,
} from "@components/RecipesFiltersNew/useRecipesFiltersFormNew";
import { ListTabs, usePagination } from "@hooks";
import { ApiResponse } from "@typeDefinitions";
import {
  parseNumber,
  parseNutrientsList,
  parseString,
  parseTagsList,
} from "@utils/filters";
import { omit } from "lodash";
import { useSearchParams } from "react-router-dom";
import { QueryOptionsTyped } from "./types";

export const parseTabName = (
  input: ListTabs | undefined,
  params: URLSearchParams,
) => {
  if (input === undefined || input === ListTabs.ALL) return;
  if (input === ListTabs.LATEST) params.append(ListTabs.LATEST, "1");
  if (input === ListTabs.FAVORITE) params.append(ListTabs.FAVORITE, "1");
  if (input === ListTabs.OWN) params.append("owner", ListTabs.OWN);
  if (input === ListTabs.UNVERIFIED) params.append("reviewed", "0");
};

export const parseRecipesFilters = (filters?: RecipesFiltersFormInput) => {
  const output = new URLSearchParams();

  parseTagsList(filters?.tags, output);
  parseNutrientsList(filters?.nutrients, output);
  parseTabName(filters?.tabs, output);
  parseString(filters?.query || undefined, "query", output);
  parseNumber(filters?.page, "page", output);
  parseNumber(filters?.perPage, "perPage", output);
  parseNumber(filters?.patient, "patient", output);

  return output;
};

export const searchRecipesQueryKeyNew = "searchRecipesQueryKeyNew";

interface CustomOptions
  extends QueryOptionsTyped<ApiResponse<RecipeSearchResourceDto[]>> {
  disableParams?: boolean;
}

export function useSearchRecipesQueryNew(options?: CustomOptions) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [committedInputs, setCommittedInputs] =
    useState<RecipesFiltersFormInput>(
      omitDefaultNutrients(convertSearchParamsToObj(searchParams)),
    );
  const params = useMemo(
    () => parseRecipesFilters(committedInputs),
    [committedInputs],
  );

  useEffect(() => {
    if (!options?.disableParams) setSearchParams(params, { replace: true });
  }, [params, options?.disableParams]);

  const { handlePaginationClick, paginatedParams } = usePagination(params);
  const { data, ...rest } = useQuery(
    [searchRecipesQueryKeyNew, paginatedParams.toString()],
    () => searchRecipesNew(paginatedParams),
    options,
  );

  return {
    recipes: data?.data,
    links: data?.meta?.links,
    submit: setCommittedInputs,
    ...rest,
    handlePaginationClick,
    meta: data?.meta,
  };
}

export const omitDefaultNutrients = (data: RecipesFiltersFormInput) => {
  const nutrientsWithNonDefaultValues = Object.entries(
    data.nutrients || {},
  ).reduce((acc, [key, value]) => {
    const defaultValue = DEFAULT_MACROS_RANGE[key];
    if (
      !defaultValue ||
      defaultValue[0] !== value[0] ||
      defaultValue[1] !== value[1]
    )
      acc[key] = value;

    return acc;
  }, {} as { [id: string]: [number, number] });

  return {
    ...omit(data, "nutrients"),
    nutrients: nutrientsWithNonDefaultValues,
  };
};

export const searchRecipesInfiniteQueryKey = "searchRecipesInfiniteQueryKey";

export const useSearchRecipesInfiniteQuery = (
  defaultInputs: RecipesFiltersFormInput | undefined = {},
  options?: QueryOptionsTyped<ApiResponse<RecipeSearchResourceDto[]>>,
) => {
  const [committedInputs, setCommittedInputs] =
    useState<RecipesFiltersFormInput>(defaultInputs);
  const params = useMemo(
    () => parseRecipesFilters(omitDefaultNutrients(committedInputs)),
    [committedInputs],
  );
  const fetchRecipes = ({ pageParam = 1 }) => {
    const paginatedParams = new URLSearchParams(params);
    paginatedParams.set("page", pageParam.toString());
    return searchRecipesNew(paginatedParams);
  };
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    ...rest
  } = useInfiniteQuery(
    [searchRecipesInfiniteQueryKey, params.toString()],
    fetchRecipes,
    {
      getNextPageParam: ({ meta }) => {
        if (!meta) return;
        const { currentPage, total, perPage } = meta;
        const nextPage = currentPage + 1;
        return nextPage <= Math.ceil(total / (perPage || 1))
          ? nextPage
          : undefined;
      },
      enabled: options?.enabled,
      keepPreviousData: options?.keepPreviousData,
      refetchOnMount: options?.refetchOnMount,
    },
  );

  const recipes = data?.pages.flatMap(page => page.data) || [];
  const lastMeta = data ? data.pages[data.pages.length - 1].meta : undefined;
  return {
    recipes,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    submit: setCommittedInputs,
    meta: lastMeta,
    ...rest,
  };
};
