import { DialogContent, DialogTitle, IconButton, styled } from "@mui/material";

interface DialogTitleStyledProps {
  compact?: boolean;
}
export const DialogTitleStyled = styled(DialogTitle, {
  shouldForwardProp: prop => prop !== "compact",
})<DialogTitleStyledProps>`
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
  min-width: ${({ compact }) => (compact ? "" : "480px")};
`;

export const IconButtonStyled = styled(IconButton)`
  position: absolute;
  right: 8px;
  top: 8px;
`;

export const DialogContentStyled = styled(DialogContent)`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;
