import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  createScheduleEvent,
  CreateScheduleEventResponse,
  CreateScheduleEventRequest,
} from "@client";
import { MutationOptions } from "./types";
import { searchScheduleEventsQueryKey } from "./useSearchScheduleEvents";
import { fetchPatientVisitsQueryKey } from "./visits";
import { ApiResponse } from "@typeDefinitions";

export const useCreateScheduleEventMutation = (
  options?: MutationOptions<
    ApiResponse<CreateScheduleEventResponse>,
    CreateScheduleEventRequest
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation<
    ApiResponse<CreateScheduleEventResponse>,
    unknown,
    CreateScheduleEventRequest
  >(payload => createScheduleEvent(payload), {
    ...options,
    onSuccess: (d, v) => {
      queryClient.invalidateQueries([searchScheduleEventsQueryKey]);
      queryClient.invalidateQueries([fetchPatientVisitsQueryKey]);
      options?.onSuccess && options.onSuccess(d, v);
    },
  });
};
