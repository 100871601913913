import { useMemo, useState } from "react";

import { MacrosUnits } from "@typeDefinitions";
import { useAppTranslation } from "@hooks";
import {
  ENERGY_PER_GRAM_OF_CARB,
  ENERGY_PER_GRAM_OF_FAT,
  ENERGY_PER_GRAM_OF_PROTEIN,
} from "@utils/macros";

import { useDebounce } from "react-use";
import { calculatePercentage, sumMacros } from "./macrosUtils";
import { compact, isEqual } from "lodash";
import {
  MacrosSlidersFormInput,
  useMacrosSlidersForm,
} from "./useMacrosSlidersForm";
import {
  FormEnergySlider,
  FormMacroSlider,
  NormSelector,
  ToggleUnit,
} from "./components";
import { Alert } from "@mui/material";

import {
  AlertWrapperStyled,
  MacrosSlidersWrapper,
} from "./MacrosSliders.styled";

export interface MacrosSliderUpdate {
  energy: number;
  fat: number;
  carbs: number;
  protein: number;
}

export interface MacrosSliderMacros {
  energy: number;
  fat: number;
  carbs: number;
  protein: number;
}

interface MacrosSlidersProps {
  clientWeight: number | null;
  defaultValues?: MacrosSlidersFormInput;
  onChange: (data: MacrosSliderMacros) => void;
  normError?: boolean;
  showNorms?: boolean;
}

export const MacrosSliders = ({
  clientWeight,
  defaultValues,
  onChange,
  normError,
  showNorms = true,
}: MacrosSlidersProps) => {
  const [unit, setUnit] = useState<MacrosUnits>(MacrosUnits.GRAMS);
  const [init, setInit] = useState(true);

  const { t } = useAppTranslation();
  const { control, watch, formState } = useMacrosSlidersForm(defaultValues);

  const energy = watch("kcal");
  const fat = watch("fat");
  const carbs = watch("carbs");
  const protein = watch("protein");
  const macrosArray = [energy, fat, carbs, protein];

  const sum = useMemo(
    () => sumMacros(fat, carbs, protein),
    [
      fat,
      carbs,
      protein,
      ENERGY_PER_GRAM_OF_FAT,
      ENERGY_PER_GRAM_OF_CARB,
      ENERGY_PER_GRAM_OF_PROTEIN,
    ],
  );

  const percentage = useMemo(
    () => calculatePercentage(sum, energy),
    [sum, energy],
  );

  const isFallbackValues = isEqual(
    { energy: 500, fat: 0, carbs: 0, protein: 0 },
    {
      energy,
      fat,
      carbs,
      protein,
    },
  );

  useDebounce(
    () => {
      if (
        !init &&
        !isFallbackValues &&
        (formState.isDirty ||
          !isEqual(defaultValues, { kcal: energy, fat, carbs, protein })) &&
        compact(macrosArray).length === macrosArray.length
      ) {
        onChange({ energy, fat, carbs, protein });
      } else setInit(false);
    },
    250,
    [JSON.stringify(watch()), defaultValues],
  );

  return (
    <MacrosSlidersWrapper>
      <FormEnergySlider
        control={control}
        label={t("macros_sliders.kcal")}
        unit="kcal"
      />
      <ToggleUnit
        clientWeight={!!clientWeight}
        onChange={unit => setUnit(unit)}
        value={unit}
      />
      <FormMacroSlider
        control={control}
        name="protein"
        label={t("macros_sliders.protein")}
        unit={unit}
        multiplier={ENERGY_PER_GRAM_OF_PROTEIN}
        weight={clientWeight}
      />
      <FormMacroSlider
        control={control}
        name="fat"
        label={t("macros_sliders.fat")}
        unit={unit}
        multiplier={ENERGY_PER_GRAM_OF_FAT}
        weight={clientWeight}
      />
      <FormMacroSlider
        control={control}
        name="carbs"
        label={t("macros_sliders.carbs")}
        unit={unit}
        multiplier={ENERGY_PER_GRAM_OF_CARB}
        weight={clientWeight}
      />
      <AlertWrapperStyled>
        {percentage === 100 && (
          <Alert severity="success">{t("macros_sliders.sum_success")}</Alert>
        )}
        {percentage !== 100 && (
          <Alert severity="error">{t("macros_sliders.sum_error")}</Alert>
        )}
      </AlertWrapperStyled>
      {showNorms && (
        <>
          <NormSelector />
          {normError && (
            <AlertWrapperStyled>
              <Alert severity="error">{t("macros_sliders.no_age")}</Alert>
            </AlertWrapperStyled>
          )}
        </>
      )}
    </MacrosSlidersWrapper>
  );
};
