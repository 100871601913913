import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const updateClient = async (
  id: number,
  payload: UpdateClientRequest,
) => {
  await fetchData(`/dietitian/patients/${id}`, APIMethods.PUT, payload);
};

export interface UpdateClientRequest {
  firstName: string;
  lastName: string;
  email: string | null;
  phone: PhoneDto;
  lang: "pl" | "en";
  sex: "m" | "w" | null;
  description: string | null;
  birthDate: string | null;
  height: number | null;
  bodyFat: number | null;
  weight: number | null;
  targetWeight: number | null;
  additionalFeatures: IdDto[];
  targets: IdDto[];
  sources: IdDto[];
  address: AddressDto;
  dietitian: IdDto;
  facility: IdDto;
}

interface PhoneDto {
  prefix: string | null;
  number: string | null;
}

interface IdDto {
  id: number;
}

interface AddressDto {
  street: string | null;
  streetNumber: string | null;
  houseNumber: string | null;
  city: string | null;
  postcode: string | null;
}
