import { useState } from "react";
import { Chevron } from "@assets/icons";
import { Stack } from "@mui/material";
import Divider from "@components/Alloweat/Divider";
import { NutrientsSection } from "@components/PreviewDrawer/common";
import {
  CustomAccordionDetails,
  CustomAccordionSummary,
  ShowButton,
  CustomAccordion,
} from "@components/ProgramSearchPreview/Components/DietNutrients/MicroNutrients.styled";
import { Nutrient } from "@typeDefinitions";
import { useAppTranslation } from "@hooks";

type NutrientsProps = {
  nutrients: Nutrient[];
};
const MicroNutrients = ({ nutrients }: NutrientsProps) => {
  const { t } = useAppTranslation();
  const [expanded, setExpanded] = useState<boolean>(false);
  return (
    <CustomAccordion
      expanded={expanded}
      onChange={() => setExpanded(prev => !prev)}
    >
      <CustomAccordionSummary>
        {!expanded && (
          <ShowButton>
            <Chevron />
            <span>{t("program_preview.nutrients.show")}</span>
          </ShowButton>
        )}
        {expanded && (
          <ShowButton>
            <Chevron style={{ transform: "rotate(-180deg)" }} />
            <span>{t("program_preview.nutrients.hide")}</span>
          </ShowButton>
        )}
      </CustomAccordionSummary>
      <CustomAccordionDetails>
        <Stack direction="column" gap="24px">
          <Divider />
          <NutrientsSection nutrients={nutrients} />
        </Stack>
      </CustomAccordionDetails>
    </CustomAccordion>
  );
};

export default MicroNutrients;
