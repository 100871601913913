import { useQuery } from "@tanstack/react-query";
import { QueryOptionsTyped } from "../types";
import {
  ProductExchangeDto,
  fetchDietsProductsReplacement,
} from "@client/diets";
import { ApiResponse } from "@typeDefinitions";

export const fetchDietsProductsReplacementQueryKey =
  "fetchDietsProductsReplacementQueryKey";

export const useFetchDietsProductsReplacementQuery = (
  dietId: string,
  options?: QueryOptionsTyped<ApiResponse<ProductExchangeDto[]>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchDietsProductsReplacementQueryKey, dietId],
    () => fetchDietsProductsReplacement(dietId),
    options,
  );

  return {
    data: data?.data,
    ...rest,
  };
};
