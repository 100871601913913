import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  FetchMealResponse,
  updateCreatorMeal,
  UpdateCreatorMealRequest,
} from "@client/meals";
import { fetchCreatorMealQueryKey, MutationOptions } from "@hooks/queries";
import { useFetchMealTagsQueryKey } from "@hooks/queries/meals/useFetchCreatorMealTags";
import { fetchCreatorMealNutrientsQueryKey } from "@hooks/queries/meals/useFetchCreatorMealNutrientsQuery";
import { ApiResponse } from "@typeDefinitions";
import { useMealVersion } from "@views/dietician/MealEdit/hooks";

export const useUpdateCreatorMealMutation = (
  mealId: string,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();
  const { version, updateVersion } = useMealVersion();

  const mutation = useMutation<unknown, unknown, UpdateCreatorMealRequest>(
    payload => updateCreatorMeal(mealId, payload, version),
    {
      ...options,
      onSuccess: (data, variables) => {
        options?.onSuccess && options.onSuccess(data, variables);
        queryClient.invalidateQueries([useFetchMealTagsQueryKey, mealId]);
        queryClient.invalidateQueries([
          fetchCreatorMealNutrientsQueryKey,
          mealId,
        ]);
      },
      onMutate: async mealRequest => {
        updateVersion();
      },
    },
  );

  return mutation;
};
