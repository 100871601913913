import {
  IdDto,
  ImageDto,
  MeasureDto,
  NutrientDto,
  TranslationLangDto,
} from "@client/common";
import { TagResourceDto, tagResourceSchema } from "@client/diets";
import { ApiEndpoints, APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

import {
  idSchema,
  imageSchema,
  measureSchema,
  nutrientSchema,
  translationLangSchema,
} from "@client/common";
import { array, number, object, string } from "yup";

export const fetchCollectionSearchPreviewRecipe = async (
  collectionId: string,
  itemId: string,
): Promise<ApiResponse<CollectionRecipeSearchPreviewDto>> => {
  const response = await fetchData(
    `${ApiEndpoints.CollectionsSearchPreview}/${collectionId}/recipe-items/${itemId}`,
    APIMethods.GET,
  );

  return reponseSchema.validate(response);
};

interface ProductDto {
  id: number;
  name: string;
  translations: TranslationLangDto[];
  nutrients: NutrientDto[];
  measures: MeasureDto[];
}
interface ProductWrapperDto {
  id: number;
  grams: number;
  measure: IdDto;
  product: ProductDto;
}
interface NestedRecipeSearchPreviewDto {
  id: number;
  name: string;
  description: string | null;
  translations: TranslationLangDto[];
  nutrients: NutrientDto[];
  tags: TagResourceDto[];
  image: ImageDto | null;
  products: ProductWrapperDto[];
  comment: string | null;
}
export interface CollectionRecipeSearchPreviewDto {
  id: number;
  servings: number;
  recipe: NestedRecipeSearchPreviewDto;
}

const productSchema = object({
  id: number().required(),
  name: string().required(),
  translations: array().of(translationLangSchema).required(),
  nutrients: array().of(nutrientSchema).required(),
  measures: array().of(measureSchema).required(),
});

const productWrapperSchema = object({
  id: number().required(),
  grams: number().required(),
  measure: idSchema.required(),
  product: productSchema.required(),
});

const nestedRecipeSearchPreviewSchema = object({
  id: number().required(),
  name: string().required(),
  description: string().nullable().default(null),
  translations: array().of(translationLangSchema).required(),
  nutrients: array().of(nutrientSchema).required(),
  tags: array().of(tagResourceSchema).required(),
  image: imageSchema.nullable().default(null),
  products: array().of(productWrapperSchema).required(),
  comment: string().nullable().default(null),
});

const collectionRecipeSearchPreviewSchema = object({
  id: number().required(),
  servings: number().required(),
  recipe: nestedRecipeSearchPreviewSchema.required(),
});

const reponseSchema = object({
  data: collectionRecipeSearchPreviewSchema.required(),
});
