import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Stack,
  styled,
} from "@mui/material";
import ChevronIcon from "@icons/chevron.svg?react";
import { FetchClientMeasurementListResponse } from "@client";
import { useAppTranslation } from "@hooks";
import { MouseEvent, useMemo, useState } from "react";
import dayjs from "dayjs";
import { useEmptyDataString } from "@hooks/helpers";
import { noData } from "@views/dietician/ClientProfile";
import Measurement from "@views/dietician/ClientHealth/components/MeasurementItem/Measurement";
import BodyMeasurementsTabs from "@components/Client/BodyMeasurementsTabs/BodyMeasurementsTabs";
import MoreActionButton from "@views/dietician/ClientHealth/components/MeasurementItem/MoreActionButton";
import { BodyMeasurementConst } from "@consts/BodyMeasurementConst";

type MeasurementItemNewProps = {
  measurement: FetchClientMeasurementListResponse;
  clientId: number;
};

const MeasurementItemNew = ({
  measurement,
  clientId,
}: MeasurementItemNewProps) => {
  const { t } = useAppTranslation();
  const [expanded, setExpanded] = useState(false);

  const date = useMemo(() => {
    return dayjs(measurement.date);
  }, [measurement]);

  const handleMenuClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  return (
    <CustomAccordion
      onChange={(e, expanded) => setExpanded(expanded)}
      expanded={expanded}
      disableGutters
    >
      <Box display="flex" justifyContent="space-between" alignItems="start">
        <AccordionSummary sx={{ flex: 1 }}>
          <Stack direction="column" flex="1" gap="2px">
            <Stack direction="row" alignItems="center" gap="8px">
              <Title>
                {t("client_profile.health.measurement")} (
                {date.format("DD.MM.YYYY")})
              </Title>
              <Chip
                sx={{ background: "#F2F2FF", color: "#5252E5" }}
                label={t(
                  "client_profile.health.measurement_source." +
                    measurement.source.id,
                )}
              />
            </Stack>
            <div style={{ height: "15px" }}>
              <Measurement.Title hidden={expanded}>
                {t("client_profile.health.weight")}{" "}
                <Measurement.Value>
                  {useEmptyDataString(
                    measurement.bodyMeasurements.find(
                      bm => bm.body.id === BodyMeasurementConst.weight,
                    )?.value,
                    "kg",
                  ) ?? noData}
                </Measurement.Value>
              </Measurement.Title>
            </div>
          </Stack>
        </AccordionSummary>
        <Stack direction="row" alignItems="center" gap="8px">
          <MoreActionButton clientId={clientId} measurement={measurement} />
          <ChevronIcon
            onClick={() => setExpanded(prev => !prev)}
            color="#727272"
            style={{
              transform: expanded ? "rotate(-270deg)" : "rotate(-90deg)",
              cursor: "pointer",
            }}
          />
        </Stack>
      </Box>
      <AccordionDetails>
        <Stack direction="column" gap="16px">
          <BodyMeasurementsTabs
            patientBodyMeasurement={measurement}
            client={{}}
          />
        </Stack>
      </AccordionDetails>
    </CustomAccordion>
  );
};

const CustomAccordion = styled(Accordion)`
  box-shadow: none;
  border: 1px solid #f1f1f1;
  padding: 12px;
  border-radius: 8px;

  :before {
    content: none;
  }

  .MuiAccordionSummary-root {
    padding: 0;
    min-height: unset;
  }

  .MuiAccordionDetails-root {
    padding: 0;
  }

  .MuiAccordionSummary-content {
    margin: 0;
  }
` as typeof Accordion;

const Title = styled("span")`
  font-family: Figtree, serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: #333333;
`;

export default MeasurementItemNew;
