import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Spoon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="14" viewBox="0 0 14 14" {...props}>
      <path
        d="M6.74201 6.10906C6.3739 5.66217 6.18857 5.1137 6.18857 4.56524C6.18857 2.53135 8.62571 0.5 11.0629 0.5C12.4084 0.5 13.5 1.59185 13.5 2.93762C13.5 5.37524 11.469 7.81286 9.43302 7.81286C8.88466 7.81286 8.3363 7.6275 7.88949 7.25931L1.88803 13.262C1.57069 13.5793 1.05534 13.5793 0.738002 13.262C0.420666 12.9446 0.420666 12.4291 0.738002 12.1117L6.74201 6.10906Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
