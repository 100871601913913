import { useAppTranslation, useDietEditorParams } from "@hooks";
import { DietDetails } from "@views/dietician/Diets/components";
import { SingleCardPageLayout } from "@components/PageLayout";

export const Diet = () => {
  const { t } = useAppTranslation();
  const dietId = useDietEditorParams();

  return (
    <SingleCardPageLayout title={t("diets.base_preview")}>
      <DietDetails dietId={dietId} padding />
    </SingleCardPageLayout>
  );
};
