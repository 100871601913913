import { useAppTranslation } from "@hooks";
import { Nutrient } from "@typeDefinitions";

import { FoodBowl } from "@assets/icons/DesignSystem";
import { MacrosText } from "@components/MacrosText";
import { TagWithIcon } from "@components/TagWithIcon";
import { CellWrapper, DetailsWrapper, Title } from "./NameColumnCell.styled";

interface NameColumnCellProps {
  name: string;
  nutrients: Nutrient[];
  onSelect: () => void;
  mealsCount: number;
}

export const NameColumnCell = ({
  name,
  nutrients,
  onSelect,
  mealsCount,
}: NameColumnCellProps) => {
  const { t } = useAppTranslation();

  return (
    <CellWrapper onClick={onSelect}>
      <Title>{name}</Title>
      <DetailsWrapper>
        <MacrosText nutrients={nutrients} />
        <TagWithIcon
          Icon={FoodBowl}
          label={`${mealsCount} ${t("diets.meals", { count: mealsCount })}`}
        />
      </DetailsWrapper>
    </CellWrapper>
  );
};
