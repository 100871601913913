import { styled } from "@mui/material";
import { useAppTranslation } from "@hooks";
import { useMemo } from "react";
import { ProgramSearchPreview } from "@client/program/preview/fetchProgramPreview";

type ProgramNameProps = {
  program: Pick<ProgramSearchPreview, "translations" | "name">;
};
const ProgramName = ({ program }: ProgramNameProps) => {
  const { isCurrentLanguage } = useAppTranslation();

  const name = useMemo(() => {
    const translation = program.translations.find(t =>
      isCurrentLanguage(t.lang),
    );
    return translation?.name ?? program.name;
  }, [program, isCurrentLanguage]);

  return <Name>{name}</Name>;
};

const Name = styled("span")`
  line-height: 24px;
  font-size: 20px;
  font-weight: 600;
  font-family: Figtree, serif;
  color: #141414;
`;

export default ProgramName;
