import { Drawer, Typography, Box, styled, List, Divider } from "@mui/material";
import { Close } from "@assets/icons";
import { IconButton } from "@components/IconButton";
import { useAppTranslation } from "@hooks";
import { useTasksContext } from "@views/dietician/Tasks/Tasks.context";
import { useSuggestedTasksCountQuery } from "@hooks/queries/suggestedTasks/useSuggestedTasksCountQuery";
import { TasksSuggestedAccordion } from "./TasksSuggestedAccordion";
import { TasksSuggestedRejectedAccordion } from "./TasksSuggestedRejectedAccordion";
import { useAllTags } from "@hooks/useAllTags";
import image from "@assets/img/tasksUptodate.png";

const TasksSuggestedPanelStyledDrawer = styled(Drawer)`
  .MuiPaper-root {
    width: 520px;
    padding: 0.2rem;
  }
`;

export const TasksSuggestedPanel = () => {
  const { t } = useAppTranslation();

  const { systemTags } = useAllTags();
  const { data: hiddenData } = useSuggestedTasksCountQuery({ hidden: 0 });
  const { data: totalTasks } = useSuggestedTasksCountQuery();

  const totalNotHidden =
    totalTasks && hiddenData ? totalTasks?.count - hiddenData?.count : 0;

  const { isSuggestedTasksPanelOpen, handleCloseSuggestedTasksPanel } =
    useTasksContext();

  const accordions = systemTags.map(({ isSystem, color, ...tag }) => ({
    ...tag,
    isExpanded: true,
  }));

  return (
    <TasksSuggestedPanelStyledDrawer
      anchor="right"
      open={isSuggestedTasksPanelOpen}
      slotProps={{
        backdrop: {
          invisible: true,
          transitionDuration: 0,
        },
      }}
      onClose={handleCloseSuggestedTasksPanel}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={1.5}
      >
        <Typography variant="h6" id="tasks-suggested-panel">
          {`${t("tasks.suggestedTasks")} (${totalNotHidden})`}
        </Typography>
        <IconButton color="gray" onClick={handleCloseSuggestedTasksPanel}>
          <Close className="text-gray-450" />
        </IconButton>
      </Box>
      {totalTasks?.count ? (
        <List className="overflow-x-hidden">
          {accordions.map(props => (
            <TasksSuggestedAccordion key={props.id} {...props} />
          ))}
          <TasksSuggestedRejectedAccordion />
        </List>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          margin="40px auto"
          alignItems="center"
          gap={1}
        >
          <img
            src={image}
            alt={t("tasks.tasksUpToDate")}
            className="w-72 h-auto"
          />
          <Typography
            fontWeight={600}
            fontSize="16px"
            lineHeight="1.5rem"
            align="center"
            color={({ colors }) => colors.neutral.dark[900]}
          >
            {t("tasks.bravo")}
          </Typography>
          <Typography
            fontWeight={400}
            fontSize="14px"
            align="center"
            color={({ colors }) => colors.neutral.dark[800]}
          >
            {t("tasks.tasksUpToDate")}
          </Typography>
        </Box>
      )}
    </TasksSuggestedPanelStyledDrawer>
  );
};
