import { Button, styled } from "@mui/material";

export const Label = styled("span")`
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral.medium[800]};
`;

interface ButtonStyledProps {
  selected?: boolean;
}

export const FilterButton = styled(Button, {
  shouldForwardProp: prop => prop !== "selected",
})<ButtonStyledProps>`
  &.MuiButton-outlined {
    border-radius: 8px;
    background: ${({ theme, selected }) =>
      selected
        ? theme.palette.primary.medium
        : theme.colors.neutral.light[100]};
    color: ${({ theme, selected }) =>
      selected ? theme.palette.primary.main : theme.colors.neutral.dark[700]};
    border-color: ${({ theme, selected }) =>
      selected ? theme.palette.primary.main : theme.colors.neutral.light[200]};
    white-space: nowrap;
    min-width: unset;
  }
`;

export const OptionsWrapper = styled("div")`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;
