import { useEffect, useMemo, useState } from "react";

import { Carrot } from "@assets/icons/DesignSystem";
import { DefinedLabelSpecialTagIf, SpecialTags } from "@components/TagWithIcon";
import { useAppTranslation, useMeasures } from "@hooks";
import { ClientContextIf, Nutrient } from "@typeDefinitions";

import { ProductItem, useItemsContext } from "../ItemsContext";
import { AccordionWrapper } from "./AccordionWrapper";
import { ProductContent } from "./ProductContent";
import { mapUserChips } from "@components/ExpandableChips";
import { ProductCollectionContent } from "./ProductCollectionContent";
import { Pen, Scales } from "@assets/icons";

export interface ProductAccordionProps {
  namePl: string;
  nameEn: string | null;
  macros: Nutrient[];
  tags: number[];
  id: number;
  categoryName?: string;
  imgUrl: string | undefined;
  clientContext?: ClientContextIf;
  mealId: number;
  collectionId?: number;
  itemId?: number;
  defaultGrams?: number;
  defalutMeasure?: string;
}

export const ProductAccordion = ({
  namePl,
  nameEn,
  macros,
  tags,
  id,
  categoryName,
  imgUrl,
  clientContext,
  mealId,
  collectionId,
  itemId,
  defalutMeasure = `${DEFAULT_MEASURE}`,
  defaultGrams = DEFAULT_GRAMS,
}: ProductAccordionProps) => {
  const { isPolishChosen, t } = useAppTranslation();
  const title = isPolishChosen ? namePl : nameEn ?? namePl;
  const [grams, setGrams] = useState(defaultGrams);
  const [measure, setMeasure] = useState(defalutMeasure);
  const [isTouched, setIsTouched] = useState(false);
  const [expanded, setExpanded] = useState<boolean | undefined>(false);

  const { toggleItems, updateItem, isChecked, singleSelect, itemsCount } =
    useItemsContext();

  const item = useMemo<ProductItem>(
    () => ({
      type: "product",
      id,
      grams,
      measure: Number(measure),
      mealId,
    }),
    [grams, measure, id, mealId],
  );

  const shouldHideCheckbox = singleSelect && !!itemsCount && !isChecked(item);

  const specialTags = useMemo(
    () =>
      [
        { id: "1", label: categoryName, Icon: Carrot },
        { id: "2", label: `${defaultGrams}g`, Icon: Scales },
      ].filter((t): t is DefinedLabelSpecialTagIf => !!t.label),
    [categoryName, t, defaultGrams],
  );

  const userTags = useMemo(
    () =>
      mapUserChips({
        disliked: clientContext?.hasUnliked,
        liked: clientContext?.hasLiked,
        allergen: clientContext?.hasAllergens,
      }),
    [clientContext],
  );

  const toggleCheck = () => {
    toggleItems([item]);
    setExpanded(true);
  };

  useEffect(() => {
    updateItem(item);
    if (isTouched && !isChecked(item)) toggleCheck();
  }, [item, isTouched]);

  return (
    <AccordionWrapper
      withImage
      checked={isChecked(item)}
      extras={<SpecialTags tags={specialTags} />}
      nutrients={macros}
      onCheck={toggleCheck}
      tags={tags}
      title={title}
      image={imgUrl}
      expanded={expanded}
      setExpanded={setExpanded}
      hiddenCheckbox={shouldHideCheckbox}
      userTags={userTags}
    >
      {collectionId && itemId ? (
        <ProductCollectionContent
          id={id}
          grams={grams}
          measure={measure}
          setGrams={setGrams}
          setMeasure={setMeasure}
          setIsTouched={setIsTouched}
          collectionId={collectionId}
          itemId={itemId}
        />
      ) : (
        <ProductContent
          id={id}
          grams={grams}
          measure={measure}
          setGrams={setGrams}
          setMeasure={setMeasure}
          setIsTouched={setIsTouched}
        />
      )}
    </AccordionWrapper>
  );
};

const DEFAULT_GRAMS = 100;
const DEFAULT_MEASURE = 1;
