import { memo } from "react";

import { Spinner } from "@components/Spinner";

/**
 * Custom loader for button
 */
export const ButtonLoader = memo(() => {
  return (
    <div className="flex items-center justify-between">
      <Spinner size="w-4 h-4" borderWidth={2} className="bg-transparent" />
    </div>
  );
});
