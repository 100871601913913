import { styled } from "@mui/material";

export const ProductRowStyled = styled("div")`
  display: flex;
  padding: 0 8px;
  background: ${({ theme }) => theme.palette.primary.light};
  border-radius: 8px;
  min-height: 40px;
  align-items: center;
`;

export const ProductsSummaryStyled = styled("div")`
  display: flex;
  padding: 0 8px;
  background: ${({ theme }) => theme.palette.primary.medium};
  border-radius: 8px;
  min-height: 40px;
  align-items: center;
`;
export const TableHeadersStyled = styled("div")`
  display: flex;
  padding: 0 8px;
  align-items: center;
`;

export const NameCell = styled("div")`
  padding: 4px 8px;
  width: 180px;
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
`;

export const Name = styled("span")`
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const SummaryName = styled(Name)`
  text-transform: uppercase;
`;

export const AmountCell = styled("div")`
  padding: 4px 8px;
  width: 180px;
`;

interface AmountProp {
  dark?: boolean;
}

export const Amount = styled("span")<AmountProp>`
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme, dark }) =>
    dark ? theme.colors.neutral.dark[800] : theme.colors.neutral.dark[700]};
`;

export const NutrientsListWrapper = styled("div")`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  flex: 1;
  max-width: 271px;
`;
export const NutrientsHeader = styled("div")`
  flex: 1;
`;
export const NutrientWrapper = styled("div")`
  display: flex;
  gap: 4px;
  white-space: nowrap;
  width: 50px;
`;

export const BoldText = styled("span")`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const Text = styled("span")`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const Header = styled("span")`
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;
