import { Link } from "./";
import * as yup from "yup";
export interface Metadata {
  currentPage: number;
  from: number | null;
  lastPage: number;
  path: string | null;
  perPage: number;
  to: number | null;
  total: number;
  links: Link[];
}

export const MetadataSchema = yup.object({
  currentPage: yup.number().required(),
  from: yup.number().nullable().defined(),
  lastPage: yup.number().required(),
  path: yup.string().nullable().defined(),
  perPage: yup.number().required(),
  to: yup.number().nullable().defined(),
  total: yup.number().required(),
  links: yup.array().defined(),
});
