import useFetchSectionsProgramPreview from "@hooks/queries/program/preview/useFetchSectionsProgramPreview";
import { Box, CircularProgress, Tab } from "@mui/material";
import { TabContext } from "@mui/lab";
import { useCallback, useMemo, useState } from "react";
import { TabList } from "@components/Alloweat/Tabs/TabList";
import { TabPanel } from "@components/Alloweat/Tabs/TabPanel";
import {
  filterSystemProgramSections,
  getUniqueSectionCategoriesId,
} from "@components/ProgramSearchPreview/utils/ProgramSectionsUtil";
import MaterialTab from "@components/ProgramSearchPreview/Components/ProgramMaterials/MaterialTab";
import MaterialTabPanel from "@components/ProgramSearchPreview/Components/ProgramMaterials/MaterialTabPanel";
import MaterialListItem from "@components/ProgramSearchPreview/Components/ProgramMaterials/MaterialListItem";
import { SectionProgramPreviewSchema } from "@client/program/preview/fetchSectionsProgramPreview";
import { useAppTranslation } from "@hooks";
import EmptyHere from "@components/ProgramSearchPreview/Components/EmptyHere/EmptyHere";

type ProgramMaterialsProps = {
  programId: number;
};

const ProgramMaterials = ({ programId }: ProgramMaterialsProps) => {
  const { data } = useFetchSectionsProgramPreview(programId, {
    staleTime: Infinity,
  });

  if (data === undefined) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  return <ProgramMaterialsData sections={data.data} />;
};

type ProgramMaterialsDataProps = {
  sections: SectionProgramPreviewSchema[];
};
const ProgramMaterialsData = ({ sections }: ProgramMaterialsDataProps) => {
  const { t } = useAppTranslation();
  const [value, setValue] = useState("0");
  const handleChange = useCallback((_: any, newValue: string) => {
    setValue(newValue);
  }, []);

  const materials = useMemo(
    () => filterSystemProgramSections(sections, false),
    [sections],
  );
  const uniqueCategoriesId = useMemo(
    () => getUniqueSectionCategoriesId(materials),
    [materials],
  );

  if (materials.length === 0) {
    return (
      <EmptyHere
        title={t("program_preview.education_materials.empty.title")}
        description={t("program_preview.education_materials.empty.description")}
      />
    );
  }

  return (
    <TabContext value={value}>
      <TabList
        onChange={handleChange}
        scrollButtons={"auto"}
        variant="scrollable"
      >
        <Tab
          label={`${t("program_preview.education_materials.all")} (${
            materials.length
          })`}
          value="0"
        />
        {uniqueCategoriesId.map(id => (
          <MaterialTab
            key={id}
            value={id.toString()}
            materials={materials}
            categoryId={id}
          />
        ))}
      </TabList>
      <TabPanel value="0">
        <ul>
          {materials.map((material, index) => (
            <MaterialListItem key={index} material={material} />
          ))}
        </ul>
      </TabPanel>
      {uniqueCategoriesId.map((id, index) => (
        <MaterialTabPanel key={index} materials={materials} categoryId={id} />
      ))}
    </TabContext>
  );
};

export default ProgramMaterials;
