import { InputAdornment, useTheme } from "@mui/material";
import { TextFieldStyled } from "./SummaryRow.styled";
import { GetNutrientStatusIf } from "./summaryRowUtils";
import { useNutrients } from "@hooks";
import { ArrowInCircle } from "@assets/icons/Arrows";
import { CheckInCircle } from "@assets/icons/DesignSystem";

interface SummaryTextFieldProps {
  id: number;
  getStatus: (id: number) => GetNutrientStatusIf | null;
  percentage?: boolean;
}
export const SummaryTextField = ({
  id,
  getStatus,
  percentage = false,
}: SummaryTextFieldProps) => {
  const { nutrientDict } = useNutrients();
  const unit = nutrientDict.get(id)?.unit;
  const status = getStatus(id);
  const {
    palette: { success, warning, error },
    colors,
  } = useTheme();
  const tooLow = status && status?.deviation < 0;

  return (
    <TextFieldStyled
      fullWidth
      size="small"
      InputProps={{
        readOnly: true,
        endAdornment: (
          <div className="flex items-center">
            {status?.color === "success" && (
              <CheckInCircle fill={success.dark} />
            )}
            {status?.color === "warning" && (
              <ArrowInCircle
                fill={warning.dark}
                className={tooLow ? "rotate-180" : ""}
              />
            )}
            {status?.color === "error" && (
              <ArrowInCircle
                fill={error.dark}
                className={tooLow ? "rotate-180" : ""}
              />
            )}
            {status?.color === "info" && (
              <ArrowInCircle
                fill={colors.neutral.dark[700]}
                className={tooLow ? "rotate-180" : ""}
              />
            )}

            <InputAdornment position="end">
              {percentage ? "%" : unit}
            </InputAdornment>
          </div>
        ),
      }}
      value={percentage ? status?.percentage : status?.value}
      color={status?.color}
    />
  );
};
