import { DraggedRecipeData } from "@views/dietician/ProgramSchedule/components/ProgramScheduleGrid";
import {
  DietitianProgramScheduleDay,
  DietitianProgramScheduleRecipe,
} from "@client/schedule";

export const dropEndOptimisticUpdate = (
  input: { data: DietitianProgramScheduleDay[] } | undefined,
  dayId: number,
  mealId: number,
  draggedRecipe: DraggedRecipeData,
) => {
  const day1 = input?.data.find(entry => entry.id === dayId);
  const day2 = input?.data.find(entry => entry.id === draggedRecipe.dayId);
  if (day1?.meals && day2?.meals) {
    const meal1 = day1.meals?.findIndex(meal => meal.id === mealId);
    const meal2 = day2.meals?.findIndex(
      meal => meal.id === draggedRecipe.mealId,
    );
    if (meal1 >= 0 && meal2 >= 0) {
      const _ = day1.meals[meal1].recipe?.id
        ? ({
            ...day1.meals[meal1].recipe,
          } as DietitianProgramScheduleRecipe)
        : undefined;
      day1.meals[meal1].recipe = day2.meals[meal2].recipe;
      day2.meals[meal2].recipe = _ ?? null;
    }
  }
  return input;
};
