import { useQuery } from "@tanstack/react-query";

import { QueryOptionsTyped } from "../types";
import {
  fetchDietitianAccount,
  FetchDietitianAccountResponse,
} from "@client/settings";
import { ApiResponse } from "@typeDefinitions";
import dayjs from "dayjs";

export const fetchDietitianAccountQueryKey = "fetchDietitianAccountQueryKey";

export const useFetchDietitianAccountQuery = (
  options?: QueryOptionsTyped<ApiResponse<FetchDietitianAccountResponse>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchDietitianAccountQueryKey],
    fetchDietitianAccount,
    {
      onSuccess: data => {
        const subscription = data.data?.clinic?.subscription;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.subscriptionOK =
          subscription && subscription.finishedAt
            ? dayjs(subscription.finishedAt).endOf("day").isAfter(dayjs())
            : true;

        options?.onSuccess && options.onSuccess(data);
      },
      ...options,
    },
  );
  return { account: data?.data, ...rest };
};
