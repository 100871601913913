import { useDebounce } from "react-use";
import pick from "lodash/pick";
import { useEffect } from "react";
import { SearchProgramsPaginationParams } from "@client/searchProgramsNew";
import { useFormContext } from "react-hook-form";
import { ProgramFiltersFormPops } from "@views/dietician/Programs/ProgramsFiltersFormType";

type SearchFilterProps = {
  requestParams: SearchProgramsPaginationParams;
  isFetching: boolean;
  refetch: () => void;
  isFetched: boolean;
};

const useFetchSearchFilter = ({
  requestParams,
  isFetching,
  refetch,
  isFetched,
}: SearchFilterProps) => {
  const { getValues, setValue } = useFormContext<ProgramFiltersFormPops>();

  useDebounce(
    () => {
      const page = getValues("page");
      if (page > 1) {
        setValue("page", 0);
        return;
      }
      if (!isFetching && !isFetched) {
        refetch();
      }
    },
    500,
    [
      JSON.stringify(
        pick(requestParams, [
          "typeDays",
          "authorId",
          "days",
          "nutrients",
          "tags",
          "numberOfDietMeals",
          "latest",
          "favorite",
          "reviewed",
          "owner",
        ]),
      ),
    ],
  );

  useDebounce(
    () => {
      const page = getValues("page");
      if (page > 1) {
        setValue("page", 0);
        return;
      }
      if (!isFetching && !isFetched) {
        refetch();
      }
    },
    1000,
    [JSON.stringify(pick(requestParams, ["query"]))],
  );

  useEffect(() => {
    if (!isFetching && !isFetched) {
      refetch();
    }
  }, [requestParams.page, requestParams.perPage]);
};

export default useFetchSearchFilter;
