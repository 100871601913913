import { useAppTranslation } from "@hooks";
import { useRecipeDetails } from "./useRecipeDetails";

import { MacrosChart } from "../MacrosChartLegacy/MacrosChart";
import { Spinner } from "@components/Spinner";
import { ViewError } from "@components/ViewError";
import { IngredientsTable } from "@components/IngredientsTable";

import { Cooking } from "@assets/icons";

export const RecipeDetails = (props: RecipeDetailsProps) => {
  const { recipeId, wwPerRecipe } = props;

  const {
    recipe,
    ingredients,
    status,
    error,
    macrosSummed,
    control,
    chartData,
    glycaemicIndicators,
    glycaemicIndex,
    glycaemicLoad,
  } = useRecipeDetails(recipeId);

  const { t, isPolishChosen } = useAppTranslation();

  if (status === "loading")
    return <Spinner className="w-full h-screen bg-transparent" />;

  if (error || !recipe) return <ViewError />;

  return (
    <div className="flex flex-col gap-7 pt-7">
      {recipe.servings === 1 && `${t("recipe.recipe_simple")}`}
      {recipe.servings !== 1 &&
        `${t("recipe.recipe_complex", { count: recipe.servings })}`}
      <div className="grid grid-cols-12 gap-7 font-roman">
        <div className="flex flex-col col-span-9">
          <IngredientsTable
            macrosSummed={macrosSummed}
            ingredients={ingredients ?? []}
            ingredientsError={error}
            ingredientsStatus={status}
            glycaemicIndicators={glycaemicIndicators}
            glycaemicIndex={glycaemicIndex}
            glycaemicLoad={glycaemicLoad}
            wwPerRecipe={wwPerRecipe}
          />
          <div className="w-fill">
            <p className="flex items-center text-lg mb-4">
              <Cooking size="w-6 h-6" className="mr-2" />
              <span>{t("recipe.how_to_prepare")}:</span>
            </p>
            <p style={{ lineHeight: 1.5 }}>
              {isPolishChosen
                ? recipe?.description?.split("\n").map((str, index) => (
                    <span
                      key={`preparation-str-${index}`}
                      className="flex w-full mb-2"
                      style={{ lineHeight: 1.5 }}
                    >
                      {str}
                    </span>
                  ))
                : recipe?.descriptionEn?.split("\n").map((str, index) => (
                    <span
                      key={`preparation-en-str-${index}`}
                      className="flex w-full mb-2"
                      style={{ lineHeight: 1.5 }}
                    >
                      {str}
                    </span>
                  ))}
            </p>
          </div>
        </div>
        <div className="flex flex-col col-span-3">
          {ingredients && (
            <MacrosChart
              control={control}
              data={chartData}
              numOfPortions={recipe.servings}
            />
          )}
        </div>
      </div>
    </div>
  );
};

interface RecipeDetailsProps {
  recipeId: number;
  wwPerRecipe?: number;
}
