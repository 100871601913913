import { Plus } from "@assets/icons";
import { LangDto } from "@client";
import { useAppParams, useAppTranslation } from "@hooks";
import { usePostDietMealMutation } from "@views/dietician/DietCreator/hooks";
import {
  NAME_EN_PLACEHOLDER,
  NAME_PL_PLACEHOLDER,
} from "../ScheduleRow/useScheduleRowForm";
import { ButtonStyled } from "./AddRowButton.styled";

export const AddRowButton = () => {
  const { t } = useAppTranslation();
  const { dietId } = useAppParams();
  const { mutate } = usePostDietMealMutation();
  const handleAdd = () =>
    mutate({
      dietId,
      payload: {
        translations: [
          { langId: LangDto.PL, name: NAME_PL_PLACEHOLDER, description: "" },
          { langId: LangDto.EN, name: NAME_EN_PLACEHOLDER, description: "" },
        ],
        mealTypeId: null,
        hour: "23:00",
        targetKcal: 0,
        targetProtein: 0,
        targetFat: 0,
        targetCarb: 0,
      },
    });
  return (
    <div className="flex justify-start">
      <ButtonStyled color="primary" onClick={handleAdd}>
        <Plus size="w-[14px] h-[14px]" />
        {t("diet.add_meal")}
      </ButtonStyled>
    </div>
  );
};
