import { QueryOptionsTyped } from "@hooks/queries";
import { useQuery } from "@tanstack/react-query";
import {
  fetchPatientLastBodyMeasurement,
  FetchPatientLastBodyMeasurementParams,
  FetchPatientLastBodyMeasurementResponse,
} from "@client/patient/bodyMeasurement/fetchPatientLastBodyMeasurement";
import { patientBodyMeasurementKeys } from "@hooks/queries/client/bodyMeasurement/patientBodyMeasurementKey";

const useFetchPatientLastBodyMeasurement = (
  params: FetchPatientLastBodyMeasurementParams,
  options?: QueryOptionsTyped<FetchPatientLastBodyMeasurementResponse>,
) => {
  return useQuery({
    queryKey: patientBodyMeasurementKeys.last(params.patientId),
    queryFn: () => fetchPatientLastBodyMeasurement(params),
    ...options,
  });
};

export default useFetchPatientLastBodyMeasurement;
