import { useCallback, useMemo } from "react";
import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import { useFetchMonitoringMeasurements } from "@hooks/queries/client/monitoring";
import MeasurementsBodySection from "@views/dietician/PatientMonitoring2/components/MeasurementsBox/MeasurementsBodySection";
import { Dayjs } from "dayjs";
import { useFetchClientQueryNew } from "@hooks/queries";
import { mapClientDataToGoals } from "@views/dietician/PatientMonitoring2/components/MeasurementsBox/MeasurementUtil";
import { useAppTranslation } from "@hooks";
import { BodyMeasurementMonitoringResource } from "@client/resources/BodyMeasurementMonitoringResource";
import {
  tooltipLabelFormatter,
  xAxisTickFormatter,
} from "@views/dietician/PatientMonitoring2/utils/ChartUtils";

const MeasurementsBodySectionOfDay = () => {
  const { t } = useAppTranslation();
  const { patientId, selectedDays } = useMonitoring();

  const { data } = useFetchMonitoringMeasurements({
    patientId,
    dateFrom: selectedDays.selectDays.from
      .clone()
      .add(-3, "days")
      .format("YYYY-MM-DD"),
    dateTo: selectedDays.selectDays.to
      .clone()
      .add(3, "days")
      .format("YYYY-MM-DD"),
  });
  const { data: clientData } = useFetchClientQueryNew(patientId);

  const latestMeasurement = useMemo(
    () => getLatestMeasurement(data?.data ?? [], selectedDays.selectDays.from),
    [data, selectedDays.selectDays.from],
  );

  const goals = useMemo(
    () => mapClientDataToGoals(clientData?.data),
    [clientData],
  );

  const getXAxisTickFormatter = useCallback(
    (date: string) => xAxisTickFormatter(t, date),
    [t],
  );

  const getTooltipLabelFormatter = useCallback(
    (date: string) => tooltipLabelFormatter(t, date),
    [t],
  );

  return (
    <MeasurementsBodySection
      data={data?.data}
      latest={latestMeasurement}
      goals={goals}
      xAxisTickFormatter={getXAxisTickFormatter}
      tooltipLabelFormatter={getTooltipLabelFormatter}
    />
  );
};

const getLatestMeasurement = (
  data: BodyMeasurementMonitoringResource[],
  today: Dayjs,
): BodyMeasurementMonitoringResource | null => {
  if (!data.length) {
    return null;
  }

  const date = today.format("YYYY-MM-DD");

  const measurement = data.find(i => i.date === date);

  if (measurement && measurement.measurements) {
    return measurement;
  }

  return null;
};

export default MeasurementsBodySectionOfDay;
