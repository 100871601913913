import { fetchData } from "@utils/api";
import { APIMethods, NoContentApiResponse } from "@typeDefinitions";

export type UpdatePatientProductExchangersParams = {
  patientId: number;
};

export type UpdatePatientProductExchangersResponse = NoContentApiResponse;

export type UpdatePatientProductExchangersPayload = {
  tags_id: number[];
};

export const updatePatientProductExchangers = async (
  params: UpdatePatientProductExchangersParams,
  payload: UpdatePatientProductExchangersPayload,
): Promise<UpdatePatientProductExchangersResponse> => {
  return await fetchData(
    "/dietitian/patients/{patient_id}/product-exchanger-type".replace(
      "{patient_id}",
      params.patientId.toString(),
    ),
    APIMethods.PUT,
    payload,
  );
};
