import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const PersonWithCheck = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="12" viewBox="0 0 14 12">
      <path
        d="M2.53203 3.40005C2.53203 2.71049 2.80596 2.04917 3.29355 1.56157C3.78115 1.07398 4.44247 0.800049 5.13203 0.800049C5.82159 0.800049 6.48291 1.07398 6.97051 1.56157C7.4581 2.04917 7.73203 2.71049 7.73203 3.40005C7.73203 4.08961 7.4581 4.75093 6.97051 5.23853C6.48291 5.72612 5.82159 6.00005 5.13203 6.00005C4.44247 6.00005 3.78115 5.72612 3.29355 5.23853C2.80596 4.75093 2.53203 4.08961 2.53203 3.40005ZM0.582031 10.5968C0.582031 8.59599 2.20297 6.97505 4.20375 6.97505H6.06031C8.06109 6.97505 9.68203 8.59599 9.68203 10.5968C9.68203 10.9299 9.41187 11.2 9.07875 11.2H1.18531C0.852188 11.2 0.582031 10.9299 0.582031 10.5968ZM13.2773 4.39536L10.6773 6.99536C10.4864 7.1863 10.1777 7.1863 9.98875 6.99536L8.68875 5.69536C8.49781 5.50442 8.49781 5.19567 8.68875 5.00677C8.87969 4.81786 9.18844 4.81583 9.37734 5.00677L10.332 5.96145L12.5867 3.70474C12.7777 3.5138 13.0864 3.5138 13.2753 3.70474C13.4642 3.89567 13.4663 4.20442 13.2753 4.39333L13.2773 4.39536Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
