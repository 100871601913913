import {
  UpdateSurveyGroupsSortRequest,
  updateSurveyGroupsSort,
} from "@client/surveys";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "../types";
import { fetchSurveyFullQueryKey } from "./useFetchSurveyFullQuery";

export const useUpdateSurveyGroupsSortMutation = (
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, UpdateSurveyGroupsSortMutationPayload>(
    ({ id, payload }) => updateSurveyGroupsSort(id, payload),
    {
      ...options,
      onSuccess(data, variables) {
        options?.onSuccess && options.onSuccess(data, variables);

        queryClient.invalidateQueries([fetchSurveyFullQueryKey, variables.id]);
      },
    },
  );
};

interface UpdateSurveyGroupsSortMutationPayload {
  id: string;
  payload: UpdateSurveyGroupsSortRequest;
}
