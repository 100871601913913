import { useEffect, useState } from "react";
import { useDebounce } from "react-use";
import { useFormContext, useWatch } from "react-hook-form";

import { NewRecipeDto } from "@client";
import {
  mapRecipeRequest,
  RecipeEditInput,
} from "@components/RecipeForm/useRecipeEditForm";
import { useUpdateRecipeMutation } from "@hooks/queries";
import { toast } from "react-toastify";
import { useAppTranslation } from "@hooks";

interface RecipeEditWatch {
  recipe: NewRecipeDto;
  recipeLoading: boolean;
}

export const RecipeEditWatch = ({ recipeLoading, recipe }: RecipeEditWatch) => {
  const { t } = useAppTranslation();
  const values = useWatch<RecipeEditInput>();
  const {
    handleSubmit,
    formState: { isDirty },
  } = useFormContext<RecipeEditInput>();

  const { mutate, isLoading } = useUpdateRecipeMutation();
  const [queued, setQueued] = useState(false);

  const createRequest = (data: RecipeEditInput, recipe: NewRecipeDto) => {
    return {
      id: recipe.id,
      payload: mapRecipeRequest(data, recipe.id, recipe.version),
    };
  };

  const update = () => {
    setQueued(false);
    handleSubmit(
      data => mutate(createRequest(data, recipe)),
      err => {
        console.log(err);
        if (err.ingredients) toast.error(t("recipes.exceeded_grams"));
      },
    )();
  };

  const [isDirtyLatch, setIsDirtyLatch] = useState(isDirty);

  useDebounce(
    () => {
      if (isLoading || recipeLoading) {
        setQueued(true);
      }

      if (
        (isDirty || isDirtyLatch) &&
        values &&
        recipe &&
        !(isLoading || recipeLoading)
      ) {
        setIsDirtyLatch(true);
        update();
      }
    },
    1000,
    [JSON.stringify(values)],
  );

  useEffect(() => {
    if (queued && !(isLoading || recipeLoading)) {
      update();
    }
  }, [queued, isLoading || recipeLoading]);

  return <></>;
};
