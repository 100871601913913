import { Box } from "@mui/material";
import { useAppTranslation } from "@hooks";
import {
  CustomLegend,
  LegendCircle,
  LegendItem,
  LegendText,
} from "@views/dietician/PatientMonitoring2/components/CustomChartLegend";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Props } from "recharts/types/component/DefaultLegendContent";
import { useMemo } from "react";
import { Goals } from "@views/dietician/PatientMonitoring2/components/MeasurementsBox/MeasurementUtil";
import { useFetchBodyMeasurementsQuery } from "@hooks/queries/dictionaries";
import { BodyMeasurementMonitoringResource } from "@client/resources/BodyMeasurementMonitoringResource";

type MeasurementChartType = {
  data: BodyMeasurementMonitoringResource[];
  type: number | null;
  goals: Goals;
  tooltipLabelFormatter: (date: string) => string;
  xAxisTickFormatter: (date: string) => string;
};

const MeasurementChart = ({
  data,
  type,
  goals,
  xAxisTickFormatter,
  tooltipLabelFormatter,
}: MeasurementChartType) => {
  const { t } = useAppTranslation();
  const { data: bodyMeasurements } = useFetchBodyMeasurementsQuery();

  const preparedData = useMemo(() => {
    if (!type) {
      return [];
    }

    return prepareData(data, type, goals);
  }, [data, type, goals]);

  const goal = useMemo(
    () => goals.find(goal => goal.id === type),
    [goals, type],
  );

  const goalLabel = useMemo(() => {
    if (!goal) {
      return "";
    }

    if (!bodyMeasurements) {
      return "";
    }

    const bodyMeasurement = bodyMeasurements.data.find(
      bodyMeasurement => bodyMeasurement.id === type,
    );

    return `${t("monitoring2.measurements_box.chart_legends.planed")} (${
      goal.value
    }${bodyMeasurement?.unit})`;
  }, [type, goal, t, bodyMeasurements]);

  const renderColorfulLegendText = (value: string) => {
    return (
      <span
        style={{ color: "#2F303A", fontStyle: "12px", marginRight: "24px" }}
      >
        {value}
      </span>
    );
  };

  const renderLegend = (props: Props) => {
    const { payload } = props;

    return (
      <CustomLegend>
        {payload?.map((entry, index) => (
          <LegendItem key={`item-${index}`}>
            <LegendCircle style={{ background: entry.color }} />
            <LegendText>{entry.value}</LegendText>
          </LegendItem>
        ))}
      </CustomLegend>
    );
  };

  return (
    <Box width="100%" height="200px">
      <ResponsiveContainer>
        <LineChart
          data={preparedData}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <XAxis
            dataKey="date"
            color="#F0F0F0"
            stroke="#A3A3A3"
            tickFormatter={xAxisTickFormatter}
          />
          <YAxis
            color="#F0F0F0"
            stroke="#A3A3A3"
            width={40}
            domain={[
              (dataMin: number) => Math.round(dataMin * 0.95 * 10) / 10,
              (dataMax: number) => Math.round(dataMax * 1.05 * 10) / 10,
            ]}
          />
          <CartesianGrid color="#F0F0F0" />
          <Legend
            iconType="circle"
            iconSize={8}
            formatter={renderColorfulLegendText}
            content={renderLegend}
          />
          <Tooltip
            cursor={{ fill: "#FBFAFC", stroke: "#F0F0F0" }}
            wrapperStyle={{
              background: "#fff",
              borderColor: "#F0F0F0",
              outline: "none",
            }}
            contentStyle={{
              background: "#fff",
              borderColor: "#F0F0F0",
              outline: "none",
            }}
            labelFormatter={tooltipLabelFormatter}
          />
          <Line
            type="monotone"
            name={t("monitoring2.measurements_box.chart_legends.realized")}
            dataKey="value"
            stroke="#7448D0"
            strokeWidth={2}
            dot={{ fill: "#7448D0" }}
            connectNulls={true}
          />
          {!!goal && (
            <Line
              type="monotone"
              name={goalLabel}
              dataKey="goal"
              stroke="#7448D070"
              strokeDasharray="4 4"
              dot={false}
              strokeWidth={2}
              connectNulls={true}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

const prepareData = (
  data: BodyMeasurementMonitoringResource[],
  type: number,
  goals: Goals,
): { date: string; value: number | null; goal?: number }[] => {
  return (
    data.map(item => {
      let value =
        item.measurements?.bodyMeasurements.find(
          bodyMeasurement => bodyMeasurement.body.id === type,
        )?.value ?? null;
      if (value === 0) {
        value = null;
      }
      const tmp: { date: string; value: number | null; goal?: number } = {
        date: item.date,
        value: value ? Math.round(value * 10) / 10 : value,
      };
      const goal = goals.find(goal => goal.id === type)?.value;
      if (goal) {
        tmp["goal"] = goal;
      }
      return tmp;
    }) ?? []
  );
};

export default MeasurementChart;
