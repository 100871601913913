import { TabPanel } from "@mui/lab";
import { IconButton, TextField, styled } from "@mui/material";

export const IconButtonOutlined = styled(IconButton)(
  ({ theme }) => `
  color: ${theme.palette.primary.main};
  border: 1px solid ${theme.palette.primary.medium};
  background: ${theme.colors.neutral.light[100]};
  height: 40px;
  width: 40px;
  & svg {
    height: 16px;
    width: 16px;
  }
  &.MuiButtonBase-root.MuiIconButton-root:hover {
    background: ${theme.colors.neutral.light[100]};
  }
`,
);

export const TabPanelStyled = styled(TabPanel)`
  display: grid;
  gap: 8px;
  padding: 0;
`;

export const SearchField = styled(TextField)`
  & .MuiInputBase-root.MuiOutlinedInput-root {
    padding-right: 0;
  }
`;
