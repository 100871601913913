import { useMemo } from "react";
import { FoodGroupTreeResponse } from "@typeDefinitions/responses/dictionaries";
import { useAppTranslation } from "@hooks";
import { TreeSelectOption } from "@components/TreeSelect";

export function useFoodGroupTreeOptions(
  foodGroupTree?: FoodGroupTreeResponse[],
) {
  const { isPolishChosen } = useAppTranslation();

  return useMemo(
    () => dtoToTreeOptions(foodGroupTree, isPolishChosen),
    [foodGroupTree],
  );
}

export const dtoToTreeOptions = (
  tree?: FoodGroupTreeResponse[],
  isPolishChosen?: boolean,
): TreeSelectOption[] => {
  return (
    tree?.map(t => ({
      value: t.children.length ? `${t.id.toString()}-parent` : t.id.toString(),
      title: isPolishChosen
        ? t.descriptionPl ?? t.description
        : t.description ?? t.descriptionPl,
      children: t.children.length
        ? [
            {
              value: t.id.toString(),
              title: isPolishChosen
                ? "Wszystkie: " + t.descriptionPl ?? t.description
                : "All: " + t.description ?? t.descriptionPl,
              children: [],
            },
            ...dtoToTreeOptions(t.children, isPolishChosen),
          ]
        : [],
    })) ?? []
  );
};
