import { useLocation, useNavigate } from "react-router-dom";
import qs from "qs";

import omit from "lodash/omit";

const getRouterParams = (path: string) => path.split("?").slice(1).join("");
export const getBasePath = (path: string) => path.split("?")[0];

const getQuery = (path: string) => qs.parse(getRouterParams(path));

const getQueryString = (params: Record<string, unknown>) => {
  const queryString = qs.stringify(params, {
    filter: (_, value) => {
      if (!value) {
        return;
      } else return value;
    },
    sort: (a, b) => a.localeCompare(b),
    arrayFormat: "repeat",
  });

  return queryString ? `?${queryString}` : "";
};

/**
 * useQueryParam - custom hook to easily consume query params with react-router-dom
 */
export const useQueryParams = <
  T extends string[],
  ReturnType extends Partial<Record<T[number], unknown>>,
>(
  ...lookupParams: T
) => {
  const location = useLocation();
  const navigate = useNavigate();

  const update = (
    paramsUpdate: Partial<Record<T[number], string | string[]>>,
  ) => {
    const queryString = getQueryString({
      ...getQuery(location.search),
      ...paramsUpdate,
    });

    navigate(location.pathname + queryString, { replace: true });
  };

  const updateQueryParamsWithSegment = (
    paramsUpdate: Partial<Record<T[number], string | string[]>>,
    dynamicSegments: Record<string, string>,
  ) => {
    const currentParams = getQuery(location.search);

    const updatedParams = { ...currentParams, ...paramsUpdate };

    const queryString = getQueryString(updatedParams);

    let updatedPathname = location.pathname;
    for (const key in dynamicSegments) {
      updatedPathname = updatedPathname.replace(
        `[${key}]`,
        dynamicSegments[key],
      );
    }

    const newPath = `${updatedPathname}${queryString ? `${queryString}` : ""}`;

    navigate(newPath, { replace: true });
  };

  const remove = async (...params: T[number][]) => {
    const queryString = getQueryString({
      ...omit(
        getQuery(location.search) as ReturnType,
        ...(params.length > 0 ? params : lookupParams),
      ),
    });

    navigate(getBasePath(location.pathname) + queryString, { replace: true });
  };

  const get = <ReturnType extends Partial<Record<T[number], unknown>>>() => {
    const query = getQuery(location.search);
    return Object.fromEntries(
      Object.entries(query).filter(([name]) => lookupParams.includes(name)),
    ) as ReturnType;
  };

  return {
    update,
    updateQueryParamsWithSegment,
    remove,
    get,
    getQuery,
  };
};
