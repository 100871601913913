import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { ChangeEvent, FC, forwardRef } from "react";
import { CheckboxControlLabelStyled } from "@Pdf/components/CheckboxFieldset/ChecbkoxControlLabelStyled";
import LabelField from "@Pdf/components/LabelField/LabelField";

type PropsType = {
  options: Array<{ id: string; name: string }>;
  value: Array<string>;
  label: string;
  onChange: (value: Array<string>) => void;
};

const CheckboxFieldset: FC<PropsType> = forwardRef<
  HTMLFieldSetElement,
  PropsType
>(({ options, value, label, onChange }, ref) => {
  const handleChange = (event: ChangeEvent) => {
    const selected = (event.target as HTMLInputElement).name;

    if (value.includes(selected)) {
      onChange(value.filter(item => item !== selected));
    } else {
      onChange([...value, selected]);
    }
  };

  return (
    <FormControl component="fieldset" ref={ref}>
      <LabelField label={label}>
        <FormGroup className={"gap-[8px]"}>
          {options.map(option => (
            <CheckboxControlLabelStyled key={option.id}>
              <FormControlLabel
                style={{ marginLeft: 0, marginRight: 0 }}
                control={
                  <Checkbox
                    checked={value.includes(option.id)}
                    onChange={handleChange}
                    name={option.id}
                  />
                }
                label={option.name}
              />
            </CheckboxControlLabelStyled>
          ))}
        </FormGroup>
      </LabelField>
    </FormControl>
  );
});

export default CheckboxFieldset;
