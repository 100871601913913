import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import type { SuggestedTasksVisibilityRequestPayload } from "@client/suggestedTasks/toggleSuggestedTasksVisibility";
import { toggleSuggestedTasksVisibility } from "@client/suggestedTasks/toggleSuggestedTasksVisibility";
import { ApiEndpoints } from "@typeDefinitions";
import { useAppTranslation } from "@hooks/useAppTranslation";

export const useToggleSuggestedTasksMutation = () => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();

  const mutation = useMutation<
    AxiosResponse,
    AxiosError,
    SuggestedTasksVisibilityRequestPayload
  >(data => toggleSuggestedTasksVisibility(data), {
    onSuccess: () => {
      const queryKeys = [
        [ApiEndpoints.SuggestedTasks],
        [ApiEndpoints.SuggestedTasksList],
        [ApiEndpoints.SuggestedTasksCount],
      ];

      queryKeys.forEach(queryKey => {
        queryClient.invalidateQueries({ queryKey });
      });
      toast.success(t("tasks.updateSuccess"), { autoClose: 1000 });
    },
  });

  const handleUpdateTaskStatus = async (
    data: SuggestedTasksVisibilityRequestPayload,
  ) => {
    try {
      await mutation.mutateAsync(data);
    } catch {
      toast.error(t("tasks.updateFailure"));
    }
  };

  return { handleUpdateTaskStatus, ...mutation };
};
