import { useMemo, useState } from "react";

import { TabContext } from "@mui/lab";
import { CircularProgress, ClickAwayListener } from "@mui/material";
import { round } from "lodash";

import { FoodBowl } from "@assets/icons/DesignSystem";
import { LangDto } from "@client";
import { TagWithIcon } from "@components/TagWithIcon";
import { useAppTranslation } from "@hooks";
import { usePostToggleFavoriteDietMutation } from "@hooks/queries";
import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";
import { ENERGY_ID } from "@utils/macros";

import { DetailsSection } from "../common/DetailsSection";
import { PreviewActionButtons } from "../common/PreviewActionButtons";
import { TabsWrapper } from "../common/TabsWrapper";
import { TagsTab } from "../common/TagsTab";
import {
  DetailsWrapper,
  ImgStyled,
  LoadingWrapper,
  TabPanelStyled,
  Wrapper,
} from "./DietPreview.styled";
import { NutrientsTab } from "./NutrientsTab/NutrientsTab";
import { MealsTab } from "./MealsTab/MealsTab";
import { useSearchDietsPreviewDietQuery } from "@hooks/queries/diets";

interface DietPreviewProps {
  dietId: number | null;
  onClose: () => void;
}

export const DietPreview = ({ dietId, onClose }: DietPreviewProps) => {
  const { t, isPolishChosen } = useAppTranslation();
  const { diet, isLoading } = useSearchDietsPreviewDietQuery(
    dietId?.toString() ?? "",
    {
      enabled: !!dietId,
    },
  );
  const { mutate, isLoading: isLoadingToggle } =
    usePostToggleFavoriteDietMutation();
  const { account } = useFetchDietitianAccountQuery();

  const [tab, setTab] = useState(Tabs.MEALS);

  const titleEn = diet?.translations.find(t => t.lang === LangDto.EN)?.name;
  const roundedKcal = round(
    diet?.nutrients.find(n => n.id === ENERGY_ID)?.value ?? 0,
  );
  const mealsCount = diet?.meals.length ?? 0;
  const img = diet?.image?.url || null;

  const tabs = useMemo(
    () => [
      {
        label: t("common.nutrients_values"),
        value: Tabs.NUTRITIONAL_VALUES,
      },
      {
        label: t("common.meals"),
        value: Tabs.MEALS,
      },
      {
        label: t("common.tags"),
        value: Tabs.TAGS,
      },
    ],
    [t],
  );

  const mealsMapped = useMemo(
    () =>
      diet?.meals.map(m => {
        const nameEn =
          m.translations.find(t => t.lang === LangDto.EN)?.name ?? "";
        return {
          id: m.id.toString(),
          label: `${isPolishChosen ? m.name : nameEn} (${m.itemsCount})`,
        };
      }) ?? [],
    [diet?.meals, isPolishChosen],
  );

  if (!dietId)
    return (
      <ClickAwayListener onClickAway={onClose}>
        <Wrapper />
      </ClickAwayListener>
    );

  if (isLoading)
    return (
      <ClickAwayListener onClickAway={onClose}>
        <Wrapper>
          <LoadingWrapper>
            <CircularProgress />
          </LoadingWrapper>
        </Wrapper>
      </ClickAwayListener>
    );

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Wrapper>
        <TabContext value={tab}>
          {img && (
            <PreviewActionButtons
              onToggle={() => mutate(dietId?.toString() ?? "")}
              isLoading={isLoadingToggle}
              isFavorite={!!diet?.favorite.includes(account?.id ?? 0)}
              actions={diet?.actions}
              editLink={`/diets/${dietId}/edit`}
              copyLink={`/diets/${dietId}/copy`}
              onClose={onClose}
              withImg
            />
          )}

          {img && <ImgStyled src={img} />}

          <DetailsWrapper>
            <DetailsSection
              titlePl={diet?.name ?? ""}
              titleEn={titleEn ?? ""}
              kcal={roundedKcal}
              actions={
                <>
                  {!img && (
                    <PreviewActionButtons
                      onToggle={() => mutate(dietId?.toString() ?? "")}
                      isLoading={isLoadingToggle}
                      isFavorite={!!diet?.favorite.includes(account?.id ?? 0)}
                      actions={diet?.actions}
                      editLink={`/diets/${dietId}/edit`}
                      copyLink={`/diets/${dietId}/copy`}
                      onClose={onClose}
                    />
                  )}
                </>
              }
              tags={
                <TagWithIcon
                  Icon={FoodBowl}
                  label={`${mealsCount} ${t("diets.meals", {
                    count: mealsCount,
                  })}`}
                />
              }
            />

            <TabsWrapper onChange={v => setTab(v)} tabs={tabs} value={tab} />
          </DetailsWrapper>

          <TabPanelStyled value={Tabs.NUTRITIONAL_VALUES}>
            <NutrientsTab nutrients={diet?.nutrients ?? []} />
          </TabPanelStyled>

          <TabPanelStyled value={Tabs.MEALS}>
            {!!mealsMapped.length && (
              <MealsTab meals={mealsMapped} dietId={dietId} />
            )}
          </TabPanelStyled>

          <TabPanelStyled value={Tabs.TAGS}>
            <TagsTab tags={diet?.tags.map(t => Number(t.id)) ?? []} />
          </TabPanelStyled>
        </TabContext>
      </Wrapper>
    </ClickAwayListener>
  );
};

enum Tabs {
  NUTRITIONAL_VALUES = "0",
  MEALS = "1",
  TAGS = "2",
}
