import { styled, Tabs } from "@mui/material";

interface StyledTabsProps {
  height?: number;
}

export const StyledTabs = styled(Tabs)<StyledTabsProps>`
  height: ${({ height }) => (height ? `${height}rem` : "unset")};
  & .MuiTabs-scroller.MuiTabs-hideScrollbar.MuiTabs-scrollableY {
    justify-content: end;
    display: grid;
  }
`;

export const ChildrenTabWrapper = styled("div")`
  display: grid;
  gap: 0.25rem;
  align-items: end;
  width: 100%;
`;

export const StyledNutrients = styled("p")`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: 0.625rem;
  color: ${({ theme }) => theme.colors.black087};
  margin-left: auto;
  &:focus {
    color: unset;
  }
`;

export const EmptyDiv = styled("div")`
  width: 100%;
  height: 0.875rem;
`;

export const StyledTitle = styled("p")`
  max-width: 12.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: auto;
`;
