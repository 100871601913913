import { useContext } from "react";

import { TableOfContentsContext } from "@context/TableOfContentsContext";
import { PointerArrowLeft, PointerArrowRight } from "@assets/icons/Arrows";
import { FullSurveyDto } from "@client/surveys";
import { useAppTranslation } from "@hooks";

import { ButtonStyled } from "./QuestionnaireAnswerPreviewModal.styled";

interface NavigationProps {
  survey?: FullSurveyDto;
}

export const Navigation = ({ survey }: NavigationProps) => {
  const { subgroupId, setSubgroupId, setGroupId, setSelectedId, selectedId } =
    useContext(TableOfContentsContext);
  const { t } = useAppTranslation();
  const subgroupsList =
    survey?.groups.flatMap(g =>
      g.sections.map(s => ({ id: s.id, groupId: g.id })),
    ) ?? [];

  const currentSubgroupIndex = subgroupsList.findIndex(
    s => s.id === subgroupId,
  );
  const isSubgroupSelected = selectedId.split("-").length === 2;
  const hasNext =
    currentSubgroupIndex !== -1 &&
    currentSubgroupIndex + 1 < subgroupsList.length;
  const hasPrev = currentSubgroupIndex !== -1 && currentSubgroupIndex > 0;
  const isSend = !hasNext && isSubgroupSelected;

  const handleNext = () => {
    if (!subgroupsList.length || !subgroupsList[currentSubgroupIndex + 1])
      return;
    console.log();
    if (!subgroupId) return setSubgroupId(subgroupsList[0].id);

    const { id, groupId } = subgroupsList[currentSubgroupIndex + 1];

    setSubgroupId(id);
    setGroupId(groupId);
    setSelectedId(`${groupId}-${id}`);
  };

  const handlePrev = () => {
    if (
      !subgroupsList.length ||
      !subgroupId ||
      !subgroupsList[currentSubgroupIndex - 1]
    )
      return;

    const { id, groupId } = subgroupsList[currentSubgroupIndex - 1];

    setSubgroupId(id);
    setGroupId(groupId);
    setSelectedId(`${groupId}-${id}`);
  };

  return (
    <>
      <ButtonStyled
        variant="outlined"
        disabled={!hasPrev}
        onClick={handlePrev}
        startIcon={<PointerArrowLeft />}
      >
        {t("questionnaires.previous")}
      </ButtonStyled>
      <ButtonStyled
        variant="contained"
        onClick={isSend ? undefined : handleNext}
        disabled={isSend}
        endIcon={<PointerArrowRight />}
      >
        {t("questionnaires.next")}
      </ButtonStyled>
    </>
  );
};
