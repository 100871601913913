import { Ref } from "react";

import { Divider, Tooltip } from "@mui/material";

import { useAppTranslation, useClientTargets, useModal } from "@hooks";

import { AccordionContent } from "./GoalsSection.styled";
import { CheckedIcon, List, Pencil } from "@assets/icons";
import dayjs from "dayjs";
import {
  AccordionSummaryStyled,
  EditButton,
  SectionTitle,
  SectionWrapper,
  StyledAccordion,
} from "../../ClientHealth.styled";
import { ProfileSectionProps, noData } from "../../ClientHealth";
import { ClientProfileCardItem } from "../ClientProfileCardItem";
import { useFetchClientLastMeasurementQuery } from "@hooks/queries";
import { calculateBmi } from "@views/dietician/PatientEnergyBalance";
import { round } from "lodash";
import { MeasurementList } from "../MeasurementList";
import { useEmptyDataString } from "@hooks/helpers";
import { EditClientMeasurementModal } from "@components/EditClientMeasurementModal";
import { AddClientMeasurementModal } from "@components/AddClientMeasurementModal";
import {
  ClientProfileCard,
  TitleSection,
} from "@views/dietician/ClientProfile";

interface InfoSectionProps extends ProfileSectionProps {
  id: number;
  infoSectionRef?: Ref<HTMLDivElement>;
}

export const GoalsSection = ({
  id,
  account,
  infoSectionRef,
}: InfoSectionProps) => {
  const { t } = useAppTranslation();
  const {
    active,
    profile: { targetWeight, targets },
  } = account;
  const { lastMeasurement } = useFetchClientLastMeasurementQuery(id.toString());
  const { modalOpened, onModalOpen, onModalClose } = useModal();
  const {
    modalOpened: listOpened,
    onModalOpen: onListOpen,
    onModalClose: onListClose,
  } = useModal();
  const lastDate = lastMeasurement
    ? dayjs(lastMeasurement.date).format("DD.MM.YYYY")
    : noData;
  const clientBmi =
    lastMeasurement?.weight && lastMeasurement?.growth
      ? round(calculateBmi(lastMeasurement.weight, lastMeasurement.growth), 2)
      : noData;
  const targetId = targets[0]?.id ?? 0;
  const { getTargetName } = useClientTargets();
  const toggleListButton = () => {
    listOpened ? onListClose() : onListOpen();
  };

  return (
    <>
      <ClientProfileCard ref={infoSectionRef}>
        <TitleSection>
          <p>{t("client_profile.health.goals_and_measurements")}</p>
          <Tooltip
            title={t("common.access_functionality_tooltip")}
            disableHoverListener={!!active}
            arrow
            placement="top"
          >
            <div>
              <EditButton
                onClick={onModalOpen}
                startIcon={<Pencil size="w-4 h-4" className="stroke-current" />}
                disableRipple
                disabled={!active}
              >
                {t("common.edit")}
              </EditButton>
            </div>
          </Tooltip>
        </TitleSection>

        <SectionWrapper>
          <SectionTitle>{t("client_profile.health.goals")}</SectionTitle>

          <ClientProfileCardItem
            title={t("client_profile.health.goals")}
            subtitle={getTargetName(targetId) || noData}
          />
          <ClientProfileCardItem
            title={t("client_profile.health.target_body_weight")}
            subtitle={targetWeight ? `${targetWeight} kg` : noData}
          />

          <Divider />

          <div className="flex justify-between">
            <SectionTitle>
              {t("client_profile.health.measurements")}
            </SectionTitle>
            <EditButton
              onClick={toggleListButton}
              startIcon={<List size="w-4 h-4" />}
              disableRipple
            >
              {t("client_profile.health.measurements_list")}
            </EditButton>
          </div>
          <ClientProfileCardItem
            title={t("client_profile.health.last_measurement_date")}
            subtitle={lastDate}
          />
          <ClientProfileCardItem
            title={t("client_profile.health.height")}
            subtitle={
              useEmptyDataString(lastMeasurement?.growth, "cm") ?? noData
            }
          />
          <ClientProfileCardItem
            title={t("client_profile.health.weight")}
            subtitle={
              useEmptyDataString(lastMeasurement?.weight, "kg") || noData
            }
          />
          <Tooltip
            followCursor
            placement="right"
            arrow
            title={t("client_profile.health.bmi_tooltip")}
          >
            <div>
              <ClientProfileCardItem
                title={t("client_profile.health.bmi")}
                subtitle={clientBmi}
              />
            </div>
          </Tooltip>
          <ClientProfileCardItem
            title={t("client_profile.health.body_fat")}
            subtitle={
              useEmptyDataString(lastMeasurement?.bodyFatLevel, "%") || noData
            }
          />

          <StyledAccordion disableGutters>
            <AccordionSummaryStyled expandIcon={<CheckedIcon />}>
              {t("client_profile.health.body_circumferences")}
            </AccordionSummaryStyled>

            <AccordionContent>
              <div className="grid gap-6">
                <SectionTitle>
                  {t("client_profile.health.circumferences")}
                </SectionTitle>
                <ClientProfileCardItem
                  title={t("client_profile.health.arm")}
                  subtitle={
                    useEmptyDataString(lastMeasurement?.arm, "cm") || noData
                  }
                />
                <ClientProfileCardItem
                  title={t("client_profile.health.biceps")}
                  subtitle={
                    useEmptyDataString(lastMeasurement?.tightBiceps, "cm") ||
                    noData
                  }
                />
                <ClientProfileCardItem
                  title={t("client_profile.health.waist")}
                  subtitle={
                    useEmptyDataString(lastMeasurement?.waist, "cm") || noData
                  }
                />
                <ClientProfileCardItem
                  title={t("client_profile.health.hips")}
                  subtitle={
                    useEmptyDataString(lastMeasurement?.hip, "cm") || noData
                  }
                />
                <ClientProfileCardItem
                  title={t("client_profile.health.thigh")}
                  subtitle={
                    useEmptyDataString(lastMeasurement?.thigh, "cm") || noData
                  }
                />
                <ClientProfileCardItem
                  title={t("client_profile.health.calf")}
                  subtitle={
                    useEmptyDataString(lastMeasurement?.calf, "cm") || noData
                  }
                />
              </div>
            </AccordionContent>
          </StyledAccordion>
        </SectionWrapper>
      </ClientProfileCard>
      {lastMeasurement ? (
        <EditClientMeasurementModal
          open={modalOpened}
          onClose={onModalClose}
          id={id.toString()}
        />
      ) : (
        <AddClientMeasurementModal
          open={modalOpened}
          onClose={onModalClose}
          id={id.toString()}
        />
      )}

      <MeasurementList
        onClose={onListClose}
        opened={listOpened}
        clientId={id}
        active={!!active}
      />
    </>
  );
};
