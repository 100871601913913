import { useMemo } from "react";

import { round } from "lodash";

import { useMealClientContext, useMeasures, useNutrients } from "@hooks";
import { scaleNutrients } from "@utils";
import { ENERGY_ID, MACROS_ARRAY, macrosSortKey } from "@utils/macros";

import {
  Amount,
  AmountCell,
  BoldText,
  Name,
  NameCell,
  NutrientWrapper,
  NutrientsListWrapper,
  ProductRowStyled,
  Text,
} from "./ProductRow.styled";
import { ProductIf } from "./ProductsTable";
import { mapUserChips, UserChips } from "@components/ExpandableChips";

export const ProductRow = ({
  name,
  grams,
  measureId,
  nutrients,
  measures,
  foodId,
}: ProductIf) => {
  const { getMeasure } = useMeasures();
  const { nutrientDict } = useNutrients();
  const { productsClientContextMap } = useMealClientContext();

  const measure = getMeasure(measureId);
  const selectedMeasure = measures.find(m => m.id === measureId)?.grams ?? 1;
  const measureValue = grams / selectedMeasure;

  const clientContext = productsClientContextMap.get(foodId);
  const userTags = useMemo(
    () =>
      mapUserChips({
        allergen: clientContext?.hasAllergens,
        disliked: clientContext?.hasUnliked,
        liked: clientContext?.hasLiked,
      }),
    [clientContext],
  );

  const sortedNutrients = useMemo(
    () =>
      nutrients
        .filter(n => MACROS_ARRAY.includes(n.id))
        .sort((a, b) => macrosSortKey(a.id, b.id)),
    [nutrients],
  );

  const proportionalNutrients = useMemo(
    () => scaleNutrients(sortedNutrients, grams),
    [sortedNutrients, grams],
  );

  return (
    <ProductRowStyled>
      <NameCell>
        <Name>{name}</Name>
        <UserChips tags={userTags} />
      </NameCell>

      <AmountCell>
        <Amount dark>{round(grams)}g&nbsp;</Amount>
        <Amount>
          / {round(measureValue, 2)} {measure?.name}
        </Amount>
      </AmountCell>

      <NutrientsListWrapper>
        {proportionalNutrients.map((n, idx) => {
          const details = nutrientDict.get(n.id);
          if (n.id === ENERGY_ID)
            return (
              <NutrientWrapper key={idx}>
                <BoldText>
                  {round(n.value, details?.roundPrecision)}
                  {details?.unit}
                </BoldText>
              </NutrientWrapper>
            );
          return (
            <NutrientWrapper key={idx}>
              <Text>{details?.short}:</Text>
              <BoldText>
                {round(n.value, details?.roundPrecision)}
                {details?.unit}
              </BoldText>
            </NutrientWrapper>
          );
        })}
      </NutrientsListWrapper>
    </ProductRowStyled>
  );
};
