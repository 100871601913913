import { round } from "lodash";
import { Typography } from "@mui/material";

import { Nutrient } from "@typeDefinitions";
import { useAppTranslation, useNutrientDictionary } from "@hooks";
import { ENERGY_ID, MACROS_ARRAY, macrosSortKey } from "@utils/macros";

import {
  BoldTypography,
  StyledCompactNutrient,
  StyledNutrient,
  TableRowNutrient,
  TableWrapper,
} from "./NutrientDetails.styled";

interface NutrientLineProps {
  nutrients: Nutrient[];
  compact?: boolean;
  table?: boolean;
}

export const NutrientDetails = ({
  nutrients,
  compact = false,
  table = false,
}: NutrientLineProps) => {
  const { t } = useAppTranslation();
  const { getNutrient } = useNutrientDictionary();
  const macros = nutrients
    .filter(n => MACROS_ARRAY.includes(n.id))
    .sort((a, b) => macrosSortKey(a.id, b.id));

  if (table)
    return (
      <TableWrapper>
        {macros.map(n => {
          const nutrientDetails = getNutrient(n.id);
          const isEnergy = n.id === ENERGY_ID;

          return (
            <TableRowNutrient key={n.id}>
              <Typography variant="body2">
                {isEnergy ? t("common.energetic_cut") : nutrientDetails?.name}:
              </Typography>
              <BoldTypography variant="subtitle2">
                {round(n.value)} {nutrientDetails?.unit}
              </BoldTypography>
            </TableRowNutrient>
          );
        })}
      </TableWrapper>
    );
  return (
    <div className="flex gap-4">
      {macros.map(n => {
        const nutrientDetails = getNutrient(n.id);
        const isEnergy = n.id === ENERGY_ID;

        if (compact)
          return (
            <StyledCompactNutrient key={n.id} wide={isEnergy}>
              <Typography variant="body2">
                {isEnergy ? t("common.energetic_cut") : nutrientDetails?.short}:
              </Typography>
              <BoldTypography variant="subtitle2">
                {round(n.value)} {nutrientDetails?.unit}
              </BoldTypography>
            </StyledCompactNutrient>
          );
        else
          return (
            <StyledNutrient key={n.id} wide={isEnergy}>
              <Typography variant="body2">
                {isEnergy ? "Energetyczność" : nutrientDetails?.name}:
              </Typography>
              <BoldTypography variant="subtitle2">
                {round(n.value)} {nutrientDetails?.unit}
              </BoldTypography>
            </StyledNutrient>
          );
      })}
    </div>
  );
};
