import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const deleteProgramDietProductRemoved = async (
  programId: string,
  dietId: string,
  productId: string,
) => {
  return await fetchData(
    `/dietitian/program/${programId}/diet-creator/${dietId}/product-removed`,
    APIMethods.DELETE,
    { productId },
  );
};
