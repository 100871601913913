import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";
import { ImportanceType } from "./optimizeProgramSchedule";

export async function saveOptimizerSettings(
  programId: number | string,
  payload: SaveOptimizerSettingsRequestBody,
): Promise<any> {
  return fetchData(
    `/dietitian/programs/${programId}/schedule/settings`,
    APIMethods.PUT,
    payload,
  );
}

export interface SaveOptimizerSettingsRequestBody {
  settings: {
    programDay: {
      id: number;
    };
    nutrients: {
      nutrient: {
        id: number | string;
      };
      value: number;
      distribution: number;
      tolerance: number;
    }[];
    balances: {
      type: { id: ImportanceType };
      value: number;
    }[];
  }[];
}
