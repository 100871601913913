import { useFormContext } from "react-hook-form";

import { useAppTranslation, useUserRoles } from "@hooks";
import { FormCheckbox } from "@components/FormCheckbox";
import { Reviewed } from "@assets/icons";

import { EditorHeaderReviewedToggleWrapper } from "./EditorHeaderReviewedToggle.styled";

interface EditorHeaderReviewedToggleProps {
  name: string;
  text?: string;
}

export const EditorHeaderReviewedToggle = ({
  text,
  name,
}: EditorHeaderReviewedToggleProps) => {
  const { t } = useAppTranslation();
  const { control } = useFormContext();
  const { isAdminClinicMember } = useUserRoles();

  if (isAdminClinicMember)
    return (
      <FormCheckbox
        name={name}
        control={control}
        label={
          <EditorHeaderReviewedToggleWrapper>
            {text ?? t("common.reviewed")}
            <Reviewed size={"w-5 h-5"} />
          </EditorHeaderReviewedToggleWrapper>
        }
      />
    );
  else return <></>;
};
