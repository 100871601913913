import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useAppTranslation } from "@hooks";
import { CustomTag } from "@client/customTags/fetchCustomTags";
import { useEffect, useMemo } from "react";
import { SyncCustomTagRequest } from "@client/customTags/syncCustomTags";

export const useCustomTagsManageForm = (values: CustomTagManageFormInput) => {
  const { t } = useAppTranslation();

  const formSchema = yup.object().shape({
    tags: yup.array().of(
      yup.object().shape({
        name: yup.string().required(t("common.required_field")),
        color: yup.string().required(t("common.required_field")),
      }),
    ),
  });

  const form = useForm<CustomTagManageFormInput>({
    defaultValues: values,
    resolver: yupResolver(formSchema),
    shouldFocusError: false,
  });

  useEffect(() => {
    form.reset(values);
  }, [values]);

  return form;
};

export interface CustomTagManageFormItemInput {
  tagId: number | null;
  name: string;
  color: string;
}

export interface CustomTagManageFormInput {
  tags: CustomTagManageFormItemInput[];
}

export const mapTagsToCustomTagManageForm = (
  tags?: CustomTag[],
): CustomTagManageFormInput => ({
  tags:
    tags?.map((tag: CustomTag) => ({
      tagId: tag.id,
      name: tag.name,
      color: tag.color,
    })) ?? [],
});

export const mapCustomTagManageFormToRequest = (
  values: CustomTagManageFormInput,
): SyncCustomTagRequest => ({
  tags: values.tags.map((tag: CustomTagManageFormItemInput) => ({
    id: tag.tagId,
    name: tag.name,
    color: tag.color,
  })),
});
