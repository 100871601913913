import { Switch, SwitchProps } from "@mui/material";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

interface FormSwitchMuiProps<T extends FieldValues> extends SwitchProps {
  name: Path<T>;
  control: Control<T>;
}

export const FormSwitchMui = <T extends FieldValues>({
  name,
  control,
  ...rest
}: FormSwitchMuiProps<T>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, onBlur, ref } }) => (
        <Switch
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          checked={value}
          {...rest}
        />
      )}
    />
  );
};
