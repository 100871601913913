import { IconButton, styled } from "@mui/material";

interface StyledIconButtonProps {
  squareBorder?: boolean;
}

export const StyledIconButton = styled(IconButton, {
  shouldForwardProp: propName => propName !== "squareBorder",
})<StyledIconButtonProps>`
  width: 3.25rem;
  height: 3.25rem;
  border-radius: ${squareBorder => squareBorder && `0.375rem`};
`;

export const OnlyIconButton = styled("div")`
  width: 3.25rem;
  height: 3.25rem;
  border-radius: 0.375rem;
`;
