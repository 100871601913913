import { useTagsUtils } from "@hooks/useTagsUtils";
import { ReactNode, useMemo } from "react";
import { AccordionDetails, Chip, Stack } from "@mui/material";
import {
  ArrowMonitoring,
  CheckedMonitoring,
  CommentMonitoring,
  CookMonitoring,
  LikeMonitoring,
  SymptomsMonitoring,
  UncheckedMonitoring,
} from "@assets/icons/Monitoring";
import {
  Comment,
  MealName,
} from "@views/dietician/PatientMonitoring2/components/MealsBox/Client/MealItem.styled";
import { useAppTranslation } from "@hooks";
import {
  CustomAccordion,
  CustomAccordionSummary,
  CustomChip,
  Label,
} from "@views/dietician/PatientMonitoring2/components/MealsBox/Drawer/RecipeMealItem.styled";
import {
  ItemRecipe,
  PatientMealsMonitoringResource,
} from "@client/resources/PatientMealsMonitoringResource";

type RecipeMealItemProps = {
  day: PatientMealsMonitoringResource;
  meal: PatientMealsMonitoringResource["meals"][0];
  item: ItemRecipe;
};

const RecipeMealItem = ({ day, meal, item }: RecipeMealItemProps) => {
  const { getTagById } = useTagsUtils();
  const { t } = useAppTranslation();

  const tags = useMemo(() => {
    return meal.tags.map(tag => (
      <Chip key={tag.id} color="primary" label={getTagById(tag.id)?.namePl} />
    ));
  }, [meal, getTagById]);

  return (
    <CustomAccordion>
      <CustomAccordionSummary
        sx={{ padding: 0 }}
        expandIcon={
          <ArrowMonitoring
            style={{
              color: "#727272",
            }}
          />
        }
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          flex="1"
          marginRight="8px"
        >
          <Stack direction="row" alignItems="center" spacing="4px">
            {item.eaten ? (
              <CheckedMonitoring fill="#3AB795" size="" />
            ) : (
              <UncheckedMonitoring fill="#F7F7F7" size="" />
            )}
            <MealName
              style={{ textDecoration: !item.eaten ? "line-through" : "" }}
            >
              {item.item.recipe.name}
            </MealName>
          </Stack>
          <Stack direction="row" alignItems="center" spacing="6px">
            {meal.comment && (
              <CustomChip
                color="primary"
                label={<CommentMonitoring fill="#5252E5" size="" />}
              />
            )}
            {meal.tags.length > 0 && (
              <CustomChip
                color="primary"
                label={<SymptomsMonitoring fill="#5252E5" size="" />}
              />
            )}
          </Stack>
        </Stack>
      </CustomAccordionSummary>
      <AccordionDetails>
        <Stack direction="column" spacing="12px">
          <Section label={"Szczegóły"}>
            <Stack direction="row" spacing="4px" alignItems="center">
              <CookMonitoring fill="#727272" size="" />
              <span>
                {meal.name} ({day.date})
              </span>
            </Stack>
          </Section>
          {item.rating !== null && meal.tags.length > 0 && (
            <Section label={"Ocena i objawy"}>
              <Stack direction="row" spacing="8px">
                {item.rating !== null && (
                  <Chip
                    label={
                      <LikeMonitoring
                        fill="#7448D0"
                        size=""
                        style={{
                          transform: item.rating ? "" : "rotate(180deg)",
                        }}
                      />
                    }
                  />
                )}
                {tags}
              </Stack>
            </Section>
          )}
          {meal.comment && (
            <Section
              label={t("monitoring2.meals_box.meals_list_drawer.comment")}
            >
              <Comment>{meal.comment}</Comment>
            </Section>
          )}
        </Stack>
      </AccordionDetails>
    </CustomAccordion>
  );
};

type SectionProps = {
  label: string;
  children: ReactNode;
};
const Section = ({ label, children }: SectionProps) => {
  return (
    <Stack direction="column" spacing="4px">
      <Label>{label}</Label>
      {children}
    </Stack>
  );
};

export default RecipeMealItem;
