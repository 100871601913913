import { useMemo } from "react";

import { CircularProgress } from "@mui/material";

import { GroupIf, TableOfContents } from "@components/TableOfContents";
import { TableOfContentsContextProvider } from "@context/TableOfContentsContext";
import { useAppParams } from "@hooks";
import { useFetchPatientSurveyQuery } from "@hooks/queries/surveys";

import { Close } from "@assets/icons";
import { ThemeProviderWrapperNew } from "themeNew";
import {
  ContentWrapper,
  DialogContentStyled,
  DialogStyled,
  DialogTitleStyled,
  IconButtonStyled,
  TableOfContentsWrapper,
} from "./PatientSurveyModal.styled";
import { SubgroupContent } from "./SubgroupContent";

interface PatientSurveyModalProps {
  open: boolean;
  onClose: () => void;
  surveyId?: string;
}

export const PatientSurveyModal = ({
  open,
  onClose,
  surveyId,
}: PatientSurveyModalProps) => {
  const { patientId } = useAppParams();
  const { data, isLoading, isSuccess } = useFetchPatientSurveyQuery(
    patientId,
    surveyId ?? "",
    { enabled: !!surveyId },
  );

  const groups: GroupIf[] = useMemo(
    () =>
      data?.survey.groups.map(g => ({
        id: g.id,
        name: g.title,
        subgroups: g.sections.map(s => ({ id: s.id, name: s.title })),
      })) ?? [],
    [data?.survey.groups],
  );

  const firstGroupId = groups?.[0]?.id;
  const firstSubgroupId = groups?.[0]?.subgroups[0]?.id;

  if (isSuccess)
    return (
      <ThemeProviderWrapperNew>
        <DialogStyled open={open} onClose={onClose}>
          <DialogTitleStyled>{data?.survey.name}</DialogTitleStyled>

          <IconButtonStyled onClick={onClose} size="small">
            <Close size="w-3 h-3" />
          </IconButtonStyled>

          <DialogContentStyled>
            <TableOfContentsContextProvider
              defaultGroupId={firstGroupId}
              defaultSubgroupId={firstSubgroupId}
            >
              <ContentWrapper>
                <TableOfContentsWrapper>
                  <TableOfContents groups={groups} />
                </TableOfContentsWrapper>

                <SubgroupContent surveyId={surveyId ?? ""} />
              </ContentWrapper>
            </TableOfContentsContextProvider>
          </DialogContentStyled>
        </DialogStyled>
      </ThemeProviderWrapperNew>
    );

  return <></>;
};
