import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const FatNotebook = (props: SvgIconProps) => (
  <SvgIcon {...props} fill={props.fill} viewBox="0 0 26 26">
    <path
      fill={props.fill ? props.fill : "currentColor"}
      id="Path_1792"
      data-name="Path 1792"
      d="M15.495,35A4.11,4.11,0,0,1,11.4,30.908v-13.8a4.11,4.11,0,0,1,4.093-4.093H28.99a4.091,4.091,0,0,1,4.041,3.64l.039.355h2.739A1.49,1.49,0,0,1,35.972,17a.606.606,0,0,1,.436.185.647.647,0,0,1,.193.457.619.619,0,0,1-.186.445L33.581,20.92l2.833,2.832a.636.636,0,0,1,0,.9.662.662,0,0,1-.452.187H33.057v6.073A4.111,4.111,0,0,1,28.962,35H15.495Zm0-5.521a2.839,2.839,0,0,0-2.447,1.405.681.681,0,0,1-.168.188l-.193.153.047.241a2.822,2.822,0,0,0,2.76,2.264H28.963a2.826,2.826,0,0,0,2.824-2.822V24.836H30.393a.809.809,0,0,1-.23-.042l-.525-.174v4.247a.64.64,0,0,1-.639.639.669.669,0,0,1-.257-.05l-.1-.041-.171.029a.821.821,0,0,1-.209.036Zm0-15.209a2.835,2.835,0,0,0-2.783,2.823V29.242l.616-.393a4.11,4.11,0,0,1,2.209-.648H28.359V15.141a.676.676,0,0,1,.073-.289l.3-.581H15.489Zm14.149,9.556.547-.218a.617.617,0,0,1,.212-.043h4.068l-2.192-2.191a.638.638,0,0,1-.184-.45.612.612,0,0,1,.18-.446l2.141-2.2H30.393a.574.574,0,0,1-.209-.043l-.546-.217Zm0-8.884a.548.548,0,0,1,0,.128l0,2.189.549-.221A.579.579,0,0,1,30.4,17h1.424l-.095-.478a2.818,2.818,0,0,0-1.509-1.984l-.672-.338Z"
      transform="translate(-9.598 -10.98)"
    />
  </SvgIcon>
);
