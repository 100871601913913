import { LoadingButton } from "@mui/lab";
import { IconButton, styled } from "@mui/material";

export const TitleStyled = styled("span")`
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;
export const SubTitleStyled = styled("span")`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
`;

export const IconButtonStyled = styled(IconButton)`
  padding: 8px;
`;
