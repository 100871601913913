import { useContext } from "react";

import { Typography, capitalize, useMediaQuery, useTheme } from "@mui/material";
import dayjs from "dayjs";

import { CheckRounded } from "@assets/icons";
import { useVisibleNutrients } from "@context/VisibleNutrientsContext";
import { Nutrient } from "@typeDefinitions";
import { getTranslatedName } from "@utils";

import {
  DayDetailsWrapper,
  DayTitle,
  DividerStyled,
  DetailsWrapperTablet,
  MicronutrientsWrapper,
} from "./MonitoringDayDetails.styled";
import { MicronutrientDetails } from "./MicronutrientsDetails";
import { DayTypeSection } from "./DayTypeSection";
import { LangDto } from "@client";
import { useAppTranslation } from "@hooks";
import { MonitoringDayCharts } from "./MonitoringDayCharts";
import { MonitoringDayKcalSection } from "./MonitoringDayKcalSection";
import { MonitoringContext } from "../context/MonitoringContext";

export const MonitoringDayDetails = () => {
  const { isPolishChosen, t } = useAppTranslation();
  const { breakpoints } = useTheme();
  const downMedium = useMediaQuery(`${breakpoints.down("medium")}`);
  const { nutrients: visibleNutrients } = useVisibleNutrients();
  const { selectedDayDetails } = useContext(MonitoringContext);
  const nutrients = selectedDayDetails?.foodDiary?.nutrients ?? [];
  const dayTargetNutrients = selectedDayDetails?.target?.nutrients ?? [];

  const getNutrientValue = (
    array: Nutrient[] | undefined,
    nutrientId: number,
  ) => array?.find(n => n.id === nutrientId)?.value ?? null;

  const getNutrientTarget = (nutrientId: number) =>
    getNutrientValue(selectedDayDetails?.target?.nutrients, nutrientId);

  const getNutrientPercentage = (nutrientId: number) => {
    const value = getNutrientValue(nutrients, nutrientId);
    const targetValue = getNutrientTarget(nutrientId);

    return value && targetValue ? (value / targetValue) * 100 : null;
  };

  const diaryDayName = isPolishChosen
    ? getTranslatedName(
        selectedDayDetails?.foodDiary?.programDay?.translations ?? [],
        LangDto.PL,
      )
    : getTranslatedName(
        selectedDayDetails?.foodDiary?.programDay?.translations ?? [],
        LangDto.EN,
      );

  const targetDayType = isPolishChosen
    ? getTranslatedName(
        selectedDayDetails?.target?.programDay?.translations ?? [],
        LangDto.PL,
      )
    : getTranslatedName(
        selectedDayDetails?.target?.programDay?.translations ?? [],
        LangDto.EN,
      );

  return (
    <DayDetailsWrapper>
      <div className="flex gap-1">
        <DayTitle variant="h5">
          {selectedDayDetails?.date
            ? dayjs(selectedDayDetails?.date).format("LL")
            : "-"}
        </DayTitle>
        {selectedDayDetails?.foodDiary?.isReported && <CheckRounded />}
      </div>
      <Typography variant="caption" color="text.secondary">
        {selectedDayDetails?.date
          ? capitalize(dayjs(selectedDayDetails?.date).format("dddd"))
          : "-"}
      </Typography>

      <DividerStyled />

      <DetailsWrapperTablet>
        <MonitoringDayKcalSection
          getNutrientTarget={getNutrientTarget}
          getNutrientValue={getNutrientValue}
          nutrients={nutrients}
        />

        {!downMedium && <DividerStyled />}

        <MonitoringDayCharts
          getNutrientPercentage={getNutrientPercentage}
          getNutrientTarget={getNutrientTarget}
          getNutrientValue={getNutrientValue}
          nutrients={nutrients}
          targetNutrients={dayTargetNutrients}
        />
      </DetailsWrapperTablet>

      <DividerStyled />

      <MicronutrientsWrapper>
        <Typography variant="caption" color="text.secondary" textAlign="center">
          {t("monitoring.micronutrients")}
        </Typography>
        {visibleNutrients.map(nutrient => (
          <MicronutrientDetails
            key={nutrient}
            nutrientId={Number(nutrient)}
            targetValue={getNutrientTarget(Number(nutrient))}
            value={getNutrientValue(nutrients, Number(nutrient))}
          />
        ))}
      </MicronutrientsWrapper>

      <DividerStyled />
      <Typography variant="caption" color="text.secondary" textAlign="center">
        {t("monitoring.day_type")}
      </Typography>
      <DayTypeSection
        diaryDayName={diaryDayName}
        targetDayType={targetDayType}
      />
    </DayDetailsWrapper>
  );
};
