import { Typography } from "@mui/material";
import { round } from "lodash";
import {
  MacrosValue,
  ScheduleSummaryNutrientWrapper,
} from "./ScheduleSummary.styled";

interface ScheduleSummaryNutrientProps {
  name: string;
  value: number;
  target: number;
  unit: string;
}

export const ScheduleSummaryNutrient = ({
  name,
  unit,
  value,
  target,
}: ScheduleSummaryNutrientProps) => {
  return (
    <ScheduleSummaryNutrientWrapper>
      <Typography color="grey">{name}</Typography>
      <Typography>
        <MacrosValue>{round(value)}</MacrosValue>/{round(target)} {unit}
      </Typography>
    </ScheduleSummaryNutrientWrapper>
  );
};
