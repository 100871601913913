import { useContext } from "react";

import { round } from "lodash";

import { useMeasures } from "@hooks";

import { ProductIf } from "../NutrientsTab/ProductsTable";
import { ServingContext } from "../ServingsContext";
import {
  Dot,
  NameText,
  ShoppingListElementStyled,
  ValueText,
} from "./PreparationTab.styled";

export const ShoppingListElement = ({
  name,
  grams,
  measureId,
  measures,
}: ProductIf) => {
  const { selectedServings, totalServings } = useContext(ServingContext);
  const proportionalGrams = (grams * selectedServings) / totalServings;
  const { getMeasure } = useMeasures();
  const measurementGrams = measures.find(m => m.id === measureId)?.grams || 1;
  const measurementName = getMeasure(measureId)?.name;
  const measurementValue = proportionalGrams / measurementGrams;

  return (
    <ShoppingListElementStyled>
      <Dot />
      <NameText>
        {name} ({round(measurementValue, 2)} {measurementName}) -{" "}
      </NameText>
      <ValueText>{round(proportionalGrams)}g</ValueText>
    </ShoppingListElementStyled>
  );
};
