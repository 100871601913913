import { Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { StyledChip } from "./FoodAndAllergensSection.styled";
import { useEffect, useRef, useState } from "react";

interface ChipWithTooltipProps {
  label: string | undefined;
  color:
    | "success"
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "warning";
  className?: string;
}
export const ChipWithTooltip = ({
  label,
  color,
  className,
}: ChipWithTooltipProps) => {
  const chipRef = useRef<HTMLDivElement | null>(null);
  const [isTooltipEnabled, setIsTooltipEnabled] = useState(false);
  const { breakpoints } = useTheme();
  const isUpMedium = useMediaQuery(`${breakpoints.up("mediumMax")}`);

  const LAPTOP_CHIP_WIDTH = 412;
  const MOBILE_CHIP_WIDTH = 265;

  useEffect(() => {
    if (chipRef.current) {
      const chipWidth = chipRef.current.offsetWidth;
      setIsTooltipEnabled(
        isUpMedium
          ? chipWidth >= LAPTOP_CHIP_WIDTH
          : chipWidth >= MOBILE_CHIP_WIDTH,
      );
    }
  }, [label, isUpMedium]);

  return (
    <Tooltip title={label} disableHoverListener={!isTooltipEnabled}>
      <div>
        <StyledChip
          ref={chipRef}
          className={className}
          label={label}
          color={color}
        />
      </div>
    </Tooltip>
  );
};
