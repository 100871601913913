import { MultiSelectFilter } from "@components/Filters/MultiSelectFilter";
import { useMemo } from "react";
import { useAppTranslation } from "@hooks";

const ProgramDaysField = () => {
  const { t } = useAppTranslation();
  const mappedOptions = useMemo(options, []);

  return (
    <MultiSelectFilter
      label={t("programs.day_types_number")}
      options={mappedOptions}
      name="typeDays"
    />
  );
};

const options = () =>
  [1, 2, 3, 4, 5, 6, 7, 8].map(day => ({
    id: day.toString(),
    label: day === 8 ? ">7" : day.toString(),
  }));

export default ProgramDaysField;
