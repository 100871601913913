import { useEffect, useState } from "react";

import { useAppTranslation } from "@hooks";
import { Textarea } from "@components/Textarea";

import { WysiwygEditorWrapper2 } from "./WysiwygEditorWrapper.styled";
import { QuillEditor } from "@components/QuillEditor";

interface VisitTextContentProps {
  mutate?: (newValue: string) => void;
  value: string;
  useWysiwyg?: boolean;
  long?: boolean;
}

export const TextContent = ({
  mutate,
  value,
  useWysiwyg,
  long,
}: VisitTextContentProps) => {
  const { t } = useAppTranslation();
  const [localValue, setValue] = useState(value || "");

  useEffect(() => {
    setValue(value);
  }, [value]);

  const handleValueChange = (value: string) => {
    setValue(value);
  };

  const handleTextValueChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  const handleBlur = () => {
    if (localValue !== value) mutate && mutate(localValue);
  };

  return useWysiwyg ? (
    <WysiwygEditorWrapper2>
      <QuillEditor
        defaultValue={value}
        placeholder={t("common.typing_prompt")}
        onChange={handleValueChange}
        onBlur={handleBlur}
      />
    </WysiwygEditorWrapper2>
  ) : (
    <Textarea
      clean
      value={localValue}
      className={long ? "min-h-60" : "min-h-40"}
      minHeight={long ? "min-h-60" : "min-h-40"}
      width="full"
      height="full"
      placeholder={t("common.typing_prompt")}
      onChange={handleTextValueChange}
      onBlur={handleBlur}
    />
  );
};
