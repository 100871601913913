import { Button, Typography } from "@mui/material";

import image from "@assets/img/error503.png";
import { useAppTranslation } from "@hooks";

import { ThemeProviderWrapperNew } from "themeNew";
import {
  ContentWrapper,
  Error503Styled,
  ImageWrapper,
  TextWrapper,
} from "./Error503.styled";
import {
  EmptyStateCard,
  ErrorPagesWrapper,
  StyledSubTitle,
  StyledTitle,
} from "../common.styled";

export const Error503 = () => {
  const { t } = useAppTranslation();
  const handleRefresh = () => location.reload();

  return (
    <ThemeProviderWrapperNew>
      <ErrorPagesWrapper>
        <EmptyStateCard>
          <Error503Styled>
            <ImageWrapper>
              <img src={image} alt={t("empty_states.alt.error_503")} />

              <ContentWrapper>
                <TextWrapper>
                  <Typography
                    color="secondary.main"
                    fontWeight={600}
                    lineHeight="1.25rem"
                    fontSize="0.875rem"
                  >
                    {t("empty_states.oops")}
                  </Typography>

                  <StyledTitle>
                    {t("empty_states.service_unavailable")}
                  </StyledTitle>
                </TextWrapper>

                <StyledSubTitle maxWidth="40.375rem">
                  {t("empty_states.service_unavailable_info")}
                </StyledSubTitle>
              </ContentWrapper>
            </ImageWrapper>

            <Button variant="contained" onClick={handleRefresh}>
              {t("common.refresh")}
            </Button>
          </Error503Styled>
        </EmptyStateCard>
      </ErrorPagesWrapper>
    </ThemeProviderWrapperNew>
  );
};
