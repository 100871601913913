import { styled } from "@mui/material";
import FormLabelMui from "@mui/material/FormLabel";

const FormLabel = styled(FormLabelMui)`
  &.MuiFormLabel-root {
    font: var(--font-figtree-medium);
    line-height: 20px;
    font-size: 12px;
    color: #4d516e;
  }
` as typeof FormLabelMui;

export default FormLabel;
