import { To } from "react-router-dom";

import { Breadcrumbs as MUIBreadcrumbs } from "@mui/material";

import { BreadcrumbStep } from "@typeDefinitions/types";
import { StyledLink, StyledTypography } from "./BreadcrumbsPath.styled";

interface IconBreadcrumbsProps {
  breadcrumbs: BreadcrumbStep[];
}

export const BreadcrumbsPath = ({ breadcrumbs }: IconBreadcrumbsProps) => {
  return (
    <div>
      <MUIBreadcrumbs aria-label="breadcrumb">
        {breadcrumbs.map((breadcrumb, i) => {
          const last = i === breadcrumbs.length - 1;
          const { icon, backTo, title } = breadcrumb;
          return last ? (
            <StyledTypography key={i} color="primary">
              {title}
            </StyledTypography>
          ) : (
            <StyledLink key={i} to={backTo as To} color="primary">
              {icon}
              <span>{title}</span>
            </StyledLink>
          );
        })}
      </MUIBreadcrumbs>
    </div>
  );
};
