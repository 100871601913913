import { useAppParams } from "@hooks";
import { useProgramDayNutrientsMutation } from "@hooks/queries";
import {
  useUpdateDietNutrientsNormMutation as useUpdateDietCreatorNutrientsNormMutation,
  UseUpdateDietNutrientsNormMutationPayload,
} from "@hooks/queries/diets/creator";
import { UseMutateFunction } from "@tanstack/react-query";

export const useUpdateDietNutrientsNormCombinedMutation = () => {
  const { programId, dayId } = useAppParams();
  const isInProgram = !!programId && !!dayId;

  const creatorMutation = useUpdateDietCreatorNutrientsNormMutation();
  const programMutation = useProgramDayNutrientsMutation();

  const handleMutate: UseMutateFunction<
    unknown,
    unknown,
    UseUpdateDietNutrientsNormMutationPayload,
    unknown
  > = (variables, options) =>
    isInProgram
      ? programMutation.mutate(
          {
            programId,
            dayId,
            payload: {
              normId: variables.payload.normId.toString(),
              nutrients: variables.payload.nutrients.map(
                ({ nutrientId, show, value }) => ({
                  id: nutrientId,
                  value,
                  visible: show,
                }),
              ),
            },
          },
          {
            onSuccess: (d, v, c) => options?.onSuccess?.(d, variables, c),
            onError: (e, v, c) => options?.onError?.(e, variables, c),
          },
        )
      : creatorMutation.mutate(variables, options);

  return {
    ...(isInProgram ? programMutation : creatorMutation),
    mutate: handleMutate,
  };
};
