import { Chip, styled } from "@mui/material";

export const InputComponentWrapper = styled("div")`
  display: inline-flex;
  align-items: center;
  flex: 1;
  padding: 6px 0 6px 0;
  gap: 4px;
  flex-wrap: wrap;
`;

export const InputStyled = styled("input")`
  padding: 0;
  height: 40px;
  flex: 1 1 0%;
  outline: none;
  text-overflow: ellipsis;
`;

export const StyledChip = styled(Chip)`
  &.MuiChip-colorWarning {
    background: ${({ theme }) => theme.colors.orange.light};
    color: ${({ theme }) => theme.colors.orange.dark};
  }
`;
