import { MouseEvent, ReactNode, useEffect, useState } from "react";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInputProps,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

import { OutlinedInputStyled } from "./MultiLanguageInput.styled";

interface MultiLanguageInputProps
  extends Omit<OutlinedInputProps, "onChange" | "endAdornment" | "value"> {
  languages: string[];
  onChange: (lang: string, value?: string) => void;
  value: (lang: string) => string;
  label: ReactNode;
  errorMessage?: string;
  errorFields?: string[];
}

export const MultiLanguageInput = (props: MultiLanguageInputProps) => {
  const [language, setLanguage] = useState(props.languages[0]);
  const handleChangeLanguage = (e: MouseEvent<HTMLElement>, val: string) => {
    if (val) setLanguage(val);
  };

  useEffect(() => {
    const { errorFields } = props;
    if (errorFields?.length) setLanguage(errorFields[0]);
  }, [props.errorFields]);

  return (
    <FormControl variant="outlined" className={props.className}>
      <InputLabel error={props.error} shrink>
        {props.label}
      </InputLabel>
      <OutlinedInputStyled
        {...props}
        value={props.value(language)}
        onChange={event => props.onChange(language, event.target.value)}
        notched
        endAdornment={
          <ToggleButtonGroup
            value={language}
            exclusive
            onChange={handleChangeLanguage}
          >
            {props.languages?.map((lang, id) => (
              <ToggleButton key={id} value={lang}>
                {lang.toUpperCase()}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        }
      />
      {props.error && (
        <FormHelperText error>{props.errorMessage}</FormHelperText>
      )}
    </FormControl>
  );
};
