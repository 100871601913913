import { useMemo } from "react";

import dayjs from "dayjs";

import {
  VisitMeasurements,
  Visits,
  VisitSegment,
  VisitSegmentType,
  VisitTagGroupIds,
} from "@typeDefinitions";
import { useAppTranslation } from "@hooks";
import {
  useFetchVisitBoxConfigQuery,
  useFetchVisitNoteQuery,
  useUpdatePatientNoteMutation,
  useUpdateVisitBoxValueMutation,
} from "@hooks/queries/visits";

const parseVisitMeasurements = (
  measurements: any,
  visitId: number,
): VisitMeasurements => ({
  date: measurements.dateOfLastTest || "",
  weight: (measurements.body.weight as string) || "",
  bodyFatContent: (measurements.body.fat as string) || "",
  idealBodyWeight: (measurements.body.targetWeight as string) || "",
  visitId,
});

export const isSegmentNote = (id: number) => id === 7;

export const usePatientVisitDetails = (
  visitDetails: Visits,
  patientId: number,
  visitOrder: number,
  patientVisits: Visits[],
) => {
  const { t, isPolishChosen } = useAppTranslation();

  const { boxConfig } = useFetchVisitBoxConfigQuery({ refetchOnMount: false });
  const { note } = useFetchVisitNoteQuery(patientId);

  const { files, tags, boxes, food, foodGroups } = visitDetails;

  const { mutate: addPatientNote } = useUpdatePatientNoteMutation(patientId);
  const { mutate: saveVisitBoxData } = useUpdateVisitBoxValueMutation(
    patientId,
    visitDetails.id,
  );

  const handlePatientNoteChange = (note: string) => {
    addPatientNote({ note });
  };
  const handleBoxValueUpdate = (boxId: number) => (value: string) => {
    saveVisitBoxData({ value, boxId });
  };

  const filterAndParseVisitTags = (categoryId: number) =>
    tags.filter(tag => tag.tagCategoryId === categoryId).map(tag => tag.id);

  const filterAndParseVisitFood = (isFavourite: boolean) =>
    food
      .filter(food => food.isFavourite === isFavourite)
      .map(({ food }) => ({
        id: food.id,
        label: (isPolishChosen ? food.name : food.nameEn) || "",
      }));

  const filterAndParseVisitFoodGroup = (isFavourite: boolean) =>
    foodGroups
      .filter(foodGroup => foodGroup.isFavourite === isFavourite)
      .map(({ foodGroup }) => ({
        id: foodGroup.id,
        label:
          (isPolishChosen ? foodGroup.name : foodGroup.nameEn) ||
          foodGroup.nameEn ||
          "",
      }));

  const SEGMENTS_LEFT = useMemo(
    () => [
      // {
      //   name: "measurements",
      //   type: "measurements" as VisitSegmentType,
      //   value: parseVisitMeasurements(
      //     visitDetails.generalData.measurement,
      //     visitDetails.id,
      //   ),
      // } as VisitSegment<"measurements">,
      {
        name: "note",
        type: "text" as VisitSegmentType,
        value: note,
        mutate: handlePatientNoteChange,
      },
      {
        name: "diseases",
        type: "disease" as VisitSegmentType,
        value: filterAndParseVisitTags(VisitTagGroupIds.DISEASE),
      },
      // {
      //   name: "allergies",
      //   type: "allergy" as VisitSegmentType,
      //   value: filterAndParseVisitTags(VisitTagGroupIds.ALLERGIES),
      // },
      {
        name: "digestive",
        type: "digestive" as VisitSegmentType,
        value: filterAndParseVisitTags(VisitTagGroupIds.DIGESTIVE),
      },
      // {
      //   name: "favoriteProducts",
      //   type: "favoriteProducts" as VisitSegmentType,
      //   value: filterAndParseVisitFood(true),
      //   group: filterAndParseVisitFoodGroup(true),
      // },
      // {
      //   name: "dislikedProducts",
      //   type: "dislikedProducts" as VisitSegmentType,
      //   value: filterAndParseVisitFood(false),
      //   group: filterAndParseVisitFoodGroup(false),
      // },
      {
        name: "supplements",
        type: "supplements" as VisitSegmentType,
        value: filterAndParseVisitTags(VisitTagGroupIds.SUPPLEMENTS),
      },
      {
        name: "meds",
        type: "meds" as VisitSegmentType,
        value: filterAndParseVisitTags(VisitTagGroupIds.MEDS),
      },
    ],
    [note, tags, visitDetails],
  );

  const parsedBoxConfigData = useMemo(
    () =>
      (boxConfig?.data || [])
        .map((segment, order) => ({
          ...segment,
          order,
          isDraggable: false,
          defaultOpen: !visitOrder,
          type: "textLong" as VisitSegmentType,
          value: boxes.find(({ box }) => box.id === segment.id)?.value,
          files: segment.hasFiles ? files : undefined,
          mutate: handleBoxValueUpdate(segment.id),
        }))
        .sort((a, b) => (a.order > b.order ? 1 : -1)),
    [boxConfig, boxes],
  );

  const segmentsLeft: VisitSegment[] = useMemo(() => {
    const segments: VisitSegment[] = [
      ...SEGMENTS_LEFT.map(({ name, ...segmentData }, order) => ({
        id: order,
        order,
        defaultOpen: !visitOrder,
        isDraggable: false, // !isFirst
        name: t(`patient.visits.${name}`),
        ...segmentData,
      })).sort((a, b) => (a.order > b.order ? 1 : -1)),
    ];

    if (visitOrder)
      segments.push(
        ...parsedBoxConfigData
          .filter(({ id }) => !isSegmentNote(id))
          .map((segment, order) => ({
            ...segment,
            order: segments.length + order,
          })),
      );

    return segments;
  }, [parsedBoxConfigData, SEGMENTS_LEFT]);

  const segmentsRight: VisitSegment[] = useMemo(() => {
    if (visitOrder && boxConfig?.data) {
      const note = parsedBoxConfigData.find(({ id }) => isSegmentNote(id))!;
      const pastNotes = patientVisits
        .slice()
        .reverse()
        .map((visit, index) => {
          return {
            note:
              visit.boxes.find(({ box }) => isSegmentNote(box.id))?.value || "",
            dateOfVisit: dayjs(visit.dateStart).format("DD.MM.YYYY"),
            id: index + 1,
          };
        })
        .filter(({ note }, i) => i < visitOrder && note?.length > 0)
        .reverse();

      return [
        {
          ...note,
          defaultOpen: true,
          order: 1,
        },
        {
          id: -1,
          order: 2,
          name: t("patient.visits.pastNotes"),
          isDraggable: false,
          type: "pastNotes",
          value: pastNotes,
        },
      ];
    }

    return parsedBoxConfigData;
  }, [boxConfig, patientVisits, parsedBoxConfigData]);

  return {
    segmentsLeft,
    segmentsRight,
  };
};
