import { Stack, styled } from "@mui/material";

type MeasurementProps = {
  title: string;
  value: string;
};

const Measurement = ({ title, value }: MeasurementProps) => {
  return (
    <Stack direction="column" gap="4px">
      <Title>{title}</Title>
      <Value>{value}</Value>
    </Stack>
  );
};

const Title = styled("span")`
  font-size: 12px;
  line-height: 20px;
  color: #4d516e;
  font-weight: normal;
`;

const Value = styled("span")`
  color: #333333;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
`;

Measurement.Title = Title;
Measurement.Value = Value;

export default Measurement;
