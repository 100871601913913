import * as yup from "yup";
import { MEETING_TYPE } from "@utils/scheduleEvent";
import { validateFiveMinutesStep } from "./formActions";

type EventSchemaType = "basic" | "visit" | "pricedVisit";

interface EventValue {
  eventType: MEETING_TYPE;
  price?: number;
}

type EventSchema = yup.ObjectSchema<EventValue>;

const createBasicEventSchema = (t: (key: string) => string) =>
  yup.object({
    eventType: yup.number().required(t("common.required_field")),
    patientId: yup.number().nullable(),
    eventName: yup.string().required(t("common.required_field")),
    isAllDay: yup.boolean().required(t("common.required_field")),
    startDate: yup
      .date()
      .typeError(t("common.required_field"))
      .required(t("common.required_field"))
      .test(
        "MINUTES_VALIDATION",
        t("visits.minutes_by_5"),
        validateFiveMinutesStep,
      ),
    finishDate: yup
      .date()
      .typeError(t("common.required_field"))
      .required(t("common.required_field"))
      .test(
        "MINUTES_VALIDATION",
        t("visits.minutes_by_5"),
        validateFiveMinutesStep,
      ),
    dietitianId: yup
      .number()
      .typeError(t("common.required_field"))
      .required(t("common.required_field")),
    clinicFacilityId: yup.number().nullable(),
    clinicProductId: yup.number().nullable(),
    price: yup.number().nullable(),
    currency: yup.string().nullable(),
    additionalInfo: yup.string().nullable(),
  }) as EventSchema;

const createVisitEventSchema = (t: (key: string) => string) =>
  yup.object({
    eventType: yup
      .number()
      .typeError(t("common.required_field"))
      .required(t("common.required_field")),
    patientId: yup
      .number()
      .typeError(t("common.required_field"))
      .required(t("common.required_field")),
    eventName: yup.string().required(t("common.required_field")),
    isAllDay: yup.boolean().required(t("common.required_field")),
    startDate: yup
      .date()
      .typeError(t("common.required_field"))
      .required(t("common.required_field"))
      .test(
        "MINUTES_VALIDATION",
        t("visits.minutes_by_5"),
        validateFiveMinutesStep,
      ),
    finishDate: yup
      .date()
      .typeError(t("common.required_field"))
      .required(t("common.required_field"))
      .test(
        "MINUTES_VALIDATION",
        t("visits.minutes_by_5"),
        validateFiveMinutesStep,
      ),
    dietitianId: yup
      .number()
      .typeError(t("common.required_field"))
      .required(t("common.required_field")),
    clinicFacilityId: yup.number().nullable(),
    clinicProductId: yup
      .number()
      .typeError(t("common.required_field"))
      .required(),
    price: yup.number().nullable(),
    currency: yup.string().nullable(),
    additionalInfo: yup.string().nullable(),
    notification: yup.boolean().nullable(),
  }) as EventSchema;

const createPricedVisitEventSchema = (
  t: (key: string) => string,
): EventSchema =>
  yup.object({
    eventType: yup
      .number()
      .typeError(t("common.required_field"))
      .required(t("common.required_field")),
    patientId: yup
      .number()
      .typeError(t("common.required_field"))
      .required(t("common.required_field")),
    eventName: yup.string().required(t("common.required_field")),
    isAllDay: yup.boolean().required(t("common.required_field")),
    startDate: yup
      .date()
      .typeError(t("common.required_field"))
      .required(t("common.required_field"))
      .test(
        "MINUTES_VALIDATION",
        t("visits.minutes_by_5"),
        validateFiveMinutesStep,
      ),
    finishDate: yup
      .date()
      .typeError(t("common.required_field"))
      .required(t("common.required_field"))
      .test(
        "MINUTES_VALIDATION",
        t("visits.minutes_by_5"),
        validateFiveMinutesStep,
      ),
    dietitianId: yup
      .number()
      .typeError(t("common.required_field"))
      .required(t("common.required_field")),
    clinicFacilityId: yup.number().nullable(),
    clinicProductId: yup
      .number()
      .typeError(t("common.required_field"))
      .required(),
    price: yup.number().typeError(t("common.required_field")),
    currency: yup.string(),
    additionalInfo: yup.string().nullable(),
  });

const getEventSchemaType = (value: EventValue): EventSchemaType => {
  if (value.eventType !== MEETING_TYPE.CONSULTATION) {
    return "basic";
  }
  return value.price ? "pricedVisit" : "visit";
};

const schemaMap: Record<
  EventSchemaType,
  (t: (key: string) => string) => EventSchema
> = {
  basic: createBasicEventSchema,
  visit: createVisitEventSchema,
  pricedVisit: createPricedVisitEventSchema,
};

export const createAddEventSchema = (t: (key: string) => string) =>
  yup.lazy((value: EventValue) => {
    const schemaType = getEventSchemaType(value);
    return schemaMap[schemaType](t);
  });
