import { FormAutocomplete } from "@components/FormAutocomplete";
import { FormCountryAutocomplete } from "@components/FormCountryAutocomplete";
import { FormOutlinedInput } from "@components/FormOutlinedInput";
import { FormTextField } from "@components/FormTextField";
import { Button, styled } from "@mui/material";

export const EditClientModalWrapper = styled("div")`
  display: grid;
  gap: 1.5rem;
  justify-content: center;
  padding: 1.5rem 2rem 0;
  width: 28.125rem;
`;

export const Divider = styled("div")`
  border-top: 1px solid ${({ theme }) => theme.colors.cancelButton};
  width: 18.5rem;
  justify-self: center;
`;

export const SplitRowStyled = styled("div")`
  display: flex;
  align-items: start;
  gap: 1.25rem;
`;

interface Flex {
  flex: number;
}

export const StyledFlexTextField = styled(FormTextField)<Flex>`
  flex: ${({ flex }) => flex};
`;

export const StyledFormOutlinedInput = styled(FormOutlinedInput)<Flex>`
  flex: ${({ flex }) => flex};
`;

export const StyledFormCountryAutocomplete = styled(
  FormCountryAutocomplete,
)<Flex>`
  flex: ${({ flex }) => flex};
`;

export const StyledAutocomplete = styled(FormAutocomplete)`
  & .MuiAutocomplete-popupIndicator {
    transform: none;
  }
`;

export const ActionButtonsWrapper = styled("div")`
  display: flex;
  position: sticky;
  justify-self: center;
  justify-content: center;
  align-items: center;
  bottom: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  gap: 0.5rem;
  z-index: 100;
  background-color: ${({ theme }) => theme.colors.whiteBackground};
`;

export const StyledCancelBtn = styled(Button)`
  background-color: ${({ theme }) => theme.palette.grey[300]};
  color: currentColor;
  text-transform: none;
  font-weight: 600;
  &:hover {
    background-color: ${({ theme }) => theme.colors.cancelButtonHover};
  }
`;

export const StyledSubmitBtn = styled(Button)`
  text-transform: none;
  font-weight: 600;

  &:hover {
    background-color: ${({ theme }) => theme.colors.submitButtonHover};
  }
`;

export const StyledTextArea = styled(FormTextField)`
  padding: unset;

  & legend {
    display: none;
  }

  & label {
    margin-top: 0.5rem;
    position: unset;
    transform: translate(14px, -4px) scale(0.75);
  }
`;

export const StyledLabel = styled("span")`
  display: block;
  position: unset;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  line-height: 1.4375em;
  font-size: 1rem;
  transform: translate(10px, -4px) scale(0.75);
  color: ${({ theme }) => theme.colors.labelGray};
`;

export const SexButtonsWrapper = styled("div")`
  display: grid;
  justify-items: start;
`;
