import {
  useRef,
  useEffect,
  CSSProperties,
  memo,
  useLayoutEffect,
  useCallback,
  useMemo,
  LegacyRef,
} from "react";
import {
  flip,
  offset,
  shift,
  arrow,
  useFloating,
  autoUpdate,
} from "@floating-ui/react-dom";
import classnames from "classnames";
import ReactDOM from "react-dom";
import { PopmenuWrapper } from "./PopMenu.styled";

export interface PopMenuProps {
  className?: string;
  innerContainerClassName?: string;
  /** style applied to popper container */
  style?: CSSProperties;
  /** name prop of anchors element */
  name?: string;
  /** menu contents */
  children?: React.ReactNode;
  /** DOM element acting as pop menu's anchor */
  anchor: React.MutableRefObject<any> | null;
  /** indicates if pop menu should be visible */
  open: boolean;
  /** callback fired on clicking outside of pop menu element */
  onClose?: () => void;
  /** pop menu placement relative to it's anchor */
  placement?:
    | "top"
    | "bottom-start"
    | "bottom"
    | "bottom-end"
    | "left-start"
    | "left"
    | "left-end"
    | "right-start"
    | "right"
    | "right-end";
  /** distance in px between anchor element and pop menu container */
  offset?: number;
  /** key down event handler for pop-menu container */
  onKeyDown?: (e: any) => void;
  /** if set to true, popper's container will match it's anchor width */
  matchWidth?: boolean;
  /** shows arrow pointing to anchor element */
  showArrow?: boolean;
  focusOnOpen?: boolean;
  itemsCenter?: boolean;
  /** shows set overflow-Y in auto */
  isOverflow?: boolean;
}

/**
 * component for displaying button and a drop down menu shown on click
 */
export const PopMenu = memo((props: PopMenuProps) => {
  const {
    anchor,
    children,
    name,
    open,
    onClose,
    placement = "bottom-start",
    offset: offsetValue,
    style,
    className,
    innerContainerClassName,
    onKeyDown,
    matchWidth,
    showArrow = false,
    focusOnOpen = true,
    itemsCenter = true,
    isOverflow = true,
  } = props;

  const arrowRef = useRef<HTMLDivElement | null>(null);
  const openRef = useRef<boolean>(open);

  const {
    x,
    y,
    reference,
    floating,
    strategy,
    placement: finalPlacement,
    middlewareData,
    refs,
    update,
  } = useFloating({
    placement,
    strategy: "absolute",
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(offsetValue || 0),
      flip(),
      shift(),
      arrow({
        element: arrowRef,
        padding: 10,
      }),
    ],
  });

  const arrowCallback = useCallback(
    (node: HTMLDivElement) => {
      arrowRef.current = node;
      update();
    },
    [update],
  );

  useLayoutEffect(() => {
    reference(anchor?.current);
  }, [anchor?.current]);

  useEffect(() => {
    openRef.current = open;
    if (open && refs.floating?.current) {
      const el = refs.floating.current?.querySelector<
        HTMLButtonElement | HTMLLinkElement | HTMLOptionElement
      >("button:not([disabled]), a:not([disabled]), option:not([disabled])");
      if (focusOnOpen) {
        el?.focus();
      }
    }
  }, [open]);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      openRef.current &&
      refs.floating.current &&
      event.target !== anchor?.current &&
      !refs.floating.current.contains(event.target as Node) &&
      !anchor?.current?.contains(event.target as Node)
    ) {
      onClose && onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    // return () => {
    //   document.removeEventListener("mousedown", handleClickOutside);
    // };
  }, [floating]);

  const sameWidth = useMemo(
    () => (matchWidth ? anchor?.current?.offsetWidth : undefined),
    [matchWidth, anchor?.current],
  );

  return ReactDOM.createPortal(
    <>
      {open && (
        <PopmenuWrapper
          isOverflow={isOverflow}
          ref={floating}
          className={classnames("popup", className)}
          style={{
            ...style,
            width: sameWidth,
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
          }}
          role="menu"
          aria-labelledby={name}
          onKeyDown={onKeyDown}
        >
          {showArrow && (
            <div
              ref={arrowCallback}
              className={classnames("popup-arrow", finalPlacement)}
              style={{
                top: !finalPlacement.includes("top")
                  ? middlewareData.arrow?.y ?? 0
                  : undefined,
                bottom: finalPlacement.includes("top")
                  ? middlewareData.arrow?.y ?? 0
                  : undefined,
                left: !finalPlacement.includes("left")
                  ? middlewareData.arrow?.x ?? 0
                  : undefined,
                right: finalPlacement.includes("left")
                  ? middlewareData.arrow?.x ?? 0
                  : undefined,
              }}
            />
          )}

          <div
            className={classnames(
              {
                "flex relative": true,
                "items-center": itemsCenter,
                "flex-col":
                  !innerContainerClassName ||
                  innerContainerClassName.indexOf("flex") < 0,
              },
              innerContainerClassName,
            )}
            role="none"
          >
            {children}
          </div>
        </PopmenuWrapper>
      )}
    </>,
    document.querySelector("body") as Element,
  );
});

// const sameWidth = {
//   name: "sameWidth",
//   enabled: true,
//   phase: "beforeWrite" as ModifierPhases,
//   requires: ["computeStyles"],
//   fn: (data: { state: State }) => {
//     data.state.styles.popper.width = `${data.state.rects.reference.width}px`;
//   },
//   effect: (data: { state: State }) => {
//     data.state.elements.popper.style.width = `${
//       (data.state.elements.reference as HTMLElement).offsetWidth
//     }px`;
//   },
// };
