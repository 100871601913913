import { useMemo } from "react";
import { useAppTranslation } from "@hooks";
import { BodyMeasurementResource } from "@client/dictionaries/fetchBodyMeasurements";
import {
  Layout,
  Value,
  Label,
} from "@components/Client/BodyMeasurementsTabs/BodyMeasurementItem.styled";
import { getTranslation } from "@utils/dictionaries/bodyMeasurementsDictionaryUtils";

type BodyMeasurementItemItemProps = {
  measurement: BodyMeasurementResource;
  value: string | null;
  showUnit?: boolean;
};

const BodyMeasurementItem = ({
  measurement,
  value,
  showUnit = true,
}: BodyMeasurementItemItemProps) => {
  const { currentLanguage } = useAppTranslation();

  const label = useMemo(() => {
    const translation = getTranslation(measurement, currentLanguage);

    if (!translation) {
      return measurement.name;
    }

    return translation.name;
  }, [measurement, currentLanguage]);

  const getValue = () => {
    if (!value) {
      return "-";
    }

    if (!showUnit) {
      return value;
    }

    return `${value} ${measurement.unit}`;
  };

  return (
    <Layout>
      <Label>{label}</Label>
      <Value>{getValue()}</Value>
    </Layout>
  );
};

export default BodyMeasurementItem;
