import { styled } from "@mui/material";

interface StyledAnswerProps {
  irrelevant?: boolean;
}
export const StyledAnswer = styled("span")<StyledAnswerProps>`
  font-size: 1rem;
  line-height: 1.714rem;
  color: ${({ theme, irrelevant }) =>
    irrelevant
      ? theme.colors.neutral.medium[600]
      : theme.colors.neutral.dark[800]};
  font-weight: 500;
`;
