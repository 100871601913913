import { useController } from "react-hook-form";

import { IconButton, useMediaQuery, useTheme } from "@mui/material";

import { PointerArrowRightRounded } from "@assets/icons/Arrows";
import { OuterLabel, StyledLabel } from "@components/OuterLabel";
import { TabSwitch } from "@components/TabSwitch";

import { DifferenceCell } from "./DifferenceCell";
import {
  ButtonWrapper,
  IconWrapper,
  RowMobile,
  RowStyled,
  TabsMobile,
} from "./ExchangeRow.styled";
import { SearchDietProductsAutocomplete } from "./SearchDietProductsAutocomplete";
import { SearchReplacementAutocomplete } from "./SearchReplacementAutocomplete";
import { ExchangeProductFormInput, Unit } from "./useExchangeProductForm";
import { SelectedContextProvider } from "./SelectedContext/SelectedContextProvider";
import { TrashNew } from "@assets/icons";
import { useAppTranslation } from "@hooks";

interface ExchangeRowProps {
  showLabel?: boolean;
  idx: number;
  onDelete: () => void;
  selectedDietProducts: number[];
}

export const ExchangeRow = ({
  showLabel,
  idx,
  onDelete,
  selectedDietProducts,
}: ExchangeRowProps) => {
  const { t } = useAppTranslation();
  const {
    colors: { neutral },
    breakpoints,
  } = useTheme();
  const upMedium = useMediaQuery(`${breakpoints.up("medium")}`);

  const tabs = [
    { id: Unit.G, label: Unit.G },
    { id: Unit.KCAL, label: Unit.KCAL },
  ];

  const {
    field: { value: unit, onChange: onUnitChange },
  } = useController<ExchangeProductFormInput, `products.${number}.unit`>({
    name: `products.${idx}.unit`,
  });
  const {
    field: { onChange: onRatioChange },
  } = useController<ExchangeProductFormInput, `products.${number}.ratio`>({
    name: `products.${idx}.ratio`,
  });

  return (
    <SelectedContextProvider
      unit={unit}
      onRatioChange={onRatioChange}
      selectedDietProducts={selectedDietProducts}
    >
      {upMedium && (
        <RowStyled>
          <OuterLabel label={showLabel && t("diet.exchange_modal.keep")}>
            <TabSwitch value={unit} onChange={onUnitChange} tabs={tabs} />
          </OuterLabel>

          <div className="flex items-start">
            <OuterLabel label={showLabel && t("diet.exchange_modal.selected")}>
              <SearchDietProductsAutocomplete idx={idx} />
            </OuterLabel>

            <IconWrapper labelSpace={showLabel}>
              <PointerArrowRightRounded fill={neutral.dark[700]} />
            </IconWrapper>

            <OuterLabel
              label={showLabel && t("diet.exchange_modal.exchange_to")}
            >
              <SearchReplacementAutocomplete idx={idx} />
            </OuterLabel>
          </div>

          <OuterLabel label={showLabel && t("diet.exchange_modal.difference")}>
            <DifferenceCell />
          </OuterLabel>

          <ButtonWrapper labelSpace={showLabel}>
            <IconButton color="primary" onClick={onDelete}>
              <TrashNew />
            </IconButton>
          </ButtonWrapper>
        </RowStyled>
      )}

      {!upMedium && (
        <RowMobile>
          <div className="flex justify-between">
            <TabsMobile>
              <StyledLabel>{t("diet.exchange_modal.keep")}</StyledLabel>
              <TabSwitch value={unit} onChange={onUnitChange} tabs={tabs} />
            </TabsMobile>

            <IconButton color="primary" onClick={onDelete}>
              <TrashNew />
            </IconButton>
          </div>

          <OuterLabel label={t("diet.exchange_modal.selected")}>
            <SearchDietProductsAutocomplete idx={idx} />
          </OuterLabel>

          <OuterLabel label={t("diet.exchange_modal.exchange_to")}>
            <SearchReplacementAutocomplete idx={idx} />
          </OuterLabel>

          <div className="pt-[8px] w-full">
            <DifferenceCell />
          </div>
        </RowMobile>
      )}
    </SelectedContextProvider>
  );
};
