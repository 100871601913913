import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

import {
  ActionsDto,
  ClinicIdDto,
  LangDto,
  NutrientDto,
  TranslationLangDto,
  actionsSchema,
  nutrientSchema,
} from "./common";
import {
  AuthorDto,
  ProgramDayDto,
  TagExtendedDto,
  authorSchema,
  clinicIdSchema,
  programDaySchema,
  tagExtendedSchema,
  translationSchema,
} from "./program";

export const searchProgramsShowProgram = async (
  programId: string,
): Promise<ApiResponse<SearchProgramsResponse2>> => {
  const data = await fetchData(
    `/dietitian/programs/search-new/show/${programId}`,
    APIMethods.GET,
  );
  return await searchProgramsShowProgramSchema.validate(data);
};

export interface SearchProgramsResponse2 {
  id: number;
  createdBy: AuthorDto | null;
  language: LangDto;
  clinic?: ClinicIdDto;
  translations: TranslationLangDto[];
  startDate: Date;
  endDate: Date | null;
  tags: TagExtendedDto[];
  nutrients: NutrientDto[];
  programDays: ProgramDayDto[];
  actions: ActionsDto;
}

export const searchProgramsSchema2 = yup.object().shape({
  id: yup.number().required(),
  createdBy: authorSchema.nullable().default(null),
  language: yup.mixed<LangDto>().oneOf(Object.values(LangDto)).required(),
  clinic: clinicIdSchema,
  translations: yup.array().of(translationSchema).required().min(1),
  startDate: yup.date().required(),
  endDate: yup.date().nullable().default(null),
  tags: yup.array().of(tagExtendedSchema).required(),
  nutrients: yup.array().of(nutrientSchema).required(),
  programDays: yup.array().of(programDaySchema).required(),
  actions: actionsSchema.required(),
});

const searchProgramsShowProgramSchema = yup.object().shape({
  data: searchProgramsSchema2.required(),
});
