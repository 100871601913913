import { styled } from "@mui/material";

export const DashboardEmptyStateWrapper = styled("div")`
  display: grid;
  gap: 32px;
  height: 100%;
  padding: 16px;
`;

export const ContentWrapper = styled("div")`
  display: grid;
  place-items: center;
  place-content: center;
  gap: 0.5rem;
`;

export const ButtonTileStyled = styled("div")`
  display: grid;
  place-items: center;
  padding: 16px 24px;
  gap: 16px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primaryLightNew};
  border-radius: 16px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.medium};
  }
`;

export const IconWrapper = styled("div")`
  display: grid;
  place-items: center;
  color: ${({ theme }) => theme.palette.primary.main};
  width: 32px;
  height: 48px;
  font-size: 18px;
`;

export const TitleWrapper = styled("div")`
  display: grid;
  place-items: center;
  gap: 24px;
`;

export const TilesListWrapper = styled("div")`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
`;
