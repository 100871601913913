import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Carrot = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="14" viewBox="0 0 14 14">
      <path
        d="M9.30281 0.652334C9.07177 0.931614 8.62492 1.57396 8.62492 2.32801C8.62492 3.34358 9.01338 3.73203 9.64049 4.35914C10.2676 4.98625 10.656 5.3747 11.6716 5.3747C12.4257 5.3747 13.068 4.92785 13.3473 4.69681C13.4488 4.61303 13.4996 4.48862 13.4996 4.35914C13.4996 4.22965 13.4488 4.10525 13.3473 4.024C13.0579 3.79296 12.3749 3.34358 11.4685 3.34358C10.656 3.34358 10.4529 3.54669 10.4529 3.54669C10.4529 3.54669 10.656 3.34358 10.656 2.53113C10.656 1.62474 10.2067 0.94177 9.97562 0.652334C9.89438 0.550778 9.76997 0.5 9.64049 0.5C9.511 0.5 9.3866 0.550778 9.30281 0.652334ZM6.71059 3.95291C5.69503 3.95291 4.75309 4.41246 4.12852 5.17667L5.66456 6.7127C5.82197 6.87012 5.82197 7.12908 5.66456 7.2865C5.50715 7.44391 5.24818 7.44391 5.09077 7.2865L3.68675 5.88248V5.88502L0.556279 12.6334C0.449645 12.8645 0.497884 13.1387 0.678147 13.3215C0.858409 13.5043 1.13261 13.55 1.36619 13.4433L4.7861 11.8591L3.46333 10.5363C3.30591 10.3789 3.30591 10.1199 3.46333 9.96251C3.62074 9.80509 3.87971 9.80509 4.03712 9.96251L5.57062 11.4935L8.1146 10.3154C9.29266 9.76955 10.0493 8.58896 10.0493 7.28904C10.0467 5.44579 8.55383 3.95291 6.71059 3.95291Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
