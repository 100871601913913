import { ModalWrapper } from "@components/ModalWrapperNew";
import { OuterLabel } from "@components/OuterLabel";
import { useAppTranslation } from "@hooks";

import { LangChipsSelect } from "./LangChipsSelect";
import { LanguageTextField } from "./LanguageTextField";
import {
  DietNameInputs,
  langOptions,
  useDietNameForm,
} from "./useDietNameForm";

interface DietNameModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  confirmText?: string;
  onSubmit: (values: DietNameInputs) => void;
  loading?: boolean;
  defaultValues?: DietNameInputs;
}

export const DietNameModal = ({
  open,
  onClose,
  title,
  confirmText,
  onSubmit,
  loading,
  defaultValues,
}: DietNameModalProps) => {
  const { t } = useAppTranslation();
  const { control, handleSubmit, watch, reset } =
    useDietNameForm(defaultValues);
  const lang = watch("lang");

  const onSubmitWrapper = () =>
    handleSubmit(
      v => onSubmit(v),
      e => console.log(e),
    )();

  const closeWithReset = () => {
    onClose();
    reset();
  };
  return (
    <ModalWrapper
      open={open}
      onClose={closeWithReset}
      onSubmit={onSubmitWrapper}
      title={title}
      confirmText={confirmText}
      loading={loading}
    >
      <div className="grid gap-[24px]">
        <LangChipsSelect control={control} />

        <OuterLabel label={t("diets.diet_name")}>
          {lang.map(langId => {
            const lang = langOptions.find(l => l.id === langId);

            if (!lang) return;

            const { id, label, pathName } = lang;

            return (
              <LanguageTextField
                key={id}
                langPrefix={label}
                name={pathName}
                control={control}
              />
            );
          })}
        </OuterLabel>
      </div>
    </ModalWrapper>
  );
};
