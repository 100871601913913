import { styled } from "@mui/material";
import { CalendarPicker } from "@mui/x-date-pickers/CalendarPicker";

export const StyledCalendarPicker = styled(CalendarPicker)`
  width: 100%;
  max-height: 240px;
  overflow: hidden;

  & .MuiPickersCalendarHeader-switchViewButton {
    display: none;
  }

  & .MuiPickersCalendarHeader-root {
    display: none;
  }

  & .MuiIconButton-root {
    display: none;
  }

  & .MuiDayPicker-weekContainer {
    gap: 10px;
  }

  & .MuiDayPicker-header {
    gap: 10px;
  }

  & .MuiPickersDay-root.Mui-selected {
    background-color: transparent !important;
  }

  & .MuiPickersDay-root {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.neutral.dark["900"]} !important;
  }

  & .MuiDayPicker-weekDayLabel {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.neutral.medium["600"]};
  }

  & .MuiPickersDay-dayOutsideMonth {
    color: ${({ theme }) => theme.colors.neutral.medium["600"]} !important;
  }
`;

export const StyledActiveDaySpan = styled("span")`
  width: 5px;
  height: 5px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(120%);
`;
