import { Dispatch, useMemo } from "react";
import DialogTitle from "@components/Alloweat/Dialog/DialogTitle";
import { Button, DialogContent, Stack, styled } from "@mui/material";
import { IconButton } from "@components/Alloweat/IconButton";
import DialogActions from "@components/Alloweat/Dialog/DialogActions";
import Dialog from "@components/Alloweat/Dialog/Dialog";
import useDeletePatientBodyMeasurementMutation from "@hooks/queries/client/bodyMeasurement/useDeletePatientBodyMeasurementMutation";
import { LoadingButton } from "@mui/lab";
import { useAppTranslation } from "@hooks";
import { CloseIcon } from "@icons/index";
import dayjs from "dayjs";

type Props = {
  patientId: number;
  patientBodyMeasurement: {
    id: number;
    date: string;
  };
  open: boolean;
  setOpen: Dispatch<boolean>;
};

const DeletePatientBodyMeasurementModal = ({
  patientBodyMeasurement,
  patientId,
  open,
  setOpen,
}: Props) => {
  const { t } = useAppTranslation();

  const mutate = useDeletePatientBodyMeasurementMutation({ patientId });
  const handleOnClick = () => {
    if (mutate.isLoading) {
      return;
    }

    mutate.mutate(
      {
        params: {
          measurementId: patientBodyMeasurement.id,
        },
      },
      {
        onSuccess: () => {
          setOpen(false);
        },
      },
    );
  };

  const date = useMemo(() => {
    return dayjs(patientBodyMeasurement.date).format("DD.MM.YYYY");
  }, [patientBodyMeasurement]);

  return (
    <Dialog open={open} fullWidth PaperProps={{ sx: { maxWidth: "510px" } }}>
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <span>
            {t(
              "patient.health_and_goal.body_measurement_tab.delete_body_measurement_modal.title",
            )}
          </span>

          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon color="727272" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        {t(
          "patient.health_and_goal.body_measurement_tab.delete_body_measurement_modal.desc",
        )}{" "}
        <Date>{date}</Date>?
      </DialogContent>
      <DialogActions>
        <Button
          disabled={mutate.isLoading}
          variant="outlined"
          onClick={() => setOpen(false)}
        >
          {t("common.cancel")}
        </Button>
        <LoadingButton
          color="error"
          onClick={handleOnClick}
          loading={mutate.isLoading}
          variant="contained"
        >
          {t(
            "patient.health_and_goal.body_measurement_tab.delete_body_measurement_modal.confirm",
          )}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const Date = styled("span")`
  font: var(--font-figtree-semi-bold);
`;

export default DeletePatientBodyMeasurementModal;
