import { useAppParams } from "@hooks";
import { MutationOptions } from "@hooks/queries";
import { useDeleteDietProductRemovedMutation as useDeleteCreatorDietProductRemovedMutation } from "@hooks/queries/diets";
import { useDeleteProgramDietProductRemovedMutation } from "@hooks/queries/program/dietCreator";

export const useDeleteDietProductRemovedMutation = (
  options?: MutationOptions,
) => {
  const { programId } = useAppParams();

  return programId
    ? useDeleteProgramDietProductRemovedMutation(programId, options)
    : useDeleteCreatorDietProductRemovedMutation(options);
};
