import { useLocalStorage } from "react-use";
import { useAppParams } from "@hooks/useAppParams";

export const SCHEDULE_IDS_KEY = "scheduleIdsKey";

interface ProgramInfo {
  [programId: string]: {
    modified: boolean;
  };
}

export function useProgramLocalStorage() {
  const { programId } = useAppParams();
  const [value, setValue] = useLocalStorage<ProgramInfo>(SCHEDULE_IDS_KEY, {});

  const markModified = () => {
    if (value?.[programId]) {
      console.log("mark modified");
      setValue(value => ({ ...value, [programId]: { modified: true } }));
    }
  };

  const markOptimized = () => {
    if (value?.[programId]) {
      console.log("mark optimised");
      setValue(value => ({ ...value, [programId]: { modified: false } }));
    }
  };

  const markInitialized = () => {
    if (!value?.[programId]) {
      console.log("mark initialized");
      setValue(value => ({ ...value, [programId]: { modified: false } }));
    }
  };

  return {
    programInfo: value?.[programId] ?? defaultValue,
    markOptimized,
    markModified,
    markInitialized,
  };
}

const defaultValue = { modified: false };
