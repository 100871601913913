import { styled, TableCell, TableRow } from "@mui/material";

import { FormSlider } from "@components/FormSlider";

export const FormSliderStyled = styled(FormSlider)`
  margin: 0 1rem 2rem 1rem;
  width: calc(100% - 2rem);

  .MuiSlider-markLabel[data-index="0"] {
    transform: unset;
  }

  .MuiSlider-markLabel[data-index="1"] {
    transform: translateX(-100%);
  }
`;

export const DayTabsWrapper = styled("div")`
  display: flex;
  margin: 1rem 0 0 -2rem;
  gap: 1rem;
`;

interface StyledTableRowProps {
  purple?: boolean;
}
export const StyledTableRow = styled(TableRow, {
  shouldForwardProp: prop => prop !== "purple",
})<StyledTableRowProps>`
  background-color: ${({ purple, theme }) =>
    purple ? theme.colors.lightPurple : ""};
`;

interface StyledTableCellProps {
  hasError?: boolean;
}
export const StyledTableCell = styled(TableCell, {
  shouldForwardProp: prop => prop !== "hasError",
})<StyledTableCellProps>`
  border: 0;
  color: ${({ hasError, theme }) =>
    hasError ? theme.colors.alertTomato : theme.palette.success.light};
`;
