import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import { IdDto, idSchema, TranslationDto } from "./common";
import { object } from "yup";

export const postProgramDayDiet = async (
  programId: string,
  dayId: string,
  payload: PostProgramDayDietPayload,
): Promise<ApiResponse<IdDto>> => {
  const response = await fetchData(
    `/dietitian/programs/${programId}/days/${dayId}/diet`,
    APIMethods.POST,
    payload,
  );

  return await responseSchema.validate(response);
};

export interface PostProgramDayDietPayload {
  translations: TranslationDto[];
}

const responseSchema = object({
  data: idSchema.required(),
});
