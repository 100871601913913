import { QueryOptionsTyped } from "@hooks/queries";
import { useQuery } from "@tanstack/react-query";
import { patientKeys } from "@hooks/queries/client/patientKeys";
import {
  fetchPatientProductExchangers,
  FetchPatientProductExchangersParams,
  FetchPatientProductExchangersResponse,
} from "@client/patient/fetchPatientProductExchangers";

const useFetchPatientProductExchangers = (
  params: FetchPatientProductExchangersParams,
  options?: QueryOptionsTyped<FetchPatientProductExchangersResponse>,
) => {
  return useQuery({
    queryKey: patientKeys.productExchangers(params.patientId),
    queryFn: () => fetchPatientProductExchangers(params),
    ...options,
  });
};

export default useFetchPatientProductExchangers;
