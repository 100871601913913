import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "./types";
import { fetchClientProgramsQueryKey } from "./useFetchClientProgramsQuery";

import {
  cloneClientProgramToClient,
  CloneClientProgramToClientRequest,
  CloneClientProgramToClientResponse,
} from "@client";
import { ApiResponse } from "@typeDefinitions";

export const useCloneClientProgramToClient = (
  patientId: number,
  programId: number,
  options?: MutationOptions<ApiResponse<CloneClientProgramToClientResponse>>,
) => {
  const queryClient = useQueryClient();

  return useMutation<
    ApiResponse<CloneClientProgramToClientResponse>,
    unknown,
    CloneClientProgramToClientRequest
  >(payload => cloneClientProgramToClient(patientId, programId, payload), {
    ...options,
    onSuccess: data => {
      queryClient.invalidateQueries([fetchClientProgramsQueryKey]);
      options?.onSuccess && options.onSuccess(data);
    },
  });
};
