import { fetchData } from "@utils/api";
import {
  APIMethods,
  CreatedApiResponse,
  NoContentApiResponse,
} from "@typeDefinitions";

export type CreatePatientBodyMeasurementParams = {
  patientId: number;
};

export type CreatePatientBodyMeasurementResponse = CreatedApiResponse;

export type CreatePatientBodyMeasurementPayload = {
  date: string;
  bodyMeasurements?: { id: number; value: number | null }[];
};

export const createPatientBodyMeasurement = async (
  params: CreatePatientBodyMeasurementParams,
  payload: CreatePatientBodyMeasurementPayload,
): Promise<CreatePatientBodyMeasurementResponse> => {
  return await fetchData(
    "/dietitian/patients/{patient_id}/measurements".replace(
      "{patient_id}",
      params.patientId.toString(),
    ),
    APIMethods.POST,
    payload,
  );
};
