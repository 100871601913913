import { OuterLabel } from "@components/OuterLabel";
import { useAppTranslation, useModal } from "@hooks";
import { QuestionTableDto, TableAnswerResourceDto } from "@client/surveys";
import { Close, Fullscreen } from "@assets/icons";

import { QuestionPreview } from "../../QuestionPreview/QuestionPreview";
import { TextStyled } from "../../QuestionPreview/QuestionPreview.styled";
import { TableAnswerPreview } from "./TableAnswerPreview";
import { HeaderStyled, IconButtonStyled } from "./TableAnswerPreview.styled";
import { ModalQuestionPreview } from "../../QuestionPreview/ModalQuestionPreview";

interface AnswerTableProps {
  question: QuestionTableDto;
  answer?: TableAnswerResourceDto;
  listIndex: number;
  totalQuestions: number;
}

export const AnswerTable = ({
  answer,
  question,
  listIndex,
  totalQuestions,
}: AnswerTableProps) => {
  const { title, description } = question;
  const { t } = useAppTranslation();
  const { modalOpened, onModalOpen, onModalClose } = useModal();

  return (
    <>
      <QuestionPreview
        title={title}
        description={description}
        listIndex={listIndex}
        totalQuestions={totalQuestions}
        actionComponents={
          <IconButtonStyled onClick={onModalOpen}>
            <Fullscreen />
          </IconButtonStyled>
        }
      >
        {question.config.heading && (
          <HeaderStyled>{question.config.heading}</HeaderStyled>
        )}
        <TableAnswerPreview
          config={question.config}
          cells={answer?.answer.data}
        />
        <OuterLabel label={t("questionnaires.questions.table.extra_comment")}>
          <TextStyled>
            {answer?.answer.comment ?? t("questionnaires.questions.no_answer")}
          </TextStyled>
        </OuterLabel>
      </QuestionPreview>

      <ModalQuestionPreview
        title={title}
        description={description}
        listIndex={listIndex}
        totalQuestions={totalQuestions}
        actionComponents={
          <IconButtonStyled onClick={onModalClose} size="small">
            <Close size="w-3 h-3" />
          </IconButtonStyled>
        }
        open={modalOpened}
        onClose={onModalClose}
      >
        {question.config.heading && (
          <HeaderStyled>{question.config.heading}</HeaderStyled>
        )}
        <TableAnswerPreview
          config={question.config}
          cells={answer?.answer.data}
        />
        <OuterLabel label={t("questionnaires.questions.table.extra_comment")}>
          <TextStyled>
            {answer?.answer.comment ?? t("questionnaires.questions.no_answer")}
          </TextStyled>
        </OuterLabel>
      </ModalQuestionPreview>
    </>
  );
};
