import { Button } from "@mui/material";
import { useAppTranslation } from "@hooks";

import { ToastStyled } from "./VersionToastInfo.styled";

export const VersionToastInfo = () => {
  const { t } = useAppTranslation();

  const handleRefresh = () => location.reload();

  return (
    <ToastStyled>
      <div>{t("common.app_outdated")}</div>
      <Button variant="contained" onClick={handleRefresh}>
        {t("common.refresh")}
      </Button>
    </ToastStyled>
  );
};
