import { IconButton, styled } from "@mui/material";

export const RowStyled = styled("div")`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  gap: 12px;
  border-radius: 8px;
  background: ${({ theme }) => theme.palette.primary.light};
`;

export const Title = styled("span")`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const IconButtonStyled = styled(IconButton)`
  height: 24px;
  width: 24px;
  padding: unset;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
`;
