import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { LangDto, PhoneDto, phoneSchema } from "./common";
import * as yup from "yup";
import { CustomTag, customTagSchema } from "@client/customTags/fetchCustomTags";

export const fetchClient = async (
  id: number | string,
): Promise<ApiResponse<FetchClientResponse>> => {
  const response = await fetchData(`/dietitian/patients/${id}`, APIMethods.GET);
  return await fetchClientSchema.validate(response);
};

export enum ClientSexDto {
  M = "m",
  W = "w",
}

const idSchema = yup.object().shape({
  id: yup.number().required(),
});

const clientAddressSchema = yup.object().shape({
  street: yup.string().nullable().default(null),
  streetNumber: yup.string().nullable().default(null),
  houseNumber: yup.string().nullable().default(null),
  city: yup.string().nullable().default(null),
  postcode: yup.string().nullable().default(null),
});

const applicationModulesSchema = yup.object().shape({
  chat: yup.boolean().required(),
  foodCreator: yup.boolean().default(false),
  accessRecipeBase: yup
    .object()
    .shape({
      alloweat: yup.boolean().required(),
      clinic: yup.boolean().required(),
    })
    .required(),
});

const profileSchema = yup.object().shape({
  lang: yup.string().oneOf(Object.values(LangDto)).required(),
  sex: yup.string().oneOf(Object.values(ClientSexDto)).nullable().default(null),
  description: yup.string().nullable().default(null),
  birthDate: yup.string().nullable().default(null),
  height: yup.number().nullable().default(null),
  weight: yup.number().nullable().default(null),
  targetWeight: yup.number().nullable().default(null),
  additionalFeatures: yup.array().of(idSchema).required(),
  targets: yup.array().of(idSchema).required(),
  sources: yup.array().of(idSchema).required(),
  address: clientAddressSchema.required(),
  avatar: yup.string().nullable().default(null),
  avatarUrl: yup.string().nullable().default(null),
  hasAccess: yup.boolean().required(),
  bodyFat: yup.number().nullable().default(null),
  applicationModules: applicationModulesSchema.required(),
  note: yup.string().nullable().default(null),
});

const facilitySchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
});

const dietitianSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
});

export const activeSchema = yup.object().shape({
  until: yup.string().nullable().default(null),
  mobileApp: yup.boolean().required(),
});

const recipeBaseSchema = yup.object().shape({
  alloweat: yup.boolean().required(),
  clinic: yup.boolean().required(),
});
const mobileApplicationModulesSchema = yup.object().shape({
  chat: yup.boolean().required(),
  foodCreator: yup.boolean().required(),
  recipeBase: recipeBaseSchema.required(),
});

const mobileApplicationSchema = yup.object().shape({
  active: yup.boolean().required(),
  firstActivatedAt: yup.string().nullable().default(null),
  modules: mobileApplicationModulesSchema.required(),
});

const clientSchema = yup.object().shape({
  id: yup.number().required(),
  active: activeSchema.nullable().default(null),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().nullable().default(null),
  emailVerified: yup.boolean().required(),
  lastLoggedAt: yup.string().nullable().defined(),
  phone: phoneSchema.required(),
  lang: yup.string().oneOf(Object.values(LangDto)).required(),
  profile: profileSchema.required(),
  facility: facilitySchema.nullable().default(null),
  dietitian: dietitianSchema.nullable(),
  remainingNumberOfEmailAddressChanges: yup.number().required(),
  avatarUrl: yup.string().nullable().default(null),
  mobileApplication: mobileApplicationSchema.nullable().default(null),
  tags: yup.array().of(customTagSchema.required()).required(),
});

const fetchClientSchema = yup.object().shape({
  data: clientSchema,
});

export interface FetchClientResponse {
  id: number;
  active: ActiveDto | null;
  firstName: string;
  lastName: string;
  email: string | null;
  emailVerified: boolean;
  lastLoggedAt: string | null;
  phone: PhoneDto;
  lang: LangDto;
  profile: ClientProfileDto;
  facility: FacilityDto | null;
  dietitian: DietitianDto | null;
  remainingNumberOfEmailAddressChanges: number;
  avatarUrl: string | null;
  mobileApplication: MobileApplicationDto | null;
  tags: CustomTag[];
}

export interface ActiveDto {
  until: string | null;
  mobileApp: boolean;
}

interface FacilityDto {
  id: number;
  name: string;
}

interface DietitianDto {
  id: number;
  name: string;
}

interface ApplicationModules {
  chat: boolean;
  foodCreator: boolean;
  accessRecipeBase: {
    alloweat: boolean;
    clinic: boolean;
  };
}

interface ClientProfileDto {
  lang: LangDto;
  sex: ClientSexDto | null;
  description: string | null;
  birthDate: string | null;
  height: number | null;
  weight: number | null;
  targetWeight: number | null;
  additionalFeatures: AdditionalFeatureDto[];
  targets: TargetDto[];
  sources: SourceDto[];
  address: ClientAddressDto;
  avatar: string | null;
  avatarUrl: string | null;
  hasAccess: boolean;
  bodyFat: number | null;
  applicationModules: ApplicationModules;
  note: string | null;
}

interface AdditionalFeatureDto {
  id: number;
}

interface TargetDto {
  id: number;
}

interface SourceDto {
  id: number;
}

export interface ClientAddressDto {
  street: string | null;
  streetNumber: string | null;
  houseNumber: string | null;
  city: string | null;
  postcode: string | null;
}

interface MobileApplicationDto {
  active: boolean;
  firstActivatedAt: string | null;
  modules: ApplicationModulesDto;
}

interface ApplicationModulesDto {
  chat: boolean;
  foodCreator: boolean;
  recipeBase: RecipeBaseDto;
}

interface RecipeBaseDto {
  alloweat: boolean;
  clinic: boolean;
}
