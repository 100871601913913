import { Dispatch, SetStateAction, useEffect, useMemo } from "react";

import { Chip, InputBaseComponentProps } from "@mui/material";
import { omit } from "lodash";

import { TreeItemOption } from "./TreeSelectSearch";
import { InputComponentWrapper, InputStyled } from "./TreeSelectSearch.styled";
import { buildDictionary, handleCheckboxChange } from "./treeSelectSearchUtils";

export interface CustomInputComponentProps {
  props: InputBaseComponentProps;
  options: TreeItemOption[];
  simplifiedSelectedIds: string[];
  selected: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;
  onFocus?: () => void;
  onBlur?: () => void;
  color?: "error" | "warning" | "success" | "primary";
}

export const CustomInputComponent = (props: CustomInputComponentProps) => {
  const {
    props: inputProps,
    options,
    selected,
    setSelected,
    simplifiedSelectedIds,
    onFocus,
    onBlur,
    color = "primary",
  } = props;
  const dictionary = useMemo(() => buildDictionary(options), [options]);

  useEffect(() => {
    onBlur && onBlur();
  }, []);

  return (
    <div className="grid w-full">
      <InputComponentWrapper>
        {simplifiedSelectedIds.map((chip, index) => {
          const data = dictionary.get(chip);

          if (!data) return <></>;

          const { id, name } = data;

          return (
            <Chip
              onFocus={onFocus}
              onBlur={onBlur}
              key={index}
              label={name}
              color={color}
              onDelete={() =>
                handleCheckboxChange(id, selected, options, setSelected)
              }
            />
          );
        })}

        <InputStyled {...omit(inputProps, "className")} />
      </InputComponentWrapper>
    </div>
  );
};
