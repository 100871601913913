import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Pizza = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="14" viewBox="0 0 14 14">
      <path
        d="M4.81946 0.513425C4.24055 0.4728 3.75559 0.868894 3.6134 1.39448L3.31379 2.52182C3.32649 2.52182 3.34172 2.52182 3.35442 2.52182C7.84094 2.52182 11.4794 6.1603 11.4794 10.6468C11.4794 10.6595 11.4794 10.6748 11.4794 10.6874L12.6068 10.3878C13.1349 10.2482 13.5284 9.76069 13.4878 9.18178C13.1679 4.54038 9.46086 0.833347 4.81946 0.513425ZM10.6618 10.9058C10.6644 10.8195 10.6669 10.7332 10.6669 10.6468C10.6669 6.60718 7.39406 3.33432 3.35442 3.33432C3.26809 3.33432 3.18176 3.33686 3.09543 3.3394L0.523361 12.9802C0.485276 13.1199 0.525901 13.2697 0.627463 13.3712C0.729026 13.4728 0.87883 13.5134 1.01848 13.4753L10.6618 10.9058ZM4.97942 5.77182C5.1949 5.77182 5.40157 5.85743 5.55394 6.0098C5.70631 6.16217 5.79192 6.36883 5.79192 6.58432C5.79192 6.79981 5.70631 7.00647 5.55394 7.15885C5.40157 7.31122 5.1949 7.39682 4.97942 7.39682C4.76393 7.39682 4.55727 7.31122 4.40489 7.15885C4.25252 7.00647 4.16692 6.79981 4.16692 6.58432C4.16692 6.36883 4.25252 6.16217 4.40489 6.0098C4.55727 5.85743 4.76393 5.77182 4.97942 5.77182ZM6.60442 9.02182C6.60442 8.80634 6.69002 8.59967 6.84239 8.4473C6.99477 8.29493 7.20143 8.20932 7.41692 8.20932C7.6324 8.20932 7.83907 8.29493 7.99144 8.4473C8.14381 8.59967 8.22942 8.80634 8.22942 9.02182C8.22942 9.23731 8.14381 9.44397 7.99144 9.59635C7.83907 9.74872 7.6324 9.83432 7.41692 9.83432C7.20143 9.83432 6.99477 9.74872 6.84239 9.59635C6.69002 9.44397 6.60442 9.23731 6.60442 9.02182ZM2.94817 10.2406C2.94817 10.0251 3.03377 9.81842 3.18614 9.66605C3.33852 9.51368 3.54518 9.42807 3.76067 9.42807C3.97615 9.42807 4.18282 9.51368 4.33519 9.66605C4.48756 9.81842 4.57317 10.0251 4.57317 10.2406C4.57317 10.4561 4.48756 10.6627 4.33519 10.8151C4.18282 10.9675 3.97615 11.0531 3.76067 11.0531C3.54518 11.0531 3.33852 10.9675 3.18614 10.8151C3.03377 10.6627 2.94817 10.4561 2.94817 10.2406Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
