import { useMutation } from "@tanstack/react-query";
import { confirmVisit } from "@client";
import { MutationOptions } from "./types";

/**
 * @deprecated Change hook like everywhere instead of that. Use invalidation if necessary.
 */
export function useConfirmVisitMutation(options?: MutationOptions) {
  return useMutation((url: string) => confirmVisit(url), {
    ...options,
    onSuccess: () => {
      options?.onSuccess && options.onSuccess();
    },
  });
}
