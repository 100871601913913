import { fetchData } from "@utils/api";
import { APIMethods, ApiEndpoints } from "@typeDefinitions";
import { TaskPriority } from "@views/dietician/Tasks/components/TaskPrioritySelect";
import { object, number, string, boolean, array, mixed } from "yup";

import qs from "qs";
import {
  PaginationLinks,
  Metadata,
  MetadataSchema,
  PaginationLinksSchema,
} from "@typeDefinitions";

export interface QueryParams {
  page: number;
  per_page: number;
  custom_tags_ids: string | number[] | undefined;
  dietitians_ids: string | number[] | undefined;
  due_date_from: string | undefined;
  due_date_to: string | undefined;
  order_by: string | undefined;
  order_dir: string | undefined;
  done?: number;
}

export const fetchTasks = async (queryParams: Partial<QueryParams>) => {
  const composedQueryString =
    "?" +
    qs.stringify(queryParams, {
      arrayFormat: "comma",
    });

  const response = await fetchData<TaskResponse>(
    `${ApiEndpoints.Tasks}${composedQueryString}`,
    APIMethods.GET,
  );

  return await taskResponseSchema.validate(response);
};

export interface CustomTag {
  id: number;
  isSystem: boolean;
  name: string;
  color: string;
}

export interface Dietitian {
  id: number;
  name: string;
  avatar: string | null;
}

export interface Client {
  id: number;
  name: string;
  activeUntil: string | null;
  avatar: string | null;
}

interface Actions {
  canEdit: boolean;
  canDelete: boolean;
}

export interface Task {
  id: number;
  name: string;
  description: string | null;
  relatedLink: string | null;
  dueDate: string | null;
  priority: TaskPriority | null;
  isDone: boolean;
  dietitian: Dietitian | null;
  client: Client | null;
  owner: Dietitian | null;
  customTags: CustomTag[];
  actions: Actions;
}

export interface TaskResponse {
  data: Task[];
  links: PaginationLinks;
  meta: Metadata;
}

export interface TaskResponseSelected extends Omit<TaskResponse, "data"> {
  tasks: Task[];
}

const customTagSchema = object({
  id: number().required(),
  isSystem: boolean().required().defined(),
  name: string().required(),
  color: string().required(),
});

const dietitianSchema = object({
  id: number().required(),
  name: string().required(),
  avatar: string().nullable().defined(),
});

const clientSchema = object({
  id: number().required(),
  name: string().required(),
  activeUntil: string().nullable().defined(),
  avatar: string().nullable().defined(),
});

const actionsSchema = object({
  canEdit: boolean().required().defined(),
  canDelete: boolean().required().defined(),
});

export const taskSchema = object({
  id: number().required(),
  name: string().required(),
  description: string().nullable().defined(),
  relatedLink: string().nullable().defined(),
  dueDate: string().nullable().defined(),
  priority: mixed<TaskPriority>()
    .oneOf(Object.values(TaskPriority))
    .nullable()
    .defined(),
  isDone: boolean().required().defined(),
  dietitian: dietitianSchema.nullable().defined(),
  client: clientSchema.nullable().defined(),
  owner: dietitianSchema.nullable().defined(),
  customTags: array().of(customTagSchema).defined(),
  actions: actionsSchema.required(),
});

export const taskResponseSchema = object({
  data: array().of(taskSchema).required(),
  links: PaginationLinksSchema.defined(),
  meta: MetadataSchema.defined(),
});
