import { ReactNode } from "react";

import { Typography } from "@mui/material";
import { IconButton } from "@components/IconButton";
import { ButtonTileStyled, IconWrapper } from "./DashboardEmptyState.styled";
import { PlusRounded } from "@assets/icons/PlusRounded";
import { TitleWrapper } from "@layouts/Dashboard";

interface ButtonTileProps {
  icon: ReactNode;
  title: string;
  info: string;
  buttonText: string;
  onClick: () => void;
  iconClassName?: string;
}

export const ButtonTile = ({
  icon,
  title,
  info,
  buttonText,
  onClick,
  iconClassName,
}: ButtonTileProps) => {
  return (
    <ButtonTileStyled>
      <TitleWrapper>
        <IconWrapper className={iconClassName ?? ""}>{icon}</IconWrapper>
        <Typography
          fontWeight={600}
          fontSize="1.125rem"
          lineHeight="1.5rem"
          align="center"
          color={({ colors }) => colors.neutral.dark[900]}
        >
          {title}
        </Typography>
      </TitleWrapper>
      <Typography
        fontWeight={400}
        fontSize="0.875rem"
        lineHeight="1.25rem"
        align="center"
        color={({ colors }) => colors.neutral.dark[800]}
      >
        {info}
      </Typography>
      <IconButton
        color="secondary-purple"
        onClick={onClick}
        className="flex gap-x-2 p-3 rounded-lg"
      >
        <PlusRounded size="h-3 w-3" />
        <span>{buttonText}</span>
      </IconButton>
    </ButtonTileStyled>
  );
};
