import { useMutation, useQueryClient } from "@tanstack/react-query";

import { EVENT_STATUS } from "@utils/scheduleEvent";
import { MutationOptions } from "./types";
import { fetchScheduleEventQueryKey } from "./useFetchScheduleEvent";
import { searchScheduleEventsQueryKey } from "./useSearchScheduleEvents";
import { patchScheduleEvent } from "@client";
import { toast } from "react-toastify";
import { useAppTranslation } from "@hooks/useAppTranslation";

interface patchScheduleRequest {
  status: EVENT_STATUS;
  notification?: boolean;
}

export const usePatchScheduleEvent = (
  id: number,
  options?: MutationOptions,
) => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, patchScheduleRequest>(
    ({ status, notification }) =>
      patchScheduleEvent(id, { status, notification }),
    {
      ...options,
      onSuccess: (_, { status }) => {
        queryClient.invalidateQueries([fetchScheduleEventQueryKey, id]);
        queryClient.invalidateQueries([searchScheduleEventsQueryKey]);
        options?.onSuccess && options.onSuccess();

        switch (status) {
          case EVENT_STATUS.CANCELED:
            toast.success(t("visits.canceled_confirmation"));
            break;
          case EVENT_STATUS.CONFIRMED:
            toast.success(t("visits.confirmed_confirmation"));
            break;
        }
      },
    },
  );
};
