import {
  ContentWrapper,
  EmptyStateCard,
  StyledSubTitle,
  StyledTitle,
} from "./NotificationEmptyState.styled";
import { useAppTranslation } from "@hooks";
import { ReactNode } from "react";

interface NotificationEmptyStateProps {
  img: string;
  content?: ReactNode;
}
export const NotificationEmptyState = ({
  content,
  img,
}: NotificationEmptyStateProps) => {
  const { t } = useAppTranslation();

  return (
    <EmptyStateCard>
      <ContentWrapper>
        <img src={img} alt={t("notifications.no_notifications")} />

        <StyledTitle>{t("notifications.no_notifications")}</StyledTitle>

        <StyledSubTitle maxWidth="40rem">{content}</StyledSubTitle>
      </ContentWrapper>
    </EmptyStateCard>
  );
};
