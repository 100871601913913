import styled from "@emotion/styled";
import { TabPanel } from "@mui/lab";
import { Button, Tab } from "@mui/material";

export const StyledTabPanel = styled(TabPanel)`
  padding: 1rem 0;
`;

export const AddOfficeButton = styled(Button)`
  font-size: 1rem;
  font-weight: 600;
  margin-left: auto;
  margin-bottom: 1rem;
`;

export const StyledTab = styled(Tab)`
  color: #000000dd;
  text-transform: unset;
  font-size: 1rem;
`;
