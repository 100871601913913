import { DialogContent, styled } from "@mui/material";

export const DialogContentStyled = styled(DialogContent)`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
  width: 478px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
