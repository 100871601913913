import { InputAdornment } from "@mui/material";

import { isArray } from "lodash";

import {
  StyledOutlinedInput,
  StyledSlider,
  StyledTypography,
} from "./SingleSimulationSlider.styled";
import { decimalInput2, decimalTwoDigitsInput } from "@utils/inputs";
import { ReactNode } from "react";

interface SingleSimulationSliderProps {
  title: string;
  unit: string;
  value: number;
  onChange: (value: number) => void;
  min: number;
  max: number;
  step?: number;
  float?: boolean;
  disabled?: boolean;
  valueLabelFormat?:
    | string
    | ((value: number, index: number) => ReactNode)
    | undefined;
}

export const SingleSimulationSlider = ({
  title,
  unit,
  value,
  onChange,
  min,
  max,
  step,
  float,
  disabled,
  valueLabelFormat,
}: SingleSimulationSliderProps) => {
  return (
    <div className="flex gap-6 items-center">
      <StyledTypography variant="body1">{title}</StyledTypography>
      <StyledOutlinedInput
        disabled={disabled}
        value={value}
        endAdornment={<InputAdornment position="end">{unit}</InputAdornment>}
        inputComponent={float ? decimalInput2 : decimalTwoDigitsInput}
        onChange={event =>
          parseFloat(event.target.value) &&
          onChange(parseFloat(event.target.value))
        }
      />
      <StyledSlider
        value={value}
        onChange={(_, value) => !isArray(value) && onChange(value)}
        min={min}
        max={max}
        step={step}
        color="secondary"
        disabled={disabled}
        valueLabelDisplay={disabled ? "off" : undefined}
        valueLabelFormat={valueLabelFormat}
      />
    </div>
  );
};
