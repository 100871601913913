import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const CheckInCircle = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="25" viewBox="0 0 24 25" {...props}>
      <path
        fill="none"
        d="M22 11.5855V12.5055C21.9988 14.6619 21.3005 16.7602 20.0093 18.4873C18.7182 20.2144 16.9033 21.478 14.8354 22.0894C12.7674 22.7008 10.5573 22.6274 8.53447 21.8801C6.51168 21.1327 4.78465 19.7516 3.61096 17.9425C2.43727 16.1335 1.87979 13.9935 2.02168 11.8418C2.16356 9.69004 2.99721 7.6418 4.39828 6.00255C5.79935 4.3633 7.69278 3.22086 9.79619 2.74563C11.8996 2.27039 14.1003 2.48782 16.07 3.36548"
        stroke={props.fill ?? "currentColor"}
        strokeWidth="1.74"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="none"
        d="M22 5.49976L11.6553 14.4998L7 10.4083"
        stroke={props?.fill ?? "currentColor"}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
