import { Badge, IconButton, Typography, styled } from "@mui/material";
import { NOTIFICATION_EVENT_STATUS } from "@utils";

interface EventTypeWrapperWrapperProps {
  status?: NOTIFICATION_EVENT_STATUS;
}

export const EventTypeWrapper = styled("div")<EventTypeWrapperWrapperProps>`
  position: absolute;
  top: 0;
  left: 8px;
  bottom: 0;
  margin: auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  ${({ status, theme }) => {
    switch (status) {
      case NOTIFICATION_EVENT_STATUS.PROGRAM_ENDS:
        return `
        background: ${theme.colors.survey.light};  
          color: ${theme.palette.primary.main}; 
      `;
      case NOTIFICATION_EVENT_STATUS.PATIENT_COOPERATION_ENDS:
        return `
        background: ${theme.palette.success.light}; 
        color: ${theme.palette.success.main}; 
          `;
      case NOTIFICATION_EVENT_STATUS.SURVEY_REPORT:
        return `
        background: ${theme.palette.info.light};  
        color: ${theme.palette.info.main}; 
      `;
      case NOTIFICATION_EVENT_STATUS.PATIENT_BIRTHDAY:
        return `
          background: ${theme.colors.survey.pink.light};  
          color: ${theme.colors.survey.pink.dark}; 
      `;
      default:
        return `
        background: black;  
        color: blue; 
      `;
    }
  }};
`;

export const ButtonWrapper = styled("div")`
  margin-top: 16px;
  margin-bottom: auto;
`;

export const NotificationButton = styled(IconButton)`
  display: none;
  width: 24px;
  height: 24px;
`;

export const EventContentWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 62px;
  padding-right: 8px;
  height: 75px;
  border-radius: 8px;
`;

export const EventName = styled("p")`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.neutral.dark[900]};
  font-weight: 500;
`;

export const Event = styled(Typography)`
  line-height: 14px;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
  margin-left: auto;
`;
export const EventTime = styled(Typography)`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
  margin-left: auto;
`;

export const StyledBadge = styled(Badge)`
  & .MuiBadge-badge {
    height: 12px;
    min-width: 12px;
    border-radius: 50%;
    padding: 0;
    top: -6px;
    right: -6px;

    &.MuiBadge-dot {
      border: 2px solid ${({ theme }) => theme.colors.neutral.light[100]};
    }
  }
`;
