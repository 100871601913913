import { useMemo } from "react";

import { Typography, useMediaQuery, useTheme } from "@mui/material";

import { useAppParams, useAppTranslation } from "@hooks";
import { useFetchCreatorMealNutrientsQuery } from "@hooks/queries/meals";
import { useFetchNutrientCategoriesQuery } from "@hooks/queries";
import { MicronutrientPreview } from "@components/MicronutrientsModal";
import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";
import { useVisibleNutrients } from "@context/VisibleNutrientsContext";
import { MACROS_ARRAY, macrosSortKey } from "@utils/macros";

import { NutrientLabel } from "./NutrientLabel";
import {
  MacrosWrapper,
  MealAverageNutrientsWrapper,
  MicrosWrapper,
  TitleWrapper,
} from "./MealAverageNutrients.styled";

export const MealAverageNutrients = () => {
  const { mealId } = useAppParams();
  const { t } = useAppTranslation();
  const { nutrients, isLoading, isError } =
    useFetchCreatorMealNutrientsQuery(mealId);

  const { nutrients: visibleMicros } = useVisibleNutrients();
  const { nutrientCategories } = useFetchNutrientCategoriesQuery();
  const { breakpoints } = useTheme();
  const isSmall = useMediaQuery(`${breakpoints.down("small")}`);
  const isMedium = useMediaQuery(`${breakpoints.down("medium")}`);

  const filteredNutrients = useMemo(
    () =>
      nutrientCategories?.flatMap(c =>
        c.nutrients.filter(n => visibleMicros.includes(n.id.toString())),
      ),
    [nutrientCategories, visibleMicros],
  );

  if (isLoading) return <Spinner className="ml-2" size="w-5 h-5" />;

  if (isError || !nutrients) return <ApiError />;

  return (
    <MealAverageNutrientsWrapper isTablet={isMedium}>
      <TitleWrapper>
        <Typography variant="body2">{t("meal.average_values")}:</Typography>
      </TitleWrapper>

      <div className="grid">
        <div className="flex">
          <MacrosWrapper isMobile={isSmall}>
            {nutrients
              .filter(n => MACROS_ARRAY.includes(n.id))
              .sort((a, b) => macrosSortKey(a.id, b.id))
              .map(n => (
                <NutrientLabel key={n.id} {...n} />
              ))}
          </MacrosWrapper>
          <MicronutrientPreview means={nutrients} />
        </div>
        {!!visibleMicros.length && filteredNutrients && (
          <MicrosWrapper>
            {filteredNutrients.map(n => (
              <div key={n.id}>
                <NutrientLabel
                  key={n.id}
                  id={n.id}
                  categoryId={n.nutrientCategoryId}
                  value={
                    nutrients?.find(nutrient => nutrient.id === n.id)?.value ??
                    0
                  }
                  shortName
                />
              </div>
            ))}
          </MicrosWrapper>
        )}
      </div>
    </MealAverageNutrientsWrapper>
  );
};
