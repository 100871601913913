import { MouseEvent, ReactNode, useState } from "react";
import { ConfirmPopover } from "@components/ConfirmPopper";

interface ConfirmationWrapperProps {
  children: ReactNode;
  onConfirm: () => void;
  confirmationText: string;
  submitText: string;
  disabled?: boolean;
  extraContent?: ReactNode;
  loading?: boolean;
}

export const ConfirmModalWrapper = ({
  onConfirm,
  children,
  confirmationText,
  submitText,
  disabled,
  extraContent,
  loading,
}: ConfirmationWrapperProps) => {
  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined);

  const handleClickAnchor = (e: MouseEvent<HTMLElement>) =>
    setAnchorEl(e.currentTarget);

  return (
    <>
      <div onClick={disabled ? () => null : handleClickAnchor}>{children}</div>
      <ConfirmPopover
        onConfirm={onConfirm}
        confirmationText={confirmationText}
        submitText={submitText}
        setAnchor={setAnchorEl}
        anchor={anchorEl}
        extraContent={extraContent}
        isLoading={loading}
      />
    </>
  );
};
