import React, { ReactNode } from "react";
import classnames from "classnames";
import { TAG_COLORS } from "@utils/constants";

export const ColoredCircle = (props: ColoredCircleProps) => {
  const { index, children, color, size = "large" } = props;

  return (
    <div
      className={classnames({
        "flex justify-center items-center text-lg rounded-full text-white font-semibold":
          true,
        "w-7 h-7 min-w-7 min-h-7": size === "large",
        "w-5 h-5 min-w-5 min-h-5": size === "medium",
        "w-3 h-3 min-w-3 min-h-3": size === "small",
      })}
      style={{ backgroundColor: color || TAG_COLORS[index || 0] }}
    >
      {children}
    </div>
  );
};

interface ColoredCircleProps {
  index?: number;
  size?: "small" | "medium" | "large";
  children: ReactNode;
  color?: string;
}
