import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const ArrowProgress = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      d="M3.46875 15.7812C3.15625 15.5 3.15625 15.0312 3.46875 14.7188C3.75 14.4375 4.21875 14.4375 4.53125 14.7188L6.25 16.4688V5.75C6.25 5.34375 6.5625 5 7 5C7.40625 5 7.75 5.34375 7.75 5.75V16.4688L9.46875 14.75C9.75 14.4375 10.2188 14.4375 10.5 14.75C10.8125 15.0312 10.8125 15.5 10.5 15.7812L7.5 18.7812C7.21875 19.0938 6.75 19.0938 6.46875 18.7812L3.46875 15.7812ZM12.75 19C12.3125 19 12 18.6875 12 18.25C12 17.8438 12.3125 17.5 12.75 17.5H14.25C14.6562 17.5 15 17.8438 15 18.25C15 18.6875 14.6562 19 14.25 19H12.75ZM12.75 15C12.3125 15 12 14.6875 12 14.25C12 13.8438 12.3125 13.5 12.75 13.5H16.25C16.6562 13.5 17 13.8438 17 14.25C17 14.6875 16.6562 15 16.25 15H12.75ZM12.75 11C12.3125 11 12 10.6875 12 10.25C12 9.84375 12.3125 9.5 12.75 9.5H18.25C18.6562 9.5 19 9.84375 19 10.25C19 10.6875 18.6562 11 18.25 11H12.75ZM12.75 7C12.3125 7 12 6.6875 12 6.25C12 5.84375 12.3125 5.5 12.75 5.5H20.25C20.6562 5.5 21 5.84375 21 6.25C21 6.6875 20.6562 7 20.25 7H12.75Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
