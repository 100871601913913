import { styled } from "@mui/material";

export const SummaryText = styled("span")`
  font-family: Figtree, serif;
  font-style: normal;
  font-weight: 500;
  color: #141414;
  font-size: 14px;
  line-height: 24px;
`;

export const SummaryTextHighlight = styled("span")`
  font-weight: 600;
`;
