import { useAppTranslation } from "@hooks";
import Dialog from "@components/Alloweat/Dialog/Dialog";
import DialogTitle from "@components/Alloweat/Dialog/DialogTitle";
import { Button, DialogContent, Stack, Tab } from "@mui/material";
import { IconButton } from "@components/Alloweat/IconButton";
import DialogActions from "@components/Alloweat/Dialog/DialogActions";
import React, { Dispatch, useState } from "react";
import BodyMeasurementsFieldsTabs from "@views/dietician/ClientHealth/BodyMeasurementTab/GoalSection/Dialog/BodyMeasurementsFieldsTabs";
import DateMeasurementField from "@views/dietician/ClientHealth/BodyMeasurementTab/GoalSection/Dialog/DateMeasurementField";
import GoalFormWrapper from "@views/dietician/ClientHealth/BodyMeasurementTab/GoalSection/Dialog/GoalForm";
import { DialogSectionLayout } from "@views/dietician/ClientHealth/ClientHealthNew.styled";
import { TabList } from "@components/Alloweat/Tabs/TabList";
import { TabContext } from "@mui/lab";
import { TabPanel } from "@components/Alloweat/Tabs/TabPanel";
import TargetBodyWeightField from "@views/dietician/ClientHealth/BodyMeasurementTab/GoalSection/Dialog/TargetBodyWeightField";
import GoalField from "@views/dietician/ClientHealth/BodyMeasurementTab/GoalSection/Dialog/GoalField";
import { CloseIcon } from "@icons/index";
import { FormCancelButton, FormSubmitButton } from "@components/FormButtons";

type GoalSectionDialogProps = {
  open: boolean;
  setOpen: Dispatch<boolean>;
};

const GoalSectionDialog = ({ open, setOpen }: GoalSectionDialogProps) => {
  const { t } = useAppTranslation();
  const [type, setType] = useState<"goal" | "measurement">("goal");

  return (
    <Dialog open={open} fullWidth PaperProps={{ sx: { maxWidth: "510px" } }}>
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <span>
            {t(
              "patient.health_and_goal.body_measurement_tab.section_goal_modal.title",
            )}
          </span>

          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon color="727272" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <GoalFormWrapper onSuccess={() => setOpen(false)}>
        <DialogContent>
          <DialogSectionLayout>
            <TabContext value={type}>
              <TabList onChange={(_, newValue) => setType(newValue)}>
                <Tab
                  label={t(
                    "patient.health_and_goal.body_measurement_tab.section_goal_modal.goal",
                  )}
                  value="goal"
                />
                <Tab
                  label={t(
                    "patient.health_and_goal.body_measurement_tab.section_goal_modal.measurement",
                  )}
                  value="measurement"
                />
              </TabList>
              <TabPanel value="goal">
                <DialogSectionLayout>
                  <GoalField />
                  <TargetBodyWeightField />
                </DialogSectionLayout>
              </TabPanel>
              <TabPanel value="measurement">
                <DialogSectionLayout>
                  <DateMeasurementField />
                  <BodyMeasurementsFieldsTabs />
                </DialogSectionLayout>
              </TabPanel>
            </TabContext>
          </DialogSectionLayout>
        </DialogContent>
        <DialogActions>
          <FormCancelButton onClick={() => setOpen(false)} />
          <FormSubmitButton />
        </DialogActions>
      </GoalFormWrapper>
    </Dialog>
  );
};

export default GoalSectionDialog;
