import { FormControlLabel, styled } from "@mui/material";
import { styled as muiStyled } from "@mui/material/styles";
import { Button } from "@mui/material";

import { FormTextField } from "@components/FormTextField";

export const VisitModalWrapper = styled("div")`
  display: grid;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 2rem 0;
`;

export const MainSection = styled("div")`
  display: flex;
  flex-direction: column;
  width: 24rem;
  gap: 1.5rem;
`;

export const ClientAddWrapper = styled("div")`
  display: flex;
  gap: 1rem;
  align-items: start;
`;

export const StyledAddBtn = styled(Button)`
  min-width: 0;
  width: 27px;
  height: 24px;
  padding: 0;
  margin-top: 0.5rem;
`;

export const DateTimeWrapper = styled("div")`
  display: flex;
  gap: 1rem;
  & > .MuiFormControlLabel-labelPlacementEnd {
    margin-right: 0;
  }
`;

export const Delimeter = styled("span")`
  width: 296px;
  height: 1px;
  align-self: center;
  background: ${({ theme }) => theme.palette.grey[300]};
  z-index: 1;
`;

export const PriceSection = styled("div")`
  display: flex;
  width: 70%;
  gap: 1rem;
`;

export const StyledTextArea = styled(FormTextField)`
  & legend {
    display: none;
  }

  & label {
    margin-top: 0.5rem;
    position: unset;
  }
`;

export const DecisionBtnsSection = styled("div")`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  position: sticky;
  bottom: 1rem;
  background: ${({ theme }) => theme.colors.whiteBackground};
  width: 250px;
  height: 48px;
  align-items: center;
  margin: 0.5rem auto 0;
  border-radius: 5px;
  z-index: 100;
`;

export const StyledCancelBtn = muiStyled(Button)`
  background-color: ${({ theme }) => theme.palette.grey[300]};
  color: currentColor;
  text-transform: none; 
  font-weight: 600;
  &:hover { 
    background-color: ${({ theme }) => theme.colors.cancelButtonHover};
  }
`;

export const StyledSubmitBtn = styled(Button)`
  text-transform: none;
  font-weight: 600;
  &:hover {
    background-color: ${({ theme }) => theme.colors.submitButtonHover};
  }
`;

export const FormControlLabelStyled = styled(FormControlLabel)`
  min-width: 100px;
`;
