import * as yup from "yup";

import {
  IdDto,
  ImageDto,
  NutrientDto,
  TranslationDto,
  idSchema,
  imageSchema,
  nutrientSchema,
  translationSchema,
} from "@client/common";
import { APIMethods } from "@typeDefinitions";
import { fetchData2, mapKeysToCamelCase } from "@utils/api";
import { ALLOWEAT_CREATOR_VERSION } from "@hooks/queries/diets/creator/useCreatorVersion";

export const fetchCreatorDiet = async (
  dietId: string,
): Promise<FetchCreatorDietResponseDto> => {
  const response = await fetchData2(
    `/dietitian/creator-2/diets/${dietId}`,
    APIMethods.GET,
  );
  const headers =
    typeof response.headers.toJSON === "function"
      ? response.headers.toJSON()
      : {};
  return fetchCreatorDietResponseSchema.validate({
    data: mapKeysToCamelCase(response.data.data),
    headers,
  });
};

export interface CreatorDietDto {
  id: number;
  clinic: IdDto;
  translations: TranslationDto[];
  targetNutrients: NutrientDto[];
  reviewed: boolean;
  showMacros: boolean;
  image: ImageDto | null;
}

export interface FetchCreatorDietResponseDto
  extends yup.InferType<typeof fetchCreatorDietResponseSchema> {
  data: CreatorDietDto;
}
const creatorDietSchema = yup.object<CreatorDietDto>().shape({
  clinic: idSchema.required(),
  id: yup.number().required(),
  translations: yup.array().of(translationSchema).required(),
  targetNutrients: yup.array().of(nutrientSchema).required(),
  reviewed: yup.boolean().required(),
  showMacros: yup.boolean().required(),
  image: imageSchema.nullable().default(null),
});

export const fetchCreatorDietResponseSchema = yup.object().shape({
  data: creatorDietSchema.required(),
  headers: yup.object().shape({
    [ALLOWEAT_CREATOR_VERSION]: yup.string().required(),
  }),
});
