import { Button, ButtonProps, Stack, styled } from "@mui/material";
import { useAppTranslation } from "@hooks";
import { Dispatch, useEffect, useMemo } from "react";
import { useFetchBodyMeasurementsQuery } from "@hooks/queries/dictionaries";
import { getTranslation } from "@utils/dictionaries/bodyMeasurementsDictionaryUtils";
import { BodyMeasurementConst } from "@consts/BodyMeasurementConst";
import { BodyMeasurementMonitoringResource } from "@client/resources/BodyMeasurementMonitoringResource";

type MeasurementsBodyTabsProps = {
  type: number | null;
  setType: Dispatch<number | null>;
  measurements: BodyMeasurementMonitoringResource | null;
};

const MeasurementsBodyTabs = ({
  type,
  setType,
  measurements,
}: MeasurementsBodyTabsProps) => {
  const { currentLanguage } = useAppTranslation();
  const { data: bodyMeasurements } = useFetchBodyMeasurementsQuery();

  const bodyMeasurementsFiltered = useMemo(() => {
    if (!bodyMeasurements) {
      return [];
    }

    return bodyMeasurements.data.filter(
      bodyMeasurement => bodyMeasurement.id !== BodyMeasurementConst.height,
    );
  }, [bodyMeasurements]);

  useEffect(() => {
    if (!bodyMeasurementsFiltered.length) {
      return;
    }

    setType(bodyMeasurementsFiltered[0]?.id ?? null);
  }, [bodyMeasurementsFiltered]);

  const getValue = (bodyId: number) => {
    if (!measurements) {
      return null;
    }

    const bodyMeasurement = measurements.measurements?.bodyMeasurements.find(
      bodyMeasurement => bodyMeasurement.body.id === bodyId,
    );
    return bodyMeasurement?.value ?? null;
  };

  return (
    <Stack
      spacing="8px"
      direction="row"
      overflow="auto"
      className="hide-scrollbar"
    >
      {bodyMeasurementsFiltered.map(bodyMeasurement => {
        const value = getValue(bodyMeasurement.id);
        const name =
          getTranslation(bodyMeasurement, currentLanguage)?.name +
          (value ? ` - ${value} ${bodyMeasurement.unit}` : "");

        if (type === bodyMeasurement.id) {
          return <ActiveButton key={bodyMeasurement.id}>{name}</ActiveButton>;
        }

        return (
          <CustomButton
            key={bodyMeasurement.id}
            size="small"
            onClick={() => setType(bodyMeasurement.id)}
          >
            {name}
          </CustomButton>
        );
      })}
    </Stack>
  );
};

const CustomButton = styled(Button)<ButtonProps>({
  "boxShadow": "none",
  "textTransform": "none",
  "fontSize": 12,
  "padding": "4px 8px",
  "border": "1px solid",
  "lineHeight": "20px",
  "backgroundColor": "#fff",
  "borderColor": "#F1F1F1",
  "borderRadius": "8px",
  "color": "#727272",
  "minWidth": "auto",
  "height": "auto",
  "fontFamily": ["Figtree", "serif"].join(","),
  "&:hover": {
    backgroundColor: "#F5F1FE",
    borderColor: "#F1F1F1",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#F5F1FE",
    borderColor: "#7448D0",
  },
  "&:focus": {
    boxShadow: "none",
  },
  "whiteSpace": "nowrap",
});

const ActiveButton = styled(CustomButton)<ButtonProps>({
  "backgroundColor": "#F5F1FE",
  "borderColor": "#7448D0",
  "color": "#7448D0",
  "&:hover": {
    backgroundColor: "#F5F1FE",
    borderColor: "#7448D0",
  },
  "&:active": {
    backgroundColor: "#F5F1FE",
    borderColor: "#7448D0",
  },
});

export default MeasurementsBodyTabs;
