import { NutritionDetails } from "@typeDefinitions";
import {
  ProductsFiltersInput,
  RecipesFiltersInput,
} from "@typeDefinitions/types";

export const SEXES = ["w", "m", "other"];

export const VARIANTS = {
  visible: (i: number) => ({
    opacity: 1,
    transition: {
      delay: i * 0.05,
    },
  }),
  hidden: { opacity: 0 },
};

export const NUTRITION_NAMES_MAP: Record<number, NutritionDetails> = {
  208: {
    order: 1,
    title: "Kalorie",
    titleEn: "Calories",
    units: "kcal",
    unitsEn: "cals",
    shortName: "E",
    shortNameEn: "E",
  },
  203: {
    order: 2,
    title: "Białka",
    titleEn: "Protein",
    units: "g",
    unitsEn: "g",
    shortName: "B",
    shortNameEn: "P",
  },
  204: {
    order: 3,
    title: "Tłuszcze",
    titleEn: "Fats",
    units: "g",
    unitsEn: "g",
    shortName: "T",
    shortNameEn: "F",
  },
  205: {
    order: 4,
    title: "Węgle",
    titleEn: "Carbs",
    units: "g",
    unitsEn: "g",
    shortName: "W",
    shortNameEn: "C",
  },
};

export type DefaultDietMealType = {
  title: string;
  titleEn: string;
  defaultRecipeFilters?: RecipesFiltersInput;
  defaultProductFilters?: ProductsFiltersInput;
};

export const DIET_MEALS: DefaultDietMealType[] = [
  {
    title: "Śniadanie",
    titleEn: "Breakfast",
    defaultRecipeFilters: {
      type_tags: [1],
    },
  },
  {
    title: "Drugie śniadanie",
    titleEn: "Second breakfast",
    defaultRecipeFilters: {
      type_tags: [2],
    },
  },
  {
    title: "Obiad",
    titleEn: "Main course",
    defaultRecipeFilters: {
      type_tags: [63],
    },
  },
  {
    title: "Przekąska",
    titleEn: "Snack",
    defaultRecipeFilters: {
      type_tags: [61],
    },
  },
  {
    title: "Kolacja",
    titleEn: "Dinner",
    defaultRecipeFilters: {
      type_tags: [54],
    },
  },
  {
    title: "Przedtreningowy",
    titleEn: "Before training",
    defaultRecipeFilters: {
      type_tags: [215],
    },
  },
  {
    title: "Podczas treningu",
    titleEn: "During training",
    defaultRecipeFilters: {
      type_tags: [216],
    },
  },
  {
    title: "Potreningowy",
    titleEn: "After training",
    defaultRecipeFilters: {
      type_tags: [111],
    },
  },
  {
    title: "Suplementacja",
    titleEn: "Suplementation",
    defaultRecipeFilters: {
      type_tags: [223],
    },
  },
  {
    title: "Przekąska II",
    titleEn: "Snack II",
    defaultRecipeFilters: {
      type_tags: [61],
    },
  },
  {
    title: "Lunch",
    titleEn: "Lunch",
    defaultRecipeFilters: {
      type_tags: [62],
    },
  },
];

export const COLORS = ["#801FFF", "#A46BFC", "#FB931F", "#5169FB", "#46BB63"];
export const TAG_COLORS = [
  "#9D65FF",
  "#3FBCB9",
  "#A7B20E",
  "#EB7093",
  "#67A9FA",
  "#2D479D",
  "#FB931F",
];

export const WEEKDAYS_MAP = {
  0: "Niedziela",
  1: "Poniedziałek",
  2: "Wtorek",
  3: "Środa",
  4: "Czwartek",
  5: "Piątek",
  6: "Sobota",
  7: "Niedziela",
};

export enum MICRO_COLORS {
  CARBS = "#B07EF2",
  LIPIDS = "#FFCC24",
  MINERALS = "#88D89C",
  VITAMINS = "#5169FB",
  OTHERS = "#FB931F",
  ENERGY = "#3FBCB9",
  MACROS = "#A7B20E",
}

export const MICRO_CATEGORY_COLORS: Record<number, string> = {
  1: MICRO_COLORS.ENERGY,
  2: MICRO_COLORS.CARBS,
  3: MICRO_COLORS.MINERALS,
  4: MICRO_COLORS.VITAMINS,
  5: MICRO_COLORS.OTHERS,
  6: MICRO_COLORS.LIPIDS,
  7: MICRO_COLORS.MACROS,
};

export const WEEKDAYS_MAP_EN = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  7: "Sunday",
};

export const getCategoryColor = (id: number | undefined) => {
  if (id && MICRO_CATEGORY_COLORS[id]) return MICRO_CATEGORY_COLORS[id];
  return;
};

export const LARGE_DESKTOP_MAX_CONTENT_WIDTH = "111.5rem";
export const TABLET_MAX_CONTENT_WIDTH = "39.75rem";
export const LAPTOP_MAX_CONTENT_WIDTH = "68.5rem";
export const FIRST_RENDER_PARAM = "?index=true";

export enum BinaryAnswer {
  NO = "0",
  YES = "1",
}

export const SAFARI_TAB_INDEX = -1;

export enum WHATS_NEW_TYPE {
  NEW = 1,
  ADDS = 2,
  DEVELOPMENT = 3,
  INCOMING = 4,
  ALL = 5,
}
