import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "../types";
import { fetchPatientVisitsQueryKey } from "./useFetchPatientVisitsQuery";
import { fetchVisitFilesQueryKey } from "./useFetchVisitFilesQuery";
import { uploadVisitFile } from "@client/visits";

export const useUploadVisitFileMutation = (
  patientId: number,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: FormData) => uploadVisitFile(patientId, payload),
    {
      ...options,
      onSuccess() {
        queryClient.invalidateQueries([fetchPatientVisitsQueryKey, patientId]);
        queryClient.invalidateQueries([fetchVisitFilesQueryKey, patientId]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};
