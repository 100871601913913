import { useQuery } from "@tanstack/react-query";
import { QueryOptionsTyped } from "../types";
import { buildDefaultQueryOptions } from "../common";
import { fetchClinicCurrencies } from "@client/settings";

export const fetchClinicCurrenciesQueryKey = "fetchClinicCurrencies";

export function useFetchClinicCurrencies(options?: QueryOptionsTyped<any>) {
  const { data, ...rest } = useQuery(
    [fetchClinicCurrenciesQueryKey],
    fetchClinicCurrencies,
    buildDefaultQueryOptions(options),
  );

  return { currencies: data?.data, ...rest };
}
