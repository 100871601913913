import { NutrientDto, nutrientSchema } from "@client/common";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import * as yup from "yup";
import { ClientContextDto, clientContextSchema } from "./creator";

export const searchDietProductsForReplacement = async (
  params?: URLSearchParams,
): Promise<
  ApiResponse<ProductReplacementDto[] | ProductReplacementClientContextDto[]>
> => {
  const response = await fetchData(
    `/dietitian/diet/products-for-replacement-search`,
    APIMethods.GET,
    undefined,
    params,
  );

  return responseSchema(params).validate(response);
};

interface TranslationLangDto {
  lang: string;
  name: string;
}
export interface ProductReplacementDto {
  id: number;
  name: string;
  translations: TranslationLangDto[];
  nutrients: NutrientDto[];
}
export interface ProductReplacementClientContextDto
  extends ProductReplacementDto {
  clientContext: ClientContextDto;
}

const translationLangSchema = yup.object({
  lang: yup.string().required(),
  name: yup.string().ensure(),
});

export const productReplacementSchema = yup.object({
  id: yup.number().required(),
  name: yup.string().required(),
  nutrients: yup.array().of(nutrientSchema).required(),
  translations: yup.array().of(translationLangSchema).required(),
});

const responseSchema = (params?: URLSearchParams) => {
  const hasClient = params?.has("client");

  return yup.object({
    data: yup
      .array()
      .of(
        yup.object({
          id: yup.number().required(),
          name: yup.string().required(),
          nutrients: yup.array().of(nutrientSchema).required(),
          translations: yup.array().of(translationLangSchema).required(),
          ...(hasClient
            ? { clientContext: clientContextSchema.required() }
            : {}),
        }),
      )
      .required(),
  });
};
