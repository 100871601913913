import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import weekday from "dayjs/plugin/weekday";
import isToday from "dayjs/plugin/isToday";
import isBetween from "dayjs/plugin/isBetween";
import i18next from "i18next";
import "dayjs/locale/pl";

dayjs.extend(relativeTime);
dayjs.extend(weekday);
dayjs.extend(isToday);
dayjs.extend(isBetween);
dayjs.locale(i18next.language === "pl" ? "pl" : "en");

export default dayjs;
