import { useParseUrlParams } from "@hooks";
import { useEffect, useState } from "react";
import { ClinicClientsFiltersInput } from "@typeDefinitions/types";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

export const useClientsFiltersForm = (
  defaultValues?: ClientsFiltersInputNew,
) => {
  const form = useForm<ClientsFiltersInputNew>({
    defaultValues: { programs: [], dietitians: [] },
  });

  const location = useLocation();

  const [init, setInit] = useState(true);

  useEffect(() => {
    const [params, paramsLength] = useParseUrlParams(location.search);

    if (!paramsLength) return;

    form.reset({
      programs: [],
      dietitians: [],
      ...params,
    });
  }, []);

  useEffect(() => {
    if (init && defaultValues) {
      form.reset({
        ...defaultValues,
      });
      setInit(false);
    }
  }, [defaultValues]);

  return form;
};

export interface ClientsFiltersInputNew {
  search?: string;
  client?: "active-subscription" | "inactive-subscription" | "all";
  programs?: string[];
  appointment?: number;
  activeMobileApp?: number;
  dietitians: string[];
  customTags: string[];
}

export const mapPatientsFiltersParams = (
  data: ClientsFiltersInputNew,
): ClinicClientsFiltersInput => {
  return {
    search: data.search ?? undefined,
    client: data.client,
    programs: data.programs,
    appointment: data.appointment,
    activeMobileApp: data.activeMobileApp ? 1 : undefined,
    dietitians: data.dietitians,
    customTags: data.customTags,
  };
};
