import { useMutation, useQueryClient } from "@tanstack/react-query";

import { createPatientProgram, CreatePatientProgramResponseDto } from "@client";
import { MutationOptions } from "./types";
import { fetchPatientProgramQueryKey } from "./useFetchPatientProgramQuery";
import { fetchProgramQueryKey } from "./useFetchProgramQuery";

export const useCreatePatientProgramMutation = (
  patientId: number,
  options?: MutationOptions<CreatePatientProgramResponseDto>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    () =>
      createPatientProgram(patientId, {
        name: "",
        hasDiary: true,
        showNutrients: [],
        sections: [],
        startDate: "",
        finishDate: null,
      }),
    {
      ...options,
      onSuccess: data => {
        queryClient.invalidateQueries([[fetchPatientProgramQueryKey]]);
        queryClient.invalidateQueries([fetchProgramQueryKey, data?.id]);
        options?.onSuccess && options.onSuccess(data);
      },
    },
  );
};
