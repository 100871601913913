import { NormNutrientDto, ProgramDayNutrientDto } from "@client";
import { NutrientCategory } from "@typeDefinitions";

export const nutrientCatgoriesMapper = (
  categories: NutrientCategory[],
  normNutrients: NormNutrientDto[],
  programNutrients: ProgramDayNutrientDto[] | undefined,
  checkedNutrients: number[] | undefined,
) => {
  return categories
    .flatMap(nutrient => nutrient.nutrients)
    .map(nutrient => {
      const nutrientValue = normNutrients.find(({ id }) => id === nutrient.id);
      const programNutrientValue = programNutrients?.find(
        ({ id }) => id === nutrient.id,
      );
      return {
        id: nutrient.id,
        value: programNutrientValue?.value ?? nutrientValue?.value ?? 0,
        visible:
          programNutrientValue?.visible ??
          !!checkedNutrients?.includes(nutrient.id),
      };
    });
};
