import { FieldValues, Path, UseFormReturn, useWatch } from "react-hook-form";

import { RangeFilterDefinition } from "@typeDefinitions";
import { FormOutlinedInput } from "@components/FormOutlinedInput";
import { useAppTranslation } from "@hooks";

import { BaseFilterSelect } from "./BaseFilterSelect";
import { InputsContainer } from "./RangeFilter.styled";
import { decimalInput0 } from "@utils/inputs";

interface SingleFilterProps<T extends FieldValues> {
  form: UseFormReturn<T>;
  filter: RangeFilterDefinition<T>;
}

export const RangeFilter = <T extends FieldValues>({
  form: { control },
  filter: { name, label },
}: SingleFilterProps<T>) => {
  const { t } = useAppTranslation();
  const nameTo = `${name}.to` as Path<T>;
  const nameFrom = `${name}.from` as Path<T>;
  const fromNumb = useWatch({ control, name: nameFrom }) as Path<T>;
  const toNumb = useWatch({ control, name: nameTo }) as Path<T>;

  return (
    <BaseFilterSelect name={label} selected={!!fromNumb || !!toNumb}>
      <InputsContainer>
        <div className="col-span-4">
          <FormOutlinedInput
            control={control}
            placeholder={t("common.from")}
            name={nameFrom}
            size="small"
            centeredText
            inputComponent={decimalInput0}
          />
        </div>
        <div className="col-span-1 text-center">-</div>
        <div className="col-span-4">
          <FormOutlinedInput
            control={control}
            placeholder={t("common.to")}
            centeredText
            name={nameTo}
            size="small"
            inputComponent={decimalInput0}
          />
        </div>
      </InputsContainer>
    </BaseFilterSelect>
  );
};
