import { Dialog, styled } from "@mui/material";

export const DialogStyled = styled(Dialog)`
  & .MuiPaper-root {
    padding: 1rem 0.5rem;
  }
  & .MuiPaper-root > * {
    padding: 0.5rem 1.5rem;
  }
`;

export const InputWrapper = styled("div")`
  padding-top: 0.5rem;
  min-width: 24.125rem;
`;
