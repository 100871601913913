import { useCallback, useRef } from "react";
import { getPdfSectionClientSide } from "@Pdf/utils/pdfUtils";
import { NutrientDetails, useAppTranslation } from "@hooks";
import { usePdfWorker } from "@Pdf/hooks/usePdfWorker";
import { PdfSectionResponseType } from "@Pdf/types/pdfTypes";
import { PDF_COLORS, PDF_DISPLAY_CODE } from "@Pdf/defaults/pdfDefaults";
import i18n from "@services/i18n";
import { LanguagesSlugs, NutrientCategory } from "@typeDefinitions";
import { useQueryClient } from "@tanstack/react-query";
import {
  fetchNutrientCategoriesQueryKey,
  useFetchNutrientCategoriesQuery,
} from "@hooks/queries";
import dayjs from "dayjs";
import { useFetchClinicNameQuery } from "@hooks/queries/settings";

const usePdfSectionPreviewExport = () => {
  const queryClient = useQueryClient();
  const cacheData = queryClient.getQueryData<NutrientCategory[]>([
    fetchNutrientCategoriesQueryKey,
  ]);
  const { nutrientCategories } = useFetchNutrientCategoriesQuery({
    enabled: !cacheData,
  });
  const { name } = useFetchClinicNameQuery();
  const categories = cacheData ?? nutrientCategories;
  const { t } = useAppTranslation();

  const abortController = useRef(new AbortController());

  const { generateSectionPdf, terminateWorker } = usePdfWorker();

  const onCancelExport = () => {
    abortController.current.abort();
    terminateWorker();
  };

  const onExport = useCallback(
    async (
      section: PdfSectionResponseType,
      download = false,
      lngId = i18n.language,
    ) => {
      const isPolishChosen = lngId === LanguagesSlugs.PL;
      const nutrientDict: Record<number, NutrientDetails> = {};

      categories
        ?.flatMap(category => category.nutrients)
        .forEach(nutrient => {
          nutrientDict[nutrient.id] = {
            name: isPolishChosen
              ? nutrient.descriptionPl
              : nutrient.descriptionEn,
            unit: nutrient.units,
            short: isPolishChosen
              ? nutrient.shortNamePl ?? nutrient.descriptionPl.charAt(0)
              : nutrient.shortNameEn ?? nutrient.descriptionEn.charAt(0),
            categoryId: nutrient.nutrientCategoryId,
          };
        });

      const tOptions = { lng: lngId };

      const weekdays = [
        t("pdf.week_days.monday_short", tOptions),
        t("pdf.week_days.tuesday_short", tOptions),
        t("pdf.week_days.wednesday_short", tOptions),
        t("pdf.week_days.thursday_short", tOptions),
        t("pdf.week_days.friday_short", tOptions),
        t("pdf.week_days.saturday_short", tOptions),
        t("pdf.week_days.sunday_short", tOptions),
      ];

      const translations = {
        servings: t("pdf.servings", tOptions),
        serving: t("pdf.serving", tOptions),
        proposition: t("pdf.proposition"),
        day: t("pdf.day"),
        wayToPrepare: t("pdf.way_to_prepare"),
        product: t("pdf.product"),
        mass: t("pdf.mass"),
        measure: t("pdf.measure"),
        shopping: t("pdf.shopping"),
        macronutrientBreakdown: t("pdf.macronutrient_breakdown"),
        page: t("pdf.page"),
        leadingDietitian: t("pdf.leading_dietitian"),
        localisation: t("pdf.localisation"),
        mealComment: t("pdf.meal_comment"),
        ingredientsList: t("pdf.ingredients_list"),
        tableOfContent: t("pdf.table_of_content"),
        emptyProgram: t("pdf.emptyProgram", tOptions),
        // Weekdays! - used in food menu
        1: t("pdf.week_days.monday_long", tOptions),
        2: t("pdf.week_days.tuesday_long", tOptions),
        3: t("pdf.week_days.wednesday_long", tOptions),
        4: t("pdf.week_days.thursday_long", tOptions),
        5: t("pdf.week_days.friday_long", tOptions),
        6: t("pdf.week_days.saturday_long", tOptions),
        7: t("pdf.week_days.sunday_long", tOptions),
      };

      const { imageRefs, htmlContentCache } = await getPdfSectionClientSide(
        section,
      );

      const content = await generateSectionPdf({
        content: section,
        patient: {
          firstName: t("pdf.clientFirstName"),
          lastName: t("pdf.clientLastName"),
        },
        clinic: {
          name: "Dietetyka",
          contact: {
            email: "",
            phone: "+48 567 211 874",
          },
          address: {
            address: "ul. Kwiatowa 3",
            zipCode: "30-201",
            city: "Warszawa",
          },
        },
        dietitian: {
          firstName: "",
          lastName: "",
        },
        htmlContentCache,
        imageRefs,
        nutrientDict,
        primaryColor: PDF_COLORS.PRIMARY,
        weekdays,
        options: [
          PDF_DISPLAY_CODE.WEEKDAY,
          PDF_DISPLAY_CODE.DATE,
          PDF_DISPLAY_CODE.MEAL_HOUR,
          PDF_DISPLAY_CODE.DAY_TYPE,
          PDF_DISPLAY_CODE.LOGO,
        ],
        clinicOptions: [
          PDF_DISPLAY_CODE.CLINIC_NAME,
          PDF_DISPLAY_CODE.CLINIC_ADDRESS,
          PDF_DISPLAY_CODE.CLINIC_EMAIL,
          PDF_DISPLAY_CODE.CLINIC_PHONE,
        ],
        translations,
        download: false,
        kcal: false,
        macros: false,
        micros: [],
      });

      if (download) {
        const today = dayjs().format("DD-MM-YYYY");
        const file = new Blob([content], { type: "application/octet-stream" });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = `${section.name.replaceAll(
          " ",
          "-",
        )}-${today}-${name?.name.replaceAll(" ", "-")}.pdf`;
        link.click();
        link.remove();
      }

      return content;
    },
    [t, categories],
  );

  return {
    onCancelExport,
    onExport,
  };
};

export default usePdfSectionPreviewExport;
