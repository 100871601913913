import { Plus } from "@assets/icons";
import { ListElement } from "./MealTypeTexField.styled";
import { useAppTranslation } from "@hooks";

interface AddOwnTypeProps {
  onClick: () => void;
}
export const AddOwnType = ({ onClick }: AddOwnTypeProps) => {
  const { t } = useAppTranslation();
  return (
    <ListElement onClick={onClick}>
      <Plus size="w-[10px] h-[10px]" />
      {t("diet.add_own")}
    </ListElement>
  );
};
