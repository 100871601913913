import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const SettingsModalWrapper = styled.div`
  display: grid;
  width: 8rem;
`;

export const StyledButton = styled(Button)`
  justify-content: start;
  padding: 0 1rem;
  font-weight: 400;
`;
