import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

import { ClientContextDto, clientContextSchema } from "./diets/creator";

export const postPatientProductsContext = async (
  patientId: string,
  payload: number[],
): Promise<ApiResponse<ProductContextDto[]>> => {
  const response = await fetchData(
    `/dietitian/patients/${patientId}/products-context`,
    APIMethods.POST,
    { ids: payload },
  );

  return postPatientProductsContextResponseSchema.validate(response);
};

export interface ProductContextDto {
  id: number;
  clientContext: ClientContextDto;
}

const recipeContextSchema = yup.object({
  id: yup.number().required(),
  clientContext: clientContextSchema.required(),
});
const postPatientProductsContextResponseSchema = yup.object({
  data: yup.array().of(recipeContextSchema).required(),
});
