import { useEffect, useState } from "react";
import { useAppTranslation } from "@hooks";
import { Control, FieldValues, Path, useFormState } from "react-hook-form";
import { LanguagesSlugs } from "@typeDefinitions";
import { SwitchSection } from "./FormMultilangDescriptionEditor.styled";
import { RadioTileGroup } from "@components/RadioTileGroup";
import { RadioTile } from "@components/RadioTile";
import { FormTextarea } from "@components/FormTextarea";

interface FormMultilangDescriptionEditorProps<T extends FieldValues> {
  control: Control<T>;
  namePl: Path<T>;
  nameEn: Path<T>;
  recipeNote?: boolean;
  label?: boolean;
  langOverride?: LanguagesSlugs.PL | LanguagesSlugs.EN;
}

export const FormMultilangDescriptionEditor = <T extends FieldValues>({
  control,
  namePl,
  nameEn,
  recipeNote,
  label = true,
  langOverride,
}: FormMultilangDescriptionEditorProps<T>) => {
  const { t } = useAppTranslation();
  const [descLang, setDescLang] = useState(LanguagesSlugs.PL);
  const formState = useFormState({ control, name: [namePl, nameEn] });
  const plErrors = formState.errors[namePl as unknown as keyof T];
  const enErrors = formState.errors[nameEn as unknown as keyof T];

  useEffect(() => {
    if (plErrors) {
      setDescLang(LanguagesSlugs.PL);
    } else if (enErrors) {
      setDescLang(LanguagesSlugs.EN);
    }
  }, [plErrors, enErrors]);

  const effectiveLang = langOverride ? langOverride : descLang;

  return (
    <div className={recipeNote ? "col-span-12" : "col-span-6"}>
      {!langOverride && (
        <SwitchSection>
          {label && (
            <p className="font-sans mr-4">
              {recipeNote ? t("recipe_new.preparation") : t("meals.note")}
            </p>
          )}
          <RadioTileGroup
            variant="outlined"
            name="descriptionLang"
            onChange={v => setDescLang(v as LanguagesSlugs)}
            defaultValue={LanguagesSlugs.PL}
          >
            <RadioTile label="PL" value={LanguagesSlugs.PL} />
            <RadioTile label="EN" value={LanguagesSlugs.EN} />
          </RadioTileGroup>
        </SwitchSection>
      )}

      {effectiveLang === LanguagesSlugs.PL ? (
        <FormTextarea
          fullWidth
          key={namePl}
          name={namePl}
          style={{ height: recipeNote ? 125 : 50 }}
          control={control}
          placeholder={`1...\n2...`}
        />
      ) : (
        <FormTextarea
          fullWidth
          key={nameEn}
          name={nameEn}
          style={{ height: recipeNote ? 125 : 50 }}
          control={control}
          placeholder={`1...\n2...`}
        />
      )}
    </div>
  );
};
