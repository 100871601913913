import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Upload = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16" height="17" viewBox="0 0 16 17" {...props}>
      <path
        d="M9 3.91501V11.4998C9 12.0529 8.55313 12.4998 8 12.4998C7.44688 12.4998 7 12.0529 7 11.4998V3.91501L4.70625 6.20887C4.31563 6.59952 3.68125 6.59952 3.29063 6.20887C2.9 5.81823 2.9 5.18382 3.29063 4.79318L7.29063 0.792983C7.68125 0.402339 8.31563 0.402339 8.70625 0.792983L12.7063 4.79318C13.0969 5.18382 13.0969 5.81823 12.7063 6.20887C12.3156 6.59952 11.6812 6.59952 11.2906 6.20887L9 3.91501ZM2 11.4998H6C6 12.6029 6.89687 13.4999 8 13.4999C9.10312 13.4999 10 12.6029 10 11.4998H14C15.1031 11.4998 16 12.3967 16 13.4999V14.4999C16 15.6031 15.1031 16.5 14 16.5H2C0.896875 16.5 0 15.6031 0 14.4999V13.4999C0 12.3967 0.896875 11.4998 2 11.4998ZM13.5 14.7499C13.6989 14.7499 13.8897 14.6709 14.0303 14.5302C14.171 14.3896 14.25 14.1988 14.25 13.9999C14.25 13.801 14.171 13.6102 14.0303 13.4695C13.8897 13.3289 13.6989 13.2498 13.5 13.2498C13.3011 13.2498 13.1103 13.3289 12.9697 13.4695C12.829 13.6102 12.75 13.801 12.75 13.9999C12.75 14.1988 12.829 14.3896 12.9697 14.5302C13.1103 14.6709 13.3011 14.7499 13.5 14.7499Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
