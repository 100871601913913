import { useState } from "react";
import { Stack } from "@mui/material";
import ChoiceButton from "@views/dietician/PatientProgramChoice/ChoiceButton/ChoiceButton";
import CancelButton from "@views/dietician/PatientProgramChoice/CancelButton";
import PatientProgramTitle from "@views/dietician/PatientProgramChoice/PatientProgramTitle/PatientProgramTitle";
import { ThemeProviderWrapperNew } from "../../../themeNew";
import PatientProgramChoiceGridFilterForm from "@views/dietician/PatientProgramChoice/PatientProgramChoiceGridFilterForm";
import PatientProgramChoiceGridFilter from "@views/dietician/PatientProgramChoice/PatientProgramChoiceGridFilter";

export type SelectedProgramType = {
  id: number;
  startDate: string;
  endDate: string | null;
} | null;
const PatientProgramChoice = () => {
  const [selectedProgram, setSelectedProgram] =
    useState<SelectedProgramType>(null);

  return (
    <ThemeProviderWrapperNew>
      <Stack direction="column" gap="16px">
        <PatientProgramTitle />
        <PatientProgramChoiceGridFilterForm>
          <PatientProgramChoiceGridFilter
            setSelectedProgram={setSelectedProgram}
            selectedProgram={selectedProgram}
          />
        </PatientProgramChoiceGridFilterForm>
        <Stack
          position="sticky"
          bottom="0px"
          direction="row"
          justifyContent="end"
          gap="8px"
          sx={{
            background: "#FBFAFC",
            padding: "16px 12px",
            borderRadius: "8px",
          }}
        >
          <CancelButton />
          <ChoiceButton selectedProgram={selectedProgram} />
        </Stack>
      </Stack>
    </ThemeProviderWrapperNew>
  );
};

export default PatientProgramChoice;
