import React from "react";
import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Diets = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 46 46">
    <g
      id="_46-icon-diets"
      data-name="46-icon-diets"
      transform="translate(-385 -483)"
    >
      <rect
        id="Rectangle_1169"
        data-name="Rectangle 1169"
        width="46"
        height="46"
        transform="translate(385 483)"
        fill="#fff"
        opacity="0"
      />
      <g
        id="Group_4086"
        data-name="Group 4086"
        transform="translate(-371.748 -310.146)"
      >
        <path
          id="Path_537"
          data-name="Path 537"
          d="M768.84,846.3a6.922,6.922,0,0,1-3.714-1.309,14.919,14.919,0,0,1-3.457-3.308A17.075,17.075,0,0,1,758,831.544c0-4.839,1.656-7.75,3.046-9.339a7.652,7.652,0,0,1,5.418-2.869,9.593,9.593,0,0,1,5.575,1.509,3.071,3.071,0,0,0,1.587.618.75.75,0,0,1,0,1.5,4.388,4.388,0,0,1-2.354-.828,8.171,8.171,0,0,0-4.809-1.3,6.226,6.226,0,0,0-4.289,2.356c-1.22,1.4-2.675,3.978-2.675,8.351a15.551,15.551,0,0,0,3.359,9.226c1.89,2.45,4.239,4.033,5.983,4.033.412,0,.821-.016,1.215-.047a.75.75,0,1,1,.118,1.5C769.74,846.285,769.291,846.3,768.84,846.3Z"
          transform="translate(0 -15.594)"
        />
        <path
          id="Path_538"
          data-name="Path 538"
          d="M816.861,823.753a.749.749,0,0,1-.585-.28,6.463,6.463,0,0,0-4.524-2.637,8.172,8.172,0,0,0-4.809,1.3,4.387,4.387,0,0,1-2.354.828.75.75,0,0,1,0-1.5,3.072,3.072,0,0,0,1.587-.618,9.594,9.594,0,0,1,5.575-1.509,6.233,6.233,0,0,1,2.814.779,9.375,9.375,0,0,1,2.879,2.418.75.75,0,0,1-.584,1.22Z"
          transform="translate(-30.965 -15.594)"
        />
        <path
          id="Path_539"
          data-name="Path 539"
          d="M796.36,813.762a.75.75,0,0,1-.739-.629,9.147,9.147,0,0,0-2.081-4.853,3.959,3.959,0,0,0-1.439-1.06l.008,0,.443-1.433c.146.045,3.582,1.174,4.549,7.1a.751.751,0,0,1-.741.871Z"
          transform="translate(-22.685 -6.421)"
        />
        <path
          id="Path_540"
          data-name="Path 540"
          d="M810.03,796.25a.75.75,0,0,1,.719.963,7.3,7.3,0,0,0-.008,3.6c.2,1.145-.771,2.446-3.052,4.093a31.921,31.921,0,0,1-3.088,1.947.75.75,0,0,1-1.107-.659,10.957,10.957,0,0,1,2.66-7.749,6.493,6.493,0,0,1,3.832-2.189Zm-4.974,8.6c.518-.317,1.148-.723,1.766-1.169,2.543-1.838,2.447-2.581,2.442-2.612a10.541,10.541,0,0,1-.211-2.97,6.444,6.444,0,0,0-1.8,1.366A8.91,8.91,0,0,0,805.056,804.848Z"
          transform="translate(-30.732 0)"
        />
        <path
          id="Path_541"
          data-name="Path 541"
          d="M866.281,826.642a.75.75,0,0,1-.691-1.042l1.257-2.98a.75.75,0,0,1,1.153-.3l2.377,1.857a.75.75,0,0,1-.924,1.182l-1.6-1.254-.876,2.077A.75.75,0,0,1,866.281,826.642Z"
          transform="translate(-72.637 -17.503)"
        />
        <path
          id="Path_542"
          data-name="Path 542"
          d="M882.072,841.071a.75.75,0,0,1-.292-1.441l2.076-.875-1.254-1.6a.75.75,0,0,1,1.182-.924l1.858,2.377a.75.75,0,0,1-.3,1.153l-2.979,1.256A.748.748,0,0,1,882.072,841.071Z"
          transform="translate(-83.303 -26.809)"
        />
        <path
          id="Path_543"
          data-name="Path 543"
          d="M874.189,831.593a.75.75,0,0,1-.472-1.334l2.923-2.36-1.222-1.221-2.379,2.909a.75.75,0,0,1-1.161-.95l2.9-3.551a.75.75,0,0,1,1.111-.056l2.4,2.4a.75.75,0,0,1-.059,1.114l-3.572,2.884A.747.747,0,0,1,874.189,831.593Z"
          transform="translate(-76.809 -19.293)"
        />
        <path
          id="Path_544"
          data-name="Path 544"
          d="M802.132,862.4a2.217,2.217,0,0,1-1.851-1.11,1.922,1.922,0,0,1-.061-1.922l.01-.018,11.424-19.394a7.879,7.879,0,0,1,6.583-3.727,6.022,6.022,0,0,1,1.64.221l.19.054a8.854,8.854,0,0,1,6.054,6.205,6.929,6.929,0,0,1-.567,4.6,7.445,7.445,0,0,1-2.963,3.338l-19.552,11.512-.018.01A1.817,1.817,0,0,1,802.132,862.4Zm-.6-2.3a.438.438,0,0,0,.044.428.727.727,0,0,0,.559.372.328.328,0,0,0,.156-.039l19.549-11.51.018-.01a6.191,6.191,0,0,0,2.811-6.262,7.389,7.389,0,0,0-5.008-5.131l-.19-.054a4.525,4.525,0,0,0-1.232-.165,6.385,6.385,0,0,0-5.275,2.962l-.01.018Z"
          transform="translate(-28.365 -27.004)"
        />
        <path
          id="Line_262"
          data-name="Line 262"
          d="M4.133,4.884a.748.748,0,0,1-.53-.22L-.53.53A.75.75,0,0,1-.53-.53.75.75,0,0,1,.53-.53L4.664,3.6a.75.75,0,0,1-.53,1.28Z"
          transform="translate(783.734 813.674)"
        />
        <path
          id="Line_263"
          data-name="Line 263"
          d="M3,3.746a.748.748,0,0,1-.53-.22l-3-3A.75.75,0,0,1-.53-.53.75.75,0,0,1,.53-.53l3,3A.75.75,0,0,1,3,3.746Z"
          transform="translate(781.887 816.811)"
        />
        <path
          id="Line_264"
          data-name="Line 264"
          d="M2.769,3.519a.748.748,0,0,1-.53-.22L-.53.53A.75.75,0,0,1-.53-.53.75.75,0,0,1,.53-.53L3.3,2.239a.75.75,0,0,1-.53,1.28Z"
          transform="translate(782.531 824.81)"
        />
        <path
          id="Line_265"
          data-name="Line 265"
          d="M1.86,2.61a.748.748,0,0,1-.53-.22L-.53.53A.75.75,0,0,1-.53-.53.75.75,0,0,1,.53-.53l1.86,1.86a.75.75,0,0,1-.53,1.28Z"
          transform="translate(775.631 827.614)"
        />
        <path
          id="Line_266"
          data-name="Line 266"
          d="M0,3.519a.75.75,0,0,1-.649-.373A.75.75,0,0,1-.377,2.12L4.39-.649a.75.75,0,0,1,1.025.272A.75.75,0,0,1,5.143.649L.377,3.417A.747.747,0,0,1,0,3.519Z"
          transform="translate(770.969 805.812)"
        />
      </g>
    </g>
  </SvgIcon>
);
