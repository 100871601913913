import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  CreatorDietMealsItemsRequest,
  postCreatorDietMealsItems,
} from "@client/diets/creator";
import { MutationOptions } from "@hooks/queries/types";

import { useCreatorVersion } from "./useCreatorVersion";
import { fetchCreatorDietMealsItemsQuery } from "./useFetchCreatorDietMealsItemsQuery";
import { fetchCreatorDietMealsQueryKey } from "./useFetchCreatorDietMealsQuery";
import { fetchCreatorDietQueryKey } from "./useFetchCreatorDietQuery";
import { fetchCreatorDietNutrientsQueryKey } from "./useFetchCreatorDietNutrientsQuery";
import { fetchDietRecipeUsedQueryKey } from "../useFetchDietRecipeUsedQuery";
import { fetchCreatorDietMealNutrientsQueryKey } from "./useFetchCreatorDietMealNutrientsQuery";

export const usePostCreatorDietMealsItemsMutation = (
  options?: MutationOptions,
) => {
  const { version } = useCreatorVersion();
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    unknown,
    UsePostCreatorDietMealsItemsMutationPayload
  >(
    ({ dietId, payload }) =>
      postCreatorDietMealsItems(dietId, payload, version),
    {
      ...options,
      onSuccess: (d, v) => {
        options?.onSuccess && options.onSuccess(d, v);
        queryClient.invalidateQueries([
          fetchCreatorDietMealsItemsQuery,
          v.dietId,
        ]);
        queryClient.invalidateQueries([
          fetchCreatorDietMealsQueryKey,
          v.dietId,
        ]);
        queryClient.invalidateQueries([fetchCreatorDietQueryKey, v.dietId]);
        queryClient.invalidateQueries([
          fetchCreatorDietNutrientsQueryKey,
          v.dietId,
        ]);
        queryClient.invalidateQueries([fetchDietRecipeUsedQueryKey, v.dietId]);
        queryClient.invalidateQueries([
          fetchCreatorDietMealNutrientsQueryKey,
          v.dietId,
        ]);
        queryClient.invalidateQueries([
          fetchCreatorDietMealNutrientsQueryKey,
          v.dietId,
        ]);
      },
    },
  );
};

interface UsePostCreatorDietMealsItemsMutationPayload {
  dietId: string;
  payload: CreatorDietMealsItemsRequest;
}
