import { Button, Typography } from "@mui/material";

import image from "@assets/img/error404.png";
import { useAppTranslation } from "@hooks";

import { ThemeProviderWrapperNew } from "themeNew";
import { ContentWrapper, Error404Styled, TextWrapper } from "./Error404.styled";
import {
  EmptyStateCard,
  ErrorPagesWrapper,
  StyledSubTitle,
  StyledTitle,
} from "../common.styled";

export const Error404 = () => {
  const { t } = useAppTranslation();
  const handleRefresh = () => location.reload();

  return (
    <ThemeProviderWrapperNew>
      <ErrorPagesWrapper>
        <EmptyStateCard>
          <Error404Styled>
            <ContentWrapper>
              <img src={image} alt={t("empty_states.alt.error_404")} />

              <TextWrapper>
                <Typography
                  color="secondary.main"
                  fontWeight={600}
                  lineHeight="1.25rem"
                  fontSize="0.875rem"
                >
                  {t("empty_states.oops")}
                </Typography>

                <StyledTitle>{t("empty_states.not_found")}</StyledTitle>
              </TextWrapper>

              <StyledSubTitle maxWidth="40.375rem">
                {t("empty_states.not_found_info")}
              </StyledSubTitle>
            </ContentWrapper>

            <Button variant="contained" onClick={handleRefresh}>
              {t("common.refresh")}
            </Button>
          </Error404Styled>
        </EmptyStateCard>
      </ErrorPagesWrapper>
    </ThemeProviderWrapperNew>
  );
};
