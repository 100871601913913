import { styled } from "@mui/material";

interface TextStyledProps {
  bold?: boolean;
}

export const TextStyled = styled("span")<TextStyledProps>`
  font-size: 1rem;
  line-height: 1.714rem;
  font-weight: ${({ bold }) => (bold ? 700 : 500)};
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;
