import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export const useEditHealthStatusForm = (values?: EditHealthStatusFormInput) => {
  const resolver = yup.object().shape({
    additionalInfo: yup.number().nullable().default(null),
  });

  return useForm<EditHealthStatusFormInput>({
    defaultValues: { additionalInfo: values?.additionalInfo },
    resolver: yupResolver(resolver),
  });
};

export interface EditHealthStatusFormInput {
  additionalInfo: number | null;
}
