import * as yup from "yup";

const EMAIL_REGX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})$/;

export const emailSchemaFactory = (errorMessage: string) =>
  yup
    .string()
    .test("email-validation", errorMessage, value => {
      const stringValue = value || "";
      if (stringValue === null || stringValue === "") {
        return true; // Allow null or empty string
      }
      return EMAIL_REGX.test(stringValue);
    })
    .nullable();
