import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const DownloadPdf = (props: SvgIconProps) => (
  <SvgIcon width="14" height="14" viewBox="0 0 14 14" {...props}>
    <path
      d="M7.8125 1.3125C7.8125 0.863086 7.44941 0.5 7 0.5C6.55059 0.5 6.1875 0.863086 6.1875 1.3125V7.4748L4.32383 5.61113C4.00645 5.29375 3.49102 5.29375 3.17363 5.61113C2.85625 5.92852 2.85625 6.44395 3.17363 6.76133L6.42363 10.0113C6.74102 10.3287 7.25645 10.3287 7.57383 10.0113L10.8238 6.76133C11.1412 6.44395 11.1412 5.92852 10.8238 5.61113C10.5064 5.29375 9.99102 5.29375 9.67363 5.61113L7.8125 7.4748V1.3125ZM2.125 9.4375C1.22871 9.4375 0.5 10.1662 0.5 11.0625V11.875C0.5 12.7713 1.22871 13.5 2.125 13.5H11.875C12.7713 13.5 13.5 12.7713 13.5 11.875V11.0625C13.5 10.1662 12.7713 9.4375 11.875 9.4375H9.29785L8.14766 10.5877C7.51289 11.2225 6.48457 11.2225 5.8498 10.5877L4.70215 9.4375H2.125ZM11.4688 10.8594C11.6304 10.8594 11.7854 10.9236 11.8996 11.0379C12.0139 11.1521 12.0781 11.3071 12.0781 11.4688C12.0781 11.6304 12.0139 11.7854 11.8996 11.8996C11.7854 12.0139 11.6304 12.0781 11.4688 12.0781C11.3071 12.0781 11.1521 12.0139 11.0379 11.8996C10.9236 11.7854 10.8594 11.6304 10.8594 11.4688C10.8594 11.3071 10.9236 11.1521 11.0379 11.0379C11.1521 10.9236 11.3071 10.8594 11.4688 10.8594Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
