import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Buffer = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      d="M11.2188 4.1875C11.4688 4.0625 11.7188 4 12 4C12.25 4 12.5 4.0625 12.75 4.1875L19.3125 6.96875C19.7188 7.15625 20 7.5625 20 8C20 8.46875 19.7188 8.875 19.3125 9.0625L12.75 11.8438C12.5 11.9688 12.25 12 12 12C11.7188 12 11.4688 11.9688 11.2188 11.8438L4.65625 9.0625C4.25 8.875 4 8.46875 4 8C4 7.5625 4.25 7.15625 4.65625 6.96875L11.2188 4.1875ZM12 5.5C11.9062 5.5 11.8438 5.53125 11.8125 5.5625L6.0625 8L11.8125 10.4688C11.8438 10.5 11.9062 10.5 12 10.5C12.0625 10.5 12.125 10.5 12.1562 10.4688L17.9062 8L12.1562 5.5625C12.125 5.53125 12.0625 5.5 12 5.5ZM11.8125 14.4688C11.8438 14.5 11.9062 14.5 12 14.5C12.0625 14.5 12.125 14.5 12.1562 14.4688L18.125 11.9375C17.9688 11.7188 17.9375 11.4375 18.0625 11.1875C18.25 10.8125 18.6875 10.6562 19.0625 10.8438L19.3438 11C19.75 11.1875 20 11.5625 20 12C20 12.4688 19.7188 12.875 19.2812 13.0625L12.75 15.8438C12.5 15.9688 12.25 16 12 16C11.7188 16 11.4688 15.9688 11.2188 15.8438L4.6875 13.0625C4.25 12.875 4 12.4688 4 11.9688C4 11.5938 4.15625 11.25 4.46875 11.0312L4.8125 10.7812C5.125 10.5625 5.59375 10.625 5.84375 10.9688C6.0625 11.25 6.03125 11.6562 5.78125 11.9062L11.8125 14.4688ZM5.84375 14.9688C6.0625 15.25 6.03125 15.6562 5.78125 15.9062L11.8125 18.4688C11.8438 18.5 11.9062 18.5 12 18.5C12.0625 18.5 12.125 18.5 12.1562 18.4688L18.125 15.9375C17.9688 15.7188 17.9375 15.4375 18.0625 15.1875C18.25 14.8125 18.6875 14.6562 19.0625 14.8438L19.3438 15C19.75 15.1875 20 15.5625 20 16C20 16.4688 19.7188 16.875 19.2812 17.0625L12.75 19.8438C12.5 19.9688 12.25 20 12 20C11.7188 20 11.4688 19.9688 11.2188 19.8438L4.6875 17.0625C4.25 16.875 4 16.4688 4 15.9688C4 15.5938 4.15625 15.25 4.46875 15.0312L4.8125 14.7812C5.125 14.5625 5.59375 14.625 5.84375 14.9688Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
