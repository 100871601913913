import { TabList, TabPanel } from "@mui/lab";
import { Tab, styled } from "@mui/material";

export const StyledTabList = styled(TabList)`
  min-height: 2.5rem;
  width: fit-content;
  background: ${({ theme }) => theme.colors.neutral.light[100]};
  padding: 0.25rem;
  border: 1px solid ${({ theme }) => theme.palette.primary.medium};
  border-radius: 0.5rem;

  & .MuiTabs-indicator {
    display: none;
  }

  & .MuiTabs-flexContainer {
    gap: 0.25rem;

    & button {
      min-height: 2rem;
      border-radius: 0.5rem;
      padding: 0.375rem 0.5rem;
      color: ${({ theme }) => theme.colors.neutral.dark[700]};

      &.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
        background: ${({ theme }) => theme.palette.primary.medium};
        color: ${({ theme }) => theme.palette.primary.main};
      }
    }
  }
`;

export const StyledTab = styled(Tab)`
  color: ${({ theme }) => theme.palette.grey[900]};
  text-transform: unset;
  font-size: 1rem;
  background: ${({ theme }) => theme.colors.neutral.light[100]};
`;

export const StyledTabPanel = styled(TabPanel)`
  padding: 0;
`;

export const SectionWrapper = styled("div")`
  display: grid;
  gap: 1rem;
`;
