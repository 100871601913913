import * as yup from "yup";

import { fetchData } from "@utils/api";
import { StoreFoodRequest } from "@typeDefinitions/requests";
import { FoodEditorResponse } from "@typeDefinitions/responses/food";
import { APIMethods } from "@typeDefinitions";

export async function requestCreateFoodEditor(
  data: StoreFoodRequest,
): Promise<CreateProductResponse> {
  const { data: food } = await fetchData(
    "/dietitian/food",
    APIMethods.POST,
    data,
  );

  return createProductResponseSchema.validate(food);
}

export async function requestUpdateFoodEditor(
  id: number,
  data: StoreFoodRequest,
): Promise<FoodEditorResponse> {
  const { data: food } = await fetchData(
    `/dietitian/food/${id}`,
    APIMethods.PUT,
    data,
  );
  return food;
}

export interface CreateProductResponse {
  id: number;
}

const createProductResponseSchema = yup.object().shape({
  id: yup.number().required(),
});
