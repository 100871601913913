import { ReactNode, useEffect, useMemo, useState } from "react";

import { Nutrient } from "@typeDefinitions";
import { scaleNutrients } from "@utils";

import {
  ProductsContext,
  ProductsContextIf,
  ProductItemIf,
  mapProductsOptions,
} from "./ProductsContext";
import { useAppTranslation } from "@hooks";

interface ProductsContextProviderProps {
  children: ReactNode;
  defaultValues?: Map<number, ProductItemIf>;
}

export const ProductsContextProvider = ({
  children,
  defaultValues,
}: ProductsContextProviderProps) => {
  const { currentLanguage } = useAppTranslation();
  const [products, setProducts] = useState<Map<number, ProductItemIf>>(
    defaultValues ?? new Map(),
  );

  const addProduct = (data: ProductItemIf) => {
    setProducts(prev => {
      const newProducts = new Map(prev);
      if (!newProducts.has(data.foodId)) newProducts.set(data.foodId, data);
      return newProducts;
    });
  };

  const getNutrients = (idx: number): Nutrient[] => {
    return products.get(idx)?.nutrients || [];
  };

  const productsOptions = useMemo(
    () => mapProductsOptions(products, currentLanguage),
    [products, currentLanguage],
  );

  const value = useMemo<ProductsContextIf>(
    () => ({
      addProduct,
      getNutrients,
      products,
      productsOptions,
    }),
    [products],
  );

  useEffect(() => {
    if (defaultValues) setProducts(defaultValues);
  }, [defaultValues]);

  return (
    <ProductsContext.Provider value={value}>
      {children}
    </ProductsContext.Provider>
  );
};
