import { CSSProperties, forwardRef, useState } from "react";

import classNames from "classnames";
import { useNavigate } from "react-router-dom";

/** clickable icon */
export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (props, ref) => {
    const {
      name,
      onClick,
      onMouseOver,
      onMouseOut,
      children,
      className,
      style,
      to,
      color = "primary",
      size = "medium",
      disabled = false,
      submit = false,
    } = props;

    const navigate = useNavigate();

    const [effect, setEffect] = useState(false);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      setEffect(true);
      if (to) navigate(to);
      if (onClick) onClick(e);
    };

    return (
      <button
        ref={ref}
        className={classNames(
          {
            "flex items-center justify-center rounded-full hover:bg-opacity-30":
              true,
            "text-primary hover:bg-primary":
              !disabled &&
              color !== "secondary" &&
              color !== "secondary-green" &&
              color !== "neutral-dark" &&
              color !== "gray" &&
              color !== "purple-light",
            "text-secondary hover:bg-secondary":
              !disabled && color === "secondary",
            "bg-white hover:bg-primary-opacity hover:text-primary-dark text-purple-dark border border-solid border-purple-dark":
              !disabled && color === "secondary-purple",
            "bg-white hover:bg-green-dark text-green-dark border border-green-dark":
              !disabled && color === "secondary-green",
            "bg-transparent hover:bg-gray-450/20 text-gray-450 border-none":
              !disabled && color === "gray",
            "bg-transparent text-neutral-dark border-none":
              !disabled && color === "neutral-dark",
            "bg-transparent hover:bg-secondary/5": color === "purple-light",
            "text-gray-700 cursor-not-allowed": disabled,
            "text-xs p-1.5": size === "small",
            "text-sm p-2": size === "medium",
            "text-xl p-2": size === "large",
            "text-2xl p-1": size === "xlarge",
            "text-4xl p-0": size === "2xlarge",
            "animate-pulse": effect,
          },
          className,
        )}
        type={submit ? "submit" : "button"}
        style={style}
        disabled={disabled}
        name={name}
        onClick={!disabled ? handleClick : undefined}
        onMouseOver={!disabled ? onMouseOver : undefined}
        onMouseOut={!disabled ? onMouseOut : undefined}
        onAnimationEnd={() => setEffect(false)}
      >
        {children}
      </button>
    );
  },
);

type IconButtonSize = "small" | "medium" | "large" | "xlarge" | "2xlarge";
type IconButtonColor =
  | "primary"
  | "secondary"
  | "secondary-purple"
  | "secondary-green"
  | "gray"
  | "neutral-dark"
  | "purple-light";

export type IconButtonProps = {
  /** SVG icom */
  children: React.ReactNode;
  /** disabled property */
  disabled?: boolean;
  /** name attribute */
  name?: string;
  /** callback fired on clicking the button */
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  /** callback fired on hovering over button */
  onMouseOver?: () => void;
  /** callback fired on hovering out of the button */
  onMouseOut?: () => void;
  /** button size */
  size?: IconButtonSize;
  /** button color */
  color?: IconButtonColor;
  /** additional classes */
  className?: string;
  /** additional styles */
  style?: CSSProperties;
  submit?: boolean;
  to?: string;
};
