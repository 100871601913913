import { useCallback, useMemo } from "react";
import {
  getAverageNutrientValue,
  getSumNutrientValue,
} from "@views/dietician/PatientMonitoring2/components/NutrientValues/NutrientValuesUtil";
import NutrientValue from "@views/dietician/PatientMonitoring2/components/NutrientValues/NutrientValues/NutrientValue";
import { Nutrients } from "@client/dictionaries";
import { CARBS_ID, ENERGY_ID, FATS_ID, PROTEIN_ID } from "@utils/macros";
import { NutrientsMonitoringResource } from "@client/resources/NutrientsMonitoringResource";
import PatientNutrientsTabs from "@components/Nutrients2/PatientNutrientsTabs";
import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import MacrosCircularProgresses from "@components/MacroCircularProgress/MacrosCircularProgresses";
import { MacroCircularProgressProps } from "@components/MacroCircularProgress/MacroCircularProgress";
import MacroTable from "@views/dietician/PatientMonitoring2/components/NutrientValues/MacroTable/MacroTable";

type AverageNutrientsValuesProps = {
  data: NutrientsMonitoringResource[];
  nutrients: Nutrients[];
};

const AverageNutrientsValues = ({
  data,
  nutrients,
}: AverageNutrientsValuesProps) => {
  const { patientId } = useMonitoring();

  const getNutrient = useCallback(
    (nutrientId: number) => {
      if (!data?.length) {
        return {
          value: 0,
          target: 0,
        };
      }

      return {
        value: getAverageNutrientValue(
          data,
          nutrientId,
          "patientEatenNutrients",
        ),
        target: getAverageNutrientValue(
          data,
          nutrientId,
          "dietitianTargetNutrients",
        ),
      };
    },
    [data],
  );

  const makeChartData = (
    nutrient: number,
    type: MacroCircularProgressProps["type"],
  ) => {
    const nutrientValues = getNutrient(nutrient);
    return {
      percent: Math.round(
        (Math.round(nutrientValues.value) /
          Math.max(Math.round(nutrientValues.target), 1)) *
          100,
      ),
      type: type,
      label: {
        value: Math.round(nutrientValues.value),
        assumption: Math.round(nutrientValues.target),
      },
    };
  };

  const kcal = useMemo(() => makeChartData(ENERGY_ID, "kcal"), [data]);
  const protein = useMemo(() => makeChartData(PROTEIN_ID, "protein"), [data]);
  const fat = useMemo(() => makeChartData(FATS_ID, "fat"), [data]);
  const carb = useMemo(() => makeChartData(CARBS_ID, "carb"), [data]);

  const dataNutrients = useMemo(() => {
    return nutrients.map(nutrient => ({
      id: nutrient.id,
      value: getAverageNutrientValue(
        data,
        nutrient.id,
        "patientEatenNutrients",
      ),
      target: getAverageNutrientValue(
        data,
        nutrient.id,
        "dietitianTargetNutrients",
      ),
    }));
  }, [data, nutrients]);

  const macroTableData = useMemo(() => {
    if (!data) {
      return [];
    }

    const getItem = (nutrientId: number) => ({
      nutrient: {
        id: nutrientId,
      },
      patient: {
        eaten: getAverageNutrientValue(
          data,
          nutrientId,
          "patientEatenNutrients",
        ),
        target: getAverageNutrientValue(
          data,
          nutrientId,
          "patientTargetNutrients",
        ),
      },
      dietitian: {
        target: getAverageNutrientValue(
          data,
          nutrientId,
          "dietitianTargetNutrients",
        ),
      },
    });

    return [
      getItem(ENERGY_ID),
      getItem(PROTEIN_ID),
      getItem(FATS_ID),
      getItem(CARBS_ID),
    ];
  }, [data]);

  return (
    <NutrientValue
      macro={
        <MacrosCircularProgresses
          kcal={kcal}
          protein={protein}
          carb={carb}
          fat={fat}
        />
      }
      nutrients={
        <PatientNutrientsTabs patientId={patientId} values={dataNutrients} />
      }
      macroTable={<MacroTable nutrients={macroTableData} />}
    />
  );
};

export default AverageNutrientsValues;
