import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MutationOptions } from "./types";
import { fetchProgramQueryKey } from "./useFetchProgramQuery";
import { fetchPatientProgramQueryKey } from "./useFetchPatientProgramQuery";
import { fetchProgramDietQueryKey } from "./useFetchProgramDietDetails";
import { CreateDietRequest, updateProgramDiet } from "@client";

export const useUpdateProgramDietMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: { programId: number; dayId: number; payload: CreateDietRequest }) =>
      updateProgramDiet(data.programId, data.dayId, data.payload),
    {
      ...options,
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries([
          fetchProgramQueryKey,
          variables.programId,
        ]);
        queryClient.invalidateQueries([fetchPatientProgramQueryKey]);
        queryClient.invalidateQueries([
          fetchProgramDietQueryKey,
          variables.programId,
          variables.dayId,
        ]);
        options?.onSuccess && options.onSuccess(_, variables);
      },
    },
  );
};
