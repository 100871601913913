import { Stack, styled } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ChevronIcon from "@icons/chevron.svg?react";
import { useAppParams } from "@hooks";
import { useFetchClientQuery } from "@hooks/queries";
import { IconButton } from "@components/Alloweat/IconButton";

const PatientProgramTitle = () => {
  const { patientId } = useAppParams();
  const { client } = useFetchClientQuery(patientId, {
    enabled: !!patientId,
  });

  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(`/patients/${patientId}/programs`);
  };

  if (!patientId) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="flex-start" gap="4px">
      <IconButton onClick={handleOnClick}>
        <ChevronIcon fontSize={24} color="#7448D0" />
      </IconButton>
      <Stack direction="column" gap="4px">
        <Title>Program żywieniowy</Title>
        <Name>{`${client?.firstName} ${client?.lastName}`}</Name>
      </Stack>
    </Stack>
  );
};

const Title = styled("span")`
  color: #141414;
  font-family: Figtree, serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
`;
const Name = styled("span")`
  color: #141414;
  font-family: Figtree, serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
`;

export default PatientProgramTitle;
