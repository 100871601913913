import { useState } from "react";

import { Logo } from "@assets/icons";
import { useAppParams, useAppTranslation } from "@hooks";

import {
  CheckboxStyled,
  FormControlLabelStyled,
  Label,
  LabelWrapper,
} from "./ReviewdCheckbox.styled";
import {
  fetchSurveyFullQueryKey,
  useUpdateSurveyMutation,
} from "@hooks/queries/surveys";
import { useDebounce } from "react-use";
import { useQueryClient } from "@tanstack/react-query";
import { ApiResponse } from "@typeDefinitions";
import { FullSurveyDto } from "@client/surveys";

interface ReviewedCheckboxProps {
  defaultChecked: boolean;
}
export const ReviewedCheckbox = ({ defaultChecked }: ReviewedCheckboxProps) => {
  const { t } = useAppTranslation();
  const { questionnaireId } = useAppParams();
  const [checked, setChecked] = useState(defaultChecked);

  const queryClient = useQueryClient();
  const data = queryClient.getQueryData<ApiResponse<FullSurveyDto>>([
    fetchSurveyFullQueryKey,
    questionnaireId,
  ]);
  const { mutate } = useUpdateSurveyMutation();

  const handleCheck = (value: boolean) => setChecked(value);

  useDebounce(
    () => {
      if (!data?.data.introduction) return;
      const { langId, name, introduction } = data.data;

      mutate({
        id: questionnaireId,
        payload: { introduction, langId, name, reviewed: checked },
      });
    },
    1000,
    [checked],
  );

  return (
    <FormControlLabelStyled
      control={
        <CheckboxStyled checked={checked} onChange={(_, v) => handleCheck(v)} />
      }
      label={
        <LabelWrapper>
          <Label>{t("questionnaires.reviewed")}</Label>
          <Logo size="w-5 h-5" />
        </LabelWrapper>
      }
    />
  );
};
