import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const CheckNew = (props: SvgIconProps) => (
  <SvgIcon {...props} width="14" height="10" viewBox="0 0 14 10">
    <path
      d="M13.75 0.21875C14.0625 0.572917 14.0625 0.916667 13.75 1.25L5.5 9.5C5.16667 9.8125 4.82292 9.8125 4.46875 9.5L0.21875 5.25C-0.0729167 4.91667 -0.0729167 4.57292 0.21875 4.21875C0.572917 3.92708 0.927083 3.92708 1.28125 4.21875L5 7.9375L12.7188 0.21875C13.0729 -0.0729167 13.4167 -0.0729167 13.75 0.21875Z"
      fill={props.fill ?? "currentColor"}
      transform="translate(0, -1)"
    />
  </SvgIcon>
);
