import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Pills = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16" height="12" viewBox="0 0 16 12">
      <path
        d="M3.67847 2.07143C3.0278 2.07143 2.4999 2.59933 2.4999 3.25V6H4.85704V3.25C4.85704 2.59933 4.32914 2.07143 3.67847 2.07143ZM0.928467 3.25C0.928467 1.73013 2.1586 0.5 3.67847 0.5C5.19833 0.5 6.42847 1.73013 6.42847 3.25V8.75C6.42847 10.2699 5.19833 11.5 3.67847 11.5C2.1586 11.5 0.928467 10.2699 0.928467 8.75V3.25ZM14.5532 9.52098C14.3789 9.82299 13.9713 9.84263 13.7233 9.5971L9.11708 4.99085C8.87155 4.74531 8.88873 4.33527 9.1932 4.16094C9.76775 3.83192 10.4332 3.64286 11.1428 3.64286C13.3133 3.64286 15.0713 5.40089 15.0713 7.57143C15.0713 8.28103 14.8823 8.94643 14.5532 9.52098ZM13.0923 10.9819C12.5178 11.3109 11.8524 11.5 11.1428 11.5C8.97222 11.5 7.21418 9.74196 7.21418 7.57143C7.21418 6.86183 7.40324 6.19643 7.73226 5.62187C7.90659 5.31987 8.31418 5.30022 8.56217 5.54576L13.1684 10.152C13.414 10.3975 13.3968 10.8076 13.0923 10.9819Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
