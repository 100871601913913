import {
  MaterialItem,
  MaterialItemChip,
  MaterialItemTitle,
} from "@components/ProgramSearchPreview/Components/ProgramMaterials/ProgramMaterials.styled";
import { useProgramSectionCategories } from "@hooks/dictionary/useProgramSectionCategories";
import { SectionProgramPreviewSchema } from "@client/program/preview/fetchSectionsProgramPreview";
import { useMemo } from "react";
import { useAppTranslation } from "@hooks";
import { getSectionCategoryName } from "@components/ProgramSearchPreview/utils/ProgramSectionsUtil";

type MaterialListItemProps = {
  material: SectionProgramPreviewSchema;
};

const MaterialListItem = ({ material }: MaterialListItemProps) => {
  const { currentLanguage } = useAppTranslation();
  const { find: findCategory } = useProgramSectionCategories();

  const categoryName = useMemo(() => {
    const category = findCategory(material.section.category.id);
    return getSectionCategoryName(category, currentLanguage);
  }, [material, findCategory, currentLanguage]);

  const materialName = useMemo(() => {
    return (
      material.translations.find(t => t.lang === currentLanguage)?.name ??
      material.name
    );
  }, [material, categoryName]);

  return (
    <MaterialItem>
      <MaterialItemTitle>{materialName}</MaterialItemTitle>
      <MaterialItemChip label={categoryName} />
    </MaterialItem>
  );
};

export default MaterialListItem;
