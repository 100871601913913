import React, { ChangeEvent, useMemo, useState } from "react";

import { format } from "date-fns";
import { RadioGroup } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";

import {
  useAppParams,
  useAppTranslation,
  useProgramMealColorsExtractor,
} from "@hooks";
import { OptimizerSettingsBase } from "@typeDefinitions";
import { Modal } from "@components/Modal";
import { Button } from "@components/Button";
import { Spinner } from "@components/Spinner";
import {
  fetchDietitianClientProgramScheduleKey,
  fetchDietitianProgramScheduleKey,
  useFetchSampleScheduleDayVariantsQuery,
  useProgramDayVariantMutation,
} from "@hooks/queries/schedule";
import { DietitianProgramScheduleDay, ImportanceType } from "@client/schedule";

import { ColoredCircle } from "../../ColoredCircle";
import { ScheduleGridRow } from "./ScheduleGridRow/ScheduleGridRow";
import { ModalTitleWrapper } from "./ModalTitleWrapper.styled";
import { ModalActionBarWrapper } from "./ModalActionBarWrapper.styled";
import { updateProgramDayOptimisticUpdate } from "./scheduleGridUtils";

export interface DayVariantModalProps {
  programId: number;
  programDayId: number;
  dayName: string;
  color: string;
  date: string;
  onVariantModalClose: () => void;
  scheduleDay: DietitianProgramScheduleDay;
  optimizerSettings?: OptimizerSettingsBase[];
  normId?: number;
}

export const DayVariantModal = (props: DayVariantModalProps) => {
  const {
    programId,
    color,
    dayName,
    programDayId,
    date,
    scheduleDay,
    optimizerSettings = [],
    onVariantModalClose,
    normId,
  } = props;

  const { t } = useAppTranslation();
  const { patientId } = useAppParams();
  const queryClient = useQueryClient();

  const [selectedVariant, setSelectedVariant] = useState<number>(
    scheduleDay.id,
  );

  const dateObj = new Date(date);

  const { sampleVariants, isLoading } = useFetchSampleScheduleDayVariantsQuery(
    programId,
    programDayId,
    {
      nutrients: optimizerSettings,
      balances: [
        { type: ImportanceType.MEALS, value: 50 },
        { type: ImportanceType.NUTRIENTS, value: 50 },
      ],
    },
  );
  const { isLoading: isUpdating, mutate: updateProgramDay } =
    useProgramDayVariantMutation(programId, programDayId);

  const parsedSampleVariants = useMemo(
    () =>
      sampleVariants?.map((variant, i) => ({ ...variant, id: -(i + 1) })) || [],
    [sampleVariants],
  );

  const recipeColors = useProgramMealColorsExtractor([
    scheduleDay,
    ...parsedSampleVariants,
  ]);

  const handleVariantSelect = (
    e: ChangeEvent<HTMLInputElement>,
    variant: string,
  ) => {
    setSelectedVariant(parseInt(variant));
  };

  const handleSave = () => {
    if (isUpdating) return;

    if (!selectedVariant || selectedVariant === scheduleDay.id) {
      onVariantModalClose();
      return;
    }

    const v = parsedSampleVariants?.find(
      variant => variant.id === selectedVariant,
    );

    if (!v) {
      onVariantModalClose();
      return;
    }

    updateProgramDay(
      {
        id: v.id < 0 ? null : v.id,
        program: { id: programId },
        programDay: { id: programDayId },
        date: scheduleDay.date,
        meals: v.meals
          .filter(meal => !!meal.recipe?.id)
          .map(meal => ({
            dietMeal: {
              ...meal.dietMeal,
              hour: meal.dietMeal.hour || "",
              description: meal.dietMeal.description || "",
            },
            recipe: {
              id: meal.recipe?.id as number,
              title: meal.recipe?.title as string,
              servings: meal.recipe?.servings,
              description: meal.recipe?.description,
            },
            servings: meal.servings || 0,
          })),
      },
      {
        onSuccess: () => {
          queryClient.setQueryData(
            [fetchDietitianProgramScheduleKey, programId],
            (input?: { data: DietitianProgramScheduleDay[] }) =>
              updateProgramDayOptimisticUpdate(input, scheduleDay, v),
          );
          queryClient.setQueryData(
            [
              fetchDietitianClientProgramScheduleKey,
              patientId,
              programId.toString(),
            ],
            (input?: { data: DietitianProgramScheduleDay[] }) =>
              updateProgramDayOptimisticUpdate(input, scheduleDay, v),
          );
          onVariantModalClose();
        },
      },
    );
  };

  return (
    <Modal
      submitButton={false}
      closeButton={false}
      closeIcon={false}
      onClose={onVariantModalClose}
      style={{ width: "90vw" }}
    >
      <div className="py-6 px-9">
        <ModalTitleWrapper>
          <h3 className="text-xl">{t("patient.schedule.variants.title")}</h3>
        </ModalTitleWrapper>

        <div className="flex items-center justify-center w-full my-6">
          <ColoredCircle color={color}>
            {dayName.slice(0, 1).toUpperCase()}
          </ColoredCircle>
          <div className="ml-4 font-semibold">{dayName}</div>
          <div className="ml-4 border-r border-gray-400 h-7" />
          <div className="ml-4 font-semibold">
            {format(dateObj, "dd.MM.yyyy")}
          </div>
        </div>
        <RadioGroup value={selectedVariant} onChange={handleVariantSelect}>
          <div className="w-full overflow-x-auto">
            <div className="w-max min-w-full">
              <ScheduleGridRow
                programId={programId}
                scheduleDay={scheduleDay}
                color={color}
                recipeColors={recipeColors}
                readOnly
                selectedVariant={selectedVariant}
                variantName={t("common.current")}
              />
              {!isLoading &&
                parsedSampleVariants?.map((variant, i) => (
                  <ScheduleGridRow
                    key={variant.id}
                    programId={programId}
                    scheduleDay={variant}
                    color={color}
                    recipeColors={recipeColors}
                    readOnly
                    selectedVariant={selectedVariant}
                    normId={normId}
                    variantName={`${t("common.variant")} ${i + 1}`}
                  />
                ))}
            </div>
          </div>
        </RadioGroup>

        <ModalActionBarWrapper>
          <Button
            size="large"
            color="gray"
            className="mx-2"
            onClick={onVariantModalClose}
          >
            {t("common.cancel")}
          </Button>
          <Button
            size="large"
            color="primary"
            className="mx-2"
            onClick={handleSave}
          >
            {isUpdating ? (
              <Spinner size="w-6 h-6" />
            ) : (
              t("patient.schedule.variants.select")
            )}
          </Button>
        </ModalActionBarWrapper>
      </div>
    </Modal>
  );
};
