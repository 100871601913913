import { Measure, Nutrient } from "@typeDefinitions";
import { TagsTab } from "../common/TagsTab";
import { NutrientsTab } from "./NutrientsTab";
import { ProductTabs } from "./ProductPreview";
import { TabPanelStyled } from "./ProductPreview.styled";
import { IdDto } from "@client";

interface ProductTabPanelsProps {
  nutrients: Nutrient[];
  measures: Measure[];
  tags: number[];
  producer?: string | null;
  shops?: IdDto[];
}

export const ProductTabPanels = ({
  nutrients,
  measures,
  tags,
  producer,
  shops,
}: ProductTabPanelsProps) => {
  return (
    <>
      <TabPanelStyled value={ProductTabs.NUTRITIONAL_VALUES}>
        <NutrientsTab nutrients={nutrients} measures={measures} />
      </TabPanelStyled>
      <TabPanelStyled value={ProductTabs.TAGS}>
        <TagsTab tags={tags} producer={producer} shops={shops} />
      </TabPanelStyled>
    </>
  );
};
