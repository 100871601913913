import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const updateSurveySubgroupsSort = async (
  groupId: string,
  payload: UpdateSurveySubgroupsSortRequest,
) => {
  await fetchData(
    `/dietitian/survey-groups/${groupId}/sections-sort`,
    APIMethods.PUT,
    payload,
  );
};

export interface UpdateSurveySubgroupsSortRequest {
  ids: number[];
}
