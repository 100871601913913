import { useQuery } from "@tanstack/react-query";

import { getPdfColorsAPI } from "@Pdf/api/pdfAPI";
import { buildDefaultQueryOptions, QueryOptionsTyped } from "@hooks/queries";
import { PdfColorResponseType } from "@Pdf/types/pdfTypes";

export const pdfColorsQueryKey = "pdfColorsQueryKey";

const usePdfColorsQuery = (
  options?: QueryOptionsTyped<Array<PdfColorResponseType>>,
) =>
  useQuery(
    [pdfColorsQueryKey],
    getPdfColorsAPI,
    buildDefaultQueryOptions(options),
  );

export default usePdfColorsQuery;
