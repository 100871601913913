import { useMutation, useQueryClient } from "@tanstack/react-query";

import { postToggleFavoriteFood } from "@client";

import { fetchProductQueryKey } from "./food";
import { MutationOptions } from "./types";

export const usePostToggleFavoriteFoodMutation = (
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, string>(
    productId => postToggleFavoriteFood(productId),
    {
      ...options,
      onSuccess: (d, productId) => {
        options?.onSuccess && options.onSuccess(d, productId);
        queryClient.invalidateQueries([fetchProductQueryKey, productId]);
      },
    },
  );
};
