import * as yup from "yup";

import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { Currencies } from "@utils/scheduleEvent";
import { validateAndFixNutrients } from "./utils/validateCatching";

export const fetchClinicProducts = async (): Promise<
  ApiResponse<FetchClinicProductDto[]>
> => {
  const data = await fetchData("/dietitian/clinic/products", APIMethods.GET);
  return await validateAndFixNutrients(fetchClinicProductsResponseSchema, data);
};

const clinicDto = yup.object().shape({
  id: yup.number().required(),
});

const priceDto = yup.object().shape({
  amount: yup.number().required(),
  currency: yup.string().required(),
});

const translationDto = yup.object().shape({
  lang: yup.string().required(),
  name: yup.string().required(),
});

const translationsDto = yup.object().shape({
  pl: translationDto.required(),
  en: translationDto.required(),
});
const fetchClinicProductSchema = yup.object().shape({
  id: yup.number().required(),
  clinic: clinicDto.required(),
  translations: translationsDto.required(),
  prices: yup.array().of(priceDto).required(),
  isActive: yup.boolean().required(),
});

const fetchClinicProductsResponseSchema = yup.object().shape({
  data: yup.array().of(fetchClinicProductSchema).required(),
});

export interface FetchClinicProductDto {
  id: number;
  clinic: ClinicDto;
  translations: Record<"pl" | "en", TranslationDto>;
  prices: PriceDto[];
  isActive: boolean;
}

interface ClinicDto {
  id: number;
}

interface TranslationDto {
  lang: "pl" | "en";
  name: string;
}

interface PriceDto {
  amount: number;
  currency: Currencies;
}
