import { useCallback, useMemo } from "react";
import { Control, Path, useController, useWatch } from "react-hook-form";

import { round } from "lodash";

import { MacroSlider } from "./";
import { MacrosSlidersFormInput } from "../";
import {
  calculateSliderValue,
  calculateValueFromSlider,
  displayValueFactory,
  labelFactory,
  sumMacros,
  valueChangeHandler,
} from "../macrosUtils";
import { FieldValues } from "react-hook-form/dist/types";

interface FormMacroSliderProps<T extends FieldValues> {
  name: Path<T>;
  control: Control<T>;
  label: string;
  unit: string;
  multiplier: number;
  weight: number | null;
}

export const FormMacroSlider = <T extends MacrosSlidersFormInput>({
  control,
  name,
  unit,
  label,
  multiplier,
  weight,
}: FormMacroSliderProps<T>) => {
  const {
    field: { value, onChange },
  } = useController({ control, name });

  const protein = useWatch({ control, name: "protein" as Path<T> });
  const energy = useWatch({ control, name: "kcal" as Path<T> });
  const fats = useWatch({ control, name: "fat" as Path<T> });
  const carbs = useWatch({ control, name: "carbs" as Path<T> });

  const sliderValue = useMemo(
    () => calculateSliderValue(energy, value, multiplier),
    [energy, value, multiplier],
  );

  const displayValue = useMemo(
    () => displayValueFactory(unit, value, sliderValue, weight ?? 0),
    [unit, value, energy, weight, multiplier, sliderValue],
  );

  const valueLabelFormat = useMemo(
    () => labelFactory(sliderValue, percentageMin, percentageMax, true),
    [sliderValue, percentageMin, percentageMax],
  );

  const handleChange = useCallback(
    (value: string) => {
      const parsedValue = valueChangeHandler(
        value,
        unit,
        multiplier,
        energy,
        weight ?? 0,
      );
      if (parsedValue !== undefined) onChange(parsedValue);
      else return;
    },
    [unit, multiplier, weight, onChange, round],
  );

  const sliderChanged = useCallback(
    (value: number) => {
      onChange(calculateValueFromSlider(energy, value, multiplier));
    },
    [onChange, energy, multiplier],
  );

  const onCalc = useCallback(() => {
    const calculateAutoValue = () => {
      const sum = sumMacros(fats, carbs, protein);
      const autoValue = value + (energy - sum) / multiplier;
      return round(Math.max(autoValue, 0));
    };

    return onChange(calculateAutoValue());
  }, [onChange, fats, carbs, protein, value, energy, multiplier]);

  return (
    <MacroSlider
      value={displayValue}
      sliderValue={sliderValue}
      unit={unit}
      label={label}
      min={percentageMin}
      max={percentageMax}
      onCalc={onCalc}
      onSliderChange={sliderChanged}
      onInputChange={handleChange}
      valueLabelFormat={valueLabelFormat}
    />
  );
};

const percentageMin = 0;
const percentageMax = 100;
