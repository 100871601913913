import { ReactNode, useCallback, useContext } from "react";

import { useAppTranslation } from "@hooks";
import { NutrientDto, requestFoodNutrients } from "@client";
import { DietitianProduct } from "@typeDefinitions";
import { SwappedProductsContext } from "@context";
import { Link } from "react-router-dom";
import { ListElement, ListElementProps } from "./ListElement";
import { ExpandableTagsList } from "./ExpandableTagsList";
import { MicronutrientPreview } from "@components/MicronutrientsModal";
import { SwappedProductDot } from "@components/SwappedProductDot";
import { ProductDetailsQueryWrapper } from "@views/dietician/product-views/components/ProductDetails";

import { Tooltip } from "@mui/material";
import { Error, PolandFlag } from "@assets/icons";

interface ProductListElementProps
  extends Omit<ListElementProps, "title" | "body" | "macros" | "micros"> {
  isGeneralList?: boolean;
  product: DietitianProduct;
  children?: ReactNode;
  grams?: number;
  micros?: NutrientDto[];
  error?: boolean;
  hasSubstitutes?: boolean;
}

export const ProductListElement = ({
  isGeneralList,
  product,
  children,
  grams,
  micros,
  error,
  hasSubstitutes,
  ...rest
}: ProductListElementProps) => {
  const { isPolishChosen, t } = useAppTranslation();
  const query = useCallback(() => requestFoodNutrients(product.id), []);

  const { getProductById } = useContext(SwappedProductsContext);
  const previousProductData = getProductById(product.id);

  return (
    <ListElement
      title={
        isGeneralList ? (
          <Link to={`/products/${product.id}`}>
            <p className="flex gap-3 items-baseline">
              {isPolishChosen ? product.namePl : product.nameEn}
              {product.plOnly ? <PolandFlag /> : ""}
            </p>
          </Link>
        ) : (
          <p className="flex gap-3 items-baseline">
            {isPolishChosen ? product.namePl : product.nameEn}
            {product.plOnly ? <PolandFlag /> : ""}
          </p>
        )
      }
      body={<ExpandableTagsList tags={product.tags} />}
      macros={product.macros}
      micros={
        <MicronutrientPreview
          means={micros}
          query={query}
          factor={micros ? undefined : grams}
        />
      }
      suffix={
        <div>
          {hasSubstitutes && (
            <SwappedProductDot previousFood={previousProductData?.foodFrom} />
          )}
          {error && (
            <Tooltip
              title={`${t("products.invalid_product")}`}
              placement={"top"}
            >
              <div>
                <Error className="text-red" />
              </div>
            </Tooltip>
          )}
        </div>
      }
      {...rest}
    >
      {!children ? (
        <ProductDetailsQueryWrapper productId={product.id} />
      ) : (
        children
      )}
    </ListElement>
  );
};
