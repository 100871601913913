import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useAppTranslation, useModalState } from "@hooks";

import { DietTemplates, NewDiet, TwoSquares } from "@assets/icons/Program";
import { Modal } from "@components/Modal";
import { DayDietSelect, DietSelect } from "@components/modals/AddDietModal";
import { ProgramEmptyDietContext } from "@context";
import {
  usePostProgramDayDietMutation,
  useUpdateProgramDayDietMutation,
  useUpdateProgramDietMutation,
} from "@hooks/queries";
import { usePostCreatorDietMutation } from "@hooks/queries/diets/creator";

import { ThemeProviderWrapperNew } from "themeNew";
import {
  DietNameInputs,
  DietNameModal,
  mapDietNameRequest,
} from "../DietCreator/components/DietNameModal";
import {
  DietSelectButton,
  SelectDietWrapper,
  StyledDayDietModal,
} from "./SelectDiet.styled";

interface SelectDietProps {
  onSelect?: () => Promise<void>;
}
export const SelectDiet = ({ onSelect }: SelectDietProps) => {
  const navigate = useNavigate();
  const {
    programInfo: { warningBorder },
    hideWarningBorder,
  } = useContext(ProgramEmptyDietContext);
  const { programId, dayId } = useParams();
  const [openDietSelect, onOpenDietSelect, onCloseDietSelect] = useModalState();
  const [openDayDietModal, onOpenDayDietModal, onCloseDayDietModal] =
    useModalState();
  const [openCreateDiet, onOpenCreateDiet, onCloseCreateDiet] = useModalState();

  const { mutateAsync, isLoading } = useUpdateProgramDayDietMutation();
  const { mutate: createProgramDiet, isLoading: isCreatingProgramDiet } =
    usePostProgramDayDietMutation();

  const handleCreateNewDietFromTemplate = async (id: number) => {
    onCloseDietSelect();
    hideWarningBorder();
    onSelect && (await onSelect());
    if (programId && dayId) {
      await mutateAsync({ programId, dayId, dietId: id.toString() });
    }
  };

  const handleCreateDiet = async (values: DietNameInputs) => {
    if (!programId || !dayId) return;

    onSelect && (await onSelect());

    createProgramDiet(
      { programId, dayId, payload: mapDietNameRequest(values) },
      {
        onSuccess: async ({ data }) => {
          navigate(`diet/${data.id}/edit`);
        },
      },
    );
  };

  const { t } = useAppTranslation();

  return (
    <SelectDietWrapper warning={warningBorder}>
      <DietSelectButton
        startIcon={
          <DietTemplates size="w-16 h-16" className="stroke-current" />
        }
        variant="outlined"
        onClick={onOpenDietSelect}
      >
        {t("addNutritionalProgram.addDietModal.dietTemp")}
      </DietSelectButton>
      <DietSelectButton
        startIcon={<NewDiet size="w-16 h-16" className="stroke-current" />}
        variant="outlined"
        onClick={onOpenCreateDiet}
      >
        {t("program.diets.add_diet_modal.new_diet")}
      </DietSelectButton>
      <DietSelectButton
        startIcon={<TwoSquares size="w-16 h-16" />}
        variant="outlined"
        onClick={onOpenDayDietModal}
      >
        {t("program.diets.add_diet_modal.select_diet_from_day")}
      </DietSelectButton>

      {openDietSelect && (
        <Modal
          onClose={onCloseDietSelect}
          closeButton={false}
          submitButton={false}
          style={{ width: "70vw" }}
          title={t("program.diets.add_diet_modal.new_diet")}
        >
          <div className="p-4 h-[90vh]">
            <DietSelect onDietSelect={handleCreateNewDietFromTemplate} />
          </div>
        </Modal>
      )}

      {openDayDietModal && (
        <StyledDayDietModal
          onClose={onCloseDayDietModal}
          closeIcon={false}
          closeButton={false}
          submitButton={false}
          title={t("program.diets.add_diet_modal.copy_diet")}
        >
          <div className="p-4">
            <DayDietSelect
              onClose={onCloseDayDietModal}
              onDietSelect={handleCreateNewDietFromTemplate}
              isLoading={isLoading}
            />
          </div>
        </StyledDayDietModal>
      )}

      <ThemeProviderWrapperNew>
        <DietNameModal
          open={openCreateDiet}
          onClose={onCloseCreateDiet}
          title={t("diets.add_modal.title")}
          confirmText={t("diets.add_modal.submit")}
          onSubmit={handleCreateDiet}
          loading={isCreatingProgramDiet}
        />
      </ThemeProviderWrapperNew>
    </SelectDietWrapper>
  );
};
