import { ReactElement, useState } from "react";

import classNames from "classnames";
import { motion } from "framer-motion";
import { SwappedProductDot } from "../SwappedProductDot/SwappedProductDot";
import { useAppTranslation } from "@hooks/useAppTranslation";

export interface TabsProps {
  children: ReactElement[];
  showScroll?: boolean;
  initialTab?: number;
  onChanged?: () => void;
  padding?: boolean;
}

export const Tabs = (props: TabsProps) => {
  const { children, showScroll, initialTab, onChanged, padding } = props;
  const [selectedTab, setSelectedTab] = useState<number>(initialTab || 0);
  const { t } = useAppTranslation();

  return (
    <div className={`w - full flex flex-col ${padding ? "p-5" : ""}`}>
      <div className="flex w-full">
        <ul
          className={`flex flex-wrap ${showScroll ? "overflow-x-scroll" : ""}`}
        >
          {children.map((child, idx) => (
            <motion.li
              key={`tab-${idx}`}
              className={classNames({
                "w-max font-roman py-3 px-6 cursor-pointer border-b": true,
                "text-gray-700 border-gray-600": selectedTab !== idx,
                "text-primary  border-primary": selectedTab === idx,
                "text-red font-semibold": child.props.error,
              })}
              onClick={() => {
                setSelectedTab(idx);
                if (onChanged) {
                  onChanged();
                }
              }}
            >
              {child.props.title}
              {child.props.hasSubstitutes && (
                <SwappedProductDot title={t("common.contains_substitutes")} />
              )}
            </motion.li>
          ))}
        </ul>
      </div>
      <div className="w-full">{children[selectedTab]}</div>
    </div>
  );
};
