import { useRef, useState } from "react";
import {
  Autocomplete,
  Chip,
  TextField,
  createFilterOptions,
  FilterOptionsState,
} from "@mui/material";
import { useAppTranslation } from "@hooks";
import { toast } from "react-toastify";
import classnames from "classnames";
import { SearchNoResults } from "@views/emptyStates/SearchNoResults";

export interface ListOption {
  id: string;
  label: string;
  text?: string;
  parent?: string;
}

export interface TagSelectAutocompleteProps {
  value: ListOption[];
  options: ListOption[];
  onChange: (value: ListOption[]) => void;
  onInputChange?: (value: string) => void;
  onRemove: (valueId: string) => void;
  onTagCreate?: (newTagName: string) => Promise<number>;
  isLoading?: boolean;
  groupBy?: (option: ListOption) => string;
  apiSearch?: boolean;
}

const filter = createFilterOptions<ListOption>();

export const TagSelectAutocomplete = ({
  value,
  options,
  isLoading,
  onChange,
  onInputChange,
  onRemove,
  onTagCreate,
  groupBy,
  apiSearch,
}: TagSelectAutocompleteProps) => {
  const { t } = useAppTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const [hadChange, setChange] = useState(false);

  const handleRemove = (value: string) => () => {
    onRemove(value);
    setChange(true);
    inputRef.current?.focus();
  };

  const handleChange = async (e: any, value: (string | ListOption)[]) => {
    if (!value.length) return;

    let newValue = value.slice(-1)[0];

    if (typeof newValue === "string") {
      if (newValue.trim() === "") {
        return;
      }

      const existingOption = options.find(
        o => o.label.toLowerCase() === (newValue as string).toLowerCase(),
      );
      if (!existingOption && !onTagCreate) return;

      if (value.find(o => typeof o !== "string" && o.id === existingOption?.id))
        return;

      if (existingOption) value[value.length - 1] = existingOption;

      newValue = existingOption || { id: "input", label: "", text: newValue };
    }

    if (onTagCreate && newValue.id === "input" && newValue.text) {
      try {
        const id = await onTagCreate(newValue.text);
        value[value.length - 1] = { id: id.toString(), label: newValue.text };
      } catch (e) {
        return;
      }
    }

    onChange(value as ListOption[]);
    setChange(true);
  };

  const handleInputChange = (e: any, value: string) => {
    onInputChange && onInputChange(value);
  };

  const handleBlur = () => {
    if (hadChange) {
      setChange(false);
      toast.success(t("common.saved") + "!");
    }
  };

  const checkAndShowTagCreationPrompt = (
    options: ListOption[],
    params: FilterOptionsState<ListOption>,
  ) => {
    const filtered = filter(options, params);

    const { inputValue } = params;
    const isExisting = options.some(
      option =>
        inputValue.toLowerCase().trim() === option.label.toLowerCase().trim(),
    );
    if (inputValue.trim() !== "" && !isExisting) {
      filtered.push({
        id: "input",
        label: `${t("common.add")} "${inputValue}"`,
        text: inputValue,
      });
    }

    return filtered;
  };

  return (
    <Autocomplete
      noOptionsText={<SearchNoResults />}
      multiple
      limitTags={3}
      value={value}
      options={options}
      groupBy={groupBy}
      disableClearable
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      loading={isLoading}
      onBlur={handleBlur}
      onChange={handleChange}
      onInputChange={handleInputChange}
      filterOptions={
        onTagCreate
          ? checkAndShowTagCreationPrompt
          : apiSearch
          ? options => options
          : undefined
      }
      renderOption={(props, option) => (
        <li
          {...props}
          className={classnames(props.className, option.text && "text-primary")}
        >
          {option.label}
        </li>
      )}
      renderInput={params => (
        <TextField
          {...params}
          inputRef={inputRef}
          placeholder={t("common.search")}
          variant="standard"
        />
      )}
      renderTags={(value: ListOption[], getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            label={option?.label}
            {...getTagProps({ index })}
            onDelete={handleRemove(option?.id)}
          />
        ))
      }
    />
  );
};
