import { styled } from "@mui/material";

export const ActionButtonsSection = styled("div")`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
`;

export const WrapperFixed = styled("div")`
  .MuiDrawer-paperAnchorRight.MuiDrawer-paperAnchorDockedRight {
    right: 15vw;
    height: 90vh;
    top: 5vh;
    bottom: 5vh;
  }
  .MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper {
    margin-top: 0 !important;
  }
`;
