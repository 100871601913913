import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const EmptyBookOpened = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="18" height="16" viewBox="0 0 18 16">
      <path
        d="M1 1.5H5.8C6.64869 1.5 7.46263 1.80436 8.06274 2.34614C8.66286 2.88791 9 3.62271 9 4.38889V14.5C9 13.9254 8.74714 13.3743 8.29706 12.9679C7.84697 12.5616 7.23652 12.3333 6.6 12.3333H1V1.5Z"
        stroke={props.fill ?? "currentColor"}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M17 1.5H12.2C11.3513 1.5 10.5374 1.80436 9.93726 2.34614C9.33714 2.88791 9 3.62271 9 4.38889V14.5C9 13.9254 9.25286 13.3743 9.70294 12.9679C10.153 12.5616 10.7635 12.3333 11.4 12.3333H17V1.5Z"
        stroke={props.fill ?? "currentColor"}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};
