import { useTheme } from "@mui/material";
import { useTagsNew } from "@hooks/useTagsNew";
import { TagCategoryType } from "@utils/tagsNew";
import { useAppTranslation } from "@hooks";

import { Nutrient } from "@typeDefinitions";
import {
  DetailsWrapper,
  TagIconWrapper,
  Label,
  Title,
  CellWrapper,
} from "./NameColumnCell.styled";
import { Clock, Cutlery, PieChart } from "@assets/icons";
import { MacrosText } from "@components/MacrosText";

interface NameColumnCellProps {
  name: string;
  tags: string[];
  nutrients: Nutrient[];
  servings: number;
  onSelect: () => void;
}
export const NameColumnCell = ({
  name,
  tags,
  nutrients,
  servings,
  onSelect,
}: NameColumnCellProps) => {
  const { t } = useAppTranslation();
  const {
    colors: { neutral },
  } = useTheme();
  const { tagCategoryDictionary, tagNameDictionary } = useTagsNew();

  const difficultyOfPreparationTagList = tagCategoryDictionary.get(
    TagCategoryType.DIFFICULTY_OF_PREPARATION,
  )?.tags;
  const preparationTimeTagList = tagCategoryDictionary.get(
    TagCategoryType.PREPARATION_TIME,
  )?.tags;

  const difficultyOfPreparationTag = tags?.find(tag =>
    difficultyOfPreparationTagList?.some(t => t.id.toString() === tag),
  );
  const preparationTimeTag = tags?.find(tag =>
    preparationTimeTagList?.some(t => t.id.toString() === tag),
  );

  return (
    <CellWrapper onClick={onSelect}>
      <Title>{name}</Title>
      <DetailsWrapper>
        <MacrosText nutrients={nutrients} />
        {!!preparationTimeTag && (
          <TagIconWrapper>
            <Clock fill={neutral.dark[700]} />
            <Label>{tagNameDictionary.get(Number(preparationTimeTag))}</Label>
          </TagIconWrapper>
        )}
        {!!difficultyOfPreparationTag && (
          <TagIconWrapper>
            <Cutlery fill={neutral.dark[700]} />
            <Label>
              {tagNameDictionary.get(Number(difficultyOfPreparationTag))}
            </Label>
          </TagIconWrapper>
        )}
        {servings > 1 && (
          <TagIconWrapper>
            <PieChart fill={neutral.dark[700]} />
            <Label>{t("recipe.recipe_complex", { count: servings })}</Label>
          </TagIconWrapper>
        )}
      </DetailsWrapper>
    </CellWrapper>
  );
};
