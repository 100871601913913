import { useQuery } from "@tanstack/react-query";
import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import { QueryOptions } from "./types";

const test503 = async (withResponse?: boolean) => {
  return await fetchData(
    "/errors-503",
    APIMethods.GET,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    withResponse ? undefined : "https://api.alloweat.bbbeee/api/v1",
  );
};

export const useTest503 = (options?: QueryOptions, withResponse?: boolean) => {
  return useQuery(["test503"], () => test503(withResponse), options);
};
