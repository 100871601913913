import { useQuery } from "@tanstack/react-query";

import { useAppTranslation } from "@hooks";
import { buildDefaultQueryOptions } from "./common";
import { fetchProgramCategories } from "@client";

export const programCategoriesQuery = "fetchProgramCategories";

export function useFetchProgramSectionsCategoryQuery() {
  const { t } = useAppTranslation();

  const { data, ...rest } = useQuery(
    [programCategoriesQuery],
    fetchProgramCategories,
    buildDefaultQueryOptions({ keepPreviousData: true }),
  );
  return {
    sections: data?.data,
    ...rest,
  };
}
