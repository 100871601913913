import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const updateProgramSections = async (
  programId: number,
  payload: { sections: number[] },
) =>
  await fetchData(
    `/dietitian/programs/${programId}/sections`,
    APIMethods.PUT,
    payload,
  );
