import styled from "@emotion/styled";
import { AccordionDetails, AccordionSummary, Step } from "@mui/material";

export const ProgramNavigationWrapper = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  width: 23.75rem;
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.boxShadows.light};
  background-color: ${({ theme }) => theme.colors.whiteBackground};
  z-index: 500;
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  min-height: 0.875rem;
  padding: 1rem;
  border-radius: 0.375rem;

  & .MuiAccordionSummary-content {
    margin: 0;
  }
`;

export const StyledTitle = styled.h2`
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: 1rem;
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0 1rem 1rem 1rem;
`;

export const StyledStep = styled(Step)`
  & span {
    font-family: inherit;
  }
`;

export const StyledContent = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.regular};
`;
