import { memo, useCallback, useMemo, useState } from "react";
import {
  useFetchNormNutrients,
  useFetchNutrientCategoriesQuery,
  useFetchNutrientsQuery,
  useFetchProgramDayNutrientsQuery,
} from "@hooks/queries";

import { Nutrient } from "@typeDefinitions";
import { scaleNutrients } from "@utils/nutrients";

import { MicronutrientsList } from "./components";
import { Spinner } from "@components/Spinner";
import { NormsProvider } from "./NormsProvider";
import { Popover, Typography } from "@mui/material";

import {
  StyledMicro,
  StyledMicroIconButton,
} from "./MicronutrientPreview.styled";

interface MicronutrientPreviewProps {
  query?: () => Promise<Nutrient[]>;
  noPadding?: boolean;
  normId?: number;
  factor?: number;
  base?: number;
  means?: Nutrient[];
  programDay?: { programId: number; dayId: number };
  autoMargin?: boolean;
  compact?: boolean;
  targetNutrients?: Nutrient[];
}

export const MicronutrientPreview = memo(
  ({
    query = async (): Promise<Nutrient[]> => [],
    noPadding,
    normId,
    factor,
    base,
    means,
    programDay,
    autoMargin,
    compact,
    targetNutrients,
  }: MicronutrientPreviewProps) => {
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const id = open ? "micronutrient-preview-popover" : undefined;

    const handleClick = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setOpen(true);
      },
      [],
    );

    const handleClose = useCallback(() => {
      setAnchorEl(null);
      setOpen(false);
    }, []);

    const { nutrientCategories, isFetching: categoriesLoading } =
      useFetchNutrientCategoriesQuery({ enabled: open });

    const { nutrients, isFetching: nutrientsLoading } = useFetchNutrientsQuery(
      query,
      { enabled: open && !means },
    );

    const { nutrients: norm, isFetching: normLoading } = useFetchNormNutrients(
      normId ?? 0,
      { enabled: !!normId },
    );

    const { nutrients: normData } = useFetchProgramDayNutrientsQuery(
      programDay?.programId.toString() ?? "0",
      programDay?.dayId.toString() ?? "0",
      { enabled: !!programDay && open },
    );

    const countedGrams = useMemo(() => {
      if (nutrients)
        return scaleNutrients(nutrients, factor ?? base ?? 100, base);
      return;
    }, [nutrients, factor, base]);

    const meansGrams = useMemo(() => {
      if (means) return scaleNutrients(means, factor ?? base ?? 100, base);
      return;
    }, [means, factor, base]);

    return (
      <>
        <StyledMicroIconButton
          noPadding={noPadding}
          autoMargin={autoMargin}
          onClick={handleClick}
        >
          <StyledMicro compact={compact} />
        </StyledMicroIconButton>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClick={e => e.stopPropagation()}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          style={{ zIndex: 99999 }}
        >
          <Typography sx={{ width: 560, overflow: "hidden" }}>
            {categoriesLoading ||
            nutrientsLoading ||
            (normId && normLoading) ? (
              <Spinner className="w-full h-96" />
            ) : (
              <NormsProvider
                norm={
                  targetNutrients ??
                  (normData?.data.nutrients.length
                    ? normData?.data.nutrients
                    : norm)
                }
              >
                <MicronutrientsList
                  nutrients={meansGrams ?? countedGrams ?? []}
                  nutrientCategories={nutrientCategories ?? []}
                />
              </NormsProvider>
            )}
          </Typography>
        </Popover>
      </>
    );
  },
);
