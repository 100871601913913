import { IconButton } from "@components/IconButton";
import {
  NotesSidePanelStyledIconButton,
  NotesSidePanelStyledListItem,
  NotesSidePanelStyledTextField,
  NotesSidePanelStyledContainer,
} from "./NotesSidePanel.styled";
import { Checklist, TrashNew } from "@assets/icons";
import type { FC } from "react";

interface NotesSidePanelItemProps {
  note: string;
  id: number;
  isEditing: boolean;
  onEdit: (id: number) => void;
  onChange: (id: number, text: string) => void;
  onBlur: (id: number) => void;
  onDelete: (id: string) => void;
  setNoteDescription: (description: string) => void;
  setNoteId: (id: number) => void;
  handleOpenCreateModal: () => void;
  textFieldRef?: (element: HTMLInputElement | null) => void;
}

export const NotesSidePanelItem: FC<NotesSidePanelItemProps> = ({
  note,
  id,
  isEditing,
  onEdit,
  onChange,
  onBlur,
  onDelete,
  textFieldRef,
  setNoteDescription,
  handleOpenCreateModal,
  setNoteId,
}) => {
  const handleCreateTaskFromNote = () => {
    setNoteId(id);
    setNoteDescription(note);
    handleOpenCreateModal();
  };

  return (
    <NotesSidePanelStyledListItem key={id}>
      {isEditing ? (
        <NotesSidePanelStyledTextField
          inputRef={textFieldRef}
          value={note}
          onChange={e => onChange(id, e.target.value)}
          onBlur={() => onBlur(id)}
          autoFocus
          size="small"
          fullWidth
          multiline
          inputProps={{ maxLength: 1000 }}
        />
      ) : (
        <div className="flex group/actions">
          <NotesSidePanelStyledIconButton
            onClick={() => onEdit(id)}
            color="neutral-dark"
          >
            <span className="leading-6 whitespace-pre-wrap">{note}</span>
          </NotesSidePanelStyledIconButton>
          <NotesSidePanelStyledContainer className="hidden group-hover/actions:flex">
            <IconButton
              color="gray"
              onClick={handleCreateTaskFromNote}
              className="hover:bg-transparent"
            >
              <Checklist className="text-xl" />
            </IconButton>
            <IconButton
              color="gray"
              className="hover:bg-transparent"
              onClick={() => onDelete(String(id))}
            >
              <TrashNew className="text-md" />
            </IconButton>
          </NotesSidePanelStyledContainer>
        </div>
      )}
    </NotesSidePanelStyledListItem>
  );
};
