import { ProductEditContent } from "../ProductEditContent";
import { StyledDrawer } from "./ProductEditDrawer.styled";

interface ProductEditDrawerProps {
  open: boolean;
  onClose: () => void;
  mealId: string;
  productIds: ProductId | null;
}

export interface ProductId {
  dietProductId: string;
  productId: string;
}

export const ProductEditDrawer = ({
  open,
  onClose,
  mealId,
  productIds,
}: ProductEditDrawerProps) => {
  return (
    <StyledDrawer
      open={open}
      variant="persistent"
      anchor="right"
      onClose={onClose}
    >
      {open && (
        <ProductEditContent
          mealId={mealId}
          productIds={productIds}
          onClose={onClose}
        />
      )}
    </StyledDrawer>
  );
};
