import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Button, Chip, Popover, styled } from "@mui/material";
import _ from "lodash";
import classNames from "classnames";
import {
  CollapseWrapper,
  RoundedButton,
  Wrapper,
} from "@components/OverflowingList/OverflowingList.styled";

export interface OverflowingListProps<T> {
  items: T[];
  renderItem: (item: T, key: number) => ReactNode;
  className?: string;
  itemHeight: number;
  gap: number;
  maxRowsCount: number;
  addonAfter?: ReactNode;
}

const countOverflowing = (container: HTMLDivElement) => {
  const containerRect = container.getBoundingClientRect();

  const overflowingChildren = _.filter(
    container.children,
    (child: HTMLDivElement) =>
      containerRect.bottom < child.getBoundingClientRect().bottom,
  );

  return overflowingChildren.length;
};

const countItemsToHide = (container: HTMLDivElement) => {
  const overflowCount = countOverflowing(container);

  if (0 === overflowCount || 1 === overflowCount) {
    return overflowCount;
  }

  return overflowCount - 1;
};

export const OverflowingList = <T,>({
  items,
  renderItem,
  className,
  itemHeight,
  gap,
  maxRowsCount,
  addonAfter,
}: OverflowingListProps<T>): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);

  const [overflowAnchorEl, setOverflowAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const [state, setState] = useState({
    visible: items as any[],
    overflow: [] as any[],
    overflowCount: 0,
    shouldRecalculate: true,
  });

  const recalculate = (items: any) => {
    const { current } = ref;

    if (!current) {
      return;
    }

    const itemsToHide = countItemsToHide(current);

    setState({
      visible: items.slice(0, items.length - itemsToHide),
      overflow: items.slice(items.length - itemsToHide, items.length),
      overflowCount: itemsToHide,
      shouldRecalculate: false,
    });
  };

  const handleOverflowClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOverflowAnchorEl(event.currentTarget);
  };

  const handleOverflowClose = () => {
    setOverflowAnchorEl(null);
  };

  useLayoutEffect(() => {
    if (!state.shouldRecalculate) {
      return;
    }

    recalculate(items);
  }, [state]);

  useEffect(() => {
    setState({
      visible: items,
      overflow: [],
      overflowCount: 0,
      shouldRecalculate: true,
    });
  }, [items]);

  useEffect(() => {
    if (0 === state.overflowCount && overflowAnchorEl) {
      setOverflowAnchorEl(null);
    }
  }, [state.overflowCount]);

  const wrapperMaxHeight = gap * (maxRowsCount - 1) + itemHeight * maxRowsCount;

  return (
    <Wrapper
      ref={ref}
      gap={gap}
      maxHeight={wrapperMaxHeight}
      className={className}
    >
      {state.visible.map(renderItem)}
      {(state.shouldRecalculate || !!state.overflowCount) && (
        <RoundedButton onClick={handleOverflowClick}>
          +{state.overflowCount}
        </RoundedButton>
      )}
      {addonAfter}
      <Popover
        id="overflowing-list-overflow"
        open={Boolean(overflowAnchorEl)}
        anchorEl={overflowAnchorEl}
        onClose={handleOverflowClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <CollapseWrapper>{state.overflow.map(renderItem)}</CollapseWrapper>
      </Popover>
    </Wrapper>
  );
};
