import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  updatePatientBodyMeasurement,
  UpdatePatientBodyMeasurementParams,
  UpdatePatientBodyMeasurementPayload,
} from "@client/patient/bodyMeasurement/updatePatientBodyMeasurement";
import { fetchClientMeasurementListQueryInfiniteKey } from "@hooks/queries";
import { patientBodyMeasurementKeys } from "@hooks/queries/client/bodyMeasurement/patientBodyMeasurementKey";

const useUpdatePatientBodyMeasurementMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      params,
      payload,
    }: {
      params: UpdatePatientBodyMeasurementParams & { patientId: number };
      payload: UpdatePatientBodyMeasurementPayload;
    }) => {
      return updatePatientBodyMeasurement(params, payload);
    },
    onSuccess: async (_, variables) => {
      const patientId = variables.params.patientId;

      queryClient.invalidateQueries({
        queryKey: patientBodyMeasurementKeys.list(patientId),
      });

      queryClient.invalidateQueries({
        queryKey: patientBodyMeasurementKeys.last(patientId),
      });

      queryClient.invalidateQueries([
        fetchClientMeasurementListQueryInfiniteKey,
        patientId.toString(),
      ]);
    },
  });
};

export default useUpdatePatientBodyMeasurementMutation;
