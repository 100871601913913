import React from "react";
import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Macros = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30.001 42.001">
    <defs>
      <clipPath id="clipPath">
        <path
          id="Union_10"
          data-name="Union 10"
          d="M-8882.57,1078.5v-42h30v42Z"
          transform="translate(9999.57 -552.5)"
          fill="#fff"
        />
      </clipPath>
    </defs>
    <g
      id="Group_4090"
      data-name="Group 4090"
      transform="translate(-1117 -483.999)"
    >
      <g id="Mask_Group_12" data-name="Mask Group 12" clipPath="url(#clipPath)">
        <g
          id="Group_4088"
          data-name="Group 4088"
          transform="translate(563.57 -126.5)"
        >
          <line
            id="Line_267"
            data-name="Line 267"
            x2="8.664"
            transform="translate(567.326 633.44)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <line
            id="Line_268"
            data-name="Line 268"
            x2="8.664"
            transform="translate(567.326 641.163)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <rect
            id="Rectangle_861"
            data-name="Rectangle 861"
            width="3.186"
            height="3.186"
            transform="translate(559.989 631.847)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <rect
            id="Rectangle_862"
            data-name="Rectangle 862"
            width="3.186"
            height="3.186"
            transform="translate(559.989 639.57)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Path_545"
            data-name="Path 545"
            d="M576.235,628.03h1.846a2.224,2.224,0,0,1,2.224,2.224v24.934a2.224,2.224,0,0,1-2.224,2.224H558.654a2.224,2.224,0,0,1-2.224-2.224V630.254a2.224,2.224,0,0,1,2.224-2.224h2.179"
            transform="translate(0 -9.913)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <line
            id="Line_269"
            data-name="Line 269"
            x2="8.664"
            transform="translate(567.326 625.717)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Path_546"
            data-name="Path 546"
            d="M566.637,648.127l1.909,2,2.384-3.186"
            transform="translate(-6.964 -22.813)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Path_547"
            data-name="Path 547"
            d="M587.063,615.809H585.6V613.5h-5.625v2.309h-1.461a.948.948,0,0,0-.948.948v2.722H588.01v-2.722A.948.948,0,0,0,587.063,615.809Z"
            transform="translate(-14.421)"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);
