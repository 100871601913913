import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Close = (props: SvgIconProps) => (
  <SvgIcon {...props} fill={props.fill} viewBox="0 0 10.121 10.123">
    <g id="icon-close" transform="translate(1.061 1.061)">
      <path
        d="M7.993,0,0,7.993"
        transform="translate(0.004 0.003)"
        fill="none"
        stroke={props.fill ? props.fill : "currentColor"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M8,8,0,0"
        fill="none"
        stroke={props.fill ? props.fill : "currentColor"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  </SvgIcon>
);
