import { Control, FieldValues, Path, useController } from "react-hook-form";

import { Slider } from "@mui/material";
import { isArray } from "lodash";

import { MediumThumbComponent } from "@components/SliderThumbs";
import { useAppTranslation } from "@hooks";

import {
  Label,
  LabelWrapper,
  Value,
  Wrapper,
} from "./FormServingsSlider.styled";

interface FormServingsSliderProps<T extends FieldValues> {
  name: Path<T>;
  control?: Control<T>;
  totalServings?: number;
}
export const FormServingsSlider = <T extends FieldValues>({
  name,
  control,
  totalServings = 20,
}: FormServingsSliderProps<T>) => {
  const { t } = useAppTranslation();
  const {
    field: { value, onChange },
  } = useController({ name, control });
  const handleSliderChange = (value: number) => {
    onChange(value);
  };

  const SINGLE_SERVING = 1;
  const FIVE_SERVINGS = 5;

  return (
    <Wrapper>
      <LabelWrapper>
        <Label>{t("recipe.ingredients_table.servings")}:</Label>
        <Value>{value}</Value>
      </LabelWrapper>

      <Slider
        slots={{ thumb: MediumThumbComponent }}
        value={value}
        max={totalServings === SINGLE_SERVING ? FIVE_SERVINGS : totalServings}
        min={1}
        onChange={(e, v) => !isArray(v) && handleSliderChange(v)}
      />
    </Wrapper>
  );
};
