import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import IrrigationBoxOfDay from "@views/dietician/PatientMonitoring2/components/Irrigation/IrrigationBoxOfDay";
import IrrigationBoxOfDays from "@views/dietician/PatientMonitoring2/components/Irrigation/IrrigationBoxOfDays";

const IrrigationBoxFactory = () => {
  const { type } = useMonitoring();

  return type.type === "day" ? <IrrigationBoxOfDay /> : <IrrigationBoxOfDays />;
};

export default IrrigationBoxFactory;
