import styled from "@emotion/styled";

interface IPopmenuWrapperProps {
  isOverflow?: boolean;
}

export const PopmenuWrapper = styled.div<IPopmenuWrapperProps>`
  max-width: 30rem;
  min-width: 2rem;
  max-height: 350px;
  overflow-y: ${({ isOverflow }) => (isOverflow ? "auto" : "unset")};
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(241, 241, 241, 1);
  border-radius: 0.375rem;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  z-index: 100;

  &:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
`;
