import { Button, styled } from "@mui/material";

interface ColorsListWrapperProps {
  outlined?: boolean;
}

interface ColorButtonProps {
  outlined?: boolean;
  selected?: boolean;
}

interface CircleProps {
  backgroundColor: string;
}

export const ColorsListWrapper = styled("div")<ColorsListWrapperProps>`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  ${props =>
    props.outlined &&
    `
       gap: 4px;
     `}
`;

export const ColorButton = styled(Button, {
  shouldForwardProp: prop => prop !== "outlined" && prop !== "selected",
})<ColorButtonProps>`
  padding: 0;
  width: 40px;
  height: 40px;
  min-width: 40px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.light[200]};

  ${props =>
    !props.outlined &&
    `
        width: 24px;
        height: 24px;
        min-width: 24px;
        border-radius: 24px;
     `}

  ${props =>
    props.selected &&
    props.outlined &&
    `
        border-color: ${props.theme.palette.primary.main};
     `}
`;

export const ColorCircle = styled("div")<CircleProps>`
  min-width: 24px;
  height: 24px;
  border-radius: 24px;
  text-align: center;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary.light};
  background-color: ${props => props.backgroundColor};
`;
