import { useCallback, useState } from "react";
import { toast } from "react-toastify";

import { Edit, GreenCircle, RedCircle } from "@assets/icons";
import {
  useAppTranslation,
  useModalState,
  useNutrientDictionary,
} from "@hooks";
import { ProgramPdfBasicData } from "@typeDefinitions";

import {
  useCloneClientProgramToBaseMutation,
  useFetchClientQuery,
  usePostClientActivateProgram,
  useUpdateProgramName,
} from "@hooks/queries";
import { Card } from "@components/Card";
import { IconButton } from "@components/IconButton";

import {
  ActiveButton,
  DownloadProgramPdf,
  LoadingButtonStyled,
  MiddleCardWrapper,
  NutrientLabel,
  NutrientsSectionWrapper,
  RightSideTopLineHeader,
  SectionLabel,
  SectionWrapper,
  TitleLabel,
  ToolsSectionWrapper,
  TopLineHeaderCardWrapper,
} from "./";
import {
  ProgramTitleEditModal,
  TitleInput,
} from "../ProgramTitleEditModal/ProgramTitleEditModal";
import { differenceInDays, format } from "date-fns";
import { SaveToBaseModal } from "../SaveToBaseModal";
import { ShowInAppButton } from "./ShowInAppButton";
import { styled, Tooltip } from "@mui/material";
import useProgramDate from "@hooks/program/useProgramDate";
import { StatusChip } from "./StatusChip";

interface Program {
  id: number;
  name: string;
  nameEn?: string;
  durationDays?: number | null;
  hasMonitoring: boolean;
  hasChat: boolean;
  hasTasks: boolean;
  hasEducation: boolean;
  hasShoppingList: boolean;
  hasDiets: boolean;
  hasDiary: boolean;
  hasMacros: boolean;
  hasNewsfeed: boolean;
  startDate?: string;
  finishDate?: string | null;
  isActive?: boolean;
}

interface ProgramInfoCardProps {
  program: Program;
  nutrients: number[] | null;
  patientId?: number;
  pdf?: ProgramPdfBasicData;
  canEdit?: boolean;
  tools?: boolean;
  shared?: boolean;
}

export const ProgramInfoCard = ({
  program,
  nutrients,
  patientId,
  canEdit,
  shared,
}: ProgramInfoCardProps) => {
  const { nutrientDictionary } = useNutrientDictionary();
  const { t, isPolishChosen } = useAppTranslation();
  const [editVisible, setEditVisible] = useState(false);
  const [openSaveToBase, onOpenSaveToBase, onCloseSaveToBase] = useModalState();
  const { client } = useFetchClientQuery(patientId ?? "", {
    enabled: !!patientId,
  });
  const { isPlanned, isActive } = useProgramDate({
    startDate: program.startDate ?? "",
    endDate: program.finishDate ?? null,
  });

  const onOpen = useCallback(() => setEditVisible(true), [setEditVisible]);

  const onClose = useCallback(() => setEditVisible(false), [setEditVisible]);

  const { mutate: updateProgramName } = useUpdateProgramName(program.id);

  const { mutate, isLoading } = usePostClientActivateProgram(program.id);

  const handleActive = useCallback(
    () => mutate({ activate: !program.isActive, patientId: patientId ?? 0 }),
    [mutate, program.isActive, patientId],
  );

  const onSubmit = useCallback(
    (d: TitleInput) => {
      setEditVisible(false);
      updateProgramName({
        name: isPolishChosen ? d.title ?? program.name : program.name,
        nameEn: isPolishChosen ? program.nameEn : d.title ?? program.nameEn,
      });
    },
    [setEditVisible],
  );

  return (
    <Card
      className="mb-6"
      header={
        <TopLineHeaderCardWrapper>
          <div className="flex items-center">
            <h2 className="text-2xl mr-3">
              {isPolishChosen ? program.name : program?.nameEn || program.name}
            </h2>
            <IconButton onClick={onOpen} disabled={!canEdit}>
              <Edit size="w-5 h-5" />
            </IconButton>
          </div>
          <RightSideTopLineHeader>
            {patientId && (
              <>
                <ShowInAppButton shared={!!shared} />
                <LoadingButtonStyled
                  onClick={onOpenSaveToBase}
                  variant="outlined"
                >
                  {t("program.summary.save_to_base")}
                </LoadingButtonStyled>
                <DownloadProgramPdf
                  programId={program.id}
                  patientId={patientId}
                />
              </>
            )}
          </RightSideTopLineHeader>
        </TopLineHeaderCardWrapper>
      }
    >
      <div className="flex flex-col gap-[16px] items-start">
        <MiddleCardWrapper>
          <SectionWrapper>
            <TitleLabel>
              {t("program.summary.program_info_card.duration")}:
            </TitleLabel>
            {program.durationDays && (
              <SectionLabel>{`${program.durationDays} ${t(
                "common.days",
              )}`}</SectionLabel>
            )}
            {program.startDate && program.finishDate && (
              <>
                <SectionLabel>
                  {`${t(
                    "program.summary.program_info_card.program_start",
                  )}: ${format(new Date(program.startDate), "dd.MM.yyyy")}`}
                </SectionLabel>
                <SectionLabel>
                  {`${t(
                    "program.summary.program_info_card.program_end",
                  )}: ${format(
                    new Date(program.finishDate),
                    "dd.MM.yyyy",
                  )} (${differenceInDays(
                    new Date(program.finishDate),
                    new Date(program.startDate),
                  )} ${t("common.days")})`}
                </SectionLabel>
              </>
            )}
          </SectionWrapper>
          {!patientId && (
            <SectionWrapper>
              <TitleLabel>
                {t("program.summary.program_info_card.tools")}:
              </TitleLabel>
              <ToolsSectionWrapper>
                {program.hasMonitoring && (
                  <SectionLabel>
                    {t("program.info.has_monitoring")}
                  </SectionLabel>
                )}

                {program.hasChat && (
                  <SectionLabel>{t("program.info.has_chat")}</SectionLabel>
                )}

                {program.hasTasks && (
                  <SectionLabel>{t("program.info.has_tasks")}</SectionLabel>
                )}

                {program.hasEducation && (
                  <SectionLabel>{t("program.info.has_education")}</SectionLabel>
                )}

                {program.hasShoppingList && (
                  <SectionLabel>
                    {t("program.info.has_shopping_list")}
                  </SectionLabel>
                )}

                {program.hasDiets && (
                  <SectionLabel>{t("program.info.has_diets")}</SectionLabel>
                )}

                {program.hasDiary && (
                  <SectionLabel>{t("program.info.has_diary")}</SectionLabel>
                )}

                {program.hasMacros && (
                  <SectionLabel>{t("program.info.has_macros")}</SectionLabel>
                )}

                {program.hasNewsfeed && (
                  <SectionLabel>{t("program.info.has_newsfeed")}</SectionLabel>
                )}
              </ToolsSectionWrapper>
            </SectionWrapper>
          )}

          <SectionWrapper>
            <TitleLabel>
              {t("program.summary.program_info_card.microelements")}:
            </TitleLabel>
            {nutrients && (
              <NutrientsSectionWrapper>
                {nutrients.length > 0 ? (
                  nutrients.map(nutrient => (
                    <NutrientLabel key={nutrient}>
                      {nutrientDictionary[nutrient]?.name}
                    </NutrientLabel>
                  ))
                ) : (
                  <p>{t("program.summary.program_info_card.no_chosen")}</p>
                )}
              </NutrientsSectionWrapper>
            )}
          </SectionWrapper>
        </MiddleCardWrapper>
        <div className="flex gap-[4px] items-center">
          <StatusLabel>{t("patient.programs.program_status")}:</StatusLabel>
          <StatusChip
            program={{
              startDate: program.startDate ?? "",
              endDate: program.finishDate ?? null,
            }}
          />
        </div>
      </div>

      {editVisible && (
        <ProgramTitleEditModal
          onClose={onClose}
          onSubmit={onSubmit}
          defaultTitle={
            isPolishChosen ? program.name ?? "" : program.nameEn ?? ""
          }
        />
      )}

      {patientId && (
        <SaveToBaseModal
          open={openSaveToBase}
          onClose={onCloseSaveToBase}
          defaultName={program.name}
          programId={program.id}
        />
      )}
    </Card>
  );
};

const StatusLabel = styled("span")`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;
