import { useAppTranslation, useModal } from "@hooks";
import {
  DetailsTypography,
  NameCellStyled,
  NameTypography,
} from "./QuestionnaireName.styled";
import { QuestionnaireAnswerPreviewModal } from "@views/public/QuestionnaireAnswerPreviewModal/QuestionnaireAnswerPreviewModal";

interface QuestionaireNameProps {
  name: string;
  questionsAmount: number;
  id: number;
}

export const QuestionnaireName = ({
  name,
  questionsAmount,
  id,
}: QuestionaireNameProps) => {
  const { t } = useAppTranslation();
  const {
    modalOpened: previewOpen,
    onModalOpen: onPreviewOpen,
    onModalClose: onPreviewClose,
  } = useModal();
  return (
    <>
      <NameCellStyled onClick={onPreviewOpen}>
        <NameTypography>{name}</NameTypography>
        <DetailsTypography>
          {t("questionnaires.questions_amount")}: {questionsAmount}
        </DetailsTypography>
      </NameCellStyled>
      <QuestionnaireAnswerPreviewModal
        id={id}
        open={previewOpen}
        onClose={onPreviewClose}
      />
    </>
  );
};
