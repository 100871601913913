import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Spinner } from "@components/Spinner";
import { ApiError } from "@components/ApiError";
import {
  mapRecipeForm,
  mapRecipeRequest,
  useRecipeEditForm,
} from "@components/RecipeForm/useRecipeEditForm";
import { useCreateRecipeMutation, useFetchRecipeQuery } from "@hooks/queries";
import { useAppParams } from "@hooks";

import { RecipeFormWrapper } from "./RecipeEdit.styled";

export const RecipeCopy = () => {
  const [init, setInit] = useState(true);
  const { recipeId } = useAppParams();
  const copiedRecipeId = recipeId ? parseInt(recipeId) : NaN;

  const { recipe, isLoading, isError, isSuccess } =
    useFetchRecipeQuery(copiedRecipeId);

  const navigate = useNavigate();

  const recipeCopy = useMemo(() => {
    const mappedRecipe = mapRecipeForm(recipe);
    if (!mappedRecipe) return;

    return {
      ...mappedRecipe,
      namePl: `${mappedRecipe.namePl} (Kopia)`,
      nameEn: `${mappedRecipe.nameEn} (Copy)`,
    };
  }, [recipe]);

  const {
    handleSubmit,
    formState: { isValid },
  } = useRecipeEditForm(recipeCopy);

  const {
    mutate,
    isLoading: isMutationLoading,
    isError: isMutationError,
  } = useCreateRecipeMutation({
    onSuccess: data => {
      setInit(false);
      navigate(`/recipes/${data?.id}/edit`, { replace: true });
    },
  });

  useEffect(() => {
    if (init && isValid && isSuccess)
      handleSubmit(
        data => mutate({ payload: mapRecipeRequest(data, null, null) }),
        err => console.log(err),
      )();
  }, [init, isValid]);

  if (init || isMutationLoading || isLoading)
    return (
      <RecipeFormWrapper>
        <Spinner />
      </RecipeFormWrapper>
    );

  if (isMutationError || isError)
    return (
      <RecipeFormWrapper>
        <ApiError />
      </RecipeFormWrapper>
    );

  return <></>;
};
