import { memo, useCallback, useState } from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

import { Checkbox, CheckboxProps } from "@components/Checkbox";

interface FormCheckboxProps<T extends FieldValues> extends CheckboxProps {
  name: Path<T>;
  control: Control<T>;
  defaultChecked?: boolean;
}

export const FormCheckbox = memo(
  <T extends FieldValues>(props: FormCheckboxProps<T>) => {
    const { defaultChecked, name, control, ...rest } = props;
    const [checked, setChecked] = useState(true);

    const onChangeChecked = useCallback(() => {
      if (defaultChecked) {
        setChecked(!checked);
      }
    }, [checked]);

    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value, ref } }) => (
          <Checkbox
            name={name}
            value={defaultChecked ? checked : !!value}
            onChange={defaultChecked ? onChangeChecked : onChange}
            ref={ref}
            {...rest}
          />
        )}
      />
    );
  },
);
