import { CellWrapper } from "../ClientsTable";
import { useAppTranslation } from "@hooks";
import { CheckInGreenRound, XinRedRound } from "@assets/icons";
import { Tooltip } from "@mui/material";

interface MobileAppComponentProps {
  isMobileAppActive?: boolean;
}

export const MobileAppComponent = ({
  isMobileAppActive,
}: MobileAppComponentProps) => {
  const { t } = useAppTranslation();

  return (
    <Tooltip
      title={
        isMobileAppActive
          ? t("clients.table.active")
          : t("clients.table.inactive_app")
      }
      arrow
      placement="top"
    >
      <CellWrapper centered>
        {isMobileAppActive ? (
          <CheckInGreenRound size="w-5 h-5" />
        ) : (
          <XinRedRound size="w-5 h-5" />
        )}
      </CellWrapper>
    </Tooltip>
  );
};
