import { PaymentType } from "@utils";
import * as yup from "yup";

import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";

import { IdDto, LangDto, idSchema } from "./common";

export const registerClinic = async (
  payload: RegisterClinicRequest,
): Promise<ApiResponse<RegisterClinicResponse>> => {
  const response = await fetchData(
    `/register-clinic`,
    APIMethods.POST,
    payload,
  );
  return registerClinicResponseSchema.validate(response);
};
export interface RegisterClinicRequest {
  firstName: string;
  lastName: string;
  email: string;
  termsOfService: number;
  subscriptionType: string | null;
  paymentType: PaymentType | null;
  lang: LangDto;
  code?: string;
}

export interface RegisterClinicResponse {
  owner: IdDto;
}

const clinicResourceSchema = yup.object().shape({
  owner: idSchema.required(),
});
const registerClinicResponseSchema = yup.object().shape({
  data: clinicResourceSchema.required(),
});
