import React from "react";

import { ScheduleGridRowWrapper } from "./ScheduleGridRowWrapper.styled";
import { EmptyScheduleGridDay } from "../ScheduleGridDay/EmptyScheduleGridDay";

interface ScheduleGridRowProps {
  date: string;
  color: string;
  startDate?: string;
}

export const EmptyScheduleGridRow = ({
  color,
  date,
  startDate,
}: ScheduleGridRowProps) => {
  return (
    <ScheduleGridRowWrapper>
      <EmptyScheduleGridDay date={date} color={color} startDate={startDate} />
      <div className="w-full flex" />
    </ScheduleGridRowWrapper>
  );
};
