import { Controller, useFormContext } from "react-hook-form";
import { FormControlLabel, Switch } from "@mui/material";
import { useAppTranslation } from "@hooks";
import { FormPops } from "@views/dietician/ClientHealth/ProfileTab/NutrientsSection/Dialog/NutrientsForm";
import NutrientsLabelLayout from "@views/dietician/ClientHealth/ProfileTab/NutrientsSection/Dialog/NutrientsLabelLayout";

const MealsMacroTargetField = () => {
  const { t } = useAppTranslation();
  const { control, watch } = useFormContext<FormPops>();
  const macroChecked = watch("macro");

  return (
    <Controller
      control={control}
      name="targetMeals"
      render={({ field: { onChange, value } }) => (
        <FormControlLabel
          disabled={!macroChecked}
          sx={{
            margin: "unset",
            alignItems: "self-start",
            gap: "8px",
            marginLeft: "28px",
          }}
          control={
            <Switch
              color="primary"
              checked={value}
              onChange={onChange}
              sx={{ marginTop: "4px" }}
            />
          }
          label={
            <NutrientsLabelLayout
              label={t(
                "patient.health_and_goal.dietary_profile_tab.nutritional_values_modal.meals_target",
              )}
              description={t(
                "patient.health_and_goal.dietary_profile_tab.nutritional_values_modal.meals_target_desc",
              )}
            />
          }
        />
      )}
    />
  );
};

export default MealsMacroTargetField;
