import { createContext, ReactNode, useMemo } from "react";

import { useProgramId } from "@hooks/useProgramId";
import { useDayId } from "@hooks/useDayId";
import { useFetchProgramDayNutrientsQuery } from "@hooks/queries";

interface ProgramContextIf {
  programId?: number;
  dayId?: number;
  microsShownToDietitian: number[];
}

export const ProgramContext = createContext<ProgramContextIf>({
  microsShownToDietitian: [],
});

interface ProgramContextProviderProps {
  children: ReactNode;
  dayId?: number;
}

export const ProgramContextProvider = ({
  children,
  dayId: dayIdProps,
}: ProgramContextProviderProps) => {
  const programId = useProgramId();
  const dayIdParams = useDayId();
  const dayId = dayIdProps ?? dayIdParams!;
  const { nutrients } = useFetchProgramDayNutrientsQuery(
    programId?.toString() ?? "",
    dayId?.toString() ?? "",
    { enabled: !!programId && !!dayId },
  );

  const context: ProgramContextIf = useMemo(
    () => ({
      programId,
      dayId,
      microsShownToDietitian:
        nutrients?.data.nutrients.filter(n => n.visible).map(n => n.id) ?? [],
    }),
    [nutrients],
  );

  return (
    <ProgramContext.Provider value={context}>
      {children}
    </ProgramContext.Provider>
  );
};
