import {
  DEFAULT_DIETS_MACROS_RANGE,
  DietsFiltersFormInput,
} from "./useDietsFiltersForm";

export const defaultFilters: DietsFiltersFormInput = {
  tags: [],
  nutrients: DEFAULT_DIETS_MACROS_RANGE,
  query: "",
  numberOfMeals: [],
};
