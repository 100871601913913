import styled from "@emotion/styled";

interface ContainerWrapperProps {
  larger?: boolean;
}

export const ContainerWrapper = styled.div<ContainerWrapperProps>`
  width: 70vw;
  max-width: ${({ larger }) => (larger ? 900 : 720)}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding-top: 36px;
  padding-bottom: 36px;
  border-radius: 6px;
  box-shadow: 0 2px 10px #0000000f;
`;
