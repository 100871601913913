import * as yup from "yup";

import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { validateAndFixNutrients } from "../utils/validateCatching";

export async function fetchProgramDietMeals(
  dietId: number,
): Promise<ApiResponse<Meal[]>> {
  const data = await fetchData(`/diets/${dietId}/meals`, APIMethods.GET);
  return await validateAndFixNutrients(fetchProgramDietMealsSchema, data);
}

const macroSchema = yup.object().shape({
  id: yup.number().required(),
  nutrientCategoryId: yup.number().required(),
  descriptionEn: yup.string().required(),
  descriptionPl: yup.string().required(),
  units: yup.string().nullable(),
  dietitianVisible: yup.boolean().required(),
  patientVisible: yup.boolean().required(),
  diaryVisible: yup.boolean().required(),
  value: yup.number().required(),
});

const recipeSchema = yup.object().shape({
  id: yup.number().required(),
  clinicId: yup.number().nullable(),
  title: yup.string().required(),
  titleEn: yup.string().nullable(),
  description: yup.string().nullable(),
  descriptionEn: yup.string().nullable(),
  macros: yup.array().of(macroSchema).required(),
  reviewed: yup.boolean().nullable(),
  isComplex: yup.boolean().nullable(),
  servings: yup.number().nullable(),
  totalPortions: yup.number().nullable(),
});

const fetchProgramDietMealsSchema = yup.object().shape({
  data: yup.array().of(
    yup.object().shape({
      id: yup.number().required(),
      clinicId: yup.number().nullable(),
      dietId: yup.number().required(),
      title: yup.string().nullable(),
      titleEn: yup.string().nullable(),
      hour: yup.string().required(),
      kcal: yup.number().nullable(),
      kcalReal: yup.number().nullable(),
      description: yup.string().nullable(),
      descriptionEn: yup.string().nullable(),
      hideInSchedule: yup.boolean().required(),
      hideInProgramList: yup.boolean().required(),
      excludeDietNutrition: yup.boolean().required(),
      isDaily: yup.boolean().nullable(),
      recipes: yup.array().of(recipeSchema).required(),
      macros: yup.array().of(macroSchema).required(),
    }),
  ),
});

interface Meal {
  id: number;
  clinicId?: number;
  dietId?: number;
  title?: string;
  titleEn?: string;
  hour: string;
  kcal?: number;
  kcalReal?: number;
  description?: string;
  descriptionEn?: string;
  hideInSchedule: boolean;
  hideInProgramList: boolean;
  excludeDietNutrition: boolean;
  isDaily?: boolean;
  recipes: Recipe[];
  macros: Macro[];
}

interface Recipe {
  id: number;
  clinicId?: number;
  title: string;
  titleEn?: string;
  description?: string;
  descriptionEn?: string;
  macros: Macro[];
  reviewed?: boolean;
  isComplex?: boolean;
  servings?: number;
  totalPortions?: number;
}

interface Macro {
  id: number;
  nutrientCategoryId: number;
  descriptionEn: string;
  descriptionPl: string;
  units?: string;
  dietitianVisible: boolean;
  patientVisible: boolean;
  diaryVisible: boolean;
  value: number;
}
