import { useContext, useMemo, useState } from "react";

import { AlertTitle, Autocomplete, TextField, Typography } from "@mui/material";
import { omit, range, round } from "lodash";

import { useAppTranslation, useClientBalanceForecast } from "@hooks";

import { AlertStyled, ForecastsWrapper } from "./MassForecast.styled";
import { SimulationSlidersContext } from "@views/dietician/PatientEnergyBalance/context/SimulationSlidersContext";
import { SearchNoResults } from "@views/emptyStates/SearchNoResults";

const DAYS_IN_WEEK = 7;

export const MassForecast = () => {
  const { t, currentLanguage } = useAppTranslation();
  const [daysCount, setDaysCount] = useState(DAYS_IN_WEEK);
  const { weeksToGoal } = useContext(SimulationSlidersContext);
  const validWeeks = weeksToGoal > 0;

  const { differenceForDays, differenceInKg, differenceInPercentage } =
    useClientBalanceForecast(daysCount);

  const daysOptions = useMemo(() => {
    return range(
      DAYS_IN_WEEK,
      (weeksToGoal + 2 || 2) * DAYS_IN_WEEK,
      DAYS_IN_WEEK,
    ).map(day => ({
      id: day,
      label: `${day / DAYS_IN_WEEK} ${t("balance.weeks", {
        count: day / DAYS_IN_WEEK,
      })}`,
    }));
  }, [weeksToGoal, currentLanguage]);

  return (
    <div className="grid gap-4">
      <div className="flex justify-between items-center">
        <Typography variant="h6">{t("balance.mass_forecast")}</Typography>
        <Autocomplete
          noOptionsText={<SearchNoResults />}
          size="small"
          renderInput={params => (
            <TextField
              {...omit(params, "InputLabelProps")}
              label={t("balance.prediction_for") + ":"}
              sx={{ minWidth: "200px" }}
            />
          )}
          defaultValue={daysOptions[0]}
          onChange={(_, val) => setDaysCount(val?.id ?? 7)}
          options={daysOptions}
          disableClearable
        />
      </div>
      <ForecastsWrapper>
        <AlertStyled severity="info" variant="outlined" icon={false}>
          <AlertTitle>{t("balance.kcal_balance")}</AlertTitle>
          <Typography variant="h5">{round(differenceForDays)} kcal</Typography>
        </AlertStyled>
        <AlertStyled severity="info" variant="outlined" icon={false}>
          <AlertTitle>{t("balance.kg_change")}</AlertTitle>
          <Typography variant="h5">{round(differenceInKg, 2)} kg</Typography>
        </AlertStyled>
        <AlertStyled severity="info" variant="outlined" icon={false}>
          <AlertTitle>{t("balance.percent_change")}</AlertTitle>
          <Typography variant="h5">
            {round(differenceInPercentage, 2)}%
          </Typography>
        </AlertStyled>
        <AlertStyled
          severity={validWeeks ? "info" : "error"}
          variant="outlined"
          icon={false}
        >
          <AlertTitle>{t("balance.target_time")}</AlertTitle>
          <Typography variant="h5">
            {validWeeks
              ? `${weeksToGoal} ${t("balance.weeks", { count: weeksToGoal })}`
              : " - "}
          </Typography>
        </AlertStyled>
      </ForecastsWrapper>
    </div>
  );
};
