import { useAppParams } from "@hooks";
import { MutationOptions } from "@hooks/queries";
import { usePostCreatorDietMealsItemsMutation } from "@hooks/queries/diets/creator";
import { usePostProgramDietMealsItemsMutation } from "@hooks/queries/program/dietCreator";

export const usePostDietMealsItemsMutation = (options?: MutationOptions) => {
  const { programId } = useAppParams();

  return programId
    ? usePostProgramDietMealsItemsMutation(programId, options)
    : usePostCreatorDietMealsItemsMutation(options);
};
