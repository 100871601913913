import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";
import { LangDto } from "./common";

export const login = async (payload: LoginRequest) => {
  return await fetchData("/login", APIMethods.POST, payload);
};

export interface LoginRequest {
  email: string;
  password: string;
  lang: LangDto;
}
