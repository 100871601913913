import { styled } from "@mui/material";

export const Title = styled("span")`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const ImgBackground = styled("div")`
  background: ${({ theme }) => theme.palette.primary.light2};
  height: 52px;
  width: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
`;

interface ImgStyledProps {
  radius?: boolean;
}

export const PlaceholderStyled = styled("img")`
  height: 44px;
`;

export const ImgStyled = styled("img")<ImgStyledProps>`
  height: 52px;
  width: 52px;
  border-radius: 8px;
  object-fit: cover;
`;
