import { Stack } from "@mui/material";
import { useState } from "react";
import { ProgramSearchPreview } from "@client/program/preview/fetchProgramPreview";
import TypeDays from "@components/ProgramSearchPreview/Components/TypeDaysChips/TypeDays";
import Diet from "@components/ProgramSearchPreview/Components/Diet/Diet";

type TypeDaysComponentProgramPatientPreviewProps = {
  program: ProgramSearchPreview;
};

const TypeDaysComponentProgram = ({
  program,
}: TypeDaysComponentProgramPatientPreviewProps) => {
  const [selectedDay, setSelectedDay] = useState(
    () => program.programDays[0].id,
  );

  const selectedDietId = program.programDays.find(day => day.id === selectedDay)
    ?.diet?.id;

  return (
    <Stack direction="column" gap="24px">
      <TypeDays
        programDays={program.programDays}
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
      />

      {selectedDietId !== undefined && (
        <Diet programId={program.id} dietId={selectedDietId} />
      )}
    </Stack>
  );
};

export default TypeDaysComponentProgram;
