import { useMutation, useQueryClient } from "@tanstack/react-query";

import { CreatorDietMealsItemsRequest } from "@client/diets/creator";
import { postProgramDietMealsItems } from "@client/program/dietCreator";
import {
  fetchCreatorDietMealNutrientsQueryKey,
  fetchCreatorDietMealsItemsQuery,
  fetchCreatorDietMealsQueryKey,
  fetchCreatorDietNutrientsQueryKey,
  fetchCreatorDietQueryKey,
} from "@hooks/queries/diets/creator";
import { useCreatorVersion } from "@hooks/queries/diets/creator/useCreatorVersion";
import { MutationOptions } from "@hooks/queries/types";
import { fetchDietRecipeUsedQueryKey } from "@hooks/queries/diets";

export const usePostProgramDietMealsItemsMutation = (
  programId: string,
  options?: MutationOptions,
) => {
  const { version } = useCreatorVersion();
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    unknown,
    UsePostProgramDietMealsItemsMutationPayload
  >(
    ({ dietId, payload }) =>
      postProgramDietMealsItems(programId, dietId, payload, version),
    {
      ...options,
      onSuccess: (d, v) => {
        options?.onSuccess && options.onSuccess(d, v);
        queryClient.invalidateQueries([
          fetchCreatorDietMealsItemsQuery,
          v.dietId,
        ]);
        queryClient.invalidateQueries([
          fetchCreatorDietMealsQueryKey,
          v.dietId,
        ]);
        queryClient.invalidateQueries([fetchCreatorDietQueryKey, v.dietId]);
        queryClient.invalidateQueries([
          fetchCreatorDietNutrientsQueryKey,
          v.dietId,
        ]);
        queryClient.invalidateQueries([fetchDietRecipeUsedQueryKey, v.dietId]);
        queryClient.invalidateQueries([
          fetchCreatorDietMealNutrientsQueryKey,
          v.dietId,
        ]);
      },
    },
  );
};

interface UsePostProgramDietMealsItemsMutationPayload {
  dietId: string;
  payload: CreatorDietMealsItemsRequest;
}
