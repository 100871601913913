import { useQuery } from "@tanstack/react-query";

import { QueryOptions } from "./types";
import { buildDefaultQueryOptions } from "./common";
import { fetchProgramDayNutrients } from "@client";

export const fetchProgramDayNutrientsQueryKey = "fetchProgramDayNutrients";

export const useFetchProgramDayNutrientsQuery = (
  programId: string,
  dayId: string,
  options?: QueryOptions,
) => {
  const { data: nutrients, ...rest } = useQuery(
    [fetchProgramDayNutrientsQueryKey, programId, dayId],
    () => fetchProgramDayNutrients(programId, dayId),
    buildDefaultQueryOptions(options),
  );
  return { nutrients, ...rest };
};
