import { fetchData } from "@utils/api";
import { APIMethods, ApiEndpoints } from "@typeDefinitions";
import { object, number, string, array } from "yup";

import {
  PaginationLinks,
  Metadata,
  MetadataSchema,
  PaginationLinksSchema,
} from "@typeDefinitions";
import qs from "qs";

export const fetchNotes = async (page: string) => {
  const composedQueryParams = qs.stringify({
    page,
  });

  const response = await fetchData<NotesResponse>(
    `${ApiEndpoints.AccountNotes}?${composedQueryParams}`,
    APIMethods.GET,
  );

  return await noteResponseSchema.validate(response);
};

export interface Note {
  id: number;
  note: string;
}

export interface NotesResponse {
  data: Note[];
  links: PaginationLinks;
  meta: Metadata;
}

const noteSchema = object({
  id: number().required(),
  note: string().required(),
});

export const noteResponseSchema = object({
  data: array().of(noteSchema).required(),
  links: PaginationLinksSchema.defined(),
  meta: MetadataSchema.defined(),
});
