import { Accordion, AccordionSummary, styled } from "@mui/material";

export const AccordionSummaryStyled = styled(AccordionSummary)`
  font-weight: 500;
  & .Mui-expanded {
    margin: unset;
  }

  &.MuiButtonBase-root.MuiAccordionSummary-root {
    height: 2.5rem;
    min-height: unset;
  }
`;

export const AccordionStyled = styled(Accordion)`
  max-width: 40.5rem;
  box-shadow: unset;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.neutral.light[200]};

  &.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded {
    border-radius: 0.5rem;
  }
  & .MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-gutters {
    padding: 0 0.75rem;
  }
  &.MuiPaper-root.MuiAccordion-root.Mui-disabled {
    background-color: ${({ theme }) => theme.colors.neutral.light[100]};
  }
  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
  }
  &.MuiPaper-root.MuiAccordion-rounded.Mui-expanded {
    margin-top: unset;
  }
  ::before {
    display: none;
  }
`;
