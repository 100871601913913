import { styled } from "@mui/material";
import { CupMonitoring } from "@assets/icons/Monitoring";

const IrrigationCircle = () => {
  return (
    <Circle>
      <CupMonitoring fill="#FAB569" size="" />
    </Circle>
  );
};

const Circle = styled("div")`
  width: 40px;
  height: 40px;
  border-radius: 80px;
  background: #fff6ed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export default IrrigationCircle;
