import { Box } from "@mui/material";
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { StyledTab, StyledTabList } from "./EditClientMeasurementModal.styled";
import { ModalShell } from "@components/ModalShell";
import { TabContext } from "@mui/lab";
import {
  mapUpdateClientForm,
  mapUpdateClientMeasurementForm,
  mapUpdateClientMeasurementRequest,
  mapUpdateClientRequest,
  useAppTranslation,
  useUpdateClientMeasurementForm,
} from "@hooks";
import { StyledTabPanel } from "@components/EditClientInfoModal";
import { MeasurementSection } from "./components/MeasurementSection";
import {
  useFetchClientLastMeasurementQuery,
  useFetchClientQuery,
  useUpdateClientMeasurementMutation,
  useUpdateClientMutation,
} from "@hooks/queries";
import { GoalsSection } from "./components/GoalsSection";

interface AddClientMeasurementModalProps {
  open: boolean;
  onClose: () => void;
  id: string;
}

export const EditClientMeasurementModal = ({
  open,
  onClose,
  id,
}: AddClientMeasurementModalProps) => {
  const { t } = useAppTranslation();
  const { lastMeasurement } = useFetchClientLastMeasurementQuery(id);
  const measurementId = lastMeasurement?.id;

  const { mutate, isLoading } = useUpdateClientMeasurementMutation();
  const { mutate: updateClientMutate, isLoading: isLoadingUpdateClient } =
    useUpdateClientMutation(Number(id));

  const { client } = useFetchClientQuery(id);

  enum ClientMeasurementTabs {
    GOALS = "1",
    MEASUREMENTS = "2",
  }
  const mappedForm = useMemo(
    () => mapUpdateClientMeasurementForm(client, lastMeasurement),
    [client, lastMeasurement],
  );
  const { control, handleSubmit, reset } =
    useUpdateClientMeasurementForm(mappedForm);

  const onCloseWithReset = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  const handleEditClientMeasurement = () => {
    handleSubmit(
      data => {
        if (measurementId) {
          mutate(
            {
              id: measurementId,
              payload: mapUpdateClientMeasurementRequest(data),
            },
            {
              onSuccess: onCloseWithReset,
            },
          );
        }
        const mappedClientData = mapUpdateClientForm(client);
        if (mappedClientData) {
          updateClientMutate(
            mapUpdateClientRequest({
              ...mappedClientData,
              targetWeight: data.targetWeight ?? undefined,
              goal: data.goal ?? undefined,
            }),
            { onSuccess: onCloseWithReset },
          );
        }
      },
      e => console.log("e", e),
    )();
  };

  const [tab, setTab] = useState(ClientMeasurementTabs.MEASUREMENTS);

  useEffect(() => {
    open && setTab(ClientMeasurementTabs.MEASUREMENTS);
  }, [open]);

  const handleChange = (
    event: SyntheticEvent,
    newValue: ClientMeasurementTabs,
  ) => {
    setTab(newValue);
  };

  return (
    <ModalShell
      title={t("client_profile.health.goals_and_measurements")}
      open={open}
      onClose={onCloseWithReset}
      isLoading={isLoading || isLoadingUpdateClient}
      onSubmit={handleEditClientMeasurement}
    >
      <TabContext value={tab}>
        <Box className="mb-2">
          <StyledTabList onChange={handleChange}>
            <StyledTab
              label={t("client_profile.health.goals")}
              value={ClientMeasurementTabs.GOALS}
            />
            <StyledTab
              label={t("client_profile.health.measurements")}
              value={ClientMeasurementTabs.MEASUREMENTS}
            />
          </StyledTabList>
        </Box>
        <StyledTabPanel value={ClientMeasurementTabs.GOALS}>
          <GoalsSection control={control} />
        </StyledTabPanel>
        <StyledTabPanel value={ClientMeasurementTabs.MEASUREMENTS}>
          <MeasurementSection
            control={control}
            lastMeasurement={lastMeasurement}
          />
        </StyledTabPanel>
      </TabContext>
    </ModalShell>
  );
};
