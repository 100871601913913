import { useMutation, useQueryClient } from "@tanstack/react-query";

import { CreateRecipeRequest } from "@client";
import { updateScheduleRecipe } from "@client/schedule";

import { MutationOptions } from "../types";
import { fetchScheduleRecipeQueryKey } from "./useFetchScheduleRecipeQuery";
import { fetchDietitianClientProgramScheduleKey } from "./useFetchDietitianClientProgramScheduleQuery";
import { fetchDietitianProgramScheduleKey } from "./useFetchDietitianProgramScheduleQuery";

export interface UpdateScheduleRecipeMutationPayload {
  dayId: string | number;
  mealId: string | number;
  recipe: CreateRecipeRequest;
}

export const useUpdateScheduleRecipeMutation = (
  programId: string | number,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ dayId, mealId, recipe }: UpdateScheduleRecipeMutationPayload) =>
      updateScheduleRecipe(
        Number(programId),
        Number(dayId),
        Number(mealId),
        recipe,
      ),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries([fetchScheduleRecipeQueryKey]);
        queryClient.invalidateQueries([fetchDietitianClientProgramScheduleKey]);
        queryClient.invalidateQueries([fetchDietitianProgramScheduleKey]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};
