import { FoodGroupResourceDto } from "@client";
import { TreeItemOption, TreeSelectSearch } from "@components/TreeSelectSearch";
import { useAppTranslation } from "@hooks";
import {
  fetchFoodGroupsTreeQueryKey,
  useFetchFoodGroupsTreeQuery,
} from "@hooks/queries";
import { useMemo } from "react";
import { Control, FieldValues, Path, useController } from "react-hook-form";
import { Label } from "./FoodTreeSelectFilter.styled";
import { useQueryClient } from "@tanstack/react-query";
import { ApiResponse } from "@typeDefinitions";

export interface FoodTreeSelectFilterProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  placeholder?: string;
  onBlur?: (() => void) | undefined;
  onFocus?: (() => void) | undefined;
  label: string;
}
export const FoodTreeSelectFilter = <T extends FieldValues>({
  control,
  name,
  placeholder,
  onBlur,
  onFocus,
  label,
}: FoodTreeSelectFilterProps<T>) => {
  const { isPolishChosen } = useAppTranslation();
  const queryClient = useQueryClient();
  const dataCached = queryClient.getQueryData<
    ApiResponse<FoodGroupResourceDto[]>
  >([fetchFoodGroupsTreeQueryKey]);
  const { data, isLoading } = useFetchFoodGroupsTreeQuery({
    enabled: !dataCached?.data,
  });
  const foodGroupsData = dataCached?.data ?? data;

  const {
    field: { value, onChange },
  } = useController<T>({ control, name });

  const mapTreeSelectOptions = (
    options: FoodGroupResourceDto[] | undefined,
  ): TreeItemOption[] => {
    if (!options) return [];

    const mapItem = (
      {
        id,
        description,
        descriptionPl,
        children,
        parentId,
      }: FoodGroupResourceDto,
      parentIds: string[],
    ): TreeItemOption => ({
      id: id.toString(),
      name: isPolishChosen ? descriptionPl || description : description,
      children: children?.length
        ? children.map(c => mapItem(c, [...parentIds, parentId.toString()]))
        : [],
      allParents: [...parentIds, parentId.toString()],
      parentId: parentId.toString(),
    });

    return options.map(o => mapItem(o, []));
  };

  const mappedOptions = useMemo(
    () => mapTreeSelectOptions(foodGroupsData),
    [foodGroupsData, isPolishChosen],
  );

  if (!value || isLoading) return <></>;

  return (
    <div className="grid gap-[4px]">
      <Label>{label}</Label>

      <TreeSelectSearch
        options={mappedOptions}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        onBlur={onBlur}
        onFocus={onFocus}
        singleSelect
      />
    </div>
  );
};
