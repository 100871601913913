import {
  Divider,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
} from "@mui/material";

export const DayTitle = styled(Typography)`
  font-size: 1rem;
  font-weight: 600;
`;

export const ChartLabelBold = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.367rem;
`;

export const ChartLabel = styled(Typography)`
  font-size: 0.875rem;
  line-height: 1.251rem;
`;

export const DividerStyled = styled(Divider)`
  min-width: 70%;
`;

export const ChartWrapper = styled("div")`
  display: grid;
  justify-items: center;
  width: 5.5rem;
  gap: 0.25rem;
`;

export const StyledToggleButton = styled(ToggleButton)`
  padding: 0.125rem 0.5rem;
  font-size: 0.625rem;
  font-weight: 200;
  color: ${({ theme }) => theme.palette.text.primary};
  border-color: ${({ theme }) => theme.colors.subtlePurple};

  &.Mui-selected {
    background-color: ${({ theme }) => theme.colors.subtlePurple};
  }
`;

export const CaptionStyled = styled(Typography)`
  font-size: 0.625rem;
`;

export const MicronutrientsWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 10rem;
  overflow: auto;
  width: 100%;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallMax}px) {
    column-gap: 1.5rem;
  }
`;

export const DetailsWrapperTablet = styled("div")`
  display: grid;
  justify-items: center;
  gap: 0.75rem;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallMax}px) {
    grid-template-columns: 1fr 1fr;
    column-gap: 1.5rem;
    align-items: center;
  }
`;

export const DayDetailsWrapper = styled("div")`
  display: grid;
  justify-items: center;
  gap: 0.75rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.largeDesktop}px) {
    width: unset;
    padding-left: unset;
    padding-right: unset;
  }
`;

export const MonitoringDayChartsStyled = styled("div")`
  display: flex;
  gap: 0.5rem;
`;

export const MicronutrientDetailsWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MicronutrientValueWrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

export const WarningDot = styled("div")`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.alertTomato};
`;

export const MonitoringDayKcalSectionWrapper = styled("div")`
  display: grid;
  justify-items: center;
`;

export const MicronutrientPreviewWrapper = styled("div")`
  display: flex;
  justify-content: center;
  width: 100%;
`;
