import { useController } from "react-hook-form";

import { FormTextFieldProps } from "@components/FormTextField";
import { useAppTranslation } from "@hooks";

import {
  PrefixStyled,
  TextFieldStyled,
  TextFieldWrapper,
} from "./LanguageTextField.styled";

interface LanguageTextFieldProps extends FormTextFieldProps {
  langPrefix: string;
}
export const LanguageTextField = ({
  langPrefix,
  name,
  control,
  ...rest
}: LanguageTextFieldProps) => {
  const { t } = useAppTranslation();
  const {
    field: { onChange, value, onBlur, ref },
    fieldState: { error },
  } = useController({ name, control });

  return (
    <TextFieldWrapper>
      <PrefixStyled>{langPrefix}</PrefixStyled>

      <TextFieldStyled
        {...rest}
        ref={ref}
        fullWidth
        value={value}
        onChange={onChange}
        onBlur={e => {
          onBlur();
          rest.onBlur && rest.onBlur(e);
        }}
        error={!!error}
        helperText={error?.message ? t(error.message) : undefined}
      />
    </TextFieldWrapper>
  );
};
