import { Skeleton, Stack } from "@mui/material";

const MealSkeleton = () => {
  return (
    <Stack spacing="12px">
      <Skeleton
        animation="pulse"
        variant="rectangular"
        height={60}
        sx={{ background: "#F7F5FF" }}
      />
      <Skeleton
        animation="pulse"
        variant="rectangular"
        height={60}
        sx={{ background: "#F7F5FF" }}
      />
      <Skeleton
        animation="pulse"
        variant="rectangular"
        height={60}
        sx={{ background: "#F7F5FF" }}
      />
      <Skeleton
        animation="pulse"
        variant="rectangular"
        height={60}
        sx={{ background: "#F7F5FF" }}
      />
    </Stack>
  );
};

export default MealSkeleton;
