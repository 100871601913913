import { useMemo } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { fetchCreatorDietQueryKey } from "./useFetchCreatorDietQuery";
import { useAppParams } from "@hooks/useAppParams";
import { FetchCreatorDietResponseDto } from "@client/diets/creator";
import { AxiosError } from "axios";

export const useCreatorVersion = () => {
  const { dietId } = useAppParams();
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData<FetchCreatorDietResponseDto>([
    fetchCreatorDietQueryKey,
    dietId,
  ]);
  const version = useMemo(
    () => data?.headers[ALLOWEAT_CREATOR_VERSION] ?? "",
    [data?.headers],
  );

  const updateVersion = () => {
    queryClient.setQueryData<FetchCreatorDietResponseDto>(
      [fetchCreatorDietQueryKey, dietId],
      oldData => {
        if (!oldData) return;

        return {
          data: {
            ...oldData.data,
          },
          headers: {
            ...oldData.headers,
            [ALLOWEAT_CREATOR_VERSION]: `${
              Number(oldData.headers[ALLOWEAT_CREATOR_VERSION]) + 1
            }`,
          },
        };
      },
    );
  };

  const invalidateVersion = (e: AxiosError) => {
    if (e?.response?.data === INCORRECT_VERSION_ERROR)
      queryClient.invalidateQueries([fetchCreatorDietQueryKey, dietId]);
  };

  return {
    version,
    updateVersion,
    invalidateVersion,
  };
};

export const ALLOWEAT_CREATOR_VERSION = "alloweat-creator-version";

const INCORRECT_VERSION_ERROR = "Version is incorrect";
