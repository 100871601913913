import {
  AutocompleteFilterWrapper,
  FilterType,
  FunnelButton,
  MultiSelectFilterWrapper,
  SingleAutocompleteFilterWrapper,
  SingleSelectFilterWrapper,
  SliderFilterWrapper,
} from "@components/Filters";
import { FiltersDrawer } from "@components/Filters/FiltersDrawer";
import { useAppTranslation } from "@hooks";
import { decimalInput1ThreeDigits } from "@utils/inputs";
import {
  CARBS_ID,
  ENERGY_ID,
  FATS_ID,
  MicrosFilters,
  PROTEIN_ID,
} from "@utils/macros";
import { useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useFetchClinicDietitiansSimpleListQuery } from "@hooks/queries/settings";
import {
  DEFAULT_PROGRAMS_MACROS_RANGE,
  ProgramsFiltersFormInput,
} from "./useProgramsFiltersForm";
import { defaultFilters } from "./programsFiltersUtils";
import { useTagsNew } from "@hooks/useTagsNew";
import { TagCategoryType } from "@utils/tagsNew";
import { DietsFiltersFormInput, MealsCount } from "@components/DietsFilters";

interface ProgramsFiltersProps {
  total?: number;
  onSubmit?: (values: ProgramsFiltersFormInput) => void;
  defaultReset?: object;
  onClear?: () => void;
}

export const ProgramsFilters = ({
  total,
  defaultReset,
  onClear,
  onSubmit,
}: ProgramsFiltersProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useAppTranslation();
  const tags = useWatch<ProgramsFiltersFormInput, "tags">({ name: "tags" });
  const nutrients = useWatch<ProgramsFiltersFormInput, "nutrients">({
    name: "nutrients",
  });
  const tabs = useWatch<ProgramsFiltersFormInput, "tabs">({
    name: "tabs",
  });
  const authorId = useWatch<ProgramsFiltersFormInput, "authorId">({
    name: "authorId",
  });
  const days = useWatch<ProgramsFiltersFormInput, "days">({
    name: "days",
  });
  const typeDays = useWatch<ProgramsFiltersFormInput, "typeDays">({
    name: "typeDays",
  });
  const numberOfDietMeals = useWatch<
    ProgramsFiltersFormInput,
    "numberOfDietMeals"
  >({
    name: "numberOfDietMeals",
  });

  const { dietitians } = useFetchClinicDietitiansSimpleListQuery();
  const { getTagCategoryOptions, getTagCategoryName } = useTagsNew();

  const countPositiveValues = useMemo(
    () =>
      Object.entries(nutrients || {})?.reduce((acc, [key, value]) => {
        if (!value) return 0;
        const [min, max] = value;
        const defaultValue = DEFAULT_PROGRAMS_MACROS_RANGE[key];

        const isDefaultValue =
          min === defaultValue[0] && max === defaultValue[1];
        const isEmptyValue = max === 0;

        if (!isDefaultValue && !isEmptyValue) acc += 1;

        return acc;
      }, 0),
    [nutrients],
  );

  const allTags =
    (tags?.length ?? 0) +
    countPositiveValues +
    Number(!!authorId) +
    Number(!!days) +
    Number(typeDays?.length ?? 0);
  Number(numberOfDietMeals?.length ?? 0);

  const dietitiansMapped = useMemo(
    () => dietitians?.map(d => ({ id: d.id.toString(), label: d.name })) ?? [],
    [dietitians],
  );

  const eliminationsMapped = useMemo(
    () => getTagCategoryOptions(TagCategoryType.ELIMINATIONS) ?? [],
    [t],
  );

  const allergendMapped = useMemo(
    () => getTagCategoryOptions(TagCategoryType.ALLERGENS) ?? [],
    [t],
  );

  const dietsMapped = useMemo(
    () => getTagCategoryOptions(TagCategoryType.DIET) ?? [],
    [t],
  );

  const programFilters: (
    | MultiSelectFilterWrapper<ProgramsFiltersFormInput>
    | SingleSelectFilterWrapper<ProgramsFiltersFormInput>
    | SingleAutocompleteFilterWrapper<ProgramsFiltersFormInput>
  )[] = useMemo(
    () => [
      {
        type: FilterType.SingleAutocomplete,
        props: {
          label: t("programs.author"),
          options: dietitiansMapped,
          placeholder: "",
          name: "authorId",
        },
      },
      {
        type: FilterType.MultiSelect,
        props: {
          label: t("programs.day_types_number"),
          options: [
            { id: TypeDays.ONE, label: TypeDays.ONE },
            { id: TypeDays.TWO, label: TypeDays.TWO },
            { id: TypeDays.THREE, label: TypeDays.THREE },
            { id: TypeDays.FOUR, label: TypeDays.FOUR },
            { id: TypeDays.FIVE, label: TypeDays.FIVE },
            { id: TypeDays.SIX, label: TypeDays.SIX },
            { id: TypeDays.SEVEN, label: TypeDays.SEVEN },
            { id: TypeDays.MORE, label: `>${TypeDays.SEVEN}` },
          ],
          name: "typeDays",
        },
      },
      {
        type: FilterType.SingleSelect,
        props: {
          label: t("programs.program_duration"),
          options: [
            {
              id: `${DurationWeeks.ONE}`,
              label: t("programs.up_to", { count: DurationWeeks.ONE }),
            },
            {
              id: `${DurationWeeks.TWO}`,
              label: t("programs.up_to", { count: DurationWeeks.TWO }),
            },
            {
              id: `${DurationWeeks.THREE}`,
              label: t("programs.up_to", { count: DurationWeeks.THREE }),
            },
            {
              id: `${DurationWeeks.FOUR}`,
              label: t("programs.up_to", { count: DurationWeeks.FOUR }),
            },
            { id: `${DurationWeeks.MORE}`, label: `> 28 ${t("common.days")}` },
          ],
          name: "days",
        },
      },
    ],
    [dietitiansMapped, t],
  );

  const dietFilters: (
    | MultiSelectFilterWrapper<ProgramsFiltersFormInput>
    | AutocompleteFilterWrapper<ProgramsFiltersFormInput>
  )[] = useMemo(
    () => [
      {
        type: FilterType.Autocomplete,
        props: {
          label: getTagCategoryName(TagCategoryType.DIET) ?? "",
          options: dietsMapped,
          placeholder: t("diets.diet_placeholder"),
        },
      },
      {
        type: FilterType.MultiSelect,
        props: {
          label: getTagCategoryName(TagCategoryType.ELIMINATIONS) ?? "",
          options: eliminationsMapped,
        },
      },
      {
        type: FilterType.Autocomplete,
        props: {
          label: getTagCategoryName(TagCategoryType.ALLERGENS) ?? "",
          options: allergendMapped,
          placeholder: t("diets.allergens_placeholder"),
        },
      },
      {
        type: FilterType.MultiSelect,
        props: {
          label: t("diets.meals_number"),
          options: [
            { id: MealsCount.ONE, label: MealsCount.ONE },
            { id: MealsCount.TWO, label: MealsCount.TWO },
            { id: MealsCount.THREE, label: MealsCount.THREE },
            { id: MealsCount.FOUR, label: MealsCount.FOUR },
            { id: MealsCount.FIVE, label: MealsCount.FIVE },
            { id: MealsCount.SIX, label: MealsCount.SIX },
            { id: MealsCount.SEVEN, label: MealsCount.SEVEN },
            { id: MealsCount.MORE, label: `>${MealsCount.SEVEN}` },
          ],
          name: "numberOfDietMeals",
        },
      },
    ],
    [dietitiansMapped, t],
  );

  const nutrientsFilters: SliderFilterWrapper<ProgramsFiltersFormInput>[] = [
    {
      type: FilterType.Slider,
      props: {
        nutrientId: ENERGY_ID.toString(),
        label: t("common.energy_efficiency"),
        maxValue: 4500,
        step: 50,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: PROTEIN_ID.toString(),
        maxValue: 200,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: FATS_ID.toString(),
        maxValue: 200,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: CARBS_ID.toString(),
        maxValue: 800,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.SATURATED_FAT.toString(),
        maxValue: 30,
        step: 1,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.CHOLESTEROL.toString(),
        maxValue: 500,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.DIETARY_FIBER.toString(),
        maxValue: 50,
        step: 1,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.SODIUM.toString(),
        maxValue: 2500,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.POTASSIUM.toString(),
        maxValue: 4000,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.CALCIUM.toString(),
        maxValue: 1300,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.PHOSPHORUS.toString(),
        maxValue: 1500,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.MAGNESIUM.toString(),
        maxValue: 500,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.IRON.toString(),
        maxValue: 30,
        step: 1,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.TOTAL_FOLATE.toString(),
        maxValue: 600,
        step: 5,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.KOBALAMINA.toString(),
        maxValue: 3,
        step: 0.1,
        decimalInput: decimalInput1ThreeDigits,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.GLYCEMIC_LOAD.toString(),
        maxValue: 200,
        step: 1,
      },
    },
    {
      type: FilterType.Slider,
      props: {
        nutrientId: MicrosFilters.CARBOHYDRATE_EXCHANGERS.toString(),
        maxValue: 25,
        step: 1,
      },
    },
  ];

  return (
    <>
      <FunnelButton allTags={allTags} open={open} setOpen={setOpen} />
      <FiltersDrawer
        tabs={[
          t("common.nutrition_program"),
          t("common.diet"),
          t("recipes.filters.nutrients"),
        ]}
        filtersLists={[programFilters, dietFilters, nutrientsFilters]}
        onClose={() => setOpen(false)}
        onSubmit={onSubmit}
        open={open}
        total={total}
        resetValues={
          defaultReset ?? {
            tabs: tabs,
            ...defaultFilters,
          }
        }
        onClear={onClear}
      />
    </>
  );
};

enum DurationWeeks {
  ONE = 7,
  TWO = 14,
  THREE = 21,
  FOUR = 28,
  MORE = 29,
}

enum TypeDays {
  ONE = "1",
  TWO = "2",
  THREE = "3",
  FOUR = "4",
  FIVE = "5",
  SIX = "6",
  SEVEN = "7",
  MORE = "8",
}
