import { postDietsProductsReplacementClearHistory } from "@client/diets";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "../types";
import { fetchDietsProductsReplacementQueryKey } from "./useFetchDietsProductsReplacementQuery";

export const usePostDietsProductsReplacementClearHistoryMutation = (
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, string>(
    dietId => postDietsProductsReplacementClearHistory(dietId),
    {
      ...options,
      onSuccess: (d, dietId) => {
        options?.onSuccess && options.onSuccess(d, dietId);
        queryClient.invalidateQueries([
          fetchDietsProductsReplacementQueryKey,
          dietId,
        ]);
      },
    },
  );
};
