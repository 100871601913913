import { Nutrient, NutrientCategory } from "@typeDefinitions";
import { MicroGroup } from "./index";

export interface MicronutrientsModalProps {
  nutrients: Nutrient[];
  nutrientCategories: NutrientCategory[];
}

export const MicronutrientsList = (props: MicronutrientsModalProps) => {
  const { nutrients, nutrientCategories } = props;

  const sortBy = [3, 6, 4, 5, 7];
  const filteredElements = nutrientCategories
    .filter(element => ![1, 2].includes(element.id))
    .sort((a, b) => {
      return sortBy.indexOf(a.id) - sortBy.indexOf(b.id);
    });

  return (
    <div
      className="flex flex-wrap pb-4"
      style={{ height: 560, overflow: "auto" }}
    >
      {filteredElements.map(microGroup => (
        <MicroGroup
          key={microGroup.id}
          nameEn={microGroup.nameEn}
          namePl={microGroup.namePl}
          elements={microGroup.nutrients}
          micros={nutrients}
          id={microGroup.id}
        />
      ))}
    </div>
  );
};
