import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";
import * as yup from "yup";
import { logFetchValidateError } from "@utils/validate";

export interface Nutrients {
  id: number;
  namePl: string;
  nameEn: string;
}

const daySchema = yup.object({
  id: yup.number().required().integer(),
  namePl: yup.string().required(),
  nameEn: yup.string().required(),
});

const daysSchema = yup.array().of(daySchema).defined();

export async function fetchDictionaryNutrients(): Promise<Nutrients[]> {
  const { data } = await fetchData(
    `/dietitian/dictionaries/nutrients`,
    APIMethods.GET,
  );

  return daysSchema
    .validate(data)
    .catch(logFetchValidateError("/dietitian/dictionaries/nutrients"));
}
