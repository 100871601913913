import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const MapMarker = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      fill={props.fill}
      style={{ fill: "none" }}
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        d="M10.4995 11.7513C10.858 11.7513 11.213 11.6806 11.5443 11.5435C11.8755 11.4063 12.1764 11.2052 12.4299 10.9517C12.6834 10.6982 12.8845 10.3972 13.0217 10.066C13.1589 9.73477 13.2295 9.37977 13.2295 9.02126C13.2295 8.66275 13.1589 8.30775 13.0217 7.97653C12.8845 7.64532 12.6834 7.34436 12.4299 7.09086C12.1764 6.83735 11.8755 6.63626 11.5443 6.49907C11.213 6.36187 10.858 6.29126 10.4995 6.29126C9.77549 6.29126 9.0811 6.57888 8.56913 7.09086C8.05716 7.60283 7.76953 8.29722 7.76953 9.02126C7.76953 9.7453 8.05716 10.4397 8.56913 10.9517C9.0811 11.4636 9.77549 11.7513 10.4995 11.7513V11.7513Z"
        stroke={props.fill ? props.fill : "#801FFF"}
        strokeWidth="1.5"
      />
      <path
        d="M3.21241 6.67875C4.93616 -0.898748 16.1624 -0.889998 17.8774 6.6875C18.8837 11.1325 16.1187 14.895 13.6949 17.2225C12.848 18.0393 11.7172 18.4958 10.5405 18.4958C9.36388 18.4958 8.2331 18.0393 7.38616 17.2225C4.97116 14.895 2.20616 11.1238 3.21241 6.67875V6.67875Z"
        stroke={props.fill ? props.fill : "#801FFF"}
        strokeWidth="1.5"
      />
    </SvgIcon>
  );
};
