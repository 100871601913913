import { ArrowRight, PointerArrowLeft } from "@assets/icons/Arrows";

import { useTheme } from "@mui/material";
import {
  AccordionDetailsStyled,
  AccordionStyled,
  AccordionSummaryStyled,
  Title,
} from "./ExchangedProductAccordion.styled";
import { ExchangedProductAccordionContent } from "./ExchangedProductAccordionContent";
import { useMealClientContext } from "@hooks";
import { useMemo } from "react";
import { mapUserChips, UserChips } from "@components/ExpandableChips";

interface ExchangedProductAccordionProps {
  from: number;
  to: number;
  currentName: string;
  previousName: string;
}
export const ExchangedProductAccordion = ({
  from,
  to,
  currentName,
  previousName,
}: ExchangedProductAccordionProps) => {
  const {
    colors: { neutral },
  } = useTheme();

  const { productsClientContextMap } = useMealClientContext();
  const clientContextFrom = useMemo(
    () => productsClientContextMap.get(from),
    [productsClientContextMap, from],
  );
  const userTagsFrom = useMemo(
    () =>
      mapUserChips({
        disliked: clientContextFrom?.hasUnliked,
        liked: clientContextFrom?.hasLiked,
        allergen: clientContextFrom?.hasAllergens,
      }),
    [clientContextFrom],
  );
  const clientContextTo = useMemo(
    () => productsClientContextMap.get(to),
    [productsClientContextMap, to],
  );
  const userTagsTo = useMemo(
    () =>
      mapUserChips({
        disliked: clientContextTo?.hasUnliked,
        liked: clientContextTo?.hasLiked,
        allergen: clientContextTo?.hasAllergens,
      }),
    [clientContextTo],
  );

  return (
    <AccordionStyled TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummaryStyled
        expandIcon={<ArrowRight size="w-[16px] h-[16px]" />}
      >
        <Title>{currentName}</Title>
        <UserChips tags={userTagsTo} />
        <PointerArrowLeft fill={neutral.dark[700]} />
        <Title gray>{previousName}</Title>
        <UserChips tags={userTagsFrom} />
      </AccordionSummaryStyled>

      <AccordionDetailsStyled>
        <ExchangedProductAccordionContent from={from} to={to} />
      </AccordionDetailsStyled>
    </AccordionStyled>
  );
};
