import { useModalState } from "@hooks";
import {
  createContext,
  useContext,
  useState,
  type Dispatch,
  type FC,
  type ReactNode,
  type SetStateAction,
} from "react";

type NumberOrNull = number | null;

interface NotesSidePanelContextType {
  noteDescription: string;
  isNotesPanelOpen: boolean;
  noteId: NumberOrNull;
  handleOpenNotesPanel: () => void;
  handleCloseNotesPanel: () => void;
  setNoteDescription: Dispatch<SetStateAction<string>>;
  setNoteId: Dispatch<SetStateAction<NumberOrNull>>;
}

const NotesSidePanelContext = createContext<
  NotesSidePanelContextType | undefined
>(undefined);

export const useNotesSidePanelContext = () => {
  const context = useContext(NotesSidePanelContext);

  if (!context) {
    throw new Error(
      "useNotesSidePanelContext must be used within a NotesSidePanelContextProvider",
    );
  }

  return context;
};

export const NotesSidePanelContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isNotesPanelOpen, handleOpenNotesPanel, handleCloseNotesPanelRow] =
    useModalState();
  const [noteDescription, setNoteDescription] = useState("");
  const [noteId, setNoteId] = useState<number | null>(null);

  const handleCloseNotesPanel = () => {
    setNoteId(null);
    handleCloseNotesPanelRow();
  };

  return (
    <NotesSidePanelContext.Provider
      value={{
        noteDescription,
        setNoteDescription,
        noteId,
        setNoteId,
        isNotesPanelOpen,
        handleCloseNotesPanel,
        handleOpenNotesPanel,
      }}
    >
      {children}
    </NotesSidePanelContext.Provider>
  );
};
