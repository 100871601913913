import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const ArrowInCircle = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="14" viewBox="0 0 14 14" {...props}>
      <path
        d="M7 12.2812C5.59933 12.2812 4.25602 11.7248 3.26559 10.7344C2.27517 9.74398 1.71875 8.40067 1.71875 7C1.71875 5.59933 2.27517 4.25602 3.26559 3.26559C4.25602 2.27517 5.59933 1.71875 7 1.71875C8.40067 1.71875 9.74398 2.27517 10.7344 3.26559C11.7248 4.25602 12.2812 5.59933 12.2812 7C12.2812 8.40067 11.7248 9.74398 10.7344 10.7344C9.74398 11.7248 8.40067 12.2812 7 12.2812ZM7 0.5C5.27609 0.5 3.62279 1.18482 2.40381 2.40381C1.18482 3.62279 0.5 5.27609 0.5 7C0.5 8.72391 1.18482 10.3772 2.40381 11.5962C3.62279 12.8152 5.27609 13.5 7 13.5C8.72391 13.5 10.3772 12.8152 11.5962 11.5962C12.8152 10.3772 13.5 8.72391 13.5 7C13.5 5.27609 12.8152 3.62279 11.5962 2.40381C10.3772 1.18482 8.72391 0.5 7 0.5ZM10.0697 7.98008C10.184 7.87344 10.25 7.72363 10.25 7.56621C10.25 7.25391 9.99609 7 9.68379 7H8.21875V4.5625C8.21875 4.11309 7.85566 3.75 7.40625 3.75H6.59375C6.14434 3.75 5.78125 4.11309 5.78125 4.5625V7H4.31621C4.00391 7 3.75 7.25391 3.75 7.56621C3.75 7.72363 3.81602 7.87344 3.93027 7.98008L6.64961 10.5166C6.74609 10.6055 6.87051 10.6562 7 10.6562C7.12949 10.6562 7.25644 10.6055 7.35039 10.5166L10.0697 7.98008Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
