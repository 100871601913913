import { ReactNode, useCallback, useMemo, useState } from "react";

import { useFetchDietitianAccountQuery } from "@hooks/queries/settings";
import { useSession } from "@hooks/talkjs";

import { ChatboxContext, ChatboxContextIf, UserChatIf } from "./ChatboxContext";
import { ChatsWrapper } from "./ChatsWrapper";
import { isString, uniqBy } from "lodash";
import { useLocation } from "react-router-dom";
import { CHAT_MAIN } from "@routes";

interface ChatboxContextProviderProps {
  children: ReactNode;
}

export const ChatboxContextProvider = ({
  children,
}: ChatboxContextProviderProps) => {
  const [selectedChats, setSelectedChats] = useState<(UserChatIf | string)[]>(
    [],
  );
  const { account } = useFetchDietitianAccountQuery();
  const { session } = useSession();
  const { pathname } = useLocation();
  const isChatPage = pathname.startsWith(CHAT_MAIN);
  const accountId = account?.id;

  const openChat = useCallback(
    (chat: UserChatIf | string) => {
      if (!accountId) return;
      setSelectedChats(state => {
        const ids = state
          .flatMap(el => (isString(el) ? el.split("-") : el.id))
          .filter(id => id !== accountId.toString());

        if (
          isString(chat)
            ? ids.some(id => chat.includes(id))
            : ids.includes(chat.id)
        )
          return state;

        return uniqBy([chat, ...state], el =>
          !isString(el) ? el.id : el,
        ).slice(0, 3);
      });
    },
    [accountId],
  );
  const closeChat = useCallback(
    (conversationId: string) =>
      setSelectedChats(state =>
        state.filter(chat => {
          if (isString(chat)) return conversationId !== chat;
          return !conversationId.split("-").includes(chat.id);
        }),
      ),
    [],
  );

  const value = useMemo<ChatboxContextIf>(
    () => ({
      openChat,
      closeChat,
      session,
    }),
    [openChat, closeChat, session],
  );

  return (
    <ChatboxContext.Provider value={value}>
      {children}
      {!isChatPage && <ChatsWrapper selectedUsers={selectedChats} />}
    </ChatboxContext.Provider>
  );
};
