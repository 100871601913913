import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Mobile = (props: SvgIconProps) => (
  <SvgIcon width="12" height="16" viewBox="0 0 12 16" {...props}>
    <path
      d="M9.5 0C10.0625 0.0208333 10.5312 0.21875 10.9062 0.59375C11.2812 0.96875 11.4792 1.4375 11.5 2V14C11.4792 14.5625 11.2812 15.0312 10.9062 15.4062C10.5312 15.7812 10.0625 15.9792 9.5 16H2.5C1.9375 15.9792 1.46875 15.7812 1.09375 15.4062C0.71875 15.0312 0.520833 14.5625 0.5 14V2C0.520833 1.4375 0.71875 0.96875 1.09375 0.59375C1.46875 0.21875 1.9375 0.0208333 2.5 0H9.5ZM6 15C6.29167 15 6.53125 14.9062 6.71875 14.7188C6.90625 14.5312 7 14.2917 7 14C7 13.7083 6.90625 13.4688 6.71875 13.2812C6.53125 13.0938 6.29167 13 6 13C5.70833 13 5.46875 13.0938 5.28125 13.2812C5.09375 13.4688 5 13.7083 5 14C5 14.2917 5.09375 14.5312 5.28125 14.7188C5.46875 14.9062 5.70833 15 6 15ZM9.5 2H2.5V12H9.5V2Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
