import { ClinicIdDto, LangDto, clinicIdSchema } from "@client/common";
import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import { IntroductionDto, introductionSchema } from "./fetchSurveys";
import * as yup from "yup";

export const fetchSurveyFull = async (
  surveyId: string,
): Promise<ApiResponse<FullSurveyDto>> => {
  const response = await fetchData(
    `/dietitian/surveys/${surveyId}/full`,
    APIMethods.GET,
  );

  return fetchSurveyFullResponseSchema.validate(response);
};

const itemOptionConfigSchema = yup.object().shape({
  uuid: yup.string().uuid().required(),
  name: yup.string().required(),
});

const textConfigSchema = yup.object().shape({
  required: yup.boolean().required(),
  placeholder: yup.string().nullable().default(null),
});

const optionsWithOtherConfigSchema = yup.object().shape({
  required: yup.boolean().required(),
  options: yup.array().of(itemOptionConfigSchema).required(),
  other: itemOptionConfigSchema.nullable().default(null),
});

const switchConfigSchema = yup.object().shape({
  required: yup.boolean().required(),
  description: yup.boolean().required(),
});

const optionsConfigSchema = yup.object().shape({
  required: yup.boolean().required(),
  options: yup.array().of(itemOptionConfigSchema).required(),
});

const rowTableSchema = yup.object().shape({
  uuid: yup.string().uuid().required(),
  name: yup.string().required(),
});

const columnTableSchema = yup.object().shape({
  uuid: yup.string().uuid().required(),
  name: yup.string().required(),
});

const tableConfigSchema = yup.object().shape({
  required: yup.boolean().required(),
  heading: yup.string().nullable().default(null),
  rows: yup.array().of(rowTableSchema).required(),
  columns: yup.array().of(columnTableSchema).required(),
});

const requiredConfigSchema = yup.object().shape({
  required: yup.boolean().required(),
});

const emptyConfigSchema = yup.object().shape({});

const questionTextSchema = yup.object().shape({
  id: yup.number().required(),
  type: yup.string<QuestionType.TEXT>().required(),
  title: yup.string().required(),
  description: yup.string().nullable().default(null),
  config: textConfigSchema.required(),
});

const questionTextAreaSchema = yup.object().shape({
  id: yup.number().required(),
  type: yup.string<QuestionType.TEXTAREA>().required(),
  title: yup.string().required(),
  description: yup.string().nullable().default(null),
  config: textConfigSchema.required(),
});

const questionCheckboxSchema = yup.object().shape({
  id: yup.number().required(),
  type: yup.string<QuestionType.CHECKBOX>().required(),
  title: yup.string().required(),
  description: yup.string().nullable().default(null),
  config: optionsWithOtherConfigSchema.required(),
});

const questionRadioSchema = yup.object().shape({
  id: yup.number().required(),
  type: yup.string<QuestionType.RADIO>().required(),
  title: yup.string().required(),
  description: yup.string().nullable().default(null),
  config: optionsWithOtherConfigSchema.required(),
});

const questionSwitchSchema = yup.object().shape({
  id: yup.number().required(),
  type: yup.string<QuestionType.SWITCH>().required(),
  title: yup.string().required(),
  description: yup.string().nullable().default(null),
  config: switchConfigSchema.required(),
});

const questionSelectSchema = yup.object().shape({
  id: yup.number().required(),
  type: yup.string<QuestionType.SELECT>().required(),
  title: yup.string().required(),
  description: yup.string().nullable().default(null),
  config: optionsConfigSchema.required(),
});

const questionSliderSchema = yup.object().shape({
  id: yup.number().required(),
  type: yup.string<QuestionType.SLIDER>().required(),
  title: yup.string().required(),
  description: yup.string().nullable().default(null),
  config: optionsConfigSchema.required(),
});

const questionTableSchema = yup.object().shape({
  id: yup.number().required(),
  type: yup.string<QuestionType.TABLE>().required(),
  title: yup.string().required(),
  description: yup.string().nullable().default(null),
  config: tableConfigSchema.required(),
});

const questionDateSchema = yup.object().shape({
  id: yup.number().required(),
  type: yup.string<QuestionType.DATE>().required(),
  title: yup.string().required(),
  description: yup.string().nullable().default(null),
  config: requiredConfigSchema.required(),
});

const systemQuestionSchema = yup.object().shape({
  id: yup.number().required(),
  type: yup.string<SystemQuestionType>().required(),
  title: yup.string().required(),
  description: yup.string().nullable().default(null),
  config: requiredConfigSchema.required(),
});

const questionEmptySchema = yup.object().shape({
  id: yup.number().required(),
  type: yup.string<QuestionType.EMPTY>().required(),
  title: yup.string().required(),
  description: yup.string().nullable().default(null),
  config: emptyConfigSchema.required(),
});

const sectionSchema = yup.object().shape({
  id: yup.number().required(),
  title: yup.string().required(),
  description: yup.string().nullable().default(null),
  questions: yup
    .array()
    .of(
      yup.lazy(value => {
        switch (value.type) {
          case QuestionType.TEXT:
            return questionTextSchema.required();
          case QuestionType.TEXTAREA:
            return questionTextAreaSchema.required();
          case QuestionType.CHECKBOX:
            return questionCheckboxSchema.required();
          case QuestionType.RADIO:
            return questionRadioSchema.required();
          case QuestionType.DATE:
            return questionDateSchema.required();
          case QuestionType.SWITCH:
            return questionSwitchSchema.required();
          case QuestionType.SELECT:
            return questionSelectSchema.required();
          case QuestionType.SLIDER:
            return questionSliderSchema.required();
          case QuestionType.TABLE:
            return questionTableSchema.required();
          case systemQuestionsList.includes(value.type):
            return systemQuestionSchema.required();
          case QuestionType.EMPTY:
            return questionEmptySchema.required();
          default:
            return questionEmptySchema.required();
        }
      }),
    )
    .required(),
});

const groupSchema = yup.object().shape({
  id: yup.number().required(),
  title: yup.string().required(),
  description: yup.string().nullable().default(null),
  sections: yup.array().of(sectionSchema).required(),
});

export const fullSurveySchema = yup.object().shape({
  id: yup.number().required(),
  clinic: clinicIdSchema.required(),
  langId: yup.mixed<LangDto>().oneOf(Object.values(LangDto)).required(),
  name: yup.string().required(),
  introduction: introductionSchema.required(),
  groups: yup.array().of(groupSchema).required(),
  numberOfQuestions: yup.number().required(),
  reviewed: yup.boolean().required(),
});

const fetchSurveyFullResponseSchema = yup.object().shape({
  data: fullSurveySchema.required(),
});

export interface FullSurveyDto {
  id: number;
  clinic: ClinicIdDto;
  langId: LangDto;
  name: string;
  introduction: IntroductionDto;
  groups: GroupDto[];
  numberOfQuestions: number;
  reviewed: boolean;
}

export interface GroupDto {
  id: number;
  title: string;
  description: string | null;
  sections: SectionDto[];
}

interface SectionDto {
  id: number;
  title: string;
  description: string | null;
  questions: (
    | QuestionTextDto
    | QuestionTextAreaDto
    | QuestionCheckboxDto
    | QuestionRadioDto
    | QuestionDateDto
    | QuestionSwitchDto
    | QuestionSelectDto
    | QuestionSliderDto
    | QuestionTableDto
    | QuestionEmptyDto
    | SystemQuestionDto
  )[];
}

interface SurveyQuestionDto<T> {
  id: number;
  type: QuestionType;
  title: string;
  description: string | null;
  config: T;
}

export interface QuestionTextDto extends SurveyQuestionDto<TextConfigDto> {
  type: QuestionType.TEXT;
}

export interface QuestionTextAreaDto extends SurveyQuestionDto<TextConfigDto> {
  type: QuestionType.TEXTAREA;
}

export interface QuestionCheckboxDto
  extends SurveyQuestionDto<OptionsWithOtherConfigDto> {
  type: QuestionType.CHECKBOX;
}

export interface QuestionRadioDto
  extends SurveyQuestionDto<OptionsWithOtherConfigDto> {
  type: QuestionType.RADIO;
}

export interface QuestionSwitchDto extends SurveyQuestionDto<SwitchConfigDto> {
  type: QuestionType.SWITCH;
}

export interface QuestionSelectDto extends SurveyQuestionDto<OptionsConfigDto> {
  type: QuestionType.SELECT;
}

export interface QuestionSliderDto extends SurveyQuestionDto<OptionsConfigDto> {
  type: QuestionType.SLIDER;
}

export interface QuestionTableDto extends SurveyQuestionDto<TableConfigDto> {
  type: QuestionType.TABLE;
}

export interface QuestionDateDto extends SurveyQuestionDto<RequiredConfigDto> {
  type: QuestionType.DATE;
}

export type SystemQuestionType =
  | QuestionType.SYSTEM_USER_WEIGHT
  | QuestionType.SYSTEM_USER_HEIGHT
  | QuestionType.SYSTEM_USER_FAT_TISSUE
  | QuestionType.SYSTEM_MEASUREMENTS
  | QuestionType.SYSTEM_USER_BIRTHDAY
  | QuestionType.SYSTEM_COOPERATION_GOALS
  | QuestionType.SYSTEM_USER_TARGET_BODY_WEIGHT
  | QuestionType.SYSTEM_PREGNANT_WOMAN
  | QuestionType.SYSTEM_SEX
  | QuestionType.SYSTEM_USER_PREFERENCES_FOOD_UNLIKED
  | QuestionType.SYSTEM_USER_PREFERENCES_FOOD_LIKED
  | QuestionType.SYSTEM_TAG_ALLERGEN
  | QuestionType.SYSTEM_TAG_DIET_TYPE;

export interface SystemQuestionDto
  extends SurveyQuestionDto<RequiredConfigDto> {
  type: SystemQuestionType;
}

export interface QuestionEmptyDto extends SurveyQuestionDto<EmptyConfigDto> {
  type: QuestionType.EMPTY;
}

export interface ItemOptionConfigDto {
  uuid: string;
  name: string;
}

export interface TextConfigDto {
  required: boolean;
  placeholder: string | null;
}

export interface OptionsWithOtherConfigDto {
  required: boolean;
  options: ItemOptionConfigDto[];
  other: ItemOptionConfigDto | null;
}

export interface SwitchConfigDto {
  required: boolean;
  description: boolean;
}

export interface OptionsConfigDto {
  required: boolean;
  options: ItemOptionConfigDto[];
}

export interface RowTableDto {
  uuid: string;
  name: string;
}

export interface ColumnTableDto {
  uuid: string;
  name: string;
}

export interface TableConfigDto {
  required: boolean;
  heading: string | null;
  rows: RowTableDto[];
  columns: ColumnTableDto[];
}

export interface RequiredConfigDto {
  required: boolean;
}

export interface EmptyConfigDto {} // eslint-disable-line

export enum QuestionType {
  EMPTY = "",
  TEXT = "text",
  TEXTAREA = "textarea",
  CHECKBOX = "checkbox",
  RADIO = "radio",
  SWITCH = "switch",
  DATE = "date",
  TIME = "time",
  DATE_TIME = "date_time",
  SELECT = "select",
  SLIDER = "slider",
  TABLE = "table",
  SYSTEM_USER_BIRTHDAY = "system_user_birthday",
  SYSTEM_USER_PHONE = "system_user_phone",
  SYSTEM_SOURCE_RECOMMENDATION = "system_source_recommendation",
  SYSTEM_USER_RESIDENTIAL_ADDRESS = "system_user_residential_address",
  SYSTEM_USER_WEIGHT = "system_user_weight",
  SYSTEM_USER_HEIGHT = "system_user_height",
  SYSTEM_USER_FAT_TISSUE = "system_user_fat_tissue",
  SYSTEM_COOPERATION_GOALS = "system_cooperation_goals",
  SYSTEM_ADDITIONAL_FEATURE = "system_additional_feature",
  SYSTEM_USER_TARGET_BODY_WEIGHT = "system_user_target_body_weight",
  SYSTEM_USER_ACTIVITY = "system_user_activity",
  SYSTEM_USER_PROFILE_TARGET = "system_user_profil_target",
  SYSTEM_TAG_ALLERGEN = "system_tag_allergen",
  SYSTEM_TAG_ELIMINATION = "system_tag_elimination",
  SYSTEM_TAG_KIND_OF_CUISINE = "system_tag_kind_of_cuisine",
  SYSTEM_USER_PREFERENCES_FOOD_LIKED = "system_user_preferences_food_liked",
  SYSTEM_USER_PREFERENCES_FOOD_UNLIKED = "system_user_preferences_food_unliked",
  SYSTEM_MEALS = "system_meals",
  SYSTEM_SEX = "system_sex",
  SYSTEM_FREQUENCY_SHOPPING_LIST = "system_frequency_shopping_list",
  SYSTEM_DISEASES_CARBOHYDRATE_METABOLISM_DISORDERS = "system_diseases_carbohydrate_metabolism_disorders",
  SYSTEM_DISEASES_DIGESTIVE_SYSTEM = "system_diseases_digestive_system",
  SYSTEM_DISEASES_CARDIOVASCULAR = "system_diseases_cardiovascular",
  SYSTEM_DISEASES_THYROID = "system_diseases_thyroid",
  SYSTEM_DISEASES_SKIN = "system_diseases_skin",
  SYSTEM_DISEASES_JOINTS_AND_SKELETAL_SYSTEM = "system_diseases_joints_and_skeletal_system",
  SYSTEM_DISEASES_OTHER = "system_diseases_other",
  SYSTEM_MEASUREMENTS = "system_measurements",
  SYSTEM_MEASUREMENT_PHOTOS = "system_measurement_photos",
  SYSTEM_FILES = "system_files",
  SYSTEM_PREGNANT_WOMAN = "system_pregnant_woman",
  SYSTEM_TAG_DIET_TYPE = "system_tag_diet_type",
}

const systemQuestionsList = [
  QuestionType.SYSTEM_USER_WEIGHT,
  QuestionType.SYSTEM_USER_HEIGHT,
  QuestionType.SYSTEM_USER_FAT_TISSUE,
  QuestionType.SYSTEM_MEASUREMENTS,
  QuestionType.SYSTEM_USER_BIRTHDAY,
  QuestionType.SYSTEM_COOPERATION_GOALS,
  QuestionType.SYSTEM_USER_TARGET_BODY_WEIGHT,
  QuestionType.SYSTEM_PREGNANT_WOMAN,
  QuestionType.SYSTEM_SEX,
  QuestionType.SYSTEM_USER_PREFERENCES_FOOD_UNLIKED,
  QuestionType.SYSTEM_USER_PREFERENCES_FOOD_LIKED,
  QuestionType.SYSTEM_TAG_ALLERGEN,
  QuestionType.SYSTEM_TAG_DIET_TYPE,
];
