import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const OilBottle = (props: SvgIconProps) => {
  return (
    <SvgIcon width="8" height="14" viewBox="0 0 8 14">
      <path
        d="M2.375 0.5C2.0373 0.5 1.76562 0.77168 1.76562 1.10938C1.76562 1.44707 2.0373 1.71875 2.375 1.71875C2.48672 1.71875 2.57812 1.81016 2.57812 1.92188V3.56973C2.57812 3.87949 2.39531 4.15625 2.14141 4.33398C1.30098 4.92051 0.75 5.89551 0.75 7V11.875C0.75 12.7713 1.47871 13.5 2.375 13.5H5.625C6.52129 13.5 7.25 12.7713 7.25 11.875V7C7.25 5.89551 6.69902 4.92051 5.85859 4.33398C5.60469 4.15625 5.42188 3.87949 5.42188 3.56973V1.92188C5.42188 1.81016 5.51328 1.71875 5.625 1.71875C5.9627 1.71875 6.23438 1.44707 6.23438 1.10938C6.23438 0.77168 5.9627 0.5 5.625 0.5H5.42188H2.57812H2.375ZM4 10.1992C3.32715 10.1992 2.78125 9.68887 2.78125 9.05664C2.78125 8.63008 3.34238 7.83535 3.70293 7.3707C3.85527 7.17266 4.14727 7.17266 4.29961 7.3707C4.65762 7.83535 5.21875 8.63008 5.21875 9.05664C5.21875 9.68887 4.67285 10.1992 4 10.1992Z"
        fill={props.fill ?? "currentColor"}
      />
    </SvgIcon>
  );
};
