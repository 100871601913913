import { styled } from "@mui/material";

interface StyledWrapperProps {
  daysView?: boolean;
}

export const StyledWrapper = styled("div")<StyledWrapperProps>`
  display: ${({ daysView }) => (daysView ? "grid" : "flex")};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  padding: 1rem;
  gap: 3rem;
  justify-content: flex-start;
  align-items: flex-end;
`;

export const StyledPieChartSection = styled("div")`
  display: flex;
  gap: 0.25rem;
  align-items: end;
`;

export const StyledLinesSection = styled("div")<StyledWrapperProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
  overflow: auto;
  padding-left: ${({ daysView }) => (daysView ? 0 : "1rem")};
  height: ${({ daysView }) => (daysView ? "auto" : "10rem")};
`;
