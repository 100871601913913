import { postOauthGoogleSetPushCalendar } from "@client/oauth";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "../types";
import { fetchGoogleCalendarListQueryKey } from "./useFetchGoogleCalendarListQuery";

export const usePostOauthGoogleSetPushCalendarMutation = (
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, string>(
    id => postOauthGoogleSetPushCalendar(id),
    {
      ...options,
      onSuccess: (data, variables) => {
        options?.onSuccess?.(data, variables);
        queryClient.invalidateQueries([fetchGoogleCalendarListQueryKey]);
      },
    },
  );
};
