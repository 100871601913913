import { createContext } from "react";
import { Session } from "talkjs/all";

export const ChatboxContext = createContext<ChatboxContextIf>({
  openChat: () => {
    null;
  },
  closeChat: () => {
    null;
  },
  session: undefined,
});

export interface ChatboxContextIf {
  openChat: (chat: UserChatIf | string) => void;
  closeChat: (userId: string) => void;
  session: Session | undefined;
}

export interface UserChatIf {
  id: string;
  name: string;
  email: string;
}
