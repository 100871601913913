import {
  SectionItemLabel,
  SectionItemLayout,
  SectionItemTextValue,
} from "@views/dietician/ClientHealth/ClientHealthNew.styled";
import { MeasurementValue } from "@views/dietician/ClientHealth/BodyMeasurementTab/MeasurementSection/MeasuremetSection.styled";
import { Chip, styled } from "@mui/material";
import { useAppTranslation, useClientParams } from "@hooks";
import { useMemo } from "react";
import dayjs from "dayjs";
import { PatientMeasurementFromConst } from "@consts/PatientMeasurementFromConst";
import useFetchPatientLastBodyMeasurement from "@hooks/queries/client/bodyMeasurement/useFetchPatientLastBodyMeasurement";

const DateMeasurement = () => {
  4;
  const { t } = useAppTranslation();
  const id = useClientParams();
  const { data: lastMeasurement } = useFetchPatientLastBodyMeasurement({
    patientId: id,
  });
  const lastDate = useMemo(() => {
    if (!lastMeasurement || !lastMeasurement.data?.date) {
      return "-";
    }

    return dayjs(lastMeasurement.data.date).format("DD.MM.YYYY");
  }, [lastMeasurement]);

  const measurementFrom = useMemo(() => {
    if (!lastMeasurement || !lastMeasurement.data) {
      return "-";
    }

    return (
      {
        [PatientMeasurementFromConst.PATIENT]: t(
          `patient.health_and_goal.body_measurement_tab.section_last_measurement.measurement_from_type.${PatientMeasurementFromConst.PATIENT}`,
        ),
        [PatientMeasurementFromConst.SURVEY]: t(
          `patient.health_and_goal.body_measurement_tab.section_last_measurement.measurement_from_type.${PatientMeasurementFromConst.SURVEY}`,
        ),
        [PatientMeasurementFromConst.DIETITIAN]: t(
          `patient.health_and_goal.body_measurement_tab.section_last_measurement.measurement_from_type.${PatientMeasurementFromConst.DIETITIAN}`,
        ),
      }[lastMeasurement.data.source.id] ?? "-"
    );
  }, [lastMeasurement]);

  return (
    <Wrapper>
      <SectionItemLayout>
        <SectionItemLabel>
          {t(
            "patient.health_and_goal.body_measurement_tab.section_last_measurement.measurement_date",
          )}
        </SectionItemLabel>
        <SectionItemTextValue>{lastDate}</SectionItemTextValue>
      </SectionItemLayout>
      <Chip color="info" label={measurementFrom} />
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export default DateMeasurement;
