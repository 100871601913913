import { Box, styled } from "@mui/material";
import { IconButton } from "@components/IconButton";

export const TasksStyledDeadlineButton = styled("button")(({ theme }) => ({
  color: theme.colors.neutral.dark["700"],
  border: "1px solid",
  borderColor: theme.colors.neutral.light["200"],
  display: "flex",
  alignItems: "center",
  height: "40px",
  padding: "0 12px",
  gap: "0 8px",
  borderRadius: "8px",
  fontSize: "14px",
  fontWeight: 500,
}));

export const TasksStyledDatePickerContainer = styled(Box)`
  padding: 1rem;
`;

export const TasksDeadlineStyledIconButton = styled(IconButton)(
  ({ theme }) => ({
    "color": theme.colors.neutral.dark["700"],
    "padding": 0,
    "margin": 0,

    "&:hover": {
      backgroundColor: "transparent",
    },
  }),
);
