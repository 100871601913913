import { createContext, ReactNode, useCallback, useMemo } from "react";

import _ from "lodash";

import { FetchDietResponse } from "@client";

interface SwappedProductIf {
  foodIdTo: number;
  foodFrom: {
    id: number;
    description: string;
    descriptionEn?: string;
  };
}
interface SwappedProductsContextIf {
  swappedProducts: number[];
  isSwapped: (id: number) => boolean;
  getProductById: (id: number) => SwappedProductIf | undefined;
  hasMealSwappedProducts: (id?: number) => boolean;
}

export const SwappedProductsContext = createContext<SwappedProductsContextIf>({
  swappedProducts: [],
  isSwapped: (id: number) => false,
  getProductById: (id: number) => undefined,
  hasMealSwappedProducts: (id?: number) => false,
});

interface SwappedContextProviderProps {
  children: ReactNode;
  dietDetails?: FetchDietResponse;
}

export const SwappedProductsContextProvider = ({
  children,
  dietDetails,
}: SwappedContextProviderProps) => {
  const productsDict = useMemo(() => {
    if (!dietDetails) return;

    const fromRecipesArray = _(dietDetails.meals)
      .flatMap(m => m.recipes)
      .flatMap(r => r.recipe.foodRecipe)
      .flatMap(f => f.food.exchange)
      .compact()
      .value();

    const fromProductsArray = _(dietDetails.meals)
      .flatMap(m => m.food)
      .flatMap(f => f.exchange)
      .compact()
      .value();

    return _([...fromRecipesArray, ...fromProductsArray])
      .keyBy("foodIdTo")
      .value();
  }, [dietDetails]);

  const getProductById = (id: number) => {
    if (productsDict && productsDict[id]) return productsDict[id];
    else return;
  };

  const productsByMeal = useMemo(() => {
    if (!dietDetails) return;

    return _(dietDetails.meals)
      .map(m => {
        const arr1 = m.food.flatMap(f => f.id);
        const arr2 = m.recipes
          .flatMap(r => r.recipe.foodRecipe)
          .flatMap(f => f.food.id);

        return {
          id: [m.id],
          products: _([...arr1, ...arr2])
            .uniq()
            .value(),
        };
      })
      .groupBy("id")
      .mapValues(v => v[0].products)
      .value();
  }, [dietDetails]);

  const getMealProductsById = useCallback(
    (id?: number) => {
      if (productsByMeal && id && productsByMeal[id]) return productsByMeal[id];
      return [];
    },
    [productsByMeal],
  );

  const swappedProducts = useMemo(() => {
    if (!productsDict) return [];
    return Object.keys(productsDict).map(id => parseInt(id));
  }, [productsDict]);

  const context = {
    swappedProducts,
    isSwapped: (id: number) => !!getProductById(id),
    getProductById,
    hasMealSwappedProducts: (id?: number) =>
      !!getMealProductsById(id).filter(id => swappedProducts.includes(id))
        .length,
  };

  return (
    <SwappedProductsContext.Provider value={context}>
      {children}
    </SwappedProductsContext.Provider>
  );
};
