import { postStoreBillData, StoreBillDataRequest } from "@client/clinic";
import { MutationOptions } from "@hooks/queries/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { clinicKeys } from "./clinicKeys";
import { fetchDietitianAccountQueryKey } from "../settings";

export const usePostStoreBillDataMutation = (options?: MutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: StoreBillDataRequest) => postStoreBillData(payload),
    onSuccess: () => {
      options?.onSuccess?.();
      queryClient.invalidateQueries(clinicKeys.billData());
      queryClient.invalidateQueries([fetchDietitianAccountQueryKey]);
    },
    ...options,
  });
};
