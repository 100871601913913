import { Dispatch, SetStateAction, createContext } from "react";

interface ServingContextIf {
  selectedServings: number;
  setSelectedServings: Dispatch<SetStateAction<number>>;
  totalServings: number;
}
export const ServingContext = createContext<ServingContextIf>({
  selectedServings: 1,
  setSelectedServings: () => {
    null;
  },
  totalServings: 1,
});
