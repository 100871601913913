import { useForm } from "react-hook-form";
import { BalanceDayDto, FetchClientResponse } from "@client";
import {
  BalanceValueMethod,
  DEFAULT_BALANCE_ACTIVITY,
  DEFAULT_BALANCE_FORMULA,
} from "@utils/balance";
import {
  calculateLbm,
  calculateSpm,
} from "@views/dietician/PatientEnergyBalance";
import { round } from "lodash";
import dayjs from "dayjs";

export const useCaloricNeedsForm = (update?: CaloricNeedsFormInputs) => {
  return useForm<CaloricNeedsFormInputs>({
    defaultValues: update ?? defaultValues,
  });
};

export const mapCaloricNeedsForm = (
  data: BalanceDayDto | undefined,
  client: FetchClientResponse | undefined,
  patientDataFilled: boolean,
): CaloricNeedsFormInputs | undefined => {
  if (!data) return;

  const calculateValue = (formulaId: number | null) => {
    if (client && patientDataFilled && formulaId != null) {
      const {
        profile: { weight, bodyFat, sex, birthDate, height },
      } = client;

      const clientLbm = calculateLbm(weight, bodyFat);

      return round(
        calculateSpm(
          formulaId,
          sex ?? undefined,
          weight ?? undefined,
          dayjs(dayjs()).diff(birthDate ?? dayjs(), "years"),
          height ?? undefined,
          clientLbm,
        ) ?? 0,
      );
    } else {
      return 0;
    }
  };

  if ("pal" in data.energyDemand) {
    const formula = data.energyDemand.pattern ?? DEFAULT_BALANCE_FORMULA;
    const spm =
      data.energyDemand.spm.value ??
      calculateValue(data.energyDemand.pattern ?? DEFAULT_BALANCE_FORMULA);
    const cpm =
      data.energyDemand.cpm.value ??
      round(spm * (data.energyDemand.pal.value ?? 0));

    const method = patientDataFilled
      ? BalanceValueMethod.AUTO
      : BalanceValueMethod.MANUAL;

    return {
      spm: {
        value: spm,
        formula: formula,
        method: data.energyDemand.spm.method ?? method,
      },
      pal: {
        value: data.energyDemand.pal.value,
        method: data.energyDemand.pal.method ?? BalanceValueMethod.AUTO,
        daily: data.energyDemand.activity.daily ?? DEFAULT_BALANCE_ACTIVITY,
        training:
          data.energyDemand.activity.training ?? DEFAULT_BALANCE_ACTIVITY,
      },
      cpm: {
        value: cpm,
        method: data.energyDemand.cpm.method ?? BalanceValueMethod.AUTO,
      },
    };
  } else {
    return {
      spm: {
        value:
          data.energyDemand.spm.value ??
          calculateValue(data.energyDemand.pattern ?? DEFAULT_BALANCE_FORMULA),
        formula: data.energyDemand.pattern ?? DEFAULT_BALANCE_FORMULA,
        method: data.energyDemand.spm.method ?? BalanceValueMethod.AUTO,
      },
      pal: {
        value: null,
        method: null,
        training: DEFAULT_BALANCE_ACTIVITY,
        daily: DEFAULT_BALANCE_ACTIVITY,
      },
      cpm: {
        value: data.energyDemand.cpm.value ?? 0,
        method: data.energyDemand.cpm.method ?? BalanceValueMethod.AUTO,
      },
    };
  }
};

export interface CaloricNeedsFormInputs {
  spm: {
    value: number | null;
    formula: number | null;
    method: BalanceValueMethod | null;
  };
  pal: {
    value: number | null;
    method: BalanceValueMethod | null;
    daily: number;
    training: number;
  };
  cpm: {
    value: number | null;
    method: BalanceValueMethod | null;
  };
}

const defaultValues = {
  spm: {
    value: null,
    formula: null,
    method: null,
  },
  pal: {
    value: null,
    method: null,
    daily: DEFAULT_BALANCE_ACTIVITY,
    training: DEFAULT_BALANCE_ACTIVITY,
  },
  cpm: {
    value: null,
    method: null,
  },
};
