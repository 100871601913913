import { IconButton, Radio } from "@mui/material";
import { FormTextFieldStyled } from "./RadioOption.styled";
import { TrashNew } from "@assets/icons";

interface RadioOptionProps {
  onDelete: () => void;
  name: string;
}

export const RadioOption = ({ name, onDelete }: RadioOptionProps) => {
  return (
    <FormTextFieldStyled
      InputProps={{
        startAdornment: <Radio disabled />,
        endAdornment: (
          <IconButton onClick={onDelete} color="primary">
            <TrashNew />
          </IconButton>
        ),
      }}
      size="small"
      name={name}
    />
  );
};
