import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const ScaleMedium = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        d="M4.6875 16.5981C4.6875 15.938 5.2207 15.3794 5.90625 15.3794C6.56641 15.3794 7.125 15.938 7.125 16.5981V17.4106C7.125 18.0962 6.56641 18.6294 5.90625 18.6294C5.2207 18.6294 4.6875 18.0962 4.6875 17.4106V16.5981Z"
        fill={props.fill ?? "currentColor"}
      />
      <path
        d="M8.75 13.3481C8.75 12.688 9.2832 12.1294 9.96875 12.1294C10.6289 12.1294 11.1875 12.688 11.1875 13.3481V17.4106C11.1875 18.0962 10.6289 18.6294 9.96875 18.6294C9.2832 18.6294 8.75 18.0962 8.75 17.4106V13.3481Z"
        fill={props.fill ?? "currentColor"}
      />
      <path
        d="M14.0312 8.87939C14.6914 8.87939 15.25 9.43799 15.25 10.0981V17.4106C15.25 18.0962 14.6914 18.6294 14.0312 18.6294C13.3457 18.6294 12.8125 18.0962 12.8125 17.4106V10.0981C12.8125 9.43799 13.3457 8.87939 14.0312 8.87939Z"
        fill="#F1F1F1"
      />
    </SvgIcon>
  );
};
