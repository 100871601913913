import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const fetchDietItemsProductsRemoved = async (
  dietId: string,
  fromId: string,
): Promise<ApiResponse<DietProductRemovedItemDto[]>> => {
  const response = await fetchData(
    `/dietitian/diets/${dietId}/items-product-removed?from=${fromId}`,
    APIMethods.GET,
  );

  return responseSchema.validate(response);
};

type RemovedType = "product" | "recipe";

interface TranslationLangDto {
  lang: string;
  name: string;
}

interface ItemDto {
  id: number;
  type: RemovedType;
}

interface ItemDataDto {
  id: number;
  name: string;
  translations: TranslationLangDto[];
}

interface ProductItemDto extends ItemDto {
  type: "product";
  product: ItemDataDto;
}

interface RecipeItemDto extends ItemDto {
  type: "recipe";
  recipe: ItemDataDto;
}

interface MealDto {
  id: number;
  name: string;
  translations: TranslationLangDto[];
}

export interface DietProductRemovedItemDto {
  id: number;
  meal: MealDto;
  item: ProductItemDto | RecipeItemDto;
}

const translationLangSchema = yup.object({
  lang: yup.string().required(),
  name: yup.string().ensure(),
});

const itemDataSchema = yup.object({
  id: yup.number().required(),
  name: yup.string().required(),
  translations: yup.array().of(translationLangSchema).required(),
});

const mealSchema = yup.object({
  id: yup.number().required(),
  name: yup.string().required(),
  translations: yup.array().of(translationLangSchema).required(),
});

const productItemSchema = yup.object({
  id: yup.number().required(),
  type: yup.string<RemovedType>().oneOf(["product"]).required(),
  product: itemDataSchema.required(),
});

const recipeItemSchema = yup.object({
  id: yup.number().required(),
  type: yup.string<RemovedType>().oneOf(["recipe"]).required(),
  recipe: itemDataSchema.required(),
});

const dietProductRemovedItemSchema = yup.object({
  id: yup.number().required(),
  meal: mealSchema.required(),
  item: yup.lazy(value => {
    if (value?.type === "recipe") return recipeItemSchema.required();
    return productItemSchema.required();
  }),
});

const responseSchema = yup.object().shape({
  data: yup.array().of(dietProductRemovedItemSchema).required(),
});
