import { styled } from "@mui/material";

interface WrapperProps {
  hide?: boolean;
}

export const Wrapper = styled("div")<WrapperProps>`
  display: grid;
  gap: 4px;

  ${({ hide, theme }) =>
    hide &&
    `
    height: 84px;
    overflow: hidden;
    @media screen and (min-width: ${theme.breakpoints.values.medium}px) {
        height: 50px;
  }
  `}
`;

export const ExtraSectionWrapper = styled("div")`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;
