import { Button, styled, Typography } from "@mui/material";

export const MainContainer = styled("div")`
  background-color: white;
  border-radius: 16px;
  width: 100%;
  height: calc(100vh - 200px);
  overflow: hidden; // Hide overflow on main container
`;

export const ScrollContainer = styled("div")`
  width: 100%;
  height: 100%;
  overflow: auto;

  // Hide vertical scrollbar but keep functionality
  scrollbar-width: none; // Firefox
  &::-webkit-scrollbar {
    display: none; // Chrome, Safari, Edge
  }
`;

export const GridContainer = styled("div")`
  background-color: white;
  border-radius: 16px;
`;

export const GridHeaderContainer = styled("div")`
  display: grid;
  grid-template-columns: 50px repeat(7, minmax(170px, 1fr));
  width: 100%;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 12;
`;

export const GridHeaderItem = styled("div")`
  border-right: 1px solid ${({ theme }) => theme.colors.gray200};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
  padding: 6px;
  cursor: pointer;
`;

export const GridHeaderItemText = styled(Typography)<{ active: boolean }>`
  background-color: ${({ theme, active }) =>
    active ? theme.palette.primary.medium : theme.palette.primary.light};
  color: ${({ theme, active }) =>
    active ? theme.palette.primary.main : theme.colors.neutral.dark["700"]};
  margin: 0;
  padding: 8px 0;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.4px;
`;

export const GridItem = styled("div")`
  border-right: 1px solid ${({ theme }) => theme.colors.gray200};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
  height: 90px;

  .add-event-button {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease, visibility 0.3s ease;
  }

  &:hover .add-event-button {
    opacity: 1;
    visibility: visible;
  }
`;

export const GridItemText = styled(Typography)`
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.4px;
  text-align: center;
`;

export const DayColumn = styled("div")`
  display: grid;
  grid-template-rows: repeat(9, 90px);
  border-right: 1px solid ${({ theme }) => theme.colors.gray200};
`;

export const AddEventButton = styled("button")`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;

  gap: 4px;
  color: ${({ theme }) => theme.palette.primary.main};
`;
