import { useMemo } from "react";

import {
  GridCallbackDetails,
  GridColumns,
  GridValidRowModel,
} from "@mui/x-data-grid";

import {
  GridPaginationWrapper,
  ITEMS_ON_PAGE,
} from "@components/PaginationNew";
import { useAppTranslation } from "@hooks";
import { Actions, Nutrient } from "@typeDefinitions";

import { useMediaQuery, useTheme } from "@mui/material";
import { FiltersNoResults } from "@views/emptyStates/FiltersNoResults";
import { EmptyStateCardForGrid } from "@views/emptyStates/common.styled";
import { ActionsColumnCell } from "./ActionsColumnCell";
import { CustomHeader } from "./CustomHeader/CustomHeader";
import { CustomRow } from "./CustomRow";
import { NameColumnCell } from "./NameColumnCell";
import { MuiDataGridStyled } from "./RecipesDataGrid.styled";
import { SingleColumnCell } from "./SingleColumnCell/SingleColumnCell";
import { TagsColumnCell } from "./TagsColumnCell";
import { GRID_ROW_BIG, GRID_ROW_SMALL } from "@utils";

interface RecipesDataGridProps {
  rows: RecipeRowIf[];
  onPageChange?: (page: number, details: GridCallbackDetails<any>) => void;
  page: number;
  perPage: number;
  setPerPage: (value: number) => void;
  isOnePage: boolean;
  rowCount?: number;
  loading?: boolean;
  onSelectRow: (id: number | null) => void;
  resetFilters: () => void;
}
export const RecipesDataGrid = ({
  rows,
  onPageChange,
  page,
  perPage,
  setPerPage,
  isOnePage,
  rowCount,
  loading,
  onSelectRow,
  resetFilters,
}: RecipesDataGridProps) => {
  const { t, isPolishChosen } = useAppTranslation();
  const { breakpoints } = useTheme();
  const downMedium = useMediaQuery(`${breakpoints.down("medium")}`);

  const columns = useMemo<GridColumns<RecipeRowIf>>(() => {
    return [
      {
        field: "name",
        headerName: t("recipes.recipe_name"),
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
        renderCell: ({
          row: { id, namePl, nameEn, tags, nutrients, servings },
        }) => (
          <NameColumnCell
            name={isPolishChosen ? namePl : nameEn}
            tags={tags}
            nutrients={nutrients}
            servings={servings}
            onSelect={() => onSelectRow(id)}
          />
        ),
      },
      {
        field: "tags",
        headerName: t("recipes.recipe_tags"),
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
        renderCell: ({ row: { tags } }) => <TagsColumnCell tags={tags} />,
      },
      {
        field: "actions",
        headerName: t("recipes.actions"),
        headerAlign: "right",
        disableColumnMenu: true,
        sortable: false,
        width: 165,
        renderCell: ({
          row: { id, isFavorite: isFavorite, actions, namePl, nameEn },
        }) => (
          <ActionsColumnCell
            id={id}
            isFavorite={isFavorite}
            actions={actions}
            namePl={namePl}
            nameEn={nameEn}
          />
        ),
      },
    ];
  }, [t]);

  const singleColumn = useMemo<GridColumns<RecipeRowIf>>(() => {
    return [
      {
        field: "combinedColumn",
        renderHeader: ({ colDef }) => <CustomHeader {...colDef} />,
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <SingleColumnCell {...row} onSelect={() => onSelectRow(row.id)} />
        ),
      },
    ];
  }, [t]);

  return (
    <MuiDataGridStyled
      columns={downMedium ? singleColumn : columns}
      rows={rows}
      page={page}
      onPageChange={onPageChange}
      rowCount={rowCount}
      pageSize={perPage}
      disableSelectionOnClick
      getRowSpacing={() => ({
        top: 8,
      })}
      autoHeight
      rowHeight={downMedium ? GRID_ROW_BIG : GRID_ROW_SMALL}
      components={{
        Pagination: GridPaginationWrapper,
        Row: CustomRow,
        NoRowsOverlay: () => (
          <EmptyStateCardForGrid className="emptyState">
            <FiltersNoResults onClick={resetFilters} />
          </EmptyStateCardForGrid>
        ),
      }}
      componentsProps={{
        pagination: {
          setPerPage,
          perPage,
          isOnePage,
          rowsPerPageOptions: [ITEMS_ON_PAGE.TEN, ITEMS_ON_PAGE.TWENTY_FIVE],
        },
      }}
      paginationMode="server"
      loading={loading}
    />
  );
};

export interface RecipeRowIf extends GridValidRowModel {
  id: number;
  namePl: string;
  nameEn: string;
  tags: string[];
  nutrients: Nutrient[];
  servings: number;
  isFavorite: boolean;
  actions: Actions;
  imgUrl: string | null;
}
