import { BodyMeasurementResource } from "@client/dictionaries";

export const getBodyMeasurementsByIds = (
  bodyMeasurements: BodyMeasurementResource[],
  ids: number[],
) => {
  return bodyMeasurements
    .filter(({ id }) => ids.includes(id))
    .sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id));
};

export const getBodyMeasurementsById = (
  bodyMeasurements: BodyMeasurementResource[],
  id: number,
) => {
  return bodyMeasurements.find(resource => resource.id === id);
};

export const getTranslation = (
  bodyMeasurement: BodyMeasurementResource,
  lang: string,
) => {
  return bodyMeasurement.translations.find(
    translation => translation.lang === lang,
  );
};
