type PatientId = number;
type ProgramId = number;

export const patientProgramPreviewKeys = {
  base: (patientId: PatientId) =>
    ["patient_program_preview", patientId] as const,
  program: ({
    patientId,
    programId,
  }: {
    patientId: PatientId;
    programId: ProgramId;
  }) => [...patientProgramPreviewKeys.base(patientId), "programs", programId],
};
