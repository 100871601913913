import { useCallback } from "react";
import { useFetchProgramSectionCategoriesQuery } from "@hooks/queries/dictionaries/useFetchProgramSectionCategoriesQuery";
import { ProgramSectionCategory } from "@client/dictionaries/fetchProgramSectionCategoriesDictionary";

export const useProgramSectionCategories = () => {
  const { data } = useFetchProgramSectionCategoriesQuery();

  const get = useCallback(() => getAllUtil(data?.data), [data]);
  const find = useCallback((id: number) => getByIdUtil(data?.data, id), [data]);

  return {
    get,
    find,
  };
};

type DataType = ProgramSectionCategory[] | undefined;
const getAllUtil = (data: DataType): ProgramSectionCategory[] => data ?? [];
const getByIdUtil = (
  data: DataType,
  id: number,
): ProgramSectionCategory | null =>
  getAllUtil(data).find(i => i.id === id) ?? null;
