import { styled } from "@mui/material";

export const QuestionnaireAnswerStyled = styled("div")`
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  margin: 0 auto;
  width: 100%;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallNew}px) {
    padding-bottom: 5.375rem;
    max-width: 41rem;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.smallNew}px) {
    padding-bottom: 4rem;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.smallNew}px) and (max-width: ${({ theme }) =>
      theme.breakpoints.values.smallMax}px) {
    max-width: 56.25rem;
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.medium}px) {
    max-width: 62.375rem;
  }
`;

export const TableOfContentsWrapper = styled("div")`
  position: sticky;
  top: 5.5rem;
`;
