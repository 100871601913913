import { LOGIN } from "@routes";
import { useAppTranslation } from "@hooks";
import { CheckRound, Show } from "@assets/icons";
import { Button } from "@components/Button";
import { FormInput } from "@components/FormInput";

import { useRegisterPatient } from "./useRegisterPatient";

/**
 * View for registering new patient in clinic once he or she
 * received verification link on email
 * @route /auth/register/patient
 */
export const RegisterPatient = () => {
  const {
    control,
    errors,
    apiErrors,
    status,
    isSubmitting,
    showPassword,
    showpasswordConfirmation,
    setShowPassword,
    setShowpasswordConfirmation,
    handleSubmit,
    handleRegisterPatient,
  } = useRegisterPatient();
  const { t } = useAppTranslation();

  if (status === "success")
    return (
      <div
        className="bg-white rounded-md shadow-paper p-7 flex flex-col justify-center items-center px-48 mt-10"
        style={{ minWidth: 900 }}
      >
        <CheckRound size="w-20 h-auto" className="mb-8" />
        <h3 className="text-xl">{t("auth.register.account_activated")}</h3>
        <Button fontLight className="mt-8 min-w-1/2" size="large" to={LOGIN}>
          {t("auth.register.back_to_login")}
        </Button>
      </div>
    );

  return (
    <div
      className="bg-white rounded-md shadow-paper p-7 flex flex-col justify-center items-center px-48 mt-10 mb-10"
      style={{ minWidth: 900 }}
    >
      <img
        src="/static/Logo@2x.png"
        srcSet="/static/Logo@2x.png"
        className="sm:h-10 h-6 mb-6"
        alt="Alloweat logo"
      />

      <h3 className="text-xl">{t("auth.register_patient.title")}</h3>

      <form
        onSubmit={handleSubmit(handleRegisterPatient)}
        className="flex flex-col items-center mt-8 w-full"
      >
        <div className="flex flex-col w-full">
          <p className="mb-2 mt-6 w-full">{t("auth.register.password")}</p>
          <FormInput
            control={control}
            name="password"
            type={showPassword ? "text" : "password"}
            error={errors?.password?.message}
            placeholder={t("auth.register.password")}
            variant="outlined"
            className="w-full"
            suffix={
              <div className="flex items-center text-gray-700">
                <span className="font-roman mr-1">
                  {showPassword ? t("common.hide") : t("common.show")}
                </span>
                <div
                  className="p-2 cursor-pointer"
                  onClick={() => setShowPassword(prev => !prev)}
                >
                  <Show size="w-4 h-4" />
                </div>
              </div>
            }
          />
        </div>

        <div className="flex flex-col w-full mb-4">
          <p className="mb-2 mt-6 w-full">
            {t("auth.register.password_repeat")}
          </p>
          <FormInput
            control={control}
            name="passwordConfirmation"
            type={showpasswordConfirmation ? "text" : "password"}
            error={errors?.passwordConfirmation?.message}
            placeholder={t("auth.register.password_repeat")}
            variant="outlined"
            className="w-full"
            suffix={
              <div className="flex items-center text-gray-700">
                <span className="font-roman mr-1">
                  {showpasswordConfirmation
                    ? t("common.hide")
                    : t("common.show")}
                </span>
                <div
                  className="p-2 cursor-pointer"
                  onClick={() => setShowpasswordConfirmation(prev => !prev)}
                >
                  <Show size="w-4 h-4" />
                </div>
              </div>
            }
          />
        </div>

        {apiErrors && (
          <p className="w-full text-start text-red mb-4">
            {t("auth.register_patient.api_error")}
          </p>
        )}

        <Button
          submit
          isLoading={isSubmitting}
          className="mt-8 min-w-1/2"
          size="large"
        >
          {t("auth.register.register")}
        </Button>
      </form>
    </div>
  );
};
