import styled from "@emotion/styled";
import { IconButton } from "@mui/material";
import { getHexColorOpacity } from "@utils";

export const Name = styled("span")`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const DraggableItemStyled = styled("div")`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 16px;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.neutral.light[100]};
  border: 1px solid ${({ theme }) => theme.colors.neutral.light[200]};
  cursor: pointer;
  &:hover .dragHandle {
    opacity: 1;
  }
`;

export const HandleWrapper = styled("div")`
  opacity: 0;
  padding: 0 4px;
  cursor: grab;
  font-size: 14px;
`;

export const ImageWrapper = styled("div")`
  display: grid;
  place-items: center;
  position: relative;
`;

export const ImgStyled = styled("img")`
  height: 60px;
  width: 60px;
  border-radius: 8px;
`;

export const DraggableButton = styled(IconButton)`
  position: absolute;
  background: ${({ theme }) =>
    getHexColorOpacity(theme.palette.primary.light, 70)};
  color: ${({ theme }) => theme.colors.neutral.dark[700]};
  opacity: 0;
  cursor: grab;
  backdrop-filter: blur(1px);

  &:active {
    cursor: grabbing;
    opacity: 1;
  }

  &.MuiButtonBase-root.MuiIconButton-root:hover {
    background: ${({ theme }) =>
      getHexColorOpacity(theme.palette.primary.light, 70)};
  }
`;

export const ImgBackground = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.palette.primary.light2};
  height: 60px;
  width: 60px;
  min-width: 60px;
  border-radius: 8px;
`;

export const ImagePlaceholder = styled("img")`
  height: 52px;
`;
