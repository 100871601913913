import { useQuery } from "@tanstack/react-query";

import { ApiResponse } from "@typeDefinitions";

import { QueryOptionsTyped } from "./types";
import {
  SystemNotificationDto,
  fetchSystemNotifications,
} from "@client/banners";

export const fetchSystemNotificationListQueryKey =
  "fetchSystemNotificationListQueryKey";

export const useFetchSystemNotificationsQuery = (
  options?: QueryOptionsTyped<ApiResponse<SystemNotificationDto[]>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchSystemNotificationListQueryKey],
    fetchSystemNotifications,
    options,
  );

  return {
    systemNotifications: data?.data,
    ...rest,
  };
};
