import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const requestHideRecipe = async (id: number) =>
  await fetchData(`/dietitian/recipes/${id}/hide`, APIMethods.PUT);

export const requestDeleteRecipe = async (id: number) =>
  await fetchData(`/dietitian/recipes/${id}`, APIMethods.DELETE);

export const requestShowRecipe = async (id: number) =>
  await fetchData(`/dietitian/recipes/${id}/show`, APIMethods.PUT);
