import { MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";
import { useIrrigationProducts } from "@hooks/dictionary/useIrrigationProducts";
import { useAppTranslation } from "@hooks";
import { Language } from "@typeDefinitions";
import { useMemo } from "react";
import {
  DialogSectionItemLabel,
  DialogSectionItemLayout,
} from "@views/dietician/ClientHealth/ClientHealthNew.styled";

const MainProductField = () => {
  const { t, currentLanguage } = useAppTranslation();
  const { getAll, getTranslation } = useIrrigationProducts();

  return (
    <DialogSectionItemLayout>
      <DialogSectionItemLabel>
        {t(
          "patient.health_and_goal.dietary_profile_tab.section_hydration.main_beverage",
        )}
      </DialogSectionItemLabel>
      <Controller
        render={({ field }) => {
          return (
            <Select
              value={field.value}
              onChange={event => field.onChange(event.target.value)}
            >
              {useMemo(
                () =>
                  getAll().map(product => (
                    <MenuItem key={product.id} value={product.id.toString()}>
                      {
                        getTranslation(
                          product,
                          currentLanguage.toUpperCase() as Language,
                        )?.name
                      }
                    </MenuItem>
                  )),
                [getAll, currentLanguage],
              )}
            </Select>
          );
        }}
        name="hydrationProductId"
      />
    </DialogSectionItemLayout>
  );
};

export default MainProductField;
