import { useFormContext, useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import { useDebounce } from "react-use";
import { useAppParams } from "@hooks";
import { UpdateCreatorMealProductRequest } from "@client/meals";
import { MealProductInputs } from "@views/dietician/MealEdit/forms/useMealProductForm";
import { useUpdateCreatorMealProductMutation } from "@hooks/queries/meals/useUpdateCreatorMealProductMutation";

interface MealProductFormWatchProps {
  productId: string;
}

export const MealProductFormWatch = ({
  productId,
}: MealProductFormWatchProps) => {
  const { mealId } = useAppParams();
  const values = useWatch<MealProductInputs>();
  const {
    handleSubmit,
    formState: { isDirty },
  } = useFormContext<MealProductInputs>();

  const { mutate, isLoading } = useUpdateCreatorMealProductMutation(
    mealId,
    productId,
  );
  const [queued, setQueued] = useState(false);

  const createRequest = (
    data: MealProductInputs,
  ): UpdateCreatorMealProductRequest => {
    return {
      foodMeasure: { id: Number(data.measureId) },
      grams: Number(data.grams),
    };
  };

  const [isDirtyLatch, setIsDirtyLatch] = useState(isDirty);

  const update = () => {
    setQueued(false);
    handleSubmit(
      data => mutate(createRequest(data)),
      err => console.log(err),
    )();
  };

  useDebounce(
    () => {
      if (isLoading) {
        setQueued(true);
      }

      if ((isDirty || isDirtyLatch) && values && !isLoading) {
        setIsDirtyLatch(true);
        update();
      }
    },
    1000,
    [JSON.stringify(values)],
  );

  useEffect(() => {
    if (queued && !isLoading) {
      update();
    }
  }, [queued, isLoading]);

  return <></>;
};
