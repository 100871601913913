import { fetchData } from "@utils/api";
import * as yup from "yup";
import { DietitianProgramScheduleDay, dietitianDietDaySchema } from "./common";
import { APIMethods, ApiResponse } from "@typeDefinitions";

export const fetchDietitianClientProgramScheduleDays = async (
  clientId: string,
  programId: string,
  params?: URLSearchParams,
): Promise<ApiResponse<DietitianProgramScheduleDay[]>> => {
  const data = await fetchData(
    `/dietitian/patients/${clientId}/programs/${programId}/schedule/days`,
    APIMethods.GET,
    undefined,
    params,
  );
  return await fetchDietDaysSchema.validate(data);
};

const fetchDietDaysSchema = yup.object().shape({
  data: yup.array().of(dietitianDietDaySchema).required(),
});
