import { useDeleteClinicFacility } from "@hooks/queries/settings";
import { ActionButtons } from "@layouts/DieticianSettingsLayout/components/ActionButtons";

interface OfficeActionProps {
  id: string | number;
  openEditModal: () => void;
  setOfficeId: (id?: number) => void;
  isMain: boolean;
}

export const OfficeAction = ({
  id,
  openEditModal,
  setOfficeId,
  isMain,
}: OfficeActionProps) => {
  const { mutate: deleteFacility } = useDeleteClinicFacility();

  return (
    <ActionButtons
      id={parseInt(id.toString())}
      setEditId={setOfficeId}
      disableDelete={isMain}
      openEditModal={openEditModal}
      deleteMutation={deleteFacility}
    />
  );
};
