import { NutrientsSection } from "@components/PreviewDrawer/common";
import { MacrosChart } from "@components/PreviewDrawer/common/MacrosChart";
import { Nutrient } from "@typeDefinitions";
import { CardStyled, DividerStyled } from "./NutrientsTab.styled";

interface NutrientsTabProps {
  nutrients: Nutrient[];
}
export const NutrientsTab = ({ nutrients }: NutrientsTabProps) => {
  return (
    <CardStyled>
      <MacrosChart nutrients={nutrients} />

      <DividerStyled />

      <NutrientsSection nutrients={nutrients} />
    </CardStyled>
  );
};
