import { fetchData } from "@utils/api";
import { APIMethods, ApiResponse, LanguagesSlugs } from "@typeDefinitions";
import * as yup from "yup";
import { logFetchValidateError } from "@utils/validate";

export interface ProgramSectionCategory {
  id: number;
  name: string;
  translations: {
    lang: LanguagesSlugs;
    name: string | null;
  }[];
}

export type ProgramSectionCategoryResponse = ApiResponse<
  ProgramSectionCategory[]
>;

export async function fetchProgramSectionCategoriesDictionary(): Promise<ProgramSectionCategoryResponse> {
  const response = await fetchData(
    `/dietitian/dictionaries/program-section-categories`,
    APIMethods.GET,
  );

  return schema
    .validate(response)
    .catch(
      logFetchValidateError(
        "/dietitian/dictionaries/program-section-categories",
      ),
    );
}

const programSectionCategorySchema = yup.object({
  id: yup.number().required(),
  name: yup.string().required(),
  translations: yup
    .array()
    .of(
      yup.object({
        lang: yup
          .mixed<LanguagesSlugs>()
          .oneOf(Object.values(LanguagesSlugs))
          .required(),
        name: yup.string().nullable().defined(),
      }),
    )
    .defined(),
});

const schema = yup.object({
  data: yup.array().of(programSectionCategorySchema).defined(),
});
