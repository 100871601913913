import { GridStateColDef } from "@mui/x-data-grid";
import { ProductRowIf } from "../ProductsDataGrid";
import { CustomHeaderWrapper } from "./CustomHeader.styled";
import { useAppTranslation } from "@hooks";

export const CustomHeader = ({
  computedWidth,
}: GridStateColDef<ProductRowIf, any, any>) => {
  const { t } = useAppTranslation();

  return (
    <CustomHeaderWrapper width={computedWidth}>
      <span>{t("products.product_name")}</span>
      <span>{t("common.tags")}</span>
      <span>{t("products.actions")}</span>
    </CustomHeaderWrapper>
  );
};
