import { RecipeTabPanels } from "@components/PreviewDrawer/Recipe";
import { useFetchRecipeQuery } from "@hooks/queries";
import { CircularProgress } from "@mui/material";
import { RecipeNutrientsTab } from "../RecipeNutrientsTab/RecipeNutrientsTab";
import { ServingContextProvider } from "@components/PreviewDrawer/Recipe/ServingsContext";
import { useAppTranslation, useMealClientContext } from "@hooks";
import { useItemsContext } from "../ItemsContext";

interface RecipeContentProps {
  id: number;
  updateServings: (servings: number) => void;
}
export const RecipeContent = ({ id, updateServings }: RecipeContentProps) => {
  const { recipe, isLoading } = useFetchRecipeQuery(id);
  const { isPolishChosen } = useAppTranslation();
  const { getRecipeServings } = useItemsContext();

  if (isLoading) return <CircularProgress />;
  if (!recipe) return <></>;
  return (
    <ServingContextProvider
      totalServings={recipe.servings}
      servings={getRecipeServings(id) ?? undefined}
    >
      <RecipeTabPanels
        nutrients={recipe.nutrients}
        description={recipe.description ?? ""}
        descriptionEn={recipe.descriptionEn ?? ""}
        products={recipe.foodRecipe.map(f => ({
          grams: f.grams,
          id: f.id,
          foodId: f.food.id,
          measureId: f.foodMeasureId,
          measures: f.food.measures,
          name: f.food[isPolishChosen ? "descriptionPl" : "description"] ?? "",
          nutrients: f.food.nutrients,
        }))}
        tags={recipe.tags.map(t => t.id)}
        NutrientsTab={props => (
          <RecipeNutrientsTab {...props} onServingsChange={updateServings} />
        )}
        padding="8px"
        comment={recipe.comment}
        commentEn={recipe.commentEn}
      />
    </ServingContextProvider>
  );
};
