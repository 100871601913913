import { useController, useFormContext } from "react-hook-form";

import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

import { useAppTranslation } from "@hooks";

import { FoodGroupResourceDto } from "@client/surveys";
import { OuterLabel } from "@components/OuterLabel";
import {
  FormTreeSelectSearch,
  TreeItemOption,
} from "@components/TreeSelectSearch/";
import { useContext, useMemo } from "react";
import { BlurContext, FormTextFieldWrapper } from "../BlurContext";
import { FoodAnswerFormInputs } from "../forms";
import { SAFARI_TAB_INDEX } from "@utils";

interface FoodRadioGroupProps {
  readOnly?: boolean;
  options: FoodGroupResourceDto[] | undefined;
}
export const FoodRadioGroup = ({ readOnly, options }: FoodRadioGroupProps) => {
  const { t, isPolishChosen } = useAppTranslation();
  const { handleBlur, handleFocus } = useContext(BlurContext);
  const { control } = useFormContext<FoodAnswerFormInputs>();
  const {
    field: { value, onChange },
  } = useController<FoodAnswerFormInputs>({ control, name: "value" });

  const mapTreeSelectOptions = (
    options: FoodGroupResourceDto[] | undefined,
  ): TreeItemOption[] => {
    if (!options) return [];

    const mapItem = (
      {
        id,
        description,
        descriptionPl,
        children,
        parentId,
      }: FoodGroupResourceDto,
      parentIds: string[],
    ): TreeItemOption => ({
      id: id.toString(),
      name: isPolishChosen ? descriptionPl || description : description,
      children: children?.length
        ? children.map(c => mapItem(c, [...parentIds, parentId.toString()]))
        : [],
      allParents: [...parentIds, parentId.toString()],
      parentId: parentId.toString(),
    });

    return options.map(o => mapItem(o, []));
  };

  const mappedOptions = useMemo(
    () => mapTreeSelectOptions(options),
    [options, isPolishChosen],
  );

  if (value === undefined) return <></>;

  return (
    <>
      <div className="grid gap-4">
        <RadioGroup
          tabIndex={SAFARI_TAB_INDEX}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={value}
          onChange={(_, v) => !readOnly && onChange(v === BooleanAnswer.YES)}
        >
          <FormControlLabel
            value={BooleanAnswer.NO}
            control={<Radio />}
            label={t(
              "questionnaires.questions.system_user_preferences_food_unliked.no",
            )}
          />
          <FormControlLabel
            value={BooleanAnswer.YES}
            control={<Radio />}
            label={t(
              "questionnaires.questions.system_user_preferences_food_unliked.yes",
            )}
          />
        </RadioGroup>
        {value && (
          <FormTreeSelectSearch
            options={mappedOptions}
            control={control}
            name="foodGroupsId"
            onBlur={handleBlur}
            onFocus={handleFocus}
            placeholder={t(
              "questionnaires.questions.system_user_preferences_food_liked.placeholder",
            )}
          />
        )}
      </div>
      {value && (
        <OuterLabel
          label={t(
            "questionnaires.questions.system_user_preferences_food_liked.comment",
          )}
        >
          <FormTextFieldWrapper
            size="small"
            multiline
            rows={3}
            control={control}
            name="description"
            fullWidth
          />
        </OuterLabel>
      )}
    </>
  );
};

enum BooleanAnswer {
  YES = "true",
  NO = "false",
}
