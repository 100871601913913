import image from "@assets/img/programEmptyState.png";
import {
  ContentWrapper,
  ProgramEmptyStateWrapper,
  TilesListWrapper,
} from "./ProgramEmptyState.styled";
import { useAppTranslation } from "@hooks";
import { FeatureTile } from "./FeatureTile";
import { Carrot } from "@assets/icons/DrawerMenu";
import { ThemeProviderWrapperNew } from "themeNew";
import { Document, Gear } from "@assets/icons";
import { EmptyStateDialog } from "./EmptyStateDialog";
import { useState } from "react";
import { EmptyStateCard, StyledSubTitle, StyledTitle } from "../common.styled";
import { Button, Tooltip, useTheme } from "@mui/material";
import { DocWithApple } from "@assets/icons/DesignSystem";

enum Modals {
  DAIRY,
  MACROS,
  SETS,
}

interface ProgramEmptyStateProps {
  onClick: () => void;
  disabled?: boolean;
}

export const ProgramEmptyState = ({
  onClick,
  disabled,
}: ProgramEmptyStateProps) => {
  const { t } = useAppTranslation();
  const [modal, setModal] = useState<null | Modals>(null);
  const { palette } = useTheme();

  const handleOpenModal = (m: Modals) => {
    setModal(m);
  };
  const handleCloseModal = () => {
    setModal(null);
  };
  const isOpened = (m: Modals) => m === modal;

  return (
    <ThemeProviderWrapperNew>
      <EmptyStateCard>
        <ProgramEmptyStateWrapper>
          <ContentWrapper>
            <img src={image} alt={t("empty_states.alt.no_programs")} />
            <StyledTitle>{t("empty_states.program")}</StyledTitle>
            <StyledSubTitle maxWidth="40rem">
              {t("empty_states.program_info")}
            </StyledSubTitle>
          </ContentWrapper>

          <TilesListWrapper>
            <FeatureTile
              icon={<Document fill={palette.primary.main} />}
              onClick={() => handleOpenModal(Modals.DAIRY)}
              title={t("empty_states.diary")}
            />
            <FeatureTile
              icon={<Carrot fill={palette.primary.main} />}
              onClick={() => handleOpenModal(Modals.MACROS)}
              title={t("empty_states.macros")}
            />
            <FeatureTile
              icon={<DocWithApple fill={palette.primary.main} />}
              onClick={() => handleOpenModal(Modals.SETS)}
              title={t("empty_states.sets")}
            />
          </TilesListWrapper>
          <Tooltip
            placement="top"
            arrow
            title={t("common.access_functionality_tooltip")}
            disableHoverListener={!disabled}
          >
            <div>
              <Button
                variant="contained"
                startIcon={<Gear size="w-4 h-4" />}
                onClick={onClick}
                disabled={disabled}
              >
                {t("empty_states.begin")}
              </Button>
            </div>
          </Tooltip>
        </ProgramEmptyStateWrapper>
      </EmptyStateCard>
      <EmptyStateDialog
        open={isOpened(Modals.DAIRY)}
        onClose={handleCloseModal}
        icon={<Document fill={palette.primary.main} />}
        title={t("empty_states.modal.diary.title")}
        question={t("empty_states.modal.diary.question")}
        answer={t("empty_states.modal.diary.answer")}
        instructionQuestion={t("empty_states.modal.diary.instructionQuestion")}
        instruction={t("empty_states.modal.diary.instruction")}
      />
      <EmptyStateDialog
        open={isOpened(Modals.MACROS)}
        onClose={handleCloseModal}
        icon={<Carrot fill={palette.primary.main} />}
        title={t("empty_states.modal.macros.title")}
        question={t("empty_states.modal.macros.question")}
        answer={t("empty_states.modal.macros.answer")}
        instructionQuestion={t("empty_states.modal.macros.instructionQuestion")}
        instruction={t("empty_states.modal.macros.instruction")}
      />
      <EmptyStateDialog
        open={isOpened(Modals.SETS)}
        onClose={handleCloseModal}
        icon={<DocWithApple fill={palette.primary.main} />}
        title={t("empty_states.modal.sets.title")}
        question={t("empty_states.modal.sets.question")}
        answer={t("empty_states.modal.sets.answer")}
        instructionQuestion={t("empty_states.modal.sets.instructionQuestion")}
        instruction={t("empty_states.modal.sets.instruction")}
      />
    </ThemeProviderWrapperNew>
  );
};
