import { Drawer, styled } from "@mui/material";

export const StyledDrawer = styled(Drawer)`
  & .MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper {
    display: flex;
    flex-direction: column;
    border-radius: 0;
    margin-top: 56px;
    max-height: calc(100% - 56px);
    width: 440px;
  }
`;

export const Title = styled("span")`
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.neutral.dark[800]};
`;

export const Header = styled("div")`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.palette.primary.light};
`;

export const Body = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 16px 66px 16px;
  overflow-y: auto;
`;

export const Footer = styled("div")`
  justify-self: flex-end;
  display: flex;
  justify-content: flex-end;
  padding: 12px 16px 12px 16px;
  gap: 8px;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  background: ${({ theme }) => theme.palette.primary.light};
`;
