import { styled } from "@mui/material";
import DialogActionsMui from "@mui/material/DialogActions";
import DialogMui from "@mui/material/Dialog";

const DialogActions = styled(DialogActionsMui)`
  button {
    padding: 8px 12px;
    min-width: unset;
    height: unset;
    border-radius: 8px;
    font: var(--font-figtree-medium);
    font-size: 14px;
    line-height: 24px;
  }
` as typeof DialogActionsMui;

export default DialogActions;
