import { TaskDeadlineSelect } from "../TasksDeadlineSelect/TasksDeadlineSelect";
import { TasksViewSettings } from "../TasksViewSettings/TasksViewSettings";
import { Plus } from "@assets/icons";
import { useTasksContext } from "../../Tasks.context";
import {
  TasksStyledIconButton,
  TaskActionsStyledButton,
} from "./TasksActions.styled";
import { FormProvider } from "react-hook-form";
import { useSettingsTaskForm } from "@hooks/tasks/useSettingsTaskForm";
import { useAppTranslation } from "@hooks";
import { useSuggestedTasksCountQuery } from "@hooks/queries/suggestedTasks/useSuggestedTasksCountQuery";

export const TasksActions = () => {
  const { t } = useAppTranslation();
  const { handleOpenCreateModal, handleOpenSuggestedTasksPanel } =
    useTasksContext();
  const form = useSettingsTaskForm();

  const { data: hiddenData } = useSuggestedTasksCountQuery({ hidden: 0 });
  const { data: totalTasks } = useSuggestedTasksCountQuery();

  const totalNotHidden =
    totalTasks && hiddenData ? totalTasks?.count - hiddenData?.count : 0;

  return (
    <div className="flex gap-2 items-center">
      <TaskDeadlineSelect />
      <TaskActionsStyledButton
        color="secondary-purple"
        onClick={handleOpenSuggestedTasksPanel}
      >{`${t("tasks.suggestedTasks")} (${
        totalNotHidden ?? ""
      })`}</TaskActionsStyledButton>
      <FormProvider {...form}>
        <TasksViewSettings />
      </FormProvider>

      <TasksStyledIconButton
        color="secondary-purple"
        onClick={handleOpenCreateModal}
      >
        <Plus width="14" height="14" />
      </TasksStyledIconButton>
    </div>
  );
};
