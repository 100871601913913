import { createContext, useContext } from "react";
import { NutrientDetails } from "@hooks";
import { roundNutrientDigitsById } from "@utils";
import { Nutrient } from "@typeDefinitions";

interface VisibleNutrientsIf {
  nutrients: string[];
  setNutrients: (nutrients: string[]) => void;
}

export const VisibleNutrientsContext = createContext<VisibleNutrientsIf>({
  nutrients: [],
  setNutrients: () => null,
});

export function useVisibleNutrients() {
  return useContext(VisibleNutrientsContext);
}

export function mapVisibleNutrientsDetails(
  visibleNutrients: number[],
  nutrients: Nutrient[],
  getNutrient: (id: number) => NutrientDetails | undefined,
) {
  return visibleNutrients.map(id => {
    const nutrient = nutrients.find(n => n.id === id);
    const nutrientDetails = getNutrient(id);
    return {
      id,
      value: nutrient
        ? roundNutrientDigitsById(
            nutrient.value,
            nutrientDetails?.categoryId,
            nutrient.id,
          )
        : " - ",
      unit: nutrientDetails?.unit,
    };
  });
}

export const visibleNutrientsKey = "visibleNutrients";
