import { FormCountryAutocomplete } from "@components/FormCountryAutocomplete";
import { Alert, Button, styled } from "@mui/material";

export const PhoneRowWrapper = styled("div")`
  display: grid;
  grid-template-columns: 6.25rem 1fr;
  align-items: start;
  gap: 1rem;
`;

export const StyledFormCountryAutocomplete = styled(FormCountryAutocomplete)`
  & .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
    padding-right: 54px;
  }
`;

export const SplitRowStyled = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
`;

export const StyledAlert = styled(Alert)`
  padding: 0;
  & .MuiAlert-icon {
    padding: 0.12rem 0 0;
  }

  & .MuiAlert-message {
    padding: 0;
  }

  & a {
    text-decoration: underline;
  }
`;

export const EmailVerifyWrapper = styled("div")`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  padding: 0;
`;
