import { TabsChipsFiltersForm } from "@components/Filters/TabsChipsFilter/TabsChipsFilterForm";
import { Box } from "@mui/material";
import { DietitianFilter } from "@views/dietician/Tasks/components/TaskDietitianFilter/DietitianFilter";
import { useAppTranslation } from "@hooks";
import { FC } from "react";
import { OptionIf } from "@components/Filters";
import { useFetchSchedulesDictionariesQuery } from "@hooks/queries";

interface CalendarFiltersProps {
  facilitiesAndMeetings: {
    facilityId: number | null;
    type: number;
  }[];
}

export const CalendarFilters: FC<CalendarFiltersProps> = ({
  facilitiesAndMeetings,
}) => {
  const { t } = useAppTranslation();

  const { schedulesDictionaries } = useFetchSchedulesDictionariesQuery();

  const facilityCounts = facilitiesAndMeetings.reduce((acc, { facilityId }) => {
    if (facilityId !== null) {
      acc[facilityId] = (acc[facilityId] || 0) + 1;
    }
    return acc;
  }, {} as Record<number, number>);

  const facilities =
    schedulesDictionaries?.facilities.map(({ id, city }) => ({
      id: String(id),
      label: `${city} (${facilityCounts[id] || 0})`,
    })) ?? [];

  const filteredMeetings = schedulesDictionaries?.meetingTypes.filter(
    ({ name }) => name.en !== "todo",
  );

  const meetingCounts = facilitiesAndMeetings.reduce((acc, { type }) => {
    if (type !== null) {
      acc[type] = (acc[type] || 0) + 1;
    }
    return acc;
  }, {} as Record<number, number>);

  const meetings =
    filteredMeetings?.map(({ id, name }) => ({
      id: String(id),
      label: `${t(`calendar.${name.en}`)} (${meetingCounts[id] || 0})`,
    })) ?? [];

  const tagFilterOptions = [...meetings, ...facilities];

  if (tagFilterOptions.length === 0) return null;

  return (
    <Box display="flex" gap="18px" alignItems="center">
      <DietitianFilter name="dietitians" />
      <div className="flex flex-wrap gap-[4px]">
        <TabsChipsFiltersForm
          name="meeting_types"
          options={meetings}
          multiple
          isWrapped={false}
        />
        {facilities.length && (
          <TabsChipsFiltersForm
            name="facilities"
            options={facilities}
            multiple
            isWrapped={false}
          />
        )}
      </div>
    </Box>
  );
};
