import { useMutation, useQueryClient } from "@tanstack/react-query";

import { deleteClient } from "@client";
import { useAppTranslation } from "@hooks";
import { toast } from "react-toastify";
import { MutationOptions } from "./types";
import { fetchClientsQueryKey } from "./useFetchClientsQuery";
import { searchClientsQueryKey } from "./settings";
import { fetchClientQueryKey } from "./useFetchClientQuery";

export const useDeleteClientMutation = (options?: MutationOptions) => {
  const { t } = useAppTranslation();
  const queryClient = useQueryClient();

  return useMutation((patientId: number) => deleteClient(patientId), {
    ...options,
    onSuccess: (_, patientId) => {
      toast.success(t("patient.confirm.client_removed"));
      queryClient.invalidateQueries([fetchClientsQueryKey]);
      queryClient.invalidateQueries([searchClientsQueryKey]);
      queryClient.invalidateQueries([fetchClientQueryKey]);
      queryClient.removeQueries({
        queryKey: [fetchClientQueryKey, patientId],
        exact: true,
      });
      options?.onSuccess && options.onSuccess();
    },
  });
};
