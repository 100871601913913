import { useQuery } from "@tanstack/react-query";
import { fetchSuggestedTasksList } from "@client/suggestedTasks/fetchSuggestedTasksList";
import { ApiEndpoints } from "@typeDefinitions";
import type { SuggestedTasksQueryParams } from "@client/suggestedTasks/fetchSuggestedTasksList";
import { useQueryParams } from "../useQueryParams";

export const useSuggestedTasksQuery = (
  page: number,
  params: SuggestedTasksQueryParams,
) => {
  const finalPage = page + 1;

  const { get } = useQueryParams("tagId");

  const { tagId } = get<{
    tagId: number[] | undefined;
  }>();

  const composedParams = params.hidden ? { ...params, tagsId: tagId } : params;

  return useQuery({
    queryKey: [
      ApiEndpoints.SuggestedTasksList,
      finalPage,
      JSON.stringify(composedParams),
    ],
    queryFn: async () => {
      const { data, meta } = await fetchSuggestedTasksList(
        finalPage,
        composedParams,
      );

      return { tasks: data, meta };
    },
  });
};
