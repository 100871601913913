import { useQuery } from "@tanstack/react-query";

import {
  CreatorDietMealRecipeDto,
  fetchCreatorDietMealRecipe,
} from "@client/diets/creator";
import { QueryOptionsTyped } from "@hooks/queries/types";
import { ApiResponse } from "@typeDefinitions";

import { useCreatorVersion } from "./useCreatorVersion";

export const fetchCreatorDietMealRecipeQueryKey =
  "fetchCreatorDietMealRecipeQueryKey";

export const useFetchCreatorDietMealRecipeQuery = (
  dietId: string,
  mealId: string,
  recipeId: string,
  options?: QueryOptionsTyped<ApiResponse<CreatorDietMealRecipeDto>>,
) => {
  const { version } = useCreatorVersion();

  const { data, ...rest } = useQuery(
    [fetchCreatorDietMealRecipeQueryKey, dietId, mealId, recipeId],
    () => fetchCreatorDietMealRecipe(dietId, mealId, recipeId, version),
    options,
  );

  return {
    data: data?.data,
    ...rest,
  };
};
