import { Box, Stack, styled } from "@mui/material";
import { MouseEvent, useState } from "react";
import {
  CustomTabPanel,
  CustomToggleButton,
  CustomToggleButtonGroup,
} from "@views/dietician/PatientMonitoring2/components/TabPanel.styled";
import { TabContext } from "@mui/lab";
import { useAppTranslation } from "@hooks";
import MeasurementsActualCompare from "@views/dietician/PatientMonitoring2/components/MeasurementsBox/Compare/MeasurementsActualCompare";
import MeasurementsFirstCompare from "@views/dietician/PatientMonitoring2/components/MeasurementsBox/Compare/MeasurementsFirstCompare";
import MeasurementsLastCompare from "@views/dietician/PatientMonitoring2/components/MeasurementsBox/Compare/MeasurementsLastCompare";

const MeasurementsCompareSection = () => {
  const { t } = useAppTranslation();
  const [tab, setTab] = useState<string>("1");

  const handleChange = (
    event: MouseEvent<HTMLElement>,
    newValue: string | null,
  ) => {
    newValue && setTab(newValue);
  };

  return (
    <Stack spacing="16px" direction="column">
      <Title>{t("monitoring2.measurements_box.compare_title")}</Title>
      <TabContext value={tab}>
        <Box>
          <CustomToggleButtonGroup
            value={tab}
            exclusive
            onChange={handleChange}
          >
            <CustomToggleButton value="1">
              {t("monitoring2.measurements_box.measurement_tabs.actual")}
            </CustomToggleButton>
            <CustomToggleButton value="2">
              {t("monitoring2.measurements_box.measurement_tabs.first")}
            </CustomToggleButton>
            <CustomToggleButton value="3">
              {t("monitoring2.measurements_box.measurement_tabs.last")}
            </CustomToggleButton>
            {/*<CustomToggleButton value="4">*/}
            {/*  {t("monitoring2.measurements_box.measurement_tabs.other")}*/}
            {/*</CustomToggleButton>*/}
          </CustomToggleButtonGroup>
        </Box>
        <CustomTabPanel value="1">
          <MeasurementsActualCompare />
        </CustomTabPanel>
        <CustomTabPanel value="2">
          <MeasurementsFirstCompare />
        </CustomTabPanel>
        <CustomTabPanel value="3">
          <MeasurementsLastCompare />
        </CustomTabPanel>
        <CustomTabPanel value="4"></CustomTabPanel>
      </TabContext>
    </Stack>
  );
};

const Title = styled("span")`
  color: #333333;
  font-size: 14px;
  line-height: 24px;
  font-family: Figtree, serif;
  font-weight: 600;
`;

export default MeasurementsCompareSection;
