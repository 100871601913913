import dayjs from "dayjs";

import { DietitianProgramScheduleRecipe } from "@client/schedule";

export const PROGRAM_SCHEDULE_TOUR = "programScheduleTour";

export const mealCopyMap = (
  mealId: number | null,
  recipe: DietitianProgramScheduleRecipe,
) => {
  return {
    id: mealId ?? 0,
    namePl: recipe.title,
    nameEn: recipe.titleEn ?? recipe.title,
  };
};

export const mealPasteMap = (copiedMealId: number, mealId: number | null) => {
  return {
    programScheduleMeal: { id: copiedMealId },
    mealsToReplace: [{ id: mealId ?? 0 }],
  };
};

export const mapDateRange = (start: string, end: string) => {
  return `${dayjs(start).locale("pl").format("DD.MM")} - ${dayjs(end)
    .locale("pl")
    .format("DD.MM")}`;
};
