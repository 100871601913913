import { styled } from "@mui/material";

export const Container = styled("div")({
  display: "flex",
  alignItems: "center",
  flexWrap: "nowrap",
  overflow: "hidden",
  gap: "8px",
});

export const TooltipContainer = styled("div")({
  display: "flex",
  maxWidth: "max-content",
  padding: "8px",
  gap: "8px",
});
