import { QueryOptionsTyped } from "@hooks/queries";
import { useQuery } from "@tanstack/react-query";
import {
  fetchMonitoringMeasurementsFirst,
  FetchMonitoringMeasurementsFirstParams,
  FetchMonitoringMeasurementsFirstResponse,
} from "@client/patient/monitoring/fetchMonitoringMeasurementFirst";
import { patientMonitoringKeys } from "@hooks/queries/client/monitoring/patientMonitoringKey";

export const useFetchMonitoringMeasurementFirst = (
  params: FetchMonitoringMeasurementsFirstParams,
  options?: QueryOptionsTyped<FetchMonitoringMeasurementsFirstResponse>,
) => {
  return useQuery({
    queryKey: patientMonitoringKeys.measurementFirst(params),
    queryFn: () => fetchMonitoringMeasurementsFirst(params),
    ...options,
  });
};
