import { styled } from "@mui/material";

interface EditorWrapperProps {
  outlined?: boolean;
}

export const EditorWrapper = styled("div")<EditorWrapperProps>`
  display: flex;
  ${({ outlined, theme }) =>
    outlined &&
    `
  min-height: 9.375rem;
  border: 1px solid ${theme.palette.divider};
  border-radius: 0.25rem;
  max-width: 40.25rem;
  width: 100%;
  padding: 0.5rem;
  overflow: auto;
  font-family: ${theme.fontFamily.regular};
  font-size: initial;
  & .mce-content-body{
    width: 100%;
    outline: none;
  }
`}
  ol,
  ul {
    list-style: revert;
    padding: revert;
    margin: revert;
  }
`;
