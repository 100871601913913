import React from "react";
import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const BulletAlert = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <g transform="translate(-1539 -1253)">
      <circle
        cx="9"
        cy="9"
        r="9"
        transform="translate(1539 1253)"
        fill="#f25c5a"
      />
      <path
        d="M47.064,18.91V15.943h3V18.91Zm.759-4.129-.741-5.506V6.292h2.965V9.274l-.741,5.506Z"
        transform="translate(1499.436 1249.708)"
        fill="#fff"
      />
    </g>
  </SvgIcon>
);
