import { useController, useFormContext } from "react-hook-form";
import { useContext } from "react";

import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

import { useAppTranslation } from "@hooks";
import { decimalPercentageInput } from "@utils/inputs";

import { BlurContext } from "../BlurContext";
import { FatTissueAnswerFormInputs } from "../forms/useFatTissueAnswerForm";
import {
  FatTissueHeader,
  FatTissueWrapper,
} from "./ControlledFatTissueInputs.styled";
import { FormTextFieldWrapper } from "../BlurContext/FormTextFieldWrapper";
import { SAFARI_TAB_INDEX } from "@utils";

interface ControlledFatTissueInputsProps {
  readOnly?: boolean;
}
export const ControlledFatTissueInputs = ({
  readOnly,
}: ControlledFatTissueInputsProps) => {
  const { handleBlur, handleFocus } = useContext(BlurContext);
  const { t } = useAppTranslation();
  const { control } = useFormContext<FatTissueAnswerFormInputs>();
  const {
    field: { value: known, onChange },
  } = useController<FatTissueAnswerFormInputs>({ name: "known" });

  if (known === undefined) return <></>;

  return (
    <>
      <RadioGroup
        tabIndex={SAFARI_TAB_INDEX}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={known}
        onChange={(_, v) => !readOnly && onChange(v === "true")}
      >
        <FormControlLabel
          value={true}
          control={<Radio />}
          label={t("common.yes")}
        />
        <FormControlLabel
          value={false}
          control={<Radio />}
          label={t("common.no")}
        />
      </RadioGroup>
      {!!known && (
        <FatTissueWrapper>
          <FatTissueHeader>
            {t("questionnaires.questions.system_user_fat_tissue.placeholder")}
          </FatTissueHeader>
          <FormTextFieldWrapper
            control={control}
            name="fat"
            size="small"
            placeholder={t(
              "questionnaires.questions.system_user_fat_tissue.placeholder",
            )}
            FormHelperTextProps={{ hidden: true }}
            InputProps={{ readOnly, inputComponent: decimalPercentageInput }}
          />
        </FatTissueWrapper>
      )}
    </>
  );
};
