import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const ArrowDown = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <rect
      id="icon_20x20_odstep_18px"
      data-name="icon 20x20 / odstep 18px"
      width="18"
      height="18"
      fill="none"
    />
    <path
      id="Stroke_1"
      data-name="Stroke 1"
      d="M9.9,0,4.95,4.95,0,0"
      transform="translate(4.5 6.3)"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </SvgIcon>
);
