import {
  BalanceActivityDaily,
  BalanceActivityTraining,
  BalanceFormula,
  BalanceType,
  BalanceValueMethod,
  FrequencyDay,
} from "@utils/balance";
import { fetchData } from "@utils/api";
import { APIMethods } from "@typeDefinitions";

export const updateClientBalance = async (
  patientId: string,
  payload: UpdateClientBalanceRequest,
) => {
  return await fetchData(
    `/dietitian/patients/${patientId}/energy-balance`,
    APIMethods.PUT,
    payload,
  );
};

export interface UpdateClientBalanceRequest {
  type: BalanceType;
  days: BalanceDayDto[];
}

interface BalanceDayDto {
  id: number | null;
  type: BalanceType;
  frequency: FrequencyDay[];
  translations: TranslationDto[];
  suggestedEnergy: SuggestedEnergyDto;
  energyDemand: EnergyDemandPalDto | EnergyDemandManualDto;
}

interface TranslationDto {
  locale: "pl" | "en";
  name: string | null;
}

interface SuggestedEnergyDto {
  kcal: number | null;
  protein: number | null;
  carb: number | null;
  fat: number | null;
}

interface EnergyDemandBasic {
  pattern: BalanceFormula | null;
  spm: ValueMethodDto;
  cpm: ValueMethodDto;
}
interface EnergyDemandPalDto extends EnergyDemandBasic {
  pal: ValueMethodDto;
  activity: ActivityDto;
}

interface ActivityDto {
  daily: BalanceActivityDaily | null;
  training: BalanceActivityTraining | null;
}

interface EnergyDemandManualDto extends EnergyDemandBasic {
  tef: ValueMethodDto;
  neat: ValueMethodDto;
  tea: ValueMethodDto;
  steps: number | null;
}

interface ValueMethodDto {
  value: number | null;
  method: BalanceValueMethod | null;
}
