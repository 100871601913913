import { useMediaQuery, useTheme } from "@mui/material";

import { useAppTranslation } from "@hooks";
import { Nutrient } from "@typeDefinitions";
import { CARBS_ID, FATS_ID, PROTEIN_ID } from "@utils";

import {
  ChartWrapper,
  MicronutrientPreviewWrapper,
  MonitoringDayChartsStyled,
} from "./MonitoringDayDetails.styled";
import { VerticalBarChart } from "./VerticalBarChart";
import { VerticalChartLabel } from "./VerticalChartLabel";
import { MicronutrientPreview } from "@components/MicronutrientsModal";

interface MonitoringDayChartsProps {
  getNutrientPercentage: (nutrientId: number) => number | null;
  getNutrientTarget: (nutrientId: number) => number | null;
  getNutrientValue: (array: Nutrient[], nutrientId: number) => number | null;
  nutrients: Nutrient[];
  targetNutrients: Nutrient[];
}
export const MonitoringDayCharts = ({
  getNutrientPercentage,
  getNutrientTarget,
  getNutrientValue,
  nutrients,
  targetNutrients,
}: MonitoringDayChartsProps) => {
  const { t } = useAppTranslation();
  const { colors, breakpoints } = useTheme();
  const downDesktop = useMediaQuery(`${breakpoints.down("desktop")}`);
  const downLargeDesktop = useMediaQuery(`${breakpoints.down("largeDesktop")}`);

  return (
    <>
      <MonitoringDayChartsStyled>
        <ChartWrapper>
          <VerticalBarChart
            backgroundColor={colors.chartPurple}
            percentage={getNutrientPercentage(PROTEIN_ID)}
          />
          <VerticalChartLabel
            label={t("monitoring.protein")}
            targetValue={getNutrientTarget(PROTEIN_ID)}
            value={getNutrientValue(nutrients, PROTEIN_ID)}
            unit="g"
          />
        </ChartWrapper>

        <ChartWrapper>
          <VerticalBarChart
            backgroundColor={colors.chartOrange}
            percentage={getNutrientPercentage(FATS_ID)}
          />
          <VerticalChartLabel
            label={t("monitoring.fat")}
            targetValue={getNutrientTarget(FATS_ID)}
            value={getNutrientValue(nutrients, FATS_ID)}
            unit="g"
          />
        </ChartWrapper>

        <ChartWrapper>
          <VerticalBarChart
            backgroundColor={colors.chartGreen}
            percentage={getNutrientPercentage(CARBS_ID)}
          />
          <VerticalChartLabel
            label={t("monitoring.carb")}
            targetValue={getNutrientTarget(CARBS_ID)}
            value={getNutrientValue(nutrients, CARBS_ID)}
            unit="g"
          />
        </ChartWrapper>

        {(downDesktop || !downLargeDesktop) && (
          <div className="h-11 m-auto">
            <MicronutrientPreview
              means={nutrients}
              targetNutrients={targetNutrients}
            />
          </div>
        )}
      </MonitoringDayChartsStyled>
      {!downDesktop && downLargeDesktop && (
        <MicronutrientPreviewWrapper>
          <MicronutrientPreview
            means={nutrients}
            targetNutrients={targetNutrients}
          />
        </MicronutrientPreviewWrapper>
      )}
    </>
  );
};
