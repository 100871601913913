import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Plus = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 12.01 12"
    style={{ width: props.width || "12px", height: props.height || "12px" }}
  >
    <g transform="translate(1 1)">
      <path
        d="M.476,0V10"
        transform="translate(4.529)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        fill="currentColor"
        stroke={props.fill ?? "currentColor"}
      />
      <path
        d="M10.01.476H0"
        transform="translate(0 4.524)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        fill="currentColor"
        stroke={props.fill ?? "currentColor"}
      />
    </g>
  </SvgIcon>
);
