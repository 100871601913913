import { useEffect, useMemo, useState } from "react";

import { CircularProgress } from "@mui/material";

import { ProductExchangeDto } from "@client/diets";
import { useAppParams, useAppTranslation, useMealClientContext } from "@hooks";
import { useFetchDietsProductsReplacementQuery } from "@hooks/queries/diets";
import { getTranslation } from "@utils/translations";

import { CategoryTitle } from "./CategoryTitle";
import { NoProductsEmptyState } from "./EmptyState";
import { ExchangedProductAccordion } from "./ExchangedProductAccordion";
import { groupProductsByCategory } from "./productsListUtils";
import { ChipFilters } from "./ChipFilters";
import { getClientContextSummary } from "./productListsUtils";
import { usePostPatientProductsContextMutation } from "@hooks/queries";

interface ExchangedListProps {
  group: boolean;
  query?: string;
  setCount: (count: number) => void;
  changeTab: () => void;
}

export const ExchangedList = ({
  group,
  query,
  setCount,
  changeTab,
}: ExchangedListProps) => {
  const { currentLanguage, t } = useAppTranslation();
  const { dietId } = useAppParams();
  const { data, isLoading } = useFetchDietsProductsReplacementQuery(dietId, {
    onSuccess: d => setCount(d?.data.length ?? 0),
  });
  const foodIds = data?.flatMap(({ from, to }) => [from.id, to.id]) ?? [];
  const { mutate: fetchProducts } = usePostPatientProductsContextMutation();
  const { productsClientContextMap } = useMealClientContext();
  const clientContextSummary = getClientContextSummary(
    foodIds,
    productsClientContextMap,
  );
  const [filterList, setFilterList] = useState<number[]>([]);

  const filteredProducts = useMemo(
    () =>
      data
        ?.filter(({ from, to, mainProductCategory }) => {
          const queryLowerCase = query?.toLowerCase() ?? "";
          const queryInPrev = getTranslation(from.translations, currentLanguage)
            .toLowerCase()
            .includes(queryLowerCase);
          const queryInCurr = getTranslation(to.translations, currentLanguage)
            .toLowerCase()
            .includes(queryLowerCase);
          const queryInCategory =
            group &&
            mainProductCategory.description
              .toLowerCase()
              .includes(queryLowerCase);

          return queryInPrev || queryInCurr || queryInCategory;
        })
        .filter(({ from, to }) =>
          filterList.length
            ? filterList.includes(from.id) || filterList.includes(to.id)
            : true,
        ),
    [data, group, query, currentLanguage],
  );

  const groupedProducts = useMemo(
    () => groupProductsByCategory(filteredProducts ?? []),
    [filteredProducts],
  );

  const productsSorted = useMemo(
    () =>
      filteredProducts?.sort((a, b) => {
        const aName = getTranslation(a.to.translations, currentLanguage);
        const bName = getTranslation(b.to.translations, currentLanguage);
        return aName.localeCompare(bName);
      }) ?? [],
    [filteredProducts, currentLanguage],
  );

  const sortProducts = (a: ProductExchangeDto, b: ProductExchangeDto) => {
    const aName = getTranslation(a.to.translations, currentLanguage);
    const bName = getTranslation(b.to.translations, currentLanguage);
    return aName.localeCompare(bName);
  };

  useEffect(() => {
    if (foodIds.length) fetchProducts({ payload: foodIds });
  }, [foodIds.length]);

  if (isLoading)
    return (
      <div className="grid place-items-center">
        <CircularProgress />
      </div>
    );

  if (!data?.length)
    return (
      <NoProductsEmptyState
        infoText={t("diet.empty_state.exchange_info")}
        onClick={changeTab}
      />
    );

  if (group)
    return (
      <>
        <ChipFilters
          summary={clientContextSummary}
          setFilterList={setFilterList}
        />
        {Object.entries(groupedProducts).map(([categoryId, data]) => {
          const { categoryNamePl, categoryNameEn, products } = data;
          const mainCategoryId = data.products[0].mainProductCategory.id;

          return (
            <div key={categoryId} className="grid gap-[16px]">
              <CategoryTitle
                id={mainCategoryId}
                nameEn={categoryNameEn}
                namePl={categoryNamePl}
              />

              <ul className="grid gap-[8px]">
                {products.sort(sortProducts).map(({ from, to }, idx) => (
                  <ExchangedProductAccordion
                    key={idx}
                    currentName={getTranslation(
                      to.translations,
                      currentLanguage,
                    )}
                    previousName={getTranslation(
                      from.translations,
                      currentLanguage,
                    )}
                    from={from.id}
                    to={to.id}
                  />
                ))}
              </ul>
            </div>
          );
        })}
      </>
    );

  return (
    <>
      <ChipFilters
        summary={clientContextSummary}
        setFilterList={setFilterList}
      />
      {productsSorted?.map(({ from, to }, idx) => (
        <ExchangedProductAccordion
          key={idx}
          currentName={getTranslation(to.translations, currentLanguage)}
          previousName={getTranslation(from.translations, currentLanguage)}
          from={from.id}
          to={to.id}
        />
      ))}
    </>
  );
};
