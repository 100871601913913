import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";

import * as yup from "yup";
import _ from "lodash";

import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useAppTranslation } from "@hooks";
import {
  FetchFoodDetailsResponse,
  NutrientDto,
  ProductNutrientDto,
} from "@client";
import { CategoryNutrientsResponse } from "@typeDefinitions/responses/dictionaries";
import { StoreFoodRequest } from "@typeDefinitions/requests";
import { GRAMS_ID, roundNutrientDigitsById } from "@utils";
import {
  PRODUCT_CATEGORY_PLACEHOLDER,
  PRODUCT_NAME_PLACEHOLDER,
} from "@views/dietician/product-views/ProductNew";

export const useProductForm = (initValues?: DietitianProductFormInput) => {
  const { t } = useAppTranslation();

  const measureSchema = yup.object().shape({
    remoteId: yup.string().required(t("common.required_field")),
    value: yup.string().required(t("common.required_field")),
  });

  const newProductFormSchema = yup.object().shape({
    namePl: yup
      .string()
      .required(t("common.required_field"))
      .max(250, t("errors.too_long")),
    nameEn: yup.string().max(250, t("errors.too_long")).nullable(),
    producer: yup.string().max(250, t("errors.too_long")).nullable(),
    categoryId: yup.string().required(t("common.required_field")),
    tags: yup.array().of(yup.string()).required(t("common.required_field")),
    measures: yup
      .array()
      .of(measureSchema)
      .min(1, t("products.filters.measures_min"))
      .required(),
    categories: yup.lazy(value =>
      yup
        .object()
        .shape(
          _.mapValues(value, nutrients =>
            yup
              .object()
              .shape(_.mapValues(nutrients, () => yup.string()))
              .required(t("common.required_field")),
          ),
        )
        .required(t("common.required_field")),
    ),
  });

  const form = useForm<DietitianProductFormInput>({
    resolver: yupResolver(newProductFormSchema),
    defaultValues: productFormInputDefault,
    reValidateMode: "onChange",
  });

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (initValues && !initialized) {
      setInitialized(true);
      form.reset(initValues, { keepDirtyValues: true });
    }
  }, [initValues, initialized]);

  return form;
};

//to be removed
export interface ProductFormInput {
  isPl: boolean;
  reviewed: boolean;
  description: string;
  descriptionEn: string;
  producer: string;
  foodGroupId?: number;
  tags: number[];
  measures: {
    measureId?: number;
    grams: number;
  }[];
  nutrients: {
    remoteId: number;
    value: number;
  }[];
  version?: number;
}

export interface DietitianProductFormInput {
  namePl: string;
  nameEn: string;
  producer: string;
  shops: string[];
  categoryId: string | null;
  tags: string[];
  measures: MeasureIf[];
  categories: NutrientCategories;
  plOnly: boolean;
  reviewed: boolean;
  barcode: string | null;
}

interface NutrientCategories {
  [categoryId: string]: NutrientsIf;
}

interface MeasureIf {
  remoteId: string | null;
  value: string;
}

interface NutrientsIf {
  [nutrientId: string]: string;
}

export const productFormInputDefault: DietitianProductFormInput = {
  namePl: "",
  nameEn: "",
  producer: "",
  shops: [],
  categoryId: null,
  tags: [],
  measures: [{ remoteId: GRAMS_ID.toString(), value: "1" }],
  categories: {},
  reviewed: false,
  plOnly: false,
  barcode: null,
};

export const mapNutrientCategoriesForm = (
  categories: CategoryNutrientsResponse[],
  nutrients: ProductNutrientDto[],
): NutrientCategories => {
  return Object.fromEntries(
    categories.map(category => [
      category.id,
      Object.fromEntries(
        category.nutrients.map(nutrient => {
          const value = nutrients.find(({ id }) => id === nutrient.id)?.value;
          return [
            nutrient.id,
            roundNutrientDigitsById(value, category.id, nutrient.id) || "",
          ];
        }),
      ),
    ]),
  );
};

const mapNutrientsRequest = (categories: NutrientCategories): NutrientDto[] => {
  return Object.entries(categories).flatMap(([, nutrients]) =>
    Object.entries(nutrients).map(([nutrientId, value]) => ({
      id: Number(nutrientId),
      value: Number(value),
    })),
  );
};

export const mapProductForm = (
  product: FetchFoodDetailsResponse | undefined,
  categories: CategoryNutrientsResponse[] | undefined,
): DietitianProductFormInput | undefined => {
  if (!product || !categories) return;

  const isPlaceholder =
    product.foodGroup.id === PRODUCT_CATEGORY_PLACEHOLDER &&
    product.descriptionPl === PRODUCT_NAME_PLACEHOLDER;

  return {
    namePl: isPlaceholder ? "" : product.descriptionPl ?? "",
    nameEn: product.description ?? "",
    tags: product.tags.map(tag => tag.id.toString()),
    categoryId: isPlaceholder ? null : product.foodGroup.id.toString(),
    producer: product.producer ?? "",
    shops: product.shops.map(({ id }) => id.toString()),
    measures: product.measures.map(measure => ({
      remoteId: measure.id.toString(),
      value: measure.grams.toString(),
    })),
    categories: mapNutrientCategoriesForm(categories, product.nutrients),
    reviewed: product.reviewed,
    plOnly: product.plOnly,
    barcode: product.barcode,
  };
};

export const mapProductRequest = (
  inputValues: DietitianProductFormInput,
  product: { version: number },
): StoreFoodRequest => {
  const {
    namePl,
    nameEn,
    tags,
    producer,
    categoryId,
    measures,
    reviewed,
    plOnly,
    categories,
    shops,
    barcode,
  } = inputValues;
  return {
    description_pl: namePl,
    description: nameEn,
    version: product.version,
    tags: tags.map(tag => Number(tag)),
    producer: producer ?? undefined,
    food_group_id: Number(categoryId),
    measures: measures.map((measure, index) => ({
      id: Number(measure.remoteId),
      grams: Number(measure.value),
      sort_order: index + 1,
    })),
    reviewed: reviewed,
    plOnly: plOnly,
    nutrients: mapNutrientsRequest(categories),
    shops: shops.map(shop => Number(shop)),
    barcode,
  };
};
