import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const CompleteCircleOutlined = (props: SvgIconProps) => (
  <SvgIcon width="24" height="25" viewBox="0 0 24 25" {...props}>
    <path
      d="M11.6445 14.7077C11.3438 15.0085 10.8789 15.0085 10.5781 14.7077L8.82812 12.9577C8.52734 12.6569 8.52734 12.1921 8.82812 11.8913C9.12891 11.5905 9.59375 11.5905 9.89453 11.8913L11.125 13.0944L14.0781 10.1413C14.3789 9.84052 14.8438 9.84052 15.1445 10.1413C15.4453 10.4421 15.4453 10.9069 15.1445 11.2077L11.6445 14.7077ZM19 12.4108C19 16.2936 15.8555 19.4108 12 19.4108C8.11719 19.4108 5 16.2936 5 12.4108C5 8.55536 8.11719 5.41083 12 5.41083C15.8555 5.41083 19 8.55536 19 12.4108ZM12 6.72333C8.85547 6.72333 6.3125 9.29364 6.3125 12.4108C6.3125 15.5554 8.85547 18.0983 12 18.0983C15.1172 18.0983 17.6875 15.5554 17.6875 12.4108C17.6875 9.29364 15.1172 6.72333 12 6.72333Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
