import { memo } from "react";

export const PieChart = memo(
  ({ scale, value, maxValue, unit, colors, name }: PieChartProps) => {
    const percent = (value / maxValue) * 100;
    const c = 21;
    const r = 16;
    const strokeWidth = 3;
    const strokeDashoffset = 25;
    const showExclamation = value > 1.05 * maxValue || value < 0.95 * maxValue;

    return (
      <div className="flex flex-col items-center">
        <div className="relative">
          <div className="absolute flex flex-col items-center justify-center w-full h-full z-10">
            <span className="text-2xl">
              {value}
              <span className="text-lg">/</span>
            </span>
            <span className="text-xl">{maxValue}</span>
            <span className="text-sm">{unit}</span>
          </div>
          <svg
            width={scale}
            height={scale}
            viewBox={`0 0 42 42`}
            className="relative"
          >
            <circle cx="21" cy="21" r="15.91549430918952" fill="#fff" />
            <circle
              cx={`${c}`}
              cy={`${c}`}
              r={`${r}`}
              fill="transparent"
              stroke={colors.shadow}
              strokeWidth={`${strokeWidth}`}
            />
            <circle
              cx={`${c}`}
              cy={`${c}`}
              r={`${r}`}
              fill="transparent"
              stroke={colors.fill}
              strokeWidth={`${strokeWidth}`}
              strokeDasharray={`${percent} ${100 - percent}`}
              strokeLinecap="round"
              strokeDashoffset={`${strokeDashoffset}`}
            />
          </svg>
        </div>
        <p className="w-full flex justify-center">
          {name}
          {showExclamation && <span className="text-2xl text-red ml-1">!</span>}
        </p>
      </div>
    );
  },
);

interface PieChartProps {
  name: string;
  value: number;
  maxValue: number;
  unit: string;
  scale: number;
  colors: Colors;
}

interface Colors {
  fill: string;
  shadow: string;
}
