import { ProgramSearchPreview } from "@client/program/preview/fetchProgramPreview";
import { CustomTab } from "@components/ProgramSearchPreview/Components/ProgramComponentsTabs/ProgramComponentsTabs.styled";
import { useAppTranslation } from "@hooks";
import { Tabs } from "@mui/material";

type ComponentsProgramPatientPreviewType = {
  setValue: (value: string) => void;
  value: string;
};
const ProgramComponentsTabs = ({
  setValue,
  value,
}: ComponentsProgramPatientPreviewType) => {
  const { t } = useAppTranslation();

  const handleChange = (_: any, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Tabs onChange={handleChange} value={value}>
      <CustomTab label={t("program_preview.tabs.day_types")} value="1" />
      <CustomTab
        label={t("program_preview.tabs.educational_materials")}
        value="2"
      />
      <CustomTab label={t("program_preview.tabs.system_sections")} value="3" />
      <CustomTab label={t("program_preview.tabs.tags")} value="4" />
    </Tabs>
  );
};

export default ProgramComponentsTabs;
