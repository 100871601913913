import { RemovedProductDto, fetchDietProductsRemoved } from "@client/diets";
import { useQuery } from "@tanstack/react-query";
import { QueryOptionsTyped } from "../types";
import { ApiResponse } from "@typeDefinitions";

export const fetchDietProductsRemovedQueryKey =
  "fetchDietProductsRemovedQueryKey";

export const useFetchDietProductsRemovedQuery = (
  dietId: string,
  options?: QueryOptionsTyped<ApiResponse<RemovedProductDto[]>>,
) => {
  const { data, ...rest } = useQuery(
    [fetchDietProductsRemovedQueryKey, dietId],
    () => fetchDietProductsRemoved(dietId),
    options,
  );

  return {
    data: data?.data,
    ...rest,
  };
};
