import {
  FetchClientBalanceResponse,
  UpdateClientBalanceRequest,
} from "@client";
import { ApiResponse } from "@typeDefinitions";

export const clientBalanceOptimisticUpdate = (
  payload: UpdateClientBalanceRequest,
): ApiResponse<FetchClientBalanceResponse> | undefined => {
  return {
    data: {
      type: payload.type,
      days: payload.days.map(day => ({
        id: day.id ?? 0,
        type: day.type,
        frequency: day.frequency,
        translations: day.translations,
        suggestedEnergy: day.suggestedEnergy,
        energyDemand: day.energyDemand,
      })),
    },
  };
};
