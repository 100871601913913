import {
  ExclamationMarkInCircle,
  ExclamationMarkInTriangle,
} from "@assets/icons";
import { ErrorTextWrapper, HelperText } from "./ErrorText.styled";
import { useTheme } from "@mui/material";
import { ReactNode } from "react";

interface ErrorTextProps {
  content?: string;
  triangle?: boolean;
  extra?: ReactNode;
}

export const ErrorText = ({ content, triangle, extra }: ErrorTextProps) => {
  const { palette } = useTheme();

  return (
    <ErrorTextWrapper largeText={triangle} hide={!content}>
      {triangle ? (
        <ExclamationMarkInTriangle
          fill={palette.error.main}
          size="w-[18px] h-[14px]"
        />
      ) : (
        <ExclamationMarkInCircle
          fill={palette.error.main}
          size="w-[13px] h-[13px]"
        />
      )}
      <HelperText>
        {content}
        {extra}
      </HelperText>
    </ErrorTextWrapper>
  );
};
