import { useEffect } from "react";
import { useForm } from "react-hook-form";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useAppTranslation } from "@hooks/useAppTranslation";
import {
  ClinicBillingDataDto,
  UpdateClinicBillingDataRequest,
} from "@client/settings";

export const useBillingDataForm = (defaultValues?: BillingDataFormInput) => {
  const { t } = useAppTranslation();
  const billingDataSchema = yup.object().shape({
    name: yup.string().nullable(),
    vatId: yup.string().nullable(),
    email: yup.string().email(t("common.email_invalid")).nullable(),
    prefix: yup.string().nullable(),
    phone: yup.string().nullable(),
    street: yup.string().nullable(),
    streetNumber: yup.string().nullable(),
    houseNumber: yup.string().nullable(),
    postCode: yup.string().nullable(),
    city: yup.string().nullable(),
  });
  const form = useForm<BillingDataFormInput>({
    defaultValues: {
      prefix: "+48",
    },
    resolver: yupResolver(billingDataSchema),
  });

  useEffect(() => {
    if (defaultValues) form.reset(defaultValues);
  }, [defaultValues]);

  return form;
};

export interface BillingDataFormInput {
  name: string;
  vatId: string;
  email: string;
  prefix: string;
  phone: string;
  street: string;
  streetNumber: string;
  houseNumber: string;
  postCode: string;
  city: string;
}

export const mapBillingDataForm = (
  data?: ClinicBillingDataDto,
): BillingDataFormInput => {
  return {
    name: data?.name ?? "",
    vatId: data?.vatId ?? "",
    email: data?.email ?? "",
    prefix: data?.phone.prefix ?? "+48",
    phone: data?.phone.number ?? "",
    street: data?.address.street ?? "",
    streetNumber: data?.address.streetNumber ?? "",
    houseNumber: data?.address.houseNumber ?? "",
    postCode: data?.address.postCode ?? "",
    city: data?.address.city ?? "",
  };
};

export const mapBillingDataRequest = (
  data: BillingDataFormInput,
): UpdateClinicBillingDataRequest => {
  return {
    name: data.name ?? null,
    email: data.email ?? null,
    vatId: data.vatId ?? null,
    phone: data.phone
      ? {
          prefix: data.prefix,
          number: data.phone,
        }
      : {
          prefix: null,
          number: null,
        },
    address: {
      street: data.street ?? null,
      streetNumber: data.streetNumber ?? null,
      houseNumber: data.houseNumber ?? null,
      city: data.city ?? null,
      postCode: data.postCode ?? null,
    },
  };
};
