import { useAppTranslation } from "@hooks";
import { ActionsColumnCell } from "../ActionsColumnCell";
import { NameColumnCell } from "../NameColumnCell";
import { TagsColumnCell } from "../TagsColumnCell";
import { SingleColumnCellStyled } from "./SingleColumnCell.styled";
import { DietRowIf } from "../DietsDataGrid";

interface SingleColumnCellProps extends DietRowIf {
  onSelect: () => void;
}

export const SingleColumnCell = ({
  id,
  actions,
  isFavorite,
  namePl,
  nameEn,
  nutrients,
  tags,
  onSelect,
  mealsCount,
}: SingleColumnCellProps) => {
  const { isPolishChosen } = useAppTranslation();

  return (
    <SingleColumnCellStyled>
      <div className="flex justify-between">
        <NameColumnCell
          name={isPolishChosen ? namePl : nameEn}
          nutrients={nutrients}
          onSelect={onSelect}
          mealsCount={mealsCount}
        />

        <div className="pr-[8px] -mt-[6px]">
          <ActionsColumnCell
            id={id}
            isFavorite={isFavorite}
            actions={actions}
            namePl={namePl}
            nameEn={nameEn}
          />
        </div>
      </div>

      <div className="pl-[16px]">
        <TagsColumnCell tags={tags} />
      </div>
    </SingleColumnCellStyled>
  );
};
