import { useCallback } from "react";

const useGoogleTagManagerScript = () => {
  const handleHeadScript = useCallback(() => {
    const startComment = document.createComment("Google Tag Manager");

    const script = document.createElement("script");
    script.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-THMSVKW');`;

    const endComment = document.createComment("End Google Tag Manager");

    document.head.appendChild(startComment);
    document.head.appendChild(script);
    document.head.appendChild(endComment);

    return () => {
      document.head.removeChild(startComment);
      document.head.removeChild(script);
      document.head.removeChild(endComment);
    };
  }, []);

  return { handleHeadScript };
};

export default useGoogleTagManagerScript;
