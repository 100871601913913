import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import pl from "@locales/pl.json";
import en from "@locales/en.json";
import common from "@locales/common";
import monitoringTranslations from "@locales/monitoring";
import componentsTranslations from "@locales/components";
import taskTranslations from "@locales/tasks";
import programPreviewTranslations from "@locales/program_preview";
import patientTranslations from "@locales/patient";
import integration from "@locales/integrations";
import empty_states from "@locales/empty_states";

// i18next
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     supportedLngs: ["pl", "en"],
//     resources: {
//       pl: {
//         translation: pl,
//       },
//       en: {
//         translation: en,
//       },
//     },
//     fallbackLng: "pl",
//     keySeparator: ".",
//     debug: false,
//     interpolation: {
//       escapeValue: false,
//     },
//   });

i18next.use(initReactI18next).init({
  resources: {
    pl: {
      translation: {
        ...pl,
        common: common.pl,
        monitoring2: monitoringTranslations.pl,
        components: componentsTranslations.pl,
        tasks: taskTranslations.pl,
        program_preview: programPreviewTranslations.pl,
        patient: patientTranslations.pl,
        integrations: integration.pl,
        empty_states: empty_states.pl,
      },
    },
    en: {
      translation: {
        ...en,
        common: common.en,
        monitoring2: monitoringTranslations.en,
        components: componentsTranslations.en,
        tasks: taskTranslations.en,
        program_preview: programPreviewTranslations.en,
        patient: patientTranslations.en,
        integrations: integration.en,
        empty_states: empty_states.en,
      },
    },
  },
  lng: "pl",
  fallbackLng: "pl",
  keySeparator: ".",
  debug: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
