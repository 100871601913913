import { ChartEmpty, ChartFull } from "./VerticalBarChart.styled";

interface VerticalBarChartProps {
  percentage: number | null;
  backgroundColor: string;
}

export const VerticalBarChart = ({
  percentage,
  backgroundColor,
}: VerticalBarChartProps) => {
  return (
    <div>
      <ChartEmpty>
        <ChartFull
          height={percentage ? `${Math.max(16, percentage)}%` : "0%"}
          background={backgroundColor}
        />
      </ChartEmpty>
    </div>
  );
};
