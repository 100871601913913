import { useMemo, useState } from "react";

import { ClickAwayListener } from "@mui/material";
import { isNumber } from "lodash";

import { RecipesFiltersFormInput } from "@components/RecipesFiltersNew";
import { TabIf } from "@components/TabSwitch";

import { ItemsListCallback, useItemsContext } from "../ItemsContext";
import { StyledDrawer } from "./AddElementSidePanel.styled";
import { DrawerContent } from "./DrawerContent";
import { useAppParams, useAppTranslation, useModalState } from "@hooks";
import { RecipeEditDrawer } from "../../RecipeDrawer/RecipeEditDrawer";

export interface AddElementSidePanelProps {
  mealName: string | null;
  mealId: number | null;
  onClose: (expand?: boolean) => void;
  defaultFilters: RecipesFiltersFormInput | undefined;
  dietId?: string;
  onSubmit?: ItemsListCallback;
  loading?: boolean;
}

export const AddElementSidePanel = ({
  mealName,
  mealId,
  onClose,
  defaultFilters,
  dietId: controlledDietId,
  onSubmit,
  loading,
}: AddElementSidePanelProps) => {
  const { t } = useAppTranslation();
  const [tab, setTab] = useState(Tabs.RECIPES);
  const { clearValues } = useItemsContext();
  const [openNewRecipe, onOpenNewRecipe, onCloseNewRecipe] = useModalState();
  const { dietId } = useAppParams();
  const ensuredDietId = controlledDietId ?? dietId;

  const [total, setTotal] = useState<TotalIf>({
    recipes: undefined,
    products: undefined,
    collections: undefined,
  });

  const tabs = useMemo<TabIf[]>(
    () => [
      {
        id: Tabs.COLLECTIONS,
        label:
          tab === Tabs.COLLECTIONS && isNumber(total.collections)
            ? `${t("common.collections")} (${total.collections})`
            : t("common.collections"),
      },
      {
        id: Tabs.RECIPES,
        label:
          tab === Tabs.RECIPES && isNumber(total.recipes)
            ? `${t("common.recipes")} (${total.recipes})`
            : t("common.recipes"),
      },
      {
        id: Tabs.PRODUCTS,
        label:
          tab === Tabs.PRODUCTS && isNumber(total.products)
            ? `${t("common.products")} (${total.products})`
            : t("common.products"),
      },
    ],
    [tab, total, t],
  );

  const handleClose = () => {
    onClose();
    clearValues();
    setTab(Tabs.RECIPES);
    onCloseNewRecipe();
  };

  const handleSuccess = () => {
    onClose(true);
    clearValues();
    setTab(Tabs.RECIPES);
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <div>
          <StyledDrawer
            open={isNumber(mealId)}
            variant="persistent"
            anchor="right"
            onClose={handleClose}
          >
            {mealId && defaultFilters && (
              <DrawerContent
                dietId={ensuredDietId}
                tab={tab}
                setTab={setTab}
                onClose={handleClose}
                mealId={mealId}
                setTotal={setTotal}
                tabs={tabs}
                defaultFilters={defaultFilters}
                onSuccess={handleSuccess}
                mealName={mealName ?? ""}
                onAddOwnRecipe={onOpenNewRecipe}
                onSubmit={onSubmit}
                loading={loading}
              />
            )}
          </StyledDrawer>
          {mealId && (
            <RecipeEditDrawer
              open={openNewRecipe}
              onClose={onCloseNewRecipe}
              mealId={mealId.toString()}
              recipeIds={null}
            />
          )}
        </div>
      </ClickAwayListener>
    </>
  );
};

export enum Tabs {
  RECIPES = "1",
  PRODUCTS = "2",
  COLLECTIONS = "3",
}

export interface TotalIf {
  recipes: number | undefined;
  products: number | undefined;
  collections: number | undefined;
}
