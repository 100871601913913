import { useMemo, useState } from "react";
import { useAppTranslation } from "@hooks";
import { useFetchBodyMeasurementsQuery } from "@hooks/queries/dictionaries";
import { BodyMeasurementConst } from "@consts/BodyMeasurementConst";
import { TabContext } from "@mui/lab";
import { TabList } from "@components/Alloweat/Tabs/TabList";
import { styled, Tab } from "@mui/material";
import { TabPanel } from "@components/Alloweat/Tabs/TabPanel";
import { SectionLayout } from "@views/dietician/ClientHealth/ClientHealthNew.styled";
import { FetchBodyMeasurementsDictionaryResponse } from "@client/dictionaries";
import { getBodyMeasurementsByIds } from "@utils/dictionaries/bodyMeasurementsDictionaryUtils";
import BodyMeasurementField from "@components/Client/FormBodyMeasurementsModal/BodyMeasurementField";

const BodyMeasurementsFieldsTabs = () => {
  const [type, setType] = useState<"basic" | "advance" | "circuits">("basic");
  const { t } = useAppTranslation();

  const { data: bodyMeasurements } = useFetchBodyMeasurementsQuery();

  const basicMeasurements = useMemo(
    () => getMeasurementsByIdsWrapper(bodyMeasurements, BasicMeasurementsTypes),
    [bodyMeasurements],
  );

  const advanceMeasurements = useMemo(
    () =>
      getMeasurementsByIdsWrapper(bodyMeasurements, AdvanceMeasurementsTypes),
    [bodyMeasurements],
  );

  const circuitsMeasurements = useMemo(
    () =>
      getMeasurementsByIdsWrapper(bodyMeasurements, CircuitsMeasurementsTypes),
    [bodyMeasurements],
  );

  return (
    <TabContext value={type}>
      <TabList
        onChange={(_, newValue) => setType(newValue)}
        scrollButtons={"auto"}
        variant="scrollable"
      >
        <Tab
          label={t(
            "patient.health_and_goal.body_measurement_tab.section_last_measurement.type_tabs.basic",
          )}
          value="basic"
        />
        <Tab
          label={t(
            "patient.health_and_goal.body_measurement_tab.section_last_measurement.type_tabs.advance",
          )}
          value="advance"
        />
        <Tab
          label={t(
            "patient.health_and_goal.body_measurement_tab.section_last_measurement.type_tabs.circuits",
          )}
          value="circuits"
        />
      </TabList>
      <Wrapper className={"scrollbar-thin"}>
        <TabPanel value="basic">
          <SectionLayout>
            {basicMeasurements.map(measurement => (
              <BodyMeasurementField
                key={measurement.id}
                bodyMeasurement={measurement}
              />
            ))}
          </SectionLayout>
        </TabPanel>
        <TabPanel value="advance">
          <SectionLayout>
            {advanceMeasurements.map(measurement => (
              <BodyMeasurementField
                key={measurement.id}
                bodyMeasurement={measurement}
              />
            ))}
          </SectionLayout>
        </TabPanel>
        <TabPanel value="circuits">
          <SectionLayout>
            {circuitsMeasurements.map(measurement => (
              <BodyMeasurementField
                key={measurement.id}
                bodyMeasurement={measurement}
              />
            ))}
          </SectionLayout>
        </TabPanel>
      </Wrapper>
    </TabContext>
  );
};

const Wrapper = styled("div")`
  height: 305px;
  overflow-y: auto;
`;

const BasicMeasurementsTypes = [
  BodyMeasurementConst.height,
  BodyMeasurementConst.weight,
  BodyMeasurementConst.bodyFatLevel,
];

const AdvanceMeasurementsTypes = [
  BodyMeasurementConst.water,
  BodyMeasurementConst.bodyFatMass,
  BodyMeasurementConst.boneTissueMass,
  BodyMeasurementConst.skeletalMuscleMass,
];

const CircuitsMeasurementsTypes = [
  BodyMeasurementConst.arm,
  BodyMeasurementConst.tightBiceps,
  BodyMeasurementConst.waist,
  BodyMeasurementConst.hip,
  BodyMeasurementConst.thigh,
  BodyMeasurementConst.calf,
  BodyMeasurementConst.chest,
  BodyMeasurementConst.abdominal,
];

const getMeasurementsByIdsWrapper = (
  bodyMeasurements: FetchBodyMeasurementsDictionaryResponse | undefined,
  measurements: number[],
) => {
  if (!bodyMeasurements) {
    return [];
  }

  return getBodyMeasurementsByIds(bodyMeasurements.data, measurements);
};

export default BodyMeasurementsFieldsTabs;
