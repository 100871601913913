import { TranslationDto } from "@client/common";
import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const updateCreatorDiet = async (
  dietId: string,
  payload: UpdateCreatorDietPayload,
  version: string,
) => {
  return await fetchData(
    `/dietitian/creator-2/diets/${dietId}`,
    APIMethods.PUT,
    payload,
    undefined,
    { "Alloweat-Creator-Version": version },
  );
};

export interface UpdateCreatorDietPayload {
  translations: TranslationDto[];
  showMacros: boolean;
}
