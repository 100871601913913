import { Chip } from "@mui/material";

import { IdDto } from "@client";
import { useTagsNew } from "@hooks/useTagsNew";

import { LabelStyled, TagsContainer, TagsTabStyled } from "./TagsTab.styled";
import { useAppTranslation } from "@hooks";
import { useProductShops } from "@hooks/useProductShops";

interface TagsTabProps {
  tags: number[];
  producer?: string | null;
  shops?: IdDto[];
}
export const TagsTab = ({ tags, producer, shops }: TagsTabProps) => {
  const { t } = useAppTranslation();
  const { tagNameDictionary } = useTagsNew();
  const { shopDictionary } = useProductShops();
  return (
    <TagsTabStyled>
      {!!tags.length && (
        <>
          <LabelStyled>{t("product.characteristic")}</LabelStyled>
          <TagsContainer>
            {tags.map(t => (
              <Chip key={t} label={tagNameDictionary.get(t)} color="primary" />
            ))}
          </TagsContainer>
        </>
      )}

      {!!shops?.length && (
        <>
          <LabelStyled>{t("product.shops")}</LabelStyled>
          <TagsContainer>
            {shops.map(({ id }) => (
              <Chip key={id} label={shopDictionary.get(id)} color="primary" />
            ))}
          </TagsContainer>
        </>
      )}

      {producer && (
        <>
          <LabelStyled>{t("product.producer")}</LabelStyled>
          <Chip label={producer} color="primary" />
        </>
      )}
    </TagsTabStyled>
  );
};
