import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const RestoreArrows = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      d="M11.5555 16.4448C12.0472 16.4448 12.4445 16.0475 12.4445 15.5558C12.4445 15.0641 12.0472 14.6669 11.5555 14.6669H8.44417C7.95247 14.6669 7.55521 14.2696 7.55521 13.7779V10.2221H8.44417C8.80253 10.2221 9.12756 10.0054 9.26646 9.67206C9.40536 9.3387 9.32757 8.95811 9.07477 8.70254L7.29686 6.92462C6.94961 6.57737 6.38568 6.57737 6.03843 6.92462L4.26051 8.70254C4.00494 8.95811 3.92993 9.3387 4.06883 9.67206C4.20773 10.0054 4.52998 10.2221 4.89112 10.2221H5.78008V13.7779C5.78008 15.2503 6.97461 16.4448 8.44695 16.4448H11.5555ZM12.4445 7.55523C11.9528 7.55523 11.5555 7.95248 11.5555 8.44418C11.5555 8.93589 11.9528 9.33314 12.4445 9.33314H15.5558C16.0475 9.33314 16.4448 9.73039 16.4448 10.2221V13.7779H15.5558C15.1975 13.7779 14.8724 13.9946 14.7335 14.328C14.5946 14.6613 14.6724 15.0419 14.9252 15.2975L16.7031 17.0754C17.0504 17.4227 17.6143 17.4227 17.9616 17.0754L19.7395 15.2975C19.9951 15.0419 20.0701 14.6613 19.9312 14.328C19.7923 13.9946 19.47 13.7779 19.1089 13.7779H18.2199V10.2221C18.2199 8.74976 17.0254 7.55523 15.5531 7.55523H12.4445Z"
      fill={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
