import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const postFoodImage = async (
  foodId: string,
  formData: FormData,
  signal?: AbortSignal,
) => {
  const response = await fetchData(
    `/dietitian/food/${foodId}/image`,
    APIMethods.POST,
    formData,
    undefined,
    { "Content-Type": "multipart/form-data" },
    undefined,
    signal,
  );
};
