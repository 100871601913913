import { FC, ReactNode } from "react";
import { LabelFieldStyled } from "@Pdf/components/LabelField/LabelFieldStyled";

type PropsType = {
  label: string;
  children: ReactNode;
};

const LabelField: FC<PropsType> = ({ label, children }) => {
  return (
    <div className={"flex flex-col"}>
      <LabelFieldStyled>{label}</LabelFieldStyled>
      {children}
    </div>
  );
};

export default LabelField;
