import { LangDto } from "@client/common";
import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const updateSurvey = async (
  surveyId: string,
  payload: UpdateSurveyRequest,
) => {
  await fetchData(`/dietitian/surveys/${surveyId}`, APIMethods.PUT, payload);
};

export interface UpdateSurveyRequest {
  langId: LangDto;
  name: string;
  introduction: IntroductionDto;
  reviewed: boolean;
}

interface IntroductionDto {
  on: boolean;
  title: string | null;
  description: string | null;
}
