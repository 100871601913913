import { useMemo, useState } from "react";

import { Switch, TextField } from "@mui/material";

import { ModalWrapper } from "@components/ModalWrapperNew";
import { useAppTranslation, useModalState } from "@hooks";
import {
  useFetchGoogleCalendarListQuery,
  useFetchOauthGoogleDeauthorizeMutation,
  useFetchOauthGoogleLinkMutation,
  usePostOauthGoogleSetCalendarMutation,
  usePostOauthGoogleSetPushCalendarMutation,
} from "@hooks/queries/oauth";

import { OuterLabel } from "@components/OuterLabel";
import { toast } from "react-toastify";
import {
  AutocompleteStyled,
  ButtonStyled,
  CardStyled,
  DividerStyled,
  Text,
  TextBold,
  Title,
} from "./GoogleCard.styled";
import { LearnMoreModal } from "./LearnMoreModal";

export const GoogleCard = () => {
  const { t } = useAppTranslation();
  const [value, setValue] = useState(false);
  const [openLearnMore, onOpenLearnMore, onCloseLearnMore] = useModalState();
  const [openIntegrate, onOpenIntegrate, onCloseIntegrate] = useModalState();
  const [openDeintegrate, onOpenDeintegrate, onCloseDeintegrate] =
    useModalState();

  const { data } = useFetchGoogleCalendarListQuery({
    onSuccess: data => {
      setValue(!!data?.data.length);
    },
  });
  const [integratedEmail] = data ?? [];
  const options = useMemo(
    () =>
      data?.map(({ id, name, pushActive }) => ({
        id,
        label: name,
        pushActive,
      })) ?? [],
    [data],
  );
  const selectedPushCalendar = options.find(({ pushActive }) => !!pushActive);

  const { mutate: toggleCalendar } = usePostOauthGoogleSetCalendarMutation({
    onSuccess: () => {
      toast.success(t("integrations.calendar.calendar_sync_success"));
    },
    onError: () => {
      toast.error(t("integrations.calendar.calendar_sync_error"));
    },
  });

  const { mutate: mutatePushCalendar } =
    usePostOauthGoogleSetPushCalendarMutation({
      onSuccess: () => {
        toast.success(t("integrations.calendar.calendar_sync_success"));
      },
      onError: () => {
        toast.error(t("integrations.calendar.calendar_sync_error"));
      },
    });

  const { mutate: fetchLink, isLoading: isFetchingLink } =
    useFetchOauthGoogleLinkMutation({
      onSuccess: data => {
        if (!data) return;
        const { link } = data;

        if (link) window.open(link, "_blank");
      },
    });
  const { mutate: deauthorize, isLoading: isDeauthorizing } =
    useFetchOauthGoogleDeauthorizeMutation({
      onSuccess: () => {
        toast.success(
          t("integrations.confirm_deintegration.deintegration_success"),
        );
        onCloseDeintegrate();
      },
      onError: () => {
        toast.error(
          t("integrations.confirm_deintegration.deintegration_error"),
        );
      },
    });

  const toogleIntegrate = (checked: boolean) => {
    checked ? onOpenIntegrate() : onOpenDeintegrate();
  };

  return (
    <>
      <CardStyled>
        <div className="flex justify-between">
          <img src="/static/googleCalendar/logo.png" width={48} height={48} />

          <Switch
            checked={value}
            onChange={(e, checked) => toogleIntegrate(checked)}
            disabled={isDeauthorizing}
          />
        </div>

        <Title>Google Calendar</Title>

        {!value && (
          <Text>
            {t("integrations.company_settings.add_events_description")}
          </Text>
        )}

        {value && (
          <div className="flex flex-col gap-[4px]">
            <Text>{t("integrations.calendar.enabled_integration")}</Text>
            <TextBold>{integratedEmail?.name}</TextBold>
          </div>
        )}

        <DividerStyled />

        {!value && (
          <div className="flex justify-start -ml-[8px]">
            <ButtonStyled onClick={onOpenLearnMore}>
              {t("integrations.company_settings.learn_more")}
            </ButtonStyled>
          </div>
        )}

        {value && (
          <div className="flex flex-col gap-[12px]">
            <Text>{t("integrations.calendar.select_calendar_desc")}</Text>

            <div className="flex flex-col gap-[8px]">
              {data?.map(({ active, id, name }) => (
                <div key={id} className="flex gap-[8px] items-center">
                  <Switch
                    checked={Boolean(active)}
                    onChange={() => toggleCalendar(id)}
                  />
                  {name}
                </div>
              ))}
            </div>
          </div>
        )}

        {value && (
          <>
            <DividerStyled />

            <div className="flex flex-col gap-[12px]">
              <Text>
                {t("integrations.calendar.select_push_calendar_desc")}
              </Text>

              <OuterLabel label={t("integrations.calendar.select_calendar")}>
                <AutocompleteStyled
                  value={selectedPushCalendar}
                  options={options}
                  renderInput={params => <TextField {...params} />}
                  size="small"
                  disableClearable
                  onChange={(e, { id }) => mutatePushCalendar(id)}
                />
              </OuterLabel>
            </div>
          </>
        )}
      </CardStyled>
      <ModalWrapper
        open={openIntegrate}
        onClose={onCloseIntegrate}
        onSubmit={fetchLink}
        loading={isFetchingLink}
        title={t("integrations.confirm_integration.title")}
        confirmText={t("integrations.confirm_integration.begin")}
        children={t("integrations.confirm_integration.question")}
      />
      <ModalWrapper
        open={openDeintegrate}
        onClose={onCloseDeintegrate}
        onSubmit={deauthorize}
        loading={isDeauthorizing}
        title={t(
          "integrations.confirm_deintegration.disable_calendar_integration",
        )}
        confirmText={t(
          "integrations.confirm_deintegration.disable_integration",
        )}
        children={t(
          "integrations.confirm_deintegration.disable_calendar_integration_desc",
        )}
        color="error"
      />
      <LearnMoreModal open={openLearnMore} onClose={onCloseLearnMore} />
    </>
  );
};
