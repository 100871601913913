import { ChangeEvent, useEffect } from "react";
import { useController } from "react-hook-form";
import { omit, round } from "lodash";
import { TextField } from "@mui/material";
import dayjs from "dayjs";

import { BalanceValueMethod, formulaGroup, FORMULAS } from "@utils/balance";
import { useAppTranslation } from "@hooks";

import { CaloricNeedsFormInputs } from "../useCaloricNeedsForm";
import { usePatientForBalance } from "./usePatientForBalance";
import { InputSuffixIconButton } from "./InputSuffixIconButton";
import {
  calculateLbm,
  calculateSpm,
} from "@views/dietician/PatientEnergyBalance";

import { decimalInputFactory } from "@utils/inputs";

import {
  AutocompleteStyled,
  FlexRow,
  InputsWrapper,
  StyledText,
} from "../CaloricNeedsForm.styled";
import { SearchNoResults } from "@views/emptyStates/SearchNoResults";

const decimalInput = decimalInputFactory(0) as any;

export const SpmFormInput = () => {
  const { t } = useAppTranslation();

  const {
    field: {
      value: { value, formula, method },
      onChange,
    },
  } = useController<CaloricNeedsFormInputs, "spm">({ name: "spm" });

  const { client, patientDataFilled } = usePatientForBalance();

  const calculateValue = (formulaId: number | null) => {
    if (client && patientDataFilled && formulaId != null) {
      const {
        profile: { weight, bodyFat, sex, birthDate, height },
      } = client;

      const clientLbm = calculateLbm(weight, bodyFat);

      return round(
        calculateSpm(
          formulaId,
          sex ?? undefined,
          weight ?? undefined,
          dayjs(dayjs()).diff(birthDate ?? dayjs(), "years"),
          height ?? undefined,
          clientLbm,
        ) ?? 0,
      );
    } else {
      return value;
    }
  };

  const handleChangeValue = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => onChange({ value: Number(e.target.value), formula, method });

  const handleChangeMethod = () => {
    const newMethod =
      !patientDataFilled || method === BalanceValueMethod.AUTO
        ? BalanceValueMethod.MANUAL
        : BalanceValueMethod.AUTO;

    onChange({ value: calculateValue(formula), formula, method: newMethod });
  };

  const handleChangeFormula = (id: number) => {
    if (method === BalanceValueMethod.AUTO) {
      onChange({ value: calculateValue(id), formula: id, method });
    } else {
      onChange({ value, formula: id, method });
    }
  };

  const mappedGroupNameFormulas = FORMULAS.map(formula => ({
    ...formula,
    group:
      formula.group === formulaGroup.BW
        ? t("balance.formula_bw")
        : formula.group === formulaGroup.LBM
        ? t("balance.formula_lbm")
        : t("balance.formula_age"),
  }));

  useEffect(() => {
    const isDefaultValues = value === 0 && method === BalanceValueMethod.MANUAL;
    const isAutoMethod = method === BalanceValueMethod.AUTO;

    if (patientDataFilled && isAutoMethod) {
      onChange({
        value: calculateValue(formula),
        formula,
        method: BalanceValueMethod.AUTO,
      });
    } else if (patientDataFilled && isDefaultValues) {
      handleChangeMethod();
    }
  }, [patientDataFilled, client?.profile, value, method]);

  return (
    <FlexRow>
      <StyledText variant="body2">{t("balance.rmr")}</StyledText>
      <InputsWrapper>
        <InputSuffixIconButton
          suffix="kcal"
          method={method}
          onIconClick={handleChangeMethod}
          disabled={method === BalanceValueMethod.AUTO}
          iconDisabled={
            !patientDataFilled && method === BalanceValueMethod.MANUAL
          }
          value={value ?? ""}
          onChange={handleChangeValue}
          inputComponent={decimalInput}
        />
        <AutocompleteStyled
          noOptionsText={<SearchNoResults />}
          value={FORMULAS.find(f => f.id === formula) ?? null}
          disabled={method === BalanceValueMethod.MANUAL}
          onChange={(_: any, value: any) => {
            handleChangeFormula(value?.id);
          }}
          renderInput={params => (
            <TextField
              {...omit(params, "InputLabelProps")}
              label={t("balance.formula")}
            />
          )}
          options={mappedGroupNameFormulas}
          groupBy={(option: any) => option.group}
          size="small"
        />
      </InputsWrapper>
    </FlexRow>
  );
};
