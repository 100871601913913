import { memo, useCallback, useState } from "react";
import { useWatch } from "react-hook-form";

import { useAppTranslation } from "@hooks";
import { ProgramFormInput } from "@hooks/useProgramForm";
import { Checkbox } from "@components/Checkbox";

import { NutrientsModal } from "./NutrientsSelectModal";

export const MicrosSelect = memo(() => {
  const { t } = useAppTranslation();
  const micros = useWatch<ProgramFormInput>({ name: "showMicros" }) as number[];
  const [showModal, setShowModal] = useState(false);
  const show = useCallback(() => setShowModal(true), []);
  const hide = useCallback(() => setShowModal(false), []);

  return (
    <>
      <Checkbox
        value={!!micros.length}
        onChange={show}
        label={`${t("program.info.microelements_values")} (${
          micros.length
            ? `${t("program.info.chosen")}: ${micros.length}`
            : t("program.info.choose_from_list")
        })`}
      />
      {showModal && <NutrientsModal hide={hide} />}
    </>
  );
});
