import { useNavigate } from "react-router-dom";

import { ModalWrapper } from "@components/ModalWrapperNew";
import { OuterLabel } from "@components/OuterLabel";
import { SearchClientsAutocomplete } from "@components/SearchClientsAutocomplete";
import { usePostProgramFromDietMutation } from "@hooks/queries";

import { Text } from "./AutogenerateMealScheduleModal.styled";
import { DietSearchAutocomplete } from "./DietSearchAutocomplete";
import { MealDateRangePicker } from "./MealDateRangePicker";
import {
  AutogenerateFormInputs,
  mapAutogenerateRequest,
  useAutogenerateForm,
} from "./useAutogenerateForm";
import { useAppTranslation } from "@hooks";
import { ALREADY_EXIST_RESPONSE_ERROR_MESSAGE } from "@client";

export interface DefaultValuesIf extends AutogenerateFormInputs {
  dietQuery?: string;
  clientQuery?: string;
}
interface AutogenerateMealScheduleModalProps {
  open: boolean;
  onClose: () => void;
  defaultValues?: DefaultValuesIf;
}
export const AutogenerateMealScheduleModal = ({
  open,
  onClose,
  defaultValues,
}: AutogenerateMealScheduleModalProps) => {
  const { t } = useAppTranslation();
  const { control, handleSubmit, setError } =
    useAutogenerateForm(defaultValues);
  const { mutate, isLoading } = usePostProgramFromDietMutation();
  const navigate = useNavigate();

  const handleSubmitAndMutate = () => {
    handleSubmit(
      values => {
        const payload = mapAutogenerateRequest(values);
        payload &&
          mutate(payload, {
            onSuccess: ({ data: { id } }, { clientId }) =>
              navigate(
                `/patients/${clientId}/nutritional-programs/${id}/schedule`,
              ),
            onError: e => {
              if (
                e.response?.data.message ===
                ALREADY_EXIST_RESPONSE_ERROR_MESSAGE
              ) {
                setError("startDate", {
                  message: t("autogenerate.already_exist_error"),
                });
              }
            },
          });
      },
      err => console.log(err),
    )();
  };

  return (
    <ModalWrapper
      onClose={onClose}
      open={open}
      onSubmit={handleSubmitAndMutate}
      title={t("autogenerate.title")}
      confirmText={t("autogenerate.submit")}
      id="autogenerate-modal"
      loading={isLoading}
      onClick={e => e.stopPropagation()}
    >
      <div className="w-[478px] grid gap-[16px]">
        <Text>{t("autogenerate.text")}</Text>

        <OuterLabel label={t("autogenerate.select_client")}>
          <SearchClientsAutocomplete
            control={control}
            name="clientId"
            active={1}
            defaultQuery={defaultValues?.clientQuery}
            placeholder={t("autogenerate.select_client")}
          />
        </OuterLabel>

        <OuterLabel label={t("autogenerate.select_diet")}>
          <DietSearchAutocomplete
            control={control}
            name="dietId"
            defaultQuery={defaultValues?.dietQuery}
            placeholder={t("autogenerate.select_diet")}
          />
        </OuterLabel>

        <OuterLabel label={t("autogenerate.select_date_range")}>
          <MealDateRangePicker control={control} />
        </OuterLabel>
      </div>
    </ModalWrapper>
  );
};
