import { styled } from "@mui/material";
import { Add } from "@assets/icons";

export const AddIconWrapper = styled("div")`
  width: 100%;
  display: flex;
  justify-content: center;
  opacity: 0.4;
`;

export const AddIconStyled = styled(Add)`
  font-size: 6rem;
  opacity: 0.4;
  color: #9ca3afff;
`;
