import * as yup from "yup";

import { APIMethods, ApiResponse } from "@typeDefinitions";
import { fetchData } from "@utils/api";

import { SectionDto } from "./fetchProgramSections";

export const fetchProgramPdfBasic = async (
  id: number,
): Promise<ApiResponse<FetchProgramPdfBasicResponse>> => {
  const data = await fetchData(
    `/dietitian/programs/${id}/pdf/basic`,
    APIMethods.GET,
  );
  return await fetchProgramPdfBasicSchema.validate(data);
};

const clinicAddressSchema = yup.object().shape({
  address: yup.string().ensure(),
  zipCode: yup.string().ensure(),
  city: yup.string().ensure(),
});

const clinicContactSchema = yup.object().shape({
  phone: yup.string().ensure(),
  email: yup.string().ensure(),
  webSiteUrl: yup.string().ensure(),
});

const clinicSchema = yup.object().shape({
  name: yup.string().required(),
  address: clinicAddressSchema.required(),
  logoUrl: yup.string().required(),
  contact: clinicContactSchema.required(),
});

const dietitianSchema = yup.object().shape({
  firstName: yup.string().ensure(),
  lastName: yup.string().ensure(),
});

const patientSchema = yup.object().shape({
  firstName: yup.string().ensure(),
  lastName: yup.string().ensure(),
});

const programSchema = yup.object().shape({
  lang: yup.string().oneOf(["pl", "en"]).required(),
  startDate: yup.string().nullable().default(null),
  finishDate: yup.string().nullable().default(null),
  durationDays: yup.number().nullable().default(null),
});

const fetchProgramPdfBasicSchema = yup.object().shape({
  data: yup.object().shape({
    program: programSchema.required(),
    clinic: clinicSchema.required(),
    dietitian: dietitianSchema.required(),
    patient: patientSchema.required(),
    sections: yup.array().ensure().required(),
  }),
});

export interface FetchProgramPdfBasicResponse {
  program: ProgramDto;
  clinic: ClinicDto;
  dietitian: DietitianDto;
  patient: PatientDto;
  sections: SectionDto[];
}
export interface ProgramDto {
  lang: "pl" | "en";
  startDate: string | null;
  finishDate: string | null;
  durationDays: number | null;
}

interface ClinicAddressDto {
  address: string;
  zipCode: string;
  city: string;
}

export interface ClinicDto {
  name: string;
  address: ClinicAddressDto;
  logoUrl: string;
  contact: ContactDto;
}

interface ContactDto {
  phone: string;
  email: string;
  webSiteUrl: string;
}

interface DietitianDto {
  firstName: string;
  lastName: string;
}

interface PatientDto {
  firstName: string;
  lastName: string;
}
