import { useMemo, useState } from "react";
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridRenderCellParams,
  GridSelectionModel,
} from "@mui/x-data-grid";
import {
  CustomDataGrid,
  FlexDataGrid,
} from "@components/Alloweat/DataGrid/DataGrid";
import ProgramSearchPreviewDrawer from "@components/ProgramSearchPreview/ProgramSearchPreviewDrawer";
import ProgramSearchPreview from "@components/ProgramSearchPreview/ProgramSearchPreview";
import { SearchProgramsPaginationResponse } from "@client/searchProgramsNew";
import TagsColumn from "@views/dietician/PatientProgramChoice/Grid/TagsColumn";
import DetailsColumn from "@views/dietician/PatientProgramChoice/Grid/DetailsColumn/DetailsColumn";
import { SelectedProgramType } from "@views/dietician/PatientProgramChoice/PatientProgramChoice";
import RadiobuttonIcon from "@icons/radiobutton.svg?react";
import RadiobuttonCheckedIcon from "@icons/radiobutton_checked.svg?react";
import { IconButton } from "@components/Alloweat/IconButton";

export type RowType = SearchProgramsPaginationResponse["data"][0];

type PatientProgramChoiceGridProps = {
  programs: SearchProgramsPaginationResponse | undefined;
  isFetching: boolean;
  pageField: {
    page: number;
    setPage: (value: number) => void;
  };
  perPageField: {
    perPage: number;
    setPerPage: (value: number) => void;
  };
  selectedProgram: SelectedProgramType;
  setSelectedProgram: (value: SelectedProgramType) => void;
};
const PatientProgramChoiceGrid = ({
  programs,
  isFetching,
  pageField: { page, setPage },
  perPageField: { perPage, setPerPage },
  selectedProgram,
  setSelectedProgram,
}: PatientProgramChoiceGridProps) => {
  const [selectedProgramId, setSelectedProgramId] = useState<number | null>(
    null,
  );

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        renderCell: (params: GridRenderCellParams<any, RowType>) => {
          return (
            <IconButton
              onClick={() =>
                setSelectedProgram({
                  id: params.row.id,
                  startDate: params.row.startDate,
                  endDate: params.row.endDate,
                })
              }
            >
              {params.row.id === selectedProgram?.id ? (
                <RadiobuttonCheckedIcon color="#7448D0" />
              ) : (
                <RadiobuttonIcon color="#D0D0D0" />
              )}
            </IconButton>
          );
        },
      },
      {
        field: "name",
        flex: 1,
        sortable: false,
        headerName: "",
        renderCell: (params: GridRenderCellParams<any, RowType>) => {
          return <DetailsColumn row={params.row} />;
        },
      },
      {
        field: "tags",
        flex: 1,
        sortable: false,
        headerName: "",
        renderCell: (params: GridRenderCellParams<any, RowType>) => {
          return <TagsColumn row={params.row} />;
        },
      },
    ];
  }, [selectedProgram]);

  return (
    <>
      <FlexDataGrid>
        <CustomDataGrid
          rows={programs?.data ?? []}
          columns={columns}
          onPageChange={setPage}
          pageSize={perPage}
          page={page}
          rowCount={programs?.meta.total ?? 0}
          loading={isFetching}
          componentsProps={{
            pagination: { setPerPage, perPage, rowsPerPageOptions: [10, 25] },
          }}
          onRowClick={(params, e, details) => {
            setSelectedProgramId(parseInt(params.row.id.toString()));
            setSelectedProgram({
              id: params.row.id,
              startDate: params.row.startDate,
              endDate: params.row.endDate,
            });
          }}
          selectionModel={selectedProgram ? [selectedProgram.id] : []}
          checkboxSelection
          headerHeight={0}
          rowHeight={68}
          disableSelectionOnClick
        />
      </FlexDataGrid>
      <ProgramSearchPreviewDrawer
        programId={selectedProgramId}
        onClose={() => {
          setSelectedProgramId(null);
        }}
      >
        {selectedProgramId !== null && (
          <ProgramSearchPreview programId={selectedProgramId} />
        )}
      </ProgramSearchPreviewDrawer>
    </>
  );
};

export default PatientProgramChoiceGrid;
