import { Dialog } from "@mui/material";
import { FormEventHandler, MouseEventHandler, ReactNode } from "react";
import {
  CancelButton,
  CloseButton,
  ModalWrapper,
  StyledDialogActions,
  Title,
  TitleWrapper,
} from "./ModalShell.styled";
import { useAppTranslation } from "@hooks";
import { Close } from "@assets/icons";
import { LoadingButton } from "@mui/lab";

interface ModalShellProps {
  title?: string;
  open: boolean;
  onClose: () => void;
  isLoading: boolean;
  onSubmit:
    | MouseEventHandler<HTMLButtonElement>
    | FormEventHandler<HTMLFormElement>;
  children: ReactNode;
  typeSubmit?: boolean;
}

export const ModalShell = ({
  title,
  open,
  onClose,
  isLoading,
  onSubmit,
  children,
  typeSubmit = false,
}: ModalShellProps) => {
  const { t } = useAppTranslation();

  return (
    <Dialog open={open} disableEscapeKeyDown>
      <form
        onSubmit={
          typeSubmit
            ? (onSubmit as FormEventHandler<HTMLFormElement>)
            : undefined
        }
      >
        <ModalWrapper>
          <TitleWrapper>
            <Title>{title}</Title>
            <CloseButton size="small" onClick={onClose}>
              <Close size="w-3 h-3" />
            </CloseButton>
          </TitleWrapper>
          {children}
        </ModalWrapper>
        <StyledDialogActions>
          <CancelButton variant="outlined" onClick={onClose}>
            {t("common.cancel")}
          </CancelButton>
          <LoadingButton
            type={typeSubmit ? "submit" : undefined}
            variant="contained"
            loading={isLoading}
            onClick={
              !typeSubmit
                ? (onSubmit as MouseEventHandler<HTMLButtonElement>)
                : undefined
            }
          >
            {t("common.save")}
          </LoadingButton>
        </StyledDialogActions>
      </form>
    </Dialog>
  );
};
