import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MutationOptions } from "../types";
import { fetchPatientVisitsQueryKey } from "./useFetchPatientVisitsQuery";
import { addFileToVisit } from "@client/visits";

export const useAddVisitFileMutation = (
  patientId: number,
  scheduleId: number,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: { fileId: number }) => addFileToVisit(scheduleId, payload.fileId),
    {
      ...options,
      onSuccess() {
        queryClient.invalidateQueries([fetchPatientVisitsQueryKey, patientId]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};
