import { useCallback, useMemo } from "react";
import { useMonitoring } from "@views/dietician/PatientMonitoring2/contexts/MonitoringContext";
import { useFetchMonitoringMeasurements } from "@hooks/queries/client/monitoring";
import { mapSelectedDaysToRequestQueryParams } from "@views/dietician/PatientMonitoring2/PatientMonitoringUtils";
import MeasurementsBodySection from "@views/dietician/PatientMonitoring2/components/MeasurementsBox/MeasurementsBodySection";
import { useFetchClientQueryNew } from "@hooks/queries";
import { mapClientDataToGoals } from "@views/dietician/PatientMonitoring2/components/MeasurementsBox/MeasurementUtil";
import { useAppTranslation } from "@hooks";
import { BodyMeasurementMonitoringResource } from "@client/resources/BodyMeasurementMonitoringResource";
import {
  tooltipLabelFormatter,
  xAxisTickFormatter,
} from "@views/dietician/PatientMonitoring2/utils/ChartUtils";

const MeasurementsBodySectionOfDays = () => {
  const { t } = useAppTranslation();
  const { patientId, selectedDays } = useMonitoring();

  const { data } = useFetchMonitoringMeasurements({
    patientId,
    ...mapSelectedDaysToRequestQueryParams(selectedDays.selectDays),
  });

  const { data: clientData } = useFetchClientQueryNew(patientId);

  const latestMeasurement = useMemo(
    () => getLatestMeasurement(data?.data ?? []),
    [data],
  );

  const goals = useMemo(
    () => mapClientDataToGoals(clientData?.data),
    [clientData],
  );

  const getXAxisTickFormatter = useCallback(
    (date: string) => xAxisTickFormatter(t, date, data?.data.length ?? 0),
    [t, data],
  );

  const getTooltipLabelFormatter = useCallback(
    (date: string) => tooltipLabelFormatter(t, date, data?.data.length ?? 0),
    [t, data],
  );

  return (
    <MeasurementsBodySection
      data={data?.data}
      latest={latestMeasurement}
      goals={goals}
      xAxisTickFormatter={getXAxisTickFormatter}
      tooltipLabelFormatter={getTooltipLabelFormatter}
    />
  );
};

const getLatestMeasurement = (
  data: BodyMeasurementMonitoringResource[],
): BodyMeasurementMonitoringResource | null => {
  if (!data.length) {
    return null;
  }

  return data.filter(i => !!i.measurements).reverse()[0] ?? null;
};

export default MeasurementsBodySectionOfDays;
