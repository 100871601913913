import * as yup from "yup";
import { fetchData } from "@utils/api";

import { fetchProgramShape, GenericFetchProgramResponse } from "./common";
import { APIMethods, ApiResponse } from "@typeDefinitions";

export const fetchPatientProgram = async (
  patientId: string,
  programId: string,
): Promise<ApiResponse<FetchPatientProgramResponse>> => {
  const response = await fetchData(
    `/dietitian/patients/${patientId}/programs/${programId}`,
    APIMethods.GET,
  );
  return await fetchPatientProgramSchema.validate(response);
};

const fetchPatientProgramSchema = yup.object().shape({
  data: yup
    .object()
    .shape({
      ...fetchProgramShape,
      finishDate: yup.string().nullable().default(null),
      isActive: yup.boolean().required(),
      shared: yup.boolean().required(),
    })
    .required(),
});

export interface FetchPatientProgramResponse
  extends GenericFetchProgramResponse {
  finishDate: string | null;
  isActive: boolean;
  shared: boolean;
}
