import { Divider, Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";
import {
  BoldTypography,
  ColorDot,
  MonitoringChartTooltipStyled,
  TooltipTypography,
  TooltipDayWrapper,
  TooltipSummaryWrapper,
  TypographyFill,
} from "./MonitoringChartSection.styled";
import { round } from "lodash";
import { useAppTranslation } from "@hooks";

interface MonitoringChartTooltipProps {
  dayName: string;
  dayNameTarget: string;
  date: dayjs.Dayjs;
  value: number | null;
  target: number | null;
  unit: string | undefined;
}
export const MonitoringChartTooltip = ({
  date,
  dayName,
  dayNameTarget,
  value,
  target,
  unit,
}: MonitoringChartTooltipProps) => {
  const { t } = useAppTranslation();
  const { palette, colors } = useTheme();

  const difference = value && target && value - target;

  return (
    <MonitoringChartTooltipStyled>
      <TooltipTypography variant="body2">
        {date.format("dd")}, {date.format("DD.MM.YY")}
      </TooltipTypography>

      <Divider />

      <TooltipDayWrapper>
        <ColorDot background={palette.primary.main} />
        <TypographyFill variant="caption">{dayName}</TypographyFill>
        <Typography variant="caption">
          {value ? round(value) : "-"} {unit}
        </Typography>
      </TooltipDayWrapper>
      <TooltipDayWrapper>
        <ColorDot background={colors.chartLightPurple} />
        <TypographyFill variant="caption">{dayNameTarget}</TypographyFill>
        <Typography variant="caption">
          {target ? round(target) : "-"} {unit}
        </Typography>
      </TooltipDayWrapper>

      <Divider />

      <TooltipSummaryWrapper>
        <Typography variant="body2">{t("common.balance")}:</Typography>
        <BoldTypography variant="body2">
          {!!difference && difference > 0 && "+"}
          {difference ? round(difference) : "-"} {unit}
        </BoldTypography>
      </TooltipSummaryWrapper>
    </MonitoringChartTooltipStyled>
  );
};
