import { useEffect, useState } from "react";
import Talk from "talkjs";

export const useUnreadMessages = (session?: Talk.Session) => {
  const [unread, setUnread] = useState(0);
  const [unreadIds, setUnreadIds] = useState<(string | undefined)[]>([]);

  useEffect(() => {
    if (!session) return;
    session.unreads.onChange(unreads => setUnread(unreads.length));
    session.unreads.onChange(unreads =>
      setUnreadIds(unreads.map(el => el.lastMessage.sender?.id.toString())),
    );
  }, [session]);

  return { unread, unreadIds };
};
