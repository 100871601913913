import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";

export const postPatientSendSurvey = async (
  patientId: string,
  surveyId: number,
) => {
  await fetchData(
    `/dietitian/patients/${patientId}/send-surveys`,
    APIMethods.POST,
    { surveyId },
  );
};
