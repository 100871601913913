import { useState } from "react";

import { useAppParams, useAppTranslation } from "@hooks";
import {
  useFetchNormsQuery,
  useFetchProgramDayNutrientsQuery,
  useProgramDayNutrientsMutation,
} from "@hooks/queries";
import { fetchNormNutrients } from "@client";

import { FormMicrosModal } from "./";
import { normNutrientsMapper, nutrientsIdMapper } from "../macrosUtils";
import { TreeSelect } from "@components/TreeSelect";
import { useNormTreeOptions } from "@components/modals/DietDayNutritionModal/components";
import { Button } from "@mui/material";

import { LabelStyled, RowStyled } from "../MacrosSliders.styled";

export const NormSelector = () => {
  const { t } = useAppTranslation();
  const { norms } = useFetchNormsQuery();
  const options = useNormTreeOptions(norms);
  const [open, setOpen] = useState(false);
  const { programId, dayId } = useAppParams();

  const { nutrients, isLoading } = useFetchProgramDayNutrientsQuery(
    programId,
    dayId,
  );

  const { mutate } = useProgramDayNutrientsMutation();

  const handleChangeNorm = async (normId: string) => {
    if (programId && dayId && nutrients) {
      const normNutrients = await fetchNormNutrients(parseInt(normId));
      const allNutrientsId = nutrientsIdMapper(
        normNutrients?.data,
        nutrients?.data?.nutrients,
      );

      mutate({
        programId,
        dayId,
        payload: {
          normId,
          nutrients: normNutrientsMapper(
            allNutrientsId,
            nutrients?.data?.nutrients,
            normNutrients?.data,
          ),
        },
      });
    }
  };

  if (isLoading) return <div />;

  return (
    <RowStyled>
      <LabelStyled variant="body2">{t("macros_sliders.norm")}</LabelStyled>
      <TreeSelect
        className="flex-1"
        variant="outlined"
        menuStyle={{ overflowY: "scroll" }}
        onChange={handleChangeNorm}
        value={nutrients?.data.norm?.id.toString()}
        options={options}
      />
      <Button onClick={() => setOpen(true)}>
        {t("macros_sliders.change_micros")}
      </Button>
      {open && nutrients?.data.norm?.id && (
        <FormMicrosModal
          onClose={() => setOpen(false)}
          programId={parseInt(programId ?? "0")}
          dayId={parseInt(dayId ?? "0")}
          normId={parseInt(nutrients.data.norm.id)}
        />
      )}
    </RowStyled>
  );
};
