import { useAppTranslation } from "@hooks";
import { useFetchClinicProducts, useFetchCurrencies } from "@hooks/queries";

export const useProductsAndCurrencies = () => {
  const { isPolishChosen } = useAppTranslation();
  const { currencies } = useFetchCurrencies();
  const { clinicProducts } = useFetchClinicProducts();

  const productsMapped = clinicProducts?.map(product => ({
    id: product.id.toString(),
    label: isPolishChosen
      ? product.translations.pl.name
      : product.translations.en.name,
  }));

  const currenciesMapped = currencies?.map(currency => ({
    id: currency.currency,
    label: currency.currency,
  }));

  return { currencies, clinicProducts, productsMapped, currenciesMapped };
};
