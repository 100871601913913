import styled from "@emotion/styled";
import { IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

export const OfficesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 0.5rem;
`;

export const InfoOfficeSection = styled.div`
  display: grid;
  gap: 2rem;
  height: fit-content;
  padding: 1rem;
  background: ${({ theme }) => theme.colors.whiteBackground};
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.boxShadows.light};
`;

export const OfficeListSection = styled.div`
  height: 100%;
  width: 100%;
`;

export const OfficeContentSection = styled.div`
  display: grid;
`;

export const AvatarSection = styled.div`
  margin: 0 auto;
`;

export const ContentSection = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: end;
`;

export const BrandItem = styled.div`
  display: grid;
`;

export const SubtitleDescr = styled.div`
  display: grid;
  gap: 0.25rem;
  align-items: center;
  max-width: 40rem;
  min-width: 1rem;
  width: 100%;
`;

export const StyledSubtitle = styled.p`
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
`;

export const Description = styled.p`
  margin-right: auto;
  line-height: initial;
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0;
`;

export const StyledDataGrid = styled(DataGrid)`
  background-color: transparent;
  border: unset;
  & .MuiDataGrid-columnSeparator.MuiDataGrid-columnSeparator--sideRight {
    color: transparent;
  }

  & .MuiDataGrid-row {
    background-color: #fff;
    box-shadow: ${({ theme }) => theme.boxShadows.light};
  }

  & .MuiDataGrid-columnHeaders {
    background-color: #fff;
    border: unset;
    font-weight: 600;
    box-shadow: ${({ theme }) => theme.boxShadows.light};
  }

  & .MuiDataGrid-columnHeader {
    padding: 0 1.75rem;
    &:focus,
    &:focus-within {
      outline: none;
    }
  }

  & .MuiDataGrid-footerContainer {
    margin-top: 0.5rem;
    background-color: ${({ theme }) => theme.colors.whiteBackground};
    border: unset;
    border-radius: 0 0 0.375rem 0.375rem;
    box-shadow: ${({ theme }) => theme.boxShadows.light};

    & .MuiToolbar-root {
      padding-right: 0.625rem;
    }
  }

  & .MuiDataGrid-cell {
    border-bottom: unset;
    padding: 0 1.75rem;
    &:focus,
    &:focus-within {
      outline: none;
    }
  }

  & .MuiDataGrid-cell--textLeft:first-child {
    font-weight: 600;
    &:hover {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;
