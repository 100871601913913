import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MutationOptions } from "./types";
import { fetchProgramQueryKey } from "./useFetchProgramQuery";
import { updatePatientProgram, UpdatePatientProgramRequest } from "@client";

export const useUpdatePatientProgramMutation = (
  programId: number,
  patientId: number,
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (request: UpdatePatientProgramRequest) =>
      updatePatientProgram(patientId, programId, request),
    {
      ...options,
      onSuccess: () => {
        queryClient.invalidateQueries([
          fetchProgramQueryKey,
          patientId,
          programId,
        ]);
        options?.onSuccess && options.onSuccess();
      },
    },
  );
};
