import { useContext, useEffect } from "react";
import { ValidationContext } from "./ValidationContext";
import { TableOfContentsContext } from "@context/TableOfContentsContext";
import { FieldValues, UseFormHandleSubmit } from "react-hook-form";

export const useUpdateValidationContext = (
  id: number,
  touchedFields: Partial<Readonly<{ [x: string]: any }>>,
  handleSubmit: UseFormHandleSubmit<FieldValues>,
) => {
  const { groupId, subgroupId } = useContext(TableOfContentsContext);
  const { updateAnswerControl, getIsTouched, handleTouched } =
    useContext(ValidationContext);
  const validate = handleSubmit(() => {
    null;
  });

  useEffect(() => {
    updateAnswerControl(groupId ?? 0, subgroupId ?? 0, id, handleSubmit);
  }, [handleSubmit]);

  useEffect(() => {
    if (getIsTouched(id)) validate();

    if (Object.keys(touchedFields).length && !getIsTouched(id)) {
      handleTouched(id, true);
      validate();
    }
  }, [Object.keys(touchedFields).length]);
};
