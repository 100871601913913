import {
  FetchPatientProgramResponse,
  FetchProgramResponse,
} from "@client/program";
import { ProgramInfoCard } from "@views/dietician/Program/components/ProgramInfoCard";
import { ProgramDiets } from "@views/dietician/Program/components/ProgramDietsCard";
import { ProgramScheduleCard } from "@views/dietician/Program/components/ProgramScheduleCard";
import { ProgramSectionsCard } from "@views/dietician/Program/components/ProgramSectionsCard";

interface ProgramViewProps {
  programId: number;
  handleRemoveSection: (sectionId: number) => void;
  dietitianProgram: FetchProgramResponse | FetchPatientProgramResponse;
  patientId?: number;
}

export const ProgramView = ({
  programId,
  handleRemoveSection,
  dietitianProgram,
  patientId,
}: ProgramViewProps) => {
  const canEdit =
    "actions" in dietitianProgram ? dietitianProgram.actions.canEdit : true;
  const shared =
    "shared" in dietitianProgram ? dietitianProgram.shared : undefined;

  return (
    <div>
      <ProgramInfoCard
        patientId={patientId}
        program={dietitianProgram}
        nutrients={dietitianProgram.showNutrients}
        canEdit={canEdit}
        shared={shared}
      />
      <ProgramDiets days={dietitianProgram.days} canEdit={canEdit} />
      <ProgramScheduleCard
        patientId={patientId}
        programId={programId}
        program={dietitianProgram}
        canEdit={canEdit}
      />
      <ProgramSectionsCard
        sections={dietitianProgram.sections}
        removeSection={handleRemoveSection}
        programId={programId}
        canEdit={canEdit}
      />
    </div>
  );
};
