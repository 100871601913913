import { lazy } from "yup";
import dayjs, { Dayjs } from "dayjs";
import { useClientParams } from "@hooks";
import { useCreateClientMeasurementMutation } from "@hooks/queries";
import { useFetchBodyMeasurementsQuery } from "@hooks/queries/dictionaries";
import { BodyMeasurementResource } from "@client/dictionaries";
import { ReactElement } from "react";
import * as yup from "yup";
import { mapObjectDynamicFieldsRules } from "@utils/yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { BodyMeasurementConst } from "@consts/BodyMeasurementConst";
import useCreatePatientBodyMeasurementMutation from "@hooks/queries/client/bodyMeasurement/useCreatePatientBodyMeasurementMutation";

export type FormPops = {
  measurementBody: {
    [key: string]: string;
  };
  date: Dayjs;
};

const CreateBodyMeasurementsForm = ({
  children,
  onSuccess,
}: Pick<GoalFormProps, "children" | "onSuccess">) => {
  const id = useClientParams();

  const { data: bodyMeasurements } = useFetchBodyMeasurementsQuery();

  if (!bodyMeasurements) {
    return null;
  }

  return (
    <GoalForm
      onSuccess={onSuccess}
      bodyMeasurements={bodyMeasurements.data}
      clientId={id}
    >
      {children}
    </GoalForm>
  );
};

type GoalFormProps = {
  bodyMeasurements: BodyMeasurementResource[];
  clientId: number;
  children: ReactElement | ReactElement[];
  onSuccess: () => void;
};

const measurementSchema = yup
  .number()
  .transform((val, orig) => (orig == "" ? null : val))
  .nullable()
  .test(
    "is-decimal",
    "The amount should be a decimal with maximum two digits after comma",
    (val: any) => {
      if (val !== null) {
        return /^\d+(\.\d{0,2})?$/.test(val);
      }
      return true;
    },
  )
  .defined();

const formSchema = yup.object({
  measurementBody: lazy(map =>
    yup.object(mapObjectDynamicFieldsRules(map, measurementSchema)).required(),
  ),
});

const GoalForm = ({
  bodyMeasurements,
  clientId,
  children,
  onSuccess,
}: GoalFormProps) => {
  const { mutate, isLoading } = useCreatePatientBodyMeasurementMutation();

  const form = useForm<FormPops>({
    defaultValues: {
      measurementBody: Object.fromEntries(
        bodyMeasurements.map(body => {
          return [body.id, ""];
        }),
      ),
      date: dayjs(),
    },
    resolver: yupResolver(formSchema),
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<FormPops> = async data => {
    const requestPayload = mapFormDataToBodyRequest(data);
    if (!requestPayload.bodyMeasurements.length) {
      onSuccess();
      return;
    }

    if (isLoading) {
      return;
    }

    await new Promise<void>(resolve => {
      mutate(
        {
          params: {
            patientId: clientId,
          },
          payload: requestPayload,
        },
        {
          onSuccess: () => {
            onSuccess();
          },
          onError: err => {
            //log error
          },
          onSettled: () => {
            resolve();
          },
        },
      );
    });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>{children}</form>
    </FormProvider>
  );
};

const mapFormDataToBodyRequest = (data: FormPops) => {
  const getMeasurement = (measurement: number) =>
    data.measurementBody[BodyMeasurementConst.height]
      ? parseFloat(data.measurementBody[measurement])
      : null;

  return {
    date: data.date.format("YYYY-MM-DD"),
    growth: getMeasurement(BodyMeasurementConst.height),
    weight: getMeasurement(BodyMeasurementConst.weight),
    bodyFatLevel: getMeasurement(BodyMeasurementConst.bodyFatLevel),
    arm: getMeasurement(BodyMeasurementConst.arm),
    tightBiceps: getMeasurement(BodyMeasurementConst.tightBiceps),
    waist: getMeasurement(BodyMeasurementConst.waist),
    hip: getMeasurement(BodyMeasurementConst.hip),
    thigh: getMeasurement(BodyMeasurementConst.thigh),
    calf: getMeasurement(BodyMeasurementConst.calf),
    water: getMeasurement(BodyMeasurementConst.water),
    bodyFatMass: getMeasurement(BodyMeasurementConst.bodyFatMass),
    skeletalMuscleMass: getMeasurement(BodyMeasurementConst.skeletalMuscleMass),
    boneTissueMass: getMeasurement(BodyMeasurementConst.boneTissueMass),
    chest: getMeasurement(BodyMeasurementConst.chest),
    abdominal: getMeasurement(BodyMeasurementConst.abdominal),
    bodyMeasurements: Object.keys(data.measurementBody)
      .map(bodyId => {
        return {
          id: parseInt(bodyId),
          value: data.measurementBody[bodyId]
            ? parseFloat(data.measurementBody[bodyId])
            : null,
        };
      })
      .filter(measurement => measurement.value != null),
  };
};

export default CreateBodyMeasurementsForm;
