import { LangDto } from "@client";
import { ProgramSearchResourceDto } from "@client/searchProgramsNew";

import { ProgramRowIf } from "./ProgramsDataGrid";
import dayjs from "dayjs";

export const mapProgramRows = (
  programs: ProgramSearchResourceDto[] | undefined,
  userId: number,
): ProgramRowIf[] => {
  if (!programs) return [];

  return programs.map(
    ({
      id,
      favorite,
      actions,
      translations,
      nutrients,
      numberOfProgramDays,
      startDate,
      endDate,
      tags,
    }) => {
      const namePl = translations.find(t => t.lang === LangDto.PL)?.name ?? "";
      const nameEn = translations.find(t => t.lang === LangDto.EN)?.name;
      const programLength =
        dayjs(startDate).isValid() && dayjs(endDate).isValid()
          ? dayjs(endDate)
              .endOf("day")
              .diff(dayjs(startDate).startOf("day"), "days") + 1
          : null;

      return {
        id: id,
        namePl: namePl,
        nameEn: nameEn ?? namePl,
        tags: tags.map(t => t.id.toString()),
        nutrients,
        isFavorite: favorite.includes(userId),
        actions: actions,
        numberOfDayTypes: numberOfProgramDays,
        programLength,
      };
    },
  );
};
