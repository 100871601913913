import { SvgIcon, SvgIconProps } from "@components/SvgIcon";

export const Male = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    fill={props.fill}
    width="21"
    height="20"
    viewBox="0 0 21 20"
  >
    <path
      d="M17.5321 4.98573L17.1786 4.63218L16.825 4.98573L14.4768 7.33395L14.1829 7.62786L14.4182 7.97052C15.2339 9.1586 15.7143 10.5918 15.7143 12.1429C15.7143 16.206 12.4203 19.5 8.35714 19.5C4.294 19.5 1 16.206 1 12.1429C1 8.07971 4.294 4.78571 8.35714 4.78571C9.90471 4.78571 11.3418 5.26219 12.5285 6.08116L12.8714 6.31782L13.1661 6.0232L15.5143 3.67498L15.8678 3.32143L15.5143 2.96788L14.0232 1.4768C13.8595 1.31307 13.8106 1.06818 13.8999 0.850831C13.9869 0.639391 14.1951 0.5 14.4286 0.5H19.4286C19.7462 0.5 20 0.753821 20 1.07143V6.07143C20 6.30486 19.8606 6.51313 19.6492 6.60005C19.4318 6.68941 19.1869 6.64053 19.0232 6.4768L17.5321 4.98573ZM6.25238 17.2242L6.44373 16.7623L6.25238 17.2242C6.91967 17.5006 7.63487 17.6429 8.35714 17.6429C9.07941 17.6429 9.79461 17.5006 10.4619 17.2242C11.1292 16.9478 11.7355 16.5427 12.2462 16.0319C12.757 15.5212 13.1621 14.9149 13.4385 14.2476C13.7149 13.5803 13.8571 12.8651 13.8571 12.1429C13.8571 11.4206 13.7149 10.7054 13.4385 10.0381C13.1621 9.37081 12.757 8.76449 12.2462 8.25377C11.7355 7.74305 11.1292 7.33792 10.4619 7.06152C9.79461 6.78512 9.07941 6.64286 8.35714 6.64286C7.63487 6.64286 6.91967 6.78512 6.25238 7.06152C5.58509 7.33792 4.97878 7.74305 4.46806 8.25377C3.95733 8.76449 3.55221 9.37081 3.27581 10.0381C2.9994 10.7054 2.85714 11.4206 2.85714 12.1429C2.85714 12.8651 2.9994 13.5803 3.27581 14.2476C3.55221 14.9149 3.95733 15.5212 4.46806 16.0319C4.97878 16.5427 5.58509 16.9478 6.25238 17.2242Z"
      fill={props.fill ?? "currentColor"}
      stroke={props.fill ?? "currentColor"}
    />
  </SvgIcon>
);
