import { useCallback, useMemo } from "react";

import { useAppTranslation } from "@hooks";
import { useFetchClientSourcesQuery } from "./queries";
import { clientDictionariesMapper } from "@utils/clients";

export const useClientSources = () => {
  const { sources, isSuccess } = useFetchClientSourcesQuery();
  const { i18n } = useAppTranslation();

  const sourcesDict = useMemo(() => {
    if (isSuccess && sources) return clientDictionariesMapper(sources);
    return;
  }, [sources]);

  const getSourceName = useCallback(
    (id: number) => {
      if (
        sourcesDict &&
        sourcesDict[id] &&
        sourcesDict[id].names[i18n.language]
      ) {
        return sourcesDict[id].names[i18n.language];
      }
      return "";
    },
    [sourcesDict, i18n.language],
  );

  return { getSourceName };
};
