import { APIMethods } from "@typeDefinitions";
import { fetchData } from "@utils/api";
import * as yup from "yup";

export const createProgram = async (
  payload: CreateProgramRequestDto,
): Promise<CreateProgramResponseDto> => {
  const rsp = await fetchData("/dietitian/programs", APIMethods.POST, payload);
  await createProgramResponseValidation.validate(rsp.data);
  return rsp.data;
};

export const cloneProgram = async (
  id: number,
): Promise<CreateProgramResponseDto> => {
  const rsp = await fetchData(
    `/dietitian/programs/${id}/clone`,
    APIMethods.POST,
  );
  await createProgramResponseValidation.validate(rsp.data);
  return rsp.data;
};

export interface CreateProgramRequestDto {
  name: "";
  durationDays: null;
  hasDiary: true;
  showNutrients: [];
  sections: [];
}

export interface CreateProgramResponseDto {
  id: number;
}

const createProgramResponseValidation = yup
  .object()
  .shape({
    id: yup.number().required(),
  })
  .required();
