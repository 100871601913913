import { useMemo } from "react";
import { Control, FieldValues, Path } from "react-hook-form";

import { InputWithSlider } from "./InputWithSlider";
import { labelFactory } from "./macrosUtils";
import { useInputSliderController } from "./useInputSliderController";

interface MacroSliderComponentProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  unit: string;
  control: Control<T>;
  min: number;
  max: number;
  transformValue?: (value: number) => number | undefined;
  transformSliderValue?: (value: number) => number | undefined;
  button?: React.ReactNode;
  sliderValue?: number;
  displayValue?: number;
  percent?: boolean;
  step: number;
  valueLabelFormat?: string;
  warning?: boolean | undefined;
}

export const MacroSliderComponent = <T extends FieldValues>({
  name,
  label,
  unit,
  control,
  min,
  max,
  transformValue = v => v,
  button,
  transformSliderValue = v => v,
  sliderValue,
  displayValue,
  percent,
  step,
  valueLabelFormat: controlledValueLabel,
  warning,
}: MacroSliderComponentProps<T>) => {
  const { value, onInputChange, onSliderChange } = useInputSliderController({
    control,
    name,
    transformValue,
    transformSliderValue,
  });

  const valueLabelFormat = useMemo(
    () => labelFactory(sliderValue ?? value, min, max, percent),
    [sliderValue, value, min, max],
  );

  return (
    <InputWithSlider
      label={label}
      unit={unit}
      value={displayValue ?? value}
      sliderValue={sliderValue ?? value}
      onInputChange={onInputChange}
      onSliderChange={onSliderChange}
      valueLabelFormat={controlledValueLabel ?? valueLabelFormat}
      min={min}
      max={max}
      button={button}
      step={step}
      color={warning ? "warning" : undefined}
    />
  );
};
